<template>
  <div class="shadow px-4 py-4 rounded bg-white print:shadow-none print:p-0">
    <JobPurchaseOrderSummary :summary="meta?.summary"/>

    <EntityPurchaseOrders
      :url-params="urlParams"
      @meta-fetch="meta = $event"
    />
  </div>
</template>
<script>
import EntityPurchaseOrders from "@/modules/purchasing-inventory/components/purchase-orders/EntityPurchaseOrders.vue";
import JobPurchaseOrderSummary from "@/modules/job-costing/components/purchase-orders/JobPurchaseOrderSummary.vue";

export default {
  components: {
    JobPurchaseOrderSummary,
    EntityPurchaseOrders,
  },
  data() {
    return {
      meta: {
        summary: {},
      }
    }
  },
  computed: {
    jobId() {
      return this.$route.params.id
    },
    urlParams() {
      return {
        job_id: this.jobId,
      }
    },
  },
}
</script>
