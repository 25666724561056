<template>
  <div>
    <layout-tabs
      v-if="!onViewResourcePage"
      :items="filteredItems"
    />
    <router-view/>
  </div>
</template>
<script>

export default {
  name: 'SubcontractInvoicesLayout',
  computed: {
    items() {
      return [
        {
          name: this.$t('Progress Invoices'),
          tooltip: this.$t('Prepare subcontract progress invoices'),
          path: `/accounts-payable/subcontract-invoices/progress`,
          permission: 'invoices_show',
        },
        {
          name: this.$t('Release Retention'),
          tooltip: this.$t('Vendor Release Retention'),
          path: `/accounts-payable/subcontract-invoices/release-retention`,
          permission: 'invoices_show',
        },
      ]
    },
    filteredItems() {
      return this.items.filter(item => this.$can(item.permission))
    },
  },
}
</script>
