<template>
  <base-report-form
      :filters="model"
      :report-number="reportNumber"
  >

    <template #filters>
      <base-filter-row :title="$t('Sort By')">

        <base-select
            v-model="model.group_by"
            :options="sortByOptions"
            class="col-span-2"
        />

      </base-filter-row>

      <base-filter-row :title="$t('Compare For')">
        <period-year-filter
            v-model="model"
            class="col-span-3"
        />

        <div class="col-span-3 flex items-baseline">
          <span class="text-sm text-gray-600 font-medium whitespace-nowrap md:mr-3">{{ $t('Subtotal On') }}</span>
          <base-select
              v-model="model.subtotal_on"
              :options="subtotalPositionOptions"
              class="flex-1"
          />

        </div>

      </base-filter-row>

      <base-filter-row>

        <base-checkbox
            v-model="model.liability_income_change_sign"
            :label="$t('Change Sings on Liability / Income Accounts')"
            class="col-span-8 mb-4"
            id="liability_income_change_sign"
        />

      </base-filter-row>

      <base-filter-row>
        <base-checkbox
            v-model="selectAccountList"
            :label="$t('Select a List of Accounts')"
            class="col-span-3 mb-3"
            id="select_account_list"
            @change="resetAccountFilters"
        />
      </base-filter-row>

      <base-filter-row :title="$t('Accounts')">

        <account-select
            v-if="selectAccountList"
            v-model="model.account_ids"
            :placeholder="$t('Select Accounts')"
            class="col-span-4"
            collapse-tags
            multiple
        />

        <template v-else>
          <account-range-filter v-model="model"/>

          <sub-account-range-filter
              v-model="model"
              grid-class="col-span-3"
          />
        </template>

      </base-filter-row>

      <base-filter-row :title="$t('Fiscal Year')">
        <fiscal-year-select
          v-model="model.fiscal_year"
          :placeholder="$t('Fiscal Year')"
          class="col-span-6 md:col-span-2"
          :show-closed-years="true"
        />
      </base-filter-row>

    </template>

    <template #table="{data, loading}">
      <budget-to-actual-compare-report-table
          :data="data"
          :filters="model"
          :report-number="reportNumber"
          :data-loading="loading"
          :subtotal-on="+model.subtotal_on"
      />
    </template>

  </base-report-form>
</template>
<script>

  import { AccountRangeFilter, PeriodYearFilter, SubAccountRangeFilter } from '@/components/range-filters'
  import BudgetToActualCompareReportTable from '@/modules/ledger/components/reports/BudgetToActualCompareReportTable'

  const sortBy = {
    Account: 'account',
    Subaccount: 'subaccount',
  }

  export default {
    components: {
      PeriodYearFilter,
      AccountRangeFilter,
      SubAccountRangeFilter,
      BudgetToActualCompareReportTable,
    },
    data() {
      return {
        reportNumber: 1884,
        selectAccountList: false,
        model: {
          group_by: sortBy.Account,
          subtotal_on: '0',
          period: 0,
          fiscal_year: this.$currentYear,
          account_ids: [],
          account_number_from: null,
          account_number_to: null,
          subaccount_number_from: null,
          subaccount_number_to: null,
          liability_income_change_sign: false,
          page_before_subaccount: false,
        },
        sortByOptions: [
          {
            label: this.$t('Account'),
            value: sortBy.Account,
          },
          {
            label: this.$t('Sub-Account'),
            value: sortBy.Subaccount,
          },
        ],
        subtotalPositionOptions: [
          {
            label: this.$t('1st Position of Acct'),
            value: '1',
          },
          {
            label: this.$t('1st 2 Position of Acct'),
            value: '2',
          },
          {
            label: this.$t('1st 3 Position of Acct'),
            value: '3',
          },
          {
            label: this.$t('No Subtotals'),
            value: '0',
          },
        ],
      }
    },
    methods: {
      resetAccountFilters() {
        this.model.account_ids = []
        this.model.account_number_from = this.model.account_number_to = null
        this.model.subaccount_number_from = this.model.subaccount_number_to = null
      },
    },
  }
</script>
