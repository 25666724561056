<template>
  <base-form :loading="loading"
             :show-cancel="showCancel"
             :save-text="$t('Create department code')"
             :update-text="$t('Update department code')"
             :can-create-another-entity="!model.id"
             layout="modal"
             @cancel="$emit('cancel')"
             @submit="onSubmit"
  >
    <div class="col-span-2">
      <base-input v-model="model.code"
                  :label="$t('Code')"
                  :placeholder="$t('Department code')"
                  id="name"
                  rules="required|code">
      </base-input>
    </div>
    <div class="col-span-3">
      <base-select v-model="model.eeoc_job_category"
                   :label="$t('EEOC Job Category')"
                   :options="jobCategoryOptions"
                   :placeholder="$t('EEOC Job Category')"
                   id="eeoc_job_category"
                   rules="required">
      </base-select>
    </div>
    <div class="col-span-6">
      <base-input v-model="model.description"
                  :label="$t('Description')"
                  :placeholder="$t('Department Description')"
                  id="name"
                  rules="max:150">
      </base-input>
    </div>
  </base-form>
</template>
<script>
  import axios from 'axios'

  export default {
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        showCancel: true,
        loading: false,
        model: {
          code: '',
          description: '',
          eeoc_job_category: 'none-or-not-applicable',
        },
        jobCategoryOptions: [
          {
            label: this.$t('None/Not Applicable'),
            value: 'none-or-not-applicable',
          },
          {
            label: this.$t('Executive/Senior Level Officials and Managers'),
            value: 'executive-or-senior-level-officials-and-managers',
          },
          {
            label: this.$t('First/Mid-Level Officials and Managers'),
            value: 'first-or-mid-level-officials-and-managers',
          },
          {
            label: this.$t('Professionals'),
            value: 'professionals',
          },
          {
            label: this.$t('Technicians'),
            value: 'technicians',
          },
          {
            label: this.$t('Sales Workers'),
            value: 'sales-workers',
          },
          {
            label: this.$t('Administrative Support Workers'),
            value: 'administrative-support-workers',
          },
          {
            label: this.$t('Craft Workers'),
            value: 'craft-workers',
          },
          {
            label: this.$t('Operatives'),
            value: 'operatives',
          },
          {
            label: this.$t('Laborers and Helpers'),
            value: 'laborers-and-helpers',
          },
          {
            label: this.$t('Service Workers'),
            value: 'service-workers',
          },
        ],
      }
    },
    methods: {
      async onSubmit() {
        try {
          this.loading = true
          if (this.model.id) {
            await axios.put(`/restify/departments/${this.model.id}`, this.model)
            this.$success(this.$t('Department code updated'))
            this.$emit('save', this.model)
          } else {
            const { data } = await axios.post('/restify/departments', this.model)
            this.$success(this.$t('Department code added'))
            this.$createAnotherEntity ? this.$emit('create-another') : this.$emit('save', data)
          }
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not update the department code'))
        } finally {
          this.loading = false
        }
      },
    },
    watch: {
      data: {
        immediate: true,
        handler(value) {
          if (!value?.id) {
            return
          }
          this.model = {
            ...this.model,
            ...value.attributes,
          }
        },
      },
    },
  }
</script>
