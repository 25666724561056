<template>
  <ProofListing
      :url-params="urlParams"
      :entity="entity"
      :url="url"
      :post-url="postUrl"
      :add-post-params="addPostParams"
      @data-fetch="data = $event"
      @success="onPosted"
  >
    <template #default="{loading}">
      <AgDataTable
          :data="mappedItems"
          :columns="columns"
          :dataLoading="loading"
          :pagination="false"
          :groupIncludeFooter="true"
          :groupIncludeTotalFooter="true"
          :suppressAggFuncInHeader="true"
          :groupDefaultExpanded="-1"
          :compact="true"
          :noBorders="true"
          groupDisplayType="groupRows"
          dom-layout="autoHeight"
      />
      <div class="mt-8">
        <h5 class="form-section-title">
          {{ $t('Account Summary') }}
        </h5>
        <ProofListingAccountSummary
            :data="data.account_summary"
            :loading="loading"
        />
      </div>
    </template>
  </ProofListing>
</template>
<script lang="ts">
  import { defineComponent, PropType } from 'vue'
  import { formatLineItemCode } from '@/utils/utils'
  import { Column } from '@/components/ag-grid/tableTypes'
  import ProofListing from '@/modules/common/components/proof-listing/ProofListing'
  import ProofListingAccountSummary from '@/modules/common/components/ProofListingAccountSummary'
  import {PlusIcon} from "vue-feather-icons";
  import axios from "axios";
  import {useStorage} from "@vueuse/core";

  export default defineComponent({
    components: {
      PlusIcon,
      ProofListing,
      ProofListingAccountSummary,
    },
    props: {
      entity: {
        type: String as PropType<string>,
        required: true,
        default: 'vendor-retentions',
      },
      url: {
        type: String as PropType<string>,
        required: true,
        default: '/restify/vendor-retentions/actions?action=release-retention-proof-listing',
      },
      postUrl: {
        type: String as PropType<string>,
        required: true,
        default: '/restify/vendor-retentions/actions?action=release-retention',
      },
      urlParams: {
        type: Object as PropType<Record<string, any>>,
        required: true,
        default: () => ({}),
      },
      mapPostPayload: {
        type: Function as PropType<(params: Record<string, any>) => Record<string, any>>,
        required: true,
      },
      mapData: {
        type: Function as PropType<(data: Record<string, any>) => Record<string, any>>,
        required: true,
      },
    },
    data() {
      return {
        loading: false as boolean,
        releaseRetentionLoading: false as boolean,
        data: {} as Record<string, any>,
      }
    },
    computed: {
      columns() {
        return [
          {
            headerName: this.$t('Job'),
            field: 'job.description',
            rowGroup: true,
            hide: true,
          },
          {
            headerName: this.$t('Vendor'),
            field: 'vendor.id',
            minWidth: 150,
            component: 'VendorLink',
          },
          {
            headerName: this.$t('Invoice Date'),
            field: 'invoice_date',
            minWidth: 120,
            maxWidth: 120,
            align: 'center',
            component: 'FormattedDate',
          },
          {
            headerName: this.$t('Line Item'),
            field: 'addl_source_id',
            minWidth: 150,
            maxWidth: 320,
            valueFormatter: params => {
              if (params.node?.footer && params.node?.level === 0) {
                return this.$t('Invoice Totals:')
              } else if (params.node?.footer && params.node?.level === -1) {
                return this.$t('Batch Totals:')
              }
              return formatLineItemCode(params.data, true)
            },
            component: 'AddlSourceLink',
            cellRendererParams: {
              showDescription: true,
            },
            cellClass: params => {
              if (params.node.footer) {
                return 'flex items-center justify-end'
              }
              return 'truncate'
            },
          },
          {
            headerName: this.$t('Gross Amount'),
            field: 'amount_to_date',
            minWidth: 120,
            maxWidth: 180,
            align: 'right',
            component: 'FormattedPrice',
            aggFunc: 'sum',
          },
          {
            headerName: this.$t('Retention Withheld'),
            field: 'retention_amount',
            minWidth: 120,
            maxWidth: 180,
            align: 'right',
            component: 'FormattedPrice',
            aggFunc: 'sum',
          },
          {
            headerName: this.$t('Release Retention'),
            field: 'release_retention_amount',
            minWidth: 120,
            maxWidth: 180,
            align: 'right',
            component: 'FormattedPrice',
            aggFunc: 'sum',
          },
        ] as Column[]
      },
      mappedItems() {
        return this.mapData(this.data)
      },
    },
    methods: {
      addPostParams(payload: Record<string, any>) {
        return this.mapPostPayload(payload, this.data)
      },
      async onPosted() {
        this.$emit('on-posted')
      },
    },
  })
</script>
