<template>
  <div v-loading="journalLoading">
    <div v-if="journal.id">
      <JobTransactionEntries
          :type="journal.attributes?.type"
          :data="journal.attributes"
          :read-only="true"
      />
    </div>
  </div>
</template>
<script>
  import JobTransactionEntries from '@/modules/job-costing/components/JobTransactionEntries.vue'

  export default {
    components: {
      JobTransactionEntries,
    },
    computed: {
      journal() {
        return this.$store.state.generalLedger.currentJournal
      },
      journalLoading() {
        return this.$store.state.generalLedger.journalLoading
      },
      getTotal() {
        const total = this.journal.attributes.meta.batch_total
        return this.$formatPrice(total)
      },
    },
  }
</script>
