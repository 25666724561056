<template>
  <div class="h-full flex-1">
    <TransactionsTable
        :url-params="urlParams"
        :showPeriodFilter="showPeriodFilter"
    />
    <base-back-link to="/ledger/accounts"/>
  </div>
</template>
<script>
  import AccountSelect from '@/modules/ledger/components/AccountSelect'
  import TransactionsTable from '@/modules/ledger/components/TransactionsTable'

  export default {
    components: {
      AccountSelect,
      TransactionsTable,
    },
    data() {
      return {
        period: '',
        showPeriodFilter: true,
      }
    },
    computed: {
      urlParams() {
        return {
          account_id: this.$route.params.id
        }
      },
    },
  }
</script>
