<template>
  <AgDataTable
    :url="url"
    :url-params="finalUrlParams"
    :columns="columns"
    :pagination="false"
    :default-filters="false"
    :transform-data="transformData"
    :groupRowRendererParams="groupRowRendererParams"
    groupDisplayType="groupRows"
    actions="search,refresh,print"
    domLayout="autoHeight"
  >
    <template #activity="{row}">
      <SettingsActivityLogRows :row="row"/>
    </template>
  </AgDataTable>
</template>
<script lang="ts">
import Data = API.Data;
import {defineComponent} from 'vue'
import {Column} from '@/components/ag-grid/tableTypes'
import SettingsActivityLogRows from '@/modules/common/components/SettingsActivityLogRows.vue'
import SettingsActivityLogGroupRow from '@/modules/common/components/SettingsActivityLogGroupRow.vue'

export default defineComponent({
  components: {
    SettingsActivityLogRows,
    SettingsActivityLogGroupRow,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    urlParams: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    finalUrlParams() {
      return {
        sort: '-created_at',
        ...this.urlParams,
      }
    },
    columns(): Column[] {
      return [
        {
          headerName: 'Activity',
          field: 'id',
          autoHeight: true,
          rowGroup: true,
          hide: true,
        },
        {
          headerName: 'Activity',
          field: 'activity',
          autoHeight: true,
        },
      ]
    },
    groupRowRendererParams(): { innerRenderer: string, module: string | undefined } {
      return {
        suppressCount: true,
        innerRenderer: 'SettingsActivityLogGroupRow',
        name: this.name,
      }
    },
  },
  methods: {
    transformData(data: Data<any>[]) {
      return data.filter(row => {
        const {current, prior} = row?.attributes
        return Object.keys(current).length || Object.keys(prior).length
      })
    },
  },
})
</script>
