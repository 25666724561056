<template>
  <base-data-table
      v-bind="defaultAttributes"
      :columns="columns"
      :data="rows"
      :filters="filters"
      :total-rows="rowsLength"
      @force-pagination="forcePagination"
  >
    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>
    <template #header="{row}">
      <template v-if="row.level === 0">
        {{ row?.header?.label }}
      </template>
      <template v-else-if="row.level === 1">
        <div class="flex items-center space-x-2 pl-2">
          <span>{{ $t(groupHeaderLabel) }}</span>
          <component
            :is="headerGroupComponent"
            :data="row.header"
            :show-preview="false"
          />
        </div>
      </template>
    </template>
    <template #subtotal="{subtotal}">
      <td colspan="2" align="center">
        {{ $t(groupHeaderLabel) }}
        {{ $t('Totals') }}
      </td>
      <td align="right">{{ subtotal.regular_hours }}</td>
      <td align="right">{{ subtotal.total_hours }}</td>
      <td align="right">{{ $formatPrice(subtotal.subject_to) }}</td>
      <td align="right">{{ $formatPrice(subtotal.overtime_exempt) }}</td>
      <td align="right">{{ $formatPrice(subtotal.premium_exempt) }}</td>
      <td align="right">{{ $formatPrice(subtotal.other_exempt) }}</td>
      <td align="right">{{ $formatPrice(subtotal.total_earnings) }}</td>
      <td align="right"/>
      <td align="right">{{ $formatPrice(subtotal.calculated) }}</td>
    </template>
    <template #html-row="{htmlData}">
      <tr class="top-border">
        <td colspan="11" class="subtotal-header">
          {{ $t('Summary by Work/Comp Code') }}
        </td>
      </tr>
      <template v-for="(codes, state) in htmlData" >
        <tr :key="state">
          <td colspan="11">
            {{state}}
          </td>
        </tr>
        <tr v-for="{totals, workers_comp_rate} in codes">
          <td>
            <router-link :to="`/payroll/rates/worker-compensation-rates?id=${workers_comp_rate?.id}`">
              {{ workers_comp_rate?.code }}
            </router-link>
          </td>
          <td>
            <router-link
              v-if="workers_comp_rate.description"
              :to="`/payroll/rates/worker-compensation-rates?id=${workers_comp_rate?.id}`"
            >
              {{ workers_comp_rate.description }}
            </router-link>
            <span v-else/>
          </td>
          <td align="right">{{ totals.regular_hours }}</td>
          <td align="right">{{ totals.total_hours }}</td>
          <td align="right">{{ $formatPrice(totals.subject_to) }}</td>
          <td align="right">{{ $formatPrice(totals.overtime_exempt) }}</td>
          <td align="right">{{ $formatPrice(totals.premium_exempt) }}</td>
          <td align="right">{{ $formatPrice(totals.other_exempt) }}</td>
          <td align="right">{{ $formatPrice(totals.total_earnings) }}</td>
          <td align="right"/>
          <td align="right">{{ $formatPrice(totals.calculated) }}</td>

        </tr>
      </template>
    </template>
  </base-data-table>
</template>
<script>
  import { workerCompSummarySortBy } from '@/modules/payroll/components/reports/util'
  import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper.vue'

  export default{
    extends: ReportTableWrapper,
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      filters: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        columns: [
          {
            label: this.$t('Work/Comp <br> Code'),
            prop: 'workers_comp_rate.code',
            minWidth: 60,
            maxWidth: 80,
            component: 'EntityLink',
            redirectTo: '/payroll/rates/worker-compensation-rates?id={ID}',
            entityKey: 'workers_comp_rate.id',
          },
          {
            label: this.$t('Description'),
            prop: 'workers_comp_rate.description',
            minWidth: 120,
            maxWidth: 160,
            component: 'EntityLink',
            redirectTo: '/payroll/rates/worker-compensation-rates?id={ID}',
            entityKey: 'workers_comp_rate.id',
            summary: () => this.$t('Totals All Codes'),
          },
          {
            label: this.$t('Regular <br> Hours'),
            prop: 'totals.regular_hours',
            minWidth: 60,
            maxWidth: 80,
            align: 'right',
            summary: () => this.grandTotals?.regular_hours,
            hideZero: true,
          },
          {
            label: this.$t('Total <br> Hours'),
            prop: 'totals.total_hours',
            minWidth: 60,
            maxWidth: 80,
            align: 'right',
            summary: () => this.grandTotals?.total_hours,
            hideZero: true,
          },
          {
            label: this.$t(`Total Pay <br> Subject: ${ this.filters.total_on }`),
            prop: 'totals.subject_to',
            minWidth: 100,
            maxWidth: 120,
            component: 'FormattedPrice',
            align: 'right',
            summary: () => {
              return this.$formatPrice(this.grandTotals?.subject_to)
            },
            hideZero: true,
          },
          {
            label: this.$t('Overtime <br> Exempt'),
            prop: 'totals.overtime_exempt',
            minWidth: 60,
            maxWidth: 80,
            component: 'FormattedPrice',
            align: 'right',
            summary: () => {
              return this.$formatPrice(this.grandTotals?.overtime_exempt)
            },
            hideZero: true,
          },
          {
            label: this.$t('Premtime <br> Exempt'),
            prop: 'totals.premium_exempt',
            minWidth: 60,
            maxWidth: 80,
            component: 'FormattedPrice',
            align: 'right',
            summary: () => {
              return this.$formatPrice(this.grandTotals?.premium_exempt)
            },
            hideZero: true,
          },
          {
            label: this.$t('Other <br> Exempt'),
            prop: 'totals.other_exempt',
            minWidth: 60,
            maxWidth: 80,
            align: 'right',
            summary: () => {
              return this.$formatPrice(this.grandTotals?.other_exempt)
            },
            hideZero: true,
          },
          {
            label: this.$t('Pay Subject<br> To W/Comp'),
            prop: 'totals.total_earnings',
            minWidth: 100,
            maxWidth: 120,
            component: 'FormattedPrice',
            align: 'right',
            summary: () => {
              return this.$formatPrice(this.grandTotals?.total_earnings)
            },
            hideZero: true,
          },
          {
            label: this.$t('Rate or <br> Percent'),
            prop: 'totals.percent',
            minWidth: 60,
            maxWidth: 80,
            component: 'FormattedPercent',
            align: 'right',
            summary: () => {
              return this.$formatPercent(this.grandTotals?.percent)
            },
            hideZero: true,
          },
          {
            label: this.$t('Calculated <br> Premiums'),
            prop: 'totals.calculated',
            minWidth: 100,
            maxWidth: 120,
            component: 'FormattedPrice',
            align: 'right',
            summary: () => {
              return this.$formatPrice(this.grandTotals?.calculated)
            },
            hideZero: true,
          },
        ],
      }
    },
    computed: {
      headerGroupComponent() {
        const componentMap = {
          [workerCompSummarySortBy.Employee]: 'EmployeeLink',
          [workerCompSummarySortBy.Job]: 'JobLink',
          default: 'EmployeeLink',
        }
        return componentMap[this.filters.sort_by] || componentMap.default
      },
      sortByEmployee() {
        return this.filters.sort_by === workerCompSummarySortBy.Employee
      },
      groupHeaderLabel() {
        return this.sortByEmployee ? 'Employee' : 'Job'
      },
      grandTotals() {
        const totals = {}
        for (let state in this.data.totals) {
          const row = this.data.totals[state]
          for (let key in row) {
            totals[key] = totals[key] ? totals[key] + row[key] : row[key]
          }
        }
        return totals
      }
    },
    methods: {
      composeRows(data) {
        const groupHeaderKey = this.sortByEmployee ? 'employee' : 'job'

        for(let state in data) {
          this.rows.push({
            header: {
              label: state,
            },
            level: 0,
          })
          for (let employee in data[state]) {
            const group = data[state][employee]
            const {workers_comp_rates, totals: subtotal} = group
            if (workers_comp_rates.length === 0) {
              return
            }
            const header = group[groupHeaderKey]
            if (header?.id) {
              // @ts-ignore
              this.rows.push({
                header,
                level: 1,
              })
            }
            // @ts-ignore
            this.rows.push(...workers_comp_rates)
            // @ts-ignore
            this.rows.push({
              subtotal,
            })
          }
        }
        const summary = this.data.summary || {}
        const summaryRows = []
        this.rows.push({
          htmlData: summary
        })
      },
    },
  }
</script>
