<template>
  <base-input :label="$t('Start Time')" class="start-time-input">
    <template #container>
      <el-time-select
        v-bind="$attrs"
        v-on="$listeners"
        :picker-options="{
          start,
          step,
          end,
        }"
        :placeholder="$t('Select Start Time')"
        class="w-full"
      >
      </el-time-select>
    </template>
  </base-input>
</template>
<script>
import { TimeSelect } from "element-ui";

export default {
  components: {
    [TimeSelect.name]: TimeSelect
  },
  computed: {
    roundInterval() {
      return this.$settings('timesheet', 'round_interval') || 15
    },
    step() {
      return `00:${this.roundInterval}`
    },
    start() {
      return this.$settings('timesheet', 'business_hours_start') || '08:00'
    },
    end() {
      return this.$settings('timesheet', 'business_hours_end') || '18:00'
    }
  }
}
</script>
<style>
.start-time-input .el-date-editor {
  width: 100% !important;
}
</style>
