<template>
  <div class="flex relative w-full lg:w-auto items-center overflow-x-auto space-x-2 flex-1 print:hidden">
    <base-tooltip v-for="(row) in selectedRows"
                  :key="row.id"
                  :content="get(row, labelKey)"
                  class="inline-flex"
    >
      <div
          class="inline-flex items-center px-2 py-1 rounded-md text-sm font-medium bg-gray-50 border border-gray-200 text-gray-800 filter-tag"
      >
        <span class="truncate ml-1 capitalize">{{ get(row, labelKey) }}</span>
        <span
            class="h-4 w-4 close-icon block ml-2 cursor-pointer bg-gray-200 hover:bg-gray-300 hover:text-gray-800 rounded flex justify-center items-center">
          <svg class="h-3 w-3" xmlns="http://www.w3.org/2000/svg" fill="none"
               viewBox="0 0 24 24"
               stroke="currentColor"
               aria-hidden="true"
               @click="$emit('remove', row)"
          >
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
        </svg>
        </span>
      </div>
    </base-tooltip>
    <div class="absolute right-0">
      <BaseButton
          variant="white"
          size="xs"
          @click="$emit('remove-all')"
      >
        {{ $t('Clear All') }}
      </BaseButton>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      selectedRows: {
        type: Array,
        default: () => []
      },
      labelKey: {
        type: String,
        default: 'attributes.name'
      }
    },
  }
</script>
<style scoped lang="scss">
  .filter-tag {
    max-width: 220px;
  }

  .close-icon {
    min-width: 16px;
  }
</style>
