<template>
  <div class="mt-10 sm:mt-0">
    <portal to="page-title">
      {{$t('Email Template - ')}} {{model.subject}}
    </portal>
    <base-form
        :loading="loading"
        :showBack="showBack"
        :focus-on-first-input="false"
        :save-text="$t('Update email template')"
        layout="vertical"
        @cancel="$emit('cancel')"
        @submit="onSubmit"
    >
      <div class="col-span-6">
        <base-input v-model="model.subject"
                    :name="$t('Subject')"
                    :label="$t('Subject')"
                    :placeholder="$t('Subject of the email')"
                    rules="required">
        </base-input>
      </div>

      <div class="col-span-6">
        <base-input v-model="model.from_address"
                    :label="$t('From Email')"
                    :placeholder="$t('From Email')"
                    id="from"
                    rules="email">
        </base-input>
      </div>

      <div class="col-span-6">
        <base-input v-model="model.from_name"
                    :label="$t('From Name')"
                    :placeholder="$t('From Name')"
                    id="from_name">
        </base-input>
      </div>
      <div class="col-span-6">
        <base-input :label="$t('Content')"
                    rules="required">
          <template v-slot:container>
            <html-editor v-model="model.html"
                         :variables="placeholders"
                         :placeholder="$t('Content of the email')"
                         id="content">
            </html-editor>
          </template>
        </base-input>
      </div>
    </base-form>
  </div>
</template>
<script>
  import axios from 'axios'
  import { convertToRedactorHtml, parseRedactorHtml } from "@/modules/settings/pages/emailUtils";

  export default {
  data() {
    return {
      loading: false,
      showBack: true,
      model: {
        subject: '',
        html: '',
        from_address: '',
        from_name: '',
      },
      placeholders: []
    }
  },
  methods: {
    async onSubmit() {
      try {
        this.loading = true
        const data = {
          ...this.model,
        }
        data.html = parseRedactorHtml(data.html)
        await axios.put(`/restify/mail-templates/${this.$route.params.id}`, data);
        this.$success(this.$t('Email template updated'))
        this.$emit('save', this.model)
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(this.$t('Could not update the email template'))
      } finally {
        this.loading = false
      }
    },
    async getEmailTemplate() {
      const { data } = await axios.get(`/restify/mail-templates/${this.$route.params.id}`)
      this.model = data.attributes
      this.model.html = convertToRedactorHtml(this.model.html)
      await this.$nextTick();
      this.model.subject = data.attributes.subject
      const placeholders = await axios.get(`/restify/mail-templates/${this.$route.params.id}/placeholders`)
      this.placeholders = Object.keys(placeholders.data)
    },
  },
  created() {
    this.getEmailTemplate()
  }
}
</script>
