<template>
  <AccountLink
      v-bind="params"
      :number="params.value"
      :data="row"
      :is-link="!editableCell"
  />
</template>
<script>
  import AccountLink from '@/components/links/AccountLink';

  export default {
    components: {
      AccountLink,
    },
    props: {
      column: {
        type: Object,
        default: () => ({}),
      },
      row: {
        type: Object,
        default: () => ({}),
      },
      params: {
        type: Object,
        default: () => ({}),
      },
      editableCell: Boolean,
    },
  }
</script>
