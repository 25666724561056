<template>
  <JobProfitReport v-bind="$attrs"/>
</template>
<script>
  import JobProfitReport from '@/modules/job-costing/components/reports/job-profit-summary/JobProfitReport'

  export default {
    components: {
      JobProfitReport,
    },
  }
</script>
