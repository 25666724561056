import Vue from 'vue'
import get from 'lodash/get'
import NotificationsPlugin from "@/components/common/NotificationPlugin";
import AuthService from "@/modules/auth/services/AuthService";
import UserService from "@/modules/auth/services/UserService";
import { userRoles } from "@/enum/enums";

Vue.use(NotificationsPlugin)
const app = new Vue({})
const $error = app.$error

const types = {
  SET_USER: 'SET_USER',
  SET_USER_META: 'SET_USER_META',
  SET_USER_COMPANIES: 'SET_USER_COMPANIES',
  SET_IS_LOGGED_IN: 'SET_IS_LOGGED_IN',
  SET_USER_ORGANIZATION: 'SET_USER_ORGANIZATION',
  SET_GLOBAL_LOGIN_RESPONSE: 'SET_GLOBAL_LOGIN_RESPONSE',
  SET_USER_COMPANY_SETTINGS: 'SET_USER_COMPANY_SETTINGS',
};
const state = {
  user: {},
  userMeta: {},
  userCompanies: [],
  organization: {},
  globalLoginResponse: {},
  permissions: [],
  userCompanySettings: [],
  isLoggedIn: localStorage.getItem('token') || false,
};

const mutations = {
  [types.SET_USER]: (state, value) => {
    state.user = value;
  },
  [types.SET_USER_META]: (state, value) => {
    state.userMeta = value;
  },
  [types.SET_IS_LOGGED_IN]: (state, value) => {
    state.isLoggedIn = value;
  },
  [types.SET_USER_ORGANIZATION]: (state, value) => {
    state.organization = value;
  },
  [types.SET_USER_COMPANIES]: (state, value) => {
    state.userCompanies = value
  },
  [types.SET_GLOBAL_LOGIN_RESPONSE]: (state, value) => {
    state.globalLoginResponse = value
  },
  [types.SET_USER_COMPANY_SETTINGS]: (state, value) => {
    state.userCompanySettings = value
  },
};

const actions = {
  async login({ commit, dispatch }, data) {
    try {
      await AuthService.login(data);
      commit(types.SET_IS_LOGGED_IN, true);
      await Promise.all([
        dispatch('refreshProfile'),
        dispatch('getOrganization'),
      ])
    } catch (err) {
      if (!err.handled) {
        $error('An error occurred during login. Please try again or contact us')
      }
      throw err
    }
  },

  async loginWithToken({ commit, dispatch }, token) {
    AuthService.setToken(token);
    commit(types.SET_IS_LOGGED_IN, true);
    await Promise.all([
      dispatch('refreshProfile'),
      dispatch('getOrganization'),
    ])
  },

  async globalLogin({ commit }, data) {
    try {
      const result = await AuthService.globalLogin(data);
      commit(types.SET_GLOBAL_LOGIN_RESPONSE, result);
      return result
    } catch (err) {
      if (err.response.status !== 400) {
        $error('An error occurred during login. Please try again or contact us')
      }
      console.warn(err)
      throw err
    }
  },
  async registerConfirm({ commit, dispatch }, { data, params }) {
    try {
      const response = await AuthService.registerConfirm(data, params);
      commit(types.SET_IS_LOGGED_IN, true);
      await Promise.all([
        dispatch('refreshProfile'),
        dispatch('getOrganization'),
      ])
      return response
    } catch (err) {
      console.warn(err)
      throw err
    }
  },
  async logout({ commit }) {
    commit(types.SET_IS_LOGGED_IN, false);
    commit(types.SET_USER, {});
    commit(types.SET_USER_ORGANIZATION, {});
    commit(types.SET_USER_META, {});
    commit(types.SET_USER_COMPANIES, []);
    await AuthService.logout();
  },
  async refreshProfile({ commit, dispatch }, setCompany = true) {
    try {
      await dispatch('company/getCurrentCompany', {}, { root: true })
      const userResult = await UserService.getProfile();
      commit(types.SET_IS_LOGGED_IN, true);
      const user = get(userResult, 'data', {})
      commit(types.SET_USER, user);
      commit(types.SET_USER_META, userResult.meta);
      if (!setCompany) {
        return
      }
      commit(types.SET_USER_COMPANIES, user.current_organization_companies)
    } catch (err) {
      console.warn(err)
    }
  },
  async getOrganization({ commit }) {
    try {
      if (!app.$can('organization_show')) {
        return
      }
      const result = await UserService.getOrganization();
      commit(types.SET_USER_ORGANIZATION, result.data);
    } catch (err) {
      console.warn(err)
    }
  },
  async getUserCompanySettings({ commit}) {
    try {
      const { data } = await UserService.getUserCompanySettings();
      commit(types.SET_USER_COMPANY_SETTINGS, data);
    } catch (err) {
      console.warn(err)
    }
  },
};
const getters = {
  user: state => state.user,
  companyRoles: state => {
    return state.user?.company_roles?.map(c => c.name) || []
  },
  organizationRoles: state => {
    return state.user?.organization_roles?.map(c => c.name) || []
  },
  companyPermissions: state => {
    const companyRoles = state.user?.company_roles || []
    let permissions = []
    companyRoles.forEach(role => {
      permissions = [...permissions, ...role.permissions]
    })
    return [...new Set(permissions)]
  },
  organizationPermissions: state => {
    const organizationRoles = state.user?.organization_roles || []
    let permissions = []
    organizationRoles.forEach(role => {
      permissions = [...permissions, ...role.permissions]
    })
    return [...new Set(permissions)]
  },
  permissions: (state, getters) => {
    const companyPermissions = getters.companyPermissions
    const organizationPermissions = getters.organizationPermissions
    const allPermissions = [...companyPermissions, ...organizationPermissions]
    return [...new Set(allPermissions)]
  },
  roles: (state, getters) => {
    const companyRoles = getters.companyRoles
    const organizationRoles = getters.organizationRoles
    return [...companyRoles, ...organizationRoles]
  },
  isCompanyAdmin: (state, getters) => {
    return getters.roles.includes(userRoles.CompanyAdmin) || getters.roles.includes('Company Admin')
  },
  isEmployee: (state, getters) => {
    return getters.roles.includes(userRoles.Employee)
  },
  isEmployeeOnly: (state, getters) => {
    const roles = getters.roles
    return roles.length === 1 && roles.includes(userRoles.Employee)
  },
  userCompanySettings: state => state.userCompanySettings,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
