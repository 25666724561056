<template>
  <base-data-table
      v-bind="defaultAttributes"
      :columns="columns"
      :data="getData"
      :total-rows="rowsLength"
      class="job-cost-detail"
      @force-pagination="forcePagination"
  >

    <template #report-header>
      <BaseReportHeader
          :header="{
            ...data.header,
            format: totalBy,
          }"
      />
    </template>

    <template #header="{row}">
      <JobLink
          v-if="row.header.id"
          :data="row.header"
          :show-preview="false"
      />
      <div v-if="row.header?.type" class="flex">
        <div class="capitalize w-16">
          {{ row.header?.type }}
        </div>
        <div class="w-[250px] max-w-[250px] truncate">
          <LineItemLink :data="{id: row.header.line_item_id, ...row.header}"/>
        </div>
        <span>
          ({{ $t('Budget:') }}
            <span class="mx-10">
              {{ $formatPrice(row.header?.amount) }}
            </span>
            {{ $t('Job-to-Date:') }}
            <span class="mx-10">
            {{ $formatPrice(row.header?.amount_to_date) }}
            </span>
            {{ $t('Remain:') }}
            <span class="mx-10">
              {{ $formatPrice(row.header?.remaining) }})
            </span>
        </span>
      </div>
    </template>

    <template #business="{row}">
      <template v-if="row.business?.id">
        <component
            :is="getBusinessComponent(row.business.type)"
            :data="row.business"
            :show-preview="false"
            :show-description="false"
        />
      </template>
      <span v-else>
          {{ $t('') }}
      </span>
    </template>

    <template #description="{row}">
      <div class="flex justify-start space-x-2">
        <LineItemLink
          v-if="row.cost_code || row.phase_code"
          :data="row"
        />
        <span v-else>{{ row.description }}</span>
      </div>
    </template>

    <template #subtotal="{row}">
      <td colspan="9"/>
      <td align="right" class="top-border">{{ row.subtotal.units }}</td>
      <td align="right" class="top-border">{{ row.subtotal.hours }}</td>
      <td align="right" class="top-border">{{ $formatPrice(row.subtotal.total) }}</td>
    </template>

    <template #footer="{row}">
      <p class="top-border pt-4">
        {{ $t('Job Period Cost:') }}
        <span class="ml-10">{{ $formatPrice(row.footer?.cost?.amount_to_date) }}</span>
      </p>
      <p class="mb-4">
        {{ $t('Job Period Income:') }}
        <span class="ml-10">{{ $formatPrice(row.footer?.income?.amount_to_date) }}</span>
      </p>
      <div v-if="totalBy !== jobCostDetailPrintOptions.History"
           class="flex w-full">
        <SummaryMinimalTable
            :summary="row.footer"
            class="max-w-[1200px]"
        />
      </div>
    </template>

  </base-data-table>
</template>
<script>
  import { transactionBusinessLinkByTypes } from '@/enum/enums'
  import { jobCostDetailPrintOptions } from '@/modules/job-costing/components/reports/util'
  import SummaryMinimalTable
    from '@/modules/job-costing/components/reports/job-cost-detail/by-line-item/SummaryMinimalTable'
  import {
    getColumnsByFormat,
    mapDataByFormat
  } from '@/modules/job-costing/components/reports/job-cost-detail/by-line-item/util'
  import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'
  import { formatLineItemCode } from "@/utils/utils";

  export default {
    extends: ReportTableWrapper,
    components: {
      SummaryMinimalTable,
    },
    props: {
      totalBy: {
        type: String,
        default: jobCostDetailPrintOptions.HistoryAndBudgets,
      },
      viewPhaseTotals: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        jobCostDetailPrintOptions,
      }
    },
    computed: {
      columns() {
        return getColumnsByFormat(this.totalBy)
      },
    },
    methods: {
      formatLineItemCode,
      composeRows(data) {
        this.rows = mapDataByFormat(this.totalBy, data)
      },
      getBusinessComponent(businessType) {
        return transactionBusinessLinkByTypes[businessType]
      },
    },
  }
</script>
