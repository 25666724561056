import IconAccountsPayable from '~icons/custom/IconAccountsPayable'
import IconAccountsReceivable from '~icons/custom/IconAccountsReceivable'
import IconAdd from '~icons/custom/IconAdd'
import IconArrowDown from '~icons/custom/IconArrowDown'
import IconArrowLeft from '~icons/custom/IconArrowLeft'
import IconArrowRight from '~icons/custom/IconArrowRight'
import IconBank from '~icons/custom/IconBank'
import IconCalendar from '~icons/custom/IconCalendar'
import IconCheck from '~icons/custom/IconCheck'
import IconCheckBatches from '~icons/custom/IconCheckBatches'
import IconClose from '~icons/custom/IconClose'
import IconCompanies from '~icons/custom/IconCompanies'
import IconCustomers from '~icons/custom/IconCustomers'
import IconDashboard from '~icons/custom/IconDashboard'
import IconDiscount from '~icons/custom/IconDiscount'
import IconDiscountDate from '~icons/custom/IconDiscountDate'
import IconDocumentManagement from '~icons/custom/IconDocumentManagement'
import IconDocumentSearch from '~icons/custom/IconDocumentSearch'
import IconEmail from '~icons/custom/IconEmail'
import IconEmployees from '~icons/custom/IconEmployees'
import IconEquipment from '~icons/custom/IconEquipment'
import IconEstimation from '~icons/custom/IconEstimation'
import IconExternalLink from '~icons/custom/IconExternalLink'
import IconGeneralLedger from '~icons/custom/IconGeneralLedger'
import IconInfo from '~icons/custom/IconInfo'
import IconInventory from '~icons/custom/IconInventory'
import IconInvoices from '~icons/custom/IconInvoices'
import IconJobCosting from '~icons/custom/IconJobCosting'
import IconJobs from '~icons/custom/IconJobs'
import IconJournals from '~icons/custom/IconJournals'
import IconLogout from '~icons/custom/IconLogout'
import IconMaps from '~icons/custom/IconMaps'
import IconMyChecks from '~icons/custom/IconMyChecks'
import IconNote from '~icons/custom/IconNote'
import IconOfficeBuildings from '~icons/custom/IconOfficeBuildings'
import IconOpenAmount from '~icons/custom/IconOpenAmount'
import IconPause from '~icons/custom/IconPause'
import IconPaymentsHold from '~icons/custom/IconPaymentsHold'
import IconPayroll from '~icons/custom/IconPayroll'
import IconPhone from '~icons/custom/IconPhone'
import IconPlus from '~icons/custom/IconPlus'
import IconRetention from '~icons/custom/IconRetention'
import IconRoles from '~icons/custom/IconRoles'
import IconSalesLastYear from '~icons/custom/IconSalesLastYear'
import IconSalesThisYear from '~icons/custom/IconSalesThisYear'
import IconSalesToDate from '~icons/custom/IconSalesToDate'
import IconSearch from '~icons/custom/IconSearch'
import IconServiceBilling from '~icons/custom/IconServiceBilling'
import IconServiceInvoices from '~icons/custom/IconServiceInvoices'
import IconSettings from '~icons/custom/IconSettings'
import IconSpecialTasks from '~icons/custom/IconSpecialTasks'
import IconStatements from '~icons/custom/IconStatements'
import IconUsers from '~icons/custom/IconUsers'
import IconVendors from '~icons/custom/IconVendors'
import IconWarehouse from '~icons/custom/IconWarehouse'
import IconWarning from '~icons/custom/IconWarning'
import IconView from '~icons/custom/IconView'
import IconPaid from '~icons/custom/IconPaid'
import IconLate from '~icons/custom/IconLate'
import IconSent from '~icons/custom/IconSent'
import IconDraft from '~icons/custom/IconDraft'
import IconFilter from '~icons/custom/IconFilter'
import IconPreset from '~icons/custom/IconPreset'
import IconFile from '~icons/custom/IconFile'
import IconPrinter from '~icons/custom/IconPrinter'
import IconRefresh from '~icons/custom/IconRefresh'

export default {
  install(Vue) {
    Vue.component('IconAccountsPayable', IconAccountsPayable)
    Vue.component('IconAccountsReceivable', IconAccountsReceivable)
    Vue.component('IconAdd', IconAdd)
    Vue.component('IconArrowDown', IconArrowDown)
    Vue.component('IconArrowLeft', IconArrowLeft)
    Vue.component('IconArrowRight', IconArrowRight)
    Vue.component('IconBank', IconBank)
    Vue.component('IconCalendar', IconCalendar)
    Vue.component('IconCheck', IconCheck)
    Vue.component('IconCheckBatches', IconCheckBatches)
    Vue.component('IconClose', IconClose)
    Vue.component('IconCompanies', IconCompanies)
    Vue.component('IconCustomers', IconCustomers)
    Vue.component('IconDashboard', IconDashboard)
    Vue.component('IconDiscount', IconDiscount)
    Vue.component('IconDiscountDate', IconDiscountDate)
    Vue.component('IconDocumentManagement', IconDocumentManagement)
    Vue.component('IconDocumentSearch', IconDocumentSearch)
    Vue.component('IconEmail', IconEmail)
    Vue.component('IconEmployees', IconEmployees)
    Vue.component('IconEquipment', IconEquipment)
    Vue.component('IconEstimation', IconEstimation)
    Vue.component('IconExternalLink', IconExternalLink)
    Vue.component('IconGeneralLedger', IconGeneralLedger)
    Vue.component('IconInfo', IconInfo)
    Vue.component('IconInventory', IconInventory)
    Vue.component('IconInvoices', IconInvoices)
    Vue.component('IconJobCosting', IconJobCosting)
    Vue.component('IconJobs', IconJobs)
    Vue.component('IconJournals', IconJournals)
    Vue.component('IconLogout', IconLogout)
    Vue.component('IconMaps', IconMaps)
    Vue.component('IconMyChecks', IconMyChecks)
    Vue.component('IconNote', IconNote)
    Vue.component('IconOfficeBuildings', IconOfficeBuildings)
    Vue.component('IconOpenAmount', IconOpenAmount)
    Vue.component('IconPause', IconPause)
    Vue.component('IconPaymentsHold', IconPaymentsHold)
    Vue.component('IconPayroll', IconPayroll)
    Vue.component('IconPhone', IconPhone)
    Vue.component('IconPlus', IconPlus)
    Vue.component('IconRetention', IconRetention)
    Vue.component('IconRoles', IconRoles)
    Vue.component('IconSalesLastYear', IconSalesLastYear)
    Vue.component('IconSalesThisYear', IconSalesThisYear)
    Vue.component('IconSalesToDate', IconSalesToDate)
    Vue.component('IconSearch', IconSearch)
    Vue.component('IconServiceBilling', IconServiceBilling)
    Vue.component('IconServiceInvoices', IconServiceInvoices)
    Vue.component('IconSettings', IconSettings)
    Vue.component('IconSpecialTasks', IconSpecialTasks)
    Vue.component('IconStatements', IconStatements)
    Vue.component('IconUsers', IconUsers)
    Vue.component('IconVendors', IconVendors)
    Vue.component('IconWarehouse', IconWarehouse)
    Vue.component('IconWarning', IconWarning)
    Vue.component('IconView', IconView)
    Vue.component('IconPaid', IconPaid)
    Vue.component('IconSent', IconSent)
    Vue.component('IconLate', IconLate)
    Vue.component('IconDraft', IconDraft)
    Vue.component('IconFilter', IconFilter)
    Vue.component('IconPreset', IconPreset)
    Vue.component('IconFile', IconFile)
    Vue.component('IconPrinter', IconPrinter)
    Vue.component('IconRefresh', IconRefresh)
  }
}
