<template>
  <div>
    <layout-tabs
        v-if="!onViewResourcePage"
        :items="filteredItems"
    />
    <router-view :billing-type="billingType"/>
  </div>
</template>
<script>
  import { formattedText } from '@/utils/utils'
  import { billingTypes } from '@/modules/accounts-receivable/pages/billings/billings'

  export default {
    props: {
      billingType: {
        type: String,
        default: billingTypes.Progress,
      },
    },
    computed: {
      items() {
        const billingTypeText = formattedText(this.billingType)
        const fullText = `All ${billingTypeText} Billings`

        return [
          {
            name: this.$t('Pending'),
            tooltip: this.$t('Billings that were not posted yet'),
            path: `/accounts-receivable/billings/${this.billingType}/pending`,
            permission: 'billings_show',
          },
          {
            name: this.$t('Open'),
            tooltip: this.$t('Posted but not paid'),
            path: `/accounts-receivable/billings/${this.billingType}/open`,
            permission: 'billings_show',
          },
          {
            name: this.$t('Partially Paid'),
            tooltip: this.$t('Partially paid billings'),
            path: `/accounts-receivable/billings/${this.billingType}/partial-paid`,
            permission: 'billings_show',
          },
          {
            name: this.$t('Paid'),
            tooltip: this.$t('Fully paid billings'),
            path: `/accounts-receivable/billings/${this.billingType}/paid`,
            permission: 'billings_show',
          },
          {
            name: this.$t('Voided'),
            tooltip: this.$t('Voided billings'),
            path: `/accounts-receivable/billings/${this.billingType}/voided`,
            permission: 'billings_show',
          },
          {
            name: this.$t(fullText),
            tooltip: this.$t('All Billings'),
            path: `/accounts-receivable/billings/${this.billingType}/all`,
            permission: 'billings_show',
          },
        ]

      },
      filteredItems() {
        return this.items.filter(item => this.$can(item.permission))
      },
    },
  }
</script>
