import i18n from "@/i18n";
import {Column} from "@/components/ag-grid/tableTypes";
import {formattedText} from "@/utils/utils";
import {equipmentTransactionTypes} from "@/enum/equipment";

export const equipmentHistoryColumns = <Column[]>[
  {
    label: i18n.t('Equipment'),
    prop: 'equipment',
    minWidth: 80,
    maxWidth: 80,
    hiddenValue: true,
  },
  {
    label: i18n.t('Description'),
    prop: 'history.description',
    minWidth: 100,
    maxWidth: 140,
  },
  {
    label: i18n.t('Transaction <br> Type'),
    prop: 'history.transaction_type',
    minWidth: 80,
    maxWidth: 100,
  },
  {
    label: i18n.t('Year /<br>Period '),
    prop: 'period_fiscal_year',
    minWidth: 70,
    maxWidth: 70,
  },
  {
    label: i18n.t('Journal'),
    prop: 'journal.name',
    minWidth: 70,
    maxWidth: 70,
  },
  {
    label: i18n.t('Transaction <br> Date'),
    prop: 'history.reference_date',
    minWidth: 80,
    maxWidth: 100,
    component: 'FormattedDate',
  },
  {
    label: i18n.t('Employee <br> Vend/Cust'),
    prop: 'business',
    minWidth: 80,
    maxWidth: 100,
  },
  {
    label: i18n.t('Account'),
    prop: 'account',
    minWidth: 80,
    maxWidth: 80,
  },
  {
    label: i18n.t('Job: <br> Srv:'),
    prop: 'source',
    minWidth: 60,
    maxWidth: 60,
  },
  {
    label: i18n.t('Phase'),
    prop: 'addl_source.phase_code',
    minWidth: 50,
    maxWidth: 50,
    align: 'center',
  },
  {
    label: i18n.t('Code <br> W/O#'),
    prop: 'addl_source.code',
    minWidth: 80,
    maxWidth: 100,
    align: 'center',
  },
  {
    label: i18n.t('CHG <br> Type'),
    prop: 'addl_source.change_order',
    minWidth: 40,
    maxWidth: 40,
    align: 'center',
  },
  {
    label: i18n.t('Hours'),
    prop: 'history.hours',
    minWidth: 60,
    maxWidth: 60,
    align: 'right',
    hideZero: true,
  },
  {
    label: i18n.t('Amount'),
    prop: 'history.amount',
    minWidth: 100,
    maxWidth: 120,
    align: 'right',
    component: 'FormattedPrice',
    hideZero: true,
  },
]

export function getAdditionalSourceLink({addl_source}: Record<string, any>) {
  if (!addl_source) {
    return false
  }

  const additionalSourceTypes: Record<string, string> = {
    ServiceBillingCode: 'service-billing-code',
    MaintenanceCode: 'maintenance-code',
    LineItem: 'line-item',
  }

  const additionalSourceLinks: Record<string, string> = {
    [additionalSourceTypes.ServiceBillingCode]: `/service-billing/settings/codes?id=${addl_source.id}`,
    [additionalSourceTypes.MaintenanceCode]: `/equipment/maintenance-codes?id=${addl_source.id}`,
    [additionalSourceTypes.LineItem]: `/job-costing/${addl_source.cost_or_income}-line-items/${addl_source.id}/view`,
  }

  return additionalSourceLinks[addl_source.type]
}

export function getSourceComponent({source}: Record<string, any>) {
  if (!source) {
    return false
  }

  const sourceTypes: Record<string, string> = {
    Job: 'job',
    WorkOrder: 'work-order',
  }

  const sourceComponents: Record<string, string> = {
    [sourceTypes.Job]: 'JobLink',
    [sourceTypes.WorkOrder]: 'WorkOrderLink',
  }

  return sourceComponents[source.type]
}

export function formatSubtotalForGroup(group: Record<string, any>, totalKey: string = 'totals') {
  const groupTotals = group[totalKey];

  if (Array.isArray(groupTotals)) {
    return groupTotals.map((item: any) => ({
      name: formattedText(item?.equipment_type?.name),
      ...item.totals
    }));
  }

  return Object.keys(groupTotals).map((key: any) => {
    const total = groupTotals[key];

    if (key === equipmentTransactionTypes.ExpenseTransaction && Array.isArray(total)) {
      return total.map((item: any) => ({
        name: formattedText(item?.equipment_type?.name),
        totals: { ...item.totals }
      }));
    }

    return {
      name: formattedText(key),
      ...total
    };
  }).flat();
}

