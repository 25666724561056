<template>
    <div>
        <layout-tabs :items="items"/>
        <div class="py-4">
            <router-view/>
        </div>
    </div>
</template>
<script>
  export default {
    data() {
      return {
        selectedOption: this.$route.path,
        items: [
          {
            name: this.$t('Edit Profile'),
            path: '/profile/edit',
          },
          {
            name: this.$t('Preferences'),
            path: '/profile/preferences',
          },
          {
            name: this.$t('Keyboard Shortcuts'),
            path: '/profile/shortcuts',
          },
        ]
      }
    }
  }
</script>
