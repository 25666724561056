<template>
  <base-data-table
      v-bind="defaultAttributes"
      :columns="columns"
      :data="getData"
      :total-rows="rowsLength"
      @force-pagination="forcePagination"
  >

    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template #subtotal="{subtotal}">
      <td colspan="4" align="right">
        {{ $t('Subtotal:') }}
      </td>

      <td v-for="column in amountColumns" align="right">
        {{ $formatPrice(get(subtotal, column.prop, 0)) }}
      </td>
    </template>

  </base-data-table>
</template>
<script>
  import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'

  export default {
    extends: ReportTableWrapper,
    props: {
      subtotalOn: {
        required: true,
        type: Number,
        default: 0,
      },
    },
    computed: {
      grandTotals() {
        return {
          period: this.get(this.data, 'items[0].period', {}),
          up_to_period: this.get(this.data, 'items[0].up_to_period', {}),
        }
      },
      columns() {
        return [
          {
            label: this.$t('Account'),
            prop: 'account.number',
            minWidth: 60,
            maxWidth: 80,
            component: 'EntityLink',
            redirectTo: '/ledger/accounts/{ID}/view',
          },
          {
            label: this.$t('Sub <br> Account'),
            prop: 'subaccount.number',
            minWidth: 50,
            maxWidth: 70,
            component: 'EntityLink',
            redirectTo: '/ledger/sub-accounts/{ID}/view',
          },
          {
            label: this.$t('Description'),
            prop: 'account.description',
            minWidth: 100,
            maxWidth: 280,
            summary: () => this.$t('Grand Total:'),
          },
          {
            label: this.$t('Type'),
            prop: 'account.type',
            maxWidth: 160,
            component: 'Status',
          },
          {
            label: this.$t('Budget'),
            prop: 'period.budget',
            maxWidth: 160,
            minWidth: 60,
            component: 'FormattedPrice',
            align: 'right',
            summary: () => this.$formatPrice(this.grandTotals.up_to_period.budget),
          },
          {
            label: this.$t('Actual'),
            prop: 'period.actual',
            maxWidth: 160,
            minWidth: 60,
            component: 'FormattedPrice',
            align: 'right',
            summary: () => this.$formatPrice(this.grandTotals.up_to_period.actual),
          },
          {
            label: this.$t('Variance'),
            prop: 'period.variance',
            maxWidth: 160,
            minWidth: 60,
            component: 'FormattedPrice',
            align: 'right',
            summary: () => this.$formatPrice(this.grandTotals.up_to_period.variance),
          },
          {
            label: this.$t('Budget'),
            prop: 'up_to_period.budget',
            maxWidth: 160,
            minWidth: 65,
            component: 'FormattedPrice',
            align: 'right',
            summary: () => this.$formatPrice(this.grandTotals.up_to_period.budget),
          },
          {
            label: this.$t('Actual'),
            prop: 'up_to_period.actual',
            maxWidth: 160,
            minWidth: 65,
            component: 'FormattedPrice',
            align: 'right',
            summary: () => this.$formatPrice(this.grandTotals.up_to_period.actual),
          },
          {
            label: this.$t('Variance'),
            prop: 'up_to_period.variance',
            maxWidth: 160,
            minWidth: 65,
            component: 'FormattedPrice',
            align: 'right',
            summary: () => this.$formatPrice(this.grandTotals.up_to_period.variance),
          },
        ]
      },
    },
    methods: {
      composeRows(data) {
        if (!this.subtotalOn) {
          this.rows = this.get(data, '[0].accounts', [])
        } else {

          data.forEach((item, index) => {

            const { accounts, period, up_to_period } = item

            this.rows.push(...accounts, {
              subtotal: {
                period,
                up_to_period,
              }
            })

            if (data.length - 1 !== index) {
              this.rows.push({
                htmlData: {},
              })
            }
          })
        }

        return this.rows
      },
    }
  }
</script>
