<template>
  <entity-select
      url="/restify/billing-rate-types"
      v-bind="$attrs"
      v-on="$listeners"
      :url-params="urlParams"
      :id="$attrs.id || 'billing-type-select'"
      :filter-options="filterOptions"
      :name="$attrs.name || $t('Billing Rate Type')"
      :placeholder="$attrs.placeholder || $t('Select Billing Rate Type')"
      :label="$attrs.label !== undefined ? $attrs.label : $t('Billing Rate Type')"
      :add-label="$t('Add Billing Rate Type')"
      :add-entity="addEntity"
      :add-temporary-entity="addTemporaryEntity"
      :value-key="valueKey"
      label-key="title"
      class="w-full"
      @data-fetch="onDataFetch"

  />
</template>
<script>
  export default {
    inheritAttrs: false,
    props: {
      excludeIds: {
        type: Array,
        default: () => [],
      },
      addEntity: {
        type: Boolean,
        default: true,
      },
      addTemporaryEntity: {
        type: Boolean,
        default: false,
      },
      urlParams: {
        type: Object,
        default: () => ({
          sort: 'title',
        }),
      },
      valueKey: {
        type: String,
        default: 'id',
      },
      autocomplete: {
        type: Boolean,
        default: true,
      },
    },
    methods: {
      onDataFetch(options) {
        if (!this.autocomplete) {
          return
        }

        const firstOption = options[0]

        if (!firstOption?.id) {
          return
        }

        const value = this.get(firstOption, `attributes[${this.valueKey}]`)

        this.$emit('input', value)
      },
      filterOptions(options) {
        if (this.excludeIds.length) {
          return options.filter(o => {
            return !this.excludeIds.includes(o.id)
          })
        }
        return options
      },
    },
  }
</script>
