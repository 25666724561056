<template>
  <settings-form
      :fields="fields"
      :module-name="moduleName"
      :before-save="saveJobTypes"
      @cancel="$router.push('/job-costing/jobs')"
  >
    <template #default="{model}">
      <div class="col-span-8 md:col-span-4 lg:col-span-2">
        <account-select v-model="model.retention_receivable_account"
                        :label="$t('Retention Receivable Account')"
                        value-key="number"
                        rules="required"
                        warningOnChange
        />
      </div>
      <div class="col-span-8 md:col-span-4 lg:col-span-2">
        <sub-account-select v-model="model.retention_receivable_subaccount"
                            :label="$t('Retention Receivable Sub Account')"
                            value-key="number"
                            clearable
        />
      </div>
      <div class="col-span-8 md:col-span-4 lg:col-span-2">
        <account-select v-model="model.retention_payable_account"
                        :label="$t('Retention Payable Account')"
                        value-key="number"
                        rules="required"
                        warningOnChange
        />
      </div>
      <div class="col-span-8 md:col-span-4 lg:col-span-2">
        <sub-account-select v-model="model.retention_payable_subaccount"
                            :name="$t('')"
                            :label="$t('Retention Payable Sub Account')"
                            value-key="number"
                            clearable
        />
      </div>
      <div class="col-span-8 md:col-span-4 lg:col-span-2">
        <base-select v-model="model.code_edit_option"
            :options="codeEditOptions"
            :label="$t('Line Items Edit Option')"
            :placeholder="$t('Line Items Edit Option')"
        />
      </div>
      <div class="col-span-8 md:col-span-4 lg:col-span-2">
        <base-select v-model="model.round_retention_to"
                     :label="$t('Round Retention to')"
                     :options="roundRetentionTypes"
        />
      </div>
      <div class="col-span-8 md:col-span-4 md:mb-8">
        <label>{{ $t('Allow progress billing to exceed Contract value') }}</label>
        <base-switch v-model="model.progress_billing_exceeds_contract_value"
                     class="mr-4 mt-3"
        />
      </div>

    </template>
    <template #overrides>
      <JobTypesGrid class="col-span-8 mt-6" ref="jobTypes"/>
    </template>
  </settings-form>
</template>
<script>
  import SettingsForm from '@/modules/common/components/SettingsForm'
  import { codeEditOptions } from '@/modules/job-costing/enum/jobCategories'
  import JobTypesGrid from '@/modules/job-costing/components/costing-types/JobTypesGrid'
  import Cache from "@/utils/Cache";

  export default {
    components: {
      SettingsForm,
      JobTypesGrid,
    },
    data() {
      return {
        codeEditOptions,
        moduleName: 'job-costing',
        fields: [
          'code_edit_option',
          'retention_payable_account',
          'retention_payable_subaccount',
          'retention_receivable_account',
          'retention_receivable_subaccount',
          'round_retention_to',
          'progress_billing_exceeds_contract_value',
        ],
        roundRetentionTypes: [
          {
            label: this.$t('Cents'),
            value: 'cents',
          },
          {
            label: this.$t('Dollar'),
            value: 'dollar',
          },
        ],
      }
    },
    methods: {
      async saveJobTypes() {
        Cache.removeForEntity('/restify/job-type-activities')
        return await this.$refs.jobTypes.save()
      },
      async showUnsavedWarning() {
        const showUnsavedWarning = this.$refs?.jobTypes?.showUnsavedWarning
        if (showUnsavedWarning) {
          return await showUnsavedWarning()
        }
        return false
      }
    },
  }
</script>
