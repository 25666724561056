<template>
  <div v-if="batch?.attributes?.pp_confirmed_at" class="ml-2">
    <BaseTooltip>
      <template #content>
        <div>
          {{ $t('Direct Deposit Confirmed at ') }} {{ $formatDate(batch.attributes.pp_confirmed_at, 'date', true) }} {{ $t('by') }}
          {{ user?.name }}.
          <span>{{ $t('Confirmation Code') }}: {{ batch.attributes.pp_confirmation_number }}</span>
        </div>
      </template>
      <StatusBadge :status="$t('Positive Pay Confirmed')"/>
    </BaseTooltip>
  </div>
</template>
<script>
import { globalResources } from "@/components/form/util";

export default {
  props: {
    batch: {
      type: Object,
      required: true
    }
  },
  computed: {
    user() {
      const userId = this.batch?.attributes?.pp_confirmed_by
      const allUsers = this.$store?.getters['globalLists/getResourceList'](globalResources.Users) || []
      return allUsers.find(user => user.id === userId)
    }
  }
}
</script>
