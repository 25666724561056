<template>
  <BaseDetailCard
    :title="$t('Vendor Payments')"
    :can-expand="true"
    :expanded="true"
    entity-name="vendor"
  >
    <BaseDetailCardRow :title="$t('Payments On Hold')" stripe>
      {{ formatPrice(vendorData.payments_on_hold_amount) }}
    </BaseDetailCardRow>
    <BaseDetailCardRow :title="$t('Payments To-Date')">
      {{ formatPrice(vendorData.payment_to_date) }}
    </BaseDetailCardRow>
    <BaseDetailCardRow :title="$t('Payments This Year')" stripe>
      {{ formatPrice(paymentActivities.this_year) }}
    </BaseDetailCardRow>
    <BaseDetailCardRow :title="$t('Payments Last Year')">
      {{ formatPrice(paymentActivities.last_year) }}
    </BaseDetailCardRow>
  </BaseDetailCard>
</template>
<script setup lang="ts">
import Data = API.Data
import { computed, PropType } from 'vue'
import { formatPrice } from '@/plugins/formatPrice'
import { Vendor } from '@/modules/common/types/models'
import { getActivitiesThisAndLastYear } from '@/utils/utils'

const props = defineProps({
  vendor: {
    type: Object as PropType<Data<Vendor>>,
    default: () => ({}),
    required: true,
  }
})

const vendorData = computed<Vendor>(() => {
  return props.vendor?.attributes || {}
})

const paymentActivities = computed(() => {
  return getActivitiesThisAndLastYear(props.vendor)
})
</script>
