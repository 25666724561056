<template>
  <Wizard :steps="steps"
          :current-step="currentStep"
          :loading="loading"
          @set-step="onSetStep"
          @on-back="onBack"
          @on-next="onNext">
    <fade-transition :duration="250"
                     mode="out-in">
      <component v-model="filters"
                 :is="getCurrentStep"
                 :data="filters"
                 :storageKey="STORAGE_KEY"
                 :ref="getCurrentStep"
      />
    </fade-transition>
  </Wizard>
</template>
<script>
  import axios from 'axios'
  import { jobLineItemTypes } from '@/enum/enums'
  import { onBack, onNext, onSetStep, setStepStates } from '@/components/lists/wizard-util'
  import step1 from '@/modules/accounts-receivable/pages/billings/forms/cost-plus/select-costs/step1'
  import step2 from '@/modules/accounts-receivable/pages/billings/forms/cost-plus/select-costs/step2'
  import step3 from '@/modules/accounts-receivable/pages/billings/forms/cost-plus/select-costs/step3'

  const STORAGE_KEY = 'selectedCostsForBilling'

  export default {
    components: {
      step1,
      step2,
      step3,
    },
    data() {
      return {
        STORAGE_KEY,
        loading: false,
        filters: {
          type: [jobLineItemTypes.Cost],
          period_from: 0,
          period_to: 12,
          job_statuses: ['open'],
          job_number_from: '',
          job_number_to: '',
          category_ids: [],
          phase_code_from: '',
          phase_code_to: '',
          cost_code_from: '',
          cost_code_to: '',
          change_order_from: '',
          change_order_to: '',
          job_type_ids: [],
          customer_code_from: '',
          customer_code_to: '',
          show_removed: 0,
          add_vendor_name: 0,
        },
        steps: [
          {
            name: 'Enter Criteria for Selection',
            status: 'current',
          },
          {
            name: 'Select Costs for Billing',
            status: 'upcoming',
          },
          {
            name: 'Creating Cost Plus Invoices',
            status: 'upcoming',
            actionName: 'Proceed',
          },
        ],
        currentStep: 1,
      }
    },
    computed: {
      getCurrentStep() {
        return `step${this.currentStep}`
      },
    },
    methods: {
      setStepStates,
      onSetStep,
      onBack,
      onNext,
      async submitForm() {
        try {
          this.loading = true
          const ref = this.$refs[this.getCurrentStep]
          const payload = ref?.tryToCreateBillings()

          // * When form is invalid, prevent submit
          if (!payload) {
            return
          }

          if (!payload.create?.length && !payload.remove?.length) {
            return this.$router.push('/accounts-receivable/billings/cost-plus/add')
          }

          const { data } = await axios.post('/restify/transactions/actions?action=create-cost-plus-billings', payload)
          await this.openBillingInvoiceForm(data.billings)
        } catch (err) {
          console.warn(err)
          this.$error(this.$t('Something went wrong. Please try again.'))
        } finally {
          this.loading = false
        }
      },
      async openBillingInvoiceForm(billings) {
        const confirmed = await this.$confirm({
          title: this.$t('Access billing edit page?'),
          description: this.$t('Do You want to edit cost plus billing'),
          buttonText: this.$t('Edit'),
        })

        if (!confirmed) {
          return this.resetForm()
        }

        await this.$store.dispatch('globalLists/getLineItemsConfig', true)

        const firstBillingId = this.get(billings, '0.id', false)

        if (!firstBillingId) {
          return this.$router.push('/accounts-receivable/billings/cost-plus/add')
        }

        await this.$router.push(`/accounts-receivable/billings/cost-plus/${firstBillingId}/edit`)
      },
      resetForm() {
        this.currentStep = 1
        localStorage.removeItem(STORAGE_KEY)
      },
    },
    beforeDestroy() {
      this.resetForm()
    },
  }
</script>
