<template>
  <BaseInput
    v-bind="$attrs"
    v-model="model"
    :label="$t('Duration')"
    :tip="$t('You can enter the duration in hours and minutes. For example, 1:30 for 1 hour and 30 minutes. Alternatively, you can enter the duration as a number of hours. For example 1.5 for 1 hour and 30 minutes.')"
    placeholder="0:00"
    rules="timeSheetDuration"
    @blur="$emit('blur')"
  >
    <template #suffix>
      <span>{{ $t('hours') }}</span>
    </template>
  </BaseInput>
</template>
<script>
import { minutesToHours, parseHoursToMinutes } from "@/modules/payroll/utils/timeCardUtils";

export default {
  props: {
    value: {
      type: String,
    },
  },
  computed: {
    model: {
      get() {
        return minutesToHours(this.value)
      },
      set(value) {
        const minutes = parseHoursToMinutes(value)
        this.$emit('input', minutes)
      }
    }
  }
}
</script>
