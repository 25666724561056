<template>
  <BaseDataTable
      v-bind="defaultAttributes"
      :columns="columns"
      :data="getData"
      :total-rows="rowsLength"
      @force-pagination="forcePagination"
  >

    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template #html-row="{row}">

      <tr>
        <td class="border-none" colspan="4">
          <div class="flex">
            <JobLink
                :data="{
                    id: row.htmlData?.id,
                    number: row.htmlData?.number,
                    description: row.htmlData?.description,
                }"
            />
            <status-badge :status="row.htmlData?.status"/>
          </div>
        </td>
        <td class="border-none" align="right">
          <span>{{ $t('Prior:') }}</span>
          <span class="ml-5">{{ $formatPercent(row.htmlData?.prior?.percent_complete) }}</span>
        </td>
        <td class="border-none" align="right">{{ $formatPrice(row.htmlData?.prior?.billings_to_date) }}</td>
        <td class="border-none" align="right">{{ $formatPrice(row.htmlData?.prior?.earned_revenue) }}</td>
        <td class="border-none" align="right">{{ $formatPrice(row.htmlData?.prior?.cost_to_date) }}</td>
        <td class="border-none" align="right">{{ $formatPrice(row.htmlData?.prior?.over_under_billed) }}</td>
      </tr>

      <tr>
        <td class="border-none" colspan="4"/>
        <td class="border-none" align="right">
          <span>{{ $t('Period:') }}</span>
          <span class="ml-5">{{ $formatPercent(row.htmlData?.period?.percent_complete) }}</span>
        </td>
        <td class="py-2" align="right">{{ $formatPrice(row.htmlData?.period?.billings_to_date) }}</td>
        <td align="right">{{ $formatPrice(row.htmlData?.period?.earned_revenue) }}</td>
        <td align="right">{{ $formatPrice(row.htmlData?.period?.cost_to_date) }}</td>
        <td align="right">{{ $formatPrice(row.htmlData?.period?.over_under_billed) }}</td>
      </tr>

      <tr>
        <td></td>
        <td class="py-2" align="right">{{ $formatPrice(row.htmlData?.subtotal?.contract_amount) }}</td>
        <td align="right">{{ $formatPrice(row.htmlData?.subtotal?.est_final_cost) }}</td>
        <td align="right">{{ $formatPrice(row.htmlData?.subtotal?.est_gross_profit) }}</td>
        <td align="right">
          <span>{{ $t('End of Period:') }}</span>
          <span class="ml-5">{{ $formatPercent(row.htmlData?.subtotal?.percent_complete) }}</span>
        </td>
        <td align="right">{{ $formatPrice(row.htmlData?.subtotal?.billings_to_date) }}</td>
        <td align="right">{{ $formatPrice(row.htmlData?.subtotal?.earned_revenue) }}</td>
        <td align="right">{{ $formatPrice(row.htmlData?.subtotal?.cost_to_date) }}</td>
        <td align="right">{{ $formatPrice(row.htmlData?.subtotal?.over_under_billed) }}</td>
      </tr>

    </template>
  </BaseDataTable>
</template>
<script>
  import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'

  export default {
    extends: ReportTableWrapper,
    computed: {
      grandTotals() {
        return this.data.grand_totals?.all || {}
      },
      columns() {
        return [
          {
            label: this.$t('Job'),
            prop: 'job',
            summary: () => this.$t('Grand Totals:'),
            minWidth: 80,
            maxWidth: 80,
          },
          {
            label: this.$t('Contract <br> Amount'),
            prop: 'contract_amount',
            align: 'right',
            summary: () => this.$formatPrice(this.grandTotals.contract_amount),
            minWidth: 80,
            maxWidth: 100,
          },
          {
            label: this.$t('Est Cost <br> @ Complete'),
            prop: 'est_final_cost',
            align: 'right',
            summary: () => this.$formatPrice(this.grandTotals.est_final_cost),
            minWidth: 80,
            maxWidth: 100,
          },
          {
            label: this.$t('Est Gross <br> Profit'),
            prop: 'est_gross_profit',
            align: 'right',
            summary: () => this.$formatPrice(this.grandTotals.est_gross_profit),
            minWidth: 80,
            maxWidth: 100,
          },
          {
            label: this.$t('Percent <br> Complete'),
            prop: 'percent_complete',
            align: 'right',
            summary: () => this.$formatPercent(this.grandTotals.percent_complete),
            minWidth: 80,
            maxWidth: 100,
          },
          {
            label: this.$t('Billings'),
            prop: 'billings_to_date',
            align: 'right',
            summary: () => this.$formatPrice(this.grandTotals.billings_to_date),
            minWidth: 80,
            maxWidth: 100,
          },
          {
            label: this.$t('Earned <br> Revenue'),
            prop: 'earned_revenue',
            align: 'right',
            summary: () => this.$formatPrice(this.grandTotals.earned_revenue),
            minWidth: 80,
            maxWidth: 100,
          },
          {
            label: this.$t('Costs'),
            prop: 'cost_to_date',
            align: 'right',
            summary: () => this.$formatPrice(this.grandTotals.cost_to_date),
            minWidth: 80,
            maxWidth: 100,
          },
          {
            label: this.$t('Over/Under <br> Billed'),
            prop: 'over_under_billed',
            align: 'right',
            summary: () => this.$formatPrice(this.grandTotals.over_under_billed),
            minWidth: 80,
            maxWidth: 100,
          },
        ]
      },
    },
    methods: {
      composeRows(data) {

        data.forEach((group) => {

          this.rows.push({
            htmlData: {
              ...group.job,
              prior: group.totals.prior,
              period: group.totals.period,
              subtotal: group.totals.all,
            },
            subtotal: [],
          })

        })

        return this.rows
      },
    },
  }
</script>
