<template>
  <div>
    <div class="flex flex-col-reverse lg:flex-row space-x-2">
      <BaseCalendar
        class="flex-1 lg:h-full"
        :loading="tasksLoading"
        :is-scheduler="isScheduler"
        :scheduler-options="schedulerOptions"
        :can-select="$can('tasks_store')"
        :can-resize="$can('tasks_update')"
        :can-move="$can('tasks_update')"
        @event-click="handleEventClick"
        @event-drop="handleEventMove"
        @event-resize="handleEventMove"
      >
        <template #eventContent="{event}">
          <EventContent :event="event"/>
        </template>
        <template #resourceLabelContent="{resource}">
          <svg v-if="tasksLoading"
               viewBox="0 0 640 357" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M4 0C1.79086 0 0 1.79086 0 4V12C0 14.2091 1.79086 16 4 16H87C89.2091 16 91 14.2091 91 12V4C91 1.79086 89.2091 0 87 0H4ZM76 72C73.7909 72 72 73.7909 72 76V84C72 86.2091 73.7909 88 76 88H159C161.209 88 163 86.2091 163 84V76C163 73.7909 161.209 72 159 72H76ZM72 240C72 237.791 73.7909 236 76 236H159C161.209 236 163 237.791 163 240V248C163 250.209 161.209 252 159 252H76C73.7909 252 72 250.209 72 248V240ZM40 72C37.7909 72 36 73.7909 36 76V84C36 86.2091 37.7909 88 40 88H48C50.2091 88 52 86.2091 52 84V76C52 73.7909 50.2091 72 48 72H40ZM36 240C36 237.791 37.7909 236 40 236H48C50.2091 236 52 237.791 52 240V248C52 250.209 50.2091 252 48 252H40C37.7909 252 36 250.209 36 248V240ZM76 144C73.7909 144 72 145.791 72 148V156C72 158.209 73.7909 160 76 160H263C265.209 160 267 158.209 267 156V148C267 145.791 265.209 144 263 144H76ZM72 312C72 309.791 73.7909 308 76 308H263C265.209 308 267 309.791 267 312V320C267 322.209 265.209 324 263 324H76C73.7909 324 72 322.209 72 320V312ZM76 96C73.7909 96 72 97.7909 72 100V116C72 118.209 73.7909 120 76 120H418C420.209 120 422 118.209 422 116V100C422 97.7909 420.209 96 418 96H76ZM72 264C72 261.791 73.7909 260 76 260H418C420.209 260 422 261.791 422 264V280C422 282.209 420.209 284 418 284H76C73.7909 284 72 282.209 72 280V264ZM0.5 36C0.223858 36 0 36.2239 0 36.5C0 36.7761 0.22385 37 0.499992 37H639.5C639.776 37 640 36.7761 640 36.5C640 36.2239 639.776 36 639.5 36H0.5ZM0 196.5C0 196.224 0.223858 196 0.5 196H639.5C639.776 196 640 196.224 640 196.5C640 196.776 639.776 197 639.5 197H0.499992C0.22385 197 0 196.776 0 196.5ZM0.5 356C0.223858 356 0 356.224 0 356.5C0 356.776 0.22385 357 0.499992 357H639.5C639.776 357 640 356.776 640 356.5C640 356.224 639.776 356 639.5 356H0.5Z"
                  fill="#EAEAEA"/>
          </svg>
          <ResourceContent v-if="!tasksLoading" :resource="resource"/>
        </template>
      </BaseCalendar>
      <div
        ref="sourceContainer"
        class="draggable w-full lg:w-[320px] flex flex-col gap-y-2 lg:max-h-[calc(100vh-165px)]"
      >
        <SourceList
          @list-change="initDraggableTasks"
        />
      </div>
    </div>
    <TaskDialog
      v-if="showEditTaskDialog"
      :open.sync="showEditTaskDialog"
      :data="selectedTask"
      @save="onTaskSave"
      @delete-confirm="onTaskDelete"
    />
  </div>
</template>
<script>
import { Draggable } from '@fullcalendar/interaction';
import BaseCalendar from "@/modules/payroll/components/tasks/BaseCalendar.vue";
import axios from "axios";
import ResourceContent from "@/modules/payroll/components/tasks/ResourceContent.vue";
import EventContent from "@/modules/payroll/components/tasks/EventContent.vue";
import orderBy from "lodash/orderBy";
import { resourceStatuses } from "@/enum/enums";
import format from "date-fns/format";
import TaskDialog from "@/modules/payroll/components/tasks/TaskDialog.vue";
import parse from "date-fns/parse";
import { addHours, addMinutes, subHours } from "date-fns";
import SourceList from "@/modules/payroll/components/tasks/SourceList.vue";
import { getAddlSourceType, getSourceType } from "@/components/grid-table/utils/cost-center-cell";
import { getColor, getEventColor, getTaskName, TaskStatus } from "@/modules/payroll/components/tasks/taskUtils";
import uniq from "lodash/uniq";

export default {
  components: {
    TaskDialog,
    EventContent,
    ResourceContent,
    BaseCalendar,
    SourceList
  },
  props: {
    isScheduler: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tasks: [],
      tasksLoading: false,
      showEditTaskDialog: false,
      selectedTask: null,
    }
  },
  computed: {
    allEmployees() {
      const employees = this.$store.state.globalLists.employees || []
      return orderBy(employees, 'name').filter(employee => employee.status !== resourceStatuses.Inactive)
    },
    unAssignedResource() {
      return {
        id: crypto.randomUUID(),
        name: 'Unassigned',
        extendedProps: {
          tasks: this.getUnassignedTasks()
        }
      }
    },
    schedulerOptions() {
      if (!this.isScheduler) {
        return {}
      }
      const resources = []
      this.allEmployees.forEach(employee => {
        resources.push({
          id: employee.id,
          title: employee.name,
          name: employee.name,
          extendedProps: {
            employee,
            tasks: this.getEmployeeTasks(employee.id),
          }
        })
      })
      resources.push(this.unAssignedResource)

      const businessHourStart = this.$settings('timesheet', 'business_hours_start') || '8:00'
      const businessHourEnd = this.$settings('timesheet', 'business_hours_end') || '18:00'
      const workingDays = this.$settings('timesheet', 'working_days') || [1, 2, 3, 4, 5]

      let start = parse(businessHourStart, 'HH:mm', new Date())
      let end = parse(businessHourEnd, 'HH:mm', new Date())
      const startTime = format(start, 'HH:mm')
      const endTime = format(end, 'HH:mm')
      let slotMinTime = startTime
      let slotMaxTime = endTime

      if (start.getHours() > 0) {
        start = subHours(start, 1)
        slotMinTime = format(start, 'HH:mm')
      }
      if (end.getHours() < 23) {
        end = subHours(end, -1)
        slotMaxTime = format(end, 'HH:mm')
      }
      return {
        resources: resources,
        resourceAreaColumns: [
          {
            field: 'name',
            headerContent: 'Employees'
          },
        ],
        events: this.taskEvents,
        droppable: true,
        eventMinHeight: 55,
        slotMinTime: slotMinTime,
        slotMaxTime: slotMaxTime,
        businessHours: {
          daysOfWeek: workingDays, // Monday - Friday
          startTime: startTime, // a start time (8 in this example)
          endTime: endTime, // an end time (6pm in this example)
        },
        drop: this.onTaskDrop,
        eventReceive: this.onEventReceive,
      }
    },
    taskEvents() {
      const taskEvents = []
      this.tasks.forEach(task => {
        const { start_at, finish_at, instructions, employee_ids, cost_center } = task.attributes
        const name = instructions || ''
        const event = {
          id: task.id,
          title: name,
          start: start_at,
          end: finish_at,
          extendedProps: task,
          ...this.getEventExtraProperties(cost_center),
          editable: true,
          startEditable: true,
          durationEditable: true,
        }
        if (!this.isScheduler) {
          taskEvents.push(event)
          return
        }
        const employees = employee_ids || []
        employees.forEach(employeeId => {
          taskEvents.push({
            ...event,
            id: `${task.id}-${employeeId}`,
            resourceId: employeeId
          })
        })
        if (employees.length === 0) {
          taskEvents.push({
            ...event,
            resourceId: this.unAssignedResource.id
          })
        }
      })
      return taskEvents
    }
  },
  methods: {
    getEventExtraProperties(costCenter) {
      const color = getEventColor(costCenter)
      return {
        classNames: ['calendar-task'],
        durationEditable: this.$can('tasks_update'),
        eventColor: getColor(color, 50),
        backgroundColor: getColor(color, 50),
        borderColor: getColor(color, 200),
        textColor: getColor(color, 500),
      }
    },
    getTaskFromEl(el) {
      const source_id = el.getAttribute('data-source-id')
      const addl_source_id = el.getAttribute('data-addl-source-id')
      const cost_center = el.getAttribute('data-cost-center')
      const is_lunch = !!el.getAttribute('data-is-lunch')
      const is_break = !!el.getAttribute('data-is-break')
      let durationInMinutes = Number(el.getAttribute('data-duration'))

      const start = new Date()
      const dateFormat = 'yyyy-MM-dd HH:mm'
      if (!durationInMinutes || isNaN(durationInMinutes)) {
        durationInMinutes = 120
      }
      const end = addMinutes(start, durationInMinutes)

      const task = {
        source_id,
        addl_source_id,
        cost_center,
        source_type: getSourceType(cost_center),
        addl_source_type: getAddlSourceType(cost_center),
        status: TaskStatus.ToDo,
        notes: '',
        employee_ids: [],
        is_lunch,
        is_break,
        duration: durationInMinutes,
        start_at: format(start, dateFormat),
        finish_at: format(end, dateFormat),
        name: ''
      }
      task.name = getTaskName(task)
      return task
    },
    async onEventReceive(info) {
      const start = info.event.start
      const taskData = this.getTaskFromEl(info.draggedEl)
      const duration = taskData.duration || 120
      const end = addMinutes(start, duration)
      if (!info.event.end) {
        info.event.setEnd(end)
      }
    },
    async onTaskDrop(info) {
      const employeeId = info.resource?.extendedProps?.employee?.id
      const dateFormat = 'yyyy-MM-dd HH:mm'
      const start = info.date
      const taskData = this.getTaskFromEl(info.draggedEl)
      const duration = taskData.duration || 120
      const end = addMinutes(start, duration)

      const task = {
        ...taskData,
        end,
        employee_ids: employeeId ? [employeeId] : [],
        start_at: format(start, dateFormat),
        finish_at: format(end, dateFormat),
      }
      const { data } = await axios.post('/restify/tasks', task, {
        params: {
          related: 'source,addlSource'
        }
      })
      this.tasks.push(data)
      await this.$store.dispatch('timesheets/addRecentTask', data)
    },
    getEmployeeTasks(employeeId) {
      return this.tasks.filter(task => task.attributes.employee_ids?.includes(employeeId))
    },
    getUnassignedTasks() {
      return this.tasks.filter(task => task.attributes.employee_ids?.length)
    },
    handleEventClick(clickInfo) {
      const task = clickInfo.event.extendedProps
      this.showEditTaskDialog = true
      this.selectedTask = task
    },
    async handleEventMove({ event, newResource, oldResource }) {
      let task = event?.extendedProps
      const dateFormat = 'yyyy-MM-dd HH:mm'
      const requestData = {
        id: task.id,
        ...task?.attributes,
        start_at: format(event.start, dateFormat),
        finish_at: format(event.end, dateFormat),
      }
      task.attributes.start_at = requestData.start_at
      task.attributes.finish_at = requestData.finish_at

      if (newResource) {
        const newEmployeeId = this.get(newResource, 'extendedProps.employee.id')
        const oldEmployeeId = this.get(oldResource, 'extendedProps.employee.id')
        let taskEmployeeIds = this.get(oldResource, 'extendedProps.tasks[0].attributes.employee_ids') || []
        taskEmployeeIds = taskEmployeeIds.filter(id => id !== oldEmployeeId)
        requestData.employee_ids = [...taskEmployeeIds, newEmployeeId].filter(e => e !== undefined)
        requestData.employee_ids = uniq(requestData.employee_ids)
        newResource.extendedProps.tasks.push(requestData)
        task.attributes.employee_ids = requestData.employee_ids
      }

      await this.updateTask(requestData)
    },
    async updateTask(data) {
      await axios.put(`/restify/tasks/${data.id}`, data)
    },
    async onTaskSave(task) {
      const matchingTaskIndex = this.tasks.findIndex(t => t.id === task.id)
      if (matchingTaskIndex !== -1) {
        this.tasks.splice(matchingTaskIndex, 1, task)
      } else {
        this.tasks.push(task)
      }
      this.showEditTaskDialog = false
    },
    onTaskDelete(task) {
      this.showEditTaskDialog = false
      this.tasks = this.tasks.filter(t => t.id !== task.id)
    },
    async getTasks() {
      try {
        this.tasksLoading = true
        const { date } = this.$route.query
        if (!date) {
          return
        }
        const { data } = await axios.get('/restify/tasks', {
          params: {
            perPage: 1000,
            date,
          }
        })
        this.tasks = data
      } finally {
        this.tasksLoading = false
      }
    },
    initDraggableTasks() {
      if (this.dragContainer) {
        this.dragContainer.destroy()
      }

      this.dragContainer = new Draggable(this.$refs.sourceContainer, {
        itemSelector: '.fc-event',
        eventData: (eventEl) => {
          const task = this.getTaskFromEl(eventEl)
          return {
            title: eventEl.innerText,
            editable: false,
            startEditable: false,
            durationEditable: false,
            ...this.getEventExtraProperties(task.cost_center),
            extendedProps: {
              attributes: task,
            }
          };
        }
      });

    }
  },
  mounted() {
    this.initDraggableTasks()
  },
  watch: {
    '$route.query.date': {
      immediate: true,
      handler() {
        this.getTasks()
      },
    }
  }
}
</script>
