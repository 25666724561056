<template>
  <BaseDetailsList class="flex flex-col md:flex-row space-x-12">
    <div class="flex flex-col p-4">
      <div class="space-y-2 w-64">
        <BaseDetailsListItem :label="$t('Posted At')">
          {{ $formatDate(details.posted_at, 'datetime') }}
        </BaseDetailsListItem>
        <BaseDetailsListItem :label="$t('Reference Date ')">
          {{ $formatDate(details.reference_date) }}
        </BaseDetailsListItem>
        <BaseDetailsListItem :label="$t('Reference No ')">
          {{ details.reference_no }}
        </BaseDetailsListItem>
      </div>
    </div>
    <div class="flex flex-col p-4">
      <div class="space-y-2 w-64">
        <BaseDetailsListItem :label="$t('Status')">
          <status-badge :status="details.status"/>
        </BaseDetailsListItem>
        <BaseDetailsListItem :label="$t('Phase/Cost/Chg')">
          <span v-if="details.addl_source_id"
                class="truncate">
              {{ getAddlSourceName(transaction) }}
          </span>
          <template v-else>
          </template>
        </BaseDetailsListItem>
        <BaseDetailsListItem :label="$t('Job')">
          <JobLink v-if="details.source_id"
                   :data="get(transaction, 'relationships.source')"
          />
        </BaseDetailsListItem>
      </div>
    </div>
  </BaseDetailsList>
</template>
<script>
  import { getAddlSourceName } from '@/components/grid-table/utils/cost-center-cell'

  export default {
    props: {
      transaction: {
        type: Object,
        required: true,
        default: () => ({}),
      },
    },
    computed: {
      details() {
        return this.transaction?.attributes || {};
      },
    },
    methods: {
      getAddlSourceName,
    },
  }
</script>
