<template>
  <div :key="get(currentResource, 'id', 'currentResource-id')">
    <portal v-if="loading" to="page-title">
      <div class="!h-7 my-1 bg-gray-100 w-[250px] placeholder-loading rounded"/>
    </portal>
    <template v-if="currentResource">
      <portal to="page-title">
        <div class="flex justify-between md:justify-start">
          <slot name="page-title">
            <span class="truncate">
              <template v-if="currentResource.attributes.name">
                {{ currentResource.attributes.name }}
              </template>
              <template v-if="currentResource.attributes.code">
                ({{ currentResource.attributes.code }})
              </template>
            </span>
            <span v-if="currentResource.attributes.status"
                  class="mx-1 md:mx-2 flex items-center">
              <status-badge :status="currentResource.attributes.status"/>
            </span>
          </slot>
        </div>
      </portal>
      <portal to="entity-name">
        <slot name="entity-name">
          {{ currentResource.attributes.name }}
          <template v-if="currentResource.attributes.code">
            ({{ currentResource.attributes.code }})
          </template>
        </slot>
      </portal>
    </template>
    <div class="flex flex-wrap justify-between items-center mb-2">
      <layout-tabs :items="authorizedGlobally"/>
      <div class="flex items-center space-x-2">
        <slot v-if="!loading" name="extra-buttons"/>
        <div class="min-w-[300px] -mt-4" :class="selectClass">
          <component
              v-if="currentResource?.id && selectComponent"
              :is="selectComponent"
              :value="resourceId"
              :add-entity="false"
              :initial-value="currentResource?.attributes"
              @change="onResourceChange"
          />
        </div>
      </div>
    </div>
    <slot/>
    <router-view v-loading="loading && !isDetailsPage"/>
  </div>
</template>
<script>
import layoutPermissions from "@/mixins/layoutPermissions";
import detailScreen from "@/mixins/detailScreen";

export default {
  mixins: [layoutPermissions, detailScreen],
  props: {
    loading: Boolean,
    currentResource: {
      type: Object,
      required: true,
      default: () => ({})
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    selectComponent: {
      type: [String, Object]
    },
    selectClass: {
      type: [String, Object]
    },
    getCurrentResource: {
      type: Function,
      required: true,
      default: async () => {
      },
    }
  },
  computed: {
    authorizedGlobally() {
      return this.filteredItems.filter(item => {
        return !(!item.productionReady && this.isProduction);
      })
    },
  }
}
</script>
