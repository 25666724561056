<template>
  <entity-preview
      v-bind="$attrs"
      :entity="workOrderData"
      :key="`preview-${workOrderData.id}`"
      :url="`/service-billing/work-orders/${workOrderData.id}/view`"
      :value-to-display="valueToDisplay"
      :parent-entity-id="workOrderData.id"
      :show-preview="showPreview"
      :target="target"
      :is-link="!editableCell"
      entity-name="workOrder"
  />
</template>
<script>
  export default {
    name: 'WorkOrderLink',
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      showDescription: {
        type: Boolean,
        default: true,
      },
      showPreview: {
        type: Boolean,
        default: true,
      },
      target: String,
      editableCell: Boolean,
    },
    computed: {
      workOrderData() {
        return this.data?.attributes || this.data || {}
      },
      valueToDisplay() {
        if (!this.showDescription || !this.workOrderData.description) {
          return this.workOrderData.number
        }
        return `${this.workOrderData.number} (${this.workOrderData.description})`
      },
    },
  }
</script>
