<template>
  <div>
    <portal to="page-title">
      <div class="flex items-center">
        {{ $t('Financial Report ') }} #{{ currentResource.attributes.code }}
      </div>
    </portal>
    <div class="flex justify-between items-center">
      <layout-tabs :items="filteredItems"/>
    </div>
    <router-view/>
  </div>
</template>
<script>
  import layoutPermissions from '@/mixins/layoutPermissions'

  export default {
    mixins: [layoutPermissions],
    computed: {
      items() {
        return [
          {
            name: this.$t('Preview'),
            path: `/ledger/settings/financial-reports/${this.$route.params.id}/view`,
            authorizedTo: 'authorizedToUpdate',
          },
          {
            name: this.$t('Edit'),
            path: `/ledger/settings/financial-reports/${this.$route.params.id}/edit`,
            authorizedTo: 'authorizedToUpdate',
          },
        ]
      },
      currentResource() {
        return this.$store.state.generalLedger.currentFinancialReport || {}
      },
    },
    methods: {
      async getCurrentResource(id) {
        await this.$store.dispatch('generalLedger/getFinancialReport', id)
      },
    },
  }
</script>
