import axios from "axios";

class UserService {

  async getProfile() {
    return axios.get(`/restify/profile`, {
      params: {
        related: 'companyRoles,organizationRoles,employee,currentOrganization,currentOrganizationCompanies'
      }
    });
  }

  getUsers() {
    return axios.get(`/restify/users/`, {
      params: {
        perPage: 500,
      }
    });
  }

  async getById(id) {
    return axios.get(`/restify/users/${id}`, {
      params: {
        related: 'companies,companyRoles'
      }
    });
  }

  async getOrganization() {
    return axios.get(`/organization`);
  }

  async getUserCompanySettings() {
    return axios.get('/restify/user-company-settings');
  }

  async updateUser(data) {
    return axios.patch(`/restify/users/${data.id}`, data);
  }

  async updateProfile(data) {
    return axios.put(`/restify/profile`, data);
  }

  getUserFormData(data) {
    const formData = new FormData();
    const keys = ['name', 'gender', 'address', 'phone', 'country_code', 'avatar']

    keys.forEach(key => {
      const value = data[key]

      if (value) {
        formData.append(key, data[key])
      }
    })
    return formData
  }

  async changePassword(data) {
    return axios.post(`/restify/users/actions?action=change-password`, data);
  }
}

export default new UserService();
