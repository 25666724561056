<template>
  <div class="truncate">
    <template v-if="isInvoiceType">
      <router-link :to="`/accounts-payable/invoices/${invoiceId}/view`">
        <template v-if="showDescription">
          {{ $t('Invoice #') }}
        </template>
        {{ reference }}
      </router-link>
    </template>

    <template v-else-if="isInvoicePaymentType">
      <router-link :to="`/accounts-payable/payments/batches/${journalId}/view`">
        <template v-if="showDescription">
          {{ $t('Invoice Payment #') }}
        </template>
        {{ reference }}
      </router-link>
    </template>

    <template v-else-if="isBillingPaymentType">
      <router-link :to="`/accounts-receivable/payments/batches/${journalId}/view`">
        <template v-if="showDescription">
          {{ $t('Billing Payment #') }}
        </template>
        {{ reference }}
      </router-link>
    </template>

    <router-link
        v-else-if="isBillingType"
        :to="`/accounts-receivable/billings/${getBillingType(meta.billing_type)}/${billingId}/view`"
    >
      {{ reference }}
    </router-link>

    <template v-else>
      {{ reference }}
    </template>
  </div>
</template>
<script>
  import { getBillingType } from '@/modules/accounts-receivable/pages/billings/billings'
  const DocumentType = {
    Invoice: 'invoice',
    InvoicePayment: 'invoice-payment',
    Billing: 'billing',
    BillingPayment: 'billing-payment',
  }
  export default {
    name: 'ReferenceLink',
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      showDescription: {
        type: Boolean,
        default: false,
      },
      referenceNo: {
        type: [String, Number],
        default: '',
      },
      id: {
        type: String,
        default: '',
      },
    },
    computed: {
      reference() {
        return this.data.attributes?.reference_no || this.referenceNo || ''
      },
      journalId() {
        return this.id || this.data.id
      },
      meta() {
        return this.data.attributes?.meta || this.data
      },
      documentType() {
        return this.data?.attributes?.document_type
      },
      documentId() {
        return this.data?.attributes?.document_id
      },
      isInvoiceType() {
        return this.documentType === DocumentType.Invoice || this.meta?.invoice_id
      },
      isInvoicePaymentType() {
        return this.documentType === DocumentType.InvoicePayment || this.meta?.invoice_payment_id
      },
      isBillingType() {
        return this.documentType === DocumentType.Billing || this.meta.billing_id && this.meta.billing_type
      },
      isBillingPaymentType() {
        return this.documentType === DocumentType.BillingPayment || this.meta?.billing_payment_id
      },
      invoiceId() {
        return this.documentId || this.meta.invoice_id
      },
      billingId() {
        return this.documentId || this.meta.billing_id
      }
    },
    methods: {
      getBillingType,
    },
  }
</script>
