<template>
  <div class="shadow p-4 md:p-6 rounded bg-white grid grid-cols-8">
    <UnionAndJobRateEntries
        :data="job"
        :read-only="true"
        api-url="/restify/job-rates"
        ref="entriesTable"
        class="col-span-8"
    />
  </div>
</template>
<script>
  import JobSelect from '@/components/select/entities/JobSelect'
  import UnionAndJobRateEntries from '@/modules/payroll/components/rates/UnionAndJobRateEntries.vue'

  export default {
    components: {
      JobSelect,
      UnionAndJobRateEntries,
    },
    computed: {
      jobId() {
        return this.$route.params.id
      },
      job() {
        return this.$store.state.jobCosting.currentJob
      },
    },
  }
</script>
