<template>
  <div class="p-4">
    <dl class="divide-y divide-gray-100 mr-2">
      <div class="totals-row">
        <span>{{ $t('Prior & Current Items Outstanding') }}</span>
        <span>{{ $formatPrice(priorAndCurrentOutstandingAmount) }}</span>
      </div>
      <div class="totals-row">
        <span>{{ $t('Future Items Reconciled') }}</span>
        <span>{{ $formatPrice(futureItemsReconciled) }}</span>
      </div>
      <div class="totals-row">
        <span>{{ $t('Adjusted Bank Statement Balance') }}</span>
        <span>{{ $formatPrice(adjustedBankStatementBalance) }}</span>
      </div>
      <div class="totals-row"
           :class="{
              'bg-red-50': difference !== 0 && !loading,
              'bg-gray-50': difference === 0 || loading,
           }"
      >
        <span>{{ $t('Difference') }}</span>
        <span>{{ $formatPrice(difference) }}</span>
      </div>
    </dl>
  </div>
</template>
<script>
  import { transactionStatuses } from '@/enum/enums'

  export default {
    inheritAttrs: false,
    props: {
      reconciliation: {
        type: Object,
        default: () => ({}),
      },
      entries: {
        type: Array,
        default: () => [],
      },
      accountBalance: {
        type: [Number, String],
        default: 0,
      },
      loading: {
        type: Boolean,
        default: false,
      }
    },
    computed: {
      fiscalYearPeriod() {
        const period = this.reconciliation.period?.toString().padStart(2, '0')
        return Number(`${this.reconciliation.fiscal_year}${period}`)
      },
      priorAndCurrentOutstandingAmount() {
        const items = this.entries.filter(entry => +entry.fiscal_year_period <= this.fiscalYearPeriod)
        const totalAmount = items.reduce((acc, item) => acc + Number(item.outstanding_amount), 0)
        return totalAmount
      },
      futureItemsReconciled() {
        const items = this.entries.filter(entry => +entry.fiscal_year_period > this.fiscalYearPeriod && entry.status !== transactionStatuses.Outstanding)
        const totalAmount = items.reduce((acc, item) => acc + Number(item.per_bank_amount), 0)
        return totalAmount ? (totalAmount * -1) : totalAmount
      },
      bankBalance() {
        return this.reconciliation.bank_balance_amount
      },
      adjustedBankStatementBalance() {
        const adjustedAmount = this.priorAndCurrentOutstandingAmount + this.futureItemsReconciled

        if (!this.bankBalance) {
          return adjustedAmount
        }

        return this.bankBalance + adjustedAmount
      },
      difference() {
        return this.accountBalance - this.adjustedBankStatementBalance
      },
    },
    methods: {
      getSummary() {
        return {
          prior_and_current_outstanding: this.roundToDecimals(this.priorAndCurrentOutstandingAmount),
          future_items_reconciled: this.roundToDecimals(this.futureItemsReconciled),
          adjusted_balance: this.roundToDecimals(this.adjustedBankStatementBalance),
          difference: this.roundToDecimals(this.difference),
        }
      },
      roundToDecimals(number) {
        return +(number.toFixed(2))
      }
    },
  }
</script>
