<template>
  <entity-select v-bind="$attrs"
                 v-on="$listeners"
                 :url="url"
                 :url-params="urlParams"
                 :name="$attrs.name || $t('Local Tax')"
                 :placeholder="$attrs.placeholder || $t('Select Local Tax')"
                 :label="$attrs.label !== undefined ? $attrs.label : $t('Local Tax')"
                 :add-entity="addEntity"
                 :edit-entity="editEntity"
                 :set-default-option="editEntity"
                 :add-label="$t('Add new Local Tax')"
                 :on-map-entry="onChangeEntry"
                 :id="$attrs.id || 'local-tax-select'"
                 :table-columns="tableColumns"
                 :label-format="formatLabel"
                 label-key="code"
  />
</template>
<script>
  export default {
    name: 'LocalTaxSelect',
    inheritAttrs: false,
    props: {
      url: {
        type: String,
        default: '/restify/local-tax-jurisdictions',
      },
      urlParams: {
        type: Object,
        default: () => ({
          sort: 'code',
        }),
      },
      addEntity: {
        type: Boolean,
        default: false,
      },
      editEntity: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      tableColumns() {
        return [
          {
            minWidth: 80,
            maxWidth: 150,
            name: this.$t('Code'),
            prop: 'attributes.code',
          },
          {
            name: this.$t('Description'),
            prop: 'attributes.description',
            class: 'w-full',
          },
        ]
      },
    },
    methods: {
      onChangeEntry(localTax) {
        this.$emit('on-map-entry', localTax)
      },
      formatLabel(option) {
        const { code, description } = option.attributes
        if (!description) {
          return code
        }
        return `${code} (${description})`
      },
    },
  }
</script>
