<template>
  <base-data-table
      :url="url"
      :columns="columns"
      :url-params="urlParams"
      :add-entity-in-new-tab="true"
      :is-expandable="true"
      :add-text="$t('Start reconciliation')"
      ref="table"
      default-sort="-date"
      actions="search,refresh,add"
      permission="cash_reconciliations"
      base-entity-path="/ledger/account-reconciliation"
      add-entity-url-query="/ledger/account-reconciliation/add"
  >
    <template #extra-actions="{row}">
      <post-action v-if="$isAuthorized('authorizedToPost', row)"
                   :id="row.id"
                   :action-url="postActionUrl(row)"
                   :has-custom-message="true"
                   entity-name="Reconciliation"
                   resource-name="reconciliation"
                   variant="gray-link"
                   size="xs"
                   @on-action-callback="refreshTable"
      >
        <template #custom-message>
          {{ $t('If you post reconciled transactions, they will not appear on your cash reconciliation.') }}<br>
          {{ $t('Transactions with a status of ') }} <b>{{ $t('Variance') }}</b> {{ $t('or') }}
          <b>{{ $t('Outstanding') }}</b> {{ $t('will continue to appear until they are reconciled.') }}
        </template>
      </post-action>
      <void-action v-if="$isAuthorized('authorizedToVoid', row)"
                   :id="row.id"
                   :action-url="voidActionUrl(row)"
                   :has-custom-message="true"
                   entity-name="Reconciliation"
                   resource-name="reconciliation"
                   variant="gray-link"
                   size="xs"
                   @on-action-callback="refreshTable"
      >
        <template #custom-message>
          {{ $t('If you void reconciled transactions, all of them will continue to appear until they are reconciled.') }}
        </template>
      </void-action>
    </template>
    <template #attributes.account="{row}">
      <account-link :data="row"/>
    </template>
    <template #attributes.period="{row}">
      {{ getPeriodName(row.attributes.period) }}
    </template>
    <template #attributes.date="{row}">
      <router-link :to="`/ledger/account-reconciliation/${row.id}/view`">
        {{ $formatDate(row.attributes.date) }}
      </router-link>
    </template>
    <template #adjusted_balance="{row}">
      {{ $formatPrice(getAdjustedBalance(row)) }}
    </template>
    <template #expand-content="{row}">
      <ReconciliationEntries
          :reconciliation-id="row.id"
          :data="mapRowData(row)"
          :url-params="getUrlParams(row)"
          class="p-4"
          readOnly
      />
    </template>
  </base-data-table>
</template>
<script>
import { resourceStatuses, transactionStatuses } from '@/enum/enums'
  import ReconciliationEntries from '@/modules/ledger/components/ReconciliationEntries'

  export default {
    components: {
      ReconciliationEntries,
    },
    data() {
      return {
        status: resourceStatuses.Pending,
        columns: [
          {
            label: this.$t('Date'),
            prop: 'attributes.date',
            maxWidth: 280,
          },
          {
            label: this.$t('Account'),
            prop: 'attributes.account',
            maxWidth: 280,
            component: 'AccountLink',
          },
          {
            label: this.$t('Bank Statement Balance'),
            prop: 'attributes.bank_balance_amount',
            maxWidth: 280,
            component: 'FormattedPrice',
          },
          {
            label: this.$t('Adjusted Bank Statement Balance'),
            prop: 'adjusted_balance',
            maxWidth: 280,
            component: 'FormattedPrice',
          },
          {
            label: this.$t('Period'),
            prop: 'attributes.period',
            maxWidth: 160,
          },
        ],
      }
    },
    computed: {
      url() {
        return '/restify/reconciliations'
      },
      urlParams() {
        return {
          status: this.status,
          fiscal_year: this.activeFiscalYear,
        }
      },
    },
    methods: {
      getAdjustedBalance(row) {
        if (row.attributes?.meta?.adjusted_balance)  {
          return row.attributes.meta.adjusted_balance
        }
        return row.attributes?.account_balance_amount
      },
      getPeriodName(period) {
        return this.$store.getters['company/getPeriodName'](period)
      },
      refreshTable() {
        this.$refs.table.refresh()
      },
      postActionUrl(row) {
        return `/restify/reconciliations/${row.id}/action?action=post-reconciliation`
      },
      voidActionUrl(row) {
        return `/restify/reconciliations/${row.id}/action?action=void-reconciliation`
      },
      mapRowData(row) {
        return {
          id: row.originalRow?.id,
          ...(row.originalRow?.attributes || {})
        }
      },
      getUrlParams(row) {
        return {
          account_number: row.originalRow?.attributes?.account,
        }
      }
    },
    watch: {
      '$route.meta': {
        immediate: true,
        handler(meta) {
          this.status = this.get(meta, 'status', resourceStatuses.Pending)
        },
      },
    },
  }
</script>
