<template>
  <div
    :class="itemsAlign"
    class="grid md:grid-cols-8 xl:grid-cols-12 gap-x-4"
  >
    <div
      v-if="title"
      v-html="title"
      class="text-sm text-gray-600 font-medium mb-5"
      :class="classes"
    />
    <slot />
  </div>
</template>
<script>
  export default {
    props: {
      title: String,
      classes: {
        type: String,
        default: 'col-span-2',
      },
      itemsAlign: {
        type: String,
        default: 'items-baseline',
      },
    },
    data() {
      return {
        inputVisible: false,
      }
    },
  }
</script>
