<template>
  <BaseDataTable :columns="columns"
                 :url="url"
                 actions="search, refresh"
                 id="service-billing-invoices"
                 :is-expandable="isExpandable"
                 ref="table">
    <template v-slot:attributes.total.due="{row}">
      <template v-if="row.attributes.template === 'regular-invoice'">
        {{ $formatPrice(row.attributes.total.due) }}
      </template>
      <template v-else>
        {{ $formatPrice(row.attributes.total.total) }}
      </template>
    </template>
    <template v-slot:attributes.sent_count="{row}">
      <base-badge :type="row.attributes.sent_count > 0 ? 'success' : 'info'">
        {{ $tc('sent times', row.attributes.sent_count, { count: row.attributes.sent_count }) }}
      </base-badge>
    </template>
    <template v-slot:attributes.template="{row}">
      <base-tooltip v-if="row.attributes.template === 'regular-invoice'"
                    :content="$t('Regular')">
        <div>
          {{ $t('R') }}
        </div>
      </base-tooltip>
      <base-tooltip v-if="row.attributes.template === 'annual-maintenance'"
                    :content="$t('Annual')">
        <div>
          {{ $t('A') }}
        </div>
      </base-tooltip>
    </template>
    <template v-slot:expand-content="{row}">
      <ServiceBillingInvoiceItems :service-billing-invoice="row.originalRow"
                           id="service-billing-invoices-items"
      />
    </template>
    <template v-slot:extra-actions="{row}">
      <table-download-button
          :loading="row.downloading"
          @click="downloadServiceInvoice(row)"
      />
      <table-icon-button v-if="hasEmail"
                         :content="$t('Send Email')"
                         @click="sendEmail(row)">
        <MailIcon class="w-4 h-4" />
      </table-icon-button>
    </template>
  </BaseDataTable>
</template>
<script>
  import { MailIcon } from 'vue-feather-icons'
  import { downloadFileForEntity } from '@/modules/common/util/downloadFileLocally'
  import ServiceBillingInvoiceItems from '@/modules/accounts-receivable/pages/customer/resources/ServiceBillingInvoiceItems'

  export default {
    components: {
      MailIcon,
      ServiceBillingInvoiceItems,
    },
    props: {
      showPagination: {
        type: Boolean,
        default: false,
      },
      hasEmail: {
        type: Boolean,
        default: false,
      },
      customerId: String,
    },
    data() {
      return {
        columns: [
          {
            label: this.$t('Date'),
            prop: 'attributes.date',
            maxWidth: 150,
            component: 'FormattedDate',
          },
          {
            label: this.$t('Downloaded'),
            prop: 'attributes.downloaded_at',
            maxWidth: 150,
            align: 'center',
            component: 'FormattedDate',
          },
          {
            label: this.$t('Sent'),
            prop: 'attributes.sent_count',
            maxWidth: 100,
            align: 'center',
          },
          {
            label: this.$t('Invoice #'),
            prop: 'attributes.invoice_number',
            minWidth: 120,
            maxWidth: 150,
          },
          {
            label: this.$t('Amount'),
            prop: 'attributes.total.due',
            maxWidth: 130,
          },
          {
            label: this.$t('Type'),
            prop: 'attributes.template',
            maxWidth: 80,
            align: 'center',
          },
        ],
      }
    },
    computed: {
      url() {
        return `restify/service-billing-invoices?customer_id=${this.customerId}`
      },
      isExpandable() {
        return !this.isProduction
      }
    },
    methods: {
      async downloadServiceInvoice(row) {
        await downloadFileForEntity(row, 'service-billing-invoices')
        this.$refs.table.refresh()
      },
      async sendEmail(row) {
        const objToEmit = {
          row,
          entityName: 'Invoice',
          action: '/restify/service-billing-invoices/action?action=send-service-billing-invoice',
        }
        await this.$emit('send-entity-email', objToEmit)
        this.$refs.table.refresh()
      },
    },
  }
</script>
