<template>
  <base-alert
      :type="promptTypes.Danger"
      class="my-4"
  >
    <p class="font-semibold mb-2 px-4">
      {{ $t('The following errors have been identified when generating the Proof Listing.') }}<br>
      {{
        $t('As per our accounting rules, we are unable to generate the proof listing. Please ensure all errors are resolved before trying again.')
      }}
    </p>
    <div class="flex justify-between">
      <ul class="px-8">
        <li
            v-for="errorMessage in prerequisitesValidation.prerequisites_errors"
            :key="errorMessage"
            class="py-0.5"
        >
          <router-link
              v-if="getLink(errorMessage)"
              :to="getLink(errorMessage)"
              target="_blank"
              class="text-blue-500 hover:text-blue-700"
          >
            {{ errorMessage }}
          </router-link>
          <span v-else>
            {{ errorMessage }}
          </span>
        </li>
      </ul>

      <div>
        <base-button
            variant="white"
            @click="fetchData"
        >
          <RefreshCwIcon class="w-4 h-4 mx-2"/>
          {{ $t('Try again') }}
        </base-button>
      </div>
    </div>
  </base-alert>
</template>
<script setup lang="ts">
  import { PropType } from 'vue'
  import { RefreshCwIcon } from 'vue-feather-icons'
  import { promptTypes } from '@/plugins/promptProps'
  import { ProofListingPrerequisitesValidation } from "@/modules/common/components/proof-listing/proofListingTypes";

  const props = defineProps({
    prerequisitesValidation: {
      type: Object as PropType<ProofListingPrerequisitesValidation>,
      required: true,
    },
  })

  const emit = defineEmits(['data-fetch'])

  function fetchData() {
    emit('data-fetch')
  }

  const linksMap: Record<string, string> = {
    'Job Costing settings': '/job-costing/settings/job-cost-settings',
    'Accounts Payable settings': '/accounts-payable/settings/ap-settings',
    'Accounts Receivable settings': '/accounts-receivable/settings/accounts-receivable-settings',
  }

  function getLink(error: string) {
    let link = ''

    Object.keys(linksMap).forEach((key) => {
      if (error.includes(key)) {
        link = linksMap[key]
      }
    })

    return link
  }
</script>
