<template>
  <div class="flex items-center">

    <div class="flex space-x-4 items-center">
      <VendorLink
        :data="vendor"
        :show-preview="false"
        link-class="text-sm text-gray-900"
      />
      <BaseCheckbox
        v-model="selectedAll"
        :label="$t('Select All (Pay In Full)')"
        :id="`${rowIndex}-select-all`"
        @change="updateGroupInvoices($event)"
      />
    </div>

    <div class="absolute right-24 text-sm text-gray-900 font-semibold">
      {{ $formatPrice(vendorInvoicesTotal) }}
    </div>

  </div>
</template>
<script>
import { paymentStatuses } from "@/modules/accounts-payable/components/payment/types";
import { useStorage } from '@vueuse/core'
const STORAGE_KEY = 'selectedAPInvoicesToPay'

export default {
  setup() {
    const selectedToPay = useStorage(STORAGE_KEY, {})
    return {
      selectedToPay
    }
  },
  data()  {
    return {
      selectedAll: false,
    }
  },
  computed: {
    data() {
      return this.params.node?.allLeafChildren[0]?.data || {}
    },
    rowIndex() {
      return this.params.rowIndex
    },
    vendor() {
      return this.get(this.data, 'relationships.vendor', {})
    },
    groupInvoices() {
      return this.params.node?.allLeafChildren.map(child => child.data)
    },
    paidStatus() {
      return this.params.paidStatus
    },
    vendorId() {
      return this.vendor?.id
    },
    selectedVendorInvoices() {
      const invoicesToPay = Object.values(this.selectedToPay || {})
      return invoicesToPay.filter(invoice => invoice.vendor_id === this.vendorId)
    },
    vendorInvoicesTotal() {
      return this.selectedVendorInvoices.reduce((acc, invoice) => acc + invoice.paid_amount, 0)
    },
  },
  methods: {
    updateGroupInvoices(selected) {
      const groupInvoices = this.groupInvoices
      const status = selected ? paymentStatuses.Paid : paymentStatuses.NotPaid
      this.params?.updateGroupInvoicesStatus(groupInvoices, status)

      this.updateChildInvoices(selected)
    },
    updateChildInvoices(selected) {
      const payment_status = selected ? this.paidStatus : null
      this.params.node.allLeafChildren.forEach(child => {
        child.setData({
          ...child.data,
          payment_status,
        })
      })
    },
    setSelectedAllDefaultState() {
      this.selectedAll = this.params.node.allLeafChildren.every(child => child.data.payment_status === this.paidStatus)
    }
  },
  mounted() {
    this.setSelectedAllDefaultState()
  }
}
</script>
