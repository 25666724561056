<template>
  <base-tooltip :content="$t('Recompute regular/ovetime/premium hours')" :tabindex="-1">
    <base-button
      :loading="loading"
      :size="size"
      variant="white"
      v-bind="$attrs"
      @click="syncTimesheets"
    >
      <RepeatIcon class="w-5 h-5 text-gray-500"/>
    </base-button>
  </base-tooltip>
</template>
<script>
import TableSyncButton from "@/components/table/actions/TableSyncButton.vue";
import axios from "axios";
import { RepeatIcon } from "vue-feather-icons";

export default {
  components: { RepeatIcon, TableSyncButton },
  props: {
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
    employeeIds: {
      type: Array,
    },
    size: {
      type: String,
    }
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    async syncTimesheets() {
      const confirm = await this.$confirm({
        title: this.$t('Recompute Timesheet Hours'),
        description: this.$t('The following action will recompute the timesheet regular, overtime and premium hour totals. Are you sure you want to proceed?'),
        buttonText: this.$t('Recompute'),
      })
      if (!confirm) {
        return
      }
      try {
        this.loading = true
        await axios.post(`/restify/timesheets/actions?action=redistribute-timesheet-minutes`, {
          start_date: this.startDate,
          end_date: this.endDate,
          only_employee_ids: this.employeeIds,
        })
        this.$emit('save')
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style>
</style>
