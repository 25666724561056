<template>
  <BaseFormDialog
      v-if="showOverridesDialog"
      :title="$t('Sub-Invoice Override')"
      :appendToBody="true"
      :open.sync="showOverridesDialog"
      size="xl"
      @close="onClose">
    <base-form
        :show-cancel="true"
        :update-text="$t('Update')"
        :focus-on-first-input="false"
        layout="modal"
        @cancel="onClose"
        @submit="onClose"
    >
      <base-input v-model="params.data.invoice_number"
                  :label="$t('Sub Invoice #')"
                  :placeholder="$t('Sub Invoice #')"
                  id="number"
                  rules="max:16"
                  class="col-span-8 md:col-span-2"
      />
      <base-date-picker
          v-model="params.data.invoice_date"
          :label="$t('Date')"
          :placeholder="$t('Date')"
          id="number"
          rules="required"
          class="col-span-8 md:col-span-1"
      />
      <base-input v-model="params.data.description"
                  :label="$t('Invoice Description')"
                  :placeholder="$t('Description')"
                  id="description"
                  rules="max:150"
                  class="col-span-6 md:col-span-3"
      />
    </base-form>
  </BaseFormDialog>
</template>
<script>

export default {
  data() {
    return {
      showOverridesDialog: false,
      entryToOverride: this.params?.data || {},
    }
  },
  methods: {
    async onClose() {
      this.showOverridesDialog = false
      this.params?.stopEditing()
    },
  },
  mounted() {
    this.entryToOverride = this.params?.data || {}
    this.showOverridesDialog = true
  },
}
</script>
