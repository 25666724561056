<template>
  <entity-select
      v-bind="$attrs"
      v-on="$listeners"
      :add-entity="false"
      :data="options"
      :label="$t(label)"
      :value-key="valueKey"
      label-key="fiscal_year"
  />
</template>
<script>
  export default {
    inheritAttrs: false,
    props: {
      label: {
        type: String,
        default: 'Fiscal Year',
      },
      valueKey: {
        type: String,
        default: 'id',
      },
      onlyOpenedYears: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      options() {
        return this.onlyOpenedYears ? this.companyOpenedYears : this.companyYears
      },
      companyYears() {
        return this.$store.state.company.fiscalYears
      },
      companyOpenedYears() {
        return this.companyYears.filter(year => !year?.attributes?.closed_at)
      },
    },
  }
</script>
