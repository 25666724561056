<template>
  <div>
    <base-go-to-edit-alert
        v-if="!!existingAdjustmentId && !model.id"
        :path="`/payroll/adjustments/${model.type}/${model.employee_id}/edit`"
        message="Adjustments for this employee and type already exist. Please select another employee or type, or edit."
    />
    <base-form
      :show-back="true"
      :loading="loading"
      :show-cancel="true"
      :save-text="saveText"
      :update-text="updateText"
      :submit-disabled="!!existingAdjustmentId && !model.id"
      layout="vertical"
      grid-classes="md:grid md:grid-cols-3 gap-x-3"
      submit-button-type="button"
      @submit="onSubmit"
      @cancel="$router.push('/payroll/adjustments')"
    >
      <employee-select-new
        v-model="model.employee_id"
        :initial-value="employee"
        :key="employee.id"
        rules="required"
        id="employee"
        class="md:col-span-1"
        @change="validateSelection"
      />
      <base-select
        v-model="model.type"
        :options="codeTypes"
        :label="$t('Type')"
        :name="$t('Type')"
        rules="required"
        id="type"
        class="md:col-span-1"
        @change="validateSelection"
      />
      <adjustment-entries
        v-show="showEntriesTable"
        :data="model"
        ref="entriesTable"
        class="col-span-3"
      />
    </base-form>
  </div>
</template>
<script>
import axios from 'axios'
import { adjustmentTypes } from '@/modules/payroll/pages/adjustment/util'
import AdjustmentEntries from '@/modules/payroll/components/adjustment/AdjustmentEntries'

export default {
  components: {
    AdjustmentEntries,
  },
  data() {
    return {
      employee: {},
      loading: false,
      codeTypes: [
        {
          label: this.$t('All'),
          value: adjustmentTypes.ALL,
        },
        {
          label: this.$t('Earnings'),
          value: adjustmentTypes.EARNINGS,
        },
        {
          label: this.$t('Benefits'),
          value: adjustmentTypes.BENEFITS,
        },
        {
          label: this.$t('Taxes'),
          value: adjustmentTypes.TAXES,
        },
        {
          label: this.$t('Deductions'),
          value: adjustmentTypes.DEDUCTIONS,
        },
        {
          label: this.$t('W-2 Memos'),
          value: adjustmentTypes.W2MEMOS,
        },
      ],
      selectedEmployee: this.$route.params?.employee_id,
      model: {
        employee_id: '',
        type: adjustmentTypes.EARNINGS,
      },
      existingAdjustmentId: false,
      validSelection: true,
    }
  },
  computed: {
    saveText() {
      return this.$t(`Save ${this.model.type}`)
    },
    updateText() {
      return this.$t(`Update ${this.model.type}`)
    },
    showEntriesTable() {
      if (this.selectedEmployee) {
        return true
      }

      return this.model.employee_id && this.validSelection
    },
  },
  methods: {
    async getEmployee(id) {
      try {
        this.loading = true
        const { data } = await axios.get(`/restify/employees/${id}`, {
          params: {
            ignore404: true
          }
        })
        this.employee = data
      } catch (err) {
        console.warn(err)
      } finally {
        this.loading = false
      }
    },
    async validateSelection() {
      await this.$nextTick()

      this.existingAdjustmentId = false

      if (!this.model.employee_id || !this.model.type) {
        return
      }
      const { type, employee_id } = this.model
      await this.$router.replace(`/payroll/adjustments/${type}/${employee_id}/edit`)

      const { data } = await axios.get('/restify/ytds', {
        params: {
          employee_id: this.model.employee_id,
          type: this.model.type,
        },
      })

      this.existingAdjustmentId = this.get(data, '[0].id', '')
      this.validSelection = !this.existingAdjustmentId

      if (this.model.id) {
        await this.refreshEmployee(this.model.id)
      }
    },
    async onSubmit() {
      try {
        this.loading = true
        await this.$refs.entriesTable.storeProgress(this.model.employee_id)
        this.$success(this.$t('Employee adjustments updated successfully!'))
      } catch (err) {
        console.warn(err)

        if (err.handled) {
          return
        }
        this.$error(this.$t('Could not store adjustments, please try again.'))
      } finally {
        this.loading = false
      }
    },
    refreshEmployee(employeeId) {
      if (!employeeId) {
        return
      }

      this.model = {
        id: employeeId,
        employee_id: employeeId,
        type: this.$route.params.type,
      }
      this.getEmployee(employeeId)
    }
  },
  watch: {
    '$route.params': {
      immediate: true,
      handler(params) {
        this.refreshEmployee(params.employee_id)
      },
    },
  },
}
</script>
