<template>
  <DirectDepositForm :hide-employee-selection="true"/>
</template>
<script>
  import DirectDepositForm from '@/modules/payroll/components/direct-deposit/DirectDepositForm'

  export default {
    components: {
      DirectDepositForm,
    },
  }
</script>
