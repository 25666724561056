<template>
  <div class="ledger-journal">
    <BaseDataTable
        :columns="columns"
        :url="url"
        :url-params="urlParams"
        :actions="tableActions"
        :default-match-filters="defaultMatchFilters"
        :data-map-function="mapJournals"
        :add-entity-in-new-tab="addEntityInNewTab"
        :select-row-filter="row => !isRowSelectionDisabled(row)"
        :add-text="$t('New journal')"
        default-sort="-updated_at"
        import-url="/ledger/journal/import"
        permission="journals"
        ref="table"
        base-entity-path="/ledger/journal"
        add-entity-url-query="/ledger/journal/add"
        @add="$router.push('/ledger/journal/add')"
        @data-fetch="journals = $event"
        @on-apply-filters="onApplyFilters"
        @all-selected="onAllSelected"
    >
      <template #select="{row}">
        <base-checkbox v-model="row.selected"
                       :disabled="isRowSelectionDisabled(row)"
        />
      </template>
      <template #attributes.period="{row}">
        {{ getPeriodName(row.attributes.period) }}
      </template>
      <template #extra-actions="{row}">
        <template v-if="$isAuthorized('authorizedToPost', row)">
          <ProofListingButton
              :selected-rows="[row]"
              path="/ledger/journal/proof-listing"
              class="mr-2"
          />
          <JournalEntriesExport
            :id="row.id"
            size="xs"
            class="!mr-0"
          />
        </template>
      </template>
      <template #attributes.name="{row}">
        <div class="flex items-center">
          <postable-tooltip :is-postable="$isAuthorized('authorizedToPost', row)"/>
          <div class="truncate">
            <router-link :to="`/ledger/journal/${row.id}/view`">
              {{ row.attributes.name }}
            </router-link>
          </div>
        </div>
      </template>
      <template #additional-actions>
        <ProofListingButton
            :disabled="selectedRows.length === 0"
            :selected-rows="selectedRows"
            path="/ledger/journal/proof-listing"
            class="mr-2"
        />
      </template>
    </BaseDataTable>
  </div>
</template>
<script>
  import axios from 'axios'
  import { GeneralLedgerJournalCode } from '@/enum/journal'
  import JournalEntriesExport from "@/modules/ledger/components/journals/JournalEntriesExport.vue";

  export default {
    components: { JournalEntriesExport },
    props: {
      moduleCode: {
        type: String,
        default: GeneralLedgerJournalCode,
      },
    },

    data() {
      return {
        url: '/restify/journals',
        addEntityInNewTab: true,
        allPagesSelected: false,
        journals: [],
        entityFilters: {
          created_by: true,
        },
      }
    },
    computed: {
      defaultMatchFilters() {
        return this.createByMeFilter
      },
      columns() {
        let result = [
          {
            label: '',
            prop: 'select',
            minWidth: 40,
            maxWidth: 40,
            smallCell: true,
          },
          {
            label: this.$t('Name'),
            prop: 'attributes.name',
            minWidth: 150,
            maxWidth: 200,
          },
        ]
        if (!this.entityFilters?.created_by) {
          result.push(
              {
                label: this.$t('Created By'),
                prop: 'attributes.created_by',
                minWidth: 120,
                maxWidth: 150,
                component: 'UserLink',
              },
          )
        }
        result.push(
            {
              label: this.$t('Period'),
              prop: 'attributes.period',
              minWidth: 120,
              maxWidth: 200,
            },
            {
              label: this.$t('Year'),
              prop: 'attributes.fiscal_year',
              minWidth: 120,
              maxWidth: 200,
            },
            {
              label: this.$t('Credit Amount'),
              prop: 'attributes.total_credit',
              maxWidth: 130,
              component: 'FormattedPrice',
            },
            {
              label: this.$t('Debit Amount'),
              prop: 'attributes.total_debit',
              maxWidth: 130,
              component: 'FormattedPrice',
            },
            {
              label: this.$t('Type'),
              prop: 'attributes.type',
              maxWidth: 150,
              component: 'Status',
            },
        )
        return result
      },
      urlParams() {
        return {
          code: this.moduleCode,
          'fiscal_year': this.activeFiscalYear,
          'status': 'pending',
          'sort': '-fiscal_year,-period',
        }
      },
      tableActions() {
        let baseActions = `search,refresh,print,`
        if (this.$can('journals_store')) {
          baseActions += `,add`
        }
        if (this.$can('journals_update')) {
          baseActions += `,import`
        }
        return baseActions
      },
      selectedRows() {
        return this.journals.filter(e => e.selected)
      },
    },
    methods: {
      onAllSelected(value) {
        this.allPagesSelected = value
      },
      onApplyFilters(filters) {
        this.entityFilters = filters
      },
      getPeriodName(period) {
        return this.$store.getters['company/getPeriodName'](period)
      },
      mapJournals(journals) {
        this.allPagesSelected = false
        return journals.map(entry => ({ ...entry, selected: false }))
      },
      isRowSelectionDisabled(row) {
        return !this.$isAuthorized('authorizedToPost', row)
      },
    },
  }
</script>
