<template>
  <FinancialReport
      :report-id="currentResource.id"
      :should-cache="false"
  />
</template>
<script setup lang="ts">
  import FinancialReport from "@/modules/ledger/components/reports/FinancialReport.vue";
  import { computed } from 'vue'

  import store from '@/store'

  const currentResource = computed(() => {
    return store.state.generalLedger.currentFinancialReport || {}
  })
</script>
