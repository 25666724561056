<template>
  <SendEmailForm
      :data="data"
      :entity="entity"
      :subject="subject"
      :action-url="`/restify/purchase-orders/${purchaseOrder.id}/actions`"
      contact-entity="vendor"
      action-name="send-purchase-order-mail"
      @save="emit('save')"
  />
</template>
<script lang="ts" setup>
import {computed, PropType} from 'vue'
import i18n from "@/i18n";
import SendEmailForm from "@/components/form/SendEmailForm.vue";
import {PurchaseOrder} from "@/modules/common/types/models";
import Data = API.Data;

const props = defineProps({
  purchaseOrder: {
    type: Object as PropType<Data<PurchaseOrder>>,
    default: () => ({}),
    required: true
  },
  entity: {
    type: String,
  }
})
const emit = defineEmits(['save'])

const subject = computed(() => {
  const title = i18n.t('Purchase Order')
  return `${title} #${props.purchaseOrder?.attributes?.number}`
})

const data = computed(() => {
  return {
    ...props.purchaseOrder,
    vendor: props.purchaseOrder?.relationships?.vendor,
  }
})
</script>
