<template>
  <div class="flex justify-between w-full transaction-group-row">
    <ReferenceLink
        :data="data"
        :id="journalId"
        :show-description="true"
    />
  </div>
</template>
<script>
  export default {
    name: 'TransactionsGroupRow',
    computed: {
      data() {
        return this.params.node?.allLeafChildren[0]?.data || {}
      },
      invoice() {
        return this.data || {}
      },
      level() {
        return this.params.node.level
      },
      journalId() {
        return this.params.journalId
      },
    },
  }
</script>
<style>
  .transaction-group-row a {
    @apply text-gray-900;
  }
</style>
