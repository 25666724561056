<template>
  <div/>
</template>
<script>
  import axios from 'axios'
  import { costTypes } from '@/enum/enums'
  import PhaseCostInput from '@/components/common/PhaseCostInput';
  import sortBy from "lodash/sortBy";
  import Cache from '@/utils/Cache'

  export default {
    components: {
      PhaseCostInput,
    },
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      job: {
        type: Object,
        default: () => ({}),
      },
      redirectToListAfterStore: {
        type: Boolean,
        default: true,
      },
      type: {
        type: String,
        default: costTypes.Cost,
      },
    },
    data() {
      return {
        budgetsLoading: false,
        defaultJobTypes: [],
      }
    },
    computed: {
      getSiblingData() {
        return this.$store.getters['sibling/getData']
      },
      getJobInitialValue() {
        return this.get(this.data, 'relationships.job') || this.job
      },
      layout() {
        return this.redirectToListAfterStore ? 'vertical' : 'modal'
      },
      showBack() {
        return this.redirectToListAfterStore
      },
    },
    methods: {
      onJobChange() {
        this.model.phase_code = ''
        this.model.cost_code = ''
        this.model.change_order = 0
        this.model.description = ''
      },
      onCancel() {
        if (this.redirectToListAfterStore) {
          return this.$router.push('/job-costing/cost-line-items')
        }
        this.$emit('cancel')
      },
      async assignBudgets(lineItemId) {
        const budgets = this.budgets.map((budget) => ({
          id: budget.id,
          line_item_id: lineItemId,
          job_type_id: budget.job_type_id,
          amount: budget.amount || 0,
          quantity: budget.quantity || 0,
          um: budget.um,
          unit_rate: budget.unit_rate || 0,
        }))

        const budgetsToSave = budgets.filter(b => !b.id) || []
        const budgetsToUpdate = budgets.filter(b => b.id) || []

        if (budgetsToSave.length) {
          await axios.post(`/restify/job-budgets/bulk`, budgetsToSave)
        }
        if (budgetsToUpdate.length) {
          await axios.post(`/restify/job-budgets/bulk/update`, budgetsToUpdate)
        }
      },
      async getLineItemBudgets() {
        try {
          this.budgetsLoading = true
          await this.getDefaultJobTypes()

          let lineItemBudgets = this.data?.relationships?.budgets || []
          this.budgets = this.defaultJobTypes.map(jobType => {
            const matchingBudget = lineItemBudgets.find(budget => budget.attributes.job_type_id === jobType.id)

            const { job_type_id, amount, quantity, um, unit_rate } = matchingBudget?.attributes || {}
            const { name, abbr } = jobType

            if (matchingBudget) {
              return {
                id: matchingBudget.id,
                name,
                abbr,
                for: jobType.for,
                job_type_id,
                amount,
                quantity,
                um,
                unit_rate
              }
            }
            const defaultTotals = this.model?.typeTotals?.[abbr] || {}

            return {
              name,
              abbr,
              for: jobType.for,
              job_type_id: jobType.id,
              amount: defaultTotals?.amount || 0,
              quantity: defaultTotals?.quantity || 0,
              um: defaultTotals?.um || '',
              unit_rate: defaultTotals?.unit_rate || 0,
            }
          })

          this.budgets = sortBy(this.budgets, 'index')
        } finally {
          this.budgetsLoading = false
        }
      },
      async getDefaultJobTypes() {
        const { data } = await Cache.getRequest('/restify/job-types', {
          params: {
            type: this.type,
            sort: 'index',
          },
        })
        const jobTypes = data.map(type => {
          return {
            ...type.attributes,
            amount: 0,
            unit_price: 0,
            um: '',
            quantity: 0,
          }
        })
        this.defaultJobTypes = sortBy(jobTypes, 'index')
      },
      fillForm(data) {
        this.model = {
          ...this.model,
          ...data,
          id: '',
        }
      },
    },
    watch: {
      getSiblingData: {
        handler(value) {
          this.fillForm(value)
          this.getLineItemBudgets()
        },
      },
      'data.attributes': {
        immediate: true,
        handler(value) {
          this.getLineItemBudgets()
          if (!value?.id) {
            return
          }
          this.model = {
            ...this.model,
            ...value,
          }
          if (!value.additional_description || typeof value.additional_description === 'string') {
            this.model.additional_description = []
          }
          if (this.model.additional_description?.[0]) {
            this.model.has_additional_description = true
          }
        },
      },
    },
  }
</script>
