<template>
  <div>
    <FinancialReportForm :financial-report="currentResource"/>
  </div>
</template>
<script setup lang="ts">
  import FinancialReportForm from "@/modules/ledger/pages/settings/financial-reports/FinancialReportForm.vue"
  import { computed } from 'vue'

  import store from '@/store'

  const currentResource = computed(() => {
    return store.state.generalLedger.currentFinancialReport || {}
  })
</script>
