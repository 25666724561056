<template>
  <base-form :layout="layout"
             :loading="loading"
             :show-cancel="showCancel"
             :show-back="showBack"
             :save-text="$t('Create customer')"
             :update-text="$t('Update customer')"
             :can-create-another-entity="!model.id"
             grid-classes="grid grid-cols-8 gap-x-4"
             @cancel="onCancel"
             @submit="onSubmit"
  >
    <base-input v-model="model.name"
                :label="$t('Name')"
                :placeholder="$t('Customer Name')"
                id="name"
                rules="required|max:35"
                class="col-span-8 md:col-span-2"
                @blur="onNameBlur"
    />
    <code-input
      v-model="model.code"
      :model="model"
      :resource-name="globalResources.Customers"
      class="col-span-8 md:col-span-2 xl:col-span-1"
    />
    <base-input v-model="model.email"
                :label="$t('Email')"
                :placeholder="$t('Email')"
                id="email"
                rules="email"
                class="col-span-8 md:col-span-2"
    />
    <base-select v-model="model.status"
                 :label="$t('Status')"
                 :placeholder="$t('Status')"
                 :options="statusOptions"
                 id="status"
                 class="col-span-8 md:col-span-2 xl:col-span-1"
    />
    <div class="col-span-8">
      <h5 class="form-section-title">
        {{ $t('Additional Information') }}
      </h5>
    </div>
    <base-phone-input v-model="model.phone"
                      id="phone"
                      class="col-span-8 md:col-span-2 lg:col-span-2"
    />
    <base-phone-input v-model="model.fax"
                      :label="$t('Fax')"
                      id="fax"
                      class="col-span-8 md:col-span-4 lg:col-span-2"
    />
    <base-input v-model="model.website_url"
                :name="$t('Website URL')"
                :label="$t('Website URL')"
                :placeholder="$t('Enter website url...')"
                id="website_url"
                class="col-span-8 md:col-span-4 lg:col-span-2"
                rules="url"
    >
      <template v-slot:suffix>
        <ExternalLinkIcon class="w-4 h-4"/>
      </template>
    </base-input>
    <base-input v-model="model.contact"
                :label="$t('Contact')"
                :placeholder="$t('Contact')"
                id="contact"
                rules="max:16"
                class="col-span-8 md:col-span-2"
    />
    <div class="col-span-8"></div>
    <account-select v-model="model.account"
                    :label="$t('Normal G/L Account')"
                    :placeholder="$t('Account')"
                    value-key="number"
                    class="col-span-8 md:col-span-2"
                    clearable
    />
    <sub-account-select v-model="model.subaccount"
                        :label="$t('Sub Account')"
                        :placeholder="$t('Sub Account')"
                        value-key="number"
                        class="col-span-8 md:col-span-2 lg:col-span-1"
                        clearable
    />
    <base-textarea v-model="model.terms"
                   :label="$t('Customer Terms')"
                   :placeholder="$t('Customer Terms')"
                   id="terms"
                   class="col-span-8 md:col-span-4"
    />

    <template v-if="!isProduction">
      <BillingRateTypeSelect
          v-model="model.billing_rate_type_id"
          :label="$t('Normal Billing Rate')"
          :placeholder="$t('Normal Billing Rate')"
          :add-entity="true"
          id="billing_rate_type_id"
          rules="required"
          class="col-span-8 md:col-span-2"
      />
      <CostCenterDefaults
          v-model="model"
          :lineItemType="$globalResources.IncomeLineItems"
          :is-customer-resource="true"
          class="col-span-8"
      />
    </template>
    <div class="col-span-8">
      <el-collapse v-model="activeGroups">
        <base-collapse-section :title="$t('Addresses & Contacts')"
                               key="addresses_and_contacts"
                               name="addresses_and_contacts"
        >
          <div class="grid grid-cols-2 gap-x-4">
            <div class="col-span-2 md:col-span-1">
              <AddressList :entityId="model.id"
                           :data="addresses"
                           :resource="model"
                           entity="customers"
                           ref="addressList"
                           @refresh="$emit('refresh')"
              />
            </div>
            <div class="col-span-2 md:col-span-1">
              <ContactList :contacts="contacts"
                           :entity-id="model.id"
                           ref="contactList"
                           entity="customers"
                           @refresh="$emit('refresh')"
              />
            </div>
          </div>
        </base-collapse-section>
        <base-collapse-section key="taxes"
                               :title="$t('Taxes')"
                               name="taxes">
          <div class="grid grid-cols-8 gap-x-4">
            <sales-tax-district-select
              v-if="!isProduction"
              v-model="model.district_id"
              :on-map-entry="onSelectDistrict"
              :add-entity="true"
              class="col-span-8 md:col-span-2"
              clearable
            />
            <base-input
              v-model="model.sales_tax_percent"
              v-bind="$validator.salesTaxPercent"
              :name="$t('Sales Tax')"
              :label="$t('Sales Tax')"
              id="sales_tax_percent"
              type="number"
              format="percent"
              class="col-span-8 md:col-span-2 xl:col-span-1"
            />
            <div class="col-span-8 md:col-span-2">
              <base-switch v-model="model.exempt_from_late_charges"
                           :label-info="$t('Exempt from Late Charges')"
                           id="exempt_from_late_charges"
              />
            </div>
            <div class="col-span-8 md:col-span-2">
              <base-switch
                v-model="model.exempt_from_sales_tax"
                :label-info="$t('Exempt From Sales Tax')"
                id="exempt_from_sales_tax"
              />
            </div>
          </div>
        </base-collapse-section>
        <base-collapse-section key="sales"
                               :title="$t('Sales Activity')"
                               name="taxes">
          <div class="grid grid-cols-8 gap-x-4">
            <base-input v-model="model.sales_activity_this_year"
                        :disabled="model.id"
                        :name="$t('This Year')"
                        :label="$t('This Year')"
                        id="sales_activity_this_year"
                        :min="0"
                        :step="0.01"
                        type="number"
                        format="price"
                        rules="max_value:99999999.99|min_value:0"
                        class="col-span-8 md:col-span-2"
                        @blur="onSalesActivityThisYearBlur"
            />
            <base-input v-model="model.sales_activity_last_year"
                        :disabled="model.id"
                        :name="$t('Last Year')"
                        :label="$t('Last Year')"
                        id="sales_activity_last_year"
                        :min="0"
                        :step="0.01"
                        type="number"
                        format="price"
                        rules="max_value:99999999.99|min_value:0"
                        class="col-span-8 md:col-span-2"
                        @blur="onSalesActivityLastYearBlur"
            />
            <base-input v-model="model.sales_activity_to_date"
                        :name="$t('To-Date')"
                        :label="$t('To-Date')"
                        id="sales_activity_to_date"
                        :min="0"
                        :step="0.01"
                        type="number"
                        format="price"
                        rules="max_value:99999999.99|min_value:0"
                        class="col-span-8 md:col-span-2"
            />
          </div>
        </base-collapse-section>
      </el-collapse>
    </div>
  </base-form>
</template>
<script>
  import axios from 'axios'
  import { isProduction } from '@/isProduction'
  import AddressList from '@/modules/common/components/AddressList'
  import ContactList from '@/modules/common/components/contacts/ContactList.vue'
  import { generateCodeFromName } from "@/utils/generateCodeFromName";
  import CostCenterDefaults from '@/components/common/CostCenterDefaults'
  import { costCenterTypes } from '@/components/grid-table/utils/cost-center'
  import { globalResources, RestifyResources } from "@/components/form/util";
  import { ExternalLinkIcon } from "vue-feather-icons";

  export default {
    inject: isProduction() ? []: ['defaultProps'],
    components: {
      ExternalLinkIcon,
      AddressList,
      ContactList,
      CostCenterDefaults,
    },
    props: {
      redirectToListAfterStore: {
        type: Boolean,
        default: true,
      },
      data: {
        type: Object,
        default: () => ({}),
      },
      showBack: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      const { name, addresses: addresses = [] } = this.defaultProps?.get || {}
      return {
        RestifyResources,
        globalResources,
        activeGroups: [],
        loading: false,
        showCancel: true,
        contacts: [],
        addresses: addresses,
        model: {
          name: name,
          code: '',
          phone: '',
          fax: '',
          contact: '',
          terms: '',
          website_url: '',
          billing_rate_type_id: undefined,
          status: 'active',
          exempt_from_late_charges: false,
          exempt_from_sales_tax: false,
          sales_tax_percent: 0,
          district_id: undefined,
          email: undefined,
          sales_activity_this_year: 0,
          sales_activity_last_year: 0,
          sales_activity_to_date: 0,

          cost_center: costCenterTypes.GeneralAndAdministrative,
          account: undefined,
          subaccount: undefined,
          source_id: undefined,
          type_id: undefined,
          addl_source_id: undefined,
        },
        statusOptions: [
          {
            label: this.$t('Active'),
            value: 'active',
          },
          {
            label: this.$t('Inactive'),
            value: 'inactive',
          },
          {
            label: this.$t('Collections'),
            value: 'collections',
          },
        ],
      }
    },
    computed: {
      layout() {
        return this.redirectToListAfterStore ? 'vertical': 'modal'
      },
    },
    methods: {
      onSalesActivityThisYearBlur() {
        this.model.sales_activity_to_date = +this.model.sales_activity_this_year
      },
      onSalesActivityLastYearBlur() {
        const sales = +this.model.sales_activity_this_year + +this.model.sales_activity_last_year
        if (!isNaN(sales)) {
          this.model.sales_activity_to_date = sales
        }
      },
      onSelectDistrict(district) {
        this.model.sales_tax_percent = district?.attributes?.sales_tax_percent
      },
      onNameBlur() {
        if (this.data.id) {
          return
        }
        this.model.code = generateCodeFromName(this.model.name)
      },
      onCancel() {
        if (this.redirectToListAfterStore) {
          return this.$router.push('/accounts-receivable/customers')
        }
        this.$emit('cancel')
      },
      async onSubmit() {
        try {
          this.loading = true
          const model = {
            ...this.model,
          }

          delete model.sales_activity_this_year
          delete model.sales_activity_last_year
          if (model.id) {
            const { data } = await axios.put(`/restify/customers/${model.id}`, model)
            this.$success(this.$t('Customer updated'))
            await this.$addSystemGeneratedNote({
              resourceName: RestifyResources.Customers,
              resourceId: data.id,
              isEdit: true,
            })
            this.$emit('refresh', data)
            this.$emit('save', data)
          } else {
            const { data } = await axios.post('/restify/customers', model)
            await this.initCustomerActivities(data.id)
            await this.attachAddressesAndContacts(data.id)
            this.$success(this.$t('Customer created'))
            await this.$addSystemGeneratedNote({
              resourceName: RestifyResources.Customers,
              resourceId: data.id,
            })
            if (this.$createAnotherEntity && this.redirectToListAfterStore) {
              return this.$emit('create-another')
            }

            if (this.redirectToListAfterStore) {
              await this.$router.push('/accounts-receivable/customers')
            }
            this.$emit('save', data)
          }
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Something went wrong. Please try again.'))
        } finally {
          this.loading = false
        }
      },
      async attachAddressesAndContacts(customerID) {
        await this.$refs.addressList.attachTemporaryAddresses(customerID)
        await this.$refs.contactList.attachTemporaryContacts(customerID)
      },
      async initCustomerActivities(customerId) {
        try {
          await axios.post(`/restify/customers/${customerId}/actions?action=customer-activities-init`, {
            sales_activity_last_year: this.model.sales_activity_last_year,
            sales_activity_this_year: this.model.sales_activity_this_year,
          })
        } catch (err) {
          console.log(err)
        }
      },
      onChangeState() {
        this.model.district_id = null
      },
    },
    watch: {
      data: {
        immediate: true,
        handler(value) {
          if (!value?.id) {
            return
          }
          this.model = {
            ...this.model,
            ...value.attributes,
          }
          this.contacts = value?.relationships?.contacts || []
          this.addresses = value?.relationships?.addresses || []
        },
      },
    },
  }
</script>
