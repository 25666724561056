<template>
    <div class="min-h-screen bg-white flex justify-center">
        <div class="hidden lg:block relative w-0 flex-1">
            <img class="absolute inset-0 h-full w-full object-cover"
                 src="/img/auth-background.jpeg"
                 alt=""/>
        </div>
        <div class="flex flex-col justify-between sm:px-6 lg:px-20 xl:px-24">
          <div class="flex-1 flex flex-col justify-center w-full max-w-xl py-12 px-4 lg:justify-start lg:flex-none lg:pt-56">
            <fade-transition mode="out-in" :duration="75">
              <router-view></router-view>
            </fade-transition>
          </div>
          <base-copyright/>
        </div>

    </div>
</template>
<script>
  export default {}
</script>
<style>
</style>
