<template>
  <EmployeeRatesForm :data="employee"
                     :rate-type="employeeRatesTypes.BenefitRates"
  />
</template>
<script>
  import axios from 'axios'
  import { employeeRatesTypes } from '@/modules/payroll/components/rates/util'
  import EmployeeRatesForm from '@/modules/payroll/components/employee-rates/EmployeeRatesForm'

  export default {
    components: {
      EmployeeRatesForm,
    },
    data() {
      return {
        employee: null,
        employeeRatesTypes,
      }
    },
    methods: {
      async getEmployee() {
        const { id } = this.$route.params
        if (!id) {
          return
        }
        const { data } = await axios.get(`/restify/employees/${id}?related=craftCode,union`)
        this.employee = data
      },
    },
    async mounted() {
      await this.getEmployee()
    },
  }
</script>
