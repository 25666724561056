<template>
  <div class="w-full">
    <div class="col-span-6">
      <base-select
          v-model="selectedColumns"
          :options="columnOptions"
          :label="$t('Columns')"
          :name="$t('Columns')"
          :placeholder="$t('Choose columns for export')"
          :rules="'required'"
          id="columns"
          multiple
          @change="triggerPreview"
      />
      <div class="tip mb-2 flex flex-col">
        <span class="font-medium mb-1">
          {{$t('Export Preview')}}
        </span>
        <span>
          {{ $t('Drag & drop columns to reorder them in the final export file') }}
        </span>
      </div>
      <AgDataTable
          :columns="tableColumns"
          :data="exportData"
          :pagination="false"
          :data-loading="loading"
          :suppressFieldDotNotation="true"
          domLayout="autoHeight"
          @grid-ready="onGridReady"
      />
    </div>
  </div>
</template>
<script>
  import startCase from 'lodash/startCase';
  import { exportActions, modelColumnMappings } from '@/components/table/actions/export/export-models/utils'
  import sortBy from "lodash/sortBy";
  import axios from "axios";
  import { costTypes } from "@/enum/enums";

  export default {
    props: {
      exportModel: {
        type: String,
        default: 'employee',
      },
      url: {
        type: String,
      },
      requestParams: {
        type: Object,
        default: () => ({})
      },
    },
    data() {
      return {
        selectedColumns: [],
        exportData: [],
        grid: null,
        loading: false,
      }
    },
    computed: {
      exportUrl() {
        const action = exportActions[this.exportModel] || exportActions['default'] || 'data-export'
        return `${this.url}/actions?action=${action}`
      },
      columns() {
        return modelColumnMappings[this.exportModel] || modelColumnMappings['default']
      },
      additionalColumns() {
        const isLineItemExport = this.exportModel === 'line-items'
        if (!isLineItemExport) {
          return []
        }
        const type = this.requestParams?.costType || this.requestParams?.type || ''
        const costBudgets = this.$store.getters['globalLists/getResourceOptions'](this.$globalResources.JobCostTypes)
        const incomeBudgets = this.$store.getters['globalLists/getResourceOptions'](this.$globalResources.JobIncomeTypes)

        let jobTypes = costBudgets
        if (type === costTypes.Income) {
          jobTypes = incomeBudgets
        }

        let types = type.split(',').filter(t => t)
        if (types.length === 0) {
          types = [costTypes.Income, costTypes.Cost]
        }
        let columns = this.mapBudgetTypes(jobTypes, types)
        if (types.length === 2) {
          columns = this.mapBudgetTypes(costBudgets, [costTypes.Income])
          columns = columns.concat(this.mapBudgetTypes(incomeBudgets, [costTypes.Cost]))
        }
        return columns
      },
      columnOptions() {
        let mappedColumns = this.columns
            .map(col => {
              return {
                value: col?.value || col,
                label: col?.label || startCase(col),
              }
            })
        mappedColumns = mappedColumns.concat(this.additionalColumns)
        return sortBy(mappedColumns, 'label')
      },
      tableColumns() {
        const colDefs = this.grid?.api.getColumnDefs() || []
        let mappedColumns = this.selectedColumns.map(colValue => {
          const column = this.columnOptions.find(col => col.value === colValue)
          let colDefIndex = colDefs.findIndex(c => c.field === colValue)
          if (colDefIndex === -1) {
            colDefIndex = this.selectedColumns.length
          }
          let valueFormatter
          if (colValue.includes('amount')) {
            valueFormatter = (params) => this.$formatPrice(params.value)
          }

          return {
            headerName: column?.label,
            field: colValue,
            minWidth: 150,
            valueFormatter,
            order: colDefIndex,
          }
        })
        return sortBy(mappedColumns, 'order')
      },
    },
    methods: {
      mapBudgetTypes(jobTypes, types) {
        let columns = []
        jobTypes.forEach(jobType => {
          types.forEach(type => {
            let prefix = `budgets.${type}.${jobType.index}`
            columns.push({
              value: `${prefix}.amount`,
              label: `${jobType.name} Budget`,
            })
            columns.push({
              value: `${prefix}.quantity`,
              label: `${jobType.name} Quantity`,
            })
            columns.push({
              value: `${prefix}.unit_rate`,
              label: `${jobType.name} Unit Price`,
            })
            columns.push({
              value: `${prefix}.um`,
              label: `${jobType.name} Unit of Measure`,
            })
          })
        })
        return columns
      },
      async onGridReady(grid) {
        this.grid = grid
        await this.getExportPreview()
      },
      async triggerPreview() {
        await this.$nextTick()
        await this.getExportPreview()
      },
      getSelectedColumns() {
        const tableColumns = this.grid?.api?.getColumnDefs() || []
        return tableColumns.map(c => c.field)
      },
      async getExportPreview() {
        try {
          this.loading = true
          const selectedColumns = this.getSelectedColumns()
          const postData = {
            format: 'csv',
            settings: {
              enclosure: '"',
            },
            columns: selectedColumns,
            repositories: 'all'
          }
          const params = {
            ...this.requestParams,
            page: 1,
            perPage: 10,
          }

          const data = await axios.post(this.exportUrl, postData, {
            params
          })
          this.exportData = this.transformData(data)
        } catch (err) {
          console.log(err)
        } finally {
          this.loading = false
        }
      },
      transformData(csvData) {
        const columnNames = this.tableColumns.map(c => c.field)
        let rows = csvData.split('\n').filter(r => r)
        // remove header
        rows.shift()
        rows = rows.map(row => {
          row = row.replaceAll('"', '')
          const rowValues = row.split(',')
          let rowObj = {
            _localId: crypto.randomUUID(),
          }
          columnNames.forEach((col, index) => {
            rowObj[col] = rowValues[index]
          })
          return rowObj
        })
        return rows
      },
    },
    async mounted() {
      if (this.selectedColumns.length === 0) {
        this.selectedColumns = this.columns
      }
    },
  }
</script>
