<template>
  <div class="truncate">
    <router-link v-if="bankId && isLink"
                 :to="`/settings/banks/${bankId}/view`"
                 :target="target"
                 :class="$attrs.class"
    >
      <span>
        {{ valueToDisplay }}
      </span>
    </router-link>
    <template v-else>
      <span>
        {{ valueToDisplay }}
      </span>
    </template>
  </div>
</template>
<script>
  export default {
    name: 'BankLink',
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      id: {
        type: String,
      },
      target: String,
      isLink: {
        type: Boolean,
        default: true,
      },
      displayOnlyInitials: Boolean,
      includeInitials: Boolean,
    },
    computed: {
      bank() {
        return this.$store.state.company.banks.find(a => a.id === this.bankId) || {}
      },
      valueToDisplay() {
        if (this.displayOnlyInitials) {
          return this.bank.initials || ''
        }

        return (this.bank.name || '') + (this.includeInitials ? ` (${this.bank.initials || ''})` : '')
      },
      bankId() {
        return this.id || this.get(this.data, 'attributes.bank_id')
      },
    },
  }
</script>
