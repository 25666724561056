<template>
  <div class="flex items-center w-full space-x-5 file-icon-info cursor-pointer no-underline"
       @click.prevent="onFileClick"
  >
    <FileIcon
        :icon-class="typeColors.text"
        :background-class="typeColors.background"
        :extension="extension"
    >
    </FileIcon>
    <div class="flex flex-1 flex-col leading-none text-sm truncate">
      <span class="font-medium mb-0.5 truncate">
        {{ name }}
      </span>
      <div v-if="!isUrl" class="mt-1">
        <Progress :stroke-width="2" :percentage="progress" color="--pc"/>
      </div>
      <span
          v-if="fileSize"
          class="text-gray-700 text-xs -mt-3">
        {{ fileSize }}
      </span>
    </div>
    <div v-if="canRemove">
      <button
          class="bg-background hover:bg-base-200 h-5 w-5 rounded cursor-pointer flex items-center justify-center"
          @click.stop="emit('close')"
      >
        <XIcon class="w-3 h-3"/>
      </button>
    </div>
    <DocumentPreviewDialog
      v-if="showPreviewDialog"
      :open.sync="showPreviewDialog"
      :data="fileToPreview"
    />
  </div>
</template>
<script setup lang="ts">
import {getFileExtension, getFileTypeColor, getHumanFileSize} from "./documentUtils";
import FileIcon from './FileIcon.vue'
import {XIcon} from 'vue-feather-icons'
import {computed, PropType, ref} from "vue";
import {Progress} from 'element-ui'
import {downloadFile, downloadFileLocally} from "@/modules/common/util/downloadFileLocally";
import DocumentPreviewDialog from "@/modules/common/components/documents/DocumentPreviewDialog.vue";

const props = defineProps({
  file: {
    type: Object as PropType<File | string>,
    default: () => ({})
  },
  fileId: {
    type: String,
  },
  fileName: {
    type: String,
  },
  progress: {
    type: Number,
    default: 0,
  },
  canRemove: {
    type: Boolean,
    default: true,
  },
  showPreviewOnClick: {
    type: Boolean,
    default: false,
  }
})

const emit = defineEmits(['close'])
const name = computed(() => {
  if (typeof props.file === 'string') {
    return props.fileName
  }
  return props.file.name
})

const isUrl = computed(() => {
  return typeof props.file === 'string' && props.file?.startsWith('http')
})
const typeColors = computed(() => {
  return getFileTypeColor(name.value)
})

const fileSize = computed(() => {
  if (typeof props.file === 'string') {
    return ''
  }
  return getHumanFileSize(props.file?.size)
})

const extension = computed(() => {
  return getFileExtension(name.value)
})

const fileUrl = computed(() => {
  return isUrl.value ? props.file : window.URL.createObjectURL(props.file)
})

const fileToPreview = computed(() => {
  return {
    attributes: {
      file_name: name.value,
      url: fileUrl.value,
    }
  }
})

const showPreviewDialog = ref(false)

function onFileClick() {
  if (props.showPreviewOnClick) {
    showPreviewDialog.value = true
    return
  }
  if (isUrl.value) {
    window.open(props.file, '_blank')
  } else {
    downloadFileLocally(props.file, name.value)
  }
}
</script>

<style>
.el-progress.el-progress--line {
  @apply flex flex-col w-full;
}

.el-progress__text,
.el-progress-bar {
  @apply w-full pr-0;
}

.el-progress__text {
  @apply flex justify-end mt-1 pr-3 text-gray-700;
}
</style>
