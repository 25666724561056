<template>
  <div class="journal-form">
    <portal v-if="!model.id"
            to="page-title">
      <span class="text-xl font-bold text-gray-900">{{ $t('Add Journal') }} </span>
      <span class="text-sm text-gray-700">({{ $t('Prepare a General Journal for posting into General Ledger') }})</span>
    </portal>
    <base-form :loading="loading"
               :show-back="true"
               :save-text="$t('Create journal')"
               :update-text="$t('Update journal')"
               :can-create-another-entity="!model.id"
               ref="baseForm"
               class="mb-10"
               layout="vertical"
               @submit="onSubmit"
    >
      <base-alert v-if="model.status === 'deleted'"
                  :type="$promptType.Danger"
                  class="col-span-6 mb-3">
        {{ $t('This journal has been deleted. You can no longer update it.') }}
      </base-alert>
      <div class="col-span-6">
        <el-collapse v-model="expandedSections">
          <base-collapse-section key="form-header"
                                 class="-m-6 mb-6"
                                 :name="$t('form-header')">
            <template slot="title">
              <span class="mr-3">
                {{ $t('Journal') }}
              </span>
              <status-badge :status="model.status"/>
            </template>
            <div class="grid grid-cols-8 gap-x-4 border-t pt-5 px-6">
              <base-input v-model="model.name"
                          :label="$t('Journal')"
                          :disabled="cantEdit"
                          :placeholder="$t('A short set of characters to describe your journal')"
                          id="name"
                          rules="required|min:1|max:20"
                          class="col-span-8 md:col-span-2 xl:col-span-1"
              />
              <base-input v-model="model.description"
                          :label="$t('Description')"
                          :disabled="cantEdit"
                          :placeholder="$t('Description')"
                          id="description"
                          rules="max:150"
                          class="col-span-8 md:col-span-2"
              />
              <base-select v-model="model.type"
                           :label="$t('Type')"
                           :placeholder="$t('Type')"
                           :options="typeOptions"
                           :disabled="cantEdit"
                           id="type"
                           class="col-span-8 md:col-span-2 xl:col-span-1"
              />
              <base-date-picker v-model="model.reference_date"
                                :label="$t('Reference Date')"
                                :placeholder="$t('Reference Date of the Journal')"
                                :disabled="cantEdit"
                                value-format="yyyy-MM-dd"
                                id="reference_date"
                                class="col-span-8 md:col-span-2"
              />
              <year-select v-model.number="model.fiscal_year"
                           :placeholder="$t('Year')"
                           :label="$t('Year')"
                           :disabled="cantEdit"
                           label-key="fiscal_year"
                           value-key="fiscal_year"
                           id="_fiscal_year"
                           class="col-span-8 md:col-span-2 xl:col-span-1"
              />
              <period-select
                v-model="model.period"
                :reference-date="model.reference_date"
                :disabled="cantEdit"
                :fiscal-year="model.fiscal_year"
                id="restrictions"
                class="col-span-8 md:col-span-2 xl:col-span-1"
              />
            </div>
          </base-collapse-section>
        </el-collapse>
      </div>
      <JournalEntries
          :journal="model"
          :key="model.type"
          ref="gridTable"
          class="col-span-6"
          @on-collapse-form-header="collapseFormHeader"
          @refresh-journal="$emit('update', model.id)"
      />
    </base-form>
  </div>
</template>
<script>
  import axios from 'axios'
  import { resourceStatuses, transactionTypes } from '@/enum/enums'
  import JournalEntries from '@/modules/ledger/components/journals/JournalEntries.vue'
  import { GeneralLedgerJournalCode, journalRecurringTypes } from '@/enum/journal'
  import { validateAgDataTable } from '@/components/ag-grid/tableUtils'

  export default {
    components: {
      JournalEntries,
    },
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      const firstPeriodValue = this.$store.getters['company/getCurrentPeriodValue']
      return {
        expandedSections: [],
        loading: false,
        model: {
          name: '',
          description: '',
          status: resourceStatuses.Pending,
          code: GeneralLedgerJournalCode,
          type: journalRecurringTypes.SinglePeriod,
          reference_date: this.$now,
          period: firstPeriodValue,
          fiscal_year: '',
          transaction_type: transactionTypes.GA,
        },
        typeOptions: [
          {
            label: this.$t('Single Period'),
            value: journalRecurringTypes.SinglePeriod,
          },
          {
            label: this.$t('Recurring Journal'),
            value: journalRecurringTypes.Recurring,
          },
        ],
      }
    },
    computed: {
      cantEdit() {
        return this.model.status === resourceStatuses.Posted
      },
    },
    methods: {
      async onSubmit() {
        try {
          const isInvalidData = await validateAgDataTable()
          if (isInvalidData) {
            return
          }

          this.loading = true
          if (this.model.id) {
            await axios.put(`/restify/journals/${this.model.id}`, this.model)
            await this.$refs.gridTable.storeProgress(this.model.id)
            this.$emit('update', this.model.id)
            this.$success(this.$t('Journal updated'))
          } else {
            const { data } = await axios.post(`/restify/journals`, this.model)
            await this.$refs.gridTable.storeProgress(data.id)
            this.$success(this.$t('Journal created'))
            this.$createAnotherEntity ? this.$emit('create-another') : await this.$router.push('/ledger/journal/pending')
          }
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not save the ledger journal'))
        } finally {
          this.loading = false
        }
      },
      redirectToDetails() {
        this.$nextTick(async () => {
          await this.$store.dispatch('generalLedger/getJournal', this.model.id)
          await this.$router.push(`/ledger/journal/${this.model.id}/view`)
        })
      },
      async collapseFormHeader() {
        if (!this.expandedSections.length) {
          return
        }

        const isValidForm = await this.$refs?.baseForm?.validate()

        if (!isValidForm) {
          return
        }

        this.expandedSections = []
      },
    },
    watch: {
      data: {
        immediate: true,
        handler(value) {
          if (!value.id) {
            this.expandedSections = ['form-header']
            this.model.fiscal_year = this.activeFiscalYear
            return
          }
          const { attributes } = value
          this.model = {
            ...this.model,
            ...attributes,
          }
          if (attributes.reference_date) {
            this.model.reference_date = this.$formatDate(attributes.reference_date, 'yyyy-MM-dd')
          }
          this.collapseFormHeader()
        },
      },
    },
  }
</script>
<style lang="scss">
  .journal-form .el-collapse-item {
    .el-collapse-item__header {
      @apply text-base px-4;
    }

    .el-collapse-item__content {
      @apply pb-0;
    }
  }
</style>
