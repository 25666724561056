<template>
  <InvoiceForm
      :key="renderKey"
      @create-another="renderKey++"
  />
</template>
<script>
  import InvoiceForm from '@/modules/accounts-payable/components/invoice/InvoiceForm'
  export default {
    components: {
      InvoiceForm,
    },
    data() {
      return {
        renderKey: 1,
      }
    },
  }
</script>
