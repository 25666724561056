<template>
  <BaseFormDialog
    v-bind="$attrs"
    v-on="$listeners"
    :title="$t('Adjust Employee Time Off Status')"
  >
    <BaseForm
      :loading="loading"
      layout="modal"
      @cancel="$emit('cancel')"
      @submit="adjustEmployeeTimeOffStatus"
    >
      <AgDataTable
        :columns="columns"
        :data="tableData"
        :pagination="false"
        :showPagination="false"
        dom-layout="autoHeight"
        class="h-full col-span-7"
      />
    </BaseForm>
  </BaseFormDialog>
</template>
<script>
import { AccrualMethods } from "@/modules/payroll/utils/accrualMethods";
import {
  validateMin,
  validateMaxDecimals,
} from "@/modules/common/util/validators";

export default {
  props: {
    employeeTimeOffStatus: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      loading: false,
      tableData: [this.employeeTimeOffStatus],
    }
  },
  computed: {
    columns() {
      return [
        {
          label: this.$t('Code'),
          prop: 'relationships.employee.attributes.code',
          component: 'EntityLink',
          redirectTo: '/payroll/employees/{ID}/view',
          minWidth: 120,
          maxWidth: 200,
        },
        {
          label: this.$t('Name'),
          prop: 'relationships.employee.attributes.name',
          minWidth: 200,
        },
        {
          label: this.$t('Carry Over (previous year)'),
          prop: 'attributes.previous_year_carry_over_hours',
          minWidth: 150,
          hide: this.isUnlimited,
          valueFormatter: this.hoursFormatter,
          valueSetter: (params) => {
            const newValue = parseFloat(params.newValue)
            const oldValue = parseFloat(params.oldValue)

            if (!validateMin(newValue, 0, { notifyErr: true })) {
              params.data.attributes.previous_year_carry_over_hours = oldValue
              return false
            }

            if (!validateMaxDecimals(newValue, 4, { notifyErr: true })) {
              params.data.attributes.previous_year_carry_over_hours = oldValue
              return false
            }

            params.data.attributes.previous_year_carry_over_hours = newValue
            return true
          },
          editable: true,
          sortable: false,
        },
        {
          label: this.$t('Accrued (this year)'),
          prop: 'attributes.current_year_accrued_hours',
          minWidth: 150,
          hide: this.isUnlimited,
          valueFormatter: this.hoursFormatter,
          valueSetter: (params) => {
            const newValue = parseFloat(params.newValue)
            const oldValue = parseFloat(params.oldValue)

            if (!validateMin(newValue, 0, { notifyErr: true })) {
              params.data.attributes.current_year_accrued_hours = oldValue
              return false
            }

            if (!validateMaxDecimals(newValue, 4, { notifyErr: true })) {
              params.data.attributes.current_year_accrued_hours = oldValue
              return false
            }

            params.data.attributes.current_year_accrued_hours = newValue
            return true
          },
          editable: true,
          sortable: false,
        },
        {
          label: this.$t('Used'),
          prop: 'attributes.current_year_used_hours',
          minWidth: 150,
          hide: this.isUnlimited,
          valueFormatter: this.hoursFormatter,
          valueSetter: (params) => {
            const newValue = parseFloat(params.newValue)
            const oldValue = parseFloat(params.oldValue)

            if (!validateMin(newValue, 0, { notifyErr: true })) {
              params.data.attributes.current_year_used_hours = oldValue
              return false
            }

            if (!validateMaxDecimals(newValue, 4, { notifyErr: true })) {
              params.data.attributes.current_year_used_hours = oldValue
              return false
            }

            params.data.attributes.current_year_used_hours = newValue
            return true
          },
          editable: true,
          sortable: false,
        },
      ]
    },
    isUnlimited() {
      return this.timeOffPolicy?.attributes?.accrual_method === AccrualMethods.Unlimited
    },
  },
  methods: {
    hoursFormatter(params) {
      return `${params.value || 0} ${this.$t('hours')}`
    },
    async adjustEmployeeTimeOffStatus() {
      const {
        previous_year_carry_over_hours,
        current_year_accrued_hours,
        current_year_used_hours,
      } = this.employeeTimeOffStatus.attributes

      try {
        this.loading = true

        const data = {
          previous_year_carry_over_hours,
          current_year_accrued_hours,
          current_year_used_hours,
        }

        await this.$store.dispatch('payroll/adjustEmployeeTimeOffStatus', {
          timeOffStatusId: this.employeeTimeOffStatus.id,
          data,
        })

        this.$emit('save', false)
      } catch (err) {
        if (err.handled) {
          return
        }
      } finally {
        this.loading = false
      }
    },
  }
}
</script>
