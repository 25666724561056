<template>
  <ReleaseRetentionProofListing
      :entity="entity"
      :url="proofListingUrl"
      :postUrl="proofListingPostUrl"
      :urlParams="urlParams"
      :mapPostPayload="mapPostPayload"
      :mapData="mapData"
      @on-posted="onPosted"
  />
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import groupBy from 'lodash/groupBy'
  import { useStorage } from '@vueuse/core'
  import ReleaseRetentionProofListing
    from '@/modules/common/components/release-retention/ReleaseRetentionProofListing.vue'
  import { costCenterTypes } from '@/components/grid-table/utils/cost-center'

  export default defineComponent({
    components: {
      ReleaseRetentionProofListing,
    },
    setup() {
      const selectedRetentions = useStorage('selected-ap-retentions', {})
      return {
        selectedRetentions,
      }
    },
    data() {
      return {
        proofListingUrl: '/restify/vendor-retentions/actions?action=release-retention-proof-listing' as string,
        proofListingPostUrl: '/restify/vendor-retentions/actions?action=release-retention' as string,
        entity: 'vendor-retentions' as string,
      }
    },
    computed: {
      urlLineItems() {
        let lineItems = this.$route.query.line_items || []
        try {
          lineItems = JSON.parse(lineItems)
        } catch (err) {
          console.warn('error parsing url query line items')
        }
        return lineItems
      },
      urlParams() {
        const groups = groupBy(this.urlLineItems, 'source_id')
        const vendors = []
        for (const vendorId in groups) {
          const vendorLineItems = groups[vendorId]
          vendors.push({
            id: vendorId,
            line_items: vendorLineItems,
          })
        }
        return {
          vendors
        }
      },
    },
    methods: {
      resetSelections() {
        this.selectedRetentions = {}
      },
      async onPosted() {
        this.resetSelections()
      },
      getOriginalItem(id) {
        return this.urlLineItems?.find(item => item.id === id) || {}
      },
      getPostPayload(data) {
        const vendors = []
        data.jobs.forEach(jobObject => {
          jobObject.vendors.forEach(vendor => {
            const row = {
              id: vendor.id,
              items: [],
            }
            vendor.line_items.forEach(lineItem => {
              const originalItem = this.getOriginalItem(lineItem.id)
              row.items.push({
                source_id: originalItem?.job_id,
                type_id: originalItem?.job_type_id,
                addl_source_id: lineItem.id,
                retention_amount: lineItem.release_retention_amount,
              })
            })

            vendors.push(row)
          })
        })
        return {
          vendors
        }
      },
      mapPostPayload(payload, data) {
        const {vendors} = this.getPostPayload(data)
        payload.vendors = vendors
        delete payload.repositories
        return payload
      },
      mapData(data) {
        if (!data?.jobs) {
          return []
        }
        return data.jobs.flatMap(jobObject => {
          const job = {
            id: jobObject.id,
            number: jobObject.number,
            description: jobObject.description,
          };

          return jobObject.vendors.flatMap(vendor => {
            const lineItems = Array.isArray(vendor.line_items) ? vendor.line_items : Object.values(vendor.line_items)

            return lineItems.map(lineItem => ({
              ...lineItem,
              id: undefined,
              _localId: crypto.randomUUID(),
              vendor,
              job,
              addl_source_id: lineItem.id,
              invoice_date: this.$now,
              addlSource: lineItem,
              cost_center: costCenterTypes.Job,
            }))
          })
        })
      }
    },
  })
</script>
