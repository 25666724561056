<template>
  <div class="import-data mt-4">
    <ImportForm
        v-bind="importSettings"
        @success="redirectToList"
    />
  </div>
</template>
<script>
  import ImportForm from '@/modules/common/components/import/ImportForm';
  import { getInitialMapping } from '@/modules/payroll/utils/timecardImportColumns';

  const colMappings = {
    VendorCode: {
      value: 'vendor_code',
      label: 'Vendor Code',
      alternativeLabels: ['Vendor', 'Code'],
      required: true,
    },
    Number: {
      value: 'number',
      label: 'Number',
      alternativeLabels: ['Invoice Number'],
      required: true,
    },
    Bank: {
      value: 'bank',
      label: 'Bank',
    },
    PaymentNumber: {
      value: 'payment_number',
      label: 'Payment Number',
      alternativeLabels: ['Payment'],
    },
    PaymentDate: {
      value: 'payment_date',
      label: 'Payment Date',
      alternativeLabels: ['Payment Date'],
    },
    UseTax: {
      value: 'use_tax',
      label: 'Use Tax',
      alternativeLabels: ['Taxable'],
    },
    Description: {
      value: 'description',
      label: 'Description',
      alternativeLabels: ['Invoice Description'],
    },
    PoNumber: {
      value: 'po_number',
      label: 'PO Number',
      alternativeLabels: ['Purchase Order'],
    },
    ClosePoAfter: {
      value: 'close_po_after',
      label: 'Close PO After',
      alternativeLabels: ['Close PO'],
    },
    Date: {
      value: 'date',
      label: 'Date',
      alternativeLabels: ['Invoice Date'],
      required: true,
    },
    DueDate: {
      value: 'due_date',
      label: 'Due Date',
      required: true,
    },
    DiscountDate: {
      value: 'discount_date',
      label: 'Discount Date',
      required: true,
    },
    PoEntryOrder: {
      value: 'po_entry_order',
      label: 'PO Entry #',
      alternativeLabels: ['PO Entry Order'],
    },
    CostCenter: {
      value: 'cost_center',
      label: 'Cost Center',
      alternativeLabels: ['Distribution Center'],
    },
    SourceCode: {
      value: 'source_code',
      label: 'Source Code',
      alternativeLabels: ['Distribution Code'],
    },
    SourceType: {
      value: 'source_type',
      label: 'Source Type',
      alternativeLabels: ['Distribution Type'],
    },
    PhaseCode: {
      value: 'phase_code',
      label: 'Phase Code',
      alternativeLabels: ['Phase'],
    },
    CostCode: {
      value: 'cost_code',
      label: 'Cost Code',
      alternativeLabels: ['Cost'],
    },
    ChangeOrder: {
      value: 'change_order',
      label: 'Change Order',
      alternativeLabels: ['Order'],
    },
    Account: {
      value: 'account',
      label: 'Account',
      required: true,
      alternativeLabels: ['G/L Account'],
    },
    Subaccount: {
      value: 'subaccount',
      label: 'Sub-account',
      alternativeLabels: ['G/L Sub-Account'],
    },
    Quantity: {
      value: 'quantity',
      label: 'Qty',
      alternativeLabels: ['Quantity'],
    },
    GrossAmount: {
      value: 'gross_amount',
      label: 'Gross Amount',
      alternativeLabels: ['Amount', 'Invoice Amount', 'Gross'],
      required: true,
    },
    DiscountAmount: {
      value: 'discount_amount',
      label: 'Discount Amount',
      alternativeLabels: ['Discount'],
    },
    RetentionAmount: {
      value: 'retention_amount',
      label: 'Retention Amount',
      alternativeLabels: ['Retention'],
    },
    EntryDescription: {
      value: 'entry_description',
      label: 'Entry Description',
    },
  }

  const options = [
    colMappings.VendorCode,
    colMappings.Number,
    colMappings.Bank,
    colMappings.PaymentNumber,
    colMappings.PaymentDate,
    colMappings.UseTax,
    colMappings.Description,
    colMappings.PoNumber,
    colMappings.ClosePoAfter,
    colMappings.Date,
    colMappings.DueDate,
    colMappings.DiscountDate,
    colMappings.PoEntryOrder,
    colMappings.CostCenter,
    colMappings.SourceCode,
    colMappings.SourceType,
    colMappings.PhaseCode,
    colMappings.CostCode,
    colMappings.ChangeOrder,
    colMappings.Account,
    colMappings.Subaccount,
    colMappings.Quantity,
    colMappings.GrossAmount,
    colMappings.DiscountAmount,
    colMappings.RetentionAmount,
    colMappings.EntryDescription,
  ]

  export default {
    components: {
      ImportForm,
    },
    data() {
      return {
        importSettings: {
          url: '/restify/invoices/import',
          columnOptions: options,
          columnMappings: colMappings,
          initialMapping: getInitialMapping(options),
          type: 'invoice-import',
          resource: 'invoices',
        },
      }
    },
    methods: {
      async redirectToList() {
        await this.$router.push('/accounts-payable/invoices/pending')
      },
    },
  }
</script>
