<template>
  <JobCostInitForm :data="data"/>
</template>
<script>
  import JobCostInitForm from '@/modules/job-costing/components/job-cost-init/JobCostInitForm'

  export default {
    components: {
      JobCostInitForm,
    },
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
  }
</script>
