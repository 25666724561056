<template>
  <div class="truncate">
    <router-link
      v-if="craftCode?.id && !editableCell"
      :to="`/payroll/codes/craft-codes?id=${craftCode.id}`"
      :class="linkClass || $attrs.class"
      :target="target"
    >
      <span :class="linkClass || $attrs.class">
        {{ valueToDisplay }}
      </span>
    </router-link>
    <template v-else>
	    {{ valueToDisplay }}
    </template>
  </div>
</template>
<script lang="ts" setup>
  import { computed } from 'vue'
  import get from 'lodash/get'
  import store from '@/store'
  import { globalResources } from "@/components/form/util";
  import { CraftCode } from "@/modules/common/types/models";

  const props = defineProps({
    data: {
      type: Object,
      default: () => ({}),
    },
    id: {
      type: String,
    },
    showDescription: {
      type: Boolean,
      default: true,
    },
    params: {
      type: Object,
      default: () => ({}),
    },
	  target: String,
    linkClass: String,
	  editableCell: Boolean,
    displayValue: {
      type: String,
      default: ''
    }
  })

  const craftCodeId = computed(() => {
    if (props.params?.value) {
       return props.params?.value
    }

    return props.id || get(props.data, 'attributes.craft_code_id')
  })

  const craftCodes = computed(() => {
    return store.getters['globalLists/getResourceList'](globalResources.CraftCodes)
  })

  const craftCode = computed(() => {
    return craftCodes.value.find((a: CraftCode) => a.id === craftCodeId.value) || {}
  })

  const valueToDisplay = computed(() => {
    if (props.displayValue) {
      return props.displayValue
    }

    const { craft_code, craft_level } = props.params?.data || {}
		if (!craftCodeId.value) {
			return `${craft_code || ''} ${craft_level || ''}`
		}

    let { code, description, level } = craftCode.value
    if (!code) {
      code = craft_code || ''
      level = craft_level || ''
    }
    if (!props.showDescription || !description) {
      return `${code} ${level}`
    }

    return `${code} ${level} (${description})`
  })
</script>
