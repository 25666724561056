<template>
  <div>
    <base-alert v-if="!employeeId"
                :type="$promptType.Danger"
                class="col-span-6 mb-3">
      {{
        $t(`You don't have an employee associated with your account and therefore we couldn't find any matching w2 froms for you.`)
      }}
    </base-alert>
    <BaseDataTable :columns="columns"
                   :url-params="urlParams"
                   :default-filters="false"
                   :can-manage-columns="false"
                   :per-page="$pagination.list"
                   :url="url"
                   actions="search,refresh"
                   ref="table"
                   @row-click="previewForm"
    >
      <template v-slot:attributes.sent_count="{row}">
        <base-badge :type="row.attributes.sent_count > 0 ? 'success' : 'info'">
          {{ $tc('sent times', row.attributes.sent_count, { count: row.attributes.sent_count }) }}
        </base-badge>
      </template>
      <template v-slot:extra-actions-before="{row}">
        <table-view-button v-if="$isAuthorized('authorizedToPreview', row)"
                           @click="previewForm(row)"
        >
          {{ $t('Preview') }}
        </table-view-button>
        <table-download-button v-if="$isAuthorized('authorizedToDownload', row)"
                               :loading="row.downloading"
                               @click="downloadForm(row)"
        />
      </template>
    </BaseDataTable>
    <w2-form-preview-dialog v-if="showFormPreviewDialog"
                            :open.sync="showFormPreviewDialog"
                            :data="get(formToView, 'attributes', {})"
                            :employee="get(formToView, 'relationships.employee.attributes', {})"
                            :company="get(formToView, 'relationships.company.attributes', {})"
    />
  </div>
</template>
<script>
  import { downloadFileForEntity } from '@/modules/common/util/downloadFileLocally';
  import W2FormPreviewDialog from '@/modules/payroll/pages/w2-forms/W2FormPreviewDialog'

  export default {
    components: {
      W2FormPreviewDialog,
    },
    data() {
      return {
        showFormPreviewDialog: false,
        selectedIndex: -1,
        formToView: null,
        downloading: false,
      }
    },
    computed: {
      employeeId() {
        return this.$store.state.auth.user?.employee?.id
      },
      url() {
        if (!this.employeeId) {
          return undefined
        }
        return `/restify/employee-w2-forms?employee_id=${this.employeeId}`
      },
      columns() {
        return [
          {
            label: this.$t('Year'),
            prop: 'attributes.year',
            maxWidth: 150,
            align: 'center',
          },
          {
            label: this.$t('Sent'),
            prop: 'attributes.sent_count',
            maxWidth: 100,
            align: 'center',
          },
          {
            label: this.$t('Sent At'),
            prop: 'attributes.sent_at',
            maxWidth: 150,
            align: 'center',
            component: 'FormattedDate',
          },
          {
            label: this.$t('Downloaded At'),
            prop: 'attributes.downloaded_at',
            maxWidth: 150,
            align: 'center',
            component: 'FormattedDate',
          },
        ]
      },
      urlParams() {
        return {
          related: 'employee,company',
          page: 1,
        }
      },
    },
    methods: {
      getEmail(row) {
        return this.get(row, 'relationships.employee.attributes.email', '')
      },
      async downloadForm(row) {
        await downloadFileForEntity(row, 'employee-w2-forms')
      },
      previewForm(row, index) {
        this.$router.push(`${this.$route.path}?id=${row.id}`)
        this.selectedIndex = index
        this.formToView = row
        this.showFormPreviewDialog = true
      },
      closePreview() {
        this.formToView = null
        this.selectedIndex = -1
        this.showFormPreviewDialog = false
      },
    },
    watch: {
      '$route.query.id'(newVal, oldVal) {
        if (!newVal && oldVal) {
          this.closePreview()
        }
      },
    },
  }
</script>
