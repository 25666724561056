<template>
  <div class="flex flex-col">
    <div class="flex flex-col template-select-wrapper">
      <span class="text-lg leading-6 text-cool-gray-900 mt-3">
        <slot name="title">
          {{ $t('Template to send') }}
        </slot>
      </span>
      <p class="text-sm leading-6 text-cool-gray-900 mt-2 mb-1">
        {{ $t('Box Template') }}
      </p>
      <div class="bg-white rounded shadow cursor-pointer border-2 border-transparent"
           :class="{'border-green-600': selectedTemplate === BOX_TEMPLATE}"
           @click="selectedTemplate = BOX_TEMPLATE"
      >
        <img class="w-full" src="/img/check-box.png" alt="box-template">
      </div>
      <p class="text-sm leading-6 text-cool-gray-900 mb-1 mt-2">
        {{ $t('Detail Template') }}
      </p>
      <div class="bg-white rounded shadow cursor-pointer border-2 border-transparent"
           :class="{'border-green-600': selectedTemplate === DETAIL_TEMPLATE}"
           @click="selectedTemplate = DETAIL_TEMPLATE"
      >
        <img class="w-full" src="/img/check-detail-2.png" alt="detail-template">
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
const BOX_TEMPLATE = 'box'
const DETAIL_TEMPLATE = 'detail'
export default {
  name: 'check-template-select',
  props: {
    value: String,
  },
  data() {
    return {
      DETAIL_TEMPLATE,
      BOX_TEMPLATE,
      selectedTemplate: this.value || BOX_TEMPLATE,
    }
  },
  watch: {
    selectedTemplate(value) {
      this.$emit('input', value)
      this.$store.commit('payroll/SET_CHECKS_TEMPLATE', value)
    }
  }
}
</script>

<style scoped>
.template-select-wrapper {
  max-height: 60vh;
  overflow-y: auto;
}
</style>
