<template>
  <base-form
      layout="vertical"
      v-loading="loading"
      :title="$t('File Attachments')"
      :save-text="$t('Download All')"
      @submit="downloadAll()"
      :show-buttons="data.length > 0"
  >
    <TableOverlayLoading class="flex justify-center col-span-6" v-if="loading"/>
    <div v-if="!data?.length && !loading">
      <p class="text-gray-500 text-center">
        {{ $t('No files found.') }}
      </p>
    </div>
    <dd v-if="data.length > 0" class="mt-1 text-sm text-gray-900 col-span-6">
      <ul class="border border-gray-200 rounded-md divide-y divide-gray-200">
        <li class="px-3 py-4 flex items-center justify-between text-sm"
            v-for="(file, index) of data"
            :key="`item-${index}`">
          <div class="w-0 flex-1 flex items-center">
            <PaperclipIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true"/>
            <div class="ml-2 flex-1 w-0 truncate">
              <span>
                {{ file?.fileName || fileName }}
              </span>
              <span class="text-xs text-gray-500 ml-2">
                {{ getSize(file) }}
              </span>
            </div>
          </div>
          <div class="ml-4 flex-shrink-0">
            <base-button
                variant="gray-link"
                @click="previewItem(file)"
            >
              {{ $t('Preview') }}
            </base-button>
            <base-button
                @click="downloadItem(file)"
                variant="primary-link">
              {{ $t('Download') }}
            </base-button>
          </div>
        </li>
      </ul>
    </dd>
    <base-dialog
        v-if="showPreviewDialog"
        :visible.sync="showPreviewDialog"
        :append-to-body="true"
        fullscreen
    >
      <iframe v-if="previewSrc" :src="previewSrc" class="min-h-[85vh] w-full"></iframe>
    </base-dialog>
  </base-form>
</template>
<script>
import { PaperclipIcon } from "vue-feather-icons"
import { downloadFileLocally } from "@/modules/common/util/downloadFileLocally";
import TableOverlayLoading from "@/components/ag-grid/TableOverlayLoading.vue";

export default {
  components: {
    TableOverlayLoading,
    PaperclipIcon
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
    },
    fileName: {
      type: String,
      default: 'file.txt'
    },
    defaultExtension: {
      type: String,
      default: 'txt'
    }
  },
  data() {
    return {
      showPreviewDialog: false,
      previewSrc: null,
    }
  },
  methods: {
    getSize(file) {
      if (!file?.length) {
        return '0 KB'
      }
      const size = file.length
      const sizeInKb = size / 1024
      return `${sizeInKb.toFixed(2)} KB`
    },
    getFileNameWithExtension(file) {
      const fileName = file?.fileName || this.fileName
      if (fileName.split('.').pop()) {
        return fileName
      }

      return `${fileName}.${this.defaultExtension}`
    },
    downloadItem(file) {
      const fileName = this.getFileNameWithExtension(file)

      downloadFileLocally(file, fileName)
    },
    previewItem(file) {
      const blob = new window.Blob([file])
      this.previewSrc = window.URL.createObjectURL(blob)
      this.showPreviewDialog = true
    },
    downloadAll() {
      this.data.forEach(file => {
        this.downloadItem(file)
      })
    }
  }
}
</script>
