<template>
  <BaseSelect
    v-bind="$attrs"
    v-on="$listeners"
    :loadingExternalData="loadingClosedJobs"
    :id="$attrs.id || 'job-select'"
    :name="$attrs.name || $t('Job')"
    :placeholder="$attrs.placeholder || $t('Select Job')"
    :label="$attrs.label !== undefined ? $attrs.label : $t('Job')"
    :add-entity="addEntity"
    :edit-entity="editEntity"
    :set-default-option="editEntity"
    :add-label="addLabel"
    :options="options"
    :label-format="formatLabel"
    :table-columns="tableColumns"
    :dataFilters="dataFilters"
    :resource-name="globalResources.Jobs"
    :value-key="valueKey"
    :label-key="labelKey"
    :initial-value="initialValue"
  />
</template>
<script>
import i18n from '@/i18n'
import { globalResources } from "@/components/form/util";

export default {
  name: 'JobSelectNew',
  props: {
    addEntity: {
      type: Boolean,
      default: false,
    },
    editEntity: {
      type: Boolean,
      default: false,
    },
    displayPayColumn: {
      type: Boolean,
      default: false,
    },
    valueKey: {
      type: String,
      default: 'id',
    },
    labelKey: {
      type: String,
      default: 'code',
    },
    addLabel: {
      type: String,
      default: i18n.t('Add new Job'),
    },
    initialValue: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      globalResources,
      dataFilters: {
        showActive: true,
      },
      loadingClosedJobs: false,
    }
  },
  computed: {
    openJobs() {
      return this.$store.getters['globalLists/getResourceList'](this.$globalResources.Jobs) || []
    },
    closedOrInactiveJobs() {
      return this.$store.getters['globalLists/getResourceList'](this.$globalResources.ClosedOrInactiveJobs) || []
    },
    options() {
      let options = [...this.openJobs]

      if (!this.dataFilters.showActive) {
        options = [...options, ...this.closedOrInactiveJobs]
      }

      return options.map(job => ({
        ...job,
        isInactive: ['inactive', 'closed'].includes(job.status),
      }))
    },
    tableColumns() {
      return [
        {
          minWidth: 100,
          maxWidth: 150,
          name: this.$t('Number'),
          prop: 'number',
        },
        {
          minWidth: 200,
          maxWidth: 200,
          name: this.$t('Description'),
          prop: 'description',
        },
        {
          minWidth: 120,
          maxWidth: 120,
          name: this.$t('Status'),
          prop: 'status',
          component: 'Status',
          filter: {
            type: 'switch',
            prop: 'showActive',
            tooltip: this.$t('Only show open jobs'),
            onChange: (value) => {
              this.dataFilters.showActive = value
            },
          },
        },
      ]

    },
    showInactiveSetting() {
      return this.$store.getters['settings/getFilterSetting']('show_inactive_jobs')
    },
  },
  watch: {
    showInactiveSetting: {
      handler() {
        this.dataFilters.showActive = !this.showInactiveSetting
      },
      immediate: true
    },
    'dataFilters.showActive': {
      async handler(value) {
        if (value) {
          return
        }

        try {
          this.loadingClosedJobs = true
          await this.$store.dispatch('globalLists/getClosedOrInactiveJobs')
        } finally {
          this.loadingClosedJobs = false
        }
      },
      immediate: true,
    }
  },
  methods: {
    formatLabel(job) {
      const { number, description } = job
      let label = number
      if (description) {
        label += ` (${description})`
      }
      return label
    },
  },
}
</script>
