export function formatHours(value, options = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}) {
  const formatter = new Intl.NumberFormat('en-US', options)
  return formatter.format(value)
}

export default {
  install(Vue) {
    Vue.prototype.$formatHours = formatHours
  }
}
