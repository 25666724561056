<template>
  <base-data-table
      v-bind="defaultAttributes"
      :columns="columns"
      :data="getData"
      :total-rows="rowsLength"
      :has-summary="hasSummary"
      :key="currentTab"
      headerRowCompacted
      class="no-borders payroll-history"
      @force-pagination="forcePagination"
  >

    <template #custom-header>
      <base-tabs
          v-model="currentTab"
          :tabs="selectedBillings"
          label-key="label"
      />
    </template>

    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>
    <!--  Register Recap  -->
    <template #header="{row}">
      <div class="flex items-inline-base">
        <EmployeeLink
            :data="row.header"
            :show-preview="false"
            class="min-w-[400px]"
        />
        <span>{{ $t('Check Date:') }}</span>
        <span class="mx-2">{{ $formatDate(get(row, 'header.payroll.payment_date')) }}</span>
        <span class="ml-6">{{ $t('Pay Period End Date:') }}</span>
        <span class="mx-2">{{ $formatDate(get(row, 'header.payroll.period_end_date')) }}</span>
      </div>
    </template>

    <template #employee="{row}">
      <dl class="w-full">
        <dt>
          <span>{{ $t('SS#:') }}</span>
          {{ row.employee.masked_ssn }}
        </dt>
        <dt class="flex justify-between">
          <span>{{ row.employee.federal }}</span>
          {{ get(row, 'employee.witholding_state.text', '') }}
        </dt>
        <dt class="flex justify-between">
          <span>{{ row.employee.status }}</span>
          {{ get(row, 'employee.sui_state.text', '') }}
        </dt>
        <dt class="flex justify-between">
          <router-link
              v-if="get(row, 'employee.union.id')"
              :to="`/payroll/rates/unions?id=${row.employee.union.id}`"
          >
            {{ row.employee.union.text }}
          </router-link>
          <span v-else/>
          <router-link
              v-if="get(row, 'employee.craft_code.id')"
              :to="`/payroll/codes/craft-codes?id=${row.employee.craft_code.id}`"
          >
            {{ row.employee.craft_code.text }}
          </router-link>
          <span v-else/>
        </dt>
        <dt class="flex justify-between">
          <span>{{ $t('Bank/Check #') }}</span>
          <div class="flex">
            <router-link
                v-if="get(row, 'bank.id')"
                :to="`/settings/banks/${row.bank.id}/view`"
            >
              {{ row.bank.initials }}
            </router-link>
            <span v-else> </span>
            &nbsp;-&nbsp;{{ row.payroll.payment_number }}</span>
          </div>
        </dt>
      </dl>
    </template>

    <template #hours="{row}">
      <dl class="w-full">
        <dt
            v-for="payCode in row.pay_codes?.items"
            class="flex justify-between"
        >
          <span>{{ payCode.code }}:</span>
          {{ payCode.hours }}
        </dt>
      </dl>
    </template>

    <template #average="{row}">
      <dl class="w-full">
        <dt
            v-for="payCode in row.pay_codes?.items"
            class="flex justify-center"
        >
          {{ payCode.rate }}
        </dt>
      </dl>
    </template>

    <template #gross_pay="{row}">
      <dl class="w-full">
        <dt
            v-for="payCode in row.pay_codes?.items"
            class="flex justify-end"
        >
          {{ $formatPrice(payCode.amount) }}
        </dt>
        <!-- Cause of rows: fed status & pay frequency rows -->
        <dt>&nbsp;</dt>
        <dt>&nbsp;</dt>
        <dt
            v-for="payCode in row.other_pay?.items"
            class="flex justify-between"
        >
          <span>{{ payCode.code }}:</span>
          {{ $formatPrice(payCode.amount) }}
        </dt>
      </dl>

    </template>

    <template #deductions="{row}">
      <dl class="w-full">
        <dt
            v-for="pay in row.deductions?.items"
            class="flex justify-between"
        >
          <span>{{ pay.code }}:</span>
          {{ $formatPrice(pay.amount) }}
        </dt>
      </dl>
    </template>

    <template #employee_taxes="{row}">
      <dl class="w-full">
        <dt
            v-for="tax in row.employee_taxes?.items"
            class="flex justify-between"
        >
          <router-link to="/payroll/codes/tax-codes">
            {{ tax.code }}:
          </router-link>
          {{ $formatPrice(tax.amount) }}
        </dt>
      </dl>
    </template>

    <template #benefits="{row}">
      <dl class="w-full">
        <dt
            v-for="benefit in row.benefits?.items"
            class="flex justify-between"
        >
          <span>{{ benefit.code }}:</span>
          {{ $formatPrice(benefit.amount) }}
        </dt>
      </dl>
    </template>

    <template #employer_taxes="{row}">
      <dl class="w-full">
        <dt
            v-for="tax in row.employer_taxes?.items"
            class="flex justify-between"
        >
          <router-link to="/payroll/codes/tax-codes">
            {{ tax.code }}:
          </router-link>
          {{ $formatPrice(tax.amount) }}
        </dt>
      </dl>
    </template>

    <template #non_payrolls="{row}">
      <dl class="w-full">
        <dt
            v-for="tax in row.non_payrolls?.items"
            class="flex justify-between"
        >
          <span>{{ tax.code }}:</span>
          {{ $formatPrice(tax.amount) }}
        </dt>
        <dt
            v-for="tax in row.insurances?.items"
            class="flex justify-between"
        >
          <span>{{ tax.code }}:</span>
          {{ $formatPrice(tax.amount) }}
        </dt>
      </dl>
    </template>

    <template
        v-if="currentTab === groups.RegisterRecap"
        #subtotal="{subtotal}"
    >
      <td align="right">{{ $t('Totals:') }}</td>
      <td align="right">
      <span class="px-3 subtotal-cell max-w-[80px]">
        {{ subtotal.hours }}
      </span>
      </td>
      <td/>
      <td align="right">
      <span class="subtotal-cell max-w-[110px]">
        {{ $formatPrice(subtotal.pay_codes + subtotal.other_pay) }}
      </span>
      </td>
      <td align="right">
      <span class="subtotal-cell max-w-[110px]">
        {{ $formatPrice(subtotal.employee_taxes) }}
      </span>
      </td>
      <td align="right">
      <span class="subtotal-cell max-w-[110px]">
        {{ $formatPrice(subtotal.deductions) }}
      </span>
      </td>
      <td align="right">
      <span class="subtotal-cell max-w-[110px]">
        {{ $formatPrice(subtotal.benefits) }}
      </span>
      </td>
      <td align="right">
      <span class="subtotal-cell max-w-[110px]">
        {{ $formatPrice(subtotal.employer_taxes) }}
      </span>
      </td>
      <td>
        <div class="justify-between flex">
          <span>{{ $t('Check =') }}</span>
          {{ $formatPrice(subtotal.total) }}
        </div>
      </td>
    </template>

    <!--  Codes Summary  -->
    <template #category="{row}">
      <span class="capitalize">
        {{ row.category ? `${formattedText(row.category)}:` : '' }}
      </span>
    </template>

    <template #item.state="{row}">
      <span class="capitalize">
        {{ row.item?.state }}
      </span>
    </template>

    <template #html-row="{htmlData}">
      <template v-if="!htmlData.isSummary">
        <td colspan="5"/>
        <td align="right">
          <span class="subtotal-cell max-w-[110px]"/>
        </td>
        <td align="right">
          {{ $formatPrice(htmlData.total) }}
        </td>
        <td/>
      </template>
      <td v-else colspan="8" align="right">
        <span class="mx-6">
          {{ $t('Total Checks =') }}
        </span>
        <span class="mx-4">
          {{ $formatPrice(htmlData.total) }}
        </span>
      </td>

    </template>

    <template #footer="{row}">
      <template v-if="hasFooterData(row)">
        <div class="w-full">
          <span class="mx-24">
          {{ $t('Total Checks =') }}
          </span>
          {{ row.footer.total_checks }}
        </div>
        <div class="w-full flex justify-between top-border bottom-border py-4">
        <span>
          {{ $t('Total MANUAL Checks =') }}
          <span class="mx-6">
            {{ row.footer.manual }}
          </span>
        </span>
          <span>
          {{ $t('Total PAYOFF Checks =') }}
          <span class="mx-6">
            {{ row.footer.payoff }}
          </span>
        </span>
          <span>
          {{ $t('Total NORMAL Checks =') }}
          <span class="ml-6">
            {{ row.footer.normal }}
          </span>
        </span>
        </div>
        <div class="py-4 flex justify-start">
          {{ $t('Tax Deposit Summary For This Payroll') }}
        </div>
        <div v-for="(deposit, index) in row.deposits"
             class="flex w-full"
        >
          <dl class="w-72">
            <dt v-if="isFederalDeposit(deposit.state)">
              {{ formattedText(deposit.state) }} {{ $t('Taxes:') }}
            </dt>
            <dt v-else>
              {{ $t('State Taxes:') }} ({{ deposit.state }})
            </dt>
          </dl>
          <dl v-if="isFederalDeposit(deposit.state)" class="w-72 mx-20 mb-6">
            <dt class="flex justify-between">
              <span>{{ $t('Fed Withholding:') }}</span>
              {{ $formatPrice(deposit.withholding) }}
            </dt>
            <dt class="flex justify-between">
              <span>{{ $t('FICA:') }}</span>
              {{ $formatPrice(deposit.fica) }}
            </dt>
            <dt class="flex justify-between">
              <span>{{ $t('Medicare:') }}</span>
              {{ $formatPrice(deposit.medicare) }}
            </dt>
            <dt class="flex justify-between mt-6">
              <span class="pt-3">{{ $t('Total Federal:') }}</span>
              <span class="top-border pt-3">
                  {{ $formatPrice(deposit.total) }}
                </span>
            </dt>
          </dl>
          <dl v-else class="w-72 mx-20 mb-6">
            <dt class="flex justify-between">
              <span>{{ $t('State Withholding:') }}</span>
              {{ $formatPrice(deposit.withholding) }}
            </dt>
            <dt v-if="deposit.hasOwnProperty('disability')"
                class="flex justify-between">
              <span>{{ $t('State Disability:') }}</span>
              {{ $formatPrice(deposit.disability) }}
            </dt>
            <dt class="flex justify-between mt-6">
              <span class="pt-3">{{ $t('Total State:') }}</span>
              <span class="top-border pt-3">
                  {{ $formatPrice(deposit.total) }}
                </span>
            </dt>
          </dl>
          <dl class="w-72 mx-20 mb-6">
            <template v-if="isFederalDeposit(deposit.state)">
              <dt>&nbsp;</dt>
              <dt>&nbsp;</dt>
              <dt>&nbsp;</dt>
              <dt>&nbsp;</dt>
              <dt class="pt-3 flex justify-between">
                <span>{{ $t('Federal Unemployment:') }}</span>
                {{ $formatPrice(deposit.unemployment) }}
              </dt>
            </template>
            <template v-else>
              <dt>&nbsp;</dt>
              <dt>&nbsp;</dt>
              <dt v-if="deposit.hasOwnProperty('disability')">&nbsp;</dt>
              <dt class="pt-3 flex justify-between">
                <span>{{ $t('State Unemployment:') }}</span>
                {{ $formatPrice(deposit.unemployment) }}
              </dt>
            </template>
          </dl>
        </div>
      </template>
    </template>

    <template #account="{row}">
      <AccountLink :data="row.account"/>
    </template>
    <template #subaccount="{row}">
      <SubAccountLink :data="row.subaccount"/>
    </template>

    <template #employee_summary>
      <span class="font-medium">{{ $t('Total Payroll:') }}</span>
    </template>

  </base-data-table>
</template>
<script>
  import sumBy from 'lodash/sumBy'
  import { formattedText } from '@/utils/utils'
  import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'

  export default {
    extends: ReportTableWrapper,
    data() {
      const groups = {
        RegisterRecap: 'register-recap',
        CodesSummary: 'codes-summary',
        AccountsSummary: 'accounts-summary',
      }

      return {
        groups,
        currentTab: groups.RegisterRecap,
        selectedBillings: [
          {
            label: this.$t('Register Recap'),
            name: groups.RegisterRecap,
          },
          {
            label: this.$t('Codes Summary'),
            name: groups.CodesSummary,
          },
          {
            label: this.$t('Accounts Summary'),
            name: groups.AccountsSummary,
          },
        ],
      }
    },
    computed: {
      hasSummary() {
        return this.currentTab !== this.groups.CodesSummary
      },
      columns() {
        if (this.currentTab === this.groups.RegisterRecap) {
          return [
            {
              label: '',
              prop: 'employee',
              align: 'right',
              minWidth: 140,
              maxWidth: 200,
            },
            {
              label: this.$t('Hours'),
              prop: 'hours',
              align: 'center',
              minWidth: 120,
              maxWidth: 140,
              padding: '0px 20px',
              summary: ({ data, column }) => {
                return sumBy(data, column.prop)
              },
              summaryAlignment: 'right',
            },
            {
              label: this.$t('Average <br> Rate'),
              prop: 'average',
              align: 'center',
              minWidth: 40,
              maxWidth: 60,
            },
            {
              label: this.$t('Gross <br> Pay'),
              prop: 'gross_pay',
              align: 'center',
              minWidth: 80,
              maxWidth: 110,
              summary: () => {
                const total = this.grandTotals.pay_codes + this.grandTotals.other_pay
                return this.$formatPrice(total)
              },
              summaryAlignment: 'right',
            },
            {
              label: this.$t('Tax <br> Deduct'),
              prop: 'employee_taxes',
              align: 'center',
              minWidth: 120,
              maxWidth: 140,
              summary: () => this.$formatPrice(this.grandTotals.employee_taxes),
              summaryAlignment: 'right',
            },
            {
              label: this.$t('Other <br> Deduct'),
              prop: 'deductions',
              align: 'center',
              minWidth: 120,
              maxWidth: 140,
              summary: () => this.$formatPrice(this.grandTotals.deductions),
              summaryAlignment: 'right',
            },
            {
              label: this.$t('Benefits'),
              prop: 'benefits',
              align: 'center',
              minWidth: 120,
              maxWidth: 140,
              summary: () => this.$formatPrice(this.grandTotals.benefits),
              summaryAlignment: 'right',
            },
            {
              label: this.$t('Employer <br> Taxes'),
              prop: 'employer_taxes',
              align: 'center',
              minWidth: 120,
              maxWidth: 140,
              summary: () => this.$formatPrice(this.grandTotals.employer_taxes),
              summaryAlignment: 'right',
            },
            {
              label: this.$t('Other <br> Payroll'),
              prop: 'non_payrolls',
              align: 'right',
              minWidth: 120,
              maxWidth: 140,
              summary: () => `
              <div class="justify-between flex">
                <span>${this.$t('Check =')}</span>
                ${this.$formatPrice(this.grandTotals.total)}
              </div>
            `,
            },
          ]
        }

        if (this.currentTab === this.groups.CodesSummary) {
          return [
            {
              label: '',
              prop: 'category',
              minWidth: 180,
              maxWidth: 220,
            },
            {
              label: this.$t('Code'),
              prop: 'item.code',
              minWidth: 70,
              maxWidth: 100,
            },
            {
              label: this.$t('Description'),
              prop: 'item.description',
              minWidth: 180,
              maxWidth: 220,
            },
            {
              label: this.$t('State / Local'),
              prop: 'item.state',
              minWidth: 70,
              maxWidth: 80,
            },
            {
              label: this.$t('Hours / Units'),
              prop: 'item.quantity',
              align: 'right',
              minWidth: 70,
              maxWidth: 120,
            },
            {
              label: this.$t('Amount'),
              prop: 'item.amount',
              component: 'FormattedPrice',
              align: 'right',
              minWidth: 100,
              maxWidth: 120,
            },
            {
              label: this.$t('Total'),
              prop: 'item.total',
              hiddenValue: true,
              align: 'right',
              minWidth: 100,
              maxWidth: 120,
            },
            {
              label: '',
              prop: 'empty-column',
              hiddenValue: true,
              minWidth: 160,
              maxWidth: 160,
            },
          ]
        }

        if (this.currentTab === this.groups.AccountsSummary) {
          return [
            {
              label: this.$t('Account'),
              prop: 'account',
              minWidth: 70,
              maxWidth: 120,
              summary: () => this.$t('Totals:'),
            },
            {
              label: this.$t('Sub Account'),
              prop: 'subaccount',
              minWidth: 70,
              maxWidth: 120,
            },
            {
              label: this.$t('Debit'),
              prop: 'debit_amount',
              component: 'FormattedPrice',
              minWidth: 70,
              maxWidth: 120,
              hideZero: true,
              summary: () => this.$formatPrice(this.accountSummary.total_credit),
            },
            {
              label: this.$t('Credit'),
              prop: 'credit_amount',
              component: 'FormattedPrice',
              minWidth: 70,
              maxWidth: 120,
              hideZero: true,
              summary: () => this.$formatPrice(this.accountSummary.total_debit),
            },
          ]
        }

        return  []
      },
      grandTotals() {
        return this.data.grand_totals?.totals
      },
      accountSummary() {
        return this.data.summaries?.accounts || {
          total_credit: 0,
          total_debit: 0,
        }
      },
    },
    methods: {
      formattedText,
      isFederalDeposit(state) {
        return state === 'federal'
      },
      hasFooterData(row) {
        return Object.keys(row.footer)?.length || false
      },
      composeRows() {
        if (this.currentTab === this.groups.AccountsSummary) {
          this.rows = this.get(this.data, 'summaries.accounts.items', [])
          return
        }

        if (this.currentTab === this.groups.CodesSummary) {
          const taxes = this.get(this.data, 'summaries.taxes.categories', {})

          Object.keys(taxes).forEach((taxName, groupIndex) => {
            const items = this.get(taxes, `${taxName}.items`, [])

            if (!items.length) return

            items.forEach((item, itemIndex) => {
              const row = {
                category: itemIndex === 0 ? taxName : '',
                item,
              }
              this.rows.push(row)
            })

            this.rows.push({
              htmlData: {
                total: this.get(taxes, `${taxName}.total`, 0),
                isSummary: groupIndex === Object.keys(taxes).length - 1,
              },
            })

            return this.rows
          })

          let checksSummary = this.get(this.data, 'summaries.checks.footer', {})
          checksSummary.total_checks = this.get(this.data, 'summaries.taxes.total_checks', 0)

          const deposits = this.get(this.data, 'summaries.deposits', [])

          this.rows.push({
            footer: checksSummary,
            deposits,
          })

          return this.rows
        }

        const { items } = this.data || []

        items.forEach(group => {
          let employee = group.employee
          group.payrolls.forEach(payrollObject => {

            const hours = this.get(payrollObject, 'pay_codes.totals.hours', 0)
            payrollObject.employee = employee
            payrollObject.hours = hours
            employee.payroll = payrollObject.payroll

            let subtotal = payrollObject.totals
            subtotal.hours = hours

            this.rows.push(
                {
                  header: employee,
                },
                payrollObject,
                {
                  subtotal,
                },
                {
                  footer: {},
                },
            )
          })
        })

        return this.rows
      },
    },
    watch: {
      currentTab() {
        this.rows = []
        this.composeRows(this.data)
      },
    },
  }
</script>
<style>
  .payroll-history td {
    vertical-align: baseline;
  }
</style>
