<template>
  <EntityApproveDialog
    v-bind="$attrs"
    v-on="$listeners"
    :entity-name="$t('recurring payable')"
    :data="invoice"
    api-base-path="/restify/recurring-invoices"
    base-path="/accounts-payable/recurring-invoices"
  >
    <RecurringInvoiceDetails
      :show-back="false"
      class="col-span-6"
    />
  </EntityApproveDialog>
</template>
<script>
import InvoiceDetails from "@/modules/accounts-payable/pages/invoices/invoice-details.vue";
import EntityApproveDialog from "@/modules/common/components/review/EntityApproveDialog.vue";
import RecurringInvoiceDetails from "../../pages/recurring-invoices/recurring-invoice-details.vue";

export default {
  components: {
    EntityApproveDialog,
    RecurringInvoiceDetails,
  },
  props: {
    invoice: {
      type: String,
      required: true,
    },
  },
  watch: {
    invoice: {
      immediate: true,
      handler(value) {
        if (!value) {
          return
        }
        this.$store.commit('accountsPayable/SET_CURRENT_RECURRING_INVOICE', value)
      },
    }
  }
}
</script>
