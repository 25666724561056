<template>
  <div>
    <TablePrintButton
        :loading="loading"
        :has-callback="hasPrintAction"
        :disabled="!billings.length"
        @on-action-callback="showDialog = true"
    />

    <BaseFormDialog v-if="showDialog"
                    :title="isServiceInvoice ? $t('Print Invoice') : $t('Print Billing')"
                    :open.sync="showDialog"
                    :appendToBody="true"
                    size="sm"
    >
      <base-form
          :show-cancel="true"
          :save-text="$t('Print')"
          :loading="loading"
          :focus-on-first-input="false"
          layout="modal"
          @cancel="showDialog = false"
          @submit="onPrint"
      >
        <BillingPdfFormatSelect
            v-model="printFormat"
            :billing-type="billingType"
            class="col-span-6"
        />
      </base-form>

    </BaseFormDialog>
  </div>
</template>
<script lang="ts">
  import axios from 'axios'
  import { previewFileInNewTab } from '@/modules/common/util/downloadFileLocally'
  import { billingTypes } from '@/modules/accounts-receivable/pages/billings/billings'
  import { defineComponent } from 'vue'
  import { defaultTemplates, } from "@/modules/accounts-receivable/utils/billingModels";
  import BillingPdfFormatSelect from "@/modules/accounts-receivable/components/billings/BillingPdfFormatSelect.vue";

  export default defineComponent({
    components: { BillingPdfFormatSelect },
    props: {
      billings: {
        required: true,
        type: Array,
        default: () => [],
      },
      billingType: {
        required: true,
        type: String,
        default: billingTypes.Progress,
      },
    },
    data() {
      return {
        loading: false,
        showDialog: false,
        printFormat: defaultTemplates[this.billingType],
      }
    },
    computed: {
      isProgressBilling() {
        return this.billingType === billingTypes.Progress
      },
      isServiceInvoice() {
        return this.billingType === billingTypes.Service
      },
      isUnitPriceBilling() {
        return this.billingType === billingTypes.UnitPrice
      },
      isLumpSumBilling() {
        return this.billingType === billingTypes.LumpSum
      },
      hasPrintAction() {
        return this.isProgressBilling || this.isServiceInvoice || this.isUnitPriceBilling || this.isLumpSumBilling
      }
    },
    methods: {
      async onPrint() {
        try {
          this.loading = true

          this.$success(this.$t('The file will be generated in a few moments, please wait.'))

          const payload = {
            repositories: this.billings,
          }

          const url = this.isProgressBilling || this.isUnitPriceBilling ? '/restify/billings/actions' : `/restify/billings/${this.$route.params.id}/actions`
          const pdfData = await axios.post(url, payload, {
            params: {
              action: this.printFormat,
            },
            responseType: 'blob',
          })

          previewFileInNewTab(pdfData)

        } catch (err) {
          console.warn(err)
          this.$error(this.$t('Something went wrong. Please try again.'))
        } finally {
          this.loading = false
          this.showDialog = false
        }
      },
    },
  })
</script>
