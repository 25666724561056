<template>
  <div>
    <base-go-to-edit-alert
        v-if="!!existingJobRateId"
        :path="`/payroll/rates/job-rates/${existingJobRateId}/edit`"
        message="Rates for selected job are defined. Please select another job, or edit."
    />
    <base-form :show-back="true"
               :loading="loading"
               :show-cancel="true"
               :focus-on-first-input="!data.id"
               :save-text="$t('Add job rates')"
               :update-text="$t('Update job rates')"
               :submit-disabled="!!existingJobRateId"
               ref="baseForm"
               layout="vertical"
               grid-classes="grid grid-cols-3 gap-x-3"
               submit-button-type="button"
               @submit="onSubmit"
               @cancel="$router.push('/payroll/rates/job-rates')"
    >
      <div class="col-span-3 lg:col-span-1 flex items-center w-full">
        <job-select v-model="model.id"
                    :disabled="!!data.id"
                    :label="$t('Job')"
                    :name="$t('Job')"
                    :initial-value="data"
                    :url-params="{
                      status: 'open',
                      sort: 'number',
                    }"
                    id="job"
                    class="flex-1"
                    rules="required"
                    @change="validateSelection"
        />
        <div class="mx-2">
          <base-tooltip
              :content="$t('This optional payroll table provides the capability to automatically assign pay/deduction rates, or working union codes based on the job where the employee is working.')">
            <IconInfo class="text-gray-500 hover:text-primary-500 cursor-help"/>
          </base-tooltip>
        </div>
      </div>
      <div v-if="model.id"
           class="col-span-3 lg:col-span-2 flex justify-end lg:mt-6 mb-3">
        <copy-rates-action :loading="copyProgress"
                           @submit="onCopyRates"
                           @close="copyFromJob = null"
                           @cancel="copyFromJob = null">
          <job-select v-model="copyFromJob"
                      :label="$t('Job Number to Copy From')"
                      :add-entity="false"
                      :url-params="jobUrlParams"
                      :exclude-ids="[model.id]"
                      rules="required"
          />
        </copy-rates-action>
      </div>
      <UnionAndJobRateEntries
          :data="data"
          ref="entriesTable"
          class="col-span-3"
      />
    </base-form>
  </div>
</template>
<script>
  import axios from 'axios'
  import CopyRatesAction from '@/modules/payroll/components/rates/CopyRatesAction'
  import UnionAndJobRateEntries from '@/modules/payroll/components/rates/UnionAndJobRateEntries'

  export default {
    inheritAttrs: false,
    components: {
      CopyRatesAction,
      UnionAndJobRateEntries,
    },
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        loading: false,
        model: {
          id: '',
        },
        copyFromJob: null,
        copyProgress: false,
        jobUrlParams: {
          sort: 'number',
          having_job_rates: true,
        },
        existingJobRateId: false,
      }
    },
    methods: {
      async validateSelection(jobId) {
        try {
          this.loading = this.existingJobRateId = false
          const { data } = await axios.get(`restify/job-rates?job_id=${jobId}`)

          this.existingJobRateId = this.get(data, '[0][attributes][job_id]', false)
        } catch (err) {
          console.warn(err)
        } finally {
          this.loading = false
        }
      },
      async onSubmit() {
        try {
          this.loading = true
          if (this.data.id) {
            await this.$refs.entriesTable.storeProgress(this.model.id)
            this.$success(this.$t('Job rates successfully managed.'))
          } else {
            await this.$refs.entriesTable.storeProgress(this.model.id)
            this.$success(this.$t('Job rates successfully managed.'))
            await this.$router.push(`/payroll/rates/job-rates/${this.model.id}/edit`)
          }
        } catch (err) {
          console.warn(err)
          this.$error(this.$t('Something went wrong. Please try again.'))
        } finally {
          this.loading = false
        }
      },
      async onCopyRates() {
        try {
          this.copyProgress = true
          await axios.post('/restify/job-rates/actions?action=copy-job-rates-to-job', {
            from: this.copyFromJob,
            to: this.model.id,
          })
          this.refreshRatesTable()
        } catch (err) {
          console.warn(err)
          this.$error(this.$t('Something went wrong. Please try again.'))
        } finally {
          this.copyProgress = false
        }
      },
      refreshRatesTable() {
        const { entriesTable } = this.$refs
        if (!entriesTable) {
          return
        }
        entriesTable.refreshEntriesTable(this.model.id)
      },
    },
    watch: {
      data: {
        immediate: true,
        handler(value) {
          if (!value?.id) {
            return
          }
          this.model = {
            ...this.model,
            ...value.attributes,
          }
        },
      },
    },
  }
</script>
