<template>
  <base-form :loading="loading"
             :show-cancel="showCancel"
             :save-text="$t('Create state tax jurisdiction')"
             :update-text="$t('Update state tax jurisdiction')"
             :can-create-another-entity="!model.id"
             layout="modal"
             grid-classes="grid grid-cols-12 gap-x-4"
             @cancel="$emit('cancel')"
             @submit="onSubmit"
  >
    <div class="col-span-12 md:col-span-3">
      <state-select v-model="model.code"
                    :label="$t('Code')"
                    :name="$t('Code')"
                    :placeholder="$t('Code')"
                    :disabled="!!get(data, 'id', false)"
                    id="code"
                    rules="required"
                    @entity-change="onChangeState"
      />
    </div>
    <div class="col-span-12 md:col-span-5">
      <base-input v-model="model.description"
                  :label="$t('Description')"
                  :name="$t('Description')"
                  :placeholder="$t('Description')"
                  id="code"
                  rules="max:150"
      />
    </div>
    <div class="col-span-12 md:col-span-2">
      <base-switch v-model="model.is_401k_exempt"
                   :label-info="$t('401k Exempt')"
                   id="is_401k_exempt"
      />
    </div>
    <div class="col-span-12 md:col-span-2">
      <base-switch v-model="model.is_125s_exempt"
                   :label-info="$t('125S  Exempt')"
                   id="is_125s_exempt"
      />
    </div>
    <div class="col-span-12 md:col-span-3">
      <base-input v-model="model.employer_number"
                  :tip="$t('Enter the appropriate employer I.D. code for the current company for this tax jurisdiction and the withholding agency.')"
                  :label="$t('Withholding')"
                  :name="$t('Withholding')"
                  :placeholder="$t('Withholding')"
                  id="employer_number"
                  rules="max:12"
      />
    </div>
    <div class="col-span-12 md:col-span-3">
      <vendor-select v-model="model.withholding_payee_vendor_id"
                     :label="$t('WH Vendor Payee')"
                     :name="$t('WH Vendor Payee')"
                     :placeholder="$t('WH Vendor Payee')"
                     :initial-value="get(data, 'relationships.whVendor')"
                     id="withholding_payee_vendor_id"
      />
    </div>
    <div class="col-span-12 md:col-span-3">
      <base-input v-model="model.sui_code"
                  :tip="$t('Enter the appropriate employer I.D. code for the current company for this tax jurisdiction and the unemployment insurance agency.')"
                  :label="$t('Unemployment')"
                  :name="$t('Unemployment')"
                  :placeholder="$t('Unemployment')"
                  id="unemployment"
                  rules="max:12"
      />
    </div>
    <div class="col-span-12 md:col-span-3">
      <vendor-select v-model="model.ui_payee_vendor_id"
                     :label="$t('UI Vendor Payee')"
                     :name="$t('UI Vendor Payee')"
                     :placeholder="$t('UI Vendor Payee')"
                     :initial-value="get(data, 'relationships.uiVendor')"
                     id="ui_payee_vendor_id"
      />
    </div>
    <div class="col-span-12 text-gray-500 font-semibold text-sm leading-6 tracking-wide border-b py-1">
      • {{ $t('This year') }} <b>{{ currentYear }}</b>
    </div>
    <div class="col-span-12 md:col-span-4 md:mt-4">
      <base-input v-model="model.sui_rate"
                  :min="0"
                  :max="999.99"
                  :step="0.01"
                  :label="$t('Unemployment Percent')"
                  :name="$t('Unemployment percent')"
                  format="percent"
                  id="sui_rate"
                  type="number"
                  rules="min_value:0|max_value:999.99"
      />
    </div>
    <div class="col-span-12 md:col-span-4 md:mt-4">
      <base-input v-model="model.sui_limit_amount"
                  :min="0"
                  :max="99999999.99"
                  :step="0.01"
                  :label="$t('Annual Earnings Limit')"
                  :name="$t('Annual Earnings Limit')"
                  format="price"
                  id="sui_rate"
                  type="number"
                  rules="min_value:0|max_value:99999999.99"
      />
    </div>
    <div class="col-span-12 md:col-span-4 md:mt-4">
      <base-input v-model="model.training_percent"
                  :min="0"
                  :max="999.99"
                  :step="0.01"
                  :label="$t('Training Tax Percent')"
                  :name="$t('Training tax percent')"
                  format="percent"
                  id="sui_rate"
                  type="number"
                  rules="min_value:0|max_value:999.99"
      />
    </div>
    <div class="col-span-12 text-gray-500 font-semibold text-sm leading-6 tracking-wide border-b py-1">
      • {{ $t('Last year') }} <b>{{ lastYear }}</b>
    </div>
    <div class="col-span-12 md:col-span-4 md:mt-4">
      <base-input v-model="model.sui_rate_last_year"
                  :min="0"
                  :max="999.99"
                  :step="0.01"
                  :label="$t('Unemployment Percent')"
                  :name="$t('Unemployment Percent')"
                  format="percent"
                  id="sui_rate_last_year"
                  type="number"
                  rules="min_value:0|max_value:999.99"
      />
    </div>
    <div class="col-span-12 md:col-span-4 md:mt-4">
      <base-input v-model="model.sui_limit_amount_last_year"
                  :min="0"
                  :max="99999999.99"
                  :step="0.01"
                  :label="$t('Annual Earnings Limit')"
                  :name="$t('Annual Earnings Limit')"
                  format="price"
                  id="sui_limit_amount_last_year"
                  type="number"
                  rules="min_value:0|max_value:99999999.99"
      />
    </div>
    <div class="col-span-12 md:col-span-4 md:mt-4">
      <base-input v-model="model.training_percent_last_year"
                  :min="0"
                  :max="999.99"
                  :step="0.01"
                  :label="$t('Training Tax Percent')"
                  :name="$t('Training Tax Percent')"
                  format="percent"
                  id="training_percent_last_year"
                  type="number"
                  rules="min_value:0|max_value:999.99"
      />
    </div>
    <div class="col-span-12 md:grid md:grid-cols-3 gap-x-3">
      <base-input v-model="model.work_comp_modify_factor"
                  :tip="$t('State Worker’s Comp Experience Modification Factor')"
                  :label="$t('Worker’s Comp Percent')"
                  :name="$t('Worker’s Comp Percent')"
                  :min="0"
                  :max="999.99"
                  :step="0.01"
                  class="col-span-1"
                  format="percent"
                  id="sui_rate"
                  type="number"
                  rules="min_value:0|max_value:999.99"
      />
      <div class="col-span-2 md:mt-4">
        <base-alert :type="$promptType.Warning" closable>
          {{$t('Non-Zero value will Override default % in payroll standard information setup page.')}}
        </base-alert>
      </div>
    </div>
  </base-form>
</template>
<script>
  import axios from 'axios'

  export default {
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        showCancel: true,
        loading: false,
        model: {
          code: '',
          description: '',
          is_125s_exempt: false,
          is_401k_exempt: false,
          employer_number: '',
          sui_code: '',
          withholding_payee_vendor_id: undefined,
          ui_payee_vendor_id: undefined,
          sui_rate: 0,
          sui_limit_amount: 0,
          training_percent: 0,
          sui_rate_last_year: 0,
          sui_limit_amount_last_year: 0,
          training_percent_last_year: 0,
          work_comp_modify_factor: 0,
        },
      }
    },
    computed: {
      currentYear() {
        return new Date().getFullYear()
      },
      lastYear() {
        return this.currentYear - 1
      },
    },
    methods: {
      async onSubmit() {
        try {
          this.loading = true
          if (this.model.id) {
            await axios.put(`/restify/state-tax-jurisdictions/${this.model.id}`, this.model)
            this.$success(this.$t('State tax jurisdiction updated'))
            this.$emit('save', this.model)
          } else {
            const { data } = await axios.post('/restify/state-tax-jurisdictions', this.model)
            this.$success(this.$t('State tax jurisdiction created'))
            this.$createAnotherEntity ? this.$emit('create-another') : this.$emit('save', data)
          }

        } catch (err) {
          console.log({err})
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not update the state tax jurisdiction'))
        } finally {
          this.loading = false
        }
      },
      onChangeState(state) {
        this.model.description = state?.description || ''
      }
    },
    watch: {
      'data.attributes': {
        immediate: true,
        handler(value) {
          if (!value?.id) {
            return
          }
          this.model = {
            ...this.model,
            ...value,
          }
        },
      },
    },
  }
</script>
