<template>
  <div>
    <BaseDataTable
        v-bind="$attrs"
        :data="tableData"
        :columns="columns"
        :can-reorder-columns="false"
        :show-pagination="showPagination"
        :open-entity-in-new-tab="openEntityInNewTab"
        :delete-action="tryDeleteContact"
        :add-text="$t('New contact')"
        :limitMaxHeight="false"
        add-button-variant="primary-light"
        actions="add,delete,edit"
        ref="table"
        @add="onOpenContactFormDialog({})"
        @edit="onOpenContactFormDialog"
        small-header
        compact
    >
      <template #attributes.name="{row}">
        <div>
          <div>{{ row?.attributes?.name }}</div>
          <div v-if="row?.attributes?.title" class="text-gray-600 text-xs -mt-1.5">{{ row?.attributes?.title }}</div>
        </div>
      </template>
      <template v-slot:custom-header>
        <h4>
          {{ $t(title) }}
        </h4>
      </template>
      <template #extra-actions-before="{row}">
        <base-button variant="gray-light"
                     title="View"
                     size="icon"
                     class="table-action-button"
                     @click="copyContact(row)"
        >
          <CopyIcon class="w-4 h-4" />
        </base-button>
      </template>
    </BaseDataTable>
    <BaseFormDialog
        v-if="showContactFormDialog"
        :title="contactToEdit.id ? $t('Edit Contact') : $t('Add Contact')"
        :open.sync="showContactFormDialog"
        @close="onCloseContactFormDialog"
    >
      <ContactForm
          :loading="loading"
          :save-text="contactToEdit.id ? $t('Update Contact') : $t('Add Contact')"
          :entity="contactToEdit"
          layout="modal"
          @cancel="onCloseContactFormDialog"
          @onSubmit="onSubmit"
      />
    </BaseFormDialog>
  </div>
</template>
<script>
  import axios from 'axios'
  import ContactForm from '@/modules/common/components/contacts/ContactForm.vue'
  import { CopyIcon } from "vue-feather-icons";
  import cloneDeep from "lodash/cloneDeep";

  export default {
    components: {
      CopyIcon,
      ContactForm,
    },
    props: {
      title: {
        type: String,
        default: 'Contacts',
      },
      showPagination: {
        type: Boolean,
        default: false,
      },
      contacts: {
        type: Array,
        default: () => [],
      },
      entity: {
        type: String,
        default: '',
      },
      entityId: {
        type: [String, Number],
        default: '',
      },
    },
    data() {
      return {
        loading: false,
        openEntityInNewTab: false,
        showContactFormDialog: false,
        contactToEdit: {},
        contactIndex: 0,
        temporaryContacts: [],
        columns: [
          {
            label: this.$t('Name'),
            prop: 'attributes.name',
            minWidth: 100,
            maxWidth: 150,
          },
          {
            label: this.$t('Type'),
            prop: 'attributes.type',
            maxWidth: 100,
            component: 'Status',
            align: 'center',
          },
          {
            label: this.$t('Email'),
            prop: 'attributes.email',
            component: 'EmailLink',
            minWidth: 100,
            maxWidth: 150,
          },
          {
            label: this.$t('Phone'),
            prop: 'attributes.phone',
            component: 'PhoneLink',
            minWidth: 100,
            maxWidth: 150,
          },
        ],
      }
    },
    computed: {
      tableData() {
        return this.entityId ? this.contacts : this.temporaryContacts
      },
    },
    methods: {
      copyContact(row) {
        this.contactToEdit = cloneDeep(row)
        delete this.contactToEdit.id
        delete this.contactToEdit.attributes.id
        this.showContactFormDialog = true
      },
      onCloseContactFormDialog() {
        this.showContactFormDialog = false
        this.contactToEdit = {}
        this.contactIndex = -1
      },
      onOpenContactFormDialog(row, index = -1) {
        this.contactToEdit = { ...row }
        this.contactIndex = index
        this.$nextTick(() => {
          this.showContactFormDialog = true
        })
      },
      async onSubmit(contact) {
        const contactObject = {
          attributes: { ...contact },
        }

        if (!this.entityId) {
          if (this.contactIndex === -1) {
            this.temporaryContacts.push(contactObject)
          } else {
            this.temporaryContacts.splice(this.contactIndex, 1, contactObject)
          }

          this.showContactFormDialog = false
          this.contactIndex = -1
          return
        }
        try {
          this.loading = true
          if (!contact.dob) {
            contact.dob = undefined
          }
          if (contact.id) {
            await axios.put(`restify/${this.entity}/${this.entityId}/contacts/${contact.id}`, contact)
            this.$success('Contact updated successfully.')
          } else {
            await axios.post(`restify/${this.entity}/${this.entityId}/contacts`, contact)
            this.$success('Contact added successfully.')
          }
          this.$emit('refresh')
          this.showContactFormDialog = false
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Something went wrong. Please try again.'))
        } finally {
          this.loading = false
        }
      },
      async attachTemporaryContacts(entityId) {
        if (!this.temporaryContacts.length) {
          return
        }
        const savePromises = this.temporaryContacts.map(
            ({ attributes }) => axios.post(`restify/${this.entity}/${entityId}/contacts`, attributes),
        )
        await Promise.all(savePromises)
      },
      async tryDeleteContact(contact, index) {
        if (!contact.id) {
          this.temporaryContacts.splice(index, 1)
          this.$success('Contact deleted successfully.')
          return
        }

        try {
          await axios.delete(`restify/${this.entity}/${this.entityId}/contacts/${contact.id}`)
          this.$success('Contact deleted successfully.')
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Something went wrong. Please try again.'))
        } finally {
          this.$emit('refresh')
        }
      },
    },
  }
</script>
