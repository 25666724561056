<template>
  <popover-config @confirm="onChange">
    <base-input v-model="inputModel"
                v-bind="$attrs"
    />
  </popover-config>
</template>
<script>
  import PopoverConfig from "@/modules/common/components/PopoverConfig";

  export default {
  name: 'default-field',
  inheritAttrs: false,
  components: {
    PopoverConfig
  },
  props: {
    value: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      inputModel: this.value,
    }
  },
  methods: {
    onChange() {
      this.$emit('on-change', this.inputModel)
    },
  }
}
</script>
