export function getCellClasses(column) {
  return {
    'relative': column.prop === 'select',
    'text-right': column.align === 'right',
    'text-left': column.align === 'left',
    'text-center': column.align === 'center',
    [`td-${column.prop}`]: column.prop,
    'px-1.5 py-1 small-cell': column.smallCell || this.compact,
    'px-2 py-2': !column.smallCell && !this.compact,
    'hidden md:table-cell': column.hideOnMobile,
    'print:hidden': column.prop === 'select',
    [column.rowClasses]: column.rowClasses,
  }
}
