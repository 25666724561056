<template>
  <div>
    <base-report-form
      :filters="model"
      :report-number="reportNumber"
      :transform-filters="transformFilters"
      divider-class="col-span-6"
    >
      <template #filters>
        <base-filter-row
          :title="$t('Sort By')"
          class="col-span-6"
        >
          <base-select
            v-model="model.sort_by"
            :multiple-limit="3"
            :options="sortByOptions"
            :name="$t('Sort By')"
            :allow-select-all="false"
            multiple
            rules="required"
            class="col-span-3"
            @change="onSortByChange"
          />
        </base-filter-row>
        <base-filter-row
          :title="$t('Subtotal By')"
          class="col-span-6"
        >
          <base-select
            v-model="model.subtotal_on"
            :multiple-limit="3"
            :options="subTotalOptions"
            :name="$t('Subtotal On')"
            :allow-select-all="false"
            multiple
            rules="required"
            class="col-span-3"
          />
        </base-filter-row>
        <CustomerRangeFilter v-model="model" class="col-span-6"/>
        <WorkOrderRangeFilter v-model="model" class="col-span-6"/>
        <ServiceCodeRangeFilter v-model="model" class="col-span-6"/>
        <PeriodYearRangeFilter v-model="model" class="col-span-6"/>
        <base-filter-row
          :title="$t('Type & Category')"
          class="col-span-6"
        >
          <base-select
            v-model="model.include"
            :options="includeOptions"
            :name="$t('Include')"
            rules="required"
            class="col-span-2"
          />
          <ServiceBillingCategorySelect
            v-model="model.category_id"
            :hide-label="true"
            clearable
            class="col-span-2"
          />
        </base-filter-row>

        <base-filter-row
          :title="$t('Worksite & Work Order Status')"
          class="col-span-6"
        >
          <base-input
            v-model="model.site_number"
            :name="$t('Worksite #')"
            :placeholder="$t('Worksite #')"
            class="col-span-2"
          />
          <base-select
            v-model="model.work_order_statuses"
            :options="workOrderStatusOptions"
            :name="$t('Work Order Status')"
            :placeholder="$t('Work Order Status')"
            multiple
            class="col-span-2"
          />
        </base-filter-row>

      </template>
      <template #table="{loading, data}">
        <ServiceBillingHistoryReportTable
          :data="data"
          :data-loading="loading"
          :format="model.format"
          :filters="model"
          :report-number="reportNumber"
          ref="reportTable"
        />
      </template>
    </base-report-form>
  </div>
</template>
<script>
import ServiceBillingHistoryReportTable
  from "@/modules/service-billing/components/reports/ServiceBillingHistoryReportTable.vue";
import CustomerRangeFilter from "@/components/range-filters/CustomerRangeFilter.vue";
import WorkOrderRangeFilter from "@/components/range-filters/WorkOrderRangeFilter.vue";
import ServiceCodeRangeFilter from "@/components/range-filters/ServiceCodeRangeFilter.vue";
import { PeriodYearRangeFilter } from "@/components/range-filters";
import {
  getIncludeOptions,
  getSBHistorySortOptions, getWorkOrderStatusOptions, IncludeOptions,
  SBHistorySortBy,
} from "@/modules/service-billing/components/reports/sortByOptions";

export default {
  components: {
    PeriodYearRangeFilter,
    ServiceCodeRangeFilter,
    WorkOrderRangeFilter,
    CustomerRangeFilter,
    ServiceBillingHistoryReportTable
  },
  data() {
    return {
      model: {
        sort_by: [SBHistorySortBy.Worksite],
        subtotal_on: [SBHistorySortBy.Worksite],
        customer_code_from: null,
        customer_code_to: null,
        work_order_number_from: null,
        work_order_number_to: null,
        service_billing_code_from: null,
        service_billing_code_to: null,
        period_start: null,
        fiscal_year_start: null,
        period_end: null,
        fiscal_year_end: null,
        include: IncludeOptions.All,
        category_id: null,
        site_number: null,
        work_order_statuses: []
      }
    }
  },
  computed: {
    sortByOptions() {
      return getSBHistorySortOptions()
    },
    subTotalOptions() {
      return this.sortByOptions.filter(option => this.model.sort_by.includes(option.value))
    },
    includeOptions() {
      return getIncludeOptions()
    },
    workOrderStatusOptions() {
      return getWorkOrderStatusOptions()
    },
    reportNumber() {
      return '5584'
    },
  },
  methods: {
    onSortByChange(value) {
      this.model.subtotal_on = value
    },
    transformFilters(params) {
      const { sort_by } = params
      if (!Array.isArray(sort_by) && typeof sort_by === 'string') {
        params.sort_by = [sort_by]
        params.subtotal_on = [sort_by]
        this.model.subtotal_on = [sort_by]
        this.model.sort_by = [sort_by]
      }
      return params
    }
  }
}
</script>
