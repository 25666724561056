<template>
  <base-select
    v-bind="$attrs"
    v-on="$listeners"
    :name="$attrs.name || $t('Cost Center')"
    :placeholder="$attrs.placeholder || $t('Cost Center')"
    :label="$attrs.label"
    :id="$attrs.id || 'cost-center'"
    :options="options"
    clearable
  />
</template>
<script>
import { allCostCenterOptions } from "@/components/ag-grid/columns/costCenterColumns";

export default {
  inheritAttrs: false,
  props: {
    filterOptions: {
      type: Function,
      default: null
    }
  },
  computed: {
    options() {
      if (!this.filterOptions) {
        return allCostCenterOptions
      }

      return allCostCenterOptions.filter(this.filterOptions)
    }
  }
}
</script>
