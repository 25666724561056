<template>
  <base-form :save-text="$t('Update Translations')"
             :show-buttons="false"
  >
    <template v-slot:title>
      <div class="flex items-center">
        <span class="mr-1">{{ $t('Custom Translations') }}</span>
      </div>
    </template>
    <template v-slot:description>
      <div class="my-1">
        {{ $t('You can override and rename certain properties and fields here.') }}
      </div>
    </template>
    <div class="col-span-6">
      <div class="flex w-full justify-end">
        <base-input v-model="searchText"
                    :placeholder="$t('Search...')"
                    class="search-input"
                    clearable
        >
          <template v-slot:prefix>
            <SearchIcon class="w-4 h-4 text-gray-400"/>
          </template>
        </base-input>
      </div>
      <TranslationTable :data="filteredTranslations"/>
    </div>
  </base-form>
</template>
<script>
  import { SearchIcon } from 'vue-feather-icons'
  import { availableOptions } from '@/enum/translations'
  import TranslationTable from '@/modules/settings/components/TranslationTable'

  export default {
    components: {
      SearchIcon,
      TranslationTable,
    },
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        loading: false,
        model: {},
        searchText: '',
      }
    },
    computed: {
      translations() {
        let data = this.$store.getters['settings/getByKey']('translations')
        if (typeof data === 'string') {
          data = JSON.parse(data)
        }
        return data?.length ? data : availableOptions
      },
      filteredTranslations() {
        return this.translations.filter(translation => translation?.key.toLowerCase().includes(this.searchText.toLowerCase()))
      },
    },
  }
</script>
<style lang="scss" scoped>
  .search-input {
    min-width: 250px;
  }
</style>
