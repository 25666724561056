<template>
  <div class="import-data mt-4">
    <ImportForm v-bind="importSettings"
                @success="redirectToList"
    />
  </div>
</template>
<script>
  import ImportForm from "@/modules/common/components/import/ImportForm";
  import { getInitialMapping } from "@/modules/payroll/utils/timecardImportColumns";

  const colMappings = {
    Code: {
      value: 'code',
      label: 'Code',
      alternativeLabels: ['Job Code'],
    },
    Description: {
      value: 'description',
      label: 'Description',
      alternativeLabels: ['Job Description'],
    },
  }

  const options = [
    colMappings.Code,
    colMappings.Description,
  ]

  export default {
    name: 'JobCategoryImport',
    components: {
      ImportForm,
    },
    data() {
      return {
        model: {
          overwrite: false,
        },
        importSettings: {
          url: '/restify/job-categories/import',
          columnOptions: options,
          columnMappings: colMappings,
          initialMapping: getInitialMapping(options),
          type: 'job-categories-import',
          resource: 'job-categories',
        },
      }
    },
    methods: {
      async redirectToList() {
        await this.$router.push('/job-costing/settings/categories')
      },
    }
  }
</script>
