<script>
  import get from 'lodash/get'
  import StatusBadge from "@/components/common/StatusBadge";
  import i18n from "@/i18n";
  export default {
    functional: true,
    components: {
      StatusBadge
    },
    props: {
      column: {
        type: Object,
        default: () => ({})
      },
      row: {
        type: Object,
        default: () => ({})
      },
      params: {
        type: Object,
        default: () => ({})
      },
    },
    render(h, { props }) {
      function getValue() {
        const value = get(props.row, props.column.prop)
        if (value === 1 || value === true || value === '1') {
          return i18n.t('Yes')
        }
        if (value === null || value === 0 || value === false || value === '0') {
          return i18n.t('No')
        }
        if (value === undefined) {
          return 'N/A'
        }
        return value
      }

      if (props.params?.node?.group || props.params?.node?.footer) {
        return h(null)
      }

      return h(StatusBadge, {
        props: {
          status: getValue(),
          plain: get(props.params, 'colDef.cellEditorParams.plain', false)
        }
      })
    }
  }
</script>
