<template>
  <div class="flex flex-1 items-center justify-between form-header-summary">
    <div class="flex items-center">
      <status-badge :status="order.status"/>
      <span class="mx-2">
      {{ $t('P.O. Summary') }}
    </span>
    </div>
    <portal-target name="po-summary"/>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
