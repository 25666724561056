<template>
  <ReconciliationForm/>
</template>
<script>
  import ReconciliationForm from '@/modules/ledger/components/ReconciliationForm'

  export default {
    components: {
      ReconciliationForm,
    },
  }
</script>
