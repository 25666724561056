<template>
  <JobRatesForm v-if="!loading"
                :data="job"
  />
</template>
<script>
  import axios from 'axios'
  import JobRatesForm from '@/modules/payroll/components/rates/JobRatesForm'

  export default {
    data() {
      return {
        loading: false,
        job: {},
      }
    },
    components: {
      JobRatesForm,
    },
    methods: {
      async getJobRates() {
        try {
          const { id } = this.$route.params
          if (!id) {
            return
          }
          this.loading = true
          const { data } = await axios.get(`/restify/jobs/${id}`)
          this.job = data
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Something went wrong please try again.'))
        } finally {
          this.loading = false
        }
      },
    },
    mounted() {
      this.getJobRates()
    },
  }
</script>
