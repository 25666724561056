<template>
  <base-form-dialog v-bind="$attrs"
                    v-on="$listeners"
                    :title="$t('Add Line Item')"
                    :append-to-body="true"
                    size="2xl"
                    @close="$emit('close')"

  >
    <base-tabs v-model="activeTab"
               :tabs="tabs"
               nav-classes="pl-6"
    >
      <CostLineItemForm
          v-if="activeTab === $t('Cost Line Item')"
          :job="job"
          :data="budget"
          :redirect-to-list-after-store="false"
          :type="costTypes.Cost"
          @save="$emit('save')"
          @cancel="$emit('close')"
      />
      <IncomeLineItemForm
          v-if="activeTab === $t('Income Line Item')"
          :job="job"
          :data="budget"
          :redirect-to-list-after-store="false"
          :type="costTypes.Income"
          @save="$emit('save')"
          @cancel="$emit('close')"
      />
    </base-tabs>
  </base-form-dialog>
</template>
<script>
  import { costTypes  } from '@/enum/enums'
  import CostLineItemForm from '@/modules/job-costing/components/line-items/CostLineItemForm'
  import IncomeLineItemForm from '@/modules/job-costing/components/line-items/IncomeLineItemForm'

  export default {
    inheritAttrs: false,
    components: {
      IncomeLineItemForm,
      CostLineItemForm,
    },
    props: {
      job: {
        type: Object,
        default: () => ({}),
      },
      budget: {
        type: Object,
        default: () => ({}),
      },
      defaultItemType: {
        type: String,
        default: costTypes.Cost,
      },
    },
    data() {
      return {
        costTypes,
        activeTab: this.defaultItemType === costTypes.Cost
          ? this.$t('Cost Line Item')
          : this.$t('Income Line Item'),
      }
    },
    computed: {
      tabs() {
        return [
          {
            name: this.$t('Cost Line Item'),
          },
          {
            name: this.$t('Income Line Item'),
          },
        ]
      },
    },
  }
</script>
