<template>
  <base-data-table
      v-bind="defaultAttributes"
      :columns="columns"
      :data="getData"
      :total-rows="rowsLength"
      @force-pagination="forcePagination"
  >

    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template #header="{row}">
      <component
          v-if="row.header?.id "
          :is="getHeaderComponent"
          :data="row.header"
          :show-preview="false"
      />
      <span v-else> {{  groupByJob ? $t('(Non - Job Payables)') : $t('(Non - Work Order Payables)') }} </span>
    </template>

    <template #invoice.number="{row}">
      <div class="flex items-center space-x-1">
        <InvoiceLink :id="row.invoice?.id" :data="row.invoice"/>
        <InvoiceStatusPopover :invoice="{id: row.invoice?.id, attributes: row.invoice}"/>
      </div>
    </template>

    <template #vendor="{row}">
      <router-link
          v-if="row.vendor?.id"
          :to="`/accounts-payable/vendors/${row.vendor.id}/view`"
          class="w-20 inline-block">
        {{ row.vendor.code }}
      </router-link>
      <span v-else class="w-20 inline-block"/>
      {{ row.vendor.name }}
    </template>

    <template #total="{row}">
      {{ $formatPrice(row.total || row.totals?.open_amount) }}
    </template>

    <template #subtotal="{subtotal}">
      <td :class="{'bottom-border': groupHasBorder(subtotal)}">
        <vendor-link
            v-if="subtotal.vendor"
            :data="subtotal.vendor"
            :show-preview="false"
            :show-description="false"
        />
      </td>
      <td :class="{'bottom-border': groupHasBorder(subtotal)}"
          colspan="3">
        <div class="flex items-center">
          <span class="py-2">{{ subtotal.name || getTotalsLabel }}</span>
          <template v-if="subtotal.retention_amount">
            <span class="px-10">({{ $t('Retention Payable:') }}</span>
            <span>{{ $formatPrice(subtotal.retention_amount) }})</span>
          </template>
        </div>
      </td>
      <td v-if="actualDays"/>
      <td v-for="column in amountColumns"
          :class="{'bottom-border': groupHasBorder(subtotal)}"
          align="right"
      >
        <span :class="{'top-border flex flex-1 justify-end': !displayOnlyTotals}">
          {{ $formatPrice(get(subtotal, column.prop, 0)) }}
        </span>
      </td>
    </template>

  </base-data-table>
</template>
<script>
  import { agedByGrouping } from '@/components/range-filters'
  import { agedByColumns } from '@/modules/common/components/reports/util'
  import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'
  import { agedByViewFormats } from '@/modules/accounts-payable/components/reports/util'
  import InvoiceLink from "@/components/links/InvoiceLink.vue";

  const module = 'Payable'

  export default {
    components: { InvoiceLink },
    extends: ReportTableWrapper,
    props: {
      format: {
        required: true,
        type: String,
        default: agedByViewFormats.EachInvoice,
      },
      days: {
        required: true,
        type: Array,
        default: () => [30, 60, 90],
      },
      actualDays: {
        type: [Boolean, String],
        default: false,
      },
      groupBy: {
        required: true,
        type: String,
        default: agedByGrouping.Job,
      },
    },
    computed: {
      columns() {
        const columns = [
          {
            label: this.$t('Vendor'),
            prop: 'vendor',
            minWidth: 200,
            maxWidth: 320,
          },
          {
            label: 'Invoice #',
            prop: 'invoice.number',
            minWidth: 170,
            maxWidth: 250,
          },
          {
            label: this.$t('Invoice <br> Date'),
            prop: 'invoice.date',
            component: 'FormattedDate',
            minWidth: 90,
            maxWidth: 90,
            summary: () => this.groupByJob ? `${this.$t('Total Selected Jobs')}<br><br>&nbsp;` : `${this.$t('Total Selected WO')}<br><br>&nbsp;`,
          },
          {
            label: this.$t('Due <br> Date'),
            prop: 'invoice.due_date',
            component: 'FormattedDate',
            minWidth: 90,
            maxWidth: 90,
          },
        ]

        if (!this.data.aging_totals) {
          return columns
        }

        const agingColumns = agedByColumns(this.days, this.data.aging_totals, this.data.grand_totals, module, this.actualDays)
        return [
          ...columns,
          ...agingColumns,
        ]
      },
      displayOnlyTotals() {
        return this.format === agedByViewFormats.JobTotal
      },
      groupByJob() {
        return this.groupBy === agedByGrouping.Job
      },
      getHeaderComponent() {
        return this.groupByJob ? 'JobLink' : 'WorkOrderLink'
      },
      getTotalsLabel() {
        return this.groupByJob ? this.$t('Job Total') : this.$t('Work Order Total')
      },
    },
    methods: {
      composeRows(data) {

        if (this.format === agedByViewFormats.JobTotal) {
          data.forEach(group => {

            const header = this.groupByJob ? group.job : group.work_order

            this.rows.push({
              header: header || { id: null },
            })

            this.rows.push({
              subtotal: {
                aging_totals: group.aging_totals,
                retention_amount: group.totals?.retention_amount,
                total: group.totals?.open_amount,
              },
            })
          })

          return this.rows
        }

        if (this.format === agedByViewFormats.VendorTotal) {
          data.forEach(group => {

            const header = this.groupByJob ? group.job : group.work_order

            this.rows.push({
              header: header || { id: null },
            })

            const vendors = group.vendors.map(vendorObject => {
              return {
                vendor: vendorObject.vendor,
                aging_totals: vendorObject.aging_totals,
                retention_amount: vendorObject.totals?.retention_amount,
                total: vendorObject.totals?.open_amount,
              }
            })

            this.rows.push(...vendors)

            this.rows.push({
              subtotal: {
                aging_totals: group.aging_totals,
                retention_amount: group.totals?.retention_amount,
                total: group.totals?.open_amount,
              },
            })
          })

          return this.rows
        }

        data.forEach(group => {

          const header = this.groupByJob ? group.job : group.work_order

          this.rows.push({
            header: header || { id: null },
          })

          const invoiceEntries = []

          group.vendors.forEach(_group => {
            const { vendor } = _group

            const invoices = _group.invoices.map((invoice, index) => {

              return {
                vendor: {
                  id: index === 0 ? vendor.id : false,
                  code: vendor.code,
                  name: vendor.name,
                },
                ...invoice,
              }
            })

            invoiceEntries.push(...invoices)

            if (_group.invoices?.length > 1) {
              invoiceEntries.push({
                subtotal: {
                  vendor,
                  name: this.$t('Vendor Subtotal'),
                  aging_totals: _group.aging_totals,
                  retention_amount: _group.totals?.retention_amount,
                  total: _group.totals?.open_amount,
                },
              })
            }
          })

          this.rows.push(...invoiceEntries)

          this.rows.push({
            subtotal: {
              aging_totals: group.aging_totals,
              retention_amount: group.totals?.retention_amount,
              total: group.totals?.open_amount,
            },
          })

        })

        return this.rows
      },
      groupHasBorder(subtotal) {
        return !subtotal.name && this.format === agedByViewFormats.EachInvoice
      },
    },
  }
</script>
