<template>
  <base-form
    :auto-height="true"
    :focus-on-first-input="false"
    @submit="onSubmit"
  >
    <div class="col-span-6">
      <base-textarea
        v-model="model.question"
        :label="$t('New Question')"
        :placeholder="$t('Question Name')"
        rules="required"
      />
    </div>
    <div class="col-span-6">
      <base-textarea
        v-model="model.description"
        :label="$t('Description (optional)')"
        :placeholder="$t('Additional description for the question')"
      />
    </div>
    <div class="flex w-full gap-x-4 col-span-6">
      <div class="min-w-[300px]">
        <base-select
          v-model="model.type"
          :options="questionOptions"
          :label="$t('Response Type')"
          @change="onResponseTypeChange"
        />
      </div>
      <div class="flex-1">
        <template v-if="[questionTypes.Boolean, questionTypes.Signature].includes(model.type)">
          <base-switch v-model="model.default_value" :label-info="$t('Response Default Answer (Left No, Right Yes)')"/>
        </template>
        <template v-else>
          <base-input v-model="model.default_value" :label="$t('Default')"/>
        </template>
      </div>
    </div>
    <template #footer>
      <BaseButton variant="primary-light" type="submit">
        <PlusIcon v-if="!data" class="w-4 h-4"/>
        <span v-if="!data">{{ $t('Add Question') }}</span>
        <span v-else>{{ $t('Update Question') }}</span>
      </BaseButton>
    </template>
  </base-form>
</template>
<script>
import { PlusIcon } from "vue-feather-icons";
import { questionTypes } from "@/modules/payroll/utils/payrollUtils";
import i18n from "@/i18n";

export default {
  components: {
    PlusIcon
  },
  props: {
    data: {
      type: Object,
    }
  },
  data() {
    return {
      questionTypes,
      model: {
        key: '',
        question: '',
        description: "",
        type: questionTypes.Boolean,
        default_value: false,
      },
      questionOptions: [
        {
          label: i18n.t('Yes or No'),
          value: questionTypes.Boolean,
        },
        {
          label: i18n.t('Text'),
          value: questionTypes.Text,
        },
        {
          label: i18n.t('Urgent Text'),
          value: questionTypes.UrgentText,
        },
        {
          label: i18n.t('Signature'),
          value: questionTypes.Signature,
        },
      ]
    }
  },
  methods: {
    onResponseTypeChange(type) {
      if (type === questionTypes.Boolean) {
        this.model.default_value = false
      } else {
        this.model.default_value = ''
      }
    },
    onSubmit() {
      this.$emit('submit', this.model)
    }
  },
  watch: {
    data: {
      immediate: true,
      handler(value) {
        if (!value) {
          return
        }
        this.model = {
          ...value
        }
      }
    }
  }
}
</script>
