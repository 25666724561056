import axios from 'axios'

const types = {
  SET_CURRENT_EQUIPMENT: 'SET_CURRENT_EQUIPMENT',
  SET_CURRENT_EQUIPMENT_LOADING: 'SET_CURRENT_EQUIPMENT_LOADING',
}

const state = {
  currentEquipment: {
    attributes: {},
    relationships: {},
    meta: {},
  },
  equipmentLoading: false,
}

const mutations = {
  [types.SET_CURRENT_EQUIPMENT]: (state, data) => {
    state.currentEquipment = {
      ...state.currentEquipment,
      ...data,
    }
  },
  [types.SET_CURRENT_EQUIPMENT_LOADING]: (state, value) => {
    state.equipmentLoading = value
  },
}

const actions = {
  async getEquipment({commit}, id) {
    try {
      commit(types.SET_CURRENT_EQUIPMENT_LOADING, true)
      const params = {
        related: 'class[id|code],address,specialty[id|code]'
      }

      const {data} = await axios.get(`/restify/equipment/${ id }`, {
        params,
      })
      commit(types.SET_CURRENT_EQUIPMENT, data)
    } finally {
      commit(types.SET_CURRENT_EQUIPMENT_LOADING, false)
    }
  },
  async patchEquipment({}, data = {}) {
    await axios.patch(`/restify/equipment/${data.id}`, data)
  }
}

const getters = {}

export default {
  namespaced: true,
  types,
  state,
  mutations,
  actions,
  getters,
}
