<template>
  <ExportInitsButton
    url="/restify/accounts-payable-init-entries/actions?action=accounts-payable-init-entries-data-export"
    :columns="['date', 'description', 'due_date', 'gross_amount', 'discount_amount', 'number', 'vendor.code']"
    :url-params="{
      accounts_payable_init_id: id,
    }"
    class="mr-4 flex"
    v-bind="$attrs"
  />
</template>
<script>
import ExportInitsButton from "@/modules/common/components/export/ExportInitsButton.vue";

export default {
  components: {
    ExportInitsButton
  },
  props: {
    id: {
      type: String,
    }
  }
}
</script>
