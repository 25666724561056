<template>
  <BaseSelect
    v-bind="$attrs"
    v-on="$listeners"
    :options="filteredOptions"
    :url-params="urlParams"
    :dataFilters="dataFilters"
    :add-entity="addEntity"
    :edit-entity="editEntity"
    :name="$attrs.name || $t('Purchase Order')"
    :placeholder="$attrs.placeholder || $t('Select Purchase Order')"
    :label="$attrs.label !== undefined ? $attrs.label : $t('Purchase Order')"
    :add-label="$t('Add new Purchase Order')"
    :id="$attrs.id || 'po-select'"
    :table-columns="tableColumns"
    :label-format="formatLabel"
    :value-key="valueKey"
    class="w-full"
    value-key="id"
    label-key="number"
  >
    <template #label>
      <slot name="label"/>
    </template>
  </BaseSelect>
</template>
<script>
import { resourceStatuses } from "@/enum/enums";

export default {
  name: 'PurchaseOrderSelect',
  inheritAttrs: false,
  props: {
    urlParams: {
      type: Object,
      default: () => ({
        sort: 'number',
      }),
    },
    addEntity: {
      type: Boolean,
      default: false,
    },
    editEntity: {
      type: Boolean,
      default: false,
    },
    valueKey: {
      type: String,
      default: 'id',
    },
    showAmounts: {
      type: Boolean,
      default: false,
    },
    vendorId: String,
    showStatusColumn: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dataFilters: {
        showActive: true,
      }
    }
  },
  computed: {
    tableColumns() {
      const columns = [
        {
          minWidth: 100,
          maxWidth: 150,
          name: this.$t('Number'),
          prop: 'number',
        },
        {
          minWidth: 200,
          maxWidth: 200,
          name: this.$t('Description'),
          prop: 'description',
        },
        {
          minWidth: 100,
          maxWidth: 150,
          name: this.$t('Amount'),
          prop: 'extended_amount',
          toFormat: this.$formatPrice,
          showIf: () => this.showAmounts,
        },
        {
          minWidth: 140,
          maxWidth: 150,
          name: this.$t('Invoiced Amount'),
          prop: 'invoiced_amount',
          toFormat: this.$formatPrice,
          showIf: () => this.showAmounts,
        },
        {
          minWidth: 150,
          maxWidth: 150,
          name: this.$t('Status'),
          prop: 'status',
          component: 'Status',
          filter: {
            type: 'switch',
            prop: 'showActive',
            tooltip: this.$t('Only show open Purchase Orders'),
            onChange: (value) => {
              this.dataFilters.showActive = value
            },
          },
          showIf: () => this.showStatusColumn,
        },
      ]

      return columns.filter(col => !col.showIf || col.showIf())
    },
    options() {
      let options = this.$store.getters['globalLists/getResourceList'](this.$globalResources.PurchaseOrders)
      if (this.dataFilters.showActive) {
        options = options.filter(o => o.status === resourceStatuses.Open)
      }

      return options.map(purchaseOrder => ({
        ...purchaseOrder,
        isInactive: purchaseOrder.status !== resourceStatuses.Open,
      }))
    },
    filteredOptions() {
      if (!this.vendorId) {
        return this.options
      }
      return this.options.filter(purchaseOrder => purchaseOrder.vendor_id === this.vendorId)
    },
  },
  methods: {
    formatLabel(purchaseOrder) {
      const { number, description } = purchaseOrder
      if (!description) {
        return number
      }
      return `${number} (${description})`
    },
  },
}
</script>
