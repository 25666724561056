<template>
  <EntitySelect
    url="/restify/craft-codes"
    :transformData="transformData"
    :dataFilters="dataFilters"
    v-bind="$attrs"
    v-on="$listeners"
    :url-params="allUrlParams"
    :filter-options="filterOptions"
    :name="$attrs.name || $t('Craft')"
    :placeholder="$attrs.placeholder || $t('Select Craft')"
    :label="$attrs.label !== undefined ? $attrs.label : $t('Craft')"
    :add-label="$t('Add new Craft')"
    :id="$attrs.id || 'craft-select'"
    :table-columns="tableColumns"
    :label-format="formatLabel"
    :hide-label="hideLabel"
    label-key="code"
  />
</template>
<script>
export default {
  name: 'CraftSelect',
  inheritAttrs: false,
  props: {
    urlParams: {
      type: Object,
      default: () => ({
        sort: 'code',
        active: true,
      }),
    },
    excludeIds: {
      type: Array,
      default: () => [],
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    displayLevel: {
      type: Boolean,
      default: false,
    },
    showActiveColumn: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dataFilters: {
        showActive: true,
      },
    }
  },
  computed: {
    tableColumns() {
      const columns = [
        {
          minWidth: 80,
          maxWidth: 100,
          name: this.$t('Code'),
          prop: 'attributes.code',
        },
        {
          minWidth: 60,
          maxWidth: 60,
          name: 'Level',
          prop: 'attributes.level',
        },
        {
          minWidth: 200,
          maxWidth: 200,
          name: this.$t('Description'),
          prop: 'attributes.description',
        },
        {
          minWidth: 130,
          maxWidth: 130,
          name: this.$t('Active'),
          prop: 'attributes.active',
          component: 'Status',
          filter: {
            type: 'switch',
            prop: 'showActive',
            tooltip: this.$t('Only show active craft codes'),
            onChange: (value) => {
              this.dataFilters.showActive = value
            },
          },
          showIf: () => this.showActiveColumn,
        },
      ]

      return columns.filter(col => !col.showIf || col.showIf())
    },
    allUrlParams() {
      return {
        ...this.urlParams,
        active: this.dataFilters.showActive ? true : null,
      }
    },
  },
  methods: {
    transformData(data) {
      return data.map(craftCode => ({
        ...craftCode,
        isInactive: !craftCode.attributes.active,
      }))
    },
    filterOptions(options) {
      if (this.excludeIds.length) {
        return options.filter(o => {
          return !this.excludeIds.includes(o.id)
        })
      }
      return options
    },
    formatLabel(craft) {
      const { code, description, level } = craft.attributes
      if (this.displayLevel) {
        return  `${code} - ${level}`
      }
      if (!description) {
        return code
      }
      return `${code} (${description})`
    },
  },
}
</script>
