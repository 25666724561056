import { resourceStatuses } from "@/enum/enums";
import JobBillingsLayout from "@/modules/accounts-receivable/layout/JobBillingsLayout";
import BillingsAllList from "@/modules/accounts-receivable/pages/billings/billings-all-list";
import ProgressBillingsLayout from "@/modules/accounts-receivable/layout/billings/ProgressBillingsLayout";
import BillingsList from "@/modules/accounts-receivable/pages/billings/billings-list";
import ProgressBillingAdd from "@/modules/accounts-receivable/pages/billings/forms/progress/progress-billing-add";
import BillingLayout from "@/modules/accounts-receivable/layout/billings/BillingLayout";
import ProgressBillingEdit from "@/modules/accounts-receivable/pages/billings/forms/progress/progress-billing-edit";
import ProgressBillingDetails
  from "@/modules/accounts-receivable/pages/billings/forms/progress/progress-billing-details";
import UnitPriceBillingsLayout from "@/modules/accounts-receivable/layout/billings/UnitPriceBillingsLayout";
import UnitPriceBillingAdd from "@/modules/accounts-receivable/pages/billings/forms/unit-price/unit-price-billing-add";
import UnitPriceBillingEdit
  from "@/modules/accounts-receivable/pages/billings/forms/unit-price/unit-price-billing-edit";
import UnitPriceBillingDetails
  from "@/modules/accounts-receivable/pages/billings/forms/unit-price/unit-price-billing-details";
import CostPlusBillingsLayout from "@/modules/accounts-receivable/layout/billings/CostPlusBillingsLayout";
import CostPlusBillingAdd from "@/modules/accounts-receivable/pages/billings/forms/cost-plus/cost-plus-billing-add";
import SelectCosts from "@/modules/accounts-receivable/pages/billings/forms/cost-plus/select-costs/form";
import CostPlusBillingEdit from "@/modules/accounts-receivable/pages/billings/forms/cost-plus/cost-plus-billing-edit";
import CostPlusBillingDetails
  from "@/modules/accounts-receivable/pages/billings/forms/cost-plus/cost-plus-billing-details";
import LumpSumBillingsLayout from "@/modules/accounts-receivable/layout/billings/LumpSumBillingsLayout";
import LumpSumBillingAdd from "@/modules/accounts-receivable/pages/billings/forms/lump-sum/lump-sum-billing-add";
import LumpSumBillingEdit from "@/modules/accounts-receivable/pages/billings/forms/lump-sum/lump-sum-billing-edit";
import LumpSumBillingProofListing
  from "@/modules/accounts-receivable/pages/billings/forms/lump-sum/lump-sum-billing-proof-listing.vue";
import ProgressBillingProofListing
  from "@/modules/accounts-receivable/pages/billings/forms/progress/progress-billing-proof-listing.vue";
import LumpSumBillingDetails
  from "@/modules/accounts-receivable/pages/billings/forms/lump-sum/lump-sum-billing-details";
import ServiceBillingsLayout from "@/modules/accounts-receivable/layout/billings/ServiceBillingsLayout.vue";
import ServiceBillingAdd from "@/modules/accounts-receivable/pages/billings/forms/service/service-billing-add";
import ServiceBillingEdit from "@/modules/accounts-receivable/pages/billings/forms/service/service-billing-edit";
import ServiceBillingDetails from "@/modules/accounts-receivable/pages/billings/forms/service/service-billing-details";
import UnitPriceBillingProofListing
  from "@/modules/accounts-receivable/pages/billings/forms/unit-price/unit-price-billing-proof-listing.vue";
import CostPlusBillingProofListing
  from "@/modules/accounts-receivable/pages/billings/forms/cost-plus/cost-plus-billing-proof-listing.vue";
import ServiceInvoicesList from "@/modules/service-billing/pages/service-invoices/service-invoices-list.vue";

const routes = [
  {
    path: 'billings',
    name: 'Billings',
    title: 'Billings',
    redirect: '/accounts-receivable/billings/all-billings',
    meta: {
      permissions: ['billings_show'],
    },
    component: JobBillingsLayout,
    children: [
      {
        path: 'all-billings',
        name: 'All Billings',
        title: 'All Billings',
        meta: {
          permissions: ['billings_show'],
        },
        component: BillingsAllList,
      },
      {
        path: 'progress',
        name: 'Job Progress Billings',
        title: 'Job Progress Billings List',
        description: 'Manage Job Progress Billings',
        redirect: '/accounts-receivable/billings/progress/open',
        meta: {
          hideBreadCrumb: true,
          permissions: ['billings_show'],
          appScreen: 'Job Progress Billings: 50.30',
        },
        component: ProgressBillingsLayout,
        children: [
          {
            path: 'pending',
            name: 'Pending Progress Billings',
            title: 'Pending Progress Billings',
            description: 'Manage Job Progress Billings',
            meta: {
              hideBreadCrumb: true,
              permissions: ['billings_show'],
              appScreen: 'Job Progress Billings: 50.30',
              status: resourceStatuses.Pending,
            },
            component: BillingsList,
          },
          {
            path: 'paid',
            name: 'Paid Progress Billings',
            title: 'Paid Progress Billings',
            description: 'Manage Paid Job Progress Billings',
            meta: {
              hideBreadCrumb: true,
              permissions: ['billings_show'],
              appScreen: 'Job Progress Billings: 50.30',
              status: resourceStatuses.Paid,
            },
            component: BillingsList,
          },
          {
            path: 'partial-paid',
            name: 'Partially Paid Progress Billings',
            title: 'Partially Paid Progress Billings',
            description: 'Manage Partial Paid Job Progress Billings',
            meta: {
              hideBreadCrumb: true,
              permissions: ['billings_show'],
              appScreen: 'Job Progress Billings: 50.30',
              status: resourceStatuses.PartialPaid,
            },
            component: BillingsList,
          },
          {
            path: 'voided',
            name: 'Voided Progress Billings',
            title: 'Voided Progress Billings',
            description: 'Manage Voided Job Progress Billings',
            meta: {
              hideBreadCrumb: true,
              permissions: ['billings_show'],
              appScreen: 'Job Progress Billings: 50.30',
              status: resourceStatuses.Voided,
            },
            component: BillingsList,
          },
          {
            path: 'open',
            name: 'Open Progress Billings',
            title: 'Open Progress Billings',
            description: 'Manage Open Job Progress Billings',
            meta: {
              hideBreadCrumb: true,
              permissions: ['billings_show'],
              appScreen: 'Job Progress Billings: 50.30',
              status: resourceStatuses.Posted,
            },
            component: BillingsList,
          },
          {
            path: 'all',
            name: 'All Progress Billings',
            title: 'All Progress Billings',
            description: 'Manage All Job Progress Billings',
            meta: {
              hideBreadCrumb: true,
              permissions: ['billings_show'],
              appScreen: 'Job Progress Billings: 50.30',
              status: resourceStatuses.All,
            },
            component: BillingsList,
          },
          {
            path: 'proof-listing',
            name: 'Progress Billings Proof Listing',
            meta: {
              permissions: ['billings_store'],
            },
            component: ProgressBillingProofListing,
          },
          {
            path: 'add',
            name: 'Add Progress Billings',
            meta: {
              permissions: ['billings_show'],
              appScreen: 'Job Progress Billings: 50.30',
            },
            component: ProgressBillingAdd,
          },
          {
            path: ':id',
            meta: {
              hideBreadCrumb: true,
              permissions: ['billings_show'],
            },
            component: BillingLayout,
            children: [
              {
                path: 'edit',
                name: 'Edit Progress Billing',
                meta: {
                  permissions: ['billings_show'],
                  appScreen: 'Job Progress Billings: 50.30',
                  resourceName: 'billings',
                },
                component: ProgressBillingEdit,
              },
              {
                path: 'view',
                name: 'View Progress Billing',
                meta: {
                  permissions: ['billings_show'],
                  appScreen: 'Job Progress Billings: 50.30',
                  resourceName: 'billings',
                },
                component: ProgressBillingDetails,
              },
            ],
          },
        ]
      },
      {
        path: 'unit-price',
        name: 'Job Unit Price Billings',
        title: 'Job Unit Price Billings List',
        description: 'Manage Job Unit Price Billings',
        redirect: '/accounts-receivable/billings/unit-price/open',
        meta: {
          hideBreadCrumb: true,
          permissions: ['billings_show'],
          appScreen: 'Job Unit Price Billings: 50.20',
        },
        component: UnitPriceBillingsLayout,
        children: [
          {
            path: 'pending',
            name: 'Pending Unit Price Billings',
            title: 'Pending Unit Price Billings',
            description: 'Manage Job Unit Price Billings',
            meta: {
              hideBreadCrumb: true,
              permissions: ['billings_show'],
              appScreen: 'Job Unit Price Billings: 50.20',
              status: resourceStatuses.Pending,
            },
            component: BillingsList,
          },
          {
            path: 'paid',
            name: 'Paid Unit Price Billings',
            title: 'Paid Unit Price Billings',
            description: 'Manage Paid Job Unit Price Billings',
            meta: {
              hideBreadCrumb: true,
              permissions: ['billings_show'],
              appScreen: 'Job Unit Price Billings: 50.20',
              status: resourceStatuses.Paid,
            },
            component: BillingsList,
          },
          {
            path: 'partial-paid',
            name: 'Partially Paid Unit Price Billings',
            title: 'Partially Paid Unit Price Billings',
            description: 'Manage Partial Paid Job Unit Price Billings',
            meta: {
              hideBreadCrumb: true,
              permissions: ['billings_show'],
              appScreen: 'Job Unit Price Billings: 50.20',
              status: resourceStatuses.PartialPaid,
            },
            component: BillingsList,
          },
          {
            path: 'voided',
            name: 'Voided Unit Price Billings',
            title: 'Voided Unit Price Billings',
            description: 'Manage Voided Job Unit Price Billings',
            meta: {
              hideBreadCrumb: true,
              permissions: ['billings_show'],
              appScreen: 'Job Unit Price Billings: 50.20',
              status: resourceStatuses.Voided,
            },
            component: BillingsList,
          },
          {
            path: 'open',
            name: 'Open Unit Price Billings',
            title: 'Open Unit Price Billings',
            description: 'Manage Open Job Unit Price Billings',
            meta: {
              hideBreadCrumb: true,
              permissions: ['billings_show'],
              appScreen: 'Job Unit Price Billings: 50.20',
              status: resourceStatuses.Posted,
            },
            component: BillingsList,
          },
          {
            path: 'all',
            name: 'All Unit Price Billings',
            title: 'All Unit Price Billings',
            description: 'Manage All Job Unit Price Billings',
            meta: {
              hideBreadCrumb: true,
              permissions: ['billings_show'],
              appScreen: 'Job Unit Price Billings: 50.20',
              status: resourceStatuses.All,
            },
            component: BillingsList,
          },
          {
            path: 'add',
            name: 'Add Unit Price Billings',
            meta: {
              permissions: ['billings_show'],
              appScreen: 'Job Unit Price Billings: 50.20',
            },
            component: UnitPriceBillingAdd,
          },
          {
            path: 'proof-listing',
            name: 'Unit Price Billings Proof Listing',
            meta: {
              permissions: ['billings_store'],
            },
            component: UnitPriceBillingProofListing,
          },
          {
            path: ':id',
            meta: {
              permissions: ['billings_show'],
            },
            component: BillingLayout,
            children: [
              {
                path: 'edit',
                name: 'Edit Unit Price Billing',
                meta: {
                  permissions: ['billings_show'],
                  appScreen: 'Job Unit Price Billings: 50.20',
                  resourceName: 'billings',
                },
                component: UnitPriceBillingEdit,
              },
              {
                path: 'view',
                name: 'View Unit Price Billing',
                meta: {
                  permissions: ['billings_show'],
                  appScreen: 'Job Unit Price Billings: 50.20',
                  resourceName: 'billings',
                },
                component: UnitPriceBillingDetails,
              },
            ],
          },
        ],
      },
      {
        path: 'cost-plus',
        name: 'Job Cost Plus Billings',
        title: 'Job Cost Plus Billings List',
        description: 'Manage Job Cost Plus Billings',
        redirect: '/accounts-receivable/billings/cost-plus/open',
        meta: {
          hideBreadCrumb: true,
          permissions: ['billings_show'],
          appScreen: 'Job Cost Plus Billings: 50.15',
        },
        component: CostPlusBillingsLayout,
        children: [
          {
            path: 'pending',
            name: 'Pending Cost Plus Billings',
            title: 'Pending Cost Plus Billings',
            description: 'Manage Job Cost Plus Billings',
            meta: {
              permissions: ['billings_show'],
              appScreen: 'Job Cost Plus Billings: 50.15',
              status: resourceStatuses.Pending,
            },
            component: BillingsList,
          },
          {
            path: 'paid',
            name: 'Paid Cost Plus Billings',
            title: 'Paid Cost Plus Billings',
            description: 'Manage Paid Job Cost Plus Billings',
            meta: {
              permissions: ['billings_show'],
              appScreen: 'Job Cost Plus Billings: 50.20',
              status: resourceStatuses.Paid,
            },
            component: BillingsList,
          },
          {
            path: 'partial-paid',
            name: 'Partial Paid Cost Plus Billings',
            title: 'Partial Paid Cost Plus Billings',
            description: 'Manage Partial Paid Job Cost Plus Billings',
            meta: {
              permissions: ['billings_show'],
              appScreen: 'Job Cost Plus Billings: 50.20',
              status: resourceStatuses.PartialPaid,
            },
            component: BillingsList,
          },
          {
            path: 'voided',
            name: 'Voided Cost Plus Billings',
            title: 'Voided Cost Plus Billings',
            description: 'Manage Voided Job Cost Plus Billings',
            meta: {
              permissions: ['billings_show'],
              appScreen: 'Job Cost Plus Billings: 50.20',
              status: resourceStatuses.Voided,
            },
            component: BillingsList,
          },
          {
            path: 'open',
            name: 'Open Cost Plus Billings',
            title: 'Open Cost Plus Billings',
            description: 'Manage Open Job Cost Plus Billings',
            meta: {
              permissions: ['billings_show'],
              appScreen: 'Job Cost Plus Billings: 50.20',
              status: resourceStatuses.Posted,
            },
            component: BillingsList,
          },
          {
            path: 'all',
            name: 'All Cost Plus Billings',
            title: 'All Cost Plus Billings',
            description: 'Manage All Job Cost Plus Billings',
            meta: {
              permissions: ['billings_show'],
              appScreen: 'Job Cost Plus Billings: 50.20',
              status: resourceStatuses.All,
            },
            component: BillingsList,
          },
          {
            path: 'add',
            name: 'Add Cost Plus Billings',
            meta: {
              permissions: ['billings_show'],
            },
            component: CostPlusBillingAdd,
          },
          {
            path: 'proof-listing',
            name: 'Cost Plus Billings Proof Listing',
            meta: {
              permissions: ['billings_update'],
            },
            component: CostPlusBillingProofListing,
          },
          {
            path: 'select-costs',
            name: 'Select Costs For Billings',
            title: 'Select Costs For Billings',
            description: 'Select costs for billing based on job transactions.',
            meta: {
              permissions: ['billings_show'],
              appScreen: 'Job Cost Plus Billings: 50.15',
            },
            component: SelectCosts,
          },
          {
            path: ':id',
            meta: {
              permissions: ['billings_show'],
            },
            component: BillingLayout,
            children: [
              {
                path: 'edit',
                name: 'Cost Plus Billing Edit',
                meta: {
                  permissions: ['billings_show'],
                  appScreen: 'Job Cost Plus Billings: 50.15',
                  resourceName: 'billings',
                },
                component: CostPlusBillingEdit,
              },
              {
                path: 'view',
                name: 'Cost Plus Billing Details',
                meta: {
                  permissions: ['billings_show'],
                  appScreen: 'Job Cost Plus Billings: 50.15',
                  resourceName: 'billings',
                },
                component: CostPlusBillingDetails,
              },
            ],
          },
        ]
      },
      {
        path: 'lump-sum',
        name: 'Lump Sum Billings',
        title: 'Lump Sum Billings List',
        description: 'Manage Lump Sum Billings',
        redirect: '/accounts-receivable/billings/lump-sum/open',
        meta: {
          hideBreadCrumb: true,
          permissions: ['billings_show'],
          appScreen: 'Lump Sum Billings: 50.40',
        },
        component: LumpSumBillingsLayout,
        children: [
          {
            path: 'pending',
            name: 'Pending Lump Sum Billings',
            title: 'Pending Lump Sum Billings',
            description: 'Manage Lump Sum Billings',
            meta: {
              hideBreadCrumb: true,
              permissions: ['billings_show'],
              appScreen: 'Lump Sum Billings: 50.40',
              status: resourceStatuses.Pending,
            },
            component: BillingsList,
          },
          {
            path: 'paid',
            name: 'Paid Lump Sum Billings',
            title: 'Paid Lump Sum Billings',
            description: 'Manage Paid Lump Sum Billings',
            meta: {
              hideBreadCrumb: true,
              permissions: ['billings_show'],
              appScreen: 'Lump Sum Billings: 50.40',
              status: resourceStatuses.Paid,
            },
            component: BillingsList,
          },
          {
            path: 'partial-paid',
            name: 'Partially Paid Lump Sum Billings',
            title: 'Partially Paid Lump Sum Billings',
            description: 'Manage Partial Paid Lump Sum Billings',
            meta: {
              hideBreadCrumb: true,
              permissions: ['billings_show'],
              appScreen: 'Lump Sum Billings: 50.40',
              status: resourceStatuses.PartialPaid,
            },
            component: BillingsList,
          },
          {
            path: 'voided',
            name: 'Voided Lump Sum Billings',
            title: 'Voided Lump Sum Billings',
            description: 'Manage Voided Lump Sum Billings',
            meta: {
              hideBreadCrumb: true,
              permissions: ['billings_show'],
              appScreen: 'Lump Sum Billings: 50.40',
              status: resourceStatuses.Voided,
            },
            component: BillingsList,
          },
          {
            path: 'open',
            name: 'Open Lump Sum Billings',
            title: 'Open Lump Sum Billings',
            description: 'Manage Open Lump Sum Billings',
            meta: {
              hideBreadCrumb: true,
              permissions: ['billings_show'],
              appScreen: 'Lump Sum Billings: 50.40',
              status: resourceStatuses.Posted,
            },
            component: BillingsList,
          },
          {
            path: 'all',
            name: 'All Lump Sum Billings',
            title: 'All Lump Sum Billings',
            description: 'Manage All Lump Sum Billings',
            meta: {
              hideBreadCrumb: true,
              permissions: ['billings_show'],
              appScreen: 'Lump Sum Billings: 50.40',
              status: resourceStatuses.All,
            },
            component: BillingsList,
          },
          {
            path: 'proof-listing',
            name: 'Lump Sum Billings Proof Listing',
            meta: {
              permissions: ['billings_store'],
            },
            component: LumpSumBillingProofListing,
          },
          {
            path: 'add',
            name: 'Add Lump Sum Billings',
            meta: {
              permissions: ['billings_show'],
              appScreen: 'Lump Sum Billings: 50.40',
            },
            component: LumpSumBillingAdd,
          },
          {
            path: ':id',
            meta: {
              hideBreadCrumb: true,
              permissions: ['billings_show'],
            },
            component: BillingLayout,
            children: [
              {
                path: 'edit',
                name: 'Edit Lump Sum Billing',
                meta: {
                  permissions: ['billings_show'],
                  appScreen: 'Lump Sum Billings: 50.30',
                  resourceName: 'billings',
                },
                component: LumpSumBillingEdit,
              },
              {
                path: 'view',
                name: 'View Lump Sum Billing',
                meta: {
                  permissions: ['billings_show'],
                  appScreen: 'Lump Sum Billings: 50.30',
                  resourceName: 'billings',
                },
                component: LumpSumBillingDetails,
              },
            ],
          },
        ]
      },
      {
        path: 'service',
        name: 'Service Billings',
        title: 'Service Billings List',
        description: 'Manage Service Billings',
        redirect: '/accounts-receivable/billings/service/open',
        meta: {
          hideBreadCrumb: true,
          permissions: ['billings_show'],
          appScreen: 'Service Billings: 55.50',
        },
        component: ServiceBillingsLayout,
        children: [
          {
            path: 'pending',
            name: 'Pending Service Billings',
            title: 'Pending Service Billings',
            description: 'Manage Service Billings',
            meta: {
              hideBreadCrumb: true,
              permissions: ['billings_show'],
              appScreen: 'Service Billings: 55.50',
              status: resourceStatuses.Pending,
            },
            component: ServiceInvoicesList,
          },
          {
            path: 'paid',
            name: 'Paid Service Billings',
            title: 'Paid Service Billings',
            description: 'Manage Paid Service Billings',
            meta: {
              hideBreadCrumb: true,
              permissions: ['billings_show'],
              appScreen: 'Service Billings: 55.50',
              status: resourceStatuses.Paid,
            },
            component: ServiceInvoicesList,
          },
          {
            path: 'partial-paid',
            name: 'Partially Paid Service Billings',
            title: 'Partially Paid Service Billings',
            description: 'Manage Partial Paid Service Billings',
            meta: {
              hideBreadCrumb: true,
              permissions: ['billings_show'],
              appScreen: 'Service Billings: 55.50',
              status: resourceStatuses.PartialPaid,
            },
            component: ServiceInvoicesList,
          },
          {
            path: 'voided',
            name: 'Voided Service Billings',
            title: 'Voided Service Billings',
            description: 'Manage Voided Service Billings',
            meta: {
              hideBreadCrumb: true,
              permissions: ['billings_show'],
              appScreen: 'Service Billings: 55.50',
              status: resourceStatuses.Voided,
            },
            component: ServiceInvoicesList,
          },
          {
            path: 'open',
            name: 'Open Service Billings',
            title: 'Open Service Billings',
            description: 'Manage Open Service Billings',
            meta: {
              hideBreadCrumb: true,
              permissions: ['billings_show'],
              appScreen: 'Service Billings: 55.50',
              status: resourceStatuses.Posted,
            },
            component: ServiceInvoicesList,
          },
          {
            path: 'all',
            name: 'All Service Billings',
            title: 'All Service Billings',
            description: 'Manage All Service Billings',
            meta: {
              hideBreadCrumb: true,
              permissions: ['billings_show'],
              appScreen: 'Service Billings: 55.50',
              status: resourceStatuses.All,
            },
            component: ServiceInvoicesList,
          },
          {
            path: 'add',
            name: 'Add Service Billings',
            meta: {
              permissions: ['billings_show'],
              appScreen: 'Service Billings: 55.50',
            },
            component: ServiceBillingAdd,
          },
          {
            path: ':id',
            meta: {
              hideBreadCrumb: true,
              permissions: ['billings_show'],
            },
            component: BillingLayout,
            children: [
              {
                path: 'edit',
                name: 'Edit Service Billing',
                meta: {
                  permissions: ['billings_update'],
                  appScreen: 'Service Billings: 55.50',
                  resourceName: 'billings',
                },
                component: ServiceBillingEdit,
              },
              {
                path: 'view',
                name: 'View Service Billing',
                meta: {
                  permissions: ['billings_show'],
                  appScreen: 'Service Billings: 55.50',
                  resourceName: 'billings',
                },
                component: ServiceBillingDetails,
              },
            ],
          },
        ]
      },
    ]
  }
]

export default routes
