<template>
  <div>
    <TimeOffForm :time-off-policy="currentTimeOffPolicy" />
  </div>
</template>
<script>
  import TimeOffForm from "@/modules/payroll/components/time-off/TimeOffForm";

  export default {
    components: {
      TimeOffForm
    },
    computed: {
      currentTimeOffPolicy() {
        return this.$store.state.payroll.currentTimeOffPolicy
      }
    }
  }
</script>
