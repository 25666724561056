<template>
  <UnitPriceBillingForm
      :key="renderKey"
      @create-another="renderKey++"
  />
</template>
<script>
  import UnitPriceBillingForm from '@/modules/accounts-receivable/components/unit-price-billings/UnitPriceBillingForm'

  export default {
    components: {
      UnitPriceBillingForm,
    },
    data() {
      return {
        renderKey: 1,
      }
    },
  }
</script>
