<template>
    <CustomerForm
        :key="renderKey"
        @create-another="renderKey++"
    />
</template>
<script>
  import CustomerForm from '@/modules/accounts-receivable/components/CustomerForm'

  export default {
    components: {
      CustomerForm,
    },
    data() {
      return {
        renderKey: 1,
      }
    },
  }
</script>
