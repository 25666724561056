<template>
  <div class="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 mt-6 gap-4">
    <NavigationCard
        v-for="card in filteredCards"
        :data="card"
        :key="card.path"
    />
  </div>
</template>
<script>
  import get from "lodash/get";
  import NavigationCard from "@/modules/dashboard/components/NavigationCard";
  import { getNestedRoutes } from "@/modules/dashboard/util/routeUtils";

  export default {
    name: "NavigationCardList",
    components: {
      NavigationCard,
    },
    props: {
      data: {
        type: Array,
        default: () => [],
      }
    },
    computed: {
      allRoutes() {
        let result = []
        if (this.$router.options.routes) {
          this.$router.options.routes.forEach(route => {
            result.push(...getNestedRoutes(route))
          })
        }
        return result
      },
      filteredCards() {
        return this.data.filter(item => {
          const route = this.findRoute(item.routeName)
          const permissions = get(route, 'meta.permissions', [])
          return this.$can(permissions);
        })
      }
    },
    methods: {
      findRoute(name) {
        return this.allRoutes.find(r => r.name === name)
      }
    }
  }
</script>
<style>
</style>
