<template>
  <div>
    <DetailsPageLayout :loading="workOrderLoading">
      <template #left>
        <WorkOrderMainCard :work-order="workOrder"/>
        <WorkOrderAmountsCard :work-order="workOrder"/>
        <WorkSiteMainDetailsCard
            :worksite="workOrder?.relationships?.workSite"
            :show-worksite-name="true"
        />
      </template>
      <template #right>
        <base-detail-card
            :title="$t('Work Order Details')"
            :key="workOrder.id"
            :entity="workOrder"
            :can-expand="false"
            :expanded="true"
            entity-name="workOrder"
        >
          <base-detail-card-row :title="$t('Work Order')">
            {{ workOrder.attributes.number }} ({{ workOrder.attributes.description }})
          </base-detail-card-row>
          <base-detail-card-row :title="$t('Worksite Notes')">
            <div>
              <div
                  v-for="(note, index) in workOrder?.relationships?.workSite?.attributes?.user_notes"
                  :key="index"
              >
                {{ note }}
              </div>
            </div>
          </base-detail-card-row>
          <base-detail-card-row :title="$t('Date')">
            {{ workOrder.attributes?.date ? $formatDate(new Date(workOrder.attributes.date), 'datetime') : '' }}
          </base-detail-card-row>
          <base-detail-card-row :title="$t('Call Placed By')">
            <span>{{ get(workOrder, 'attributes.call_taken_by', '') }}</span>
          </base-detail-card-row>
          <base-detail-card-row :title="$t('Call Taken By')">
            <span>{{ get(workOrder, 'attributes.call_taken_by', '') }}</span>
          </base-detail-card-row>
          <base-detail-card-row :title="$t('Billing Type')" stripe>
            <span>{{ get(workOrder, 'relationships.billingRateType.attributes.title', '') }}</span>
          </base-detail-card-row>
          <base-detail-card-row :title="$t('Purchase Order')" stripe>
            <span>{{ get(workOrder, 'attributes.customer_purchase_order', '') }}</span>
          </base-detail-card-row>
        </base-detail-card>

        <base-detail-card :title="$t('Dates')" class="mt-4">
          <base-detail-card-row
              v-for="(date, index) in dates"
              :key="date.title"
              :title="date.title"
              :stripe="!!(index % 2)"
          >
            {{ date.value ? $formatDate(new Date(date.value), 'datetime') : '' }}
          </base-detail-card-row>
        </base-detail-card>

        <base-detail-card :title="$t('Timecard Overrides')" class="mt-4">
          <base-detail-card-row :title="$t('Withholding State')">
          <span>
            {{ get(workOrder, 'relationships.withholdingState.attributes.code', '') }}
            ({{ get(workOrder, 'relationships.withholdingState.attributes.description', '') }})
          </span>
          </base-detail-card-row>
          <base-detail-card-row :title="$t('Withholding Local 1')" stripe>
          <span>
            {{ get(workOrder, 'relationships.withholdingLocal.attributes.code', '') }}
            ({{ get(workOrder, 'relationships.withholdingLocal.attributes.description', '') }})
          </span>
          </base-detail-card-row>
          <base-detail-card-row :title="$t('Withholding Local 2')">
          <span>
            {{ get(workOrder, 'relationships.withholdingLocal2.attributes.code', '') }}
            ({{ get(workOrder, 'relationships.withholdingLocal2.attributes.description', '') }})
          </span>
          </base-detail-card-row>
          <base-detail-card-row :title="$t('SUI/SDI State')" stripe>
          <span>
            {{ get(workOrder, 'relationships.suiSdiState.attributes.code', '') }}
            ({{ get(workOrder, 'relationships.suiSdiState.attributes.description', '') }})
          </span>
          </base-detail-card-row>
        </base-detail-card>

        <base-detail-card class="mt-4" :title="$t('Additional Information')">
          <base-detail-card-row :title="$t('Compliance Format')">
            <span class="capitalize">{{ get(workOrder, 'attributes.compliance_format', '') }}</span>
          </base-detail-card-row>
          <base-detail-card-row :title="$t('Worker\'s Comp Rate')" stripe>
          <span v-if="workOrder.attributes.workers_comp_rate_code">
            {{ get(workOrder, 'relationships.workersCompRate.attributes.code', '') }}
            ({{ get(workOrder, 'relationships.workersCompRate.attributes.description', '') }})
          </span>
            <span v-else>

          </span>
          </base-detail-card-row>
          <base-detail-card-row :title="$t('Gen Liability Rate')">
          <span v-if="workOrder.attributes.gen_liability_rate_code">
            {{ get(workOrder, 'relationships.genLiabilityRate.attributes.code', '') }}
            ({{ get(workOrder, 'relationships.genLiabilityRate.attributes.description', '') }})
          </span>
            <span v-else>

          </span>
          </base-detail-card-row>
          <base-detail-card-row :title="$t('Union')" stripe>
          <span v-if="workOrder.attributes.union_id">
            {{ get(workOrder, 'relationships.union.attributes.code', '') }}
            ({{ get(workOrder, 'relationships.union.attributes.description', '') }})
          </span>
            <span v-else>

          </span>
          </base-detail-card-row>
          <base-detail-card-row :title="$t('Work Order Assigned To')">
            <employee-link
                v-if="workOrder.attributes.assigned_employee_id"
                :data="workOrder.relationships.assignedEmployee"
            />
            <span v-else>

          </span>
          </base-detail-card-row>
          <base-detail-card-row :title="$t('Commissioned Salesperson')" stripe>
            <employee-link
                v-if="workOrder.attributes.salesperson_employee_id"
                :data="workOrder.relationships.salespersonEmployee"
            />
            <span v-else>

          </span>
          </base-detail-card-row>
          <base-detail-card-row :title="$t('Quoted Minimum Cost')">
            {{ $formatPrice(workOrder.attributes.minimum_cost_quoted_amount) }}
          </base-detail-card-row>
          <base-detail-card-row :title="$t('Quoted Estimated Cost')" stripe>
            {{ $formatPrice(workOrder.attributes.estimated_cost_quoted_amount) }}
          </base-detail-card-row>
        </base-detail-card>

        <base-back-link to="/service-billing/work-orders"/>
      </template>
    </DetailsPageLayout>
  </div>
</template>
<script>
  import { formattedText } from '@/utils/utils'
  import DetailsPageLayout from "@/modules/common/components/DetailsPageLayout.vue";
  import WorkOrderMainCard from "@/modules/service-billing/components/workorder/WorkOrderMainCard.vue";
  import WorkSiteMainCard from "@/modules/service-billing/components/worksite/WorkSiteMainCard.vue";
  import WorkSiteMainDetailsCard from "@/modules/service-billing/components/worksite/WorkSiteMainDetailsCard.vue";
  import WorkOrderAmountsCard from "@/modules/service-billing/components/workorder/WorkOrderAmountsCard.vue";

  export default {
    components: { WorkOrderAmountsCard, WorkSiteMainDetailsCard, WorkSiteMainCard, WorkOrderMainCard, DetailsPageLayout },
    computed: {
      workOrder() {
        return this.$store.state.serviceBilling.currentWorkOrder
      },
      workOrderLoading() {
        return this.$store.state.serviceBilling.workOrderLoading
      },
      dates() {
        if (!this.workOrder?.id) {
          return []
        }

        let { timetables } = this.workOrder?.attributes || []

        if (typeof timetables === 'object') {
          let result = []

          Object.keys(timetables).forEach(key => {
            const date = {
              title: formattedText(key),
              value: timetables[key]
            }

            result.push(date)
          })

          return  result
        } else {
          return timetables.map(date => {
            return {
              ...date,
              title: formattedText(date.key),
            }
          })
        }
      },
    },
  }
</script>
