<template>
  <base-data-table
    v-bind="defaultAttributes"
    :filters="filters"
    :columns="columns"
    :data="getData"
    :total-rows="rowsLength"
    @force-pagination="forcePagination"
    class="service-billing-history-report"
  >

    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template #work_order="{row}">
      <WorkOrderLink
        :data="row.work_order"
        :show-description="false"
        :show-preview="false"
      />
    </template>
    <template #customer="{row}">
      <CustomerLink
        :data="row.customer"
        :show-description="false"
        :show-preview="false"
      />
    </template>

    <template #assigned="{row}">
      <EmployeeLink
        :data="row.assigned"
        :show-description="false"
        :show-preview="false"
      />
    </template>
    <template #salesperson="{row}">
      <EmployeeLink
        :data="row.salesperson"
        :show-description="false"
        :show-preview="false"
      />
    </template>

    <template #html-row="{htmlData}">
      <template>
        <tr class="content-row">
          <td>
            <div class="h-6">
              {{ $t('Name: ') }}
            </div>
            <div class="h-6"></div>
            <div class="h-6"></div>
            <div class="h-6">
              {{$t('Cost:')}}
            </div>
            <div class="h-6">
              {{$t('Bill:')}}
            </div>
          </td>
          <td colspan="4">
            <div :title="htmlData.worksite?.name" class="truncate max-w-[200px] h-6">
              {{ htmlData.worksite?.name }}
            </div>
            <div class="h-6">
              {{ $t('Address:') }}
              <span :title="htmlData.worksite?.street_number" class="truncate max-w-[150px]">
                {{ htmlData.worksite?.street_number }}
              </span>
            </div>
            <div class="h-6">
              <span>{{ $t('Last Invoice #:') }}</span>
              <span>{{ htmlData.work_order?.last_invoice_number }}</span>
            </div>
            <div class="flex space-x-4 h-6">
              <div v-for="(item, key) in htmlData.service_billing_types.cost"
                    :key="key">
                <span class="mr-4">{{ item?.service_billing_type?.abbr }}</span>
                <span>{{ $formatPrice(item?.service_billing_type?.total) }}</span>
              </div>
            </div>
            <div class="flex space-x-4 h-6">
              <div v-for="(item, key) in htmlData.service_billing_types.income"
                   :key="key">
                <span class="mr-4">{{ item?.service_billing_type?.abbr }}</span>
                <span>{{ $formatPrice(item?.service_billing_type?.total) }}</span>
              </div>
            </div>
          </td>
          <td>
            <div class="h-6">
              {{ $t('Direction: ') }}
            </div>
            <div class="h-6"></div>
            <div class="h-6">
              {{ $t('Date:') }}
            </div>
            <div class="h-6"></div>
            <div class="h-6"></div>
          </td>
          <td colspan="4">
            <div :title="htmlData.worksite?.cross_streets" class="truncate max-w-[300px] h-6">
              {{ htmlData.worksite?.cross_streets }}
            </div>
            <div class="truncate max-w-[300px] h-6">
              {{ htmlData.worksite?.street_direction_code }}
              {{ htmlData.worksite?.street_name }}
              {{ htmlData.worksite?.unit_number }}
            </div>
            <div class="truncate max-w-[350px] h-6">
              <span>
                {{ $formatDate(htmlData.work_order?.last_invoice_date) }}
              </span>
              <span :title="htmlData.work_order?.description">
                {{ htmlData.work_order?.description }}
              </span>
            </div>
            <div class="h-6"></div>
            <div class="h-6"></div>
          </td>
          <td class="flex flex-col">
            <div class="h-6">
              {{ $t('SB Category: ') }}
            </div>
            <div class="h-6">
              {{ htmlData.worksite?.city }}
            </div>
            <div class="h-6"></div>
            <div class="h-6"></div>
            <div class="h-6"></div>
          </td>
          <td colspan="4">
            <div class="h-6">
              {{ htmlData.category?.name }}
            </div>
            <div class="h-6">
              {{ htmlData.worksite?.state }}
              {{ htmlData.worksite?.postal_code }}
            </div>
            <div class="h-6"></div>
            <div class="h-6"></div>
            <div class="h-6"></div>
          </td>
        </tr>
      </template>
    </template>
    <template #footer-row>
      <td colspan="11">
        <ServiceBillingTypesTotalsFooter :grand-totals="grandTotals"/>
      </td>
    </template>
  </base-data-table>
</template>
<script>
import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'
import ServiceBillingTypesTotalsFooter
  from "@/modules/service-billing/components/reports/ServiceBillingTypesTotalsFooter.vue";

export default {
  components: { ServiceBillingTypesTotalsFooter },
  extends: ReportTableWrapper,
  props: {
    format: {
      required: true,
      type: String,
      default: '',
    },
    filters: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {}
  },
  computed: {
    columns() {
      return [
        {
          label: this.$t('Work <br> Order'),
          prop: 'work_order',
          minWidth: 60,
          maxWidth: 80,
        },
        {
          label: this.$t('Customer'),
          prop: 'customer',
          minWidth: 50,
          maxWidth: 80,
        },
        {
          label: this.$t('Status'),
          prop: 'work_order.status',
          minWidth: 60,
          maxWidth: 100,
          component: 'Status',
        },
        {
          label: this.$t('Site <br> No.'),
          prop: 'worksite.number',
          minWidth: 50,
          maxWidth: 80,
        },
        {
          label: this.$t('W.O. <br> Date'),
          prop: 'work_order.date',
          minWidth: 100,
          maxWidth: 100,
          component: 'FormattedDate',
        },
        {
          label: this.$t('Promised <br> Date'),
          prop: 'work_order.timetables.promised',
          minWidth: 100,
          maxWidth: 100,
          component: 'FormattedDate',
        },
        {
          label: this.$t('Sched <br> Date'),
          prop: 'work_order.timetables.scheduled',
          minWidth: 100,
          maxWidth: 100,
          component: 'FormattedDate',
        },
        {
          label: this.$t('Start <br> Date'),
          prop: 'work_order.actual_start_date',
          minWidth: 90,
          maxWidth: 90,
          component: 'FormattedDate',
          summary: () => this.$t('Grand Totals of Selected Work Orders:'),
        },
        {
          label: this.$t('Compl <br> Date'),
          prop: 'work_order.completion_date',
          minWidth: 100,
          maxWidth: 100,
          component: 'FormattedDate',
        },
        {
          label: this.$t('Assigned <br> To'),
          prop: 'assigned',
          minWidth: 60,
          maxWidth: 80,
          component: 'EmployeeLink',
        },
        {
          label: this.$t('Sales <br> Person'),
          prop: 'salesperson',
          minWidth: 60,
          maxWidth: 80,
          component: 'EmployeeLink',
        },
        {
          label: this.$t('Quote <br> Min $'),
          prop: 'work_order.minimum_cost_quoted_amount',
          minWidth: 100,
          maxWidth: 140,
          align: 'right',
          component: 'FormattedPrice',
          summary: () => this.$formatPrice(this.grandTotals.totals?.minimum_cost_quoted_amount),
        },
        {
          label: this.$t('Quote <br> Est $'),
          prop: 'work_order.estimated_cost_quoted_amount',
          minWidth: 100,
          maxWidth: 140,
          align: 'right',
          component: 'FormattedPrice',
          summary: () => this.$formatPrice(this.grandTotals.totals?.estimated_cost_quoted_amount),
        },
        {
          label: this.$t('Cost <br> To-Date'),
          prop: 'total.cost',
          minWidth: 100,
          maxWidth: 140,
          align: 'right',
          component: 'FormattedPrice',
          summary: () => this.$formatPrice(this.grandTotals.totals?.cost),
        },
        {
          label: this.$t('Billing <br> To-Date'),
          prop: 'total.income',
          minWidth: 100,
          maxWidth: 140,
          align: 'right',
          component: 'FormattedPrice',
          summary: () => this.$formatPrice(this.grandTotals.totals?.billed),
        },
      ]
    },
  },
  methods: {
    composeRows(data) {
      data.forEach(row => {
        this.rows.push(row)
        if (this.filters.format === 'long') {
          this.rows.push({
            htmlData: row
          })
        }
      })
      return this.rows
    },
  },
}
</script>
