<template>
  <BaseDataTable
      :data="invoices"
      :columns="columns"
      :can-reorder-columns="false"
      :title="$t('Payment Invoices')"
      :has-summary="true"
      :default-filters="false"
      :group-by="groupBy"
      :hide-table-top-section="true"
      :show-pagination="false"
      ref="table"
  >
    <template #header-end="{row}">
        <span>
          {{ getFormattedVendorAmount(row.header.attributes.id) }}
        </span>
    </template>
    <template #attributes.status_summary>
      <span class="font-medium ml-2">{{ $t('Totals') }}</span>
    </template>
    <template #pivots.gross_amount_summary>
      <span>{{ $formatPrice(totalGross) }}</span>
    </template>
    <template #pivots.discount_amount_summary>
      <span>{{ $formatPrice(totalDiscount) }}</span>
    </template>
    <template #pivots.net_amount_summary>
      <span class="font-medium">{{ $formatPrice(totalPaid) }}</span>
    </template>
  </BaseDataTable>
</template>
<script>
  import { groupByType } from '@/components/table/utils/groupByTypes';
  import sumBy from 'lodash/sumBy'
  import sum from 'lodash/sum'

  export default {
    props: {
      title: {
        type: String,
        default: '',
      },
      invoices: {
        type: Array,
      },
    },
    data() {
      return {
        groupBy: groupByType.Vendor,
      }
    },
    computed: {
      columns() {
        return [
          {
            label: this.$t('Number'),
            prop: 'attributes.number',
            component: 'EntityLink',
            redirectTo: '/accounts-payable/invoices/{ID}/view',
            maxWidth: 120,
            params: {
              tabindex: -1,
            },
          },
          {
            label: this.$t('Due Date'),
            prop: 'attributes.due_date',
            minWidth: 100,
            maxWidth: 200,
            component: 'FormattedDate',
          },
          {
            label: this.$t('Discount Date'),
            prop: 'attributes.discount_date',
            minWidth: 100,
            maxWidth: 200,
            component: 'FormattedDate',
            padding: '6px',
          },
          {
            label: this.$t('Status'),
            prop: 'attributes.status',
            maxWidth: 90,
            component: 'StatusLink',
          },
          {
            label: this.$t('Gross'),
            prop: 'pivots.gross_amount',
            component: 'FormattedPrice',
            minWidth: 50,
            maxWidth: 120,
            align: 'right',
          },
          {
            label: this.$t('Discount'),
            prop: 'pivots.discount_amount',
            component: 'FormattedPrice',
            minWidth: 50,
            maxWidth: 120,
            align: 'right',
          },
          {
            label: this.$t('Net'),
            prop: 'pivots.net_amount',
            component: 'FormattedPrice',
            minWidth: 50,
            maxWidth: 120,
            align: 'right',
          },
        ]
      },
      totalGross() {
        return this.sumBy(this.invoices, 'pivots.gross_amount')
      },
      totalDiscount() {
        return this.sumBy(this.invoices, 'pivots.discount_amount')
      },
      totalPaid() {
        return this.sumBy(this.invoices, 'pivots.net_amount')
      },
    },
    methods: {
      sumBy(data, prop) {
        const mappedData = data.map(row => +this.get(row, prop))
        return sum(mappedData)
      },
      getVendorPaidAmount(vendorId) {
        const vendorInvoices = this.data.filter(invoice => {
          const currentVendorId = this.get(invoice, 'attributes.vendor_id', '')
          return currentVendorId === vendorId
        })
        return sumBy(vendorInvoices, 'pivots.gross_amount')
      },
      getFormattedVendorAmount(vendorId) {
        const amount = this.getVendorPaidAmount(vendorId)
        if (amount) {
          return this.$formatPrice(amount)
        }
        return ''
      },
    },
  }
</script>
