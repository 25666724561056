<template>
  <BankOneOffChecks
    :bank="bank"
  />
</template>
<script setup lang="ts">
import store from "@/store";
import { computed } from "vue";
import BankOneOffChecks from "@/modules/settings/components/BankOneOffChecks.vue";

const bank = computed(() => {
  return store.state.settings.currentBank || {}
})
</script>
