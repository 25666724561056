<template>
  <div class="fixed bottom-0 inset-x-0 md:px-4 md:pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center form-dialog"
  >
    <transition enter-active-class="ease-out duration-75"
                enter-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="ease-in duration-75"
                leave-class="opacity-0"
                leave-to-class="opacity-100"
                appear
    >
      <div v-if="showModal"
           class="fixed inset-0 transition-opacity">
        <div @click="onCancel"
             class="absolute inset-0 bg-gray-500 opacity-75">
        </div>
      </div>
    </transition>

    <transition enter-active-class="ease-out duration-75"
                enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enter-to-class="opacity-100 translate-y-0 sm:scale-100"
                leave-active-class="ease-out duration-75"
                leave-class="opacity-100 translate-y-0 sm:scale-100"
                leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                appear
                @after-enter="onAfterEnter"
                @after-leave="onAfterLeave"
    >
      <div v-if="showModal"
           v-loading="loading"
           ref="dialogInner"
           class="relative dialog-inner bg-white md:rounded-lg overflow-y-auto max-h-screen shadow-xl transform transition-all sm:w-full"
           :class="{
                    'sm:max-w-xl': size === 'sm',
                    'sm:max-w-4xl': size === 'default',
                    'sm:max-w-6xl': size === 'lg',
                    'sm:max-w-7xl': size === 'xl',
                    'sm:max-w-8xl': size === '2xl',
                    'show-arrows': showArrows,
                    'px-4 pt-5 pb-4 sm:p-6': hasPadding,
                    'pt-5': !hasPadding,
                    '!pt-0': noPadding,
                 }"
           role="dialog"
           aria-modal="true"
           aria-labelledby="modal-headline"
      >
        <template v-if="showArrows">
          <base-button :disabled="previousDisabled"
                       variant="white"
                       rounded
                       size="sm"
                       type="button"
                       class="left-arrow absolute rounded-full"
                       @click="$emit('prev-click')"
          >
            <ChevronLeftIcon class="h-8"/>
          </base-button>
          <base-button :disabled="nextDisabled"
                       rounded
                       variant="white"
                       size="sm"
                       type="button"
                       class="right-arrow absolute rounded-full"
                       @click="$emit('next-click')"
          >
            <ChevronRightIcon class="h-8"/>
          </base-button>
        </template>
        <h3 v-if="title || $slots.title"
            id="modal-headline"
            class="text-center max-w-85 md:max-w-full px-16"
        >
          <slot name="title">
            {{ title }}
          </slot>
        </h3>
        <dialog-shortcuts />
        <div v-if="closeable"
             class="fixed md:absolute top-0 right-0 pt-4 pr-4">
          <button type="button"
                  class="bg-white md:bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-75"
                  aria-label="Close"
                  @click="onCancel">
            <svg class="h-8 w-8 md:w-6 md:h-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"/>
            </svg>
          </button>
        </div>
        <slot/>
      </div>
    </transition>
  </div>
</template>

<script>
  import i18n from '@/i18n'
  import { ChevronLeftIcon, ChevronRightIcon } from 'vue-feather-icons'
  import DialogShortcuts from "@/components/common/modal/DialogShortcuts";

  export default {
    name: 'BaseFormDialog',
    inheritAttrs: false,
    components: {
      ChevronRightIcon,
      ChevronLeftIcon,
      DialogShortcuts,
    },
    props: {
      open: Boolean,
      loading: Boolean,
      appendToBody: Boolean,
      title: String,
      confirmText: {
        type: String,
        default: i18n.t('Save'),
      },
      cancelText: {
        type: String,
        default: i18n.t('Cancel'),
      },
      size: {
        type: String,
        default: 'default',
      },
      showArrows: {
        type: Boolean,
        default: false,
      },
      hasPadding: {
        type: Boolean,
        default: false,
      },
      noPadding: {
        type: Boolean,
        default: false,
      },
      previousDisabled: {
        type: Boolean,
        default: false,
      },
      nextDisabled: {
        type: Boolean,
        default: false,
      },
      closeable: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        showModal: this.open,
      }
    },
    methods: {
      onDelete() {
        this.showModal = false
      },
      onCancel() {
        if (!this.closeable) {
          return
        }
        this.showModal = false
      },
      onAfterLeave() {
        this.$emit('close')
        this.$emit('update:open', false)
      },
      async onAfterEnter() {
        if (!this.$refs.cancel) {
          return
        }
      },
      removeQueryParameters() {
        const { id } = this.$route.query
        if (!this.$route.query || !id) {
          return
        }
        const query = {}
        this.$router.replace({ query })
      },
      onKeyDown(event) {
        if (event.code === 'Escape' && this.showModal) {
          this.onCancel()
        }
      },
    },
    watch: {
      open(value) {
        this.showModal = value
      },
      onKeyDown(event) {
        if (event.code === 'Escape' && this.showModal) {
          this.onCancel()
        }
      }
    },
    mounted() {
      if (this.appendToBody) {
        document.body.appendChild(this.$el);
      }
      document.addEventListener('keydown', this.onKeyDown)
    },
    destroyed() {
      this.removeQueryParameters()
      // if appendToBody is true, remove DOM node after destroy
      if (this.appendToBody && this.$el && this.$el.parentNode) {
        this.$el.parentNode.removeChild(this.$el);
      }
      document.removeEventListener('keydown', this.onKeyDown)
    },
  }
</script>
<style scoped lang="scss">
  .left-arrow {
    left: -60px;
    top: calc(50% - 25px);
  }

  .right-arrow {
    right: -60px;
    top: calc(50% - 25px);
  }

  .dialog-inner.show-arrows {
    @apply overflow-y-visible;
  }

  .form-dialog {
    z-index: 1001;
  }

  .form-dialog :deep(.form-inner) {
    @apply px-6 md:px-8;

    &.no-padding {
      @apply p-0;
    }
  }
</style>
