<template>
  <div>
    <base-input :label="$t('Photos')">
      <template #container>
        <el-upload
          :auto-upload="false"
          :on-preview="handlePictureCardPreview"
          :on-change="onChange"
          :on-remove="handleRemove"
          :file-list="fileList"
          list-type="picture-card"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </template>
    </base-input>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>
<script>
import { Dialog, Upload } from "element-ui";
import { PlusIcon } from "vue-feather-icons";

export default {
  components: {
    [Upload.name]: Upload,
    [Dialog.name]: Dialog,
    PlusIcon,
  },
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      fileList: this.value,
    };
  },
  methods: {
    handleRemove(_, fileList) {
      this.fileList = fileList
      this.$emit('input', fileList)
    },
    onChange(_, fileList) {
      this.fileList = fileList
      this.$emit('input', fileList)
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    }
  }
}
</script>
<style>
.el-upload-list--picture-card .el-upload-list__item-thumbnail {
  object-fit: contain;
}
</style>
