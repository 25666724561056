<template>
  <BaseGridSelect
      ref="select"
      v-focus
      v-model="selectedValue"
      :own-columns="tableColumns"
      :resource-name="params.resourceName"
      :filterMethod="params.filterMethod"
      valueKey="id"
      labelKey="name"
      inline-errors
      class="w-full"
      @change="onChange"
      @keydown.enter.native="goToNextCell"
  />
</template>
<script>
  import { selectEditorMixin } from '@/components/ag-grid/cellEditors/selectEditorMixin';

  export default {
    mixins: [selectEditorMixin],
    methods: {
      stopEditing() {
        this.params.stopEditing()
      },
    },
    computed: {
      tableColumns() {
        return [
          {
            name: this.$t('Name'),
            prop: 'name',
            minWidth: 120,
            maxWidth: 120,
          },
          {
            name: this.$t('Location'),
            component: this.$tableColumns.LocationLink,
            minWidth: 300,
            maxWidth: 300,
          },
        ]
      },
    },
  }
</script>
