<template>
  <div className="import-data mt-4">
    <ImportForm v-bind="importSettings"
                @success="redirectToList"
    />
  </div>
</template>
<script>
  import ImportForm from '@/modules/common/components/import/ImportForm';
  import { getInitialMapping } from '@/modules/payroll/utils/timecardImportColumns';

  const colMappings = {
    Code: {
      value: 'code',
      label: 'Code',
      alternativeLabels: ['Equipment Code'],
    },
    Description: {
      value: 'description',
      label: 'Description',
      alternativeLabels: ['Class Description'],
    },
  }

  const options = [
    colMappings.Code,
    colMappings.Description,
  ]

  export default {
    components: {
      ImportForm,
    },
    data() {
      return {
        importSettings: {
          url: '/restify/specialty-codes/import',
          columnOptions: options,
          columnMappings: colMappings,
          initialMapping: getInitialMapping(options),
          type: 'specialty-codes-import',
          resource: 'specialty codes',
        },
      }
    },
    methods: {
      async redirectToList() {
        await this.$router.push('/accounts-payable/settings/specialty-codes')
      },
    },
  }
</script>
