<template>
  <APPaymentsProofListing />
</template>
<script>
  import APPaymentsProofListing from "@/modules/accounts-payable/components/checks/APPaymentsProofListing";

  export default {
    components: {
      APPaymentsProofListing,
    }
  }
</script>
