<template>
  <div class="mt-2 material-details">
    <DetailsPageLayout :loading="materialLoading">

      <template #left>
        <MaterialMainCard
          :material="material"
          :materialFullData="materialFullData"
        />
        <MaterialSecondCard
          :material="material"
          :materialFullData="materialFullData"
        />
      </template>

      <template #right>
        <MaterialLastOrderCard
          :material="material"
          :materialFullData="materialFullData"
        />
        <MaterialInventoryCard
          :material="material"
        />
      </template>

    </DetailsPageLayout>
    <base-back-link to="/purchasing-inventory/materials"/>
  </div>
</template>
<script lang="ts" setup>
import store from '@/store'
import { computed } from 'vue'
import DetailsPageLayout from '@/modules/common/components/DetailsPageLayout.vue'
import MaterialMainCard from '@/modules/purchasing-inventory/components/material/MaterialMainCard.vue'
import MaterialSecondCard from '@/modules/purchasing-inventory/components/material/MaterialSecondCard.vue'
import MaterialLastOrderCard from '@/modules/purchasing-inventory/components/material/MaterialLastOrderCard.vue'
import MaterialInventoryCard from '@/modules/purchasing-inventory/components/material/MaterialInventoryCard.vue'

const materialFullData = computed(() => {
  return store.state.inventoryManagement.currentMaterial
})

const material = computed(() => {
  return store.state.inventoryManagement.currentMaterial.attributes
})

const materialLoading = computed(() => {
  return store.state.inventoryManagement.materialLoading
})

</script>
<style lang="scss">
.material-details {
  span {
    @apply text-gray-500 font-medium;
  }
}
</style>
