<template>
  <div class="col-span-6 lg:col-span-3">
    <CustomerPreview :entity="customer" />
  </div>
</template>
<script>
  import CustomerPreview from "@/modules/common/components/entity-preview/CustomerPreview";

  export default {
    components: {
      CustomerPreview,
    },
    props: {
      customer: {
        type: Object,
        default: () => ({}),
      },
    },
  }
</script>
