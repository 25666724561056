<template>
    <li>
        <div class="flex justify-between py-4 px-4 sm:px-6 items-center text-sm leading-5 font-medium text-gray-500 border-b"
             v-if="!periodIndex">
            <div class="flex items-center leading-5 justify-between w-full">
                <div class="flex items-center">
                  {{$t('Period Amount')}}
                </div>
                <div class="flex items-center">
                  {{$t('Ending Balance')}}
                </div>
            </div>
        </div>
        <div role="button"
             :tabindex="0"
             :id="`button-period-${period.period}`"
             @click="$emit('view-transactions', period.period)"
             @keydown.enter="$emit('view-transactions', period.period)"
             class="flex justify-between items-center hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
            <div class="px-4 flex-1 py-4 sm:px-6">
                <div class="flex items-center justify-between">
                    <div class="text-sm leading-5 font-medium text-primary-600 truncate">
                        {{getPeriodName(period.period)}}
                    </div>
                </div>
                <div class="mt-2 flex justify-between">
                    <base-tooltip :content="$t(`Amount for ${getPeriodName(period.period)}`)" :tabindex="-1">
                        <div class="mr-6 flex items-center leading-5 text-gray-700">
                            <ActivityIcon class="w-4 h-4 mr-2 activity-icon"></ActivityIcon>
                            {{$formatPrice(period.amount)}}
                        </div>
                    </base-tooltip>
                    <base-tooltip :content="$t(`End Balance for ${getPeriodName(period.period)}`)" :tabindex="-1">
                        <div class="mt-2 flex items-center leading-5 text-gray-700 sm:mt-0">
                            <ArrowRightCircleIcon class="w-4 h-4 mr-4"></ArrowRightCircleIcon>
                            {{ getEndBalance(period.amount) }}
                        </div>
                    </base-tooltip>
                </div>
            </div>
        </div>
    </li>
</template>
<script>
  import { ActivityIcon, ArrowRightCircleIcon } from 'vue-feather-icons'

  export default {
    components: {
      ActivityIcon,
      ArrowRightCircleIcon,
    },
    props: {
      period: {
        type: Object,
        default: () => ({})
      },
      months: {
        type: Object,
        default: () => ({})
      },
      previousPeriods: {
        type: Array,
        default: () => []
      },
      periodIndex: {
        type: Number,
        default: 0,
      }
    },
    computed: {
      endBalance() {
        let amount = 0
        this.previousPeriods.forEach(period => {
          amount += period.amount
        })

        return amount
      }
    },
    methods: {
      getPeriodName(period) {
        return this.$store.getters['company/getPeriodName'](period, 'originalLabel')
      },
      getEndBalance(periodAmount) {
        const amount = this.endBalance + periodAmount
        return this.$formatPrice(amount)
      },
    }
  }
</script>
<style scoped>
.activity-icon {
  min-width: 16px;
}
</style>
