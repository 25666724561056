<template>
  <div class="col-span-6 lg:col-span-3">
    <VendorPreview :entity="vendor" />
  </div>
</template>
<script>
  import VendorPreview from "@/modules/common/components/entity-preview/VendorPreview";

  export default {
    components: {
      VendorPreview,
    },
    props: {
      vendor: {
        type: Object,
        default: () => ({})
      }
    },
  }
</script>
