<template>
  <div>
    <AgDataTable
        url="/restify/financial-reports"
        actions="search,refresh,add,edit,delete"
        entity="financial-reports"
        :columns="columns"
        :add-text="$t('New Financial Report')"
        :add-entity-in-new-tab="true"
    />
  </div>
</template>
<script lang="ts" setup>
  import { computed } from 'vue'
  import { Column } from '@/components/ag-grid/tableTypes'
  import i18n from "@/i18n";
  import { FinancialReportType, FinancialReportTypeLabels } from "@/modules/ledger/enum/financialReports";
  import { ValueFormatterParams } from '@ag-grid-community/core'
  import { FinancialReport } from "@/modules/common/types/models";
  import { dateTypes } from "@/plugins/dateFormatPlugin";
  import Data = API.Data;

  const columns = computed<Column<Data<FinancialReport>>[]>(() => {
    return [
      {
        headerName: i18n.t('Code'),
        field: 'attributes.code',
        minWidth: 80,
      },
      {
        headerName: i18n.t('Description'),
        field: 'attributes.description',
        minWidth: 180,
      },
      {
        headerName: i18n.t('Type'),
        field: 'attributes.type',
        minWidth: 180,
        valueFormatter: (params: ValueFormatterParams) => {
          const value = params.value as FinancialReportType
          return FinancialReportTypeLabels[value] || ''
        }
      },
      {
        headerName: i18n.t('Last Updated'),
        field: 'attributes.updated_at',
        minWidth: 160,
        component: 'FormattedDate',
        cellRendererParams: {
          format: dateTypes.DateTime,
        }
      }
    ]
  })
</script>
