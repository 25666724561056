<template>
  <div class="report-page">
    <layout-tabs :items="filteredItems"/>
    <router-view/>
  </div>
</template>
<script>

  export default {
    data() {
      return {
        items: [
          {
            name: this.$t('Daily Job Cost Detail'),
            path: '/job-costing/reports/daily-job-cost-detail',
            permission: 'job_costing_reports',
          },
          {
            name: this.$t('Billing / Cost Comparison'),
            path: '/job-costing/reports/billing-cost-comparison',
            permission: 'job_costing_reports',
          },
          {
            name: this.$t('Job Cost Detail'),
            path: '/job-costing/reports/job-cost-detail',
            permission: 'job_costing_reports',
          },
          {
            name: this.$t('Job Profit Summary'),
            path: '/job-costing/reports/profit-summary',
            permission: 'job_costing_reports',
          },
          {
            name: this.$t('Job Cost Variance'),
            path: '/job-costing/reports/job-cost-variance',
            permission: 'job_costing_reports',
            productionReady: false,
          },
        ],
      }
    },
    computed: {
      filteredItems() {
        return this.items.filter(item => {
          if (item.productionReady === false) {
            return this.isDev
          }

          return this.$can(item.permission)
        })
      },
    },
  }
</script>
