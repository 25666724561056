<template>
  <div class="th-select-wrapper">
    <base-select
        :value="selectValue"
        :options="selectOptions"
        :inline-errors="true"
        popper-class="import-select-dropdown"
        size="mini"
        class="th-select truncate"
        @change="onChange"
    >
    </base-select>
  </div>
</template>
<script>
  import orderBy from 'lodash/orderBy'

  export default {
    name: 'ImportColumnMappingSelect',
    props: {
      value: {
        type: [String, Number],
      },
      mapping: {
        type: Object,
        default: () => ({}),
      },
      options: {
        type: Array,
        default: () => [],
      },
      colIndex: Number,
    },
    data() {
      return {
        selectValue: this.value
      }
    },
    computed: {
      selectOptions() {
        const mappedOptions = this.options.map(option => {
          const mappingValue = this.mapping[option.value]
          return {
            value: option.value,
            label: option.label,
            disabled: mappingValue > -1,
          }
        })
        let options = orderBy(mappedOptions, ['disabled', 'label'], ['asc', 'asc'])
        let skipOption = {
          value: -1,
          label: this.$t('Skip'),
        }
        return [skipOption, ...options]
      }
    },
    methods: {
      onChange(newValue) {
        const previousValue = this.selectValue
        this.$emit('change', newValue, previousValue)
        this.selectValue = newValue
      }
    },
  }
</script>
