<template>
    <div>
        <div>
            <img class="h-12 mx-auto w-auto" src="/img/cp.png" alt="Workflow"/>
            <h1 class="page-heading">
                {{$t('Reset Password')}}
            </h1>
            <p class="mt-2 text-sm leading-5 text-gray-600 max-w text-center">
                {{$t('Enter your new password below')}}
            </p>
        </div>
        <div class="mt-8">
            <div class="mt-6 relative">
                <div class="w-full border-t border-gray-300"/>
                <div class="relative flex justify-center text-sm leading-5"/>
            </div>
            <div class="mt-6">
                <ValidationObserver v-slot="{ handleSubmit, valid }">
                    <form @submit.prevent="handleSubmit(onSubmit)">
                        <BaseInput v-model="model.password"
                                   :label="$t('New Password')"
                                   :placeholder="$t('New Password')"
                                   rules="required|min:8"
                                   id="password"
                                   name="password"
                                   type="password"
                        />
                        <BaseInput v-model="model.password_confirmation"
                                   :label="$t('Confirm Password')"
                                   :placeholder="$t('Confirm Password')"
                                   rules="required|password:@password"
                                   id="password_confirmation"
                                   type="password"
                        />
                        <div class="flex justify-end text-sm leading-5">
                            <router-link to="/login">
                                {{$t('Back to login')}}
                            </router-link>
                        </div>
                        <div class="mt-6">
                            <BaseButton block
                                        type="submit"
                                        :disabled="!valid"
                                        :loading="loading">
                                {{$t('Reset Password')}}
                            </BaseButton>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>
<script>
  import AuthService from '@/modules/auth/services/AuthService';

  export default {
    data() {
      return {
        model: {
          email: '',
          token: '',
          password: null,
          password_confirmation: null,
        },
        loading: false,
      };
    },
    methods: {
      async onSubmit() {
        try {
          this.loading = true

          this.model = {
            ...this.model,
            ...this.$route.query,
          }

          await AuthService.resetPassword(this.model)
          this.$success(this.$t('The password it was reset!'))
          await this.$router.push({name: 'Login'})
        } catch (e) {
          if (e.handled) {
            return
          }
          this.$error(this.$t('Something went wrong please try again.'))
        } finally {
          this.loading = false
        }
      },
    },
  };
</script>
