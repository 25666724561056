<template>
  <TimesheetEntryDialog
    v-if="showEditDialog"
    :open.sync="showEditDialog"
    :data="fullEntryToEdit"
    :date="entryToEdit?.date"
    :employee-id="entryToEdit?.employee_id"
    @close="onClose"
    @submit="updateAndClose"
  />
</template>
<script>
  import TimesheetEntryDialog from "@/modules/payroll/components/timesheets/TimesheetEntryDialog.vue";

  export default {
    components: {
      TimesheetEntryDialog,
    },
    data() {
      return {
        showEditDialog: false,
        entryToEdit: this.params?.data || {},
      }
    },
    computed: {
      fullEntryToEdit() {
        return {
          id: this.entryToEdit?.id,
          attributes: this.entryToEdit,
        }
      }
    },
    mounted() {
      this.entryToEdit = this.params?.data || {}
      this.showEditDialog = true
    },
    methods: {
      getValue() {
        return {}
      },
      async onClose() {
        this.showEditDialog = false
        this.params?.stopEditing()
      },
      async onSubmit(entry) {
        const data = entry?.attributes
        if (data) {
          this.params.node.setData(data)
        }
      },
      async updateAndClose(entry) {
        await this.onSubmit(entry)
        await this.onClose()
      }
    },
  }
</script>
