<template>
  <div>
    <layout-tabs :items="filteredItems"/>
    <router-view/>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        items: [
          {
            name: this.$t('Pending'),
            tooltip: this.$t('Reconciliation list that were not posted yet'),
            path: '/ledger/account-reconciliation/pending',
            permission: 'cash_reconciliations_show',
          },
          {
            name: this.$t('Posted'),
            tooltip: this.$t('Posted reconciliation list'),
            path: '/ledger/account-reconciliation/posted',
            permission: 'cash_reconciliations_show',
          },
          {
            name: this.$t('Voided'),
            tooltip: this.$t('Voided reconciliation list'),
            path: '/ledger/account-reconciliation/voided',
            permission: 'cash_reconciliations_show',
          },
        ],
      }
    },
    computed: {
      filteredItems() {
        return this.items.filter(item => this.$can(item.permission))
      },
    },
  }
</script>
