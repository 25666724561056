import PhaseCostCodeForm from '@/modules/estimates/components/PhaseCostCodeForm'
import CompanyForm from '@/modules/settings/components/CompanyForm'
import EquipmentClassForm from '@/modules/equipment/components/EquipmentClassForm'
import SpecialtyCodeForm from '@/modules/equipment/components/SpecialtyCodeForm'
import CrewLaborRatesForm from '@/modules/estimates/components/CrewLaborRatesForm'
import BidForm from '@/modules/estimates/components/BidForm'
import MaterialClassForm from '@/modules/purchasing-inventory/components/MaterialClassForm'
import MaterialForm from '@/modules/purchasing-inventory/components/MaterialForm'
import WorkItemForm from '@/modules/estimates/components/WorkItemForm'
import EstimateFormulaForm from '@/modules/estimates/components/EstimateFormulaForm'
import MaterialCodeForm from '@/modules/estimates/components/MaterialCodeForm'
import CostTypeForm from '@/modules/job-costing/components/CostTypeForm'
import JobForm from '@/modules/job-costing/components/JobForm'
import WorkerCompensationRateForm from '@/modules/payroll/components/rates/WorkerCompensationRateForm'
import GeneralLiabilityRateForm from '@/modules/payroll/components/rates/GeneralLiabilityRateForm'
import VendorForm from '@/modules/accounts-payable/components/VendorForm'
import SalesTaxDistrictsForm from '@/modules/accounts-receivable/components/settings/SalesTaxDistrictsForm'
import CustomerForm from '@/modules/accounts-receivable/components/CustomerForm'
import CategoryForm from '@/modules/job-costing/components/CategoryForm'
import OcipForm from '@/modules/payroll/components/rates/OcipForm'
import EquipmentForm from '@/modules/equipment/components/EquipmentForm'
import CostLineItemForm from '@/modules/job-costing/components/line-items/CostLineItemForm'
import BillingRateTypeForm from '@/modules/payroll/components/rates/BillingRateTypeForm'
import WorkSiteForm from '@/modules/service-billing/components/WorkSiteForm'
import CompanyDetails from '@/modules/settings/pages/company/company-details'
import VendorDetails from '@/modules/accounts-payable/pages/vendors/vendor-details'
import JobDetails from '@/modules/job-costing/pages/jobs/job-details'
import InvoiceDetails from '@/modules/accounts-payable/pages/invoices/invoice-details'
import BankForm from '@/modules/settings/components/BankForm'
import BankDetails from '@/modules/settings/pages/bank/bank-details'
import EmployeeFormFull from "@/modules/payroll/components/employee/EmployeeFormFull";
import EmployeeDetails from "@/modules/payroll/pages/employee/employee-details";
import CustomerDetails from "@/modules/accounts-receivable/pages/customer/customer-details";
import StateTaxJurisdictionForm from "@/modules/payroll/components/settings/StateTaxJurisdictionForm";
import WorkOrdersForm from "@/modules/service-billing/components/WorkOrdersForm.vue";
import ContactForm from "@/modules/common/components/contacts/ContactForm.vue";
import AddressForm from "@/modules/settings/components/AddressForm.vue";
import DocumentTypeForm from "@/modules/settings/pages/company/DocumentTypeForm.vue";
import DepartmentCodeForm from "@/modules/payroll/components/codes/DepartmentCodeForm.vue";

const editResources = {
  '/restify/phase-costs': {
    component: PhaseCostCodeForm,
    name: 'Create Phase Code/Cost Code',
  },
  '/restify/companies': {
    component: CompanyForm,
    name: 'Create Company',
  },
  '/restify/equipment-classes': {
    component: EquipmentClassForm,
    name: 'Create Equipment Class Code',
  },
  '/restify/equipment-specialties': {
    component: SpecialtyCodeForm,
    name: 'Create Equipment Specialty Codes',
  },
  '/restify/crew-labor-rates': {
    component: CrewLaborRatesForm,
    name: 'Create Crew Labor Rates',
  },
  '/restify/bids': {
    component: BidForm,
    name: 'Create Bid',
  },
  '/restify/material-classes': {
    component: MaterialClassForm,
    name: 'Create Material Class',
  },
  '/restify/materials': {
    component: MaterialForm,
    name: 'Create Material',
  },
  '/restify/work-items': {
    component: WorkItemForm,
    name: 'Create Work Item',
  },
  '/restify/estimate-formulas': {
    component: EstimateFormulaForm,
    name: 'Create Estimate Formula',
  },
  '/restify/material-codes': {
    component: MaterialCodeForm,
    name: 'Create Material Code',
  },
  '/restify/job-types': {
    component: CostTypeForm,
    name: 'Create Job Cost Type',
  },
  '/restify/jobs': {
    component: JobForm,
    name: 'Create Job',
  },
  '/restify/workers-comp-rates': {
    component: WorkerCompensationRateForm,
    name: 'Create Worker Compensation Rates',
  },
  '/restify/gen-liability-rates': {
    component: GeneralLiabilityRateForm,
    name: 'Create General Liability Rates',
  },
  '/restify/vendors': {
    component: VendorForm,
    name: 'Create Vendor',
  },
  '/restify/districts': {
    component: SalesTaxDistrictsForm,
    name: 'Create Sales Tax District',
  },
  '/restify/customers': {
    component: CustomerForm,
    name: 'Create Customer',
  },
  '/restify/employees': {
    component: EmployeeFormFull,
    name: 'Create Employee',
  },
  '/restify/job-categories': {
    component: CategoryForm,
    name: 'Create Job Category',
  },
  '/restify/ocip-codes': {
    component: OcipForm,
    name: 'Create Ocip Code',
  },
  '/restify/equipment': {
    component: EquipmentForm,
    name: 'Create Equipment',
  },
  '/restify/line-items': {
    component: CostLineItemForm,
    name: 'Create Cost Line Item'
  },
  '/restify/billing-rate-types': {
    component: BillingRateTypeForm,
    name: 'Create Billing Rate Type',
  },
  '/restify/work-sites': {
    component: WorkSiteForm,
    name: 'Create Worksite',
  },
  '/restify/work-orders': {
    component: WorkOrdersForm,
    name: 'Create Work Order',
  },
  '/restify/banks': {
    component: BankForm,
    name: 'Create new Bank',
  },
  '/restify/state-tax-jurisdictions': {
    component: StateTaxJurisdictionForm,
    name: 'Create State Tax',
  },
  '/restify/contacts': {
    component: ContactForm,
    name: 'Create new Contact',
  },
  '/restify/addresses': {
    component: AddressForm,
    name: 'Create new Address',
  },
  '/restify/document-types': {
    component: DocumentTypeForm,
    name: 'Document Type',
  },
  '/restify/departments': {
    component: DepartmentCodeForm,
    name: 'Department',
  }
}

const viewResources = {
  '/restify/companies': {
    component: CompanyDetails,
    name: 'Company',
  },
  '/restify/vendors': {
    component: VendorDetails,
    name: 'Vendor',
  },
  '/restify/employees': {
    component: EmployeeDetails,
    name: 'Employee',
  },
  '/restify/customers': {
    component: CustomerDetails,
    name: 'Customer',
  },
  '/restify/jobs': {
    component: JobDetails,
    name: 'Job',
  },
  '/restify/invoices': {
    component: InvoiceDetails,
    name: 'Invoice',
  },
  '/restify/banks': {
    component: BankDetails,
    name: 'Bank',
  },
}

export function getEntityComponentData(apiUrl) {
  return editResources[apiUrl]
}

export function getEntityViewComponent(apiUrl) {
  return viewResources[apiUrl]
}
