<template>
  <div class="mt-10 sm:mt-0">
    <base-form
        v-bind="$attrs"
        :save-text="$t('Create Company')"
        :update-text="$t('Update Company')"
        :loading="loading"
        :focus-on-first-input="false"
        grid-classes="grid grid-cols-8 gap-x-3"
        @cancel="$emit('cancel')"
        @submit="updateCompany"
    >
      <div class="col-span-8 md:col-span-4 xl:col-span-2">
        <base-input v-model="model.name"
                    :label="$t('Name')"
                    :placeholder="$t('Company Name')"
                    id="name"
                    rules="required">
        </base-input>
      </div>
      <div class="col-span-8 md:col-span-2 xl:col-span-1">
        <base-input v-model="model.abbr"
                    :label="$t('Short Name')"
                    :placeholder="$t('Short Name')"
                    id="short_name"
                    rules="required"
        >
        </base-input>
      </div>
      <div class="col-span-8 md:col-span-2 xl:col-span-1">
        <base-input v-model="model.number"
                    :label="$t('Number')"
                    :placeholder="$t('Company Number')"
                    id="number"
                    :min="0"
                    type="number"
                    rules="max_value:65535">
        </base-input>
      </div>
      <div class="col-span-8 md:col-span-2 xl:col-span-1">
        <base-select
            v-model="model.active"
            :label="$t('Status')"
            :options="statusOptions"
        />
      </div>
      <div class="col-span-8"/>
      <div class="col-span-8 md:col-span-4 xl:col-span-2">
        <base-input v-model="model.email"
                    :label="$t('Email')"
                    :placeholder="$t('Email')"
                    id="email"
                    rules="email">
        </base-input>
      </div>
      <div class="col-span-8 md:col-span-4 xl:col-span-2">
        <base-phone-input v-model="model.phone"
                          :label="$t('Primary Contact Phone')"
                          id="phone"
        />
      </div>
      <div class="col-span-8 md:col-span-4 xl:col-span-2">
        <base-phone-input v-model="model.fax"
                          :label="$t('Fax')"
                          id="fax"
        />
      </div>
      <div class="col-span-8"/>
      <div class="col-span-8 md:col-span-4 xl:col-span-2">
        <base-input v-model="model.contractor_license"
                    :label="$t('Contractor Licence')"
                    :placeholder="$t('Contractor Licence')"
                    id="contractor_license"/>
      </div>
      <div class="col-span-8 md:col-span-4 xl:col-span-2">
        <base-input v-model="model.naics_code"
                    :label="$t('NAICS Code')"
                    :placeholder="$t('NAICS Code')"
                    id="naics_code"/>
      </div>
      <div class="col-span-8"></div>
      <div class="col-span-8 md:col-span-4 xl:col-span-2">
        <base-input
            v-model="model.fiscal_year"
            :label="$t('Fiscal Year')"
            :name="$t('Fiscal Year')"
            type="number"
            rules="required"
        />
      </div>
      <div class="col-span-8 md:col-span-4 xl:col-span-2">
        <base-date-picker
            v-model="model.first_month"
            :label="$t('First Month')"
            :name="$t('First Month')"
            type="month"
            format="MMMM"
            value-format="MMMM"
            rules="required"
        />
      </div>
      <div class="col-span-8 md:col-span-4 xl:col-span-2">
        <base-input
            v-model="model.calendar_year_of_period_1"
            :label="$t('Calendar Year Of Period 1')"
            :name="$t('Calendar year of period 1')"
            :rules="{
              'required': true,
              'min_value': $validator.minFiscalYear,
              'max_value': $validator.maxFiscalYear,
            }"
            type="number"
        />
      </div>
      <div class="col-span-4">
        <base-checkbox
            v-model="model.multiple_local_taxes"
            :label="$t('Multiple Local Taxes')"
            id="multiple_local_taxes"
            @change="onChangeUseMultipleLocalTaxes"
        />
      </div>
      <div class="col-span-8">
        <base-checkbox
            v-model="model.multiple_state_taxes"
            :label="$t('Multiple State Taxes')"
            id="multiple_state_taxes"
        />
      </div>
      <div
          v-if="model.multiple_local_taxes"
          class="col-span-8 md:col-span-6 mt-4"
      >
        <TimecardOverrides
            v-model="model"
            scope="Timecard G&A"
            :collapsible="false"
        />
      </div>
      <el-collapse v-model="collapseItems" class="col-span-8">
        <base-collapse-section v-if="!data.id" :title="$t('Address')" key="address" name="address">
          <LocalAddressForm class="col-span-8" :model="model.address"/>
        </base-collapse-section>
        <base-collapse-section
            v-if="data.id"
            :title="$t('Addresses')"
            key="timecard-overrides"
            name="timecard-overrides"
        >
          <AddressList
              :entityId="model.id"
              :data="model.addresses"
              :resource="model"
              title=""
              entity="companies"
              ref="addressList"
              @refresh="getCurrentCompany"
          />
        </base-collapse-section>
        <base-collapse-section
            v-if="model.id"
            :title="$t('Payroll Settings')"
            key="payroll-settings"
            name="payroll-settings"
        >
          <div class="grid grid-cols-8 gap-x-3">
            <div class="col-span-8 md:col-span-2">
              <base-input
                  v-model="model.insurance_number"
                  :label="$t('Insurance Number')"
                  :placeholder="$t('Insurance Number')"
                  rules="alpha_num|max:40">
              </base-input>
            </div>
            <div class="col-span-8 md:col-span-2 lg:col-span-1">
              <base-select
                  v-model="model.license_type"
                  :label="$t('License Type')"
                  :options="licenseTypeOptions">
              </base-select>
            </div>
            <div class="col-span-8 md:col-span-3">
              <base-input
                  v-model="model.license_type_description"
                  :label="$t('License Description')"
                  :placeholder="$t('License Description')"
                  :rules="model.license_type === LicenceTypes.Other ? 'required|max:34' : 'max:34'"
              />
            </div>
            <div class="col-span-8"></div>
            <div class="col-span-8 md:col-span-2">
              <base-input
                  v-model="model.PWCR_number"
                  :label="$t('PWCR Number')"
                  :placeholder="$t('PWCR Number')"
                  type="number"
                  :min="0"
                  rules="numeric"
              />
            </div>
            <div class="col-span-8 md:col-span-2">
              <base-switch
                  v-model="model.use_PWCR_registration"
                  :label-info="$t('Use PWCR Registration')">
              </base-switch>
            </div>
          </div>
        </base-collapse-section>
      </el-collapse>
    </base-form>
  </div>
</template>
<script>
import AddressList from '@/modules/common/components/AddressList'
import CompanyService from '@/modules/settings/services/CompanyService'
import TimecardOverrides from '@/components/common/TimecardOverrides.vue'
import LocalAddressForm from "@/modules/settings/components/LocalAddressForm.vue";
import { addressTypes } from "@/enum/enums";

const LicenceTypes = {
  CSLB: 'cslb',
  PL: 'pl',
  Other: 'other',
}
export default {
  components: {
    LocalAddressForm,
    AddressList,
    TimecardOverrides,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      LicenceTypes,
      loading: false,
      collapseItems: ['address'],
      model: {
        name: '',
        email: '',
        naics_code: undefined,
        abbr: '',
        contractor_license: undefined,
        phone: undefined,
        fax: undefined,
        active: true,
        fiscal_year: new Date().getFullYear(),
        first_month: 'January',
        calendar_year_of_period_1: new Date().getFullYear(),
        multiple_local_taxes: false,
        multiple_state_taxes: false,
        withholding_local_2_id: null,
        withholding_local_id: null,
        withholding_state_id: null,
        gen_liability_state_id: null,
        workers_comp_state_id: null,
        insurance_number: undefined,
        license_type: undefined,
        license_type_description: undefined,
        PWCR_number: undefined,
        use_PWCR_registration: undefined,
        address: {
          type: addressTypes.Headquarters,
          postal_code: null,
          address_1: null,
          address_2: null,
          city: null,
          state: null,
          name: null,
        }
      },
      licenseTypeOptions: [
        {
          label: this.$t('CSLB'),
          value: LicenceTypes.CSLB,
        },
        {
          label: this.$t('PL'),
          value: LicenceTypes.PL,
        },
        {
          label: this.$t('Other'),
          value: LicenceTypes.Other,
        }
      ],
      statusOptions: [
        {
          label: this.$t('Active'),
          value: true,
        },
        {
          label: this.$t('Inactive'),
          value: false,
        }
      ]
    }
  },
  methods: {
    async updateCompany() {
      try {
        this.loading = true
        const payload = {
          ...this.model,
        }
        if (this.model.id) {
          if (!payload.email) {
            payload.email = null
          }
          if (payload.address) {
            delete payload.address
          }
          await CompanyService.updateCompany(payload)
          this.$success(this.$t('Company updated'))
          await this.$store.dispatch('company/getCurrentCompany')
        } else {
          const { data } = await CompanyService.createCompany(payload)
          data.id = data.company_id
          await this.$store.dispatch('company/markUserCompanyAsActive', data)
          await this.$store.dispatch('auth/refreshProfile')
          this.$success(this.$t(`Company created successfully! Your current active company was changed to ${this.model.name}`))
          window.location.reload()

        }
        this.$emit('save', this.model)
      } catch (err) {
        this.$error(this.$t('Could not update the company'))
      } finally {
        this.loading = false
      }
    },
    onChangeUseMultipleLocalTaxes() {
      this.model.withholding_local_2_id = null
      this.model.withholding_local_id = null
      this.model.withholding_state_id = null
      this.model.gen_liability_state_id = null
      this.model.workers_comp_state_id = null
    },
    getCurrentCompany() {
      this.$store.dispatch('company/getCurrentCompany')
    },
  },
  watch: {
    data: {
      immediate: true,
      handler(value) {
        const newModel = {
          ...value?.attributes,
          ...value?.relationships,
        }
        this.model = {
          ...this.model,
          ...newModel,
        }
      },
    },
  },
}
</script>
