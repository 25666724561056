<template>
  <div class="pt-4">
    <base-filter-row :title="$t('Transaction Type')">
      <base-select
          v-model="value.type"
          :options="transitionTypeOptions"
          :name="$t('Transaction Type')"
          class="col-span-4 2xl:col-span-3"
          rules="required"
          multiple
      />
    </base-filter-row>

    <base-filter-row :title="$t('Periods')">

      <base-filter-range class="col-span-4">
        <template #start>

          <base-input
              v-model="value.period_from"
              :placeholder="$t('Period From')"
              :name="$t('Period From')"
              :rules="{
                'min_value': 0,
                'max_value': value.period_to,
              }"
              class="w-1/2"
              type="number"
          />

        </template>
        <template #end>

          <base-input
              v-model="value.period_to"
              :placeholder="$t('Period To')"
              :name="$t('Period To')"
              :rules="{
                'min_value': value.period_from,
                'max_value': 12,
              }"
              class="w-1/2"
              type="number"
          />

        </template>
      </base-filter-range>

    </base-filter-row>

    <job-range-filter v-model="value"/>

    <base-filter-row :title="$t('Include')">

      <div class="flex items-baseline col-span-3">
        <span class="text-label">{{ $t('Statuses') }}</span>
        <base-select
            v-model="value.job_statuses"
            :add-entity="false"
            :options="jobStatusOptions"
            class="flex-1"
            collapse-tags
            multiple
        />
      </div>

      <div class="flex items-baseline col-span-3">
        <span class="text-label">{{ $t('Types') }}</span>
        <base-select
            v-model="value.job_type_ids"
            :add-entity="false"
            :options="jobTypes"
            value-key="id"
            label-key="name"
            class="flex-1"
            collapse-tags
            multiple
        />
      </div>

    </base-filter-row>

    <line-item-range-filter v-model="value"/>

    <customer-range-filter v-model="value"/>

    <base-filter-row>
      <div class="col-span-6">
        <base-checkbox
            v-model="value.show_removed"
            :label="$t('Show cost history previously set to Remove')"
            id="show_removed"
        />
        <base-checkbox
            v-model="value.add_vendor_name"
            :label="$t('Do you want to place history Vendor Name in detail description')"
            id="add_vendor_name"
        />
      </div>
    </base-filter-row>

  </div>
</template>
<script>
  import { jobLineItemTypes } from '@/enum/enums'
  import { jobStatusOptions } from '@/modules/job-costing/enum/jobs'
  import { JobRangeFilter, LineItemRangeFilter, CustomerRangeFilter } from '@/components/range-filters'

  export default {
    components: {
      JobRangeFilter,
      LineItemRangeFilter,
      CustomerRangeFilter,
    },
    props: {
      value: {
        type: Object,
        default: () => ({
          type: [],
          period_from: 0,
          period_to: 12,
          job_status: [],
          job_number_from: '',
          job_number_to: '',
          category_id: [],
          phase_code_from: '',
          phase_code_to: '',
          cost_code_from: '',
          cost_code_to: '',
          change_order_from: '',
          change_order_to: '',
          job_type_ids: [],
          customer_code_from: '',
          customer_code_to: '',
          show_removed: 0,
          add_vendor_name: 0,
        }),
      },
    },
    data() {
      return {
        jobStatusOptions,
        transitionTypeOptions: [
          {
            label: this.$t('Cost Items'),
            value: jobLineItemTypes.Cost,
          },
          {
            label: this.$t('Retention Items'),
            value: jobLineItemTypes.Income,
          },
        ],
      }
    },
    computed: {
      jobTypes() {
        return this.$store.getters['globalLists/getResourceOptions'](this.$globalResources.JobCostTypes)
      },
    },
  }
</script>
