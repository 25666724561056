<template>
  <div class="report-wrapper">
    <portal to="page-title-actions">
      <ReportPresets
          v-bind="$attrs"
          ref="reportPresets"
          @on-apply="onApplyPreset"
      />
    </portal>

    <div
        v-if="preset?.id"
        class="border rounded mb-4"
    >
      <base-collapse-form
          :expanded.sync="sectionExpanded"
          :save-text="$t('Update Preset')"
          :loading="loading"
          layout="vertical"
          class="items-baseline"
          @submit="onUpdatePreset"
      >
        <template #title>
          <span class="font-medium leading-5 text-gray-700">
              {{ $t('Selected Preset') }}:
              {{ preset.name }}
          </span>
        </template>

        <div class="col-span-2 flex flex-col">
          <base-input
              v-model="preset.name"
              :label="$t('Preset Name')"
              rules="required"
          />
          <div class="flex space-x-4">
            <base-input
                v-model.number="preset.filters.start_row"
                :label="$t('Start Row')"
                rules="required|min_value:0"
                type="number"
                class="w-1/2"
            />
            <base-checkbox
                v-model="preset.public"
                :label="$t('Set Preset as Public')"
                id="public"
            />
          </div>
        </div>

        <AgDataTable
            :key="preset?.filters?.colNames?.length"
            :pagination="false"
            :columns="columns"
            :data="preset?.filters?.colNames || []"
            class="col-span-4"
        />

      </base-collapse-form>
    </div>

  </div>
</template>
<script>
  import axios from 'axios'
  import { formattedText } from '@/utils/utils'
  import { getCellClasses } from '@/components/ag-grid/columnUtils'
  import ReportPresets from '@/modules/common/components/reports/ReportPresets'
  import sortBy from "lodash/sortBy";

  export default {
    components: {
      ReportPresets,
    },
    data() {
      return {
        preset: {},
        sectionExpanded: true,
        activeReport: this.reportType,
        mappingOptions: [],
        mapping: [],
        loading: false,
      }
    },
    computed: {
      columns() {
        return [
          {
            headerName: this.$t('Column Name'),
            field: 'label',
            cellClass: params => getCellClasses(params, 'name'),
            editable: false,
          },
          {
            headerName: this.$t('Mapped Field'),
            field: 'mapping',
            editable: true,
            cellEditor: this.$cellEditors.BaseSelect,
            valueFormatter: params => {
              const op = this.mappingOptions.find(o => o.value === params.value)
              return op?.label
            },
            valueGetter: params => {
              const index = params.node.rowIndex + 1
              let matchingKey = ''
              const mapping = this.preset.filters.mapping
              for (let key in mapping) {
                if (mapping[key] === index) {
                  matchingKey = key
                  break
                }
              }
              let mappingKey = matchingKey || params.data.mapping
              const op = this.mappingOptions.find(o => o.key === mappingKey)
              return op?.value
            },
            valueSetter: params => {
              const newValue = this.mappingOptions.find(o => o.value === params.newValue)
              const oldValue = this.mappingOptions.find(o => o.value === params.oldValue)
              const newKey = newValue?.key
              const oldKey = oldValue?.key
              params.data.mapping = newKey
              if (!params.newValue) {
                params.data.mapping = null
              }
              const indexValue = params.node.rowIndex + 1
              if (newKey) {
                this.preset.filters.mapping[newKey] = indexValue
              }
              if (oldKey) {
                this.preset.filters.mapping[oldKey] = -1
              }
              return true
            },
            cellEditorParams: params => {
              return {
                rules: 'required',
                options: this.getMappingOptions(),
              }
            },
          },
        ]
      },
    },
    methods: {
      async onUpdatePreset() {
        try {
          this.loading = true

          let payload = this.preset

          const columns = this.preset?.filters?.colNames || []
          const mapping = {}
          columns.forEach(col => {
            const op = this.mappingOptions.find(o => o.key === col.mapping)
            let value = op?.value
            if (value === undefined) {
              value = -1
            }
            if (col?.mapping) {
              mapping[col.mapping] = value
            }
          })

          payload.filters['colNames'] = this.preset.filters.colNames

          const {data} = await axios.put(`/restify/report-presets/${this.preset?.id}`, payload)
          this.onApplyPreset(data)

          this.sectionExpanded = false
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not update the preset'))
        } finally {
          this.loading = false
        }
      },
      getMappingOptions() {
        const selectedOptions = this.preset?.filters?.colNames.map(m => m.mapping)
        return this.mappingOptions.map(o => {
          return {
            ...o,
            disabled: selectedOptions.includes(o.key) && o.key !== null,
          }
        })
      },
      onApplyPreset(preset) {
        this.preset = preset?.attributes
        this.preset.filters.colNames = this.preset.filters.colNames.map((col, index) => {
          return {
            id: index,
            ...col,
          }
        })
        this.$emit('change', preset?.attributes)
      },
      mapFilters(filters) {
        const { mapping, colNames } = filters || {}

        let mappingData = Object.keys(mapping).map(key => {
          let order = colNames?.findIndex(c => c.mapping === key)
          if (order === -1 && colNames?.length) {
            order = colNames?.length
          }
          return {
            id: Math.random(),
            value: mapping[key],
            label: formattedText(key),
            disabled: true,
            order,
            key,
          }
        })

        mappingData = sortBy(mappingData, 'order')
        this.mapping = mappingData

        this.mappingOptions = [{
          label: 'Skip',
          value: -1,
          key: null,
        }]

        this.mapping.forEach((option, index) => {
          this.mappingOptions.push({
            label: option.label,
            value: (index + 1),
            key: option.key,
          })
        })

      },
    },
    watch: {
      preset(data) {
        if (!data) {
          return
        }
        this.mapFilters(data.filters)
      },
    },
  }
</script>
