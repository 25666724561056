<template>
    <div class="mt-10 sm:mt-0">
        <base-form :save-text="$t('Update Organization')"
                   :loading="loading"
                   :show-buttons="$can('organization_update')"
                   grid-classes="grid grid-cols-8 gap-x-3"
                   @cancel="$emit('cancel')"
                   @submit="updateOrganization"
        >
            <template v-slot:title>
                <div class="flex items-center">
                    <span class="mr-1">{{data.name}}</span>
                    <status-badge :status="data.active ? $t('Active') : $t('Inactive')"/>
                </div>
            </template>
            <template v-slot:description>
                <div class="my-1">{{$t('General details and primary contact info of the organization')}}</div>
                <div>{{$t('Last Login: ')}} <em class="font-semibold">{{formattedLogin}}</em></div>
            </template>

            <div class="col-span-8 md:col-span-4 lg:col-span-2">
                <base-input v-model="model.subdomain"
                            :label="$t('Subdomain')"
                            :placeholder="$t('Subdomain')"
                            readonly
                            id="name">
                </base-input>
            </div>

            <div class="col-span-8 md:col-span-4 lg:col-span-6">
                <base-input v-model="model.name"
                            :label="$t('Name')"
                            :placeholder="$t('Organization Name')"
                            id="name"
                            rules="required|max:150">
                </base-input>
            </div>

          <div class="col-span-8 md:col-span-4 lg:col-span-4">
            <base-input v-model="model.contact_name"
                        :label="$t('Contact name')"
                        :placeholder="$t('Contact name')"
                        id="contact_name"/>
          </div>
          <div class="col-span-8 md:col-span-4 lg:col-span-4">
            <base-input v-model="model.contact_email"
                        :label="$t('Contact email')"
                        :placeholder="$t('Contact email')"
                        id="contact_email"
                        rules="email"
            />
          </div>
            <div class="col-span-8 md:col-span-4 lg:col-span-4">
                <base-phone-input v-model="model.phone"
                                  :label="$t('Primary Contact Phone')"
                                  id="phone"
                />
            </div>
            <div class="col-span-8 md:col-span-4 lg:col-span-4">
                <base-phone-input v-model="model.fax"
                                  :label="$t('Fax')"
                                  id="fax"
                />
            </div>
            <!--Change to v-if="true" when the endpoint on backend is updated to support addresses-->
            <address-list v-if="false"
                          class="col-span-8 mt-4"
                          entity="organisation"
                          :entityId="model.id"
                          ref="addressList"
                          :data="model.addresses"
                          @refresh="$emit('refresh')">
            </address-list>
        </base-form>
        <template v-if="!isProduction">
            <OrganizationSettingsForm class="my-8"/>
        </template>
    </div>
</template>
<script>
import StatusBadge from '@/components/common/StatusBadge'
import AddressList from '@/modules/common/components/AddressList'
import OrganizationService from '@/modules/settings/services/OrganizationService'
import OrganizationSettingsForm from '@/modules/settings/components/OrganizationSettingsForm'

export default {
    components: {
        StatusBadge,
        AddressList,
        OrganizationSettingsForm,
    },
    props: {
        data: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            loading: false,
            model: {
                name: '',
                subdomain: '',
                address_1: '',
                address_2: '',
                city: '',
                state: '',
                phone: '',
                fax: '',
                last_login: '',
                active: false,
            },
        }
    },
    computed: {
        formattedLogin() {
            if (!this.model.last_login) {
                return this.$t('Unknown')
            }
            return this.$formatDate(this.model.last_login)
        }
    },
    watch: {
        data: {
            immediate: true,
            handler(value) {
                this.model = {
                    ...this.model,
                    ...value,
                }
            }
        }
    },
    methods: {
        async updateOrganization() {
            try {
                this.loading = true
                await OrganizationService.updateOrganization(this.model)
                this.$success(this.$t('Organization updated'))
                this.$emit('save')
            } catch (err) {
                this.$error(this.$t('Could not update the organization settings'))
            } finally {
                this.loading = false
            }
        },
    }
}
</script>
