<template>
  <BaseFormDialog
    v-bind="$attrs"
    v-on="$listeners"
    :title="title"
    :appendToBody="true"
    size="sm"
  >
    <BaseForm
      :saveText="$t('Update')"
      :showCancel="true"
      :loading="loading"
      layout="modal"
      @submit="onSubmit"
      @cancel="$emit('close')"
    >
      <BaseInput
        v-model="model.number"
        :name="$t('Number')"
        :label="$t('Number')"
        :placeholder="$t('Number')"
        rules="required"
        type="number"
        id="number"
        class="col-span-12"
      />

      <BaseAlert
        v-if="currentNumber && model.number != currentNumber"
        type="warning"
        class="col-span-12 -mt-2 mb-2"
      >
        <span v-html="newNumberWarning" />
      </BaseAlert>
    </BaseForm>
  </BaseFormDialog>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'
import axios from 'axios'
import { Bank } from '@/modules/common/types/models'
import Data = API.Data;
import BankBlankChecksPrintDialog from "@/modules/settings/components/BankBlankChecksPrintDialog.vue";
import { PrinterIcon } from "vue-feather-icons";
import { getLastCheckNumber, getLastDirectDepositNumber } from "@/modules/settings/utils/bankUtils";

export default defineComponent({
  components: {
    BankBlankChecksPrintDialog,
    PrinterIcon
  },
  props: {
    bank: {
      type: Object as PropType<Data<Bank>>,
      required: true,
    },
    field: {
      type: String, // last-check-number | last-direct-deposit-number
      required: true
    }
  },
  data() {
    return {
      loading: false,
      model: {
        number: 0,
      },
    }
  },
  computed: {
    title() {
      if (this.field === 'last-check-number') {
        return this.$t('Edit Last Check Number')
      }

      return this.$t('Edit Last Direct Deposit Number')
    },
    currentNumber() {
      if (this.field === 'last-check-number') {
        return getLastCheckNumber(this.bank) || 0
      }

      return getLastDirectDepositNumber(this.bank) || 0
    },
    newNumberWarning() {
      const numberOnModel = Number(this.model.number)
      const currentNumber = this.currentNumber

      if (numberOnModel < currentNumber) {
        return this.$t('Setting a smaller number might cause collisions with existing invoice payments.')
      }

      if (numberOnModel > currentNumber) {
        return this.$t('Setting a greater number might create gaps in invoice payments sequencing.')
      }

      return ''
    },
    actionUrl() {
      if (this.field === 'last-check-number') {
        return `/restify/banks/${this.bank.id}/actions?action=update-last-check-number`
      }

      return `/restify/banks/${this.bank.id}/actions?action=update-last-direct-deposit-number`
    }
  },
  methods: {
    async onSubmit() {
      try {
        this.loading = true
        await axios.post(this.actionUrl, this.model)
        this.$store.dispatch('settings/getBank', this.bank.id)
        this.$emit('close')
      }
      catch (err: any) {
        if (err.handled) {
          return
        }
        this.$error(this.$t('Something went wrong please try again.'))
      }
      finally {
        this.loading = false
      }
    },
  },
  created() {
    this.model.number = this.currentNumber
  }
})
</script>
