<template>
  <AccountForm
      :data="currentAccount"
      grid-classes="grid grid-cols-8 gap-x-3"
      layout="vertical"
  />
</template>
<script>
  import AccountForm from '@/modules/ledger/components/AccountForm.vue'

  export default {
    components: {
      AccountForm,
    },
    computed: {
      currentAccount() {
        return this.$store.state.generalLedger.currentAccount || {}
      },
    },
  }
</script>
