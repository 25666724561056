<template>
  <div>
    <BaseDataTable
        ref="table"
        :columns="columns"
        :default-filters="false"
        :open-entity-in-new-tab="openEntityInNewTab"
        :add-text="$t('New role')"
        actions="search,refresh"
        hide-actions="view"
        permission="company_roles"
        url="/restify/company-roles"
        @add="onAdd"
        @edit="onRowEdit">
      <template v-slot:attributes.name="{row}">
        {{ formatRoleName(row.attributes.name) }}
      </template>
      <template v-slot:permission_groups="{row}">
        <div class="overflow-auto">
          <base-tooltip v-for="group in getPermissionGroups(row)"
                        :content="$t(`Contains ${group.count} permissions for ${group.name}`)"
                        :key="group.name">
            <StatusBadge :status="`${group.name} (${group.count})`"
                         class="ml-2"
            />
          </base-tooltip>
        </div>
      </template>
      <template v-slot:total_permissions="{row}">
        <StatusBadge :status="row.attributes.permissions.length.toString()"/>
      </template>
    </BaseDataTable>
    <BaseFormDialog v-if="showAddDialog"
                    :title="$t('Add Role')"
                    ref="addDialog"
                    :open.sync="showAddDialog">
      <RoleForm layout="modal"
                @cancel="$refs.addDialog.onCancel()"
                @save="onCreate"
      />
    </BaseFormDialog>
    <BaseFormDialog v-if="showEditDialog"
                    :title="$t('Edit Role')"
                    ref="dialog"
                    :open.sync="showEditDialog"
                    @close="refreshData()">
      <RoleForm layout="modal"
                :data="rowToEdit"
                @cancel="$refs.dialog.onCancel();"
                @save="onUpdate"
      />
    </BaseFormDialog>
  </div>
</template>
<script>
  import { Tooltip } from 'element-ui'
  import groupBy from 'lodash/groupBy'
  import startCase from 'lodash/startCase'
  import camelCase from 'lodash/camelCase'
  import StatusBadge from '@/components/common/StatusBadge'
  import RoleForm from '@/modules/settings/components/RoleForm'

  export default {
    components: {
      RoleForm,
      StatusBadge,
      [Tooltip.name]: Tooltip,
    },
    data() {
      return {
        openEntityInNewTab: false,
        rowToEdit: {},
        showEditDialog: false,
        showAddDialog: false,
      }
    },
    computed: {
      columns() {
        return [
          {
            label: this.$t('Name'),
            prop: 'attributes.name',
            maxWidth: 190,
          },
          {
            label: this.$t('Permission Count'),
            prop: 'total_permissions',
            maxWidth: 190,
          },
        ]
      },
    },
    methods: {
      formatRoleName(name) {
        return startCase(camelCase(name))
      },
      getPermissionGroups(row) {
        const groups = groupBy(row.attributes.permissions, 'attributes.group')
        return Object.keys(groups)
            .map(name => {
              const count = groups[name].length
              return {
                name: this.formatRoleName(name),
                count,
              }
            })
      },
      onAdd() {
        this.showAddDialog = true
      },
      onRowEdit(row) {
        this.rowToEdit = row
        this.showEditDialog = true
      },
      onCreate() {
        this.showAddDialog = false
        this.refreshData()
      },
      onUpdate() {
        this.showEditDialog = false
        this.refreshData()
      },
      refreshData() {
        this.$refs.table.refresh()
      },
    },
  }
</script>
