<template>
  <span class="capitalize">
    {{ getValue }}
  </span>
</template>
<script>
  import get from 'lodash/get'

  export default {
    props: {
      column: {
        type: Object,
        default: () => ({}),
      },
      row: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      getValue() {
        let value = get(this.row, this.column.prop, '')
        if (!value) {
          return ''
        }
        //Remove special characters like (/,-,_) to make the text more readable
        value = value.replace(/[^\w\s]/gi, ' ')
        return value
      },
    },
  }
</script>
