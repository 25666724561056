<template>
    <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
         :class="{'bg-gray-50': variant === 'gray'}"
    >
        <dt class="text-sm leading-5 font-medium text-gray-500">
            <slot name="left">
                {{title}}
            </slot>
        </dt>
        <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
            <slot name="right">
                {{description}}
            </slot>
        </dd>
    </div>
</template>
<script>
export default {
    name: 'BaseListItem',
    props: {
        variant: {
            type: String,
            default: 'white'
        },
        title: String,
        description: String,
    }
}
</script>
<style>
</style>
