import i18n from '@/i18n'

export const codes = [
  {
    label: i18n.t('GL - General Journal'),
    value: 'GL',
  },
  {
    label: i18n.t('JC - Job Cost/Income Journal'),
    value: 'JC',
  },
  {
    label: i18n.t('AP - Purchase Journal'),
    value: 'AP',
  },
  {
    label: i18n.t('CD - Cash Disbursment Journal'),
    value: 'CD',
  },
  {
    label: i18n.t('PR - Payroll Journal'),
    value: 'PR',
  },
  {
    label: i18n.t('AR - Sales Journal'),
    value: 'AR',
  },
  {
    label: i18n.t('CR - Cash Receipts Journal'),
    value: 'CR',
  },
  {
    label: i18n.t('EQ - Equipment Journal'),
    value: 'EQ',
  },
  {
    label: i18n.t('PO - Purchase Order Journal'),
    value: 'PO',
  },
  {
    label: i18n.t('IV - Inventory Journal'),
    value: 'IV',
  },
  {
    label: i18n.t('SB - Service Billing Journal'),
    value: 'SB',
  },
]
