<template>
  <div>
    <base-go-to-edit-alert
        v-if="invoiceAlreadyExists"
        :path="`/accounts-payable/invoices/${invoice.id}/edit`"
        message="Invoice exists in open payable file. Please select another number or vendor, or edit."
    />

    <base-alert
        v-else-if="selectedVendor.status === resourceStatuses.Inactive"
        :type="$promptType.Warning"
        class="mb-4"
        closable
    >
      {{ $t('Warning...Vendor is inactive, you can edit/activate vendor by click on edit button next to the select.') }}
    </base-alert>

    <base-alert
        v-else-if="hasExpirationDates"
        :type="$promptType.Warning"
        class="mb-4"
        closable
    >
      <div class="flex items-center">
        {{ $t('Warning...') }}
      </div>
      <div class="flex flex-col ml-5">
        <span v-for="message in expirationMessages" :key="message">
          {{ message }}
        </span>
      </div>
    </base-alert>

    <base-alert
        v-else-if="systemDateWarning"
        :type="$promptType.Warning"
        class="mb-4"
        closable
    >
      {{ systemDateWarning }}
    </base-alert>

    <base-alert
        v-else-if="purchaseOrderTotalAmount"
        :type="$promptType.Warning"
        class="mb-4"
        closable
    >
      {{ $t('Warning...Gross Invoice amount exceeds P.O. remaining amount by:') }}
      <b>{{ getExceedsPoAmount }}</b>
    </base-alert>

  </div>
</template>
<script>
  import parseISO from 'date-fns/parseISO'
  import { resourceStatuses } from '@/enum/enums'
  import formatDistanceToNow from 'date-fns/formatDistanceToNow'
  import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'

  let date = new Date()
  date.setDate(date.getDate() + 30)
  const NEXT_MONTH_TIME = date.getTime()

  export default {
    props: {
      selectedVendor: {
        type: Object,
        default: () => ({}),
      },
      invoiceAlreadyExists: {
        type: Boolean,
        default: false,
      },
      invoiceId: [Number, String],
      invoice: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        resourceStatuses,
        dateKeys: ['expiration_date', 'workers_compensation', 'general_liability'],
        dateMappings: {
          expiration_date: {
            label: this.$t('Expiration Date'),
            value: 'expiration_date',
          },
          workers_compensation: {
            label: this.$t('Workers Compensation Date'),
            value: 'workers_compensation',
          },
          general_liability: {
            label: this.$t('General Liability Date'),
            value: 'general_liability',
          },
        },
      }
    },
    computed: {
      purchaseOrderTotalAmount() {
        if (!this.invoice.purchase_order_id) {
          return 0
        }
        const { total_amount, invoiced_amount } = this.$store.state.globalLists.purchaseOrders.find(po => po.id === this.invoice.purchase_order_id) || 0
        return total_amount - invoiced_amount
      },
      getExceedsPoAmount() {
        return this.$formatPrice(this.purchaseOrderTotalAmount - this.invoice.gross_amount)
      },
      expirationMessages() {
        let messages = []
        this.dateKeys.forEach(key => {

          const dateValue = this.selectedVendor[key]
          const valueLabel = this.dateMappings[key].label

          if (this.isDateExpired(dateValue)) {

            let timeAgo = formatDistanceToNow(parseISO(dateValue))
            messages.push(`${valueLabel} expired ${timeAgo} ago (${this.$formatDate(dateValue)})`)

          } else if (!this.isDateExpired(dateValue) && this.willDateExpireThisMonth(dateValue)) {

            let timeAgo = formatDistanceToNow(parseISO(dateValue))
            messages.push(`${valueLabel} will expire in ${timeAgo} (${this.$formatDate(dateValue)})`)
          }
        })
        return messages
      },
      hasExpirationDates() {
        if (!this.selectedVendor?.id) {
          return false
        }
        return this.dateKeys.some(key => {
          return this.willDateExpireThisMonth(this.selectedVendor[key]) || this.isDateExpired(this.selectedVendor[key])
        })
      },
      systemDateWarning() {
        if (!this.invoice.due_date) {
          return
        }

        const today = new Date()
        const dueDate = new Date(this.invoice.due_date)
        const formattedDueDate = this.$formatDate(dueDate)
        const daysDifference = differenceInCalendarDays(dueDate, today)

        if (daysDifference > 90) {
          return this.$t(`Invoice Due Date - ${formattedDueDate} is ${daysDifference} days after System Date.`)
        }

        if (daysDifference < -90) {
          const positiveDifference = daysDifference * -1
          return this.$t(`Invoice Due Date - ${formattedDueDate} is ${positiveDifference} days before System Date.`)
        }

        return false
      },
    },
    methods: {
      isDateExpired(dateToCompare) {
        if (!dateToCompare) {
          return false
        }

        return new Date(dateToCompare).getTime() < new Date().getTime()
      },
      willDateExpireThisMonth(dateToCompare) {
        if (!dateToCompare) {
          return false
        }

        return new Date(dateToCompare).getTime() < NEXT_MONTH_TIME
      },
    },
  }
</script>
