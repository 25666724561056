<template>
  <div class="col-span-6">
    <div class="flex items-end">
      <VendorSelect v-model="vendor_id"
                    v-focus
                    :url-params="urlParams"
                    :on-map-entry="onChangeVendor"
                    :show-open-amount="true"
                    :add-entity="false"
                    id="vendor"
                    inline-errors
                    clearable
      />
      <base-tooltip v-if="vendor_id"
                    :tabindex="-1"
                    :content="$t('Edit Vendor')">
        <base-button variant="white"
                     :tabindex="-1"
                     class="ml-2"
                     @click="showEditVendorDialog = true">
          <Edit3Icon class="w-5 h-5 text-primary-500"/>
        </base-button>
      </base-tooltip>
    </div>
    <BaseFormDialog v-if="showEditVendorDialog"
                    :title="$t('Edit Vendor')"
                    size="xl"
                    :open.sync="showEditVendorDialog">
      <VendorForm :redirectToListAfterStore="false"
                  :show-back="false"
                  :data="selectedVendor"
                  @save="onUpdateVendor"
                  @cancel="showEditVendorDialog = false"
      />
    </BaseFormDialog>
  </div>
</template>
<script>

  import { Edit3Icon } from 'vue-feather-icons'
  import VendorSelect from '@/components/select/entities/VendorSelect'
  import VendorForm from '@/modules/accounts-payable/components/VendorForm'

  export default {
    components: {
      Edit3Icon,
      VendorForm,
      VendorSelect,
    },
    data() {
      return {
        urlParams: {
          sort: '-current_ap_amount',
          related: 'addresses'
        },
        showEditVendorDialog: false,
        vendor_id: null,
        selectedVendor: {},
      }
    },
    methods: {
      onChangeVendor(vendor) {
        this.selectedVendor = vendor?.attributes
        this.$emit('change', vendor)
      },
      onUpdateVendor(vendor) {
        this.onChangeVendor(vendor)
        this.showEditVendorDialog = false
      },
    },
  }
</script>
