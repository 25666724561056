<template>
  <div class="print:hidden">
    <nav :class="navClasses"
         class="flex space-x-4 overflow-x-scroll nav-bar"
         aria-label="Tabs"
    >
      <div v-for="tab in tabs"
           :key="tab[valueKey]"
           :class="{'active': tab[valueKey] === value}"
           role="button"
           class="inline-flex tab-button nav-item"
           @click="selectTab(tab)"
      >
        <BaseTooltip v-if="tab.tooltip" :content="tab.tooltip">
          <div>
            {{ labelFormat ? labelFormat($t(tab[labelKey])) : tab[labelKey] }}
          </div>
        </BaseTooltip>
        <div v-else>
          {{ labelFormat ? labelFormat($t(tab[labelKey]), tab) : tab[labelKey] }}
        </div>
      </div>
    </nav>
    <slot/>
  </div>
</template>
<script>
  export default {
    props: {
      tabs: {
        type: Array,
        default: () => [],
      },
      value: {
        type: [String, Number],
        default: '',
      },
      valueKey: {
        type: String,
        default: 'name',
      },
      labelKey: {
        type: String,
        default: 'name',
      },
      labelFormat: Function,
      navClasses: [Object, String, Array],
    },
    methods: {
      selectTab(tab) {
        this.$emit('input', tab[this.valueKey])
      },
    },
  }
</script>
<style scoped>
  .tab-button {
    white-space: nowrap;
  }
</style>
