<template>
  <div>
    <ManageTimeCards
        :read-only="true"
        :add-entity-in-new-tab="true"
        :add-entity-url-query="getAddUrl"
        :days="days"
    />
  </div>
</template>
<script lang="ts" setup>
  import { computed } from 'vue'
  import { useRoute } from 'vue2-helpers/vue-router';
  import ManageTimeCards from "@/modules/payroll/components/timecard/ManageTimeCards.vue";
  import { getTimeCardBatchDays } from "@/modules/payroll/utils/timeCardUtils";

  const props = defineProps({
    batch: {
      type: Object,
      default: () => ({}),
    }
  })
  const route = useRoute()

  const getAddUrl = computed<string>(() => {
    const id = route.params.id
    return id ? `/payroll/timecard-batches/${id}/add` : `/payroll/timecards/add`
  })

  const days = computed(() => {
    return getTimeCardBatchDays(props.batch.period_end_date)
  })
</script>
