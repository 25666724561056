<template>
  <BaseTooltip
    v-if="recurringInvoiceId"
    :content="$t('This invoice was created based on a recurring payable')">
    <RouterLink :to="`/accounts-payable/recurring-invoices/${recurringInvoiceId}/view`">
      <RecurringInvoiceIcon class="w-5 h-5"/>
    </RouterLink>
  </BaseTooltip>
</template>
<script>
import RecurringInvoiceIcon from "@/modules/accounts-payable/pages/invoices/RecurringInvoiceIcon.vue";

export default {
  components: {
    RecurringInvoiceIcon
  },
  props: {
    invoice: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    recurringInvoiceId() {
      return this.invoice?.attributes?.recurring_invoice_id
    }
  }
}
</script>
