import { globalResources, storeActions } from '@/components/form/util'

const repositoriesMap = {
  [globalResources.Jobs]: {
    component: () => import('@/modules/job-costing/components/JobForm'),
    actionTitle: 'Add new Job',
    refreshDataAction: storeActions[globalResources.Jobs],
  },
  [globalResources.Customers]: {
    component: () => import('@/modules/accounts-receivable/components/CustomerForm.vue'),
    actionTitle: 'Add new Customer',
    refreshDataAction: storeActions[globalResources.Customers],
  },
  [globalResources.Vendors]: {
    component: () => import('@/modules/accounts-payable/components/VendorForm.vue'),
    actionTitle: 'Add new Vendor',
    refreshDataAction: storeActions.refreshResource,
  },
  [globalResources.CostLineItems]: {
    component: () => import('@/modules/job-costing/components/line-items/CostLineItemForm'),
    actionTitle: 'Add new Cost Line Item',
    refreshDataAction: storeActions[globalResources.CostLineItems],
  },
  [globalResources.Materials]: {
    component: () => import('@/modules/purchasing-inventory/components/MaterialForm'),
    actionTitle: 'Add new Material',
    refreshDataAction: storeActions.refreshResource,
  },
  [globalResources.Equipments]: {
    component: () => import('@/modules/equipment/components/EquipmentForm'),
    actionTitle: 'Add new Equipment',
    refreshDataAction: storeActions.refreshResource,
  },
  [globalResources.CraftCodes]: {
    component: () => import('@/modules/payroll/components/codes/CraftCodeForm.vue'),
    actionTitle: 'Add new Craft Code',
    refreshDataAction: storeActions.refreshResource,
  },
  [globalResources.DepartmentCodes]: {
    component: () => import('@/modules/payroll/components/codes/DepartmentCodeForm.vue'),
    actionTitle: 'Add new Department Code',
    refreshDataAction: storeActions.refreshResource,
  },
  [globalResources.WorkOrders]: {
    component: () => import('@/modules/service-billing/components/WorkOrdersForm'),
    actionTitle: 'Add new Work Order',
    refreshDataAction: storeActions.refreshResource,
  },
  [globalResources.CompanyAddresses]: {
    component: () => import('@/modules/settings/components/CompanyAddressForm'),
    actionTitle: 'Add new Address',
    refreshDataAction: storeActions[globalResources.CompanyAddresses],
  },
  [globalResources.SalesTaxDistricts]: {
    component: () => import('@/modules/accounts-receivable/components/settings/SalesTaxDistrictsForm.vue'),
    actionTitle: 'Add new Sales Tax District',
    refreshDataAction: storeActions.refreshResource
  },
  default: '',
}
export const authorizedToAdd = (repo) => {
  return !!getAddResourceConfig(repo)
}

export const getAddResourceConfig = (repo) => {
  return repositoriesMap[repo]
}
