export const barChartSeriesConfig = {
  type: 'bar',
  barGap: 0,
  emphasis: {
    focus: 'series'
  },
  itemStyle: {
    borderRadius: [5, 5, 0, 0],
  },
}
