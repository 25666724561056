<template>

  <base-report-form
      :filters="model"
      :report-number="reportNumber"
  >

    <template #filters>

      <common-report-filters v-model="model"/>

      <base-filter-row :title="$t('Transaction Dates')">
        <date-range-filter
            v-model="model"
            startDateRequired
            endDateRequired
            class="col-span-4"
        />
      </base-filter-row>

      <base-filter-row>
        <base-checkbox
            v-model="model.sort_by_category"
            :label="$t('Sort by Category')"
            class="mb-2 col-span-3"
            id="sort_by_category"
        />
      </base-filter-row>

    </template>

    <template #table="{loading, data}">

      <daily-job-cost-detail-report-table
          :data="data"
          :filters="model"
          :report-number="reportNumber"
          :data-loading="loading"
          ref="reportTable"
      />

    </template>
  </base-report-form>

</template>
<script>
  import { DateRangeFilter } from '@/components/range-filters'
  import { commonFilters } from '@/modules/job-costing/components/reports/util'
  import CommonReportFilters from '@/modules/job-costing/components/reports/CommonReportFilters'
  import DailyJobCostDetailReportTable from '@/modules/job-costing/components/reports/DailyJobCostDetailReportTable'

  export default {
    components: {
      DateRangeFilter,
      CommonReportFilters,
      DailyJobCostDetailReportTable,
    },
    data() {
      return {
        reportNumber: '2871',
        model: {
          ...commonFilters,
          start_date: null,
          end_date: this.$now,
          sort_by_category: false,
        },
      }
    },
  }
</script>
