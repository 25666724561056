// * aged by reports
import i18n from "@/i18n";
import store from "@/store";
import { $modules } from "@/enum/enums";
import { defaultColors, ReportShadingTypesDefaults } from "@/modules/common/components/reports/reportShadingTypes";

export function computedRetentionAmount(data, toPrice = true) {
  const total = Object.values(data).reduce((acc, value) => acc + value, 0)

  if (!toPrice) {
    return total
  }

  return app.$formatPrice(total)
}

const actualDaysColumn = {
  label: i18n.t('Actual <br> Days'),
  prop: 'actual_days',
  align: 'right',
  minWidth: 60,
  maxWidth: 100,
}

export function agedByColumns(days, agingTotals, grandTotals, module, showActualDaysColum = false) {

  let columns = [
    {
      label: i18n.t(`0 to ${days[0]} <br> Days`),
      prop: 'aging_totals.30',
      component: 'FormattedPrice',
      align: 'right',
      minWidth: 100,
      maxWidth: 120,
      summary: () => `${app.$formatPrice(agingTotals['30'])}<br><br>&nbsp`,
    },
    {
      label: i18n.t(`${(+days[0]) + 1} to ${days[1]} <br> Days`),
      prop: 'aging_totals.60',
      component: 'FormattedPrice',
      align: 'right',
      minWidth: 100,
      maxWidth: 120,
      summary: () => `${app.$formatPrice(agingTotals['60'])}<br><br>&nbsp`,
    },
    {
      label: i18n.t(`${(+days[1]) + 1} to ${days[2]} <br> Days`),
      prop: 'aging_totals.90',
      component: 'FormattedPrice',
      align: 'right',
      minWidth: 100,
      maxWidth: 120,
      summary: () => {
        const total = app.$formatPrice(agingTotals['90'])
        if (!grandTotals.open_amount) {
          return `${total}<br><br>&nbsp`
        }
        return `${total}<br><br>${i18n.tc(`Retention ${module}:`)}`
      },
    },
    {
      label: i18n.t(`Over ${days[2]} <br> Days`),
      prop: 'aging_totals.90+',
      component: 'FormattedPrice',
      align: 'right',
      minWidth: 100,
      maxWidth: 120,
      summary: () => `${app.$formatPrice(agingTotals['90+'])}<br><br>&nbsp`,
    },
    {
      label: i18n.tc(`Total <br> ${module}`),
      prop: 'total',
      component: 'FormattedPrice',
      align: 'right',
      minWidth: 100,
      maxWidth: 120,
      summary: () => {
        const total = computedRetentionAmount(agingTotals)
        if (!grandTotals.open_amount) {
          return `${total}<br><br>&nbsp`
        }
        let retentionField = grandTotals.retention_amount
        if (module === 'Receivable') {
          retentionField = grandTotals.retention_receivable
        }
        return `${app.$formatPrice(grandTotals.open_amount)}<br><br>${app.$formatPrice(retentionField)}`
      },
    },
  ]


  if (!showActualDaysColum) {
    return columns
  }

  columns.splice(0, 0, actualDaysColumn)

  return columns
}


export function useReportShading() {
  const companyShading = store.getters['company/getSettingValue']($modules.GENERAL, 'shading') || ReportShadingTypesDefaults // * reports shading setting
  const companyShadeReports = store.getters['company/getSettingValue']($modules.GENERAL, 'shade_reports') || false // * apply shading setting to reports

  const userCompanySettings = store.getters['auth/userCompanySettings'].map(setting => setting.attributes)
  const userShading = userCompanySettings.find(setting => setting.key === 'shading')
  const userShadeReports = userCompanySettings.find(setting => setting.key === 'shade_reports')

  let reportShadingSettings = companyShading
  const shadeReports = companyShadeReports

  Object.keys(reportShadingSettings).forEach(key => {
    reportShadingSettings[key]['color1'] = reportShadingSettings[key]['color1'] || defaultColors[key]
    reportShadingSettings[key]['color2'] = reportShadingSettings[key]['color2'] || defaultColors[key]
  })

  const userShadingId = userShading?.id
  const userShadeReportsId = userShadeReports?.id

  return {
    reportShadingSettings,
    shadeReports,
    // for update user company settings purpose
    userShadingId,
    userShadeReportsId,
  }
}
