<template>
  <div class="report-page">
    <layout-tabs :items="filteredItems"/>
    <router-view/>
  </div>
</template>
<script>

  export default {
    data() {
      return {
        items: [
          {
            name: this.$t('History Selections'),
            path: '/ledger/reports/history-selections',
            permission: 'general_ledger_reports',
          },
          {
            name: this.$t('Job & Other Distributions'),
            path: '/ledger/reports/job-and-other-distributions',
            permission: 'general_ledger_reports',
          },
          {
            name: this.$t('Check / Deposit Register'),
            path: '/ledger/reports/check-deposit-register',
            permission: 'general_ledger_reports',
          },
          {
            name: this.$t('General Ledger'),
            path: '/ledger/reports/general-ledger',
            permission: 'general_ledger_reports',
          },
          {
            name: this.$t('Account Listing'),
            path: '/ledger/reports/account-listing',
            permission: 'general_ledger_reports',
          },
          {
            name: this.$t('Sub-Accounts / Budgets'),
            path: '/ledger/reports/sub-accounts-budgets',
            permission: 'general_ledger_reports',
          },
          {
            name: this.$t('Budget To Actual Compare'),
            path: '/ledger/reports/budget-to-actual-compare',
            permission: 'general_ledger_reports',
          },
          {
            name: this.$t('Account Balance Comparison'),
            path: '/ledger/reports/account-balance-comparison',
            permission: 'general_ledger_reports',
          },
          {
            name: this.$t('Trial Balance'),
            path: '/ledger/reports/trial-balance',
            permission: 'general_ledger_reports',
          },
          // We don't need to show this as it's not fully ready yet
          // {
          //   name: this.$t('Financial Reports'),
          //   path: '/ledger/reports/financial-reports',
          //   permission: 'general_ledger_reports',
          // },
          {
            name: this.$t('Journals'),
            path: '/ledger/reports/journals',
            permission: 'general_ledger_reports',
          },
          {
            name: this.$t('Financial Reports'),
            path: '/ledger/reports/financial-reports',
            permission: 'general_ledger_reports',
          },
        ],
      }
    },
    computed: {
      filteredItems() {
        return this.items.filter(item => this.$can(item.permission))
      },
      reportType() {
        return this.$route.path.replace('/ledger/reports/', '')
      },
    },
  }
</script>
