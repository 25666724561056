<template>
  <div>
    <layout-tabs :items="filteredItems"/>
    <router-view/>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        items: [
          {
            name: this.$t('Customer Payments'),
            path: `/accounts-receivable/payments/create`,
            permission: 'billing_payments_store',
          },
          {
            name: this.$t('Payment Batches'),
            path: `/accounts-receivable/payments/batches`,
            permission: 'billing_payments_show',
          },
          {
            name: this.$t('Payment History'),
            path: `/accounts-receivable/payments/all`,
            permission: 'billing_payments_show',
          },
          {
            name: this.$t('Clear Joint Checks'),
            path: `/accounts-receivable/payments/clear-joint-checks`,
            permission: 'billing_payments_store',
          },
        ],
      }
    },
    computed: {
      filteredItems() {
        return this.items.filter(item => this.$can(item.permission))
      },
    },
  }
</script>
