<template>
  <div>
    <BaseDataTable
        :columns="columns"
        :url="url"
        :url-params="allParams"
        :is-expandable="isExpandable"
        :default-match-filters="defaultMatchFilters"
        :select-row-filter="row => !isRowSelectionDisabled(row)"
        :add-entity-in-new-tab="addEntityInNewTab"
        :add-entity-url-query="addEntityUrlQuery"
        :base-entity-path="baseEntityPath"
        :enable-all-select="false"
        :group-by.sync="selectedGroupBy"
        :add-text="addText"
        :actions="actions"
        v-bind="tableProps"
        hide-actions="view,edit"
        ref="table"
        permission="billings"
        entity="billings"
        @all-selected="onAllSelected"
        @delete="refreshTable"
        @data-fetch="billings = $event"
    >
      <template
          v-if="(isProgressBilling || isUnitPriceBilling) && actionPermission('bulkSelection')"
          #print-action
      >
        <BillingPrintAction
            :billings="selectedRowIds"
            :billing-type="billingType"
            class="w-full billing-print-action"
        />
      </template>
      <template #job="{row}">
        <JobLink v-if="get(row, 'attributes.job_id')"
                 :data="get(row, 'relationships.job')"/>
        <span v-else></span>
      </template>
      <template #workOrder="{row}">
        <work-order-link
            :data="row.relationships.workOrder"
            :show-description="false"
        />
      </template>
      <template #customer="{row}">
        <customer-link :data="row.relationships.customer"/>
      </template>
      <template #attributes.status="{row}">
        <invoice-status-popover :invoice="row"
                                resource-name="billings"/>
      </template>
      <template #attributes.gross_amount="{row}">
        {{ $formatPrice(getAmount(row)) }}
      </template>
      <template #additional-actions>
        <template v-if="billingType === billingTypes.CostPlus">
          <base-button
              :class="{'mr-2': actionPermission('postBulk')}"
              size="md"
              variant="white"
              @click="$router.push('/accounts-receivable/billings/cost-plus/add')"
          >
            <IconAdd class="w-4 h-4"/>
            {{ $t('Add Invoice') }}
          </base-button>
        </template>
        <void-button v-if="actionPermission('voidBulk')"
                     :disabled="voidBulkDisabled"
                     :loading="voidingProgress"
                     size="xs"
                     variant="gray-link"
                     @click="showVoidDialog = true"
        >
          {{ $t('Void') }}
        </void-button>
        <ProofListingButton
            v-if="actionPermission('postBulk') && getProofListingPath && $can('billings_update')"
            :disabled="postableSelectedRows.length === 0"
            :selected-rows="postableSelectedRows"
            :path="getProofListingPath"
        />
      </template>
      <template #select="{row}">
        <base-checkbox v-model="row.selected"
                       :disabled="isRowSelectionDisabled(row)"/>
      </template>
      <template #attributes.number="{row}">
        <div>
          <div class="flex items-center">
            <postable-tooltip
                v-if="billingStatus === resourceStatuses.Pending"
                :is-postable="$isAuthorized($actionPolicies.Post, row)"
            />
            <router-link :to="getViewPath(row)">
              {{ row.attributes.number }}
            </router-link>
          </div>
        </div>
      </template>
      <template #expand-content="{row}">
        <BillingDetails
            :billing-type="getBillingType(row.originalRow)"
            :billing="row.originalRow"
            :fetch-entries="true"
        />
      </template>
      <template #extra-actions="{row}">
        <PostAction v-if="$isAuthorized($actionPolicies.Post, row) && !hasProofListing(row)"
                    :id="row.id"
                    entity-name="Billing"
                    resource-name="billings"
                    variant="gray-link"
                    size="xs"
                    @on-action-callback="refreshTable"
        />
        <ProofListingButton
            v-if="$isAuthorized('authorizedToPost', row) && hasProofListing(row) && $can('billings_update')"
            :selected-rows="[row]"
            :path="getProofListingPathByType(row)"
        />
        <VoidAction v-if="$isAuthorized($actionPolicies.Void, row) && $can('billings_update')"
                    :id="row.id"
                    entity-name="Billing"
                    resource-name="billings"
                    variant="gray-link"
                    size="xs"
                    @on-action-callback="refreshTable"
        />
      </template>
      <template v-slot:extra-actions-before="{row}">
        <TableViewButton :link="getViewPath(row)"/>
        <TableEditButton
            v-if="$isAuthorized($actionPolicies.Update, row) && $can('billings_update')"
            :link="getEditPath(row)"
            :open-entity-in-new-tab="true"
        />
      </template>
    </BaseDataTable>
    <BaseFormDialog v-if="showPostDialog"
                    :title="$t('Post selected billing(s)?')"
                    :open.sync="showPostDialog">
      <BasePostDialog @cancel="showPostDialog = false"
                      prompt-message="This will post the billing(s) entries to the corresponding job. Note that this action is irreversible."
                      @save="onPost"
      />
    </BaseFormDialog>
    <BaseFormDialog v-if="showVoidDialog"
                    :title="$t('Void selected billing(s)?')"
                    :open.sync="showVoidDialog">
      <BaseVoidDialog @cancel="showVoidDialog = false"
                      prompt-message="Note that this action is irreversible. We will still keep the invoices in our history"
                      @save="onVoid"
      />
    </BaseFormDialog>
  </div>
</template>
<script>

  import axios from 'axios'
  import cloneDeep from 'lodash/cloneDeep'
  import lowerCase from 'lodash/lowerCase'
  import {
    apiTypes,
    billingTypes,
    getBillingType,
    TABLE_ACTIONS,
    TABLE_COLUMNS,
    TABLE_URL_PARAMS,
  } from '@/modules/accounts-receivable/pages/billings/billings'
  import BillingDetails from '@/modules/accounts-receivable/pages/billings/BillingDetails'
  import { groupByType } from '@/components/table/utils/groupByTypes';
  import BillingPrintAction from '@/modules/accounts-receivable/layout/billings/BillingPrintAction.vue'
  import Cache from '@/utils/Cache'
  import { resourceStatuses } from '@/enum/enums';
  import { entityPreviewFields } from '@/modules/common/components/entity-preview/entities'

  export default {
    inheritAttrs: false,
    components: {
      BillingPrintAction,
      BillingDetails,
    },
    props: {
      displayTypeColumn: {
        type: Boolean,
        default: false,
      },
      defaultMatch: {
        type: Boolean,
        default: true,
      },
      billingStatus: String,
      billingType: String,
      urlParams: Object,
      tableProps: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        billingTypes,
        groupByType,
        selectedGroupBy: '',
        showPostDialog: false,
        showVoidDialog: false,
        postingProgress: false,
        voidingProgress: false,
        billings: [],
        isExpandable: true,
        allPagesSelected: true,
        addEntityInNewTab: true,
        resourceStatuses,
      }
    },
    computed: {
      addText() {
        const type = lowerCase(this.billingType)
        return this.$t(`New ${type} billing`)
      },
      addEntityUrlQuery() {
        if (this.tableProps.addEntityUrlQuery) {
          return this.tableProps.addEntityUrlQuery
        }
        let path = `/accounts-receivable/billings/${billingTypes.Progress}/add`

        if (this.billingType) {
          path = `/accounts-receivable/billings/${this.billingType}/add`
        }

        if (this.billingType === billingTypes.CostPlus) {
          path = `/accounts-receivable/billings/${this.billingType}/select-costs`
        }
        const url = new URL(path, window.location.origin); // Use a base for relative URL
        const searchParams = new URLSearchParams();
        const allowedKeys = ['customer_id', 'work_order_id', 'job_id']
        allowedKeys.forEach(key => {
          if (this.urlParams?.[key]) {
            searchParams.set(key, this.urlParams[key])
          }
        })
        url.search = searchParams.toString()
        return `${url.pathname}${url.search}`
      },
      baseEntityPath() {
        if (this.tableProps.baseEntityPath) {
          return this.tableProps.baseEntityPath
        }

        return `/accounts-receivable/billings/${this.billingType}`
      },
      url() {
        return '/restify/billings'
      },
      allParams() {
        if (this.tableProps.urlParams) {
          return {
            ...this.tableProps.urlParams,
            // * Make sure the status is always included in the params
            ...TABLE_URL_PARAMS[this.billingStatus],
          }
        }

        let params = {
          related: entityPreviewFields.Customer + ',' + entityPreviewFields.Job,
        }

        if (!this.billingType) {
          params = {
            ...params,
            ...TABLE_URL_PARAMS[this.billingStatus],
            ...this.urlParams,
          }
        }

        params = {
          ...params,
          type: apiTypes[this.billingType],
          ...TABLE_URL_PARAMS[this.billingStatus],
          ...this.urlParams,
        }

        return params
      },
      actions() {
        let actions = ['search', 'refresh', 'print', 'export']
        if (this.$can('billings_store')) {
          actions.push('add')
        }
        return actions.join(',')
      },
      getProofListingPath() {
        const pathMap = {
          [billingTypes.Progress]: '/accounts-receivable/billings/progress/proof-listing',
          [billingTypes.UnitPrice]: '/accounts-receivable/billings/unit-price/proof-listing',
          [billingTypes.LumpSum]: '/accounts-receivable/billings/lump-sum/proof-listing',
          [billingTypes.CostPlus]: '/accounts-receivable/billings/cost-plus/proof-listing',
        }
        return pathMap[this.billingType]
      },
      selectedRows() {
        return this.billings.filter(e => e.selected)
      },
      selectedRowIds() {
        return this.selectedRows.map(e => e.id)
      },
      defaultMatchFilters() {
        if (!this.defaultMatch) {
          return {}
        }

        return this.createByMeFilter
      },
      columns() {
        if (this.tableProps.columns) {
          return this.tableProps.columns
        }

        let baseColumns = TABLE_COLUMNS[this.billingStatus]
        baseColumns = cloneDeep(baseColumns)

        if (this.billingType === billingTypes.LumpSum) {
          const jobColumnIndex = baseColumns.findIndex(column => column.prop === 'job')

          if (jobColumnIndex !== -1) {
            baseColumns.splice(jobColumnIndex, 1)
          }
        }

        if (this.displayTypeColumn) {
          const typeColumn = {
            prop: 'attributes.type',
            label: this.$t('Type'),
            maxWidth: 100,
            component: 'Status',
            align: 'center',
          }
          baseColumns.splice(3, 0, typeColumn)
        }

        return baseColumns
      },
      filteredByCustomer() {
        const { customer_id } = this.urlParams || {}
        return !!customer_id;
      },
      voidBulkDisabled() {
        return !this.selectedRows.some(row => row.meta[this.$actionPolicies.Void])
      },
      postBulkDisabled() {
        return !this.selectedRows.some(row => row.meta[this.$actionPolicies.Post])
      },
      postableSelectedRows() {
        return this.selectedRows.filter(row => row.meta[this.$actionPolicies.Post])
      },
      isProgressBilling() {
        return this.billingType === billingTypes.Progress
      },
      isUnitPriceBilling() {
        return this.billingType === billingTypes.UnitPrice
      }
    },
    methods: {
      hasProofListing(row) {
        const type = this.getBillingType(row)
        return [billingTypes.Progress, billingTypes.UnitPrice, billingTypes.LumpSum, billingTypes.CostPlus].includes(this.getBillingType(row))
      },
      getAmount(row) {
        const type = this.getBillingType(row)
        if (type === billingTypes.Service && row.attributes.status === resourceStatuses.Pending) {
          return row.attributes.distribution_amount
        }
        return row.attributes.gross_amount
      },
      getProofListingPathByType(row) {
        const type = this.getBillingType(row)
        return `/accounts-receivable/billings/${type}/proof-listing`
      },
      actionPermission(action) {
        return TABLE_ACTIONS[`${this.billingStatus}`].includes(action)
      },
      onAllSelected(value) {
        this.allPagesSelected = value
      },
      async onPost(data) {
        try {
          this.postingProgress = true
          this.showPostDialog = false
          const repositories = this.selectedRowIds
          const payload = {
            repositories,
            ...data,
          }
          await axios.post('/restify/billings/actions?action=post-billings', payload)
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t(`Could not post billing(s)`))
        } finally {
          this.postingProgress = false
          this.refreshTable()
          await this.refreshGlobalLists()
        }
      },
      async refreshGlobalLists() {
        Cache.removeForEntity('progress-prg')
        Cache.removeForEntity('jobs')
        await Promise.all([
          this.$store.dispatch('globalLists/getJobs'),
          this.$store.dispatch('globalLists/getLineItemsConfig', true),
        ])
      },
      isRowSelectionDisabled(row) {
        const { meta } = row
        if (!meta) {
          return false
        }
        return !meta[this.$actionPolicies.Post] && !meta[this.$actionPolicies.Void]
      },
      refreshTable() {
        this.allPagesSelected = false
        this.$refs?.table?.refresh()
      },
      async onVoid(data) {
        try {
          this.voidingProgress = true
          this.showVoidDialog = false
          const repositories = this.selectedRowIds
          const payload = {
            repositories,
            ...data,
          }
          await axios.post('/restify/billings/actions?action=void-billings', payload)

        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t(`Could not void billing(s)`))
        } finally {
          this.voidingProgress = false
          this.refreshTable()
        }
      },
      getBillingType(row) {
        return getBillingType(row)
      },
      getViewPath(row) {
        if (this.tableProps.baseEntityPath) {
          return `${this.tableProps.baseEntityPath}/${row.id}/view`
        }

        const type = this.getBillingType(row)
        return `/accounts-receivable/billings/${type}/${row.id}/view`
      },
      getEditPath(row) {
        if (this.tableProps.baseEntityPath) {
          return `${this.tableProps.baseEntityPath}/${row.id}/edit`
        }

        const type = this.getBillingType(row)
        return `/accounts-receivable/billings/${type}/${row.id}/edit`
      },
    },
    watch: {
      filteredByCustomer: {
        immediate: true,
        handler(newValue, oldValue) {
          if (newValue === oldValue) {
            return
          }

          this.selectedGroupBy = newValue ? '' : groupByType.Customer
          this.refreshTable()
        },
      },
    },
  }
</script>
<style lang="scss">
  .billing-print-action {
    .base-button,
    .btn {
      @apply p-0 w-full flex justify-start;
    }
    .print-icon-wrapper {
      @apply p-2 bg-primary-50 mr-2 rounded-md;
    }
  }
</style>
