<template>
  <base-form
    layout="vertical"
    :loading="loading"
    :show-cancel="true"
    :show-back="true"
    :can-create-another-entity="!model.id"
    :save-text="$t('Create Time Off Policy')"
    :update-text="$t('Update Time Off Policy')"
    grid-classes="grid grid-cols-8 gap-x-4"
    @cancel="onCancel"
    @submit="onSubmit"
  >

    <base-input
      v-model="model.description"
      :label="$t('Policy Name')"
      :placeholder="$t('Policy Name')"
      id="description"
      class="col-span-8 md:col-span-4 lg:col-span-2"
      rules="required|max:150"
      @blur="onNameBlur"
    />

    <code-input
      v-model="model.code"
      :model="model"
      :resource-name="globalResources.TimeOffPolicies"
      class="col-span-8 md:col-span-4 lg:col-span-1"
    />

    <base-textarea
      v-model="model.details"
      :label="$t('Policy Description')"
      :placeholder="$t('Optional')"
      :rows="1"
      class="col-span-8 md:col-span-4 lg:col-span-2"
      id="details"
      rules="max:150"
    />

    <entity-select
      v-model="model.time_off_type_id"
      url="/restify/time-off-types"
      :add-entity="false"
      :label="$t('Type')"
      label-key="name"
      value-key="id"
      id="time_off_type"
      class="col-span-8 md:col-span-2 lg:col-span-1"
      rules="required"
    />

    <state-select
      v-model="model.state"
      :label="$t('State')"
      :placeholder="$t('State')"
      clerable
      id="state"
      rules="required"
      class="col-span-8 md:col-span-2 lg:col-span-1"
    />

    <base-select
      v-model="model.rate"
      :options="rateOptions"
      :label="$t('Rate')"
      :placeholder="$t('Rate')"
      id="rate"
      class="col-span-8 md:col-span-4 lg:col-span-1"
    />

    <account-select
      v-model="model.account"
      :label="$t('Account')"
      :placeholder="$t('Account')"
      value-key="number"
      id="state"
      class="col-span-8 md:col-span-4 lg:col-span-2"
    />

    <sub-account-select
      v-model="model.subaccount"
      :label="$t('Sub Account')"
      :placeholder="$t('Optional')"
      value-key="number"
      id="state"
      class="col-span-8 md:col-span-2 lg:col-span-1"
    />
    <div class="col-span-8"/>
    <div class="col-span-8 md:col-span-4 lg:col-span-2">
      <BaseSwitch
        class="mr-2 mb-0"
        :label-info="$t('Active')"
        v-model="model.active"
      />
    </div>
    <div class="col-span-8 md:col-span-4 lg:col-span-2">
      <base-switch
        class="mr-2 mb-0"
        :label-info="$t('Follow State Requirements')"
        v-model="model.follow_state_requirements"
      />
    </div>
    <div class="col-span-8 md:col-span-4 lg:col-span-2">
      <base-switch
        class="mr-2 mb-0"
        :label-info="$t('Allow Time Borrowing')"
        v-model="model.allow_time_borrowing"
      />
    </div>
    <div class="col-span-8 md:col-span-4 lg:col-span-2">
      <base-switch
        class="mr-2 mb-0"
        :label-info="$t('Paid out on Termination')"
        v-model="model.paid_out_on_termination"
      />
    </div>

    <div class="col-span-8 mt-4">
      <el-collapse v-model="activeGroups">
        <BaseCollapseSection
          key="subject_to"
          name="subject_to"
        >
          <template v-slot:title>
            <div class="flex w-full items-center justify-between">
              {{ $t('Subject To') }}
              <div class="flex items-center">
                {{ bulkSubjectToState ? $t('Disable All') : $t('Enable All') }}
                <BaseSwitch
                  v-model="bulkSubjectToState"
                  class="ml-2 mr-4"
                  @click.native.stop
                  @change="triggerRrBulkAction"
                />
              </div>
            </div>
          </template>
          <div class="grid grid-cols-8 gap-x-4">
            <div class="col-span-8 md:col-span-2 mb-2">
              <BaseCheckbox
                v-model="model.subject_to_workers_comp"
                :label="(`Worker's Compensation`)"
                id="subject_to_workers_comp"
              />
              <BaseCheckbox
                v-model="model.subject_to_liability_insurance"
                :label="(`General Liability Insurance`)"
                id="subject_to_liability_insurance"
              />
              <BaseCheckbox
                v-model="model.subject_to_fui"
                :label="$t('Federal Unemployment')"
                id="subject_to_fui"
              />
              <BaseCheckbox
                v-model="model.subject_to_sui"
                :label="$t('State Unemployment')"
                id="subject_to_sui"
              />
              <BaseCheckbox
                v-model="model.subject_to_sdi"
                :label="$t('State Disability')"
                id="subject_to_sdi"
              />
              <BaseCheckbox
                v-model="model.subject_to_fica"
                :label="$t('Fica/Medicare')"
                id="subject_to_fica"
              />
            </div>
            <div class="col-span-8 md:col-span-2 xl:col-span-1">
              <BaseSelect
                v-model="model.subject_to_fit"
                :options="withholdingSubjectOptions"
                :label="$t('Federal Withholding')"
              />
            </div>
            <div class="col-span-8 md:col-span-2 xl:col-span-1">
              <BaseSelect
                v-model="model.subject_to_sit"
                :options="withholdingSubjectOptions"
                :label="$t('State Withholding')"
              />
            </div>
            <div class="col-span-8 md:col-span-2 xl:col-span-1">
              <BaseSelect
                v-model="model.subject_to_lit"
                :options="withholdingSubjectOptions"
                :label="$t('Local Withholding')"
              />
            </div>
          </div>
        </BaseCollapseSection>
        <base-collapse-section
          :title="$t('Details')"
          key="details"
          name="details"
        >
          <div class="grid grid-cols-8 gap-x-4">
            <base-select
              v-model="model.accrual_method"
              :label="$t('Method')"
              :placeholder="$t('Method')"
              :options="AccrualMethodOptions"
              id="accrual_method"
              class="col-span-8 md:col-span-4 lg:col-span-2"
              rules="required"
            />
            <div class="col-span-8 md:col-span-4 lg:col-span-6"></div>
            <base-input
              v-model="model.max_hours"
              :label="$t('Max Hours')"
              :placeholder="$t('Max Hours')"
              :tip="$t('This is the maximum number of hours that can be accrued for this policy. Set 0 for no limit.')"
              id="name"
              class="col-span-8 md:col-span-4 lg:col-span-2"
              rules="required|min_value:0"
            >
              <template #suffix><span>hrs</span></template>
            </base-input>

            <base-input
              v-model="model.waiting_period_days"
              :label="$t('Waiting Period')"
              :placeholder="$t('Waiting Period')"
              :tip="$t('No. of days to wait before the employee balance will be updated. Set 0 for no waiting period.')"
              id="name"
              class="col-span-8 md:col-span-4 lg:col-span-2"
              rules="required|min_value:0"
            >
              <template #suffix>{{ $t('days') }}</template>
            </base-input>

            <base-input
              v-model="model.usage_waiting_period_days"
              :label="$t('Usage Waiting Period')"
              :placeholder="$t('Usage Waiting Period')"
              :tip="$t('No. of days to wait before the employee can use the time off. ')"
              id="name"
              class="col-span-8 md:col-span-4 lg:col-span-2"
              rules="required|min_value:0"
            >
              <template #suffix>{{ $t('days') }}</template>
            </base-input>
          </div>
        </base-collapse-section>
        <base-collapse-section
          v-if="!isUnlimited"
          :title="$t('Rules')"
          key="rules"
          name="rules"
        >
          <TimeOffRules
            :time-off-policy="model"
            :policy-rules="timeOffPolicy?.relationships?.tenures"
          />
        </base-collapse-section>
      </el-collapse>
    </div>
  </base-form>
</template>
<script>
import { AccrualMethodOptions, AccrualMethods } from "@/modules/payroll/utils/accrualMethods";
import TimeOffRules from "@/modules/payroll/components/time-off/TimeOffRules";
import axios from "axios";
import { generateCodeFromName } from "@/utils/generateCodeFromName";
import { globalResources } from "@/components/form/util";

const RateTypes = {
  RegularRate: 'regular-rate',
  NinetyDayAverage: 'ninety-day-average'
}

const TAX_NO = 'no'
const TAX_YES = 'yes'
const TAX_FLAT = 'flat'

export default {
  components: {
    TimeOffRules,
  },
  props: {
    timeOffPolicy: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      globalResources,
      AccrualMethodOptions,
      loading: false,
      activeGroups: ['subject_to', 'details', 'rules'],
      withholdingSubjectOptions: [
        {
          label: this.$t('Yes'),
          value: TAX_YES,
        },
        {
          label: this.$t('No'),
          value: TAX_NO,
        },
        {
          label: this.$t('Flat'),
          value: TAX_FLAT,
        },
      ],
      bulkSubjectToState: true,
      model: {
        description: '',
        code: '',
        details: '',
        time_off_type_id: null,
        state: null,
        rate: RateTypes.RegularRate,
        follow_state_requirements: false,
        account: null,
        subaccount: null,
        accrual_method: AccrualMethods.PerHourWorked,
        max_hours: 0,
        waiting_period_days: 0,
        usage_waiting_period_days: 0,
        paid_out_on_termination: false,
        allow_time_borrowing: false,
        include_on_union_reports: false,
        active: true,
        subject_to_workers_comp: true,
        subject_to_liability_insurance: true,
        subject_to_fit: TAX_YES,
        subject_to_sit: TAX_YES,
        subject_to_lit: TAX_YES,
        subject_to_fica: true,
        subject_to_fui: true,
        subject_to_sdi: true,
        subject_to_sui: true,
      },
    }
  },
  computed: {
    isUnlimited() {
      return this.model?.accrual_method === AccrualMethods.Unlimited
    },
    rateOptions() {
      return [
        {
          label: this.$t('Regular Rate'),
          value: RateTypes.RegularRate
        },
        {
          label: this.$t('90 Day Average'),
          value: RateTypes.NinetyDayAverage
        }
      ]
    }
  },
  methods: {
    onCancel() {
      this.$emit('cancel')
    },
    onNameBlur() {
      this.model.code = generateCodeFromName(this.model.description)
    },
    async onSubmit() {
      try {
        this.loading = true
        if (!this.model.code) {
          this.model.code = generateCodeFromName(this.model.description)
        }
        if (this.timeOffPolicy.id) {
          await axios.put(`/restify/time-off-policies/${this.timeOffPolicy.id}`, this.model)
          this.$success(this.$t('Time Off Policy updated'))
        } else {
          const { data } = await axios.post(`/restify/time-off-policies`, this.model)
          this.$success(this.$t('Time Off Policy created successfully'))
          await this.$router.push(`/payroll/codes/time-off/${data.id}/view`)
        }
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(this.$t('Could not save the time off policy'))
      } finally {
        this.loading = false
      }
    },
    triggerRrBulkAction(state) {
      this.model = {
        ...this.model,
        subject_to_fit: state ? TAX_YES : TAX_NO,
        subject_to_sit: state ? TAX_YES : TAX_NO,
        subject_to_lit: state ? TAX_YES : TAX_NO,
        subject_to_fica: state,
        subject_to_fui: state,
        subject_to_sdi: state,
        subject_to_sui: state,
        subject_to_workers_comp: state,
        subject_to_liability_insurance: state,
      }
    },
  },
  watch: {
    timeOffPolicy: {
      immediate: true,
      handler(val) {
        if (!val.attributes) {
          return
        }
        this.model = {
          ...this.model,
          ...val?.attributes,
        }
      }
    }
  }
}
</script>
