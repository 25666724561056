<template>
  <MaterialForm
    :key="renderKey"
    @create-another="renderKey++"
  />
</template>
<script setup lang="ts">
import { ref } from 'vue'
import MaterialForm from '@/modules/purchasing-inventory/components/MaterialForm.vue'

const renderKey = ref(1)
</script>
