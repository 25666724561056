<template>
  <div>
    <BaseFormDialog :title="title || $t(`Create Payments`)"
                    :append-to-body="true"
                    v-bind="$attrs"
                    v-on="$listeners"
                    size="xl"
    >
      <APPaymentsProofListing
          :hide-buttons="true"
          class="px-4"/>
    </BaseFormDialog>
  </div>
</template>
<script>
  import APPaymentsProofListing from "@/modules/accounts-payable/components/checks/APPaymentsProofListing";

  const STORAGE_KEY = 'selectedAPInvoicesToPay'

  export default {
    components: {
      APPaymentsProofListing,
    },
    props: {
      selections: {
        type: Object,
        default: () => null
      },
      title: {
        type: String,
      },
    },
    methods: {
      saveSelections() {
        if (!this.selections) {
          return
        }
        localStorage.setItem(STORAGE_KEY, JSON.stringify(this.selections))
      }
    },
    created() {
      this.saveSelections()
    },
  }
</script>
