<template>
    <div>
        <layout-tabs :items="filteredItems"/>
        <router-view/>
    </div>
</template>
<script>
  export default {
    data() {
      return {
        items: [
          {
            name: this.$t('Purchasing & Inventory Settings'),
            path: '/purchasing-inventory/settings/purchasing-inventory-settings',
            permission: 'inventory_management_settings',
          },
          {
            name: this.$t('Material Classes'),
            path: '/purchasing-inventory/settings/material-classes',
            permission: 'equipment_class_show',
          },
        ],
      }
    },
    computed: {
      filteredItems() {
        return this.items.filter(item => this.$can(item.permission))
      },
    },
  }
</script>
