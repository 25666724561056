<template>
  <UserLink
      :id="id"
      :target="target"
      :is-link="!editableCell"
  />
</template>
<script>
  import UserLink from '@/components/links/UserLink.vue';

  export default {
    components: {
      UserLink,
    },
    props: {
      column: {
        type: Object,
        default: () => ({}),
      },
      row: {
        type: Object,
        default: () => ({}),
      },
      params: {
        type: Object,
        default: () => ({}),
      },
      editableCell: Boolean,
    },
    computed: {
      id() {
        return this.get(this.row, this.column?.prop);
      },
      target() {
        return this.params?.target || ''
      }
    }
  }
</script>
