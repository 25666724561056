<template>
  <base-data-table
      v-bind="defaultAttributes"
      :filters="filters"
      :columns="columns"
      :data="getData"
      :total-rows="rowsLength"
      @force-pagination="forcePagination"
      class="service-billing-history-report"
  >

    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>
    <template #header="{row}">
      <div :style="{marginLeft: `${row?.header.level * 20}px`}">
        {{ row?.header?.title }}
      </div>
    </template>

    <template #work_order="{row}">
      <WorkOrderLink :data="row.work_order" :show-description="false"/>
    </template>
    <template #customer="{row}">
      <CustomerLink :data="row.customer" :show-description="false"/>
    </template>
    <template #journal="{row}">
      <JournalLink :data="row.journal"/>
    </template>
    <template #business="{row}">
      <BusinessLink v-if="row.business" :data="row"/>
    </template>
    <template #document="{row}">
      <ReportDocumentLink v-if="row.document" :data="row.document"/>
    </template>
    <template #fiscal_year="{row}">
      <div class="flex items-center space-x-1">
        <span>{{ row.period }}</span>
        <span>{{ row.fiscal_year }}</span>
      </div>
    </template>
    <template #quantity="{row}">
      <QuantityCell :data="row"/>
    </template>
    <template #cost="{row}">
      <CostCell :data="row"/>
    </template>

    <template #subtotal="{subtotal}">
      <td colspan="3"/>
      <td colspan="8">
        <div
            class="flex justify-between items-center"
            :style="{marginLeft: `${subtotal.level * -20}px`}"
        >
          <div>{{ $t('Subtotal: ') }} {{ getLevelEntity(subtotal.level) }}</div>
          <div>
            <span class="mr-4">({{ $t('cost hours = ') }}</span> {{ $formatHours(subtotal.cost_hours) }},
            <span class="mr-4">{{ $t('bill hours = ') }}</span> {{ $formatHours(subtotal.billed_hours) }})
          </div>
        </div>
      </td>
      <td class="text-right !pr-1">
        {{ $formatPrice(subtotal.cost) }}
      </td>
      <td class="text-right !pr-1">
        {{ $formatPrice(subtotal.billed) }}
      </td>
      <td class="text-right !pr-1">
        {{ $formatPrice(subtotal.sales_tax_amount) }}
      </td>
    </template>

  </base-data-table>
</template>
<script>
import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'
import { getSBHistorySortOption } from "@/modules/service-billing/components/reports/sortByOptions";
import BusinessLink from "@/components/links/BusinessLink.vue";
import ReportDocumentLink from "@/components/links/ReportDocumentLink.vue";
import QuantityCell from "@/modules/service-billing/components/reports/QuantityCell.vue";
import CostCell from "@/modules/service-billing/components/reports/CostCell.vue";

export default {
  components: {
    CostCell,
    QuantityCell,
    ReportDocumentLink,
    BusinessLink
  },
  extends: ReportTableWrapper,
  props: {
    format: {
      required: true,
      type: String,
      default: '',
    },
    filters: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {}
  },
  computed: {
    columns() {
      return [
        {
          label: this.$t('Work Order'),
          prop: 'work_order',
          minWidth: 60,
          maxWidth: 100,
        },
        {
          label: this.$t('Customer'),
          prop: 'customer',
          minWidth: 60,
          maxWidth: 100,
        },
        {
          label: this.$t('Journal'),
          prop: 'journal',
          minWidth: 60,
          maxWidth: 100,
        },
        {
          label: this.$t('Fiscal </br> Period'),
          prop: 'fiscal_year',
          minWidth: 60,
          maxWidth: 80,
        },
        {
          label: this.$t('Trans Date'),
          prop: 'reference_date',
          minWidth: 80,
          maxWidth: 120,
          component: 'FormattedDate'
        },
        {
          label: this.$t('Account'),
          prop: 'account',
          minWidth: 80,
          maxWidth: 100,
          component: 'AccountLink',
          params: {
            showDescription: false,
          },
        },
        {
          label: this.$t('Type'),
          prop: 'cost_type',
          minWidth: 60,
          maxWidth: 80,
        },
        {
          label: this.$t('Service Code'),
          prop: 'service_billing_code.code',
          minWidth: 80,
          maxWidth: 100,
        },
        {
          label: this.$t('Ven/Emp <br> Item'),
          prop: 'business',
          minWidth: 100,
          maxWidth: 120,
        },
        {
          label: this.$t('Invoice <br> Mtl/Eqp'),
          prop: 'document',
          minWidth: 60,
          maxWidth: 100,
        },
        {
          label: this.$t('Quantity'),
          prop: 'quantity',
          minWidth: 80,
          maxWidth: 120,
          align: 'right',
        },
        {
          label: this.$t('Cost'),
          prop: 'cost',
          minWidth: 120,
          maxWidth: 120,
          align: 'right',
          component: 'FormattedPrice',
          params: {
            hideZero: true,
          },
        },
        {
          label: this.$t('Billed'),
          prop: 'billed',
          minWidth: 120,
          maxWidth: 120,
          align: 'right',
          component: 'FormattedPrice',
          params: {
            hideZero: true,
          },
        },
        {
          label: this.$t('Sales Tax'),
          prop: 'sales_tax_amount',
          minWidth: 120,
          maxWidth: 120,
          align: 'right',
          component: 'FormattedPrice',
          params: {
            hideZero: true,
          },
        },
      ]
    },
  },
  methods: {
    processEntries(data, level = 0) {
      const isArray = Array.isArray(data)
      if (!isArray) {
        for (let key in data) {
          const { entries, totals } = data[key]
          const sortByOption = this.filters?.sort_by[level]
          const entityName = getSBHistorySortOption(sortByOption)
          let title = `${entityName || ''} ${key || ''}`
          this.rows.push({
            header: {
              title,
              level,
            }
          })
          this.processEntries(entries, level + 1)
          if (totals && this.filters.subtotal_on?.includes(totals?.sort)) {
            const subtotal = {
              ...totals,
              level
            }
            this.rows.push({
              subtotal,
            })
          }
        }
      } else {
        this.rows.push(...data)
      }
    },
    composeRows(data) {
      this.processEntries(data)
      return this.rows
    },
    getLevelEntity(level) {
      const sortByOption = this.filters?.sort_by[level]
      return getSBHistorySortOption(sortByOption)
    },
  },
}
</script>
<style lang="scss">
.service-billing-history-report .td-cost .cell {
  overflow: visible;
}
</style>
