<template>
  <div class="flex w-full lg:w-auto items-center overflow-x-auto space-x-2 flex-1 print:hidden">
    <base-tooltip v-for="(tag, index) in tags"
                  :key="tag.name + index"
                  :content="`${tag.name} = ${getTagValue(tag)}`"
                  class="inline-flex"
    >
      <div
          class="inline-flex items-center px-2 py-1 rounded-md text-sm font-medium bg-gray-50 border border-gray-200 text-gray-800 filter-tag"
      >
        <span class="truncate ml-1 capitalize">{{ tag.name }} = {{ getTagValue(tag) }}</span>
        <span
            class="h-4 w-4 close-icon block ml-2 cursor-pointer bg-gray-200 hover:bg-gray-300 hover:text-gray-800 rounded flex justify-center items-center">
          <svg class="h-3 w-3" xmlns="http://www.w3.org/2000/svg" fill="none"
               viewBox="0 0 24 24"
               stroke="currentColor"
               aria-hidden="true"
               @click="$emit('close', tag.key, fullFilters[tag.key])"
          >
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
        </svg>
        </span>
      </div>
    </base-tooltip>
  </div>
</template>
<script>
  import startCase from 'lodash/startCase'

  const exceptions = [
    'created_by',
    'updated_by',
    'posted_by',
    'vendor_id',
    'purchase_order_id',
    'material_id',
    'equipment_id',
    'journal_id',
    'job_id',
    'void_journal_id',
    'customer_id',
    'class_id',
    'specialty_id',
    'sales_person_id',
    'withholding_state_id',
    'sui_sdi_state_id',
    'withholding_local_id',
    'withholding_local_2_id',
    'start_date',
    'expiration_date',
    'document-types',
  ]

  export default {
    props: {
      filters: {
        type: Object,
        default: () => ({}),
      },
      fullFilters: {
        type: [Object, Array],
        default: () => ({}),
      },
      configOptions: {
        type: Array,
        default: () => [],
      },
    },
    computed: {
      currentUserId() {
        return this.$store.state.auth.user.id
      },
      tags() {
        if (!this.filters) {
          return []
        }
        let tags = []
        for (let key in this.filters) {
          let value = this.filters[key]
          if (!value || !key) {
            continue
          }

          const name = this.configOptions.find(op => op.valueKey === key)?.filterName || startCase(key)

          tags.push({
            name,
            key,
            value,
          })
        }
        return tags
      },
    },
    methods: {
      getValue(tag) {
        if (exceptions.includes(tag.key) && tag.value === this.currentUserId) {
          return this.$t('Me')
        }

        if (exceptions.includes(tag.key) && this.fullFilters[tag.key]) {
          return this.getTagValue(tag)
        }

        if (this.isBooleanTypeFilter(tag)) {
          return this.getHumanReadableText(tag.value)
        }

        return tag.value
      },
      getTagValue(tag) {
        if (this.isDateFilter(tag)) {
          const fullValue = this.fullFilters[tag.key]
          return this.getDateValue(fullValue)
        }
        if (this.hasMultipleValues(tag)) {
          return this.getArrayValue(tag)
        }
        return this.getItemValue(tag)
      },
      getArrayValue(tag) {
        const values = tag.value.split(',')
        return values.map(value => {
          return this.getItemValue({
            key: tag.key,
            value,
          })
        }).join(',')
      },
      hasMultipleValues(tag) {
        let value = tag.value || ''
        if (typeof value !== 'string') {
          return false
        }

        return value.includes(',')
      },
      getHumanReadableText(value) {
        return ['true', true].includes(value) ? this.$t('Yes') : this.$t('No')
      },
      getDateValue(value) {
        if (Array.isArray(value)) {
          return value.map(v => this.$formatDate(v)).join(',')
        }
        return this.$formatDate(value)
      },
      isBooleanTypeFilter(tag) {
        return typeof tag.value === 'boolean' || ['true', 'false'].includes(tag.value)
      },
      isDateFilter(tag) {
        return ['start_date', 'expiration_date'].includes(tag.key)
      },
      getItemValue(tag) {
        if (this.isBooleanTypeFilter(tag)) {
          return this.getHumanReadableText(tag.value)
        }

        if (exceptions.includes(tag.key) && tag.value === this.currentUserId) {
          return this.$t('Me')
        }

        const fullValues = this.fullFilters[tag.key]

        if (!Array.isArray(fullValues)) {
          let baseObject = fullValues?.attributes ? fullValues.attributes : fullValues
          return baseObject?.name || baseObject?.code || baseObject?.number || baseObject?.description || baseObject?.period_end_date || startCase(tag.value)
        }

        const fullValue = fullValues.find(f => f.id === tag.value)
        return fullValue?.attributes?.name || fullValue?.attributes?.code || fullValue?.attributes?.number || fullValue?.attributes?.description || tag.value
      },
    },
  }
</script>
<style scoped lang="scss">
  .filter-tag {
    max-width: 220px;
  }

  .close-icon {
    min-width: 16px;
  }
</style>
