<template>
  <div class="flex flex-col">
    <BaseDataTable :columns="tableColumns"
                   :data="translations"
                   :show-pagination="false"
    >
      <template v-slot:value="{row, index}">
        <template v-if="row.editable">
          <base-input inline-errors
                      rules="required"
                      :name="$t('Field')"
                      :id="`text-field-${index}`"
                      v-model="translations[index].value"
                      @keydown.enter="onSave(row)"
          />
        </template>
        <template v-else>
          {{ row.value }}
        </template>
      </template>
      <template v-slot:extra-actions="{row, index}">
        <base-button v-if="!row.editable"
                     variant="primary-link"
                     @click="onEdit(row, index)">
          {{$t('Edit')}}
        </base-button>
        <template v-else>
          <base-button variant="gray-link"
                       @click="onCancel(row)">
            {{$t('Cancel')}}
          </base-button>
          <base-button variant="green-link"
                       :loading="loading"
                       :disable="!row.value"
                       @click="onSave(row)">
            {{$t('Update')}}
          </base-button>
        </template>
      </template>
    </BaseDataTable>
  </div>
</template>
<script>
  import axios from 'axios'
  import cloneDeep from 'lodash/cloneDeep'
  import { setOrganizationTranslations } from '@/utils/utils'

  export default {
    props: {
      data: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        translations: [],
        loading: false,
      }
    },
    computed: {
      organizationSettings() {
        return this.$store.state.settings.organizationSettings || []
      },
      tableColumns() {
        return [
          {
            label: this.$t('Value'),
            prop: 'key',
          },
          {
            label: this.$t('New Value'),
            prop: 'value',
            maxWidth: 320,
            minWidth: 320,
          },
        ]
      }
    },
    methods: {
      onEdit(row, index) {
        this.$set(row, 'editable', true)
        this.$nextTick(() => {
          this.focusOnInput(index)
        })
      },
      focusOnInput(index) {
        const input = document.getElementById(`text-field-${index}`)
        if (!input) {
          return
        }
        input.focus()
      },
      initOrRefreshOptions() {
        this.translations = cloneDeep(this.data)
      },
      onCancel(row) {
        this.initOrRefreshOptions()
        this.$set(row, 'editable', false)
      },
      async onSave() {
        try {
          this.loading = true
          const settings = this.organizationSettings.find(settings => settings.key === 'translations')
          if (!settings.id) {
            return
          }
          const settingsCopy = cloneDeep(this.translations)
          settingsCopy.forEach(row => {
            delete row.editable
          })
          settings.value = JSON.stringify(settingsCopy)
          await axios.put(`/restify/organization-settings/${settings.id}`, settings)
          this.$success(this.$t('Organization translation updated'))
          this.setLocaleMessage()
          this.translations.forEach(row => {
            this.$set(row, 'editable', false)
          })
        } catch (err) {
          this.$error(this.$t('Could not update the organization translation'))
        } finally {
          this.$nextTick(() => {
            this.loading = false
          })
        }
      },
      setLocaleMessage() {
        setOrganizationTranslations()
      },
    },
    watch: {
      data: {
        immediate: true,
        handler() {
          this.initOrRefreshOptions()
        },
      },
    },
  }
</script>
