<template>
  <div class="work-order-group-row flex justify-between">
    <div class="flex space-x-2 font-medium">
      <base-checkbox
        v-model="selectedAll"
        :label="$t('Select All')"
        :id="`${rowIndex}-select-all`"
        @change="updateChildRows($event)"
      />
      <div class="flex items-center space-x-2">
        <span>{{ $t('Work Order:') }}</span>
        <WorkOrderLink
          :data="workOrder"
          class="work-order-link"
        />
        <span>{{ $t('Customer:') }}</span>
        <CustomerLink
          :data="customer"
          class="work-order-link"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { updateRows } from "@/modules/service-billing/components/cost-for-billing/costForBillingUtils";
import {defineComponent} from "vue";

export default defineComponent({
  data() {
    return {
      selectedAll: false,
    }
  },
  computed: {
    data() {
      return this.params.node?.allLeafChildren[0]?.data || {}
    },
    workOrder() {
      return this.data?.work_order || {}
    },
    customer() {
      return this.data?.customer || {}
    },
    level() {
      return this.params.node.level
    },
    rowIndex() {
      return this.params.rowIndex
    },
  },
  methods: {
    async updateChildRows(selected) {
      const childRows = this.params.node.allLeafChildren || []
      await updateRows(childRows, selected)
    },
  },
})
</script>
<style lang="scss">
.work-order-group-row {
  .work-order-link a {
    @apply text-sm font-medium;
  }
}
</style>
