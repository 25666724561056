<template>
  <div class="bg-gray-100 print:bg-white min-h-screen">
    <div class="flex flex-col items-center w-full py-8">
      <div class="max-w-7xl w-full">
        <router-view/>
      </div>
    </div>
    <base-copyright/>
  </div>
</template>
<script>
  export default {}
</script>
<style>
</style>
