<template>
  <div
      v-if="entity"
      :class="{'print:hidden': files.length === 0}"
      class="bg-gray-50 border border-gray-200 rounded pt-3 pb-2 px-4 print:px-2 print:pt-1 print:pb-2"
  >
    <h4 class="font-medium text-base print:text-print mb-1">{{ $t(title) }}</h4>
    <FileAttachmentsLoading v-if="loadingFiles && !filesToDisplay.length"/>
    <div
        v-if="filesToDisplay.length"
        :class="{
          'mb-4 print:mb-2': !showMore && !showLess,
          'mb-2 print:mb-0': showMore || showLess,
        }"
    >
      <div v-if="false" class="w-full lg:w-1/2 text-sm print:text-print">
        <span class="font-medium">{{ $t('Heads up:') }}</span>
        {{
          $t('Users who have access to this page will be able to see and download all attached files.  The max file size is 10 MB.')
        }}
      </div>
      <div v-for="file in filesToDisplay"
           :key="file.uuid || file.name"
           class="flex justify-between"
      >
        <div class="flex items-end text-sm space-x-1">
          <el-progress
              v-if="fileProgress[file.id] !== undefined && fileProgress[file.id] < 100"
              :width="24"
              :stroke-width="3"
              color="var(--primary-500)"
              :percentage="fileProgress[file.id]"
              :show-text="false"
              type="circle"
          />
          <span
              v-else
              v-loading="file.downloading"
              :class="getFileIcon(file)"
              class="w-5 h-5 print:h-3 print:w-3"
          />
          <span
              :class="{'underline hover:font-medium': file.uuid}"
              class="cursor-pointer text-gray-700 print:text-print"
              @click="downloadFile(file)"
          >
            {{ file.name }}</span>
          <span class="text-gray-600 text-sm print:text-print">
            ({{ file.type || file.extension }}, {{ humanReadableSize(file.size) }})
          </span>
        </div>
        <CloseButton
          v-if="authorizedToRemoveFiles"
          class="print:hidden"
          size="lg"
          @click="removeFile(file)"
        />
      </div>
      <div class="-mb-2">
        <BaseButton
            v-if="showMore"
            variant="gray-link"
            @click="onShowMoreClick"
        >
          {{ $t(`+ ${moreFilesCount} more files`) }}
        </BaseButton>

        <BaseButton
            v-if="showLess"
            variant="gray-link"
            @click="onShowLessClick"
        >
          {{ $t(`Show less`) }}
        </BaseButton>
      </div>
    </div>

    <DocumentPreviewDialog
      v-if="showPreviewDialog"
      :open.sync="showPreviewDialog"
      :data="previewData"
    />

    <BaseUploadButton
        v-if="authorizedToAttachFiles"
        :accept="acceptedFileTypes"
        multiple
        class="print:hidden"
        @input="onChange"
    >
      {{ $t('Attach files') }}
    </BaseUploadButton>
  </div>
</template>
<script>
  import axios from "axios";
  import { Progress } from 'element-ui'
  import CloseButton from "@/components/common/CloseButton";
  import {
    getFileExtension,
    humanReadableSize,
    isAttachmentPreviewAble,
  } from "@/modules/common/util/fileUtils";
  import FileAttachmentsLoading from "@/modules/accounts-payable/components/attachements/FileAttachmentsLoading";
  import { downloadFileLocally } from "@/modules/common/util/downloadFileLocally";
  import FilePreviewDetails from "@/modules/common/components/documents/FilePreviewDetails.vue";
  import DocumentPreviewDialog from "@/modules/common/components/documents/DocumentPreviewDialog.vue";
  import { acceptedFileTypes } from "@/modules/common/components/documents/documentUtils";

  export default {
    components: {
      DocumentPreviewDialog,
      FilePreviewDetails,
      CloseButton,
      FileAttachmentsLoading,
      [Progress.name]: Progress,
    },
    props: {
      entity: {
        type: String,
        required: true,
      },
      entityId: {
        type: [String, Number],
      },
      title: {
        type: String,
        default: 'File Attachments',
      },
      authorizedToAttachFiles: {
        type: Boolean,
        default: true,
      },
      authorizedToRemoveFiles: {
        type: Boolean,
        default: true,
      }
    },
    data() {
      return {
        files: [],
        fileProgress: {},
        initialDisplayLimit: 4,
        displayLimit: 4,
        loadingFiles: false,
        showPreviewDialog: false,
        previewData: null,
        acceptedFileTypes,
      }
    },
    computed: {
      filesToDisplay() {
        return this.files.slice(0, this.displayLimit)
      },
      showMore() {
        return this.files.length > this.displayLimit
      },
      showLess() {
        return this.files.length === this.displayLimit && this.files.length > this.initialDisplayLimit
      },
      moreFilesCount() {
        return this.files.length - this.displayLimit
      },
      routeId() {
        return this.entityId || this.$route.params.id
      },
      uploadUrl() {
        let baseUrl = `/restify/${this.entity}`
        if (this.routeId) {
          baseUrl += `/${this.routeId}`
        }
        baseUrl += `/actions?action=create-attachments`

        return baseUrl
      },
      listUrl() {
        let baseUrl = `/restify/${this.entity}`
        if (this.routeId) {
          baseUrl += `/${this.routeId}`
        }
        baseUrl += `/actions?action=list-attachments`

        return baseUrl
      }
    },
    methods: {
      async onChange(event) {
        const files = event.target.files
        let fileArray = Array.from(files)

        fileArray.forEach(file => {
          file.id = new Date().getTime() + file.name
          this.$set(this.fileProgress, file.id, 0)
        })
        this.files.push(...fileArray)
        if (!this.routeId) {
          return
        }
        await this.uploadFiles(fileArray)
      },
      async triggerUpload() {
        if (!this.files.length) {
          return
        }
        await this.$nextTick()
        await this.uploadFiles(this.files)
      },
      async uploadFiles(files = []) {
        if (!files.length) {
          files = this.files
        }
        const data = new FormData()
        files.forEach(file => {
          data.append('files[]', file)
        })
        const fileIds = files.filter(f => f.id).map(f => f.id)

        await axios.post(this.uploadUrl, data, {
          onUploadProgress: progressEvent => {
            let progress = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
            fileIds.forEach(id => {
              this.$set(this.fileProgress, id, progress)
            })
          }
        })
        await this.getFileAttachments()
        await this.$nextTick()
      },
      getAttachmentByName(name) {
        const nameWithoutExtension = name.split('.').slice(0, -1).join('.')
        return this.files.find(f => f.name === nameWithoutExtension || f.file_name === name)
      },
      async getFileAttachments() {
        if (!this.routeId || !this.entity) {
          return
        }
        try {
          this.loadingFiles = true
          const { data } = await axios.post(this.listUrl)
          this.files = Object.values(data)
        } finally {
          this.loadingFiles = false
        }
      },
      getFileIcon(file) {
        return `fiv-viv fiv-icon-${getFileExtension(file)}`;
      },
      async handleFileDownload(file) {
        if (!file.uuid) {
          downloadFileLocally(file, file.name)
          return
        }
        const fileName = file.file_name || `${file.name}.${file.extension}`

        const data = await axios.get(`/media/${file.uuid}`, {
          responseType: 'blob',
        })
        if (isAttachmentPreviewAble(data.type)) {
          this.previewData = {
            id: file.uuid,
            attributes :{
              url: URL.createObjectURL(data),
              name: file.name,
              file_name: fileName,
            },
          }
          this.showPreviewDialog = true
          return
        }
        downloadFileLocally(data, fileName)
      },
      async downloadFile(file) {
        try {
          this.$set(file, 'downloading', true)
          await this.handleFileDownload(file)
        } finally {
          this.$set(file, 'downloading', false)
        }
      },
      async removeFile(file) {
        const confirmed = await this.$deleteConfirm({
          title: this.$t('Remove attachment'),
          description: this.$t('This action will remove the attachment. Are you sure?'),
          buttonText: this.$t('Remove'),
        })
        if (!confirmed) {
          return
        }

        this.files = this.files.filter(f => {
          if (file.uuid) {
            return file.uuid !== f.uuid
          }
          return file.id !== f.id
        })
        if (file.uuid) {
          await axios.delete(`/media/${file.uuid}`)
        }
      },
      humanReadableSize,
      onShowMoreClick() {
        this.displayLimit = this.files.length
      },
      onShowLessClick() {
        this.displayLimit = this.initialDisplayLimit
      },
      refreshAttachments() {
        this.getFileAttachments()
      },
      updateAttachmentsCount() {
        this.$emit('update:attachments-count', this.files.length)
      },
    },
    mounted() {
      this.getFileAttachments()
    },
    watch: {
      files(value) {
        this.updateAttachmentsCount(value)
      },
      routeId(newValue, oldValue) {
        if (!oldValue) {
          return
        }
        this.files = []
        this.getFileAttachments()
      }
    }
  }
</script>
<style>
</style>
