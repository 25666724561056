<template>
  <div class="max-w-full p-4">
    <PurchaseOrderAdjustItems
      :data="params.data"
      :is-posted="isPosted"
      readOnly
    />
  </div>
</template>
<script>
import PurchaseOrderAdjustItems
  from "@/modules/purchasing-inventory/components/purchase-orders/PurchaseOrderAdjustItems.vue";
import { resourceStatuses } from "@/enum/enums";
export default {
  components: {
    PurchaseOrderAdjustItems,
  },
  data() {
    return {
      resourceStatuses
    }
  },
  computed: {
    isPosted() {
      return this.params.data?.attributes?.status === resourceStatuses.Posted
    }
  }
}
</script>
