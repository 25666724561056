<template>
  <settings-form
      :fields="fields"
      :module-name="moduleName"
      :before-save="saveCostType"
      :authorized-to-collapse="true"
      grid-classes="grid grid-cols-1"
      @cancel="$router.push('/equipment/list')"
  >
    <template #content="{model}">
      <div class="grid grid-cols-8 gap-x-3 px-4">
        <div class="col-span-8 md:col-span-4 lg:col-span-2">
          <account-select
              v-model="model.disposal_account"
              :label="$t('Disposal Account')"
              value-key="number"
              rules="required"
              warningOnChange
          />
        </div>
        <div class="col-span-8 md:col-span-4 lg:col-span-2">
          <sub-account-select
              v-model="model.disposal_subaccount"
              :label="$t('Disposal Sub Account')"
              value-key="number"
          />
        </div>
        <div class="col-span-8 md:col-span-4 lg:col-span-2">
          <account-select
              v-model="model.calculated_depreciation_account"
              :label="$t('Calculated Depreciation Account')"
              value-key="number"
              rules="required"
              warningOnChange
          />
        </div>
        <div class="col-span-8 md:col-span-4 lg:col-span-2">
          <sub-account-select
              v-model="model.calculated_depreciation_subaccount"
              :label="$t('Calculated Depreciation Sub Account')"
              value-key="number"
          />
        </div>
        <div class="col-span-8 md:col-span-4 lg:col-span-2">
          <base-input
              v-model="model.economic_cost_factor"
              :label="$t('Economic Cost Factor')"
              :min="0"
              :max="999.99"
              :step="1"
              type="number"
              format="percent"
              rules="min_value:0|max_value:999.99"
          />
        </div>
        <div class="col-span-8 md:col-span-4 lg:col-span-2">
          <base-input
              v-model="model.unallocated_expense_factor"
              :label="$t('Unallocated Expense Factor')"
              :min="0"
              :max="999.99"
              :step="1"
              type="number"
              format="percent"
              rules="min_value:0|max_value:999.99"
          />
        </div>
        <AddressSelect
            v-model="model.default_address_id"
            :address-type="$addressTypes.Warehouse"
            :label="$t('Default Address for Equipments')"
            class="col-span-8 md:col-span-4 lg:col-span-2"
        />
      </div>
    </template>

    <template #overrides>
      <EquipmentCostTypesGrid class="col-span-8 mt-6" ref="costTypes"/>
    </template>
  </settings-form>
</template>
<script>
  import SettingsForm from '@/modules/common/components/SettingsForm'
  import EquipmentCostTypesGrid from '@/modules/equipment/components/EquipmentCostTypesGrid'

  export default {
    components: {
      SettingsForm,
      EquipmentCostTypesGrid,
    },
    data() {
      return {
        moduleName: 'equipment-management',
        fields: [
          'calculated_depreciation_account',
          'calculated_depreciation_subaccount',
          'disposal_account',
          'disposal_subaccount',
          'default_address_id',
          'economic_cost_factor',
          'unallocated_expense_factor',
        ],
        roundRetentionTypes: [
          {
            label: this.$t('Cents'),
            value: 'cents',
          },
          {
            label: this.$t('Dollar'),
            value: 'dollar',
          },
        ],
      }
    },
    methods: {
      async saveCostType() {
        await this.$refs.costTypes.save()
        return true
      },
    },
  }
</script>
