<template>
  <div>
    <LineItemTransactions
      v-if="viewType === viewTypes.Transactions"
      :url-params="urlParams">
      <template #actions>
        <LineItemTransactionTypeSelect v-model="viewType"/>
      </template>
    </LineItemTransactions>
    <JobCostInitEntries
      v-else-if="viewType === viewTypes.Inits"
      :type="lineItem?.attributes?.type"
      :read-only="true"
      :show-pagination="true"
      :url-params="initUrlParams"
      :show-journal-link="true"
      actions="search,refresh"
      url="/restify/job-costing-init-entries"
    >
      <template #actions>
        <LineItemTransactionTypeSelect v-model="viewType"/>
      </template>
    </JobCostInitEntries>
  </div>
</template>
<script>
import LineItemTransactions from '@/modules/job-costing/components/line-items/TransactionHistory.vue'
import LineItemTransactionTypeSelect
  from "@/modules/job-costing/components/line-items/LineItemTransactionTypeSelect.vue";
import JobCostInitEntries from "@/modules/job-costing/components/job-cost-init/JobCostInitEntries.vue";

const viewTypes = {
  Transactions: 'transactions',
  Inits: 'inits'
}

export default {
  components: {
    JobCostInitEntries,
    LineItemTransactionTypeSelect,
    LineItemTransactions,
  },
  data() {
    return {
      viewTypes,
      viewType: viewTypes.Transactions,
    }
  },
  computed: {
    initUrlParams() {
      return {
        line_item_id: this.lineItem.id,
      }
    },
    urlParams() {
      return {
        addl_source_id: this.lineItem.id,
        related: 'type,business,account[id|number]',
      }
    },
    lineItem() {
      return this.$store.state.jobCosting.currentLineItem
    },
    lineItemLoading() {
      return this.$store.state.jobCosting.lineItemLoading
    },
  },
}
</script>
