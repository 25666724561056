<template>
  <div class="px-4 py-5 bg-white sm:p-6 rounded-md max-h-full h-full base-card">
    <slot></slot>
  </div>
</template>
<script>
  export default {}
</script>
<style>
</style>
