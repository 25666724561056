<template>
  <div>
    <ServiceBillingInvoicesPage :customer-id="customer.id"/>
  </div>
</template>
<script>
  import ServiceBillingInvoices from '@/modules/accounts-receivable/pages/customer/resources/ServiceBillingInvoices'
  import ServiceBillingInvoicesPage
    from '@/modules/service-billing/pages/service-billing-invoices/service-billing-invoices'

  export default {
    components: {
      ServiceBillingInvoices,
      ServiceBillingInvoicesPage,
    },
    computed: {
      customer() {
        return this.$store.state.accountsReceivable.currentCustomer
      }
    },
  }
</script>
