<template>
  <BaseReportForm
    :report-number="reportNumber"
    :filters="model"
    :transform-filters="transformFilters"
  >
    <template #filters>
      <BaseFilterRow :title="$t('Distributions')">
        <BaseSelect
          v-model="model.distributions"
          :options="historyDistributionHistoryOptions"
          class="col-span-4"
          @change="onDistributionChange"
        />
      </BaseFilterRow>
      <BaseFilterRow
        v-if="model.distributions !== historyDistributionHistory.All"
      >
        <JobRangeFilter
          v-if="model.distributions === historyDistributionHistory.Job"
          v-model="model"
          fromKey="distribution_code_from"
          toKey="distribution_code_to"
          class="col-span-12"
        />
        <WorkOrderRangeFilter
          v-else-if="model.distributions === historyDistributionHistory.WorkOrders"
          v-model="model"
          fromKey="distribution_code_from"
          toKey="distribution_code_to"
          class="col-span-12"
        />
        <EquipmentRangeFilter
          v-else-if="model.distributions === historyDistributionHistory.Equipment"
          v-model="model"
          fromKey="distribution_code_from"
          toKey="distribution_code_to"
          class="col-span-12"
        />
      </BaseFilterRow>
      <BaseFilterRow :title="$t('Subtotal On')">
        <BaseSelect
          v-model="model.subtotal_on"
          :options="historyDistributionSubtotalOnOptions"
          class="col-span-4"
          collapse-tags
          multiple
        />
      </BaseFilterRow>
      <PayrollDatesFilter
        v-model="model"
      />
    </template>
    <template #table="{data, loading}">
      <PayrollHistoryDistributionReportTable
        :data="data"
        :filters="model"
        :report-number="reportNumber"
        :data-loading="loading"
        ref="reportTable"
      />
    </template>
  </BaseReportForm>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import {
  historyDistributionHistory,
  historyDistributionHistoryOptions,
  historyDistributionSubtotalOn,
  historyDistributionSubtotalOnOptions, selectionDateFields
} from '@/modules/payroll/components/reports/util'
import {
  JobRangeFilter,
  EquipmentRangeFilter,
  WorkOrderRangeFilter,
} from '@/components/range-filters'
import PayrollDatesFilter from '@/modules/payroll/components/reports/PayrollDatesFilter.vue'
import PayrollHistoryDistributionReportTable
  from '@/modules/payroll/components/reports/PayrollHistoryDistributionReportTable.vue'

export default defineComponent({
  components: {
    PayrollDatesFilter,
    PayrollHistoryDistributionReportTable,
    JobRangeFilter,
    WorkOrderRangeFilter,
    EquipmentRangeFilter,
  },
  data() {
    return {
      reportNumber: 4871,
      historyDistributionHistory,
      historyDistributionHistoryOptions,
      historyDistributionSubtotalOnOptions,
      model: {
        distributions: historyDistributionHistory.All,
        distribution_code_from: null,
        distribution_code_to: null,
        subtotal_on: [ historyDistributionSubtotalOn.Job ],
        ...selectionDateFields,
        // @ts-ignore
      } as Payroll.History_Distribution,
    }
  },
  methods: {
    transformFilters(params: any) {
      const { subtotal_on } = params
      if (!Array.isArray(subtotal_on) && typeof subtotal_on === 'string') {
        params.subtotal_on = [subtotal_on]
        this.model.subtotal_on = [subtotal_on]
      }
      return params
    },
    onDistributionChange() {
      this.model.distribution_code_from = null
      this.model.distribution_code_to = null
    }
  }
})
</script>
