<template>
  <div>
    <TabStatusFilter
      v-model="currentStatus"
      :statuses="statuses"
    />
    <InvoiceTable
      v-if="currentStatus !== resourceStatuses.Review"
      :invoice-status="currentStatus"
      :url-params="urlParams"
      :default-match="false"
      :limit-max-height="false"
    />
    <ReviewInvoices
      v-else
      :url-params="urlParams"
    />
  </div>
</template>
<script>
import { resourceStatuses } from '@/enum/enums'
import InvoiceTable from '@/modules/accounts-payable/pages/invoices/InvoiceTable'
import TabStatusFilter from "@/modules/common/components/TabStatusFilter.vue";
import ReviewInvoices from "@/modules/accounts-payable/pages/invoices/review-invoices.vue";

export default {
  components: {
    ReviewInvoices,
    TabStatusFilter,
    InvoiceTable,
  },
  props: {
    urlParams: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      resourceStatuses,
      currentStatus: resourceStatuses.All,
    }
  },
  computed: {
    statuses() {
      return [
        {
          label: this.$t('Pending'),
          value: resourceStatuses.Pending,
        },
        {
          label: this.$t('Open'),
          value: resourceStatuses.Open,
        },
        {
          label: this.$t('Paid'),
          value: resourceStatuses.Paid,
        },
        {
          label: this.$t('All'),
          value: resourceStatuses.All,
        },
        {
          label: this.$t('Review'),
          value: resourceStatuses.Review,
        },
      ]
    }
  },
}
</script>
