<template>
  <div class="w-full">
    <span class="action-item-text"
          @click="showImportDialog = true">
      <div class="p-2 bg-primary-50 mr-2 rounded-md">
        <UploadIcon class="w-4 h-4 text-primary-500"/>
      </div>
      <span>{{ $t('Import') }}</span>
    </span>
    <BaseFormDialog
        v-if="showImportDialog"
        :title="$t('Import module settings from JSON')"
        :open.sync="showImportDialog"
        :append-to-body="true"
        size="lg"
    >
      <BaseForm
          :loading="loading"
          :submit-disabled="errorMessage"
          :save-text="$t('Import')"
          layout="modal"
          @submit="onImport"
      >
        <div class="col-span-6">
          <base-alert
              v-if="errorMessage"
              type="danger"
              closable
          >
            {{ errorMessage }}
          </base-alert>
        </div>
        <div class="col-span-6 flex flex-wrap mb-4">
          <Upload
              :auto-upload="false"
              :on-change="parseFile"
              :on-remove="onFileRemove"
              :limit="1"
              class="mt-5"
              action="default"
              accept=".json"
          >
            <BaseButton class="select-file-button focus:outline-none focus:ring-blue"
                        ref="uploadButton"
                        variant="white">
              {{ $t('Select File') }}
            </BaseButton>
          </Upload>
        </div>
        <SettingsTable
            :fields="fields"
            :settings-object="uploadedJson"
            :action="SettingsActions.Import"
            ref="settingsTable"
        />
      </BaseForm>

    </BaseFormDialog>
  </div>
</template>

<script setup lang="ts">
  import axios from 'axios'
  import { ref } from 'vue'
  import i18n from '@/i18n'
  import store from '@/store'
  import { Upload } from 'element-ui'
  import { UploadIcon } from 'vue-feather-icons'
  import SettingsTable from '@/modules/common/components/modules-settings/SettingsTable.vue'
  import { SettingsActions } from '@/modules/common/components/modules-settings/settingsActionsUtil'
  import { validateAgDataTable } from '@/components/ag-grid/tableUtils'

  const props = defineProps({
    settingsObject: {
      type: Object as () => Record<string, any>,
      default: () => ({}),
    },
    moduleName: String,
    fields: Array as () => string[],
  })

  const emit = defineEmits(['refresh-form'])

  let showImportDialog = ref(false)
  let loading = ref(false)
  let uploadedJson = ref<Record<string, any>>({})
  let settingsTable = ref(null)
  let errorMessage = ref(null)

  async function parseFile(file: any) {
    const reader = new FileReader()

    reader.readAsText(file.raw)
    reader.onload = () => {
      const result = JSON.parse(reader.result as string)
      if (result.length) {
        // @ts-ignore
        errorMessage.value = i18n.t('Remove uploaded file and enter a valid JSON.')
        return
      }
      uploadedJson.value = result
    }
  }

  function onFileRemove() {
    uploadedJson.value = {}
    errorMessage.value = null
  }

  async function onImport() {

    const isInvalidData = await validateAgDataTable()
    if (isInvalidData) {
      return
    }

    try {
      loading.value = true
      // @ts-ignore
      let settings = settingsTable?.value?.getTableData() || {}
      const module = props.settingsObject

      const payload = {
        ...settings,
        id: module.id,
      }

      await axios.put(`/restify/company-settings/${ module.id }`, {
        value: payload,
      })

      store.dispatch('company/getCompanySettings')
      emit('refresh-form')

      uploadedJson.value = {}
      showImportDialog.value = false
    } catch (err) {
      console.warn(err)
    } finally {
      loading.value = false
    }
  }
</script>
