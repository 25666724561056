<template>
  <div class="flex items-center justify-between">
    <span class="tracking-wide font-medium text-sm leading-normal text-gray-400 mr-2">
      <slot name="label">
        {{ label }}
      </slot>
    </span>
    <span class="leading-tight tracking-wide text-gray-900 uppercase">
      <slot>
        {{ value }}
      </slot>
    </span>
  </div>
</template>
<script>
  export default {
    props: {
      label: {
        type: String,
      },
      value: {
        type: String,
      },
    },
  }
</script>
<style>
</style>
