<template>
  <div class="px-4 pb-6">
    <AgDataTable
      :data="scheduleRows"
      :columns="columns"
      :pagination="false"
      :no-data-text="$t('Could not compute the detailed schedule')"
      domLayout="autoHeight"
    />
  </div>
</template>
<script>
import { rrulestr } from "rrule";

export default {
  props: {
    rule: {
      type: String,
    }
  },
  data() {
    return {
      maxEntries: 30,
    }
  },
  computed: {
    scheduleRows() {
      try {
        const rule = rrulestr(this.rule)
        if (!rule.options.count && !rule.all()?.length) {
          rule.options.count = this.maxEntries
        }
        return rule.all().map(date => {
          return {
            id: crypto.randomUUID(),
            date,
          }
        })
      } catch (err) {
        return []
      }
    },
    columns() {
      return [
        {
          headerName: this.$t('Date'),
          field: 'date',
          component: 'FormattedDate',
          minWidth: 150,
          maxWidth: 200,
        },
        {
          headerName: this.$t('Time'),
          field: 'date',
          component: 'FormattedDate',
          cellRendererParams: {
            format: 'HH:mm',
          }
        }
      ]
    }
  }
}
</script>
