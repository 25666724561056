<template>
  <AgDataTable
    :columns="columns"
    :url-params="urlParams"
    :groupIncludeFooter="true"
    :groupIncludeTotalFooter="true"
    url="/restify/service-billing-histories"
    actions="search,refresh"
    domLayout="autoHeight"
  >
    <template #attributes.worksite_id="{row}">
      <WorksiteLink :data="row?.relationships?.workSite" :show-name="false"/>
    </template>
    <template #attributes.customer_id="{row}">
      <CustomerLink :data="row?.relationships?.customer"/>
    </template>
    <template #attributes.journal_id="{row}">
      <JournalLink
        v-if="row?.attributes?.journal_id"
        :id="row?.attributes?.journal_id"
        :data="row?.relationships?.journal"
      />
    </template>
    <template #attributes.business_id="{row}">
      <component
        v-if="row?.attributes?.business_id"
        :is="getBusinessComponent(row.attributes.business_type)"
        :data="row?.relationships?.business"
        :show-description="false"
        :show-name="false"
      />
      <span v-else/>
    </template>
  </AgDataTable>
</template>
<script>

import i18n from "@/i18n";
import { transactionBusinessLinkByTypes } from "@/enum/enums";

const TransactionTypes = {
  Burden: 'burden',
  RegularHours: 'regular-hours',
  NA: 'n/a',
}

const TransactionTypeLabels = {
  [TransactionTypes.Burden]: 'Burden',
  [TransactionTypes.RegularHours]: 'Regular Hours',
  [TransactionTypes.NA]: 'Unknown',
}
export default {
  computed: {
    columns() {
      return [
        {
          headerName: this.$t('Worksite'),
          field: 'attributes.worksite_id',
          minWidth: 80,
          maxWidth: 100,
        },
        {
          headerName: this.$t('Customer'),
          field: 'attributes.customer_id',
          minWidth: 120,
          maxWidth: 140,
        },
        {
          headerName: this.$t('Journal'),
          field: 'attributes.journal_id',
          minWidth: 120,
          maxWidth: 120,
        },
        {
          headerName: this.$t('Fiscal Year / Period'),
          field: 'attributes.fiscal_year',
          minWidth: 80,
          maxWidth: 110,
          valueFormatter: params => {
            if (!params.data) {
              return ''
            }
            return `${params.value} / ${params.data?.attributes?.period}`
          },
        },
        {
          headerName: this.$t('Date'),
          field: 'attributes.reference_date',
          minWidth: 80,
          maxWidth: 110,
          component: 'FormattedDate',
        },
        {
          headerName: this.$t('Account'),
          field: 'attributes.account',
          minWidth: 100,
          maxWidth: 120,
          component: 'AccountLink',
          cellRendererParams: {
            showDescription: false,
          },
        },
        {
          headerName: this.$t('Trans Type'),
          field: 'attributes.transaction_type',
          minWidth: 100,
          maxWidth: 180,
          valueFormatter: params => {
            return TransactionTypeLabels[params.value] || params.value
          },
        },
        {
          label: i18n.t('Vend / Emp / Item'),
          prop: 'attributes.business_id',
          minWidth: 100,
          maxWidth: 120,
        },
        {
          headerName: this.$t('Invoice / Mtl / Eqp'),
          field: 'relationships.addlSource.name',
          minWidth: 100,
          maxWidth: 120,
        },
        {
          headerName: this.$t('Quantity / Hours'),
          field: 'attributes.quantity',
          minWidth: 80,
          maxWidth: 100,
          align: 'right',
          valueFormatter: params => {
            return params.value ? params.value.toFixed(2) : ''
          },
          aggFunc: 'sum',
        },
        {
          headerName: this.$t('$ Amount'),
          field: 'attributes.amount',
          minWidth: 100,
          maxWidth: 150,
          component: 'FormattedPrice',
          align: 'right',
          aggFunc: 'sum',
        },
      ]
    },
    urlParams() {
      return {
        work_order_id: this.$route.params.id,
        related: 'workSite[id|number|name],journal[id|name],business[id|name|code],addlSource,customer[id|name|code|current_ar_amount|retention_amount|sales_activity_this_year]',
      }
    },
  },
  methods: {
    getBusinessComponent(businessType) {
      return transactionBusinessLinkByTypes[businessType]
    },
  }
}
</script>
