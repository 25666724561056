<template>
  <base-grid-select
    v-bind="$attrs"
    v-on="$listeners"
    :resource-name="globalResources.CraftCodes"
    :name="$attrs.name || $t('Craft Code')"
    :placeholder="$attrs.placeholder || $t('Select Craft Code')"
    :label="getLabel"
    :id="$attrs.id || 'craft-code-select'"
    label-key="code"
  />
</template>
<script>
import { globalResources } from "@/components/form/util";

export default {
  name: 'CraftCodeSelect',
  props: {
    hideLabel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      globalResources,
    }
  },
  computed: {
    getLabel() {
      const label = this.$attrs.label
      if (label !== undefined) {
        return label
      }
      if (this.hideLabel) {
        return ''
      }
      return this.$t('Craft Code')
    }
  },
}
</script>
