<template>
  <BaseFormDialog
      v-bind="$attrs"
      v-on="$listeners"
      :title="$t(`Void Payments`)"
  >
    <VoidDialog
        v-bind="$attrs"
        v-on="$listeners"
        :loading="loading"
        @submit="onSubmit"
        size="xl"
    >
    </VoidDialog>
  </BaseFormDialog>
</template>
<script>
  import axios from 'axios'
  import VoidDialog from '@/components/common/modal/VoidDialog';
  import { $modules } from "@/enum/enums";

  export default {
    components: {
      VoidDialog,
    },
    props: {
      batches: {
        type: Array,
        default: () => [],
      },
      payments: {
        type: Array,
        default: () => [],
      },
      forBillings: {
        type: Boolean,
      }
    },
    data() {
      return {
        fileUrl: null,
        loading: false,
      }
    },
    methods: {
      async onSubmit(data = {}) {
        if (this.batches?.length) {
          await this.voidBatches(data)
        } else if (this.payments?.length) {
          await this.voidPayments(data)
        }
      },
      async voidBatches(data = {}) {
        try {
          this.loading = true
          let url = '/restify/invoice-payments/action?action=void-invoice-payment-batches'
          if (this.forBillings) {
            url = '/restify/billing-payments/action?action=void-billing-payment-batches'
          }
          await axios.post(url,
              {
                batches: this.batches,
                ...data,
              },
          )
          this.$success(this.$t('Payment Batch(es) voided!'))
          this.$emit('update:open', false)
        } catch (err) {
          this.$error('Could not void the provided payment batches')
        } finally {
          this.loading = false
        }
      },
      async voidPayments(data = {}) {
        try {
          this.loading = true
          let url = '/restify/invoice-payments/action?action=void-invoice-payments'
          if (this.forBillings) {
            url = '/restify/billing-payments/action?action=void-billing-payments'
          }
          await axios.post(url,
              {
                repositories: this.payments,
                ...data,
              },
          )
          this.$emit('save')
          this.$emit('update:open', false)
        } catch (err) {
          this.$error('Could not void the provided payments')
        } finally {
          this.loading = false
        }
      },
    },
  }
</script>
