import Vue from 'vue'
import Notifications from './Notifications.vue';

const NotificationStore = {
  state: [], // here the notifications will be added
  settings: {
    overlap: true,
    verticalAlign: 'top',
    horizontalAlign: 'right',
    type: 'info',
    timeout: 5000,
    closeOnClick: true,
    showClose: true
  },
  setOptions(options) {
    this.settings = Object.assign(this.settings, options);
  },
  removeNotification(timestamp) {
    const indexToDelete = this.state.findIndex(n => n.timestamp === timestamp);
    if (indexToDelete !== -1) {
      this.state.splice(indexToDelete, 1);
    }
  },
  addNotification(notification) {
    if (typeof notification === 'string' || notification instanceof String) {
      notification = { message: notification };
    }
    notification.timestamp = new Date();
    notification.timestamp.setMilliseconds(
      notification.timestamp.getMilliseconds() + this.state.length
    );
    notification = Object.assign({}, this.settings, notification);
    this.state.push(notification);
  },
  notify(notification) {
    if (Array.isArray(notification)) {
      notification.forEach(notificationInstance => {
        this.addNotification(notificationInstance);
      });
    } else {
      this.addNotification(notification);
    }
  },
};
let app = new Vue({
  data: {
    notificationStore: NotificationStore
  },
  methods: {
    notify(notification) {
      this.notificationStore.notify(notification, Vue);
    },
    getNotification(notification) {
      if (typeof notification !== 'object') {
        notification = {
          message: notification,
        }
      }
      return notification
    },
    info(notification) {
      this.notify({
        type: 'info',
        ...this.getNotification(notification),
      })
    },
    error(notification) {
      this.notify({
        type: 'danger',
        ...this.getNotification(notification),
      })
    },
    warning(notification) {
      this.notify({
        type: 'warning',
        ...this.getNotification(notification),
      })
    },
    success(notification) {
      this.notify({
        type: 'success',
        ...this.getNotification(notification),
      })
    }
  }
});
const NotificationsPlugin = {
  install(Vue, options) {
    Vue.prototype.$notify = app.notify;
    Vue.prototype.$error = app.error;
    Vue.prototype.$success = app.success;
    Vue.prototype.$info = app.info;
    Vue.prototype.$warning = app.warning;

    Vue.prototype.$notifications = app.notificationStore;
    Vue.component('Notifications', Notifications);
    if (options) {
      NotificationStore.setOptions(options);
    }
  }
};

export const error = app.error
export const success = app.success
export const warning = app.warning
export const info = app.info
export const notify = app.notify
export default NotificationsPlugin;
