<template>
  <div class="flex flex-col">
    <customer-history-table :url-params="urlParams"/>
  </div>
</template>
<script>
import CustomerHistoryTable from '@/modules/accounts-receivable/components/customer-history/CustomerHistoryTable'

export default {
  components: {
    CustomerHistoryTable,
  },
  computed: {
    urlParams() {
      return {
        job_id: this.$route.params.id,
      }
    },
  },
}
</script>
