<template>
  <BaseDataTable
    v-bind="defaultAttributes"
    :columns="columns"
    :data="getData"
    :total-rows="rowsLength"
    :has-summary="false"
    :compact="true"
    class="no-borders"
    :customizeLastSubtotalRow="false"
    @force-pagination="forcePagination"
  >

    <template #report-header>
      <BaseReportHeader :header="data.header" />
    </template>

    <template #header="{ row }">
      <div class="flex items-inline-base">
        <EmployeeLink
          :data="row.header?.employee"
          :show-preview="false"
          class="min-w-[400px]"
        />
        <BaseAddressLink
          :value="row.header?.address"
          class="mx-2"
        />

        <span>{{ $t('SS#') }}</span>
        <span class="mx-2">
          {{ row.header?.employee?.masked_ssn }}
        </span>
        <BasePhoneLink
          :value="row.header?.employee?.phone_1"
          :phoneMask="'(###)###-####'"
          :no-data-text="''"
        />
      </div>
    </template>

    <template #subtotal="{ subtotal }">
      <td />
      <td />
      <td> {{ subtotal.local }} </td>
      <td align="left">
        {{ subtotal?.type }}
      </td>
      <td align="right">
        {{ $formatPrice(subtotal?.q1_amount) }}
      </td>
      <td align="right">
        {{ $formatPrice(subtotal?.q2_amount) }}
      </td>
      <td align="right">
        {{ $formatPrice(subtotal?.q3_amount) }}
      </td>
      <td align="right">
        {{ $formatPrice(subtotal?.q4_amount) }}
      </td>
      <td align="right">
        {{ $formatPrice(subtotal?.total) }}
      </td>
    </template>

    <template #html-row="{ htmlData }">
      <tr class="content-row font-semibold">
        <td colspan="9">
          <div class="py-4">
            {{ htmlData }}
          </div>
        </td>
      </tr>
    </template>

  </BaseDataTable>
</template>
<script>
import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'
import { ToDateEarningsAndTaxesOptions } from '@/modules/payroll/components/reports/util'

export default {
  extends: ReportTableWrapper,
  computed: {
    columns() {
      return [
        {
          label: this.$t('Employee <br> Pay/Deduction'),
          prop: 'pay_deduction',
          align: 'left',
          minWidth: 80,
          maxWidth: 200,
        },
        {
          label: this.$t('ST'), // State
          prop: 'state',
          align: 'right',
          minWidth: 40,
          maxWidth: 60,
        },
        {
          label: this.$t('LC'), // Local
          prop: 'local',
          align: 'right',
          minWidth: 40,
          maxWidth: 60,
        },
        {
          label: this.$t('Type'),
          prop: 'type',
          align: 'left',
          minWidth: 80,
          maxWidth: 200,
        },
        {
          label: this.$t('1st Quarter'),
          prop: 'q1_amount',
          align: 'right',
          minWidth: 80,
          maxWidth: 110,
          formatter: (row, value) => {
            return this.$formatPrice(value)
          },
        },
        {
          label: this.$t('2nd Quarter'),
          prop: 'q2_amount',
          align: 'right',
          minWidth: 80,
          maxWidth: 110,
          formatter: (row, value) => {
            return this.$formatPrice(value)
          },
        },
        {
          label: this.$t('3rd Quarter'),
          prop: 'q3_amount',
          align: 'right',
          minWidth: 80,
          maxWidth: 110,
          formatter: (row, value) => {
            return this.$formatPrice(value)
          },
        },
        {
          label: this.$t('4th Quarter'),
          prop: 'q4_amount',
          align: 'right',
          minWidth: 80,
          maxWidth: 110,
          formatter: (row, value) => {
            return this.$formatPrice(value)
          },
        },
        {
          label: this.$t('Year-To-Date'),
          prop: 'total',
          align: 'right',
          minWidth: 80,
          maxWidth: 110,
          formatter: (row, value) => {
            return this.$formatPrice(value)
          },
        },
      ]
    },
    print() {
      return this.options.print
    }
  },
  methods: {
    getDetailEmployeeLines(groupItems) {
      if (this.print !== ToDateEarningsAndTaxesOptions.PrintBy.Detail) {
        return []
      }

      const employeeLines = []

      for (const state in groupItems) {
        for (const code in groupItems[state]) {
          const entry = groupItems[state][code]
          employeeLines.push({
            pay_deduction: `${entry.code.code} ${entry.code.description}`,
            state: entry.state,
            local: entry.local,
            type: entry.type,
            q1_amount: entry.ytd.q1_amount,
            q2_amount: entry.ytd.q2_amount,
            q3_amount: entry.ytd.q3_amount,
            q4_amount: entry.ytd.q4_amount,
            total: entry.total
          })
        }
      }

      return employeeLines
    },
    addEmployeeRows(data) {
      data.forEach(group => {
        const groupItems = group.items
        const headerLine = {
          header: {
            employee: group.employee,
            address: group.address,
          },
        }

        const employeeDetailLines = this.getDetailEmployeeLines(groupItems)

        const subtotalLines = []

        for (const type in group.totals) {
          const entry = group.totals[type]
          subtotalLines.push({
            subtotal: {
              local: this.$t('Total'),
              type: entry.type,
              q1_amount: entry.q1_amount,
              q2_amount: entry.q2_amount,
              q3_amount: entry.q3_amount,
              q4_amount: entry.q4_amount,
              total: entry.total
            }
          })
        }

        const employeeRows = [
          headerLine,
          ...employeeDetailLines,
          ...subtotalLines,
        ]

        this.rows.push(
          ...employeeRows
        )
      })
    },
    addGrandTotals() {
      this.rows.push(
        {
          htmlData: this.$t('Grand Totals for Selected Payroll Register'),
        },
      )

      const grand_totals = this.data.grand_totals
      const sub_totals = this.data.sub_totals

      for (const type in grand_totals) {
        const typeRows = []

        for (const state in grand_totals[type]) {
          for (const code in grand_totals[type][state]) {
            const entry = grand_totals[type][state][code]

            typeRows.push({
              pay_deduction: `${entry.code.code} ${entry.code.description}`,
              state: entry.state,
              type: entry.type,
              q1_amount: entry.q1_amount,
              q2_amount: entry.q2_amount,
              q3_amount: entry.q3_amount,
              q4_amount: entry.q4_amount,
              total: entry.total
            })
          }
        }

        const entry = sub_totals[type]

        if (entry) {
          typeRows.push({
            subtotal: {
              local: this.$t('Sub Totals:'),
              q1_amount: entry.q1_amount,
              q2_amount: entry.q2_amount,
              q3_amount: entry.q3_amount,
              q4_amount: entry.q4_amount,
              total: entry.total
            }
          })
        }

        this.rows.push(
          ...typeRows
        )
      }
    },
    composeRows(data) {
      if (this.print !== ToDateEarningsAndTaxesOptions.PrintBy.GrandTotals) {
        this.addEmployeeRows(data)
      }

      this.addGrandTotals()

      return this.rows
    },
  }
}
</script>
