export default {
  update: (el, _, vnode) => {
    el.addEventListener('keyup', async (e) => {
      if (!e?.target?.value) {
        return
      }
      e.target.value = e.target.value.toUpperCase()
      vnode?.componentInstance?.$emit('input', e.target.value.toUpperCase())
      e.target.style.textTransform = 'uppercase'
    })
  },
}
