<template>
  <AgDataTable
      :columns="columns"
      :url-params="urlParams"
      :groupIncludeFooter="true"
      :groupIncludeTotalFooter="true"
      url="/restify/material-histories"
      actions="search,refresh"
      domLayout="autoHeight"
  >
    <template #attributes.business_id="{row}">
      <component
          v-if="row.attributes.business_id"
          :is="getBusinessComponent(row.attributes.business_type)"
          :id="row.attributes.business_id"
          :show-description="false"
          :show-name="false"
      />
      <span v-else/>
    </template>
    <template #attributes.journal_id="{row}">
      <JournalLink
          :id="row.attributes.journal_id"
          :data="row.relationships.journal"
      />
    </template>
  </AgDataTable>
</template>
<script>

import TransactionHistory from "@/modules/job-costing/components/line-items/TransactionHistory.vue";
import i18n from "@/i18n";
import { transactionBusinessLinkByTypes } from "@/enum/enums";
import AddlSourceLink from "@/components/links/AddlSourceLink.vue";
import SourceLink from "@/components/links/SourceLink.vue";

export default {
  components: {
    SourceLink,
    AddlSourceLink,
    TransactionHistory,
  },
  computed: {
    columns() {
      return [
        {
          headerName: this.$t('Description'),
          field: 'attributes.description',
          minWidth: 200,
          maxWidth: 400,
        },
        {
          headerName: this.$t('Fiscal Year / Period'),
          field: 'attributes.fiscal_year',
          minWidth: 80,
          maxWidth: 110,
          valueFormatter: params => {
            if (!params.data || !params.value) {
              return ''
            }
            return `${params.value} / ${params.data?.attributes?.period}`
          },
        },
        {
          headerName: this.$t('Journal'),
          field: 'attributes.journal_id',
          minWidth: 100,
          maxWidth: 150,
        },
        {
          headerName: this.$t('Date'),
          field: 'attributes.reference_date',
          minWidth: 80,
          maxWidth: 110,
          component: 'FormattedDate',
        },
        {
          label: i18n.t('Employee Vend/Cust'),
          prop: 'attributes.business_id',
          minWidth: 100,
          maxWidth: 200,
        },
        {
          headerName: this.$t('Account'),
          field: 'attributes.account',
          minWidth: 100,
          maxWidth: 120,
          component: 'AccountLink',
          cellRendererParams: {
            showDescription: false,
          },
        },
        {
          headerName: this.$t('Job W/O EQP'),
          field: 'relationships.source.number',
          minWidth: 100,
          maxWidth: 120,
        },
        {
          headerName: this.$t('Quantity'),
          field: 'attributes.meta.quantity',
          minWidth: 120,
          maxWidth: 200,
          align: 'right',
          valueFormatter: params => {
            if (params.value === undefined) {
              return ''
            }
            let value = params.value ? params.value.toFixed(3) : ''
            const um = params.data?.attributes?.meta?.um || ''
            return `${value} ${um}`
          },
          aggFunc: 'sum',
        },
        {
          headerName: this.$t('$ Amount'),
          field: 'attributes.amount',
          minWidth: 100,
          maxWidth: 150,
          component: 'FormattedPrice',
          align: 'right',
          aggFunc: 'sum',
        },
      ]
    },
    urlParams() {
      return {
        material_id: this.$route.params.id,
        related: 'journal[id|name],source[id|code|name],addlSource[id|code|name|phase_code|cost_code|change_order],account[id|number]',
      }
    },
  },
  methods: {
    getBusinessComponent(businessType) {
      return transactionBusinessLinkByTypes[businessType]
    },
  }
}
</script>
