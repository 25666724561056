<template>
  <CompanyForm
      v-loading="companyLoading"
      :data="company"
      layout="modal"
      @cancel="$router.push('/companies')"
  />
</template>

<script>
  import CompanyForm from '@/modules/settings/components/CompanyForm';

  export default {
    name: 'company-edit',
    components: {
      CompanyForm,
    },
    computed: {
      company() {
        return this.$store.state.company.currentCompany
      },
      companyLoading() {
        return this.$store.state.company.currentCompanyLoading
      },
    },
  }
</script>
