import ProfileLayout from "@/modules/profile/layout/ProfileLayout";
import ProfileEdit from "@/modules/profile/pages/profile-edit";
import ProfilePreferences from "@/modules/profile/pages/profile-preferences";
import ProfileShortcuts from "@/modules/profile/pages/profile-shortcuts";
import ProfileSettings from "@/modules/profile/pages/profile-settings";

export default [
  {
    path: '/profile',
    name: 'Profile',
    redirect: '/profile/edit',
    meta: {
      productionReady: true,
      hideBreadCrumb: true,
    },
    component: ProfileLayout,
    children: [
      {
        path: 'edit',
        name: 'Profile Edit',
        title: 'Profile Edit',
        description: 'Manage your profile data, session and avatar',
        component: ProfileEdit
      },
      {
        path: 'preferences',
        name: 'Layout Preferences',
        title: 'Layout Preferences',
        description: 'Manage your preferences such as preferred layout, colors and font size',
        component: ProfilePreferences
      },
      {
        path: 'shortcuts',
        name: 'Keyboard Shortcuts',
        title: 'Keyboard Shortcuts',
        description: 'See keyboard shortcuts for the application',
        component: ProfileShortcuts
      },
      {
        path: 'settings',
        name: 'Profile Settings',
        title: 'Profile Settings',
        description: 'Manage your profile settings',
        component: ProfileSettings
      },
    ]
  }
]
