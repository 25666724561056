<template>
  <div>
    <BaseDataTable :columns="columns"
                   :edit-entity-url-query="editEntityUrlQuery"
                   :add-text="$t('New department code')"
                   url="/restify/departments"
                   default-sort="code"
                   actions="search,refresh,print"
                   permission="employees"
                   ref="table"
                   hide-actions="view"
                   @add="onRowAdd"
                   @edit="onRowEdit"
    />
    <BaseFormDialog v-if="showDialog"
                    :title="rowToEdit ? $t('Edit Department Code') : $t('Add Department Code')"
                    @close="onEventTrigger"
                    :open.sync="showDialog">
      <DepartmentCodeForm :data="rowToEdit"
                          :key="renderKey"
                          @cancel="onEventTrigger"
                          @save="onEventTrigger"
                          @create-another="renderKey++"
      />
    </BaseFormDialog>
  </div>
</template>
<script>
  import DepartmentCodeForm from '@/modules/payroll/components/codes/DepartmentCodeForm'

  export default {
    components: {
      DepartmentCodeForm,
    },
    data() {
      return {
        renderKey: 1,
        editEntityUrlQuery: '?id={ID}',
        rowToEdit: null,
        showDialog: false,
        columns: [
          {
            label: this.$t('Code'),
            prop: 'attributes.code',
            component: 'EntityLink',
            redirectTo: '/payroll/codes/department-codes?id={ID}',
          },
          {
            label: this.$t('Description'),
            prop: 'attributes.description',
          },
          {
            label: this.$t('EEOC Job Category'),
            prop: 'attributes.eeoc_job_category',
            component: 'FormattedText',
          },
        ],
      }
    },
    methods: {
      onRowEdit(row) {
        this.showDialog = true
        this.rowToEdit = row
      },
      onRowAdd() {
        this.showDialog = true
      },
      refreshTable() {
        this.$refs.table.refresh()
      },
      onEventTrigger() {
        this.rowToEdit = null
        this.showDialog = false
        this.refreshTable()
      },
    },
  }
</script>
