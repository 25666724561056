import AuthLayout from "@/modules/auth/layout/AuthLayout";
import Login from "@/modules/auth/pages/login";
import RegisterConfirm from "@/modules/auth/pages/register-confirm";
import ForgotPassword from "@/modules/auth/pages/forgot-password";
import ResetPassword from "@/modules/auth/pages/reset-password";
import PayrollConsent from "@/modules/auth/pages/payroll-consent";
import EmployeeConsentOptout from "@/modules/auth/pages/employee-consent-optout";
import NoOrganization from "@/modules/auth/pages/no-organization";
import Version from "@/modules/auth/pages/version";
import NotFound from "@/modules/auth/pages/404";
import ChooseOrganization from "@/modules/auth/pages/choose-organization.vue";

const hasToken = () => localStorage.getItem('token')

export default [
  {
    path: '/',
    components: {
      default: AuthLayout
    },
    redirect: hasToken() ? '/welcome': '/login',
    meta: {
      productionReady: true,
    },
    children: [
      {
        path: 'login',
        name: 'Login',
        component: Login
      },
      {
        path: 'choose-organization',
        name: 'Choose Organization',
        component: ChooseOrganization
      },
      {
        path: 'register',
        name: 'Register',
        component: RegisterConfirm
      },
      {
        path: 'forgot-password',
        name: 'Forgot Password',
        component: ForgotPassword
      },
      {
        path: 'password/reset',
        name: 'Reset Password',
        component: ResetPassword
      },
    ]
  },
  {
    path: '/payroll-consent',
    name: 'Payroll Consent',
    meta: {
      productionReady: true,
    },
    component: PayrollConsent
  },
  {
    path: '/employee-consent/optout',
    name: 'Employee Consent Opt Out',
    meta: {
      productionReady: true,
    },
    component: EmployeeConsentOptout
  },
  {
    path: '/no-organization',
    name: 'Organization Not Found',
    meta: {
      skipOrganizationCheck: true,
      productionReady: true,
    },
    component: NoOrganization
  },
  {
    path: '/version',
    name: 'Version Page',
    meta: {
      skipOrganizationCheck: true,
      productionReady: true,
    },
    component: Version,
  },
  {
    path: '*',
    name: 'Not Found',
    meta: {
      skipOrganizationCheck: true,
      productionReady: true,
    },
    component: NotFound,
  },
]
