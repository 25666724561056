<template>
  <BaseDataTable :columns="columns"
                 :url-params="urlParams"
                 :show-pagination="false"
                 url="/restify/payrolls"
  >
    <template v-slot:employee="{row}">
      <entity-preview :entity="get(row, 'relationships.employee.attributes')"
                      :key="`preview-${row.attributes.employee_id}`"
                      :url="`/payroll/employees/${row.attributes.employee_id}/view`"
                      :value-to-display="get(row, 'relationships.employee.attributes.code', '')"
                      :parent-entity-id="row.attributes.employee_id"
                      entity-name="employee"
      />
    </template>
  </BaseDataTable>
</template>
<script>

  export default {
    name: 'PayrollBatch',
    inheritAttrs: false,
    props: {
      id: String,
    },
    computed: {
      columns() {
        return [
          {
            label: this.$t('Employee'),
            prop: 'employee',
          },
          {
            label: this.$t('Bank'),
            prop: 'relationships.bank.attributes.name'
          },
          {
            label: this.$t('Status'),
            prop: 'attributes.status',
            component: 'Status',
          },
          {
            label: this.$t('Type'),
            prop: 'attributes.type',
            component: 'Status',
          },
        ]
      },
      urlParams() {
        return {
          payroll_batch_id: this.id,
          related: 'employee,timecard,bank'
        }
      }
    }
  }
</script>
