<template>
  <div>
    <span class="action-item-text"
          @click="showDialog = true">
      <div class="p-2 bg-green-50 mr-2 rounded-md">
        <DownloadIcon class="w-4 h-4 text-green-500"/>
      </div>
      <span>{{ $t('Export') }}</span>
    </span>

    <BaseFormDialog v-if="showDialog"
                    :title="$t(`Export ${modelName}`)"
                    :open.sync="showDialog"
                    :append-to-body="true"
                    size="xl"
    >
      <ValidationObserver v-slot="{ valid }">
        <base-form layout="modal" :focusOnFirstInput="false">
          <div class="col-span-6 md:col-span-3">
            <base-select v-model="exportTo"
                         :label="$t('Export To')"
                         :placeholder="$t('Export To')"
                         :options="exportOptions"
                         id="export_to">
            </base-select>
          </div>
          <div v-if="exportTo !== 'clipboard'"
               class="col-span-6 md:col-span-3">
            <base-input v-model="fileNameToExport"
                        :label="$t('File Name')"
                        :placeholder="$t('File Name')"
                        id="name"
                        rules="required">
            </base-input>
          </div>
          <div class="col-span-6">
            <component :is="exportComponent"
                       :export-model="exportModel"
                       :url="url"
                       :requestParams="requestParams"
                       ref="modelToExport"/>
          </div>
          <template v-slot:footer>
            <div class="flex items-center">
              <base-cancel-button class="mr-3"
                                  @click="showDialog = false">
                {{ $t('Cancel') }}
              </base-cancel-button>
              <base-submit-button :loading="loading"
                                  :disabled="!valid"
                                  @click="download">
                {{ $t('Export') }}
              </base-submit-button>
            </div>
          </template>
        </base-form>
      </ValidationObserver>
    </BaseFormDialog>
  </div>
</template>
<script>
  import axios from 'axios'
  import startCase from 'lodash/startCase'
  import { DownloadIcon } from 'vue-feather-icons'
  import AccountBalances from './export-models/AccountBalances'
  import DefaultExportModel from './export-models/DefaultExportModel'
  import { downloadFileLocally } from "@/modules/common/util/downloadFileLocally";

  export default {
    name: 'ExportAction',
    components: {
      DownloadIcon,
      AccountBalances,
      DefaultExportModel,
    },
    props: {
      url: {
        type: String,
        default: '',
      },
      exportModel: {
        type: String,
        default: 'AccountBalances',
      },
      requestParams: {
        type: Object,
        default: () => ({})
      },
      defaultEnclosure: {
        type: String,
        default: '"',
      }
    },
    data() {
      return {
        loading: false,
        showDialog: false,
        exportOptions: [
          {
            label: 'Clipboard',
            value: 'clipboard',
          },
          {
            label: 'CSV',
            value: 'csv',
          },
          {
            label: 'Excel',
            value: 'xlsx',
          }
        ],
        exportTo: 'clipboard',
        fileNameToExport: '',
      }
    },
    computed: {
      exportComponent() {
        const mappings = {
          'AccountBalances': AccountBalances,
          'Employees': DefaultExportModel,
          default: DefaultExportModel,
        }
        return mappings[this.exportModel] || mappings.default
      },
      modelName() {
        if (!this.exportModel || this.exportModel === 'default') {
          return ''
        }
        return startCase(this.exportModel)
      }
    },
    methods: {
      getUrlQuery() {
        return this.$refs.modelToExport?.exportUrl
      },
      async download() {
        try {
          this.loading = true
          const url = this.getUrlQuery()
          let format = this.exportTo
          if (format === 'clipboard') {
            format = 'csv'
          }
          let summary = ''

          const requestParams = {
            ...this.requestParams,
          }
          delete requestParams.page

          if (this.exportModel === 'AccountBalances') {
            summary = await axios.get(url, {
              params: requestParams,
              format,
            })
          } else {
            const postData = {
              format,
              settings: {
                enclosure: this.defaultEnclosure,
              },
              columns: this.$refs.modelToExport?.getSelectedColumns(),
              repositories: 'all'
            }
            summary = await axios.post(url, postData, {
              params: requestParams,
              responseType: 'blob'
            })
          }

          if (this.exportTo === 'clipboard') {
            let summaryText = await summary.text();
            await this.copyToClipboard(summaryText)
            this.showDialog = false
            this.$success(this.$t('Data copied to clipboard'))
          } else {
            downloadFileLocally(summary, `${this.fileNameToExport}.${format}`)

            this.showDialog = false
            this.$success(this.$t('Data exported successfully!'))
          }
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Something went wrong please try again.'))
        } finally {
          this.loading = false
        }
      },
      composeFileName() {
        this.fileNameToExport = `${startCase(this.exportModel)} - ${this.$formatDate(new Date())}`
      },
      copyToClipboard(text) {
        return navigator.clipboard.writeText(text)
      }
    },
    mounted() {
      this.composeFileName()
    },
    watch: {
      showDialog(value) {
        if (!value) {
          return
        }
        setTimeout(() => {
          const formElement = this.$el.querySelector('#export_to label')
          if (formElement) {
            formElement.focus()
          }
        }, 300)
      }
    }
  }
</script>

