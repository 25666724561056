<template>
  <div class="flex flex-col space-y-2 w-full pt-2 h-full justify-start leading-normal overflow-x-auto">
    <div v-if="level === 0" class="flex space-x-6">
      <span class="font-medium">
        {{ $t('Job:') }} {{ billing.job?.number }} {{ billing.job?.description }}
      </span>
      <span>
        {{ $t('Customer:') }} {{ billing.customer?.name }}
      </span>
      <span>
        {{ $t('Invoice:') }} #{{ billing.number }}
      </span>
      <span>
        {{ $t('Date:') }} {{ billing.date }}
      </span>
      <span v-if="billing.retention_percent !== undefined">
        {{ $t('Retention:') }} {{ $formatPercent(billing.retention_percent) }}
      </span>
    </div>
    <div v-if="level === 0" class="flex space-x-6">
      <div>
        {{$t('Markup % & Subject to Tax by Type')}}
      </div>
      <div v-for="type in costTypes || []"
           :key="type.abbr"
           class="flex space-x-2">
        <span>{{ type.abbr }}:</span>
        <span>{{ $formatPercent(type.markup_percent) }}</span>
        <span>{{ type.subject_to_tax ? $t('Yes'): $t('No') }}</span>
      </div>
    </div>
    <template v-if="level === 1">
      <div v-if="lineItem.id" class="flex space-x-4">
        <div v-if="lineItem.phase_code" class="flex space-x-2">
          <span>{{$t('Phase Code:')}}</span>
          <span>{{lineItem.phase_code}}</span>
        </div>
        <div v-if="lineItem.cost_code" class="flex space-x-2">
          <span>{{$t('Cost Code:')}}</span>
          <span>{{lineItem.cost_code}}</span>
        </div>
        <div v-if="lineItem.change_order" class="flex space-x-2">
          <span>{{$t('Change Order')}}</span>
          <span>{{lineItem.change_order}}</span>
        </div>
      </div>
      <div class="absolute right-5 text-red-500"
           v-for="error in errors">
        <span>{{error.message}}</span>
        <span v-if="error.open_amount">
        {{ $formatPercent(error.open_amount) }}
      </span>
      </div>
    </template>
  </div>
</template>
<script>
  export default {
    props: {

    },
    computed: {
      data() {
        return this.params.node?.allLeafChildren[0]?.data || {}
      },
      billing() {
        return this.data.billing || {}
      },
      costTypes() {
        return this.billing?.meta?.cost_types || []
      },
      lineItem() {
        return this.data?.lineItem || {}
      },
      errors() {
        return this.data.billing?.errors || []
      },
      level() {
        return this.params.node.level
      }
    }
  }
</script>
