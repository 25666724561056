<template>
  <EquipmentClassForm
      :data="classCode"
      :key="classCode.id"
      :readonly="$route.path.includes('view')"
  />
</template>
<script>
  import axios from 'axios'
  import EquipmentClassForm from '@/modules/equipment/components/EquipmentClassForm'

  export default {
    components: {
      EquipmentClassForm,
    },
    data() {
      return {
        classCode: {},
      }
    },
    methods: {
      async getClassCode() {
        const { data } = await axios.get(`/restify/equipment-classes/${this.$route.params.id}`)
        this.classCode = data
      },
    },
    async mounted() {
      await this.getClassCode()
    },
  }
</script>
