<template>
  <MaterialForm
    :data="currentMaterial"
    @refresh="getMaterial"
  />
</template>
<script lang="ts" setup>
import store from '@/store'
import { computed } from 'vue'
import MaterialForm from '@/modules/purchasing-inventory/components/MaterialForm.vue'

const currentMaterial = computed(() => {
  return store.state.inventoryManagement.currentMaterial
})

function getMaterial() {
  store.dispatch('inventoryManagement/getMaterial', currentMaterial.value.id)
}
</script>
