<template>
  <div class="-mt-4">
    <portal to="page-title">
      &nbsp;
    </portal>
    <PaymentsWidget
        :title="$t('AP Paid')"
        url="/dashboard/accounts-payable/monthly-payment"
    />

    <NavigationCardList :data="cardItems"/>
  </div>
</template>
<script>
  import get from "lodash/get";
  import NavigationCardList from "@/modules/dashboard/components/NavigationCardList";
  import PaymentsWidget from "@/modules/accounts-receivable/pages/dashboard/widgets/PaymentsWidget";

  export default {
    components: {
      PaymentsWidget,
      NavigationCardList,
    },
    computed: {
      cardItems() {
        return [
          {
            name: this.$t('Vendors'),
            routeName: this.$t('Vendors'),
            icon: 'IconVendors',
            color: 'green',
            path: '/accounts-payable/vendors',
          },
          {
            name: this.$t('Invoices'),
            routeName: this.$t('Pending Invoices'),
            icon: 'IconInvoices',
            color: 'yellow',
            path: '/accounts-payable/invoices/pending',
          },
          {
            name: this.$t('Payments'),
            routeName: this.$t('All Payments'),
            icon: 'IconPayroll',
            color: 'green',
            path: '/accounts-payable/payments/all',
          },
          {
            name: this.$t('Vendor History'),
            routeName: this.$t('Review Vendor History'),
            icon: 'IconJournals',
            color: 'blue',
            path: '/accounts-payable/vendor-review-history',
          },
        ]
      },
      filteredCards() {
        return this.cardItems.filter(item => {
          const route = this.findRoute(item.routeName)
          const permissions = get(route, 'meta.permissions', [])
          return this.$can(permissions);
        })
      },
    },
  }
</script>
<style>
</style>
