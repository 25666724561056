<template>
  <div v-loading="loading">
    <layout-tabs :items="filteredItems"/>
    <router-view v-if="!loading"/>
  </div>
</template>
<script>
  import { BILL_TO_STORAGE_KEY } from '@/modules/accounts-receivable/pages/billing-rates/billingRates'

  export default {
    data() {
      return {
        loading: false,
      }
    },
    computed: {
      items() {
        return [
          {
            name: this.$t('Material'),
            tooltip: this.$t('Material Bill Type'),
            path: '/accounts-receivable/settings/billing-rates/material',
            permission: 'invoices_show',
          },
          {
            name: this.$t('Equipment'),
            tooltip: this.$t('Equipment Bill Type'),
            path: '/accounts-receivable/settings/billing-rates/equipment',
            permission: 'invoices_show',
          },
          {
            name: this.$t('Craft / Level'),
            tooltip: this.$t('Craft/Level Bill Type'),
            path: '/accounts-receivable/settings/billing-rates/craft-code',
            permission: 'invoices_show',
          },
        ]
      },
      filteredItems() {
        return this.items.filter(item => this.$can(item.permission))
      },
    },
    methods: {
      async getBillingRateTypes() {
        this.loading = true
        await this.$store.dispatch('globalLists/getBillingRateTypes')
        this.loading = false
      },
    },
    mounted() {
      this.getBillingRateTypes()
    },
    beforeDestroy() {
      if (localStorage.getItem(BILL_TO_STORAGE_KEY)) {
        localStorage.removeItem(BILL_TO_STORAGE_KEY)
      }
    }
  }
</script>
