<template>
  <div>
    <BaseDataTable :columns="columns"
                   :url="url"
                   :edit-entity-url-query="editEntityUrlQuery"
                   :add-text="$t('New OCIP Code')"
                   import-url="/payroll/codes/ocip-codes/import"
                   view-entity-url-query="/payroll/codes/ocip-codes"
                   actions="search,refresh,print,import,export"
                   default-sort="code"
                   permission="accounts"
                   ref="table"
                   @add="onRowAdd"
                   @edit="onRowEdit"
    />
    <BaseFormDialog v-if="showDialog"
                    :title="rowToEdit ? $t('Edit OCIP Code') : $t('Add OCIP Code')"
                    :open.sync="showDialog"
                    @close="onEventTrigger">
      <OcipForm :data="rowToEdit"
                :key="renderKey"
                @cancel="onEventTrigger"
                @save="onEventTrigger"
                @create-another="renderKey++"
      />
    </BaseFormDialog>
  </div>
</template>
<script>
  import OcipForm from '@/modules/payroll/components/rates/OcipForm'

  export default {
    components: {
      OcipForm,
    },
    data() {
      return {
        renderKey: 1,
        editEntityUrlQuery: '?id={ID}',
        url: '/restify/ocip-codes',
        rowToEdit: null,
        showDialog: false,
        columns: [
          {
            label: this.$t('Code'),
            prop: 'attributes.code',
            component: 'EntityLink',
            redirectTo: '/payroll/codes/ocip-codes?id={ID}',
          },
          {
            label: this.$t('Description'),
            prop: 'attributes.description',
          },
        ],
      }
    },
    methods: {
      onRowEdit(row) {
        this.showDialog = true
        this.rowToEdit = row.attributes
      },
      onRowAdd() {
        this.showDialog = true
      },
      refreshTable() {
        this.$refs.table.refresh()
      },
      onEventTrigger() {
        this.rowToEdit = null
        this.showDialog = false
        this.refreshTable()
      },
    },
  }
</script>
