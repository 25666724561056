import i18n from "@/i18n";

export const AccrualMethods = {
  // Fixed
  PerAnniversaryYear: 'per_anniversary_year',
  PerCalendarYear: 'per_calendar_year',
  PerPayPeriod: 'per_pay_period',

  // Hourly
  PerHourPaid: 'per_hour_paid',
  PerHourPaidNoOvertime: 'per_hour_paid_no_overtime',
  PerHourWorked: 'per_hour_worked',

  // No Limit
  Unlimited: 'unlimited',
}

export const AccrualMethodLabels = {
  [AccrualMethods.PerAnniversaryYear]: i18n.t('Given Per Anniversary Year'),
  [AccrualMethods.PerCalendarYear]: i18n.t('Given Per Calendar Year'),
  [AccrualMethods.PerPayPeriod]: i18n.t('Accrued Per Pay Period'),
  [AccrualMethods.PerHourPaid]: i18n.t('Accrued Per Hours Paid'),
  [AccrualMethods.PerHourPaidNoOvertime]: i18n.t('Accrued Per Hours Paid No Overtime'),
  [AccrualMethods.PerHourWorked]: i18n.t('Accrued Per Hours Worked'),
  [AccrualMethods.Unlimited]: i18n.t('Unlimited'),
}

export const AccrualMethodOptions = Object.keys(AccrualMethodLabels).map(key => {
 return {
   value: key,
   label: AccrualMethodLabels[key],
 }
})
