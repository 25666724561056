import { reactive } from "vue";
import {GridApi} from "@ag-grid-community/core";

export const gridContext = reactive({
  storageKey: 'default',
  loading: false,
  isPrintMode: false,
  filtersUrl: '',
  filterOptions: {} as Record<string, any>,
  matchFilters: { } as Record<string, any>,
  advancedFilters: [],
  fullMatchFilters: {} as Record<string, any>,
  displayMatchFilters: {} as Record<string, any>,
  lastRequestParams: {} as Record<string, any>,
  perPageOptions: [5, 10, 15, 20, 25, 50, 100],
  api: null as GridApi | null,
})

export function beforePrint() {
  if (gridContext.isPrintMode) {
    return
  }
  gridContext.isPrintMode = true;
}

export function afterPrint() {
  if (!gridContext.isPrintMode) {
    return
  }
  gridContext.isPrintMode = false;
}

if (window.matchMedia) {
  const mediaQueryList = window.matchMedia('print');
  mediaQueryList.addEventListener('change', (mql) => {
    if (mql.matches) {
      beforePrint();
    } else {
      afterPrint();
    }
  });
  if (mediaQueryList?.matches) {
    beforePrint()
  }
}

window.onbeforeprint = beforePrint;
window.onafterprint = afterPrint;
