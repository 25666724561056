import { RRule, rrulestr } from "rrule";
import i18n from "@/i18n";
import { isDevOrLocal } from "@/isProduction";

export const FrequencyType = {
  Hourly: 'hourly',
  Daily: 'daily',
  Weekly: 'weekly',
  Monthly: 'monthly',
  Quarterly: 'quarterly',
  Custom: 'custom'
}

export const StopType = {
  Never: 'never',
  After: 'after',
  On: 'on'
}


export function getRuleFrequency(ruleOptions) {
  const frequencyMap = {
    [RRule.HOURLY]: FrequencyType.Hourly,
    [RRule.DAILY]: FrequencyType.Daily,
    [RRule.WEEKLY]: FrequencyType.Weekly,
    [RRule.MONTHLY]: FrequencyType.Monthly,
  }
  let frequencyType = frequencyMap[ruleOptions.freq]
  if (ruleOptions.freq === RRule.MONTHLY && ruleOptions.interval === 3) {
    frequencyType = FrequencyType.Quarterly
  }
  return frequencyType
}

export function getFrequencyLabel(ruleOptions) {
  const options = getFrequencyOptions()
  const frequency = getRuleFrequency(ruleOptions)
  return options.find(o => o.value === frequency)?.label
}

export function getStopType(ruleOptions) {
  let stopType = StopType.Never
  if (ruleOptions.count) {
    stopType = StopType.After
  }
  if (ruleOptions.until) {
    stopType = StopType.On
  }
  return stopType
}

export function getStopTypeOptions() {
  return [
    {
      value: StopType.Never,
      label: i18n.t('Never')
    },
    {
      value: StopType.After,
      label: i18n.t('After')
    },
    {
      value: StopType.On,
      label: i18n.t('On')
    }
  ]
}

export function getFrequencyOptions() {
  const options = [
    { value: FrequencyType.Hourly, label: i18n.t('Hourly'), enabled: () => isDevOrLocal(), },
    { value: FrequencyType.Daily, label: i18n.t('Daily'), enabled: () => isDevOrLocal(), },
    { value: FrequencyType.Weekly, label: i18n.t('Weekly') },
    { value: FrequencyType.Monthly, label: i18n.t('Monthly') },
    { value: FrequencyType.Quarterly, label: i18n.t('Quarterly') },
  ]
  return options.filter(option => option.enabled ? option.enabled() : true)
}

export function getFrequencyLabelFromPayable(data) {
  try {
    const ruleStr = data?.attributes?.recurring_rule
    const rule = rrulestr(ruleStr)
    return getFrequencyLabel(rule?.options || {})
  } catch (err) {
    console.log(err)
    return ''
  }
}
