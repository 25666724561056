<template>
  <div>
    <BaseDataTable :columns="columns"
                   :add-entity-in-new-tab="true"
                   :is-expandable="true"
                   :add-text="$t('New union rate')"
                   entity="union"
                   ref="table"
                   url="/restify/unions"
                   import-url="/payroll/rates/unions/import"
                   default-sort="code"
                   actions="search,refresh,import,export,view,print"
                   permission="employees"
                   view-entity-url-query="/payroll/rates/unions"
                   @add="$router.push('/payroll/rates/union/add')"
                   @edit="onView"
    >
      <template #expand-content="{row}">
        <UnionRatesTable :union="row"/>
      </template>
    </BaseDataTable>
    <BaseFormDialog v-if="showDialog"
                    :title="$t('Union Details')"
                    :open.sync="showDialog"
                    size="xl"
                    @close="onEventTrigger">
      <base-detail-card :title="$t('Details')"
                        :key="unionToView.id"
                        :entity="unionToView"
                        :can-expand="false"
                        :expanded="true"
                        class="m-4"
                        entity-name="union"
      >
        <base-detail-card-row :title="$t('Union')">
          {{ unionToView.attributes.code }}
          <template v-if="unionToView.attributes.description">({{ unionToView.attributes.description }})</template>
        </base-detail-card-row>
        <base-detail-card-row :title="$t('Rates')"
                              stripe>
          <status-badge :status="Number(unionToView.attributes.rates_count)"/>
        </base-detail-card-row>
      </base-detail-card>
      <base-detail-card :title="$t('Rates')"
                        :entity="unionToView"
                        :can-expand="true"
                        :expanded="true"
                        class="m-4"
                        entity-name="union"
      >
        <UnionRatesTable :union="unionToView"/>
      </base-detail-card>
    </BaseFormDialog>
  </div>
</template>
<script>
  import UnionRatesTable from '@/modules/payroll/components/rates/UnionRatesTable'

  export default {
    components: {
      UnionRatesTable,
    },
    data() {
      return {
        showDialog: false,
        unionToView: {},
        columns: [
          {
            label: this.$t('Code'),
            prop: 'attributes.code',
            component: 'EntityLink',
            redirectTo: '/payroll/rates/unions?id={ID}',
          },
          {
            label: this.$t('Description'),
            prop: 'attributes.description',
          },
          {
            label: this.$t('Rates'),
            prop: 'attributes.rates_count',
            minWidth: 60,
            maxWidth: 100,
            align: 'center',
            component: 'Count',
          },
        ],
      }
    },
    methods: {
      onView(row) {
        this.showDialog = true
        this.unionToView = row
      },
      onEventTrigger() {
        this.unionToView = null
        this.showDialog = false
        this.refreshTable()
      },
      refreshTable() {
        this.$refs.table.refresh()
      },
    },
  }
</script>
