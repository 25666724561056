<template>
  <div class="import-data mt-4">
    <ImportForm v-bind="importSettings"
                @success="redirectToList"
    >
      <FiscalYearSelect
          v-model="model.year"
          :label="$t('Fiscal Year')"
          :default-previous-year="true"
          class="col-span-6 md:col-span-1"
          id="fiscal_year"
      />
      <PeriodSelect
          v-model="model.period"
          :placeholder="$t('Period')"
          :default-to-last-period="true"
          class="col-span-6 md:col-span-1"
          id="period"
      />
    </ImportForm>
  </div>
</template>
<script>
  import ImportForm from "@/modules/common/components/import/ImportForm";
  import { getInitialMapping } from "@/modules/payroll/utils/timecardImportColumns";
  import Cache from "@/utils/Cache";

  const colMappings = {
    Number: {
      value: 'number',
      label: 'Number',
      alternativeLabels: ['Invoice No', 'Invoice Number'],
      required: true,
    },
    Description: {
      value: 'description',
      label: 'Description',
      alternativeLabels: [],
    },
    Date: {
      value: 'date',
      label: 'Date',
      alternativeLabels: ['Invoice Date'],
      required: true,
    },
    DueDate: {
      value: 'due_date',
      label: 'Due Date',
      alternativeLabels: ['Invoice Due Date'],
      required: true,
    },
    GrossAmount: {
      value: 'gross_amount',
      label: 'Gross Amount',
      alternativeLabels: ['Amount'],
    },
    DiscountAmount: {
      value: 'discount_amount',
      label: 'Discount Amount',
      alternativeLabels: ['Discount'],
    },
    VendorCode: {
      value: 'vendor_code',
      label: 'Vendor Code',
      alternativeLabels: ['Vendor'],
      required: true,
    }
  }

  const options = [
    colMappings.Number,
    colMappings.Description,
    colMappings.Date,
    colMappings.DueDate,
    colMappings.DiscountAmount,
    colMappings.GrossAmount,
    colMappings.VendorCode,
  ]

  export default {
    components: {
      ImportForm,
    },
    data() {
      return {
        model: {
          year: null,
          period: null,
        },
        importSettings: {
          url: '/restify/accounts-payable-init-entries/import',
          columnOptions: options,
          columnMappings: colMappings,
          initialMapping: getInitialMapping(options),
          type: 'account-payable-init-import',
          resource: 'account-payable-init',
          transformRequestData: this.transformRequestData,
        },
      }
    },
    methods: {
      transformRequestData(formData) {
        formData.append('fiscal_year', this.model.year)
        formData.append('period', this.model.period)
      },
      async redirectToList() {
        Cache.removeForEntity('accounts-payable-inits')
        await this.$store.dispatch('globalLists/getAPConfiguration', true)
        await this.$router.push('/accounts-payable/settings/ap-initialize')
      },
    }
  }
</script>
