<template>
  <AccountSelect
      ref="select"
      v-focus
      v-model="selectedValue"
      :value-key="params.valueKey"
      :multiple="params.multiple"
      :allow-create="params.allowCreate"
      inline-errors
      class="w-full"
      @change="onChange"
      @keydown.enter.native="goToNextCell"
  />
</template>
<script>
  import { selectEditorMixin } from "@/components/ag-grid/cellEditors/selectEditorMixin";

  export default {
    name: 'AccountSelectCellEditor',
    mixins: [selectEditorMixin],
  }
</script>
