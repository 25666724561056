<template>
  <ProofListing
      entity="billing"
      url="/restify/billings/actions?action=post-lump-sum-billings-proof-listing"
      post-url="/restify/billings/action?action=post-billings"
      ids-field="billing_ids"
      @data-fetch="data = $event"
      #default="{ loading }"
  >
    <AgDataTable
        :data="flatData"
        :data-loading="loading"
        :columns="columns"
        :pagination="false"
        :groupDefaultExpanded="-1"
        :compact="true"
        :no-borders="true"
        :groupIncludeFooter="true"
        :suppressAggFuncInHeader="true"
        :sortable="false"
        :groupRowRendererParams="groupRowRendererParams"
        groupDisplayType="groupRows"
        domLayout="autoHeight"
    >

    </AgDataTable>

    <div class="mt-4 print:break-inside-avoid">
      <h5 class="form-section-title">
        {{ $t('Totals') }}
      </h5>
      <AgDataTable
          :data="totalsData"
          :columns="totalColumns"
          :pagination="false"
          :no-borders="true"
          :compact="true"
          dom-layout="autoHeight"
      />
    </div>

    <div class="grid md:grid-cols-2 gap-4 mt-8 print:mt-4 print:break-inside-avoid">
      <div>
        <h5 class="form-section-title">
          {{ $t('Cost Type Summary') }}
        </h5>
        <ProofListingCostTypeSummary
            :data="data.cost_type_summary"
            :loading="loading"
        />
      </div>
      <div>
        <h5 class="form-section-title">
          {{ $t('Account Summary') }}
        </h5>
        <ProofListingAccountSummary
            :data="data.account_summary"
            :loading="loading"
        />
      </div>
    </div>
  </ProofListing>
</template>
<script>
  import InvoiceProofListingEntries from "@/modules/accounts-payable/components/invoice/InvoiceProofListingEntries";
  import ProofListingAccountSummary from "@/modules/common/components/ProofListingAccountSummary";
  import ProofListingCostTypeSummary from "@/modules/common/components/ProofListingCostTypeSummary";
  import PostDialog from "@/components/common/modal/PostDialog";
  import ProofListing from "@/modules/common/components/proof-listing/ProofListing";
  import LumpSumBillingProofGroupRow
    from "@/modules/accounts-receivable/pages/billings/forms/lump-sum/LumpSumBillingProofGroupRow.vue";

  export default {
    components: {
      PostDialog,
      ProofListing,
      InvoiceProofListingEntries,
      ProofListingAccountSummary,
      ProofListingCostTypeSummary,
      LumpSumBillingProofGroupRow,
    },
    data() {
      return {
        loading: false,
        data: {
          customers: [],
          account_summary: [],
          cost_type_summary: {},
        },
      }
    },
    computed: {
      totalsData() {
        const {
          batch_totals,
          normal_invoices_totals,
          prepaid_invoices_totals,
          recurring_invoices_totals
        } = this.data;
        return [
          {
            id: crypto.randomUUID(),
            name: this.$t('Batch Totals'),
            ...batch_totals,
          },
          {
            id: crypto.randomUUID(),
            name: this.$t('Total Normal'),
            ...normal_invoices_totals,
          },
          {
            id: crypto.randomUUID(),
            name: this.$t('Total Prepaid Invoices'),
            ...prepaid_invoices_totals,
          },
          {
            id: crypto.randomUUID(),
            name: this.$t('Total Recurring Invoices'),
            ...recurring_invoices_totals,
          },
        ]
      },
      totalColumns() {
        return [
          {
            headerName: this.$t('Total'),
            field: 'name',
            minWidth: 150,
          },
          {
            headerName: this.$t('Amount'),
            field: 'gross_amount',
            minWidth: 100,
            maxWidth: 150,
            align: 'right',
            component: 'FormattedPrice',
          },
          {
            headerName: this.$t('Sales Tax'),
            field: 'sales_tax_amount',
            minWidth: 100,
            maxWidth: 150,
            align: 'right',
            component: 'FormattedPrice',
          },
          {
            headerName: this.$t('Retention'),
            field: 'retention_amount',
            minWidth: 100,
            maxWidth: 150,
            align: 'right',
            component: 'FormattedPrice',
          },
          {
            headerName: this.$t('Net'),
            field: 'net_amount',
            minWidth: 100,
            maxWidth: 150,
            align: 'right',
            component: 'FormattedPrice',
          },
        ]
      },
      flatData() {
        const allEntries = []
        this.data.customers.forEach(customer => {
          customer?.billings.forEach(billing => {
            const { number, date } = billing
            billing.full_description = `<b>${number}</b> ${date} ${customer.name}`
            billing.entries.forEach(entry => {
              allEntries.push({
                ...entry,
                billing,
                customer,
                gross_amount: +entry.gross_amount,
                sales_tax_amount: +entry.sales_tax_amount,
                net_amount: +entry.net_amount,
              })
            })
          })
        })
        return allEntries
      },
      groupRowRendererParams() {
        return {
          innerRenderer: 'LumpSumBillingProofGroupRow',
        }
      },
      columns() {
        return [
          {
            headerName: this.$t('Customer'),
            field: 'customer.code',
            rowGroup: true,
            hide: true,
          },
          {
            headerName: this.$t('Billing'),
            field: 'billing.full_description',
            rowGroup: true,
            hide: true,
            keyCreator: params => params.value.full_description,
            valueGetter: params => params.data.billing,
          },
          {
            headerName: ' ',
            field: 'cost_center',
            minWidth: 30,
            maxWidth: 50,
          },
          {
            headerName: this.$t('Job'),
            field: 'source_id',
            minWidth: 60,
            maxWidth: 320,
            component: 'SourceLink',
          },
          {
            headerName: this.$t('Type'),
            field: 'type_id',
            minWidth: 50,
            maxWidth: 60,
            component: 'TypeLink',
          },
          {
            headerName: this.$t('Line Item'),
            field: 'addl_source_id',
            minWidth: 80,
            maxWidth: 100,
            component: 'AddlSourceLink',
            cellRendererParams: {
              showDescription: false,
            },
          },
          {
            headerName: this.$t('MTL/EQP'),
            field: 'special_source_id',
            minWidth: 70,
            maxWidth: 120,
            component: 'SpecialSourceLink',
          },
          {
            headerName: this.$t('Description'),
            field: 'description',
            autoHeight: true,
            minWidth: 150,
            maxWidth: 320,
            cellRenderer: params => {
              const { footer, level } = params.node
              if (!footer) {
                return params.data.description
              }
              if (level === 1) {
                return this.$t('Invoice Total:')
              }
              return this.$t('Customer Total:')
            }
          },
          {
            headerName: this.$t('Account'),
            field: 'account',
            minWidth: 40,
            maxWidth: 70,
            component: 'AccountLink',
            cellRendererParams: {
              showDescription: false,
            }
          },
          {
            headerName: this.$t('Qty'),
            field: 'quantity',
            align: 'right',
            minWidth: 40,
            maxWidth: 70,
            valueGetter: params => {
              const quantity = params.data?.quantity
              if (quantity) {
                return +quantity
              }
              return ''
            },
          },
          {
            headerName: this.$t('Amount'),
            field: 'gross_amount',
            minWidth: 80,
            maxWidth: 120,
            component: 'FormattedPrice',
            cellRendererParams: {
              hideZero: true,
            },
            align: 'right',
            aggFunc: 'sum',
          },
          {
            headerName: this.$t('Sales Tax'),
            field: 'sales_tax_amount',
            minWidth: 80,
            maxWidth: 120,
            valueGetter: params => +params.data.sales_tax_amount,
            component: 'FormattedPrice',
            cellRendererParams: {
              hideZero: true,
            },
            align: 'right',
            aggFunc: 'sum',
          },
          {
            headerName: this.$t('Retention'),
            field: 'retention_amount',
            minWidth: 80,
            maxWidth: 120,
            valueGetter: params => +params.data.retention_amount,
            component: 'FormattedPrice',
            cellRendererParams: {
              hideZero: true,
            },
            align: 'right',
            aggFunc: 'sum',
          },
          {
            headerName: this.$t('Net'),
            field: 'net_amount',
            minWidth: 80,
            maxWidth: 120,
            component: 'FormattedPrice',
            align: 'right',
            aggFunc: 'sum',
          },
        ]
      }
    },
  }
</script>
