<template>
  <EntityApproveDialog
    v-bind="$attrs"
    v-on="$listeners"
    :entity-name="$t('purchase order adjustment')"
    :data="data"
    api-base-path="/restify/purchase-order-adjustments"
    base-path="/purchasing-inventory/purchase-orders-adjustments"
  >
    <PurchaseOrderAdjustmentDetails
      :show-back="false"
      class="col-span-6"
    />
  </EntityApproveDialog>
</template>
<script>
import InvoiceDetails from "@/modules/accounts-payable/pages/invoices/invoice-details.vue";
import EntityApproveDialog from "@/modules/common/components/review/EntityApproveDialog.vue";
import PurchaseOrderAdjustmentDetails
  from "@/modules/purchasing-inventory/pages/purchase-orders/purchase-order-adjustment-details.vue";

export default {
  components: {
    PurchaseOrderAdjustmentDetails,
    EntityApproveDialog,
    InvoiceDetails,
  },
  props: {
    data: {
      type: String,
      required: true,
    },
  },
  watch: {
    data: {
      immediate: true,
      handler(value) {
        if (!value) {
          return
        }
        this.$store.commit('inventoryManagement/SET_CURRENT_PURCHASE_ORDER_ADJUSTMENT', value)
      },
    }
  }
}
</script>
