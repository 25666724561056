<template>
    <entity-preview
        v-bind="$attrs"
        :entity="employeeData"
        :key="`preview-${data?.id}`"
        :url="`/payroll/employees/${employeeData?.id}/view`"
        :value-to-display="valueToDisplay"
        :parent-entity-id="data?.id"
        :link-class="linkClass"
        entity-name="employee"
    />
</template>
<script>
  export default {
    name: 'EmployeeLink',
    props: {
      id: [String, Number],
      data: {
        type: Object,
        default: () => ({}),
      },
      showName: {
        type: Boolean,
        default: true,
      },
      showNameOnly: {
        type: Boolean,
        default: false,
      },
      linkClass: {
        type: String,
        default: 'text-base print:text-print text-gray-900',
      },
    },
    computed: {
      allEmployees() {
        return this.$store.state.globalLists.employees
      },
      employeeData() {
        if (this.id) {
          return this.allEmployees.find(employee => employee.id === this.id) || {}
        }
        return this.data?.attributes || this.data || {};
      },
      valueToDisplay() {
        const {code, name} = this.employeeData
        if (this.showNameOnly) {
          return name
        }

        if (!this.showName || !name) {
          return code
        }
        return `${code} (${name})`
      }
    }
  }
</script>
