<template>
  <line-item-select
      v-focus
      v-model="selectedValue"
      :job-id="sourceId"
      :type-id="typeId"
      :line-item-type="lineItemType"
      :allow-empty="allowEmpty"
      :filterMethod="params.filterMethod"
      class="ag-cell-editor w-full"
      @change="onChange"
      @keydown.enter.native="goToNextCell"
  />
</template>
<script>
  import LineItemSelect from '@/components/select/entities/LineItemSelect'
  import { selectEditorMixin } from "@/components/ag-grid/cellEditors/selectEditorMixin";
  import { globalResources } from "@/components/form/util";

  export default {
    name: 'LineItemCell',
    components: {
      LineItemSelect
    },
    mixins: [selectEditorMixin],
    computed: {
      lineItemType() {
        return this.params.lineItemType || globalResources.CostLineItems
      },
      allowEmpty() {
        return this.params.allowEmpty !== undefined ? this.params.allowEmpty : true
      },
      sourceId() {
        return this.params?.data?.source_id || this.params.source_id
      },
      typeId() {
        return this.params?.data?.type_id || this.params.type_id
      }
    }
  }
</script>
