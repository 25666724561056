<template>
  <div class="payroll-stubs">
    <portal to="page-title">
      {{$route.name}} <template v-if="!employeeId">{{ $formatDate($route.params.id, 'date', true) }}</template>
    </portal>
    <email-consent-alert
        v-if="!employeeId"
        :isEmployeeConsentRequired="isEmployeeConsentRequired"
    />
    <div class="mt-8">

      <BaseDataTable :columns="columns"
                     :url="url"
                     :url-params="urlParams"
                     :select-row-filter="selectRowFilter"
                     actions="search,refresh,export"
                     entity="employee-checks"
                     @data-fetch="employees = $event"
                     @all-selected="onAllSelected"
                     ref="table">
        <template v-slot:select="{row}">
          <base-checkbox v-if="$can('employee_checks_update')"
                         :disabled="!selectRowFilter(row)"
                         v-model="row.selected">
          </base-checkbox>
        </template>
        <template v-slot:relationships.employee.attributes.email="{row, index}">
          <div class="truncate flex items-center">
            <template v-if="getEmail(row)">
              <warning-tip
                  v-if="!didConsent(row)"
                  :message="$t('There was no consent email sent for this employee yet')"/>
              <warning-tip
                  v-if="!canReceiveChecks(row)"
                  :message="$t('The employee has opted out to receive checks via email')"
              />
              <a :href="`mailto:${getEmail(row)}`"
                 class="text-primary-500 hover:text-primary-900 hover:underline cursor-pointer truncate">
                {{ getEmail(row) }}
              </a>
            </template>
            <base-button v-else-if="$isAuthorized('authorizedToUpdate', row)"
                         variant="white"
                         size="xs"
                         @click="addEmail(row, index)">
              {{ $t('Add Email') }}
            </base-button>
            <span v-else>

            </span>
          </div>
        </template>
        <template v-slot:attributes.sent_count="{row}">
          <base-badge :type="row.attributes.sent_count > 0 ? 'success' : 'info'">
            {{ $tc('sent times', row.attributes.sent_count, { count: row.attributes.sent_count }) }}
          </base-badge>
        </template>
        <template v-slot:extra-actions-before="{row, index}">
          <table-view-button v-if="$isAuthorized('authorizedToPreview', row)"
                             @click="previewCheck(row)"
          >
            {{ $t('Preview') }}
          </table-view-button>
          <table-edit-button v-if="$isAuthorized('authorizedToUpdate', row)"
                             @click="addEmail(row, index)"
          />
          <table-download-button v-if="$isAuthorized('authorizedToDownload', row)"
                                 :loading="row.downloading"
                                 @click="downloadCheck(row)"
          />
          <template v-if="$isAuthorized('authorizedToSendEmail', row)">
            <table-icon-button v-if="!getEmployee(row).payroll_consent_sent_at && getEmail(row)"
                               :content="$t('Send Consent Email')"
                               @click="sendConsentEmail(row)"
            >
              <MailIcon class="w-4 h-4"></MailIcon>
            </table-icon-button>
          </template>
        </template>
        <template v-slot:additional-actions>
          <download-unsent-checks v-if="false"/>
          <div>
            <base-button variant="primary"
                         :disabled="selectedRows.length === 0 && !allPagesSelected"
                         @click="sendEmails"
            >
              <MailIcon class="w-5 h-5 mr-2"></MailIcon>
              {{ $t('Send Check(s)') }}
            </base-button>
          </div>
        </template>
      </BaseDataTable>
      <base-form-dialog v-if="showAddEmailDialog && rowToEdit"
                        :key="rowToEdit.id"
                        :title="getEmployee(rowToEdit).name"
                        :show-arrows="true"
                        :open.sync="showAddEmailDialog"
                        :previous-disabled="selectedIndex <= 0"
                        :next-disabled="selectedIndex === employees.length - 1 && isLastPage()"
                        @next-click="onNextClick"
                        @prev-click="onPrevClick"
      >
        <ValidationObserver v-slot="{ handleSubmit }">
          <base-form :loading="rowToEdit.loading"
                     layout="modal"
                     @cancel="$emit('cancel')"
                     @submit="handleSubmit(onEmailAdd)"
          >

            <div class="col-span-6">
              <base-input v-model="modelToEdit.email"
                          :label="$t('Email')"
                          :placeholder="$t('Employee email')"
                          id="email"
                          rules="required|email">
              </base-input>
            </div>
          </base-form>
        </ValidationObserver>
      </base-form-dialog>
      <check-preview-dialog v-if="showCheckPreviewDialog"
                            :open.sync="showCheckPreviewDialog"
                            :data="get(rowToEdit, 'attributes', {})"
                            :employee="get(rowToEdit, 'relationships.employee.attributes', {})"
                            :company="get(rowToEdit, 'relationships.company.attributes', {})"
      />
    </div>
    <DeleteDialog v-if="showConfirmDialog"
                  :title="$t(`Send Emails to ${allPagesSelected ?  `all (${totalEmployees})` : selectedRows.length} employee(s)`)"
                  :description="$t(`This action will send email(s) to ${allPagesSelected ?  `all (${totalEmployees})` : selectedRows.length} employee(s). Make sure to validate the check amounts and preview the checks before sending them via email. Note that employees without an email or that haven't agreed to receive checks via email will be skipped.`)"
                  :delete-text="$t('Send')"
                  :open="true"
                  class="select-template-dialog"
                  type="primary"
                  @delete="onConfirmSendAction"
                  @cancel="onConfirmRejectAction"
    >
      <check-template-select v-model="selectedTemplate"/>
    </DeleteDialog>
  </div>
</template>
<script>
  import axios from 'axios'
  import CheckPreviewDialog from '@/modules/payroll/components/CheckPreviewDialog';
  import CheckTemplateSelect from '@/modules/payroll/components/CheckTemplateSelect';
  import EmailConsentAlert from '@/modules/payroll/components/EmailConsentAlert';
  import DownloadUnsentChecks from '@/modules/payroll/components/DownloadUnsentChecks';
  import DeleteDialog from '@/components/common/modal/DeleteDialog';
  import { MailIcon } from 'vue-feather-icons'
  import { downloadFileForEntity } from '@/modules/common/util/downloadFileLocally';
  import WarningTip from '@/modules/payroll/components/WarningTip';

  export default {
    name: 'EmployeeChecks',
    components: {
      WarningTip,
      MailIcon,
      DeleteDialog,
      EmailConsentAlert,
      CheckPreviewDialog,
      CheckTemplateSelect,
      DownloadUnsentChecks,
    },
    props: {
      employeeId: {
        type: String,
      },
    },
    data() {
      return {
        showAddEmailDialog: false,
        showCheckPreviewDialog: false,
        selectedIndex: -1,
        rowToEdit: null,
        allPagesSelected: false,
        downloading: false,
        sendEmailLoading: false,
        modelToEdit: {
          email: '',
        },
        confirmResolve: null,
        confirmReject: null,
        showConfirmDialog: false,
        selectedTemplate: this.$store.state.payroll.defaultChecksTemplate || 'box',
        employees: [],
        columns: [
          {
            label: '',
            prop: 'select',
            minWidth: 40,
            maxWidth: 40,
            smallCell: true,
          },
          {
            label: this.$t('Code'),
            prop: 'relationships.employee.attributes.code',
            component: 'EntityLink',
            redirectTo: '/payroll/employees/{ID}/view',
            entityKey: 'relationships.employee.id',
            maxWidth: 115,
          },
          {
            label: this.$t('Name'),
            prop: 'relationships.employee.attributes.name',
            maxWidth: 200,
          },
          {
            label: this.$t('Email'),
            prop: 'relationships.employee.attributes.email',
            minWidth: 200,
            maxWidth: 270,
            component: 'EmailLink',
          },
          {
            label: this.$t('Check Amount'),
            prop: 'attributes.amount',
            maxWidth: 130,
            component: 'FormattedPrice',
          },
          {
            label: this.$t('Check Date'),
            prop: 'attributes.date',
            maxWidth: 150,
            align: 'center',
            component: 'FormattedDate',
          },
          {
            label: this.$t('Period Ending'),
            prop: 'attributes.end_date',
            maxWidth: 150,
            align: 'center',
            component: 'FormattedDate',
          },
          {
            label: this.$t('Sent'),
            prop: 'attributes.sent_count',
            maxWidth: 100,
            align: 'center',
          },
        ],
      }
    },
    computed: {
      url() {
        return `/restify/employee-checks`
      },
      urlParams() {
        const data = {
          related: 'employee,company',
        }
        if (this.employeeId) {
          data.employee_id = this.employeeId
          return data
        }
        const { id } = this.$route.params
        if (id && id !== 'undefined' && id !== 'null') {
          data.end_date = id
        }
        return data
      },
      selectedRows() {
        return this.employees.filter(e => e.selected)
      },
      selectableRows() {
        return this.employees.filter(row => this.selectRowFilter(row))
      },
      totalEmployees() {
        const table = this.$refs.table
        if (!table) {
          return 0
        }
        return table.pagination.total
      },
      isEmployeeConsentRequired() {
        return this.$store.getters['company/isEmployeeConsentRequired']
      },
    },
    methods: {
      isLastPage() {
        const { last_page, current_page } = this.get(this.$refs, 'table.pagination', {})
        return last_page === current_page
      },
      previewCheck(row, index) {
        this.selectedIndex = index
        this.rowToEdit = row
        this.showCheckPreviewDialog = true
      },
      async sendConsentEmail(row) {
        const employee = this.getEmployee(row)
        const confirmed = await this.$confirm({
          title: this.$t(`Send Consent email to ${employee.name}`),
          description: this.$t(`This action will send a consent emails to ${employee.name}.`),
          buttonText: this.$t('Send'),
        })

        if (!confirmed) {
          return
        }
        try {
          this.$set(row, 'loading', true)
          const data = {
            repositories: [employee.id],
          }
          await axios.post(`/restify/employees/action?action=send-consent-mail`, data)
          this.$success(this.$t(`Consent email was queued to be sent. ${employee.name} will receive the email in a couple of minutes.`))
          this.$refs.table.refresh()
        } catch (err) {
          this.$error('An error occurred while trying to send the consent emails')
        } finally {
          this.$set(row, 'loading', false)
        }
      },
      onConfirmSendAction() {
        this.showConfirmDialog = false
        if (this.confirmResolve) {
          this.confirmResolve(true)
        }
      },
      onConfirmRejectAction() {
        this.showConfirmDialog = false
        if (this.confirmResolve) {
          this.confirmResolve(false)
        }
      },
      confirmSendAction() {
        this.showConfirmDialog = true
        return new Promise(resolve => {
          this.confirmResolve = resolve
        })
      },
      selectRowFilter(row) {
        return this.hasEmail(row) && this.canSendChecks(row)
      },
      async sendEmails() {
        const confirmed = await this.confirmSendAction()

        if (!confirmed) {
          return
        }
        try {
          this.sendEmailLoading = true
          let payload = {
            repositories: [],
          }
          if (this.allPagesSelected) {
            payload.repositories = 'all'
          } else {
            payload.repositories = this.selectedRows.map(r => r.id)
          }
          const params = {
            template: this.selectedTemplate,
          }
          if (this.employeeId) {
            params.employee_id = this.employeeId
          } else {
            params.end_date = this.$route.params.id
          }
          await axios.post(`/restify/employee-checks/action?action=send-employee-check`, payload, { params })
          this.allPagesSelected = false
          this.$refs.table.selectRows(false)
          this.$success(this.$t('Check emails are queued to be sent. The selected employees will receive their checks in a couple of minutes.'))
          this.$refs.table.refresh()
        } catch (err) {
          this.$error('An error occurred while trying to send the checks via email')
        } finally {
          this.sendEmailLoading = false
        }
      },
      hasEmail(row) {
        const email = this.getEmail(row)
        return email !== '' && email !== null
      },
      canSendChecks(row) {
        return this.canReceiveChecks(row) && this.didConsent(row)
      },
      canReceiveChecks(row) {
        return this.get(row, 'relationships.employee.attributes.receive_checks_via_email', false)
      },
      didConsent(row) {
        if (!this.isEmployeeConsentRequired) {
          return true
        }
        return this.get(row, 'relationships.employee.attributes.payroll_consent_confirmed_at') !== null
      },
      didOptOut(row) {
        return this.get(row, 'relationships.employee.attributes.payroll_consent_optout_at') !== null
      },
      getEmail(row) {
        return this.get(row, 'relationships.employee.attributes.email', '')
      },
      getEmployee(row) {
        return this.get(row, 'relationships.employee.attributes', {})
      },
      async onNextClick() {
        if (this.selectedIndex === this.employees.length - 1) {
          if (!this.isLastPage()) {
            this.$refs.table.pagination.current_page++
            await this.$refs.table.refresh()
            this.selectedIndex = 0
            this.rowToEdit = this.employees[this.selectedIndex]
            this.modelToEdit.email = this.getEmail(this.rowToEdit)
            return
          }
          this.showAddEmailDialog = false
          return
        }
        this.selectedIndex++
        this.rowToEdit = this.employees[this.selectedIndex]
        this.modelToEdit.email = this.getEmail(this.rowToEdit)
      },
      onPrevClick() {
        this.selectedIndex--
        this.rowToEdit = this.employees[this.selectedIndex]
        this.modelToEdit.email = this.getEmail(this.rowToEdit)
      },
      addEmail(row, index) {
        this.rowToEdit = row
        this.selectedIndex = index
        this.showAddEmailDialog = true
        this.modelToEdit.email = this.getEmail(row)
      },
      onAllSelected(value) {
        this.allPagesSelected = value
      },
      async downloadCheck(row) {
        await downloadFileForEntity(row, 'employee-checks')
      },
      async onEmailAdd() {
        const employee = this.get(this.rowToEdit, 'relationships.employee.attributes', {})
        const employeeId = employee.id
        const previousEmail = employee.email
        try {
          this.$set(this.rowToEdit, 'loading', true)
          employee.email = this.modelToEdit.email
          await axios.patch(`/restify/employees/${employeeId}`, {
            email: this.modelToEdit.email
          })
          this.$set(this.rowToEdit, 'loading', false)
          await this.onNextClick()
        } catch (err) {
          this.$error(this.$t('Could not update the employee information'))
          employee.email = previousEmail
        } finally {
          this.$set(this.rowToEdit, 'loading', false)
        }
      },
    },
  }
</script>
<style lang="scss">
  .payroll-stubs table {
    .td-select,
    .th-select {
      @apply pl-3 pr-0;
    }
  }

  .payroll-stubs .dialog-wrapper {
    margin-top: 0;
    top: 10px;
  }

  .payroll-stubs .select-template-dialog {
    z-index: 100;
  }
</style>
