<template>
  <div class="customer-details">
    <template>
      <base-go-to-edit-alert
        v-if="!canSendEmail && customer"
        :path="`/accounts-receivable/customers/${customer.id}/edit`"
        message="Before sending statements/invoices/billings emails, you should add the customer email."
      />
      <DetailsPageLayout :loading="customerLoading">
        <template #left>
          <CustomerMainCard :customer="customer"/>
          <CustomerSummaryCard :customer="customer"/>
        </template>
        <template #right>
          <CustomerSecondaryCard :customer="customer"/>
          <CustomerSalesAndTaxCard :customer="customer"/>
          <AddressesDetailsCard
            :addresses="customer.relationships?.addresses"
            no-data-text="No addresses were added for this customer"
          />
          <ContactsDetailsCard
            :contacts="customer.relationships?.contacts"
            no-data-text="No contacts were added for this customer"
          />
        </template>
      </DetailsPageLayout>
      <base-back-link to="/accounts-receivable/customers"/>
    </template>
  </div>
</template>
<script lang="ts" setup>
import Data = API.Data
import store from '@/store'
import { computed } from 'vue'
import { Customer } from '@/modules/common/types/models'
import DetailsPageLayout from '@/modules/common/components/DetailsPageLayout.vue'
import AddressesDetailsCard from '@/modules/common/components/AddressesDetailsCard.vue'
import ContactsDetailsCard from '@/modules/common/components/contacts/ContactsDetailsCard.vue'
import CustomerMainCard from '@/modules/accounts-receivable/components/customer-details/CustomerMainCard.vue'
import CustomerSummaryCard from '@/modules/accounts-receivable/components/customer-details/CustomerSummaryCard.vue'
import CustomerSecondaryCard from '@/modules/accounts-receivable/components/customer-details/CustomerSecondaryCard.vue'
import CustomerSalesAndTaxCard from '@/modules/accounts-receivable/components/customer-details/CustomerSalesAndTaxCard.vue'

const canSendEmail = computed((): boolean => {
  return store.getters['accountsReceivable/canSendEmail']
})

const customer = computed((): Data<Customer> => {
  return store.state.accountsReceivable.currentCustomer
})

const customerLoading = computed((): boolean => {
  return store.state.accountsReceivable.customerLoading
})
</script>
<style lang="scss">
.customer-details {
  span {
    @apply text-gray-500 font-medium;
  }
}
</style>
