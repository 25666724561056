<template>
  <div>
    <layout-tabs :items="filteredItems"/>
    <router-view/>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        items: [
          {
            name: this.$t('Settings Activity'),
            path: '/job-costing/settings/activity/activity-log',
            permission: 'job_costing_settings',
          },
          {
            name: this.$t('Job Types Activity'),
            path: '/job-costing/settings/activity/job-types-activity-log',
            permission: 'job_costing_settings',
          },
          {
            name: this.$t('Job Categories Activity'),
            path: '/job-costing/settings/activity/job-categories-activity-log',
            permission: 'job_costing_settings',
          },
        ],
      }
    },
    computed: {
      filteredItems() {
        return this.items.filter(item => this.$can(item.permission))
      },
    },
  }
</script>
