import i18n from "@/i18n";

export const accountRestrictions = {
  NoRestrictions: 'no',
  NonJob: 'non_job',
  JobExpense: 'job_expense',
  JobIncome: 'job_income',
  Inventory: 'inventory',
  Equipment: 'equipment',
  Service: 'service',
}

export const restrictionDictionary = {
  [accountRestrictions.NoRestrictions]: 'No restrictions',
  [accountRestrictions.NonJob]: 'Use with NON-JOB Transactions only',
  [accountRestrictions.JobExpense]: 'Use only for JOB EXPENSE Transactions',
  [accountRestrictions.JobIncome]: 'Use only for JOB INCOME Transactions',
  [accountRestrictions.Inventory]: 'Use only for INVENTORY Transactions',
  [accountRestrictions.Equipment]: 'Use only for EQUIPMENT Transactions',
  [accountRestrictions.Service]: 'Use only for SERVICE/BILLING Transactions',
  service: 'Use only for SERVICE/BILLING Transactions',
}

export const accountStatusOptions = [
  {
    label: i18n.t('Active'),
    value: true,
  },
  {
    label: i18n.t('Inactive'),
    value: false,
  },
]
