<template>
  <div>
    <div class="grid grid-cols-2 gap-x-5">
      <template v-if="lineItem.id">
        <div class="col-span-2">
          <div class="mb-5 grid grid-cols-1 gap-5 md:grid-cols-3 lg:grid-cols-4">
            <base-stats-card icon="IconSalesToDate" :title="$t('Total Budget')">
              {{ $formatPrice(lineItem.attributes.amount) }}
            </base-stats-card>
            <base-stats-card icon="IconOpenAmount" :title="$t('Total Actual')">
              {{ $formatPrice(lineItem.attributes.amount_to_date) }}
            </base-stats-card>
            <base-stats-card icon="IconPaymentsHold" class="capitalize" :title="$t('Estimated Final Cost')">
              {{ $formatPrice(lineItem.attributes.est_final_cost) }}
            </base-stats-card>
            <base-stats-card icon="IconRetention" :title="$t('Completion')">
              {{ $formatPercent(lineItem.attributes.completion) }}
            </base-stats-card>
            <base-stats-card icon="IconSalesToDate" :title="$t('Committed P.0. Amount')">
              {{ $formatPrice(lineItem.attributes.committed_po_amount) }}
            </base-stats-card>
            <base-stats-card icon="IconSalesToDate" :title="$t('Invoiced P.0. Amount')">
              {{ $formatPrice(lineItem.attributes.invoiced_po_amount) }}
            </base-stats-card>
          </div>
        </div>
        <div class="col-span-2 md:col-span-1">
          <base-detail-card :title="$t('Line Item Details')"
                            :key="lineItem.id"
                            :entity="lineItem"
                            entity-name="cost-budget"
                            :can-expand="false"
                            :expanded="true">
            <base-detail-card-row :title="$t('Phase Code')">
              {{ lineItem.attributes.phase_code }}
            </base-detail-card-row>
            <base-detail-card-row :title="$t('Cost Code')" stripe>
              {{ lineItem.attributes.cost_code }}
            </base-detail-card-row>
            <base-detail-card-row :title="$t('Change Order')">
              {{ lineItem.attributes.change_order }}
            </base-detail-card-row>
            <base-detail-card-row :title="$t('Description')" stripe>
              {{ lineItem.attributes.description }}
            </base-detail-card-row>
          </base-detail-card>
        </div>
        <div class="col-span-2 md:col-span-1 mt-5 md:mt-0">
          <base-detail-card :title="$t('From Job')"
                            :key="lineItem.id"
                            entity-name="job"
                            :can-expand="false"
                            :expanded="true">
            <template v-slot:title>
              <div class="flex">
                <span class="mr-2">{{ $t('From Job:') }}</span>
                <JobLink :job="job"/>
              </div>
            </template>
            <base-detail-card-row :title="$t('Job Details')">
              {{ job.attributes.number }}
              <template v-if="job.attributes.description">
                ({{ job.attributes.description }})
              </template>
            </base-detail-card-row>
            <base-detail-card-row :title="$t('Retention – Subcontracts')" stripe>
              {{ $formatPercent(job.attributes.subcontract_retention_percent / 100) }}
            </base-detail-card-row>
            <base-detail-card-row :title="$t('Retention – Billings')">
              {{ $formatPercent(job.attributes.billing_retention_percent / 100) }}
            </base-detail-card-row>
            <base-detail-card-row :title="$t('Job Units')" stripe>
              {{ Math.round(job.attributes.units) }}
            </base-detail-card-row>
          </base-detail-card>
        </div>
        <div class="col-span-2 md:col-span-1 mt-5">
          <LineItemBudgetsCard :budgets="budgets"/>
        </div>
        <div class="col-span-2 md:col-span-1 mt-5">
          <base-detail-card :title="$t('Additional Settings')"
                            :key="lineItem.id"
                            :expanded="false"
                            entity-name="budget">
            <base-detail-card-row :title="$t('Account')">
              <AccountLink :number="lineItem.attributes.account"/>
            </base-detail-card-row>
            <base-detail-card-row :title="$t('Sub Account')" stripe>
              <SubAccountLink :number="lineItem.attributes.subaccount"/>
            </base-detail-card-row>
            <base-detail-card-row :title="$t('Vendor')">
              <VendorLink :data="vendor?.attributes"/>
            </base-detail-card-row>
            <base-detail-card-row :title="$t('Material')" stripe>
              <MaterialLink :data="material?.attributes"/>
            </base-detail-card-row>
            <base-detail-card-row :title="$t('Equipment')">
              <EquipmentLink :data="equipment?.attributes"/>
            </base-detail-card-row>
          </base-detail-card>
        </div>
        <div class="col-span-2 md:col-span-1 mt-5">
          <base-detail-card :title="$t('Payroll Settings')"
                            :key="lineItem.id"
                            :expanded="false"
                            entity-name="budget">
            <base-detail-card-row :title="$t('Certified P/R Exempt')">
              <status-badge :status="lineItem.attributes.certified_payroll"/>
            </base-detail-card-row>
            <base-detail-card-row :title="$t('Pay Rate')">
              <template v-if="lineItem.attributes.pay_rate_adjustment === rateAdjustmentTypes.Dollar">
                {{ $formatPrice(lineItem.attributes.pay_rate_value) }}
              </template>
              <template v-if="lineItem.attributes.pay_rate_adjustment === rateAdjustmentTypes.Percentage">
                {{ $formatPercent(lineItem.attributes.pay_rate_value) }}
              </template>
              <template v-if="lineItem.attributes.pay_rate_adjustment === rateAdjustmentTypes.NoAdjustment">
                {{ $t('No Adjustment') }}
              </template>
            </base-detail-card-row>
            <base-detail-card-row
                v-if="lineItem.attributes.workers_comp_rate_code"
                :title="$t(`Worker's Comp Rate`)"
            >
              <router-link :to="`payroll/rates/worker-compensation-rates?id=${workersCompRate.id}`">
                {{ lineItem.attributes.workers_comp_rate_code }}
              </router-link>
            </base-detail-card-row>
            <base-detail-card-row
                v-if="lineItem.attributes.gen_liability_rate_code"
                :title="$t('Gen Liability Rate')"
            >
              <router-link :to="`payroll/rates/general-liability-rates?id=${genLiabilityRate.id}`">
                {{ lineItem.attributes.gen_liability_rate_code }}
              </router-link>
            </base-detail-card-row>
          </base-detail-card>
        </div>
      </template>
    </div>
    <base-back-link :to="backPath" />
  </div>
</template>
<script>
  import { formattedText } from '@/utils/utils'
  import { rateAdjustmentTypes } from '@/modules/job-costing/enum/jobs'
  import LineItemBudgetsCard from "@/modules/job-costing/components/line-items/LineItemBudgetsCard.vue";
  import { costTypes } from "@/enum/enums";

  export default {
    components: { LineItemBudgetsCard },
    data() {
      return {
        rateAdjustmentTypes,
      }
    },
    computed: {
      backPath() {
        const jobId = this.$route.query.fromJob
        if (jobId) {
          return `/job-costing/jobs/${jobId}/line-items`
        }
        return '/job-costing/cost-line-items'
      },
      lineItem() {
        return this.$store.state.jobCosting.currentLineItem
      },
      lineItemLoading() {
        return this.$store.state.jobCosting.lineItemLoading
      },
      job() {
        const { job } = this.lineItem.relationships
        return job
      },
      vendor() {
        const { vendor } = this.lineItem.relationships
        return vendor
      },
      material() {
        const { material } = this.lineItem.relationships
        return material
      },
      equipment() {
        const { equipment } = this.lineItem.relationships
        return equipment
      },
      workersCompRate() {
        const { workersCompRate } = this.lineItem.relationships
        return workersCompRate
      },
      genLiabilityRate() {
        const { genLiabilityRate } = this.lineItem.relationships
        return genLiabilityRate
      },
      budgets() {
        const { budgets } = this.lineItem.relationships
        return budgets
      },
    },
    methods: {
      formattedText,
    },
  }
</script>
