<template>
  <el-collapse v-model="expandedSections"
               class="col-span-8 progress-invoices">
    <base-collapse-section
        key="form-header"
        name="form-header"
        class="mb-6"
        wrapperClasses="ml-0"
    >
      <template #title>
        <div class="pl-4">
          {{ $t('Filters') }}
        </div>
      </template>
      <base-form layout="vertical"
                 class="subcontract-progress-filters"
                 :loading="loading"
                 :save-text="$t('Proceed')"
                 @submit="onSubmit"
      >
        <JobRangeFilter class="col-span-6" v-model="model"/>
        <VendorRangeFilter class="col-span-6" v-model="model"/>
      </base-form>
    </base-collapse-section>
  </el-collapse>
</template>
<script>
  import JobRangeFilter from "@/components/range-filters/JobRangeFilter.vue";
  import VendorRangeFilter from "@/components/range-filters/VendorRangeFilter.vue";

  export default {
    components: { VendorRangeFilter, JobRangeFilter },
    data() {
      return {
        loading: false,
        expandedSections: ['form-header'],
        model: {
          job_number_from: undefined,
          job_number_to: undefined,
          vendor_code_from: undefined,
          vendor_code_to: undefined,
        }
      }
    },
    methods: {
      onSubmit() {
        this.$emit('save', this.model)
        this.expandedSections = []
      }
    }
  }
</script>
<style lang="scss">
.progress-invoices {
  @apply shadow;
  .form__inner {
    @apply shadow-none;
  }
}
</style>
