<template>
  <component
      :is="wrapperComponent"
      :title="$t('Timecard Overrides')"
      key="timecard-overrides"
      name="timecard-overrides">
    <div class="grid grid-cols-4 gap-x-3">
      <state-tax-select
          v-model="value.withholding_state_id"
          :label="$tc(`${scope} Withholding State`)"
          :initial-value="get(data, 'relationships.withholdingState')"
          :placeholder="$t(`${scope} State`)"
          class="col-span-4 md:col-span-1"
          clearable
          @change="onChangeState"
      />
      <state-tax-select
          v-if="multipleStateTaxes"
          v-model="value.sui_sdi_state_id"
          :label="$t(`${scope} SUI/SDI State`)"
          :initial-value="get(data, 'relationships.suiSdiState')"
          :placeholder="$t(`${scope} State 2`)"
          class="col-span-4 md:col-span-1"
          clearable
      />
      <template v-if="multipleLocalTaxes">
        <local-tax-select
            v-model="value.withholding_local_id"
            :label="$t(`${scope} Local 1`)"
            :initial-value="get(data, 'relationships.withholdingLocal')"
            :placeholder="$t(`${scope} Local 1`)"
            class="col-span-4 md:col-span-1"
            clearable
        />
        <local-tax-select
            v-model="value.withholding_local_2_id"
            :label="$t(`${scope} Local 2`)"
            :initial-value="get(data, 'relationships.withholdingLocal2')"
            :placeholder="$t(`${scope} Local 2`)"
            class="col-span-4 md:col-span-1"
        />
      </template>
    </div>
  </component>
</template>
<script>

  export default {
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      scope: {
        type: String,
        default: 'Withholding',
      },
      collapsible: {
        type: Boolean,
        default: true,
      },
      value: {
        type: Object,
        default: () => ({
          withholding_state_id: null,
          withholding_local_id: null,
          withholding_local_2_id: null,
          sui_sdi_state_id: null,
          gen_liability_state_id: null,
          workers_comp_state_id: null,
        }),
      },
    },
    computed: {
      wrapperComponent() {
        return this.collapsible ? 'base-collapse-section' : 'div'
      },
      activeCompany() {
        return this.$store.state.company.activeCompany || {}
      },
      multipleLocalTaxes() {
        if (this.value.hasOwnProperty('multiple_local_taxes')) {
          return this.value.multiple_local_taxes
        }
        return this.activeCompany.multiple_local_taxes
      },
      multipleStateTaxes() {
        if (this.value.hasOwnProperty('multiple_state_taxes')) {
          return this.value.multiple_state_taxes
        }
        return this.activeCompany.multiple_state_taxes
      },
    },
    methods: {
      onChangeState(stateId) {
        this.value.gen_liability_state_id = this.value.workers_comp_state_id = stateId
      },
    },
  }
</script>
