<template>
  <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      xml:space="preserve">
<g>
	<path style="fill:#CBD4D8;" d="M418.067,366.933c-5.12,0-8.533-3.413-8.533-8.533v-25.6c0-5.12,3.413-8.533,8.533-8.533
		c5.12,0,8.533,3.413,8.533,8.533v25.6C426.601,363.52,423.187,366.933,418.067,366.933"/>
  <path style="fill:#CBD4D8;" d="M418.067,418.133c-18.773,0-34.133-15.36-34.133-34.133c0-5.12,3.413-8.533,8.533-8.533
		s8.533,3.413,8.533,8.533c0,9.387,7.68,17.067,17.067,17.067s17.067-7.68,17.067-17.067s-7.68-17.067-17.067-17.067
		c-5.12,0-8.533-3.413-8.533-8.533s3.413-8.533,8.533-8.533c18.773,0,34.133,15.36,34.133,34.133
		C452.201,402.773,436.841,418.133,418.067,418.133"/>
</g>
    <path style="fill:#2C3C42;" d="M435.134,332.8h-34.133v-10.24c0-15.36,5.973-29.867,17.067-40.96
	c11.093,11.093,17.067,25.6,17.067,40.96V332.8z"/>
    <path style="fill:#51656B;" d="M418.067,290.133c-5.12,0-8.533-3.413-8.533-8.533V179.2c0-5.12,3.413-8.533,8.533-8.533
	c5.12,0,8.533,3.413,8.533,8.533v102.4C426.601,286.72,423.187,290.133,418.067,290.133"/>
    <g>
	<path style="fill:#F4BA48;" d="M127.934,494.933h68.267V95.573l-25.6-78.507h-16.213l-26.453,78.507V494.933z M204.734,512h-85.333
		c-5.12,0-8.533-3.413-8.533-8.533v-409.6c0-0.853,0-1.707,0.853-2.56l28.16-85.333C140.734,2.56,144.147,0,147.561,0h28.16
		c3.413,0,6.827,2.56,7.68,5.973l28.16,85.333c0,0.853,0.853,1.707,0.853,2.56v409.6C213.267,508.587,209.854,512,204.734,512
		L204.734,512z"/>
      <path style="fill:#F4BA48;" d="M93.801,170.667h17.067V102.4H93.801V170.667z M119.401,187.733H85.267
		c-5.12,0-8.533-3.413-8.533-8.533V93.867c0-5.12,3.413-8.533,8.533-8.533h34.133c5.12,0,8.533,3.413,8.533,8.533V179.2
		C127.934,184.32,124.521,187.733,119.401,187.733L119.401,187.733z"/>
</g>
    <path style="fill:#51656B;" d="M51.134,110.933v51.2h-8.533c-18.773,0-34.133-15.36-34.133-34.133v-17.067H51.134z"/>
    <g>
	<path style="fill:#F4BA48;" d="M119.401,512c-2.56,0-4.267-0.853-5.973-2.56c-3.413-3.413-2.56-8.533,0.853-11.947l77.653-69.973
		l-77.653-62.293c-2.56-1.707-3.413-4.267-3.413-6.827s0.853-5.12,3.413-6.827l76.8-61.44l-76.8-61.44
		c-2.56-1.707-3.413-4.267-3.413-6.827c0-2.56,0.853-5.12,3.413-6.827l85.333-68.267c3.413-2.56,9.387-2.56,11.947,1.707
		c2.56,3.413,2.56,9.387-1.707,11.947l-76.8,61.44l75.947,61.44c1.707,1.707,3.413,4.267,3.413,6.827c0,2.56-0.853,5.12-3.413,6.827
		l-75.947,61.44l75.947,61.44c1.707,1.707,3.413,4.267,3.413,6.827s-0.853,5.12-2.56,6.827l-84.48,76.8
		C123.667,511.147,121.107,512,119.401,512"/>
      <path style="fill:#F4BA48;" d="M204.734,512c-1.707,0-4.267-0.853-5.973-2.56l-85.333-76.8c-1.707-1.707-2.56-3.413-2.56-5.973
		s0.853-5.12,3.413-6.827l76.8-61.44l-76.8-61.44c-2.56-1.707-3.413-4.267-3.413-6.827c0-2.56,0.853-5.12,3.413-6.827l76.8-61.44
		l-76.8-61.44c-3.413-2.56-4.267-8.533-1.707-11.947c2.56-3.413,8.533-4.267,11.947-1.707l84.48,68.267
		c1.707,1.707,3.413,4.267,3.413,6.827c0,2.56-0.853,5.12-3.413,6.827l-75.947,61.44l75.947,61.44
		c1.707,1.707,3.413,4.267,3.413,6.827s-0.853,5.12-3.413,6.827l-75.947,61.44l77.653,69.973c3.413,3.413,3.413,8.533,0.853,11.947
		C209.854,511.147,207.294,512,204.734,512"/>
      <path style="fill:#F4BA48;" d="M119.401,162.133c-2.56,0-5.12-0.853-6.827-3.413c-2.56-4.267-1.707-9.387,1.707-11.947
		l85.333-59.733c4.267-2.56,9.387-1.707,11.947,1.707c2.56,4.267,1.707,9.387-1.707,11.947l-85.333,59.733
		C122.814,161.28,121.107,162.133,119.401,162.133"/>
      <path style="fill:#F4BA48;" d="M204.734,162.133c-1.707,0-3.413-0.853-5.12-1.707l-85.333-59.733
		c-4.267-2.56-5.12-7.68-1.707-11.947c2.56-4.267,7.68-5.12,11.947-1.707l85.333,59.733c4.267,2.56,5.12,7.68,1.707,11.947
		C209.854,161.28,207.294,162.133,204.734,162.133"/>
      <path style="fill:#F4BA48;" d="M213.267,170.667h267.947L447.081,102.4H213.267V170.667z M494.867,187.733H204.734
		c-5.12,0-8.533-3.413-8.533-8.533V93.867c0-5.12,3.413-8.533,8.533-8.533h247.467c3.413,0,5.973,1.707,7.68,5.12l42.667,85.333
		c1.707,2.56,0.853,5.973,0,8.533C500.841,186.027,497.427,187.733,494.867,187.733L494.867,187.733z"/>
</g>
    <path style="fill:#2C3C42;" d="M51.134,196.267h42.667V76.8H51.134V196.267z"/>
    <g>
	<path style="fill:#F4BA48;" d="M418.067,187.733c-2.56,0-5.12-0.853-6.827-3.413l-61.44-76.8l-61.44,76.8
		c-3.413,4.267-10.24,4.267-13.653,0l-68.267-85.333c-2.56-3.413-2.56-9.387,1.707-11.947c3.413-2.56,9.387-2.56,11.947,1.707
		l61.44,76.8l61.44-76.8c3.413-4.267,10.24-4.267,13.653,0l68.267,85.333c2.56,3.413,2.56,9.387-1.707,11.947
		C422.334,186.88,419.774,187.733,418.067,187.733"/>
      <path style="fill:#F4BA48;" d="M213.267,187.733c-1.707,0-3.413-0.853-5.12-1.707c-3.413-2.56-4.267-8.533-1.707-11.947
		l68.267-85.333c3.413-4.267,10.24-4.267,13.653,0l61.44,76.8l61.44-76.8c2.56-3.413,8.533-4.267,11.947-1.707
		c3.413,2.56,4.267,8.533,1.707,11.947l-68.267,85.333c-3.413,4.267-10.24,4.267-13.653,0l-61.44-76.8l-61.44,76.8
		C218.387,186.88,215.827,187.733,213.267,187.733"/>
      <path style="fill:#F4BA48;" d="M418.067,187.733c-1.707,0-4.267-0.853-5.12-1.707c-3.413-3.413-4.267-8.533-0.853-11.947
		l47.787-57.173c3.413-3.413,8.533-4.267,11.947-0.853c3.413,3.413,4.267,8.533,0.853,11.947l-47.787,57.173
		C423.187,186.88,420.627,187.733,418.067,187.733"/>
</g>
    <path style="fill:#2C3C42;" d="M307.134,512H59.667c-5.12,0-8.533-3.413-8.533-8.533c0-5.12,3.413-8.533,8.533-8.533h247.467
	c5.12,0,8.533,3.413,8.533,8.533C315.667,508.587,312.254,512,307.134,512"/>
    <g>
</g>
</svg>


</template>
<script>
  export default {}
</script>
<style>
</style>
