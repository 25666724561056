import DocumentsLayout from "@/modules/documents/DocumentsLayout";

export default [
  {
    path: '/documents',
    name: 'Documents',
    component: DocumentsLayout,
    children: []
  }
]
