import ObjectValues = API.ObjectValues;

// * Equipment History Report
export enum AssetTypes {
  All = 'all',
  ExcludeDisposed = 'exclude_disposed',
}
// * Equipment History Report
export enum GroupByTypes {
  EquipmentCode = 'equipment_code',
  ClassCode = 'class_code',
  JobNumber = 'job_no',
  WorkOrderNumber = 'work_order_no',
  JournalNumber = 'journal_no',
  GLAccount = 'gl_account',
  EqpMaintenanceCode = 'eqp_maint_code',
}

export type AssetType = ObjectValues<typeof AssetTypes>
export type GroupByType = ObjectValues<typeof GroupByTypes>

export interface EquipmentListing {
  include_asset_type: AssetType,
  equipment_code_from: string | null,
  equipment_code_to: string | null,
  equipment_class_code_from: string | null,
  equipment_class_code_to: string | null,
  job_number_from: string | null,
  job_number_to: string | null,
  work_order_number_from: string | null,
  work_order_number_to: string | null,
  equipment_specialty_code_from: string | null,
  equipment_specialty_code_to: string | null,
  group_by: GroupByType,
  balance_sheet_equipment_only: boolean,
  period_start: Date | null,
  fiscal_year_start: Date | null,
  period_end: Date | null,
  fiscal_year_end: Date | null,
  equipment_type_id: string | null,
  transaction_type: string | null,
  summary_only: boolean,
}
