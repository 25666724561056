<template>
  <BaseSelect
    v-bind="$attrs"
    v-on="listeners"
    :name="$attrs.name || $t('Account Number')"
    :placeholder="$attrs.placeholder || $t('Select account')"
    :label="$attrs.label"
    :options="filteredAccounts"
    :table-columns="tableColumns"
    :id="$attrs.id || 'account-select'"
    :value-key="valueKey"
    :hide-label="hideLabel"
    :label-format="formatAccountName"
    :dataFilters="dataFilters"
    label-key="number"
  />
</template>
<script>
import { formatAccountName, isFieldRequired } from '@/modules/common/util/formattingUtils';
import { isControlAccount } from "@/plugins/settingsAndEnumsPlugin";

export default {
  inheritAttrs: false,
  props: {
    hideLabel: {
      type: Boolean,
      default: false,
    },
    valueKey: {
      type: String,
      default: 'id',
    },
    labelKey: {
      type: String,
      default: 'number',
    },
    restrictions: {
      type: Array,
      default: () => [],
    },
    filterFunction: {
      type: Function,
    },
    showLabelDescription: {
      type: Boolean,
      default: true,
    },
    warningOnChange: {
      type: Boolean,
      default: false,
    },
    canSelectControlAccounts: {
      type: Boolean,
      default: false,
    },
    showStatusColumn: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      initialValue: '',
      dataFilters: {
        showActive: true,
      },
    }
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.onChangeAccount,
      }
    },
    tableColumns() {
      return [
        {
          minWidth: 100,
          maxWidth: 120,
          name: this.$t('Number'),
          prop: 'number',
        },
        {
          minWidth: 200,
          maxWidth: 200,
          name: this.$t('Description'),
          prop: 'description',
        },
        {
          minWidth: 130,
          maxWidth: 130,
          name: this.$t('Status'),
          prop: 'status',
          component: 'Status',
          filter: {
            type: 'switch',
            prop: 'showActive',
            tooltip: this.$t('Only show active accounts'),
            onChange: (value) => {
              this.dataFilters.showActive = !this.dataFilters.showActive
            },
          },
          showIf: () => this.showStatusColumn,
        },
      ]
    },
    accounts() {
      const allAccounts = this.$store.state.company.accounts
      const hasRestrictions = this.restrictions.length > 0
      if (hasRestrictions) {
        return this.$store.getters['company/getFilteredAccountsByRestrictions'](this.restrictions)
      }
      if (this.dataFilters.showActive) {
        return allAccounts.filter(account => account.active)
      }

      return allAccounts
    },
    filteredAccounts() {
      let accounts = this.accounts
      if (this.filterFunction) {
        accounts = this.accounts.filter(this.filterFunction)
      }
      accounts = accounts.map(account => {
        return {
          ...account,
          status: account.active ? 'active' : 'inactive',
          isInactive: !account.active,
          disabled: isControlAccount(account?.number) && !this.canSelectControlAccounts,
        }
      })
      return accounts
    },
    showWarningOnChange() {
      return this.warningOnChange && isFieldRequired(this.$attrs)
    },
    showInactiveSetting() {
      return this.$store.getters['settings/getFilterSetting']('show_inactive_accounts')
    }
  },
  watch: {
    showInactiveSetting: {
      handler() {
        this.dataFilters.showActive = !this.showInactiveSetting
      },
      immediate: true
    }
  },
  methods: {
    formatAccountName(account, asHtml = false) {
      return formatAccountName(account, asHtml, this.showLabelDescription)
    },
    async onChangeAccount(newValue) {
      const showControlWarning = isControlAccount(newValue) && !this.canSelectControlAccounts
      if (showControlWarning) {
        const confirmed = await this.$confirm({
          title: this.$t('Change account?'),
          description: this.$t(`The account you select is used as a control account in one of the settings screens. Changing it may have unintended consequences. Are you sure you want to change the account from <b>${this.$attrs.value}</b> to <b>${newValue}</b>?`),
          buttonText: this.$t('Confirm'),
        })
        if (!confirmed) {
          return
        }
      }
      if (!this.initialValue || !this.showWarningOnChange || newValue === this.initialValue) {
        this.$emit('input', newValue)
        return
      }

      const confirmed = await this.$confirm({
        title: this.$t('Change account?'),
        description: this.$t(`Are you sure you want to change the account from <b>${this.$attrs.value}</b> to <b>${newValue}</b>?`),
        buttonText: this.$t('Confirm'),
      })

      if (!confirmed) {
        return
      }

      this.$emit('input', newValue)
    },
  },
  created() {
    this.initialValue = this.$attrs.value
  },
}
</script>
