<template>
  <BaseDetailCard
    :title="$t('Sales & Tax')"
    :can-expand="true"
    :expanded="true"
    entity-name="customer"
  >
    <BaseDetailCardRow :title="$t('Normal Billing Rate')">
      <router-link
        v-if="customerData.billing_rate_type_id"
        to="accounts-receivable/settings/sales-tax-districts"
      >
        {{ billingRateType?.title }}
      </router-link>
      <span v-else/>
    </BaseDetailCardRow>
    <BaseDetailCardRow :title="$t('Sales Tax')">
      {{ formatPercent(customerData.sales_tax_percent) }}
    </BaseDetailCardRow>
    <BaseDetailCardRow :title="$t('Exempt from Late Charges')">
      <StatusBadge :status="customerData.exempt_from_late_charges"/>
    </BaseDetailCardRow>
  </BaseDetailCard>
</template>
<script lang="ts" setup>
import Data = API.Data
import { computed, PropType } from 'vue'
import { Customer } from '@/modules/common/types/models'
import { formatPercent } from '@/plugins/formatPercent'

const props = defineProps({
  customer: {
    type: Object as PropType<Data<Customer>>,
    default: () => ({}),
    required: true,
  }
})

const customerData = computed((): Customer => {
  return props.customer?.attributes || {}
})

const billingRateType = computed(() => {
  return props.customer?.relationships?.billing_rate_type?.attributes || {}
})
</script>
