import axios from 'axios'
import { entityPreviewFields } from "@/modules/common/components/entity-preview/entities";

const types = {
  SET_CURRENT_WORK_ORDER: 'SET_CURRENT_WORK_ORDER',
  SET_CURRENT_WORK_ORDER_LOADING: 'SET_CURRENT_WORK_ORDER_LOADING',
  SET_CURRENT_WORK_SITE: 'SET_CURRENT_WORK_SITE',
  SET_CURRENT_WORK_SITE_LOADING: 'SET_CURRENT_WORK_SITE_LOADING',
}

const state = {
  types: [],
  currentWorkOrder: {
    attributes: {},
    relationships: {},
    meta: {},
  },
  currentWorkSite: {
    attributes: {},
    relationships: {},
    meta: {},
  },
  currentServiceInvoice: {
    attributes: {},
    relationships: {},
    meta: {},
  },
  workOrderLoading: false,
  workSiteLoading: false,
  serviceInvoiceLoading: false,
}

const mutations = {
  [types.SET_CURRENT_WORK_ORDER]: (state, data) => {
    state.currentWorkOrder = {
      ...state.currentWorkOrder,
      ...data,
    }
  },
  [types.SET_CURRENT_WORK_ORDER_LOADING]: (state, value) => {
    state.workOrderLoading = value
  },
  [types.SET_CURRENT_WORK_SITE]: (state, data) => {
    state.currentWorkSite = {
      ...state.currentWorkSite,
      ...data,
    }
  },
  [types.SET_CURRENT_WORK_SITE_LOADING]: (state, value) => {
    state.workSiteLoading = value
  },
}

const actions = {
  async getWorkOrder({ commit }, id) {
    try {
      commit(types.SET_CURRENT_WORK_ORDER_LOADING, true)
      const { data } = await axios.get(`/restify/work-orders/${id}`, {
        params: {
          related: 'customer.address,workSite,assignedEmployee,genLiabilityRate,workersCompRate,union,category,purchaseOrder,billingRateType,assignedEmployee,salespersonEmployee,suiSdiState,withholdingLocal,withholdingLocal2,withholdingState,serviceBillingCodes'
        }
      })
      commit(types.SET_CURRENT_WORK_ORDER, data)
    } finally {
      commit(types.SET_CURRENT_WORK_ORDER_LOADING, false)
    }
  },
  updateWorkOrder({ state }, workOrder = {}) {
    state.currentWorkOrder.attributes = workOrder.attributes
  },
  async getWorkSite({ commit }, id) {
    try {
      commit(types.SET_CURRENT_WORK_SITE_LOADING, true)

      const params = {
        related: `${entityPreviewFields.Customer},district[id|code],${entityPreviewFields.SalesPerson},category[id|code]`,
      }

      const { data } = await axios.get(`/restify/work-sites/${id}`, {
        params,
      })
      commit(types.SET_CURRENT_WORK_SITE, data)
    } finally {
      commit(types.SET_CURRENT_WORK_SITE_LOADING, false)
    }
  },
  async getServiceInvoice({ state }, id) {
    try {
      state.serviceInvoiceLoading = true
      const { data } = await axios.get(`/restify/billings/${id}`, {
        params: {
          related: 'workOrder,customer,customer.addresses,district,billingRateType',
        }
      })
      state.currentServiceInvoice = {
        ...state.currentServiceInvoice,
        ...data,
      }
    } finally {
      state.serviceInvoiceLoading = false
    }
  },
  async patchWorkOrder({ state }, data = {}) {
    await axios.patch(`/restify/work-orders/${data.id}`, data)
  }
}

export default {
  namespaced: true,
  types,
  state,
  mutations,
  actions,
}
