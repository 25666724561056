<template>
  <div class="payroll-stubs h-full flex flex-col">
    <AgDataTable
        :columns="columns"
        :url="url"
        :url-params="urlParams"
        :select-row-filter="hasEmail"
        :permission="isProduction ? '' : 'employees'"
        :default-match-filters="defaultMatchFilters"
        :add-text="$t('New employee')"
        :actions="actions"
        :actions-column-width="170"
        :enableFillHandle="true"
        :enableRangeSelection="true"
        :disable-col-flex="true"
        :per-page="$pagination.list"
        import-url="/payroll/employees/import"
        ref="table"
        default-sort="code"
        entity="employees"
        @data-fetch="employees = $event"
        @selection-change="selectedRows = $event"
        @add="onAddEmployee"
    >
      <template #attributes.code="{row}">
        <EmployeeLink :data="row" :show-name="false"/>
      </template>
      <template #attributes.email="{row, index}">
        <div class="truncate">
          <a v-if="getEmail(row)"
             :href="`mailto:${getEmail(row)}`"
             class="cursor-pointer truncate">
            {{ getEmail(row) }}
          </a>
          <base-button v-else-if="$isAuthorized('authorizedToUpdate', row) && isProduction"
                       variant="white"
                       @click="addEmail(row, index)">
            {{ $t('Add Email') }}
          </base-button>
          <span v-else>

          </span>
        </div>
      </template>
      <template #attributes.sent_count="{row}">
        <base-badge :type="row.attributes.sent_count > 0 ? 'success' : 'info'">
          {{ $tc('sent times', row.attributes.sent_count, { count: row.attributes.sent_count }) }}
        </base-badge>
      </template>
      <template #attributes.user_status="{row}">
        <EmployeeUserStatus :employee="row"/>
      </template>
      <template #extra-actions="{row, index}">
        <table-email-button v-if="$isAuthorized('authorizedToSendConsentEmail', row)"
                            @click="sendEmail(row)"
                            :loading="row.loading"
        />
      </template>
      <template #dropdown-actions>
        <TableActionItem>
          <button
              :disabled="deleteUsersDisabled || removeUsersLoading"
              :class="{ 'opacity-50 cursor-not-allowed': deleteUsersDisabled || removeUsersLoading }"
              class="action-item-text"
              @click="deleteSelectedUsers"
          >
            <div class="p-2 bg-red-50 mr-2 rounded-md">
              <Trash2Icon class="w-4 h-4 text-red-500"/>
            </div>
            <span>{{ $t('Remove User(s)') }}</span>
          </button>
        </TableActionItem>
      </template>
      <template #additional-actions
                v-if="$can('employee_checks_update')">
        <div>
          <base-button variant="primary"
                       class="mr-2"
                       :disabled="sendConsentDisabled"
                       @click="sendEmails"
          >
            <MailIcon class="w-5 h-5 mr-2"/>
            <div>{{ $t('Send consent email') }}</div>
          </base-button>
        </div>
        <div>
          <base-button variant="primary"
                       :disabled="createUsersDisabled"
                       :loading="createUsersLoading"
                       @click="attachUsers"
          >
            <IconAdd class="w-5 h-5 mr-2"/>
            <div>{{ $t('Create users') }}</div>
          </base-button>
        </div>
      </template>
    </AgDataTable>
    <base-form-dialog v-if="showFormDialog"
                      :key="rowToEdit.id"
                      :title="rowToEdit.id ? `${getEmployee(rowToEdit).name} (${getEmployee(rowToEdit).code})` : $t('Add Employee')"
                      :show-arrows="!!rowToEdit.id"
                      :open.sync="showFormDialog"
                      :size="isProduction ? 'default' : '2xl'"
                      :previous-disabled="selectedIndex <= 0"
                      :next-disabled="selectedIndex === employees.length - 1 && isLastPage()"
                      @next-click="onNextClick"
                      @prev-click="onPrevClick"
                      @close="closeFormDialog"
    >
      <template v-if="isProduction">
        <employee-form-minimal
            :data="rowToEdit"
            @cancel="closeFormDialog"
            @on-next="onNextClick"
            @attach-user="attachSingleUser"
            @re-fetch-employee="reFetchEmployee"
        />
      </template>
      <template v-else>
        <employee-form-full
            :data="rowToEdit"
            @cancel="closeFormDialog"
            @on-next="onNextClick"
            @attach-user="attachSingleUser"
            @refresh-table="refreshTable"
            @re-fetch-employee="reFetchEmployee"
        />
      </template>
    </base-form-dialog>
  </div>
</template>
<script>
  import axios from 'axios'
  import { MailIcon, Trash2Icon } from 'vue-feather-icons'
  import TableEditButton from '@/components/table/actions/TableEditButton'
  import TableActionItem from '@/components/table/actions/TableActionItem'
  import EmployeeFormMinimal from '@/modules/payroll/components/employee/EmployeeFormMinimal'
  import EmployeeFormFull from '@/modules/payroll/components/employee/EmployeeFormFull'
  import { defineComponent } from 'vue'
  import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";
  import { jobStatusOptions } from "@/modules/job-costing/enum/jobs";
  import { cellClasses } from "@/components/ag-grid/columnUtils";
  import { valueSetterWithUpdate } from "@/components/ag-grid/cellEditors/cellEditorUtils";
  import { statusOptions } from "@/modules/payroll/utils/employeeUtils";
  import EmployeeUserStatus from "@/modules/payroll/components/employee/EmployeeUserStatus.vue";

  export default defineComponent({
    components: {
      EmployeeUserStatus,
      MailIcon,
      Trash2Icon,
      EmployeeFormMinimal,
      EmployeeFormFull,
      TableEditButton,
      TableActionItem,
    },
    data() {
      return {
        showFormDialog: false,
        showCheckPreviewDialog: false,
        rowToEdit: null,
        selectedIndex: -1,
        allPagesSelected: false,
        sendEmailLoading: false,
        removeUsersLoading: false,
        createUsersLoading: false,
        modelToEdit: {
          email: '',
          name: '',
          dob: null,
          hire_date: null,
        },
        employees: [],
        selectedRows: [],
        columns: [
          {
            label: this.$t('Code'),
            prop: 'attributes.code',
            component: 'EntityLink',
            redirectTo: '/payroll/employees/{ID}/view',
            minWidth: 160,
            showDisabledCheckboxes: true,
            checkboxSelection: params => {
              const canUpdateEmployees = this.$can('employees_update')
              return canUpdateEmployees && this.getEmail(params.data)
            },
            headerCheckboxSelection: true,
          },
          {
            label: this.$t('Name'),
            prop: 'attributes.name',
            minWidth: 150,
            maxWidth: 320,
          },
          {
            label: this.$t('Status'),
            prop: 'attributes.status',
            component: 'Status',
            minWidth: 120,
            editable: true,
            cellEditor: cellEditors.Status,
            cellEditorParams: {
              options: statusOptions,
            },
            headerClass: cellClasses.HeaderEditable,
            valueSetter: (params) => {
              return valueSetterWithUpdate({
                storeAction: 'payroll/patchEmployee',
              })(params)
            },
          },
          {
            label: this.$t('Phone'),
            minWidth: 150,
            maxWidth: 200,
            prop: 'attributes.phone_1',
            component: 'PhoneLink',
          },
          {
            label: this.$t('Email'),
            prop: 'attributes.email',
            minWidth: 250,
            maxWidth: 350,
            component: 'EmailLink',
          },
          {
            label: this.$t('Hire Date'),
            prop: 'attributes.hire_date',
            minWidth: 140,
            align: 'center',
            component: 'FormattedDate',
          },
          {
            label: this.$t('User Status'),
            prop: 'attributes.user_status',
            minWidth: 140,
            align: 'center',
          },
        ],
      }
    },
    computed: {
      url() {
        return '/restify/employees'
      },
      urlParams() {
        return !this.isProduction ? {} : {
          related: 'user',
        }
      },
      actions() {
        const actions = ['search','refresh','view']
        if (this.$can('employees_update')) {
          actions.push(...['import', 'export', 'bulk-delete'])
        }
        return actions.join(',')
      },
      createUsersDisabled() {
        return !this.selectedRows.some(e => !e.attributes.user_id)
      },
      deleteUsersDisabled() {
        return !this.selectedRows.some(e => e.attributes.user_id)
      },
      sendConsentDisabled() {
        return !this.selectedRows.some(e => !e.attributes.payroll_consent_confirmed_at)
      },
      defaultMatchFilters() {
        return this.employeeStatusFilter
      },
    },
    methods: {
      isLastPage() {
        const { last_page, current_page } = this.get(this.$refs, 'table.pagination', {})
        return last_page === current_page
      },
      onAddEmployee() {
        this.rowToEdit = {}
        this.selectedIndex = -1
        this.showFormDialog = true
      },
      async deleteSelectedUsers() {
        const selectedUsersToDelete = this.selectedRows.filter(e => e.attributes.user_id)
        const confirmed = await this.$deleteConfirm({
          title: this.$t(`Remove Users from ${selectedUsersToDelete.length} employee(s)`),
          description: this.$t('This action will remove the associated users from the selected employees. They will no longer be able to log in, but you can invite them again at a later point in time.'),
          buttonText: this.$t('Remove'),
        })
        if (!confirmed) {
          return
        }
        try {
          this.removeUsersLoading = true
          let payload = {
            repositories: [],
          }
          if (this.allPagesSelected) {
            payload.repositories = 'all'
          } else {
            payload.repositories = selectedUsersToDelete.map(r => r.id)
          }
          await axios.post('/restify/employees/action?action=detach-users-from-employees', payload)
          this.allPagesSelected = false
          this.$refs.table.deselectAllRows()
          this.$success(this.$t('Users will be removed from the selected employees'))
          this.$refs.table.refresh()
        } catch (err) {
          this.$error('An error occurred while trying to remove the user from the employee')
        } finally {
          this.removeUsersLoading = false
        }
      },
      async sendEmails() {
        const selectedEmails = this.selectedRows.filter(e => this.hasEmail(e))
        const confirmed = await this.$confirm({
          title: this.$t(`Send Emails to ${selectedEmails.length} employees`),
          description: this.$t(`This action will send consent emails to ${selectedEmails.length} employees.`),
          buttonText: this.$t('Send'),
        })

        if (!confirmed) {
          return
        }
        try {
          this.sendEmailLoading = true
          let payload = {
            repositories: [],
          }
          if (this.allPagesSelected) {
            payload.repositories = 'all'
          } else {
            payload.repositories = this.selectedRows.map(r => r.id)
          }
          await axios.post(`/restify/employees/action?action=send-consent-mail`, payload)
          this.allPagesSelected = false
          this.$refs.table.deselectAllRows()
          this.$success(this.$t('Consent emails are queued to be sent. The selected employees will receive them in a couple of minutes.'))
          this.$refs.table.refresh()
        } catch (err) {
          this.$error('An error occurred while trying to send the consent emails')
        } finally {
          this.sendEmailLoading = false
        }
      },
      async sendEmail(row) {
        const confirmed = await this.$confirm({
          title: this.$t(`Send Email to: ${row.attributes.name}`),
          description: this.$t(`This action will send consent email to <b>${row.attributes.email}.</b>`),
          buttonText: this.$t('Send'),
        })

        if (!confirmed) {
          return
        }
        try {
          this.$set(row, 'loading', true)
          await axios.post('/restify/employees/action?action=send-consent-mail', {
            repositories: [row.id],
          })
          this.$success(this.$t(`Consent email are queued to be sent. The ${row.attributes.name} will receive them in a couple of minutes.`))
          this.$refs.table.refresh()
        } catch (err) {
          this.$error('An error occurred while trying to send the consent email.')
        } finally {
          this.$delete(row, 'loading')
        }
      },
      async attachSingleUser() {
        await this.attachUsers()
        await this.onNextClick()
      },
      async attachUsers() {
        const selectedEmails = this.selectedRows.filter(e => this.hasEmail(e) && !e?.attributes?.user_id)
        const confirmed = await this.$confirm({
          title: this.$t(`Create users for ${selectedEmails.length} employees`),
          description: this.$t(`This action will create users and send registration confirmation emails to ${selectedEmails.length} employees.`),
          buttonText: this.$t('Create'),
        })
        if (!confirmed) {
          return
        }

        try {
          this.createUsersLoading = true
          let payload = {
            repositories: [],
          }
          if (this.allPagesSelected) {
            payload.repositories = 'all'
          } else {
            payload.repositories = this.selectedRows.map(r => r.id)
          }
          await axios.post(`/restify/employees/actions?action=attach-users-to-employees`, payload)
          this.allPagesSelected = false
          this.$refs.table?.deselectAllRows()
          this.$refs.table?.refresh()
        } catch (err) {
          this.$error('An error occurred while trying to send the registration emails')
        } finally {
          this.createUsersLoading = false
        }
      },
      hasEmail(row) {
        const email = this.getEmail(row)
        return email !== '' && email !== null
      },
      getEmail(row) {
        return this.get(row, 'attributes.email', '')
      },
      getEmployee(row) {
        return this.get(row, 'attributes', {})
      },
      refreshTable() {
        this.showFormDialog = false
        this.$refs.table.refresh()
      },
      async onNextClick() {
        if (this.selectedIndex === this.employees.length - 1) {
          if (!this.isLastPage()) {
            this.$refs.table.pagination.current_page++
            await this.$refs.table.refresh()
            this.selectedIndex = 0
            this.rowToEdit = this.employees[this.selectedIndex]
            return
          }
          this.showFormDialog = false
          return
        }
        this.selectedIndex++
        this.rowToEdit = this.employees[this.selectedIndex]
      },
      onPrevClick() {
        this.selectedIndex--
        this.rowToEdit = this.employees[this.selectedIndex]
      },
      async addEmail(row, index) {
        this.rowToEdit = row
        this.rowToEdit.loading = false
        this.selectedIndex = index
        this.showFormDialog = true
        this.rowToEdit = await this.$store.dispatch('payroll/getEmployee', row.id)
      },
      closeFormDialog() {
        this.showFormDialog = false
        this.rowToEdit = null
        this.selectedIndex = -1
      },
      async reFetchEmployee(id, callback) {
        try {
          const { data } = await axios.get(`/restify/employees/${id}`, {
            params: this.urlParams,
          })
          this.rowToEdit = data
          this.$set(this.employees, this.selectedIndex, data)
          return callback()
        } catch (err) {
          console.warn(err)
        }
      },
    },
  })
</script>
<style lang="scss">
  .payroll-stubs table {
    .td-select,
    .th-select {
      min-width: 2.5rem;
      @apply pl-3 pr-0;
    }
  }
</style>
