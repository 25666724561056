<template>
  <div class="vendor-details">
    <DetailsPageLayout :loading="workSiteLoading">
      <template #left>
        <WorkSiteMainCard :worksite="workSite"/>
        <WorkSiteMainDetailsCard :worksite="workSite"/>
      </template>
      <template #right>
        <WorkSiteSecondaryCard
            :work-site="workSite"
            :work-site-data="workSiteData"/>
      </template>
    </DetailsPageLayout>
    <base-back-link to="/service-billing/worksites"/>
  </div>
</template>
<script>
import { formattedAddress, locationLink } from '@/utils/utils'
import WorksiteWorkOrders from '@/modules/service-billing/pages/worksites/worksite-work-orders'
import DetailsPageLayout from "@/modules/common/components/DetailsPageLayout.vue";
import WorkSiteMainDetailsCard from "@/modules/service-billing/components/worksite/WorkSiteMainDetailsCard.vue";
import WorkSiteMainCard from "@/modules/service-billing/components/worksite/WorkSiteMainCard.vue";
import WorkSiteSecondaryCard from "@/modules/service-billing/components/worksite/WorkSiteSecondaryCard.vue";

export default {
  inheritAttrs: false,
  components: {
    WorkSiteSecondaryCard,
    WorkSiteMainCard,
    WorkSiteMainDetailsCard,
    DetailsPageLayout,
    WorksiteWorkOrders,
  },
  computed: {
    workSiteLoading() {
      return this.$store.state.serviceBilling.workSiteLoading
    },
    workSite() {
      return this.$store.state.serviceBilling.currentWorkSite
    },
    workSiteData() {
      return this.workSite?.attributes || {}
    },
  },
  methods: {
    locationLink,
    formattedAddress,
  },
}
</script>
