<template>
  <BaseDataTable
    :url="url"
    :url-params="urlParams"
    :columns="columns"
    :show-actions-column="false"
    :show-pagination="false"
    :default-sort="defaultSort"
    class="-mt-4"
  >
    <template #additional-actions>
      <slot name="actions"/>
    </template>
    <template #links="{row}">
      <div v-if="!row.links?.length">
        <span class="text-gray-500 text-sm">{{$t('No linked line items')}}</span>
      </div>
      <div v-else>
        <div class="w-full flex">
          <div class="w-[300px] text-sm text-gray-700">
            {{$t('Line Item')}}
          </div>
          <div class="w-[100px] text-right text-sm text-gray-700">
            {{$t('Budget')}}
          </div>
          <div class="w-[100px] text-right text-sm text-gray-700">
            {{$t('Actual')}}
          </div>
        </div>
        <div v-for="link in row.links" :key="link.id" class="flex">
          <div class="w-[300px] truncate">
            <LineItemLink :id="link.id" link-class="font-normal !text-gray-700 text-sm truncate"/>
          </div>
          <div class="w-[100px] text-right text-sm text-gray-700">
            {{$formatPrice(link.amount)}}
          </div>
          <div class="w-[100px] text-right text-sm text-gray-700">
            {{$formatPrice(link.amount_to_date)}}
          </div>
        </div>
        <div class="w-full flex">
          <div class="w-[300px]">
          </div>
          <div class="w-[100px] text-right text-sm text-gray-700">
            {{ $formatPrice(row.link_totals.amount) }}
          </div>
          <div class="w-[100px] text-right text-sm text-gray-700">
            {{ $formatPrice(row.link_totals.amount_to_date) }}
          </div>
        </div>
      </div>
    </template>
    <template #amount="{row}">
      <LineItemLinkedBudgetsDiffCell
        :row="row"
        :line-item-type="type"
        amount-key="amount"
      />
    </template>

    <template #amount_to_date="{row}">
      <LineItemLinkedBudgetsDiffCell
        :row="row"
        :line-item-type="type"
        amount-key="amount_to_date"
      />
    </template>
  </BaseDataTable>
</template>
<script>
import LineItemLinkedBudgetsDiffCell
  from "@/modules/job-costing/components/line-items/LineItemLinkedBudgetsDiffCell.vue";

export default {
  components: {
    LineItemLinkedBudgetsDiffCell
  },
  props: {
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      defaultSort: 'phase_code,cost_code,change_order',
    }
  },
  computed: {
    url() {
      return '/restify/line-items/linked-line-items'
    },
    urlParams() {
      return {
        type: this.type,
        job_id: this.$route.params.id,
      }
    },
    columns() {
      return [
        {
          label: this.$t('Description'),
          prop: 'description',
          component: 'EntityLink',
          entityKey: 'id',
          redirectTo: `/job-costing/${this.type}-line-items/{ID}/view`,
          maxWidth: 200,
        },
        {
          label: this.$t('Phase Code'),
          prop: 'phase_code',
          minWidth: 30,
          maxWidth: 60,
        },
        {
          label: this.$t('Cost Code'),
          prop: 'cost_code',
          minWidth: 40,
          maxWidth: 60,
        },
        {
          label: this.$t('Chg Order'),
          prop: 'change_order',
          minWidth: 40,
          maxWidth: 60,
          align: 'center',
        },
        {
          label: this.$t('Linked Line Items'),
          prop: 'links',
          minWidth: 400,
          maxWidth: 400,
        },
        {
          label: this.$t('Total Budget'),
          prop: 'amount',
          align: 'right',
        },
        {
          label: this.$t('Total Actual'),
          prop: 'amount_to_date',
          align: 'right',
        },
      ]
    }
  }
}
</script>
