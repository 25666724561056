<template>
  <base-form
      :loading="loading"
      :show-cancel="showCancel"
      :save-text="$t('Create direct deposit code')"
      :update-text="$t('Update direct deposit code')"
      :can-create-another-entity="!model.id"
      layout="modal"
      @submit="onSubmit"
      @cancel="$emit('cancel')"
  >
    <code-input
      v-model="model.code"
      :model="model"
      :resource-name="globalResources.DirectDepositCodes"
      class="col-span-6 md:col-span-2"
    />
    <base-input
        v-model="model.description"
        :label="$t('Description')"
        :placeholder="$t('Description')"
        class="col-span-6 md:col-span-3"
    >
    </base-input>
    <div class="col-span-6 md:col-span-1">
      <ActiveSwitch v-model="model.active"/>
    </div>
  </base-form>
</template>
<script lang="ts">
  import axios from 'axios'
  import {defineComponent} from 'vue'
  import {DirectDepositCode} from '@/modules/common/types/models'
  import {globalResources} from "@/components/form/util";

  export default defineComponent({
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        loading: <boolean>false,
        showCancel: <boolean>true,
        globalResources,
        model: {
          code: '',
          description: '',
          active: true,
          show_in_ytds: false,
        } as DirectDepositCode,
      }
    },
    methods: {
      async onSubmit() {
        try {
          this.loading = true
          if (this.model.id) {
            await axios.put(`/restify/direct-deposit-codes/${this.model.id}`, this.model)
            this.$success(this.$t('Direct deposit code updated'))
            this.$emit('save', this.model)
          } else {
            const {data} = await axios.post('/restify/direct-deposit-codes', this.model)
            this.$success(this.$t('Direct deposit code added'))
            // @ts-ignore
            this.$createAnotherEntity ? this.$emit('create-another') : this.$emit('save', data)
          }
        } catch (err: any) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not update the direct deposit code'))
        } finally {
          this.loading = false
        }
      },
    },
    watch: {
      data: {
        immediate: true,
        handler(value) {
          const data = value?.attributes || {}
          this.model = {
            ...this.model,
            ...data,
          }
        },
      },
    },
  })
</script>
