import router from "@/router";

export function getNestedRoutes(route) {
  let results = []
  if (!route.children) {
    return results
  }
  route.children.forEach(route => {
    results.push(route)
    results.push(...getNestedRoutes(route))
  })
  return results
}

export function checkIfRouteExists(route) {
  const result = router.resolve(route)
  return result.resolved.name !== 'Not Found'
}
