<template>
  <div
    v-loading="loading"
    class="flex flex-col min-h-[300px]"
  >
    <div
      class="mb-6 flex flex-row items-baseline space-x-3"
    >

      <el-upload
        :auto-upload="false"
        :on-change="handleImageSelection"
        :on-remove="resetCropper"
        :show-file-list="true"
        :limit="1"
        ref="upload"
        class="col-span-6 md:col-span-2 mt-6"
        action="default"
        accept=".jpeg, .png"
      >
        <base-button variant="white">
          {{ $t(actionTitle) }}
        </base-button>
      </el-upload>
    </div>
    <div class="flex w-full justify-center space-x-4">
      <div class="w-3/4">
        <VueCropper
          v-show="selectedImage"
          :src="selectedImage"
          ref="cropper"
        />
      </div>
    </div>
    <div v-if="croppedImage" class="flex w-full justify-center">
      <div
        class="w-3/4 mt-4"
      >
        <p class="mb-4 font-semibold text-center text-sm">{{ $t('Signature to be used') }}</p>
        <img
          :src="croppedImage"
          class="border rounded-md"
        >
      </div>
    </div>
  </div>
</template>

<script>
import 'cropperjs/dist/cropper.css'
import { Upload } from 'element-ui'
import VueCropper from 'vue-cropperjs'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    VueCropper,
    [Upload.name]: Upload,
  },
  props: {
    entity: {
      type:  String,
      required: true,
    },
    entityId: {
      type: [String, Number],
    },
    actionTitle: {
      type: String,
      default: 'Attach Image',
    },
  },
  data() {
    return {
      selectedImage: null,
      croppedImage: null,
      loading: false,
      files: [],
      selectedImageFullName: null,
    }
  },
  computed: {
    baseUrl() {
      return `/restify/${ this.entity }/${ this.entityId }`
    }
  },
  methods: {
    cropImage() {
      this.croppedImage = this.$refs.cropper?.getCroppedCanvas()?.toDataURL()
    },
    reset() {
      this.$refs.cropper.reset()
      this.croppedImage = null
    },
    resetCropper() {
      this.selectedImage = this.croppedImage = null
      this.$refs.cropper.clear()
    },
    handleImageSelection(fileData, rawFileData = true) {
      this.selectedImageFullName = fileData.name

      const file = rawFileData ? fileData.raw : fileData
      const reader = new FileReader()

      reader.onload = (event) => {
        this.selectedImage = event.target.result
        this.$refs.cropper.replace(event.target.result)
      }

      reader.readAsDataURL(file)
    },
    // * Convert base64 image to file, so we can send it to the server as a file, cause the server expects a file not a base64 image string
    convertBase64ToImageFile(base64Image) {
      const byteCharacters = atob(base64Image.split(',')[1])
      const byteNumbers = new Array(byteCharacters.length)

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      const type = base64Image.split(';')[0].split(':')[1]
      const blob = new Blob([byteArray], { type })

      const parts = this.selectedImageFullName.split('.')
      const fileName = parts[0]
      const fileExtension = parts[parts.length - 1]
      const fileWithExtension = `${ fileName }.${ fileExtension }`

      return new File([blob], fileWithExtension, { type: blob.type })
    },
    saveImage() {
      if (!this.selectedImage) {
        this.$error(this.$t('Please select an image first.'))
        return
      }
      this.cropImage()
      return this.convertBase64ToImageFile(this.croppedImage)
    },
  },
})
</script>
