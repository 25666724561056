<template>
  <entity-preview
      v-bind="$attrs"
      :entity="customerData"
      :key="`preview-${customerData.id}`"
      :url="`/accounts-receivable/customers/${customerData.id}/view`"
      :value-to-display="displayValue"
      :parent-entity-id="customerData.id"
      :show-preview="showPreview"
      :target="target"
      :link-class="linkClass"
      :is-link="!editableCell"
      entity-name="customer"
  />
</template>
<script>
  export default {
    name: 'CustomerLink',
    props: {
      id: [String, Number],
      data: {
        type: Object,
        default: () => ({}),
      },
      showCode: {
        type: Boolean,
        default: true,
      },
      showName: {
        type: Boolean,
        default: false,
      },
      showPreview: {
        type: Boolean,
        default: true,
      },
      linkClass: {
        type: String,
        default: 'text-base print:text-print text-gray-900',
      },
      target: String,
      editableCell: Boolean,
    },
    computed: {
      allCustomers() {
        return this.$store.state.globalLists.customers
      },
      customerData() {
        if (this.id) {
          return this.allCustomers.find(j => j.id === this.id) || {}
        }
        return this.data?.attributes || this.data || {}
      },
      displayValue() {
        let { id, code, name } = this.customerData

        if (!id) {
          return ''
        }

        if (!this.showName || !name) {
          return code
        }
        if (this.showCode) {
          return `${code}  (${name})`
        }
        return name
      }
    },
  }
</script>
