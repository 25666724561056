<template>
  <div>
    <div class="flex justify-between">
      <layout-tabs :items="filteredItems" class="mt-0"/>
      <div v-if="showConfirmDirectDepositButton">
        <ConfirmDirectDepositDialog :batch="currentResource"/>
      </div>
      <div v-if="showConfirmPositivePayButton">
        <ConfirmPositivePayDialog :batch="currentResource"/>
      </div>
      <template v-if="currentResource">
        <portal to="page-title">
          <div class="flex items-center justify-between md:justify-start">
            {{ pageTitle }}
            <span class="mx-1 md:mx-2 flex items-center">
              <status-link
                v-if="currentResource.attributes"
                :row="currentResource"
              />
            </span>
            <DirectDepositConfirmStatus :batch="currentResource"/>
            <PositivePayConfirmStatus :batch="currentResource"/>
          </div>
        </portal>
      </template>
    </div>
    <router-view/>
  </div>
</template>
<script>
  import layoutPermissions from '@/mixins/layoutPermissions'
  import { resourceStatuses } from "@/enum/enums";
  import ConfirmDirectDepositDialog from "@/modules/payroll/components/payroll/ConfirmDirectDepositDialog.vue";
  import DirectDepositConfirmStatus from "@/modules/payroll/components/payroll/DirectDepositConfirmStatus.vue";
  import ConfirmPositivePayDialog from "@/modules/payroll/components/payroll/ConfirmPositivePayDialog.vue";
  import PositivePayConfirmStatus from "@/modules/payroll/components/payroll/PositivePayConfirmStatus.vue";

  export default {
    components: { PositivePayConfirmStatus, ConfirmPositivePayDialog, DirectDepositConfirmStatus, ConfirmDirectDepositDialog },
    mixins: [layoutPermissions],
    data() {
      return {
        resourceStatuses,
      }
    },
    computed: {
      isPending() {
        return this.currentResource.attributes.status === resourceStatuses.Pending
      },
      isDDRoute() {
        return this.$route.path.includes('direct-deposit')
      },
      isPositivePayRoute() {
        return this.$route.path.includes('positive-pay')
      },
      showConfirmDirectDepositButton() {
        return !this.isPending && this.isDDRoute
      },
      showConfirmPositivePayButton() {
        return !this.isPending && this.isPositivePayRoute
      },
      items() {
        return [
          {
            name: this.$t('Summary'),
            tooltip: this.$t('Payroll Register Summary'),
            path: `/payroll/batches/${this.$route.params.id}/summary`,
            authorizedTo: 'authorizedToShow',
          },
          {
            name: this.$t('Adjust Taxes'),
            tooltip: this.$t('Adjust Payroll Register Taxes'),
            path: `/payroll/batches/${this.$route.params.id}/adjust-taxes`,
            authorizedTo: 'authorizedToShow',
            enabled: () => this.isPending
          },
          {
            name: this.$t('Adjust Entries'),
            tooltip: this.$t('Adjust Payroll Register Entries'),
            path: `/payroll/batches/${this.$route.params.id}/adjust-entries`,
            authorizedTo: 'authorizedToShow',
            enabled: () => this.isPending
          },
          {
            name: this.isPending ? this.$t('Preview Certified Payroll'): this.$t('Certified Payroll'),
            tooltip: this.$t('Preview Certified Payroll'),
            path: `/payroll/batches/${this.$route.params.id}/preview-certified-payroll`,
            authorizedTo: 'authorizedToShow',
          },
          {
            name: this.isPending ? this.$t('Preview Positive Pay') : this.$t('Positive Pay'),
            tooltip: this.$t('Positive Pay Proof Listing'),
            path: `/payroll/batches/${this.$route.params.id}/positive-pay`,
            authorizedTo: 'authorizedToShow',
          },
          {
            name: this.isPending ? this.$t('Preview Direct Deposit') : this.$t('Direct Deposit'),
            tooltip: this.$t('Direct Deposit Listing or File Download'),
            path: `/payroll/batches/${this.$route.params.id}/direct-deposit`,
            authorizedTo: 'authorizedToShow',
          },
        ]
      },
      currentResource() {
        return this.$store.state.payroll.currentPayrollBatch
      },
      periodEndDate() {
        return this.currentResource?.attributes?.period_end_date
      },
      formattedEndDate() {
        if (!this.periodEndDate) {
          return ''
        }
        return this.$formatDate(this.periodEndDate, 'date', true)
      },
      pageTitle() {
        const description = this.get(this.currentResource, 'attributes.description')
        let title = `${this.$route.name} ${this.formattedEndDate}`
        if (description) {
          title = `${title} (${description})`
        }
        return title
      },
    },
    methods: {
      async getCurrentResource(id) {
        await this.$store.dispatch('payroll/getPayrollBatch', id)
        document.title = this.pageTitle
      },
    },
  }
</script>
