<template>
  <base-button
      :variant="variant"
      class="print:hidden proof-listing-button"
      size="xs"
      v-bind="$attrs"
      v-on="$listeners"
      @click="goToProofListing"
  >
    <div class="flex items-center">
      <BookIcon class="w-5 h-5 mr-2"/>
      <span>
        <slot>{{ $t('Proof Listing') }}</slot>
      </span>
    </div>
  </base-button>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { BookIcon } from 'vue-feather-icons'

  export default defineComponent({
    name: 'ProofListingButton',
    components: {
      BookIcon,
    },
    props: {
      variant: {
        type: String,
        default: 'success-link',
      },
      path: {
        type: String,
        required: true,
      },
      selectedRows: {
        type: Array,
        default: () => [],
      },
      queryParams: {
        type: Object,
      },
      callbackAction: Function,
    },
    methods: {
      async goToProofListing() {
        if (this.callbackAction) {
          return this.callbackAction()
        }

        // If the number of selected rows is greater than 10, we store the ids in local storage because of browser url length limits
        const ids = this.selectedRows.map(i => i.id)
        const maxUrlIds = 10

        let query: any = {
          ids
        }
        if (this.queryParams && ids.length <= maxUrlIds) {
          query = {
            ...this.queryParams
          }
        }
        if (ids.length > maxUrlIds) {
          delete query.ids
          localStorage.setItem('proofListingIds', JSON.stringify(ids))
        }
        this.$router.push({
          path: this.path,
          query
        })
      }
    }
  })
</script>
<style>
  .proof-listing-button .btn {
    @apply px-0;
  }
</style>
