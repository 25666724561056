<template>
  <TableActionItem>
    <BaseButton
      class="py-2"
      variant="primary-link"
      size="sm"
      :loading="loading"
      @click="validateJournalBalance"
    >
      <div class="p-2 bg-primary-50 mr-2 rounded-md">
        <CheckCircleIcon class="w-4 h-4 text-primary-500"/>
      </div>
      <span>{{ $t('Validate Journal Balance') }}</span>
    </BaseButton>
    <BaseFormDialog
      v-if="showErrorsDialog"
      :open.sync="showErrorsDialog"
      :appendToBody="true"
      :has-padding="true"
    >
      <div class="text-gray-700 text-lg my-4 ml-6">
        {{ $t('The following errors were found:') }}
      </div>
      <div class="prose">
        <ul
          v-for="(error, errorKey) in errors"
          :key="errorKey"
          class="my-2"
        >
          <span class="font-medium text-lg ml-1">{{$t('Journal: ')}} {{ errorKey }}</span>
          <li v-for="message in error" :key="message" class="text-red-700">
            {{ message }}
          </li>
        </ul>
      </div>
    </BaseFormDialog>
  </TableActionItem>
</template>
<script setup lang="ts">
import {CheckCircleIcon} from "vue-feather-icons";
import axios from "axios";
import {ref} from "vue";

const errors = ref([])
const showErrorsDialog = ref(false)
const loading = ref(false)

async function validateJournalBalance() {
  try {
    loading.value = true
    const {data} = await axios.post(`/restify/journals/actions?action=validate-journal-balance`)
    errors.value = data.errors
    if (Object.values(data.errors)?.length) {
      showErrorsDialog.value = true
    }
  } finally {
    loading.value = false
  }
}
</script>
