<template>
  <div>
    <div class="flex justify-between w-full leading-4">
      <div v-if="level === 0" class="flex space-x-6 w-[280px]">
        <span>
          {{ employee.code }} {{ employee.name }}
        </span>
      </div>
      <div class="flex space-x-4 print:mr-6">
        <div>
          ({{ checkType }})
        </div>
        <div v-if="timecard.payment_number" class="flex space-x-2">
          <div>{{ $t('Check No:') }}</div>
          <div>{{ timecard.payment_number }}</div>
        </div>
        <div v-if="timecard.payment_date" class="flex space-x-2">
          <div>{{ $t('Date:') }}</div>
          <div>{{ $formatDate(timecard.payment_date) }}</div>
        </div>
        <div v-if="bank?.id" class="flex space-x-2">
          <div>{{ $t('Bank:') }}</div>
          <div>{{ bank.name }}</div>
        </div>
      </div>
    </div>
    <template v-if="level === 1">
      <div class="text-red-500 leading-none"
           v-for="error in errors">
        <span>{{ error.message }}</span>
      </div>
    </template>
  </div>
</template>
<script>
  import { checkTypeOptions } from "@/modules/payroll/components/timecard/checkTypeOptions";

  export default {
    computed: {
      data() {
        return this.params.node?.allLeafChildren[0]?.data || {}
      },
      errors() {
        return this.data.invoice?.errors || []
      },
      employee() {
        return this.data?.employee || {}
      },
      timecard() {
        return this.data?.timecard || {}
      },
      bank() {
        return this.data?.bank || {}
      },
      checkType() {
        const type = this.timecard?.type
        return checkTypeOptions.find(c => c.value === type)?.label || ''
      },
      level() {
        return this.params.node.level
      }
    }
  }
</script>
