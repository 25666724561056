<template>
  <div>
    <nav class="flex space-x-4 nav-bar" aria-label="Tabs">
      <a href="javascript:;"
         :class="{'active': currentStatus === resourceStatuses.Pending}"
         class="nav-item"
         @click="onFilterInvoices(resourceStatuses.Pending)"
      >
        {{ $t('Pending') }}
      </a>
      <a href="javascript:;"
         :class="{'active': currentStatus === resourceStatuses.Open}"
         class="nav-item"
         @click="onFilterInvoices(resourceStatuses.Open)"
      >
        {{ $t('Open') }}
      </a>
      <a href="javascript:;"
         :class="{'active': currentStatus === resourceStatuses.Paid}"
         class="nav-item"
         @click="onFilterInvoices(resourceStatuses.Paid)"
      >
        {{ $t('Paid') }}
      </a>
      <a href="javascript:;"
         :class="{'active': currentStatus === resourceStatuses.All}"
         class="nav-item"
         @click="onFilterInvoices(resourceStatuses.All)"
      >
        {{ $t('All') }}
      </a>
    </nav>
    <BillingsTable :billing-status="currentStatus"
                   :url-params="urlParams"
                   :default-match="false"
                   displayTypeColumn
    />
  </div>
</template>
<script>
  import { resourceStatuses } from '@/enum/enums'
  import BillingsTable from '@/modules/accounts-receivable/pages/billings/BillingsTable'

  export default {
    components: {
      BillingsTable,
    },
    props: {
      urlParams: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        resourceStatuses,
        currentStatus: this.$route.query?.status || resourceStatuses.All,
      }
    },
    methods: {
      onFilterInvoices(status) {
        this.currentStatus = status
        this.updateRouteQuery({ status })
      },
      updateRouteQuery(query) {
        this.$router.replace({ query: { ...query } })
      },
    },
  }
</script>
