<template>
  <div>
    <img
      :src="src"
      :alt="alt"
      loading="lazy"
      class="cursor-zoom-in"
      v-bind="$attrs"
      @click="showDialog = true"
    >
    <BaseFormDialog
      v-if="showDialog"
      :open.sync="showDialog"
      no-padding
      size="xl"
      @close="showDialog = false"
    >
      <div class="flex justify-center items-center">
        <img class="w-auto h-full max-h-[85vh]" loading="lazy" :src="src" :alt="alt" v-bind="$attrs">
      </div>
    </BaseFormDialog>
  </div>
</template>

<script lang="ts" setup>
import {ref} from "vue";

const props = defineProps({
  src: String,
  alt: String,
})

const showDialog = ref(false)
</script>
