<script>
  import get from 'lodash/get'
  import StatusBadge from '@/components/common/StatusBadge'

  export default {
    functional: true,
    components: {
      StatusBadge,
    },
    props: {
      column: {
        type: Object,
        default: () => ({}),
      },
      row: {
        type: Object,
        default: () => ({}),
      },
    },
    render(h, { props }) {
      function getValue() {
        return get(props.row, props.column.prop, 0)
      }

      return h(StatusBadge, {
        props: {
          status: getValue(),
        },
      })
    },
  }
</script>
