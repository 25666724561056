const state = {
    // Add new resource on the fly
    showAddDialog: false,
    resourceName: '',
}

const actions = {
    async setDialogState({ state }, show) {
        state.showAddDialog = show
    },
    async setResourceName({ state }, resource) {
        state.resourceName = resource
    },
    async resetOptions({ state }) {
        state.showAddDialog = false
        state.resourceName = ''
    }
}

const getters = {
    getDialogState: state => state.showAddDialog,
    getResourceName: state => state.resourceName,
}

export default {
    namespaced: true,
    state,
    actions,
    getters
};
