function isInViewport(element) {
  const rect = element.getBoundingClientRect();
  const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
  const windowWidth = (window.innerWidth || document.documentElement.clientWidth);

  // Check if any part of the element is visible in the viewport
  const vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0);
  const horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);

  return (vertInView && horInView);
}
export function getVisibleForm() {
  const forms = document.querySelectorAll('form');
  let lastVisibleForm = null;
  for (const form of forms) {
    if (isInViewport(form)) {
      lastVisibleForm = form
    }
  }
  return lastVisibleForm
}
