export default {
  inserted: (el, binding) => {
    const input = el.querySelector('input')
    const textarea = el.querySelector('textarea')
    const select = el.querySelector('select')
    const button = el.querySelector('button')
    const div = el.querySelector('div[role="checkbox"]')
    let elements = [input, textarea, select, button, div]
    const focusableElement = elements.find(el => el && el.focus)

    const elementToFocus = focusableElement || el
    const delay = binding.value || 0

    setTimeout(() => {
      elementToFocus?.focus()
    }, delay)
  },
}
