<template>
  <BaseWrapper v-bind="$attrs"
               :items="items"
               :entity="entity"
               :footer="footer"
               title-key="number"
               status-key="status"
  />
</template>
<script>
  import BaseWrapper from '@/modules/common/components/entity-preview/BaseWrapper'

  export default {
    components: {
      BaseWrapper,
    },
    props: {
      entity: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        items: [
          {
            title: this.$t('Paid'),
            value: this.$formatPrice(this.entity.paid_amount),
          },
          {
            title: this.$t('Open'),
            value: this.$formatPrice(this.entity.open_amount),
          },
        ],
        footer: {},
      }
    },
  }
</script>
