<template>
  <Timecard/>
</template>
<script>
  import Timecard from '@/modules/payroll/components/timecard/Timecard'

  export default {
    components: {
      Timecard,
    },
  }
</script>
