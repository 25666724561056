<template>
  <EntityPurchaseOrders
    :url-params="urlParams"
  />
</template>
<script>
import EntityPurchaseOrders from "@/modules/purchasing-inventory/components/purchase-orders/EntityPurchaseOrders.vue";

export default {
  components: {
    EntityPurchaseOrders,
  },
  computed: {
    vendorId() {
      return this.$route.params.id
    },
    urlParams() {
      return {
        vendor_id: this.vendorId,
      }
    },
    currentVendor() {
      return this.$store.state.accountsPayable.currentVendor
    },
  },
  mounted() {
    this.vendorId = this.currentVendor.id
  }
}
</script>
