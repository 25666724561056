<template>
  <EquipmentSetting/>
</template>
<script>
  import EquipmentSetting from '@/modules/equipment/components/EquipmentSetting'

  export default {
    components: {
      EquipmentSetting,
    },
  }
</script>
