<template>
  <div>
    <CustomerLink
        :id="customerId"
        :show-name="true"
        linkClass="text-gray-600 hover:text-gray-800"
    />
  </div>
</template>
<script>
  export default {
    computed: {
      data() {
        return this.params.node?.allLeafChildren[0]?.data || {}
      },
      customerId() {
        return this.get(this.data, 'attributes.customer_id')
      },
    },
  }
</script>
