<template>
  <span>
    {{ getValue }}
  </span>
</template>
<script>
  import get from 'lodash/get'

  export default {
    props: {
      column: {
        type: Object,
        default: () => ({}),
      },
      row: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      getValue() {
        const value = parseFloat(get(this.row, this.column.prop, 0))
        return value.toFixed(2)
      }
    }
  }
</script>
