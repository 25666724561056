<template>
  <base-tooltip class="cursor-help"
                :content="$t(dictionary)">
    <el-progress v-bind="$attrs"
                 :show-text="showText"
                 :color="colors"
                 :stroke-width="strokeWidth"
                 :percentage="computedPercentage"
    />
  </base-tooltip>
</template>
<script>
  import {Progress} from 'element-ui'

  export default {
    components: {
      [Progress.name]: Progress,
    },
    props: {
      toCompare: {
        type: Number || String,
        default: 0,
      },
      value: {
        type: Number || String,
        default: 0,
      },
      dictionary: {
        type: String,
        default: '',
      },
      showText: {
        type: Boolean,
        default: true,
      },
      strokeWidth: {
        type: Number,
        default: 10,
      }
    },
    data() {
      return {
        colors: [
          {color: '#f56c6c', percentage: 100},
          {color: '#e6a23c', percentage: 80},
          {color: '#5cb87a', percentage: 60},
          {color: '#1989fa', percentage: 40},
          {color: '#6f7ad3', percentage: 20}
        ]
      }
    },
    computed: {
      computedPercentage() {
        const valueToFloat = parseFloat(this.value)
        const totalToFloat = parseFloat(this.toCompare)
        return valueToFloat ? (valueToFloat * 100 / totalToFloat).toFixed(2) : 0
      }
    }
  }
</script>
