<template>
  <div class="truncate">
    <router-link
        v-if="timeOffPolicyId"
        :to="`/payroll/codes/time-off/${timeOffPolicyId}/view`"
        :class="linkClass"
    >
      <span>
        {{ valueToDisplay }}
      </span>
    </router-link>
    <template v-else>
      --
    </template>
  </div>
</template>
<script>
  export default {
    name: 'TimeOffPolicyLink',
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      id: {
        type: String,
      },
      showDescription: {
        type: Boolean,
        default: true,
      },
      linkClass: String,
    },
    computed: {
      timeOffPolicyId() {
        return this.id || this.get(this.data, 'attributes.source_id')
      },
      timeOffPolicies() {
        return this.$store.getters['globalLists/getResourceList'](this.$globalResources.TimeOffPolicies)
      },
      policy() {
        return this.timeOffPolicies.find(a => a.id === this.timeOffPolicyId) || {}
      },
      valueToDisplay() {
        const { code, description } = this.policy

        if (!this.showDescription) {
          return code
        }
        return `${code} (${description})`
      },
    },
  }
</script>
