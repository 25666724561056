<template>
  <base-data-table
      v-bind="defaultAttributes"
      :columns="columns"
      :data="getData"
      :total-rows="rowsLength"
      :reportNumber="reportNumber"
      @force-pagination="forcePagination"
  >

    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template #header="{row}">
      <div>
        <EmployeeLink
            :data="row.header"
            :show-preview="false"
        />
      </div>
    </template>

    <template #subtotal="{subtotal}">
      <td/>
      <td align="right">
        <div class="flex">
          {{ subtotal.masked_ssn }}
          <dl class="ml-4">
            <dt>{{ $t('QTD:') }}</dt>
            <dt>{{ $t('YTD:') }}</dt>
          </dl>
        </div>
      </td>
      <td
          v-for="column in amountColumns"
          :key="column.prop"
          align="right"
      >
        <dt>{{ $formatPrice(subtotal.qtd?.[column.prop]) }}</dt>
        <dt>{{ $formatPrice(subtotal.ytd?.[column.prop]) }}</dt>
      </td>
      <td align="right">
        <dt>{{ subtotal.qtd?.weeks }}</dt>
        <dt>{{ subtotal.ytd?.weeks }}</dt>
      </td>
    </template>

    <template #masked_ssn_summary>
      <div class="flex mt-4">
        {{ $t('Grand Totals:') }}
        <dl class="ml-4">
          <dt>{{ $t('QTD:') }}</dt>
          <dt class="mt-4">{{ $t('YTD:') }}</dt>
        </dl>
      </div>
      <div v-if="localQuarterly">&nbsp;</div>
      <div
          v-else
          class="mt-4"
      >
        {{ $t('Employees Working on 12th Day of Month for this Quarter:') }}
        <span class="mx-10">
          <span class="mx-4">{{ $t('1st Month:') }} </span>
          {{ workedDay12s.day12_month_1 }}
        </span>
        <span>
          <span class="mx-4">{{ $t('2nd Month:') }} </span>
          {{ workedDay12s.day12_month_2 }}
        </span>
        <span class="mx-10">
          <span class="mx-4">{{ $t('3rd Month:') }} </span>
          {{ workedDay12s.day12_month_3 }}
        </span>
      </div>
    </template>

    <template v-for="column in amountColumns" #[getSummarySlotName(column)]>
      <dl>
        <dt>{{ $formatPrice(data.grand_totals.qtd?.[column.prop]) }}</dt>
        <dt class="mt-4">{{ $formatPrice(data.grand_totals.ytd?.[column.prop]) }}</dt>
      </dl>
      <div>&nbsp;</div>
    </template>

    <template #weeks_summary>
      <dl>
        <dt>{{ data.grand_totals.qtd?.weeks }}</dt>
        <dt class="mt-4">{{ data.grand_totals.ytd?.weeks }}</dt>
      </dl>
      <div>&nbsp;</div>
    </template>

  </base-data-table>
</template>
<script lang="ts">
  import {defineComponent} from 'vue'
  import {Column} from '@/components/ag-grid/tableTypes'
  import {earningTypes} from '@/modules/payroll/components/reports/util.js'
  import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper.vue'

  export default defineComponent({
    extends: ReportTableWrapper,
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      reportNumber: {
        type: [Number, String],
        default: 4882,
      },
    },
    computed: {
      columns(): Column[] {
        const {options} = this.data

        const suiRate = this.$formatPercent(options?.sui_rate)
        const taxComputedLabel = `SUI Tax <br> Computed <br> ${suiRate}`

        const suiLimit = this.$formatPrice(options?.sui_limit)
        const taxableToLabel = `SUI <br> Taxable To <br> ${suiLimit}`

        const columns = [
          {
            label: this.$t('Employee'),
            prop: 'employee',
            hiddenValue: true,
            minWidth: 100,
            maxWidth: 100,
          },
          {
            label: this.$t('Soc Sec #'),
            prop: 'masked_ssn',
            minWidth: 120,
            maxWidth: 120,
          },
          {
            label: this.$t('SIT <br> Earnings'),
            prop: 'sit_earnings',
            component: 'FormattedPrice',
            align: 'right',
            renderIf: () => !this.localQuarterly
          },
          {
            label: this.$t('Local <br> Earnings'),
            prop: 'lit_earnings',
            component: 'FormattedPrice',
            align: 'right',
            renderIf: () => this.localQuarterly
          },
          {
            label: this.$t('Local Tax<br> Employee'),
            prop: 'lit_employee',
            component: 'FormattedPrice',
            align: 'right',
            renderIf: () => this.localQuarterly
          },
          {
            label: this.$t('Local Tax<br> Employer'),
            prop: 'lit_employer',
            component: 'FormattedPrice',
            align: 'right',
            renderIf: () => this.localQuarterly
          },
          {
            label: this.$t('SIT Tax <br> W/Held'),
            prop: 'sit',
            component: 'FormattedPrice',
            align: 'right',
            renderIf: () => !this.localQuarterly
          },
          {
            label: this.$t('SDI Earn <br> Taxable To'),
            prop: 'sdi_earnings',
            component: 'FormattedPrice',
            align: 'right',
            renderIf: () => !this.localQuarterly
          },
          {
            label: this.$t('SDI Tax <br> W/Held'),
            prop: 'sdi',
            component: 'FormattedPrice',
            align: 'right',
            renderIf: () => !this.localQuarterly
          },
          {
            label: this.$t('SUI  <br> Total'),
            prop: 'calculated_sui',
            component: 'FormattedPrice',
            align: 'right',
            renderIf: () => !this.localQuarterly
          },
          {
            label: taxableToLabel,
            prop: 'sui_earnings',
            component: 'FormattedPrice',
            align: 'right',
            renderIf: () => !this.localQuarterly
          },
          {
            label: taxComputedLabel,
            prop: 'sui',
            component: 'FormattedPrice',
            align: 'right',
            renderIf: () => !this.localQuarterly
          },
          {
            label: this.$t('Weeks <br> Worked'),
            prop: 'weeks',
            align: 'right',
          },
        ]

        // @ts-ignore
        return columns.filter(column => column.renderIf ? column.renderIf() : true)
      },
      localQuarterly(): boolean {
        return this.reportNumber === '4882a'
      },
      workedDay12s(): Record<string, any> {
        // @ts-ignore
        return this.get(this.data, 'grand_totals.worked_day12s', {})
      },
    },
    methods: {
      composeRows(data: Record<string, any>) {
        data.forEach((group: Record<string, any>) => {
          const {employee} = group

          // @ts-ignore
          this.rows.push({
            header: employee,
          })

          group['masked_ssn'] = employee.masked_ssn

          // @ts-ignore
          this.rows.push({
            subtotal: group,
          })
        })

        return this.rows
      },
    },
  })
</script>
