<template>
  <div class="truncate">
    <router-link
        v-if="data?.id && !editableCell"
        :to="`/payroll/codes/department-codes?id=${data.id}`"
        :class="$attrs.class"
        :target="target"
    >
      <span>
        {{ valueToDisplay }}
      </span>
    </router-link>
    <template v-else>
	    {{ valueToDisplay }}
    </template>
  </div>
</template>
<script lang="ts" setup>
  import { computed } from 'vue'
  import get from 'lodash/get'
  import store from '@/store'
  import { globalResources } from "@/components/form/util";
  import { Department} from "@/modules/common/types/models";

  const props = defineProps({
    data: {
      type: Object,
      default: () => ({}),
    },
    id: {
      type: String,
    },
    showDescription: {
      type: Boolean,
      default: true,
    },
    params: {
      type: Object,
      default: () => ({}),
    },
	  target: String,
	  editableCell: Boolean,
  })

  const departmentId = computed(() => {
    if (props.params?.value) {
       return props.params?.value
    }

    return props.id || get(props.data, 'attributes.department_id')
  })

  const options = computed(() => {
    return store.getters['globalLists/getResourceList'](globalResources.DepartmentCodes)
  })

  const data = computed(() => {
    return options.value.find((a: Department) => a.id === departmentId.value) || {}
  })

  const valueToDisplay = computed(() => {
    let { code, description } = props.params?.data || {}
    code = code || ''
    description = description || ''
    if (description) {
      description = `(${description})`
    }
		if (!departmentId.value) {
			return `${code} ${description}`
		}

    code = data.value?.code || code || ''
    description = data.value?.description || code || ''
    if (description) {
      description = `(${description})`
    }
    if (!props.showDescription || !description) {
      return `${code} ${description}`
    }

    return `${code} ${description}`
  })
</script>
