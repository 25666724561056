import store from "@/store";
import { globalResources } from "@/components/form/util";
import { codeLabels, codeTypes } from "@/modules/payroll/components/rates/util";
import { costCenterJobTypes, costCenterTypes } from "@/components/grid-table/utils/cost-center";
import { sourceTypes } from "@/modules/service-billing/util/service-billing";
import { composeLineItemName } from "@/utils/utils";

const sourceMap = {
  [costCenterTypes.Job]: globalResources.Jobs,
  [costCenterTypes.Equipment]: globalResources.Equipments,
  [costCenterTypes.WorkOrder]: globalResources.WorkOrders,
  [costCenterTypes.Inventory]: globalResources.Materials,
  [costCenterTypes.Material]: globalResources.Materials,
  [costCenterTypes.Labor]: globalResources.CraftCodes,
}

const sourceMapByType = {
  [sourceTypes.Subcontract]: '',
  [sourceTypes.Equipment]: globalResources.Equipments,
  [sourceTypes.Labor]: globalResources.CraftCodes,
  [sourceTypes.Material]: globalResources.Materials,
  default: '',
}

const additionalSourceMap = {
  [costCenterTypes.Job]: globalResources.LineItems,
  [costCenterTypes.Equipment]: globalResources.EquipmentMaintenances,
}

function specialSourceOptions(specialSourceType) {
  const globalLists = store.state.globalLists
  const specialSourceMap = {
    [codeTypes.PAY]: globalLists.withPayUnits.pays,
    [codeTypes.BEN]: globalLists.withPayUnits.benefits,
    [codeTypes.DEDUCTION]: globalLists.withPayUnits.deductions,
    [codeTypes.TAX]: globalLists.withPayUnits.taxes,
    [codeTypes.FLEX_BEN]: globalLists[globalResources.FlexibleBenefitPlans],
    [codeTypes.RETIREMENT_PLAN]: globalLists[globalResources.RetirementPlans],
    [codeTypes.TIME_OFF]: globalLists[globalResources.TimeOffPolicies],
    [codeTypes.EQUIPMENT]: globalLists[globalResources.Equipments],
    [codeTypes.MATERIAL]: globalLists[globalResources.Materials],
    [codeTypes.VENDOR]: globalLists[globalResources.Vendors],
    [codeTypes.CRAFT_CODE]: globalLists[globalResources.CraftCodes],
    [costCenterTypes.Equipment]: globalLists[globalResources.Equipments],
    [costCenterTypes.Material]: globalLists[globalResources.Materials],
    [costCenterTypes.Labor]: globalLists[globalResources.CraftCodes],
    default: [],
  }
  return specialSourceMap[specialSourceType] || specialSourceMap.default
}

function specialSourceName({ special_source_type, special_source_id }) {
  if (!special_source_type || !special_source_id) {
    return undefined
  }
  const options = specialSourceOptions(special_source_type)
  const option = options.find(o => o.id?.toString() === special_source_id?.toString()) || { code: '' }
  return option?.code
}

export function getSpecialSourceOption({ special_source_type, special_source_id }) {
  if (!special_source_type || !special_source_id) {
    return undefined
  }
  const options = specialSourceOptions(special_source_type)
  return options.find(o => o.id?.toString() === special_source_id?.toString()) || { code: '' }
}

function specialSourceUrl({ special_source_type, special_source_id }) {
  if (special_source_type === codeTypes.PAY) {
    return `/payroll/codes/pay-codes?id=${special_source_id}`
  } else if (special_source_type === codeTypes.DEDUCTION) {
    return `/payroll/codes/deduction-codes?id=${special_source_id}`
  } else if (special_source_type === codeTypes.BEN) {
    return `/payroll/codes/benefit-codes?id=${special_source_id}`
  } else if (special_source_type === codeTypes.TAX) {
    return `/payroll/codes/tax-codes`
  } else if (special_source_type === codeTypes.VENDOR) {
    return `/accounts-payable/vendors/${special_source_id}/view`
  } else if (special_source_type === codeTypes.EQUIPMENT) {
    return `/equipment/list/${special_source_id}/view`
  } else if (special_source_type === codeTypes.MATERIAL) {
    return `/purchasing-inventory/materials/${special_source_id}/view`
  }

  return ''
}

function specialSourceType(special_source_type) {
  return codeLabels[special_source_type] || ''
}

function sourceName({ cost_center, source_type, source_id, html, showDescription }) {
  let sourceName = sourceMap[cost_center]
  if (cost_center === undefined) {
    sourceName = sourceMapByType[source_type]
  }

  const hideDescription = showDescription === false

  if (!source_id || !sourceName) {
    return undefined
  }
  const {
    code,
    number,
    description,
  } = store.getters['globalLists/getResourceById'](sourceName, source_id)

  const mainField = code ?? number
  if (!description || hideDescription) {
    return mainField
  }
  if (html) {
    return `<span>${mainField} &nbsp;</span><span class="link-description">(${description})</span>`
  }
  return `${mainField} (${description})`
}

function sourceUrl({ cost_center, source_type, source_id }) {
  if (cost_center === costCenterTypes.Job || source_type === sourceTypes.Subcontract) {
    return `/job-costing/jobs/${source_id}/view`
  }

  if (cost_center === costCenterTypes.Equipment || source_type === sourceTypes.Equipment) {
    return `/equipment/list/${source_id}/view`
  }

  if (cost_center === costCenterTypes.WorkOrder) {
    return `/service-billing/work-orders/${source_id}/view`
  }

  if (cost_center === costCenterTypes.Inventory || source_type === sourceTypes.Material) {
    return `/purchasing-inventory/materials/${source_id}/view`
  }

  if (source_type === sourceTypes.Labor) {
    return `/payroll/codes/craft-codes?id=${source_id}`
  }
  return ''
}

function addlSourceUrl({ cost_center, addl_source_id, type }) {
  if (cost_center === costCenterTypes.Job) {
    const lineItem = store.getters['globalLists/getResourceById'](globalResources.LineItems, addl_source_id) || { type: 'cost' }
    type = lineItem?.type || type
    return `/job-costing/${type}-line-items/${addl_source_id}/view`
  } else if (cost_center === costCenterTypes.Equipment) {
    return `/equipment/maintenance-codes?id=${addl_source_id}`
  }
}

function addlSourceName({ cost_center, addl_source_id, showDescription }) {
  const sourceName = additionalSourceMap[cost_center]
  if (!addl_source_id || !sourceName) {
    return undefined
  }
  const hideDescription = showDescription === false

  const {
    code,
    number,
    description,
  } = store.getters['globalLists/getResourceById'](sourceName, addl_source_id)

  let mainField = code ?? number
  if (description && !hideDescription) {
    return `${mainField} (${description})`
  }
  return mainField || ''
}

export function getSourceName(row, html = false, showDescription = true) {
  const { cost_center, source_type, source_id } = row?.attributes || row

  if (cost_center === costCenterTypes.GeneralAndAdministrative) {
    return ''
  }

  return sourceName({ cost_center, source_type, source_id, html, showDescription })
}

export function getSourceLink(row) {
  const { cost_center, source_type, source_id } = row?.attributes || row
  return sourceUrl({ cost_center, source_type, source_id })
}

export function getSpecialSourceLink(row) {
  const { special_source_type, special_source_id } = row?.attributes || row
  return specialSourceUrl({ special_source_type, special_source_id })
}

export function getSpecialSourceType(row) {
  const { special_source_type } = row?.attributes || row
  return specialSourceType(special_source_type)
}

export function getSpecialSourceName(row) {
  const { special_source_type, special_source_id } = row?.attributes || row
  return specialSourceName({ special_source_type, special_source_id })
}

export function getAddlSourceName(row, addlSource, showDescription = true) {
  const { cost_center, addl_source_id } = row?.attributes || row

  // * To cover the case when line item job status is inactive
  if (cost_center === costCenterTypes.Job && addlSource?.id) {
    return composeLineItemName(addlSource, showDescription)
  }

  return addlSourceName({ cost_center, addl_source_id, showDescription })
}

export function getAddlSourceLink(row, additionalSource = {}) {
  const { cost_center, addl_source_id } = row?.attributes || row
  const type = additionalSource?.type
  return addlSourceUrl({ cost_center, addl_source_id, type })
}

export function getSourceType(costCenter) {
  const map = {
    [costCenterTypes.GeneralAndAdministrative]: sourceTypes.Optional,
    [costCenterTypes.Job]: sourceTypes.Subcontract,
    [costCenterTypes.WorkOrder]: sourceTypes.WorkOrder,
    [costCenterTypes.Equipment]: sourceTypes.Equipment,
  }
  return map[costCenter]
}

export function getAddlSourceType(costCenter) {
  const map = {
    [costCenterTypes.Job]: costCenterJobTypes.LineItem,
  }
  return map[costCenter] || null
}
