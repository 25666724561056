<template>
    <ServiceInvoiceForm
        v-if="serviceInvoice.id"
        :data="serviceInvoice"
        list-path="/accounts-receivable/billings/service/pending"
    />
</template>
<script>
  import ServiceInvoiceForm from '@/modules/service-billing/components/ServiceInvoiceForm'

  export default {
    components: {
      ServiceInvoiceForm,
    },
    computed: {
      serviceInvoice() {
        return this.$store.state.accountsReceivable.currentBilling
      },
    },
  }
</script>
