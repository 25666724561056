<template>
  <base-data-table
    v-bind="defaultAttributes"
    :columns="columns"
    :data="getData"
    :total-rows="rowsLength"
    :has-summary="false"
    @force-pagination="forcePagination"
  >

    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template #header="{row}">
      {{ $t('Union') }}:
      <router-link :to="`/payroll/rates/unions?id=${row.header.id}`">
        {{ row.header.code }}
        <span v-if="row.header.description">
          ({{ row.header.description }})
        </span>
      </router-link>
    </template>

    <template #job.number="{row}">
      <JobLink
        :data="row.job"
        :show-description="false"
        :show-preview="false"
      />
    </template>

    <template #subtotal="{subtotal}">
      <td colspan="3" align="right">
        {{ $t('Union Totals:') }}
      </td>
      <td align="right">{{ subtotal.hours?.REGPAY }}</td>
      <td align="right">{{ subtotal.hours?.OVTPAY }}</td>
      <td align="right">{{ subtotal.hours?.PRMPAY }}</td>
      <td align="right">{{ subtotal.hours?.total }}</td>
      <td align="right">{{ $formatPrice(subtotal.amount) }}</td>
    </template>

    <template #subtotals="{subtotals}">
        <tr v-for="subtotal in subtotals">
          <td colspan="2"/>
          <td class="space-x-2">
            {{ subtotal.special_source?.number }} -
            {{ subtotal.special_source?.description }}
            {{ subtotal.special_source?.type }}
          </td>
          <td align="right">{{ subtotal?.totals?.hours?.REGPAY }}</td>
          <td align="right">{{ subtotal?.totals?.hours?.OVTPAY }}</td>
          <td align="right">{{ subtotal?.totals?.hours?.PRMPAY }}</td>
          <td align="right">{{ subtotal?.totals?.hours?.total }}</td>
          <td align="right">{{ $formatPrice(subtotal?.totals?.amount) }}</td>
        </tr>
    </template>

  </base-data-table>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { Column } from '@/components/ag-grid/tableTypes'
import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper.vue'

export default defineComponent({
  extends: ReportTableWrapper,
  computed: {
    columns(): Column[] {
      return [
        {
          label: this.$t('Job'),
          prop: 'job.number',
          minWidth: 100,
          maxWidth: 120,
        },
        {
          label: this.$t('Description/Address'),
          prop: 'job.description',
          minWidth: 120,
          maxWidth: 220,
        },
        {
          label: this.$t('Other Pay/Deductions'),
          prop: 'special_source.type',
          minWidth: 120,
          maxWidth: 220,
        },
        {
          label: this.$t('Regular <br> Hours'),
          prop: 'totals.hours.REGPAY',
          minWidth: 100,
          maxWidth: 120,
          align: 'right',
          hideZero: true,
        },
        {
          label: this.$t('Overtime <br> Hours'),
          prop: 'totals.hours.OVTPAY',
          minWidth: 100,
          maxWidth: 120,
          align: 'right',
          hideZero: true,
        },
        {
          label: this.$t('Premium <br> Hours'),
          prop: 'totals.hours.PRMPAY',
          minWidth: 100,
          maxWidth: 120,
          align: 'right',
          hideZero: true,
        },
        {
          label: this.$t('Total <br> Hours/Units'),
          prop: 'totals.hours.total',
          minWidth: 100,
          maxWidth: 120,
          align: 'right',
          hideZero: true,
        },
        {
          label: this.$t('Total Pay/Ded <br> Amount'),
          prop: 'totals.amount',
          minWidth: 100,
          maxWidth: 120,
          component: 'FormattedPrice',
          align: 'right',
          hideZero: true,
        }
      ]
    },
    includePayCodeDetail(): boolean {
      return this.options?.include_paycode_detail
    },
  },
  methods: {
    composeRows(data: Record<string, any>): [ Record<string, any> ] {
      if (this.includePayCodeDetail) {
        return this.composeRowsWithPayCodeDetail(data)
      }
      data.forEach((group: Record<string, any>) => {
        const {union, jobs, totals} = group

        this.rows.push({
          header: union,
        })

        this.rows.push(...jobs)

        this.rows.push({
          subtotal: totals,
        })
      })
      return this.rows
    },
    composeRowsWithPayCodeDetail(data: Record<string, any>): [ Record<string, any> ] {
      data.forEach((group: Record<string, any>) => {
        const {union, jobs, totals, special_sources} = group
        this.rows.push({
          header: union,
        })
        jobs.forEach((jobObject: Record<string, any>) => {
          this.rows.push({
            ...jobObject,
          })
          jobObject?.special_sources.forEach((specialSourceObject: Record<string, any>) => {
            this.rows.push({
              ...specialSourceObject,
              job: {
                description: specialSourceObject?.special_source?.description,
              }
            })
          })
        })
        this.rows.push({
          subtotal: totals,
        })
        this.rows.push({
          subtotals: special_sources,
        })
      })
      return this.rows
    },
  },
})
</script>
