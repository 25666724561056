<template>
  <div>
    <layout-tabs :items="filteredItems" class="-mt-2"/>
    <router-view/>
  </div>
</template>
<script>

export default {
  data() {
    return {
      items: [
        {
          name: this.$t('Timesheets'),
          path: '/payroll/timesheets/list',
          permission: 'timesheets_approve'
        },
        {
          name: this.$t('Review Timesheets'),
          path: '/payroll/timesheets/review',
          permission: 'timesheets_approve'
        },
        {
          name: this.$t('Tasks'),
          path: '/payroll/timesheets/tasks',
          permission: 'tasks_store'
        },
        {
          name: this.$t('Calendar'),
          path: '/payroll/timesheets/calendar',
          permission: 'tasks_store'
        }
      ],
    }
  },
  computed: {
    filteredItems() {
      return this.items.filter(item => this.$can(item.permission))
    },
  },
}
</script>
