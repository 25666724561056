<template>
  <div class="import-data mt-4">
    <ImportForm
        v-bind="importSettings"
        @success="redirectToList"
    />
  </div>
</template>
<script>
  import ImportForm from '@/modules/common/components/import/ImportForm';
  import { getInitialMapping } from '@/modules/payroll/utils/timecardImportColumns';

  const colMappings = {
    Code: {
      value: 'code',
      label: 'Code',
      alternativeLabels: ['OCIP Code', 'OCIP'],
      required: true,
    },
    Description: {
      value: 'description',
      label: 'Description',
    },
  }

  const options = [
    colMappings.Code,
    colMappings.Description,
  ]

  export default {
    components: {
      ImportForm,
    },
    data() {
      return {
        importSettings: {
          url: '/restify/ocip-codes/import',
          columnOptions: options,
          columnMappings: colMappings,
          initialMapping: getInitialMapping(options),
          type: 'ocip-code-import',
          resource: 'ocip-codes',
        },
      }
    },
    methods: {
      async redirectToList() {
        await this.$router.push('/payroll/codes/ocip-codes')
      },
    },
  }
</script>
