<template>
  <div class="grid grid-cols-5 gap-x-2 print:text-print">
    <span class="tracking-wide font-medium text-sm print:text-print leading-normal text-gray-400 mr-2 mb-2">
      {{ title || $t('Billed To') }}
    </span>
    <div class="col-span-4 border-l border-gray-200 pl-2">
      <entity-preview
          v-if="showDetailsOnHover"
          :entity="company"
          :key="`preview-${company.id}`"
          :url="`/settings/companies/${company.id}/view`"
          :valueToDisplay="valueToDisplay"
          :parent-entity-id="company.id"
          entity-name="company"
          link-class="font-semibold tracking-wider text-base print:text-print text-gray-900"
      />
      <div v-else class="font-semibold tracking-wider text-base print:text-print text-gray-900">
        {{ valueToDisplay }}
      </div>
      <div class="leading-tight tracking-wide space-y-2 text-sm print:text-print mt-2">
        <div v-if="companyAddress">
          <base-address-link :value="companyAddress"/>
        </div>
        <div>
          {{ company.abbr || $t('No short name provided') }}
        </div>
        <div>
          <base-email-link :value="company.email"/>
        </div>
        <div>
          <base-phone-link :value="company.phone"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      company: {
        type: Object,
        default: () => ({}),
      },
      title: {
        type: String,
      },
      showDetailsOnHover: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      companyAddress() {
        const { addresses, address } = this.company

        if (address) {
          return address
        }

        if (!addresses || !addresses.length) {
          return ''
        }
        return addresses.find(address => address.type === 'billing') || addresses[0]
      },
      valueToDisplay() {
        return `${this.company.code || ''} ${this.company.name || ''}`
      }
    },
  }
</script>
