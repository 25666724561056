<template>
  <AgDataTable
      ref="table"
      url="/restify/time-off-statuses"
      :url-params="urlParams"
      :columns="columns"
      :add-text="$t('Time Off Statuses')"
      :masterDetail="true"
      :detailCellRenderer="detailCellRenderer"
      :detailRowAutoHeight="true"
      actions="search,refresh"
      class="h-full"
  >
    <template #policy="{row}">
      <router-link :to="`/payroll/codes/time-off/${row.attributes.time_off_policy_id}/view`">
        {{row.relationships?.timeOffPolicy?.attributes.code}} ({{row.relationships?.timeOffPolicy?.attributes.description}})
      </router-link>
    </template>
  </AgDataTable>
</template>
<script>
  import EmployeeTimeOffActivities from "@/modules/payroll/components/time-off/EmployeeTimeOffActivities.vue";

  export default {
    components: {
      EmployeeTimeOffActivities
    },
    data() {
      return {
        detailCellRenderer: 'EmployeeTimeOffActivities',
      }
    },
    computed: {
      urlParams() {
        return {
          employee_id: this.$route.params.id,
          related: 'timeOffPolicy',
        }
      },
      columns() {
        return [
          {
            field: 'id',
            headerName: ' ',
            cellRenderer: 'agGroupCellRenderer',
            minWidth: 55,
            maxWidth: 55,
            valueGetter: () => '',
          },
          {
            label: this.$t('Policy'),
            minWidth: 150,
            prop: 'policy',
          },
          {
            label: this.$t('Carry over (previous year)'),
            minWidth: 120,
            prop: 'attributes.previous_year_carry_over_hours',
            valueFormatter: (params) => {
              return `${params.value} ${this.$t('hours')}`
            },
          },
          {
            label: this.$t('Accrued (this year)'),
            minWidth: 120,
            prop: 'attributes.current_year_accrued_hours',
            valueFormatter: (params) => {
              return `${params.value} ${this.$t('hours')}`
            },
          },
          {
            label: this.$t('Used'),
            minWidth: 120,
            prop: 'attributes.current_year_used_hours',
            valueFormatter: (params) => {
              return `${params.value} ${this.$t('hours')}`
            },
          },
          {
            label: this.$t('Available'),
            prop: 'attributes.balance',
            minWidth: 120,
            valueFormatter: (params) => {
              return `${params.value} ${this.$t('hours')}`
            },
          },
        ]
      }
    }
  }
</script>
