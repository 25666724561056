<template>
  <base-data-table
      v-bind="defaultAttributes"
      :columns="columns"
      :data="rows"
      :total-rows="rowsLength"
      :has-summary="false"
      @force-pagination="forcePagination"
  >

    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template #restrictions="{row}">
      <div class="truncate">
        {{ $t(row.restrictions) }}
      </div>
    </template>

  </base-data-table>
</template>
<script>
  import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'

  export default {
    extends: ReportTableWrapper,
    computed: {
      columns() {
        return [
          {
            label: this.$t('Account'),
            prop: 'number',
            minWidth: 60,
            maxWidth: 80,
            component: 'AccountLink',
            params: {
              showDescription: false,
            }
          },
          {
            label: this.$t('Description'),
            prop: 'description',
            minWidth: 120,
            maxWidth: 200,
          },
          {
            label: this.$t('Type'),
            prop: 'type',
            minWidth: 60,
            maxWidth: 100,
            component: 'Status',
          },
          {
            label: this.$t('Restrictions On Use'),
            prop: 'restrictions',
          },
        ]
      },
    },
    methods: {
      composeRows(data) {
        this.rows = data
      },
    },
  }
</script>
