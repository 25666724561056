<template>
  <router-link v-if="row && link"
               :to="link"
               title="View"
               tabindex="-1"
               class="table-action-button flex"
  >
    <base-button variant="gray-light"
                 :tabindex="skipFocus ? -1 : 0"
                 size="icon"
    >
      <EyeIcon class="w-4 h-4"/>
    </base-button>
  </router-link>
  <base-button
    v-else
    :tabindex="skipFocus ? -1 : 0"
    :loading="loading"
    variant="gray-light"
    title="View"
    size="icon"
    class="table-action-button"
    @click="$emit('click')"
  >
    <EyeIcon class="w-4 h-4"/>
  </base-button>
</template>
<script>
import { EyeIcon } from 'vue-feather-icons'

export default {
  name: 'TableViewButton',
  components: {
    EyeIcon,
  },
  props: {
    row: {
      type: Object,
      default: () => ({})
    },
    link: {
      type: String,
    },
    skipFocus: {
      type: Boolean,
      default: false,
    },
    loading: Boolean,
  }
}
</script>
