<template>
    <div>
        <div class="border-4 border-dashed border-gray-200 rounded-lg h-96 flex items-center justify-center">
            <h2 class="text-xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate">
                Coming soon...
            </h2>
        </div>
        <router-view/>
    </div>
</template>
<script>
  export default {}
</script>
<style>
</style>
