<template>
  <div>
    <base-form
      :loading="loading"
      :save-text="$t('Create direct deposit')"
      :update-text="$t('Update direct deposit')"
      layout="vertical"
      grid-classes="grid grid-cols-6 gap-x-3"
      @submit="onSubmit"
    >
      <employee-select
        v-model="model.id"
        :disabled="!!employeeId"
        :initial-value="selectedEmployee"
        :key="selectedEmployee.id"
        :tip="$t('Setup Direct Deposit information for applicable employees who request paychecks to be directly deposited to their checking and/or savings accounts.')"
        class="col-span-6 md:col-span-2"
        id="employee"
        rules="required"
        @entity-change="onChangeEmployee"
      />
      <div class="col-span-6 md:col-span-1">
        <base-input
          v-model="employee.paper_check_min_pay_amount"
          :label="$t('Minimum Paper Check Amount')"
          :tip="$t('Enter the minimum amount to be paid by paper check.')"
          type="number"
          format="price"
        />
      </div>
      <div class="col-span-6 md:col-span-1">
        <base-input
          v-model="employee.identification_code"
          :label="$t('Identification Code')"
          :tip="$t('Employee identification code #.')"
        />
      </div>
      <base-alert v-if="hasPendingPayrolls" class="col-span-6 mb-2" type="warning">
        {{ $t(`${selectedEmployee?.attributes?.name} is part of a computed payroll. If you want to make any changes here, make sure to re-compute the payroll.`) }}
      </base-alert>
      <DirectDepositEntries
        v-show="model.id"
        :employee-id="model.id"
        ref="entriesTable"
        class="col-span-6"
      />
      <template #extra-buttons-right>
        <base-button class="mr-2" variant="primary-light" @click="onPreNoticeClick">
          {{ $t('Generate Pre-Notice File') }}
        </base-button>
      </template>
    </base-form>
    <DirectDepositReportDialog
      v-if="showPreNoticeDialog"
      :key="preNoticeRenderKey"
      :open.sync="showPreNoticeDialog"
      :employees="[selectedEmployee?.id || $route.params.id]"
      :preNoticeFormat="true"
    />
  </div>
</template>
<script>
  import axios from 'axios'
  import { validateAgDataTable } from '@/components/ag-grid/tableUtils'
  import DirectDepositEntries from '@/modules/payroll/components/direct-deposit/DirectDepositEntries'
  import DirectDepositReportDialog
    from "@/modules/payroll/components/reports/direct-deposit/DirectDepositReportDialog.vue";

  export default {
    components: {
      DirectDepositReportDialog,
      DirectDepositEntries,
    },
    props: {
      hideEmployeeSelection: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        loading: false,
        showPreNoticeDialog: false,
        preNoticeRenderKey: 0,
        employeeId: this.$route.params?.id,
        model: {
          id: '',
        },
        // * Selected employee data stored to compare with the form data and update employee data if changed
        selectedEmployee: {},
        employee: {},
        hasPendingPayrolls: false,
      }
    },
    computed: {
      employeeDataChanged() {
        const { paper_check_min_pay_amount, identification_code } = this.selectedEmployee?.attributes || {}
        return paper_check_min_pay_amount !== this.employee.paper_check_min_pay_amount
          || identification_code !== this.employee.identification_code
      },
    },
    methods: {
      onPreNoticeClick() {
        this.preNoticeRenderKey++
        this.showPreNoticeDialog = true
      },
      async generateIdentificationCode() {
        const { code, ssn } = this.employee
        let paddedCode = code.padEnd(11, '0')
        const last4Ssn = ssn.slice(-4) || ''
        this.employee.identification_code = `${paddedCode}${last4Ssn}`
        await axios.patch(`/restify/employees/${this.model.id}`, {
          identification_code: this.employee.identification_code
        })
      },
      async getEmployee(id) {
        try {
          this.loading = true
          const { data } = await axios.get(`/restify/employees/${id}`)
          this.selectedEmployee = data
          this.employee = {
            ...data.attributes,
          }
          if (!this.employee.identification_code) {
            await this.generateIdentificationCode()
          }
          await this.checkForPendingPayrolls()
        } catch (err) {
          console.warn(err)
        } finally {
          this.loading = false
        }
      },
      async onSubmit() {
        try {
          const isInvalidData = await validateAgDataTable()
          if (isInvalidData) {
            return
          }

          this.loading = true
          await this.$refs.entriesTable.storeProgress(this.model.id)

          if (!this.employeeId) {
            await this.$router.push(`/payroll/direct-deposits/${this.model.id}/edit`)
          }

          this.$refs.entriesTable.refresh()

          this.$success(this.$t('Employee direct deposits set up successfully.'))

          if (!this.employeeDataChanged) {
            return
          }

          await axios.put(`/restify/employees/${this.model.id}`, this.employee)
        } catch (err) {
          console.warn(err)
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could set up employee direct deposits.'))
        } finally {
          this.loading = false
        }
      },
      onChangeEmployee(employee) {
        this.selectedEmployee = employee
        this.employee = {
          ...employee.attributes,
        }
      },
      async checkForPendingPayrolls() {
        const { data } = await axios.post(`/restify/employees/${this.employee.id}/actions?action=check-for-pending-payrolls`)
        const { has_pending_payrolls } = data
        this.hasPendingPayrolls = has_pending_payrolls
      }
    },
    watch: {
      '$route.params': {
        immediate: true,
        handler(params) {
          const {id} = params
          if (!id) {
            return
          }

          this.model.id = id
          this.getEmployee(id)
        },
      },
    },
  }
</script>
