<template>
  <base-form
      :loading="loading"
      :show-cancel="showCancel"
      :show-back="showBack"
      :save-text="$t('Create job category')"
      :update-text="$t('Update job category')"
      layout="vertical"
      @cancel="$router.push('/job-costing/settings/categories')"
      @submit="onSubmit"
  >
    <base-input
        v-model="model.code"
        :label="$t('Code')"
        :placeholder="$t('Category Code')"
        :disabled="!!model.id"
        class="col-span-3 md:col-span-1"
        rules="required|code"
    />
    <base-input
        v-model="model.description"
        :disabled="readonly"
        :label="$t('Description')"
        :placeholder="$t('Description')"
        class="col-span-3 md:col-span-2"
        rules="max:150"
    />
    <base-select
        v-model="model.code_edit_option"
        :disabled="readonly"
        :label="$t('Line Items Edit Option')"
        :placeholder="$t('Line Items Edit Option')"
        :options="phaseCodeTypes"
        class="col-span-3 md:col-span-2"
    />
    <div class="col-span-6 md:col-span-1">
      <base-input
          v-model="model.burden_rate"
          :disabled="readonly"
          :name="$t('Rate')"
          :label="$t('Rate')"
          :rules="getRateValidationRules"
          :format="getRateFormat"
          :min="0"
          :step="0.01"
          type="number"
      >
        <template v-slot:suffix>
          <select v-model="model.burden_rate_type"
                  class="form-select h-full py-0 -mr-1 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm sm:leading-5">
            <option v-for="option in rateTypes"
                    :value="option.value"
                    :key="option.value">
              {{ option.label }}
            </option>
          </select>
        </template>
      </base-input>
    </div>
    <base-select
        v-model="model.burden_calc"
        :label="$t('Burden Calculation Method')"
        :placeholder="$t('Burden Calculation Method')"
        :disabled="readonly"
        :options="burdenCalcMethods"
        class="col-span-3 md:col-span-2"
        id="burden_calc"
    />
    <div class="col-span-6">
      <el-collapse v-model="activeGroup">
        <base-collapse-section :title="$t('G/L Account Assignments (optional). They will Override J/C & P/R setup.')"
                               key="assign-accounts"
                               name="assign-accounts">
          <AccountAssignments
              :get-types-url="getTypesUrl"
              :add-types-url="addTypesUrl"
              :data="data"
              :module="modules.JobCosting"
              ref="accountAssignmentsTable"
              class="flex-1"
          />
          <PayrollExpenseAccounts
              :data="model"
              :module-prefix="getModulePrefix"
              class="flex-1"
              ref="payrollExpenseTable"
          />
        </base-collapse-section>
      </el-collapse>
    </div>
  </base-form>
</template>
<script>
  import axios from 'axios'
  import {
    burdenCalculationMethodOptions,
    codeEditOptions,
    rateTypeOptions,
    rateTypes,
  } from '@/modules/job-costing/enum/jobCategories'
  import { expenseAccountTypes, modulePrefixes, modules } from '@/modules/common/components/settings/util'
  import AccountAssignments from '@/modules/common/components/settings/AccountAssignments'
  import PayrollExpenseAccounts from '@/modules/common/components/settings/PayrollExpenseAccounts'

  export default {
    components: {
      AccountAssignments,
      PayrollExpenseAccounts,
    },
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      showBack: {
        type: Boolean,
        default: true,
      },
      redirectOnCreate: {
        type: Boolean,
        default: false,
      },
      readonly: Boolean,
    },
    data() {
      const expenseAccounts = expenseAccountTypes(modules.JobCosting)
      return {
        modules,
        activeGroup: 'assign-accounts',
        getTypesUrl: '/restify/job-types',
        addTypesUrl: '/restify/job-categories',
        loading: false,
        showCancel: true,
        model: {
          burden_calc_override: false,
          burden_calc: burdenCalculationMethodOptions[0].value,
          burden_rate: 0,
          burden_rate_type: rateTypeOptions[0].value,
          code_edit_option: codeEditOptions[0].value,
          code: '',
          description: '',

          estimated_burden_job_expense_account: null,
          estimated_burden_job_expense_subaccount: null,
          estimated_burden_job_liability_account: null,
          estimated_burden_job_liability_subaccount: null,

          ...expenseAccounts,
        },
        phaseCodeTypes: codeEditOptions,
        burdenCalcMethods: burdenCalculationMethodOptions,
        rateTypes: rateTypeOptions,
        activeTab: this.$t('Cost Accounts'),
        tabs: [
          {
            name: this.$t('Cost Accounts'),
          },
          {
            name: this.$t('Income Accounts'),
          },
        ],
      }
    },
    computed: {
      getRateValidationRules() {
        return this.model.burden_rate_type === rateTypes.Percent ? 'max_value:100|min_value:0' : 'max_value:9999999999.99|min_value:0'
      },
      getRateFormat() {
        return this.model.burden_rate_type === rateTypes.Percent ? 'percent' : 'price'
      },
      getModulePrefix() {
        return modulePrefixes[modules.JobCosting]
      },
    },
    methods: {
      async onSubmit() {
        try {
          this.loading = true
          this.$refs.payrollExpenseTable?.updatePayrollExpenseAccounts()
          if (this.model.id) {
            const { data } = await axios.put(`/restify/job-categories/${this.model.id}`, this.model)
            this.$success(this.$t('Job category updated'))
            await this.assignCostTypes(this.model.id)
            this.$emit('save', data)
          } else {
            const { data } = await axios.post('/restify/job-categories', this.model)
            this.$success(this.$t('Job category created'))
            await this.assignCostTypes(data.id)

            if (this.redirectOnCreate) {
              await this.$router.push(`/job-costing/settings/categories/${data.id}/edit`)
            }
            this.$emit('save', data)
          }
        } catch (err) {
          console.warn(err)
          if (err.handled) {
            return
          }
          this.$error(this.$t('Something went wrong. Please try again.'))
        } finally {
          this.loading = false
        }
      },
      async assignCostTypes(categoryId) {
        await this.$refs.accountAssignmentsTable.assignTypes(categoryId)
      },
    },
    watch: {
      'data.attributes': {
        immediate: true,
        handler(value) {
          if (!value?.id) {
            return
          }
          this.model = {
            ...this.model,
            ...value,
          }
          this.model.burden_rate = this.round(this.model.burden_rate)
        },
      },
    },
  }
</script>
