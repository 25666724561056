<template>

  <base-report-form
      :filters="model"
      :report-number="reportNumber"
  >
    <template #filters>

      <base-filter-row :title="$t('Format')">
        <base-select
            v-model="model.format"
            :options="formatOptions"
            class="col-span-3"
            rules="required"
        />
      </base-filter-row>

      <base-filter-row>
        <base-checkbox
            v-model="model.show_vendor_name"
            :label="$t('Print Vendor Name Instead of Code')"
            class="col-span-3 mb-3"
            id="show_vendor_name"
        />

        <base-checkbox
            v-model="model.show_employee_name"
            :label="$t('Print Employee Name Instead of Code')"
            class="col-span-3 mb-3"
            id="show_employee_name"
        />
      </base-filter-row>

      <period-year-range-filter v-model="model"/>

      <base-filter-row :title="$t('Sort By')">
        <base-select
            v-model="model.sort_by"
            :options="checkDepositRegisterSortByOptions"
            class="col-span-2"
            rules="required"
        />
      </base-filter-row>

      <base-filter-row :title="$t('Status')">
        <base-select
            v-model="model.status"
            :options="statusOptions"
            class="col-span-3"
            rules="required"

        />

        <base-checkbox
            v-model="model.include_period_0"
            :label="$t('Include Period 0')"
            class="col-span-3"
            id="include_period_0"
        />
      </base-filter-row>

      <base-filter-row>
        <base-checkbox
            v-model="selectAccountList"
            :label="$t('Select a List of Accounts')"
            class="col-span-3 mb-3"
            id="select_account_list"
            @change="resetAccountFilters"
        />
      </base-filter-row>

      <base-filter-row :title="$t('Accounts')">
        <account-select
            v-if="selectAccountList"
            v-model="model.account_ids"
            :placeholder="$t('Select Accounts')"
            class="col-span-4"
            collapse-tags
            multiple
        />

        <account-range-filter
            v-else
            v-model="model"
        />

      </base-filter-row>
    </template>

    <template #table="{data, loading}">

      <check-deposit-register-report-table
          :data="data"
          :filters="model"
          :report-number="reportNumber"
          :data-loading="loading"
          :sortBy="model.sort_by"
          ref="reportTable"
      />

    </template>

  </base-report-form>

</template>
<script lang="ts">
  import { defineComponent  } from 'vue'
  import { transactionStatuses } from '@/enum/enums'
  import { checkDepositRegisterSortByTypes } from '@/modules/ledger/components/reports/util.js'
  import { AccountRangeFilter, PeriodYearRangeFilter , periodYearFilterKeys } from '@/components/range-filters'
  import CheckDepositRegisterReportTable from '@/modules/ledger/components/reports/CheckDepositRegisterReportTable.vue'

  const formats = {
    Register: 'register',
    PostedCashReconciliation: 'posted-cash-reconciliation',
  }

  export default defineComponent({
    components: {
      AccountRangeFilter,
      PeriodYearRangeFilter,
      CheckDepositRegisterReportTable,
    },
    data() {
      return {
        reportNumber: 1877,
        selectAccountList: false,
        model:  <GL_REPORTS.CheckDepositRegister> {
          format: formats.Register,
          sort_by: checkDepositRegisterSortByTypes.ReferenceDate,
          account_ids: [],
          account_number_from: null,
          account_number_to: null,

          ...periodYearFilterKeys,

          status: transactionStatuses.Outstanding,
          include_period_0: false,
          show_employee_name: false,
          show_vendor_name: false,
        },
        checkDepositRegisterSortByOptions: [
          {
            label: this.$t('Reference Date'),
            value: checkDepositRegisterSortByTypes.ReferenceDate,
          },
          {
            label: this.$t('Reference Number'),
            value: checkDepositRegisterSortByTypes.ReferenceNumber,
          },
          {
            label: this.$t('F/Period, Ref Date'),
            value: checkDepositRegisterSortByTypes['F/Period Ref Date'],
          },
          {
            label: this.$t('F/Period, Ref Number'),
            value: checkDepositRegisterSortByTypes['F/Period Ref Number'],
          }
        ],
        statusOptions: [
          {
            label: this.$t('Outstanding'),
            value: transactionStatuses.Outstanding,
          },
          {
            label: this.$t('Reconciled'),
            value: transactionStatuses.Reconciled,
          },
          {
            label: this.$t('Variance'),
            value: transactionStatuses.Variance,
          },
        ],
        formatOptions: [
          {
            label: this.$t('Register'),
            value: formats.Register,
          },
          {
            label: this.$t('Posted Cash Reconciliation'),
            value: formats.PostedCashReconciliation,
          }
        ],
      }
    },
    methods: {
      resetAccountFilters() {
        this.model.account_ids = []
        this.model.account_number_from = this.model.account_number_to = null
      },
    },
  })
</script>
