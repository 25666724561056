import { w9FileOptions } from "@/enum/enums";
import { stateList } from "@/enum/state";
import { assetTypeOptions, dispositionOptions, intervalTypeOptions } from "@/enum/equipment";
import { globalResources } from "@/components/form/util";
import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";

const accountColumnProps = {
  cellEditor: cellEditors.AccountSelect,
  cellEditorParams: {
    valueKey: 'number',
    allowCreate: true,
  },
}
const subAccountColumnProps = {
  cellEditor: cellEditors.SubAccountSelect,
  cellEditorParams: {
    valueKey: 'number',
  },
}

export const extraColumns = {
  account: accountColumnProps,
  'special account': accountColumnProps,
  'disposal account': accountColumnProps,
  'depreciation account': accountColumnProps,

  'sub account': subAccountColumnProps,
  'special sub account': subAccountColumnProps,
  'disposal sub account': subAccountColumnProps,
  'depreciation sub account': subAccountColumnProps,

  date: {
    type: 'date',
  },
  'sales tax percent': {
    type: 'percent',
    cellClass: 'percent-cell',
    cellEditor: cellEditors.Numeric,
  },
  craft: {
    cellEditor: cellEditors.GlobalResourceSelect,
    cellEditorParams: {
      resourceName: globalResources.CraftCodes,
    },
  },
  'form w-9': {
    cellEditor: cellEditors.BaseSelect,
    cellEditorParams: {
      options: w9FileOptions,
    },
  },
  'asset type': {
    cellEditor: cellEditors.BaseSelect,
    cellEditorParams: {
      options: assetTypeOptions,
    },
  },
  disposition: {
    cellEditor: cellEditors.BaseSelect,
    cellEditorParams: {
      options: dispositionOptions,
    },
  },
  interval: {
    cellEditor: cellEditors.BaseSelect,
    cellEditorParams: {
      options: intervalTypeOptions,
    },
  },
  state: {
    cellEditor: cellEditors.BaseSelect,
    cellEditorParams: {
      options: stateList,
    },
    labelKey: 'description',
  },
}
