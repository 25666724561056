<template>
  <AccountsReceivableInitForm :data="data"/>
</template>
<script>
  import AccountsReceivableInitForm from '@/modules/accounts-receivable/components/settings/AccountsReceivableInitForm'
  export default {
    components: {
      AccountsReceivableInitForm,
    },
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
  }
</script>
