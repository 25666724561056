<template>
  <AgDataTable
    ref="table"
    v-if="filters"
    url="/restify/purchase-orders/subcontract-progress"
    :columns="columns"
    :url-params="filters"
    :transform-data="transformData"
    :groupDefaultExpanded="-1"
    :groupIncludeFooter="true"
    :groupRowRendererParams="groupRowRendererParams"
    groupDisplayType="groupRows"
    actions="search,refresh"
    @grid-ready="grid = $event"
    v-bind="editableTableProps"
  >
    <template #overrides="{row}">
      <div class="flex justify-center w-full">
        <TableEditButton :skipFocus="true"/>
      </div>
    </template>
  </AgDataTable>
</template>
<script setup lang="ts">
import {computed, ref} from "vue";
import i18n from "@/i18n";
import {Column} from "@/components/ag-grid/tableTypes";
import {editableTableProps, getTableData} from "@/components/ag-grid/tableUtils";
import {
  cellClasses,
  percentageValidator,
  requiredValueSetter,
  stopEditingOnTab
} from "@/components/ag-grid/columnUtils";
import orderBy from "lodash/orderBy";
import {formatPercent} from "@/plugins/formatPercent";
import {cellEditors} from "@/components/ag-grid/cellEditors/cellEditors";
import {GridReadyEvent, ValueSetterParams} from "@ag-grid-community/core";
import {formatPrice} from "@/plugins/formatPrice";

const props = defineProps({
  filters: {
    type: Object,
    required: true
  },
  date: {
    type: Date,
  }
})

const grid = ref<GridReadyEvent>()
const columns = computed<Column[]>(() => {
  return [
    {
      label: i18n.t('Job'),
      prop: 'job.number',
      rowGroup: true,
      hide: true,
    },
    {
      headerName: i18n.t('Phase'),
      field: 'line_item.phase_code',
      minWidth: 80,
      maxWidth: 80,
      align: 'center',
      cellClass: cellClasses.ReadOnlyLight,
      suppressNavigable: true,
    },
    {
      headerName: i18n.t('Cost Code'),
      field: 'line_item.cost_code',
      minWidth: 80,
      maxWidth: 80,
      align: 'center',
      cellClass: cellClasses.ReadOnlyLight,
      suppressNavigable: true,
    },
    {
      headerName: i18n.t('Change Order'),
      field: 'line_item.change_order',
      minWidth: 60,
      maxWidth: 80,
      align: 'center',
      cellClass: cellClasses.ReadOnlyLight,
      suppressNavigable: true,
    },
    {
      headerName: i18n.t('Vendor'),
      field: 'vendor.id',
      minWidth: 100,
      maxWidth: 200,
      component: 'VendorLink',
      cellClass: cellClasses.ReadOnlyLight,
      cellRendererParams: {
        showName: false,
      },
      suppressNavigable: true,
    },
    {
      headerName: i18n.t('PO #'),
      field: 'purchase_order.number',
      minWidth: 80,
      maxWidth: 150,
      cellClass: cellClasses.ReadOnlyLight,
      suppressNavigable: true,
    },
    {
      headerName: i18n.t('Current Contract'),
      field: 'purchase_order_entry.extended_amount',
      minWidth: 80,
      maxWidth: 160,
      align: 'right',
      component: 'FormattedPrice',
      cellClass: cellClasses.ReadOnlyLight,
      aggFunc: 'sum',
      suppressNavigable: true,
    },
    {
      headerName: i18n.t('Invoiced to Date'),
      field: 'purchase_order_entry.invoiced_amount',
      minWidth: 80,
      maxWidth: 160,
      align: 'right',
      component: 'FormattedPrice',
      cellClass: cellClasses.ReadOnlyLight,
      aggFunc: 'sum',
      suppressNavigable: true,
    },
    {
      headerName: i18n.t('% Complete / Units'),
      minWidth: 80,
      maxWidth: 120,
      align: 'right',
      field: 'period_percent',
      valueGetter: params => {
        if (params.node?.footer || params.node?.group) {
          return ''
        }
        if (params.data.period_percent) {
          return params.data.period_percent
        }
        const entry = params.data.purchase_order_entry
        return getEntryPercentage(entry)
      },
      valueFormatter: params => {
        return formatPercent(params.value)
      },
      editable: true,
      cellEditor: cellEditors.Numeric,
      valueSetter: (params) => {
        const isValid = requiredValueSetter(params, 0, percentageValidator)
        if (!isValid) {
          return false
        }
        const initialPercent = getEntryPercentage(params.data.purchase_order_entry)
        const percentage = +params.newValue - initialPercent
        params.data.period_percent = params.newValue
        computePeriodGross(params, percentage)
        computeRetention(params)
        return isValid;
      },
    },
    {
      headerName: i18n.t('Period Gross'),
      minWidth: 120,
      maxWidth: 160,
      align: 'right',
      field: 'gross_amount',
      aggFunc: 'sum',
      valueFormatter: params => {
        return formatPrice(params.value || 0)
      },
      editable: true,
      cellEditor: cellEditors.Numeric,
      valueSetter: (params) => {
        const value = +params.newValue
        if (isNaN(value)) {
          return false
        }
        params.data.gross_amount = value
        computeRetention(params)
        computePercent(params, value)
        return true;
      },
    },
    {
      headerName: i18n.t('Period Retention'),
      minWidth: 120,
      maxWidth: 160,
      align: 'right',
      field: 'retention_amount',
      valueFormatter: params => {
        return formatPrice(params.value || 0)
      },
      editable: true,
      cellEditor: cellEditors.Numeric,
      valueSetter: (params) => {
        const value = +params.newValue
        if (value < 0) {
          return false
        }
        params.data.retention_amount = value
        return true;
      },
      suppressKeyboardEvent: stopEditingOnTab,
    },
    {
      headerName: '',
      field: 'overrides',
      pinned: 'right',
      minWidth: 40,
      maxWidth: 60,
      editable: true,
      cellEditor: 'ProgressInvoiceOverrideDialog',
      suppressPaste: true,
      cellEditorPopup: true,
      onCellClicked: params => {
        params.api.startEditingCell({
          rowIndex: params.rowIndex,
          colKey: params.column.colId,
        })
      }
    },
  ]
})

const groupRowRendererParams = computed(() => {
  return {
    suppressCount: true,
    innerRenderer: 'ProgressInvoiceGroupRow',
  }
})

function computePeriodGross(params: ValueSetterParams, percentage: number) {
  const contractAmount = params.data.purchase_order_entry?.extended_amount || 0
  params.data.gross_amount = contractAmount * percentage / 100
}

function computeRetention(params: ValueSetterParams) {
  const retentionPercent = params.data?.job?.subcontract_retention_percent || 0
  params.data.retention_amount = params.data.gross_amount * retentionPercent / 100
}

function computePercent(params: ValueSetterParams, periodGross: number) {
  const initialInvoicedAmount = params.data.purchase_order_entry?.invoiced_amount || 0
  const contractAmount = params.data.purchase_order_entry?.extended_amount || 0
  params.data.period_percent = (periodGross + initialInvoicedAmount) / contractAmount * 100
}

function getEntryPercentage(entry: any) {
  if (!entry.extended_amount) {
    return 0
  }
  return entry.invoiced_amount / entry.extended_amount * 100
}

function transformData(data: any[]) {
  const entries: any[] = []
  data.forEach(item => {
    let mappedEntries = item.entries.map((entry: any) => {
      return {
        _localId: entry.purchase_order_entry.id,
        invoice_number: undefined,
        invoice_date: props.date || new Date(),
        invoice_description: undefined,
        ...entry,
        job: item.job,
      }
    })
    mappedEntries = orderBy(mappedEntries, ['line_item.phase_code', 'line_item.cost_code', 'line_item.change_order'])
    entries.push(...mappedEntries)
  })
  return entries
}

function getEntries() {
  return getTableData(grid.value?.api).filter(entry => entry.dirty)
}
defineExpose({
  getEntries
})
</script>
<script lang="ts">
import ProgressInvoiceGroupRow
  from "@/modules/accounts-payable/components/subcontract-invoices/ProgressInvoiceGroupRow.vue";
import ProgressInvoiceOverrideDialog
  from "@/modules/accounts-payable/components/subcontract-invoices/ProgressInvoiceOverrideDialog.vue";

export default {
  components: {
    ProgressInvoiceOverrideDialog,
    ProgressInvoiceGroupRow,
  }
}
</script>
