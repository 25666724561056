<template>
  <div class="cost-plus-billing-proof">
    <ProofListing
        entity="billing"
        url="/restify/billings/actions?action=post-cost-plus-billings-proof-listing"
        post-url="/restify/billings/action?action=post-billings"
        ids-field="billing_ids"
        @data-fetch="data = $event"
        #default="{ loading }"
    >
      <AgDataTable
          :data="flatData"
          :data-loading="loading"
          :columns="columns"
          :pagination="false"
          :groupDefaultExpanded="-1"
          :compact="true"
          :no-borders="true"
          :groupIncludeFooter="true"
          :suppressAggFuncInHeader="true"
          :sortable="false"
          :groupRowRendererParams="groupRowRendererParams"
          :getRowHeight="getRowHeight"
          groupDisplayType="groupRows"
          domLayout="autoHeight"
      />

      <div class="grid lg:grid-cols-2 gap-4 mt-8 print:break-inside-avoid">
        <div class="col-span-2">
          <h5 class="form-section-title">
            {{ $t('Grand Total All Invoices') }}
          </h5>
          <AgDataTable
              :data="totalsData"
              :columns="totalColumns"
              :pagination="false"
              :compact="true"
              :no-borders="true"
              :groupIncludeTotalFooter="true"
              dom-layout="autoHeight"
          />
        </div>
        <div class="col-span-1 print:hidden"/>
        <div class="col-span-1">
          <h5 class="form-section-title">
            {{ $t('Account Summary') }}
          </h5>
          <ProofListingAccountSummary
              :data="data.account_summary"
              :loading="loading"
          />
        </div>
      </div>
    </ProofListing>
  </div>
</template>
<script lang="ts">
  import PostDialog from "@/components/common/modal/PostDialog.vue";
  import ProofListing from "@/modules/common/components/proof-listing/ProofListing.vue";
  import ProofListingAccountSummary from "@/modules/common/components/ProofListingAccountSummary.vue";
  import CostPlusBillingProofGroupRow
    from "@/modules/accounts-receivable/components/cost-plus-billings/CostPlusBillingProofGroupRow.vue";
  import { Column } from "@/components/ag-grid/tableTypes";
  import { defineComponent } from "vue";
  import { RowHeightParams, ValueFormatterParams } from "@ag-grid-community/core";

  export default defineComponent({
    components: {
      PostDialog,
      ProofListing,
      ProofListingAccountSummary,
      CostPlusBillingProofGroupRow,
    },
    data() {
      return {
        loading: false,
        data: {
          billings: [],
          account_summary: [],
          summary: {},
        },
      }
    },
    computed: {
      totalsData(): any[] {
        const {
          summary,
        } = this.data as any;
        let data = []
        for (let key in summary.cost_types) {
          const type = summary.cost_types[key];
          data.push({
            id: crypto.randomUUID(),
            cost_type: key,
            ...type,
          })
        }
        return data
      },
      totalColumns(): Column[] {
        return [
          {
            headerName: this.$t('Summary By Cost Type'),
            field: 'cost_type',
          },
          {
            headerName: this.$t('Extended Cost'),
            field: 'extended_amount',
            minWidth: 100,
            align: 'right',
            component: 'FormattedPrice',
            aggFunc: 'sum',
          },
          {
            headerName: this.$t('Markup'),
            field: 'markup_amount',
            minWidth: 100,
            maxWidth: 150,
            align: 'right',
            component: 'FormattedPrice',
            aggFunc: 'sum',
          },
          {
            headerName: this.$t('Percent'),
            field: 'markup_percent',
            minWidth: 80,
            maxWidth: 100,
            align: 'right',
            component: 'FormattedPercent',
          },
          {
            headerName: this.$t('Total Billing'),
            field: 'gross_amount',
            minWidth: 100,
            align: 'right',
            component: 'FormattedPrice',
            aggFunc: 'sum',
          },
          {
            headerName: this.$t('Subject To Tax'),
            field: 'subject_to_sales_tax_amount',
            minWidth: 100,
            maxWidth: 150,
            align: 'right',
            component: 'FormattedPrice',
            aggFunc: 'sum',
          },
          {
            headerName: this.$t('Subject To Retention'),
            field: 'subject_to_retention_amount',
            minWidth: 100,
            align: 'right',
            component: 'FormattedPrice',
            aggFunc: 'sum',
          },
        ]
      },
      flatData() {
        const allEntries: any[] = []
        this.data.billings.forEach((billing: any) => {
          const { customer } = billing
          billing.line_items.forEach((lineItem: any) => {
            lineItem.entries.forEach((entry: any) => {
              allEntries.push({
                ...entry,
                billing,
                customer,
                lineItem,
              })
            })
          })
        })
        return allEntries
      },
      groupRowRendererParams() {
        return {
          innerRenderer: 'CostPlusBillingProofGroupRow',
          suppressCount: true,
          wrapText: true,
          autoHeight: true,
        }
      },
      columns(): Column[] {
        return [
          {
            headerName: this.$t('Billing'),
            field: 'billing.number',
            rowGroup: true,
            hide: true,
            wrapText: true,
            autoHeight: true,
          },
          {
            headerName: this.$t('Line Item'),
            field: 'lineItem.phase_code',
            minWidth: 200,
            maxWidth: 400,
            flex: 1,
            rowGroup: true,
          },
          {
            headerName: this.$t('Cost Type'),
            field: 'meta.type_abbr',
            minWidth: 60,
            maxWidth: 60,
          },
          {
            headerName: this.$t('Reference Date'),
            field: 'meta.date',
            minWidth: 100,
            maxWidth: 100,
            component: 'FormattedDate',
          },
          {
            headerName: this.$t('Qty'),
            field: 'quantity',
            minWidth: 60,
            maxWidth: 70,
            align: 'right',
          },
          {
            headerName: this.$t('Unit Price'),
            field: 'unit_price',
            minWidth: 60,
            maxWidth: 120,
            component: 'FormattedPrice',
            align: 'right'
          },
          {
            headerName: this.$t('Subject To tax'),
            children: [
              {
                headerName: 'Tax',
                field: 'meta.subject_to_tax',
                minWidth: 40,
                maxWidth: 60,
                valueFormatter: (params: ValueFormatterParams) => {
                  if (!params.data) {
                    return ''
                  }
                  return params.value ? 'Yes' : 'No'
                }
              },
              {
                headerName: 'Ret',
                field: 'meta.subject_to_retention',
                minWidth: 40,
                maxWidth: 60,
                valueFormatter: (params: ValueFormatterParams) => {
                  if (!params.data) {
                    return ''
                  }
                  return params.value ? 'Yes' : 'No'
                }
              },
              {
                headerName: 'Markup',
                field: 'meta.subject_to_markup',
                minWidth: 40,
                maxWidth: 70,
                valueFormatter: (params: ValueFormatterParams) => {
                  if (!params.data) {
                    return ''
                  }
                  return params.value ? 'Yes' : 'No'
                }
              }
            ]
          },
          {
            headerName: this.$t('Account'),
            field: 'account',
            minWidth: 60,
            maxWidth: 80,
            component: 'AccountLink',
            cellRendererParams: {
              showDescription: false,
            }
          },
          {
            headerName: this.$t('Extended Cost'),
            field: 'meta.extended_amount',
            minWidth: 80,
            component: 'FormattedPrice',
            align: 'right',
            aggFunc: 'sum',
          },
          {
            headerName: this.$t('Markup'),
            field: 'meta.markup_amount',
            minWidth: 80,
            component: 'FormattedPrice',
            align: 'right',
            aggFunc: 'sum',
          },
          {
            headerName: this.$t('Profit'),
            field: 'meta.profit_amount',
            minWidth: 80,
            component: 'FormattedPrice',
            align: 'right',
            aggFunc: 'sum',
          },
          {
            headerName: this.$t('Total'),
            field: 'gross_amount',
            minWidth: 80,
            component: 'FormattedPrice',
            align: 'right',
            aggFunc: 'sum',
          },
        ]
      }
    },
    methods: {
      getRowHeight(params: RowHeightParams) {
        const { node } = params
        if (node.group && !node.footer && node.level === 0) {
          return 75
        }
        return 40
      },
    },
  })
</script>
<style lang="scss">
  .cost-plus-billing-proof {
    .ag-row-group .ag-group-value {
      @apply h-full;
    }

    .ag-row-group.ag-row-group-indent-0 .ag-group-expanded {
      @apply flex items-start pt-3 h-full;
    }
  }
</style>
