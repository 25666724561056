<template>
  <div
      :class="{
        'no-borders': noBorders,
        'compact-table': compact,
      }"
      class="print:p-0 print:m-0 table-wrapper"
  >
    <div ref="tableContainer"
         :style="tableStyles"
         class="table-container relative align-middle inline-block min-w-full sm:rounded-md border border-gray-200 overflow-auto w-full">
      <table class="min-w-full" v-loading="loading && data.length">
        <thead>
        <template v-if="$slots['report-header']">
          <tr>
            <th colspan="100%" class="thead-infos report-header-row">
              <slot name="report-header"/>
            </th>
          </tr>
        </template>
        <tr
            class="main-table-columns"
            :class="{
              'sticky top-0 z-50 bg-gray-50': isReportTable,
            }">
          <slot name="thead-infos"/>
        </tr>
        <tr
            class="main-table-columns"
            :class="{
              'sticky top-0 z-50 bg-gray-50': isReportTable,
              'top-6': isReportTable && $slots['thead-infos'],
            }"
        >
          <th class="border-b non-draggable bg-gray-50 print:hidden"
              v-if="isExpandable"
          />
          <th v-for="column in columns"
              :key="`${column.prop}-header`"
              class="border-b border-gray-200 bg-gray-50 leading-tight tracking-wide font-semibold text-gray-700 text-xs relative"
              :class="{
                [`th-${column.prop}`]: column.prop,
                'non-draggable': !column.draggable || !column.showDrag,
                'border border-dashed shadow-lg cursor-move': column.draggable && column.showDrag,
                'hidden md:table-cell': column.hideOnMobile,
                'px-1.5 py-1.5 small-cell': column.smallCell || compact,
                'px-3 py-3': !column.smallCell && !compact,
                'print:hidden': column.prop === 'select',
                [column.classes]: column.classes,
              }"
              :style="column.getStyles()"
          >
            <div class="flex items-center th-cell"
                 :class="{
                     'cursor-pointer select-none': column.sortable && !column.showDrag,
                     'justify-end': column.align === 'right',
                     'justify-start': column.align === 'left',
                     'justify-center': column.align === 'center',
                 }"
                 @click="toggleSort(column)"
            >
              <ColumnHeaderRenderer :column="column"/>
              <div v-if="column.sortable"
                   class="flex flex-col ml-1 print:hidden"
              >
                <ChevronUpIcon class="w-3 h-3 -mb-1"
                               :stroke-width="column.sortDirection === 'asc' ? 5 : 3"
                               :class="{'text-primary-600': column.sortDirection === 'asc'}"
                />
                <ChevronDownIcon class="w-3 h-3"
                                 :stroke-width="column.sortDirection === 'desc' ? 5 : 3"
                                 :class="{'text-primary-600': column.sortDirection === 'desc'}"
                />
              </div>
            </div>
          </th>
        </tr>
        </thead>
        <slot></slot>
        <tbody class="bg-white">
        <template v-for="(row, index) in tableData">
          <tr
            v-if="!skipRowRenderBeforeHtmlRow || !row.htmlData"
            :key="getRowKey(row, index)"
            :class="[getRowClasses(row), {
              'expandable-row': row.expanded !== undefined,
              'expanded-row': row.expandable,
              'header-row': row.header,
              'header-row-compacted': headerRowCompacted,
              'footer-row': row.footer,
              'selected-row': row.selected,
              'content-row': !row.header && !row.footer && !row.expanded,
              'last-content-row': isNextRowFooter(row, index),
              'subtotal-row': row.subtotal,
              'last-subtotal-row': row.subtotal && !isNextRowFooter(row, index) && customizeLastSubtotalRow,
              'has-top-header': $slots['thead-infos'],
            }]"
            class="hover:bg-gray-50 focus:bg-gray-50 focus:ring-0 transition-colors duration-75 text-base"
          >
            <slot name="expand-row-icon"
                  v-if="isExpandable && !row.expandable && !row.header">
              <td class="pl-4 py-2 border-b print:hidden"
                  :style="{
                  'min-width': '50px',
                  'max-width': '50px',
                  'width': '50px',
                }"
              >
                <base-tooltip :content="row.expanded ? $t('Hide details') : $t('Show details')"
                              :tabindex="isExpandableFocusable ? 0 : -1"
                >
                  <ChevronDownIcon
                      class="w-5 h-5 cursor-pointer text-gray-400 hover:text-primary-500 transform transition duration-100"
                      :class="{'text-primary-500 rotate-180': row.expanded}"
                      @click="onExpandRow(row, index)"
                  />
                </base-tooltip>
              </td>
            </slot>
            <template v-if="row.header">
              <slot name="header-row" :row="row">
                <td :colspan="isExpandable ? columns.length + 1 : columns.length"
                    class="px-2 py-2 font-medium"
                    :class="{
                      'px-2 py-2 font-medium': !compact,
                      'py-1.5 px-2 print:py-2 text-sm small-cell': compact,
                    }"
                >
                  <slot name="header" :row="row" :index="index"/>
                </td>
              </slot>
            </template>
            <template v-if="row.expandable">
              <td :colspan="columns.length + 1" class="border-b">
                <slot name="expand-content" :row="row"/>
              </td>
            </template>
            <template v-if="isNotCustomRow(row)">
              <td v-for="column in columns"
                  v-if="column.colspan"
                  :is="column.rowTag"
                  :key="column.prop"
                  :class="getCellClasses(column)"
                  :style="column.getStyles()"
                  :colspan="column.colspan"
                  :rowspan="column.rowspan"
                  class="whitespace-nowrap text-gray-700 td-cell"
                  @click="onRowClick(row, index, column)"
              >
                <ColumnRenderer :row="row"
                                :index="index"
                                :column="column"/>
              </td>
            </template>
            <template v-if="row.footer">
              <td :colspan="columns.length"
                  class="px-2 py-2 font-medium text-right">
                <slot name="footer" :row="row"/>
              </td>
            </template>
            <template v-if="row.subtotal">
              <slot name="subtotal" :row="row"/>
            </template>
          </tr>
          <template v-if="row.subtotals">
            <slot name="subtotals" :row="row"/>
          </template>
          <template v-if="row.htmlData">
            <slot name="html-row" :row="row"/>
          </template>
        </template>
        <template v-if="loading && data.length === 0">
          <TableLoadingRow
              v-for="i in placeholderRowCount"
              :key="i"
              :columns="columns"
              :no-borders="noBorders"
              :compact="compact"
              :isExpandable="isExpandable"
              :getCellClasses="getCellClasses"
          />
        </template>

        <tr v-if="data.length === 0 && !loading"
            key="empty">
          <td :colspan="isExpandable ? columns.length + 1 : columns.length"
              class="text-center py-5 text-gray-500 text-lg td-cell no-data-cell"
              :class="{
                  'py-5 px-1.5 print:py-2': !compact,
                  'py-1.5 px-2 print:py-2 text-sm small-cell': compact,
              }"
          >
            <slot name="loading"/>
            <slot name="empty">
              <span v-if="!loading"
                    :class="{'text-sm': compact}"
              >
                  {{ $t('No Data') }}
              </span>
            </slot>
          </td>
        </tr>
        <tr v-if="displaySummary"
            key="summary"
            class="summary-row">
          <td v-if="isExpandable" class="pl-4 py-1 border-b print:hidden"
              style="{
                'min-width': '40px',
                'max-width': '40px',
                'width': '40px',
              }"
          >
          </td>
          <td v-for="column in columns"
              :key="column.prop"
              :class="{
                'text-right': column.align === 'right',
                'text-left': column.align === 'left',
                'text-center': column.align === 'center',
                'px-1.5 py-1 small-cell': column.smallCell || compact,
                'px-2 py-2 td-cell': !column.smallCell && !compact,
              }"
              :style="column.getStyles()"
          >
            <slot name="summary" :column="column"/>
          </td>
        </tr>
        <tr v-if="$slots['footer-row']">
          <slot name="footer-row"></slot>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>

  import get from 'lodash/get'
  import orderBy from 'lodash/orderBy'
  import cloneDeep from 'lodash/cloneDeep'
  import { ref } from 'vue'
  import { ChevronDownIcon, ChevronUpIcon } from 'vue-feather-icons'
  import TableLoadingRow from '@/components/table/TableLoadingRow'
  import { useMaxAvailableHeight } from '@/modules/common/composables/useMaxAvailableHeight'
  import { getCellClasses } from '@/components/table/tableUtils'

  function getColumnData(row, prop) {
    return get(row, `${prop}`, '')
  }

  export default {
    components: {
      ColumnRenderer: {
        functional: true,
        props: ['row', 'column', 'index'],
        render(h, { props, scopedSlots }) {
          const classList = ['cell']

          if (props.prop !== 'select') {
            classList.push('truncate')
          }

          if (props.column.align === 'center') {
            classList.push('flex justify-center')
          }

          const classes = classList.join(' ')
          const attrs = { attrs: { class: classes } }

          if (scopedSlots) {
            const renderedScopedSlot = props.column.$scopedSlots.default({
              row: props.row,
              index: props.index,
            })

            return h('div', attrs, [renderedScopedSlot])
          }

          return h('span', [getColumnData(props.row, props.column.prop)])
        },
      },
      ColumnHeaderRenderer: {
        functional: true,
        props: ['column'],
        render(h, { props }) {
          if (props.column.$scopedSlots.header) {
            return props.column.$scopedSlots.header({
              column: props.column,
            })
          }
          return h('span', [props.column.label])
        },
      },
      ChevronUpIcon,
      ChevronDownIcon,
      TableLoadingRow,
    },
    props: {
      data: {
        type: Array,
        default: () => [],
      },
      rowKey: {
        type: String,
        default: 'id',
      },
      localSort: {
        type: Boolean,
        default: true,
      },
      defaultSort: {
        type: String,
        default: '',
      },
      loading: {
        type: Boolean,
        default: true,
      },
      hasSummary: {
        type: Boolean,
        default: false,
      },
      forceSummary: {
        type: Boolean,
        default: false,
      },
      rowClasses: {
        type: Function,
        default: () => '',
      },
      isExpandable: {
        type: Boolean,
        default: false,
      },
      isExpandableFocusable: {
        type: Boolean,
        default: true,
      },
      limitMaxHeight: {
        type: Boolean,
        default: true,
      },
      expandAllRows: {
        type: Boolean,
        default: false,
      },
      compact: {
        type: Boolean,
        default: false,
      },
      noBorders: {
        type: Boolean,
        default: false,
      },
      perPage: {
        type: Number,
      },
      placeholderRows: {
        type: Number,
      },
      headerRowCompacted: {
        type: Boolean,
        default: false,
      },
      isReportTable: Boolean,
      skipRowRenderBeforeHtmlRow: {
        type: Boolean,
        default: false,
      },
      customizeLastSubtotalRow: {
        type: Boolean,
        default: true,
      },
    },
    provide() {
      return {
        addColumn: this.addColumn,
        removeColumn: this.removeColumn,
      }
    },
    data() {
      return {
        columns: [],
        tableData: [],
      }
    },
    setup(props) {
      const tableContainer = ref(null)
      let styles = {}

      if (props.limitMaxHeight) {
        let result = useMaxAvailableHeight(tableContainer, {
          extraOffset: 70,
          minHeight: 300,
        })
        styles = result.styles
      }

      return {
        tableContainer,
        tableStyles: styles,
      }
    },
    computed: {
      displaySummary() {
        if (this.hasSummary && this.forceSummary) {
          return true
        }
        return this.hasSummary && this.data.length
      },
      placeholderRowCount() {
        if (this.placeholderRows) {
          return this.placeholderRows
        }
        const minRows = 2
        if (this.noBorders) {
          return minRows
        }
        const maxRows = 15
        const rows = this.perPage || minRows
        return Math.min(rows, maxRows)
      },
    },
    methods: {
      isNotCustomRow(row) {
        return !(
            row.header ||
            row.footer ||
            row.subtotal ||
            row.subtotals ||
            row.expandable ||
            row.htmlData
        )
      },
      getRowKey(row, index) {
        if (isNaN(row[this.rowKey])) {
          return index
        }
        return row[this.rowKey] + index
      },
      onExpandRow(row, index) {
        if (row.expanded) {
          row.expanded = false
          this.tableData.splice(index + 1, 1)
        } else {
          const _row = {
            id: row.id,
            expandable: true,
            originalRow: row,
          }
          this.tableData.splice(index + 1, 0, _row)
          row.expanded = true
        }
        this.$emit('expand-row', row)
      },
      onRowClick(row, index, column) {
        if (column.prop === 'actions') {
          return
        }
        this.$emit('row-click', row, index, column)
      },
      getRowClasses(row) {
        if (this.rowClasses && typeof this.rowClasses === 'function') {
          return this.rowClasses(row)
        }
        return ''
      },
      isNextRowFooter(row, index) {
        const nextRow = this.tableData[index + 1]
        if (!nextRow) {
          return false
        }
        return nextRow?.footer || nextRow?.subtotal
      },
      getCellClasses,
      hasSorting() {
        return this.columns.some(col => col.sortDirection !== '')
      },
      toggleDefaultSort() {
        if (this.hasSorting() || !this.defaultSort) {
          return
        }
        let column = this.columns.find(col => col.prop && col.prop.includes(this.defaultSort))
        if (!column) {
          return
        }
        if (this.defaultSort && column.prop && column.prop.includes(this.defaultSort)) {
          this.toggleSort(column, false)
        }
      },
      addColumn(item) {
        const index = this.$slots.default.indexOf(item.$vnode)
        this.columns.splice(index, 0, item)
      },
      removeColumn(step) {
        const columns = this.columns
        const index = columns.indexOf(step)

        if (index > -1) {
          columns.splice(index, 1)
        }
      },
      toggleSort(column, sendEvent = true) {
        if (!column.sortable) {
          return
        }
        if (sendEvent) {
          this.$emit('sort', column, this.columns)
        }

        this.columns.forEach(col => {
          if (col.prop !== column.prop) {
            col.sortDirection = ''
          }
        })
        if (!sendEvent) {
          column.toggleSort()
        }
        if (!this.localSort) {
          return
        }
        column.toggleSort()

        const columnsToSort = this.columns.filter(c => c.sortDirection !== '')
        const columnProps = columnsToSort.map(c => c.prop)
        const columnSortOrders = columnsToSort.map(c => c.sortDirection)

        if (columnProps.length === 0) {
          this.tableData = cloneDeep(this.data)
        } else {
          this.tableData = orderBy(this.tableData, columnProps, columnSortOrders)
        }
      },
      async toggleAllRowExpand(expanded) {
        const data = []
        this.tableData.forEach(row => {
          if (row.expandable) {
            return
          }
          const expandedRow = {
            id: row.id,
            expandable: true,
            originalRow: row,
          }
          row.expanded = expanded
          data.push(row)
          if (expanded) {
            data.push(expandedRow)
          }
        })
        this.tableData = cloneDeep(data)
      },
      collapseRows() {
        this.toggleAllRowExpand(false)
      },
      expandRows() {
        if (!this.expandAllRows) {
          return
        }
        this.toggleAllRowExpand(true)
      },
    },
    watch: {
      data: {
        immediate: true,
        handler(value, oldValue) {
          this.tableData = value
          this.expandRows()
          if (!oldValue || oldValue.length === 0) {
            this.toggleDefaultSort()
          }
        },
      },
      expandAllRows(value) {
        this.toggleAllRowExpand(value)
      },
    },
  }
</script>
<style lang="scss">
  .thead-infos {
    @apply text-center px-4 py-1 bg-gray-50 text-xs leading-4 font-semibold text-gray-600 tracking-wider;
  }

  thead tr:first-child .thead-infos {
    @apply pt-3;
  }

  .thead-infos.text-left {
    text-align: left;
  }

  .thead-infos.text-right {
    text-align: right;
  }

  .table-wrapper {
    tr > th {
      @apply bg-gray-50;
    }

    tr > td.subtotal {
      @apply px-2 py-2 font-medium;
    }

    .sortable-chosen {
      @apply border border-dashed bg-blue-50;
    }

    tr > td {
      @apply border-b whitespace-nowrap border-gray-200;
    }

    table th:first-child:not(.small-cell),
    table .td-cell:first-child:not(.small-cell) {
      @apply pl-4;
    }
  }

  .no-borders.table-wrapper {
    tbody,
    table tr,
    table th {
      @apply bg-transparent;
    }

    thead tr th {
      @apply bg-gray-50 print:bg-transparent;
    }

    table td {
      @apply text-sm leading-4 print:text-print print:leading-none border-none bg-transparent;
    }

    .table-container {
      @apply border-none;
    }
  }

  .data-table table {
    border-collapse: separate; /* Don't collapse */
    border-spacing: 0;
  }

  .data-table table thead tr th {
    position: sticky;
    z-index: 2;

    &.thead-infos {
      z-index: 5;
    }
  }

  .data-table table .actions-header,
  .data-table table .table-actions {
    position: sticky;
    right: 0;
    z-index: 3;
    @apply border-l py-1;

    .cell {
      @apply space-x-2;
    }
  }

  .data-table table .actions-header, {
    z-index: 9;
  }

  .data-table table .table-actions {
    z-index: 2;
    @apply bg-white px-2;

    .cell {
      @apply flex items-center;

      .el-tooltip:last-child {
        @apply mr-0;
      }
    }
  }

  .data-table table tbody tr.selected-row {
    @apply bg-gray-50;
  }

  .data-table table tbody tr:last-child:not(.expanded-row) td,
  .data-table table tbody tr:last-child.expanded-row > td {
    border-bottom: 0;
  }

  .base-form .table-container,
  .wizard .table-container {
    max-height: 100% !important;
  }

  div.html-row {
    @apply px-3 py-2;
  }
</style>
