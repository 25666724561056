<template>
  <base-form
      :loading="loading"
      :show-cancel="showCancel"
      :save-text="$t('Create w2 memo code')"
      :update-text="$t('Update w2 memo code')"
      :can-create-another-entity="!model.id"
      layout="modal"
      grid-classes="grid grid-cols-8 gap-x-4"
      @cancel="$emit('cancel')"
      @submit="onSubmit"
  >
    <base-input
        v-model="model.code"
        :label="$t('Code')"
        :placeholder="$t('W2 memo code')"
        rules="required|max:8|startsWith:W2X"
        class="col-span-8 md:col-span-3"
    />
    <base-textarea
        v-model="model.description"
        :label="$t('Description')"
        :placeholder="$t('Description')"
        rows="1"
        rules="max:150"
        class="col-span-8 md:col-span-5"
    />
    <base-input
        v-model="model.box_number"
        :label="$t('Box Number')"
        :placeholder="$t('Box Number')"
        class="col-span-8 md:col-span-2"
        rules="required|numeric|min_value:1"
        type="number"
    />
    <base-input
        v-model="model.box_code"
        :label="$t('Box Code')"
        :placeholder="$t('Box Code')"
        class="col-span-8 md:col-span-4"
        rules="max:20"
    />
  </base-form>
</template>
<script lang="ts">
  import axios from 'axios'
  import { defineComponent } from 'vue'
  import { WTwoCode } from '@/modules/common/types/models'

  export default defineComponent({
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        showCancel: true,
        loading: false,
        model: {
          code: '',
          description: '',
          box_code: '',
          box_number: 0,
          '401k': false,
          hire: false,
        } as WTwoCode,
      }
    },
    methods: {
      async onSubmit() {
        try {
          this.loading = true
          if (this.model.id) {
            await axios.put(`/restify/w-two-codes/${ this.model.id }`, this.model)
            this.$success(this.$t('W2 memo code updated.'))
            this.$emit('save', this.model)
          } else {
            const {data} = await axios.post('/restify/w-two-codes', this.model)
            this.$success(this.$t('W2 memo code created.'))
            // @ts-ignore
            this.$createAnotherEntity ? this.$emit('create-another') : this.$emit('save', data)
          }
        } catch (err: any) {
          if (err.handled) {
            return
          }
          console.log('err', err)
          this.$error(this.$t('Could not update the w2 memo code.'))
        } finally {
          this.loading = false
        }
      },
    },
    watch: {
      data: {
        immediate: true,
        handler(value) {
          if (!value?.id) {
            return
          }
          this.model = {
            ...this.model,
            ...value.attributes,
          }
        },
      },
    },
  })
</script>
