<template>

  <BaseDataTable
      :show-pagination="false"
      :data="mockData"
      :columns="columns"
      class="report-table"
  >

    <template #header="{row}">
      {{ $t('Vendor:') }}
      <span class="mx-1"> {{ row.header.code }} - {{ row.header.name }} </span>
    </template>

    <template #subtotal="{row}">
      <td align="right">
        {{ $t('Subtotal:') }}
      </td>
      <td colspan="2" align="right">
        <span class="px-2">{{ formatPrice(row.subtotal.amount) }}</span>
      </td>
    </template>

    <template #html-row="{row}">
      <tr class="grand-totals-row">
        <td align="right" class="py-1">
          {{ $t('Grand Total:') }}
        </td>
        <td colspan="2" align="right" class="py-1">
          <span class="px-2">{{ formatPrice(row.htmlData.amount) }}</span>
        </td>
      </tr>
    </template>

  </BaseDataTable>

</template>
<script setup lang="ts">
  import i18n from '@/i18n'
  import { PropType } from 'vue'
  import { formatPrice } from 'src/plugins/formatPrice'

  const props = defineProps({
    settings: {
      type: Object as PropType<Record<string, any>>,
    },
  })

  const columns = [
    {
      label: i18n.t('Invoice'),
      prop: 'number',
    },
    {
      label: i18n.t('Due Date'),
      prop: 'due_date',
      component: 'FormattedDate',
    },
    {
      label: i18n.t('Amount'),
      prop: 'amount',
      align: 'right',
      component: 'FormattedPrice',
    },
  ]

  const mockData = [
    {
      header: {
        id: crypto.randomUUID(),
        code: 'Vendor 1',
        name: 'John Doe',
      },
    },
    {
      number: 'INV-001',
      due_date: '2021-01-15',
      amount: 100,
    },
    {
      number: 'INV-002',
      due_date: '2021-01-15',
      amount: 1000,
    },
    {
      subtotal: {
        amount: 11100,
      },
    },
    {
      header: {
        id: crypto.randomUUID(),
        code: 'Vendor 2',
        name: 'John Doe',
      },
    },
    {
      number: 'INV-003',
      due_date: '2021-01-15',
      amount: 10000,
    },
    {
      subtotal: {
        amount: 10000,
      },
    },
    {
      htmlData: {
        amount: 21100,
      },
    },

  ]
</script>

<style lang="scss">
  .report-table {
    @apply font-mono;
    .main-table-columns th {
      background: v-bind('settings.title.color1');
    }

    .header-row td {
      background: v-bind('settings.group.color1');
      font-size: 12px;
    }

    .content-row:not(.subtotal-row) td {
      background: v-bind('settings.detail.color1');
      font-size: 12px;
    }

    .subtotal-row {
      background: v-bind('settings.totals.color1');
      font-size: 14px;
    }

    .grand-totals-row {
      background: v-bind('settings.totals.color1');
      @apply font-semibold;
    }
  }
</style>
