<template>
  <CustomerHistory/>
</template>
<script>
  import CustomerHistory from '@/modules/accounts-receivable/components/customer-history/CustomerHistory'

  export default {
    components: {
      CustomerHistory,
    },
  }
</script>
