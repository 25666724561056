<template>
  <BarChartCard
      v-if="jobId"
      ref="chart"
      :url="url"
      :url-params="urlParams"
      :title="title"
      :options="chartOptions"
      :show-title-section="showTitleSection"
      :show-range-labels="false"
      :return-full-response="true"
      :format-labels-as-price="true"
      :show-range-picker="false"
      :no-data-loaded="noDataLoaded"
      @data-fetch="onDataFetch"
  >
    <template #filters>
      <BaseSelect
          v-model="selectedOption"
          :options="options"
          :inline-errors="true"
          @change="onTypeChange"
      />
    </template>
  </BarChartCard>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import BarChartCard from "@/components/charts/BarChartCard.vue";
  import { verticalDataAxis } from "@/components/charts/chartOptions";
  import { barChartSeriesConfig } from "@/modules/accounts-receivable/pages/dashboard/widgets/chartUtils";
  import ReportIntervalSelect from "@/modules/accounts-receivable/pages/dashboard/widgets/ReportIntervalSelect.vue";
  import { ChartColors } from "@/components/charts/chartColors";
  import { costTypes } from "@/enum/enums";

  export default defineComponent({
    components: {
      BarChartCard,
      ReportIntervalSelect,
    },
    props: {
      title: {
        type: String,
      },
      url: {
        type: String,
        default: '/restify/line-items',
      },
      showTitleSection: {
        type: Boolean,
        default: true,
      },
      jobId: {
        type: String,
        required: true,
      }
    },
    data() {
      return {
        noDataLoaded: false,
        options: [
          {
            label: this.$t('Cost'),
            value: costTypes.Cost,
          },
          {
            label: this.$t('Income'),
            value: costTypes.Income,
          },
        ],
        selectedOption: costTypes.Income,
        chartOptions: {
          series: [],
          labels: [],
          dataZoom: [
            {
              start: 0,
              end: 100
            },
          ],
        } as any,
      }
    },
    computed: {
      urlParams() {
        return {
          job_id: this.jobId,
          related: 'budgets'
        };
      },
    },
    methods: {
      async onTypeChange() {
        await this.$nextTick()
        const ref = this.$refs.chart as any
        await ref.getData();
      },
      onDataFetch(data: any) {
        const budgetTypes = data.meta.summary[this.selectedOption]?.types || []
        const isCost = this.selectedOption === costTypes.Cost
        let labels = []
        let series = []
        labels = budgetTypes.map((row: any) => row.abbr)
        const seriesConfig = {
          ...barChartSeriesConfig,
          barMaxWidth: 40,
          barGap: '-50%',
          zLevel: 1,
          emphasis: {
            focus: 'series'
          },
        }
        series = [
          {
            ...seriesConfig,
            name: this.$t('Budget'),
            data: budgetTypes.map((row: any) => row.budget),
            color: ChartColors.Gray,
          },
          {
            ...seriesConfig,
            name: this.$t('Actual'),
            data: budgetTypes.map((row: any) => row.actual),
            color: isCost ? ChartColors.Red : ChartColors.Green,
          },
        ]

        series = series.filter(s => s.data.length > 0)
        this.noDataLoaded = series.length === 0

        const xAxis = {
          ...verticalDataAxis,
          data: labels,
        }
        this.chartOptions.dataZoom = []
        this.chartOptions.labels = labels
        this.chartOptions.series = series
        this.chartOptions.xAxis = xAxis
      },
    },
  })
</script>

