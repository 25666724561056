<template>
  <div class="truncate text-gray-900 flex items-center">
    <a v-if="value"
       :href="value"
       target="_blank"
       rel="noopener"
       class="text-gray-900 hover:text-primary-500 hover:underline cursor-pointer truncate">
      {{ value }}
    </a>
    <span v-else>{{ noDataText }}</span>
  </div>
</template>
<script>

  import i18n from "@/i18n";

  export default {
    props: {
      value: {
        type: String,
      },
      noDataText: {
        type: String,
        default: i18n.t('')
      }
    },
  }
</script>
