<template>
  <div>
    <layout-tabs
        v-if="!onViewResourcePage"
        :items="filteredItems"
    />
    <router-view/>
  </div>
</template>
<script>

  export default {
    data() {
      return {
        items: [
          {
            name: this.$t('Pending'),
            tooltip: this.$t('Invoices that were not posted yet'),
            path: '/service-billing/service-invoices/pending',
            permission: 'billings_show',
          },
          {
            name: this.$t('Open'),
            tooltip: this.$t('Posted but not paid'),
            path: '/service-billing/service-invoices/open',
            permission: 'billings_show',
          },
          {
            name: this.$t('Partially Paid'),
            tooltip: this.$t('Partially paid billings'),
            path: '/service-billing/service-invoices/partial-paid',
            permission: 'billings_show',
          },
          {
            name: this.$t('Paid'),
            tooltip: this.$t('Fully paid billings'),
            path: '/service-billing/service-invoices/paid',
            permission: 'billings_show',
          },
          {
            name: this.$t('Voided'),
            tooltip: this.$t('Voided billings'),
            path: '/service-billing/service-invoices/voided',
            permission: 'billings_show',
          },
          {
            name: this.$t('All Invoices'),
            tooltip: this.$t('All Invoices'),
            path: '/service-billing/service-invoices/all',
            permission: 'billings_show',
          },
        ],
      }
    },
    computed: {
      filteredItems() {
        return this.items.filter(item => this.$can(item.permission))
      },
    },
  }
</script>
