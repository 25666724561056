<template>
  <base-report-form
      :filters="model"
      :report-number="reportNumber"
  >

    <template #filters>

      <common-report-filters v-model="model"/>
      <base-filter-row>
        <base-checkbox
            v-model="model.include_billings"
            :label="$t('Include UNPOSTED Progress Billings')"
            class="mb-2 col-span-3"
            id="include_billings"
        />
      </base-filter-row>

      <base-filter-row :title="$t('Print')">
        <base-select
            v-model="model.total_by"
            :add-entity="false"
            :options="computeTotalByOptions"
            class="col-span-3"
        />
      </base-filter-row>

      <base-filter-row :title="$t('Include Types')">
        <base-select
            v-model="model.job_cost_type_ids"
            :add-entity="false"
            :options="getJobCostTypes"
            :name="$t('Types')"
            rules="required"
            class="col-span-3"
            collapse-tags
            multiple
        />
      </base-filter-row>

    </template>

    <template #table="{data, loading}">

      <BillingCostComparisonReportTable
        :data="data"
        :filters="model"
        :report-number="reportNumber"
        :type="model.total_by"
        :data-loading="loading"
        ref="reportTable"
      />

    </template>
  </base-report-form>

</template>
<script>
  import { commonFilters, computeTotalBy } from '@/modules/job-costing/components/reports/util'
  import CommonReportFilters from '@/modules/job-costing/components/reports/CommonReportFilters'
  import BillingCostComparisonReportTable
    from '@/modules/job-costing/components/reports/BillingCostComparisonReportTable'

  export default {
    components: {
      CommonReportFilters,
      BillingCostComparisonReportTable,
    },
    data() {
      return {
        computeTotalBy,
        reportNumber: '2877',
        computeTotalByOptions: [
          {
            label: this.$t('Line Item & Phase Totals'),
            value: computeTotalBy.PhaseCode,
          },
          {
            label: this.$t('Line Item Totals Only'),
            value: computeTotalBy.PhaseAndCostCode,
          },
          {
            label: this.$t('Job Totals Only'),
            value: computeTotalBy.Job,
          },
        ],
        model: {
          ...commonFilters,

          total_by: computeTotalBy.PhaseCode,
          include_billings: false,
          job_cost_type_ids: [],
          job_income_type_ids: [],
        },
      }
    },
    computed: {
      jobCostTypes() {
        return this.$store.getters['globalLists/getResourceOptions'](this.$globalResources.JobCostTypes)
      },
      jobIncomeTypes() {
        return this.$store.getters['globalLists/getResourceOptions'](this.$globalResources.JobIncomeTypes)
      },
      getJobCostTypes() {
        return this.jobCostTypes.map(type => {
          return {
            label: `${type.abbr} (${type.name})`,
            value: type.id,
          }
        })
      },
    },
    methods: {
      async setDefaultJobTypes() {
        this.model.job_income_type_ids = this.jobIncomeTypes.map(type => type.id)
        this.model.job_cost_type_ids = this.jobCostTypes.map(type => type.id)
      },
    },
    mounted() {
      this.setDefaultJobTypes()
    },
    watch: {
      jobIncomeTypes: {
        immediate: true,
        handler() {
          this.setDefaultJobTypes()
        }
      },
    }
  }
</script>
