<template>
  <div>
    <AgDataTable
      :url="url"
      :columns="columns"
      :url-params="allUrlParams"
      :actions="'search,refresh'"
      :add-entity-in-new-tab="addEntityInNewTab"
      :searchPlaceholder="$t('Search recurring payables...')"
      :add-text="$t('New recurring payable')"
      :add-entity-url-query="addEntityUrlQuery"
      :title="title"
      :defaultFilters="!hideAllActions"
      :hide-actions="hideAllActions ? 'add': hideActions"
      :selected-rows.sync="selectedRows"
      :actions-column-width="170"
      permission="recurring_invoices"
      ref="table"
      base-entity-path="/accounts-payable/recurring-invoices"
      @data-fetch="onDataFetch"
    >
      <template #attributes.recurring_rule="{row}">
        <RRuleText
          :value="row.attributes.recurring_rule"
          :invoice="row"
        />
      </template>
      <template #frequency="{row}">
        {{ getFrequencyLabelFromPayable(row) }}
      </template>
      <template #start_date="{row}">
        {{ $formatDateOnly(row.attributes.start_date) }}
      </template>
      <template #attributes.cost_center="{row}">
        <div class="flex items-center space-x-1">
          <span>{{ row.attributes.cost_center }}</span>
          <SourceLink
            v-if="row.attributes.source_id"
            :data="row.attributes"
            :data-as-source="true"
          />
        </div>
      </template>
      <template #extra-actions-before="{row}">
        <BaseButton
          v-if="showActivateButton && !isReview"
          :loading="activeLoading"
          variant="gray-icon"
          size="xs"
          @click="activateInvoice(row)"
        >
          <PlayIcon class="w-4 h-4"/>
        </BaseButton>
      </template>
      <template #extra-actions="{row, index}">
        <slot
          name="extra-actions"
          :row="row"
          :index="index"
        >
        </slot>
      </template>
      <template #additional-actions>
        <BaseButton
          v-if="showActivateButton"
          :disabled="selectedRows.length === 0"
          :loading="activeLoading"
          variant="gray-link"
          @click="activateInvoices"
        >
          <PlayIcon class="w-4 h-4 mr-1"/>
          <span>{{ $t('Activate') }}</span>
        </BaseButton>
      </template>
      <template
        v-if="showAdvanceInvoicesAction"
        #dropdown-actions>
        <TableActionItem>
          <button
            class="action-item-text"
            :class="{
              'opacity-50': selectedRows.length === 0,
            }"
            type="button"
            :disabled="selectedRows.length === 0"
            @click="showGenerateInvoicesDialog = true"
          >
            <RecurringInvoiceIcon class="w-4 h-4 mr-1"/>
            <span>{{ $t('Generate Invoices') }}</span>
          </button>
        </TableActionItem>
      </template>
    </AgDataTable>
    <GenerateInvoicesDialog
      v-if="showGenerateInvoicesDialog"
      :open.sync="showGenerateInvoicesDialog"
      :recurring-invoices="selectedRows"
      @save="onInvoicesGenerated"
      @close="showGenerateInvoicesDialog = false"
    />
  </div>
</template>
<script>
import { resourceStatuses } from '@/enum/enums'
import { getAddButtonPath } from "@/modules/common/util/costCenterUtils";
import SourceLink from "@/components/links/SourceLink.vue";
import i18n from "@/i18n";
import RRuleText from "@/modules/accounts-payable/components/recurring-invoice/RRuleText.vue";
import DownloadChecksDialog from "@/modules/payroll/components/payroll/DownloadChecksDialog.vue";
import SendPayrollChecksForm from "@/modules/payroll/components/checks/SendPayrollChecksForm.vue";
import CheckTemplateSelect from "@/modules/payroll/components/CheckTemplateSelect.vue";
import ConfirmEmailSendDialog from "@/components/common/modal/ConfirmEmailSendDialog.vue";
import { PlayIcon, Trash2Icon } from "vue-feather-icons";
import TableActionItem from "@/components/table/actions/TableActionItem.vue";
import { TableActions } from "@/components/ag-grid/tableUtils";
import RecurringInvoiceIcon from "@/modules/accounts-payable/pages/invoices/RecurringInvoiceIcon.vue";
import GenerateInvoicesDialog from "@/modules/accounts-payable/components/recurring-invoice/GenerateInvoicesDialog.vue";
import { getFrequencyLabelFromPayable } from "@/modules/accounts-payable/components/recurring-invoice/recurringUtils";

export default {
  components: {
    PlayIcon,
    GenerateInvoicesDialog,
    Trash2Icon,
    RRuleText,
    SourceLink,
    TableActionItem,
    ConfirmEmailSendDialog,
    RecurringInvoiceIcon,
    CheckTemplateSelect,
    SendPayrollChecksForm,
    DownloadChecksDialog,
  },
  props: {
    urlParams: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
    },
    invoiceStatus: {
      type: String,
    },
    hideAllActions: {
      type: Boolean,
      default: false,
    },
    hideActions: {
      type: String,
      default: ''
    },
    isReview: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      resourceStatuses,
      generateLoading: false,
      showGenerateInvoicesDialog: false,
      activeLoading: false,
      addEntityInNewTab: true,
      url: '/restify/recurring-invoices',
      invoices: [],
      selectedRows: [],
    }
  },
  computed: {
    TableActions() {
      return TableActions
    },
    addEntityUrlQuery() {
      const baseUrl = '/accounts-payable/recurring-invoices/add'
      return getAddButtonPath(baseUrl, this.urlParams)
    },
    status() {
      return this.$route.meta?.status || this.invoiceStatus || ''
    },
    allUrlParams() {
      return {
        status: this.status,
        ...this.urlParams,
      }
    },
    showAdvanceInvoicesAction() {
      return [resourceStatuses.Active].includes(this.status)
    },
    showActivateButton() {
      return [resourceStatuses.Pending].includes(this.status) && !this.isReview
    },
    columns() {
      return [
        {
          headerName: this.$t('Vendor'),
          field: 'attributes.vendor_id',
          minWidth: 200,
          maxWidth: 300,
          component: 'VendorLink',
          checkboxSelection: true,
        },
        {
          headerName: i18n.t('Reference'),
          field: 'attributes.cost_center',
          minWidth: 200,
          maxWidth: 350,
        },
        {
          headerName: this.$t('Frequency'),
          field: 'frequency',
          minWidth: 80,
          maxWidth: 200,
        },
        {
          headerName: this.$t('Schedule'),
          field: 'attributes.recurring_rule',
          minWidth: 380,
          maxWidth: 500,
          hide: true,
        },
        {
          headerName: this.$t('Amount'),
          field: 'attributes.gross_amount',
          component: 'FormattedPrice',
          minWidth: 140,
          maxWidth: 180,
          align: 'right',
        },
        {
          headerName: this.$t('Type'),
          align: 'center',
          field: 'attributes.type',
          minWidth: 100,
          maxWidth: 120,
          component: 'Status',
        },
        {
          headerName: this.$t('Status'),
          align: 'center',
          field: 'attributes.status',
          minWidth: 80,
          maxWidth: 100,
          component: 'StatusLink',
        },
        {
          headerName: this.$t('Review Status'),
          align: 'center',
          field: 'attributes.review_status',
          minWidth: 100,
          maxWidth: 140,
          component: 'ReviewStatus',
        },
        {
          headerName: this.$t('Post Automatically'),
          align: 'center',
          field: 'attributes.should_post',
          minWidth: 100,
          maxWidth: 120,
          component: 'Status',
        },
        {
          headerName: this.$t('Last Issue At'),
          align: 'center',
          field: 'attributes.last_issue_at',
          minWidth: 160,
          maxWidth: 200,
          component: 'FormattedDate',
          hide: true,
        },
        {
          headerName: this.$t('Next Recurrence'),
          align: 'center',
          field: 'attributes.next_recurrence_at',
          minWidth: 160,
          maxWidth: 200,
          component: 'FormattedDate',
          hide: true,
        },
        {
          headerName: this.$t('Created By'),
          field: 'attributes.created_by',
          minWidth: 120,
          maxWidth: 180,
          component: 'UserLink',
          hide: true,
        },
      ]
    },
  },
  methods: {
    async activateInvoices() {
      try {
        this.activeLoading = true
        const confirmed = await this.$confirm({
          title: this.$t('Activate Recurring Payable(s)'),
          description: this.$t('By making the recurring payable(s) active, they will start creating new invoices based on the provided schedule. Are you sure?'),
          buttonText: this.$t('Activate')
        })
        if (!confirmed) {
          return
        }
        const invoiceIds = this.selectedRows.map(i => i.id)
        for (let id of invoiceIds) {
          await this.$store.dispatch('accountsPayable/activateRecurringInvoice', id)
        }
        await this.refreshData()
      } catch (err) {
        if (err.handled) {
          return
        }
      } finally {
        this.activeLoading = false
      }
    },
    async activateInvoice(row) {
      const confirmed = await this.$confirm({
        title: this.$t('Activate Recurring Payable'),
        description: this.$t('By making the recurring payable active, they will start creating new invoices based on the provided schedule. Are you sure?'),
        buttonText: this.$t('Activate')
      })
      if (!confirmed) {
        return
      }
      await this.$store.dispatch('accountsPayable/activateRecurringInvoice', row.id)
      await this.refreshData()
    },
    getFrequencyLabelFromPayable,
    onInvoicesGenerated() {
      this.refreshData()
      this.showGenerateInvoicesDialog = false
    },
    async refreshData() {
      await this.$refs.table?.refresh()
    },
    onDataFetch(invoices) {
      this.invoices = invoices
      this.$emit('data-fetch', invoices)
    }
  },
}
</script>
