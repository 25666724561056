<template>
  <div>
    <base-button
      v-bind="$attrs"
      :loading="downloadLoading"
      type="button"
      :variant="$attrs.variant || 'primary-light'"
      @click="showDialog = true"
    >
      <slot>
        <IconFile class="w-4 h-4 mr-1"/>
        {{ $t('Preview Checks') }}
      </slot>
    </base-button>
    <BaseFormDialog
      v-if="showDialog"
      :open.sync="showDialog"
      :title="$t('Print Checks')"
      :appendToBody="true"
    >
      <base-form
        :save-text="$t('Print Checks')"
        :loading="downloadLoading"
        :focus-on-first-input="false"
        @cancel="$emit('cancel')"
        layout="modal"
        @submit="previewChecks"
      >
        <div class="col-span-6">
          <base-select
            id="print_checks"
            v-model="model.position"
            :options="positionOptions"
            :label="$t('Check Position')"
          />
        </div>
        <div class="col-span-6">
          <base-checkbox
            id="cut_off"
            v-model="model.cut"
            :label="$t('Show cut section with scissors?')"
          />
        </div>
      </base-form>
    </BaseFormDialog>
  </div>
</template>
<script>
  import { previewFileInNewTab } from "@/modules/common/util/downloadFileLocally";
  const positionTypes = {
    Top: 'top',
    Bottom: 'bottom',
    Center: 'middle',
  }
  export default {
    name: 'PreviewChecksButton',
    inheritAttrs: false,
    props: {
      downloadFunction: {
        type: Function,
      }
    },
    data() {
      return {
        downloadLoading: false,
        showDialog: false,
        model: {
          position: positionTypes.Top,
          cut: true,
        },
        positionOptions: [
          {
            label: this.$t('Top'),
            value: positionTypes.Top,
          },
          {
            label: this.$t('Bottom'),
            value: positionTypes.Bottom,
          },
          {
            label: this.$t('Center'),
            value: positionTypes.Center,
          }
        ]
      }
    },
    methods: {
      async previewChecks() {
        this.$emit('click')
        try {
          if (!this.downloadFunction) {
            return
          }
          this.$info('Generating preview... Please wait as this may take a while.')
          this.downloadLoading = true
          const res = await this.downloadFunction(this.model)
          if (!res.size) {
            this.$warning(this.$t('There are no checks to preview. All the recorded payments are either credit card or manual.'))
            return
          }
          previewFileInNewTab(res)
          this.$success(this.$t('PDF preview generated successfully'))
        } catch (err) {
          console.log(err)
          this.$error(this.$t('An error occurred while generating the PDF preview'))
        } finally {
          this.downloadLoading = false
        }
      }
    }
  }
</script>
