<template>
  <div class="import-data mt-4">
    <ImportForm
        v-bind="importSettings"
        @success="redirectToList"
    />
  </div>
</template>
<script>
import ImportForm from '@/modules/common/components/import/ImportForm';
import { getInitialMapping } from '@/modules/payroll/utils/timecardImportColumns';

const colMappings = {
  Code: {
    value: 'code',
    label: 'Code',
    alternativeLabels: ['Benefit Code'],
    required: true,
  },
  Description: {
    value: 'description',
    label: 'Description',
  },
  CalculateBy: {
    value: 'calculate_by',
    label: 'Calculate By',
    alternativeLabels: ['Calculated', 'Calculate By'],
    required: true,
  },
  MultiplyBy: {
    value: 'multiply_by',
    label: 'Multiply By',
    alternativeLabels: ['Multiplied', 'Multiplied By'],
    required: true,
  },
  ExpenseAccount: {
    value: 'expense_account',
    label: 'Expense Account',
    alternativeLabels: [],
  },
  ExpenseSubAccount: {
    value: 'expense_subaccount',
    label: 'Expense SubAccount',
    alternativeLabels: [],
  },
  LiabilityAccount: {
    value: 'liability_account',
    label: 'Liability Account',
    alternativeLabels: [],
  },
  LiabilitySubAccount: {
    value: 'liability_subaccount',
    label: 'Liability SubAccount',
    alternativeLabels: [],
  },
  IncludeOnCertifiedPayrollReport: {
    value: 'include_on_certified_payroll_report',
    label: 'Include On Cert Payroll',
    alternativeLabels: [],
  },
  IncludeOnUnionReports: {
    value: 'include_on_union_reports',
    label: 'Include On Union Report',
    alternativeLabels: [],
  },
  Is401KMatch: {
    value: 'is_401k_match',
    label: '401k Match',
    alternativeLabels: [],
  },
  ShouldDistribute: {
    value: 'should_distribute',
    label: 'Should Distribute',
    alternativeLabels: [],
  },
  SubjectToFica: {
    value: 'subject_to_fica',
    label: 'Fica',
    alternativeLabels: [],
  },
  SubjectToFit: {
    value: 'subject_to_fit',
    label: 'Fit',
    alternativeLabels: [],
  },
  SubjectToFui: {
    value: 'subject_to_fui',
    label: 'Fui',
    alternativeLabels: [],
  },
  SubjectToLiabilityInsurance: {
    value: 'subject_to_liability_insurance',
    label: 'Subject To Liability Insurance',
    alternativeLabels: ['Gen Liab'],
  },
  SubjectToLit: {
    value: 'subject_to_lit',
    label: 'Lit',
    alternativeLabels: [],
  },
  SubjectToSdi: {
    value: 'subject_to_sdi',
    label: 'Sdi',
    alternativeLabels: [],
  },
  SubjectToSit: {
    value: 'subject_to_sit',
    label: 'Sit',
    alternativeLabels: [],
  },
  SubjectToSui: {
    value: 'subject_to_sui',
    label: 'Sui',
    alternativeLabels: [],
  },
  SubjectToWorkersComp: {
    value: 'subject_to_workers_comp',
    label: 'Subject To Workers Comp',
    alternativeLabels: ['WrkCmp'],
  },
  UseEmployeeRegularRate: {
    value: 'use_employee_regular_rate',
    label: 'Use RegPay',
    alternativeLabels: [],
  },
}

const options = [
  colMappings.Code,
  colMappings.Description,
  colMappings.CalculateBy,
  colMappings.MultiplyBy,
  colMappings.ExpenseAccount,
  colMappings.ExpenseSubAccount,
  colMappings.LiabilityAccount,
  colMappings.LiabilitySubAccount,
  colMappings.IncludeOnCertifiedPayrollReport,
  colMappings.IncludeOnUnionReports,
  colMappings.Is401KMatch,
  colMappings.ShouldDistribute,
  colMappings.SubjectToFica,
  colMappings.SubjectToFit,
  colMappings.SubjectToFui,
  colMappings.SubjectToLiabilityInsurance,
  colMappings.SubjectToLit,
  colMappings.SubjectToSdi,
  colMappings.SubjectToSit,
  colMappings.SubjectToSui,
  colMappings.SubjectToWorkersComp,
  colMappings.UseEmployeeRegularRate,
]

export default {
  components: {
    ImportForm,
  },
  data() {
    return {
      importSettings: {
        url: '/restify/benefit-codes/import',
        columnOptions: options,
        columnMappings: colMappings,
        initialMapping: getInitialMapping(options),
        type: 'benefit-code-import',
        resource: 'benefit-codes',
      },
    }
  },
  methods: {
    async redirectToList() {
      await this.$router.push('/payroll/codes/benefit-codes')
    },
  },
}
</script>
