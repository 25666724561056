<template>
  <AgDataTable
    v-bind="editableTableProps"
    :columns="columns"
    :url="url"
    :url-params="urlParams"
    :per-page="500"
    :table-filters="!!data.id"
    :add-text="$t('New rate')"
    :transform-data="mapData"
    :get-empty-row="getEmptyEntry"
    :show-pagination="true"
    :read-only="readOnly"
    :actions="readOnly ? '' : 'add'"
    ref="gridTable"
    @cell-focused="onCellFocused"
    @grid-ready="grid = $event"
  />
</template>
<script>
import { cellEditors } from '@/components/ag-grid/cellEditors/cellEditors'
import { requiredValueSetter } from '@/components/ag-grid/columnUtils'
import {
  codeTypes,
  composeCodeApplication,
  ratesSourceMap
} from '@/modules/payroll/components/rates/util'
import { calculateAmountAs } from '@/enum/payroll'
import { editableTableProps, getRowData, getTableData } from '@/components/ag-grid/tableUtils'
import { sourceCol, updateColumnHeader } from '@/components/ag-grid/columns/costCenterColumns'
import axios from 'axios'
import { getDeleteColumn } from '@/components/ag-grid/columns/deleteColumns'
import i18n from "@/i18n";

const sourceColumnTitle = {
  [codeTypes.DEDUCTION]: 'Deduction Code',
  [codeTypes.BEN]: 'Benefit Code',
  default: 'Deduction Code',
}

const rateTypeOptions = [
  {
    label: i18n.t('DED'),
    value: codeTypes.DEDUCTION,
  },
  {
    label: i18n.t('BEN'),
    value: codeTypes.BEN,
  },
]

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    apiUrl: {
      type: String,
      default: '/restify/time-off-rates',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editableTableProps,
      entries: [],
      grid: null,
    }
  },
  computed: {
    url() {
      return this.data.id ? this.apiUrl : ''
    },
    urlParams() {
      if (!this.data.id) {
        return {}
      }

      return {
        time_off_policy_id: this.data.id,
      }
    },
    columns() {
      return [
        {
          headerName: this.$t('Type'),
          field: 'source_type',
          editable: this.canEditRow,
          cellClass: this.fieldCellClass,
          cellEditor: cellEditors.BaseSelect,
          cellEditorParams: {
            options: rateTypeOptions,
          },
          valueSetter: params => {
            params.data.source_id = ''
            return requiredValueSetter(params)
          },
          valueFormatter: params => {
            const option = rateTypeOptions.find(option => option.value === params.value)
            return option?.label || params.value
          },
          minWidth: 60,
          maxWidth: 80,
        },
        {
          ...sourceCol,
          editable: this.canEditRow,
          cellClass: this.fieldCellClass,
          component: 'RatesLink',
          cellRendererParams: {
            target: '_blank',
          },
          cellEditorParams: params => {
            const { source_type } = params.data
            const resourceName = ratesSourceMap[source_type]

            return {
              resourceName,
              unauthorizedToAdd: false,
            }
          },
          valueSetter: params => {
            params.data.source_id = params.newValue
            this.onChangeSource(params.data)
            return true
          },
          minWidth: 150,
        },
        {
          headerName: this.$t('Code Application'),
          field: 'code_application',
          cellClass: 'readonly-ag-cell',
          minWidth: 150,
          maxWidth: 250,
          suppressNavigable: true,
        },
        {
          headerName: this.$t('Calculate By'),
          field: 'calculate_by',
          cellClass: 'readonly-ag-cell',
          component: 'FormattedText',
          minWidth: 100,
          maxWidth: 150,
          suppressNavigable: true,
        },
        {
          headerName: this.$t('Multiply By'),
          field: 'multiply_by',
          cellClass: 'readonly-ag-cell',
          component: 'FormattedText',
          minWidth: 100,
          maxWidth: 150,
          suppressNavigable: true,
        },
        {
          headerName: this.$t('Amount'),
          field: 'rate',
          editable: this.isRateEditable,
          cellEditor: cellEditors.Numeric,
          minWidth: 50,
          maxWidth: 150,
          cellClass: params => {
            const isEditable = this.isRateEditable(params)
            return isEditable ? 'flex justify-end' : 'readonly-ag-cell flex justify-end'
          },
          valueFormatter: params => {
            const { calculate_by } = params.data
            if (calculate_by === calculateAmountAs.Percentage) {
              return this.$formatPercent(params.value, {
                maximumFractionDigits: 3,
              })
            }
            return this.$formatPrice(params.value)
          },
          suppressNavigable: params => !this.isRateEditable(params),
          suppressKeyboardEvent: params => {
            let isTabKey = params.event.key === 'Tab'
            if (isTabKey) {
              params.api.stopEditing()
            }
          },
        },
        {
          ...getDeleteColumn({
            title: this.$t('Delete Rate'),
            url: this.apiUrl,
            hide: params => {
              if (params?.data?.is_used) {
                return true
              }
              return this.readOnly
            },
          }),
        },
      ]
    },
  },
  methods: {
    canEditRow(params) {
      return !params?.data?.is_used
    },
    fieldCellClass(params) {
      if (this.canEditRow(params)) {
        return ''
      }
      return 'readonly-ag-cell'
    },
    mapData(data) {
      this.entries = data.map(rate => {
        const code_application = composeCodeApplication(rate.attributes)
        return {
          ...rate.attributes,
          code_application,
        }
      })
      return this.entries
    },
    getEmptyEntry() {
      return {
        source_id: null,
        rate: 0,
        source_type: codeTypes.DEDUCTION,
        _localId: crypto.randomUUID(),
      }
    },
    isRateEditable(params) {
      const { is_used } = params.data
      return !is_used
    },
    findResource({ source_id, source_type }) {
      const resourceName = ratesSourceMap[source_type] || ratesSourceMap.default
      const resourceList = this.$store.getters['globalLists/getResourceList'](resourceName)
      return resourceList.find(resource => resource.id === source_id) || {}
    },
    onChangeSource(data) {
      const resource = this.findResource(data)
      data['code_application'] = composeCodeApplication(resource)
      data['calculate_by'] = resource?.calculate_by
      data['multiply_by'] = resource?.multiply_by
    },
    onCellFocused(params) {
      const row = getRowData(params)
      const sourceCol = params.columnApi.getColumn('source_id')?.colDef
      const source_type = row?.source_type
      const title = sourceColumnTitle[source_type]
      updateColumnHeader(sourceCol, title)
      params.api.refreshHeader()
    },
    async refreshEntriesTable() {
      if (!this.data.id) {
        return
      }
      await this.$refs.gridTable.refresh()
    },
    async storeProgress(id) {
      let additionalPayload = {
        time_off_policy_id: id,
      }

      const tableData = getTableData(this.grid.api)
        .map(row => {
          return {
            ...row,
            ...additionalPayload,
          }
        })
      const entriesToSave = tableData.filter(t => !t.id && t.dirty)
      const entriesToUpdate = tableData.filter(t => t.id && t.dirty)

      if (entriesToSave.length) {
        await axios.post(`${ this.apiUrl }/bulk`, entriesToSave)
      }
      if (entriesToUpdate.length) {
        await axios.post(`${ this.apiUrl }/bulk/update`, entriesToUpdate)
      }
      await this.refreshEntriesTable()
    },
  },
}
</script>
