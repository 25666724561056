<template>
  <BaseReportForm
    :filters="model"
    :report-number="reportNumber"
  >
    <template #filters>

      <BaseFilterRow :title="$t('Sort & Format')">
        <BaseSelect
          v-model="model.sort_by"
          :options="sortByOptions"
          class="col-span-2"
        />

        <BaseSelect
          v-model="model.print"
          :options="printOptions"
          class="col-span-2"
        />

      </BaseFilterRow>

      <BaseFilterRow :title="$t(`Include`)">
        <BaseSelect
          v-model="model.include"
          :options="includeOptions"
          class="col-span-4"
          @change="model.code_ids = []"
        />
      </BaseFilterRow>

      <BaseFilterRow
        v-if="model.include !== ToDateEarningsAndTaxesOptions.Include.All"
        :title="codeSelectAttrs.label"
      > 
        <CodeSelect
          v-model="model.code_ids"
          v-bind="codeSelectAttrs"
          :multiple="true"
          :hide-label="true"
          collapse-tags
          class="col-span-4"
        />
      </BaseFilterRow>

      <BaseFilterRow :title="$t(`State`)">
        <StateSelect
          v-model="model.state"
          class="col-span-4"
        />
      </BaseFilterRow>

      <EmployeeRangeFilter
        :title="$t('Employee Range')"
        v-model="model"
      />

      <DepartmentRangeFilter
        v-model="model"
      />

      <BaseFilterRow :title="$t(`Print Archive Year`)">
        <BaseInput
          v-model="model.archive_year"
          :placeholder="$t('Print Archive Year (YYYY)')"
          type="number"
          clearable
          class="col-span-4"
        />
      </BaseFilterRow>

    </template>

    <template #table="{ data, loading }">
      <ToDateEarningsAndTaxesReportTable
        :data="data"
        :filters="model"
        :data-loading="loading"
        :report-number="reportNumber"
        ref="reportTable"
      />
    </template>
  </BaseReportForm>
</template>
<script>
import ToDateEarningsAndTaxesReportTable from '@/modules/payroll/components/reports/to-date-earnings-and-taxes/ToDateEarningsAndTaxesReportTable.vue'
import { DepartmentRangeFilter } from '@/components/range-filters'
import { EmployeeRangeFilter } from '@/components/range-filters'
import { ToDateEarningsAndTaxesOptions } from '@/modules/payroll/components/reports/util'
import { globalResources } from "@/components/form/util";

export default {
  components: {
    EmployeeRangeFilter,
    DepartmentRangeFilter,
    ToDateEarningsAndTaxesReportTable,
  },
  setup() {
    return {
      globalResources,
      ToDateEarningsAndTaxesOptions,
    }
  },
  data() {
    return {
      reportNumber: 4883,
      model: {
        code_ids: null, // [] Deduction/pay/tax & hours codes based on "include" selection
        include: ToDateEarningsAndTaxesOptions.Include.All,
        sort_by: ToDateEarningsAndTaxesOptions.SortBy.Employee,
        print: ToDateEarningsAndTaxesOptions.PrintBy.Detail,
        state: null,
        employee_code_from: null,
        employee_code_to: null,        
        department_code_from: null,
        department_code_to: null,
        archive_year: null,
      },
      // Select options
      sortByOptions: [
        {
          label: this.$t('Employee'),
          value: ToDateEarningsAndTaxesOptions.SortBy.Employee,
        },
        {
          label: this.$t('Pay/Deduct Code'),
          value: ToDateEarningsAndTaxesOptions.SortBy.Code,
        },
      ],
      printOptions: [
        {
          label: this.$t('Detail'),
          value: ToDateEarningsAndTaxesOptions.PrintBy.Detail,
        },
        {
          label: this.$t('Subtotals'),
          value: ToDateEarningsAndTaxesOptions.PrintBy.SubTotals,
        },
        {
          label: this.$t('Grand Totals'),
          value: ToDateEarningsAndTaxesOptions.PrintBy.GrandTotals,
        }
      ],
      includeOptions: [
        {
          label: this.$t('All Codes'),
          value: ToDateEarningsAndTaxesOptions.Include.All,
        },
        {
          label: this.$t('Pay Codes Only'),
          value: ToDateEarningsAndTaxesOptions.Include.PayCodes,
        },
        {
          label: this.$t('Deduction Codes Only'),
          value: ToDateEarningsAndTaxesOptions.Include.DeductionCodes,
        },
        {
          label: this.$t('Tax/Hours Codes Only'),
          value: ToDateEarningsAndTaxesOptions.Include.TaxAndHours,
        },
      ],
    }
  },
  computed: {
    codeSelectAttrs() {
      const attrsMap = {
        [ToDateEarningsAndTaxesOptions.Include.PayCodes]: {
          label: this.$t('Pay Codes'),
          placeholder: this.$t('Select Pay Codes'),
          codeTypes: [
            globalResources.PayCodes,
            globalResources.BenefitCodes,
            globalResources.TimeOffPolicies,
          ],
        },
        [ToDateEarningsAndTaxesOptions.Include.DeductionCodes]: {
          label: this.$t('Deduction Codes'),
          placeholder: this.$t('Select Deduction Codes'),
          codeTypes: [
            globalResources.DeductionCodes,
            globalResources.RetirementPlans,
            globalResources.FlexibleBenefitPlans,
            globalResources.GeneralCodes,
          ],
          filterMethod: (code) => {
            if (code.type === globalResources.GeneralCodes) {
              return ['WRKCMP'].includes(code.code)
            }

            return true
          },
        },
        [ToDateEarningsAndTaxesOptions.Include.TaxAndHours]: {
          label: this.$t('Tax/Hours Codes'),
          placeholder: this.$t('Select Tax/Hours Codes'),
          codeTypes: [
            globalResources.TaxCodes,
            globalResources.GeneralCodes,
          ],
          filterMethod: (code) => {
            if (code.type === globalResources.GeneralCodes) {
              return ['HOURSA', 'HOURSB', 'HOURSC', 'WEEKS'].includes(code.code)
            }

            return true
          },
        },
      }
      return attrsMap[this.model.include] || {}
    },
    codeFilterLabel() {
      const labelsMap = {
        [ToDateEarningsAndTaxesOptions.Include.PayCodes]: this.$t('Pay Codes'),
        [ToDateEarningsAndTaxesOptions.Include.DeductionCodes]: this.$t('Deduction Codes'),
        [ToDateEarningsAndTaxesOptions.Include.TaxAndHours]: this.$t('Tax/Hours Codes'),
      }

      return labelsMap[this.model.include] || ''
    }
  }
}
</script>
