<template>
  <base-button v-bind="$attrs"
               v-on="$listeners"
               :variant="variant"
               class="save-button"
  >
    <slot>
      {{ $t('Submit') }}
    </slot>
  </base-button>
</template>
<script>
  export default {
    inheritAttrs: false,
    props: {
      variant: {
        type: String,
        default: 'primary',
      },
    },
  }
</script>
<style>
  .save-button button {
    min-width: 120px;
  }
</style>
