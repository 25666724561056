<template>
  <WorkOrdersTable
      :status="urlParams.status"
      :billed="urlParams.billed"
      :work-site-id="workSiteId"
  />
</template>
<script>
import WorkOrdersTable from "@/modules/service-billing/components/WorkOrdersTable.vue";

export default {
  components: {
    WorkOrdersTable
  },
  props: {
    workSiteId: {
      type: String,
    }
  },
  computed: {
    urlParams() {
      const { billed, status } = this.$route.meta
      return {
        billed,
        status
      }
    },
  },
}
</script>
