<template>
  <div>
    <div class="invoice-details-page">
      <slot name="header">
        <div class="flex justify-between items-center border-b border-gray-200 print:border-none px-4 py-2 print:py-1">
          <div class="flex items-center">
            <h5 class="font-semibold mr-3 print:text-print">
              {{ title }} <template v-if="get(invoice, 'number', '')">#{{ get(invoice, 'number', '') }}</template>
            </h5>
            <slot name="title"></slot>
          </div>
          <div
              v-if="loading"
              class="flex space-x-2"
          >
            <div class="w-16 placeholder-loading h-8"></div>
            <div class="w-16 placeholder-loading h-5"></div>
            <div class="w-16 placeholder-loading h-5"></div>
          </div>
          <slot v-else name="title-right">
            <div class="flex items-center">
              <span v-if="isDue(invoice)" class="text-base print:text-print leading-6 font-semibold text-red-600 mr-2">
                  {{ getDueDaysCount(invoice) }} {{ $t('days due') }}
                </span>
              <template v-if="invoice.gross_amount !== undefined && invoice.paid_amount !== undefined">
                <template v-if="!invoice.is_voided && !hideOpenAmount">
                  <span class="text-base print:text-print leading-6 font-semibold text-gray-900 mr-2">
                    {{ $t('Open Amount: ') }}
                  </span>
                  <div
                      class="bg-gray-900 bg-opacity-5 font-medium rounded-md py-1 px-4 print:px-1 print:text-print mr-3 tracking-wide">
                    {{ $formatPrice(invoice.open_amount) }}
                  </div>
                  <span class="tracking-wide font-medium text-sm print:text-print leading-normal text-gray-400 mr-2">
                    {{ $t('out of') }}
                  </span>
                </template>
                <div class="text-sm print:text-print font-medium tracking-wide">
                  {{ $formatPrice(invoice.net_amount) }}
                </div>
              </template>
            </div>
          </slot>
        </div>
      </slot>
      <div
          class="mt-4 px-6 print:px-4 flex flex-col space-y-4 sm:space-y-0 print:items-end sm:flex-row print:flex-row justify-between relative">
        <div class="grid grid-cols-1 gap-y-4 z-1">
          <div v-if="loading"
               class="flex space-x-2 min-w-[500px]">
            <div class="flex flex-col space-y-2 w-2/3">
              <div class="w-2/3 placeholder-loading h-5"></div>
              <div class="w-full placeholder-loading"></div>
              <div class="w-1/2 placeholder-loading"></div>
              <div class="w-1/2 placeholder-loading"></div>
              <div class="w-1/2 placeholder-loading"></div>
            </div>
            <div class="flex flex-col space-y-2 w-2/3">
              <div class="w-2/3 placeholder-loading h-5"></div>
              <div class="w-full placeholder-loading"></div>
              <div class="w-1/2 placeholder-loading"></div>
              <div class="w-1/2 placeholder-loading"></div>
            </div>
          </div>
          <slot v-else name="header-left"></slot>
        </div>
        <div class="grid grid-cols-1 gap-y-4">
          <InvoiceStamp :invoice="invoice"/>
          <slot name="header-right">
            <div v-if="loading"
                 class="flex-1 w-full grid grid-cols-1 gap-2 min-w-[250px]">
              <div class="w-full placeholder-loading"></div>
              <div class="w-full placeholder-loading"></div>
              <div class="w-full placeholder-loading"></div>
            </div>
            <div v-else>
              <InvoiceHeaderRow
                  v-if="invoice.date"
                  :label="$t('Issued:')"
                  :value="formatDateNoTimezone(invoice.date)"
              />
              <InvoiceHeaderRow
                  v-if="invoice.discount_date"
                  :label="$t('Discount Date:')"
                  :value="formatDateNoTimezone(invoice.discount_date)"
              />
              <InvoiceHeaderRow
                  v-if="invoice.due_date"
                  :label="$t('Due Date:')"
                  :value="formatDateNoTimezone(invoice.due_date)"
              />
              <InvoiceHeaderRow
                  v-if="invoice.work_start_date"
                  :label="$t('Start Date:')"
                  :value="formatDateNoTimezone(invoice.work_start_date)"
              />
              <InvoiceHeaderRow
                  v-if="invoice.work_end_date"
                  :label="$t('End Date:')"
                  :value="formatDateNoTimezone(invoice.work_end_date)"
              />
              <InvoiceHeaderRow
                v-if="invoice?.meta?.work_date_from"
                :label="$t('Service Start Date:')"
                :value="formatDateNoTimezone(invoice?.meta?.work_date_from)"
              />
              <InvoiceHeaderRow
                v-if="invoice?.meta?.work_date_to"
                :label="$t('Service End Date:')"
                :value="formatDateNoTimezone(invoice?.meta?.work_date_to)"
              />
              <div class="print:hidden">
                <InvoiceHeaderRow
                    v-if="get(invoice, 'created_by')"
                    :label="$t('Created By:')"
                >
                  <UserLink
                      :id="get(invoice, 'created_by')"
                      class="tracking-wide text-gray-900"
                  />
                </InvoiceHeaderRow>
                <InvoiceHeaderRow
                    v-if="get(invoice, 'updated_by')"
                    :label="$t('Updated By:')"
                    class="mt-2"
                >
                  <UserLink
                      :id="get(invoice, 'updated_by')"
                      class="tracking-wide text-gray-900"
                  />
                </InvoiceHeaderRow>
                <InvoiceHeaderRow
                    v-if="invoice.updated_at"
                    :label="$t('Last Updated:')"
                    :value="$formatDate(invoice.updated_at, $dateTypes.DateTime, false)"
                    :capitalize="false"
                >
                  <template>
                    {{$formatDate(invoice.updated_at)}}
                    {{formatHours(invoice.updated_at)}}
                  </template>
                </InvoiceHeaderRow>
                <InvoiceHeaderRow
                    v-if="get(invoice, 'voided_by')"
                    :label="$t('Voided By:')"
                    class="mt-2"
                >
                  <UserLink
                      :id="get(invoice, 'voided_by')"
                      class="tracking-wide text-gray-900"
                  />
                </InvoiceHeaderRow>
                <InvoiceHeaderRow
                  v-if="get(invoice, 'purchase_order_id')"
                  :label="$t('Purchase Order')"
                  class="mt-2"
                >
                  <PurchaseOrderLink
                    :data="get(invoice, 'purchaseOrder')"
                    class="tracking-wide text-gray-900"
                  />
                </InvoiceHeaderRow>
                <slot name="header-right-extra"></slot>
              </div>
            </div>
          </slot>
        </div>
      </div>
      <div class="mt-6 print:mt-4">
        <div
            v-if="invoice.meta?.is_initialization"
            class="w-full text-center my-8">
          <h3 class="text-orange-600">{{ $t('Initialized invoice and was not posted to G/L') }}</h3>
        </div>
        <slot name="content">
          <div class="min-h-[100px]"></div>
        </slot>

        <div class="grid grid-cols-3 gap-4 px-4 print:px-0 pb-4 mt-4">
          <div v-if="invoice.rejection_reason" class="col-span-3">
            <base-alert type="danger">
              <div class="flex space-x-2 items-center">
                <span class="font-medium">{{$t('Reject Reason:')}}</span>
                <span>
                  {{ invoice.rejection_reason }}
                </span>
              </div>
            </base-alert>
          </div>
          <slot name="footer">
            <div class="col-span-3 md:col-span-2">
              <slot name="attachments">
                <FileAttachments
                  v-if="!hideAttachments"
                  :entity="entity"
                  :entity-id="invoice?.id"
                />
              </slot>

              <EntityHistory
                  v-if="!hideHistory"
                  :data="invoice"
                  :entity="historyEntity"
              />
            </div>
            <slot name="totals">
              <dl v-if="!loading" class="col-span-3 md:col-span-1 divide-y divide-gray-100">
                <div v-if="invoice.gross_amount !== undefined"
                     class="totals-row bg-gray-50">
                  <span>{{ $t('Gross') }}</span>
                  <span>{{ $formatPrice(invoice.gross_amount) }}</span>
                </div>
                <div v-if="invoice.retention_amount !== undefined"
                     class="totals-row">
                  <span>{{ $t('Retention') }}</span>
                  <span>{{ $formatPrice(invoice.retention_amount) }}</span>
                </div>
                <div v-if="invoice.material_stored_amount !== undefined && invoice.material_stored_amount !== 0"
                     class="totals-row">
                  <span>{{ $t('Material Stored') }}</span>
                  <span>{{ $formatPrice(invoice.material_stored_amount) }}</span>
                </div>
                <div v-if="invoice.sales_tax_amount !== undefined"
                     class="totals-row">
                  <span>{{ $t('Taxes') }}</span>
                  <span>{{ $formatPrice(invoice.sales_tax_amount) }}</span>
                </div>
                <div v-if="invoice.gross_amount !== undefined"
                     class="totals-row">
                  <span>{{ $t('Net') }}</span>
                  <span>{{ $formatPrice(invoice.net_amount) }}</span>
                </div>
                <div v-if="invoice.discount_amount"
                     class="totals-row">
                  <span>{{ $t('Discount Available') }}</span>
                  <span>{{ $formatPrice(invoice.discount_amount) }}</span>
                </div>
                <div v-if="invoice.discount_taken_amount"
                     class="totals-row">
                  <span>{{ $t('Discount Taken') }}</span>
                  <span>{{ $formatPrice(invoice.discount_taken_amount) }}</span>
                </div>
                <div v-if="invoice.discount_given_amount"
                     class="totals-row">
                  <span>{{ $t('Discount Given') }}</span>
                  <span>{{ $formatPrice(invoice.discount_given_amount) }}</span>
                </div>
                <div v-if="invoice.paid_amount !== undefined && !invoice.is_voided"
                     class="totals-row">
                  <span>{{ $t('Payments') }}</span>
                  <span>{{ $formatPrice(paidAmount) }}</span>
                </div>
                <div
                    v-if="invoice.gross_amount !== undefined && invoice.paid_amount !== undefined && !invoice.is_voided && !hideOpenAmount"
                    class="totals-row">
                  <span>{{ $t('Open') }}</span>
                  <span>{{ $formatPrice(invoice.open_amount) }}</span>
                </div>
              </dl>
            </slot>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { defineComponent } from 'vue'
  import InvoiceHeaderRow from '@/components/invoice/InvoiceHeaderRow.vue';
  import InvoiceStamp from '@/components/invoice/InvoiceStamp.vue';
  import { getDueDaysCount, isDue } from '@/modules/accounts-payable/pages/invoices/invoiceUtils';
  import EntityHistory from "@/components/invoice/EntityHistory.vue";
  import { formatDateNoTimezone, formatHours } from "@/plugins/dateFormatPlugin";
  import { InvoiceTypes } from "@/modules/accounts-payable/enum/invoice";
  import { billingTypeAbbr, getBillingTitle } from "@/modules/accounts-receivable/pages/billings/billings";
  import PurchaseOrderLink from "@/components/links/PurchaseOrderLink.vue";
  import { resourceStatuses } from "@/enum/enums";


  const entityTypes = {
    Invoice: 'invoice',
    Invoices: 'invoices',
    Billing: 'billing',
    Billings: 'billings',
    default: 'invoice',
  }

  export default defineComponent({
    name: 'InvoiceDetailsPage',
    components: {
      PurchaseOrderLink,
      InvoiceStamp,
      InvoiceHeaderRow,
      EntityHistory,
    },
    props: {
      data: {
        type: Object,
        required: true,
        default: () => ({}),
      },
      entity: {
        type: String,
        default: entityTypes.Invoices,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      hideAttachments: {
        type: Boolean,
        default: false,
      },
      hideHistory: {
        type: Boolean,
        default: false,
      },
      historyEntity: {
        type: String,
        default: 'Invoice',
      },
      customTitle: {
        type: String,
      }
    },
    computed: {
      invoice() {
        return {
          ...this.data?.attributes || {},
          ...this.data?.relationships || {},
        }
      },
      invoiceType() {
        return this.invoice.type || this.data.type
      },
      title() {
        if (this.customTitle) {
          return this.customTitle
        }
        return getBillingTitle(this.invoiceType)
      },
      isManualInvoice() {
        return this.invoice.type === InvoiceTypes.Manual
      },
      isPending() {
        return [resourceStatuses.Pending, resourceStatuses.NoPost].includes(this.invoice.status)
      },
      hideOpenAmount() {
        return this.isPending && this.isManualInvoice
      },
      paidAmount() {
        if (this.isManualInvoice) {
          return this.invoice.net_amount
        }
        let discountTaken = this.invoice.discount_taken_amount || 0
        if (this.invoice.discount_taken_amount === undefined) {
          discountTaken = this.invoice.discount_given_amount || 0
        }
        return this.invoice.paid_amount - discountTaken
      },
    },
    methods: {
      formatHours,
      formatDateNoTimezone,
      getDueDaysCount,
      isDue,
    },
  })
</script>
