export function convertToRedactorHtml (html) {
  let regex = new RegExp(/([^"])::(.*?)::/, 'g')
  let finalHtml = html.replace(regex, '$1<span class="redactor-component" data-redactor-type="variable" tabindex="-1" contenteditable="false">::$2::</span>')
  return finalHtml
}

export function parseRedactorHtml(html) {
  let regex = new RegExp(/<span data-redactor-type="variable">(.*?)<\/span>/, 'g')
  let regex2 = new RegExp(/<span class="redactor-component" data-redactor-type="variable" tabindex="-1" contenteditable="false">(.*?)<\/span>/, 'g')
  let finalHtml = html.replace(regex, '$1')
  finalHtml = finalHtml.replace(regex2, '$1')
  return finalHtml
}
