<template>
  <PurchaseOrderAdjustForm
    :data="purchaseOrderAdjustment"
    @refresh="getPurchaseOrder"
  />
</template>
<script>
import PurchaseOrderAdjustForm from '@/modules/purchasing-inventory/components/purchase-orders/PurchaseOrderAdjustForm'

export default {
  components: {
    PurchaseOrderAdjustForm,
  },
  computed: {
    purchaseOrderAdjustment() {
      const purchaseOrder = this.$store.state.inventoryManagement.currentPurchaseOrderAdjustment
      return {
        ...purchaseOrder?.attributes,
        ...purchaseOrder?.relationships,
      }
    },
  },
  methods: {
    async getPurchaseOrder() {
      await this.$store.dispatch('inventoryManagement/getPurchaseOrderAdjustment', this.$route.params.id)
    },
  },
}
</script>
