const colors = [
  '#3182ce',
  '#da5ae2',
  '#57d16c',
  '#5575ea',
  '#f76157',
  '#1d4ed2',
  '#efbe53',
  '#b2bccc',
  '#dbdee8'
]
export default colors

export const ChartColors = {
  Blue: '#3182ce',
  Green: '#10b981',
  Cyan: '#0891b2',
  Gray: '#64748b',
  Red: '#ef4444',
  Yellow: '#efbe53',
  Orange: '#f97316',
}
export function getRandomChartColor() {
  const index = Math.floor(Math.random() * colors.length)
  return colors[index] || colors[0]
}

export function getMainColor() {
  return colors[0]
}

export const grayColors = {
  200: '#E5E7EB',
  300: '#D1D5DB',
  400: '#9CA3AF',
  500: '#6B7280',
  600: '#4B5563',
  700: '#374151',
  800: '#1F2937',
  900: '#111827',
}
