<template>
  <base-form
      v-bind="$attrs"
      :loading="loading"
      :layout="layout"
      :save-text="$t('Create employee')"
      :update-text="$t('Update employee')"
      @cancel="$emit('cancel')"
      @submit="onSubmit"
  >
    <div class="col-span-6 md:col-span-3">
      <base-input v-model="model.email"
                  :label="$t('Email')"
                  :placeholder="$t('Employee email')"
                  :tip="$t('This email will be used for receiving checks')"
                  id="email"
                  rules="email">
      </base-input>
    </div>
    <div class="col-span-6 md:col-span-3">
      <base-input v-model="model.name"
                  :label="$t('Name')"
                  :placeholder="$t('Employee name')"
                  id="name"
                  rules="required">
      </base-input>
    </div>
    <div class="col-span-6 md:col-span-3">
      <base-date-picker v-model="model.hire_date"
                        :label="$t('Hire Date')"
                        :placeholder="$t('Hire Date')"
      />
    </div>
    <div class="col-span-6 md:col-span-3">
      <base-date-picker v-model="model.rehire_date"
                        :label="$t('Rehire Date')"
                        :placeholder="$t('Rehire Date')"
      />
    </div>
    <div class="col-span-6 md:col-span-3">
      <base-date-picker v-model="model.dob"
                        :label="$t('Birth Date')"
                        :placeholder="$t('Birth Date')"
      />
    </div>
    <div class="col-span-6 md:col-span-3">
      <base-phone-input v-model="model.phone"
                        :label="$t('Phone Number')"
                        :placeholder="$t('Phone Number')"
      />
    </div>
    <div class="col-span-6 md:col-span-3 flex">
      <ssn-input v-model="model.ssn"
                 :placeholder="$t('SSN')"
                 :label="$t('SSN')"
                 :name="$t('SSN')"
                 class="flex-1"
      >
      </ssn-input>
    </div>
    <div class="col-span-6 md:col-span-3 mb-4">
      <base-switch v-model="model.receive_checks_via_email"
                   :label-info="$t('Receive checks via email')"
      />
    </div>
    <address-list :title="$t('Employee Address')"
                  :entity-id="model.id"
                  :data="addresses"
                  ref="addressList"
                  class="col-span-6"
                  entity="employees"
                  @refresh="$emit('re-fetch-employee', model.id)"
    />
    <employee-users-list  :employee-id="model.id"
                          :data="users"
                          class="col-span-6 mt-6"
                          @remove-user="$emit('re-fetch-employee', model.id)"
    />
    <template #extra-buttons-right>
      <base-tooltip :disabled="!!(!model.user_id && model.email)"
                    :content="$t(`This employee either doesn't have an email or already has an attached user.`)"
      >
        <base-submit-button
            v-if="!model.user_id"
            :loading="createUsersLoading"
            :disabled="!!(model.user_id || !model.email)"
            class="mr-2"
            @click="onAttachUser"
        >
          <IconAdd class="w-5 h-5 mr-2"/>
          {{ $t('Save & Create User') }}
        </base-submit-button>
      </base-tooltip>
    </template>
  </base-form>
</template>
<script>
  import axios from 'axios'
  import SsnInput from '@/components/form/SsnInput'
  import AddressList from '@/modules/common/components/AddressList'
  import EmployeeUsersList from '@/modules/payroll/components/EmployeeUsersList'

  export default {
    inheritAttrs: false,
    components: {
      SsnInput,
      AddressList,
      EmployeeUsersList,
    },
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      layout: {
        type: String,
        default: 'modal',
      },
    },
    data() {
      return {
        loading: false,
        createUsersLoading: false,
        model: {
          email: '',
          name: '',
          dob: null,
          hire_date: null,
          user_id: null,
          rehire_date: null,
          ssn: null,
          receive_checks_via_email: true,
        },
        addresses: [],
        users: [],
      }
    },
    methods: {
      async onSubmit(goToNext = true, attachUser = false) {
        try {
          this.$set(this.data, 'loading', true)
          this.loading = !this.createUsersLoading
          await axios.put(`/restify/employees/${this.model.id}`, {
            ...this.model,
          })

          if (goToNext) {
            this.$emit('re-fetch-employee', this.model.id, () => {
              this.goToNext()
            })
          }
          if (attachUser) {
            this.$emit('attach-user')
          }
        } catch (err) {
          this.$error(this.$t('Could not update the employee information'))
        } finally {
          this.$set(this.data, 'loading', false)
          this.loading = this.createUsersLoading = false
        }
      },
      goToNext() {
        this.$emit('on-next')
      },
      async onAttachUser() {
        this.createUsersLoading = true
        await this.onSubmit(false, true)
      },
    },
    watch: {
      data: {
        immediate: true,
        handler(employee) {
          if (!employee?.id) {
            return
          }

          this.model = {
            ...this.model,
            ...employee.attributes,
          }

          this.users = employee?.relationships?.user ? [employee?.relationships?.user]: []

          this.addresses = employee?.relationships?.addresses || []
        },
      },
    },
  }
</script>
