<template>
  <base-button variant="gray-link"
               class="print:hidden"
               v-bind="$attrs"
               @click="onClick">
    <ShareIcon class="w-4 h-4 mr-2"/>
    <slot>
      {{ $t('Share') }}
    </slot>
  </base-button>
</template>
<script>
  import { ShareIcon } from 'vue-feather-icons'

  export default {
    name: 'TableShareButton',
    components: {
      ShareIcon,
    },
    props: {
      link: String,
    },
    methods: {
      onClick() {
        const link = this.link || window.location.href
        this.copyToClipboard(link)
        this.$success(this.$t('Link copied to clipboard'))
      },
      copyToClipboard(text) {
        return navigator.clipboard.writeText(text)
      }
    }
  }
</script>
