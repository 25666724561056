import axios from "axios";

const apiKey = import.meta.env.VITE_MAPS_API_KEY
export async function getAddressFromCoordinates(lat, long) {
  const instance = axios.create()
  const response = await instance.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
    params: {
      latlng: `${lat},${long}`,
      key: apiKey
    }
  })
  const result = response.data.results[0];
  const addressComponents = result.address_components;
  return parseAddressComponent(addressComponents);
}

export function parseAddressComponent(addressComponents) {
  return {
    address_1: findAddressComponent(addressComponents, 'street_address') || findAddressComponent(addressComponents, 'route'),
    city: findAddressComponent(addressComponents, 'locality'),
    state: findAddressComponent(addressComponents, 'administrative_area_level_1', 'short_name'),
    postal_code: findAddressComponent(addressComponents, 'postal_code'),
  }
}

export function findAddressComponent(addressComponents, type, field = 'long_name') {
  return addressComponents.find(component => component.types.includes(type))?.[field]
}
