<template>
  <BaseCard class="border">
    <div class="text-sm flex flex-col space-y-4">
      <div :title="$t('Number')"
           class="detail-row">
        <HashIcon class="detail-icon"/>
        <div>{{ jobData.number }}</div>
        <StatusBadge :status="jobData.status"/>
      </div>
      <div :title="$t('Details')"
           class="detail-row">
        <BookOpenIcon class="detail-icon"/>
        <div>{{ jobData.description }}</div>
      </div>
      <div :title="$t('Category')"
           class="detail-row">
        <BoxIcon class="detail-icon"/>
        <router-link
            v-if="jobData.category_id"
            :to="`/job-costing/settings/categories/${jobData.category_id}/edit`"
        >
          {{ get(job, 'relationships.category.attributes.code') }}
        </router-link>
        <span v-else>- -</span>
      </div>
    </div>
  </BaseCard>
</template>
<script lang="ts" setup>
  import { BookOpenIcon, BoxIcon, HashIcon } from 'vue-feather-icons'
  import { computed, PropType } from "vue";
  import { Job } from "@/modules/common/types/models";
  import Data = API.Data;

  const props = defineProps({
    job: {
      type: Object as PropType<Data<Job>>,
      default: () => ({}),
      required: true,
    }
  })

  const jobData = computed<Job>(() => {
    return props.job?.attributes || {}
  })
</script>
