import VueRouter from 'vue-router'
import authRoutes from '@/modules/auth/routes'
import publicRoutes from '@/modules/public/routes'
import reportPrintRoutes from '@/modules/print/routes'
import wikiRoutes from '@/modules/wiki/routes'
import dashboardRoutes from '@/modules/dashboard/routes'

const routes = [
  ...authRoutes,
  ...publicRoutes,
  ...reportPrintRoutes,
  ...wikiRoutes,
  ...dashboardRoutes
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior: (to, from ,savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  }

})

function handleRouterError(error) {
  const message = error?.message || ''
  if (!(message?.includes('redundant navigation'))) {
    throw error;
  }
}

const originalPush = router.push
const originalReplace = router.replace

router.push = function push(location) {
  return originalPush.call(this, location).catch(handleRouterError)
}

router.replace = function replace(location) {
  return originalReplace.call(this, location).catch(handleRouterError)
}

export default router
