<template>
  <base-form
      :update-text="$t('Update overrides')"
      :loading="loading"
      :focus-on-first-input="false"
      layout="modal"
      grid-classes="grid grid-cols-12 gap-x-3"
      @submit="onApply"
  >
    <div class="col-span-12 md:col-span-3">
      <union-select v-model="model.union_id"
                    :label-class="getLabelClass(model.union_from)"
                    :initial-value="model.union"
                    :add-entity="false"
                    id="union_id"
                    clearable
                    @change="onUnionChange"
      />
    </div>
    <div class="col-span-12 md:col-span-3">
      <craft-select v-model="model.craft_code_id"
                    :label-class="getLabelClass(model.craft_code_from)"
                    :initial-value="model.craftCode"
                    :label="$t('Craft/Level')"
                    :add-entity="false"
                    key="craft_code_id"
                    id="craft_code_id"
                    clearable
                    :rules="model.union_id ? 'required': ''"
                    @change="onCraftCodeChange"
      />
    </div>
    <div class="col-span-12 md:col-span-2">
      <sub-trade-select
          v-model="model.sub_trade_id"
          :initial-value="model.subTrade"
          :label="$t('Sub Trade')"
          :add-entity="false"
          :disabled="!model.craft_code_id"
          :craft-code-id="model.craft_code_id"
          :allow-selection-without-craft-code="false"
          key="sub_trade_id"
          id="sub_trade_id"
          clearable
          @change="onSubTradeChange"
      />
    </div>
    <div class="col-span-12 md:col-span-3">
      <base-switch v-model="model.is_certified_payroll_exempt"
                   :label-class="getLabelClass(model.certified_payroll_exempt_from)"
                   :label-info="$t('Certified P/R Exempt')"
                   @change="onCertifiedPayrollChange"
      />
    </div>
    <div class="hidden md:flex col-span-12 md:col-span-1"/>
    <div class="col-span-12 md:col-span-3">
      <base-input v-model="model.special_rate"
                  :label="$t('Special Pay/Ded Rate')"
                  :label-class="getLabelClass(model.special_rate_from)"
                  :name="$t('Special Rate')"
                  :placeholder="$t('Special Rate')"
                  :min="0"
                  :step="0.01"
                  type="number"
                  format="price"
                  id="special_rate"
                  rulse="min_value:0|max_value:9999.99"
                  @change="onSpecialRateChange"
      />
    </div>
    <div class="col-span-12 md:col-span-3">
      <base-input v-model="model.regular_rate"
                  :label="$t('Regular Rate')"
                  :label-class="getLabelClass(model.regular_rate_from)"
                  :name="$t('Regular Rate')"
                  :placeholder="$t('Regular Rate')"
                  :min="0"
                  :step="0.01"
                  id="regular_rate"
                  type="number"
                  format="price"
                  rules="min_value:0|max_value:9999.99"
                  @change="computeNextRates"
                  @blur="computeNextRates"
      />
    </div>
    <div class="col-span-12 md:col-span-3">
      <base-input v-model="model.overtime_rate"
                  :label="$t('Overtime Rate')"
                  :label-class="getLabelClass(model.overtime_rate_from)"
                  :name="$t('Overtime Rate')"
                  :placeholder="$t('Overtime Rate')"
                  :min="0"
                  :step="0.01"
                  id="overtime_rate"
                  type="number"
                  format="price"
                  rules="min_value:0|max_value:9999.99"
                  @change="onOvertimeChange"
      />
    </div>
    <div class="col-span-12 md:col-span-3">
      <base-input v-model="model.premium_rate"
                  :label="$t('Premium Rate')"
                  :label-class="getLabelClass(model.premium_rate_from)"
                  :name="$t('Premium Rate')"
                  :placeholder="$t('Premium Rate')"
                  :min="0"
                  :step="0.01"
                  id="premium_rate"
                  type="number"
                  format="price"
                  rules="min_value:0|max_value:9999.99"
                  @change="onPremiumChange"
      />
    </div>
    <div class="col-span-12 md:col-span-3">
      <state-tax-select
          v-model="model.withholding_state_id"
          :label-class="getLabelClass(model.withholding_state_from)"
          :label="$t(stateTaxLabel)"
          :name="$t(stateTaxLabel)"
          :placeholder="$t(stateTaxLabel)"
          id="withholding_state"
          clearable
          @change="onWithholdingStateChange"
      />
    </div>
    <div
        v-if="useMultipleStateTaxes"
        class="col-span-12 md:col-span-3"
    >
      <state-tax-select
          v-model="model.sui_sdi_state_id"
          :label-class="getLabelClass(model.sui_sdi_state_from)"
          :label="$t('SUI/SDI State')"
          :name="$t('SUI/SDI State')"
          :placeholder="$t('SUI/SDI State')"
          id="sui_sdi_state"
          clearable
          @change="onSuiSdiStateChange"
      />
    </div>
    <div class="col-span-12 md:col-span-3">
      <local-tax-select
          v-model="model.withholding_local_id"
          :label-class="getLabelClass(model.withholding_local_from)"
          :label="$t(localTaxLabel)"
          :name="$t(localTaxLabel)"
          :placeholder="$t(localTaxLabel)"
          id="withholding_local"
          clearable
          @change="onWithholdingLocalChange"
      />
    </div>
    <div
        v-if="useMultipleLocalTaxes"
        class="col-span-12 md:col-span-3"
    >
      <local-tax-select
          v-model="model.withholding_local_2_id"
          :label-class="getLabelClass(model.withholding_local_2_from)"
          :label="$t('Local 2')"
          :name="$t('Local 2')"
          :placeholder="$t('Local 2')"
          id="withholding_local_2"
          clearable
          @change="onWithholdingLocalTwoChange"
      />
    </div>
    <div class="col-span-12 md:col-span-3">
      <worker-comp-rate-select
          v-model="model.workers_comp_rate_code"
          :label-class="getLabelClass(model.workers_comp_rate_from)"
          :rules="getWorkersCompValidationRules"
          id="workers_comp_rate_code"
          @change="onWorkersCompRateChange"
      />
    </div>
    <div class="col-span-12 md:col-span-3">
      <gen-liability-rate-select v-model="model.gen_liability_rate_code"
                                 :label-class="getLabelClass(model.gen_liability_rate_from)"
                                 id="gen_liability_rate_code"
                                 clearable
                                 @change="onGenLiabilityRateChange"
      />
    </div>
    <div class="col-span-12 md:col-span-3">
      <account-select v-model="model.account"
                      :label="$t('Expense Account')"
                      :label-class="getLabelClass(model.account_from)"
                      :name="$t('Expense Account')"
                      :placeholder="$t('Expense Account')"
                      value-key="number"
                      id="account"
                      rules="required"
                      @change="onAccountChange"
      />
    </div>
    <div class="col-span-12 md:col-span-3">
      <sub-account-select v-model="model.subaccount"
                          :label="$t('Expense Sub-Account')"
                          :label-class="getLabelClass(model.subaccount_from)"
                          id="subaccount"
                          value-key="number"
                          @change="onSubAccountChange"
      />
    </div>
    <div class="col-span-12 md:col-span-3">
      <account-select v-model="model.special_account"
                      :label="$t('Special Account')"
                      :label-class="getLabelClass(model.special_account_from)"
                      id="special_account"
                      value-key="number"
                      @change="onSpecialAccountChange"
      />
    </div>
    <div class="col-span-12 md:col-span-3">
      <sub-account-select v-model="model.special_subaccount"
                          :label-class="getLabelClass(model.special_subaccount_from)"
                          :label="$t('Special Sub-Account')"
                          id="special_subaccount"
                          value-key="number"
                          @change="onSpecialSubAccountChange"
      />
    </div>
    <div class="col-span-12">
      <span class="text-sm text-gray-500">{{ $t('Fields marked with bolder text color were overwritten by the user.') }}</span>
    </div>
    <template #extra-buttons-right>
      <BaseButton
          variant="primary-light"
          class="mr-2"
          @click="resetOverrides"
      >
        {{ $t('Reset Overrides') }}
      </BaseButton>
    </template>
  </base-form>
</template>
<script>
  import { ratesEnum } from '@/enum/enums'
  import CraftSelect from '@/components/select/entities/CraftSelect'
  import SubTradeSelect from '@/components/select/entities/SubTradeSelect.vue'
  import { codeTypes } from '@/modules/payroll/components/rates/util'
  import StateTaxSelect from "@/components/select/entities/StateTaxSelect";
  import LocalTaxSelect from "@/components/select/entities/LocalTaxSelect";
  import { getRates } from "@/modules/payroll/components/timecard/OverrideController";
  import { RateFrom } from "@/modules/payroll/utils/timeCardUtils";

  export default {
    components: {
      CraftSelect,
      SubTradeSelect,
      StateTaxSelect,
      LocalTaxSelect,
    },
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      employeeId: {
        type: String,
      }
    },
    data() {
      return {
        loading: false,
        model: {
          union_id: undefined,
          craft_code_id: undefined,
          sub_trade_id: undefined,
          withholding_local_id: undefined,
          withholding_local_2_id: undefined,
          withholding_state_id: undefined,
          sui_sdi_state_id: undefined,
          is_certified_payroll_exempt: false,
          regular_rate: 0,
          overtime_rate: 0,
          premium_rate: 0,
          special_rate: 0,
          workers_comp_rate_code: '',
          gen_liability_rate_code: '',
          account: '',
          subaccount: '',
          special_account: '',
          special_subaccount: '',
        },
      }
    },
    computed: {
      getWorkersCompValidationRules() {
        return [codeTypes.PAY, codeTypes.BEN].includes(this.data.special_source_type) ? 'required' : ''
      },
      stateTaxLabel() {
        return this.useMultipleStateTaxes ? this.$t('Withholding State') : this.$t('State')
      },
      localTaxLabel() {
        return this.useMultipleLocalTaxes ? this.$t('Local 1') : this.$t('Local')
      },
      useMultipleLocalTaxes() {
        return this.$store.getters['company/getCompanyUseMultipleLocalTaxes']
      },
      useMultipleStateTaxes() {
        return this.$store.getters['company/getCompanyUseMultipleStateTaxes']
      },
    },
    methods: {
      onApply() {
        this.loading = true
        this.$emit('submit', this.model, () => {
          this.loading = false
        })
      },
      getLabelClass(field) {
        if (field === RateFrom.UserDefined) {
          return 'font-semibold text-gray-900'
        }
        return undefined
      },
      async computeNextRates() {
        await this.$nextTick()
        let regularRate = +this.model.regular_rate || 0
        this.model.overtime_rate = regularRate * ratesEnum.OvertimeMultiplier
        this.model.premium_rate = regularRate * ratesEnum.PremiumMultiplier

        this.model.regular_rate_from = RateFrom.UserDefined
        this.model.overtime_rate_from = RateFrom.UserDefined
        this.model.premium_rate_from = RateFrom.UserDefined
      },
      async onOvertimeChange() {
        await this.$nextTick()
        this.model.overtime_rate_from = RateFrom.UserDefined
      },
      async onPremiumChange() {
        await this.$nextTick()
        this.model.premium_rate_from = RateFrom.UserDefined
      },
      async computeRates() {
        await this.$nextTick()
        const data = {
          ...this.data,
          ...this.model,
          employee_id: this.employeeId,
        }
        const overrides = await getRates(data)
        this.model.regular_rate = overrides.regular_rate
        this.model.overtime_rate = overrides.overtime_rate
        this.model.premium_rate = overrides.premium_rate
      },
      async onUnionChange() {
        await this.computeRates()
        this.model.union_from = RateFrom.UserDefined
      },
      async onCraftCodeChange(craftCode) {
        await this.computeRates()
        this.model.craft_code_from = RateFrom.UserDefined
        if (!craftCode) {
          this.model.sub_trade_id = undefined
        }
      },
      async onSubTradeChange() {
        await this.computeRates()
        this.model.sub_trade_from = RateFrom.UserDefined
      },
      onCertifiedPayrollChange() {
        this.model.certified_payroll_exempt_from = RateFrom.UserDefined
      },
      onSpecialRateChange() {
        this.model.special_rate_from = RateFrom.UserDefined
      },
      onWithholdingStateChange() {
        this.model.withholding_state_from = RateFrom.UserDefined
      },
      onSuiSdiStateChange() {
        this.model.sui_sdi_state_from = RateFrom.UserDefined
      },
      onWithholdingLocalChange() {
        this.model.withholding_local_from = RateFrom.UserDefined
      },
      onWithholdingLocalTwoChange() {
        this.model.withholding_local_2_from = RateFrom.UserDefined
      },
      onWorkersCompRateChange() {
        this.model.workers_comp_rate_from = RateFrom.UserDefined
      },
      onGenLiabilityRateChange() {
        this.model.gen_liability_rate_from = RateFrom.UserDefined
      },
      onAccountChange() {
        this.model.account_from = RateFrom.UserDefined
      },
      onSubAccountChange() {
        this.model.subaccount_from = RateFrom.UserDefined
      },
      onSpecialAccountChange() {
        this.model.special_account_from = RateFrom.UserDefined
      },
      onSpecialSubAccountChange() {
        this.model.special_subaccount_from = RateFrom.UserDefined
      },
      async resetOverrides() {
        const confirmed = await this.$confirm({
          title: this.$t('Reset Overrides'),
          description: this.$t('This action will reset all overrides to their default values. Are you sure?'),
        })
        if (!confirmed) {
          return
        }

        this.model.regular_rate_from = RateFrom.Undefined
        this.model.overtime_rate_from = RateFrom.Undefined
        this.model.premium_rate_from = RateFrom.Undefined
        this.model.union_from = RateFrom.Undefined
        this.model.craft_code_from = RateFrom.Undefined
        this.model.certified_payroll_exempt_from = RateFrom.Undefined
        this.model.special_rate_from = RateFrom.Undefined
        this.model.withholding_state_from = RateFrom.Undefined
        this.model.withholding_local_from = RateFrom.Undefined
        this.model.withholding_local_2_from = RateFrom.Undefined
        this.model.sui_sdi_state_from = RateFrom.Undefined
        this.model.workers_comp_rate_from = RateFrom.Undefined
        this.model.gen_liability_rate_from = RateFrom.Undefined
        this.model.account_from = RateFrom.Undefined
        this.model.subaccount_from = RateFrom.Undefined
        this.model.special_account_from = RateFrom.Undefined

        this.$emit('reset', this.model, () => {
          this.loading = false
        })
      }
    },
    watch: {
      data: {
        immediate: true,
        handler(value) {
          this.model = {
            ...this.model,
            ...value,
          }
        },
      },
    },
  }
</script>
