<template>
  <base-form layout="vertical"
             :save-text="$t('Update')"
             :loading="loading"
             class="subcontract-progress_form"
             grid-classes="grid grid-cols-8 gap-x-3"
             @submit="onSubmit"
  >
    <div class="col-span-8 md:col-span-3 flex">
      <base-switch v-model="selectedVendor.default_all_pay_to_dd">
        <template v-slot:label-info>
          <div class="flex items center text-sm font-medium leading-5 text-gray-700 mb-3">
            <span class="whitespace-nowrap">
              {{ $t('Direct Deposit') }}
            </span>
            <span class="ml-1">
              <base-tooltip :content="$t('Default all payments to this Direct Deposit Account')">
                <IconInfo class="text-gray-500 hover:text-primary-500 cursor-help"/>
              </base-tooltip>
            </span>
          </div>
        </template>
      </base-switch>
    </div>
    <div class="col-span-8 md:col-span-1"/>
    <div class="col-span-8 md:col-span-4 mb-3 md:mb-6">
      <VendorInfo v-if="vendor.id"
                  :key="vendor.id"
                  :vendor="vendor"
      />
    </div>
    <div class="col-span-8 md:col-span-4 lg:col-span-2">
      <base-select v-model="selectedVendor.dd_status"
                   :options="priorityOptions"
                   :label="$t('Priority')"
                   tip="Select the Current Status of this Direct Deposit setup. Choose either Active, P/Note, or Inactive (if Direct Deposit is no longer valid for this vendor."
                   id="priority"
                   rules="required"
      />
    </div>
    <div class="col-span-8 md:col-span-4 lg:col-span-2">
      <base-select v-model="selectedVendor.dd_account_type"
                   :options="bankAccountTypes"
                   :disabled="inactiveStatus"
                   id="dd_account_type"
                   :label="$t('Type')"
                   :name="$t('Type')"
                   :rules="inactiveStatus ? '' : 'required'"
      />
    </div>
    <div class="col-span-8 md:col-span-4 lg:col-span-2">
      <base-input v-model="selectedVendor.dd_routing_number"
                  :name="$t('Transit Routing')"
                  :placeholder="$t('Transit Routing')"
                  :label="$t('Transit Routing')"
                  id="dd_routing_number"
                  type="number"
                  :disabled="inactiveStatus"
                  :rules="transitRoutingRules"
      />
    </div>
    <div class="col-span-8 md:col-span-4 lg:col-span-2">
      <base-input v-model="selectedVendor.dd_account_number"
                  :name="$t('Account')"
                  :placeholder="$t('Vendor`s bank account')"
                  :label="$t('Account')"
                  :disabled="inactiveStatus"
                  type="number"
                  id="dd_account_number"
                  :rules="accountNumberRules"
      />
    </div>
  </base-form>
</template>
<script>
  import axios from 'axios'
  import { bankAccountTypes } from '@/enum/enums'
  import VendorSelect from '@/components/select/entities/VendorSelect'
  import VendorInfo from '@/modules/accounts-payable/components/vendor-history/VendorInfo'

  export default {
    components: {
      VendorInfo,
      VendorSelect,
    },
    data() {
      return {
        bankAccountTypes,
        loading: false,
        priorityOptions: [
          {
            label: this.$t('Active'),
            value: 'active',
          },
          {
            label: this.$t('Pre notification'),
            value: 'pre_note',
          },
          {
            label: this.$t('Inactive'),
            value: 'inactive',
          },
        ],
        selectedVendor: {
          dd_status: 'active',
          dd_account_type: 'checking',
          dd_routing_number: '',
          dd_account_number: '',
          default_all_pay_to_dd: false,
        },
      }
    },
    computed: {
      vendorID() {
        return this.$route.params.id
      },
      vendor() {
        return this.$store.state.accountsPayable.currentVendor
      },
      inactiveStatus() {
        return this.selectedVendor.dd_status === 'Inactive'
      },
      accountNumberRules() {
        return this.inactiveStatus ? '' : 'max:17|required'
      },
      transitRoutingRules() {
        return this.inactiveStatus ? '' : 'required|routingNumber'
      },
    },
    methods: {
      async onSubmit() {
        try {
          this.loading = true
          if (!this.vendor.id) {
            return
          }
          await axios.put(`restify/vendors/${this.vendor.id}`, this.selectedVendor)
          this.$success(this.$t('Vendor updated successfully.'))
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Something went wrong. Please try again.'))
        } finally {
          this.loading = false
        }
      },
    },
    watch: {
      vendor: {
        immediate: true,
        handler(data) {
          if (!data?.id) {
            return
          }
          this.selectedVendor = {
            ...this.selectedVendor,
            ...(data?.attributes || {}),
          }
        }
      }
    }
  }
</script>
