import store from '@/store';
import Vue from 'vue';

export const RoleScopes = {
  Company: 'company',
  Organization: 'organization',
}

let app = new Vue({
  store,
  computed: {
    permissions() {
      return this.$store.state.auth.permissions
    },
  },
  methods: {
    can(permissions, roleScope) {
      if (typeof permissions === 'string') {
        permissions = [permissions]
      }
      let userPermissions = this.$store.getters['auth/permissions']
      if (roleScope === RoleScopes.Company) {
        userPermissions = this.$store.getters['auth/companyPermissions']
      } else if (roleScope === RoleScopes.Organization) {
        userPermissions = this.$store.getters['auth/organizationPermissions']
      }
      return permissions.every(permission => userPermissions.includes(permission))
    }
  }
});
export default {
  install(Vue) {
    Vue.prototype.$can = app.can;
    Vue.prototype.$roleScopes = RoleScopes;
  }
}

export const can = app.can;
