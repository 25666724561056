<template>
  <div class="mt-6">
    <AgDataTable
        v-if="tableData.length || loading"
        :columns="columns"
        :data="tableData"
        :data-loading="loading"
        :compact="true"
        :no-borders="true"
        :pagination="false"
        :show-pagination="false"
        dom-layout="autoHeight"
        id="gridTable"
        ref="table"
    >
      <template #id="{row}">
        <JobLink :data="row.job"/>
      </template>
      <template #customer="{row}">
        <CustomerLink
            :data="row.customer"
            :show-name="true"
        />
      </template>
    </AgDataTable>
    <base-alert
        v-else
        :type="$promptType.Warning"
        class="col-span-4"
    >
      {{ $t('No selections, click to Proceed button to access edit Cost Plus Invoices Page.') }}
    </base-alert>
  </div>
</template>
<script>
  import { getCellClasses, validateTableData } from '@/components/ag-grid/columnUtils'
  import { billingTypeAbbr, getNextBillingNumber } from '@/modules/accounts-receivable/pages/billings/billings'
  import axios from "axios";

  export default {
    props: {
      storageKey: {
        type: String,
        default: 'selectedCostsForBilling',
      },
    },
    data() {
      return {
        loading: false,
        tableData: [],
        columns: [
          {
            headerName: this.$t('Job'),
            field: 'id',
            cellClass: 'readonly-ag-cell',
          },
          {
            headerName: this.$t('Customer'),
            field: 'customer',
            cellClass: 'readonly-ag-cell',
          },
          {
            headerName: this.$t('Invoice Number'),
            field: 'number',
            editable: true,
            cellClass: getCellClasses,
          },
        ],
      }
    },
    methods: {
      getDataFromStorage() {
        return JSON.parse(localStorage.getItem(this.storageKey))
      },
      mapTableData(jobs, numbersArray) {
        Object.keys(jobs).forEach(((jobId, index) => {
          const job = jobs[jobId]
          const billingNumber = numbersArray[index]
          this.tableData.push({
            id: job.id,
            job,
            customer: job.customer,
            number: billingNumber || getNextBillingNumber(billingTypeAbbr.CostPlus, job),
          })
        }))
      },
      async formatPayload() {
        const data = this.getDataFromStorage()
        if (!data) {
          return
        }
        const { jobs: jobs = {}, actions: actions = {} } = data

        if (!Object.keys(actions)?.length) {
          return
        }

        this.loading = true
        try {
          const billingNumberItems = []

          Object.keys(jobs).forEach(jobId => {
            const job = jobs[jobId]

            billingNumberItems.push({
              job_id: job?.id,
              job_number: job?.number,
              customer_id: job.customer_id,
            })
          })

          const response = await axios.post(`/restify/billings/actions?action=suggest-cost-plus-billing-numbers`, {
            items: billingNumberItems
          })
          const numbersArray = Object.values(response?.data?.numbers || {})

          this.mapTableData(jobs, numbersArray)
        } catch (err) {
          this.mapTableData(jobs, [])
        } finally {
          this.loading = false
        }
      },
      tryToCreateBillings() {
        const valid = validateTableData()

        if (!valid) {
          this.$error(this.$t('Please fill in the required fields'))
          return false
        }

        const data = this.getDataFromStorage()

        if (!data) {
          return false
        }

        const { jobs: jobs = {}, actions: actions = {} } = data

        if (!Object.keys(actions).length) {
          return {}
        }

        let result = {
          create: [],
          remove: [],
          billing_numbers: [],
        }

        Object.keys(actions).forEach(transactionId => {
          const action = actions[transactionId]
          result[action].push(transactionId)
        })

        this.tableData.forEach(row => {
          result.billing_numbers.push({
            job_id: row.job.id,
            customer_id: row.job.customer_id,
            number: row.number,
          })
        })

        return result
      },
    },
    mounted() {
      this.formatPayload()
    },
  }
</script>
