<template>
  <WorkOrdersTable
      :customer-id="$route.params.id"
  />
</template>
<script>
import WorkOrdersTable from "@/modules/service-billing/components/WorkOrdersTable.vue";
import { resourceStatuses } from "@/enum/enums";

export default {
  components: {
    WorkOrdersTable,
  },
}
</script>
