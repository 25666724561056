<template>
  <base-filter-range :class="gridClass">
    <template #start>
      <base-input
          :value="from"
          :add-entity="false"
          :name="$t('From')"
          :placeholder="$t('From')"
          :rules="{
            'max_value': maxVal
          }"
          class="w-1/2"
          type="number"
          label=""
          @change="onChangeFromValue"
      />
    </template>
    <template #end>
      <base-input
          :value="to"
          :add-entity="false"
          :name="$t('To')"
          :placeholder="$t('To')"
          :rules="{
            'min_value': from,
            'max_value': maxVal,
          }"
          class="w-1/2"
          type="number"
          label=""
          @change="onChangeToValue"
      />
    </template>
  </base-filter-range>
</template>
<script>
  export default {
    props: {
      from: [Number, String],
      to: [Number, String],
      gridClass: {
        type: String,
        default: 'col-span-4',
      },
      maxVal: {
        type: Number,
        default: 1000000000,
      },
      nullable: {
        type: Boolean,
        default: false,
      }
    },
    methods: {
      onChangeFromValue(value) {
        value = this.nullable ? (value || null) : value

        this.$emit('update:from', value)
      },
      onChangeToValue(to) {
        to = this.nullable ? (to || null) : to

        this.$emit('update:to', to)
      },
    },
  }
</script>
