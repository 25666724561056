<template>
  <div class="grid gap-4 print:gap-2 print:grid-cols-4 payroll-line-items"
       :class="{
          'grid-cols-4': categoryCount.length >= 4,
          'grid-cols-3': categoryCount.length === 3,
          'grid-cols-2': categoryCount.length === 2,
       }"
  >
    <div v-for="(category, key) in defaultCategories"
         :key="key"
         class="col-span-4 md:col-span-2 lg:col-span-1 flex flex-col justify-between line-item-category"
    >
      <BaseDataTable
          :columns="getColumns(key)"
          :data="getEntries(key)"
          :show-pagination="false"
          :limit-max-height="false"
          :compact="true"
          has-summary
          force-summary
          class="payroll-line-items-table"
      >
        <template #empty>
          <div class="text-xs print:text-xxs text-left">
            {{ $t(`No ${getCategoryName(key)}`) }}
          </div>
        </template>
        <template #special_source.description="{row}">
          <span>{{ row.special_source.description }}</span>
          <BaseTooltip v-if="isAdjusted(row)">
            <template #content>
              <div>{{ $t(`This entry was manually adjusted from.`) }}</div>
            </template>
            <span class="ml-1 font-medium">*</span>
          </BaseTooltip>
        </template>
        <template #amount="{row}">
          <div class="flex flex-wrap space-x-2 justify-end">
            <BaseTooltip
              v-if="isAdjusted(row)"
              :content="$t('Amount before adjustments')"
              class="print:hidden"
            >
              <span class="text-gray-500">
                {{ $formatPrice(row.amount - row.adjusted_amount) }} →
              </span>
            </BaseTooltip>
            <span>{{ $formatPrice(row.amount) }}</span>
          </div>

        </template>
        <template #special_source.description_summary="{row}">
          <span class="text-sm print:text-print">
            {{ getCategoryName(key) }}
          </span>
        </template>
        <template #amount_summary="{row}">
          <span class="text-sm print:text-print font-medium">
            {{ $formatPrice(getTotals(key)?.amount || 0) }}
          </span>
        </template>
        <template #current_ytd_amount_summary="{row}">
          <span class="text-sm print:text-print font-medium">
            {{ $formatPrice(getTotals(key)?.current_ytd_amount) }}
          </span>
        </template>
      </BaseDataTable>
    </div>
  </div>
</template>
<script>
  import { payCategories } from '@/modules/payroll/components/timecard/util'
  import i18n from "@/i18n";

  const defaultCategories = {
    [payCategories.GrossPay]: i18n.t('Gross pay'),
    [payCategories.EmployeeTaxes]: i18n.t('Tax Deductions'),
    [payCategories.Deductions]: i18n.t('Other Deductions'),
    [payCategories.NetPay]: i18n.t('Net Pay'),
    [payCategories.Benefits]: i18n.t('Benefits'),
    [payCategories.EmployerTaxes]: i18n.t('Employer Taxes'),
    [payCategories.Insurance]: i18n.t('Insurance'),
    [payCategories.NonPayroll]: i18n.t('Non Payroll'),
  }

  export default {
    inheritAttrs: false,
    props: {
      data: {
        type: Object,
        default: () => [],
      },
      showYearToDate: {
        type: Boolean,
        default: false,
      },
      displayCategories: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      defaultCategories() {
        if (this.displayCategories.length) {
          const filteredCategories = {}
          this.displayCategories.forEach(category => {
            category.keys.forEach(key => {
              filteredCategories[key] = defaultCategories[key]
            })
          })
          return filteredCategories
        }
        return defaultCategories
      },
      categories() {
        return this.data?.categories || {}
      },
      categoryCount() {
        return Object.values(this.defaultCategories)
      }
    },
    methods: {
      getCategoryName(key) {
        return defaultCategories[key] || ''
      },
      getSpecialSourceDescription(row) {
        const sourceId = row.attributes.special_source_id
        const description = this.$store.getters['globalLists/getSpecialSourceDescription'](sourceId)
        return description || row.attributes.special_source_code
      },
      isAdjusted(row) {
        return row?.was_adjusted
      },
      getTotals(categoryKey) {
        return this.categories[categoryKey]?.totals || {}
      },
      getEntries(categoryKey) {
        return this.categories[categoryKey]?.entries || []
      },
      getColumns(categoryKey) {
        const categoryName = this.getCategoryName(categoryKey)
        const columns = [
          {
            label: categoryName,
            prop: 'special_source.description',
            minWidth: 60,
            maxWidth: 150,
            verticalAlign: 'top',
          },
          {
            label: this.$t('Hrs'),
            prop: 'quantity',
            minWidth: 40,
            maxWidth: 100,
            verticalAlign: 'top',
            renderIf: () => categoryName === this.$t('Gross pay')
          },
          {
            label: this.$t('Amount'),
            prop: 'amount',
            minWidth: 80,
            maxWidth: 150,
            verticalAlign: 'top',
            component: 'FormattedPrice',
            align: 'right',
          },
          {
            label: this.$t('To-Date'),
            prop: 'current_ytd_amount',
            minWidth: 80,
            maxWidth: 150,
            component: 'FormattedPrice',
            align: 'right',
            verticalAlign: 'top',
            renderIf: () => this.showYearToDate,
          },
        ]
        return columns.filter(column => column.renderIf ? column.renderIf() : true)
      },
    },
  }
</script>
<style lang="scss">
  .payroll-line-items {
    --row-height: 29px;
    @media print {
      --row-height: 22px;
    }
    .line-item-category {
      padding-bottom: var(--row-height);
      height: calc(100% + var(--row-height));
    }
    .payroll-line-items-table {
      @apply relative h-full;
    }
    .table-wrapper {
      @apply h-full min-h-full;
    }
    .table-container {
      @apply border-none h-full;
    }

    .summary-row {
      @apply absolute w-full bottom-0;
      display: table;
    }

    .table-wrapper table td {
      @apply text-sm leading-4 print:text-print print:leading-none border-none bg-transparent;
    }

    tbody,
    .table-wrapper table tr,
    .table-wrapper table th {
      @apply bg-transparent;
    }

    .table-wrapper table .no-data-cell > * {
      @apply text-sm print:text-print;
    }

    @media print {
      @apply my-1;

      .line-item-category {
        @apply col-span-1;
      }
      table th {
        font-weight: 500 !important;
      }
      table td,
      table th, {
        padding: 1px 2px;
        font-size: 12px;
        @apply print:text-print;
      }
    }
  }
</style>
