import i18n from "@/i18n";
import axios from "axios";
import { error } from "@/components/common/NotificationPlugin";
import { billingTypesAbbr } from "@/enum/enums";
import { ref } from "vue";

type BillingTypes = keyof typeof billingTypesAbbr;

type BillingModel = {
  type: BillingTypes;
  work_order_id?: string;
  work_order_number?: string;
  job_id?: string;
  job_number?: string;
  customer_id?: string;
}

export function useInvoiceSequencing() {
  const loadingNumber = ref(false)

  async function getNextInvoiceNumber(model?: BillingModel) {
    try {
      loadingNumber.value = true
      const url = '/restify/billings/getters/suggest-billing-number'

      const { data: number } = await axios.get(url, { params: model })

      return number
    }
    catch (err) {
      // @ts-ignore
      if (err?.handled) {
        return ''
      }

      error(i18n.t('Could not generate invoice number'))
      return ''
    }
    finally {
      loadingNumber.value = false
    }
  }

  return {
    getNextInvoiceNumber,
    billingTypesAbbr,
    loadingNumber,
  }
}
