<template>
  <BaseFormDialog :title="$t(`Email Checks for ${batches.length} payment batch(es)`)"
                  :append-to-body="true"
                  v-bind="$attrs"
                  v-on="$listeners"
  >
    <base-form :save-text="$t('Email Checks')"
               show-cancel
               :loading="loading"
               layout="modal"
               ref="form"
               @cancel="$emit('update:open', false)"
               @submit="onSubmit"
    >
      <base-input v-model="model.email"
                  :label="$t('Email')"
                  :tip="$t('Checks for the payment batch(es) will be sent to this email')"
                  rules="required|email"
                  class="col-span-6"/>
    </base-form>
  </BaseFormDialog>
</template>
<script>
  export default {
    props: {
      batches: {
        type: Array,
        default: () => []
      },
      allBatches: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        loading: false,
        model: {
          email: '',
        }
      }
    },
    methods: {
      onSubmit() {
        try {
          this.loading = true
        } finally {
          this.loading = false
        }
      }
    }
  }
</script>
<style>
</style>
