<template>
  <base-filter-range :class="gridClass">
    <template #start>
      <craft-select
        v-model="value.craft_code_from_id"
        :placeholder="$t('Craft Code From')"
        :add-entity="false"
        :hide-label="true"
        id="craft_code_from"
        class="w-1/2"
        clearable
        @entity-change="onCraftFromChange"
        @blur="onBlurStartRange"
      />
    </template>
    <template #end>
      <craft-select
        v-model="value.craft_code_to_id"
        :add-entity="false"
        :hide-label="true"
        :placeholder="$t('Craft Code To')"
        class="w-1/2"
        id="craft_code_to"
        clearable
        @entity-change="onCraftToChange"
      />
    </template>
  </base-filter-range>
</template>
<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({
        craft_code_from_id: '',
        craft_code_to_id: '',
        craft_code_from: '',
        craft_code_to: '',
        craft_level_from: '',
        craft_level_to: '',
      }),
    },
    gridClass: {
      type: String,
      default: 'col-span-4',
    },
  },
  methods: {
    onBlurStartRange() {
      if (!this.value.craft_code_from) {
        return
      }

      this.value.craft_code_to = this.value.craft_code_to || this.value.craft_code_from
    },
    onCraftFromChange(craftCode) {
      const { code, level } = craftCode?.attributes || {}
      this.value.craft_code_from = code
      this.value.craft_level_from = level
    },
    onCraftToChange(craftCode) {
      const { code, level } = craftCode?.attributes || {}
      this.value.craft_code_to = code
      this.value.craft_level_to = level
    }
  },
}
</script>
