<template>
    <div>
        <div>
            <img class="h-12 mx-auto w-auto" src="/img/cp.png" alt="Workflow"/>
            <h1 class="page-heading">
                {{$t('Choose Organization')}}
            </h1>
        </div>
        <div class="mt-8">
            <div class="mt-6 relative">
                <div class="w-full border-t border-gray-300"/>
                <div class="relative flex justify-center text-sm leading-5/"/>
            </div>
            <div class="mt-6 flex flex-col space-y-2">
              <BaseButton
                  v-for="organization in organizations"
                  :key="organization.id"
                  class="w-full"
                  variant="white"
                  block
                  @click="onOrganizationClick(organization)"
              >
                {{ organization.name }}
              </BaseButton>
            </div>
        </div>
    </div>
</template>
<script>

  import AuthService from "@/modules/auth/services/AuthService";

  export default {
    computed: {
      globalLoginResponse() {
        return this.$store.state.auth.globalLoginResponse
      },
      organizations() {
        return this.globalLoginResponse?.organizations || []
      }
    },
    methods: {
      onOrganizationClick(organization) {
        const { plainTextToken } = this.globalLoginResponse
        AuthService.loginWithTokenRedirect(organization, plainTextToken)
      }
    }
  }
</script>
