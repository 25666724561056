<template>
  <AgedByReportForm
      :print-format-options="printFormatOptions"
      :report-number="reportNumbers.AgedByCustomer"
  />
</template>
<script setup>
  import AgedByReportForm from '@/modules/accounts-receivable/components/reports/AgedByReportForm'
  import { agedByViewFormats, reportNumbers } from '@/modules/accounts-receivable/components/reports/util'
  import i18n from "@/i18n";

  const printFormatOptions = [
    {
      label: i18n.t('View Each Invoice'),
      value: agedByViewFormats.EachInvoice,
    },
    {
      label: i18n.t('View Customer Total Only'),
      value: agedByViewFormats.CustomerTotal,
    },
    {
      label: i18n.t('View Past Due Invoice Only'),
      value: agedByViewFormats.PastInvoice,
    },
  ]
</script>
