<template>
  <AgDataTable
    v-bind="editableTableProps"
    :columns="columns"
    :data="data"
  >
  </AgDataTable>
</template>
<script lang="ts" setup>

import {Column} from "@/components/ag-grid/tableTypes";
import {computed} from "vue";
import i18n from "@/i18n";
import {cellClasses, markupValidator, requiredValueSetter, stopEditingOnTab} from "@/components/ag-grid/columnUtils";
import {cellEditors} from "@/components/ag-grid/cellEditors/cellEditors";
import {editableTableProps} from "@/components/ag-grid/tableUtils";
import {ValueSetterParams} from "@ag-grid-community/core";

defineProps({
  data: {
    type: Array,
    required: true,
    default: () => []
  },
})

const emit = defineEmits(['change'])

function onBooleanChange(params: ValueSetterParams) {
  emit('change', !!params.newValue)
  return requiredValueSetter(params, false)
}
const columns = computed<Column[]>(() => {
  return [
    {
      headerName: i18n.t('Name'),
      field: 'type_name',
      minWidth: 150,
      maxWidth: 300,
      cellClass: cellClasses.ReadOnly,
    },
    {
      headerName: i18n.t('Abbr'),
      field: 'type_abbr',
      minWidth: 70,
      maxWidth: 100,
      cellClass: cellClasses.ReadOnly,
    },
    {
      headerName: i18n.t('Markup'),
      field: 'markup_percent',
      component: 'FormattedPercent',
      align: 'right',
      cellEditor: cellEditors.Numeric,
      valueSetter: params => {
        params.newValue = +params.newValue
        emit('change', params.newValue)
        return requiredValueSetter(params, 0, markupValidator)
      },
      editable: true,
      minWidth: 100,
      maxWidth: 150,
    },
    {
      headerName: i18n.t('Profit'),
      field: 'profit_percent',
      component: 'FormattedPercent',
      align: 'right',
      cellEditor: cellEditors.Numeric,
      valueSetter: params => {
        params.newValue = +params.newValue
        emit('change', params.newValue)
        return requiredValueSetter(params, 0, markupValidator)
      },
      suppressKeyboardEvent: stopEditingOnTab,
      editable: true,
      minWidth: 100,
      maxWidth: 150,
    },
    {
      headerName: i18n.t('Subject to Tax'),
      field: 'subject_to_tax',
      component: 'Status',
      align: 'center',
      cellEditor: cellEditors.Boolean,
      editable: true,
      minWidth: 60,
      maxWidth: 150,
      valueSetter: params => {
        const val = !!params.newValue
        if (!val) {
          params.data.markup_subject_to_tax = false
          params.data.profit_subject_to_tax = false
        }
        return onBooleanChange(params)
      },
    },
    {
      headerName: i18n.t('Markup Subject to Tax'),
      field: 'markup_subject_to_tax',
      component: 'Status',
      align: 'center',
      cellEditor: cellEditors.Boolean,
      editable: params => {
        return params?.data?.subject_to_tax
      },
      cellClass: params => {
        return params?.data?.subject_to_tax ? '' : cellClasses.ReadOnly
      },
      minWidth: 60,
      maxWidth: 150,
      valueSetter: onBooleanChange
    },
    {
      headerName: i18n.t('Profit Subject to Tax'),
      field: 'profit_subject_to_tax',
      component: 'Status',
      align: 'center',
      cellEditor: cellEditors.Boolean,
      editable: params => {
        return params?.data?.subject_to_tax
      },
      cellClass: params => {
        return params?.data?.subject_to_tax ? '' : cellClasses.ReadOnly
      },
      minWidth: 60,
      maxWidth: 150,
      valueSetter: onBooleanChange
    },
    {
      headerName: i18n.t('Subject to Retention'),
      field: 'subject_to_retention',
      component: 'Status',
      align: 'center',
      cellEditor: cellEditors.Boolean,
      editable: true,
      minWidth: 60,
      maxWidth: 150,
      valueSetter: onBooleanChange
    },
  ]
})
</script>
