<template>
  <BankLink
      :id="params?.id"
      :data="row"
      :target="params?.target"
      :is-link="!editableCell"
  />
</template>
<script>
  import BankLink from "@/components/links/BankLink";

  export default {
    components: {
      BankLink,
    },
    props: {
      column: {
        type: Object,
        default: () => ({}),
      },
      row: {
        type: Object,
        default: () => ({}),
      },
      params: {
        type: Object,
        default: () => ({}),
      },
      editableCell: Boolean,
    }
  }
</script>
