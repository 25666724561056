<template>
  <div>
    <div class="flex justify-between w-full leading-4">
      <div v-if="level === 0" class="flex space-x-6">
        <TimecardEntryDate :row="data" :days="days"/>
      </div>
    </div>
  </div>
</template>
<script>
import TimecardEntryDate from "@/modules/payroll/components/timecard/TimecardEntryDate.vue";

export default {
  components: {
    TimecardEntryDate
  },
  computed: {
    data() {
      return this.params.node?.allLeafChildren[0]?.data || {}
    },
    level() {
      return this.params.node.level
    },
    days() {
      return this.params?.groupRowRendererParams?.days || []
    }
  }
}
</script>
