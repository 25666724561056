<template>
  <entity-select
      url="/restify/document-types"
      v-bind="$attrs"
      v-on="$listeners"
      :url-params="urlParams"
      :name="$attrs.name || $t('Document Type')"
      :placeholder="$attrs.placeholder || $t('Select Document Type')"
      :label="$attrs.label !== undefined ? $attrs.label : $t('Document Type')"
      :add-label="$t('Add new Document Type')"
      :add-entity="addEntity"
      :edit-entity="editEntity"
      :set-default-option="editEntity"
      :filter-options="filterOptions"
      :add-temporary-entity="true"
      label-key="description"
      class="w-full"
      :id="$attrs.id || 'document-type-select'"
  />
</template>
<script>
export default {
  inheritAttrs: false,
  props: {
    urlParams: {
      type: Object,
      default: () => ({
        sort: 'name'
      })
    },
    addEntity: {
      type: Boolean,
      default: true
    },
    editEntity: {
      type: Boolean,
      default: false,
    },
    module: {
      type: String,
    }
  },
  methods: {
    filterOptions(options) {
      if (!this.module) {
        return options
      }
      return options.filter(option => {
        const { used_in } = option.attributes || { used_in: []}
        return used_in.includes(this.module) || used_in?.length === 0
      })
    }
  }
}
</script>
