<template>
  <entity-select url="/restify/material-classes"
                 v-bind="$attrs"
                 v-on="$listeners"
                 :url-params="urlParams"
                 :filter-options="filterOptions"
                 :name="$attrs.name || $t('Material Class')"
                 :placeholder="$attrs.placeholder || $t('Select Material Class')"
                 :label="$attrs.label !== undefined ? $attrs.label : $t('Material Class')"
                 :add-label="$t('Add Material Class')"
                 :id="$attrs.id || 'material-select'"
                 :table-columns="tableColumns"
                 :label-format="formatLabel"
                 :hide-label="hideLabel"
                 label-key="code"
  />
</template>
<script>
  export default {
    name: 'MaterialSelect',
    inheritAttrs: false,
    props: {
      urlParams: {
        type: Object,
        default: () => ({
          sort: 'code',
        }),
      },
      excludeIds: {
        type: Array,
        default: () => [],
      },
      hideLabel: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        tableColumns: [
          {
            minWidth: 100,
            maxWidth: 150,
            name: this.$t('Code'),
            prop: 'attributes.code',
          },
          {
            minWidth: 200,
            maxWidth: 200,
            name: this.$t('Description'),
            prop: 'attributes.description',
          },
        ],
      }
    },
    methods: {
      filterOptions(options) {
        if (!this.excludeIds.length) {
          return options
        }
        return options.filter(o => {
          return !this.excludeIds.includes(o.id)
        })
      },
      formatLabel(materialClass) {
        const { code, description } = materialClass.attributes
        if (!description) {
          return code
        }
        return `${code} (${description})`
      },
    },
  }
</script>
