<template>
    <PreNoticeForm :data="preNotice"
                   :readonly="$route.path.includes('view')"
    />
</template>
<script>
  import axios from 'axios'
  import PreNoticeForm from '@/modules/job-costing/components/PreNoticeForm'

  export default {
    components: {
      PreNoticeForm,
    },
    data() {
      return {
        preNotice: {},
      }
    },
    methods: {
      async getPreNotice() {
        const { data } = await axios.get(`/restify/job-pre-notices/${this.$route.params.id}?related=job`)
        this.preNotice = data
      },
    },
    async mounted() {
      await this.getPreNotice()
    },
  }
</script>
