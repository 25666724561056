import Vue from 'vue'
import TableDeleteButton from "@/components/table/actions/TableDeleteButton.vue";

export function getDeleteColumn({ title, description, ...extraProperties }) {
  return {
    headerName: ' ',
    field: 'row_actions',
    // pinned: 'right',
    minWidth: 50,
    maxWidth: 50,
    editable: (params) => {
      return !params?.data?.readonly
    },
    cellRendererFramework: Vue.extend({
      render(h) {
        let shouldHide = this.params?.hide
        if (typeof extraProperties?.hide === 'function') {
          shouldHide = extraProperties?.hide(this.params)
        }
        if (this.params?.node?.footer || this.params?.data?.readonly || shouldHide) {
          return h('div')
        }
        return h(TableDeleteButton, {
          props: {
            skipFocus: true,
          }
        })
      }
    }),
    cellEditor: 'EntryDeleteDialog',
    cellEditorParams: params =>  {
      const getEditorParams = extraProperties?.getEditorParams
      let editorParams = {}
      if (getEditorParams) {
        editorParams = getEditorParams(params)
      }
      return {
        url: extraProperties?.url,
        title,
        description,
        onConfirm: extraProperties?.onConfirm,
        skipRowDelete: extraProperties?.skipRowDelete,
        ...editorParams,
      }
    },
    cellEditorPopup: true,
    suppressPaste: true,
    onCellClicked: params => {
      params.api.startEditingCell({
        rowIndex: params.rowIndex,
        colKey: params.column.colId,
      })
    },
    ...extraProperties,
  }
}
