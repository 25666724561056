<template>
  <BaseCard class="border">
    <div class="flex justify-center mb-5">
      <BaseAvatar size="lg" :name="employeeData.first_name"/>
    </div>
    <div class="w-full flex flex-col items-center">
      <h3>{{ employeeData.first_name }} {{ employeeData.last_name }}</h3>
      <CraftCodeLink :id="employeeData.craft_code_id" class="mb-2 mt-1 text-gray-700"/>
      <StatusBadge :status="employeeData.status"/>
    </div>
    <div class="mt-8 text-sm flex flex-col space-y-4">
      <div
          :title="$t('Birth Date')"
          class="flex space-x-3">
        <svg class="h-4 w-4 text-primary-500"
             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
             stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M12 8.25v-1.5m0 1.5c-1.355 0-2.697.056-4.024.166C6.845 8.51 6 9.473 6 10.608v2.513m6-4.87c1.355 0 2.697.055 4.024.165C17.155 8.51 18 9.473 18 10.608v2.513m-3-4.87v-1.5m-6 1.5v-1.5m12 9.75l-1.5.75a3.354 3.354 0 01-3 0 3.354 3.354 0 00-3 0 3.354 3.354 0 01-3 0 3.354 3.354 0 00-3 0 3.354 3.354 0 01-3 0L3 16.5m15-3.38a48.474 48.474 0 00-6-.37c-2.032 0-4.034.125-6 .37m12 0c.39.049.777.102 1.163.16 1.07.16 1.837 1.094 1.837 2.175v5.17c0 .62-.504 1.124-1.125 1.124H4.125A1.125 1.125 0 013 20.625v-5.17c0-1.08.768-2.014 1.837-2.174A47.78 47.78 0 016 13.12M12.265 3.11a.375.375 0 11-.53 0L12 2.845l.265.265zm-3 0a.375.375 0 11-.53 0L9 2.845l.265.265zm6 0a.375.375 0 11-.53 0L15 2.845l.265.265z"/>
        </svg>

        <span>{{ formatDate(employeeData.dob) }}</span>
      </div>
      <div
        v-if="employeeData.initial_hire_date && employeeData.hire_date !== employeeData.initial_hire_date"
        :title="$t('Initial Hire Date')"
        class="flex space-x-3 items-center"
      >
        <CalendarIcon class="h-4 w-4 text-primary-500"/>
        <span>{{ formatDate(employeeData.initial_hire_date) }}</span>
      </div>
      <div :title="$t('Hire Date')"
           class="flex space-x-3 items-center">
        <BriefcaseIcon class="h-4 w-4 text-primary-500"/>
        <span>{{ formatDate(employeeData.hire_date) }}</span>
      </div>
    </div>
    <slot name="actions"></slot>
  </BaseCard>
</template>
<script lang="ts" setup>
  import { BriefcaseIcon, CalendarIcon } from 'vue-feather-icons'
  import { computed, PropType } from "vue";
  import { Employee } from "@/modules/common/types/models";
  import { formatDate } from "@/plugins/dateFormatPlugin";
  import CraftCodeLink from "@/components/links/CraftCodeLink.vue";
  import Data = API.Data;

  const props = defineProps({
    employee: {
      type: Object as PropType<Data<Employee>>,
      default: () => ({}),
      required: true,
    }
  })

  const employeeData = computed<Employee>(() => {
    return props.employee?.attributes || {}
  })
</script>
