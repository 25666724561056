<template>
  <base-filter-range>
    <template #start>
      <base-select
          v-model="value.period"
          :placeholder="$t('Fiscal Period')"
          :options="periods"
          :hide-label="true"
          class="w-1/2"
          clearable
      />
    </template>
    <template #end>
      <base-input
          v-model="value.fiscal_year"
          :placeholder="$t('Fiscal Year')"
          :name="$t('Fiscal Year')"
          :rules="{
            'min_value': $validator.minFiscalYear,
            'max_value': $validator.maxFiscalYear,
          }"
          class="w-1/2"
      />
    </template>
  </base-filter-range>
</template>
<script>
  export default {
    props: {
      value: {
        type: Object,
        default: () => ({
          period: 0,
          fiscal_year: 0,
        }),
      },
    },
    computed: {
      periods() {
        return this.$store.state.company.periods
      },
    },
  }
</script>
