<template>
  <VendorLink
    :id="data?.attributes?.vendor_id"
    :data="vendor"
    link-class="text-sm text-gray-900"
  />
</template>
<script>
export default {
  name: 'VendorGroupRow',
  computed: {
    data() {
      return this.params.node?.allLeafChildren[0]?.data || {}
    },
    vendor() {
      return this.get(this.data, 'relationships.vendor', {})
    },
  },
}
</script>
