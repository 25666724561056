<template>
  <AgDataTable
    v-bind="$attrs"
    :columns="columns"
    :url="url"
    :url-params="finalUrlParams"
    :show-actions-column="false"
    :masterDetail="true"
    :detailCellRenderer="detailCellRenderer"
    :detailRowAutoHeight="true"
    :is-expandable="true"
    default-sort="-reference_date"
    actions="search,refresh"
    ref="historyTable"
    class="h-full"
    domLayout="autoHeight"
  >
    <template #attributes.journal="{row}">
      <JournalLink :data="{ id: row.attributes?.journal_id, name: row.attributes?.journal}"/>
    </template>
    <template #subaccount="{row}">
      <SubAccountLink
        v-if="get(row, 'relationships.subaccount')"
        :data="get(row, 'relationships.subaccount')"
      />
    </template>
    <template #business="{row}">
      <BusinessLink
        v-if="getBusinessData(row)"
        :data="getBusinessData(row)"
      />
    </template>
    <template #additional-actions
              v-if="showPeriodFilter">
      <PeriodFilter v-model="selectedPeriod"/>
    </template>
    <template v-slot:attributes.period="{row}">
      {{ getPeriodName(row.attributes.period) }}
    </template>
  </AgDataTable>
</template>
<script>
import PeriodFilter from '../components/PeriodFilter'
import TransactionDetails from "@/modules/ledger/components/TransactionDetails";
import BusinessLink from "@/components/links/BusinessLink.vue";
import { transactionStatuses } from "@/enum/enums";
import axios from "axios";
import TransactionExpandDetails from "@/modules/ledger/components/accounts/TransactionExpandDetails.vue";

export default {
  components: {
    BusinessLink,
    PeriodFilter,
    TransactionDetails,
    TransactionExpandDetails,
  },
  props: {
    url: {
      type: String,
      default: '/restify/transactions',
    },
    urlParams: {
      type: Object,
      default: () => ({}),
    },
    showPeriodFilter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedPeriod: null,
      detailCellRenderer: 'TransactionExpandDetails',
      statusOptions: [
        {
          label: this.$t('Reconciled'),
          value: transactionStatuses.Reconciled,
        },
        {
          label: this.$t('Outstanding'),
          value: transactionStatuses.Outstanding,
        },
      ]
    }
  },
  computed: {
    periodFilter() {
      return this.selectedPeriod || this.urlParams?.period || undefined
    },
    selectedAccount() {
      return this.$store.state.generalLedger.currentAccount || {}
    },
    finalUrlParams() {
      const accountFiscalYear = this.selectedAccount?.attributes?.fiscal_year
      return {
        ...this.urlParams,
        related: 'subaccount,source[id|number|description],business[id|code|name]',
        fiscal_year: accountFiscalYear || this.activeFiscalYear,
        period: this.periodFilter
      }
    },
    columns() {
      const isDebug = this.$route.query.debug
      const columns = [
        {
          headerName: ' ',
          cellRenderer: 'agGroupCellRenderer',
          maxWidth: 55,
          minWidth: 55,
        },
        {
          label: this.$t('Journal'),
          prop: 'attributes.journal',
          maxWidth: 100,
        },
        {
          label: this.$t('Ref Date'),
          prop: 'attributes.reference_date',
          maxWidth: 140,
          component: 'FormattedDate',
        },
        {
          label: this.$t('Description'),
          prop: 'attributes.description',
          minWidth: 150,
          maxWidth: 400,
        },
        {
          label: this.$t('Sub'),
          prop: 'subaccount',
          maxWidth: 60,
        },
        {
          label: this.$t('Vendor/Customer'),
          prop: 'business',
          maxWidth: 150,
        },
        {
          label: this.$t('Ref #'),
          prop: 'attributes.reference_no',
          maxWidth: 100,
        },
        {
          label: this.$t('Period'),
          prop: 'attributes.period',
          maxWidth: 140,
          renderIf: () => !this.periodFilter,
        },
        {
          label: this.$t('Amount'),
          prop: 'attributes.amount',
          minWidth: 150,
          align: 'right',
          component: 'FormattedPrice',
        },
        {
          label: this.$t('Status'),
          prop: 'attributes.status',
          minWidth: 150,
          align: 'right',
          hide: !isDebug,
          editable: true,
          cellEditor: this.$cellEditors.BaseSelect,
          cellEditorParams: {
            options: this.statusOptions,
          },
          valueSetter: params => {
            if (!params.newValue) {
              params.data.attributes.status = params.data.prior_status
            } else {
              params.data.attributes.status = params.newValue
            }
            if (params.newValue === transactionStatuses.Reconciled) {
              params.data.attributes.outstanding_amount = 0
            }
            if (params.newValue === transactionStatuses.Outstanding) {
              params.data.attributes.outstanding_amount = params.data.attributes.amount
            }
            this.updateTransaction(params.data)
            return true
          },
        },
        {
          label: this.$t('Outstanding Amount'),
          prop: 'attributes.outstanding_amount',
          minWidth: 150,
          align: 'right',
          hide: !isDebug,
        },
      ]

      return columns.filter(column => column.renderIf ? column.renderIf() : true)
    },
  },
  methods: {
    async updateTransaction(transaction) {
      const status = transaction.attributes.status
      let per_bank_amount = transaction.attributes.per_bank_amount
      if (status === transactionStatuses.Reconciled) {
        per_bank_amount = transaction.attributes.amount
      }
      if (status === transactionStatuses.Outstanding) {
        per_bank_amount = 0
      }
      await axios.post(`/restify/transactions/actions?action=update-transaction-status`, {
        transactions: [
          {
            id: transaction.id,
            status,
            per_bank_amount
          }
        ]
      })
    },
    getPeriodName(period) {
      return this.$store.getters['company/getPeriodName'](period)
    },
    getBusinessData(row) {
      const business = this.get(row, 'relationships.business', {})
      return {
        business: {
          ...business,
          type: row.attributes?.business_type
        }
      }
    }
  },
}
</script>
