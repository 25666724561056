<template>
  <BaseFormDialog
    v-bind="$attrs"
    v-on="$listeners"
    :append-to-body="true"
    size="lg"
  >
    <base-form
      @submit="confirmLocation"
      class="w-full mt-6"
      layout="vertical"
      :save-text="$t('Confirm Address')"
    >
      <div class="col-span-6 mb-4">
        <GoogleAutocomplete
          :label="$t('Enter a location')"
          :placeholder="$t('Type to search for a location...')"
          @place_changed="onAddressChange">
        </GoogleAutocomplete>
      </div>
      <div class="col-span-6">
        <GmapMap
          :center="markerPosition"
          :zoom="7"
          style="height: 500px"
          class="w-full"
          @click="selectLocation"
        >
          <GmapMarker :position="markerPosition"/>
        </GmapMap>
      </div>
    </base-form>
  </BaseFormDialog>
</template>
<script>
import Vue from 'vue'
import GmapVue from 'gmap-vue'
import GoogleAutocomplete from "@/components/maps/GoogleAutocomplete.vue";

const apiKey = import.meta.env.VITE_MAPS_API_KEY
Vue.use(GmapVue, {
  load: {
    key: apiKey,
    libraries: 'places',
  },

  installComponents: true
})
const defaultLocation = {
  lat: 38.53603654298597,
  lng: -121.44829809431094
}
export default {
  components: { GoogleAutocomplete },
  data() {
    return {
      center: { ...defaultLocation },
      markerPosition: { ...defaultLocation },
      selectedPosition: { lat: () => defaultLocation.lat, lng: () => defaultLocation.lng },
      locationSelected: false,
    }
  },
  methods: {
    selectLocation(event) {
      this.locationSelected = true
      this.markerPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      this.selectedPosition = event.latLng;
    },
    confirmLocation() {
      this.$emit('confirm', this.selectedPosition)
    },
    setUserLocation() {
      if (!navigator.geolocation) {
        return;
      }
      navigator.geolocation.getCurrentPosition(
        position => {
          this.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          this.markerPosition = this.center
          this.selectedPosition = {
            lat: () => this.center.lat,
            lng: () => this.center.lng,
          };
        },
        error => {
          this.$error('An error occurred while trying to get your location')
        }
      )
    },
    onAddressChange(place) {
      const location = place.geometry.location
      this.markerPosition = {
        lat: location.lat(),
        lng: location.lng(),
      };
      this.selectedPosition = location;
    }
  },
  mounted() {
    this.setUserLocation()
  }
}
</script>
<style>
</style>
