<template>
  <div class="flex justify-between w-full leading-4">
    <EmployeeLink :id="employee.id"/>
    <div v-if="payroll.net_pay" class="flex space-x-2 font-semibold">
      <span>{{ $t('Net Pay:') }}</span>
      <span>{{ $formatPrice(netPay) }}</span>
    </div>
  </div>
</template>
<script>
  import sumBy from 'lodash/sumBy'
  import { codeTypes } from "@/modules/payroll/components/rates/util";
  import { reservedPayCodes } from "@/enum/enums";

  export default {
    computed: {
      data() {
        return this.params.node?.allLeafChildren[0]?.data || {}
      },
      payroll() {
        return this.data?.payroll || {}
      },
      employee() {
        return this.data?.employee || {}
      },
      deductionEntries() {
        const allEntries = this.params.node?.allLeafChildren || []
        return allEntries.filter(entry => entry.data.type === codeTypes.DEDUCTION)
      },
      reservedEntries() {
        const allEntries = this.params.node?.allLeafChildren || []
        return allEntries.filter(entry => this.isReservedCode(entry))
      },
      payTotal() {
        return sumBy(this.reservedEntries, 'data.amount')
      },
      adjustedPayTotal() {
        return sumBy(this.reservedEntries, 'data.adjusted_amount')
      },
      adjustedDeductionsTotal() {
        return sumBy(this.deductionEntries, 'data.adjusted_amount')
      },
      deductionsTotal() {
        return sumBy(this.deductionEntries, 'data.amount')
      },
      netPay() {
        return this.payroll.net_pay + this.deductionsTotal - this.adjustedDeductionsTotal - this.payTotal + this.adjustedPayTotal
      },
      level() {
        return this.params.node.level
      }
    },
    methods: {
      isReservedCode(entry) {
        return [reservedPayCodes.RegularPay, reservedPayCodes.OvertimePay, reservedPayCodes.PremiumPay].includes(entry.data?.special_source?.code)
      },
    }
  }
</script>
