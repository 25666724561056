<template>
  <base-select
    v-bind="$attrs"
    v-on="$listeners"
    :name="$t('Task Type')"
    :placeholder="$t('Task Type')"
    :label="$attrs.label || $t('Task Type')"
    :options="costCenterOptions"
    id="cost_center"
  />
</template>
<script>
import i18n from "@/i18n";
import { costCenterTypes } from "@/components/grid-table/utils/cost-center";

export default {
  data() {
    return {
      costCenterOptions: [
        {
          label: i18n.t('General & Administrative'),
          value: costCenterTypes.GeneralAndAdministrative,
        },
        {
          label: i18n.t('Job (Line Item)'),
          value: costCenterTypes.Job,
        },
        {
          label: i18n.t('Work Order'),
          value: costCenterTypes.WorkOrder,
        },
        {
          label: i18n.t('Equipment'),
          value: costCenterTypes.Equipment,
        },
      ]
    }
  }
}
</script>
