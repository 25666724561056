import { checkTypes } from "@/modules/payroll/components/rates/util";
import i18n from "@/i18n";

export const checkTypeOptions = [
  {
    label: i18n.t('Normal'),
    value: checkTypes.NORMAL,
  },
  {
    label: i18n.t('Compute Payoff Check'),
    value: checkTypes.COMPUTE_PAYOFF_CHECK,
  },
  {
    label: i18n.t('Manual Check / Adjust'),
    value: checkTypes.MANUAL_CHECK_ADJUST,
  },
  {
    label: i18n.t('Normal-Calc Tax Only'),
    value: checkTypes.NORMAL_CALC_TAX_ONLY,
  },
  {
    label: i18n.t('Normal-TaxOnly-No D/D'),
    value: checkTypes.NORMAL_TAX_ONLY_NO_DD,
  },
  {
    label: i18n.t('Normal-No D/D'),
    value: checkTypes.NORMAL_NO_DD,
  },
]
