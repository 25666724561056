<template>
  <ExportInitsButton
    url="/restify/job-costing-init-entries/actions?action=job-costing-init-entries-data-export"
    :url-params="{
      job_costing_init_id: id,
    }"
    :columns="exportColumns"
    class="mr-4 flex"
    v-bind="$attrs"
  />
</template>
<script>
import ExportInitsButton from "@/modules/common/components/export/ExportInitsButton.vue";

export default {
  components: {
    ExportInitsButton
  },
  props: {
    id: {
      type: String,
    }
  },
  data() {
    return {
      exportColumns: [
        'job.number',
        'job_type.abbr',
        'business.code',
        'line_item.phase_code',
        'line_item.cost_code',
        'line_item.change_order',
        'quantity',
        'amount',
        'retention_amount',
      ]
    }
  }
}
</script>
