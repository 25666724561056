<template>
  <ServiceInvoiceForm
      :key="renderKey"
      @create-another="renderKey++"
  />
</template>
<script>
  import ServiceInvoiceForm from '@/modules/service-billing/components/ServiceInvoiceForm'
  export default {
    components: {
      ServiceInvoiceForm,
    },
    data() {
      return {
        renderKey: 1,
      }
    }
  }
</script>
