<template>
  <div>
    <AgDataTable
        :data="subTrades"
        :columns="columns"
        :data-loading="loading"
        :pagination="false"
        :show-pagination="false"
        :delete-action="onRowDelete"
        :add-text="$t('New sub trade')"
        :compact="true"
        :openEntityInNewTab="false"
        domLayout="autoHeight"
        add-button-variant="primary-light"
        ref="table"
        hide-actions="view"
        actions="add,delete,edit,refresh"
        @add="onRowAdd"
        @edit="onRowEdit"
    >
      <template v-slot:custom-header>
        <div>
          <h6 class="form-section-title">
            {{ $t('Sub Trades') }}
          </h6>
          <p class="form-section-description -mt-1">
            {{
              $t('Sub Trades are used to group employees by trade. They are used only for reporting purposes for Certified Payroll.')
            }}
          </p>
        </div>
      </template>
    </AgDataTable>
    <BaseFormDialog v-if="showDialog"
                    :title="rowToEdit ? $t('Edit Sub Trade') : $t('Add Sub Trade')"
                    :open.sync="showDialog"
                    @close="onCloseDialog"
    >
      <SubTradeForm
        :data="rowToEdit"
        :craft_code_id="craft_code_id"
        layout="modal"
        @cancel="onCloseDialog"
        @save="onSubmit"
        @edit="onEdit"
      />
    </BaseFormDialog>
  </div>
</template>
<script>
import SubTradeForm from "@/modules/payroll/components/codes/SubTradeForm.vue";
import axios from "axios";

export default {
  components: { SubTradeForm },
  inheritAttrs: false,
  props: {
    craft_code_id: {
      type: String,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      showDialog: false,
      rowToEdit: null,
      rowsToDelete: [],
      subTrades: [],
    }
  },
  computed: {
    columns() {
      return [
        {
          label: this.$t('Trade'),
          prop: 'attributes.trade',
          minWidth: 150,
          maxWidth: 250,
        },
        {
          label: this.$t('Classification'),
          prop: 'attributes.classification',
          minWidth: 150,
          maxWidth: 250,
        },
        {
          label: this.$t('Description'),
          prop: 'attributes.description',
        },
      ]
    },
  },
  methods: {
    onRowEdit(row) {
      this.showDialog = true
      this.rowToEdit = row
    },
    onRowAdd() {
      this.showDialog = true
    },
    refreshTable() {
      this.$refs.table.refresh()
    },
    onCloseDialog() {
      this.rowToEdit = null
      this.showDialog = false
      this.refreshTable()
    },
    onSubmit(subTrade) {
      this.subTrades.unshift(subTrade)
      this.onCloseDialog()
    },
    onEdit(subTrade) {
      const index = this.subTrades.findIndex(s => s.id === subTrade.id)
      if (index === -1) {
        return
      }
      this.subTrades.splice(index, 1, subTrade)
      this.onCloseDialog()
    },
    async onRowDelete(row, index) {
      await axios.delete(`/restify/sub-trades/${row.id}`)
      this.subTrades.splice(index, 1)
    },
    async getSubTrades() {
      const { data } = await axios.get(`/restify/sub-trades`, {
        params: {
          craft_code_id: this.craft_code_id,
          perPage: 100,
        }
      })
      this.subTrades = data
    }
  },
  async mounted() {
    await this.getSubTrades()
  }
}
</script>
