<!--TODO: implement other formats-->
<template>
  <JobCostDetailTable v-bind="$attrs"/>
</template>
<script>
  import JobCostDetailTable from '@/modules/job-costing/components/reports/job-cost-detail/by-line-item/JobCostDetailTable'

  export default {
    components: {
      JobCostDetailTable,
    },
  }
</script>
