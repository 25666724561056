<template>
  <base-form-dialog v-bind="$attrs"
                    v-on="$listeners"
                    :title="$t(`Statement for ${customer.name}`)"
                    :has-padding="true"
                    size="lg"
                    class="check-modal"
  >
    <div class="prose flex flex-col w-full max-w-full">
      <div class="flex justify-between items-end w-full">
        <div>
          <base-tooltip :content="$t('Company')">
            <div class="flex items-center">
              <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-cool-gray-400" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd"
                      d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
                      clip-rule="evenodd"></path>
              </svg>
              <h5 class="text-gray-700">{{ company.name || '' }}</h5>
            </div>
          </base-tooltip>
          <div v-if="company.address_1" class="flex text-xs leading-5 text-cool-gray-500 font-medium">
            <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-cool-gray-400" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd"
                    d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
                    clip-rule="evenodd"></path>
            </svg>
            <span>{{company.address_1}}</span>
            <span>{{company.city}}, {{company.state}}</span>
          </div>
        </div>
        <div class="flex flex-col items-end">
          <h4>{{data.no}}</h4>
          <time class="whitespace-no-wrap text-sm leading-5 text-cool-gray-500">
            {{ $formatDate(data.date) }}
          </time>
        </div>
      </div>
      <hr>
      <div class="flex justify-between">
        <div class="flex w-full text-sm leading-5 text-cool-gray-500 font-medium">
          <div>
            <base-tooltip :content="$t('Customer')">
              <div class="flex items-center">
                <svg class="flex-shrink-0 mr-1.5 mb-2 h-5 w-5 text-cool-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span class="text-cool-gray-700 mb-2">{{ customer.name || '' }}</span>
              </div>
            </base-tooltip>
            <dd v-if="customer.address_1" class="flex items-center capitalize sm:mr-6 mt-1">
              <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-cool-gray-400" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd"
                      d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
                      clip-rule="evenodd"></path>
              </svg>
              {{ customer.address_1 }}, {{ customer.city }}, {{ customer.state }} {{ customer.zip_code }}
            </dd>
          </div>
        </div>
      </div>
      <hr>
    </div>
    <!--
  Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
  Read the documentation to get started: https://tailwindui.com/documentation
-->
    <div v-if="data.total.Over90 !== '0'" class="rounded-md bg-red-50 p-4">
      <div class="flex">
        <div class="flex-shrink-0">
          <!-- Heroicon name: x-circle -->
          <svg class="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="ml-3">
          <h6 class="text-red-800">
            <b>{{$t('Past Due Notice')}}</b> {{customer.name}} {{$t(' has due payments for over 90 days!')}}
          </h6>
        </div>
      </div>
    </div>

    <div class="overflow-hidden sm:rounded-lg w-full mt-2">
      <div class="border-b border-gray-200 text-center pb-3">
        <p class="mt-1 text-center text-sm leading-5 text-gray-500">
          {{ $t('Summary') }}
        </p>
      </div>
    </div>
    <div class="flex mt-6 space-x-4">
      <BaseDataTable :columns="summaryColumns"
                     :data="summaryData"
                     :show-pagination="false"
                     class="flex-1 max-w-full">
        <template #total="{row}">
          {{ $formatPrice(getTotalDue(row)) }}
        </template>
      </BaseDataTable>
    </div>
    <div class="overflow-hidden sm:rounded-lg w-full">
      <div class="border-b border-gray-200 text-center py-3">
        <p class="mt-1 text-center text-sm leading-5 text-gray-500">
          {{ $t('Invoices ') }}
        </p>
      </div>
    </div>
    <div class="flex mt-6 space-x-4">
      <BaseDataTable :columns="invoiceColumns"
                     :data="data.invoices"
                     :show-pagination="false"
                     class="flex-1 max-w-full"/>
    </div>

    <FileAttachments
        :entity-id="data.id"
        class="mt-2"
        entity="customer-statements"
    />
  </base-form-dialog>
</template>
<script>
  import { getTotalDue } from "@/modules/accounts-receivable/components/statementUtils";

  export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    customer: {
      type: Object,
      default: () => ({})
    },
    company: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      invoiceColumns: [
        {
          label: this.$t('INV #'),
          prop: 'Number',
          maxWidth: 150,
        },
        {
          label: this.$t('Date'),
          prop: 'FormattedDate',
          maxWidth: 150,
          minWidth: 120,
        },
        {
          label: this.$t('Description'),
          prop: 'Description',
          maxWidth: 150,
          minWidth: 120,
        },
        {
          label: this.$t('Amount'),
          prop: 'InvoiceAmount',
          maxWidth: 150,
          minWidth: 120,
          component: 'FormattedPrice'
        },
        {
          label: this.$t('Balance Due'),
          prop: 'Balance',
          maxWidth: 150,
          minWidth: 120,
          component: 'FormattedPrice'
        },
      ],
      summaryColumns: [
        {
          label: this.$t('31-60 days due'),
          prop: '31-60',
          maxWidth: 150,
          minWidth: 120,
          component: 'FormattedPrice'
        },
        {
          label: this.$t('61-90 days due'),
          prop: '61-90',
          maxWidth: 150,
          minWidth: 120,
          component: 'FormattedPrice'
        },
        {
          label: this.$t('90+ days due'),
          prop: 'Over90',
          maxWidth: 150,
          minWidth: 120,
          component: 'FormattedPrice'
        },
        {
          label: this.$t('Current'),
          prop: 'Current',
          maxWidth: 150,
          minWidth: 120,
          component: 'FormattedPrice'
        },
        {
          label: this.$t('Total Due'),
          prop: 'total',
          maxWidth: 150,
          minWidth: 120,
          component: 'FormattedPrice',
          params: {
            classes: 'font-semibold'
          }
        },
      ]
    }
  },
  computed: {
    summaryData() {
      const data = {
        ...this.data.total,
        Total: this.data.customer_data.Due,
      }
      return [data]
    }
  },
  methods: {
    getTotalDue,
    getTotal(arr, field) {
      let sum = 0;
      arr.forEach(entry => {
        sum+= +entry[field] || 0
      })
      return sum
    },
    getNetPay() {
      const deposits = this.data?.deposit || []
      let sum = 0;
      deposits.forEach(deposit => {
        Object.keys(deposit).map(key => {
          sum += parseFloat(deposit[key]);
        })
      })
      return sum
    }
  }
}
</script>
<style scoped>
.check-modal .prose hr {
  @apply my-4;
}

.check-modal .status-badge {
  width: 130px;
}
</style>
