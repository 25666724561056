<template>
  <div>
    <PurchaseOrderItems
      :data="params.data"
      readOnly
      class="p-4"
    />
  </div>
</template>
<script>
import PurchaseOrderItems from "@/modules/purchasing-inventory/components/purchase-orders/PurchaseOrderItems.vue";
export default {
  components: {
    PurchaseOrderItems
  }
}
</script>
