<template>
  <div class="flex-shrink-0 flex px-4 py-2 relative"
       v-click-outside="onMenuClickOutside">
    <div class="flex items-center transition-all delay-300"
         role="button"
         @click="showDropdown = !showDropdown">
      <base-avatar :name="$user.name || 'N/A'"
                   size="sm"
      />
      <div class="mx-3 dropdown-button">
        <p class="text-sm leading-5 font-medium text-gray-400 truncate">
          {{ $user.name || 'N/A' }}
        </p>
        <p class="text-xs leading-4 font-medium text-primary-600 truncate">
          {{ $companyName }}
        </p>
      </div>
      <IconArrowDown class="text-gray-400"
                     :class="{'menu-active': showDropdown}"/>
    </div>
    <transition enter-active-class="transition ease-out duration-100"
                enter-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">
      <div v-if="showDropdown"
           class="origin-top-right absolute py-2 right-0 mr-2 md:mr-auto mt-14 w-full rounded-md bg-white shadow-xs opacity-0 z-10 w-48 overflow-y-auto">
        <p @click="redirectAction('/profile/edit')"
           class="profile-menu_item">
          {{ $t('Manage Account') }}
        </p>
        <p v-if="$can('companies_update')"
           @click="redirectAction('/settings/companies')"
           class="profile-menu_item">
          {{ $t('Manage Companies') }}
        </p>
        <div v-if="userCompanies.length"
             v-loading="localLoading"
             class="max-h-companies overflow-y-auto">
          <div v-for="company in userCompanies"
               :key="company.id"
               @click="makeCompanyActive(company.id)"
               class="profile-menu_item text-xs font-medium leading-7 tracking-wide flex items-center py-2">
            <template v-if="isActiveCompany(company.id)">
              <div class="flex items-center justify-between w-full truncate">
                <span class="mr-1 truncate">
                  {{ company.label }}
                </span>
                <IconCheck class="text-blue-600 check-icon w-4 h-4"/>
              </div>
            </template>
            <base-tooltip v-else :content="$t('Make Company Active')">
              <span class="truncate">
                {{ company.label }}
              </span>
            </base-tooltip>
          </div>
        </div>
        <router-link v-if="$can('companies_store')"
                     to="/settings/companies?addCompany=true">
          <div class="px-6 py-3 flex items-center text-blue-600 text-sm font-semibold leading-7 tracking-wide">
            <IconAdd class="w-4 h-4"/>
            <span class="ml-1">{{ $t('Add Company') }}</span>
          </div>
        </router-link>

        <router-link
            v-if="showChangelog()"
            to="/changelog"
            @click.native="onMenuClickOutside"
            class="profile-menu_item flex items-cneter">
          {{ $t('Changelog') }}
        </router-link>
        <p @click="logout"
           class="profile-menu_item">
          {{ $t('Log Out') }}
        </p>
      </div>
    </transition>
  </div>
</template>
<script>

  import { showChangelog } from "@/modules/dashboard/pages/changelog";

  export default {
    data() {
      return {
        showDropdown: false,
        localLoading: false,
      }
    },
    computed: {
      userCompanies() {
        const companies = this.$store.state.auth.userCompanies || []
        return companies.map(c => {
          return {
            ...c,
            label: c.abbr || c.name,
          }
        })
      },
    },
    methods: {
      showChangelog,
      isActiveCompany(companyId) {
        return companyId.toString() === this.$currentCompany?.id?.toString()
      },
      onMenuClickOutside() {
        this.showDropdown = false
      },
      redirectAction(route) {
        if (this.$route.path === route) {
          return
        }
        this.$router.push(route)
        this.onMenuClickOutside()
      },
      logout() {
        this.$store.dispatch('auth/logout')
        this.$router.push('/login')
      },
      async makeCompanyActive(id) {
        if (this.isActiveCompany(id)) {
          return
        }
        this.localLoading = true
        const companies = this.$store.state.company.userCompanies
        const company = companies.find(c => c.attributes.id === id) || { id }
        await this.$store.dispatch('company/markUserCompanyAsActive', company)
        this.localLoading = false
        this.showDropdown = false
        await this.$store.dispatch('auth/refreshProfile')
        window.location = '/welcome'
      },
    },
  }
</script>
<style lang="scss">
  .profile-menu_item {
    @apply block cursor-pointer font-semibold px-6 my-1 py-2 text-sm leading-6 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 truncate;
  }

  .max-h-companies {
    max-height: 200px;
  }

  .max-h-85 {
    max-height: 22rem;
  }

  .mt-14 {
    margin-top: 3.5rem;
  }

  .dropdown-button {
    max-width: 150px;
    min-width: 100px;
  }

  .check-icon {
    min-width: 16px;
    min-height: 16px;
  }

  .menu-active {
    transform: rotate(180deg);
  }
</style>
