import i18n from "@/i18n";
import { resourceStatuses } from "@/enum/enums";
import { tableColumns } from "@/components/table/tableColumns";

const selectColumn = {
  label: '',
  prop: 'select',
  minWidth: 40,
  maxWidth: 40,
  smallCell: true,
}

const baseColumns = [
  {
    label: i18n.t('Vendor'),
    prop: 'vendors.code',
    hiddenValue: true,
    minWidth: 80,
    maxWidth: 80,
  },
  {
    label: i18n.t('Invoice #'),
    prop: 'attributes.number',
    maxWidth: 160,
  },
  {
    label: i18n.t('Reference'),
    prop: 'attributes.cost_center',
    minWidth: 120,
    maxWidth: 200,
    visible: false,
  },
  {
    label: i18n.t('Date'),
    prop: 'attributes.date',
    minWidth: 100,
    maxWidth: 150,
    component: 'FormattedDate',
  },
  {
    label: i18n.t('Due Date'),
    prop: 'attributes.due_date',
    minWidth: 100,
    maxWidth: 150,
    component: 'FormattedDate',
    visible: false,
  },
  {
    label: i18n.t('Discount Date'),
    prop: 'attributes.discount_date',
    minWidth: 100,
    maxWidth: 150,
    component: 'FormattedDate',
    visible: false,
  },
]


export const TABLE_COLUMNS = {
  [resourceStatuses.Pending]: [
    selectColumn,
    ...baseColumns,
    {
      label: i18n.t('Created By'),
      prop: 'attributes.created_by',
      minWidth: 100,
      maxWidth: 150,
      component: tableColumns.UserLink,
    },
    {
      label: i18n.t('Amount'),
      prop: 'attributes.net_amount',
      component: 'FormattedPrice',
      minWidth: 200,
      maxWidth: 200,
      align: 'right',
    },
    {
      label: i18n.t('Status'),
      align: 'center',
      prop: 'attributes.status',
      maxWidth: 160,
      component: 'StatusLink',
    },
    {
      label: i18n.t('Review Status'),
      align: 'center',
      prop: 'attributes.review_status',
      maxWidth: 160,
      component: 'ReviewStatus',
    },
    {
      label: i18n.t('Is Receipt'),
      align: 'center',
      prop: 'attributes.is_receipt',
      maxWidth: 100,
      component: 'Status',
    },
  ],
  [resourceStatuses.Paid]: [
    selectColumn,
    ...baseColumns,
    {
      label: i18n.t('Created By'),
      prop: 'attributes.created_by',
      minWidth: 100,
      maxWidth: 150,
      component: tableColumns.UserLink,
    },
    {
      label: i18n.t('Amount'),
      prop: 'attributes.net_amount',
      component: 'FormattedPrice',
      minWidth: 200,
      maxWidth: 200,
      align: 'right',
    },
    {
      label: i18n.t('Paid'),
      prop: 'attributes.paid_amount',
      component: 'FormattedPrice',
      minWidth: 200,
      maxWidth: 200,
      align: 'right',
    },
    {
      label: i18n.t('Paid At'),
      prop: 'attributes.paid_at',
      minWidth: 100,
      maxWidth: 150,
      component: 'FormattedDate',
    },
    {
      label: i18n.t('Is Receipt'),
      align: 'center',
      prop: 'attributes.is_receipt',
      maxWidth: 100,
      component: 'Status',
    },
  ],
  [resourceStatuses.PartialPaid]: [
    selectColumn,
    ...baseColumns,
    {
      label: i18n.t('Created By'),
      prop: 'attributes.created_by',
      minWidth: 100,
      maxWidth: 150,
      component: tableColumns.UserLink,
    },
    {
      label: i18n.t('Amount'),
      prop: 'attributes.net_amount',
      component: 'FormattedPrice',
      minWidth: 200,
      maxWidth: 200,
      align: 'right',
    },
    {
      label: i18n.t('Paid Amount'),
      prop: 'attributes.paid_amount',
      component: 'FormattedPrice',
      minWidth: 200,
      maxWidth: 200,
      align: 'right',
    },
    {
      label: i18n.t('Open Amount'),
      prop: 'attributes.open_amount',
      minWidth: 140,
      maxWidth: 140,
      component: 'FormattedPrice',
      align: 'right',
    },
    {
      label: i18n.t('Paid At'),
      prop: 'attributes.paid_at',
      minWidth: 100,
      maxWidth: 150,
      component: 'FormattedDate',
    },
  ],
  [resourceStatuses.Posted]: [
    ...baseColumns,
    {
      label: i18n.t('Created By'),
      prop: 'attributes.created_by',
      minWidth: 100,
      maxWidth: 150,
      component: tableColumns.UserLink,
    },
    {
      label: i18n.t('Open Amount'),
      prop: 'attributes.open_amount',
      component: 'FormattedPrice',
      minWidth: 200,
      maxWidth: 200,
      align: 'right',
    },
    {
      label: i18n.t('Retention'),
      prop: 'attributes.retention_amount',
      component: 'FormattedPrice',
      minWidth: 150,
      maxWidth: 200,
      align: 'right',
    },
    {
      label: i18n.t('Net'),
      prop: 'attributes.net_amount',
      component: 'FormattedPrice',
      minWidth: 150,
      maxWidth: 200,
      align: 'right',
    },
    {
      label: i18n.t('Status'),
      prop: 'attributes.status',
      align: 'right',
      maxWidth: 150,
    },
    {
      label: i18n.t('Posted By'),
      prop: 'attributes.posted_by',
      maxWidth: 150,
      component: tableColumns.UserLink,
    },
    {
      label: i18n.t('Posted At'),
      prop: 'attributes.posted_at',
      component: 'FormattedDate',
    },
  ],
  [resourceStatuses.Open]: [
    ...baseColumns,
    {
      label: i18n.t('Created By'),
      prop: 'attributes.created_by',
      minWidth: 100,
      maxWidth: 150,
      component: tableColumns.UserLink,
    },
    {
      label: i18n.t('Open Amount'),
      prop: 'attributes.open_amount',
      component: 'FormattedPrice',
      minWidth: 200,
      maxWidth: 200,
      align: 'right',
    },
    {
      label: i18n.t('Status'),
      prop: 'attributes.status',
      align: 'right',
      maxWidth: 150,
    },
    {
      label: i18n.t('Posted By'),
      prop: 'attributes.posted_by',
      maxWidth: 150,
      component: tableColumns.UserLink,
    },
    {
      label: i18n.t('Posted At'),
      prop: 'attributes.posted_at',
      component: 'FormattedDate',
    },
  ],
  [resourceStatuses.Hold]: [
    ...baseColumns,
    {
      label: i18n.t('Created By'),
      prop: 'attributes.created_by',
      minWidth: 100,
      maxWidth: 150,
      component: tableColumns.UserLink,
    },
    {
      label: i18n.t('Open Amount'),
      prop: 'attributes.open_amount',
      component: 'FormattedPrice',
      minWidth: 200,
      maxWidth: 200,
      align: 'right',
    },
    {
      label: i18n.t('Posted By'),
      prop: 'attributes.posted_by',
      maxWidth: 150,
      component: tableColumns.UserLink,
    },
    {
      label: i18n.t('Posted At'),
      prop: 'attributes.posted_at',
      component: 'FormattedDate',
    },
  ],
  [resourceStatuses.All]: [
    ...baseColumns,
    {
      label: i18n.t('Created By'),
      prop: 'attributes.created_by',
      minWidth: 100,
      maxWidth: 150,
      component: tableColumns.UserLink,
    },
    {
      label: i18n.t('Amount'),
      prop: 'attributes.gross_amount',
      component: 'FormattedPrice',
      minWidth: 200,
      maxWidth: 200,
      align: 'right',
    },
    {
      label: i18n.t('Open Amount'),
      prop: 'attributes.open_amount',
      minWidth: 140,
      maxWidth: 140,
      component: 'FormattedPrice',
      align: 'right',
    },
    {
      label: i18n.t('Status'),
      prop: 'attributes.status',
      align: 'right',
      maxWidth: 150,
    },
    {
      label: i18n.t('Is Receipt'),
      align: 'center',
      prop: 'attributes.is_receipt',
      maxWidth: 100,
      component: 'Status',
    },
    {
      label: i18n.t('Posted At'),
      prop: 'attributes.posted_at',
      component: 'FormattedDate',
    },
  ],
  [resourceStatuses.Voided]: [
    ...baseColumns,
    {
      label: i18n.t('Amount'),
      prop: 'attributes.gross_amount',
      component: 'FormattedPrice',
      minWidth: 200,
      maxWidth: 200,
      align: 'right',
    },
    {
      label: i18n.t('Posted At'),
      prop: 'attributes.posted_at',
      minWidth: 100,
      maxWidth: 150,
      component: 'FormattedDate',
    },
    {
      label: i18n.t('Voided At'),
      prop: 'attributes.voided_at',
      minWidth: 100,
      maxWidth: 150,
      component: 'FormattedDate',
    },
  ]
}

export const TABLE_ACTIONS = {
  [resourceStatuses.Pending]: [
    'post',
    'postBulk',
  ],
  [resourceStatuses.Paid]: [
    'void',
    'voidBulk',
    'groupBy',
  ],
  [resourceStatuses.PartialPaid]: [
    'voidBulk',
    'groupBy',
  ],
  [resourceStatuses.All]: [
    'groupBy',
  ],
  [resourceStatuses.Posted]: [
    'groupBy',
  ],
  [resourceStatuses.Hold]: [
    'groupBy',
  ],
  [resourceStatuses.Open]: [
    'groupBy',
  ],
  [resourceStatuses.Voided]: [
    'groupBy',
  ],
}

export const TABLE_URL_PARAMS = {
  [resourceStatuses.Pending]: {
    status: `${resourceStatuses.Pending},${resourceStatuses.NoPost}`
  },
  [resourceStatuses.Paid]: {
    status: resourceStatuses.Paid
  },
  [resourceStatuses.PartialPaid]: {
    status: resourceStatuses.PartialPaid
  },
  [resourceStatuses.Posted]: {
    status: `${resourceStatuses.Posted},${resourceStatuses.PartialPaid},${resourceStatuses.Hold}`,
  },
  [resourceStatuses.Open]: {
    status: `${resourceStatuses.Posted},${resourceStatuses.PartialPaid},${resourceStatuses.Hold}`,
  },
  [resourceStatuses.Hold]: {
    status: resourceStatuses.Hold,
  },
  [resourceStatuses.Voided]: {
    status: resourceStatuses.Voided
  },
  [resourceStatuses.All]: {},
}

export const InvoiceTypes = {
  Normal: 'normal',
  Manual: 'manual',
  Monthly: 'monthly',
  Quarterly: 'quarterly',
}

export const invoiceTypeOptions = [
  {
    label: i18n.t('Normal'),
    value: InvoiceTypes.Normal,
  },
  {
    label: i18n.t('Manual'),
    value: InvoiceTypes.Manual,
  },
]

export const invoiceStatusOptions = [
  {
    label: i18n.t('Pending'),
    value: resourceStatuses.Pending,
  },
  {
    label: i18n.t('No Post'),
    value: resourceStatuses.NoPost,
  },
]
