<template>
  <job-transactions-table
      :key="`${status}-${type}`"
      :type="type"
      :status="status"
  />
</template>
<script>
  import { transactionTypes, resourceStatuses } from '@/enum/enums'
  import JobTransactionsTable from '@/modules/job-costing/components/JobTransactionsTable'

  export default {
    components: {
      JobTransactionsTable,
    },
    data() {
      return {
        type: transactionTypes.Cost,
        status: resourceStatuses.Pending,
      }
    },
    watch: {
      '$route.meta': {
        immediate: true,
        handler(meta) {
          this.status = meta.status
          this.type = meta.type
        },
      },
    },
  }
</script>
