<template>
  <base-form
      :loading="loading"
      :can-create-another-entity="!model.id"
      :save-text="$t('Create address')"
      :update-text="$t('Update address')"
      layout="modal"
      grid-classes="grid grid-cols-6 gap-x-3"
      @submit="onSubmit"
  >
    <base-input
        v-model="model.name"
        :label="$t('Name')"
        :name="$t('Name')"
        :placeholder="$t('Name')"
        id="name"
        class="col-span-6 md:col-span-2"
        rules="required|max:100"
    />

    <base-select
        v-model="model.type"
        :label="$t('Type')"
        :options="typeOptions"
        class="col-span-6 md:col-span-1"
    />

    <div class="col-span-6"/>

    <base-textarea
        v-model="model.address_1"
        :label="$t('Primary Address 1')"
        :placeholder="$t('Address 1')"
        :rows="1"
        class="col-span-6 md:col-span-3"
        id="address_1"
        rules="required"
    />
    <base-textarea
        v-model="model.address_2"
        :label="$t('Primary Address 2')"
        :placeholder="$t('Address 2')"
        :rows="1"
        class="col-span-6 md:col-span-3"
        id="address_2"
    />
    <base-input
        v-model="model.city"
        :label="$t('City')"
        :placeholder="$t('City')"
        class="col-span-6 md:col-span-2"
        id="city"
        rules="required|max:45"
    />
    <state-select
        v-model="model.state"
        :label="$t('State')"
        :placeholder="$t('State')"
        class="col-span-6 md:col-span-2"
        id="state"
        rules="required|max:2"
    />
    <base-zip-code
        v-model="model.postal_code"
        :label="$t('Postal Code')"
        :placeholder="$t('Postal Code')"
        class="col-span-6 md:col-span-2"
        id="postal_code"
        rules="required|zipCode"
    />

    <div class="col-span-6">
      <h5 class="form-section-title">
        {{ $t('Contact') }}
      </h5>
    </div>
    <base-input
        v-model="model.contact"
        :label="$t('Contact Name')"
        :name="$t('Contact Name')"
        :placeholder="$t('Contact Name')"
        class="col-span-6 md:col-span-2"
        rules="max:100"
    />
    <base-input
        v-model="model.email"
        :label="$t('Email')"
        :name="$t('Email')"
        :placeholder="$t('Email')"
        class="col-span-6 md:col-span-2"
        type="email"
        rules="email"
    />
    <div class="col-span-6 md:col-span-2">
      <base-phone-input
          v-model="model.phone"
      />
    </div>
    <base-textarea
        v-model="model.description"
        :label="$t('Description')"
        :name="$t('Description')"
        :placeholder="$t('Description')"
        :rows="1"
        class="col-span-6"
        rules="max:150"
    />

    <div class="col-span-6">
      <base-checkbox
          v-model="model.primary"
          :label="$t('Primary')"
          id="primary"
      />
    </div>
    <div class="col-span-6">
      <base-checkbox
          v-model="model.active"
          :label="$t('Active')"
          id="active"
      />
    </div>

  </base-form>
</template>
<script lang="ts">
  import axios from 'axios'
  import store from '@/store'
  import { defineComponent } from 'vue'
  import { addressTypes, addressableMorph } from '@/enum/enums'
  import {Address} from "@/modules/common/types/models";

  const companyId = store.getters['company/getCurrentCompanyId']

  const addressableProps = {
    addressable_type: addressableMorph.Company,
    addressable_id: companyId,
  }

  export default defineComponent({
    inheritAttrs: false,
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        loading: false,
        model: {
          name: '',
          type: addressTypes.Warehouse,
          description: '',
          contact: '',
          email: '',
          phone: '',
          address_1: '',
          address_2: '',
          city: '',
          state: '',
          postal_code: '',
          primary: false,
          active: true,
          ...addressableProps,
        } as Address,

        typeOptions: [
          {
            label: this.$t('Billing'),
            value: addressTypes.Billing,
          },
          {
            label: this.$t('Business'),
            value: addressTypes.Business,
          },
          {
            label: this.$t('Home'),
            value: addressTypes.Home,
          },
          {
            label: this.$t('Headquarters'),
            value: addressTypes.Headquarters,
          },
          {
            label: this.$t('Shipping'),
            value: addressTypes.Shipping,
          },
          {
            label: this.$t('Warehouse'),
            value: addressTypes.Warehouse,
          },
        ],
      }
    },
    methods: {
      async onSubmit() {
        try {
          this.loading = true
          if (this.model.id) {
            await axios.put(`/restify/addresses/${this.model.id}`, this.model)
            this.$success('Address updated successfully.')
          } else {
            await axios.post('/restify/addresses', this.model)
            this.$success('Address added successfully.')
          }

          this.$emit('save')
        } catch (err: any) {
          console.warn(err)
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not update address.'))
        } finally {
          this.loading = false
        }
      },
    },
    watch: {
      data: {
        immediate: true,
        handler(address: API.Data<any>) {
          if (!address?.id) {
            return
          }
          this.model = {
            ...this.model,
            ...address.attributes,
            ...addressableProps,
          }
        },
      },
    },
  })
</script>
