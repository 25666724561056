<template>
  <div class="mt-10 sm:mt-0 role-form">
    <base-form v-bind="$attrs"
               :save-text="$t('Create Role')"
               :update-text="$t('Update Role')"
               :loading="loading"
               @cancel="$emit('cancel')"
               @submit="updateRole"
    >
      <div v-if="model.id" class="col-span-6 mb-4 flex justify-end space-x-2">
        <div>
          <RolePermissionsImport @change="onPermissionsImport"/>
        </div>
        <div>
          <BaseButton variant="white" @click="exportPermissions">
            <DownloadIcon class="w-4 h-4 mr-2 text-green-500"/>
            <span>{{$t('Export')}}</span>
          </BaseButton>
        </div>
      </div>
      <div class="col-span-6">
        <base-input v-model="model.name"
                    :label="$t('Name')"
                    :placeholder="$t('Role Name')"
                    :readonly="model.id"
                    id="name"
                    rules="required">
        </base-input>
      </div>
      <div v-if="model.id"
           v-loading="fetchingPermissions"
           class="col-span-6">
        <h4 class="font-medium mb-4">{{ $t('Permissions') }}</h4>
        <div class="mb-4 flex items-center">
          <search-input
              v-model="searchQuery"
              data-test="permission-search"
              class="search-input flex-1"
          >
          </search-input>
          <div class="ml-6 space-x-2 flex items-center">
            <base-switch v-model="expandAllRows"
                         :label-info="$t('Expand all')"
                         inline
                         @change="toggleExpandRows"
            />
          </div>
        </div>
        <NoDataRow v-if="noResults">
          <template #icon>
            &nbsp;
          </template>
          <template #description>
            {{ $t('No permissions matching your query were found') }}
          </template>
        </NoDataRow>
        <el-collapse v-if="!noResults" v-model="activeGroups">
          <el-collapse-item v-for="(group, groupName) in permissionGroups"
                            :key="groupName"
                            :title="formatName(groupName)"
                            :name="groupName">
            <template #title>
              <div class="flex justify-between w-full">
                <span>{{ formatName(groupName) }}</span>
                <StatusBadge :status="getPermissionGroupCount(group)"
                             class="mr-2"
                />
              </div>
            </template>
            <div class="grid grid-cols-2 gap-4 ml-2 mb-2">
              <div v-for="item in group" :key="item.key">
                <div class="text-base pb-2 font-medium">{{formatName(item.key)}}</div>
                <div v-for="permission in item.data" :key="permission.name">
                  <base-checkbox :value="isPermissionEnabled(item, permission.name)"
                                 :id="permission.name"
                                 :key="permission.name"
                                 :label="permission.displayName"
                                 @input="togglePermission({ row: item, value: $event, permissionName: permission.name })"
                  />
                </div>
                <base-checkbox :value="areAllRowPermissionsEnabled(item)"
                               :id="item.key"
                               :key="item.key"
                               :label="$t(`All ${formatName(item.key)} permissions`)"
                               @input="toggleAllRowPermissions(item, $event)"
                />
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>

      <template v-if="model.id" v-slot:footer>
        <span></span>
      </template>
    </base-form>
  </div>
</template>
<script>
  import groupBy from 'lodash/groupBy'
  import camelCase from 'lodash/camelCase'
  import snakeCase from 'lodash/snakeCase'
  import startCase from 'lodash/startCase'
  import RoleService from '@/modules/settings/services/RoleService'
  import Cache from "@/utils/Cache";
  import SearchInput from "@/components/form/SearchInput.vue";
  import NoDataRow from "@/components/table/NoDataRow.vue";
  import { DownloadIcon } from "vue-feather-icons";
  import { saveFileAsJson } from "@/modules/common/util/fileUtils";
  import { formatDate } from "@/plugins/dateFormatPlugin";
  import RolePermissionsImport from "@/modules/settings/components/RolePermissionsImport.vue";

  export default {
    components: {
      RolePermissionsImport,
      DownloadIcon,
      SearchInput,
      NoDataRow,
    },
    props: {
      data: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        loading: false,
        fetchingPermissions: false,
        model: {
          name: '',
        },
        searchQuery: '',
        permissions: [],
        activeGroups: [],
        expandAllRows: false,
      }
    },
    computed: {
      columns() {
        return [
          {
            label: this.$t('Name'),
            prop: 'key',
            maxWidth: 250,
          },
          {
            label: this.$t('View'),
            prop: 'view',
            maxWidth: 100,
            align: 'center'
          },
          {
            label: this.$t('Create'),
            prop: 'create',
            maxWidth: 100,
            align: 'center'
          },
          {
            label: this.$t('Update'),
            prop: 'update',
            maxWidth: 100,
            align: 'center'
          },
          {
            label: this.$t('Delete'),
            prop: 'delete',
            maxWidth: 100,
            align: 'center'
          },
          {
            label: this.$t('All'),
            prop: 'all',
            maxWidth: 100,
            align: 'center'
          },
        ]
      },
      permissionGroups() {
        const permissionGroups = groupBy(this.permissions, 'group')
        const finalGroups = {}
        for (let key in permissionGroups) {
          const subGroup = groupBy(permissionGroups[key], 'sub_group')
          const subGroups = []
          Object.keys(subGroup).forEach(subGroupKey => {
            let permissions = subGroup[subGroupKey].map(permission => {
              return {
                ...permission,
                displayName: this.formatName(permission.name)
              }
            })
            permissions = permissions.filter(p => p.displayName?.toLowerCase().includes(this.searchQuery?.toLowerCase()))
            if (permissions.length === 0) {
              return
            }
            subGroups.push({
              id: subGroupKey,
              key: subGroupKey,
              data: permissions
            })
          })
          if (subGroups.length === 0) {
            continue
          }
          finalGroups[key] = subGroups
        }
        return finalGroups
      },
      noResults() {
        return Object.keys(this.permissionGroups).length === 0
      }
    },
    watch: {
      data: {
        immediate: true,
        handler(value) {
          if (!value) {
            return
          }
          const data = value.attributes || {}
          const name = data.name || ''
          this.model = {
            ...this.model,
            ...data,
            name: startCase(camelCase(name))
          }
        }
      }
    },
    async created() {
      await this.getPermissions()
    },
    methods: {
      async getPermissions() {
        try {
          this.fetchingPermissions = true
          const { data } = await Cache.getRequest('/permissions', {
            ttl: 60 * 60 * 1000,
            params: {
              perPage: 500
            }
          })
          this.permissions = data?.permissions || []
        } finally {
          this.fetchingPermissions = false
        }
      },
      getPermission(row, name) {
        return row.data.find(p => p.name.includes(name))
      },
      getPermissionGroupCount(group) {
        let count = 0
        group.forEach(item => {
          item.data.forEach(permission => {
            const isEnabled = this.isPermissionEnabled(item, permission.name)
            if (isEnabled) {
              count++
            }
          })
        })
        return count
      },
      areAllRowPermissionsEnabled(row) {
        return row.data.every(permission => {
          const existingPermissions = this.model.permissions || []
          return existingPermissions.find(p => p === permission.name) !== undefined
        })
      },
      async toggleAllRowPermissions(row, value) {
        const groupName = startCase(camelCase(row.key))
        row.data.forEach(permission => {
          const isEnabled = this.isPermissionEnabled(row, permission.name)
          if (value && !isEnabled) {
            this.model.permissions.push(permission.name)
          } else if (!value && isEnabled) {
            const index = this.model.permissions.indexOf(permission.name)
            if (index === -1) {
              return
            }
            this.model.permissions.splice(index, 1)
          }
        })

        try {
          await RoleService.updateRole(this.model)
          const word = value ? `enabled` : 'disabled'
          this.$success(this.$t(`Permissions for ${groupName} ${word}`))
        } catch (err) {
          this.$error(this.$t(`Could not update the permissions for ${groupName}`))
        }
      },
      isPermissionEnabled(row, name) {
        const permission = this.getPermission(row, name)
        if (!permission) {
          return
        }
        const existingPermissions = this.model.permissions || []
        return existingPermissions.find(p => p === permission.name) !== undefined
      },
      async togglePermission({ row, value, permissionName }) {
        const permission = this.getPermission(row, permissionName)
        if (!permission) {
          return
        }

        const name = startCase(camelCase(permission.name))
        const isEnabled = this.isPermissionEnabled(row, permissionName)

        if (value && !isEnabled) {
          this.model.permissions.push(permission.name)
        } else if (!value && isEnabled) {
          const index = this.model.permissions.indexOf(permission.name)
          if (index === -1) {
            return
          }
          this.model.permissions.splice(index, 1)
        }
        try {
          await RoleService.updateRole(this.model)
          this.$success(this.$t(`Permissions ${name} updated`))
        } catch (err) {
          this.$error(this.$t(`Could not enable permission ${name}`))
        }
      },
      formatName(name) {
        if (name === 'null') {
          return this.$t('Organization')
        }
        return startCase(camelCase(name))
      },
      async updateRole() {
        try {
          this.loading = true
          const data = {
            ...this.model,
            context: 'company',
            name: snakeCase(this.model.name),
            permissions: [],
          }
          if (!data.id) {
            await RoleService.createRole(data);
            this.$success(this.$t('Role created'))
          }
          this.$emit('save')
        } catch (err) {
          this.$error(this.$t('Could not update the role'))
        } finally {
          this.loading = false
        }
      },
      exportPermissions() {
        // @ts-ignore
        const data = this.model.permissions || []
        const fileName = `Role ${ startCase(this.model.name) } - ${ formatDate(new Date()) }`
        saveFileAsJson(data, `${ fileName }.json`)
      },
      async onPermissionsImport(permissions) {
        if (!permissions) {
          return
        }
        this.model.permissions = permissions
        await this.$nextTick()
        await RoleService.updateRole(this.model)
      },
      toggleExpandRows(value) {
        if (value) {
          this.activeGroups = Object.keys(this.permissionGroups)
        } else {
          this.activeGroups = []
        }
      }
    }
  }
</script>
<style lang="scss">
  .role-form .el-collapse-item .el-collapse-item__header {
    @apply text-base;
  }

  .role-form .table-wrapper__inner {
    @apply shadow-none;
  }
</style>
