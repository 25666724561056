<template>
  <el-dropdown
      trigger="click"
      class="relative inline-block text-left"
  >
    <span class="rounded-md shadow-sm">
      <button type="button"
              @click="showDropdown = !showDropdown"
              class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150">
        {{ $t(selectedPeriod) }}
        <chevron-down-icon class="-mr-1 ml-2 h-5 w-5"/>
      </button>
    </span>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item v-for="(period, index) in periods"
                        :key="index"
                        :class="{'text-primary-600 font-semibold': period.label === selectedPeriod }"
                        class="block cursor-pointer"
                        @click.native="onPeriodSelect(period)"
      >
        {{ $t(period.label) }}
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>
<script>
  import format from "date-fns/format";
  import { ChevronDownIcon } from 'vue-feather-icons'
  import { Dropdown, DropdownItem, DropdownMenu } from 'element-ui'

  const periods = [
    {
      label: 'All periods',
      value: '',
    }
  ]

  for (let i = 1; i <= 12; i++) {
    periods.push({
      label: format(new Date().setMonth(i - 1), 'MMMM'),
      value: i,
    })
  }

  export default {
    components: {
      ChevronDownIcon,
      [Dropdown.name]: Dropdown,
      [DropdownMenu.name]: DropdownMenu,
      [DropdownItem.name]: DropdownItem,
    },
    props: {
      value: {
        type: String
      }
    },
    model: {
      prop: 'value',
      event: 'change',
    },
    data() {
      return {
        showDropdown: false,
        selectedPeriod: 'All periods',
        periods,
      }
    },
    methods: {
      onPeriodSelect(period) {
        this.selectedPeriod = period.label
        this.showDropdown = false
        this.$emit('change', period.value)
      },
    }
  }
</script>
