<template>
  <base-form :loading="loading"
             :save-text="$t('Create OCIP code')"
             :update-text="$t('Update OCIP code')"
             layout="modal"
             @submit="onSubmit"
  >
    <div class="col-span-6">
      <base-input v-model="model.code"
                  :label="$t('Code')"
                  :placeholder="$t('OCIP code')"
                  id="name"
                  rules="required|code">
      </base-input>
    </div>
    <div class="col-span-6">
      <base-input v-model="model.description"
                  :label="$t('Description')"
                  :placeholder="$t('Ocip Description')"
                  id="description"
                  rules="required|max:150">
      </base-input>
    </div>
  </base-form>
</template>
<script>
  import axios from 'axios'

  export default {
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      redirectToListAfterStore: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        loading: false,
        model: {
          code: '',
          description: '',
        },
      }
    },
    watch: {
      data: {
        immediate: true,
        handler(value) {
          this.model = {
            ...this.model,
            ...value,
          }
        },
      },
    },
    methods: {
      async onSubmit() {
        try {
          this.loading = true
          if (this.model.id) {
            await axios.put(`/restify/ocip-codes/${this.model.id}`, this.model)
            this.$success(this.$t('OCIP code updated'))
            this.$emit('save', this.model)
          } else {
            const { data } = await axios.post('/restify/ocip-codes', this.model)
            this.$success(this.$t('OCIP code added'))
            this.$emit('save', data)
          }
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not update the OCIP code'))
        } finally {
          this.loading = false
        }
      },
    },
  }
</script>
