<template>
  <base-form
      :show-buttons="false"
      layout="modal"
      class="line-item-history"
      grid-classes="grid grid-cols-2 gap-4"
  >
    <div class="col-span-1 grid grid-cols-2 gap-x-4">
      <base-select
          v-model="model.format"
          :options="getFormatOptions"
          :label="$t('Format')"
          :placeholder="$t('Format')"
          class="col-span-2 md:col-span-1"
          @change="onChangeFormat"
      />
      <template v-if="model.format !== renderFormats.Summary">
        <base-select
            v-model="model.types"
            :options="filteredTypes"
            :label="$t('Types')"
            :placeholder="$t('Types')"
            :multiple="true"
            :collapse-tags="true"
            value-key="id"
            label-key="abbr"
            class="col-span-2 md:col-span-1"
        />
        <template v-if="model.format === renderFormats.Detail">
          <div class="col-span-2 md:col-span-1 flex items-end">
            <base-input
                v-model="model.periods[0]"
                :placeholder="$t('From period')"
                :name="$t('From period')"
                class="w-1/2"
                id="from-fiscal-year"
                rules="min_value:0|max_value:13"
                @input="filtersApplied = false"
                @blur="onBlurRangeFilter(0, 'periods')"
            />
            <base-input
                v-model="model.fiscal_years[0]"
                :placeholder="$t('From year')"
                :name="$t('From year')"
                :rules="`min_value:0|max_value:${currentYear}`"
                class="w-1/2"
                id="to-fiscal-year"
                @input="filtersApplied = false"
                @blur="onBlurRangeFilter(0, 'fiscal_years')"
            />
          </div>
          <div class="col-span-2 md:col-span-1 flex items-end">
            <base-input
                v-model="model.periods[1]"
                :placeholder="$t('To period')"
                :name="$t('To period')"
                class="w-1/2"
                id="from-period"
                rules="min_value:0|max_value:13"
                @input="filtersApplied = false"
                @blur="onBlurRangeFilter(1, 'periods')"
            />
            <base-input
                v-model="model.fiscal_years[1]"
                :placeholder="$t('To year')"
                :name="$t('To year')"
                :rules="`min_value:0|max_value:${currentYear}`"
                class="w-1/2"
                id="to-period"
                @input="filtersApplied = false"
                @blur="onBlurRangeFilter(1, 'fiscal_years')"
            />
          </div>
        </template>
      </template>
    </div>
    <div class="col-span-2 md:col-span-1">
      <base-detail-card
          :expanded="true"
          :can-expand="false"
      >
        <base-detail-card-row :title="$t('Phase / Code / Chg')" justifyEnd>
          <span class="font-medium">
            {{ formatLineItemCode(budget) }}
          </span>
        </base-detail-card-row>
        <base-detail-card-row :title="$t('Description')" justifyEnd stripe>
          <span class="font-medium">
            {{ budget.description }}
          </span>
        </base-detail-card-row>
      </base-detail-card>
    </div>
    <div v-if="model.format === renderFormats.Detail"
         class="col-span-2 flex justify-end"
    >
      <base-button
          :loading="loading"
          variant="primary"
          @click="onFilter"
      >
        {{ $t('Apply') }}
      </base-button>
    </div>
    <div class="col-span-2">
      <template v-if="model.format !== renderFormats.Detail">
        <line-item-summary :data="data"/>
      </template>
    </div>
    <LineItemTransactions
        v-if="filtersApplied && model.format === renderFormats.Detail"
        :url-params="transactionsUrlParams"
    />
  </base-form>
</template>
<script>
  import { formatLineItemCode } from '@/utils/utils'
  import { moduleAccountTypes } from '@/components/form/util'
  import LineItemSummary from '@/modules/job-costing/components/line-items/LineItemSummary'
  import LineItemTransactions from '@/modules/job-costing/components/line-items/TransactionHistory.vue'

  const renderFormats = {
    Detail: 'detail',
    Summary: 'summary',
    ProductionUnits: 'production-units',
  }

  export default {
    components: {
      LineItemSummary,
      LineItemTransactions,
    },
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        currentYear: new Date().getFullYear(),
        loading: false,
        renderFormats,
        filtersApplied: false,
        moduleAccountTypes,
        model: {
          format: renderFormats.Summary,
          types: [],
          fiscal_years: [],
          periods: [],
        },
      }
    },
    computed: {
      isCostType() {
        return this.budget.type === moduleAccountTypes.Cost
      },
      budget() {
        return this.data.attributes
      },
      transactionsUrlParams() {
        const filters = this.composeIntervalFilter()

        return {
          ...filters,
          format: this.model.format,
          types: this.model.types,
          type_id: this.model.types.join(','),
          addl_source_id: this.budget.id,
          related: 'type,business,account[id|number]',
        }
      },
      filteredTypes() {
        return this.$store.getters['jobCosting/getJobTypesByType'](this.budget.type)
      },
      defaultTypeIds() {
        if (!this.filteredTypes) {
          return []
        }
        return this.filteredTypes.map(type => type.id)
      },
      getFormatOptions() {
        const options = [
          {
            label: this.$t('Detail'),
            value: renderFormats.Detail,
          },
          {
            label: this.$t('Summary'),
            value: renderFormats.Summary,
          },
        ]

        if (!this.model.format === this.isCostType) {
          return options
        }

        return [
          ...options,
          {
            label: this.$t('Production Units'),
            value: renderFormats.ProductionUnits,
          },
        ]
      },
    },
    methods: {
      formatLineItemCode,
      composeIntervalFilter() {
        let { fiscal_years, periods } = this.model

        const options = [
          ...periods,
          ...fiscal_years,
        ].filter(value => value)

        if (options.length === 4) {
          return {
            interval: `${options[0]},${options[2]},${options[1]},${options[3]}`,
          }
        }

        if (fiscal_years[0] && !fiscal_years[1]) {
          fiscal_years[1] = fiscal_years[0]
        }

        if (periods[0] && !periods[1]) {
          periods[1] = periods[0]
        }

        return {
          fiscal_years: fiscal_years.filter(value => value).join(','),
          periods: periods.filter(value => value).join(','),
        }
      },
      async onFilter() {
        this.loading = true
        await this.$nextTick()
        this.filtersApplied = true
        this.loading = false
      },
      onChangeFormat() {
        this.model.types = this.defaultTypeIds
        this.filtersApplied = false
      },
      onBlurRangeFilter(index, field) {
        if (index === 0 && this.model[field][0]) {
          this.model[field][1] = this.model[field][1] || this.model[field][0]
        } else if (index === 1 && !this.model[field][1]) {
          this.model[field][0] = ''
        }

        this.model = {
          ...this.model,
        }
      },
    },
  }
</script>
<style lang="scss">
  .line-item-history {
    input[id^="from-"] {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    input[id^="to-"] {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }
</style>
