<template>
  <BaseDataTable
    v-bind="defaultAttributes"
    :columns="columns"
    :data="getData"
    :total-rows="rowsLength"
    :has-summary="false"
    @force-pagination="forcePagination"
  >

    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template #header="{ row }">
      <JobLink :data="row.header"/>
    </template>

    <template #subtotal="{ subtotal }">
      <td colspan="5" />
      <td >
        {{ subtotal.description }}
      </td>
      <td align="right">
        {{ $formatHours(subtotal.labor_hours) }}
      </td>
      <td align="right">
        {{ $formatHours(subtotal.material_quantity) }}
      </td>
      <td />
      <td align="right">
        {{ $formatHours(subtotal.equipment_hours) }}
      </td>
      <td />
      <td align="right">
        {{ $formatPrice(subtotal.amount) }}
      </td>
    </template>

    <template #html-row="{ htmlData }">
      <tr>
        <td
          v-if="htmlData.spacing"
          :colspan="columns.length"
        >
          <div class="py-1" />
        </td>
      </tr>
    </template>
  </BaseDataTable>
</template>
<script>
import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'
import { sourceTypes } from "@/modules/service-billing/util/service-billing";

export default {
  extends: ReportTableWrapper,
  computed: {
    columns() {
      return [
        {
          label: this.$t('Date'),
          prop: 'date',
          minWidth: 120,
          maxWidth: 120,
          component: 'FormattedDate',
        },
        {
          label: this.$t('Phase <br> Code'),
          prop: 'phase_code',
          minWidth: 50,
          maxWidth: 60,
          align: 'center',
        },
        {
          label: this.$t('Cost <br> Code'),
          prop: 'cost_code',
          minWidth: 50,
          maxWidth: 60,
          align: 'center',
        },
        {
          label: this.$t('Change <br> Order'),
          prop: 'change_order',
          minWidth: 50,
          maxWidth: 80,
          align: 'center',
        },
        {
          label: this.$t('Description'),
          prop: 'description',
          minWidth: 120,
          maxWidth: 150,
          component: 'EntityLink',
          redirectTo: '/job-costing/cost-line-items/{ID}/view',
        },
        {
          label: this.$t('Transaction <br> Description'),
          prop: 'transaction_description',
          minWidth: 120,
          maxWidth: 150,
        },
        {
          label: this.$t('Labor <br> Hours'),
          prop: 'labor_hours',
          minWidth: 80,
          maxWidth: 100,
          align: 'right',
          component: 'FormattedHours',
        },
        {
          label: this.$t('Material <br> Units'),
          prop: 'material_quantity',
          minWidth: 100,
          maxWidth: 120,
          align: 'right',
          component: 'FormattedHours',
        },
        {
          label: this.$t('Material <br> Code'),
          prop: 'material_code',
          minWidth: 100,
          maxWidth: 120,
          align: 'center',
        },
        {
          label: this.$t('Equipment <br> Hours'),
          prop: 'equipment_hours',
          minWidth: 100,
          maxWidth: 120,
          align: 'right',
          component: 'FormattedHours',
        },
        {
          label: this.$t('Equipment <br> Code'),
          prop: 'equipment_code',
          minWidth: 100,
          maxWidth: 120,
          align: 'center',
        },
        {
          label: this.$t('Job Cost'),
          prop: 'amount',
          minWidth: 100,
          maxWidth: 120,
          align: 'right',
          component: 'FormattedPrice',
        },
      ]
    },
  },
  methods: {
    getQuantities(lineItem) {
      let labor_hours
      let equipment_hours
      let material_quantity

      if (lineItem.special_source_type === null) {
        labor_hours = lineItem.quantity
      }
      if (lineItem.special_source_type === sourceTypes.Equipment) {
        equipment_hours = lineItem.quantity
      }
      if (lineItem.special_source_type === sourceTypes.Material) {
        material_quantity = lineItem.quantity
      }

      return {
        labor_hours,
        equipment_hours,
        material_quantity,
      }
      
    },
    getCodes(lineItem) {
      let equipment_code, material_code
      if (lineItem.special_source_type === sourceTypes.Equipment) {
        equipment_code = lineItem.special_source_code
      }
      if (lineItem.special_source_type === sourceTypes.Material) {
        material_code = lineItem.special_source_code
      }

      return {
        equipment_code,
        material_code,
      }
    },
    composeRows(data) {
      data.forEach(group => {
        this.rows.push({
          header: group.job,
        })

        for (const date in group.entries) {
          const entries = group.entries[date]

          entries.forEach(entry => {
            const {
              labor_hours,
              equipment_hours,
              material_quantity
            } = this.getQuantities(entry)

            const {
              equipment_code,
              material_code
            } = this.getCodes(entry)

            let item = {
              ...entry,
              id: entry.line_item_id,
              date,
              labor_hours,
              equipment_hours,
              material_quantity,
              amount: entry.amount,
              equipment_code,
              material_code,
            }

            this.rows.push(item)
          })

          this.rows.push({
            htmlData: {
              spacing: true
            }
          })
        }
        
        const totals = group.totals

        this.rows.push({
          subtotal: {
            description: this.$t('Total Selected Period'),
            amount: totals.amount,
            labor_hours: totals.hours,
            equipment_hours: totals.equipment,
            material_quantity: totals.material,
          }
        })
      })

      return this.rows
    },
  }
}
</script>
