<template>
  <BaseDataTable
      :columns="columns"
      :url="url"
      :url-params="urlParams"
      :add-text="$t('New preliminary notice')"
      actions="search, refresh, view"
      permission="job_pre_notices"
      @add="$router.push('pre-notice/add')"
      ref="table"
      :add-entity-in-new-tab="addEntityInNewTab"
  >
    <template v-slot:job="{row}">
      <JobLink :data="get(row, 'relationships.job')"/>
    </template>
  </BaseDataTable>
</template>
<script>

  export default {
    data() {
      return {
        url: '/restify/job-pre-notices',
        urlParams: {
          related: 'job',
        },
        addEntityInNewTab: true,
      }
    },
    computed: {
      columns() {
        return [
          {
            label: this.$t('Job'),
            prop: 'job',
          },
          {
            label: this.$t('Date Mailed'),
            prop: 'attributes.date_mailed',
            component: 'FormattedDate',
          },
          {
            label: this.$t('Declarer Name'),
            prop: 'attributes.declarer_name',
          },
          {
            label: this.$t('Declarer Title'),
            prop: 'attributes.title',
          },
          {
            label: this.$t('Signed At (city, state)'),
            prop: 'attributes.signed_location',
          },
          {
            label: this.$t('Notice Date'),
            prop: 'attributes.notice_date',
            component: 'FormattedDate',
          },
          {
            label: this.$t('Estimated Price'),
            prop: 'attributes.est_price',
            component: 'FormattedPrice',
            align: 'right',
          },
        ]
      },
    },
  }
</script>
