function focusOnElement(element, direction = 'next') {
  //add all elements we want to include in our selection
  const focussableElements = 'a:not([tabindex="-1"]), button:not([tabindex="-1"]), input[type=text]:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])';
  if (!document.activeElement) {
    return
  }
  const exceptions = ['form', 'input', 'select']
  if (exceptions.includes(document.activeElement.tagName.toLowerCase())) {
    return
  }
  const elements = element.querySelectorAll(focussableElements) || []
  const focussable = Array.prototype.filter.call(elements, (element) => {
    //check for visibility while always include the current activeElement
    return element.offsetWidth > 0 || element.offsetHeight > 0 || element === document.activeElement
  });
  const index = focussable.indexOf(document.activeElement);
  if (index > -1 && direction === 'next') {
    const nextElement = focussable[index + 1] || focussable[0];
    nextElement.focus();
  }
  if (index > -1 && direction === 'prev') {
    const prevElement = focussable[index - 1] || focussable[focussable.length - 1];
    prevElement.focus();
  }

}

export function initKeyboardFocusEvents(element) {
  if (!element) {
    return
  }
  element.addEventListener('keydown', (evt) => {
    if (evt.code === 'ArrowRight') {
      focusOnElement(element, 'next')
    } else if (evt.code === 'ArrowLeft') {
      focusOnElement(element, 'prev')
    }
  })

}
