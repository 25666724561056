<template>
  <div class="px-3 sm:px-6 detail-row items-center"
       :class="{
          'bg-gray-50': stripe,
          'flex space-x-4': !vertical,
          'flex flex-col space-y-4': vertical
      }"
  >
    <template v-if="!vertical">
      <dt
        :title="title"
        class="text-sm font-medium text-gray-500 min-w-[160px] md:min-w-[220px] truncate"
      >
        <slot name="title">
          {{ title }}
        </slot>
      </dt>
      <dd :class="{
       'flex justify-end': justifyEnd,
       'justify-between': !justifyEnd,
    }"
          class="text-sm text-gray-900 py-2 sm:py-0 sm:mt-0 flex-1 flex flex-col sm:flex-row w-full"
      >
        <slot v-if="!inputVisible"></slot>
        <base-field-edit :entity="entity"
                         :field="field"
                         :entity-name="entityName"
                         class="mt-2 sm:mt-0"
                         @toggle-input="inputVisible = $event"
        />
      </dd>

    </template>
    <template v-else>
      <div class="flex flex-col w-full mb-4">
        <span class="font-semibold text-sm">
          {{ title }}
        </span>
        <span v-if="!isLoading()" class="text-gray-600 text-sm">
          <slot/>
        </span>
        <span v-if="isLoading()" class="bg-gray-100 placeholder-loading-animation rounded-md h-4 w-20"></span>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    title: [String, Object],
    stripe: Boolean,
    justifyEnd: Boolean,
    field: String,
    vertical: Boolean,
  },
  data() {
    return {
      inputVisible: false,
    }
  },
  inject: ['entity', 'entityName', 'isLoading'],
}
</script>
<style scoped lang="scss">
.detail-row {
  @apply transition-all duration-75;

  @screen sm {
    min-height: 42px;
  }
}

.detail-row :deep(a) {
  @apply text-gray-900 hover:underline;
}

.detail-row:hover {
  box-shadow: 0 3px 20px 0 rgba(16, 21, 39, 0.05);
}
</style>
