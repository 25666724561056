<template>
  <entity-select
    url="/restify/jobs"
    :transformData="transformData"
    :url-params="allUrlParams"
    :dataFilters="dataFilters"
    :table-columns="tableColumns"
    v-bind="$attrs"
    v-on="$listeners"
    :filter-options="filterOptions"
    :name="$attrs.name || $t('Job')"
    :placeholder="$attrs.placeholder || $t('Select Job')"
    :label="$attrs.label !== undefined ? $attrs.label : $t('Job')"
    :add-label="$t('Add new Job')"
    :add-entity="addEntity"
    :edit-entity="editEntity"
    :set-default-option="editEntity"
    :label-format="formatJobLabel"
    :id="$attrs.id || 'job-select'"
    :hide-label="hideLabel"
    :value-key="valueKey"
    :initial-value="initialValue"
    class="w-full"
  />
</template>
<script>
import uniqBy from 'lodash/uniqBy'

export default {
  inheritAttrs: false,
  props: {
    urlParams: {
      type: Object,
      default: () => ({
        sort: 'number',
      }),
    },
    valueKey: {
      type: String,
      value: 'id',
    },
    addEntity: {
      type: Boolean,
      default: true,
    },
    editEntity: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    excludeIds: {
      type: Array,
      default: () => [],
    },
    sortBy: {
      type: String,
      default: 'number',
    },
    initialValue: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      dataFilters: {
        showActive: true,
      },
    }
  },
  computed: {
    tableColumns() {
      return [
        {
          minWidth: 100,
          maxWidth: 150,
          name: this.$t('Number'),
          prop: 'attributes.number',
        },
        {
          minWidth: 200,
          maxWidth: 200,
          name: this.$t('Description'),
          prop: 'attributes.description',
        },
        {
          minWidth: 120,
          maxWidth: 120,
          name: this.$t('Status'),
          prop: 'attributes.status',
          component: 'Status',
          filter: {
            type: 'switch',
            prop: 'showActive',
            tooltip: this.$t('Only show open jobs'),
            onChange: (value) => {
              this.dataFilters.showActive = value
            },
          },
        },
      ]
    },
    showInactiveSetting() {
      return this.$store.getters['settings/getFilterSetting']('show_inactive_jobs')
    },
    allUrlParams() {
      return {
        sort: this.sortBy,
        ...this.urlParams,
        status: this.dataFilters.showActive ? 'open' : null,
      }
    },
  },
  watch: {
    showInactiveSetting: {
      handler() {
        this.dataFilters.showActive = !this.showInactiveSetting
      },
      immediate: true
    }
  },
  methods: {
    transformData(data) {
      return data.map((job) => ({
        ...job,
        isInactive: job.attributes.status !== 'open',
      }))
    },
    filterOptions(options) {
      if (this.excludeIds.length) {
        return options.filter(o => {
          return !this.excludeIds.includes(o.id)
        })
      }
      return uniqBy(options, 'id')
    },
    formatJobLabel(job) {
      const { number, description } = job.attributes
      let label = number
      if (description) {
        label += ` (${description})`
      }
      return label
    },
  },
}
</script>
