<template>
  <div>
    <router-link v-if="data.id" :to="invoiceLink">
      {{ number }}
    </router-link>
    <span v-else>
      {{ number }}
    </span>
  </div>
</template>
<script>

export default {
  name: 'InvoiceLink',
  props: {
    id: String,
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    invoiceLink() {
      const id = this.data?.id || this.id
      return `/accounts-payable/invoices/${id}/view`
    },
    number() {
      return this.data?.attributes?.number || this.data?.number || ''
    },
  }
}
</script>
