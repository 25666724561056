<template>
  <base-tooltip
      placement="bottom"
  >
    <div class="shortcuts-icon cursor-pointer">
      <svg aria-hidden="true"
           xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"
           class="w-4 h-4 text-gray-500">
        <path fill="currentColor"
              d="M528 448H48c-26.51 0-48-21.49-48-48V112c0-26.51 21.49-48 48-48h480c26.51 0 48 21.49 48 48v288c0 26.51-21.49 48-48 48zM128 180v-40c0-6.627-5.373-12-12-12H76c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm96 0v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm96 0v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm96 0v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm96 0v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm-336 96v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm96 0v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm96 0v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm96 0v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm-336 96v-40c0-6.627-5.373-12-12-12H76c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm288 0v-40c0-6.627-5.373-12-12-12H172c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h232c6.627 0 12-5.373 12-12zm96 0v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12z"
              class=""></path>
      </svg>
    </div>

    <template v-slot:content>
      <div class="w-full flex flex-col shortcuts-content bg-white border border-gray-100 shadow">
        <div class="text-sm text-gray-800 pb-2">
          {{ $t('Keyboard Shortcuts') }}
        </div>
        <div v-for="shortcut in shortcuts"
             :key="shortcut.key"
             class="py-3 border-t border-gray-200 bg-gray-100 -mx-2 px-2">
          <span class="bg-white rounded font-medium text-gray-900 px-1.5 py-1 mr-2">
            {{ shortcut.key }}
          </span>
          <span class="text-gray-700">
            {{ shortcut.description }}
          </span>
        </div>
      </div>
    </template>
  </base-tooltip>
</template>
<script>
  import { Popover } from 'element-ui'

  export default {
    name: 'DialogShortcuts',
    components: {
      [Popover.name]: Popover,
    },
    computed: {
      shortcuts() {
        return [
          {
            key: 'Esc',
            description: this.$t('closes popup'),
          },
          {
            key: 'Tab',
            description: this.$t('focus next input or element'),
          },
          {
            key: 'Shift + Tab',
            description: this.$t('focus previous input or element'),
          },
          {
            key: 'Ctrl/Cmd + Enter',
            description: this.$t('saves or updates form'),
          },
        ]
      }
    }
  }
</script>
<style lang="scss" scoped>
  .shortcuts-icon {
    @apply absolute;
    right: 50px;
    top: 23px;
    @screen md {
      right: 45px;
      top: 20px;
    }
  }

  .shortcuts-content {
    @apply -m-2.5 px-2 pt-2 rounded overflow-hidden;
    min-width: 300px;
  }
</style>
