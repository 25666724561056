export const paymentTypes = {
  DirectDeposit: 'direct-deposit',
  Check: 'check',
  CreditCard: 'credit-card',
  Manual: 'manual',
  JointPayment: 'joint-payment',
}

export const paymentTypeOptions = () => {

}

export function defaultsToDirectDeposit(vendor = {}) {
  const { dd_status, default_all_pay_to_dd } = vendor?.attributes || {}
  return dd_status === 'active' && default_all_pay_to_dd
}

export function getPaymentType(vendor) {
  if (defaultsToDirectDeposit(vendor)) {
    return 'direct-deposit'
  }
  return 'check'
}

export function isCreditCardOrManual(payment) {
  return [paymentTypes.CreditCard, paymentTypes.Manual].includes(payment?.type)
}
