import { reactive, Ref } from 'vue'
import { GridReadyEvent } from '@ag-grid-community/core';
import  { beforePrint } from "@/components/ag-grid/gridContext";

type Grid = GridReadyEvent & {
  gridId?: string
}
let printTriggerCount = 0

const PrintColLimits = {
  MaxWidth: 160,
  MinWidth: 140
}

function mapColumnForPrint(col: any) {
  col.previousMaxWidth = col.maxWidth
  col.previousMinWidth = col.minWidth
  col.previousWidth = col.width

  if (col?.maxWidth > PrintColLimits.MaxWidth) {
    col.maxWidth = PrintColLimits.MaxWidth
  }
  if (col?.minWidth > PrintColLimits.MinWidth) {
    col.minWidth = PrintColLimits.MinWidth
  }
  if (col.children) {
    col.children = col.children.map(mapColumnForPrint)
  }
  return col
}

function resetColumnAfterPrint(col: any) {
  col.maxWidth = col.previousMaxWidth
  col.minWidth = col.previousMinWidth
  if (col.children) {
    col.children = col.children.map(resetColumnAfterPrint)
  }
  return col
}

function resetColumnsAfterPrint(colDefs: any[]) {
  colDefs = colDefs?.map(resetColumnAfterPrint)
  return colDefs
}

function mapColumnsForPrint(colDefs: any[]) {
  return colDefs?.map((col: any) => mapColumnForPrint(col))
}

export function setPrinterFriendly(grid: Grid, props: any, state: any) {
  if (state.printReady) {
    return
  }
  grid.api.setDomLayout('print')
  // const gridElement = document.querySelector<HTMLElement>(`#${grid.gridId} .ag-root-wrapper`)
  // if (gridElement) {
  //     gridElement.style.width = ''
  //     gridElement.style.height = ''
  // }
  let colDefs = grid.api.getColumnDefs() || [];
  colDefs = mapColumnsForPrint(colDefs)
  grid.api.setColumnDefs(colDefs)
  grid.columnApi.autoSizeAllColumns()
  state.printReady = true

  // const gridRoot = document.querySelector<HTMLElement>(`#${grid.gridId}`)
  // if (gridRoot) {
  //     gridRoot.style.width = `${gridElement?.clientWidth}px`
  // }
}

export function usePrinterFriendly(grid: Ref<GridReadyEvent>, props: any) {
  const state = reactive({
    columns: [],
    printReady: false,
    colDefs: []
  })

  const result = {
    setPrinterFriendly: () => setPrinterFriendly(grid.value, props.value, state),
  }
  // @ts-ignore
  window.setPrinterFriendly = result.setPrinterFriendly
  return result
}

export function printAction() {
  beforePrint()
  setTimeout(() => {
    window.print()
  }, 100)
}

export function isPrintPage() {
  return window.location.href.includes('/print')
}


export function wrapAgGridInTable(el: HTMLElement) {
  const agGrid = el.querySelector('.ag-root-wrapper-body');
  if (!agGrid) {
    return
  }
  const table = document.createElement('table');
  table.classList.add('ag-root');
  agGrid.parentNode.appendChild(table);
  table.appendChild(agGrid)

  const thead = document.createElement('thead');
  const tr = document.createElement('tr');
  const th = document.createElement('th');
  th.style.padding = '0px'
  th.style.border = 'none'

  tr.appendChild(th);
  thead.appendChild(tr);

  const agGridHeader = agGrid.querySelector('.ag-header');
  th.appendChild(agGridHeader);
  table.appendChild(thead);

  // body
  const tbody = document.createElement('tbody');
  const tr2 = document.createElement('tr');
  const td = document.createElement('td');
  td.style.padding = '0px'
  td.style.border = 'none'

  tr2.appendChild(td);
  tbody.appendChild(tr2);

  const agGridBody = agGrid.querySelector('.ag-body-viewport');
  td.appendChild(agGridBody);
  table.appendChild(tbody);

  // Footer
  const tfoot = document.createElement('tfoot');
  const tr3 = document.createElement('tr');
  const td2 = document.createElement('td');
  td2.style.padding = '0px'
  td2.style.border = 'none'

  tr3.appendChild(td2);
  tfoot.appendChild(tr3);

  const agGridFooter = agGrid.querySelector('.ag-floating-bottom');
  td2.appendChild(agGridFooter);
  table.appendChild(tfoot);
}

export async function prepareTableForPrint(el: HTMLElement, params: any) {
  params.api.setDomLayout('print')
  params.api.sizeColumnsToFit();
}
