<template>
  <div>
    <layout-tabs
        v-show="!$route.params.id"
        :items="filteredItems"
    />
    <router-view/>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        items: [
          {
            name: this.$t('Cost Line Items'),
            path: '/job-costing/cost-line-items',
            permission: 'line_items_show',
          },
          {
            name: this.$t('Income Line Items'),
            path: '/job-costing/income-line-items',
            permission: 'line_items_show',
          },
        ],
      }
    },
    computed: {
      filteredItems() {
        return this.items.filter(item => this.$can(item.permission))
      },
    },
  }
</script>
