<template>
  <base-button
      :variant="variant"
      :size="iconOnly ? 'icon': undefined"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <DownloadIcon
        :class="{
            'w-4 h-4': iconOnly,
            'w-5 h-5 mr-2': !iconOnly,
        }"
    />
    <span v-if="!iconOnly">
      <slot>{{ $t('Download') }}</slot>
    </span>
  </base-button>
</template>
<script>
import { DownloadIcon } from 'vue-feather-icons'

export default {
  name: 'DownloadButton',
  components: {
    DownloadIcon,
  },
  props: {
    variant: {
      type: String,
      default: 'green-light',
    },
    iconOnly: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
