<template>
  <div>
    <transition enter-active-class="transform transition ease-in-out duration-100"
                enter-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="transform transition ease-in-out duration-75"
                leave-class="opacity-100"
                leave-to-class="opacity-0"
                appear
    >
      <div v-show="open"
           class="fixed inset-0 top-0 transition-opacity">
        <div class="absolute inset-0 bg-gray-600 opacity-75"/>
      </div>
    </transition>
    <transition enter-active-class="transform transition ease-in-out duration-100"
                enter-class="translate-x-full"
                enter-to-class="translate-x-0"
                leave-active-class="transform transition ease-in-out duration-75"
                leave-class="translate-x-0"
                leave-to-class="translate-x-full"
                appear
    >
      <div class="fixed inset-0 overflow-hidden"
           v-if="open">
        <div class="absolute inset-0 overflow-hidden"
             @mousedown.self.stop="closeDrawer">
          <div class="absolute inset-y-0 right-0 pl-10 max-w-full flex">
            <div class="w-screen max-w-xl">
              <div class="h-full flex flex-col bg-white shadow-xl pt-4 overflow-y-scroll">
                <div class="px-4">
                  <div class="flex items-start justify-between">
                    <h2 id="slide-over-title">
                      <slot name="title">
                        {{title}}
                      </slot>
                    </h2>
                    <div class="ml-3 h-7 flex items-center">
                      <button
                          @click="closeDrawer"
                          class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <span class="sr-only">Close panel</span>
                        <!-- Heroicon name: outline/x -->
                        <svg class="h-6 w-6"
                             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="mt-4 relative flex-1"
                     :class="{'px-4 sm:px-6': horizontalPadding}"
                >
                  <slot></slot>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>

  export default {
    props: {
      open: {
        type: Boolean,
        default: false
      },
      appendToBody: {
        type: Boolean,
        default: true
      },
      horizontalPadding: {
        type: Boolean,
        default: true
      },
      title: String,
    },
    model: {
      prop: 'open',
      event: 'change'
    },
    methods: {
      closeDrawer() {
        this.$emit('change', false)
      },
      onKeyDown(event) {
        if (event.code === 'Escape' && this.open) {
          this.closeDrawer()
        }
      }
    },
    mounted() {
      if (this.appendToBody) {
        document.body.appendChild(this.$el);
      }
      document.addEventListener("keydown", this.onKeyDown)
    },
    destroyed() {
      // if appendToBody is true, remove DOM node after destroy
      if (this.appendToBody && this.$el && this.$el.parentNode) {
        this.$el.parentNode.removeChild(this.$el);
      }
      document.removeEventListener("keydown", this.onKeyDown)
    }
  }
</script>
