export const promptTypes = {
  Primary: 'primary',
  Light: 'light',
  Success: 'success',
  Warning: 'warning',
  Danger: 'danger',
  DangerLight: 'danger-light',
  Consent: 'consent',
}

export default {
  install(Vue) {
    Vue.prototype.$promptType = promptTypes
  }
}
