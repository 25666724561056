<template>
  <div v-loading="loading">
    <CategoryForm
        v-if="!loading"
        :data="category"
        :readonly="$route.path.includes('view')"
    />
  </div>
</template>
<script>
  import axios from 'axios'
  import CategoryForm from '@/modules/service-billing/components/settings/CategoryForm'

  export default {
    components: {
      CategoryForm,
    },
    data() {
      return {
        category: {},
        loading: false,
      }
    },
    methods: {
      async getClassCode() {
        try {
          this.loading = true

          const { data } = await axios.get(`/restify/service-billing-categories/${this.$route.params.id}?related=serviceBillingTypes`)

          this.category = {
            attributes: data.attributes,
            costTypes: data?.relationships?.serviceBillingTypes || [],
          }
        } catch (err) {
          console.warn(err)
        } finally {
          await this.$nextTick()
          this.loading = false
        }
      },
    },
    async mounted() {
      await this.getClassCode()
    },
  }
</script>
