<template>
  <BaseCard class="border work-order-amounts-card">
    <div class="text-sm flex flex-col space-y-4">
      <div :title="$t('Cost')"
           class="detail-row">
        <IconAccountsPayable class="detail-icon"/>
        <div>
          <span class="text-xs text-gray-500">{{ $t('Cost To Date') }}</span>
          <div>{{ $formatPrice(workOrderData.cost_amount) }}</div>
        </div>
      </div>
      <div :title="$t('Income')"
           class="detail-row">
        <IconAccountsReceivable class="detail-icon"/>
        <div>
          <span class="text-xs text-gray-500">{{ $t('Income To Date') }}</span>
          <div>{{ $formatPrice(workOrderData.income_amount) }}</div>
        </div>
      </div>
      <div :title="$t('Difference')"
           class="detail-row">
        <IconOpenAmount class="detail-icon"/>
        <div>
          <span class="text-xs text-gray-500">{{ $t('Margin') }}</span>
          <div>{{ $formatPrice(workOrderData.income_amount - workOrderData.cost_amount) }}</div>
        </div>
      </div>
    </div>
  </BaseCard>
</template>
<script lang="ts" setup>
import {computed, PropType} from 'vue'
import {WorkOrder, WorkSite} from '@/modules/common/types/models'
import {HashIcon, UserCheckIcon, UserPlusIcon} from 'vue-feather-icons'
import Data = API.Data;

const props = defineProps({
  workOrder: {
    type: Object as PropType<Data<WorkOrder>>,
    required: true,
  },
})

const workOrderData = computed<WorkOrder>(() => {
  return props.workOrder?.attributes || {}
})
</script>
<style>
.work-order-amounts-card .detail-icon path {
  stroke: currentColor;
}
</style>
