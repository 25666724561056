<template>
  <div>
    <base-tooltip :content="$t('Refresh data')" :tabindex="-1">
      <base-button variant="white"
                   size="sm"
                   @click="$emit('click')">
        <RefreshCwIcon class="w-5 h-5 text-gray-500"/>
      </base-button>
    </base-tooltip>
  </div>
</template>
<script>
  import { RefreshCwIcon } from 'vue-feather-icons'

  export default {
    components: {
      RefreshCwIcon,
    }
  }
</script>
<style>
</style>
