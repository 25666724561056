<template>
  <JobForm
      :key="renderKey"
      @create-another="renderKey++"
  />
</template>
<script>
  import JobForm from '@/modules/job-costing/components/JobForm'

  export default {
    components: {
      JobForm,
    },
    data() {
      return {
        renderKey: 1,
      }
    },
  }
</script>
