<template>
  <base-form-dialog
    v-bind="$attrs"
    v-on="$listeners"
    :title="$t('Upload Signature')"
  >
    <base-form
      layout="modal"
      :model="model"
      :loading="loading"
      :show-cancel="true"
      :save-text="$t('Upload Signature')"
      @cancel="onCancel"
      @submit="onSubmit"
    >
      <BaseTabs
        v-model="activeTab"
        :tabs="tabs"
        labelKey="label"
        class="col-span-6"
      >
        <SignaturePad
          ref="signaturePad"
          v-show="activeTab === TabKeys.Signature"
        />
        <ImageCropper
          v-if="activeTab === TabKeys.File"
          ref="imageUpload"
          :entityId="entityId"
          entity="banks"
        />
      </BaseTabs>
    </base-form>
  </base-form-dialog>
</template>
<script>
import axios from "axios";
import SignaturePad from "@/modules/settings/components/SignaturePad.vue";
import ImageCropper from "@/components/common/files/ImageCropper.vue";

const TabKeys = {
  Signature: 'signature',
  File: 'file',
}
export default {
  components: {
    ImageCropper,
    SignaturePad,
  },
  props: {
    name: {
      type: String,
      default: null,
    },
    entity: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      model: {
        file: null,
        document_type_id: null,
      },
      loading: false,
      documentTypes: [],
      signaturePad: null,
      TabKeys,
      activeTab: TabKeys.Signature,
      tabs: [
        {
          label: this.$t('Draw Signature'),
          name: TabKeys.Signature,
        },
        {
          label: this.$t('Upload File'),
          name: TabKeys.File,
        }
      ]
    }
  },
  computed: {
    entityId() {
      return this.$route.params.id
    }
  },
  methods: {
    async getDocumentTypes() {
      const { data } = await axios.get('/restify/document-types', {
        params: {
          perPage: 100,
        }
      })
      this.documentTypes = data
      const SignatureKey = 'Signature'
      const signatureType = this.documentTypes.find(type => type.attributes.description === SignatureKey)
      if (signatureType) {
        this.model.document_type_id = signatureType.id
      } else {
        const { data } = await axios.post('/restify/document-types', {
          description: SignatureKey,
          used_in: [this.$modules.SETTINGS]
        })
        this.model.document_type_id = data.id
      }
    },
    onCancel() {
      this.$emit('update:open', false)
    },
    getFile() {
      if (this.activeTab === TabKeys.Signature) {
        const fileName = `${this.name} Signature.png`
        const image = this.$refs.signaturePad?.saveImage()
        return new File([image], fileName)
      } else if (this.activeTab === TabKeys.File) {
        return this.$refs.imageUpload?.saveImage()
      }
    },
    getUploadUrl() {
      if (!this.entityId || !this.entity) {
        return
      }
      return `/restify/${this.entity}/${this.entityId}/actions?action=upload-document`
    },
    async onSubmit() {
      try {
        this.loading = true
        const file = this.getFile()
        if (!file) {
          return
        }
        const formData = new FormData()
        const fileName = `${this.name} Signature`
        formData.append('name', fileName)
        formData.append('document_type_id', this.model.document_type_id)
        formData.append('file', file)
        const { data } = await axios.post(this.getUploadUrl(), formData)
        this.$emit('save', { url: data.url, id: data.document_id })
      } finally {
        this.loading = false
      }
    },
  },
  mounted() {
    this.getDocumentTypes()
  }
}
</script>
<style>
</style>
