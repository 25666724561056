<template>
  <ProgressBillingForm
      :data="billing"
      @refresh="getBilling"
  />
</template>
<script>
  import ProgressBillingForm from '@/modules/accounts-receivable/components/progress-bllings/ProgressBillingForm'

  export default {
    components: {
      ProgressBillingForm,
    },
    computed: {
      billing() {
        return this.$store.state.accountsReceivable.currentBilling
      },
    },
    methods: {
      async getBilling() {
        await this.$store.dispatch('accountsReceivable/getBilling', this.billing.id)
      }
    }
  }
</script>
