import i18n from "@/i18n";

export const StimulsoftPrintEntities = {
  PurchaseOrder: 'purchase-order',
  LumpSumBilling: 'lump-sum-billing',
  UnitPrice: 'unit-price-billing',
  CostPlus: 'cost-plus-billing',
  ServiceInvoice: 'service-invoice',
  ProgressBilling: 'progress-billing',
  Billing: 'billing',
  FinancialReport: 'financial-report',
  AccountReconciliation: 'reconciliation',
  Waivers: 'waiver',
  WorkOrder: 'work-order',
}

export const StimulsoftPrintTemplates = {
  [StimulsoftPrintEntities.PurchaseOrder]: {
    title: i18n.t('Purchase Order'),
    showOrientation: false,
    templates: [
      {
        label: i18n.t('Fixed Price'),
        value: '/reports/po.mrt',
      },
      {
        label: i18n.t('Unit Price'),
        value: '/reports/po-unit.mrt',
      },
      {
        label: i18n.t('Subcontract Agreement'),
        value: '/reports/po-subcontract.mrt',
      }
    ]
  },
  [StimulsoftPrintEntities.LumpSumBilling]: {
    title: i18n.t('Lump Sum Billing'),
    showOrientation: false,
    templates: [
      {
        label: i18n.t('Form'),
        value: '/reports/lump-sum.mrt',
      },
    ]
  },
  [StimulsoftPrintEntities.CostPlus]: {
    title: i18n.t('Cost Plus Billing'),
    showOrientation: false,
    templates: [
      {
        label: i18n.t('Summary'),
        value: '/reports/cost_plus_summary.mrt',
      },
    ]
  },
  [StimulsoftPrintEntities.UnitPrice]: {
    title: i18n.t('Unit Price Billing'),
    showOrientation: false,
    templates: [
      {
        label: i18n.t('Combined'),
        value: '/reports/unit-price-combined.mrt',
      },
    ]
  },
  [StimulsoftPrintEntities.ProgressBilling]: {
    title: i18n.t('Progress Billing'),
    showOrientation: false,
    templates: [
      {
        label: i18n.t('AIA Form G703'),
        value: '/reports/progress-g703.mrt',
      },
      {
        label: i18n.t('AIA Form G702'),
        value: '/reports/progress-g702.mrt',
      },
      {
        label: i18n.t('Combined Invoice'),
        value: '/reports/progress-combined.mrt',
      },
    ]
  },
  [StimulsoftPrintEntities.ServiceInvoice]: {
    title: i18n.t('Service Invoice'),
    showOrientation: false,
    templates: [
      {
        label: i18n.t('Standard'),
        value: '/reports/service-invoice-standard.mrt',
      },
      {
        label: i18n.t('Grid'),
        value: '/reports/service-invoice-grid.mrt',
      },
    ]
  },
  [StimulsoftPrintEntities.FinancialReport]: {
    title: i18n.t('Financial Report'),
    showOrientation: false,
    templates: [
      {
        label: i18n.t('Standard'),
        value: '/reports/financial-standard.mrt',
      },
    ]
  },
  [StimulsoftPrintEntities.WorkOrder]: {
    title: i18n.t('Standard'),
    showOrientation: false,
    templates: [
      {
        label: i18n.t('Standard'),
        value: '/reports/work-order.mrt',
      },
    ]
  }
}
