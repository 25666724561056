<template>

  <base-data-table
      :data="tableData"
      :columns="columns"
      :limitMaxHeight="false"
      :addDefaultColumns="false"
      :showActionsColumn="false"
      :show-pagination="false"
      :has-summary="true"
      :compact="true"
  >
    <template #subtotal="{row}">
      <td>
        {{ $t(row.subtotal.type) }}:
      </td>
      <td class="top-border">{{ $formatPrice(row.subtotal?.amount) }}</td>
      <td class="top-border">{{ $formatPrice(row.subtotal?.prior) }}</td>
      <td class="top-border">{{ $formatPrice(row.subtotal?.period) }}</td>
      <td class="top-border">{{ $formatPrice(row.subtotal?.total) }}</td>
      <td class="top-border">{{ $formatPrice(row.subtotal?.amount_to_date) }}</td>
      <td class="top-border">{{ $formatPrice(row.subtotal?.remaining) }}</td>
    </template>
  </base-data-table>

</template>
<script>
  const totalTypeNameMap = {
    cost: 'Total Costs',
    income: 'Total Billed',
  }

  export default {
    props: {
      summary: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      columns() {
        return [
          {
            label: this.$t('Job Summary'),
            prop: 'type',
            align: 'left',
            minWidth: 120,
            maxWidth: 150,
            summary: () => this.$t('Billed less Cost:'),
          },
          {
            label: this.$t('Total <br> Budget <br> (1)'),
            prop: 'amount',
            minWidth: 120,
            maxWidth: 150,
            component: 'FormattedPrice',
            align: 'right',
            summary: () => this.$formatPrice(this.summary.income_less_cost?.amount),
          },
          {
            label: this.$t('Prior to <br> Period <br> (2)'),
            prop: 'prior',
            minWidth: 120,
            maxWidth: 150,
            component: 'FormattedPrice',
            align: 'right',
            summary: () => this.$formatPrice(this.summary.income_less_cost?.prior),
          },
          {
            label: this.$t('Period <br> Amount <br> (3)'),
            prop: 'period',
            minWidth: 120,
            maxWidth: 150,
            component: 'FormattedPrice',
            align: 'right',
            summary: () => this.$formatPrice(this.summary.income_less_cost?.period),
          },
          {
            label: this.$t('Total to end <br> of period <br> (2 + 3)'),
            prop: 'total',
            minWidth: 120,
            maxWidth: 150,
            component: 'FormattedPrice',
            align: 'right',
            summary: () => this.$formatPrice(this.summary.income_less_cost?.total),
          },
          {
            label: this.$t('Total job <br> to-date <br> (4)'),
            prop: 'amount_to_date',
            minWidth: 120,
            maxWidth: 150,
            component: 'FormattedPrice',
            align: 'right',
            summary: () => this.$formatPrice(this.summary.income_less_cost?.amount_to_date),
          },
          {
            label: this.$t('Remaining <br> budget <br> (1 - 4)'),
            prop: 'remaining',
            minWidth: 120,
            maxWidth: 150,
            component: 'FormattedPrice',
            align: 'right',
            summary: () => this.$formatPrice(this.summary.income_less_cost?.remaining),
          },
        ]
      },
      tableData() {
        let rows = []

        Object.keys(this.summary).forEach(key => {
          const data = this.get(this.summary, `[${key}]`)

          if (data.summary) {
            rows.push(...data.summary)
            rows.push({
              subtotal: {
                type: totalTypeNameMap[key],
                period: data.period,
                prior: data.prior,
                amount: data.amount,
                amount_to_date: data.amount_to_date,
                total: data.total,
                remaining: data.remaining,
              },
            })

          }
        })

        return rows
      },
    },
  }
</script>
