<template>
  <BaseFormDialog
    v-bind="$attrs"
    v-on="$listeners"
    size="lg"
    :title="$t(`Generate Pre Notice File`)"
    :appendToBody="true"
    @close="onClose"
  >
    <div class="direct-deposit-dialog">
      <payroll-direct-deposit v-bind="$attrs"/>
    </div>
  </BaseFormDialog>
</template>
<script>
import PayrollDirectDeposit from "@/modules/payroll/pages/payroll/batch/payroll-direct-deposit.vue";

export default {
  components: {
    PayrollDirectDeposit
  },
  methods: {
    onClose() {
      this.$emit("close");
      const query = {}
      this.$router.replace({
        path: this.$route.path,
        query,
      })
    }
  }
}
</script>
<style>
.direct-deposit-dialog .form-section-title {
  @apply px-4;
}
</style>
