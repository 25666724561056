<template>
  <SendEmailForm
      :data="billing"
      :action-url="actionUrl"
      :action-name="actionName"
      :entity="entity"
      :subject="subject"
      @save="emit('save')"
      @cancel="emit('cancel')"
  />
</template>
<script lang="ts" setup>
import {computed} from 'vue'
import i18n from "@/i18n";
import {billingTypes} from "@/modules/accounts-receivable/pages/billings/billings";
import SendEmailForm from "@/components/form/SendEmailForm.vue";

const props = defineProps({
  billing: {
    type: Object,
    default: () => ({}),
  },
  billingType: {
    type: String,
    default: billingTypes.Service
  },
  entity: {
    type: String,
  }
})
const emit = defineEmits(['save', 'cancel'])

const subject = computed(() => {
  const titleMap = {
    [billingTypes.Service]: i18n.t('Service Invoice'),
    [billingTypes.Progress]: i18n.t('Progress Billing'),
    [billingTypes.UnitPrice]: i18n.t('Unit Price Billing'),
    [billingTypes.CostPlus]: i18n.t('Cost Plus Billing'),
    [billingTypes.LumpSum]: i18n.t('Lump Sum Billing'),
  }
  const title = titleMap[props.billingType]
  return `${title} #${props.billing.attributes.number}`
})

const actionUrl = computed(() => {
  return `/restify/billings/${props.billing.id}/actions`
})

const actionName = computed(() => {
  const actions = {
    [billingTypes.Service]: 'send-service-billing-mail',
    [billingTypes.Progress]: 'send-progress-billing-mail',
    [billingTypes.UnitPrice]: 'send-unit-price-billing-mail',
    [billingTypes.CostPlus]: 'send-cost-plus-billing-mail',
    [billingTypes.LumpSum]: 'send-lump-sum-billing-mail',
  }
  return actions[props.billingType]
})
</script>
