<template>
  <button type="button"
          class="close-button"
          aria-label="Close"
          v-bind="$attrs"
          v-on="$listeners">
    <i
        :class="{
          'text-2xl': size === 'xl',
          'text-lg': size === 'lg',
          'text-md': size === 'md',
          'text-sm': size === 'sm',
        }"
        class="close-icon el-icon el-icon-close"
    ></i>
  </button>
</template>
<script>
  export default {
    name: 'close-button',
    inheritAttrs: false,
    props: {
      size: {
        type: String,
        default: 'xl'
      }
    }
  }
</script>
<style scoped lang="scss">
  .close-button {
    @apply p-1 rounded flex items-center;
    &:hover {
      @apply bg-gray-100;

      .close-icon {
        @apply text-gray-700;
      }
    }

    .close-icon {
      @apply font-semibold text-gray-500;
    }

    .icon-small {
      @apply font-semibold text-gray-500;
    }
  }
</style>
