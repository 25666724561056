<template>
  <div
    v-bind="$attrs"
    :class="{
        'fixed bottom-0 bg-white border p-6 shadow-xl mx-auto left-[50%] ml-[-290px] w-[600px] rounded visible': fixed && data,
        'hidden': fixed && !data,
    }"

  >
    <div v-if="fixed">
      <CloseButton class="absolute right-0 top-0"
                   @click="$emit('close')"/>
    </div>
    <template v-if="data">
      <div class="border border-gray-100 md:grid md:grid-cols-3 bottom-summary">
        <slot></slot>
      </div>
    </template>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import CloseButton from "@/components/common/CloseButton.vue";


export default defineComponent({
  components: {
    CloseButton,
  },
  props: {
    data: {
      type: [Object, null],
      default: () => ({}),
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    fixed: {
      type: Boolean,
      default: true,
    },
  },
  computed: {},
  methods: {
    cleanup() {
      try {
        if (!this.fixed) {
          return
        }
        document.body.removeChild(this.$el)
      } catch (err) {
        console.warn(err)
      }
    }
  },
  mounted() {
    if (this.fixed) {
      document.body.appendChild(this.$el)
    }
  },
  beforeDestroy() {
    this.cleanup()
  }
})
</script>
<style lang="scss">
.bottom-summary {
  a {
    @apply text-gray-500 hover:underline;
  }
}
</style>
