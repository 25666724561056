<template>
  <base-data-table
    v-bind="defaultAttributes"
    :columns="columns"
    :data="getData"
    :total-rows="rowsLength"
    :has-summary="false"
    :data-loading="dataLoading"
    class="no-borders certified-payroll"
    @force-pagination="forcePagination"
  >

    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template #html-row="{htmlData}">
      <tr v-if="htmlData.recordType === RecordTypes.FileHeader" class="content-row">
        <td colspan="3">
          <div class="flex flex-col">
            <span class="mb-2">{{ $t('File Header Record (type 1)') }}</span>
            <div class="record-row">
              <span>{{ $t('Priority Code') }}</span>
              <span>: {{htmlData.priorityCode}}</span>
            </div>
            <div class="record-row">
              <span>{{ $t('Immediate Destination') }}</span>
              <span>: {{htmlData.immediateDestination}}</span>
            </div>
            <div class="record-row">
              <span>{{ $t('Immediate Origin') }}</span>
              <span>: {{htmlData.immediateOrigin}}</span>
            </div>
            <div class="record-row">
              <span>{{ $t('File Creation Date') }}</span>
              <span>: {{htmlData.transmissionDate}}</span>
            </div>
            <div class="record-row">
              <span>{{ $t('File Creation Time') }}</span>
              <span>: {{htmlData.transmissionTime}}</span>
            </div>
          </div>
        </td>
        <td colspan="3">
          <div class="flex flex-col">
            <span class="mb-2"></span>
            <div class="record-row">
              <span>{{ $t('File I.D. Modifier') }}</span>
              <span>: {{htmlData.fileIDModifier}}</span>
            </div>
            <div class="record-row">
              <span>{{ $t('Record Size') }}</span>
              <span>: {{htmlData.recordSize}}</span>
            </div>
            <div class="record-row">
              <span>{{ $t('Blocking Factor') }}</span>
              <span>: {{htmlData.blockingFactor}}</span>
            </div>
            <div class="record-row">
              <span>{{ $t('Format Code') }}</span>
              <span>: {{htmlData.formatCode}}</span>
            </div>
          </div>
        </td>
        <td colspan="3">
          <div class="flex flex-col">
            <span class="mb-2"></span>
            <div class="record-row">
              <span>{{ $t('Immediate Destination Name') }}</span>
              <span>: {{htmlData.immediateDestinationName}}</span>
            </div>
            <div class="record-row">
              <span>{{ $t('Immediate Origin Name') }}</span>
              <span>: {{htmlData.immediateOriginName}}</span>
            </div>
            <div class="record-row">
              <span>{{ $t('Reference Code') }}</span>
              <span>: {{htmlData.referenceCode}}</span>
            </div>
          </div>
        </td>
      </tr>
      <tr v-if="htmlData.recordType === RecordTypes.BatchHeader" class="content-row">
        <td colspan="3">
          <div class="flex flex-col">
            <span class="mb-2">{{ $t('Batch Header Record (type 5)') }}</span>
            <div class="record-row">
              <span>{{ $t('Service Class Code') }}</span>
              <span>: {{htmlData.serviceClassCode}}</span>
            </div>
            <div class="record-row">
              <span>{{ $t('Company Name') }}</span>
              <span>: {{htmlData.companyName}}</span>
            </div>
            <div class="record-row">
              <span>{{ $t('Discretionary Date') }}</span>
              <span>: {{htmlData.companyDistretionaryData}}</span>
            </div>
            <div class="record-row">
              <span>{{ $t('Company I.D.') }}</span>
              <span>: {{htmlData.companyID}}</span>
            </div>
            <div class="record-row">
              <span>{{ $t('Standard Entry Class') }}</span>
              <span>: {{htmlData.standardEntryClassCode}}</span>
            </div>
          </div>
        </td>
        <td colspan="3">
          <div class="flex flex-col">
            <span class="mb-2"></span>
            <div class="record-row">
              <span>{{ $t('Company Entry Description') }}</span>
              <span>: {{htmlData.companyEntryDescription}}</span>
            </div>
            <div class="record-row">
              <span>{{ $t('Company Descriptive Date') }}</span>
              <span>: {{htmlData.companyDescriptiveDate}}</span>
            </div>
            <div class="record-row">
              <span>{{ $t('Effective Entry Date') }}</span>
              <span>: {{htmlData.effectiveEntryDate}}</span>
            </div>
          </div>
        </td>
        <td colspan="3">
          <div class="flex flex-col">
            <span class="mb-2"></span>
            <div class="record-row">
              <span>{{ $t('Originator Status Code') }}</span>
              <span>: {{htmlData.originatorStatusCode}}</span>
            </div>
            <div class="record-row">
              <span>{{ $t('Originator DFI Identification') }}</span>
              <span>: {{htmlData.originatingDFI_ID}}</span>
            </div>
            <div class="record-row">
              <span>{{ $t('Batch Number') }}</span>
              <span>: {{htmlData.batchNumber}}</span>
            </div>
          </div>
        </td>
      </tr>
      <tr v-if="htmlData.recordType === RecordTypes.BatchControl" class="content-row">
        <td colspan="3">
          <div class="flex flex-col">
            <span class="mb-2">{{ $t('Batch Control Record (type 8)') }}</span>
            <div class="record-row">
              <span>{{ $t('Service Class Code') }}</span>
              <span>: {{htmlData.serviceClassCode}}</span>
            </div>
            <div class="record-row">
              <span>{{ $t('Entry/Addenda Count') }}</span>
              <span>: {{htmlData.entryOrAddendaCount}}</span>
            </div>
            <div class="record-row">
              <span>{{ $t('Entry Hash') }}</span>
              <span>: {{htmlData.entryHash}}</span>
            </div>
          </div>
        </td>
        <td colspan="3">
          <div class="flex flex-col">
            <span class="mb-2"></span>
            <div class="record-row">
              <span>{{ $t('Total Debit Entry Amount') }}</span>
              <span>: {{htmlData.totalDebitEntryAmount}}</span>
            </div>
            <div class="record-row">
              <span>{{ $t('Total Credit Entry Amount') }}</span>
              <span>: {{htmlData.totalCreditEntryAmount}}</span>
            </div>
            <div class="record-row">
              <span>{{ $t('Company I.D.') }}</span>
              <span>: {{htmlData.companyID}}</span>
            </div>
            <div class="record-row">
              <span>{{ $t('Message Authentication Code') }}</span>
              <span>: {{htmlData.messageAuthenticateCode}}</span>
            </div>
          </div>
        </td>
        <td colspan="3">
          <div class="flex flex-col">
            <span class="mb-2"></span>
            <div class="record-row">
              <span>{{ $t('(Reserved)') }}</span>
              <span>: {{ htmlData.reservedBlank }}</span>
            </div>
            <div class="record-row">
              <span>{{ $t('Originator DFI Identification') }}</span>
              <span>: {{htmlData.originatingDFI_ID}}</span>
            </div>
            <div class="record-row">
              <span>{{ $t('Batch Number') }}</span>
              <span>: {{htmlData.batchNumber}}</span>
            </div>
          </div>
        </td>
      </tr>
      <tr v-if="htmlData.recordType === RecordTypes.FileControl" class="content-row">
        <td colspan="3">
          <div class="flex flex-col">
            <span class="mb-2">{{ $t('Batch Control Record (type 9)') }}</span>
            <div class="record-row">
              <span>{{ $t('Batch Count') }}</span>
              <span>: {{htmlData.BatchCount}}</span>
            </div>
            <div class="record-row">
              <span>{{ $t('Block Count') }}</span>
              <span>: {{htmlData.BlockCount}}</span>
            </div>
            <div class="record-row">
              <span>{{ $t('Entry/Addenda Count') }}</span>
              <span>: {{htmlData.EntryOrAddendaCount}}</span>
            </div>
            <div class="record-row">
              <span>{{ $t('Entry Hash') }}</span>
              <span>: {{htmlData.EntryHash}}</span>
            </div>
          </div>
        </td>
        <td colspan="3">
          <div class="flex flex-col">
            <span class="mb-2"></span>
            <div class="record-row">
              <span>{{ $t('Total Debit Entry Amount') }}</span>
              <span>: {{htmlData.TotalDebitEntryAmount}}</span>
            </div>
            <div class="record-row">
              <span>{{ $t('Total Credit Entry Amount') }}</span>
              <span>: {{htmlData.TotalCreditEntryAmount}}</span>
            </div>
            <div class="record-row">
              <span>{{ $t('(Reserved)') }}</span>
              <span>: {{ htmlData.ReservedBlank }}</span>
            </div>
          </div>
        </td>
        <td colspan="3"/>
      </tr>
      <tr v-if="htmlData.recordType === RecordTypes.BlockTrailing" class="content-row">
        <td colspan="9">
          {{htmlData.record}}
        </td>
      </tr>
    </template>
  </base-data-table>
</template>
<script>

import ReportTableWrapper from "@/modules/common/components/reports/ReportTableWrapper.vue";
const RecordTypes = {
  FileHeader: "1",
  BatchHeader: "5",
  Entry: "6",
  BatchControl: "8",
  FileControl: "9",
  BlockTrailing: "B",
}
export default {
  extends: ReportTableWrapper,
  props: {
    dataLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      RecordTypes
    }
  },
  computed: {
    columns() {
      return [
        {
          label: this.$t('Transact Code'),
          prop: 'transactionCode',
          minWidth: 100,
        },
        {
          label: this.$t('Receiving DFI ID'),
          prop: 'receivingDFI_ID',
          minWidth: 100,
        },
        {
          label: this.$t('DFI Account #'),
          prop: 'RDFIAccountNumber',
          minWidth: 100,
        },
        {
          label: this.$t('Amount ($/cc)'),
          prop: 'transactionAmount',
          minWidth: 100,
        },
        {
          label: this.$t('Individual I.D.'),
          prop: 'individualID',
          minWidth: 100,
        },
        {
          label: this.$t('Individual Name'),
          prop: 'individualName',
          minWidth: 100,
        },
        {
          label: this.$t('Discretionary Data'),
          prop: 'discretionaryData',
          minWidth: 100,
        },
        {
          label: this.$t('Addenda Indicator'),
          prop: 'addendaIndicator',
          minWidth: 100,
        },
        {
          label: this.$t('Trace Number'),
          prop: 'traceNumber',
          minWidth: 100,
        },
      ]
    }
  },
  methods: {
    composeRows(data) {
      data.file_records.forEach(row => {
        row.id = crypto.randomUUID()
        const recordType = row.recordType
        if (recordType === RecordTypes.Entry) {
          this.rows.push(row)
        } else {
          this.rows.push({
            htmlData: row,
          })
        }
      })
      return this.rows
    }
  }
}
</script>
<style scoped lang="scss">
.record-row {
  @apply flex justify-between;
  > span {
   flex: 1;
  }
}
tr.content-row {
  td {
    background: theme('colors.gray.50') !important;
    border-bottom: 1px dashed theme('colors.gray.400') !important;
  }
}
</style>
