import store from "@/store";

export default {
  install(Vue) {
    Vue.mixin({
      computed: {
        createByMeFilter() {
          const hideCreatedByMe = store.getters['settings/getFilterSetting']('hide_created_by_me') || true
          if (hideCreatedByMe) {
            return {}
          }
          return {
            created_by: store.state.auth.user.id,
          }
        },
        jobStatusFilter() {
          const showInactive = store.getters['settings/getFilterSetting']('show_inactive_jobs') || false
          if (showInactive) {
            return {}
          }
          return {
            status: 'open',
          }
        },
        employeeStatusFilter() {
          const showInactive = store.getters['settings/getFilterSetting']('show_inactive_employees') || false
          if (showInactive) {
            return {}
          }
          return {
            show_inactive: `${showInactive}`,
          }
        },
        onViewResourcePage() {
          return !!this.$route.params.id
        }
      }
    })
  }
}
