<template>
  <div>
    <BaseDataTable :columns="columns"
                   :edit-entity-url-query="editEntityUrlQuery"
                   :add-text="$t(`New billing rate type`)"
                   ref="table"
                   view-entity-url-query="/accounts-receivable/settings/billing-rate-types"
                   url="/restify/billing-rate-types"
                   actions="search, refresh"
                   permission="employees"
                   @add="onRowAdd"
                   @edit="onRowEdit"
    />
    <BaseFormDialog v-if="showDialog"
                    :title="rowToEdit ? $t('Edit Billing Type') : $t('Add Billing Type')"
                    @close="onEventTrigger"
                    :open.sync="showDialog">
      <BillingRateTypeForm :data="rowToEdit"
                           @cancel="onEventTrigger"
                           @save="onEventTrigger"
      />
    </BaseFormDialog>
  </div>
</template>
<script>
  import BillingRateTypeForm from '@/modules/payroll/components/rates/BillingRateTypeForm'

  export default {
    components: {
      BillingRateTypeForm,
    },
    data() {
      return {
        editEntityUrlQuery: '?id={ID}',
        rowToEdit: null,
        showDialog: false,
        columns: [
          {
            label: this.$t('Title'),
            prop: 'attributes.title',
            component: 'EntityLink',
            redirectTo: '/accounts-receivable/settings/billing-rate-types?id={ID}',
          },
        ],
      }
    },
    methods: {
      onRowEdit(row) {
        this.showDialog = true
        this.rowToEdit = row
      },
      onRowAdd() {
        this.showDialog = true
      },
      refreshTable() {
        this.$refs.table.refresh()
      },
      onEventTrigger() {
        this.rowToEdit = null
        this.showDialog = false
        this.refreshTable()
      },
    },
  }
</script>
