<template>
  <div class="truncate">
    <router-link v-if="id && isLink"
                 :to="sourceLink"
                 :target="target"
                 class="truncate"
    >
      <span class="truncate">
        <template v-if="showSourceType">
          {{ sourceType }}
        </template>
        {{ sourceName }}
      </span>
    </router-link>
    <span v-else class="truncate">
       {{ sourceName }}
    </span>
  </div>
</template>
<script>
  import {
    getSpecialSourceLink,
    getSpecialSourceName,
    getSpecialSourceType
  } from "@/components/grid-table/utils/cost-center-cell";

  export default {
    props: {
      id: String,
      data: {
        type: Object,
        default: () => ({}),
      },
      target: String,
      isLink: {
        type: Boolean,
        default: true,
      },
      showSourceType: {
        type: Boolean,
        default: true,
      }
    },
    computed: {
      sourceLink() {
        return getSpecialSourceLink(this.data);
      },
      sourceName() {
        return getSpecialSourceName(this.data);
      },
      sourceType() {
        return getSpecialSourceType(this.data)
      }
    },
  }
</script>
