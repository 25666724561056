<template>
  <base-form
      :loading="loading"
      :save-text="$t('Create district')"
      :update-text="$t('Update district')"
      layout="modal"
      grid-classes="grid grid-cols-6 gap-x-3"
      @cancel="$emit('cancel')"
      @submit="onSubmit"
  >
    <state-select
        v-model="model.state"
        :label="$t('State')"
        :placeholder="$t('State')"
        class="col-span-6 md:col-span-2"
        rules="required"
        id="state"
    />
    <code-input
      v-model="model.code"
      :model="model"
      :resource-name="globalResources.SalesTaxDistricts"
      class="col-span-6 md:col-span-2"
    />
    <base-input
        v-model="model.county"
        :label="$t('County')"
        :placeholder="$t('County')"
        class="col-span-6 md:col-span-2"
        rules="max:15"
    />
    <base-input
        v-model="model.description"
        :label="$t('Description')"
        :placeholder="$t('Description')"
        class="col-span-6"
        rules="max:150"
    />
    <account-select
        v-model="model.account"
        :name="$t('Account')"
        :label="$t('Account')"
        value-key="number"
        class="col-span-6 md:col-span-2"
        id="account"
        clearable
    />
    <sub-account-select
        v-model="model.subaccount"
        :name="$t('Sub Account')"
        :label="$t('Sub Account')"
        value-key="number"
        class="col-span-6 md:col-span-2"
        id="subaccount"
        clearable
    />
    <base-input
        v-model="model.sales_tax_percent"
        v-bind="$validator.salesTaxPercent"
        :label="$t('Sales Tax Percent')"
        :name="$t('Sales Tax Percent')"
        class="col-span-6 md:col-span-2"
        id="sales_tax_percent"
        type="number"
        format="percent"
    />
  </base-form>
</template>
<script>
  import axios from 'axios'
  import { globalResources } from "@/components/form/util";

  export default {
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        globalResources,
        loading: false,
        model: {
          state: '',
          code: '',
          county: '',
          description: '',
          account_id: '',
          subaccount_id: '',
          sales_tax_percent: 0,
        },
      }
    },
    methods: {
      async onSubmit() {
        try {
          this.loading = true

          if (this.model.id) {
            await axios.put(`/restify/districts/${this.model.id}`, this.model)
            this.$success(this.$t('Sales tax district updated'))
          } else {
            await axios.post('/restify/districts', this.model)
            this.$success(this.$t('Sales tax district created'))
          }

          this.$emit('save', this.model)
        } catch (err) {

          if (err.handled) {
            console.warn(err)
            return
          }

          this.$error(this.$t('Could not update the sales tax district'))
        } finally {
          this.loading = false
        }
      },
    },
    watch: {
      data: {
        immediate: true,
        handler(value) {
          if (!value?.id) {
            return
          }

          this.model = {
            ...this.model,
            ...value.attributes,
          }
        },
      },
    },
  }
</script>
