<template>
  <JobForm :data="job"
           @refresh="getJob"
  />
</template>
<script>
  import JobForm from '@/modules/job-costing/components/JobForm'

  export default {
    components: {
      JobForm,
    },
    computed: {
      job() {
        const job = this.$store.state.jobCosting.currentJob
        return {
          ...job?.attributes,
          ...job?.relationships,
        }
      },
    },
    methods: {
      getJob() {
        this.$store.dispatch('jobCosting/getJob', this.$route.params.id)
      }
    }
  }
</script>
