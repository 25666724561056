<template>
    <div class="relative overflow-hidden inline-block cursor-pointer">
        <base-button :variant="variant" size="sm">
            <slot></slot>
        </base-button>
        <input v-bind="$attrs"
               v-on="$listeners"
               type="file"
               name="myfile"
               class="absolute left-0 top-0 right-0 bottom-0 opacity-0 text-5xl shadow-none"
        />
    </div>
</template>
<script>
  export default {
    inheritAttrs: false,
    props: {
      variant: {
        type: String,
        default: 'white'
      }
    }
  }
</script>
