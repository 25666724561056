<template>
  <base-grid-select
      v-on="$listeners"
      v-bind="$attrs"
      :filterMethod="filterMethod"
      :resource-name="$globalResources.ServiceBillingCodes"
      ref="select"
  >
    <template #custom-filter>
      <div class="flex items-center cursor-pointer justify-center">
        <base-checkbox
            v-model="showAllServiceCodes"
            :label="$t('Show All Service Codes')"
            id="show-all-service-codes"
            @change="onChangeFilterState"
        />
      </div>
    </template>
  </base-grid-select>
</template>
<script>

  export default {
    props: {
      // * Used for display only codes attached to work order
      serviceBillingCodeIds: Array,
      showAllCodes: Boolean,
    },
    data() {
      return {
        showAllServiceCodes: true,
      }
    },
    methods: {
      filterMethod(option) {
        const codes = this.serviceBillingCodeIds || []
        return this.showAllServiceCodes ? true : codes.includes(option.id)
      },
      onChangeFilterState(state) {
        this.$emit('on-change-filter', state)
      },
    },
    mounted() {
      this.showAllServiceCodes = this.showAllCodes
    },
  }
</script>
