<template>
  <div class="import-data mt-4">
    <ImportForm
        v-bind="importSettings"
        @success="redirectToList"
    />
  </div>
</template>
<script>
  import ImportForm from '@/modules/common/components/import/ImportForm';
  import { getInitialMapping } from '@/modules/payroll/utils/timecardImportColumns';

  const colMappings = {
    Union: {
      value: 'union',
      label: 'Union',
      alternativeLabels: ['Union Code'],
      required: true,
    },
    SourceType: {
      value: 'source_type',
      label: 'Source Type',
      alternativeLabels: ['Source'],
      required: mapping => {
        return mapping.source_code !== -1
      },
    },
    SourceCode: {
      value: 'source_code',
      label: 'Source Code',
      alternativeLabels: [],
      required: mapping => {
        return mapping.source_type !== -1
      }
    },
    CraftCode: {
      value: 'craft_code',
      label: 'Craft Code',
      alternativeLabels: ['Craft'],
      required: true,
    },
    CraftLevel: {
      value: 'craft_level',
      label: 'Craft Level',
      alternativeLabels: ['Level'],
      required: true,
    },
    Rate: {
      value: 'rate',
      label: 'Rate',
      alternativeLabels: [],
      required: true,
    },
    StartDate: {
      value: 'start_date',
      label: 'Start Date',
      alternativeLabels: [],
    }
  }

  const options = [
    colMappings.Union,
    colMappings.SourceCode,
    colMappings.SourceType,
    colMappings.CraftCode,
    colMappings.CraftLevel,
    colMappings.Rate,
    colMappings.StartDate,
  ]

  export default {
    components: {
      ImportForm,
    },
    data() {
      return {
        importSettings: {
          url: '/restify/union-rates/import',
          columnOptions: options,
          columnMappings: colMappings,
          initialMapping: getInitialMapping(options),
          type: 'union-import',
          resource: 'union-rate',
          showTemplateDownload: true,
        },
      }
    },
    methods: {
      async redirectToList() {
        await this.$router.push('/payroll/rates/unions')
      },
    },
  }
</script>
