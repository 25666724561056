import i18n from "@/i18n";

export function getDefaultPeriods() {
  return [
    { number: 1, name: 'January', is_open: true },
    { number: 2, name: 'February', is_open: true },
    { number: 3, name: 'March', is_open: true },
    { number: 4, name: 'April', is_open: true },
    { number: 5, name: 'May', is_open: true },
    { number: 6, name: 'June', is_open: true },
    { number: 7, name: 'July', is_open: true },
    { number: 8, name: 'August', is_open: true },
    { number: 9, name: 'September', is_open: true },
    { number: 10, name: 'October', is_open: true },
    { number: 11, name: 'November', is_open: true },
    { number: 12, name: 'December', is_open: true },
  ]
}

export const getWeekDays = () => [
  {
    label: i18n.t('Monday'),
    value: 1,
    id: 1,
  },
  {
    label: i18n.t('Tuesday'),
    value: 2,
    id: 2
  },
  {
    label: i18n.t('Wednesday'),
    value: 3,
    id: 3
  },
  {
    label: i18n.t('Thursday'),
    value: 4,
    id: 4
  },
  {
    label: i18n.t('Friday'),
    value: 5,
    id: 5
  },
  {
    label: i18n.t('Saturday'),
    value: 6,
    id: 6
  },
  {
    label: i18n.t('Sunday'),
    value: 7,
    id: 7
  }
]


export function getMonthName(number) {
  return getDefaultPeriods().find(period => period.number === number + 1).name;
}

export function getCurrentPeriod (periods = []) {
  const monthNumber = new Date().getMonth() + 1
  const monthName = getMonthName(new Date().getMonth())
  const period = periods.find(period => period.name === monthName)
  return period?.number || monthNumber
}

export function removeTimezoneFromDate(date) {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
}
