<template>
  <div>
    <AgDataTable
        :columns="tableColumns"
        :data="rules"
        :add-text="$t('New Rule')"
        :get-row-id="getRowId"
        :pagination="false"
        :stopEditingWhenCellsLoseFocus="true"
        :delete-action="deleteRule"
        editType="fullRow"
        dom-layout="autoHeight"
        actions="add,delete"
        @cell-value-changed="onCellValueChanged"
        @add="addRule"
        @delete="deleteRule"
    >
    </AgDataTable>
    <ul class="text-gray-700 p-4 flex flex-col space-y-2">
      <li
          v-for="rule in rules || []"
          :key="rule.id"
          class="flex flex-wrap break-words space-x-2"
      >
        <p>{{ getRuleText(rule) }}</p>
        <p v-if="rule.carryover_limit_hours">
          {{ $t('Carryover limit ') }} {{ rule.carryover_limit_hours }}
        </p>
      </li>
    </ul>
  </div>
</template>
<script>
import { AccrualMethodLabels, AccrualMethods } from "@/modules/payroll/utils/accrualMethods";
  import { getRuleText } from "@/modules/payroll/utils/timeOffUtils";
  import pluralize from "pluralize";
  import axios from "axios";

  const emptyRule = {
    _localId: crypto.randomUUID(),
    local_id: 1,
    milestone: 0,
    rate: 1,
    rate_unit: 1,
    carryover_limit_hours: 0,
    max_accrual_hours_per_pay_period: 0,
    max_accrual_hours_per_year: 0,
  }
  export default {
    props: {
      timeOffPolicy: {
        type: Object,
        default: () => ({})
      },
      policyRules: {
        type: Array,
        default: () => [],
      }
    },
    data() {
      return {
        rules: []
      }
    },
    computed: {
      tableColumns() {
        const method = this.timeOffPolicy?.accrual_method
        const accrualMethodLabel = AccrualMethodLabels[method] || ''
        const isGivenMethod = [AccrualMethods.PerAnniversaryYear, AccrualMethods.PerCalendarYear].includes(method)
        return [
          {
            prop: 'rate',
            label: isGivenMethod ? this.$t('Given hours') : this.$t('Earned hours'),
            editable: true,
            valueFormatter: params => {
              return `${params.value} ${pluralize('hours', params.value)}`
            },
          },
          {
            prop: 'rate_unit',
            headerValueGetter: () => {
              return accrualMethodLabel
            },
            editable: true,
          },
          {
            prop: 'milestone',
            label: this.$t('Milestone (years)'),
            editable: true,
          },
          {
            prop: 'carryover_limit_hours',
            label: this.$t('Max carryover hours'),
            editable: true,
          },
        ]
      },
    },
    methods: {
      getRuleText(rule) {
        return getRuleText(rule, this.timeOffPolicy?.accrual_method)
      },
      getRowId(params) {
        return params.data.id || params.data.local_id
      },
      async addRule() {
        const rule = {
          ...emptyRule,
          time_off_policy_id: this.timeOffPolicy?.id,
        }
        this.rules.push({
          ...rule,
          local_id: new Date().getTime(),
        })
        const { data } = await axios.post(`/restify/tenure-accrual-rules`, rule)
        const createdRule = {
          ...(data?.attributes || {}),
          id: data?.id,
        }
        this.rules.splice(this.rules.length - 1, 1, createdRule)
      },
      initRules(policyRules) {
        this.rules = policyRules.map(r => r.attributes)
      },
      async onCellValueChanged(params) {
        if (!params.data?.id) {
          return
        }
        await this.updateRule(params.data)
      },
      async updateRule(rule) {
        const { data } = await axios.put(`/restify/tenure-accrual-rules/${rule.id}`, rule)
        const updatedRule = {
          ...(data?.attributes || {}),
          id: data?.id,
        }
        const index = this.rules.findIndex(r => r.id === rule.id)
        this.rules.splice(index, 1, updatedRule)
      },
      async deleteRule(rule)  {
        const index = this.rules.findIndex(r => r.id === rule.id)
        if (index === -1) {
          return
        }
        this.rules.splice(index, 1)
        await axios.delete(`/restify/tenure-accrual-rules/${rule.id}`)
      }
    },
    watch: {
      policyRules: {
        immediate: true,
        handler(policyRules) {
          if (!policyRules?.length) {
            return
          }
          this.initRules(policyRules)
        }
      }
    }
  }
</script>
