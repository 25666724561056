<template>
  <ProofListing
      entity="invoice"
      url="/restify/invoices/actions?action=post-invoices-proof-listing"
      post-url="/restify/invoices/action?action=post-invoices"
      ids-field="invoice_ids"
      @data-fetch="data = $event"
      #default="{ loading }"
  >
    <AgDataTable
        :data="flatData"
        :columns="columns"
        :data-loading="loading"
        :pagination="false"
        :groupDefaultExpanded="-1"
        :groupIncludeFooter="true"
        :groupRowRendererParams="groupRowRendererParams"
        :compact="true"
        :no-borders="true"
        groupDisplayType="groupRows"
        dom-layout="autoHeight"
    />

    <div class="mt-4">
      <AgDataTable
          :data="totalsData"
          :columns="totalColumns"
          :pagination="false"
          :no-borders="true"
          :compact="true"
          class="w-full"
          dom-layout="autoHeight"
      />
    </div>

    <div class="grid lg:grid-cols-2 gap-4 mt-8 print:break-inside-avoid">
      <div>
        <h5 class="form-section-title">
          {{ $t('Cost Type Summary') }}
        </h5>
        <ProofListingCostTypeSummary
            :data="data.cost_type_summary"
            :loading="loading"
        />
      </div>
      <div>
        <h5 class="form-section-title">
          {{ $t('Account Summary') }}
        </h5>
        <ProofListingAccountSummary
            :data="data.account_summary"
            :loading="loading"
        />
      </div>
    </div>
  </ProofListing>
</template>
<script>
  import i18n from "@/i18n";
  import PostDialog from "@/components/common/modal/PostDialog";
  import ProofListing from "@/modules/common/components/proof-listing/ProofListing";
  import ProofListingAccountSummary from "@/modules/common/components/ProofListingAccountSummary";
  import ProofListingCostTypeSummary from "@/modules/common/components/ProofListingCostTypeSummary";
  import InvoiceProofGroupRow from "@/modules/accounts-payable/components/invoice/InvoiceProofGroupRow.vue";

  export default {
    components: {
      PostDialog,
      ProofListing,
      InvoiceProofGroupRow,
      ProofListingAccountSummary,
      ProofListingCostTypeSummary,
    },
    data() {
      return {
        loading: false,
        data: {
          vendors: [],
          account_summary: [],
          cost_type_summary: {},
        },
      }
    },
    computed: {
      totalsData() {
        const {
          batch_totals,
          normal_invoices_totals,
          manual_invoices_totals,
          no_post_invoices_totals,
          recurring_invoices_totals
        } = this.data;
        return [
          {
            name: this.$t('Batch Totals'),
            ...batch_totals,
            id: crypto.randomUUID(),
          },
          {
            name: this.$t('Total Normal/Hold Invoices'),
            ...normal_invoices_totals,
            id: crypto.randomUUID(),
          },
          {
            name: this.$t('Total Manual Check Invoices'),
            ...manual_invoices_totals,
            id: crypto.randomUUID(),
          },
          {
            name: this.$t('Total No Post Invoices'),
            ...no_post_invoices_totals,
            id: crypto.randomUUID(),
          },
          {
            name: this.$t('Total Recurring Invoices'),
            ...recurring_invoices_totals,
            id: crypto.randomUUID(),
          },
        ]
      },
      totalColumns() {
        return [
          {
            label: i18n.t('Total'),
            prop: 'name',
            minWidth: 150,
          },
          {
            label: i18n.t('Amount'),
            prop: 'gross_amount',
            minWidth: 100,
            maxWidth: 150,
            align: 'right',
            component: 'FormattedPrice',
          },
          {
            label: i18n.t('Discount'),
            prop: 'discount_amount',
            minWidth: 100,
            maxWidth: 150,
            align: 'right',
            component: 'FormattedPrice',
          },
          {
            label: i18n.t('Retention'),
            prop: 'retention_amount',
            minWidth: 100,
            maxWidth: 150,
            align: 'right',
            component: 'FormattedPrice',
          },
          {
            label: i18n.t('Net'),
            prop: 'net_amount',
            minWidth: 100,
            maxWidth: 150,
            align: 'right',
            component: 'FormattedPrice',
          },
        ]
      },
      flatData() {
        const allEntries = []
        this.data.vendors.forEach(vendor => {
          vendor?.invoices.forEach(invoice => {
            invoice.entries.forEach(entry => {
              allEntries.push({
                ...entry,
                invoice,
                vendor,
                gross_amount: +entry.gross_amount,
                retention_amount: +entry.retention_amount,
                discount_amount: +entry.discount_amount,
                net_amount: +entry.net_amount,
              })
            })
          })
        })
        return allEntries
      },
      groupRowRendererParams() {
        return {
          innerRenderer: 'InvoiceProofGroupRow',
        }
      },
      columns() {
        return [
          {
            label: i18n.t('Vendor'),
            prop: 'vendor.code',
            rowGroup: true,
            hide: true,
          },
          {
            headerName: this.$t('Invoice'),
            field: 'invoice.id',
            rowGroup: true,
            hide: true,
          },
          {
            headerName: ' ',
            field: 'cost_center',
            minWidth: 30,
            maxWidth: 50,
          },
          {
            headerName: this.$t('Source'),
            field: 'source_id',
            minWidth: 50,
            maxWidth: 70,
            component: 'SourceLink',
            cellRendererParams: {
              showDescription: false,
            },
          },
          {
            headerName: this.$t('Type'),
            field: 'type_id',
            minWidth: 40,
            maxWidth: 50,
            component: 'TypeLink',
          },
          {
            headerName: this.$t('Line Item'),
            field: 'addl_source_id',
            minWidth: 100,
            maxWidth: 100,
            component: 'AddlSourceLink',
            cellRendererParams: {
              showDescription: false,
            },
          },
          {
            headerName: this.$t('Description'),
            field: 'description',
            minWidth: 80,
          },
          {
            headerName: this.$t('Acc'),
            field: 'account',
            minWidth: 40,
            maxWidth: 60,
            component: 'AccountLink',
            cellRendererParams: {
              showDescription: false,
            }
          },
          {
            headerName: this.$t('Sub'),
            field: 'subaccount',
            minWidth: 40,
            maxWidth: 60,
            component: 'SubAccountLink',
            cellRendererParams: {
              showDescription: false,
            }
          },
          {
            headerName: this.$t('Qty'),
            field: 'quantity',
            valueGetter: params => {
              if (!params.data) {
                return ''
              }
              return +params.data.quantity
            },
            align: 'right',
            minWidth: 30,
            maxWidth: 50,
          },
          {
            headerName: this.$t('Amount'),
            field: 'gross_amount',
            minWidth: 90,
            maxWidth: 130,
            component: 'FormattedPrice',
            cellRendererParams: {
              hideZero: true,
            },
            align: 'right',
            aggFunc: 'sum',
          },
          {
            headerName: this.$t('Discount'),
            field: 'discount_amount',
            minWidth: 90,
            maxWidth: 130,
            component: 'FormattedPrice',
            cellRendererParams: {
              hideZero: true,
            },
            align: 'right',
            aggFunc: 'sum',
          },
          {
            headerName: this.$t('Retention'),
            field: 'retention_amount',
            minWidth: 90,
            maxWidth: 130,
            component: 'FormattedPrice',
            cellRendererParams: {
              hideZero: true,
            },
            align: 'right',
            aggFunc: 'sum',
          },
          {
            headerName: this.$t('Net'),
            field: 'net_amount',
            minWidth: 90,
            maxWidth: 130,
            component: 'FormattedPrice',
            cellRendererParams: {
              hideZero: true,
            },
            align: 'right',
            aggFunc: 'sum',
          },
        ]
      },
    },
  }
</script>
