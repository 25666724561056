<template>
  <base-form
      :loading="loading"
      :show-cancel="true"
      :show-back="true"
      :save-text="saveText"
      :update-text="updateText"
      :focus-on-first-input="!$route.params.id"
      layout="vertical"
      @cancel="$router.push('/job-costing/settings/job-cost-init')"
      @submit="onSubmit"
  >

    <portal v-if="!model.id" to="page-title">
      <span v-if="model.type === costTypes.Cost">
        {{ $t('Add Cost & Retention Payable') }}
      </span>
      <span v-else>
        {{ $t('Add Billings & Retention Receivable') }}
      </span>
    </portal>
    <base-select
        v-model="model.type"
        :placeholder="$t('Initialize')"
        :label="$t('Initialize')"
        :options="types"
        :disabled="!!model.id"
        rules="required"
        class="col-span-6 md:col-span-1"
        inline-errors
        @change="onTypeChange"
    />

    <FiscalYearSelect
        v-model="model.fiscal_year"
        :label="$t('Fiscal Year')"
        :default-previous-year="true"
        class="col-span-6 md:col-span-1"
        id="fiscal_year"
    />
    <PeriodSelect
        v-model="model.period"
        :placeholder="$t('Period')"
        :default-to-last-period="true"
        class="col-span-6 md:col-span-1"
        id="period"
    />

    <template v-if="model.type === costTypes.Income">
      <div class="col-span-6"></div>
      <job-select
        v-model="extraModel.job_id"
        :on-map-entry="onChangeJob"
        :url-params="jobUrlParams"
        :add-entity="false"
        clearable
        id="job_id"
        class="col-span-6 md:col-span-2"
      />
      <customer-select
        v-model="extraModel.customer_id"
        :initial-value="extraModel.selected_customer"
        :add-entity="false"
        clearable
        class="col-span-6 md:col-span-2"
        @entity-change="onChangeCustomer"
      />

      <base-input
        v-model="extraModel.retention_percent"
        :min="0.00"
        :max="100"
        :name="$t('Retention')"
        :label="$t('Retention Percent')"
        id="retention_percent"
        type="number"
        rules="max_value:100|min_value:0"
        format="percent"
        @change="onRetentionChange"
      />

    </template>

    <JobCostInitEntries
        :data="model"
        :initial-entries="initialEntries"
        :extra-data="extraModel"
        :key="model.id"
        :type="model.type"
        class="col-span-6"
        ref="gridTable"
    />
    <template
      v-if="model.id"
      #extra-buttons-right>
      <JobCostInitExport :id="$route.params.id"/>
    </template>
  </base-form>
</template>
<script>
  import axios from 'axios'
  import { businessType, costTypes } from '@/enum/enums'
  import { useStorage } from '@vueuse/core'
  import JobCostInitEntries from '@/modules/job-costing/components/job-cost-init/JobCostInitEntries'
  import { validateAgDataTable } from '@/components/ag-grid/tableUtils'
  import JobCostInitExport from "@/modules/job-costing/components/job-cost-init/JobCostInitExport.vue";
  import { globalResources } from "@/components/form/util";

  export default {
    components: {
      JobCostInitExport,
      JobCostInitEntries,
    },
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        loading: false,
        costTypes,
        model: {
          type: useStorage('jc-init-type', costTypes.Cost),
          status: this.$resourceStatuses.Pending,
          fiscal_year: this.$currentYear,
          period: this.$currentPeriod,
        },
        extraModel:  {
          job_id: null,
          customer_id: null,
          selected_job: null,
          selected_customer: null,
          retention_percent: 0,
        },
        initialEntries: [],
        types: [
          {
            label: this.$t('Costs & Retention Payable'),
            value: costTypes.Cost,
          },
          {
            label: this.$t('Billings & Retention Receive'),
            value: costTypes.Income,
          },
        ],
      }
    },
    computed: {
      saveText() {
        return this.model.type === costTypes.Cost ? this.$t('Create cost and retention') : this.$t('Create billing and retention')
      },
      updateText() {
        return this.model.type === costTypes.Cost ? this.$t('Update cost and retention') : this.$t('Update billing and retention')
      },
      jobUrlParams() {
        return {
          related: 'customer[id|code|name]'
        }
      }
    },
    methods: {
      onTypeChange() {
        this.initialEntries = []
        this.extraModel = {
          job_id: null,
          customer_id: null,
          selected_job: null,
          selected_customer: null,
          retention_percent: 0,
        }
      },
      async onChangeJob(job) {
        if (!job) {
          this.initialEntries = []
          return
        }
        const { attributes } = job
        const customer = this.get(job, 'relationships.customer', {})
        this.extraModel.selected_customer = customer
        this.extraModel.selected_job = job
        this.extraModel.job_id = job.id
        this.extraModel.customer_id = customer?.id

        this.extraModel.retention_percent = attributes.billing_retention_percent
        this.mapJobLineItems()
      },
      onChangeCustomer(customer) {
        if (!customer) {
          return
        }
        this.extraModel.selected_customer = customer
        this.mapJobLineItems()
      },
      onRetentionChange(value) {
        this.extraModel.retention_percent = +value
        this.mapJobLineItems()
      },
      mapJobLineItems() {
        if (!this.extraModel.job_id) {
          return
        }
        const incomeLineItems = this.$store.getters['globalLists/getResourceList'](this.$globalResources.IncomeLineItems)
        const jobLineItems = incomeLineItems.filter(lineItem => lineItem.job_id === this.extraModel.job_id)
        this.initialEntries = this.mapInitialEntries(jobLineItems)
      },
      getLineItemBudgetTypeId(lineItem) {
        let typeAbbr = null
        const typeTotals = lineItem?.typeTotals || {}
        for (let key in typeTotals) {
          if (typeTotals[key]?.amount > 0) {
            typeAbbr = key
            break
          }
        }
        const abbr = typeAbbr || 'PRG'
        const incomeTypes = this.$store.getters['globalLists/getResourceList'](globalResources.JobIncomeTypes)
        const type = incomeTypes.find(type => type.abbr === abbr)
        return type?.id
      },
      mapInitialEntries(lineItems) {
        return lineItems.map(lineItem => {
          return {
            _localId: crypto.randomUUID(),
            job_id: this.extraModel.job_id,
            job_type_id: this.getLineItemBudgetTypeId(lineItem),
            line_item_id: lineItem.id,
            quantity: 0,
            amount: 0,
            retention_amount: 0,
            business_id: this.extraModel.customer_id,
            business_type: businessType.Customer,
          }
        })
      },
      async onSubmit() {
        try {
          const isInvalidData = await validateAgDataTable()
          if (isInvalidData) {
            return
          }

          this.loading = true
          const requestData = {
            ...this.model,
            job_id: this.extraModel.job_id,
            customer_id: this.extraModel.customer_id,
            retention_percent: this.extraModel.retention_percent,
          }
          if (this.model.id) {
            await axios.put(`/restify/job-costing-inits/${this.model.id}`, requestData)
            await this.$refs.gridTable.storeProgress(this.model.id)

            this.$success(this.$t('Job Costing Init updated successfully'))
          } else {
            const { data } = await axios.post('/restify/job-costing-inits', requestData)
            await this.$refs.gridTable.storeProgress(data.id)

            this.$success(this.$t('Job Costing Init created successfully'))
            await this.$router.push('/job-costing/settings/job-cost-init')
          }
        } catch (err) {
          console.error(err)
          if (err.handled) {
            return
          }
          this.$error(this.$t('Error creating Job Costing Init'))
        } finally {
          this.loading = false
        }
      },
    },
    watch: {
      data: {
        immediate: true,
        handler(value) {
          if (!value.id) {
            return
          }

          this.model = {
            ...this.model,
            ...value.attributes,
          }
          this.extraModel.job_id = value.attributes?.job_id
          this.extraModel.customer_id = value.attributes?.customer_id
          this.extraModel.retention_percent = value.attributes?.retention_percent
        },
      },
    },
  }
</script>
