<template>
  <div class="flex items-center text-gray-900 truncate">
    <a v-if="formattedAddress(value)"
       :href="locationLink(value)"
       target="_blank"
       rel="noopener"
       class="text-gray-900 hover:text-primary-700 cursor-pointer truncate"
    >
      <address class="address-field truncate">
        {{ formattedAddress(value) }}
      </address>
    </a>
    <span v-else>{{$t('No address')}}</span>
  </div>
</template>
<script>
  import { formattedAddress, locationLink } from "@/utils/utils";

  export default {
    props: {
      value: {
        type: [String, Object],
      }
    },
    methods: {
      locationLink,
      formattedAddress,
    }
  }
</script>
<style>
</style>
