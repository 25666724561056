<template>
  <BaseDetailCard
    :title="$t('Customer Details')"
    :can-expand="false"
    :expanded="true"
    entity-name="customer"
  >
    <BaseDetailCardRow
      :title="$t('Default Account')"
      stripe
      field="account"
    >
      <AccountLink :number="customerData.account"/>
    </BaseDetailCardRow>
    <BaseDetailCardRow
      :title="$t('Default Subaccount')"
      field="subaccount"
    >
      <SubAccountLink :number="customerData.subaccount"/>
    </BaseDetailCardRow>
      <BaseDetailCardRow :title="$t('Terms')" stripe>
        <span>{{ customerData.terms || '' }}</span>
      </BaseDetailCardRow>
      <BaseDetailCardRow :title="$t('District')">
        <router-link
          v-if="customerData.district_id"
          to="accounts-receivable/settings/sales-tax-districts"
        >
          {{ district?.code }}
          </router-link>
        <span v-else/>
      </BaseDetailCardRow>
  </BaseDetailCard>
</template>
<script lang="ts" setup>
import Data = API.Data
import { computed, PropType } from 'vue'
import { Customer } from '@/modules/common/types/models'

const props = defineProps({
  customer: {
    type: Object as PropType<Data<Customer>>,
    default: () => ({}),
    required: true,
  }
})

const customerData = computed((): Customer => {
  return props.customer?.attributes || {}
})

const district = computed(() => {
  return props.customer?.relationships?.district?.attributes || {}
})

</script>
