<template>
  <ExportInitsButton
    url="/restify/accounts-receivable-init-entries/actions?action=accounts-receivable-init-entries-data-export"
    :url-params="{
      accounts_receivable_init_id: id,
    }"
    :columns="['number', 'customer.code', 'date', 'description', 'gross_amount']"
    class="mr-4 flex"
    v-bind="$attrs"
  />
</template>
<script>
import ExportInitsButton from "@/modules/common/components/export/ExportInitsButton.vue";

export default {
  components: {
    ExportInitsButton
  },
  props: {
    id: {
      type: String,
    }
  }
}
</script>
