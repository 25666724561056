<template>
  <PurchaseOrderForm
      :data="purchaseOrder"
      @refresh="getPurchaseOrder"
  />
</template>
<script>
  import PurchaseOrderForm from '@/modules/purchasing-inventory/components/purchase-orders/PurchaseOrderForm'

  export default {
    components: {
      PurchaseOrderForm,
    },
    computed: {
      purchaseOrder() {
        const purchaseOrder = this.$store.state.inventoryManagement.currentPurchaseOrder
        return {
          ...purchaseOrder?.attributes,
          ...purchaseOrder?.relationships,
        }
      },
    },
    methods: {
      async getPurchaseOrder() {
        await this.$store.dispatch('inventoryManagement/getPurchaseOrder', this.$route.params.id)
      },
    },
  }
</script>
