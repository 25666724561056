<template>
  <entity-select url="/restify/workers-comp-rates"
                 v-bind="$attrs"
                 v-on="$listeners"
                 :url-params="urlParams"
                 :name="$attrs.name || $t(`Worker's Comp Rate`)"
                 :placeholder="$attrs.placeholder || $t(`Select Worker's Comp Rate`)"
                 :label="$attrs.label !== undefined ? $attrs.label : $t(`Worker's Comp Rate`)"
                 :add-label="$t(`Add new Worker's Comp Rate`)"
                 :id="$attrs.id || 'worker-comp-select'"
                 :table-columns="tableColumns"
                 :label-format="formatLabel"
                 :hide-label="hideLabel"
                 :value-key="valueKey"
                 :add-entity="addEntity"
                 label-key="code"
  />
</template>
<script>
  export default {
    name: 'WorkerCompRate',
    inheritAttrs: false,
    props: {
      valueKey: {
        type: String,
        default: 'code',
      },
      urlParams: {
        type: Object,
        default: () => ({
          sort: 'code',
          active: true,
        }),
      },
      hideLabel: {
        type: Boolean,
        default: false,
      },
      addEntity: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        tableColumns: [
          {
            minWidth: 100,
            maxWidth: 150,
            name: this.$t('Code'),
            prop: 'attributes.code',
          },
          {
            minWidth: 200,
            maxWidth: 200,
            name: this.$t('Description'),
            prop: 'attributes.description',
          },
        ],
      }
    },
    methods: {
      formatLabel(rate) {
        const { code, description } = rate.attributes
        if (!description) {
          return code
        }
        return `${code} (${description})`
      },
    },
  }
</script>
