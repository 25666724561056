<template>
  <div v-if="showPrompt" class="rounded-md px-4 lg:px-10 bg-orange-50 shadow p-4 mx-6 mt-4">
    <div class="flex">
      <div class="flex-shrink-0">
        <svg class="h-5 w-5 text-orange-400" fill="currentColor" viewBox="0 0 20 20">
          <path fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clip-rule="evenodd"/>
        </svg>
      </div>
      <div class="ml-3 flex-1 md:flex md:justify-between">
        <p class="text-sm leading-5 text-orange-700">
          {{ $t('Your New Fiscal Year begins on January 1. Based on your System Date of') }} <b>{{ TODAY }}</b>,
          {{
            $t('it appears that you have not yet opened the New Fiscal Year. Before posting any New Fiscal Year journals,')
          }}
          <router-link to="/ledger/settings/fiscal-years">
            <span class="underline whitespace-nowrap font-medium text-orange-700 hover:text-orange-600 transition ease-in-out duration-150">
              {{ $t('select Manage Fiscal Years') }} &rarr;
            </span>
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>
<script>

  const date = new Date()
  const CURRENT_YEAR = date.getFullYear()

  export default {
    data() {
      const TODAY = this.$formatDate(new Date())
      return {
        TODAY,
        CURRENT_YEAR,
      }
    },
    computed: {
      showPrompt() {
        return this.lastFiscalYear ? this.lastFiscalYear < this.CURRENT_YEAR : false
      }
    },
  }
</script>



