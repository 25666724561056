<template>
  <BillingCostComparisonReport/>
</template>
<script>
  import BillingCostComparisonReport from '@/modules/job-costing/components/reports/BillingCostComparisonReport'

  export default {
    components: {
      BillingCostComparisonReport,
    },
  }
</script>
