<template>
  <base-input
    v-bind="$attrs"
    :value="null">
    <template v-slot:container="{errors}">
      <div class="date-picker__wrapper"
           :class="{'date-input-error': errors.length}">
        <base-input-error :errors="errors"
                          :show-tooltip="inlineErrors">
          <el-date-picker
            v-on="$listeners"
            v-bind="$attrs"
            :value-format="type === types.DateTime ? undefined : valueFormat"
            :format="format"
            :placeholder="$attrs.placeholder || $t('Select a date')"
            :clearable="clearable"
            :type="type"
          />
        </base-input-error>
      </div>
    </template>
  </base-input>
</template>
<script>
import { DatePicker } from 'element-ui'

const types = {
  Date: 'date',
  DateTime: 'datetime',
}

export default {
  components: {
    [DatePicker.name]: DatePicker,
  },
  props: {
    inlineErrors: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    valueFormat: {
      type: String,
      default: 'yyyy-MM-dd',
    },
    type: {
      type: String,
      default: types.Date,
    },
  },
  data() {
    return {
      types
    }
  },
  computed: {
    format() {
      if (this.$attrs.format) {
        return this.$attrs.format
      }
      const dataFormat = this.$store.getters['settings/getByKey']('date_format') || 'MM/dd/yyyy'
      if (this.type === types.DateTime) {
        return `${dataFormat} HH:mm`
      }
      return dataFormat
    },
  },
}
</script>
<style lang="scss">
.date-picker__wrapper.date-input-error {
  .el-date-editor.el-input {
    .el-input__inner {
      @apply bg-red-50 border-red-300 text-red-900 focus:ring-2 focus:outline-none focus:ring-red-300 focus:ring-opacity-40 focus:border-red-300;
    }
  }
}

.date-picker__wrapper {
  .el-date-editor.el-input {
    @apply w-full;
    .el-input__inner {
      @apply shadow-sm focus:ring-primary-200 focus:ring-2 focus:ring-opacity-20 focus:border-primary-300 block w-full sm:text-sm border-gray-300 rounded-md;
    }
  }
}

.el-picker__popper .el-date-picker .el-picker-panel__body-wrapper .el-picker-panel__body {
  .el-picker-panel__content .el-month-table .cell {
    @apply hover:text-primary-500;
  }

  .el-picker-panel__content .el-month-table .today .cell {
    @apply text-primary-500;
  }

  .el-picker-panel__content .el-month-table .current .cell {
    @apply text-primary-500;
  }

  .el-picker-panel__content .el-year-table .available .cell {
    @apply hover:text-primary-500;
  }

  .el-picker-panel__content .el-year-table .available.today .cell {
    @apply text-primary-500;
  }

  .el-date-picker__header .el-picker-panel__icon-btn {
    @apply hover:text-primary-500;
  }

  .el-date-picker__header .el-date-picker__header-label {
    @apply hover:text-primary-500;
  }

  .el-picker-panel__content .today span {
    @apply text-primary-500 hover:text-primary-500;
  }

  .el-picker-panel__content .available {
    @apply hover:text-primary-500;
  }

  .el-picker-panel__content .available.current span {
    @apply bg-primary-500;
  }
}
</style>
