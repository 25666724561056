<template>
  <VendorSelect
      ref="select"
      v-focus
      v-bind="params"
      v-model="selectedValue"
      :value-key="params.valueKey"
      :data="undefined"
      inline-errors
      class="w-full"
      @change="onChange"
      @keydown.enter.native="goToNextCell"
  />
</template>
<script>
import { selectEditorMixin } from "@/components/ag-grid/cellEditors/selectEditorMixin";

export default {
  name: 'VendorSelectCellEditor',
  mixins: [selectEditorMixin],
}
</script>
