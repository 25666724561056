<template>
  <div class="import-data mt-4">
    <ImportForm v-bind="importSettings"
                @success="redirectToList"
    >
      <div class="col-span-6 md:col-span-1">
        <label class="block text-sm font-medium leading-5 text-gray-700 mb-3">
          {{ $t('Update matching employees') }}
        </label>
        <base-switch v-model.number="model.overwrite"
                     id="overwrite"
        />
      </div>
    </ImportForm>
  </div>
</template>
<script>
  import { getInitialMapping } from "@/modules/payroll/utils/timecardImportColumns";
  import ImportForm from "@/modules/common/components/import/ImportForm";

  const colMappings = {
    Code: {
      value: 'code',
      label: 'Code',
      alternativeLabels: ['Employee Code'],
    },
    FirstName: {
      value: 'first_name',
      label: 'First Name',
      alternativeLabels: ['First', 'F Name'],
      required: (mapping) => {
        return mapping.name === -1;
      },
    },
    LastName: {
      value: 'last_name',
      label: 'Last Name',
      alternativeLabels: ['Last', 'L Name'],
      required: (mapping) => {
        return mapping.name === -1;
      },
    },
    MiddleName: {
      value: 'middle_name',
      label: 'Middle Name',
      alternativeLabels: ['Middle'],
    },
    Name: {
      value: 'name',
      label: 'Full Name',
      alternativeLabels: ['Name'],
      required: (mapping) => {
        return mapping.first_name === -1 && mapping.last_name === -1;
      },
    },
    Suffix: {
      value: 'suffix',
      label: 'Suffix',
      alternativeLabels: [],
    },
    Email: {
      value: 'email',
      label: 'Email',
      alternativeLabels: [],
    },
    Phone1: {
      value: 'phone_1',
      label: 'Phone',
      alternativeLabels: ['Mobile', 'Phone Number'],
    },
    SSN: {
      value: 'ssn',
      label: 'SSN',
      alternativeLabels: ['Social Security Number'],
    },
    Dob: {
      value: 'dob',
      label: 'Birth Date',
      alternativeLabels: ['Date of Birth'],
    },
    Status: {
      value: 'status',
      label: 'Status',
      alternativeLabels: ['Employee Status'],
    },
    PayFrequency: {
      value: 'pay_frequency',
      label: 'Pay Frequency',
      alternativeLabels: [],
    },
    CraftCode: {
      value: 'craft_code_level',
      label: 'Craft Code',
      alternativeLabels: ['Craft Code'],
    },
    Union: {
      value: 'union',
      label: 'Union',
      alternativeLabels: [],
    },
    Account: {
      value: 'account',
      label: 'Account',
      alternativeLabels: ['Account No.', 'Account Number', 'GL Account'],
    },
    SubAccount: {
      value: 'subaccount',
      label: 'Sub Account',
      alternativeLabels: ['Sub Account No.', 'Sub Account Number'],
    },
    SUISDIState: {
      value: 'sui_sdi_state',
      label: 'SUI/SDI State',
      alternativeLabels: [],
    },
    WitHoldingState: {
      value: 'withholding_state',
      label: 'Withholding State',
      alternativeLabels: [],
    },
    WitHoldingLocal: {
      value: 'withholding_local',
      label: 'Withholding Local',
      alternativeLabels: [],
    },
    WorkersCompRate: {
      value: 'workers_comp_rate_code',
      label: 'Workers Comp',
      alternativeLabels: [`Worker's Comp`, `Worker's Comp Rate`],
    },
    GenLiability: {
      value: 'gen_liability_rate_code',
      label: 'Gen Liability',
      alternativeLabels: [`Gen Liability Rate`, `Gen Liability Code`],
    },
    RegularRate: {
      value: 'regular_rate',
      label: 'Regular Rate',
      alternativeLabels: ['Reg Rate', 'Rate'],
    },
    Address1: {
      value: 'address_1',
      label: 'Address 1',
      alternativeLabels: [],
    },
    Address2: {
      value: 'address_2',
      label: 'Address 2',
      alternativeLabels: [],
    },
    FullAddress: {
      value: 'address',
      label: 'Full Address',
      alternativeLabels: ['Address'],
    },
    City: {
      value: 'city',
      label: 'City',
      alternativeLabels: [],
      required: mapping => {
        return mapping.address_1 !== -1;
      }
    },
    State: {
      value: 'state',
      label: 'State',
      alternativeLabels: [],
      required: mapping => {
        return mapping.address_1 !== -1;
      }
    },
    PostalCode: {
      value: 'postal_code',
      label: 'Zip Code',
      alternativeLabels: ['Zip', 'Postal Code'],
      required: mapping => {
        return mapping.address_1 !== -1;
      }
    },
    HireDate: {
      value: 'hire_date',
      label: 'Hire Date',
      alternativeLabels: ['Date Hired'],
    }
  }
  export const options = [
    colMappings.Code,
    colMappings.LastName,
    colMappings.FirstName,
    colMappings.MiddleName,
    colMappings.Name,
    colMappings.Suffix,
    colMappings.Email,
    colMappings.Phone1,
    colMappings.SSN,
    colMappings.Dob,
    colMappings.Status,
    colMappings.PayFrequency,
    colMappings.CraftCode,
    colMappings.Union,
    colMappings.Account,
    colMappings.SubAccount,
    colMappings.SUISDIState,
    colMappings.WitHoldingState,
    colMappings.WitHoldingLocal,
    colMappings.WorkersCompRate,
    colMappings.GenLiability,
    colMappings.RegularRate,
    colMappings.Address1,
    colMappings.Address2,
    colMappings.FullAddress,
    colMappings.City,
    colMappings.State,
    colMappings.PostalCode,
    colMappings.HireDate,
  ]

  export default {
    components: {
      ImportForm,
    },
    data() {
      return {
        model: {
          overwrite: false,
        },
        importSettings: {
          url: '/restify/employees/import',
          columnOptions: options,
          columnMappings: colMappings,
          initialMapping: getInitialMapping(options),
          type: 'employees-import',
          transformRequestData: this.transformRequestData,
          resource: 'employees',
          showTemplateDownload: true,
        },
      }
    },
    methods: {
      transformRequestData(formData) {
        formData.append('overwrite', +this.model.overwrite)
      },
      async redirectToList() {
        await this.$router.push('/payroll/employees')
      },
    }
  }
</script>
