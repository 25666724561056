<template>
  <BaseCard class="border">
    <div class="text-sm flex flex-col space-y-4">
      <div :title="$t('Code')"
           class="detail-row">
        <HashIcon class="detail-icon"/>
        <div>{{ customerData.code }}</div>
        <StatusBadge :status="customerData.status"/>
      </div>
      <div
        :title="$t('Email')"
        class="detail-row">
        <MailIcon class="detail-icon"/>
        <BaseEmailLink :value="customerData.email"/>
      </div>
      <div :title="$t('Phone')"
           class="detail-row">
        <PhoneIcon class="detail-icon"/>
        <BasePhoneLink :value="customerData.phone"/>
      </div>
      <div :title="$t('Address')"
           class="detail-row">
        <MapPinIcon class="detail-icon"/>
        <BaseAddressLink :value="props.customer?.relationships?.address"/>
      </div>
    </div>
    <div class="flex space-x-4 border-t border-gray-100 mt-4 -mb-4"
    >
      <EmailButton :value="customerData.email"/>
      <PhoneButton :value="customerData.phone"/>
      <AddressButton :value="location"/>
    </div>
  </BaseCard>
</template>
<script lang="ts" setup>
import Data = API.Data
import { computed, PropType } from 'vue'
import { locationLink } from '@/utils/utils'
import { Customer } from '@/modules/common/types/models'
import { HashIcon,MailIcon, PhoneIcon, MapPinIcon } from 'vue-feather-icons'
import EmailButton from '@/modules/common/components/buttons/EmailButton.vue'
import PhoneButton from '@/modules/common/components/buttons/PhoneButton.vue'
import AddressButton from '@/modules/common/components/buttons/AddressButton.vue'

const props = defineProps({
  customer: {
    type: Object as PropType<Data<Customer>>,
    required: true,
  },
})

const customerData = computed((): Customer => {
  return props.customer?.attributes || {}
})
const location = computed(() => {
  return locationLink(props.customer?.relationships?.address)
})
</script>
