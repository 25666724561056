<template>
  <EquipmentForm
    :key="renderKey"
    @create-another="renderKey++"
  />
</template>
<script lang="ts" setup>
import { ref } from 'vue'
import EquipmentForm from '@/modules/equipment/components/EquipmentForm.vue'

const renderKey = ref(1)
</script>
