<template>
  <base-stats-card icon="IconInventory" :title="title">
    <template v-if="loading">
      <div class="bg-gray-50 placeholder-loading-animation rounded-sm h-6 w-[128px] mt-1 mb-2"/>
      <div class="bg-gray-50 placeholder-loading-animation rounded-sm h-4 w-8"/>
    </template>
    <template v-else-if="hasNoBudgets(type)">
      <div>
        {{ $t(`No ${type} budgets`) }}
      </div>
      <div :title="description" class="text-sm text-gray-500 font-normal truncate">
        {{ description }}
      </div>
    </template>
    <template v-else>
      <div :class="{'text-red-500': isOverBudget(type) }">
        {{ $formatPriceRound(getTotals(type).actual) }}
      </div>
      <div class="text-gray-500 text-sm font-medium">
        {{ $formatPercent(getRemainingPercentage(type)) }} {{ $t('of budget used') }}
      </div>
    </template>
  </base-stats-card>
</template>
<script>
  import { costOrIncomeTypes } from "@/components/grid-table/utils/cost-center";

  export default {
    props: {
      lineItems: {
        type: Array,
        default: () => []
      },
      type: String,
      loading: Boolean,
    },
    data() {
      return {
        costOrIncomeTypes,
      }
    },
    computed: {
      title() {
        return this.type === costOrIncomeTypes.Cost ? this.$t('Actual Cost') : this.$t('Actual Income')
      },
      description() {
        return this.$t(`Add ${this.type} line items and budgets to see cost amounts`)
      }
    },
    methods: {
      getTotals(type) {
        return this.lineItems?.meta?.summary[type]?.totals || {}
      },
      getRemainingPercentage(type) {
        const { budget, actual } = this.getTotals(type)
        return budget ? (actual / budget) * 100 : 0
      },
      isOverBudget(type) {
        const { budget, actual } = this.getTotals(type)
        return actual > budget
      },
      hasNoBudgets(type) {
        const { budget } = this.getTotals(type)
        return budget === undefined
      }
    }
  }
</script>
