import DashboardLayout from "@/modules/dashboard/layout/DashboardLayout";
import Changelog from "@/modules/dashboard/pages/changelog.vue";
import Welcome from "@/modules/dashboard/pages/welcome";
import ResourceNotFound from "@/modules/dashboard/pages/resource-not-found.vue";

function getAllModuleRoutes () {
  const files = import.meta.globEager('../**/routes.js')

  const exceptions = ['auth', 'dashboard', 'wiki', 'public', 'reports', 'print', 'billings'] // we don't want to include these modules here
  let fileKeys = Object.keys(files)

  fileKeys = fileKeys.filter(pathName => {
    return exceptions.every(exception => !pathName.includes(exception))
  })
  const flatRoutes = []
  fileKeys.forEach(key => {
    flatRoutes.push(...files[key].default)
  })
  return flatRoutes
}

const allModuleRoutes = getAllModuleRoutes()
export default [
  {
    path: '/',
    name: 'Home',
    redirect: '/welcome',
    meta: {
      requiresAuth: true
    },
    components: {
      default: DashboardLayout
    },
    children: [
      {
        path: 'welcome',
        name: 'Dashboard',
        title: 'Dashboard',
        description: 'Find the most relevant information for you in the application dashboard.',
        component: Welcome,
        meta: {
          hideBreadCrumb: true,
        }
      },
      {
        path: 'changelog',
        name: 'Changelog',
        title: 'Changelog',
        description: 'Stay up to date with all of the latest additions and improvements we\'ve made to Construction Partner',
        component: Changelog,
        meta: {
          hideBreadCrumb: true,
        }
      },
      {
        path: '404',
        name: '404',
        component: ResourceNotFound,
        meta: {
          hideBreadCrumb: true,
        }
      },
      ...allModuleRoutes
    ]
  }
]
