<template>
  <BaseWrapper v-bind="$attrs"
               :items="items"
               :entity="entity"
               :footer="footer"
               title-key="name"
               status-key="status"
  />
</template>
<script>
  import BaseWrapper from '@/modules/common/components/entity-preview/BaseWrapper'

  export default {
    components: {
      BaseWrapper,
    },
    props: {
      entity: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        items: [
          {
            title: this.$t('Last Payment'),
            value: this.$formatDate(this.entity.last_payment_date),
            dateType: true,
          },
          {
            title: this.$t('Open Amount'),
            value: this.$formatPrice(this.entity.current_ap_amount),
          },
          {
            title: this.$t('Payment To-date'),
            value: this.$formatPrice(this.entity.payment_to_date),
          },
        ],
        footer: {
          email: true,
          phone: true,
          address: true,
          link: true,
        },
      }
    },
  }
</script>
