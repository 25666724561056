<template>
  <div v-if="data.length"
       class="employee-users">
    <base-data-table
        :columns="columns"
        :data="data"
        :can-reorder-columns="false"
        :show-pagination="false"
        :title="title"
        ref="table"
        compact
    >
      <template v-slot:extra-actions="{row}">
        <table-delete-button @click="deleteUser(row)"/>
      </template>
    </base-data-table>
  </div>
</template>
<script>
  import axios from 'axios';

  export default {
    props: {
      title: {
        type: String,
        default: 'Associated User',
      },
      data: {
        type: Array,
        default: () => [],
      },
      employeeId: {
        type: [String, Number],
      },
    },
    data() {
      return {
        removeUserLoading: false,
      }
    },
    computed: {
      columns() {
        return [
          {
            label: this.$t('Email'),
            prop: 'attributes.email',
            maxWidth: 160,
            minWidth: 150,
            component: 'EntityLink',
            redirectTo: '/settings/users/{ID}/details',
          },
          {
            label: this.$t('Name'),
            prop: 'attributes.name',
            maxWidth: 150,
          },
        ]
      },
    },
    methods: {
      async deleteUser(user) {
        const confirmed = await this.$deleteConfirm({
          title: this.$t('Remove user from the employee'),
          description: this.$t('This action will remove the associated user from the employee. They will no longer be able to log in, but you can invite them again at a later point in time.'),
          buttonText: this.$t('Remove'),
        })
        if (!confirmed) {
          return
        }
        try {
          this.removeUserLoading = true
          let payload = {
            repositories: [this.employeeId],
          }
          await axios.post('/restify/employees/action?action=detach-users-from-employees', payload)
          this.$success(this.$t('User will be removed from the employees'))
          this.$emit('remove-user')
        } catch (err) {
          this.$error('An error occurred while trying to remove the user from the employee')
        } finally {
          this.removeUserLoading = false
        }
      },
    },
  }
</script>
<style lang="scss">
  .employee-users {
    .table-title {
      @apply -mb-4 -mt-2;
    }
  }
</style>
