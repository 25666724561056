<template>
  <base-form
      layout="vertical"
      :loading="loading"
      :save-text="$t('Create Invoices')"
      :focus-on-first-input="false"
      @submit="onSubmit"
  >
    <base-date-picker v-model="model.billing_date"
                      :label="$t('Invoice Date')"
                      :placeholder="$t('Invoice Date')"
                      id="date"
                      rules="required"
                      class="col-span-6 md:col-span-2"
    />

    <base-checkbox
        v-model="model.include_vendor_name"
        :label="$t('Include Vendor Name In Description')"
        id="include_vendor_name"
        rules="required"
        :tip="$t('Vendor name will be included in the description of the invoice entry')"
        class="col-span-6 md:col-span-2"
    />
    <base-checkbox
        v-model="model.include_employee_name"
        :label="$t('Include Employee Name In Description')"
        :tip="$t('Employee name will be included in the description of the invoice entry')"
        id="include_employee_name"
        rules="required"
        class="col-span-6 md:col-span-2"
    />
    <CostForBillingTable
        ref="table"
        class="mt-4 col-span-6"
        :data="model.billing_date"
        :filters="filters"
    />
  </base-form>
</template>
<script lang="ts" setup>
import {ref} from "vue";
import {error} from "@/components/common/NotificationPlugin";
import i18n from "@/i18n";
import {useRouter} from "vue2-helpers/vue-router";
import Cache from "@/utils/Cache";
import {dateTypes, formatDate} from "@/plugins/dateFormatPlugin";
import CostForBillingTable from "@/modules/service-billing/components/cost-for-billing/CostForBillingTable.vue";
import axios from "axios";
import {
  CostForBillingDataEntry,
  CostForBillingEntry
} from "@/modules/service-billing/components/cost-for-billing/models/costForBillingModels";

const props = defineProps({
  filters: {
    type: Object,
    required: true
  }
})

const loading = ref(false)
const model = ref({
      billing_date: new Date(),
      include_employee_name: false,
      include_vendor_name: false,
      entries: [],
    }
)

function mapEntriesToRequest(entries: CostForBillingDataEntry[]): CostForBillingEntry[] {
  return entries
    .filter(entry => entry.selected)
    .map(entry => {
      return {
        service_billing_history_id: entry.id,
        service_billing_code_id: entry.service_billing_code_id,
        work_order_id: entry.work_order_id,
        subject_to_tax: entry.subject_to_tax,
        billing_unit_rate: entry.billing_unit_rate,
      }
    })
}

const table = ref()

const router = useRouter()

async function onSubmit() {
  try {
    loading.value = true
    const tableEntries = table.value?.getEntries() || []
    const entries: CostForBillingEntry[] = mapEntriesToRequest(tableEntries)
    const entries_to_remove: string[] = tableEntries
      .filter((entry: CostForBillingDataEntry) => entry.removed)
      .map((entry: CostForBillingDataEntry) => entry.id)

    if (entries.length === 0 && entries_to_remove.length === 0) {
      error(i18n.t('Please modify at least one entry.'))
      return
    }
    await axios.post(`/restify/service-billing-histories/actions?action=create-service-billings-from-history`, {
      billing_date: formatDate(model.value.billing_date, dateTypes.IsoDate),
      include_employee_name: model.value.include_employee_name,
      include_vendor_name: model.value.include_vendor_name,
      entries,
      entries_to_remove,
    })
    Cache.removeForEntity('billing')
    await router.push('/service-billing/service-invoices/pending')
  } catch (err: any) {
    if (err.handled) {
      return
    }
    error(i18n.t('Something went wrong please try again.'))
  } finally {
    loading.value = false
  }
}
</script>
