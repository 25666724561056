<template>
  <div>
    <TimeOffForm />
  </div>
</template>
<script>
  import TimeOffForm from "@/modules/payroll/components/time-off/TimeOffForm";

  export default {
    components: {
      TimeOffForm
    }
  }
</script>
