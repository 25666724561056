import { validCodeChars } from '@/modules/common/veeValidate'

export function generateCodeFromName(name = '') {
  // Extract only valid code characters
  const matches = name.match(new RegExp(`[${validCodeChars}\\s]+`, 'g'))
  name = matches?.join('') || ''

  if (!name) {
    return ''
  }

  const codeLength = 6
  const charsPerWord = 3
  let code = ''

  const nonEmptyWords = name
    .split(' ')
    .map(p => p.trim())
    .filter(p => p !== '')

  if (nonEmptyWords.length === 1) {
    return name.substring(0, codeLength).toUpperCase()
  }

  nonEmptyWords.forEach(part => {
    if (code.length >= codeLength) {
      return
    }
    let charsToAdd = Math.min(charsPerWord, codeLength - code.length)
    code += part.substring(0, charsToAdd).trim().toUpperCase()
  })

  return code
}
