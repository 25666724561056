<template>
    <div>
        <el-image v-if="imageSrc"
                  :src="imageSrc"
                  :alt="name"
                  :class="{
                      'avatar-sm': size === 'sm',
                      'avatar-md': size === 'md',
                      'avatar-lg': size === 'lg',
                      'avatar-xl': size === 'xl',
                      'avatar-2xl': size === '2xl',
                      'rounded-full': rounded,
                      'rounded': size==='sm' && !rounded,
                      'rounded-lg': size!=='sm' && !rounded,
                  }"
                  class="avatar border-2 border-white">

        </el-image>
        <div v-else
             :class="{
                  'avatar-sm': size === 'sm',
                  'avatar-md': size === 'md',
                  'avatar-lg': size === 'lg',
                  'avatar-xl': size === 'xl',
                  'avatar-2xl': size === '2xl',
                  'rounded-full': rounded,
                  'rounded': size==='sm' && !rounded,
                  'rounded-lg': size!=='sm' && !rounded,
             }"
             class="avatar text-white text-xs bg-primary-600 border-2 border-primary-400 shadow flex items-center justify-center">
            {{initials}}
        </div>
    </div>
</template>
<script>
  import { Image, Tooltip } from 'element-ui'

  export default {
    name: 'base-avatar',
    components: {
      [Image.name]: Image,
      [Tooltip.name]: Tooltip
    },
    props: {
      name: [String, Number],
      size: String,
      src: String,
      rounded: {
        type: Boolean,
        default: true
      },
    },
    computed: {
      imageSrc() {
        if (this.src) {
          return this.src
        }
        return ''
      },
      initials() {
        let initials = this.name || ''

        if (typeof this.name === "string") {
          initials = this.name.split(' ').map(word => word.charAt(0)).join('').toUpperCase() || ''
        }
        return initials.substr(0, 2)
      }
    }
  }
</script>
<style scoped lang="scss">
    .avatar {
        width: 40px;
        height: 40px;

        :deep(img) {
            @apply shadow border-0;
        }
    }

    .avatar-sm {
        width: 28px;
        height: 28px;
    }

    .avatar-md {
        width: 34px;
        height: 34px;
    }

    .avatar-lg {
        width: 50px;
        height: 50px;
    }

    .avatar-xl {
        width: 60px;
        height: 60px;
    }
    .avatar-2xl {
      width: 100px;
      height: 100px;
      @apply text-2xl;
    }
</style>
<style>
    .avatar-group .avatar {
        position: relative;
        z-index: 2;
    }

    .avatar-group .avatar + .avatar {
        margin-left: -1rem;
    }
</style>
