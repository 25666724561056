<template>
  <div>
    <AgDataTable
        :url="url"
        :columns="columns"
        :url-params="urlParams"
        :edit-entity-url-query="editEntityUrlQuery"
        :add-text="$t('New address')"
        ref="table"
        default-sort="name"
        domLayout="autoHeight"
        actions="search,refresh,print,edit"
        hide-actions="view"
        permission="companies"
        @add="onRowAdd"
        @edit="onRowEdit"
    />

    <BaseFormDialog
        v-if="showDialog"
        :title="rowToEdit ? $t('Edit Address') : $t('Add Address')"
        :open.sync="showDialog"
        size="lg"
        @close="onEventTrigger">
      <CompanyAddressForm
          :data="rowToEdit"
          @cancel="onEventTrigger"
          @save="onEventTrigger"
      />
    </BaseFormDialog>

  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { addressableMorph } from '@/enum/enums'
  import { Address } from '@/modules/common/types/models'
  import { Column } from '@/components/ag-grid/tableTypes'
  import CompanyAddressForm from '@/modules/settings/components/CompanyAddressForm.vue'

  export default defineComponent({
    components: {
      CompanyAddressForm,
    },
    data() {
      return {
        editEntityUrlQuery: '?id={ID}',
        rowToEdit: null as API.Data<Address> | null,
        showDialog: false,
        columns: [
          {
            label: this.$t('Name'),
            prop: 'attributes.name',
            component: 'EntityLink',
            redirectTo: '/settings/company-addresses?id={ID}',
            minWidth: 150,
            maxWidth: 400,
          },
          {
            headerName: this.$t('Address'),
            field: 'attributes.address_1',
            component: 'AddressLink',
            minWidth: 150,
            maxWidth: 400,
          },
          {
            headerName: this.$t('Zip'),
            field: 'attributes.postal_code',
            minWidth: 100,
            maxWidth: 150,
          },
          {
            headerName: this.$t('Type'),
            field: 'attributes.type',
            component: 'Status',
            align: 'center',
            minWidth: 100,
            maxWidth: 150,
          },
          {
            headerName: this.$t('Active'),
            field: 'attributes.active',
            component: 'Status',
            align: 'center',
            maxWidth: 100,
          },
          {
            headerName: this.$t('Contact'),
            field: 'attributes.contact',
            minWidth: 100,
            maxWidth: 150,
          },
          {
            headerName: this.$t('Phone'),
            field: 'attributes.phone',
            component: 'PhoneLink',
            minWidth: 100,
            maxWidth: 150,
          },
          {
            label: this.$t('Email'),
            prop: 'attributes.email',
            component: 'EmailLink',
            minWidth: 100,
            maxWidth: 150,
          },
        ] as Column [],
      }
    },
    computed: {
      companyId() {
        return this.$store.getters['company/getCurrentCompanyId']
      },
      url() {
        return !this.companyId ? '': '/restify/addresses'
      },
      urlParams() {
        return !this.companyId ? {}: {
          addressable_type: addressableMorph.Company,
          addressable_id: this.companyId,
        }
      },
    },
    methods: {
      onRowEdit(row: API.Data<Address>) {
        this.showDialog = true
        this.rowToEdit = row
      },
      onRowAdd() {
        this.showDialog = true
      },
      refreshTable() {
        // @ts-ignore
        this.$refs.table.refresh()
      },
      onEventTrigger() {
        this.rowToEdit = null
        this.showDialog = false
        this.refreshTable()
      },
    },
  })
</script>
