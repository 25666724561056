<template>
  <EntitySelect
    url="/restify/employees/getters/time-off-statuses"
    v-bind="$attrs"
    v-on="$listeners"
    :url-params="urlParams"
    :dataFilters="dataFilters"
    :name="$attrs.name || $t('Time Off Policy')"
    :placeholder="$attrs.placeholder || $t('Select Time Off Policy')"
    :label="$attrs.label !== undefined ? $attrs.label : $t('Time Off Policy')"
    :add-entity="false"
    label-key="policy.description"
    value-key="policy.id"
    class="w-full"
    :id="$attrs.id || 'employee-time-off-select'"
    :table-columns="tableColumns"
    :transform-data="transformData"
    :filterOptions="filterOptions"
    :label-format="labelFormat"
  >
    <template #default="{loading, options}">
      <el-option
        v-if="!loading && options?.length === 0"
        :disabled="true"
        :value="-1"
      >
        {{ $t('No time off policies found') }}
      </el-option>
    </template>
  </EntitySelect>
</template>
<script>
import { Option } from 'element-ui'
export default {
  components: {
    [Option.name]: Option,
  },
  inheritAttrs: false,
  props: {
    employeeId: {
      type: String,
      required: true,
    }
  },
  computed: {
    urlParams() {
      return {
        repositories: [this.employeeId],
        active: this.dataFilters.showActive
      }
    }
  },
  data() {
    return {
      dataFilters: {
        showActive: true,
      },
      tableColumns: [
        {
          minWidth: 200,
          maxWidth: 200,
          name: this.$t('Policy'),
          prop: 'policy.description'
        },
        {
          minWidth: 100,
          maxWidth: 150,
          name: this.$t('Used'),
          prop: 'status.current_year_used_hours',
          toFormat: value => {
            return `${value} ${this.$t('hours')}`
          }
        },
        {
          minWidth: 100,
          maxWidth: 150,
          name: this.$t('Available'),
          prop: 'status.balance',
          toFormat: value => {
            return `${value} ${this.$t('hours')}`
          }
        },
        {
          minWidth: 130,
          maxWidth: 130,
          name: this.$t('Active'),
          prop: 'policy.active',
          component: 'Status',
          filter: {
            type: 'switch',
            prop: 'showActive',
            tooltip: this.$t('Only show active policies'),
            onChange: (value) => {
              this.dataFilters.showActive = value
            },
          },
          showIf: () => this.showActiveColumn,
        },
      ],
    }
  },
  methods: {
    transformData(data) {
      let options = data[this.employeeId]

      return options.map(option => ({
        ...option,
        isInactive: !option.policy.active
      }))
    },
    filterOptions(data) {
      if (this.dataFilters.showActive) {
        return data.filter(item => item.policy.active)
      }

      return data
    },
    labelFormat(item) {
      const description = item.policy.description
      const status = item.status
      if (status.balance) {
        return `${description} (${status.balance} ${this.$t('hours')} ${this.$t('available')})`
      } else {
        return description
      }
    }
  }
}
</script>
