<template>
  <div>
    <void-button
      :loading="loading"
      @click="showDialog = true"
    >
      {{ $t('Void') }}
    </void-button>

    <BaseFormDialog
      v-if="showDialog"
      :title="$t('Void Payrolls')"
      :appendToBody="true"
      :open.sync="showDialog"
      size="lg"
    >
      <base-form
        :save-text="$t('Void')"
        :cancel-text="$t('Cancel')"
        :show-cancel="true"
        :loading="loading"
        layout="vertical"
        @cancel="showDialog = false"
        @submit="onSubmit"
      >
        <div
          class="col-span-6 flex items-center justify-center text-md leading-5 text-cool-gray-500 font-medium pb-2">
          <ValidationWarning>
            {{ $t(`Note that this action is irreversible. We will still keep the payroll in our history.`) }}
          </ValidationWarning>
        </div>
        <div class="col-span-6 md:col-span-2">
          <base-input :label="$t('Fiscal Year')">
            <template #container>
              <fiscal-year-select
                v-model="model.fiscal_year"
                class="w-full"
                id="fiscal_year"
              />
            </template>
          </base-input>
        </div>
        <div class="col-span-6 md:col-span-2">
          <period-select
            v-model="model.period"
            :fiscal-year="model.fiscal_year"
            :placeholder="$t('Void Period')"
            rules="required"
            id="period"
          />
        </div>
        <div class="col-span-6 md:col-span-2">
          <base-switch
            v-model="model.should_replicate"
            :label-info="$t('Replicate payrolls after voiding')"
          />
        </div>
        <div class="col-span-6 mt-4">
          <h5 class="text-gray-600 mb-2">
            {{ $t('Select Payrolls to Void') }} <span>({{
              $t('When all selected, the whole batch will be voided')
            }})</span>
          </h5>
          <AgDataTable
            :data="tableData"
            :columns="columns"
            :show-pagination="false"
            :pagination="false"
            :selected-rows.sync="selectedRows"
            domLayout="autoHeight"
          >
          </AgDataTable>
        </div>
      </base-form>
    </BaseFormDialog>
  </div>
</template>
<script>
import axios from "axios";
import { resourceStatuses } from "@/enum/enums";
import Cache from "@/utils/Cache";
import { refreshGlobalResourcesByModule } from "@/utils/modules";
import { isPeriodDefined } from "@/modules/ledger/utils/periodUtils";

export default {
  props: {
    batchSummary: {
      type: Object,
      required: true,
    },
    payrollData: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      showDialog: false,
      loading: false,
      model: {
        fiscal_year: this.activeFiscalYear,
        period: null,
        should_replicate: false,
      },
      selectedRows: [],
      journal: {},
    }
  },
  computed: {
    tableData() {
      return this.payrollData
        .map(row => {
          return {
            id: row.payroll.id,
            ...row,
          }
        })
        .filter(row => {
          return row.payroll.status !== resourceStatuses.Voided
        })
    },
    columns() {
      return [
        {
          headerName: this.$t('Employee'),
          field: 'employee.name',
          headerCheckboxSelection: true,
          checkboxSelection: true,
          minWidth: 200,
        },
        {
          label: this.$t('Status'),
          prop: 'payroll.status',
          component: 'Status',
          minWidth: 80,
        },
        {
          label: this.$t('Payment Number'),
          prop: 'payroll.payment_number',
          align: 'right',
          maxWidth: 95,
        },
        {
          label: this.$t('Payment Date'),
          prop: 'payroll.payment_date',
          align: 'center',
          component: 'FormattedDate',
        },
        {
          label: this.$t('Gross Pay'),
          prop: 'payroll.totals.gross_pay',
          component: 'FormattedPrice',
          align: 'right',
        },
        {
          label: this.$t('Hours'),
          prop: 'payroll.totals.hours',
          align: 'right',
        },
        {
          label: this.$t('Net Pay'),
          prop: 'payroll.totals.net_pay',
          component: 'FormattedPrice',
          align: 'right',
        },
      ]
    },
    allRowsSelected() {
      return this.selectedRows.length === this.tableData.length
    }
  },
  methods: {
    async voidBatch() {
      try {
        this.loading = true
        await axios.post(`/restify/payroll-batches/${this.batchSummary.id}/actions?action=void-payroll-batch`, this.model)
        this.showDialog = false
        this.$emit('submit')
      } finally {
        this.loading = false
      }
    },
    async voidPayrolls() {
      try {
        this.loading = true
        const ids = this.selectedRows.map(row => row.id)
        await axios.post(`/restify/payroll-batches/${this.batchSummary.id}/actions?action=void-selected-payrolls-in-batch`, {
          repositories: ids,
          ...this.model,
        })
        this.showDialog = false
        this.$emit('submit')
      } finally {
        this.loading = false
      }

    },
    async onSubmit() {
      if (this.allRowsSelected) {
        await this.voidBatch()
      } else {
        await this.voidPayrolls()
      }
      await refreshGlobalResourcesByModule(this.$currentModule)
      if (this.model.should_replicate) {
        Cache.removeForEntity('timecard-batches')
        await this.$router.push('/payroll/timecard-batches/pending')
      }
    },
    setPostData() {
      const { period, fiscal_year } = this.journal?.attributes || {}
      if (!isPeriodDefined(period) && !fiscal_year) {
        return
      }
      this.model.period = period
      this.model.fiscal_year = fiscal_year
    },
    async getJournalData() {
      const journalId = this.batchSummary?.attributes?.journal_id
      const { data } = await axios.get(`/restify/journals/${journalId}`)
      this.journal = data
      this.setPostData()
    }
  },
  mounted() {
    this.getJournalData()
  }
}
</script>
