<template>
  <base-filter-range :class="gridClass">
    <template #start>
      <gen-liability-rate-select
          v-model="value.liability_code_from"
          :add-entity="false"
          :placeholder="$t('Liability From')"
          :hide-label="true"
          class="flex-1"
          clearable
          @blur="onBlurStartRange"
      />
    </template>
    <template #end>
      <gen-liability-rate-select
          v-model="value.liability_code_to"
          :add-entity="false"
          :placeholder="$t('Liability To')"
          :hide-label="true"
          class="flex-1"
          clearable
          @blur="onBlurEndRange"
      />
    </template>
  </base-filter-range>
</template>
<script>
  export default {
    props: {
      value: {
        type: Object,
        default: () => ({
          liability_code_from: null,
          liability_code_to: null,
        }),
      },
      gridClass: {
        type: String,
        default: 'col-span-4',
      },
    },
    methods: {
      onBlurStartRange() {
        this.value.liability_code_to = this.value.liability_code_to || this.value.liability_code_from
      },
      onBlurEndRange() {
        if (!this.value.liability_code_from) {
          return
        }

        this.onBlurStartRange()
      },
    },
  }
</script>
