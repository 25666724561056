<template>
  <div
      id="report-page"
      class="report-page print-layout"
      :class="{
          'shade-reports': shadeReports
       }"
  >
    <div v-if="showHeader" class="report-table-wrapper mb-2">
      <table>
        <thead>
        <tr>
          <th>
            <BaseReportHeader :header="header"/>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            <router-view></router-view>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
  import { useReportShading } from "@/modules/common/components/reports/util";
  import BaseReportHeader from "@/components/common/BaseReportHeader.vue";

  export default {
    components: {
      BaseReportHeader
    },
    setup() {
      const { reportShadingSettings, shadeReports } = useReportShading()
      return {
        reportShadingSettings,
        shadeReports,
      }
    },
    computed: {
      reportShading() {
        return this.reportShadingSettings
      },
      header() {
        return {
          title: this.$route.name,
          company: this.$currentCompany
        }
      },
      showHeader() {
        const path = this.$route.path
        const exceptionNames = [
            'Payroll Register Summary'
        ]
        return path.includes('proof-listing') || exceptionNames.includes(this.$route.name)
      }
    },
    mounted() {
      this.$store.dispatch('globalLists/getGLConfiguration')
      this.$store.dispatch('globalLists/getGeneralConfiguration')
    }
  }
</script>
<style lang="scss">
  .report-page.print-layout .data-table .table-container {
    max-height: initial;
  }

  .print-layout {
    .report-header,
    .ag-root-wrapper {
      border: none;
      min-width: 1000px;
      max-width: 1000px;
    }

    table.ag-root {
      display: table;
      min-width: 1000px;
      max-width: 1000px;
    }

    .ag-root-wrapper,
    .ag-root-body,
    //.ag-root,
    .ag-center-cols-container {
      width: 100% !important;
    }
  }

  .report-page.shade-reports {
    .ag-theme-alpine {
      --ag-borders-critical: none;
    }
    .ag-header-viewport,
    .ag-header-container,
    .ag-header-group-cell,
    .ag-header-cell {
      background: v-bind('reportShading.title.color1');
    }

    .ag-header-row {
      @apply font-medium;
    }

    .ag-group-expanded,
    .ag-header-cell-resize::after {
      display: none;
    }

    .ag-row:not(.ag-row-footer) {
      background: v-bind('reportShading.detail.color1');

      .ag-cell {
        background: v-bind('reportShading.detail.color1');
      }
    }

    .ag-row:not(.ag-row-footer):nth-child(odd) {
      background: v-bind('reportShading.detail.color2');

      .ag-cell {
        background: v-bind('reportShading.detail.color2');
      }
    }

    .expandable-row td,
    .header-row td,
    .ag-row-group {
      border-top: none !important;
      border-bottom: none !important;
      background: v-bind('reportShading.group.color1');

      .ag-cell {
        background: v-bind('reportShading.group.color1');
      }
    }

    .ag-row.ag-row-group.ag-row-level-0 {
      border-top: 1px solid theme('colors.gray.500') !important;
    }

    .ag-row-footer {
      background: v-bind('reportShading.totals.color1');
      margin-bottom: 26px;

      .ag-cell {
        border-top: none;
        background: v-bind('reportShading.totals.color1');
      }
    }
  }
</style>
