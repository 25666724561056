import { CollapseTransition, FadeTransition } from "vue2-transitions";
import { Collapse, CollapseItem } from "element-ui";
import { LoaderIcon } from 'vue-feather-icons'
import EntitySelect from "@/components/select/EntitySelect";
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import VoidButton from "@/components/common/VoidButton";
import PostButton from "@/components/common/PostButton";
import ProofListingButton from "@/components/common/ProofListingButton";
import DownloadButton from "@/components/common/DownloadButton";
import PostableTooltip from "@/components/common/PostableTooltip";
import DeleteButton from "@/components/common/DeleteButton";
import ValidationWarning from "@/components/common/ValidationWarning";
import InfiniteLoading from 'vue-infinite-loading';

// Links
import JobLink from "@/components/links/JobLink";
import LineItemLink from "@/components/links/LineItemLink";
import JobTypeLink from "@/components/links/JobTypeLink";
import AccountLink from "@/components/links/AccountLink";
import SubAccountLink from "@/components/links/SubAccountLink";
import UserLink from "@/components/links/UserLink";
import BankLink from "@/components/links/BankLink";
import PayCodeLink from "@/components/links/PayCodeLink";
import TimeOffPolicyLink from "@/components/links/TimeOffPolicyLink";
import SalesTaxDistrictLink from "@/components/links/SalesTaxDistrictLink";
import ServiceBillingTypeLink from "@/components/links/ServiceBillingTypeLink";
import VendorLink from "@/components/links/VendorLink";
import BillingLink from "@/components/links/BillingLink";
import CustomerLink from "@/components/links/CustomerLink";
import WorksiteLink from "@/components/links/WorksiteLink";
import EmployeeLink from "@/components/links/EmployeeLink";
import EquipmentLink from "@/components/links/EquipmentLink";
import MaterialLink from "@/components/links/MaterialLink";
import WorkOrderLink from "@/components/links/WorkOrderLink";
import TableIconButton from '@/components/table/actions/TableIconButton'
import TableDownloadButton from '@/components/table/actions/TableDownloadButton'
import TableEditButton from '@/components/table/actions/TableEditButton'
import TableEmailButton from '@/components/table/actions/TableEmailButton'
import TablePrintButton from '@/components/table/actions/TablePrintButton'
import TableShareButton from '@/components/table/actions/TableShareButton'
import TableViewButton from '@/components/table/actions/TableViewButton'
import TableSaveButton from '@/components/table/actions/TableSaveButton'
import TableDeleteButton from '@/components/table/actions/TableDeleteButton'
import TableAddButton from '@/components/table/actions/TableAddButton'
import TableRefreshButton from '@/components/table/actions/TableRefreshButton'
import TableActionItem from '@/components/table/actions/TableActionItem'

import PostAction from '@/components/common/modal/PostAction'
import InvoiceQuickPay from '@/components/common/modal/InvoiceQuickPay'
import VoidAction from '@/components/common/modal/VoidAction'
import StatusBadge from '@/components/common/StatusBadge'
import Wizard from '@/components/lists/Wizard'
import LayoutTabs from '@/components/common/LayoutTabs'

import BankSelect from '@/components/common/BankSelect'
import StateSelect from '@/modules/common/components/StateSelect'
import YearSelect from '@/modules/common/components/YearSelect'
import AccountSelect from '@/modules/ledger/components/AccountSelect'
import SubAccountSelect from '@/modules/ledger/components/SubAccountSelect'
import CompanySelect from '@/modules/settings/components/CompanySelect'
import FiscalYearSelect from '@/modules/dashboard/components/FiscalYearSelect'
import PeriodSelect from '@/modules/ledger/components/PeriodSelect'
import UserSelect from '@/modules/ledger/components/UserSelect'
import VendorSelect from '@/components/select/entities/VendorSelect'
import CustomerSelect from '@/components/select/entities/CustomerSelect'
import BillingSelect from '@/components/select/entities/BillingSelect'
import JournalSelect from '@/components/select/entities/JournalSelect'
import CompanyRoleSelect from '@/components/select/entities/CompanyRoleSelect.vue'
import JobSelect from '@/components/select/entities/JobSelect'
import W2CodeSelect from '@/components/select/entities/W2CodeSelect'
import EntityPreview from '@/modules/common/components/entity-preview/EntityPreview'
import InvoiceStatusPopover from '@/modules/accounts-payable/components/invoice/InvoiceStatusPopover'
import BillingRateTypeSelect from '@/components/select/entities/BillingRateTypeSelect'
import EmployeeSelect from '@/components/select/entities/EmployeeSelect'
import EmployeeSelectNew from '@/components/select/entities/EmployeeSelectNew'
import WorkerCompRateSelect from '@/components/select/entities/WorkerCompRateSelect'
import GenLiabilityRateSelect from '@/components/select/entities/GenLiabilityRateSelect'
import EquipmentClassSelect from '@/components/select/entities/EquipmentClassSelect'
import EquipmentAssetTypeSelect from '@/components/select/entities/EquipmentAssetTypeSelect'
import DocumentTypeSelect from '@/components/select/entities/DocumentTypeSelect.vue'
import MaterialClassSelect from '@/components/select/entities/MaterialClassSelect'
import WorkOrderSelect from '@/components/select/entities/WorkOrderSelect'
import MaterialSelect from '@/components/select/entities/MaterialSelect'
import EquipmentSelect from '@/components/select/entities/EquipmentSelect'
import WorkSiteSelect from '@/components/select/entities/WorkSiteSelect'
import PurchaseOrderSelect from '@/components/select/entities/PurchaseOrderSelect'
import UnionSelect from '@/components/select/entities/UnionSelect'
import ServiceBillingCategorySelect from '@/components/select/entities/ServiceBillingCategorySelect'
import JobCategorySelect from '@/components/select/entities/JobCategorySelect'
import SalesTaxDistrictSelect from '@/components/select/entities/SalesTaxDistrictSelect'
import FileAttachments from "@/modules/accounts-payable/components/attachements/FileAttachments";
import GenerateNextNumber from "@/modules/common/components/GenerateNextNumber";
import DepartmentSelect from "@/components/select/entities/DepartmentSelect";
import CraftCodeSelect from "@/components/select/entities/CraftCodeSelect";
import ReferenceLink from "@/components/links/ReferenceLink";
import AddressSelect from '@/components/select/entities/AddressSelect'
import TaggableInput from '@/components/form/TaggableInput'
import StateTaxSelect from '@/components/select/entities/StateTaxSelect'
import LocalTaxSelect from '@/components/select/entities/LocalTaxSelect'
import DistributionWarning from '@/components/common/DistributionWarning'
import StatusLink from '@/components/links/StatusLink'
import ContactSelect from '@/components/select/entities/ContactSelect'
import SummaryPill from "@/components/common/SummaryPill.vue";
import RangeFilter from "@/components/range-filters/RangeFilter.vue";
import EquipmentSpecialtySelect from "@/components/select/entities/EquipmentSpecialtySelect.vue";
import OcipSelect from "@/components/select/entities/OcipSelect.vue";
import CraftSelect from "@/components/select/entities/CraftSelect";
import AddressTypeSelect from "@/components/select/entities/AddressTypeSelect"
import JournalLink from "@/components/links/JournalLink.vue";
import StimulsoftLoadingPrintButton from "@/modules/purchasing-inventory/components/StimulsoftLoadingPrintButton.vue";
import PayCodeSelect from "@/components/select/entities/PayCodeSelect.vue";
import TaxCodeSelect from "@/components/select/entities/TaxCodeSelect.vue";
import CodeSelect from "@/components/select/entities/CodeSelect.vue";
import DeductionCodeSelect from "@/components/select/entities/DeductionCodeSelect.vue";
import CodeInput from "@/components/form/CodeInput.vue";
import ActiveSwitch from "@/components/common/ActiveSwitch.vue";
import DepartmentCodeLink from "@/components/links/DepartmentCodeLink.vue";
import TimeOffPolicySelect from "@/components/select/entities/TimeOffPolicySelect.vue";
import TimecardBatchSelect from "@/components/select/entities/TimecardBatchSelect.vue";
import PayrollBatchSelect from "@/components/select/entities/PayrollBatchSelect.vue";
import PayrollSelect from "@/components/select/entities/PayrollSelect.vue";
import TimecardSelect from "@/components/select/entities/TimecardSelect.vue";

const AgDataTable = () => import('@/components/ag-grid/AgDataTable')
const HtmlEditor = () => import('@/components/form/HtmlEditor')
const GenericChart = () => import('@/components/charts/GenericChart')

const StimulsoftPrintButton = () => ({
  component: import('@/modules/purchasing-inventory/components/StimulsoftPrintButton.vue'),
  loading: StimulsoftLoadingPrintButton,
  delay: 100,
})

/**
 * This will require and make global all components starting with **Base** (e.g BaseButton, BaseInput etc) or **Icon** (e.g IconPhone)
 */
const components = import.meta.globEager('../components/**/(Base|Icon)*.(vue|js)')

export default {
  install(Vue) {

    for (let filePath in components) {
      const componentName = upperFirst(
        camelCase(
          // Gets the file name regardless of folder depth
          filePath
            .split('/')
            .pop()
            .replace(/\.\w+$/, '')
        )
      )
      const componentConfig = components[filePath]
      // Register component globally
      Vue.component(
        componentName,
        // Look for the component options on `.default`, which will
        // exist if the component was exported with `export default`,
        // otherwise fall back to module's root.
        componentConfig.default || componentConfig
      )
    }

    // Other global components
    Vue.component(FadeTransition.name, FadeTransition)
    Vue.component(CollapseTransition.name, CollapseTransition)
    Vue.component(EntitySelect.name, EntitySelect)
    Vue.component(VoidButton.name, VoidButton)
    Vue.component(PostButton.name, PostButton)
    Vue.component(ProofListingButton.name, ProofListingButton)
    Vue.component(DownloadButton.name, DownloadButton)
    Vue.component(PostableTooltip.name, PostableTooltip)
    Vue.component(DeleteButton.name, DeleteButton)
    Vue.component(JobLink.name, JobLink)
    Vue.component(LineItemLink.name, LineItemLink)
    Vue.component(JobTypeLink.name, JobTypeLink)
    Vue.component(AccountLink.name, AccountLink)
    Vue.component(SubAccountLink.name, SubAccountLink)
    Vue.component(UserLink.name, UserLink)
    Vue.component(BankLink.name, BankLink)
    Vue.component(PayCodeLink.name, PayCodeLink)
    Vue.component(TimeOffPolicyLink.name, TimeOffPolicyLink)
    Vue.component(SalesTaxDistrictLink.name, SalesTaxDistrictLink)
    Vue.component(ServiceBillingTypeLink.name, ServiceBillingTypeLink)
    Vue.component(VendorLink.name, VendorLink)
    Vue.component(BillingLink.name, BillingLink)
    Vue.component(JournalLink.name, JournalLink)
    Vue.component(CustomerLink.name, CustomerLink)
    Vue.component(WorksiteLink.name, WorksiteLink)
    Vue.component(EmployeeLink.name, EmployeeLink)
    Vue.component(EquipmentLink.name, EquipmentLink)
    Vue.component(WorkOrderLink.name, WorkOrderLink)
    Vue.component(MaterialLink.name, MaterialLink)
    Vue.component(Collapse.name, Collapse)
    Vue.component(CollapseItem.name, CollapseItem)
    Vue.component(ActiveSwitch.name, ActiveSwitch)
    Vue.component('DepartmentCodeLink', DepartmentCodeLink)
    Vue.component('ValidationWarning', ValidationWarning)
    Vue.component('LoaderIcon', LoaderIcon)
    Vue.component('StatusBadge', StatusBadge)
    Vue.component('BankSelect', BankSelect)
    Vue.component('PeriodSelect', PeriodSelect)
    Vue.component('LayoutTabs', LayoutTabs)
    Vue.component('StateSelect', StateSelect)
    Vue.component('YearSelect', YearSelect)
    Vue.component('AccountSelect', AccountSelect)
    Vue.component('SubAccountSelect', SubAccountSelect)
    Vue.component('CompanySelect', CompanySelect)
    Vue.component('UserSelect', UserSelect)
    Vue.component('VendorSelect', VendorSelect)
    Vue.component('CustomerSelect', CustomerSelect)
    Vue.component('BillingSelect', BillingSelect)
    Vue.component('CompanyRoleSelect', CompanyRoleSelect)
    Vue.component('JournalSelect', JournalSelect)
    Vue.component('JobSelect', JobSelect)
    Vue.component('W2CodeSelect', W2CodeSelect)
    Vue.component('FiscalYearSelect', FiscalYearSelect)
    Vue.component('EntityPreview', EntityPreview)
    Vue.component('InfiniteLoading', InfiniteLoading)
    Vue.component('InvoiceStatusPopover', InvoiceStatusPopover)
    Vue.component('TableRefreshButton', TableRefreshButton)
    Vue.component('TableAddButton', TableAddButton)
    Vue.component('TableEditButton', TableEditButton)
    Vue.component('TableDeleteButton', TableDeleteButton)
    Vue.component('TableViewButton', TableViewButton)
    Vue.component('TableSaveButton', TableSaveButton)
    Vue.component('TableIconButton', TableIconButton)
    Vue.component('TableDownloadButton', TableDownloadButton)
    Vue.component('TableEmailButton', TableEmailButton)
    Vue.component('TablePrintButton', TablePrintButton)
    Vue.component('TableShareButton', TableShareButton)
    Vue.component('TableActionItem', TableActionItem)
    Vue.component('Wizard', Wizard)
    Vue.component('PostAction', PostAction)
    Vue.component('InvoiceQuickPay', InvoiceQuickPay)
    Vue.component('VoidAction', VoidAction)
    Vue.component('BillingRateTypeSelect', BillingRateTypeSelect)
    Vue.component('EmployeeSelect', EmployeeSelect)
    Vue.component('EmployeeSelectNew', EmployeeSelectNew)
    Vue.component('WorkerCompRateSelect', WorkerCompRateSelect)
    Vue.component('GenLiabilityRateSelect', GenLiabilityRateSelect)
    Vue.component('EquipmentClassSelect', EquipmentClassSelect)
    Vue.component('AgDataTable', AgDataTable)
    Vue.component('HtmlEditor', HtmlEditor)
    Vue.component('GenericChart', GenericChart)
    Vue.component('StimulsoftPrintButton', StimulsoftPrintButton)
    Vue.component('MaterialClassSelect', MaterialClassSelect)
    Vue.component('WorkOrderSelect', WorkOrderSelect)
    Vue.component('MaterialSelect', MaterialSelect)
    Vue.component('EquipmentSelect', EquipmentSelect)
    Vue.component('WorkSiteSelect', WorkSiteSelect)
    Vue.component('PurchaseOrderSelect', PurchaseOrderSelect)
    Vue.component('UnionSelect', UnionSelect)
    Vue.component('ServiceBillingCategorySelect', ServiceBillingCategorySelect)
    Vue.component('JobCategorySelect', JobCategorySelect)
    Vue.component('SalesTaxDistrictSelect', SalesTaxDistrictSelect)
    Vue.component('FileAttachments', FileAttachments)
    Vue.component('GenerateNextNumber', GenerateNextNumber)
    Vue.component('DepartmentSelect', DepartmentSelect)
    Vue.component('CraftCodeSelect', CraftCodeSelect)
    Vue.component('TimeOffPolicySelect', TimeOffPolicySelect)
    Vue.component('TimecardBatchSelect', TimecardBatchSelect)
    Vue.component('PayrollBatchSelect', PayrollBatchSelect)
    Vue.component('PayrollSelect', PayrollSelect)
    Vue.component('TimecardSelect', TimecardSelect)
    Vue.component('ReferenceLink', ReferenceLink)
    Vue.component('AddressSelect', AddressSelect)
    Vue.component('TaggableInput', TaggableInput)
    Vue.component('CodeInput', CodeInput)
    Vue.component('StateTaxSelect', StateTaxSelect)
    Vue.component('LocalTaxSelect', LocalTaxSelect)
    Vue.component('DistributionWarning', DistributionWarning)
    Vue.component('StatusLink', StatusLink)
    Vue.component('ContactSelect', ContactSelect)
    Vue.component('SummaryPill', SummaryPill)
    Vue.component('RangeFilter', RangeFilter)
    Vue.component('EquipmentSpecialtySelect', EquipmentSpecialtySelect)
    Vue.component('EquipmentAssetTypeSelect', EquipmentAssetTypeSelect)
    Vue.component('DocumentTypeSelect', DocumentTypeSelect)
    Vue.component('OcipSelect', OcipSelect)
    Vue.component('CraftSelect', CraftSelect)
    Vue.component('PayCodeSelect', PayCodeSelect)
    Vue.component('TaxCodeSelect', TaxCodeSelect)
    Vue.component('CodeSelect', CodeSelect)
    Vue.component('DeductionCodeSelect', DeductionCodeSelect)
    Vue.component('AddressTypeSelect', AddressTypeSelect)
  }
}
