<template>
  <VendorForm
      :key="renderKey"
      @create-another="renderKey++"
  />
</template>
<script>
  import VendorForm from '@/modules/accounts-payable/components/VendorForm'

  export default {
    components: {
      VendorForm,
    },
    data() {
      return {
        renderKey: 1,
      }
    },
  }
</script>
