<template>
  <div class="relative bg-gray-50 overflow-hidden min-h-screen flex flex-col items-center justify-center">

    <div class="relative pt-6 pb-12 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">
      <div class="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28">
        <div class="text-center">
          <h2 class="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
            Houston,
            <br class="xl:hidden"/>
            <span class="text-primary-600">we have a problem</span>
          </h2>
          <p class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            {{$t(`We’re not quite sure what went wrong. You can go back, or try contacting us if you need a hand.`)}}
          </p>
          <div class="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
            <base-button class="sm:mr-5"
                         variant="primary"
                         size="xl"
                         @click="goHome">
              {{$t('Take me Home')}}
            </base-button>
            <base-button class="sm:mr-5"
                         variant="white"
                         size="xl"
                         @click="openEmail">
              {{$t('Contact Us')}}
            </base-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    methods: {
      openEmail(){
        window.open(`mailto:support@constructionpartner.com?cc=russroy@constructionpartner.com&subject=Can't find my organization`)
      },
      async goHome() {
        await this.$router.push('/')
      }
    },
  }
</script>
<style>
</style>
