<template>
  <div>
    <h5 class="form-section-title">
      {{ customerCode }} {{ $t('Customer aging') }}
    </h5>
    <BaseDataTable
      :columns="columns"
      :data="tableData"
      :show-pagination="false"
      :data-loading="loading"
      :placeholderRows="1"
      compact
    />
  </div>
</template>
<script>
import axios from "axios";
import { dateTypes } from "@/plugins/dateFormatPlugin";

export default {
  props: {
    customerId: {
      type: String,
      required: true
    },
    customerCode: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      tableData: [],
      loading: true,
    }
  },
  computed: {
    requestParams() {
      return {
        group_by: 'job',
        today: true,
        format: 'customer-total',
        period: this.$currenPeriod,
        fiscal_year: this.$currentFiscalYear,
        customer_code_from: this.customerCode,
        customer_code_to: this.customerCode,
        actual_days: true,
        age_by: 'date',
        aging_days: [30, 60, 90],
        start_date: this.$formatDate(new Date(), dateTypes.IsoDate),
        date: new Date(),
      }
    },
    columns() {
      return [
        {
          prop: '30',
          label: this.$t('0 to 30 days'),
          component: 'FormattedPrice',
        },
        {
          prop: '60',
          label: this.$t('31 to 60 days'),
          component: 'FormattedPrice',
        },
        {
          prop: '90',
          label: this.$t('61 to 90 days'),
          component: 'FormattedPrice',
        },
        {
          prop: '90+',
          label: this.$t('Over 90 days'),
          component: 'FormattedPrice',
        }
      ]
    }
  },
  methods: {
    async getData() {
      try {
        this.loading = true
        const { data } = await axios.get(`/reports/report-5882`, {
          params: this.requestParams
        })
        this.tableData = [data.aging_totals]
      } finally {
        this.loading = false
      }
    }
  },
  mounted() {
    this.getData()
  }
}
</script>
<style>
</style>
