<template>
  <div>
    <base-form :save-text="$t('Update Settings')"
               :loading="loading"
               :show-buttons="$can('organization_update')"
               :auto-height="true"
               @cancel="$emit('cancel')"
               @submit="updateSettings"
    >
      <template v-slot:title>
        <div class="flex items-center">
          <span class="mr-1">{{ $t('Application Settings') }}</span>
        </div>
      </template>
      <template v-slot:description>
        <div class="my-1">
          {{ $t('Application specific settings that you can change such as the date format, currency format etc.') }}
        </div>
      </template>
      <div class="col-span-6 border-b mb-2 pb-1 mt-4">
        <span class="text-base leading-6 font-medium text-gray-900">{{ $t('Date & Time Format') }}</span>
      </div>
      <div class="col-span-6">
        <base-select v-model="model.date_format"
                     rules="required"
                     :options="generateDateOptions(dateFormatOptions)"
                     :label="$t('Date Format')"/>
        <div class="-mt-4 mb-4 text-sm flex flex-col">
          <span class="font-semibold mt-1">
            {{ formattedDate }}
          </span>
          <span class="input-label">
            {{ $t('Edit the date format to see how it will look like. Check out') }}
            <a class="text-primary" href="https://date-fns.org/v2.14.0/docs/format"
               target="_blank"
               rel="noopener">
              {{ $t('format examples here') }}
            </a>
          </span>
        </div>
      </div>
      <div class="col-span-6">
        <base-select v-model="model.datetime_format"
                     rules="required"
                     :options="generateTimeOptions(hourFormatOptions)"
                     :label="$t('Date Time Format')"/>
        <div class="-mt-4 mb-4 text-sm flex flex-col">
          <span class="font-semibold mt-1">
            {{ formattedDateTime }}
          </span>
          <span class="input-label">
            {{ $t('Edit the date time format to see how it will look like. Check out') }}
            <a class="text-primary" href="https://date-fns.org/v2.14.0/docs/format"
               target="_blank"
               rel="noopener">
              {{ $t('format examples here') }}
            </a>
          </span>
        </div>
      </div>
      <div class="col-span-6 border-b mb-2 pb-1 mt-4">
        <span class="text-base leading-6 font-medium text-gray-900">{{ $t('Currency Format') }}</span>
      </div>
      <div class="col-span-6 md:col-span-2">
        <base-select v-model="model.currency_format.thousands_separator"
                     rules="required"
                     :options="separators"
                     :label="$t('Thousands Separator')"/>
      </div>
      <div class="col-span-6 md:col-span-2">
        <base-select v-model="model.currency_format.decimals_point"
                     rules="required"
                     :options="separators"
                     :label="$t('Decimals Separator')"/>
      </div>
      <div class="col-span-6 md:col-span-2">
        <base-input v-model="model.currency_format.decimals"
                    :label="$t('Decimals')"
                    :disabled="true"
                    type="number"/>
      </div>
      <span class="col-span-6 font-semibold">
        {{ formattedCurrency }}
      </span>

      <base-detail-card :title="$t('Filter Settings')" class="col-span-6 mt-4">
        <base-detail-card-row :title="$t('Show inactive accounts')">
          <base-switch v-model="model.filter_options.show_inactive_accounts"/>
        </base-detail-card-row>

        <base-detail-card-row :title="$t('Show closed jobs')" stripe>
          <base-switch v-model="model.filter_options.show_inactive_jobs"/>
        </base-detail-card-row>

        <base-detail-card-row :title="$t('Show inactive vendors')">
          <base-switch v-model="model.filter_options.show_inactive_vendors"/>
        </base-detail-card-row>

        <base-detail-card-row :title="$t('Show inactive customers')">
          <base-switch v-model="model.filter_options.show_inactive_customers"/>
        </base-detail-card-row>

        <base-detail-card-row :title="$t('Show inactive equipment')">
          <base-switch v-model="model.filter_options.show_inactive_equipment"/>
        </base-detail-card-row>

        <base-detail-card-row :title="$t('Show inactive materials')">
          <base-switch v-model="model.filter_options.show_inactive_materials"/>
        </base-detail-card-row>

        <base-detail-card-row :title="$t('Hide created by me filter')">
          <base-switch v-model="model.filter_options.hide_created_by_me"/>
        </base-detail-card-row>
      </base-detail-card>
    </base-form>
  </div>
</template>
<script>
  import axios from 'axios'

  const jsonSettingKeys = ['currency_format', 'translations', 'filter_options']

  export default {
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        loading: false,
        model: {
          filter_options: {},
        },
        separators: [
          {
            label: this.$t('Comma'),
            value: ',',
          },
          {
            label: this.$t('Dot'),
            value: '.',
          },
        ],
        dateFormatOptions: [
          'MM/dd/yyyy',
          'MM/dd/yy',
          'MM-dd-yyyy',
          'MM-dd-yy',
          'MMM do yyyy',
          'MMMM do yyyy',
        ],
        hourFormatOptions: [
          'HH:mm:ss',
          'h:mm aaa',
          'HH:mm',
        ],
      }
    },
    computed: {
      organizationSettings() {
        return this.$store.state.settings.organizationSettings || []
      },
      formattedDateTime() {
        return this.$formatDate(new Date(), this.model.datetime_format)
      },
      formattedDate() {
        return this.$formatDate(new Date(), this.model.date_format)
      },
      formattedCurrency() {
        return `1000${this.model.currency_format.thousands_separator}000${this.model.currency_format.decimals_point}99`
      },
    },
    watch: {
      organizationSettings: {
        immediate: true,
        handler(value) {
          this.initModel(value)
        },
      },
    },
    methods: {
      generateDateOptions(options) {
        const date = new Date()
        return options.map(option => {
          return {
            value: option,
            label: this.$formatDate(date, option),
          }
        })
      },
      generateTimeOptions(options) {
        const date = new Date()
        return options.map(option => {
          const fullOption = `${this.model.date_format} ${option}`
          return {
            value: fullOption,
            label: this.$formatDate(date, fullOption),
          }
        })
      },
      preventAddStringAsValue(value) {
        if (typeof value === 'string') {
          return JSON.parse(value)
        }
        return value
      },
      initModel(settings) {
        settings.forEach(setting => {
          const key = setting.key
          let value = setting.value
          if (jsonSettingKeys.includes(key)) {
            value = this.preventAddStringAsValue(setting.value)
          }
          this.$set(this.model, key, value)
        })
      },
      async updateSettings() {
        try {
          this.loading = true
          for (const setting of this.organizationSettings) {
            let key = setting.key
            let value = this.model[setting.key]
            if (jsonSettingKeys.includes(key)) {
              value = JSON.stringify(value)
            }
            if (value === setting.value) {
              continue
            }
            await axios.put(`/restify/organization-settings/${setting.id}`, {
              ...setting,
              value,
            })
            this.$set(setting, 'value', value)
          }
          this.$success(this.$t('Organization settings updated'))
          this.$emit('save')
        } catch (err) {
          this.$error(this.$t('Could not update the organization settings'))
        } finally {
          this.loading = false
        }
      },
    },
  }
</script>
