<template>
  <div>
    <AgDataTable
        :columns="columns"
        :data="years"
        :add-text="$t('Open fiscal year')"
        :show-pagination="false"
        :add-button-disabled="!canOpenFiscalYear"
        :pagination="false"
        :actions-column-width="180"
        domLayout="autoHeight"
        hide-actions="filters"
        actions="add"
        @add="showAddDialog = true">
      <template
        v-if="$route.query.debug"
        #additional-actions-before
      >
        <BaseButton variant="primary-light" @click="showAddPreviousFYDialog = true">
          {{ $t('Open Previous Fiscal Year') }}
        </BaseButton>
      </template>
      <template #attributes.closed_at="{row}">
        <status-badge :status="row.attributes.closed_at ? $t('no') : $t('yes')"></status-badge>
      </template>
      <template #fiscalYear="{row}">
        <div class="flex items-center">
          <base-tooltip :content="$t('Current Fiscal Year')">
            <CheckCircleIcon v-if="isCurrent(row)"
                             class="w-5 h-5 text-green-400 mr-2 cursor-help"/>
          </base-tooltip>
          {{ row.attributes.fiscal_year }}
        </div>
      </template>
      <template #extra-actions="{row}">
        <template>
          <base-button v-if="canClose(row)"
                       variant="danger-link"
                       size="sm"
                       @click="onClose(row.attributes)">
            {{ $t('Close') }}
          </base-button>
          <base-button v-else-if="canReopen(row)"
                       variant="primary-link"
                       size="sm"
                       @click="onReopen(row.attributes)">
            {{ $t('Reopen') }}
          </base-button>
        </template>
        <template v-if="!row.attributes.balances_transferred">
          <base-tooltip :content="$t('Transfer Open Balances')">
            <base-button variant="green-link"
                         @click="onTransferBalance(row.attributes)">
              {{ $t('Transfer Balances') }}
            </base-button>
          </base-tooltip>
        </template>
      </template>
    </AgDataTable>
    <BaseFormDialog v-if="showAddDialog"
                    :title="$t('Open Accounts - New Fiscal Year')"
                    :open.sync="showAddDialog">
      <base-form :save-text="$t('Open')"
                 :show-cancel="showCancel"
                 :loading="loading"
                 layout="modal"
                 @cancel="showAddDialog = false"
                 @submit="onSubmit"
      >
        <div class="col-span-6 flex justify-center">
          <article class="prose text-center leading-5 mb-6 pb-5 border-b leading-5 text-gray-500">
            <div class="text-orange-600 mb-4 flex justify-center items-center mb-3">
              <IconWarning class="w-5 h-5 mx-1"/>
              {{ $t('Please Read the Following Warning') }}
            </div>
            <p class="flex justify-center">
              {{ $t('Genera Ledger accounts will now be setup for the new year with zero balances.') }}
            </p>
            <p class="flex justify-center">{{ $t('The old year will remain UNCHANGED.') }}</p>
            <p>{{ $t('You may transfer closing balances from the old year to the new year now, or you may do this later. You will still be able to post transaction to the old year using the Fiscal Year selector from the top-right of the screen.') }}</p>
          </article>
        </div>
        <div class="col-span-6 md:col-span-2">
          <base-input
              id="name"
              :value="nextFiscalYear"
              :label="$t('The current year will be set to')"
              :placeholder="$t('The current year will be set to')"
              readonly
              class="col-span-6 mt-4"
          />
        </div>
        <div class="col-span-6 md:col-span-2">
          <period-select
            v-model="model.first_month"
            :placeholder="$t('First Period')"
            :for-post="false"
            id="period_to"
          />
        </div>
        <div class="col-span-6 md:col-span-2">
          <base-input
              id="calendar_year_of_period_1"
              v-model="model.calendar_year_of_period_1"
              type="number"
              :label="$t('Calendar Year of Period 1')"
              :placeholder="$t('Calendar Year of Period 1')"
              rules="required"
              class="col-span-6"
              @change="onCalendarYearChange"
          />
        </div>
        <div class="col-span-6 md:col-span-2 flex">
          <base-switch v-model="model.transfer_balances"
                       :label-info="$t('Transfer Balances')"/>
          <base-tooltip :content="$t(`Select to transfer Closing Balances for Fiscal Year ${currentFiscalYear} to Opening Balance for Fiscal Year ${nextFiscalYear}`)">
            <IconInfo class="mx-1 w-5 h-5 hover:text-primary-300 cursor-help"/>
          </base-tooltip>
        </div>
        <template #footer>
          <base-cancel-button v-if="showCancel"
                              class="mr-3"
                              @click="showAddDialog = false">
            {{ $t('Cancel') }}
          </base-cancel-button>
          <base-submit-button
              type="submit"
              :loading="loading"
          >
            {{ $t('Open') }}
          </base-submit-button>
        </template>
      </base-form>
    </BaseFormDialog>

    <BaseFormDialog
      v-if="showAddPreviousFYDialog"
      :title="$t('Open Accounts - New Fiscal Year')"
      :open.sync="showAddPreviousFYDialog"
    >
      <base-form
        :save-text="$t('Open Previous Fiscal Year')"
        :loading="loading"
        layout="modal"
        @cancel="showAddPreviousFYDialog = false"
        @submit="onOpenPreviousYear"
      >
        <div class="col-span-6 md:col-span-2">
          <base-input
            id="previous_fiscal_year"
            type="number"
            v-model="previousFiscalYearModel.fiscal_year"
            :label="$t('Fiscal year to open')"
            :placeholder="$t('The year that will be created an open')"
            rules="required"
            class="col-span-6 mt-4"
          />
        </div>
        <div class="col-span-6 md:col-span-2">
          <period-select
            v-model="previousFiscalYearModel.first_month"
            :placeholder="$t('First Period')"
            :for-post="false"
            id="period_to"
          />
        </div>
      </base-form>
    </BaseFormDialog>
  </div>
</template>
<script>
  import axios from 'axios'
  import { CheckCircleIcon } from 'vue-feather-icons'


  export default {
    components: {
      CheckCircleIcon,
    },
    data() {
      return {
        showCancel: true,
        showAddDialog: false,
        loading: false,
        showAddPreviousFYDialog: false,
        previousFiscalYearModel: {
          fiscal_year: null,
          first_month: 1,
        },
        model: {
          calendar_year_of_period_1: null,
          first_month: 1,
          transfer_balances: false,
        },
        columns: [
          {
            label: this.$t('Fiscal Year'),
            prop: 'fiscalYear',
          },
          {
            label: this.$t('Balances Transferred'),
            prop: 'attributes.balances_transferred',
            component: 'Status',
          },
          {
            label: this.$t('Open'),
            prop: 'attributes.closed_at',
            component: 'Status',
          },
        ],
      }
    },
    computed: {
      nextFiscalYear() {
        let fiscalYear = this.currentFiscalYear + 1
        if (fiscalYear === this.lastFiscalYear) {
          fiscalYear = this.lastFiscalYear + 1
        }
        const currentYear = new Date().getFullYear()
        return isNaN(fiscalYear) ? currentYear : fiscalYear
      },
      years() {
        return this.$store.state.company.fiscalYears
      },
      currentFiscalYear() {
        return parseInt(this.$store.state.company.currentFiscalYear)
      },
      canOpenFiscalYear() {
        const current = this.years.find((year) => year.attributes.fiscal_year === this.currentFiscalYear)
        const previous = this.years.find((year) => year.attributes.fiscal_year === this.currentFiscalYear - 1)
        return this.isYearClosed(previous) && this.hasBalancesTransferred(current)
      },
      lastFiscalYear() {
        return this.years[0]?.attributes?.fiscal_year
      }
    },
    methods: {
      getPeriodName(period) {
        return this.$store.getters['company/getPeriodName'](period, 'originalLabel')
      },
      canClose(row) {
        const { closed_at } = row.attributes
        return !closed_at && !this.isCurrent(row)
      },
      canReopen(row) {
        return !!row.attributes.closed_at
      },
      hasBalancesTransferred(row) {
        return !!row?.attributes?.balances_transferred
      },
      onCalendarYearChange(year) {
        const fiscalYear = this.nextFiscalYear
        let calendarYear = +year
        if (!calendarYear) {
          return
        }
        if (calendarYear > fiscalYear || calendarYear < fiscalYear - 1) {
          this.$error(this.$t('Calendar year must be the same as the fiscal year or one year less.'))
        }
      },
      isYearClosed(row) {
        if (!row) {
          return true
        }
        return row.attributes?.closed_at && row.attributes?.balances_transferred
      },
      isCurrent(row) {
        return row.attributes.fiscal_year === this.currentFiscalYear
      },
      async onSubmit() {
        try {
          this.loading = true

          const data = {
            fiscal_year: this.nextFiscalYear,
            transfer_balances: this.model.transfer_balances,
            first_month: this.getPeriodName(this.model.first_month),
            calendar_year_of_period_1: this.model.calendar_year_of_period_1,
          }
          await axios.post('/restify/fiscal-years/action?action=open-new-fiscal-year', data)

          await this.$store.dispatch('company/getFiscalYears')
          await this.$store.dispatch('company/getCompanySettings')

          this.$success(this.$t('Fiscal year opened successfully'))
          this.showAddDialog = false
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could open fiscal year'))
        } finally {
          this.loading = false
        }
      },
      async onOpenPreviousYear() {
        try {
          this.loading = true

          const data = {
            fiscal_year: this.previousFiscalYearModel.fiscal_year,
            first_month: this.getPeriodName(this.previousFiscalYearModel.first_month),
            debug: true
          }
          await axios.post('/restify/fiscal-years/action?action=open-previous-fiscal-year', data)

          await this.$store.dispatch('company/getFiscalYears')
          await this.$store.dispatch('company/getCompanySettings')

          this.$success(this.$t('Fiscal year opened successfully'))
          this.showAddPreviousFYDialog = false
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could open fiscal year'))
        } finally {
          this.loading = false
        }
      },
      async onClose(row) {
        const confirmed = await this.$confirm({
          title: this.$t('Close fiscal year?'),
          description: this.$t('This action will close the selected year. After closing transactions can no longer be posted to this fiscal year.'),
          buttonText: this.$t('Confirm'),
        })

        if (!confirmed) {
          return
        }
        await axios.post('/restify/fiscal-years/action?action=close-fiscal-year', { ...row })
        await this.$store.dispatch('company/getFiscalYears')
      },
      async onReopen(row) {
        const confirmed = await this.$confirm({
          title: this.$t('Reopen fiscal year?'),
          description: this.$t('This action will reopen the selected year. Note that posting transactions to this fiscal year will affect the fiscal years after it.'),
          buttonText: this.$t('Confirm'),
        })

        if (!confirmed) {
          return
        }
        await axios.post('/restify/fiscal-years/action?action=reopen-fiscal-year', { ...row })
        await this.$store.dispatch('company/getFiscalYears')
      },
      async onTransferBalance(row) {
        const confirmDescription = `${this.$t('Closing Balances for Fiscal year')} <b>${this.currentFiscalYear - 1}</b> <br>  ${this.$t('will be transferred to Opening Balances for Fiscal Year')} <b>${row.fiscal_year}</b>.`

        const confirmed = await this.$confirm({
          title: this.$t('Transfer open balances?'),
          description: confirmDescription,
          buttonText: this.$t('Confirm'),
        })
        if (!confirmed) {
          return
        }

        await axios.post('/restify/fiscal-years/action?action=transfer-fiscal-year-balances', {...row})
        await this.$store.dispatch('company/getFiscalYears')
      },
    },
  }
</script>
