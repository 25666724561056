<template>
  <div>
    <CustomerLink
        v-if="level === 0"
        :data="customer"
        :show-preview="false"
        :show-name="true"
        link-class="text-sm text-gray-900"
    />
    <div v-if="level === 1" class="flex space-x-6">
      <template v-if="data.check">
        <span class="font-medium">
          {{ $t('Payment:') }} #{{ data.check.number }}
        </span>
        <span v-if="data.check.date">
          {{ $t('Date:') }} {{ $formatDate(data.check.date) }}
        </span>
        <div v-if="data.check.bank" class="flex space-x-2">
          <span>{{ $t('Bank:') }}</span> <BankLink :id="data.check?.bank?.id" class="bank-link"/>
        </div>
      </template>
      <template v-if="data.discount">
        <span class="font-medium">
          {{ $t('Discount') }}
        </span>
        <span v-if="data.date">
          {{ $t('Date:') }} {{ $formatDate(data.date) }}
        </span>
      </template>
    </div>
  </div>
</template>
<script>
  export default {
    computed: {
      data() {
        return this.params.node?.allLeafChildren[0]?.data || {}
      },
      customer() {
        return this.get(this.data, 'customer', {})
      },
      level() {
        return this.params.node.level
      }
    },
    data() {
      return {
        styleUpdated: false,
      }
    },
  }
</script>
<style>
  .bank-link a {
    @apply text-gray-900;
  }
</style>
