<template>
  <base-form-dialog v-bind="$attrs"
                    v-on="$listeners"
                    size="lg"
                    class="consent-modal"
                    :closeable="false"
                    :title="$t(`Electronic Pay Stub Notice Form`)"
  >
    <div v-loading="loading"
         class="prose flex flex-col w-full max-w-full mt-6 md:px-6"
         v-if="template.html"
         v-html="template.html">
    </div>
    <div v-else class="prose flex flex-col w-full max-w-full mt-6 md:px-6">
      {{$t('Consent template not provided.')}}
    </div>
    <div class="flex justify-center mt-6">
      <base-submit-button :loading="loading" @click="confirmConsent">
        {{ $t('Agree to receive paper pay statements.') }}
      </base-submit-button>
    </div>
  </base-form-dialog>
</template>
<script>
  import axios from "axios";

  export default {
    data() {
      return {
        loading: false,
        template: {
          html: ''
        }
      }
    },
    computed: {
      company() {
        return this.$store.state.company.activeCompany || {}
      },
      companyName() {
        return this.company.name
      },
      companyAddress() {
        const { addresses } = this.company
        if (!addresses || !addresses.length) {
          return ''
        }
        return addresses.find(address => address.attributes.type === 'billing') || addresses[0]
      },
      employee() {
        return this.$store.state.auth.user?.employee || {}
      },
    },
    methods: {
      async confirmConsent() {
        try {
          this.loading = true
          await axios.post(`/employee-consent/action`, {
            token: this.employee?.payroll_consent_token,
            action: 'confirm',
          })
          await this.$store.dispatch('auth/refreshProfile')
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not communicate with the server. Please try reloading the page'))
        } finally {
          this.loading = false
        }
      },
      async getConsentTemplate() {
        try {
          this.loading = true
          const templates = await axios.get(`/restify/app-templates`, {
            params: {
              formatted: true,
              key: 'employee_consent',
            }
          })
          this.template = this.get(templates.data, '[0].attributes') || {}
          if (!this.template || !this.template.html) {
            this.$emit('close')
          }
        } finally {
          this.loading = false
        }
      }
    },
    async created() {
      await this.getConsentTemplate()
    }
  }
</script>
<style lang="scss" scoped>
  .consent-modal {
    top: 0;
    @screen md {
      top: 5vh;
    }
    :deep(.dialog-inner) {
      @screen md {
        max-height: 90vh;
      }
    }
  }
</style>
