<template>
  <settings-form
      :fields="fields"
      :module-name="moduleName"
      @cancel="$router.push('/ledger/accounts')"
  >
    <template v-slot="{model}">
      <div class="col-span-3 lg:col-span-2">
        <account-select v-model="model.retained_earnings_account"
                        :label="$t('Retained Earnings Account')"
                        value-key="number"
                        rules="required"
        />
      </div>
      <div class="col-span-3 lg:col-span-2">
        <sub-account-select v-model="model.retained_earnings_subaccount"
                            :label="$t('Retained Earnings Sub Account')"
                            value-key="number"
        />
      </div>
      <div class="col-span-3 lg:col-span-1">
        <base-input v-model="model.calendar_year_of_period_1"
                    :label="$t('Calendar Year of Period 1')"
                    :placeholder="$t('Calendar Year of Period 1')"
        />
      </div>
      <div class="col-span-3 lg:col-span-1">
        <base-select
            v-model="model.prior_fiscal_year_status"
            :label="$t('Prior Fiscal Year Status')"
            :disabled="true"
            :options="fiscalYearStatus"
        />
      </div>

    </template>
  </settings-form>
</template>
<script>
  import SettingsForm from "@/modules/common/components/SettingsForm";

  export default {
    components: {
      SettingsForm,
    },
    data() {
      return {
        moduleName: 'general-ledger',
        fields: [
          'calendar_year_of_period_1',
          'current_bank_record_date',
          'current_bank_statement_balance',
          'current_fiscal_year',
          'date_current_fiscal_year_was_openend',
          'last_fiscal_period_posted',
          'last_fiscal_year_open_balance_posted',
          'last_fiscal_year_posted',
          'last_journal_number_posted',
          'prior_fiscal_year_status',
          'retained_earnings_account',
          'retained_earnings_subaccount',
        ],
        fiscalYearStatus: [
          {
            label: this.$t('Open'),
            value: 'open',
          },
          {
            label: this.$t('Closed'),
            value: 'closed',
          },
        ],
      }
    },
    computed: {
      periods() {
        return this.$store.getters['company/getAuthorizedToUsePeriods']
      },
    },
    methods: {
      periodLabel(periodNumber) {
        return this.$store.getters['company/getPeriodName'](periodNumber)
      }
    }
  }
</script>
