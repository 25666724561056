<template>
  <div>
    <AgDataTable
      :data="entries"
      :columns="columns"
      :is-row-selectable="isRowSelectable"
      :selected-rows.sync="selectedRows"
      :add-text="$t('New Row')"
      :add-row-on-tab="true"
      :pagination="false"
      :get-empty-row="getEmptyRow"
      actions="search,add"
      ref="gridTable"
      id="gridTable"
      showCellsLegend
      @grid-ready="onGridReady"
    >
      <template #custom-header/>
    </AgDataTable>
    <StimulsoftPrintButton
      ref="printButton"
      :data="printData"
      :entity="StimulsoftPrintEntities.Waivers"
    />
  </div>
</template>
<script>
  import axios from 'axios'
  import { cellEditors } from '@/components/ag-grid/cellEditors/cellEditors'
  import { getCellClasses, requiredValueSetter, stopEditingOnTab } from '@/components/ag-grid/columnUtils'
  import { validateAgDataTable } from '@/components/ag-grid/tableUtils'
  import { getDeleteColumn } from "@/components/ag-grid/columns/deleteColumns";
  import { StimulsoftPrintEntities } from "@/enum/stimulsoft";

  export default {
    props: {
      selections: {
        type: Array,
        default: () => [],
      },
      model: {
        type: Object,
        default: () => ({}),
      },
      module: {
        type: String,
      },
    },
    data() {
      return {
        StimulsoftPrintEntities,
        entries: [],
        selectedRows: [],
        emptyRow: {
          job_id: null,
          entity_id: null,
          is_joint_vendor: false,
          amount: 0,
        },
        printData: null,
      }
    },
    computed: {
      accountsPayableModule() {
        return this.module === this.$modules.AP
      },
      columns() {
        const columns = [
          {
            headerCheckboxSelection: true,
            checkboxSelection: true,
            align: 'center',
            minWidth: 70,
            maxWidth: 70,
          },
          {
            headerName: this.$t('Job'),
            field: 'job_id',
            component: 'JobLink',
            cellEditor: this.$cellEditors.GlobalResourceSelect,
            cellEditorParams: {
              resourceName: this.$globalResources.Jobs,
              rules: 'required',
              target: '_blank',
            },
            cellClass: params => getCellClasses(params, 'job_id'),
            valueSetter: params => {
              const jobId = params.newValue
              params.data.job_id = params.newValue
              if (this.accountsPayableModule) {
                return true
              }

              const job = this.$store.getters['globalLists/getResourceById'](this.$globalResources.Jobs, jobId)
              params.data.entity_id = job?.customer_id
              return true
            },
            editable: true,
          },
          {
            headerName: this.$t('Vendor'),
            field: 'entity_id',
            component: 'VendorLink',
            cellEditor: this.$cellEditors.GlobalResourceSelect,
            cellEditorParams: {
              resourceName: this.$globalResources.Vendors,
              rules: 'required',
              target: '_blank',
            },
            cellClass: params => getCellClasses(params, 'entity_id'),
            editable: true,
            renderIf: () => this.model.module === this.$modules.AP,
          },
          {
            headerName: this.$t('Customer'),
            field: 'entity_id',
            component: 'CustomerLink',
            cellEditor: 'GlobalResourceCell',
            cellEditorParams: {
              resourceName: this.$globalResources.Customers,
              rules: 'required',
              target: '_blank',
            },
            editable: true,
            renderIf: () => this.model.module === this.$modules.AR,
          },
          {
            headerName: this.$t('Joint Check'),
            field: 'is_joint_vendor',
            component: 'Status',
            cellEditor: cellEditors.Boolean,
            cellEditorParams: {
              showYesNoLabel: true,
            },
            editable: true,
            minWidth: 70,
            maxWidth: 80,
            renderIf: () => this.model.module === this.$modules.AP,
          },
          {
            headerName: this.$t('Billing'),
            field: 'billing_id',
            editable: true,
            valueSetter: params => {
              if (!params.newValue) {
                params.data.billing = null
                params.data.amount = 0
              }
              params.data.billing_id = params.newValue
              return true
            },
            valueFormatter: params => {
              const billing = params.data?.billing?.attributes
              if (!billing) {
                return ''
              }
              return `#${billing?.number || ''} (${this.$formatPrice(billing?.open_amount || 0)})`
            },
            cellEditor: cellEditors.BillingSelect,
            cellEditorParams: params => {
              return {
                initialValue: params.data?.billing,
                onValueChanged(params, value, fullValue) {
                  params.data.billing = fullValue
                  params.data.amount = fullValue?.attributes?.gross_amount || 0
                },
                urlParams: {
                  job_id: params.data?.job_id,
                  customer_id: params.data?.entity_id,
                },
              }
            },
            renderIf: () => this.model.module === this.$modules.AR,
          },
          {
            label: this.$t('Amount'),
            field: 'amount',
            editable: true,
            maxWidth: 200,
            cellEditor: cellEditors.Numeric,
            component: 'FormattedPrice',
            valueSetter: params => requiredValueSetter(params, 0),
            suppressKeyboardEvent: stopEditingOnTab,
          },
          {
            ...getDeleteColumn({}),
            minWidth: 65,
            maxWidth: 65,
          },
        ]

        return columns.filter(column => column.renderIf ? column.renderIf() : true)
      },
    },
    methods: {
      getEmptyRow() {
        return {
          ...this.emptyRow,
          _localId: crypto.randomUUID(),
        }
      },
      isRowSelectable(params) {
        return true
      },
      mapRows() {

        return this.selectedRows.map(row => {
          delete row.dirty
          delete row.id
          return {
            ...row,
            entity_type: this.model.module === this.$modules.AP ? 'vendor' : 'customer',
          }
        }).filter(row => row.job_id && row.entity_id)
      },
      async tryGeneratePdf() {
        if (!this.selectedRows?.length) {
          return this.$error(this.$t('Please add / select at least one row'))
        }

        const isInvalidData = await validateAgDataTable()
        if (isInvalidData) {
          return
        }

        let payload = {
          template_id: this.model.template_id,
          module: this.model.module,
          document_date: this.model.document_date,
          through_date: this.model.through_date,
          claimant_title: this.model.claimant_title || '',
          items: this.mapRows(),
        }

        this.printData = await axios.post('/waivers-and-releases/stimulsoft', payload)
        await this.$refs?.printButton?.onPrintClick()
      },
      mapTableData(data) {
        if (!data.length) {
          this.entries = [{
            ...this.emptyRow,
            id: crypto.randomUUID(),
          }]

          return
        }

        this.entries = data.map(item => {
          item.id = crypto.randomUUID()
          return item
        })
      },
      onGridReady() {
        this.selectedRows = this.entries
      },
    },
    watch: {
      selections: {
        immediate: true,
        handler(data) {
          this.mapTableData(data)
        },
      },
    },
  }
</script>
