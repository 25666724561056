<template>
  <AgDataTable
    v-bind="$attrs"
    :columns="columns"
    :url="url"
    :urlParams="urlParams"
    :groupIncludeTotalFooter="true"
    :transform-data="transformData"
    domLayout="autoHeight"
    class="mt-4"
    action-type="post"
    ref="table"
  >

    <template #number="{row}">
      <JobLink :data="row"/>
    </template>

  </AgDataTable>
</template>
<script>

import axios from 'axios'
import { cellClasses, percentageValidator, requiredValueSetter } from '@/components/ag-grid/columnUtils'

export default {
  props: {
    urlParams: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      url: '/restify/review-job-summaries',
      columns: [
        {
          headerName: '',
          children: [
            {
              headerName: this.$t('Job'),
              field: 'number',
              minWidth: 250,
              maxWidth: 300,
              sortable: true,
              cellClass: params => {
                if (params.node.footer) {
                  return ''
                }
                return cellClasses.ReadOnlyLight
              },
            },
            {
              headerName: this.$t('Budget Cost To-Date'),
              field: 'budget_cost_to_date',
              component: 'FormattedPrice',
              align: 'right',
              minWidth: 140,
              maxWidth: 200,
              aggFunc: 'sum',
              cellClass: params => {
                if (params.node.footer) {
                  return 'flex justify-end'
                }
                return `flex justify-end ${ cellClasses.ReadOnlyLight }`
              },
            },
            {
              headerName: this.$t('Actual Cost To-Date'),
              field: 'actual_cost_to_date',
              component: 'FormattedPrice',
              align: 'right',
              minWidth: 130,
              maxWidth: 200,
              aggFunc: 'sum',
              cellClass: params => {
                if (params.node.footer) {
                  return 'flex justify-end'
                }
                return `flex justify-end ${ cellClasses.ReadOnlyLight }`
              },
            },
          ],
        },
        {
          headerName: this.$t('Completion'),
          children: [
            {
              headerName: this.$t('Prior'),
              field: 'completion',
              align: 'right',
              component: 'FormattedPercent',
              minWidth: 80,
              maxWidth: 120,
              aggFunc: 'avg',
              cellClass: params => {
                if (params.node.footer) {
                  return 'flex justify-end'
                }
                return `flex justify-end ${ cellClasses.ReadOnlyLight }`
              },
            },
            {
              headerName: this.$t('Current'),
              field: 'current_completion',
              align: 'right',
              component: 'FormattedPercent',
              minWidth: 100,
              maxWidth: 160,
              aggFunc: 'avg',
              editable: true,
              valueSetter: (params) => {
                const isValid = requiredValueSetter(params, 0, percentageValidator)

                if (!isValid) {
                  return false
                }

                this.computeEstimatedCost(params)
                this.updateCompletion(params)

                return true
              },
            },
          ],
        },
        {
          headerName: '',
          children: [
            {
              headerName: this.$t('Estimated Cost Variance'),
              field: 'estimated_cost_variance',
              component: 'FormattedPrice',
              align: 'right',
              aggFunc: 'sum',
              minWidth: 140,
              maxWidth: 200,
              cellClass: params => {
                let classes = 'flex justify-end'
                if (params.node.footer) {
                  return classes
                }
                classes += ` ${ cellClasses.ReadOnlyLight }`
                return params.value < 0 ? (classes + ' text-red-500') : classes
              },
            },
            {
              headerName: this.$t('Contract To-Date'),
              field: 'contract_to_date',
              component: 'FormattedPrice',
              align: 'right',
              aggFunc: 'sum',
              minWidth: 120,
              maxWidth: 200,
              cellClass: params => {
                if (params.node.footer) {
                  return 'flex justify-end'
                }
                return `flex justify-end ${ cellClasses.ReadOnlyLight }`
              },
            },
            {
              headerName: this.$t('Billings To-Date'),
              field: 'billings_to_date',
              component: 'FormattedPrice',
              aggFunc: 'sum',
              align: 'right',
              minWidth: 150,
              cellClass: params => {
                if (params.node.footer) {
                  return 'flex justify-end'
                }
                return `flex justify-end ${ cellClasses.ReadOnlyLight }`
              },
            },
          ],
        },
      ],
    }
  },
  methods: {
    computeEstimatedCost(params) {
      let entry = params.data

      const toDividePercentage = entry.current_completion > 0 ? entry.current_completion : entry.completion

      const estimatedCostVariance = entry.actual_cost_to_date * 100 / toDividePercentage

      entry.estimated_cost_variance = estimatedCostVariance.toFixed(2)

      params.node.setData(entry)
    },
    async updateCompletion(params) {
      try {
        params.data.loading = true

        let row = params.data

        let data = [{
          job_id: row.id,
          completion: row.current_completion,
        }]

        await axios.post('/restify/line-items/action?action=job-progress-updating-by-ids', { data })
      } catch (err) {
        console.warn(err)
        if (err.handled) {
          return
        }
        this.$error(this.$t('Something went wrong. Please try again.'))
      } finally {
        params.data.loading = false
      }
    },
    transformData(data) {
      return data.map(item => {
        item.current_completion = item.completion
        return item
      })
    },
    refresh() {
      this.$refs.table.refresh()
    },
  },
}
</script>
