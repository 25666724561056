<template>
  <div class="job-details">
    <template v-if="job">
      <div class="col-span-6">
        <div class="mb-5 grid grid-cols-1 gap-5 md:grid-cols-3 lg:grid-cols-4">
        </div>
      </div>
      <DetailsPageLayout :loading="loading || jobLoading">
        <template #left>
          <JobDetailsPrimaryCard :job="job"/>
          <JobDetailsSecondaryCard :job="job"/>
          <JobBudgetStatsCard
              :line-items="lineItems"
              :loading="lineItemsLoading"
              :type="costOrIncomeTypes.Cost"
          />
          <JobBudgetStatsCard
              :line-items="lineItems"
              :loading="lineItemsLoading"
              :type="costOrIncomeTypes.Income"
          />
        </template>
        <template #right>
          <JobLineItemBudgetsChart :title="$t('Job Budgets')" :job-id="job.id" class="border"/>

          <AddressesDetailsCard
              :addresses="jobAddresses"
              no-data-text="No addresses were added for this job"
          />
          <ContactsDetailsCard
              :contacts="job.relationships.contacts"
              no-data-text="No contacts were added for this job"
          />

          <base-detail-card :title="$t('Job Specifications')">
            <base-detail-card-row :title="$t('Pre Notice')">
              <div v-if="preNoticeId">
                <router-link
                    :to="`/job-costing/settings/pre-notice/${preNoticeId}/edit`"
                    target="_blank"
                    class="btn-link"
                >
                  <div class="flex items-center">
                    <span class="mr-2">{{ $t('View Pre-Notice') }}</span>
                    <ExternalLinkIcon class="w-4 h-4"/>
                  </div>
                </router-link>
              </div>
              <span v-else></span>
            </base-detail-card-row>

            <base-detail-card-row :title="$t('District')" stripe>
              <SalesTaxDistrictLink
                  :data="job.relationships?.district"
                  :id="job.attributes?.district_id"/>
            </base-detail-card-row>
            <base-detail-card-row :title="$t('Arch/Engr Project #')" stripe>
              {{ job.attributes.project_number || '' }}
            </base-detail-card-row>
            <base-detail-card-row :title="$t('Job Units')">
              {{ job.attributes.units }}
            </base-detail-card-row>
            <base-detail-card-row :title="$t('U/M')" stripe>
              {{ job.attributes.um || '' }}
            </base-detail-card-row>
            <base-detail-card-row :title="$t('Pre-Bill Worksheet')">
              {{ job.attributes.pre_bill_worksheet || '' }}
            </base-detail-card-row>
          </base-detail-card>
          <base-detail-card :title="$t('Payroll Settings')">
            <base-detail-card-row :title="$t('Certified Payroll')">
              <status-badge :status="job.attributes.certified_payroll"/>
            </base-detail-card-row>

            <base-detail-card-row :title="$t('Print')" stripe>
              {{ job.attributes.class }}
            </base-detail-card-row>

            <base-detail-card-row :title="$t('Last Payroll')">
              {{ $formatPrice(job.attributes.last_payroll_number) }}
            </base-detail-card-row>

            <base-detail-card-row :title="$t('Last Pay Period')" stripe>
              {{ $formatDate(job.attributes.last_pay_period) }}
            </base-detail-card-row>

            <base-detail-card-row :title="$t('Compliance Format')">
              {{ formattedText(job.attributes.compliance_format) }}
            </base-detail-card-row>

            <base-detail-card-row :title="$t('Print SS')" stripe>
              <status-badge :status="job.attributes.print_ss"/>
            </base-detail-card-row>

            <base-detail-card-row :title="$t('Union Code')">
              {{ job.attributes.union_code || '' }}
            </base-detail-card-row>
            <base-detail-card-row :title="$t('Union Rates')" stripe>
              {{ job.attributes.union_rates || '' }}
            </base-detail-card-row>

          </base-detail-card>

          <!--They don't serve a purpose now. We can enable them later on-->
          <base-detail-card v-show="false" :title="$t('Job Dates')">
            <base-detail-card-row :title="$t('Contract Start Date')">
              {{ $formatDate(job.attributes.contract_start_date) }}
            </base-detail-card-row>

            <base-detail-card-row :title="$t('Schedule start Date')" stripe>
              {{ $formatDate(job.attributes.schedule_start_date) }}
            </base-detail-card-row>

            <base-detail-card-row :title="$t('Actual start Date')">
              {{ $formatDate(job.attributes.actual_start_date) }}
            </base-detail-card-row>

            <base-detail-card-row :title="$t('Original completion Date')" stripe>
              {{ $formatDate(job.attributes.original_completion_date) }}
            </base-detail-card-row>

            <base-detail-card-row :title="$t('Current completion Date')">
              {{ $formatDate(job.attributes.current_completion_date) }}
            </base-detail-card-row>

            <base-detail-card-row :title="$t('Schedule completion Date')" stripe>
              {{ $formatDate(job.attributes.schedule_completion_date) }}
            </base-detail-card-row>

            <base-detail-card-row :title="$t('Actual completion Date')">
              {{ $formatDate(job.attributes.actual_completion_date) }}
            </base-detail-card-row>

            <base-detail-card-row :title="$t('B.O.D Date')" stripe>
              {{ $formatDate(job.attributes.BOD_date) }}
            </base-detail-card-row>

            <base-detail-card-row :title="$t('N.O.C Filed Date')">
              {{ $formatDate(job.attributes.NOC_date) }}
            </base-detail-card-row>

          </base-detail-card>
        </template>
      </DetailsPageLayout>
      <base-back-link to="/job-costing/jobs"/>
    </template>
  </div>
</template>
<script>
  import { formattedText } from '@/utils/utils'
  import Cache from '@/utils/Cache.js'
  import { ExternalLinkIcon } from 'vue-feather-icons'
  import { costOrIncomeTypes } from "@/components/grid-table/utils/cost-center";
  import JobBudgetStatsCard from "@/modules/job-costing/components/line-items/JobBudgetStatsCard.vue";
  import ContactsDetailsCard from '@/modules/common/components/contacts/ContactsDetailsCard.vue'
  import AddressesDetailsCard from '@/modules/common/components/AddressesDetailsCard.vue'
  import JobLineItemBudgetsChart
    from "@/modules/accounts-receivable/pages/dashboard/widgets/JobLineItemBudgetsChart.vue";
  import DetailsPageLayout from "@/modules/common/components/DetailsPageLayout.vue";
  import JobDetailsPrimaryCard from "@/modules/job-costing/components/job/JobDetailsPrimaryCard.vue";
  import JobDetailsSecondaryCard from "@/modules/job-costing/components/job/JobDetailsSecondaryCard.vue";

  export default {
    components: {
      JobDetailsPrimaryCard,
      JobDetailsSecondaryCard,
      DetailsPageLayout,
      JobLineItemBudgetsChart,
      AddressesDetailsCard,
      ContactsDetailsCard,
      JobBudgetStatsCard,
      ExternalLinkIcon
    },
    props: {
      id: [String, Number],
    },
    data() {
      return {
        loading: false,
        costOrIncomeTypes,
        lineItems: [],
        itemsLoading: false,
      }
    },
    computed: {
      job() {
        return this.$store.state.jobCosting.currentJob
      },
      jobLoading() {
        return this.$store.state.jobCosting.jobLoading
      },
      lineItemsLoading() {
        return this.jobLoading || this.itemsLoading
      },
      jobAddresses() {
        return this.get(this.job, 'relationships.addresses', [])
      },
      jobAddress() {
        const { addresses } = this.job.relationships
        if (!addresses || !addresses.length) {
          return ''
        }
        return addresses.find(address => address.type === 'billing') || addresses[0]
      },
      preNoticeId() {
        return this.get(this.job, 'relationships.preNotice.id')
      }
    },
    methods: {
      formattedText,
      async getJobLineItems() {
        try {
          this.itemsLoading = true
          this.lineItems = await Cache.getRequest(`/restify/line-items`, {
            params: {
              job_id: this.job.id,
              related: 'budgets',
            }
          })
        } finally {
          this.itemsLoading = false
        }
      },
    },
    async mounted() {
      await this.getJobLineItems()
    }
  }
</script>
