<template>
  <div>
    <div class="flex items-center justify-between w-full leading-4">
      <div class="flex space-x-4 items-center">
        <EmployeeLink :id="employeeId"/>
        <span>
          {{ $formatDate(data?.attributes?.date, LongDateFormat) }}
        </span>
        <TimesheetTags :row="timesheet"/>
      </div>
      <div>
        <TableViewButton
          :loading="loading"
          @click="onViewTimesheet"
        />
      </div>
    </div>
    <TimesheetDialog
      v-if="showTimesheetDialog"
      :open.sync="showTimesheetDialog"
      :timesheet="selectedTimesheet"
      :show-next-button="false"
    />
  </div>
</template>
<script>

import TimesheetDialog from "@/modules/payroll/components/timesheets/TimesheetDialog.vue";
import axios from "axios";
import { LongDateFormat } from "@/plugins/dateFormatPlugin";
import TimesheetTags from "@/modules/payroll/components/timesheets/TimesheetTags.vue";

export default {
  components: {
    TimesheetTags,
    TimesheetDialog
  },
  data() {
    return {
      LongDateFormat,
      loading: false,
      showTimesheetDialog: false,
      selectedTimesheet: null,
    }
  },
  computed: {
    children() {
      return this.params.node?.allLeafChildren
    },
    data() {
      return this.children[0]?.data || {}
    },
    employeeId() {
      return this.data?.attributes?.employee_id || {}
    },
    timesheet() {
      const timesheetData = this.data?.relationships?.timesheet || {}
      return {
        id: timesheetData?.id,
        attributes: timesheetData
      }
    }
  },
  methods: {
    async onViewTimesheet() {
      try {
        this.loading = true
        this.selectedTimesheet = this.timesheet
        this.showTimesheetDialog = true
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
