<template>
  <div class="grid grid-cols-2 gap-4">
    <base-data-table
        v-bind="$attrs"
        :data="getSummary"
        :columns="columns"
        :can-reorder-columns="false"
        :show-pagination="false"
        :has-summary="true"
        :compact="true"
        :limit-max-height="false"
        :class="isCostType && !showBudgetsOnly ? 'col-span-2 md:col-span-1' : 'col-span-2'"
        key="types"
    >
      <template #percent="{row}">
        {{ $formatPercent(row.percent) }}
      </template>
      <template #abbr="{row}">
        {{ getJobType(row.abbr)?.name || row.abbr }}
      </template>
      <template #abbr_summary>
        <span class="text-sm font-semibold">
          {{ $t('Total') }}
        </span>
      </template>
      <template #budget_summary>
        <span class="text-sm font-semibold">
          {{ $formatPrice(getTypesSummary.budget) }}
        </span>
      </template>
      <template #actual_summary>
        <span class="text-sm font-semibold">
          {{ $formatPrice(getTypesSummary.actual) }}
        </span>
      </template>
      <template #remaining_summary>
        <span class="text-sm font-semibold">
          {{ $formatPrice(getTypesSummary.remaining) }}
        </span>
      </template>
      <template #percent_summary="{row}">
        <span class="text-sm font-semibold">
          {{ $formatPercent(getTypesSummary.percent) }}
        </span>
      </template>
    </base-data-table>
    <template v-if="!showBudgetsOnly">
      <base-data-table
          v-if="isCostType"
          :data="getAdditionalSummary"
          :columns="additionalSummaryColumns"
          :can-reorder-columns="false"
          :show-pagination="false"
          :compact="true"
          key="totals"
          class="col-span-2 md:col-span-1"
      />
      <JobProgressUpdateActivity
          :url-params="{
          line_item_id: data.id
        }"
          :title="$t('Progress Update Activity') "
          class="col-span-2"
      />
    </template>
  </div>
</template>
<script>
  import { moduleAccountTypes } from '@/components/form/util'
  import JobProgressUpdateActivity from "@/modules/job-costing/components/line-items/JobProgressUpdateActivity";
  import { uniqueJobTypeAbbreviations } from "@/enum/enums";

  export default {
    components: {
      JobProgressUpdateActivity
    },
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      showBudgetsOnly: {
        type: Boolean,
        default: false,
      },
      showEmptyBudgets: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        columns: [
          {
            label: this.$t('Type'),
            prop: 'abbr',
            minWidth: 100,
            maxWidth: 100,
          },
          {
            label: this.$t('Budget'),
            prop: 'budget',
            component: 'FormattedPrice',
            align: 'right',
            minWidth: 60,
            maxWidth: 100,
          },
          {
            label: this.$t('Actual'),
            prop: 'actual',
            component: 'FormattedPrice',
            align: 'right',
            minWidth: 60,
            maxWidth: 100,
          },
          {
            label: this.$t('Remaining'),
            prop: 'remaining',
            component: 'FormattedPrice',
            align: 'right',
            minWidth: 60,
            maxWidth: 100,
          },
          {
            label: this.$t('% Est Completion'),
            prop: 'percent',
            component: 'FormattedPercent',
            align: 'right',
            minWidth: 40,
            maxWidth: 60,
          },
        ],
        additionalSummaryColumns: [
          {
            label: this.$t('Type'),
            prop: 'name',
          },
          {
            label: this.$t('Budget'),
            prop: 'budget',
            align: 'right',
          },
          {
            label: this.$t('Actual'),
            prop: 'actual',
            align: 'right',
          },
          {
            label: this.$t('Remaining'),
            prop: 'remaining',
            align: 'right',
          },
        ],
      }
    },
    computed: {
      allJobTypes() {
        return this.$store.state.globalLists.jobTypes || []
      },
      isCostType() {
        return this.budget.type === moduleAccountTypes.Cost
      },
      budget() {
        return this.data.attributes
      },
      getSummary() {
        if (!this.data?.meta) {
          return []
        }

        let { types } = this.get(this.data, `meta.summary[${this.budget.type}]`, { types: [] })

        types = types.map(type => {
          const remaining = +type.budget - +type.actual

          return {
            ...type,
            remaining,
          }
        })

        if (this.showEmptyBudgets) {
          return types
        }

        types = types.filter(type => {
          return type.budget > 0 || type.actual > 0
        })

        // ? TBD: what means production units filter
        return types
      },
      getAdditionalSummary() {
        if (!this.budget?.id) {
          return []
        }
        const materialBudget = this.getBudgetByAbbr(uniqueJobTypeAbbreviations.Material)
        const laborBudget = this.getBudgetByAbbr(uniqueJobTypeAbbreviations.Labor)
        const equipmentBudget = this.getBudgetByAbbr(uniqueJobTypeAbbreviations.Equipment)

        return [
          {
            name: this.$t('Quantity'),
            budget: this.$formatFloat(materialBudget.quantity, 3),
            actual: this.$formatFloat(materialBudget.quantity_to_date, 3),
            remaining: this.$formatFloat(+materialBudget.quantity - +materialBudget.quantity_to_date, 3),
          },
          {
            name: this.$t('Labor Hours'),
            budget: this.$formatFloat(laborBudget.quantity),
            actual: this.$formatFloat(laborBudget.quantity_to_date),
            remaining: this.$formatFloat(+laborBudget.quantity - +laborBudget.quantity_to_date),
          },
          {
            name: this.$t('Equipment Hours'),
            budget: this.$formatFloat(equipmentBudget.quantity),
            actual: this.$formatFloat(equipmentBudget.quantity_to_date),
            remaining: this.$formatFloat(+equipmentBudget.quantity - +equipmentBudget.quantity_to_date),
          },
        ]
      },
      getTypesSummary() {
        const summary = this.get(this.data, `meta.summary[${this.budget.type}]`, { types: [], totals: {} })
        let result = {
          budget: 0,
          actual: 0,
          remaining: 0,
          percent: summary?.totals?.percent || 0,
        }

        const types = this.getSummary

        types.forEach(type => {
          result.budget += type.budget
          result.actual += type.actual
          result.remaining += type.remaining
        })

        return result
      },
    },
    methods: {
      getJobType(abbr) {
        return this.allJobTypes.find(j => j.abbr === abbr)
      },
      getBudgetByAbbr(abbr) {
        const defaultData = {
          quantity: 0,
          quantity_to_date: 0,
        }
        return this.getSummary.find(type => type.abbr === abbr) || defaultData
      },
    },
  }
</script>
