<template>
  <base-tooltip :content="$t('Download')"
                :tabindex="-1">
    <base-button variant="gray-light"
                 :tabindex="0"
                 size="icon"
                 :class="{
                    'mr-2': hasMargin,
                 }"
                 v-bind="$attrs"
                 @click="$emit('click')"
    >
      <DownloadIcon class="w-4 h-4" />
    </base-button>
  </base-tooltip>
</template>
<script>
  import { DownloadIcon } from 'vue-feather-icons'

  export default {
    inheritAttrs: false,
    components: {
      DownloadIcon,
    },
    props: {
      hasMargin: {
        type: Boolean,
        default: true,
      }
    }
  }
</script>
