<template>
  <base-report-form
      :filters="model"
      :has-own-data-fetcher="true"
      :hide-presets="true"
      :is-report-table="false"
      save-text="Apply Filters"
      @on-update-filter="onSubmit"
  >

    <template #filters>

      <base-filter-row :title="$t('View Selections')">
        <base-select
            v-model="model.view_selections"
            :options="viewSelectionTypes"
            id="view_selection"
            class="col-span-8 md:col-span-4"
            multiple
        />
        <customer-select
            v-model="model.customer_id"
            :hide-label="true"
            :add-entity="false"
            class="col-span-8 md:col-span-4 lg:col-span-3"
            clearable
        />
      </base-filter-row>

      <base-filter-row>
        <base-checkbox
            v-model="selectJobList"
            :label="$t('Select a List of Jobs')"
            class="col-span-3 mb-3"
            id="select-job-list"
            @change="resetJobFilters"
        />
      </base-filter-row>

      <base-filter-row :title="$t('Jobs')">

        <base-filter-range
            v-if="!selectJobList"
            class="col-span-4"
        >

          <template #start>
            <job-select
                v-model="model.from"
                :urlParams="jobUrlParams"
                :add-entity="false"
                :placeholder="$t('Job From')"
                :hide-label="true"
                value-key="number"
                class="col-span-4"
                clearable
            />
          </template>

          <template #end>
            <job-select
                v-model="model.to"
                :urlParams="jobUrlParams"
                :add-entity="false"
                :placeholder="$t('Job To')"
                :hide-label="true"
                :fetch-on-mount="false"
                :rules="model.from ? 'required' : ''"
                value-key="number"
                clearable
                @blur="onBlurEndRange"
            />
          </template>

        </base-filter-range>

        <job-select
            v-else
            v-model="model.jobs"
            :add-entity="false"
            :collapse-tags="true"
            :placeholder="$t('Select Jobs')"
            :hide-label="true"
            class="col-span-4"
            multiple
        />

        <job-category-select
            v-if="selectJobList"
            v-model="model.categories"
            :add-entity="false"
            :hide-label="true"
            :placeholder="$t('Select Job Category')"
            class="col-span-2"
            collapse-tags
            multiple
        />

        <base-select
          v-model="model.status"
          :add-entity="false"
          :options="jobStatusOptions"
          :hide-label="true"
          :placeholder="$t('Select Status')"
          class="col-span-2"
          collapse-tags
          multiple
          @change="onJobStatusChange"
        />

      </base-filter-row>
    </template>

    <template #table>

      <SummaryEntries
          :url-params="urlParams"
          :filters="model"
      />

    </template>

  </base-report-form>
</template>
<script>
  import { JobStatus } from '@/modules/job-costing/enum/jobs'
  import { jobStatusOptions } from '@/modules/job-costing/enum/jobs'
  import SummaryEntries from '@/modules/job-costing/components/review-job/SummaryEntries'

  export default {
    components: {
      SummaryEntries,
    },
    data() {
      return {
        selectJobList: false,
        jobStatusOptions,
        urlParams: {
          status: [JobStatus.Open],
          customer_id: null,
          from: null,
          to: null,
          jobs: [],
          categories: [],
          view_selections: ['change-orders-only', 'base-contract-only'],
        },
        viewSelectionTypes: [
          {
            label: this.$t('Change Orders Only'),
            value: 'change-orders-only',
          },
          {
            label: this.$t('Base Contract Only'),
            value: 'base-contract-only',
          },
        ],
        loading: false,
        model: {
          status: [JobStatus.Open],
          customer_id: null,
          from: null,
          to: null,
          jobs: [],
          categories: [],
          view_selections: ['change-orders-only', 'base-contract-only'],
        },
      }
    },
    computed: {
      jobUrlParams() {
        const statusToString = this.model.status.join(',')
        return {
          status: statusToString,
          customer_id: this.model.customer_id,
        }
      },
    },
    methods: {
      onSubmit() {
        this.urlParams = {
          ...this.model,
          sort: 'number',
        }
      },
      resetJobFilters() {
        this.model.jobs = []
        this.model.from = this.model.to = null
      },
      onBlurEndRange() {
        this.model.to = this.model.to || this.model.from
      },
      onJobStatusChange() {
        this.resetJobFilters()
      },
    },
  }
</script>
