const businessLinkMap = {
  vendor: 'VendorLink',
  customer: 'CustomerLink',
  employee: 'EmployeeLink',
  default: '',
}

// * Mainly For reports
export function getRelatedBusinessLink(row) {
  const type = row?.business?.type
  return businessLinkMap[type]
}


export function getJournalPath(journalId, journalName) {
  const journal = journalName || ''
  const journalPrefixMap = {
    'PR': '/payroll/journals',
    'JC': '/job-costing/journals',
    'SB': '/service-billing/journals',
    'GL': '/ledger/journal',
    'AR': '/accounts-receivable/journals',
    'CR': '/accounts-receivable/journals',
    'AP': '/accounts-payable/journals',
    'CD': '/accounts-payable/journals',
    default: '/ledger/journal'
  }
  const prefix = journal.substring(0, 2)
  const path = journalPrefixMap[prefix] || journalPrefixMap.default
  return `${path}/${journalId}/view`
}
