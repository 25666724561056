import ObjectValues = API.ObjectValues;

export enum ReportShadingPattern {
  None = 'none',
  Solid = 'solid',
  Alternate = 'alternate',
}

export enum ReportSectionTypePattern {
  Title = 'title',
  Group = 'group',
  Detail = 'detail',
  Totals = 'totals',
}

type ReportShadingPatternType = ObjectValues<typeof ReportSectionTypePattern>;

export type ReportShading = {
  pattern: ReportShadingPatternType;
  color1: string;
  color2: string;
}

export type ReportShadingType = {
  [ReportSectionTypePattern.Title]: ReportShading;
  [ReportSectionTypePattern.Group]: ReportShading;
  [ReportSectionTypePattern.Detail]: ReportShading;
  [ReportSectionTypePattern.Totals]: ReportShading;
}

export const ReportShadingDefaults: ReportShading = {
  // @ts-ignore
  pattern: ReportShadingPattern.Solid,
  color1: '#FFFFFF',
  color2: '#FFFFFF',
}

export const defaultColors = {
  [ReportSectionTypePattern.Title]: '#B0C4DE',
  [ReportSectionTypePattern.Group]: '#C6D5D9',
  [ReportSectionTypePattern.Detail]: '#E1EBEE',
  [ReportSectionTypePattern.Totals]: '#F4F6CC',
  default: '#FFFFFF',
}

export const ReportShadingTypesDefaults: ReportShadingType = {
  title: {
    // @ts-ignore
    pattern: ReportShadingPattern.Solid,
    color1: defaultColors[ReportSectionTypePattern.Title],
    color2: defaultColors.default
  },
  group: {
    // @ts-ignore
    pattern: ReportShadingPattern.Solid,
    color1: defaultColors[ReportSectionTypePattern.Group],
    color2: defaultColors.default,
  },
  detail: {
    // @ts-ignore
    pattern: ReportShadingPattern.Alternate,
    color1: defaultColors[ReportSectionTypePattern.Detail],
    color2: defaultColors.default,
  },
  totals: {
    // @ts-ignore
    pattern: ReportShadingPattern.Solid,
    color1: defaultColors[ReportSectionTypePattern.Totals],
    color2: defaultColors.default,
  }
}
