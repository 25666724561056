<template>
  <div class="flex items-center">
    <base-tooltip :content="$t('In Pending Timecards')">
      <div class="flex flex items-center mr-3">
        <BookOpenIcon class="w-5 h-5 mr-1"/>
        <span>{{ batch.counts.pending }}</span>
      </div>
    </base-tooltip>
    <base-tooltip :content="$t('Computed Timecards')">
      <div class="flex flex items-center mr-3">
        <IconServiceInvoices class="w-5 h-5 mr-1"/>
        <span>{{ batch.counts.computed }}</span>
      </div>
    </base-tooltip>
    <base-tooltip :content="$t('Posted Timecards')">
      <div class="flex flex items-center mr-3">
        <SendIcon class="w-5 h-5 mr-1"/>
        <span>{{ batch.counts.posted }}</span>
      </div>
    </base-tooltip>
    <base-tooltip :content="$t('Voided Timecards')">
      <div class="flex flex items-center">
        <DeleteIcon class="w-5 h-5 mr-1"/>
        <span>{{ batch.counts.voided }}</span>
      </div>
    </base-tooltip>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { BookOpenIcon, DeleteIcon, SendIcon } from 'vue-feather-icons'

  export default defineComponent({
    components: {
      BookOpenIcon,
      DeleteIcon,
      SendIcon,
    },
    props: {
      batch: {
        type: Object,
        default: () => ({}),
      },
    },
  })
</script>
