<template>
  <div class="report-page mb-4">
    <BaseReportHeader
        :header="reportHeader"
        class="my-4 leading-tight tracking-wide font-semibold text-gray-700 text-xs border rounded border-gray-200 p-4 bg-gray-50"
    />
    <AgDataTable
        :key="data.id"
        :url="url"
        :url-params="urlParams"
        :columns="columns"
        :pagination="false"
        :show-pagination="false"
        :groupDefaultExpanded="-1"
        :groupIncludeFooter="true"
        :suppressAggFuncInHeader="true"
        :groupIncludeTotalFooter="true"
        :groupRowRendererParams="groupRowRendererParams"
        :compact="true"
        :no-borders="true"
        :perPage="1000"
        groupDisplayType="groupRows"
        dom-layout="autoHeight"
    />
  </div>
</template>
<script>
  import ProofListingGroupRow
    from '@/modules/accounts-payable/pages/settings/accounts-payable-init/ProofListingGroupRow'

  export default {
    components: {
      ProofListingGroupRow,
    },
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      groupRowRendererParams() {
        return {
          innerRenderer: 'ProofListingGroupRow',
        }
      },
      filters() {
        const { period, fiscal_year } = this.data?.attributes || {}
        return `${this.$t('Outstanding Payables thru end of Fiscal Period')} ${period} ${fiscal_year}`
      },
      reportHeader() {
        return {
          title: this.$t('Initialize Open Payables - Proof Listing'),
          company: {
            name: this.$currentCompany?.name,
          },
          code: '39.93',
          filters: [this.filters],
        }
      },
      url() {
        return this.data.id ? '/restify/accounts-payable-init-entries' : ''
      },
      urlParams() {
        const baseParams = {
          sort: 'vendor.code'
        }
        return this.data.id ? {
          accounts_payable_init_id: this.data.id,
          ...baseParams,
        } : baseParams
      },
      columns() {
        return [
          {
            headerName: this.$t('Vendor'),
            field: 'attributes.vendor_id',
            component: 'VendorLink',
            rowGroup: true,
            hide: true,
          },
          {
            headerName: ' ',
            field: 'empty_column',
            minWidth: 30,
            maxWidth: 50,
          },
          {
            headerName: this.$t('Number'),
            field: 'attributes.number',
            minWidth: 120,
          },
          {
            headerName: this.$t('Date'),
            field: 'attributes.date',
            component: 'FormattedDate',
            minWidth: 120,
          },
          {
            headerName: this.$t('Due Date'),
            field: 'attributes.due_date',
            component: 'FormattedDate',
            minWidth: 120,
          },
          {
            headerName: this.$t('Description'),
            field: 'attributes.description',
            minWidth: 150,
            maxWidth: 300,
          },
          {
            headerName: this.$t('Amount'),
            field: 'attributes.gross_amount',
            component: 'FormattedPrice',
            aggFunc: 'sum',
            minWidth: 120,
            cellRendererParams: {
              hideZero: true,
            },
          },
          {
            headerName: this.$t('Discount Pending'),
            field: 'attributes.discount_amount',
            component: 'FormattedPrice',
            aggFunc: 'sum',
            minWidth: 120,
            cellRendererParams: {
              hideZero: true,
            },
          },
        ]
      },
    },
  }
</script>
