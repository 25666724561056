import config from "@/modules/common/config";
export function formatPercent(value, options = {}, fixedValue = false) {
  if (value === null || value === undefined) {
    return ''
  }

  // Because:  percentages are fractions of 100
  if (!fixedValue) value = value / 100;

  const formatOptions = {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: config.MAXIMUM_FRACTION_DIGITS,
    ...options
  }
  const isRound = value * 100 % 1 === 0

  if (isRound) {
    formatOptions.maximumFractionDigits = 0
    formatOptions.minimumFractionDigits = 0
  }

  const formatter = new Intl.NumberFormat('en-US', formatOptions)
  return formatter.format(value)
}

export function getPercentage(value1 = 0, value2 = 0, useIntlFormatter = true) {
  let percent
  if (isNaN(value1)) {
    value1 = 0
  }
  if (isNaN(value2)) {
    value2 = 0
  }

  if (value2 === 0 && value1 > 0) {
    percent = 100
  } else if (value2 === 0 && value1 === 0) {
    percent = 0
  } else {
    percent = value1 / value2
  }
  if (!useIntlFormatter) {
    percent = value2 * 100 / value1
    return percent.toFixed(2)
  }

  return formatPercent(percent, { minimumFractionDigits: 0, maximumFractionDigits: 0 }, true)
}

function getPercentageValue(value, percent) {
  const amount = parseFloat(value * percent / 100)
  return amount.toFixed(2)
}

function formatFloat(value, fractionDigits = 2) {
  return parseFloat(value).toFixed(fractionDigits)
}

export default {
  install(Vue) {
    Vue.prototype.$formatPercent = formatPercent
    Vue.prototype.$getPercent = getPercentage
    Vue.prototype.$getPercentageValue = getPercentageValue
    Vue.prototype.$formatFloat = formatFloat
  }
}
