<template>
  <el-collapse v-model="activeGroup"
               class="bg-white rounded-lg shadow preview-component">
    <el-collapse-item
        key="card"
        name="card"
    >
      <template #title>
        <div class="flex max-w-[90%] items-center justify-between px-5 py-3">
          <div class="text-base text-gray-900 font-semibold leading-snug tracking-wide truncate">
            <slot name="title">
              {{ entity[titleKey] }}
            </slot>
          </div>
          <status-badge
              v-if="entity[statusKey]"
              :status="entity[statusKey]"
          />
        </div>
      </template>
      <div class="w-full flex items-center justify-between">
        <div class="flex-1 truncate">
          <div class="px-5 py-2">
            <div v-for="item in items"
                 :key="item.title"
                 class="grid grid-cols-2 gap-x-5"
            >
              <base-tooltip :content="item.title"
                            :tabindex="-1"
              >
                <div class="col-span-1 text-sm text-gray-400 font-medium leading-loose tracking-wide truncate">
                  {{ item.title }}
                </div>
              </base-tooltip>
              <div class="col-span-1 text-sm text-gray-900 font-semibold leading-loose tracking-wide truncate"
                   :class="{
                    'text-primary-600': item.dateType
                  }"
              >
                <template v-if="item.component">
                  <component v-bind="item"
                             :is="item.component"
                  />
                </template>
                <span v-else-if="item.format === 'price'">
                {{ $formatPrice(item.value) }}
              </span>
                <span v-else>
                {{ item.value || '' }}
              </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="footer"
           class="flex space-x-4 border-t border-gray-100 px-5"
      >
        <div v-if="footer.email"
             class="flex-1 flex">
          <EmailButton :value="entity.email"/>
        </div>
        <div v-if="footer.phone"
             class="flex-1 flex">
          <PhoneButton :value="entity[phoneKey]"/>
        </div>
        <div v-if="footer.address"
             class="flex-1 flex">
          <AddressButton :value="locationLink"/>
        </div>
        <div v-if="footer.link"
             class="flex-1 flex">
          <WebsiteButton :value="entity.website_url"/>
        </div>
      </div>
    </el-collapse-item>
  </el-collapse>
</template>
<script>
  import { ExternalLinkIcon } from 'vue-feather-icons'
  import EmailButton from "@/modules/common/components/buttons/EmailButton.vue";
  import PhoneButton from "@/modules/common/components/buttons/PhoneButton.vue";
  import AddressButton from "@/modules/common/components/buttons/AddressButton.vue";
  import WebsiteButton from "@/modules/common/components/buttons/WebsiteButton.vue";

  export default {
    inheritAttrs: false,
    components: {
      WebsiteButton,
      AddressButton,
      PhoneButton,
      EmailButton,
      ExternalLinkIcon
    },
    props: {
      entity: {
        type: Object,
        default: () => ({}),
      },
      titleKey: {
        type: String,
        default: 'name',
      },
      statusKey: {
        type: String,
        default: 'status',
      },
      phoneKey: {
        type: String,
        default: 'phone',
      },
      items: Array,
      footer: {
        type: Object,
        default: () => ({
          email: true,
          phone: true,
          address: true,
        }),
      },
      expanded: {
        type: Boolean,
        default: true
      },
      locationLink: String,
    },
    data() {
      return {
        activeGroup: this.expanded ? ['card'] : []
      }
    }
  }
</script>
<style lang="scss">
  .preview-component {
    height: fit-content;
    @apply overflow-hidden;
  }
  .preview-component .el-collapse-item__content {
    @apply mb-0 pb-0;
  }
</style>
