<template>
  <div>
    <component
      v-if="component && data"
      :id="data.id"
      :is="component"
      :data="data"
    />
    <div v-else>
      <InvoiceLink
        v-if="row?.meta?.invoice_id"
        :data="{
            id: row?.meta?.invoice_id,
            number: row?.reference_no,
          }"
      />
      <div v-else>
        {{ row.reference_no || data?.number }}
      </div>
    </div>
  </div>
</template>
<script>

import InvoiceLink from "@/components/links/InvoiceLink.vue";
import MaterialLink from "@/components/links/MaterialLink.vue";
import EquipmentLink from "@/components/links/EquipmentLink.vue";

export default {
  name: 'ReportDocumentLink',
  components: {
    InvoiceLink,
    MaterialLink,
    EquipmentLink
  },
  props: {
    id: String,
    data: {
      type: Object,
      default: () => ({}),
    },
    row: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    component() {
      const map = {
        invoice: InvoiceLink,
        material: MaterialLink,
        equipment: EquipmentLink,
      }
      const type = this.data?.type
      return map[type]
    }
  }
}
</script>
