<template>
  <div class="py-3 sm:py-4 flex items-center justify-center sm:px-6"
       :class="{
          'bg-gray-50': stripe
      }"
  >
    <dd class="no-data-message">
      <slot></slot>
    </dd>
  </div>
</template>
<script>
  export default {
    props: {
      title: String,
      stripe: Boolean,
      justifyEnd: Boolean,
    }
  }
</script>
<style>
</style>
