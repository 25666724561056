<template>
    <base-form :loading="loading"
               :showCancel="showCancel"
               :showBack="showBack"
               layout="modal"
               @cancel="$emit('cancel')"
               @submit="onSubmit"
    >
        <div class="col-span-6">
            <base-input v-model="model.code"
                        :label="$t('Code')"
                        :placeholder="$t('Code')"
                        id="code"
                        :disabled="!!model.id"
                        rules="required|code"
            />
        </div>
        <div class="col-span-6">
            <base-input v-model="model.description"
                        :label="$t('Description')"
                        :placeholder="$t('Description')"
                        id="description"
                        rules="max:150"
            />
        </div>
    </base-form>
</template>
<script>
  import axios from 'axios'

  export default {
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      showBack: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        loading: false,
        showCancel: true,
        model: {
          code: '',
          description: '',
        },
      }
    },
    watch: {
      data: {
        immediate: true,
        handler(value) {
          this.model = {
            ...this.model,
            ...value,
          }
        },
      },
    },
    methods: {
      async onSubmit() {
        try {
          this.loading = true
          if (this.model.id) {
            await axios.put(`/restify/equipment-specialties/${this.model.id}`, this.model)
            this.$success(this.$t('Equipment specialty code updated'))
          } else {
            await axios.post('/restify/equipment-specialties', this.model)
            this.$success(this.$t('Equipment specialty code added'))
          }
          this.$emit('save', this.model)
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not update the equipment specialty code'))
        } finally {
          this.loading = false
        }
      },
    },
  }
</script>
