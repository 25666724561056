<template>
  <div class="h-[calc(100%-50px)]">
    <layout-tabs
        v-if="!onViewResourcePage"
        :items="filteredItems"
    />
    <router-view/>
  </div>
</template>
<script>

  export default {
    data() {
      return {
        items: [
          {
            name: this.$t('Open'),
            tooltip: this.$t('Open Work Orders'),
            path: `/service-billing/work-orders/open`,
            permission: 'work_orders_show',
          },
          {
            name: this.$t('Billed'),
            tooltip: this.$t('Billed Work Orders'),
            path: `/service-billing/work-orders/billed`,
            permission: 'work_orders_show',
          },
          {
            name: this.$t('Unbilled'),
            tooltip: this.$t('Unbilled Work Orders'),
            path: `/service-billing/work-orders/unbilled`,
            permission: 'work_orders_show',
          },
          {
            name: this.$t('Closed'),
            tooltip: this.$t('Work orders that have been closed'),
            path: `/service-billing/work-orders/closed`,
            permission: 'work_orders_show',
          },
          {
            name: this.$t('Recurring'),
            tooltip: this.$t('Work orders that were setup as recurring'),
            path: `/service-billing/work-orders/recurring`,
            permission: 'work_orders_show',
          },
          {
            name: this.$t('Completed'),
            tooltip: this.$t('Work orders that have been completed'),
            path: `/service-billing/work-orders/completed`,
            permission: 'work_orders_show',
          },
        ],
      }
    },
    computed: {
      filteredItems() {
        return this.items.filter(item => this.$can(item.permission))
      },
    },
  }
</script>
