<template>
  <div class="flex-1 flex flex-col">
    <layout-tabs :items="filteredItems"/>
    <router-view/>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        items: [
          {
            name: this.$t('State'),
            path: '/payroll/taxes/state',
            permission: 'payroll_settings',
          },
        ],
      }
    },
    computed: {
      filteredItems() {
        const permissions = this.$store.getters['auth/permissions']
        return this.items.filter(item => permissions.includes(item.permission))
      },
    },
  }
</script>
