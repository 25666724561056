<template>
  <div class="flex items-center">
    <base-tooltip :content="$t('Normal Checks')">
      <div class="flex flex items-center mr-3">
        <FileTextIcon class="w-5 h-5 mr-1"/>
        <span>{{ batch.counts?.normal }}</span>
      </div>
    </base-tooltip>
    <base-tooltip :content="$t('Manual Checks')">
      <div class="flex flex items-center mr-3">
        <PenToolIcon class="w-5 h-5 mr-1"></PenToolIcon>
        <span>{{ batch.counts?.manual }}</span>
      </div>
    </base-tooltip>
    <base-tooltip :content="$t('Payoff Checks')">
      <div class="flex flex items-center">
        <ArrowRightCircleIcon class="w-5 h-5 mr-1"></ArrowRightCircleIcon>
        <span>{{ batch.counts?.payoff }}</span>
      </div>
    </base-tooltip>
  </div>
</template>
<script lang="ts">
  import { defineComponent, PropType } from 'vue'
  import { ArrowRightCircleIcon, FileTextIcon, PenToolIcon } from 'vue-feather-icons'
  import { TimeCardBatch } from "@/modules/payroll/components/timecard/timecardTypes";


  export default defineComponent({
    components: {
      FileTextIcon,
      PenToolIcon,
      ArrowRightCircleIcon,
    },
    props: {
      batch: {
        type: Object as PropType<TimeCardBatch>,
        default: () => ({}),
      },
    },
  })
</script>
