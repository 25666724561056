<template>
  <div>
    <base-grid-select
        v-focus
        v-if="isNotPayrollCodes"
        v-model="selectedValue"
        :resource-name="resourceName"
        :unauthorized-to-add="unAuthorizedToAdd"
        @change="onChange"
        @keydown.enter.native="goToNextCell"
    >

    </base-grid-select>
    <BaseSelect
        v-else
        v-focus
        v-model="selectedValue"
        :options="getOptions"
        :hide-label="true"
        :table-columns="tableColumns"
        :clearable="true"
        :resource-name="resourceName"
        class="flex-1"
        value-key="id"
        inline-errors
        @change="onChange"
        @keydown.enter.native="goToNextCell"
    />

  </div>
</template>
<script>
  import { selectEditorMixin } from "@/components/ag-grid/cellEditors/selectEditorMixin";
  import { codeTypes } from "@/modules/payroll/components/rates/util";
  import { BenefitExceptions, PayCodeExceptions } from "@/modules/payroll/utils/benefitCodes";

  export default {
    name: 'CostCenterSelectCellEditor',
    mixins: [selectEditorMixin],
    data() {
      return {
        codeTypes,
      }
    },
    computed: {
      commonColumns() {
        return [
          {
            minWidth: 100,
            maxWidth: 150,
            name: this.$t('Code'),
            prop: 'code',
          },
          {
            minWidth: 150,
            maxWidth: 150,
            name: this.$t('Description'),
            prop: 'description',
          },
          {
            minWidth: 100,
            maxWidth: 150,
            name: this.$t('Calculate By'),
            prop: 'calculate_by',
          },
        ]
      },
      timeOffColumns() {
        return [
          {
            minWidth: 100,
            maxWidth: 150,
            name: this.$t('Code'),
            prop: 'code',
          },
          {
            minWidth: 100,
            maxWidth: 150,
            name: this.$t('State'),
            prop: 'state',
          },
          {
            minWidth: 80,
            maxWidth: 150,
            name: this.$t('Used'),
            prop: 'current_year_used_hours',
          },
          {
            minWidth: 120,
            maxWidth: 150,
            name: this.$t('Available'),
            prop: 'balance',
          }
        ]
      },
      specialSourceType() {
        return this.params?.data?.special_source_type
      },
      tableColumns() {
        return this.specialSourceType === codeTypes.TIME_OFF
            ? this.timeOffColumns
            : this.commonColumns
      },
      getOptions() {
        const rowCodeType = this.specialSourceType
        if (!rowCodeType) {
          return []
        }

        const globalLists = this.$store.state.globalLists
        let benefits = globalLists.withPayUnits.benefits || []
        let payCodes = globalLists.withPayUnits.pays || []
        benefits = benefits.filter(code => code.available_in_timecards && !BenefitExceptions.includes(code.code))
        payCodes = payCodes.filter(code => code.available_in_timecards && !PayCodeExceptions.includes(code.code))

        const mapping = {
          [codeTypes.PAY]: payCodes,
          [codeTypes.BEN]: benefits,
          [codeTypes.DEDUCTION]: globalLists.withPayUnits.deductions,
          [codeTypes.TAX]: globalLists.withPayUnits.taxes,
          [codeTypes.FLEX_BEN]: globalLists[this.$globalResources.FlexibleBenefitPlans],
          [codeTypes.RETIREMENT_PLAN]: globalLists[this.$globalResources.RetirementPlans],
          [codeTypes.TIME_OFF]: globalLists[this.$globalResources.TimeOffPolicies],
          [codeTypes.MATERIAL]: globalLists[this.$globalResources.Materials],
          [codeTypes.EQUIPMENT]: globalLists[this.$globalResources.Equipments],
          [codeTypes.VENDOR]: globalLists[this.$globalResources.Vendors],
          [codeTypes.LABOR]: globalLists[this.$globalResources.CraftCodes],
          [codeTypes.CRAFT_CODE]: globalLists[this.$globalResources.CraftCodes],
        }
        let data = mapping[rowCodeType] || []
        data = data.map(row => {
          row.label = row.description || row.name || row.code
          return row
        })
        if (rowCodeType === codeTypes.TIME_OFF) {
          data = this.mergeTimeOffPoliciesWithStatuses(data)
        }
        return data.filter(row => {
          if (row?.code === '*') {
            return false
          }
          return row.active !== undefined ? row.active : true
        })
      },
      timeOffStatuses() {
        return this.$store.state.globalLists[this.$globalResources.TimeOffStatuses]
      },
      resourceName() {
        const resourceMap = {
          [codeTypes.PAY]: this.$globalResources.PayCodes,
          [codeTypes.BEN]: this.$globalResources.BenefitCodes,
          [codeTypes.DEDUCTION]: this.$globalResources.DeductionCodes,
          [codeTypes.TAX]: this.$globalResources.TaxCodes,
          [codeTypes.FLEX_BEN]: this.$globalResources.FlexibleBenefitPlans,
          [codeTypes.RETIREMENT_PLAN]: this.$globalResources.RetirementPlans,
          [codeTypes.TIME_OFF]: this.$globalResources.TimeOffPolicies,
          [codeTypes.VENDOR]: this.$globalResources.Vendors,
          [codeTypes.EQUIPMENT]: this.$globalResources.Equipments,
          [codeTypes.MATERIAL]: this.$globalResources.Materials,
          [codeTypes.LABOR]: this.$globalResources.CraftCodes,
        }
        return resourceMap[this.specialSourceType]
      },
      isNotPayrollCodes() {
        const types = [codeTypes.EQUIPMENT, codeTypes.MATERIAL, codeTypes.VENDOR, codeTypes.LABOR]
        return types.includes(this.specialSourceType)
      },
      unAuthorizedToAdd() {
        return this.params.unAuthorizedToAdd !== undefined ?? true
      }
    },
    methods: {
      mergeTimeOffPoliciesWithStatuses(timeOffPolicies) {
        const stateCode = this.$store.state.globalLists.stateTaxes.find(t => t.id === this.params?.data.withholding_state_id)?.code
        if (stateCode) {
          timeOffPolicies = timeOffPolicies.filter(policy => policy.state === stateCode)
        }
        return timeOffPolicies.map(policy => {
          const status = this.timeOffStatuses.find(status => status.time_off_policy_id === policy.id) || {}
          return {
            ...status,
            ...policy,
          }
        })
      }
    }
  }
</script>
