<template>
  <base-collapse-form
      :show-buttons="showButtons"
      :expanded.sync="sectionExpanded"
      layout="vertical"
  >
    <template #title>
      <span v-if="!sectionExpanded && selectedVendor.id">
        {{ selectedVendor.name }}
        ({{ selectedVendor.code }})
      </span>
    </template>
    <div class="col-span-6 lg:col-span-2">
      <vendor-selector @change="onChangeVendor"/>
    </div>
    <div class="lg:col-span-1"/>
    <vendor-info v-if="selectedVendor.id"
                :vendor="selectedVendor"/>
  </base-collapse-form>
</template>
<script>

  import VendorInfo from '@/modules/accounts-payable/components/vendor-history/VendorInfo'
  import VendorSelector from '@/modules/accounts-payable/components/vendor-history/VendorSelector'

  export default {
    components: {
      VendorInfo,
      VendorSelector,
    },
    data() {
      return {
        selectedVendor: {},
        model: {
          vendor_id: null,
        },
        showButtons: false,
        sectionExpanded: true,
      }
    },
    methods: {
      onApplyFiler() {
        const filters = { ...this.model }
        this.$emit('on-filter', filters)
      },
      onChangeVendor(vendor) {
        this.selectedVendor = {
          ...vendor?.attributes,
          relationships: {
            ...vendor?.relationships,
          },
        }
        this.model.vendor_id = vendor?.id
        this.onApplyFiler()
      },
    },
  }
</script>
