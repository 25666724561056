<template>
  <div class="truncate">
    <router-link
        v-if="salesTaxDistrictId"
        :to="`/accounts-receivable/settings/sales-tax-districts?id=${salesTaxDistrictId}`"
    >
      <span>
        {{ valueToDisplay }}
      </span>
    </router-link>
    <template v-else>
      --
    </template>
  </div>
</template>
<script>
  export default {
    name: 'SalesTaxDistrictLink',
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      id: {
        type: String,
      },
      showDescription: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      salesTaxDistrictId() {
        return this.id || this.get(this.data, 'attributes.sales_tax_district_id')
      },
      salesTaxDistricts() {
        return this.$store.getters['globalLists/getResourceList'](this.$globalResources.SalesTaxDistricts)
      },
      salesTaxDistrict() {
        if (this.data && this.data?.attributes) {
          return this.data?.attributes || {}
        }
        return this.salesTaxDistricts.find(a => a.id === this.salesTaxDistrictId) || {}
      },
      valueToDisplay() {
        const { state, code, sales_tax_percent, description } = this.salesTaxDistrict

        if (!this.showDescription) {
          return state
        }
        if (sales_tax_percent !== undefined) {
          const percent = this.$formatPercent(sales_tax_percent)
          return `${state}, ${code} (${description}, ${percent})`
        }
        return `${state}, ${code} (${description})`
      },
    },
  }
</script>
