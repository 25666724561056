<template>
  <div
    class="calendar-wrapper"
  >
    <FullCalendar
      v-if="fullcalendarKey"
      :key="fullcalendarKey"
      ref="calendarRef"
      :options="calendarOptions"
      :class="{
        'base-calendar': !isScheduler,
        'base-scheduler': isScheduler,
      }"
    >
      <template #eventContent="{ event }">
        <slot
          name="eventContent"
          :event="event"
        />
      </template>
      <template #resourceLabelContent="{ resource }">
        <slot
          name="resourceLabelContent"
          :resource="resource"
        />
      </template>
    </FullCalendar>
  </div>
</template>
<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import scrollGridPlugin from '@fullcalendar/scrollgrid';
import differenceInDays from "date-fns/differenceInDays";
import addDays from "date-fns/addDays";
import { getWeekDays } from "@/utils/dateUtils";

const LicenseKey = import.meta.env.VITE_FULL_CALENDAR_LICENSE_KEY

export default {
  components: {
    FullCalendar,
  },
  props: {
    isScheduler: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    schedulerOptions: {
      type: Object,
      default: () => ({}),
    },
    entity: {
      type: String,
      default: 'tasks'
    },
    loading: {
      type: Boolean,
      default: false,
    },
    canSelect: {
      type: Boolean,
      default: true,
    },
    canResize: {
      type: Boolean,
      default: true,
    },
    canMove: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      fullcalendarKey: 0,
      calendarApi: null,
    }
  },
  computed: {
    isEventResourceEditable() {
      return true
    },
    startEndDates() {
      const dateFilter = this.$route.query.date
      const emptyFilter = {
        start: undefined,
        end: undefined
      }
      if (!dateFilter) {
        return emptyFilter
      }

      const dates = dateFilter.split(',')
      const start = dates[0]
      const end = dates[1]

      if (!start || !end) {
        return emptyFilter
      }

      return {
        start,
        end
      }
    },
    defaultViewType() {
      if (this.isScheduler) {
        return 'resourceTimelineWeek'
      }

      return 'dayGridMonth'
    },
    viewType() {
      return this.calendarApi?.view?.type
    },
    defaultSlotMinWidth() {
      return 50
    },
    schedulerOptionsComputed() {
      if (!this.isScheduler) {
        return {}
      }

      const minSlotWidth = 50
      const maxSlotWidth = 500
      const zoomStep = 50

      const workingDays = this.$settings('timesheet', 'working_days') || [1, 2, 3, 4, 5]
      const weekends = workingDays.includes(6) && workingDays.includes(7)

      return {
        initialView: this.defaultViewType,
        resourceOrder: 'name',
        slotMinWidth: this.defaultSlotMinWidth,
        headerToolbar: {
          right: 'zoomOut resetZoom zoomIn todayCustom prev,next',
          center: 'title',
          left: 'resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth',
        },
        views: {
          resourceTimelineDay: {
            titleFormat: {
              year: 'numeric',
              month: 'long',
              day: '2-digit',
              weekday: 'long',
            }
          }
        },
        customButtons: {
          zoomOut: {
            text: '-',
            click: () => {
              const current =  this.$route.query?.slotWidth || this.defaultSlotMinWidth
              const newValue = Number(current) - zoomStep

              if (newValue < minSlotWidth) {
                return
              }

              this.$router.replace({
                path: this.$route.path,
                query: {
                  ...this.$route.query,
                  slotWidth: newValue
                }
              })
            }
          },
          resetZoom: {
            text: this.$t('Reset'),
            click: () => {
              this.$router.replace({
                path: this.$route.path,
                query: {
                  ...this.$route.query,
                  slotWidth: this.defaultSlotMinWidth
                }
              })
            }
          },
          zoomIn: {
            text: '+',
            click: () => {
              const current = this.$route.query?.slotWidth || this.defaultSlotMinWidth
              const newValue = Number(current) + zoomStep

              if (newValue > maxSlotWidth) {
                return
              }

              this.$router.replace({
                path: this.$route.path,
                query: {
                  ...this.$route.query,
                  slotWidth: newValue
                }
              })
            }
          },
          todayCustom: {
            text: this.$t('Today'),
            click: this.onTodayClicked
          },
        },
        contentHeight: null,
        expandRows: true,
        selectMirror: true,
        dayMaxEvents: false,
        weekends,
        firstDay: 1,
        ...this.schedulerOptions,
      }
    },
    calendarOptions() {
      const firstDay = 0
      let initialDate = new Date()
      let { end, start } = this.startEndDates

      let initialView = this.defaultViewType
      if (initialView?.includes('resource')) {
        initialView = this.defaultViewType
      }
      try {
        if (start && end) {
          start = new Date(start)
          end = new Date(end)

          if (!this.isScheduler) {
            const difference = differenceInDays(end, start)
            start = addDays(start, difference / 2)
          }

          initialDate = new Date(start)
        }
      } catch (err) {
        console.log('Error parsing the calendar start date')
      }

      const select = this.canSelect ? this.handleDateSelect : undefined
      const eventClick = this.handleEventClick
      const eventDrop = this.canMove ? this.handleEventDrop : undefined
      const eventResize = this.canResize ? this.handleEventResize : undefined
      return {
        height: '100%',
        contentHeight: 'auto',
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
          scrollGridPlugin,
          resourceTimelinePlugin,
        ],
        headerToolbar: {
          left: 'dayGridMonth,timeGridWeek,timeGridDay',
          right: 'today prev,next',
          center: 'title',
        },
        initialDate,
        initialView,
        editable: this.canMove,
        resourceEditable: false,
        scrollTimeReset: false,
        eventResourceEditable: this.isEventResourceEditable,
        selectable: this.canSelect,
        resizable: this.canResize,
        selectMirror: true,
        dayMaxEvents: false,
        weekends: true,
        firstDay: firstDay,
        datesSet: this.handleDateChanges,
        dayCellClassNames: this.dayCellClassNames,
        slotLabelClassNames: this.dayCellClassNames,
        slotLaneClassNames: this.dayCellClassNames,
        select,
        eventClick,
        eventDrop,
        eventResize,
        resourceAreaWidth: '250px',
        slotMinWidth: 190,
        aspectRatio: 2,
        schedulerLicenseKey: LicenseKey,
        ...this.options,
        ...this.schedulerOptionsComputed,
      }
    }
  },
  methods: {
    async triggerCalendarReset() {
      this.fullcalendarKey++

      await this.$nextTick()
      this.calendarApi = this.$refs.calendarRef?.getApi()


      this.calendarApi?.changeView(this.$route.query?.viewType || this.defaultViewType)
      this.calendarApi?.setOption('slotMinWidth', this.$route.query?.slotWidth || this.defaultSlotMinWidth)
    },
    dayCellClassNames(params) {
      if (this.isDisabledDay(params.date)) {
        return ['bg-gray-50']
      }
      return []
    },
    isDisabledDay(date) {
      const workingDays = getWeekDays().map(d => d.value)
      // Sunday is saved as 7 under localisation working days and here returned as 0
      const day = date?.getDay() || 7

      return !workingDays.includes(day)
    },
    handleEventClick(event) {
      this.$emit('event-click', event)
    },
    handleEventDrop(event) {
      this.$emit('event-drop', event)
    },
    handleEventResize(event) {
      this.$emit('event-resize', event)
    },
    async handleDateChanges(event) {
      let dateFormat = 'yyyy-MM-dd'
      let start = this.$formatDate(event.start, dateFormat)
      let end = this.$formatDate(event.end, dateFormat)

      await this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          date: `${start},${end}`
        }
      })
    },
    handleDateSelect(selectInfo) {
      let calendarApi = selectInfo.view.calendar
      calendarApi.unselect() // clear date selection
      let daysDifference = differenceInDays(selectInfo.end, selectInfo.start)

      let end = selectInfo.end
      if (end) {
        end.setDate(end.getDate() - 1)
        end = this.$formatDate(end, 'yyyy-MM-dd')
      }

      let start_date = selectInfo.startStr
      let end_date = daysDifference > 1 && end ? end : undefined

      this.$emit('select', {
        start_date,
        end_date,
        event: selectInfo,
      })
    },
    async onTodayClicked() {
      await this.$nextTick()

      this.calendarApi?.today()
        
      const todayEl = document.querySelector('.fc-day-today')
      
      if (!todayEl) {
        return
      }

      todayEl.scrollIntoView({
        inline: 'start',
      })
    },
  },
  watch: {
    '$route.query.viewType': {
      handler(value) {
        if (!this.isScheduler) {
          return
        }

        this.calendarApi?.changeView(value || this.defaultViewType)
      },
    },
    '$route.query.slotWidth': {
      handler(value) {
        if (!this.isScheduler) {
          return
        }

        this.calendarApi?.setOption('slotMinWidth', value || this.defaultSlotMinWidth)
      },
    },
  },
  mounted() {
    this.triggerCalendarReset()
  },
}
</script>
<style lang="scss">
.calendar-task {
  @apply px-2;
}

.calendar-wrapper {

  .fc-timeline-event {
    @apply rounded;
  }

  .base-scheduler {
    min-height: calc(100vh - 165px);
    @apply mb-0;
  }

  .base-calendar {
    max-height: calc(100vh - 165px);
    @apply mb-0 overflow-y-auto;
  }

  .fc .fc-header-toolbar {
    @apply px-8 bg-gray-100 py-4 mb-0 border-t border-l border-r border-gray-200 rounded-t-md;
  }

  .fc .fc-toolbar {
    @apply flex-wrap gap-2 lg:gap-0;
  }
}

.base-scheduler,
.base-calendar {
  --fc-button-bg-color: theme('colors.primary.500');
  --fc-button-border-color: theme('colors.gray.500');
  --fc-button-hover-bg-color: theme('colors.gray.700');
  --fc-button-hover-border-color: theme('colors.gray.700');
  --fc-button-active-bg-color: theme('colors.primary.500');
  --fc-button-active-border-color: theme('colors.primary.600');

  --fc-border-color: theme('colors.gray.200');
}

</style>
