<template>
  <BaseWrapper v-bind="$attrs"
               :items="items"
               :entity="entity"
               :footer="{}"
               title-key="code"
               status-key="status"
  />
</template>
<script>
  import BaseWrapper from '@/modules/common/components/entity-preview/BaseWrapper'

  export default {
    components: {
      BaseWrapper,
    },
    props: {
      entity: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        items: [
          {
            title: this.$t('Last Invoice Unit Rate'),
            value: this.$formatPrice(this.entity.last_invoice_unit_rate),
          },
          {
            title: this.$t('Last PO Unit Rate'),
            value: this.$formatPrice(this.entity.last_po_unit_rate),
          },
          {
            title: this.$t('Standard Unit Rate'),
            value: this.$formatPrice(this.entity.standard_unit_rate),
          },
          {
            title: this.$t('Unit of Measure'),
            value: this.entity.um,
          },
        ],
      }
    },
  }
</script>
