<template>
  <div>
    <layout-tabs
      v-if="!onViewResourcePage"
      :items="filteredItems"
    />
    <router-view/>
  </div>
</template>
<script>

export default {
  computed: {
    items() {
      return [
        {
          name: this.$t('Pending'),
          tooltip: this.$t('Pending recurring invoices'),
          path: `/accounts-payable/recurring-invoices/pending`,
          permission: 'recurring_invoices_show',
        },
        {
          name: this.$t('Active'),
          tooltip: this.$t('Active recurring payables'),
          path: `/accounts-payable/recurring-invoices/active`,
          permission: 'recurring_invoices_show',
        },
        {
          name: this.$t('Inactive'),
          tooltip: this.$t('Inactive recurring payables'),
          path: `/accounts-payable/recurring-invoices/inactive`,
          permission: 'recurring_invoices_show',
        },
        {
          name: this.$t('All'),
          tooltip: this.$t('Recurring Payables that were not posted yet'),
          path: `/accounts-payable/recurring-invoices/all`,
          permission: 'recurring_invoices_show',
        },
        {
          name: this.$t('Review'),
          tooltip: this.$t('Review recurring payables'),
          path: `/accounts-payable/recurring-invoices/review`,
          permission: 'recurring_invoices_update',
        },
      ]
    },
    filteredItems() {
      return this.items.filter(item => this.$can(item.permission))
    },
  },
}
</script>
