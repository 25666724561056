<template>
    <div class="w-full">
        <div class="col-span-6 md:col-span-3 flex">
            <account-select v-model="numbersFrom"
                            :name="$t('From Account Number')"
                            :label="$t('From Account Number')"
                            :label-format="formatAccountName"
                            value-key="number"
                            class=" w-1/2 mr-2"
                            id="account-number-from"
            />
            <account-select v-model="numbersTo"
                            :name="$t('To Account Number')"
                            :label="$t('To Account Number')"
                            :label-format="formatAccountName"
                            value-key="number"
                            class=" w-1/2"
                            id="account-number-to"
            />
        </div>
        <div class="col-span-6 md:col-span-3">
            <entity-select v-model="accountTypes"
                           :data="accountTypeOptions"
                           :label="$t('Account Type')"
                           label-key="label"
                           value-key="value"
                           :multiple="true"
                           rules="required"
            >
            </entity-select>
        </div>
        <div class="col-span-6">
            <entity-select v-model="periodsToExport"
                           :data="allPeriods"
                           :label="$t('Periods')"
                           :placeholder="$t('Select Periods')"
                           label-key="name"
                           value-key="id"
                           :multiple="true"
                           id="periods"
                           rules="required">
            </entity-select>
        </div>
    </div>
</template>
<script>
  import format from 'date-fns/format'
  import { accountTypes } from '@/enum/account'
  import AccountSelect from '@/modules/ledger/components/AccountSelect'

  const allPeriods = []
  const periodNumbers = []

  for (let i = 1; i <= 12; i++) {
    periodNumbers.push(i)
    allPeriods.push({
      attributes: {
        name: format(new Date().setMonth(i - 1), 'MMMM'),
        id: i,
      },
    })
  }

  export default {
    components: {
      AccountSelect,
    },
    data() {
      return {
        step: 10,
        allPeriods,
        periodNumbers,
        resourceName: 'Account Balances',
        periodsToExport: periodNumbers,
        accountTypes: ['asset', 'liability', 'income', 'expense'],
        numbersFrom: '',
        numbersTo: '',
      }
    },
    computed: {
      exportUrl() {
        return `restify/accounts/balances/export?period=${this.periodsToExport}&fiscal_year=${this.activeFiscalYear}&types=${this.accountTypes}&numbersFrom=${this.numbersFrom}&numbersTo=${this.numbersTo}`
      },
      accountTypeOptions() {
        return accountTypes.map((type) => ({ attributes: { ...type } }))
      },
    },
    methods: {
      formatAccountName(account) {
        const { number, description } = account.attributes
        return `${number} (${description})`
      },
    },
  }
</script>
