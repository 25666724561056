import i18n from "@/i18n";
import { costTypes } from "@/enum/enums";

export const JobStatus = {
  Open: 'open',
  Inactive: 'inactive',
  Closed: 'closed'
}

export const JobTypeFor = {
  Cost: {
    Subcontract: 'SUB',
    Equipment: 'EQP',
    Labor: 'LAB',
    Material: 'MTL',
    Generic: 'GEN',
  },
  Income: {
    Progress: 'PRG',
    LumpSum: 'LMP',
    UnitPrice: 'UPB',
    CostPlus: 'CPB',
    Generic: 'GEN',
  },
}
export const JobTypeForLabels = {
  Cost: {
    [JobTypeFor.Cost.Subcontract]: i18n.t('Subcontract'),
    [JobTypeFor.Cost.Equipment]: i18n.t('Equipment'),
    [JobTypeFor.Cost.Labor]: i18n.t('Labor'),
    [JobTypeFor.Cost.Material]: i18n.t('Material'),
    [JobTypeFor.Cost.Generic]: i18n.t('Generic'),
  },
  Income: {
    [JobTypeFor.Income.Progress]: i18n.t('Progress'),
    [JobTypeFor.Income.LumpSum]: i18n.t('Lump Sum'),
    [JobTypeFor.Income.UnitPrice]: i18n.t('Unit Price'),
    [JobTypeFor.Income.CostPlus]: i18n.t('Cost Plus'),
    [JobTypeFor.Income.Generic]: i18n.t('Generic'),
  }
}

export const JobTypeForOptions = {
  Cost: [
    {
      label: JobTypeForLabels.Cost[JobTypeFor.Cost.Subcontract],
      value: JobTypeFor.Cost.Subcontract,
    },
    {
      label: JobTypeForLabels.Cost[JobTypeFor.Cost.Equipment],
      value: JobTypeFor.Cost.Equipment,
    },
    {
      label: JobTypeForLabels.Cost[JobTypeFor.Cost.Labor],
      value: JobTypeFor.Cost.Labor,
    },
    {
      label: JobTypeForLabels.Cost[JobTypeFor.Cost.Material],
      value: JobTypeFor.Cost.Material,
    },
    {
      label: JobTypeForLabels.Cost[JobTypeFor.Cost.Generic],
      value: JobTypeFor.Cost.Generic,
    }
  ],
  Income: [
    {
      label: JobTypeForLabels.Income[JobTypeFor.Income.Progress],
      value: JobTypeFor.Income.Progress,
    },
    {
      label: JobTypeForLabels.Income[JobTypeFor.Income.LumpSum],
      value: JobTypeFor.Income.LumpSum,
    },
    {
      label: JobTypeForLabels.Income[JobTypeFor.Income.UnitPrice],
      value: JobTypeFor.Income.UnitPrice,
    },
    {
      label: JobTypeForLabels.Income[JobTypeFor.Income.CostPlus],
      value: JobTypeFor.Income.CostPlus,
    },
    {
      label: JobTypeForLabels.Income[JobTypeFor.Income.Generic],
      value: JobTypeFor.Income.Generic,
    }
  ]
}

export const allJobStatuses = Object.values(JobStatus)

export const jobStatusOptions = [
  {
    label: i18n.t('Open'),
    value: JobStatus.Open,
  },
  {
    label: i18n.t('Closed'),
    value: JobStatus.Closed,
  },
  {
    label: i18n.t('Inactive'),
    value: JobStatus.Inactive,
  },
]

export const  rateAdjustmentTypes = {
  NoAdjustment: 'no-adjustment',
  Dollar: 'dollar',
  Percentage: 'percentage',
}

export const certifiedPayrollTypes = {
  WithUnion: 'yes-with-union',
  WithoutUnion: 'yes-w/o-union',
  WithoutUnionCraft: 'yes-w/o-union-and-craft',
  No: 'no',
}


export const costOptions = [
  {
    label: i18n.t('Income'),
    value: costTypes.Income
  },
  {
    label: i18n.t('Cost'),
    value: costTypes.Cost
  }
]

export const linkedLineItemViewTypes = {
  CostVsIncome: 'cost-vs-income',
  IncomeVsCost: 'income-vs-cost',
  Setup: 'setup',
}
