import { costCenterTypes } from "@/components/grid-table/utils/cost-center";
import { globalResources } from "@/components/form/util";
import { getResource } from "@/components/ag-grid/columnUtils";
import { laborHourTypes } from "@/enum/enums";
import i18n from "@/i18n";
import { sourceTypes } from "@/modules/service-billing/util/service-billing";
import store from "@/store";
import { formatPercent } from "@/plugins/formatPercent";

export const specialSourceMap = {
  [sourceTypes.Material]: globalResources.Materials,
  [sourceTypes.Equipment]: globalResources.Equipments,
  [sourceTypes.Labor]: globalResources.CraftCodes,
  default: '',
}

export const sourceMap = {
  [costCenterTypes.Job]: globalResources.Jobs,
  [costCenterTypes.Inventory]: globalResources.Materials,
  [costCenterTypes.Material]: globalResources.Materials,
  [costCenterTypes.Equipment]: globalResources.Equipments,
  [costCenterTypes.Labor]: globalResources.CraftCodes,
  ...specialSourceMap,
}

export const laborTypeOptions = [
  {
    label: i18n.t('Reg Hours'),
    value: laborHourTypes.Regular,
  },
  {
    label: i18n.t('Ovt Hours'),
    value: laborHourTypes.Overtime,
  },
  {
    label: i18n.t('Prm Hours'),
    value: laborHourTypes.Premium,
  },
]

export const specialSourceTypeOptions = [
  {
    label: i18n.t('Material'),
    value: sourceTypes.Material,
  },
  {
    label: i18n.t('Equipment'),
    value: sourceTypes.Equipment,
  },
  {
    label: i18n.t('Labor'),
    value: sourceTypes.Labor,
  },
]

export function getLaborTypeLabel(value) {
  const laborType = laborTypeOptions.find(option => option.value === value)
  return laborType?.label
}

export function getSpecialSourceTypeLabel(value) {
  const specialSource = specialSourceTypeOptions.find(option => option.value === value)
  return specialSource?.label
}

export function getTaxDistrict(districtId) {
  const allDistricts = store.state?.globalLists[globalResources.SalesTaxDistricts] || []
  return allDistricts.find(d => d.id === districtId)
}
export function getTaxDistrictName(districtId) {
  const district = getTaxDistrict(districtId)
  if (!district) {
    return ''
  }
  return formatDistrictLabel(district)
}

export function formatDistrictLabel(district) {
  const { state, code, description, sales_tax_percent } = district || {}
  const defaultLabel =  code ? `${state}, ${code}` : state
  const percent = formatPercent(sales_tax_percent)
  if (!description) {
    return `${defaultLabel} (${percent})`
  }

  return `${defaultLabel} (${description}, ${percent})`
}

export function getSpecialResource(resourceId, special_source_type) {
  const resourceName = sourceMap[special_source_type]
  return getResource(resourceId, resourceName)
}

export function isTypeColEditable(params) {
  return params.data?.cost_center === costCenterTypes.Job
}

export function isAdditionalSourceEditable(params) {
  return params.data?.cost_center === costCenterTypes.Job
}

function tryAssignRegularRate(resource, billing_rate_type_id) {
  let rate = resource?.billing_rates.find(rate => rate.billing_rate_type_id === billing_rate_type_id)
  if (!billing_rate_type_id) {
    rate = resource?.billing_rates?.[0]
  }
  return rate?.regular_rate || 0
}

export function getSpecialSourceRateDetails(params, billing_rate_type_id) {
  params.data.unit_rate = 0
  params.data.uses_billing_rate = false
  let resource = getSpecialResource(params.data.special_source_id, params.data.special_source_type)
  if (!resource.id) {
    resource = getSpecialResource(params.data.source_id, params.data.cost_center)
  }

  if (!params.data.special_source_id) {
    params.data.description = ''
    params.data.um = ''
    params.data.unit_rate = 0
  }
  if (params.data.hasOwnProperty('um')) {
    params.data.um = resource.um
  }

  if (resource.hasOwnProperty('description')) {
    params.data.description = resource.description
  }

  // TODO check if this is correct with Russ

  if (resource.standard_unit_rate) {
    params.data.unit_rate = resource.standard_unit_rate
  }

  if (resource.standard_job_cost_hourly_rate) {
    params.data.unit_rate = resource.standard_job_cost_hourly_rate
  }

  if (resource.hasOwnProperty('billing_rates') && resource?.billing_rates?.length) {
    params.data.unit_rate = tryAssignRegularRate(resource, billing_rate_type_id)
    params.data.uses_billing_rate = true
  }

  return params.data
}
