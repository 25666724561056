<template>
  <BankForm
      :data="bankDetails"
      @refresh="getBank"
  />
</template>
<script>
  import BankForm from '@/modules/settings/components/BankForm'

  export default {
    components: {
      BankForm,
    },
    computed: {
      bank() {
        return this.$store.state.settings.currentBank
      },
      bankDetails() {
        return {
          ...this.bank.attributes,
          ...this.bank.relationships,
        }
      },
    },
      methods: {
        async getBank() {
          await this.$store.dispatch('settings/getBank', this.$route.params.id)
        }
      }
  }
</script>
