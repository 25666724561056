<template>
  <AdjustmentForm/>
</template>
<script>
  import AdjustmentForm from '@/modules/payroll/components/adjustment/AdjustmentForm'

  export default {
    components: {
      AdjustmentForm,
    },
  }
</script>
