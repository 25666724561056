<template>
  <div>
    <BaseButton
      variant="gray-link"
      v-bind="$attrs"
      @click="showDialog = true"
    >
      <DownloadIcon class="w-4 h-4 text-green-500 mr-2"/>
      <span>{{ $t('Export') }}</span>
    </BaseButton>
    <ExportInitsDialog
      v-if="showDialog"
      :open.sync="showDialog"
      :url="url"
      :url-params="urlParams"
      :columns="columns"
      @close="showDialog = false"
    />
  </div>
</template>
<script>
import { DownloadIcon } from "vue-feather-icons";
import ExportInitsDialog from "@/modules/common/components/export/ExportInitsDialog.vue";

export default {
  components: { ExportInitsDialog, DownloadIcon },
  props: {
    columns: {
      type: Array,
      default: () => [],
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    urlParams: {
      type: Object,
    }
  },
  data() {
    return {
      showDialog: false,
    }
  }
}
</script>
