<template>
  <base-select
    v-bind="$attrs"
    v-on="$listeners"
    :value="displayedValue"
    :options="yearOptions"
    :filterable="false"
    :inline-errors="inlineErrors"
    :label="label"
    class="fiscal-year-select"
    @change="onYearSelect"
  >
    <el-option
      v-for="option in yearOptions"
      :key="option.value"
      :value="option.value"
      :label="option.label"
      :disabled="option.disabled"
    >
      <div class="flex justify-between w-full items-center">
        <span>{{ option.value }}</span>
        <span v-if="option.closed">
            <BaseTooltip :content="$t('This fiscal year was closed')">
              <LockIcon class="w-3 h-3 text-gray-500"/>
            </BaseTooltip>
          </span>
      </div>
    </el-option>
  </base-select>
</template>
<script>
  import { Option } from 'element-ui'
  import { LockIcon } from 'vue-feather-icons'

  export default {
    components: {
      LockIcon,
      [Option.name]: Option
    },
    props: {
      saveToSession: {
        type: Boolean,
        default: false,
      },
      value: {
        type: [Number, String],
      },
      showDefaultFiscalYear: {
        type: Boolean,
        default: true,
      },
      fullWidth: Boolean,
      showClosedYears: {
        type: Boolean,
        default: false,
      },
      disableClosedYears: {
        type: Boolean,
        default: false,
      },
      inlineErrors: {
        type: Boolean,
        default: false,
      },
      defaultPreviousYear: {
        type: Boolean,
        default: false,
      },
      label: String,
      showFullLabel: {
        type: Boolean,
        default: true,
      }
    },
    data() {
      return {
        showDropdown: false,
      }
    },
    computed: {
      fiscalYears() {
        return this.$store.state.company.fiscalYears
      },
      years() {
        if (this.showClosedYears) {
          return this.fiscalYears.map(year => year?.attributes?.fiscal_year)
        }
        return this.$store.getters['company/activeFiscalYears']
      },
      yearOptions() {
        return this.years.map(year => ({
          label: this.showFullLabel ? `${this.$t('Fiscal Year')} ${year}` : year,
          value: year,
          closed: this.isYearClosed(year),
          disabled: this.disableClosedYears && this.isYearClosed(year),
        }))
      },
      defaultFiscalYear() {
        if (this.defaultPreviousYear && !this.isPreviousFiscalYearOpen) {
          const previousYear = this.$currentFiscalYear - 1
          this.$warning(this.$t(`Fiscal year ${previousYear} is closed. Defaulting to fiscal year ${this.$currentFiscalYear}`))
        }
        if (this.defaultPreviousYear) {
          return this.$previousFiscalYear
        }
        return this.activeFiscalYear
      },
      displayedValue() {
        let value = this.value
        if (!value && this.showDefaultFiscalYear) {
          value = this.activeFiscalYear
        }
        return value
      },
    },
    methods: {
      isYearClosed(year) {
        const yearObject = this.fiscalYears.find(fiscalYear => fiscalYear?.attributes?.fiscal_year === year)
        return yearObject?.attributes?.closed_at !== null
      },
      onYearSelect(year) {
        this.$emit('input', year)
        this.showDropdown = false
        if (!this.saveToSession) {
          return
        }
        // Save data to sessionStorage
        sessionStorage.setItem('activeFiscalYear', year)
        this.$store.dispatch('company/setSessionActiveFiscalYear', +year)
        this.$store.dispatch('company/setSessionActiveFiscalYear', +year)
        this.updateActiveYearData(year)
      },
      updateActiveYearData(year) {
        const { id } = this.fiscalYears.find(op => op.attributes.fiscal_year === year) || {}
        if (!id) {
          return
        }
        this.$store.dispatch('company/getFiscalYear', id)
      },
      getFiscalYears() {
        this.$store.dispatch('company/getFiscalYears')
      },
      onMenuClickOutside() {
        this.showDropdown = false
      },
      tryGetSessionActiveFiscalYear() {
        let year = sessionStorage.getItem('activeFiscalYear')
        if (!this.value && this.defaultFiscalYear) {
          this.$emit('input', this.defaultFiscalYear)
        }
        if (!year) {
          return
        }
        this.$store.dispatch('company/setSessionActiveFiscalYear', +year)
      },
    },
    mounted() {
      this.tryGetSessionActiveFiscalYear()
      this.getFiscalYears()
    },
  }
</script>
<style scoped lang="scss">
  .fiscal-year-select :deep(.el-input .el-input__inner),
  .fiscal-year-select :deep(.el-input__suffix .el-input__icon::before) {
    @apply leading-5 font-medium text-gray-700;
  }

  .fiscal-year-select :deep(.el-input .el-input__inner) {
    @apply pl-0 pr-4 text-center;
  }
</style>
