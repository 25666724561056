<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <base-form :loading="loading"
               :show-cancel="showCancel"
               :save-text="model.id ? $t(`Update Workers Compensation Rate`) : $t(`Create Workers Compensation Rate`)"
               :can-create-another-entity="!model.id"
               layout="modal"
               @cancel="$emit('cancel')"
               @submit="handleSubmit(onSubmit)"
    >
      <base-input v-model="model.code"
                  :disabled="!!model.id"
                  :label="$t('Code')"
                  :placeholder="$t('Code')"
                  class="col-span-6 md:col-span-2"
                  rules="required|code"
      />
      <base-input v-model="model.description"
                  :label="$t('Description')"
                  :placeholder="$t('Description')"
                  class="col-span-6 md:col-span-4"
                  rules="max:150"
                  id="description"
      />
      <state-select v-model="model.state"
                    :label="$t('State')"
                    :placeholder="$t('Select State')"
                    :disabled="!authorizedToUpdateStartDate"
                    class="col-span-6 md:col-span-2"
                    id="state"
                    clearable
                    @change="onChangeState"
      />
      <base-select v-model="model.rate_limit_type"
                   :label="$t('Code is Valid for Regular Hourly Rate')"
                   :placeholder="$t('Code is Valid for Regular Hourly Rate')"
                   :options="limitRegularRatesOptions"
                   :disabled="!authorizedToUpdateStartDate"
                   class="col-span-6 md:col-span-2"
                   id="rate_limit_type"
      />
      <rate-input v-model="model.rate_limit"
                  :name="$t('Limit on Regular Hourly Rate')"
                  :label="$t('Limit on Regular Hourly Rate')"
                  :disabled="!authorizedToUpdateStartDate"
                  class="col-span-6 md:col-span-2"
                  id="rate_limit"
      />
      <worker-comp-rate-select v-model="model.alternate_code"
                               :label="$t('Alternate Code')"
                               :placeholder="$t('If Regular Hourly Rate is Not Within Above Limit')"
                               :rules="alternateCodeValidationRules"
                               :disabled="!authorizedToUpdateStartDate || !model.rate_limit"
                               :add-entity="true"
                               class="col-span-6 md:col-span-2"
                               id="alternate_code"
                               clearable
      />
      <base-select v-model="model.calculate_by"
                   :label="$t('Premiums Calculate By')"
                   :placeholder="$t('Premiums Calculate By')"
                   :options="calculateByOptions"
                   :disabled="!authorizedToUpdateStartDate"
                   class="col-span-6 md:col-span-2"
                   id="calculate_by"
                   rules="required"
                   @change="onChangeCalculateBy"
      />
      <base-select v-model="model.multiply_by"
                   :label="multiplyByLabel"
                   :placeholder="multiplyByLabel"
                   :options="multiplyByOptions"
                   :disabled="!authorizedToUpdateStartDate"
                   class="col-span-6 md:col-span-2"
                   id="multiply_by"
                   rules="required"
      />
      <rate-input v-model="model.employer_rate"
                  :name="`${$t(`Employer's`)} ${multiplyValueLabel}`"
                  :label="`${$t(`Employer's`)} ${multiplyValueLabel}`"
                  :disabled="!authorizedToUpdateStartDate"
                  :format="inputRateFormat"
                  class="col-span-6 md:col-span-2"
                  id="employer_rate"
      />
      <rate-input v-if="isWashington"
                  v-model="model.employee_deduction_rate"
                  :label="`${$t(`Employee's Deduction`)} ${multiplyValueLabel}`"
                  :name="`${$t(`Employee's Deduction`)} ${multiplyValueLabel}`"
                  :disabled="!authorizedToUpdateStartDate"
                  :format="inputRateFormat"
                  class="col-span-6 md:col-span-2"
                  id="employee_deduction_rate"
      />
      <base-date-picker v-model="model.start_date"
                        :label="$t('Start Date')"
                        :name="$t('Start Date')"
                        :placeholder="$t('Start Date')"
                        :disabled="!authorizedToUpdateStartDate"
                        :picker-options="getSuggestionsForStartDate"
                        class="col-span-6 md:col-span-2"
                        rules="required"
                        id="start_date"
      />
      <template v-if="canCreateACopy"
                #extra-buttons>
        <base-tooltip :content="$t('Make a copy for the selected state and start date.')"
                      :tabindex="-1"
        >
          <base-button :disabled="!isValidToMakeACopy"
                       :loading="loading"
                       variant="success"
                       class="ml-4"
                       @click="handleSubmit(onSubmit(true))"
          >
            {{ $t('Make a Copy') }}
          </base-button>
        </base-tooltip>
      </template>
    </base-form>
  </ValidationObserver>
</template>
<script>
  import axios from 'axios'
  import {
    calculateBy, calculateByOptions,
    composeSuggestionsForStartDate,
    multiplyBy,
    StateBasedOnEmployeeRateAndDeduction,
  } from '@/modules/payroll/components/rates/util'
  import RateInput from "@/components/form/RateInput.vue";

  const MULTIPLY_BY_LABELS = {
    [calculateBy.Percentage]: 'Multiply % By',
    [calculateBy.Rate]: 'Multiply Rate By',
  }

  const MULTIPLY_VALUE_LABELS = {
    [calculateBy.Percentage]: 'Percent',
    [calculateBy.Rate]: 'Rate',
  }

  const MULTIPLY_BY_OPTIONS = {
    [calculateBy.Percentage]: [
      {
        label: 'Total Hourly Pay at Regular Rate plus other Earnings',
        value: multiplyBy.INCLUDE_EXTRA,
      },
      {
        label: 'Total Earnings',
        value: multiplyBy.TOTAL_EARNINGS,
      },
    ],
    [calculateBy.Rate]: [
      {
        label: 'Regular Hours',
        value: multiplyBy.REGULAR_HOURS,
      },
      {
        label: 'Total Hours',
        value: multiplyBy.TOTAL_HOURS,
      },
    ],
  }

  export default {
    props: {
      data: {
        type: Object,
        default: () => ({
          attributes: {},
        }),
      },
      selectedDate: String,
    },
    components: {
      RateInput,
    },
    data() {
      return {
        loading: false,
        showCancel: true,
        calculateByOptions,
        model: {
          code: '',
          description: '',
          calculate_by: calculateBy.Percentage,
          state: '',
          rate_limit_type: 'egt',
          rate_limit: 0,
          alternate_code: '',
          multiply_by: multiplyBy.INCLUDE_EXTRA,
          employer_rate: 0,
          employee_deduction_rate: 0,
          start_date: null,
        },
        limitRegularRatesOptions: [
          {
            label: this.$t('Less Than'),
            value: 'lt',
          },
          {
            label: this.$t('Equal or Greater Than'),
            value: 'egt',
          },
        ],
      }
    },
    computed: {
      canCreateACopy() {
        return this.model.id && this.authorizedToUpdateStartDate
      },
      isValidToMakeACopy() {
        const { start_date, state } = this.data.attributes
        return !!(this.model.state !== state || this.model.start_date !== start_date)
      },
      authorizedToUpdateStartDate() {
        if (!this.model.id) {
          return true
        }
        return this.$isAuthorized('authorizedToUpdateStartDate', this.data)
      },
      multiplyByLabel() {
        return this.$t(MULTIPLY_BY_LABELS[this.model.calculate_by])
      },
      multiplyValueLabel() {
        return this.$t(MULTIPLY_VALUE_LABELS[this.model.calculate_by])
      },
      multiplyByOptions() {
        return MULTIPLY_BY_OPTIONS[this.model.calculate_by]
      },
      inputRateFormat() {
        return this.model.calculate_by === calculateBy.Percentage ? 'percent' : 'price'
      },
      rateFractionDigits() {
        return this.model.calculate_by === calculateBy.Percentage ? 2 : 5
      },
      isWashington() {
        return this.model.state === StateBasedOnEmployeeRateAndDeduction
      },
      alternateCodeValidationRules() {
        return this.model.rate_limit ? 'required' : ''
      },
      getSuggestionsForStartDate() {
        if (!this.data?.attributes?.start_date) {
          return this.selectedDate
        }
        return composeSuggestionsForStartDate(this.data?.attributes?.start_date)
      },
    },
    methods: {
      async onSubmit(makeCopy = false) {
        try {
          this.loading = true
          if (this.model.id && !makeCopy) {
            await axios.put(`/restify/workers-comp-rates/${this.model.id}`, this.model)
            this.$success(this.$t('Worker Compensation Rates updated'))
            this.$emit('save', this.model)
          } else {
            const { data } = await axios.post('/restify/workers-comp-rates', this.model)
            this.$success(this.$t('Worker Compensation Rates created'))
            this.$createAnotherEntity ? this.$emit('create-another') : this.$emit('save', data)
          }
          if (!this.$createAnotherEntity) {
            return this.$emit('save', this.model)
          }
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not update the worker compensation rates'))
        } finally {
          this.loading = false
        }
      },
      onChangeCalculateBy(option) {
        this.model.multiply_by = MULTIPLY_BY_OPTIONS[option]?.[0]?.value
      },

      onChangeState(state) {
        this.model.employee_deduction_rate = 0
        if (this.isWashington) {
          this.model.calculate_by = calculateBy.Rate
          this.model.multiply_by = multiplyBy.TOTAL_HOURS
        } else {
          this.model.calculate_by = calculateBy.Percentage
          this.model.multiply_by = multiplyBy.INCLUDE_EXTRA
        }
        this.onChangeCalculateBy()
      },
    },
    watch: {
      'data.attributes': {
        immediate: true,
        handler(value) {
          if (!value?.id) {
            return this.model.start_date = this.selectedDate
          }

          this.model = {
            ...this.model,
            ...value,
          }
        },
      },
    },
  }
</script>
