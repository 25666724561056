<template>
  <div class="relative create-payments">
    <CustomerPayments ref="customerPayments" />
  </div>
</template>
<script>
  import CustomerPayments from "@/modules/accounts-receivable/pages/payments/CustomerPayments.vue";

  export default {
    name: 'create-customer-payments',
    components: {
      CustomerPayments,
    },
    beforeRouteLeave(to, from, next) {
      this.$refs?.customerPayments?.cleanupData()
      next()
    },
  }
</script>
