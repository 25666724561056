<template>
  <BaseDataTable :columns="columns"
                 :data="formattedData"
                 :has-summary="true"
                 :show-pagination="false"
                 ref="table"
  >
    <template v-slot:name="{row}">
      <span class="capitalize">
        {{ row.name }}
      </span>
    </template>
    <template v-slot:name_summary>
      <span class="text-right font-semibold">
        {{ $t('Totals') }}:
      </span>
    </template>
    <template v-slot:budget_summary>
      <span class="text-right font-semibold">
        {{ $formatPrice(totals.budget) }}
      </span>
    </template>
    <template v-slot:to_date_summary>
      <span class="text-right font-semibold">
        {{ $formatPrice(totals.to_date) }}
      </span>
    </template>
    <template v-slot:remaining_summary>
      <span class="text-right font-semibold">
        {{ $formatPrice(totals.remaining) }}
      </span>
    </template>
  </BaseDataTable>
</template>
<script>

  export default {
    props: {
      summaries: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        totals: {
          budget: 0,
          to_date: 0,
          remaining: 0,
        },
        columns: [
          {
            label: '',
            prop: 'name',
          },
          {
            label: this.$t('Budget'),
            prop: 'budget',

            component: 'FormattedPrice',
            align: 'right',
          },
          {
            label: this.$t('To-Date'),
            prop: 'to_date',
            component: 'FormattedPrice',
            align: 'right',
          },
          {
            label: this.$t('Remaining'),
            prop: 'remaining',
            component: 'FormattedPrice',
            align: 'right',
          },
        ]
      }
    },
    computed: {
      formattedData() {
        return Object.keys(this.summaries).map((key) => {
          this.totals.budget += parseFloat(this.summaries[key]?.budget)
          this.totals.to_date += parseFloat(this.summaries[key]?.to_date)
          this.totals.remaining += parseFloat(this.summaries[key]?.remaining)
          return {
            name: key.replace('_', ' '),
            ...this.summaries[key],
          }
        })
      }
    }
  }
</script>
