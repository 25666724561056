<template>
  <base-data-table
      v-bind="defaultAttributes"
      :columns="columns"
      :data="getData"
      :total-rows="rowsLength"
      :has-summary="false"
      :data-loading="dataLoading"
      @force-pagination="forcePagination"
  >

    <template #custom-header>
      <slot name="header-tabs"/>
    </template>

    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template #header="{row}">
      <div class="flex">
        <JobLink
            :data="row.header.job"
            :show-preview="false"
            :show-name="true"
            class="w-1/2"
        />
        <CustomerLink
            v-if="get(row, 'header.customer.id')"
            :data="row.header.customer"
            :show-preview="false"
            :show-name="true"
        />
      </div>
    </template>

    <template
        v-if="!dataLoading"
        #thead-infos
    >
      <template v-if="isUnitPriceBilling">
        <th colspan="8"/>
        <th colspan="2" align="center" class="thead-infos">
          <span class="flex w-full justify-center mr-2 border-b-2 border-dashed pb-2">
            {{ $t('Previously Billed') }}
          </span>
        </th>
        <th/>
        <th colspan="2" align="center" class="thead-infos">
          <span class="flex w-full justify-center mr-2 border-b-2 border-dashed pb-2">
            {{ $t('This Period') }}
          </span>
        </th>
        <th/>
      </template>
      <template v-else>
        <th colspan="7"/>
        <th colspan="2" align="center" class="thead-infos">
          <span class="flex w-full justify-center mr-2 border-b-2 border-dashed pb-2">
            {{ $t('This Period') }}
          </span>
        </th>
        <th/>
        <th v-show="isProgressBilling" colspan="2" align="center" class="thead-infos">
          <span class="flex w-full justify-center mr-2 border-b-2 border-dashed pb-2">
            {{ $t('Materials Stored') }}
          </span>
        </th>
        <th/>
        <template v-if="isLumpSumBilling">
          <th/>
          <th/>
        </template>
      </template>
    </template>

    <template #html-row="{htmlData}">
      <tr class="content-row" v-if="typeof htmlData === 'string'">
        <td :colspan="columns.length" class="font-semibold">
          {{ htmlData }}
        </td>
      </tr>

      <tr v-else-if="htmlData.proposals"
          class="content-row"
      >
        <td :colspan="columns.length">
          <OutstandingProposalsSection :data="htmlData"/>
        </td>
      </tr>

      <tr v-else class="empty-line border-b w-full">
        <td :colspan="columns.length"/>
      </tr>
    </template>

    <template #previous="{row}">
      {{ isProgressBilling ? $formatPrice(row.previous) : '' }}
    </template>

    <template #material_stored_amount="{row}">
      {{ isProgressBilling ? $formatPrice(row.material_stored_amount) : '' }}
    </template>

    <template #contract_quantity="{row}">
      {{ `${row.quantity}  ${row.um}` }}
    </template>

    <template #subtotal="{subtotal}">
      <td colspan="4" align="right">
        {{ $t(subtotal.name) }}
      </td>
      <td v-if="isUnitPriceBilling" colspan="2"/>
      <td align="right">
        <span class="pt-4"
              :class="{'top-border': !subtotal.job}"
        >
          {{ $formatPrice(subtotal.amount) }}
        </span>
      </td>
      <td align="right" :colspan="isUnitPriceBilling ? 3 : 1">
        <span class="pt-4"
              :class="{'top-border': !subtotal.job}"
        >
        {{ $formatPrice(subtotal.amount_to_date) }}
        </span>
      </td>
      <td/>
      <template v-if="!isUnitPriceBilling">
        <td>
          {{ $formatPercent(subtotal.percent_complete) }}
        </td>
        <td colspan="2"/>
      </template>
      <td>
        <span class="pt-4"
              :class="{'top-border': !subtotal.job}"
        >
        {{ isProgressBilling ? $formatPrice(subtotal.previous || 0) : '' }}
        </span>
      </td>
      <td/>
      <td align="right">
        <span class="pt-4"
              :class="{'top-border': !subtotal.job}"
        >
        {{ $formatPrice(subtotal.retention_amount) }}
        </span>
      </td>
    </template>

  </base-data-table>
</template>
<script>
  import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'
  import { billingTypeAbbr } from '@/modules/accounts-receivable/pages/billings/billings'
  import OutstandingProposalsSection
    from '@/modules/accounts-receivable/components/reports/job-pre-billing-worksheet/OutstandingProposalsSection'

  export default {
    extends: ReportTableWrapper,
    components: {
      OutstandingProposalsSection,
    },
    props: {
      billingType: {
        type: String,
        default: billingTypeAbbr.Progress,
      },
      dataLoading: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      columns() {

        const baseColumns = [
          {
            label: this.$t('Phase <br> Code'),
            prop: 'phase_code',
            minWidth: 60,
            maxWidth: 60,
            component: 'EntityLink',
            redirectTo: '/job-costing/income-line-items/{ID}/view',
          },
          {
            label: this.$t('Cost <br> Code'),
            prop: 'cost_code',
            minWidth: 60,
            maxWidth: 60,
            component: 'EntityLink',
            redirectTo: '/job-costing/income-line-items/{ID}/view',
          },
          {
            label: this.$t('Change <br> Order'),
            prop: 'change_order',
            minWidth: 60,
            maxWidth: 60,
            component: 'EntityLink',
            redirectTo: '/job-costing/income-line-items/{ID}/view',
          },
          {
            label: this.$t('Description'),
            prop: 'description',
            minWidth: 140,
            maxWidth: 180,
          },
        ]

        if (this.isUnitPriceBilling) {
          return [
            ...baseColumns,
            {
              label: this.$t('Contract <br> Quantity'),
              prop: 'contract_quantity',
              align: 'right',
              minWidth: 130,
              maxWidth: 160,
              hideZero: true,
            },
            {
              label: this.$t('Unit <br> Price'),
              prop: 'unit_rate',
              align: 'right',
              minWidth: 100,
              maxWidth: 120,
              component: 'FormattedPrice',
              hideZero: true,
            },
            {
              label: this.$t('Total <br> Value'),
              prop: 'amount',
              align: 'right',
              minWidth: 100,
              maxWidth: 120,
              component: 'FormattedPrice',
              hideZero: true,
            },
            {
              label: '',
              prop: 'empty_column',
              minWidth: 50,
              maxWidth: 50,
              hiddenValue: true,
              hideZero: true,
            },
            {
              label: this.$t('Quantity'),
              prop: 'quantity_to_date',
              minWidth: 100,
              maxWidth: 120,
              align: 'right',
              hideZero: true,
            },
            {
              label: this.$t('Amount'),
              prop: 'amount_to_date',
              align: 'right',
              minWidth: 100,
              maxWidth: 120,
              component: 'FormattedPrice',
              hideZero: true,
            },
            {
              label: '',
              prop: 'empty_column_',
              minWidth: 50,
              maxWidth: 50,
              hiddenValue: true,
              hideZero: true,
            },
            {
              label: this.$t('Quantity'),
              prop: 'quantity__',
              minWidth: 100,
              maxWidth: 120,
              hiddenValue: true,
              hideZero: true,
            },
            {
              label: this.$t('Amount'),
              prop: 'amount___',
              align: 'right',
              minWidth: 100,
              maxWidth: 120,
              component: 'FormattedPrice',
              hideZero: true,
            },
            {
              label: this.$t('Retention <br> To-Date'),
              prop: 'retention_amount',
              align: 'right',
              minWidth: 100,
              maxWidth: 120,
              component: 'FormattedPrice',
              hideZero: true,
            },
          ]
        }

        return [
          ...baseColumns,
          {
            label: this.$t('Scheduled <br> Value'),
            prop: 'amount',
            align: 'right',
            minWidth: 100,
            maxWidth: 120,
            component: 'FormattedPrice',
            hideZero: true,
          },
          {
            label: this.$t('Previously <br> Billed'),
            prop: 'amount_to_date',
            align: 'right',
            minWidth: 100,
            maxWidth: 120,
            component: 'FormattedPrice',
            hideZero: true,
          },
          {
            label: '',
            prop: 'empty_column',
            minWidth: 50,
            maxWidth: 50,
            hiddenValue: true,
            hideZero: true,
          },
          {
            label: this.$t('Completed'),
            prop: 'percent_complete',
            minWidth: 100,
            maxWidth: 120,
            component: 'FormattedPercent',
            hideZero: true,
          },
          {
            label: this.$t('Amount'),
            prop: 'amount_',
            align: 'right',
            minWidth: 100,
            maxWidth: 120,
            component: 'FormattedPrice',
            hideZero: true,
          },
          {
            label: '',
            prop: 'empty_column_',
            minWidth: 50,
            maxWidth: 50,
            hiddenValue: true,
            hideZero: true,
          },
          {
            label: this.isProgressBilling ? this.$t('Previous') : '',
            prop: 'previous',
            minWidth: 100,
            maxWidth: 120,
            hiddenValue: true,
            hideZero: true,
          },
          {
            label: this.isProgressBilling ? this.$t('Current') : '',
            prop: 'material_stored_amount',
            align: 'right',
            minWidth: 100,
            maxWidth: 120,
            hiddenValue: true,
            hideZero: true,
          },
          {
            label: this.$t('Retention <br> To-Date'),
            prop: 'retention_amount',
            align: 'right',
            minWidth: 100,
            maxWidth: 120,
            component: 'FormattedPrice',
            hideZero: true,
          },
        ]
      },
      isProgressBilling() {
        return this.billingType === billingTypeAbbr.Progress
      },
      isUnitPriceBilling() {
        return this.billingType === billingTypeAbbr.UnitPrice
      },
      isLumpSumBilling() {
        return this.billingType === billingTypeAbbr.LumpSum
      },
    },
    methods: {
      composeRows(data) {
        data.forEach(group => {

          const { job, customer, base_contract, change_orders, proposals, totals } = group

          this.rows.push({
            header: {
              job,
              customer,
            },
          })

          if (base_contract.items.length) {
            const items = base_contract.items.map(item => {
              return {
                ...item?.line_item,
                ...item?.totals,
              }
            })
            this.rows.push(
                {
                  htmlData: this.$t('Base Contract:'),
                },
                ...items,
                {
                  subtotal: {
                    name: 'Total Base Contract:',
                    ...base_contract.totals,
                  },
                },
            )
          }

          if (change_orders.items.length) {
            const items = change_orders.items.map(item => {
              return {
                ...item?.line_item,
                ...item?.totals,
              }
            })
            this.rows.push(
                {
                  htmlData: this.$t('Change Orders:'),
                },
                ...items,
                {
                  subtotal: {
                    name: 'Total Change Orders:',
                    ...change_orders.totals,
                  },
                },

            )
          }
          const costToDate = this.$formatPrice(job.cost_to_date)
          this.rows.push(
              {
                subtotal: {
                  job: true,
                  name: `Total Job:  (cost to-date = ${costToDate})`,
                  ...totals,
                },
                htmlData: job,
              },
          )

          if (proposals.items.length) {

            const proposalRows = []

            proposals.items?.forEach(proposalObject => {
              proposalObject.entries?.forEach(item => {
                proposalRows.push({
                  ...item,
                  proposal: proposalObject.proposal,
                })
              })
            })

            this.rows.push(
                {
                  htmlData: {
                    proposals: true,
                    items: proposalRows,
                    totals: proposals.totals,
                  },
                },
            )
          }
        })

        return this.rows
      },
    },
  }
</script>
