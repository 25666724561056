<template>
  <entity-select
      url="/restify/gen-liability-rates"
      v-bind="$attrs"
      v-on="$listeners"
      :url-params="urlParams"
      :name="$attrs.name || $t('Gen Liability Rate')"
      :placeholder="$attrs.placeholder || $t('Select Gen Liability Rate')"
      :label="$attrs.label !== undefined ? $attrs.label : $t('Gen Liability Rate')"
      :add-label="$t('Add new Gen Liability Rate')"
      :id="$attrs.id || 'gen-liability-select'"
      :table-columns="tableColumns"
      :label-format="formatLabel"
      :hide-label="hideLabel"
      :value-key="valueKey"
      :add-entity="addEntity"
      label-key="code"
      class="w-full"
  />
</template>
<script>
  export default {
    name: 'GenLiabilityRateSelect',
    inheritAttrs: false,
    props: {
      valueKey: {
        type: String,
        default: 'code',
      },
      urlParams: {
        type: Object,
        default: () => ({
          sort: 'code',
          active: true,
        }),
      },
      hideLabel: {
        type: Boolean,
        default: false,
      },
      addEntity: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        tableColumns: [
          {
            minWidth: 100,
            maxWidth: 150,
            name: this.$t('Code'),
            prop: 'attributes.code',
          },
          {
            minWidth: 200,
            maxWidth: 200,
            name: this.$t('Description'),
            prop: 'attributes.description',
          },
        ],
      }
    },
    methods: {
      formatLabel(rate) {
        const { code, description } = rate.attributes
        if (!description) {
          return code
        }
        return `${code} (${description})`
      },
    },
  }
</script>
