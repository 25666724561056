<template>
  <div>
    <BaseDataTable
        ref="table"
        :columns="columns"
        :data="entryTotals"
        :can-manage-columns="false"
        :show-actions-column="false"
        :is-expandable="true"
        :limit-max-height="false"
        :data-loading="loading"
        :expand-all-rows="toggleTableExpand"
        :show-pagination="false"
        :row-classes="rowClasses"
        actions="print"
    >
      <template v-slot:additional-actions-before>
        <div class="mr-2 space-x-2 flex items-center">
          <base-switch v-model="toggleTableExpand"
                       :label-info="$t('Toggle row details')"
                       inline
          />
        </div>
      </template>
      <template #attributes.source_id="{row}">
        <SourceLink :id="row.attributes.source_id" :data="row"/>
      </template>
      <template #expand-content="{row}">
        <div class="p-4">
          <TimecardEntriesTable :data="get(row, 'originalRow.rows', [])"/>
        </div>
      </template>
    </BaseDataTable>
  </div>
</template>
<script>

  import SourceLink from "@/components/links/SourceLink";
  import axios from "axios";
  import groupBy from "lodash/groupBy";
  import sumBy from "lodash/sumBy";
  import TimecardEntriesTable from "@/modules/payroll/pages/timecard/TimecardEntriesTable";

  export default {
    components: {
      SourceLink,
      TimecardEntriesTable,
    },
    data() {
      return {
        loading: false,
        toggleTableExpand: false,
        entries: [],
      }
    },
    computed: {
      currentResource() {
        return this.$store.state.payroll.currentTimecardBatch
      },
      columns() {
        return [
          {
            label: this.$t('Cost \nCenter'),
            prop: 'attributes.cost_center',
            align: 'center',
            maxWidth: '70px',
          },
          {
            label: this.$t('Source'),
            prop: 'attributes.source_id',
            maxWidth: '160px',
          },
          {
            label: this.$t('Regular Hours'),
            prop: 'attributes.regular_hours',
            align: 'center',
            maxWidth: '60px',
          },
          {
            label: this.$t('Overtime Hours'),
            prop: 'attributes.overtime_hours',
            align: 'center',
            minWidth: '30px',
            maxWidth: '60px',
          },
          {
            label: this.$t('Premium Hours'),
            prop: 'attributes.premium_hours',
            align: 'center',
            minWidth: '30px',
            maxWidth: '60px',
          },
        ]
      },
      urlParams() {
        return {
          timecard_batch_id: this.$route.params.id,
        }
      },
      getAddUrl() {
        const id = this.$route.params.id
        return id ? `/payroll/timecard-batches/${id}/add` : `/payroll/timecards/add`
      },
      entryTotals() {
        const groups = groupBy(this.entries, 'attributes.source_id')
        return Object.keys(groups).map(sourceId => {
          const rows = groups[sourceId]
          return {
            id: sourceId,
            attributes: {
              source_id: sourceId,
              cost_center: rows[0]?.attributes?.cost_center,
              regular_hours: sumBy(rows, 'attributes.regular_hours'),
              overtime_hours: sumBy(rows, 'attributes.overtime_hours'),
              premium_hours: sumBy(rows, 'attributes.premium_hours'),
              units: sumBy(rows, 'attributes.units'),
            },
            rows,
          }
        })
      }
    },
    methods: {
      async getEntries() {
        try {
          this.loading = true
          const entries = await axios.get('/restify/timecard-entries', {
            params: {
              timecard_batch_id: this.$route.params.id,
              perPage: 1000,
            }
          })
          this.entries = entries.data
        } finally {
          this.loading = false
        }
      },
      rowClasses(row) {
        if (!row.expandable && this.toggleTableExpand) {
          return 'bg-gray-100 print:bg-white'
        }
        return ''
      }
    },
    created() {
      this.getEntries()
    }
  }
</script>
