<template>
  <div class="import-data mt-4">
    <ImportForm v-bind="importSettings"
                @success="redirectToList"
    />
  </div>
</template>
<script>
  import ImportForm from '@/modules/common/components/import/ImportForm';
  import { getInitialMapping } from '@/modules/payroll/utils/timecardImportColumns';

  const colMappings = {
    Code: {
      value: 'code',
      label: 'Code',
      alternativeLabels: ['Equipment Code'],
    },
    Description: {
      value: 'description',
      label: 'Description',
      alternativeLabels: [],
    },
    YearModel: {
      value: 'year_and_model',
      label: 'Year And Model',
      alternativeLabels: ['Year&Model', 'Year-Model', 'Year,Model'],
    },
    SerialNumber: {
      value: 'serial_no',
      label: 'Serial Number',
      alternativeLabels: ['Serial No'],
    },
    LicenceNumber: {
      value: 'license_no',
      label: 'License Number',
      alternativeLabels: ['License No'],
    },
    Class: {
      value: 'equipment_class_code',
      label: 'Class',
      alternativeLabels: ['Equipment Class', 'Class Code'],
    },
    AssetType: {
      value: 'asset_type',
      label: 'Asset Type',
      alternativeLabels: ['Asset', 'Type'],
    },
    Disposition: {
      value: 'disposition',
      label: 'Disposition',
      alternativeLabels: [],
    },
    StandardJobCostHourlyRate: {
      value: 'standard_job_cost_hourly_rate',
      label: 'Job Cost Hourly Rate',
      alternativeLabels: [],
    },
  }

  const options = [
    colMappings.Code,
    colMappings.Description,
    colMappings.YearModel,
    colMappings.SerialNumber,
    colMappings.LicenceNumber,
    colMappings.Class,
    colMappings.AssetType,
    colMappings.Disposition,
    colMappings.StandardJobCostHourlyRate,
  ]

  export default {
    components: {
      ImportForm,
    },
    data() {
      return {
        importSettings: {
          url: '/restify/equipment/import',
          columnOptions: options,
          columnMappings: colMappings,
          initialMapping: getInitialMapping(options),
          type: 'equipment-import',
          resource: 'equipment',
        },
      }
    },
    methods: {
      async redirectToList() {
        await this.$router.push('/equipment/list')
      },
    },
  }
</script>
