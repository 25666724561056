import store from '@/store'

export const availableOptions = [
  {
    key: 'Phase Code',
    value: 'Phase Code',
  },
  {
    key: 'Cost Code',
    value: 'Cost Code',
  },
  {
    key: 'Change Order',
    value: 'Change Order',
  },
]

export function fetchTranslations () {
  let data = store.getters['settings/getByKey']('translations')
  try {
    if (typeof data === 'string') {
      data = JSON.parse(data)
    }
  } catch (err) {
    console.warn('Could not parse translations')
  }
  return data?.length ? data : availableOptions
}
