<template>
  <BaseDataTable
      v-bind="defaultAttributes"
      :columns="columns"
      :data="getData"
      :total-rows="rowsLength"
      :class="{'long-format': format === jobProfitReportFormats.Long }"
      :no-borders="false"
      class="job-profit-report"
      @force-pagination="forcePagination"
  >

    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template #header="{row}">
        <JobLink
            :data="row.header"
            :show-preview="false"
        />
    </template>

    <template #job>
      {{ $t('Total Contract:') }}
    </template>

    <template #footer="{row}">
      <div class="flex justify-between font-normal">
        <div class="text-left">
          <dl>
            <dt v-for="note in row.footer?.job?.notes">
              {{ note }}
            </dt>
          </dl>
        </div>
        <div class="flex space-x-4">
          <dl>
            <dt>&nbsp;</dt>
            {{ $t('Earned Gross Profit:') }}
            <dt>&nbsp;</dt>
            <dt>
              {{ $t('Estimated Remaining Cost:') }}
            </dt>
          </dl>
          <dl class="text-right">
            <dt>&nbsp;</dt>
            <dt>{{ $formatPrice(row.footer?.earned_gross_profit) }}</dt>
            <dt>&nbsp;</dt>
            <dt>{{ $formatPrice(row.footer?.est_remainig_cost) }}</dt>
          </dl>
        </div>
      </div>
    </template>

    <template #job_summary="{row}">
      <div class="ml-1">{{ $t('Grand Totals:') }}</div>
      <div class="ml-1 py-2">{{ $t('Base Contr') }}</div>
      <div class="ml-1 pb-1">{{ $t('App Chg Ord') }}</div>
      <div class="ml-1 mt-3">{{ $t('Tot Contract') }}</div>
    </template>

    <template v-for="column in amountColumns" #[getSummarySlotName(column)]>
      <div>&nbsp;</div>
      <div v-if="column.component === 'FormattedPrice'" class="text-right">
        <div class="py-2">
          {{ $formatPrice(grandTotals['base'][column.name]) }}
        </div>
        <div class="border-b pb-1">
          {{ $formatPrice(grandTotals['change_order'][column.name]) }}
        </div>
        <div class="mt-3">
          {{ $formatPrice(grandTotals['all'][column.name]) }}
        </div>
      </div>
      <div v-else class="text-right">
        <div class="py-2">
          {{ $formatPercent(grandTotals['base'][column.name]) }}
        </div>
        <div class="border-b">
          {{ $formatPercent(grandTotals['change_order'][column.name]) }}
        </div>
        <div class="mt-3">
          {{ $formatPercent(grandTotals['all'][column.name]) }}
        </div>
      </div>
    </template>

  </BaseDataTable>
</template>
<script>
  import { jobProfitReportFormats } from '@/modules/job-costing/components/reports/util'
  import { groupByType } from "@/components/table/utils/groupByTypes";
  import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'

  export default {
    extends: ReportTableWrapper,
    props: {
      format: {
        type: String,
        default: jobProfitReportFormats.Short,
      },
    },
    data() {
      return {
        jobProfitReportFormats,
        header: {},
        groupByType,
      }
    },
    computed: {
      grandTotals() {
        return this.data.grand_totals || {}
      },
      columns() {
        return [
          {
            label: this.$t('Job'),
            prop: 'job',
            minWidth: 100,
            maxWidth: 160,
          },
          {
            label: this.$t('Contract <br> Amount'),
            prop: 'totals.base.contract_amount',
            name: 'contract_amount',
            align: 'right',
            component: 'FormattedPrice',
            minWidth: 90,
            maxWidth: 150,
          },
          {
            label: this.$t('Budgeted <br> Cost'),
            prop: 'totals.base.budgeted_cost',
            name: 'budgeted_cost',
            align: 'right',
            component: 'FormattedPrice',
            minWidth: 90,
            maxWidth: 150,
          },
          {
            label: this.$t('Budgeted <br> Gross Profit'),
            prop: 'totals.base.budgeted_gross_profit',
            name: 'budgeted_gross_profit',
            align: 'right',
            component: 'FormattedPrice',
            minWidth: 90,
            maxWidth: 150,
            renderIf: () => !this.$isPrintPage,
          },
          {
            label: this.$t('%'),
            prop: 'totals.base.budgeted_gross_profit_percent',
            name: 'budgeted_gross_profit_percent',
            align: 'right',
            component: 'FormattedPercent',
            minWidth: 40,
            maxWidth: 60,
          },
          {
            label: this.$t('Est Cost <br> @ Complete'),
            prop: 'totals.base.est_final_cost',
            name: 'est_final_cost',
            align: 'right',
            component: 'FormattedPrice',
            minWidth: 90,
            maxWidth: 150,
          },
          {
            label: this.$t('Est Gross <br> Profit'),
            prop: 'totals.base.est_gross_profit',
            name: 'est_gross_profit',
            align: 'right',
            component: 'FormattedPrice',
            minWidth: 90,
            maxWidth: 150,
          },
          {
            label: this.$t('%'),
            prop: 'totals.base.est_gross_profit_percent',
            name: 'est_gross_profit_percent',
            align: 'right',
            component: 'FormattedPercent',
            minWidth: 40,
            maxWidth: 60,
          },
          {
            label: this.$t('Cost <br> To-Date'),
            prop: 'totals.base.cost_to_date',
            name: 'cost_to_date',
            align: 'right',
            component: 'FormattedPrice',
            minWidth: 90,
            maxWidth: 150,
          },
          {
            label: this.$t('% <br> Compl'),
            prop: 'totals.base.percent_complete',
            name: 'percent_complete',
            align: 'right',
            component: 'FormattedPercent',
            minWidth: 50,
            maxWidth: 70,
          },
          {
            label: this.$t('Billing <br> To-Date'),
            prop: 'totals.base.billings_to_date',
            name: 'billings_to_date',
            align: 'right',
            component: 'FormattedPrice',
            minWidth: 90,
            maxWidth: 150,
          },
          {
            label: this.$t('Earned <br> Revenue'),
            prop: 'totals.base.earned_revenue',
            name: 'earned_revenue',
            align: 'right',
            component: 'FormattedPrice',
            minWidth: 90,
            maxWidth: 150,
          },
          {
            label: this.$t('Over/Under <br> Billed'),
            prop: 'totals.base.over_under_billed',
            name: 'over_under_billed',
            align: 'right',
            component: 'FormattedPrice',
            minWidth: 90,
            maxWidth: 150,
          },
        ]
      },
      amountColumns() {
        return this.columns.filter(c => c.prop.includes('totals'))
      }
    },
    methods: {
      composeRows(data) {

        data.forEach((group) => {

          this.rows.push({
            header: group.job,
            data: [group],
          })

          this.rows.push(group)

          if (this.format === jobProfitReportFormats.Long) {
            this.rows.push({
              footer: group
            })
          }
        })

        return this.rows
      },
    },
  }
</script>
<style lang="scss">
  .job-profit-report.long-format .table-wrapper .content-row td {
    @apply border-none;
  }
</style>
