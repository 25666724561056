<template>
  <div class="p-3">
    <BaseDataTable :columns="columns"
                   :data="getInvoices"
                   :show-pagination="false"
    >
      <template v-slot:description="{row}">
        <template v-if="row.formatted_reference_date">
          {{ row.formatted_reference_date }} {{ row.item_description1 || '' }}
        </template>
        <template v-else>
          {{ row.service_description || row.item_description1 || '' }}
        </template>
      </template>
      <template v-slot:quantity="{row}">
        <template v-if="row.template === 'regular-invoice'">
          {{ row.quantity }} ({{ row.units_of_measure }}) x {{ $formatPrice(row.unit_rate) }}
        </template>
        <template v-if="row.template === 'annual-maintenance'">
          1
        </template>
      </template>
    </BaseDataTable>
  </div>
</template>
<script>
  export default {
    props: {
      serviceInvoice: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        columns: [
          {
            label: this.$t('Code'),
            prop: 'item_code',
            maxWidth: 150,
          },
          {
            label: this.$t('Date'),
            prop: 'iso_reference_date',
            maxWidth: 150,
            minWidth: 120,
          },
          {
            label: this.$t('Description'),
            prop: 'description',
            maxWidth: 150,
            minWidth: 120,
          },
          {
            label: this.$t('Quantity'),
            prop: 'quantity',
            maxWidth: 150,
            minWidth: 120,
          },
          {
            label: this.$t('Amount'),
            prop: 'extented_amount',
            maxWidth: 150,
            minWidth: 120,
            component: 'FormattedPrice',
          },
        ],
      }
    },
    computed: {
      getInvoices() {
        return this.serviceInvoice?.attributes?.items || []
      },
    },
  }
</script>
