<template>
  <div class="py-4">
    <BaseDataTable
      :data="tableData"
      :columns="columns"
      :limit-max-height="false"
      v-bind="$attrs"
    >
      <template #withholding_state="{row}">
        <div>
          <div
            v-for="(state, key) in row.withholding_state"
            :key="key"
          >
            {{ key }}
          </div>
        </div>
      </template>
      <template #quantity="{row}">
        <div>
          <div
            v-for="(state, key) in row.withholding_state"
            :key="key"
            class="text-right"
          >
            {{ $formatQuantity(state.totals?.quantity) }}
          </div>
        </div>
      </template>
      <template #amount="{row}">
        <div>
          <div
            v-for="(state, key) in row.withholding_state"
            :key="key"
            class="text-right"
          >
            {{ $formatPrice(state.totals?.amount) }}
          </div>
        </div>
      </template>
      <template #subtotal="{subtotal, row}">
        <td colspan="2">
        </td>
        <td colspan="2">
          {{ row.title }}
        </td>
        <td class="text-right !pr-1">
          {{ $formatQuantity(subtotal.quantity) }}
        </td>
        <td class="text-right !pr-3">
          {{ $formatPrice(subtotal.amount) }}
        </td>
      </template>
    </BaseDataTable>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    tableData() {
      const rows = []
      const data = this.data || { totals: {}, total_by_category: {} }
      const totals = data.totals || {}
      const totalsByCategory = data.total_by_category || {}

      for (let summaryKey in totals) {
        const summaryValue = totals[summaryKey] || {}
        let index = 0
        for (let codeKey in summaryValue) {
          const title = index === 0 ? summaryKey : ''
          const codeValue = summaryValue[codeKey] || {}

          rows.push({
            title,
            ...codeValue
          })
          index++
        }
        rows.push({
          subtotal: totalsByCategory[summaryKey] || {},
          title: `Total for ${summaryKey}`,
        })
      }
      return rows
    },
    columns() {
      return [
        {
          label: this.$t('Summary'),
          prop: 'title',
          minWidth: 100,
        },
        {
          label: this.$t('Code'),
          prop: 'code.code',
          minWidth: 50,
          maxWidth: 100,
        },
        {
          label: this.$t('Description'),
          prop: 'code.description',
          minWidth: 100,
        },
        {
          label: this.$t('W/H State'),
          prop: 'withholding_state',
          minWidth: 50,
          maxWidth: 80,
        },
        {
          label: this.$t('Hours <br> Units'),
          prop: 'quantity',
          minWidth: 50,
          maxWidth: 80,
          align: 'right',
        },
        {
          label: this.$t('Amount'),
          prop: 'amount',
          minWidth: 100,
          maxWidth: 120,
          align: 'right',
        }
      ]
    }
  }
}
</script>
