<template>
  <div class="flex-1 flex flex-col">
    <layout-tabs :items="filteredItems"/>
    <router-view/>
  </div>
</template>
<script>
  import layoutPermissions from "@/mixins/layoutPermissions";

  export default {
    mixins: [layoutPermissions],
    computed: {
      items() {
        return [
          {
            name: this.$t('Pay Rates'),
            path: `/payroll/employees/${this.$route.params.id}/rates/pay-rates`,
            authorizedTo: 'authorizedToShow',
          },
          {
            name: this.$t('Deduction Rates'),
            path: `/payroll/employees/${this.$route.params.id}/rates/deduction-rates`,
            authorizedTo: 'authorizedToShow',
          },
          {
            name: this.$t('Retirement Rates'),
            path: `/payroll/employees/${this.$route.params.id}/rates/retirement-rates`,
            authorizedTo: 'authorizedToShow',
          },
          {
            name: this.$t('Benefit Rates'),
            path: `/payroll/employees/${this.$route.params.id}/rates/benefit-rates`,
            authorizedTo: 'authorizedToShow',
          },
          {
            name: this.$t('Flexible Benefit Rates'),
            path: `/payroll/employees/${this.$route.params.id}/rates/flexible-benefit-rates`,
            authorizedTo: 'authorizedToShow',
          },
        ]
      },
      currentResource() {
        return this.$store.state.payroll.currentEmployee
      },
    },
  }
</script>
