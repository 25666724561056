<template>
  <div class="payroll-batches">
    <div class="mt-2">
      <BaseDataTable :columns="columns"
                     actions="delete,search,refresh"
                     url="/restify/employee-checks/batches"
                     :data-loading="loading"
                     :delete-action="deleteAction"
                     :add-default-columns="false"
                     on-delete-confirm-message="Are you sure you want to delete this Payroll Check Batches? Any previously sent email links to Paystubs to employees will no longer work. This action cannot be undone."
                     ref="invitesTable">
        <template v-slot:end_date="{row}">
          <router-link :to="`/payroll/check-batches/${getDate(row)}`">
            {{$formatDate(row.end_date, 'date', true)}}
          </router-link>
        </template>
        <template v-slot:download_percent="{row}">
          {{$getPercent(+row.downloaded_count, +row.sent_count)}}
        </template>
        <template v-slot:extra-actions-before="{row}">
          <table-view-button @click="seeMoreClick(row)"/>
        </template>
      </BaseDataTable>
    </div>
  </div>
</template>
<script>
  import axios from "axios";
  import TableViewButton from "@/components/table/actions/TableViewButton";

  export default {
  components: {
    TableViewButton
  },
  data() {
    return {
      batches: [],
      loading: false,
      columns: [
        {
          label: this.$t('End Date'),
          prop: 'end_date',
          maxWidth: 150,
          align: 'center',
          component: 'FormattedDate',
        },
        {
          label: this.$t('Payroll Journal'),
          prop: 'journal',
          maxWidth: 200,
        },
        {
          label: this.$t('Check Count'),
          prop: 'count',
          maxWidth: 100,
          align: 'center',
          component: 'Count',
        },
        {
          label: this.$t('Sent Count'),
          prop: 'sent_count',
          maxWidth: 100,
          align: 'center',
          component: 'Count',
        },
        {
          label: this.$t('Sent Times'),
          prop: 'sent_times',
          maxWidth: 100,
          align: 'center',
          component: 'Count',
        },
        {
          label: this.$t('Downloaded'),
          prop: 'downloaded_count',
          maxWidth: 100,
          align: 'center',
          component: 'Count',
        },
        {
          label: this.$t('Downloaded %'),
          prop: 'download_percent',
          maxWidth: 140,
          align: 'center',
        },
      ]
    }
  },
  methods: {
    seeMoreClick(row) {
      this.$router.push(`/payroll/check-batches/${this.getDate(row)}`)
    },
    getDate(row) {
      return this.$formatDate(row.end_date, 'yyyy-MM-dd', true)
    },
    async deleteAction(row, index) {
      try {
        this.loading = true
        await axios.delete(`/restify/employee-checks/batches/${this.getDate(row)}`)
        this.$success(`Payroll check batches for #${this.getDate(row)} deleted.`)
        this.$refs.invitesTable.tableData.splice(index, 1)
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(`Could not delete payroll check batches for #${this.getDate(row)}.`)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
