<template>
  <base-popover :parent-entity-id="`popover-status-${invoice.id}`"
                :placement="placement"
                :ref="`popover-status-${invoice.id}`"
  >
    <template #body>
      <div v-if="canChangeStatus" class="w-32 flex-col">
        <base-radio v-for="(label, value) in pendingStatuses"
                    :value="invoice.attributes.status"
                    :label="value"
                    :key="`radio-status-${value}`"
                    :active="value === invoice.attributes.status"
                    name="status"
                    @input="changeInvoiceStatus(invoice, $event)"
                    inline>
          {{ label }}
        </base-radio>
      </div>
    </template>
    <template #reference>
      <div class="flex justify-end">
        <status-link :row="invoice"/>
      </div>
    </template>
  </base-popover>
</template>
<script>
  import axios from 'axios'

  export default {
    props: {
      invoice: {
        type: Object,
        default: () => ({})
      },
      placement: {
        type: String,
        default: 'right'
      },
      resourceName: {
        type: String,
        default: 'invoices'
      },
      link: String,
    },
    data() {
      return {
        pendingStatuses: {
          [this.$resourceStatuses.NoPost]: this.$t('No Post'),
          [this.$resourceStatuses.Pending]: this.$t('Pending'),
        },
      }
    },
    computed: {
      canChangeStatus() {
        return this.$isAuthorized('authorizedToUpdate', this.invoice) && [this.$resourceStatuses.NoPost, this.$resourceStatuses.Pending].includes(this.invoice.attributes.status)
      },
    },
    methods: {
      async changeInvoiceStatus(invoice, status) {
        const oldStatus = invoice.attributes.status
        try {
          invoice.attributes.status = status
          this.$set(invoice, 'attributes', invoice.attributes)
          const data = {
            ...invoice.attributes,
            status
          }
          const invoiceResult = await axios.put(`/restify/${this.resourceName}/${invoice.id}`, data)
          const finalInvoice = {
            ...invoice,
            ...invoiceResult.data,
          }
          this.$set(invoice, 'attributes', finalInvoice.attributes)
          this.$emit('status-change', finalInvoice)
        } catch (err) {
          this.$error(this.$t('Could not update the status.'))
          invoice.attributes = oldStatus
          this.$set(invoice, 'attributes', invoice)
        }
      }
    }
  }
</script>
