<template>
    <base-form :loading="loading"
               :showCancel="showCancel"
               :showBack="showBack"
               layout="modal"
               @cancel="$emit('cancel')"
               @submit="onSubmit"
    >
        <div class="col-span-6 md:col-span-2">
            <base-input v-model="model.code"
                        :label="$t('Code')"
                        :placeholder="$t('Code')"
                        :disabled="model.id"
                        id="code"
                        rules="required|code"
            />
        </div>
        <div class="col-span-6 md:col-span-4">
            <base-input v-model="model.description"
                        :label="$t('Description')"
                        :placeholder="$t('Description')"
                        id="description"
                        rules="max:150"
            />
        </div>
        <div class="col-span-6 mt-4 mb-6 pb-2 border-b">
            <h5 class="font-medium">
                {{$t('Normal G/L Asset Account/Sub-Account for Inventory')}}
            </h5>
        </div>
        <div class="col-span-6 md:col-span-3">
            <account-select v-model="model.inventory_account"
                            :name="$t('Account Number')"
                            :label="$t('Account Number')"
                            id="inventory_account"
                            rules="required"
                            value-key="number"
            />
        </div>
        <div class="col-span-6 md:col-span-3">
            <sub-account-select v-model="model.inventory_subaccount"
                                :label="$t('Sub Account Number')"
                                id="disposal_subaccount"
                                value-key="number"
            />
        </div>
        <div class="col-span-6 mt-4 mb-6 pb-2 border-b">
            <h5 class="font-medium">
                {{$t('Normal G/L Expense Account for COGS (Cost Of Goods Sold)')}}
            </h5>
        </div>
        <div class="col-span-6 md:col-span-3">
            <account-select v-model="model.cogs_account"
                            :name="$t('Account Number')"
                            :label="$t('Account Number')"
                            id="calc_depreciation_account"
                            rules="required"
                            value-key="number"
            />
        </div>
        <div class="col-span-6 md:col-span-3">
            <sub-account-select v-model="model.calc_depreciation_subaccount"
                                :label="$t('Sub Account Number')"
                                id="cogs_subaccount"
                                value-key="number"
            />
        </div>
    </base-form>
</template>
<script>
  import axios from 'axios'
  import AccountSelect from '@/modules/ledger/components/AccountSelect'
  import SubAccountSelect from '@/modules/ledger/components/SubAccountSelect'

  export default {
    components: {
      AccountSelect,
      SubAccountSelect,
    },
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        loading: false,
        showCancel: true,
        showBack: false,
        model: {
          code: '',
          description: '',
          inventory_account: undefined,
          inventory_subaccount: undefined,
          cogs_account: undefined,
          cogs_subaccount: undefined,
        },
      }
    },
    watch: {
      data: {
        immediate: true,
        handler(value) {
          this.model = {
            ...this.model,
            ...value,
          }
        },
      },
    },
    methods: {
      async onSubmit() {
        try {
          this.loading = true
          if (this.model.id) {
            await axios.put(`/restify/material-classes/${this.model.id}`, this.model)
            this.$success(this.$t('Material class updated'))
          } else {
            await axios.post('/restify/material-classes', this.model)
            this.$success(this.$t('Material class added'))
          }
          this.$emit('save', this.model)
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not update the equipment material class code'))
        } finally {
          this.loading = false
        }
      },
    },
  }
</script>
