import store from "@/store";
import { globalResources } from "@/components/form/util";
import {Billing, WorkSite} from "@/modules/common/types/models";
import {round} from "lodash";
import axios from "axios";
import Data = API.Data;
import {costCenterTypes} from "@/components/grid-table/utils/cost-center";
import {$confirm} from "@/components/common/modal/modalPlugin";
import i18n from "@/i18n";
import get from "lodash/get";
import {set} from "vue";
import orderBy from "lodash/orderBy";

type TaxDistrictParams = {
  job_id: string | null;
  customer_id: string | null;
  district_id: string | null;
  work_order_id: string | null;
}

export function getTotalAppliedForBilling(billing) {
  const amount = +billing?.amount || 0
  const discount = +billing?.discount_amount || 0
  const cash_on_deposit = +billing?.from_cash_on_deposit_amount || 0
  return amount + cash_on_deposit + discount
}

export function getBillingsFromOtherPayments(otherCustomerPayments, row) {
  let billings = []
  otherCustomerPayments.forEach(payment => {
    const matchingBilling = payment?.billings[row.id]
    if (!matchingBilling) {
      return
    }
    billings.push(matchingBilling)
  })
  return billings
}

export function getTaxPercentage({ job_id, customer_id, district_id }: TaxDistrictParams) {
  const job = store.getters['globalLists/getResourceById'](globalResources.Jobs, job_id)
  const customer = store.getters['globalLists/getResourceById'](globalResources.Customers, customer_id)
  const districtId = district_id || job?.district_id || customer?.district_id
  const district = store.getters['globalLists/getResourceById'](globalResources.SalesTaxDistricts, districtId)
  return district?.sales_tax_percent || 0
}
export async function getDistrictId({ job_id, customer_id, district_id, work_order_id }: TaxDistrictParams) {
  const job = store.getters['globalLists/getResourceById'](globalResources.Jobs, job_id)
  const customer = store.getters['globalLists/getResourceById'](globalResources.Customers, customer_id)

  const workOrder = store.getters['globalLists/getResourceById'](globalResources.WorkOrders, work_order_id)
  if (workOrder?.work_site_id) {
    const res: any = await axios.get(`/restify/work-sites/${workOrder.work_site_id}`)
    const workSite: Data<WorkSite> = res.data
    if (workSite?.attributes.district_id) {
      district_id = workSite?.attributes.district_id
    }
  }
  return district_id || job?.district_id || customer?.district_id
}

export async function getSalesTaxDistrict(params: TaxDistrictParams) {
  const districtId = await getDistrictId(params)
  return store.getters['globalLists/getResourceById'](globalResources.SalesTaxDistricts, districtId)
}

export async function getSalesTaxPercentage({ job_id, customer_id, district_id, work_order_id }: TaxDistrictParams) {
  const job = store.getters['globalLists/getResourceById'](globalResources.Jobs, job_id)
  const customer = store.getters['globalLists/getResourceById'](globalResources.Customers, customer_id)

  const district = await getSalesTaxDistrict({ job_id, customer_id, district_id, work_order_id })
  const exempt_from_sales_tax = job.exempt_from_sales_tax || customer.exempt_from_sales_tax || false
  let percentage = job.sales_tax_percent || customer.sales_tax_percent || district?.sales_tax_percent || 0
  if (exempt_from_sales_tax) {
    percentage = 0
  }
  return {
    exempt_from_sales_tax,
    percentage,
  }
}

export function getRetentionPercentage(jobId: string) {
  const job = store.getters['globalLists/getResourceById'](globalResources.Jobs, jobId)
  return job?.billing_retention_percent || 0
}

export function computeBillingEntrySalesTax(entry: any, billing: Billing) {
  const taxPercent = entry.sales_tax_percent || billing.sales_tax_percent || 0
  const subjectToTax = entry?.subject_to_tax || entry?.meta?.subject_to_tax
  if (entry?.meta?.custom_sales_tax && entry.sales_tax_amount !== 0) {
    return {
      amount: entry.sales_tax_amount,
      exempt_from_sales_tax: false,
    }
  }
  if (!taxPercent || subjectToTax === false) {
    return {
      amount: 0,
      exempt_from_sales_tax: false,
    }
  }
  const lineItemId = entry.addl_source_id
  let jobId = billing.job_id
  if (entry.cost_center === costCenterTypes.Job) {
    jobId = entry.source_id
  }
  const job = store.getters['globalLists/getResourceById'](globalResources.Jobs, jobId)
  const lineItem = store.getters['globalLists/getResourceById'](globalResources.LineItems, lineItemId)
  const exempt_from_sales_tax = lineItem?.exempt_from_sales_tax || job?.exempt_from_sales_tax || false
  const baseTaxAmount = (entry.gross_amount || 0) + (entry.material_stored_amount || 0)
  let amount = taxPercent * baseTaxAmount / 100
  if (exempt_from_sales_tax) {
    amount = 0
  }
  return {
    amount: +amount,
    exempt_from_sales_tax,
  }
}

export function computeBillingEntryRetention(entry: any, billing: Billing) {
  let { retention_percent, gross_amount } = entry
  retention_percent = retention_percent || billing?.retention_percent || 0
  gross_amount = gross_amount || 0
  if (retention_percent === 0) {
    return 0
  }
  return round(retention_percent * gross_amount / 100, 2)
}

export async function shouldEntriesChange(tableRef: any) {
  if (!tableRef) {
    return
  }
  const entries = tableRef?.getEntries()
  if (entries?.length === 0) {
    return
  }
  const confirmed = await $confirm({
    title: i18n.t('Update entries table?'),
    description: i18n.t(`Do you want to update all table entries with the new information? Note that this will not save the data in the database. To save the data, use the 'Save' or 'Update' button.`),
    buttonText: i18n.t('Update entries'),
  })
  if (!confirmed) {
    return
  }
  tableRef?.recomputeEntryAmounts?.()
}

export function naturalSortEntries(entries: any[], lineItemKey: string) {
  const mappedEntries = entries.map(entry => {
    const lineItem = get(entry, lineItemKey)
    if (!lineItem) {
      return entry
    }
    if (!isNaN(+lineItem.phase_code)) {
      lineItem.phase_code = +lineItem.phase_code
    }
    if (!isNaN(+lineItem.cost_code)) {
      lineItem.cost_code = +lineItem.cost_code
    }
    set(entry, lineItemKey, lineItem)
    return entry
  })
  return orderBy(mappedEntries, [`${lineItemKey}.phase_code`, `${lineItemKey}.cost_code`, `${lineItemKey}.change_order`])
}

export const ReportIntervals = {
  Monthly: '1 month',
  Weekly: '1 week',
}

