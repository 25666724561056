<template>
  <CostPlusBillingForm
      :key="renderKey"
      @create-another="renderKey++"
  />
</template>
<script>
  import CostPlusBillingForm from '@/modules/accounts-receivable/components/cost-plus-billings/CostPlusBillingForm'

  export default {
    components: {
      CostPlusBillingForm,
    },
    data() {
      return {
        renderKey: 1,
      }
    },
  }
</script>
