<template>
    <CategoryForm :redirect-on-create="true"/>
</template>
<script>
  import CategoryForm from '@/modules/job-costing/components/CategoryForm'

  export default {
    components: {
      CategoryForm,
    },
  }
</script>
