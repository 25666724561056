import i18n from "@/i18n";

export const modelColumnMappings = {
  'employee-checks': [
    'employee.email',
    'employee.code',
    'employee.name',
    'amount',
    'date',
    'end_date',
    'sent_count'
  ],
  'service-billing-invoices': [
    'customer.email',
    'customer.code',
    'customer.name',
    'total.due',
    'date',
    'invoice_date',
    'sent_count'
  ],
  'customer-statements': [
    'customer.email',
    'customer.code',
    'customer.name',
    {
      label: i18n.t('Due Amount'),
      value: 'customer_data.ReceivableAmount'
    },
    'date',
    'sent_count'
  ],
  employees: [
    'code',
    'name',
    'email',
    'federal_status',
    'phone_1',
    'dob',
    'sex',
    'minority',
    'pay_frequency',
    'receive_checks_via_email',
    'fit_adjustment_type',
    'fit_adjustment_rate',
    'status',
    'account',
    'subaccount',
    'sui_sdi_state.code',
    'withholding_state.code',
    'workers_comp_rate_code',
    'gen_liability_rate_code',
    'hourly_rate',
    'salary_amount',
    'on_certified_payroll',
    'hire_date',
    'created_at'
  ],
  accounts: [
    'number',
    'description',
    'type',
    'restrictions',
    'active',
    'fiscal_year',
    'created_at'
  ],
  customers: [
    'code',
    'name',
    'email',
    'phone',
    'fax',
    'status',
    'account',
    'subaccount',
    'terms',
    'current_ar_amount',
    'current_unapplied_cash_on_deposit_amount',
    'retention_amount',
    'sales_tax_percent',
    'district.code',
    'exempt_from_sales_tax',
    'exempt_from_late_charges',
  ],
  vendors: [
    'code',
    'name',
    'email',
    'phone',
    'fax',
    'status',
    'account',
    'subaccount',
    'current_ap_amount',
    'retention_amount',
    'payment_to_date',
    'payments_on_hold_amount',
    'fed_tax_id',
    'state_tax_id',
    'w9_on_file'
  ],
  invoices: [
    'number',
    'description',
    'vendor.code',
    'date',
    'due_date',
    'discount_date',
    'posted_at',
    'voided_at',
    'paid_at',
    'gross_amount',
    'discount_amount',
    'retention_amount',
    'open_amount',
  ],
  billings: [
    'number',
    'description',
    'customer.code',
    'job.number',
    'date',
    'discount_date',
    'due_date',
    'posted_at',
    'voided_at',
    'paid_at',
    'type',
    'net_amount',
    'discount_amount',
    'retention_amount',
    'sales_tax_amount',
    'gross_amount',
    'open_amount',
    'status',
    'created_at',
  ],
  'line-items': [
    'description',
    'phase_code',
    'cost_code',
    'change_order',
    'job.number',
    'amount',
    'amount_to_date',
    'retention_amount',
    'account',
    'additional_description',
    'status',
  ],
  'gen-liability-rates': [
    'code',
    'description',
    'state',
    'calculate_by',
    'multiply_by',
    'amount',
    'start_date',
  ],
  'workers-comp-rates': [
    'code',
    'description',
    'employer_rate',
    'employee_deduction_rate',
    'calculate_by',
    'multiply_by',
    'rate_limit',
    'rate_limit_type',
    'state',
    'start_date',
  ],
  jobs: [
    'number',
    'description',
    'category.code',
    'user_notes',
    'status',
    'billing_rate_type.code',
    'billing_retention_percent',
    'certified_payroll',
    'customer.code',
    'district.code',
    'district.state',
    'gen_liability_rate_code',
    'print_ss',
    'project_number',
    'sales_person.code',
    'subcontract_retention_percent',
    'union.code',
    'union_rate_type.title',
    'units',
    'um',
    'workers_comp_ocip.code',
  ],
  'maintenance-code': [
    'code',
    'description',
    'interval',
    'interval_months',
    'interval_value'
  ],
  equipment: [
    'code',
    'description',
    'year_and_model',
    'serial_no',
    'license_no',
    'class.code',
    'asset_type',
    'disposition',
    'standard_job_cost_hourly_rate',
    'status',
  ],
  'equipment-class': [
    'code',
    'description',
    'disposal_account',
    'disposal_subaccount',
    'calculated_depreciation_account',
    'calculated_depreciation_subaccount',
  ],
  districts: [
    'account',
    'code',
    'county',
    'description',
    'state',
    'sales_tax_percent',
    'subaccount',
  ],
  'craft-code': [
    'code',
    'level',
    'description',
    'billing_description',
    'trade',
    'classification',
  ],
  'pay-code': [
    'code',
    'description',
    'calculate_by',
    'multiply_by',
    'expense_subaccount',
    'include_on_certified_payroll_report',
    'include_on_union_reports',
    'is_disposable_earning',
    'liability_account',
    'liability_subaccount',
    'subject_to_fica',
    'subject_to_fit',
    'subject_to_fui',
    'subject_to_liability_insurance',
    'subject_to_lit',
    'subject_to_sdi',
    'subject_to_sit',
    'subject_to_sui',
    'subject_to_workers_comp',
    'treat_units_as_regular_hours',
    'use_employee_regular_rate',
  ],
  'benefit-code': [
    'code',
    'description',
    'calculate_by',
    'multiply_by',
    'expense_account',
    'expense_subaccount',
    'liability_account',
    'liability_subaccount',
    'include_on_certified_payroll_report',
    'include_on_union_reports',
    'is_401k_match',
    'liability_account',
    'liability_subaccount',
    'multiply_by',
    'should_distribute',
    'subject_to_fica',
    'subject_to_fit',
    'subject_to_fui',
    'subject_to_liability_insurance',
    'subject_to_lit',
    'subject_to_sdi',
    'subject_to_sit',
    'subject_to_sui',
    'subject_to_workers_comp',
    'use_employee_regular_rate',
  ],
  'flexible-benefit-plan': [
    'code',
    'description',
    'calculate_by',
    'multiply_by',
    'account',
    'subaccount',
    'include_on_union_reports',
    'reduce_disposible_earnings',
    'subject_to_fica',
    'subject_to_fit',
    'subject_to_fui',
    'subject_to_liability_insurance',
    'subject_to_lit',
    'subject_to_sdi',
    'subject_to_sit',
    'subject_to_sui',
    'subject_to_workers_comp',
  ],
  'deduction-code': [
    'code',
    'description',
    'calculate_by',
    'multiply_by',
    'account',
    'subaccount'
  ],
  'retirement-plan': [
    'code',
    'description',
    'calculate_by',
    'multiply_by',
    'account',
    'subaccount',
    'include_on_union_reports',
    'is_loan',
    'is_roth',
    'multiply_by',
    'reduce_gross_pay_by_125s',
  ],
  'union': [
    'code',
    'description',
  ],
  'accounts-receivable-inits': [
    'number',
    'customer.code',
    'date',
    'description',
    'gross_amount'
  ],
  default: [
    'code',
    'description'
  ]
}

export const exportActions = {
  'accounts-receivable-inits': 'accounts-receivable-init-entries-data-export',
  default: 'data-export',
}
