<template>
  <CostLineItemForm
      :type="costTypes.Cost"
      :data="lineItem"
  />
</template>
<script>
  import { costTypes } from '@/enum/enums'
  import CostLineItemForm from '@/modules/job-costing/components/line-items/CostLineItemForm'

  export default {
    components: {
      CostLineItemForm,
    },
    data() {
      return {
        costTypes,
      }
    },
    computed: {
      lineItem() {
        return this.$store.state.jobCosting.currentLineItem
      },
      lineItemLoading() {
        return this.$store.state.jobCosting.lineItemLoading
      },
    },
  }
</script>
