<template>
  <div>
    <layout-tabs :items="filteredItems"/>
    <router-view/>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        items: [
          {
            name: this.$t('Periods'),
            path: `/ledger/settings/initialize-system`,
            permission: 'periods_show',
          },
          {
            name: this.$t('Fiscal Years'),
            path: `/ledger/settings/fiscal-years`,
            permission: 'fiscal_year_show',
          },
          {
            name: this.$t('Financial Reports'),
            path: `/ledger/settings/financial-reports`,
            permission: 'general_ledger_reports',
          },
          {
            name: this.$t('Settings'),
            path: `/ledger/settings/general-ledger`,
            permission: 'fiscal_year_show',
          },
          {
            name: this.$t('Settings Update Activity'),
            path: `/ledger/settings/activity-log`,
            permission: 'general_ledger_settings',
          }
        ],
      }
    },
    computed: {
      filteredItems() {
        return this.items.filter(item => this.$can(item.permission))
      },
    },
  }
</script>
