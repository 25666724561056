<template>
  <div>
    <BaseDataTable
        :url="url"
        :columns="columns"
        :edit-entity-url-query="editEntityUrlQuery"
        :add-text="$t('New service billing code')"
        ref="table"
        view-entity-url-query="/service-billing/settings/codes"
        default-sort="code"
        actions="search,refresh"
        permission="service_billing_codes"
        @add="onRowAdd"
        @edit="onRowEdit"
    />
    <BaseFormDialog
        v-if="showDialog"
        :title="rowToEdit ? $t('Edit S/B Code') : $t('Add S/B Code')"
        :open.sync="showDialog"
        @close="onEventTrigger">
      <ServiceBillingCodeForm
          :data="rowToEdit"
          @cancel="onEventTrigger"
          @save="onEventTrigger"
      />
    </BaseFormDialog>
  </div>
</template>
<script>
  import { loadGlobalResourceByModule } from '@/utils/modules'
  import ServiceBillingCodeForm from '@/modules/service-billing/components/settings/ServiceBillingCodeForm'

  export default {
    components: {
      ServiceBillingCodeForm,
    },
    data() {
      return {
        url: '/restify/service-billing-codes',
        editEntityUrlQuery: '?id={ID}',
        rowToEdit: null,
        showDialog: false,
        columns: [
          {
            label: this.$t('Code'),
            prop: 'attributes.code',
            component: 'EntityLink',
            redirectTo: '/service-billing/settings/codes?id={ID}',
          },
          {
            label: this.$t('Description'),
            prop: 'attributes.description',
            maxWidth: 200,
          },
          {
            label: this.$t('Hours'),
            prop: 'attributes.hours',
            align: 'center',
          },
          {
            label: this.$t('Minutes'),
            prop: 'attributes.minutes',
            align: 'center',
          },
          {
            label: this.$t('Technicians'),
            prop: 'attributes.technicians',
            maxWidth: 100,
          },
        ],
      }
    },
    methods: {
      onRowEdit(row) {
        this.showDialog = true
        this.rowToEdit = row
      },
      onRowAdd() {
        this.showDialog = true
      },
      refreshTable() {
        this.$refs.table.refresh()
      },
      onEventTrigger() {
        this.rowToEdit = null
        this.showDialog = false
        this.refreshTable()
        loadGlobalResourceByModule(this.$modules.SB)
      },
    },
  }
</script>
