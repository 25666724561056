<template>
  <InvoiceForm
      v-if="invoice"
      :invoice="invoice"
      :collapse-header="true"
      @refresh="getInvoice"
  />
</template>
<script>
  import InvoiceForm from '@/modules/accounts-payable/components/invoice/InvoiceForm'

  export default {
    components: {
      InvoiceForm,
    },
    computed: {
      invoice() {
        return this.$store.state.accountsPayable.currentInvoice
      },
    },
    methods: {
      getInvoice() {
        this.$store.dispatch('accountsPayable/getInvoice', this.$route.params.id)
      },
    },
    watch: {
      invoice: {
        deep: true,
        immediate: true,
        handler(value) {
          if (!value.id) {
            return
          }
          const { meta } = value || {}
          if (meta.hasOwnProperty('authorizedToUpdate') && !meta.authorizedToUpdate) {
            this.$router.push(`/accounts-payable/invoices/${value.id}/view`)
          }
        },
      },
    },
  }
</script>
