<template>
    <div>
        <BaseDataTable :columns="columns"
                       :url="url"
                       :url-params="{
                         related: 'bid'
                       }"
                       :add-text="$t('New bid item')"
                       default-sort="code"
                       actions="search,refresh"
                       hide-actions="view"
                       permission="bid_items"
                       ref="table"
                       @add="onRowAdd"
                       @edit="onRowEdit"
                       :edit-entity-url-query="editEntityUrlQuery"
        >
            <template v-slot:bid="{row}">
                <template v-if="row.attributes.bid_id">
                    <router-link :to="`/estimates/bids?id=${row.attributes.bid_id}`">
                        {{get(row, 'relationships.bid.attributes.number')}}
                    </router-link>
                </template>
                <template v-else>

                </template>
            </template>
        </BaseDataTable>
        <BaseFormDialog v-if="showAddDialog"
                        :title="$t('Add Bid Item')"
                        :open.sync="showAddDialog">
            <BidItemForm @cancel="showAddDialog = false"
                         @save="onAdd"
            />
        </BaseFormDialog>
        <BaseFormDialog v-if="showEditDialog"
                        :title="$t('Edit Bid Item')"
                        :open.sync="showEditDialog">
            <BidItemForm :data="rowToEdit"
                         @cancel="showEditDialog = false"
                         @save="onEdit"
            />
        </BaseFormDialog>
    </div>
</template>
<script>
  import BidItemForm from '@/modules/estimates/components/BidItemForm'

  export default {
    components: {
      BidItemForm,
    },
    data() {
      return {
        editEntityUrlQuery: '?id={ID}',
        url: '/restify/bid-items',
        rowToEdit: null,
        showAddDialog: false,
        showEditDialog: false,
        columns: [
          {
            label: this.$t('Code'),
            prop: 'attributes.code',
            component: 'EntityLink',
            redirectTo: '/estimates/bid-items?id={ID}',
          },
          {
            label: this.$t('Bid'),
            prop: 'bid',
          },
          {
            label: this.$t('Description'),
            prop: 'attributes.description',
            minWidth: 150,
            maxWidth: 200,
          },
          {
            label: this.$t('Status'),
            prop: 'attributes.status',
            component: 'Status',
            align: 'center',
          },
        ],
      }
    },
    methods: {
      onRowEdit(row) {
        this.showEditDialog = true
        this.rowToEdit = row
      },
      onRowAdd() {
        this.showAddDialog = true
      },
      onAdd() {
        this.showAddDialog = false
        this.$refs.table.refresh()
      },
      onEdit() {
        this.showEditDialog = false
        this.$refs.table.refresh()
      },
    },
  }
</script>
