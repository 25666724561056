<template>
  <BaseReportForm
      :filters="model"
      :report-number="reportNumber"
  >
    <template #filters>

      <BaseFilterRow :title="$t('Format')">
        <BaseSelect
            v-model="model.format"
            :add-entity="false"
            :options="formatOptions"
            class="col-span-2"
            @change="onChangedFormat"
        />
        <BaseCheckbox
            v-model="model.include_non_union_transactions"
            :label="$t('Include Non-Union Transactions')"
            id="include_non_union_transactions"
            class="col-span-4"
        />
      </BaseFilterRow>

      <BaseFilterRow :title="$t('Unions')">
        <RangeFilter
            :config="RangeFilterConfig.Union"
            :model="model"
            :from.sync="model.union_code_from"
            :to.sync="model.union_code_to"
            class="col-span-4"
        />
        <span class="whitespace-nowrap text-sm text-gray-600 font-medium">
          {{ $t('Jobs') }}
        </span>
        <RangeFilter
            :config="RangeFilterConfig.Job"
            :model="model"
            :from.sync="model.job_number_from"
            :to.sync="model.job_number_to"
            class="col-span-4"
        />
      </BaseFilterRow>

      <BaseFilterRow :title="$t('Accounts')">
        <AccountRangeFilter
            v-model="model"
            class="col-span-4"
        />
        <template v-if="byCraftCodeFormat">
          <span class="whitespace-nowrap text-sm text-gray-600 font-medium">
            {{ $t('Craft Codes') }}
          </span>
          <CraftRangeFilter v-model="model" class="col-span-4"/>
        </template>
      </BaseFilterRow>

      <BaseFilterRow
          v-if="byEmployeeWeekFormat"
          :title="$t('Include')"
      >
        <BaseSelect
            v-model="model.wages"
            :options="wagesOptions"
            class="col-span-3"
        />

      </BaseFilterRow>

      <PayrollDatesFilter v-model="model"/>

    </template>

    <template #table="{loading, data}">
      <component
          :is="tableComponent"
          :data="data"
          :filters="model"
          :report-number="reportNumber"
          :data-loading="loading"
          ref="reportTable"
      />
    </template>

  </BaseReportForm>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { JobStatus } from '@/modules/job-costing/enum/jobs'
  import { selectionDateFields } from '@/modules/payroll/components/reports/util'
  import { RangeFilterConfig, AccountRangeFilter } from '@/components/range-filters'
  import PayrollDatesFilter from '@/modules/payroll/components/reports/PayrollDatesFilter.vue'
  import UnionReportByCraft from '@/modules/payroll/components/reports/union-report/UnionReportByCraft.vue'
  import { unionReportFormats, UnionReportFormat } from '@/modules/payroll/components/reports/union-report/unionReportUtil'
  import UnionReportByEmployee from '@/modules/payroll/components/reports/union-report/UnionReportByEmployee.vue'
  import UnionReportByEmployeeByWeek
    from '@/modules/payroll/components/reports/union-report/UnionReportByEmployeeByWeek.vue'
  import UnionReportByJobTotalHours
    from '@/modules/payroll/components/reports/union-report/UnionReportByJobTotalHours.vue'
  import UnionReportByJobAndEmployee
    from '@/modules/payroll/components/reports/union-report/UnionReportByJobAndEmployee.vue'
  import CraftRangeFilter from "@/components/range-filters/CraftRangeFilter.vue";

  const wages = {
    Prevailing: 'prevailing',
    NonPrevailing: 'non-prevailing',
    None: 'none',
  }

  export default defineComponent({
    components: {
      CraftRangeFilter,
      PayrollDatesFilter,
      AccountRangeFilter,
      UnionReportByCraft,
      UnionReportByEmployee,
      UnionReportByEmployeeByWeek,
      UnionReportByJobTotalHours,
      UnionReportByJobAndEmployee,
    },
    data() {
      return {
        selectionDateFields,
        RangeFilterConfig,
        model: <PAYROLL.UNION_REPORT>{
          format: unionReportFormats.ByCraft,
          job_statuses: [ JobStatus.Open, JobStatus.Closed ],
          job_number_from: null,
          job_number_to: null,
          union_code_from: null,
          union_code_to: null,
          craft_code_from_id: null,
          craft_code_to_id: null,
          craft_code_from: null,
          craft_code_to: null,
          craft_level_from: null,
          craft_level_to: null,
          account_number_from: null,
          account_number_to: null,
          wages: wages.None,
          new_page_for_craft_code: false,
          include_non_union_transactions: false,
          ...selectionDateFields,
          totals_only: false,
          include_paycode_detail: false,
        },
        wagesOptions: [
          {
            label: this.$t('Prevailing and Non-Prevailing Wages'),
            value: wages.Prevailing,
          },
          {
            label: this.$t('Prevailing Wages Only'),
            value: wages.NonPrevailing,
          },
          {
            label: this.$t('Non-Prevailing Wages Only'),
            value: wages.None,
          },
        ],
        formatOptions: [
          {
            label: this.$t('By Craft'),
            value: unionReportFormats.ByCraft,
          },
          {
            label: this.$t('By Employee - Summary'), // * 4887a
            value: unionReportFormats.ByEmployeeSummary,
          },
          {
            label: this.$t('By Employee - By Week'), // * 4887b
            value: unionReportFormats.ByEmployeeWeek,
          },
          {
            label: this.$t('By Job - Hourly Pay Only'), // * 4887c
            value: unionReportFormats.ByJobHourlyPay,
          },
          {
            label: this.$t('By Job - w/Other Pay/Ded'), // * 4887c + summaries
            value: unionReportFormats.ByJobSummary,
          },
          // ! TODO: backend in progress
          // {
          //   label: this.$t('By Job and Employee'), // * 4887d
          //   value: unionReportFormats.ByJobAndEmployee,
          // },
          // * 4887e
          // {
          //   label: this.$t('By Job and Employee Totals'),
          //   value: unionReportFormats.ByJobAndEmployeeTotals,
          // },
          // * 4887f
          // {
          //   label: this.$t('By Craft w/Hours Paid'),
          //   value: unionReportFormats.ByCraftHoursPaid,
          // },
        ],
      }
    },
    computed: {
      reportNumber(): string | number {
        const reportNumberMap = {
          [unionReportFormats.ByCraft]: 4887,
          [unionReportFormats.ByEmployeeSummary]: '4887a',
          [unionReportFormats.ByEmployeeWeek]: '4887b',
          [unionReportFormats.ByJobHourlyPay]: '4887c',
          [unionReportFormats.ByJobSummary]: '4887c',
          // ! TODO: backend in progress
          // [unionReportFormats.ByJobAndEmployee]: '4887d',
          default: 4887,
        }
        // @ts-ignore
        return reportNumberMap[this.model.format] || reportNumberMap.default
      },
      byCraftCodeFormat(): boolean {
        return this.model.format === unionReportFormats.ByCraft
      },
      byEmployeeWeekFormat(): boolean {
        return this.model.format === unionReportFormats.ByEmployeeWeek
      },
      tableComponent(): string {
        const componentMap = {
          [unionReportFormats.ByCraft]: 'UnionReportByCraft',
          [unionReportFormats.ByEmployeeSummary]: 'UnionReportByEmployee',
          [unionReportFormats.ByEmployeeWeek]: 'UnionReportByEmployeeByWeek',
          [unionReportFormats.ByJobHourlyPay]: 'UnionReportByJobTotalHours',
          [unionReportFormats.ByJobSummary]: 'UnionReportByJobTotalHours',
          // ! TODO: backend in progress
          // [unionReportFormats.ByJobAndEmployee]: 'UnionReportByJobAndEmployee',
          default: 'UnionReportByCraft',
        }
        // @ts-ignore
        return componentMap[this.model.format] || componentMap.default
      }
    },
    methods: {
      onChangedFormat(format: UnionReportFormat) {
        this.model.craft_code_from = this.model.craft_code_to = null
        this.model.wages = wages.None

        this.model.include_paycode_detail = format === unionReportFormats.ByJobSummary;
      }
    }
  })
</script>
