<template>
  <base-data-table
      v-bind="defaultAttributes"
      :columns="columns"
      :data="rows"
      :total-rows="rowsLength"
      :has-summary="false"
      class="equipment-history-listing-report-table"
      @force-pagination="forcePagination"
  >
    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>
    <template #header="{row}">

      <template v-if="isGroupByEquipmentOrClass">
        <span v-if="row?.header?.is_equipment_class">
          <span>{{ $t('Equipment Class') }}:</span>
          <router-link
              v-if="row.header?.equipmentClass?.id"
              :to="`/equipment/settings/classes/${row.header.equipmentClass.id}/view`">
            {{ row.header.equipmentClass.code }} ({{ row.header.equipmentClass.description }})
          </router-link>
        </span>
        <div v-else
             class="flex items-center space-x-2">
          <EquipmentLink
              :data="row.header?.equipment"
              :show-preview="false"
          />
          <span v-if="row.header?.equipment?.asset_type">({{ formattedText(row.header.equipment.asset_type) }})</span>
          <template v-if="row.header?.equipmentClass?.id">
            <span>{{ $t('Class') }}:</span>
            <router-link :to="`/equipment/settings/classes/${row.header.equipmentClass.id}/view`">
              {{ row.header.equipmentClass?.code }}
            </router-link>
          </template>
        </div>

      </template>

      <template v-else>
        <template v-if="groupByJobOrWorkOrder">
          <router-link
              v-if="!row?.header?.is_equipment"
              :to="getGroupHeaderLink(row.header)">
            {{ row?.header?.code || row?.header?.number }}
            <span v-if="row?.header?.description">
              ({{ row.header.description }})
            </span>
          </router-link>
          <EquipmentLink
              v-else
              :data="row?.header?.equipment"
              :show-preview="false"
          />
          <span v-else>{{ row.header }}</span>
        </template>
        <template v-else>
          <router-link
              v-if="getGroupHeaderLink(row?.header)"
              to="">
            {{ row?.header?.code || row?.header?.number }}
            <span v-if="row?.header?.description">
              ({{ row.header.description }})
            </span>
          </router-link>
          <AccountLink
              v-else-if="groupByAccount"
              :number="row?.header"
          />
          <!--* for journal number-->
          <span v-else>
            {{ row?.header }}
          </span>
        </template>
      </template>

    </template>
    <template #equipment="{row}">
      <EquipmentLink
          :data="row.equipment"
          :show-preview="false"
          :show-description="false"
      />
    </template>
    <template #period_fiscal_year="{row}">
      {{ `${row?.history?.fiscal_year}   ${row?.history?.period}` }}
    </template>
    <template #business="{row}">
      <component
          v-if="getRelatedBusinessLink(row)"
          :is="getRelatedBusinessLink(row)"
          :data="row?.business"
          :show-preview="false"
          :show-name="false"
          :show-description="false"
      />
      <span v-else/>
    </template>
    <template #account="{row}">
      <AccountLink
          :number="row?.history?.account"
          :show-description="false"
      />
    </template>
    <template #source="{row}">
      <component
          v-if="getSourceComponent(row)"
          :is="getSourceComponent(row)"
          :data="row?.source"
          :show-description="false"
          :show-preview="false"
      />
      <span v-else/>
    </template>
    <template #addl_source.code="{row}">
      <router-link
          v-if="getAdditionalSourceLink(row)"
          :to="getAdditionalSourceLink(row)"
      >
        {{ row?.addl_source?.code || row?.addl_source?.cost_code }}
      </router-link>
      <span v-else/>
    </template>
    <template #history.hours="{row}">
      {{ row?.history?.hours ? row.history.hours : '' }}
    </template>

    <template #subtotals="{subtotals}">
      <tr
          v-if="subtotals.header"
          class="subtotal-row"
      >
        <td colspan="2"/>
        <td colspan="7">
          <div class="flex items-center space-x-2 justify-end">
            <span>{{ $t(subtotalHeader(subtotals?.header)) }}</span>
            <router-link
                v-if="getGroupHeaderLink(subtotals?.header)"
                :to="getGroupHeaderLink(subtotals?.header)"
            >
              {{ subtotals?.header?.code || subtotals?.header?.number }}
            </router-link>
            <AccountLink
                v-else-if="groupByAccount"
                :number="subtotals?.header"
                :show-description="false"
            />
            <!--* for journal number-->
            <span v-else>
                {{ subtotals?.header }}
              </span>
          </div>
        </td>
        <td align="right">{{ subtotals.gallons || '' }}</td>
        <td align="right">{{ subtotals.hours || '' }}</td>
        <td align="right">
          <span class="mx-2">{{ $formatPrice(subtotals.amount || '') }}</span>
        </td>
      </tr>
      <tr
          v-else
          class="subtotal-row"
          v-for="subtotal in subtotals"
      >
        <td colspan="12" align="right">{{ subtotal.name }}</td>
        <td align="right">{{ subtotal.hours || '' }}</td>
        <td align="right">
          <span class="mx-2">{{ $formatPrice(subtotal.amount || '') }}</span>
        </td>
      </tr>
    </template>

    <template #html-row="{ htmlData }">
      <tr>
        <td colspan="14" align="end"> {{ $t('Grand Totals For Selected History Transactions') }}</td>
      </tr>
      <tr
          v-if="grandTotals[equipmentTransactionTypes.JobCosted]"
          class="summary-row"
      >
        <td colspan="9"/>
        <td colspan="3" align="left">
          {{ $t('Job Costed') }}
        </td>
        <td align="right">
          {{ grandTotals[equipmentTransactionTypes.JobCosted]?.totals?.hours }}
        </td>
        <td align="right">
          {{ $formatPrice(grandTotals[equipmentTransactionTypes.JobCosted]?.totals?.amount) }}
        </td>
      </tr>
      <tr
          v-if="grandTotals[equipmentTransactionTypes.CustomerBilled]"
          class="summary-row"
      >
        <td colspan="9"/>
        <td colspan="3" align="left">
          {{ $t('Customer Billed') }}
        </td>
        <td align="right">
          {{ grandTotals[equipmentTransactionTypes.CustomerBilled]?.totals?.hours }}
        </td>
        <td align="right">
          {{ $formatPrice(grandTotals[equipmentTransactionTypes.CustomerBilled]?.totals?.amount) }}
        </td>
      </tr>
      <template v-if="grandTotals[equipmentTransactionTypes.ExpenseTransaction]">
        <tr class="grand-total-row">
          <td colspan="8"/>
          <td colspan="6" align="center">{{ $t('G/L Account') }}</td>
        </tr>
        <template v-for="expenseTransactions in grandTotals[equipmentTransactionTypes.ExpenseTransaction]">
          <tr
              v-for="account in expenseTransactions.accounts"
              class="grand-total-row"
          >
            <td colspan="8"/>
            <td colspan="4">
              <router-link to="/equipment/settings/equipment-management-settings">
                {{ expenseTransactions.equipment_type?.name }}
              </router-link>
            </td>
            <td align="right">
              <AccountLink
                  :number="account.number"
                  :show-description="false"
              />
            </td>
            <td align="right"> {{ $formatPrice(account.totals?.amount) }}</td>
          </tr>
          <tr class="grand-total-row summary-row">
            <td colspan="8"/>
            <td colspan="4">{{ $t('Total') }} {{ expenseTransactions.equipment_type?.name }}</td>
            <td colspan="2" align="right">{{ $formatPrice(expenseTransactions.totals.amount) }}</td>
          </tr>
        </template>
      </template>
    </template>
  </base-data-table>
</template>
<script lang="ts">
  import {defineComponent} from 'vue'
  import {formattedText} from '@/utils/utils'
  import {equipmentTransactionTypes} from '@/enum/equipment'
  import {getRelatedBusinessLink} from '@/components/links/util'
  import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper.vue'
  import {
    equipmentHistoryColumns,
    getAdditionalSourceLink,
    getSourceComponent,
    formatSubtotalForGroup,
  } from "@/modules/equipment/components/reports/utils";
  import {GroupByTypes} from "@/modules/equipment/components/reports/filterTypes";

  export default defineComponent({
    extends: ReportTableWrapper,
    data() {
      return {
        equipmentTransactionTypes,
        columns: equipmentHistoryColumns,
      }
    },
    computed: {
      groupBy(): string {
        return this.options.group_by
      },
      groupByAccount(): boolean {
        return this.groupBy === GroupByTypes.GLAccount
      },
      groupByEquipment(): boolean {
        return this.groupBy === GroupByTypes.EquipmentCode
      },
      groupByClassCode(): boolean {
        return this.groupBy === GroupByTypes.ClassCode
      },
      isGroupByEquipmentOrClass(): boolean {
        return this.groupByEquipment || this.groupByClassCode
      },
      groupByJobOrWorkOrder(): boolean {
        return [GroupByTypes.JobNumber, GroupByTypes.WorkOrderNumber].includes(this.groupBy as GroupByTypes)
      },
      resourceFieldsByGroupKey() {
        const groupTypeMap: Record<string, string> = {
          [GroupByTypes.JobNumber]: 'job',
          [GroupByTypes.WorkOrderNumber]: 'work_order',
          [GroupByTypes.JournalNumber]: 'journal_name',
          [GroupByTypes.GLAccount]: 'account_number',
          [GroupByTypes.EqpMaintenanceCode]: 'maintenance_code',
          default: '',
        }

        return groupTypeMap[this.groupBy] || groupTypeMap.default
      },
      subtotalHeader() {
        const groupTypeMap: Record<string, string> = {
          [GroupByTypes.EquipmentCode]: 'Equipment',
          [GroupByTypes.ClassCode]: 'Class',
          [GroupByTypes.JobNumber]: 'Job',
          [GroupByTypes.WorkOrderNumber]: 'Work Order',
          [GroupByTypes.JournalNumber]: 'Journal',
          [GroupByTypes.GLAccount]: 'G/L Account',
          [GroupByTypes.EqpMaintenanceCode]: 'Maintenance Code',
        }
        return `${this.$t('Summary Totals For')} ${groupTypeMap[this.groupBy]}`
      },
    },
    methods: {
      formattedText,
      getSourceComponent,
      getRelatedBusinessLink,
      getAdditionalSourceLink,
      composeRows(data: Record<string, any>) {

        if (this.groupByEquipment) {
          this.mapEquipmentGroupRows(data, null)
          //@ts-ignore
          this.rows.push({
            htmlData: this.grandTotals,
          })
          return
        }

        if (this.groupByClassCode) {
          return this.mapClassGroupRows(data)
        }

        this.mapForAnotherGroupsRows(data)
      },
      mapEquipmentGroupRows(data: Record<string, any>, groupClass: Record<string, any> | null) {
        data.forEach((group: Record<string, any>) => {
          const {equipment, transaction_types, equipment_class} = group
          const equipmentClass = equipment_class || groupClass
          //@ts-ignore
          this.rows.push({
            header: {
              equipment,
              equipmentClass,
            },
          })

          const types = Object.values(transaction_types)
          types.forEach((transactionType) => {
            //@ts-ignore
            if (!transactionType?.length) {
              this.handleEmptyTransactionType(this.rows, transactionType)
            } else {
              this.handleNonEmptyTransactionType(this.rows, transactionType)
            }
          });
        })
      },
      handleEmptyTransactionType(rows: any[], transactionType: any) {
        rows.push(...transactionType?.items)
        if (transactionType?.items?.length > 1) {
          const subtotals = [{
            ...transactionType?.totals
          }]
          rows.push({
            subtotals,
          });
        }
      },
      handleNonEmptyTransactionType(rows: any[], transactionType: any) {
        transactionType?.forEach((type: Record<string, any>) => {
          rows.push(...type?.items)
          const subtotals = [{
            ...type?.totals
          }]
          rows.push({
            subtotals,
          });
        });
      },
      mapClassGroupRows(data: Record<string, any>) {
        data.forEach((groupObject: Record<string, any>) => {
          const {equipment_class: equipmentClass} = groupObject
          if (equipmentClass) {
            //@ts-ignore
            this.rows.push({
              header: {
                equipmentClass,
                is_equipment_class: true,
              },
            })
          }

          this.mapEquipmentGroupRows(groupObject.items, equipmentClass)

          if (equipmentClass) {
            //@ts-ignore
            this.rows.push({
              subtotals: {
                header: equipmentClass
              }
            })
          }

          const subtotals = formatSubtotalForGroup(groupObject)
          //@ts-ignore
          this.rows.push({
            subtotals,
          })

        })
        //@ts-ignore
        this.rows.push({
          htmlData: this.grandTotals,
        })
      },
      mapForAnotherGroupsRows(data: Record<string, any>) {
        const groupKey = this.resourceFieldsByGroupKey
        if (!groupKey) {
          return this.rows = []
        }

        data.forEach((group: Record<string, any>) => {
          const header = group[groupKey]
          if (header) {
            //@ts-ignore
            this.rows.push({
              header,
            })
          }

          if (this.groupByJobOrWorkOrder) {
            group?.items?.forEach((item: Record<string, any>) => {
              const {equipment} = item
              //@ts-ignore
              this.rows.push({
                header: {
                  equipment,
                  is_equipment: true,
                },
              })

              const types = Object.values(item.transaction_types)
              types.forEach(type => {
                //@ts-ignore
                this.rows.push(...type?.items)
              })

            })
          } else {
            //@ts-ignore
            this.rows.push(...group?.items)
          }

          if (header) {
            let subtotals = {
              header: header,
              ...group?.totals,
            }
            //@ts-ignore
            this.rows.push({
              subtotals,
            })
          }

          const subtotals = formatSubtotalForGroup(group)
          //@ts-ignore
          this.rows.push({
            subtotals,
          });
        })
        //@ts-ignore
        this.rows.push({
          htmlData: this.grandTotals,
        })
      },
      getGroupHeaderLink(header: Record<string, any>) {
        const linksByGroupKey: Record<string, string> = {
          [GroupByTypes.EquipmentCode]: `/equipment/equipment/${header.id}/view`,
          [GroupByTypes.ClassCode]: `/equipment/settings/classes/${header.id}/view`,
          [GroupByTypes.JobNumber]: `/job-costing/jobs/${header.id}/line-items`,
          [GroupByTypes.WorkOrderNumber]: `/service-billing/work-orders/${header.id}/view`,
          [GroupByTypes.EqpMaintenanceCode]: `/equipment/maintenance-codes?id=${header.id}`,
          default: '',
        }

        return linksByGroupKey[this.groupBy] || linksByGroupKey.default
      },
    },
  })
</script>
<style lang="scss">
  .equipment-history-listing-report-table {
    .grand-total-row {
      > td {
        @apply border-none;
        &:last-child {
          padding-inline: 12px;
        }
      }
    }

    .summary-row > td {
      border: none !important;
    }
  }
</style>
