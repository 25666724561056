<template>
  <div class="p-4">
    <AgDataTable
      url="/restify/time-off-activities"
      :url-params="urlParams"
      :columns="columns"
      domLayout="autoHeight"
    >
      <template #payroll_batch_id="{row}">
        <div v-if="row.attributes?.meta?.payroll_batch_id" class="flex items-center w-full justify-between space-x-2">
          <router-link :to="`/payroll/batches/${row.attributes?.meta.payroll_batch_id}`">
            {{ $formatDate(row.attributes?.meta.payment_date) }}
          </router-link>
        </div>
      </template>
      <template #activity="{row}">
        <SettingsActivityLogRows :row="row"/>
      </template>
    </AgDataTable>
  </div>
</template>
<script>
import SettingsActivityLogRows from "@/modules/common/components/SettingsActivityLogRows.vue";
import startCase from "lodash/startCase";

export default {
  components: {
    SettingsActivityLogRows
  },
  computed: {
    urlParams() {
      const { employee_id, time_off_policy_id } = this.params?.data?.attributes || {}
      return {
        employee_id,
        time_off_policy_id,
      }
    },
    columns() {
      return [
        {
          headerName: this.$t('Category'),
          field: 'attributes.category',
          valueFormatter: params => {
            return startCase(params.value)
          },
          minWidth: 100,
          maxWidth: 200,
        },
        {
          headerName: this.$t('Event'),
          field: 'attributes.event',
          valueFormatter: params => {
            return startCase(params.value)
          },
          minWidth: 150,
          maxWidth: 250
        },
        {
          headerName: this.$t('Payment Number'),
          field: 'attributes.meta.payment_number',
          align: 'right',
          maxWidth: 160,
        },
        {
          headerName: this.$t('Payment Date'),
          field: 'attributes.meta.payment_date',
          align: 'center',
          component: 'FormattedDate',
          maxWidth: 160,
        },
        {
          headerName: 'Activity',
          field: 'activity',
          autoHeight: true,
          minWidth: 200,
        },
      ]
    }
  }
}
</script>
