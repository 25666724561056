<template>
  <div>
    <div class="flex justify-between w-full leading-4">
      <EmployeeLink :id="employeeId"/>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    data() {
      return this.params.node?.allLeafChildren[0]?.data || {}
    },
    timeCard() {
      return this.data?.relationships?.timecard?.attributes || {}
    },
    employeeId() {
      return this.timeCard?.employee_id
    },
    level() {
      return this.params.node.level
    }
  }
}
</script>
