<template>
  <div>
    <div class="flex justify-between space-x-2">
      <div class="mb-2">
        <p class="tip my-2 ml-2">
          Adjust timecard batch entries. Only subtrades can be adjusted.
          <br>
          This will not have an impact on the amounts or taxes. It will only affect the certified payroll report.
        </p>
        <div class="flex space-x-2 items-center text-sm text-gray-500 ml-2">
          <div class="w-4 h-4 rounded bg-blue-200"></div>
          <div>{{ $t('Adjusted rows') }}</div>
        </div>
      </div>

      <div>
        <BaseButton
            :disabled="!hasUpdates"
            :loading="loading"
            @click="adjustEntries"
        >
          {{ $t('Adjust Timecard Entries') }}
        </BaseButton>
      </div>
    </div>
    <AgDataTable
        :url="`/restify/timecard-entries`"
        :url-params="urlParams"
        v-bind="tableProps"
        :columns="tableColumns"
        :show-cells-legend="true"
        :groupDefaultExpanded="-1"
        :groupIncludeFooter="true"
        :groupRowRendererParams="groupRowRendererParams"
        :compact="true"
        :transform-data="transformData"
        :get-row-class="getRowClass"
        groupDisplayType="groupRows"
    >
    </AgDataTable>
  </div>
</template>
<script>
import { editableTableProps } from "@/components/ag-grid/tableUtils";
import { getCostCenterRowDefaults, getTimeCardBatchDays } from "@/modules/payroll/utils/timeCardUtils";
import TimecardAdjustEntriesGroupRow from "@/modules/payroll/components/timecard/TimecardAdjustEntriesGroupRow.vue";
import { cellClasses, requiredValueSetter } from "@/components/ag-grid/columnUtils";
import { additionalSourceCol, costCenterCol, sourceCol, typeCol } from "@/components/ag-grid/columns/costCenterColumns";
import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";
import cloneDeep from "lodash/cloneDeep";
import axios from "axios";

export default {
  components: {
    TimecardAdjustEntriesGroupRow,
  },
  data() {
    return {
      entriesToUpdate: {},
      loading: false,
      tableProps: {
        ...editableTableProps,
        authorizeToCopyLastRow: false,
        addRowOnTab: false,
        defaultFilters: false,
      },
      initialData: [],
    }
  },
  computed: {
    urlParams() {
      return {
        timecard_batch_id: this.$route.params.id,
        related: 'timecard'
      }
    },
    timeCardBatch() {
      return this.$store.state.payroll.currentTimecardBatch
    },
    days() {
      return getTimeCardBatchDays(this.timeCardBatch.period_end_date)
    },
    tableColumns() {
      return [
        {
          headerName: this.$t('Employee'),
          field: 'relationships.timecard.attributes.employee_id',
          component: 'EmployeeLink',
          rowGroup: true,
          hide: true,
        },
        {
          field: 'date',
          minWidth: 100,
          maxWidth: 150,
          valueFormatter: params => {
            return this.days.find(day => day.value.startsWith(params.value))?.label
          },
          cellClass: cellClasses.ReadOnly,
        },
        {
          ...costCenterCol,
          cellClass: cellClasses.ReadOnly,
          editable: false,
        },
        {
          ...sourceCol,
          cellClass: cellClasses.ReadOnly,
          editable: false,
        },
        {
          ...typeCol,
          cellClass: cellClasses.ReadOnly,
          editable: false,
        },
        {
          ...additionalSourceCol(),
          cellClass: cellClasses.ReadOnly,
          editable: false,
        },
        {
          field: 'regular_hours',
          headerName: 'Regular Hours',
          minWidth: 50,
          maxWidth: 80,
          aggFunc: 'sum',
          cellClass: cellClasses.ReadOnly,
        },
        {
          field: 'overtime_hours',
          headerName: 'Overtime Hours',
          minWidth: 50,
          maxWidth: 80,
          aggFunc: 'sum',
          cellClass: cellClasses.ReadOnly,
        },
        {
          field: 'premium_hours',
          headerName: 'Premium Hours',
          minWidth: 50,
          maxWidth: 80,
          aggFunc: 'sum',
          cellClass: cellClasses.ReadOnly,
        },
        {
          field: 'craft_code_id',
          headerName: 'Craft Code',
          valueFormatter: params => {
            const craft = this.$store.getters['globalLists/getResourceById'](this.$globalResources.CraftCodes, params.value)
            if (craft?.id) {
              return `${craft?.code}/${craft?.level}`
            }
            return ''
          },
          minWidth: 50,
          maxWidth: 80,
          cellClass: cellClasses.ReadOnly,
        },
        {
          field: 'sub_trade_id',
          headerName: 'Adjusted Sub Trade',
          valueFormatter: params => {
            const subTrade = this.$store.getters['globalLists/getResourceById'](this.$globalResources.SubTrades, params.value)
            return subTrade?.description || ''
          },
          editable: true,
          cellEditor: this.$cellEditors.GlobalResourceSelect,
          cellEditorParams: {
            resourceName: this.$globalResources.SubTrades,
            valueKey: 'id',
            labelKey: 'description'
          },
          valueSetter: params => {
            const { data, newValue } = params
            this.updateEntries({
              row: data,
              field: 'sub_trade_id',
              fieldValue: newValue,
            })
            data.sub_trade_id = newValue
            return true
          }
        }
      ]
    },
    hasUpdates() {
      return Object.values(this.entriesToUpdate).length > 0
    },
    groupRowRendererParams() {
      return {
        innerRenderer: 'TimecardAdjustEntriesGroupRow',
        suppressCount: true,
      }
    },
    timeCardBatchId() {
      return this.$route.params.id
    },
  },
  methods: {
    getRowClass(params) {
      const data = params.data
      const wasAdjusted = data?.meta?.adjustments?.sub_trade
      if (this.entriesToUpdate[data?.id] || wasAdjusted) {
        return '!bg-blue-50'
      }
    },
    transformData(data) {
      this.initialData = cloneDeep(data)
      return data.map(row => {
        return {
          id: row.id,
          ...row.attributes,
          relationships: row.relationships,
        }
      })
    },
    updateEntries({ row, field, fieldValue }) {
      const key = row.id
      if (this.entriesToUpdate[key]) {
        this.entriesToUpdate[key][field] = fieldValue
        return
      }
      this.$set(this.entriesToUpdate, key, row)
    },
    mapEntriesToRequest() {
      const timeCardEntries = cloneDeep(this.initialData)
      return Object.values(this.entriesToUpdate).map(row => {
        const originalEntry = timeCardEntries.find(entry => entry.id === row.id)
        return {
          id: row.id,
          new_sub_trade_id: row.sub_trade_id,
          old_sub_trade_id: originalEntry?.attributes?.sub_trade_id,
        }
      })
    },
    async adjustEntries() {
      try {
        const confirmed = await this.$confirm({
          title: this.$t('Adjust Timecards ?'),
          description: this.$t('The subtrades will be adjusted for the timcard batch.'),
          buttonText: this.$t('Adjust Timecards'),
        })
        if (!confirmed) {
          return
        }
        this.loading = true
        const entries = this.mapEntriesToRequest()
        await axios.post(`/restify/timecard-batches/${this.timeCardBatchId}/actions?action=adjust-sub-trades`, {
          entries,
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
