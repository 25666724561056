<template>
  <div class="truncate">
    <a v-if="getFormattedAddress && !editableCell"
       :href="getLocationLink"
       target="_blank"
       class="hover:text-primary-900 hover:underline cursor-pointer"
    >
      <address :title="getFormattedAddress"
               class="address-field truncate"
      >
        {{ getFormattedAddress }}
      </address>
    </a>
    <span v-else>
      {{ getFormattedAddress }}
    </span>
  </div>
</template>
<script>
  import { formattedAddress, locationLink } from '@/utils/utils'
  import store from '@/store'
  import isEmpty from "lodash/isEmpty";

  export default {
    props: {
      id: String,
      column: {
        type: Object,
        default: () => ({}),
      },
      row: {
        type: Object,
        default: () => ({})
      },
      editableCell: Boolean,
    },
    computed: {
      getValue() {
        if (this.id) {
          // * Applied to company address for now
          const address = this.$store.getters['globalLists/getResourceById'](this.$globalResources.CompanyAddresses, this.id)
          if (isEmpty(address)) {
            return this.get(this.row, 'relationships.address', {})
          }
          return address
        }

        return this.column.prop ? this.get(this.row, this.column.prop, '') : this.row
      },
      getFormattedAddress() {
        const address = this.getValue
        if (!address) {
          return ''
        }
        return formattedAddress(address)
      },
      getLocationLink() {
        const address = this.getValue
        if (!address) {
          return ''
        }
        return locationLink(address)
      },
    },
  }
</script>
