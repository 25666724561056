import EmptyLayout from "@/components/common/EmptyLayout";
import Bids from "@/modules/estimates/pages/bids";
import BidItems from "@/modules/estimates/pages/bid-items";
import CrewLaborRates from "@/modules/estimates/pages/crew-labor-rates";
import WorkItems from "@/modules/estimates/pages/work-items";
import Formulas from "@/modules/estimates/pages/formulas";
import MaterialCodes from "@/modules/estimates/pages/material-codes";
import PhaseCost from "@/modules/estimates/pages/phase-cost";
import SettingsLayout from "@/modules/estimates/layout/SettingsLayout";

export default [
  {
    path: '/estimates',
    name: 'Estimates',
    component: EmptyLayout,
    redirect: '/estimates/bids',
    meta: {
      permissions: ['bids_show'],
    },
    children: [
      {
        path: 'bids',
        name: 'Bids',
        title: 'Bids',
        description: 'Setup Bid Descriptions',
        meta: {
          permissions: ['bids_show'],
        },
        component: Bids,
      },
      {
        path: 'bid-items',
        name: 'Bid Items',
        title: 'Bid Items',
        description: 'Setup Bid Item Descriptions',
        meta: {
          permissions: ['bid_items_show'],
        },
        component: BidItems,
      },
      {
        path: 'crew-labor-rates',
        name: 'Crew Labor Rates',
        title: 'Crew Labor Rates',
        description: 'Setup Crew Labor Rates',
        meta: {
          permissions: ['crew_labor_rate_show'],
        },
        component: CrewLaborRates,
      },
      {
        path: 'work-items',
        name: 'Work Items',
        title: 'Work Items',
        description: 'Setup Work Item Values',
        meta: {
          permissions: ['work_items_show'],
        },
        component: WorkItems,
      },
      {
        path: 'formulas',
        name: 'Estimate Formulas',
        title: 'Estimate Formulas',
        description: 'Setup Estimate Formulas',
        meta: {
          permissions: ['estimate_formulas_show'],
        },
        component: Formulas,
      },
      {
        path: 'material-codes',
        name: 'Material Codes',
        title: 'Material Codes',
        description: 'Setup Material Codes',
        meta: {
          permissions: ['material_code_show'],
        },
        component: MaterialCodes,
      },
      {
        path: 'phase-cost',
        name: 'Phase Cost Codes',
        title: 'Phase Cost Codes',
        description: 'Setup Phase Cost Code Values',
        meta: {
          permissions: ['estimate_codes_show'],
        },
        component: PhaseCost,
      },
      {
        path: 'settings',
        name: 'Work Items Settings',
        redirect: '/estimates/settings/work-items',
        meta: {
          permissions: ['estimate_codes_show'],
        },
        component: SettingsLayout,
        children: [

        ],
      },
    ],
  },
]
