<template>
  <div>
    <CostForBillingFilters @save="onFilter"/>
    <CostForBillingForm v-if="filters" class="mt-4" :filters="filters"/>
  </div>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import CostForBillingFilters from "@/modules/service-billing/components/cost-for-billing/CostForBillingFilters.vue";
import CostForBillingForm from "@/modules/service-billing/components/cost-for-billing/CostForBillingForm.vue";

const filters = ref(null)

async function onFilter(newFilters: any = {}) {
  setTimeout(() => {
    filters.value = {
      ...newFilters,
    }
  }, 300)
}
</script>
