<template>
  <EntitySelect
    v-bind="$attrs"
    v-on="$listeners"
    :url="url"
    :url-params="allUrlParams"
    :transformData="transformData"
    :dataFilters="dataFilters"
    :name="$attrs.name || $t('Address')"
    :placeholder="$attrs.placeholder || $t('Select Address')"
    :label="$attrs.label !== undefined ? $attrs.label : $t('Address')"
    :add-entity="true"
    :id="$attrs.id || 'address-select'"
    :table-columns="tableColumns"
    :value-key="valueKey"
    :label-key="labelKey"
    :label-format="labelFormat"
    :extra-options="extraOptions"
    :form-params="{
      entity: formEntity,
      requireEntity: true,
      ...formParams,
    }"
    :addLabel="$t('Add Address')"
  />
</template>
<script>
import { addressTypes } from "@/enum/enums";
import { RestifyResources } from "@/components/form/util";

export default {
  name: 'AddressSelect',
  props: {
    addressType: {
      type: String,
      default: '',
    },
    addressableId: String,
    valueKey: {
      type: String,
      default: 'id',
    },
    labelKey: {
      type: String,
      default: 'name',
    },
    includeDefaultAddress: {
      type: Boolean,
      default: false,
    },
    formParams: {
      type: Object,
      default: () => ({}),
    },
    showActiveColumn: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dataFilters: {
        showActive: true,
      }
    }
  },
  computed: {
    url() {
      return '/restify/addresses'
    },
    urlParams() {
      return {
        type: this.addressType,
        addressable_id: this.addressableId,
      }
    },
    allUrlParams() {
      if (!this.showActiveColumn) {
        return this.urlParams
      }

      return {
        ...this.urlParams,
        active: this.dataFilters.showActive ? true : undefined,
      }
    },
    tableColumns() {
      const columns = [
        {
          name: this.$t('Name'),
          prop: 'attributes.name',
          minWidth: 120,
          maxWidth: 120,
        },
        {
          name: this.$t('Type'),
          prop: 'attributes.type',
          component: this.$tableColumns.Status,
          minWidth: 120,
          maxWidth: 120,
          showIf: () => !this.addressType,
        },
        {
          name: this.$t('Location'),
          component: this.$tableColumns.LocationLink,
          minWidth: 300,
          maxWidth: 300,
        },
        {
          minWidth: 120,
          maxWidth: 140,
          name: this.$t('Active'),
          prop: 'attributes.active',
          component: 'Status',
          filter: {
            type: 'switch',
            prop: 'showActive',
            tooltip: this.$t('Only show active addresses'),
            onChange: (value) => {
              this.dataFilters.showActive = value
            },
          },
          showIf: () => this.showActiveColumn,
        },
      ]

      return columns.filter(col => !col.showIf || col.showIf())
    },
    formEntity() {
      const entityMap = {
        [addressTypes.Job]: RestifyResources.Jobs,
        [addressTypes.Warehouse]: RestifyResources.Companies,
        [addressTypes.Home]: RestifyResources.Employees,
      }
      return entityMap[this.addressType]
    },
    warehouseAddresses() {
      return this.$store.getters['company/getWarehouseAddresses'] || []
    },
    extraOptions() {
      if (!this.includeDefaultAddress || this.addressType === addressTypes.Warehouse) {
        return []
      }

      let warehouseAddresses = this.warehouseAddresses

      if (this.showActiveColumn && this.dataFilters.showActive) {
        warehouseAddresses = warehouseAddresses.filter(address => address.attributes.active)
      }

      return warehouseAddresses.map(address => ({
        ...address,
        isInactive: !address.attributes.active,
      }))
    }
  },
  methods: {
    transformData(data) {
      return data.map(address => ({
        ...address,
        isInactive: !address.attributes.active,
      }))
    },
    labelFormat(address) {
      const { name, type } = address.attributes
      return name || type
    },
  },
}
</script>
