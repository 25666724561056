<template>
  <div>
    <purchase-order-select
        v-bind="$attrs"
        :label="$t('P.O. Number')"
        :placeholder="$t('P.O. Number')"
        :add-entity="false"
        :disabled="poSelectDisabled"
        :key="$attrs.value"
        :show-amounts="true"
        id="purchase_order_id"
        @entity-change="onChangePurchaseOrder"
        @input="onInput"
        clearable
    >
      <template v-if="$attrs.value"
                #label>
        <span class="flex items center justify-between">
        {{ $t('P.O. Number') }}
        <base-tooltip :content="$t('See P.O. line items')">
          <span class="mr-2"
                @click="previewPoItems = true">
            <IconView class="w-4 h-4 cursor-pointer hover:text-gray-700"/>
          </span>
        </base-tooltip>
        </span>
      </template>
    </purchase-order-select>
    <base-form-dialog
        v-if="showDialog"
        :title="$t('Select Purchase Order Line Items')"
        :open.sync="showDialog"
        size="xl"
        @close="onCloseDialog">
      <base-form
          :save-text="$t('Accept Checked Items')"
          layout="modal"
          @submit="onSubmit"
      >
        <purchase-order-entries
            :data="purchaseOrder"
            class="col-span-6"
            ref="poItemsTable"
        />
      </base-form>
    </base-form-dialog>
    <base-form-dialog
        v-if="previewPoItems"
        :title="$t('Purchase Order Line Items')"
        :open.sync="previewPoItems"
        size="xl"
    >
      <purchase-order-entries
          :data="purchaseOrder"
          :show-select-column="false"
          class="p-4"
          ref="poItemsTable"
      />
    </base-form-dialog>
  </div>
</template>
<script>
import PurchaseOrderEntries from '@/modules/purchasing-inventory/components/purchase-orders/PurchaseOrderEntries'
import cloneDeep from "lodash/cloneDeep";

export default {
    components: {
      PurchaseOrderEntries,
    },
    props: {
      vendor: {
        type: Object,
        default: () => ({}),
      },
      invoice: {
        type: Object,
        default: () => ({}),
      }
    },
    data() {
      return {
        previewPoItems: '',
        showDialog: false,
        purchaseOrder: {},
        selectedLineItemIds: [],
        inputEventTriggered: false,
      }
    },
    computed: {
      purchaseOrderOptions() {
        return this.$store.getters['globalLists/getResourceList'](this.$globalResources.PurchaseOrders)
      },
      poSelectDisabled() {
        return !this.purchaseOrderOptions.filter(po => po.vendor_id === this.vendor.id).length
      },
    },
    methods: {
      onCloseDialog() {
        if (!this.selectedLineItemIds.length) {
          this.$emit('input', null)
        }

        this.showDialog = false
      },
      onInput(value) {
        this.inputEventTriggered = !this.inputEventTriggered
        if (this.inputEventTriggered) {
          return
        }
        if (!value) {
          return this.onClearPurchaseOrder((emit) => {

            if (emit) {
              this.$emit('input', null)
            }
          })
        }

        this.$emit('input', value)
      },
      async compareInvoiceGrossToPOAmount(purchaseOrder) {
        const poAmount = purchaseOrder?.extended_amount
        const grossAmount = this.invoice?.gross_amount
        if (poAmount >= grossAmount) {
          return true
        }
        const confirmed = await this.$confirm({
          title: this.$t('Gross amount exceeds purchase order amount'),
          description: this.$t(`The gross amount of the invoice exceeds the amount of the selected purchase order. Purchase Order Amount: <b>${this.$formatPrice(poAmount)}.</b> Invoice Gross Amount: <b>${this.$formatPrice(grossAmount)}.</b> Are you sure you want to continue?`),
          buttonText: this.$t('Change Gross Amount'),
        })
        if (confirmed) {
          this.invoice.gross_amount = purchaseOrder.extended_amount
        }
        return confirmed
      },
      async onChangePurchaseOrder(purchaseOrder) {
        if (!purchaseOrder) {
          return
        }
        const validated = await this.compareInvoiceGrossToPOAmount(purchaseOrder)
        if (!validated) {
          return
        }

        this.purchaseOrder = purchaseOrder
        this.showDialog = true
      },
      // * Callback for update value of purchase order in parent component
      async onClearPurchaseOrder(callback) {
        if (!this.selectedLineItemIds.length) {
          return callback(true)
        }

        const confirmed = await this.$confirm({
          title: this.$t('Detach purchase order?'),
          description: this.$t('Are you sure you want to detach the purchase order? This will remove purchase order line items from the invoice.'),
          buttonText: this.$t('Confirm'),
        })

        if (!confirmed) {
          return callback(false)
        }

        this.$emit('on-clear-items', this.selectedLineItemIds)
        this.selectedLineItemIds = []
        return callback(true)
      },
      async onSubmit() {
        const lineItems = this.$refs?.poItemsTable?.selectedRows || []
        if (!lineItems.length) {
          return
        }

        const valid = await this.validateItems(lineItems)
        if (!valid) {
          return
        }

        await this.tryAddInvoiceLineItems(lineItems)
      },
      async validateItems(lineItems) {
        const hasInvoicedItems = lineItems.filter(item => item.attributes.invoiced_amount >= item.attributes.amount).length > 0
        if (!hasInvoicedItems) {
          return true
        }
        return this.$confirm({
          title: this.$t('Invoiced items'),
          description: this.$t(`Some items have already been fully invoiced. You can still add them to the invoice, but the items will not be tracked against the Purchase order. Are you sure you want to add them to the invoice?`),
          buttonText: this.$t('Confirm'),
        });
      },
      async tryAddInvoiceLineItems(lineItems) {
        if (!this.selectedLineItemIds.length) {
          return this.addInvoiceLineItems(lineItems)
        }

        const confirmed = await this.$confirm({
          title: this.$t('Change purchase order?'),
          description: this.$t('Are you sure you want to change the purchase order? This will remove purchase order line items from the invoice.'),
          buttonText: this.$t('Confirm'),
        })

        if (!confirmed) {
          return
        }

        this.addInvoiceLineItems(lineItems)
      },
      addInvoiceLineItems(lineItems) {
        this.selectedLineItemIds = lineItems.map(lineItem => lineItem.id)
        const items = cloneDeep(lineItems)
        this.$emit('on-select-items', items)
        this.showDialog = false
      },
    },
  }
</script>
