<template>
  <base-data-table
      v-bind="defaultAttributes"
      :columns="columns"
      :data="getData"
      :total-rows="rowsLength"
      :transform-filters="transformFilters"
      @force-pagination="forcePagination"
  >

    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template #header="{row}">
      <template v-if="renderJournalColumn">
        <div class="flex">
          <div class="w-1/3 flex" v-if="groupBySubaccount">
            <span class="mr-3">{{ $t('Sub-Account No.') }}</span>
            <SubAccountLink
                v-if="get(row, 'header.subaccount')"
                :data="row.header.subaccount"
            />
          </div>
          <div class="w-2/3 flex">
            <span class="mr-3">{{ $t('Account No.') }}</span>
            <AccountLink
                v-if="get(row, 'header.account')"
                :data="row.header.account"
            />
          </div>
        </div>
      </template>

      <template v-else-if="get(row,'header.journal.id')">
        <div class="flex space-x-2 items-center">
          <span class="mr-3">{{ $t('Journal No.') }}</span>
          <JournalLink :data="row.header.journal"/>
        </div>
      </template>

      <span v-else></span>

    </template>

    <template #business="{row}">
      <component
          v-if="getRelatedBusinessLink(row)"
          :is="getRelatedBusinessLink(row)"
          :data="row?.business"
          :show-preview="false"
          :show-name="false"
          :show-description="false"
      />

      <span v-else></span>
    </template>

    <template #job="{row}">
      <JobLink
          v-if="isJobCostCenter(row)"
          :data="composeJobData(row.source)"
          :show-description="false"
          :show-preview="false"
      />
      <span v-else></span>
    </template>

    <template #subaccount="{row}">
      <SubAccountLink v-if="row.subaccount?.number" :number="row.subaccount?.number"/>
    </template>

    <template #account="{row}">
      <AccountLink :data="row.account" :show-description="false"/>
    </template>

    <template #addlSource="{row}">
      <AddlSourceLink
          v-if="row.addlSource?.id"
          :id="row.addlSource?.id"
          :data="getAddlSourceData(row)"
      />
    </template>

    <template #reference_number="{row}">
      <ReferenceLink
          v-if="hasMetaData(row)"
          :data="row.transaction.meta"
          :id="row.journal?.id"
          :reference-no="row.transaction.reference_number"
      />
      <span v-else></span>
    </template>

    <template #source="{row}">
      <router-link
          v-if="!isJobCostCenter(row)"
          :to="getSourceLink(row)"
      >
        {{ row.source?.code || '' }}
      </router-link>

      <span v-else></span>
    </template>

    <template #period_fiscal_year="{row}">
      {{ row.transaction.period + ' ' + row.transaction.fiscal_year }}
    </template>

    <template #subtotal="{subtotal}">
      <td colspan="12" align="right">
          <span
              v-if="subtotal.posted_at"
              class="mx-8"
          >
          {{ $tc(`( Posted on ${$formatDate(subtotal.posted_at, '', true)} )`) }}
        </span>
        {{ getSubtotalTitle }}
      </td>
      <td class="top-border" align="right">
        {{ $formatPrice(subtotal.total) }}
      </td>
    </template>

  </base-data-table>
</template>
<script>
  import { getRelatedBusinessLink } from '@/components/links/util'
  import { costCenterTypes } from '@/components/grid-table/utils/cost-center'
  import { getSourceLink } from '@/components/grid-table/utils/cost-center-cell'
  import { historySelectionGroupBy } from '@/modules/ledger/components/reports/util'
  import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'
  import { dateTypes } from "@/plugins/dateFormatPlugin";
  import AddlSourceLink from "@/components/links/AddlSourceLink.vue";

  export default {
    components: {
      AddlSourceLink
    },
    extends: ReportTableWrapper,
    props: {
      groupBy: {
        required: true,
        type: String,
        default: historySelectionGroupBy.Account,
      },
      totalsOnly: [Boolean, Number],
      transformFilters: Function,
    },
    data() {
      return {
        dateTypes
      }
    },
    computed: {
      columns() {
        const columns = [
          {
            label: this.$t('Sub <br>Acct'),
            prop: 'subaccount',
            minWidth: 40,
            maxWidth: 50,
            component: 'SubAccountLink',
          },
          {
            label: this.$t('Fiscal <br>P/Year'),
            prop: 'period_fiscal_year',
            minWidth: 60,
            maxWidth: 100,
          },
          {
            label: this.$t('Account'),
            prop: 'account',
            minWidth: 40,
            maxWidth: 50,
            component: 'AccountLink',
            renderIf: () => !this.renderJournalColumn,
            params: {
              showDescription: false,
            }
          },
          {
            label: this.$t('Journal'),
            prop: 'journal.name',
            component: 'EntityLink',
            redirectTo: '/ledger/journal/{ID}/view',
            entityKey: 'journal.id',
            minWidth: 50,
            maxWidth: 80,
            renderIf: () => this.renderJournalColumn,
          },
          {
            label: this.$t('Ref <br> No.'),
            prop: 'transaction.reference_number',
            minWidth: 40,
            maxWidth: 80,
          },
          {
            label: this.$t('Ref <br> Date'),
            prop: 'transaction.reference_date',
            component: 'FormattedDate',
            minWidth: 80,
            maxWidth: 100,
          },
          {
            label: this.$t('Vend/Cust <br> Employee'),
            prop: 'business',
            minWidth: 60,
            maxWidth: 90,
          },
          {
            label: this.$t('Description'),
            prop: 'transaction.description',
            minWidth: 100,
            maxWidth: 180,
          },
          {
            label: this.$t('Job'),
            prop: 'job',
            minWidth: 60,
            maxWidth: 90,
          },
          {
            label: this.$t('Line Item'),
            prop: 'addlSource',
            component: 'AddlSourceLink',
            minWidth: 80,
            maxWidth: 120,
          },
          {
            label: this.$t('Type'),
            prop: 'type.abbr',
            align: 'center',
            minWidth: 40,
            maxWidth: 60,
          },
          {
            label: this.$t('MTL/EQP <br> WRK ORD'),
            prop: 'source',
            minWidth: 50,
            maxWidth: 80,
          },
          {
            label: this.$t('QTY <br> HOURS'),
            prop: 'transaction.meta.quantity',
            minWidth: 40,
            maxWidth: 60,
          },
          {
            label: this.$t('Amount'),
            prop: 'transaction.amount',
            component: 'FormattedPrice',
            align: 'right',
            minWidth: 100,
            summary: () => this.$formatPrice(this.data.grand_totals.amount),
          },
        ]

        return columns.filter(column => column.renderIf ? column.renderIf() : true)
      },
      getSubtotalTitle() {
        const titleMap = {
          [historySelectionGroupBy.Account]: this.$t('Account Total:'),
          [historySelectionGroupBy.Journal]: this.$t('Journal Total:'),
          [historySelectionGroupBy.PostedDate]: this.$t('Journal Total:'),
          [historySelectionGroupBy.SubAccount]: this.$t('Sub-Account Total:'),
          [historySelectionGroupBy.FiscalPeriod]: this.$t('Account Total:'),
          default: '',
        }

        return titleMap[this.groupBy]
      },
      groupByAccount() {
        return this.groupBy === historySelectionGroupBy.Account
      },
      groupBySubaccount() {
        return this.groupBy === historySelectionGroupBy.SubAccount
      },
      groupByFiscalPeriod() {
        return this.groupBy === historySelectionGroupBy.FiscalPeriod
      },
      renderJournalColumn() {
        return this.groupByAccount || this.groupBySubaccount || this.groupByFiscalPeriod
      },
    },
    methods: {
      composeRows(data) {
        if (this.groupBySubaccount || this.groupByFiscalPeriod) {

          data.forEach(group => {

            group?.forEach(item => {
              let { transactions: transactions = [], subaccount, account } = item

              if (this.totalsOnly) {
                transactions = []
              }

              this.rows.push(
                  {
                    header: {
                      subaccount,
                      account,
                    },
                  },
                  ...transactions,
                  {
                    subtotal: {
                      total: item.totals.amount,
                    },
                  },
              )
            })
          })

          return this.rows
        }

        data.forEach(item => {
          let { transactions: transactions = [], account, journal } = item

          if (this.totalsOnly) {
            transactions = []
          }

          this.rows.push(
              {
                header: {
                  account,
                  journal,
                },
              },
              ...transactions,
              {
                subtotal: {
                  total: item.totals.amount,
                  posted_at: journal?.posted_at,
                },
              },
          )
        })

        return this.rows
      },
      getRelatedBusinessLink,
      isJobCostCenter(row) {
        return row.transaction.cost_center === costCenterTypes.Job
      },
      getSourceLink(row) {
        return getSourceLink({
          cost_center: row?.transaction?.cost_center,
          source_type: row?.source?.type,
          source_id: row.source?.id,
        })
      },
      hasMetaData(row) {
        return row.transaction.meta && Object.keys(row.transaction.meta)?.length
      },
      composeJobData(source) {
        if (!source) {
          return
        }
        source.number = source.code
        return source
      },
      getAddlSourceData(row) {
        return {
          id: row.addlSource?.id,
          attributes: {
            ...row.transaction,
            addl_source_id: row.addlSource?.id,
          },
          addlSource: row.addlSource,
        }
      }
    },
  }
</script>
