<template>
  <div class="import-data">
    <ImportForm v-bind="importSettings"
                @success="redirectToTimeCard"
    >
      <div class="col-span-6 md:col-span-1">
        <base-input :label="$t('Fiscal Year')">
          <template v-slot:container>
            <fiscal-year-select v-model="model.year"
                                class="w-full"
                                id="fiscal_year"
            />
          </template>
        </base-input>
      </div>

      <div class="col-span-6 md:col-span-2">
        <period-end-date-picker v-model="model.period_end_date"/>
      </div>
    </ImportForm>
  </div>
</template>
<script>
  import { colMappings, getInitialMapping, options } from "@/modules/payroll/utils/timecardImportColumns";
  import ImportForm from "@/modules/common/components/import/ImportForm";
  import PeriodEndDatePicker from "@/modules/payroll/components/timecard/PeriodEndDatePicker";

  export default {
    components: {
      ImportForm,
      PeriodEndDatePicker,
    },
    data() {
      return {
        model: {
          period_end_date: null,
          year: new Date().getFullYear(),
        },
        importSettings: {
          url: '/restify/timecard-batches/import',
          columnOptions: options,
          columnMappings: colMappings,
          initialMapping: getInitialMapping(options),
          type: 'timecard-import',
          transformRequestData: this.transformRequestData,
          resource: 'timecards',
          showOverwrite: false,
        },
      }
    },
    methods: {
      transformRequestData(formData) {
        formData.append('year', this.model.year);
        formData.append('period_end_date', this.model.period_end_date);
      },
      async redirectToTimeCard(data) {
        await this.$router.push('/payroll/timecard-batches/pending')
      },
    }
  }
</script>
