import {resourceStatuses} from "@/enum/enums";
import i18n from "@/i18n";

const CustomerStatus = {
  Active: 'active',
  Inactive: 'inactive',
  Collections: 'collections',
}
export const CustomerStatusOptions = [
  {
    label: i18n.t('Active'),
    value: CustomerStatus.Active,
  },
  {
    label: i18n.t('Inactive'),
    value: CustomerStatus.Inactive,
  },
  {
    label: i18n.t('Collections'),
    value: CustomerStatus.Collections,
  },
]
