<template>
  <div>
    <layout-tabs :items="filteredItems" class="-mt-2"/>
    <router-view/>
  </div>
</template>
<script>

  export default {
    data() {
      return {
        items: [
          {
            name: this.$t('Pending'),
            tooltip: this.$t('Timecards that were not posted yet'),
            path: `/payroll/timecard-batches/pending`,
            permission: 'timecards_show',
          },
          {
            name: this.$t('Posted'),
            tooltip: this.$t('Posted timecards'),
            path: `/payroll/timecard-batches/posted`,
            permission: 'timecards_show',
          },
          {
            name: this.$t('Voided'),
            tooltip: this.$t('Voided timecards'),
            path: `/payroll/timecard-batches/voided`,
            permission: 'timecards_show',
          },
          {
            name: this.$t('All'),
            tooltip: this.$t('All timecards'),
            path: `/payroll/timecard-batches/all`,
            permission: 'timecards_show',
          },
        ],
      }
    },
    computed: {
      filteredItems() {
        return this.items.filter(item => this.$can(item.permission))
      },
    },
  }
</script>
