<template>
  <base-form-dialog v-bind="$attrs"
                    v-on="$listeners"
                    :title="title"
                    :has-padding="true"
                    size="2xl"
                    class="check-modal"
  >
    <div class="prose flex flex-col w-full max-w-full">
      <div class="flex justify-between items-end w-full">
        <div>
          <h5 class="text-gray-700">{{ company.name || '' }}</h5>
          <div v-if="company.address_1" class="flex text-xs leading-5 text-cool-gray-500 font-medium">
            <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-cool-gray-400" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd"
                    d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
                    clip-rule="evenodd"></path>
            </svg>
            <span>{{ company.address_1 }}</span>
            <span>{{ company.city }}, {{ company.state }}</span>
          </div>
        </div>
        <div class="flex flex-col items-end">
          <h4>{{ payroll.payment_number }}</h4>
          <time class="whitespace-nowrap text-sm leading-5 text-cool-gray-500">
            {{ $formatDate(payroll.payment_date) }}
          </time>
        </div>
      </div>
      <hr>
      <div class="flex justify-between">
        <div class="flex w-full text-gray-700">
          <h5 class="text-gray-700">{{ $t('Paid Amount') }}</h5>
        </div>
        <div class="text-gray-900 font-semibold leading-normal">
          {{ $formatPrice(payroll.totals.net_pay) }}
        </div>
      </div>
      <hr>
      <template v-if="!ownCheck">
        <div class="flex justify-between">
          <div class="flex w-full text-sm leading-5 text-cool-gray-500 font-medium">
            <div>
              <span class="text-cool-gray-700 mb-2">{{ employee.name }}</span>
              <dd v-if="employee.address_1" class="flex items-center capitalize sm:mr-6 mt-1">
                <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-cool-gray-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd"
                        d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
                        clip-rule="evenodd"></path>
                </svg>
                {{ employee.address_1 }}, {{ employee.city }}, {{ employee.state }} {{ employee.zip_code }}
              </dd>
            </div>
          </div>
          <div>
            <span
                class="status-badge inline-flex items-center px-3 py-1 rounded-full text-xs font-medium leading-4 bg-yellow-100 text-yellow-800 capitalize">
              Non Negotiatable
            </span>
          </div>
        </div>
      </template>
      <div class="w-full mt-5">
        <div class="overflow-hidden sm:rounded-lg details-wrapper">
          <el-collapse>
            <el-collapse-item key="details"
                              :name="$t('Details')"
            >
              <template v-slot:title>
                <div>
                  <h4 class="font-medium collapse-title">
                    {{ $t('Direct Deposit Notification') }}
                  </h4>
                  <p class="mt-1 text-sm leading-5 text-gray-500">
                    {{ $t('Deposit details and check information.') }}
                  </p>
                </div>
              </template>
              <dl>
                <div class="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm leading-5 font-medium text-gray-500">
                    {{ $t('Employee') }}
                  </dt>
                  <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ employee.name }}
                  </dd>
                </div>
                <div class="bg-white px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm leading-5 font-medium text-gray-500">
                    {{ $t('Social Security #') }}
                  </dt>
                  <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ employee.masked_ssn || '' }}
                  </dd>
                </div>
                <div class="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm leading-5 font-medium text-gray-500">
                    {{ $t('Email') }}
                  </dt>
                  <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ employee.email || '' }}
                  </dd>
                </div>
                <div class="bg-white px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm leading-5 font-medium text-gray-500">
                    {{ $t('Regular Rate') }}
                  </dt>
                  <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ $formatPrice(labor.regular_rate) }}
                  </dd>
                </div>
                <div class="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm leading-5 font-medium text-gray-500">
                    {{ $t('Period Start Date') }}
                  </dt>
                  <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ $formatDate(payroll.start_date) }}
                  </dd>
                </div>
                <div class="bg-white px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm leading-5 font-medium text-gray-500">
                    {{ $t('Period End Date') }}
                  </dt>
                  <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ $formatDate(payroll.period_end_date) }}
                  </dd>
                </div>
                <div class="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm leading-5 font-medium text-gray-500">
                    {{ $t('Check Date') }}
                  </dt>
                  <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ $formatDate(payroll.payment_date) }}
                  </dd>
                </div>
                <div class="bg-white px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm leading-5 font-medium text-gray-500">
                    {{ $t('Check #') }}
                  </dt>
                  <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ payroll.payment_number }}
                  </dd>
                </div>
                <div class="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm leading-5 font-medium text-gray-500">
                    {{ $t('Net Pay') }}
                  </dt>
                  <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2 font-medium">
                    {{ $formatPrice(payroll.totals.net_pay) }}
                  </dd>
                </div>
              </dl>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </div>
    <PayrollLineItems
        :data="payrollLineItemsData"
        :show-year-to-date="true"
        :display-categories="lineItemCategories"
    />
  </base-form-dialog>
</template>
<script>

  import { payCategories } from "@/modules/payroll/components/timecard/util";
  import PayrollLineItems from "@/modules/payroll/components/payroll/PayrollLineItems";
  import i18n from "@/i18n";

  export default {
    components: {
      PayrollLineItems
    },
    props: {
      data: {
        type: Object,
        default: () => ({})
      },
      ownCheck: {
        type: Boolean,
      }
    },
    data() {
      return {
        lineItemCategories: [
          {
            name: i18n.t('Gross pay'),
            keys: [payCategories.GrossPay],
          },
          {
            name: i18n.t('Deductions'),
            keys: [payCategories.EmployeeTaxes, payCategories.Deductions],
          },
        ],
      }
    },
    computed: {
      currentPayrollBatchDetails() {
        return this.$store.state.payroll.currentPayrollBatchDetails;
      },
      payrollLineItemsData() {
        return this.currentPayrollBatchDetails.find(row => row.payroll.id === this.data.id)
      },
      employee() {
        return this.get(this.data, 'relationships.employee.attributes', {});
      },
      company() {
        return this.$currentCompany
      },
      entries() {
        return this.get(this.data, 'relationships.entries', []);
      },
      payroll() {
        return this.get(this.data, 'attributes', {});
      },
      labor() {
        return this.get(this.payroll, 'labor[0]', {})
      },
      title() {
        if (this.ownCheck) {
          return this.$t('Check details')
        }
        return this.$t(`Check information for ${this.employee.name}`)
      },
    },
  }
</script>
<style scoped>
  .check-modal .prose hr {
    @apply my-4;
  }

  .check-modal .status-badge {
    width: 130px;
  }

  .details-wrapper :deep(.el-collapse-item__header) {
    @apply h-auto;
  }

  .collapse-title {
    @apply mt-2 mb-0;
  }
</style>
