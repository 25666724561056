<template>
  <div class="flex justify-between items-center group">
    <div class="flex flex-col w-full py-2 pl-1 pr-5">
    <span class="text-sm mb-1">
      {{ params.displayName }}
    </span>
      <ImportColumnMappingSelect
          :options="params.options"
          :mapping="params.mapping"
          :colIndex="colIndex"
          :value="value"
          @change="onMappingChange"
          class="w-full font-medium"
      />
    </div>
    <div class="hidden absolute right-[5px] top-[5px] group-hover:flex">
      <TableDeleteButton
          @click="deleteColumn"
      />
    </div>
  </div>
</template>
<script>
  import ImportColumnMappingSelect from "@/modules/common/components/import/ImportColumnMappingSelect";

  export default {
    components: {
      ImportColumnMappingSelect,
    },
    computed: {
      colIndex() {
        const index = this.columns.findIndex(c => c.colId === this.params?.column?.colId)
        if (index === -1) {
          return index
        }
        return index
      },
      value() {
        return this.getColKey(this.params?.colMapping, this.colIndex)
      },
      columns() {
        return this.params?.columnApi?.getColumns() || []
      }
    },
    methods: {
      getColKey(colKey, colIndex) {
        for (let key in this.params?.mapping) {
          const value = this.params.mapping[key]
          if (colIndex === value) {
            return key
          }
        }
        if (this.params.mapping[colKey] === -1) {
          return -1
        }
        if (this.params.mapping[colKey] !== colIndex) {
          return -1
        }
        return colKey || -1
      },
      onMappingChange(value, oldValue) {
        const mapping = this.params.mapping

        if (oldValue && oldValue !== -1) {
          mapping[oldValue] = -1
        }
        if (value !== -1) {
          mapping[value] = this.colIndex
        }
        this.params.updateMapping(mapping, value, this.colIndex)
      },
      async deleteColumn() {
        const confirm = await this.$deleteConfirm({
          title: this.$t('Delete column'),
          description: this.$t('Are you sure you want to delete this column?'),
        })
        if (!confirm || !this.params.deleteColumn) {
          return
        }
        await this.params.deleteColumn(this.value)
      },
    }
  }
</script>

