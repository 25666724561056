<template>
  <div class="mr-20 mt-2">
    <base-button variant="primary-light" @click="showDialog = true">
      {{ $t('Confirm Direct Deposit') }}
    </base-button>
    <BaseFormDialog
      v-if="showDialog"
      :title="$t('Confirm Direct Deposit Upload')"
      :appendToBody="true"
      :open.sync="showDialog"
    >
      <div v-if="batch?.attributes?.dd_confirmed_at" class="my-4 px-8">
        <BaseAlert type="danger">
          <div class="flex flex-wrap">
            {{ $t('Direct Deposit already confirmed on') }} {{ $formatDate(batch.attributes.dd_confirmed_at, 'date', true) }}
            <template v-if="batch?.attributes?.dd_confirmed_by">
              {{$t('by')}}
              <UserLink :id="batch?.attributes?.dd_confirmed_by" class="ml-1"/>
            </template>
          </div>
        </BaseAlert>
      </div>

      <base-form
        :save-text="$t('Confirm')"
        :cancel-text="$t('Cancel')"
        :show-cancel="true"
        :loading="loading"
        layout="vertical"
        @cancel="showDialog = false"
        @submit="onSubmit"
      >
        <div class="col-span-6 md:col-span-3">
          <base-date-picker
            v-model="model.dd_confirmed_at"
            :label="$t('Confirmation Date')"
            :placeholder="$t('Confirmation Date')"
          />
        </div>
        <div class="col-span-6 md:col-span-3">
          <base-input
            v-model="model.dd_confirmation_number"
            rules="required"
            :label="$t('Confirmation Number')"
            :placeholder="$t('Confirmation Number')"
          />
        </div>
      </base-form>
    </BaseFormDialog>
  </div>
</template>
<script>
import axios from "axios";

export default {
  props: {
    batch: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      showDialog: false,
      loading: false,
      model: {
        dd_confirmed_at: new Date(),
        dd_confirmation_number: null,
      }
    }
  },
  methods: {
    async onSubmit() {
      try {
        const batchId = this.batch?.id
        this.loading = true
        await axios.post(`/restify/payroll-batches/${batchId}/actions?action=confirm-direct-deposit`, this.model)
        await this.$store.dispatch('payroll/getPayrollBatch', batchId)
        this.showDialog = false
      } catch (err) {
        this.$error(this.$t('Could not confirm direct deposit'))
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
