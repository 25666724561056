<template>
  <div
      :class="{
        '!bg-blue-50': isOverride,
      }"
      class="col-span-1 py-1.5"
  >
    <p class="leading-tight tracking-wide font-semibold text-sm text-gray-900">
      <slot name="label">
        {{ label }}
      </slot>
      <BaseTooltip v-if="isOverride" :content="$t('This field was overwritten by the user')">
        <span>*</span>
      </BaseTooltip>
    </p>
    <p class="leading-tight text-sm font-medium text-gray-500">
      <template v-if="loading">
        {{ $t('Loading...') }}
      </template>
      <template v-else>
        <slot></slot>
      </template>
    </p>
  </div>
</template>
<script>
  export default {
    props: {
      label: String,
      loading: Boolean,
      isOverride: Boolean,
    },
  }
</script>
<style>
</style>
