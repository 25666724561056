<template>
  <div class="h-screen flex overflow-hidden bg-gray-100 dashboard-layout shadow theme-light">
    <!-- Off-canvas menu for mobile -->
    <div v-show="showCanvasOverlay" class="md:hidden">
      <div class="fixed inset-0 flex z-40">
        <transition enter-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-class="opacity-100"
                    leave-to-class="opacity-0">
          <div v-show="showSidebar"
               class="fixed inset-0 transition-opacity ease-linear duration-300">
            <div class="absolute inset-0 bg-gray-600 opacity-75"></div>
          </div>
        </transition>

        <transition enter-class="-translate-x-full"
                    enter-to-class="translate-x-0"
                    leave-class="translate-x-0"
                    leave-to-class="-translate-x-full"
        >
          <div v-show="showSidebar"
               class="relative flex-1 flex flex-col max-w-xs w-full transition ease-in-out duration-300 transform bg-white"
          >
            <div class="absolute top-0 right-0 -mr-14 p-1">
              <button
                  class="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600"
                  @click="toggleSidebar(false)"
                  aria-label="Close sidebar">
                <svg class="h-6 w-6 text-white" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"/>
                </svg>
              </button>
            </div>
            <div class="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
              <div class="flex-shrink-0 flex items-center px-4">
                <img class="h-8 w-auto" src="/img/cp.png" alt="CP4Cloud logo"/>
              </div>
              <nav class="mt-5 px-2">
                <router-link v-for="menu in sidebarMenus"
                             :key="menu.path"
                             :to="menu.path"
                             class="menu-item mt-1 group flex items-center px-2 py-2 text-base leading-6 font-medium rounded-md focus:outline-none focus:text-white transition ease-in-out duration-150 text-gray-600 hover:text-gray-900 hover:bg-gray-50 focus:bg-gray-100"
                             @click.native="toggleSidebar(false)"
                >
                  <component :is="menu.icon" stroke="currentColor"
                             class="mr-4 h-6 w-6 transition ease-in-out duration-150 text-gray-600 group-hover:text-gray-900 group-focus:text-gray-900"
                  />
                  {{ menu.name }}
                </router-link>
              </nav>
            </div>

          </div>
        </transition>
        <div class="flex-shrink-0 w-14">
          <!-- Force sidebar to shrink to fit close icon -->
        </div>
      </div>
    </div>

    <!-- Static sidebar for desktop -->
    <div class="hidden md:flex md:flex-shrink-0">
      <div class="flex flex-col w-64 bg-white border-r border-gray-200"
      >
        <div class="h-0 flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
          <div class="flex items-center flex-shrink-0 px-4">
            <img class="h-8 w-auto -ml-1" src="/img/cp.png" alt="CP4Cloud Logo"/>
            <h4 class="ml-2 text-gray-600">
              {{ $t('CP Wiki') }}
            </h4>
          </div>
          <wiki-search-overlay class="mt-2 -mb-2 mx-2"/>
          <nav class="mt-5 flex-1 px-2 bg-white">
            <template v-for="menu in sidebarMenus">
              <template v-if="menu.group">
                <p :key="menu.name"
                   class="px-3 mt-5 mb-2 text-xs font-semibold text-gray-400 uppercase tracking-wider">
                  {{ menu.name }}
                </p>
              </template>
              <router-link v-else
                           :key="menu.path"
                           :to="menu.path"
                           class="menu-item group flex items-center px-2 py-2 text-sm leading-5 font-medium rounded-md focus:outline-none transition ease-in-out duration-150
                           text-gray-600 hover:text-gray-900 hover:bg-gray-50 focus:bg-gray-100"
              >
                <component v-if="menu.icon"
                           :is="menu.icon" stroke="currentColor"
                           class="mr-3 h-6 w-6 transition ease-in-out duration-150 text-gray-600 group-hover:text-gray-900 group-focus:text-gray-900"
                />
                <div v-else class="ml-3 w-1 h-1 mr-2 bg-gray-500 rounded-full"></div>
                {{ menu.name }}
              </router-link>
            </template>

            <template v-for="(menuItems, menu) in articleMenus">
              <template>
                <p :key="menu"
                   class="px-3 mt-5 mb-2 text-xs font-semibold text-gray-400 uppercase tracking-wider">
                  {{ menu !== 'null' ? menu : $t('Default') }}
                </p>
              </template>
              <router-link v-for="item in menuItems"
                           :key="item.path"
                           :to="item.path"
                           class="menu-item group flex items-center justify-between px-2 py-2 text-sm leading-5 font-medium rounded-md focus:outline-none transition ease-in-out duration-150
                           text-gray-600 hover:text-gray-900 hover:bg-gray-50 focus:bg-gray-100"
              >
                <div class="flex items-center">
                  <div class="ml-3 w-1 h-1 mr-2 bg-gray-500 rounded-full"></div>
                  <base-tooltip :content="item.name">
                    <span class="truncate page-name">{{ item.name }}</span>
                  </base-tooltip>
                </div>
              </router-link>
            </template>
          </nav>
        </div>
        <div class="flex-shrink-0 flex border-t border-gray-200 p-4">
          <router-link to="/" class="flex-shrink-0 w-full group block">
            <div class="flex items-center">
              <div>
                <svg class="w-6 h-6 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"></path>
                </svg>
              </div>
              <div class="ml-3">
                <p class="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                  {{ $t('Back to the Application') }}
                </p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'
  import groupBy from 'lodash/groupBy'
  import WikiSearchOverlay from "@/modules/wiki/components/WikiSearchOverlay";

  export default {
    components: {
      WikiSearchOverlay
    },
    data() {
      return {
        showSidebar: false,
        showCanvasOverlay: false,
        articles: [],
        initialSidebarMenus: [
          {
            name: this.$t('New Page'),
            path: '/wiki/new',
            icon: 'IconPlus'
          },
        ]
      }
    },
    computed: {
      sidebarMenus() {
        return this.initialSidebarMenus
      },
      articleMenus() {
        const articleMenus = this.articles.map((article) => {
          return {
            name: article.attributes.title || this.$t('Page ') + article.id,
            path: `/wiki/${article.id}`,
            category: article.attributes.category,
            draft: article.attributes.draft,
          }
        })
        return groupBy(articleMenus, 'category')
      }
    },
    methods: {
      toggleSidebar(value) {
        if (value) {
          this.showSidebar = true
          this.showCanvasOverlay = true
          return
        }
        this.showSidebar = false
        setTimeout(() => {
          this.showCanvasOverlay = false
        }, 300)
      },
      async getArticles() {
        const { data } = await axios.get(`/restify/wiki-articles`)
        this.articles = data
      }
    },
    watch: {
      '$route.path'() {
        this.getArticles()
      }
    },
    created() {
      this.getArticles()
    }
  }
</script>
<style>
  .theme-light .menu-item.router-link-active {
    @apply bg-gray-100;
  }
  .page-name {
    max-width: 180px;
  }
</style>
