<template>
  <div>
    <BaseDataTable
      :columns="columns"
      :url="url"
      :url-params="urlParams"
      :is-expandable="true"
      :delete-action="deleteTimeOffRates"
      :add-entity-in-new-tab="true"
      :default-match-filters="defaultMatchFilters"
      :add-text="$t('New time off rate')"
      ref="table"
      view-entity-url-query="/payroll/rates/time-off-rates"
      default-sort="-updated_at"
      actions="search,refresh,view,add,edit,delete"
      @add="$router.push('/payroll/rates/time-off-rates/add')"
    >
      <template #expand-content="{row}">
        <TimeOffRateEntries
          :data="row"
          :read-only="true"
          api-url="/restify/time-off-rates"
          ref="entriesTable"
          class="m-4"
        />
      </template>
    </BaseDataTable>
  </div>
</template>
<script>
import axios from 'axios'
import TimeOffRateEntries from '@/modules/payroll/components/rates/TimeOffRateEntries.vue'

export default {
  components: {
    TimeOffRateEntries,
  },
  data() {
    return {
      url: '/restify/time-off-policies',
      urlParams: {
        related: 'timeOffType',
      },
    }
  },
  computed: {
    columns() {
      return [
        {
          label: this.$t('Description'),
          prop: 'attributes.description',
          maxWidth: 300,
          minWidth: 160,
          component: 'EntityLink',
          redirectTo: '/payroll/codes/time-off/{ID}/view',
        },
        {
          label: this.$t('Type'),
          prop: 'relationships.timeOffType.attributes.name',
          maxWidth: 180,
          minWidth: 160,
        },
        {
          label: this.$t('Rates'),
          prop: 'attributes.rates_count',
          component: 'Count',
        },
        {
          label: this.$t('Active'),
          prop: 'attributes.active',
          component: 'Status'
        },
      ]
    },
    defaultMatchFilters() {
      return {
        having_time_off_rates: true,
      }
    },
  },
  methods: {
    onEventTrigger() {
      this.refreshTable()
    },
    async deleteTimeOffRates(row) {
      try {
        await axios.post(`/restify/time-off-policies/${row.id}/actions?action=delete-time-off-rates-from-policy`)
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(this.$t('Something went wrong. Please try again.'))
      } finally {
        this.refreshTable()
      }
    },
    refreshTable() {
      this.$refs.table.refresh()
    },
  },
}
</script>
