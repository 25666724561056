export function isAuthorized(action, entity) {
  if (!entity) {
    return false
  }
  const { meta } = entity
  if (!meta) {
    return true
  }
  return meta[action]
}

export default {
  install(Vue) {
    Vue.prototype.$isAuthorized = isAuthorized
  }
}
