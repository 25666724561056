<template>
  <EmployeeRatesForm :rate-type="employeeRatesTypes.BenefitRates"/>
</template>
<script>
  import { employeeRatesTypes } from '@/modules/payroll/components/rates/util'
  import EmployeeRatesForm from '@/modules/payroll/components/employee-rates/EmployeeRatesForm'

  export default {
    components: {
      EmployeeRatesForm,
    },
    data() {
      return {
        employeeRatesTypes,
      }
    },
  }
</script>
