<template>
  <div>
    <router-link v-if="data.id" :to="journalLink">
      {{ journalName }}
    </router-link>
    <div v-else>
      {{ journalName }}
    </div>
  </div>
</template>
<script>
import { getJournalPath } from "@/components/links/util";

export default {
  name: 'JournalLink',
  props: {
    id: [String, Number],
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    journal() {
      return this.get(this.data, 'relationships.journal') || this.data
    },
    journalName() {
      return this.data.name || this.data?.attributes?.name
    },
    journalLink() {
      return getJournalPath(this.data.id, this.journalName)
    }
  }
}
</script>
