import store from '@/store'
import groupBy from 'lodash/groupBy'

export const BILL_TO_STORAGE_KEY = 'selectedBillToType'

function billingRateTypes() {
  return store.state.globalLists.billingRateTypes
}

export function fillRateOverrides(rates) {
  const rateTypes = billingRateTypes()

  return rateTypes.map(type => {

    const {
      overtime_rate: overtime_rate = 0,
      regular_rate: regular_rate = 0,
      premium_rate: premium_rate = 0,
      id: id = undefined
    } = rates.find(rate => rate?.billing_rate_type_id?.toString() === type?.id?.toString()) || {}

    return {
      ...type,
      regular_rate,
      overtime_rate,
      premium_rate,
      id,
      billing_rate_type_id: type.id
    }
  })
}

export function mapBillingRates(billings) {
  const groups = groupBy(billings, 'attributes.source_id')
  let rates = []

  Object.keys(groups).forEach(sourceId => {
    const billings = groups[sourceId]
    let group = { ...billings[0].attributes }
    const { source } = billings[0].relationships || []
    const billRates = billings.map(billing => billing.attributes)
    group['billing_rates'] = fillRateOverrides(billRates)
    rates.push({
      ...group,
      ...source[0],
      source: {
        attributes: source[0],
      }
    })
  })

  return rates
}
