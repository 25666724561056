<template>
  <base-report-form
      :filters="model"
      :has-own-data-fetcher="true"
      :hide-presets="true"
      :is-report-table="false"
      save-text="Apply Filters"
      @on-update-filter="onApplyFilters"
  >

    <template #filters>

      <base-filter-row :title="$t('Status')">

        <base-select
            v-model="model.status"
            :add-entity="false"
            :options="jobStatusOptions"
            :hide-label="true"
            :placeholder="$t('Select Status')"
            class="col-span-2"
            collapse-tags
            multiple
            @change="onJobStatusChange"
        />

      </base-filter-row>

      <base-filter-row>
        <base-checkbox
            v-model="selectJobList"
            :label="$t('Select a List of Jobs')"
            class="col-span-3 mb-3"
            id="select-job-list"
            @change="resetJobFilters"
        />
      </base-filter-row>

      <base-filter-row :title="$t('Jobs')">

        <base-filter-range
            v-if="!selectJobList"
            class="col-span-4"
        >

          <template #start>
            <job-select
                v-model="model.from"
                :urlParams="jobUrlParams"
                :add-entity="false"
                :placeholder="$t('Job From')"
                :hide-label="true"
                value-key="number"
                class="col-span-4"
                clearable
            />
          </template>

          <template #end>
            <job-select
                v-model="model.to"
                :urlParams="jobUrlParams"
                :add-entity="false"
                :placeholder="$t('Job To')"
                :hide-label="true"
                :fetch-on-mount="false"
                :rules="model.from ? 'required' : ''"
                value-key="number"
                clearable
                @blur="onBlurEndRange"
            />
          </template>

        </base-filter-range>

        <job-select
            v-else
            v-model="model.jobs"
            :add-entity="false"
            :collapse-tags="true"
            :placeholder="$t('Select Jobs')"
            :hide-label="true"
            rules="required"
            class="col-span-4"
            multiple
        />

        <job-category-select
            v-if="selectJobList"
            v-model="model.category_id"
            :add-entity="false"
            :hide-label="true"
            :placeholder="$t('Select Job Category')"
            class="col-span-2"
        />

      </base-filter-row>
    </template>

    <template #table>

      <div class="flex justify-end">
        <base-button
            variant="white"
            @click="showDialog = true"
        >
          {{ $t('Update Completion Percent For All Jobs') }}
        </base-button>
      </div>

      <BaseFormDialog
          v-if="showDialog"
          :title="$t('Update Completion Percent')"
          :open.sync="showDialog"
          size="sm"
      >
        <base-form v-bind="$attrs"
                   :save-text="$t('Update')"
                   :loading="loading"
                   layout="modal"
                   @submit="updateCompletion"
        >
          <base-input
              v-model="completion"
              :label="$t('Completion Percent')"
              :min="0"
              :max="100"
              type="number"
              format="percent"
              rules="max_value:100|min_value:0"
              class="col-span-6"
          />
        </base-form>
      </BaseFormDialog>

      <AllLineItemsTable
          :url-params="urlParams"
          ref="lineItemsTable"
      />

    </template>

  </base-report-form>
</template>
<script>
  import axios from 'axios';
  import { JobStatus, jobStatusOptions } from '@/modules/job-costing/enum/jobs'
  import AllLineItemsTable from '@/modules/job-costing/components/progress-updating/AllLineItemsTable'

  export default {
    components: {
      AllLineItemsTable,
    },
    data() {
      return {
        showDialog: false,
        completion: 0,
        loading: false,
        selectJobList: false,
        jobStatusOptions,
        urlParams: {
          status: [JobStatus.Open],
          from: null,
          to: null,
          jobs: [],
          category_id: null,
        },
        model: {
          status: [JobStatus.Open],
          from: null,
          to: null,
          jobs: [],
          category_id: null,
        },
      }
    },
    computed: {
      jobUrlParams() {
        const statusToString = this.model.status.join(',')
        return {
          status: statusToString,
        }
      },
    },
    methods: {
      async updateCompletion() {
        try {
          this.loading = true

          if (this.selectJobList) {
            const data = this.model.jobs.map(job_id => {
              return {
                job_id,
                completion: this.completion,
              }
            })

            await axios.post('/restify/line-items/action?action=job-progress-updating-by-ids', { data })
          } else {
            let payload = {
              completion: this.completion,
              from: this.model.from,
              to: this.model.to,
              status: this.model.status,
            }

            if (this.model.category_id) {
              payload.category_id = this.model.category_id
            }

            await axios.post('restify/line-items/action?action=job-progress-updating-all-between', payload)
          }

        } catch (err) {
          console.warn(err)
          if (err.handled) {
            return
          }
          this.$error(this.$t('Something went wrong. Please try again.'))
        } finally {
          this.loading = false
          this.showDialog = false
          this.$refs.lineItemsTable.refresh()
        }
      },
      onApplyFilters() {
        this.urlParams = {
          ...this.model,
          sort: 'number',
        }
      },
      resetJobFilters() {
        this.model.jobs = []
        this.model.from = this.model.to = null
      },
      onBlurEndRange() {
        this.model.to = this.model.to || this.model.from
      },
      onJobStatusChange() {
        this.resetJobFilters()
      },
    },
  }
</script>
