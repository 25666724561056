<template>
  <entity-select url="/restify/billings"
                 v-bind="$attrs"
                 v-on="$listeners"
                 :url-params="urlParams"
                 :name="$attrs.name || $t('Billing')"
                 :placeholder="$attrs.placeholder || $t('Select Billing')"
                 :label="$attrs.label !== undefined ? $attrs.label : $t('Billing')"
                 :add-entity="addEntity"
                 :edit-entity="editEntity"
                 :set-default-option="editEntity"
                 :add-label="$t('Add new Billing')"
                 label-key="number"
                 :on-map-entry="onChangeEntry"
                 :label-format="formatLabel"
                 :id="$attrs.id || 'billing-select'"
                 :table-columns="tableColumns"
  />
</template>
<script>
  export default {
    inheritAttrs: false,
    props: {
      urlParams: {
        type: Object,
        default: () => ({
          sort: 'status',
        }),
      },
      addEntity: {
        type: Boolean,
        default: true,
      },
      editEntity: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        tableColumns: [
          {
            minWidth: 100,
            maxWidth: 150,
            name: this.$t('Invoice #'),
            prop: 'attributes.number',
          },
          {
            minWidth: 80,
            maxWidth: 120,
            name: this.$t('Type'),
            prop: 'attributes.type',
          },
          {
            minWidth: 100,
            maxWidth: 150,
            name: this.$t('Net'),
            prop: 'attributes.net_amount',
            toFormat: this.$formatPrice,
          },
          {
            minWidth: 100,
            maxWidth: 150,
            name: this.$t('Remaining'),
            prop: 'attributes.open_amount',
            toFormat: this.$formatPrice,
          },
          {
            minWidth: 100,
            maxWidth: 150,
            name: this.$t('Date'),
            prop: 'attributes.date',
            toFormat: this.$formatDate,
          },
        ],
      }
    },
    methods: {
      formatLabel(billing) {
        const { number, description } = billing.attributes
        if (!description) {
          return number
        }
        return `${number} (${description})`
      },
      onChangeEntry(billing) {
        this.$emit('on-map-entry', billing)
      },
    },
  }
</script>

