<template>
  <AgDataTable
      ref="table"
      :columns="columns"
      :url="url"
      :urlParams="urlParams"
      :groupIncludeTotalFooter="true"
      domLayout="autoHeight"
      class="mt-4"
      action-type="post"
  >

    <template #number="{row}">
      <JobLink
          :data="row"
          :show-preview="true"
          :additional-props="jobAdditionalProps"
      />
    </template>

  </AgDataTable>
</template>
<script>
  import axios from 'axios'
  import { cellClasses } from '@/components/ag-grid/columnUtils'
  import { jobStatusOptions } from '@/modules/job-costing/enum/jobs'

  export default {
    props: {
      urlParams: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        jobAdditionalProps: [
          {
            label: this.$t('Budgeted Gross Margin'),
            prop: 'budgeted_gross_margin',
            format: 'price',
          },
          {
            label: this.$t('Projected Gross Margin'),
            prop: 'projected_gross_margin',
            format: 'price',
          },
          {
            label: this.$t('Retention Payable'),
            prop: 'retention_payable',
            format: 'price',
          },
          {
            label: this.$t('Retention Receivable'),
            prop: 'retention_receivable',
            format: 'price',
          },
        ],
        url: '/restify/review-job-summaries',
        columns: [
          {
            headerName: this.$t('Status'),
            field: 'status',
            minWidth: 120,
            maxWidth: 150,
            sortable: true,
            cellEditor: this.$cellEditors.BaseSelect,
            cellEditorParams: {
              options: jobStatusOptions,
              onValueChanged: this.onChangeStatus,
            },
            valueFormatter: params => {
              const op = jobStatusOptions.find(o => o.value === params.value)
              return op?.label
            },
            editable: true,
          },
          {
            headerName: this.$t('Job'),
            field: 'number',
            minWidth: 120,
            maxWidth: 300,
            sortable: true,
            cellClass: params => {
              if (params.node.footer) {
                return ''
              }
              return cellClasses.ReadOnlyLight
            },
          },
          {
            headerName: this.$t('Budget Cost To-Date'),
            field: 'budget_cost_to_date',
            component: 'FormattedPrice',
            align: 'right',
            minWidth: 120,
            maxWidth: 200,
            aggFunc: 'sum',
            cellClass: params => {
              if (params.node.footer) {
                return 'flex justify-end'
              }
              return `flex justify-end ${cellClasses.ReadOnlyLight}`
            },
          },
          {
            headerName: this.$t('Actual Cost To-Date'),
            field: 'actual_cost_to_date',
            component: 'FormattedPrice',
            align: 'right',
            minWidth: 120,
            maxWidth: 200,
            aggFunc: 'sum',
            cellClass: params => {
              if (params.node.footer) {
                return 'flex justify-end'
              }
              return `flex justify-end ${cellClasses.ReadOnlyLight}`
            },
          },
          {
            headerName: this.$t('Completion'),
            field: 'completion',
            align: 'right',
            component: 'FormattedPercent',
            minWidth: 100,
            maxWidth: 120,
            aggFunc: 'avg',
            cellClass: params => {
              if (params.node.footer) {
                return 'flex justify-end'
              }
              return `flex justify-end ${cellClasses.ReadOnlyLight}`
            },
          },
          {
            headerName: this.$t('Estimated Cost Variance'),
            field: 'estimated_cost_variance',
            component: 'FormattedPrice',
            align: 'right',
            aggFunc: 'sum',
            minWidth: 120,
            maxWidth: 200,
            cellClass: params => {
              let classes = 'flex justify-end'
              if (params.node.footer) {
                return classes
              }
              classes += ` ${cellClasses.ReadOnlyLight}`
              return params.value < 0 ? (classes + ' text-red-500') : classes
            },
          },
          {
            headerName: this.$t('Contract To-Date'),
            field: 'contract_to_date',
            component: 'FormattedPrice',
            align: 'right',
            aggFunc: 'sum',
            minWidth: 120,
            maxWidth: 200,
            cellClass: params => {
              if (params.node.footer) {
                return 'flex justify-end'
              }
              return `flex justify-end ${cellClasses.ReadOnlyLight}`
            },
          },
          {
            headerName: this.$t('Billings To-Date'),
            field: 'billings_to_date',
            component: 'FormattedPrice',
            aggFunc: 'sum',
            align: 'right',
            minWidth: 150,
            cellClass: params => {
              if (params.node.footer) {
                return 'flex justify-end'
              }
              return `flex justify-end ${cellClasses.ReadOnlyLight}`
            },
          },
        ],
      }
    },
    methods: {
      async onChangeStatus(params, status) {
        try {
          params.data.loading = true

          let row = params.data
          row.status = status
          await axios.put(`/restify/jobs/${row.id}`, row)

          this.$success(this.$t('Job status updated'))
        } catch (err) {
          console.warn(err)
          if (err.handled) {
            return
          }
          this.$error(this.$t('Something went wrong. Please try again.'))
        } finally {
          params.data.loading = false
        }
      },
    },
  }
</script>
