<template>
  <div class="flex justify-between">
    <customer-link v-if="groupBy === groupByType.Customer"
                   :data="get(row, 'header')"
                   :show-name="true"
    />

    <vendor-link v-else-if="groupBy === groupByType.Vendor"
                 :data="get(row, 'header')"
    />

    <employee-link v-else-if="groupBy === groupByType.Employee"
                   :data="get(row, 'header')"
    />

    <work-order-link v-else-if="groupBy === groupByType.WorkOrder"
                     :data="get(row, 'header')"
    />

    <worksite-link v-else-if="groupBy === groupByType.WorkSite"
                     :data="get(row, 'header')"
    />

    <div v-if="groupBy === groupByType.Journal"
         class="flex items-center justify-between"
    >
      <base-tooltip :content="$t('Journal Name')">
        <router-link :to="`/accounts-receivable/journals/${get(row, 'header.id')}/view`">
          <span>{{ get(row, 'header.attributes.name') }}</span>
        </router-link>
      </base-tooltip>
      <base-tooltip :content="$t('Last Updated')">
        <span class="text-sm">{{ $formatDate(get(row, 'header.attributes.updated_at'), 'datetime') }}</span>
      </base-tooltip>
    </div>
    <slot></slot>
  </div>
</template>
<script>
  import { groupByType } from "@/components/table/utils/groupByTypes";

  export default {
    name: 'TableGroupByHeader',
    props: {
      groupBy: {
        type: String,
        default: '',
      },
      row: {
        type: Object,
        default: () => ({}),
      }
    },
    data() {
      return {
        groupByType,
      }
    },
  }
</script>
