<template>
  <BillingsTable
      v-bind="$attrs"
      :key="status"
      :billing-status="status"
      :billing-type="billingTypes.Service"
      :tableProps="tableProps"
  />
</template>
<script>
  import { resourceStatuses } from '@/enum/enums'
  import { groupByType } from '@/components/table/utils/groupByTypes'
  import { billingTypeAbbr, billingTypes } from '@/modules/accounts-receivable/pages/billings/billings'
  import BillingsTable from '@/modules/accounts-receivable/pages/billings/BillingsTable'

  export default {
    components: {
      BillingsTable,
    },
    props: {
      filters: {
        type: Object,
        default: () => ({}),
      },
      resourceStatus: {
        type: String,
        default: resourceStatuses.Pending,
      },
    },
    data() {
      return {
        billingTypes,
        status: resourceStatuses.Pending,
        selectedGroupBy: groupByType.Customer,
      }
    },
    computed: {
      tableProps() {
        const urlParams = {
          ...this.filters,
          related: 'customer,workOrder,district',
          type: billingTypeAbbr.Service,
        }

        return {
          urlParams,
          columns: this.columns,
          addEntityUrlQuery: this.addEntityUrlQuery,
          baseEntityPath: '/service-billing/service-invoices',
          addText: this.$t('New service invoice'),
          permission: 'service_billing_invoices',
          actions: 'search,refresh,view,print',
          groupByOptions: this.groupByOptions,
        }
      },
      columns() {
        const columns = [
          {
            label: this.$t('Invoice #'),
            prop: 'attributes.number',
            maxWidth: 160,
            component: 'EntityLink',
            redirectTo: '/service-billing/service-invoices/{ID}/view',
          },
          {
            label: this.$t('Date'),
            prop: 'attributes.date',
            minWidth: 100,
            maxWidth: 150,
            component: 'FormattedDate',
          },
          {
            label: this.$t('Description'),
            prop: 'attributes.description',
            minWidth: 100,
            maxWidth: 150,
          },
          {
            label: this.$t('Amount'),
            prop: 'attributes.gross_amount',
            component: 'FormattedPrice',
            align: 'right',
            minWidth: 100,
            maxWidth: 150,
          },
          {
            label: this.$t('Tax'),
            prop: 'attributes.sales_tax_amount',
            component: 'FormattedPrice',
            align: 'right',
            minWidth: 100,
            maxWidth: 150,
          },
          {
            label: this.$t('Start Date'),
            prop: 'attributes.meta.work_date_from',
            component: 'FormattedDate',
            align: 'right',
            minWidth: 100,
            maxWidth: 150,
          },
          {
            label: this.$t('End Date'),
            prop: 'attributes.meta.work_date_to',
            component: 'FormattedDate',
            align: 'right',
            minWidth: 100,
            maxWidth: 150,
          },
        ]

        if (this.status === resourceStatuses.All) {
          columns.push({
            label: this.$t('Status'),
            prop: 'attributes.status',
            align: 'center',
            maxWidth: 160,
            component: 'Status',
          })
        }

        if (this.filterByWorkOrder) {
          return columns
        }

        if (this.selectedGroupBy === groupByType.Customer) {
          columns.splice(1, 0,
              {
                label: this.$t('Work Order'),
                prop: 'workOrder',
                minWidth: 100,
                maxWidth: 120,
              },
          )
        } else {
          columns.splice(1, 0,
              {
                label: this.$t('Customer'),
                prop: 'customer',
                minWidth: 100,
                maxWidth: 120,
              },
          )
        }

        return columns
      },
      filterByWorkOrder() {
        const { work_order_id } = this.filters
        return !!work_order_id
      },
      addEntityUrlQuery() {
        if (!this.filterByWorkOrder) {
          return '/service-billing/service-invoices/add'
        }

        return `/service-billing/service-invoices/add/${this.filters.work_order_id}`
      },
      groupByOptions() {
        if (!this.filterByWorkOrder) {
          return [
            groupByType.Customer,
            groupByType.WorkOrder,
          ]
        }

        return []
      },
    },
    watch: {
      '$route.meta': {
        immediate: true,
        handler(meta) {
          this.status = meta?.status || resourceStatuses.All
        },
      },
    },
  }
</script>
