<template>
  <job-transaction-form
      :type="type"
      :key="renderKey"
      @create-another="renderKey++"
  />
</template>
<script>
  import { costTypes } from '@/enum/enums'
  import JobTransactionForm from '@/modules/job-costing/components/JobTransactionForm'

  export default {
    components: {
      JobTransactionForm,
    },
    data() {
      return {
        renderKey: 1,
        type: this.$route.meta?.type || costTypes.Cost,
      }
    },
  }
</script>
