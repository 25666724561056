<template>
  <BaseSelect
    v-on="$listeners"
    v-bind="$attrs"
    :multiple="multiple"
    :label-format="formatLabel"
    :table-columns="tableColumns"
    :options="options"
    :dataFilters="dataFilters"
    :resource-name="globalResources.DeductionCodes"
    :value-key="valueKey"
    label-key="code"
  />
</template>
<script>
import { globalResources } from "@/components/form/util";

export default {
  props: {
    valueKey: {
      type: String,
      default: 'id',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    showActiveColumn: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      globalResources,
      dataFilters: {
        showActive: true,
      },
    }
  },
  computed: {
    options() {
      let deductionCodes = this.$store.getters['globalLists/getResourceList'](globalResources.DeductionCodes) || []
      if (this.dataFilters.showActive) {
        deductionCodes = deductionCodes.filter(code => code.active)
      }

      return deductionCodes.map(deductionCode => ({
        ...deductionCode,
        isInactive: !deductionCode.active,
      }))
    },
    tableColumns() {
      const columns = [
        {
          minWidth: 100,
          maxWidth: 150,
          name: this.$t('Code'),
          prop: 'code',
        },
        {
          minWidth: 150,
          maxWidth: 150,
          name: this.$t('Description'),
          prop: 'description',
        },
        {
          minWidth: 130,
          maxWidth: 130,
          name: this.$t('Active'),
          prop: 'active',
          component: 'Status',
          filter: {
            type: 'switch',
            prop: 'showActive',
            tooltip: this.$t('Only show active deduction codes'),
            onChange: (value) => {
              this.dataFilters.showActive = value
            },
          },
          showIf: () => this.showActiveColumn,
        },
      ]

      return columns.filter(c => !c.showIf || c.showIf())
    }
  },
  methods: {
    formatLabel(option) {
      const { code, description } = option
      if (!description) {
        return code
      }
      return `${code} (${description})`
    },
  },
}
</script>
