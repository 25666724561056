<template>
  <ElTooltip
      v-bind="$attrs || {}"
      :open-delay="400"
      :content="content ? content.toString() : ''"
      :placement="$attrs?.placement || 'top'"
      :disabled="$attrs?.disabled || (!content && !$slots.content)"
      :popper-options="{
        gpuAcceleration: false,
      }"
  >
    <slot></slot>
    <template #content>
      <slot name="content"></slot>
    </template>
  </ElTooltip>
</template>
<script>
  import { Tooltip } from 'element-ui'

  export default {
    name: 'BaseTooltip',
    inheritAttrs: false,
    components: {
      [Tooltip.name]: Tooltip,
    },
    props: {
      content: {
        type: [String, Number, Object, Boolean, Array],
        default: '',
      }
    },
  }
</script>
