<template>
  <base-data-table
    v-bind="defaultAttributes"
    :filters="filters"
    :columns="columns"
    :data="getData"
    :total-rows="rowsLength"
    @force-pagination="forcePagination"
  >

    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template #header="{row}">
      <div class="flex items-center space-x-1">
        <template v-if="row.header.line_item">
          <span>{{ $t('Line Item: ') }}</span>
          <LineItemLink
            :data="row.header?.line_item"
            :show-preview="false"
          />
        </template>
        <template v-else-if="row.header.job">
          <span>{{ $t('Job: ') }}</span>
          <JobLink
            :data="row.header?.job"
            :show-preview="false"
          />
        </template>
        <template v-else>
          <span>{{ row.header.fallback }}</span>
        </template>
      </div>
    </template>
    <template #line_item="{row}">
      <LineItemLink
        v-if="row.line_item"
        :data="row.line_item"/>
    </template>

    <template #purchase_order.number="{row}">
      <PurchaseOrderLink :data="row.purchase_order"/>
    </template>

    <template #vendor.code="{row}">
      <VendorLink :data="row.vendor" :show-preview="false" :show-description="false"/>
    </template>

    <template #entry.quantity="{row}">
      <div class="flex space-x-1 justify-end">
        <span>{{ $formatQuantity(row.entry.quantity) }}</span>
        <span v-if="row.entry">{{ row.entry.um }}</span>
      </div>
    </template>

    <template #subtotal="{row, subtotal}">
      <td colspan="5"/>
      <td colspan="2">
        <div class="flex justify-end">
          {{ row?.title }}
        </div>
      </td>
      <td class="text-right !pr-1">
        {{ $formatPrice(subtotal.sales_tax_amount) }}
      </td>
      <td class="text-right !pr-1">
        {{ $formatPrice(subtotal.freight_amount) }}
      </td>
      <td class="text-right !pr-1">
        {{ $formatPrice(subtotal.extended_amount) }}
      </td>
    </template>

  </base-data-table>
</template>
<script>
import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'
import SourceLink from "@/components/links/SourceLink.vue";
import { costCenterTypes, sourceTypeAbbrMap, sourceTypeMap } from "@/components/grid-table/utils/cost-center";
import { SortBy } from "@/modules/purchasing-inventory/components/reports/purchaseListingOrderSortBy";
import FormattedQuantity from "@/components/table/cells/FormattedQuantity.vue";
import { formatHours } from "../../../../plugins/formatHours";
import PurchaseOrderLink from "@/components/links/PurchaseOrderLink.vue";

export default {
  components: { PurchaseOrderLink, FormattedQuantity, SourceLink },
  extends: ReportTableWrapper,
  props: {
    format: {
      required: true,
      type: String,
      default: '',
    },
    filters: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {}
  },
  computed: {
    columns() {
      return [
        {
          label: this.$t('Line Item'),
          prop: 'line_item',
          minWidth: 100,
          maxWidth: 250,
        },
        {
          label: this.$t('Cost Type'),
          prop: 'cost_type.name',
          minWidth: 60,
          maxWidth: 100,
        },
        {
          label: this.$t('P.O. #'),
          prop: 'purchase_order.number',
          minWidth: 60,
          maxWidth: 100,
        },
        {
          label: this.$t('Date'),
          prop: 'purchase_order.date',
          minWidth: 80,
          maxWidth: 120,
          component: 'FormattedDate',
        },
        {
          label: this.$t('Vendor'),
          prop: 'vendor.code',
          minWidth: 60,
          maxWidth: 80,
        },
        {
          label: this.$t('Quantity'),
          prop: 'entry.quantity',
          minWidth: 60,
          maxWidth: 100,
          align: 'right',
          component: 'FormattedQuantity',
        },
        {
          label: this.$t('Unit Price'),
          prop: 'entry.unit_rate',
          minWidth: 100,
          maxWidth: 120,
          align: 'right',
          component: 'FormattedPrice',
        },
        {
          label: this.$t('Sales <br> Tax'),
          prop: 'entry.sales_tax_amount',
          minWidth: 60,
          maxWidth: 100,
          align: 'right',
          component: 'FormattedPrice',
        },
        {
          label: this.$t('Freight'),
          prop: 'entry.freight_amount',
          minWidth: 60,
          maxWidth: 100,
          align: 'right',
          component: 'FormattedPrice',
        },
        {
          label: this.$t('Total <br> P.O. Amount'),
          prop: 'entry.extended_amount',
          minWidth: 60,
          maxWidth: 100,
          align: 'right',
          component: 'FormattedPrice',
        },
      ]
    },
  },
  methods: {
    formatHours,
    processLineItems(lineItems) {
      const rows = []
      lineItems.forEach(row => {
        const purchaseOrders = row.purchase_orders || []
        purchaseOrders.forEach(purchaseOrder => {
          purchaseOrder.line_item = row.line_item
          rows.push(...this.processEntries(purchaseOrder))
        })
        rows.push({
          title: this.$t('Line Item Totals:'),
          subtotal: row.totals
        })
      })
      return rows
    },
    processEntries(purchaseOrder) {
      const rows = []
      if (!purchaseOrder.entries) {
        return []
      }
      purchaseOrder.entries.forEach((entry, index) => {
        const mappedEntry = {
          ...entry,
          purchase_order: purchaseOrder.purchase_order,
          vendor: purchaseOrder.vendor,
        }
        if (index === 0) {
          mappedEntry.line_item = purchaseOrder.line_item
        }
        rows.push(mappedEntry)
      })
      return rows
    },
    addGrandTotals(data) {
      const grandTotals = data.reduce((acc, row) => {
        acc.sales_tax_amount += (row.totals?.sales_tax_amount || 0)
        acc.freight_amount += (row.totals?.freight_amount || 0)
        acc.extended_amount += (row.totals?.extended_amount || 0)

        return acc
      },
      {
        sales_tax_amount: 0,
        freight_amount: 0,
        extended_amount: 0,
      })

      this.rows.push({
        title: this.$t('Grand Totals:'),
        subtotal: grandTotals
      })
    },
    composeRows(data) {
      const rows = []
      if (this.filters?.sort_by === SortBy.LineItem) {
        data.forEach(row => {
          rows.push({ header: { job: row.job, fallback: row.job.description, } })
          const lineItemRows = this.processLineItems(row.line_items || [])
          rows.push(...lineItemRows)
          rows.push({
            title: 'Job Totals:',
            subtotal: row.totals
          })
        })
        this.rows = rows

        this.addGrandTotals(data)
      }
      return this.rows
    },
  },
}
</script>
