<template>
  <div>
    <base-button v-bind="$attrs"
                 variant="white"
                 @click="showDialog = true"
    >
      <CopyIcon class="w-4 h-4 mr-3"/>
      {{ $t('Copy Rates') }}
    </base-button>
    <BaseFormDialog v-if="showDialog"
                    :title="$t('Copy Rates')"
                    :open.sync="showDialog"
                    :appendToBody="true"
                    @close="$emit('close')"
    >
      <ValidationObserver v-slot="{ handleSubmit }">
        <base-form :focus-on-first-input="false"
                   :save-text="$t('Copy')"
                   grid-classes="grid grid-cols-1"
                   layout="modal"
                   @cancel="$emit('cancel')"
                   @submit="handleSubmit(onSubmit)"
        >
          <slot/>
        </base-form>
      </ValidationObserver>
    </BaseFormDialog>
  </div>
</template>
<script>
  import { CopyIcon } from 'vue-feather-icons'

  export default {
    components: {
      CopyIcon,
    },
    data() {
      return {
        showDialog: false,
      }
    },
    methods: {
      onSubmit() {
        this.$emit('submit')
        this.showDialog = false
      },
    },
  }
</script>
