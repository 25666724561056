<template>
  <div class="truncate">
    <component
        v-if="sourceProps"
        :is="getLinkComponent"
        :data="sourceProps"
        :show-description="showDescription"
        :show-preview="showPreview"
        :id="dataAsSource ? (sourceProps?.attributes?.source_id || sourceProps?.source_id) : undefined"
        :link-class="linkClass"
    />
    <template v-else>
      <div class="truncate">
        <router-link
          v-if="id && sourceLink"
          :to="sourceLink"
          :title="sourceName"
          :target="target"
          class="truncate"
        >
          <div v-html="sourceName" class="truncate print:flex print:flex-col"/>
        </router-link>
        <div v-else
             v-html="sourceName"
             class="truncate print:flex print:flex-col">
        </div>
      </div>
    </template>
  </div>
</template>
<script>
  import { costCenterTypes } from '@/components/grid-table/utils/cost-center'
  import { getSourceLink, getSourceName } from '@/components/grid-table/utils/cost-center-cell'
  import { gridContext } from "@/components/ag-grid/gridContext";
  import { sourceTypes } from "@/modules/service-billing/util/service-billing";

  const sourceLinkComponents = {
    [costCenterTypes.Job]: 'JobLink',
    [costCenterTypes.WorkOrder]: 'WorkOrderLink',
    [costCenterTypes.Equipment]: 'EquipmentLink',
    [costCenterTypes.Inventory]: 'MaterialLink',
    [costCenterTypes.Material]: 'MaterialLink',
    default: '',
  }

  export default {
    props: {
      id: String,
      data: {
        type: Object,
        default: () => ({}),
      },
      target: String,
      showDescription: {
        type: Boolean,
        default: true
      },
      showPreview: {
        type: Boolean,
        default: true
      },
      isLink: {
        type: Boolean,
        default: true,
      },
      dataAsSource: {
        type: Boolean,
        default: false,
      },
      linkClass: {
        type: String,
        default: '',
      }
    },
    computed: {
      sourceProps() {
        if (this.dataAsSource) {
          return this.data
        }
        return this.get(this.data, 'relationships.source') || this.data.source
      },
      sourceData() {
        return this.data.attributes || this.data
      },
      getLinkComponent() {
        let { cost_center, source_type } = this.sourceData
        if (!cost_center) {
          const costCenterMap = {
            [sourceTypes.Subcontract]: costCenterTypes.Job,
            [sourceTypes.Equipment]: costCenterTypes.Equipment,
            [sourceTypes.Material]: costCenterTypes.Material,
            [sourceTypes.WorkOrder]: costCenterTypes.WorkOrder,
          }
          cost_center = costCenterMap[source_type]
        }
        return sourceLinkComponents[cost_center]
      },
      sourceLink() {
        if (!this.isLink) {
          return ''
        }
        return getSourceLink(this.sourceData);
      },
      sourceName() {
        const html = !this.sourceLink
        let showDescription = this.showDescription
        if (gridContext.isPrintMode) {
          showDescription = false
        }
        return getSourceName(this.sourceData, html, showDescription);
      },
    },
  }
</script>
