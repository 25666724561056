<template>
  <CustomerForm :data="customer"
                @refresh="getCustomer"/>
</template>
<script>
  import CustomerForm from '@/modules/accounts-receivable/components/CustomerForm'

  export default {
    components: {
      CustomerForm,
    },
    computed: {
      customer() {
        return this.$store.state.accountsReceivable.currentCustomer
      },
      customerLoading() {
        return this.$store.state.accountsReceivable.customerLoading
      }
    },
    methods: {
      async getCustomer() {
        await this.$store.dispatch('accountsReceivable/getCustomer', this.$route.params.id)
      },
    },
  }
</script>
