<template>
  <div class="data-table">
    <h4 v-if="title"
        class="my-2 font-bold leading-8 text-gray-800 flex items-center text-base print:text-xs print:leading-4 print:my-1">
      <slot name="title">
        <span v-html="title"></span>
      </slot>
    </h4>
    <filter-tags class="flex w-full md:hidden"
                 :filters="displayMatchFilters"
                 :full-filters="fullMatchFilters"
                 :config-options="getFilterConfig()"
                 @close="clearFilterValue"
    />
    <div v-if="showTableTopSection"
         class="w-full flex print:hidden"
         :class="{'mb-2': !showAction('search')}"
    >
      <div class="flex w-full justify-between table-header overflow-x-auto items-center"
           :class="{
              'compact': compact,
              'custom-header': !!$scopedSlots['custom-header'],
           }"
      >
        <slot name="custom-header">
          <div class="flex">
            <search-input v-if="showAction('search')"
                        v-model="filters.search"
                        :placeholder="searchPlaceholder"
                        data-test="input-table-search"
                        @change="value => refresh({search: value})"
                        @clear="refresh"
            />
            <div v-if="showFiltersDropdown"
                 class="ml-2">
              <TableFilters v-if="canFilter"
                            :config-options="getFilterConfig()"
                            :default-presets="getFilterDefaults()"
                            :active-filters="matchFilters"
                            @on-change="onChangeFilers"
                            ref="entityFilters"
                            @on-display-change="onChangeDisplayFilters"
                            @on-full-filter-change="fullMatchFilters = $event"
              />
            </div>
            <table-refresh-button v-if="showAction('refresh')"
                                  class="mx-2"
                                  @click="forceRefresh()"
            />
          </div>
          <filter-tags class="hidden md:flex"
                       :filters="displayMatchFilters"
                       :config-options="getFilterConfig()"
                       :full-filters="fullMatchFilters"
                       @close="clearFilterValue"
          />
        </slot>
        <div class="flex items-center justify-end actions">
          <div class="flex items-center space-x-2">
            <slot name="additional-actions-before"
                  :selected-rows="selectedRows"
                  :all-selected="allPagesSelected"
                  :total="pagination.total"
            />
          </div>
          <TableActionsDropdown v-if="hasActions">
            <TableActionItem v-if="showAction('download')">
              <DownloadAction :url="`${url}/download`" :entity="entity"/>
            </TableActionItem>
            <TableActionItem v-if="showAction('export')">
              <ExportAction :export-model="entity"
                            :request-params="lastRequestParams"
                            :url="url"
              />
            </TableActionItem>
            <TableActionItem v-if="showAction('upload')">
              <UploadAction :url="url"
                            :entity="entity"
                            @refresh="refresh"
              />
            </TableActionItem>
            <TableActionItem v-if="showAction('import')">
              <router-link v-if="importUrl"
                           :to="importUrl"
                           class="action-item-text">
                <div class="p-2 bg-primary-50 mr-2 rounded-md">
                  <UploadIcon class="w-4 h-4 text-primary-500"/>
                </div>
                <span>{{ $t('Import') }}</span>
              </router-link>
              <ImportAction
                  v-else
                  :model-name="importModel"
                  @save="onImportFinish"/>
            </TableActionItem>
            <TableActionItem v-if="showAction('print')">
              <div
                  :class="{
                      'action-item-text': $slots['print-action']
                  }">
                <slot name="print-action">
                  <PrintAction
                      :entity="entity"
                      :filters="displayMatchFilters"
                  />
                </slot>
              </div>
            </TableActionItem>
            <slot name="dropdown-actions"></slot>
          </TableActionsDropdown>
          <div class="flex ml-2">
            <slot name="additional-actions"
                  :selected-rows="selectedRows"
                  :all-selected="allPagesSelected"
                  :total="pagination.total"
            >
              <base-tooltip
                  :content="!tableData.length ? $t('No data to print.') : ''">
                <ReportPrintAction
                    v-if="isReportTable && !hideActions.includes('print')"
                    v-bind="$attrs"
                    :disabled="!tableData.length"
                />
              </base-tooltip>
            </slot>
            <template v-if="$slots['additional-actions']">
              <base-tooltip :content="!tableData.length ? $t('No data to print.') : ''">
                <ReportPrintAction
                    v-if="isReportTable"
                    v-bind="$attrs"
                    :disabled="!tableData.length"
                />
              </base-tooltip>
            </template>
          </div>
          <table-add-button
              v-if="showAction('add')"
              :addEntityInNewTab="addEntityInNewTab"
              :link="composeAddEntityLink"
              :text="addText"
              :variant="addButtonVariant"
              :disabled="addButtonDisabled"
              class="ml-2"
              @click="$emit('add', $event)"
          />
        </div>
      </div>
    </div>
    <base-table :data="tableData"
                :loading="loading || dataLoading"
                :default-sort="defaultSort"
                :local-sort="false"
                :compact="compact"
                :per-page="pagination.per_page"
                :is-report-table="isReportTable"
                v-bind="$attrs"
                v-on="listeners"
                ref="table"
    >
      <template v-slot:empty>
        <slot name="empty">
          <NoDataRow
              :loading="loading || dataLoading"
              :entity="entity"
              :addText="addText"
              :addEntityInNewTab="addEntityInNewTab"
              :link="composeAddEntityLink"
              :showAction="showAction"
              @add="$emit('add', $event)"
          />
        </slot>
      </template>
      <template v-slot:report-header>
        <slot name="report-header"/>
      </template>
      <template v-slot:thead-infos>
        <slot name="thead-infos"/>
      </template>
      <template v-slot:header="{row, index}">
        <slot name="header" :row="row" :index="index">
          <TableGroupByHeader :row="row" :group-by="groupBy">
            <template>
              <slot name="header-end" :row="row"></slot>
            </template>
          </TableGroupByHeader>
        </slot>
      </template>
      <template v-slot:header-row="{row}">
        <slot name="header-row" :row="row"/>
      </template>
      <template v-slot:html-row="{row}">
        <slot name="html-row" :row="row" :htmlData="row.htmlData"/>
      </template>
      <template v-slot:footer="{row}">
        <slot name="footer" :row="row"/>
      </template>
      <template v-slot:subtotal="{row}">
        <slot name="subtotal" :row="row" :subtotal="row.subtotal"/>
      </template>
      <template v-slot:subtotals="{row}">
        <slot name="subtotals" :row="row" :subtotals="row.subtotals"/>
      </template>
      <template v-slot:expand-content="{row}">
        <slot name="expand-content" :row="row"/>
      </template>
      <base-table-column v-for="column in visibleColumns"
                         :key="`${column.prop}-${tableKey}`"
                         v-bind="column"
                         :draggable="canReorderColumns && isColumnDraggable(column)"
                         :sortable="isColumnSortable(column)"
      >
        <template v-slot:header="{column, index}">
          <slot :name="`${column.prop}-header`"
                :column="column"
                :index="index">
            <template v-if="column.prop === 'select'">
              <TableRowSelect
                  ref="rowHeaderSelect"
                  :selectableRows="selectableRows"
                  :show-dropdown="enableAllSelect"
                  :total="get(pagination ,'total', 0)"
                  @all-selected="onAllSelected"
              />
            </template>
            <span :title="column.labelTooltip || ''" v-html="column.label"/>
          </slot>
        </template>
        <template v-slot="{row, index}">
          <slot :name="column.prop" :row="row" :column="column" :index="index">
            <template v-if="column.component">
              <component :is="column.component"
                         :column="column"
                         :params="column.params || {}"
                         :row="row">
              </component>
            </template>
            <template v-else-if="column.prop === 'select'">
              <div v-if="row.selected" class="absolute inset-y-0 left-0 w-0.5 bg-primary-600"></div>
              <base-checkbox v-model="row.selected" :disabled="rowSelectDisabled(row)"/>
            </template>
            <template v-else-if="column.hideZero">
              <span>{{ getCellContent(row, column) || '' }}</span>
            </template>
            <template v-else-if="column.formatter">
              <span> {{ column.formatter(row, getCellContent(row, column)) }} </span>
            </template>
            <div v-else class="truncate">
              <span>{{ getCellContent(row, column) }}</span>
            </div>
          </slot>
        </template>
      </base-table-column>
      <base-table-column v-if="actions && showActionsColumn || $scopedSlots['extra-actions']"
                         :width="actionsColumnWidth"
                         :maxWidth="actionsColumnWidth"
                         :draggable="false"
                         key="actions"
                         classes="actions-header"
                         rowClasses="table-actions print:hidden"
                         prop="actions"
      >
        <template v-slot:header>
          <div class="flex items-center">
            <slot name="actions-header-before">
              <GroupByDropdown v-if="groupByOptions.length"
                               v-model="groupByModel"
                               :options="groupByOptions"
              />
            </slot>
            <manage-columns
                v-if="url && canManageColumns"
                key="manage-columns"
                :data="tableData"
                :columns="columns"
                :table-key="visibleColumnsKey"
                :table-ref="$refs.table"
                :visible-columns="visibleColumns"
                :is-expandable="$attrs['is-expandable'] || $attrs.isExpandable"
                :add-default-columns="addDefaultColumns"
                @update-columns="updateColumns"/>
          </div>
        </template>
        <template v-slot="{row, index}">
          <slot name="extra-actions-before" :row="row" :index="index"/>
          <table-view-button v-if="$isAuthorized('authorizedToShow', row) && showAction('view')"
                             :row="row"
                             :skip-focus="skipActionButtonFocus"
                             :link="getViewEntityPath(row.id)"
          />
          <table-edit-button v-if="$isAuthorized('authorizedToUpdate', row) && showAction('edit')"
                             :openEntityInNewTab="openEntityInNewTab"
                             :row="row"
                             :skip-focus="skipActionButtonFocus"
                             :link="composeEditEntityLink(row)"
                             @click="$emit('edit', row, index)"
          />
          <table-delete-button v-if="$isAuthorized('authorizedToDelete', row) && showAction('delete')"
                               :skip-focus="skipActionButtonFocus"
                               @click="onDelete(row, index)"
          />
          <slot name="extra-actions" :row="row" :index="index"/>
        </template>
      </base-table-column>
      <template v-slot:summary="{column}">
        <slot :name="`${column.prop}_summary`">
          <span
              v-if="column.summary"
              v-html="column.summary({ column, data: tableData })"
              class="font-medium"
              :class="{
                     'flex justify-end': column.summaryAlignment === 'right',
                     'flex justify-start': column.summaryAlignment === 'left',
                     'flex justify-center': column.summaryAlignment === 'center',
                 }"
          />
        </slot>
      </template>
      <template #footer-row>
        <slot name="footer-row"></slot>
      </template>
    </base-table>
    <slot name="after-table"/>
    <div v-if="showPagination"
         class="w-full flex justify-between table-pagination print:hidden p-2"
         :class="{
            'pt-4': !compact,
        }"
    >
      <div class="footer-left">
        <slot name="footer-left"></slot>
      </div>
      <div class="w-full flex items-end md:items-center justify-between">
        <div class="flex flex-row items-center text-gray-700 text-sm mt-2 md:mt-0">
          <span class="mr-2 md:mr-4 mb-1 md:mb-0 text-gray-900">
            {{ $t('Total ') }} {{ pagination.total }}
          </span>
          <el-select class="pagination-select"
                     size="mini"
                     v-model="pagination.per_page">
            <el-option v-for="value in perPageOptions"
                       :key="value"
                       :label="value"
                       :value="value">
            </el-option>
          </el-select>
        </div>
        <template v-show="tableData.length > 0">
          <el-pagination class="hidden md:flex"
                         v-show="tableData.length > 0"
                         @current-change="refresh"
                         :current-page.sync="pagination.current_page"
                         :page-sizes="perPageOptions"
                         :page-size="pagination.per_page"
                         :pager-count="5"
                         layout="prev, pager, next, jumper"
                         :total="pagination.total">
          </el-pagination>
          <el-pagination class="flex md:hidden mobile-pagination"
                         :current-page.sync="pagination.current_page"
                         :page-sizes="perPageOptions"
                         :page-size="pagination.per_page"
                         :total="pagination.total"
                         layout="jumper, prev, next"
                         @current-change="refresh"
          >
          </el-pagination>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
  import { FilePlusIcon, FilterIcon, PlusIcon, RefreshCwIcon, UploadIcon } from 'vue-feather-icons'
  import { Option, Pagination, Select } from 'element-ui'
  import axios from 'axios'
  import get from 'lodash/get'
  import isEmpty from 'lodash/isEmpty'
  import capitalize from 'lodash/capitalize'
  import cloneDeep from 'lodash/cloneDeep';
  import ImportAction from '@/components/table/actions/import/ImportAction';
  import PrintAction from '@/components/table/actions/PrintAction';
  import ExportAction from '@/components/table/actions/export/ExportAction';
  import DownloadAction from '@/components/table/actions/download/DownloadAction';
  import UploadAction from '@/components/table/actions/upload/UploadAction';
  import TableFilters from '@/components/table/TableFilters';
  import FilterTags from '@/components/table/FilterTags';
  import ManageColumns from '@/components/table/ManageColumns';
  import { getFilterConfig } from '@/components/table/getFilterConfig';
  import Cache from '@/utils/Cache';
  import TableEditButton from '@/components/table/actions/TableEditButton';
  import TableDeleteButton from '@/components/table/actions/TableDeleteButton';
  import TableViewButton from '@/components/table/actions/TableViewButton';
  import TableRefreshButton from '@/components/table/actions/TableRefreshButton';
  import TableAddButton from '@/components/table/actions/TableAddButton';
  import { initKeyboardFocusEvents } from '@/components/table/utils/focusUtils';
  import TableRowSelect from '@/components/common/checkbox/TableRowSelect';
  import TableActionsDropdown from '@/components/table/actions/TableActionsDropdown';
  import TableActionItem from '@/components/table/actions/TableActionItem';
  import TableGroupByHeader from '@/components/table/groupBy/TableGroupByHeader';
  import GroupByDropdown from '@/components/table/groupBy/GroupByDropdown';
  import i18n from '@/i18n';
  import { groupTableData } from '@/components/table/groupBy/groupTableData';
  import NoDataRow from '@/components/table/NoDataRow';
  import { tableColumns } from '@/components/table/tableColumns';
  import pluralize from 'pluralize';
  import ReportPrintAction from '@/components/table/ReportPrintAction'
  import SearchInput from "@/components/form/SearchInput.vue";

  export default {
    inheritAttrs: false,
    components: {
      ReportPrintAction,
      NoDataRow,
      SearchInput,
      FilterIcon,
      PlusIcon,
      UploadIcon,
      TableAddButton,
      GroupByDropdown,
      TableGroupByHeader,
      TableEditButton,
      TableViewButton,
      TableDeleteButton,
      TableRefreshButton,
      TableRowSelect,
      ImportAction,
      ExportAction,
      UploadAction,
      FilePlusIcon,
      RefreshCwIcon,
      ManageColumns,
      TableFilters,
      FilterTags,
      PrintAction,
      TableActionItem,
      DownloadAction,
      TableActionsDropdown,
      [Select.name]: Select,
      [Option.name]: Option,
      [Pagination.name]: Pagination,
      ...tableColumns,
    },
    props: {
      compact: {
        type: Boolean,
        default: false,
      },
      data: {
        type: Array,
        default: () => [],
      },
      editEntityUrlQuery: {
        type: String,
        default: '/{ID}/edit',
      },
      baseEntityPath: {
        type: String,
        default: null,
      },
      addEntityUrlQuery: {
        type: String,
        default: '',
      },
      openEntityInNewTab: {
        type: Boolean,
        default: true,
      },
      skipActionButtonFocus: {
        type: Boolean,
        default: true,
      },
      addEntityInNewTab: {
        type: Boolean,
        default: false,
      },
      columns: {
        type: Array,
        default: () => [],
      },
      actions: {
        type: String,
        default: '',
      },
      hideActions: {
        type: String,
        default: '',
      },
      url: {
        type: String,
        default: '',
      },
      urlQuery: {
        type: String,
        default: '',
      },
      title: {
        type: String,
        default: '',
      },
      deleteAction: {
        type: Function,
      },
      dataLoading: {
        type: Boolean,
        default: false,
      },
      showPagination: {
        type: Boolean,
        default: true,
      },
      permission: {
        type: String,
      },
      showActionsColumn: {
        type: Boolean,
        default: true,
      },
      enableAllSelect: {
        type: Boolean,
        default: true,
      },
      importModel: {
        type: String,
        default: 'accounts',
      },
      importUrl: {
        type: String,
      },
      perPage: {
        type: Number,
      },
      actionType: {
        type: String,
        default: 'get',
      },
      entity: {
        type: String,
        default: '',
      },
      defaultSort: {
        type: String,
        default: '',
      },
      defaultFilters: {
        type: Boolean,
        default: true,
      },
      defaultMatchFilters: {
        type: [Object, Boolean],
        default: () => ({}),
      },
      urlParams: {
        type: Object,
        default: () => ({}),
      },
      dataMapFunction: {
        type: [Function, Object],
      },
      selectRowFilter: {
        type: [Function, Object],
      },
      deleteDescription: {
        type: String,
      },
      deleteActionText: {
        type: String,
        default: 'Delete',
      },
      deleteTitle: {
        type: String,
      },
      customEntityFilters: {
        type: Array,
        default: () => [],
      },
      isDynamicData: {
        type: Boolean,
        default: false,
      },
      canReorderColumns: {
        type: Boolean,
        default: true,
      },
      canManageColumns: {
        type: Boolean,
        default: true,
      },
      addDefaultColumns: {
        type: Boolean,
        default: true,
      },
      fetchData: {
        type: Boolean,
        default: true,
      },
      viewEntityUrlQuery: {
        type: String,
        default: '',
      },
      searchPlaceholder: {
        type: String,
        default: i18n.t('Search...'),
      },
      groupBy: {
        type: String,
        default: '',
      },
      groupByOptions: {
        type: Array,
        default: () => [],
      },
      smallHeader: {
        type: Boolean,
        default: false,
      },
      addText: {
        type: String,
        default: 'New',
      },
      addButtonVariant: {
        type: String,
        default: 'primary',
      },
      addButtonDisabled: {
        type: Boolean,
        default: false,
      },
      rowSelectDisabled: {
        type: Function,
        default: () => false,
      },
      hideTableTopSection: {
        type: Boolean,
        default: false,
      },
      totalRows: Number,
      isReportTable: Boolean,
    },
    data() {
      return {
        tableData: this.data,
        originalTableData: [],
        visibleColumns: [],
        loading: false,
        perPageOptions: [5, 10, 15, 20, 25, 50, 100, 250, 500],
        tableKey: 1,
        allPagesSelected: false,
        lastRequestParams: {},
        pagination: {
          current_page: 1,
          from: 1,
          last_page: 1,
          per_page: this.perPage || 50,
          to: 5,
          total: this.data.length || 5,
        },
        filters: {
          search: '',
        },
        matchFilters: {
          ...this.defaultMatchFilters,
        },
        advancedFilters: [],
        fullMatchFilters: {},
        displayMatchFilters: {
          ...this.defaultMatchFilters,
        },
        sort: this.defaultSort || '-updated_at',
        filterOptions: {},
        filtersUrl: '',
      }
    },
    computed: {
      actionsArray() {
        return this.actions.split(',').map(action => action.trim().toLowerCase())
      },
      listeners() {
        return {
          ...this.$listeners,
          sort: this.onSort,
        }
      },
      hasFilters() {
        return this.getFilterConfig().length > 0
      },
      showFiltersDropdown() {
        return this.defaultFilters && this.filtersUrl && !isEmpty(this.filterOptions)
      },
      composeAddEntityLink() {
        return this.addEntityUrlQuery ? this.addEntityUrlQuery : `${this.$route.path}/add`
      },
      visibleColumnsKey() {
        return this.url + this.$route.path + this.columns.length
      },
      actionsColumnWidth() {
        return '1%'
      },
      selectableRows() {
        return this.tableData.filter(row => {
          return this.isRowSelectable(row) && !this.rowSelectDisabled(row)
        })
      },
      selectedRows() {
        return this.tableData.filter(row => row.selected)
      },
      hasActions() {
        const actions = ['upload', 'import', 'export', 'download', 'print']
        const hasActionSlot = this.$slots['dropdown-actions'] || this.$scopedSlots['dropdown-actions']
        return actions.some(action => this.showAction(action)) || hasActionSlot
      },
      hasAdditionalActions() {
        return this.$slots['additional-actions'] || this.$slots['additional-actions-before']
      },
      showTableTopSection() {
        if (this.hideTableTopSection) {
          return false
        }

        const hasTableActions = this.showAction('search') || this.showAction('refresh') || this.showAction('add')
        return hasTableActions || this.title || this.hasActions || this.hasAdditionalActions || this.isReportTable
      },
      canFilter() {
        return !this.hideActions.includes('filters')
      },
      groupByModel: {
        get() {
          return this.groupBy
        },
        set(value) {
          this.$emit('update:groupBy', value)
        },
      },
    },
    methods: {
      get,
      getCellContent(row, column) {
        // * In case we group data but want to filter by grouped column
        const hiddenValue = get(column, 'hiddenValue', false)
        if (hiddenValue) return ''

        const content = get(row, column.prop)
        const hasContent = content !== undefined && content !== null && content !== ''
        return hasContent ? content : ''
      },
      getViewEntityPath(rowId) {
        if (!this.viewEntityUrlQuery) {
          const path = this.baseEntityPath || this.$route.path
          return `${path}/${rowId}/view`
        }
        return `${this.viewEntityUrlQuery}?id=${rowId}`
      },
      composeEditEntityLink(row) {
        const path = this.baseEntityPath || this.$route.path
        return `${path}${this.editEntityUrlQuery.replace('{ID}', row.id)}`
      },
      onSort(column) {
        column.toggleSort()
        let direction = column.sortDirection
        direction = direction === 'desc' ? '-' : ''
        let prop = column.prop
        prop = prop.replace('attributes.', '')

        this.sort = direction + prop
        if (column.sortDirection === '') {
          this.sort = ''
        }
        this.refresh()
      },
      isRowSelectable(row) {
        if (row.header) {
          return false
        }
        if (this.selectRowFilter) {
          return this.selectRowFilter(row)
        }
        return true
      },
      selectRows(value) {
        this.tableData.forEach(row => {
          if (row.header) {
            return
          }
          row.selected = value
        })
        if (value || !this.$refs?.rowHeaderSelect[0]) {
          return
        }
        try {
          this.$refs.rowHeaderSelect[0].allPagesSelected = false
        } catch (err) {
          console.log(err, 'Could not find rowHeaderSelect ref')
        }
      },
      isColumnSortable(column) {
        let prop = column.prop || ''
        prop = prop.replace('attributes.', '')
        let sortables = this.filterOptions.sortables || []

        if (!sortables.length) {
          return column.sortable || false
        }

        sortables = sortables.map(s => s.column)
        return sortables.includes(prop)
      },
      isColumnDraggable(column) {
        const exceptions = ['select']
        if (exceptions.includes(column.prop)) {
          return false
        }
        return column.draggable
      },
      showAction(action) {
        if (this.hideActions.includes(action)) {
          return false
        }
        const actionToPermissionMapping = {
          view: 'show',
          add: 'store',
          edit: 'update',
          delete: 'delete',
        }
        const fullPermission = `${this.permission}_${actionToPermissionMapping[action]}`
        const exceptionActions = ['search', 'refresh', 'import', 'export', 'download', 'upload', 'print']
        const skipPermissionCheck = exceptionActions.includes(action)

        const includedInActions = this.actionsArray.includes(action)

        if (skipPermissionCheck) {
          return includedInActions
        }

        if (!action) {
          return false
        }

        if (this.permission) {
          return this.$can(fullPermission)
        }
        return this.actionsArray.includes(action)
      },
      getFilterConfig() {
        const filters = getFilterConfig(this.filterOptions.matches)
        if (!this.customEntityFilters.length) {
          return filters
        }
        return filters.concat(this.customEntityFilters)
      },
      getFilterDefaults() {
        let map = {}
        if (!this.filterOptions.matches) {
          return {}
        }
        this.filterOptions.matches.forEach(f => {
          if (typeof f.type === 'object' || f.type === 'boolean' || f.type === 'bool') {
            map[f.column] = false
          } else {
            map[f.column] = ''
          }
        })
        return {
          ...map,
          ...this.displayMatchFilters,
        }
      },
      clearFilterValue(key, fullValue) {
        if (this.matchFilters[key]) {
          this.matchFilters[key] = typeof this.matchFilters[key] === 'boolean' ? false : ''
        }
        if (this.displayMatchFilters[key]) {
          this.displayMatchFilters[key] = typeof this.displayMatchFilters[key] === 'boolean' ? false : ''
        }
        if (typeof fullValue === 'object' && fullValue.advanced) {
          let index = this.advancedFilters.findIndex(f => f.key === fullValue.key)
          if (index !== -1) {
            this.advancedFilters.splice(index, 1)
          }
        }

        if (this.$refs?.entityFilters) {
          this.$refs.entityFilters.resetFilter(key)
        }

        this.refresh()
      },
      onChangeFilers(query, fullQuery) {
        if (!query) {
          return
        }
        const advancedFilters = []
        const baseFilters = { ...query }
        for (let key in fullQuery) {
          let value = fullQuery[key]
          if (typeof value === 'object' && value.advanced) {
            advancedFilters.push(value)
            delete baseFilters[key]
          }
        }
        let newFilters = {
          ...this.matchFilters,
          ...baseFilters,
        }
        for (let key in newFilters) {
          let val = newFilters[key]
          if (Array.isArray(val)) {
            newFilters[key] = val.join(',')
          }
          if (!val) {
            delete newFilters[key]
          }
        }
        this.advancedFilters = advancedFilters
        this.matchFilters = newFilters
        this.refresh()
      },
      onChangeDisplayFilters(filters) {
        if (!filters) {
          return
        }
        let newFilters = {
          ...filters,
        }
        for (let key in newFilters) {
          if (!newFilters[key]) {
            delete newFilters[key]
          }
        }
        this.displayMatchFilters = newFilters
      },
      async goToLastPage() {
        if (this.pagination.current_page === this.pagination.last_page) {
          return
        }
        this.pagination.current_page = this.pagination.last_page
        await this.refresh()
      },
      async goToNextPage() {
        if (this.pagination.current_page >= this.pagination.last_page) {
          return
        }
        this.pagination.current_page++
        await this.refresh()
      },
      assignData(data) {
        if (this.isDynamicData) {
          this.tableData = data
          this.updatePaginationTotal(this.data.length)
          return
        }
        this.tableData = data.map(row => {
          const selected = this.isRowSelectable(row) ? this.allPagesSelected : false
          return {
            ...row,
            selected,
          }
        })
        if (!this.data.length) {
          return
        }
        this.updatePaginationTotal(this.data.length)
      },
      updatePaginationTotal(dataLength) {
        const total = this.totalRows || dataLength

        this.pagination = {
          ...this.pagination,
          total,
        }
      },
      onAllSelected(value) {
        this.allPagesSelected = value
        this.$emit('all-selected', value)
      },
      getMatchFilters() {
        let filters = {}
        for (let key in this.matchFilters) {
          if (this.matchFilters[key]) {
            filters[key] = this.matchFilters[key]
          }
        }
        return filters
      },
      getAdvancedFilters() {
        const filters = this.advancedFilters.filter(f => {
          const key = this.get(Object.keys(f.value), '[0]')
          const value = this.get(f.value, key, [])
          return Array.isArray(value) && value.length > 0
        })
        return btoa(JSON.stringify(filters))
      },
      forceRefresh() {
        this.refresh({}, true)
      },
      async refresh(overrideOptions = {}, force = false) {
        if (!this.url) {
          this.$emit('force-pagination', this.pagination)
          return
        }
        try {
          this.loading = true
          let params = {
            page: this.pagination.current_page,
            perPage: this.pagination.per_page,
          }
          if (this.filters.search) {
            params.search = this.filters.search
          }

          params = {
            sort: this.sort,
            ...params,
            ...this.getMatchFilters(),
            ...this.urlParams,
            ...overrideOptions,
          }
          if (this.advancedFilters.length) {
            params.filters = this.getAdvancedFilters()
          }

          if (overrideOptions.sort) {
            this.sort = overrideOptions.sort
          }

          const fullUrl = this.url + this.urlQuery
          const actionType = this.actionType
          let result = {}

          if (actionType === 'get') {
            result = await Cache.getRequest(fullUrl, {
              params,
              invalidateCache: force,
            })
          } else {
            result = await axios[actionType](fullUrl, { ...params })
          }

          this.lastRequestParams = params
          const { data, meta, links } = result
          this.pagination = {
            ...meta,
            per_page: this.pagination.per_page,
          }
          if (this.showPagination) {
            this.$store.commit('table/SET_CURRENT_PER_PAGE', this.pagination.per_page)
          }

          this.originalTableData = data
          this.assignData(data)

          if (this.dataMapFunction) {
            this.tableData = this.dataMapFunction(this.originalTableData)
          }

          if (this.groupBy) {
            this.tableData = groupTableData(this.tableData, this.groupBy)
          }
          this.loading = false

          if (links?.filters) {
            this.filtersUrl = links.filters

            await this.getFilters()
          }
          this.$emit('data-fetch', this.tableData)
          this.$emit('meta-fetch', meta)
          this.$emit('on-apply-filters', this.matchFilters)
        } catch (err) {
          if (err.handled) {
            return
          }
          console.warn(err)
          this.$error(this.$t('Could not fetch the table data'))
        } finally {
          this.loading = false
        }
      },
      async onDelete(row, index) {
        try {
          let entityName = capitalize(this.entity || this.$t('row'))
          entityName = pluralize(entityName, 1)

          const defaultDeleteTitle = this.$t(`Delete ${entityName} ?`)
          const defaultDeleteMessage = this.$t(`Are you sure you want to delete this ${entityName}? The data will be removed on our servers. This action cannot be undone.`)

          const title = this.deleteTitle || defaultDeleteTitle
          const description = this.deleteDescription || defaultDeleteMessage

          const confirmed = await this.$deleteConfirm({
            title,
            description,
            buttonText: this.$t(this.deleteActionText),
          })

          if (!confirmed) {
            return
          }

          if (this.deleteAction) {
            await this.deleteAction(row, index)
            return
          }

          await axios.delete(`${this.url}/${row.id}`)

          if (index === undefined || index < 0) {
            return
          }
          this.tableData.splice(index, 1)
        } catch (err) {
          console.warn(err)
          this.$error('Could not delete the specified row')
        }
        this.$emit('delete', row)
      },
      async getById(id) {
        if (!this.url || !this.showAction('edit')) {
          return
        }
        let url = `${this.url}/${id}`
        if (this.urlQuery) {
          url += this.urlQuery
        }

        const { data } = await axios.get(`${url}`, {
          params: {
            ...this.urlParams,
          },
        })

        this.$emit('edit', data)
      },
      async onImportFinish() {
        await this.refresh()
      },
      async getFilters() {
        if (!this.url || !this.defaultFilters || !this.filtersUrl || !isEmpty(this.filterOptions)) {
          return
        }
        try {
          const url = this.filtersUrl.replace('/api', '')
          const filterParams = {
            include: 'matches,sortables',
          }
          if (this.urlQuery.includes('type=cost')) {
            filterParams.type = 'cost'
          } else if (this.urlQuery.includes('type=income')) {
            filterParams.type = 'income'
          }
          const result = await Cache.getRequest(url, {
            ttl: 60 * 60 * 1000, // 1 hour
            params: filterParams,
          })
          this.filterOptions = {
            sortables: [],
            matches: [],
          }
          result.data.forEach(filter => {
            if (filter.type === 'sortables' || filter.key === 'sortables') {
              this.filterOptions.sortables.push(filter)
            } else {
              this.filterOptions.matches.push(filter)
            }
          })
        } catch (err) {
          console.warn('Could not retrieve filters')
        }
      },
      updateColumns(value) {
        this.visibleColumns = cloneDeep(value)
        this.tableKey = Math.random().toString()
      },
      initVisibleColumns() {
        let visibleTableColumns = this.$store.state.table.visibleColumns[this.visibleColumnsKey];

        if (!visibleTableColumns) {
          visibleTableColumns = this.columns.filter(c => c.visible !== false)
        }

        this.visibleColumns = visibleTableColumns.filter(col => {
          return !col?.renderIf || col.renderIf()
        }).map((_col, index) => {
          _col.order = index
          _col.showDrag = false
          return _col
        })

        this.updateColumns(this.visibleColumns)
      },
      onKeyDown(event) {
        if (event.target && event.target.nodeName === 'INPUT') {
          return
        }
        if (event.code === 'Equal' || event.key === '+' || event.key === '=') {

          if (!this.showAction('add')) {
            return
          }
          event.preventDefault()
          if (this.addEntityInNewTab) {
            this.$router.push(this.composeAddEntityLink)
          } else {
            this.$emit('add')
          }
        }
      },
      onChangeDefaultMatchFilters(filters) {
        this.matchFilters = this.displayMatchFilters = filters
      },
    },
    created() {
      if (!this.fetchData) {
        return
      }
      this.refresh()
    },
    mounted() {
      document.addEventListener('keydown', this.onKeyDown)
      initKeyboardFocusEvents(this.$el)
      this.initVisibleColumns()
    },
    beforeDestroy() {
      document.removeEventListener('keydown', this.onKeyDown)
    },
    watch: {
      '$route.query.id': {
        immediate: true,
        async handler(value) {
          if (value && this.url) {
            await this.getById(value)
          }
        },
      },
      data(value) {
        this.originalTableData = cloneDeep(value)
        this.assignData(value)
        if (this.groupBy) {
          this.tableData = groupTableData(this.tableData, this.groupBy)
        }
      },
      url() {
        if (!this.fetchData) {
          return
        }
        this.refresh()
      },
      urlParams: {
        deep: true,
        handler() {
          this.refresh()
        },
      },
      urlQuery() {
        this.refresh()
      },
      columns() {
        this.initVisibleColumns()
      },
      'pagination.per_page'(value) {
        if (!value) {
          return
        }
        this.pagination.current_page = 1
        this.$store.commit('table/SET_CURRENT_PER_PAGE', value)
        this.$nextTick(async () => {
          await this.refresh()
        })
      },
      groupBy(groupByValue) {
        if (!groupByValue) {
          return
        }
        this.tableData = groupTableData(this.originalTableData, groupByValue)
      },
      defaultMatchFilters(filters) {
        this.onChangeDefaultMatchFilters(filters)
      },
    },
  }
</script>
<style lang="scss">


  .mobile-pagination {
    span.el-pagination__jump {
      margin-left: 10px !important;
    }

    .el-pagination .btn-next .el-icon, .el-pagination .btn-prev,
    .el-pagination .btn-next .el-icon, .el-pagination .btn-next {
      .el-icon {
        @apply text-lg;
      }
    }
  }
</style>
<style scoped lang="scss">
  .pagination-select {
    width: 80px;
  }

  .table-pagination {
    z-index: 8;
    @apply fixed bottom-0 left-0 bg-gray-50;
    @screen sm {
      @apply relative bg-gray-50;
    }
  }

  .table-header {
    &.no-overflow {
      overflow: inherit;
    }

    &.compact {
      @apply my-2;
    }

    &:not(.compact) {
      @apply mb-4 mt-2;
    }
  }

  .actions {
    @apply sm:mb-auto sm:w-auto;
  }
</style>
