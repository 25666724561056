<template>
  <div>
    <infinite-loading direction="top"
                      :distance="500"
                      @infinite="infiniteScroll">
      <span slot="spinner"/>
      <span slot="no-more"/>
      <span slot="no-results"/>
    </infinite-loading>
    <ul class="space-y-8 h-full">
      <li v-if="!notes.length && !loading"
          class="flex w-full h-full items-center justify-center">
        <h4 class="text-gray-700">
          {{ $t('No notes created yet') }}
        </h4>
      </li>
      <li v-for="(note, index) in notes"
          :key="`note-${index}`">
        <div class="flex w-full space-x-3"
             v-click-outside="closeActionMenus">
          <div
            v-if="!note?.meta?.is_system_generated"
            class="flex text-white justify-center items-center w-10 h-10 rounded-full border border-primary-500 bg-primary-400">
            {{ getUserShortAbbr(note) }}
          </div>
          <div v-else class="w-10 h-10 border border-gray-300 flex items-center justify-center bg-gray-50 rounded-full">
            <ActivityIcon class="w-4 h-4 text-gray-500"/>
          </div>
          <div class="flex-1 flex items-start justify-between">
            <div :class="{'flex items-center mt-1 space-x-2': note?.meta?.is_system_generated}">
              <div class="text-sm">
                <UserLink
                  :id="getCreatorOrEditor(note)"
                  class="font-medium user-link"
                  :class="{
                    'border-b-2': isCurrentUser(note),
                    'system-generated-link': note?.meta?.is_system_generated,
                  }"
                />
              </div>
              <div
                :class="{
                  'text-gray-900': isCurrentUser(note),
                  'text-xs text-gray-500 mt-0.5': note?.meta?.is_system_generated,
                  'text-sm text-gray-700 mt-1': !note?.meta?.is_system_generated,
                }">
                <p v-html="getContent(note.content)"/>
              </div>
            </div>
            <div class="text-sm space-x-2 mt-0.5">
              <span class="flex-none inline text-xs leading-5 text-gray-500">
                {{ formatCreatedAt(note.created_at) }}
              </span>
            </div>
          </div>
          <div class="flex justify-end relative min-w-[32px]">
            <button
              v-show="!note?.meta?.is_system_generated"
              type="button"
              @click.prevent.stop="triggerActionMenu(note)"
              class="group relative w-8 h-8 bg-white rounded-full inline-flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              id="options-menu-0" aria-expanded="false" aria-haspopup="true">
              <span class="sr-only">Open options menu</span>
              <span class="flex items-center justify-center h-full w-full rounded-full">
                <svg class="w-4 h-4 text-gray-400 group-hover:text-gray-500"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                     aria-hidden="true">
                  <path
                    d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"/>
                </svg>
              </span>
            </button>
            <transition enter-active-class="transition ease-out duration-100"
                        enter-class="transform opacity-0 scale-95"
                        enter-to-class="transform opacity-100 scale-100"
                        leave-active-class="transition ease-in duration-75"
                        leave-class="transform opacity-100 scale-100"
                        leave-to-class="transform opacity-0 scale-95"
                        appear
            >
              <div v-if="note['actions']"
                   :key="`${index}-${note['actions']}`"
                   class="origin-top-right absolute z-10 top-0 right-9 w-28 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                   role="menu" aria-orientation="vertical" aria-labelledby="options-menu-0">
                <div class="py-1 flex flex-col" role="none">
                  <base-button variant="green-link"
                               @click="onEditNote(note)">
                    <Edit2Icon class="w-4 h-4 mr-1"/>
                    {{ $t('Edit') }}
                  </base-button>
                  <base-button variant="danger-link"
                               @click="onDeleteNote(note)">
                    <Trash2Icon class="w-4 h-4 mr-1"/>
                    {{ $t('Delete') }}
                  </base-button>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
  import axios from 'axios'
  import { Edit2Icon, Trash2Icon, ActivityIcon } from 'vue-feather-icons'
  import { globalResources } from "@/components/form/util";
  import format from "date-fns/format";
  import parseISO from "date-fns/parseISO";
  import store from "@/store";

  export default {
    components: {
      Edit2Icon,
      Trash2Icon,
      ActivityIcon,
    },
    props: {
      data: {
        type: Array,
        default: () => [],
      },
      perPage: {
        type: Number,
        default: 50,
      },
      baseUrl: {
        type: String,
        default: '',
      },
      loading: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        notes: [],
        page: 2,
      }
    },
    computed: {
      currentUserId() {
        return this.$store.state.auth.user.id
      },
      allUsers() {
        return this.$store.getters['globalLists/getResourceList'](globalResources.Users) || []
      },
    },
    methods: {
      getContent(content) {
        return content.replace('the entity', 'the record')
      },
      formatCreatedAt(date) {
        const parsedDate = parseISO(date)
        const dateFormat = store.getters['settings/getByKey']('datetime_format') || 'MM/dd/yyyy HH:mm'
        return format(parsedDate, dateFormat)
      },
      async infiniteScroll($state) {
        try {
          const { data } = await axios.get(`${this.baseUrl}`, {
            params: {
              sort: 'created_at',
              perPage: this.perPage,
              page: this.page,
            },
          })
          if (data.length > 1) {
            this.page += 1;
            const result = data.reverse().map(note => ({ id: note.id, ...note.attributes, ...note.relationships }))
            this.notes = [
              ...result,
              ...this.notes,
            ]
            $state.loaded();
          } else {
            $state.complete();
          }
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Something went wrong please try again.'))
        }
      },
      closeActionMenus() {
        this.notes = this.notes.map(note => {
          delete note?.actions
          return note
        })
      },
      getUserShortAbbr(note) {
        const userId = this.getCreatorOrEditor(note)
        const user = this.allUsers.find(u => u.id === userId) || {}
        let name = user?.name || ''
        let result = name.split(' ')

        if (result.length < 2) {
          result = result[0].slice(0, 2).toUpperCase()
        } else {
          result = result[0].slice(0, 1).toUpperCase() + result[1].slice(0, 1).toUpperCase()
        }

        return result
      },
      isCurrentUser(note) {
        const userId = this.getCreatorOrEditor(note)
        return +userId === +this.currentUserId
      },
      getCreatorOrEditor(note) {
        if (note.updated_by) {
          return note.updated_by
        }
        return note.created_by
      },
      triggerActionMenu(note) {
        this.closeActionMenus()
        const state = !note?.actions
        this.$set(note, 'actions', state)
      },
      async submitForm(note) {
        try {
          if (note.id) {
            await axios.put(`${this.baseUrl}/${note.id}`, {
              ...note,
            })
            const index = this.getNoteIndex(note)
            this.notes.splice(index, 1, note)
          } else {
            const { data } = await axios.post(`${this.baseUrl}`, {
              ...note,
              title: `Note ${this.notes.length}`,
            })
            await this.updateNoteList(data.id)
          }
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Something went wrong please try again.'))
        } finally {
          this.closeActionMenus()
        }
      },
      async updateNoteList(noteID) {
        const { data } = await axios.get(`${this.baseUrl}/${noteID}`)
        const newNote = { id: data.id, ...data.attributes, ...data.relationships }
        this.notes.push(newNote)
      },
      async onDeleteNote(note) {
        const confirmed = await this.$deleteConfirm({
          title: this.$t(`Delete note #${note.id}`),
          description: this.$t(`Are you sure you want to delete this note?
           We will still keep a copy of it on our servers.`),
        })
        if (!confirmed) {
          return
        }
        try {
          await axios.delete(`${this.baseUrl}/${note.id}`)
          this.$success(this.$t('Note deleted successfully!'))
          const index = this.getNoteIndex(note)
          this.notes.splice(index, 1)
          this.$emit('on-delete')
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not delete the note.'))
        }
      },
      getNoteIndex(note) {
        return this.notes.findIndex(el => el.id === note.id)
      },
      onEditNote(note) {
        this.$emit('on-edit', note)
        this.triggerActionMenu(note)
      },
      initNotes(notes) {
        if (this.notes?.length) {
          return
        }
        this.notes = notes
      },
    },
    watch: {
      data(value) {
        if (!value) {
          return
        }
        this.initNotes(value)
      },
    },
  }
</script>
<style lang="scss">
.user-link a{
  @apply font-medium text-gray-900 text-xs;
}
</style>
