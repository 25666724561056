import store from '@/store'

export const historySelectionGroupBy = {
  Account: 'account',
  Journal: 'journal',
  PostedDate: 'posted_date',
  SubAccount: 'subaccount',
  FiscalPeriod: 'fiscal_period',
}

export function getOrderedMoths() {
  const periods = store.getters['company/getPeriods']
  return periods.map(period => period.name)
}

export const checkDepositRegisterSortByTypes = {
  ReferenceDate: 'reference_date',
  ReferenceNumber: 'reference_number',
  'F/Period Ref Date': 'fyp_reference_date',
  'F/Period Ref Number': 'fyp_reference_number',
}
