<template>
  <ProofListing
      entity="billing"
      url="/restify/billings/actions?action=post-progress-billings-proof-listing"
      post-url="/restify/billings/action?action=post-billings"
      ids-field="billing_ids"
      @data-fetch="data = $event"
      #default="{ loading }"
  >
    <AgDataTable
        :data="flatData"
        :data-loading="loading"
        :columns="columns"
        :pagination="false"
        :groupDefaultExpanded="-1"
        :compact="true"
        :no-borders="true"
        :groupIncludeFooter="true"
        :suppressAggFuncInHeader="true"
        :sortable="false"
        :groupRowRendererParams="groupRowRendererParams"
        groupDisplayType="groupRows"
        domLayout="autoHeight"
    />

    <div class="grid lg:grid-cols-2 gap-4 mt-8 print:break-inside-avoid">
      <div>
        <h5 class="form-section-title">
          {{ $t('Account Summary') }}
        </h5>
        <ProofListingAccountSummary
            :data="data.account_summary"
            :loading="loading"
        />
      </div>
      <div>
        <h5 class="form-section-title">
          {{ $t('Totals') }}
        </h5>
        <AgDataTable
            :data="totalsData"
            :columns="totalColumns"
            :pagination="false"
            :no-borders="true"
            :compact="true"
            dom-layout="autoHeight"
        />
      </div>
    </div>
  </ProofListing>
</template>
<script>
  import InvoiceProofListingEntries from "@/modules/accounts-payable/components/invoice/InvoiceProofListingEntries";
  import ProofListingAccountSummary from "@/modules/common/components/ProofListingAccountSummary";
  import ProofListingCostTypeSummary from "@/modules/common/components/ProofListingCostTypeSummary";
  import PostDialog from "@/components/common/modal/PostDialog";
  import ProofListing from "@/modules/common/components/proof-listing/ProofListing";
  import BillingProofGroupRow from "@/modules/accounts-receivable/components/billings/BillingProofGroupRow.vue";

  export default {
    components: {
      PostDialog,
      ProofListing,
      InvoiceProofListingEntries,
      ProofListingAccountSummary,
      ProofListingCostTypeSummary,
      BillingProofGroupRow,
    },
    data() {
      return {
        loading: false,
        data: {
          customers: [],
          account_summary: [],
        },
      }
    },
    computed: {
      totalsData() {
        const {
          summary,
        } = this.data;
        return [
          {
            id: crypto.randomUUID(),
            name: this.$t('Work Completed This Period'),
            amount: summary?.work_completed_this_period.amount
          },
          {
            id: crypto.randomUUID(),
            name: this.$t('Material Stored Change'),
            amount: summary?.material_stored_change.amount
          },
          {
            id: crypto.randomUUID(),
            name: this.$t('Retention Change'),
            amount: summary?.retention_change.amount
          },
          {
            id: crypto.randomUUID(),
            name: this.$t('Sales Tax'),
            amount: summary?.sales_tax?.amount
          },
          {
            id: crypto.randomUUID(),
            name: this.$t('Net Period Billing'),
            amount: summary?.net_period_billing.amount
          },
        ]
      },
      totalColumns() {
        return [
          {
            headerName: this.$t('Total'),
            field: 'name',
            minWidth: 150,
          },
          {
            headerName: this.$t('Amount'),
            field: 'amount',
            minWidth: 150,
            align: 'right',
            component: 'FormattedPrice',
            cellClass: params => {
              if (params.rowIndex === this.totalsData.length - 1) {
                return 'font-bold flex justify-end'
              }
              return 'flex justify-end'
            }
          },
        ]
      },
      flatData() {
        const allEntries = []
        this.data?.billings?.forEach(billing => {
          const { customer } = billing
          billing.entries.forEach(entry => {
            allEntries.push({
              ...entry,
              billing,
              customer,
              gross_amount: +entry.gross_amount,
              sales_tax_amount: +entry.sales_tax_amount,
              net_amount: +entry.net_amount,
            })
          })
        })
        return allEntries
      },
      groupRowRendererParams() {
        return {
          innerRenderer: 'BillingProofGroupRow',
        }
      },
      columns() {
        return [
          {
            headerName: this.$t('Billing'),
            field: 'billing.number',
            rowGroup: true,
            hide: true,
          },

          {
            headerName: this.$t('Contract'),
            children: [
              {
                headerName: this.$t('Line Item'),
                field: 'line_item',
                component: 'AddlSourceLink',
                cellRendererParams: {
                  showDescription: false,
                },
                minWidth: 80,
                maxWidth: 100,
              },
              {
                headerName: this.$t('Description'),
                field: 'description',
                minWidth: 140,
                maxWidth: 320,
                tooltipField: 'description',
                cellRenderer: params => {
                  if (params.node.footer) {
                    return this.$t('Total')
                  }
                  return params.data.description
                }
              },
              {
                headerName: this.$t('Schedule Value'),
                field: 'jobBudget.amount',
                component: 'FormattedPrice',
                valueGetter: params => {
                  return +params.data.jobBudget.amount
                },
                cellClass: 'bg-gray-50 flex justify-end',
                align: 'right',
                minWidth: 110,
                maxWidth: 140,
                aggFunc: 'sum',
              },
            ]
          },
          {
            headerName: this.$t('Completed'),
            children: [
              {
                headerName: this.$t('Comp $'),
                field: 'jobBudget.amount_to_date',
                component: 'FormattedPrice',
                cellClass: 'bg-gray-50 flex justify-end',
                valueGetter: params => {
                  return +params.data.jobBudget.amount_to_date
                },
                minWidth: 100,
                maxWidth: 140,
                align: 'right',
                aggFunc: 'sum',
              },
              {
                headerName: this.$t('Comp %'),
                field: 'jobBudget.completion',
                component: 'FormattedPercent',
                valueGetter: params => {
                  return +params.data.jobBudget.completion
                },
                minWidth: 55,
                maxWidth: 70,
                align: 'right',
                aggFunc: 'avg',
              },
              {
                headerName: this.$t('This Period'),
                field: 'gross_amount',
                valueGetter: params => {
                  return +params.data.gross_amount
                },
                minWidth: 100,
                maxWidth: 160,
                align: 'right',
                component: 'FormattedPrice',
                cellRendererParams: {
                  hideZero: true,
                },
                aggFunc: 'sum',
              },
            ]
          },
          {
            headerName: this.$t('Material Stored'),
            children: [
              {
                headerName: this.$t('Prior'),
                field: 'summary.material_stored_prior',
                align: 'right',
                component: 'FormattedPrice',
                cellRendererParams: {
                  hideZero: true,
                },
                cellClass: 'bg-gray-50 flex justify-end',
                valueGetter: params => {
                  return +params.data.summary.material_stored_prior
                },
                minWidth: 90,
                maxWidth: 160,
                aggFunc: 'sum',
              },
              {
                headerName: this.$t('Current'),
                field: 'summary.material_stored_current',
                valueGetter: params => {
                  return +params.data.summary.material_stored_current
                },
                minWidth: 90,
                maxWidth: 160,
                component: 'FormattedPrice',
                cellRendererParams: {
                  hideZero: true,
                },
                align: 'right',
                aggFunc: 'sum',
              },
            ]
          },
          {
            headerName: 'Retention',
            children: [
              {
                headerName: this.$t('Prior'),
                field: 'summary.retention_prior',
                valueGetter: params => {
                  return +params.data.summary.retention_prior
                },
                component: 'FormattedPrice',
                cellRendererParams: {
                  hideZero: true,
                },
                minWidth: 90,
                maxWidth: 160,
                aggFunc: 'sum',
                align: 'right',
                cellClass: 'bg-gray-50 flex justify-end',
              },
              {
                headerName: this.$t('Current'),
                field: 'summary.retention_current',
                valueGetter: params => {
                  return +params.data.summary.retention_current
                },
                component: 'FormattedPrice',
                cellRendererParams: {
                  hideZero: true,
                },
                align: 'right',
                minWidth: 90,
                maxWidth: 160,
                aggFunc: 'sum',
              },
            ]
          }
        ]
      }
    },
  }
</script>
