<template>
  <WorkOrdersForm
      :key="renderKey"
      @create-another="renderKey++"
  />
</template>
<script>
  import WorkOrdersForm from '@/modules/service-billing/components/WorkOrdersForm'

  export default {
    components: {
      WorkOrdersForm,
    },
    data() {
      return {
        renderKey: 1,
      }
    },
  }
</script>
