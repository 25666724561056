import { nextTick } from 'vue'

export async function scrollToSelectedValue(value, selectRef, idValue) {
  const optionIndex = selectRef?.options?.findIndex(o => o.value === value || idValue && o.value === idValue)
  if (optionIndex !== -1 && selectRef?.hoverIndex !== optionIndex) {
    const option = selectRef.options[optionIndex]
    await nextTick()
    selectRef.hoverIndex = optionIndex
    selectRef.scrollToOption(option)
  }
}
