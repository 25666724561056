import { formatPrice } from "@/plugins/formatPrice";
import get from "lodash/get";
import { resourceStatuses } from "@/enum/enums";

export const shippingLocations = {
  Warehouse: 'warehouse',
  CostCenter: 'cost-center',
}

export const FormatTypes = {
  Text: 'text',
  Quantity: 'quantity',
  Price: 'price'
}

export function compareValueFormatter(params, prevValueKey, formatType, postValueKey) {
  let prevValue = get(params.data, prevValueKey)
  const status = params.data?.attributes?.status || params.data?.status
  if (status === resourceStatuses.Posted && postValueKey) {
    prevValue = get(params.data, postValueKey)
  }
  const isPrevValueDefined = prevValue !== undefined
  if (params.value === prevValue || !isPrevValueDefined) {
    return formatValue(params.value, formatType)
  }
  return `${formatValue(prevValue, formatType)} -> ${formatValue(params.value, formatType)}`
}

export function comparePriceFormatter(value, newValue)  {
  if (value === newValue) {
    return formatPrice(value)
  }
  return `${formatPrice(value)} -> ${formatPrice(newValue)}`
}

export function formatValue(value, type) {
  if (type === FormatTypes.Text) {
    return value || ''
  } else if (type === FormatTypes.Quantity) {
    return value || 0
  }
  return formatPrice(value)
}
