<template>
  <AgDataTable
      v-bind="tableProps"
      :url="url"
      :columns="columns"
      :url-params="urlParams"
      :transform-data="transformData"
      :get-empty-row="getEmptyEntry"
      :groupIncludeTotalFooter="true"
      :add-text="$t('New line item')"
      :read-only="readOnly"
      :no-borders="readOnly"
      :actions="actions"
      :show-cells-legend="showCellsLegend"
      :get-row-class="getRowClasses"
      suppressColumnReordering
      hide-actions="filters"
      ref="gridTable"
      id="gridTable"
      @grid-ready="onGridReady"
      @cell-focused="onCellFocused"
      @cell-value-changed="onCellValueChanged"
  >
    <template #cells-legend-after>
      <div class="flex space-x-2 items-center">
        <div class="w-4 h-4 rounded bg-blue-200"></div>
        <div>{{ $t('Rows using billing rates') }}</div>
      </div>
    </template>
  </AgDataTable>
</template>
<script>
  import axios from 'axios'
  import pick from 'lodash/pick'
  import omit from 'lodash/omit'
  import { costTypes as costOrIncomeTypes } from '@/enum/enums';
  import {
    cellClasses,
    getCellClasses,
    getResourceLabel,
    requiredValueSetter,
    stopEditingOnTab
  } from '@/components/ag-grid/columnUtils'
  import { editableTableProps, getTableData, getTableNodes } from '@/components/ag-grid/tableUtils'
  import { getDefaultAccountsByPriority } from '@/modules/common/util/costCenterUtils'
  import {
    dateCol,
    descriptionCol,
    quantityCol,
    specialSourceCol, updateCostCenterHeaderNames
  } from '@/components/ag-grid/columns/costCenterColumns'
  import { costCenterJobTypes, costCenterTypes } from '@/components/grid-table/utils/cost-center'
  import { getDeleteColumn } from "@/components/ag-grid/columns/deleteColumns";
  import { computeBillingEntrySalesTax } from "@/modules/accounts-receivable/utils/billingUtils";
  import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";
  import {
    getSpecialSourceRateDetails,
  } from "@/modules/accounts-receivable/components/lump-sum-billings/lumpSumBillingUtils";
  import {
    specialSourceMap,
    specialSourceTypeMap
  } from "@/modules/accounts-receivable/components/cost-plus-billings/costPlusBillingUtils";
  import { JobTypeFor } from "@/modules/job-costing/enum/jobs";

  const metaFields = [
    'extended_amount',
    'date',
    'markup_amount',
    'markup_percent',
    'profit_amount',
    'profit_percent',
    'subject_to_tax',
    'subject_to_retention',
    'subject_to_markup',
    'markup_subject_to_tax',
    'profit_subject_to_tax',
    'exempt_from_sales_tax',
    'uses_billing_rate',
  ]

  const ResourceEndpoint = '/restify/billing-entries'

  export default {
    props: {
      billing: {
        type: Object,
        default: () => ({}),
      },
      readOnly: {
        type: Boolean,
        default: false,
      },
      showCellsLegend: {
        type: Boolean,
        default: true,
      },
      actions: {
        type: String,
        default: 'add',
      },
    },
    data() {
      return {
        editableTableProps,
        grid: null,
      }
    },
    computed: {
      url() {
        return this.billing.id ? ResourceEndpoint : ''
      },
      urlParams() {
        return this.billing.id ? {
          billing_id: this.billing.id,
          sort: 'order',
          related: 'addlSource[id|phase_code|cost_code|change_order|type]',
        } : {}
      },
      tableProps() {
        return {
          ...editableTableProps,
          ...this.$attrs,
        }
      },
      columns() {
        return [
          {
            headerName: this.$t('Line Item'),
            field: 'addl_source_id',
            cellEditor: this.$cellEditors.GlobalResourceSelect,
            component: 'AddlSourceLink',
            cellEditorParams: {
              resourceName: this.$globalResources.IncomeLineItems,
              target: '_blank',
            },
            cellClass: params => {
              if (this.readOnly) return;
              return params.node.footer ? '' : getCellClasses(params, 'addl_source_id')
            },
            editable: true,
            minWidth: 200,
            maxWidth: 320,
          },
          {
            headerName: this.$t('Type'),
            field: 'type_id',
            cellEditor: this.$cellEditors.GlobalResourceSelect,
            component: 'JobTypeLink',
            cellEditorParams: {
              resourceName: this.$globalResources.JobCostTypes,
              target: '_blank',
            },
            cellClass: params => {
              if (this.readOnly) return;
              return params.node.footer ? '' : getCellClasses(params, 'type_id')
            },
            editable: true,
            valueSetter: params => {
              params.data.type_id = params.newValue
              const markup = this.getMarkupByType(params.newValue)
              params.data.subject_to_tax = markup?.subject_to_tax || false
              params.data.subject_to_retention = markup?.subject_to_retention || false
              params.data.markup_subject_to_tax = markup?.markup_subject_to_tax || false
              params.data.profit_subject_to_tax = markup?.profit_subject_to_tax || false
              const usedFor = this.getUsedFor(params)
              params.data.special_source_type = specialSourceTypeMap[usedFor] || null
              params.data.special_source_id = null
              params.data.description = ''
              params.data.extended_amount = 0
              params.data.markup_amount = 0
              params.data.profit_amount = 0
              return true
            },
            minWidth: 80,
            maxWidth: 80,
          },
          {
            ...specialSourceCol,
            minWidth: 150,
            maxWidth: 250,
            cellEditor: cellEditors.GlobalResourceSelect,
            component: null,
            suppressNavigable: () => false,
            editable: params => {
              const usedFor = this.getUsedFor(params)
              return ![JobTypeFor.Cost.Subcontract, JobTypeFor.Cost.Generic].includes(usedFor)
            },
            cellClass: params => {
              if (params.node.footer) {
                return ''
              }
              const usedFor = this.getUsedFor(params)
              const notEditable = [JobTypeFor.Cost.Subcontract, JobTypeFor.Cost.Generic].includes(usedFor)
              if (!params.data?.type_id || notEditable) {
                return cellClasses.ReadOnly
              }
              return ''
            },
            valueFormatter: params => {
              if (!params.data) {
                return ''
              }
              const usedFor = this.getUsedFor(params)
              const resourceName = specialSourceMap[usedFor]
              return getResourceLabel(params?.data?.special_source_id, resourceName)
            },
            cellEditorParams: params => {
              const usedFor = this.getUsedFor(params)
              const resourceName = specialSourceMap[usedFor]

              return {
                resourceName,
              }
            },
            valueSetter: params => {
              params.data.special_source_id = params.newValue
              this.onChangeSpecialCode(params)
              return true
            }
          },
          {
            ...descriptionCol,
          },
          {
            ...dateCol,
          },
          {
            headerName: this.$t('Unit'),
            field: 'um',
            minWidth: 60,
            maxWidth: 120,
            editable: true,
            sortable: false,
          },
          {
            ...quantityCol,
            valueSetter: params => {
              const isValid = requiredValueSetter(params, 0)
              if (!isValid) {
                return false
              }
              const value = +params.newValue
              if (value > 0 && params.data.unit_rate) {
                params.data.uses_billing_rate = false
              }
              this.calculateAmounts(params)
              return true
            },
          },
          {
            headerName: this.$t('Unit Price'),
            field: 'unit_rate',
            minWidth: 90,
            maxWidth: 150,
            component: 'FormattedPrice',
            editable: true,
            cellEditor: cellEditors.Numeric,
            valueSetter: params => {
              const isValid = requiredValueSetter(params, 0)
              if (!isValid) {
                return false
              }
              const value = +params.newValue
              if (value > 0) {
                this.calculateAmounts(params)
              }
              if (value > 0 && params.data.quantity) {
                params.data.uses_billing_rate = false
              }
              return true
            },
          },
          {
            headerName: this.$t('T'),
            headerTooltip: this.$t('Subject to Tax'),
            field: 'subject_to_tax',
            component: 'Status',
            cellEditor: this.$cellEditors.Boolean,
            cellEditorParams: {
              plain: true,
            },
            valueSetter: params => {
              params.data.subject_to_tax = params.newValue
              if (params.newValue) {
                const salesTax = this.getComputedTaxAmount(params.data)
                params.data.sales_tax_amount = salesTax?.amount
                if (params.data.meta) {
                  params.data.meta.exempt_from_sales_tax = salesTax?.exempt_from_sales_tax
                }
              } else {
                params.data.sales_tax_amount = 0
              }
              return true
            },
            editable: true,
            minWidth: 70,
            maxWidth: 90,
          },
          {
            headerName: this.$t('R'),
            headerTooltip: this.$t('Subject to Retention'),
            field: 'subject_to_retention',
            component: 'Status',
            cellEditor: this.$cellEditors.Boolean,
            cellEditorParams: {
              plain: true,
            },
            valueSetter: params => {
              params.data.subject_to_retention = params.newValue
              if (params.newValue) {
                params.data.retention_amount = +this.getComputedRetentionAmount(params.data)
              } else {
                params.data.retention_amount = 0
              }
              return true
            },
            editable: true,
            minWidth: 70,
            maxWidth: 90,
          },
          {
            headerName: this.$t('M'),
            headerTooltip: this.$t('Subject to Markup'),
            field: 'subject_to_markup',
            component: 'Status',
            cellEditor: this.$cellEditors.Boolean,
            cellEditorParams: {
              plain: true,
            },
            valueSetter: params => {
              params.data.markup_amount = 0
              params.data.subject_to_markup = params.newValue
              return true
            },
            editable: true,
            minWidth: 70,
            maxWidth: 90,
          },

          {
            headerName: this.$t('Amount'),
            field: 'extended_amount',
            editable: true,
            cellEditor: this.$cellEditors.Numeric,
            component: 'FormattedPrice',
            minWidth: 100,
            valueSetter: params => {
              const isValid = requiredValueSetter(params, 0)

              if (!isValid) {
                return false
              }

              params.data.extended_amount = params.newValue
              params.data.uses_billing_rate = false
              this.calculateAmounts(params)
              return true
            },
            valueGetter: params => {
              return +params.data?.extended_amount || 0
            },
            aggFunc: 'sum',
          },
          {
            headerName: this.$t('Markup'),
            field: 'markup_amount',
            cellEditor: this.$cellEditors.Numeric,
            component: 'FormattedPrice',
            minWidth: 100,
            valueSetter: params => {
              const isValid = requiredValueSetter(params, 0)

              if (!isValid) {
                return false
              }

              params.data.markup_amount = params.newValue
              this.calculateAmounts(params, { skipMarkup: true })
              return true
            },
            cellClass: params => {
              if (this.readOnly || params.node?.footer) {
                return ''
              }
              const { subject_to_markup, uses_billing_rate } = params?.data || {}
              const isReadOnly = !subject_to_markup || uses_billing_rate
              return isReadOnly ? cellClasses.ReadOnly : ''
            },
            editable: params => {
              const { subject_to_markup, uses_billing_rate } = params?.data || {}
              return subject_to_markup && !uses_billing_rate
            },
            aggFunc: 'sum',
          },
          {
            headerName: this.$t('Profit'),
            field: 'profit_amount',
            cellEditor: this.$cellEditors.Numeric,
            component: 'FormattedPrice',
            minWidth: 100,
            valueSetter: params => {
              const isValid = requiredValueSetter(params, 0)

              if (!isValid) {
                return false
              }

              params.data.profit_amount = params.newValue || 0
              this.calculateAmounts(params, { skipProfit: true })
              return true
            },
            editable: true,
            aggFunc: 'sum',
            suppressKeyboardEvent: params => {
              let isTabKey = params.event.key === 'Tab'
              const { subject_to_retention, subject_to_tax } = params.data
              if (isTabKey && !subject_to_retention && !subject_to_tax) {
                params.api.stopEditing()
              }
            },
          },
          {
            headerName: this.$t('Retention'),
            field: 'retention_amount',
            cellEditor: this.$cellEditors.Numeric,
            component: 'FormattedPrice',
            minWidth: 100,
            valueSetter: params => {
              const isValid = requiredValueSetter(params, 0)

              if (!isValid) {
                return false
              }

              params.data.retention_amount = params.newValue
              return true
            },
            cellClass: params => {
              if (this.readOnly || params.node?.footer) {
                return ''
              }
              return params.data.subject_to_retention ? '' : cellClasses.ReadOnly
            },
            editable: params => params.data?.subject_to_retention,
            suppressKeyboardEvent: params => {
              let isTabKey = params.event.key === 'Tab'
              const { subject_to_tax } = params.data
              if (isTabKey && !subject_to_tax) {
                params.api.stopEditing()
              }
            },
            aggFunc: 'sum',
          },
          {
            headerName: this.$t('Tax'),
            field: 'sales_tax_amount',
            cellEditor: this.$cellEditors.Numeric,
            component: 'FormattedPrice',
            minWidth: 100,
            valueSetter: params => {
              const isValid = requiredValueSetter(params, 0)

              if (!isValid) {
                return false
              }

              params.data.sales_tax_amount = params.newValue
              return true
            },
            cellClass: params => {
              if (this.readOnly || params.node?.footer) {
                return ''
              }
              return params.data.subject_to_tax ? '' : cellClasses.ReadOnly
            },
            editable: params => params.data?.subject_to_tax,
            suppressKeyboardEvent: stopEditingOnTab,
            aggFunc: 'sum',
          },
          {
            headerName: this.$t('Billing'),
            field: 'gross_amount',
            component: 'FormattedPrice',
            minWidth: 100,
            cellClass: (params) => {
	            if (this.readOnly || params.node?.footer) {
		            return ''
	            }
							return cellClasses.ReadOnly
            },
            aggFunc: 'sum',
          },
          {
            field: 'uses_billing_rate',
            hide: true,
          },
          {
            ...getDeleteColumn({
                url: ResourceEndpoint,
                hide: this.readOnly,
                pinned: 'right',
              },
            ),
          },
        ]
      },
      getJobSubcontractTypeId() {
        return this.$store.getters['globalLists/getJobSubcontractTypeId']
      },
    },
    methods: {
      async storeProgress(billing_id) {

        const gridApi = this.grid.api
        let entries = getTableData(gridApi)
        entries = entries.map((entry, index) => {
          entry.order = index + 1
          return entry
        })

        entries = entries.filter(entry => entry.dirty).map(entry => {
          entry.billing_id = billing_id
          entry = this.composeModel(entry)
          return entry
        })

        const promises = []

        const entriesToUpdate = entries.filter(entry => entry.id)
        const entriesToStore = entries.filter(entry => !entry.id)

        if (entriesToStore.length) {
          promises.push(axios.post('/restify/billing-entries/bulk', entriesToStore))
        }

        if (entriesToUpdate.length) {
          promises.push(axios.post('/restify/billing-entries/bulk/update', entriesToUpdate))
        }

        await Promise.all(promises)
        this.refreshEntriesTable()
      },
      composeModel(entry) {
        entry['meta'] = pick(entry, metaFields)
        entry = omit(entry, [...metaFields, 'cost_or_income'])

        return entry
      },
      getJobCostTypeById(id) {
        return this.$store.getters['globalLists/getJobCostTypeById'](id)
      },
      getUsedFor(params) {
        const { type_id } = params.data
        const costType = this.getJobCostTypeById(type_id)
        return costType?.for
      },
      getEntries() {
        return getTableData(this.grid.api)
      },
      recomputeEntryAmounts() {
        const nodes = getTableNodes(this.grid.api)
        nodes.forEach(node => {
          node.data.dirty = true
          this.calculateAmounts({ data: node.data, node })
        })
      },
      onCellFocused(params) {
        updateCostCenterHeaderNames(params)
      },
      async onCellValueChanged(params) {
        const field = params?.colDef?.field

        if (['type_id', 'addl_source_id'].includes(field) && params.data.type_id && params.data.addl_source_id) {
          await this.assignAccountToEntry(params)
        }

        if (['subject_to_tax', 'subject_to_retention', 'subject_to_markup'].includes(field)) {
          let entry = params.data
          entry.gross_amount = this.calculateGrossAmount(entry)
          params.node.setData(entry)
        }
      },
      async assignAccountToEntry(params) {
        params.data.cost_or_income = costOrIncomeTypes.Income

        const { account, subaccount } = await getDefaultAccountsByPriority(params.data)
        params.data.account = account
        params.data.subaccount = subaccount

        params.node.setData(params.data)
      },
      onGridReady(params) {
        this.grid = params
        this.grid.api.sizeColumnsToFit()
      },
      transformData(data) {
        return data.map(entry => {
          const { attributes } = entry
          return {
            ...attributes,
            ...attributes.meta,
            profit_amount: attributes.meta.profit_amount || 0,
            profit_percent: attributes.meta.profit_percent || 0,
            uses_billing_rate: attributes.meta.uses_billing_rate || false,
            cost_center: costCenterTypes.Job,
            relationships: entry.relationships,
          }
        })
      },
      getRowClasses(params) {
        if (params?.data?.uses_billing_rate) {
          return '!bg-blue-50'
        }
        return ''
      },
      onChangeSpecialCode(params) {
        params.data.unit_rate = 0
        params.data.extended_amount = 0
        params.data.markup_amount = 0
        params.data.profit_amount = 0
        params.data.description = ''
        const usedFor = this.getUsedFor(params)
        params.data.special_source_type = specialSourceTypeMap[usedFor] || null
        params.data = getSpecialSourceRateDetails(params, this.data?.customer?.billing_rate_type_id)

        params.node.setData(params.data)
      },
      calculateAmounts(params, options = {}) {
        let entry = params.data

        entry.extended_amount = this.getComputedExtendedAmount(entry)
        if (!options.skipMarkup && !entry.uses_billing_rate) {
          entry.markup_amount = +this.getComputedMarkupAmount(entry)
        }
        if (entry.uses_billing_rate) {
          entry.markup_amount = 0
        }
        if (!options.skipProfit) {
          entry.profit_amount = +this.getComputedProfitAmount(entry)
        }
        entry.retention_amount = +this.getComputedRetentionAmount(entry)
        const salesTax = this.getComputedTaxAmount(entry)
        entry.sales_tax_amount = +salesTax.amount
        entry.exempt_from_sales_tax = salesTax.exempt_from_sales_tax
        entry.gross_amount = this.calculateGrossAmount(entry)

        if (entry.meta) {
          entry.meta.exempt_from_sales_tax = salesTax.exempt_from_sales_tax
        }

        params.node.setData(entry)
      },
      getComputedMarkupAmount(entry) {
        const markupPercent = this.getMarkupPercentageByType(entry.type_id)
        return +this.$getPercentageValue(entry.extended_amount, markupPercent)
      },
      getComputedProfitAmount(entry) {
        const profitPercent = this.getProfitPercentageByType(entry.type_id)
        return +this.$getPercentageValue(entry.extended_amount, profitPercent)
      },
      getComputedExtendedAmount(entry) {
        const { unit_rate, quantity } = entry
        if (!unit_rate || !quantity) {
          return entry.extended_amount
        }
        return +unit_rate * +quantity
      },
      getComputedRetentionAmount(entry) {
        if (!this.billing.retention_percent) {
          return 0
        }

        const profit = +entry.profit_amount || 0
        const amount = (+entry.extended_amount + +entry.markup_amount + profit)
        return +this.$getPercentageValue(amount, this.billing.retention_percent)
      },
      getComputedTaxAmount(entry) {
        let taxableAmount = +entry.extended_amount

        const markup = this.getMarkupByType(entry?.type_id)
        if (markup?.markup_subject_to_tax) {
          taxableAmount += +entry.markup_amount
        }
        if (markup?.profit_subject_to_tax) {
          taxableAmount += +(entry.profit_amount || 0)
        }
        const data = {
          ...entry,
          gross_amount: taxableAmount,
        }
        return computeBillingEntrySalesTax(data, this.billing)
      },
      getMarkupByType(type_id) {
        const markups = this.get(this.billing, 'meta.cost_types', [])
        return markups.find(markup => markup.type_id === type_id)
      },
      getMarkupPercentageByType(type_id) {
        const markup = this.getMarkupByType(type_id)

        return markup?.markup_percent || 0
      },
      getProfitPercentageByType(type_id) {
        const markup = this.getMarkupByType(type_id)

        return markup?.profit_percent || 0
      },
      calculateGrossAmount(entry) {
        const {
          extended_amount: extended_amount = 0,
          markup_amount: markup_amount = 0,
          profit_amount: profit_amount = 0,
        } = entry

        const grossAmount = +extended_amount + +markup_amount + +profit_amount
        return grossAmount
      },
      getEmptyEntry() {
        this.tryCollapseFormHeader()
        const entries = getTableData(this.grid.api) || []
        const order = entries?.length || 1

        const markup = this.getMarkupByType(this.getJobSubcontractTypeId)

        const subject_to_tax = markup?.subject_to_tax || false
        const subject_to_retention = markup?.subject_to_retention || false
        const markup_subject_to_tax = markup?.markup_subject_to_tax
        const profit_subject_to_tax = markup?.profit_subject_to_tax
        const subject_to_markup = !!this.getMarkupPercentageByType(this.getJobSubcontractTypeId)

        return {
          order,
          cost_center: costCenterTypes.Job,
          billing_id: this.billing.id,
          source_id: this.billing.job_id,
          source_type: costCenterJobTypes.Job,
          addl_source_id: '',
          addl_source_type: costCenterJobTypes.LineItem,
          type_id: this.getJobSubcontractTypeId,
          type_type: costCenterJobTypes.Type,
          account: '',
          subaccount: '',
          description: '',
          gross_amount: 0,
          retention_amount: 0,
          sales_tax_amount: 0,
          _localId: crypto.randomUUID(),
          // * Meta fields
          date: this.$now,
          quantity: 0,
          unit_rate: 0,
          um: '',
          extended_amount: 0,
          markup_amount: 0,
          markup_percent: 0,
          profit_amount: 0,
          profit_percent: 0,
          uses_billing_rate: false,
          subject_to_tax,
          subject_to_retention,
          subject_to_markup,
          markup_subject_to_tax,
          profit_subject_to_tax,
        }
      },
      tryCollapseFormHeader() {
        this.$emit('on-collapse-form-header')
      },
      refreshEntriesTable() {
        this.$refs.gridTable.refresh()
      },
    },
  }
</script>
