<template>
  <DepreciationTable
      :key="status"
      :status="status"
      :authorized-to-post="authorizedToPost"
  />
</template>
<script>
  import { resourceStatuses } from '@/enum/enums'
  import DepreciationTable from '@/modules/equipment/components/DepreciationTable'

  export default {
    components: {
      DepreciationTable,
    },
    data() {
      return {
        status: resourceStatuses.Pending,
      }
    },
    computed: {
      authorizedToPost() {
        return this.status === resourceStatuses.Pending
      }
    },
    watch: {
      '$route.meta': {
        immediate: true,
        handler(meta) {
          this.status = meta?.status
        },
      },
    },
  }
</script>
