<template>
  <div>
    <BaseCollapseForm
        :title="$t('Filters')"
        :focus-on-first-input="!filters.customer_id"
        :save-text="$t('Confirm selection')"
        :loading="loading"
        :expanded="!onEditAction"
        layout="vertical"
        submit-button-type="button"
        @cancel="$router.push('/accounts-receivable/settings/billing-rates')"
        @submit="onSubmit"
    >
      <customer-select v-model="model.customer_id"
                       :disabled="onEditAction"
                       rules="required"
                       id="customer"
                       class="col-span-6 md:col-span-2"
      />
      <base-select v-model="model.source_type"
                   :options="billTypeOptions"
                   :name="$t('Bill Type')"
                   :label="$t('Bill Type')"
                   :disabled="onEditAction"
                   rules="required"
                   id="bill_type"
                   class="col-span-6 md:col-span-2"
      />
      <base-select v-model="model.apply_to"
                   :options="applyToOptions"
                   :name="$t('Apply To')"
                   :label="$t('Apply To')"
                   :disabled="onEditAction"
                   :multiple="!onEditAction"
                   rules="required"
                   id="apply_to"
                   class="col-span-6 md:col-span-2"
      />
    </BaseCollapseForm>
    <BaseForm v-if="validForm"
              :loading="loading"
              :save-text="$t('Create billing rate')"
              :update-text="$t('Update billing rate')"
              class="mt-4"
              layout="vertical"
              @submit="saveRates"
    >
      <BillingRatesOverrides
          :data="data"
          :billing-types="billingTypes"
          :source-type="model.source_type"
          ref="entriesTable"
          class="col-span-6"
      />
    </BaseForm>
  </div>
</template>
<script>
  import axios from 'axios'
  import { billingRateSources } from '@/enum/enums'
  import { BILL_TO_STORAGE_KEY } from '@/modules/accounts-receivable/pages/billing-rates/billingRates'
  import BillingRatesOverrides
    from '@/modules/accounts-receivable/components/customer-billing-rates/BillingRatesOverrides'
  import { validateAgDataTable } from '@/components/ag-grid/tableUtils'

  export default {
    components: {
      BillingRatesOverrides,
    },
    props: {
      data: {
        type: Array,
        default: () => [],
      },
      filters: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        loading: false,
        showOverridesTable: false,
        model: {
          customer_id: undefined,
          apply_to: ['jobs'],
          source_type: billingRateSources.Material,
        },
        billTypeOptions: [
          {
            label: this.$t('Material'),
            value: billingRateSources.Material,
          },
          {
            label: this.$t('Equipment'),
            value: 'equipment',
          },
          {
            label: this.$t('Craft/Level'),
            value: 'craft-code',
          },
        ],
        applyToOptions: [
          {
            label: this.$t('Jobs'),
            value: 'jobs',
          },
          {
            label: this.$t('Work Orders'),
            value: 'work-orders',
          },
        ],
      }
    },
    computed: {
      billingTypes() {
        return this.$store.state.globalLists.billingRateTypes
      },
      onEditAction() {
        return !!this.filters.customer_id
      },
      validForm() {
        return this.showOverridesTable || this.onEditAction
      },
    },
    methods: {
      async onSubmit() {
        if (!this.onEditAction) {
          await this.validateSelections()
          return
        }

        await this.saveRates()
      },
      async saveRates() {
        const isInvalidData = await validateAgDataTable()
        if (isInvalidData) {
          return
        }
        await this.storeProgress()
      },
      redirectToEditPage(id) {
        this.$router.push(`/accounts-receivable/settings/billing-rates/${id}/edit`)
      },
      async storeProgress() {
        try {
          this.loading = true
          const { entriesTable } = this.$refs
          if (!entriesTable) {
            return
          }
          const entriesToDelete = entriesTable.entriesToDelete
          const entries = entriesTable.getData()

          if (this.onEditAction) {
            await this.runBillingRateOperations(entries, entriesToDelete)
            this.$success(this.$t('Customer billing rates updated'))
          } else {
            await this.storeBillingRates(entries)
            this.$success(this.$t('Customer billing rates created'))
            this.redirectToEditPage()
          }
          await this.$router.push('/accounts-receivable/settings/billing-rates')
        } catch (err) {
          console.warn(err)
          if (err.handled) {
            return
          }
          this.$error(this.$t('Something went wrong. Please try again.'))
        } finally {
          this.loading = false
          this.$refs?.entriesTable?.refreshData()
        }
      },
      async runBillingRateOperations(entries, entriesToDelete) {
        let promises = []
        let entriesToStore = []
        let entriesToUpdate = []
        entries.filter(entry => entry.dirty).forEach(({ source_id, billing_rates }, entryIndex) => {
          billing_rates.forEach((rate, rateIndex) => {
            rate = this.setRatesValues(rate, rateIndex, entries[entryIndex])
            const rateObj = {
              ...rate,
              ...this.model,
              source_id,
            }
            if (rate.id) {
              entriesToUpdate.push(rateObj)
            } else {
              entriesToStore.push(rateObj)
            }
          })
        })

        const entriesToDeleteIds = entriesToDelete.map(entry => entry.id)

        if (entriesToDeleteIds.length) {
          promises.push(axios.delete('/restify/customer-billing-rates/bulk/delete', {
            params: entriesToDeleteIds
          }))
        }

        if (entriesToStore.length) {
          promises.push(axios.post('/restify/customer-billing-rates/bulk', entriesToStore))
        }
        if (entriesToUpdate.length) {
          promises.push(axios.post('/restify/customer-billing-rates/bulk/update', entriesToUpdate))
        }

        await Promise.all(promises)
      },
      async storeBillingRates(entries) {
        let promises = []
        let entriesToStore = []
        this.model.apply_to.forEach(apply_to => {
          entries.forEach((entry) => {
            entry['billing_rates'].forEach((rate, rateIndex) => {
              rate = this.setRatesValues(rate, rateIndex, entry)
              const billingRate = {
                ...rate,
                apply_to,
                source_type: this.model.source_type,
                customer_id: this.model.customer_id,
                source_id: entry.source_id,
              }
              entriesToStore.push(billingRate)
            })
          })
        })
        promises.push(axios.post('/restify/customer-billing-rates/bulk', entriesToStore))
        await Promise.all(promises)
      },
      setRatesValues(rate, index, entry) {
        return {
          ...rate,
          regular_rate: entry[`billing_rates.${index}.regular_rate`] || rate.regular_rate,
          premium_rate: entry[`billing_rates.${index}.premium_rate`] || rate.premium_rate,
          overtime_rate: entry[`billing_rates.${index}.overtime_rate`] || rate.overtime_rate,
        }
      },
      async validateSelections() {
        try {
          this.loading = true
          const { customer_id, apply_to, source_type } = this.model
          const applyTo = apply_to.join(',')
          const { data } = await axios.get('/restify/customer-billing-rates', {
            params: {
              customer_id,
              source_type,
              apply_to: applyTo,
            },
          })
          if (!data.length) {
            this.showOverridesTable = true
          } else {
            const confirmed = await this.$confirm({
              title: this.$t('Billing rates already exists'),
              description: this.$t('Billing rate overrides already exists, you want to edit it?'),
              buttonText: this.$t('Confirm'),
            })
            if (!confirmed) {
              this.model.customer_id = undefined
              return this.focusOnCustomerSelect()
            }
            this.redirectToEditPage()
          }
        } catch (err) {
          console.warn(err)
        } finally {
          this.loading = false
        }
      },
      focusOnCustomerSelect() {
        const customerSelect = document.querySelector('#customer input')
        if (!customerSelect) {
          return
        }
        customerSelect.focus()
      },
      setSourceType() {
        if (this.filters?.customer_id) {
          return
        }
        this.model.source_type = localStorage.getItem(BILL_TO_STORAGE_KEY) || billingRateSources.Material
      },
    },
    async mounted() {
      this.setSourceType()
    },
    watch: {
      filters(value) {
        this.model = {
          ...this.model,
          ...value,
        }
      },
    },
  }
</script>
