<template>
  <base-input v-bind="$attrs">
    <template v-slot:container="{errors}">
      <div class="date-picker__wrapper"
           :class="{'date-input-error': errors.length}">
        <base-input-error :errors="errors"
                          :show-tooltip="inlineErrors">
          <el-time-picker v-on="$listeners"
                          v-bind="$attrs"
                          :placeholder="$attrs.placeholder || $t('Time')"
                          :clearable="clearable"
                          :picker-options="pickerOptions"
          />
        </base-input-error>
      </div>
    </template>
  </base-input>
</template>
<script>
  import { TimePicker } from 'element-ui'

  export default {
    components: {
      [TimePicker.name]: TimePicker,
    },
    props: {
      inlineErrors: {
        type: Boolean,
        default: false,
      },
      clearable: {
        type: Boolean,
        default: false,
      },
      pickerOptions: {
        type: Object,
        default: () => ({}),
      },
    },
  }
</script>
<style lang="scss">
  .date-picker__wrapper.date-input-error {
    .el-date-editor.el-input {
      .el-input__inner {
        @apply bg-red-50 border-red-300 text-red-900 focus:ring-2 focus:outline-none focus:ring-red-300 focus:ring-opacity-40 focus:border-red-300;
      }
    }
  }

  .date-picker__wrapper {
    .el-date-editor.el-input {
      @apply w-full;
      .el-input__inner {
        @apply shadow-sm focus:ring-primary-200 focus:ring-2 focus:ring-opacity-20 focus:border-primary-300 block w-full sm:text-sm border-gray-300 rounded-md;
      }
    }
  }

  .el-time-panel__btn {
    border: 1px solid #cccccc;
    border-radius: 4px;
  }

  .el-time-panel {
    .el-time-spinner {
      .el-time-spinner__item:hover:not(.disabled):not(.active) {
        @apply bg-primary-50;
      }
    }

    .el-time-panel__btn.confirm {
      font-weight: 500;
      color: white;
      background: var(--primary-600);
    }
  }
</style>
