import { formatDate } from "@/plugins/dateFormatPlugin";
import { Journal } from "@/modules/common/types/models";

export const GeneralLedgerJournalCode = 'GL'

export const journalTypes = {
  [GeneralLedgerJournalCode]: GeneralLedgerJournalCode,
  JobCosting: 'JC',
  AccountsPayable: 'AP',
  CashDisbursement: 'CD',
  Payroll: 'PR',
  AccountsReceivable: 'AR',
  CashReceipts: 'CR',
  Equipment: 'EQ',
  Inventory: 'IV',
  PurchaseOrder: 'PO',
  ServiceBilling: 'SB',
}

export const journalRecurringTypes = {
  SinglePeriod: 'single_period',
  Recurring: 'recurring',
}

export function getJournalTemporaryFields(model: Journal) {
  const referenceDate = new Date(model.reference_date)

  const fiscal_year = referenceDate.getFullYear()
  const period = referenceDate.getUTCMonth()
  const date = formatDate(referenceDate)

  const temporaryFields = {
    name: `${model.code} - ${date}`,
    fiscal_year,
    period,
  }

  return {
    ...model,
    ...temporaryFields,
  }
}
