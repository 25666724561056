<template>
  <base-data-table
    v-bind="defaultAttributes"
    :columns="columns"
    :data="getData"
    :total-rows="rowsLength"
    :has-summary="false"
    :compact="true"
    :filters="filters"
    class="no-borders payroll-history"
    @force-pagination="forcePagination"
  >
    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>
    <template #header="{row}">
      {{ row.header?.title }}
    </template>
    <template #employee="{row}">
      <EmployeeLink :data="row.employee" :show-name="false"/>
    </template>
    <template #payroll.quarter="{row}">
      <div class="flex space-x-2">
        <span>{{ row.payroll.quarter }}</span>
        <span>{{ row.payroll.year }}</span>
      </div>
    </template>
    <template #withholding_state="{row}">
      <StateTaxLink :id="row.withholding_state?.id" :show-description="false"/>
    </template>
    <template #union="{row}">
      <UnionLink :id="row.union?.id" :show-description="false"/>
    </template>
    <template #craft="{row}">
      <CraftCodeLink :id="row.craft?.id" :show-description="false"/>
    </template>
    <template #is_certified_payroll_exempt="{row}">
      <div>
        <span v-if="row.entry?.is_certified_payroll_exempt">
        Y
      </span>
        <span v-else></span>
      </div>
    </template>
    <template #journal="{row}">
      <JournalLink :data="row.journal"/>
    </template>
    <template #journal.fiscal_year="{row}">
      <div class="flex space-x-2">
        <span>{{ row.journal.period }}</span>
        <span>{{ row.journal.fiscal_year }}</span>
      </div>
    </template>
    <template #entry.source="{row}">
      <SourceLink
        :data="getSource(row)"
        :show-description="false"
        :show-preview="false"
      />
    </template>
    <template #subtotal="{subtotal, row}">
      <td colspan="13">
      </td>
      <td colspan="4">
        {{ row.title || $t('Totals:') }}
      </td>
      <td class="text-right !pr-1">
        {{ $formatQuantity(subtotal.quantity) }}
      </td>
      <td class="text-right !pr-3">
        {{ $formatPrice(subtotal.amount) }}
      </td>
    </template>
    <template #footer-row>
      <td colspan="19">
        <SelectedPayrollHistorySummary
          :data="data"
          v-bind="$attrs"
        />
      </td>
    </template>
  </base-data-table>
</template>
<script>
import ReportTableWrapper from "@/modules/common/components/reports/ReportTableWrapper.vue";
import { SelectedHistoryPrintBy, SelectedHistorySortBy } from "@/modules/payroll/components/reports/util";
import StateTaxLink from "@/components/links/StateTaxLink.vue";
import UnionLink from "@/components/links/UnionLink.vue";
import CraftCodeLink from "@/components/links/CraftCodeLink.vue";
import JournalLink from "@/components/links/JournalLink.vue";
import SourceLink from "@/components/links/SourceLink.vue";
import SelectedPayrollHistorySummary
  from "@/modules/payroll/components/reports/selected-payroll-history/SelectedPayrollHistorySummary.vue";

export default {
  components: {
    SelectedPayrollHistorySummary,
    SourceLink,
    JournalLink,
    CraftCodeLink,
    UnionLink,
    StateTaxLink
  },
  extends: ReportTableWrapper,
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    }
  },
  computed: {
    columns() {
      return [
        {
          label: this.$t('Employee <br> Code'),
          prop: 'employee',
          minWidth: 60,
          maxWidth: 100,
        },
        {
          label: this.$t('Period <br> End'),
          prop: 'payroll.period_end_date',
          minWidth: 100,
          maxWidth: 120,
          component: 'FormattedDate'
        },
        {
          label: this.$t('Payment <br> Number'),
          prop: 'payroll.payment_number',
          minWidth: 60,
          maxWidth: 100,
        },
        {
          label: this.$t('Payment <br> Date'),
          prop: 'payroll.payment_date',
          minWidth: 100,
          maxWidth: 120,
          component: 'FormattedDate'
        },
        {
          label: this.$t('Quart'),
          prop: 'payroll.quarter',
          minWidth: 50,
          maxWidth: 70,
        },
        {
          label: this.$t('W/H <br> State'),
          prop: 'withholding_state',
          minWidth: 50,
          maxWidth: 50,
        },
        {
          label: this.$t('Union'),
          prop: 'union',
          minWidth: 50,
          maxWidth: 50,
        },
        {
          label: this.$t('Craft'),
          prop: 'craft',
          minWidth: 50,
          maxWidth: 50,
        },
        {
          label: this.$t('Work <br> Comp'),
          prop: 'workers_comp_rate.code',
          minWidth: 50,
          maxWidth: 50,
        },
        {
          label: this.$t('Gen <br> Liab'),
          prop: 'gen_liability_rate.code',
          minWidth: 50,
          maxWidth: 50,
        },
        {
          label: this.$t('Day'),
          labelTooltip: this.$t('Timecard entry day'),
          prop: 'entry.day',
          minWidth: 40,
          maxWidth: 50,
        },
        {
          label: this.$t('Cert <br> Expt'),
          labelTooltip: this.$t('Is exempt from certified payroll'),
          prop: 'is_certified_payroll_exempt',
          minWidth: 40,
          maxWidth: 50,
        },
        {
          label: this.$t('Journal'),
          prop: 'journal',
          minWidth: 50,
          maxWidth: 80,
        },
        {
          label: this.$t('Fiscal <br> Priod'),
          prop: 'journal.fiscal_year',
          minWidth: 50,
          maxWidth: 80,
        },
        {
          label: this.$t('Job <br> Eqp/Wo'),
          prop: 'entry.source',
          minWidth: 70,
          maxWidth: 100,
        },
        {
          label: this.$t('G/L <br> Debit'),
          prop: 'entry.debit_account',
          minWidth: 50,
          maxWidth: 60,
        },
        {
          label: this.$t('G/L <br> Credit'),
          prop: 'entry.credit_account',
          minWidth: 50,
          maxWidth: 60,
        },
        {
          label: this.$t('Hours <br> Units'),
          prop: 'entry.quantity',
          minWidth: 50,
          maxWidth: 80,
          align: 'right',
          component: 'FormattedQuantity',
        },
        {
          label: this.$t('Amount'),
          prop: 'entry.amount',
          minWidth: 100,
          maxWidth: 120,
          align: 'right',
          component: 'FormattedPrice',
        }
      ]
    },
    subTotalsOnly() {
      return this.filters.print_by === SelectedHistoryPrintBy.SubTotalsOnly
    }
  },
  methods: {
    getSource(row) {
      const source = row?.entry?.source || {}
      return {
        ...source,
        source,
      }
    },
    processEmployee(employee, includeSubtotal = true) {
      if (!employee.payrolls) {
        return
      }
      for (let key in employee.payrolls) {
        if (this.subTotalsOnly) {
          continue
        }
        const payroll = employee.payrolls[key]
        const entries = (payroll.entries || []).map(entry => {
          return {
            ...entry,
            payroll: payroll?.payroll,
            journal: payroll?.journal,
            employee: employee?.employee,
          }
        })
        this.rows.push(...entries)
      }
      if (!includeSubtotal) {
        return
      }
      this.rows.push({
        subtotal: employee.totals,
        title: `Total Employee ${employee.employee.code}`,
      })
    },
    processCode(row, employee) {
      if (!row.payrolls) {
        return
      }
      for (let key in row.payrolls) {
        if (this.subTotalsOnly) {
          continue
        }
        const payroll = row.payrolls[key]
        const entries = (payroll.entries || []).map(entry => {
          return {
            ...entry,
            payroll: payroll?.payroll,
            journal: payroll?.journal,
            employee: employee,
          }
        })
        this.rows.push(...entries)
      }
      this.rows.push({
        subtotal: row.totals,
        title: `Total Code ${row?.code?.code || ''}`,
      })
    },
    processSpecialSources(value) {
      for (let sourceKey in value.special_sources) {
        const row = value.special_sources[sourceKey]
        const displayCode = sourceKey.split('|')?.[0]

        for (let code in row.employees) {
          const employee = row.employees[code]
          this.processEmployee(employee, false)
        }
        this.rows.push({
          subtotal: row.totals,
          title: `Total Code ${displayCode}`,
        })
      }
    },
    byCode(items) {
      for (let key in items) {
        const value = items[key]
        const { code, description, category } = value?.code || {}
        const headerTitle = `Payroll Code ${code} ${description} (${category})`
        this.rows.push({
          header: {
            ...value.code,
            title: headerTitle,
          },
        })
        for (let code in value.employees) {
          const employee = value.employees[code]
          this.processEmployee(employee)
        }
        this.rows.push({
          subtotal: value.totals,
          title: `Total Code`,
        })
      }
    },
    byEmployee(data) {
      for (let code in data) {
        const row = data[code]
        const employeeName = row.employee?.name
        this.rows.push({
          header: {
            ...row.employee,
            title: `Employee ${employeeName}`,
          }
        })
        for (let key in row.codes) {
          const code = row.codes[key]
          this.processCode(code, row.employee)
        }
        this.rows.push({
          subtotal: row.totals,
          title: `Total Employee ${employeeName}`,
        })
      }
    },
    byCodeJob(data) {
      for (let key in data) {
        const value = data[key]
        const { code, description, category } = value?.code || {}
        for (let sourceKey in value.sources) {
          const row = value.sources[sourceKey]
          const sourceNumber = row?.source?.number || ''
          let headerTitle = `Payroll Code ${code} Job ${sourceNumber}   ${description} (${category})`
          this.rows.push({
            header: {
              ...value.code,
              title: headerTitle,
            },
          })

          for (let code in row.employees) {
            const employee = row.employees[code]
            this.processEmployee(employee)
          }

          this.rows.push({
            subtotal: row.totals,
            title: `Total Job ${sourceNumber}`,
          })
        }
      }
    },
    byJobCode(data) {
      for (let key in data) {
        const value = data[key]
        const sourceNumber = value?.source?.number || '     '

        for (let codeKey in value.codes) {
          const row = value.codes[codeKey]
          const { code, description, category } = row?.code || {}
          let headerTitle = `Job ${sourceNumber} Payroll Code ${code}   ${description} (${category})`
          this.rows.push({
            header: {
              ...value.code,
              title: headerTitle,
            },
          })

          for (let code in row.employees) {
            const employee = row.employees[code]
            this.processEmployee(employee)
          }

          this.rows.push({
            subtotal: row.totals,
            title: `Total Job ${sourceNumber}`,
          })
        }
      }
    },
    byAccount(data) {
      for (let key in data) {
        const value = data[key]
        const { number, description } = value?.account || {}
        let headerTitle = `GL Account ${number} ${description}`
        this.rows.push({
          header: {
            ...value.code,
            title: headerTitle,
          },
        })
        this.processSpecialSources(value)
        this.rows.push({
          subtotal: value.totals,
          title: `Total Account ${number}`,
        })
      }
    },
    byDate(data) {
      for (let key in data) {
        const value = data[key]
        let baseTitle = this.filters.sort_by === SelectedHistorySortBy.PeriodEndDate ? 'Period End Date' : 'Payment Date'
        let formattedDate = this.$formatDate(key)
        let headerTitle = `${baseTitle} ${formattedDate}`
        this.rows.push({
          header: {
            ...value.code,
            title: headerTitle,
          },
        })
        this.processSpecialSources(value)
        this.rows.push({
          subtotal: value.totals,
          title: `Total ${baseTitle} ${formattedDate}`,
        })
      }
    },
    byJournal(data) {
      for (let key in data) {
        const value = data[key]
        const { name } = value?.journal || {}
        let headerTitle = `Journal ${name}`
        this.rows.push({
          header: {
            ...value.code,
            title: headerTitle,
          },
        })

        for (let code in value.codes) {
          const row = value.codes[code]
          const displayCode = row.code?.code

          for (let key in row.employees) {
            const employee = row.employees[key]
            employee.payrolls = {
              [employee.payroll.id]: {
                ...employee,
                journal: value.journal,
              },
            }
            this.processEmployee(employee, false)
          }
          this.rows.push({
            subtotal: row.totals,
            title: `Total Code ${displayCode}`,
          })
        }
        this.rows.push({
          subtotal: value.totals,
          title: `Total Journal ${name}`,
        })
      }
    },
    byPaymentNumber(data) {
      for (let key in data) {
        const value = data[key]
        const paymentNumber = value.payroll?.payment_number
        let headerTitle = `Payment Number ${paymentNumber}`
        this.rows.push({
          header: {
            ...value.code,
            title: headerTitle,
          },
        })
        for (let codeKey in value.codes) {
          const row = value.codes[codeKey]
          const payrollData = {
            ...row,
            payroll: value.payroll,
            journal: value.journal,
          }
          const employeeData = {
            employee: value.employee,
            payrolls: {
              [value.payroll.id]: payrollData,
            },
          }
          this.processEmployee(employeeData, false)

          this.rows.push({
            subtotal: row.totals,
            title: `Total Code ${row.code?.code}`,
          })

        }
        this.rows.push({
          subtotal: value.totals,
          title: `Total Payment Number ${paymentNumber}`,
        })
      }

    },
    composeRows(data) {
      const { sort_by } = this.filters || {}
      if (sort_by === SelectedHistorySortBy.Code) {
        this.byCode(data)
      } else if (sort_by === SelectedHistorySortBy.Employee) {
        this.byEmployee(data)
      } else if (sort_by === SelectedHistorySortBy.CodeJob) {
        this.byCodeJob(data)
      } else if ([SelectedHistorySortBy.CreditAccount, SelectedHistorySortBy.DebitAccount].includes(sort_by)) {
        this.byAccount(data)
      } else if ([SelectedHistorySortBy.PeriodEndDate, SelectedHistorySortBy.PaymentDate].includes(sort_by)) {
        this.byDate(data)
      } else if (sort_by === SelectedHistorySortBy.JobCode) {
        this.byJobCode(data)
      } else if (sort_by === SelectedHistorySortBy.Journal) {
        this.byJournal(data)
      } else if (sort_by === SelectedHistorySortBy.PaymentNumber) {
        this.byPaymentNumber(data)
      }
    }
  }
}
</script>
