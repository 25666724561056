import { globalResources } from "@/components/form/util";
import i18n from "@/i18n";
import store from "@/store";

export function getPhaseTitle(phaseCode, jobId) {
  const phaseTitles = store.getters['globalLists/getResourceList'](globalResources.PhaseTitles)
  const phaseDescription = phaseTitles.find((phaseItem) => phaseItem.phase_code?.toString() === phaseCode?.toString() && phaseItem.job_id === jobId)?.description
  if (!phaseDescription) {
    return i18n.t('Phase ') + phaseCode
  }
  return phaseDescription
}
