<template>
  <div v-if="accountRange"
       class="flex flex-wrap space-x-2">
    <div v-for="range in accountRange">
      <base-badge
          v-if="isRange(range)">
        {{ range[0] }} - {{ range[1] }}
      </base-badge>
      <template v-else-if="Array.isArray(range)">
        <base-badge v-for="account in range" :key="account">
          {{ account }}
        </base-badge>
      </template>
      <base-badge
          v-else
      >
        {{ range }}
      </base-badge>
    </div>
  </div>
</template>
<script setup lang="ts">
  const props = defineProps({
    accountRange: {
      type: Array,
      default: () => [],
    },
  })

  function isRange(range: string | string[]) {
    return Array.isArray(range) && range?.length === 2
  }
</script>
