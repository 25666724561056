<template>
  <BaseSelect
      ref="select"
      v-focus
      v-model="selectedValue"
      :options="options"
      :append-to-body="true"
      inline-errors
      class="w-full"
      @change="onChange"
      @keydown.enter.native="goToNextCell"
  />
</template>
<script>
  import { costCenterTypes } from "@/components/grid-table/utils/cost-center";
  import { selectEditorMixin } from "@/components/ag-grid/cellEditors/selectEditorMixin";

  export default {
    name: 'CostCenterSelectCellEditor',
    mixins: [selectEditorMixin],
    computed: {
      options() {
        if (this.params?.options?.length) {
          return this.params.options
        }
        return [
          {
            label: this.$t('G&A'),
            value: costCenterTypes.GeneralAndAdministrative,
          },
          {
            label: this.$t('JOB'),
            value: costCenterTypes.Job,
          },
          {
            label: this.$t('S/B'),
            value: costCenterTypes.WorkOrder,
          },
          {
            label: this.$t('EQP'),
            value: costCenterTypes.Equipment,
          },
        ]

      }
    },
  }
</script>
