<template>
  <router-view/>
</template>
<script>
  import { isProduction } from '@/isProduction'
  import { loadGlobalResourceByModule } from '@/utils/modules'

  export default {
    data() {
      return {
        lastAccessedModule: '',
      }
    },
    methods: {
      async tryLoadModuleGlobalResource() {
        const module = this.$currentModule

        if (this.lastAccessedModule === module) {
          return
        }

        this.lastAccessedModule = module
        await loadGlobalResourceByModule(module)
      },
    },
    watch: {
      '$route.path': {
        immediate: true,
        handler(route) {
          if (isProduction()) {
            return
          }
          this.tryLoadModuleGlobalResource(route)
        },
      }
    },
  }
</script>
