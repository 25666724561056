<template>
  <base-form :loading="loading"
             :show-cancel="showCancel"
             :save-text="$t('Create local tax jurisdiction')"
             :update-text="$t('Update local tax jurisdiction')"
             :can-create-another-entity="!model.id"
             layout="modal"
             grid-classes="grid grid-cols-12 gap-x-4"
             @cancel="$emit('cancel')"
             @submit="onSubmit"
  >
    <div class="col-span-12 md:col-span-2">
      <base-input v-model="model.code"
                  :label="$t('Code')"
                  :name="$t('Code')"
                  :placeholder="$t('Code')"
                  :disabled="!!get(data, 'id', false)"
                  id="code"
                  rules="required|length:2"
      />
    </div>
    <div class="col-span-12 md:col-span-4">
      <base-input v-model="model.description"
                  :label="$t('Description')"
                  :name="$t('Description')"
                  :placeholder="$t('Description')"
                  id="code"
                  rules="max:150"
      />
    </div>
    <div class="col-span-12 md:col-span-2">
      <base-switch v-model="model.is_401k_exempt"
                   :label-info="$t('401k Exempt')"
                   id="is_401k_exempt"
      />
    </div>
    <div class="col-span-12 md:col-span-2">
      <base-switch v-model="model.is_125s_exempt"
                   :label-info="$t('125S  Exempt')"
                   id="is_125s_exempt"
      />
    </div>
    <div class="col-span-12 md:col-span-2">
      <base-input v-model="model.employer_number"
                  :tip="$t('Enter the appropriate employer I.D. code for the current company for this tax jurisdiction and the withholding agency.')"
                  :label="$t('Withholding')"
                  :name="$t('Withholding')"
                  :placeholder="$t('Withholding')"
                  id="employer_number"
                  rules="max:12"
      />
    </div>
    <div class="col-span-12 md:col-span-3">
      <vendor-select v-model="model.withholding_payee_vendor_id"
                     :label="$t('WH Vendor Payee')"
                     :name="$t('WH Vendor Payee')"
                     :placeholder="$t('WH Vendor Payee')"
                     :initial-value="get(data, 'relationships.whVendor')"
                     id="withholding_payee_vendor_id"
      />
    </div>
    <div class="col-span-12 md:col-span-3">
      <base-input v-model="model.w2_description"
                  :label="$t('W-2 Box Description')"
                  :name="$t('W-2 Box Description')"
                  :placeholder="$t('W-2 Box Description')"
                  :tip="$t('Enter a brief local tax jurisdiction code description for use on Box 20 of the W-2 forms if desired.')"
                  id="w2_description"
                  rules="max:50"
      />
    </div>
    <div class="col-span-12 md:col-span-3">
      <base-select v-model="model.type"
                   :options="typeOptions"
                   :label="$t('Type')"
                   :placeholder="$t('Type')"
                   id="type"
      />
    </div>
    <div class="col-span-12 md:col-span-3">
      <w-2-code-select v-model="model.w2_code_id"
                       :tip="$t('Note: Normally blank. Only box 14 codes allowed.')"
                       id="w2_code_id"
      />
    </div>
    <base-detail-card :title="$t('Customer Details')"
                      :can-expand="false"
                      :expanded="true"
                      class="col-span-12"
    >
      <template v-slot:title>
        <p class="text-gray-500 font-semibold text-sm leading-6 tracking-wide">
          <b>{{ $t('Tax Table') }}</b> - {{
            $t('Add / Update local tax table records for married, single, and head of household.')
          }}
        </p>
      </template>
      <div class="grid grid-cols-4 gap-x-6 p-4">
        <base-switch v-model="taxTable.update"
                     label-info="Update tax table"
                     @input="resetTaxTableUpdateProgress"
        />
        <base-select v-model="taxTable.responsibleParty"
                     :options="responsiblePartyOptions"
                     :disabled="!taxTable.update"
                     multiple
                     :label="$t('Responsible Party')"
                     :name="$t('Responsible Party')"
                     @change="resetTaxTableUpdateProgress"
        />
        <base-input v-model="taxTable.employee"
                    :label="$t('Employee Tax')"
                    :name="$t('Employee Tax')"
                    :disabled="cantUpdateTaxTable('employee')"
                    :min="0"
                    :max="100"
                    :step="0.00"
                    class="flex-1"
                    format="percent"
                    id="employee_tax"
                    rules="min_value:0|max_value:100"
        />
        <base-input v-model="taxTable.employer"
                    :label="$t('Employer Tax')"
                    :name="$t('Employer Tax')"
                    :disabled="cantUpdateTaxTable('employer')"
                    :min="0"
                    :max="100"
                    :step="0.00"
                    class="flex-1"
                    format="percent"
                    id="employer_tax"
                    rules="min_value:0|max_value:100"
        />
      </div>
    </base-detail-card>
  </base-form>
</template>
<script>
  import axios from 'axios'

  export default {
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        showCancel: true,
        loading: false,
        typeOptions: [
          {
            label: 'Undefined',
            value: 'undefined',
          },
          {
            label: this.$t('County'),
            value: 'county',
          },
          {
            label: this.$t('City'),
            value: 'city',
          },
          {
            label: this.$t('School'),
            value: 'school',
          },
          {
            label: this.$t('Other'),
            value: 'other',
          },
        ],
        model: {
          code: '',
          description: '',
          is_125s_exempt: false,
          is_401k_exempt: false,
          employer_number: '',
          withholding_payee_vendor_id: undefined,
          w2_description: '',
          type: 'undefined',
          w2_code_id: undefined,
        },
        responsiblePartyOptions: [
          {
            label: this.$t('Employee'),
            value: 'employee',
          },
          {
            label: this.$t('Employer'),
            value: 'employer',
          },
        ],
        taxTable: {
          update: false,
          responsibleParty: ['employee'],
          employee: 0,
          employer: 0,
        },
      }
    },
    methods: {
      async onSubmit() {
        try {
          this.loading = true
          if (this.model.id) {
            await axios.put(`/restify/local-tax-jurisdictions/${this.model.id}`, this.model)
            await this.tryUpdateTaxTable(this.model.id)
            this.$success(this.$t('Local tax jurisdiction updated'))
            this.$emit('save', this.model)
          } else {
            const {data} = await axios.post('/restify/local-tax-jurisdictions', this.model)
            await this.tryUpdateTaxTable(data.id)
            this.$success(this.$t('Local tax jurisdiction created'))
            this.$createAnotherEntity ? this.$emit('create-another') : this.$emit('save', data)
          }
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not update the local tax jurisdiction'))
        } finally {
          this.loading = false
        }
      },
      async tryUpdateTaxTable(id) {
        if (!this.taxTable.update) {
          return
        }
        await axios.post('/restify/local-tax-jurisdictions/actions?action=update-local-tax', {
          jurisdiction_id: id,
          employee: this.taxTable.employee,
          employer: this.taxTable.employer,
        })
      },
      resetTaxTableUpdateProgress() {
        this.taxTable = {
          ...this.taxTable,
          employee: 0,
          employer: 0,
        }
      },
      cantUpdateTaxTable(responsible) {
        return !this.taxTable.update || !this.taxTable.responsibleParty.includes(responsible)
      },
    },
    watch: {
      'data.attributes': {
        immediate: true,
        handler(value) {
          if (!value) {
            return
          }
          this.model = {
            ...this.model,
            ...value,
          }
        },
      },
    },
  }
</script>
