<template>
  <AgDataTable
    :columns="columns"
    :url="url"
    :url-params="urlParams"
    :filter-method="filterMethod"
    :add-text="$t('New material')"
    :add-entity-in-new-tab="true"
    :per-page="$pagination.list"
    :enableFillHandle="true"
    :enableRangeSelection="true"
    :default-match-filters="defaultMatchFilters"
    ref="table"
    default-sort="code"
    permission="materials"
    actions="search,refresh,print,bulk-delete"
  >
    <template v-slot:vendor="{row}">
      <VendorLink
        :data="getVendor(row)"
        :show-description="false"
      />
    </template>
    <template #attributes.description="{row}">
      <span class="truncate" :title="row.attributes.description">{{ row.attributes.description }}</span>
    </template>
  </AgDataTable>
</template>
<script lang="ts" setup>
import i18n from '@/i18n'
import {resourceStatuses} from "@/enum/enums";
import {computed} from "vue";
import {getDefaultMatchFilter} from "@/components/ag-grid/filterUtils";
import {cellEditors} from "@/components/ag-grid/cellEditors/cellEditors";
import {equipmentStatusOptions} from "@/modules/equipment/util/equipmentUtils";
import {cellClasses} from "@/components/ag-grid/columnUtils";
import {ValueSetterParams} from "@ag-grid-community/core";
import {valueSetterWithUpdate} from "@/components/ag-grid/cellEditors/cellEditorUtils";
import {materialStatusOptions} from "@/modules/purchasing-inventory/utils/materialUtils";

const url = '/restify/materials'

const urlParams = {
  related: 'class[id|code],lastInvoicedVendor[id|code|name],primaryVendor[id|code|name],alternateVendor[id|code|name],address[name]',
}

const columns = [
  {
    label: i18n.t('Material'),
    prop: 'attributes.code',
    component: 'EntityLink',
    redirectTo: '/purchasing-inventory/materials/{ID}/view',
    minWidth: 110,
    maxWidth: 120,
  },
  {
    label: i18n.t('Class'),
    prop: 'relationships.class.attributes.code',
    component: 'EntityLink',
    redirectTo: '/purchasing-inventory/settings/material-classes?id={ID}',
    entityKey: 'attributes.class_id',
    minWidth: 100,
    maxWidth: 160,
  },
  {
    label: i18n.t('Address'),
    prop: 'relationships.address.attributes.name',
    minWidth: 100,
    maxWidth: 160,
  },
  {
    label: i18n.t('Vendor'),
    prop: 'vendor',
    minWidth: 80,
    maxWidth: 160,
  },
  {
    label: i18n.t('Description'),
    prop: 'attributes.description',
    minWidth: 150,
    maxWidth: 200,
  },
  {
    label: i18n.t('Status'),
    prop: 'attributes.status',
    component: 'Status',
    align: 'center',
    minWidth: 90,
    editable: true,
    cellEditor: cellEditors.Status,
    cellEditorParams: {
      options: materialStatusOptions,
    },
    headerClass: cellClasses.HeaderEditable,
    valueSetter: (params: ValueSetterParams) => {
      return valueSetterWithUpdate({
        storeAction: 'inventoryManagement/patchMaterial',
      })(params)
    },
  },
  {
    label: i18n.t('Unit Measure'),
    prop: 'attributes.um',
    align: 'center',
    minWidth: 80,
  },
]

const defaultMatchFilters = computed(() => {
  return getDefaultMatchFilter('show_inactive_materials')
})


function getVendor(row: any) {
  const {relationships} = row || {}
  return relationships.primaryVendor?.attributes || relationships.attributes?.alternateVendor || {}
}

function filterMethod(material: any) {
  if (material.status) {
    return material.status === resourceStatuses.Active
  }
  return true
}
</script>
