<template>
  <div>
    <customer-link
      :data="customer"
      :show-name="true"
    />
  </div>
</template>
<script>

export default {
  computed: {
    data() {
      return this.params.node?.allLeafChildren[0]?.data || {}
    },
    rowIndex() {
      return this.params.rowIndex
    },
    customer() {
      return this.get(this.data, 'relationships.customer', {})
    },
  },
}
</script>
