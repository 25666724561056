<template>
  <div class="employee-details mt-2">
    <DetailsPageLayout :loading="loading || currentEmployeeLoading">
      <template #left>
        <EmployeeMainDetailsCard :employee="employee"/>
        <EmployeeSecondaryDetailsCard :employee="employee"/>
        <EmployeeRatesCard :employee="employee"/>
      </template>
      <template #right>
        <EmployeeDetailsCard :employee="employee"/>
        <EmployeePayrollDetailsCard :employee="employee"/>
      </template>
    </DetailsPageLayout>

    <base-back-link to="/payroll/employees"/>
  </div>
</template>
<script>
  import EmployeeMainDetailsCard from "@/modules/payroll/components/employee/EmployeeMainDetailsCard.vue";
  import EmployeeSecondaryDetailsCard from "@/modules/payroll/components/employee/EmployeeSecondaryDetailsCard.vue";
  import EmployeeRatesCard from "@/modules/payroll/components/employee/EmployeeRatesCard.vue";
  import EmployeeDetailsCard from "@/modules/payroll/components/employee/EmployeeDetailsCard.vue";
  import EmployeePayrollDetailsCard from "@/modules/payroll/components/employee/EmployeePayrollDetailsCard.vue";
  import DetailsPageLayout from "@/modules/common/components/DetailsPageLayout.vue";

  export default {
    components: {
      DetailsPageLayout,
      EmployeePayrollDetailsCard,
      EmployeeDetailsCard,
      EmployeeRatesCard,
      EmployeeMainDetailsCard,
      EmployeeSecondaryDetailsCard,
    },
    props: {
      id: [String, Number],
    },
    data() {
      return {
        loading: false,
      }
    },
    computed: {
      employee() {
        return this.$store.state.payroll.currentEmployee
      },
      currentEmployeeLoading() {
        return this.$store.state.payroll.currentEmployeeLoading
      },
    },
  }
</script>
<style lang="scss">
  .employee-details a {
    @apply text-gray-700 hover:underline;
  }
</style>
