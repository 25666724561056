<template>
  <div>
    <AgDataTable
        :url="finalUrl"
        :url-params="finalUrlParams"
        :default-filters="false"
        :columns="columns"
        :compact="true"
        :data="initialEntries"
        :read-only="isReadOnly"
        :add-text="$t('Add Entry')"
        :enableRangeSelection="true"
        :enableFillHandle="true"
        :addRowOnTab="true"
        :transform-data="transformData"
        :pagination="false"
        :show-pagination="showPagination"
        :authorize-to-copy-last-row="!readOnly"
        :no-borders="isReadOnly"
        :actions="tableActions"
        :get-empty-row="getEmptyRow"
        :show-cells-legend="!isReadOnly"
        :groupIncludeTotalFooter="true"
        :perPage="showPagination ? 50: 1000"
        :allowTableBulkDelete="true"
        suppressColumnReordering
        default-sort="updated_at"
        dom-layout="autoHeight"
        id="gridTable"
        ref="table"
        @grid-ready="onGridReady"
        @cell-value-changed="onCellValueChanged"
    >
      <template #additional-actions-before>
        <slot name="actions"></slot>
      </template>
      <template
        v-if="showJournalLink"
        #extra-actions-before="{row}"
      >
        <TableViewButton :link="`/job-costing/settings/job-cost-init/${row?.job_costing_init_id}/proof-listing`"/>
      </template>
    </AgDataTable>
  </div>
</template>
<script>
  import axios from 'axios'
  import { businessType, costTypes } from '@/enum/enums'
  import { getTableData } from '@/components/ag-grid/tableUtils'
  import { cellClasses, getCellClasses, requiredValueSetter } from '@/components/ag-grid/columnUtils'
  import { getDeleteColumn } from "@/components/ag-grid/columns/deleteColumns";

  export default {
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      extraData: {
        type: Object,
        default: () => ({}),
      },
      initialEntries: {
        type: Object,
        default: () => ({})
      },
      type: {
        type: String,
        default: costTypes.Cost,
      },
      readOnly: {
        type: Boolean,
        default: false,
      },
      url: {
        type: String,
      },
      urlParams: {
        type: Object,
        default: () => ({}),
      },
      showPagination: {
        type: Boolean,
        default: false,
      },
      showJournalLink: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        grid: null,
        emptyRow: {
          job_id: '',
          job_type_id: '',
          line_item_id: '',
          quantity: 0,
          amount: 0,
          retention_amount: 0,
          business_id: '',
          business_type: businessType.Vendor,
        },
      }
    },
    computed: {
      jobSubcontractTypeId() {
        return this.$store.getters['globalLists/getJobSubcontractTypeId']
      },
      batchId() {
        return this.data.id || this.params?.data?.id
      },
      finalUrl() {
        if (this.url) {
          return this.url
        }
        return this.batchId ? '/restify/job-costing-init-entries' : ''
      },
      finalUrlParams() {
        let urlParams = this.batchId ? { job_costing_init_id: this.batchId } : {}
        return {
          ...urlParams,
          ...this.urlParams,
        }
      },
      isCostType() {
        const type = this.params?.type|| this.type
        return type === costTypes.Cost
      },
      tableActions() {
        if (this.showPagination) {
          return 'search,refresh'
        }
        return this.isReadOnly ? '' : 'add'
      },
      columns() {
        return [
          {
            headerName: this.$t('Job'),
            field: 'job_id',
            component: 'JobLink',
            cellEditor: this.$cellEditors.GlobalResourceSelect,
            cellEditorParams: {
              resourceName: this.$globalResources.Jobs,
              target: '_blank',
            },
            valueSetter: params => {
              params.data.line_item_id = null
              params.data.job_id = params.newValue
              return true
            },
            cellClass: getCellClasses,
            editable: true,
          },
          {
            headerName: this.$t('Type'),
            field: 'job_type_id',
            cellEditor: this.$cellEditors.GlobalResourceSelect,
            component: 'JobTypeLink',
            cellEditorParams: {
              resourceName: this.isCostType ? this.$globalResources.JobCostTypes : this.$globalResources.JobIncomeTypes,
              target: '_blank',
            },
            valueSetter: params => {
              params.data.job_type_id = params.newValue
              params.data.retention_amount = 0
              return true
            },
            cellClass: getCellClasses,
            editable: true,
          },
          {
            headerName: this.$t('Line Item'),
            field: 'line_item_id',
            component: 'LineItemLink',
            cellEditor: this.$cellEditors.LineItemSelect,
            cellEditorParams: params => {
              return {
                source_id: params.data.job_id,
                type_id: params.data.job_type_id,
                lineItemType: this.isCostType ? this.$globalResources.CostLineItems : this.$globalResources.IncomeLineItems,
                target: '_blank',
              }
            },
            cellClass: getCellClasses,
            editable: true,
          },
          {
            headerName: 'Quantity',
            field: 'quantity',
            minWidth: 50,
            maxWidth: 150,
            editable: true,
            cellEditor: this.$cellEditors.Numeric,
            valueSetter: params => requiredValueSetter(params, 0),
            cellClass: this.getAmountClasses,
          },
          {
            headerName: this.$t('Amount'),
            field: 'amount',
            editable: true,
            cellEditor: this.$cellEditors.Numeric,
            component: 'FormattedPrice',
            valueSetter: params => {
              const valid = requiredValueSetter(params, 0)
              if (!valid) {
                return false
              }
              const amount = +params.newValue
              const retention_percent = (this.extraData?.retention_percent || 0) / 100
              params.data.retention_amount = amount * retention_percent
              return valid
            },
            valueGetter: params => {
              const amount = +params?.data?.amount || 0
              if (isNaN(amount)) {
                return 0
              }
              return amount
            },
            cellClass: this.getAmountClasses,
            aggFunc: 'sum',
          },
          {
            headerName: this.isCostType ? this.$t('Subcontract Retention') : this.$t('Customer Retention'),
            field: 'retention_amount',
            cellEditor: this.$cellEditors.Numeric,
            component: 'FormattedPrice',
            valueSetter: params => requiredValueSetter(params, 0),
            editable: params => {
              return !this.isCostType || params.data?.job_type_id === this.jobSubcontractTypeId
            },
            suppressNavigable: params => {
              return this.isCostType && params.data?.job_type_id !== this.jobSubcontractTypeId
            },
            valueGetter: params => {
              const amount = params?.data?.retention_amount || 0
              return +amount
            },
            cellClass: this.getRetentionCellClasses,
            aggFunc: 'sum',
          },
          {
            headerName: this.isCostType ? this.$t('Vendor') : this.$t('Customer'),
            field: 'business_id',
            cellEditor: this.$cellEditors.GlobalResourceSelect,
            component: this.isCostType ? 'VendorLink' : 'CustomerLink',
            cellEditorParams: {
              resourceName: this.isCostType ? this.$globalResources.Vendors : this.$globalResources.Customers,
              target: '_blank',
              stopNavigationOnChange: true,
            },
            editable: true,
            suppressKeyboardEvent: params => {
              let isTabKey = params.event.key === 'Tab'
              if (isTabKey) {
                params.api.stopEditing()
              }
            },
            cellClass: params => {
              if (params.data?.business_id || params.node.footer) {
                return ''
              }

              if (!this.isCostType) {
                return 'invalid-ag-cell'
              }

              return params?.data?.retention_amount && params?.data?.job_type_id === this.jobSubcontractTypeId ? 'invalid-ag-cell' : ''
            },
          },
          {
            ...getDeleteColumn({
              url: '/restify/job-costing-init-entries',
              hide: this.isReadOnly,
            }),
          },
        ]
      },
      isReadOnly() {
        return this.params ? this.params.readOnly : this.readOnly
      },
    },
    methods: {
      onGridReady(params) {
        this.grid = params
      },
      async onCellValueChanged(params) {
        params.node.setData(params.data)
        return true
      },
      getAmountClasses(params) {
        if (params.node.footer || this.isReadOnly) {
          return ''
        }

        const amount = !!(params.data?.quantity || params.data?.amount || params.data?.retention_amount)
        return amount ? '' : cellClasses.Invalid
      },
      getRetentionCellClasses(params) {
        if (!this.isCostType) {
          return this.getAmountClasses(params)
        }

        if (params.node.footer || this.isReadOnly) {
          return ''
        }

        if (params.data?.job_type_id !== this.jobSubcontractTypeId) {
          return cellClasses.ReadOnly
        }
        return this.getAmountClasses(params)
      },
      getTableData() {
        const gridApi = this.grid?.api
        return getTableData(gridApi)
      },
      async storeProgress(job_costing_init_id) {
        const promises = []
        const entries = this.getTableData()

        const entriesToUpdate = entries.filter(entry => entry.dirty && entry.id)

        const entriesToStore = entries.filter(entry => !entry.id && entry.dirty).map(entry => {
          entry.job_costing_init_id = job_costing_init_id
          return entry
        })

        if (entriesToStore.length) {
          promises.push(axios.post('/restify/job-costing-init-entries/bulk', entriesToStore))
        }

        if (entriesToUpdate.length) {
          promises.push(axios.post('/restify/job-costing-init-entries/bulk/update', entriesToUpdate))
        }

        await Promise.all(promises)
        this.$refs.table.refresh()
      },
      getEmptyRow() {
        return {
          ...this.emptyRow,
          business_type: this.isCostType ? businessType.Vendor : businessType.Customer,
          _localId: crypto.randomUUID(),
          job_costing_init_id: this.batchId,
        }
      },
      transformData(data) {
        return data.map(entry => entry.attributes)
      },
    },
  }
</script>
