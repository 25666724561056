<template>
  <BaseFormDialog :title="$t('Positive Verification')"
                  v-bind="$attrs"
                  v-on="$listeners"
                  size="sm"
  >
    <base-form layout="modal"
               :show-cancel="true"
               :cancel-text="$t('Close')"
               :can-submit-form="false"
               :loading="loading"
               v-on="$listeners"
               @cancel="$emit('update:open', false)"
               grid-classes="grid col-span-1 gap-y-5 mt-6"
    >
      <div v-if="loading" class="col-span-6 flex items-center justify-center">
        <loader-icon class="flex items-center justify-center w-full spin-animation w-8 h-8"/>
      </div>
      <template v-else>
        <div
          v-if="items"
          class="col-span-1"
        >
          <dt class="text-sm font-medium text-gray-500">
            {{ $t('Attachments') }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            <ul class="border border-gray-200 rounded-md divide-y divide-gray-200">
              <li class="px-3 py-4 flex items-center justify-between text-sm"
                  v-for="(item, itemName) of items"
                  :key="`item-${itemName}`">
                <div class="w-0 flex-1 flex items-center">
                  <PaperclipIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true"/>
                  <span class="ml-2 flex-1 w-0 truncate">
                    {{ itemName }}
                  </span>
                </div>
                <div class="ml-4 flex-shrink-0">
                  <a href="javascript:;"
                     role="button"
                     @click="downloadItem(item, itemName)"
                     class="font-medium text-primary-600 hover:text-primary-500">
                    {{ $t('Download') }}
                  </a>
                </div>
              </li>
            </ul>
          </dd>
        </div>
        <div
          v-else
          class="flex justify-center text-primary-500 font-semibold text-lg"
        >
          {{ $t('No items to download.') }}
        </div>
      </template>
    </base-form>
  </BaseFormDialog>
</template>
<script>
  import axios from 'axios'
  import { PaperclipIcon } from 'vue-feather-icons'
  import { downloadFileLocally } from '@/modules/common/util/downloadFileLocally'

  export default {
    components: {
      PaperclipIcon,
    },
    props: {
      action: {
        type: String,
        default: 'restify/invoice-payments/action?action=generate-batch-positive-pay',
      },
      payload: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        loading: true,
        items: null,
      }
    },
    methods: {
      async getItems() {
        try {
          this.loading = true
          const res = await axios.post(this.action, this.payload)

          if (res && res?.data?.items) {
            this.items = res.data?.items
          }
        } catch (err) {
          this.$error('Could not download the checks for the provided payment batches')
        } finally {
          this.loading = false
        }
      },
      downloadItem(item, itemName) {
        downloadFileLocally(item, itemName)
      },
    },
    mounted() {
      this.getItems()
    },
  }
</script>
