interface Row {
  id: string;
  key: string;
  value: string | boolean | number | null;
}

export const SettingsActions = {
  Import: 'import',
  Export: 'export',
} as const;

export type SettingsAction = typeof SettingsActions[keyof typeof SettingsActions]

const fieldsToOmit = ['id', 'module', 'created_at', 'updated_at', 'deleted_at']

export function mapDataToSettingsTable(data: Record<string, any>): Row[] {
  const result = Object.keys(data)
    .filter(key => !fieldsToOmit.includes(key) && !key.includes('_id'))
    .map(key => {
      return {
        key,
        id: crypto.randomUUID(),
        // @ts-ignore
        value: data[key] as Row['value'],
      }
    })

  return result
}

export function mapDataToApiPayload(data: Row[]) {
  let result = {} as Record<string, any>

  data.forEach(({key, value}) => {
    result[key] = value
  })

  return result
}
