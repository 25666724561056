<template>
  <DetailLayout
      :loading="materialLoading"
      :current-resource="currentResource"
      :get-current-resource="getCurrentResource"
      :select-component="MaterialSelect"
      :items="items"
  >
    <template #page-title>
      <span class="truncate">
          {{ currentResource.attributes.code }}
          <template v-if="currentResource.attributes.description">
            ({{ currentResource.attributes.description }})
          </template>
        </span>
    </template>
  </DetailLayout>
</template>
<script>
import layoutPermissions from '@/mixins/layoutPermissions'
import detailScreen from "@/mixins/detailScreen";
import DetailLayout from "@/modules/common/components/DetailLayout.vue";
import MaterialSelect from "@/components/select/entities/MaterialSelect.vue";

export default {
  components: {
    DetailLayout
  },
  data() {
    return {
      MaterialSelect
    }
  },
  computed: {
    items() {
      return [
        {
          name: this.$t('Details'),
          path: `/purchasing-inventory/materials/${ this.$route.params.id }/view`,
          authorizedTo: 'authorizedToShow',
        },
        {
          name: this.$t('Edit'),
          path: `/purchasing-inventory/materials/${ this.$route.params.id }/edit`,
          authorizedTo: 'authorizedToUpdate',
        },
        {
          name: this.$t('Invoices'),
          path: `/purchasing-inventory/materials/${ this.$route.params.id }/invoices`,
          authorizedTo: 'authorizedToUpdate',
          permission: 'invoices_show'
        },
        {
          name: this.$t('Billings'),
          path: `/purchasing-inventory/materials/${ this.$route.params.id }/billings`,
          authorizedTo: 'authorizedToUpdate',
          permission: 'billings_show'
        },
        {
          name: this.$t('Purchase Orders'),
          path: `/purchasing-inventory/materials/${ this.$route.params.id }/purchase-orders`,
          authorizedTo: 'authorizedToUpdate',
        },
        {
          name: this.$t('Documents'),
          path: `/purchasing-inventory/materials/${ this.$route.params.id }/documents`,
          authorizedTo: 'authorizedToUpdate',
          permission: 'materials_update'
        },
        {
          name: this.$t('Timesheets'),
          path: `/purchasing-inventory/materials/${ this.$route.params.id }/timesheets`,
          authorizedTo: 'authorizedToUpdate',
          permission: 'timesheets_approve'
        },
        {
          name: this.$t('Transactions'),
          path: `/purchasing-inventory/materials/${ this.$route.params.id }/transactions`,
          authorizedTo: 'authorizedToUpdate',
          permission: 'transaction_show'
        },
      ]
    },
    materialLoading() {
      return this.$store.state.inventoryManagement.materialLoading
    },
    currentResource() {
      return this.$store.state.inventoryManagement.currentMaterial
    },
  },
  methods: {
    async getCurrentResource(id) {
      await this.$store.dispatch('inventoryManagement/getMaterial', id)
    },
  },
}
</script>
