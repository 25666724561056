<template>
  <div>
    <InvoiceTable
      :key="status"
      :invoice-status="status"
      :hide-actions="'add,edit,delete,view,import,print'"
      :show-select-column="false"
      :show-proof-button="false"
      :url-params="urlParams"
      @data-fetch="onDataFetch"
    >
      <template #extra-actions="{row}">
        <base-button
          variant="gray-light"
          :title="$t('Review')"
          size="icon"
          class="table-action-button"
          @click="setCurrentEntity(row)"
        >
          <CheckCircleIcon class="w-4 h-4"/>
        </base-button>
      </template>
    </InvoiceTable>
    <InvoiceApproveDialog
      v-if="showEntityDetails"
      :open.sync="showEntityDetails"
      :invoice="selectedEntity"
      :show-next-button="showNextButton"
      :show-prev-button="showPrevButton"
      @prev="onPrev"
      @next="onNext"
      @close="showEntityDetails = false"
    />
  </div>
</template>
<script setup>
import { resourceStatuses } from '@/enum/enums'
import InvoiceTable from '@/modules/accounts-payable/pages/invoices/InvoiceTable'
import InvoiceApproveDialog from "@/modules/accounts-payable/components/invoice/InvoiceApproveDialog.vue";
import { CheckCircleIcon } from "vue-feather-icons";
import { useEntityReview } from "@/modules/common/composables/useEntityReview";
import { ref } from "vue";

const props = defineProps({
  urlParams: {
    type: Object,
    default: () => ({}),
  }
})

const {
  onNext,
  onPrev,
  setCurrentEntity,
  onDataFetch,
  showPrevButton,
  showNextButton,
  selectedEntity,
  showEntityDetails
} = useEntityReview()
const status = ref(resourceStatuses.Pending)
</script>
