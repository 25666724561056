import Vue from 'vue'
import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";
import { requiredValueSetter } from "@/components/ag-grid/columnUtils";
import EntryDeleteDialog from "@/components/ag-grid/cellEditors/EntryDeleteDialog.vue";
import i18n from "@/i18n";

Vue.component('EntryDeleteDialog', EntryDeleteDialog)
export function getEditablePriceCol({ field, headerName, onChange, ...otherProperties }) {
  otherProperties = otherProperties || {}
  return {
    headerName,
    field,
    minWidth: 90,
    maxWidth: 120,
    component: 'FormattedPrice',
    editable: true,
    sortable: false,
    cellEditor: cellEditors.Numeric,
    valueGetter: params => {
      return +params.data[field]
    },
    valueSetter: params => {
      const isValid = requiredValueSetter(params, 0)
      if (!isValid) {
        return false
      }
      if (onChange) {
        onChange(params)
      }
      return true
    },
    align: 'right',
    aggFunc: 'sum',
    ...otherProperties
  }
}


export const accountCellProps = {
  component: 'AccountLink',
  cellEditor: cellEditors.AccountSelect,
  editable: true,
  cellEditorParams: {
    valueKey: 'number',
  },
  minWidth: 160,
  maxWidth: 220,
}

export const subAccountCellProps = {
  component: 'SubAccountLink',
  cellEditor: cellEditors.SubAccountSelect,
  editable: true,
  cellEditorParams: {
    valueKey: 'number',
  },
  minWidth: 60,
  maxWidth: 120,
}

export function yesNoFormatter(params) {
  return params.value ? i18n.t('Y') : i18n.t('N')
}

export function stopEditingOnTab(params) {
  let isTabKey = params.event.key === 'Tab'
  if (isTabKey) {
    params.api.stopEditing()
  }
}
