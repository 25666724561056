<template>
  <BaseCard class="border">
    <div class="text-sm flex flex-col space-y-4">
      <div :title="$t('Code')"
           class="detail-row">
        <HashIcon class="detail-icon"/>
        <div>{{ workOrderData.number }}</div>
        <StatusBadge :status="workOrderData.status"/>
      </div>
      <div :title="$t('Customer')"
           class="detail-row">
        <UserPlusIcon class="detail-icon"/>
        <div class="truncate">
          <span class="text-xs text-gray-500">{{$t('Customer')}}</span>
          <CustomerLink :data="workOrder?.relationships?.customer" :show-name="true"/>
        </div>
      </div>
      <div :title="$t('Assigned To')"
           class="detail-row">
        <UserCheckIcon class="detail-icon"/>
        <div>
          <span class="text-xs text-gray-500">{{ $t('Assigned To') }}</span>
          <EmployeeLink :data="workOrder?.relationships?.assignedEmployee"/>
        </div>
      </div>
    </div>
  </BaseCard>
</template>
<script lang="ts" setup>
import {computed, PropType} from 'vue'
import {WorkOrder, WorkSite} from '@/modules/common/types/models'
import {HashIcon, UserCheckIcon, UserPlusIcon} from 'vue-feather-icons'
import Data = API.Data;

const props = defineProps({
  workOrder: {
    type: Object as PropType<Data<WorkOrder>>,
    required: true,
  },
})

const workOrderData = computed<WorkOrder>(() => {
  return props.workOrder?.attributes || {}
})
</script>
