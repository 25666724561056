<template>
  <div
      :title="typeName"
      class="truncate"
  >
    <router-link
        v-if="typeLink"
        :to="typeLink"
        :target="target"
        class="truncate"
    >
      <span class="truncate capitalize">
        {{ typeName }}
      </span>
    </router-link>
    <span v-else
          class="truncate capitalize">
      {{ typeName }}
    </span>
  </div>
</template>
<script>

  import { costCenterTypes, typeTypesMap } from "@/components/grid-table/utils/cost-center";
  import { globalResources } from "@/components/form/util";
  import { getLaborHourAbbr } from "@/modules/service-billing/util/service-billing";

  export default {
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      target: String,
      isLink: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      costCenter() {
        return this.data?.attributes?.cost_center || this.data?.cost_center
      },
      sourceType() {
        return this.data?.attributes?.source_type || this.data?.source_type
      },
      typeLink() {
        if (!this.isLink) {
          return ''
        }

        if (this.costCenter === costCenterTypes.Job) {
          return `/job-costing/settings/job-cost-settings`
        } else if (this.costCenter === costCenterTypes.Equipment) {
          return `/equipment/settings/equipment-management-settings`
        } else if (this.costCenter === costCenterTypes.WorkOrder) {
          return `/service-billing/settings/service-billing-settings`
        }
        return ''
      },
      typeList() {
        const listName = typeTypesMap[this.costCenter]
        const globalLists = this.$store.state.globalLists

        if (this.costCenter === costCenterTypes.Job) {
          const costTypes = globalLists[globalResources.JobCostTypes] || []
          const incomeTypes = globalLists[globalResources.JobIncomeTypes] || []
          return [...costTypes, ...incomeTypes]
        }
        return globalLists[listName] || []
      },
      typeData() {
        const id = this.data?.attributes?.type_id || this.data?.type_id
        return this.typeList.find(t => t.id === id)
      },
      typeName() {
        if (this.costCenter === undefined && this.sourceType) {
          return this.sourceType
        }
        if (this.costCenter === costCenterTypes.Labor) {
          return getLaborHourAbbr(this.data?.labor_type)
        }
        return this.typeData?.abbr
      }
    },
  }
</script>
<style>
</style>
