export function getTotalDue(row) {
  const keys = ['31-60', '61-90', 'Current', 'Over90']
  let sum = 0
  keys.forEach(key => {
    let value = row[key]
    if (value !== undefined) {
      sum += +value
    }
  })
  return sum
}
