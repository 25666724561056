<template>
  <div class="flex flex-col">
    <vendor-history-table :url-params="urlParams"/>
  </div>
</template>
<script>
  import VendorHistoryTable from '@/modules/accounts-payable/components/vendor-history/VendorHistoryTable'

  export default {
    components: {
      VendorHistoryTable,
    },
    computed: {
      vendorId() {
        return this.$route.params.id || this.currentVendor.id
      },
      urlParams() {
        return {
          vendor_id: this.vendorId,
        }
      },
      currentVendor() {
        return this.$store.state.accountsPayable.currentVendor
      },
    }
  }
</script>
