<template>
  <BaseFormDialog
    v-bind="$attrs"
    v-on="$listeners"
    size="lg"
    :title="$t('Generate Invoices')"
    :appendToBody="true"
  >
    <BaseForm
      layout="modal"
      :show-cancel="true"
      :save-text="$t('Generate Invoices')"
      :loading="loading"
      @cancel="$emit('close')"
      @submit="onSubmit"
    >
      <BaseAlert class="col-span-6 mb-4">
        {{ $t('This action will generate invoices for the selected recurring payables until ') }}
        {{ $formatDate(model.date) }}
      </BaseAlert>

      <BaseDatePicker
        v-model="model.date"
        :label="$t('Date Until')"
        :placeholder="$t('Date until')"
        :value-format="null"
        class="col-span-6 md:col-span-3"
      />
      <BaseSwitch
        v-model="model.force"
        :label-info="$t('Force Initial Start Date')"
        :placeholder="$t('Force Initial Start Date')"
        class="col-span-6 md:col-span-3"
      />
      <h5 class="form-section-title">
        {{ $t('Invoices to generate') }}
      </h5>
      <div
        v-for="invoice in sortedRecurringInvoices"
        :key="invoice.id"
        class="col-span-6"
      >
        <h5 class="form-section-title mb-1">
          <span class="font-medium">{{ $t('For Recurring Payable ') }} {{ invoice.attributes.description }} {{ $t('for ') }}</span>
          <VendorLink class="ml-1" :id="invoice.attributes.vendor_id"/>
          <span v-if="getStartDate(invoice)" class="ml-1 font-medium flex items-center space-x-1">
            {{ $t('starting from ') }} {{ $formatDate(getStartDate(invoice)) }}
            <BaseTip
              v-if="hasIssuedInvoices(invoice) && !model.force"
            >
              <template #content>
                <div class="max-w-[300px]">
                  {{$t('This recurring payable already has issued invoices. The start date considered is the last issued invoice based on the recurring payable. If you want to generate invoices with the initial start date, use the Force Initial Start Date option.')}}
                </div>
              </template>
            </BaseTip>
          </span>
        </h5>
        <AgDataTable
          :data="getRecurrenceList(invoice)"
          :columns="columns"
          :pagination="false"
          :no-data-text="$t('No invoices to generate based on the selected dates.')"
          domLayout="autoHeight"
          class="mb-4"
        />
      </div>
    </BaseForm>
  </BaseFormDialog>
</template>
<script>
import { rrulestr } from "rrule";
import i18n from "@/i18n";
import axios from "axios";
import { ApiDateFormat } from "@/plugins/dateFormatPlugin";
import { addMinutes } from "date-fns";
import orderBy from "lodash/orderBy";

export default {
  props: {
    recurringInvoices: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      loading: false,
      model: {
        date: new Date(),
        repositories: this.recurringInvoices.map(r => r.id),
        force: false,
      }
    }
  },
  computed: {
    sortedRecurringInvoices() {
      const invoiceConfigs = this.recurringInvoices.map(invoice => {
        return {
          ...invoice,
          count: this.getRecurrenceList(invoice)?.length
        }
      })
      return orderBy(invoiceConfigs, 'count', 'desc')
    },
    columns() {
      return [
        {
          headerName: this.$t('Date'),
          field: 'date',
          component: 'FormattedDate',
          minWidth: 150,
        },
        {
          label: this.$t('Amount'),
          prop: 'gross_amount',
          component: 'FormattedPrice',
          minWidth: 140,
          maxWidth: 180,
          align: 'right',
        },
        {
          label: this.$t('Type'),
          align: 'center',
          prop: 'type',
          minWidth: 100,
          maxWidth: 120,
          component: 'Status',
        },
        {
          label: i18n.t('Post Automatically'),
          align: 'center',
          prop: 'should_post',
          minWidth: 100,
          maxWidth: 120,
          component: 'Status',
        },
      ]
    }
  },
  methods: {
    getRecurrenceList(invoice) {
      const rule = rrulestr(invoice.attributes.recurring_rule)
      rule.options.until = this.model.date
      if (invoice.attributes.last_issue_at && !this.model.force) {
        rule.options.dtstart = new Date(invoice.attributes.last_issue_at)
      }
      if (rule.options.dtstart && invoice.attributes.last_issue_at) {
        // This is done to skip displaying again the last issue at
        rule.options.dtstart = addMinutes(rule.options.dtstart, 1)
      }
      const recurrenceList = rule.all()
      return recurrenceList.map(date => ({
        ...invoice.attributes,
        id: crypto.randomUUID(),
        date,
      }))
    },
    getStartDate(invoice) {
      if (this.model.force) {
        return invoice.attributes.start_date
      }
      return invoice.attributes.last_issue_at || invoice.attributes.start_date
    },
    hasIssuedInvoices(invoice) {
      return invoice.attributes.last_issue_at
    },
    async onSubmit() {
      try {
        this.loading = true
        await axios.post(`/restify/recurring-invoices/actions?action=advance-recurrence`, {
          repositories: this.model.repositories,
          date: this.$formatDate(this.model.date, ApiDateFormat),
          force: this.model.force,
        })
        this.$emit('save')
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(this.$t('Could not generate the invoices'))
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
