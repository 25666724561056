<template>
  <div>
    <div v-if="showDateSelections && availableDates.length"
         class="flex flex-col w-full rounded-lg border bg-white overflow-auto p-2 mb-2">
      <h5 class="form-section-title px-2">{{ $t('Intervals') }}</h5>
      <div class="flex items-center justify-between">
        <base-tabs
            :value="currentDate"
            :tabs="availableDates"
            :label-format="dateFormatting"
            label-key="start_date"
            value-key="start_date"
            @input="onChangeDate"
        />
        <AddStartDateDialog
            v-if="showAddButton"
            :entity-repo="entityRepo"
            :last-start-date="lastStartDate"
            @refresh="refresh"
        />
      </div>
    </div>
    <slot/>
  </div>
</template>
<script>
import axios from 'axios'
import sortBy from 'lodash/sortBy'
import AddStartDateDialog from '@/modules/payroll/components/rates/AddStartDateDialog'

export const ActiveStateKey = 'active'

export default {
  components: {
    AddStartDateDialog,
  },
  props: {
    entityRepo: {
      type: String,
      default: 'gen-liability-rates',
    },
    showDateSelections: {
      type: Boolean,
      default: true,
    },
    showAddButton: {
      type: Boolean,
      default: true,
    },
    urlParams: {
      type: Object,
      default: () => ({}),
    },
    unionId: {
      type: String,
    }
  },
  data() {
    return {
      availableDates: [],
      currentDate: null,
    }
  },
  computed: {
    params() {
      if (!this.currentDate) {
        return {}
      }
      if (this.currentDate === ActiveStateKey) {
        return {
          active: true,
        }
      }
      return {
        start_date: this.currentDate,
      }
    },
    lastStartDate() {
      if (this.availableDates.length < 2) {
        return ''
      }
      const {start_date} = this.availableDates.at(-2)
      return start_date
    },
  },
  methods: {
    addActiveTab() {
      this.availableDates.push({
        start_date: ActiveStateKey,
        is_active: true,
      })
    },
    sortDates() {
      this.availableDates = sortBy(this.availableDates, 'start_date')
    },
    async getAvailableDates(setDefaultDate = true) {
      try {
        this.availableDates = await axios.get(`/restify/${this.entityRepo}/dates`, {
          params: {
            union_id: this.unionId,
          },
        })
        this.sortDates()
        this.addActiveTab()

        if (setDefaultDate) {
          this.setDefaultDate()
        } else {
          this.setCurrentDate()
        }
      } catch (err) {
        console.warn(err)
        if (err.handled) {
          return
        }
        this.$error(this.$t('Something went wrong. Please refresh the page and try again.'))
      }
    },
    setDefaultDate() {
      this.currentDate = ActiveStateKey
      this.emitOnChangeDate()
    },
    setCurrentDate() {
      const routeStartDate = this.$route.query.start_date
      if (routeStartDate) {
        this.currentDate = routeStartDate
      }
      this.emitOnChangeDate()
    },
    dateFormatting(_, dateObject) {
      let {start_date, end_date} = dateObject
      if (start_date === ActiveStateKey) {
        return this.$t('Active')
      }

      return end_date ?
          `${this.$formatDate(start_date, false, true)} -> ${this.$formatDate(end_date, false, true)}`
          : this.$formatDate(start_date, false, true)
    },
    onChangeDate(date) {
      this.currentDate = date
      this.emitOnChangeDate()
    },
    async emitOnChangeDate() {
      const newQuery = {
        ...this.$route.query,
        start_date: this.currentDate === ActiveStateKey ? this.currentDate : this.$formatDate(this.currentDate, this.$dateTypes.IsoDate, true),
      }
      this.$emit('on-change-date', this.currentDate)
      if (JSON.stringify(newQuery) === JSON.stringify(this.$route.query)) {
        return
      }
      await this.$router.replace({
        ...this.$route,
        query: newQuery,
      })
    },
    refresh(date) {
      this.getAvailableDates()
      this.currentDate =date
    },
    getLastActiveStartDate() {
      const lastDate = this.availableDates.findLast(({
             is_active,
             start_date,
           }) => is_active && start_date !== ActiveStateKey) || {}
      return lastDate.start_date
    },
    syncStartDate(data) {
      if (data.active) {
        data.start_date = this.getLastActiveStartDate()
      }

      this.$emit('update:url-params', data)
    },
  },
  async mounted() {
    await this.$nextTick()
    if (!this.showDateSelections) {
      return
    }
    await this.getAvailableDates()
  },
  watch: {
    params: {
      deep: true,
      handler(value) {
        this.syncStartDate(value)
      },
    },
  },
}
</script>
