<template>
    <div>
        <div>
            <img class="h-12 mx-auto w-auto" src="/img/cp.png" alt="Workflow"/>
            <h1 class="page-heading">
                {{$t('Forgot Password')}}
            </h1>
            <p class="mt-2 text-sm leading-5 text-gray-600 max-w text-center">
                {{$t('Enter your email to reset it')}}
            </p>
        </div>
        <div class="mt-8">
            <div class="mt-6 relative">
                <div class="w-full border-t border-gray-300"/>
                <div class="relative flex justify-center text-sm leading-5"/>
            </div>
            <div class="mt-6">
                <ValidationObserver v-slot="{ handleSubmit, valid }">
                    <form @submit.prevent="handleSubmit(onSubmit)">
                        <BaseInput v-model="model.email"
                                   :label="$t('Email address')"
                                   :placeholder="$t('Email address')"
                                   rules="required"
                                   id="email"
                                   type="email"
                        />
                        <div class="flex justify-end text-sm leading-5">
                            <router-link to="/login">
                                {{$t('Back to login')}}
                            </router-link>
                        </div>
                        <div class="mt-6">
                            <BaseButton block
                                        type="submit"
                                        :disabled="!valid"
                                        :loading="loading">
                                {{$t('Send reset password link')}}
                            </BaseButton>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>
<script>
  import AuthService from '@/modules/auth/services/AuthService';

  export default {
    data() {
      return {
        loading: false,
        model: {
          email: '',
        },
      };
    },
    methods: {
      async onSubmit() {
        try {
          this.loading = true
          await AuthService.forgotPassword(this.model)
          this.$success(this.$t(' Please check your email!'))
        } catch (e) {
          if (e.handled) {
            return
          }
          this.$error(this.$t('Something went wrong please try again.'))
        } finally {
          this.loading = false
        }
      },
    },
  };
</script>
