<template>
  <BaseTooltip :content="tooltip">
    <PenToolIcon v-if="type === checkTypes.COMPUTE_PAYOFF_CHECK" v-bind="$attrs"/>
    <FileTextIcon v-if="type === checkTypes.NORMAL" v-bind="$attrs"/>
    <svg
      v-if="type === checkTypes.MANUAL_CHECK_ADJUST"
      v-bind="$attrs"
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
      stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide">
      <path d="M4 22h14a2 2 0 0 0 2-2V7l-5-5H6a2 2 0 0 0-2 2v4"/>
      <path d="M14 2v4a2 2 0 0 0 2 2h4"/>
      <path d="m3 15 2 2 4-4"/>
    </svg>
    <svg
      v-if="type === checkTypes.NORMAL_CALC_TAX_ONLY"
      v-bind="$attrs"
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
      stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide">
      <path
        d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z"/>
      <path d="m9.5 14.5 5-5"/>
    </svg>
    <svg
      v-if="type === checkTypes.NORMAL_TAX_ONLY_NO_DD"
      v-bind="$attrs"
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
      stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      <path d="M4 2v20l2-1 2 1 2-1 2 1 2-1 2 1 2-1 2 1V2l-2 1-2-1-2 1-2-1-2 1-2-1-2 1Z"/>
      <path d="M14 8H8"/>
      <path d="M16 12H8"/>
      <path d="M13 16H8"/>
    </svg>
    <svg
      v-if="type === checkTypes.NORMAL_NO_DD"
      v-bind="$attrs"
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
      stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      <rect width="20" height="12" x="2" y="6" rx="2"/>
      <circle cx="12" cy="12" r="2"/>
      <path d="M6 12h.01M18 12h.01"/>
    </svg>
  </BaseTooltip>
</template>

<script setup lang="ts">
import {computed} from "vue";
import i18n from "@/i18n";
import {checkTypes} from "@/modules/payroll/components/rates/util";
import { ArrowRightCircleIcon, FileTextIcon, PenToolIcon } from 'vue-feather-icons'

const props = defineProps({
  type: String,
})

const tooltip = computed(() => {
  const map = {
    [checkTypes.NORMAL]: i18n.t('Normal'),
    [checkTypes.NORMAL_CALC_TAX_ONLY]: i18n.t('Normal Calc Tax Only'),
    [checkTypes.NORMAL_TAX_ONLY_NO_DD]: i18n.t('Normal Tax Only No DD'),
    [checkTypes.NORMAL_NO_DD]: i18n.t('Normal No DD'),
    [checkTypes.MANUAL_CHECK_ADJUST]: i18n.t('Manual Check Adjust'),
    [checkTypes.COMPUTE_PAYOFF_CHECK]: i18n.t('Compute Payoff Check'),
  }
  return map[props.type as string] || props.type
})
</script>
