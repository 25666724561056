<template>
  <div>
    <div>
      <img class="h-12 mx-auto w-auto" src="/img/cp.png" alt="Construction Partner Logo"/>
      <h1 class="page-heading">
        {{ $t('Confirm invitation') }}
      </h1>
      <p class="max-w-sm mt-2 text-gray-700 text-center">
        {{ $t('Setup a password for your account which you will later on use to login into your account.') }}
      </p>
    </div>
    <div class="mt-8">
      <div class="mt-6 relative">
        <div class="w-full border-t border-gray-300"/>
        <div class="relative flex justify-center text-sm leading-5"/>
      </div>
      <div class="mt-6">
        <ValidationObserver v-slot="{ handleSubmit, valid }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <BaseInput v-if="$route.query.email"
                       :value="$route.query.email"
                       :label="$t('Email')"
                       :placeholder="$t('Email')"
                       readonly
            />
            <BaseInput v-model="model.password"
                       :label="$t('Password')"
                       :placeholder="$t('Password')"
                       rules="required"
                       id="password"
                       type="password"
            />
            <div class="flex justify-end text-sm leading-5">
              <router-link to="/login">
                {{ $t('Login into your account') }}
              </router-link>
            </div>
            <div class="mt-6">
              <BaseButton type="submit"
                          block
                          :disabled="!valid"
                          :loading="loading">
                {{ $t('Confirm invitation') }}
              </BaseButton>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        loading: false,
        model: {
          name: '',
          password: '',
        },
      }
    },
    methods: {
      async onSubmit() {
        try {
          this.loading = true
          this.$store.commit('company/SET_ADMIN_VIEWING_COMPANY', false)
          await this.$store.dispatch('auth/registerConfirm', { data: this.model, params: this.$route.query })
          this.$router.push({ name: 'Dashboard' })
        } finally {
          this.loading = false
        }
      },
    },
  }
</script>
