export const stateList = [
  {
    value: 'AL',
    label: 'AL',
    description: 'Alabama',
  },
  {
    value: 'AK',
    label: 'AK',
    description: 'Alaska',
  },
  {
    value: 'AZ',
    label: 'AZ',
    description: 'Arizona',
  },
  {
    value: 'AR',
    label: 'AR',
    description: 'Arkansas',
  },
  {
    value: 'CA',
    label: 'CA',
    description: 'California',
  },
  {
    value: 'CO',
    label: 'CO',
    description: 'Colorado',
  },
  {
    value: 'CT',
    label: 'CT',
    description: 'Connecticut',
  },
  {
    value: 'DE',
    label: 'DE',
    description: 'Delaware',
  },
  {
    value: 'DC',
    label: 'DC',
    description: 'District Of Columbia',
  },
  {
    value: 'FL',
    label: 'FL',
    description: 'Florida',
  },
  {
    value: 'GA',
    label: 'GA',
    description: 'Georgia',
  },
  {
    value: 'HI',
    label: 'HI',
    description: 'Hawaii',
  },
  {
    value: 'ID',
    label: 'ID',
    description: 'Idaho',
  },
  {
    value: 'IL',
    label: 'IL',
    description: 'Illinois',
  },
  {
    value: 'IN',
    label: 'IN',
    description: 'Indiana',
  },
  {
    value: 'IA',
    label: 'IA',
    description: 'Iowa',
  },
  {
    value: 'KS',
    label: 'KS',
    description: 'Kansas',
  },
  {
    value: 'KY',
    label: 'KY',
    description: 'Kentucky',
  },
  {
    value: 'LA',
    label: 'LA',
    description: 'Louisiana',
  },
  {
    value: 'ME',
    label: 'ME',
    description: 'Maine',
  },
  {
    value: 'MD',
    label: 'MD',
    description: 'Maryland',
  },
  {
    value: 'MA',
    label: 'MA',
    description: 'Massachusetts',
  },
  {
    value: 'MI',
    label: 'MI',
    description: 'Michigan',
  },
  {
    value: 'MN',
    label: 'MN',
    description: 'Minnesota',
  },
  {
    value: 'MS',
    label: 'MS',
    description: 'Mississippi',
  },
  {
    value: 'MO',
    label: 'MO',
    description: 'Missouri',
  },
  {
    value: 'MT',
    label: 'MT',
    description: 'Montana',
  },
  {
    value: 'NE',
    label: 'NE',
    description: 'Nebraska',
  },
  {
    value: 'NV',
    label: 'NV',
    description: 'Nevada',
  },
  {
    value: 'NH',
    label: 'NH',
    description: 'New Hampshire',
  },
  {
    value: 'NJ',
    label: 'NJ',
    description: 'New Jersey',
  },
  {
    value: 'NM',
    label: 'NM',
    description: 'New Mexico',
  },
  {
    value: 'NY',
    label: 'NY',
    description: 'New York',
  },
  {
    value: 'NC',
    label: 'NC',
    description: 'North Carolina',
  },
  {
    value: 'ND',
    label: 'ND',
    description: 'North Dakota',
  },
  {
    value: 'OH',
    label: 'OH',
    description: 'Ohio',
  },
  {
    value: 'OK',
    label: 'OK',
    description: 'Oklahoma',
  },
  {
    value: 'OR',
    label: 'OR',
    description: 'Oregon',
  },
  {
    value: 'PA',
    label: 'PA',
    description: 'Pennsylvania',
  },
  {
    value: 'RI',
    label: 'RI',
    description: 'Rhode Island',
  },
  {
    value: 'SC',
    label: 'SC',
    description: 'South Carolina',
  },
  {
    value: 'SD',
    label: 'SD',
    description: 'South Dakota',
  },
  {
    value: 'TN',
    label: 'TN',
    description: 'Tennessee',
  },
  {
    value: 'TX',
    label: 'TX',
    description: 'Texas',
  },
  {
    value: 'UT',
    label: 'UT',
    description: 'Utah',
  },
  {
    value: 'VT',
    label: 'VT',
    description: 'Vermont',
  },
  {
    value: 'VA',
    label: 'VA',
    description: 'Virginia',
  },
  {
    value: 'WA',
    label: 'WA',
    description: 'Washington',
  },
  {
    value: 'WV',
    label: 'WV',
    description: 'West Virginia',
  },
  {
    value: 'WI',
    label: 'WI',
    description: 'Wisconsin',
  },
  {
    value: 'WY',
    label: 'WY',
    description: 'Wyomin',
  },
]
