<template>
  <div>
    <base-alert v-if="!employeeId"
                :type="$promptType.Danger"
                class="col-span-6 mb-3">
      {{ $t(`We couldn't find employee information associated to your account. Please contact your company admin for more information.`) }}
    </base-alert>
    <BaseDataTable :columns="columns"
                   :url-params="urlParams"
                   :default-filters="false"
                   :can-manage-columns="false"
                   :per-page="$pagination.list"
                   :url="url"
                   actions="search,refresh"
                   ref="table"
                   @row-click="previewCheck"
    >
      <template v-slot:extra-actions-before="{row}">
        <table-view-button v-if="$isAuthorized('authorizedToPreview', row)"
                           @click="previewCheck(row)"
        >
          {{ $t('Preview') }}
        </table-view-button>
        <table-download-button v-if="$isAuthorized('authorizedToDownload', row)"
                               :loading="row.downloading"
                               @click="downloadCheck(row)"
        />
      </template>
    </BaseDataTable>
    <check-preview-dialog v-if="showCheckPreviewDialog"
                          :open.sync="showCheckPreviewDialog"
                          :data="get(rowToEdit, 'attributes', {})"
                          :employee="get(rowToEdit, 'relationships.employee.attributes', {})"
                          :company="get(rowToEdit, 'relationships.company.attributes', {})"
                          :own-check="true"
    />

  </div>
</template>
<script>
  import { downloadFileForEntity } from "@/modules/common/util/downloadFileLocally";
  import CheckPreviewDialog from "@/modules/payroll/components/CheckPreviewDialog";

  export default {
    components: {
      CheckPreviewDialog,
    },
    data() {
      return {
        showCheckPreviewDialog: false,
        selectedIndex: -1,
        rowToEdit: null,
        downloading: false,
      }
    },
    computed: {
      employeeId() {
        return this.$store.state.auth.user?.employee?.id
      },
      url() {
        if (!this.employeeId) {
          return undefined
        }
        return `/restify/employees/${this.employeeId}/employee-checks`
      },
      columns() {
        return [
          {
            label: this.$t('Check Date'),
            prop: 'attributes.date',
            maxWidth: 130,

            component: 'FormattedDate',
            padding: '10px 12px'
          },
          {
            label: this.$t('Period Ending'),
            prop: 'attributes.end_date',
            component: 'FormattedDate',
            maxWidth: 150,
            padding: '10px 12px'
          },
        ]
      },
      urlParams() {
        return {
          related: 'employee,company',
          page: 1,
        }
      }
    },
    methods: {
      getEmail(row) {
        return this.get(row, 'relationships.employee.attributes.email', '')
      },
      async downloadCheck(row) {
        await downloadFileForEntity(row, 'employee-checks')
      },
      previewCheck(row, index) {
        this.$router.push(`${this.$route.path}?id=${row.id}`)
        this.selectedIndex = index
        this.rowToEdit = row
        this.showCheckPreviewDialog = true
      },
      closePreview() {
        this.rowToEdit = null
        this.selectedIndex = -1
        this.showCheckPreviewDialog = false
      }
    },
    watch: {
      '$route.query.id'(newVal, oldVal) {
        if (!newVal && oldVal) {
          this.closePreview()
        }
      }
    }
  }
</script>
