<template>
  <div>
    <div class="grid grid-cols-2 gap-x-5">
      <template v-if="lineItem.id">
        <div class="col-span-2">
          <div class="mb-5 grid grid-cols-1 gap-5 md:grid-cols-3 lg:grid-cols-4">
            <base-stats-card icon="IconSalesToDate" :title="$t('Total budget')">
              {{ $formatPrice(lineItem.attributes.amount) }}
            </base-stats-card>
            <base-stats-card icon="IconOpenAmount" :title="$t('Total Actual')">
              {{ $formatPrice(lineItem.attributes.amount_to_date) }}
            </base-stats-card>
            <base-stats-card icon="IconPaymentsHold" class="capitalize" :title="$t('Estimated Final Cost')">
              {{ $formatPrice(lineItem.attributes.est_final_cost) }}
            </base-stats-card>
            <base-stats-card icon="IconRetention" :title="$t('Completion')">
              {{ $formatPercent(lineItem.attributes.completion) }}
            </base-stats-card>
          </div>
        </div>
        <div class="col-span-2 md:col-span-1">
          <base-detail-card :title="$t('Line Item Details')"
                            :key="lineItem.id"
                            :entity="lineItem"
                            entity-name="cost-budget"
                            :can-expand="false"
                            :expanded="true">
            <base-detail-card-row :title="$t('Phase Code')">
              {{ lineItem.attributes.phase_code }}
            </base-detail-card-row>
            <base-detail-card-row :title="$t('Cost Code')" stripe>
              {{ lineItem.attributes.cost_code }}
            </base-detail-card-row>
            <base-detail-card-row :title="$t('Change Order')">
              {{ lineItem.attributes.change_order }}
            </base-detail-card-row>
            <base-detail-card-row :title="$t('Description')" stripe>
              {{ lineItem.attributes.description }}
            </base-detail-card-row>
          </base-detail-card>
        </div>
        <div class="col-span-2 md:col-span-1">
          <base-detail-card :title="$t('From Job')"
                            :key="lineItem.id"
                            entity-name="job"
                            :can-expand="false"
                            :expanded="true">
            <template v-slot:title>
              <div class="flex">
                <span class="mr-2">{{ $t('From Job:') }}</span>
                <JobLink :data="job"/>
              </div>
            </template>
            <base-detail-card-row :title="$t('Job Details')">
              {{ job.attributes.number }}
              <template v-if="job.attributes.description">
                ({{ job.attributes.description }})
              </template>
            </base-detail-card-row>
            <base-detail-card-row :title="$t('Retention – Subcontracts')" stripe>
              {{ $formatPercent(job.attributes.subcontract_retention_percent / 100) }}
            </base-detail-card-row>
            <base-detail-card-row :title="$t('Retention – Billings')">
              {{ $formatPercent(job.attributes.billing_retention_percent / 100) }}
            </base-detail-card-row>
            <base-detail-card-row :title="$t('Job Units')" stripe>
              {{ Math.round(job.attributes.units) }}
            </base-detail-card-row>
          </base-detail-card>
        </div>
        <div class="col-span-2 md:col-span-1 mt-5">
          <LineItemBudgetsCard :budgets="budgets"/>
        </div>
      </template>
    </div>
    <base-back-link :to="backPath"/>
  </div>
</template>
<script>
  import LineItemBudgetsCard from "@/modules/job-costing/components/line-items/LineItemBudgetsCard.vue";

  export default {
    components: { LineItemBudgetsCard },
    computed: {
      backPath() {
        const jobId = this.$route.query.fromJob
        if (jobId) {
          return `/job-costing/jobs/${jobId}/line-items`
        }
        return '/job-costing/income-line-items'
      },
      lineItem() {
        return this.$store.state.jobCosting.currentLineItem
      },
      lineItemLoading() {
        return this.$store.state.jobCosting.lineItemLoading
      },
      job() {
        const { job } = this.lineItem.relationships
        return job
      },
      budgets() {
        const { budgets } = this.lineItem.relationships
        return budgets
      },
    },
  }
</script>
