<template>
  <BaseDataTable
      :data="billings"
      :columns="columns"
      :can-reorder-columns="false"
      :title="$t('Payment Billings')"
      :has-summary="true"
      :default-filters="false"
      :group-by="groupBy"
      :hide-table-top-section="true"
      :show-pagination="false"
      ref="table"
  >
    <template #header-end="{row}">
        <span>
          {{ getFormattedCustomerAmount(row.header.attributes.id) }}
        </span>
    </template>
    <template #attributes.number="{row}">
      <BillingLink :data="row"/>
    </template>
    <template #attributes.status_summary>
      <span class="font-medium ml-2">{{ $t('Totals') }}</span>
    </template>
    <template #pivots.gross_amount_summary>
      <span>{{ $formatPrice(totalGross) }}</span>
    </template>
    <template #pivots.discount_amount_summary>
      <span>{{ $formatPrice(totalDiscount) }}</span>
    </template>
    <template #pivots.net_amount_summary>
      <span class="font-medium">{{ $formatPrice(totalPaid) }}</span>
    </template>
  </BaseDataTable>
</template>
<script>
  import { groupByType } from '@/components/table/utils/groupByTypes';
  import sumBy from 'lodash/sumBy'
  import sum from 'lodash/sum'

  export default {
    props: {
      title: {
        type: String,
        default: '',
      },
      billings: {
        type: Array,
      },
    },
    data() {
      return {
        groupBy: groupByType.Customer,
      }
    },
    computed: {
      columns() {
        return [
          {
            label: this.$t('Number'),
            prop: 'attributes.number',
            maxWidth: 120,
            params: {
              tabindex: -1,
            },
          },
          {
            label: this.$t('Due Date'),
            prop: 'attributes.due_date',
            minWidth: 120,
            maxWidth: 160,
            component: 'FormattedDate',
          },
          {
            label: this.$t('Discount Date'),
            prop: 'attributes.discount_date',
            minWidth: 120,
            maxWidth: 160,
            component: 'FormattedDate',
            padding: '6px',
          },
          {
            label: this.$t('Status'),
            prop: 'attributes.status',
            maxWidth: 90,
            component: 'StatusLink',
          },
          {
            label: this.$t('Gross'),
            prop: 'pivots.gross_amount',
            component: 'FormattedPrice',
            minWidth: 50,
            maxWidth: 120,
            align: 'right',
          },
          {
            label: this.$t('Discount'),
            prop: 'pivots.discount_amount',
            component: 'FormattedPrice',
            minWidth: 50,
            maxWidth: 120,
            align: 'right',
          },
          {
            label: this.$t('Net'),
            prop: 'pivots.net_amount',
            component: 'FormattedPrice',
            minWidth: 50,
            maxWidth: 120,
            align: 'right',
          },
        ]
      },
      totalGross() {
        return this.sumBy(this.billings, 'pivots.gross_amount')
      },
      totalDiscount() {
        return this.sumBy(this.billings, 'pivots.discount_amount')
      },
      totalPaid() {
        return this.sumBy(this.billings, 'pivots.net_amount')
      },
    },
    methods: {
      sumBy(data, prop) {
        const mappedData = data.map(row => +this.get(row, prop))
        return sum(mappedData)
      },
      getCustomerPaidAmount(customerId) {
        const customerBillings = this.data.filter(billing => {
          const currentCustomerId = this.get(billing, 'attributes.customer_id', '')
          return currentCustomerId === customerId
        })
        return sumBy(customerBillings, 'pivots.gross_amount')
      },
      getFormattedCustomerAmount(customerId) {
        const amount = this.getCustomerPaidAmount(customerId)
        if (amount) {
          return this.$formatPrice(amount)
        }
        return ''
      },
    },
  }
</script>
