<template>
  <PurchaseOrderTable
      :status="status"
      :key="status"
  />
</template>
<script>
  import { resourceStatuses } from '@/enum/enums'
  import PurchaseOrderTable from '@/modules/purchasing-inventory/components/purchase-orders/PurchaseOrderTable'

  export default {
    components: {
      PurchaseOrderTable,
    },
    data() {
      return {
        status: resourceStatuses.Pending,
      }
    },
    watch: {
      '$route.meta': {
        immediate: true,
        handler(meta) {
          this.status = meta?.status
        },
      },
    },
  }
</script>
