<template>
  <ProfileForm
      :data="$store.state.user.selectedUser"
      :save-text="$t('Update user')"
      :show-buttons="$can('users_update')">
  </ProfileForm>
</template>
<script>
  import ProfileForm from "@/modules/profile/components/ProfileForm";

  export default {
    components: {
      ProfileForm
    },
  }
</script>
<style>
</style>
