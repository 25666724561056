<template>
  <div class="p-4">
    <component
        v-bind="readOnlyProps"
        :is="getComponentByType"
        :billing="billingData.attributes"
        :model="billingData.attributes"
        :invoice="billingData.attributes"
        :showAddButton="false"
        :actionColumns="false"
        :billing-entries="getEntries"
        :show-pagination="false"
        :no-borders="true"
        :limit-max-height="false"
        readOnly
    />
  </div>
</template>
<script>
  import { billingTypes } from '@/modules/accounts-receivable/pages/billings/billings'
  import UnitPriceBillingEntriesDetails
    from '@/modules/accounts-receivable/components/unit-price-billings/UnitPriceBillingEntriesDetails'
  import CostPlusBillingEntries
    from '@/modules/accounts-receivable/components/cost-plus-billings/CostPlusBillingEntries'
  import ProgressBillingEntriesDetails
    from '@/modules/accounts-receivable/components/progress-bllings/ProgressBillingEntriesDetails'
  import LumpSumBillingEntries
    from '@/modules/accounts-receivable/components/lump-sum-billings/LumpSumBillingEntriesDetails'
  import ServiceInvoiceEntries from '@/modules/service-billing/components/ServiceInvoiceEntries'
  import axios from "axios";

  const entriesTableMap = {
    [billingTypes.Progress]: 'ProgressBillingEntriesDetails',
    [billingTypes.CostPlus]: 'CostPlusBillingEntries',
    [billingTypes.UnitPrice]: 'UnitPriceBillingEntriesDetails',
    [billingTypes.LumpSum]: 'LumpSumBillingEntries',
    [billingTypes.Service]: 'ServiceInvoiceEntries',
    default: '',
  }

  export default {
    components: {
      UnitPriceBillingEntriesDetails,
      CostPlusBillingEntries,
      ProgressBillingEntriesDetails,
      LumpSumBillingEntries,
      ServiceInvoiceEntries,
    },
    props: {
      billingType: {
        type: String,
        default: billingTypes.Progress,
      },
      billing: {
        type: Object,
        default: () => ({}),
      },
      fetchEntries: {
        type: Boolean,
        default: false,
      }
    },
    computed: {
      billingData() {
        return this.params?.billing || this.billing
      },
      billingTypeData() {
        return this.params?.billingType || this.billingType
      },
      readOnlyProps() {
        return {
          readOnly: true,
          showCellsLegend: false,
          actions: '',
        }
      },
      getComponentByType() {
        return entriesTableMap[this.billingTypeData]
      },
      async getEntries() {
        let entries = this.get(this.billingData, 'entries', [])
        if (this.fetchEntries && this.billingType === billingTypes.UnitPrice) {
          entries = await this.getBillingEntries()
        }
        return entries.map(entry => entry.attributes)
      },
    },
    methods: {
      async getBillingEntries() {
        const { data } = await axios.get(`/restify/billing-entries`, {
          params: {
            billing_id: this.billingData.id,
            perPage: 500,
            related: 'addlSource',
          }
        })
        return data
      }
    }
  }
</script>
