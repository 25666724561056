<template>
  <BaseCard class="border">
    <div
      class="text-sm flex flex-col space-y-4"
      :class="{
          '-mb-4': address,
      }">
      <div :title="$t('Code')"
           class="detail-row">
        <ToolIcon class="detail-icon"/>
        <span>{{ equipment.code }}</span>
        <StatusBadge :status="equipment.status"/>
      </div>
      <div :title="$t('Description')"
           class="detail-row">
        <BookOpenIcon class="detail-icon"/>
        <span>{{ equipment.description }}</span>
      </div>
      <div :title="$t('Class')" class="detail-row">
        <BoxIcon class="detail-icon"/>
        <router-link
          v-if="equipment.class_id"
          :to="`/equipment/settings/classes/${equipment.class_id}/view`"
        >
          {{ get(equipmentFullData, 'relationships.class.attributes.code') }}
        </router-link>
        <span v-else>({{ $t('No Class') }})</span>
      </div>
      <div v-if="address" class="flex border-t border-gray-100">
        <AddressButton :value="location"/>
      </div>
    </div>
  </BaseCard>
</template>
<script setup lang="ts">
import AddressButton from '@/modules/common/components/buttons/AddressButton.vue'
import { BookOpenIcon, BoxIcon, ToolIcon } from 'vue-feather-icons'
import { computed } from 'vue'
import { locationLink } from '@/utils/utils'

const props = defineProps({
  equipment: {
    type: Object,
    required: true,
  },
  equipmentFullData: {
    type: Object,
    required: true,
  },
})

const address = computed(() => {
  return props.equipmentFullData?.relationships?.address
})

const location = computed(() => {
  return locationLink(address.value)
})
</script>
