<template>
  <div>
    <InvoiceEntries
        v-if="!invoice.meta?.is_initialization"
        v-bind="$attrs"
        :data="invoiceData"
        :read-only="true"
        :showCellsLegend="false"
    />

    <InvoicePayments
        v-if="showPaymentsTable(invoice)"
        :data="invoicePayments"
        :class="{
          'opacity-50': invoiceData.is_voided
        }"
        class="mt-4"
    />
  </div>
</template>
<script>
  import InvoicePayments from '@/modules/accounts-payable/components/invoice/InvoicePayments'
  import InvoiceEntries from '@/modules/accounts-payable/components/invoice/InvoiceEntries.vue'
  import { showPaymentsTable } from '@/modules/accounts-payable/pages/invoices/invoiceUtils'

  export default {
    components: {
      InvoiceEntries,
      InvoicePayments,
    },
    props: {
      invoice: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        perPage: 200,
      }
    },
    computed: {
      invoiceData() {
        return this.params?.invoice || this.invoice
      },
      invoicePayments() {
        return this.get(this.invoiceData, 'payments') || this.get(this.invoiceData, 'originalRow.relationships.payments') || []
      },
    },
    methods: {
      showPaymentsTable,
    },
  }
</script>
