<template>
  <div>
    <BaseDataTable
        :columns="columns"
        :edit-entity-url-query="editEntityUrlQuery"
        :add-text="$t('New craft code')"
        ref="table"
        url="/restify/craft-codes"
        view-entity-url-query="/payroll/codes/craft-codes"
        import-url="/payroll/codes/craft-codes/import"
        default-sort="code"
        actions="search,refresh,print,import,export"
        entity="craft-code"
        permission="employees"
        @add="onRowAdd"
        @edit="onRowEdit"
    />
    <BaseFormDialog
        v-if="showDialog"
        :title="rowToEdit ? $t('Edit Craft Code') : $t('Add Craft Code')"
        :open.sync="showDialog"
        size="xl"
        @close="onEventTrigger(false)"
    >
      <CraftCodeForm
          :key="renderKey"
          :data="rowToEdit"
          @cancel="onEventTrigger(false)"
          @save="onEventTrigger"
          @create-another="renderKey++"
      />
    </BaseFormDialog>
  </div>
</template>
<script>
  import CraftCodeForm from '@/modules/payroll/components/codes/CraftCodeForm'

  export default {
    components: {
      CraftCodeForm,
    },
    data() {
      return {
        renderKey: 1,
        editEntityUrlQuery: '?id={ID}',
        rowToEdit: null,
        showDialog: false,
        columns: [
          {
            label: this.$t('Code'),
            prop: 'attributes.code',
            component: 'EntityLink',
            redirectTo: '/payroll/codes/craft-codes?id={ID}',
          },
          {
            label: this.$t(`Level`),
            prop: 'attributes.level',
          },
          {
            label: this.$t('Description'),
            prop: 'attributes.description',
          },
          {
            label: this.$t('Trade'),
            prop: 'attributes.trade',
          },
          {
            label: this.$t('Classification'),
            prop: 'attributes.classification',
          },
          {
            label: this.$t('Active'),
            prop: 'attributes.active',
            component: 'Status',
            align: 'center',
          },
        ],
      }
    },
    methods: {
      onRowEdit(row) {
        this.showDialog = true
        this.rowToEdit = row
      },
      onRowAdd() {
        this.showDialog = true
      },
      refreshTable() {
        this.$refs.table.refresh()
      },
      onEventTrigger(refresh = true) {
        this.rowToEdit = null
        this.showDialog = false
        if (!refresh) {
          return
        }
        this.refreshTable()
      },
    },
  }
</script>
