<template>
  <base-detail-card
      :title="$t('Budgets')"
      :expanded="true"
      entity-name="budget">
    <AgDataTable
        :data="budgets"
        :columns="columns"
        :compact="true"
        :show-pagination="false"
        :pagination="false"
        :row-height="32"
        :no-borders="true"
        :resizable="false"
        domLayout="autoHeight"
        class="px-4 pb-4"
    >
      <template #name="{row}">
        {{getJobTypeNameById(row)}}
      </template>
    </AgDataTable>
  </base-detail-card>
</template>
<script>
  export default {
    props: {
      budgets: {
        type: Array,
        default: () => [],
      }
    },
    computed: {
      columns() {
        return [
          {
            label: this.$t('Name'),
            prop: 'name'
          },
          {
            label: this.$t('Amount'),
            prop: 'attributes.amount',
            component: 'FormattedPrice',
            align: 'right',
            params: {
              hideZero: true,
            },
          },
          {
            label: this.$t('Qty'),
            prop: 'attributes.quantity',
            align: 'right',
          },
          {
            label: this.$t('Measure'),
            prop: 'attributes.um',
          },
          {
            label: this.$t('Unit Price'),
            prop: 'attributes.unit_rate',
            align: 'right',
            component: 'FormattedPrice',
            params: {
              hideZero: true,
            }
          },
        ]
      }
    },
    methods: {
      getJobTypeNameById(lineItem) {
        return this.$store.getters['jobCosting/getJobTypeNameById'](lineItem.attributes.job_type_id)
      }
    }
  }
</script>
