<template>
  <div>
    <BaseInput
      v-if="isReadOnly && !disabled"
      v-bind="$attrs"
      :readonly="true"
      :placeholder="placeholder || $t('Automatically assigned')"
      :value="showValue ? value : undefined"
      key="readonly-state"
    >
      <template #suffix>
        <LoadingCircle
          v-if="loading"
          class="w-4 h-4"
        />
        <BaseTooltip
          v-else
          :content="unlockText || $t('Unlock to enter a value')"
          :tabindex="-1"
        >
          <UnlockIcon
            tabindex="0"
            class="w-4 h-4 cursor-pointer"
            @click="unlockInput"
            @keyup.enter="unlockInput"
          />
        </BaseTooltip>
      </template>
    </BaseInput>
    <BaseInput
      v-else
      v-on="listeners"
      v-bind="$attrs"
      :disabled="disabled"
      :value="value"
      :placeholder="placeholder"
      ref="input"
      key="editable-input"
    >
      <template #suffix>
        <LoadingCircle
          v-if="loading"
          class="w-4 h-4"
        />
        <BaseTooltip
          v-else-if="!disabled"
          :disabled="lockTooltipDisabled"
          :content="lockText || $t('Lock to assign value automatically')"
          :tabindex="-1"
        >
          <LockIcon
            tabindex="0"
            class="w-4 h-4 cursor-pointer"
            @click="lockInput"
            @keyup.enter="lockInput"
          />
        </BaseTooltip>
      </template>
    </BaseInput>
  </div>
</template>
<script>
  import { taggableInputStates } from '@/enum/enums'
  import { LockIcon, UnlockIcon } from 'vue-feather-icons'
  import LoadingCircle from '@/modules/wiki/components/LoadingCircle.vue';

  export default {
    inheritAttrs: false,
    components: {
      UnlockIcon,
      LockIcon,
      LoadingCircle,
    },
    props: {
      value: {
        type: [Number, String],
        default: '',
      },
      placeholder: String,
      isBooleanSourceType: Boolean,
      defaultState: Boolean,
      showValue: Boolean,
      disabled: Boolean,
      lockTooltipDisabled: Boolean,
      unlockText: String,
      lockText: String,
      loading: Boolean,
    },
    data() {
      return {
        isReadOnly: this.defaultState,
      }
    },
    computed: {
      listeners() {
        return {
          ...this.$listeners,
          input: this.onInput,
        }
      },
    },
    methods: {
      onInput(val) {
        this.$emit('input', val)
      },
      unlockInput() {
        this.isReadOnly = false
        this.$nextTick(() => {
          const input = this.$el.querySelector('input')
          input && input.focus()
        })

        const valueToEmit = this.isBooleanSourceType ? true : taggableInputStates.Manual
        this.emitter(valueToEmit)
      },
      lockInput() {
        this.isReadOnly = true

        const valueToEmit = this.isBooleanSourceType ? false : taggableInputStates.Automatic
        this.emitter(valueToEmit)
      },
      emitter(state) {
        this.$emit('on-update-state', state)
      },
    },
  }
</script>
