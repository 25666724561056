<template>
  <el-dropdown
      trigger="click"
  >
    <BaseButton variant="gray-light">
      <div class="space-x-1">
        <span>{{ $t('PDF Template:') }}</span>
        <b class="capitalize">{{ selectedTemplate }}</b>
      </div>
    </BaseButton>

    <el-dropdown-menu class="h-full" slot="dropdown">
      <CheckTemplateSelect class="max-w-md h-full px-4" v-model="selectedTemplate"/>
    </el-dropdown-menu>
  </el-dropdown>
</template>
<script>
  import { Dropdown, DropdownMenu } from 'element-ui'
  import CheckTemplateSelect from "@/modules/payroll/components/CheckTemplateSelect";

  export default {
    name: 'CheckTemplateDropdown',
    components: {
      CheckTemplateSelect,
      [Dropdown.name]: Dropdown,
      [DropdownMenu.name]: DropdownMenu,
    },
    props: {
      value: {
        type: String,
        default: false,
      },
    },
    computed: {
      selectedTemplate: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      }
    }
  }
</script>
<style>
</style>
