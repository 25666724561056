<template>
  <div>
    <layout-tabs :items="filteredItems"/>
    <router-view/>
  </div>
</template>
<script>

  export default {
    data() {
      return {
        items: [
          {
            name: this.$t('Equipment History Listing'),
            path: '/equipment/reports/history-listing',
            permission: 'equipment_management_reports',
          },
          {
            name: this.$t('Equipment Expense Listing'),
            path: '/equipment/reports/expense-listing',
            permission: 'equipment_management_reports',
          },
        ],
      }
    },
    computed: {
      filteredItems() {
        return this.items.filter(item => this.$can(item.permission))
      },
    },
  }
</script>
