<template>
  <base-form :loading="loading"
             :save-text="$t('Create state tax')"
             :update-text="$t('Update state tax')"
             layout="modal"
             grid-classes="grid grid-cols-6 gap-y-3 gap-x-4"
             @submit="onSubmit"
  >
    <div class="col-span-6 md:col-span-2">
      <employee-select v-model="model.employee_id"
                       :disabled="!!model.id || onViewResourcePage"
                       :display-pay-column="true"
                       :initial-value="currentEmployee"
                       id="employee"
                       rules="required"
                       @change="validateSelection"
      />
    </div>
    <div class="col-span-6 md:col-span-2">
      <tax-jurisdiction-select
        v-model="model.jurisdiction_id"
        :disabled="!!model.id"
        :label="$t('Jurisdiction')"
        :name="$t('Jurisdiction')"
        :placeholder="$t('Jurisdiction')"
        id="jurisdiction"
        rules="required"
        @change="validateSelection"
      />
    </div>
    <div class="col-span-6 md:col-span-2">
      <base-select
        v-model="model.type"
        :disabled="!!model.id"
        :options="taxTypeOptions"
        :label="$t('Tax Type')"
        :name="$t('Tax Type')"
        :placeholder="$t('Tax Type')"
        id="type"
        rules="required"
        @change="onChangeType"
      />
    </div>
    <div class="col-span-6 md:col-span-2">
      <base-select v-model="model.status"
                   :options="getTaxStatuses"
                   :label="$t('Tax Filing Status')"
                   :name="$t('Tax Filing Status')"
                   :placeholder="$t('Tax Filing Status')"
                   :rules="isWithholdingTaxType ? 'required': ''"
                   id="status"
      />
    </div>
    <div class="col-span-6 md:col-span-2">
      <base-input
          v-if="model.status === federalFilingStatuses.PERCENT_OF_FEDERAL"
          v-model="model.percent_of_federal"
          :label="$t('Percent of Federal withholding tax')"
          :name="$t('Percent of Federal withholding tax')"
          :placeholder="$t('Percent of Federal withholding tax')"
          :min="0"
          :max="999.999"
          :step="0.001"
          type="number"
          format="percent"
          id="percent_of_federal"
          rules="min_value:0|max_value:999.999"
      />
      <base-input v-else
                  v-model="model.personal_tax_credit_allowance_count"
                  :label="$t('Personal Tax Credit Allowances')"
                  :name="$t('Personal Tax Credit Allowances')"
                  :placeholder="$t('Personal Tax Credit Allowances')"
                  :disabled="!isWithholdingTaxType"
                  :min="0"
                  :max="99"
                  :step="1"
                  type="number"
                  id="personal_tax_credit_allowance_count"
                  rules="min_value:0|max_value:99"
      />
    </div>
    <div class="col-span-6 md:col-span-2">
      <base-input v-model="model.estimated_deduction_allowance_count"
                  :label="$t('Withholding Allowances for Estimated Deductions')"
                  :name="$t('Withholding Allowances for Estimated Deductions')"
                  :placeholder="$t('Withholding Allowances for Estimated Deductions')"
                  :disabled="!isWithholdingTaxType"
                  :min="0"
                  :max="99"
                  :step="1"
                  type="number"
                  id="estimated_deduction_allowance_count"
                  rules="min_value:0|max_value:99"
      />
    </div>
    <div class="col-span-6 md:col-span-3">
      <base-select v-model="model.sit_adjustment_type"
                   :options="taxAdjustmentOptions"
                   :tip="$t('Special Adjustment Withholding Tax Adjustment')"
                   :label="$t('Special Adjustment')"
                   :name="$t('Special Adjustment')"
                   :palceholder="$t('Special Adjustment')"
                   :disabled="!isWithholdingTaxType"
                   id="adjustment"
                   rules="required"
                   @change="onAdjustmentTypeChange"
      />
    </div>
    <div class="col-span-6 md:col-span-3">
      <base-input v-model="model.sit_adjustment_rate"
                  v-bind="getAdjustmentInputAttrs"
                  :disabled="!isWithholdingTaxType || model.sit_adjustment_type === taxAdjustments.NO_ADJUSTMENT"
      />
    </div>
  </base-form>
</template>
<script>
  import axios from 'axios'
  import EmployeeSelect from '@/components/select/entities/EmployeeSelect'
  import { federalFilingStatuses, taxAdjustments, taxTypes } from '@/enum/enums'
  import TaxJurisdictionSelect from '@/components/select/entities/TaxJurisdictionSelect'
  import i18n from "@/i18n";
  import { getAdjustmentTypes, getTaxStatusOptions } from "@/modules/payroll/components/taxes/stateTax";

  export default {
    components: {
      EmployeeSelect,
      TaxJurisdictionSelect,
    },
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        taxTypes,
        taxAdjustments,
        loading: false,
        federalFilingStatuses,
        taxAdjustmentOptions: getAdjustmentTypes(),
        taxTypeOptions: [
          {
            label: this.$t('Withholding'),
            value: taxTypes.STATE_WITHHOLDING,
          },
          {
            label: this.$t('Disability'),
            value: taxTypes.STATE_DISABILITY,
          },
          {
            label: this.$t('Unemployment'),
            value: taxTypes.STATE_UNEMPLOYMENT,
          },
        ],
        exemptStatusOptions: [
          {
            label: this.$t('Exempt'),
            value: federalFilingStatuses.EXEMPT,
          },
        ],
        model: {
          employee_id: undefined,
          jurisdiction_id: '',
          type: taxTypes.STATE_WITHHOLDING,
          status: null,
          percent_of_federal: 0,
          personal_tax_credit_allowance_count: 0,
          estimated_deduction_allowance_count: 0,
          sit_adjustment_type: taxAdjustments.NO_ADJUSTMENT,
          sit_adjustment_rate: 0,
        },
      }
    },
    computed: {
      taxStatusOptions() {
        const options = getTaxStatusOptions()
        return options.filter(option => option.enabled ? option.enabled() : true)
      },
      getAdjustmentInputAttrs() {
        let commonAttrs = {
          label: i18n.t('Adjustment'),
          name: i18n.t('Adjustment'),
          placeholder: i18n.t('Adjustment'),
          tip: i18n.t('Enter either the $ amount or percentage corresponding to the previous selection.'),
          type: 'number',
          id: 'adjustment_rate',
        }

        if ([taxAdjustments.ADDITIONAL_AMOUNT, taxAdjustments.FIXED_AMOUNT].includes(this.model.sit_adjustment_type)) {
          return {
            ...commonAttrs,
            format: 'price',
            min: 0,
            max: 99999.99,
            step: 0.01,
            rules: 'min_value:0|max_value:99999.99',
          }
        }
        return {
          ...commonAttrs,
          format: 'percent',
          min: 0,
          max: 100,
          step: 0.01,
          rules: 'min_value:0|max_value:100',
        }
      },
      currentEmployee() {
        if (!this.onViewResourcePage) { // onViewEmployeePage
          return this.get(this.data, 'relationships.employee')
        }

        return this.$store.state.payroll.currentEmployee
      },
      isWithholdingTaxType() {
        return this.model.type === taxTypes.STATE_WITHHOLDING;
      },
      getTaxStatuses() {
        if (this.isWithholdingTaxType) {
          return this.taxStatusOptions
        }
        return this.exemptStatusOptions
      },
    },
    methods: {
      onAdjustmentTypeChange(type) {
        if (type !== taxAdjustments.NO_ADJUSTMENT) {
          return
        }
        this.model.sit_adjustment_rate = 0
      },
      async onChangeType() {
        await this.$nextTick()
        if (this.isWithholdingTaxType) {
          this.model.status = federalFilingStatuses.SINGLE
          return
        }
        this.model.status = federalFilingStatuses.EXEMPT
        this.model.percent_of_federal = 0
        this.model.personal_tax_credit_allowance_count = 0
        this.model.estimated_deduction_allowance_count = 0
        this.model.sit_adjustment_type = taxAdjustments.NO_ADJUSTMENT
        this.model.sit_adjustment_rate = 0
      },
      async validateSelection() {
        const { employee_id, jurisdiction_id } = this.model
        if (!employee_id || !jurisdiction_id) {
          return
        }
        const { data } = await axios.get('/restify/employee-state-taxes', {
          params: {
            employee_id,
            jurisdiction_id,
          },
        })
        const taxId = this.get(data, '[0].id', false)
        if (!taxId) {
          return
        }
        const confirmed = await this.$confirm({
          title: this.$t('Employee Tax'),
          description: this.$t('Tax for this employee and state code has been set up, you want to edit it?'),
          buttonText: this.$t('Confirm'),
        })
        if (!confirmed) {
          this.model.employee_id = undefined
          return this.focusOnEmployeeSelect()
        }
        await this.$router.replace(`/payroll/taxes?id=${taxId}`)
      },
      focusOnEmployeeSelect() {
        const select = document.querySelector('#employee input')
        if (!select) {
          return
        }
        select.focus()
      },
      async onSubmit() {
        try {
          this.loading = true
          if (this.model.id) {
            await axios.put(`/restify/employee-state-taxes/${this.model.id}`, this.model)
            this.$success(this.$t('Employee tax updated.'))
          } else {
            await axios.post('/restify/employee-state-taxes', this.model)
            this.$success(this.$t('Employee tax created.'))
          }
          return this.$emit('save')
        } catch (err) {
          if (err.handled) {
            return
          }
          console.log('err', err)
          this.$error(this.$t('Could not update the employee tax.'))
        } finally {
          this.loading = false
        }
      },
    },
    watch: {
      data: {
        immediate: true,
        handler(value) {
          if (this.onViewResourcePage) {
            this.model.employee_id = this.currentEmployee.id
          }

          if (!value?.id) {
            return
          }
          this.model = {
            ...this.model,
            ...value.attributes,
          }
        },
      },
    },
  }
</script>
