<template>
  <div class="report-page mb-4">
    <BaseReportHeader
        :header="reportHeader"
        class="my-4 leading-tight tracking-wide font-semibold text-gray-700 text-xs border rounded border-gray-200 p-4 bg-gray-50"
    />
    <EquipmentAdjustmentEntries :data="data" :read-only="true"/>
  </div>
</template>
<script>
  import EquipmentAdjustmentEntries from "@/modules/equipment/components/equipment-init/EquipmentAdjustmentEntries.vue";

  export default {
    components: {
      EquipmentAdjustmentEntries,
    },
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      filters() {
        const { period, fiscal_year } = this.data?.attributes || {}
        return `${this.$t('Equipment Adjustments for Period ')} ${period} Fiscal Year ${fiscal_year}`
      },
      reportHeader() {
        return {
          title: this.$t('Equipment Adjustment - Proof Listing'),
          company: {
            name: this.$currentCompany?.name,
          },
          code: '39.93',
          filters: [this.filters],
        }
      },
    },
  }
</script>
