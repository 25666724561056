<template>
  <BaseWrapper
      v-bind="$attrs"
      :items="items"
      :entity="entity"
      :footer="footer"
      :locationLink="getLocationLink"
      phone-key="worksite_phone"
      status-key="status"
  >
    <template #title>
      {{ entity.number }}
      <span v-if="entity.description">
        ({{ entity.description }})
      </span>
    </template>
  </BaseWrapper>
</template>
<script>
  import { locationLink } from '@/utils/utils'
  import BaseWrapper from '@/modules/common/components/entity-preview/BaseWrapper'

  export default {
    inheritAttrs: false,
    components: {
      BaseWrapper,
    },
    props: {
      entity: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        items: [
          {
            title: this.$t('Date'),
            value: this.$formatDate(this.entity.date),
          },
          {
            title: this.$t('Cost'),
            value: this.$formatPrice(this.entity.cost_amount),
          },
          {
            title: this.$t('Income'),
            value: this.$formatPrice(this.entity.income_amount),
          },
        ],
        footer: {
          phone: true,
          address: true,
        },
      }
    },
    computed: {
      getLocationLink() {
        return locationLink(this.entity)
      },
    },
  }
</script>
