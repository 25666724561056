<template>
  <div>
    <AgDataTable
        ref="table"
        url="/restify/documents"
        actions="add,edit,delete,refresh,search"
        domLayout="autoHeight"
        :url-params="urlParams"
        :columns="columns"
        :actions-column-width="180"
        :row-height="75"
        :header-height="45"
        :exclude-filters="['documentable_type', 'documentable_id']"
        :open-entity-in-new-tab="false"
        :add-text="$t('Upload Document')"
        @add="showAddDialog = true"
        @view="onView"
        @edit="onEdit"
    >
      <template #attributes.name="{row}">
        <FileInfo :data="row"/>
      </template>
      <template #attributes.tags="{row}">
        <div v-if="row.attributes?.tags" class="space-x-1 overflow-x-auto">
          <BaseBadge v-for="(tag, index) in row.attributes?.tags" :key="index">
            {{ tag }}
          </BaseBadge>
        </div>
      </template>
      <template #extra-actions-before="{row}">
        <TableDownloadButton
            :has-margin="false"
            :loading="row.downloading"
            @click="downloadMedia(row)"/>
        <TableViewButton
            v-if="$isAuthorized('authorizedToShow', row)"
            @click="onView(row)"
        />
      </template>
    </AgDataTable>
    <DocumentUploadDialog
        v-if="showAddDialog"
        :open.sync="showAddDialog"
        :upload-url="uploadAction"
        @save="onSave"
        @close="onDialogClose"
    />

    <DocumentUploadDialog
        v-if="showEditDialog"
        :is-edit="true"
        :open.sync="showEditDialog"
        :upload-url="showEditDialog"
        :document="rowToEdit"
        @save="onSave"
        @close="onDialogClose"
    />
    <DocumentPreviewDialog
        v-if="showViewDialog"
        :open.sync="showViewDialog"
        :data="rowToEdit"
    />
  </div>
</template>
<script lang="ts" setup>
import {computed, ref} from "vue";
import {Column} from "@/components/ag-grid/tableTypes";
import i18n from "@/i18n";
import {useRoute} from "vue2-helpers/vue-router";
import DocumentUploadDialog from "@/modules/common/components/documents/DocumentUploadDialog.vue";
import FileInfo from "@/modules/common/components/documents/FileInfo.vue";
import DocumentPreviewDialog from "@/modules/common/components/documents/DocumentPreviewDialog.vue";
import TableViewButton from "@/components/table/actions/TableViewButton.vue";
import Data = API.Data;
import {RestifyResources} from "@/components/form/util";

const props = defineProps({
  documentable_id: {
    type: String,
  },
  documentable_type: {
    type: String,
  },
})

const columns = computed<Column[]>(() => {
  return [
    {
      headerName: i18n.t('Name'),
      field: 'attributes.name',
      minWidth: 320,
      maxWidth: 500,
    },
    {
      headerName: i18n.t('Start Date'),
      field: 'attributes.start_date',
      component: 'FormattedDate',
      minWidth: 120,
      maxWidth: 160,
    },
    {
      headerName: i18n.t('Expiration Date'),
      field: 'attributes.expiration_date',
      component: 'FormattedDate',
      minWidth: 120,
      maxWidth: 160,
    },
    {
      headerName: i18n.t('Type'),
      field: 'relationships.documentType.attributes.description',
      minWidth: 80,
      maxWidth: 160,
    },
    {
      headerName: i18n.t('Tags'),
      field: 'attributes.tags',
      minWidth: 120,
      maxWidth: 250,
    },
    {
      headerName: i18n.t('Created By'),
      field: 'attributes.created_by',
      component: 'UserLink',
      minWidth: 140,
      maxWidth: 170,
    },
  ]
})

const showAddDialog = ref(false)
const showEditDialog = ref(false)
const showViewDialog = ref(false)

const route = useRoute()
const resource = computed(() => {
  let resourceName = props.documentable_type || route.meta.resourceName
  if (resourceName.endsWith('s')) {
    resourceName = resourceName.slice(0, -1)
  }
  return resourceName
})
const resourceId = computed(() => {
  return props.documentable_id || route.params.id
})
const urlParams = computed(() => {
  return {
    documentable_id: resourceId.value,
    documentable_type: resource.value,
    related: 'documentType',
  }
})

async function downloadMedia(file: Data<Document>) {
  window.open(file?.attributes?.url, '_blank')
}

const table = ref()
const rowToEdit = ref()

function onEdit(row: Data<Document>, fromQuery = false) {
  if (fromQuery) {
    onView(row)
    return
  }
  rowToEdit.value = row
  showEditDialog.value = true
}

function onView(row: Data<Document>) {
  rowToEdit.value = row
  showViewDialog.value = true
}

async function onDialogClose() {
  showAddDialog.value = false
  showEditDialog.value = false
}

async function onSave() {
  await onDialogClose()
  await table.value?.refresh()
}

const uploadAction = computed(() => {
  let entity = `${resource.value}s`
  if (resource.value === RestifyResources.Equipment) {
    entity = 'equipment'
  }
  if (resource.value === 'company') {
    entity = 'companies'
  }
  return `/restify/${entity}/${resourceId.value}/actions?action=upload-document`
})
</script>
