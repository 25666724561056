import i18n from '@/i18n'
import store from '@/store'
import { calculateAmountAs } from '@/enum/payroll'
import { formatPrice } from '@/plugins/formatPrice'
import { formatPercent } from '@/plugins/formatPercent'
import { cellClasses, stopEditingOnTab } from '@/components/ag-grid/columnUtils'
import { cellEditors } from '@/components/ag-grid/cellEditors/cellEditors'
import { codeTypes, ratesSourceMap, ratesSourceTypeOptions } from '@/modules/payroll/components/rates/util'

function findSourceResourceName({ source_id, source_type }) {
  const resourceName = ratesSourceMap[source_type] || ratesSourceMap.default
  const resourceList = store.getters['globalLists/getResourceList'](resourceName)
  return resourceList.find(resource => resource.id === source_id) || {}
}

function suppressKeyboardEvent(params) {
  stopEditingOnTab(params)
}


export const ratesColumns = {
  'union-rates': [
    {
      headerName: i18n.t('Craft Code'),
      field: 'craft_code',
      cellClass: cellClasses.ReadOnlyLight,
      minWidth: 50,
    },
    {
      headerName: i18n.t('Level'),
      field: 'craft_level',
      align: 'center',
      cellClass: cellClasses.ReadOnlyLight,
      minWidth: 80,
      maxWidth: 120,
    },
    {
      headerName: i18n.t('Type'),
      field: 'source_type',
      cellClass: cellClasses.ReadOnlyLight,
      valueFormatter: params => {
        const option = ratesSourceTypeOptions.find(option => option.value === params.value)
        return option?.label || params.value
      },
      minWidth: 80,
      maxWidth: 120,
    },
    {
      headerName: i18n.t('Code'),
      field: 'source_id',
      cellClass: cellClasses.ReadOnlyLight,
      valueFormatter: params => {
        const resource = findSourceResourceName(params.data)
        const { code, description } = resource
        if (!code) {
          return ''
        }
        let text = code
        if (description) {
          text = `${code} (${description})`
        }
        return text
      },
      minWidth: 150,
    },
    {
      headerName: i18n.t('Amount'),
      field: 'rate',
      align: 'right',
      cellEditor: cellEditors.Numeric,
      valueFormatter: params => {
        const { calculate_by } = params.data
        if (calculate_by === calculateAmountAs.Percentage) {
          return formatPercent(params.value, {
            maximumFractionDigits: 3,
          })
        }
        return formatPrice(params.value, {
            maximumFractionDigits: 5,
        })
      },
      editable: true,
      minWidth: 50,
      maxWidth: 150,
      suppressKeyboardEvent,
    },
  ],
  'workers-comp-rates': [
    {
      headerName: i18n.t('Code'),
      field: 'code',
      cellClass: cellClasses.ReadOnlyLight,
      minWidth: 50,
      maxWidth: 80,
    },
    {
      headerName: i18n.t('State'),
      field: 'state',
      cellClass: cellClasses.ReadOnlyLight,
      minWidth: 50,
      maxWidth: 80,
    },
    {
      headerName: i18n.t('Description'),
      field: 'description',
      cellClass: cellClasses.ReadOnlyLight,
      minWidth: 150,
    },
    {
      headerName: i18n.t('Employer Amount'),
      field: 'employer_rate',
      valueFormatter: params => {
        const { calculate_by } = params.data
        if (calculate_by === calculateAmountAs.Percentage) {
          return formatPercent(params.value, {
            maximumFractionDigits: 3,
          })
        }
        return formatPrice(params.value, {
            maximumFractionDigits: 5,
        })
      },
      align: 'right',
      editable: true,
      minWidth: 160,
      maxWidth: 200,
    },
    {
      headerName: i18n.t('Employee Deduction Amount'),
      field: 'employee_deduction_rate',
      valueFormatter: params => {
        const { calculate_by } = params.data
        if (calculate_by === calculateAmountAs.Percentage) {
          return formatPercent(params.value, {
            maximumFractionDigits: 3,
          })
        }
        return formatPrice(params.value, {
            maximumFractionDigits: 5,
        })
      },
      editable: true,
      align: 'right',
      minWidth: 160,
      maxWidth: 200,
      suppressKeyboardEvent,
    },
  ],
  'gen-liability-rates': [
    {
      headerName: i18n.t('Code'),
      field: 'code',
      cellClass: cellClasses.ReadOnlyLight,
      minWidth: 50,
      maxWidth: 80,
    },
    {
      headerName: i18n.t('State'),
      field: 'state',
      cellClass: cellClasses.ReadOnlyLight,
      minWidth: 50,
      maxWidth: 80,
    },
    {
      headerName: i18n.t('Description'),
      field: 'description',
      cellClass: cellClasses.ReadOnlyLight,
      minWidth: 150,
    },
    {
      headerName: i18n.t('Employer Amount'),
      field: 'amount',
      valueFormatter: params => {
        const { calculate_by } = params.data
        if (calculate_by === calculateAmountAs.Percentage) {
          return formatPercent(params.value, {
              maximumFractionDigits: 3,
          })
        }
        return formatPrice(params.value, {
            maximumFractionDigits: 5,
        })
      },
      align: 'right',
      editable: true,
      minWidth: 160,
      maxWidth: 200,
      suppressKeyboardEvent,
    },
  ],
}
