<template>
  <div>
    <portal to="lmp-summary">
      <div class="flex items-center">
        <div class="summary">
          {{ $t('Gross:') }}
          <span>
            {{ $formatPrice(getTotals.distribution) }}
          </span>
        </div>
        <div class="summary">
          {{ $t('Sales Tax:') }}
          <span>
            {{ $formatPrice(getTotals.taxes) }}
          </span>
        </div>
        <div class="summary">
          {{ $t('Retention:') }}
          <span>
            {{ $formatPrice(getTotals.retention_amount) }}
          </span>
        </div>
        <div class="summary">
          {{ $t('Net:') }}
          <span>
            {{ $formatPrice(getTotals.net_amount) }}
          </span>
        </div>
      </div>
    </portal>
    <div>
      <base-alert
          v-if="balance !== 0"
          :type="$promptType.Warning"
          closable
      >
        <span class="mr-2">{{ $t('Gross doesn’t match the gross billing amount.') }} </span>
        <span class="mr-2">{{$t('Gross:')}} <span class="font-medium">{{ $formatPrice(getTotals.distribution) }}</span></span>
        <span class="mr-2">{{$t('Gross Billing:')}} <span class="font-medium">{{ $formatPrice(billing.gross_amount) }}</span></span>
        <span class="mr-2">{{$t('Difference: ')}} <span class="font-semibold">{{ $formatPrice(balance) }}</span></span>
      </base-alert>
    </div>
    <AgDataTable
        v-bind="editableTableProps"
        :add-text="$t('New Entry')"
        :url="url"
        :url-params="urlParams"
        :columns="columns"
        :transform-data="mapData"
        :get-empty-row="getEmptyEntry"
        :groupIncludeFooter="true"
        :groupIncludeTotalFooter="true"
        :showCellsLegend="true"
        ref="gridTable"
        actions="add"
        hide-actions="filters"
        suppressColumnReordering
        @cell-focused="onCellFocused"
        @cell-value-changed="onCellValueChanged"
        @grid-ready="onGridReady"
        @data-updated="entries = $event"
        domLayout="autoHeight"
    >
    </AgDataTable>
  </div>
</template>
<script>
  import pick from "lodash/pick";
  import sumBy from "lodash/sumBy";
  import { costCenterDefaultFields, costCenterTypes, setTypeSources, } from "@/components/grid-table/utils/cost-center";
  import { getResourceLabelPlural, globalResourceLabels, globalResources } from "@/components/form/util";
  import { getNumberValue } from "@/utils/utils";
  import {
    accountCol,
    additionalSourceCol,
    costCenterCol,
    descriptionCol,
    quantityCol,
    sourceCol,
    specialSourceCol,
    specialSourceTypeCol,
    typeCol,
    updateCostCenterHeaderNames
  } from "@/components/ag-grid/columns/costCenterColumns";
  import i18n from "@/i18n";
  import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";
  import {
    cellClasses,
    getResourceLabel,
    percentageValidator,
    requiredValueSetter,
    stopEditingOnTab,
  } from "@/components/ag-grid/columnUtils";
  import { getDefaultAccounts } from "@/modules/common/util/costCenterUtils";
  import { editableTableProps, getTableData } from "@/components/ag-grid/tableUtils";
  import { laborHourTypes } from "@/enum/enums";
  import {
    getSpecialResource, getSpecialSourceRateDetails, getTaxDistrict,
    getTaxDistrictName,
    isTypeColEditable,
    sourceMap, specialSourceMap, specialSourceTypeOptions,
  } from "@/modules/accounts-receivable/components/lump-sum-billings/lumpSumBillingUtils";
  import axios from "axios";
  import WarningTip from "@/modules/payroll/components/WarningTip.vue";
  import { getDeleteColumn } from "@/components/ag-grid/columns/deleteColumns";
  import bus from "@/event-bus/EventBus";
  import LumpSumBillingOverrideSummary
    from "@/modules/accounts-receivable/components/lump-sum-billings/LumpSumBillingOverrideSummary.vue";
  import {
    computeBillingEntryRetention,
    computeBillingEntrySalesTax
  } from "@/modules/accounts-receivable/utils/billingUtils";
  import { getLaborHourAbbr, laborHourTypeOptions } from "@/modules/service-billing/util/service-billing";

  const ResourceEndpoint = '/restify/billing-entries'

  const metaObj = {
    labor_type: 0,
    district_id: null,
    percentage: 0,
  }

  const jobCostingFields = [
    'cost_center',
    'source_id',
    'type_id',
    'addl_source_id',
  ]

  export default {
    name: 'LumpSumBillingEntriesV2',
    components: {
      LumpSumBillingOverrideSummary,
      WarningTip,
    },
    props: {
      computedGrossBilling: Boolean,
      billing: {
        type: Object,
        default: () => ({}),
      },
      customer: {
        type: Object,
        default: () => ({
          sales_tax_percent: 0,
          district_id: null,
          billing_rate_type_id: null,
        }),
      },
    },
    data() {
      return {
        entries: [],
        grid: null,
        editableTableProps,
      }
    },
    computed: {
      url() {
        if (!this.billing.id) {
          return ''
        }
        return ResourceEndpoint
      },
      urlParams() {
        if (!this.billing.id) {
          return {}
        }
        return {
          billing_id: this.billing.id,
          perPage: 500,
          sort: 'order',
        }
      },
      getTotals() {
        const distribution = sumBy(this.entries, entry => getNumberValue(entry.gross_amount))
        const retention_amount = sumBy(this.entries, entry => getNumberValue(entry.retention_amount))
        const taxes = sumBy(this.entries, entry => getNumberValue(entry.sales_tax_amount))

        return {
          taxes,
          retention_amount,
          distribution,
          net_amount: distribution - retention_amount + taxes,
        }
      },
      balance() {
        return +this.billing.gross_amount - +this.getTotals.distribution
      },
      columns() {
        return [
          {
            ...costCenterCol,
            cellEditorParams: {
              options: [
                {
                  label: i18n.t('JOB'),
                  value: costCenterTypes.Job,
                },
                {
                  label: i18n.t('G&A'),
                  value: costCenterTypes.GeneralAndAdministrative,
                },
                {
                  label: i18n.t('EQP'),
                  value: costCenterTypes.Equipment,
                },
                {
                  label: i18n.t('INV'),
                  value: costCenterTypes.Inventory,
                },
                {
                  label: i18n.t('LAB'),
                  value: costCenterTypes.Labor,
                },
              ],
            },
            valueSetter: params => {
              const value = params.newValue
              if (params.newValue === params.oldValue) {
                return true
              }
              params.data.cost_center = params.newValue
              params.data.source_id = null
              params.data.account = null
              params.data.subaccount = null
              params.data.source_type = null
              params.data.type_id = null
              params.data.type_type = null
              params.data.addl_source_id = null
              params.data.addl_source_type = null

              if (value === costCenterTypes.Labor) {
                params.data.labor_type = laborHourTypes.Regular
                params.data.um = this.$t('Hours')
              }

              if ([costCenterTypes.Job].includes(value)) {
                params.data.type_id = this.lumpSumBillingTypeId
              }
              params.node.setData(params.data)
              return true
            }
          },
          {
            ...sourceCol,
            cellEditorParams: params => {
              const { cost_center } = params.data
              const resourceName = sourceMap[cost_center]
              const resourceLabel = getResourceLabelPlural(resourceName)
              let noMatchText = ''
              if (this.billing.customer_id) {
                noMatchText = this.$t(`No matching ${resourceLabel} for this customer`)
              }
              return {
                resourceName,
                noMatchText,
                filterMethod: (option) => {
                  if (cost_center === costCenterTypes.Labor && option?.code === '*') {
                    return false
                  }
                  if (this.billing.customer_id) {
                    return !option.customer_id || option.customer_id === this.billing.customer_id
                  }
                  return true
                },
              }
            },
            valueSetter: params => {
              params.data.source_id = params.newValue
              params.data.addl_source_id = null
              params.data.retention_percent = 0
              if (params.data.cost_center !== costCenterTypes.GeneralAndAdministrative) {
                this.onChangeSpecialCode(params)
              }
              const isJob = params.data.cost_center === costCenterTypes.Job
              if (!isJob) {
                return true
              }
              const job = this.$store.getters['globalLists/getResourceById'](this.$globalResources.Jobs, params.data.source_id)
              if (job.district_id) {
                params.node.setDataValue('district_id', job.district_id)
              }
              if (job.billing_retention_percent) {
                params.node.setDataValue('retention_percent', job.billing_retention_percent)
              }
              return true
            },
            editable: this.isSourceEditable,
            suppressNavigable: params => {
              return !this.isSourceEditable(params)
            },
            cellClass: params => {
              if (params.node.footer) {
                return ''
              }
              const hasValue = params.data?.source_id
              if (!this.isSourceEditable(params)) {
                return 'bg-gray-100 cursor-not-allowed'
              }
              if (!hasValue) {
                return cellClasses.Invalid
              }
              return ''
            }
          },
          {
            ...typeCol,
            editable: this.isTypeColEditable,
            cellClass: params => {
              if (params.node.footer) {
                return ''
              }
              let hasValue = params.data?.type_id
              if (this.isLabor(params)) {
                hasValue = params.data?.labor_type
              }
              if (!this.isTypeColEditable(params)) {
                return 'bg-gray-100 cursor-not-allowed'
              }
              if (!hasValue) {
                return cellClasses.Invalid
              }
              return ''
            },
            suppressNavigable: params => {
              return !this.isTypeColEditable(params)
            },
            component: 'TypeLink',
            valueGetter: params => {
              if (this.isLabor(params)) {
                return getLaborHourAbbr(params.data?.labor_type)
              }
              return params.data?.type_id
            },
            cellEditorSelector: params => {
              if (this.isLabor(params)) {
                return {
                  component: cellEditors.BaseSelect
                }
              }
              return {
                component: cellEditors.GlobalResourceSelect
              }
            },
            cellEditorParams: params => {
              const { cost_center } = params.data
              const resourceMapping = {
                [costCenterTypes.Job]: globalResources.JobTypes,
              }
              let options = []
              if (this.isLabor(params)) {
                options = laborHourTypeOptions
              }
              const resourceName = resourceMapping[cost_center]

              return {
                resourceName,
                options,
              }
            },
            valueSetter: params => {
              params.data.addl_source_id = null

              if (this.isLabor(params)) {
                params.data.labor_type = params.newValue
              } else {
                params.data.type_id = params.newValue
              }
              return true
            },
          },
          {
            ...additionalSourceCol(),
            minWidth: 100,
            maxWidth: 200,
            editable: isTypeColEditable,
            cellRendererParams: {
              showDescription: false,
            },
            cellClass: params => {
              if (params.node.footer) {
                return ''
              }
              const hasValue = params.data?.addl_source_id
              if (!isTypeColEditable(params)) {
                return 'bg-gray-100 cursor-not-allowed'
              }
              if (!hasValue) {
                return cellClasses.Invalid
              }
              return ''
            },
            suppressNavigable: params => {
              return !isTypeColEditable(params)
            },
            valueSetter: params => {
              params.data.addl_source_id = params.newValue
              this.onChangeAdditionalSource(params)
              return true
            }
          },
          {
            ...accountCol(),
            minWidth: 80,
            maxWidth: 150,
            cellRendererParams: {
              showDescription: false,
            },
            cellClass: params => {
              if (params.node.footer) {
                return ''
              }
              const hasValue = params.data?.account
              return hasValue ? '' : cellClasses.Invalid
            },
          },
          {
            field: 'subaccount',
            headerName: this.$t('Sub'),
            minWidth: 60,
            maxWidth: 80,
            editable: true,
            cellEditor: cellEditors.SubAccountSelect,
            cellEditorParams: {
              valueKey: 'number',
            },
            suppressNavigable: () => true
          },
          {
            ...specialSourceTypeCol,
            cellEditorParams: {
              options: specialSourceTypeOptions,
            },
            editable: isTypeColEditable,
            cellClass: params => {
              if (params.node.footer) {
                return ''
              }
              const hasValue = params.data?.type_id
              if (!isTypeColEditable(params)) {
                return 'bg-gray-100 cursor-not-allowed'
              }
              if (!hasValue) {
                return cellClasses.Invalid
              }
              return ''
            },
            suppressNavigable: params => {
              return !isTypeColEditable(params)
            },
            valueFormatter: params => {
              if (!params.data) {
                return ''
              }
              return specialSourceTypeOptions.find(o => o.value === params?.data?.special_source_type)?.label
            },
            minWidth: 80,
            maxWidth: 150,
          },
          {
            ...specialSourceCol,
            minWidth: 150,
            maxWidth: 250,
            cellEditor: cellEditors.GlobalResourceSelect,
            component: null,
            valueFormatter: params => {
              if (!params.data) {
                return ''
              }
              const { special_source_type } = params.data
              const resourceName = specialSourceMap[special_source_type]
              return getResourceLabel(params?.data?.special_source_id, resourceName)
            },
            cellEditorParams: params => {
              const { special_source_type } = params.data
              const resourceName = specialSourceMap[special_source_type]

              return {
                resourceName,
              }
            },
            valueSetter: params => {
              params.data.special_source_id = params.newValue
              this.onChangeSpecialCode(params)
              return true
            }
          },
          {
            ...descriptionCol,
          },
          {
            headerName: this.$t('Unit'),
            field: 'um',
            minWidth: 60,
            maxWidth: 120,
            editable: true,
            sortable: false,
          },
          {
            ...quantityCol,
            valueSetter: params => {
              const isValid = requiredValueSetter(params, 0)
              if (!isValid) {
                return false
              }
              this.calculateGrossAmount(params)
              this.calculateSalesTax(params)
              this.calculateRetention(params)
              return true
            },
          },
          {
            headerName: this.$t('Unit Price'),
            field: 'unit_rate',
            minWidth: 90,
            maxWidth: 150,
            component: 'FormattedPrice',
            editable: true,
            cellEditor: cellEditors.Numeric,
            valueSetter: params => {
              const isValid = requiredValueSetter(params, 0)
              if (!isValid) {
                return false
              }
              const value = +params.newValue
              if (value > 0) {
                this.calculateGrossAmount(params)
                this.calculateSalesTax(params)
                this.calculateRetention(params)
              }
              return true
            },
          },
          {
            headerName: this.$t('Amount'),
            field: 'gross_amount',
            minWidth: 100,
            maxWidth: 150,
            component: 'FormattedPrice',
            editable: true,
            sortable: false,
            cellEditor: cellEditors.Numeric,
            valueGetter: params => +params.data.gross_amount,
            valueSetter: params => {
              const isValid = requiredValueSetter(params, 0)
              if (!isValid) {
                return false
              }
              this.calculateUnitPrice(params)
              this.calculateSalesTax(params)
              this.calculateRetention(params)
              return true
            },
            aggFunc: 'sum'
          },
          {
            headerName: this.$t('Tax District'),
            field: 'district_id',
            minWidth: 80,
            maxWidth: 100,
            editable: true,
            cellEditor: cellEditors.GlobalResourceSelect,
            cellEditorParams: {
              resourceName: globalResources.SalesTaxDistricts,
            },
            valueFormatter: params => {
              if (!params.data) {
                return ''
              }
              return getTaxDistrictName(params.data?.district_id)
            },
            valueSetter: params => {
              params.data.district_id = params.newValue
              const district = getTaxDistrict(params.newValue)
              if (!district) {
                params.data.sales_tax_percent = 0
                return true
              }
              if (district.sales_tax_percent === null || district.sales_tax_percent === undefined) {
                return true
              }
              params.data.sales_tax_percent = district.sales_tax_percent
              this.calculateSalesTax(params)
              this.calculateRetention(params)
              return true
            },
          },
          {
            headerName: this.$t('Tax %'),
            field: 'sales_tax_percent',
            minWidth: 70,
            maxWidth: 150,
            component: 'FormattedPercent',
            editable: true,
            cellEditor: cellEditors.Numeric,
            valueSetter: params => {
              const isValid = requiredValueSetter(params, 0, percentageValidator)
              if (!isValid) {
                return false
              }
              this.calculateSalesTax(params)
              this.calculateRetention(params)
              return true
            },
          },
          {
            headerName: this.$t('Tax Amount'),
            field: 'sales_tax_amount',
            minWidth: 90,
            maxWidth: 150,
            component: 'FormattedPrice',
            editable: true,
            cellEditor: cellEditors.Numeric,
            tooltipValueGetter(params) {
              if (params.data?.exempt_from_sales_tax) {
                return 'This billing entry is exempt from sales tax.'
              }
            },
            cellClass: params => {
              if (params.data?.exempt_from_sales_tax && !params.value) {
                return 'warning-ag-cell'
              }
            },
            valueGetter: params => +params.data.sales_tax_amount,
            valueSetter: params => {
              const isValid = requiredValueSetter(params, 0)
              if (!isValid) {
                return false
              }
              this.calculateSalesTaxPercentage(params)
              return true
            },
            suppressKeyboardEvent: stopEditingOnTab,
            aggFunc: 'sum',
          },
          {
            headerName: this.$t('Ret %'),
            field: 'retention_percent',
            minWidth: 80,
            maxWidth: 150,
            component: 'FormattedPercent',
            editable: true,
            cellEditor: cellEditors.Numeric,
            valueSetter: params => {
              const isValid = requiredValueSetter(params, 0, percentageValidator)
              if (!isValid) {
                return false
              }
              params.data.retention_amount = params.data.retention_percent * params.data.gross_amount / 100
              return true
            },
          },
          {
            headerName: this.$t('Retention'),
            field: 'retention_amount',
            minWidth: 90,
            maxWidth: 120,
            component: 'FormattedPrice',
            editable: true,
            cellEditor: cellEditors.Numeric,
            valueSetter: params => {
              const isValid = requiredValueSetter(params, 0)
              if (!isValid) {
                return false
              }
              if (!params.data.gross_amount) {
                params.data.retention_percent = 0
                return true
              }
              params.data.retention_percent = params.data.retention_amount / params.data.gross_amount * 100
              return true
            },
          },
          {
            headerName: this.$t('Total'),
            field: 'extended_amount',
            align: 'right',
            pinned: 'right',
            minWidth: 90,
            maxWidth: 120,
            valueGetter: params => {
              return this.getRowExtendedTotal(params)
            },
            valueFormatter: params => {
              if (params.node.footer) {
                return this.$formatPrice(params.node.aggData?.extended_amount)
              }
              const total = this.getRowExtendedTotal(params)
              return this.$formatPrice(total)
            },
            aggFunc: 'sum',
          },
          {
            field: 'labor_type',
            hide: true,
          },
          {
            field: 'retention_percent',
            hide: true,
          },
          {
            field: 'retention_amount',
            hide: true,
          },
          {
            field: 'subaccount',
            hide: true,
          },
          {
            field: 'special_source_type',
            hide: true,
          },
          {
            ...getDeleteColumn({
              url: ResourceEndpoint,
              pinned: 'right',
            })
          },
        ]
      },
      lumpSumBillingTypeId() {
        return this.$store.getters['globalLists/getJobLumpSumBillingTypeId']
      },
      customerCostCenterDefaults() {
        let defaults = pick(this.customer, costCenterDefaultFields)
        if (!defaults.cost_center) {
          defaults.cost_center = costCenterTypes.Job
          defaults.type_id = this.lumpSumBillingTypeId
        }

        return defaults
      },
    },
    methods: {
      isLabor(params) {
        return params.data?.cost_center === costCenterTypes.Labor
      },
      isTypeColEditable(params) {
        return [costCenterTypes.Job, costCenterTypes.Labor].includes(params.data?.cost_center)
      },
      isSourceEditable(params) {
        const invalidCostCenters = [costCenterTypes.GeneralAndAdministrative]
        return !invalidCostCenters.includes(params.data?.cost_center)
      },
      getTypeAbbr(typeId) {
        return this.$store.getters['globalLists/getResourceById'](this.$globalResources.JobTypes, typeId)?.abbr
      },
      calculateSalesTax(params) {
        const salesTax = computeBillingEntrySalesTax(params.data, this.billing)
        params.data.sales_tax_amount = salesTax.amount
        params.data.exempt_from_sales_tax = salesTax.exempt_from_sales_tax
      },
      calculateRetention(params) {
        params.data.retention_amount = computeBillingEntryRetention(params.data, this.billing)
      },
      calculateUnitPrice(params) {
        if (!params.data.quantity) {
          return
        }
        params.data.unit_rate = this.round(params.data.gross_amount / params.data.quantity, 2)
      },
      calculateGrossAmount(params) {
        params.data.gross_amount = this.round(params.data.unit_rate * params.data.quantity, 2)
        this.onChangeAmount()
      },
      calculateSalesTaxPercentage(params) {
        if (!params.data.gross_amount) {
          params.data.sales_tax_percent = 0
          return
        }
        params.data.sales_tax_percent = this.round(params.data.sales_tax_amount / params.data.gross_amount * 100, 3)
      },
      onCellFocused(params) {
        updateCostCenterHeaderNames(params)
      },
      closeOtherSummaries() {
        bus.$emit('close-lump-sum-entry-summary')
      },
      getRowExtendedTotal(params) {
        const { gross_amount, sales_tax_amount, retention_amount } = params.data || {}
        return +gross_amount + +sales_tax_amount - +retention_amount
      },
      mapData(data) {
        return data.map(entry => {
          const labor_type = entry.attributes?.meta?.labor_type || entry.attributes?.meta?.hourly_rate
          return {
            ...entry.attributes,
            ...metaObj,
            ...entry.attributes?.meta,
            labor_type,
          }
        })
      },
      tryCollapseFormHeader() {
        this.$emit('on-collapse-form-header')
      },
      onGridReady(grid) {
        this.grid = grid
      },
      getEmptyEntry() {
        this.tryCollapseFormHeader()
        const costCenterDefaults = this.customerCostCenterDefaults

        return {
          ...costCenterDefaults,
          _localId: crypto.randomUUID(),
          billing_id: this.billing.id,
          gross_amount: 0,
          retention_percent: 0,
          description: '',
          discount_amount: 0,
          quantity: 0,
          um: '',
          unit_rate: 0,
          sales_tax_amount: 0,
          labor_type: null,
          district_id: this.billing.meta?.district_id || null,
          retention_amount: 0,
          sales_tax_percent: this.billing.sales_tax_percent || 0,
          order: 1,
          meta: {
            labor_type: null,
          }
        }
      },
      async onCellValueChanged(params) {
        const field = params?.colDef?.field

        if (jobCostingFields.includes(field)) {
          await this.assignAccountToEntry(params)
        }
      },
      async assignAccountToEntry(params) {
        params.data = await getDefaultAccounts(params.data)
        if (!params.data.account && params.data.cost_center !== costCenterTypes.Job) {
          params.data.account = this.customer.account
        }
        params.node.setDataValue('account', params.data.account)
        params.node.setData(params.data)
      },
      getTableData() {
        return getTableData(this.grid?.api)
      },
      onChangeAmount() {
        if (!this.computedGrossBilling) {
          return
        }

        const entries = this.getTableData()
        const totalAmount = sumBy(entries, 'gross_amount')
        this.$emit('update:gross-amount', totalAmount)
      },
      onChangeSpecialCode(params) {
        params.data.unit_rate = 0
        params.data = getSpecialSourceRateDetails(params, this.customer.billing_rate_type_id)

        params.node.setData(params.data)
      },
      onChangeAdditionalSource(params) {
        let resource = this.$store.getters['globalLists/getResourceById'](this.$globalResources.LineItems, params.data.addl_source_id)
        if (resource.hasOwnProperty('description')) {
          params.data.description = resource.description
        }
        const type = this.getTypeAbbr(params.data.type_id)
        const typeTotals =  this.get(resource, `typeTotals.${type}`, {})
        const { unit_rate, um } = typeTotals
        params.data.unit_rate = unit_rate || 0
        params.data.um = um || ''
        params.node.setData(params.data)
      },
      tryAssignRegularRate(resource) {
        let rate = resource?.billing_rates.find(rate => rate.billing_rate_type_id === this.customer.billing_rate_type_id)
        if (!this.customer.billing_rate_type_id) {
          rate = resource?.billing_rates?.[0]
        }
        return rate?.regular_rate || 0
      },
      validateAmount(grossAmount = 0) {
        const distribution = this.getTotals.distribution + grossAmount

        if (this.billing.gross_amount > 0 && distribution > this.billing.gross_amount) {
          const message = `Gross totals can't be greater that Gross billing. <br>
            Distribution: ${distribution} <br>
            Gross billing: ${this.billing.gross_amount} <br>`
          this.$error(message)

          return false
        }

        return true
      },
      async storeProgress(billing_id) {
        const validAmounts = this.validateAmount()

        if (!validAmounts) {
          const err = new Error('Invalid gross amount')
          err.handled = true
          throw err
        }

        const entries = this.getTableData()
            .map((entry, index) => {
              const dirty = entry.dirty ? entry.dirty : entry.order !== index
              return {
                ...entry,
                dirty,
                billing_id,
                order: index,
              }
            })
            .filter(entry => entry.dirty)

        const entriesToSave = entries.filter(entry => !entry.id).map(this.composeModel)
        const entriesToUpdate = entries.filter(entry => entry.id).map(this.composeModel)

        if (entriesToSave.length) {
          await axios.post(`${ResourceEndpoint}/bulk`, entriesToSave)
        }
        if (entriesToUpdate.length) {
          await axios.post(`${ResourceEndpoint}/bulk/update`, entriesToUpdate)
        }

        await this.refreshEntriesTable()
      },
      async refreshEntriesTable() {
        if (!this.billing.id) {
          return
        }
        await this.$refs.gridTable.refresh()
      },
      composeModel(entry) {
        const metaFields = Object.keys(metaObj)
        entry.meta = pick(entry, metaFields)

        entry = setTypeSources(entry)

        return entry
      }
    },
  }
</script>
