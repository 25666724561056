<template>
  <base-data-table
      v-bind="defaultAttributes"
      :columns="columns"
      :data="getData"
      :total-rows="rowsLength"
      :has-summary="false"
      :compact="true"
      class="no-borders payroll-history"
      @force-pagination="forcePagination"
  >

    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template #header="{row}">
      <div class="flex items-inline-base">
        <EmployeeLink
            :data="row.header"
            :show-preview="false"
            class="min-w-[400px]"
        />
        <span>{{ $t('SocSec #:') }}</span>
        <span class="mx-2">{{ row.header?.masked_ssn }}</span>
      </div>
    </template>

    <template #average="{row}">
      <dl class="w-full">
        <dt
            v-for="payCode in row.pay_codes?.items"
            class="flex justify-between"
        >
          <span>{{ payCode.code }}:</span>
          {{ $formatPrice(payCode.rate) }}
        </dt>
      </dl>
    </template>

    <template #hours="{row}">
      <div>
        <p v-for="payCode in row.pay_codes?.items">
          {{ payCode.hours?.toFixed(2) }}
        </p>
      </div>
    </template>

    <template #hourly_pay="{row}">
      <div>
        <p v-for="payCode in row.pay_codes?.items">
          {{ $formatPrice(payCode.amount) }}
        </p>
      </div>
    </template>

    <template #other_pay="{row}">
      <dl class="w-full">
        <dt
            v-for="pay in row.other_pay?.items"
            class="flex justify-between"
        >
          <span>{{ pay.code }}:</span>
          {{ $formatPrice(pay.amount) }}
        </dt>
      </dl>
    </template>

    <template #federal_taxes="{row}">
      <dl class="w-full">
        <dt
            v-for="tax in row.taxes?.federal?.items"
            class="flex justify-between"
        >
          <span>{{ tax.code }}:</span>
          {{ $formatPrice(tax.amount) }}
        </dt>
      </dl>
    </template>

    <template #state_tax="{row}">
      <dl class="w-full">
        <dt
            v-for="stateTax in row.taxes?.state?.items"
            class="flex justify-between"
        >
          <span>{{ stateTax.code }}:</span>
          {{ $formatPrice(stateTax.amount) }}
        </dt>
      </dl>
    </template>

    <template #other_deductions="{row}">
      <dl class="w-full">
        <dt
            v-for="deduction in row.deductions?.items"
            class="flex justify-between"
        >
          <span>{{ deduction.code }}:</span>
          {{ $formatPrice(deduction.amount) }}
        </dt>
      </dl>
    </template>

    <template #benefits="{row}">
      <dl class="w-full">
        <dt
            v-for="benefit in row.benefits?.items"
            class="flex justify-between"
        >
          <span>{{ benefit.code }}:</span>
          {{ $formatPrice(benefit.amount) }}
        </dt>
      </dl>
    </template>

    <template #subtotal="{subtotal}">
      <td/>
      <td align="right" class="top-border">
        {{ subtotal?.pay_codes?.totals?.hours?.toFixed(2) }}
      </td>
      <td align="right" class="top-border">
        {{ $formatPrice(subtotal?.pay_codes?.totals?.amount) }}
      </td>
      <td align="right" class="top-border">
        {{ $formatPrice(subtotal?.other_pay?.total) }}
      </td>
      <td align="right" class="top-border">
        {{ $formatPrice(subtotal?.taxes?.federal?.total) }}
      </td>
      <td align="right" class="top-border">
        {{ $formatPrice(subtotal?.taxes?.state?.total + subtotal?.taxes?.local?.total) }}
      </td>
      <td align="right" class="top-border">
        {{ $formatPrice(subtotal?.deductions?.total) }}
      </td>
      <td align="right" class="top-border">
        {{ $formatPrice(subtotal?.benefits?.total) }}
      </td>
      <td align="right" class="top-border">
        {{ $formatPrice(subtotal?.total) }}
      </td>
    </template>

    <template #html-row="{htmlData}">
      <td colspan="9" class="content-row font-semibold py-4 px-2">
        {{htmlData}}
      </td>
    </template>

  </base-data-table>
</template>
<script>
  import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'

  export default {
    extends: ReportTableWrapper,
    computed: {
      columns() {
        return [
          {
            label: this.$t('Average <br> Rate'),
            prop: 'average',
            align: 'center',
            minWidth: 80,
            maxWidth: 110,
          },
          {
            label: this.$t('Hours'),
            prop: 'hours',
            align: 'right',
            minWidth: 40,
            maxWidth: 60,
          },
          {
            label: this.$t('Hourly <br> Pay'),
            prop: 'hourly_pay',
            align: 'right',
            minWidth: 80,
            maxWidth: 110,
          },
          {
            label: this.$t('Other <br> Pay'),
            prop: 'other_pay',
            align: 'center',
            minWidth: 80,
            maxWidth: 110,
          },
          {
            label: this.$t('Tax <br> Federal'),
            prop: 'federal_taxes',
            align: 'center',
            minWidth: 80,
            maxWidth: 110,
          },
          {
            label: this.$t('Tax <br> State/Local'),
            prop: 'state_tax',
            align: 'center',
            minWidth: 80,
            maxWidth: 110,
          },
          {
            label: this.$t('Other <br> Deductions'),
            prop: 'other_deductions',
            align: 'center',
            minWidth: 80,
            maxWidth: 110,
          },
          {
            label: this.$t('Benefits'),
            prop: 'benefits',
            align: 'center',
            minWidth: 80,
            maxWidth: 110,
          },
          {
            label: this.$t('Net Pay'),
            prop: 'net_pay',
            align: 'right',
            hiddenValue: true,
            minWidth: 80,
            maxWidth: 110,
          },
        ]
      },
    },
    methods: {
      composeRows(data) {

        data.forEach(group => {

          this.rows.push(
              {
                header: group.employee,
              },
              group,
              {
                subtotal: group,
              },
          )
        })

        this.rows.push(
            {
              htmlData: this.$t('Grand Total All Selected Employees'),
            },
            { ...this.data.grand_totals },
            {
              subtotal: this.data.grand_totals,
            },
        )

        return this.rows
      },
    }
  }
</script>
<style>
  .payroll-history td {
    vertical-align: baseline;
  }
</style>
