<template>
  <base-data-table
    v-bind="defaultAttributes"
    :columns="columns"
    :data="getData"
    :total-rows="rowsLength"
    :filters="filters"
    @force-pagination="forcePagination"
  >
    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>
    <template #header="{row}">
      <component
        :is="distributionLinkComponent"
        :data="row.header"
        :show-preview="false"
      />
    </template>
    <template #account="{row}">
      <AccountLink
        :number="row.account"
        :show-description="false"
      />
    </template>
    <template #employee="{row}">
      <EmployeeLink
        :data="row.employee"
        :show-description="false"
        :show-preview="false"
      />
    </template>
    <template #subtotal="{subtotal}">
      <td colspan="8">
        <span class="capitalize">{{ $t(distributionLabel) }}</span>
        {{ $t('Totals:') }}
      </td>
      <td align="right">{{ subtotal.regular_hours }}</td>
      <td align="right">{{ subtotal.overtime_hours }}</td>
      <td align="right">{{ subtotal.premium_hours }}</td>
      <td align="right">
        {{ $formatPrice(subtotal.total_hourly_pay_amount) }}
      </td>
      <td align="right">
        {{ $formatPrice(subtotal.other_pay_amount) }}
      </td>
      <td align="right">
        {{ $formatPrice(subtotal.benefit_pay_amount) }}
      </td>
      <td align="right">
        {{ $formatPrice(subtotal.total_pay_amount) }}
      </td>
    </template>
  </base-data-table>
</template>
<script lang="ts">
import pluralize from 'pluralize';
import { defineComponent, PropType } from 'vue'
import { formattedText } from '@/utils/utils'
import { Column } from '@/components/ag-grid/tableTypes'
import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'
import { historyDistributionHistory } from '@/modules/payroll/components/reports/util'

export default defineComponent({
  extends: ReportTableWrapper,
  props: {
    filters: {
      type: Object as PropType<Record<string, any>>,
      default: () => ({}),
    },
  },
  computed: {
    distribution() {
      return this.filters?.distributions || historyDistributionHistory.Job
    },
    distributionLabel() {
      const label = formattedText(this.distribution)
      return pluralize.singular(label)
    },
    distributionLinkComponent() {
      const links =  {
        [historyDistributionHistory.Job]: 'JobLink',
        [historyDistributionHistory.Equipment]: 'EquipmentLink',
        [historyDistributionHistory.WorkOrders]: 'WorkOrderLink',
        default: 'JobLink',
      }
      return links[this.distribution] || links.default
    },
    columns(): Column[] {
      return [
        {
          label: this.distributionLabel,
          prop: 'distribution',
          hiddenValue: true,
          minWidth: 100,
          maxWidth: 100,
        },
        {
          label: this.$t('Account'),
          prop: 'account',
          minWidth: 60,
          maxWidth: 80,
        },
        {
          label: this.$t('Phase <br> Code'),
          prop: 'line_item.phase_code',
          minWidth: 60,
          maxWidth: 60,
          component: 'EntityLink',
          redirectTo: '/job-costing/income-line-items/{ID}/view',
        },
        {
          label: this.$t('Cost <br> Code'),
          prop: 'line_item.cost_code',
          minWidth: 60,
          maxWidth: 60,
          component: 'EntityLink',
          redirectTo: '/job-costing/income-line-items/{ID}/view',
        },
        {
          label: this.$t('Change <br> Order'),
          prop: 'line_item.change_order',
          minWidth: 60,
          maxWidth: 60,
          component: 'EntityLink',
          redirectTo: '/job-costing/income-line-items/{ID}/view',
        },
        {
          label: this.$t('Period <br> Ending'),
          prop: 'period_end_date',
          component: 'FormattedDate',
          minWidth: 100,
          maxWidth: 120,
        },
        {
          label: this.$t('Employee'),
          prop: 'employee',
          component: 'EmployeeLink',
          minWidth: 100,
          maxWidth: 100,
        },
        {
          label: this.$t('Avg Reg <br> Rate'),
          prop: 'totals.avg_rate',
          component: 'FormattedPrice',
          hideZero: true,
          minWidth: 100,
          maxWidth: 120,
        },
        {
          label: this.$t('Regular <br> Hours'),
          prop: 'totals.regular_hours',
          align: 'right',
          hideZero: true,
          minWidth: 80,
          maxWidth: 100,
        },
        {
          label: this.$t('Overtime <br> Hours'),
          prop: 'totals.overtime_hours',
          align: 'right',
          hideZero: true,
          minWidth: 80,
          maxWidth: 100,
        },
        {
          label: this.$t('Premium <br> Hours'),
          prop: 'totals.premium_hours',
          align: 'right',
          hideZero: true,
          minWidth: 80,
          maxWidth: 100,
        },
        {
          label: this.$t('Total <br> Hours Pay'),
          prop: 'totals.total_hourly_pay_amount',
          align: 'right',
          component: 'FormattedPrice',
          hideZero: true,
          minWidth: 100,
          maxWidth: 120,
        },
        {
          label: this.$t('Other <br> Pay'),
          prop: 'totals.other_pay_amount',
          align: 'right',
          component: 'FormattedPrice',
          hideZero: true,
          minWidth: 100,
          maxWidth: 120,
        },
        {
          label: this.$t('Benefit <br> Pay'),
          prop: 'totals.benefit_pay_amount',
          align: 'right',
          component: 'FormattedPrice',
          hideZero: true,
          minWidth: 100,
          maxWidth: 120,
        },
        {
          label: this.$t('Total <br> Pay'),
          prop: 'totals.total_pay_amount',
          align: 'right',
          component: 'FormattedPrice',
          hideZero: true,
          minWidth: 100,
          maxWidth: 120,
        }
      ]
    },
  },
  methods: {
    composeRows(data: Record<string, any>) {
      if (this.distribution === historyDistributionHistory.Equipment) {
        return this.composeRowsForEquipment(data)
      }

      if (this.distribution === historyDistributionHistory.WorkOrders) {
        return this.composeRowsForWorkOrders(data)
      }

      return this.composeRowsForJobs(data)
    },
    composeRowsForEquipment(data: Record<string, any>) {
      data?.equipment.forEach((group: Record<string, any>) => {
        const { equipment, entries, totals } = group
        this.rows.push({
          header: equipment,
        })
        this.rows.push(...entries)
        this.rows.push({
          subtotal: totals,
        })
      })
      return this.rows
    },
    composeRowsForWorkOrders(data: Record<string, any>) {
      data?.work_orders.forEach((group: Record<string, any>) => {
        const { entries, totals } = group
        this.rows.push({
          header: group['work-order']
        })
        this.rows.push(...entries)
        this.rows.push({
          subtotal: totals,
        })
      })
      return this.rows
    },
    composeRowsForJobs(data: Record<string, any>) {
      data?.jobs.forEach((group: Record<string, any>) => {
        const { job, entries, totals } = group
        this.rows.push({
          header: job,
        })
        this.rows.push(...entries)
        this.rows.push({
          subtotal: totals,
        })
      })
      return this.rows
    }
  },
})
</script>
