<template>
  <tr class="hover:bg-gray-50 focus:bg-gray-50 focus:ring-0 transition-colors duration-75 text-base">
    <td
        v-if="isExpandable"
        class="pl-4 py-2 border-b print:hidden"
    >
      <div :class="{
            'h-4': noBorders || compact,
            'h-6': !noBorders && !compact,
          }"
           class="bg-gray-100 rounded w-6"
      />
    </td>
    <td
        v-for="column in columns"
        :key="column.prop"
        class="whitespace-nowrap text-gray-700 td-cell"
        :class="getCellClasses(column)"
    >
      <div v-if="column.prop === undefined"/>
      <div
          v-else-if="isImage(column)"
          class="placeholder-loading-animation rounded-md h-10 w-10"
      />
      <div
          v-else-if="isStatus(column)"
          :class="{
            'h-4': noBorders || compact,
            'h-[25.5px]': !noBorders && !compact,
          }"
          class="placeholder-loading-animation rounded-full w-14"
      />
      <div v-else-if="isOptions(column)" class="flex space-x-2">
        <div class="placeholder-loading-animation rounded-md h-[30px] w-[30px]"/>
        <div class="placeholder-loading-animation rounded-md h-[30px] w-[30px]"/>
        <div class="placeholder-loading-animation rounded-md h-[30px] w-[30px]"/>
      </div>
      <div
          v-else
          :class="{
            'h-4': noBorders || compact,
            'h-6': !noBorders && !compact,
          }"
          class="placeholder-loading-animation rounded w-full min-w-[20px]"
      />
    </td>
  </tr>
</template>
<script>
  export default {
    props: {
      columns: {
        type: Array,
        default: () => [],
      },
      getCellClasses: {
        type: Function,
        default: () => ({})
      },
      noBorders: Boolean,
      compact: Boolean,
      isExpandable: Boolean,
    },
    methods: {
      isImage(column) {
        const imageProps = ['image', 'avatar', 'allocations']
        return imageProps.some(prop => column?.prop?.includes(prop))
      },
      isStatus(column) {
        const statusProps = ['status', 'type', 'active']
        return statusProps.some(prop => column?.prop?.includes(prop))
      },
      isOptions(column) {
        return column?.prop?.includes('actions')
      },
    }
  }
</script>
