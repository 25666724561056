<template>
  <div class="truncate">
    {{ valueToDisplay }}
  </div>
</template>
<script lang="ts" setup>
  import { computed } from 'vue'
  import get from 'lodash/get'
  import store from '@/store'
  import { globalResources } from "@/components/form/util";

  const props = defineProps({
    data: {
      type: Object,
      default: () => ({}),
    },
    id: {
      type: String,
    },
    showDescription: {
      type: Boolean,
      default: true,
    },
    params: {
      type: Object,
      default: () => ({}),
    },
  })

  const subTradeId = computed(() => {
    if (props.params?.value) {
       return props.params?.value
    }

    return props.id || get(props.data, 'attributes.sub_trade_id')
  })

  const subTrades = computed(() => {
    return store.getters['globalLists/getResourceList'](globalResources.SubTrades)
  })

  const subTrade = computed(() => {
    return subTrades.value.find((a: any) => a.id === subTradeId.value) || {}
  })

  const valueToDisplay = computed(() => {
    const { trade, description } = subTrade.value
    return description || trade
  })
</script>
