<template>
  <div>
    <a v-if="formattedAddress && !editableCell"
       :href="locationLink"
       target="_blank"
       class="hover:text-primary-900 hover:underline cursor-pointer"
    >
      <address class="address-field truncate">
        {{ formattedAddress }}
      </address>
    </a>
    <span v-else>{{ formattedAddress }}</span>
  </div>
</template>
<script>
  export default {
    props: {
      column: {
        type: Object,
        default: () => ({})
      },
      row: {
        type: Object,
        default: () => ({})
      },
      editableCell: Boolean,
    },
    computed: {
      formattedAddress() {
        const row = this.row.attributes
        const address = row.address
        if (address && typeof address === 'string' && address.length > 0) {
          return address
        }
        const city = row.city || ''
        const state = row.state || ''

        let fullAddress = ''

        if (city) {
          fullAddress += city
        }
        if (state) {
          fullAddress += `, ${state}`
        }
        return fullAddress.trim()
      },
      locationLink() {
        return `https://www.google.com/maps/search/?api=1&query=${this.formattedAddress}`
      },
    }
  }
</script>
