<template>
  <PurchaseOrderAdjustForm
    :key="renderKey"
    @create-another="renderKey++"
  />
</template>
<script>
import PurchaseOrderAdjustForm from '@/modules/purchasing-inventory/components/purchase-orders/PurchaseOrderAdjustForm'

export default {
  components: {
    PurchaseOrderAdjustForm,
  },
  data() {
    return {
      renderKey: 1,
    }
  },
}
</script>
