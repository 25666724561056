<template>
  <div class="flex flex-col">
    <div class="mb-6">
      <VendorHistoryFilters @on-filter="onApplyFilters"/>
    </div>
    <VendorHistoryTable :url-params="filters"/>
    <base-back-link to="/accounts-payable/vendors"/>
  </div>
</template>
<script>
  import VendorHistoryTable from '@/modules/accounts-payable/components/vendor-history/VendorHistoryTable'
  import VendorHistoryFilters from '@/modules/accounts-payable/components/vendor-history/VendorHistoryFilters'

  export default {
    data() {
      return {
        url: '/restify/invoices',
        filters: null,
      }
    },
    components: {
      VendorHistoryTable,
      VendorHistoryFilters,
    },
    methods: {
      onApplyFilters(data) {
        this.filters = data
      },
    },
  }
</script>
