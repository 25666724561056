<template>
  <CategoryForm/>
</template>
<script>
  import CategoryForm from '@/modules/service-billing/components/settings/CategoryForm'

  export default {
    components: {
      CategoryForm,
    },
  }
</script>
