<template>
  <BaseFormDialog
      :title="$t('Add new column')"
      :open.sync="showDialog"
      :appendToBody="true"
      @close="$emit('close')">
    <base-form
        :save-text="$t('Add Column')"
        layout="vertical"
        @cancel="$emit('close')"
        @submit="onSubmit"
    >
      <div class="col-span-6">
        <base-input v-model="model.name"
                    :label="$t('Column Name')"
                    :placeholder="$t('1-20 characters')"
                    id="number"
                    rules="required|min:1|max:20"
        />
      </div>
    </base-form>
  </BaseFormDialog>
</template>
<script>
  export default {
    data() {
      return {
        showDialog: true,
        model: {
          name: '',
        }
      }
    },
    methods: {
      onSubmit() {
        this.$emit('submit', this.model.name)
      }
    }
  }
</script>
