<template>
  <entity-select
      url="/restify/job-categories"
      v-bind="$attrs"
      v-on="$listeners"
      :name="$attrs.name || $t('Category')"
      :placeholder="$attrs.placeholder || $t('Job Category')"
      :label="$attrs.label !== undefined ? $attrs.label : $t('Job Category')"
      :add-label="$t('Add new Category')"
      :label-format="formatJobLabel"
      :id="$attrs.id || 'job-category-select'"
      :table-columns="tableColumns"
      :value-key="valueKey"
      class="w-full"
  />
</template>
<script>

  export default {
    inheritAttrs: false,
    props: {
      urlParams: {
        type: Object,
        default: () => ({
          sort: 'code',
        }),
      },
      valueKey: {
        type: String,
        value: 'id',
      },
    },
    data() {
      return {
        tableColumns: [
          {
            minWidth: 140,
            maxWidth: 140,
            name: this.$t('Code'),
            prop: 'attributes.code',
          },
          {
            minWidth: 120,
            maxWidth: 120,
            name: this.$t('Description'),
            prop: 'attributes.description',
          },
        ],
      }
    },
    methods: {
      formatJobLabel(category) {
        const { code, description } = category.attributes
        return !description ? `${code}` : `${code} (${description})`
      },
    },
  }
</script>
