<template>
  <div class="relative inline-block text-left table-filters print:hidden">
    <div>
      <span class="rounded-md shadow-sm">
        <base-tooltip :content="$t('F6 to open filters')">
          <button @click="toggleDropdown"
                  class="inline-flex justify-center items-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm leading-5 font-medium text-gray-400 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150">
            <svg width="20" height="20" viewBox="0 0 20 20" aria-labelledby="filter"
                 role="presentation" class="fill-current text-80">
              <path fill-rule="evenodd"
                    d="M.293 5.707A1 1 0 0 1 0 4.999V1A1 1 0 0 1 1 0h18a1 1 0 0 1 1 1v4a1 1 0 0 1-.293.707L13 12.413v2.585a1 1 0 0 1-.293.708l-4 4c-.63.629-1.707.183-1.707-.708v-6.585L.293 5.707zM2 2v2.585l6.707 6.707a1 1 0 0 1 .293.707v4.585l2-2V12a1 1 0 0 1 .293-.707L18 4.585V2H2z"/>
            </svg>
          <IconArrowDown class="hidden md:flex ml-1"/>
        </button>
        </base-tooltip>
      </span>
    </div>
    <side-drawer v-model="showDrawer"
                 :title="$t('Filters')"
                 :horizontal-padding="false">
      <form @submit.prevent="onApplyFilters" class="relative h-full">
        <div class="bg-white shadow-xs px-2 pb-4 overflow-hidden relative filters-dropdown__content overflow-y-auto">
          <slot>
            <div v-for="(option, index) in filteredConfigOptions"
                 :key="index">
              <div class="h-6 flex items-center bg-gray-100 px-4 text-gray-500 text-sm font-semibold -mx-2 mb-1">
                {{ option.filterName }}
              </div>
              <div class="my-3 px-2">
                <component
                    :is="option.component"
                    v-bind="option.attrs"
                    v-model="model[option.valueKey]"
                    @input="onEntityChange($event, option)"
                    @entity-change="onEntityChange($event, option)"
                    inline-errors
                    :add-entity="false"
                    :multiple="Array.isArray(model[option.valueKey])"
                    :ref="`${option.component}-${index}`"
                    class="border-none p-0"
                />
              </div>
            </div>
          </slot>
        </div>
        <div class="absolute bottom-0 w-full bg-white py-4 px-4 sm:px-6 flex justify-end">
          <base-cancel-button @click.stop="onResetFilters"
                              class="flex-1 mr-2"
                              :disabled="!filtersApplied"
                              block>
            {{ $t('Reset Filters') }}
          </base-cancel-button>
          <base-submit-button class="flex-1"
                              type="submit"
                              block>
            {{ $t('Apply Filters') }}
          </base-submit-button>
        </div>
      </form>
    </side-drawer>
  </div>
</template>
<script>
  import BooleanFilter from '@/components/table/BooleanFilter'
  import SideDrawer from '@/components/common/modal/SideDrawer';

  export default {
    components: {
      BooleanFilter,
      SideDrawer,
    },
    props: {
      configOptions: {
        type: Array,
        default: () => [],
      },
      defaultPresets: {
        type: Object,
        default: () => ({}),
      },
      activeFilters: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        showDrawer: false,
        model: { ...this.defaultPresets },
        fullModel: {
          ...this.defaultPresets,
        },
        displayModel: {},
      }
    },
    computed: {
      filtersApplied() {
        return JSON.stringify(this.model) !== JSON.stringify(this.defaultPresets)
      },
      filteredConfigOptions() {
        return this.configOptions.filter(item => {
          if (item.hidden) {
            return false
          }
          const matchingFilter = this.configOptions.find(f => f.valueKey === item.valueKey && item.component !== f.component)
          return !(matchingFilter && item.component === 'base-input')
        })
      },
    },
    methods: {
      onEntityChange(value, filter) {
        try {
          let key = filter.valueKey
          this.displayModel[key] = value
          if (typeof value === 'object' && !Array.isArray(value)) {
            this.displayModel[key] = this.getLabelFromValue(value, filter)
          } else if (Array.isArray(value)) {
            this.displayModel[key] = value.map(v => this.getLabelFromValue(v, filter)).join(',')
          }
          if (filter.advanced) {
            let mappedValue = value.map(v => {
              if (typeof v === 'object') {
                return v?.attributes?.value
              }
              return v
            })
            const advancedValue = {
              advanced: true,
              key: filter.key,
              value: {
                [key]: mappedValue,
              },
            }
            this.$set(this.fullModel, key, advancedValue)
          } else {
            this.$set(this.fullModel, key, value)
          }
        } catch (e) {
          console.warn(e)
        }
      },
      getLabelFromValue(value, filter) {
        let labelKey = filter.labelKey
        if (value?.label) {
          return value.label
        }
        let defaultLabels = ['name', 'number', 'code', 'label']
        if (labelKey && typeof value === 'object') {
          return value?.attributes?.[labelKey] || value[labelKey] || ''
        }
        let label = ''
        defaultLabels.forEach(key => {
          const labelValue = value?.attributes?.[key] || value?.[key] || ''
          if (labelValue) {
            label = labelValue
          }
        })
        return label
      },
      onChange(query) {
        this.$emit('on-change', query, this.fullModel)
        this.$emit('on-display-change', this.displayModel)
        this.$emit('on-full-filter-change', this.fullModel)
      },
      onResetFilters() {
        this.model = { ...this.defaultPresets }
        this.displayModel = {}
        this.onChange(this.model)
        this.tryResetBoolFilterState()
      },
      tryResetBoolFilterState() {
        const refs = this.$refs
        Object.keys(refs).forEach(key => {
          if (key.includes('boolean-filter') && refs[key]) {
            refs[key][0].preselectOption(null)
          }
        })
      },
      onApplyFilters() {
        this.onChange(this.model)
        this.showDrawer = false
      },
      toggleDropdown() {
        this.showDrawer = !this.showDrawer
        if (!this.showDrawer) {
          return
        }

        this.setDefaultMatchFilters()
      },
      setDefaultMatchFilters() {
        Object.keys(this.activeFilters).forEach(key => {
          this.model[key] = this.activeFilters[key].toString()
        })
      },
      focusOnFirstFilter() {
        if (!this.showDrawer) {
          return false
        }
      },
      onKeyDown(event) {
        if (event.target && event.target.nodeName === 'INPUT') {
          return
        }
        if (event.code === 'F6') {
          this.toggleDropdown()
        }
      },
      resetFilter(filterKey) {
        this.model[filterKey] = ''
        this.displayModel[filterKey] = ''
        this.fullModel[filterKey] = ''
      },
    },
    mounted() {
      const firstInput = document.querySelector('.table-filters input:enabled')
      if (firstInput) {
        setTimeout(() => {
          firstInput.focus()
        }, 400)
      }
      document.addEventListener('keydown', this.onKeyDown)
    },
    beforeDestroy() {
      document.removeEventListener('keydown', this.onKeyDown)
    },
    watch: {
      activeFilters: {
        deep: true,
        handler(value) {
          if (!value) {
            return
          }
          for (let key in value) {
            let val = value[key]
            let localValue = this.model[key]
            if (localValue && !val) {
              this.model[key] = typeof localValue === 'boolean' ? false : ''
              this.displayModel[key] = typeof localValue === 'boolean' ? false : ''
            }
          }
        },
      },
    },
  }
</script>
<style lang="scss">
  .filters-dropdown__content {
    max-height: calc(100vh - 140px);
    @apply overflow-y-auto pb-10;
  }
  .filters-dropdown__content .date-picker__wrapper .el-date-editor {
    @apply w-full;
  }
</style>
