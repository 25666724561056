const state = {
  data: {}
}

const actions = {
  setData({ state }, model) {
    state.data = model
  }
}

const getters = {
  getData: state => {
    return state.data
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters
}
