import { globalResources } from "@/components/form/util";
import { JobTypeFor } from "@/modules/job-costing/enum/jobs";
import { sourceTypes } from "@/modules/service-billing/util/service-billing";

export const specialSourceMap = {
  [JobTypeFor.Cost.Material]: globalResources.Materials,
  [JobTypeFor.Cost.Equipment]: globalResources.Equipments,
  [JobTypeFor.Cost.Labor]: globalResources.CraftCodes,
  default: '',
}
export const specialSourceTypeMap = {
  [JobTypeFor.Cost.Material]: sourceTypes.Material,
  [JobTypeFor.Cost.Equipment]: sourceTypes.Equipment,
  [JobTypeFor.Cost.Labor]: sourceTypes.Labor,
}
