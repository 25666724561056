const types = {
  SET_VISIBLE_COLUMNS: 'SET_VISIBLE_COLUMNS',
  SET_CURRENT_PER_PAGE: 'SET_CURRENT_PER_PAGE',
};
const state = {
  visibleColumns: {},
  currentPerPage: 50,
  isDataGridDirty: false,
};

const mutations = {
  [types.SET_VISIBLE_COLUMNS]: (state, value) => {
    const { table, columns } = value
    state.visibleColumns[table] = columns
  },
  [types.SET_CURRENT_PER_PAGE]: (state, value) => {
    state.currentPerPage = value
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
