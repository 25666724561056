<template>
  <div>
    <layout-tabs :items="filteredItems"/>
    <router-view/>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        items: [
          {
            name: this.$t('Accounts Receivable Settings'),
            path: '/accounts-receivable/settings/accounts-receivable-settings',
            permission: 'accounts_receivable_settings',
          },
          {
            name: this.$t('Settings Update Activity'),
            path: '/accounts-receivable/settings/activity-log',
            permission: 'job_costing_settings',
          },
          {
            name: this.$t('Sales Tax Districts'),
            path: '/accounts-receivable/settings/sales-tax-districts',
            permission: 'districts_show',
          },
          {
            name: this.$t('Initialize Open Receivable'),
            path: '/accounts-receivable/settings/ar-initialize',
            permission: 'accounts_receivable_settings',
          },
          {
            name: 'Customer Billing Rates',
            path: '/accounts-receivable/settings/billing-rates',
            permission: 'accounts_receivable_settings',
          },
          {
            name: this.$t('Billing Rate Types'),
            path: '/accounts-receivable/settings/billing-rate-types',
            permission: 'accounts_receivable_settings',
          },
        ],
      }
    },
    computed: {
      filteredItems() {
        return this.items.filter(item => this.$can(item.permission))
      },
    },
  }
</script>
