<template>
  <AgDataTable
      :url="url"
      :url-params="urlParams"
      :columns="columns"
      :pagination="false"
      :default-filters="false"
      :transform-data="transformData"
      :groupRowRendererParams="groupRowRendererParams"
      groupDisplayType="groupRows"
      actions="search,refresh,print"
      domLayout="autoHeight"
  >
    <template #activity="{row}">
      <SettingsActivityLogRows :row="row"/>
    </template>
  </AgDataTable>
</template>
<script lang="ts">
  import Data = API.Data;
  import { defineComponent } from 'vue'
  import { Column } from '@/components/ag-grid/tableTypes'
  import SettingsActivityLogRows from '@/modules/common/components/SettingsActivityLogRows.vue'
  import SettingsActivityLogGroupRow from '@/modules/common/components/SettingsActivityLogGroupRow.vue'

  export default defineComponent({
    components: {
      SettingsActivityLogRows,
      SettingsActivityLogGroupRow,
    },
    props: {
      settingsId: String,
      module: String,
    },
    computed: {
      url(): string {
        return !this.settingsId ? '' : 'restify/company-setting-activities'
      },
      urlParams(): { company_setting_id?: string, sort?: string } {
        return this.settingsId ? {
          company_setting_id: this.settingsId,
          sort: '-created_at',
        } : {}
      },
      columns(): Column[] {
        return [
          {
            headerName: 'Activity',
            field: 'id',
            autoHeight: true,
            rowGroup: true,
            hide: true,
          },
          {
            headerName: 'Activity',
            field: 'activity',
            autoHeight: true,
          },
        ]
      },
      groupRowRendererParams(): { innerRenderer: string, module: string | undefined } {
        return {
          suppressCount: true,
          innerRenderer: 'SettingsActivityLogGroupRow',
          module: this.module,
        }
      },
    },
    methods: {
      transformData(data: Data<any>[]) {
        return data.filter(row => {
          const {current, prior} = row?.attributes
          return Object.keys(current).length || Object.keys(prior).length
        })
      },
    },
  })
</script>
