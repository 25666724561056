<template>
  <AgDataTable
      :columns="columns"
      :pagination="false"
      :show-pagination="false"
      :data="addresses"
      domLayout="autoHeight"
      showCellsLegend
      @grid-ready="onGridReady"
  />
</template>
<script>
  import pick from 'lodash/pick'
  import omit from 'lodash/omit'
  import { getTableData } from '@/components/ag-grid/tableUtils'
  import { stateList } from '@/enum/state'
  import { formattedText } from '@/utils/utils'

  const types = [
    'project',
    'contact_person',
    'owner',
    'original_contractor',
    'lender',
    'subcontractor',
    'trust_fund_1',
    'trust_fund_2',
    'trust_fund_3',
    'architect',
  ]

  export default {
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        grid: null,
        addresses: [],
      }
    },
    computed: {
      columns() {
        return [
          {
            headerName: this.$t('Type'),
            field: 'type',
            cellClass: params => 'bg-gray-100 cursor-not-allowed',
          },
          {
            headerName: this.$t('Name'),
            field: 'name',
            editable: true,
          },
          {
            headerName: this.$t('Address'),
            field: 'address_1',
            editable: true,
          },
          {
            headerName: this.$t('City'),
            field: 'city',
            editable: true,
          },
          {
            headerName: this.$t('State'),
            field: 'state',
            editable: true,
            cellEditor: this.$cellEditors.BaseSelect,
            cellEditorParams: {
              options: stateList,
            },
          },
          {
            headerName: this.$t('Postal Code'),
            field: 'postal_code',
            editable: true,
          },
          {
            headerName: this.$t('Parcel'),
            field: 'parcel',
            editable: (params) => params.data.type === 'Project',
            cellClass: params => params.data.type === 'Project' ? '' : 'bg-gray-100 cursor-not-allowed',
          },
        ]
      },
    },
    methods: {
      formatAddresses() {
        const addressObjects = pick(this.data, types)

        this.addresses = Object.keys(addressObjects).map(key => {
          const type = formattedText(key)
          return {
            type,
            _localId: key,
            ...addressObjects[key],
          }
        })
      },
      onGridReady(params) {
        this.grid = params
      },
      getPayload() {
        let payload = omit(this.data, types)

        const gridApi = this.grid.api
        const data = getTableData(gridApi)

        let parties = {}

        data.forEach((item) => {
          parties[`${item._localId}`] = omit(item, ['type', '_localId'])
        })

        payload.parties = parties
        parties.individual = this.data.individual

        return payload
      },
    },
    mounted() {
      this.formatAddresses()
    },
  }
</script>
