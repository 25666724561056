<template>
  <div class="cursor-pointer base-radio__wrapper relative border py-3 px-2"
       :class="{
              'bg-gray-100': disabled,
              'flex items-center justify-start': inline,
              'border-primary-200 bg-primary-100': active,
              'border-gray-200': !active,
     }">
        <input :id="cbId"
               class="form-radio h-4 w-4 text-gray-800 transition duration-150 ease-in-out cursor-pointer"
               type="radio"
               :disabled="disabled"
               :value="label"
               v-model="model"/>
      <label :for="cbId" class="cursor-pointer w-full absolute inset-0 flex items-center">
        <span class="ml-3 font-medium text-gray-900 pl-6 capitalize"
              :class="{'text-primary-900': active}"
        >
          <slot></slot>
        </span>
      </label>
    </div>
</template>
<script>
  export default {
    name: 'base-radio',
    props: {
      label: {
        type: [String, Number],
        description: 'Radio label'
      },
      disabled: {
        type: Boolean,
        description: 'Whether radio is disabled'
      },
      active: {
        type: Boolean,
        description: 'Whether radio is active'
      },
      value: {
        type: [String, Boolean],
        description: 'Radio value'
      },
      inline: {
        type: Boolean,
        description: 'Whether radio is inline'
      }
    },
    data () {
      return {
        cbId: ''
      }
    },
    computed: {
      model: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      },
    },
    mounted () {
      this.cbId = Math.random().toString(16).slice(2)
    }
  }
</script>
