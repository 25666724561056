<template>
  <transaction-details :transaction="params?.data"/>
</template>
<script>
import TransactionDetails from "@/modules/ledger/components/TransactionDetails.vue";

export default {
  components: { TransactionDetails }
}
</script>
