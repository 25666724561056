<template>
  <entity-preview
      v-bind="$attrs"
      :entity="jobData"
      :key="`preview-${jobData.id}`"
      :url="`/job-costing/jobs/${jobData.id}/line-items/list`"
      :value-to-display="valueToDisplay"
      :parent-entity-id="parentId || jobData.id"
      :link-class="linkClass"
      :show-preview="showPreview"
      :target="target"
      :is-link="!editableCell"
      entity-name="job"
  />
</template>
<script>
  import { gridContext } from "@/components/ag-grid/gridContext";

  export default {
    name: 'JobLink',
    props: {
      id: [String, Number],
      data: {
        type: Object,
        default: () => ({}),
      },
      label: {
        type: String,
      },
      parentId: {
        type: String,
      },
      linkClass: {
        type: String,
        default: 'text-base print:text-print text-gray-900',
      },
      showDescription: {
        type: Boolean,
        default: true,
      },
      showPreview: {
        type: Boolean,
        default: true,
      },
      target: String,
      editableCell: Boolean,
    },
    computed: {
      allJobs() {
        return this.$store.state.globalLists.jobs
      },
      jobData() {
        let job = null
        if (this.id) {
          job = this.allJobs.find(j => j.id === this.id)
        }
        if (job) {
          return job
        }
        return this.data?.attributes || this.data || {};
      },
      valueToDisplay() {
        if (this.label) {
          return this.label;
        }
        let showDescription = this.jobData.description && this.showDescription;
        if (gridContext.isPrintMode) {
          showDescription = false
        }
        return showDescription ? `${this.jobData.number} (${this.jobData.description})` : this.jobData.number
      },
    },
  }
</script>
