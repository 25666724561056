<template>
  <div>
    <BaseDataTable :columns="columns"
                   :url-params="urlParams"
                   :row-select-disabled="rowSelectDisabled"
                   url="/restify/payrolls"
                   actions="search,refresh"
                   ref="table"
    >
      <template #additional-actions="{ selectedRows, allSelected, total }">
        <div class="space-x-2 flex items-center">
          <DownloadChecksDialog :batch="payrollBatch"/>

          <SendPayrollChecksForm
            :data="selectedRows"
            action-url="/restify/payrolls/actions"
            action-name="mail-payrolls"
          />

        </div>

        <ConfirmEmailSendDialog
            ref="confirmSendDialog"
            :title="$t(`Send Emails to ${allSelected ?  `all (${total})` : selectedRows.length} employee(s)`)"
            :description="$t(`This action will send email(s) to ${allSelected ?  `all (${total})` : selectedRows.length} employee(s). Make sure to validate the check amounts and preview the checks before sending them via email. Note that employees without an email or that haven't agreed to receive checks via email will be skipped.`)"
        >
          <CheckTemplateSelect v-model="selectedTemplate"/>
        </ConfirmEmailSendDialog>
      </template>

      <template #extra-actions="{row}">
        <table-view-button @click="previewCheck(row)"/>
        <div>
          <DownloadChecksDialog
            :payroll="getDownloadPayroll(row)"
            :batch="{
              id: row?.attributes?.payroll_batch_id
            }"
          />
        </div>
      </template>

      <template #employee="{row}">
        <EmployeeLink :data="get(row, 'relationships.employee')"/>
      </template>

      <template #email="{row}">
        <div class="flex items-center h-8">
          <template v-if="getEmail(row) && !row.editing">
            <WarningTip v-if="isEmployeeConsentRequired && !getEmployee(row).payroll_consent_sent_at"
                        :message="$t('There was no consent email sent for this employee yet')"
            />
            <BaseEmailLink :value="getEmail(row)"/>
          </template>
          <span v-else>

          </span>
        </div>
      </template>

      <template #attributes.payment_number="{row}">
        <div class="flex justify-between">
          <PaymentTypeIcon :type="row.attributes.type" class="w-4 h-4"/>
          <span>{{ row.attributes.payment_number }}</span>
        </div>
      </template>

    </BaseDataTable>
    <PayrollCheckPreviewDialog
        v-if="showCheckPreviewDialog"
        :open.sync="showCheckPreviewDialog"
        :data="rowToPreview"
    />
  </div>
</template>
<script>
  import axios from "axios";
  import { MailIcon } from 'vue-feather-icons'
  import { downloadFileForEntity } from "@/modules/common/util/downloadFileLocally";
  import EmailConsentAlert from "@/modules/payroll/components/EmailConsentAlert";
  import { resourceStatuses } from "@/enum/enums";
  import ConfirmEmailSendDialog from "@/components/common/modal/ConfirmEmailSendDialog";
  import WarningTip from "@/modules/payroll/components/WarningTip";
  import CheckTemplateSelect from "@/modules/payroll/components/CheckTemplateSelect";
  import CheckTemplateDropdown from "@/modules/payroll/components/CheckTemplateDropdown";
  import PayrollCheckPreviewDialog from "@/modules/payroll/components/PayrollCheckPreviewDialog";
  import DownloadChecksDialog from "@/modules/payroll/components/payroll/DownloadChecksDialog.vue";
  import SendPayrollChecksForm from "@/modules/payroll/components/checks/SendPayrollChecksForm.vue";
  import PaymentTypeIcon from "@/modules/payroll/components/payroll/PaymentTypeIcon.vue";

  export default {
    name: 'payroll-check-batch-details',
    components: {
      PaymentTypeIcon,
      SendPayrollChecksForm,
      DownloadChecksDialog,
      MailIcon,
      WarningTip,
      EmailConsentAlert,
      CheckTemplateSelect,
      CheckTemplateDropdown,
      ConfirmEmailSendDialog,
      PayrollCheckPreviewDialog,
    },
    data() {
      return {
        selectedTemplate: this.$store.state.payroll.defaultChecksTemplate || 'box',
        sendEmailLoading: false,
        showCheckPreviewDialog: false,
        rowToPreview: null,
      }
    },
    computed: {
      columns() {
        return [
          {
            label: this.$t('Employee'),
            prop: 'employee',
            maxWidth: 200,
          },
          {
            label: this.$t('Email'),
            prop: 'email',
            minWidth: 200,
            maxWidth: 270,
          },
          {
            label: this.$t('Check Pay'),
            prop: 'attributes.totals.check_pay',
            maxWidth: 130,
            component: 'FormattedPrice',
            align: 'right',
          },
          {
            label: this.$t('DD Pay'),
            prop: 'attributes.totals.direct_deposit_pay',
            maxWidth: 130,
            component: 'FormattedPrice',
            align: 'right',
          },
          {
            label: this.$t('Net Pay'),
            prop: 'attributes.totals.net_pay',
            maxWidth: 130,
            component: 'FormattedPrice',
            align: 'right',
          },
          {
            label: this.$t('Payment Date'),
            prop: 'attributes.payment_date',
            maxWidth: 150,
            align: 'center',
            component: 'FormattedDate',
          },
          {
            label: this.$t('Payment Number'),
            prop: 'attributes.payment_number',
            maxWidth: 200,
          },
          {
            label: this.$t('Period Ending'),
            prop: 'attributes.period_end_date',
            maxWidth: 150,
            align: 'center',
            component: 'FormattedDate',
          },
          {
            label: this.$t('Sent'),
            prop: 'attributes.telemetry.send',
            maxWidth: 100,
            align: 'center',
            sortable: true,
            component: 'Count',
          },
          {
            label: this.$t('Downloaded'),
            prop: 'attributes.telemetry.download',
            maxWidth: 100,
            align: 'center',
            component: 'Count',
          },
        ]
      },
      urlParams() {
        return {
          payroll_batch_id: this.$route.params.id,
          related: 'employee',
        }
      },
      payrollBatch() {
        return this.$store.state.payroll.currentPayrollBatch
      },
      isPosted() {
        return this.payrollBatch?.attributes?.status === resourceStatuses.Posted
      },
      hasPdfPaths() {
        return this.payrollBatch?.attributes?.pdf_paths?.length > 0
      },
      isEmployeeConsentRequired() {
        return !!this.$store.getters['company/isEmployeeConsentRequired']
      },
    },
    methods: {
      getEmployee(row) {
        return this.get(row, 'relationships.employee.attributes', {})
      },
      getEmail(row) {
        return this.get(row, 'relationships.employee.attributes.email')
      },
      rowSelectDisabled(row) {
        return this.getEmail(row) === null
      },
      sendConsentDisabled(selectedRows) {
        return selectedRows.filter(row => {
          const employee = this.getEmployee(row)
          return employee.payroll_consent_sent_at === null
        }).length === 0
      },
      previewCheck(row) {
        this.rowToPreview = row
        this.showCheckPreviewDialog = true
      },
      refreshTableWithDelay() {
        setTimeout(() => {
          this.$refs.table.refresh()
        }, 500)
      },
      getDownloadPayroll(row) {
        return {
          payroll: row?.attributes,
          employee: row?.relationships?.employee?.attributes || {}
        }
      },
    },
    mounted() {
      this.$store.dispatch('payroll/getPayrollBatchDetails', this.$route.params.id)
    }
  }
</script>
