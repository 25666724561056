<template>
  <AgDataTable
      :url="url"
      :read-only="readOnly"
      :url-params="urlParams"
      :columns="columns"
      :table-filters="!!props.data.id"
      :add-text="$t('New entry')"
      :transform-data="mapData"
      :get-empty-row="getEmptyEntry"
      :actions="actions"
      :groupIncludeTotalFooter="true"
      :enableFillHandle="true"
      v-bind="editableTableProps"
      @grid-ready="grid = $event"
      ref="gridTable"
  />
</template>
<script lang="ts" setup>
  import { computed, onMounted, ref } from 'vue'
  import {adjustmentTypes, codeScope, sourceTypeConfigs,} from '@/modules/payroll/pages/adjustment/util'
  import { globalResources } from '@/components/form/util'
  import store from '@/store'
  import i18n from "@/i18n";
  import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";
  import {YTD, YTDS} from "@/modules/common/types/models";
  import { editableTableProps, getTableData, storeEntriesProgress } from "@/components/ag-grid/tableUtils";
  import { getDeleteColumn } from "@/components/ag-grid/columns/deleteColumns";
  import { Column } from "@/components/ag-grid/tableTypes";
  import {
    GridReadyEvent,
    ICellEditorParams,
    ICellRendererParams,
    SuppressKeyboardEventParams, ValueGetterParams, ValueSetterParams
  } from '@ag-grid-community/core'
  import { findGlobalResource, findOptionLabel } from "@/components/ag-grid/cellEditors/cellEditorUtils";
  import { codeTypes } from "@/modules/payroll/components/rates/util";
  import Data = API.Data;
  import {formatPrice} from "@/plugins/formatPrice";
  import {getSetting} from "@/plugins/settingsAndEnumsPlugin";
  import {$modules} from "@/enum/enums";
  import orderBy from "lodash/orderBy";

  const props = defineProps({
    data: {
      type: Object,
      default: () => ({}),
    },
    readOnly: {
      type: Boolean,
    }
  })

  const actions = computed(() => {
    if (props.readOnly) {
      return
    }
    return `add,refresh`
  })

  const entries = ref<YTDS>([])

  const url = computed(() => {
    return props.data.employee_id ? '/restify/ytds' : ''
  })
  const urlParams = computed(() => {
    const payrollCalendarYear = getSetting($modules.PR, 'calendar_year') || new Date().getFullYear()
    const type = props.data.type === adjustmentTypes.ALL ? undefined : props.data.type
    return !props.data.employee_id ? {} : {
      employee_id: props.data.employee_id,
      type,
      year: payrollCalendarYear,
      sort: 'source.code',
    }
  })

  const activeFiscalYear = computed(() => {
    return store.getters['company/getSessionActiveFiscalYear']
  })

  function getEmptyEntry() {
    return {
      _localId: crypto.randomUUID(),
      source_id: null,
      source_type: sourceTypeConfigs[props.data.type].sourceType,
      employee_id: props.data?.employee_id,
      type: props.data.type,
      state_id: null,
      local_id: null,
      q1_amount: 0,
      q2_amount: 0,
      q3_amount: 0,
      q4_amount: 0,
      year: activeFiscalYear.value,
    }
  }

  function getResourceNameForSource(source: YTD) {
    const resourceMap = {
      [codeTypes.DEDUCTION]: globalResources.DeductionCodes,
      [codeTypes.DIRECT_DEPOSIT_CODE]: globalResources.DirectDepositCodes,
      [codeTypes.FLEX_BEN]: globalResources.FlexibleBenefitPlans,
      [codeTypes.RETIREMENT_PLAN]: globalResources.RetirementPlans,
      [codeTypes.GENERAL]: globalResources.GeneralCodes,
      [codeTypes.PAY]: globalResources.PayCodes,
      [codeTypes.BEN]: globalResources.BenefitCodes,
      [codeTypes.TAX]: globalResources.TaxCodes,
      [codeTypes.WTWO]: globalResources.WTwoCodes,
      [codeTypes.TIME_OFF]: globalResources.TimeOffPolicies,
    }
    return resourceMap[source.source_type]
  }

  function quarterCellRenderer(params: ICellRendererParams) {
    const type = params?.data?.type
    if (type === codeTypes.GENERAL) {
      return params.value
    }
    return formatPrice(params.value)
  }

  function getSource(row: YTD) {
    if (!row) {
      return
    }
    const resourceName = getResourceNameForSource(row)
    return findGlobalResource(resourceName, row.source_id, 'id')
  }

  function getSourceName(row: YTD) {
    const source = getSource(row)
    if (!source) {
      return ''
    }
    return source?.code ? `${source.code} (${source.description})` : ''
  }


  const columns = computed<Column[]>(() => {
    const sourceType = sourceTypeConfigs[props.data.type]
    const isDeductionTab = props.data.type === adjustmentTypes.DEDUCTIONS
    const isEarningsTab = props.data.type === adjustmentTypes.EARNINGS
    const isAllTab = props.data.type === adjustmentTypes.ALL

    let sourceTypeOptions = [
      {
        label: i18n.t('Deduction Codes'),
        value: codeTypes.DEDUCTION,
      },
      {
        label: i18n.t('Direct Deposit Codes'),
        value: codeTypes.DIRECT_DEPOSIT_CODE,
      },
      {
        label: i18n.t('General Codes'),
        value: codeTypes.GENERAL,
      },
      {
        label: i18n.t('Flexible Benefits'),
        value: codeTypes.FLEX_BEN,
      },
      {
        label: i18n.t('Retirement Plan'),
        value: codeTypes.RETIREMENT_PLAN,
      },
    ]

    if (isEarningsTab) {
      sourceTypeOptions = [
        {
          label: i18n.t('Pay Codes'),
          value: codeTypes.PAY,
        },
        {
          label: i18n.t('Time Off Policies'),
          value: codeTypes.TIME_OFF,
        },
      ]
    }
    if (isAllTab) {
      sourceTypeOptions = [
        {
          label: i18n.t('Benefit Codes'),
          value: codeTypes.BEN,
        },
        {
          label: i18n.t('Deduction Codes'),
          value: codeTypes.DEDUCTION,
        },
        {
          label: i18n.t('Direct Deposit Codes'),
          value: codeTypes.DIRECT_DEPOSIT_CODE,
        },
        {
          label: i18n.t('General Codes'),
          value: codeTypes.GENERAL,
        },
        {
          label: i18n.t('Flexible Benefits'),
          value: codeTypes.FLEX_BEN,
        },
        {
          label: i18n.t('Retirement Plan'),
          value: codeTypes.RETIREMENT_PLAN,
        },
        {
          label: i18n.t('Pay Codes'),
          value: codeTypes.PAY,
        },
        {
          label: i18n.t('Time Off Policies'),
          value: codeTypes.TIME_OFF,
        },
        {
          label: i18n.t('Tax Codes'),
          value: codeTypes.TAX,
        },
        {
          label: i18n.t('W-2 Memos'),
          value: codeTypes.WTWO,
        },
      ]
    }

    return [
      {
        field: 'attributes.employee_id',
        hide: true,
      },
      {
        headerName: i18n.t(`${sourceType.cellTitle} Type`),
        field: 'source_type',
        minWidth: 80,
        editable: true,
        cellEditor: cellEditors.BaseSelect,
        cellEditorParams: {
          options: sourceTypeOptions,
        },
        valueFormatter: params => {
          return findOptionLabel(sourceTypeOptions, params.value)
        },
        hide: !isDeductionTab && !isEarningsTab && !isAllTab,
      },
      {
        headerName: i18n.t(sourceType.cellTitle),
        field: 'source_id',
        minWidth: 250,
        editable: true,
        cellEditor: cellEditors.GlobalResourceSelect,
        cellEditorParams: (params: ICellEditorParams) => {
          return {
            resourceName: getResourceNameForSource(params.data),
            filterMethod: getFilteredCodes,
          }
        },
        valueFormatter: params => {
          return getSourceName(params.data)
        },
        valueSetter: params => {
          params.data.source_id = params.newValue
          const source = getSource(params.data)
          if (source.state) {
            const state = findGlobalResource(globalResources.StateTaxes, source.state, 'code')
            params.data.state_id = state?.id
          }
          return true
        }
      },
      {
        headerName: i18n.t('State'),
        field: 'state_id',
        minWidth: 40,
        cellEditor: cellEditors.GlobalResourceSelect,
        cellEditorParams: {
          resourceName: globalResources.StateTaxes,
        },
        hide: props.data?.type === adjustmentTypes.W2MEMOS,
        editable: true,
        valueFormatter: params => {
          const state = findGlobalResource(globalResources.StateTaxes, params.value, 'id')
          return state?.code
        }
      },
      {
        headerName: i18n.t('Local'),
        field: 'local_id',
        minWidth: 40,
        cellEditor: cellEditors.GlobalResourceSelect,
        cellEditorParams: {
          resourceName: globalResources.LocalTaxes,
        },
        hide: props.data?.type === adjustmentTypes.W2MEMOS,
        editable: true,
        valueFormatter: params => {
          const local = findGlobalResource(globalResources.LocalTaxes, params.value, 'id')
          return local?.code
        }
      },
      {
        headerName: i18n.t('1st Quarter'),
        field: 'q1_amount',
        minWidth: 50,
        cellRenderer: quarterCellRenderer,
        align: 'right',
        editable: true,
        valueSetter: (params: ValueSetterParams) => {
          params.data.q1_amount = +params.newValue
          return true
        },
        aggFunc: 'sum',
      },
      {
        headerName: i18n.t('2nd Quarter'),
        field: 'q2_amount',
        minWidth: 50,
        cellRenderer: quarterCellRenderer,
        align: 'right',
        editable: true,
        valueSetter: (params: ValueSetterParams) => {
          params.data.q2_amount = +params.newValue
          return true
        },
        aggFunc: 'sum',
      },
      {
        headerName: i18n.t('3rd Quarter'),
        field: 'q3_amount',
        minWidth: 50,
        cellRenderer: quarterCellRenderer,
        align: 'right',
        editable: true,
        valueSetter: (params: ValueSetterParams) => {
          params.data.q3_amount = +params.newValue
          return true
        },
        aggFunc: 'sum',
      },
      {
        headerName: i18n.t('4th Quarter'),
        field: 'q4_amount',
        minWidth: 50,
        cellRenderer: quarterCellRenderer,
        align: 'right',
        editable: true,
        valueSetter: (params: ValueSetterParams) => {
          params.data.q4_amount = +params.newValue
          return true
        },
        suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
          let isTabKey = params.event.key === 'Tab'
          if (isTabKey) {
            params.api.stopEditing()
          }
        },
        aggFunc: 'sum',
      },
      {
        ...getDeleteColumn({
          url: url.value,
          title: i18n.t('Delete Adjustment'),
          description: i18n.t('Delete Adjustment'),
          hide: props.readOnly,
        })
      }
    ]

  })

  function getFilteredCodes(code: any) {
    if (code.show_in_ytds === undefined) {
      return true
    }
    return !!code.show_in_ytds
  }

  async function setCurrentAdjustmentType() {
    const type = props.data?.type || adjustmentTypes.EARNINGS
    await store.dispatch('dataGridConfig/setPayrollAdjustmentType', type)
  }

  onMounted(async () => {
    await setCurrentAdjustmentType()
  })

  function mapData(data: Data<YTD>[]) {
    entries.value = data.map(rate => {
      const row = rate.attributes || {}
      const source = getSource(row)
      const priorityMap: Record<string, number> = {
        [codeScope.Federal]: 2,
        [codeScope.State]: 1,
        [codeScope.Local]: 0,
      }
      const priority: number = priorityMap[source?.scope] || 0
      return {
        ...row,
        source: getSource(row),
        priority,
        source_name: getSourceName(row),
      }
    })
    return orderBy(entries.value, ['source_type', 'priority', 'source.code'], ['asc', 'desc', 'asc'])
  }

  const grid = ref<GridReadyEvent>()
  async function storeProgress() {
    entries.value = getTableData(grid.value?.api)
    await storeEntriesProgress(entries.value, url.value)
  }

  defineExpose({
    storeProgress,
  })
</script>
