import i18n             from "@/i18n";
import { billingTypes } from "@/modules/accounts-receivable/pages/billings/billings";

export const progressPrintActions = {
  G703: 'print-g703',
  G702: 'print-g702',
  CombinedInvoice: 'print-progress-combined',
}

export const servicePrintActions = {
  Standard: 'print-a5555-sb-invoice-unit-price-format',
  Grid: 'print-a5555-sb-unit-price-grid',
}

export const unitPricePrintActions = {
  Combined: 'print-unit-price-combined',
  EarnedValues: 'print-unit-price-value',
}
const LumpSumPrintActions = {
  Form: 'print-lump-sum-form',
}

export const progressPrintFormats = [
  {
    label: i18n.t('AIA Form G703'),
    value: progressPrintActions.G703,
  },
  {
    label: i18n.t('AIA Form G702'),
    value: progressPrintActions.G702,
  },
  {
    label: i18n.t('Combined Invoice'),
    value: progressPrintActions.CombinedInvoice,
  },
]

export const servicePrintFormats = [
  {
    label: i18n.t('Standard'),
    value: servicePrintActions.Standard,
  },
  {
    label: i18n.t('Grid'),
    value: servicePrintActions.Grid,
  },
]

export const unitPricePrintFormats = [
  {
    label: i18n.t('Combined'),
    value: unitPricePrintActions.Combined,
  },
  {
    label: i18n.t('Earned Values'),
    value: unitPricePrintActions.EarnedValues,
  }
]

const printLumpSumFormats = [
  {
    label: i18n.t('Form'),
    value: LumpSumPrintActions.Form,
  },
]

export function getPrintFormats(billingType: string) {
  const mapping = {
    [billingTypes.Service]: servicePrintFormats,
    [billingTypes.Progress]: progressPrintFormats,
    [billingTypes.UnitPrice]: unitPricePrintFormats,
    [billingTypes.LumpSum]: printLumpSumFormats,
  }
  return mapping[billingType] || []
}

export const defaultTemplates = {
  [billingTypes.Service]: servicePrintActions.Standard,
  [billingTypes.Progress]: progressPrintActions.G703,
  [billingTypes.UnitPrice]: unitPricePrintActions.Combined,
  [billingTypes.LumpSum]: LumpSumPrintActions.Form,
}
