<template>
  <router-link
    v-if="openEntityInNewTab && link"
    :key="`edit-${row.id}`"
    :to="link"
    :title="title"
    :disabled="disabled"
    class="table-action-button hidden md:flex"
    tabindex="-1"
    @click.native="$emit('click')"
  >
    <BaseButton
      variant="gray-light"
      :tabindex="skipFocus ? -1 : 0"
      size="icon"
    >
      <Edit2Icon class="w-4 h-4"/>
    </BaseButton>
  </router-link>
  <BaseButton
    v-else
    :tabindex="skipFocus ? -1 : 0"
    variant="gray-light"
    size="icon"
    class="table-action-button hidden md:flex"
    :title="title"
    @click="$emit('click')"
  >
    <Edit2Icon class="w-4 h-4"/>
  </BaseButton>
</template>
<script>
import { Edit2Icon } from 'vue-feather-icons'
import i18n from '@/i18n'

export default {
  name: 'TableEditButton',
  components: {
    Edit2Icon,
  },
  props: {
    row: {
      type: Object,
      default: () => ({})
    },
    link: {
      type: String,
    },
    openEntityInNewTab: Boolean,
    disabled: Boolean,
    skipFocus: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: i18n.t('Edit')
    }
  }
}
</script>
