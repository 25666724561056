import Vue from 'vue';
import axios from 'axios'
import { cancelTopProgress } from '@/modules/common/progressbar';
import config from '@/modules/common/config'
import { isExceptionDomain, isProduction } from "@/isProduction";

const notifier = new Vue();
const errMessage = 'You are not authorized to view this page.';

let loginRoute = {
  path: '/login'
};

function removeStorageItems() {
  // * On import data the ids are changed, so we need to remove the old ones
  localStorage.removeItem('customer-payments');
  localStorage.removeItem('ar-join-checks');
  localStorage.removeItem('selectedCostsForBilling');
  localStorage.removeItem('selectedBillToType');
  localStorage.removeItem('selectedAPInvoicesToPay');
}

function redirectToLogin(next, fromPath) {
  cancelTopProgress();
  removeStorageItems();
  return next(`${loginRoute.path}?from=${fromPath}`);
}

function redirectBack(next, to) {
  notifier.$error(errMessage);
  return next(to.path);
}

/**
 * Middleware to check if user has the correct right to access a certain page.
 * Checks are performed based on route meta fields `meta: { requiresAuth: true }`.
 * @param {object} router Vue router instance
 */
export default function authMiddleware(router) {
  router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

    if (!requiresAuth) {
      return next();
    }
    const token = localStorage.getItem('token') || to.query.token;

    if (!token) {
      return redirectToLogin(next, to.fullPath);
    }

    const record = to.matched.find(record => record.meta.permissions && record.name === to.name);
    if (record) {
      let { permissions, productionReady } = record.meta;
      let canNavigate = notifier.$can(permissions);
      if (!productionReady && isProduction()) {
        return next('/')
      }
      if (!canNavigate) {
        return redirectBack(next, from);
      }
    }

    return next();
  });
}
