<!--Format to percent or price-->
<template>
  <span>
    {{ getValue }}
  </span>
</template>
<script>
  import get from 'lodash/get'
  import { calculateBy } from '@/modules/payroll/components/rates/util'

  export default {
    props: {
      column: {
        type: Object,
        default: () => ({}),
      },
      row: {
        type: Object,
        default: () => ({}),
      },
      calculateByKey: {
        type: String,
        default: 'attributes.calculate_by',
      },
    },
    computed: {
      getValue() {
        const amount = get(this.row, this.column.prop)
        const calculateBy = get(this.row, this.calculateByKey)

        if (calculateBy === calculateBy.Percentage) {
          return this.$formatPercent(amount / 100, {
            maximumFractionDigits: 2
          })
        }
        return this.$formatPrice(amount)
      },
    },
  }
</script>
