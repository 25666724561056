<template>
  <VendorHistory/>
</template>
<script>
  import VendorHistory from '@/modules/accounts-payable/components/vendor-history/VendorHistory'

  export default {
    components: {
      VendorHistory,
    }
  }
</script>
