<template>
  <div>
    <div class="mt-8">
      <BaseDataTable :columns="columns"
                     url="/restify/app-templates"
                     ref="table">
        <template v-slot:attributes.html="{row}">
          <div class="truncate" v-html="row.attributes.html || ''"></div>
        </template>
        <template v-slot:extra-actions="{row}">
          <table-edit-button v-if="$can('app_templates_update')"
                             @click="seeMoreClick(row)">
          </table-edit-button>
        </template>
      </BaseDataTable>
    </div>
  </div>
</template>
<script>
  import TableEditButton from "@/components/table/actions/TableEditButton";

  export default {
    components: {
      TableEditButton,
    },
    data() {
      return {
        rowToEdit: null,
        columns: [
          {
            label: this.$t('Title'),
            prop: 'attributes.title',
            maxWidth: 200,
          },
          {
            label: this.$t('Content'),
            prop: 'attributes.html',
            maxWidth: 200,
          },
        ]
      }
    },
    methods: {
      seeMoreClick(row) {
        this.$router.push(`/settings/app-templates/${row.id}`)
      },
    }
  }
</script>
