<template>
  <span>
    {{ getValue }}
  </span>
</template>
<script>
  import { getNodeValue } from "@/components/ag-grid/columnUtils";

  export default {
    props: {
      column: {
        type: Object,
        default: () => ({}),
      },
      row: {
        type: Object,
        default: () => ({}),
      },
      params: {
        type: Object,
        default: () => ({})
      }
    },
    computed: {
      columnParams() {
        return this.column.params || {}
      },
      getValue() {
        let value = getNodeValue({
          params: this.params,
          row: this.row,
          column: this.column,
        })

        if (value === '') {
          return value
        }

        value = parseFloat(value)

        if (!value) {
          return '0.000'
        }
        const formatter = new Intl.NumberFormat('en-US', {
          minimumFractionDigits: this.columnParams.minimumFractionDigits || 3,
          maximumFractionDigits: this.columnParams.maximumFractionDigits || 3,
        })
        return formatter.format(value)
      },
    }
  }
</script>
