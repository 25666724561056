<template>
  <div @click="$router.push(data.path)"
       class="bg-white border border-gray-200 min-h-xs px-10 pt-8 pb-6 rounded-md cursor-pointer flex flex-col hover:bg-gray-50">
    <div class="w-12 h-12 rounded-md flex items-center justify-center mb-2"
         :class="`bg-${data.color}-50`"
    >
      <component :is="data.icon" :class="`text-${data.color}-600`" class="w-4 h-4"/>
    </div>
    <h2 class="section-title">{{ data.name }}</h2>
    <p class="leading-tight font-medium text-sm text-gray-400 mt-2">
      {{ getDescription(data.routeName) }}
    </p>
    <div class="flex flex-1 items-end">
      <router-link :to="data.path" class="w-full flex justify-end mt-2">
        <IconArrowRight class="w-6 h-6 text-primary-600 cursor-pointer transform hover:text-primary-700"/>
      </router-link>
    </div>
  </div>
</template>
<script>
  import { getNestedRoutes } from "@/modules/dashboard/util/routeUtils";

  export default {
    props: {
      data: {
        type: Object,
        default: () => ({}),
      }
    },
    computed: {
      allRoutes() {
        let result = []
        if (this.$router.options.routes) {
          this.$router.options.routes.forEach(route => {
            result.push(...getNestedRoutes(route))
          })
        }
        return result
      }
    },
    methods: {
      findRoute(name) {
        return this.allRoutes.find(r => r.name === name)
      },
      getDescription(name) {
        const route = this.findRoute(name)
        return route?.description || ``
      }
    }
  }
</script>
<style>
</style>
