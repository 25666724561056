<template>
  <PayrollBatchesTable/>
</template>
<script>
  import PayrollBatchesTable from "@/modules/payroll/components/payroll/PayrollBatchesTable";

  export default {
    components: {
      PayrollBatchesTable,
    },
  }
</script>
