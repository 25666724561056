<template>
  <div :key="get(currentResource, 'id', 'invoice')">
    <portal to="page-title">
      {{ pageTitle }},&nbsp;@
      <router-link v-if="vendor?.id" :to="`/accounts-payable/vendors/${vendor?.id}/invoices`">
        {{ vendor?.code }}
        <span v-if="vendor?.name">({{ vendor.name }})</span>
      </router-link>
    </portal>
    <div class="flex justify-between items-center">
      <layout-tabs :items="filteredItems"/>
      <div class="flex items-center space-x-2">
        <base-button
          v-if="canHold && !isOnHold"
          variant="primary-link"
          @click="holdInvoice"
        >
          <PauseIcon class="w-4 h-4 mr-1"/>
          <span>{{ $t('Hold') }}</span>
        </base-button>
        <base-button
          v-if="isOnHold"
          variant="primary-link"
          @click="unHoldInvoice"
        >
          <PlayIcon class="w-4 h-4 mr-1"/>
          <span>{{ $t('Unhold') }}</span>
        </base-button>
        <InvoiceQuickPay v-if="$isAuthorized('authorizedToQuickPay', currentResource)"
                         :invoice="currentResource"
                         @save-success="getCurrentResource"
        />
        <ProofListingButton
            v-if="$isAuthorized('authorizedToPost', currentResource)"
            :selected-rows="[currentResource]"
            path="/accounts-payable/invoices/proof-listing"
        />

        <VoidAction v-if="$isAuthorized('authorizedToVoid', currentResource)"
                    :id="currentResource?.id"
                    @on-action-callback="getCurrentResource"
        />
        <PreviewChecksButton
            v-if="hasPayments"
            :download-function="downloadChecks"
        >
          <PrinterIcon class="w-5 h-5 mr-2"></PrinterIcon>
          <span>{{ $t('Print Check(s)') }}</span>
        </PreviewChecksButton>
      </div>
    </div>
    <portal to="entity-name">
      {{ $t('Invoice ') }}{{ currentResource?.attributes?.number }}
      ({{vendor?.name}})
    </portal>
    <router-view/>
  </div>
</template>
<script>
  import axios from 'axios'
  import { PauseIcon, PrinterIcon, PlayIcon } from 'vue-feather-icons'
  import layoutPermissions from '@/mixins/layoutPermissions'
  import PreviewChecksButton from '@/components/common/PreviewChecksButton'
  import { resourceStatuses } from "@/enum/enums";

  export default {
    components: {
      PauseIcon,
      PlayIcon,
      PrinterIcon,
      PreviewChecksButton,
    },
    mixins: [layoutPermissions],
    computed: {
      items() {
        return [
          {
            name: this.$t('Details'),
            path: `/accounts-payable/invoices/${this.$route.params.id}/view`,
            authorizedTo: 'authorizedToShow',
          },
          {
            name: this.$t('Edit'),
            path: `/accounts-payable/invoices/${this.$route.params.id}/edit`,
            authorizedTo: 'authorizedToUpdate',
          },
        ]
      },
      currentResource() {
        return this.$store.state.accountsPayable.currentInvoice
      },
      vendor() {
        const { vendor } = this.currentResource?.relationships || {}
        return vendor?.attributes
      },
      pageTitle() {
        const invoiceNumber = this.currentResource.attributes.number || ''
        return `${this.$t('Invoice ')} #${invoiceNumber}`
      },
      payments() {
        return this.get(this.currentResource, 'relationships.payments', [])
      },
      hasPayments() {
        return this.payments.length > 0
      },
      canHold() {
        return [resourceStatuses.Posted, resourceStatuses.PartialPaid].includes(this.currentResource.attributes.status)
      },
      isOnHold() {
        return this.currentResource.attributes.status === resourceStatuses.Hold
      },
    },
    methods: {
      async getCurrentResource(id) {
        if (!id) {
          id = this.$route.params.id
        }
        await this.$store.dispatch('accountsPayable/getInvoice', id)
      },
      async holdInvoice() {
        const confirmed = await this.$deleteConfirm({
          title: this.$t('Hold Invoice'),
          description: this.$t('By confirming this action, the invoice will be put on hold and cannot be paid. Are you sure?'),
          buttonText: this.$t('Hold')
        })
        if (!confirmed) {
          return
        }
        const { id } = this.currentResource
        await this.$store.dispatch('accountsPayable/holdInvoice', id)
      },
      async unHoldInvoice() {
        const confirmed = await this.$deleteConfirm({
          title: this.$t('Unhold Invoice'),
          description: this.$t('The invoice will be unhold and can be paid. Are you sure?'),
          buttonText: this.$t('Unhold')
        })
        if (!confirmed) {
          return
        }
        const { id } = this.currentResource
        await this.$store.dispatch('accountsPayable/unHoldInvoice', id)
      },
      async downloadChecks(model = {}) {
        const url = `/restify/invoice-payments/action?action=print-invoice-payment-checks`
        const repositories = this.payments.map(b => b.id)
        const data = {
          repositories,
          ...model,
        }
        const params = { responseType: 'blob' }

        return await axios.post(url, data, params)
      },
    },
  }
</script>
