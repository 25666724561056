<template>
  <div>
    <portal
        v-if="expandedSections.length === 0 && model.number"
        to="page-title"
    >
      {{ $t('Billing #') }}{{ model.number }},&nbsp;@
      <router-link :to="`/accounts-receivable/customers/${selectedCustomer.id}/view`">
        {{ selectedCustomer?.attributes?.code }}
        <span v-if="selectedCustomer?.attributes?.name">({{ selectedCustomer.attributes.name }})</span>
      </router-link>
    </portal>
    <base-go-to-edit-alert
        v-if="!!existingBillingId"
        :path="`/accounts-receivable/billings/progress/${existingBillingId}/edit`"
        message="Progress billing with the same billing number already exists. Please choose another billing number, job or customer."
    />
    <base-form
        :loading="loading"
        :show-back="showBack"
        :show-cancel="true"
        :save-text="$t('Create progress billing')"
        :update-text="$t('Update progress billing')"
        :submit-disabled="!!existingBillingId"
        :can-create-another-entity="!model.id"
        :focus-on-first-input="!$route.params.id"
        :sticky-header="expandedSections.length === 0"
        submit-button-type="button"
        layout="vertical"
        grid-classes="grid grid-cols-8 gap-x-4"
        @cancel="$router.push('/accounts-receivable/billings/progress')"
        @submit="onSubmit"
    >
      <template #header>
        <el-collapse v-model="expandedSections"
                     class="col-span-8">
          <base-collapse-section key="form-header"
                                 name="form-header"
                                 class="-m-6"
                                 has-background
          >
            <template #title>
              <div class="flex w-full items-center justify-between mx-2 form-header-summary">
                <BillingFormHeader :data="model"/>
                <portal-target name="billing-summary"/>
              </div>
            </template>
            <div class="grid grid-cols-8 gap-x-4 mx-2">
              <job-select
                  v-model="model.job_id"
                  :disabled="!!$route.params.id"
                  :on-map-entry="onChangeJob"
                  :edit-entity="!model.id"
                  :url-params="jobUrlParams"
                  :add-entity="false"
                  rules="required"
                  id="job_id"
                  class="col-span-8 md:col-span-2"
                  @change="validateSelection"
              />
              <customer-select
                  v-model="model.customer_id"
                  :disabled="!!$route.params.id || !!customerInputDisabled"
                  :initial-value="selectedCustomer"
                  :add-entity="false"
                  rules="required"
                  class="col-span-8 md:col-span-2"
                  @change="validateSelection"
                  @entity-change="onChangeCustomer"
              />

              <InvoiceNumberInput
                v-model="model.number"
                :model="model"
                :key="loadingNumber"
                :loading="loadingNumber"
                class="col-span-8 md:col-span-2 xl:col-span-1"
                @change="validateSelection"
                @blur="fillApplicationNumber"
              />

              <BillingDates
                  :model="model"
                  @date-change="validateSelection"
                  @date-blur="fillApplicationDate"
              />

              <BillingTax
                :model="model"
                :data="data"
                @sales-tax-change="shouldEntriesChange"
              >
                <template #after-tax-percentage>
                  <div class="mt-7">
                    <TableSyncButton
                      :content="$t('Re-compute sales tax')"
                      @click="shouldEntriesChange"
                    />
                  </div>
                </template>
              </BillingTax>
              <div class="col-span-8">
                <h5 class="form-section-title">
                  {{ $t('Retention') }}
                </h5>
              </div>
              <base-input
                  v-model="model.retention_completed_percent"
                  :min="0"
                  :max="100"
                  :name="getRetentionLabel"
                  :label="getRetentionLabel"
                  id="retention_completed_percent"
                  type="number"
                  rules="max_value:100|min_value:0"
                  format="percent"
                  class="col-span-8 md:col-span-2 xl:col-span-1"
                  @change="onRetentionChange"
              />
              <base-input
                  v-model="model.retention_stored_percent"
                  :min="0"
                  :max="100"
                  :name="$t('Retention Stored')"
                  :label="$t('Retention Stored')"
                  id="retention_stored_percent"
                  type="number"
                  rules="max_value:100|min_value:0"
                  format="percent"
                  class="col-span-8 md:col-span-2 xl:col-span-1"
                  @change="onRetentionChange"
              />
              <div class="col-span-8"/>
              <base-input
                  v-model="model.application_number"
                  :key="loadingNumber"
                  :label="$t('Application #')"
                  :placeholder="$t('Application Number')"
                  id="application_number"
                  rules="max:50"
                  class="col-span-8 md:col-span-2 xl:col-span-1"
              />
              <base-date-picker
                  v-model="model.application_date"
                  :label="$t('Application Date')"
                  :placeholder="$t('Application Date')"
                  :name="$t('Application Date')"
                  id="application_date"
                  class="col-span-8 md:col-span-2"
              />
              <base-date-picker
                  v-model="model.period_to"
                  :label="$t('Period To')"
                  :placeholder="$t('Period To')"
                  :name="$t('Period To')"
                  id="period_to"
                  class="col-span-8 md:col-span-2"
              />
              <base-input
                  v-model="model.architect"
                  :label="$t('Architect Project Number')"
                  :placeholder="$t('Architect Project Number')"
                  :name="$t('Architect Project Number')"
                  id="architect"
                  rules="max:12"
                  class="col-span-8 md:col-span-2"
              />
              <div class="col-span-8 md:col-span-2 xl:col-span-1">
                <base-switch :value="model.status === resourceStatuses.NoPost"
                             :label-info="$t('No Post')"
                             :disable="noPostToggleDisabled"
                             @input="triggerNoPost"
                />
              </div>
            </div>
          </base-collapse-section>
        </el-collapse>
      </template>

      <div class="col-span-8 mt-6">
        <ProgressBillingEntries
            v-bind="$attrs"
            :billing="model"
            :key="model.job_id"
            :get-billing-entries="getBillingEntries"
            ref="entriesTable"
        />
      </div>

      <FileAttachments
          ref="attachments"
          class="col-span-8 mt-4"
          entity="billings"
          :entity-id="entityId"
      />

    </base-form>
  </div>
</template>
<script>
import axios from 'axios'
import pick from 'lodash/pick'
import { resourceStatuses } from '@/enum/enums'
import { billingTypeAbbr, getNextBillingNumber } from '@/modules/accounts-receivable/pages/billings/billings'
import ProgressBillingEntries from '@/modules/accounts-receivable/components/progress-bllings/ProgressBillingEntries'
import BillingFormHeader from '@/modules/accounts-receivable/components/BillingFormHeader'
import { RestifyResources } from "@/components/form/util";
import { getTaxPercentage, shouldEntriesChange } from "@/modules/accounts-receivable/utils/billingUtils";
import BillingDates from "@/modules/accounts-receivable/components/billings/BillingDates.vue";
import BillingTax from "@/modules/accounts-receivable/components/billings/BillingTax.vue";
import InvoiceNumberInput from "@/modules/accounts-payable/components/invoice/InvoiceNumberInput.vue";
import { useInvoiceSequencing } from '@/modules/accounts-receivable/composables/useInvoiceSequencing'
import TableSyncButton from "@/components/table/actions/TableSyncButton.vue";

const retentionBasis = {
  ToDate: 'to-date',
  ThisPeriod: 'this-period',
}

const retentionLabels = {
  [retentionBasis.ThisPeriod]: 'This Period',
  [retentionBasis.ToDate]: 'Completed',
}

const meta = {
  retention_basis: retentionBasis.ToDate,
  retention_completed_percent: 0,
  retention_stored_percent: 0,
  application_number: '',
  application_date: '',
  period_to: '',
  architect: '',
  exempt_from_sales_tax: false,
  district_id: '',
}

export default {
  components: {
    TableSyncButton,
    InvoiceNumberInput,
    BillingTax,
    BillingDates,
    ProgressBillingEntries,
    BillingFormHeader,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const {
      getNextInvoiceNumber,
      billingTypesAbbr,
      loadingNumber,
    } = useInvoiceSequencing()

    return {
      getNextInvoiceNumber,
      billingTypesAbbr,
      loadingNumber,
    }
  },
  data() {
    return {
      selectedCustomer: {},
      selectedJob: {},
      existingBillingId: false,
      resourceStatuses,
      expandedSections: ['form-header'],
      billingTypeAbbr,
      loading: false,
      showBack: true,
      createdId: null,
      entityId: null,
      model: {
        job_id: null,
        customer_id: null,
        number: null,
        gross_amount: 0,
        discount_percent: 0,
        sales_tax_percent: 0,
        retention_percent: 0,
        retention_basis: retentionBasis.ThisPeriod,
        retention_completed_percent: 0,
        retention_stored_percent: 0,
        date: this.$now,
        due_date: null,
        discount_date: null,
        type: billingTypeAbbr.Progress,
        status: this.$settings(this.$modules.AR, 'default_prg_status') || resourceStatuses.Pending,
        ...meta,
        meta,
      },
    }
  },
  computed: {
    jobUrlParams() {
      return {
        with_budget: billingTypeAbbr.Progress,
        related: 'customer[id|code|name]'
      }
    },
    getRetentionLabel() {
      return this.$t('Retention') + ' ' + this.$t(retentionLabels[this.model.retention_basis])
    },
    noPostToggleDisabled() {
      return ![resourceStatuses.Pending, resourceStatuses.NoPost].includes(this.model.status)
    },
    customerInputDisabled() {
      return this.model.id || (this.model.job_id && this.model.customer_id)
    },
  },
  methods: {
    async validateSelection() {
      if (this.model.id || !this.model.number || !this.model.job_id || !this.model.customer_id) {
        return
      }
      this.existingBillingId = false

      const { data } = await axios.get('restify/billings', {
        params: {
          job_id: this.model.job_id,
          customer_id: this.model.customer_id,
          number: this.model.number,
          status: `${resourceStatuses.Pending},${resourceStatuses.NoPost}`,
          type: billingTypeAbbr.Progress,
        },
      })

      this.existingBillingId = this.get(data, '[0].id', '')
    },
    fillApplicationNumber() {
      this.model.application_number = this.model.application_number || this.model.number
    },
    fillApplicationDate() {
      this.model.application_date = this.model.application_date || this.model.date
    },
    async onSubmit() {
      try {
        this.loading = true
        this.model.meta = pick(this.model, Object.keys(meta))

        if (this.model.id) {
          await axios.put(`/restify/billings/${this.model.id}`, this.model)
          await this.$refs.entriesTable.storeProgress(this.model.id)

          this.$success(this.$t('Job progress billing updated'))
          await this.$addSystemGeneratedNote({
            resourceName: RestifyResources.Billings,
            resourceId: this.model.id,
            isEdit: true,
          })
          this.$emit('refresh')
        } else {
          const { data } = await axios.post('/restify/billings/', this.model)
          this.createdId = data.id
          await this.$refs.entriesTable.storeProgress(data.id)
          await this.$addSystemGeneratedNote({
            resourceName: RestifyResources.Billings,
            resourceId: data.id,
          })
          this.entityId = data.id
          await this.$refs.attachments.triggerUpload()
          if (this.$createAnotherEntity) {
            return this.$emit('create-another')
          }
          this.$router.push('/accounts-receivable/billings/progress/pending')
        }

      } catch (err) {
        console.warn(err)

        if (this.createdId) {
          await axios.delete(`/restify/billings/${this.createdId}`)
          this.createdId = null
        }
        if (err.handled) {
          return
        }
        this.$error(this.$t('Something went wrong. Please try again.'))
      } finally {
        this.loading = false
      }
    },
    onChangeJob(job) {
      if (!job) {
        return
      }
      const { attributes } = job
      this.selectedJob  = job
      this.selectedCustomer = this.get(job, 'relationships.customer', {})

      this.model.job_id = job.id
      this.model.retention_percent = this.model.retention_completed_percent = this.model.retention_stored_percent = attributes.billing_retention_percent
      this.model.customer_id = attributes.customer_id
      this.model.architect = attributes.project_number

      this.getInvoiceNumber()
    },
    triggerNoPost(value) {
      value ? this.model.status = resourceStatuses.NoPost : this.model.status = resourceStatuses.Pending
    },
    async getInvoiceNumber() {
      if (this.model.id) {
        return
      }

      this.model.number = this.model.application_number = ''

      if (!this.model.job_id || !this.model.customer_id) {
        return
      }

      const model = {
        type: this.billingTypesAbbr.Progress,
        job_id: this.model.job_id,
        job_number: this.selectedJob?.attributes?.number,
        customer_id: this.model.customer_id,
      }

      const invoiceNumber = await this.getNextInvoiceNumber(model)
      this.model.number = this.model.application_number = invoiceNumber
      this.validateSelection()
    },
    onChangeCustomer(customer) {
      this.selectedCustomer = customer
      this.getInvoiceNumber()
    },
    async onRetentionChange() {
      const hasEntriesWithRetention = this.$refs.entriesTable.hasEntriesWithRetention()
      if (!hasEntriesWithRetention) {
        return
      }
      await this.$nextTick()
      const confirmed = await this.$confirm({
        title: this.$t('Update Retention for All Entries'),
        description: this.$t('Do you want to update retention for all entries?'),
        buttonText: this.$t('Update Retention'),
      })
      if (!confirmed) {
        return
      }
      this.$refs.entriesTable.recomputeEntryAmounts()
    },
    async shouldEntriesChange() {
      await shouldEntriesChange(this.$refs.entriesTable)
    },
    async getBillingEntries() {
      try {
        const { data } = await axios.get('/restify/billing-entries', {
          params: {
            billing_id: this.model.id,
            perPage: 999999,
            sort: 'order',
            related: 'addlSource',
          },
        })

        return data.map(entry => entry.attributes)
      } catch (err) {
        console.warn(err)
      }
    },
    tryFillFromQuery() {
      const { customer_id, job_id } = this.$route.query
      if (this.data?.id) {
        return
      }
      if (customer_id) {
        this.model.customer_id = customer_id
      }
      if (job_id) {
        this.model.job_id = job_id
      }
    }
  },
  mounted() {
    this.tryFillFromQuery()
  },
  watch: {
    data: {
      immediate: true,
      handler(value) {
        if (!value?.id) {
          return
        }

        this.expandedSections = []

        this.model = {
          ...this.model,
          ...value.attributes,
          ...value?.attributes?.meta,
        }

        this.selectedCustomer = this.get(value, 'relationships.customer', {})
      },
    },
  },
}
</script>
