<template>
  <EntityPurchaseOrders
    :url-params="urlParams"
  />
</template>
<script>
import EntityPurchaseOrders from "@/modules/purchasing-inventory/components/purchase-orders/EntityPurchaseOrders.vue";

export default {
  components: {
    EntityPurchaseOrders,
  },
  computed: {
    urlParams() {
      return {
        work_order_id: this.$route.params.id,
      }
    },
  }
}
</script>
