<template>
  <div class="statement-batches">
    <div class="mt-8">
      <BaseDataTable :columns="columns"
                     actions="delete"
                     url="/restify/customer-statements/batches"
                     :data-loading="loading"
                     :add-default-columns="false"
                     :delete-action="deleteAction"
                     :on-delete-confirm-message="$t('Are you sure you want to delete this Customer Statement Batch? Any previously sent email links to customers will no longer work. This action cannot be undone.')"
                     ref="invitesTable">
        <template #date="{row}">
          <router-link :to="`/accounts-receivable/statement-batches/${row.date}`">
            {{ $formatDate(row.date) }}
          </router-link>
        </template>
        <template #download_percent="{row}">
          {{ $getPercent(+row.downloaded_count, +row.sent_count) }}
        </template>
        <template #extra-actions-before="{row}">
          <table-view-button @click="seeMoreClick(row)"/>
        </template>
      </BaseDataTable>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'

  export default {
    data() {
      return {
        batches: [],
        loading: false,
        columns: [
          {
            label: this.$t('Date'),
            prop: 'date',
            maxWidth: 100,
          },
          {
            label: this.$t('Statement Count'),
            prop: 'count',
            maxWidth: 100,
            align: 'center',
            component: 'Count',
          },
          {
            label: this.$t('Sent Count'),
            prop: 'sent_count',
            maxWidth: 100,
            align: 'center',
            component: 'Count',
          },
          {
            label: this.$t('Sent Times'),
            prop: 'sent_times',
            maxWidth: 100,
            align: 'center',
            component: 'Count',
          },
          {
            label: this.$t('Downloaded'),
            prop: 'downloaded_count',
            maxWidth: 100,
            align: 'center',
            component: 'Count',
          },
          {
            label: this.$t('Downloaded %'),
            prop: 'download_percent',
            maxWidth: 140,
            align: 'center',
          },
        ],
      }
    },
    methods: {
      seeMoreClick(row) {
        this.$router.push(`/accounts-receivable/statement-batches/${row.date}`)
      },
      async deleteAction(row, index) {
        try {
          this.loading = true
          await axios.delete(`/restify/customer-statements/batches/${row.date}`)
          this.$success(`Customer statement batches for date #${row.date} deleted.`)
          this.$refs.invitesTable.tableData.splice(index, 1)
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(`Could not delete customer statement batches for date #${row.attributes.date}.`)
        } finally {
          this.loading = false
        }
      },
    },
  }
</script>
