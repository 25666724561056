<template>
  <BaseDataTable
      class="public-progress-billing-entries"
      :data="sortedData"
      :data-loading="loading"
      :columns="columns"
      :per-page="perPage"
      :show-pagination="false"
      :no-borders="true"
      :limit-max-height="false"
      :placeholder-rows="15"
      has-summary
  >
    <template #budget="{row}">
      {{ $formatPrice(getBudget(row)?.amount) }}
    </template>
    <template #completed="{row}">
      {{ $formatPrice(getAmountToDate(row)) }}
    </template>
    <template #completed_percentage="{row}">
      {{ $formatPercent(getCompletedPercentage(row)) }}
    </template>
    <template #total_completed="{row}">
      {{ $formatPrice(getTotalCompleted(row)) }}
    </template>

    <template #relationships.addlSource.attributes.description_summary>
      {{ $t('Totals To-Date:') }}
    </template>
    <template #budget_summary>
      {{ $formatPrice(totals.budget) }}
    </template>
    <template #completed_summary>
      {{ $formatPrice(totals.completed) }}
    </template>
    <template #completed_percentage_summary>
      {{ $formatPercent(totals.completed_percentage) }}
    </template>
    <template #attributes.amount_summary>
      {{ $formatPrice(totals.amount) }}
    </template>
    <template #total_completed_summary>
      {{ $formatPrice(totals.total_completed) }}
    </template>
  </BaseDataTable>
</template>
<script>
  import sortBy from 'lodash/sortBy'
  import {
    getAmountToDate,
    getBudget,
    getCompletedPercentage,
    getTotalCompleted,
    getTotals
  } from "@/modules/public/utils/publicBillingUtils";

  export default {
    name: 'PublicProgressBillingEntries',
    props: {
      data: {
        type: Array,
        default: () => [],
      },
      loading: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        perPage: 200,
      }
    },
    computed: {
      columns() {
        return [
          {
            label: this.$t('Phase'),
            prop: 'relationships.addlSource.attributes.phase_code',
            maxWidth: 40,
            minWidth: 20,
          },
          {
            label: this.$t('Cost Code'),
            prop: 'relationships.addlSource.attributes.cost_code',
            maxWidth: 30,
            minWidth: 20,
            align: 'center',
          },
          {
            label: this.$t('Chg Ord'),
            prop: 'relationships.addlSource.attributes.change_order',
            maxWidth: 30,
            minWidth: 20,
            align: 'center',
          },
          {
            label: this.$t('Description of work'),
            prop: 'relationships.addlSource.attributes.description',
            maxWidth: 170,
          },
          {
            label: this.$t('Scheduled of Values'),
            prop: 'budget',
            maxWidth: 140,
            align: 'right',
            component: this.$tableColumns.FormattedPrice,
          },
          {
            label: this.$t('Previously Completed'),
            prop: 'completed',
            maxWidth: 140,
            align: 'right',
            component: this.$tableColumns.FormattedPrice,
          },
          {
            label: this.$t('This Period'),
            prop: 'attributes.gross_amount',
            maxWidth: 140,
            align: 'right',
            component: this.$tableColumns.FormattedPrice,
          },
          {
            label: this.$t('% Completed'),
            prop: 'completed_percentage',
            maxWidth: 80,
            align: 'right',
            component: this.$tableColumns.FormattedPercent,
          },
          {
            label: this.$t('Total Completed'),
            prop: 'total_completed',
            maxWidth: 100,
            align: 'right',
            component: this.$tableColumns.FormattedPrice,
          },
        ]
      },
      sortedData() {
        return sortBy(this.data, 'relationships.addlSource.attributes.change_order')
      },
      itemsWithoutChangeOrders() {
        return this.sortedData.filter(r => r.relationships?.addlSource?.attributes?.change_order === 0)
      },
      itemsWithChangeOrders() {
        return this.sortedData.filter(r => r.relationships?.addlSource?.attributes?.change_order !== 0)
      },
      totalsWithoutChangeOrders() {
        return getTotals(this.itemsWithoutChangeOrders)
      },
      totalsWithChangeOrders() {
        return getTotals(this.itemsWithChangeOrders)
      },
      totals() {
        return getTotals(this.sortedData)
      }
    },
    methods: {
      getBudget,
      getTotalCompleted,
      getAmountToDate,
      getCompletedPercentage,
    }
  }
</script>
<style lang="scss">
  .public-progress-billing-entries .table-wrapper {
    .summary-row {
      @apply bg-gray-100;
    }
  }
</style>
