<template>
  <div>
    <div class="flex justify-between space-x-2">
      <p class="tip my-2 ml-1">
        {{
          $t(`Adjust payroll batch taxes. A maximum threshold of $1 is allowed for each entry. Making changes here will affect the payroll batch totals. `)
        }}
      </p>
      <BaseButton
          :disabled="!hasUpdates"
          :loading="loading"
          @click="adjustTaxes"
      >
        {{ $t('Adjust Payroll Taxes') }}
      </BaseButton>
    </div>
    <AgDataTable
        :url="`/restify/payroll-batches/${payrollBatchId}/adjust-taxes`"
        actions="search,refresh"
        v-bind="tableProps"
        :columns="tableColumns"
        :show-cells-legend="true"
        :transform-data="transformData"
        :groupDefaultExpanded="-1"
        groupDisplayType="groupRows"
        :groupRowRendererParams="groupRowRendererParams"
    >
    </AgDataTable>
  </div>
</template>
<script>
  import { editableTableProps } from "@/components/ag-grid/tableUtils";
  import { cellClasses } from "@/components/ag-grid/columnUtils";
  import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";
  import i18n from "@/i18n";
  import AdjustPayrollBatchTaxesGroupRow
    from "@/modules/payroll/components/payroll/AdjustPayrollBatchTaxesGroupRow.vue";
  import axios from "axios";
  import cloneDeep from 'lodash/cloneDeep'
  import sortBy from 'lodash/sortBy'

  export default {
    components: {
      AdjustPayrollBatchTaxesGroupRow,
    },
    data() {
      return {
        tableProps: {
          ...editableTableProps,
          authorizeToCopyLastRow: false,
          addRowOnTab: false,
          defaultFilters: false,
        },
        thresholdAmount: 1,
        loading: false,
        entriesToUpdate: {},
        initialData: [],
        unrestrictedTaxCodes: ['TAXF1', 'TAXS1']
      }
    },
    computed: {
      payrollBatchId() {
        return this.$route.params.id
      },
      groupRowRendererParams() {
        return {
          innerRenderer: 'AdjustPayrollBatchTaxesGroupRow',
          suppressCount: true,
        }
      },
      hasUpdates() {
        return Object.values(this.entriesToUpdate).length > 0
      },
      tableColumns() {
        return [
          {
            headerName: i18n.t('Employee'),
            field: 'employee.id',
            rowGroup: true,
            hide: true,
          },
          {
            headerName: this.$t('Code'),
            field: 'special_source.code',
            minWidth: 80,
            maxWidth: 120,
            cellRendererParams: {
              showSourceType: false,
            },
            cellClass: cellClasses.ReadOnly,
            suppressNavigable: true,
          },
          {
            headerName: this.$t('Description'),
            field: 'special_source.description',
            minWidth: 150,
            cellClass: cellClasses.ReadOnly,
            suppressNavigable: true,
          },
          {
            headerName: this.$t('Account'),
            field: 'account',
            component: 'AccountLink',
            minWidth: 60,
            maxWidth: 80,
            cellClass: cellClasses.ReadOnly,
            cellRendererParams: {
              showDescription: false,
            },
            suppressNavigable: true,
          },
          {
            headerName: this.$t('Sub'),
            field: 'subaccount',
            component: 'SubAccountLink',
            minWidth: 60,
            maxWidth: 80,
            cellClass: cellClasses.ReadOnly,
            cellRendererParams: {
              showDescription: false,
            },
            suppressNavigable: true,
          },
          {
            headerName: this.$t('$ Amount'),
            field: 'amount',
            minWidth: 150,
            maxWidth: 250,
            align: 'right',
            cellClass: cellClasses.ReadOnly,
            suppressNavigable: true,
            component: 'FormattedPrice',
          },
          {
            headerName: this.$t('$ Adjusted Amount'),
            field: 'adjusted_amount',
            minWidth: 150,
            maxWidth: 250,
            editable: true,
            component: 'FormattedPrice',
            align: 'right',
            cellEditor: cellEditors.Numeric,
            valueSetter: params => {
              const { data, newValue } = params
              const taxCode = data.special_source.code
              const newAmount = parseFloat(newValue)
              const oldValue = data.amount
              const difference = Math.abs(newAmount - oldValue)
              if (newValue < 0) {
                this.$error(this.$t('Negative values are not allowed.'))
                return false
              }
              if (difference > 1 && !this.unrestrictedTaxCodes.includes(taxCode)) {
                this.$error(this.$t('A maximum threshold of $1 is allowed for each entry.'))
                return false
              }
              this.updateEntries({
                row: data,
                field: 'adjusted_amount',
                fieldValue: newAmount,
              })
              data.adjusted_amount = newAmount
              return true
            }
          }
        ]
      }
    },
    methods: {
      transformData(data) {
        this.initialData = cloneDeep(data)
        data = sortBy(data, 'employee.code')
        const entries = []
        data.forEach(row => {
          row.entries.forEach(entry => {
            entries.push({
              _localId: crypto.randomUUID(),
              ...entry,
              employee: row.employee,
              payroll: row.payroll,
            })
          })
        })
        return entries
      },
      getEntryKey(entry, payroll) {
        return payroll?.id + entry.key
      },
      updateEntries({ row, field, fieldValue }) {
        const key = this.getEntryKey(row, row.payroll)
        if (this.entriesToUpdate[key]) {
          this.entriesToUpdate[key][field] = fieldValue
          return
        }
        this.$set(this.entriesToUpdate, key, row)
      },
      mapEntriesToRequest() {
        let payrolls = cloneDeep(this.initialData)
        payrolls.forEach(payroll => {
          delete payroll.employee
          payroll.entries = payroll.entries.filter(entry => {
            return this.entriesToUpdate[this.getEntryKey(entry, payroll.payroll)]
          })
          payroll.entries = payroll.entries.map(entry => {
            const updatedEntry = this.entriesToUpdate[this.getEntryKey(entry, payroll.payroll)]
            if (updatedEntry) {
              return {
                ...entry,
                adjusted_amount: updatedEntry.adjusted_amount,
              }
            }
            return entry
          })
        })
        payrolls = payrolls.filter(p => p.entries.length > 0)
        return {
          payrolls,
        }
      },
      async adjustTaxes() {
        try {
          const confirmed = await this.$confirm({
            title: this.$t('Adjust Taxes ?'),
            description: this.$t('The taxes will be adjusted for the selected entries. The net pay will be affected. Are you sure you want to continue?'),
            buttonText: this.$t('Adjust Taxes'),
          })
          if (!confirmed) {
            return
          }
          this.loading = true
          const data = this.mapEntriesToRequest()
          await axios.post(`/restify/payroll-batches/${this.payrollBatchId}/actions?action=adjust-taxes`, data)
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error('Could not adjust taxes')
        } finally {
          this.loading = false
        }
      }
    }
  }
</script>
