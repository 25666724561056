<template>
  <div>

    <base-filter-row>
      <base-checkbox
          v-model="selectJobList"
          :label="$t('Select a List of Jobs')"
          class="col-span-3 mb-3"
          id="select-job-list"
          @change="resetJobFilters"
      />
    </base-filter-row>

    <base-filter-row
        v-if="selectJobList"
        :title="$t('Jobs')"
    >
      <job-select
          v-model="value.jobs"
          :add-entity="false"
          :collapse-tags="true"
          :placeholder="$t('Select jobs')"
          :hide-label="true"
          value-key="number"
          class="col-span-4"
          multiple
      />
    </base-filter-row>

    <job-range-filter
        v-else
        v-model="value"
    >
      <slot name="job-statuses"/>
    </job-range-filter>

    <base-filter-row :title="$t('Additional Filters')">
      <base-select
          v-model="additionalFilter1"
          :options="filteredAddlFilterOptions(2)"
          :hide-label="true"
          :placeholder="$t('Select Filter Type')"
          class="col-span-2"
          clearable
          @change="onChangeAddlFilter(2)"
      />
      <component
          v-model="value[additionalFilter1]"
          :is="getFilterComponent(additionalFilter1)"
          :add-entity="false"
          :hide-label="true"
          :class="{ '-mt-6': additionalFilter1 === additionalFilters.CertifiedJob }"
          rules="required"
          class="col-span-3 border-none"
          inline-errors
          clearable
          multiple
      />
    </base-filter-row>

    <base-filter-row v-if="showSecondAdditionalFiltersSection">
      <base-select
          v-model="additionalFilter2"
          :options="filteredAddlFilterOptions(1)"
          :hide-label="true"
          class="col-span-2"
          clearable
          @change="onChangeAddlFilter(1)"
      />
      <component
          v-model="value[additionalFilter2]"
          :is="getFilterComponent(additionalFilter2)"
          :add-entity="false"
          :hide-label="true"
          :class="{ '-mt-6': [additionalFilters.OcipJob, additionalFilters.CertifiedJob].includes(additionalFilter2) }"
          rules="required"
          class="col-span-3"
          clearable
          multiple
      />
    </base-filter-row>

  </div>
</template>
<script>
  import {
    JobRangeFilter,
  } from '@/components/range-filters'

  const additionalFilters = {
    Category: 'category_ids',
    Customer: 'customer_ids',
    Employee: 'employee_ids',
    Union: 'union_ids',
    JobCity: 'cities',
    JobState: 'states',
    TaxState: 'state_ids',
    TaxDistrict: 'district_ids',
    CertifiedJob: 'certified_payroll',
  }

  export default {
    components: {
      JobRangeFilter,
    },
    props: {
      value: {
        required: true,
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        additionalFilters,
        additionalFilter1: null,
        additionalFilter2: null,
        additionalFilter1Value: null,
        additionalFilter2Value: null,
        selectJobList: false,
        additionalFilterOptions: [
          {
            label: this.$t('Category'),
            value: additionalFilters.Category,
          },
          {
            label: this.$t('Customer'),
            value: additionalFilters.Customer,
          },
          {
            label: this.$t('Sales Employee'),
            value: additionalFilters.Employee,
          },
          {
            label: this.$t('Union'),
            value: additionalFilters.Union,
          },
          {
            label: this.$t('Job City'),
            value: additionalFilters.JobCity,
          },
          {
            label: this.$t('Job State'),
            value: additionalFilters.JobState,
          },
          {
            label: this.$t('Tax State'),
            value: additionalFilters.TaxState,
          },
          {
            label: this.$t('Tax District'),
            value: additionalFilters.TaxDistrict,
          },
          {
            label: this.$t('Certified Job'),
            value: additionalFilters.CertifiedJob,
          },
        ],
        additionalFilterComponents: {
          [additionalFilters.Category]: 'JobCategorySelect',
          [additionalFilters.Customer]: 'CustomerSelect',
          [additionalFilters.Employee]: 'EmployeeSelect',
          [additionalFilters.Union]: 'UnionSelect',
          [additionalFilters.JobCity]: 'BaseInput',
          [additionalFilters.JobState]: 'StateSelect',
          [additionalFilters.TaxState]: 'StateTaxSelect',
          [additionalFilters.TaxDistrict]: 'SalesTaxDistrictSelect',
          [additionalFilters.CertifiedJob]: 'BaseSwitch',
          default: '',
        },
      }
    },
    computed: {
      showSecondAdditionalFiltersSection() {
        return this.value[this.additionalFilter1]
      }
    },
    methods: {
      getFilterComponent(filterType) {
        return this.additionalFilterComponents[filterType] || ''
      },
      resetJobFilters() {
        this.value.job_ids = []
        this.value.job_number_from = this.value.job_number_to  = null
      },
      filteredAddlFilterOptions(filterNumberToOmit) {
        return this.additionalFilterOptions.filter(option => option.value !== this[`additionalFilter${filterNumberToOmit}`])
      },
      onChangeAddlFilter(filterNumberToOmit) {
        Object.values(additionalFilters).forEach(key => {
          if (this[`additionalFilter${filterNumberToOmit}`] !== key) {
            delete this.value[key]
          }
        })
      },
    },
  }
</script>
