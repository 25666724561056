<template>
  <JobCostInitForm/>
</template>
<script>
  import JobCostInitForm from '@/modules/job-costing/components/job-cost-init/JobCostInitForm'

  export default {
    components: {
      JobCostInitForm,
    },
  }
</script>
