<template>
  <div>
    <validation-warning
        v-if="hasPostedData"
        class="mt-1"
    >
      {{ $t('Periods cannot be changed because there are posted journals. You can still close periods.') }}
    </validation-warning>
    <base-tabs
        v-model="selectedFiscalYear"
        :tabs="activeFiscalYearTabs"
        label-key="value"
        class="mb-2"
    />
    <AgDataTable
        :key="selectedFiscalYear"
        :columns="columns"
        :data="filteredPeriods"
        :show-pagination="false"
        :pagination="false"
        domLayout="autoHeight"
        hide-actions="filters"
        class="col-span-6"
        ref="gridTable"
    >

      <template #value="{index}">
        {{ $t('Period ') }} {{ index + 1 }}
      </template>
    </AgDataTable>
  </div>
</template>
<script>
  import axios from 'axios'
  import format from 'date-fns/format'
  import orderBy from 'lodash/orderBy'
  import ValidationWarning from '@/components/common/ValidationWarning'

  function getPeriods() {
    const monthOptions = []
    for (let i = 0; i < 12; i++) {
      const date = new Date()
      date.setDate(1)
      date.setMonth(i)

      const label = format(new Date(date), 'MMMM')
      monthOptions.push({
        label: label,
        value: label,
      })
    }
    return monthOptions
  }

  export default {
    components: {
      ValidationWarning,
    },
    data() {
      return {
        loading: false,
        originalPeriods: getPeriods(),
        selectedFiscalYear: new Date().getFullYear(),
      }
    },
    computed: {
      companyFiscalYears() {
        return this.$store.state.company.fiscalYears
      },
      activeCompanyFiscalYears() {
        return this.companyFiscalYears.filter(fiscalYear => !fiscalYear.attributes?.closed_at)
      },
      activeFiscalYearTabs() {
        return this.activeCompanyFiscalYears.map(fiscalYear => {
          return {
            value: fiscalYear.attributes?.fiscal_year,
            name: fiscalYear.attributes?.fiscal_year,
          }
        })
      },
      periods() {
        const fiscalYear = this.companyFiscalYears.find(fiscalYear => fiscalYear.attributes?.fiscal_year === this.selectedFiscalYear) || { attributes: {} }
        return fiscalYear.attributes.periods || []
      },
      currentFiscalYearData() {
        return this.$store.state.company.currentFiscalYearData || []
      },
      selectedFiscalYearData() {
        return this.companyFiscalYears.find(fiscalYear => fiscalYear.attributes?.fiscal_year === this.selectedFiscalYear) || { attributes: {} }
      },
      selectedFiscalYearId() {
        return this.selectedFiscalYearData?.id || this.currentFiscalYearData?.id
      },
      filteredPeriods() {
        const periods = this.periods.filter(p => p.number !== 0 && p.number !== 13)
        return orderBy(periods, 'number').map(period => {
          return {
            _localId: crypto.randomUUID(),
            ...period,
          }
        })
      },
      columns() {
        return [
          {
            headerName: this.$t('Period'),
            field: 'value',
            maxWidth: 400,
          },
          {
            headerName: this.$t('Description'),
            field: 'name',
            cellEditor: this.$cellEditors.BaseSelect,
            cellEditorParams: {
              options: this.originalPeriods,
              onValueChanged: this.onChangePeriod,
            },
            valueFormatter: params => {
              const op = this.originalPeriods.find(o => o.name === params.value)
              return op?.name
            },
            editable: params => {
              return !this.hasPostedData
            },
          },
          {
            headerName: this.$t('Open'),
            field: 'is_open',
            maxWidth: 200,
            component: 'Status',
            cellEditor: this.$cellEditors.Boolean,
            cellEditorParams: {
              plain: true,
            },
            valueSetter: params => {
              this.updatePeriodStatus(params, params.newValue)
              return true
            },
            editable: params => {
              return true
            },
          },
        ]
      },
      hasPostedData() {
        return this.filteredPeriods.some(p => p.is_used || !p.is_open)
      },
    },
    methods: {
      getRowId() {
        return crypto.randomUUID()
      },
      /**
       * Generates period labels for all periods depending on the selected period.
       * E.g (If user selects "August" for period 1, the function will generate the list of periods [August, September, ... July]
       * @param period selected period
       * @param newValue of the current period
       * @return {[]} periods and their updated names
       */
      getUpdatedPeriods(period, newValue) {
        const oldIndex = period.number
        const originalIndex = this.originalPeriods.findIndex(p => p.value === newValue)
        const newPeriods = []
        const maxPeriod = 12
        for (let i = 1; i <= maxPeriod; i++) {
          const currentPeriod = this.periods.find(p => p.number === i)
          if (!currentPeriod) {
            continue
          }
          const periodToUpdate = {
            ...currentPeriod,
          }
          let newIndex = originalIndex - oldIndex + i
          if (newIndex < 0) {
            newIndex = maxPeriod + newIndex + i - 1
          }
          if (newIndex >= maxPeriod) {
            newIndex = newIndex - maxPeriod
          }
          const newName = this.originalPeriods[newIndex].label
          periodToUpdate.name = newName
          currentPeriod.name = newName
          currentPeriod.label = newName
          newPeriods.push(periodToUpdate)
        }
        return newPeriods
      },
      async onChangePeriod(params, newValue) {
        try {
          const period = params.data

          if (period.name === newValue) {
            return
          }
          params.data.loading = true

          const firstPeriod = this.getUpdatedPeriods(period, newValue)[0]

          await axios.post(`/restify/fiscal-years/${this.selectedFiscalYearId}/actions?action=sort-periods`, {
            first_month: firstPeriod.name,
          })
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t(`Could not update the periods `))
        } finally {
          params.data.loading = false
          this.$refs.gridTable.refresh()
        }
      },
      async updatePeriodStatus(params, newValue) {
        let period = params.data
        try {
          if (period.is_open === newValue) {
            return
          }

          params.data.loading = true
          period.is_open = newValue
          await axios.post(`/restify/fiscal-years/${this.selectedFiscalYearId}/actions?action=toggle-period-status`, {
            period: period.number,
          })
          this.$set(period, 'is_open', newValue)
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t(`Could not update period ${period.label}`))
        } finally {
          params.data.loading = false
        }
      },
    },
    created() {
      this.selectedFiscalYear = this.$currentFiscalYear
    },
  }
</script>
