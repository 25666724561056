<template>
  <SourceLink
      :id="sourceId"
      :data="row"
      :target="params?.target"
      :show-description="params.showDescription"
      :is-link="!editableCell"
  />
</template>
<script>
  import SourceLink from "@/components/links/SourceLink";

  export default {
    components: {
      SourceLink,
    },
    props: {
      column: {
        type: Object,
        default: () => ({}),
      },
      row: {
        type: Object,
        default: () => ({}),
      },
      params: {
        type: Object,
        default: () => ({}),
      },
      editableCell: Boolean,
    },
    computed: {
      sourceId() {
        return this.get(this.row, 'attributes.source_id') || this.get(this.row, 'source_id')
      },
    }
  }
</script>
