<template>
  <div class="report-page mb-4">
    <BaseReportHeader
        :header="reportHeader"
        class="my-4 leading-tight tracking-wide font-semibold text-gray-700 text-xs border rounded border-gray-200 p-4 bg-gray-50"
    />
    <AgDataTable
        :key="data.id"
        :url="url"
        :url-params="urlParams"
        :columns="columns"
        :pagination="false"
        :show-pagination="false"
        :groupDefaultExpanded="-1"
        :groupIncludeFooter="true"
        :suppressAggFuncInHeader="true"
        :groupIncludeTotalFooter="true"
        :groupRowRendererParams="groupRowRendererParams"
        :compact="true"
        :no-borders="true"
        :perPage="1000"
        groupDisplayType="groupRows"
        dom-layout="autoHeight"
    >

      <template #attributes.job_id="{row}">
        <JobLink
            :id="get(row, 'attributes.job_id')"
            :show-preview="false"
        />
      </template>

      <template #attributes.job_type_id="{row}">
        <JobTypeLink :id="get(row, 'attributes.job_type_id')"/>
      </template>

      <template #attributes.line_item_id="{row}">
        <LineItemLink :id="get(row, 'attributes.line_item_id')"/>
      </template>

      <template #attributes.business_id="{row}">
        <component
            v-if="get(row, 'attributes.business_id')"
            :id="row.attributes.business_id"
            :is="getBusinessComponent(row.attributes.business_type)"
            :show-preview="false"
        />
        <span v-else/>
      </template>
    </AgDataTable>
  </div>
</template>
<script>
  import { costTypes, transactionBusinessLinkByTypes } from '@/enum/enums'
  import ProofListingGroupRow from '@/modules/job-costing/pages/settings/job-cost-init/ProofListingGroupRow'

  export default {
    name: 'JobCostInitProofListing',
    components: {
      ProofListingGroupRow
    },
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        rows: [],
      }
    },
    computed: {
      isCostType() {
        const type = this.get(this.data, 'attributes.type', costTypes.Cost)
        return type === costTypes.Cost
      },
      groupRowRendererParams() {
        return {
          innerRenderer: 'ProofListingGroupRow',
        }
      },
      filters() {
        const { period, fiscal_year } = this.data?.attributes || {}
        return this.isCostType ? `${this.$t('Job Costs & Retention thru end of Fiscal Period')} ${period} ${fiscal_year}`
            : `${this.$t('Job Billings & Retention thru end of Fiscal Period')} ${period} ${fiscal_year}`
      },
      reportHeader() {
        return {
          title: this.isCostType ? this.$t('Initialize Job Costs / Retentions - Proof Listing') : this.$t('Initialize Job Billings / Retentions - Proof Listing'),
          company: {
            name: this.$currentCompany?.name,
          },
          code: this.isCostType ? '29.94A' : '29.94B',
          filters: [this.filters],
        }
      },
      url() {
        return this.data.id ? '/restify/job-costing-init-entries' : ''
      },
      urlParams() {
        return this.data.id ? { job_costing_init_id: this.data.id } : {}
      },
      columns() {
        return [
          {
            label: this.$t('Job'),
            prop: 'attributes.job_id',
            minWidth: 100,
            maxWidth: 140,
            rowGroup: true,
            hide: true,
          },
          {
            headerName: ' ',
            field: 'empty_column',
            minWidth: 30,
            maxWidth: 50,
          },
          {
            label: this.$t('Type'),
            prop: 'attributes.job_type_id',
            component: this.$tableColumns.TypeLink,
            minWidth: 120,
            maxWidth: 150,
          },
          {
            label: this.$t('Line Item'),
            prop: 'attributes.line_item_id',
            minWidth: 200,
            maxWidth: 250,
          },
          {
            label: this.isCostType ? this.$t('Vendor') : this.$t('Customer'),
            prop: 'attributes.business_id',
            align: 'right',
            minWidth: 200,
            maxWidth: 250,
          },
          {
            label: this.$t('Quantity / Hours'),
            prop: 'attributes.quantity',
            align: 'right',
            minWidth: 100,
            maxWidth: 140,
            cellRendererParams: {
              hideZero: true,
            },
          },
          {
            label: this.isCostType ? this.$t('Job Cost Amount') : this.$t('Job Billed Amount'),
            prop: 'attributes.amount',
            component: 'FormattedPrice',
            align: 'right',
            minWidth: 120,
            aggFunc: 'sum',
            cellRendererParams: {
              hideZero: true,
            },
          },
          {
            label: this.isCostType ? this.$t('Retention Payable') : this.$t('Retention Receivable'),
            prop: 'attributes.retention_amount',
            component: 'FormattedPrice',
            align: 'right',
            minWidth: 120,
            aggFunc: 'sum',
            cellRendererParams: {
              hideZero: true,
            },
          },
        ]
      },
    },
    methods: {
      getBusinessComponent(businessType) {
        return transactionBusinessLinkByTypes[businessType]
      },
    },
  }
</script>
