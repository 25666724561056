<template>
  <div class="flex items-center space-x-2">
    <base-dropdown :tooltip-content="$t('Manage Columns')"
                   :menu-classes="{
                    'manage-columns-menu columns-dropdown': true,
                    'less-data': this.data.length < 10
                   }"
    >
      <template v-slot:trigger>
        <button @click="toggleDropdown"
                ref="button"
                class="inline-flex items-center justify-center w-full bg-transparent text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
               fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
               class="h-5 w-5 feather feather-columns">
            <path data-v-7d0c7aee=""
                  d="M12 3h7a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-7m0-18H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h7m0-18v18">
            </path>
          </svg>
          <svg class="h-5 w-5"
               fill="currentColor"
               viewBox="0 0 20 20">
            <path fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"/>
          </svg>
        </button>
      </template>

      <div class="rounded-md bg-white shadow-xs relative z-10">
        <div v-for="(column, index) in displayedColumns"
             :key="index"
             :tabindex="0"
             class="h-10 py-1 text-gray-700 border-b px-4 hover:bg-primary-100 cursor-pointer flex justify-between items-center"
             @click="updateColumn(column, true)"
        >
          <base-checkbox v-model="column.checked"
                         :label="column.label"
                         @input="updateColumn(column)">
            <template v-slot:label>
              <div v-html="column.label"
                  class="w-32 inline-flex text-sm text-gray-900 font-medium capitalize"
              />
            </template>
          </base-checkbox>
        </div>
      </div>
    </base-dropdown>
    <base-tooltip class="column-reorder-button"
                  :class="{'drag-enabled': isDragEnabled}"
                  :content="getDragTooltipContent">
      <base-button variant="gray-light"
                   size="xs"
                   class="hidden md:flex"
                   @click="toggleColumnDrag"
      >
        <MoveIcon v-if="!isDragEnabled" class="w-3 h-3"></MoveIcon>
        <CheckIcon v-else class="w-3 h-3"></CheckIcon>
      </base-button>
    </base-tooltip>
  </div>
</template>

<script>
  import { CheckIcon, MoveIcon } from 'vue-feather-icons'
  import cloneDeep from "lodash/cloneDeep";
  import Sortable from "sortablejs";

  export default {
    name: 'manage-columns',
    components: {
      MoveIcon,
      CheckIcon,
    },
    props: {
      columns: {
        type: Array,
        default: () => [],
      },
      visibleColumns: {
        type: Array,
        default: () => [],
      },
      tableKey: {
        type: String,
        default: '',
      },
      isExpandable: {
        type: Boolean,
        default: false,
      },
      tableRef: {
        type: Object,
        default: () => ({}),
      },
      addDefaultColumns: {
        type: Boolean,
        default: true,
      },
      data: {
        type: Array,
        default: () => [],
      }
    },
    data() {
      return {
        showDropdown: false,
        dropdownPosition: {
          top: 0,
          right: 0,
        },
        columnsData: []
      }
    },
    computed: {
      displayedColumns() {
        return this.columnsData.filter(c => c.label)
      },
      isDragEnabled() {
        return this.visibleColumns.some(c => c.showDrag)
      },
      getDragTooltipContent() {
        if (this.isDragEnabled) {
          return this.$t('Save column order')
        }
        return this.$t('Click to enable column reorder')
      }
    },
    methods: {
      toggleDropdown() {
        const ref = this.$refs.button
        if (ref) {
          const boundingRect = ref.getBoundingClientRect()
          this.dropdownPosition = {
            top: `${boundingRect.top + 50}px`,
            left: `${boundingRect.left - 100}px`,
          }
        }
        this.showDropdown = !this.showDropdown
      },
      updateColumn(column, shouldUpdate = false) {
        if (shouldUpdate) {
          column.checked = !column.checked
        }
        let availableColumns = this.columnsData.filter(el => el.checked);
        this.$store.commit('table/SET_VISIBLE_COLUMNS', {
          table: this.tableKey,
          columns: availableColumns
        })
        this.$emit('update-columns', availableColumns)
      },
      initData() {
        if (this.addDefaultColumns) {
          const updatedAt = {
            prop: 'attributes.updated_at',
            label: 'Updated At',
            minWidth: 100,
            maxWidth: 150,
            component: 'FormattedDate',
            checked: false,
          }
          const updatedAtCol = this.columns.find(c => c.prop === updatedAt.prop)
          if (!updatedAtCol) {
            this.columns.push(updatedAt)
          }
        }
        this.columnsData = this.columns
            .map(column => {
              let selected = this.visibleColumns.find(el => el.prop === column.prop)
              column.checked = !!selected
              return column
            })
      },
      onMenuClickOutside() {
        this.showDropdown = false
      },
      toggleColumnDrag() {
        this.visibleColumns.forEach(column => {
          this.$set(column, 'showDrag', !column.showDrag)
        })
      },
      initSortable() {
        if (!this.tableRef) {
          return
        }
        const table = this.tableRef.$el.querySelector('table thead tr')
        if (!table) {
          return
        }
        Sortable.create(table, {
          group: 'description',
          filter: '.non-draggable',
          dragClass: ".table-header-drag",
          fallbackOnBody: true,
          animation: 150,
          onStart: function (evt) {
            if (!evt.oldIndex) {
              evt.preventDefault()
              return
            }
            return evt.oldIndex;
          },
          onMove(event) {
            if (event.related && event.related.classList.contains('non-draggable')) {
              return false
            }
          },
          onEnd: async ({ newIndex, oldIndex }) => {
            let clonedColumns = cloneDeep(this.visibleColumns)
            if (this.isExpandable) {
              newIndex--
              oldIndex--
            }
            const targetRow = clonedColumns.splice(oldIndex, 1)[0]
            if (!targetRow) {
              return
            }
            clonedColumns.splice(newIndex, 0, targetRow);
            clonedColumns = clonedColumns.filter(col => col !== undefined)
            clonedColumns.map((col, index) => {
              col.order = index
            })
            this.$store.commit('table/SET_VISIBLE_COLUMNS', {
              table: this.tableKey,
              columns: clonedColumns
            })
            this.$emit('update-columns', clonedColumns)
          },
        })
      }
    },
    watch: {
      visibleColumns() {
        this.initData()
      },
    },
    mounted() {
      this.initData()
      this.initSortable()
    },
  }
</script>
<style>
  .manage-columns-menu {
    max-height: 300px;
    @apply overflow-y-auto;
  }
  .manage-columns-menu.less-data {
    @apply fixed;
  }
  .column-reorder-button {
    margin-top: -2px;
    transition: opacity 0.15s;
    @apply opacity-0;
  }

  .column-reorder-button.drag-enabled,
  .actions-header:hover .column-reorder-button {
    @apply opacity-100;
  }
</style>
