<template>
  <div>
    <router-link v-if="data.id" :to="billingLink">
      <slot>
        {{ number }}
        <StatusBadge v-if="data.status && showStatus" :status="data.status"></StatusBadge>
      </slot>
    </router-link>
    <span v-else>
      {{ number }}
    </span>
  </div>
</template>
<script>
import { getBillingType } from "@/modules/accounts-receivable/pages/billings/billings";

export default {
  name: 'BillingLink',
  props: {
    id: String,
    data: {
      type: Object,
      default: () => ({}),
    },
    showStatus: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    billingLink() {
      const id = this.data?.id || this.id
      const typePath = getBillingType(this.data)
      return `/accounts-receivable/billings/${typePath}/${id}/view`
    },
    number() {
      return this.data?.attributes?.number || this.data?.number || ''
    },
  }
}
</script>
