<template>
  <div
    v-loading="loading"
    class="flex items-center space-x-2"
    @click="validate"
  >
    <div v-if="!alreadyProcessed"
         class="flex space-x-2 items-center">
      <BaseUploadButton
        accept="image/*,application/pdf"
        class="print:hidden"
        @input="onFileSelect"
      >
        {{ $t('Upload statement for Automated Reconciliation (beta)') }}
      </BaseUploadButton>
      <base-tooltip :tabindex="-1">
        <template #content>
          <div class="max-w-[320px]">
            {{
              $t('This feature allows uploading a PDF or an image of a bank statement which will be automatically scanned and processed in order to extract the transaction information and populate the form automatically. Note that this feature is experimental and might be inconsistent. Please validate all the data against the original file.')
            }}
          </div>
        </template>
        <HelpCircleIcon class="ml-2 w-4 h-4 text-gray-500 hover:text-gray-700 cursor-help"/>
      </base-tooltip>
    </div>
    <div v-else class="flex items-start space-x-2">
      <BaseAlert>
        {{ $t('This reconciliation was already processed with AI. You can reprocess the transactions again.') }}
      </BaseAlert>
      <BaseButton
        :loading="reprocessing"
        class="mt-2"
        variant="white"
        @click="reprocessTransactions">
        {{ $t('Reprocess') }}
      </BaseButton>
    </div>
    <BaseFormDialog
      v-if="showProcessingDialog"
      :title="$t('Processing bank statement')"
      :open.sync="showProcessingDialog"
      :append-to-body="true"
      size="sm"
    >
      <div class="min-h-[200px] px-8 py-10 space-y-4">
        <div
          v-for="step in processingSteps"
          :key="step.title"
          class="flex items-center space-x-2"
        >
          <CircleIcon
            v-if="step.status === ProcessingStep.Pending"
            class="w-4 h-4 text-gray-400"
          />
          <LoaderIcon
            v-else-if="step.status === ProcessingStep.Loading"
            class="spin-animation w-4 h-4"
          />
          <CheckCircleIcon
            v-else-if="step.status === ProcessingStep.Success"
            class="w-4 h-4 text-green-400"
          />
          <XCircleIcon
            v-else-if="step.status === ProcessingStep.Error"
            class="w-4 h-4 text-red-400"
          />
          <span class="text-gray-600 text-lg">{{ step.title }}</span>
        </div>
      </div>
    </BaseFormDialog>
    <div class="flex flex-col space-y-6">
      <PendingFileInfo
        v-for="(file, index) in selectedFiles"
        :key="file?.name || index"
        :file="file"
        :file-name="file?.name"
        :can-remove="true"
        :show-preview-on-click="true"
        @close="removeFile(index)"
      />
    </div>
  </div>
</template>
<script>
import PendingFileInfo from "@/modules/common/components/documents/PendingFileInfo.vue";
import axios from "axios";
import format from "date-fns/format";
import { CheckCircleIcon, CircleIcon, HelpCircleIcon, LoaderIcon, XCircleIcon } from "vue-feather-icons";
import { delay } from "@/utils/utils";
import { parseCsvFile } from "@/modules/common/util/csvUtils";

const ProcessingStep = {
  Pending: 'pending',
  Loading: 'loading',
  Success: 'success',
  Error: 'error',
}
export default {
  components: {
    HelpCircleIcon,
    PendingFileInfo,
    CheckCircleIcon,
    XCircleIcon,
    LoaderIcon,
    CircleIcon,
  },
  props: {
    account: {
      type: String,
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false,
      reprocessing: false,
      showProcessingDialog: false,
      selectedFiles: [],
      ProcessingStep,
      processingSteps: this.getInitialSteps(),
    }
  },
  computed: {
    alreadyProcessed() {
      return this.data?.meta?.parsed_csv_file_id
    }
  },
  methods: {
    getInitialSteps() {
      return [
        {
          title: this.$t('Uploading bank statement to server'),
          status: ProcessingStep.Loading,
        },
        {
          title: this.$t('Processing bank statement'),
          status: ProcessingStep.Pending,
        },
        {
          title: this.$t('Extracting transactions from bank statement'),
          status: ProcessingStep.Pending,
        },
        {
          title: this.$t('Applying matching transactions'),
          status: ProcessingStep.Pending,
        }
      ]
    },
    processingFailed() {
      return this.processingSteps.some(step => step.status === ProcessingStep.Error) || !this.loading
    },
    setCurrentStepAsFailed() {
      this.selectedFiles = []
      const loadingStepIndex = this.processingSteps.findIndex(step => step.status === ProcessingStep.Loading)
      if (loadingStepIndex === -1) {
        return
      }
      const step = this.processingSteps[loadingStepIndex]
      this.processingSteps.splice(loadingStepIndex, 1, {
        ...step,
        status: ProcessingStep.Error
      })
    },
    async simulateProcessing() {
      this.showProcessingDialog = true
      await delay(5000)
      if (this.processingFailed()) {
        return
      }
      this.processingSteps[0].status = ProcessingStep.Success
      this.processingSteps[1].status = ProcessingStep.Loading
      await delay(20000)
      if (this.processingFailed()) {
        return
      }
      this.processingSteps[1].status = ProcessingStep.Success
      this.processingSteps[2].status = ProcessingStep.Loading
      await delay(30000)
      if (this.processingFailed()) {
        return
      }
      this.processingSteps[2].status = ProcessingStep.Success
      this.processingSteps[3].status = ProcessingStep.Loading
    },
    updateAllProcessingSteps() {
      this.processingSteps = this.processingSteps.map(step => {
        return {
          ...step,
          status: ProcessingStep.Success
        }
      })
      this.showProcessingDialog = false
    },
    resetProcessingSteps() {
      this.processingSteps = this.getInitialSteps()
      this.showProcessingDialog = false
    },
    validate(event) {
      if (!this.account) {
        this.$error(this.$t('Please select an account first'))
        event.preventDefault()
        return
      }
    },
    onFileSelect(event) {
      this.selectedFiles = Array.from(event.target.files) || [];
      const file = this.selectedFiles[0]
      if (!file) {
        return
      }
      this.processFile(file)
    },
    removeFile(index) {
      this.selectedFiles.splice(index, 1);
    },
    async processFile(file) {
      if (!file) {
        return
      }
      try {
        this.loading = true
        this.$info(this.$t('Statement is being processed. Please wait as this might take a while...'))
        const form = new FormData
        form.append('files[]', file)
        form.append('type', 'bank-statement')
        this.simulateProcessing().then(() => {
          // done un purpose to simulate the processing which can take from 30s up to 2mins or more.
        })
        let res = await axios.post(`/ai/vision`, form)
        const response = res.data
        this.updateAllProcessingSteps()
        if (response.error) {
          this.$error(response.message)
          return
        }
        this.$success(this.$t('Statement processed successfully. Filling form with data...'))
        const model = {}
        try {
          /**
           * The AI parsing uses very short property names to reduce the output size
           * Field mappings below
           * b -> balance
           * d -> date
           * t -> transactions
           */
          model.date = response.d ? format(response.d, 'yyyy-MM-dd') : model.date
        } catch (err) {
          console.log(err)
        }
        model.bank_balance_amount = response.b ? response.b : model.balance
        const transactions = response.t || []
        this.$emit('parsed', model, transactions, file)
      } catch (err) {
        this.setCurrentStepAsFailed()
        if (err.handled) {
          return
        }
        this.$error(this.$t('Could not process the provided file.'))
      } finally {
        this.resetProcessingSteps()
        this.loading = false
      }
    },
    parseTransactionsCsv(csvData) {
      const headers = csvData[0]
      const data = []
      for (let i = 1; i < csvData.length; i++) {
        const row = csvData[i]
        const transaction = {}
        for (let j = 0; j < headers.length; j++) {
          const key = headers[j]
          let value = row[j]
          if (key === 'reconciled') {
            value = (value?.toLowerCase() === 'true' || value?.toLowerCase() === 'yes')
          }
          transaction[headers[j]] = value
        }
        data.push(transaction)
      }
      return data
    },
    mapTransactionsToCompactFormat(transactions) {
      return transactions.map(t => {
        const typeMap = {
          credit: 'c',
          debit: 'c',
        }
        return {
          d: t.date,
          a: +t.amount,
          r: t.reference,
          t: typeMap[t.type],
          reconciled: t.reconciled,
        }
      })
    },
    async reprocessTransactions() {
      try {
        this.reprocessing = true
        const fileId = this.data?.meta?.parsed_csv_file_id
        const data = await axios.get(`/media/${fileId}`, {
          responseType: 'blob',
        })
        const { results, err } = await parseCsvFile(data)
        const transactions = this.parseTransactionsCsv(results.data)
        const compactTransactions = this.mapTransactionsToCompactFormat(transactions)
        this.$emit('reprocess', compactTransactions)
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(this.$t('Could not reprocess the transactions.'))
      } finally {
        this.reprocessing = false
      }
    }
  },
}
</script>
