<template>
  <div
      v-if="remainingAmount !== 0"
      class="flex items-center text-sm"
  >
    <base-tooltip :tabindex="-1"
                  :content="$t(`Gross total amount should by equal with ${batchName} amount.`)">
      <div class="flex items-center cursor-help font-semibold"
           :class="remainingAmount < 0 ? 'text-red-500' : ''">
        <slot>
          <IconWarning class="w-5 h-5 mr-1"/>
          <span v-if="remainingAmount < 0">
          {{ $t(`Gross total exceeds ${batchName} amount `) }}: {{ $formatPrice(differenceAmounts) }}
          </span>
            <span v-else>
            {{ $t(`Gross total is less than ${batchName} amount `) }}: {{ $formatPrice(differenceAmounts) }}
          </span>
        </slot>
      </div>
    </base-tooltip>
  </div>
</template>
<script setup>

  import { computed } from 'vue'
  import { round } from "lodash";

  const props = defineProps({
    batchAmount: {
      type: [Number, String],
      default: 0,
      required: true,
    },
    entriesAmount: {
      type: Number,
      default: 0,
      required: true,
    },
    batchName: {
      type: String,
      default: 'invoice',
      required: true,
    },
  })

  const remainingAmount = computed(() => {
    return Number(props.batchAmount) - Number(props.entriesAmount)
  })

  const differenceAmounts = computed(() => {
    const amount = remainingAmount
    return Math.abs(amount.value)
  })

</script>
