<template>
  <div v-if="show"
       class="rounded-md px-4 bg-orange-50 shadow p-4 mx-auto mt-4 print:hidden">
    <div class="flex">
      <div class="flex-shrink-0">
        <svg class="h-5 w-5 text-orange-400" fill="currentColor" viewBox="0 0 20 20">
          <path fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clip-rule="evenodd"/>
        </svg>
      </div>
      <div class="ml-3 flex-1 md:flex md:justify-between">
        <p v-if="isEmployeeConsentRequired" class="text-sm leading-5 text-orange-700">
          {{ $t(`Before sending ${resourceName}, fill in employee emails and send them a consent email`) }}
        </p>
        <p v-else class="text-sm leading-5 text-orange-700">
          {{ $t(`Before sending ${resourceName}, fill in employee emails`) }}
        </p>
        <p class="mt-3 text-sm leading-5 md:mt-0 md:ml-6" @click="goToEmployees">
          <a href="javascript:;"
             class="whitespace-nowrap font-medium text-orange-700 hover:text-orange-600 transition ease-in-out duration-150">
            {{ $t('Go to employees listing') }} &rarr;
          </a>
        </p>
      </div>
      <div class="ml-auto pl-3">
        <div
            :title="$t('Dismiss this alert message')"
            class="-mx-1.5 -my-1.5">
          <button type="button"
                  class="inline-flex bg-orange-50 rounded-md p-1.5 text-orange-500 hover:bg-orange-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-orange-50 focus:ring-orange-600"
                  @click="dismissAlert"
          >
            <span class="sr-only">{{ $t('Dismiss') }}</span>
            <XIcon class="h-5 w-5 text-orange-700" aria-hidden="true"/>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { XIcon } from 'vue-feather-icons'

  export default {
    components: {
      XIcon,
    },
    props: {
      isEmployeeConsentRequired: {
        type: Boolean,
        default: false,
      },
      resourceName: {
        type: String,
        default: 'checks'
      }
    },
    data() {
      return {
        show: localStorage.getItem('hideEmailConsentAlert') !== 'true'
      }
    },
    methods: {
      goToEmployees() {
        this.$router.push('/payroll/employees')
      },
      dismissAlert() {
        localStorage.setItem('hideEmailConsentAlert', 'true')
        this.show = false
      }
    }
  }
</script>
