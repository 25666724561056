import EmptyLayout from "@/components/common/EmptyLayout";
import WorksitesList from "@/modules/service-billing/pages/worksites/worksites-list";
import WorksiteAdd from "@/modules/service-billing/pages/worksites/worksite-add";
import WorkSiteLayout from "@/modules/service-billing/layout/WorkSiteLayout";
import WorksiteEdit from "@/modules/service-billing/pages/worksites/worksite-edit";
import WorksiteDetails from "@/modules/service-billing/pages/worksites/worksite-details";
import WorksiteWorkOrders from "@/modules/service-billing/pages/worksites/worksite-work-orders";
import WorkOrdersList from "@/modules/service-billing/pages/work-orders/work-orders-list";
import WorkOrderAdd from "@/modules/service-billing/pages/work-orders/work-order-add";
import WorkOrderLayout from "@/modules/service-billing/layout/WorkOrderLayout";
import WorkOrderEdit from "@/modules/service-billing/pages/work-orders/work-order-edit";
import WorkOrderDetails from "@/modules/service-billing/pages/work-orders/work-order-details";
import WorkOrderBillings from "@/modules/service-billing/pages/work-orders/work-order-billings.vue";
import ServiceBillingInvoiceBatches
  from "@/modules/service-billing/pages/service-billing-invoices/service-billing-invoice-batches";
import ServiceInvoicesList from "@/modules/service-billing/pages/service-invoices/service-invoices-list";
import ServiceInvoiceAdd from "@/modules/service-billing/pages/service-invoices/service-invoice-add";
import ServiceInvoiceLayout from "@/modules/service-billing/layout/ServiceInvoiceLayout";
import ServiceInvoiceEdit from "@/modules/service-billing/pages/service-invoices/service-invoice-edit";
import ServiceInvoiceDetails from "@/modules/service-billing/pages/service-invoices/service-invoice-details";
import ServiceBillingInvoices from "@/modules/service-billing/pages/service-billing-invoices/service-billing-invoices";
import WorkOrderReviewStatus from "@/modules/service-billing/pages/work-orders/work-order-review-status";
import SettingsLayout from "@/modules/service-billing/layout/SettingsLayout";
import ServiceBillingsSettings from "@/modules/service-billing/pages/settings/service-billings-settings";
import ServiceBillingCodesList from "@/modules/service-billing/pages/settings/service-billing-codes-list";
import CategoriesList from "@/modules/service-billing/pages/settings/categories-list";
import CategoryAdd from "@/modules/service-billing/pages/settings/category-add";
import CategoryEdit from "@/modules/service-billing/pages/settings/category-edit";
import SBJournals from '@/modules/common/components/journal/journal'
import SBJournalDetail from '@/modules/common/components/journal/journal-details'
import ServiceInvoicesLayout from '@/modules/service-billing/layout/ServiceInvoicesLayout'
import { $modules, resourceStatuses, workOrderStatuses } from "@/enum/enums";
import SettingsActivityLog from "@/modules/common/components/SettingsActivityLog.vue";
import WorkOrdersLayout from "@/modules/service-billing/layout/WorkOrdersLayout.vue";
import WorkOrderInvoices from "@/modules/service-billing/pages/work-orders/work-order-invoices.vue";
import WorkOrderPurchaseOrders from "@/modules/service-billing/pages/work-orders/work-order-purchase-orders.vue";
import WorkOrderTransactions from "@/modules/service-billing/pages/work-orders/work-order-transactions.vue";
import { RestifyResources } from "@/components/form/util";
import DocumentsTable from "@/modules/common/components/documents/DocumentsTable.vue";
import ReportLayout from "@/modules/service-billing/layout/ReportLayout.vue";
import ServiceBillingHistory from "@/modules/service-billing/pages/reports/service-billing-history.vue";
import SelectCostForBilling from "@/modules/service-billing/pages/service-invoices/select-cost-for-billing.vue";
import WorkOrderListing from "@/modules/service-billing/pages/reports/work-order-listing.vue";
import WorkOrderDetailsReport from "@/modules/service-billing/pages/reports/work-order-detail.vue";
import WorkOrderProfitSummary from "@/modules/service-billing/pages/reports/work-order-profit-summary.vue";
import WorkOrderTimesheets from "@/modules/service-billing/pages/work-orders/work-order-timesheets.vue";

const reportRoutes = [
  {
    path: 'service-billing-history',
    name: 'Service Billing History',
    title: 'Service Billing History',
    meta: {
      permissions: ['service_billing_reports'],
      appScreen: 'Service Billing History: 58.84',
    },
    component: ServiceBillingHistory,
  },
  {
    path: 'work-order-detail',
    name: 'Work Order Details',
    title: 'Work Order Details',
    meta: {
      permissions: ['service_billing_reports'],
      appScreen: 'Work Order Listing: 58.81',
    },
    component: WorkOrderDetailsReport,
  },
  {
    path: 'work-order-listing',
    name: 'Work Order Listing',
    title: 'Work Order Listing',
    meta: {
      permissions: ['service_billing_reports'],
      appScreen: 'Work Order Listing: 58.82',
    },
    component: WorkOrderListing,
  },
  {
    path: 'work-order-profit-summary',
    name: 'Work Order Profit Summary',
    title: 'Work Order Profit Summary',
    meta: {
      permissions: ['service_billing_reports'],
      appScreen: 'Work Order Profit Summary: 58.83',
    },
    component: WorkOrderProfitSummary,
  },
]
export default [
  {
    path: '/service-billing',
    name: 'Service Billing',
    component: EmptyLayout,
    redirect: '/service-billing/service-billing-invoice-batches',
    meta: {
      permissions: ['service_billing_show'],
      productionReady: true,
      isModule: true,
    },
    children: [
      {
        path: 'worksites',
        name: 'Worksites',
        title: 'Worksites',
        description: 'Define address and billing information for service billing worksites.',
        meta: {
          permissions: ['worksite_show'],
          appScreen: 'S/B Worksites: 55.10',
        },
        component: WorksitesList,
      },
      {
        path: 'worksites/add',
        name: 'Add Worksite',
        title: 'Add Worksite',
        meta: {
          permissions: ['worksite_store'],
          appScreen: 'S/B Worksites: 55.10',
        },
        component: WorksiteAdd,
      },
      {
        path: 'worksites/:id',
        name: 'Worksite Details ',
        redirect: '/service-billing/worksites',
        meta: {
          permissions: ['worksite_show'],
        },
        component: WorkSiteLayout,
        children: [
          {
            path: 'edit',
            name: 'Worksite Edit',
            meta: {
              permissions: ['worksite_update'],
              appScreen: 'S/B Worksites: 55.10',
              resourceName: 'work-sites',
            },
            component: WorksiteEdit,
          },
          {
            path: 'view',
            name: 'Worksite Details',
            meta: {
              permissions: ['worksite_show'],
              appScreen: 'S/B Worksites: 55.10',
              resourceName: 'work-sites',
            },
            component: WorksiteDetails,
          },
          {
            path: 'documents',
            name: 'Worksite Documents',
            meta: {
              permissions: ['worksite_documents_show'],
              resourceName: RestifyResources.WorkSites,
            },
            component: DocumentsTable,
          },
          {
            path: 'work-orders',
            name: 'Worksite Work Orders',
            meta: {
              permissions: ['worksite_show'],
              resourceName: 'work-sites',
            },
            component: WorksiteWorkOrders,
          },
        ]
      },
      {
        path: 'work-orders',
        name: 'Work Orders',
        description: 'Document work to be performed by a Work Order.',
        meta: {
          permissions: ['work_orders_show'],
          appScreen: 'S/B Work Orders: 55.20',
        },
        redirect: '/service-billing/work-orders/open',
        component: WorkOrdersLayout,
        children: [
          {
            path: 'open',
            name: 'Open Work Orders',
            title: 'Open Work Orders',
            meta: {
              status: workOrderStatuses.Open
            },
            component: WorkOrdersList
          },
          {
            path: 'billed',
            name: 'Billed Work Orders',
            title: 'Billed Work Orders',
            meta: {
              billed: true,
            },
            component: WorkOrdersList
          },
          {
            path: 'unbilled',
            name: 'Unbilled Work Orders',
            title: 'Unbilled Work Orders',
            meta: {
              billed: false,
            },
            component: WorkOrdersList
          },
          {
            path: 'closed',
            name: 'Closed Work Orders',
            title: 'Closed Work Orders',
            meta: {
              status: workOrderStatuses.Closed
            },
            component: WorkOrdersList
          },
          {
            path: 'recurring',
            name: 'Recurring Work Orders',
            title: 'Recurring Work Orders',
            meta: {
              status: workOrderStatuses.Recurring
            },
            component: WorkOrdersList
          },
          {
            path: 'completed',
            name: 'Completed Work Orders',
            title: 'Completed Work Orders',
            meta: {
              status: workOrderStatuses.Completed
            },
            component: WorkOrdersList
          }
        ]
      },
      {
        path: 'work-orders/add',
        name: 'Add Work Order',
        title: 'Add Work Order',
        meta: {
          permissions: ['work_orders_store'],
        },
        component: WorkOrderAdd,
      },
      {
        path: 'work-orders/:id',
        name: 'Work Orders ',
        redirect: '/service-billing/work-orders',
        meta: {
          permissions: ['work_orders_show'],
        },
        component: WorkOrderLayout,
        children: [
          {
            path: 'edit',
            name: 'Work Order Edit',
            meta: {
              permissions: ['work_orders_update'],
              appScreen: 'S/B Work Orders: 55.20',
              resourceName: 'work-orders',
            },
            component: WorkOrderEdit,
          },
          {
            path: 'view',
            name: 'Work Order Details',
            meta: {
              permissions: ['work_orders_update'],
              appScreen: 'S/B Work Orders: 55.20',
              resourceName: 'work-orders',
            },
            component: WorkOrderDetails,
          },
          {
            path: 'billings',
            name: 'Work Order Billings',
            meta: {
              permissions: ['billings_show'],
              resourceName: 'work-orders',
            },
            component: WorkOrderBillings,
          },
          {
            path: 'invoices',
            name: 'Work Order Invoices',
            meta: {
              permissions: ['invoices_show'],
              resourceName: 'work-orders',
            },
            component: WorkOrderInvoices,
          },
          {
            path: 'purchase-orders',
            name: 'Work Order Purchase Orders',
            meta: {
              permissions: ['purchase_orders_show'],
              resourceName: 'work-orders',
            },
            component: WorkOrderPurchaseOrders,
          },
          {
            path: 'timesheets',
            name: 'Work Order Timesheets',
            meta: {
              permissions: ['timesheets_approve'],
              resourceName: 'work-orders',
            },
            component: WorkOrderTimesheets,
          },
          {
            path: 'transactions',
            name: 'Work Order Transactions',
            meta: {
              permissions: ['transaction_show'],
              resourceName: 'work-orders',
            },
            component: WorkOrderTransactions,
          },
        ]
      },
      {
        path: 'select-cost-for-billing',
        name: 'Select Costs For Billing',
        title: 'Select Costs For Billing',
        description: 'Select costs for billing based on work order transactions.',
        meta: {
          permissions: ['billings_store'],
          appScreen: 'S/B Select Costs For Billing: 55.30',
        },
        component: SelectCostForBilling,
      },
      {
        path: 'service-billing-invoice-batches',
        name: 'Service Invoice Batches',
        title: 'Service Invoice Batches',
        description: 'Quickly access send or download Service Invoices',
        meta: {
          permissions: ['billings_show'],
          productionReady: true,
        },
        component: ServiceBillingInvoiceBatches,
      },
      {
        path: 'service-invoices',
        name: 'Service Invoices',
        redirect: '/service-billing/service-invoices/open',
        component: ServiceInvoicesLayout,
        meta: {
          permissions: ['billings_show'],
          appScreen: 'S/B Work Orders: 55.50',
        },
        children: [
          {
            path: 'pending',
            name: 'Pending Service Invoices',
            title: 'Pending Service Invoices',
            description: 'Provide data entry of billing detail for Service invoices.',
            meta: {
              permissions: ['billings_show'],
              appScreen: 'S/B Work Orders: 55.50',
              status: resourceStatuses.Pending
            },
            component: ServiceInvoicesList,
          },
          {
            path: 'paid',
            name: 'Paid Service Invoices',
            title: 'Paid Service Invoices',
            description: 'Manage Paid S/B Invoices',
            meta: {
              permissions: ['billings_show'],
              appScreen: 'S/B Work Orders: 55.50',
              status: resourceStatuses.Paid
            },
            component: ServiceInvoicesList,
          },
          {
            path: 'partial-paid',
            name: 'Partially Paid Service Invoices',
            title: 'Partially Paid Service Invoices',
            description: 'Manage Partial Paid S/B Invoices',
            meta: {
              permissions: ['billings_show'],
              appScreen: 'S/B Work Orders: 55.50',
              status: resourceStatuses.PartialPaid
            },
            component: ServiceInvoicesList,
          },
          {
            path: 'voided',
            name: 'Voided Service Invoices',
            title: 'Voided Service Invoices',
            description: 'Manage Voided S/B Invoices',
            meta: {
              permissions: ['billings_show'],
              appScreen: 'S/B Work Orders: 55.50',
              status: resourceStatuses.Voided
            },
            component: ServiceInvoicesList,
          },
          {
            path: 'open',
            name: 'Open Service Invoices',
            title: 'Open Service Invoices',
            description: 'Manage Open S/B Invoices',
            meta: {
              permissions: ['billings_show'],
              appScreen: 'S/B Work Orders: 55.50',
              status: resourceStatuses.Posted
            },
            component: ServiceInvoicesList,
          },
          {
            path: 'all',
            name: 'All Service Invoices',
            title: 'All Service Invoices',
            description: 'Manage All S/B Invoices',
            meta: {
              permissions: ['billings_show'],
              appScreen: 'S/B Work Orders: 55.50',
              status: resourceStatuses.All
            },
            component: ServiceInvoicesList,
          },
          {
            path: 'add/:workOrder?',
            name: 'Add Service Invoice',
            meta: {
              permissions: ['billings_store'],
              resourceName: 'billings',
            },
            component: ServiceInvoiceAdd,
          },
          {
            path: ':id',
            meta: {
              permissions: ['billings_update'],
            },
            component: ServiceInvoiceLayout,
            children: [
              {
                path: 'edit',
                name: 'Edit Service Invoice',
                meta: {
                  permissions: ['billings_update'],
                  resourceName: 'billings',
                },
                component: ServiceInvoiceEdit,
              },
              {
                path: 'view',
                name: 'Service Invoice Details',
                meta: {
                  permissions: ['billings_update'],
                  resourceName: 'billings',
                },
                component: ServiceInvoiceDetails,
              },
            ]
          },
        ],
      },
      {
        path: 'service-billing-invoice-batches/:date',
        name: 'Service Invoices Batch',
        meta: {
          permissions: ['service_billing_invoices_show'],
          productionReady: true,
        },
        component: ServiceBillingInvoices,
      },
      {
        path: 'work-order-review-status',
        name: 'Review Work Order Status',
        title: 'Review Work Order Status',
        description: 'Review the cost & billing status of selected work orders and update selected items of information.',
        meta: {
          permissions: ['work_orders_show'],
          appScreen: 'S/B Work Orders: 55.70',
        },
        component: WorkOrderReviewStatus,
      },
      {
        path: 'settings',
        redirect: '/service-billing/settings/service-billing-settings',
        meta: {
          permissions: ['service_billing_settings'],
        },
        component: SettingsLayout,
        children: [
          {
            path: 'service-billing-settings',
            name: 'Service Billing Settings',
            title: 'Service Billing Settings',
            description: 'Initialize S/B System',
            meta: {
              permissions: ['service_billing_settings'],
              appScreen: 'Initialize S/B System: 55.91',
            },
            component: ServiceBillingsSettings,
          },
          {
            path: 'activity-log',
            name: 'SB Settings Update Activity',
            meta: {
              permissions: ['service_billing_settings'],
              module: $modules.SB,
            },
            component: SettingsActivityLog,
          },
          {
            path: 'codes',
            name: 'Service Billing Codes',
            title: 'Service Billing Codes',
            description: 'Define standard Service Billing codes.',
            meta: {
              permissions: ['service_billing_codes_show'],
              appScreen: 'S/B Codes: 55.92',
            },
            component: ServiceBillingCodesList,
          },
          {
            path: 'categories',
            name: 'Service Billing Categories',
            title: 'Service Billing Categories',
            description: 'Define Service Billing Category Codes',
            meta: {
              // TODO update permission to service_billing_categories_show after backend adds it
              permissions: ['service_billing_settings'],
              appScreen: 'Define S/B Categories: 55.93',
            },
            component: CategoriesList,
          },
          {
            path: 'categories/add',
            name: 'Add S/B Category',
            title: 'Add S/B Category',
            description: 'Add S/B Category Codes',
            meta: {
              // TODO update permission to service_billing_categories_store after backend adds it
              permissions: ['service_billing_settings'],
              appScreen: 'Define S/B Category Codes: 55.93',
            },
            component: CategoryAdd,
          },
          {
            path: 'categories/:id/edit',
            name: 'S/B Category Edit',
            meta: {
              // TODO update permission to service_billing_categories_update after backend adds it
              permissions: ['service_billing_settings'],
              appScreen: 'Define S/B Category Codes: 55.93',
            },
            component: CategoryEdit,
          },
          {
            path: 'categories/:id/view',
            name: 'S/B Category Details',
            meta: {
              // TODO update permission to service_billing_categories_show after backend adds it
              permissions: ['service_billing_settings'],
              appScreen: 'Define S/B Category: 55.93',
            },
            component: CategoryEdit,
          },
        ],
      },
      {
        path: 'reports',
        name: 'SB Reports',
        redirect: '/service-billing/reports/work-order-detail',
        meta: {
          permissions: ['accounts_payable_reports'],
        },
        component: ReportLayout,
        children: reportRoutes,
      },
      {
        path: 'journals',
        name: 'SB Journals',
        title: 'SB Journals',
        redirect: 'journals',
        meta: {
          permissions: ['accounts_payable_reports'],
          appScreen: 'General Journal Entries: 10.40',
          module: 'accounts-payable',
        },
        component: EmptyLayout,
        children: [
          {
            path: '',
            name: 'SB Journals List',
            title: 'SB Journals',
            meta: {
              permissions: ['service_billing_reports'],
              appScreen: 'General Journal Entries: 10.40',
              module: 'service-billing',
              hideBreadCrumb: true,
            },
            component: SBJournals,
          },
          {
            path: ':id/view',
            name: 'SB Journal Details',
            meta: {
              permissions: ['service_billing_reports'],
              appScreen: 'General Journal Entries: 10.40',
              module: 'service-billing',
            },
            component: SBJournalDetail,
          },
        ]
      },
    ]
  }
]
