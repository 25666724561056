<template>
  <base-form :loading="loading"
             :show-back="showBack"
             :show-cancel="showCancel"
             :save-text="$t('Create preliminary notice')"
             :update-text="$t('Update preliminary notice')"
             :focus-on-first-input="!$route.params.id"
             layout="vertical"
             grid-classes="grid grid-cols-8 gap-x-3"
             @submit="onSubmit"
  >
    <div class="col-span-8 md:col-span-4 lg:col-span-2">
      <JobSelect v-model="model.job_id"
                 :disabled="!!model.id"
                 :initial-value="get(data, 'relationships.job')"
                 id="job_id"
                 @change="onChangeJobNo"
                 rules="required"
      />
    </div>
    <div class="col-span-8 md:col-span-4 lg:col-span-2">
      <base-date-picker v-model="model.date_mailed"
                        :label="$t('Date Mailed')"
                        :placeholder="$t('Date Mailed')"
                        id="date_mailed"
      />
    </div>
    <div class="col-span-8 md:col-span-4 lg:col-span-2">
      <base-textarea v-model="model.declarer_name"
                     :rows="1"
                     :label="$t('Declarer Name')"
                     :placeholder="$t('Declarer Name')"
                     id="declarer_name"
      />
    </div>
    <div class="col-span-8 md:col-span-4 lg:col-span-2">
      <base-textarea v-model="model.declarer_title"
                     :rows="1"
                     :label="$t('Declarer Title')"
                     :placeholder="$t('Declarer Title')"
                     id="tideclarer_titletle"
      />
    </div>
    <div class="col-span-8 md:col-span-4 lg:col-span-3">
      <base-textarea v-model="model.signed_location"
                     :rows="1"
                     :label="$t('Signed At (city, state)')"
                     :placeholder="$t('Signed At (city, state)')"
                     id="signed_location"
      />
    </div>
    <div class="col-span-8 md:col-span-4 lg:col-span-2">
      <base-date-picker v-model="model.notice_date"
                        :label="$t('Notice Date')"
                        :placeholder="$t('Notice Date')"
                        id="notice_date"
      />
    </div>
    <div class="col-span-8 mb-4">
      <h4>{{ $t('Company Information') }}</h4>
    </div>
    <div class="col-span-8 md:col-span-4 lg:col-span-2">
      <base-input v-model="model.individual.name"
                  :label="$t('Company Name')"
                  :placeholder="$t('Company Name')"
                  id="company_name"
      />
    </div>
    <div class="col-span-8 md:col-span-4 lg:col-span-2">
      <base-textarea v-model="model.individual.address_1"
                     :label="$t('Address')"
                     :placeholder="$t('Address')"
                     :rows="1"
                     id="service-address"
      />
    </div>
    <div class="col-span-8 md:col-span-4 lg:col-span-1">
      <base-input v-model="model.individual.city"
                  :label="$t('City')"
                  :placeholder="$t('City')"
                  id="service-city"
      />
    </div>
    <div class="col-span-8 md:col-span-4 lg:col-span-1">
      <state-select v-model="model.individual.state"
                    :label="$t('State')"
                    :placeholder="$t('State')"
                    id="service-state"
      />
    </div>
    <div class="col-span-8 md:col-span-4 lg:col-span-2">
      <base-zip-code v-model="model.individual.postal_code"
                     :label="$t('Postal Code')"
                     placeholder="Postal Code"
                     id="postal_code"
      />
    </div>
    <div class="col-span-8 md:col-span-4 lg:col-span-2">
      <base-input v-model="model.declarer_relation"
                  :label="$t('Relationship to the parties receiving the notice')"
                  :placeholder="$t('Relationship to the parties receiving the notice')"
                  id="relation_parties_receiving_notice"
      />
    </div>
    <div class="col-span-8 md:col-span-4 lg:col-span-2">
      <base-input v-model="model.est_price"
                  v-bind="$validator.amount"
                  :placeholder="$t('e.g 10.00')"
                  :label="$t('Estimated Price')"
                  :name="$t('Estimated Price')"
                  id="est_price"
      />
    </div>
    <div class="col-span-8 md:col-span-4">
      <base-textarea v-model="model.description"
                     :rows="1"
                     :label="$t('Description of Labor, Service...')"
                     :placeholder="$t('Description')"
                     id="description"
      />
    </div>

    <div class="col-span-8">
      <el-collapse v-model="expandedSections">
        <base-collapse-section
            :title="$t('Addresses')"
                               name="addresses"
                               key="addresses">
          <PreNoticeAddresses
              :key="model.id"
              :data="model"
              class="col-span-8"
              ref="addresses"
          />
        </base-collapse-section>
      </el-collapse>
    </div>

  </base-form>
</template>
<script>
  import axios from 'axios'
  import PreNoticeAddresses from './PreNoticeAddresses'

  export default {
    components: {
      PreNoticeAddresses,
    },
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        showBack: true,
        showCancel: true,
        expandedSections: [
          'addresses'
        ],
        loading: false,
        model: {
          job_id: '',
          date_mailed: this.$now,
          description: '',
          declarer_name: '',
          declarer_title: '',
          declarer_relation: '',
          signed_location: '',
          est_price: 0,
          notice_date: this.$now,
          services_finished_date_from: this.$now,
          services_finished_date_to: this.$now,
          // ? individual is the company information
          individual: {
            city: null,
            name: null,
            state: null,
            address_1: null,
            postal_code: null,
          },
          project: {
            city: null,
            name: '',
            state: null,
            parcel: null,
            address_1: null,
            postal_code: null,
          },
          contact_person: {
            city: null,
            name: null,
            state: null,
            address_1: null,
            postal_code: null,
          },
          owner: {
            city: null,
            name: null,
            state: null,
            address_1: null,
            postal_code: null,
          },
          original_contractor: {
            city: null,
            name: null,
            state: null,
            address_1: null,
            postal_code: null,
          },
          lender: {
            city: null,
            name: null,
            state: null,
            address_1: null,
            postal_code: null,
          },
          subcontractor: {
            city: null,
            name: null,
            state: null,
            address_1: null,
            postal_code: null,
          },
          trust_fund_1: {
            city: null,
            name: null,
            state: null,
            address_1: null,
            postal_code: null,
          },
          trust_fund_2: {
            city: null,
            name: null,
            state: null,
            address_1: null,
            postal_code: null,
          },
          trust_fund_3: {
            city: null,
            name: null,
            state: null,
            address_1: null,
            postal_code: null,
          },
          architect: {
            city: null,
            name: null,
            state: null,
            address_1: null,
            postal_code: null,
          },
        },
      }
    },
    methods: {
      async onChangeJobNo(job_id) {
        try {
          const { data } = await axios.get(`/restify/job-pre-notices?job_id=${job_id}`)

          if (data.length) {
            const confirmed = await this.$confirm({
              title: this.$t(`Pre-notice exists`),
              description: this.$t(`Do you want to update pre-notice?`),
              buttonText: this.$t('Yes'),
            })

            if (!confirmed) {
              this.model.job_id = ''
              return
            }

            const id = this.get(data, '0.id')

            await this.$router.push(`/job-costing/settings/pre-notice/${id}/edit`)
          }
        } catch (err) {
          console.log('err', err)
        }
      },
      async onSubmit() {
        try {
          this.loading = true

          const payload = this.$refs.addresses.getPayload(this.model)

          if (this.model.id) {
            await axios.put(`/restify/job-pre-notices/${this.model.id}`, payload)
            this.$success(this.$t('Job pre notice updated'))
          } else {
            await axios.post(`/restify/job-pre-notices`, payload)
            await this.$router.push(`/job-costing/settings/pre-notice`)
            this.$success(this.$t('Job pre notice added'))
          }
        } catch (err) {
          console.warn('err', err)
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not save.'))
        } finally {
          this.loading = false
        }
      },
    },
    watch: {
      'data.attributes': {
        immediate: true,
        handler(value) {
          if (!value?.id) {
            return
          }

          this.model = {
            ...this.model,
            ...value,
            ...value.parties,
          }
        },
      },
    },
  }
</script>
