export function formatQuantity(value, options = {
  minimumFractionDigits: 3,
  maximumFractionDigits: 3,
}) {
  const formatter = new Intl.NumberFormat('en-US', options)
  return formatter.format(value)
}

export default {
  install(Vue) {
    Vue.prototype.$formatQuantity = formatQuantity
  }
}
