<template>
  <BaseFormDialog
    v-bind="$attrs"
    v-on="$listeners"
    :appendToBody="true"
    :title="$t('Copy Line Items')"
    size="sm"
  >
    <BaseForm
      :show-cancel="true"
      :save-text="$t('Copy')"
      :submit-disabled="targetJobHasLines"
      :loading="loading"
      layout="modal"
      @cancel="$emit('close')"
      @submit="triggerCopy"
    >
      <ValidationWarning
        v-if="targetJobHasLines && !checkingCanCopy"
        class="text-xs col-span-6"
      >
        {{ $t('Job already has line items. You can only copy to a job that has no line items.') }}
      </ValidationWarning>
      <JobSelect
        v-model="fromJobId"
        :label="$t('From Job')"
        :name="$t('From Job')"
        :placeholder="$t('Select From Job')"
        :excludeIds="[toJobId]"
        :addEntity="false"
        class="col-span-6"
        rules="required"
      />
      <JobSelect
        :value="toJobId"
        :label="$t('To Job')"
        :name="$t('To Job')"
        :placeholder="$t('Select To Job')"
        :disabled="true"
        :addEntity="false"
        class="col-span-6"
        rules="required"
      />
    </BaseForm>
  </BaseFormDialog>
</template>
<script>
import axios from 'axios'

export default {
  props: {
    toJobId: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      fromJobId: null,
      loading: false,
      targetJobHasLines: true,
      checkingCanCopy: false
    }
  },
  methods: {
    async triggerCopy() {
      this.loading = true
      try {
        await axios.post(`/restify/jobs/actions?action=copy-line-items`, {
          from: this.fromJobId,
          to: this.toJobId
        })

        this.$emit('copied')
      } catch (err) {
        if (err.handled) {
          return
        }

        this.$error(this.$t('Failed to copy line items'))
      } finally {
        this.loading = false
      }
    },
    async checkTargetJobHasLines() {
      try {
        this.checkingCanCopy = true
        const { data } = await axios.get('/restify/line-items', {
          params: {
            job_id: this.toJobId
          }
        })

        this.targetJobHasLines = data.length > 0
      }
      finally {
        this.checkingCanCopy = false
      }
    }
  },
  async created() {
    await this.checkTargetJobHasLines()
  }
}
</script>
