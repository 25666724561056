<template>
  <RecurringInvoiceForm
    :key="renderKey"
    @create-another="renderKey++"
  />
</template>
<script>

import RecurringInvoiceForm from "@/modules/accounts-payable/components/recurring-invoice/RecurringInvoiceForm.vue";

export default {
  components: {
    RecurringInvoiceForm,
  },
  data() {
    return {
      renderKey: 1,
    }
  },
}
</script>
