import inRange from 'lodash/inRange'
import { findGlobalResource } from "@/components/ag-grid/cellEditors/cellEditorUtils";
import { RestifyResourceLabels } from "@/components/form/util";
import i18n from "@/i18n";
import { error } from '@/components/common/NotificationPlugin';

const allowedIntervals = [
  [0, 13],
  [20, 33],
  [60, 73],
  [79, 81],
]

function validFirstTwoDigits(firstTwoDigits) {
  return allowedIntervals.some(interval => inRange(firstTwoDigits, interval[0], interval[1]))
}
export function routingNumberValidator(value) {
  const digits = value.toString().split('')
  const firstTwoDigits = +digits.slice(0, 2).join('')

  if (digits.length !== 9 || !firstTwoDigits) {
    return false
  }

  if (validFirstTwoDigits(firstTwoDigits)) {
    const sum = 3 * (+digits[0] + +digits[3] + +digits[6]) + 7 * (+digits[1] + +digits[4] + +digits[7]) + (+digits[2] + +digits[5] + +digits[8])
    return +sum % 10 === 0;
  }
  return false
}

export function zipCodeValidator(value) {
  // Regular expression for standard ZIP code (5 digits)
  const standardZipPattern = /^\d{5}$/;

  // Regular expression for ZIP+4 code (5 digits, hyphen, 4 digits)
  const zipPlusFourPattern = /^\d{5}-\d{4}$/;

  // Check if the input matches either the standard ZIP code or ZIP+4 code pattern
  return standardZipPattern.test(value) || zipPlusFourPattern.test(value);
}

export function checkForDuplicateCode({resourceName, code, key = 'code', id }) {
  if (!resourceName) {
    return false
  }
  const duplicateResource = findGlobalResource(resourceName, code, key)
  const resourceId = duplicateResource?.id
  return resourceId && resourceId !== id
}

export function validateEmail(email) {
  const regex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return regex.test(email);
}

export function hoursValidation(value) {
  try {
    if (!value) {
      return false
    }
    const parts = value.split(':')
    if (parts.length > 2) {
      return false
    }
    if (parts.length === 2) {
      const minutes = +parts[1]
      const hours = +parts[0]
      if (isNaN(hours) || isNaN(minutes)) {
        return false
      }
      return minutes >= 0 && minutes < 60 && hours >= 0
    }
    if (parts.length === 1) {
      const val = parseFloat(value)
      return val >= 0 && !isNaN(val)
    }
    return false
  } catch (err) {
    return false
  }
}

const defaultValidationOptions = {
  notifyErr: false,
}

export function validateNumber(value, { notifyErr } = defaultValidationOptions) {
  const isNumber = !isNaN(value) && !isNaN(parseFloat(value))
  if (isNumber) {
    return true
  }

  if (notifyErr) {
    error(i18n.t('Value must be a number'))
  }

  return false
}

export function validateMin(value, minValue, { notifyErr } = defaultValidationOptions) {
  if (value >= minValue) {
    return true
  }

  if (notifyErr) {
    error(i18n.tc('value cannot be less than', null, {
      limit: minValue,
    }))
  }
  
  return false
}

export function validateMax(value, maxValue, { notifyErr } = defaultValidationOptions) {
  if (value <= maxValue) {
    return true
  }

  if (notifyErr) {
    error(i18n.tc('value cannot be greater than', null, {
      limit: maxValue,
    }))
  }
  
  return false
}

export function validateMaxDecimals(value, maxDecimals, { notifyErr } = defaultValidationOptions) {
  value = value.toString()
  const decimalsCount = value.split('.')[1]?.length || 0

  if (decimalsCount <= maxDecimals) {
    return true
  }

  if (notifyErr) {
    error(i18n.tc('max decimals allowed validation', null, {
      limit: maxDecimals,
    }))
  }

  return false
}
