<template>
  <div>
    <base-input
      v-bind="$attrs"
      v-on="$listeners"
      v-model="modelValue"
      id="code"
      :label="$attrs.label || $t('Code')"
      :placeholder="$attrs.placeholder || $t('Code')"
      :disabled="!!model?.id"
      :rules="{
        required: true,
        code: {
          resourceName,
          max: maxLength,
          key: valueKey,
          id: model.id,
        },
      }"
    />
  </div>
</template>
<script setup>
import { computed, ref } from "vue";
import { findGlobalResource } from "@/components/ag-grid/cellEditors/cellEditorUtils";
import { globalResources, RestifyResourceLabels } from "@/components/form/util";
import i18n from "@/i18n";

const props = defineProps({
  model: {
    type: Object,
    required: true,
  },
  resourceName: {
    type: String,
  },
  value: {
    type: String,
  },
  valueKey: {
    type: String,
    default: 'code'
  },
  maxLength: {
    type: Number,
    default: 8,
  }
})

const emit = defineEmits(['input'])

const modelValue = computed({
  get() {
    return props.value
  },
  set(value) {
    emit('input', value)
  }
})
</script>
