<template>
  <div>
    <BaseTooltip :content="tooltipContent">
      <StatusBadge :status="status"/>
    </BaseTooltip>
  </div>
</template>
<script>
import { appModule, resourceStatuses } from '@/enum/enums'
import StatusBadge from '@/components/common/StatusBadge'

export default {
  components: {
    StatusBadge,
  },
  props: {
    id: String, // * Status value
    row: {
      type: Object,
      default: () => ({}),
    },
    editableCell: Boolean,
  },
  computed: {
    tooltipContent() {
      if (this.row.attributes.rejection_reason) {
        return this.row.attributes.rejection_reason
      }
      return this.status
    },
    status() {
      const { status, approved_at, rejected_at } = this.row.attributes || {}
      if (approved_at) {
        return resourceStatuses.Approved
      }
      if (rejected_at) {
        return resourceStatuses.Rejected
      }
      return 'not_reviewed'
    },
  },
}
</script>
