<template>
  <Upload
      :auto-upload="false"
      :on-change="parseFile"
      :on-remove="onFileRemove"
      :limit="1"
      action="default"
      accept=".json"
  >
    <BaseButton class="select-file-button focus:outline-none focus:ring-blue"
                ref="uploadButton"
                variant="white">
      <UploadIcon class="w-4 h-4 mr-2 text-green-500"/>
      <span>{{ $t('Import') }}</span>
    </BaseButton>
  </Upload>
</template>
<script setup lang="ts">
import {Upload} from "element-ui";
import i18n from "@/i18n";
import {ref} from "vue";
import {error} from "@/components/common/NotificationPlugin";
import {UploadIcon} from "vue-feather-icons";

let uploadedJson = ref<string[]>([])
const emit = defineEmits(['change'])

async function parseFile(file: any) {
  const reader = new FileReader()

  reader.readAsText(file.raw)
  reader.onload = () => {
    try {
      const result = JSON.parse(reader.result as string)
      if (!Array.isArray(result)) {
        error(i18n.t('The provided file is not a valid permissions file.'))
        return
      }
      uploadedJson.value = result
      onJsonChange()
    } catch (Err) {
      error(i18n.t('Could not parse the provided file.'))
    }
  }
}

function onFileRemove() {
  uploadedJson.value = []
  onJsonChange()
}

function onJsonChange() {
  emit('change', uploadedJson.value)
}

</script>
