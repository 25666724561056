<template>
  <div class="flex justify-between w-full">
    <div v-html="params.value"/>
    <template v-if="params.node.level === 1">
      <div class="absolute right-5 text-red-500"
           v-for="error in errors">
        <span>{{error.message}}</span>
        <span v-if="error.open_amount">
        {{ $formatPrice(error.open_amount) }}
      </span>
      </div>
    </template>
  </div>
</template>
<script>
  export default {
    computed: {
      data() {
        return this.params.node?.allLeafChildren[0]?.data || {}
      },
      errors() {
        return this.data.billing?.errors || []
      }
    }
  }
</script>
