<template>
  <div :key="get(currentResource, 'id')">
    <template v-if="currentResource">
      <portal to="page-title">
        <div class="flex justify-between items-center space-x-1 md:justify-start">
          <div>
            {{ pageTitle }}
          </div>
          <status-link
            v-if="currentResource.attributes"
            :row="currentResource"
          />
          <DirectDepositConfirmStatus :batch="currentResource"/>
          <PositivePayConfirmStatus :batch="currentResource"/>
        </div>
      </portal>
    </template>
    <router-view/>
  </div>
</template>
<script>
  import layoutPermissions from '@/mixins/layoutPermissions'
  import DirectDepositConfirmStatus from "@/modules/payroll/components/payroll/DirectDepositConfirmStatus.vue";
  import PositivePayConfirmStatus from "@/modules/payroll/components/payroll/PositivePayConfirmStatus.vue";

  export default {
    components: { PositivePayConfirmStatus, DirectDepositConfirmStatus },
    mixins: [layoutPermissions],
    data() {
      return {
      }
    },
    computed: {
      currentResource() {
        return this.$store.state.payroll.currentPayrollBatch
      },
      periodEndDate() {
        return this.currentResource?.attributes?.period_end_date
      },
      formattedEndDate() {
        if (!this.periodEndDate) {
          return ''
        }
        return this.$formatDate(this.periodEndDate, 'date', true)
      },
      pageTitle() {
        return this.$t(`Check Batch ${this.formattedEndDate}`)
      }
    },
    methods: {
      async getCurrentResource(id) {
        await this.$store.dispatch('payroll/getPayrollBatch', id)
      },
    },
  }
</script>
