<template>
  <div class="px-5 pt-8">
    <base-form layout="vertical"
               :title="$t('Create new wiki page')"
               :loading="loading"
               :save-text="$t('Create page')"
               @submit="onSubmit">
      <base-input v-model="model.title"
                  class="col-span-6"
                  :label="$t('Title')">
      </base-input>
      <base-input v-model="model.category"
                  class="col-span-6"
                  :label="$t('Category')">
      </base-input>

      <base-input class="col-span-6" :label="$t('Content')">
        <html-editor v-model="model.content"></html-editor>
      </base-input>
    </base-form>
  </div>
</template>
<script>
  import axios from 'axios'

  export default {
    data() {
      return {
        loading: false,
        model: {
          title: '',
          category: '',
          content: ``
        }
      }
    },
    methods: {
      async onSubmit() {
        try {
          this.loading = true
          const { data } = await axios.post(`/restify/wiki-articles`, this.model)
          this.$success(this.$t('Page created successfully'))
          this.$router.push(`/wiki/${data.id}`)
        } finally {
          this.loading = false
        }
      }
    }
  }
</script>
<style>
</style>
