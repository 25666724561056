<template>
  <el-collapse v-model="expandedSections"
               class="col-span-8 cost-for-billing">
    <base-collapse-section
        key="form-header"
        name="form-header"
        class="mb-6"
        wrapperClasses="ml-0"
    >
      <template #title>
        <div class="pl-4">
          {{ $t('Filters') }}
        </div>
      </template>
      <base-form layout="vertical"
                 class="subcontract-progress-filters"
                 :loading="loading"
                 :save-text="$t('Proceed')"
                 @submit="onSubmit"
      >
        <CustomerRangeFilter v-model="model" class="col-span-6"/>
        <WorkOrderRangeFilter v-model="model" class="col-span-6"/>
        <base-filter-row :title="$t('Cost History Date Range')" class="col-span-6">
          <DateRangeFilter v-model="model" class="col-span-4"/>
        </base-filter-row>
        <base-filter-row :title="$t('Work Order Status')" class="col-span-6">
          <BaseSelect
              v-model="model.work_order_status"
              :options="workOrderStatuses"
              multiple
              class="col-span-4"
          />
        </base-filter-row>
        <base-filter-row :title="$t('Category')" class="col-span-6">
          <div class="col-span-4">
            <ServiceBillingCategorySelect
                v-model="model.category_id"
                :hide-label="true"
            />
          </div>
        </base-filter-row>
        <base-filter-row :title="$t('Include removed')" class="col-span-6">
          <div class="col-span-4">
            <base-checkbox
              v-model="model.show_removed"
              :label="$t('Show history items previously set to Remove')"
              id="show_removed"
            />
          </div>
        </base-filter-row>
      </base-form>
    </base-collapse-section>
  </el-collapse>
</template>
<script>
import { CustomerRangeFilter, WorkOrderRangeFilter } from "@/components/range-filters";
import DateRangeFilter from "@/components/range-filters/DateRangeFilter.vue";
import { resourceStatuses } from "@/enum/enums";

export default {
  components: {
    DateRangeFilter,
    CustomerRangeFilter,
    WorkOrderRangeFilter,
  },
  data() {
    return {
      loading: false,
      expandedSections: ['form-header'],
      model: {
        customer_code_from: undefined,
        customer_code_to: undefined,
        work_order_number_from: undefined,
        work_order_number_to: undefined,
        work_order_status: [resourceStatuses.Open],
        category_id: null,
        show_removed: 0,
      }
    }
  },
  computed: {
    workOrderStatuses() {
      return [
        {
          label: this.$t('Open'),
          value: resourceStatuses.Open
        },
        {
          label: this.$t('Closed'),
          value: resourceStatuses.Closed
        }
      ]
    }
  },
  methods: {
    onSubmit() {
      this.$emit('save', this.model)
      this.expandedSections = []
    }
  }
}
</script>
<style lang="scss">
.cost-for-billing {
  @apply shadow;
  .form__inner {
    @apply shadow-none;
  }
}
</style>
