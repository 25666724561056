<template>
  <base-input
    :fractionDigits="5"
    :min="0"
    :step="0.00001"
    :max="99.99999"
    type="number"
    rules="max_value:99.99999|min_value:0"
    v-bind="$attrs"
    v-on="$listeners"
  >
  </base-input>
</template>

<script>
export default {
  name: 'RateInput',
  inheritAttrs: false,
}
</script>
