<template>
  <nav class="flex space-x-4 nav-bar" aria-label="Tabs">
    <a
      v-for="status in statuses"
      :key="status.value"
      :class="{'active': value === status.value}"
      href="javascript:;"
      class="nav-item"
      @click="onFilterInvoices(status.value)"
    >
      {{ status.label }}
    </a>
  </nav>
</template>
<script>
import { resourceStatuses } from "@/enum/enums";

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    statuses: {
      type: Array,
      default: () => []
    },
  },
  methods: {
    onFilterInvoices(status) {
      this.currentStatus = status
      this.updateRouteQuery({ status })
      this.$emit('input', status)
    },
    updateRouteQuery(query) {
      this.$router.push({ query: { ...query } })
    },
  },
  mounted() {
    this.$emit('input', this.$route.query?.status || resourceStatuses.All)
  }
}
</script>
<style>
</style>
