<template>
    <base-form :loading="loading"
               :showCancel="showCancel"
               :showBack="showBack"
               :save-text="$t('Create material code')"
               :update-text="$t('Update material code')"
               layout="modal"
               @cancel="$emit('cancel')"
               @submit="onSubmit"
    >
        <div class="col-span-6 md:col-span-3">
            <base-input v-model="model.code"
                        :label="$t('Code')"
                        :placeholder="$t('Material Code')"
                        id="code"
                        rules="required|code"
            />
        </div>
        <div class="col-span-6 md:col-span-3">
            <entity-select v-model="model.material_class_code_id"
                           url="/restify/material-classes"
                           :label="$t('Class')"
                           label-key="code"
                           value-key="id"
            />
        </div>
        <div class="col-span-6">
            <base-input v-model="model.description"
                        :label="$t('Description')"
                        :placeholder="$t('Description')"
                        id="description"
                        rules="max:150"
            />
        </div>
        <div class="col-span-6 md:col-span-3">
            <base-input v-model="model.um"
                        :label="$t('Unit Measure')"
                        :placeholder="$t('Unit Measure')"
                        id="um"
                        rules="length:4"
            />
        </div>
        <div class="col-span-6 md:col-span-3">
            <base-input v-model="model.unit_price"
                        :label="$t('Unit Price')"
                        :placeholder="$t('e.g 100.00')"
                        :step="0.001"
                        id="unit_price"
                        type="number"
                        format="price"
                        rules="max_value:999999.999"
            />
        </div>
    </base-form>
</template>
<script>
  import axios from 'axios'

  export default {
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      showBack: {
        type: Boolean,
        default: true,
      }
    },
    data() {
      return {
        loading: false,
        showCancel: true,
        model: {
          cost: '',
          description: '',
          material_class_code_id: undefined,
          um: '',
          unit_price: 0.00,
        },
      }
    },
    watch: {
      data: {
        immediate: true,
        handler(value) {
          this.model = {
            ...this.model,
            ...value,
          }
        },
      },
    },
    methods: {
      async onSubmit() {
        try {
          this.loading = true
          if (this.model.id) {
            await axios.put(`/restify/material-codes/${this.model.id}`, this.model)
            this.$success(this.$t('Material code updated'))
          } else {
            await axios.post('/restify/material-codes', this.model)
            this.$success(this.$t('Material code added'))
          }
          this.$emit('save', this.model)
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not update material code'))
        } finally {
          this.loading = false
        }
      },
    },
  }
</script>
