<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <base-form :loading="loading"
               :show-cancel="showCancel"
               :can-create-another-entity="!model.id"
               :save-text="model.id ? $t('Update gen liability rates') : $t('Create gen liability rates')"
               layout="modal"
               @cancel="$emit('cancel')"
               @submit="handleSubmit(onSubmit)"
    >
      <base-input v-model="model.code"
                  :disabled="!!model.id"
                  :label="$t('General Liability Code')"
                  :placeholder="$t('General Liability Code')"
                  class="col-span-6 md:col-span-2"
                  rules="required|code"
      />
      <base-input v-model="model.description"
                  :label="$t('Description')"
                  :placeholder="$t('Enter code description')"
                  class="col-span-6 md:col-span-4"
                  rules="max:150"
                  id="description"
      />
      <state-select v-model="model.state"
                    :label="$t('State')"
                    :placeholder="$t('Select State')"
                    :disabled="!authorizedToUpdateAmount"
                    class="col-span-6 md:col-span-3"
                    id="state"
      />
      <base-select v-model="model.calculate_by"
                   :label="$t('Premiums Calculate By')"
                   :placeholder="$t('Premiums Calculate By')"
                   :options="calculateByOptions"
                   :disabled="!authorizedToUpdateAmount"
                   class="col-span-6 md:col-span-3"
                   id="calculate_by"
                   rules="required"
                   @change="onChangeCalculateBy"
      />
      <base-select v-model="model.multiply_by"
                   :label="multiplyByLabel"
                   :placeholder="multiplyByLabel"
                   :options="multiplyByOptions"
                   :disabled="!authorizedToUpdateAmount"
                   class="col-span-6 md:col-span-3"
                   id="multiply_by"
                   rules="required"
      />
      <base-input v-model="model.amount"
                  :label="multiplyValueLabel"
                  :placeholder="multiplyValueLabel"
                  :min="0"
                  :fractionDigits="4"
                  :format="getAmountInputFormat"
                  :disabled="!authorizedToUpdateAmount"
                  :step="0.0001"
                  class="col-span-6 md:col-span-3"
                  id="amount"
                  type="number"
                  rules="max_value:9999999999.999999|min_value:0"
      />
      <base-date-picker v-model="model.start_date"
                        :label="$t('Start Date')"
                        :placeholder="$t('Start Date')"
                        :disabled="!authorizedToUpdateStartDate"
                        :picker-options="getSuggestionsForStartDate"
                        class="col-span-6 md:col-span-3"
                        rules="required"
                        id="start_date"
      />
      <template v-if="canCreateACopy"
                #extra-buttons>
        <base-tooltip :content="$t('Make a copy for the selected state and start date.')"
                      :tabindex="-1"
        >
          <base-button :disabled="!isValidToMakeACopy"
                       :loading="loading"
                       variant="success"
                       class="ml-4"
                       @click="handleSubmit(onSubmit(true))"
          >
            {{ $t('Make a Copy') }}
          </base-button>
        </base-tooltip>
      </template>
    </base-form>
  </ValidationObserver>
</template>
<script>
  import axios from 'axios'
  import {
    calculateBy,
    calculateByOptions,
    composeSuggestionsForStartDate,
    multiplyBy,
  } from '@/modules/payroll/components/rates/util'

  const MULTIPLY_BY_LABELS = {
    [calculateBy.Percentage]: 'Multiply % By',
    [calculateBy.Rate]: 'Multiply Rate By',
  }

  const MULTIPLY_VALUE_LABELS = {
    [calculateBy.Percentage]: 'Percent',
    [calculateBy.Rate]: 'Rate',
  }

  const MULTIPLY_BY_OPTIONS = {
    [calculateBy.Percentage]: [
      {
        label: 'Total Hourly Pay at Regular Rate plus other Earnings',
        value: multiplyBy.INCLUDE_EXTRA,
      },
      {
        label: 'Total Earnings',
        value: multiplyBy.TOTAL_EARNINGS,
      },
    ],
    [calculateBy.Rate]: [
      {
        label: 'Regular Hours',
        value: multiplyBy.REGULAR_HOURS,
      },
      {
        label: 'Total Hours',
        value: multiplyBy.TOTAL_HOURS,
      },
    ],
  }

  export default {
    props: {
      data: {
        type: Object,
        default: () => ({
          attributes: {},
        }),
      },
      selectedDate: String,
    },
    data() {
      return {
        showCancel: true,
        loading: false,
        calculateByOptions,
        model: {
          code: '',
          state: '',
          description: '',
          calculate_by: calculateBy.Percentage,
          multiply_by: multiplyBy.INCLUDE_EXTRA,
          amount: 0,
          start_date: null,
        },
      }
    },
    computed: {
      canCreateACopy() {
        return this.model.id && this.authorizedToUpdateStartDate
      },
      isValidToMakeACopy() {
        const { start_date, state } = this.data.attributes
        return !!(this.model.state !== state || this.model.start_date !== start_date)
      },
      authorizedToUpdateAmount() {
        if (!this.model.id) {
          return true
        }
        return this.$isAuthorized('authorizedToUpdateAmount', this.data)
      },
      authorizedToUpdateStartDate() {
        if (!this.model.id) {
          return true
        }
        return this.$isAuthorized('authorizedToUpdateStartDate', this.data)
      },
      multiplyByLabel() {
        return this.$t(MULTIPLY_BY_LABELS[this.model.calculate_by])
      },
      multiplyValueLabel() {
        return this.$t(MULTIPLY_VALUE_LABELS[this.model.calculate_by])
      },
      multiplyByOptions() {
        return MULTIPLY_BY_OPTIONS[this.model.calculate_by]
      },
      getAmountInputFormat() {
        return this.model.calculate_by === calculateBy.Percentage ? 'percent' : 'price'
      },
      getSuggestionsForStartDate() {
        if (!this.data?.attributes?.start_date) {
          return this.selectedDate
        }
        return composeSuggestionsForStartDate(this.data?.attributes?.start_date)
      },
    },
    methods: {
      async onSubmit(makeCopy = false) {
        try {
          this.loading = true
          if (this.model.id && !makeCopy) {
            await axios.put(`/restify/gen-liability-rates/${this.model.id}`, this.model)
            this.$success(this.$t('General Liability Rates updated'))
            this.$emit('save', this.model)
          } else {
            const { data } = await axios.post('/restify/gen-liability-rates', this.model)
            this.$success(this.$t('General Liability Rates created'))
            this.$createAnotherEntity ? this.$emit('create-another') : this.$emit('save', data)
          }
          if (!this.$createAnotherEntity) {
            return this.$emit('save', this.model)
          }
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not update the general liability rates'))
        } finally {
          this.loading = false
        }
      },
      onChangeCalculateBy(option) {
        this.model.multiply_by = MULTIPLY_BY_OPTIONS[option]?.[0]?.value
      },
    },
    watch: {
      'data.attributes': {
        immediate: true,
        handler(value) {
          if (!value?.id) {
            return this.model.start_date = this.selectedDate
          }
          this.model = {
            ...this.model,
            ...value,
          }
        },
      },
    },
  }
</script>


