<template>
  <AdjustmentEntriesTable :type="adjustmentTypes.EARNINGS"/>
</template>
<script>
  import { adjustmentTypes } from '@/modules/payroll/pages/adjustment/util'
  import AdjustmentEntriesTable from '@/modules/payroll/pages/adjustment/AdjustmentEntriesTable'

  export default {
    components: {
      AdjustmentEntriesTable,
    },
    data() {
      return {
        adjustmentTypes,
      }
    },
  }
</script>
