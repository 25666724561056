<template>
  <div class="grid grid-cols-3 gap-2">
    <line-item-input-select
        ref="phaseSelect"
        v-bind="$attrs"
        :tip="$t('Select or add new Phase Code')"
        :value="phase_code"
        :id-value="id || lastLineItemId"
        :label="$t('Phase')"
        :placeholder="$t('Phase Code')"
        :rules="`max:20|${phaseCodeValidation}`"
        :add-entity="false"
        label-key="phase_code"
        id="phase_code"
        key="phase_code"
        @blur="onPhaseBlur"
        @input="onPhaseCodeInput"
        @entity-change="budget => onCodeChange(budget, true)"
    />
    <line-item-input-select
        ref="costCodeSelect"
        v-bind="$attrs"
        :tip="$t('Select or add new Cost Code')"
        :value="cost_code"
        :id-value="id || lastLineItemId"
        :label="$t('Code')"
        :placeholder="$t('Cost Code')"
        :rules="`max:20|${costCodeValidation}`"
        :add-entity="false"
        :allow-create="true"
        :url-params="{
          phase_code: phase_code,
        }"
        label-key="cost_code"
        id="cost_code"
        key="cost_code"
        @blur="onCostCodeBlur"
        @input="onCostCodeInput"
        @entity-change="onCodeChange"
    />
    <line-item-input-select
        ref="changeOrderSelect"
        v-bind="$attrs"
        :tip="$t('Select or add new Change Order')"
        :value="change_order"
        :id-value="id || lastLineItemId"
        :label="$t('Change Order')"
        :placeholder="$t('Change Order')"
        rules="max_value:999|min_value:0"
        :add-entity="false"
        :allow-create="true"
        :url-params="{
          phase_code: phase_code,
          cost_code: cost_code,
        }"
        label-key="change_order"
        id="change_order"
        key="change_order"
        @blur="onChangeOrderBlur"
        @input="onChangeOrderInput"
        @entity-change="onCodeChange"
    />
  </div>
</template>
<script>
  import LineItemInputSelect from '@/components/select/entities/LineItemInputSelect'

  export default {
    components: {
      LineItemInputSelect,
    },
    props: {
      id: {
        type: String,
        default: '',
      },
      phase_code: {
        type: [String, Number],
        default: '',
      },
      cost_code: {
        type: [String, Number],
        default: '',
      },
      change_order: {
        type: [String, Number],
        default: 0,
      },
    },
    data() {
      return {
        lastLineItemId: null,
      }
    },
    computed: {
      phaseCodeValidation() {
        return !this.cost_code ? 'required' : ''
      },
      costCodeValidation() {
        return !this.phase_code ? 'required' : ''
      },
    },
    methods: {
      onPhaseBlur() {
        this.$refs.phaseSelect?.emitLastQuery()
      },
      onCostCodeBlur() {
        this.$refs.costCodeSelect?.emitLastQuery()
      },
      onChangeOrderBlur() {
        this.$refs.changeOrderSelect?.emitLastQuery()
      },
      onPhaseCodeInput(value) {
        this.$emit('update:phase_code', value)
      },
      onCostCodeInput(value) {
        this.$emit('update:cost_code', value)
      },
      onChangeOrderInput(value) {
        this.$emit('update:change_order', value)
      },
      onCodeChange(lineItem, updatePhaseCode) {
        if (!lineItem) {
          return
        }
        this.lastLineItemId = lineItem?.id
        const { phase_code, cost_code, change_order, description } = lineItem.attributes || {}
        this.$emit('update:cost_code', cost_code)
        this.$emit('update:change_order', change_order)
        this.$emit('update:description', description)

        if (!updatePhaseCode) {
          return
        }
        this.$emit('update:phase_code', phase_code)
      },
    },
  }
</script>
