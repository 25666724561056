<template>
  <div v-loading="reconciliationLoading">

    <portal to="page-title">
      {{ pageTitle }}
    </portal>

    <div class="flex justify-between items-center mr-2">
      <layout-tabs :items="filteredItems"/>
      <div class="flex items-center space-x-2">

        <StimulsoftPrintButton
          :data="printData"
          :entity="StimulsoftPrintEntities.AccountReconciliation"
        />
        <PostAction
            v-if="$isAuthorized('authorizedToPost', currentResource)"
            :entity-name="$t('Account Reconciliation')"
            :id="currentResource.id"
            :has-custom-message="true"
            :action-url="`/restify/reconciliations/${currentResource.id}/actions?action=post-reconciliation`"
            resource-name="reconciliation"
            @on-action-callback="getCurrentResource"
        >
          <template #custom-message>
            {{ $t('If you post reconciled transactions, they will not appear on your cash reconciliation.') }}<br>
            {{ $t('Transactions with a status of ') }} <b>{{ $t('Variance') }}</b> {{ $t('or') }}
            <b>{{ $t('Outstanding') }}</b> {{ $t('will continue to appear until they are reconciled.') }}
          </template>
        </PostAction>
      </div>
    </div>

    <router-view/>
  </div>
</template>
<script>
  import layoutPermissions from '@/mixins/layoutPermissions'
  import { StimulsoftPrintEntities } from "@/enum/stimulsoft";
  import { computedAccountBalance, getAccountByNumber, getAccountByNumberSync } from "@/utils/account";

  export default {
    mixins: [layoutPermissions],
    computed: {
      StimulsoftPrintEntities() {
        return StimulsoftPrintEntities
      },
      items() {
        return [
          {
            name: this.$t('Details'),
            path: `/ledger/account-reconciliation/${this.$route.params.id}/view`,
            authorizedTo: 'authorizedToShow',
          },
          {
            name: this.$t('Edit'),
            path: `/ledger/account-reconciliation/${this.$route.params.id}/edit`,
            authorizedTo: 'authorizedToUpdate',
          },
        ]
      },
      reconciliationLoading() {
        return this.$store.state.generalLedger.reconciliationLoading
      },
      currentResource() {
        return this.$store.state.generalLedger.currentReconciliation || {}
      },
      accountDescription() {
        return getAccountByNumberSync(this.currentResource?.attributes?.account)?.description
      },
      pageTitle() {
        let { account, date } = this.currentResource.attributes
        date = this.$formatDate(date)

        let title = `${this.$t('Reconciliation ')} ${date} @ ${account}`

        if (this.accountDescription) {
          title = `${title} (${this.accountDescription})`
        }
        return title
      },
      accountBalance() {
        const account = getAccountByNumberSync(this.currentResource?.attributes?.account)
        return computedAccountBalance(account, this.currentResource?.attributes?.period)
      },
      printData() {
        const entries = this.$store.state.generalLedger.currentReconciliationEntries || []
        const account = this.$store.getters['company/getAccountByNumber'](this.currentResource?.attributes?.account)
        const account_description = account?.description || ''
        return {
          company: this.$currentCompany,
          reconciliation: {
            ...this.currentResource,
            attributes: {
              ...this.currentResource?.attributes,
              account_description,
              gl_balance: this.accountBalance
            }
          },
          entries: entries.map(entry => {
            return {
              ...entry,
              business: entry.business || {},
            }
          })
        }
      }
    },
    methods: {
      async getCurrentResource(id) {
        await this.$store.dispatch('generalLedger/getReconciliation', id)
      },
    },
  }
</script>
