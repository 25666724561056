<template>
    <PreNoticeForm/>
</template>
<script>
  import PreNoticeForm from '@/modules/job-costing/components/PreNoticeForm'

  export default {
    components: {
      PreNoticeForm,
    },
  }
</script>
