<template>
  <div class="pb-6 text-gray-700 text-sm flex justify-center">© {{year}} Construction Partner, Inc. <span class="print:hidden ml-1">All rights reserved.</span></div>
</template>
<script>
  export default {
    data() {
      return {
        year: new Date().getFullYear()
      }
    }
  }
</script>
