<template>
  <lump-sum-billing-form :data="billing"/>
</template>
<script>
  import LumpSumBillingForm from '@/modules/accounts-receivable/components/lump-sum-billings/LumpSumBillingForm'

  export default {
    components: {
      LumpSumBillingForm,
    },
    computed: {
      billing() {
        return this.$store.state.accountsReceivable.currentBilling
      },
    },
  }
</script>
