<template>
  <div>
    <div v-if="$can('customer_statements_update')"
         class="flex justify-between items-center mt-8 mb-3">
      <h3>
        {{ $t('Statements to send') }}
      </h3>
      <div class="flex items-center">
        <base-button variant="primary"
                     :disabled="!customers.some(e => e.selected)"
                     @click="sendEmails"
        >
          {{ $t('Send') }}
        </base-button>
      </div>
    </div>
    <BaseDataTable :columns="columns"
                   :url="url"
                   :url-params="urlParams"
                   :data-map-function="mapCustomers"
                   :row-classes="getRowClasses"
                   :select-row-filter="hasEmail"
                   default-sort="customers.name"
                   actions="search, refresh, export"
                   entity="customer-statements"
                   ref="table"
                   @add="$router.push('/accounts-receivable/statements/add')"
                   @edit="addEmail"
                   @all-selected="onAllPagesSelected"
                   @data-fetch="customers = $event"
    >
      <template v-slot:select="{row}">
        <base-checkbox v-if="$can('customer_statements_update')"
                       v-model="row.selected"
                       :disabled="!getEmail(row)">
        </base-checkbox>
      </template>
      <template v-slot:attributes.sent_count="{row}">
        <base-badge :type="row.attributes.sent_count > 0 ? 'success' : 'info'">
          {{ $tc('sent times', row.attributes.sent_count, { count: row.attributes.sent_count }) }}
        </base-badge>
      </template>
      <template v-slot:customers.name="{row}">
        <div>
          <base-tooltip :content="getCustomer(row).name">
            <entity-preview :entity="row.relationships.customer.attributes"
                            :key="`preview-${row.id}`"
                            entity-name="customer"
                            :url="`/accounts-receivable/customers/${getCustomer(row).id}/view`"
                            :valueToDisplay="getCustomer(row).name"
                            :parent-entity-id="row.id"
            />
          </base-tooltip>
          <base-phone-link :value="getCustomer(row).phone"></base-phone-link>
        </div>
      </template>
      <template v-slot:extra-actions="{row, index}">
        <table-view-button @click="previewStatement(row)"/>
        <table-edit-button v-if="$can('customer_statements_update') && !customerId"
                           @click="addEmail(row, index)"/>
        <table-download-button :loading="row.downloading"
                               @click="downloadStatement(row)"/>
        <table-icon-button v-if="getEmail(row)"
                           :content="$t('Send Email')"
                           @click="sendStatement(row)"
        >
          <MailIcon class="w-4 h-4"></MailIcon>
        </table-icon-button>
      </template>
      <template v-slot:footer-left>
        <div class="flex items-center ml-2">
          <div class="bg-red-50 border border-red-400 w-5 h-5 rounded"></div>
          <span class="ml-2 text-gray-600">{{ $t('Statements with payments that are 90+ days due') }}</span>
        </div>
      </template>
    </BaseDataTable>
    <base-form-dialog v-if="showAddEmailDialog && rowToEdit"
                      :key="rowToEdit.id"
                      :title="getCustomer(rowToEdit).name"
                      :show-arrows="true"
                      :open.sync="showAddEmailDialog"
                      :previous-disabled="selectedIndex <= 0"
                      :next-disabled="selectedIndex === customers.length - 1 && isLastPage()"
                      @next-click="onNextClick"
                      @prev-click="onPrevClick"
    >
      <ValidationObserver v-slot="{ handleSubmit }">
        <base-form :loading="rowToEdit.loading"
                   layout="modal"
                   @cancel="$emit('cancel')"
                   @submit="handleSubmit(onEmailAdd)"
        >

          <div class="col-span-6">
            <base-input v-model="modelToEdit.email"
                        :label="$t('Email')"
                        :placeholder="$t('Customer Email')"
                        id="email"
                        rules="required|email">
            </base-input>
          </div>
        </base-form>
      </ValidationObserver>
    </base-form-dialog>
    <statement-preview-dialog v-if="showStatementPreviewDialog"
                              :open.sync="showStatementPreviewDialog"
                              :data="get(rowToEdit, 'attributes', {})"
                              :customer="getCustomer(rowToEdit)"
                              :company="get(rowToEdit, 'relationships.company.attributes', {})"
    />
    <DeleteDialog v-if="showConfirmDialog"
                  :title="$t(`Send Emails to ${allPagesSelected ?  `all (${totalCustomers})` : selectedEmails.length} customer(s)`)"
                  :description="$t(`This action will send email(s) to ${allPagesSelected ?  `all (${totalCustomers})` : selectedEmails.length} customer(s). Make sure to validate the statement amounts and preview the statements before sending them via email. Note that customers without an email will be skipped.`)"
                  :delete-text="$t('Send')"
                  :open="true"
                  type="primary"
                  @delete="onConfirmSendAction"
                  @cancel="onConfirmRejectAction"
    >
    </DeleteDialog>
  </div>
</template>
<script>
  import axios from 'axios'
  import { MailIcon } from 'vue-feather-icons'
  import DeleteDialog from "@/components/common/modal/DeleteDialog";
  import StatementPreviewDialog from "@/modules/accounts-receivable/components/StatementPreviewDialog";
  import { downloadFileForEntity } from "@/modules/common/util/downloadFileLocally";

  export default {
    components: {
      MailIcon,
      DeleteDialog,
      StatementPreviewDialog,
    },
    props: {
      customerId: String,
    },
    data() {
      return {
        url: '/restify/customer-statements',
        showAddEmailDialog: false,
        showStatementPreviewDialog: false,
        selectedIndex: -1,
        rowToEdit: null,
        allPagesSelected: false,
        sendEmailLoading: false,
        modelToEdit: {
          email: ''
        },
        confirmResolve: null,
        confirmReject: null,
        showConfirmDialog: false,
        selectedTemplate: 'default',
        customers: [],
      }
    },
    computed: {
      urlParams() {
        const data = {
          related: 'customer,company'
        }
        const { date } = this.$route.params
        if (date && date !== 'undefined' && date !== 'null') {
          data.date = date
        }
        if (this.customerId) {
          data.customer_id = this.customerId
        }
        return data
      },
      columns() {
        const columns = [
          {
            label: '',
            prop: 'select',
            minWidth: 40,
            maxWidth: 40,
            smallCell: true,
          },
          {
            label: this.$t('Customer'),
            prop: 'customers.name',
            maxWidth: 200,
            renderIf: () => !this.customerId
          },
          {
            label: this.$t('Email'),
            prop: 'relationships.customer.attributes.email',
            minWidth: 200,
            maxWidth: 270,
            component: 'EmailLink'
          },
          {
            label: this.$t('Due Amount'),
            prop: 'attributes.customer_data.ReceivableAmount',
            maxWidth: 130,
            component: 'FormattedPrice',
          },
          {
            label: this.$t('Date'),
            prop: 'attributes.date',
            maxWidth: 150,
            align: 'center',
            component: 'FormattedDate',
          },
          {
            label: this.$t('Sent'),
            prop: 'attributes.sent_count',
            maxWidth: 100,
            align: 'center',
          },
          {
            label: this.$t('Downloaded'),
            prop: 'attributes.downloaded_at',
            maxWidth: 150,
            align: 'center',
            component: 'FormattedDate',
          },
        ]
        return columns.filter(c => {
          if (c.renderIf) {
            return c.renderIf()
          }
          return true
        })
      },
      selectedRows() {
        return this.customers.filter(e => e.selected)
      },
      selectedEmails() {
        return this.customers.filter(e => e.selected && this.hasEmail(e))
      },
      totalCustomers() {
        const table = this.$refs.table
        if (!table) {
          return 0
        }
        return table.pagination.total
      }
    },
    methods: {
      mapCustomers(customers) {
        return customers.map(entry => {
          return {
            ...entry,
            selected: false,
          }
        })
      },
      getRowClasses(row) {
        if (this.hasDuePayments(row)) {
          return 'bg-red-50'
        }
        return ''
      },
      hasDuePayments(row) {
        const over90 = row?.attributes?.total?.Over90
        return over90 && over90 !== '0'
      },
      isLastPage() {
        const { last_page, current_page } = this.get(this.$refs, 'table.pagination', {})
        return last_page === current_page
      },
      previewStatement(row, index) {
        this.selectedIndex = index
        this.rowToEdit = row
        this.showStatementPreviewDialog = true
      },
      async sendStatement(row) {
        const customer = this.getCustomer(row)
        const confirmed = await this.$confirm({
          title: this.$t(`Send Statement Email to ${customer.name}`),
          description: this.$t(`This action will send a statement email to ${customer.name}.`),
          buttonText: this.$t('Send')
        })

        if (!confirmed) {
          return
        }
        try {
          this.$set(row, 'loading', true)
          const params = {
            template: this.selectedTemplate
          }
          const data = {
            repositories: [row.id]
          }
          await axios.post(`/restify/customer-statements/action?action=send-customer-statement`, data, { params })
          this.$success(this.$t(`Email was queued to be sent. ${customer.name} will receive the email in a couple of minutes.`))
          this.$refs.table.refresh()
        } catch (err) {
          this.$error('An error occurred while trying to send the statement emails')
        } finally {
          this.$set(row, 'loading', false)
        }
      },
      async downloadStatement(row) {
        await downloadFileForEntity(row, 'customer-statements')
      },
      onConfirmSendAction() {
        this.showConfirmDialog = false
        if (this.confirmResolve) {
          this.confirmResolve(true)
        }
      },
      onConfirmRejectAction() {
        this.showConfirmDialog = false
        if (this.confirmResolve) {
          this.confirmResolve(false)
        }
      },
      confirmSendAction() {
        this.showConfirmDialog = true
        return new Promise(resolve => {
          this.confirmResolve = resolve
        })
      },
      async sendEmails() {
        const confirmed = await this.confirmSendAction()

        if (!confirmed) {
          return
        }
        try {
          this.sendEmailLoading = true
          let payload = {
            repositories: []
          }
          if (this.allPagesSelected) {
            payload.repositories = 'all'
          } else {
            payload.repositories = this.selectedRows.map(r => r.id)
          }
          const params = {
            template: this.selectedTemplate,
            date: this.$route.params.date,
          }
          await axios.post(`/restify/customer-statements/action?action=send-customer-statement`, payload, { params })
          this.allPagesSelected = false
          this.$refs.table.selectRows(false)
          this.$success(this.$t('Statement emails are queued to be sent. The selected customers will receive their statements in a couple of minutes.'))
          this.$refs.table.refresh()
        } catch (err) {
          this.$error('An error occurred while trying to send the statements via email')
        } finally {
          this.sendEmailLoading = false
        }
      },
      hasEmail(row) {
        const email = this.getEmail(row)
        return email !== '' && email !== null
      },
      getEmail(row) {
        return this.get(row, 'relationships.customer.attributes.email', '')
      },
      getCustomer(row) {
        const customerData = row.attributes.customer_data || {}
        const baseCustomer = this.get(row, 'relationships.customer.attributes', {})
        return {
          ...customerData,
          ...baseCustomer,
        }
      },
      async onNextClick() {
        if (this.selectedIndex === this.customers.length - 1) {
          if (!this.isLastPage()) {
            this.$refs.table.pagination.current_page++
            await this.$refs.table.refresh()
            this.selectedIndex = 0
            this.rowToEdit = this.customers[this.selectedIndex]
            this.modelToEdit.email = this.getEmail(this.rowToEdit)
            return
          }
          this.showAddEmailDialog = false
          return
        }
        this.selectedIndex++
        this.rowToEdit = this.customers[this.selectedIndex]
        this.modelToEdit.email = this.getEmail(this.rowToEdit)
      },
      onPrevClick() {
        this.selectedIndex--
        this.rowToEdit = this.customers[this.selectedIndex]
        this.modelToEdit.email = this.getEmail(this.rowToEdit)
      },
      addEmail(row, index) {
        this.rowToEdit = row
        this.selectedIndex = index
        this.showAddEmailDialog = true
        this.modelToEdit.email = this.getEmail(row)
      },
      onAllPagesSelected(value) {
        this.allPagesSelected = value
      },
      async onEmailAdd() {
        const customer = this.get(this.rowToEdit, 'relationships.customer.attributes', {})
        const customerId = customer.id
        const previousEmail = customer.email
        try {
          this.$set(this.rowToEdit, 'loading', true)
          customer.email = this.modelToEdit.email
          await axios.patch(`/restify/customers/${customerId}`, {
            email: this.modelToEdit.email
          })
          this.$set(this.rowToEdit, 'loading', false)
          await this.onNextClick()
        } catch (err) {
          this.$error(this.$t('Could not update the customer information'))
          customer.email = previousEmail
        } finally {
          this.$set(this.rowToEdit, 'loading', false)
        }
      }
    },
  }
</script>
