<template>
  <DetailLayout
      :loading="vendorLoading"
      :current-resource="currentResource"
      :get-current-resource="getCurrentResource"
      :select-component="VendorSelect"
      :items="items"
  >
  </DetailLayout>
</template>
<script>
  import layoutPermissions from '@/mixins/layoutPermissions'
  import detailScreen from "@/mixins/detailScreen";
  import DetailLayout from "@/modules/common/components/DetailLayout.vue";
  import VendorSelect from "@/components/select/entities/VendorSelect.vue";

  export default {
    components: { DetailLayout },
    data() {
      return {
        VendorSelect,
      }
    },
    computed: {
      items() {
        return [
          {
            name: this.$t('Details'),
            path: `/accounts-payable/vendors/${this.currentVendorId}/view`,
            authorizedTo: 'authorizedToShow',
          },
          {
            name: this.$t('Edit'),
            path: `/accounts-payable/vendors/${this.currentVendorId}/edit`,
            authorizedTo: 'authorizedToUpdate',
          },
          {
            name: this.$t('Invoices'),
            path: `/accounts-payable/vendors/${this.currentVendorId}/invoices`,
            authorizedTo: 'authorizedToShow',
          },
          {
            name: this.$t('Purchase Orders'),
            path: `/accounts-payable/vendors/${this.currentVendorId}/purchase-orders`,
            authorizedTo: 'authorizedToShow',
          },
          {
            name: this.$t('Documents'),
            path: `/accounts-payable/vendors/${this.currentVendorId}/documents`,
            authorizedTo: 'authorizedToShow',
          },
          {
            name: this.$t('Direct Deposit'),
            path: `/accounts-payable/vendors/${this.currentVendorId}/direct-deposit`,
            authorizedTo: 'authorizedToShow',
          },
        ]
      },
      vendorLoading() {
        return this.$store.state.accountsPayable.vendorLoading
      },
      currentResource() {
        return this.$store.state.accountsPayable.currentVendor
      },
      currentVendorId() {
        return this.$route.params.id
      },
    },
    methods: {
      async getCurrentResource(id) {
        await this.$store.dispatch('accountsPayable/getVendor', id)
      },
    },
  }
</script>
