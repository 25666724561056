<template>
  <ElRadioGroup
    v-model="model"
    :size="size"
    :fill="fillColor"
  >
    <ElRadioButton
      v-for="option of options"
      :key="option.value"
      :label="option.value"
    >
      {{ option.label }}
    </ElRadioButton>
  </ElRadioGroup>
</template>
<script>
import {
  RadioButton,
  RadioGroup
} from 'element-ui'

export default {
  components: {
    [RadioButton.name]: RadioButton,
    [RadioGroup.name]: RadioGroup
  },
  props: {
    value: {
      type: [String, Number, Boolean],
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
    size: {
      type: String,
      default: 'large',
    },
    defaultFill: {
      type: String,
      default: '#4299e1',
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    selectedOption() {
      return this.options.find(v => v.value === this.value)
    },
    fillColor() {
      if (this.selectedOption?.fill) {
        return this.selectedOption.fill
      }

      return this.defaultFill
    }
  }
}
</script>
