<template>
  <entity-select v-bind="$attrs"
                 v-on="$listeners"
                 :url-params="urlParams"
                 :name="$attrs.name || $t('W-2 Memo')"
                 :placeholder="$attrs.placeholder || $t('Select W-2 Memo Code')"
                 :label="$attrs.label !== undefined ? $attrs.label : $t('W-2 Memo Code')"
                 :id="$attrs.id || 'w-2-select'"
                 :add-entity="false"
                 :table-columns="tableColumns"
                 :label-format="formatLabel"
                 :hide-label="hideLabel"
                 url="restify/w-two-codes"
  />
</template>
<script>
  export default {
    name: 'W2Select',
    inheritAttrs: false,
    props: {
      urlParams: {
        type: Object,
        default: () => ({
          sort: 'code',
        }),
      },
      hideLabel: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        tableColumns: [
          {
            minWidth: 100,
            maxWidth: 150,
            name: this.$t('Code'),
            prop: 'attributes.code',
          },
          {
            minWidth: 200,
            maxWidth: 200,
            name: this.$t('Description'),
            prop: 'attributes.description',
          },
        ],
      }
    },
    methods: {
      formatLabel(op) {
        const { code, description } = op.attributes
        if (!description) {
          return code
        }
        return `${code} (${description})`
      },
    },
  }
</script>
