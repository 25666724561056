<template>
  <div>
    <base-data-table
        :url="url"
        :columns="columns"
        :url-params="urlParams"
        :show-pagination="false"
        :row-classes="getRowClasses"
        @data-fetch="dataFetch"
    >
      <template #select="{row}">
        <base-checkbox
          v-model="row.selected"
          :disabled="!canSelectRow(row)"
        />
      </template>

      <template #attributes.source_id="{row}">
        <router-link v-if="row.attributes.source_id"
                     :to="getSourceLink(row)"
                     class="truncate"
        >
        <span class="truncate">
          {{ getSourceName(row) }}
        </span>
        </router-link>
        <span v-else
              class="truncate">
        {{ getSourceName(row) }}
      </span>
      </template>

      <template #attributes.addl_source_id="{row}">
      <span class="truncate">
          {{ getAddlSourceName(row) }}
      </span>
      </template>

    </base-data-table>
    <div class="flex space-x-2 items-center text-sm text-gray-500 ml-2 mt-2">
      <div class="w-4 h-4 rounded bg-blue-200"></div>
      <div>{{ $t('Invoiced items') }}</div>
    </div>
  </div>
</template>
<script>
  import { getAddlSourceName, getSourceLink, getSourceName } from '@/components/grid-table/utils/cost-center-cell'

  export default {
    inheritAttrs: false,
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      showSelectColumn: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        lineItems: [],
      }
    },
    computed: {
      columns() {
        return [
          {
            label: '',
            prop: 'select',
            minWidth: 40,
            maxWidth: 40,
            smallCell: true,
            renderIf: () => this.showSelectColumn,
          },
          {
            label: this.$t('#'),
            prop: 'attributes.order',
            align: 'center',
            minWidth: 30,
            maxWidth: 30,
          },
          {
            label: this.$t('Cost \nCenter'),
            prop: 'attributes.cost_center',
            align: 'center',
            maxWidth: 90,
          },
          {
            label: this.$t('Source'),
            prop: 'attributes.source_id',
            align: 'center',
            maxWidth: 120,
          },
          {
            label: this.$t('Line Item'),
            prop: 'attributes.addl_source_id',
            align: 'center',
            maxWidth: 100,
          },
          {
            label: this.$t('Qty'),
            prop: 'attributes.quantity',
            align: 'right',
            minWidth: 40,
            maxWidth: 70,
          },
          {
            label: this.$t('Qty Invoiced'),
            prop: 'attributes.quantity_invoiced',
            align: 'right',
            minWidth: 40,
            maxWidth: 70,
          },
          {
            label: this.$t('Unit'),
            prop: 'attributes.um',
            maxWidth: 70,
            align: 'right',
          },
          {
            label: this.$t('Unit Price'),
            prop: 'attributes.unit_rate',
            maxWidth: 140,
            align: 'right',
            component: this.$tableColumns.FormattedPrice,
          },
          {
            label: this.$t('Amount'),
            prop: 'attributes.amount',
            maxWidth: 140,
            align: 'right',
            component: this.$tableColumns.FormattedPrice,
          },
          {
            label: this.$t('Sales Tax'),
            prop: 'attributes.sales_tax_amount',
            maxWidth: 110,
            align: 'right',
            component: this.$tableColumns.FormattedPrice,
          },
          {
            label: this.$t('Freight'),
            prop: 'attributes.freight_amount',
            maxWidth: 110,
            align: 'right',
            component: this.$tableColumns.FormattedPrice,
          },
          {
            label: this.$t('Invoiced Amount'),
            prop: 'attributes.invoiced_amount',
            maxWidth: 140,
            align: 'right',
            component: this.$tableColumns.FormattedPrice,
          },
        ]
      },
      url() {
        if (!this.data.id) {
          return
        }
        return '/restify/purchase-order-entries'
      },
      urlParams() {
        if (!this.data.id) {
          return
        }
        return {
          purchase_order_id: this.data.id,
          sort: 'order',
        }
      },
      selectedRows() {
        return this.lineItems.filter(e => e.selected)
      },
    },
    methods: {
      getSourceLink,
      getSourceName,
      getAddlSourceName,
      wasInvoiced(row) {
        return row.attributes?.invoiced_amount >= row.attributes?.amount
      },
      getRowClasses(row) {
        const wasInvoiced = this.wasInvoiced(row)
        if (wasInvoiced) {
          return '!bg-blue-50'
        }
        return ''
      },
      canSelectRow(row) {
        return !this.wasInvoiced(row)
      },
      dataFetch(data) {
        this.lineItems = data.map(item => {
          item.selected = this.canSelectRow(item)
          return item
        })

        this.$emit('update:items', data)
      },
    },
  }
</script>
