<template>
  <div v-loading="dataLoading">
    <base-form
      :loading="loading"
      :show-back="showBack"
      :show-cancel="showCancel"
      :can-create-another-entity="!model.id"
      :save-text="$t('Create purchase order adjustment')"
      :update-text="$t('Update purchase order adjustment')"
      layout="vertical"
      grid-classes="grid grid-cols-1"
      ref="baseForm"
      @cancel="$router.push('/purchasing-inventory/purchase-order-adjustments')"
      @submit="onSubmit"
    >
      <div class="col-span-1">
        <ElCollapse v-model="expandedSections">
          <BaseCollapseSection
            key="form-header"
            class="-m-6 mb-6"
            name="form-header"
            has-background
          >
            <template slot="title">
              <PurchaseOrderAdjustmentFormHeader
                :data="model"
              />
            </template>
            <div class="grid grid-cols-8 gap-4 mx-2">
              <div class="col-span-8 md:col-span-1">
                <PurchaseOrderSelect
                  v-model="model.purchase_order_id"
                  :disabled="model.id"
                  rules="required"
                  @entity-change="onChangePurchaseOrder"
                />
              </div>
              <BaseInput
                :label="$t('P.O. Number')"
                :name="$t('P.O. Number')"
                :disabled="model.purchase_order_id"
                v-model="model.number"
              />
              <div class="col-span-8 md:col-span-2">
                <VendorSelect
                  v-model="model.vendor_id"
                  :disabled="model.purchase_order_id"
                  class="col-span-8 md:col-span-1"
                  rules="required"
                />
              </div>
              <BaseInput
                v-model="model.placed_by_name"
                :label="$t('Placed By Name')"
                :name="$t('Placed By Name')"
                :placeholder="$t('Placed By Name')"
                class="col-span-8 md:col-span-2"
                rules="max:150"
              />
              <BaseDatePicker
                v-model="model.delivery_date"
                :label="$t('Delivery Date')"
                :name="$t('Delivery Date')"
                :placeholder="$t('Delivery Date')"
                class="col-span-8 md:col-span-2"
                rules="required"
              />
              <BaseTextarea
                v-model="model.description"
                :label="$t('Description')"
                :name="$t('Description')"
                :placeholder="$t('Description')"
                :rows="1"
                class="col-span-8 md:col-span-4"
                id="description"
                rules="max:150"
              />
              <BaseInput
                v-model="model.discount_percent"
                :label="$t('Discount')"
                :name="$t('Discount')"
                :min="0"
                :max="99.99"
                :step="1"
                class="col-span-8 md:col-span-1"
                rules="min_value:0|max_value:99.99"
                format="percent"
                type="number"
              />
              <SalesTaxDistrictSelect
                v-model="model.meta.sales_tax_district_id"
                :on-map-entry="onSelectDistrict"
                :add-entity="true"
                class="col-span-3 md:col-span-1"
                clearable
              />
              <BaseInput
                v-model="model.sales_tax_percent"
                v-bind="$validator.salesTaxPercent"
                :label="$t('Sales Tax')"
                :name="$t('Sales Tax')"
                class="col-span-8 md:col-span-1"
                format="percent"
                type="number"
              />
            </div>
          </BaseCollapseSection>
        </ElCollapse>
        <PurchaseOrderAdjustItems
          :data="model"
          :po-entries="poEntries"
          :is-posted="model.status === resourceStatuses.Posted"
          :purchase-order="purchaseOrder"
          ref="gridTable"
          @on-collapse-form-header="collapseFormHeader"
        />
      </div>
    </base-form>
  </div>
</template>
<script>
import axios from 'axios'
import { resourceStatuses } from '@/enum/enums'
import FileAttachments from '@/modules/accounts-payable/components/attachements/FileAttachments'
import PurchaseOrderItems from '@/modules/purchasing-inventory/components/purchase-orders/PurchaseOrderItems'
import PurchaseOrderFormHeader
  from '@/modules/purchasing-inventory/components/purchase-orders/PurchaseOrderFormHeader'
import { validateAgDataTable } from '@/components/ag-grid/tableUtils'
import PurchaseOrderAdjustItems
  from "@/modules/purchasing-inventory/components/purchase-orders/PurchaseOrderAdjustItems.vue";
import PurchaseOrderAdjustmentFormHeader
  from "@/modules/purchasing-inventory/components/purchase-orders/PurchaseOrderAdjustmentFormHeader.vue";

const metaFields = {
  sales_tax_district_id: null,
}

export default {
  components: {
    FileAttachments,
    PurchaseOrderItems,
    PurchaseOrderFormHeader,
    PurchaseOrderAdjustItems,
    PurchaseOrderAdjustmentFormHeader,
  },
  props: {
    data: {
      typ: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      resourceStatuses,
      expandedSections: [],
      loading: false,
      dataLoading: false,
      showCancel: true,
      showBack: true,
      entityId: null,
      model: this.getInitialModel(),
      poEntries: [],
    }
  },
  computed: {
    purchaseOrder() {
      if (this.selectedPurchaseOrder) {
        return this.selectedPurchaseOrder
      }
      return this.data?.purchaseOrder?.attributes
    }
  },
  methods: {
    getInitialModel() {
      return {
        purchase_order_id: null,
        status: null,
        number: null,
        amount: 0,
        freight_amount: 0,
        sales_tax_amount: 0,
        delivery_date: this.$now,
        description: null,
        placed_by_name: null,
        discount_percent: 0,
        sales_tax_percent: 0,
        meta: metaFields,
        selectedPurchaseOrder: null,
      }
    },
    onSelectDistrict(district) {
      this.model.sales_tax_percent = this.get(district, 'attributes.sales_tax_percent', 0)
    },
    async onChangePurchaseOrder(purchaseOrder) {
      if (!purchaseOrder) {
        this.model = this.getInitialModel()
        return
      }
      try {
        this.dataLoading = true
        const purchaseOrderId = purchaseOrder.id
        this.model = {
          ...this.model,
          ...purchaseOrder,
          id: undefined,
          purchase_order_id: purchaseOrderId,
        }
        const fullPurchaseOrder = await axios.get(`/restify/purchase-orders/${purchaseOrderId }`)
        const modelKeys = Object.keys(this.getInitialModel())
        modelKeys.forEach(key => {
          const value = fullPurchaseOrder.data.attributes[key]
          if (value !== undefined) {
            this.model[key] = value
          }
        })
        this.selectedPurchaseOrder = fullPurchaseOrder?.data.attributes
        await this.getPurchaseOrderEntries()
      } finally {
        this.dataLoading = false
      }
    },
    async getPurchaseOrderEntries() {
      const entries = await axios.get('/restify/purchase-order-entries', {
        params: {
          purchase_order_id: this.model.purchase_order_id,
          perPage: 500,
        },
      })
      this.poEntries = entries.data || []
    },
    async onSubmit() {
      try {
        const isInvalidData = await validateAgDataTable()
        if (isInvalidData) {
          return
        }

        this.loading = true
        if (this.model.id) {
          await axios.put(`/restify/purchase-order-adjustments/${ this.model.id }`, this.model)
          await this.$refs.gridTable.storeProgress(this.model.id)
          this.$success(this.$t(`PO Adjustment was updated successfully`))
          this.$emit('refresh')
        } else {
          const { data } = await axios.post('/restify/purchase-order-adjustments', this.model)
          await this.$refs.gridTable.storeProgress(data.id)

          this.$success(this.$t(`PO Adjustment was created successfully`))
          this.entityId = data.id
          this.$createAnotherEntity ? this.$emit('create-another') : await this.$router.push('/purchasing-inventory/purchase-orders-adjustments/pending')
        }
        await this.$store.dispatch('globalLists/getIVConfiguration', true)
      } catch (err) {
        console.warn(err)
        if (err.handled) {
          return
        }
        this.$error(this.$t('Something went wrong please try again.'))
      } finally {
        this.loading = false
      }
    },
    setDefaultProps() {
      this.model.status = this.$settings(this.$modules.IV, 'purchase_order_status') || resourceStatuses.Pending
      this.model.sales_tax_percent = this.$settings(this.$modules.IV, 'sales_tax_percent') || 0
      this.expandedSections = ['form-header']
    },
    async collapseFormHeader() {
      if (!this.expandedSections.length) {
        return
      }

      const isValidForm = await this.$refs.baseForm?.validate()

      if (!isValidForm) {
        return
      }

      this.expandedSections = []
    },
  },
  watch: {
    data: {
      immediate: true,
      handler(value) {
        if (!value.id) {
          return this.setDefaultProps()
        }

        this.model = {
          ...this.model,
          meta: { ...metaFields },
          ...value,
          vendor_id: value?.purchaseOrder?.attributes.vendor_id,
          number: value?.purchaseOrder?.attributes?.number,
        }

        this.collapseFormHeader()
      },
    },
  },
}
</script>
