<template>
  <RatesStartDates
      :url-params.sync="startDateParams"
      :entity-repo="ratesWithStartDate.WorkerComp"
      ref="intervalsWrapper"
  >
    <BaseDataTable
        :url="url"
        :url-params="urlParams"
        :columns="columns"
        :edit-entity-url-query="editEntityUrlQuery"
        :view-entity-url-query="viewEntityUrlQuery"
        :add-text="$t(`New worker's comp rate`)"
        ref="table"
        import-model="workers-comp-rates"
        entity="workers-comp-rates"
        default-sort="code"
        actions="search,refresh,import,export"
        import-url="/payroll/rates/worker-compensation-rates/import"
        hide-actions="view"
        permission="employees"
        @add="onRowAdd"
        @edit="onRowEdit"
    />
    <BaseFormDialog
        v-if="showDialog"
        :title="rowToEdit ? $t('Edit Workers Compensation Rate') : $t('Add Workers Compensation Rate')"
        :open.sync="showDialog"
        @close="onEventTrigger"
    >
      <WorkerCompensationRateForm
          :data="rowToEdit"
          :key="renderKey"
          :selected-date="startDateParams.start_date"
          @cancel="onEventTrigger"
          @save="onEventTrigger"
          @create-another="renderKey++"
      />
    </BaseFormDialog>
  </RatesStartDates>
</template>
<script>
import { formatCalculateByAmounts, ratesWithStartDate } from '@/modules/payroll/components/rates/util'
  import RatesStartDates from '@/modules/payroll/components/rates/RatesStartDates'
  import WorkerCompensationRateForm from '@/modules/payroll/components/rates/WorkerCompensationRateForm'

  export default {
    components: {
      RatesStartDates,
      WorkerCompensationRateForm,
    },
    data() {
      return {
        renderKey: 1,
        ratesWithStartDate,
        editEntityUrlQuery: '?id={ID}',
        rowToEdit: null,
        showDialog: false,
        startDateParams: {},
      }
    },
    computed: {
      columns() {
        return [
          {
            label: this.$t('Code'),
            prop: 'attributes.code',
            component: 'EntityLink',
            redirectTo: '/payroll/rates/worker-compensation-rates?id={ID}',
          },
          {
            label: this.$t('State'),
            prop: 'attributes.state',
          },
          {
            label: this.$t('Description'),
            prop: 'attributes.description',
            minWidth: 100,
            maxWidth: 200,
          },
          {
            label: this.$t('Calculate By'),
            prop: 'attributes.calculate_by',
            component: 'FormattedText',
            minWidth: 100,
            maxWidth: 200,
          },
          {
            label: this.$t('Multiply By'),
            prop: 'attributes.multiply_by',
            minWidth: 100,
            maxWidth: 200,
            component: 'FormattedText',
          },
          {
            label: this.$t('Employer Amount Or Percent'),
            prop: 'attributes.employer_rate',
            align: 'right',
            formatter: formatCalculateByAmounts,
            maxWidth: 100,
          },
          {
            label: this.$t('Employee Deduction Amount Or Percent'),
            prop: 'attributes.employee_deduction_rate',
            align: 'right',
            formatter: formatCalculateByAmounts,
            maxWidth: 100,
          },
        ]
      },
      viewEntityUrlQuery() {
        return '/payroll/rates/worker-compensation-rates'
      },
      urlParams() {
        return {
          ...this.startDateParams,
        }
      },
      url() {
        return '/restify/workers-comp-rates'
      },
    },
    methods: {
      onRowEdit(row) {
        this.showDialog = true
        this.rowToEdit = row
      },
      onRowAdd() {
        this.showDialog = true
      },
      refreshTable() {
        this.$refs.table.refresh()
      },
      refreshDateIntervals() {
        this.$refs.intervalsWrapper.refresh()
      },
      onEventTrigger() {
        this.rowToEdit = null
        this.showDialog = false
        this.refreshDateIntervals()
        this.refreshTable()
      },
    },
  }
</script>
