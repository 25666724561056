<template>
  <div>
    <div @click="showDialog = true">
      <slot name="action">
        <base-button variant="primary-light">
          {{ $t('Save Preset') }}
        </base-button>
      </slot>
    </div>

    <base-form-dialog
        v-if="showDialog"
        :title="title"
        :append-to-body="true"
        :open.sync="showDialog"
        v-on="$listeners"
    >
      <base-form :save-text="saveText"
                 :loading="loading"
                 class="col-span-6"
                 layout="modal"
                 @submit="savePreset"
                 @cancel="showDialog = false">
        <div class="col-span-6 md:col-span-3">
          <base-input
              v-model="model.name"
              :label="$t('Preset Name')"
              :placeholder="$t('Preset Name')"
              rules="required"
          />
        </div>
        <div class="col-span-6 md:col-span-3">
          <label>{{ $t('Set as public') }}</label>
          <base-switch v-model="model.public"
                       class="mr-4 mt-3"
          />
        </div>

        <template #extra-buttons-right>
          <base-submit-button
              :loading="loading"
              :disabled="!model.name"
              variant="primary-light"
              class="mr-2"
              @click="exportPreset"
          >
            {{ $t('Export') }}
          </base-submit-button>

            <base-submit-button
                v-if="model.id"
                :loading="loading"
                variant="primary-light"
                class="mr-2"
                @click="createNewPreset"
            >
              {{ $t('Create New Preset') }}
            </base-submit-button>
        </template>

      </base-form>
    </base-form-dialog>
  </div>
</template>
<script lang="ts">
  import axios from 'axios';
  import { defineComponent } from 'vue';
  import { ReportPresetJson } from "@/modules/common/components/reports/presetTypes";
  import { saveFileAsJson } from "@/modules/common/util/fileUtils";

  export default defineComponent({
    props: {
      preset: {
        type: Object,
        default: () => ({}),
      },
      payload: {
        type: Object,
        default: () => ({}),
      },
      type: String,
    },
    data() {
      return {
        showDialog: false as boolean,
        model: {
          name: '',
          public: false,
        },
        loading: false,
      }
    },
    computed: {
      title() {
        return this.preset?.id ? this.$t('Edit Preset') : this.$t('Add Preset')
      },
      saveText() {
        return this.preset?.id ? this.$t('Update Preset') : this.$t('Create Preset')
      },
    },
    methods: {
      createNewPreset() {
        return this.savePreset(false)
      },
      async exportPreset() {
        const preset = this.getPayload()
        saveFileAsJson(preset, `${preset.name}.json`)
        this.showDialog = false
      },
      getPayload(): ReportPresetJson {
        return {
          filters: this.payload,
          report_type: this.type,
          name: this.model.name,
          public: this.model.public,
        }
      },
      async savePreset(canUpdate = true): Promise<ReportPresetJson | void> {
        try {
          this.loading = true
          const payload: ReportPresetJson = this.getPayload()

          if (this.preset?.id && canUpdate) {
            await axios.put(`/restify/report-presets/${this.preset?.id}`, payload)
          } else {
            await axios.post('/restify/report-presets', payload)
          }
          this.showDialog = false
          return payload
        } catch (err: any) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not save the preset'))
        } finally {
          this.loading = false
        }
      }
    },
    watch: {
      preset: {
        immediate: true,
        handler(preset) {
          if (!preset) {
            return
          }
          this.model = preset
        },
      }
    }
  })
</script>
