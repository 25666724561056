<template>
  <BaseFreeTextSelect
    v-bind="$attrs"
    v-on="$listeners"
    :displayEmptyList="false"
    :name="$t('Check Number')"
    :label="$t('Check Number')"
    :placeholder="$t('Check Number')"
    :optionsUrl="optionsUrl"
    :transformData="transformOptions"
    :optionsTooltip="$t('Select from Unused Blank Checks')"
    :noDataText="$t('No Unused Blank Checks')"
  />
</template>
<script>
export default {
  inheritAttrs: false,
  props: {
    bankId: {
      type: String,
    },
  },
  computed: {
    optionsUrl() {
      if (!this.bankId) {
        return ''
      }

      return `/restify/banks/${this.bankId}/getters/unused-blank-checks`
    }
  },
  methods: {
    transformOptions(data) {
      return data.map(item => ({
        ...item,
        value: item.number,
        label: item.number,
      }))
    }
  },
}
</script>
