<template>
  <div class="relative bg-gray-50 overflow-hidden min-h-screen flex flex-col items-center justify-center">
    <div class="relative pb-12 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">
      <div class="mx-auto max-w-screen-xl px-4 mt-8 sm:px-6">
        <div v-if="actionsSuccess" class="text-center">
          <img class="h-24 mx-auto w-auto" src="/img/cp.png" alt="Logo"/>
          <h1 v-if="$route.query.action"
              class="page-heading text-4xl sm:text-5xl">
            {{$t('Consent')}}
            <br class="xl:hidden"/>
            <span v-if="$route.query.action === 'confirm'" class="text-primary-600">{{$t('confirmed')}}</span>
            <span v-if="$route.query.action === 'reject'" class="text-red-600">{{$t('rejected')}}</span>
          </h1>
          <p class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            <template v-if="$route.query.action === 'confirm'">
              {{ $t(`From now on you will be able to receive pay stubs and updates via email`) }}
            </template>
            <template v-if="$route.query.action === 'reject'">
              {{ $t(`From now on you will only receive a hard copy of pay stubs and no emails with pay stubs.`) }}
            </template>
          </p>
        </div>

        <div v-if="actionError" class="text-center">
          <img class="h-24 mx-auto w-auto" src="/img/cp.png" alt="Logo"/>
          <h1 class="page-heading text-4xl sm:text-5xl">
            {{$t('An error occurred')}}
          </h1>
          <p class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            {{$t('Please try to refresh the page to retry the action or')}}
          </p>
          <div class="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
            <base-button class="sm:mr-5"
                         variant="white"
                         size="xl"
                         @click="openEmail">
              {{ $t('Contact Us') }}
            </base-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'

  export default {
  data() {
    return {
      actionsSuccess: false,
      actionError: false,
    }
  },
  methods: {
    async consentAction() {
      try {
        await axios.post(`/employee-consent/action`, this.$route.query)
        this.actionsSuccess = true
      } catch (err) {
        this.actionError = true
        if (err.handled) {
          return
        }
        this.$error(this.$t('Could not communicate with the server. Please try reloading the page'))
      }
    },
    openEmail() {
      window.open(`mailto:support@constructionpartner.com?cc=russroy@constructionpartner.com&subject=Can't confirm my payroll consent`)
    }
  },
  async mounted() {
    await this.consentAction()
  }
}
</script>
<style>
</style>
