<template>
  <div>
    <router-link v-if="addEntityInNewTab"
                 :to="link"
                 data-test="button-list-add"
    >
      <base-button :variant="variant"
                   :size="size"
      >
        <div class="py-0.5 flex items-center capitalize">
          <PlusIcon class="-ml-1 mr-2 h-4 w-4"/>
          <span>{{ text }}</span>
        </div>
      </base-button>
    </router-link>
    <base-button v-else
                 :variant="variant"
                 @click="$emit('click', $event)"
                 :size="size"
                 data-test="button-list-add"
    >
      <div class="py-0.5 flex items-center capitalize">
        <PlusIcon class="-ml-1 mr-2 h-4 w-4"/>
        <span>{{ text }}</span>
      </div>
    </base-button>
  </div>
</template>
<script>
  import { PlusIcon } from 'vue-feather-icons'
  import i18n from "@/i18n";

  export default {
    components: {
      PlusIcon
    },
    props: {
      addEntityInNewTab: Boolean,
      link: [String, Object],
      variant: {
        type: String,
        default: 'primary'
      },
      text: {
        type: String,
        default: i18n.t('Add')
      },
      size: {
        type: String,
        default: 'sm'
      }
    },
  }
</script>
