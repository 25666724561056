import get from 'lodash/get'
import { Loading } from 'element-ui'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import PortalVue from 'portal-vue'
import NotificationPlugin from '@/components/common/NotificationPlugin/index'
import VeeValidatePlugin from '@/modules/common/veeValidate'
import ModalPlugin from '@/components/common/modal/modalPlugin'
import PermissionPlugin from '@/modules/auth/plugins/permissionsPlugin'
import DateFormatPlugin from './dateFormatPlugin'
import SettingsAndEnumsPlugin from './settingsAndEnumsPlugin'
import FiltersPlugin from './filtersPlugin'
import FormatPrice from './formatPrice'
import FormatHours from './formatHours'
import FormatQuantity from './formatQuantity'
import FormatPercent from './formatPercent'
import Icons from './iconsPlugin'
import ActionAuthorization from './actionAuthorization'
import clickOutside from '@/directives/click-ouside.js'
import focus from '@/directives/focus.js'
import { mask } from 'vue-the-mask'
import TableProps from './tableProps'
import PromptProps from './promptProps'
import uppercase from '@/directives/uppercase.js'
import ValidatorPlugin from "@/plugins/validatorPlugin";
import AgGridProps from "@/plugins/agGridProps";

// configure language
locale.use(lang)

export default {
  install(Vue) {
    Vue.use(ModalPlugin)
    Vue.use(NotificationPlugin)
    Vue.use(PermissionPlugin)
    Vue.use(DateFormatPlugin)
    Vue.use(VeeValidatePlugin)
    Vue.use(FiltersPlugin)
    Vue.use(FormatPrice)
    Vue.use(FormatHours)
    Vue.use(FormatQuantity)
    Vue.use(FormatPercent)
    Vue.use(Icons)
    Vue.use(PortalVue)
    Vue.use(ActionAuthorization)
    Vue.use(SettingsAndEnumsPlugin)
    Vue.use(TableProps)
    Vue.use(PromptProps)
    Vue.use(ValidatorPlugin)
    Vue.use(Loading.directive)
    Vue.directive('click-outside', clickOutside)
    Vue.directive('focus', focus)
    Vue.directive('uppercase', uppercase)
    Vue.directive('input-mask', mask)
    Vue.use(AgGridProps)
    Vue.mixin({
      methods: {
        get,
        getOptionLabel(options, value) {
          if (!options || !Array.isArray(options)) {
            return value
          }
          const option = options.find(o => o.value === value) || {}
          return option.label || value
        },
        round(number, decimals = 2) {
          let base = 1
          for (let i = 0; i < decimals; i++) {
            base = base * 10
          }
          return Math.round(number * base) / base
        },
      },
    })
  },
}
