<template>
  <div>
    <VendorLink
        :id="vendorId"
        linkClass="text-gray-600 hover:text-gray-800"
    />

  </div>
</template>
<script>
  export default {
    computed: {
      data() {
        return this.params.node?.allLeafChildren[0]?.data || {}
      },
      vendorId() {
        return this.get(this.data, 'attributes.vendor_id')
      },
    },
  }
</script>
