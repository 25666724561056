<template>
  <div>
    <router-link
        v-if="authorizedToAccessJournal"
        :to="getLink"
    >
      <StatusBadge :status="status"/>
    </router-link>

    <StatusBadge
        v-else
        :status="status"
    />
  </div>
</template>
<script>
import { appModule, resourceStatuses } from '@/enum/enums'
  import StatusBadge from '@/components/common/StatusBadge'

  const modulesMap = {
    job_costing_inits: appModule.JobCosting,
    accounts_receivable_inits: appModule.AccountsReceivable,
    accounts_payable_inits: appModule.AccountsPayable,
    invoices: appModule.AccountsPayable,
    billings: appModule.AccountsReceivable,
    invoice_payments: appModule.AccountsPayable,
    billing_payments: appModule.AccountsReceivable,
    payroll_batches: appModule.Payroll,
    default: '',
  }

  export default {
    components: {
      StatusBadge,
    },
    props: {
      id: String, // * Status value
      row: {
        type: Object,
        default: () => ({}),
      },
      editableCell: Boolean,
    },
    computed: {
      status() {
        return this.row.attributes?.status
      },
      journalId() {
        const { void_journal_id, journal_id } = this.row.attributes || {}
        return void_journal_id || journal_id
      },
      hasJournal() {
        return ![this.$resourceStatuses.Pending, this.$resourceStatuses.NoPost].includes(this.status) && this.journalId
      },
      moduleBasePatch() {
        return modulesMap[this.row.type]
      },
      authorizedToAccessJournal() {
        return this.hasJournal && !!this.moduleBasePatch && !this.editableCell
      },
      getLink() {
        return `/${this.moduleBasePatch}/journals/${this.journalId}/view`
      },
    },
  }
</script>
