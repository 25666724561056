<template>
  <OrganizationTranslations/>
</template>
<script>
  import OrganizationTranslations from '@/modules/settings/components/OrganizationTranslations'

  export default {
    components: {
      OrganizationTranslations,
    },
  }
</script>
