<template>
  <BaseReportForm
    :filters="model"
    :report-number="reportNumber"
  >
    <template #filters>

      <BaseFilterRow :title="$t('Format')">
        <BaseSelect
          v-model="model.format"
          :options="formatOptions"
          class="col-span-3"
        />

        <BaseCheckbox
          v-model="model.phase_totals"
          :label="$t('Print Phase Totals')"
          class="mb-2 col-span-3"
          id="print_phase_totals"
        />
      </BaseFilterRow>

      <CommonReportFilters
        v-model="model"
      />

      <BaseFilterRow :title="$t('Period / Year')">
        <PeriodYearFilter
          v-model="model"
          class="col-span-4"
        />
      </BaseFilterRow>

    </template>

    <template #table="{ loading, data }">
      <JobCostVarianceReportTable
        :data="data"
        :filters="model"
        :report-number="reportNumber"
        :data-loading="loading"
        ref="reportTable"
      />
    </template>
  </BaseReportForm>
</template>
<script>
import { commonFilters, JobCostVarianceReportOptions } from '@/modules/job-costing/components/reports/util'
import CommonReportFilters from '@/modules/job-costing/components/reports/CommonReportFilters'
import JobCostVarianceReportTable from '@/modules/job-costing/components/reports/job-cost-variance/JobCostVarianceReportTable.vue'
  import { PeriodYearFilter } from '@/components/range-filters'

export default {
  components: {
    CommonReportFilters,
    JobCostVarianceReportTable,
    PeriodYearFilter,
  },
  data() {
    return {
      reportNumber: '2886',
      model: {
        ...commonFilters,
        format: JobCostVarianceReportOptions.Format.Summary,
        phase_totals: false,
        fiscal_year: null,
        period: null,
      },
      // Select options
      formatOptions: [
        {
          label: this.$t('Line Item Summary'),
          value: JobCostVarianceReportOptions.Format.Summary,
        },
        {
          label: this.$t('List Cost Types'),
          value: JobCostVarianceReportOptions.Format.Detail,
        },
      ],
    }
  },
}
</script>
