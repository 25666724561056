<template>
  <div>
    <TransactionsTable
        v-if="isTransactionType"
        :journal="journal"
        :show-pagination="authorizedToPrint"
        :authorized-to-print="authorizedToPrint"
        :per-page="2000"
    >
      <template #thead-infos>
        <tr class="text-left">
          <th colspan="100%" class="thead-infos">
            <div class="flex justify-between">
              <span>{{ $t('Date') }}: {{ $formatDate(new Date()) }}</span>
              <span>{{ $t('Journal') + ' #' + journal.name + ' - ' + recordTypeName }}</span>
              <span>
                Company: {{ $companyName }}
              </span>
            </div>
          </th>
        </tr>
        <tr class="text-left">
          <th colspan="100%" class="thead-infos text-left">
            <div class="flex justify-between">
            <span>
              Time: {{ $formatDate(new Date(), 'HH:mm:ss') }}
            </span>
              <span>
                {{ $t('Year / Period') }}:
                {{ journal.fiscal_year || '' }}  -
                {{ getPeriodName(journal.period) }}
              </span>
            </div>
          </th>
        </tr>
      </template>
    </TransactionsTable>
    <AccountsReceivableInitList
      v-else-if="isARInit"
      :url-params="urlParams"
      :show-actions="false"
    />
    <AccountsPayableInitList
      v-else-if="isAPInit"
      :url-params="urlParams"
      :show-actions="false"
    />

    <JobCostInitList
      v-else-if="isJCInit"
      :url-params="urlParams"
      :show-actions="false"
    />

    <AgDataTable
        v-else
        v-bind="$attrs"
        :url-params="urlParams"
        :compact="true"
        :no-borders="true"
        :groupIncludeTotalFooter="true"
        :suppressAggFuncInHeader="true"
        hide-actions="filters"
        domLayout="autoHeight"
    >
      <template #thead-infos>
        <tr class="text-left">
          <th colspan="100%" class="thead-infos">
            <div class="flex justify-between">
              <span>{{ $t('Date') }}: {{ $formatDate(new Date()) }}</span>
              <span>{{ $t('Journal') + ' #' + journal.name + ' - ' + recordTypeName }}</span>
              <span>
                Company: {{ $companyName }}
              </span>
            </div>
          </th>
        </tr>
        <tr class="text-left">
          <th colspan="100%" class="thead-infos text-left">
            <div class="flex justify-between">
            <span>
              Time: {{ $formatDate(new Date(), 'HH:mm:ss') }}
            </span>
              <span>
                {{ $t('Year / Period') }}:
                {{ journal.fiscal_year || '' }}  -
                {{ getPeriodName(journal.period) }}
              </span>
            </div>
          </th>
        </tr>
      </template>

      <template #billing_number="{row}">
        <router-link v-if="row"
                     :to="`/accounts-receivable/billings/${getBillingType(row)}/${row.id}/view`">
          {{ row.attributes.number }}
        </router-link>
      </template>

    </AgDataTable>
  </div>
</template>
<script>
  import { types } from '@/modules/common/components/journal/util'
  import UserLink from '@/components/links/UserLink'
  import TransactionsTable from '@/modules/common/components/journal/TransactionsTable'
  import { getBillingType } from '@/modules/accounts-receivable/pages/billings/billings'
  import AccountsReceivableInitList
    from "@/modules/accounts-receivable/pages/settings/accounts-receivable-init/accounts-receivable-init-list.vue";
  import AccountsPayableInitList
    from "@/modules/accounts-payable/pages/settings/accounts-payable-init/accounts-payable-init-list.vue";
  import JobCostInitList from "@/modules/job-costing/pages/settings/job-cost-init/job-cost-init-list.vue";

  export default {
    components: {
      JobCostInitList,
      UserLink,
      TransactionsTable,
      AccountsPayableInitList,
      AccountsReceivableInitList,
    },
    props: {
      journal: {
        type: Object,
        required: true,
      },
      type: {
        type: String,
        default: types.Transaction,
      },
      urlParams: {
        type: Object,
        default: () => ({})
      },
      recordTypeName: String,
      authorizedToPrint: Boolean,
    },
    computed: {
      isTransactionType() {
        return this.type === types.Transaction
      },
      isARInit() {
        return this.type === types.AccountsReceivableInit
      },
      isAPInit() {
        return this.type === types.AccountsPayableInit
      },
      isJCInit() {
        return this.type === types.JobCostInit
      }
    },
    methods: {
      getPeriodName(period) {
        return this.$store.getters['company/getPeriodName'](period)
      },
      getBillingType(row) {
        return getBillingType(row)
      },
    },
  }
</script>
