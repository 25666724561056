<template>
  <base-report-form
      :filters="model"
      :report-number="reportNumber"
  >

    <template #filters>

      <base-filter-row :title="$t('Format')">

        <base-select
            v-model="model.double_spaced"
            :options="formatOptions"
            :add-entity="false"
            class="col-span-3"
        />

        <base-checkbox
            v-model="model.adjusted_to_cash_basis"
            :label="$t('Adjusted to Cash Basis')"
            class="col-span-3"
            id="adjusted_to_cash_basis"
        />

      </base-filter-row>

      <base-filter-row :title="$t('Fiscal Period Range')">
        <period-select
            v-model="model.period_start"
            label=""
            class="col-span-2"
        />
        <period-select
            v-model="model.period_end"
            label=""
            class="col-span-2"
        />
      </base-filter-row>

      <base-filter-row :title="$t('Period Amounts')">

        <base-select
            v-model="model.net_change_only"
            :options="printOptions"
            class="col-span-3"
        />

      </base-filter-row>

      <base-filter-row>
        <base-checkbox
            v-model="selectAccountList"
            :label="$t('Select a List of Accounts')"
            class="col-span-3 mb-3"
            id="select_account_list"
            @change="resetAccountFilters"
        />
      </base-filter-row>

      <base-filter-row :title="$t('Accounts')">

        <account-select
            v-if="selectAccountList"
            v-model="model.account_ids"
            :placeholder="$t('Select Accounts')"
            class="col-span-4"
            collapse-tags
            multiple
        />

        <template v-else>
          <account-range-filter v-model="model"/>

          <base-checkbox
              v-model="model.exclude_inactive"
              :label="$t('Exclude Inactive Accounts')"
              class="col-span-4"
              id="exclude_inactive"
          />
        </template>

      </base-filter-row>

      <base-filter-row :title="$t('Fiscal Year')">
        <fiscal-year-select
          v-model="model.fiscal_year"
          :placeholder="$t('Fiscal Year')"
          class="col-span-6 md:col-span-2"
          :show-closed-years="true"
        />
      </base-filter-row>

    </template>

    <template #table="{data, loading}">

      <trial-balance-report-table
          :data="data"
          :filters="model"
          :report-number="reportNumber"
          :data-loading="loading"
          ref="reportTable"
      />

    </template>

  </base-report-form>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { AccountRangeFilter } from '@/components/range-filters'
  import TrialBalanceReportTable from '@/modules/ledger/components/reports/TrialBalanceReportTable.vue'

  export default defineComponent({
    components: {
      AccountRangeFilter,
      TrialBalanceReportTable,
    },
    data() {
      return {
        reportNumber: '1886',
        selectAccountList: false,
        model: <GL_REPORTS.TrialBalance> {
          account_ids: [],
          account_number_from: null,
          account_number_to: null,
          exclude_inactive: 0,
          period_start: this.$currenPeriod,
          period_end: this.$currenPeriod,
          double_spaced: false,
          adjusted_to_cash_basis: false,
          net_change_only: true,
          fiscal_year: this.$currentFiscalYear,
        },
        printOptions: [
          {
            label: 'Print Net Change Only',
            value: true,
          },
          {
            label: 'Print Debits and Credits',
            value: false,
          },
        ],
        formatOptions: [
          {
            label: 'Single Spaced',
            value: false,
          },
          {
            label: 'Double Spaced',
            value: true,
          },
        ],
      }
    },
    methods: {
      resetAccountFilters() {
        this.model.account_ids = []
        this.model.exclude_inactive = 0
        this.model.account_number_from = this.model.account_number_to = null
      },
    },
  })
</script>
