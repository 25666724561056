<template>
  <BaseDataTable :add-default-columns="false"
                 :columns="columns"
                 url="restify/employee-w2-forms/batches"
                 actions="delete,search,refresh"
  >
    <template #year="{row}">
      <router-link :to="`/payroll/w2-forms-batches/${row.year}`">
        {{ row.year }}
      </router-link>
    </template>
    <template #download_percent="{row}">
      {{ $getPercent(+row.downloaded_count, +row.sent_count) }}
    </template>
    <template #extra-actions-before="{row}">
      <table-view-button @click="seeMoreClick(row)"/>
    </template>
  </BaseDataTable>
</template>
<script>
  export default {
    data() {
      return {
        columns: [
          {
            label: this.$t('Year'),
            prop: 'year',
            maxWidth: 150,
            align: 'center',
          },
          {
            label: this.$t('Forms Count'),
            prop: 'count',
            maxWidth: 100,
            align: 'center',
            component: 'Count',
          },
          {
            label: this.$t('Sent Count'),
            prop: 'sent_count',
            maxWidth: 100,
            align: 'center',
            component: 'Count',
          },
          {
            label: this.$t('Downloaded'),
            prop: 'downloaded_count',
            maxWidth: 100,
            align: 'center',
            component: 'Count',
          },
          {
            label: this.$t('Downloaded %'),
            prop: 'download_percent',
            maxWidth: 140,
            align: 'center',
          },
        ],
      }
    },
    methods: {
      seeMoreClick(row) {
        this.$router.push(`/payroll/w2-forms-batches/${row.year}`)
      },
    },
  }
</script>
