<template>
  <div class="col-span-6 grid gap-x-3 px-1"
       :class="gridCols">
    <slot/>
    <div class="col-span-2 md:col-span-1">
      <account-select v-model="model.debit_account"
                      :disabled="disabled"
                      :name="$t('Expense Account Number')"
                      :label="$t('Expense Account Number')"
                      label-key="number"
                      value-key="number"
                      id="debit_account"
                      rules="required"
      />
    </div>
    <div class="col-span-2 md:col-span-1">
      <sub-account-select v-model="model.debit_subaccount"
                          :disabled="disabled"
                          :label="$t('Expense Sub Account Number')"
                          inline-errors
                          value-key="number"
                          id="debit_subaccount"
      />
    </div>
    <div class="col-span-2 md:col-span-1">
      <account-select v-model="model.credit_account"
                      :disabled="disabled"
                      :name="$t('Credit Account Number')"
                      :label="$t('Credit Account Number')"
                      label-key="number"
                      value-key="number"
                      id="credit-account"
                      rules="required"
      />
    </div>
    <div class="col-span-2 md:col-span-1">
      <sub-account-select v-model="model.credit_subaccount"
                          :disabled="disabled"
                          :label="$t('Credit Sub Account Number')"
                          inline-errors
                          value-key="number"
                          id="credit-subaccount"
      />
    </div>
  </div>
</template>
<script>
  import AccountSelect from '@/modules/ledger/components/AccountSelect'
  import SubAccountSelect from '@/modules/ledger/components/SubAccountSelect'

  export default {
    components: {
      AccountSelect,
      SubAccountSelect,
    },
    props: {
      model: {
        type: Object,
        default: () => ({}),
      },
      disabled: Boolean,
      gridCols: {
        type: String,
        default: 'grid-cols-2',
      },
    },
  }
</script>
