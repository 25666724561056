<template>
  <div>
    <portal to="page-title">
      <div class="flex items-center">
        {{ $t('Journal ') }} #{{ currentResource.attributes.name }}
        <span v-if="currentResource.attributes.status"
              class="mx-1 md:mx-2 flex items-center">
        <status-badge :status="currentResource.attributes.status"/>
      </span>
      </div>
    </portal>
    <div class="flex justify-between items-center">
      <layout-tabs :items="filteredItems"/>
      <div class="flex space-x-2 items-center">
        <BaseTooltip
          v-if="false"
          :content="$t('You cannot change the journal posting for a closed fiscal year')"
          :disabled="!belongsToClosedYear"
        >
          <BaseButton
            variant="white"
            :disabled="belongsToClosedYear"
            @click="showChangeJournalPostingDialog = true"
          >
            {{ $t('Change Journal Posting') }}
          </BaseButton>
        </BaseTooltip>
        <ProofListingButton
          v-if="$isAuthorized('authorizedToPost', currentResource)"
          :selected-rows="[currentResource]"
          path="/ledger/journal/proof-listing"
        />
        <TablePrintButton/>
      </div>
      <ChangeJournalPostingDialog
        v-if="showChangeJournalPostingDialog"
        :open.sync="showChangeJournalPostingDialog"
        :journal="journal"
        :appendToBody="true"
        :showAllPeriods="true"
        @close="showChangeJournalPostingDialog = false"
      />
    </div>
    <router-view/>
  </div>
</template>
<script>
  import layoutPermissions from '@/mixins/layoutPermissions'
  import PrintAction from "@/components/table/actions/PrintAction.vue";
  import ChangeJournalPostingDialog from '@/modules/common/components/journal/ChangeJournalPostingDialog'

  export default {
    components: {
      PrintAction,
      ChangeJournalPostingDialog,
    },
    mixins: [layoutPermissions],
    data() {
      return {
        showChangeJournalPostingDialog: false,
      }
    },
    computed: {
      items() {
        return [
          {
            name: this.$t('Details'),
            path: `/ledger/journal/${this.$route.params.id}/view`,
            authorizedTo: 'authorizedToShow',
          },
          {
            name: this.$t('Edit'),
            path: `/ledger/journal/${this.$route.params.id}/edit`,
            authorizedTo: 'authorizedToUpdate',
          },
        ]
      },
      currentResource() {
        return this.$store.state.generalLedger.currentJournal || {}
      },
      journal() {
        const journal = this.currentResource
        return {
          ...journal?.attributes,
          ...journal?.relationships,
        }
      },
      allFiscalYears() {
        return this.$store.state.company.fiscalYears || []
      },
      fiscalYear() {
        return this.allFiscalYears.find((fy) => fy.attributes.fiscal_year == this.journal?.fiscal_year) || {}
      },
      belongsToClosedYear() {
        return this.fiscalYear?.attributes?.closed_at
      },
    },
    methods: {
      async getCurrentResource(id) {
        await this.$store.dispatch('generalLedger/getJournal', id)
      },
    },
  }
</script>
