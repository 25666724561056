<template>
  <div class="progress-group-row">
    <div class="flex space-x-2 font-medium">
      <span>{{ $t('Job:') }}</span>
      <JobLink :data="job" class="job-link"/>
      <span class="pl-10 text-sm">{{ $t('Retention: ') }}</span>
      <span class="text-sm">{{ formatPercent(job.subcontract_retention_percent) }}</span>
    </div>
  </div>
</template>
<script>
import { formatPercent } from "@/plugins/formatPercent";

export default {
  methods: { formatPercent },
  computed: {
    data() {
      return this.params.node?.allLeafChildren[0]?.data || {}
    },
    job() {
      return this.data?.job || {}
    },
    level() {
      return this.params.node.level
    }
  }
}
</script>
<style lang="scss">
.progress-group-row {
  .job-link a {
    @apply text-sm font-medium;
  }
}
</style>
