<template>
  <div class="relative base-input-errors">
    <slot v-if="!showTooltip"/>
    <p v-if="help" class="text-sm text-gray-500">{{ help }}</p>
    <div v-if="!showTooltip"
         :class="{'h-5 mb-1': !showTooltip}"
    >
        <p v-for="error in errors"
           :key="error" :title="error"
           class="text-sm text-red-600 truncate form-item-error"
        >
          {{ error }}
        </p>
    </div>
    <template v-if="showTooltip">
      <template v-if="errors.length">
        <slot></slot>
        <span class="errors-wrapper">
          {{ errors.join('.') }}
        </span>

      </template>
      <template v-else>
        <slot></slot>
      </template>
    </template>
  </div>
</template>
<script>

  export default {
    inheritAttrs: false,
    components: {},
    props: {
      help: {
        type: String,
        default: '',
      },
      errors: {
        type: Array,
        default: () => [],
      },
      showTooltip: Boolean,
    },
  }
</script>
<style lang="scss">
  .base-input-errors .errors-wrapper {
      left: 0;
      visibility: hidden;
      @apply bg-red-600 text-xs px-3 py-2;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      position: absolute;
      z-index: 2;
      bottom: 45px;
  }

  .base-input-errors:hover .errors-wrapper {
    visibility: visible;
  }

  .errors-wrapper::after {
    @apply block w-0 h-0 absolute;
    content: '';
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #DC2626;
    left: 10px;
    top: 100%;
  }
</style>
