import axios from 'axios'

const types = {
  SET_CURRENT_DASHBOARD: 'SET_CURRENT_DASHBOARD',
  SET_CURRENT_DASHBOARD_LOADING: 'SET_CURRENT_JOB_LOADING',
  SET_ALL_DASHBOARDS: 'SET_ALL_DASHBOARDS',
  SET_ALL_DASHBOARDS_LOADING: 'SET_ALL_DASHBOARDS_LOADING',
  ADD_DASHBOARD: 'ADD_DASHBOARD',
}

const state = {
  currentDashboard: {
    attributes: {},
    relationships: {},
    meta: {},
  },
  allDashboards: [],
  dashboardLoading: false,
  allDashboardsLoading: false,
}

const mutations = {
  [types.SET_CURRENT_DASHBOARD]: (state, data) => {
    state.currentDashboard = {
      ...state.currentDashboard,
      ...data,
    }
  },
  [types.SET_CURRENT_DASHBOARD_LOADING]: (state, value) => {
    state.dashboardLoading = value
  },
  [types.SET_ALL_DASHBOARDS]: (state, value) => {
    state.allDashboards = value
  },
  [types.SET_ALL_DASHBOARDS_LOADING]: (state, value) => {
    state.allDashboardsLoading = value
  },
  [types.ADD_DASHBOARD]: (state, value) => {
    state.allDashboards.push(value)
  }
}

const actions = {
  async getAllDashboards({ commit, rootState }, forceReload = false) {
    try {
      if (state.allDashboards.length && !forceReload || state.allDashboardsLoading) {
        return
      }
      commit(types.SET_ALL_DASHBOARDS_LOADING, true)
      const { data } = await axios.get(`/restify/dashboards`, {
        params: {
          perPage: 100,
        }
      })
      commit(types.SET_ALL_DASHBOARDS, data)
    } finally {
      commit(types.SET_ALL_DASHBOARDS_LOADING, false)
    }
  },
  async setAsMainDashboard({ commit, state }, id) {
    await axios.post(`/restify/dashboards/${id}/actions/?action=set-as-main-dashboard `, {})
  },
  async saveOrUpdateMainDashboard({ dispatch, getters, commit, state }, dashboard) {
    if (!dashboard.widgets.length) {
      return
    }
    const mainDashboard = getters.mainDashboard
    if (mainDashboard.id) {
      return axios.put(`/restify/dashboards/${mainDashboard.id}`, dashboard)
    } else {
      const { data } = await axios.post(`/restify/dashboards`, dashboard)
      dashboard.id = data.id
      data.attributes.main = true
      commit(types.ADD_DASHBOARD, data)
    }
    await dispatch('setAsMainDashboard', dashboard.id)
    dashboard.main = true
    return dashboard
  },
}

const getters = {
  mainDashboard: (state) => {
    return state.allDashboards.find(el => el.attributes.main === true) || {}
  },
}

export default {
  namespaced: true,
  types,
  state,
  mutations,
  actions,
  getters,
}
