<template>
  <base-form :loading="loading"
             :can-create-another-entity="!model.id"
             :save-text="$t('Create sub account')"
             :update-text="$t('Update sub account')"
             layout="modal"
             @cancel="$emit('cancel')"
             @submit="onSubmit"
  >
    <div class="col-span-2">
      <base-input v-model="model.number"
                  :label="$t('Sub Account Number')"
                  :disabled="disabled"
                  :placeholder="$t('1-20 characters')"
                  id="number"
                  rules="required|min:1|max:20">
      </base-input>
    </div>
    <div class="col-span-4">
      <base-input v-model="model.description"
                  :disabled="disabled"
                  :label="$t('Description')"
                  :placeholder="$t('Description')"
                  rules="max:150"
                  id="description">
      </base-input>
    </div>
  </base-form>
</template>
<script>
  import axios from 'axios'

  export default {
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        editEntityUrlQuery: '?id={ID}',
        rowToEdit: null,
        showDialog: false,
        loading: false,
        model: {
          number: '',
          description: '',
          fiscal_year: this.activeFiscalYear,
        },
      }
    },
    methods: {
      async onSubmit() {
        try {
          this.loading = true
          if (this.model.id) {
            const { data } = await axios.put(`/restify/subaccounts/${this.model.id}`, this.model);
            this.$success(this.$t('Ledger sub account updated'))
            this.$store.commit('generalLedger/SET_CURRENT_SUB_ACCOUNT', data)
          } else {
            await axios.post('/restify/subaccounts', {
              ...this.model,
              fiscal_year: this.activeFiscalYear,
            })
            this.$success(this.$t('Ledger sub account created'))
          }

          this.$createAnotherEntity ? this.$emit('create-another') : this.$emit('save', this.model)
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not update the ledger sub account'))
        } finally {
          this.loading = false
        }
      },
    },
    watch: {
      'data.attributes': {
        immediate: true,
        handler(value) {
          if (!value?.id) {
            return
          }
          this.model = {
            ...this.model,
            ...value,
          }
        },
      },
    },
  }
</script>
