export default {
  computed: {
    resourceId() {
      return this.$route.params.id
    }
  },
  methods: {
    async onResourceChange(id) {
      if (id === this.resourceId) {
        return
      }
      const newPath = this.$route.path.replace(this.resourceId, id)
      await this.$router.push(newPath)
    }
  }
}
