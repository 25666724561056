<template>
  <div class="flex justify-end">
    <BaseSelect
      :value="value"
      :options="options"
      :filterable="false"
      inline-errors
      class="w-[200px] print:hidden"
      @change="onChange"
    >
      <el-option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
        :label="option.label"
      >
        <div class="flex justify-between w-full items-center">
          <span>{{ option.label }}</span>
          <component :is="option.component"/>
        </div>
      </el-option>
    </BaseSelect>
  </div>
</template>
<script>
  import { Option } from 'element-ui'
  import { GridIcon, ListIcon } from 'vue-feather-icons'

  export default {
    components: {
      ListIcon,
      GridIcon,
      [Option.name]: Option,
    },
    props: {
      value: {
        type: String,
        default: '',
      },
      options: {
        type: Array,
        default: () => [],
      },
      viewModes: {
        type: Object,
        default: () => ({}),
      },
    },
    methods: {
      onChange(value) {
        this.$emit('update:viewMode', value)
      },
    },
  }
</script>
