<template>
  <ExportInitsButton
    url="/restify/journal-entries/actions?action=journal-entry-export-action"
    :columns="[
      'account',
      'subaccount',
      'description',
      'journal.name',
      'journal.type',
      'reference_no',
      'reference_date',
      'amount',
     ]"
    :url-params="{
      journal_id: id,
    }"
    class="mr-4 flex"
    v-bind="$attrs"
  />
</template>
<script>
import ExportInitsButton from "@/modules/common/components/export/ExportInitsButton.vue";

export default {
  components: {
    ExportInitsButton
  },
  props: {
    id: {
      type: String,
    }
  }
}
</script>
