<template>
  <base-form-dialog
      v-bind="$attrs"
      v-on="$listeners"
      :title="isEdit ? $t('Update Document') : $t('Upload Document')"
  >
    <base-form
        layout="modal"
        :model="model"
        :loading="loading"
        :save-text="isEdit ? $t('Update Document') : $t('Upload Document')"
        @cancel="onCancel"
        @submit="onSubmit"
    >
      <FileDropzone
          v-model="model.file"
          :multiple="false"
          :file-progress="fileProgress"
          :file-name="document?.attributes?.file_name"
          :file-id="document?.id"
          :disabled="document?.id"
          :accept="acceptedFileTypes"
          class="mb-6 mt-2 col-span-6"
          @input="onFileChange"
      />

      <base-input v-model="model.name"
                  :label="$t('Name')"
                  :placeholder="$t('Name')"
                  id="name"
                  class="col-span-6 lg:col-span-3"
                  rules="required|max:100"
      />
      <document-type-select
          v-model="model.document_type_id"
          :label="$t('Document Type')"
          :placeholder="$t('Document Type')"
          :module="usedInModule"
          id="document_type"
          rules="required"
          class="col-span-6 lg:col-span-3"
      />
      <base-date-picker v-model="model.start_date"
                        :label="$t('Start Date')"
                        :placeholder="$t('Start Date')"
                        id="start_date"
                        class="col-span-6 lg:col-span-3"
      />
      <base-date-picker v-model="model.expiration_date"
                        :label="$t('Expiration Date')"
                        :placeholder="$t('Expiration Date')"
                        id="expiration_date"
                        class="col-span-6 lg:col-span-3"
      />
      <base-select
          v-model="model.tags"
          multiple
          filterable
          allow-create
          default-first-option
          :reserve-keyword="false"
          :label="$t('Tags')"
          :placeholder="$t('Add tags for this document')"
          :no-data-text="$t('Type to create a tag')"
          class="col-span-6"
      />
    </base-form>
  </base-form-dialog>
</template>
<script lang="ts" setup>
import {computed, PropType, ref, watch} from "vue";
import {
  acceptedFileTypes,
  Document,
  getFileWithoutExtension,
  updateFileProgress
} from "@/modules/common/components/documents/documentUtils";
import {FileProgress} from "@/modules/common/components/documents/documentTypes";
import FileDropzone from "./FileDropzone.vue";
import {error, success} from "@/components/common/NotificationPlugin";
import i18n from "@/i18n";
import {getFileExtension} from "@/modules/common/util/fileUtils";
import axios from "axios";
import Data = API.Data;
import DocumentTypeSelect from "@/components/select/entities/DocumentTypeSelect.vue";
import {useRoute} from "vue2-helpers/vue-router";
import {$modules} from "@/enum/enums";
import {modules} from "@/modules/common/components/settings/util";

const props = defineProps({
  document: {
    type: Object as PropType<Data<Document>>,
    default: null,
  },
  uploadUrl: {
    type: String,
  },
  isEdit: {
    type: Boolean,
    default: false,
  }
})

const loading = ref(false)
const model = ref({
  id: '',
  name: '',
  document_type_id: '',
  tags: [] as string[],
  file: null as File | null | string,
  start_date: '' as Date | string,
  expiration_date: '' as Date | string,
})

const fileProgress = ref<FileProgress>({})

watch(() => props.document, (value) => {
  if (!props.isEdit) {
    return
  }
  const {name, start_date, expiration_date, tags, url, document_type_id } = value?.attributes || {}

  model.value = {
    id: value?.id as string,
    name,
    start_date,
    expiration_date,
    tags,
    document_type_id,
    file: url,
  }
}, {immediate: true})

function onFileChange(file: File) {
  if (file) {
    model.value.name = getFileWithoutExtension(file.name)
  } else {
    model.value.name = ''
  }
}

const route = useRoute()
const modulePath = computed(() => {
  return route.path.split('/')[1]
})

const UsedInByModule = {
  [modules.AccountsPayable]: $modules.AP,
  [modules.AccountsReceivable]: $modules.AR,
  [modules.JobCosting]: $modules.JC,
  [modules.Payroll]: $modules.PR,
  [modules.ServiceBilling]: $modules.SB,
  [modules.Inventory]: $modules.INV,
  [modules.Equipment]: $modules.EQP,
  [modules.GeneralLedger]: $modules.GL,
  [modules.Settings]: $modules.SETTINGS,
}

const usedInModule = computed(() => {
  return UsedInByModule[modulePath.value]
})


function onCancel() {
  emit('close')
}

function prepareFormData(): FormData {
  const formData = new FormData()
  const firstFile = model.value.file as File
  let {id, start_date, expiration_date, name, document_type_id}: any = model.value

  if (id) {
    formData.append('id', id)
  }
  formData.append('name', name)
  formData.append('document_type_id', document_type_id)
  model.value.tags.forEach((tag: any) => {
    formData.append('tags[]', tag)
  })
  if (start_date && typeof start_date === 'object') {
    start_date = start_date.toISOString()
  }
  if (expiration_date && typeof expiration_date === 'object') {
    expiration_date = expiration_date.toISOString()
  }
  if (start_date) {
    formData.append('start_date', start_date)
  }
  if (expiration_date) {
    formData.append('expiration_date', expiration_date)
  }

  if (typeof firstFile !== 'object') {
    return formData
  }

  updateFileProgress({file: firstFile, progress: 0, fileProgress})
  const extension = getFileExtension(firstFile)
  const fileName = `${model.value.name}.${extension}`
  formData.append('file', firstFile, fileName)

  return formData
}


const emit = defineEmits(['save', 'close'])

async function onSubmit() {
  try {
    if (!model.value.file) {
      error(i18n.t('Please select a file'))
      return
    }
    loading.value = true
    const formData = prepareFormData()
    const firstFile = model.value.file

    const uploadUrl = props.uploadUrl as string
    if (props.isEdit) {
      await axios.patch(`/restify/documents/${model.value.id}`, model.value)
    } else {
      await axios.post(uploadUrl, formData, {
        onUploadProgress: (data: any) => {
          const progress = data.loaded / data.total
          updateFileProgress({file: firstFile, progress: progress * 100, fileProgress})
        },
      })
      updateFileProgress({file: firstFile, progress: 100, fileProgress})
    }
    if (props.isEdit) {
      success(i18n.t('Document updated successfully'))
    }
    emit('save')
  } catch (err: any) {
    console.log(err)
    if (err.handled) {
      return
    }
  } finally {
    loading.value = false
  }
}


</script>
