<template>
  <BaseDetailCard
      :title="$t('Worksite Details')"
      :can-expand="false"
      :expanded="true"
      entity-name="worksite"
  >
    <BaseDetailCardRow :title="$t('Type')">
      <span>{{ worksiteType }}</span>
    </BaseDetailCardRow>
    <BaseDetailCardRow :title="$t('Directions')">
      <span>{{ workSiteData.cross_streets }}</span>
    </BaseDetailCardRow>
    <BaseDetailCardRow :title="$t('Sales Person')">
      <div v-if="workSiteData.sales_person_id">
        <EmployeeLink :data="workSite?.relationships?.salesPerson"/>
      </div>
      <div v-else>
        {{ $t('No sales person assigned') }}
      </div>
    </BaseDetailCardRow>
    <BaseDetailCardRow :title="$t('S/B Category')">
      <div class="text-gray-600 font-medium">
        <template v-if="workSiteData.category_id">
          {{ workSite?.relationships?.category?.attributes?.code }}
        </template>
        <template v-else>
          {{ $t('No S/B category assigned') }}
        </template>
      </div>
    </BaseDetailCardRow>
    <BaseDetailCardRow :title="$t('Notes')">
      <div v-if="workSiteData.user_notes?.length" class="mb-2">
        <div v-for="(note, index) in workSiteData.user_notes" :key="index" class="text-gray-600 font-medium">
          {{ note }}
        </div>
      </div>
      <div v-else>
        {{ $t('No notes provided') }}
      </div>
    </BaseDetailCardRow>
  </BaseDetailCard>
</template>
<script lang="ts" setup>
import {computed, PropType} from 'vue'
import {WorkSite} from '@/modules/common/types/models'
import {workSiteTypeLabels} from "@/modules/service-billing/util/service-billing";
import Data = API.Data;

const props = defineProps({
  workSiteData: {
    type: Object as PropType<WorkSite>,
    default: () => ({}),
    required: true,
  },
  workSite: {
    type: Object as PropType<Data<WorkSite>>,
    default: () => ({}),
    required: true,
  }
})

const worksiteType = computed(() => {
  const type = props.workSiteData?.worksite_type
  return workSiteTypeLabels[type] || type
})
</script>
