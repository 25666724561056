<template>
  <BaseCard class="border">
    <div class="text-sm flex flex-col space-y-4">
      <div
          v-if="employeeData.status === employeeStatuses.SALARY"
          :title="$t('Salary')"
          class="detail-row"
      >
        <div v-html="walletIcon" class="detail-icon"/>
        <div class="flex w-full justify-between space-x-2">
          <span class="text-sm text-gray-500">{{ $t('Salary') }}</span>
          <span>{{ formatPrice(employeeData.salary_amount) }}</span>
        </div>
      </div>
      <router-link
          :title="$t('Regular Rate')"
          :to="`${employeeLink}/rates/pay-rates`"
          class="detail-row">
        <svg class="detail-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
             stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"/>
        </svg>

        <div class="flex w-full justify-between space-x-2">
          <span class="text-sm text-gray-500">{{ $t('Regular Rate') }}</span>
          <span>{{ formatPrice(rates.regular.rate) }}</span>
        </div>
      </router-link>

      <router-link
          :title="$t('Benefits')"
          :to="`${employeeLink}/rates/benefit-rates`"
          class="detail-row">
        <svg class="detail-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path
              d="M5.00488 9.00256C5.55717 9.00256 6.00488 9.45027 6.00488 10.0026C7.63965 10.0026 9.14352 10.5629 10.3349 11.5021L12.5049 11.5026C13.837 11.5026 15.0339 12.0814 15.8579 13.0013L19.0049 13.0026C20.9972 13.0026 22.7173 14.1678 23.521 15.854C21.1562 18.9746 17.3268 21.0026 13.0049 21.0026C10.2142 21.0026 7.85466 20.3993 5.944 19.3446C5.80557 19.7281 5.43727 20.0026 5.00488 20.0026H2.00488C1.4526 20.0026 1.00488 19.5548 1.00488 19.0026V10.0026C1.00488 9.45027 1.4526 9.00256 2.00488 9.00256H5.00488ZM6.00589 12.0026L6.00488 17.0237L6.05024 17.057C7.84406 18.3175 10.183 19.0026 13.0049 19.0026C16.0089 19.0026 18.8035 17.8469 20.84 15.8731L20.9729 15.7396L20.8537 15.6392C20.3897 15.2762 19.8205 15.0509 19.2099 15.0095L19.0049 15.0026L16.8932 15.0016C16.9663 15.3235 17.0049 15.6585 17.0049 16.0026V17.0026H8.00488V15.0026L14.7949 15.0016L14.7605 14.9231C14.38 14.1295 13.593 13.5678 12.6693 13.5079L12.5049 13.5026L9.57547 13.5024C8.66823 12.577 7.40412 12.0028 6.00589 12.0026ZM4.00488 11.0026H3.00488V18.0026H4.00488V11.0026ZM13.6513 3.57794L14.0046 3.9317L14.3584 3.57794C15.3347 2.60163 16.9177 2.60163 17.894 3.57794C18.8703 4.55425 18.8703 6.13716 17.894 7.11347L14.0049 11.0026L10.1158 7.11347C9.13948 6.13716 9.13948 4.55425 10.1158 3.57794C11.0921 2.60163 12.675 2.60163 13.6513 3.57794ZM11.53 4.99215C11.3564 5.16572 11.3372 5.43514 11.4714 5.62926L11.5289 5.69819L14.0039 8.17356L16.4798 5.69926C16.6533 5.52569 16.6726 5.25627 16.5376 5.0614L16.4798 4.99215C16.3062 4.81858 16.0368 4.7993 15.8417 4.93444L15.7724 4.99236L14.0033 6.76098L12.236 4.99108L12.1679 4.93429C11.973 4.7993 11.7036 4.81858 11.53 4.99215Z"
              fill="currentColor"></path>
        </svg>

        <div class="flex w-full justify-between space-x-2">
          <span class="text-sm text-gray-500">{{ $t('Benefits') }}</span>
          <span>{{ formatPrice(rates.benefits) }}</span>
        </div>
      </router-link>
      <router-link
          :title="$t('Deductions')"
          :to="`${employeeLink}/rates/deduction-rates`"
          class="detail-row">
        <svg class="detail-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path
              d="M10 5.99658C10 6.72908 9.80311 7.41559 9.45936 8.00608L12 10.5824L18.7279 3.85445C19.509 3.0734 20.7753 3.0734 21.5563 3.85445L9.44618 15.9646C9.79807 16.5601 10 17.2548 10 17.9966C10 20.2057 8.20914 21.9966 6 21.9966C3.79086 21.9966 2 20.2057 2 17.9966C2 15.7874 3.79086 13.9966 6 13.9966C6.74181 13.9966 7.43645 14.1985 8.03197 14.5504L10.5858 11.9966L8.03197 9.44276C7.43645 9.79465 6.74181 9.99658 6 9.99658C3.79086 9.99658 2 8.20572 2 5.99658C2 3.78744 3.79086 1.99658 6 1.99658C8.20914 1.99658 10 3.78744 10 5.99658ZM8 5.99658C8 4.89201 7.10457 3.99658 6 3.99658C4.89543 3.99658 4 4.89201 4 5.99658C4 7.10115 4.89543 7.99658 6 7.99658C7.10457 7.99658 8 7.10115 8 5.99658ZM21.5563 20.1387C20.7753 20.9198 19.509 20.9198 18.7279 20.1387L13.4113 14.8221L14.8255 13.4079L21.5563 20.1387ZM16 10.9966H18V12.9966H16V10.9966ZM20 10.9966H22V12.9966H20V10.9966ZM6 10.9966H8V12.9966H6V10.9966ZM2 10.9966H4V12.9966H2V10.9966ZM6 19.9966C7.10457 19.9966 8 19.1012 8 17.9966C8 16.892 7.10457 15.9966 6 15.9966C4.89543 15.9966 4 16.892 4 17.9966C4 19.1012 4.89543 19.9966 6 19.9966Z"
              fill="currentColor"></path>
        </svg>

        <div class="flex w-full justify-between space-x-2">
          <span class="text-sm text-gray-500">{{ $t('Deductions') }}</span>
          <span>{{ formatPrice(rates.deductions) }}</span>
        </div>
      </router-link>
      <!-- Display employee total cost heere instead -->
      <div
          v-if="false"
          :title="$t('Effective Rate (Regular - Deductions + Benefits)')"
           class="detail-row border-t pt-2">
        <svg class="detail-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path
              d="M22.0049 7H23.0049V17H22.0049V20C22.0049 20.5523 21.5572 21 21.0049 21H3.00488C2.4526 21 2.00488 20.5523 2.00488 20V4C2.00488 3.44772 2.4526 3 3.00488 3H21.0049C21.5572 3 22.0049 3.44772 22.0049 4V7ZM20.0049 17H14.0049C11.2435 17 9.00488 14.7614 9.00488 12C9.00488 9.23858 11.2435 7 14.0049 7H20.0049V5H4.00488V19H20.0049V17ZM21.0049 15V9H14.0049C12.348 9 11.0049 10.3431 11.0049 12C11.0049 13.6569 12.348 15 14.0049 15H21.0049ZM14.0049 11H17.0049V13H14.0049V11Z"
              fill="currentColor"></path>
        </svg>

        <div class="flex w-full justify-between space-x-2">
          <span class="text-sm text-gray-500">{{ $t('Effective Rate') }}</span>
          <span>{{ formatPrice(effectiveRate) }}</span>
        </div>
      </div>
    </div>
  </BaseCard>
</template>
<script lang="ts" setup>
  import axios from 'axios'
  import { computed, onMounted, PropType, ref } from 'vue'
  import { Employee } from "@/modules/common/types/models";
  import { formatPrice } from "@/plugins/formatPrice";
  import { employeeStatuses } from "@/enum/enums";
  import Data = API.Data;

  const walletIcon = `<svg class="detail-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.0049 7H21.0049C21.5572 7 22.0049 7.44772 22.0049 8V20C22.0049 20.5523 21.5572 21 21.0049 21H3.00488C2.4526 21 2.00488 20.5523 2.00488 20V4C2.00488 3.44772 2.4526 3 3.00488 3H18.0049V7ZM4.00488 9V19H20.0049V9H4.00488ZM4.00488 5V7H16.0049V5H4.00488ZM15.0049 13H18.0049V15H15.0049V13Z" fill="currentColor"></path></svg>`

  const props = defineProps({
    employee: {
      type: Object as PropType<Data<Employee>>,
      default: () => ({}),
      required: true,
    }
  })

  const employeeData = computed<Employee>(() => {
    return props.employee?.attributes || {}
  })

  type Rates = {
    benefits: number;
    deductions: number;
    craft_code: {
      id: string;
      code: string;
      level: string;
    };
    union: {
      id: string;
      code: string;
      level: string;
    };
    regular: {
      from: string;
      rate: number;
    }
  }

  const rates = ref<Rates>({
    benefits: 0,
    deductions: 0,
    craft_code: {
      id: '',
      code: '',
      level: '',
    },
    union: {
      id: '',
      code: '',
      level: '',
    },
    regular: {
      from: '',
      rate: 0,
    }
  })

  const effectiveRate = computed(() => {
    return rates.value.regular.rate - rates.value.deductions + rates.value.benefits
  })

  const employeeLink = computed(() => {
    return `/payroll/employees/${props.employee.id}`
  })

  async function getRates() {
    if (!props.employee.id) {
      return
    }
    const { data } = await axios.post('/restify/employees/rates-summary', {}, {
      params: {
        employee_id: props.employee.id,
      }
    })
    rates.value = data
  }

  onMounted(async () => {
    await getRates()
  })
</script>
<style lang="scss" scoped>
  .detail-icon {
    @apply h-4 w-4 text-primary-500 min-w-[16px];
  }

  .detail-row {
    @apply flex space-x-3 items-center;
  }
</style>
