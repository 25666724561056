export interface SpecialSelectionModel {
  totalPaid: number,
  totalDiscount: number
  invoiceId: string
  model: any,
}

export const paymentStatuses = {
  NotPaid: null,
  Paid: 'Paid',
  Special: 'special',
}
