import store from "@/store";
import { appModule } from '@/enum/enums'
import Cache from "@/utils/Cache";

const promises = {
  [appModule.Payroll]: [
    'getPRConfiguration',
    'getLineItemsConfig',
  ],
  [appModule.InventoryManagement]: [
    'getIVConfiguration',
    'getLineItemsConfig',
  ],
  [appModule.AccountsPayable]: [
    'getAPConfiguration',
    'getLineItemsConfig',
  ],
  [appModule.AccountsReceivable]: [
    'getARConfiguration',
    'getLineItemsConfig',
  ],
  [appModule.ServiceBilling]: [
    'getSBConfiguration',
  ],
  [appModule.GeneralLedger]: [
    'getGLConfiguration',
    'getLineItemsConfig',
  ],
  [appModule.JobCosting]: [
    'getJCConfiguration',
    'getLineItemsConfig',
  ],
  [appModule.EquipmentManagement]: [
    'getEQPConfiguration',
  ],
  [appModule.Settings]: [
    'getSettingsConfiguration',
  ],
}

export async function refreshGlobalResourcesByModule(module)  {
  Cache.removeForEntity('/configuration/list')
  await loadGlobalResourceByModule(module)
}

export async function loadGlobalResourceByModule(module) {
  const moduleLists = promises[module]

  if (!moduleLists) {
    return
  }

  const modulePromises = moduleLists.map(list => {
    return store.dispatch(`globalLists/${list}`)
  })

  await Promise.all(modulePromises)
}
