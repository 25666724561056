<template>
  <div class="payroll-batches">
    <div class="mt-8">
      <BaseDataTable :columns="columns"
                     url="/restify/mail-templates"
                     ref="table">
        <template v-slot:attributes.html="{row}">
          <div class="truncate" v-html="row.attributes.html"></div>
        </template>
        <template v-slot:extra-actions="{row}">
          <table-edit-button v-if="$can('mail_templates_update')"
                             @click="seeMoreClick(row)">
          </table-edit-button>
        </template>
      </BaseDataTable>
    </div>
  </div>
</template>
<script>
import TableEditButton from "@/components/table/actions/TableEditButton";
export default {
  components: {
    TableEditButton,
  },
  data() {
    return {
      rowToEdit: null,
      columns: [
        {
          label: this.$t('Subject'),
          prop: 'attributes.subject',
          maxWidth: 200,
        },
        {
          label: this.$t('Content'),
          prop: 'attributes.html',
          maxWidth: 200,
        },
      ]
    }
  },
  methods: {
    seeMoreClick(row) {
      this.$router.push(`/settings/email-templates/${row.id}`)
    },
  }
}
</script>
