<template>
  <div>
    <portal to="po-summary">
      <div class="flex items-center">
        <div class="summary">
          {{ $t('Amount') }}
          <span>
              {{ $formatPrice(summaries.amount) }}
            </span>
        </div>
        <div class="summary">
          {{ $t('Tax') }}
          <span>
              {{ $formatPrice(summaries.sales_tax_amount) }}
            </span>
        </div>
        <div class="summary">
          {{ $t('Freight') }}
          <span>
              {{ $formatPrice(summaries.freight_amount) }}
            </span>
        </div>
        <div class="summary">
          {{ $t('Total') }}
          <span>
              {{ $formatPrice(summaries.extended_amount) }}
            </span>
        </div>
      </div>
    </portal>
    <AgDataTable
        v-bind="editableTableProps"
        :copy-previous-row-enabled-by-default="hasSpecialUrlParams"
        :columns="columns"
        :url="url"
        :url-params="urlParams"
        :add-text="$t('New entry')"
        :transform-data="mapData"
        :get-empty-row="getEmptyEntry"
        :default-filters="false"
        :readOnly="readOnly"
        :show-cells-legend="!readOnly"
        :groupIncludeTotalFooter="true"
        :no-borders="readOnly"
        :actions="tableActions"
        :resizable="false"
        suppressColumnReordering
        ref="gridTable"
        id="gridTable"
        @cell-focused="onCellFocused"
        @cell-value-changed="onCellValueChanged"
        @grid-ready="grid = $event"
    />
    <div class="tip mt-2 flex space-x-2 ml-1" v-if="data.instructions">
      <span>{{ $t('Special Instructions:') }}</span>
      <span>{{ data.instructions }}</span>
    </div>
  </div>
</template>
<script>
import { getCostCenterFields, hasSpecialUrlParams, onChangeCostCenter } from '@/modules/common/util/costCenterUtils'
  import { costCenterTypes } from '@/components/grid-table/utils/cost-center'
  import { editableTableProps, getTableData, storeBatchEntriesProgress } from '@/components/ag-grid/tableUtils'
  import { cellClasses, getCellClasses, requiredValueSetter } from '@/components/ag-grid/columnUtils'
  import {
    additionalSourceCol,
    allCostCenterOptions,
    costCenterCol,
    descriptionCol,
    isSourceEditable,
    quantityCol,
    sourceCol,
    typeCol,
    updateCostCenterHeaderNames,
  } from '@/components/ag-grid/columns/costCenterColumns'
  import { addressTypes } from '@/enum/enums'
  import { getDeleteColumn } from "@/components/ag-grid/columns/deleteColumns";
import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";
import { modules } from "@/modules/common/components/settings/util";

  const ResourceEndpoint = '/restify/purchase-order-entries'

  const summaryFields = {
    Quantity: 'quantity',
    UnitPrice: 'unit_rate',
    ExtendedAmount: 'amount',
  }

  const computedFields = [summaryFields.UnitPrice, summaryFields.ExtendedAmount, summaryFields.Quantity]

  export default {
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      readOnly: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        editableTableProps,
        grid: null,
        entries: [],
      }
    },
    computed: {
      url() {
        return !this.data.id ? '' : ResourceEndpoint
      },
      urlParams() {
        return !this.data.id ? {} : {
          purchase_order_id: this.data.id,
          sort: 'order',
          related: 'address,source[id|number|description],addlSource[id|phase_code|cost_code|change_order|description]',
          perPage: 200,
        }
      },
      hasSpecialUrlParams() {
        return hasSpecialUrlParams(this.$route.query)
      },
      tableActions() {
        return this.readOnly ? '' : 'add'
      },
      columns() {
        return [
          {
            ...costCenterCol,
            cellEditorParams: {
              options: allCostCenterOptions,
            },
            valueSetter: params => {
              params.data.cost_center = params.newValue
              const isGA = params.data?.cost_center === costCenterTypes.GeneralAndAdministrative
              params.data.address_id = isGA ? this.companyPrimaryAddressId : null
              params.data = onChangeCostCenter(params.data)
              params.node.setData(params.data)
              return true
            },
          },
          {
            ...sourceCol,
            headerName: this.readOnly ? this.$t('Source') : ' ',
            minWidth: 80,
            maxWidth: 100,
            editable: params => this.isSourceEditable(params),
            suppressNavigable: params => !this.isSourceEditable(params),
            cellRendererParams: {
              target: '_blank',
              showDescription: false,
            },
            cellClass: params => {
              if (params.node.footer || this.readOnly) {
                return ''
              }

              if (!isSourceEditable(params)) {
                return cellClasses.ReadOnly
              }

              const hasValue = params.data?.source_id
              return hasValue ? '' : cellClasses.Invalid
            }
          },
          {
            ...typeCol,
            headerName: this.readOnly ? this.$t('Type') : ' ',
            editable: params => this.isSourceEditable(params),
            suppressNavigable: params => !this.isSourceEditable(params),
            cellClass: params => {
              if (params.node.footer || this.readOnly) {
                return ''
              }

              const isEditable = this.isSourceEditable(params)
              if (!isEditable) {
                return cellClasses.ReadOnly
              }

              const hasValue = params.data?.type_id
              return hasValue ? '' : cellClasses.Invalid
            },
          },
          {
            ...additionalSourceCol({
              readOnly: this.readOnly,
            }),
            cellRendererParams: {
              target: '_blank',
              showDescription: false,
            },
            headerName: this.readOnly ? this.$t('Line Item') : ' ',
            minWidth: 90,
            maxWidth: 120,
            cellEditorParams: params => {
              const { cost_center } = params.data

              const resourceMapping = {
                [costCenterTypes.Equipment]: this.$globalResources.EquipmentMaintenances,
              }

              const resourceName = resourceMapping[cost_center]

              return {
                resourceName,
                filterMethod: (option) => {
                  if (cost_center !== costCenterTypes.Job) {
                    return true
                  }
                  const isSubType = this.isSubContractType(params)
                  if (!isSubType) {
                    return true
                  }
                  // show only line items without a subcontractor vendor or with the same vendor id as the invoice
                  return !option.vendor_id || option.vendor_id === this.vendor?.id
                },
              }
            },
          },
          {
            headerName: this.$t('Address'),
            field: 'address_id',
            component: 'LocationLink',
            cellEditor: this.$cellEditors.AddressSelect,
            cellEditorParams: (params) => {
              const cost_center = params.data?.cost_center
              let address_type = addressTypes.Warehouse
              let entityId = params.data?.source_id
              if (cost_center === costCenterTypes.Job)  {
                address_type = addressTypes.Job
              } else if (cost_center === costCenterTypes.Equipment || cost_center === costCenterTypes.WorkOrder) {
                entityId = this.$currentCompany.id
              }
              return {
                addressable_id: entityId,
                address_type: address_type,
                formParams: {
                  addressType: address_type,
                  entityId,
                },
                onValueChanged: (params, newValue, fullValue) => {
                  if (!params.data.relationships) {
                    params.data.relationships = {}
                  }
                  params.data.relationships.address = fullValue
                  params.node.setData(params.data)
                }
              }
            },
            editable: true,
            cellClass: getCellClasses,
            minWidth: 160,
            maxWidth: 220,
          },
          {
            headerName: this.$t('Material'),
            field: 'material_id',
            cellEditor: this.$cellEditors.GlobalResourceSelect,
            component: 'MaterialLink',
            cellEditorParams: {
              resourceName: this.$globalResources.Materials,
              target: '_blank',
            },
            cellClass: params => {
              if (params.node.footer) {
                return ''
              }

              const hasValue = params.data?.material_id
              const isInventoryCostCenter = params.data?.cost_center === costCenterTypes.Inventory

              if (!hasValue && isInventoryCostCenter) {
                return cellClasses.Invalid
              }

              return ''
            },
            valueSetter: params => {
              params.data.material_id = params.newValue
              this.onChangeMaterial(params)
              return true
            },
            editable: true,
            minWidth: 80,
            maxWidth: 90,
          },
          {
            ...descriptionCol,
            suppressKeyboardEvent: params => {
              let isTabKey = params.event.key === 'Tab'
              if (isTabKey) {
                params.api.stopEditing()
              }
            },
          },
          {
            headerName: this.$t('Fixed price'),
            field: 'is_fixed_price',
            editable: true,
            minWidth: 80,
            maxWidth: 90,
            component: 'Status',
            cellEditor: cellEditors.Boolean,
            valueSetter: params => {
              const value = !!params.newValue
              params.data.is_fixed_price = value
              if (!value) {
                params.data.unit_rate = 0
                params.data.quantity = 0
              }
              return true
            }
          },
          {
            ...quantityCol,
            maxWidth: 80,
            valueSetter: params => {
              const isValid = requiredValueSetter(params, 0)
              if (!isValid) {
                return false
              }

              this.onCellValueChanged(params)
              return true
            },
            editable: this.isQuantityEditable,
            cellClass: this.fixedPriceCellClass,
          },
          {
            ...quantityCol,
            headerName: this.$t('Invoiced Qty'),
            field: 'quantity_invoiced',
            editable: false,
            valueGetter: this.fieldValueGetter,
            maxWidth: 80,
            hide: !this.readOnly,
          },
          {
            headerName: this.$t('Unit'),
            field: 'um',
            minWidth: 80,
            maxWidth: 90,
            editable: this.isQuantityEditable,
            cellClass: this.fixedPriceCellClass,
          },
          {
            headerName: this.$t('Unit Price'),
            field: 'unit_rate',
            component: 'FormattedPrice',
            cellEditor: this.$cellEditors.Numeric,
            valueGetter: this.fieldValueGetter,
            valueSetter: params => {
              const isValid = requiredValueSetter(params, 0)
              if (!isValid) {
                return false
              }

              this.onCellValueChanged(params)
              return true
            },
            minWidth: 100,
            maxWidth: 140,
            editable: this.isQuantityEditable,
            cellClass: this.fixedPriceCellClass,
          },
          {
            headerName: this.$t('Amount'),
            field: 'amount',
            component: 'FormattedPrice',
            cellEditor: this.$cellEditors.Numeric,
            valueGetter: this.fieldValueGetter,
            valueSetter: params => {
              const isValid = requiredValueSetter(params, 0)
              if (!isValid) {
                return false
              }

              this.onCellValueChanged(params)
              return true
            },
            editable: true,
            minWidth: 100,
            maxWidth: 140,
            aggFunc: 'sum',
          },
          {
            headerName: this.$t('Sales Tax'),
            field: 'sales_tax_amount',
            component: 'FormattedPrice',
            cellEditor: this.$cellEditors.Numeric,
            valueGetter: this.fieldValueGetter,
            valueSetter: requiredValueSetter,
            editable: true,
            minWidth: 100,
            maxWidth: 140,
            aggFunc: 'sum',
          },
          {
            headerName: this.$t('Freight'),
            field: 'freight_amount',
            component: 'FormattedPrice',
            cellEditor: this.$cellEditors.Numeric,
            valueGetter: this.fieldValueGetter,
            valueSetter: requiredValueSetter,
            editable: true,
            minWidth: 100,
            maxWidth: 140,
            aggFunc: 'sum',
          },
          {
            headerName: this.$t('Gross Amount'),
            field: 'extended_amount',
            component: 'FormattedPrice',
            editable: false,
            minWidth: 100,
            maxWidth: 140,
            aggFunc: 'sum',
            hide: !this.readOnly,
          },
          {
            headerName: this.$t('Invoiced Amount'),
            field: 'invoiced_amount',
            component: 'FormattedPrice',
            editable: false,
            minWidth: 100,
            maxWidth: 140,
            aggFunc: 'sum',
            hide: !this.readOnly,
          },
          {
            ...getDeleteColumn({
              url: ResourceEndpoint,
              hide: this.readOnly,
            }),
          },
        ]
      },
      companyPrimaryAddressId() {
        return this.$store.getters['company/getActiveCompanyPrimaryAddressId']
      },
      summaries() {
        const data = {
          amount: 0,
          sales_tax_amount: 0,
          freight_amount: 0,
          extended_amount: 0,
        }

        this.entries.forEach(entry => {
          data.amount += +entry.amount
          data.sales_tax_amount += +entry.sales_tax_amount
          data.freight_amount += +entry.freight_amount
        })

        data.extended_amount = data.amount + data.sales_tax_amount + data.freight_amount

        return data
      },
      getJobSubcontractTypeId() {
        return this.$store.getters['globalLists/getJobSubcontractTypeId']
      },
    },
    methods: {
      fieldValueGetter(params) {
        const field = params?.colDef?.field
        return params?.data ? +params.data[field] : 0
      },
      fixedPriceCellClass(params) {
        if (params?.data?.is_fixed_price) {
          return `${cellClasses.ReadOnly} !text-gray-100`
        }
      },
      isQuantityEditable(params) {
        return !params?.data?.is_fixed_price
      },
      async storeProgress(purchase_order_id) {
        const gridApi = this.grid.api
        await storeBatchEntriesProgress(gridApi, 'purchase-order-entries', { purchase_order_id })
        this.refreshData()
      },
      refreshData() {
        this.$refs.gridTable.refresh()
      },
      isSourceEditable(params) {
        if (!params.data?.cost_center) {
          return false
        }
        return ![costCenterTypes.GeneralAndAdministrative, costCenterTypes.Inventory].includes(params.data.cost_center)
      },
      isSubContractType(params) {
        return params.data?.type_id === this.getJobSubcontractTypeId
      },
      onChangeMaterial(params) {
        let entry = params.data
        const { um, standard_unit_rate, description } = this.getMaterialResource(entry.material_id)
        entry.um = um
        entry.unit_rate = standard_unit_rate
        entry.description = description

        params.node.setData(entry)
      },
      getMaterialResource(id) {
        return this.$store.getters['globalLists/getResourceById'](this.$globalResources.Materials, id) || {
          um: '',
          standard_unit_rate: 0,
        }
      },
      mapData(data) {
        this.entries = data.map(item => {
          return {
            ...item.attributes,
            is_fixed_price: item?.attributes?.is_fixed_price || false,
            id: item.id,
            relationships: item.relationships,
          }
        })
        return this.entries
      },
      onCellFocused(params) {
        if (this.readOnly) {
          return
        }

        updateCostCenterHeaderNames(params)
        this.entries = getTableData(this.grid?.api)
      },
      onCellValueChanged(params) {
        const field = params?.colDef?.field
        let entry = params.data
        entry[field] = params.newValue

        if (!entry.quantity || !computedFields.includes(field)) {
          params.node.setData(entry)
          return true
        }

        if ([summaryFields.UnitPrice, summaryFields.Quantity].includes(field)) {
          entry[summaryFields.ExtendedAmount] = entry.quantity * entry.unit_rate
          entry.sales_tax_amount = parseFloat(entry.amount / 100 * this.data.sales_tax_percent).toFixed(2)
          params.node.setData(entry)
          return true
        }

        entry[summaryFields.UnitPrice] = parseFloat(entry.amount / entry.quantity).toFixed(2)

        if (this.data.sales_tax_percent) {
          entry.sales_tax_amount = parseFloat(entry.amount / 100 * this.data.sales_tax_percent).toFixed(2)
        }

        params.node.setData(entry)
        return true
      },
      getEmptyEntry() {
        this.tryCollapseFormHeader()
        const order = this.entries.length + 1
        const { source_id, cost_center } = getCostCenterFields(this.$route.query)

        return {
          order,
          material_id: null,
          purchase_order_id: this.data.id,
          source_id,
          source_type: null,
          addl_source_id: null,
          addl_source_type: null,
          type_id: null,
          type_type: null,
          cost_center,
          um: null,
          is_fixed_price: this.$settings(this.$modules.IV, 'is_po_entry_fixed_price'),
          extended_amount: 0,
          unit_rate: 0,
          quantity: 0,
          quantity_received: 0,
          amount: 0,
          sales_tax_amount: 0,
          freight_amount: 0,
          description: null,
          address_id: this.companyPrimaryAddressId,
          _localId: crypto.randomUUID(),
        }
      },
      tryCollapseFormHeader() {
        this.$emit('on-collapse-form-header')
      },
    },
  }
</script>
