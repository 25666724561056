<template>
  <base-form
      :loading="loading"
      :show-cancel="showCancel"
      :show-back="showBack"
      :save-text="$t('Create category')"
      :update-text="$t('Update category')"
      grid-classes="grid grid-cols-6 gap-x-4"
      layout="vertical"
      @cancel="$router.push('/service-billing/settings/categories')"
      @submit="onSubmit"
  >
    <base-input
        v-model="model.code"
        :label="$t('Code')"
        :placeholder="$t('Category Code')"
        :disabled="!!$route.params.id"
        class="col-span-6 md:col-span-1"
        rules="required|code"
    />
    <base-input
        v-model="model.description"
        :disabled="readonly"
        :label="$t('Description')"
        :placeholder="$t('Description')"
        class="col-span-6 md:col-span-3"
        id="description"
        rules="max:150"
    />
    <account-select
        v-model="model.equipment_utilization_account"
        :label="$t('Credit Account')"
        :tip="$t('Equipment Utilization Credit Account')"
        value-key="number"
        class="col-span-6 md:col-span-1"
    />
    <sub-account-select
        v-model="model.equipment_utilization_subaccount"
        :label="$t('Sub Account')"
        value-key="number"
        class="col-span-6 md:col-span-1"
    />
    <el-collapse v-model="activeGroup"
                 class="col-span-6">
      <base-collapse-section
          :title="$t('G/L Account Assignments (optional). They will Override S/B setup.')"
          key="assign-accounts"
          name="assign-accounts">
        <AccountAssignments
            :get-types-url="getTypesUrl"
            :add-types-url="addTypesUrl"
            :data="data"
            :module="modules.ServiceBilling"
            ref="accountAssignmentsTable"
            class="flex-1"
        />
        <PayrollExpenseAccounts
            :have-burden-accounts="haveBurdenAccounts"
            :data="model"
            :module-prefix="getModulePrefix"
            class="flex-1"
            ref="payrollExpenseTable"
        />
      </base-collapse-section>
    </el-collapse>
  </base-form>
</template>
<script>
  import axios from 'axios'
  import { expenseAccountTypes, modulePrefixes, modules } from '@/modules/common/components/settings/util'
  import AccountAssignments from '@/modules/common/components/settings/AccountAssignments'
  import PayrollExpenseAccounts from '@/modules/common/components/settings/PayrollExpenseAccounts'

  export default {
    components: {
      AccountAssignments,
      PayrollExpenseAccounts,
    },
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      showBack: {
        type: Boolean,
        default: true,
      },
      readonly: Boolean,
    },
    data() {
      const expenseAccounts = expenseAccountTypes(modules.ServiceBilling)
      return {
        modules,
        haveBurdenAccounts: true,
        activeGroup: 'assign-accounts',
        getTypesUrl: '/restify/service-billing-types',
        addTypesUrl: '/restify/service-billing-categories',
        loading: false,
        showCancel: true,
        model: {
          code: '',
          description: '',
          equipment_utilization_account: '',
          equipment_utilization_subaccount: '',

          ...expenseAccounts,
        },
      }
    },
    computed: {
      getModulePrefix() {
        return modulePrefixes[modules.ServiceBilling]
      },
    },
    methods: {
      async onSubmit() {
        try {
          this.loading = true
          this.$refs.payrollExpenseTable?.updatePayrollExpenseAccounts()
          if (this.model.id) {
            await axios.put(`/restify/service-billing-categories/${this.model.id}`, this.model)
            this.$success(this.$t('Service Billing Category updated'))
            await this.assignTypes(this.model.id)
          } else {
            const { data } = await axios.post('/restify/service-billing-categories', this.model)
            this.$success(this.$t('Service Billing Category created'))
            await this.assignTypes(data.id)
            await this.$router.push(`/service-billing/settings/categories/${data.id}/edit`)
          }
        } catch (err) {
          console.warn(err)
          if (err.handled) {
            return
          }
          this.$error(this.$t('Something went wrong. Please try again.'))
        } finally {
          this.loading = false
        }
      },
      async assignTypes(categoryId) {
        await this.$refs.accountAssignmentsTable.assignTypes(categoryId)
      },
    },
    watch: {
      'data.attributes': {
        immediate: true,
        handler(value) {
          if (!value?.id) {
            return
          }

          this.model = {
            ...this.model,
            ...value,
          }
        },
      },
    },
  }
</script>
