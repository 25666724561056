<template>
  <AgDataTable
      :columns="summaryColumns"
      :data="tableData"
      :data-loading="loading"
      :compact="true"
      :pagination="false"
      :no-borders="true"
      dom-layout="autoHeight"
      v-bind="$attrs"
  >
  </AgDataTable>
</template>
<script>
  export default {
    name: 'ProofListingCostTypeSummary',
    props: {
      data: {
        type: [Object, Array],
        default: () => ({}),
      },
      loading: Boolean,
    },
    computed: {
      tableData() {
        const entries = []
        for (let key in this.data) {
          const amount = this.data[key] || 0
          if (!amount) {
            continue
          }
          entries.push({
            id: crypto.randomUUID(),
            type: key,
            amount,
          })
        }
        return entries
      },
      summaryColumns() {
        return [
          {
            headerName: this.$t('Cost Type'),
            field: 'type',
            minWidth: 150,
          },
          {
            headerName: this.$t('Invoice Amount'),
            field: 'amount',
            align: 'right',
            minWidth: 150,
            maxWidth: 200,
            component: 'FormattedPrice',
          },
        ]
      },
    },
  }
</script>

