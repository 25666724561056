<template>
  <EntitySelect
    url="/restify/customers"
    :transformData="transformData"
    v-bind="$attrs"
    v-on="$listeners"
    :url-params="allUrlParams"
    :name="$attrs.name || $t('Customer')"
    :placeholder="$attrs.placeholder || $t('Select Customer')"
    :label="$attrs.label !== undefined ? $attrs.label : $t('Customer')"
    :add-entity="addEntity"
    :edit-entity="editEntity"
    :set-default-option="editEntity"
    :add-label="$t('Add new Customer')"
    :on-map-entry="onChangeEntry"
    :label-format="formatCustomerLabel"
    :id="$attrs.id || 'customer-select'"
    :table-columns="customerTableColumns"
    :remote-search="true"
    :value-key="valueKey"
    :initial-value="initialValue"
    :dataFilters="dataFilters"
    label-key="code"
    class="flex-1"
  />
</template>
<script>
export default {
  inheritAttrs: false,
  props: {
    addEntity: {
      type: Boolean,
      default: true,
    },
    editEntity: {
      type: Boolean,
      default: false,
    },
    showOpenAmount: {
      type: Boolean,
      default: false,
    },
    showCashOnDeposit: {
      type: Boolean,
      default: false,
    },
    showStatusColumn: {
      type: Boolean,
      default: true,
    },
    valueKey: {
      type: String,
      default: 'id',
    },
    initialValue: {
      type: [Object, Array],
    },
    urlParams: {
      type: Object,
      default: () => ({
        sort: 'code',
      }),
    },
  },
  data() {
    return {
      dataFilters: {
        showActive: true
      },
    }
  },
  computed: {
    allUrlParams() {
      if (!this.showStatusColumn) {
        return this.urlParams
      }

      return {
        ...this.urlParams,
        status: this.dataFilters.showActive ? 'active' : null,
      }
    },
    customerTableColumns() {
      const columns =  [
        {
          minWidth: 100,
          maxWidth: 150,
          name: this.$t('Code'),
          prop: 'attributes.code',
        },
        {
          minWidth: 200,
          maxWidth: 200,
          name: this.$t('Name'),
          prop: 'attributes.name',
        },
        {
          minWidth: 140,
          maxWidth: 200,
          name: this.$t('Open Amount'),
          prop: 'attributes.current_ar_amount',
          toFormat: this.$formatPrice,
          showIf: () => this.showOpenAmount,
        },
        {
          minWidth: 120,
          maxWidth: 200,
          name: this.$t('Cash On Deposit'),
          prop: 'attributes.current_unapplied_cash_on_deposit_amount',
          toFormat: this.$formatPrice,
          showIf: () => this.showCashOnDeposit,
        },
        {
          minWidth: 120,
          maxWidth: 140,
          name: this.$t('Status'),
          prop: 'attributes.status',
          component: 'Status',
          filter: {
            type: 'switch',
            prop: 'showActive',
            tooltip: this.$t('Only show active customers'),
            onChange: (value) => {
              this.dataFilters.showActive = value
            },
          },
          showIf: () => this.showStatusColumn,
        },
      ]

      return columns.filter((column) => !column.showIf || column.showIf())
    },
    showInactiveSetting() {
      return this.$store.getters['settings/getFilterSetting']('show_inactive_customers')
    },
  },
  watch: {
    showInactiveSetting: {
      handler() {
        this.dataFilters.showActive = !this.showInactiveSetting
      },
      immediate: true
    }
  },
  methods: {
    transformData(data) {
      return data.map((customer) => ({
        ...customer,
        isInactive: customer.attributes.status !== 'active',
      }))
    },
    formatCustomerLabel(customer) {
      const { code, name } = customer?.attributes || {}
      if (!name) {
        return code
      }
      return `${code} (${name})`
    },
    onChangeEntry(customer) {
      this.$emit('on-map-entry', customer)
    },
  },
}
</script>

