<template>
  <div class="flex flex-col items-center mt-6">
    <canvas ref="canvas" class="w-[500px] h-[250px] border border-gray-200 rounded"/>

    <BaseButton
      variant="white"
      class="mt-4"
      @click="clearDrawing">
      {{ $t('Clear Drawing') }}
    </BaseButton>
  </div>
</template>
<script>
import SignaturePad from "signature_pad";

export default {
  data() {
    return {
      signaturePad: null,
    }
  },
  methods: {
    initSignaturePad() {
      const canvas = this.$refs.canvas
      if (!canvas) {
        return
      }
      this.signaturePad = new SignaturePad(canvas);
    },
    clearDrawing() {
      this.signaturePad.clear()
    },
    resizeCanvas() {
      const canvas = this.$refs.canvas
      if (!canvas || !this.signaturePad) {
        return
      }
      // When zoomed out to less than 100%, for some very strange reason,
      // some browsers report devicePixelRatio as less than 1
      // and only part of the canvas is cleared then.
      const ratio = Math.max(window.devicePixelRatio || 1, 1);

      // This part causes the canvas to be cleared
      canvas.width = canvas.offsetWidth * ratio;
      canvas.height = canvas.offsetHeight * ratio;
      canvas.getContext("2d").scale(ratio, ratio);

      // This library does not listen for canvas changes, so after the canvas is automatically
      // cleared by the browser, SignaturePad#isEmpty might still return false, even though the
      // canvas looks empty, because the internal data of this library wasn't cleared. To make sure
      // that the state of this library is consistent with visual state of the canvas, you
      // have to clear it manually.
      //signaturePad.clear();

      // If you want to keep the drawing on resize instead of clearing it you can reset the data.
      this.signaturePad.fromData(this.signaturePad.toData());
    },
    saveImage() {
      if (!this.signaturePad) {
        return
      }
      if (this.signaturePad.isEmpty()) {
        this.$error(this.$t('Please provide a signature first.'))
      } else {
        const dataURL = this.signaturePad.toDataURL();
        return this.dataURLToBlob(dataURL);
      }
    },
    dataURLToBlob(dataURL) {
      // Code taken from https://github.com/ebidel/filer.js
      const parts = dataURL.split(';base64,');
      const contentType = parts[0].split(":")[1];
      const raw = window.atob(parts[1]);
      const rawLength = raw.length;
      const uInt8Array = new Uint8Array(rawLength);

      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }

      return new Blob([uInt8Array], { type: contentType });
    }
  },
  mounted() {
    this.initSignaturePad()
    window.onresize = this.resizeCanvas;
    this.resizeCanvas();
  }
}
</script>
