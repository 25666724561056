<template>
  <dl class="mb-2">
    <dt
        v-for="field in getChangedFields(row)"
        :key="field.name"
        class="flex space-x-2 items-center"
    >
      <span class="w-40 md:w-64 lg:w-72 capitalize truncate">
        {{ formattedText(field.name) }}
      </span>

      <div class="w-40 truncate">
        <component
            v-if="componentsMap[field.field]"
            :is="componentsMap[field.field]"
            :id="field.prior"
            :status="field.prior"
        />
        <span v-else
              class="font-medium">
          {{ field.prior === null ? '' : field.prior }}
        </span>
      </div>

      <div class="w-24">-></div>
      <template>
        <component
            v-if="componentsMap[field.field]"
            :is="componentsMap[field.field]"
            :id="field.current"
            :number="field.current"
            :status="field.current"
        />
        <span v-else
              class="font-medium">
          {{ field.current === null ? '' : field.current }}
        </span>
      </template>

    </dt>
  </dl>
</template>
<script setup>
  import i18n from '@/i18n'
  import { formattedText } from '@/utils/utils'
  import { formatPrice } from '@/plugins/formatPrice'
  import { formatPercent } from '@/plugins/formatPercent'
  import { formatDate } from '@/plugins/dateFormatPlugin'
  import SalesTaxDistrictLink from '@/components/links/SalesTaxDistrictLink.vue'

  const props = defineProps({
    row: Object,
  })

  const componentsMap = {
    default_bank_id: 'BankLink',
    default_invoice_status: 'StatusBadge',
    force_discounts: 'StatusBadge',
    post_discounts_to_accounts: 'StatusBadge',
    paycode_for_fringe_build: 'PayCodeLink',
    is_ssn_masked: 'StatusBadge',
    is_fringe_build: 'StatusBadge',
    is_biweekly_payroll: 'StatusBadge',
    distribute_benefit_hours: 'StatusBadge',
    calculate_rate_using_salary: 'StatusBadge',
    apply_tax_to_billings: 'StatusBadge',
    default_status: 'StatusBadge',
    default_cpb_status: 'StatusBadge',
    default_lmp_status: 'StatusBadge',
    default_prg_status: 'StatusBadge',
    default_unt_status: 'StatusBadge',
    sales_tax_district_id: 'SalesTaxDistrictLink',
    equipment_utilization_credit_account: 'AccountLink',
    equipment_utilization_credit_subaccount: 'SubAccountLink',
    cost_type_id: 'ServiceBillingTypeLink',
    income_type_id: 'ServiceBillingTypeLink',
    retention_receivable_account: 'AccountLink',
    retention_receivable_subaccount: 'SubAccountLink',
    retention_payable_account: 'AccountLink',
    retention_payable_subaccount: 'SubAccountLink',
    default: '',
  }

  const fieldLabels = {
    default_bank_id: i18n.t('Default Bank'),
    federal_employer_id: i18n.t('Federal I.D No'),
    sales_tax_district_id: i18n.t('Sales Tax District'),
    cost_type_id: i18n.t('Cost Type'),
    income_type_id: i18n.t('Income Type'),
  }

  function isPriceField(field) {
    const priceFields = ['amount', 'cost', 'gross', 'retention']
    return priceFields.includes(field)
  }

  function isPercentField(field) {
    const percentageFields = ['workers_comp_mod_percent', 'percent', 'percentage']
    return percentageFields.includes(field)
  }

  function isDateField(field) {
    const dateFields = ['date', 'due', 'start', 'end']
    return dateFields.includes(field)
  }

  const fieldsToSkip = ['last_updated_at', 'last_updated_by', 'frequently_used_service_codes']

  function getChangedFields(row) {
    const { prior, current } = row?.attributes

    const fields = []

    for (let key in prior) {
      let priorValue = prior[key]
      let currentValue = current[key]

      if (priorValue === currentValue || fieldsToSkip.includes(key)) {
        continue
      }

      if (isPriceField(key)) {
        priorValue = formatPrice(priorValue || 0)
        currentValue = formatPrice(currentValue || 0)

      } else if (isPercentField(key)) {

        const formatOptions = {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }

        priorValue = formatPercent(priorValue || 0, formatOptions)
        currentValue = formatPercent(currentValue || 0, formatOptions)

      } else if (isDateField(key)) {
        priorValue = priorValue ? formatDate(priorValue) : null
        currentValue = currentValue ? formatDate(priorValue) : null
      }

      fields.push({
        name: fieldLabels[key] || key,
        field: key,
        prior: priorValue,
        current: currentValue,
      })
    }
    return fields
  }

</script>
