<template>
  <base-tooltip :content="content"
                :tabindex="-1">
    <base-button :variant="variant"
                 :tabindex="0"
                 size="icon"
                 class="mr-2"
                 v-bind="$attrs"
                 @click="$emit('click')"
    >
      <slot></slot>
    </base-button>
  </base-tooltip>
</template>
<script>
  export default {
    name: 'TableIconButton',
    inheritAttrs: false,
    props: {
      content: {
        type: String,
        default: ''
      },
      variant: {
        type: String,
        default: 'primary-light'
      }
    }
  }
</script>
