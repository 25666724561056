<template>
  <div v-if="data.length" class="px-2">
    <h5 class="my-2 font-semibold print:text-print">{{ $t('Payments') }}</h5>
    <AgDataTable
        :data="data"
        :columns="columns"
        :no-borders="true"
        :pagination="false"
        :compact="true"
        :show-pagination="false"
        :group-include-total-footer="true"
        domLayout="autoHeight"
    >
      <template #attributes.date="{row}">
        <router-link
            v-if="get(row, 'attributes.journal_id')"
            :to="`/${module}/payments/batches/${row.attributes.journal_id}/view`"
        >
          {{ $formatDate(row.attributes.date) }}
        </router-link>
      </template>
      <template #attributes.number="{row}">
        <router-link
            v-if="get(row, 'attributes.journal_id')"
            :to="`/${module}/payments/batches/${row.attributes.journal_id}/view`"
        >
          {{ row.attributes.number }}
        </router-link>
      </template>
      <template #created_by="{row}">
        <UserLink
            v-if="get(row, 'attributes.created_by')"
            :id="row.attributes.created_by"
        />
      </template>
    </AgDataTable>
  </div>
</template>
<script>
  import { resourceStatuses } from "@/enum/enums";

  export default {
    props: {
      data: {
        type: Array,
      },
      module: {
        type: String,
        default: 'accounts-payable',
      }
    },
    computed: {
      columns() {
        return [
          {
            headerName: this.$t('Payment Date'),
            field: 'attributes.date',
            maxWidth: 160,
            component: 'FormattedDate',
          },
          {
            headerName: this.$t('Payment Reference'),
            field: 'attributes.number',
            minWidth: 100,
            maxWidth: 200,
          },
          {
            headerName: this.$t('Type'),
            field: 'attributes.type',
            minWidth: 60,
            maxWidth: 120,
            component: 'Status',
          },
          {
            headerName: this.$t('Status'),
            field: 'attributes.status',
            minWidth: 50,
            maxWidth: 90,
            component: 'Status',
          },
          {
            headerName: this.$t('Bank'),
            field: 'attributes.bank_id',
            maxWidth: 200,
            component: 'BankLink',
          },
          {
            headerName: this.$t('Created By'),
            field: 'created_by',
            align: 'right',
            maxWidth: 200,
          },
          {
            headerName: this.$t('Created At'),
            field: 'attributes.created_at',
            maxWidth: 160,
            align: 'right',
            component: 'FormattedDate',
          },
          {
            headerName: this.$t('Gross'),
            field: 'pivots.gross_amount',
            minWidth: 100,
            maxWidth: 160,
            align: 'right',
            valueGetter: params => {
              if (this.isVoided(params)) {
                return 0
              }
              return +params.data.pivots?.gross_amount
            },
            component: 'FormattedPrice',
            aggFunc: 'sum',
          },
          {
            headerName: this.$t('Discount'),
            field: 'pivots.discount_amount',
            minWidth: 100,
            maxWidth: 160,
            align: 'right',
            valueGetter: params => {
              if (this.isVoided(params)) {
                return 0
              }
              return +params.data.pivots?.discount_amount
            },
            component: 'FormattedPrice',
            aggFunc: 'sum',
          },
          {
            headerName: this.$t('Net'),
            field: 'pivots.net_amount',
            minWidth: 100,
            maxWidth: 160,
            align: 'right',
            valueGetter: params => {
              if (this.isVoided(params)) {
                return 0
              }
              return +params.data.pivots?.net_amount
            },
            component: 'FormattedPrice',
            aggFunc: 'sum',
          },
        ]
      },
    },
    methods: {
      isVoided(params) {
        return params?.data?.attributes?.status === resourceStatuses.Voided
      }
    }
  }
</script>
