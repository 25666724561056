import { formatDate } from "@/plugins/dateFormatPlugin";

export function addCsvHeader(content: string) {
  const date = formatDate(new Date(), 'MM/dd/yyyy')
  const time = formatDate(new Date(), 'HH:mm:ss')

  const firstRow = `"DATE","TIME"`
  const secondRow = `"${date}","${time}"`
  const thirdRow = `""`
  content = `${firstRow}\n${secondRow}\n${thirdRow}\n${content}`

  return content
}

type ConvertJsonToCSVFileParams = {
  fileName: string
  data: any[]
  addHeader?: boolean
}

export async function convertJsonToCSVFile({fileName, data, addHeader = true}: ConvertJsonToCSVFileParams) {
  const Papa = await import('papaparse')

  let csvContent = Papa.unparse(data)
  if (addHeader) {
    csvContent = addCsvHeader(csvContent)
  }

  return new File([csvContent], fileName, {type: 'text/csv'})
}

export async function parseCsvFile(fileData: File) {
  return new Promise(async (resolve, reject) => {
    let Papa = await import('papaparse')
    // @ts-ignore
    Papa = Papa?.default || Papa

    Papa.parse(fileData, {
      complete: async (results: any, file: File) => {
        resolve({
          results,
          file
        })
      },
      error: (err: any, file) => {
        reject({
          err,
          file
        })
      }
    })
  })
}

export function parseImportErrors(errors: any[] | Record<string, any>) {
  let errorsResult = []
  if (Array.isArray(errors)) {
    errorsResult = errors.map(row => row.join(''))
  } else {
    for (let key in errors) {
      let error = errors[key]
      if (Array.isArray(error)) {
        error = error.join('\n')
      }
      errorsResult.push(error)
    }
  }

  const errorCount = Array.isArray(errors) ? errors.length : Object.keys(errors).length

  if (errorCount === 0) {
    errorsResult = []
    return
  }

  const firstError = `We found ${errorCount} errors during the import.`
  if (firstError) {
    errorsResult.unshift(firstError)
  }
  return errorsResult
}

export async function parseExcelFile(fileData: File) {
  return new Promise(async (resolve, reject) => {
    let XLXS = await import('xlsx')
    // @ts-ignore
    XLXS = XLXS?.default || XLXS
    const reader = new FileReader();
    reader.onload = async (e) => {
      const result = e.target?.result as any
      let data = new Uint8Array(result);
      let workbook = XLXS.read(data, {
        type: 'array',
        cellDates: true,
        dateNF: 'mm/dd/yyyy',
      });
      let sheetName = workbook.SheetNames[0]

      let worksheet = workbook.Sheets[sheetName];
      const resultData = XLXS.utils.sheet_to_json(worksheet, {
        header: 1,
        defval: null,
        raw: false,
      })
      resolve({
        results: {data: resultData, errors: []},
        file: fileData,
      })
    };
    reader.readAsArrayBuffer(fileData)
  })
}

function getExtension(fileName: string) {
  const parts = fileName.split('.')
  return parts[parts.length - 1]
}

export async function parseExcelOrCsvFile(fileData: File) {
  const spreadSheetExtensions = ['xlsx', 'xls']
  const extension = getExtension(fileData.name)
  const isSpreadSheet = spreadSheetExtensions.includes(extension)

  if (isSpreadSheet) {
    return parseExcelFile(fileData)
  }
  return parseCsvFile(fileData)
}
