import { formatDate } from "@/plugins/dateFormatPlugin";

export function parsePreFlightErrors(err, timecardEntries) {
  const timecards = err?.response?.data?.data || []
  let messages = []
  for (let timeCardId in timecards) {
    const timecard = timecards[timeCardId]
    for (let entryId in timecard?.entries) {
      let entryMessages = timecard.entries[entryId]
      entryMessages.forEach(message => {
        let parsedMessage = ''
        let matchingEntry = timecardEntries.find(entry => entry.id === entryId)
        if (matchingEntry) {
          let date = formatDate(matchingEntry.attributes.date, 'ccc do')
          let employeeName = matchingEntry.employee?.attributes?.name
          parsedMessage = `${employeeName}, ${date} - `
        }
        parsedMessage += message
        messages.push({
          timeCardId,
          entryId,
          message: parsedMessage
        })
      })
    }
  }
  err.handled = true
  return messages
}


export function filterPayrollsForPrint(data, config) {
  data.payrolls = data.payrolls.filter(payroll => {
    const isDirectDeposit = payroll.check.is_dd_only
    if (isDirectDeposit) {
      return config.print_dds
    }
    return config.print_checks
  })
  return data
}

export const questionTypes = {
  Boolean: 'boolean',
  Signature: 'signature',
  Text: 'text',
  UrgentText: 'urgent_text',
}

export const TimesheetStatuses = {
  Approved: 'approved',
  Rejected: 'rejected',
  PayrollCreated: 'payroll submitted',
  TimeCardCreated: 'timecard created',
  Submitted: 'submitted',
  Pending: 'pending',
}
