<template>
  <div>
    <a v-if="entityAddress && !editableCell"
       :href="locationLink"
       target="_blank"
       rel="noopener"
       class="text-gray-700 hover:text-primary-900 cursor-pointer"
    >
      <address class="address-field truncate">
        {{ formattedAddress }}
      </address>
    </a>
    <span v-else>{{ entityAddress ?  formattedAddress : ''}}</span>
  </div>
</template>
<script>
  export default {
    props: {
      row: {
        type: Object,
        default: () => ({})
      },
      editableCell: Boolean,
    },
    data() {
      return {
        entityAddress: false
      }
    },
    computed: {
      formattedAddress() {
        const entityAddress = this.entityAddress

        const city = entityAddress.city || ''
        const state = entityAddress.state || ''

        let address = ''

        if ( city ) {
          address += city
        }
        if ( state ) {
          address += `, ${state}`
        }
        return address.trim()
      },
      locationLink() {
        return `https://www.google.com/maps/search/?api=1&query=${this.formattedAddress}`
      }
    },
    methods: {
      getFirstAddress() {
        return this.get(this.row, 'relationships.addresses', false)
      },
      tryToGetPrimaryAddress() {
        const addresses = this.get(this.row, 'relationships.addresses', [])
        let primaryAddress = addresses.find(address => address.attributes.primary)

        if ( !primaryAddress ) {
          primaryAddress = this.getFirstAddress(this.row)
        }

        return primaryAddress.attributes
      },
      getEntityAddress() {
        this.entityAddress = this.tryToGetPrimaryAddress()
      }
    },
    mounted() {
      this.getEntityAddress()
    }
  }
</script>
