<template>
  <base-alert
      v-if="lastNumber"
      :closable="true"
      class="mb-2"
  >
    {{ $t(`Last used ${entity} number`) }} - <b>{{ lastNumber }}</b>
  </base-alert>
</template>
<script>
  import { generateNextNumber } from '@/utils/generateNextNumber'
  import pluralize from 'pluralize'

  export default {
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      entity: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        lastNumber: '',
      };
    },
    methods: {
      async trySetNextJobNumber() {
        let entity = pluralize(this.entity)
        const { nextNumber, lastNumber } = await generateNextNumber(entity)

        await this.$nextTick()
        this.$emit('set-next-number', nextNumber)
        this.lastNumber = lastNumber
      },
    },
    mounted() {
      this.trySetNextJobNumber()
    },
  }
</script>
