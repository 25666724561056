import { ProofListingPostData } from "@/modules/common/components/proof-listing/proofListingTypes";
import { useRoute, useRouter } from "vue2-helpers/vue-router";

type PostResult = {
  data?: {
    journal: string;
    message: string;
    [key: string]: any
  }
}
type HandlePostRedirectParams = {
  postData: ProofListingPostData;
  postResult: PostResult;
}

export function usePostRedirect() {
  const router = useRouter()
  const route = useRoute()
  async function handlePostRedirect(params: HandlePostRedirectParams) {
    const { postData, postResult } = params
    const currentRoute = route.path
    const journalId = postResult.data?.journal_id

    const basePath = currentRoute.split('/')[1]
    let journalsPath = `/${basePath}/journals`

    if (basePath === 'ledger') {
      journalsPath = journalId ? `/ledger/journal` : `/ledger/journal/posted`
    }
    if (postData.redirect_to_journal) {
      let journalPath = journalId ? `${journalsPath}/${journalId}/view` : `${journalsPath}`

      router.push(journalPath)
    } else {
      router.go(-1)
    }
  }

  return {
    handlePostRedirect,
  }
}
