<template>
  <ReconciliationForm :data="currentReconciliation"/>
</template>
<script>
  import ReconciliationForm from '@/modules/ledger/components/ReconciliationForm'

  export default {
    components: {
      ReconciliationForm,
    },
    computed: {
      currentReconciliation() {
        return this.$store.state.generalLedger.currentReconciliation || {}
      },
    },
  }
</script>
