<template>
  <div class="import-data mt-4">
    <ImportForm
        v-bind="importSettings"
        @success="redirectToList"
    >
      <div class="col-span-6 md:col-span-1">
        <CustomerSelect
          v-model="model.customer_id"
          id="customer_id"
        />
      </div>

    </ImportForm>
  </div>
</template>
<script>
  import ImportForm from "@/modules/common/components/import/ImportForm";
  import { getInitialMapping } from "@/modules/payroll/utils/timecardImportColumns";

  const colMappings = {
    Number: {
      value: 'number',
      label: 'Number',
      alternativeLabels: ['JOB NO.'],
      required: true,
    },
    Description: {
      value: 'description',
      label: 'Description',
      alternativeLabels: ['Job Description'],
    },
    Category: {
      value: 'category_code',
      label: 'Category',
      alternativeLabels: ['Job Category'],
    },
    Notes1: {
      value: 'notes_1',
      label: 'Notes 1',
      alternativeLabels: ['Job Notes 1'],
    },
    Notes2: {
      value: 'notes_2',
      label: 'Notes 2',
      alternativeLabels: ['Job Notes 2'],
    },
    Notes3: {
      value: 'notes_3',
      label: 'Notes 3',
      alternativeLabels: ['Job Notes 3'],
    },
    Notes4: {
      value: 'notes_4',
      label: 'Notes 4',
      alternativeLabels: ['Job Notes 4'],
    },
    Status: {
      value: 'status',
      label: 'Status',
      alternativeLabels: ['Job Status'],
    },
    Address1: {
      value: 'address_1',
      label: 'Address 1',
      alternativeLabels: ['Job Address 1', 'Jobsite Address 1'],
    },
    Address2: {
      value: 'address_2',
      label: 'Address 2',
      alternativeLabels: ['Job Address 2', 'Jobsite Address 2'],
    },
    FullAddress: {
      value: 'address',
      label: 'Full Address',
      alternativeLabels: ['Address', 'Jobsite Address'],
    },
    City: {
      value: 'city',
      label: 'City',
      alternativeLabels: ['Job City', 'Jobsite City'],
      required: mapping => {
        return mapping.address_1 !== -1;
      }
    },
    State: {
      value: 'state',
      label: 'State',
      alternativeLabels: ['Job State', 'Jobsite State'],
      required: mapping => {
        return mapping.address_1 !== -1;
      }
    },
    PostalCode: {
      value: 'postal_code',
      label: 'Zip Code',
      alternativeLabels: ['Zip', 'Postal Code', 'Job Zip', 'Jobsite Zip'],
      required: mapping => {
        return mapping.address_1 !== -1;
      }
    },
    BillingRetentionType: {
      value: 'billing_rate_type',
      label: 'Billing Rate Type',
      alternativeLabels: ['BILL RATE'],
    },
    BillingRetentionPercent: {
      value: 'billing_retention_percent',
      label: 'Billing Retention Percent',
      alternativeLabels: ['Retention Billing %'],
    },
    CertifiedPayroll: {
      value: 'certified_payroll',
      label: 'Certified Payroll',
      alternativeLabels: [],
    },
    CustomerCode: {
      value: 'customer_code',
      label: 'Customer Code',
      alternativeLabels: ['Customer No.', 'Cust Code'],
    },
    DistrictCode: {
      value: 'district_code',
      label: 'District Code',
      alternativeLabels: ['Tax District', 'Sales Tax Dist', 'Sales Tax District'],
    },
    DistrictState: {
      value: 'district_state',
      label: 'District State',
      alternativeLabels: ['Tax State', 'Sales Tax State'],
    },
    GenLiabilityOCIPCode: {
      value: 'gen_liability_ocip_code',
      label: 'Gen Liability OCIP Code',
      alternativeLabels: ['General Liab OCIP Code'],
    },
    PrintSS: {
      value: 'print_ss',
      label: 'Print SS',
      alternativeLabels: ['Print Cert SS#'],
    },
    ProjectNumber: {
      value: 'project_number',
      label: 'Project Number',
      alternativeLabels: ['Project No.', 'Project', 'Architect/Engineer Project #'],
    },
    SalesPersonCode: {
      value: 'sales_person_code',
      label: 'Sales Person',
      alternativeLabels: ['Sales Person Code'],
    },
    SubContractRetentionPercent: {
      value: 'subcontract_retention_percent',
      label: 'Subcontract Retention Percent',
      alternativeLabels: ['Retention Subcontract %'],
    },
    UnionCode: {
      value: 'union_code',
      label: 'Union Code',
      alternativeLabels: [],
    },
    UnionRate: {
      value: 'union_rate_type',
      label: 'Union Rate',
      alternativeLabels: [],
    },
    Units: {
      value: 'units',
      label: 'Units',
      alternativeLabels: ['Job Units'],
    },
    Um: {
      value: 'um',
      label: 'Um',
      alternativeLabels: ['Unit of Measure', 'Unit Measure', 'Job Unit Measure'],
    },
    WithHoldingStateCode: {
      value: 'withholding_state_code',
      label: 'Withholding State Code',
      alternativeLabels: ['Withholding State', 'Timecard W/H State'],
    },
    WithHoldingLocal1Code: {
      value: 'withholding_local_1_code',
      label: 'Withholding Local 1 Code',
      alternativeLabels: ['Withholding Local Code', 'Timecard W/H Local 1'],
    },
    WithHoldingLocal2Code: {
      value: 'withholding_local_2_code',
      label: 'Withholding Local 2 Code',
      alternativeLabels: ['Timecard W/H Local 2'],
    },
    WorkerCompOCIPCode: {
      value: 'workers_comp_ocip_code',
      label: `Worker's Comp OCIP Code`,
      alternativeLabels: [],
    }
  }

  const options = [
    colMappings.Number,
    colMappings.Status,
    colMappings.Description,
    colMappings.Category,
    colMappings.Notes1,
    colMappings.Notes2,
    colMappings.Notes3,
    colMappings.Notes4,
    colMappings.Address1,
    colMappings.Address2,
    colMappings.FullAddress,
    colMappings.City,
    colMappings.State,
    colMappings.PostalCode,
    colMappings.BillingRetentionType,
    colMappings.BillingRetentionPercent,
    colMappings.CertifiedPayroll,
    colMappings.CustomerCode,
    colMappings.DistrictCode,
    colMappings.DistrictState,
    colMappings.GenLiabilityOCIPCode,
    colMappings.PrintSS,
    colMappings.ProjectNumber,
    colMappings.SalesPersonCode,
    colMappings.SubContractRetentionPercent,
    colMappings.UnionCode,
    colMappings.UnionRate,
    colMappings.Units,
    colMappings.Um,
    colMappings.WithHoldingStateCode,
    colMappings.WithHoldingLocal1Code,
    colMappings.WithHoldingLocal2Code,
    colMappings.WithHoldingLocal2Code,
    colMappings.WorkerCompOCIPCode,
  ]

  export default {
    name: 'JobImport',
    components: {
      ImportForm,
    },
    data() {
      return {
        importSettings: {
          url: '/restify/jobs/import',
          columnOptions: options,
          columnMappings: colMappings,
          initialMapping: getInitialMapping(options),
          transformRequestData: this.transformRequestData,
          type: 'jobs-import',
          resource: 'jobs',
        },
        model: {
          customer_id: this.$route.query.customer_id || null,
        },
      }
    },
    methods: {
      transformRequestData(formData) {
        if (this.model.customer_id) {
          formData.append('customer_id', this.model.customer_id)
        }
      },
      async redirectToList() {
        await this.$router.push('/job-costing/jobs')
        await this.$store.dispatch('globalLists/getJobs')
      },
    }
  }
</script>
