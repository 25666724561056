<template>
  <BankPositivePay :data="bank"/>
</template>
<script setup lang="ts">
import store from "@/store";
import { computed } from "vue";
import BankPositivePay from "@/modules/settings/components/BankPositivePay.vue";


const bank = computed(() => {
  return store.state.settings.currentBank || {}
})
</script>
