<template>
  <settings-form
      :fields="fields"
      :module-name="moduleName"
      :before-save="saveJobTypes"
      :authorized-to-collapse="true"
      grid-classes="grid grid-cols-1"
      @cancel="$router.push('/service-billing/work-orders')"
  >
    <template #content="{model}">
      <div class="grid grid-cols-8 gap-x-3 px-4">
        <base-input v-model="model.city"
                    :label="$t('City')"
                    :name="$t('City')"
                    :placeholder="$t('City')"
                    class="col-span-8 md:col-span-1"
                    rules="max:20"
        />
        <state-select
            v-model="model.state"
            :label="$t('State')"
            :name="$t('State')"
            :placeholder="$t('State')"
            class="col-span-8 md:col-span-1"
        />
        <base-input
            v-model="model.last_work_order_number"
            :label="$t('Last Work Order #')"
            :name="$t('Last Work Order')"
            :min="0"
            :max="999999"
            :step="1"
            class="col-span-8 md:col-span-1"
            type="number"
            rules="min_value:0|max_value:999999"
        />
        <base-select
            v-model="model.default_status"
            :options="invoiceStatusOptions"
            :label="$t('WO Invoice Status')"
            :name="$t('WO Invoice Status')"
            class="col-span-8 md:col-span-1"
            rules="required"
        />
        <base-input
            v-model="model.last_recurring_invoice_number"
            :label="$t('Last Recurring Invoice #')"
            :name="$t('Last Recurring Invoice #')"
            :min="0"
            :max="99999"
            :step="1"
            class="col-span-8 md:col-span-1"
            type="number"
            rules="min_value:0|max_value:99999"
        />

        <base-input
            v-model="model.invoice_prefix_letter"
            v-uppercase
            :label="$t('Invoice Prefix Letter')"
            :name="$t('Invoice Prefix Letter')"
            class="col-span-8 md:col-span-1"
            rules="alpha"
        />
        <base-input
          v-model="model.last_invoice_number"
          v-uppercase
          :label="$t('Last Invoice Number')"
          :name="$t('Last Invoice Number')"
          class="col-span-8 md:col-span-1"
        />
        <div class="col-span-8 md:col-span-1"/>
        <service-billing-code-select
            v-model="model.default_service_code"
            :label="$t('Work Order Service Code')"
            :name="$t('Work Order Service Code')"
            :tip="$t('Enter the Service Code you will be billing against most often')"
            class="col-span-8 md:col-span-2"
        />
        <service-billing-code-select
            v-model="model.frequently_used_service_codes"
            :label="$t('Standard Work Service Codes')"
            class="col-span-8 md:col-span-4"
            multiple
        />
        <div class="col-span-8 md:col-span-1"/>
        <worker-comp-rate-select
            v-model="model.workers_comp_rate_code"
            :label="$t('Worker’s Comp Code')"
            class="col-span-8 md:col-span-2"
            clearable
        />
        <base-select
            v-model="model.cost_type_id"
            :label="$t('Cost Type')"
            :options="getJobCostTypes"
            class="col-span-8 md:col-span-2"
            clearable
        />
        <gen-liability-rate-select
            v-model="model.gen_liability_rate_code"
            :label="$t('General Liability Code')"
            class="col-span-8 md:col-span-2"
            clearable
        />
        <base-select
            v-model="model.income_type_id"
            :label="$t('Income Type')"
            :options="getJobIncomeTypes"
            class="col-span-8 md:col-span-2"
            clearable
        />
        <account-select
            v-model="model.equipment_utilization_credit_account"
            :label="$t('Equipment Utilization Account')"
            :name="$t('Equipment Utilization Account')"
            class="col-span-8 md:col-span-2"
        />
        <sub-account-select
            v-model="model.equipment_utilization_credit_subaccount"
            :label="$t('Sub Account')"
            :name="$t('Sub Account')"
            class="col-span-8 md:col-span-1"
        />
      </div>
    </template>
    <template #overrides>
      <service-billing-types-grid
          class="col-span-8 mt-6"
          ref="serviceBillingTypes"
      />
    </template>
  </settings-form>
</template>
<script>
  import { resourceStatuses } from '@/enum/enums'
  import SettingsForm from '@/modules/common/components/SettingsForm'
  import ServiceBillingCodeSelect from '@/components/select/entities/ServiceBillingCodeSelect'
  import ServiceBillingTypesGrid from '@/modules/service-billing/components/settings/ServiceBillingTypesGrid'

  export default {
    components: {
      SettingsForm,
      ServiceBillingTypesGrid,
      ServiceBillingCodeSelect,
    },
    data() {
      return {
        moduleName: 'service-billing',
        invoiceStatusOptions: [
          {
            label: this.$t('Pending'),
            value: resourceStatuses.Pending,
          },
          {
            label: this.$t('Prepaid'),
            value: resourceStatuses.Prepaid,
          },
          {
            label: this.$t('No Post'),
            value: resourceStatuses.NoPost,
          }
        ],
        fields: [
          'city',
          'state',
          'last_work_order_number',
          'default_status',
          'last_recurring_invoice_number',
          'invoice_prefix_letter',
          'last_invoice_number',
          'default_service_code',
          'frequently_used_service_codes',
          'workers_comp_rate_code',
          'cost_type_id',
          'gen_liability_rate_code',
          'income_type_id',
          'equipment_utilization_credit_account',
          'equipment_utilization_credit_subaccount',
          'last_year_posted',
          'last_period_posted',
          'last_journal_number',
        ],
      }
    },
    computed: {
      jobCostTypes() {
        return this.$store.getters['globalLists/getResourceOptions'](this.$globalResources.ServiceBillingCostTypes)
      },
      jobIncomeTypes() {
        return this.$store.getters['globalLists/getResourceOptions'](this.$globalResources.ServiceBillingIncomeTypes)
      },
      getJobCostTypes() {
        return this.jobCostTypes.map(type => {
          return {
            label: `${type.abbr} (${type.name})`,
            value: type.id,
          }
        })
      },
      getJobIncomeTypes() {
        return this.jobIncomeTypes.map(type => {
          return {
            label: `${type.abbr} (${type.name})`,
            value: type.id,
          }
        })
      },
    },
    methods: {
      async saveJobTypes() {
        return await this.$refs.serviceBillingTypes.save()
      },
    },
  }
</script>
