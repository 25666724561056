<template>
  <BaseSelect
    v-on="$listeners"
    v-bind="$attrs"
    :multiple="multiple"
    :options="getOptions"
    :label-format="formatLabel"
    :table-columns="tableColumns"
    :resource-name="globalResources.TaxCodes"
    :value-key="valueKey"
    label-key="code"
  />
</template>
<script>
import { globalResources } from "@/components/form/util";

export default {
  props: {
    excludeIds: {
      type: Array,
      default: () => [],
    },
    valueKey: {
      type: String,
      default: 'id',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      globalResources,
      tableColumns: [
        {
          minWidth: 100,
          maxWidth: 150,
          name: this.$t('Code'),
          prop: 'code',
        },
        {
          minWidth: 150,
          maxWidth: 150,
          name: this.$t('Description'),
          prop: 'description',
        },
      ],
    }
  },
  computed: {
    getOptions() {
      return this.$store.getters['globalLists/getResourceList'](globalResources.TaxCodes) || []
    },
  },
  methods: {
    formatLabel(option) {
      const { code, description } = option
      if (!description) {
        return code
      }
      return `${code} (${description})`
    },
  },
}
</script>
