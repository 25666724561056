<template>
  <div class="print:hidden font-normal">
    <BaseTooltip :content="$t('Compute Payroll')">
      <BaseButton
        :loading="loading"
        variant="primary-light"
        @click="showDialog = true"
      >
        <IconServiceInvoices
          class="w-5 h-5 mr-1"
        />
        {{ $t('Compute') }}
      </BaseButton>
    </BaseTooltip>
    <BaseFormDialog
      v-if="showDialog"
      :title="$t('Compute Payroll')"
      :open.sync="showDialog"
      @close="showDialog = false"
    >
      <ValidationObserver>
        <BaseForm
          :save-text="saveText"
          :focus-on-first-input="false"
          layout="modal"
          submit-button-type="button"
          :submitDisabled="syncingBatch"
          grid-classes="grid grid-cols-1"
          @submit="computeAllTimeCards"
        >
          <template #extra-buttons-right>
            <BaseButton
              v-if="showRemainingButton"
              class="mr-2"
              :disabled="syncingBatch"
              @click="onComputeRemaining"
            >
              {{ $t('Compute Remaining') }} ({{ remainingTimecardsToCompute.length }})
            </BaseButton>
          </template>
          <div
            v-if="syncingBatch"
            class="flex space-x-4"
          >
            <LoadingCircle
              class="w-5 h-5 mt-1"
            />
            <span>{{ $t('Analyzing batch...') }}</span>
          </div>
          <template v-else>
            <BaseAlert
              v-if="!computable"
              :type="$promptType.Warning"
              class="col-span-1"
            >
              {{ $t('There are no Timecards to compute.') }}
            </BaseAlert>
            <template v-else>
              <BaseAlert
                :type="$promptType.Primary"
              >
                {{ $t(`By doing this, the selected Timecards will be computed and we’ll provide you with an overview of the payroll including taxes and previews of employee checks.`) }}
              </BaseAlert>
              <BaseAlert
                v-if="adjustments"
                :type="$promptType.Warning"
                class="mt-2"
              >
                {{ $t('Payrolls in this batch have adjustments which will be lost when recomputing batch.') }}
              </BaseAlert>
            
              <AgDataTable
                v-if="showRemainingButton && remainingTimecardsToCompute.length"
                :title="$t('Remaining Timecards To Compute')"
                :columns="timecardColumns"
                :dataLoading="syncingBatch"
                :data="remainingTimecardsToCompute"
                :pagination="false"
                :showPagination="false"
                :hideTableTopSection="true"
                domLayout="autoHeight"
              />
            </template>
            <div class="col-span-1 mt-2">
              <BankSelect
                v-model="model.bank_id"
                :used-for="BankUsedInTypes.Payroll"
                :label="$t('Bank')"
                :name="$t('Bank')"
                id="bank"
                rules="required"
              />
            </div>
          </template>
        </BaseForm>
      </ValidationObserver>
    </BaseFormDialog>
  </div>
</template>
<script>
import axios from "axios";
import { BankUsedInTypes } from "@/enum/enums";
import LoadingCircle from '@/modules/wiki/components/LoadingCircle.vue';

export default {
  inheritAttrs: false,
  components: {
    LoadingCircle,
  },
  data() {
    return {
      BankUsedInTypes,
      loading: false,
      showDialog: false,
      model: {
        bank_id: undefined,
      },
      syncingBatch: false,
      timecardBatch: null,
      remainingTimecardsToCompute: []
    }
  },
  computed: {
    currentTimecardBatch() {
      return this.$store.state.payroll.currentTimecardBatch || {}
    },
    hasPayrollBatch() {
      return !!this.timecardBatch?.payroll_batch_id
    },
    timecardColumns() {
      return [
          {
            label: 'Employee',
            prop: 'employee.code',
            valueFormatter: params => {
              const { code, name } = params.data.employee
              if (!code && !name) {
                return `${this.$t('Employee not found')} #${params.data.number}`
              }
              return `${code} (${name}) #${params.data.number}`
            }
          },
          {
            label: 'Status',
            prop: 'employee.status',
            component: 'Status',
            minWidth: 90,
            maxWidth: 100,
          },
          {
            label: 'Pay Frequency',
            prop: 'employee.pay_frequency',
            component: 'Status',
            minWidth: 90,
            maxWidth: 100,
          },
          {
            label: 'Regular Hours',
            prop: 'regular_hours',
            minWidth: 50,
            maxWidth: 90,
          },
          {
            label: 'Overtime Hours',
            prop: 'overtime_hours',
            minWidth: 50,
            maxWidth: 90,
          },
          {
            label: 'Premium Hours',
            prop: 'premium_hours',
            minWidth: 50,
            maxWidth: 90,
          },
          {
            label: 'Total Hours',
            prop: 'total_hours',
            minWidth: 70,
            maxWidth: 210,
          },
          {
            label: 'Entries',
            prop: 'entries_count',
            minWidth: 70,
            maxWidth: 120,
          },
        ]
    },
    showRemainingButton() {
      return this.computable > this.computed && this.hasPayrollBatch
    },
    counts() {
      return this.timecardBatch?.counts || {}
    },
    adjustments() {
      return this.counts.adjustments || 0
    },
    computable() {
      return this.counts.computable || 0
    },
    computed() {
      return this.counts.computed || 0
    },
    saveText() {
      if (this.hasPayrollBatch) {
        return this.$t('Recompute Batch')
      }
      return this.$t('Compute')
    }
  },
  methods: {
    async confirmAdjustmentsOverride() {
      const confirmed = await this.$confirm({
        title: this.$t('Override Adjustments?'),
        description: this.$t('Payrolls in this batch have adjustments which will be lost when computing.<br> Are you sure you want to continue?'),
        buttonText: this.$t('Continue'),
      })

      return confirmed
    },
    async computeAllTimeCards() {
      if (this.adjustments && !await this.confirmAdjustmentsOverride()) {
        return
      }

      this.loading = true
      this.showDialog = false
      this.$emit('on-compute', this.model, () => {
        this.loading = false
      })
    },
    async onComputeRemaining() {
      try {
        this.loading = true
        const url = '/restify/timecards/actions?action=compute-selected-timecards'
        const model = {
          bank_id: this.model.bank_id,
          repositories: this.remainingTimecardsToCompute.map(t => t.id)
        }

        await axios.post(url, model)

        await this.$store.dispatch('payroll/getTimecardBatch', this.timecardBatch?.id)
        const payrollBatchId = this.timecardBatch?.payroll_batch_id
        if (!payrollBatchId) {
          return
        }
        await this.$router.push(`/payroll/batches/${payrollBatchId}`)
      }
      catch (err) {
        if (err?.handled) {
          return
        }
        
        this.$error(this.$t('Failed to compute remaining timecards'))
      }
      finally {
        this.loading = false
      }
    },
    async syncBatch() {
      this.timecardBatch = null
      this.remainingTimecardsToCompute = []

      try {
        this.syncingBatch = true
        const timecardBatchId = this.currentTimecardBatch?.id

        const url = `/restify/timecard-batches/${timecardBatchId}/getters/timecard-batch-compute-modal`
        const { data } = await axios.get(url)

        this.timecardBatch = data.batch
        this.remainingTimecardsToCompute = data.timecards

        console.log(data)
      }
      catch (err) {
        if (err?.handled) {
          return
        }
        
        this.$error(this.$t('Failed to sync batch'))
      }
      finally {
        this.syncingBatch = false
      }
    }
  },
  watch: {
    showDialog() {
      if (!this.showDialog) {
        return
      }

      this.syncBatch()
    }
  }
}
</script>
