import orderBy from "lodash/orderBy";
import { ref } from "vue";

export const SortDirection = {
  None: undefined,
  Asc: 'asc',
  Desc: 'desc',
}

export function useSelectSorting() {
  const sortDirection = ref()
  const sortBy = ref()

  function sortData(data) {
    if (!sortBy.value || !sortDirection.value) {
      return data;
    }
    return orderBy(data, sortBy.value, sortDirection.value);
  }

  function onSort(column) {
    if (sortBy.value !== column) {
      sortBy.value = column;
      sortDirection.value = SortDirection.Asc;
    }

    if (sortDirection.value === SortDirection.Asc) {
      sortDirection.value = SortDirection.Desc;
    } else if (sortDirection.value === SortDirection.Desc) {
      sortDirection.value = SortDirection.None;
    } else if (sortDirection.value === SortDirection.None) {
      sortDirection.value = SortDirection.Asc;
    }
  }

  return {
    sortBy,
    onSort,
    sortData,
    sortDirection,
  }
}
