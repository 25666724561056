<template>
  <base-report-form
      :filters="model"
      :report-number="reportNumber"
      :focus-on-first-input="false"
  >

    <template #filters>
      <base-filter-row :title="$t('Report')">
        <financial-report-select
            v-model="model.financial_report_id"
            class="col-span-3"
        />
      </base-filter-row>
      <base-filter-row :title="$t('Title')">
        <div class="col-span-4 flex flex-col space-y-1">
          <base-input
              v-model="model.title1"
              :inline-errors="true"
              key="title1"
              :placeholder="$t('Enter Report Title')"
          />
          <base-input
              v-model="model.title2"
              :inline-errors="true"
              key="title2"
              :placeholder="$t('Enter Report Title')"
          />
          <base-input
              v-model="model.title3"
              :inline-errors="true"
              key="title3"
              :placeholder="$t('Enter Report Title')"
          />
        </div>
      </base-filter-row>
      <base-filter-row>
        <base-checkbox
            v-model="selectAccountList"
            :label="$t('Select a List of Accounts')"
            class="col-span-3 mb-3"
            id="select_account_list"
            @change="resetAccountFilters"
        />
      </base-filter-row>

      <base-filter-row :title="$t('Accounts')">

        <account-select
            v-if="selectAccountList"
            v-model="model.account_ids"
            :placeholder="$t('Select Accounts')"
            class="col-span-4"
            collapse-tags
            multiple
        />

        <template v-else>
          <account-range-filter v-model="model"/>
        </template>

      </base-filter-row>
      <base-filter-row :title="$t('Period')">
        <period-select
            v-model="model.period_start"
            :label="$t('Beginning Period')"
            class="col-span-2"
        />
        <period-select
            v-model="model.period_end"
            :label="$t('End Period')"
            class="col-span-2"
        />
      </base-filter-row>
      <base-filter-row :title="$t('Formatting')">
        <base-checkbox
            v-model="model.round_to_whole_dollars"
            :label="$t('Round to Whole Dollars')"
            id="round_to_whole_dollars"
            class="col-span-2"
        />

        <base-checkbox
            v-model="model.round_to_whole_percent"
            :label="$t('Round to Whole Percent')"
            id="round_to_whole_percent"
            class="col-span-2"
        />
      </base-filter-row>

      <base-filter-row :title="$t('Fiscal Year')">
        <fiscal-year-select
          v-model="model.fiscal_year"
          :placeholder="$t('Fiscal Year')"
          class="col-span-6 md:col-span-2"
          :show-closed-years="true"
        />
      </base-filter-row>

    </template>

    <template #table="{data, loading}">

      <financial-report-table
          :data="data"
          :data-loading="loading"
          :filters="model"
          :report-number="reportNumber"
          ref="reportTable"
      />

    </template>

  </base-report-form>
</template>
<script lang="ts">
  import { AccountRangeFilter, PeriodYearRangeFilter } from '@/components/range-filters'
  import AccountListingReportTable from '@/modules/ledger/components/reports/AccountListingReportTable.vue'
  import FinancialReportSelect from "@/modules/ledger/components/reports/FinancialReportSelect.vue";
  import FinancialReportTable from "@/modules/ledger/components/reports/FinancialReportTable.vue";
  import { defineComponent } from 'vue';

  export default defineComponent({
    props: {
      reportId: {
        type: String,
      },
      shouldCache: {
        type: Boolean,
        default: true,
      }
    },
    components: {
      AccountRangeFilter,
      AccountListingReportTable,
      PeriodYearRangeFilter,
      FinancialReportSelect,
      FinancialReportTable,
    },
    data() {
      return {
        reportNumber: '1887',
        selectAccountList: false,
        model: {
          account_ids: [],
          account_number_from: null,
          account_number_to: null,
          period_start: this.$currenPeriod,
          period_end: this.$currenPeriod,
          fiscal_year: this.$currentFiscalYear,
          round_to_whole_dollars: false,
          round_to_whole_percent: false,
          financial_report_id: this.reportId || null,
          title1: null as string | null,
          title2: null as string | null,
          title3: null as string | null,
          cache_date: this.shouldCache ? null : new Date().getTime(),
        },
      }
    },
    methods: {
      resetAccountFilters() {
        this.model.account_ids = []
        this.model.round_to_whole_dollars = false
        this.model.account_number_from = this.model.account_number_to = null
      },
      getPeriodName(period: number) {
        return this.$store.getters['company/getPeriodName'](period)
      },
      getDefaultTitles() {
        const startPeriod = this.getPeriodName(this.model.period_start)
        const endPeriod = this.getPeriodName(this.model.period_end)
        this.model.title1 = this.$currentCompany?.name
        this.model.title2 = this.$t(`For period ${startPeriod} to ${endPeriod}, ${this.model.fiscal_year}`).toString()
      }
    },
    mounted() {
      this.getDefaultTitles()
    },
  })
</script>
