<template>
  <base-card v-bind="$attrs"
             v-on="$listeners"
  >
    <div v-if="showTitleSection" class="flex flex-col md:flex-row justify-between">
      <slot name="title" :range="dateRange">
        <h5 v-if="title" class="form-section-title">
          {{ $t(title) }}
          <span v-if="showRangeLabels" class="text-gray-600 ml-2 text-base">
            {{ $formatDate(dateRange[0], 'MMM yyy', true) }} - {{ $formatDate(dateRange[1], 'MMM yyy', true) }}
          </span>
        </h5>
      </slot>
      <div class="flex items-center justify-end flex-1">
        <slot name="filters">

        </slot>
        <month-range-date-picker
            v-if="showDatePicker"
            v-model="dateRange"
            @input="onDateRangeChange"/>
      </div>
    </div>
    <div class="flex flex-col items-center md:flex-row lg:flex-col xl:flex-row h-full"
    >
      <generic-chart
          :loading="loading"
          :chart-data="chartData"
          class="mt-4 w-full h-96 base-chart-container"
      />
    </div>
  </base-card>
</template>
<script>
  import subYears from 'date-fns/subYears';
  import endOfMonth from 'date-fns/endOfMonth';
  import { chartOptions, colors, labelAxis, verticalDataAxis } from "./chartOptions";
  import { getMainColor } from "@/components/charts/chartColors";
  import MonthRangeDatePicker from "@/components/form/MonthRangeDatePicker";
  import Cache from '@/utils/Cache.js'


  export default {
    name: 'BarChartCard',
    inheritAttrs: false,
    components: {
      MonthRangeDatePicker,
    },
    props: {
      title: {
        type: String,
        default: '',
      },
      seriesName: {
        type: String,
        default: '',
      },
      url: {
        type: String,
        required: true,
      },
      urlParams: {
        type: Object,
        default: () => ({}),
      },
      responseProp: {
        type: String,
      },
      configureChart: {
        type: Function,
      },
      mapData: {
        type: Function,
      },
      formatLabelsAsPrice: {
        type: Boolean,
        default: false,
      },
      showLegend: {
        type: Boolean,
        default: false,
      },
      showRangeLabels: {
        type: Boolean,
        default: true,
      },
      options: {
        type: Object,
        default: () => ({}),
      },
      showTitleSection: {
        type: Boolean,
        default: true,
      },
      returnFullResponse: {
        type: Boolean,
        default: false,
      },
      showDatePicker: {
        type: Boolean,
        default: false,
      },
      noDataLoaded: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        loading: true,
        data: [],
        dateRange: [
          subYears(new Date(), 1),
          new Date(),
        ],
        filters: {
          start_date: undefined,
          end_date: undefined,
        }
      }
    },
    computed: {
      noData() {
        return !this.url || !this.loading && this.data.length === 0 || this.noDataLoaded
      },
      chartData() {
        let data = this.data.map(r => +r.value)
        let labels = this.data.map(r => r.label)

        const dataAxisResult = {
          ...verticalDataAxis,
          data: labels,
        }

        labelAxis.axisLabel.color = colors.light;
        labelAxis.splitLine.lineStyle.color = colors.lightLine;
        const xAxis = dataAxisResult;
        const yAxis = labelAxis;

        chartOptions.legend.textStyle.color = colors.light;

        if (this.formatLabelsAsPrice) {
          chartOptions.tooltip.valueFormatter = (value) => this.$formatPrice(value, { maximumFractionDigits: 0 });
          yAxis.axisLabel.formatter = (value) => this.$formatPrice(value, { maximumFractionDigits: 0 });
        }

        chartOptions.legend.show = this.showLegend;

        if (this.noData) {
          chartOptions.title = {
            show: true,
            textStyle: {
              color: colors.dark,
              fontSize: 16,
              fontWeight: 'normal',
              fontFamily: 'Inter var'
            },
            text: 'No data found',
            left: 'center',
            top: 'center'
          }
        } else {
          chartOptions.title = {
            show: false,
          }
        }
        let options = {
          ...chartOptions,
          xAxis,
          yAxis,
          grid: {
            left: 80,
            top: 20,
            right: 20,
            bottom: 45,
          },
          series: [{
            name: this.seriesName,
            data: data,
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            itemStyle: {
              borderRadius: [5, 5, 0, 0],
              color: getMainColor(),
            },
          }],
          ...this.options,
        }
        if (this.configureChart) {
          const result = this.configureChart(options)
          if (result) {
            options = result
          }
        }
        return options
      },
    },
    watch: {
      filters: {
        handler() {
          this.getData()
        },
        deep: true,
      },
    },
    methods: {
      onDateRangeChange(value) {
        if (!Array.isArray(value) || value?.length !== 2) {
          return
        }
        const start_date = value[0]
        let end_date = value[1]
        end_date = endOfMonth(end_date)
        this.filters.start_date = this.$formatDate(start_date, 'isoDate', true)
        this.filters.end_date = this.$formatDate(end_date, 'isoDate', true)
        this.$emit('year-change', value[0].getFullYear())
      },
      async getData() {
        if (!this.url) {
          this.loading = false
          return
        }
        try {
          this.loading = true
          const response = await Cache.getRequest(this.url, {
            params: {
              ...this.urlParams,
              ...this.filters,
            }
          })
          if (this.responseProp) {
            this.data = this.get(response.data, this.responseProp)
          } else {
            this.data = response.data
          }

          const data = this.returnFullResponse ? response : this.data
          this.$emit('data-fetch', data)

          if (this.mapData) {
            this.data = this.mapData(this.data)
          }
        } finally {
          this.loading = false
        }
      }
    },
    async created() {
      await this.getData()
    }
  }
</script>
