<template>
  <base-form
      :loading="loading"
      :show-cancel="showCancel"
      :save-text="$t('Create benefit code')"
      :update-text="$t('Update benefit code')"
      :can-create-another-entity="!model.id"
      layout="modal"
      grid-classes="grid grid-cols-8 gap-x-4"
      @cancel="$emit('cancel')"
      @submit="onSubmit"
  >
    <NonTaxableWarning
        :data="model"
        class="col-span-8 mb-4"
    />
    <code-input
      v-model="model.code"
      :model="model"
      :resource-name="globalResources.BenefitCodes"
      class="col-span-8 md:col-span-3 xl:col-span-1"
    />
    <base-input
        v-model="model.description"
        :label="$t('Description')"
        :placeholder="$t('Description')"
        id="description"
        rules="max:150"
        class="col-span-8 md:col-span-5 xl:col-span-2"
    />
    <w-2-code-select
        v-model="model.w2_code_id"
        clearable
        class="col-span-8 md:col-span-2"
    />
    <base-select
        v-model="model.calculate_by"
        :label="$t('Calculate By')"
        :placeholder="$t('Calculate By')"
        :options="calculateByOptions"
        id="calculate_by"
        rules="required"
        class="col-span-8 md:col-span-2 xl:col-span-1"
        @change="onChangeCalculateBy"
    />

    <base-select
        v-model="model.multiply_by"
        :label="$t('Multiply Rate By')"
        :placeholder="$t('Multiply Rate By')"
        :options="getMultiplyByOptions"
        id="multiply_by"
        rules="required"
        class="col-span-8 md:col-span-1"
        @change="onChangeMultiplyBy"
    />
    <div class="col-span-6 md:col-span-1">
      <ActiveSwitch v-model="model.active"></ActiveSwitch>
    </div>
    <account-select
        v-model="model.liability_account"
        :label="$t('Liability Account')"
        :placeholder="$t('Liability Account')"
        clearable
        value-key="number"
        label-key="number"
        id="liability_account"
        rules="required"
        class="col-span-4 md:col-span-2"
    />
    <sub-account-select
        v-model="model.liability_subaccount"
        :label="$t('Liability Subaccount')"
        :placeholder="$t('Liability Subaccount')"
        clearable
        value-key="number"
        label-key="number"
        id="liability_subaccount"
        class="col-span-4 md:col-span-2 xl:col-span-1"
    />
    <account-select
        v-model="model.expense_account"
        :label="$t('Expense Account')"
        :placeholder="$t('Expense Account')"
        clearable
        value-key="number"
        label-key="number"
        id="expense_account"
        class="col-span-4 md:col-span-2"
    />
    <sub-account-select
        v-model="model.expense_subaccount"
        :label="$t('Expense Subaccount')"
        :placeholder="$t('Expense Subaccount')"
        clearable
        value-key="number"
        label-key="number"
        id="expense_subaccount"
        class="col-span-4 md:col-span-2 xl:col-span-1"
    />
    <div class="col-span-6 md:col-span-1">
    </div>
    <div class="col-span-6 md:col-span-1">
      <base-switch
        id="available_in_timecards"
        v-model="model.available_in_timecards"
        :label-info="$t('Available in Timecards')"
      />
    </div>
    <div class="col-span-8 md:col-span-2 mb-4 md:mt-0">
      <base-checkbox
          v-model="model.use_employee_regular_rate"
          :disabled="useEmployeeRegularRateDisabled"
          :label="$t('Use Employee regular rate')"
          id="use_employee_regular_rate"
      />
    </div>
    <div class="col-span-8">
      <el-collapse v-model="activeGroups">
        <BaseCollapseSection
          key="subject_to"
          name="subject_to"
        >
          <template v-slot:title>
            <div class="flex w-full items-center justify-between">
              {{ $t('Subject To') }}
              <div class="flex items-center">
                {{ bulkSubjectToState ? $t('Disable All') : $t('Enable All') }}
                <BaseSwitch
                  v-model="bulkSubjectToState"
                  class="ml-2 mr-4"
                  @click.native.stop
                  @change="triggerRrBulkAction"
                />
              </div>
            </div>
          </template>
          <div class="grid grid-cols-8 gap-x-4">
            <div class="col-span-8 md:col-span-2 mb-2">
              <BaseCheckbox
                v-model="model.subject_to_workers_comp"
                :label="(`Worker's Compensation`)"
                id="subject_to_workers_comp"
              />
              <BaseCheckbox
                v-model="model.subject_to_liability_insurance"
                :label="(`General Liability Insurance`)"
                id="subject_to_liability_insurance"
              />
            </div>
          </div>
        </BaseCollapseSection>
      </el-collapse>
    </div>
    <div class="col-span-8">
      <el-collapse v-model="activeGroups">
        <base-collapse-section
            key="subject_to"
            name="subject_to"
            v-if="false"
        >
        <!--TODO This part doesnt work on benefits yet as we don't support taxable benefits-->
          <template v-slot:title>
            <div class="flex w-full items-center justify-between">
              {{ $t('Subject To') }}
              <div class="flex items-center">
                {{ bulkTaxesState ? $t('Disable All') : $t('Enable All') }}
                <base-switch
                    v-model="bulkTaxesState"
                    class="ml-2 mr-4"
                    @click.native.stop
                    @change="triggerRrBulkAction"
                />
              </div>
            </div>
          </template>
          <div class="grid grid-cols-8 gap-x-4">
            <div class="col-span-8 md:col-span-2 mb-4">
              <base-checkbox
                  v-model="model.subject_to_fui"
                  :label="$t('Federal Unemployment')"
                  id="subject_to_fui"
              />
              <base-checkbox
                  v-model="model.subject_to_sui"
                  :label="$t('State Unemployment')"
                  id="subject_to_sui"
              />
              <base-checkbox
                  v-model="model.subject_to_sdi"
                  :label="$t('State Disability')"
                  id="subject_to_sdi"
              />
              <base-checkbox
                  v-model="model.subject_to_fica"
                  :label="$t('Fica/Medicare')"
                  id="subject_to_fica"
              />
            </div>
            <base-select
                v-model="model.subject_to_fit"
                :options="withholdingSubjectOptions"
                :label="$t('Federal Withholding')"
                class="col-span-8 md:col-span-2 xl:col-span-1"
            />
            <base-select
                v-model="model.subject_to_sit"
                :options="withholdingSubjectOptions"
                :label="$t('State Withholding')"
                class="col-span-8 md:col-span-2 xl:col-span-1"
            />
            <base-select
                v-model="model.subject_to_lit"
                :options="withholdingSubjectOptions"
                :label="$t('Local Withholding')"
                class="col-span-8 md:col-span-2 xl:col-span-1"
            />
          </div>
        </base-collapse-section>
        <base-collapse-section
            :title="$t('Additional Information')"
            key="additional_information"
            name="additional_information"
        >
          <div class="grid grid-cols-8 gap-x-4">
            <div class="col-span-8 md:col-span-2 mb-4">
              <base-checkbox
                  v-model="model.include_on_certified_payroll_report"
                  :label="$t('Include On Certified Payroll')"
                  id="include_on_certified_payroll_report"
              />
              <base-checkbox
                  v-model="model.include_on_union_reports"
                  :label="$t('Include On Union Report')"
                  id="include_on_union_reports"
              />
              <base-checkbox
                  v-model="model.show_on_paystub"
                  :label="$t('Show on Paystub')"
                  id="show_on_paystub"
              />
              <base-checkbox
                  v-model="model.should_distribute"
                  :label="$t('Should Distribute')"
                  id="should_distribute"
              />
              <base-checkbox
                  v-model="model.is_401k_match"
                  :label="$t('Is Benefit a 401K Employer Match')"
                  id="is_401k_match"
              />
            </div>
            <base-select
                v-model="model.classify_as"
                :label="$t('Classify As')"
                :options="classifyAsOptions"
                class="col-span-8 md:col-span-2 mb-4"
            />
            <base-select
                v-model="model.certified_payroll_category_type"
                :label="$t('Certified Payroll Category Type')"
                :placeholder="$t('Category Type')"
                :tip="$t('Certified payroll category type')"
                :options="categoryTypeOptions"
                clearable
                id="certified_payroll_category_type"
                class="col-span-8 md:col-span-2"
            />
            <entity-select
                v-model="model.job_type_id"
                :label="$t('Normal Job Cost Type')"
                :placeholder="$t('Normal Job Cost Type')"
                :add-entity="false"
                clearable
                url="/restify/job-types?type=cost&sort=index"
                id="job_type_id"
                class="col-span-8 md:col-span-2"
            />
          </div>
        </base-collapse-section>
      </el-collapse>
    </div>
  </base-form>
</template>
<script lang="ts">

  import axios from 'axios'
  import pick from 'lodash/pick'
  import { defineComponent } from 'vue'
  import {
    CALCULATE_BY_PERCENTAGE_KEY,
    CALCULATE_BY_RATE_KEY,
    calculateByOptions,
    categoryTypeOptions,
    multiplyBy,
    multiplyOptions,
    SPECIAL_PAY_UNITS_KEY,
  } from '@/modules/payroll/components/rates/util'
  import { globalResources } from '@/components/form/util.js'
  import BaseCollapseSection from '@/components/form/BaseCollapseSection.vue'
  import NonTaxableWarning from '@/modules/payroll/components/codes/NonTaxableWarning.vue'
  import { BenefitCode } from '@/modules/common/types/models'

  const TAX_FIELDS = [
    'subject_to_fit',
    'subject_to_sit',
    'subject_to_lit',
    'subject_to_fica',
    'subject_to_fui',
    'subject_to_sdi',
    'subject_to_sui',
  ]

  const TAX_YES = 'yes'
  const TAX_NO = 'no'
  const TAX_FLAT = 'flat'

  const CLASSIFICATION_FRINGE_PAID_TO_PLANS = 'fringe-paid-to-plans'
  const CLASSIFICATION_FRINGE_PAID_IN_CASH = 'fringe-paid-in-cash'

  export default defineComponent({
    components: {
      BaseCollapseSection,
      NonTaxableWarning,
    },
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        globalResources,
        showCancel : true,
        SPECIAL_PAY_UNITS_KEY,
        multiplyOptions,
        calculateByOptions,
        categoryTypeOptions,
        bulkTaxesState: false,
        activeGroups: ['subject_to', 'additional_information'],
        withholdingSubjectOptions: [
          {
            label: this.$t('Yes'),
            value: TAX_YES,
          },
          {
            label: this.$t('No'),
            value: TAX_NO,
          },
          {
            label: this.$t('Flat'),
            value: TAX_FLAT,
          },
        ],
        loading: false,
        model: {
          code: '',
          description: '',
          w2_code_id: null,
          job_type_id: null,
          liability_account: '',
          liability_subaccount: '',
          expense_account: '',
          expense_subaccount: '',
          calculate_by: CALCULATE_BY_RATE_KEY,
          multiply_by: multiplyBy.REGULAR_HOURS,
          use_employee_regular_rate: false,
          subject_to_workers_comp: true,
          subject_to_liability_insurance: true,
          subject_to_fit: TAX_NO,
          subject_to_sit: TAX_NO,
          subject_to_lit: TAX_NO,
          subject_to_fica: false,
          subject_to_fui: false,
          subject_to_sdi: false,
          subject_to_sui: false,
          include_on_certified_payroll_report: true,
          include_on_union_reports: true,
          show_on_paystub: true,
          should_distribute: false,
          classify_as: CLASSIFICATION_FRINGE_PAID_TO_PLANS,
          certified_payroll_category_type: null,
          is_401k_match: false,
          active: true,
          show_in_ytds: false,
          available_in_timecards: true,
        } as BenefitCode,
        bulkSubjectToState: true,
      }
    },
    computed: {
      getMultiplyByOptions(): string {
        // @ts-ignore
        return this.multiplyOptions[this.model.calculate_by]
      },
      laborTypeId(): string {
        return this.$store.getters['globalLists/getLaborTypeIdByModule'](globalResources.JobCostTypes)
      },
      useEmployeeRegularRateDisabled(): boolean {
        return this.model.multiply_by !== multiplyBy[SPECIAL_PAY_UNITS_KEY]
      },
      isSubjectToTax() {
        let isTaxable = false
        for (const key of TAX_FIELDS) {
          const value = this.model[key as keyof BenefitCode]
          if (value === TAX_YES) {
            isTaxable = true
            break
          }
        }
        return isTaxable
      },
      classifyAsOptions() {
        if (this.isSubjectToTax) {
          return [
            {
              label: this.$t('Fringe Paid in Cash'),
              value: CLASSIFICATION_FRINGE_PAID_IN_CASH,
            },
          ]

        }
        return [
          {
            label: this.$t('Fringe Paid to Plans'),
            value: CLASSIFICATION_FRINGE_PAID_TO_PLANS,
          },
        ]
      }
    },
    methods: {
      async onSubmit() {
        try {
          this.loading = true
          if (this.model.id) {
            await axios.put(`/restify/benefit-codes/${this.model.id}`, this.model)
            this.$success(this.$t('Benefit code updated.'))
            this.$emit('save', this.model)
          } else {
            const { data }  = await axios.post('/restify/benefit-codes', this.model)
            this.$success(this.$t('Benefit code created.'))
            // @ts-ignore
            this.$createAnotherEntity ? this.$emit('create-another') : this.$emit('save', data)
          }
        } catch (err: any) {
          if (err.handled) {
            return
          }
          console.log('err', err)
          this.$error(this.$t('Could not update the benefit code.'))
        } finally {
          this.loading = false
        }
      },
      triggerRrBulkAction(state: boolean) {
        this.model = {
          ...this.model,
          subject_to_fit: state ? TAX_YES : TAX_NO,
          subject_to_sit: state ? TAX_YES : TAX_NO,
          subject_to_lit: state ? TAX_YES : TAX_NO,
          subject_to_fica: state,
          subject_to_fui: state,
          subject_to_sdi: state,
          subject_to_sui: state,
          subject_to_workers_comp: state,
          subject_to_liability_insurance: state,
        }

        if (this.useEmployeeRegularRateDisabled) {
          this.model.use_employee_regular_rate = false
        } else {
          this.model.use_employee_regular_rate = state
        }
      },
      onChangeMultiplyBy() {
        this.model.use_employee_regular_rate = false
      },
      onChangeCalculateBy(option: string) {
        if (option === CALCULATE_BY_PERCENTAGE_KEY) {
          this.model.multiply_by = multiplyBy.REGULAR_HOURLY_PAY
          return
        }
        this.model.multiply_by = multiplyBy.REGULAR_HOURS
      },
      setBulkTaxesState() {
        const taxObj = pick(this.model, TAX_FIELDS) || {}
        this.bulkTaxesState = Object.values(taxObj).every((value) => [TAX_YES, TAX_FLAT, true].includes(value))
        this.model.job_type_id = this.laborTypeId
      },
    },
    created() {
      if (!this.model.id) {
        this.triggerRrBulkAction(false)
        this.bulkTaxesState = false
      }
    },
    watch: {
      data: {
        immediate: true,
        handler(value) {
          if (!value?.id) {
            return this.bulkTaxesState = true
          }
          this.model = {
            ...this.model,
            ...value.attributes,
          }
          this.setBulkTaxesState()
        },
      },
      isSubjectToTax: {
        immediate: true,
        handler(value) {
          if (value) {
            this.model.classify_as = CLASSIFICATION_FRINGE_PAID_IN_CASH
          } else {
            this.model.classify_as = CLASSIFICATION_FRINGE_PAID_TO_PLANS
          }
        }
      }
    },
  })
</script>
