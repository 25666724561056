export const colMappings = {
  Employee: {
    value: 'employee_code',
    label: 'Employee Code',
    alternativeLabels: ['Employee'],
  },
  CraftCode: {
    value: 'craft_code_level',
    label: 'Craft Code',
    alternativeLabels: ['Craft Code/Level'],
  },
  Job: {
    value: 'job_number',
    label: 'Job Number',
    alternativeLabels: ['Job', 'Job No.'],
  },
  PhaseCode: {
    value: 'phase_code',
    label: 'Phase Code',
  },
  CostCode: {
    value: 'cost_code',
    label: 'Cost Code',
  },
  ChangeOrder: {
    value: 'change_order',
    label: 'Change Order',
  },
  RegularHours: {
    value: 'regular_hours',
    label: 'Regular Hours',
    alternativeLabels: ['Regular Hrs'],
  },
  OvertimeHours: {
    value: 'overtime_hours',
    label: 'Overtime Hours',
    alternativeLabels: ['Overtime Hrs'],
  },
  PremiumHours: {
    value: 'premium_hours',
    label: 'Premium Hours',
    alternativeLabels: ['Premium Hours'],
  },
  Day: {
    value: 'day',
    label: 'Day Of Week',
    alternativeLabels: ['Day'],
  },
  EquipmentCode: {
    value: 'equipment_code',
    label: 'Equipment Code',
    alternativeLabels: ['Equipment', 'EQP Code', 'EQP'],
  },
  SpecialType: {
    value: 'special_type',
    label: 'Special Pay Type',
    alternativeLabels: ['Special Type'],
  },
  SpecialCode: {
    value: 'special_code',
    label: 'Special Code',
    alternativeLabels: ['Special Pay Code', 'Special Pay/Ded Code'],
  },
  Units: {
    value: 'units',
    label: 'Special Units',
    alternativeLabels: ['Units'],
  },
  SpecialRate: {
    value: 'special_rate',
    label: 'Special Rate',
    alternativeLabels: ['Special Rate/Percent'],
  },
  EquipmentCostType: {
    value: 'equipment_cost_type',
    label: 'EQP Cost Type',
    alternativeLabels: ['Equipment Cost Type']
  },
  WorkOrder: {
    value: 'work_order_number',
    label: 'Work Order',
    alternativeLabels: ['Work Order Number'],
  },
  MaterialCode: {
    value: 'material_or_maint_code',
    label: 'Mat/Maint Code',
    alternativeLabels: ['Inventory MTL Class Code', 'Material Code', 'Material'],
  },
  Account: {
    value: 'account',
    label: 'Account',
    alternativeLabels: ['GL Account'],
  },
  SubAccount: {
    value: 'subaccount',
    label: 'Sub Account',
    alternativeLabels: ['GL Sub Account'],
  },
  PaymentNumber: {
    value: 'payment_number',
    label: 'Payment Number',
    alternativeLabels: ['Check Number'],
  },
  PaymentDate: {
    value: 'payment_date',
    label: 'Payment Date',
    alternativeLabels: ['Check Date'],
  },
  Week: {
    value: 'week',
    label: 'Week',
    alternativeLabels: ['Week Number'],
  },
  WorkersComp: {
    value: 'workers_comp_rate_code',
    label: "Worker Comp Code",
    alternativeLabels: ['Work Comp Code'],
  },
  RegularRate: {
    value: 'regular_rate',
    label: 'Regular Rate',
  },
  OvertimeRate: {
    value: 'overtime_rate',
    label: 'Overtime Rate',
  },
  PremiumRate: {
    value: 'premium_rate',
    label: 'Premium Rate',
  },
  Ssn: {
    value: 'ssn',
    label: 'SSN',
    alternativeLabels: ['Employee SSN'],
  },
  Date: {
    value: 'date',
    label: 'Date',
    alternativeLabels: ['Work Date'],
  },
  Union: {
    value: 'union',
    label: 'Union',
  },
  GenLiability: {
    value: 'gen_liability_rate_code',
    label: 'Gen Liability',
  },
  SpecialAccount: {
    value: 'special_account',
    label: 'Special Account',
  },
  SpecialSubAccount: {
    value: 'special_subaccount',
    label: 'Special Sub Account',
  },
  SuiState: {
    value: 'sui_sdi_state',
    label: 'SUI/SDI State',
  },
  WitHoldingState: {
    value: 'withholding_state',
    label: 'State',
    alternativeLabels: ['State Code', 'Withholding State']
  },
  WitHoldingLocal1: {
    value: 'withholding_local',
    label: 'Local',
    alternativeLabels: ['Local Code', 'Withholding Local']
  },
  WitHoldingLocal2: {
    value: 'withholding_local_2',
    label: 'Withholding Local 2',
  },
}
export const options = [
  colMappings.Employee,
  colMappings.CraftCode,
  colMappings.Job,
  colMappings.PhaseCode,
  colMappings.CostCode,
  colMappings.ChangeOrder,
  colMappings.RegularHours,
  colMappings.OvertimeHours,
  colMappings.PremiumHours,
  colMappings.Day,
  colMappings.EquipmentCode,
  colMappings.SpecialType,
  colMappings.SpecialCode,
  colMappings.SpecialRate,
  colMappings.Units,
  colMappings.EquipmentCostType,
  colMappings.WorkOrder,
  colMappings.MaterialCode,
  colMappings.Account,
  colMappings.SubAccount,
  colMappings.PaymentNumber,
  colMappings.PaymentDate,
  colMappings.Week,
  colMappings.WorkersComp,
  colMappings.RegularRate,
  colMappings.OvertimeRate,
  colMappings.PremiumRate,
  colMappings.Ssn,
  colMappings.Date,
  colMappings.GenLiability,
  colMappings.SpecialAccount,
  colMappings.SpecialSubAccount,
  colMappings.SuiState,
  colMappings.WitHoldingState,
  colMappings.WitHoldingLocal1,
  colMappings.WitHoldingLocal2,
]

export function getColMapping(name, colMappings = {}, index = 0) {
  let column = {
    label: name,
    key: name || `Column ${index + 1}`,
    mapping: null,
  }
  name = name.toLowerCase()
  for (let key in colMappings) {
    let col = colMappings[key]
    let labels = [col.label]

    if (col.alternativeLabels) {
      labels.push(...col.alternativeLabels)
    }

    labels = labels.map(l => l.toLowerCase())

    if (labels.includes(name) || col.value === name) {
      column.label = col.label
      column.mapping = col.value
      break
    }
  }
  return column
}

export function getInitialMapping(options) {
  let mapping = {}
  options.forEach(option => {
    mapping[option.value] = -1
  })
  return mapping
}
