<template>
  <div>
    <layout-tabs :items="filteredItems"/>
    <router-view/>
  </div>
</template>
<script>
  export default {
    computed: {
      items() {
        return [
          {
            name: this.$t('Create Payments'),
            path: `/accounts-payable/payments/create`,
            permission: 'invoice_payments_store',
          },
          {
            name: this.$t('Payment Batches'),
            path: `/accounts-payable/payments/batches`,
            permission: 'invoice_payments_show',
          },
          {
            name: this.$t('Payment History'),
            path: `/accounts-payable/payments/all`,
            permission: 'invoice_payments_show',
          },
        ]
      },
      filteredItems() {
        return this.items.filter(item => this.$can(item.permission))
      },
    },
  }
</script>
