<template>
  <EmployeeRatesForm
      :data="employee"
      :hide-employee-info="true"
      :rate-type="employeeRatesTypes.FlexibleBenefitRates"
  />
</template>
<script lang="ts" setup>
  import { computed } from 'vue'
  import store from '@/store'
  import { employeeRatesTypes } from '@/modules/payroll/components/rates/util'
  import EmployeeRatesForm from '@/modules/payroll/components/employee-rates/EmployeeRatesForm.vue'

  const employee = computed(() => {
    return store.state.payroll.currentEmployee
  })
</script>
