<template>
  <component :is="tag"
             class="p-badge"
             :class="{
              [`badge-${type}`]: type,
              'badge-pill': pill,
             }"
  >
    <slot/>
    <CloseButton
        v-if="closeable"
        size="sm"
        @click="$emit('close')"/>
  </component>
</template>
<script>
  import CloseButton from "@/components/common/CloseButton.vue";

  export default {
    name: 'base-badge',
    components: { CloseButton },
    props: {
      tag: {
        type: String,
        default: 'span',
        description: 'Html tag to use for the badge.'
      },
      pill: {
        type: Boolean,
        default: true,
        description: 'Whether badge is of pill type'
      },
      type: {
        type: String,
        default: 'default',
        validator: (value) => {
          let acceptedValues = ['primary', 'info', 'success', 'warning', 'danger', 'default']
          return acceptedValues.indexOf(value) !== -1
        },
        description: 'Badge type (primary|info|danger|default|warning|success)'
      },
      closeable: {
        type: Boolean,
        default: false,
      }
    }
  }
</script>
<style>
  .p-badge {
    letter-spacing: 0.48px;
    @apply inline-flex items-center px-3 py-1 rounded-full text-xs font-semibold leading-4;
  }

  .p-badge.badge-default {
    @apply bg-gray-600 text-gray-600 bg-opacity-5;
  }

  .p-badge.badge-info {
    @apply bg-blue-600 bg-opacity-5 text-blue-600;
  }

  .p-badge.badge-warning {
    @apply bg-orange-600 bg-opacity-5 text-orange-600;
  }

  .p-badge.badge-success {
    @apply text-green-600 bg-green-600 bg-opacity-5;
  }

  .p-badge.badge-danger {
    @apply bg-red-600 bg-opacity-5 text-red-600;
  }
</style>
