<template>
  <base-select
    id="status"
    :label="$t('Status')"
    :placeholder="$t('Status')"
    :options="statusOptions"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>
<script>
import { VendorStatusOptions } from "@/modules/accounts-payable/enum/vendor";

export default {
  data() {
    return {
      statusOptions: VendorStatusOptions,
    }
  }
}
</script>
