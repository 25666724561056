<template>
  <base-select
      v-bind="$attrs"
      v-on="$listeners"
      :name="$attrs.name || $t('Service Code')"
      :placeholder="$attrs.placeholder || $t('Select service code')"
      :label="$attrs.label || $t('Service billing code')"
      :options="options"
      :id="$attrs.id || 'service-code-select'"
      :value-key="valueKey"
      :label-key="labelKey"
      :table-columns="tableColumns"
  />
</template>
<script>

  export default {
    inheritAttrs: false,
    props: {
      valueKey: {
        type: String,
        default: 'id',
      },
      labelKey: {
        type: String,
        default: 'code',
      },
    },
    computed: {
      tableColumns() {
        return [
          {
            minWidth: 100,
            maxWidth: 100,
            name: this.$t('Code'),
            prop: 'code',
          },
          {
            minWidth: 150,
            maxWidth: 150,
            name: this.$t('Description'),
            prop: 'description',
          },
        ]
      },
      options() {
        return this.$store.getters['globalLists/getResourceOptions'](this.$globalResources.ServiceBillingCodes)
      },
    },
  }
</script>
