<template>
  <div class="job-billings">
    <layout-tabs
        v-if="!onViewResourcePage"
        :items="filteredItems"
    />
    <router-view/>
  </div>
</template>
<script>
  export default {
    computed: {
      items() {
        return [
          {
            name: this.$t('Progress'),
            path: '/accounts-receivable/billings/progress',
            permission: 'customers_show',
          },
          {
            name: this.$t('Unit Price'),
            path: '/accounts-receivable/billings/unit-price',
            permission: 'customers_show',
          },
          {
            name: this.$t('Cost Plus'),
            path: '/accounts-receivable/billings/cost-plus',
            permission: 'customers_show',
          },
          {
            name: this.$t('Lump Sum'),
            path: '/accounts-receivable/billings/lump-sum',
            permission: 'customers_show',
          },
          {
            name: this.$t('Service'),
            path: '/accounts-receivable/billings/service',
            permission: 'customers_show',
          },
          {
            name: this.$t('All Billings'),
            path: '/accounts-receivable/billings/all-billings',
            permission: 'billings_show',
          },
        ]
      },
      filteredItems() {
        const permissions = this.$store.getters['auth/permissions']
        return this.items.filter(item => {
          if (!item.productionReady && this.isProduction) {
            return false
          }
          return permissions.includes(item.permission)
        })
      },
    },
  }
</script>
<style lang="scss">
  .job-billings {
    .el-collapse-item__header {
      @apply text-base mx-1;
    }
  }
</style>

