<template>
  <base-form-dialog v-bind="$attrs"
                    v-on="$listeners"
                    :title="title"
                    :has-padding="true"
                    size="xl"
                    class="w2-form"
  >
    <div class="flex flex-col w-full max-w-full mt-4">
      <div class="flex flex-col sm:flex-row space-y-4 justify-between items-start w-full mb-8">
        <div>
          <h5 class="text-gray-700">{{ company.name || '' }}</h5>
          <div v-if="company.address1" class="flex text-xs leading-5 text-cool-gray-500 font-medium">
            <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-cool-gray-400" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd"
                    d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
                    clip-rule="evenodd"></path>
            </svg>
            <span>{{ company.address1 }}</span>
            <span>{{ company.city }}, {{ company.state }}</span>
          </div>
        </div>
        <div>
          <div class="flex w-full text-sm leading-5 text-cool-gray-500 font-medium">
            <div class="sm:text-right">
              <span class="text-gray-700 mb-2">{{ employee.Name }}, {{ employee.Code }}</span>
              <dd v-if="employeeAddress.address_1" class="flex items-center capitalize mt-1">
                {{ employeeAddress.address_1 }}, {{ employeeAddress.city }}, {{ employeeAddress.state }}
                {{ employeeAddress.zip_code }}
              </dd>
            </div>
          </div>
        </div>
      </div>

      <base-data-table :data="formData"
                       :columns="federalColumns"
                       :show-pagination="false"
                       :title="$t('Federal')"
      >
        <template #ssn>
          {{ employeeSsn }}
        </template>
      </base-data-table>
      <base-data-table :data="formData"
                       :columns="stateColumns"
                       :show-pagination="false"
                       :title="$t('State/Local')"
                       class="mt-4"
      >
        <template #extra>
          &nbsp;
        </template>
      </base-data-table>
    </div>

    <FileAttachments
        :entity-id="data.id"
        class="mt-2"
        entity="employee-w2-forms"
    />
  </base-form-dialog>
</template>
<script>
  export default {
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      employeeSsn() {
        const { ssn1, ssn2, ssn3 } = this.employee
        return `${ssn1}-${ssn2}-${ssn3}`
      },
      employeeAddress() {
        const { address } = this.employee
        return {
          address_1: address.Address_1,
          address_2: address.Address_2,
          city: address.City,
          state: address.State,
        }
      },
      company() {
        return this.data.attributes.company_data
      },
      employee() {
        return this.data.attributes.employee_data
      },
      formData() {
        return [this.data.attributes.form_data]
      },
      title() {
        return this.$t(`W2 Form for ${this.employee.Name}`)
      },
      federalColumns() {
        return [
          {
            label: this.$t('SSN#'),
            prop: 'ssn',
            minWidth: '150px',
            maxWidth: '150px',
          },
          {
            label: this.$t('Box 1 (fit wage)'),
            prop: '_1WagTips',
            component: 'FormattedPrice',
            minWidth: '150px',
            maxWidth: '150px',
          },
          {
            label: this.$t('Box 2 (fit w/h)'),
            prop: '_1FedTaxW',
            component: 'FormattedPrice',
            minWidth: '150px',
            maxWidth: '150px',
          },
          {
            label: this.$t('Box 3 (fica wage)'),
            prop: '_1SSWages',
            component: 'FormattedPrice',
            minWidth: '150px',
            maxWidth: '150px',
          },
          {
            label: this.$t('Box 4 (fica w/h)'),
            prop: '_1SSTaxWd',
            component: 'FormattedPrice',
            minWidth: '150px',
            maxWidth: '150px',
          },
          {
            label: this.$t('Box 5 (med wage)'),
            prop: '_1MedcrWT',
            component: 'FormattedPrice',
            minWidth: '150px',
            maxWidth: '150px',
          },
          {
            label: this.$t('Box 6 (med w/h)'),
            prop: '_1MedcrTW',
            component: 'FormattedPrice',
            minWidth: '150px',
            maxWidth: '150px',
          },
          {
            label: this.$t('Box 12d (401k)'),
            prop: '_1d12Box',
            component: 'FormattedPrice',
            minWidth: '150px',
            maxWidth: '150px',
          },
        ]
      },
      stateColumns() {
        return [
          {
            label: this.$t('State'),
            prop: '_1STa',
            minWidth: '150px',
            maxWidth: '150px',
          },
          {
            label: this.$t('Box 14 (sdi w/h)'),
            prop: '_1Sa',
            minWidth: '150px',
            maxWidth: '150px',
          },
          {
            label: this.$t('Box 16 (sit wage)'),
            prop: '_1StWgTa',
            component: 'FormattedPrice',
            minWidth: '150px',
            maxWidth: '150px',
          },
          {
            label: this.$t('Box 17 (sit w/h)'),
            prop: '_1StTxA',
            component: 'FormattedPrice',
            minWidth: '150px',
            maxWidth: '150px',
          },
          {
            label: this.$t('Box 18 (loc wage)'),
            prop: '_1LcWgTb',
            component: 'FormattedPrice',
            minWidth: '150px',
            maxWidth: '150px',
          },
          {
            label: this.$t('Box 19 (loc w/h)'),
            prop: '_1LcTxB',
            component: 'FormattedPrice',
            minWidth: '150px',
            maxWidth: '150px',
          },
          {
            label: '',
            width: '100%',
            prop: 'extra'
          }
        ]
      }
    },
  }
</script>
<style scoped lang="scss">
  .w2-form .prose hr {
    @apply my-4;
  }

  .w2-form .status-badge {
    width: 130px;
  }

  .collapse-title {
    @apply mt-2 mb-0;
  }
</style>

