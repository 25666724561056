<template>
  <div>
    <span class="action-item-text"
          @click="showDialog = true">
      <div class="p-2 bg-primary-50 mr-2 rounded-md mr-2">
        <UploadIcon class="w-4 h-4 text-primary-500"/>
      </div>
      <span>{{ $t('Upload') }}</span>
    </span>

    <base-form-dialog v-if="showDialog"
                      :open.sync="showDialog"
                      :title="$t(`Upload data for ${entity}`)"
                      :append-to-body="true"
                      class="upload-form"
    >
      <ValidationObserver v-slot="{ handleSubmit }">
        <base-form :loading="loading"
                   layout="modal"
                   :submit-disabled="!form.file"
                   :save-text="$t('Upload')"
                   @submit="handleSubmit(onSubmit)"
        >
          <div class="col-span-6 flex justify-center mb-6">
            <DownloadAction :url="`${url}/download`"
                            :entity="entity"
                            class="mb-1"
            >
              <span class="mr-1">{{ $t('Download current data ') }}</span>
            </DownloadAction>
          </div>
          <div class="col-span-6 flex justify-center mb-6 upload-container">
            <el-upload
                class="flex flex-col items-center w-full"
                action="default"
                drag
                accept=".xlsx, .xls, .csv"
                :auto-upload="false"
                :on-remove="onFileRemove"
                :on-change="onFileChange"
                :limit="1"
            >
              <base-button class="select-file-button focus:outline-none focus:shadow-outline-blue"
                           ref="uploadButton"
                           variant="white">
                {{ $t('Choose or drag Excel File') }}
                <FilePlusIcon class="ml-1 w-5 h-5 text-green-500"/>
              </base-button>
            </el-upload>
          </div>
        </base-form>
      </ValidationObserver>
    </base-form-dialog>
  </div>
</template>
<script>
  import axios from 'axios'
  import { Upload } from 'element-ui'
  import { FilePlusIcon, UploadIcon } from 'vue-feather-icons'
  import DownloadAction from "@/components/table/actions/download/DownloadAction";

  export default {
    name: 'UploadAction',
    components: {
      UploadIcon,
      FilePlusIcon,
      DownloadAction,
      [Upload.name]: Upload,
    },
    props: {
      url: {
        type: String,
        default: '',
      },
      entity: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        form: {
          file: null,
        },
        loading: false,
        showDialog: false,
      }
    },
    methods: {
      onFileRemove() {
        this.form.file = null
      },
      onFileChange(event, files) {
        if (!files) {
          return
        }
        this.form.file = files[0]
      },
      async onSubmit() {
        try {
          if (!this.form.file) {
            return
          }
          this.loading = true
          const formData = new FormData()
          formData.append('file', this.form.file?.raw)
          await axios.post(`${this.url}/upload`, formData)

          this.showDialog = false
          this.$success(this.$t('Data uploaded successfully!'))
          this.$emit('refresh')
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Something went wrong please try again.'))
        } finally {
          this.loading = false
        }
      }
    }
  }
</script>
<style scoped lang="scss">
  .upload-form :deep(.form-footer) {
    @apply justify-center;
  }

  .upload-container {
    :deep(.el-upload) {
      @apply w-full h-full;
    }

    :deep(.el-upload-dragger) {
      @apply w-full flex justify-center items-center;
    }
  }
</style>
