<template>
  <AgDataTable
      :url="url"
      :columns="columns"
      :add-entity-in-new-tab="addEntityInNewTab"
      :add-text="$t('New category')"
      :actions-column-width="60"
      actions="add,edit,search,refresh"
      ref="table"
  />
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { Column } from "@/components/ag-grid/tableTypes";

  export default defineComponent({
    data() {
      return {
        addEntityInNewTab: true,
        url: '/restify/service-billing-categories',
        columns: [
          {
            headerName: this.$t('Category'),
            field: 'attributes.code',
            component: 'EntityLink',
            redirectTo: '/service-billing/settings/categories/{ID}/edit',
            minWidth: 200,
            maxWidth: 320,
          },
          {
            headerName: this.$t('Description'),
            field: 'attributes.description',
          },
        ] as Column[],
      }
    },
  })
</script>

