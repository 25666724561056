<template>
  <BaseReportForm
    :filters="model"
    :report-number="reportNumber"
  >
    <template #filters>

      <BaseFilterRow :title="$t('Select By')">
        <BaseSelect
          v-model="model.select_by"
          :options="selectByOptions"
          :placeholder="$t('Select By')"
          class="col-span-4"
          @change="onSelectByChanged"
        />
      </BaseFilterRow>

      <BaseFilterRow :title="$t('Sort & Subtotal By')">
        <BaseSelect
          v-model="model.sort_by"
          :options="sortByOptions"
          :placeholder="$t('Sort By')"
          class="col-span-4"
          @change="onSortByChanged"
        />
      </BaseFilterRow>

      <BaseFilterRow
        v-if="model.select_by === VendorPaymentRegisterOptions.SelectBy.FiscalPeriods"
        :title="$t('Fiscal Periods')"
      >
        <div class="flex col-span-8 space-x-2">
          <BaseSelect
            v-model="model.period_start"
            :placeholder="$t('Fiscal Period From')"
            :name="$t('Fiscal Period From')"
            :options="periods"
            :hide-label="true"
            rules="required"
          />
          <BaseSelect
            v-model="model.period_end"
            :placeholder="$t('Fiscal Period To')"
            :name="$t('Fiscal Period To')"
            :options="periods"
            rules="required"
          />

          <BaseInput
            v-model="model.fiscal_year"
            :placeholder="$t('Fiscal Year (YYYY)')"
            :name="$t('Fiscal Year')"
            type="number"
            rules="required"
          />
        </div>
      </BaseFilterRow>

      <BaseFilterRow
        v-if="[
          VendorPaymentRegisterOptions.SortBy.VendorPayment,
          VendorPaymentRegisterOptions.SortBy.VendorInvoice,
        ].includes(model.sort_by)"
        :title="$t('Cost Center')"
      >
        <CostCenterSelect
          v-model="model.cost_center"
          :class="{
            'col-span-4': !costCenterHasType,
            'col-span-2': costCenterHasType,
          }"
          @change="model.type_id = null"
          rules="required"
        />

        <BaseSelect
          v-if="costCenterHasType"
          v-model="model.type_id"
          :options="costCenterTypeOptions"
          :placeholder="$t('Type')"
          value-key="id"
          label-key="abbr"
          class="col-span-2"
          clearable
      />
      </BaseFilterRow>

      <BaseFilterRow
        v-if="model.select_by === VendorPaymentRegisterOptions.SelectBy.PaymentDates"
        :title="$t('Payment Date Range')"
      >
        <DateRangeFilter
          v-model="model"
          startDateKey="payment_date_from"
          endDateKey="payment_date_to"
          class="col-span-4"
        />
      </BaseFilterRow>

      <VendorRangeFilter
        v-model="model"
      />

      <VendorRangeFilter
        :title="$t(`Joint Payee`)"
        v-model="model"
        fromKey="joint_vendor_code_from"
        toKey="joint_vendor_code_to"
        :placeholders="{
          from: $t('Payee From'),
          to: $t('Payee To'),
        }"
      />


      <BaseFilterRow :title="$t('Payment No. Range')">
        <BaseInput
          v-model="model.payment_number_from"
          :placeholder="$t('Payment No. From')"
          :name="$t('Payment No. From')"
          type="number"
          class="col-span-2"
          clearable
        />
        <BaseInput
          v-model="model.payment_number_to"
          :placeholder="$t('Payment No. To')"
          :name="$t('Payment No. To')"
          type="number"
          class="col-span-2"
          :rules="model.payment_number_from ? 'required' : ''"
          clearable
        />
      </BaseFilterRow>

      <BaseFilterRow :title="$t('Bank')">
        <BankSelect
          v-model="model.bank_id"
          label=""
          :edit-entity="false"
          :inline-errors="true"
          :useDefaultBank="false"
          clearable
          class="col-span-4"
        />
      </BaseFilterRow>

      <BaseFilterRow>
        <BaseCheckbox
          v-model="model.list_paid_invoices"
          :label="$t('List Paid Invoices')"
          class="mb-2 col-span-4"
          id="list_paid_invoices"
        />
      </BaseFilterRow>

    </template>

    <template #table="{ data, loading }">
      <VendorPaymentRegisterReportTable
        :data="data"
        :filters="model"
        :data-loading="loading"
        :report-number="reportNumber"
        ref="reportTable"
      />
    </template>
  </BaseReportForm>
</template>
<script>
import VendorPaymentRegisterReportTable from '@/modules/accounts-payable/components/reports/vendor-payment-register/VendorPaymentRegisterReportTable.vue'
import CostCenterSelect from "@/components/select/entities/CostCenterSelect.vue";

import {
  DateRangeFilter,
  VendorRangeFilter,
} from '@/components/range-filters'
import { VendorPaymentRegisterOptions } from '@/modules/accounts-payable/components/reports/util'
import { globalResources } from "@/components/form/util";
import { costCenterTypes } from '@/components/grid-table/utils/cost-center'

export default {
  components: {
    VendorPaymentRegisterReportTable,
    DateRangeFilter,
    VendorRangeFilter,
    CostCenterSelect,
  },
  setup() {
    return {
      globalResources,
      VendorPaymentRegisterOptions,
    }
  },
  data() {
    return {
      reportNumber: 3885,
      model: {
        select_by: VendorPaymentRegisterOptions.SelectBy.PaymentDates,
        sort_by: VendorPaymentRegisterOptions.SortBy.Payment,
        
        // If select_by = fiscal_periods
        fiscal_year: null,
        period_start: null,
        period_end: null,

        // If select_by = payment_dates
        payment_date_from: null,
        payment_date_to: null,

        payment_number_from: null,
        payment_number_to: null,

        vendor_code_from: null,
        vendor_code_to: null,

        joint_vendor_code_from: null,
        joint_vendor_code_to: null,

        list_paid_invoices: false,

        // If sort_by in [vendor_payment, vendor_invoice]
        cost_center: null,
        type_id: null, // Used to filter Vendors with JOB or S/B by type

        bank_id: null,
      },
      // Select options
      sortByOptions: [
        {
          label: this.$t('Payment'),
          value: VendorPaymentRegisterOptions.SortBy.Payment,
        },
        {
          label: this.$t('Fiscal Periods'),
          value: VendorPaymentRegisterOptions.SortBy.FiscalPeriods,
        },
        {
          label: this.$t('Vendor/Payment #'),
          value: VendorPaymentRegisterOptions.SortBy.VendorPayment,
        },
        {
          label: this.$t('Journal'),
          value: VendorPaymentRegisterOptions.SortBy.Journal,
        },
        {
          label: this.$t('Vendor/Invoice #'),
          value: VendorPaymentRegisterOptions.SortBy.VendorInvoice,
        },
      ],
      selectByOptions: [
        {
          label: this.$t('Payment Dates'),
          value: VendorPaymentRegisterOptions.SelectBy.PaymentDates,
        },
        {
          label: this.$t('Fiscal Periods'),
          value: VendorPaymentRegisterOptions.SelectBy.FiscalPeriods,
        },
        {
          label: this.$t('All Payments'),
          value: VendorPaymentRegisterOptions.SelectBy.AllPayments,
        },
        {
          label: this.$t('Direct Deposit Only'),
          value: VendorPaymentRegisterOptions.SelectBy.DirectDeposit,
        },
      ],
    }
  },
  computed: {
    costCenterHasType() {
      return [
        costCenterTypes.Job,
        costCenterTypes.WorkOrder
      ].includes(this.model.cost_center)
    },
    costCenterTypeOptions() {
      const options = {
        [costCenterTypes.Job]: this.$store.getters['globalLists/getResourceOptions'](globalResources.JobTypes),
        [costCenterTypes.WorkOrder]: this.$store.getters['globalLists/getResourceOptions'](globalResources.ServiceBillingCostTypes),
      }
      
      return options[this.model.cost_center] || []
    },
    periods() {
      return this.$store.state.company.periods || []
    },
  },
  methods: {
    onSelectByChanged() {
      if (this.model.select_by !== VendorPaymentRegisterOptions.SelectBy.FiscalPeriods) {
        this.model.fiscal_year = null
        this.model.period_start = null
        this.model.period_end = null
      }

      if (this.model.select_by !== VendorPaymentRegisterOptions.SelectBy.PaymentDates) {
        this.model.payment_date_from = null
        this.model.payment_date_to = null
      }
    },
    onSortByChanged() {
      this.model.cost_center = null
      this.model.type_id = null
    },
  }
}
</script>
