<template>
  <base-select
      v-bind="$attrs"
      v-on="$listeners"
      :placeholder="$attrs.placeholder || $t('Period to post to')"
      :options="filteredPeriods"
      :label="$t(label)"
      :value="defaultPeriod"
  />
</template>
<script>
  import parse from 'date-fns/parse'
  import parseISO from 'date-fns/parseISO'
  import endOfMonth from 'date-fns/endOfMonth'
  import orderBy from "lodash/orderBy";

  const currentYear = new Date().getFullYear()

  export default {
    props: {
      referenceDate: {
        type: String,
        default: '',
      },
      allPeriods: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
        default: 'Period',
      },
      value: {
        type: [String, Object, Number],
        default: '',
      },
      showDefaultPeriod: {
        type: Boolean,
        default: true,
      },
      fiscalYear: {
        type: [Number, String],
      },
      defaultToLastPeriod: {
        type: Boolean,
        default: false,
      },
      forPost: {
        type: Boolean,
        default: true,
      },
      showOffsetPeriods: {
        type: Boolean,
        default: false,
      }
    },
    model: {
      prop: 'value',
      event: 'change',
    },
    computed: {
      periods() {
        if (this.forPost) {
          return this.$store.getters['company/getAuthorizedToUsePeriods']
        }
        return orderBy(this.$store.state.company.periods, 'number')
      },
      selectedYear() {
        const fiscalYear = this.fiscalYear || this.$currentFiscalYear
        return this.$store.state.company.fiscalYears.find(year => year?.attributes?.fiscal_year === fiscalYear)
      },
      periodsByYear() {
        let periods = this.selectedYear?.attributes?.periods || this.periods
        periods = orderBy(periods, 'number')
        periods = periods.filter(p => p?.is_open) || []
        return periods.map(period => {
          return {
            ...period,
            label: `${period.number} (${period.name})`,
          }
        })
      },
      parsedReferenceDate() {
        const date = parseISO(this.referenceDate)
        let month = 1, day = 1, year = currentYear
        if (isNaN(date.getTime())) {
          return {
            month,
            day,
            year,
          }
        }
        return {
          month: date.getMonth(),
          day: date.getDate(),
          year: date.getFullYear(),
        }
      },
      firstPeriod() {
        return this.periodsByYear.find(p => p.value === 1)
      },
      lastPeriod() {
        return this.periodsByYear.find(p => p.value === 12)
      },
      preselectedPeriodOpened() {
        return this.filteredPeriods.find(p => +p.value === +this.value)
      },
      defaultPeriod() {
        const noValue = this.value === null || this.value === undefined || this.value === ''
        if (!noValue && this.preselectedPeriodOpened) {
          return this.value
        }
        if (!this.showDefaultPeriod) {
          return null
        }
        const currentMonth = new Date().getMonth()
        let defaultPeriod = this.periodsByYear.find(p => {
          const periodMonth = parse(`${p.originalLabel}`, 'MMMM', new Date()).getMonth()
          return periodMonth === currentMonth
        })

        if (this.defaultToLastPeriod && this.isPreviousFiscalYearOpen) {
          defaultPeriod = this.periodsByYear.find(p => p.number === 12)
        }

        if (noValue && defaultPeriod?.value) {
          this.$emit('change', defaultPeriod?.value)
        }
        return defaultPeriod ? defaultPeriod?.value : null
      },
      showFirstPeriod() {
        if (this.showOffsetPeriods) {
          return true
        }
        if (!this.firstPeriod) {
          return false
        }
        const date = parse(`${this.firstPeriod.originalLabel}`, 'MMMM', new Date())
        if (isNaN(date.getTime())) {
          return false
        }
        // Display only if it's the 1st of the first period
        return date.getMonth() === this.parsedReferenceDate.month && this.parsedReferenceDate.day === 1
      },
      showLastPeriod() {
        if (this.showOffsetPeriods) {
          return true
        }
        if (!this.lastPeriod) {
          return false
        }
        const date = parse(`${this.lastPeriod.originalLabel}`, 'MMMM', new Date())
        if (isNaN(date.getTime())) {
          return false
        }

        const month = date.getMonth()
        const lastDayOfMonth = endOfMonth(date).getDate()

        // Display only if it's the last day of the last period
        return month === this.parsedReferenceDate.month && this.parsedReferenceDate.day === lastDayOfMonth
      },
      filteredPeriods() {
        if (this.allPeriods) {
          return this.periodsByYear
        }
        return this.periodsByYear.filter(period => {
          const openBalancePeriod = 0
          const yearEndPeriod = 13

          if (period.value === yearEndPeriod && this.showLastPeriod) {
            return true
          }

          if (period.value === openBalancePeriod && this.showFirstPeriod) {
            return true
          }
          return !(period.value === openBalancePeriod || period.value === yearEndPeriod)
        })
      },
    },
  }
</script>
