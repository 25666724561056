<template>
  <div :key="get(currentResource, 'id', 'bank-layout')">
    <portal to="page-title">
      <div class="flex items-center justify-between md:justify-start">
        <span class="truncate">
          {{ currentResource.attributes.name }}
          ({{ currentResource.attributes.initials }})
        </span>
      </div>
    </portal>
    <layout-tabs :items="filteredItems"/>
    <router-view v-loading="currentResourceLoading" />
  </div>
</template>
<script>
import layoutPermissions from '@/mixins/layoutPermissions'

export default {
  mixins: [layoutPermissions],
  computed: {
    items() {
      const id = this.$route.params.id
      const tabs = [
        {
          name: this.$t('Details'),
          path: `/settings/banks/${id}/view`,
          authorizedTo: 'authorizedToShow',
        },
        {
          name: this.$t('Edit'),
          path: `/settings/banks/${id}/edit`,
          authorizedTo: 'authorizedToUpdate',
        },
        {
          name: this.$t('Direct Deposit'),
          path: `/settings/banks/${id}/direct-deposit`,
          authorizedTo: 'authorizedToUpdate',
        },
        {
          name: this.$t('Checks & Positive Pay'),
          path: `/settings/banks/${id}/positive-pay`,
          authorizedTo: 'authorizedToUpdate',
        },
        {
          name: this.$t('Blank Checks'),
          path: `/settings/banks/${id}/blank-checks`,
          authorizedTo: 'authorizedToUpdate',
          enabled: () => this.currentResource?.attributes?.is_for_micr,
        },
        {
          name: this.$t('One Off Checks'),
          path: `/settings/banks/${id}/one-off-checks`,
          authorizedTo: 'authorizedToUpdate',
          permission: 'one_off_check_show',
          enabled: () => this.currentResource?.attributes?.is_for_micr,
        },
        {
          name: this.$t('Documents'),
          path: `/settings/banks/${id}/documents`,
          authorizedTo: 'authorizedToUpdate',
        },
      ]

      return tabs
    },
    currentResource() {
      return this.$store.state.settings.currentBank || {}
    },
    currentResourceLoading() {
      return this.$store.state.settings.currentBankLoading
    },
  },
  methods: {
    async getCurrentResource(id) {
      await this.$store.dispatch('settings/getBank', id)
    },
  },
}
</script>
