<template>
  <div class="flex items-center">

    <div class="flex space-x-4 items-center">
      <JobLink
          :data="job"
          :show-preview="false"
          link-class="text-sm text-gray-900"
      />
      <base-checkbox
          v-model="selectedAll"
          :label="$t('Select All (Full Withheld Amount)')"
          :id="`${rowIndex}-select-all`"
          @change="updateGroupRetentionsMethod($event)"
      />
    </div>

    <div class="absolute right-4 text-sm text-gray-900 font-semibold">
      {{ $formatPrice(jobGroupTotal) }}
    </div>

  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    data()  {
      return {
        selectedAll: false as boolean,
      }
    },
    computed: {
      data() {
        return this.params.node?.allLeafChildren[0]?.data || {}
      },
      rowIndex() {
        return this.params.rowIndex
      },
      job() {
        return this.data?.job
      },
      retentions() {
        return this.params.node?.allLeafChildren.map(child => child.data)
      },
      jobGroupTotal() {
        return this.retentions.reduce((acc, invoice) => acc + invoice.release_retention, 0)
      },
      releaseFullMethod() {
        return this.params.releaseFullMethod
      },
    },
    methods: {
      updateGroupRetentionsMethod(selected) {
        const method = selected ? this.releaseFullMethod : null
        this.params?.updateGroupRetentionsMethod(this.retentions, method)

        this.updateChildRetentions(selected)
      },
      updateChildRetentions(selected) {
        const release_method = selected ? this.releaseFullMethod : null
        this.params.node.allLeafChildren.forEach(child => {
          child.setData({
            ...child.data,
            release_method,
            release_retention: selected ? child.data.retention_amount : 0,
          })
        })
      },
      setSelectedAllDefaultState() {
        this.selectedAll = this.params.node.allLeafChildren.every(child => child.data.release_method === this.releaseFullMethod)
      },
    },
    mounted() {
      this.setSelectedAllDefaultState()
    }
  })
</script>
