<template>
  <Wizard
    :current-step="currentStep"
    :steps="steps"
    :show-cancel="false"
    :loading="loading"
    wrapper-classes="p-4"
    @set-step="onSetStep"
    @on-back="onBack"
    @on-next="onNext"
  >
    <template v-if="currentStep === 1">
      <base-filter-row :title="$t(`Claimant's Title`)">
        <base-input
          v-model="model.claimant_title"
          :name="$t(`Claimant's Title`)"
          :placeholder="$t(`Claimant's Title`)"
          class="col-span-4"
        />
      </base-filter-row>

      <base-filter-row :title="$t('Document Date')">
        <div class="flex items-baseline col-span-8">

          <base-date-picker
            v-model="model.document_date"
            :name="$t('Document Date')"
            class="w-[250px]"
            rules="required"
          />

          <span class="text-sm text-gray-600 font-medium whitespace-nowrap md:mx-3">
            {{ $t('Furnished Through Date') }}
          </span>
          <base-date-picker
            v-model="model.through_date"
            :name="$t('Furnished Date')"
            class="w-[250px]"
            rules="required"
          />
        </div>

      </base-filter-row>

      <base-filter-row :title="$t(getSelectionTitle)">

        <base-select
          v-model="selectionType"
          :options="getSelectionTypeOptions"
          :name="$t('Selection')"
          class="col-span-3"
          rules="required"
          @change="onSelectionTypeChange"
        />

      </base-filter-row>

      <template v-if="selectionType === selectionTypes.Range">

        <component
          v-model="model"
          :is="subjectedResourceComponent"
          rules="required"
        />

        <job-range-filter v-model="model">
          <div class="col-span-3 flex items-baseline">
            <span class="text-label">{{ getJobTypeTitle }}</span>
            <base-select
              v-model="model.job_type_ids"
              :add-entity="false"
              :options="jobTypes"
              :name="getJobTypeTitle"
              rules="required"
              value-key="id"
              label-key="name"
              class="flex-1"
              collapse-tags
              multiple
            />
          </div>
        </job-range-filter>

      </template>

    </template>

    <WaiverAndReleaseGrid
      v-show="currentStep === 2"
      :model="model"
      :selections="selections"
      :module="module"
      ref="selectionsTable"
    />

  </Wizard>
</template>
<script>
import axios from 'axios'
import { onBack, onSetStep } from '@/components/lists/wizard-util'
import { CustomerRangeFilter, JobRangeFilter, VendorRangeFilter } from '@/components/range-filters'
import WaiverAndReleaseGrid from '@/modules/common/components/waiver-release/WaiverAndReleaseGrid'

const selectionTypes = {
  Individual: 'individual',
  Range: 'range',
}

export default {
  components: {
    JobRangeFilter,
    VendorRangeFilter,
    CustomerRangeFilter,
    WaiverAndReleaseGrid,
  },
  props: {
    module: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      selectionTypes,
      selectionType: selectionTypes.Individual,
      loading: false,
      selections: [],
      model: {
        module: this.$modules.AP,
        claimant_title: null,

        document_date: this.$now,
        through_date: this.$now,

        vendor_code_from: null,
        vendor_code_to: null,

        customer_code_from: null,
        customer_code_to: null,

        job_number_from: null,
        job_number_to: null,
        job_type_ids: [],
      },
      currentStep: 1,
      steps: [
        {
          name: this.$t('Print Options'),
          status: 'current',
        },
        {
          name: this.$t('Summary'),
          status: 'upcoming',
          icon: 'IconFile',
          actionName: 'Preview Documents',
        },
      ],
    }
  },
  computed: {
    subjectedResourceComponent() {
      return this.accountsPayableModule ? 'VendorRangeFilter' : 'CustomerRangeFilter'
    },
    getSelectionTypeOptions() {
      return [
        {
          label: this.$t('Select Individual'),
          value: selectionTypes.Individual,
        },
        {
          label: this.accountsPayableModule ? this.$t('Select from Vendor / Job Ranges') : this.$t('Select from Customer / Job Ranges'),
          value: selectionTypes.Range,
        },
      ]
    },
    getSelectionTitle() {
      return this.accountsPayableModule ? this.$t('Vendor Selection') : this.$t('Customer Selection')
    },
    getJobTypeTitle() {
      return this.accountsPayableModule ? this.$t('Cost Types') : this.$t('Income Types')
    },
    accountsPayableModule() {
      return this.module === this.$modules.AP
    },
    jobTypes() {
      const jobType = this.accountsPayableModule ? this.$globalResources.JobCostTypes : this.$globalResources.JobIncomeTypes

      return this.$store.getters['globalLists/getResourceOptions'](jobType)
    },
    allJobTypes() {
      return this.jobTypes.map(type => type.id)
    },
  },
  methods: {
    onSetStep,
    onBack,
    onNext() {
      if (this.currentStep === this.steps.length) {
        return this.generatePdf()
      }

      if (this.selectionType === selectionTypes.Range) {
        return this.getFilterForms()
      }

      this.currentStep++
      this.setStepStates()
    },
    setStepStates() {
      this.steps.forEach((step, index) => {
        if (index > (this.currentStep - 1)) {
          step.status = 'upcoming'
        } else {
          if (index < (this.currentStep - 1)) {
            step.status = 'complete'
          }
          if ((index + 1) === this.currentStep) {
            step.status = 'current'
          }
        }
      })
    },
    async getFilterForms() {
      try {
        this.loading = true

        if (this.module === this.$modules.AP) {
          delete this.model.customer_code_from
          delete this.model.customer_code_to
        } else {
          delete this.model.vendor_code_from
          delete this.model.vendor_code_to
        }
        const params = {
          ...this.model
        }
        if (!params.vendor_code_from && !params.vendor_code_to) {
          delete params.vendor_code_from
          delete params.vendor_code_to
        }
        if (!params.customer_code_from && !params.customer_code_to) {
          delete params.customer_code_from
          delete params.customer_code_to
        }

        if (!params.job_number_from && !params.job_number_to) {
          delete params.job_number_from
          delete params.job_number_to
        }

        const { data } = await axios.post('/restify/waiver-release-templates/actions?action=filter-forms', params)
        this.selections = data

        this.currentStep++
        this.setStepStates()
      } catch (err) {
        console.warn(err)
        if (err.handled) {
          return
        }

        this.$error(this.$t('Could not fetch data for current selection.'))
      } finally {
        this.loading = false
      }
    },
    async generatePdf() {
      try {
        this.loading = true
        await this.$refs.selectionsTable?.tryGeneratePdf()
      } catch (err) {
        console.warn(err)
        if (err.handled) {
          return
        }

        this.$error(this.$t('Could not generate pdf.'))
      } finally {
        this.loading = false
      }
    },
    onSelectionTypeChange(type) {
      type === selectionTypes.Range
        ? this.model.job_type_ids = this.allJobTypes
        : this.model.job_type_ids = []

      this.model.vendor_code_from = this.model.vendor_code_to = null
      this.model.job_number_from = this.model.job_number_to = null
      this.model.customer_code_from = this.model.customer_code_to = null
    },
    async setModelModule() {
      this.model.module = this.module
      await this.$nextTick()
    },
  },
  mounted() {
    this.setModelModule()
  },
}
</script>
