<template>
  <base-form
      :loading="loading"
      :can-create-another-entity="!model.id"
      :save-text="$t('Create service billing code')"
      :update-text="$t('Update service billing code')"
      layout="modal"
      grid-classes="grid grid-cols-6 gap-x-3"
      @submit="onSubmit"
  >
    <base-input
        v-model="model.code"
        :label="$t('Code')"
        :placeholder="$t('Code')"
        :name="$t('Code')"
        :disabled="!!model.id"
        class="col-span-6 md:col-span-2"
        id="code"
        rules="required|max:4|alpha"
    />
    <base-input
        v-model="model.description"
        :label="$t('Description')"
        :placeholder="$t('Description')"
        :name="$t('Description')"
        class="col-span-6 md:col-span-4"
        rules="max:150"
    />
    <base-input
        v-model="model.hours"
        :label="$t('Normal Work Time (Hours)')"
        :placeholder="$t('Hours')"
        :name="$t('Hours')"
        :min="0"
        :max="99"
        :step="1"
        class="col-span-6 md:col-span-3"
        type="number"
        rules="min:0|max:99"
    />
    <base-input
        v-model="model.minutes"
        :label="$t('Normal Work Time (Minutes)')"
        :placeholder="$t('Minutes')"
        :name="$t('Minutes')"
        :min="0"
        :max="99"
        :step="1"
        class="col-span-6 md:col-span-3"
        rules="min:0|max:99"
        type="number"
    />
    <base-input
        v-model="model.technicians"
        :label="$t('Number of Technicians')"
        :placeholder="$t('Number of Technicians')"
        :name="$t('Number of Technicians Required')"
        :min="0"
        :max="99"
        :step="1"
        class="col-span-6 md:col-span-3"
        rules="min:0|max:99"
        type="number"
    />
    <base-input
        v-model="model.technical_level"
        :label="$t('Minimum Experience Level for Lead Technician')"
        :placeholder="$t('Technical level')"
        :name="$t('Technical level')"
        :min="1"
        :max="5"
        :step="1"
        class="col-span-6 md:col-span-3"
        rules="min:1|max:5"
        type="number"
    />
  </base-form>
</template>
<script>
  import axios from 'axios'

  export default {
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        loading: false,
        model: {
          code: '',
          description: '',
          hours: 0,
          minutes: 0,
          technicians: 0,
          technical_level: 1,
        },
      }
    },
    methods: {
      async onSubmit() {
        try {
          this.loading = true
          if (this.model.id) {
            await axios.put(`/restify/service-billing-codes/${this.model.id}`, this.model)
            this.$success(this.$t('Service billing code updated'))
          } else {
            await axios.post('/restify/service-billing-codes', this.model)
            this.$success(this.$t('Service billing code added'))
          }
          if (!this.$createAnotherEntity) {
            return this.$emit('save', this.model)
          }
          this.createAnotherEntity()
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not update the service code'))
        } finally {
          this.loading = false
        }
      },
      createAnotherEntity() {
        this.model.id = undefined
        this.focusOnCodeInput()
      },
      focusOnCodeInput() {
        const codeInput = document.querySelector('#code')
        if (!codeInput) {
          return
        }
        codeInput.focus()
      },
    },
    watch: {
      data: {
        immediate: true,
        handler(value) {
          if (!value?.id) {
            return
          }
          this.model = {
            ...this.model,
            ...value.attributes,
          }
        },
      },
    },
  }
</script>
