<template>
  <JobRatesForm
      :key="renderKey"
      @create-another="renderKey++"
  />
</template>
<script>
  import JobRatesForm from '@/modules/payroll/components/rates/JobRatesForm'

  export default {
    components: {
      JobRatesForm,
    },
    data() {
      return {
        renderKey: 1,
      }
    },
  }
</script>
