<template>
  <div
    v-loading="loading"
    class="flex items-center space-x-2"
    @click="validate"
  >
    <div class="flex space-x-2 items-center">
      <BaseUploadButton
        accept="image/*,application/pdf"
        class="print:hidden ml-4"
        @input="onFileSelect"
      >
        {{ $t('Upload an invoice (beta)') }}
      </BaseUploadButton>
      <base-tooltip :tabindex="-1">
        <template #content>
          <div class="max-w-[320px]">
            {{ $t('This feature allows uploading a PDF or image which will be automatically scanned and processed in order to extract the invoice information and populate the form automatically. Note that this feature is experimental and might be inconsistent. Please validate all the data against the original file.') }}
          </div>
        </template>
        <HelpCircleIcon class="ml-2 w-4 h-4 text-gray-500 hover:text-gray-700 cursor-help"/>
      </base-tooltip>
    </div>
    <div class="flex flex-col space-y-6">
      <PendingFileInfo
        v-for="(file, index) in selectedFiles"
        :key="file?.name || index"
        :file="file"
        :file-name="file?.name"
        :can-remove="true"
        :show-preview-on-click="true"
        @close="removeFile(index)"
      />
    </div>
  </div>
</template>
<script>
import PendingFileInfo from "@/modules/common/components/documents/PendingFileInfo.vue";
import config from "@/modules/common/config";
import axios from "axios";
import format from "date-fns/format";
import { HelpCircleIcon } from "vue-feather-icons";

export default {
  components: {
    HelpCircleIcon,
    PendingFileInfo
  },
  props: {
    vendorId: {
      type: String,
    }
  },
  data() {
    return {
      loading: false,
      selectedFiles: [],
    }
  },
  methods: {
    validate(event) {
      if (!this.vendorId) {
        this.$error(this.$t('Please select a vendor first'))
        event.preventDefault()
        return
      }
    },
    onFileSelect(event) {
      this.selectedFiles = Array.from(event.target.files) || [];
      const file = this.selectedFiles[0]
      if (!file) {
        return
      }
      this.processFile(file)
    },
    removeFile(index) {
      this.selectedFiles.splice(index, 1);
    },
    async processFile(file) {
      if (!file) {
        return
      }
      try {
        this.loading = true
        this.$info(this.$t('Invoice is being processed. Please wait as this might take a while...'))
        const apiUrl = `${config.PDF_API_URL}/ai/parse-invoice`
        const form = new FormData
        form.append('file', file)
        const response = await axios.post(apiUrl, form)
        if (response.error) {
          this.$error(response.message)
          return
        }
        this.$success(this.$t('Invoice processed successfully. Filling form with data...'))
        const model = {

        }
        try {
          model.date = response.date ? format(response.date, 'yyyy-MM-dd') : model.date
        } catch (err) {
          console.log(err)
        }
        try {
          model.due_date = response.date ? format(response.due_date, 'yyyy-MM-dd') : model.date
        } catch (err) {
          console.log(err)
        }
        model.gross_amount = response.gross_amount ? response.gross_amount : model.gross_amount
        model.number = response.number ? response.number : model.number
        model.description = response.description ? response.description : model.description
        const entries = response.invoice_entries || []
        this.$emit('parsed', model, entries)
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(this.$t('Could not process the provided file.'))
      } finally {
        this.loading = false
      }
    }
  },
}
</script>
