<template>
  <div>
    <AgDataTable
      ref="unusedBlankChecksTable"
      :columns="tableColumns"
      :url="tableDataUrl"
      :add-text="$t('New Blank Check')"
      actions="add,refresh"
      domLayout="autoHeight"
      :selected-rows.sync="selectedRows"
      :isRowSelectable="isRowSelectable"
      @add="openCreateDialog"
    >
      <template #additional-actions-before>
        <BankBlankChecksPrintDialog
          ref="printDialog"
          :blankChecks="selectedChecksToPrint"
          :bank="bank"
          @close="printNewlyCreated = false"
        />
      </template>
    </AgDataTable>

    <BaseFormDialog
      v-if="showCreateDialog"
      :title="$t('Create Blank Checks')"
      :open.sync="showCreateDialog"
      :appendToBody="true"
      size="sm"
    >
      <BaseForm
        :saveText="$t('Create')"
        :showCancel="true"
        :loading="creating"
        layout="modal"
        @submit="onCreate"
        @cancel="showCreateDialog = false"
      >
        <template #extra-buttons>
          <BaseButton
            :loading="creating"
            type="button"
            class="ml-2"
            @click="onCreate(true)"
          >
            <PrinterIcon class="w-5 h-5 mr-2" />
            {{ $t('Create & Print') }}
          </BaseButton>
        </template>
        <BaseInput
          v-model="model.start_number"
          :name="$t('Starting Number')"
          :label="$t('Starting Number')"
          :placeholder="$t('Starting Number')"
          rules="required"
          type="number"
          id="starting-number"
          class="col-span-12"
        />

        <BaseAlert
          v-if="model.start_number != nextCheckNumber"
          type="warning"
          class="col-span-12 -mt-2 mb-2"
        >
          <span v-html="startingNumberWarning" />
        </BaseAlert>

        <BaseInput
          v-model="model.copies"
          :name="$t('Copies')"
          :label="$t('Copies')"
          :placeholder="$t('Copies')"
          rules="required|min_value:1|max_value:999999"
          class="col-span-12"
          type="number"
          id="copies"
        />
      </BaseForm>
    </BaseFormDialog>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'
import axios from 'axios'
import { Bank } from '@/modules/common/types/models'
import Data = API.Data;
import BankBlankChecksPrintDialog from "@/modules/settings/components/BankBlankChecksPrintDialog.vue";
import { PrinterIcon } from "vue-feather-icons";
import BaseButton from '@/components/common/BaseButton.vue';

export default defineComponent({
  components: {
    BankBlankChecksPrintDialog,
    PrinterIcon
  },
  props: {
    bank: {
      type: Object as PropType<Data<Bank>>,
      required: true,
    },
  },
  data() {
    return {
      unusedBlankChecks: [],
      creating: false,
      newlyCreatedBlankChecks: [],
      printNewlyCreated: false,
      tableColumns: [
        {
          headerCheckboxSelection: true,
          checkboxSelection: true,
          label: this.$t('Check Number'),
          prop: 'attributes.number'
        },
        {
          label: this.$t('Created By'),
          prop: 'attributes.created_by',
          component: 'UserLink'
        },
        {
          label: this.$t('Created At'),
          prop: 'attributes.updated_at',
          component: 'FormattedDate'
        },
        {
          label: this.$t('Is Used'),
          prop: 'attributes.is_used',
          component: 'Status'
        }
      ],
      model: {
        start_number: 1,
        copies: 1,
      },
      showCreateDialog: false,
      selectedRows: [],
    }
  },
  computed: {
    nextCheckNumber() {
      return Number(this.bank.attributes.last_ap_check_no) + 1
    },
    tableDataUrl() {
      return `/restify/blank-checks?bank_id=${this.bank.id}`
    },
    startingNumberWarning() {
      const numberOnModel = Number(this.model.start_number)
      const nextCheckNumber = this.nextCheckNumber

      if (numberOnModel < nextCheckNumber) {
        return this.$t('Starting number is smaller than the next check number.<br>This might cause collisions with existing invoice payments.')
      }

      if (numberOnModel > nextCheckNumber) {
        return this.$t('Starting number is greater than the next check number.<br>This might create gaps in invoice payments sequencing.')
      }

      return ''
    },
    selectedChecksToPrint() {
      if (this.printNewlyCreated) {
        return this.newlyCreatedBlankChecks
      }

      return this.selectedRows
    }
  },
  methods: {
    async onCreate(print = false) {
      try {
        this.creating = true
        const { data } = await axios.post(`/restify/banks/${this.bank.id}/actions?action=create-blank-checks`, this.model)
        this.newlyCreatedBlankChecks = data.checks

        this.onAfterCreate(print)
      }
      catch (err: any) {
        if (err.handled) {
          return
        }

        this.$error(this.$t('Failed to create blank checks'))
      }
      finally {
        this.creating = false
      }
    },
    async onAfterCreate(print = false) {
      this.showCreateDialog = false
      if (print) {
        this.printNewlyCreated = true
        // @ts-ignore
        this.$refs.printDialog?.openDialog()
      }
      // @ts-ignore
      this.$refs.unusedBlankChecksTable?.refresh()

      await this.$store.dispatch('settings/getBank', this.bank.id)
      this.resetModel()
    },
    openCreateDialog() {
      this.showCreateDialog = true
      this.newlyCreatedBlankChecks = []
      this.resetModel()
    },
    resetModel() {
      this.model.start_number = this.nextCheckNumber
      this.model.copies = 1
    },
    isRowSelectable(params: any) {
      return !params.data.attributes.is_used
    }
  },
})
</script>
