<template>
  <CostLineItemForm
      :key="renderKey"
      :type="costTypes.Cost"
      @create-another="renderKey++"
  />
</template>
<script>
  import { costTypes } from '@/enum/enums'
  import CostLineItemForm from '@/modules/job-costing/components/line-items/CostLineItemForm'

  export default {
    components: {
      CostLineItemForm,
    },
    data() {
      return {
        costTypes,
        renderKey: 1,
      }
    },
  }
</script>
