<template>
  <el-popover
      placement="top"
      width="200"
      trigger="click"
      v-model="visible"
  >
    <ValidationObserver v-slot="{valid}">
      <slot></slot>
      <div class="flex w-full justify-between">
        <base-button variant="white"
                     size="sm"
                     @click="visible = false">
          {{ $t('Cancel') }}
        </base-button>
        <base-button variant="primary"
                     size="sm"
                     :disabled="!valid"
                     @click="onChange">{{ $t('Confirm') }}
        </base-button>
      </div>
    </ValidationObserver>
    <base-button slot="reference" variant="white" class="mt-1">
      <SettingsIcon class="w-5 h-5 text-primary-500"/>
    </base-button>
  </el-popover>
</template>
<script>
import { SettingsIcon } from 'vue-feather-icons'
import { Popover } from 'element-ui'

export default {
  name: 'popover-config',
  inheritAttrs: false,
  components: {
    SettingsIcon,
    [Popover.name]: Popover,
  },
  data() {
    return {
      visible: false
    }
  },
  methods: {
    onChange() {
      this.$emit('confirm')
      this.visible = false
    },
  }
}
</script>
