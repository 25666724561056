<template>
  <div>
    <Wizard :steps="steps"
            :current-step="currentStep"
            :loading="loading"
            :skip-validation="currentStep === 1"
            :show-cancel="false"
            wrapper-classes="p-4"
            @set-step="onSetStep"
            @on-back="onBack"
            @on-next="onNext"
    >
      <fade-transition :duration="250"
                       mode="out-in">
        <base-form v-if="currentStep === 1"
                   :show-buttons="false"
                   ref="step1Form"
                   layout="vertical"
                   class="select-joint-checks-form"
        >
          <ClearJointChecksTableV2
            ref="jointChecksTable"
            :data="joint_checks"
          />

        </base-form>
        <base-form v-if="currentStep === 2"
                   :show-buttons="false"
                   ref="step2Form"
                   layout="vertical"
                   class="post-joint-checks-form"
        >
          <div class="col-span-6 md:col-span-3">
            <base-input :label="$t('Fiscal Year')">
              <template v-slot:container>
                <fiscal-year-select v-model="postModel.fiscal_year"
                                    class="w-full"
                                    id="fiscal_year"
                />
              </template>
            </base-input>
          </div>

          <div class="col-span-6 md:col-span-3">
            <period-select v-model="postModel.period"
                           :fiscal-year="postModel.fiscal_year"
                           rules="required"
                           id="period"
            />
          </div>

          <ClearJointChecksTableV2
            :data="joint_checks"
            readonly
          />

        </base-form>
      </fade-transition>
    </Wizard>
  </div>
</template>
<script>
  import axios from 'axios'
  import ClearJointChecksTableV2 from "@/modules/accounts-receivable/components/payments/ClearJointChecksTableV2.vue";
  import { validateAgDataTable } from "@/components/ag-grid/tableUtils";

  const STORAGE_KEY = 'ar-join-checks'
  export default {
    components: {
      ClearJointChecksTableV2,
    },
    data() {
      let joint_checks = []
      try {
        joint_checks = JSON.parse(localStorage.getItem(STORAGE_KEY))
      } catch (err) {
        console.warn(err)
      }
      return {
        loading: false,
        currentStep: 1,
        postModel: {
          fiscal_year: this.activeFiscalYear,
          period: null,
        },
        joint_checks,
      }
    },
    computed: {
      steps() {
        return [
          {
            name: this.$t('Select Invoices'),
            status: 'current',
          },
          {
            name: 'Review & Post',
            status: 'review',
            actionName: this.$t('Create & Post')
          },
        ]
      },
    },
    methods: {
      setStepStates() {
        this.steps.forEach((step, index) => {
          if (index > (this.currentStep - 1)) {
            step.status = 'upcoming'
          } else {
            if (index < (this.currentStep - 1)) {
              step.status = 'complete'
            }
            if ((index + 1) === this.currentStep) {
              step.status = 'current'
            }
          }
        })
      },
      onSetStep(step) {
        this.currentStep = step
        this.setStepStates()
      },
      onBack() {
        if (this.currentStep === 1) {
          return
        }
        this.currentStep--
        this.setStepStates()
      },
      async onNext() {
        if (this.currentStep === 1) {
          const valid = await this.validateFirstStep()
          if (!valid) {
            return
          }
        }
        if (this.currentStep === this.steps.length) {
          return this.submitForm()
        }
        this.currentStep++
        this.setStepStates()
      },
      checkForDuplicates() {
        let hasDuplicates = false
        this.joint_checks.forEach(((check, index) => {
          const otherChecks = this.joint_checks.slice(index + 1)
          if (otherChecks.find(c => c.customer_id === check.customer_id && c.payment_number === check.payment_number)) {
            hasDuplicates = true
          }
        }))
        return hasDuplicates
      },
      async validateFirstStep() {
        this.joint_checks = this.$refs?.jointChecksTable?.getData() || []
        if (this.checkForDuplicates()) {
          this.$error(this.$t('There are duplicate payment numbers for the same customer in the list'))
          return false
        }
        localStorage.setItem(STORAGE_KEY, JSON.stringify(this.joint_checks))
        const invalidData = await validateAgDataTable()
        return !invalidData
      },
      prepareData() {
        let joint_payments = this.joint_checks.map(check => {
          return {
            customer_id: check.customer_id,
            vendor_id: check.vendor_id,
            billing_id: check.billing_id,
            payment_number: check.payment_number,
            payment_amount: +check.payment_amount,
            payment_date: check.payment_date,
            clear_account: check.clear_account,
            clear_subaccount: check.clear_subaccount || null,
          }
        })
        return {
          fiscal_year: this.postModel.fiscal_year,
          period: this.postModel.period,
          joint_payments,
        }
      },
      async submitForm() {
        try {
          const data = this.prepareData()
          this.loading = true
          await axios.post('/restify/billing-payments/actions?action=clear-joint-payments', data)
          localStorage.setItem(STORAGE_KEY, undefined)
          await this.$router.push(`/accounts-receivable/payments/batches`)
        } catch (err) {
          if (err.handled) {
            return
          }
          console.log(err)
          this.$error(this.$t('Could not post the joint checks'))
        } finally {
          this.loading = false
        }
      }
    }
  }
</script>
<style lang="scss">
.select-joint-checks-form,
.post-joint-checks-form {
  .form__inner {
    @apply shadow-none;
  }
}
</style>
