<template>
  <div class="payroll-stubs">
    <portal to="page-title">
      <template v-if="$route.params.year">
        {{ $route.params.year }}
      </template>
      {{ $route.name }}
    </portal>
    <email-consent-alert v-if="!employeeId"
                         :is-employee-consent-required="isEmployeeConsentRequired"
                         resourceName="w2 forms"
    />
    <div class="mt-8">
      <BaseDataTable :columns="columns"
                     :url="url"
                     :url-params="urlParams"
                     :select-row-filter="selectRowFilter"
                     actions="search,refresh,export"
                     entity="employee-w2-forms"
                     @data-fetch="employees = $event"
                     @all-selected="onAllSelected"
                     ref="table">
        <template v-slot:select="{row}">
          <base-checkbox :disabled="!selectRowFilter(row)"
                         v-model="row.selected">
          </base-checkbox>
        </template>
        <template #employees.code="{row}">
          <router-link :to="`/payroll/employees/${row.relationships.employee.id}/view`">
            {{ get(row, 'attributes.employee_data.Code', '') }}
          </router-link>
        </template>
        <template #employees.name="{row}">
          {{ get(row, 'attributes.employee_data.Name', '') }}
        </template>
        <template #relationships.employee.attributes.email="{row}">
          <div class="truncate flex items-center">
            <template v-if="getEmail(row)">
              <warning-tip v-if="!didConsent(row)"
                           :message="$t('There was no consent email sent for this employee yet')"/>
              <a :href="`mailto:${getEmail(row)}`"
                 class="text-primary-500 hover:text-primary-900 hover:underline cursor-pointer truncate">
                {{ getEmail(row) }}
              </a>
            </template>
            <span v-else>

            </span>
          </div>
        </template>
        <template #attributes.sent_count="{row}">
          <base-badge :type="row.attributes.sent_count > 0 ? 'success' : 'info'">
            {{ $tc('sent times', row.attributes.sent_count, { count: row.attributes.sent_count }) }}
          </base-badge>
        </template>
        <template #extra-actions-before="{row}">
          <table-view-button v-if="$isAuthorized('authorizedToPreview', row)"
                             @click="previewCheck(row)"
          >
            {{ $t('Preview') }}
          </table-view-button>
          <table-download-button v-if="$isAuthorized('authorizedToDownload', row)"
                                 :loading="row.downloading"
                                 @click="downloadW2Forms(row)"
          />
          <template v-if="$isAuthorized('authorizedToSendEmail', row)">
            <table-icon-button v-if="!getEmployee(row).payroll_consent_sent_at && getEmail(row)"
                               :content="$t('Send Consent Email')"
                               @click="sendConsentEmail(row)"
            >
              <MailIcon class="w-4 h-4"/>
            </table-icon-button>
          </template>
        </template>
        <template #additional-actions>
          <base-button variant="primary"
                       :disabled="selectedRows.length === 0 && !allPagesSelected"
                       @click="sendEmails"
          >
            <MailIcon class="w-5 h-5 mr-2"></MailIcon>
            {{ $t('Send Form(s)') }}
          </base-button>
        </template>
      </BaseDataTable>
      <w2-form-preview-dialog v-if="showPreviewDialog"
                              :open.sync="showPreviewDialog"
                              :data="formToView"
      />
    </div>
    <DeleteDialog v-if="showConfirmDialog"
                  :title="$t(`Send Emails to ${allPagesSelected ?  `all (${totalEmployees})` : selectedRows.length} employee(s)`)"
                  :description="$t(`This action will send email(s) to ${allPagesSelected ?  `all (${totalEmployees})` : selectedRows.length} employee(s). Make sure to validate the w2 form data. Note that employees without an email or that haven't agreed to receive checks via email will be skipped.`)"
                  :delete-text="$t('Send')"
                  :open="true"
                  class="select-template-dialog"
                  type="primary"
                  @delete="onConfirmSendAction"
                  @cancel="onConfirmRejectAction"
    />
  </div>
</template>
<script>
  import axios from 'axios'
  import { MailIcon } from 'vue-feather-icons'
  import WarningTip from '@/modules/payroll/components/WarningTip'
  import DeleteDialog from '@/components/common/modal/DeleteDialog'
  import EmailConsentAlert from '@/modules/payroll/components/EmailConsentAlert'
  import { downloadFileForEntity } from '@/modules/common/util/downloadFileLocally'
  import W2FormPreviewDialog from '@/modules/payroll/pages/w2-forms/W2FormPreviewDialog'

  export default {
    name: 'W2Forms',
    components: {
      WarningTip,
      MailIcon,
      DeleteDialog,
      EmailConsentAlert,
      W2FormPreviewDialog,
    },
    props: {
      employeeId: {
        type: String,
      },
    },
    data() {
      return {
        showAddEmailDialog: false,
        showPreviewDialog: false,
        formToView: null,
        allPagesSelected: false,
        downloading: false,
        sendEmailLoading: false,
        modelToEdit: {
          email: '',
        },
        confirmResolve: null,
        confirmReject: null,
        showConfirmDialog: false,
        employees: [],
        columns: [
          {
            label: '',
            prop: 'select',
            minWidth: 40,
            maxWidth: 40,
            smallCell: true,
          },
          {
            label: this.$t('Code'),
            prop: 'employees.code',
            maxWidth: 115,
          },
          {
            label: this.$t('Name'),
            prop: 'employees.name',
            maxWidth: 200,
          },
          {
            label: this.$t('Email'),
            prop: 'relationships.employee.attributes.email',
            minWidth: 200,
            maxWidth: 270,
            component: 'EmailLink',
          },
          {
            label: this.$t('Sent'),
            prop: 'attributes.sent_count',
            maxWidth: 100,
            align: 'center',
          },
          {
            label: this.$t('Sent At'),
            prop: 'attributes.sent_at',
            maxWidth: 150,
            align: 'center',
            component: 'FormattedDate',
          },
          {
            label: this.$t('Downloaded At'),
            prop: 'attributes.downloaded_at',
            maxWidth: 150,
            align: 'center',
            component: 'FormattedDate',
          },
        ],
      }
    },
    computed: {
      url() {
        return '/restify/employee-w2-forms'
      },
      urlParams() {
        const data = {
          related: 'employee,company',
        }
        if (this.employeeId) {
          data.employee_id = this.employeeId
          return data
        }
        const { year } = this.$route.params
        if (year && year !== 'undefined' && year !== 'null') {
          data.year = year
        }
        return data
      },
      selectedRows() {
        return this.employees.filter(e => e.selected)
      },
      totalEmployees() {
        const table = this.$refs.table
        if (!table) {
          return 0
        }
        return table.pagination.total
      },
      isEmployeeConsentRequired() {
        return this.$store.getters['company/isEmployeeConsentRequired']
      },
    },
    methods: {
      previewCheck(row) {
        this.formToView = row
        this.showPreviewDialog = true
      },
      async sendConsentEmail(row) {
        const employee = this.getEmployee(row)
        const confirmed = await this.$confirm({
          title: this.$t(`Send Consent email to ${employee.name}`),
          description: this.$t(`This action will send a consent emails to ${employee.name}.`),
          buttonText: this.$t('Send'),
        })

        if (!confirmed) {
          return
        }
        try {
          this.$set(row, 'loading', true)
          const data = {
            repositories: [employee.id],
          }
          await axios.post(`/restify/employees/action?action=send-consent-mail`, data)
          this.$success(this.$t(`Consent email was queued to be sent. ${employee.name} will receive the email in a couple of minutes.`))
          this.$refs.table.refresh()
        } catch (err) {
          this.$error('An error occurred while trying to send the consent emails')
        } finally {
          this.$set(row, 'loading', false)
        }
      },
      onConfirmSendAction() {
        this.showConfirmDialog = false
        if (this.confirmResolve) {
          this.confirmResolve(true)
        }
      },
      onConfirmRejectAction() {
        this.showConfirmDialog = false
        if (this.confirmResolve) {
          this.confirmResolve(false)
        }
      },
      confirmSendAction() {
        this.showConfirmDialog = true
        return new Promise(resolve => {
          this.confirmResolve = resolve
        })
      },
      selectRowFilter(row) {
        return this.hasEmail(row) && this.didConsent(row)
      },
      async sendEmails() {
        const confirmed = await this.confirmSendAction()

        if (!confirmed) {
          return
        }
        try {
          this.sendEmailLoading = true
          let payload = {
            repositories: [],
          }
          if (this.allPagesSelected) {
            payload.repositories = 'all'
          } else {
            payload.repositories = this.selectedRows.map(r => r.id)
          }
          const params = {}
          if (this.employeeId) {
            params.employee_id = this.employeeId
          }
          await axios.post('/restify/employee-w2-forms/action?action=send-employee-w2-form', payload, { params })
          this.allPagesSelected = false
          this.$refs.table.selectRows(false)
          this.$success(this.$t('W2 form emails are queued to be sent. The selected employees will receive their w2 form in a couple of minutes.'))
          this.$refs.table.refresh()
        } catch (err) {
          this.$error('An error occurred while trying to send the w2 form via email')
        } finally {
          this.sendEmailLoading = false
        }
      },
      hasEmail(row) {
        const email = this.getEmail(row)
        return email !== '' && email !== null
      },
      didConsent(row) {
        if (!this.isEmployeeConsentRequired) {
          return true
        }
        return this.get(row, 'relationships.employee.attributes.payroll_consent_confirmed_at') !== null
      },
      getEmail(row) {
        return this.get(row, 'relationships.employee.attributes.email', '')
      },
      getEmployee(row) {
        return this.get(row, 'relationships.employee.attributes', {})
      },
      onAllSelected(value) {
        this.allPagesSelected = value
      },
      async downloadW2Forms(row) {
        await downloadFileForEntity(row, 'employee-w2-forms')
      },
    },
  }
</script>
