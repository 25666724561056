<template>
  <div>
    <BaseButton
      variant="primary-link"
      class="print:hidden send-email-button"
      v-bind="$attrs"
      @click="onSendClick"
    >
    <span class="flex items-center">
      <MailIcon class="w-4 h-4 mr-2"/>
      <span>
        {{ $t('Send Emails') }}
      </span>
    </span>
    </BaseButton>

    <BaseFormDialog
      v-if="showSendEmailDialog"
      :title="$t('Send Invoice via Email')"
      :open.sync="showSendEmailDialog"
      size="lg"
      @close="showSendEmailDialog = false"
    >
      <base-form
        v-bind="$attrs"
        :save-text="$t('Send')"
        :loading="loading"
        layout="modal"
        @cancel="$emit('cancel')"
        @submit="sendEmail"
      >

        <div v-if="false" class="col-span-6">
          <BaseCheckbox
            v-model="model.include_pdf"
            :label="$t('Include a PDF version of the invoice')"
            id="include_pdf"
          />
        </div>

        <div class="col-span-6">
          <BaseCheckbox
            id="include_signature"
            v-model="model.include_signature"
          >
            <template #label>
              {{ $t('Print Signature') }}
              <template v-if="model.include_signature">
                {{ $t(' (if the bank has a signature, the signature will be included on each check)') }}
              </template>
            </template>
          </BaseCheckbox>
        </div>
        <div class="col-span-6 mb-4">
          <base-checkbox
            id="cut_section"
            v-model="model.flags.cut"
            :label="$t('Show cut section with scissors?')"
          />
        </div>

        <BaseSelect
          v-model="selectedTemplate"
          @change="onTemplateChange"
          :label="$t('Template')"
          :options="availableTemplates"
          rules="required"
          value-key="id"
          label-key="name"
          class="col-span-6 md:col-span-3"
        />

        <div class="col-span-6">
          <h4>
            {{ $t('Select Payments to Send') }}
          </h4>
          <div class="text-gray-600 text-sm mb-4">
            ({{ $t('When all selected, all payments with valid recipient email addresses will be sent') }})
          </div>
          <AgDataTable
            :pagination="false"
            :data="data"
            :columns="columns"
            :selected-rows.sync="selectedRows"
            domLayout="autoHeight"
          >
          </AgDataTable>
        </div>
      </base-form>
    </BaseFormDialog>
  </div>
</template>
<script lang="ts" setup>
import {computed, ref, set, toRef} from 'vue'
import {MailIcon} from 'vue-feather-icons'
import axios from 'axios'
import i18n from "@/i18n";
import {error, success} from '@/components/common/NotificationPlugin'
import {ContactModel} from "@/modules/common/types/common";
import {usePrintTemplates} from "@/components/form/composables/usePrintTemplates";
import {cellClasses} from "@/components/ag-grid/columnUtils";
import get from "lodash/get";
import {ContactTypes} from "@/enum/enumTypes";
import {Contact} from "@/modules/common/types/models";
import {ICellEditorParams, ValueGetterParams, ValueSetterParams} from "@ag-grid-community/core";
import {cellEditors} from "@/components/ag-grid/cellEditors/cellEditors";
import {validateEmail} from "@/modules/common/util/validators";
import {RestifyResources} from "@/components/form/util";
import Data = API.Data;

const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  entity: {
    type: String,
  },
  contactEntity: {
    type: String,
  },
  actionUrl: {
    type: String,
    required: true,
  },
  actionName: {
    type: String,
    required: true,
  },
})

const {availableTemplates, selectedTemplate, onTemplateChange} = usePrintTemplates(toRef(props, 'entity'))

const showSendEmailDialog = ref(false)
const loading = ref(false)

interface Recipient {
  id: string,
  contacts: ContactModel[],
}

const model = ref({
  repositories: [] as string[],
  recipients: [] as Recipient[],
  include_pdf: false, // Will be added later on
  include_signature: true,
  template_id: null,
  flags: {
    cut: true,
  }
})

const selectedRows = ref([])

async function onSendClick() {
  showSendEmailDialog.value = true
}

const columns = computed(() => {
  return [
    {
      headerName: i18n.t('Number'),
      field: 'attributes.number',
      minWidth: 100,
      maxWidth: 150,
      headerCheckboxSelection: true,
      checkboxSelection: (params: any) => {
        return hasContacts(params.data)
      },
    },
    {
      headerName: i18n.t('Date'),
      field: 'attributes.date',
      component: 'FormattedDate',
      minWidth: 140,
      maxWidth: 160,
    },
    {
      headerName: i18n.t('Amount'),
      field: 'attributes.net_amount',
      component: 'FormattedPrice',
      minWidth: 100,
      maxWidth: 150,
    },
    {
      headerName: i18n.t('Recipient'),
      field: `relationships.${props.contactEntity}.id`,
      cellRendererParams: {
        showDescription: false,
      },
      component: 'VendorLink',
      minWidth: 150,
      maxWidth: 250,
    },
    {
      headerName: i18n.t('Recipient Email'),
      field: 'contacts',
      headerClass: cellClasses.HeaderEditable,
      cellEditorSelector: (params: any) => {
        const contacts = getContacts(params.data)
        if (contacts.length === 0) {
          return {
            component: 'agTextCellEditor',
          }
        }
        return {
          component: cellEditors.BaseSelect,
        }
      },
      cellEditorParams: (params: ICellEditorParams) => {
        return {
          options: getContacts(params.data),
          valueKey: 'email',
          labelKey: 'email',
        }
      },
      editable: true,
      valueGetter: (params: ValueGetterParams) => {
        const contacts = getContacts(params.data)
        if (contacts.length === 0) {
          return i18n.t('No payment contact provided')
        }
        return contacts[0]?.email
      },
      valueSetter: (params: ValueSetterParams) => {
        const value = params.newValue
        const isValidEmail = validateEmail(value)
        if (!isValidEmail) {
          error(i18n.t('Please provide a valid email'))
          return false
        }
        createPaymentContact(params.data, value).then((data) => {
          const contacts = getContacts(params.data)
          contacts.push(data)
          set(params.data, `relationships.${props.contactEntity}.relationships.contacts`, contacts)
          params.node?.setData(params.data)
        })
        return true
      }
    },
  ]
})

async function createPaymentContact(row: any, email: string) {
  try {
    const url = getCreateContactUrl(row)
    const contact = {
      type: ContactTypes.Payment,
      email: email,
      name: email,
    }
    const {data} = await axios.post(url, contact)
    return data
  } catch (err) {
    error(i18n.t('Could not create contact'))
    return null
  }
}

function getCreateContactUrl(row: any) {
  const entity = get(row, `relationships.${props.contactEntity}`)
  if (!entity) {
    return ''
  }
  const resourceMap: Record<string, string> = {
    vendor: RestifyResources.Vendors,
    customer: RestifyResources.Customers,
    employee: RestifyResources.Employees,
  }
  const resourcePath = resourceMap[props.contactEntity as string] || props.contactEntity
  return `/restify/${resourcePath}/${entity.id}/contacts`
}

function getContacts(row: any) {
  const contacts = get(row, `relationships.${props.contactEntity}.relationships.contacts`, [])
  return contacts
    .filter((c: Data<Contact>) => c.attributes.type === ContactTypes.Payment)
    .map((c: Data<Contact>) => c.attributes)
}

function hasContacts(row: any) {
  return getContacts(row)?.length > 0
}

const emit = defineEmits(['save', 'cancel'])

function getRecipients() {
  return selectedRows.value.map((row: any) => {
    const contacts = getContacts(row).map((contact: Contact) => {
      return {
        id: contact.id,
        email: contact.email,
        name: contact.name,
      }
    })
    return {
      id: get(row, `relationships.${props.contactEntity}.id`),
      contacts,
    }
  })
}

function getRepositories() {
  return selectedRows.value.map((row: any) => row.id)
}

async function sendEmail() {
  try {
    loading.value = true
    const data = {
      recipients: getRecipients(),
      repositories: getRepositories(),
      include_pdf: model.value.include_pdf,
      include_signature: model.value.include_signature,
      template_id: selectedTemplate.value,
      flags: model.value.flags,
    }
    await axios.post(props.actionUrl, data, {
      params: {
        action: props.actionName,
      }
    })
    // success(i18n.t('Emails are queued to be sent. The selected recipients will receive their email in a couple of minutes.'))
    showSendEmailDialog.value = false
    emit('save')
  } catch (err: any) {
    if (err.handled) {
      return
    }
    error(i18n.t('Could not send emails'))
  } finally {
    loading.value = false
  }
}
</script>
