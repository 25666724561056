<template>
  <div>
    <layout-tabs :items="filteredItems"/>
    <router-view/>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        items: [
          {
            name: this.$t('All Line Items'),
            path: '/job-costing/progress-updating/all-line-items',
            permission: 'jobs_show',
          },
          //{
          //  name: this.$t('Selected Line Items'),
          //  path: '/job-costing/progress-updating/selected-line-items',
          //  permission: 'jobs_show',
          //}
        ],
      }
    },
    computed: {
      filteredItems() {
        return this.items.filter(item => this.$can(item.permission))
      },
    },
  }
</script>
