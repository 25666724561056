<template>
    <div>
        <BaseDataTable :columns="columns"
                       :url="url"
                       :add-text="$t('New crew labor rate')"
                       actions="search,refresh"
                       default-sort="code"
                       hide-actions="view"
                       permission="crew_labor_rates"
                       ref="table"
                       @add="onRowAdd"
                       @edit="onRowEdit"
                       :edit-entity-url-query="editEntityUrlQuery"
        >
        </BaseDataTable>
        <BaseFormDialog v-if="showAddDialog"
                        :title="$t('Add Crew Labor Rates')"
                        :open.sync="showAddDialog">
            <CrewLaborRatesForm @cancel="showAddDialog = false"
                     @save="onAdd"
            />
        </BaseFormDialog>
        <BaseFormDialog v-if="showEditDialog"
                        :title="$t('Edit Crew Labor Rates')"
                        :open.sync="showEditDialog">
            <CrewLaborRatesForm :data="rowToEdit"
                     @cancel="showEditDialog = false"
                     @save="onEdit"
            />
        </BaseFormDialog>
    </div>
</template>
<script>
  import CrewLaborRatesForm from '@/modules/estimates/components/CrewLaborRatesForm'

  export default {
    components: {
      CrewLaborRatesForm,
    },
    data() {
      return {
        editEntityUrlQuery: '?id={ID}',
        url: '/restify/crew-labor-rates',
        rowToEdit: null,
        showAddDialog: false,
        showEditDialog: false,
        columns: [
          {
            label: this.$t('Code'),
            prop: 'attributes.code',
            component: 'EntityLink',
            redirectTo: '/estimates/crew-labor-rates?id={ID}',
          },
          {
            label: this.$t('Description'),
            prop: 'attributes.descriptions[0]',
            minWidth: 150,
            maxWidth: 400,
          },
          {
            label: this.$t('Additional Description'),
            prop: 'attributes.descriptions[1]',
            minWidth: 150,
            maxWidth: 400,
          },
        ],
      }
    },
    methods: {
      onRowEdit(row) {
        this.showEditDialog = true
        this.rowToEdit = row.attributes
      },
      onRowAdd() {
        this.showAddDialog = true
      },
      onAdd() {
        this.showAddDialog = false
        this.$refs.table.refresh()
      },
      onEdit() {
        this.showEditDialog = false
        this.$refs.table.refresh()
      },
    },
  }
</script>
