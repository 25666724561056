import axios from "axios";

const types = {
  ADD_NOTE: 'ADD_NOTE',
  SET_CURRENT_NOTES: 'SET_CURRENT_NOTES',
  SET_CURRENT_NOTES_COUNT: 'SET_CURRENT_NOTES_COUNT',
  SET_PREV_NOTES_COUNT: 'SET_PREV_NOTES_COUNT',
  TRIGGER_NOTES_PANEL: 'TRIGGER_NOTES_PANEL',
  RESET_NOTES_PANEL: 'RESET_NOTES_PANEL',
};
const state = {
  currentNotes: [],
  currentNotesCount: 0,
  prevNotesCount: 0,
  notesPanelKey: 0,
  notesPanelData: {},
};

const mutations = {
  [types.RESET_NOTES_PANEL]: (state) => {
    state.notesPanelData = {}
  },
  [types.TRIGGER_NOTES_PANEL]: (state, data) => {
    state.notesPanelData = data
    state.notesPanelKey++
  },
  [types.SET_CURRENT_NOTES]: (state, value) => {
    state.currentNotes = value
  },
  [types.SET_CURRENT_NOTES_COUNT]: (state, value) => {
    state.currentNotesCount = value
  },
  [types.SET_PREV_NOTES_COUNT]: (state, value) => {
    state.prevNotesCount = value
  },
  [types.ADD_NOTE]: (state, value) => {
    state.currentNotes.push(value)
  },
};

const actions = {
  async getNotes({ state, commit }, { params, resourceName, resourceId }) {
    const { data, meta } = await axios.get(`/restify/${resourceName}/${resourceId}/notes`, {
      params,
    })
    return {
      data,
      meta,
    }
  },
  async addSystemGeneratedNote({ state, commit, rootState }, {
    isEdit,
    resourceName,
    resourceId,
    customTitle,
  }) {
    if (!resourceId || !resourceName) {
      return
    }
    let title = `${isEdit ? 'made an update.' : 'created the record.'}`
    if (customTitle) {
      title = customTitle
    }
    const url = `/restify/${resourceName}/${resourceId}/notes`
    const { data } = await axios.post(url, {
      title: title,
      content: title,
      meta: {
        is_system_generated: true,
      }
    })
    commit(types.ADD_NOTE, data)
    commit(types.SET_CURRENT_NOTES_COUNT, state.currentNotesCount + 1)
    commit(types.SET_PREV_NOTES_COUNT, state.currentNotesCount + 1)
  }
};
const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
