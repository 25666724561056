<template>
  <JournalForm
      :key="renderKey"
      @create-another="renderKey++"
  />
</template>
<script>
  import JournalForm from '@/modules/ledger/components/journals/JournalForm.vue'

  export default {
    components: {
      JournalForm,
    },
    data() {
      return {
        renderKey: 1,
      }
    },
  }
</script>
