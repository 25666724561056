<template>
  <LineItemLink
      v-bind="params"
      :data="get(row, column.prop)"
      :id="id"
      :is-link="!editableCell"
  />
</template>
<script>
  import LineItemLink from '@/components/links/LineItemLink.vue';

  export default {
    components: {
      LineItemLink,
    },
    props: {
      id: [String, Number],
      column: {
        type: Object,
        default: () => ({}),
      },
      row: {
        type: Object,
        default: () => ({}),
      },
      params: {
        type: Object,
        default: () => ({}),
      },
      editableCell: Boolean,
    },
  }
</script>
