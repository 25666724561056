export const groupByType = {
  Employee: 'employee',
  Customer: 'customer',
  Account: 'account',
  Vendor: 'vendor',
  Source: 'source',
  Journal: 'journal',
  Job: 'job',
  ReportJob: 'reportJob',
  Reference: 'reference_no',
  WorkOrder: 'workOrder',
  State: 'state',
  WorkSite: 'workSite',
}
