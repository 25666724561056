<template>
  <div>
    <base-alert v-if="!serviceBillingCodes.length">
      <div class="flex items-center justify-between">
        <span>{{ $t('No Service Codes.') }}</span>
        <a href="/service-billing/settings/codes">{{ $t('+ Add') }}</a>
      </div>
    </base-alert>
    <ag-data-table
        v-else
        actions="add"
        v-bind="tableProps"
        :data="entries"
        :add-text="$t('New entry')"
        ref="gridTable"
    >
    </ag-data-table>
  </div>
</template>
<script>
  import { globalResources } from '@/components/form/util'
  import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";
  import { requiredValueSetter } from "@/components/ag-grid/columnUtils";
  import { editableTableProps } from "@/components/ag-grid/tableUtils";
  import { getDeleteColumn } from "@/components/ag-grid/columns/deleteColumns";

  export default {
    props: {
      codes: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        entries: [],
      }
    },
    computed: {
      tableProps() {
        return {
          ...editableTableProps,
          columns: [
            {
              headerName: this.$t('Code'),
              field: 'id',
              minWidth: 50,
              maxWidth: 80,
              cellEditor: cellEditors.BaseSelect,
              cellEditorParams: {
                options: this.serviceBillingCodes,
                labelKey: 'code',
                valueKey: 'id',
              },
              editable: true,
              valueSetter: params => {
                const code = this.getServiceBillingOption(params.newValue)
                params.data.id = code.id
                params.data.hours = code.hours
                params.data.minutes = code.minutes
                params.data.description = code.description

                params.node.setData(params.data)
                return requiredValueSetter(params)
              },
              valueFormatter: params => {
                const code = this.getServiceBillingOption(params.value)
                return code ? code.code : ''
              },
              suppressKeyboardEvent: params => {
                let isTabOrEnterKey = ['Tab', 'Enter'].includes(params.event.key)
                if (isTabOrEnterKey) {
                  params.api.stopEditing()
                  params.event.stopPropagation()
                }
              },
            },
            {
              headerName: this.$t('Description'),
              field: 'description',
              cellClass: 'readonly-ag-cell',
              minWidth: 100,
              suppressNavigable: true,
            },
            {
              headerName: this.$t('Hours'),
              field: 'hours',
              cellClass: 'readonly-ag-cell',
              minWidth: 50,
              maxWidth: 80,
              suppressNavigable: true,
            },
            {
              headerName: this.$t('Minutes'),
              field: 'minutes',
              cellClass: 'readonly-ag-cell',
              minWidth: 50,
              maxWidth: 80,
              suppressNavigable: true,
            },
            {
              ...getDeleteColumn({
                title: this.$t(`Delete service code`),
                description: this.$t(`Are you sure you want to delete this service code from this work order ?`),
              })
            }
          ],
          getEmptyRow: () => {
            return {
              id: '',
              code: '',
              description: '',
              hours: '',
              minutes: '',
              _localId: crypto.randomUUID(),
            }
          },
        }
      },
      serviceBillingCodes() {
        return this.$store.getters['globalLists/getResourceOptions'](globalResources.ServiceBillingCodes)
      },
    },
    methods: {
      getServiceBillingOption(id) {
        return this.serviceBillingCodes.find(code => code.id === id)
      },
      mapEntries(codes) {
        this.entries = codes.map(code => {
          return {
            id: code.id,
            ...code.attributes
          }
        })
      },
      getTableData() {
        return this.$refs.gridTable?.getTableData() || []
      }
    },
    watch: {
      codes: {
        immediate: true,
        handler(options) {
          this.mapEntries(options)
        },
      }
    },
  }
</script>
