<template>
  <div class="inline-block text-left"
       v-click-outside="onMenuClickOutside">
    <base-tooltip :content="tooltipContent">
      <span class="rounded-md flex items-center" @click="toggleDropdown">
        <slot name="trigger"></slot>
      </span>
    </base-tooltip>
    <transition enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
    >
      <div v-show="showDropdown"
           class="origin-top-left absolute right-0 mt-1 rounded-md shadow-lg z-10"
           :class="menuClasses"
      >
        <div class="rounded-md bg-white shadow-xs overflow-hidden relative">
          <slot></slot>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
  export default {
    name: 'base-dropdown',
    props: {
      tooltipContent: {
        type: String,
      },
      menuClasses: [String, Object],
    },
    data() {
      return {
        showDropdown: false,
      }
    },
    methods: {
      onMenuClickOutside() {
        this.showDropdown = false
        this.$emit('change', this.showDropdown)
      },
      toggleDropdown() {
        this.showDropdown = !this.showDropdown
        this.$emit('change', this.showDropdown)
      }
    }
  }
</script>
<style>
  .columns-dropdown {
    right: 50px;
  }
</style>
