<template>
  <DetailLayout
      :loading="workSiteLoading"
      :current-resource="currentResource"
      :get-current-resource="getCurrentResource"
      :select-component="WorkSiteSelect"
      :items="items"
  >
    <template #page-title>
        <span>
          {{ $t('Worksite ') }}{{ currentResource.attributes.number }}
          ({{ get(currentResource, 'relationships.customer.attributes.name', '') }})
        </span>
    </template>
    <template #entity-name>
        <span>
          {{ $t('Worksite ') }}{{ currentResource.attributes.number }}
          ({{ get(currentResource, 'relationships.customer.attributes.name', '') }})
        </span>
    </template>
    <portal to="page-title-status">
      <base-button
          variant="gray-link"
          size="xs"
          @click="tryAddWorkOrder"
      >
        <IconOfficeBuildings class="w-4 h-4 mr-2"/>
        {{ $t('Add Work Order') }}
      </base-button>
    </portal>

  </DetailLayout>
</template>
<script>
  import layoutPermissions from '@/mixins/layoutPermissions'
  import detailScreen from "@/mixins/detailScreen";
  import DetailLayout from "@/modules/common/components/DetailLayout.vue";
  import WorkSiteSelect from "@/components/select/entities/WorkSiteSelect.vue";

  export default {
    components: { DetailLayout },
    mixins: [layoutPermissions, detailScreen],
    data() {
      return {
        WorkSiteSelect
      }
    },
    computed: {
      items() {
        return [
          {
            name: this.$t('Details'),
            path: `/service-billing/worksites/${this.$route.params.id}/view`,
            authorizedTo: 'authorizedToShow',
          },
          {
            name: this.$t('Edit'),
            path: `/service-billing/worksites/${this.$route.params.id}/edit`,
            authorizedTo: 'authorizedToUpdate',
          },
          {
            name: this.$t('Documents'),
            path: `/service-billing/worksites/${this.$route.params.id}/documents`,
            authorizedTo: 'authorizedToShow',
            permission: 'worksite_documents_show'
          },
          {
            name: this.$t('Work Orders'),
            path: `/service-billing/worksites/${this.$route.params.id}/work-orders`,
            authorizedTo: 'authorizedToShow',
          },
        ]
      },
      currentResource() {
        return this.$store.state.serviceBilling.currentWorkSite || {}
      },
      workSiteLoading() {
        return this.$store.state.serviceBilling.workSiteLoading
      }
    },
    methods: {
      async getCurrentResource(id) {
        await this.$store.dispatch('serviceBilling/getWorkSite', id)
      },
      tryAddWorkOrder() {
        this.$router.push(`/service-billing/work-orders/add?worksite=${this.currentResource.id}`)
      },
    },
  }
</script>
