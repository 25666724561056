<template>
  <div class="bg-white border sm:rounded-lg">
    <div class="flex flex-col h-full">
      <div v-if="title || $slots.title"
           @click="toggleExpand"
           class="flex justify-between px-6 py-3"
           :class="{'cursor-pointer': canExpand}">
        <div class="flex items-center text-gray-900 select-none hover:text-primary-600">
          <h5 class="font-semibold mr-3">
            <slot name="title">
              {{ title }}
            </slot>
          </h5>
          <template v-if="canExpand">
            <ChevronDownIcon v-if="!localExpanded" class="w-4 h-4"/>
            <ChevronUpIcon v-else class="w-4 h-4"/>
          </template>
        </div>
        <slot name="icon"/>
      </div>
      <collapse-transition :duration="100">
        <dl v-if="localExpanded"
            class="sm:rounded-b-md overflow-hidden">
          <div class="grid gap-x-4 grid-cols-1"
               :class="{
                  'md:grid-cols-2': cols === 2,
                  'md:grid-cols-3': cols === 3,
                  'md:grid-cols-4': cols === 4,
                }">
            <slot/>
          </div>
        </dl>
      </collapse-transition>
    </div>
  </div>
</template>
<script>
  import { ChevronDownIcon, ChevronUpIcon } from 'vue-feather-icons'

  export default {
    components: {
      ChevronDownIcon,
      ChevronUpIcon,
    },
    props: {
      title: [String, Boolean],
      expanded: {
        type: Boolean,
        default: false,
      },
      canExpand: {
        type: Boolean,
        default: true,
      },
      entity: {
        type: Object,
        default: () => ({}),
      },
      entityName: {
        type: String,
        default: '',
      },
      cols: {
        type: Number,
        default: 1,
      },
      loading: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        localExpanded: this.expanded,
      }
    },
    provide() {
      return {
        entity: this.entity,
        entityName: this.entityName,
        isLoading: () => this.loading,
      }
    },
    methods: {
      toggleExpand() {
        if (!this.canExpand) {
          return
        }
        this.localExpanded = !this.localExpanded
      },
    },
  }
</script>
<style>
</style>
