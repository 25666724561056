<template>
  <div class="truncate">
    <router-link
        v-if="!editableCell"
        :target="target"
        to="/job-costing/settings/job-cost-settings"
        class="text-gray-900"
        :title="typeName"
    >
      <span>
        {{ typeAbbr }}
      </span>
    </router-link>
    <template v-else>
      <span :title="typeName">
        {{ typeAbbr }}
      </span>
    </template>
  </div>
</template>
<script>
  export default {
    name: 'JobTypeLink',
    props: {
      id: [String, Number],
      target: String,
      editableCell: Boolean,
    },
    computed: {
      allTypes() {
        return this.$store.state.globalLists.jobTypes
      },
      typeData() {
        return this.allTypes.find(type => type.id === this.id)
      },
      typeAbbr() {
        return this.typeData?.abbr
      },
      typeName() {
        return this.typeData?.name
      }
    },
  }
</script>
