<template>
  <base-data-table
      v-bind="defaultAttributes"
      :columns="columns"
      :data="rows"
      :total-rows="rowsLength"
      :has-summary="false"
      @force-pagination="forcePagination"
  >
    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>
    <template #header="{row}">
      <template v-if="isGroupByEquipmentOrClass">
        <span v-if="row?.header?.is_equipment_class">
          <span>{{ $t('Equipment Class') }}:</span>
          <router-link
              v-if="row.header?.equipmentClass?.id"
              :to="`/equipment/settings/classes/${row.header.equipmentClass.id}/view`">
            {{ row.header.equipmentClass.code }}
            <span v-if="row.header.equipmentClass.description">
              ({{ row.header.equipmentClass.description }})
            </span>
          </router-link>
        </span>
        <div v-else
             class="flex items-center space-x-2">
          <EquipmentLink
              :data="row.header?.equipment"
              :show-preview="false"
          />
          <template v-if="row.header?.equipmentClass?.id">
            <span>{{ $t('Class') }}:</span>
            <router-link :to="`/equipment/settings/classes/${row.header.equipmentClass.id}/view`">
              {{ row.header.equipmentClass?.code }}
            </router-link>
          </template>
        </div>

      </template>

      <template v-else>
        <router-link
            v-if="getGroupHeaderLink(row?.header)"
            to="">
          {{ row?.header?.code || row?.header?.number }}
          <span v-if="row?.header?.description">
              ({{ row.header.description }})
            </span>
        </router-link>
        <AccountLink
            v-else-if="groupByAccount"
            :number="row?.header"
        />
        <!--* for journal number-->
        <span v-else>
          {{ row?.header }}
        </span>
      </template>

    </template>
    <template #equipment="{row}">
      <EquipmentLink
          :data="row.equipment"
          :show-preview="false"
          :show-description="false"
      />
    </template>
    <template #period_fiscal_year="{row}">
      {{ `${row?.history?.fiscal_year}   ${row?.history?.period}` }}
    </template>
    <template #account="{row}">
      <AccountLink
          :number="row?.history?.account"
          :show-description="false"
      />
    </template>

    <template #subtotals="{subtotals}">
      <tr
          v-if="subtotals.header"
          class="subtotal-row"
      >
        <td colspan="2"/>
        <td colspan="7">
          <div class="flex items-center space-x-2 justify-end">
            <span>{{ $t(subtotalHeader(subtotals?.header)) }}</span>
            <router-link
                v-if="getGroupHeaderLink(subtotals?.header)"
                :to="getGroupHeaderLink(subtotals?.header)"
            >
              {{ subtotals?.header?.code || subtotals?.header?.number }}
            </router-link>
            <AccountLink
                v-else-if="groupByAccount"
                :number="subtotals?.header"
                :show-description="false"
            />
            <!--* for journal number-->
            <span v-else>
                {{ subtotals?.header }}
              </span>
          </div>
        </td>
        <td align="right">{{ subtotals.gallons || '' }}</td>
        <td align="right">{{ subtotals.hours || '' }}</td>
        <td align="right">
          <span class="mx-2">{{ $formatPrice(subtotals.amount || '') }}</span>
        </td>
      </tr>
      <tr v-else-if="subtotals.emptyRow">
        <td>&nbsp;</td>
      </tr>
      <tr v-else-if="subtotals.subtotalsByCostType || !isGroupByEquipmentOrClass">
        <td align="center" colspan="12">{{ $t('Sub-Totals by Cost Type') }}</td>
      </tr>
      <tr
          v-else
          v-for="subtotal in subtotals"
          class="subtotal-row"
      >
        <td colspan="9" align="right">{{ subtotal.name }}</td>
        <td align="right">{{ subtotal.gallons || '' }}</td>
        <td align="right">{{ subtotal.hours || '' }}</td>
        <td align="right">
          <span class="mx-2">{{ subtotal.amount ? $formatPrice(subtotal.amount) : '' }}</span>
        </td>
      </tr>
    </template>

    <template #html-row="{ htmlData }">
      <tr>
        <td colspan="7"/>
        <td colspan="2" align="left">
          {{ $t('Grand Totals') }}
        </td>
        <td align="right">
          {{ grandTotals.totals?.gallons || '' }}
        </td>
        <td align="right">
          {{ grandTotals.totals?.hours || '' }}
        </td>
        <td align="right">
          {{ $formatPrice(grandTotals.totals?.amount) }}
        </td>
      </tr>
      <tr>
        <td colspan="15">&nbsp;</td>
      </tr>
      <tr>
        <td colspan="8" align="right"> {{ $t('Grand Totals By Cost Type:') }}</td>
        <td colspan="5"></td>
      </tr>
      <tr
          v-for="subtotal in grandTotals.subtotals"
          class="no-borders"
      >
        <td colspan="7"/>
        <td colspan="2" align="left">
          <router-link to="/equipment/settings/equipment-management-settings">
            {{ subtotal.equipment_type?.name }}
          </router-link>
        </td>
        <td align="right">
          {{ subtotal.totals?.gallons || '' }}
        </td>
        <td align="right">
          {{ subtotal.totals?.hours || '' }}
        </td>
        <td align="right">
          {{ $formatPrice(subtotal.totals?.amount) }}
        </td>
      </tr>
    </template>
  </base-data-table>
</template>
<script lang="ts">
  import get from "lodash/get";
  import {defineComponent} from 'vue'
  import {formattedText} from '@/utils/utils'
  import {Column} from '@/components/ag-grid/tableTypes'
  import {equipmentTransactionTypes} from '@/enum/equipment'
  import {GroupByTypes} from "@/modules/equipment/components/reports/filterTypes";
  import {formatSubtotalForGroup,} from "@/modules/equipment/components/reports/utils";
  import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper.vue'

  export default defineComponent({
    extends: ReportTableWrapper,
    props: {
      filters: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        equipmentTransactionTypes,
      }
    },
    computed: {
      columns(): Column[] {
        return [
          {
            label: this.$t('Equipment'),
            prop: 'equipment',
            minWidth: 100,
            maxWidth: 160,
            hiddenValue: true,
          },
          {
            label: this.$t('Description'),
            prop: 'history.description',
            minWidth: 80,
            maxWidth: 110,
          },
          {
            label: this.$t('Cost Type'),
            prop: this.isGroupByEquipmentOrClass ? 'equipment_type.name' : 'type.name',
            minWidth: 100,
            maxWidth: 160,
            component: 'EntityLink',
            redirectTo: '/equipment/settings/equipment-management-settings',
          },
          {
            label: this.$t('Maint <br>Code '),
            prop: 'maintenance_code.code',
            minWidth: 80,
            maxWidth: 90,
            component: 'EntityLink',
            redirectTo: '/equipment/maintenance-codes?id={ID}',
          },
          {
            label: this.$t('Fiscal<br> Year Per'),
            prop: 'period_fiscal_year',
            minWidth: 80,
            maxWidth: 90,
          },
          {
            label: this.$t('Journal'),
            prop: 'journal.name',
            minWidth: 80,
            maxWidth: 100,
          },
          {
            label: this.$t('Transaction <br> Date'),
            prop: 'history.reference_date',
            minWidth: 80,
            maxWidth: 100,
            component: 'FormattedDate',
          },
          {
            label: this.$t('Vendor'),
            prop: 'business.code',
            minWidth: 80,
            maxWidth: 100,
            component: 'EntityLink',
            redirectTo: '/accounts-payable/vendors/{ID}/view',
            entityKey: 'business.id',
          },
          {
            label: this.$t('Account'),
            prop: 'account',
            minWidth: 80,
            maxWidth: 100,
          },
          {
            label: this.$t('Gallons <br> (fuel)'),
            prop: 'history.gallons',
            minWidth: 100,
            maxWidth: 120,
            align: 'right',
            hideZero: true,
          },
          {
            label: this.$t('Hours'),
            prop: 'history.hours',
            minWidth: 60,
            maxWidth: 80,
            align: 'right',
            hideZero: true,
          },
          {
            label: this.$t('Amount'),
            prop: 'history.amount',
            minWidth: 100,
            maxWidth: 120,
            align: 'right',
            component: 'FormattedPrice',
            hideZero: true,
          },
        ]
      },
      groupBy(): string {
        return this.options.group_by
      },
      showSummaryOnly(): boolean {
        return this.filters.summary_only
      },
      groupByAccount(): boolean {
        return this.groupBy === GroupByTypes.GLAccount
      },
      groupByEquipment(): boolean {
        return this.groupBy === GroupByTypes.EquipmentCode
      },
      groupByClassCode(): boolean {
        return this.groupBy === GroupByTypes.ClassCode
      },
      isGroupByEquipmentOrClass(): boolean {
        return this.groupByEquipment || this.groupByClassCode
      },
      resourceFieldsByGroupKey() {
        const groupTypeMap: Record<string, string> = {
          [GroupByTypes.JournalNumber]: 'journal_name',
          [GroupByTypes.GLAccount]: 'account_number',
          [GroupByTypes.EqpMaintenanceCode]: 'maintenance_code',
          default: '',
        }

        return groupTypeMap[this.groupBy] || groupTypeMap.default
      },
    },
    methods: {
      formattedText,
      composeRows(data: Record<string, any>) {
        if (this.groupByEquipment) {
          this.mapEquipmentGroupRows(data, null)
          //@ts-ignore
          this.rows.push({
            htmlData: this.grandTotals,
          })
          return
        }

        if (this.groupByClassCode) {
          return this.mapClassGroupRows(data)
        }

        this.mapForAnotherGroupsRows(data)
      },

      mapEquipmentGroupRows(data: Record<string, any>, groupClass: Record<string, any> | null) {

        data.forEach((group: Record<string, any>) => {

          const {equipment, cost_types, equipment_class, totals} = group
          const equipmentClass = equipment_class || groupClass
          //@ts-ignore
          this.rows.push({
            header: {
              equipment,
              equipmentClass,
            },
          })

          let groupItems = cost_types
          if (get(cost_types, '[0].maintenance_codes')) {
            groupItems = cost_types[0].maintenance_codes
          }

          groupItems.forEach((groupItem: Record<string, any>, index: number) => {

            if (!this.showSummaryOnly) {
              groupItem?.items?.forEach((item: Record<string, any>) => {
                // @ts-ignore
                this.rows.push({
                  ...item,
                  equipment_type: cost_types[index]?.equipment_type,
                })
              })
            }

            let subtotalName = `${this.$t('Total')} ${cost_types[index]?.equipment_type?.name}`
            if (cost_types[0].maintenance_codes) {
              subtotalName = groupItem.maintenance_code?.code
                  ? `${this.$t('Sub-Total')} ${groupItem.maintenance_code?.code} ${groupItem.maintenance_code?.description}`
                  : `${this.$t('Sub-Total')} ${this.$t('(general repairs & maintenance)')}`
            }
            // * subtotals for each equipment type
            const subtotals = [{
              ...groupItem.totals,
              name: subtotalName,
            }]
            // @ts-ignore
            this.rows.push({
              subtotals,
            })
          })
          // * subtotals for each equipment
          const subtotals = {
            header: equipment,
            ...totals,
            name: `${this.$t('Total Equipment')} ${equipment.code}`,
          }
          // @ts-ignore
          this.rows.push({
            subtotals,
          })
        })
      },
      mapClassGroupRows(data: Record<string, any>) {
        data.forEach((groupObject: Record<string, any>) => {
          const {equipment_class: equipmentClass, totals} = groupObject
          if (equipmentClass) {
            //@ts-ignore
            this.rows.push({
              header: {
                equipmentClass,
                is_equipment_class: true,
              },
            })
          }

          this.mapEquipmentGroupRows(groupObject.items, equipmentClass)

          if (equipmentClass) {
            const subtotals = {
              header: equipmentClass,
              ...totals,
              is_equipment_class: true,
            }
            //@ts-ignore
            this.rows.push({
              subtotals: {
                emptyRow: true,
              }
            })
            //@ts-ignore
            this.rows.push({
              subtotals,
            })
          }
          //@ts-ignore
          this.rows.push({
            subtotals: {
              subtotalsByCostType: true,
            }
          })
          const subtotals = formatSubtotalForGroup(groupObject, 'subtotals')
          //@ts-ignore
          this.rows.push({
            subtotals,
          })
        })
        //@ts-ignore
        this.rows.push({
          htmlData: this.grandTotals,
        })
      },
      mapForAnotherGroupsRows(data: Record<string, any>) {
        const groupKey = this.resourceFieldsByGroupKey
        if (!groupKey) {
          return this.rows = []
        }

        data.forEach((group: Record<string, any>) => {
          const header = group[groupKey]
          if (header) {
            //@ts-ignore
            this.rows.push({
              header,
            })
          }

          if (!this.showSummaryOnly) {
            //@ts-ignore
            this.rows.push(...group?.items)
          }

          if (header) {
            let subtotals = {
              header: header,
              ...group?.totals,
            }
            //@ts-ignore
            this.rows.push({
              subtotals,
            })
          }

          const subtotals = formatSubtotalForGroup(group, 'subtotals')
          //@ts-ignore
          this.rows.push({
            subtotals,
          });
        })
        //@ts-ignore
        this.rows.push({
          htmlData: this.grandTotals,
        })
      },
      getGroupHeaderLink(header: Record<string, any>) {
        const linksByGroupKey: Record<string, string> = {
          [GroupByTypes.EquipmentCode]: `/equipment/list/${header.id}/view`,
          [GroupByTypes.ClassCode]: `/equipment/settings/classes/${header.id}/view`,
          [GroupByTypes.JobNumber]: `/job-costing/jobs/${header.id}/line-items`,
          [GroupByTypes.WorkOrderNumber]: `/service-billing/work-orders/${header.id}/view`,
          [GroupByTypes.EqpMaintenanceCode]: `/equipment/maintenance-codes?id=${header.id}`,
          default: '',
        }

        if (header?.class_id) {
          return `${this.$t('Total')} ${linksByGroupKey[GroupByTypes.EquipmentCode]}` || linksByGroupKey.default
        }

        return linksByGroupKey[this.groupBy] || linksByGroupKey.default
      },
      subtotalHeader(header: Record<string, any>): string {
        const groupTypeMap: Record<string, string> = {
          [GroupByTypes.EquipmentCode]: 'Equipment',
          [GroupByTypes.ClassCode]: 'Class',
          [GroupByTypes.JobNumber]: 'Job',
          [GroupByTypes.WorkOrderNumber]: 'Work Order',
          [GroupByTypes.JournalNumber]: 'Journal',
          [GroupByTypes.GLAccount]: 'G/L Account',
          [GroupByTypes.EqpMaintenanceCode]: 'Maintenance Code',
        }

        if (header?.class_id) {
          return `${this.$t('Total')} ${groupTypeMap[GroupByTypes.EquipmentCode]}` || linksByGroupKey.default
        }

        return `${this.$t('Total')} ${groupTypeMap[this.groupBy]}`
      },
    },
  })
</script>
