<template>
  <base-form
      :loading="loading"
      :show-cancel="true"
      :show-back="true"
      :save-text="$t('Create Batch')"
      :update-text="$t('Update Batch')"
      :focus-on-first-input="!$route.params.id"
      layout="vertical"
      @cancel="$router.push('/accounts-receivable/settings/ar-initialize')"
      @submit="onSubmit"
  >

    <FiscalYearSelect
        v-model="model.fiscal_year"
        :label="$t('Fiscal Year')"
        :default-previous-year="true"
        class="col-span-6 md:col-span-1"
        id="fiscal_year"
    />
    <PeriodSelect
        v-model="model.period"
        :placeholder="$t('Period')"
        :default-to-last-period="true"
        class="col-span-6 md:col-span-1"
        id="period"
    />

    <AccountsReceivableInitEntries
        :data="model"
        :key="model.id"
        class="col-span-6"
        ref="gridTable"
    />

    <template
      v-if="model.id"
      #extra-buttons-right>
      <AccountsReceivableInitExport :id="$route.params.id"/>
    </template>
  </base-form>
</template>
<script>
  import axios from 'axios'
  import AccountsReceivableInitEntries
    from '@/modules/accounts-receivable/components/settings/AccountsReceivableInitEntries'
  import { validateAgDataTable } from '@/components/ag-grid/tableUtils'
  import AccountsReceivableInitExport
    from "@/modules/accounts-receivable/components/settings/AccountsReceivableInitExport.vue";

  export default {
    components: {
      AccountsReceivableInitExport,
      AccountsReceivableInitEntries,
    },
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        loading: false,
        model: {
          status: this.$resourceStatuses.Pending,
          fiscal_year: this.$currentYear,
          period: this.$currentPeriod,
        },
      }
    },
    methods: {
      async onSubmit() {
        try {
          const isInvalidData = await validateAgDataTable()
          if (isInvalidData) {
            return
          }

          this.loading = true
          if (this.model.id) {
            await axios.put(`/restify/accounts-receivable-inits/${this.model.id}`, this.model)
            await this.$refs.gridTable.storeProgress(this.model.id)

            this.$success(this.$t('Open Receivables updated successfully'))
          } else {
            const { data } = await axios.post('/restify/accounts-receivable-inits', this.model)
            await this.$refs.gridTable.storeProgress(data.id)

            this.$success(this.$t('Open Receivables created successfully'))
            await this.$router.push('/accounts-receivable/settings/ar-initialize')
          }
        } catch (err) {
          console.warn(err)
          if (err.handled) {
            return
          }
          this.$error(this.$t('Error Initialize Open Receivables'))
        } finally {
          this.loading = false
        }
      },
    },
    watch: {
      data: {
        immediate: true,
        handler(value) {
          if (!value.id) {
            return
          }

          this.model = {
            ...this.model,
            ...value.attributes,
          }
        },
      },
    },
  }
</script>
