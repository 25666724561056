<template>
  <div>
    <base-data-table
        :url="url"
        :columns="columns"
        :edit-entity-url-query="editEntityUrlQuery"
        :add-text="$t('New maintenance code')"
        :per-page="$pagination.list"
        entity="maintenance-code"
        import-url="/equipment/maintenance-codes/import"
        ref="table"
        actions="search,add,refresh,import,export"
        permission="maintenance_codes"
        view-entity-url-query="/equipment/maintenance-codes"
        @add="onRowAdd"
        @edit="onRowEdit"
    >
      <template #attributes.interval="{row}">
        <span class="capitalize">
          {{ row.attributes.interval }}
        </span>
      </template>
    </base-data-table>
    <base-form-dialog
        v-if="showDialog"
        :title="rowToEdit ? $t('Edit Maintenance Code') : $t('Add Maintenance Code')"
        :open.sync="showDialog"
        @close="onEventTrigger"
    >
      <MaintenanceCodeForm
          :data="rowToEdit"
          :key="renderKey"
          @cancel="onEventTrigger"
          @save="onEventTrigger"
          @create-another="renderKey++"
      />
    </base-form-dialog>
  </div>
</template>
<script>
  import MaintenanceCodeForm from '@/modules/equipment/components/MaintenanceCodeForm'

  export default {
    components: {
      MaintenanceCodeForm,
    },
    data() {
      return {
        renderKey: 1,
        editEntityUrlQuery: '?id={ID}',
        rowToEdit: null,
        showDialog: false,
        url: '/restify/maintenance-codes',
        columns: [
          {
            label: this.$t('Code'),
            prop: 'attributes.code',
            component: 'EntityLink',
            redirectTo: '/equipment/maintenance-codes?id={ID}',
          },
          {
            label: this.$t('Description'),
            prop: 'attributes.description',
            minWidth: 150,
            maxWidth: 200,
          },
          {
            label: this.$t('Interval'),
            prop: 'attributes.interval',
            minWidth: 100,
            maxWidth: 150,
          },
          {
            label: this.$t('Months'),
            prop: 'attributes.interval_months',
            minWidth: 100,
            maxWidth: 150,
          },
          {
            label: this.$t('Value'),
            prop: 'attributes.interval_value',
            minWidth: 100,
            maxWidth: 150,
          },
        ],
      }
    },
    methods: {
      onRowEdit(row) {
        this.showDialog = true
        this.rowToEdit = row
      },
      onRowAdd() {
        this.showDialog = true
      },
      refreshTable() {
        this.$refs.table.refresh()
      },
      onEventTrigger() {
        this.rowToEdit = null
        this.showDialog = false
        this.refreshTable()
      },
    },
  }
</script>
