<template>
  <div class="col-span-6">
    <AgDataTable
        v-bind="editableTableProps"
        :columns="columns"
        :data="data"
        :is-row-selectable="isRowSelectable"
        :selected-rows.sync="selectedRows"
        ref="gridTable"
        id="gridTable"
        @grid-ready="onGridReady"
    >
      <template #cells-legend>
        <div class="flex space-x-2 items-center mt-4 text-sm">
          <div class="w-4 h-4 rounded bg-gray-200"></div>
          <div>{{
              $t(`Note that the not selectable rows doesn't match with module field and could be imported.`)
               }}
          </div>
        </div>
      </template>
    </AgDataTable>
  </div>
</template>
<script lang="ts" setup>
  import i18n from '@/i18n'
  import store from '@/store'
  import { computed, ref } from 'vue'
  import { formattedText } from '@/utils/utils'
  import { Column } from '@/components/ag-grid/tableTypes'
  import { cellClasses } from '@/components/ag-grid/columnUtils'
  import { editableTableProps } from '@/components/ag-grid/tableUtils'
  import { GridReadyEvent, ValueFormatterParams, CellClassParams } from '@ag-grid-community/core'
  import {
    mapDataToApiPayload, mapDataToSettingsTable, SettingsAction, SettingsActions,
  } from '@/modules/common/components/modules-settings/settingsActionsUtil'

  const props = defineProps({
    settingsObject: {
      type: Object as () => Record<string, any>,
      default: () => ({}),
    },
    fields: Array as () => string[],
    action: String as () => SettingsAction,
  })

  let selectedRows = ref<any[]>([])
  let grid = ref<GridReadyEvent | null>(null)
  const gridTable = ref()

  const columns: Column[] = [
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      minWidth: 50,
      maxWidth: 50,
    },
    {
      headerName: i18n.t('Name'),
      field: 'key',
      cellClass: cellClasses.ReadOnlyLight,
      valueFormatter: params => {
        return formattedText(params.value)
      },
    },
    {
      headerName: i18n.t('Value'),
      field: 'value',
      editable: true,
      valueFormatter: (params: ValueFormatterParams) => {
        if (!params.value || !isAccountCell(params.data.key)) {
          return params.value
        }

        return getAccountCellLabel(params.value)
      },
      cellClass: (params: CellClassParams) => {
        if (!params.value || !isAccountCell(params.data.key)) {
          return params.value
        }

        return getAccountCellClass(params.value)
      },
    },
  ]

  function isAccountCell(value: string | undefined) {
    return !value?.includes('subaccount') && value?.includes('account')
  }

  const accounts = store.state.company.accounts

  function getAccountCellLabel(account: string) {
    const accountIndex = accounts.findIndex((accountObject: Record<string, any>) => accountObject.number == account) as number
    return accountIndex === -1 ? `Account - '${ account }' - not exists.` : account
  }

  function getAccountCellClass(account: string) {
    const accountIndex = accounts.findIndex((accountObject: Record<string, any>) => accountObject.number == account) as number
    return accountIndex === -1 ? cellClasses.Invalid : ''
  }

  const data = computed(() => {
    return mapDataToSettingsTable(props.settingsObject)
  })

  function onGridReady(params: GridReadyEvent) {
    grid.value = params
    selectedRows.value = data.value
  }

  function isRowSelectable(params: any) {
    if (props.action === SettingsActions.Export) {
      return true
    }

    const { key } = params.data
    if (!key) {
      return true
    }

    return props.fields?.includes(key)
  }


  function getTableData() {
    return mapDataToApiPayload(selectedRows.value)
  }

  defineExpose({
    getTableData,
  })
</script>
