<template>
  <base-filter-range :class="gridClass">
    <template #start>
      <account-select
          v-model="value.account_number_from"
          :placeholder="$t('Account From')"
          :add-entity="false"
          :rules="accountFromValidator"
          :can-select-control-accounts="true"
          value-key="number"
          id="account_number_from"
          class="w-1/2"
          clearable
          @blur="onBlurStartRange"
      />
    </template>
    <template #end>
      <account-select
          v-model="value.account_number_to"
          :add-entity="false"
          :placeholder="$t('Account To')"
          :can-select-control-accounts="true"
          :rules="accountToValidator"
          value-key="number"
          class="w-1/2"
          id="account_number_to"
          clearable
      />
    </template>
  </base-filter-range>
</template>
<script>
  export default {
    props: {
      value: {
        type: Object,
        default: () => ({
          account_number_from: '',
          account_number_to: '',
        }),
      },
      gridClass: {
        type: String,
        default: 'col-span-4',
      },
    },
    computed: {
      accountToValidator() {
        return this.value.account_number_from ? `required|min_value:${+this.value.account_number_from}` : ''
      },
      accountFromValidator() {
        return this.value.account_number_to ? `required|max_value:${+this.value.account_number_to}` : ''
      },
    },
    methods: {
      onBlurStartRange() {
        if (!this.value.account_number_from) {
          return
        }

        this.value.account_number_to = this.value.account_number_to || this.value.account_number_from
      },
    },
  }
</script>
