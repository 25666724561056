<template>
  <div class="h-screen flex overflow-hidden bg-gray-100 dashboard-layout shadow"
       :class="{
            'theme-dark': theme === 'dark',
            'theme-light': theme === 'white',
            'theme-primary': theme === 'primary',
         }"
  >
    <div v-show="showCanvasOverlay" class="relative md:hidden">
      <div class="fixed left-0 flex z-40 h-screen">
        <transition enter-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-class="opacity-100"
                    leave-to-class="opacity-0">
          <div v-show="showSidebar"
               class="fixed inset-0 transition-opacity ease-linear duration-300">
            <div class="absolute inset-0 bg-gray-600 opacity-75"></div>
          </div>
        </transition>
        <transition enter-class="-translate-x-full"
                    enter-to-class="translate-x-0"
                    leave-class="translate-x-0"
                    leave-to-class="-translate-x-full"
        >
          <div v-show="showSidebar"
               class="flex-1 flex flex-col max-w-xs w-full transition ease-in-out duration-300 transform"
               :class="{
                  'bg-gray-800': theme === 'dark',
                  'bg-white': theme === 'white',
                  'bg-primary-800': theme === 'primary',
               }"
          >
            <div class="absolute top-0 right-0 -mr-14 p-1">
              <button
                  class="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600"
                  @click="toggleSidebar(false)"
                  aria-label="Close sidebar">
                <svg class="h-6 w-6 text-white" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"/>
                </svg>
              </button>
            </div>
            <div class="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
              <router-link to="/welcome" class="flex-shrink-0 flex items-center justify-center">
                <img class="h-8 w-auto" src="/img/cp.png" alt="CP4Cloud logo"/>
              </router-link>
              <nav class="mt-5">
                <template v-for="menu in sidebarMenus">
                  <div v-if="!menu.group"
                       @click="onMenuClick(menu)"
                       :key="`${menu.path}-${menu.name}`"
                       :to="menu.path"
                       :data-test="`menu-mobile-${menu.abbr}`"
                       role="button"
                       class="mt-1 menu-item group flex items-center mx-2 py-1 justify-center text-base leading-6 font-medium rounded-md focus:outline-none focus:text-white transition ease-in-out duration-150"
                       :class="{
                                'text-gray-300 hover:text-white hover:bg-gray-700 focus:bg-gray-700': theme === 'dark',
                                'text-gray-600 hover:text-gray-900 hover:bg-gray-50 focus:bg-gray-100': theme === 'white',
                                'text-primary-200 hover:text-white hover:bg-primary-700 focus:bg-primary-700': theme === 'primary',
                                'router-link-active' : isActiveMenu(menu.path)
                             }"
                  >
                    <div class="px-2 py-1 flex items-center"
                         :class="showSubmenu ?'flex-col': 'w-full'">
                      <component :is="menu.icon"
                                 stroke="currentColor"
                                 class="mx-3 h-5 w-5 transition ease-in-out duration-150"
                                 :class="{
                                    'text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300': theme === 'dark',
                                    'text-gray-600 group-hover:text-gray-900 group-focus:text-gray-900': theme === 'white',
                                    'text-primary-200 group-hover:text-primary-300 group-focus:text-primary-300': theme === 'primary',
                               }"
                      />
                      <span class="mt-1 text-gray-400 text-sm">
                        {{ showSubmenu ? menu.abbr : menu.name }}
                      </span>
                    </div>
                  </div>
                </template>
              </nav>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div class="hidden md:flex md:flex-shrink-0 relative">
      <div class="flex flex-col"
           :class="[{
                    'bg-gray-800': theme === 'dark',
                    'bg-white': theme === 'white',
                    'bg-primary-800': theme === 'primary',
                 }, sidebarExpanded ? 'w-64' : 'w-20']"
      >
        <div class="h-0 flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
          <router-link to="/welcome" class="flex items-center flex-shrink-0 px-6"
                       :class="{'justify-center': !sidebarExpanded}">
            <img class="h-8 w-auto -ml-1" src="/img/cp.png" alt="CP4Cloud Logo"/>
            <h3 v-if="sidebarExpanded"
                class="text-base ml-2"
                :class="{
                    'text-gray-200': theme === 'dark',
                    'text-gray-600': theme === 'white',
                    'text-primary-200': theme === 'primary',
                }">
              {{ $t('Construction Partner') }}
            </h3>
          </router-link>
          <nav class="flex-1 px-2"
               :class="[{
                  'bg-gray-800': theme === 'dark',
                  'bg-white': theme === 'white',
                  'bg-primary-800': theme === 'primary',
               }, sidebarExpanded ? 'mt-5': 'mt-8']"
          >
            <transition-group enter-active-class="transform transition ease-in-out duration-150"
                              enter-class="translate-y-2"
                              enter-to-class="translate-y-0"
                              leave-active-class="transform transition ease-in-out duration-150"
                              leave-class="translate-y-0"
                              leave-to-class="translate-y-2"
                              appear
            >
              <template v-for="menu in sidebarMenus">
                <template v-if="menu.group">
                  <p :key="`${menu.path}-${menu.name}`"
                     @click="triggerSubmenu(menu)"
                     class="px-3 text-xs font-semibold text-gray-400 uppercase tracking-wider"
                     :class="sidebarExpanded ? 'mt-5 mb-2' : 'hidden'">
                    {{ menu.name }}
                  </p>
                </template>
                <div v-else
                     @click="triggerSubmenu(menu)"
                     :key="`${menu.path}-${menu.name}`"
                     :to="menu.path"
                     :data-test="`menu-${menu.abbr}`"
                     role="button"
                     class="menu-item mt-1 group flex items-center text-sm leading-5 font-medium rounded-md focus:outline-none transition ease-in-out duration-150"
                     :class="{
                    'text-gray-300 hover:bg-gray-700 hover:text-white focus:text-white focus:bg-gray-700': theme === 'dark',
                    'text-gray-600 hover:text-gray-900 hover:bg-gray-50 focus:bg-gray-100': theme === 'white',
                    'text-primary-200 hover:bg-primary-700 focus:text-white focus:bg-primary-700 hover:text-white': theme === 'primary',
                    'justify-center': !sidebarExpanded,
                    'router-link-active' : isActiveMenu(menu.path),
                 }"
                >
                  <template v-if="sidebarExpanded">
                    <div class="flex items-center px-2 py-2">
                      <SidebarMenuIcon :icon="menu.icon"
                                       :class="sidebarExpanded ? 'mr-3' : 'mr-0'"
                      />
                      <span :key="`${menu.path}-${menu.name}`">
                        {{ menu.name }}
                      </span>
                    </div>
                  </template>
                  <base-tooltip v-else
                                effect="light"
                                placement="right"
                                :content="menu.name"
                                :key="`${menu.path}-${menu.name}`">
                    <div class="p-2 flex items-center flex-col">
                      <SidebarMenuIcon :icon="menu.icon"
                                       :class="sidebarExpanded ? 'mr-3' : 'mr-0'"
                      />
                      <span class="mt-1 text-gray-400 text-sm">{{ menu.abbr }}</span>
                    </div>
                  </base-tooltip>
                </div>
              </template>
            </transition-group>
          </nav>
        </div>
        <div class="h-16 items-center flex px-6"
             :class="sidebarExpanded ? 'justify-end': 'justify-center'">
          <AppVersion v-if="sidebarExpanded"/>
          <div class="p-2 rounded shadow-md transition ease-in-out duration-150"
               role="button"
               :class="{
                  'text-gray-300 bg-gray-600 hover:bg-gray-700 hover:text-white focus:text-white focus:bg-gray-700': theme === 'dark',
                  'text-gray-600 bg-gray-100 hover:text-gray-900 hover:bg-gray-50 focus:bg-gray-100': theme === 'white',
                  'text-primary-200 bg-primary-600 hover:bg-primary-700 focus:text-white focus:bg-primary-700 hover:text-white': theme === 'primary',
                  'toggle-sidebar-icon': !sidebarExpanded,
               }"
               @click="toggleSidebarState">
            <IconArrowLeft/>
          </div>
        </div>
      </div>
    </div>
    <SidebarSubmenu v-show="submenuExists"
                    :menu-opened="showSubmenu"
                    :sidebar-expanded="sidebarExpanded"
                    :items="submenus"
                    :overlay-class="showCanvasOverlay ? 'ml-20': 'ml-0'"
                    :theme="theme"
                    @hide-menu="triggerHideSubmenu"
    />
  </div>
</template>
<script>
  import bus from '@/event-bus/EventBus'
  import sidebarMenus from '@/modules/dashboard/enum/sidebarMenus'
  import SidebarSubmenu from '@/modules/dashboard/components/SidebarSubmenu'
  import DashboardPageHeader from '@/modules/dashboard/components/DashboardPageHeader'
  import SidebarMenuIcon from "@/modules/dashboard/components/SidebarMenuIcon";
  import AppVersion from "@/modules/dashboard/components/AppVersion.vue";

  export default {
    components: {
      AppVersion,
      SidebarSubmenu,
      SidebarMenuIcon,
      DashboardPageHeader,
    },
    props: {
      theme: String,
    },
    data() {
      let sidebarExpanded = false
      try {
        sidebarExpanded = JSON.parse(localStorage.getItem('sidebar-expanded'))
      } catch (err) {
        console.warn(err)
      }
      return {
        showSidebar: false,
        showCanvasOverlay: false,
        sidebarExpanded,
        showSubmenu: false,
        submenus: [],
        selectedMenu: {},
        menus: sidebarMenus,
      }
    },
    computed: {
      submenuExists() {
        return !!(this.submenus && this.submenus.length)
      },
      user() {
        return this.$store.state.auth.user
      },
      organization() {
        return this.$store.state.auth.organization
      },
      sidebarMenus() {
        return this.menus.filter(item => {
          if (!item.productionReady && this.isProduction) {
            return false
          }
          return this.$can(item.permission, item.permissionScope)
        })
      },
    },
    watch: {
      sidebarExpanded(value) {
        localStorage.setItem('sidebar-expanded', value)
      },
    },
    methods: {
      triggerSubmenu(menu) {
        const { submenus } = menu
        this.selectedMenu = menu
        this.submenus = submenus || []
        this.submenus = this.submenus.filter(item => {
          if (item.enabled) {
            return item.enabled()
          }
          return true
        })
        this.$nextTick(() => {
          this.focusOnFirstSubmenu()
          this.showSubmenu = true
        })
      },
      triggerHideSubmenu() {
        this.showCanvasOverlay = this.showSubmenu = false
        this.selectedMenu = null
      },
      toggleSidebarState() {
        this.sidebarExpanded = !this.sidebarExpanded
        this.$nextTick(() => {
          bus.$emit('toggle-sidebar')
        })
      },
      toggleSidebar(value) {
        if (value) {
          this.showSidebar = true
          this.showCanvasOverlay = true
          return
        }
        this.showSidebar = false
        setTimeout(() => {
          this.showCanvasOverlay = false
        }, 300)
      },
      async makeCompanyActive(id) {
        const companies = this.$store.state.company.userCompanies
        const company = companies.find(c => c.attributes.id === id) || { id }
        await this.$store.dispatch('company/markUserCompanyAsActive', company)
        await this.$store.dispatch('auth/refreshProfile', false)
      },
      onMenuClick(menu) {
        const { submenus } = menu
        this.selectedMenu = menu
        this.submenus = submenus || []
        this.$nextTick(() => {
          this.sidebarExpanded = false
          this.showSubmenu = this.showCanvasOverlay = true
        })
      },
      focusOnFirstSubmenu() {
        this.$nextTick(() => {
          const firstSubmenu = this.$el.querySelector('.submenu-item')
          if (!firstSubmenu) {
            return
          }
          firstSubmenu.focus()
        })
      },
      isActiveMenu(path) {
        if (this.selectedMenu?.path) {
          return path === this.selectedMenu?.path
        }
        return this.$route.fullPath.startsWith(path)
      },
      triggerMenuClick(route) {
        const matchingMenu = this.sidebarMenus.find(m => m.name === route.name?.trim())
        if (!matchingMenu) {
          return
        }
        this.triggerSubmenu(matchingMenu)
      }
    },
  }
</script>
<style lang="scss">
  .theme-dark .menu-item.router-link-active {
    @apply bg-gray-900;
  }

  .theme-light .menu-item.router-link-active {
    @apply bg-gray-100;
  }

  .theme-primary .menu-item.router-link-active {
    @apply bg-primary-900;
  }

  .toggle-sidebar-icon {
    transform: rotateY(180deg);
  }

  .w-20 {
    width: 5rem;
  }
</style>
