<template>
  <BillingsTable
      v-bind="$attrs"
      :billing-status="status"
  />
</template>
<script>
  import { resourceStatuses } from '@/enum/enums'
  import BillingsTable from '@/modules/accounts-receivable/pages/billings/BillingsTable'

  export default {
    components: {
      BillingsTable,
    },
    data() {
      return {
        status: resourceStatuses.Pending,
      }
    },
    watch: {
      '$route.meta': {
        immediate: true,
        handler(meta) {
          this.status = meta?.status
        },
      },
    },
  }
</script>
