import axios from "axios";

const types = {
  SET_USER: 'SET_USER',
  TOGGLE_CREATE_ANOTHER_ENTITY: 'TOGGLE_CREATE_ANOTHER_ENTITY',
};
const state = {
  selectedUser: {},
  createAnotherEntity: false,
};

const mutations = {
  [types.SET_USER]: (state, value) => {
    const { attributes, relationships } = value
    state.selectedUser = {
      ...attributes,
      ...relationships,
    };
  },
  [types.TOGGLE_CREATE_ANOTHER_ENTITY]: (state, value) => {
    state.createAnotherEntity = value
  }
};

const actions = {
  toggleCreateAnotherEntity({ commit }, value) {
    commit(types.TOGGLE_CREATE_ANOTHER_ENTITY, value)
  },
  async getUserById({ commit }, id) {
    const { data } = await axios.get(`/restify/users/${id}`, {
      params: {
        related: 'currentOrganizationCompanies,companyRoles'
      }
    });
    commit(types.SET_USER, data);
  }
};
const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
