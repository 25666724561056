<template>
  <AccountsPayableInitForm :data="data"/>
</template>
<script>
  import AccountsPayableInitForm from '@/modules/accounts-payable/components/settings/AccountsPayableInitForm'
  export default {
    components: {
      AccountsPayableInitForm,
    },
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
  }
</script>
