<template>
  <base-data-table
      v-bind="defaultAttributes"
      :columns="columns"
      :data="getData"
      :total-rows="rowsLength"
      :has-summary="false"
      headerRowCompacted
      @force-pagination="forcePagination"
  >

    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template #header="{row}">
      <div v-if="!row.header?.isEmployeeRow">
        {{ $t('Union') }}:
        <router-link :to="`/payroll/rates/unions?id=${row.header.id}`">
          {{ row.header.code }}
          <span v-if="row.header.description">
            ({{ row.header.description }})
          </span>
        </router-link>
      </div>
      <div
          v-else
          class="flex w-full space-x-12 items-center"
      >
          <router-link
              :to="`/payroll/employees/${row.header.employee.id}/view`"
          >
            {{ row.header.employee.code }}
            {{ row.header.employee.name }}
          </router-link>
          <span>
            {{ row.header.employee.ssn }}
          </span>
        <router-link :to="`/payroll/codes/craft-codes?id=${row.header.craft_code.id}`">
          {{ row.header.craft_code.description }}
        </router-link>
      </div>
    </template>

    <template #thead-infos>
      <th colspan="2"/>
      <th colspan="2">
        <p class="w-full text-center ml-8">{{ $t('Regular Pay') }}</p>
      </th>
      <th colspan="2" align="center">
        <p class="w-full text-center ml-8">{{ $t('Overtime Pay') }}</p>
      </th>
      <th colspan="2" align="center">
        <p class="w-full text-center ml-8">{{ $t('Premium Pay') }}</p>
      </th>
      <th colspan="2" align="center">
        <p class="w-full text-center pl-12">{{ $t('Total Hourly Pay') }}</p>
      </th>
    </template>

    <template #subtotals="{subtotals}">
      <tr v-for="{special_source} in subtotals">
        <td colspan="6"/>
        <td>{{ special_source.description }}</td>
        <td>{{ special_source.type }}</td>
        <td align="right">{{ special_source.hours }}</td>
        <td align="right">
          <span class="px-2">
            {{ $formatPrice(special_source.amount) }}
          </span>
        </td>
      </tr>
    </template>
    <template #subtotal="{subtotal}">

      <template v-if="subtotal?.payrollsSubtotal">
        <td colspan="7"/>
        <td align="right">{{ $t('Total') }}:</td>
        <td align="right" >
          {{ subtotal.hours?.total }}
        </td>
        <td align="right" >
          {{ $formatPrice(subtotal.amount?.total) }}
        </td>
      </template>
      <template v-else>
        <td colspan="7"/>
        <td align="right">{{ $t('Total') }}:</td>
        <td align="right" colspan="2">
            {{ $formatPrice(subtotal.amount?.summary) }}
        </td>
      </template>
    </template>

    <template #html-row="{ htmlData }">
      <tr class="top-border">
        <td colspan="10" class="subtotal-header">
          {{ $t('Summary Totals for Selected Periods') }}
        </td>
      </tr>
      <tr>
        <td>{{ $t('Pay/Deduction') }}</td>
        <td align="right">{{ $t('Hours') }}</td>
        <td align="right">{{ $t('Amount') }}</td>
      </tr>
      <tr>
        <td class="flex justify-between">
          <span>{{ $t('Regular Pay') }}</span>
          <span>({{ $t('Pay') }})</span>
        </td>
        <td align="right">{{ htmlData.totals?.hours?.REGPAY }}</td>
        <td align="right">{{ $formatPrice( htmlData.totals?.amount?.REGPAY) }}</td>
      </tr>
      <tr>
        <td class="flex justify-between">
          <span>{{ $t('Overtime Pay') }}</span>
          <span>({{ $t('Pay') }})</span>
        </td>
        <td align="right">{{ htmlData.totals?.hours?.OVTPAY }}</td>
        <td align="right">{{ $formatPrice( htmlData.totals?.amount?.OVTPAY) }}</td>
      </tr>
      <tr>
        <td class="flex justify-between">
          <span>{{ $t('Premium Pay') }}</span>
          <span>({{ $t('Pay') }})</span>
        </td>
        <td align="right">{{ htmlData.totals?.hours?.PRMPAY }}</td>
        <td align="right">{{ $formatPrice( htmlData.totals?.amount?.PRMPAY) }}</td>
      </tr>
      <tr>
        <td class="subtotal-row" align="right">
          {{ $t('Total Hourly Pay') }}:
        </td>
        <td align="right" class="subtotal-row"> {{ htmlData.totals?.hours?.total }}</td>
        <td align="right" class="subtotal-row">{{ $formatPrice( htmlData.totals?.amount?.total) }}</td>
        <td class="subtotal-row" colspan="8"/>
      </tr>
      <tr v-for="{special_source} in htmlData.special_sources">
        <td class="flex justify-between">
          <span>{{ special_source.description }}</span>
          <span>{{ special_source.type }}</span>
        </td>
        <td align="right">{{ special_source.hours }}</td>
        <td align="right">{{ $formatPrice(special_source.amount) }}</td>
      </tr>
      <tr>
        <td class="subtotal-row" align="right">
          {{ $t('Grand Totals') }}:
        </td>
        <td class="subtotal-row" align="right">
          {{ htmlData.totals?.hours?.summary }}
        </td>
        <td class="subtotal-row" align="right">
          {{ $formatPrice(htmlData.totals?.amount?.summary) }}
        </td>
        <td class="subtotal-row" colspan="8"/>
      </tr>
    </template>
  </base-data-table>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { reservedPayCodeList } from '@/enum/enums'
  import { Column } from '@/components/ag-grid/tableTypes'
  import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper.vue'

  export default defineComponent({
    extends: ReportTableWrapper,
    computed: {
      columns(): Column[] {
        return [
          {
            label: this.$t('Employee / Soc Sec # / Craft'),
            prop: 'employee.name',
            hiddenValue: true,
            minWidth: 250,
            maxWidth: 350,
          },
          {
            label: this.$t('Period Ending'),
            prop: 'period_end_date',
            minWidth: 100,
            maxWidth: 120,
            component: 'FormattedDate',
          },
          {
            label: this.$t('Hours'),
            prop: 'totals.hours.REGPAY',
            minWidth: 100,
            maxWidth: 120,
            align: 'right',
            hideZero: true,
          },
          {
            label: this.$t('Amount'),
            prop: 'totals.amount.REGPAY',
            minWidth: 100,
            maxWidth: 120,
            align: 'right',
            hideZero: true,
            component: 'FormattedPrice',
          },
          {
            label: this.$t('Hours'),
            prop: 'totals.hours.OVTPAY',
            minWidth: 100,
            maxWidth: 120,
            align: 'right',
            hideZero: true,
          },
          {
            label: this.$t('Amount'),
            prop: 'totals.amount.OVTPAY',
            minWidth: 100,
            maxWidth: 120,
            align: 'right',
            hideZero: true,
            component: 'FormattedPrice',
          },
          {
            label: this.$t('Hours'),
            prop: 'totals.hours.PRMPAY',
            minWidth: 100,
            maxWidth: 120,
            align: 'right',
            hideZero: true,
          },
          {
            label: this.$t('Amount'),
            prop: 'totals.amount.PRMPAY',
            minWidth: 100,
            maxWidth: 120,
            align: 'right',
            hideZero: true,
            component: 'FormattedPrice',
          },
          {
            label: this.$t('Hours'),
            prop: 'totals.hours.total',
            minWidth: 100,
            maxWidth: 120,
            align: 'right',
            hideZero: true,
          },
          {
            label: this.$t('Amount'),
            prop: 'totals.amount.total',
            minWidth: 100,
            maxWidth: 120,
            component: 'FormattedPrice',
            align: 'right',
            hideZero: true,
          }
        ]
      },
    },
    methods: {
      composeRows(data: Record<string, any>) {
        data.forEach((group: Record<string, any>) => {
          const {union, employees} = group
          // @ts-ignore
          this.rows.push({
            header: union,
          })

          employees.forEach((employeeObject: Record<string, any>) => {
            const {craft_codes, employee} = employeeObject || {}
            craft_codes.forEach((craftCode: Record<string, any>) => {
              const { craft_code } = craftCode
              const row = {
                craft_code,
                employee,
                isEmployeeRow: true,
                union,
              }
              // @ts-ignore
              this.rows.push({
                header: row,
              })

              const { payrolls,  special_sources : subtotals, totals : subtotal} = craftCode
              // @ts-ignore
              this.rows.push(...payrolls)
              if (payrolls.length > 1) {
                // @ts-ignore
                this.rows.push({
                  subtotal: {
                    ...subtotal,
                    payrollsSubtotal: true,
                  }
                })
              }
              // @ts-ignore
              this.rows.push({
                subtotals
              })
              // @ts-ignore
              this.rows.push({
                subtotal,
              })
            })
          })
          // @ts-ignore
          this.rows.push({
            htmlData: {
              special_sources: group.special_sources,
              totals: group.totals,
            },
          })
        })
        return this.rows
      },
    },
  })
</script>
