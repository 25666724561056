<template>
  <div>
    <BaseButton
        variant="primary-link"
        class="print:hidden send-email-button"
        v-bind="$attrs"
        @click="onSendClick"
    >
    <span class="flex items-center">
      <MailIcon class="w-4 h-4 mr-2"/>
      <span>
        {{ $t('Send Email') }}
      </span>
    </span>
    </BaseButton>

    <BaseFormDialog
        v-if="showSendEmailDialog"
        :title="$t('Send Invoice via Email')"
        :open.sync="showSendEmailDialog"
        @close="showSendEmailDialog = false"
    >
      <base-form
          v-bind="$attrs"
          :save-text="$t('Send')"
          :loading="loading"
          :submit-disabled="model.contacts.length === 0"
          layout="modal"
          @cancel="$emit('cancel')"
          @submit="sendEmail"
      >
        <ContactEmailRecipients
            :contacts="contacts"
            :data="contactData"
            :contact-entity="contactEntity"
            v-model="model.contacts"
        />

        <div class="col-span-6">
          <base-input
              v-model="model.subject"
              :label="$t('Subject')"
              rules="required"
              id="subject"
          />
        </div>

        <div class="col-span-6 mb-4">
          <BaseCheckbox
              v-model="model.include_pdf"
              :label="$t('Include a PDF version of the invoice')"
              id="include_pdf"
          />
        </div>
        <div class="col-span-6 mb-4">
          <BaseCheckbox
              v-model="model.include_attachments"
              :label="$t('Include attachments')"
              id="include_attachments"
          />
        </div>

        <BaseSelect
            v-model="selectedTemplate"
            @change="onTemplateChange"
            :label="$t('Template')"
            :options="availableTemplates"
            value-key="id"
            label-key="name"
            class="col-span-6"
        />
      </base-form>
    </BaseFormDialog>
  </div>
</template>
<script lang="ts" setup>
import {computed, getCurrentInstance, PropType, ref, toRef} from 'vue'
import {MailIcon} from 'vue-feather-icons'
import ContactEmailRecipients from "@/modules/common/components/contacts/ContactEmailRecipients.vue";
import axios from 'axios'
import i18n from "@/i18n";
import {error} from '@/components/common/NotificationPlugin'
import {ContactModel} from "@/modules/common/types/common";
import {usePrintTemplates} from "@/components/form/composables/usePrintTemplates";

const props = defineProps({
  data: {
    type: Object as PropType<any>,
    default: () => ({}),
  },
  entity: {
    type: String,
  },
  actionUrl: {
    type: String,
    required: true,
  },
  actionName: {
    type: String,
    required: true,
  },
  subject: {
    type: String,
    required: true,
  },
  contactEntity: {
    type: String,
    default: 'customer',
  }
})

const { availableTemplates, selectedTemplate, onTemplateChange } = usePrintTemplates(toRef(props, 'entity'))

const showSendEmailDialog = ref(false)
const loading = ref(false)

const model = ref({
  contacts: [] as ContactModel[],
  subject: '',
  include_pdf: true,
  include_attachments: false,
  template_id: null,
})

const contactData = computed(() => {
  return props.data[props.contactEntity]
})

const contacts = computed(() => {
  return props.data[props.contactEntity]?.relationships?.contacts
})

const app: any = getCurrentInstance()

function initSubject() {
  const companyName = app?.proxy?.$companyName || ''
  model.value.subject = `${props.subject} from ${companyName}`
}

async function onSendClick() {
  showSendEmailDialog.value = true
  initSubject()
}

const emit = defineEmits(['save', 'cancel'])

async function sendEmail() {
  try {
    loading.value = true
    const data = {
      subject: model.value.subject,
      contacts: model.value.contacts.map((c) => {
        return {
          name: c.name,
          email: c.email,
        }
      }),
      include_pdf: model.value.include_pdf,
      include_attachments: model.value.include_attachments,
      template_id: selectedTemplate.value,

    }
    await axios.post(props.actionUrl, data, {
      params: {
        action: props.actionName,
      }
    })
    showSendEmailDialog.value = false
    emit('save')
  } catch (err: any) {
    if (err.handled) {
      return
    }
    error(i18n.t('Could not send emails'))
  } finally {
    loading.value = false
  }
}
</script>
