<template>
  <div class="import-data mt-4">
    <ImportForm v-bind="importSettings"
                @success="redirectToList"
    />
  </div>
</template>
<script>
  import ImportForm from "@/modules/common/components/import/ImportForm";
  import { getInitialMapping } from "@/modules/payroll/utils/timecardImportColumns";

  const colMappings = {
    Code: {
      value: 'code',
      label: 'Code',
      alternativeLabels: ['Vendor Code'],
    },
    Name: {
      value: 'name',
      label: 'Name',
      alternativeLabels: ['Subcontractors', 'Vendor Name', 'Customer'],
    },
    Email: {
      value: 'email',
      label: 'Email',
      alternativeLabels: [],
    },
    Phone: {
      value: 'phone',
      label: 'Phone',
      alternativeLabels: ['TELEPHONE'],
    },
    Contact: {
      value: 'contact',
      label: 'Contact',
      alternativeLabels: ['Contact Name'],
    },
    Fax: {
      value: 'fax',
      label: 'Fax',
      alternativeLabels: [],
    },
    Address1: {
      value: 'address_1',
      label: 'Address 1',
      alternativeLabels: ['Customer Address 1'],
    },
    Address2: {
      value: 'address_2',
      label: 'Address 2',
      alternativeLabels: ['Customer Address 2'],
    },
    FullAddress: {
      value: 'address',
      label: 'Full Address',
      alternativeLabels: ['Address'],
    },
    City: {
      value: 'city',
      label: 'City',
      alternativeLabels: ['Customer City'],
      required: mapping => {
        return mapping.address_1 !== -1;
      }
    },
    State: {
      value: 'state',
      label: 'State',
      alternativeLabels: ['Customer State'],
      required: mapping => {
        return mapping.address_1 !== -1;
      }
    },
    Status: {
      value: 'status',
      label: 'Status',
      alternativeLabels: ['Customer Status'],
    },
    PostalCode: {
      value: 'postal_code',
      label: 'Zip Code',
      alternativeLabels: ['Zip', 'Postal Code', 'Customer Zip'],
      required: mapping => {
        return mapping.address_1 !== -1;
      }
    },
    FederalTax: {
      value: 'fed_tax_id',
      label: 'Federal Tax',
      alternativeLabels: ['Fed Tax', 'Tax Id'],
    },
    StateTax: {
      value: 'state_tax_id',
      label: 'State Tax',
      alternativeLabels: [],
    },
    W9OnFile: {
      value: 'w9_on_file',
      label: 'Form W-9',
      alternativeLabels: ['W9 On File', 'W9 Form', 'W-9 Status'],
    },
    Account: {
      value: 'account',
      label: 'Account',
      alternativeLabels: ['Account No.', 'Account Number', 'GL Account'],
    },
    SubAccount: {
      value: 'subaccount',
      label: 'Sub Account',
      alternativeLabels: ['Sub Account No.', 'Sub Account Number', 'GL Sub Acct'],
    },
    CurrentAPAmount: {
      value: 'current_ap_amount',
      label: 'Current AP Amount',
      alternativeLabels: ['AP Amount', 'Open Amount', 'Current Payables'],
    },
    PaidLastYearAmount: {
      value: 'paid_last_year_amount',
      label: 'Paid Last Year',
      alternativeLabels: [],
    },
    PaidThisYearAmount: {
      value: 'paid_this_year_amount',
      label: 'Paid This Year',
      alternativeLabels: [],
    }
  }

  const options = [
    colMappings.Code,
    colMappings.Name,
    colMappings.Email,
    colMappings.Phone,
    colMappings.Contact,
    colMappings.Fax,
    colMappings.Address1,
    colMappings.Address2,
    colMappings.FullAddress,
    colMappings.City,
    colMappings.State,
    colMappings.Status,
    colMappings.PostalCode,
    colMappings.Account,
    colMappings.SubAccount,
    colMappings.FederalTax,
    colMappings.StateTax,
    colMappings.W9OnFile,
    colMappings.CurrentAPAmount,
    colMappings.PaidLastYearAmount,
    colMappings.PaidThisYearAmount,
  ]

  export default {
    components: {
      ImportForm,
    },
    data() {
      return {
        importSettings: {
          url: '/restify/vendors/import',
          columnOptions: options,
          columnMappings: colMappings,
          initialMapping: getInitialMapping(options),
          type: 'vendors-import',
          resource: 'vendors',
        },
      }
    },
    methods: {
      async redirectToList() {
        await this.$router.push('/accounts-payable/vendors')
        await this.$store.dispatch('globalLists/getVendors')
      },
    }
  }
</script>
