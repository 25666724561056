<template>
  <div>
    <portal to="page-title-actions">
      <DownloadButton
          v-if="!isPending"
          :disabled="!isValidBank"
          variant="white"
          class="ml-2"
          @click="showPositiveVerificationDialog = true"
      >
        <span>{{ $t('Download Positive Pay') }}</span>
      </DownloadButton>
    </portal>
    <PositiveVerificationDialog
        v-if="showPositiveVerificationDialog"
        action="/restify/payroll-batches/action?action=generate-positive-pay"
        :payload="{repositories: [batchId]}"
        :open.sync="showPositiveVerificationDialog"
    />
    <base-alert
        v-if="isPending"
        :type="$promptType.Warning"
        class="mb-4"
    >
      {{ $t('Payroll batch was not posted yet. Check numbers are assigned after posting. You can use this screen for previewing the positive pay before posting.') }}
    </base-alert>

    <base-alert
        v-if="!isValidBank"
        :type="$promptType.Warning"
        class="mb-4 space-x-2"
    >
      <div class="flex flex-wrap items-center">
        <span class="mr-2">{{ $t('The selected payroll bank is not suitable for positive pay. Consider using another bank or updating the bank details.') }}</span>
        <BankLink :id="bankId"/>
      </div>
    </base-alert>

    <BaseDataTable
        id="payroll-positive-pay"
        :url="`/restify/payroll-batches/${batchId}/details`"
        :columns="columns"
        :is-report-table="true"
        :show-actions-column="false"
        :compact="true"
        :limit-max-height="false"
        :has-summary="true"
        :data-map-function="transformData"
        actions="search,refresh"
        default-sort="employees.code"
        class="no-borders"
    >
      <template #report-header>
        <BaseReportHeader :header="reportHeader">
          <template #left>
            <dt>{{$t('Payroll Ending Date: ')}} {{$formatDate(currentResource?.attributes?.period_end_date)}}</dt>
            <dt class="flex items-center space-x-1">
              <span>{{$t('Bank: ')}}</span>
              <BankLink :id="currentResource?.attributes?.bank_id"/>
            </dt>
          </template>
        </BaseReportHeader>
      </template>

      <template #employee.code="{row}">
        <EmployeeLink :data="row.employee" :is-payroll-preview="true"/>
      </template>
      <template #payroll.payment_number="{row}">
      <span v-if="row.payroll.payment_number">
        {{ row.payroll.payment_number }}
      </span>
        <span v-else>***</span>
      </template>
    </BaseDataTable>
  </div>
</template>
<script>
  import { resourceStatuses } from "@/enum/enums";
  import BaseReportHeader from "@/components/common/BaseReportHeader.vue";
  import { promptTypes as $promptType } from "@/plugins/promptProps";
  import { EyeIcon } from "vue-feather-icons";
  import axios from "axios";
  import PositiveVerificationDialog from "@/modules/accounts-payable/components/payment/PositiveVerificationDialog.vue";

  export default {
    components: { PositiveVerificationDialog, EyeIcon, BaseReportHeader },
    data() {
      return {
        showPositiveVerificationDialog: false,
        bankDetails: null,
      }
    },
    computed: {
      $promptType() {
        return $promptType
      },
      batchId() {
        return this.$route.params.id
      },
      currentResource() {
        return this.$store.state.payroll.currentPayrollBatch
      },
      isPending() {
        return this.currentResource.attributes.status === resourceStatuses.Pending
      },
      bankId() {
        return this.currentResource?.attributes?.bank_id
      },
      reportHeader() {
        return {
          title: this.$t('Positive Pay File Listing'),
          company: this.$currentCompany,
        }
      },
      columns() {
        return [
          {
            label: this.$t('Check #'),
            prop: 'payroll.payment_number',
            width: 100,
            maxWidth: 150,
            summary: () => this.$t('Check Totals:')
          },
          {
            label: this.$t('Amount'),
            prop: 'payroll.totals.check_pay',
            component: 'FormattedPrice',
            align: 'right',
            width: 200,
            maxWidth: 300,
            summary: () => this.$formatPrice(this.currentResource?.attributes?.totals?.check_pay),
          },
          {
            label: this.$t('Date'),
            prop: 'payroll.period_end_date',
            component: 'FormattedDate',
            align: 'center',
            maxWidth: 150,
          },
          {
            label: this.$t('Employee'),
            prop: 'employee.code',
          },
        ]
      },
      bankHasRoutingNumber() {
        return this.bankDetails?.attributes?.routing_number !== null
      },
      isValidBank() {
        return this.bankDetails?.attributes?.is_for_positive_pay && this.bankHasRoutingNumber
      }
    },
    methods: {
      transformData(data) {
        return data.filter(row => {
          return row.payroll.totals.check_pay > 0
        })
      },
      async getBankDetails() {
        if (!this.bankId || this.bankDetails) {
          return
        }
        const { data } = await axios.get(`/restify/banks/${this.bankId}`)
        this.bankDetails = data
      }
    },
    mounted() {
      this.getBankDetails()
    },
    watch: {
      currentResource() {
        this.getBankDetails()
      }
    }
  }
</script>
