<template>
  <ProgressBillingEntries
      :billing="billing"
      :read-only="true"
      :showCellsLegend="false"
      :get-billing-entries="getBillingEntries"
  />
</template>
<script>
  import ProgressBillingEntries
    from '@/modules/accounts-receivable/components/progress-bllings/ProgressBillingEntries.vue';
  import axios from 'axios';

  export default {
    name: 'ProgressBillingEntriesDetails',
    components: { ProgressBillingEntries },
    props: {
      billing: {
        type: Object,
        default: () => ({}),
      },
    },
    methods: {
      async getBillingEntries() {
        try {
          const { data } = await axios.get('/restify/billing-entries', {
            params: {
              sort: 'order',
              billing_id: this.billing.id,
              perPage: 999999,
            },
          })

          return data.map(entry => entry.attributes)
        } catch (err) {
          console.warn(err)
        }
      },
    },
  }
</script>
