<template>
  <div class="space-y-2 mb-2">
    <div class="w-full lg:w-1/2 placeholder-loading"></div>
    <div class="w-full lg:w-1/2 placeholder-loading"></div>
  </div>
</template>
<script>
  export default {}
</script>
<style>
</style>
