<template>
  <BaseFormDialog
    v-bind="$attrs"
    v-on="$listeners"
    size="lg"
  >
    <BaseForm
      :save-text="$t('Generate Timecards')"
      :loading="loading"
      layout="vertical"
      @submit="onSubmit"
    >
      <div class="col-span-6 md:col-span-2">
        <period-end-date-picker
          v-model="model.period_end_date"
          :showPendingBatchWarning="false"
          @batch-change="pendingBatch = $event"
          @change="onPeriodEndDateChange"
        />
      </div>
      <div class="col-span-6 md:col-span-2">
        <timecard-year-select v-model="model.year"/>
      </div>
      <div class="col-span-6"></div>
      <div class="col-span-6 md:col-span-2">
        <base-date-picker
          v-model="model.start_date"
          :disabled="true"
          :value-format="ApiDateFormat"
          :placeholder="$t('Select a Pay Period End Date')"
          :label="$t('Start Date')"
          :name="$t('Start Date')"
          class="w-[250px]"
          rules="required"
        />
      </div>
      <div class="col-span-6 md:col-span-2">
        <base-date-picker
          v-model="model.end_date"
          :disabled="true"
          :value-format="ApiDateFormat"
          :placeholder="$t('Select a Pay Period End Date')"
          :label="$t('End Date')"
          :name="$t('End Date')"
          class="w-[250px]"
          rules="required"
        />
      </div>
      <div class="col-span-6">
        <BaseAlert v-if="pendingBatch" type="warning" class="my-4">
          {{ $t('A pending timecard batch was found for the provided Pay Period End Date. The selected employees will be added to the batch.') }}
        </BaseAlert>
        <div class="font-medium text-gray-700 mb-4">
          {{$t('This action will generate timecards for the following timesheets. Only approved timesheets will be included.')}}
        </div>
        <AgDataTable
          url="/restify/timesheets"
          :url-params="urlParams"
          :columns="tableColumns"
          :groupIncludeFooter="true"
          :groupIncludeTotalFooter="true"
          :groupDefaultExpanded="-1"
          :suppressAggFuncInHeader="true"
          :groupRowRendererParams="groupRowRendererParams"
          :transform-data="onDataFetch"
          :groupSelectsChildren="true"
          :per-page="100"
          groupDisplayType="groupRows"
          domLayout="autoHeight"
          class="w-full"
          actions="search,refresh"
          @data-fetch="afterDataFetch"
          @grid-ready="grid = $event"
        >
          <template #attributes.date="{row, params}">
            <div>
              <span v-if="row">
                {{ $formatDate(row.attributes?.date, LongDateFormat) }}
              </span>
              <span v-if="!row && params.node.level === 0">
                {{ $t('Employee Totals') }}
              </span>
              <span v-if="!row && params.node.level === -1">
                {{ $t('Grand Totals') }}
              </span>
            </div>
          </template>
          <template #tags="{row}">
            <TimesheetTags :row="row"/>
          </template>
        </AgDataTable>
      </div>
    </BaseForm>
  </BaseFormDialog>
</template>
<script>

import PeriodEndDatePicker from "@/modules/payroll/components/timecard/PeriodEndDatePicker.vue";
import TimecardYearSelect from "@/modules/payroll/components/timecard/TimecardYearSelect.vue";
import axios from "axios";
import { TimesheetStatuses } from "@/modules/payroll/utils/payrollUtils";
import { ApiDateFormat, LongDateFormat } from "@/plugins/dateFormatPlugin";
import { getTotalMinutes, hoursFormatter } from "@/modules/payroll/utils/timesheetUtils";
import TimesheetTags from "@/modules/payroll/components/timesheets/TimesheetTags.vue";
import TimesheetGroupRow from "@/modules/payroll/components/timesheets/TimesheetGroupRow.vue";
import uniq from "lodash/uniq";
import parse from "date-fns/parse";
import { subWeeks } from "date-fns";
import format from "date-fns/format";
import uniqBy from "lodash/uniqBy";

export default {
  components: {
    TimesheetTags,
    TimecardYearSelect,
    PeriodEndDatePicker,
    TimesheetGroupRow
  },
  props: {
    periodEndDate: {
      type: Date,
    },
    startDate: {
      type: Date,
    },
    endDate: {
      type: Date,
    },
    employeeIds: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      loading: false,
      model: {
        period_end_date: this.periodEndDate,
        year: this.$settings(this.$modules.PR, 'calendar_year'),
        start_date: null,
        end_date: null,
        employee_ids: [],
      },
      pendingBatch: null,
      grid: null,
      ApiDateFormat,
      LongDateFormat,
    }
  },
  computed: {
    urlParams() {
      return {
        sort: 'employee.code,date',
        status: TimesheetStatuses.Approved,
        date: `${this.model.start_date},${this.model.end_date}`
      }
    },
    tableColumns() {
      return [
        {
          label: this.$t('Employee'),
          field: 'attributes.employee_id',
          component: 'EmployeeLink',
          rowGroup: true,
          hide: true,
        },
        {
          headerName: this.$t('Date'),
          field: 'attributes.date',
          minWidth: 200,
          headerCheckboxSelection: true,
        },
        {
          headerName: this.$t('Status'),
          field: 'attributes.status',
          component: 'TimesheetStatus',
          minWidth: 130,
          maxWidth: 350,
        },
        {
          headerName: this.$t('Tags'),
          field: 'tags',
          minWidth: 100,
          maxWidth: 200,
        },
        {
          headerName: this.$t('Regular'),
          field: 'attributes.regular_minutes',
          minWidth: 100,
          maxWidth: 100,
          valueFormatter: hoursFormatter,
          aggFunc: 'sum',
        },
        {
          headerName: this.$t('Overtime'),
          field: 'attributes.overtime_minutes',
          minWidth: 100,
          maxWidth: 100,
          valueFormatter: hoursFormatter,
          aggFunc: 'sum',
        },
        {
          headerName: this.$t('Premium'),
          field: 'attributes.premium_minutes',
          minWidth: 100,
          maxWidth: 100,
          valueFormatter: hoursFormatter,
          aggFunc: 'sum',
        },
        {
          headerName: this.$t('Total Hours'),
          field: 'attributes.total_minutes',
          minWidth: 100,
          maxWidth: 100,
          pinned: 'right',
          valueGetter: getTotalMinutes,
          valueFormatter: hoursFormatter,
          aggFunc: 'sum',
        },
      ]
    },
    groupRowRendererParams() {
      return {
        innerRenderer: 'TimesheetGroupRow',
        suppressCount: true,
        checkbox: true,
      }
    },
    isBiWeeklyPayroll() {
      return this.$settings(this.$modules.PR, 'is_biweekly_payroll')
    },
  },
  methods: {
    onPeriodEndDateChange(date) {
      const weeksToSubtract = this.isBiWeeklyPayroll ? 2 : 1
      const periodEndDate = parse(date, ApiDateFormat, new Date())
      const startDate = subWeeks(periodEndDate, weeksToSubtract)
      const endDate = periodEndDate

      this.model.start_date = format(startDate, ApiDateFormat)
      this.model.end_date = format(endDate, ApiDateFormat)
    },
    onDataFetch(data) {
      const employees = data.map(row => row.attributes.employee_id)
      this.model.employee_ids = uniq(employees)
      return data
    },
    async afterDataFetch() {
      await this.$nextTick()
      this.selectAllRows()
    },
    selectAllRows() {
      this.grid?.api?.selectAll()
    },
    getSelectedEmployees() {
      const rows = this.grid?.api?.getSelectedRows() || []
      const employeeIds = rows.map(row => row?.attributes?.employee_id).filter(row => !!row)
      return uniq(employeeIds)
    },
    async onSubmit() {
      try {
        this.loading = true
        const data = {
          ...this.model,
          employee_ids: this.getSelectedEmployees(),
          batch_id: this.pendingBatch?.id || undefined,
        }
        const response = await axios.post(`/restify/timesheets/actions?action=generate-timecards`, data)
        const batchId = response.data.batch_id
        if (batchId) {
          await this.$router.push(`/payroll/timecard-batches/${batchId}/view`)
        }
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
