<script>
  export default {
    name: 'BaseTableColumn',
    props: {
      sortable: Boolean,
      showDrag: Boolean,
      label: String,
      labelTooltip: String,
      align: String,
      verticalAlign: String,
      classes: String,
      rowClasses: String,
      rowTag: {
        type: String,
        default: 'td'
      },
      prop: String,
      minWidth: [String, Number],
      width: [String, Number],
      maxWidth: [String, Number],
      padding: [String, Number],
      smallCell: Boolean,
      summary: Function,
      hideOnMobile: Boolean,
      draggable: {
        type: Boolean,
        default: true,
      },
      background: String,
      redirectTo: String,
      entityKey: String,
      hiddenValue: Boolean,
      formatter: Function,
      colspan: {
        type: [Number, String],
        default: 1,
      },
      rowspan: {
        type: [Number, String],
        default: 1,
      },
      params: Object,
      summaryAlignment: String,
      hideZero: Boolean,
    },
    data() {
      return {
        sortDirection: '',
      }
    },
    inject: ['addColumn', 'removeColumn'],
    mounted() {
      this.addColumn(this);
    },
    destroyed() {
      if ( this.$el && this.$el.parentNode ) {
        this.$el.parentNode.removeChild(this.$el)
      }
      this.removeColumn(this)
    },
    render(h) {
      return h(null)
    },
    methods: {
      toggleSort() {
        if ( !this.sortable ) {
          return
        }
        if ( this.sortDirection === 'asc' ) {
          this.sortDirection = 'desc'
        } else if ( this.sortDirection === 'desc' ) {
          this.sortDirection = ''
        } else {
          this.sortDirection = 'asc'
        }
      },
      parseStyleProperty(property) {
        let result = ''

        if ( typeof property === 'number' ) {
          result = `${property}px`
        } else {
          result = property
        }
        return result
      },
      getStyles() {
        const styles = {}
        const properties = ['minWidth', 'maxWidth', 'width', 'background', 'padding', 'verticalAlign']
        properties.forEach(key => {
          if (this[key] !== undefined) {
            styles[key] = this.parseStyleProperty(this[key])
          }
        })
        return styles
      }
    }
  }
</script>
