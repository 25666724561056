<template>
  <div class="flex justify-between w-full leading-4">
    <EmployeeLink :id="employee.id"/>
    <div v-if="payroll.net_pay" class="flex space-x-2 font-semibold">
      <span>{{ $t('Net Pay:') }}</span>
      <span>{{ $formatPrice(netPay) }}</span>
    </div>
  </div>
</template>
<script>
  import sumBy from 'lodash/sumBy'

  export default {
    computed: {
      data() {
        return this.params.node?.allLeafChildren[0]?.data || {}
      },
      payroll() {
        return this.data?.payroll || {}
      },
      employee() {
        return this.data?.employee || {}
      },
      adjustedEmployeeTaxes() {
        const children = this.params.node?.allLeafChildren || []
        return sumBy(children, 'data.adjusted_amount')
      },
      netPay() {
        const employeeTaxes = this.payroll.employee_taxes || 0
        return this.payroll.net_pay + employeeTaxes - this.adjustedEmployeeTaxes
      },
      level() {
        return this.params.node.level
      }
    }
  }
</script>
