<template>
  <base-data-table
      v-bind="defaultAttributes"
      :columns="columns"
      :data="getData"
      :total-rows="rowsLength"
      @force-pagination="forcePagination"
  >

    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template #header="{row}">
      <EmployeeLink
          :data="row.header"
          :show-preview="false"
      />
    </template>

    <template #subtotal="{subtotal}">
      <td/>
      <td align="right">
        <div class="flex">
          {{ subtotal.masked_ssn }}
          <dl class="ml-8">
            <dt>{{ $t('QTD:') }}</dt>
            <dt>{{ $t('YTD:') }}</dt>
          </dl>
        </div>
      </td>
      <td
          v-for="column in amountColumns"
          :key="column.prop"
          align="right"
      >
        <dt>{{ $formatPrice(subtotal.qtd?.[column.prop]) }}</dt>
        <dt>{{ $formatPrice(subtotal.ytd?.[column.prop]) }}</dt>
      </td>
    </template>

    <template #masked_ssn_summary>
      <div class="flex">
        {{ $t('Grand Totals:') }}
        <dl class="ml-4">
          <dt>{{ $t('QTD:') }}</dt>
          <dt class="mt-4">{{ $t('YTD:') }}</dt>
        </dl>
      </div>
    </template>

    <template v-for="column in amountColumns" #[getSummarySlotName(column)]>
      <dl>
        <dt>{{ $formatPrice(data.grand_totals.qtd?.[column.prop]) }}</dt>
        <dt class="mt-4">{{ $formatPrice(data.grand_totals.ytd?.[column.prop]) }}</dt>
      </dl>
    </template>

  </base-data-table>
</template>
<script>
  import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'

  export default {
    extends: ReportTableWrapper,
    computed: {
      columns() {
        return [
          {
            label: this.$t('Employee'),
            prop: 'employee',
            hiddenValue: true,
            minWidth: 100,
            maxWidth: 100,
          },
          {
            label: this.$t('Soc Sec #'),
            prop: 'masked_ssn',
            minWidth: 120,
            maxWidth: 120,
          },
          {
            label: this.$t('FIT <br> Earnings'),
            prop: 'fit_earnings',
            component: 'FormattedPrice',
            align: 'right',
          },
          {
            label: this.$t('FIT Tax <br> W/Held'),
            prop: 'fit',
            component: 'FormattedPrice',
            align: 'right',
          },
          {
            label: this.$t('FICA  <br> Earnings'),
            prop: 'fica_earnings',
            component: 'FormattedPrice',
            align: 'right',
          },
          {
            label: this.$t('FICA Tax <br> W/Held'),
            prop: 'fica',
            component: 'FormattedPrice',
            align: 'right',
          },
          {
            label: this.$t('FICA Tax <br> Computed <br>'),
            prop: 'calculated_fica',
            component: 'FormattedPrice',
            align: 'right',
          },
          {
            label: this.$t('Medicare <br> Earnings'),
            prop: 'medicare_earnings',
            component: 'FormattedPrice',
            align: 'right',
          },
          {
            label: this.$t('Medicare <br> W/Held'),
            prop: 'medicare',
            component: 'FormattedPrice',
            align: 'right',
          },
          {
            label: this.$t('Medicare <br> Computed <br>'),
            prop: 'calculated_medicare',
            component: 'FormattedPrice',
            align: 'right',
          },
          {
            label: this.$t('FUI <br> Earnings'),
            prop: 'fui_earnings',
            component: 'FormattedPrice',
            align: 'right',
          },
          {
            label: this.$t('FUI <br> Computed'),
            prop: 'calculated_fui',
            component: 'FormattedPrice',
            align: 'right',
          },
        ]
      },
    },
    methods: {
      composeRows(data) {
        data.forEach(group => {
          const { employee } = group

          this.rows.push({
            header: employee,
          })

          group['masked_ssn'] = employee.masked_ssn

          this.rows.push({
            subtotal: group,
          })
        })

        return this.rows
      },
    },
  }
</script>
