<template>
  <div :class="params.classes || ''">
    {{ formattedPrice }}
  </div>
</template>
<script>
  import { formatPrice } from "@/plugins/formatPrice";
  import { getNodeValue, isSummaryRow } from "@/components/ag-grid/columnUtils";

  export default {
    props: {
      column: {
        type: Object,
        default: () => ({})
      },
      row: {
        type: Object,
        default: () => ({})
      },
      params: {
        type: Object,
        default: () => ({})
      }
    },
    computed: {
      columnParams() {
        return this.column.params || {}
      },
      formattedPrice() {
        let value = getNodeValue({
          params: this.params,
          row: this.row,
          column: this.column,
        })

        const isZeroValue = value === 0 || value === '0.00'
        const isSummary = isSummaryRow(this.params)

        if (value === '') {
          return value
        }

        const hideZero = this.params.hideZero || this.column.hideZero

        if (value === undefined || value === null) {
          return hideZero ? '' : '$0.00'
        }

        if (hideZero && isZeroValue && !isSummary) {
          return ''
        }

        if (this.params.absoluteValue) {
          value = Math.abs(value)
        }
        
        return formatPrice(value, this.columnParams)
      }
    }
  }
</script>
