<template>
  <EquipmentAdjustmentForm :data="data"/>
</template>
<script>
import EquipmentAdjustmentForm from "@/modules/equipment/components/equipment-init/EquipmentAdjustmentForm.vue";

export default {
  components: {
    EquipmentAdjustmentForm,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  }
}
</script>
