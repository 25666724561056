import { differenceInBusinessDays, differenceInMinutes } from "date-fns";
import { costCenterTypes } from "@/components/grid-table/utils/cost-center";
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '@/../tailwind.config'
import {
  getAddlSourceName,
  getAddlSourceType,
  getSourceName,
  getSourceType,
  getSpecialSourceName
} from "@/components/grid-table/utils/cost-center-cell";
import i18n from "@/i18n";
import differenceInDays from "date-fns/differenceInDays";

const fullConfig = resolveConfig(tailwindConfig)

export const TaskStatus = {
  ToDo: 'todo',
  InProgress: 'in-progress',
  Done: 'done',
}

const workingHoursPerDay = 8
export function getEventDuration(event) {
  const { start, end } = event || {}
  if (!end || !start) {
    return 0
  }
  const daysDiff = differenceInBusinessDays(end, start)
  if (daysDiff > 0) {
    return daysDiff * workingHoursPerDay * 60
  }
  return differenceInMinutes(end, start)
}

export function getEventLoggedDuration(event) {
  return event.extendedProps?.attributes?.logged_minutes || 0
}

export function getDurationForEvents(events) {
  let totalMinutes = 0
  events.forEach((event) => {
    totalMinutes += getEventDuration(event)
  })
  return totalMinutes
}

export function getLoggedDurationForEvents(events) {
  let totalMinutes = 0
  events.forEach((event) => {
    totalMinutes += getEventLoggedDuration(event)
  })
  return totalMinutes
}

export function getEventColor(cost_center) {
  const map = {
    null: 'gray',
    [costCenterTypes.Job]: 'primary',
    [costCenterTypes.WorkOrder]: 'indigo',
    [costCenterTypes.Equipment]: 'violet',
  }
  return map[cost_center] || 'primary'
}

export function getColor(color, shade) {
  return fullConfig.theme.colors?.[color]?.[shade]
}
export function getTaskName(model) {
  const cost_center = model.cost_center
  const source_type = getSourceType(cost_center)
  const addl_source_type = getAddlSourceType(cost_center)
  const sourceName = getSourceName({cost_center, source_id: model.source_id, source_type })
  const addlSourceName = getAddlSourceName({ cost_center, addl_source_id: model.addl_source_id, addl_source_type })

  if (model.addl_source_id) {
    return `${sourceName || ''}\n${addlSourceName || ''}`
  }
  if (model.source_id) {
    return sourceName || ''
  }
  if (model.cost_center === costCenterTypes.GeneralAndAdministrative) {
    return i18n.t('General & Administrative')
  }
  return ''
}
