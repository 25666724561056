<template>
  <base-form-dialog
      v-bind="$attrs"
      v-on="$listeners"
      :title="$t('Clone Timecard Batch')"
  >
    <base-form
        layout="modal"
        :model="model"
        :loading="loading"
        @cancel="onCancel"
        @submit="onSubmit"
    >
      <p class="tip col-span-6 mb-8">
        {{ $t('Cloning a timecard batch will create a new batch with the same timecards. The new batch will be in the Pending status.') }}
      </p>
      <div class="col-span-6 md:col-span-2">
        <base-select v-model="model.year"
                     :options="availableYears"
                     :label="$t('Year')"
                     :name="$t('Year')"
                     id="year"
                     rules="required"
        />
      </div>
      <div class="col-span-6 md:col-span-2">
        <period-end-date-picker v-model="model.period_end_date"/>
      </div>
    </base-form>
  </base-form-dialog>
</template>
<script>
import PeriodEndDatePicker from "@/modules/payroll/components/timecard/PeriodEndDatePicker.vue";
import axios from "axios";

export default {
  components: {
    PeriodEndDatePicker
  },
  props: {
    batch: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {
      model: {
        period_end_date: null,
        year: null
      },
      loading: false,
    }
  },
  computed: {
    payrollCalendarYear() {
      const currentYear = new Date().getFullYear()
      return this.$settings(this.$modules.PR, 'calendar_year') || currentYear
    },
    availableYears() {
      return [
        {
          label: this.payrollCalendarYear,
          value: this.payrollCalendarYear,
        },
        {
          label: this.payrollCalendarYear - 1,
          value: this.payrollCalendarYear - 1,
        },
      ]
    },
  },
  methods: {
    onCancel() {
      this.$emit('cancel')
    },
    async onSubmit() {
      try {
        this.loading = true
        await axios.post(`/restify/timecard-batches/${this.batch.id}/actions?action=clone`, {
          year: this.model.year,
          period_end_date: this.model.period_end_date,
        })
        await this.$router.push('/payroll/timecard-batches/pending')
      } catch (err)  {
        if (err.handled) {
          return
        }
        this.$error(this.$t('Failed to clone timecard batch'))
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
