<template>
  <base-filter-row :title="$t('Aged Thru')">
    <base-checkbox
        :value="today"
        :label="$t('Today')"
        id="today"
        @change="triggerTodayChange"
    />
    <base-filter-range class="col-span-4">
      <template #start>
        <base-select
            :value="period"
            :placeholder="$t('Period')"
            :name="$t('Period')"
            :options="periods"
            rules="required"
            label=""
            class="w-1/2"
            clearable
            @change="onChangePeriod"
        />
      </template>
      <template #end>
        <base-input
            :value="fiscal_year"
            :placeholder="$t('Fiscal Year')"
            :name="$t('Fiscal Year')"
            :rules="{
              'min_value': $validator.minFiscalYear,
              'max_value': maxFiscalYear,
            }"
            class="w-1/2"
            inline-errors
            @change="onChangeFiscalYear"
        />
      </template>
    </base-filter-range>
  </base-filter-row>
</template>
<script>

  import store from "@/store";
  import { maxBy } from "lodash";

  export default {
    props: {
      today: [Boolean, String],
      period: [Number, String],
      fiscal_year: [Number, String],
    },
    computed: {
      periods() {
        return this.$store.state.company.periods
      },
      isToday() {
        return this.period === this.$currentPeriod && this.fiscal_year === this.$currentYear
      },
      maxFiscalYear() {
        const fiscalYears = store.state.company.fiscalYears
        const maxFiscalYear = maxBy(fiscalYears, 'attributes.fiscal_year')
        return maxFiscalYear?.attributes?.fiscal_year || this.$currentYear
      },
    },
    methods: {
      triggerTodayChange(value) {
        this.$emit('update:today', value)

        if (!value) {
          return
        }

        this.setTodayDates()
      },
      setTodayDates() {
        this.$emit('update:period', this.$currentPeriod)
        this.$emit('update:fiscal_year', this.$currentYear)
      },
      onChangePeriod(period) {
        this.$emit('update:period', period)
        this.updateTodayValue()
      },
      onChangeFiscalYear(fiscalYear) {
        this.$emit('update:fiscal_year', fiscalYear)
        this.updateTodayValue()
      },
      async updateTodayValue() {
        await this.$nextTick()

        const today = !(this.period.toString() || this.fiscal_year) || this.isToday
        this.$emit('update:today', today)
      },
    },
  }
</script>
