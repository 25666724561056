<template>
  <AgDataTable
      :data-loading="loading"
      :data="entries"
      :compact="true"
      :columns="columns"
      :show-pagination="false"
      :pagination="false"
      domLayout="autoHeight"
      @grid-ready="grid = $event"
  >
    <template #cells-legend>
      <div>
        <div class="flex space-x-2 mt-2 text-gray-600">
          <div class="flex space-x-2 items-center">
            <div class="w-4 h-4 rounded bg-gray-200"></div>
            <div>{{ $t('Read only fields') }}</div>
          </div>
          <div class="flex space-x-2 items-center">
            <div class="w-4 h-4 rounded bg-yellow-100"></div>
            <div>{{ $t('Computed fields') }}</div>
          </div>
          <div class="flex-1 flex justify-end">
            <base-button
              v-if="hasRateOverrides"
              variant="primary-light"
              @click="resetRates"
            >
              {{ $t('Reset Employee Pay Rates') }}
            </base-button>
          </div>
        </div>
        <div v-if="hasRateOverridesWithZero" class="mt-4">
          <base-alert type="danger">
            {{ $t('Some effective rates have been set to zero.') }}
          </base-alert>
        </div>
      </div>
    </template>
  </AgDataTable>
</template>
<script lang="ts" setup>
import i18n from '@/i18n'
import {jobTableOverrides, ratesEnum, reservedPayCodes} from '@/enum/enums'
import {computed, nextTick, ref} from 'vue'
import {Column} from "@/components/ag-grid/tableTypes";
import {cellClasses} from "@/components/ag-grid/columnUtils";
import {priceFormatter} from "@/components/ag-grid/cellFormatters/cellFormatters";
import {
  CellClassParams,
  GridApi,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams
} from '@ag-grid-community/core'
import {cellEditors} from "@/components/ag-grid/cellEditors/cellEditors";
import {getTableData} from "@/components/ag-grid/tableUtils";
import {saveRates} from "@/modules/payroll/components/employee-rates/storeEmployeeRates";
import axios from "axios";
import Cache from "@/utils/Cache";
import WarningTip from "@/modules/payroll/components/WarningTip.vue";

const props = defineProps({
  entries: {
    type: Array,
    default: () => [],
  },
  hasUnionRates: Boolean,
  loading: Boolean,
})
const jobOverrideOptions = [
  {
    label: i18n.t('Always'),
    value: jobTableOverrides.OverrideAlways,
  },
  {
    label: i18n.t('Only if Job Rate is Greater'),
    value: jobTableOverrides.OverrideIfGreater,
  },
  {
    label: i18n.t('Never'),
    value: jobTableOverrides.OverrideNever,
  },
]

const hasRateOverrides = computed(() => {
  return props.hasUnionRates && props.entries.some((entry: any) => entry.id)
})

const hasRateOverridesWithZero = computed(() => {
  return hasRateOverrides.value && props.entries.some((entry: any) => entry.rate === 0)
})

const emit = defineEmits(['refresh'])
async function resetRates() {
  grid.value.api.forEachNode((node: any) => {
    node.data.rate = 0
    node.setData(node.data)
  })
  const data = getData().filter(rate => rate.id && !rate.is_union_rate)
  const promises = data.map(rate => {
    return axios.delete(`/restify/employee-pay-rates/${rate.id}`)
  })
  await Promise.all([promises])
  Cache.removeForEntity('employee-pay-rates')
  emit('refresh')
}

const columns = computed<Column[]>(() => {
  return [
    {
      headerName: i18n.t('Pay Code'),
      field: 'pay_code_label',
      cellClass: cellClasses.ReadOnly
    },
    {
      headerName: i18n.t('Standard Union'),
      field: 'union_rate',
      cellClass: cellClasses.ReadOnly,
      valueFormatter: priceFormatter,
      valueGetter: getUnionRate,
      hide: !props.hasUnionRates,
    },
    {
      headerName: i18n.t('Hourly Rate'),
      field: 'rate',
      valueFormatter: priceFormatter,
      cellEditor: cellEditors.Numeric,
      valueSetter: (params: ValueSetterParams) => {
        const value = +params.newValue
        params.data.rate = value

        const nodes: any[] = getTableNotes(params.api)
        nodes[1].data.rate = value * ratesEnum.OvertimeMultiplier
        nodes[2].data.rate = value * ratesEnum.PremiumMultiplier
        nodes[1].data.dirty = true
        nodes[2].data.dirty = true
        nodes.forEach(node => {
          setNodeData(node)
        })
        return true
      },
      editable: params => {
        return params.data._code === reservedPayCodes.RegularPay
      },
      cellClass: params => {
        const isRegularPay = params.data._code === reservedPayCodes.RegularPay
        if (!isRegularPay) {
          return cellClasses.ReadOnly
        }
      },
    },
    {
      headerName: i18n.t('Effective Rate'),
      data: 'effective_rate',
      valueGetter: (params: ValueGetterParams) => {
        const unionRate = getUnionRate(params)
        const { id, rate } = params.data
        return (id || rate) ? params.data.rate : unionRate
      },
      cellClass: (params: CellClassParams) => {
        const unionRate = getUnionRate(params)
        const {union_rate, rate} = params?.data || {}
        if (unionRate && !union_rate && !rate) {
          return 'bg-yellow-100'
        }
        return cellClasses.ReadOnly
      },
      valueFormatter: priceFormatter,
      hide: !props.hasUnionRates,
    },
    {
      headerName: i18n.t('Allow Job Table Override'),
      field: 'job_can_override_rate',
      valueFormatter: (params: ValueFormatterParams) => {
        return jobOverrideOptions.find((option) => option.value === params.value)?.label
      },
      valueSetter: (params: ValueSetterParams) => {
        params.data.job_can_override_rate = params.newValue
        const nodes: any[] = getTableNotes(params.api)
        nodes[1].data.job_can_override_rate = params.newValue
        nodes[2].data.job_can_override_rate = params.newValue
        nodes[1].data.dirty = true
        nodes[2].data.dirty = true
        nodes.forEach(node => {
          setNodeData(node)
        })
        return true
      },
      editable: params => {
        return params.data._code === reservedPayCodes.RegularPay
      },
      cellClass: params => {
        const isRegularPay = params.data._code === reservedPayCodes.RegularPay
        if (!isRegularPay) {
          return cellClasses.ReadOnly
        }
      },
      cellEditor: cellEditors.BaseSelect,
      cellEditorParams: {
        options: jobOverrideOptions,
      }
    },
  ]
})

function getUnionRate(params: ValueGetterParams | CellClassParams) {
  const isDefined = !!params.data.union_rate
  const nodes: any[] = getTableNotes(params.api)
  const baseRate = nodes[0].data?.union_rate
  if (isDefined || !baseRate) {
    return params.data.union_rate
  }
  const rowCode = params.data._code
  if (rowCode === reservedPayCodes.OvertimePay) {
    return baseRate * ratesEnum.OvertimeMultiplier
  }
  if (rowCode === reservedPayCodes.PremiumPay) {
    return baseRate * ratesEnum.PremiumMultiplier
  }
  return params.data.union_rate

}

function getTableNotes(api: GridApi) {
  const nodes: any[] = []
  api.forEachNode((node: any) => {
    nodes.push(node)
  })
  return nodes
}

function setNodeData(node: any) {
  node.setData(node.data)
}

const grid = ref()

function getData() {
  let tableData = getTableData(grid.value.api)
  tableData = tableData.map(rate => {
    if (!rate.accumulation_interval) {
      rate.accumulation_interval = 'annual'
    }
    if (!rate.limit_on_accumulated_amount) {
      rate.limit_on_accumulated_amount = 0
    }
    if (!rate.period_limit_amount) {
      rate.period_limit_amount = 0
    }
    return rate
  })
  return tableData
}
async function storeProgress() {
  let tableData = getData()
  await saveRates(`/restify/employee-pay-rates`, tableData)
}

defineExpose({
  storeProgress,
})
</script>
