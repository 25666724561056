<template>
  <div class="relative bg-gray-50 overflow-hidden min-h-screen flex flex-col items-center justify-center">
    <div class="relative pb-12 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">
      <div class="mx-auto max-w-screen-xl px-4 mt-8 sm:px-6">
        <div class="text-center">
          <img class="h-24 mx-auto w-auto" src="/img/cp.png" alt="Logo"/>
          <h1 class="page-heading text-4xl sm:text-5xl">
            {{ $t('Employee Consent') }}
            <br class="xl:hidden"/>
            <span class="text-primary-600">{{$t('Opt Out')}}</span>
          </h1>
          <p class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            {{ $t(`You will no longer receive your pay stub electronically via email.`) }}
          </p>
        </div>
      </div>
      <div class="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
        <base-button variant="primary"
                     size="xl"
                     @click="$router.push('/login')">
          {{$t('Login')}}
        </base-button>
      </div>
    </div>
  </div>
</template>
<script>
  export default {}
</script>
