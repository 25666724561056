<template>
  <OrganizationForm :data="organization"
                    @save="getOrganization"
  />
</template>
<script>
import OrganizationService from "@/modules/settings/services/OrganizationService";
import OrganizationForm from "@/modules/settings/components/OrganizationForm";

export default {
  components: {
    OrganizationForm
  },
  data() {
    return {
      organization: {}
    }
  },
  methods: {
    async getOrganization() {
      this.organization = await OrganizationService.getOrganization()
    }
  },
  mounted() {
    this.getOrganization()
  }
}
</script>
