<template>
  <component
      v-bind="$attrs"
      :is="getComponentByFormat"
      :format="format"
  />
</template>
<script>
  import { jobProfitReportFormats } from '@/modules/job-costing/components/reports/util'
  import ByPeriodFormat from '@/modules/job-costing/components/reports/job-profit-summary/formats/ByPeriodFormat'
  import ShortLongFormat from '@/modules/job-costing/components/reports/job-profit-summary/formats/ShortLongFormat'
  import LineItemSummaryFormat
    from '@/modules/job-costing/components/reports/job-profit-summary/formats/LineItemSummaryFormat'

  const componentsMap = {
    [jobProfitReportFormats.Periods]: 'ByPeriodFormat',
    [jobProfitReportFormats.ActivePeriods]: 'ByPeriodFormat',
    [jobProfitReportFormats.Long]: 'ShortLongFormat',
    [jobProfitReportFormats.Short]: 'ShortLongFormat',
    [jobProfitReportFormats.SingleLine]: 'LineItemSummaryFormat',
    default: 'LineItemSummaryFormat',
  }

  export default {
    props: {
      format: {
        required: true,
        type: String,
        default: jobProfitReportFormats.SingleLine,
      },
    },
    components: {
      ByPeriodFormat,
      ShortLongFormat,
      LineItemSummaryFormat,
    },
    computed: {
      getComponentByFormat() {
        return componentsMap[this.format]
      },
    },
  }
</script>
