<template>
  <entity-select
      v-bind="$attrs"
      v-on="$listeners"
      url="/restify/organization-roles"
      :label-format="formatRoleName"
      :label="$attrs.label === undefined ? $t('Role'): $attrs.label"
      :placeholder="$attrs.placeholder === undefined ? $t('Select Role'): $attrs.placeholder"
      :id="$attrs.id || 'organization-role-select'"
      :add-entity="false"
      label-key="name"
      value-key="name"
      ref="select"
  >
  </entity-select>
</template>
<script>
  import startCase from "lodash/startCase";
  import camelCase from "lodash/camelCase";

  export default {
    inheritAttrs: false,
    methods: {
      formatRoleName(option) {
        const name = option.attributes.name
        return startCase(camelCase(name))
      },
      focus() {
        this.$refs.select.focus()
      },
    }
  }
</script>
