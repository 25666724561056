<template>
  <AgDataTable
      :columns="columns"
      :url="url"
      :url-params="urlParams"
      :add-text="$t('New bank')"
      :add-entity-in-new-tab="addEntityInNewTab"
      :enableFillHandle="true"
      :enableRangeSelection="true"
      domLayout="autoHeight"
      actions="search,refresh,view"
      permission="banks"
      ref="table"
  >
    <template #attributes.last_check_no="{row}">
      <div class="flex flex-col">
        <span v-if="row.attributes.last_ap_check_no">
          AP: {{row.attributes.last_ap_check_no}}
        </span>
        <span v-if="row.attributes.last_pr_check_no">
          PR: {{ row.attributes.last_pr_check_no }}
        </span>
      </div>
    </template>
    <template #attributes.last_dd_no="{row}">
      <div class="flex flex-col">
        <span v-if="row.attributes.last_ap_dd_no">
          AP: {{row.attributes.last_ap_dd_no}}
        </span>
        <span v-if="row.attributes.last_pr_dd_no">
          PR: {{ row.attributes.last_pr_dd_no }}
        </span>
      </div>
    </template>
    <template #attributes.used_in="{row}">
      <div>
        <div v-for="option in getUsedOptions(row)"
             :key="option"
        >
          {{option}}
        </div>
      </div>
    </template>
  </AgDataTable>
</template>
<script lang="ts" setup>
  import i18n from '@/i18n'
  import { Column } from '@/components/ag-grid/tableTypes'
  import {getLastCheckNumber, getLastDirectDepositNumber} from "@/modules/settings/utils/bankUtils";
  import {ValueSetterParams} from "@ag-grid-community/core";
  import {BankUsedInOptions} from "@/enum/enums";
  import {Bank} from "@/modules/common/types/models";
  import Data = API.Data;
  import {cellClasses} from "@/components/ag-grid/columnUtils";
  import {valueSetterWithUpdate} from "@/components/ag-grid/cellEditors/cellEditorUtils";
  import {cellEditors} from "@/components/ag-grid/cellEditors/cellEditors";

  const url: string = '/restify/banks'

  const urlParams: Record<string, string> = {
    related: 'addresses,account',
  }

  const addEntityInNewTab: boolean = true

  const columns: Column[] = [
    {
      headerName: i18n.t('Name'),
      field: 'attributes.name',
      component: 'EntityLink',
      redirectTo: '/settings/banks/{ID}/view',
      minWidth: 200,
      maxWidth: 300,
    },
    {
      headerName: i18n.t('Initials'),
      field: 'attributes.initials',
      align: 'center',
      minWidth: 60,
      maxWidth: 80,
    },
    {
      headerName: i18n.t('Address'),
      field: 'relationships.addresses',
      minWidth: 180,
      maxWidth: 300,
      component: 'PrimaryAddressLink',
    },
    {
      headerName: i18n.t('Account'),
      field: 'attributes.account',
      component: 'AccountLink',
      minWidth: 120,
      maxWidth: 140,
      cellRendererParams: {
        showDescription: false,
      }
    },
    {
      headerName: i18n.t('Sub Account'),
      field: 'attributes.subaccount',
      component: 'SubAccountLink',
      minWidth: 110,
      maxWidth: 120,
      cellRendererParams: {
        showDescription: false,
      }
    },
    {
      headerName: i18n.t('Active'),
      field: 'attributes.active',
      component: 'Status',
      minWidth: 120,
      maxWidth: 140,
      editable: true,
      headerClass: cellClasses.HeaderEditable,
      cellEditor: cellEditors.Boolean,
      valueSetter: (params: ValueSetterParams) => {
        return valueSetterWithUpdate({
          storeAction: 'settings/patchBank',
        })(params)
      },
    },
    {
      headerName: i18n.t('Used For'),
      field: 'attributes.used_in',
      minWidth: 200,
      maxWidth: 300,
      autoHeight: true,
    },
    {
      headerName: i18n.t('Last Check Number'),
      field: 'attributes.last_check_no',
      valueGetter: params => {
        return getLastCheckNumber(params.data)
      },
      autoHeight: true,
      minWidth: 120,
      maxWidth: 160,
    },
    {
      headerName: i18n.t('Last DD Number'),
      field: 'attributes.last_dd_no',
      valueGetter: (params: any) => {
        return getLastDirectDepositNumber(params.data)
      },
      minWidth: 120,
      maxWidth: 160,
    },
  ]

  function getUsedOptions(bank: Data<Bank>) {
    return bank.attributes.used_in?.map((x: string) => {
      return BankUsedInOptions.find((option: any) => option.value === x)?.label || x
    })
  }
</script>
