<template>
  <div class="md:grid md:grid-cols-3 md:gap-6 base-form"
       :class="{[`layout-${layout}`]: layout}"
  >
    <slot name="info">
      <div v-if="hasTitle || hasDescription"
           class="md:col-span-1 form-description">
        <div class="px-4 sm:px-0">
          <h4 v-if="hasTitle" class="form-section-title">
            <slot name="title">
              {{ title }}
            </slot>
          </h4>
          <p v-if="hasDescription" class="mt-1 text-sm leading-5 text-gray-600">
            <slot name="description">
              {{ description }}
            </slot>
          </p>
        </div>
      </div>
    </slot>
    <div class="mt-5 md:mt-0 md:col-span-2 form-info">
      <div :style="autoHeight ? {} : formStyles"
           ref="formInner"
           class="shadow overflow-hidden sm:rounded-md form__inner relative"
      >
        <ValidationObserver
          v-slot="{ valid, dirty }"
          ref="observer"
          :disabled="true"
        >
          <span id="formIndicator" :dirty="dirty"/>
          <form :id="formId" @submit.prevent="handleSubmit(onSubmit)" ref="baseForm">
            <div v-if="$slots.header"
                 :class="{
                  'px-4 sm:px-6 pt-6': !noPadding,
                  'no-padding': noPadding,
                  'sticky top-0 z-10': stickyHeader,
                }"
                 class="bg-white">
              <slot name="header"></slot>
            </div>
            <div
              :class="{
                  'px-4 py-5 sm:p-6': !noPadding,
                  'no-padding': noPadding,
                }"
              class="bg-white form-inner form-wrapper">
              <div :class="gridClasses">
                <slot :handleSubmit="handleSubmit"/>
              </div>
            </div>
            <div v-if="showButtons"
                 class="px-4 py-4 flex items-center justify-between sm:px-6 form-footer sticky bottom-0"
                 :class="{'bg-gray-50': footerBackground}">
              <div>
                <template>
                  <slot name="extra-buttons-left"/>
                </template>
                <base-button v-if="showBack"
                             variant="primary-link"
                             class="mr-2"
                             @click="$router.go(-1)">
                  &#8592; {{ $t(` Back`) }}
                </base-button>
                <base-button v-if="showReset"
                             variant="gray-link"
                             class="mr-2"
                             @click="onResetForm">
                  {{ $t(`Reset Form`) }}
                </base-button>
              </div>
              <div class="flex items-center">
                <base-checkbox v-if="canCreateAnotherEntity"
                               :value="$createAnotherEntity"
                               :label="getCreateAnotherLabel"
                               id="create-another"
                               @input="toggleCreateAnotherEntity"
                />
                <slot name="extra-buttons-right"
                      :handle-submit="handleSubmit"
                />
                <slot name="footer"
                      :valid="valid"
                      :dirty="dirty"
                      :loading="loading"
                      :handle-submit="handleSubmit"
                      :submit-disabled="submitDisabled"
                >
                  <template>
                    <base-cancel-button v-if="showCancel"
                                        class="mr-3"
                                        @click="$emit('cancel')">
                      {{ $t(cancelText) }}
                    </base-cancel-button>
                    <base-tooltip :disabled="!submitTooltip" :content="submitTooltip ? submitTooltip : undefined ">
                      <base-submit-button v-if="canSubmitForm"
                                          :loading="loading"
                                          :type="submitButtonType"
                                          :disabled="submitDisabled"
                                          @click="trySubmit(handleSubmit)"
                      >
                        <span class="capitalize">{{ $t(getSaveText) }}</span>
                      </base-submit-button>
                    </base-tooltip>
                  </template>
                  <slot name="extra-buttons"/>
                </slot>
              </div>
            </div>
          </form>
        </ValidationObserver>
        <slot name="additional-section"/>
      </div>
    </div>
  </div>
</template>
<script>
  import { ref } from 'vue'
  import { useMaxAvailableHeight } from '@/modules/common/composables/useMaxAvailableHeight';
  import { isInViewport } from '@/components/form/util';
  import { gridContext } from "@/components/ag-grid/gridContext";
  import { getVisibleForm } from "@/components/form/formUtils";

  export default {
    props: {
      loading: Boolean,
      title: String,
      description: String,
      cancelText: {
        type: String,
        default: 'Cancel',
      },
      saveText: {
        type: String,
        default: '',
      },
      updateText: {
        type: String,
        default: '',
      },
      layout: {
        type: String,
        default: 'default',
      },
      showButtons: {
        type: Boolean,
        default: true,
      },
      showReset: {
        type: Boolean,
        default: false,
      },
      canSubmitForm: {
        type: Boolean,
        default: true,
      },
      submitButtonType: {
        type: String,
        default: 'submit',
      },
      showBack: {
        type: Boolean,
        default: false,
      },
      showCancel: {
        type: Boolean,
        default: false,
      },
      footerBackground: {
        type: Boolean,
        default: true,
      },
      gridClasses: {
        type: String,
        default: 'grid grid-cols-6 gap-x-3',
      },
      submitDisabled: {
        type: Boolean,
        default: false,
      },
      submitTooltip: {
        type: String,
      },
      canCreateAnotherEntity: {
        type: Boolean,
        default: false,
      },
      focusOnFirstInput: {
        type: Boolean,
        default: true,
      },
      noPadding: {
        type: Boolean,
        default: false,
      },
      autoHeight: {
        type: Boolean,
        default: false,
      },
      entity: String,
      stickyHeader: {
        type: Boolean,
      },
    },
    setup() {
      const formInner = ref(null)
      const { styles } = useMaxAvailableHeight(formInner)

      return {
        formInner,
        formStyles: styles,
      }
    },
    data() {
      return {
        isSubmitting: false,
        formId: crypto.randomUUID(),
      }
    },
    computed: {
      getCreateAnotherLabel() {
        if (!this.entity) {
          return this.$t('Create Another')
        }
        return this.$t('Create Another') + ' ' + this.$t(this.entity)
      },
      getSaveText() {
        return this.$formSaveText
      },
      isEdit() {
        const id = this.$parent?.data?.id || this.$parent?.model?.id
        return !!id
      },
      hasTitle() {
        return this.title || this.$slots.title
      },
      hasDescription() {
        return this.description || this.$slots.description
      },
    },
    methods: {
      async handleSubmit(cb) {
        if (this.isSubmitting) {
          return
        }
        this.isSubmitting = true
        const isValid = await this.validate();
        this.isSubmitting = false
        if (isValid) {
          return cb()
        }
        this.$error(this.$t('Please fill in the required fields'))
        const firstError = this.$el.querySelector('.form-item-error')
        if (!firstError) {
          return
        }
        const isVisible = firstError && isInViewport(firstError)

        if (!isVisible) {
          firstError?.scrollIntoView({
            block: 'end',
          })
        }

      },
      trySubmit(handleSubmit) {
        // forms is submitted automatically in this case
        if (this.submitButtonType === 'submit') {
          return
        }
        handleSubmit(this.onSubmit)
      },
      onSubmit(evt) {
        this.stopTableEditing()
        this.$emit('submit', evt)
        this.resetValidation()
      },
      stopTableEditing() {
        if (!gridContext.api) {
          return
        }
        // gridContext.api?.stopEditing() // TODO this caused some strange issues when opening a dialog form from within the table
      },
      toggleCreateAnotherEntity(value) {
        this.$store.dispatch('user/toggleCreateAnotherEntity', value)
      },
      async validate() {
        return await this.$refs?.observer?.validate()
      },
      async resetValidation() {
        await this.$nextTick()
        const form = this.$refs.observer
        if (!form) {
          return
        }
        form.reset()
      },
      focusOnFirstFormInput() {
        setTimeout(() => {
          let firstInput = ''
          if (!document.querySelector('.form-dialog')) {
            firstInput = this.$el.querySelector('.base-form input:enabled')
          } else {
            firstInput = this.$el.querySelector('.form-dialog .base-form input:enabled')
          }

          if (firstInput && this.focusOnFirstInput && !this.isEdit) {
            firstInput.focus()
          }
        }, 500)
      },
      onResetForm() {
        const form = this.$refs.baseForm
        if (!form) {
          return
        }
        form.reset()
      },
      onKeyDown(event) {
        const isCommandEnter = event.code === 'Enter' && (event.metaKey || event.ctrlKey)
        if (!isCommandEnter) {
          return
        }
        const targetForm = event.target?.closest('form')
        const visibleForm = getVisibleForm()
        if (targetForm?.id !== this.formId && visibleForm?.id !== this.formId) {
          return
        }
        this.handleSubmit(this.onSubmit)
      }
    },
    mounted() {
      document.addEventListener('keydown', this.onKeyDown)
      this.focusOnFirstFormInput()
    },
    beforeDestroy() {
      document.removeEventListener('keydown', this.onKeyDown)
    }
  }
</script>
<style lang="scss" scoped>
  .form__inner {
    @apply overflow-auto;
  }

  .form-footer {
    @apply shadow;
    position: sticky;
    z-index: 1000;
  }

  .layout-modal {
    .form-description {
      @apply hidden;
    }

    .form-info {
      @apply col-span-3;
    }

    .form__inner {
      @apply shadow-none;
    }

    .form-wrapper {
      @media (min-width: 640px) {
        max-height: 80vh !important;
        overflow-y: auto;
      }
    }
  }

  .layout-vertical {
    .form-info,
    .form-description {
      @apply col-span-3;
    }
  }
</style>
<style>
  .wizard .form__inner {
    max-height: 100% !important;
  }
</style>
