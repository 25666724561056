<template>
  <base-select
    v-bind="$attrs"
    v-on="$listeners"
    :options="availableStates"
    :table-columns="tableColumns"
    :label-format="formatLabel"
    :placeholder="$t('Select State')"
  />
</template>
<script>
  import { stateList } from '@/enum/state'

  export default {
    inheritAttrs: false,
    model: {
      prop: 'value',
      event: 'change',
    },
    props: {
      filterMethod: {
        type: Function,
      },
    },
    data() {
      return {
        tableColumns: [
          {
            minWidth: 80,
            maxWidth: 80,
            name: this.$t('Code'),
            prop: 'label',
          },
          {
            minWidth: 150,
            maxWidth: 150,
            name: this.$t('Description'),
            prop: 'description',
          },
        ],
        stateList,
      }
    },
    computed: {
      availableStates() {
        if (this.filterMethod) {
          return this.stateList.filter(this.filterMethod)
        }

        return this.stateList
      },
    },
    methods: {
      formatLabel(state) {
        return `${state.label} (${state.description})`
      },
    },
  }
</script>
