<template>
  <div>
    <BaseTabs
        :value="activeTab"
        @input="onTabChange"
        :tabs="tabs"
        labelKey="label"
    >
      <AgDataTable
          v-if="activeTab === costTypes.Cost"
          v-bind="gridSettings(costTypes.Cost)"
          ref="gridTable"
          key="costTable"
          @grid-ready="grid = $event"
          @cell-value-changed="onCellValueChanged"
      >
      </AgDataTable>
      <AgDataTable
          v-else
          v-bind="gridSettings(costTypes.Income)"
          ref="gridTable"
          key="incomeTable"
          @grid-ready="grid = $event"
          @cell-value-changed="onCellValueChanged"
      >
      </AgDataTable>
    </BaseTabs>
  </div>
</template>
<script lang="ts">
  import Data = API.Data;
  import { defineComponent } from 'vue'
  import { costTypes } from '@/enum/enums'
  import { Column } from '@/components/ag-grid/tableTypes'
  import { cellEditors } from '@/components/ag-grid/cellEditors/cellEditors'
  import { GridApi, GridReadyEvent, ICellEditorParams, ValueSetterParams} from '@ag-grid-community/core'
  import {
    editableTableProps,
    getTableData,
    storeBatchEntriesProgress,
    validateAgDataTable
  } from '@/components/ag-grid/tableUtils'
  import { accountCellProps, subAccountCellProps } from '@/components/ag-grid/columns/editableColumns'
  import {
    cellClasses,
    getCellClasses,
    markupValidator,
    requiredValueSetter, stopEditingOnTab
  } from '@/components/ag-grid/columnUtils'
  import { getDeleteColumn } from "@/components/ag-grid/columns/deleteColumns";
  import {JobTypeFor, JobTypeForLabels, JobTypeForOptions} from "@/modules/job-costing/enum/jobs";

  interface JobTypeModel {
    id?: string
    _localId?: string
    type: string
    name: string
    abbr: string
    for: string
    debit_account: string | null
    debit_subaccount: string | null
    credit_account: string | null
    credit_subaccount: string | null
    markup_percent: number
    readonly: boolean
  }


  export default defineComponent({
    data() {
      return {
        grid: null as GridReadyEvent | null,
        costTypes,
        activeTab: costTypes.Cost as string,
        tabs: [
          {
            name: costTypes.Cost,
            label: this.$t('Cost Types'),
          },
          {
            name: costTypes.Income,
            label: this.$t('Income Types'),
          },
        ],
      }
    },
    computed: {
      accountColumnsMap() {
        return {
          [costTypes.Income]: [
            {
              headerName: this.$t('Income Account'),
              field: 'credit_account',
              ...accountCellProps,
              cellClass: getCellClasses,
            },
            {
              headerName: this.$t('Sub'),
              field: 'credit_subaccount',
              ...subAccountCellProps,
            },
            {
              headerName: this.$t('Debit Account'),
              field: 'debit_account',
              ...accountCellProps,
            },
            {
              headerName: this.$t('Sub'),
              field: 'debit_subaccount',
              ...subAccountCellProps,
              suppressKeyboardEvent: stopEditingOnTab,
            },
          ] as Column[],
          [costTypes.Cost]: [
            {
              headerName: this.$t('Expense Account'),
              field: 'debit_account',
              ...accountCellProps,
              cellClass: getCellClasses,
            },
            {
              headerName: this.$t('Sub'),
              field: 'debit_subaccount',
              ...subAccountCellProps,
            },
            {
              headerName: this.$t('Credit Account'),
              field: 'credit_account',
              ...accountCellProps,
              cellClass: getCellClasses,
            },
            {
              headerName: this.$t('Sub'),
              field: 'credit_subaccount',
              ...subAccountCellProps,
            },
            {
              headerName: this.$t('Subject to Retention'),
              field: 'subject_to_retention',
              component: 'Status',
              cellEditor: cellEditors.Boolean,
              editable: true,
              minWidth: 80,
              maxWidth: 100,
            },
            {
              headerName: this.$t('Subject to Tax'),
              field: 'subject_to_tax',
              component: 'Status',
              cellEditor: cellEditors.Boolean,
              editable: true,
              minWidth: 80,
              maxWidth: 100,
            },
            {
              headerName: this.$t('Markup Subject to Tax'),
              field: 'markup_subject_to_tax',
              component: 'Status',
              cellEditor: cellEditors.Boolean,
              editable: true,
              minWidth: 80,
              maxWidth: 100,
            },
            {
              headerName: this.$t('Profit Subject to Tax'),
              field: 'profit_subject_to_tax',
              component: 'Status',
              cellEditor: cellEditors.Boolean,
              editable: true,
              minWidth: 80,
              maxWidth: 100,
            },
            {
              headerName: this.$t('Markup'),
              field: 'markup_percent',
              component: 'FormattedPercent',
              cellEditor: cellEditors.Numeric,
              valueSetter: params => {
                return requiredValueSetter(params, 0, markupValidator)
              },
              editable: true,
              minWidth: 100,
              maxWidth: 120,
            },
            {
              headerName: this.$t('Profit'),
              field: 'profit_percent',
              component: 'FormattedPercent',
              cellEditor: cellEditors.Numeric,
              valueSetter: params => {
                return requiredValueSetter(params, 0, markupValidator)
              },
              suppressKeyboardEvent: stopEditingOnTab,
              editable: true,
              minWidth: 100,
              maxWidth: 120,
            },
          ] as Column[]
        }
      },
    },
    methods: {
      async onTabChange(tab: string) {
        const confirmed = await this.showUnsavedWarning(tab)

        if (confirmed) {
          this.activeTab = tab
        }
      },
      async showUnsavedWarning(tab?: string) {
        const data = getTableData(this.grid?.api)
        const hasUnsavedData = this.hasUnsavedData(data)
        if (!hasUnsavedData) {
          return true
        }
        if (tab && !hasUnsavedData) {
          this.activeTab = tab
          return true
        }

        const type = this.activeTab === costTypes.Cost ? this.$t('Cost') : this.$t('Income')
        return await this.$confirm({
          title: this.$t('Unsaved Changes'),
          description: this.$t(`The changes made under the ${type} Types table will be lost unless you don't click the Update button. Are you sure you want to continue?`),
        })
      },
      hasUnsavedData(data: any[]) {
        return data.some(row => row.dirty)
      },
      gridSettings(type: string) {

        const urlParams = {
          type,
          sort: 'index',
        }

        return {
          urlParams,
          ...editableTableProps,
          url: `/restify/job-types`,
          actions: 'add,search,refresh',
          showPagination: true,
          defaultFilters: false,
          addText: this.$t(`New ${type} type`),
          getEmptyRow: () : JobTypeModel => {
            const commonDefaults = {
              _localId: crypto.randomUUID(),
              type,
              abbr: '',
              for: JobTypeFor.Income.Generic,
              name: '',
              credit_account: null,
              credit_subaccount: null,
              debit_account: null,
              debit_subaccount: null,
              markup_percent: 0,
              profit_percent: 0,
              readonly: false,
            }

            const costTypeDefaults = {
              subject_to_retention: false,
              subject_to_tax: false,
              markup_subject_to_tax: false,
              profit_subject_to_tax: false,
            }

            const incomeTypeDefaults = {
            }

            return {
              ...commonDefaults,
              ...(type === costTypes.Cost ? costTypeDefaults : incomeTypeDefaults),
            }
          },
          transformData: (data: Data<any>[]) => {
            return data.map(row => {
              return {
                ...row.attributes,
                meta: row.meta,
              }
            })
          },
          columns: [
            {
              headerName: this.$t('Name'),
              field: 'name',
              editable: true,
              minWidth: 150,
              maxWidth: 200,
            },
            {
              headerName: this.$t('Abbr'),
              field: 'abbr',
              editable: true,
              minWidth: 60,
              maxWidth: 100,
            },
            {
              headerName: this.$t('Used For'),
              field: 'for',
              editable: true,
              cellEditor: cellEditors.BaseSelect,
              cellEditorParams: (params: ICellEditorParams) => {
                let options = JobTypeForOptions.Cost
                if (type === costTypes.Income) {
                  options = JobTypeForOptions.Income
                }
                return {
                  options
                }
              },
              valueFormatter: params => {
                if (type === costTypes.Income) {
                  return JobTypeForLabels.Income[params.value] || JobTypeForLabels.Income[JobTypeFor.Income.Generic]
                }
                return JobTypeForLabels.Cost[params.value] || JobTypeForLabels.Cost[JobTypeFor.Cost.Generic]
              },
              minWidth: 90,
              maxWidth: 140,
            },
            ...this.accountColumnsMap[type],
            {
              ...getDeleteColumn({
                url: '/restify/job-types',
              } as any),
            },
          ] as Column[]
        }
      },
      onCellValueChanged(params: ValueSetterParams) {
        if (!params.newValue) {
          return true
        }

        const field = params?.colDef?.field
        const entry = params.data

        if (field === 'credit_account' && !entry.debit_account) {
          entry.debit_account = params.newValue
          return
        }
        if (field === 'debit_account' && !entry.credit_account) {
          entry.credit_account = params.newValue
        }

        params?.node?.setData(entry)
        return true
      },
      async storeProgress() {
        const gridApi = this?.grid?.api as GridApi
        await storeBatchEntriesProgress(gridApi, 'job-types')
        this.refreshData()
      },
      refreshData() {
        this.$refs.gridTable?.refresh()
      },
      async save() {
        const isInvalidData = await validateAgDataTable()
        if (!isInvalidData) {
          await this.storeProgress()
        }

        return !isInvalidData
      },
    },
  })
</script>
