<template>
  <base-filter-row :title="$t('Departments')">
    <base-filter-range :class="gridClass">
      <template #start>
        <department-select
            v-model="value.department_code_from"
            :add-entity="false"
            :placeholder="$t('Department From')"
            :hide-label="true"
            label=""
            value-key="code"
            clearable
            @blur="onBlurStartRange"
        />
      </template>
      <template #end>
        <department-select
            v-model="value.department_code_to"
            :add-entity="false"
            :placeholder="$t('Department To')"
            :hide-label="true"
            label=""
            :rules="value.department_code_from ? 'required' : ''"
            value-key="code"
            clearable
        />
      </template>
    </base-filter-range>
  </base-filter-row>
</template>
<script>
  export default {
    props: {
      value: {
        type: Object,
        default: () => ({
          department_code_from: null,
          department_code_to: null,
        }),
      },
      gridClass: {
        type: String,
        default: 'col-span-4',
      },
    },
    methods: {
      onBlurStartRange() {
        this.value.department_code_to = this.value.department_code_to || this.value.department_code_from
      },
    },
  }
</script>
