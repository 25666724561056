export enum DirectDepositAccountTypes {
  CHECKING = 'checking',
  SAVINGS = 'savings',
}

export enum DirectDepositStatuses {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PRE_NOTICE = 'pre-notice',
}
