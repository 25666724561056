<template>
  <div>
    <base-report-form
        :filters="model"
        :report-number="reportNumber"
        divider-class="col-span-6"
    >
      <template #filters>
        <base-filter-row
          :title="$t('Format')"
          class="col-span-6"
        >
          <base-select
            v-model="model.format"
            :options="formatOptions"
            :name="$t('Format')"
            rules="required"
            class="col-span-3"
          />
        </base-filter-row>
        <base-filter-row
            :title="$t('Sort By')"
            class="col-span-6"
        >
          <base-select
              v-model="model.sort_by"
              :options="sortByOptions"
              :name="$t('Sort By')"
              rules="required"
              class="col-span-3"
          />
        </base-filter-row>
        <CustomerRangeFilter v-model="model" class="col-span-6"/>
        <WorkOrderRangeFilter v-model="model" class="col-span-6"/>
        <ServiceCodeRangeFilter v-model="model" class="col-span-6"/>
        <PeriodYearRangeFilter v-model="model" class="col-span-6"/>
        <base-filter-row
          :title="$t('Type & Category')"
          class="col-span-6"
        >
          <base-select
            v-model="model.include"
            :options="includeOptions"
            :name="$t('Include')"
            :placeholder="$t('Include')"
            rules="required"
            class="col-span-2"
          />
          <ServiceBillingCategorySelect
            v-model="model.category_id"
            :hide-label="true"
            clearable
            class="col-span-2"
          />
        </base-filter-row>
        <base-filter-row
            :title="$t('Worksite & Work Order Status')"
            class="col-span-6"
        >
          <base-input
              v-model="model.site_number"
              :name="$t('Worksite #')"
              :placeholder="$t('Worksite #')"
              class="col-span-2"
          />
          <base-select
              v-model="model.work_order_statuses"
              :options="workOrderStatusOptions"
              :name="$t('Work Order Status')"
              :placeholder="$t('Work Order Status')"
              multiple
              class="col-span-2"
          />
        </base-filter-row>

      </template>
      <template #table="{loading, data}">
        <WorkOrderDetailsReportTable
            :data="data"
            :data-loading="loading"
            :format="model.format"
            :filters="model"
            :report-number="reportNumber"
            ref="reportTable"
        />
      </template>
    </base-report-form>
  </div>
</template>
<script>
import CustomerRangeFilter from "@/components/range-filters/CustomerRangeFilter.vue";
import WorkOrderRangeFilter from "@/components/range-filters/WorkOrderRangeFilter.vue";
import ServiceCodeRangeFilter from "@/components/range-filters/ServiceCodeRangeFilter.vue";
import { PeriodYearRangeFilter } from "@/components/range-filters";
import {
  getIncludeOptions,
  getWorkOrderDetailSortOptions,
  getWorkOrderStatusOptions,
  IncludeOptions,
  WODetailSortBy,
  WorkOrderDetailFormats,
} from "@/modules/service-billing/components/reports/sortByOptions";
import DateRangeFilter from "@/components/range-filters/DateRangeFilter.vue";
import WorkOrderListingReportTable from "@/modules/service-billing/components/reports/WorkOrderListingReportTable.vue";
import WorkOrderDetailsReportTable from "@/modules/service-billing/components/reports/WorkOrderDetailsReportTable.vue";

export default {
  components: {
    WorkOrderDetailsReportTable,
    WorkOrderListingReportTable,
    DateRangeFilter,
    PeriodYearRangeFilter,
    ServiceCodeRangeFilter,
    WorkOrderRangeFilter,
    CustomerRangeFilter,
  },
  data() {
    return {
      model: {
        sort_by: WODetailSortBy.WorkOrder,
        format: WorkOrderDetailFormats.Detail,
        customer_code_from: null,
        customer_code_to: null,
        work_order_number_from: null,
        work_order_number_to: null,
        service_billing_code_from: null,
        service_billing_code_to: null,
        period_start: null,
        fiscal_year_start: null,
        period_end: null,
        fiscal_year_end: null,
        include: IncludeOptions.All,
        category_id: null,
        site_number: null,
        one_wo_per_page: false,
        work_order_statuses: []
      }
    }
  },
  computed: {
    sortByOptions() {
      return getWorkOrderDetailSortOptions()
    },
    formatOptions() {
      return [
        { label: this.$t('Detail'), value: WorkOrderDetailFormats.Detail },
        { label: this.$t('Work Order Totals'), value: WorkOrderDetailFormats.WorkOrderTotals },
        { label: this.$t('Summary Totals'), value: WorkOrderDetailFormats.SummaryTotals },
      ]
    },
    workOrderStatusOptions() {
      return getWorkOrderStatusOptions()
    },
    includeOptions() {
      return getIncludeOptions()
    },
    reportNumber() {
      return '5581'
    },
  },
}
</script>
