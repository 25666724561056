<template>
  <BaseTooltip
    :content="$t('Application Version')"
    class="flex text-gray-400 text-xs pr-2"
  >
    <div class="space-x-1">
      <span>{{ version }}</span>
      <span v-if="!isProd">({{ $t('Beta') }})</span>
    </div>
  </BaseTooltip>
</template>
<script>
import { isProduction } from "@/isProduction";
export default {
  data() {
    return {
      version: __APP_VERSION__,
    }
  },
  computed: {
    isProd() {
      return isProduction()
    }
  },
}
</script>
