<template>
  <div>
    <div class="mb-5 grid gap-5 grid-cols-4">
      <base-stats-card icon="IconBank" :title="$t('Account Type')">
        {{ formattedText(bank.attributes.account_type) }}
      </base-stats-card>
      <base-stats-card icon="IconOpenAmount" :title="$t('Positive Pay Format')">
        <span class="uppercase">
          {{ formattedText(bank.attributes.positive_pay_format) }}
        </span>
      </base-stats-card>
      <BaseStatsCard
        icon="IconPayroll"
        :title="$t('Last Check')"
        class="relative"
      >
        <BaseButton
          v-if="canEditNumbers"
          variant="primary-light"
          size="xs"
          class="absolute right-2 top-2"
          @click="onEditLastNumber('last-check-number')"
        >
          <EditIcon class="w-4 h-4 mr-1"/>
          {{ $t('Edit') }}
        </BaseButton>
        <div class="flex flex-col mt-1">
          <span v-if="bank.attributes.last_ap_check_no">
            AP: {{ bank.attributes.last_ap_check_no }}
          </span>
          <span v-if="bank.attributes.last_pr_check_no">
            PR: {{ bank.attributes.last_pr_check_no }}
          </span>
        </div>
      </BaseStatsCard>
      <BaseStatsCard
        icon="IconCheckBatches"
        :title="$t('Last Direct Deposit')"
        class="relative"
      >
        <BaseButton
          v-if="canEditNumbers"
          variant="primary-light"
          size="xs"
          class="absolute right-2 top-2"
          @click="onEditLastNumber('last-direct-deposit-number')"
        >
          <EditIcon class="w-4 h-4 mr-1"/>
          {{ $t('Edit') }}
        </BaseButton>
        {{ lastDirectDepositNumber }}
      </BaseStatsCard>
    </div>
    <base-detail-card :title="$t('Bank Details')"
                      :key="bank.id"
                      :entity="bank"
                      :can-expand="false"
                      :expanded="true"
                      entity-name="bank"
    >
      <base-detail-card-row :title="$t('Name')"
                            field="name"
                            stripe>
        {{ bank.attributes.name }}
      </base-detail-card-row>

      <base-detail-card-row :title="$t('Account / Sub account')">
        <div class="flex items-center space-x-2">
        <AccountLink :number="bank.attributes.account"/>
        <template v-if="bank.attributes.subaccount">
          <span>/</span>
          <SubAccountLink :number="bank.attributes.subaccount"/>
        </template>
        </div>
      </base-detail-card-row>

      <base-detail-card-row :title="$t('Email')"
                            field="email"
                            stripe>
        <base-email-link :value="bank.attributes.email"/>
      </base-detail-card-row>

      <base-detail-card-row :title="$t('Fax')"
                            field="fax">
        <base-email-link :value="bank.attributes.fax"/>
      </base-detail-card-row>

      <base-detail-card-row :title="$t('Phone')"
                            field="phone"
                            stripe>
        <base-phone-link :value="bank.attributes.phone"/>
      </base-detail-card-row>

      <base-detail-card-row :title="$t('Address')">
        <base-address-link :value="bankAddress"/>
      </base-detail-card-row>

      <base-detail-card-row :title="$t('Direct Deposit')" stripe>
        <status-badge :status="bank.attributes.is_for_dd"/>
      </base-detail-card-row>

      <base-detail-card-row :title="$t('Print Checks')">
        <status-badge :status="bank.attributes.is_for_micr"/>
      </base-detail-card-row>

      <base-detail-card-row :title="$t('Positive Pay')"
                            stripe>
        <status-badge :status="bank.attributes.is_for_positive_pay"/>
      </base-detail-card-row>

    </base-detail-card>
    <base-back-link to="/settings/banks" />
    <BankLastNumberEditDialog
      v-if="editLastNumberModel.showDialog"
      :open.sync="editLastNumberModel.showDialog"
      :bank="bank"
      :appendToBody="true"
      :field="editLastNumberModel.field"
      @close="editLastNumberModel.showDialog = false"
    />
  </div>
</template>
<script>
import { formattedText } from '@/utils/utils'
import { getLastCheckNumber, getLastDirectDepositNumber } from "@/modules/settings/utils/bankUtils";
import { BankUsedInTypes } from '@/enum/enums'
import { EditIcon } from 'vue-feather-icons';
import BankLastNumberEditDialog from "@/modules/settings/components/BankLastNumberEditDialog.vue";

export default {
  components: {
    EditIcon,
    BankLastNumberEditDialog
  },
  data() {
    return {
      editLastNumberModel: {
        showDialog: false,
        field: '',
      }
    }
  },
  computed: {
    bank() {
      return this.$store.state.settings.currentBank
    },
    bankAddress() {
      return this.get(this.bank, 'relationships.addresses[0]', '')
    },
    lastDirectDepositNumber() {
      return getLastDirectDepositNumber(this.bank)
    },
    canEditNumbers() {
      if (!this.bank.meta.authorizedToUpdate) {
        return false
      }

      const bankModules = this.bank.attributes.used_in || []
      return [
        BankUsedInTypes.AccountsPayable,
        BankUsedInTypes.Payroll,
      ].some(module => bankModules.includes(module))
    }
  },
  methods: {
    getLastCheckNumber,
    formattedText,
    onEditLastNumber(field)  {
      this.editLastNumberModel.field = field
      this.editLastNumberModel.showDialog = true
    }
  },
}
</script>
