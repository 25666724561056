<template>
  <div class="import-data mt-4">
    <ImportForm
        v-bind="importSettings"
        @success="redirectToList"
    />
  </div>
</template>
<script>
  import ImportForm from '@/modules/common/components/import/ImportForm';
  import { getInitialMapping } from '@/modules/payroll/utils/timecardImportColumns';

  const colMappings = {
    Code: {
      value: 'code',
      label: 'Code',
      alternativeLabels: ['Benefit Code'],
      required: true,
    },
    Description: {
      value: 'description',
      label: 'Description',
    },
    CalculateBy: {
      value: 'calculate_by',
      label: 'Calculate By',
      alternativeLabels: ['Calculated', 'Calculate By'],
      required: true,
    },
    MultiplyBy: {
      value: 'multiply_by',
      label: 'Multiply By',
      alternativeLabels: ['Multiplied', 'Multiplied By'],
      required: true,
    },
    Account: {
      value: 'account',
      label: 'Account',
      alternativeLabels: ['Account No.', 'Account Number', 'GL Account', 'Liability Account'],
    },
    SubAccount: {
      value: 'subaccount',
      label: 'Sub Account',
      alternativeLabels: ['Sub Account No.', 'Sub Account Number', 'GL Sub Acct'],
    },
  }

  const options = [
    colMappings.Code,
    colMappings.Description,
    colMappings.CalculateBy,
    colMappings.MultiplyBy,
    colMappings.Account,
    colMappings.SubAccount,
  ]

  export default {
    components: {
      ImportForm,
    },
    data() {
      return {
        importSettings: {
          url: '/restify/deduction-codes/import',
          columnOptions: options,
          columnMappings: colMappings,
          initialMapping: getInitialMapping(options),
          type: 'deduction-code-import',
          resource: 'deduction-codes',
        },
      }
    },
    methods: {
      async redirectToList() {
        await this.$router.push('/payroll/codes/deduction-codes')
      },
    },
  }
</script>
