<template>
  <DepreciationComputeForm/>
</template>
<script>
  import DepreciationComputeForm from '@/modules/equipment/components/DepreciationComputeForm'

  export default {
    components: {
      DepreciationComputeForm,
    },
  }
</script>
