<template>
  <div class="payroll-summary">
    <h5 class="form-section-title mt-2 print:mt-1">
      {{ $t('Pay / Benefit / Deduction Summary') }}
    </h5>
    <div class="grid grid-cols-1 gap-4">
      <div
          v-for="category in nonEmptyCategories"
          :key="category.category"
          class="payroll-summary-category"
      >
        <BaseDataTable
            :columns="getCategoryColumns(category)"
            :data="category.items"
            :limit-max-height="false"
            :data-loading="loading"
            :title="category.category"
            v-bind="tableProps"
            class="h-full relative"
        >
          <template #total_summary>
                <span class="font-medium text-base print:text-print">
                  {{ $formatPrice(category.total) }}
                </span>
          </template>
        </BaseDataTable>
      </div>
    </div>

    <h5 class="form-section-title mt-6 print:mt-4">
      {{ $t('Tax Summary') }}
    </h5>
    <div class="grid grid-cols-1 gap-4">
      <div class="payroll-summary-category">
        <BaseDataTable
            :columns="taxColumns"
            :data="get(summaryData, 'taxes.federal[0].items', [])"
            :limit-max-height="false"
            :data-loading="loading"
            :title="$t('Federal Taxes')"
            v-bind="tableProps"
            class="h-full relative"
        >
          <template #name_summary>
            <span class="text-sm print:text-print">{{ $t('Unemployment: ') }}</span>
            <span class="font-medium text-sm print:text-print">
                   {{ $formatPrice(get(summaryData, 'taxes.federal[0].totals.unemployment', 0)) }}
                </span>
          </template>
          <template #total_summary>
                  <span class="font-medium text-base print:text-print">
                    {{ $formatPrice(get(summaryData, 'taxes.federal[0].totals.total', 0)) }}
                  </span>
          </template>
        </BaseDataTable>
      </div>
      <div
          v-for="category in get(summaryData, 'taxes.state', [])"
          :key="category.category"
          class="payroll-summary-category"
      >
        <BaseDataTable
            :columns="taxColumns"
            :data="category.items"
            :limit-max-height="false"
            :data-loading="loading"
            :title="category.category"
            v-bind="tableProps"
            class="h-full relative"
        >
          <template #name_summary>
            <span class="text-sm print:text-print">{{ $t('Unemployment: ') }}</span>
            <span class="font-medium text-sm print:text-print">
                   {{ $formatPrice(category.totals.unemployment) }}
                </span>
          </template>

          <template #total_summary>
                <span class="font-medium text-base print:text-print">
                  {{ $formatPrice(category.totals.total) }}
                </span>
          </template>
        </BaseDataTable>
      </div>
    </div>
    <div class="break-inside-avoid">
      <h5 class="form-section-title mt-6 print:mt-4">
        {{ $t('Account Summary') }}
      </h5>
      <BaseDataTable
          :columns="accountSummaryColumns"
          :data="get(summaryData, 'accounts.items', [])"
          :limit-max-height="false"
          :data-loading="loading"
          v-bind="tableProps"
      >
        <template #credit_summary>
            <span class="font-medium text-base print:text-print">
              {{ $formatPrice(get(summaryData, 'accounts.totals.credit')) }}
            </span>
        </template>
        <template #debit_summary>
            <span class="font-medium text-base print:text-print">
              {{ $formatPrice(get(summaryData, 'accounts.totals.debit')) }}
            </span>
        </template>
      </BaseDataTable>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'

  const TotalTypes = {
    Credit: 'credit',
    Debit: 'debit',
  }
  export default {
    name: 'PayrollSummary',
    data() {
      return {
        loading: false,
        summaryData: {
          items: [],
          totals: {}
        },
        TotalTypes
      }
    },
    computed: {
      tableProps() {
        return {
          hideTableTopSection: true,
          limitMaxHeight: false,
          compact: true,
          noBorders: true,
          hasSummary: true,
          forceSummary: true,
          showPagination: false,
        }
      },
      batchId() {
        return this.$route.params.id
      },
      nonEmptyCategories() {
        const categories = this.get(this.summaryData, 'categories.categories', [])
        return categories.filter(c => c.items.length > 0)
      },
      taxColumns() {
        return [
          {
            label: this.$t('Name'),
            prop: 'name',
          },
          {
            label: this.$t('Total'),
            prop: 'total',
            align: 'right',
            component: 'FormattedPrice',
          },
        ]
      },
      accountSummaryColumns() {
        return [
          {
            label: this.$t('Account'),
            prop: 'account',
            component: 'AccountLink',
          },
          {
            label: this.$t('Sub Account'),
            prop: 'subaccount',
          },
          {
            label: this.$t('Description'),
            prop: 'account_description',
            minWidth: 80,
            maxWidth: 180,
          },
          {
            label: this.$t('Debit'),
            prop: 'debit',
            align: 'right',
            component: 'FormattedPrice',
          },
          {
            label: this.$t('Credit'),
            prop: 'credit',
            align: 'right',
            component: 'FormattedPrice',
          },
        ]
      }
    },
    methods: {
      getCategoryColumns(category) {
        let categoryName = category.category
        function showQuantity() {
          return !['Tax deductions', 'Employer taxes', 'Insurance'].includes(categoryName)
        }
        let columns = [
          {
            label: this.$t('Code'),
            prop: 'code',
            width: 80,
          },
          {
            label: this.$t('Description'),
            prop: 'description',
            width: 250,
          },
          {
            label: this.$t('State'),
            prop: 'state',
            width: 70,
          },
          {
            label: this.$t('Local'),
            prop: 'local',
            width: 70,
          },
          {
            label: this.$t('Quantity'),
            prop: 'quantity',
            align: 'right',
            width: 70,
            enabled: showQuantity,
          },
          {
            label: this.$t('Total'),
            prop: 'total',
            align: 'right',
            component: 'FormattedPrice',
            minWidth: 100,
          },
        ]
        return columns.filter(c => {
          if (c.enabled) {
            return c.enabled()
          }
          return true
        })
      },
      async getSummaryData() {
        try {
          this.loading = true
          const { data } = await axios.get(`/restify/payroll-batches/${this.batchId}/summary`)
          this.summaryData = data
        } finally {
          this.loading = false
        }
      },
    },
    mounted() {
      this.getSummaryData()
    }
  }
</script>
<style lang="scss">
  .payroll-summary {
    --row-height: 29px;
    @media print {
      --row-height: 22px;
    }
    .payroll-summary-category {
      padding-bottom: calc(var(--row-height));
      height: calc(100% + var(--row-height));
      min-height: calc(100% + var(--row-height));
      @apply break-inside-avoid;

      .table-container {
        position: inherit;
      }

      .summary-row {
        @apply absolute w-full bottom-0;
        display: table;
      }
    }
  }
</style>
