<template>
  <div class="html-cell">
    <html-editor
        v-model="selectedValue"
        :focusOnLoad="true"
        :config="htmlConfig"
        inline-errors
        class="w-full"
        @enter="onEnter"
    />
  </div>
</template>
<script>

  import { selectEditorMixin } from "@/components/ag-grid/cellEditors/selectEditorMixin";

  export default {
    name: 'NumericCell',
    mixins: [selectEditorMixin],
    data() {
      return {
        htmlConfig: {
          plugins: ['fontcolor', 'fontsize'],
        }
      }
    },
    methods: {
      onEnter(event) {
        event?.stopPropagation()
      }
    }
  }
</script>
<style lang="scss">
  .html-cell {
    .re-button.re-lists,
    .re-button.re-html,
    .re-button.re-format,
    .re-button.re-image, {
      @apply hidden;
    }
  }
</style>
