<template>
  <PayrollBatchesTable :status="resourceStatuses.Voided"/>
</template>
<script>
  import { resourceStatuses } from "@/enum/enums";
  import PayrollBatchesTable from "@/modules/payroll/components/payroll/PayrollBatchesTable";

  export default {
    components: {
      PayrollBatchesTable,
    },
    data() {
      return {
        resourceStatuses,
      }
    }
  }
</script>
