<template>
    <div>
        <layout-tabs :items="filteredItems"/>
        <div class="mt-8">
            <router-view/>
        </div>
    </div>
</template>
<script>
  export default {
    data() {
      return {
        items: [

        ],
      }
    },
    computed: {
      filteredItems() {
        return this.items.filter(item => this.$can(item.permission))
      },
    },
  }
</script>
