<template>
  <UnionForm v-if="!loading"
             :data="union"
  />
</template>
<script>
  import axios from 'axios'
  import UnionForm from '@/modules/payroll/components/rates/UnionForm'

  export default {
    components: {
      UnionForm,
    },
    data() {
      return {
        loading: false,
        union: {},
      }
    },
    methods: {
      async getUnion() {
        try {
          const { id } = this.$route.params
          if (!id) {
            return
          }
          this.loading = true
          const { data } = await axios.get(`/restify/unions/${id}`)
          this.union = data
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Something went wrong please try again.'))
        } finally {
          this.loading = false
        }
      },
    },
    mounted() {
      this.getUnion()
    },
  }
</script>
