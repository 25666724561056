<template>
  <BillingsLayout :billing-type="billingTypes.UnitPrice"/>
</template>
<script>
  import { billingTypes } from '@/modules/accounts-receivable/pages/billings/billings'
  import BillingsLayout from '@/modules/accounts-receivable/layout/billings/BillingsLayout'

  export default {
    components: {
      BillingsLayout,
    },
    data() {
      return {
        billingTypes,
      }
    },
  }
</script>
