<template>
  <BaseDataTable :columns="columns"
                 :data="jobCostings"
                 :has-summary="true"
                 :show-pagination="false"
                 ref="table"
  >
    <template #completion="{row, index}">
      <base-input :value="row.completion"
                  :placeholder="$t('Completion')"
                  :name="$t('completion')"
                  :min="0"
                  :max="99"
                  :step="0.01"
                  :id="`completion-${index}`"
                  type="number"
                  format="percent"
                  inline-errors
                  @blur="onBlurInput(row)"
                  @input="computedEstimatedCost($event, row)"
      />
    </template>
    <template #est_final_cost="{row, index}">
      <base-input :value="row.est_final_cost"
                  :placeholder="$t('Estimated final cost')"
                  :name="$t('Estimated Final Cost')"
                  :id="`est_final_cost-${index}`"
                  :min="0"
                  :max="99999999.99"
                  :step="0.01"
                  type="number"
                  format="price"
                  inline-errors
                  @blur="onBlurInput(row)"
                  @input="computedCompletion($event, row)"
      />
    </template>
    <template #variance="{row}">
      {{ computedVariance(row) }}
    </template>
    <template #job_costing_name_summary>
      <span class="text-right font-semibold">
        {{ $t('Totals') }}:
      </span>
    </template>
    <template #budget_summary>
      <span class="text-right font-semibold">
        {{ $formatPrice(summary.budget) }}
      </span>
    </template>
    <template #to_date_summary>
      <span class="text-right font-semibold">
        {{ $formatPrice(summary.to_date) }}
      </span>
    </template>
    <template #est_final_cost_summary>
      <span class="text-right font-semibold">
        {{ $formatPrice(summary.est_final_cost) }}
      </span>
    </template>
    <template #variance_summary>
      <span class="text-right font-semibold">
        {{ $formatPrice(summary.variance) }}
      </span>
    </template>
  </BaseDataTable>
</template>
<script>
  import axios from 'axios'

  export default {
    props: {
      jobCostings: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        columns: [
          {
            label: '',
            prop: 'job_type_name',
          },
          {
            label: this.$t('Budget'),
            prop: 'budget',

            component: 'FormattedPrice',
            align: 'right',
          },
          {
            label: this.$t('To-Date'),
            prop: 'to_date',

            component: 'FormattedPrice',
            align: 'right',
          },
          {
            label: this.$t('Completion'),
            prop: 'completion',

            minWidth: 150,
            maxWidth: 150,
            align: 'right',
          },
          {
            label: this.$t('Estimated Final Cost'),
            prop: 'est_final_cost',
            minWidth: 150,
            maxWidth: 150,
            align: 'right',
          },
          {
            label: this.$t('Variance'),
            prop: 'variance',
            align: 'right',
          },
        ],
      }
    },
    computed: {
      summary() {
        let summaries = {
          budget: 0,
          to_date: 0,
          completion: 0,
          est_final_cost: 0,
          variance: 0,
        }

        this.jobCostings.forEach((record) => {
          summaries.budget += record?.budget
          summaries.to_date += record?.to_date
          summaries.completion += record?.completion ? record?.completion : 0
          summaries.est_final_cost += record?.est_final_cost ? parseFloat(record?.est_final_cost) : 0
        })

        summaries.variance = (summaries?.budget - summaries?.est_final_cost)
        return summaries
      },
    },
    methods: {
      computedVariance(row) {
        const budget = row.budget ? row.budget : 0
        const est_final_cost = row.est_final_cost ? row.est_final_cost : 0
        return this.$formatPrice(parseFloat(budget) - parseFloat(est_final_cost))
      },
      computedEstimatedCost(value, row) {
        row.completion = value
        if (!row.to_date) {
          row.est_final_cost = 0
          return
        }
        row.est_final_cost = (row.to_date * 100 / value).toFixed(2)
      },
      computedCompletion(value, row) {
        row.est_final_cost = value
        if (!row.to_date) {
          row.completion = 0
          return
        }
        row.completion = (row.to_date * 100 / value).toFixed(2)
      },
      onBlurInput(row) {
        const data = [
          {
            job_id: row.id,
            completion: parseFloat(row.completion),
          },
        ]
        this.updateLineItem(data)
      },
      async updateLineItem(data) {
        await axios.post('restify/line-items/action?action=job-progress-updating-by-ids', { data })
      },
    },
  }
</script>
