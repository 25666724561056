<template>
  <div
    :class="{
        'fixed bottom-0 bg-white border p-6 shadow-xl mx-auto left-[50%] ml-[-290px] w-[600px] rounded visible': fixed && data,
        'hidden': fixed && !data,
      }">
    <div v-if="fixed">
      <CloseButton
        class="absolute right-0 top-0"
        @click="$emit('close')"/>
    </div>
    <div v-if="!readOnly && !data" class="tip mb-2 -mt-8">
      {{ $t('Select one of the rows to view employee information and rates.') }}
    </div>
    <template v-if="data">
      <div class="border bg-white border-gray-100 md:grid md:grid-cols-3 timecard-entry-summary">
        <OverrideSummaryField
          :label="$t('Tax District')"
          class="pl-4">
          {{ getTaxDistrictName(data?.meta?.district_id) }}
        </OverrideSummaryField>
        <OverrideSummaryField
          :label="$t('Tax %')">
          {{ $formatPercent(data?.sales_tax_percent) }}
        </OverrideSummaryField>
        <OverrideSummaryField
          :label="$t('Sub Account')">
          {{ data.subaccount }}
        </OverrideSummaryField>

        <OverrideSummaryField
          :label="$t('Retention %')"
          class="pl-4 bg-gray-50">
          {{ $formatPercent(data.retention_percent) }}
        </OverrideSummaryField>
        <OverrideSummaryField
          :label="$t('Retention')"
          class="bg-gray-50">
          {{ $formatPrice(data.retention_amount) }}
        </OverrideSummaryField>
        <OverrideSummaryField
          :label="$t('Labor Type')"
          class="bg-gray-50">
          <div>{{ getLaborTypeLabel(data?.meta.hourly_rate) }}</div>
        </OverrideSummaryField>
        <OverrideSummaryField
          :label="$t('Special Source Type')"
          class="pl-4">
          <div>{{ getSpecialSourceTypeLabel(data?.special_source_type) }}</div>
        </OverrideSummaryField>
        <OverrideSummaryField
          :label="$t('Special Source')"
          class="col-span-2"
        >
          <div>{{ getSpecialSourceName(data?.special_source_id) }}</div>
        </OverrideSummaryField>
      </div>
    </template>
  </div>
</template>
<script>
import CloseButton from "@/components/common/CloseButton.vue";
import OverrideSummaryField from "@/modules/payroll/components/timecard/OverrideSummaryField.vue";
import { globalResources } from "@/components/form/util";
import {
  getLaborTypeLabel,
  getSpecialSourceTypeLabel, getTaxDistrictName,
  specialSourceMap
} from "@/modules/accounts-receivable/components/lump-sum-billings/lumpSumBillingUtils";
import { getResourceLabel } from "@/components/ag-grid/columnUtils";

export default {
  components: {
    OverrideSummaryField,
    CloseButton
  },
  props: {
    data: {
      type: [Object, null],
      default: () => ({}),
      required: true,
    },
    fixed: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    getSpecialSourceTypeLabel,
    getLaborTypeLabel,
    getTaxDistrictName,
    getSpecialSourceName() {
      const { special_source_type } = this.data
      const resourceName = specialSourceMap[special_source_type]
      return getResourceLabel(this.data.special_source_id, resourceName)
    }
  }
}
</script>
