<template>
  <div class="prose prose-sm max-w-[50rem] mx-auto">
    <portal to="page-title">
      <div class="-mt-4"></div>
    </portal>
    <h1>{{ $t('Changelog') }}</h1>
    <h5>
      {{ $t(`Stay up to date with all of the latest additions and improvements we've made to Construction Partner`) }}
    </h5>

    <Changelog/>
  </div>
</template>
<script>
  import Changelog from '@/../CHANGELOG.md'

  export default {
    components: {
      Changelog
    },
  }
</script>
