<template>
  <div>
    <div v-if="errors"
         v-for="error in errors"
         :key="error"
         class="mt-2 flex justify-end mr-2 text-red-600">
      {{ error?.message || error }}
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      errors: {
        type: Array,
        default: () => []
      }
    }
  }
</script>
