<template>
  <base-report-form
      :filters="model"
      :report-number="reportNumber"
      @data-fetch="onDataFetch"
  >

    <template #filters>

      <base-filter-row :title="$t('View')">

        <base-select
            v-model="model.format"
            :options="formatOptions"
            class="col-span-3"
            rules="required"
        />
        <div class="col-span-3 flex items-baseline">
          <span class="text-label">{{ $t('Billing Types') }}</span>
          <base-select
              v-model="model.job_type_ids"
              :options="billingTypes"
              :name="$t('Billing Type')"
              value-key="id"
              label-key="abbr"
              class="flex-1"
              rules="required"
              collapse-tags
              multiple
          />
        </div>

      </base-filter-row>

      <base-filter-row>

        <div class="col-span-6 flex mb-4">
          <base-checkbox
              v-model="model.include_cost_to_date"
              :label="$t('Include Cost To-Date')"
              id="include_cost_to_date"
          />
          <base-checkbox
              v-model="model.include_remaining_to_bill"
              :label="$t('Include Remaining to Bill')"
              id="include_remaining_to_bill"
          />
        </div>

      </base-filter-row>

      <job-and-additional-filters v-model="model">
        <template #job-statuses>
          <div class="col-span-2 flex items-baseline">
            <span class="text-label">{{ $t('Status') }}</span>
            <base-select
                v-model="model.job_statuses"
                :add-entity="false"
                :options="jobStatusOptions"
                class="flex-1"
                rules="required"
                collapse-tags
                multiple
            />
          </div>
        </template>
      </job-and-additional-filters>

      <line-item-range-filter v-model="model"/>

    </template>

    <template #table="{data, loading}">
      <job-pre-billing-worksheet-report-table
          :data="getData"
          :filters="model"
          :report-number="reportNumber"
          :data-loading="loading"
          :billing-type="currentTab"
      >

        <template #header-tabs>
          <base-tabs
              v-model="currentTab"
              :tabs="selectedBillings"
              value-key="abbr"
          />
        </template>

      </job-pre-billing-worksheet-report-table>

    </template>

  </base-report-form>
</template>
<script>
  import { jobStatusOptions } from '@/modules/job-costing/enum/jobs'
  import { JobAndAdditionalFilters, LineItemRangeFilter } from '@/components/range-filters'
  import { preBillingViewFormats } from '@/modules/accounts-receivable/components/reports/util'
  import JobPreBillingWorksheetReportTable
    from '@/modules/accounts-receivable/components/reports/job-pre-billing-worksheet/JobPreBillingWorksheetReportTable'

  export default {
    components: {
      LineItemRangeFilter,
      JobAndAdditionalFilters,
      JobPreBillingWorksheetReportTable,
    },
    data() {
      const billingTypeIds = this.$store.getters['jobCosting/getBillingTypeIds']

      return {
        reportData: [],
        selectedBillings: [],
        reportHeader: {},
        currentTab: '',
        jobStatusOptions,
        model: {
          format: preBillingViewFormats.LineItemSummary,
          job_type_ids: billingTypeIds,
          start_date: null,
          end_date: null,
          customer_code_from: null,
          customer_code_to: null,
          job_ids: [],
          job_number_from: null,
          job_number_to: null,
          job_statuses: [this.$resourceStatuses.Open],
          phase_code_from: null,
          phase_code_to: null,
          phase_codes: null,
          cost_code_from: null,
          cost_code_to: null,

          include_cost_to_date: true,
          include_remaining_to_bill: false,
        },
        formatOptions: [
          {
            label: this.$t('Line Item Summary'),
            value: preBillingViewFormats.LineItemSummary,
          },
        ],
      }
    },
    computed: {
      reportNumber() {
        return 5889
      },
      billingTypes() {
        return this.$store.getters['jobCosting/getBillingTypes']
      },
      billingTypeIds() {
        return this.$store.getters['jobCosting/getBillingTypeIds']
      },
      getData() {
        const items = this.get(this.reportData, `[${this.getTypeIndexByAbbr}].jobs`, [])

        return {
          header: this.reportHeader,
          items,
        }
      },
      getTypeIndexByAbbr() {
        return this.selectedBillings.findIndex(type => type.abbr === this.currentTab)
      },
    },
    methods: {
      onDataFetch(data) {
        this.selectedBillings = data.items?.map(({ job_type }) => job_type)
        this.currentTab = this.get(this.selectedBillings, '[0].abbr', '')

        this.reportHeader = data.header
        this.reportData = data.items || []
      },
    },
    watch: {
      billingTypeIds: {
        immediate: true,
        handler(value) {
          this.model.job_type_ids = value || []
        },
      }
    }
  }
</script>
