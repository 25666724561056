import EmptyLayout from "@/components/common/EmptyLayout";
import OwnEmployeeChecks from "@/modules/employee-portal/pages/own-employee-checks";
import OwnEmployeeW2Forms from "@/modules/employee-portal/pages/own-employee-w2-forms";
import OwnEmployeeSettings from "@/modules/employee-portal/pages/own-employee-settings";

export default [
  {
    path: '/',
    name: 'Employee',
    component: EmptyLayout,
    redirect: 'payroll/my-checks',
    children: [
      {
        path: 'payroll/my-checks',
        name: 'My Checks',
        title: 'My Checks',
        description: 'Preview or download your own employee checks.',
        meta: {
          productionReady: true,
          permissions: ['own_employee_checks_show'],
        },
        component: OwnEmployeeChecks,
      },
      {
        path: 'payroll/my-w2-forms',
        name: 'My W2 Forms',
        title: 'My W2 Forms',
        description: 'Preview or download your own employee w2 forms.',
        meta: {
          productionReady: true,
          permissions: ['own_employee_w2_forms_show'],
        },
        component: OwnEmployeeW2Forms,
      },
      {
        path: 'payroll/my-settings',
        name: 'Personal Payroll Settings',
        title: 'Personal Payroll Settings',
        description: 'Manage your payroll settings',
        meta: {
          productionReady: true,
          permissions: ['own_employee_checks_show'],
        },
        component: OwnEmployeeSettings,
      },
    ]
  }
]
