<template>
  <BaseDataTable
      :columns="columns"
      :is-expandable="true"
      :default-match-filters="defaultMatchFilters"
      :add-entity-in-new-tab="true"
      :view-entity-url-query="`/payroll/employees/${rateType}`"
      :add-text="addText"
      :delete-action="onDelete"
      :delete-description="getDeleteDescription"
      :url-params="{show_inactive: false}"
      ref="table"
      url="/restify/employees"
      default-sort="code"
      permission="employees"
      hide-actions="view"
      actions="search,refresh,print"
      @add="$router.push(`/payroll/employees/${rateType}/add `)"
  >
    <template #expand-content="{row}">
      <EmployeeRates
          :employee-id="row.id"
          :rate-type="rateType"
          :read-only="true"
          class="p-3"
      />
    </template>
    <template #employee="{row}">
      <EmployeeLink :data="row"/>
    </template>
  </BaseDataTable>
</template>
<script lang="ts" setup>
  import axios from 'axios'
  import i18n from '@/i18n'
  import { computed } from 'vue'
  import { employeeRatesTypes } from '@/modules/payroll/components/rates/util'
  import EmployeeRates from '@/modules/payroll/components/employee-rates/EmployeeRates.vue'
  import { Column } from '@/components/ag-grid/tableTypes'
  import Data = API.Data
  import { Employee } from '@/modules/common/types/models'

  const props = defineProps({
    rateType: {
      type: String,
      default: employeeRatesTypes.PayRates,
    },
  })

  const addText = computed(() => {
    const textMap = {
      [employeeRatesTypes.PayRates]: i18n.t('New pay rate'),
      [employeeRatesTypes.BenefitRates]: i18n.t('New benefit rate'),
      [employeeRatesTypes.RetirementRates]: i18n.t('New retirement rate'),
      [employeeRatesTypes.FlexibleBenefitRates]: i18n.t('New flexible benefit rate'),
      default: i18n.t('New deduction rate'),
    }
    return textMap[props.rateType] || textMap.default
  })

  const columns = computed<Column[]>(() => {
    return [
      {
        label: i18n.t('Employee'),
        prop: 'employee',
        minWidth: 100,
        maxWidth: 150,
      },
      {
        label: i18n.t('Status'),
        prop: 'attributes.status',
        component: 'Status',
        minWidth: 80,
        maxWidth: 80,
      },
      {
        label: i18n.t('Rates'),
        prop: 'attributes.pay_rates_count',
        component: 'Count',
        renderIf: () => props.rateType === employeeRatesTypes.PayRates,
      },
      {
        label: i18n.t('Rates'),
        prop: 'attributes.deduction_rates_count',
        component: 'Count',
        renderIf: () => props.rateType === employeeRatesTypes.DeductionRates,
      },
      {
        label: i18n.t('Rates'),
        prop: 'attributes.benefit_rates_count',
        component: 'Count',
        renderIf: () => props.rateType === employeeRatesTypes.BenefitRates,
      },
      {
        label: i18n.t('Pay Frequency'),
        prop: 'attributes.pay_frequency',
        minWidth: 100,
        maxWidth: 100,
        component: 'Status',
      },
    ]
  })

  const defaultMatchFilters = computed(() => {
    const rateType = props.rateType.replaceAll('-', '_')
    return {
      [`having_${ rateType }`]: true,
    }
  })

  const getDeleteDescription = i18n.t(`The following action will delete not used ${ props.rateType } setup for this employee. This action is irreversible.`)

  async function onDelete(employee: Data<Employee>) {
    try {
      await axios.post(`/restify/employees/${ employee.id }/actions?action=delete-${ props.rateType }-from-employee`)
    } catch (err) {
      console.warn(err)
    } finally {
      // @ts-ignore
      this.$refs.table.refresh({}, true)
    }
  }
</script>
