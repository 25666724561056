<template>
  <div class="import-data mt-4">
    <ImportForm v-bind="importSettings"
                @success="redirectToList"
    />
  </div>
</template>
<script>
  import ImportForm from "@/modules/common/components/import/ImportForm";
  import { getInitialMapping } from "@/modules/payroll/utils/timecardImportColumns";

  const colMappings = {
    Code: {
      value: 'code',
      label: 'Code',
      required: true,
      alternativeLabels: ['Rate Code'],
    },
    Description: {
      value: 'description',
      label: 'Description',
      alternativeLabels: ['Rate Description'],
    },
    State: {
      value: 'state',
      label: 'State',
      alternativeLabels: ['Rate State'],
    },
    CalculateBy: {
      value: 'calculate_by',
      label: 'Calculate By',
      alternativeLabels: [],
    },
    MultiplyBy: {
      value: 'multiply_by',
      label: 'Multiply By',
      alternativeLabels: [],
    },
    Amount: {
      value: 'amount',
      label: 'Amount',
      required: true,
      alternativeLabels: [],
    },
    StartDate: {
      value: 'start_date',
      label: 'Start Date',
      required: true,
      alternativeLabels: [],
    }

  }

  const options = [
    colMappings.Code,
    colMappings.Description,
    colMappings.State,
    colMappings.CalculateBy,
    colMappings.MultiplyBy,
    colMappings.Amount,
    colMappings.StartDate,
  ]

  export default {
    components: {
      ImportForm,
    },
    data() {
      return {
        importSettings: {
          url: '/restify/gen-liability-rates/import',
          columnOptions: options,
          columnMappings: colMappings,
          initialMapping: getInitialMapping(options),
          type: 'gen-liability-rates-import',
          resource: 'gen-liability-rates',
          showTemplateDownload: true,
        },
      }
    },
    methods: {
      async redirectToList() {
        await this.$router.push('/payroll/rates/general-liability-rates')
        await this.$store.dispatch('globalLists/getPRConfiguration')
      },
    }
  }
</script>
