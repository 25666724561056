<template>
  <ServiceCodeLink
      :data="row"
      :target="linkTargetProp"
      :id="id"
      :is-link="!editableCell"
      :show-description="params.showDescription"
  />
</template>
<script>
  import ServiceCodeLink from "@/components/links/ServiceCodeLink";

  export default {
    components: {
      ServiceCodeLink,
    },
    props: {
      id: [String, Number],
      column: {
        type: Object,
        default: () => ({}),
      },
      row: {
        type: Object,
        default: () => ({}),
      },
      params: {
        type: Object,
        default: () => ({}),
      },
      editableCell: Boolean,
    },
    computed: {
      linkTargetProp() {
        return this.get(this.params, 'colDef.cellEditorParams.target', false)
      },
    }
  }
</script>
