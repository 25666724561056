<template>
  <div class="report-form">
    <portal to="page-title-actions">
      <base-button
          v-if="showReportTable"
          variant="white"
          @click="collapseForm"
      >
        <IconFilter class="w-4 h-4 mr-2"/>
        {{ $t('Change filters') }}
      </base-button>

      <div v-else-if="!hidePresets" class="flex">
        <portal-target name="report-common-filter"/>
        <report-presets
            :report-type="reportNumber"
            ref="reportPreset"
            @on-apply="onApplyPreset"
        />
      </div>
    </portal>

    <base-form
        v-show="!showReportTable"
        :save-text="$t(saveText)"
        :show-cancel="true"
        :loading="loading"
        :focus-on-first-input="focusOnFirstInput"
        layout="vertical"
        submit-button-type="button"
        grid-classes="grid grid-cols-1"
        @submit="viewReport"
        @cancel="showReportTable = true"
    >
        <StimulsoftPrintButton
                ref="printButton"
                :url="getReportUrl()"
                :hide-button="true"
                :entity="stimulsoftPrintEntity"
        />

      <slot name="filters"/>

      <template v-if="!hidePresets">
        <hr class="mb-4" :class="dividerClass"/>
        <base-filter-row :title="$t('Preset')" class="h-16">
          <base-checkbox
              v-model="storePreset"
              :label="preset.id ? $t('Update Preset') : $t('Save as Future Preset')"
              class="col-span-2 mb-6"
              id="save-preset"
          />
          <template v-if="storePreset">
            <base-input
                v-model="preset.name"
                :placeholder="$t('Enter Preset Name')"
                class="col-span-2"
            />
            <base-checkbox
                v-model="preset.public"
                :label="$t('Set as Public')"
                class="col-span-2 mb-6"
                id="public"
            />
          </template>

          <base-button v-if="!storePreset && preset.id"
                       variant="gray-link"
                       class="mb-6 col-span-2"
                       @click="onClearPreset"
          >
            <IconClose class="mr-2"/>
            {{ $t('Clear Preset') }}
            ({{ preset.name }})
          </base-button>

        </base-filter-row>
      </template>
    </base-form>
    <div
        :class="{
          'shaded-report-table': shadeReportTable,
        }"
    >
      <div v-if="reportErrors.length > 0">
        <h4 class="form-section-title">
          {{ $t('Report Errors') }}
        </h4>
        <base-alert
            v-for="(error, index) in reportErrors"
            :key="index"
            type="danger"
            class="mb-4"
        >
          {{ error }}
        </base-alert>
      </div>
      <slot
          v-if="showReportTable"
          :loading="loading"
          :data="reportData"
          name="table"
      />
    </div>
  </div>
</template>
<script>
  import axios from 'axios'
  import omitBy from 'lodash/omitBy'
  import ReportPresets from '@/modules/common/components/reports/ReportPresets'
  import { useReportShading } from '@/modules/common/components/reports/util.js'
  import cloneDeep from "lodash/cloneDeep";

  const filterTypeArrayKeys = [
    'statuses',
    'job_statuses',
    'work_order_statuses',
    'job_type_ids',
  ]

  export default {
    components: {
      ReportPresets,
    },
    props: {
      filters: {
        required: true,
        type: Object,
        default: () => ({}),
      },
      reportNumber: {
        required: true,
        type: [String, Number],
        default: '',
      },
      focusOnFirstInput: {
        type: Boolean,
        default: true,
      },
      hasOwnDataFetcher: {
        type: Boolean,
        default: false,
      },
      hidePresets: {
        type: Boolean,
        default: false,
      },
      saveText: {
        type: String,
        default: 'Run report',
      },
      isReportTable: {
        type: Boolean,
        default: true,
      },
      isPrint: {
        type: Boolean,
        default: false,
      },
      isStimulSoftPrint: {
        type: Boolean,
        default: false,
      },
      stimulsoftPrintEntity: {
        type: String,
      },
      transformFilters: {
        type: Function,
      },
      dividerClass: {
        type: String,
      }
    },
    setup() {
      const { reportShadingSettings, shadeReports } = useReportShading()
      return {
        reportShadingSettings,
        shadeReports,
      }
    },
    data() {
      return {
        reportData: {},
        reportErrors: [],
        loading: false,
        showReportTable: false,
        storePreset: false,
        preset: {
          id: null,
          name: '',
          report_type: '',
          public: false,
        },
        initialFilters: {},
      }
    },
    computed: {
      shadeReportTable() {
        return this.shadeReports && this.isReportTable
      },
      getCalendarYear() {
        return this.$store.getters['payroll/getCalendarYear'] || this.$currentYear
      },
      isPayrollModule() {
        return this.$currentModule === this.$modules.PR
      },
      isGeneralLedgerModule() {
        return this.$currentModule === this.$modules.LG
      },
      reportShading() {
        return this.reportShadingSettings
      },
    },
    methods: {
      onApplyPreset(preset) {
        const { attributes } = preset
        this.preset = attributes
        this.updateParentFiltersModel(attributes.filters)
        this.storePreset = false
      },
      onClearPreset() {
        this.preset.id = this.preset.name = null
        this.updateParentFiltersModel(this.initialFilters)

        this.$refs.reportPreset?.resetSelection()
      },
      hasValue(field) {
        return field !== null && field !== undefined && field !== ''
      },
      getReportParams(filters = null) {
        if (this.isPayrollModule) {
          this.filters['calendar_year'] = this.getCalendarYear
        }

        if (this.isGeneralLedgerModule && !this.filters.fiscal_year) {
          this.filters['fiscal_year'] = this.activeFiscalYear
        }

        let params = cloneDeep(filters || this.filters)
        params['date'] = this.$dateWithTimeZone

        let reportPath = `report-${this.reportNumber}`
        let method = 'GET'
        if (this.isPrint) {
          reportPath = `print-report-${this.reportNumber}`
          method = 'POST'
          params.orientation = 'portrait'

        }
        if (!this.hasValue(params.period_start)) {
          params.period_start = undefined
        }
        if (!this.hasValue(params.period_end)) {
          params.period_end = undefined
        }
        if (!this.hasValue(params.account_number_from)) {
          params.account_number_from = null
        }
        if (!this.hasValue(params.account_number_to)) {
          params.account_number_to = null
        }
        if (!params.fiscal_year_start) {
          params.fiscal_year_start = undefined
        }
        if (!params.fiscal_year_end) {
          params.fiscal_year_end = undefined
        }
        if (params.summary && typeof params.summary === 'object' && method === 'GET') {
          for (let key in params.summary) {
            params[`summary[${key}]`] = params.summary[key]
          }
          delete params.summary
        }
        return {
          params,
          method,
          reportPath
        }
      },
      getReportUrl(filters) {
        const { params, reportPath } = this.getReportParams(filters)
        const basePath = `/reports/${reportPath}`
        const searchParams = new URLSearchParams({})
        for (let key in params) {
          const value = params[key]
          if (value === undefined || value === null) {
            continue
          }
          if (Array.isArray(value)) {
            value.forEach((item) => {
              searchParams.append(`${key}[]`, item)
            })
          } else {
            searchParams.append(key, params[key])
          }
        }
        return `${basePath}?${searchParams}`
      },
      async viewReport(filters = null) {
        if (this.isStimulSoftPrint) {
          await this.$refs.printButton.onPrintClick()
          return
        }
        try {
          this.reportErrors = []
          this.loading = true
          this.collapseForm()
          this.showReportTable = true

          if (this.hasOwnDataFetcher) {
            return this.$emit('on-update-filter', filters)
          }

          let { params, method, reportPath } = this.getReportParams(filters)
          if (this.transformFilters) {
            params = this.transformFilters(params)
          }
          let data
          if (method === 'POST') {
            data = await axios.post(`/reports/${reportPath}`, params, {
              responseType: 'blob'
            })
          } else {
            const response = await axios.get(`/reports/${reportPath}`, {
              params,
            })
            data = response.data
          }

          this.$emit('data-fetch', data)

          this.reportData = data
          this.addFiltersInUrlQuery()
          this.trySavePreset()

        } catch (err) {
          console.warn(err)
          if (err?.response?.status === 422) {
            this.reportErrors = this.mapErrors(err.response?.data)
            this.reportData = {}
          }
        } finally {
          this.loading = false
        }
      },
      mapErrors(data = {}) {
        const errors = []
        if (!data?.errors) {
          return errors
        }
        for(let key in data?.errors) {
          const keyErrors = data?.errors[key]
          if (Array.isArray(keyErrors)) {
            errors.push(...keyErrors)
          } else {
            errors.push(keyErrors)
          }
        }
        return errors
      },
      trySavePreset() {
        if (!this.storePreset) {
          return
        }

        let payload = this.preset

        payload.report_type = this.reportNumber
        payload.filters = this.filters

        if (this.preset.id) {
          axios.put('/restify/report-presets', payload)
        } else {
          axios.post('/restify/report-presets', payload)
        }
      },
      collapseForm() {
        this.showReportTable = false
      },
      addFiltersInUrlQuery() {
        const query = this.getActiveFilters()

        if (JSON.stringify(this.$route.query) === JSON.stringify(query)) {
          return
        }

        this.$router.push({
          query,
        })
      },
      getActiveFilters() {
        return omitBy(this.filters, (value) => {
          return !value || value.length === 0
        })
      },
      async mapUrlQueriesToModel() {
        let filters = this.$route.query

        Object.keys(filters).forEach(key => {
          if (filterTypeArrayKeys.includes(key) && typeof filters[key] === 'string' && filters[key] !== '[object Object]') {
            filters[key] = filters[key].split(',')
          } else if (filters[key] === "[object Object]") {
            delete filters[key]
          }
        })

        const newFilters = {
          ...this.filters,
          ...filters,
        }

        this.storeInitialFilters(newFilters)

        if (!filters || !Object.keys(filters).length) {
          return
        }

        this.updateParentFiltersModel(newFilters)
        await this.$nextTick()
        await this.viewReport(newFilters)
      },
      updateParentFiltersModel(filters) {
        this.$parent.model = {
          ...this.$parent.model,
          ...filters,
        }
      },
      // * Store initial filters to reset preset
      storeInitialFilters(filters) {
        this.initialFilters = { ...filters }
      },
      tryResetForm(query) {
        if (!query || !Object.keys(query).length) {
          // * To cover the case when we click on the report link (breadcrumbs)
          this.collapseForm()
          this.updateParentFiltersModel({})
        }
      },
    },
    mounted() {
      this.mapUrlQueriesToModel()
    },
    watch: {
      '$route.query': {
        handler(query) {
          this.tryResetForm(query)
        },
      },
    },
  }
</script>
<style lang="scss">
  .text-label {
    @apply text-sm text-gray-600 font-medium whitespace-nowrap md:mr-3;
  }

  .shaded-report-table {
    .main-table-columns th,
    .ag-header-viewport,
    .ag-header-container,
    .ag-header-cell {
      background: v-bind('reportShading.title.color1');
    }

    .header-row td {
      background: v-bind('reportShading.group.color1');
    }

    .content-row:not(.subtotal-row) td,
    .ag-row:not(.ag-row-footer),
    .ag-row:not(.ag-row-footer) .ag-cell {
      background: v-bind('reportShading.detail.color1');
    }

    .content-row:not(.subtotal-row):nth-child(odd) td,
    .ag-row:not(.ag-row-footer):nth-child(odd),
    .ag-row:not(.ag-row-footer):nth-child(odd) .ag-cell {
      background: v-bind('reportShading.detail.color2');
    }

    .content-row.has-background:not(.subtotal-row):nth-child(odd) td {
      background: v-bind('reportShading.detail.color1');
    }

    .subtotal-row,
    .ag-row-footer,
    .ag-row-footer .ag-cell {
      background: v-bind('reportShading.totals.color1');
      border: none;
    }

    .summary-row td {
      background: v-bind('reportShading.totals.color1');
      border-top: 1px solid;
    }
  }
</style>
