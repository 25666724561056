<template>
  <div>
    <AgDataTable
      v-bind="editableTableProps"
      :url="url"
      :url-params="urlParams"
      :transform-data="mapData"
      :get-empty-row="getEmptyEntry"
      :add-text="$t('New Entry')"
      :columns="columns"
      :show-pagination="true"
      :selected-rows.sync="selectedRows"
      import-url="/accounts-payable/invoices/receipts/import"
      actions="refresh,add,search,import"
      suppressColumnReordering
      @grid-ready="grid = $event"
      @cell-focused="onCellFocused"
      @cell-value-changed="onCellValueChanged"
    >
      <template #header-info>
        <BankSelect
          v-model="model.default_bank_id"
          :use-default-bank="false"
          :edit-entity="false"
          clearable
        />
      </template>
      <template #additional-actions>
        <ProofListingButton
          v-if="$can('invoices_update')"
          :disabled="postableSelectedRows.length === 0"
          :selected-rows="postableSelectedRows"
          path="/accounts-payable/invoices/proof-listing"
          class="mr-2"
        />
      </template>
      <template #view="{row}">
        <div class="flex justify-center">
          <TableViewButton @click="onViewClick(row)"/>
        </div>
      </template>
    </AgDataTable>
    <InvoiceApproveDialog
      v-if="showEntityDetails"
      :open.sync="showEntityDetails"
      :invoice="selectedEntity"
      :hide-all-buttons="true"
      @close="showEntityDetails = false"
    />
  </div>
</template>
<script>
import { BankUsedInTypes, resourceStatuses } from "@/enum/enums";
import { costCenterDefaultFields, costCenterTypes, setTypeSources } from "@/components/grid-table/utils/cost-center";
import { editableTableProps, saveInlineEntry, updateInlineEntry } from "@/components/ag-grid/tableUtils";
import {
  accountCol,
  additionalSourceCol,
  costCenterCol,
  descriptionCol,
  sourceCol,
  subAccountCol,
  typeCol,
  updateCostCenterHeaderNames
} from "@/components/ag-grid/columns/costCenterColumns";
import { costCenterFields, getDefaultAccounts, onChangeCostCenter } from "@/modules/common/util/costCenterUtils";
import pick from "lodash/pick";
import { globalResources } from "@/components/form/util";
import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";
import { cellClasses, stopEditingOnTab } from "@/components/ag-grid/columnUtils";
import { getDeleteColumn } from "@/components/ag-grid/columns/deleteColumns";
import { getEditablePriceCol } from "@/components/ag-grid/columns/editableColumns";
import axios from "axios";
import { isRowValid } from "@/modules/accounts-payable/pages/invoices/receiptUtils";
import TimesheetsDateFilter from "@/modules/payroll/components/timesheets/TimesheetsDateFilter.vue";
import { CheckCircleIcon } from "vue-feather-icons";
import { useEntityReview } from "@/modules/common/composables/useEntityReview";
import InvoiceApproveDialog from "@/modules/accounts-payable/components/invoice/InvoiceApproveDialog.vue";
import parse from "date-fns/parse";
import differenceInDays from "date-fns/differenceInDays";

export default {
  components: { InvoiceApproveDialog, CheckCircleIcon, TimesheetsDateFilter },
  setup() {
    const {
      setCurrentEntity,
      selectedEntity,
      showEntityDetails
    } = useEntityReview()
    return {
      setCurrentEntity,
      showEntityDetails,
      selectedEntity
    }
  },
  data() {
    return {
      grid: null,
      model: {
        default_bank_id: this.$settings(this.$currentModule, 'default_bank_id'),
      },
      originalRows: [],
      selectedRows: [],
    }
  },
  computed: {
    postableSelectedRows() {
      return this.selectedRows.filter(row => row.is_in_balance)
    },
    editableTableProps() {
      return editableTableProps
    },
    url() {
      return `/restify/invoices`
    },
    urlParams() {
      return {
        status: resourceStatuses.Pending,
        bank_id: this.model.default_bank_id,
        is_receipt: true,
        related: 'entries'
      }
    },
    getJobSubcontractTypeId() {
      return this.$store.getters['globalLists/getJobSubcontractTypeId']
    },
    columns() {
      return [
        {
          field: 'bank_id',
          headerName: this.$t('Bank'),
          component: 'BankLink',
          checkboxSelection: true,
          headerCheckboxSelection: true,
          cellRendererParams: (params) => {
            return {
              id: params.data.bank_id,
            }
          },
          editable: true,
          cellEditor: cellEditors.BankSelect,
          cellEditorParams: {
            usedFor: BankUsedInTypes.AccountsPayable,
          },
          minWidth: 150,
          maxWidth: 250,
          cellClass: (params) => params?.data?.bank_id ? '' : cellClasses.Invalid,
        },
        {
          field: 'vendor_id',
          headerName: this.$t('Vendor'),
          editable: true,
          cellEditor: cellEditors.GlobalResourceSelect,
          cellEditorParams: {
            resourceName: globalResources.Vendors,
          },
          component: 'VendorLink',
          minWidth: 200,
          maxWidth: 400,
          cellClass: (params) => params?.data?.vendor_id ? '' : cellClasses.Invalid,
          valueSetter: params => {
            params.data.vendor_id = params.newValue
            this.onVendorChange(params)
            params.node.setData(params.data)
            return true
          },
        },
        {
          field: 'date',
          headerName: this.$t('Date'),
          editable: true,
          cellEditor: cellEditors.DatePicker,
          component: 'FormattedDate',
          minWidth: 140,
          cellClass: (params) => params?.data?.date ? '' : cellClasses.Invalid,
          valueSetter: params => {
            params.data.date = params.newValue
            this.checkForOldReceipt(params.newValue)
            return true
          }
        },
        {
          ...costCenterCol,
          cellEditorParams: {
            options: [
              {
                label: this.$t('G&A'),
                value: costCenterTypes.GeneralAndAdministrative,
              },
              {
                label: this.$t('JOB'),
                value: costCenterTypes.Job,
              },
              {
                label: this.$t('S/B'),
                value: costCenterTypes.WorkOrder,
              },
              {
                label: this.$t('EQP'),
                value: costCenterTypes.Equipment,
              },
              {
                label: this.$t('INV'),
                value: costCenterTypes.Inventory,
              },
            ],
          },
          valueSetter: params => {
            let value = params.newValue
            params.data.cost_center = value
            params.data = onChangeCostCenter(params.data)

            const costCenterDefaults = this.getVendorDefaults(params.data)

            if (costCenterDefaults.cost_center === value) {
              Object.keys(costCenterDefaults).forEach(key => {
                params.data[key] = costCenterDefaults[key]
              })
            }
            params.node.setData(params.data)
            return true
          },
          editable: true,
          cellClass: params => additionalSourceCol().cellClass(params),
        },
        {
          ...sourceCol,
          valueSetter: params => {
            params.data.source_id = params.newValue
            params.data.addl_source_id = ''
            setTypeSources(params.data)
            params.node.setData(params.data)
            return true
          },
          editable: this.isSourceEditable,
          suppressNavigable: params => !this.isSourceEditable(params),
          cellClass: params => {
            if (params.node.footer || this.readOnly) {
              return ''
            }

            const isEditable = this.isSourceEditable(params)
            if (!isEditable) {
              return cellClasses.ReadOnly
            }

            const hasValue = params.data?.source_id
            return hasValue ? '' : cellClasses.Invalid
          },
          cellRendererParams: {
            showDescription: false,
          },
          minWidth: 60,
          maxWidth: 120,
        },
        {
          ...typeCol,
          editable: this.isTypeEditable,
          cellClass: params => {
            if (params.node.footer || this.readOnly) {
              return ''
            }

            const isEditable = this.isTypeEditable(params)
            if (!isEditable) {
              return cellClasses.ReadOnly
            }

            const hasValue = params.data?.type_id
            return hasValue ? '' : cellClasses.Invalid
          },
          valueSetter: params => {
            const entry = params.data
            if (entry.cost_center === costCenterTypes.Equipment) {
              entry.quantity = 0
            }
            entry.type_id = params.newValue
            setTypeSources(params.data)
            params.node.setData(params.data)
            return true
          },
        },
        {
          ...additionalSourceCol(),
          cellRendererParams: {
            showDescription: false,
          },
          minWidth: 80,
          maxWidth: 120,
          valueSetter: params => {
            params.data.addl_source_id = params.newValue
            setTypeSources(params.data)
            params.node.setData(params.data)
            return true
          },
          cellEditorParams: params => {
            const { cost_center } = params.data
            const resourceMapping = {
              [costCenterTypes.Equipment]: globalResources.EquipmentMaintenances,
            }
            const resourceName = resourceMapping[cost_center]

            return {
              resourceName,
              filterMethod: (option) => {
                if (cost_center !== costCenterTypes.Job) {
                  return true
                }
                const isSubType = this.isSubContractType(params)
                if (!isSubType) {
                  return true
                }
                // show only line items without a subcontractor vendor or with the same vendor id as the invoice
                return !option.vendor_id || option.vendor_id === params.data?.vendor_id
              },
            }
          },
          editable: true,
          cellClass: params => additionalSourceCol().cellClass(params),
        },
        {
          ...accountCol(),
          cellRendererParams: {
            showDescription: false,
          },
          minWidth: 50,
          maxWidth: 80,
        },
        {
          ...subAccountCol,
        },
        {
          ...descriptionCol,
          minWidth: 200,
        },
        {
          ...getEditablePriceCol({
            field: 'gross_amount',
            headerName: this.$t('Amount'),
          }),
          suppressKeyboardEvent: stopEditingOnTab,
        },
        {
          field: 'view',
          headerName: ' ',
          minWidth: 40,
          maxWidth: 60,
          editable: false,
        },
        {
          ...getDeleteColumn({
            url: `/restify/invoices`,
          }),
        },
      ]
    }
  },
  methods: {
    getEmptyEntry() {
      return {
        bank_id: this.model.default_bank_id,
        vendor_id: null,
        date: null,
        description: '',
        cost_center: costCenterTypes.GeneralAndAdministrative,
        source_id: null,
        source_type: null,
        addl_source_id: null,
        addl_source_type: null,
        type_id: null,
        type_type: null,
        account: null,
        subaccount: null,
        gross_amount: 0,
      }
    },
    getVendorDefaults(data) {
      const vendor = this.$store.getters['globalLists/getResourceById'](globalResources.Vendors, data.vendor_id)
      return pick(vendor, costCenterDefaultFields)
    },
    isSourceEditable(params) {
      return params.data?.cost_center !== costCenterTypes.GeneralAndAdministrative
    },
    isTypeEditable(params) {
      const editableCostCenters = [costCenterTypes.Job, costCenterTypes.Equipment, costCenterTypes.WorkOrder]
      return editableCostCenters.includes(params.data?.cost_center)
    },
    checkForOldReceipt(dateStr) {
      const date = parse(dateStr, 'yyyy-MM-dd', new Date())
      const days = differenceInDays(new Date(), date)
      const warningLimit = 90
      const isOld = days >= warningLimit
      if (isOld) {
        this.$warning(this.$t('The entered date is older than 90 days.'))
      }
    },
    isSubContractType(params) {
      return params.data?.type_id === this.getJobSubcontractTypeId
    },
    onVendorChange(params) {
      const costCenterDefaults = this.getVendorDefaults(params.data)
      Object.keys(costCenterDefaults).forEach(key => {
        params.data[key] = costCenterDefaults[key]
      })
    },
    getVendorAccounts(entry) {
      const vendorDefaults = this.getVendorDefaults(entry)
      if (vendorDefaults.account) {
        entry.account = vendorDefaults.account
        entry.subaccount = vendorDefaults.subaccount
      }
      return entry
    },
    async trySetDefaultAccounts(entry) {
      if (entry.cost_center === costCenterTypes.GeneralAndAdministrative) {
        return this.getVendorAccounts(entry)
      }

      return await getDefaultAccounts(entry)
    },
    onCellFocused(params) {
      updateCostCenterHeaderNames(params, true)
    },
    async onCellValueChanged(params) {
      const field = params?.colDef?.field
      const entry = params.data
      if (costCenterFields.includes(field)) {
        params.data = await this.trySetDefaultAccounts(entry)
        params.node.setData(params.data)
      }

      await this.saveOrUpdateEntry(params)
    },
    async saveOrUpdateEntry(params) {
      const row = params.data
      const isValid = isRowValid(row)
      if (!isValid) {
        return params.data
      }
      const oldData = { ...params.data }
      let savedData = params.data
      try {
        if (!row.id) {
          savedData = await saveInlineEntry({
            row,
            params,
            url: '/restify/invoices/actions?action=create-receipt',
            transformData: this.mapReceiptResponse,
          })
        } else {
          savedData = await updateInlineEntry({
            row,
            params,
            method: 'post',
            url: `/restify/invoices/actions?action=update-receipt`,
            transformData: this.mapReceiptResponse,
          })
        }
        params.data.loading = false
        params.node.setData(params.data)
      } catch (err) {
        await this.restoreOldEntry(params, oldData)
      }
      return savedData
    },
    async restoreOldEntry(params, oldData) {
      const id = params.data?.id
      if (id) {
        const { data } = await axios.get(`/restify/invoices/${id}?related=entries`)
        params.data = this.mapInvoice(data)
        params.node.setData(params.data)
        return
      }
      params.data = oldData
      params.node.setData(oldData)
    },
    mapReceiptResponse(data) {
      const { entry, invoice } = data || {}
      return {
        id: invoice.id,
        number: invoice.number,
        vendor_id: invoice.vendor_id,
        bank_id: invoice.bank_id,
        date: invoice.date,
        description: invoice.description,
        gross_amount: invoice.gross_amount,
        is_in_balance: invoice.is_in_balance,
        entry_id: entry.id,
        cost_center: entry.cost_center,
        source_id: entry.source_id,
        source_type: entry.source_type,
        addl_source_id: entry.addl_source_id,
        addl_source_type: entry.addl_source_type,
        type_id: entry.type_id,
        type_type: entry.type_type,
        account: entry.account,
        subaccount: entry.subaccount,
      }
    },
    mapInvoice(invoice) {
      const firstEntry = invoice?.relationships?.entries[0]?.attributes || {}
      const { vendor_id, bank_id, date, description, gross_amount, is_in_balance, number } = invoice?.attributes || {}
      return {
        id: invoice.id,
        vendor_id,
        bank_id,
        number,
        date,
        description,
        gross_amount,
        is_in_balance,
        entry_id: firstEntry.id,
        cost_center: firstEntry.cost_center,
        source_id: firstEntry.source_id,
        source_type: firstEntry.source_type,
        addl_source_id: firstEntry.addl_source_id,
        addl_source_type: firstEntry.addl_source_type,
        type_id: firstEntry.type_id,
        type_type: firstEntry.type_type,
        account: firstEntry.account,
        subaccount: firstEntry.subaccount,
      }

    },
    async onViewClick(row) {
      try {
        row.loading = true
        const originalInvoice = this.originalRows.find(invoice => invoice.id === row.id)
        if (originalInvoice) {
          originalInvoice.attributes = {
            ...originalInvoice.attributes,
            ...row,
          }
          this.setCurrentEntity(originalInvoice)
        } else {
          const { data } = await axios.get(`/restify/invoices/${row.id}`)
          this.setCurrentEntity(data)
        }
      } finally {
        row.loading = false
      }
    },
    mapData(data) {
      this.originalRows = data
      return data.map(this.mapInvoice)
    }
  }
}
</script>
