<template>
  <div>
    <AgDataTable
      ref="oneOffChecksTable"
      :columns="tableColumns"
      :url="tableDataUrl"
      :urlParams="tableUrlParams"
      :add-text="$t('New One Off Check')"
      actions="add,refresh"
      domLayout="autoHeight"
      :selected-rows.sync="selectedRows"
      :actionsColumnWidth="150"
      :isRowSelectable="isRowSelectable"
      @add="openCreateDialog"
    >
      <template #additional-actions-before>
        <BankOneOffChecksPrintDialog
          ref="printDialog"
          :oneOffChecks="checksToPrint"
          :disabled="!selectedPrintableChecks.length"
          @close="printSingleCheck = null"
        />
      </template>
      <template #extra-actions="{ row }">
        <div class="flex flex-1 items-center">
          <PostButton
            v-if="$isAuthorized('authorizedToPost', row)"
            :title="$t('Post')"
            :loading="posting[row.id]"
            :showLabel="false"
            size="xs"
            class="ml-2"
            @click="postCheck(row)"
          />
          <TablePrintButton
            v-if="row.attributes.status === BankOneOffCheckStatuses.Posted"
            :title="$t('Print')"
            hasCallback
            :showLabel="false"
            size="xs"
            class="-mr-1"
            @on-action-callback="printCheck(row)"
          />
          <BaseButton
            v-if="getJournalId(row)"
            :title="$t('View Journal')"
            variant="primary-link"
            size="icon"
            class="ml-1"
          >
            <router-link
              :to="`/ledger/journal/${getJournalId(row)}/view`"
            >
              <EyeIcon class="w-4 h-4"/>
            </router-link>
          </BaseButton>
          <VoidButton
            v-if="$isAuthorized('authorizedToVoid', row)"
            :title="$t('Void')"
            :loading="voiding[row.id]"
            size="xs"
            @click="voidCheck(row)"
          />
        </div>
      </template>
    </AgDataTable>

    <BankOneOffChecksCreateDialog
      v-if="showCreateDialog"
      :open.sync="showCreateDialog"
      :bank="bank"
      @close="showCreateDialog = false"
      @created="onAfterCreate"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'
import axios from 'axios';
import Data = API.Data;
import { Bank } from '@/modules/common/types/models'
import BankOneOffChecksCreateDialog from "@/modules/settings/components/BankOneOffChecksCreateDialog.vue";
import BankOneOffChecksPrintDialog from "@/modules/settings/components/BankOneOffChecksPrintDialog.vue";
import { BankOneOffCheckStatuses } from "@/modules/settings/utils/bankUtils"
import { EyeIcon } from 'vue-feather-icons'

export default defineComponent({
  components: {
    BankOneOffChecksCreateDialog,
    BankOneOffChecksPrintDialog,
    EyeIcon,
  },
  props: {
    bank: {
      type: Object as PropType<Data<Bank>>,
      required: true,
    },
  },
  data() {
    return {
      BankOneOffCheckStatuses,
      printSingleCheck: null,
      tableColumns: [
        {
          headerCheckboxSelection: true,
          checkboxSelection: true,
          label: this.$t('Number'),
          field: 'attributes.number',
          minWidth: 300,
          maxWidth: 300,
        },
        {
          label: this.$t('Payable To'),
          field: 'attributes.payable_to_name',
          minWidth: 150,
        },
        {
          label: this.$t('Payable To Address'),
          field: 'attributes.payable_to_address',
          minWidth: 150,
          hide: true
        },
        {
          label: this.$t('Amount'),
          prop: 'attributes.amount',
          align: 'right',
          component: 'FormattedPrice',
          minWidth: 190,
          maxWidth: 190,
        },
        {
          label: this.$t('Date'),
          field: 'attributes.date',
          component: 'FormattedDate',
          minWidth: 180,
          maxWidth: 180,
        },
        {
          label: this.$t('Status'),
          field: 'attributes.status',
          component: 'Status',
          minWidth: 150,
          maxWidth: 150,
        },
      ],
      showCreateDialog: false,
      selectedRows: [],
      posting: {} as {[id: string]: boolean },
      voiding: {} as {[id: string]: boolean}, 
    }
  },
  computed: {
    tableDataUrl() {
      return `/restify/one-off-checks`
    },
    tableUrlParams() {
      return {
        sort: '-number',
        bank_id: this.bank.id
      }
    },
    selectedPrintableChecks() {
      return this.selectedRows.filter((row: any) => {
        return row.attributes.status === BankOneOffCheckStatuses.Posted
      })
    },
    checksToPrint() {
      if (this.printSingleCheck) {
        return [this.printSingleCheck]
      }
      return this.selectedPrintableChecks
    }
  },
  methods: {
    refreshTable() {
      // @ts-ignore
      this.$refs.oneOffChecksTable?.refresh()
    },
    async onAfterCreate({ check, print }: { check: any, print: boolean }) {
      this.showCreateDialog = false

      if (print) {
        this.printCheck(check)
      }

      this.refreshTable()

      await this.$store.dispatch('settings/getBank', this.bank.id)
    },
    openCreateDialog() {
      this.showCreateDialog = true
    },
    isRowSelectable(params: any) {
      return params.data.attributes.status === BankOneOffCheckStatuses.Posted
    },
    printCheck(row: any) {
      this.printSingleCheck = row
      // @ts-ignore
      this.$refs.printDialog?.openDialog()
    },
    getJournalId(row: any) {
      return row.attributes.journal_id 
    },
    async postCheck(row: any) {
      try {
        this.$set(this.posting, row.id, true)
        const id = row.id
        const url = `/restify/one-off-checks/${id}/actions?action=post-one-off-check`
        await axios.post(url)

        this.refreshTable()
      } catch (err: any) {
        if (err?.handled) {
          return
        }

        this.$error(this.$t('Could not post check.'))
      }
      finally {
        this.$set(this.posting, row.id, false)
      }
    },
    async voidCheck(row: any) {
      const confirmed = await this.$confirm({
        title: this.$t('Void Check'),
        description: this.$t('Are you sure you want to void this check? This action cannot be undone.'),
        buttonText: this.$t('Void'),
        type: 'danger',
      })

      if (!confirmed) {
        return
      }

      try {
        this.$set(this.voiding, row.id, true)
        const id = row.id
        const url = `/restify/one-off-checks/${id}/actions?action=void-one-off-check`
        await axios.post(url)

        this.refreshTable()
      } catch (err: any) {
        if (err?.handled) {
          return
        }

        this.$error(this.$t('Could not void check.'))
      }
      finally {
        this.$set(this.voiding, row.id, false)
      }
    },
  },
})
</script>
