// eslint-disable-next-line no-unused-vars
import {isExceptionDomain} from "@/isProduction";
import config from "@/modules/common/config";
import axios from "axios";
import router from "@/router";

let subdomainValid = false

async function isOrganizationValid() {
  try {
    if (subdomainValid || isExceptionDomain(config.ORGANIZATION_NAME)) {
      return true
    }
    await axios.get(`${config.ROOT_API_URL}/subdomain-check`, {
      params: {
        subdomain: config.ORGANIZATION_NAME
      }
    })
    subdomainValid = true
    return true
  } catch (err) {
    return false
  }
}

export async function checkOrganizationValidity() {
  const isValid = await isOrganizationValid()
  if (!isValid) {
    await router.push('/no-organization')
  }
}
