<template>
  <EquipmentClassForm/>
</template>
<script>
  import EquipmentClassForm from '@/modules/equipment/components/EquipmentClassForm'

  export default {
    components: {
      EquipmentClassForm,
    },
  }
</script>
