<template>
  <base-report-form
      :filters="model"
      :report-number="reportNumber"
  >

    <template #filters>

      <base-filter-row :title="$t('Format')">
        <base-select
            v-model="model.double_spaced"
            :options="formatOptions"
            :add-entity="false"
            class="col-span-3"
        />
      </base-filter-row>

      <base-filter-row>
        <base-checkbox
            v-model="selectAccountList"
            :label="$t('Select a List of Accounts')"
            class="col-span-3 mb-3"
            id="select_account_list"
            @change="resetAccountFilters"
        />
      </base-filter-row>

      <base-filter-row :title="$t('Accounts')">

        <account-select
            v-if="selectAccountList"
            v-model="model.account_ids"
            :placeholder="$t('Select Accounts')"
            class="col-span-4"
            collapse-tags
            multiple
        />

        <template v-else>
          <account-range-filter v-model="model"/>

          <base-checkbox
              v-model="model.exclude_inactive"
              :label="$t('Exclude Inactive Accounts')"
              class="col-span-4"
              id="exclude_inactive"
          />
        </template>

      </base-filter-row>

      <base-filter-row :title="$t('Fiscal Year')">
        <fiscal-year-select
          v-model="model.fiscal_year"
          :placeholder="$t('Fiscal Year')"
          class="col-span-6 md:col-span-2"
          :show-closed-years="true"
        />
      </base-filter-row>

    </template>

    <template #table="{data, loading}">

      <account-listing-report-table
          :data="data"
          :filters="model"
          :report-number="reportNumber"
          :data-loading="loading"
          ref="reportTable"
      />

    </template>

  </base-report-form>
</template>
<script lang="ts">

  import { defineComponent } from 'vue'
  import { AccountRangeFilter } from '@/components/range-filters'
  import AccountListingReportTable from '@/modules/ledger/components/reports/AccountListingReportTable.vue'

  const formatOptions = [
    {
      label: 'Single Spaced',
      value: false,
    },
    {
      label: 'Double Spaced',
      value: true,
    },
  ]

  export default defineComponent({
    components: {
      AccountRangeFilter,
      AccountListingReportTable,
    },
    data() {
      return {
        reportNumber: '1882',
        selectAccountList: false,
        model: <GL_REPORTS.AccountListing> {
          account_ids: [],
          account_number_from: null,
          account_number_to: null,
          fiscal_year: this.$currentFiscalYear,
          exclude_inactive: 0,
          double_spaced: false,
        },
        formatOptions,
      }
    },
    methods: {
      resetAccountFilters() {
        this.model.account_ids = []
        this.model.exclude_inactive = 0
        this.model.account_number_from = this.model.account_number_to = null
      },
    },
  })
</script>
