<template>
  <div class="relative bg-gray-50 overflow-hidden min-h-screen flex flex-col items-center justify-center">

    <div class="relative pt-6 pb-12 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">
      <div class="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28">
        <div class="text-center">
          <span class="mt-3 max-w-md mx-auto text-base text-gray-500 md:mt-5 text-xl md:max-w-3xl">
            {{$t(`Version:`)}} <span class="text-primary-500 font-semibold ml-2">{{version}}</span>
          </span>
          <div class="mt-5 max-w-md mx-auto flex justify-center md:mt-8">
            <base-button variant="white"
                         size="xl"
                         @click="openEmail">
              {{$t('Contact Us')}}
            </base-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      version: import.meta.env.PACKAGE_VERSION
    }
  },
  methods: {
    openEmail(){
      window.open(`mailto:support@constructionpartner.com?cc=russroy@constructionpartner.com&subject=Issue with deployed version`)
    }
  }
}
</script>
<style>
</style>
