<template>
  <div class="grid grid-cols-6 gap-x-3"
       v-bind="$attrs">
    <div class="col-span-6 md:col-span-3">
      <base-input v-model="model.name"
                  :label="$t('Display Name')"
                  :tip="$t('This is the name that will be displayed on the address list and in the address dropdowns')"
                  :placeholder="$t('Address Name')"/>
    </div>
    <div class="col-span-6">
      <base-textarea v-model="model.address_1"
                     :label="$t('Primary Address 1')"
                     :placeholder="$t('Address 1')"
                     :rows="2"
                     rules="required"
                     id="address_1"/>
    </div>
    <div class="col-span-6">
      <base-textarea v-model="model.address_2"
                     :label="$t('Primary Address 2')"
                     :placeholder="$t('Address 2')"
                     :rows="1"
                     id="address_2"/>
    </div>
    <div class="col-span-6 md:col-span-2">
      <base-input v-model="model.city"
                  :label="$t('City')"
                  rules="required|max:45"
                  placeholder="City"
                  id="city"/>
    </div>
    <div class="col-span-6 md:col-span-2">
      <state-select v-model="model.state"
                    :label="$t('State')"
                    rules="required|max:2"
                    placeholder="State"
                    id="state"/>
    </div>
    <div class="col-span-6 md:col-span-2">
      <base-input v-model="model.postal_code"
                  :label="$t('Postal Code')"
                  placeholder="Postal Code"
                  id="postal_code"
                  rules="required|zipCode"
      />
    </div>
  </div>
</template>
<script>
import { addressTypes } from '@/enum/enums'

export default {
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
    addressType: {
      type: String,
      default: addressTypes.Warehouse,
    }
  },
  data() {
    return {
      loading: false,
    }
  },
}
</script>
