<template>
  <entity-select
      v-bind="$attrs"
      v-on="$listeners"
      url="/restify/unions"
      :url-params="urlParams"
      :filter-options="filterOptions"
      :name="$attrs.name || $t('Union')"
      :placeholder="$attrs.placeholder || $t('Select Union')"
      :label="$attrs.label !== undefined ? $attrs.label : $t('Union')"
      :add-label="$t('Add new Union')"
      :add-entity="addEntity"
      :edit-entity="editEntity"
      :set-default-option="editEntity"
      :label-format="formatUnionLabel"
      :id="$attrs.id || 'union-select'"
      :table-columns="tableColumns"
      :hide-label="hideLabel"
  />
</template>
<script>
  export default {
    name: 'UnionSelect',
    inheritAttrs: false,
    props: {
      urlParams: {
        type: Object,
        default: () => ({
          sort: 'code',
        }),
      },
      addEntity: {
        type: Boolean,
        default: false,
      },
      editEntity: {
        type: Boolean,
        default: false,
      },
      hideLabel: {
        type: Boolean,
        default: false,
      },
      excludeIds: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        tableColumns: [
          {
            minWidth: 100,
            maxWidth: 150,
            name: this.$t('Code'),
            prop: 'attributes.code',
          },
          {
            minWidth: 200,
            maxWidth: 200,
            name: this.$t('Description'),
            prop: 'attributes.description',
          },
        ],
      }
    },
    methods: {
      filterOptions(options) {
        if (this.excludeIds.length) {
          return options.filter(o => {
            return !this.excludeIds.includes(o.id)
          })
        }
        return options
      },
      formatUnionLabel(union) {
        const {code, description} = union.attributes
        if (!description) {
          return code
        }
        return `${code} (${description})`
      },
    },
  }
</script>
