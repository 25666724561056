<template>
  <div>
    <layout-tabs :items="items"/>

    <div class="py-4">
      <router-view/>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        selectedOption: this.$route.path,
      }
    },
    computed: {
      items() {
        return [
          {
            name: this.$t('Details'),
            path: `/settings/users/${this.$route.params.id}/view`,
            authorizedTo: 'authorizedToShow',
          },
          {
            name: this.$t('Edit'),
            path: `/settings/users/${this.$route.params.id}/edit`,
            authorizedTo: 'authorizedToUpdate',
          },
        ]
      }
    },
    async mounted() {
      await this.$store.dispatch('user/getUserById', this.$route.params.id)
    },
  }
</script>
