<template>
  <SubAccountLink
      :data="get(row, column.prop)"
      :id="params.id"
      :show-description="showDescription"
      :number="params?.number"
      :target="params?.target"
      :is-link="!editableCell"
  />
</template>
<script>
  import SubAccountLink from "@/components/links/SubAccountLink";

  export default {
    components: {
      SubAccountLink,
    },
    props: {
      column: {
        type: Object,
        default: () => ({}),
      },
      row: {
        type: Object,
        default: () => ({}),
      },
      params: {
        type: Object,
        default: () => ({}),
      },
      editableCell: Boolean,
    },
    computed: {
      showDescription() {
        if (this.params.showDescription !== undefined) {
          return this.params.showDescription
        }
        return true
      }
    }
  }
</script>
