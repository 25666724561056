<template>
  <div
      :title="userName"
      class="truncate"
  >
    <router-link
        v-if="userId && !editableCell && $can('users_show')"
        :to="`/settings/users/${userId}/view`"
        :target="target"
    >
      <span>
        {{ userName }}
      </span>
    </router-link>
    <template v-else>
      {{ userName }}
    </template>
  </div>
</template>
<script>
  import { globalResources } from "@/components/form/util";

  export default {
    name: 'UserLink',
    props: {
      id: {
        required: true,
      },
      data: {
        type: Object,
      },
      target: String,
      editableCell: Boolean,
    },
    computed: {
      allUsers() {
        return this.$store?.getters['globalLists/getResourceList'](globalResources.Users) || []
      },
      userId() {
        return this.id || this.user?.id
      },
      user() {
        if (this.data) {
          return this.data
        }
        return this.allUsers.find(user => user.id === this.id)
      },
      userName() {
        return this.user?.name
      }
    }
  }
</script>
