<template>
  <job-transaction-form
      v-loading="journalLoading"
      v-if="journal.id"
      :data="journal"
      :type="type"
  />
</template>
<script>
  import JobTransactionForm from '@/modules/job-costing/components/JobTransactionForm'

  export default {
    components: {
      JobTransactionForm,
    },
    data() {
      return {
        type: this.$route.meta?.type,
      }
    },
    computed: {
      journal() {
        return this.$store.state.generalLedger.currentJournal
      },
      journalLoading() {
        return this.$store.state.generalLedger.journalLoading
      },
    },
  }
</script>
