import Vue from 'vue'
import i18n from '@/i18n'
import DeleteDialog from './DeleteDialog'
import VoidDialog from '@/components/common/modal/VoidDialog'
import { promptTypes } from "@/plugins/promptProps";

export function cleanup(componentInstance) {
  if (!componentInstance?.$el) {
    return
  }
  document.body.removeChild(componentInstance.$el)
}

export function $confirm(params = {}) {
  return new Promise((resolve, reject) => {
    try {
      const DeleteConstructor = Vue.extend(DeleteDialog)
      const mountId = 'confirm-modal'
      const componentInstance = new DeleteConstructor({
        i18n,
        propsData: {
          open: true,
          title: params.title || '',
          description: params.description || '',
          actionQuestion: params.actionQuestion || '',
          type: params.type || 'primary',
          deleteText: params.buttonText || 'Ok'
        },
      })
      const modalDiv = document.createElement('div');
      modalDiv.id = mountId
      document.body.appendChild(modalDiv)

      componentInstance.$mount(`#${mountId}`);
      componentInstance.$on('delete', () => {
        resolve(true);
      });
      componentInstance.$on('cancel', () => {
        resolve(false);
        cleanup(componentInstance)
      });

    } catch (err) {
      reject(err)
    }
  })
}

export function $voidConfirm(params = {}) {
  return new Promise((resolve, reject) => {
    try {
      const VoidConstructor = Vue.extend(VoidDialog)
      const mountId = 'void-modal'
      const componentInstance = new VoidConstructor({
        i18n,
        propsData: {
          visible: true,
          open: true,
          show: true,
          title: params.title || '',
          description: params.description || '',
        },
      })
      const modalDiv = document.createElement('div');
      modalDiv.id = mountId
      document.body.appendChild(modalDiv)

      componentInstance.$mount(`#${mountId}`);
      componentInstance.$on('save', (data) => {
        resolve(data);
      });
      componentInstance.$on('close', () => {
        resolve(false);
        cleanup(componentInstance)
      });

    } catch (err) {
      reject(err)
    }
  })
}

export function $deleteConfirm(params = {}) {
  return new Promise((resolve, reject) => {
    try {
      const DeleteConstructor = Vue.extend(DeleteDialog)
      const mountId = 'delete-modal'
      const componentInstance = new DeleteConstructor({
        i18n,
        propsData: {
          open: true,
          title: params.title || '',
          description: params.description || '',
          actionQuestion: params.actionQuestion || '',
          deleteText: params.buttonText || 'Delete',
          type: promptTypes.Danger,
        },
      })
      const modalDiv = document.createElement('div');
      modalDiv.id = mountId
      document.body.appendChild(modalDiv)

      componentInstance.$mount(`#${mountId}`);
      componentInstance.$on('delete', () => {
        resolve(true);
      });
      componentInstance.$on('cancel', () => {
        resolve(false);
        cleanup(componentInstance)
      });

    } catch (err) {
      reject(err)
    }
  })
}


export default {
  install(Vue) {
    Vue.prototype.$deleteConfirm = $deleteConfirm;
    Vue.prototype.$voidConfirm = $voidConfirm
    Vue.prototype.$confirm = $confirm
  }
}

