<template>

  <base-report-form
      :filters="model"
      :report-number="getReportNumber"
  >

    <template #filters>
      <common-report-filters v-model="model"/>

      <base-filter-row :title="$t('Format')">
        <base-select
            v-model="model.format"
            :add-entity="false"
            :options="formatOptions"
            class="col-span-3"
        />
      </base-filter-row>

      <period-year-range-filter
          v-if="[jobProfitReportFormats.Periods, jobProfitReportFormats.ActivePeriods].includes(model.format)"
          v-model="model"
      />

      <base-filter-row
          v-else
          :title="$t('Select')"
      >
        <base-select
            v-model="model.selection"
            :add-entity="false"
            :options="selectionOptions"
            class="col-span-3"
        />

        <period-year-filter
            v-if="model.selection === selections.EndOfPeriod"
            v-model="model"
            class="col-span-3"
        />
      </base-filter-row>

      <base-filter-row :title="$t('Job Date')">
        <base-select
            v-model="model.job_date"
            :add-entity="false"
            :options="jobDatesOptions"
            :placeholder="$t('Select Job Date')"
            class="col-span-3"
            clearable
        />

        <date-range-filter
            v-if="model.job_date"
            v-model="model"
            class="col-span-3"
        />
      </base-filter-row>
    </template>

    <template #table="{loading, data}">

      <JobProfitReportTable
          :data="data"
          :filters="model"
          :data-loading="loading"
          :format="model.format"
          ref="reportTable"
      />

    </template>
  </base-report-form>

</template>
<script>
  import CommonReportFilters from '@/modules/job-costing/components/reports/CommonReportFilters'
  import {
    PeriodYearRangeFilter,
    DateRangeFilter,
    PeriodYearFilter,
    periodYearFilterKeys,
  } from '@/components/range-filters'
  import { commonFilters, jobProfitReportFormats } from '@/modules/job-costing/components/reports/util'
  import JobProfitReportTable from '@/modules/job-costing/components/reports/job-profit-summary/JobProfitReportTable'

  const selections = {
    ToDate: 'to-date',
    EndOfPeriod: 'end-of-period',
  }

  const jobDates = {
    ContractStartDate: 'contract_start_date',
    ScheduleStartDate: 'schedule_start_date',
    ActualStartDate: 'actual_start_date',
    OriginalCompletion: 'original_completion_date',
    CurrentCompletion: 'current_completion_date',
    ActualCompletion: 'actual_completion_date',
    ScheduledCompletion: 'scheduled_completion',
    BOD: 'BOD_date',
    NOC: 'NOC_date',
  }

  const reportNumber = '2888'

  export default {
    components: {
      DateRangeFilter,
      CommonReportFilters,
      JobProfitReportTable,
      PeriodYearRangeFilter,
      PeriodYearFilter,
    },
    data() {
      return {
        jobProfitReportFormats,
        selections,
        jobDates,
        selectionOptions: [
          {
            label: this.$t('Job To-Date Values'),
            value: selections.ToDate,
          },
          {
            label: this.$t('Values thru End of Period'),
            value: selections.EndOfPeriod,
          },
        ],
        formatOptions: [
          {
            label: this.$t('Short w/Earned Revenue'),
            value: jobProfitReportFormats.Short,
          },
          {
            label: this.$t('Long w/Earned Revenue'),
            value: jobProfitReportFormats.Long,
          },
          {
            label: this.$t('Single Line Summary'),
            value: jobProfitReportFormats.SingleLine,
          },
          {
            label: this.$t('Prior and Current Period'),
            value: jobProfitReportFormats.Periods,
          },
          {
            label: this.$t('Period Active'),
            value: jobProfitReportFormats.ActivePeriods,
          },
        ],
        jobDatesOptions: [
          {
            label: this.$t('Contract Start Date'),
            value: jobDates.ContractStartDate,
          },
          {
            label: this.$t('Schedule Start Date'),
            value: jobDates.ScheduleStartDate,
          },
          {
            label: this.$t('Actual Start Date'),
            value: jobDates.ActualStartDate,
          },
          {
            label: this.$t('Original Completion Date'),
            value: jobDates.OriginalCompletion,
          },
          {
            label: this.$t('Current Completion Date'),
            value: jobDates.CurrentCompletion,
          },
          {
            label: this.$t('Actual Completion Date'),
            value: jobDates.ActualCompletion,
          },
          {
            label: this.$t('Scheduled Completion Date'),
            value: jobDates.ScheduledCompletion,
          },
          {
            label: this.$t('Beneficial Occupancy Date'),
            value: jobDates.BOD,
          },
          {
            label: this.$t('Notice of Compliance Date'),
            value: jobDates.NOC,
          },
        ],
        model: {
          ...commonFilters,

          start_date: null,
          end_date: this.$now,

          format: jobProfitReportFormats.Short,
          selection: selections.ToDate,

          ...periodYearFilterKeys,
        },
      }
    },
    computed: {
      getReportNumber() {
        return [jobProfitReportFormats.Periods, jobProfitReportFormats.ActivePeriods].includes(this.model.format) ? `${reportNumber}b` : reportNumber
      },
    },
  }
</script>
