import { reportRoutes as ledgerReportRoutes } from "@/modules/ledger/routes";
import { reportRoutes as jobCostReportRoutes } from "@/modules/job-costing/routes";
import { reportRoutes as apReportRoutes } from "@/modules/accounts-payable/routes";
import { reportRoutes as arReportRoutes } from "@/modules/accounts-receivable/routes";
import { reportRoutes as payrollReportRoutes } from "@/modules/payroll/routes";
import { reportRoutes as equipmentReportRoutes } from "@/modules/equipment/routes";
import InvoicesProofListing from "@/modules/accounts-payable/pages/invoices/invoices-proof-listing.vue";
import PrintLayout from "@/modules/print/layout/PrintLayout.vue";
import PaymentsProofListing from "@/modules/accounts-payable/pages/payments-proof-listing.vue";
import ProgressBillingProofListing
  from "@/modules/accounts-receivable/pages/billings/forms/progress/progress-billing-proof-listing.vue";
import UnitPriceBillingProofListing
  from "@/modules/accounts-receivable/pages/billings/forms/unit-price/unit-price-billing-proof-listing.vue";
import CostPlusBillingProofListing
  from "@/modules/accounts-receivable/pages/billings/forms/cost-plus/cost-plus-billing-proof-listing.vue";
import LumpSumBillingProofListing
  from "@/modules/accounts-receivable/pages/billings/forms/lump-sum/lump-sum-billing-proof-listing.vue";
import TimecardBatchProof from "@/modules/payroll/pages/timecard/timecard-batch-proof.vue";
import PayrollBatchPrint from "@/modules/payroll/pages/payroll/batch/payroll-batch-print.vue";
import PayrollPositivePay from "@/modules/payroll/pages/payroll/batch/payroll-positive-pay.vue";

let allReportRoutes = [
  ...ledgerReportRoutes,
  ...jobCostReportRoutes,
  ...apReportRoutes,
  ...arReportRoutes,
  ...payrollReportRoutes,
  ...equipmentReportRoutes,
]

allReportRoutes = allReportRoutes.map((route) => {
  return {
    ...route,
    name: `${route.name} Print`,
    title: undefined,
    description: undefined,
  }
})
export default [
  {
    path: '/print',
    name: 'Print',
    redirect: '/print',
    component: PrintLayout,
    children: [
      ...allReportRoutes,
      {
        path: 'invoices/proof-listing',
        name: 'Invoices Proof Listing ',
        component: InvoicesProofListing,
      },
      {
        path: 'payments/proof-listing',
        name: 'Payments Proof Listing ',
        component: PaymentsProofListing,
      },
      {
        path: 'progress/proof-listing',
        name: 'PB Proof Listing ',
        component: ProgressBillingProofListing,
      },
      {
        path: 'unit-price/proof-listing',
        name: 'UPB Proof Listing ',
        component: UnitPriceBillingProofListing,
      },
      {
        path: 'cost-plus/proof-listing',
        name: 'CPB Proof Listing Print',
        component: CostPlusBillingProofListing,
      },
      {
        path: 'lump-sum/proof-listing',
        name: 'Lump Sum Billings Proof Listing ',
        component: LumpSumBillingProofListing,
      },
      {
        path: 'timecard-batches/:id/proof-listing',
        name: 'Timecard Batch Proof Listing  ',
        component: TimecardBatchProof,
      },
      {
        path: 'batches/:id/summary',
        name: 'Payroll Register Summary',
        component: PayrollBatchPrint,
      },
      {
        path: 'batches/:id/positive-pay',
        name: 'Payroll Positive Pay Proof Listing',
        component: PayrollPositivePay,
      },
    ]
  },
]
