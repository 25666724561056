<template>
  <service-invoices-list
      :filters="urlParams"
      :resource-status="resourceStatuses.All"
  />
</template>
<script>
import ServiceInvoicesList from '@/modules/service-billing/pages/service-invoices/service-invoices-list'
import { resourceStatuses } from "@/enum/enums";

export default {
  components: {
    ServiceInvoicesList,
  },
  computed: {
    resourceStatuses() {
      return resourceStatuses
    },
    urlParams() {
      return {
        work_order_id: this.$route.params.id,
      }
    },
  }
}
</script>
