<template>
  <div v-loading="loading">
    <template v-if="summary && !loading">
      <div class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
        <div class="bg-white overflow-hidden shadow rounded-lg">
          <div class="px-4 py-5 sm:p-6">
            <div class="flex items-center">
              <div class="flex-shrink-0 bg-green-500 rounded-md p-3">
                <ActivityIcon class="h-6 w-6 text-white"/>
              </div>
              <div class="ml-5 w-0 flex-1">
                <dl>
                  <dt class="text-sm leading-5 font-medium text-gray-500 truncate">
                    {{ $t('Opening Balance') }}
                  </dt>
                  <dd class="flex items-baseline">
                    <div class="text-2xl leading-8 font-semibold text-gray-900">
                      {{ openBalance }}
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-white overflow-hidden shadow rounded-lg">
          <div class="px-4 py-5 sm:p-6">
            <div class="flex items-center">
              <div class="flex-shrink-0 bg-primary-500 rounded-md p-3">
                <ArrowRightCircleIcon class="h-6 w-6 text-white"/>
              </div>
              <div class="ml-5 w-0 flex-1">
                <dl>
                  <dt class="text-sm leading-5 font-medium text-gray-500 truncate">
                    {{ $t('Year End Balance') }}
                  </dt>
                  <dd class="flex items-baseline">
                    <div class="text-2xl leading-8 font-semibold text-gray-900">
                      {{ yearEndBalance }}
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5 grid grid-cols-1 gap-5 lg:grid-cols-2">
        <div class="bg-white shadow overflow-hidden sm:rounded-md flex col-span-1">
          <ul class="w-full">
            <SummaryCard v-for="(period, index) of filteredPeriods.slice(1, 7)"
                         :key="period.name"
                         :period-index="index"
                         :period="period"
                         :previous-periods="filteredPeriods.slice(0, index + 1)"
                         @view-transactions="onViewTransactions"/>
          </ul>
        </div>
        <div class="bg-white shadow overflow-hidden sm:rounded-md flex col-span-1">
          <ul class="w-full">
            <SummaryCard v-for="(period, index) of filteredPeriods.slice(7, 13)"
                         :key="period.name"
                         :period-index="index"
                         :period="period"
                         :previous-periods="filteredPeriods.slice(0, 6 + index + 1)"
                         @view-transactions="onViewTransactions"/>
          </ul>
        </div>
        <BaseFormDialog
          v-if="showDialog"
          :open.sync="showDialog"
          :has-padding="true"
          :title="$t(`History for ${currentPeriodName} `)"
          size="lg"
        >
          <TransactionsTable :url-params="transactionsParams" class="pt-4"/>
        </BaseFormDialog>
      </div>
    </template>
    <div v-else-if="!summary && !loading"
         class="flex justify-center mt-10 w-full text-3xl text-gray-700 font-medium col-span-3">
      {{ $t(`We couldn't find data for fiscal year ${activeFiscalYear}`) }}
    </div>
    <base-back-link to="/ledger/accounts"/>
  </div>
</template>
<script>
  import axios from 'axios'
  import sumBy from 'lodash/sumBy'
  import SummaryCard from '@/modules/ledger/components/SummaryCard'
  import AccountSelect from '@/modules/ledger/components/AccountSelect'
  import TransactionsTable from '@/modules/ledger/components/TransactionsTable'
  import { ActivityIcon, ArrowRightCircleIcon } from 'vue-feather-icons'

  export default {
    components: {
      SummaryCard,
      ActivityIcon,
      AccountSelect,
      TransactionsTable,
      ArrowRightCircleIcon,
    },
    data() {
      return {
        accountID: this.$route.params.id,
        summary: [],
        showDialog: false,
        loading: false,
        period: '',
      }
    },
    computed: {
      currentPeriodName() {
        if (!this.period) {
          return ''
        }
        return this.getPeriodName(this.period)
      },
      openBalance() {
        const amount = this.summary[0]?.amount || 0
        return this.$formatPrice(amount)
      },
      transactionsParams() {
        return {
          account_id: this.$route.params.id,
          period: this.period,
        }
      },
      yearEndBalance() {
        const amount = sumBy(this.summary, 'amount')
        return this.$formatPrice(amount)
      },
      filteredPeriods() {
        return this.summary.filter(p => p.period < 13)
      },
      currentAccount() {
        return this.$store.state.generalLedger.currentAccount || {}
      }
    },
    methods: {
      getPeriodName(period) {
        return this.$store.getters['company/getPeriodName'](period, 'originalLabel')
      },
      async getAccountSummary() {
        try {
          this.loading = true
          const { data } = await axios.get(`/restify/accounts/${this.accountID}?fiscal_year=${this.activeFiscalYear}`)
          this.composeSummary(data)
        } catch (e) {
          console.warn(e)
        } finally {
          this.loading = false
        }
      },
      composeSummary(data) {
        const attributes = data.attributes
        this.summary = []

        for (let i = 0; i < 14; i++) {
          this.summary.push({
            amount: attributes[`p${i}_amount`],
            period: i,
          })
        }
        this.focusOnFirstPeriod()
      },
      onViewTransactions(period) {
        this.period = period
        this.showDialog = true
      },
      focusOnFirstPeriod() {
        this.$nextTick(() => {
          const button = document.querySelector('#button-period-1')
          if (button) {
            button.focus()
          }
        })
      },
    },
    created() {
      this.composeSummary(this.currentAccount)
    },
    watch: {
      activeFiscalYear: {
        handler() {
          this.getAccountSummary()
        },
      },
      accountID: {
        handler(value) {
          this.$router.push(`/ledger/accounts/${value}/summary`)
        }
      },
      currentAccount(account) {
        this.composeSummary(account)
      }
    },
  }
</script>
<style>
  .account-select {
    min-width: 250px;
    max-width: 400px;
  }
</style>
