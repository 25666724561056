<template>
  <div>
    <BaseDataTable :columns="columns"
                   :show-actions-column="false"
                   :add-text="$t('New tax code')"
                   url="/restify/tax-codes"
                   :url-params="{ related: 'vendor' }"
                   default-sort="code"
                   actions="search,refresh,print"
                   ref="table"
    >
      <template #vendor.code="{row}">
        <entity-preview :entity="get(row, 'relationships.vendor.attributes')"
                        :key="`preview-${row.id}`"
                        :url="`/accounts-payable/vendors/${row.attributes.vendor_id}/view`"
                        :value-to-display="get(row, 'relationships.vendor.attributes.code', '')"
                        :parent-entity-id="row.id"
                        entity-name="vendor"
        />
      </template>
      <template #attributes.code="{row}">
        <div>
          <router-link
              v-if="taxLink(row)"
              :to="taxLink(row)"
              class="hover:underline hover:text-primary-500 text-gray-700 font-medium"
          >
            {{ row.attributes.code }}
          </router-link>
          <template v-else>
            {{ row.attributes.code }}
          </template>
        </div>
      </template>
    </BaseDataTable>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        rowToView: null,
        columns: [
          {
            label: this.$t('Code'),
            prop: 'attributes.code',
          },
          {
            label: this.$t('Description'),
            prop: 'attributes.description',
          },
          {
            label: this.$t('Vendor'),
            prop: 'vendor.code',
          },
        ],
      }
    },
    methods: {
      taxLink(row) {
        const { scope } = row.attributes
        if (scope === 'state') {
          return `/payroll/settings/state-taxes`
        } else if (scope === 'local') {
          return `/payroll/settings/local-taxes`
        }
        return ''
      }
    }
  }
</script>
