<template>
  <div class="flex items-center">
    <BaseTooltip
        :content="$t('Normal Checks')">
      <div class="flex flex items-center mr-3">
        <FileTextIcon class="w-5 h-5 mr-1"></FileTextIcon>
        <span>{{ batch.counts?.normal }}</span>
      </div>
    </BaseTooltip>
    <BaseTooltip
        v-if="batch.counts?.manual !== undefined"
        :content="$t('Manual Checks')">
      <div class="flex flex items-center mr-3">
        <PenToolIcon class="w-5 h-5 mr-1"></PenToolIcon>
        <span>{{ batch.counts?.manual }}</span>
      </div>
    </BaseTooltip>
  </div>
</template>
<script lang="ts">
  import { ArrowRightCircleIcon, FileTextIcon, PenToolIcon } from 'vue-feather-icons'
  import { TimeCardBatch } from "@/modules/payroll/components/timecard/timecardTypes";
  import { defineComponent, PropType } from 'vue';

  export default defineComponent({
    components: {
      FileTextIcon,
      PenToolIcon,
      ArrowRightCircleIcon,
    },
    props: {
      batch: {
        type: Object as PropType<TimeCardBatch>,
        default: () => ({})
      }
    }
  })
</script>
