<template>
  <div class="p-4">
    <BaseFormDialog v-if="showDialog"
                    :title="$t('Select accounts')"
                    :open.sync="showDialog"
                    :append-to-body="true"
    >
      <BaseForm
          layout="modal"
          :save-text="$t('Save')"
          @submit="onSubmit"
      >
        <div class="col-span-6">
          <AgDataTable
              actions="add"
              :add-text="$t('Add row')"
              v-bind="editableTableProps"
              :data="accountRangeData"
              :columns="columns"
              :get-empty-row="getNewRow"
              @grid-ready="grid = $event"
          >
          </AgDataTable>
        </div>
      </BaseForm>
    </BaseFormDialog>
  </div>
</template>
<script>
  import { selectEditorMixin } from "@/components/ag-grid/cellEditors/selectEditorMixin";
  import AccountRangeTags from "@/modules/ledger/components/financial-reports/AccountRangeTags.vue";
  import { editableTableProps, getTableData } from "@/components/ag-grid/tableUtils";
  import { cellClasses } from "@/components/ag-grid/columnUtils";
  import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";
  import { getDeleteColumn } from "@/components/ag-grid/columns/deleteColumns";

  export default {
    name: 'AccountMultipleRangeSelect',
    components: {
      AccountRangeTags
    },
    mixins: [selectEditorMixin],
    data() {
      return {
        showDialog: true,
        fromAccount: [],
        toAccount: [],
        grid: null,
      }
    },
    computed: {
      editableTableProps() {
        return editableTableProps
      },
      accountToValidator() {
        return this.fromAccount ? `required|min_value:${+this.fromAccount}` : ''
      },
      accountFromValidator() {
        return this.toAccount ? `required|max_value:${+this.toAccount}` : ''
      },
      accountRangeData() {
        return this.selectedValue.map(range => {
          const isArray = Array.isArray(range)
          const isRange = isArray && range.length === 2
          let from = range
          let to = isRange ? range[1] : null
          if (isRange) {
            from = range[0]
          } else if (!isRange && !isArray) {
            from = [range]
          }
          return {
            _localId: crypto.randomUUID(),
            from,
            to,
            isRange,
          }
        })
      },
      columns() {
        return [
          {
            headerName: this.$t('Range'),
            field: 'isRange',
            editable: true,
            component: 'Status',
            cellEditor: cellEditors.Boolean,
            valueSetter: (params) => {
              if (params.newValue === params.oldValue) {
                return true
              }
              params.data.from = null
              params.data.to = null
              params.data.isRange = Boolean(params.newValue)
              params.node.setData(params.data)
              return true
            },
          },
          {
            headerName: this.$t('From'),
            field: 'from',
            editable: true,
            cellEditor: cellEditors.AccountSelect,
            cellEditorParams: params => {
              return {
                valueKey: 'number',
                multiple: !params.data.isRange,
                stopNavigationOnChange: true,
                showLabelDescription: false,
                allowCreate: true,
              }
            },
            valueSetter: params => {
              params.data.from = params.newValue
              return true
            },
            cellClass: (params) => {
              const { isRange, from, to } = params.data
              const noAccount = !from
              const invalidRange = isRange && (+from > +to || !to)
              if (noAccount || invalidRange) {
                return cellClasses.Invalid
              }
              return ''
            },
            suppressKeyboardEvent: params => {
              let isTabKey = params.event.key === 'Tab'
              const isRange = params.data.isRange
              if (isTabKey && !isRange) {
                params.api.stopEditing()
              }
            }
          },
          {
            headerName: this.$t('To'),
            field: 'to',
            editable: params => {
              return params.data.isRange
            },
            cellEditor: cellEditors.AccountSelect,
            cellEditorParams: {
              valueKey: 'number',
              stopNavigationOnChange: true,
              showLabelDescription: false,
              allowCreate: true,
            },
            valueSetter: params => {
              params.data.to = params.newValue
              params.node.setData(params.data)
              return true
            },
            cellClass: (params) => {
              const { isRange, from, to } = params.data
              const noAccount = !to
              const invalidRange = isRange && +from > +to
              if (!isRange) {
                return cellClasses.ReadOnly
              }
              if (noAccount || invalidRange) {
                return cellClasses.Invalid
              }
              return ''
            },
            suppressNavigable: params => {
              return !params.data.isRange
            },
            suppressKeyboardEvent: params => {
              let isTabKey = params.event.key === 'Tab'
              if (isTabKey) {
                params.api.stopEditing()
              }
            },
          },
          {
            ...getDeleteColumn({
              title: this.$t(`Delete row`),
              description: this.$t(`Are you sure you want to delete this row`),
            })
          }
        ]
      }
    },
    methods: {
      getNewRow() {
        return {
          _localId: crypto.randomUUID(),
          from: null,
          to: null,
          isRange: true,
        }
      },
      convertTableData() {
        const data = getTableData(this.grid.api)
        return data.map(row => {
          return row.isRange ? [row.from, row.to] : row.from
        })
      },
      onSubmit() {
        this.selectedValue = this.convertTableData()
        this.showDialog = false
        this.params.api.stopEditing()
        this.params.api.tabToNextCell()
      }
    },
    watch: {
      showDialog(value) {
        if (!value) {
          this.params.api.stopEditing()
          this.params.api.tabToNextCell()
        }
      }
    }
  }
</script>
