<template>
  <BaseInput :label="label">
    <template #container>
      <gmap-autocomplete
        v-bind="$attrs"
        v-on="$listeners"
        @keydown.native.enter.prevent=""
      />
    </template>
  </BaseInput>
</template>
<script>
import Vue from 'vue'
import GmapVue from 'gmap-vue'

const apiKey = import.meta.env.VITE_MAPS_API_KEY
Vue.use(GmapVue, {
  load: {
    key: apiKey,
    libraries: 'places',
  },
  installComponents: true
})
export default {
  props: {
    label: String,
  },
}
</script>
<style lang="scss">
.pac-target-input {
  @apply form-input;
}
.pac-container {
  z-index: 2000;
}
</style>
