<template>
  <div class="mr-2 relative download-checks">
    <base-button variant="white"
                 :loading="loading"
                 @click="showTemplateSelect = !showTemplateSelect"
    >
      <DownloadIcon class="h-5 text-green-500 mr-2"/>
      {{ $t('Download unsent checks') }}
    </base-button>
    <check-template-select v-if="showTemplateSelect"
                           v-model="selectedTemplate"
                           class="absolute top-0 mt-2 bg-white px-4 z-10 shadow template-select">
      <template v-slot:title>
        {{$t('Template to download')}}
      </template>
      <div class="flex items-center justify-center my-3">
        <base-button variant="white"
                     :loading="loading"
                     @click="downloadChecks(selectedTemplate)"
        >
          <DownloadIcon class="h-5 text-green-500 mr-2"/>
          {{ $t('Download') }}
        </base-button>
      </div>
    </check-template-select>
  </div>
</template>
<script>
import { DownloadIcon } from 'vue-feather-icons'
import { downloadFile } from "@/modules/common/util/downloadFileLocally";
import CheckTemplateSelect from "@/modules/payroll/components/CheckTemplateSelect";

export default {
  components: {
    DownloadIcon,
    CheckTemplateSelect,
  },
  data() {
    return {
      loading: false,
      showTemplateSelect: false,
      selectedTemplate: this.$store.state.payroll.defaultChecksTemplate || 'box'
    }
  },
  methods: {
    async downloadChecks() {
      try {
        this.loading = true
        this.showTemplateSelect = false
        const journal = this.$route.params.id
        const fileName = `Unsent-Checks-${journal}.pdf`
        await downloadFile(`/restify/employee-checks/journals/${journal}/download-unsent?template=${this.selectedTemplate}`, fileName)
      } catch (err) {
        this.$error(this.$t('Could not download the pdf with unsent checks'))
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss">
.download-checks .template-select {
  min-width: 320px;
  top: 40px;
  right: 0;
}
</style>
