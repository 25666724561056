<template>
  <ProofListing
      entity="billing"
      url="/restify/billings/actions?action=post-unit-price-billings-proof-listing"
      post-url="/restify/billings/action?action=post-billings"
      ids-field="billing_ids"
      @data-fetch="data = $event"
      #default="{ loading }"
  >
    <AgDataTable
        :data="flatData"
        :data-loading="loading"
        :columns="columns"
        :pagination="false"
        :groupDefaultExpanded="-1"
        :compact="true"
        :no-borders="true"
        :groupIncludeFooter="true"
        :suppressAggFuncInHeader="true"
        :sortable="false"
        :groupRowRendererParams="groupRowRendererParams"
        groupDisplayType="groupRows"
        domLayout="autoHeight"
    />

    <div class="grid md:grid-cols-2  gap-4 mt-8 print:break-inside-avoid">
      <div>
        <h5 class="form-section-title">
          {{ $t('Account Summary') }}
        </h5>
        <ProofListingAccountSummary
            :data="data.account_summary"
            :loading="loading"
        />
      </div>
      <div>
        <h5 class="form-section-title">
          {{ $t('Totals') }}
        </h5>
        <AgDataTable
            :data="totalsData"
            :columns="totalColumns"
            :pagination="false"
            :no-borders="true"
            :compact="true"
            dom-layout="autoHeight"
        />
      </div>
    </div>
  </ProofListing>
</template>
<script>
  import PostDialog from "@/components/common/modal/PostDialog";
  import ProofListing from "@/modules/common/components/proof-listing/ProofListing";
  import ProofListingAccountSummary from "@/modules/common/components/ProofListingAccountSummary";
  import BillingProofGroupRow from "@/modules/accounts-receivable/components/billings/BillingProofGroupRow.vue";

  export default {
    components: {
      PostDialog,
      ProofListing,
      ProofListingAccountSummary,
      BillingProofGroupRow,
    },
    data() {
      return {
        loading: false,
        data: {
          billings: [],
          account_summary: [],
          summary: {},
        },
      }
    },
    computed: {
      totalsData() {
        const {
          summary,
        } = this.data;
        return [
          {
            id: crypto.randomUUID(),
            name: this.$t('Work Completed This Period'),
            amount: summary?.work_completed_this_period?.amount
          },
          {
            id: crypto.randomUUID(),
            name: this.$t('Retention Change'),
            amount: summary?.retention_change?.amount
          },
          {
            id: crypto.randomUUID(),
            name: this.$t('Sales Tax'),
            amount: summary?.sales_tax?.amount
          },
          {
            id: crypto.randomUUID(),
            name: this.$t('Net Period Billing'),
            amount: summary?.net_period_billing?.amount
          },
        ]
      },
      totalColumns() {
        return [
          {
            headerName: this.$t('Total'),
            field: 'name',
            minWidth: 120,
          },
          {
            headerName: this.$t('Amount'),
            field: 'amount',
            minWidth: 150,
            align: 'right',
            component: 'FormattedPrice',
            cellClass: params => {
              if (params.rowIndex === this.totalsData.length - 1) {
                return 'font-bold flex justify-end'
              }
              return 'flex justify-end'
            }
          },
        ]
      },
      flatData() {
        const allEntries = []
        this.data.billings.forEach(billing => {
          const { customer } = billing
          billing.entries.forEach(entry => {
            allEntries.push({
              ...entry,
              billing,
              customer,
              gross_amount: +entry.gross_amount,
              sales_tax_amount: +entry.sales_tax_amount,
            })
          })
        })
        return allEntries
      },
      groupRowRendererParams() {
        return {
          innerRenderer: 'BillingProofGroupRow',
        }
      },
      columns() {
        return [
          {
            headerName: this.$t('Billing'),
            field: 'billing.number',
            minWidth: 200,
            maxWidth: 320,
            rowGroup: true,
            hide: true,
          },

          {
            headerName: this.$t('Contract'),
            children: [
              {
                headerName: this.$t('Line Item'),
                field: 'line_item',
                component: 'AddlSourceLink',
                cellRendererParams: {
                  showDescription: false,
                },
                minWidth: 80,
                maxWidth: 100,
              },
              {
                headerName: this.$t('Description'),
                field: 'description',
                wrapText: true,
                minWidth: 160,
                maxWidth: 250,
                cellRenderer: params => {
                  if (params.node.footer) {
                    return this.$t('Total')
                  }
                  return params.data.description
                }
              },
              {
                headerName: this.$t('Quantity'),
                field: 'jobBudget.quantity',
                valueFormatter: params => {
                  if (params.node.footer || params.node.group) {
                    return
                  }
                  const { quantity } = params.data.jobBudget
                  const { um } = params.data
                  return `${quantity} ${um}`
                },
                align: 'right',
                minWidth: 110,
                maxWidth: 150,
              },
              {
                headerName: this.$t('Unit Price'),
                field: 'unit_rate',
                valueGetter: params => {
                  return +params.data.unit_rate
                },
                component: 'FormattedPrice',
                cellRendererParams: {
                  hideZero: true,
                },
                align: 'right',
                minWidth: 90,
                maxWidth: 150,
                aggFunc: 'sum',
              },
            ]
          },
          {
            headerName: this.$t('Quantity'),
            children: [
              {
                headerName: this.$t('Prior'),
                field: 'jobBudget.quantity_to_date',
                valueGetter: params => {
                  if (!params.data) {
                    return
                  }
                  return +(params.data.jobBudget.quantity_to_date || 0)
                },
                minWidth: 60,
                maxWidth: 80,
                align: 'right',
              },
              {
                headerName: this.$t('Current'),
                field: 'quantity',
                valueGetter: params => {
                  if (!params.data) {
                    return
                  }
                  return +params.data.quantity
                },
                minWidth: 60,
                maxWidth: 80,
                align: 'right',
              },
            ]
          },
          {
            headerName: this.$t('Billing'),
            children: [
              {
                headerName: this.$t('Prior'),
                field: 'jobBudget.amount_to_date',
                component: 'FormattedPrice',
                cellRendererParams: {
                  hideZero: true,
                },
                cellClass: 'bg-gray-50 flex justify-end',
                valueGetter: params => {
                  return +params.data.jobBudget.amount_to_date
                },
                minWidth: 100,
                maxWidth: 150,
                aggFunc: 'sum',
              },
              {
                headerName: this.$t('Current'),
                field: 'gross_amount',
                valueGetter: params => {
                  return +params.data.gross_amount
                },
                minWidth: 100,
                maxWidth: 150,
                component: 'FormattedPrice',
                cellRendererParams: {
                  hideZero: true,
                },
                align: 'right',
                aggFunc: 'sum',
              },
              {
                headerName: this.$t('To Date'),
                hide: this.$isPrintPage,
                field: 'to_date',
                valueGetter: params => {
                  if (params.node.footer || params.node.group) {
                    return 0
                  }
                  return +params.data.gross_amount + +params.data.jobBudget.amount_to_date
                },
                valueFormatter: params => {
                  if (params.node.footer || params.node.group) {
                    return this.$formatPrice(params.node.aggData?.to_date)
                  }
                  if (params.value === 0) {
                    return ''
                  }
                  return this.$formatPrice(params.value)
                },
                align: 'right',
                minWidth: 100,
                maxWidth: 150,
                aggFunc: 'sum',
              },
            ]
          },
          {
            headerName: 'Retention',
            children: [
              {
                headerName: this.$t('Prior'),
                field: 'jobBudget.retention_amount',
                valueGetter: params => {
                  return +params.data.jobBudget.retention_amount
                },
                component: 'FormattedPrice',
                cellRendererParams: {
                  hideZero: true,
                },
                minWidth: 100,
                maxWidth: 150,
                aggFunc: 'sum',
                cellClass: 'bg-gray-50 flex justify-end',
              },
              {
                headerName: this.$t('Current'),
                field: 'retention_amount',
                valueGetter: params => {
                  return +params.data.retention_amount
                },
                component: 'FormattedPrice',
                cellRendererParams: {
                  hideZero: true,
                },
                align: 'right',
                minWidth: 100,
                maxWidth: 150,
                aggFunc: 'sum',
              },
              {
                headerName: this.$t('To Date'),
                hide: this.$isPrintPage,
                field: 'retention_to_date',
                valueGetter: params => {
                  return +params.data.retention_to_date
                },
                component: 'FormattedPrice',
                cellRendererParams: {
                  hideZero: true,
                },
                align: 'right',
                minWidth: 100,
                maxWidth: 150,
                aggFunc: 'sum',
              },
            ]
          }
        ]
      }
    },
  }
</script>
