<template>
  <div>
    <div
        class="flex justify-end space-x-3 text-sm">
      <dl>
        <dt>{{ firstLabel }}:</dt>
        <dt>{{ secondLabel }}:</dt>
      </dl>
      <dl>
        <dt>
          {{ formatPrice(firstAmount) }}
        </dt>
        <dt>
          {{ formatPrice(secondAmount) }}
        </dt>
        <dt
            class="text-xs font-semibold mt-1"
            :class="{
              'text-red-500': (incomeAmount - costAmount) < 0,
              'text-green-500': (incomeAmount - costAmount) > 0,
            }"
        >
          {{ formatPrice(amountDifference) }}
        </dt>
      </dl>
    </div>
  </div>
</template>
<script lang="ts" setup>
  import i18n from '@/i18n'
  import get from 'lodash/get'
  import { PropType, computed } from 'vue'
  import { costTypes } from '@/enum/enums'
  import { formatPrice } from '@/plugins/formatPrice'
  import {costOrIncomeTypes} from "@/components/grid-table/utils/cost-center";

  const props = defineProps({
    row: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },
    lineItemType: {
      type: String as PropType<string>,
      required: true,
    },
    amountKey: {
      type: String as PropType<string>,
      required: true,
      default: 'amount',
    },
  })

  const linkTotals = computed(() => {
    return get(props.row, 'link_totals', {})
  })

  const linkAmount = computed(() => {
    return linkTotals.value[props.amountKey] || 0
  })

  const isIncomeType = computed(() => {
    return props.lineItemType === costTypes.Income
  })

  const rowAmount = computed(() => {
    return props.row[props.amountKey] || 0
  })

  const costAmount = computed(() => {
    return isIncomeType.value ? linkAmount.value : rowAmount.value
  })

  const incomeAmount = computed(() => {
    return isIncomeType.value ? rowAmount.value : linkAmount.value
  })

  const amountDifference = computed(() => {
    const difference = costAmount.value - incomeAmount.value
    return Math.abs(difference)
  })

  const firstAmount = computed(() => {
    return props.lineItemType === costOrIncomeTypes.Cost ? costAmount.value : incomeAmount.value
  })
  const secondAmount = computed(() => {
    return props.lineItemType === costOrIncomeTypes.Cost ? incomeAmount.value : costAmount.value
  })

  const firstLabel = computed(() => {
    return props.lineItemType === costOrIncomeTypes.Cost ? i18n.t('Cost') : i18n.t('Income')
  })

  const secondLabel = computed(() => {
    return props.lineItemType === costOrIncomeTypes.Cost ? i18n.t('Income') : i18n.t('Cost')
  })

</script>
