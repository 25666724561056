<template>
  <div>
    <AgDataTable
      url="/restify/timesheet-entries"
      :url-params="finalUrlParams"
      :columns="columns"
      actions="search,refresh"
      :groupDefaultExpanded="-1"
      :groupIncludeFooter="true"
      :groupIncludeTotalFooter="true"
      :suppressAggFuncInHeader="true"
      :groupRowRendererParams="groupRowRendererParams"
      groupDisplayType="groupRows"
      domLayout="autoHeight"
    >
      <template #attributes.date="{row, params}">
        <div>
        <span v-if="row">
          {{ row.attributes?.notes }}
        </span>
          <span v-if="!row && params.node.level === 0">
            {{ $t('Totals') }}
          </span>
          <span v-if="!row && params.node.level === -1">
            {{ $t('Grand Totals') }}
          </span>
        </div>
      </template>
      <template #attributes.status="{row}">
        <TimesheetStatus :params="{data: row?.relationships?.timesheet}"/>
      </template>
    </AgDataTable>
  </div>
</template>
<script>
import parse from "date-fns/parse";
import format from "date-fns/format";
import { LongDateFormat } from "@/plugins/dateFormatPlugin";
import TimesheetEntryGroupRow from "@/modules/payroll/components/timesheets/TimesheetEntryGroupRow.vue";
import { minutesToHours } from "@/modules/payroll/utils/timeCardUtils";
import TimesheetDialog from "@/modules/payroll/components/timesheets/TimesheetDialog.vue";
import TimesheetStatus from "@/components/table/cells/TimesheetStatus.vue";

export default {
  components: {
    TimesheetStatus,
    TimesheetDialog,
    TimesheetEntryGroupRow
  },
  props: {
    urlParams: {
      type: Object,
      default: () => ({})
    },
    showSource: {
      type: Boolean,
      default: false,
    },
    showAddlSource: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      LongDateFormat
    }
  },
  computed: {
    finalUrlParams() {
      return {
        related: 'timesheet',
        sort: '-date',
        ...this.urlParams,
      }
    },
    columns() {
      return [
        {
          field: 'attributes.timesheet_id',
          rowGroup: true,
          hide: true,
        },
        {
          headerName: this.$t('Notes'),
          field: 'attributes.notes',
          minWidth: 200,
        },
        {
          headerName: this.$t('Source'),
          field: 'attributes.source_id',
          component: 'SourceLink',
          minWidth: 200,
          maxWidth: 350,
          hide: !this.showSource,
        },
        {
          headerName: this.$t('Line Item'),
          field: 'attributes.addl_source_id',
          component: 'AddlSourceLink',
          minWidth: 130,
          maxWidth: 350,
          hide: !this.showAddlSource,
        },
        {
          headerName: this.$t('Status'),
          field: 'attributes.status',
          component: 'TimesheetStatus',
          minWidth: 100,
          maxWidth: 150,
        },
        {
          headerName: this.$t('Duration'),
          field: 'attributes.duration',
          minWidth: 130,
          maxWidth: 200,
          valueFormatter: params => {
            const duration = params.value || 0
            return minutesToHours(duration)
          },
          aggFunc: 'sum',
        },
        {
          headerName: this.$t('Start Time'),
          field: 'attributes.clocked_in_at',
          minWidth: 100,
          maxWidth: 150,
          valueFormatter: params => {
            if (!params.data) {
              return
            }
            const start = params.data?.attributes?.start_time
            let startTime = parse(start, 'HH:mm:ss', new Date())
            return format(startTime, 'hh:mm aa')
          },
        },
        {
          headerName: this.$t('End Time'),
          field: 'attributes.end_time',
          minWidth: 100,
          maxWidth: 150,
          valueFormatter: params => {
            if (!params.data) {
              return
            }
            const end = params.data?.attributes?.end_time
            let startTime = parse(end, 'HH:mm:ss', new Date())
            return format(startTime, 'hh:mm aa')
          },
        },
      ]
    },
    groupRowRendererParams() {
      return {
        innerRenderer: 'TimesheetEntryGroupRow',
        suppressCount: true,
      }
    }
  }
}
</script>
