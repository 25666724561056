<template>
  <span class="flex space-x-2 my-2">
    <UserLink :id="data.attributes?.created_by"/>
    <span>{{ $t('updated') }}  {{ $tc('fields', fieldCount) }}</span>
    <span class="font-medium capitalize">
        <template v-if="formattedName">
          {{formattedName}}
        </template>
        <template v-else>
        {{ formattedText(module) }} {{ $t('Settings') }}
        </template>
    </span>
    <span>{{ timeAgo(data.attributes.updated_at) }} {{ $t('ago') }}</span>
  </span>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { formattedText } from 'src/utils/utils'
  import { timeAgo } from 'src/plugins/dateFormatPlugin'

  export default defineComponent({
    methods: {
      timeAgo,
      formattedText,
    },
    computed: {
      data(): {} {
        return this.params.node?.allLeafChildren[0]?.data || {}
      },
      module(): string {
        return this.params.module
      },
      formattedName(): string {
        return this.params.name
      },
      fieldCount() {
        return Object.keys(this.data?.attributes?.current || {} ).length || 0
      }
    },
  })
</script>
