<template>
  <div class="grid grid-cols-5 gap-4">
    <div class="col-span-1">
      <router-link
        :to="`/payroll/employees/${line.employee_id}/rates/${line.rate_type}`"
      >
        {{ line.description }}
      </router-link>
    </div>
    <div class="col-span-1 flex flex-wrap justify-between">
      <div class="overflow-hidden">{{ calculateByLabel }} ({{ formattedFrequency }})</div>
      <div>{{ formattedRate }}</div>
    </div>
    <div class="col-span-1 flex flex-wrap justify-between">
      <div> {{ accumulationLimitLabel }}</div>
      <div>
        {{ $formatPrice(line.limit_on_accumulated_amount) }}
      </div>
    </div>
    <div class="col-span-1 flex flex-wrap justify-between">
      <div>{{ $t('Period Limit') }}</div>
      <div>
        {{ $formatPrice(line.period_limit_amount) }}
      </div>
    </div>
    <div class="col-span-1 flex flex-wrap justify-between">
      <div>{{ $t('To-Date') }}</div>
      <div>
        {{ $formatPrice(line.accumulated_amount_to_date) }}
      </div>
    </div>
  </div>
</template>
<script setup>
import i18n from '@/i18n'
import { computed } from 'vue'
import { formatPrice } from '@/plugins/formatPrice.js'
import { formatPercent } from '@/plugins/formatPercent'

import {
  rateTypeFrequencyOptions,
  CALCULATE_BY_PERCENTAGE_KEY,
  calculateByOptions,
} from '@/modules/payroll/components/rates/util'

const props = defineProps({
  line: {
    type: Object,
    required: true
  }
})

const calculateByLabel = computed(() => {
  return calculateByOptions.find(o => o.value === props.line.calculate_by)?.label || i18n.t('Rate')
})

const formattedRate = computed(() => {
  if (props.line.calculate_by === CALCULATE_BY_PERCENTAGE_KEY) {
    return formatPercent(props.line.rate)
  }

  return formatPrice(props.line.rate, { maximumFractionDigits: 3 })
})

const formattedFrequency = computed(() => {
  return rateTypeFrequencyOptions[props.line.rate_type].find(o => o.value === props.line.frequency)?.label || props.line.frequency
})

const accumulationLimitLabel = computed(() => {
  const labels = {
    'annual': i18n.t('Annual Limit'),
    'monthly': i18n.t('Monthly Limit'),
    'cumulative': i18n.t('Cumm Limit'),
  }

  return labels[props.line.accumulation_interval] || i18n.t('Limit')
})
</script>
