<template>
  <div :class="{'flex items-center space-x-2 inline-switch': inline}">
    <slot name="label-info">
      <label v-if="labelInfo"
             :class="{
                'mb-3': !inline,
                'mb-0': !inline,
             }"
             class="block text-sm font-medium leading-5 text-gray-700">
        {{ $t(labelInfo) }}
      </label>
    </slot>
    <div :tabindex="0"
         :aria-checked="model"
         :class="{
                'bg-gray-200': !model,
                'bg-gray-800': (model && !disabled),
                'bg-gray-300 cursor-not-allowed focus:shadow-none': disabled
              }"
         class="relative mt-1 flex flex-shrink-0 h-6 w-14 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring"
         role="checkbox"
         @click="triggerToggle()"
         @keydown.space.prevent="triggerToggle()"
         @blur="$emit('blur', $event)"
    >
      <span
        v-if="showYesNoLabel"
        class="text-xs absolute top-1/2 -translate-y-1/2"
        :class="{
          'text-white left-1': model,
          'text-gray-700 right-1': !model,
        }"
      >
        {{ model ? $t('Yes') : $t('No') }}
      </span>
      <span
        aria-hidden="true"
        :class="{ 'translate-x-8': model, 'translate-x-0': !model }"
        class="inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200"
      />
    </div>
  </div>
</template>
<script>
  export default {
    name: 'base-switch',
    props: {
      value: {
        type: [Array, Boolean, String, Number],
        description: 'Switch value',
        default: false,
      },
      disabled: {
        type: [Boolean, String],
        description: 'Whether switch is disabled',
      },
      labelInfo: {
        type: [String],
        default: '',
      },
      inline: Boolean,
      showYesNoLabel: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      model: {
        get() {
          if (typeof this.value === 'string') {
            return this.value === '1'
          }
          return this.value
        },
        set(value) {
          if (typeof this.value === 'string') {
            this.$emit('input', value ? '1' : '0')
            this.$emit('change', value ? '1' : '0')
          } else {
            this.$emit('input', value)
            this.$emit('change', value)
          }
        },
      },
    },
    methods: {
      triggerToggle() {
        if (this.disabled) {
          return
        }
        this.model = !this.model
      },
    },
  }
</script>
<style scoped>
  .inline-switch label {
    @apply mb-0;
  }
</style>
