<template>
  <BaseWrapper v-bind="$attrs"
               :items="items"
               :entity="entity"
               :footer="footer"
               title-key="name"
               status-key="status"
  />
</template>
<script>
import BaseWrapper from '@/modules/common/components/entity-preview/BaseWrapper'

export default {
  components: {
    BaseWrapper,
  },
  props: {
    entity: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      footer: {
        email: true,
        phone: true,
        address: true,
      },
    }
  },
  computed: {
    items() {
      return [
        {
          title: this.$t('Open Amount'),
          value: this.$formatPrice(this.entity.current_ar_amount),
        },
        {
          title: this.$t('Retention Amount'),
          value: this.$formatPrice(this.entity.retention_amount),
        },
        {
          title: this.$t('Sales This Year'),
          value: this.$formatPrice(this.entity.sales_activity_this_year),
        },
      ]
    }
  }
}
</script>
