<template>
  <base-input ref="input"
              v-on="listeners"
              v-bind="$attrs"
              :placeholder="$attrs.placeholder || '555-987-6543'"
              :label="$attrs.label !== undefined ? $attrs.label : $t('Phone')"
              :name="$attrs.name || $t('Phone')"
              :id="$attrs.id || 'phone-input'"
              v-input-mask="phoneMask"
              :rules="validationRules"
              :value="displayValue"
  >
    <template v-slot:suffix>
      <PhoneIcon class="w-4 h-4"/>
    </template>
  </base-input>
</template>
<script>
  import tokens from 'vue-the-mask/src/tokens'
  import masker from 'vue-the-mask/src/masker'
  import { PhoneIcon } from 'vue-feather-icons'

  export default {
    inheritAttrs: false,
    components: {
      PhoneIcon,
    },
    props: {
      value: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        isReadOnly: true,
        displayValue: this.value,
        phoneMask: '###-###-####',
      }
    },
    computed: {
      validationRules() {
        return {
          regex: /^([0-9]{3}-[0-9]{3}-[0-9]{4})$/,
        }
      },
      listeners() {
        return {
          ...this.$listeners,
          input: this.onInput,
        }
      },
    },
    methods: {
      onInput(val) {
        this.displayValue = val
        let rawValue = masker(val, this.phoneMask, false, tokens)
        this.$emit('input', rawValue)
      },
      formatDisplayValue() {
        setTimeout(() => {
          this.displayValue = masker(this.value, this.phoneMask, true, tokens)
        }, 1000)
      },
    },
    mounted() {
      this.formatDisplayValue()
    },
  }
</script>
