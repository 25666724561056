<template>
  <RecurringInvoiceForm
    v-if="invoice"
    :invoice="invoice"
    :collapse-header="true"
    @refresh="getInvoice"
  />
</template>
<script>

import RecurringInvoiceForm from "@/modules/accounts-payable/components/recurring-invoice/RecurringInvoiceForm.vue";

export default {
  components: {
    RecurringInvoiceForm,
  },
  computed: {
    invoice() {
      return this.$store.state.accountsPayable.currentRecurringInvoice
    },
  },
  methods: {
    getInvoice() {
      this.$store.dispatch('accountsPayable/getRecurringInvoice', this.$route.params.id)
    },
  },
  watch: {
    invoice: {
      deep: true,
      immediate: true,
      handler(value) {
        if (!value.id) {
          return
        }
        const { meta } = value || {}
        if (meta.hasOwnProperty('authorizedToUpdate') && !meta.authorizedToUpdate) {
          this.$router.push(`/accounts-payable/recurring-invoices/${value.id}/view`)
        }
      },
    },
  },
}
</script>
