<template>
  <BillingRatesForm :filters="{}"/>
</template>
<script>
  import BillingRatesForm from '@/modules/accounts-receivable/components/customer-billing-rates/BillingRatesForm'

  export default {
    components: {
      BillingRatesForm,
    },
  }
</script>
