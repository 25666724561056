<template>
  <div>
    <InvoiceDetailsPage
        :data="currentBilling"
        :loading="billingLoading"
        entity="billings"
    >
      <template #title>
        <invoice-status-popover
            :invoice="currentBilling"
            placement="right"
            resource-name="billings"
            class="print:hidden"
        />
      </template>
      <template #header-left>
        <div class="flex flex-col space-y-4 md:flex-row md:space-x-2 md:space-y-0">
          <CompanyContactInfo :title="$t('From')" :company="$currentCompany"/>
          <CustomerContactInfo :title="$t('Invoice For')" :customer="customer"/>
        </div>
      </template>
      <template #header-right-extra>
        <slot name="header-right-extra" :billing="billing" :billing-entries="getEntries"/>
      </template>
      <template #content>
        <slot
            v-if="!billing.meta?.is_initialization"
            :billing="billing"
            :billing-entries="getEntries"
            name="content"
        />
        <InvoicePayments
            v-if="billing.id && showPaymentsTable(billing)"
            :data="billingPayments"
            :class="{
              'opacity-50': billing.is_voided
            }"
            module="accounts-receivable"
            class="mt-4"
        />
      </template>
    </InvoiceDetailsPage>
    <base-back-link to="/accounts-receivable/billings/"/>
  </div>
</template>

<script>
  import InvoiceDetailsPage from '@/components/invoice/InvoiceDetailPage'
  import CompanyContactInfo from '@/modules/accounts-payable/components/invoice/CompanyContactInfo'
  import CustomerContactInfo from '@/modules/accounts-receivable/components/CustomerContactInfo'
  import InvoicePayments from '@/modules/accounts-payable/components/invoice/InvoicePayments.vue'
  import { showPaymentsTable } from '@/modules/accounts-payable/pages/invoices/invoiceUtils'

  export default {
    components: {
      InvoicePayments,
      CompanyContactInfo,
      CustomerContactInfo,
      InvoiceDetailsPage,
    },
    computed: {
      getEntries() {
        const entries = this.get(this.billing, 'entries', [])
        return entries.map(entry => entry.attributes)
      },
      currentBilling() {
        return this.$store.state.accountsReceivable.currentBilling
      },
      billingPayments() {
        return this.get(this.billing, 'payments', [])
      },
      billing() {
        return {
          ...this.currentBilling?.attributes || {},
          ...this.currentBilling?.relationships || {},
        }
      },
      billingLoading() {
        return !!this.$store.state.accountsReceivable.billingLoading
      },
      customer() {
        const customerData = this.billing['customer'] || { attributes: {}, relationships: {} }
        const addresses = this.billing['customer.addresses']?.addresses || []
        return {
          ...customerData?.attributes,
          ...customerData?.relationships,
          addresses,
        }
      },
    },
    methods: {
      showPaymentsTable,
    },
  }
</script>
