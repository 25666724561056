import axios from "axios";

class CompanyService {

  async getCompanies() {
    const { data } = await axios.get(`/restify/companies`);
    return data
  }

  async getCompany(id) {
    const { data } = await axios.get(`/restify/companies/${id}?related=addresses`);
    return data
  }

  async getUserCompanies(params = {}) {
    const { data } = await axios.get(`/restify/companies`, {
      params
    })
    return data
  }

  async createCompany(data) {
    return axios.post(`/restify/companies/actions?action=create-company`, data);
  }

  async markAsActive(id) {
    return axios.post(`/restify/companies/${id}/active`, {});
  }

  async updateCompany(data) {
    return axios.put(`/restify/companies/${data.id}?related=addresses`, data);
  }

  async deleteCompany(data) {
    return axios.delete(`/restify/companies/${data.id}`, data);
  }

  async attachUserToCompany({ companyId, userId, role, roles }) {
    return axios.post(`/restify/companies/${companyId}/attach/users`, {
      users: [userId],
      roles: roles ? roles : [role]
    });
  }

  async detachUserFromCompany({ companyId, userId }) {
    return axios.post(`/restify/companies/${companyId}/detach/users`, {
      users: [userId],
    });
  }

  async updateUserRole({ companyId, userId, roles }) {
    await this.detachUserFromCompany({ companyId, userId })
    await this.attachUserToCompany({ companyId, userId, roles })
  }
}

export default new CompanyService();
