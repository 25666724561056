<template>
  <div v-if="timesheet.clocked_out_at" class="col-span-3 border border-gray-200 rounded-xl p-6">
    <h4 class="mb-2 form-section-title">
      {{ $t('Clock Out Details') }}
    </h4>
    <div class="flex justify-between mb-4">
      <span class="flex flex-col bg-gray-50 rounded-md text-sm px-3 py-1 min-w-[200px]">
        <span class="font-medium">{{ $t('Clocked In At') }}</span>
        <span
          class="text-gray-600 mt-0.5">{{ $formatDate(timesheet.created_at, dateTypes.DateTimeMinutes) }}</span>
      </span>
      <span class="flex flex-col bg-gray-50 rounded-md text-sm px-3 py-1 min-w-[200px]">
        <span class="font-medium">{{ $t('Clocked Out At') }}</span>
        <span class="text-gray-600 mt-0.5">{{ clockOutDate }}</span>
      </span>
    </div>
    <div class="divide-y divide-gray-200">
      <div v-for="question in timesheet.compliance" :key="question.key" class="py-2">
        <div class="font-semibold">
          {{ question.question }}
        </div>
        <div class="text-gray-600 text-sm">
          <template v-if="question.type === questionTypes.Signature">
            <img class="max-w-[200px] h-auto" :src="question.value" :alt="question.question">
          </template>
          <template v-else-if="question.type === questionTypes.Boolean">
            {{ question.value ? $t('Yes') : $t('No') }}
          </template>
          <span
            v-else
            :class="{
              'text-red-700 font-medium': question.type === questionTypes.UrgentText && question.value,
            }"
            >
            {{ question.value || '--' }}
          </span>
          <div v-if="question.details" class="mt-2">
            <div>{{ question.details }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { questionTypes } from "@/modules/payroll/utils/payrollUtils";
import parse from "date-fns/parse";
import { dateTypes, formatDate } from "@/plugins/dateFormatPlugin";
import { computed } from "vue";

const props = defineProps({
  timesheet: {
    type: Object,
    required: true,
    default: () => ({}),
  }
})

const clockOutDate = computed(() => {
  let date = parse(props.timesheet.clocked_out_at, 'yyyy-MM-dd HH:mm:ss', new Date())
  return formatDate(date, dateTypes.DateTimeMinutes)
})
</script>
