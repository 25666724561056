<template>
  <entity-select url="/restify/ocip-codes"
                 v-bind="$attrs"
                 v-on="$listeners"
                 :url-params="urlParams"
                 :name="$attrs.name || $t('Ocip')"
                 :placeholder="$attrs.placeholder || $t('Select Ocip')"
                 :label="$attrs.label !== undefined ? $attrs.label : $t('Ocip')"
                 :add-label="$t('Add new Ocip')"
                 :id="$attrs.id || 'ocip-select'"
                 :table-columns="tableColumns"
                 :label-format="formatLabel"
                 label-key="code"
  />
</template>
<script>
  export default {
    name: 'OcipSelect',
    inheritAttrs: false,
    props: {
      urlParams: {
        type: Object,
        default: () => ({
          sort: 'code',
        }),
      },
    },
    data() {
      return {
        tableColumns: [
          {
            minWidth: 100,
            maxWidth: 150,
            name: this.$t('Code'),
            prop: 'attributes.code',
          },
          {
            minWidth: 180,
            maxWidth: 180,
            name: this.$t('Description'),
            prop: 'attributes.description',
          },
        ],
      }
    },
    methods: {
      formatLabel(ocip) {
        const { code, description } = ocip.attributes
        if (!description) {
          return code
        }
        return `${code} (${description})`
      },
    },
  }
</script>
