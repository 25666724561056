<template>
  <base-form
    :save-text="$t('Update')"
    :show-buttons="can('companies_update')"
    :loading="loading"
    :auto-height="true"
    grid-classes="grid grid-cols-8 gap-x-3"
    @submit="updateSettings"
  >
    <template v-slot:title>
      {{ $t('Template Settings') }}
    </template>
    <template v-slot:description>
      <div class="my-1">
        {{ $t('Enhance the display and printing of PDF templates colors') }}
      </div>
    </template>

    <div class="col-span-8 md:col-span-4">
      <base-input :label="$t('Accent Color')">
        <template #container>
          <el-color-picker v-model="model.accent_color"/>
        </template>
      </base-input>
    </div>
    <div class="mt-4 col-span-8">
      <base-input :label="$t('Company Logo')" :tip="$t('Used in some of the print templates')">
        <template #container>
          <div class="flex items-center space-x-4">
            <div class="w-[120px]">
              <base-upload-button accept="image/jpg,image/jpeg,image/png"
                                  @input="onFileUpdate">
                {{ $t('Upload image') }}
              </base-upload-button>
            </div>
            <div v-if="model?.logo" class="max-h-[100px]">
              <img :src="model.logo" alt="company logo" class="max-h-[120px]">
            </div>
          </div>
        </template>
      </base-input>

    </div>
    <div class="col-span-8 flex justify-end">
      <base-button
        variant="gray-link"
        @click="resetToDefault"
      >
        <IconRefresh class="w-4 h-4 mx-1"/>
        {{ $t('Reset to Default') }}
      </base-button>
    </div>

  </base-form>
</template>
<script setup lang="ts">
import {ColorPicker as ElColorPicker} from "element-ui";
import {PropType, ref, watch} from 'vue'
import {can} from '@/modules/auth/plugins/permissionsPlugin'
import {TemplateSettingDefaults} from "@/modules/common/components/reports/templateSettings";
import axios from "axios";

const props = defineProps({
  settings: Object as PropType<Record<string, any>>,
  loading: Boolean,
})

const emit = defineEmits(['save'])

const model = ref({
  ...TemplateSettingDefaults
})

async function onFileUpdate(event: any) {
  const file = event.target.files[0]
  if (!file) {
    model.value.logo = null
  }
  model.value.logo = URL.createObjectURL(file)
  const requestData = new FormData()
  requestData.append('file', file)
  const { data } = await axios.post(`/restify/company-settings/actions?action=upload-logo`, requestData)
  model.value.logo = data.url
}

function updateSettings() {
  emit('save', {
    ...props.settings,
    template_settings: model.value
  })
}

function resetToDefault() {
  model.value = {
    ...TemplateSettingDefaults
  }
}

function setModel(settings: any) {
  model.value = settings.template_settings || {...TemplateSettingDefaults}
}

watch(() => props.settings, settings => {
  setModel(settings)
}, {immediate: true})

</script>
