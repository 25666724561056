<template>
  <div :key="get(currentResource, 'id', 'customer')">
    <template v-if="currentResource">
      <portal to="page-title">
        {{ currentResource.attributes.description }}
        <template v-if="currentResource.attributes.code">
          ({{ currentResource.attributes.code }})
        </template>
      </portal>
      <portal to="entity-name">
        {{ currentResource.attributes.description }}
        <template v-if="currentResource.attributes.code">
          ({{ currentResource.attributes.code }})
        </template>
      </portal>
    </template>
    <layout-tabs :items="authorizedGlobally"/>
    <router-view @refresh="getCurrentResource"/>
  </div>
</template>
<script>
  import layoutPermissions from '@/mixins/layoutPermissions'

  export default {
    mixins: [layoutPermissions],
    computed: {
      items() {
        return [
          {
            name: this.$t('Details'),
            path: `/payroll/codes/time-off/${this.$route.params.id}/view`,
            productionReady: true,
            authorizedTo: 'authorizedToShow',
          },
          {
            name: this.$t('Edit'),
            path: `/payroll/codes/time-off/${this.$route.params.id}/edit`,
            authorizedTo: 'authorizedToUpdate',
          },
        ]

      },
      authorizedGlobally() {
        return this.filteredItems.filter(item => {
          return !(!item.productionReady && this.isProduction);
        })
      },
      currentResource() {
        return this.$store.state.payroll.currentTimeOffPolicy
      },
    },
    methods: {
      async getCurrentResource(id) {
        await this.$store.dispatch('payroll/getTimeOffPolicy', id)
      },
    },
  }
</script>
