<template>
  <WorkOrdersForm
      v-loading="workOrderLoading"
      :data="workOrder"
      @refresh="getWorkOrder"
  />
</template>
<script>
  import WorkOrdersForm from '@/modules/service-billing/components/WorkOrdersForm'

  export default {
    components: {
      WorkOrdersForm,
    },
    computed: {
      workOrderLoading() {
        return this.$store.state.serviceBilling.workOrderLoading
      },
      workOrder() {
        return this.$store.state.serviceBilling.currentWorkOrder
      },
    },
    methods: {
      getWorkOrder(id) {
        this.$store.dispatch('serviceBilling/getWorkOrder', id)
      },
    },
  }
</script>
