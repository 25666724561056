<template>
  <base-form-dialog
      v-bind="$attrs"
      v-on="$listeners"
      :append-to-body="true"
      :title="$t('New Time Off Policy')"
      @close="resetToDefaults"
  >
    <div :class="{
      'p-6': !generateBasedOnState,
    }">
      <base-form
          v-if="generateBasedOnState"
          :save-text="$t('Generate')"
          :loading="loading"
          layout="modal"
          @submit="generateTimeOffPolicy"
      >
        <base-select
            v-model="model.kind"
            :options="kindOptions"
            :label="$t('Kind')"
            :placeholder="$t('Kind')"
            id="kind"
            class="col-span-6 md:col-span-2"
        />
        <base-select
            v-model="model.state"
            :options="stateOptions"
            :label="$t('State')"
            :placeholder="$t('State')"
            id="kind"
            class="col-span-6 md:col-span-2"
        />
      </base-form>
      <div v-if="!generateBasedOnState" class="flex h-[250px] justify-center items-center">
        <div class="space-y-2 max-w-xs w-full">
          <BaseButton
              variant="white"
              type="button"
              block
              @click="generateBasedOnState = true"
          >
            {{ $t('Generate based on State') }}
          </BaseButton>
          <BaseButton
              variant="white"
              type="button"
              block
              @click="redirectToAdd">
            {{ $t('Create from scratch') }}
          </BaseButton>
        </div>
      </div>
    </div>
  </base-form-dialog>
</template>
<script setup>

import { useRouter } from "vue2-helpers/vue-router"
import { computed, ref } from "vue";
import i18n from "@/i18n";
import axios from "axios";
import { error, success } from "@/components/common/NotificationPlugin";
import store from "@/store";

const generateBasedOnState = ref(false)
const router = useRouter()
const emit = defineEmits(['close', 'save'])

const KindTypes = {
  Sick: 'sick'
}
const model = ref({
  kind: KindTypes.Sick,
  state: 'WA'
})

const kindOptions = computed(() => {
  return [
    {
      label: i18n.t('Sick'),
      value: KindTypes.Sick
    }
  ]
})

const stateOptions = computed(() => {
  return [
    {
      label: i18n.t('Washington'),
      value: 'WA'
    }
  ]
})


async function redirectToAdd() {
  await router.push('/payroll/codes/time-off/add')
}

const loading = ref(false)
async function generateTimeOffPolicy() {
  try {
    loading.value = true
    await axios.post('/restify/time-off-policies/actions?action=generate-default-time-off-policy', model.value)
    await store.dispatch('globalLists/getPRConfiguration', true)
    emit('save')
  } catch (err) {
    if (err.handled) {
      return
    }
    error(i18n.t('Failed to generate the Time Off Policy'))
  } finally {
    loading.value = false
  }
}

function resetToDefaults() {
  generateBasedOnState.value = false
  emit('close')
  emit('update:open', false)
}

</script>
