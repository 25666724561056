<template>
  <div>
    <BaseDataTable :columns="columns"
                   :url="url"
                   :url-params="urlParams"
                   :default-match-filters="defaultMatchFilters"
                   :per-page="$pagination.list"
                   default-sort="number"
                   actions="search,refresh,export,print"
                   ref="table"
    >
      <template v-slot:net_change_amount="{row}">
        {{ $formatPrice(getNetPeriodChangeAmount(row)) }}
      </template>
      <template v-slot:ending_balance="{row}">
        {{ getEndingBalance(row) }}
      </template>
      <template v-slot:extra-actions-before="{row}">
        <table-view-button :link="`/ledger/accounts/${row.id}/summary`"/>
      </template>
    </BaseDataTable>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        url: '/restify/accounts/balances',
        exportModel: 'AccountBalances',
      }
    },
    computed: {
      columns() {
        return [
          {
            label: this.$t('Account'),
            prop: 'number',
            maxWidth: 200,
            component: 'EntityLink',
            redirectTo: '/ledger/accounts/{ID}/summary',
          },
          {
            label: this.$t('Description'),
            prop: 'description',
            maxWidth: 280,
          },
          {
            label: this.$t('Type'),
            prop: 'type',
            minWidth: 70,
            maxWidth: 70,
            component: 'Status',
          },
          {
            label: this.$t('Opening Balance'),
            minWidth: 100,
            prop: 'p0_amount',
            maxWidth: 130,
            component: 'FormattedPrice',
          },
          {
            label: this.$t('Net / Period Change'),
            prop: 'net_change_amount',
            minWidth: 100,
            maxWidth: 130,
            component: 'FormattedPrice',
          },
          {
            label: this.$t('Ending Balance'),
            prop: 'ending_balance',
            minWidth: 100,
            maxWidth: 130,
            component: 'FormattedPrice',
          },
          {
            label: this.$t('Last Update'),
            prop: 'updated_at',
            component: 'FormattedDate',
          },
        ]
      },
      urlParams() {
        return {
          fiscal_year: this.activeFiscalYear
        }
      },
      defaultMatchFilters() {
        return {
          active: true,
        }
      },
    },
    methods: {
      getNetPeriodChangeAmount(row) {
        let amount = 0

        for (let i = 1; i < 13; i++) {
          amount += row[`p${i}_amount`]
        }
        return amount
      },
      getEndingBalance(row) {
        let amount = this.getNetPeriodChangeAmount(row)
        amount = amount + row.p0_amount + row.p13_amount

        return this.$formatPrice(amount)
      },
    },
  }
</script>
