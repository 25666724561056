<template>
  <base-data-table
      v-bind="defaultAttributes"
      :columns="columns"
      :data="getData"
      :total-rows="rowsLength"
      :has-summary="false"
      headerRowCompacted
      @force-pagination="forcePagination"
  >

    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template #header="{row}">
      {{ $t('Union') }}:
      <router-link :to="`/payroll/rates/unions?id=${row.header.id}`">
        {{ row.header.code }}
        <span v-if="row.header.description">
          ({{ row.header.description }})
        </span>
      </router-link>
    </template>

    <template #employee.name="{row}">
      <router-link
          v-if="row.employee.id"
          :to="`/payroll/employees/${row.employee.id}/view`"
      >
        {{ row.employee?.name }}
      </router-link>
      <span v-else/>
    </template>

    <template #thead-infos>
      <th colspan="3"/>
      <th colspan="2">
        <p class="w-full text-center ml-8">{{ $t('Regular Pay') }}</p>
      </th>
      <th colspan="2" align="center">
        <p class="w-full text-center ml-8">{{ $t('Overtime Pay') }}</p>
      </th>
      <th colspan="2" align="center">
        <p class="w-full text-center ml-8">{{ $t('Premium Pay') }}</p>
      </th>
      <th colspan="2" align="center">
        <p class="w-full text-center pl-12">{{ $t('Total Hourly Pay') }}</p>
      </th>
    </template>

    <template #subtotals="{subtotals}">
      <tr v-for="{special_source} in subtotals">
        <td colspan="7"/>
        <td>{{ special_source.description }}</td>
        <td>{{ special_source.type }}</td>
        <td align="right">{{ special_source.hours }}</td>
        <td align="right">
          <span class="px-2">
            {{ $formatPrice(special_source.amount) }}
          </span>
        </td>
      </tr>
    </template>
    <template #subtotal="{subtotal}">
      <td colspan="8"/>
      <td align="right">{{ $t('Total') }}:</td>
      <td align="right" colspan="2">
        {{ $formatPrice(subtotal.amount?.special_sources) }}
      </td>
    </template>

    <template #html-row="{ htmlData }">
      <tr class="top-border">
        <td colspan="11" class="subtotal-header">
          {{ $t('Summary Totals for Selected Periods') }}
        </td>
      </tr>
      <tr>
        <td>{{ $t('Pay/Deduction') }}</td>
        <td align="right">{{ $t('Hours') }}</td>
        <td align="right">{{ $t('Amount') }}</td>
      </tr>
      <tr>
        <td class="flex justify-between">
          <span>{{ $t('Regular Pay') }}</span>
          <span>({{ $t('Pay') }})</span>
        </td>
        <td align="right">{{ htmlData.totals?.hours?.REGPAY }}</td>
        <td align="right">{{ $formatPrice( htmlData.totals?.amount?.REGPAY) }}</td>
      </tr>
      <tr>
        <td class="flex justify-between">
          <span>{{ $t('Overtime Pay') }}</span>
          <span>({{ $t('Pay') }})</span>
        </td>
        <td align="right">{{ htmlData.totals?.hours?.OVTPAY }}</td>
        <td align="right">{{ $formatPrice( htmlData.totals?.amount?.OVTPAY) }}</td>
      </tr>
      <tr>
        <td class="flex justify-between">
          <span>{{ $t('Premium Pay') }}</span>
          <span>({{ $t('Pay') }})</span>
        </td>
        <td align="right">{{ htmlData.totals?.hours?.PRMPAY }}</td>
        <td align="right">{{ $formatPrice( htmlData.totals?.amount?.PRMPAY) }}</td>
      </tr>
      <tr>
        <td class="subtotal-row" align="right">
          {{ $t('Total Hourly Pay') }}:
        </td>
        <td align="right" class="subtotal-row"> {{ htmlData.totals?.hours?.total }}</td>
        <td align="right" class="subtotal-row">{{ $formatPrice( htmlData.totals?.amount?.total) }}</td>
        <td class="subtotal-row" colspan="9"/>
      </tr>
      <tr v-for="{special_source} in htmlData.special_sources">
        <td class="flex justify-between">
          <span>{{ special_source.description }}</span>
          <span>{{ special_source.type }}</span>
        </td>
        <td align="right">{{ special_source.hours }}</td>
        <td align="right">{{ $formatPrice(special_source.amount) }}</td>
      </tr>
      <tr>
        <td class="subtotal-row" align="right">
          {{ $t('Grand Totals') }}:
        </td>
        <td class="subtotal-row" align="right">
          {{ htmlData.totals?.special_sources?.hours }}
        </td>
        <td class="subtotal-row" align="right">
          {{ $formatPrice(htmlData.totals?.special_sources?.amount) }}
        </td>
        <td class="subtotal-row" colspan="8"/>
      </tr>
    </template>
  </base-data-table>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { reservedPayCodeList } from '@/enum/enums'
  import { Column } from '@/components/ag-grid/tableTypes'
  import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper.vue'

  export default defineComponent({
    extends: ReportTableWrapper,
    data() {
      return {
        sourceTypes: {
          Reserved: 'Reserved',
          Other: 'Other',
        }
      }
    },
    computed: {
      columns(): Column[] {
        return [
          {
            label: this.$t('Employee'),
            prop: 'employee.name',
            hiddenValue: true,
            minWidth: 100,
            maxWidth: 160,
          },
          {
            label: this.$t('Soc Sec #'),
            prop: 'employee.ssn',
            minWidth: 80,
            maxWidth: 110,
          },
          {
            label: this.$t('Craft'),
            prop: 'craft_code.description',
            minWidth: 120,
            maxWidth: 180,
            component: 'EntityLink',
            redirectTo: '/payroll/codes/craft-codes?id={ID}',
            entityKey: 'craft_code.id',
          },
          {
            label: this.$t('Hours'),
            prop: 'totals.hours.REGPAY',
            minWidth: 100,
            maxWidth: 120,
            align: 'right',
            hideZero: true,
          },
          {
            label: this.$t('Amount'),
            prop: 'totals.amount.REGPAY',
            minWidth: 100,
            maxWidth: 120,
            align: 'right',
            hideZero: true,
            component: 'FormattedPrice',
          },
          {
            label: this.$t('Hours'),
            prop: 'totals.hours.OVTPAY',
            minWidth: 100,
            maxWidth: 120,
            align: 'right',
            hideZero: true,
          },
          {
            label: this.$t('Amount'),
            prop: 'totals.amount.OVTPAY',
            minWidth: 100,
            maxWidth: 120,
            align: 'right',
            hideZero: true,
            component: 'FormattedPrice',
          },
          {
            label: this.$t('Hours'),
            prop: 'totals.hours.PRMPAY',
            minWidth: 100,
            maxWidth: 120,
            align: 'right',
            hideZero: true,
          },
          {
            label: this.$t('Amount'),
            prop: 'totals.amount.PRMPAY',
            minWidth: 100,
            maxWidth: 120,
            align: 'right',
            hideZero: true,
            component: 'FormattedPrice',
          },
          {
            label: this.$t('Hours'),
            prop: 'totals.hours.total',
            minWidth: 100,
            maxWidth: 120,
            align: 'right',
            hideZero: true,
          },
          {
            label: this.$t('Amount'),
            prop: 'totals.amount.total',
            minWidth: 100,
            maxWidth: 120,
            component: 'FormattedPrice',
            align: 'right',
            hideZero: true,
          }
        ]
      },
    },
    methods: {
      composeRows(data: Record<string, any>) {
        data.forEach((group: Record<string, any>) => {
          const {union, employees, special_sources, totals} = group
          // @ts-ignore
          this.rows.push({
            header: union,
          })

          employees.forEach((employeeObject: Record<string, any>) => {
            const {craft_codes, employee} = employeeObject || {}
            craft_codes.forEach((craft_code: Record<string, any>) => {
              const row = {
                ...craft_code,
                employee,
              }
              // @ts-ignore
              this.rows.push(row)
              const {special_sources : subtotals, totals : subtotal} = craft_code
              // @ts-ignore
              this.rows.push({
                subtotals
              })
              // @ts-ignore
              this.rows.push({
                subtotal,
              })
            })
          })
          // @ts-ignore
          this.rows.push({
            htmlData: {
              special_sources,
              totals,
            },
          })
        })

        return this.rows
      },
    },
  })
</script>
