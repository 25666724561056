<template>
  <div>
    <AgDataTable
        :columns="columns"
        :edit-entity-url-query="editEntityUrlQuery"
        :add-text="$t('New benefit code')"
        entity="benefit-code"
        import-url="/payroll/codes/benefit-codes/import"
        url="/restify/benefit-codes"
        actions="search,refresh,add,edit,print,import,export"
        permission="employees"
        hide-actions="view"
        ref="table"
        :transform-data="mapData"
        @add="onRowAdd"
        @edit="onRowEdit"
    />
    <BaseFormDialog
        v-if="showDialog"
        :title="formTitle"
        :open.sync="showDialog"
        size="2xl"
        @close="onEventTrigger(false)"
        @cancel="onEventTrigger(false)"
    >
      <BenefitCodeForm
          :data="rowToEdit"
          :key="renderKey"
          @close="onEventTrigger(false)"
          @cancel="onEventTrigger(false)"
          @save="onEventTrigger"
          @create-another="renderKey++"
      />
    </BaseFormDialog>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { Column } from '@/components/ag-grid/tableTypes'
  import { BenefitCode } from '@/modules/common/types/models'
  import BenefitCodeForm from '@/modules/payroll/components/codes/BenefitCodeForm.vue'
  import Data = API.Data;
  import {BenefitExceptions} from "@/modules/payroll/utils/benefitCodes";

  export default defineComponent({
    components: {
      BenefitCodeForm,
    },
    data() {
      return {
        renderKey: <number>1,
        editEntityUrlQuery: <string>'?id={ID}',
        rowToEdit: null as API.Data<BenefitCode> | null,
        showDialog: <boolean>false,
      }
    },
    computed: {
      formTitle() {
        if (!this.rowToEdit) {
          return this.$t('Add Benefit Code')
        }
        if (this.$isAuthorized(this.$actionPolicies.Update, this.rowToEdit)) {
          return this.$t('Edit Benefit Code')
        }
        return this.$t('View Benefit Code')
      },
      columns(): Column[] {
        return [
          {
            headerName: this.$t('Code'),
            field: 'attributes.code',
            component: 'EntityLink',
            redirectTo: '/payroll/codes/benefit-codes?id={ID}',
          },
          {
            headerName: this.$t('Description'),
            field: 'attributes.description',
          },
          {
            headerName: this.$t('Calculate By'),
            field: 'attributes.calculate_by',
            component: 'FormattedText',
          },
          {
            headerName: this.$t('Multiply By'),
            field: 'attributes.multiply_by',
            component: 'FormattedText',
          },
          {
            headerName: this.$t('Payroll Category Type'),
            field: 'attributes.certified_payroll_category_type',
            component: 'Status',
          },
          {
            headerName: this.$t('Employee Regular Rate'),
            field: 'attributes.use_employee_regular_rate',
            component: 'Status',
          },
          {
            label: this.$t('Active'),
            prop: 'attributes.active',
            component: 'Status',
            align: 'center',
          },
        ]
      },
    },
    methods: {
      mapData(data: Data<BenefitCode>[]){
        const search = this.$refs.table?.searchQuery || ''
        return data.filter((item: API.Data<BenefitCode>) => {
          if (search) {
            return true
          }
          return !BenefitExceptions.includes(item.attributes.code)
        })
      },
      onRowEdit(row: API.Data<BenefitCode>) {
        this.showDialog = true
        this.rowToEdit = row
      },
      onRowAdd() {
        this.showDialog = true
      },
      refreshTable() {
        // @ts-ignore
        this.$refs.table.refresh()
      },
      onEventTrigger(refresh: boolean = true) {
        this.rowToEdit = null
        this.showDialog = false

        if (!refresh) {
          return
        }

        this.refreshTable()
      },
    },
  })
</script>
