<template>
  <base-dropdown :tooltip-content="$t('Group By')"
                 :menu-classes="`left-0 max-w-sm`"
  >
    <template v-slot:trigger>
      <button @click="toggleDropdown"
              ref="button"
              class="inline-flex items-center justify-center w-full -ml-2 md:pr-4 bg-transparent text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
             stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M3 10h18M3 14h18m-9-4v8m-7 0h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"/>
        </svg>
        <svg class="h-5 w-5"
             fill="currentColor"
             viewBox="0 0 20 20">
          <path fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"/>
        </svg>
      </button>
    </template>

    <div class="rounded-md bg-white shadow-xs relative z-10">
      <div v-for="(option, index) in options"
           :key="index"
           :tabindex="0"
           :class="{
             'text-primary-500': option === value,
             'text-gray-700': option !== value,
           }"
           class="h-10 py-1 border-b px-4 hover:bg-primary-100 cursor-pointer flex justify-between items-center"
           @click="chooseOption(option)"
      >
        <div class="capitalize">
          {{ option }}
        </div>
      </div>
    </div>
  </base-dropdown>
</template>
<script>
  export default {
    props: {
      value: {
        type: String,
        default: ''
      },
      options: {
        type: Array,
        default: () => [],
      }
    },
    data() {
      return {
        showDropdown: false,
      }
    },
    methods: {
      toggleDropdown() {
        this.showDropdown = !this.showDropdown
      },
      chooseOption(option) {
        this.$emit('input', option)
        this.showDropdown = false
      }
    }
  }
</script>
