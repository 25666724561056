<template>
  <div>
    <UnitPriceBillingEntries
        v-bind="$attrs"
        :billing="billing"
        :get-billing-entries="getBillingEntries"
        :read-only="true"
        :showCellsLegend="false"
        ref="entriesTable"
    />
  </div>
</template>
<script>
  import UnitPriceBillingEntries
    from "@/modules/accounts-receivable/components/unit-price-billings/UnitPriceBillingEntries.vue";
  import axios from "axios";

  export default {
    name: 'UnitPriceBillingEntriesDetails',
    components: { UnitPriceBillingEntries },
    props: {
      billing: {
        type: Object,
        default: () => ({}),
      },
    },
    methods: {
      async getBillingEntries() {
        try {
          const { data } = await axios.get('/restify/billing-entries', {
            params: {
              billing_id: this.billing.id,
              perPage: 500,
              sort: 'order',
              related: 'addlSource',
            },
          })
          return data.map(entry => entry.attributes)
        } catch (err) {
          console.warn(err)
        }
      }
    },
  }
</script>
