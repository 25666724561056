<template>
  <base-filter-row :title="$t('Service Codes')">
    <base-filter-range :class="gridClass">
      <template #start>
        <service-billing-code-select
            v-model="value.service_billing_code_from"
            v-bind="$attrs"
            value-key="code"
            :placeholder="$t('Service Code From')"
            :hide-label="true"
            clearable
            @blur="onBlurStartRange"
        />
      </template>
      <template #end>
        <service-billing-code-select
            v-model="value.service_billing_code_to"
            v-bind="$attrs"
            value-key="code"
            :placeholder="$t('Service Code to')"
            :hide-label="true"
            :rules="value.service_billing_code_from ? 'required' : ''"
            clearable
        />
      </template>
    </base-filter-range>

    <slot/>

  </base-filter-row>
</template>
<script>
import ServiceBillingCodeSelect from "@/components/select/entities/ServiceBillingCodeSelect.vue";

export default {
  components: {
    ServiceBillingCodeSelect,
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        service_billing_code_from: null,
        service_billing_code_to: null,
      }),
    },
    gridClass: {
      type: String,
      default: 'col-span-4',
    },
  },
  methods: {
    onBlurStartRange() {
      this.value.service_billing_code_to = this.value.service_billing_code_to || this.value.service_billing_code_from
    }
  }
}
</script>
