<template>
  <base-switch
    id="active"
    :label-info="$t('Active')"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>
<script>
export default {
  name: 'ActiveSwitch',
  inheritAttrs: false,
}
</script>
