<template>
    <div>
        <BaseDataTable :columns="columns"
                       :url="url"
                       :add-text="$t('New formula')"
                       actions="search,refresh"
                       default-sort="code"
                       permission="estimate_formulas"
                       hide-actions="view"
                       ref="table"
                       @add="onRowAdd"
                       @edit="onRowEdit"
                       :edit-entity-url-query="editEntityUrlQuery"
        >
        </BaseDataTable>
        <BaseFormDialog v-if="showAddDialog"
                        :title="$t('Add Estimate Formula')"
                        :open.sync="showAddDialog">
            <EstimateFormulaForm @cancel="showAddDialog = false"
                                 @save="onAdd"
            />
        </BaseFormDialog>
        <BaseFormDialog v-if="showEditDialog"
                        :title="$t('Edit Estimate Formula')"
                        :open.sync="showEditDialog">
            <EstimateFormulaForm :data="rowToEdit"
                                 @cancel="showEditDialog = false"
                                 @save="onEdit"
            />
        </BaseFormDialog>
    </div>
</template>
<script>
  import EstimateFormulaForm from '@/modules/estimates/components/EstimateFormulaForm'

  export default {
    components: {
      EstimateFormulaForm,
    },
    data() {
      return {
        editEntityUrlQuery: '?id={ID}',
        url: '/restify/estimate-formulas',
        rowToEdit: null,
        showAddDialog: false,
        showEditDialog: false,
        columns: [
          {
            label: this.$t('Code'),
            prop: 'attributes.code',
            component: 'EntityLink',
            redirectTo: '/estimates/formulas?id={ID}',
          },
          {
            label: this.$t('Formula'),
            prop: 'attributes.formula',
          },
          {
            label: this.$t('Description'),
            prop: 'attributes.description',
            minWidth: 150,
            maxWidth: 200,
          },
          {
            label: this.$t('Dimension X'),
            prop: 'attributes.um_x',
            align: 'center',
          },
          {
            label: this.$t('Dimension Y'),
            prop: 'attributes.um_y',
            align: 'center',
          },
          {
            label: this.$t('Dimension Z'),
            prop: 'attributes.um_z',
            align: 'center',
          },
        ],
      }
    },
    methods: {
      onRowEdit(row) {
        this.showEditDialog = true
        this.rowToEdit = row.attributes
      },
      onRowAdd() {
        this.showAddDialog = true
      },
      onAdd() {
        this.showAddDialog = false
        this.$refs.table.refresh()
      },
      onEdit() {
        this.showEditDialog = false
        this.$refs.table.refresh()
      },
    },
  }
</script>
