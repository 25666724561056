<template>
  <div>
    <base-collapse-form
        :show-buttons="false"
        :expanded.sync="sectionExpanded"
        layout="vertical"
    >
      <template #title>
      <span v-if="!sectionExpanded && workOrder.id">
        {{ workOrder.attributes.description }}
        ({{ workOrder.attributes.number }})
      </span>
      </template>
      <div class="col-span-6 lg:col-span-2">
        <div class="flex items-end">
          <work-order-select
              v-model="workOrderId"
              :add-entity="false"
              label=""
              class="flex-1"
              inline-errors
              clearable
              @entity-change="onChangeWorkOrder"
          />
          <base-tooltip v-if="workOrderId"
                        :tabindex="-1"
                        :content="$t('Edit Work Order')">
            <base-button variant="white"
                         :tabindex="-1"
                         class="ml-2"
                         @click="showEditWorkOrderDialog = true">
              <Edit3Icon class="w-5 h-5 text-primary-500"/>
            </base-button>
          </base-tooltip>
        </div>
      </div>
      <div class="lg:col-span-1"/>
      <div class="col-span-6 lg:col-span-3">
        <work-order-preview
            v-if="workOrderId"
            :key="workOrderId"
            :entity="workOrder.attributes"
            class="flex-1 mb-5"
        />
      </div>
    </base-collapse-form>
    <base-form-dialog
        v-if="showEditWorkOrderDialog"
        :title="$t('Edit Vendor')"
        size="xl"
        :open.sync="showEditWorkOrderDialog">
      <work-orders-form
          :redirect-to-list-after-store="false"
          :show-back="false"
          :data="workOrder"
          @save="onUpdateWorkOrder"
          @cancel="showEditWorkOrderDialog = false"
      />
    </base-form-dialog>
    <service-invoices-list :filters="urlParams"/>
  </div>
</template>
<script>
  import { Edit3Icon } from 'vue-feather-icons'
  import WorkOrdersForm from '@/modules/service-billing/components/WorkOrdersForm'
  import WorkOrderPreview from '@/modules/common/components/entity-preview/WorkOrderPreview'
  import ServiceInvoicesList from '@/modules/service-billing/pages/service-invoices/service-invoices-list'

  export default {
    components: {
      Edit3Icon,
      WorkOrdersForm,
      WorkOrderPreview,
      ServiceInvoicesList,
    },
    data() {
      return {
        workOrderId: '',
        sectionExpanded: true,
        workOrder: {},
        showEditWorkOrderDialog: false,
      }
    },
    computed: {
      urlParams() {
        return {
          work_order_id: this.workOrderId,
        }
      },
    },
    methods: {
      onChangeWorkOrder(workOrder) {
        this.workOrder = workOrder
      },
      onUpdateWorkOrder(data) {
        this.workOrder = data
        this.showEditWorkOrderDialog = false
      },
    },
  }
</script>
