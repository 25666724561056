<template>
  <div class="import-data mt-4">
    <ImportForm v-bind="importSettings"
                @success="redirectToList"
    >
      <FiscalYearSelect
          v-model="model.year"
          :label="$t('Fiscal Year')"
          :default-previous-year="true"
          class="col-span-6 md:col-span-1"
          id="fiscal_year"
      />
      <PeriodSelect
          v-model="model.period"
          :placeholder="$t('Period')"
          :default-to-last-period="true"
          class="col-span-6 md:col-span-1"
          id="period"
      />

      <div class="col-span-6 md:col-span-1">
        <BaseSelect
            v-model="model.type"
            :label="$t('Type')"
            :options="costOptions"
            rules="required"
        />
      </div>
    </ImportForm>
  </div>
</template>
<script>
  import ImportForm from "@/modules/common/components/import/ImportForm";
  import { getInitialMapping } from "@/modules/payroll/utils/timecardImportColumns";
  import { costOptions } from "@/modules/job-costing/enum/jobs";
  import Cache from "@/utils/Cache";

  const colMappings = {
    JobNumber: {
      value: 'job_number',
      label: 'Number',
      alternativeLabels: ['Job Number', 'Job No.'],
      required: true,
    },
    JobType: {
      value: 'job_type',
      label: 'Job Type',
      alternativeLabels: ['Job Type', 'Income Type', 'Cost Type'],
      required: true,
    },
    Phase: {
      value: 'phase_code',
      label: 'Phase',
      alternativeLabels: ['Phase Code', 'Phase_Code'],
      required: mapping => {
        return mapping.cost_code === -1
      }
    },
    CostCode: {
      value: 'cost_code',
      label: 'Cost Code',
      alternativeLabels: ['Cost', 'Cost_Code'],
      required: mapping => {
        return mapping.phase_code === -1
      }
    },
    ChangeOrder: {
      value: 'change_order',
      label: 'Change Order',
      alternativeLabels: ['CNG', 'Change_Order', 'CHG ORD'],
    },
    Quantity: {
      value: 'quantity',
      label: 'Quantity',
      alternativeLabels: ['Qty'],
    },
    Amount: {
      value: 'amount',
      label: 'Amount',
      alternativeLabels: ['Amount'],
    },
    RetentionAmount: {
      value: 'retention_amount',
      label: 'Retention',
      alternativeLabels: ['Retention Amount'],
    },
    VendorOrCustomerCode: {
      value: 'business_code',
      label: 'Vendor/Customer Code',
      alternativeLabels: ['Vendor', 'Customer', 'Cust Code', 'Vend Code', 'Customer Code', 'Vendor Code'],
    }
  }

  const options = [
    colMappings.JobNumber,
    colMappings.JobType,
    colMappings.Phase,
    colMappings.CostCode,
    colMappings.ChangeOrder,
    colMappings.Quantity,
    colMappings.Amount,
    colMappings.RetentionAmount,
    colMappings.VendorOrCustomerCode,
  ]

  export default {
    components: {
      ImportForm,
    },
    data() {
      return {
        costOptions,
        model: {
          year: null,
          period: null,
          type: null,
        },
        importSettings: {
          url: '/restify/job-costing-init-entries/import',
          columnOptions: options,
          columnMappings: colMappings,
          initialMapping: getInitialMapping(options),
          type: 'job-costing-init-import',
          resource: 'job-costing-init',
          transformRequestData: this.transformRequestData,
        },
      }
    },
    methods: {
      transformRequestData(formData) {
        formData.append('fiscal_year', this.model.year)
        formData.append('period', this.model.period)
        formData.append('type', this.model.type)
      },
      async redirectToList() {
        Cache.removeForEntity('job-costing-inits')
        await this.$store.dispatch('globalLists/getJCConfiguration', true)
        await this.$router.push('/job-costing/settings/job-cost-init')
      },
    }
  }
</script>
