<template>
  <div class="truncate">
    <router-link v-if="accountId && isLink && hasPermission"
                 :to="`/ledger/accounts/${accountId}/view`"
                 :title="fullName"
                 :target="target"
                 :class="$attrs.class"
    >
      <span>
        {{ accountNumber }}
      </span>
      <span class="print:hidden" v-if="showDescription && description">
        ({{ description }})
      </span>
    </router-link>
    <template v-else>
      <span>
        {{ accountNumber }}
      </span>
      <span class="print:hidden" v-if="showDescription && description">
        ({{ description }})
      </span>
    </template>
  </div>
</template>
<script>
  import { isUUID } from "@/utils/utils";
  export default {
    name: 'AccountLink',
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      id: {
        type: String,
      },
      showDescription: {
        type: Boolean,
        default: true,
      },
      number: {
        type: [Number, String],
      },
      target: String,
      isLink: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      account() {
        if (this.id && isUUID(this.id)) {
          return this.$store.getters['company/getCompanyAccount'](this.id)
        }

        if (this.number) {
          return this.$store.getters['company/getCompanyAccountByNumber'](this.number)
        }

        const account = this.get(this.data, 'relationships.account.attributes');
        const accountField = account?.number || this.get(this.data, 'attributes.account') || this.get(this.data, 'account') || this.get(this.data, 'number')
        if (!account) {
          return this.$store.state.company.accounts.find(a => a.number === accountField)
        }
        return account
      },
      accountNumber() {
        return this.account?.number
      },
      description() {
        return this.account?.description
      },
      fullName() {
        if (this.description) {
          return `${this.accountNumber} (${this.description})`
        }
        return this.accountNumber
      },
      accountId() {
        return this.account?.id
      },
      hasPermission() {
        return this.$can('accounts_update')
      },
    },
  }
</script>
