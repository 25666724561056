<template>
  <div class="flex flex-col w-full col-span-6 mt-6 journal-entries"
  >
    <template v-if="isWaiverAndReleaseDocumentType">
      <p class="font-semibold text-gray-600">{{ $t('Type column') }}:</p>
      <div class="flex w-full justify-between shadow-md p-2 text-gray-500">
        <div class="w-full md:w-1/2 mx-2 pt-1">
          <p><b>1 </b>{{ $t('Conditional Waiver and Release Upon Progress Payment') }}</p>
          <p><b>3 </b>{{ $t('Conditional Waiver and Release Upon Final Payment') }}</p>
        </div>
        <div class="w-full md:w-1/2 mx-2">
          <p><b>2 </b>{{ $t('Unconditional Waiver and Release Upon Progress Payment') }}</p>
          <p><b>3 </b>{{ $t('Unconditional Waiver and Release Upon Final Payment') }}</p>
        </div>
      </div>
    </template>
    <BaseDataTable :columns="columns"
                   :url="url"
                   class="border-t pt-4"
                   :data-map-function="dataMapFunction"
                   :per-page="perPage"
                   ref="table"
                   :title="$t('Documents')"
                   actions="search,refresh,add"
                   @add="addEntry"
                   @data-fetch="onEntriesFetch"
    >
      <template #job_id="{row, index}">
        <JobSelect v-model="row.job_id"
                   :id="`job-${index}`"
                   :add-entity="true"
                   label=" "
                   @change="onFieldChange(row)"
                   @blur="onFieldBlur(row, index)"
                   inline-errors
        />
      </template>
      <template #comment="{row, index}">
        <base-input v-model="row.comment"
                    :placeholder="$t('Comment')"
                    :name="$t('Comment')"
                    :id="`comment-${index}`"
                    @blur="onFieldBlur(row, index)"
                    @input="onFieldChange(row)"
                    inline-errors
        />
      </template>
      <template #entry_date="{row, index}"
                v-if="isGeneralNotesDocumentType || isJointCheckArrangementDocumentType">
        <base-date-picker v-model="row.entry_date"
                          :id="`entry_date-${index}`"
                          @blur="tryAddEntry(index, row)"
                          @input="onFieldChange(row)"
                          inline-errors
        />
      </template>
      <template #joint_vendor_id="{row, index}"
                v-if="isJointCheckArrangementDocumentType">
        <VendorSelect v-model="row.joint_vendor_id"
                      @change="tryAddEntry(index, row)"
                      :id="`joint_vendor_id-${index}`"
                      label=" "
                      inline-errors
        />
      </template>
      <template #notice_date="{row, index}"
                v-if="isPreliminaryNoticeDocumentType">
        <base-date-picker v-model="row.notice_date"
                          :id="`notice_date-${index}`"
                          @blur="onFieldBlur(row, index)"
                          @input="onFieldChange(row)"
                          inline-errors
        />
      </template>
      <template #lienor_vendor_id="{row, index}"
                v-if="isPreliminaryNoticeDocumentType">
        <VendorSelect v-model="row.lienor_vendor_id"
                      :id="`lienor_vendor_id-${index}`"
                      @blur="onFieldBlur(row, index)"
                      @change="onFieldChange(row)"
                      inline-errors
                      label=" "
        />
      </template>
      <template #certified_mail_no="{row, index}"
                v-if="isPreliminaryNoticeDocumentType">
        <base-input v-model="row.certified_mail_no"
                    :id="`certified_mail_no-${index}`"
                    @blur="onFieldBlur(row, index)"
                    @input="onFieldChange(row)"
                    inline-errors
        />
      </template>
      <template #estimated_price="{row, index}"
                v-if="isPreliminaryNoticeDocumentType">
        <base-input v-model="row.estimated_price"
                    :id="`certified_mail_no-${index}`"
                    :min="0"
                    :step="0.01"
                    type="number"
                    format="price"
                    inline-errors
                    @focus="onFocusNumberInput(row, 'estimated_price')"
                    @blur="tryAddEntry(index, row)"
                    @input="onFieldChange(row)"
        />
      </template>
      <template #date_sent="{row, index}"
                v-if="isWaiverAndReleaseDocumentType">
        <base-date-picker v-model="row.date_sent"
                    :id="`date_sent-${index}`"
                    @blur="onFieldBlur(row, index)"
                    @input="onFieldChange(row)"
                    inline-errors
        />
      </template>
      <template #type="{row, index}"
                v-if="isWaiverAndReleaseDocumentType">
        <base-select v-model="row.type"
                     :options="typeOptions"
                     :id="`type-${index}`"
                     @blur="onFieldBlur(row, index)"
                     @change="onFieldChange(row)"
                     inline-errors
                     :value="typeOptions[0].value"
        />
      </template>
      <template #amount="{row, index}"
                v-if="isWaiverAndReleaseDocumentType">
        <base-input v-model="row.amount"
                    :id="`amount-${index}`"
                    @focus="onFocusNumberInput(row, 'amount')"
                    @blur="onBlurNumberInput(row, 'amount', index)"
                    @input="onFieldChange(row)"
                    inline-errors
                    :min="0"
                    :max="9999999999.99"
                    type="number"
                    :step="0.01">
          <template #prefix>$</template>
        </base-input>
      </template>
      <template #check_no="{row, index}"
                v-if="isWaiverAndReleaseDocumentType">
        <base-input v-model="row.check_no"
                    :id="`check_no-${index}`"
                    @focus="onFocusNumberInput(row, 'check_no')"
                    @blur="onBlurNumberInput(row, 'check_no', index)"
                    @input="onFieldChange(row)"
                    inline-errors
                    :min="0"
                    type="number"
                    :step="0.01"
        />
      </template>
      <template #throw_date="{row, index}" v-if="isWaiverAndReleaseDocumentType">
        <base-date-picker v-model="row.throw_date"
                          :id="`throw_date-${index}`"
                          @blur="onFieldBlur(row, index)"
                          @input="onFieldChange(row)"
                          inline-errors
        />
      </template>
      <template #receive_date="{row, index}"
                v-if="isWaiverAndReleaseDocumentType">
        <base-date-picker v-model="row.receive_date"
                          :id="`receive_date-${index}`"
                          @blur="tryAddEntry(index, row)"
                          inline-errors
        />
      </template>
      <template #actions="{index}">
        <button @click.prevent="deleteRow(index)"
                type="button"
                :tabindex="-1"
                :id="`delete-${index}`"
                class="text-red-500 hover:text-red-600 p-1 focus:outline-none focus:ring">
          <trash2-icon class="w-5 h-5"></trash2-icon>
        </button>
      </template>
    </BaseDataTable>
  </div>
</template>
<script>
  import axios from 'axios'
  import pick from 'lodash/pick'
  import format from 'date-fns/format'
  import { Trash2Icon } from 'vue-feather-icons'
  import JobSelect from '@/components/select/entities/JobSelect'
  import VendorSelect from '@/components/select/entities/VendorSelect'

  const TODAY = format(new Date(), 'yyyy-MM-dd')

  export default {
    components: {
      JobSelect,
      Trash2Icon,
      VendorSelect,
    },
    props: {
      vendor: {
        type: Object,
        default: () => ({}),
      },
      documentType: {
        type: String,
        default: 'Preliminary Notice',
      },
    },
    data() {
      return {
        perPage: 10,
        entries: [],
        typeOptions: [
          {
            label: this.$t('1'),
            value: '1',
          },
          {
            label: this.$t('2'),
            value: '2',
          },
          {
            label: this.$t('3'),
            value: '3',
          },
          {
            label: this.$t('4'),
            value: '4',
          },
        ],
      }
    },
    computed: {
      url() {
        return `/restify/vendor-documents?vendor_id=${this.vendor.id}&document_type=${this.documentType}`
      },
      columns() {
        let columns = [
          {
            label: this.$t('Job'),
            prop: 'job_id',
            smallCell: true,
            align: 'center',
            minWidth: 150,
            maxWidth: 180,
          }, {
            label: this.$t('Comment'),
            prop: 'comment',
            smallCell: true,
            align: 'center',
            minWidth: 170,
            maxWidth: 200,
          },
        ]

        if (this.isGeneralNotesDocumentType) {
          columns = [...columns, ...this.getGeneralNotesFields()]
        }

        if (this.isWaiverAndReleaseDocumentType) {
          columns = [...columns, ...this.getWaiverAndReleaseFields()]
        }

        if (this.isJointCheckArrangementDocumentType) {
          columns = [...columns, ...this.getJointCheckArrangementFields()]
        }

        if (this.isPreliminaryNoticeDocumentType) {
          columns = [...columns, ...this.getPreliminaryNoticeFields()]
        }

        columns.push({
          label: '',
          prop: 'actions',
          smallCell: true,
          minWidth: '100',
          maxWidth: '100',
        })

        return columns
      },
      isWaiverAndReleaseDocumentType() {
        return this.documentType === 'Waiver and Release'
      },
      isJointCheckArrangementDocumentType() {
        return this.documentType === 'Joint Check Arrangement'
      },
      isPreliminaryNoticeDocumentType() {
        return this.documentType === 'Preliminary Notice'
      },
      isGeneralNotesDocumentType() {
        return this.documentType === 'General Notes'
      },
    },
    methods: {
      getJointCheckArrangementFields() {
        return [{
          label: this.$t('Entry date'),
          prop: 'entry_date',
          smallCell: true,
          align: 'center',
          minWidth: 120,
          maxWidth: 150,
        }, {
          label: this.$t('Joint vendor'),
          prop: 'joint_vendor_id',
          smallCell: true,
          align: 'center',
          minWidth: 120,
          maxWidth: 150,
        }]
      },
      getPreliminaryNoticeFields() {
        return [{
          label: this.$t('Notice date'),
          prop: 'notice_date',
          smallCell: true,
          align: 'center',
          minWidth: 120,
          maxWidth: 150,
        }, {
          label: this.$t('Lienor vendor'),
          prop: 'lienor_vendor_id',
          smallCell: true,
          align: 'center',
          minWidth: 120,
          maxWidth: 150,
        }, {
          label: this.$t('Certified mail No'),
          prop: 'certified_mail_no',
          smallCell: true,
          align: 'center',
          minWidth: 120,
          maxWidth: 150,
        }, {
          label: this.$t('Estimated price'),
          prop: 'estimated_price',
          smallCell: true,
          align: 'center',
          minWidth: 120,
          maxWidth: 150,
        }]
      },
      getGeneralNotesFields() {
        return [{
          label: this.$t('Entry date'),
          prop: 'entry_date',
          smallCell: true,
          align: 'center',
          minWidth: 120,
          maxWidth: 150,
        }]
      },
      getWaiverAndReleaseFields() {
        return [{
          label: this.$t('Type'),
          prop: 'type',
          smallCell: true,
          align: 'center',
          minWidth: 120,
          maxWidth: 150,
        }, {
          label: this.$t('Amount'),
          prop: 'amount',
          smallCell: true,
          align: 'center',
          minWidth: 150,
          maxWidth: 150,
        }, {
          label: this.$t('Check No.'),
          prop: 'check_no',
          smallCell: true,
          align: 'center',
          minWidth: 120,
          maxWidth: 150,
        }, {
          label: this.$t('Date sent'),
          prop: 'date_sent',
          smallCell: true,
          align: 'center',
          minWidth: 120,
          maxWidth: 200,
        }, {
          label: this.$t('Throw date'),
          prop: 'throw_date',
          smallCell: true,
          align: 'center',
          minWidth: 120,
          maxWidth: 200,
        }, {
          label: this.$t('Receive date'),
          prop: 'receive_date',
          smallCell: true,
          align: 'center',
          minWidth: 120,
          maxWidth: 200,
        }]
      },
      dataMapFunction(data) {
        return data.map(row => {
          if (row.attributes) {
            return {
              ...row.attributes,
              id: row.id,
            }
          }
          return row
        })
      },
      focusOnLastActiveElement(lastActiveElement) {
        if (!lastActiveElement || !lastActiveElement.id) {
          return
        }
        this.$nextTick(() => {
          const element = document.querySelector(`#${lastActiveElement.id}`)
          if (element) {
            element.focus()
          }
        })
      },
      onFocusNumberInput(row, field) {
        if (row[field] === 0) {
          row[field] = ''
        }
      },
      onBlurNumberInput(row, field, index) {
        if (row[field] === '') {
          row[field] = 0
        }

        this.onFieldBlur(row, index)
      },
      onFieldChange(row) {
        this.$set(row, 'dirty', true)
      },
      getFormRequiredFields(documentType) {
        const fields = {
          'General Notes': [
            'job_id',
          ],
          'Waiver and Release': [
            'job_id',
          ],
          'Joint Check Arrangement': [
            'job_id',
            'joint_vendor_id',
          ],
          'Preliminary Notice': [
            'job_id',
            'lienor_vendor_id',
            'estimated_price',
          ],
        }
        return fields[documentType]
      },
      validEntry(index) {
        const entry = this.entries[index]
        const requiredFields = this.getFormRequiredFields(this.documentType)

        return Object.keys(pick(entry, requiredFields)).every(key => entry[key])
      },
      async onFieldBlur(row, index, isLastElement) {
        if (!row.dirty || !this.validEntry(index)) {
          return
        }

        let result = {}
        let lastActiveElement = document.activeElement

        try {
          this.$set(row, 'loading', true)
          this.focusOnLastActiveElement(lastActiveElement)

          const data = {
            ...row,
            vendor_id: this.vendor.id,
            document_type: this.documentType,
          }

          if (row.id) {
            result = await axios.put(`restify/vendor-documents/${row.id}`, data)
          } else if (isLastElement) {
            result = await axios.post(`restify/vendor-documents`, data)
          }
        } catch (err) {
          console.log(err)
          return false
        } finally {
          this.$set(row, 'loading', false)
        }

        let newRow = this.get(result, 'data.attributes', {})
        const previousRow = this.$refs.table.tableData[index]

        newRow = {
          ...newRow,
          ...previousRow,
          dirty: false,
        }

        this.$refs.table.tableData.splice(index, 1, newRow)
        this.focusOnLastActiveElement(lastActiveElement)
        return true
      },
      focusOnjob(index) {
        const job = document.querySelector(`#job-${index} input`)
        if (job) {
          job.focus()
        }
      },
      onEntriesFetch(data) {
        this.entries = data

        if (!this.$route.params.id) {
          this.entries.push(this.getEmptyEntry())
        }
      },
      getEmptyEntry() {
        return {
          vendor_id: undefined,
          job_id: undefined,
          document_type: '',
          comment: '',
          date_sent: TODAY,
          type: 1,
          amount: 0,
          check_no: 0,
          receive_date: TODAY,
          throw_date: TODAY,
          entry_date: TODAY,
          joint_vendor_id: undefined,
          notice_date: TODAY,
          lienor_vendor_id: undefined,
          certified_mail_no: '',
          estimated_price: 0,
        }
      },
      async addEntry() {
        await this.$refs.table.goToLastPage()

        this.entries.push(this.getEmptyEntry())
        this.$nextTick(() => {
          this.focusOnjob(this.entries.length - 1)
        })
      },
      async goToNextPage() {
        await this.$refs.table.goToNextPage()
        this.$nextTick(() => {
          this.focusOnjob(this.entries.length - 1)
        })
      },
      tryAddEntry(index, row) {
        this.$set(row, 'dirty', true)
        this.onFieldBlur(row, index, true)

        if (!this.validEntry(index)) {
          return
        }

        const { pagination } = this.$refs.table
        const isLastPage = pagination.current_page === pagination.last_page
        const isLastItem = index === this.entries.length - 1
        if (isLastItem && isLastPage) {
          this.addEntry()
        }
        if (isLastItem && !isLastPage) {
          this.goToNextPage()
        }
      },
      async deleteRow(index) {
        const entry = this.entries[index]
        this.entries.splice(index, 1)
        if (!entry.id) {
          return
        }
        try {
          await axios.delete(`/restify/vendor-documents/${entry.id}`)
        } catch (err) {
          this.entries.splice(index, 0, entry)
        }
      },
    },
  }
</script>
