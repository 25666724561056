import i18n from '@/i18n'
import en from 'vee-validate/dist/locale/en.json'
import { extend, localize, ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  alpha,
  alpha_num,
  email,
  excluded,
  length,
  max,
  max_value,
  min,
  min_value,
  numeric,
  regex,
  required,
} from 'vee-validate/dist/rules'
import {
  checkForDuplicateCode, hoursValidation,
  routingNumberValidator,
  zipCodeValidator
} from '@/modules/common/util/validators'
import { globalResourceLabels } from "@/components/form/util";

extend('required', required)
extend('email', email)
extend('min', min)
extend('max', max)
extend('max_value', max_value)
extend('min_value', min_value)
extend('numeric', numeric)
extend('excluded', excluded)
extend('alpha', alpha)
extend('alpha_num', alpha_num)
extend('length', length)
extend('regex', regex)

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: i18n.t('Password confirmation does not match'),
})

const codeMaxLength = 8
const codeMinLength = 2
export const validCodeChars = 'a-zA-Z0-9$%&*_-'

extend('code', {
  params: ['resourceName', 'min', 'max', 'key', 'id'],
  validate(value, { resourceName, min, max, key, id }) {
    if (!value) {
      return false
    }
    const minLength = min || codeMinLength
    const maxLength = max || codeMaxLength
    const codeKey = key || 'code'
    const regex = new RegExp(`^[${validCodeChars}]{${minLength},${maxLength}}$`)
    const valid =  regex.test(value)
    if (valid && resourceName) {
      const isDuplicate = checkForDuplicateCode({ resourceName, code: value, key: codeKey, id })
      return !isDuplicate
    }
    return valid
  },
  message: (field, { resourceName, ...rest }) => {
    const value = rest._value_
    if (!value) {
      return `${field} is required`
    }
    const key = rest.key || 'code'
    const id = rest.id
    const minLength = rest.min || codeMinLength
    const maxLength = rest.max || codeMaxLength
    if (value?.length < minLength) {
      return `${field} must be at least ${minLength} characters`
    }
    if (value?.length > maxLength) {
      return `${field} must be max ${maxLength} characters`
    }
    const isDuplicateCode = checkForDuplicateCode({ resourceName, code: value, key: rest.key, id})
    if (isDuplicateCode && resourceName) {
      const resourceLabel = globalResourceLabels[resourceName] || resourceName
      return `${resourceLabel} with this ${key} already exists`
    }
    return `${field} is invalid.`
  }
})

const urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/

extend('url', {
  validate: (value) => {
    return urlRegex.test(value)
  },
  message: i18n.t('Invalid url. Please provide a valid url'),
})

extend('routingNumber', {
  validate: (value) => {
    return routingNumberValidator(value)
  },
  message: i18n.t('Please use a valid routing number.'),
})

extend('timeSheetDuration', {
  validate: value => {
    return hoursValidation(value)
  },
  message: i18n.t('Duration is invalid'),
})

extend('grossAmount', {
  validate: value => {
    const min = -999999999.99
    const max = 999999999.99
    const num = parseFloat(value)
    if (num === 0) {
      return false
    }
    return num >= min && num <= max
  },
  message: i18n.t('Gross Amount is invalid'),
})

extend('zipCode', {
  validate: (value) => {
    return zipCodeValidator(value)
  },
  message: i18n.t('Please use a valid zip code.'),
})

extend('noWildcard', {
  validate: (value) => {
    return !value.startsWith('*')
  },
  message: i18n.t('Wildcard * is not allowed'),
})

extend('startsWith', {
  validate: (value, args) => {
    const { substring } = args
    return value.startsWith(substring)
  },
  params: ['substring'],
  message: (field, { substring }) => {
    return `The ${field} field must start with "${substring}".`
  }
});

const dictionary = {
  custom: {},
}

localize({
  en: {
    ...en,
    ...dictionary,
  },
})

export default {
  install(Vue) {
    Vue.component('ValidationObserver', ValidationObserver)
    Vue.component('ValidationProvider', ValidationProvider)
  },
}
