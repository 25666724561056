<template>
  <AddressSelect
      ref="select"
      v-focus
      v-bind="params"
      v-model="selectedValue"
      :value-key="params.valueKey"
      :address-type="params.address_type"
      :addressable-id="params.addressable_id"
      :include-default-address="true"
      :data="undefined"
      inline-errors
      class="w-full"
      @change="onChange"
      @keydown.enter.native="goToNextCell"
  />
</template>
<script>
  import { selectEditorMixin } from "@/components/ag-grid/cellEditors/selectEditorMixin";

  export default {
    name: 'AddressSelectCellEditor',
    mixins: [selectEditorMixin],
  }
</script>
