<template>
    <span :class="{
             'rounded-md': !rounded,
             'rounded-full': rounded,
             'block w-full': block,
             'inline-flex': !block,
          }"
          class="base-button"
    >
        <button v-bind="$attrs"
                :type="$attrs.type || 'button'"
                :disabled="disabled || loading"
                v-on="$listeners"
                ref="button"
                class="btn"
                :class="{
                  'rounded-md': !rounded,
                  'rounded-full': rounded,
                  'btn-primary': variant === 'primary',
                  'btn-success': variant === 'success',
                  'btn-primary-link': variant === 'primary-link',
                  'btn-green-link': variant === 'green-link',
                  'btn-yellow-link': variant === 'yellow-link',
                  'btn-gray-link': variant === 'gray-link',
                  'gray-icon-button': variant === 'gray-icon',
                  'btn-gray-light': variant === 'gray-light',
                  'btn-danger': variant === 'danger',
                  'btn-danger-light': variant === 'danger-light',
                  'btn-primary-light': variant === 'primary-light',
                  'btn-green-light': variant === 'green-light',
                  'btn-yellow-light': variant === 'yellow-light',
                  'btn-danger-link': variant === 'danger-link',
                  'btn-secondary': variant === 'secondary',
                  'btn-white': variant === 'white',
                  'icon-button': size === 'icon',
                  'btn-xs': size === 'xs',
                  'btn-sm': size === 'sm',
                  'btn-md': size === 'md',
                  'btn-lg': size === 'lg',
                  'btn-xl': size === 'xl',
                  'btn-no-padding': size === 'no-padding',
                  'opacity-50 cursor-not-allowed': disabled || loading,
                  'inline-flex': !block,
                  'w-full flex justify-center': block,
                  [btnClass]: btnClass
                }"
        >
        <div v-if="loading"
             class="absolute flex w-full items-center justify-center">
            <loader-icon class="spin-animation"
                         :class="{
                           'h-4 w-4': size === 'xs',
                           'h-5 w-5': size === 'sm',
                           'h-5 w-5': !['xs', 'sm'].includes(size),
                         }"/>
        </div>

        <div class="flex items-center whitespace-nowrap"
             :class="{'opacity-0': loading}"
        >
            <slot></slot>
        </div>
    </button>
    </span>
</template>
<script>
  import { LoaderIcon } from 'vue-feather-icons'

  export default {
    inheritAttrs: false,
    components: {
      LoaderIcon,
    },
    props: {
      block: {
        type: Boolean,
        default: false
      },
      rounded: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      loading: {
        type: Boolean,
        default: false
      },
      variant: {
        type: String,
        default: 'primary' // primary|secondary|danger|danger-secondary|white
      },
      size: {
        type: String,
        default: 'md' // xs|sm|md|lg|xl
      },
      btnClass: {
        type: String,
      }
    },
    methods: {
      focus() {
        if (!this.$refs.button) {
          return
        }
        this.$refs.button.focus()
      }
    },
  }
</script>
<style lang="scss">
  .icon-button {
    padding: 8px;
    @screen md {
      padding: 6px;
    }
  }

  .gray-icon-button {
    @apply text-gray-800 hover:text-gray-900 bg-gray-900 bg-opacity-5 hover:bg-opacity-25;
  }
</style>
