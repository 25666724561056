<template>
  <div></div>
</template>
<script>
  import axios from "axios";

  export default {
    methods: {
      async deleteRow() {
        const confirmed = await this.$deleteConfirm({
          title: this.$t('Delete entry'),
          description: this.$t('Are you sure you want to delete this timecard entry ? ')
        })
        const row = this.params.data
        if (confirmed) {
          try {
            this.params.api.applyTransaction({
              remove: [row]
            });
            if (row.id) {
              await axios.delete(`/restify/timecard-entries/${row.id}`)
              await this.$store.dispatch('payroll/resetTimecardBatchToPending')
            }
            if (this.params.onConfirm) {
              this.params.onConfirm(this.params)
            }
          } catch (err) {
            this.params.api.applyTransaction({
              add: [row]
            });
          }
        } else {
          this.params?.stopEditing()
        }
      }
    },
    async mounted() {
      await this.deleteRow()
    },
  }
</script>
