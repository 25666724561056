<template>
  <div class="import-data mt-4">
    <ImportForm v-bind="importSettings"
                @success="redirectToList"
    >
      <div class="col-span-6 md:col-span-1">
        <base-input :label="$t('Fiscal Year')">
          <template v-slot:container>
            <fiscal-year-select v-model="model.year"
                                class="w-full"
                                id="fiscal_year"
            />
          </template>
        </base-input>
      </div>
      <div class="col-span-6 md:col-span-1">
        <period-select
          v-model="model.period"
          :fiscal-year="model.fiscal_year"
          :show-offset-periods="true"
          :label="$t('Period')"
          :name="$t('Period')"
          rules="required"
          id="period-select"
        />
      </div>
    </ImportForm>
  </div>
</template>
<script>
  import { getInitialMapping } from "@/modules/payroll/utils/timecardImportColumns";
  import ImportForm from "@/modules/common/components/import/ImportForm";
  import PeriodEndDatePicker from "@/modules/payroll/components/timecard/PeriodEndDatePicker";
  import Cache from "@/utils/Cache";

  const colMappings = {
    Account: {
      value: 'account',
      label: 'Account',
      alternativeLabels: ['Account No.'],
      required: true,
    },
    SubAccount: {
      value: 'subaccount',
      label: 'Sub Accoount',
      alternativeLabels: ['Sub Acct'],
    },
    Description: {
      value: 'description',
      label: 'Description',
      alternativeLabels: ['Transaction Description'],
    },
    Journal: {
      value: 'journal_name',
      label: 'Journal',
      alternativeLabels: ['Journal Name'],
      required: true,
    },
    ReferenceDate: {
      value: 'reference_date',
      label: 'Reference Date',
      alternativeLabels: ['Date'],
    },
    ReferenceNumber: {
      value: 'reference_no',
      label: 'Reference No',
      alternativeLabels: ['Reference No.'],
    },
    Type: {
      value: 'journal_type',
      label: 'Type',
      alternativeLabels: ['Account Restrictions', 'Journal Type'],
    },
    Amount: {
      value: 'amount',
      label: 'Amount',
      alternativeLabels: ['$ Amount'],
      required: true,
    },
  }
  export const options = [
    colMappings.Account,
    colMappings.SubAccount,
    colMappings.Description,
    colMappings.Journal,
    colMappings.ReferenceDate,
    colMappings.ReferenceNumber,
    colMappings.Type,
    colMappings.Amount,
  ]


  export default {
    components: {
      ImportForm,
      PeriodEndDatePicker,
    },
    data() {
      return {
        model: {
          year: null,
        },
        importSettings: {
          url: '/restify/journal-entries/import',
          columnOptions: options,
          columnMappings: colMappings,
          initialMapping: getInitialMapping(options),
          type: 'journals-import',
          transformRequestData: this.transformRequestData,
          resource: 'journals',
        },
      }
    },
    methods: {
      transformRequestData(formData) {
        formData.append('fiscal_year', this.model.year)
        formData.append('period', this.model.period)
      },
      async redirectToList() {
        Cache.removeForEntity('journals')
        await this.$router.push('/ledger/journal/pending?refresh=true')
        await this.$store.dispatch('globalLists/getGeneralConfiguration', true)
      },
    }
  }
</script>
