<template>
  <div v-if="!params.disabled" class="flex items-center">
    <div
        class="w-6 h-6 mr-2 border rounded-md"
        :style="{
          'background-color': `${params.value}`,
        }"
    />
    {{ formattedValue }}
  </div>
</template>
<script setup lang="ts">
  import { computed } from 'vue'
  import { hexColorToRgba } from '@/utils/utils.js'


  const props = defineProps({
    params: {
      type: Object,
      default: () => ({}),
    },
  })
  const formattedValue = computed(() => {
    const value = props.params.value

    if (!value) {
      return ''
    }

    return hexColorToRgba(value, 1)
  })
</script>
