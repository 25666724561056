<template>
  <JobsTable/>
</template>
<script>
import JobsTable from "@/modules/job-costing/components/job/JobsTable.vue";

export default {
  components: {
    JobsTable,
  }
}
</script>
