<template>
  <AgDataTable
      :columns="columns"
      :url-params="urlParams"
      :default-match-filters="defaultMatchFilters"
      :can-reorder-columns="false"
      :compact="true"
      :show-actions-column="false"
      domLayout="autoHeight"
      actions="search,refresh"
      url="/restify/transactions"
      class="col-span-2"
  >
    <template #additional-actions-before>
      <slot name="actions"></slot>
    </template>
    <template #attributes.journal="{row}">
      <span v-if="!getJournalId(row.attributes)">
        {{ row.attributes.journal }}
      </span>
      <router-link
          v-else
          :to="getJournalPath(row.attributes)"
      >
        {{ row.attributes.journal }}
      </router-link>
    </template>

    <template #period_fiscal_year="{row}">
      {{ `${row.attributes.period} / ${row.attributes.fiscal_year}` }}
    </template>

    <template #attributes.business="{row}">
      <span v-if="!get(row, 'relationships.business.id')"/>
      <component
          v-else
          :is="getRelatedBusinessComponent(row)"
          :data="get(row, 'relationships.business')"
          :show-description="false"
      />
    </template>

    <template #attributes.reference_no="{row}">
      <ReferenceLink :data="row"/>
    </template>

  </AgDataTable>
</template>
<script lang="ts">

import {defineComponent} from 'vue'
import {Column} from '@/components/ag-grid/tableTypes'
import {Transaction} from '@/modules/common/types/models'
import {ICellRendererParams} from "@ag-grid-community/core";
import {getJournalPath} from "@/components/links/util";

const businessTypeComponent: Record<string, string> = {
  customer: 'CustomerLink',
  vendor: 'VendorLink',
  employee: 'EmployeeLink',
  default: '',
}

export default defineComponent({
  props: {
    urlParams: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      columns: [
        {
          headerName: this.$t('Journal'),
          field: 'attributes.journal',
          minWidth: 100,
          maxWidth: 120,
        },
        {
          headerName: this.$t('Ref Date'),
          field: 'attributes.reference_date',
          component: 'FormattedDate',
          minWidth: 100,
          maxWidth: 120,
        },
        {
          headerName: this.$t('Description'),
          field: 'attributes.description',
          minWidth: 120,
        },
        {
          headerName: this.$t('Period / Year'),
          field: 'period_fiscal_year',
          minWidth: 100,
          maxWidth: 120,
        },
        {
          headerName: this.$t('G/L Account'),
          field: 'relationships.account.attributes.number',
          minWidth: 250,
          maxWidth: 320,
          component: 'AccountLink',
        },
        {
          headerName: this.$t('Ven / Cust'),
          field: 'attributes.business',
          minWidth: 100,
          maxWidth: 120,
        },
        {
          headerName: this.$t('Ref No.'),
          field: 'attributes.reference_no',
          minWidth: 100,
          maxWidth: 160,
        },
        {
          headerName: this.$t('Type'),
          field: 'relationships.type.name',
          minWidth: 100,
          maxWidth: 160,
        },
        {
          headerName: this.$t('Special Source'),
          field: 'attributes.special_source_id',
          component: 'SpecialSourceLink',
          minWidth: 140,
          maxWidth: 250,
        },
        {
          headerName: this.$t('Qty / H'),
          field: 'attributes.quantity',
          minWidth: 100,
          maxWidth: 120,
        },
        {
          headerName: this.$t('Amount'),
          field: 'attributes.amount',
          component: 'FormattedPrice',
          cellRendererParams: {
            hideZero: true,
          },
          minWidth: 120,
          maxWidth: 160,
        },
      ] as Column[],
    }
  },
  computed: {
    defaultMatchFilters() {
      return {
        hide_voided: true,
      }
    },
  },
  methods: {
    getRelatedBusinessComponent(row: API.Data<any>) {
      const {business_type} = row.attributes
      return businessTypeComponent[business_type]
    },
    getJournalId(data: Transaction) {
      return data?.journal_id
    },
    getJournalPath(data: Transaction) {
      const journal = data.journal || ''
      return getJournalPath(this.getJournalId(data), journal)
    }
  },
})
</script>
