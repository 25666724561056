<template>
  <JobCostingSetting ref="settings"/>
</template>
<script>
  import JobCostingSetting from '@/modules/job-costing/components/JobCostingSetting'

  export default {
    components: {
      JobCostingSetting,
    },
    async beforeRouteLeave(to, from , next) {
      const answer = await this.$refs.settings.showUnsavedWarning()
      if (answer) {
        next()
      } else {
        next(false)
      }
    }

  }
</script>
