<template>
  <base-data-table
      v-bind="defaultAttributes"
      :columns="columns"
      :data="getData"
      :total-rows="rowsLength"
      :has-summary="true"
      @force-pagination="forcePagination"
  >

    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template #header="{row}">
      <AccountLink :data="row.header?.account"/>
    </template>

    <template #journal.name="{row}">
      <JournalLink :data="row.journal"/>
    </template>

    <template #period_fiscal_year="{row}">
      {{ row.transaction?.period }} -
      {{ row.transaction?.fiscal_year }}
    </template>

    <template #transaction.business="{row}">
      <component
          v-if="get(row, 'business.id')"
          :is="getBusinessComponent(row.business.type)"
          :data="row.business"
          :show-preview="false"
          :show-description="false"
          :show-name="false"
      />
    </template>

    <template #special_source="{row}">
      <component
          v-if="get(row, 'row.specialSource.id')"
          :is="getSpecialSourceLinkComponent(row)"
          :data="row.specialSource"
          :show-description="false"
      />
      <span v-else></span>
    </template>

    <template #subtotal="{subtotal}">
      <template v-if="!subtotal.isAccountTotal">
        <td colspan="6"/>
        <td align="right">{{ subtotalOnly ? '' : $t('SubTotal:') }}</td>
        <td>
          <router-link
              v-if="subtotal.source?.id"
              :to="`/job-costing/jobs/${subtotal.source?.id}/view`"
          >
            {{ subtotal.source?.code }}
          </router-link>
          <span v-else></span>
        </td>
        <td colspan="6"/>
      </template>
      <template v-else>
        <td colspan="13"/>
        <td>{{ $t('Account Total:') }}</td>
      </template>
      <td align="right">
        {{ $formatPrice(subtotal?.amount) }}
      </td>
    </template>

  </base-data-table>
</template>
<script lang="ts">
  import { defineComponent, PropType } from 'vue'
  import { Column } from '@/components/ag-grid/tableTypes'
  import { transactionBusinessLinkByTypes, transactionSpecialSourceLinkByTypes } from '@/enum/enums.js'
  import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper.vue'

  export default defineComponent({
    extends: ReportTableWrapper,
    props: {
      subtotalOnly: {
        type: Boolean as PropType<boolean>,
        default: false,
      },
    },
    data() {
      return {
        columns: <Column[]>
            [
              {
                label: this.$t('Sub <br> Account'),
                prop: 'transaction.subaccount.number',
                component: 'EntityLink',
                redirectTo: '/ledger/sub-accounts/{ID}/view',
                minWidth: 80,
                maxWidth: 80,
              },
              {
                label: this.$t('Fiscal<br>P/Year'),
                prop: 'period_fiscal_year',
                minWidth: 80,
                maxWidth: 100,
              },
              {
                label: this.$t('Journal'),
                prop: 'journal.name',
                minWidth: 80,
                maxWidth: 120,
              },
              {
                label: this.$t('Reference <br> Number'),
                prop: 'transaction.reference_number',
                minWidth: 100,
                maxWidth: 120,
              },
              {
                label: this.$t('Reference <br> Date'),
                prop: 'transaction.reference_date',
                component: 'FormattedDate',
                minWidth: 100,
                maxWidth: 120,
              },
              {
                label: this.$t('Vend/Cust <br> Empl'),
                prop: 'transaction.business',
                minWidth: 80,
                maxWidth: 100,
              },
              {
                label: this.$t('Description'),
                prop: 'transaction.description',
                minWidth: 120,
                maxWidth: 160,
                summary: () => this.$t('Grand Total All Accounts:'),
              },
              {
                label: this.$t('Job'),
                prop: 'source.code',
                component: 'EntityLink',
                redirectTo: '/job-costing/jobs/{ID}/view',
                entityKey: 'source.id',
                minWidth: 80,
                maxWidth: 100,
              },
              {
                label: this.$t('Phase <br> Code'),
                prop: 'addlSource.phase_code',
                component: 'EntityLink',
                redirectTo: '/job-costing/income-line-items/{ID}/view',
                entityKey: 'addlSource.id',
                minWidth: 60,
                maxWidth: 60,
              },
              {
                label: this.$t('Cost <br> Code'),
                prop: 'addlSource.cost_code',
                component: 'EntityLink',
                redirectTo: '/job-costing/income-line-items/{ID}/view',
                entityKey: 'addlSource.id',
                minWidth: 60,
                maxWidth: 60,
              },
              {
                label: this.$t('Change <br> Order'),
                prop: 'addlSource.change_order',
                component: 'EntityLink',
                redirectTo: '/job-costing/income-line-items/{ID}/view',
                entityKey: 'addlSource.id',
                minWidth: 60,
                maxWidth: 60,
              },
              {
                label: this.$t('Type'),
                prop: 'type.abbr',
                component: 'EntityLink',
                redirectTo: '/job-costing/settings/job-cost-settings',
                minWidth: 40,
                maxWidth: 50,
              },
              {
                label: this.$t('Mtl/Eqp <br> Wrk Ord'),
                prop: 'special_source',
                minWidth: 80,
                maxWidth: 100,
              },
              {
                label: this.$t('Qty/<br>Hours'),
                prop: 'transaction.meta.quantity',
                minWidth: 40,
                maxWidth: 50,
                hideZero: true,
              },
              {
                label: this.$t('Amount'),
                prop: 'transaction.amount',
                component: 'FormattedPrice',
                align: 'right',
                minWidth: 100,
                maxWidth: 120,
                hideZero: true,
                summary: () => {
                  const total = this.grandTotals?.amount
                  return this.$formatPrice(total)
                },
              },
            ],
      }
    },
    methods: {
      composeRows(data: Record<string, any>) {
        data.forEach((group: Record<string, any>) => {
          const header = {
            account: group.account,
          }

          if (header.account?.number) {
          //@ts-ignore
            this.rows.push({
              header,
            })
          }

          const sources = group?.sources || {}

          if (!this.subtotalOnly) {
            sources.forEach((sourceObject: Record<string, any>) => {
              let transactions = sourceObject?.transactions || []
              let source = sourceObject?.source || {}

              transactions = transactions.map((transaction: Record<string, any>) => {
                transaction.source = source
                return {
                  ...transaction,
                }
              })

              // @ts-ignore
              this.rows.push(...transactions)

              let subtotal = sourceObject?.subtotals || {}
              subtotal.source = source

              // @ts-ignore
              this.rows.push({
                subtotal,
              })
            })
          } else {
            sources.forEach((sourceObject: Record<string, any>) => {
              let source = sourceObject?.source || {}
              let subtotal = sourceObject?.subtotals || {}
              subtotal.source = source

              // @ts-ignore
              this.rows.push({
                subtotal,
              })
            })
          }


          let subtotal = group?.totals || {}
          subtotal.isAccountTotal = true

          // @ts-ignore
          this.rows.push({
            subtotal,
          })

        })
      },
      getBusinessComponent(businessType: string) {
        return transactionBusinessLinkByTypes[businessType]
      },
      getSpecialSourceLinkComponent(row: Record<string, any>) {
        const special_source_type = row.specialSource?.type
        if (!special_source_type) {
          return ''
        }
        return transactionSpecialSourceLinkByTypes[special_source_type] || ''
      },
    },
  })
</script>
