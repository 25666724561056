import i18n from "@/i18n";
import {costTypes} from "@/enum/enums";
import {cellEditors} from "@/components/ag-grid/cellEditors/cellEditors";
import {cellClasses, markupValidator, requiredValueSetter, stopEditingOnTab} from "@/components/ag-grid/columnUtils";
import {Column} from "@/components/ag-grid/tableTypes";

export const modules = {
  AccountsPayable: 'accounts-payable',
  AccountsReceivable: 'accounts-receivable',
  GeneralLedger: 'general-ledger',
  Payroll: 'payroll',
  JobCosting: 'job-costing',
  ServiceBilling: 'service-billing',
  Inventory: 'purchasing-inventory',
  Equipment: 'equipment',
  Settings: 'settings',
}

export const modulePrefixes = {
  [modules.JobCosting]: 'job',
  [modules.ServiceBilling]: 'sb',
}

export const expenseAccountTypes = (module: string) => {
  const prefix = modulePrefixes[module]
  return {
    [`employer_benefits_${prefix}_expense_account`]: '',
    [`employer_benefits_${prefix}_expense_subaccount`]: '',
    [`fica_${prefix}_expense_account`]: '',
    [`fica_${prefix}_expense_subaccount`]: '',
    [`fui_${prefix}_expense_account`]: '',
    [`fui_${prefix}_expense_subaccount`]: '',
    [`gen_liability_${prefix}_expense_account`]: '',
    [`gen_liability_${prefix}_expense_subaccount`]: '',
    [`lit_${prefix}_expense_account`]: '',
    [`lit_${prefix}_expense_subaccount`]: '',
    [`medicare_${prefix}_expense_account`]: '',
    [`medicare_${prefix}_expense_subaccount`]: '',
    [`sui_${prefix}_expense_account`]: '',
    [`sui_${prefix}_expense_subaccount`]: '',
    [`workers_comp_${prefix}_expense_account`]: '',
    [`workers_comp_${prefix}_expense_subaccount`]: '',
    [`estimated_burden_${prefix}_expense_account`]: '',
    [`estimated_burden_${prefix}_expense_subaccount`]: '',
  }
}

const columnsPerModule = (module: string, type: string) => {
  const accountColumnProps: Partial<Column> = {
    component: 'AccountLink',
    editable: true,
    cellEditor: cellEditors.AccountSelect,
    cellEditorParams: {
      valueKey: 'number'
    }
  }

  const subAccountColumnProps: Partial<Column> = {
    component: 'SubAccountLink',
    width: 80,
    maxWidth: 100,
    editable: true,
    cellEditor: cellEditors.SubAccountSelect,
    cellEditorParams: {
      valueKey: 'number'
    },
  }
  if (module === modules.ServiceBilling) {
    return {
      columns: [
        {
          headerName: i18n.t('Name'),
          field: 'name',
          cellClass: cellClasses.ReadOnly,
          minWidth: 200,
          maxWidth: 320,
        },
        {
          headerName: type === costTypes.Cost ? i18n.t('Debit Account') : i18n.t('Non-Tax Account'),
          field: 'non_tax_account',
          ...accountColumnProps,
        },
        {
          headerName: i18n.t('Sub'),
          field: 'non_tax_subaccount',
          component: 'SubAccountLink',
          ...subAccountColumnProps
        },
        {
          headerName: i18n.t('Taxable Account'),
          field: 'account',
          hide: type !== costTypes.Income,
          ...accountColumnProps,
        },
        {
          headerName: i18n.t('Sub'),
          field: 'subaccount',
          ...subAccountColumnProps,
          hide: type !== costTypes.Income,
        },
        {
          headerName: i18n.t('Markup'),
          field: 'markup_percent',
          cellEditor: cellEditors.Numeric,
          component: 'FormattedPercent',
          minWidth: 80,
          editable: true,
          hide: type !== costTypes.Cost,
        },
      ] as Column[]
    }
  }
  if (module === modules.JobCosting && type === costTypes.Cost) {
    return {
      columns: [
        {
          headerName: i18n.t('Name'),
          field: 'name',
          cellClass: cellClasses.ReadOnly,
          minWidth: 200,
          maxWidth: 320,
        },
        {
          headerName: i18n.t('Expense Account'),
          field: 'debit_account',
          ...accountColumnProps,
        },
        {
          headerName: i18n.t('Expense Sub'),
          field: 'debit_subaccount',
          ...subAccountColumnProps,
        },
        {
          headerName: i18n.t('Credit Account'),
          field: 'credit_account',
          ...accountColumnProps,
        },
        {
          headerName: i18n.t('Credit Sub'),
          field: 'credit_subaccount',
          ...subAccountColumnProps
        },
        {
          headerName: i18n.t('Subject to Tax'),
          field: 'subject_to_tax',
          component: 'Status',
          cellEditor: cellEditors.Boolean,
          editable: true,
          minWidth: 60,
          maxWidth: 100,
        },
        {
          headerName: i18n.t('Markup Subject to Tax'),
          field: 'markup_subject_to_tax',
          component: 'Status',
          cellEditor: cellEditors.Boolean,
          editable: true,
          minWidth: 60,
          maxWidth: 100,
        },
        {
          headerName: i18n.t('Profit Subject to Tax'),
          field: 'profit_subject_to_tax',
          component: 'Status',
          cellEditor: cellEditors.Boolean,
          editable: true,
          minWidth: 60,
          maxWidth: 100,
        },
        {
          headerName: i18n.t('Markup'),
          field: 'markup_percent',
          component: 'FormattedPercent',
          editable: true,
          cellEditor: cellEditors.Numeric,
          hide: type !== costTypes.Cost,
        },
        {
          headerName: i18n.t('Profit'),
          field: 'profit_percent',
          component: 'FormattedPercent',
          cellEditor: cellEditors.Numeric,
          valueSetter: params => {
            return requiredValueSetter(params, 0, markupValidator)
          },
          suppressKeyboardEvent: stopEditingOnTab,
          editable: true,
          minWidth: 100,
          maxWidth: 120,
        },
      ] as Column[]
    }
  }
  if (module === modules.JobCosting && type === costTypes.Income) {
    return {
      columns: [
        {
          headerName: i18n.t('Name'),
          field: 'name',
          cellClass: cellClasses.ReadOnly,
          minWidth: 200,
          maxWidth: 320,
        },
        {
          headerName: i18n.t('Credit Account'),
          field: 'credit_account',
          ...accountColumnProps,
        },
        {
          headerName: i18n.t('Credit Sub'),
          field: 'credit_subaccount',
          ...subAccountColumnProps
        },
        {
          headerName: i18n.t('Debit Account'),
          field: 'debit_account',
          ...accountColumnProps,
        },
        {
          headerName: i18n.t('Debit Sub'),
          field: 'debit_subaccount',
          ...subAccountColumnProps,
        },
        {
          headerName: i18n.t('Markup'),
          field: 'markup_percent',
          component: 'FormattedPercent',
          editable: true,
          cellEditor: cellEditors.Numeric,
          hide: type !== costTypes.Cost,
        },
      ] as Column[]
    }
  }

  return {}
}

const accountTypeTabs = {
  [modules.ServiceBilling]: [
    {
      name: costTypes.Cost,
      label: i18n.t('Cost Types'),
    },
    {
      name: costTypes.Income,
      label: i18n.t('Billing Types'),
    },
  ],
  [modules.JobCosting]: [
    {
      name: costTypes.Cost,
      label: i18n.t('Cost Types'),
    },
    {
      name: costTypes.Income,
      label: i18n.t('Income Types'),
    },
  ]
}

export function getAccountTypeTabs(module: string) {
  return accountTypeTabs[module]
}

export function getTableProps(module: string, type: string) {
  return columnsPerModule(module, type)
}
