<template>
  <BaseDataTable
      url="/restify/job-progress-histories"
      actions="refresh,search"
      :show-actions-column="false"
      :limitMaxHeight="false"
      :urlParams="urlParams"
      :columns="columns"
      v-bind="$attrs"
  >
    <template #name="{row}">
      <span class="flex space-x-2 mb-4">
        <UserLink :id="row.attributes.created_by"/>
        <span>{{ $t('updated') }}</span>
        <span class="font-medium">{{ getJobTypeName(row.attributes.job_type_id) }}</span>

        <span v-if="displayLineItem" class="flex space-x-2">
          <span>{{ $t('on line item') }}</span>
          <LineItemLink :id="row.attributes.line_item_id"/>
        </span>

        <span>{{ timeAgo(row.attributes.updated_at) }} {{ $t('ago') }}</span>
      </span>
      <ul>
        <li v-for="field in getChangedFields(row)"
            :key="field.name"
            class="flex space-x-2 items-center"
        >
          <span class="w-40">
            {{ field.name }}
          </span>
          <span>{{ field.prior }}</span> <span>-></span> <span>{{ field.current }}</span>
        </li>
      </ul>
    </template>
  </BaseDataTable>
</template>
<script>
  import { timeAgo } from '@/plugins/dateFormatPlugin'

  export default {
    props: {
      budgetId: String,
      urlParams: {
        type: Object,
        default: () => ({})
      }
    },
    computed: {
      displayLineItem() {
        return !this.urlParams.line_item_id
      },
      columns() {
        return [
          {
            label: this.$t('Action'),
            prop: 'name',
          },
        ]
      },
      fieldLabels() {
        return {
          completion: this.$t('% Est Completion'),
          est_final_cost: this.$t('Est Final Cost'),
          last_updated_at: this.$t('Updated At'),
          last_updated_by: this.$t('Updated By'),
          variance_amount: this.$t('Variance'),
        }
      }
    },
    methods: {
      timeAgo,
      getJobType(jobTypeId) {
        return this.$store.getters['globalLists/getResourceById'](this.$globalResources.JobTypes, jobTypeId)
      },
      getJobTypeName(jobTypeId) {
        return this.getJobType(jobTypeId)?.name || ''
      },
      getChangedFields(row) {
        const { prior, current } = row?.attributes
        const fields = []

        const fieldsToSkip = ['last_updated_at', 'last_updated_by']
        const priceFields = ['variance_amount', 'est_final_cost']
        const percentageFields = ['completion']

        for (let key in prior) {
          let priorValue = prior[key]
          let currentValue = current[key]

          if (priorValue === currentValue || fieldsToSkip.includes(key)) {
            continue
          }

          if (priceFields.includes(key)) {
            priorValue = this.$formatPrice(priorValue || 0)
            currentValue = this.$formatPrice(currentValue || 0)
          } else if (percentageFields.includes(key)) {
            const formatOptions = {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            }
            priorValue = this.$formatPercent(priorValue || 0, formatOptions)
            currentValue = this.$formatPercent(currentValue || 0, formatOptions)
          }

          fields.push({
            name: this.fieldLabels[key] || key,
            field: key,
            prior: priorValue,
            current: currentValue,
          })
        }
        return fields
      }
    }
  }
</script>
