<template>
  <div class="flex-1 h-full">
    <layout-tabs
        v-if="!onViewResourcePage"
        :items="filteredItems"
    />
    <router-view/>
  </div>
</template>
<script>

  export default {
    data() {
      return {
        items: [
          {
            name: this.$t('Pending'),
            tooltip: this.$t('Purchase orders that were not posted yet'),
            path: '/purchasing-inventory/purchase-orders-adjustments/pending',
            permission: 'purchase_orders_update',
          },
          {
            name: this.$t('Open'),
            tooltip: this.$t('Posted purchase order adjustments'),
            path: '/purchasing-inventory/purchase-orders-adjustments/open',
            permission: 'purchase_orders_update',
          },
          {
            name: this.$t('All'),
            tooltip: this.$t('All purchase order adjustments'),
            path: '/purchasing-inventory/purchase-orders-adjustments/all',
            permission: 'purchase_orders_update',
          },
          {
            name: this.$t('Review'),
            tooltip: this.$t('Review purchase order adjustments'),
            path: '/purchasing-inventory/purchase-orders-adjustments/review',
            permission: 'purchase_orders_approve',
          },
        ],
      }
    },
    computed: {
      filteredItems() {
        return this.items.filter(item => this.$can(item.permission))
      },
    },
  }
</script>
