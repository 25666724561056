<template>
  <BaseWrapper v-bind="$attrs"
               :items="items"
               :entity="entity"
               :footer="footer"
               :locationLink="getLocationLink"
               phone-key="worksite_phone"
               title-key="name"
               status-key="status"
  />
</template>
<script>
  import { locationLink } from '@/utils/utils'
  import { capitalize } from 'lodash/string'
  import BaseWrapper from '@/modules/common/components/entity-preview/BaseWrapper'

  export default {
    components: {
      BaseWrapper,
    },
    props: {
      entity: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        items: [
          {
            title: this.$t('Type'),
            value: capitalize(this.entity.worksite_type),
          },
          {
            title: this.$t('Contact'),
            value: this.entity.contact_name,
          },
        ],
        footer: {
          phone: true,
          address: true,
        },
      }
    },
    computed: {
      getLocationLink() {
        return locationLink(this.entity)
      },
    },
  }
</script>
