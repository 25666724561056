<template>
  <SpecialSourceLink
      :id="specialSourceId"
      :data="row"
      :target="params?.target"
      :is-link="!editableCell"
  />
</template>
<script>
  import SpecialSourceLink from "@/components/links/SpecialSourceLink";

  export default {
    components: {
      SpecialSourceLink,
    },
    props: {
      column: {
        type: Object,
        default: () => ({}),
      },
      row: {
        type: Object,
        default: () => ({}),
      },
      params: {
        type: Object,
        default: () => ({}),
      },
      editableCell: Boolean,
    },
    computed: {
      specialSourceId() {
        return this.get(this.row, 'attributes.special_source_id', false) || this.row.special_source_id
      }
    }
  }
</script>
