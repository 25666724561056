<template>
  <div>
    <layout-tabs :items="filteredItems"/>
    <router-view/>
  </div>
</template>
<script>
  import { adjustmentTypes } from '@/modules/payroll/pages/adjustment/util'

  export default {
    computed: {
      items() {
        return [
          {
            name: this.$t('All'),
            path: `/payroll/adjustments/${adjustmentTypes.ALL}`,
            permission: 'payroll_settings',
          },
          {
            name: this.$t('Earnings'),
            path: `/payroll/adjustments/${adjustmentTypes.EARNINGS}`,
            permission: 'payroll_settings',
          },
          {
            name: this.$t('Benefits'),
            path: `/payroll/adjustments/${adjustmentTypes.BENEFITS}`,
            permission: 'payroll_settings',
          },
          {
            name: this.$t('Taxes'),
            path: `/payroll/adjustments/${adjustmentTypes.TAXES}`,
            permission: 'payroll_settings',
          },
          {
            name: this.$t('Deductions'),
            path: `/payroll/adjustments/${adjustmentTypes.DEDUCTIONS}`,
            permission: 'payroll_settings',
          },
          {
            name: this.$t('W-2 Memos'),
            path: `/payroll/adjustments/${adjustmentTypes.W2MEMOS}`,
            permission: 'payroll_settings',
          },
        ]
      },
      filteredItems() {
        const permissions = this.$store.getters['auth/permissions']
        return this.items.filter(item => permissions.includes(item.permission))
      },
    },
  }
</script>
