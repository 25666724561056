<template>
  <BaseSelect
    v-bind="$attrs"
    v-on="$listeners"
    :label="$attrs.label || $t('Address Type')"
    :name="$attrs.name || $t('Address Type')"
    :options="typeOptions"
    :placeholder="$attrs.placeholder || $t('Select Address Type')"
    :rules="rules"
  />
</template>
<script>
import { addressTypes } from '@/enum/enums'

export default {
  props: {
    rules: {
      type: String,
      default: 'required',
    },
  },
  data() {
    return {
      typeOptions: [
        {
          label: this.$t('Job'),
          value: addressTypes.Job,
        },
        {
          label: this.$t('Warehouse'),
          value: addressTypes.Warehouse,
        },
        {
          label: this.$t('Employee'),
          value: addressTypes.Home,
        },
      ]
    }
  },
}
</script>
