<template>
  <div>
    <base-form v-bind="$attrs"
               :title="$t('Change Password')"
               :save-text="$t('Change Password')"
               :update-text="$t('Change Password')"
               :loading="loading"
               @cancel="$emit('cancel')"
               @submit="changePassword"
    >

      <div class="col-span-6">
        <base-input v-model="model.current_password"
                    type="password"
                    :label="$t('Current Password')"
                    :placeholder="$t('Current Password')"
                    id="current_password"
                    rules="required">
        </base-input>
      </div>
      <div class="col-span-6">
        <base-input v-model="model.new_password"
                    type="password"
                    :label="$t('New Password')"
                    :placeholder="$t('New Password')"
                    id="new_password"
                    rules="required|min:8">
        </base-input>
      </div>
      <div class="col-span-6">
        <base-input v-model="model.new_password_confirmation"
                    type="password"
                    :label="$t('New Password Confirmation')"
                    :placeholder="$t('New Password Confirmation')"
                    id="new_password_confirmation"
                    :rules="`required|password:@${$t('New Password')}`">
        </base-input>
      </div>
    </base-form>
  </div>
</template>
<script>
  import UserService from "@/modules/auth/services/UserService";

  export default {
    data() {
      return {
        loading: false,
        model: {
          current_password: '',
          new_password: '',
          new_password_confirmation: '',
        },
      }
    },
    methods: {
      async changePassword() {
        try {
          this.loading = true
          await UserService.changePassword(this.model);
          await this.$store.dispatch('auth/refreshProfile')
          this.$success(this.$t('User password successfully changed'))
          this.$emit('save')
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not change the user password'))
        } finally {
          this.loading = false
        }
      }
    }
  }
</script>
<style>
</style>
