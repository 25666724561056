<template>
  <base-form :can-create-another-entity="!model.id"
             :loading="loading"
             :show-cancel="showCancel"
             :save-text="$t('Create budget')"
             :update-text="$t('Update budget')"
             layout="modal"
             @cancel="$emit('cancel')"
             @submit="onSubmit"
  >
    <div class="col-span-6 md:col-span-2">
      <account-select v-model="model.account_id"
                      :disabled="!!model.id"
                      :name="$t('Account Number')"
                      :label="$t('Account Number')"
                      label-key="number"
                      id="account"
                      rules="required"
      />
    </div>
    <div class="col-span-6 md:col-span-1">
      <sub-account-select v-model="model.subaccount_id"
                          :disabled="!!model.id"
                          :label="$t('Sub Account')"
                          label-key="number"
                          id="subaccount"
      />
    </div>
    <div class="col-span-6 md:col-span-2">
      <base-input v-model="model.amount"
                  @input="onChangeFiscalYearBudget"
                  :label="$t('Fiscal Year Budget')"
                  :placeholder="$t('e.g 100.00')"
                  id="amount"
                  type="number"
                  format="price"
                  rules="max_value:9999999999.99"
      />
    </div>
    <div class="col-span-6">
      <h5 class="form-section-title">{{ $t('Monthly Budgets') }}</h5>
    </div>
    <div class="col-span-6 mb-4">
      <base-checkbox v-model="model.spread_uniformly"
                     :label="$t('Spread Uniformly to each Period')"
                     id="spread_uniformly"
      />
    </div>
    <div class="col-span-6 md:col-span-3 xl:col-span-2"
         v-for="index in 12"
         :key="index">
      <base-input v-model="model[`p${index}_amount`]"
                  :key="index"
                  :label="getPeriodName(index)"
                  :placeholder="$t('e.g 10.00')"
                  :step="0.01"
                  :max="9999999999.99"
                  format="price"
                  type="number"
                  id="period_amount"
                  rules="max_value:9999999999.99"
                  @input="onChangePeriodBudget($event, index)"
      />
    </div>
  </base-form>
</template>
<script>
  import axios from 'axios'

  export default {
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        showCancel: true,
        loading: false,
        model: {
          account_id: undefined,
          subaccount_id: undefined,
          amount: 0,
          spread_uniformly: true,
          period_budget: 0,
          fiscal_year: this.activeFiscalYear,
          p1_amount: 0,
          p2_amount: 0,
          p3_amount: 0,
          p4_amount: 0,
          p5_amount: 0,
          p6_amount: 0,
          p7_amount: 0,
          p8_amount: 0,
          p9_amount: 0,
          p10_amount: 0,
          p11_amount: 0,
          p12_amount: 0,
        },
      }
    },
    watch: {
      'data.attributes': {
        immediate: true,
        handler(value) {
          if (!value) {
            return
          }

          this.model = {
            ...this.model,
            ...value,
          }

          if (this.model.p0_amount !== undefined) {
            delete this.model.p0_amount
          }

          if (this.model.p13_amount !== undefined) {
            delete this.model.p13_amount
          }
        },
      },
    },
    methods: {
      onChangeFiscalYearBudget(value, periods = 12) {
        const budgetPerPeriod = (value / periods).toFixed(2)
        this.spreadBudgets(budgetPerPeriod)
      },
      onChangePeriodBudget(value, index) {
        const fiscalYearBudget = Number(this.model.amount) - Number(value)
        this.onChangeFiscalYearBudget(fiscalYearBudget, 11)
        this.$nextTick(() => {
          this.model[`p${index}_amount`] = value
        })
      },
      spreadBudgets(amount) {
        for (let i = 1; i <= 12; i++) {
          this.model[`p${i}_amount`] = amount
        }
      },
      async onSubmit() {
        try {
          this.loading = true

          if (this.model.id) {
            await axios.put(`/restify/budgets/${this.model.id}`, this.model)
            this.$success(this.$t('Budget updated'))
          } else {
            this.model.fiscal_year = this.activeFiscalYear
            await axios.post('/restify/budgets', this.model)
            this.$success(this.$t('Budget created'))
          }

          this.$createAnotherEntity ? this.$emit('create-another') : this.$emit('save', this.model)
        } catch (err) {
          if (err.handled) {
            return
          }
          console.log('err', err)
          this.$error(this.$t('Could not update the budget'))
        } finally {
          this.loading = false
        }
      },
      getPeriodName(period) {
        return this.$store.getters['company/getPeriodName'](period)
      },
    },
  }
</script>
