import store from "@/store";
import { globalResources } from "@/components/form/util";

export function getAvailableCashOnDeposit(params: any, allPayments: any[] = []) {
  const { data } = params
  const customer_id = data?.customer_id
  let customer = data?.customer

  if (!customer && customer_id) {
    customer = store.getters['globalLists/getResourceById'](globalResources.Customers, customer_id)
  }

  if (!customer?.id) {
    return 0
  }

  const prevCustomerPayments = allPayments.filter((payment: any) => {
    return payment.customer_id === customer_id && payment.idx < data.idx
  })

  const prevAppliedDeposit = prevCustomerPayments.reduce((acc: number, payment: any) => {
    const appliedDeposit = (payment.billings || []).reduce((acc2: number, billing: any) => {
      return acc2 + Number(billing.from_cash_on_deposit_amount || 0)
    }, 0)

    return acc + appliedDeposit
  }, 0)

  const customerCashOnDeposit = customer?.current_unapplied_cash_on_deposit_amount || 0
  return data.amount - data.apply_to_billings_amount + customerCashOnDeposit - prevAppliedDeposit
}
