<template>
  <base-report-form
      :filters="model"
      :report-number="reportNumber"
  >

    <template #filters>

      <base-filter-row
          :title="$t('Type')"
      >
        <base-select
            v-model="model.type"
            :add-entity="false"
            :options="earningTypeOptions"
            class="col-span-2"
        />

      </base-filter-row>

      <base-filter-row
          :title="$t('Quarter')"
      >
        <base-select
            v-model="model.quarter"
            :add-entity="false"
            :options="quarterOptions"
            class="col-span-1"
        />

        <div class="flex col-span-2 items-baseline">
          <span class="text-label">{{ $t('State') }}</span>
          <state-select
              v-model="model.state"
              :add-entity="false"
              :name="$t('State')"
              class="flex-1"
              rules="required"
          />
        </div>

        <div v-if="model.type === earningTypes.LocalEarnings"
             class="flex col-span-2 items-baseline">
          <span class="text-label">{{ $t('Local') }}</span>
          <base-input
              v-model="model.local"
              class="flex-1"
          />
        </div>

      </base-filter-row>

      <department-range-filter
          v-if="model.type === earningTypes.StateEarnings"
          v-model="model"
      />

    </template>

    <template #table="{loading, data}">

      <quarterly-earnings-state-local-report-table
          :data="data"
          :filters="model"
          :report-number="reportNumber"
          :data-loading="loading"
          ref="reportTable"
      />

    </template>

  </base-report-form>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { quarterOptions } from '@/enum/enums'
  import { DepartmentRangeFilter } from '@/components/range-filters'
  import { earningTypes } from '@/modules/payroll/components/reports/util'
  import QuarterlyEarningsStateLocalReportTable
    from '@/modules/payroll/components/reports/QuarterlyEarningsStateLocalReportTable.vue'

  export default defineComponent({
    components: {
      DepartmentRangeFilter,
      QuarterlyEarningsStateLocalReportTable,
    },
    data() {
      return {
        earningTypes,
        quarterOptions,
        earningTypeOptions: [
          {
            label: this.$t('State Earnings'),
            value: earningTypes.StateEarnings,
          },
          {
            label: this.$t('Local Earnings'),
            value: earningTypes.LocalEarnings,
          },
        ],
        model: {
          calendar_year: null,
          type: earningTypes.StateEarnings,
          quarter: 1,
          state: null,
          local: null,
          department_code_from: null,
          department_code_to: null,
        },
      }
    },
    computed: {
      reportNumber() {
        return this.model.type === earningTypes.StateEarnings ? 4882 : '4882a'
      },
    },
  })
</script>
