import axios from 'axios'

const types = {
  SET_CURRENT_BANK: 'SET_CURRENT_BANK',
  SET_CURRENT_BANK_LOADING: 'SET_CURRENT_BANK_LOADING',
  SET_ORGANIZATION_SETTINGS: 'SET_ORGANIZATION_SETTINGS',
};

function parseSettings(settings) {
  return settings.map(config => {
    let attributes = {
      ...config.attributes,
      id: config.id
    }
    if (['currency_format', 'translations', 'filter_options'].includes(attributes.key)) {
      if (attributes.value === '[]' && attributes.key === 'filter_options') {
        attributes.value = '{}'
      }
      attributes.value = JSON.parse(attributes.value)
    }

    return attributes
  })
}

const state = {
  organizationSettings: [],
  currentBank: {
    attributes: {},
    relationships: {},
    meta: {}
  },
  currentBankLoading: false,
};

const mutations = {
  [types.SET_ORGANIZATION_SETTINGS]: (state, value) => {
    value = parseSettings(value)
    state.organizationSettings = value
  },
  [types.SET_CURRENT_BANK_LOADING]: (state, value) => {
    state.currentBankLoading = value
  },
  [types.SET_CURRENT_BANK]: (state, bank) => {
    state.currentBank = {
      ...state.currentBank,
      ...bank,
    }
  },
};

const actions = {
  async getOrganizationSettings({ commit }) {
    const { data } = await axios.get('/restify/organization-settings')
    commit(types.SET_ORGANIZATION_SETTINGS, data)
  },
  async getBank({ commit }, bankId) {
    try {
      commit(types.SET_CURRENT_BANK_LOADING, true)
      const { data } = await axios.get(`/restify/banks/${bankId}?related=addresses,directDeposit`)
      commit(types.SET_CURRENT_BANK, data)
    } finally {
      commit(types.SET_CURRENT_BANK_LOADING, false)
    }
  },
  async patchBank({ state }, data = {}) {
    await axios.patch(`/restify/banks/${data.id}`, data)
  }
};
const getters = {
  getByKey: state => key => {
    const setting = state.organizationSettings.find(s => s.key === key)
    return setting?.value || ''
  },
  getFilterSetting: (_, getters) => key => {
    const filterOptions = getters['getByKey']('filter_options') || {}
    return filterOptions[key]
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
