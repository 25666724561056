<template>
    <base-form :loading="loading"
               :showCancel="showCancel"
               :showBack="showBack"
               :save-text="$t('Create bid')"
               :update-text="$t('Update bid')"
               layout="modal"
               @cancel="$emit('cancel')"
               @submit="onSubmit"
    >
        <div class="col-span-6 md:col-span-2">
            <base-input v-model="model.number"
                        :label="$t('Number')"
                        :placeholder="$t('Bid Number')"
                        id="number"
                        rules="required|length:4|numeric"
            />
        </div>
        <div class="col-span-6 md:col-span-4">
            <base-input v-model="model.description"
                        :label="$t('Description')"
                        :placeholder="$t('Description')"
                        id="description"
                        rules="max:150"
            />
        </div>
        <div class="col-span-6 md:col-span-3">
            <base-input v-model="model.labor_area"
                        :label="$t('Labor Area')"
                        :placeholder="$t('Labor Area')"
                        id="labor_area"
                        type="number"
                        :min="1"
                        :step="1"
                        rules="min_value:1|max_value:10"
            />
        </div>
        <div class="col-span-6 md:col-span-3">
            <base-date-picker v-model="model.date"
                              :label="$t('Date')"
                              :placeholder="$t('Date')"
                              id="date"
            />
        </div>
        <div class="col-span-6 md:col-span-2">
            <base-input v-model="model.estimator"
                        :label="$t('Estimator')"
                        :placeholder="$t('Person assigned to manage this Bid')"
                        id="estimator"
                        rules="max:15"
            />
        </div>
        <div class="col-span-6 md:col-span-2">
            <entity-select v-model="model.crew_labor_rate_id"
                           url="/restify/crew-labor-rates"
                           :label="$t('Labor Crew Code')"
                           label-key="code"
                           :add-entity="true"
                           value-key="id"
                           rules="required"
            />
        </div>
        <div class="col-span-6 md:col-span-2">
          <base-switch v-model="model.compute_bond"
                       label-info="Compute Bond"/>
        </div>
        <div class="col-span-6 mb-4">
            <el-collapse v-model="activeGroups">
                <el-collapse-item key="notes"
                                  :title="$t('Notes')"
                                  name="notes">
                    <div class="grid grid-cols-6 gap-x-3 border-t pt-5 px-1">
                        <div class="col-span-6 md:col-span-3">
                            <base-input v-model="model.notes[0]"
                                        id="note_1"
                                        :placeholder="$t('Note 1')"
                            />
                        </div>
                        <div class="col-span-6 md:col-span-3">
                            <base-input v-model="model.notes[1]"
                                        id="note_2"
                                        :placeholder="$t('Note 2')"
                            />
                        </div>
                        <div class="col-span-6 md:col-span-3">
                            <base-input v-model="model.notes[2]"
                                        id="note_3"
                                        :placeholder="$t('Note 3')"
                            />
                        </div>
                        <div class="col-span-6 md:col-span-3">
                            <base-input v-model="model.notes[3]"
                                        id="note_4"
                                        :placeholder="$t('Note 4')"
                            />
                        </div>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>
    </base-form>
</template>
<script>
  import axios from 'axios'

  export default {
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      showBack: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        loading: false,
        showCancel: true,
        activeGroups: [],
        model: {
          number: '',
          description: '',
          labor_area: 1,
          estimator: '',
          date: '',
          notes: [],
          crew_labor_rate_id: undefined,
          compute_bond: true,
          profit_percent: 0,
        },
      }
    },
    watch: {
      'data.attributes': {
        immediate: true,
        handler(value) {
          this.model = {
            ...this.model,
            ...value,
          }
          if (!value.id) {
            this.activeGroups = ['notes']
          }
        },
      },
    },
    methods: {
      onFocusNumberInput(field) {
        if (this.model[field] === 0) {
          this.model[field] = ''
        }
      },
      onBlurNumberInput(field) {
        if (this.model[field] === '') {
          this.model[field] = 0
        }
      },
      async onSubmit() {
        try {
          this.loading = true
          if (this.model.id) {
            await axios.put(`/restify/bids/${this.model.id}`, this.model)
            this.$success(this.$t('Bid updated'))
          } else {
            await axios.post('/restify/bids', this.model)
            this.$success(this.$t('Bid added'))
          }
          this.$emit('save', this.model)
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not update bid'))
        } finally {
          this.loading = false
        }
      },
      mounted() {
        const numberInput = document.querySelector('#number')
        if (numberInput) {
          numberInput.focus()
        }
      },
    },
  }
</script>
<style lang="scss">
    .el-collapse-item .el-collapse-item__header {
        @apply text-base;
    }
</style>

