<template>
  <base-button
      :variant="variant"
      class="print:hidden void-button"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <span class="flex items-center">
      <DeleteIcon class="w-5 h-5 mr-2"/>
      <span>
        <slot></slot>
      </span>
    </span>
  </base-button>
</template>
<script>
  import { DeleteIcon } from 'vue-feather-icons'

  export default {
    name: 'VoidButton',
    components: {
      DeleteIcon,
    },
    props: {
      variant: {
        type: String,
        default: 'danger-link',
      },
    }
  }
</script>
<style>
  td .void-button .btn {
    @apply px-0;
  }
</style>
