<template>
  <div>
    <AgDataTable
        ref="table"
        url="/restify/document-types"
        actions="search,add,edit,delete,refresh"
        :open-entity-in-new-tab="false"
        :columns="columns"
        :add-text="$t('New Document Type')"
        domLayout="autoHeight"
        @add="showAddDialog = true"
        @edit="onEdit"
    >
      <template #attributes.used_in="{row}">
        <div>
          <div
              v-for="option in getUsedOptions(row)"
              :key="option"
          >
            {{ option }}
          </div>
        </div>
      </template>
    </AgDataTable>
    <DocumentTypeDialog
        v-if="showAddDialog"
        :open.sync="showAddDialog"
        @close="showAddDialog = false"
        @save="onSave"
    />

    <DocumentTypeDialog
        v-if="showEditDialog"
        :open.sync="showEditDialog"
        :data="rowToEdit"
        @close="showEditDialog = false"
        @save="onSave"
    />
  </div>
</template>
<script lang="ts" setup>
import {computed, ref} from "vue";
import {Column} from "@/components/ag-grid/tableTypes";
import i18n from "@/i18n";
import DocumentTypeDialog from "@/modules/settings/pages/company/DocumentTypeDialog.vue";
import {UsedInOptions} from "@/enum/enums";
import Data = API.Data;

const columns = computed<Column[]>(() => {
  return [
    {
      headerName: i18n.t('Name'),
      field: 'attributes.description',
    },
    {
      headerName: i18n.t('Used In'),
      field: 'attributes.used_in',
      autoHeight: true,
    },
    {
      headerName: i18n.t('Created At'),
      field: 'attributes.updated_at',
      component: 'FormattedDate',
      maxWidth: 200,
    }
  ]
})

function getUsedOptions(row: Data<any>) {
  return row.attributes.used_in?.map((x: string) => {
    return UsedInOptions.find((option: any) => option.value === x)?.label || x
  })
}


const showAddDialog = ref(false)
const showEditDialog = ref(false)
const rowToEdit = ref(null)

function onEdit(row: any) {
  rowToEdit.value = row
  showEditDialog.value = true
}

const table = ref()

function onSave() {
  showAddDialog.value = false
  showEditDialog.value = false
  table.value?.refresh()
}
</script>
