<template>
  <base-filter-range :class="gridClass">
    <template #start>
      <sub-account-select
          v-model="value.subaccount_number_from"
          :placeholder="$t('Sub-Account From')"
          :add-entity="false"
          value-key="number"
          class="flex-1"
          id="subaccount_number_from"
          clearable
          @blur="onBlurStartRange"
      />
    </template>
    <template #end>
      <sub-account-select
          v-model="value.subaccount_number_to"
          :add-entity="false"
          :placeholder="$t('Sub-Account To')"
          value-key="number"
          class="flex-1"
          id="subaccount_number_to"
          clearable
      />
    </template>
  </base-filter-range>
</template>
<script>
  export default {
    props: {
      value: {
        type: Object,
        default: () => ({
          subaccount_number_from: '',
          subaccount_number_to: '',
        }),
      },
      gridClass: {
        type: String,
        default: 'col-span-3',
      }
    },
    methods: {
      onBlurStartRange() {
        if (!this.value.subaccount_number_from) {
          return
        }

        this.value.subaccount_number_to = this.value.subaccount_number_to || this.value.subaccount_number_from
      },
    },
  }
</script>
