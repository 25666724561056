import store from '@/store'
import { globalResources, RestifyResources } from '@/components/form/util'
import { $modules, actionPolicies, addressTypes, resourceStatuses } from '@/enum/enums'

export function getSetting(moduleName = '', key = '') {
  return store.getters['company/getSettingValue'](moduleName, key)
}
function getControlAccounts() {
  const accountsPayable = getSetting($modules.AP, 'account')
  const accountsReceivable = getSetting($modules.AR, 'account')
  const retentionReceivable = getSetting($modules.JC, 'retention_receivable_account')
  const retentionPayable = getSetting($modules.JC, 'retention_payable_account')
  return [accountsPayable, accountsReceivable, retentionReceivable, retentionPayable]
    .filter(account => account)
}

export function isControlAccount(account) {
  const accounts = getControlAccounts()
  return accounts.includes(account?.toString())
}

export default {
  install(Vue) {
    Vue.prototype.$pagination = {
      default: 50,
      list: 50,
    }
    Vue.prototype.$settings = getSetting
    Vue.prototype.$globalResources = globalResources
    Vue.prototype.$resourceStatuses = resourceStatuses
    Vue.prototype.$actionPolicies = actionPolicies
    Vue.prototype.$addressTypes = addressTypes

    Vue.prototype.$modules = $modules
    Vue.prototype.$RestifyResources = RestifyResources
  }
}
