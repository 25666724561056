<template>
    <base-form :loading="loading"
               :showCancel="showCancel"
               :showBack="showBack"
               :save-text="$t('Create formula')"
               :update-text="$t('Update formula')"
               layout="modal"
               @cancel="$emit('cancel')"
               @submit="onSubmit"
    >
        <div class="col-span-6 md:col-span-2">
            <base-input v-model="model.code"
                        :label="$t('Code')"
                        :placeholder="$t('Formula Code')"
                        id="code"
                        rules="required|code"
            />
        </div>
        <div class="col-span-6 md:col-span-4">
            <base-input v-model="model.description"
                        :label="$t('Description')"
                        :placeholder="$t('Description')"
                        id="description"
                        rules="max:150"
            />
        </div>
        <div class="col-span-6">
            <base-input v-model="model.formula"
                        :label="$t('Formula Notation')"
                        :placeholder="$t('Formula Notation')"
                        id="formula"
                        rules="required|max:80"
            />
        </div>
        <div class="col-span-6 mt-4 mb-6 pb-2 border-b">
            <h4 class="font-medium">
                {{$t('Normal Unit Measure For Dimensions')}}
            </h4>
        </div>
        <div class="col-span-6 md:col-span-2">
            <base-input v-model="model.um_x"
                        :label="$t('Dimension X')"
                        :placeholder="$t('Dimension X')"
                        id="um_x"
                        rules="max:4"
            />
        </div>
        <div class="col-span-6 md:col-span-2">
            <base-input v-model="model.um_y"
                        :label="$t('Dimension Y')"
                        :placeholder="$t('Dimension Y')"
                        id="um_y"
                        rules="max:4"
            />
        </div>
        <div class="col-span-6 md:col-span-2">
            <base-input v-model="model.um_z"
                        :label="$t('Dimension Z')"
                        :placeholder="$t('Dimension Z')"
                        id="um_z"
                        rules="max:4"
            />
        </div>
        <div class="col-span-6 bg-white overflow-hidden shadow rounded-lg px-3 leading-6 font-medium">
            <el-collapse v-model="activeGroups">
                <el-collapse-item key="example"
                                  :title="$t('Example / Notes')"
                                  name="example">
                    {{$t('Enter formula using standard algebraic notation:')}} <br>
                    <strong>+</strong> {{$t('addition')}} <strong>-</strong> {{$t('subtraction')}} <strong>*</strong>
                    {{$t('multiplication')}}
                    <strong>/</strong> {{$t('division')}} <strong>^</strong> {{$t('exponentiation')}}<br>
                    <strong>PI</strong> {{$t('value of')}} <strong>pi ()</strong>{{$t('enclose operation A, B, C for parameter values A, B, or X, Y, Z for dimension values X, Y, or Z')}}
                    <p class="mt-3"><strong>{{$t('Examples')}}:</strong> {{$t('Circle Area')}} = <strong>PI*X^2</strong>,
                        {{$t('dim')}} X = ({{$t('circle radius')}})
                        {{$t('Vol(ft to cy)')}} = <strong>(X * Y * Z) / 27</strong></p>

                </el-collapse-item>
            </el-collapse>
        </div>
    </base-form>
</template>
<script>
  import axios from 'axios'

  export default {
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      showBack: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        loading: false,
        showCancel: true,
        activeGroups: ['example'],
        model: {
          code: '',
          formula: '',
          description: '',
          um_x: '',
          um_y: '',
          um_z: '',
        },
      }
    },
    watch: {
      data: {
        immediate: true,
        handler(value) {
          this.model = {
            ...this.model,
            ...value,
          }
        },
      },
    },
    methods: {
      async onSubmit() {
        try {
          this.loading = true
          if (this.model.id) {
            await axios.put(`/restify/estimate-formulas/${this.model.id}`, this.model)
            this.$success(this.$t('Estimate Formula updated'))
          } else {
            await axios.post('/restify/estimate-formulas', this.model)
            this.$success(this.$t('Estimate Formula added'))
          }
          this.$emit('save', this.model)
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not update the estimate formula'))
        } finally {
          this.loading = false
        }
      },
      mounted() {
        const codeInput = document.querySelector('#code')
        if (codeInput) {
          codeInput.focus()
        }
      },
    },
  }
</script>
<style lang="scss">
    .el-collapse {
        @apply border-none;
    }

    .el-collapse-item .el-collapse-item__header {
        @apply text-base;
    }
</style>

