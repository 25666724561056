<template>
  <BaseDataTable v-loading="loading"
                 :columns="columns"
                 :url-params="urlParams"
                 :is-expandable="true"
                 :add-entity-in-new-tab="true"
                 :open-entity-in-new-tab="false"
                 :data-map-function="mapRates"
                 :add-text="addText"
                 ref="table"
                 url="/restify/customer-billing-rates"
                 actions="search, refresh"
                 permission="customers"
                 hide-actions="view, delete"
                 base-entity-path="/accounts-receivable/settings/billing-rates"
                 add-entity-url-query="/accounts-receivable/settings/billing-rates/add"
                 @data-fetch="billingRates = $event"
                 @edit="onRowEdit"
  >
    <template #expand-content="{row}">
      <BillingRatesOverrides
          :data="row.originalRow?.rates"
          :billing-types="billingTypes"
          :source-type="sourceType"
          :read-only="true"
          domLayout="autoHeight"
          class="p-4"
      />
    </template>
    <template #customer="{row}">
      <template v-if="row.attributes.customer_id">
        <entity-preview :entity="get(row, 'relationships.customer.attributes')"
                        :key="`preview-${row.id}`"
                        :url="`/accounts-receivable/customers/${row.attributes.customer_id}/edit`"
                        :valueToDisplay="get(row, 'relationships.customer.attributes.code')"
                        :parent-entity-id="row.id"
                        entity-name="customer"
        />
      </template>
      <template v-else>

      </template>
    </template>
  </BaseDataTable>
</template>
<script>
  import groupBy from 'lodash/groupBy'
  import lowerCase from 'lodash/lowerCase'
  import { BILL_TO_STORAGE_KEY, mapBillingRates } from '@/modules/accounts-receivable/pages/billing-rates/billingRates'
  import BillingRatesOverrides from '@/modules/accounts-receivable/components/customer-billing-rates/BillingRatesOverrides.vue'

  export default {
    components: {
      BillingRatesOverrides,
    },
    props: {
      sourceType: {
        type: String,
        default: 'material',
      },
      ratesColumns: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        loading: false,
        billingRates: [],
        columns: [
          {
            label: this.$t('Customer'),
            prop: 'customer',
          },
          {
            label: this.$t('Apply To'),
            prop: 'attributes.apply_to',
            component: 'FormattedText',
          },
        ],
      }
    },
    computed: {
      billingTypes() {
        return this.$store.state.globalLists.billingRateTypes
      },
      addText() {
        const type = lowerCase(this.sourceType)
        return this.$t(`New ${type} rate`)
      },
      urlParams() {
        return {
          source_type: this.sourceType,
          related: 'customer,billingRateType,source,source.billingRates'
        }
      },
    },
    methods: {
      onRowEdit(row) {
        const { customer_id, apply_to, source_type } = row.attributes
        this.$router.push(`/accounts-receivable/settings/billing-rates/${customer_id}/${apply_to}/${source_type}/edit`)
      },
      mapRates(billings) {
        const applyToGroups = groupBy(billings, 'attributes.apply_to')
        const result = []

        for (let applyTo in applyToGroups) {
          const customerGroups = groupBy(applyToGroups[applyTo], 'attributes.customer_id')
          for (let customerID in customerGroups) {
            const billings = customerGroups[customerID]
            let row = billings[0]
            const rates = mapBillingRates(billings)
            result.push({
              ...row,
              rates,
            })
          }
        }

        return result
      },
      storeCurrentBillingType() {
        const { path } = this.$route
        let billTo = path.split('/').pop()
        localStorage.setItem(BILL_TO_STORAGE_KEY, billTo)
      },
    },
    mounted() {
      this.storeCurrentBillingType()
    },
  }
</script>
