<template>
  <div class="ag-cell-label-container">
    <div class="ag-header-cell-label space-x-2">
      <span class="ag-header-cell-text">
        {{ params.displayName }}
      </span>
      <base-tooltip :content="params.headerTip">
        <HelpCircleIcon class="w-h h-4 text-gray-500 hover:text-primary-500 cursor-help"/>
      </base-tooltip>
    </div>
  </div>
</template>
<script>
  import { HelpCircleIcon } from 'vue-feather-icons'
  export default {
    components: {
      HelpCircleIcon,
    },
  }
</script>
