<template>
  <base-form
      :layout="layout"
      :loading="loading"
      :showBack="showBack"
      :showCancel="showCancel"
      :show-reset="!model.id && redirectToListAfterStore"
      :can-create-another-entity="!model.id && redirectToListAfterStore"
      :save-text="$t('Create income line item')"
      :update-text="$t('Update income line item')"
      :focus-on-first-input="false"
      grid-classes="grid grid-cols-8 gap-x-3"
      @cancel="onCancel"
      @submit="onSubmit"
  >
    <div class="col-span-8 md:col-span-4 lg:col-span-2">
      <JobSelectNew
        v-model="model.job_id"
        :initial-value="getJobInitialValue"
        :disabled="!!$route.params.id"
        id="job_id"
        rules="required"
        @change="onJobChange"
      />
    </div>
    <phase-cost-input
        :phase_code.sync="model.phase_code"
        :cost_code.sync="model.cost_code"
        :change_order.sync="model.change_order"
        :description.sync="model.description"
        :line-item-type="costTypes.Income"
        :job-id="model.job_id"
        :id="model.id"
        class="col-span-8 md:col-span-4 lg:col-span-3"
    />

    <div class="col-span-8 md:col-span-4 lg:col-span-1">
      <base-switch v-model="model.exempt_from_sales_tax"
                   label-info="Exempt From Sales Tax"/>
    </div>

    <div class="col-span-8 md:col-span-4 lg:col-span-1">
      <base-switch v-model="model.has_additional_description"
                   label-info="Additional Description"/>
    </div>

    <div class="col-span-8 lg:col-span-4">
      <base-textarea
          v-model="model.description"
          :label="$t('Description')"
          :placeholder="$t('Description')"
          id="description"
          rules="max:150"
          class="col-span-2"
      />
    </div>

    <div class="col-span-8 md:col-span-4 lg:col-span-4">
      <div class="grid grid-cols-2 gap-x-2">

      </div>
      <div class="col-span-8 md:col-span-4 lg:col-span-3"
           v-if="model.has_additional_description">
        <base-input v-model="model.additional_description[0]"
                    :label="$t('Additional Description')"
                    :placeholder="$t('Additional Description')"
                    id="additional_description"
                    rules="max:300">
        </base-input>
      </div>
    </div>
    <div class="col-span-8 mt-4 -mx-2">
      <el-collapse v-model="activeGroups">
        <base-collapse-section key="budgets"
                               :title="$t('Budgets')"
                               :name="$t('budgets')">
          <LineItemBudgets
              :type="model.type"
              :budgets="budgets"
              :budgetsLoading="budgetsLoading"
          />
        </base-collapse-section>

      </el-collapse>
    </div>
  </base-form>
</template>
<script>
  import axios from 'axios'
  import { costTypes } from '@/enum/enums'
  import LineItemForm from '@/modules/job-costing/components/line-items/LineItemForm'
  import LineItemBudgets from "@/modules/job-costing/components/line-items/LineItemBudgets";
  import { RestifyResources } from "@/components/form/util";
  import JobSelectNew from "@/components/select/entities/JobSelectNew.vue";

  export default {
    extends: LineItemForm,
    components: {
      JobSelectNew,
      LineItemBudgets
    },
    data() {
      return {
        costTypes,
        loading: false,
        showCancel: true,
        activeGroups: this.data.id ? ['budgets'] : ['payroll', 'budgets'],
        budgets: [],
        model: {
          type: costTypes.Income,
          job_id: this.job.id || this.$route.query.jobId,
          phase_code: '',
          cost_code: '',
          change_order: 0,
          account: undefined,
          subaccount: undefined,
          has_additional_description: false,
          additional_description: [],
          exempt_from_sales_tax: false,
          material_id: undefined,
          equipment_id: undefined,
        },
      }
    },
    methods: {
      async onSubmit() {
        try {
          this.loading = true

          if (this.model.id) {
            await axios.put(`/restify/line-items/${this.model.id}`, this.model)
            await this.assignBudgets(this.model.id)
            this.$success(this.$t('Job income line item updated'))
            await this.$store.dispatch('jobCosting/getLineItem', this.model.id)
            await this.$addSystemGeneratedNote({
              resourceName: RestifyResources.LineItems,
              resourceId: this.model.id,
              isEdit: true,
            })
          } else {
            const { data } = await axios.post('/restify/line-items', this.model)
            this.$success(this.$t('Job income line item created'))
            await this.assignBudgets(data.id)
            await this.$addSystemGeneratedNote({
              resourceName: RestifyResources.LineItems,
              resourceId: data.id,
            })
            if (this.$createAnotherEntity && this.redirectToListAfterStore) {
              return this.$emit('create-another')
            }

            let path = `/job-costing/income-line-items`
            const routeId = this.$route.params.id
            if (routeId) {
              path = `/job-costing/jobs/${routeId}/line-items`
            }

            if (this.redirectToListAfterStore) {
              await this.$router.push(path)
            }

            this.$emit('save', data)
          }
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not update the job income line items'))
        } finally {
          this.loading = false
        }
      },
    },
  }
</script>
