import { resourceStatuses } from "@/enum/enums";
import i18n from "@/i18n";

export const equipmentStatusOptions = [
  {
    label: i18n.t('Active'),
    value: resourceStatuses.Active,
  },
  {
    label: i18n.t('Inactive'),
    value: resourceStatuses.Inactive,
  },
]
