<template>
  <base-button variant="gray-light"
               size="icon"
               :title="$t('Save')"
               v-bind="$attrs"
               v-on="$listeners"
  >
    <CheckIcon class="w-4 h-4"/>
  </base-button>
</template>
<script>
  import { CheckIcon } from 'vue-feather-icons'

  export default {
    name: 'TableSaveButton',
    inheritAttrs: false,
    components: {
      CheckIcon,
    },
  }
</script>
