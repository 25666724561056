<template>
  <BaseTooltip
      :disabled="!hasNewNotes"
      :content="$tc('You have new notes', newNotesCount)"
  >
    <base-button variant="gray-icon"
                 size="icon"
                 class="print:hidden relative"
                 v-bind="$attrs"
                 @click.prevent.stop="onToggleNotePanel"
                 v-on="$listeners"
    >
      <div
          v-if="hasNewNotes && showNoteIndicator"
          class="w-3 h-3 flex items-center justify-center text-[8px] bg-red-500 rounded-full text-white absolute right-[-5px] top-[-5px]">
        {{ newNotesCount }}
      </div>
      <IconNote class="w-4 h-4"/>
    </base-button>
  </BaseTooltip>
</template>
<script>
export default {
  props: {
    params: {
      type: Object,
      required: true
    },
    showNoteIndicator: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    currentNotesCount() {
      return this.$store.state.notes.currentNotesCount
    },
    prevNotesCount() {
      if (!this.showNoteIndicator) {
        return 0
      }
      return this.$store.state.notes.prevNotesCount
    },
    hasNewNotes() {
      return this.currentNotesCount > this.prevNotesCount && this.currentNotesCount > 0
    },
    newNotesCount() {
      return this.currentNotesCount - this.prevNotesCount
    }
  },
  methods: {
    async onToggleNotePanel() {
      this.$emit('toggle-note-panel', this.params);
      this.$store.commit('notes/TRIGGER_NOTES_PANEL', this.params)
      // wait for the panel to open
      setTimeout(() => {
        localStorage.setItem(`prev-notes-count-${this.$route.params.id}`, this.newNotesCount)
        this.$store.commit('notes/SET_PREV_NOTES_COUNT', this.newNotesCount)
      }, 100)
    }
  }
}
</script>

