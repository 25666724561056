<template>
  <BaseFormDialog
    v-bind="$attrs"
    v-on="$listeners"
    :title="$t('Move Employees to Policy')"
  >
    <BaseForm
      :loading="loading"
      :save-text="$t('Move')"
      :submit-disabled="!toPolicyId"
      layout="modal"
      @cancel="$emit('cancel')"
      @submit="moveEmployees"
    >
      <AgDataTable
        :columns="columns"
        :data="tableData"
        :showPagination="false"
        :pagination="false"
        dom-layout="autoHeight"
        class="h-full col-span-6"
      />

      <div class="mt-4 col-span-6">
        <div class="text-blue-600 flex justify-center text-xs items-center mb-3">
          <IconInfo class="w-4 h-4 mx-1"/>
          {{ $t('Employees can be moved only between policies with the same type and same state.') }}
        </div>
        <div class="flex flex-wrap space-x-2">
          <TimeOffPolicySelect
            :value="fromPolicy.id"
            disabled
            :label="$t('From Time Off Policy')"
            :placeholder="$t('From Time Off Policy')"
            class="flex-1"
            required="true"
          />
          <TimeOffPolicySelect
            v-model="toPolicyId"
            :label="$t('To Time Off Policy')"
            :placeholder="$t('Select To Time Off Policy')"
            :filterMethod="filterToPolicies"
            class="flex-1"
            required="true"
          />
        </div>
      </div>
    </BaseForm>
  </BaseFormDialog>
</template>
<script>
import {
  validateMin,
  validateMax,
  validateMaxDecimals,
} from "@/modules/common/util/validators";

export default {
  props: {
    timeOffStatuses: {
      type: Array,
      default: () => []
    },
    fromPolicy: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      loading: false,
      showDialog: false,
      toPolicyId: null,
      tableData: JSON.parse(JSON.stringify(this.timeOffStatuses)),
    }
  },
  computed: {
    columns() {
      return [
        {
          label: this.$t('Code'),
          prop: 'relationships.employee.attributes.code',
          component: 'EntityLink',
          redirectTo: '/payroll/employees/{ID}/view',
          minWidth: 120,
          maxWidth: 200,
        },
        {
          label: this.$t('Name'),
          prop: 'relationships.employee.attributes.name',
          minWidth: 200,
        },
        {
          label: this.$t('Carry Over (previous year)'),
          prop: 'attributes.previous_year_carry_over_hours',
          minWidth: 150,
          hide: this.isUnlimited,
          valueFormatter: this.hoursFormatter,
          valueSetter: (params) => {
            const newValue = parseFloat(params.newValue)
            const fromStatus = this.timeOffStatuses.find(status => status.id === params.data.id)
            const originalValue = parseFloat(fromStatus.attributes.previous_year_carry_over_hours)

            if (!validateMin(newValue, 0, { notifyErr: true })) {
              params.data.attributes.previous_year_carry_over_hours = originalValue
              return false
            }

            if (!validateMaxDecimals(newValue, 4, { notifyErr: true })) {
              params.data.attributes.previous_year_carry_over_hours = originalValue
              return false
            }

            if (!validateMax(newValue, originalValue, { notifyErr: true })) {
              params.data.attributes.previous_year_carry_over_hours = originalValue
              return false
            }

            params.data.attributes.previous_year_carry_over_hours = Math.min(newValue, originalValue)
            return true
          },
          sortable: false,
          editable: true,
        },
        {
          label: this.$t('Accrued (this year)'),
          prop: 'attributes.current_year_accrued_hours',
          minWidth: 150,
          hide: this.isUnlimited,
          valueFormatter: this.hoursFormatter,
          valueSetter: (params) => {
            const newValue = parseFloat(params.newValue)
            const fromStatus = this.timeOffStatuses.find(status => status.id === params.data.id)
            const originalValue = parseFloat(fromStatus.attributes.current_year_accrued_hours)
            
            if (!validateMin(newValue, 0, { notifyErr: true })) {
              params.data.attributes.current_year_accrued_hours = originalValue
              return false
            }

            if (!validateMaxDecimals(newValue, 4, { notifyErr: true })) {
              params.data.attributes.current_year_accrued_hours = originalValue
              return false
            }

            if (!validateMax(newValue, originalValue, { notifyErr: true })) {
              params.data.attributes.current_year_accrued_hours = originalValue
              return false
            }

            params.data.attributes.current_year_accrued_hours = Math.min(newValue, originalValue)
            return true
          },
          sortable: false,
          editable: true,
        },
        {
          label: this.$t('Used'),
          prop: 'attributes.current_year_used_hours',
          minWidth: 150,
          hide: this.isUnlimited,
          valueFormatter: this.hoursFormatter,
          valueSetter: (params) => {
            const newValue = parseFloat(params.newValue)
            const fromStatus = this.timeOffStatuses.find(status => status.id === params.data.id)
            const originalValue = parseFloat(fromStatus.attributes.current_year_used_hours)

            if (!validateMin(newValue, 0, { notifyErr: true })) {
              params.data.attributes.current_year_used_hours = originalValue
              return false
            }

            if (!validateMaxDecimals(newValue, 4, { notifyErr: true })) {
              params.data.attributes.current_year_used_hours = originalValue
              return false
            }

            if (!validateMax(newValue, originalValue, { notifyErr: true })) {
              params.data.attributes.current_year_used_hours = originalValue
              return false
            }

            params.data.attributes.current_year_used_hours = Math.min(newValue, originalValue)
            return true
          },
          sortable: false,
          editable: true,
        },
      ]
    },
  },
  methods: {
    hoursFormatter(params) {
      return `${params.value || 0} ${this.$t('hours')}`
    },
    async moveEmployees() {
      const from = this.fromPolicy.id
      const to = this.toPolicyId
      const employees = this.tableData.map(status => {
        return {
          id: status.attributes.employee_id,
          current_year_accrued_hours: status?.attributes?.current_year_accrued_hours || 0,
          current_year_used_hours: status?.attributes?.current_year_used_hours || 0,
          previous_year_carry_over_hours: status?.attributes?.previous_year_carry_over_hours || 0,
        }
      })

      try {
        this.loading = true
        
        await this.$store.dispatch('payroll/moveEmployeesBetweenTimeOffPolicies', { from, to, employees })
        this.$emit('save', false)
      }
      catch (err) {
        this.$error(this.$t('Failed to move employees'))
      }
      finally {
        this.loading = false
      }
    },
    filterToPolicies(policy) {
      const {
        time_off_type_id: fromTypeId,
        state: fromState,
        id: fromId
      } = this.fromPolicy.attributes
      
      const {
        time_off_type_id: toTypeId,
        state: toState,
        id: toId
      } = policy

      return fromId !== toId && fromState === toState && fromTypeId === toTypeId
    }
  }
}
</script>
