<template>
  <AgDataTable
      ref="table"
      :columns="columns"
      :url-params="urlParams"
      :data-loading="loading"
      :add-default-columns="false"
      :disable-col-flex="true"
      actions="search,refresh,view"
      url="/restify/payroll-batches"
  >
    <template #attributes.period_end_date="{row}">
      <router-link :to="`/payroll/checks/batches/${row.id}/view`">
        {{ $formatDate(row.attributes.period_end_date, 'date', true) }}
      </router-link>
    </template>
  </AgDataTable>
</template>
<script>
  export default {
    name: 'payroll-check-batches',
    data() {
      return {
        loading: false,
      }
    },
    computed: {
      columns() {
        return [
          {
            headerName: this.$t('End Date'),
            prop: 'attributes.period_end_date',
            maxWidth: 350,
            component: 'FormattedDate',
          },
          {
            headerName: this.$t('Check Count'),
            prop: 'attributes.counts.normal',
            minWidth: 100,
            component: 'Count',
          },
          {
            headerName: this.$t('Sent Count'),
            prop: 'attributes.telemetry.send_count',
            minWidth: 100,
            component: 'Count',
          },
          {
            headerName: this.$t('Sent Times'),
            prop: 'attributes.telemetry.sent_times',
            minWidth: 100,
            component: 'Count',
          },
          {
            headerName: this.$t('Downloaded'),
            prop: 'attributes.telemetry.download_count',
            minWidth: 100,
            component: 'Count',
          },
          {
            headerName: this.$t('Downloaded %'),
            prop: 'download_percent',
            minWidth: 120,
            valueFormatter: params => {
              const { download_count, send_count } = params.data.attributes.telemetry
              return this.$getPercent(+download_count, +send_count)
            }
          },
        ]
      },
      urlParams() {
        return {
          status: 'posted'
        }
      }
    },
    methods: {
      seeMoreClick(row) {
        this.$router.push(`/payroll/checks/batches/${row.id}`)
      },
      getDate(row) {
        return this.$formatDate(row.end_date, 'yyyy-MM-dd', true)
      },
    }
  }
</script>
