<template>
  <EquipmentForm
    :data="currentEquipment"
    @refresh="getEquipment"
  />
</template>
<script lang="ts" setup>
import store from '@/store'
import { computed } from 'vue'
import EquipmentForm from '@/modules/equipment/components/EquipmentForm.vue'

const currentEquipment = computed(() => {
  return store.state.equipmentManagement.currentEquipment
})

function getEquipment() {
  store.dispatch('equipmentManagement/getEquipment', currentEquipment.value.id)
}
</script>
