<template>
  <base-button variant="gray-light"
               :tabindex="0"
               v-bind="$attrs"
               size="icon"
               class="mr-2 hidden md:flex table-action-button"
               @click="$emit('click')">
    <MailIcon class="w-4 h-4"/>
  </base-button>
</template>
<script>
  import { MailIcon } from 'vue-feather-icons'

  export default {
    name: 'TableEmailButton',
    components: {
      MailIcon,
    },
  }
</script>
