<template>
  <base-form-dialog
      v-bind="$attrs"
      v-on="$listeners"
      :title="title"
      :has-padding="true"
      size="2xl"
  >
    <div class="absolute right-[80px] top-[15px]">
      <TableDownloadButton
          :has-margin="false"
          @click="downloadFile"/>
    </div>
    <FilePreviewDetails
        :data="data"
        ref="preview"/>
  </base-form-dialog>
</template>
<script lang="ts" setup>
import FilePreviewDetails from "@/modules/common/components/documents/FilePreviewDetails.vue";
import {computed, PropType} from "vue";
import Data = API.Data;

const props = defineProps({
  data: {
    type: Object as PropType<Data<Document>>,
    default: () => ({})
  }
})
const title = computed(() => {
  return props.data?.attributes?.name
})

function downloadFile() {
  window.open(props.data?.attributes?.url, '_blank')
}
</script>
