<template>
  <div class="h-full flex flex-col">
    <layout-tabs
        v-if="!onViewResourcePage"
        :items="filteredItems"
    />
    <router-view/>
  </div>
</template>
<script>

  export default {
    data() {
      return {
        items: [
          {
            name: this.$t('Employees'),
            path: '/payroll/employees',
            permission: 'payroll_settings',
          },
          {
            name: this.$t('Pay Rates'),
            path: '/payroll/pay-rates',
            permission: 'payroll_settings',
          },
          {
            name: this.$t('Deduction Rates'),
            path: '/payroll/deduction-rates',
            permission: 'payroll_settings',
          },
          {
            name: this.$t('Retirement Rates'),
            path: '/payroll/retirement-rates',
            permission: 'payroll_settings',
          },
          {
            name: this.$t('Benefit Rates'),
            path: '/payroll/benefit-rates',
            permission: 'payroll_settings',
          },
          {
            name: this.$t('Flexible Benefit Rates'),
            path: '/payroll/flexible-benefit-rates',
            permission: 'payroll_settings',
          },
          {
            name: this.$t('Taxes'),
            path: '/payroll/taxes',
            permission: 'payroll_settings',
          },
          {
            name: this.$t('Adjustments'),
            path: '/payroll/adjustments',
            permission: 'payroll_settings',
          },
          {
            name: this.$t('Direct Deposits'),
            path: '/payroll/direct-deposits',
            permission: 'payroll_settings',
          },
        ],
      }
    },
    computed: {
      filteredItems() {
        const permissions = this.$store.getters['auth/permissions']
        return this.items.filter(item => permissions.includes(item.permission))
      },
    },
  }
</script>
