<template>
  <div>
    <AgDataTable
        v-bind="editableTableProps"
        :url="url"
        :url-params="urlParams"
        :default-filters="false"
        :columns="columns"
        :read-only="isReadOnly"
        :add-text="$t('Add Entry')"
        :transform-data="transformData"
        :get-empty-row="getEmptyRow"
        :show-cells-legend="!isReadOnly"
        :groupIncludeTotalFooter="true"
        :no-borders="isReadOnly"
        :actions="tableActions"
        :perPage="1000"
        suppressColumnReordering
        default-sort="updated_at"
        id="gridTable"
        ref="table"
        @grid-ready="onGridReady"
    />
  </div>
</template>
<script>
  import axios from 'axios'
  import { cellEditors } from '@/components/ag-grid/cellEditors/cellEditors'
  import { editableTableProps, getTableData } from '@/components/ag-grid/tableUtils'
  import { descriptionCol } from '@/components/ag-grid/columns/costCenterColumns'
  import { getCellClasses, requiredValueSetter } from '@/components/ag-grid/columnUtils'
  import { getDeleteColumn } from "@/components/ag-grid/columns/deleteColumns";

  export default {
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      readOnly: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        editableTableProps,
        grid: null,
        emptyRow: {
          vendor_id: null,
          number: null,
          date: this.$now,
          due_date: this.$nextMonth,
          description: null,
          gross_amount: 0,
          discount_amount: 0,
        },
      }
    },
    computed: {
      batchId() {
        return this.data.id || this.params?.data?.id
      },
      url() {
        return this.batchId ? '/restify/accounts-payable-init-entries' : ''
      },
      urlParams() {
        const baseParams = {
          sort: 'vendor.code'
        }
        return this.batchId ? { accounts_payable_init_id: this.batchId, ...baseParams } : baseParams
      },
      tableActions() {
        return this.isReadOnly ? '' : 'add'
      },
      columns() {
        return [
          {
            headerName: this.$t('Vendor'),
            field: 'vendor_id',
            cellEditor: this.$cellEditors.GlobalResourceSelect,
            component: 'VendorLink',
            cellEditorParams: {
              resourceName: this.$globalResources.Vendors,
              target: '_blank',
            },
            cellClass: getCellClasses,
            editable: true,
          },
          {
            headerName: this.$t('Invoice'),
            children: [
              {
                headerName: this.$t('Number'),
                field: 'number',
                editable: true,
                cellClass: getCellClasses,
                minWidth: 120,
                maxWidth: 160,
              },
              {
                headerName: this.$t('Date'),
                field: 'date',
                editable: true,
                cellEditor: this.$cellEditors.DatePicker,
                valueFormatter: params => {
                  return params.value ? this.$formatDate(params.value) : ''
                },
                cellClass: getCellClasses,
                minWidth: 120,
                maxWidth: 160,
              },
              {
                headerName: this.$t('Due Date'),
                field: 'due_date',
                editable: true,
                cellEditor: this.$cellEditors.DatePicker,
                valueFormatter: params => {
                  return params.value ? this.$formatDate(params.value) : ''
                },
                cellClass: getCellClasses,
                minWidth: 120,
                maxWidth: 160,
              },
              {
                ...descriptionCol,
              },
            ],
          },
          {
            headerName: ' ',
            children: [
              {
                headerName: this.$t('Amount'),
                field: 'gross_amount',
                editable: true,
                cellEditor: cellEditors.Numeric,
                component: 'FormattedPrice',
                valueSetter: params => requiredValueSetter(params, 0),
                valueGetter: params => {
                  const value = +params.data?.gross_amount
                  if (isNaN(value)) {
                    return 0
                  }
                  return value
                },
                aggFunc: 'sum',
                minWidth: 120,
                maxWidth: 160,
              },
              {
                headerName: this.$t('Discount Pending'),
                field: 'discount_amount',
                editable: true,
                cellEditor: cellEditors.Numeric,
                component: 'FormattedPrice',
                valueSetter: params => {
                  const isValid = requiredValueSetter(params, 0) && params.newValue < params.data.gross_amount

                  if (!isValid) {
                    this.$warning(this.$t('Discount Pending must be less than Amount'))
                    params.data.discount_amount = 0
                    return false
                  }

                  return true
                },
                suppressKeyboardEvent: params => {
                  let isTabKey = params.event.key === 'Tab'
                  if (isTabKey) {
                    params.api.stopEditing()
                  }
                },
                aggFunc: 'sum',
                minWidth: 120,
                maxWidth: 160,
              },
            ],
          },
          {
            ...getDeleteColumn({
              url: '/restify/accounts-payable-init-entries',
              hide: this.isReadOnly,
            }),
          },
        ]
      },
      isReadOnly() {
        return this.params ? this.params.readOnly : this.readOnly
      },
    },
    methods: {
      onGridReady(params) {
        this.grid = params
      },
      getTableData() {
        const gridApi = this.grid?.api
        return getTableData(gridApi)
      },
      async storeProgress(accounts_payable_init_id) {
        const promises = []
        const entries = this.getTableData()

        const entriesToUpdate = entries.filter(entry => entry.dirty && entry.id)

        const entriesToStore = entries.filter(entry => !entry.id && entry.dirty).map(entry => {
          entry.accounts_payable_init_id = accounts_payable_init_id
          return entry
        })

        if (entriesToStore.length) {
          promises.push(axios.post('/restify/accounts-payable-init-entries/bulk', entriesToStore))
        }

        if (entriesToUpdate.length) {
          promises.push(axios.post('/restify/accounts-payable-init-entries/bulk/update', entriesToUpdate))
        }

        await Promise.all(promises)
        this.$refs.table.refresh()
      },
      getEmptyRow() {
        return {
          ...this.emptyRow,
          _localId: crypto.randomUUID(),
          accounts_payable_init_id: this.batchId,
        }
      },
      transformData(data) {
        return data.map(entry => entry.attributes)
      },
    },
  }
</script>
