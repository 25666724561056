<template>
  <W2Forms :employee-id="$route.params.id"/>
</template>
<script>
  import W2Forms from '@/modules/payroll/pages/w2-forms/w2-forms'

  export default {
    components: {
      W2Forms,
    }
  }
</script>
