<template>
  <nav class="hidden sm:flex items-center text-sm leading-5 font-medium overflow-y-auto">
    <template v-for="(route, index) in matchedRoutes">
      <router-link v-if="!isModuleRoute(route)"
                   :to="route.path ? route.path : route"
                   :key="route.path"
                   class="breadcrumb-link"
      >
        <template v-if="isNotLastItem(index)">
          {{ route.name }}
        </template>
        <portal-target v-else name="page-title" class="truncate text-sm breadcrumb-link">
          {{ route.name }}
        </portal-target>
      </router-link>
      <span v-else
            :key="route.path"
            class="breadcrumb-link cursor-pointer select-none"
            @click="handleRouteClick(route)"
      >
        {{ route.name }}
      </span>
      <svg :key="route.name + 'arrow'"
           v-if="isNotLastItem(index)"
           class="flex-shrink-0 mx-1 h-5 w-5 text-gray-400"
           fill="currentColor"
           viewBox="0 0 20 20">
        <path fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd"></path>
      </svg>
      <base-tooltip :key="route.path + 'info'"
                    :content="route.meta.appScreen"
                    v-if="showAppScreenInfo(index, route)">
        <HelpCircleIcon class="ml-2 text-gray-400 hover:text-gray-700 cursor-help"/>
      </base-tooltip>
    </template>
  </nav>
</template>
<script>
  import { isProduction } from "@/isProduction";
  import { HelpCircleIcon } from 'vue-feather-icons'
  import bus from "@/event-bus/EventBus";

  export default {
    name: 'BreadCrumbs',
    components: {
      HelpCircleIcon
    },
    inject: ['triggerMenuClick'],
    computed: {
      matchedRoutes() {
        const matchedRoutes = this.$route.matched
        return matchedRoutes
            .filter(route => route.name && !route.meta?.hideBreadCrumb)
            .map(route => {
              const params = this.$route.params
              for (let key in params) {
                route.path = route.path.replace(`:${key}`, params[key])
              }
              return route
            })
      },
    },
    methods: {
      isNotLastItem(index) {
        return index !== this.matchedRoutes.length - 1
      },
      showAppScreenInfo(index, route) {
        if (isProduction()) {
          return false
        }
        return !!(!this.isNotLastItem(index) && route?.meta?.appScreen);
      },
      isModuleRoute(route) {
        return route?.meta?.isModule
      },
      async handleRouteClick(route) {
        if (this.triggerMenuClick) {
          this.triggerMenuClick(route)
        }
      }
    }
  }
</script>
<style lang="scss">
  .breadcrumb-link {
    @apply whitespace-nowrap px-1.5 text-gray-400 hover:text-gray-700 transition duration-150 ease-in-out truncate;
    min-width: 60px;
    max-width: 250px;
    span {
      @apply text-gray-400 text-sm font-medium;
    }
    a {
      @apply text-gray-400 hover:text-gray-700;
    }
    .hide-breadcrumb {
      @apply hidden;
    }
  }
  .breadcrumb-link :deep(.status-badge) {
    @apply hidden;
  }
</style>
