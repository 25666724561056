<template>
  <BaseTabs
      v-model="activeTab"
      :tabs="tabs"
      label-key="label"
  >
    <AgDataTable
        v-if="activeTab === costTypes.Cost"
        v-bind="gridSettings(costTypes.Cost)"
        ref="gridTable"
        key="costTable"
        @grid-ready="grid = $event"
    />
    <AgDataTable
        v-else
        v-bind="gridSettings(costTypes.Income)"
        ref="gridTable"
        key="incomeTable"
        @grid-ready="grid = $event"
    />
  </BaseTabs>
</template>
<script lang="ts">
  import Data = API.Data;
  import { defineComponent } from 'vue'
  import { costTypes } from '@/enum/enums'
  import { Column } from '@/components/ag-grid/tableTypes'
  import { GridApi, GridReadyEvent } from '@ag-grid-community/core'
  import ColHeaderWithTip from '@/components/ag-grid/ColHeaderWithTip.vue'
  import { cellEditors } from '@/components/ag-grid/cellEditors/cellEditors'
  import { lineTypeOptions } from '@/modules/service-billing/util/service-billing'
  import { editableTableProps, storeBatchEntriesProgress, validateAgDataTable } from '@/components/ag-grid/tableUtils'
  import { accountCellProps, subAccountCellProps } from '@/components/ag-grid/columns/editableColumns'
  import {
    cellClasses,
    getCellClasses,
    markupValidator,
    requiredValueSetter
  } from '@/components/ag-grid/columnUtils'
  import { getDeleteColumn } from "@/components/ag-grid/columns/deleteColumns";

  export default defineComponent({
    components: {
      ColHeaderWithTip,
    },
    data() {
      return {
        costTypes,
        grid: null as GridReadyEvent | null,
        activeTab: costTypes.Cost,
        tabs: [
          {
            name: costTypes.Cost,
            label: this.$t('Cost Types'),
          },
          {
            name: costTypes.Income,
            label: this.$t('Billing Types'),
          },
        ],
        tableColumns: {
          [costTypes.Cost]: [
            {
              headerName: this.$t('Name'),
              field: 'name',
              cellClass: getCellClasses,
              minWidth: 120,
              editable: true,
            },
            {
              headerName: this.$t('Abbr'),
              field: 'abbr',
              editable: params => {
                const readonly = this.get(params, 'data.readonly', false)
                return !readonly
              },
              cellClass: params => {
                const readonly = this.get(params, 'data.readonly', false)
                return readonly ? cellClasses.ReadOnly : getCellClasses(params)
              },
              align: 'center',
              minWidth: 120,
              maxWidth: 150,
            },
            {
              headerName: this.$t('Debit Account'),
              field: 'account',
              cellClass: getCellClasses,
              ...accountCellProps,
              maxWidth: 400,
            },
            {
              headerName: this.$t('Sub'),
              field: 'subaccount',
              ...subAccountCellProps,
              maxWidth: 250,
            },

            {
              headerName: this.$t('Markup'),
              field: 'markup_percent',
              component: 'FormattedPercent',
              cellEditor: cellEditors.Numeric,
              valueSetter: params => {
                return requiredValueSetter(params, 0, markupValidator)
              },
              suppressKeyboardEvent: params => {
                let isTabKey = params.event.key === 'Tab'
                if (isTabKey) {
                  params.api.stopEditing()
                }
              },
              editable: true,
              minWidth: 100,
              maxWidth: 120,
            },
            {
              ...getDeleteColumn({
                url: '/restify/service-billing-types',
              } as any),
            },
          ] as Column[],
          [costTypes.Income]: [
            {
              headerName: this.$t('Name'),
              field: 'name',
              cellClass: getCellClasses,
              minWidth: 120,
            },
            {
              headerName: this.$t('Abbr'),
              field: 'abbr',
              editable: params => {
                const readonly = this.get(params, 'data.readonly', false)
                return !readonly
              },
              cellClass: params => {
                const readonly = this.get(params, 'data.readonly', false)
                return readonly ? cellClasses.ReadOnly : getCellClasses(params)
              },
              align: 'center',
              minWidth: 120,
              maxWidth: 150,
            },
            {
              headerName: this.$t('ELM'),
              field: 'line_type',
              component: 'Select',
              cellEditor: cellEditors.BaseSelect,
              headerComponent: 'ColHeaderWithTip',
              headerComponentParams: {
                headerTip: this.$t('The Billing Item Type is used to control the type of item code allowed for use with each Billing Type.'),
              },
              cellEditorParams: {
                options: lineTypeOptions,
              },
              valueFormatter: (params) => {
                const op = lineTypeOptions.find(o => o.value === params.value) || { label : '' }
                return op.label
              },
              minWidth: 120,
              maxWidth: 150,
              editable: true,
            },
            {
              headerName: this.$t('Taxable'),
              field: 'is_taxable',
              component: 'Status',
              cellEditor: cellEditors.Boolean,
              align: 'center',
              minWidth: 80,
              maxWidth: 80,
              editable: true,
            },
            {
              headerName: this.$t('Non-Tax Account'),
              field: 'non_tax_account',
              ...accountCellProps,
              cellClass: getCellClasses,
              maxWidth: 400,
            },
            {
              headerName: this.$t('Sub'),
              field: 'non_tax_subacount',
              ...subAccountCellProps,
              maxWidth: 250,
            },
            {
              headerName: this.$t('Taxable Account'),
              field: 'account',
              ...accountCellProps,
              maxWidth: 400,
            },
            {
              headerName: this.$t('Sub'),
              field: 'subaccount',
              ...subAccountCellProps,
              suppressKeyboardEvent: params => {
                let isTabKey = params.event.key === 'Tab'
                if (isTabKey) {
                  params.api.stopEditing()
                }
              },
              maxWidth: 250,
            },
            {
              ...getDeleteColumn({
                url: '/restify/service-billing-types',
              } as any),
            },
          ] as Column[]
        },
      }
    },
    methods: {
      gridSettings(type: string) {
        const typeText = this.activeTab === costTypes.Cost ? this.$t('cost') : this.$t('income')
        const urlParams = {
          type,
          sort: 'index',
        }
        return {
          urlParams,
          ...editableTableProps,
          url: '/restify/service-billing-types',
          actions: 'add,search,refresh',
          addText: this.$t(`New ${typeText} type`),
          getEmptyRow: () : SB.ServiceBillingTypeModel  => {
            return {
              type,
              name: '',
              abbr: '',
              line_type: '',
              non_tax_account: null,
              non_tax_subacount: null,
              account: null,
              subaccount: null,
              markup_percent: 0,
              is_taxable: false,
              readonly: false,
              _localId: crypto.randomUUID(),
            }
          },
          transformData: (data: Data<any>[]) => {
            return data.map(row => {
              return {
                id: row.id,
                ...row.attributes,
                meta: row.meta,
              }
            })
          },
          columns: this.tableColumns[this.activeTab],
        }
      },
      async storeProgress() {
        const gridApi = this?.grid?.api as GridApi
        await storeBatchEntriesProgress(gridApi, 'service-billing-types')
        this.refreshData()
      },
      refreshData() {
        // @ts-ignore
        this.$refs.gridTable?.refresh()
      },
      async save() {
        const isInvalidData = await validateAgDataTable()
        if (!isInvalidData) {
          await this.storeProgress()
        }

        return !isInvalidData
      },
    },
  })
</script>
