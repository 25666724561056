<template>
  <entity-select
      url="/restify/service-billing-categories"
      v-bind="$attrs"
      v-on="$listeners"
      :url-params="urlParams"
      :add-entity="addEntity"
      :edit-entity="editEntity"
      :set-default-option="editEntity"
      :name="$attrs.name || $t('S/B Category')"
      :placeholder="$attrs.placeholder || $t('Select S/B Category')"
      :label="$attrs.label !== undefined ? $attrs.label : $t('S/B Category')"
      :add-label="$t('Add new S/B Category')"
      :id="$attrs.id || 'sb-cat-select'"
      :table-columns="tableColumns"
      :label-format="formatLabel"
      label-key="code"
  />
</template>
<script>
  export default {
    name: 'ServiceBillingCategorySelect',
    inheritAttrs: false,
    props: {
      urlParams: {
        type: Object,
        default: () => ({
          sort: 'code',
        }),
      },
      addEntity: {
        type: Boolean,
        default: false,
      },
      editEntity: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        tableColumns: [
          {
            minWidth: 100,
            maxWidth: 150,
            name: this.$t('Code'),
            prop: 'attributes.code',
          },
          {
            minWidth: 150,
            maxWidth: 150,
            name: this.$t('Description'),
            prop: 'attributes.description',
          },
        ],
      }
    },
    methods: {
      formatLabel(category) {
        const { code, description } = category.attributes
        if (!description) {
          return code
        }
        return `${code} (${description})`
      },
    },
  }
</script>
