<template>
  <div class="truncate">
    <router-link
        :target="target"
        to="service-billing/settings/service-billing-settings"
        class="text-gray-900"
    >
      <span>
        {{ typeName }}
      </span>
    </router-link>
  </div>
</template>
<script>
  export default {
    name: 'ServiceBillingTypeLink',
    props: {
      id: [String, Number],
      target: String,
    },
    computed: {
      allTypes() {
        return this.$store.getters['globalLists/getResourceList'](this.$globalResources.ServiceBillingTypes)
      },
      typeData() {
        return this.allTypes.find(type => type.id === this.id)
      },
      typeName() {
        return this.typeData?.name
      },
    },
  }
</script>
