<template>
  <BillingRatesTable :rates-columns="columns"
                     source-type="material"
  />
</template>
<script>
  import BillingRatesTable from '@/modules/accounts-receivable/pages/billing-rates/BillingRatesTable'

  export default {
    components: {
      BillingRatesTable,
    },
    data() {
      return {
        columns: [
          {
            label: this.$t('Material'),
            prop: 'code',
            component: 'EntityLink',
            redirectTo: '/purchasing-inventory/materials/{ID}/edit',
          },
          {
            label: this.$t('Description'),
            prop: 'description',
          },
          {
            label: this.$t('Unit Measure'),
            prop: 'um',
          },
          {
            label: this.$t('Standard Unit Cost'),
            prop: 'standard_unit_price',
            component: 'FormattedPrice',
          },
        ],
      }
    },
  }
</script>
