<template>
  <div>
    <div @mouseleave="onMouseLeave(true)"
         @mouseover="mouseOverOnQuickPreview(true)"
         :style="previewWrapperStyles"
         class="fixed hidden left-auto min-w-40 h-auto bg-white border shadow-lg z-999999 rounded transition-all"
    >
      <transition enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
      >
        <slot name="body" v-if="hoveredRowId === 'hovered-row-id'">
        </slot>
      </transition>
    </div>
    <div @mouseover="onMouseOver()"
         @click="onMouseOver('click')"
         :id="`row-${parentEntityId}`"
         @mouseleave="onMouseLeave(false)">
      <slot name="reference">
      </slot>
    </div>
  </div>
</template>
<script>

  export default {
    props: {
      valueToDisplay: {
        type: String || Number,
        default: '',
      },
      parentEntityId: {
        type: String || Number,
        default: '',
      },
      trigger: {
        type: String,
        default: 'auto',
      },
      placement: {
        type: String,
        default: 'right'
      },
      rectTopMargin: {
        type: Number,
        default: 35,
      },
    },
    data() {
      return {
        previewWrapperStyles: {},
        quickPreviewFocused: false,
        hoveredRowId: false,
      }
    },
    methods: {
      onMouseOver(method = null) {
        if (method !== 'click' && this.trigger === 'click') {
          return
        }
        const rowID = `row-${this.parentEntityId}`
        this.hidePreview()
        this.hoveredRowId = 'hovered-row-id'
        const el = document.getElementById(rowID)
        if (!el) {
          return
        }
        const rect = el.getBoundingClientRect()
        this.setStyles(rect)
        this.$emit('after-enter')
      },
      setStyles(rect) {
        const marginTop = rect.y - this.rectTopMargin
        let marginLeft = rect.x + rect.width + 5
        if (this.placement === 'left') {
          marginLeft = rect.x - rect.width - 50
        }
        this.previewWrapperStyles = {
          display: 'block',
          top: `${marginTop}px`,
          left: `${marginLeft}px`
        }
      },
      onMouseLeave(forced) {
        if (forced) {
          return this.hidePreview()
        }
        setTimeout(() => {
          if (this.quickPreviewFocused) {
            return
          }
          this.hidePreview()
        }, 100)
      },
      hidePreview() {
        this.hoveredRowId = false
        this.quickPreviewFocused = false
        this.previewWrapperStyles = {}
      },
      mouseOverOnQuickPreview(state) {
        this.quickPreviewFocused = state
      }
    },
  }
</script>
