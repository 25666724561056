<template>
  <base-tooltip :content="message">
    <svg class="h-5 w-5 warning-icon text-red-400 mr-1" fill="currentColor" viewBox="0 0 20 20">
      <path fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clip-rule="evenodd"/>
    </svg>
  </base-tooltip>
</template>
<script>
  export default {
    props: {
      message: String,
    }
  }
</script>
<style scoped>
  .warning-icon {
    min-width: 20px;
    min-height: 20px;
  }
</style>
