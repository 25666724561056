<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="bg-white shadow overflow-hidden sm:rounded-lg">
    <div class="px-4 py-5 sm:px-6 flex items-center">
      <base-avatar :name="user.name"
                   :src="user.photo_url"
                   class="mr-5"
                   size="xl"/>
      <div>
        <h4>
          {{ user.name }}
        </h4>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">
          {{ $t('Created on ') }} {{ $formatDate(user.created_at) }}
        </p>
      </div>
    </div>
    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
      <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">
            {{ $t('Full Name') }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ user.name }}
          </dd>
        </div>
        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">
            {{ $t('Email') }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            <base-email-link :value="user.email"></base-email-link>
          </dd>
        </div>
        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">
            {{ $t('Phone') }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            <base-phone-link :value="user.phone"></base-phone-link>
          </dd>
        </div>
        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">
            {{ $t('Address') }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            <base-address-link :value="user.address"/>
          </dd>
        </div>
        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">
            {{ $t('Company Access') }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            <div class="py-1">
              <CompanyLink
                  v-for="company in user.currentOrganizationCompanies || []"
                  :key="company.id"
                  :company="company"
                  class="text-gray-900"
              />
            </div>
          </dd>
        </div>
        <div class="sm:col-span-2">
        </div>
      </dl>
    </div>
  </div>

</template>
<script>

  import CompanyLink from "@/components/links/CompanyLink.vue";

  export default {
    components: {
      CompanyLink,
    },
    computed: {
      user() {
        return this.$store.state.user.selectedUser
      },
    }
  }
</script>
