<template>
    <div>
        <BaseDataTable :columns="columns"
                       :url="url"
                       :url-params="urlParams"
                       default-sort="phase"
                       actions="search,refresh"
                       hide-actions="view"
                       permission="estimate_codes"
                       ref="table"
                       @add="onRowAdd"
                       @edit="onRowEdit"
                       :edit-entity-url-query="editEntityUrlQuery"
        >
            <template v-slot:work_item_code="{row}">
                <template v-if="row.attributes.work_item_code_id">
                    <router-link :to="`/estimates/work-items?id=${row.attributes.work_item_code_id}`">
                        {{get(row, 'relationships.workItem.attributes.code')}}
                    </router-link>
                </template>
                <template v-else>

                </template>
            </template>
        </BaseDataTable>
        <BaseFormDialog v-if="showAddDialog"
                        :title="$t('Add Phase Code / Cost Code')"
                        :open.sync="showAddDialog">
            <PhaseCostCodeForm @cancel="showAddDialog = false"
                                  @save="onAdd"
            />
        </BaseFormDialog>
        <BaseFormDialog v-if="showEditDialog"
                        :title="$t('Edit Phase Code / Cost Code')"
                        :open.sync="showEditDialog">
            <PhaseCostCodeForm :data="rowToEdit"
                                  @cancel="showEditDialog = false"
                                  @save="onEdit"
            />
        </BaseFormDialog>
    </div>
</template>
<script>
  import PhaseCostCodeForm from '@/modules/estimates/components/PhaseCostCodeForm'

  export default {
    components: {
      PhaseCostCodeForm,
    },
    data() {
      return {
        editEntityUrlQuery: '?id={ID}',
        url: '/restify/phase-costs',
        urlParams: {
          related: 'workItem'
        },
        rowToEdit: null,
        showAddDialog: false,
        showEditDialog: false,
        columns: [
          {
            label: this.$t('Phase Code'),
            prop: 'attributes.phase',
            component: 'EntityLink',
            redirectTo: '/estimates/phase-cost?id={ID}',
          },
          {
            label: this.$t('Cost Code'),
            prop: 'attributes.cost',
          },
          {
            label: this.$t('Description'),
            prop: 'attributes.description',
            maxWidth: 200,
          },
          {
            label: this.$t('U.M'),
            prop: 'attributes.um',
            align: 'center',
          },
          {
            label: this.$t('Work Item Code'),
            prop: 'work_item_code',
          },
        ],
      }
    },
    methods: {
      onRowEdit(row) {
        this.showEditDialog = true
        this.rowToEdit = row.attributes
      },
      onRowAdd() {
        this.showAddDialog = true
      },
      onAdd() {
        this.showAddDialog = false
        this.$refs.table.refresh()
      },
      onEdit() {
        this.showEditDialog = false
        this.$refs.table.refresh()
      },
    },
  }
</script>
