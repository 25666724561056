import { ref } from "vue";

export function useEntityReview() {
  const data = ref([]);
  const showPrevButton = ref(false)
  const showNextButton = ref(false)
  const selectedEntity = ref(null)
  const selectedIndex = ref(0)
  const showEntityDetails = ref(false)

  function setCurrentEntity(entity) {
    if (!entity) {
      return
    }
    const index = data.value.findIndex(i => i.id === entity.id)
    showPrevButton.value = index > 0
    showNextButton.value = index < data.value.length - 1
    selectedEntity.value = entity
    selectedIndex.value = index
    showEntityDetails.value = true
  }

  function onNext() {
    selectedIndex.value++
    setCurrentEntity(data.value[selectedIndex.value])
  }
  function onPrev() {
    selectedIndex.value--
    setCurrentEntity(data.value[selectedIndex.value])
  }

  function onDataFetch(entries) {
    data.value = entries.filter(row => !row.header)
  }

  return {
    onNext,
    onPrev,
    setCurrentEntity,
    onDataFetch,
    data,
    showPrevButton,
    showNextButton,
    showEntityDetails,
    selectedEntity,
    selectedIndex,
  }
}
