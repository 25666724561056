import axios from 'axios';
import config from "@/modules/common/config";
import localforage from "localforage";

class AuthService {

  setToken(token) {
    localStorage.setItem('token', token);
  }

  getToken() {
    return localStorage.getItem('token');
  }

  async login(data, params) {
    let result = await axios.post('/login', data, {
      params,
    });
    let { plainTextToken } = result;
    this.setToken(plainTextToken);
    return result;
  }

  async globalLogin(data) {
    return axios.post(`${config.ROOT_API_URL}/global/login`, data);
  }

  loginWithTokenRedirect(organization, token) {
    const url = `${organization?.uiUrl}login?token=${token}`
    window.location.href = url
  }

  async registerConfirm(data, params) {
    const requestData = {
      name: data.name,
      password: data.password,
      password_confirmation: data.password,
    }
    const requestParams = {
      from: params.from,
      token: params.invitation,
    }
    const user = await axios.post('/register', requestData, {
      params: requestParams,
    })
    await this.login({
      email: user.email,
      password: data.password,
    })

    return user
  }

  async logout() {
    localStorage.removeItem('token');
    sessionStorage.clear();
    await localforage.clear()

    delete axios.defaults.headers.common['Authorization'];
  }

  async resetPassword(data) {
    return await axios.post('/reset', data);
  }

  async forgotPassword(data) {
    return axios.post('/forgot', data);
  }
}

export default new AuthService();
