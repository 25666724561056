<template>
  <div>
    <portal to="page-title">
      <span class="text-xl font-bold text-gray-900">{{ $t('Lump Sum Billings Batch') }} </span>
      <span class="text-sm text-gray-700 mb-2">({{ $formatDate(batchDate) }})</span>
    </portal>
    <BaseDataTable :columns="columns"
                   :url="url"
                   :url-params="urlParams"
                   :data-map-function="mapCustomers"
                   actions="search, refresh"
                   ref="table"
                   @all-selected="onAllPagesSelected"
                   @edit="addEmail"
                   @data-fetch="customers = $event"
    >
      <template v-slot:customer_email="{row, index}">
        <div class="truncate">
          <a v-if="getEmail(row)"
             :href="`mailto:${getEmail(row)}`"
             class="text-primary-500 hover:text-primary-900 hover:underline cursor-pointer truncate">
            {{ getEmail(row) }}
          </a>
          <base-button v-else
                       variant="white"
                       @click="addEmail(row, index)">
            {{ $t('Add Email') }}
          </base-button>
        </div>
      </template>
      <template v-slot:additional-actions>
        <div>
          <base-button variant="primary"
                       :disabled="!customers.some(e => e.selected)"
                       @click="sendEmails"
                       class="-mr-1"
          >
            <MailIcon class="w-5 h-5 mr-2"></MailIcon>
            {{ $t('Send Billing(s)') }}
          </base-button>
        </div>
      </template>
      <template v-slot:select="{row}">
        <base-checkbox v-model="row.selected"
                       :disabled="!getEmail(row)">
        </base-checkbox>
      </template>
      <template v-slot:attributes.sent_count="{row}">
        <base-badge :type="row.attributes.sent_count > 0 ? 'success' : 'info'">
          {{ $tc('sent times', row.attributes.sent_count, { count: row.attributes.sent_count }) }}
        </base-badge>
      </template>
      <template v-slot:attributes.customer_data.Name="{row}">
        <div>
          <div class="w-full truncate">{{ getCustomer(row).name }}</div>
          <base-phone-link :value="getCustomer(row).phone"></base-phone-link>
        </div>
      </template>
      <template v-slot:extra-actions="{row, index}">
        <table-edit-button v-if="$isAuthorized('authorizedToUpdate', row) && !customerId"
                           @click="addEmail(row, index)"
        />
        <table-download-button v-if="$isAuthorized('authorizedToDownload', row)"
                               :loading="row.downloading"
                               @click="downloadLumpSumBilling(row)"
        />
        <template v-if="$isAuthorized('authorizedToSendEmail', row) && getEmail(row)">
          <table-icon-button :content="$t('Send Consent Email')"
                             @click="sendLumpSumBilling(row)"
          >
            <MailIcon class="w-4 h-4"></MailIcon>
          </table-icon-button>
        </template>
      </template>
    </BaseDataTable>
    <base-form-dialog v-if="showAddEmailDialog && rowToEdit"
                      :key="rowToEdit.id"
                      :title="getCustomer(rowToEdit).name"
                      :show-arrows="true"
                      :open.sync="showAddEmailDialog"
                      :previous-disabled="selectedIndex <= 0"
                      :next-disabled="selectedIndex === customers.length - 1 && isLastPage()"
                      @next-click="onNextClick"
                      @prev-click="onPrevClick"
    >
      <ValidationObserver v-slot="{ handleSubmit }">
        <base-form :loading="rowToEdit.loading"
                   layout="modal"
                   @cancel="$emit('cancel')"
                   @submit="handleSubmit(onEmailAdd)"
        >
          <div class="col-span-6">
            <base-input v-model="modelToEdit.email"
                        :label="$t('Email')"
                        :placeholder="$t('Customer Email')"
                        id="email"
                        rules="required|email">
            </base-input>
          </div>
        </base-form>
      </ValidationObserver>
    </base-form-dialog>
    <DeleteDialog v-if="showConfirmDialog"
                  :title="$t(`Send Emails to ${allPagesSelected ?  `all (${totalCustomers})` : selectedEmails.length} customer(s)`)"
                  :description="$t(`This action will send email(s) to ${allPagesSelected ?  `all (${totalCustomers})` : selectedEmails.length} customer(s). Make sure to validate the lump sum billing amounts and preview the lump sum billing before sending them via email. Note that customers without an email will be skipped.`)"
                  :delete-text="$t('Send')"
                  :open="true"
                  type="primary"
                  @delete="onConfirmSendAction"
                  @cancel="onConfirmRejectAction"
    >
    </DeleteDialog>
  </div>
</template>
<script>
  import axios from 'axios'
  import { MailIcon } from 'vue-feather-icons'
  import DeleteDialog from '@/components/common/modal/DeleteDialog'
  import { downloadFileForEntity } from '@/modules/common/util/downloadFileLocally'

  export default {
    components: {
      MailIcon,
      DeleteDialog,
    },
    props: {
      customerId: String,
    },
    data() {
      return {
        url: '/restify/lump-sum-billings',
        showAddEmailDialog: false,
        showPreviewDialog: false,
        selectedIndex: -1,
        rowToEdit: null,
        allPagesSelected: false,
        sendEmailLoading: false,
        modelToEdit: {
          email: '',
        },
        confirmResolve: null,
        confirmReject: null,
        showConfirmDialog: false,
        customers: [],
        batchDate: '',
      }
    },
    computed: {
      urlParams() {
        const data = {
          related: 'customer,company',
        }
        const { date } = this.$route.params
        if (date && date !== 'undefined' && date !== 'null') {
          data.date = date
        }
        if (this.customerId) {
          data.customer_id = this.customerId
        }
        return data
      },
      columns() {
        const columns = [
          {
            label: '',
            prop: 'select',
            minWidth: 40,
            maxWidth: 40,
            smallCell: true,
          },
          {
            label: this.$t('Customer'),
            prop: 'attributes.customer_data.Name',
            maxWidth: 200,
            renderIf: () => !this.customerId
          },
          {
            label: this.$t('Email'),
            prop: 'customer_email',
            minWidth: 200,
            maxWidth: 270,
            component: 'EmailLink',
          },
          {
            label: this.$t('Due Amount'),
            prop: 'attributes.totals.netTotalLessTax',
            maxWidth: 130,
            component: 'FormattedPrice',
          },
          {
            label: this.$t('Downloaded At'),
            prop: 'attributes.downloaded_at',
            maxWidth: 150,
            align: 'center',
            component: 'FormattedDate',
          },
          {
            label: this.$t('Sent'),
            prop: 'attributes.sent_count',
            maxWidth: 100,
            align: 'center',
          },
        ]
        return columns.filter(c => {
          if (c.renderIf) {
            return c.renderIf()
          }
          return true
        })
      },
      allSelected() {
        return this.selectableRows.length && this.selectableRows.every(r => r.selected) && !this.allPagesSelected
      },
      selectedRows() {
        return this.customers.filter(e => e.selected)
      },
      selectableRows() {
        return this.customers.filter(row => this.hasEmail(row))
      },
      selectedEmails() {
        return this.customers.filter(e => e.selected && this.hasEmail(e))
      },
      totalCustomers() {
        const table = this.$refs.table
        if (!table) {
          return 0
        }
        return table.pagination.total
      },
    },
    methods: {
      mapCustomers(customers) {
        return customers.map(entry => {
          return {
            ...entry,
            selected: false,
          }
        })
      },
      isLastPage() {
        const { last_page, current_page } = this.get(this.$refs, 'table.pagination', {})
        return last_page === current_page
      },
      async sendLumpSumBilling(row) {
        const customer = this.getCustomer(row)
        const confirmed = await this.$confirm({
          title: this.$t(`Send Lump Sum Billings Email to ${customer.name}`),
          description: this.$t(`This action will send a lump sum billing email to ${customer.name}.`),
          buttonText: this.$t('Send'),
        })

        if (!confirmed) {
          return
        }
        try {
          this.$set(row, 'loading', true)
          const data = {
            repositories: [row.id],
          }
          await axios.post(`/restify/lump-sum-billings/action?action=send-lump-sum-billing`, data)
          this.$success(this.$t(`Email was queued to be sent. ${customer.name} will receive the email in a couple of minutes.`))
          this.$refs.table.refresh()
        } catch (err) {
          this.$error('An error occurred while trying to send the lump sum billing emails')
        } finally {
          this.$set(row, 'loading', false)
        }
      },
      async downloadLumpSumBilling(row) {
        await downloadFileForEntity(row, 'lump-sum-billings')
      },
      onConfirmSendAction() {
        this.showConfirmDialog = false
        if (this.confirmResolve) {
          this.confirmResolve(true)
        }
      },
      onConfirmRejectAction() {
        this.showConfirmDialog = false
        if (this.confirmResolve) {
          this.confirmResolve(false)
        }
      },
      confirmSendAction() {
        this.showConfirmDialog = true
        return new Promise(resolve => {
          this.confirmResolve = resolve
        })
      },
      async sendEmails() {
        const confirmed = await this.confirmSendAction()

        if (!confirmed) {
          return
        }
        try {
          this.sendEmailLoading = true
          let payload = {
            repositories: [],
          }
          if (this.allPagesSelected) {
            payload.repositories = 'all'
          } else {
            payload.repositories = this.selectedRows.map(r => r.id)
          }
          const params = {

            date: this.$route.params.date,
          }
          await axios.post(`/restify/lump-sum-billings/action?action=send-lump-sum-billing`, payload, { params })
          this.allPagesSelected = false
          this.$refs.table.selectRows(false)
          this.$success(this.$t('Lump Sum Billings emails are queued to be sent. The selected customers will receive their lump sum billings in a couple of minutes.'))
          this.$refs.table.refresh()
        } catch (err) {
          this.$error('An error occurred while trying to send the lump sum billings via email')
        } finally {
          this.sendEmailLoading = false
        }
      },
      hasEmail(row) {
        const email = this.getEmail(row)
        return email !== '' && email !== null
      },
      getEmail(row) {
        return this.get(row, 'relationships.customer.attributes.email')
      },
      getCustomer(row) {
        const customerData = row.attributes.customer_data || {}
        const baseCustomer = this.get(row, 'relationships.customer.attributes', {})
        return {
          ...customerData,
          ...baseCustomer,
        }
      },
      async onNextClick() {
        if (this.selectedIndex === this.customers.length - 1) {
          if (!this.isLastPage()) {
            this.$refs.table.pagination.current_page++
            await this.$refs.table.refresh()
            this.selectedIndex = 0
            this.rowToEdit = this.customers[this.selectedIndex]
            this.modelToEdit.email = this.getEmail(this.rowToEdit)
            return
          }
          this.showAddEmailDialog = false
          return
        }
        this.selectedIndex++
        this.rowToEdit = this.customers[this.selectedIndex]
        this.modelToEdit.email = this.getEmail(this.rowToEdit)
      },
      onPrevClick() {
        this.selectedIndex--
        this.rowToEdit = this.customers[this.selectedIndex]
        this.modelToEdit.email = this.getEmail(this.rowToEdit)
      },
      addEmail(row, index) {
        this.rowToEdit = row
        this.selectedIndex = index
        this.showAddEmailDialog = true
        this.modelToEdit.email = this.getEmail(row)
      },
      onAllPagesSelected(value) {
        this.allPagesSelected = value
      },
      async onEmailAdd() {
        const customer = this.get(this.rowToEdit, 'relationships.customer.attributes', {})
        const customerId = customer.id
        const previousEmail = customer.email
        try {
          this.$set(this.rowToEdit, 'loading', true)
          customer.email = this.modelToEdit.email
          await axios.put(`/restify/customers/${customerId}`, {
            email: this.modelToEdit.email,
          })
          this.$set(this.rowToEdit, 'loading', false)
          await this.onNextClick()
        } catch (err) {
          this.$error(this.$t('Could not update the customer information'))
          customer.email = previousEmail
        } finally {
          this.$set(this.rowToEdit, 'loading', false)
        }
      },
    },
    mounted() {
      const { date } = this.$route.params
      this.batchDate = date
    },
  }
</script>
