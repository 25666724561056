<template>
  <div class="flex items-center">
    <base-tooltip :content="$t('Normal Payments')">
      <div class="flex flex items-center mr-3">
        <FileTextIcon class="w-5 h-5 mr-1"></FileTextIcon>
        <span>{{ batch.check_count || 0 }}</span>
      </div>
    </base-tooltip>
    <base-tooltip
      v-if="batch.joint_payment_count"
      :content="$t('Joint Payments')">
      <div class="flex flex items-center mr-3">
        <svg class="w-5 h-5 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m8 6 4-4 4 4"/><path d="M12 2v10.3a4 4 0 0 1-1.172 2.872L4 22"/><path d="m20 22-5-5"/></svg>
        <span>{{ batch.joint_payment_count || 0 }}</span>
      </div>
    </base-tooltip>
    <base-tooltip v-if="batch.manual_count !== undefined || batch.credit_card_count !== undefined"
                  :content="$t('Manual Payments')">
      <div class="flex flex items-center mr-3">
        <PenToolIcon class="w-5 h-5 mr-1"></PenToolIcon>
        <span>{{manualPaymentsCount}}</span>
      </div>
    </base-tooltip>
    <base-tooltip v-if="batch.direct_deposit_count !== undefined"
                  :content="$t('Direct Deposits')">
      <div class="flex flex items-center">
        <ArrowRightCircleIcon class="w-5 h-5 mr-1"></ArrowRightCircleIcon>
        <span>{{batch.direct_deposit_count || 0}}</span>
      </div>
    </base-tooltip>
  </div>
</template>
<script>
  import { ArrowRightCircleIcon, FileTextIcon, PenToolIcon, UsersIcon } from 'vue-feather-icons'

  export default {
    components: {
      UsersIcon,
      PenToolIcon,
      FileTextIcon,
      ArrowRightCircleIcon,
    },
    props: {
      batch: {
        type: Object,
        default: () => ({})
      }
    },
    computed: {
      manualPaymentsCount() {
        const manualCount = this.batch.manual_count || 0
        const creditCardCount = this.batch.credit_card_count || 0
        return manualCount + creditCardCount
      }
    }
  }
</script>
