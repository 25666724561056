<template>
  <div class="mt-2 equipment-details">
    <DetailsPageLayout :loading="equipmentLoading">

      <template #left>
        <EquipmentMainCard
          :equipment="equipment"
          :equipmentFullData="equipmentFullData"
        />
        <EquipmentUtilizationInformation :equipment="equipment"/>
      </template>

      <template #right>
        <EquipmentDetailsCard
          :equipment="equipment"
          :equipmentFullData="equipmentFullData"
        />
        <EquipmentFinancialInformation :equipment="equipment"/>
      </template>

    </DetailsPageLayout>
    <base-back-link to="/equipment/list"/>
  </div>
</template>
<script lang="ts" setup>
import store from '@/store'
import { computed } from 'vue'
import DetailsPageLayout from '@/modules/common/components/DetailsPageLayout.vue'
import EquipmentUtilizationInformation from '@/modules/equipment/components/equipment/EquipmentUtilizationInformation.vue'
import EquipmentDetailsCard from '@/modules/equipment/components/equipment/EquipmentDetailsCard.vue'
import EquipmentFinancialInformation from '@/modules/equipment/components/equipment/EquipmentFinancialInformation.vue'
import EquipmentMainCard from '@/modules/equipment/components/equipment/EquipmentMainCard.vue'

const equipmentFullData = computed(() => {
  return store.state.equipmentManagement.currentEquipment
})

const equipment = computed(() => {
  return store.state.equipmentManagement.currentEquipment.attributes
})

const equipmentLoading = computed(() => {
  return store.state.equipmentManagement.equipmentLoading
})

</script>
<style lang="scss">
.equipment-details {
  span {
    @apply text-gray-500 font-medium;
  }
}
</style>
