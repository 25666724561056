<template>
  <div class="my-1 flex">
    <base-checkbox
        v-model="canCopy"
        :label="$t(`Copy data from another ${getComponent.title}`)"
        id="copy-from-sibling"
        @change="onChangeState"
    />
    <financial-report-select
        v-if="getComponent?.resourceName === 'FinancialReports'"
        v-model="entity"
        :disabled="!canCopy"
        :select-first-option="false"
        class="w-64"
        clearable
        inline-errors
        @entity-change="onChange"
    />
    <base-grid-select
        v-else-if="getComponent.resourceName"
        v-model="entity"
        :disabled="!canCopy"
        :own-policy="authorizedToAdd"
        :resource-name="$globalResources[`${getComponent.resourceName}`]"
        :add-entity="false"
        class="w-64"
        clearable
        @entity-change="onChange"
    />
    <component
        v-else
        v-model="entity"
        :is="getComponent.name"
        :add-entity="false"
        :disabled="!canCopy"
        class="w-64"
        label=""
        clearable
        inline-errors
        @entity-change="onChange"
    />
  </div>
</template>
<script>
  import { getSiblingComponent } from '@/modules/common/util/copySiblingUtil'
  import FinancialReportSelect from "@/modules/ledger/components/reports/FinancialReportSelect.vue";

  export default {
    components: {
      FinancialReportSelect
    },
    data() {
      return {
        canCopy: false,
        entity: '',
      }
    },
    computed: {
      getComponent() {
        return getSiblingComponent(this.$route.path)
      },
    },
    methods: {
      authorizedToAdd() {
        return false
      },
      onChange(data = {}) {
        const model = data.attributes || data
        this.$store.dispatch('sibling/setData', model)
      },
      onChangeState() {
        this.entity = ''
      },
      resetState() {
        this.$store.dispatch('sibling/setData', {})
      },
    },
    beforeDestroy() {
      this.resetState()
    },
  }
</script>
