<template>
  <div>
    <EmailConsentAlert v-if="false" :isEmployeeConsentRequired="isEmployeeConsentRequired"/>
    <EmployeeChecksTable />
  </div>
</template>
<script>
  import EmailConsentAlert from "@/modules/payroll/components/EmailConsentAlert";
  import EmployeeChecksTable from "@/modules/payroll/components/EmployeeChecksTable";

  export default {
    components: {
      EmailConsentAlert,
      EmployeeChecksTable,
    },
    computed: {
      isEmployeeConsentRequired() {
        return !!this.$store.getters['company/isEmployeeConsentRequired']
      },
    }
  }
</script>
