<template>
  <AgDataTable
    :key="renderKey"
    ref="table"
    url="/restify/phase-titles"
    :url-params="urlParams"
    :columns="columns"
    :transform-data="transformData"
    actions="delete"
    domLayout="autoHeight"
    @delete="renderKey++"
  >
    <template #description="{row}">
      <div>
        <span v-if="!row.description" class="text-gray-400 text-sm">
          {{ $t('No description provided. Double click to add') }}
        </span>
        <span v-else>
          {{ row.description }}
        </span>
      </div>
    </template>
    <template #budget="{row}">
      <div class="py-2">
        <div
          class="flex justify-end space-x-3 text-sm leading-normal">
          <dl>
            <dt>{{ $t('Income') }}:</dt>
            <dt>{{ $t('Cost') }}:</dt>
            <dt>{{ $t('Difference') }}:</dt>
          </dl>
          <dl class="leading-normal">
            <dt>
              {{ formatPrice(row.income_budget) }}
            </dt>
            <dt>
              {{ formatPrice(row.cost_budget) }}
            </dt>
            <dt class="text-xs font-semibold mt-1">
              {{ formatPrice(row.income_budget - row.cost_budget) }}
            </dt>
          </dl>
        </div>
      </div>
    </template>
    <template #actual="{row}">
      <div class="py-2">
        <div
          class="flex justify-end space-x-3 text-sm leading-normal">
          <dl>
            <dt>{{ $t('Income') }}:</dt>
            <dt>{{ $t('Cost') }}:</dt>
            <dt>{{ $t('Difference') }}:</dt>
          </dl>
          <dl class="leading-normal">
            <dt>
              {{ formatPrice(row.income_amount) }}
            </dt>
            <dt>
              {{ formatPrice(row.cost_amount) }}
            </dt>
            <dt class="text-xs font-semibold mt-1">
              {{ formatPrice(row.income_amount - row.cost_amount) }}
            </dt>
          </dl>
        </div>
      </div>
    </template>
  </AgDataTable>
</template>
<script>
import { globalResources } from "@/components/form/util";
import { uniqBy } from "lodash";
import axios from "axios";
import { cellClasses } from "@/components/ag-grid/columnUtils";
import { jobLineItemTypes } from "@/enum/enums";
import { formatPrice } from "@/plugins/formatPrice";

export default {
  data() {
    return {
      renderKey: 1,
    }
  },
  computed: {
    jobId() {
      return this.$route.params.id
    },
    jobLineItems() {
      const lineItems = this.$store.getters['globalLists/getResourceList'](globalResources.LineItems) || []
      return lineItems.filter(item => item.job_id === this.jobId)
    },
    urlParams() {
      return {
        job_id: this.jobId,
      }
    },
    columns() {
      return [
        {
          headerName: this.$t('Phase'),
          field: 'phase_code',
          minWidth: 100,
          maxWidth: 150,
        },
        {
          headerName: this.$t('Budget'),
          field: 'budget',
          minWidth: 200,
          maxWidth: 300,
          component: 'FormattedPrice',
          autoHeight: true,
        },
        {
          headerName: this.$t('Actual'),
          field: 'actual',
          minWidth: 200,
          maxWidth: 300,
          component: 'FormattedPrice',
        },
        {
          headerName: this.$t('Description'),
          field: 'description',
          minWidth: 200,
          flex: 1,
          editable: true,
          headerClass: cellClasses.HeaderEditable,
          valueFormatter: params => {
            if (!params.value) {
              return this.$t('No description provided. Double click to add')
            }
            return params.value
          },
          valueSetter: params => {
            if (!params.newValue) {
              this.$error(this.$t('Description cannot be empty. To remove, use the delete button'))
              return false
            }
            params.data.description = params.newValue
            this.addOrUpdatePhaseTitle(params)
            return true
          },
        }
      ]
    }
  },
  methods: {
    formatPrice,
    phaseItems() {
      const phaseItems = {}
      this.jobLineItems.forEach(lineItem => {
        if (!phaseItems[lineItem.phase_code]) {
          phaseItems[lineItem.phase_code] = {
            phase_code: lineItem.phase_code,
            description: '',
            job_id: lineItem.job_id,
            cost_budget: 0,
            cost_amount: 0,
            income_budget: 0,
            income_amount: 0,
          }
        }
        if (lineItem.type === jobLineItemTypes.Cost) {
          phaseItems[lineItem.phase_code].cost_budget += lineItem.amount
          phaseItems[lineItem.phase_code].cost_amount += lineItem.amount_to_date
        } else {
          phaseItems[lineItem.phase_code].income_budget += lineItem.amount
          phaseItems[lineItem.phase_code].income_amount += lineItem.amount_to_date
        }
      })
      return Object.values(phaseItems)
    },
    transformData(data) {
      const phaseItems = this.phaseItems()
      return phaseItems.map(phaseItem => {
        const matchingPhase = data.find(item => item.attributes.phase_code === phaseItem.phase_code) || {}
        return {
          _localId: crypto.randomUUID(),
          phase_code: phaseItem.phase_code,
          cost_budget: phaseItem.cost_budget,
          income_budget: phaseItem.income_budget,
          cost_amount: phaseItem.cost_amount,
          income_amount: phaseItem.income_amount,
          job_id: phaseItem.job_id,
          description: '',
          ...(matchingPhase?.attributes || {}),
          meta: {
            authorizedToDelete: !!matchingPhase?.id
          }
        }
      })
    },
    async addOrUpdatePhaseTitle(params) {
      const row = params.data
      if (row.id) {
        await axios.put(`/restify/phase-titles/${row.id}`, {
          description: row.description,
          job_id: row.job_id,
          phase_code: row.phase_code,
        })
      } else {
        const { data } = await axios.post('/restify/phase-titles', {
          phase_code: row.phase_code,
          description: row.description,
          job_id: row.job_id,
        })
        params.data.id = data.id
        params.data.meta = {
          authorizedToDelete: true
        }
      }
    },
  }
}
</script>
