<template>
  <BaseDataTable
    v-bind="defaultAttributes"
    :dataLoading="dataLoading"
    :columns="columns"
    :data="getData"
    :total-rows="rowsLength"
    :has-summary="false"
    :compact="true"
    class="no-borders payroll-history"
    @force-pagination="forcePagination"
  >

    <template #report-header>
      <div class="md:grid print:grid grid-cols-2">
        <BaseReportHeader :header="data.header"/>
        <BaseDataTable
          v-if="!dataLoading && data?.items"
          :title="$t('Employees Working 12th Day of Month')"
          :columns="_12thDayColumns"
          :pagination="false"
          :compact="true"
          :showPagination="false"
          :data="[data.items]"
          class="-mt-4"
        />
      </div>
      <div
        v-if="!dataLoading && data?.items"
        class="md:grid print:grid grid-cols-2 my-2 font-normal"
      >
        <div class="flex space-x-2">
          <div class="font-semibold">
            {{ $t('Employer State Account #') }}:
          </div>
          <div>
            {{ data.items.state_employer_number }}
          </div>
        </div>
        <div class="flex space-x-2">
          <div class="font-semibold">
            {{ $t('Total Wages This Quarter') }}:
          </div>
          <div>
            {{ $formatPrice(data.items.sdi_wages) }}
          </div>
        </div>
      </div>
    </template>

    <template #subtotal="{subtotal}">
      <td/>
      <td>
        {{ $t('Total') }}
      </td>
      <td
        v-if="reportOptions.include !== QuarterlyStateUnemploymentOptions.Include.None"
        align="right"
      >
        <span v-if="reportOptions.include === QuarterlyStateUnemploymentOptions.Include.HoursWorked">
          {{ $formatHours(subtotal?.hours_worked) }}
        </span>
        <span v-else-if="reportOptions.include === QuarterlyStateUnemploymentOptions.Include.WeeksWorked">
          {{ subtotal?.weeks_worked }}
        </span>
      </td>
      <td align="right" class="top-border">
        {{ $formatPrice(subtotal?.sdi_wages) }}
      </td>
    </template>
  </BaseDataTable>
</template>
<script>
import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'
import {
  QuarterlyStateUnemploymentOptions
} from '@/modules/payroll/components/reports/util'

export default {
  extends: ReportTableWrapper,
  props: {
    dataLoading: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Object
    }
  },
  data() {
    return {
      QuarterlyStateUnemploymentOptions
    }
  },
  computed: {
    reportOptions() {
      const nameFormat = this.filters?.nameFormat || this.$route.query?.nameFormat || QuarterlyStateUnemploymentOptions.NameFormats.FirstLast
      const include = this.filters?.include || this.$route.query?.include || QuarterlyStateUnemploymentOptions.Include.None

      return {
        nameFormat,
        include,
      }
    },
    columns() {
      return [
        {
          label: this.$t('Soc Sec #'),
          prop: 'employee.ssn',
          component: 'FormattedSSN',
          minWidth: 80,
          maxWidth: 110,
        },
        {
          label: this.$t('Employee Name'),
          prop: 'employee',
          minWidth: 80,
          maxWidth: 110,
          formatter: (row, employee) => {
            if (!employee) return ''

            if (this.reportOptions?.nameFormat === QuarterlyStateUnemploymentOptions.NameFormats.LastFirst) {
              return [
                employee.last_name,
                employee.first_name,
                employee.middle_name,
              ].filter(Boolean).join(' ')
            }

            return [
              employee.first_name,
              employee.middle_name,
              employee.last_name,
            ].filter(Boolean).join(' ')
          }
        },
        {
          label: this.$t('Hours Worked'),
          prop: 'hours_worked',
          align: 'right',
          component: 'FormattedHours',
          minWidth: 80,
          maxWidth: 110,
          visible: this.reportOptions?.include === QuarterlyStateUnemploymentOptions.Include.HoursWorked,
        },
        {
          label: this.$t('Weeks Worked'),
          prop: 'weeks_worked',
          align: 'right',
          minWidth: 80,
          maxWidth: 110,
          visible: this.reportOptions?.include === QuarterlyStateUnemploymentOptions.Include.WeeksWorked,
        },
        {
          label: this.$t('Subject Wages'),
          prop: 'subject_wages',
          align: 'right',
          component: 'FormattedPrice',
          minWidth: 80,
          maxWidth: 110,
        },
      ]
    },
    _12thDayColumns() {
      return [
        {
          label: this.$t('1st Mo'),
          prop: 'workedOn12thDay[0]'
        },
        {
          label: this.$t('2nd Mo'),
          prop: 'workedOn12thDay[1]'
        },
        {
          label: this.$t('3rd Mo'),
          prop: 'workedOn12thDay[2]'
        },
      ]
    }
  },
  methods: {
    composeRows(data) {
      this.rows.push(...data.employees)

      this.rows.push({
        subtotal: {
          sdi_wages: data.sdi_wages,
          hours_worked: data.hours_worked,
          weeks_worked: data.weeks_worked,
        }
      })

      return this.rows
    },
  }
}
</script>
