import axios from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import { globalResources, storeMutations } from "@/components/form/util";
import { SPECIAL_PAY_UNITS_KEY } from '@/modules/payroll/components/rates/util'
import Cache from '@/utils/Cache';
import { cacheTTL } from "./cacheConfig";

// Filter special codes: multiply_by -> special-pay-units
function storeSpecialCodeForTimeCards(codes, type) {
  payrollState['withPayUnits'][type] = codes.filter(({ multiply_by, code }) => {
    return multiply_by === SPECIAL_PAY_UNITS_KEY
  })
}

export const payrollState = {
  [globalResources.CraftCodes]: [],
  [globalResources.SubTrades]: [],
  [globalResources.PayCodes]: [],
  [globalResources.BenefitCodes]: [],
  [globalResources.DeductionCodes]: [],
  [globalResources.DirectDepositCodes]: [],
  [globalResources.DepartmentCodes]: [],
  [globalResources.GeneralCodes]: [],
  [globalResources.TaxCodes]: [],
  [globalResources.StateTaxes]: [],
  [globalResources.LocalTaxes]: [],
  [globalResources.UnionCodes]: [],
  [globalResources.BillingRateTypes]: [],
  [globalResources.WTwoCodes]: [],
  [globalResources.AllCraftsAndLevels]: [],
  [globalResources.TimeOffPolicies]: [],
  [globalResources.TimeOffStatuses]: [],
  withPayUnits: {
    pays: [],
    benefits: [],
    deductions: [],
    taxes: [],
  }
};

export const payrollActions = {
  setDeductionCodes({ commit }, data) {
    commit(storeMutations.SetResource, { data, resource: globalResources.DeductionCodes })
    storeSpecialCodeForTimeCards(data, 'deductions')
    commit(storeMutations.SetResource, { data: payrollState.withPayUnits, resource: 'withPayUnits' })
  },
  setPayCodes({ commit }, data) {
    commit(storeMutations.SetResource, { data, resource: globalResources.PayCodes })
    storeSpecialCodeForTimeCards(data, 'pays')
    commit(storeMutations.SetResource, { data: payrollState.withPayUnits, resource: 'withPayUnits' })
  },
  setTaxCodes({ commit }, data) {
    commit(storeMutations.SetResource, { data, resource: globalResources.TaxCodes })
    storeSpecialCodeForTimeCards(data, 'taxes')
    commit(storeMutations.SetResource, { data: payrollState.withPayUnits, resource: 'withPayUnits' })
  },
  async getBillingRateTypes({ commit }) {
    const { data } = await Cache.getRequest('/restify/billing-rate-types?sort=id', {
      ttl: cacheTTL,
    })
    const codes = data.map(type => type.attributes)
    commit(storeMutations.SetResource, { data: codes, resource: globalResources.BillingRateTypes })
  },
  async getTimeOffStatuses({ commit }, employeeId) {
    const { data } = await Cache.getRequest('/restify/time-off-statuses', {
      params: {
        employee_id: employeeId,
      },
      ttl: cacheTTL,
    })
    const statuses = data.map(status => status.attributes)
    commit(storeMutations.SetResource, { data: statuses, resource: globalResources.TimeOffStatuses })
  }
};

export const payrollGetters = {
  getReservedPayCodes: (payrollState) => codes => {
    return payrollState[globalResources.PayCodes].filter(({ code }) => codes.includes(code))
  },
  getSpecialSourceDescription: payrollState => id => {
    const allCodes = payrollState[globalResources.PayCodes]
      .concat(payrollState[globalResources.BenefitCodes])
      .concat(payrollState[globalResources.TaxCodes])
      .concat(payrollState[globalResources.DeductionCodes])

    return allCodes.find(c => c.id === id)?.description
  }
};
