<template>
  <div class="flex-1">
    <TabStatusFilter
      v-model="currentStatus"
      :statuses="statuses"
    />
    <PurchaseOrderTable
      v-if="currentStatus !== resourceStatuses.Review"
      :status="currentStatus"
      :url-params="urlParams"
      :limit-max-height="false"
      @meta-fetch="$emit('meta-fetch', $event)"
    />
    <ReviewPurchaseOrders
      v-else
      :url-params="urlParams"
    />
  </div>
</template>
<script>
import { resourceStatuses } from '@/enum/enums'
import TabStatusFilter from "@/modules/common/components/TabStatusFilter.vue";
import PurchaseOrderTable from "@/modules/purchasing-inventory/components/purchase-orders/PurchaseOrderTable.vue";
import ReviewPurchaseOrders from "@/modules/purchasing-inventory/pages/purchase-orders/review-purchase-orders.vue";

export default {
  components: {
    ReviewPurchaseOrders,
    PurchaseOrderTable,
    TabStatusFilter,
  },
  props: {
    urlParams: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {
      resourceStatuses,
      currentStatus: resourceStatuses.All,
    }
  },
  computed: {
    statuses() {
      return [
        {
          label: this.$t('Pending'),
          value: resourceStatuses.Pending,
        },
        {
          label: this.$t('Open'),
          value: resourceStatuses.Open,
        },
        {
          label: this.$t('Closed'),
          value: resourceStatuses.Closed,
        },
        {
          label: this.$t('All'),
          value: resourceStatuses.All,
        },
        {
          label: this.$t('Review'),
          value: resourceStatuses.Review,
        },
      ]
    }
  },
}
</script>
