<template>
  <div>
    <base-form layout="vertical"
               :loading="loading"
               :cans-submit-form="$can('job_costing_progress_updating_store')"
               :saveText="$t('Apply')"
               @submit="onSubmit"
    >
      <div class="col-span-6">
        <JobSelect v-model="model.job_id"
                   id="job_id"
                   :rules="'required'"
        />
      </div>
      <div class="col-span-6 md:col-span-2">
        <entity-select v-model="model.phase_id"
                       url="/restify/phase-costs"
                       :label="$t('Phase Code')"
                       label-key="phase"
                       value-key="id"
                       clearable
        />
      </div>
      <div class="col-span-6 md:col-span-2">
        <entity-select v-model="model.code_id"
                       url="/restify/phase-costs"
                       :label="$t('Cost Code')"
                       label-key="cost"
                       value-key="id"
                       clearable
        />
      </div>
      <div class="col-span-6 md:col-span-2">
        <entity-select v-model="model.change_order_id"
                       url="/restify/phase-costs"
                       :label="$t('Change Order')"
                       label-key="cost"
                       value-key="id"
                       clearable
        />
      </div>
    </base-form>
    <SelectedLineItemsSummary
        v-if="summaries"
        :summaries="summaries"
        class="mt-4"
    />
    <SelectedLineItemsTable
        v-if="jobCostings"
        :job-costings="jobCostings"
        class="mt-4"/>
  </div>
</template>
<script>
  import axios from 'axios'
  import JobSelect from '@/components/select/entities/JobSelect'
  import SelectedLineItemsTable from '@/modules/job-costing/components/progress-updating/SelectedLineItemsTable'
  import SelectedLineItemsSummary from '@/modules/job-costing/components/progress-updating/SelectedLineItemsSummary'

  export default {
    components: {
      JobSelect,
      SelectedLineItemsTable,
      SelectedLineItemsSummary,
    },
    data() {
      return {
        loading: false,
        model: {
          job_id: undefined,
          phase_id: undefined,
          code_id: undefined,
          change_order_id: undefined,
        },
        summaries: false,
        jobCostings: false,
      }
    },
    methods: {
      resetForm() {
        this.model = {
          job_id: undefined,
          phase_id: undefined,
          code_id: undefined,
          change_order_id: undefined,
        }
      },
      async onSubmit() {
        try {
          this.summaries = false
          this.jobCostings = false

          this.loading = true
          const {
            data,
            meta,
          } = await axios.get(`/restify/line-items/${this.model.job_id}/progress-updating?related=jobCostings`)

          this.summaries = meta
          this.jobCostings = data
        } catch (e) {
          if (e.handled) {
            return
          }
          this.$error(this.$t('Something went wrong please try again.'))
        } finally {
          this.loading = false
        }
      },
    },
  }
</script>
