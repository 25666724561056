<template>
  <div>
    <ProofListing
        :entity="$t('Billing Payment')"
        :show-share="false"
        :urlParams="model"
        :addPostParams="beforePost"
        url="/restify/billing-payments/actions?action=create-billing-payments-proof-listing"
        post-url="/restify/billing-payments/actions?action=create-billing-payments"
        @data-fetch="data = $event"
        @success="onPosted"
    >

      <template #default="{loading}">

        <div class="grid grid-cols-8 gap-4 mt-4 print:hidden">
          <div class="col-span-8 md:col-span-2">
            <base-input v-model="model.reference_no"
                        :min="0"
                        :label="$t('Reference Date')"
                        id="reference">
            </base-input>
          </div>
        </div>

        <AgDataTable
            :data="mappedBillings"
            :columns="columns"
            :data-loading="loading"
            :pagination="false"
            :groupIncludeFooter="true"
            :groupDefaultExpanded="-1"
            :groupIncludeTotalFooter="true"
            :suppressAggFuncInHeader="true"
            :compact="true"
            :no-borders="true"
            :groupRowRendererParams="groupRowRendererParams"
            :get-row-id="params => params.data._localId"
            groupDisplayType="groupRows"
            dom-layout="autoHeight"
        />

        <div class="grid lg:grid-cols-2">
          <div/>
          <div>
            <h5 class="form-section-title">
              {{ $t('Account Summary') }}
            </h5>
            <ProofListingAccountSummary
                :data="data.account_summary"
                :loading="loading"
            />
          </div>
        </div>
      </template>

    </ProofListing>

  </div>
</template>
<script>
  import Cache from '@/utils/Cache'
  import capitalize from "lodash/capitalize";
  import ProofListing from '@/modules/common/components/proof-listing/ProofListing.vue'
  import ProofListingAccountSummary from '@/modules/common/components/ProofListingAccountSummary.vue'
  import ARPaymentsProofListingRowGroup
    from '@/modules/accounts-receivable/components/payments/ARPaymentsProofListingRowGroup.vue'
  import { StorageKey } from "@/modules/accounts-receivable/pages/payments/customerPaymentTypes";
  import { formatDate } from "@/plugins/dateFormatPlugin";
  import parseISO from 'date-fns/parseISO'

  import { useStorage } from '@vueuse/core'

  function parseSavedPayments(storagePayments) {
    let payments = []
    try {
      payments = storagePayments
      payments = payments.map(payment => {
        const noAmounts = payment.billings?.every(billing => billing.amount === 0 && billing.from_cash_on_deposit_amount === 0)
        const zeroAmount = noAmounts || payment.amount === 0
        let bank_id = zeroAmount ? null : payment.bank_id
        let number = zeroAmount ? null : payment.number

        if (!payment.billings?.length) {
          bank_id = payment.bank_id
          number = payment.number
        }

        return {
          ...payment,
          is_adjustment: payment.adjustments,
          bank_id,
          number,
          date: formatDate(parseISO(payment.date), 'yyyy-MM-dd'),
        }
      })
    } catch (err) {
      console.log(err)
    }
    return payments
  }

  export default {
    components: {
      ProofListing,
      ProofListingAccountSummary,
      ARPaymentsProofListingRowGroup,
    },
    setup() {
      const storagePayments = useStorage(StorageKey, [], sessionStorage)
      return {
        storagePayments,
      }
    },
    data() {
      let payments = parseSavedPayments(this.storagePayments)

      return {
        data: {},
        model: {
          payments,
          reference_no: this.$formatDate(new Date()),
        }
      }
    },
    computed: {
      groupRowRendererParams() {
        return {
          innerRenderer: 'ARPaymentsProofListingRowGroup',
          suppressCount: true,
          wrapText: true,
          autoHeight: true,
        }
      },
      mappedBillings() {
        const allBillings = []

        this.data.customers?.forEach(customer => {
          customer.payments.forEach(((payment, index) => {
            const key = payment?.check?.number + index?.toString()
            const { billings } = payment?.check || []

            if (billings?.length === 0 && payment?.check?.to_cash_on_deposit_amount) {
              allBillings.push(this.getDepositBilling(customer, index, key))
            }
            const billingsCount = billings?.length || 0
            allBillings.push(...billings?.map(billing => {
              const to_cash_on_deposit_amount = payment?.check?.to_cash_on_deposit_amount ? payment?.check?.to_cash_on_deposit_amount / billingsCount : 0
              return {
                _localId: key + billing.id,
                ...billing,
                check: payment.check,
                to_cash_on_deposit_amount,
                customer,
                p_type: key,
                [key]: payment,
              }
            }))
            const discountBillings = payment?.discount?.billings || []
            if (billingsCount === 0 && discountBillings.length > 0) {
              allBillings.push(...discountBillings.map(billing => {
                return {
                  _localId: key + billing.id,
                  ...billing,
                  check: payment.check,
                  to_cash_on_deposit_amount: 0,
                  customer,
                  p_type: key,
                  [key]: payment,
                }
              }))
            }
          }))
        })

        return allBillings
      },
      columns() {
        return [
          {
            headerName: this.$t('Customer'),
            field: 'customer.name',
            rowGroup: true,
            hide: true,
          },
          {
            headerName: this.$t('Type'),
            field: 'p_type',
            rowGroup: true,
            hide: true,
          },
          {
            headerName: this.$t('Customer Invoice'),
            children: [
              {
                headerName: this.$t('Number'),
                field: 'number',
                minWidth: 80,
                maxWidth: 120,
                component: 'EntityLink',
                redirectTo: '/accounts-receivable/billings/progress/{ID}/view',
              },
              {
                headerName: this.$t('Date'),
                field: 'date',
                minWidth: 100,
                maxWidth: 100,
                component: 'FormattedDate',
              },
            ],
          },
          {
            headerName: this.$t('Invoice Balance'),
            children: [
              {
                headerName: this.$t('Prior'),
                field: 'prior_amount',
                minWidth: 90,
                maxWidth: 200,
                component: 'FormattedPrice',
                align: 'right',
                cellRendererParams: {
                  hideZero: true,
                },
              },
              {
                headerName: this.$t('Open'),
                field: 'open_amount',
                minWidth: 130,
                maxWidth: 200,
                align: 'right',
                component: 'FormattedPrice',
                cellRendererParams: {
                  hideZero: true,
                },
                cellRenderer: params => {
                  const { footer, key } = params.node

                  if (!footer) {
                    return this.$formatPrice(params.value)
                  }

                  if (['discount', 'check'].includes(key)) {
                    let text = capitalize(key)
                    if (text === 'Check') {
                      text = 'Payment'
                    }
                    return `${text} Totals:`
                  } else if (key) {
                    return this.$t('Customer Totals:')
                  }

                  return this.$t('Grand Totals:')
                },
              },
            ]
          },
          {
            headerName: '',
            children: [
              {
                headerName: this.$t('Discount'),
                field: 'discount_amount',
                minWidth: 100,
                maxWidth: 150,
                align: 'right',
                component: 'FormattedPrice',
                cellRendererParams: {
                  hideZero: true,
                },
                aggFunc: 'sum',
              },
              {
                headerName: this.$t('Account'),
                field: 'discount_account',
                minWidth: 60,
                maxWidth: 90,
                cellRendererParams: params => {
                  if (!params.data) {
                    return
                  }
                  return {
                    showDescription: false,
                    target: '_blank',
                    number: params.data?.discount_account,
                  }
                },
                component: 'AccountLink',
              },
            ],
          },
          {
            headerName: this.$t('Applied To'),
            children: [
              {
                headerName: this.$t('XFER From Deposit'),
                field: 'from_cash_on_deposit_amount',
                minWidth: 100,
                maxWidth: 150,
                align: 'right',
                component: 'FormattedPrice',
                cellRendererParams: {
                  hideZero: true,
                },
                aggFunc: 'sum',
              },
              {
                headerName: this.$t('Deposit'),
                field: 'to_cash_on_deposit_amount',
                minWidth: 100,
                maxWidth: 150,
                align: 'right',
                component: 'FormattedPrice',
                cellRendererParams: {
                  hideZero: true,
                },
                aggFunc: 'sum',
              },
              {
                headerName: this.$t('Payment'),
                field: 'applied_amount',
                minWidth: 100,
                maxWidth: 200,
                align: 'right',
                component: 'FormattedPrice',
                cellRendererParams: {
                  hideZero: true,
                },
                aggFunc: 'sum',
              }
            ],
          },
        ]
      },
    },
    methods: {
      getDepositBilling(customer, index, key) {
        const payment = customer.payments[index]
        return {
          _localId: crypto.randomUUID(),
          customer,
          p_type: key,
          check: payment.check,
          [key]: payment,
          amount: 0,
          from_cash_on_deposit_amount: 0,
          prior_amount: 0,
          discount_amount: 0,
          to_cash_on_deposit_amount: payment?.check?.to_cash_on_deposit_amount,
          discount_account: null,
          number: 'Deposit',
        }
      },
      beforePost(payload) {
        payload.fiscal_year = payload.fiscal_year || this.$currentFiscalYear
        payload.period = payload.period || this.$currenPeriod
        payload.reference_no = this.model.reference_no
        payload.payments = this.model.payments
      },
      async onPosted(data) {
        const journalId = data?.data?.journal_id

        if (journalId) {
          this.clearStorage()
          Cache.removeForEntity('customer')
          await this.$store.dispatch('globalLists/getCustomers')
        } else {
          this.$error(this.$t('Could not post the payments.'))
        }
      },
      clearStorage() {
        this.storagePayments = []
      },
    },
  }
</script>
