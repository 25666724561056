import get from "lodash/get";
import sumBy from "lodash/sumBy";
import meanBy from "lodash/meanBy";

export function getBudget(entry) {
  const lineItemId = entry?.attributes?.addl_source_id
  const budgets = get(entry, 'relationships.addlSource.relationships.budgets', [])
  const budget = budgets.find(b => b?.attributes?.line_item_id === lineItemId)

  return budget?.attributes || {}
}

export function getAmountToDate(entry) {
  if (entry.attributes?.meta?.posted_amount_to_date !== undefined) {
    return entry.attributes.meta?.posted_amount_to_date
  }
  return getBudget(entry)?.amount_to_date
}

export function getCompletedPercentage(entry) {
  if (entry.attributes.meta?.posted_completion !== undefined) {
    return entry.attributes.meta?.posted_completion
  }
  const budget = getBudget(entry)
  const completed = getAmountToDate(entry)
  let totalAmount = completed + entry?.attributes?.gross_amount
  return totalAmount / budget?.amount * 100
}

export function getTotalCompleted(row) {
  let previouslyCompleted = getAmountToDate(row)
  const thisPeriod = row.attributes?.gross_amount || 0
  return thisPeriod + previouslyCompleted
}

export function getTotals(items) {
  const budget = sumBy(items, (row) => getBudget(row)?.amount)
  const completed = sumBy(items, getAmountToDate)
  const completed_no_retention = sumBy(items, (row) => {
    const budget = getBudget(row)
    const completed = getAmountToDate(row)
    return completed - budget?.retention_amount
  })
  const completed_percentage = meanBy(items, getCompletedPercentage)
  const total_completed = sumBy(items, getTotalCompleted)
  const amount = sumBy(items, 'attributes.gross_amount')
  const amount_no_retention = sumBy(items, row => {
    return row.attributes.gross_amount - row.attributes.retention_amount
  })
  const retention = sumBy(items, 'attributes.retention_amount')

  return {
    budget,
    completed,
    completed_no_retention,
    completed_percentage,
    total_completed,
    amount,
    amount_no_retention,
    retention
  }
}
