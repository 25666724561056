<template>
  <JobLineItemProgressUpdate
      :type="costTypes.Cost"
      :data="lineItem"
  />
</template>
<script>
  import { costTypes } from '@/enum/enums'
  import JobLineItemProgressUpdate from "@/modules/job-costing/components/line-items/JobLineItemProgressUpdate";

  export default {
    components: {
      JobLineItemProgressUpdate,
    },
    data() {
      return {
        costTypes,
      }
    },
    computed: {
      lineItem() {
        return this.$store.state.jobCosting.currentLineItem
      },
      lineItemLoading() {
        return this.$store.state.jobCosting.lineItemLoading
      },
    },
  }
</script>
