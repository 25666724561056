<template>
  <BaseSelect
    v-bind="$attrs"
    v-on="$listeners"
    :id="$attrs.id || 'employee-select'"
    :name="$attrs.name || $t('Employee')"
    :placeholder="$attrs.placeholder || $t('Select Employee')"
    :label="$attrs.label !== undefined ? $attrs.label : $t('Employee')"
    :add-entity="addEntity"
    :edit-entity="editEntity"
    :set-default-option="editEntity"
    :add-label="addLabel"
    :options="options"
    :label-format="formatLabel"
    :table-columns="tableColumns"
    :dataFilters="dataFilters"
    :resource-name="globalResources.Employees"
    :value-key="valueKey"
    :label-key="labelKey"
    :initial-value="initialValue"
  />
</template>
<script>
import i18n from '@/i18n'
import { globalResources } from "@/components/form/util";

export default {
  name: 'EmployeeSelect',
  props: {
    addEntity: {
      type: Boolean,
      default: false,
    },
    editEntity: {
      type: Boolean,
      default: false,
    },
    displayPayColumn: {
      type: Boolean,
      default: false,
    },
    valueKey: {
      type: String,
      default: 'id',
    },
    labelKey: {
      type: String,
      default: 'code',
    },
    addLabel: {
      type: String,
      default: i18n.t('Add new Employee'),
    },
    initialValue: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      globalResources,
      dataFilters: {
        showActive: true,
      },
    }
  },
  computed: {
    options() {
      const employees =  this.$store.state.globalLists.employees || []
      if (this.dataFilters.showActive) {
        return employees.filter(e => !['inactive', 'deceased'].includes(e.status))
      }
      return employees.map(employee => ({
        ...employee,
        isInactive: ['inactive', 'deceased'].includes(employee.status),
      }))
    },
    tableColumns() {
      const columns = [
        {
          minWidth: 80,
          maxWidth: 150,
          name: this.$t('Code'),
          prop: 'code',
        },
        {
          minWidth: 130,
          maxWidth: 130,
          name: this.$t('Name'),
          prop: 'name',
        },
        {
          minWidth: 80,
          maxWidth: 100,
          name: this.$t('Pay'),
          prop: 'salary_amount',
          component: 'FormattedPrice',
          showIf: () => this.displayPayColumn,
        },
        {
          minWidth: 130,
          maxWidth: 130,
          name: this.$t('Status'),
          prop: 'status',
          component: 'Status',
          filter: {
            type: 'switch',
            prop: 'showActive',
            tooltip: this.$t('Only show active employees'),
            onChange: (value) => {
              this.dataFilters.showActive = value
            },
          },
        },
      ]

      return columns.filter(c => !c.showIf || c.showIf())
    },
  },
  methods: {
    formatLabel(employee) {
      const { code, name } = employee
      return !name ? code : `${code} (${name})`
    },
  },
}
</script>
