<template>
  <BaseDataTable
    v-bind="defaultAttributes"
    :columns="columns"
    :data="getData"
    :total-rows="rowsLength"
    :has-summary="false"
    :compact="true"
    class="no-borders"
    :customizeLastSubtotalRow="false"
    @force-pagination="forcePagination"
  >

    <template #report-header>
      <BaseReportHeader :header="data.header" />
    </template>

    <template #header="{ row }">
      <div
        v-if="row.header.employee"
        class="flex items-inline-base"
      >
        <EmployeeLink
          :data="row.header?.employee"
          :show-preview="false"
          class="min-w-[400px]"
        />
      </div>
      <div
        v-else-if="row.header.fiscalPeriod"
        class="flex space-x-2 items-inline-base"
      >
        <div>
          {{ $t('Fiscal Period') }}:
        </div>
        <div>
          {{ row.header.fiscalPeriod.period }} {{ row.header.fiscalPeriod.year }}
        </div>
      </div>
    </template>

    <template #employee="{ row }">
      <EmployeeLink
        v-if="row.employee"
        :data="row.employee"
        :show-preview="false"
        class="min-w-[400px]"
      />
    </template>

    <template #subtotal="{ subtotal }">
      <td> {{ subtotal.title }} </td>
      <td align="right">
        {{ $formatPrice(subtotal?.value) }}
      </td>
      <td :colspan="columns.length - 2" />
    </template>

    <template #html-row="{ htmlData }">
      <tr class="font-semibold">
        <td align="left" style="vertical-align: top;">
          <div class="pl-2">
            <div
              v-for="line in htmlData.lines"
              :key="line.title"
            >
              {{ line.title }}
            </div>
            <div class="border-t border-gray-300 my-2" />
            <div>
              {{ $t('Grand Total Net Pay') }}
            </div>
          </div>
        </td>
        <td align="right">
          <div
            v-for="line in htmlData.lines"
            :key="line.title"
          >
            <div>{{ $formatPrice(line.value) }}</div>
          </div>
          <div class="border-t border-gray-300 my-2" />
          <div>{{ $formatPrice(htmlData.net_pay) }}</div>
        </td>
        <td :colspan="columns.length - 2" />
      </tr>
    </template>

  </BaseDataTable>
</template>
<script>
import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'
import { PayrollPaymentRegisterOptions } from '@/modules/payroll/components/reports/util'
import { get } from 'lodash'

export default {
  extends: ReportTableWrapper,
  computed: {
    columns() {
      return [
        {
          label: this.$t('Payment <br> No.'),
          prop: 'payment_number',
          align: 'left',
          minWidth: 80,
          maxWidth: 100,
        },
        {
          label: this.$t('Payment(D/D) <br> Amount'),
          prop: 'payment_amount',
          align: 'right',
          minWidth: 80,
          maxWidth: 100,
          component: 'FormattedPrice',
        },
        {
          label: this.$t('D/D Code'),
          prop: 'dd_code',
          align: 'left',
          minWidth: 80,
          maxWidth: 100,
        },
        {
          label: this.$t('Payment <br> Date'),
          prop: 'payment_date',
          align: 'left',
          minWidth: 80,
          maxWidth: 120,
          component: 'FormattedDate',
        },
        {
          label: this.$t('Period <br> End Date'),
          prop: 'period_end_date',
          align: 'left',
          minWidth: 80,
          maxWidth: 120,
          component: 'FormattedDate',
        },
        {
          label: this.$t('Calendar <br> Q/Year'),
          prop: 'calendar_quarter_year',
          align: 'left',
          minWidth: 80,
          maxWidth: 120,
        },
        {
          label: this.$t('Fiscal <br> Per/Year'),
          prop: 'fiscal_period_year',
          align: 'left',
          minWidth: 80,
          maxWidth: 120,
        },
        {
          label: this.$t('ST'),
          prop: 'state',
          align: 'left',
          minWidth: 40,
          maxWidth: 60,
        },
        {
          label: this.$t('Employee'),
          prop: 'employee',
          align: 'left',
          minWidth: 80,
          maxWidth: 200,
        },
      ]
    },
    sortBy() {
      return this.options.sort_by
    },
    hasSubtotals() {
      return [
        PayrollPaymentRegisterOptions.SortBy.Employee,
        PayrollPaymentRegisterOptions.SortBy.FiscalPeriods,
      ].includes(this.sortBy)
    }
  },
  methods: {
    addHeaderRow(rowGroup) {
      const handlers = {
        [PayrollPaymentRegisterOptions.SortBy.Employee]: () => {
          this.rows.push({
            header: {
              employee: rowGroup.employee
            }
          })
        },
        [PayrollPaymentRegisterOptions.SortBy.FiscalPeriods]: () => {
          const period = get(rowGroup, 'payments[0].journal.period')
          const year = get(rowGroup, 'payments[0].journal.fiscal_year')

          this.rows.push({
            header: {
              fiscalPeriod: {
                period,
                year,
              }
            }
          })
        },
      }

      handlers[this.sortBy]?.()
    },
    addGroupRows(rowGroup) {
      const {
        employee: groupEmployee,
        payments: paymentGroups,
        state: groupState
      } = rowGroup

      paymentGroups.forEach(paymentGroup => {
        const {
          payment,
          journal,
          ...paymentDetails
        } = paymentGroup

        const employee = groupEmployee || paymentGroup.employee
        const state = groupState || paymentGroup.state

        const dd_codes = Object.keys(paymentDetails?.direct_deposit || {})

        if (dd_codes?.length) {
          dd_codes.forEach((dd_code, index) => {
            const amount = paymentDetails.direct_deposit[dd_code]
            this.addPaymentRow(payment, journal, state, employee, amount, dd_code, index)
          })
        } else {
          const amount = paymentDetails.check_pay
          this.addPaymentRow(payment, journal, state, employee, amount)
        }
      })
    },
    addPaymentRow(payment, journal, state, employee, amount, dd_code = null, index = null) {
      if (index && index > 0) {
        const row = {
          dd_code,
          payment_amount: amount,
        }

        this.rows.push(row)
        return
      }

      const quarter = payment?.quarter
      const year = journal?.fiscal_year

      const row = {
        payment_number: payment?.payment_number,
        payment_amount: amount,
        dd_code,
        payment_date: payment?.payment_date,
        period_end_date: payment?.period_end_date,
        calendar_quarter_year: `${quarter} ${year}`,
        fiscal_period_year: `${journal?.period} ${year}`,
        state: state?.code,
        employee,
      }

      this.rows.push(row)
    },
    addSubtotals(rowGroup) {
      if (!this.hasSubtotals) {
        return
      }

      const total = rowGroup.total

      const checksTitles = {
        [PayrollPaymentRegisterOptions.SortBy.Employee]: this.$t('Employee Total Checks'),
        [PayrollPaymentRegisterOptions.SortBy.FiscalPeriods]: this.$t('Fiscal Period Total Checks'),
      }

      this.rows.push({
        subtotal: {
          title: checksTitles[this.sortBy],
          value: total.check_pay
        }
      })

      for (const dd_code in total.direct_deposits) {
        this.rows.push({
          subtotal: {
            title: dd_code,
            value: total.direct_deposits[dd_code]
          }
        })
      }
    },
    addGrandTotals() {
      const data = []

      const grandTotals = this.data.grand_totals

      data.push({
        title: this.$t('Grand Total Checks'),
        value: grandTotals.check_pay
      })

      for (const dd_code in grandTotals.direct_deposits) {
        data.push({
          title: dd_code,
          value: grandTotals.direct_deposits[dd_code]
        })
      }

      this.rows.push(
        {
          htmlData: {
            lines: data,
            net_pay: grandTotals.net_pay,
          }
        },
      )
    },
    composeRows(data) {
      data.forEach(rowGroup => {
        this.addHeaderRow(rowGroup)

        this.addGroupRows(rowGroup)

        this.addSubtotals(rowGroup)
      })

      this.addGrandTotals()

      return this.rows
    },
  }
}
</script>
