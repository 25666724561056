import { computed, nextTick, onMounted, ref, watch } from 'vue'
import { useElementSize } from '@vueuse/core'

const defaultOptions = {
  minHeight: 300,
  initialOffset: 80,
  extraOffset: 10,
}
export function useMaxAvailableHeight(element, { minHeight, initialOffset, extraOffset } = defaultOptions) {
  let yOffset = ref(initialOffset)
  const { height } = useElementSize(element)

  function calculateYOffset() {
    const boundingRect = element.value?.getBoundingClientRect()
    if (!boundingRect) {
      return
    }
    yOffset.value = boundingRect.top
  }

  const maxHeight = computed(() => {
    const offset = yOffset.value + extraOffset
    return `calc(100vh - ${offset}px)`
  })

  const styles = computed(() =>{
    const height = `max(${maxHeight.value}, ${minHeight}px)`
    return {
      maxHeight: height,
    }
  })

  watch(height, async () => {
    await nextTick()
    calculateYOffset()
  })

  onMounted(() => {
    calculateYOffset()
  })

  return {
    styles,
    maxHeight,
  }
}
