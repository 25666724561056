<template>
  <base-form
    ref="form"
    v-bind="$attrs"
    :save-text="saveText"
    :loading="loading"
    :show-cancel="showCancel"
    :auto-height="true"
    layout="modal"
    @cancel="$emit('cancel')"
    @submit="updateAddress"
  >
    <div class="col-span-6 md:col-span-3">
      <base-input v-model="model.name"
                  :label="$t('Display Name')"
                  :tip="$t('This is the name that will be displayed on the address list and in the address dropdowns')"
                  :placeholder="$t('Address Name')"/>
    </div>
    <div class="col-span-6 md:col-span-2 lg:col-span-1">
      <base-select
        v-if="data"
        v-model="model.type"
        :label="$t('Type')"
        :options="typeOptions"
      />
    </div>
    <div v-if="requireEntity" class="col-span-6 md:col-span-2">
      <JobSelect
        v-if="entity === RestifyResources.Jobs"
        v-model="model.entity_id"
        @entity-change="onJobChange"
      />
      <EmployeeSelectNew
        v-if="entity === RestifyResources.Employees"
        v-model="model.entity_id"
        @entity-change="onEmployeeChange"
      />
    </div>
    <div class="col-span-6 mb-4">
      <base-switch v-model="showCoordinates" :label-info="$t('Enter Coordinates')"/>
    </div>
    <div v-show="showCoordinates" class="col-span-6 md:col-span-3">
      <base-input
        v-model="model.lat"
        :label="$t('Latitude')"
        :tip="$t('Enter the latitude of the address')"
        :placeholder="$t('Latitude')"
        @blur="onCoordinatesBlur"
      />
    </div>
    <div v-show="showCoordinates" class="col-span-6 md:col-span-3">
      <base-input
        v-model="model.long"
        :label="$t('Longitude')"
        :tip="$t('Enter the longitude of the address')"
        :placeholder="$t('Longitude')"
        @blur="onCoordinatesBlur"
      />
    </div>
    <div class="col-span-6 md:col-span-3 mb-4">
      <BaseButton variant="primary-light" @click="showMapsDialog = true">
        <MapPinIcon class="w-4 h-4 mr-2"/>
        <span>{{$t('Pick Location on Map')}}</span>
      </BaseButton>
    </div>
    <GoogleMapsDialog
      v-if="showMapsDialog"
      :open.sync="showMapsDialog"
      @confirm="onCoordinatesConfirm"
    />
    <div class="col-span-6">
      <base-textarea v-model="model.address_1"
                     :label="$t('Primary Address 1')"
                     :placeholder="$t('Address 1')"
                     :rows="2"
                     :rules="hasCoordinates ? undefined : 'required'"
                     id="address_1"/>
    </div>
    <div class="col-span-6">
      <base-textarea v-model="model.address_2"
                     :label="$t('Primary Address 2')"
                     :placeholder="$t('Address 2')"
                     :rows="1"
                     id="address_2"/>
    </div>
    <div class="col-span-6 md:col-span-2">
      <base-input v-model="model.city"
                  :label="$t('City')"
                  :rules="hasCoordinates ? 'max:45' : 'required|max:45'"
                  placeholder="City"
                  id="city"/>
    </div>
    <div class="col-span-6 md:col-span-2">
      <state-select v-model="model.state"
                    :label="$t('State')"
                    :rules="hasCoordinates ? 'max:2' : 'required|max:2'"
                    placeholder="State"
                    id="state"/>
    </div>
    <div class="col-span-6 md:col-span-2">
      <base-input
        v-model="model.postal_code"
        :label="$t('Postal Code')"
        placeholder="Postal Code"
        id="postal_code"
        :rules="hasCoordinates ? 'zipCode' : 'required|zipCode'"
        @blur="onPostalCodeBlur"
      />
    </div>
    <div class="col-span-6">
      <base-checkbox
        v-if="data"
        v-model="model.primary"
        :label="$t('Primary')"
        id="primary"
      />
    </div>
    <div class="col-span-6">
      <base-checkbox
        v-if="data"
        v-model="model.active"
        :label="$t('Active')"
        id="active"
      />
    </div>

  </base-form>
</template>
<script>
import axios from 'axios'
import { addressTypes, getAddressableType } from '@/enum/enums'
import { RestifyResourceLabels, RestifyResources } from "@/components/form/util";
import { MapPinIcon } from 'vue-feather-icons'
import GoogleMapsDialog from "@/components/maps/GoogleMapsDialog.vue";
import { getAddressFromCoordinates } from "@/utils/addressUtils";
export default {
  components: {
    GoogleMapsDialog,
    MapPinIcon
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    entityId: {
      type: [String, Number],
      default: '',
    },
    showCancel: {
      type: Boolean,
      default: true,
    },
    primary: {
      type: Boolean,
      default: true,
    },
    resource: {
      type: Object,
      default: () => ({}),
    },
    entity: {
      type: String,
      default: 'companies',
    },
    addressType: {
      type: String,
      default: addressTypes.Billing,
    },
    saveText: String,
    requireEntity: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showMapsDialog: false,
      loading: false,
      showCoordinatesSwitch: false,
      RestifyResources,
      model: {
        entity_id: this.entityId || null,
        type: this.addressType,
        primary: this.primary,
        active: true,
        postal_code: '',
        address_1: '',
        address_2: '',
        city: '',
        state: '',
        name: '',
        lat: null,
        long: null,
      },
      typeOptions: [
        {
          label: this.$t('Job'),
          value: addressTypes.Job,
        },
        {
          label: this.$t('Billing'),
          value: addressTypes.Billing,
        },
        {
          label: this.$t('Business'),
          value: addressTypes.Business,
        },
        {
          label: this.$t('Home'),
          value: addressTypes.Home,
        },
        {
          label: this.$t('Headquarters'),
          value: addressTypes.Headquarters,
        },
        {
          label: this.$t('Shipping'),
          value: addressTypes.Shipping,
        },
        {
          label: this.$t('Warehouse'),
          value: addressTypes.Warehouse,
        },
      ],
    }
  },
  computed: {
    showCoordinates:{
      get() {
        return !!this.model.lat || this.showCoordinatesSwitch
      },
      set(value) {
        this.showCoordinatesSwitch = value
      }
    },
    hasCoordinates() {
      return !!this.model.lat && !!this.model.long
    }
  },
  watch: {
    data: {
      immediate: true,
      handler(value) {
        if (!value) {
          return this.setDefaultProps()
        }
        this.model = {
          ...this.model,
          ...value.attributes,
          index: value.index,
        }
        if (this.model.lat && this.model.long) {
          this.showCoordinatesSwitch = true
        }
      },
    },
    'resource.code'() {
      this.setDefaultProps()
    },
    'resource.number'() {
      this.setDefaultProps()
    },
    'resource.abbr'() {
      this.setDefaultProps()
    },
  },
  methods: {
    isCreate() {
      return !this.entityId && !this.model.entity_id
    },
    async updateAddress() {
      if (this.isCreate()) {
        return this.$emit('on-add', this.model)
      }

      try {
        this.loading = true
        const entityId = this.model.entity_id || this.$route.params.id || this.entityId
        const requestData = {
          ...this.model,
          addressable_type: getAddressableType(this.entity),
          addressable_id: entityId,
        }
        if (this.model.id) {
          await axios.put(`/restify/${this.entity}/${entityId}/addresses/${this.model.id}`, requestData)
          this.$success(this.$t('Address updated'))
          this.$emit('cancel')
        } else {
          const { data } = await axios.post(`/restify/${this.entity}/${entityId}/addresses`, requestData)
          this.$success(this.$t('Address created'))
          this.$emit('save', data)
        }
      } catch (err) {
        console.warn(err)
        this.$error(this.$t('Could not update the address'))
      } finally {
        this.loading = false
      }
    },
    setDefaultProps() {
      const defaultsByEntity = {
        jobs: {
          name: `${this.resource.number || ''} Address`,
          type: addressTypes.Job,
        },
        vendors: {
          name: `${this.resource.code || ''} Address`,
          type: addressTypes.Business,
        },
        employees: {
          name: `${this.resource.code || ''} Address`,
          type: addressTypes.Home,
        },
        customers: {
          name: `${this.resource.code || ''} Address`,
          type: addressTypes.Business,
        },
        companies: {
          name: `${this.resource.abbr || ''} Address`,
          type: addressTypes.Headquarters,
        },
        banks: {
          name: `${this.resource.initials || ''} Address`,
          type: addressTypes.Business,
        },
        default: {
          name: this.$t('Address Name'),
          type: addressTypes.Warehouse,
        },
      }

      const defaults = defaultsByEntity[this.entity] || defaultsByEntity.default

      this.model = {
        ...this.model,
        ...defaults,
      }
    },
    onJobChange(job) {
      if (this.model.id) {
        return
      }
      this.model.name = `Job ${job?.attributes?.number || ''}`
    },
    onEmployeeChange(employee) {
      if (this.model.id) {
        return
      }
      this.model.name = `Employee ${employee?.code || ''}`
    },
    assignName() {
      if (this.model.id) {
        return
      }
      const resourceName = RestifyResourceLabels[this.entity] || ''
      const name = this.data ? this.data?.attributes?.name : this.resource.number
      this.model.name = `${resourceName} ${name || ''} Address`
    },
    async onPostalCodeBlur() {
      if (!this.isCreate()) {
        return
      }
      const valid = await this.$refs.form?.validate()
      if (!valid) {
        return
      }
      await this.updateAddress()
    },
    async setAddressFromCoordinates() {
      try {
        const address = await getAddressFromCoordinates(this.model.lat, this.model.long)
        this.model = {
          ...this.model,
          ...address,
        }
      } catch (err) {
        this.$error(this.$t('Could not get address from coordinates'))
      }
    },
    async onCoordinatesBlur() {
      const {lat, long} = this.model
      if (!lat || !long) {
        return
      }
      await this.setAddressFromCoordinates()
    },
    async onCoordinatesConfirm(event) {
      if(!event?.lat) {
        return
      }
      this.model.lat = event.lat()
      this.model.long = event.lng()
      this.showMapsDialog = false
      await this.setAddressFromCoordinates()
    }
  },
  mounted() {
    this.assignName()
  }
}
</script>
