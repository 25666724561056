<template>
  <div class="purchase-order-summary mb-2">
    <el-collapse
      v-model="activeGroups"
      class="col-span-2 xl:col-span-1">
      <base-collapse-section
        name="summary"
        wrapper-classes="p-2 print:p-0"
        has-background
      >
        <template #title>
          <div class="flex flex-1 items-center justify-between po-form-header">
            <div class="flex items-center">
              <span class="mx-2 print:mx-0 capitalize">
                {{ $t('Committed Cost Totals') }}
              </span>
            </div>
            <div v-show="isCollapse('summary')"
                 class="flex items-center">
              <div class="summary">
                <span class="mr-1">{{$t('Purchase')}}</span> {{ $formatPrice(summary.committed_po_amount) }}
              </div>
              <div class="summary">
                <span class="mr-1">{{$t('Invoiced')}}</span> {{ $formatPrice(summary.invoiced_po_amount) }}
              </div>
              <div class="summary">
                <span class="mr-1">{{$t('Remaining')}}</span> {{ $formatPrice(summary.remaining_po_amount) }}
              </div>
            </div>
          </div>
        </template>
        <base-data-table
          v-bind="$attrs"
          :columns="columns"
          :data="tableData"
          :compact="true"
          :show-pagination="false"
        >
          <template #remaining_amount="{row}">
            {{ $formatPrice(row.remaining_amount) }}
          </template>
        </base-data-table>
      </base-collapse-section>
    </el-collapse>
  </div>
</template>
<script>
export default {
  props: {
    summary: {
      Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      activeGroups: [],
    }
  },
  computed: {
    tableData() {
      return [this.summary]
    },
    columns() {
      return [
        {
          label: this.$t('1 <br> Budget'),
          prop: 'amount',
          align: 'right',
          component: 'FormattedPrice',
          minWidth: 80,
          maxWidth: 100,
        },
        {
          label: this.$t('2 <br> Purchase Commitments'),
          prop: 'committed_po_amount',
          align: 'right',
          component: 'FormattedPrice',
          minWidth: 80,
          maxWidth: 100,
        },
        {
          label: this.$t('3 <br> Invoiced Commitments'),
          prop: 'invoiced_po_amount',
          align: 'right',
          component: 'FormattedPrice',
          minWidth: 80,
          maxWidth: 100,
        },
        {
          label: this.$t('4 <br>  Remaining Commitments'),
          prop: 'remaining_po_amount',
          align: 'right',
          component: 'FormattedPrice',
          minWidth: 80,
          maxWidth: 100,
        },
        {
          label: this.$t('5 <br> Actual Cost To-Date'),
          prop: 'amount_to_date',
          align: 'right',
          component: 'FormattedPrice',
          minWidth: 80,
          maxWidth: 100,
        },
        {
          label: this.$t('6 <br> Remaining <br> (1 - 4 - 5)'),
          prop: 'remaining_amount',
          align: 'right',
          component: 'FormattedPrice',
          minWidth: 80,
          maxWidth: 100,
        },
      ]
    }
  },
  methods: {
    isCollapse(type) {
      return !this.activeGroups.includes(type)
    },
  },
}
</script>
<style lang="scss">
.purchase-order-summary {
  .el-collapse {
    border-bottom: 0;
  }

  div.summary {
    @apply mx-1 text-sm font-semibold bg-gray-50 px-4 rounded-md;

    &:last-child {
      @apply bg-primary-50 font-bold;
    }
  }
}
</style>
