<template>
  <div class="import-data mt-4">
    <ImportForm v-bind="importSettings"
                @success="redirectToList"
    >
      <div class="col-span-6 md:col-span-1">
        <base-input :label="$t('Fiscal Year')">
          <template v-slot:container>
            <fiscal-year-select v-model="model.year"
                                class="w-full"
                                id="fiscal_year"
            />
          </template>
        </base-input>
      </div>
      <div class="col-span-6 md:col-span-1">
        <label class="block text-sm font-medium leading-5 text-gray-700 mb-3">
          {{ $t('Update matching accounts') }}
        </label>
        <base-switch v-model.number="model.overwrite"
                     id="overwrite"
        />
      </div>
    </ImportForm>
  </div>
</template>
<script>
  import { getInitialMapping } from "@/modules/payroll/utils/timecardImportColumns";
  import ImportForm from "@/modules/common/components/import/ImportForm";
  import PeriodEndDatePicker from "@/modules/payroll/components/timecard/PeriodEndDatePicker";
  import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";
  import { accountTypes, restrictionOptions } from "@/enum/account";

  const colMappings = {
    Number: {
      value: 'number',
      label: 'Number',
      alternativeLabels: ['Account'],
      required: true,
    },
    Description: {
      value: 'description',
      label: 'Description',
      alternativeLabels: [],
    },
    Type: {
      value: 'type',
      label: 'Type',
      alternativeLabels: ['Account Type'],
      required: true,
      cellEditor: cellEditors.BaseSelect,
      cellEditorParams: {
        options: accountTypes,
      }
    },
    Restrictions: {
      value: 'restrictions',
      label: 'Restrictions',
      alternativeLabels: ['Account Restrictions'],
      cellEditor: cellEditors.BaseSelect,
      cellEditorParams: {
        options: restrictionOptions,
      }
    },
  }
  export const options = [
    colMappings.Number,
    colMappings.Description,
    colMappings.Restrictions,
    colMappings.Type,
  ]


  export default {
    components: {
      ImportForm,
      PeriodEndDatePicker,
    },
    data() {
      return {
        model: {
          overwrite: false,
          year: null,
        },
        importSettings: {
          url: '/restify/accounts/import',
          columnOptions: options,
          columnMappings: colMappings,
          initialMapping: getInitialMapping(options),
          type: 'accounts-import',
          transformRequestData: this.transformRequestData,
          resource: 'accounts',
        },
      }
    },
    methods: {
      transformRequestData(formData) {
        formData.append('fiscal_year', this.model.year)
        formData.append('overwrite', +this.model.overwrite)
      },
      async redirectToList() {
        await this.$router.push('/ledger/accounts')
        await this.$store.dispatch('company/getCompanyAccounts')
      },
    }
  }
</script>
