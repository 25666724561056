<template>
  <div>
    <layout-tabs :items="filteredItems"/>
    <router-view/>
  </div>
</template>
<script>

  export default {
    data() {
      return {
        items: [
          {
            name: this.$t('Pending'),
            path: '/equipment/depreciation/pending',
            permission: 'equipment_show',
          },
          {
            name: this.$t('Posted'),
            path: '/equipment/depreciation/posted',
            permission: 'equipment_show',
          },
          {
            name: this.$t('Compute'),
            path: '/equipment/depreciation/compute',
            permission: 'equipment_show',
            icon: 'IconServiceInvoices',
          },
        ],
      }
    },
    computed: {
      filteredItems() {
        return this.items.filter(item => this.$can(item.permission))
      },
    },
  }
</script>
