<template>
  <base-form :save-text="$t('Void')"
             :loading="$attrs.loading || resourceLoading"
             :show-cancel="showCancel"
             class="col-span-6"
             layout="modal"
             @cancel="$emit('cancel')"
             @submit="onSubmit"
  >
    <template v-if="hasCustomMessage">
      <base-alert class="col-span-6"
                  :type="$promptType.Warning">
        <slot name="custom-message"/>
      </base-alert>
    </template>
    <template v-else>
      <div class="col-span-6 flex items-center justify-center text-md leading-5 text-cool-gray-500 font-medium pb-6">
        <ValidationWarning>
          {{ $t(`Note that this action is irreversible. We will still keep the ${resourceName} in our history.`) }}
        </ValidationWarning>
      </div>
      <template v-if="isAccounting">
        <div class="col-span-6 md:col-span-3">
          <base-input :label="$t('Fiscal Year')">
            <template v-slot:container>
              <fiscal-year-select v-model="model.fiscal_year"
                                  class="w-full"
                                  id="fiscal_year"
              />
            </template>
          </base-input>
        </div>
        <div class="col-span-6 md:col-span-3">
          <period-select v-model="model.period"
                         :fiscal-year="model.fiscal_year"
                         :placeholder="$t('Void Period')"
                         rules="required"
                         id="period"
          />
        </div>
      </template>
    </template>
  </base-form>
</template>
<script>
import axios from "axios";
import { isPeriodDefined } from "@/modules/ledger/utils/periodUtils";

export default {
  props: {
    hasCustomMessage: {
      type: Boolean,
      default: false,
    },
    resourceName: {
      type: String,
      default: 'invoices',
    },
    resourceId: {
      type: String,
    },
    isAccounting: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showCancel: true,
      model: {
        period: null,
        fiscal_year: this.activeFiscalYear,
      },
      resourceLoading: false,
    }
  },
  methods: {
    onSubmit() {
      this.$emit('submit', this.model)
    },
    async getResourceData() {
      if (!this.resourceName || !this.resourceId) {
        return
      }
      try {
        this.resourceLoading = true
        const { data } = await axios.get(`/restify/${this.resourceName}/${this.resourceId}`, {
          params: {
            related: 'journal',
            ignore404: true,
          }
        })
        const journal = data?.relationships.journal || {}
        const { period, fiscal_year } = journal?.attributes || {}
        if (!isPeriodDefined(period) && !fiscal_year) {
          return
        }
        this.model.period = period
        this.model.fiscal_year = fiscal_year
      } catch (err) {
        console.error(err)
      } finally {
        this.resourceLoading = false
      }
    }
  },
  mounted() {
    this.getResourceData()
  }
}
</script>
