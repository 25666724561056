<template>
  <div>
    <BaseDataTable :columns="columns"
                   :url="url"
                   :url-params="urlParams"
                   :edit-entity-url-query="editEntityUrlQuery"
                   :add-text="$t('New sub account')"
                   :per-page="$pagination.list"
                   default-sort="number"
                   actions="search,refresh,print"
                   hide-actions="view"
                   permission="subaccounts"
                   ref="table"
                   @add="onRowAdd"
                   @edit="onRowEdit"
                   @data-fetch="onDataFetch"
                   @delete="refreshGlobalList"
    />
    <BaseFormDialog v-if="showDialog"
                    :title="rowToEdit ? $t('Edit General Ledger Sub Account') : $t('Add General Ledger Sub Account')"
                    :open.sync="showDialog"
                    @close="onEventTrigger(false)">
      <SubAccountForm :data="rowToEdit"
                      :key="renderKey"
                      @cancel="onEventTrigger(false)"
                      @save="onEventTrigger"
                      @create-another="renderKey++"
      />
    </BaseFormDialog>
  </div>
</template>
<script>
  import SubAccountForm from '@/modules/ledger/components/SubAccountForm'

  export default {
    components: {
      SubAccountForm,
    },
    data() {
      return {
        renderKey: 1,
        editEntityUrlQuery: '?id={ID}',
        rowToEdit: null,
        showDialog: false,
        columns: [
          {
            label: this.$t('# Number'),
            prop: 'attributes.number',
            maxWidth: 200,
            component: 'EntityLink',
            redirectTo: '/ledger/sub-accounts?id={ID}',
          },
          {
            label: this.$t('Description'),
            prop: 'attributes.description',
          },
        ],
      }
    },
    computed: {
      url() {
        return `/restify/subaccounts`
      },
      urlParams() {
        return {
          fiscal_year: this.activeFiscalYear,
        }
      },
    },
    methods: {
      onRowEdit(row) {
        this.showDialog = true
        this.rowToEdit = row
      },
      onRowAdd() {
        this.showDialog = true
      },
      refreshTable() {
        this.$refs.table.refresh()
      },
      refreshGlobalList() {
        this.$store.dispatch('company/getCompanySubAccounts')
      },
      onEventTrigger(refresh = true) {
        this.rowToEdit = null
        this.showDialog = false
        if (!refresh) {
          return
        }
        this.refreshTable()
        this.refreshGlobalList()
      },
      onDataFetch(data) {
        this.$store.commit('company/SET_SUB_ACCOUNTS', data)
      },
    },
  }
</script>
