<template>
  <StatusBadge
    v-if="hasTimesheet && !hasLink"
    :type="status === TimesheetStatus.Pending ? 'warning': undefined"
    :status="status"
    :status-text="statusText"
  />
  <RouterLink
    v-else-if="hasTimesheet && hasLink"
    :to="link"
  >
    <StatusBadge
      :type="status === TimesheetStatus.Pending ? 'warning': undefined"
      :status="status"
      :status-text="statusText"
    />
  </RouterLink>
</template>
<script setup lang="ts">
import {computed} from 'vue'
import i18n from "@/i18n";
import {TimesheetStatus} from "@/modules/payroll/components/timesheets/utils";

const props = defineProps({
  params: {
    type: Object,
    default: () => ({}),
  },
})

const hasTimesheet = computed(() => {
  return !!props.params.data?.id
})

const status = computed(() => {
  return props.params.data?.attributes.status
})

const statusText = computed(() => {
  const map = {
    [TimesheetStatus.Approved]: i18n.t('Approved'),
    [TimesheetStatus.Rejected]: i18n.t('Rejected'),
    [TimesheetStatus.PayrollCreated]: i18n.t('Payroll Created'),
    [TimesheetStatus.Transferred]: i18n.t('Transferred'),
    [TimesheetStatus.Submitted]: i18n.t('Submitted'),
    [TimesheetStatus.Pending]: i18n.t('Pending'),
  }
  return map[status.value] || status.value
})

const hasLink = computed(() => {
  return [TimesheetStatus.PayrollCreated, TimesheetStatus.Transferred].includes(status.value)
})
const link = computed(() => {
  const {timecard_batch_id, payroll_batch_id} = props.params.data?.attributes || {}
  if (timecard_batch_id) {
    return `/payroll/timecard-batches/${timecard_batch_id}/view`
  }
  if (payroll_batch_id) {
    return `/payroll/batches/${payroll_batch_id}/view`
  }
  return null
})
</script>
