<template>
  <DailyJobCostDetailReport/>
</template>
<script>
  import DailyJobCostDetailReport from '@/modules/job-costing/components/reports/DailyJobCostDetailReport'

  export default {
    components: {
      DailyJobCostDetailReport,
    },
  }
</script>
