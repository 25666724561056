<template>
  <div>
    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
      <div class="px-4 py-5 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          {{ $t('Keyboard Shortcuts') }}
        </h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">
          {{ $t('See a full list of keyboard shortcuts') }}
        </p>
      </div>
      <div class="border-t border-gray-200">
        <dl>
          <div v-for="(shortcut, index) in shortcutList"
               :key="shortcut.title"
               class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
               :class="{'bg-gray-50': index % 2 === 0}"
          >
            <dt class="text-sm font-medium text-gray-700 flex flex-col">
              <span>
                {{ shortcut.title }}
              </span>
              <span v-if="shortcut.subtitle"
                    class="text-xs text-gray-500">
                {{shortcut.subtitle}}
              </span>
            </dt>
            <dd class="mt-1 flex items-center text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <div class="flex items-center">
                <div class="w-48">
                  <span class="text-gray-400 text-sm leading-5 py-0.5 px-1.5 border border-gray-300 rounded-md">
                  <kbd v-if="shortcut.specialKey" class="font-sans">
                     <abbr title="Command" class="no-underline">
                       Control
                     </abbr>
                  </kbd>
                  <kbd v-if="shortcut.key" class="font-sans">
                    {{ shortcut.key }}
                  </kbd>
                </span>
                </div>
              </div>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>
<script>

  export default {
    data() {
      return {
        shortcutList: [
          {
            title: this.$t('Global Search'),
            key: 'Ctrl/Cmd + K'
          },
          {
            title: this.$t('Open Table Filters'),
            key: 'F6'
          },
          {
            title: this.$t('Save or update forms'),
            subtitle: this.$t('Applicable to forms containing a save or update button'),
            key: 'Ctrl/Cmd + Enter'
          },
          {
            title: this.$t('Add new entry'),
            subtitle: this.$t('Applicable to tables containing a + button'),
            key: '+'
          },
          {
            title: this.$t('Close popups'),
            key: 'ESC'
          }
        ]
      }
    },
  }
</script>
<style>
</style>
