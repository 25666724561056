<template>
  <div>
    <portal to="page-title">
      <div class="-mt-4"></div>
    </portal>
    <DashboardPage />
    <div v-if="false" class="bg-white border border-gray-200 min-h-xs px-12 py-10 rounded-md w-full">
      <div class="flex flex-col md:flex-row">
        <base-avatar :name="$user.name"
                     :src="$user.photo_url"
                     class="mr-5"
                     size="xl"/>
        <div>
          <h2>{{ $user.name }}</h2>
          <p class="text-xs leading-4 font-medium text-primary-600 truncate">
            {{ $companyName }}
          </p>
          <p class="font-medium tracking-wide leading-tight text-gray-400 text-sm mt-2">
            {{ $t(`Welcome to Construction Partner!`) }}
            <br>
            {{ $t('Start your construction financial journey by using our efficient administration tools') }}
          </p>
        </div>
        <div
            class="flex mt-4 md:mt-0 md:justify-end items-start flex-1 text-xs tracking-wide font-semibold leading-loose">
          <router-link to="/profile/shortcuts"
                       class="text-gray-400 hover:text-primary-500 mr-6 flex items-center text-xs">
            <ChevronRightIcon class="w-3 h-3 mr-1"/>
            {{ $t('Keyboard') }}
          </router-link>
          <router-link to="/profile/preferences" class="text-gray-400 hover:text-primary-500 flex items-center text-xs">
            <ChevronRightIcon class="w-3 h-3 mr-1"/>
            {{ $t('Preferences') }}
          </router-link>
        </div>
      </div>
    </div>

    <NavigationCardList v-if="false" :data="cardItems" />
  </div>
</template>

<script>
  import { ChevronRightIcon } from 'vue-feather-icons'
  import NavigationCardList from "@/modules/dashboard/components/NavigationCardList";
  import store from "@/store";

  const DashboardPage = () => import("@/modules/dashboard/components/dashboard/DashboardPage");

  export default {
    name: 'welcome',
    components: {
      DashboardPage,
      ChevronRightIcon,
      NavigationCardList,
    },
    computed: {
      cardItems() {
        return [
          {
            name: this.$t('Vendors'),
            routeName: this.$t('Vendors'),
            icon: 'IconVendors',
            color: 'green',
            path: '/accounts-payable/vendors',
          },
          {
            name: this.$t('Invoices'),
            routeName: this.$t('Pending Invoices'),
            icon: 'IconInvoices',
            color: 'yellow',
            path: '/accounts-payable/invoices/pending',
          },
          {
            name: this.$t('Customers'),
            routeName: this.$t('Customers'),
            icon: 'IconCustomers',
            color: 'primary',
            path: '/accounts-receivable/customers',
          },
          {
            name: this.$t('Statements'),
            routeName: this.$t('Statement Batches'),
            icon: 'IconStatements',
            color: 'red',
            path: '/accounts-receivable/statements',
          },
          {
            name: this.$t('Employees'),
            routeName: this.$t('Employees'),
            icon: 'IconEmployees',
            color: 'purple',
            path: '/payroll/employees',
          },
          {
            name: this.$t('Check Batches'),
            routeName: this.$t('Payroll Check Batches'),
            icon: 'IconCheckBatches',
            color: 'orange',
            path: '/payroll/check-batches',
          },
          {
            name: this.$t('My Checks'),
            routeName: this.$t('My Checks'),
            icon: 'IconMyChecks',
            color: 'green',
            path: 'payroll/my-checks',
          },
          {
            name: this.$t('Service Invoices'),
            routeName: this.$t('Service Invoice Batches'),
            icon: 'IconServiceInvoices',
            color: 'indigo',
            path: '/service-billing/service-billing-invoice-batches',
          },
          {
            name: this.$t('Jobs'),
            routeName: this.$t('Jobs'),
            icon: 'IconJobs',
            color: 'blue',
            path: '/job-costing/jobs',
          },
          {
            name: this.$t('GL Journals'),
            routeName: this.$t('Pending Journals Entries'),
            icon: 'IconJournals',
            color: 'indigo',
            path: '/ledger/journal/pending',
          },
          {
            name: this.$t('Settings'),
            routeName: this.$t('Company Settings'),
            icon: 'IconSettings',
            color: 'gray',
            path: '/settings/company-settings',
          }
        ]
      },
    },
    async beforeRouteEnter(to, from, next) {
      const permissions = store.getters['auth/permissions']
      const isEmployeeOnly = store.getters['auth/isEmployeeOnly']
      if (permissions.includes('own_employee_checks_show') && isEmployeeOnly) {
        return next('/payroll/my-checks')
      }
      return next()
    },
  }
</script>
<style lang="scss" scoped>
  .section-title {
    font-size: 22px;
    @apply mt-1 font-extrabold tracking-wider;
  }
</style>
