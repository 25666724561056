<template>
  <base-filter-row :title="$t('Period / Year')">
    <base-filter-range class="col-span-3">
      <template #start>
        <base-select
            v-model="value.period_start"
            :placeholder="$t('Period From')"
            :options="periods"
            :rules="{
              'required': periodOrYearFilled,
            }"
            :name="$t('Period')"
            :hide-label="true"
            key="period_start"
            class="w-1/2"
            clearable
        />
      </template>
      <template #end>
        <base-input
            v-model="value.fiscal_year_start"
            :placeholder="$t('Fiscal Year')"
            :name="$t('Fiscal Year')"
            :rules="{
              'required': periodOrYearFilled,
              'min_value': $validator.minFiscalYear,
              'max_value': $validator.maxFiscalYear,
            }"
            class="w-1/2"
        />
      </template>
    </base-filter-range>
    <base-filter-range class="col-span-3">
      <template #start>
        <base-select
            v-model="value.period_end"
            :placeholder="$t('Period To')"
            :options="periods"
            :rules="{
              'required': periodOrYearFilled,
              'min_value': minPeriodValidation,
            }"
            :name="$t('Period')"
            :hide-label="true"
            key="period_end"
            class="w-1/2"
            clearable
        />
      </template>
      <template #end>
        <base-input
            v-model="value.fiscal_year_end"
            :placeholder="$t('Fiscal Year')"
            :name="$t('Fiscal Year')"
            :rules="{
              'required': periodOrYearFilled,
              'min_value': value.fiscal_year_start,
              'max_value': $validator.maxFiscalYear,
            }"
            class="w-1/2"
        />
      </template>
    </base-filter-range>
    <BaseButton
      variant="white"
      size="sm"
      class="ml-4"
      @click="resetFilter"
    >
      {{ $t('Reset') }}
    </BaseButton>
    <slot></slot>
  </base-filter-row>
</template>
<script>
  import { hasValue } from "@/utils/utils";

  export default {
    props: {
      periodRequired: {
        type: Boolean,
        default: false,
      },
      yearRequired: {
        type: Boolean,
        default: false,
      },
      value: {
        type: Object,
        default: () => ({
          period_start: null,
          period_end: null,
          fiscal_year_start: null,
          fiscal_year_end: null,
        }),
      },
    },
    computed: {
      periods() {
        return this.$store.state.company.periods
      },
      periodOrYearFilled() {
        const filterKeys = ['period_start', 'period_end', 'fiscal_year_start', 'fiscal_year_end']

        const state = filterKeys.some(field => {
          return this.value[field]
        })

        return state
      },
      minPeriodValidation() {
        if (this.value.fiscal_year_start === this.value.fiscal_year_end) {
          return this.value.period_start
        }
        return null
      },
      hasValues() {
        const { period_start, period_end, fiscal_year_start, fiscal_year_end } = this.value
        const filterKeys = [period_start, period_end, fiscal_year_start, fiscal_year_end]
        return filterKeys.some(key => {
          return hasValue(this.value[key])
        })
      }
    },
    methods: {
      resetFilter() {
        this.value.period_start = null
        this.value.period_end = null
        this.value.fiscal_year_start = null
        this.value.fiscal_year_end = null
      }
    },
    watch: {
      'value.period_start'(value){
        if (value === '') {
          this.value.period_start = null
        }
        if (this.value?.include_period_0 === undefined) {
          return
        }
        this.value.include_period_0 = value === 0
      },
      'value.period_end'(value) {
        if (value === '') {
          this.value.period_end = null
        }
      },
      'value.fiscal_year_start'(value) {
        if (value === '') {
          this.value.fiscal_year_start = null
        }
      },
      'value.fiscal_year_end'(value) {
        if (value === '') {
          this.value.fiscal_year_end = null
        }
      },
    }
  }
</script>
