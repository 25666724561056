import isBefore from 'date-fns/isBefore'
import parseISO from 'date-fns/parseISO'
import differenceInDays from 'date-fns/differenceInDays'
import { resourceStatuses } from "@/enum/enums";

export function mapInvoices(invoices, selectedToPay = {}) {
  return invoices.map(invoice => mapInvoiceInfo(invoice, selectedToPay))
}
export function mapInvoiceInfo(entry, selectedToPay = {}) {
  const matchingPayment = selectedToPay[entry.id] || {}
  return {
    ...entry,
    attributes: {
      ...entry.attributes,
      current_paid_amount: matchingPayment.paid_amount || 0,
    },
    payment_status: matchingPayment.payment_status || null
  }
}

export function isDue(invoice) {
  const status = getInvoiceStatus(invoice)
  const isOpen = [resourceStatuses.Posted, resourceStatuses.PartialPaid].includes(status)
  const days = differenceInDays(new Date(), getDueDate(invoice))
  return days > 0 && isOpen
}

export function getDueDate(invoice) {
  const due_date = invoice.due_date || invoice?.attributes?.due_date
  return parseISO(due_date)
}

export function getInvoiceStatus(invoice) {
  return invoice.status || invoice?.attributes?.status
}

export function getDueDaysCount(invoice) {
  return differenceInDays(new Date(), getDueDate(invoice))
}
function isPaid(status) {
  return status === resourceStatuses.Paid
}
function isVoided(status) {
  return status === resourceStatuses.Voided
}
function isPartiallyPaid(status) {
  return status === resourceStatuses.PartialPaid
}

function hasPayments(invoice) {
  return invoice?.payments?.length > 0
}
export function showPaymentsTable(invoice) {
    const status = invoice?.status || invoice?.attributes?.status || resourceStatuses.Pending
    return isPaid(status) || isPartiallyPaid(status) || isVoided(status) || hasPayments(invoice)
}
