<template>
  <div>
    <layout-tabs :items="filteredItems"/>
    <router-view/>
  </div>
</template>
<script>

  export default {
    computed: {
      items() {
        return [
          {
            name: this.$t('Aged By Vendor'),
            path: '/accounts-payable/reports/aged-by-vendor',
            permission: 'accounts_payable_reports',
          },
          {
            name: this.$t('Aged By Job'),
            path: '/accounts-payable/reports/aged-by-job',
            permission: 'accounts_payable_reports',
          },
          {
            name: this.$t('Vendor Payment Register'),
            path: '/accounts-payable/reports/vendor-payment-register',
            permission: 'accounts_payable_reports',
            productionReady: false,
          },
          {
            name: this.$t('Vendor History'),
            path: '/accounts-payable/reports/vendor-history',
            permission: 'accounts_payable_reports',
          },
          {
            name: this.$t('Waiver & Release'),
            path: '/accounts-payable/reports/waiver-and-release',
            permission: 'accounts_payable_reports',
          },
        ]
      },
      filteredItems() {
        return this.items.filter(item => {
          if (item.productionReady === false) {
            return this.isDev
          }

          return this.$can(item.permission)}
        )
      },
    },
  }
</script>
