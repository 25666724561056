<template>
  <base-data-table
      v-bind="defaultAttributes"
      :columns="columns"
      :data="getData"
      :total-rows="rowsLength"
      :has-summary="false"
      @force-pagination="forcePagination"
  >

    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template #header="{row}">
      {{ $t('Union') }}:
      <router-link :to="`/payroll/rates/unions?id=${row.header.id}`">
        {{ row.header.code }}
        <span v-if="row.header.description">
          ({{ row.header.description }})
        </span>
      </router-link>
    </template>

    <template #employee.name="{row}">
      <router-link
          v-if="row.employee.id"
          :to="`/payroll/employees/${row.employee.id}/view`"
      >
        {{ row.employee?.name }}
      </router-link>
      <span v-else/>
    </template>
    <template #craft_code="{row}">
      <CraftCodeLink :params="{value: row.craft_code?.id}"/>
    </template>

    <template #subtotal="{subtotal}">
      <td colspan="3" align="right">
        {{ $t('Totals:') }}
      </td>
      <td align="right">{{ subtotal.hours?.REGPAY }}</td>
      <td align="right">{{ subtotal.hours?.OVTPAY }}</td>
      <td align="right">{{ subtotal.hours?.PRMPAY }}</td>
      <td align="right">{{ subtotal.hours?.total }}</td>
      <td align="right">{{ $formatPrice(subtotal.amount) }}</td>
    </template>
    <template #subtotals="{subtotals}">
      <tr class="top-border">
        <td colspan="8" class="subtotal-header">
          {{ $t('Pay/Deduction/Benefit Summary') }}
        </td>
      </tr>
      <template
          v-for="{craft_codes, special_source, totals} in subtotals"
      >
        <tr
            v-for="(craft_code, index) in craft_codes"
            :key="craft_code.id"
        >
          <template v-if="!index">
            <td>{{ special_source.type }}</td>
            <td>
                {{ special_source.code }}
                <span v-if="special_source.description">
                ({{ special_source.description }})
              </span>
            </td>
          </template>
          <td v-else colspan="2"/>
          <td>
            <router-link :to="`/payroll/codes/craft-codes?id=${craft_code.craft_code?.id}`">
            {{ craft_code.craft_code?.description }}
            </router-link>
          </td>
          <td align="right">{{ craft_code.totals?.hours?.REGPAY || '' }}</td>
          <td align="right">{{ craft_code.totals?.hours?.OVTPAY || '' }}</td>
          <td align="right">{{ craft_code.totals?.hours?.PRMPAY ||  '' }}</td>
          <td align="right">{{ craft_code.totals?.hours?.total || '' }}</td>
          <td align="right">{{ $formatPrice(craft_code.totals?.amount) }}</td>
        </tr>
        <tr
            v-if="craft_codes.length > 1"
            class="subtotal-row"
        >
          <td colspan="3" align="right">
            {{ $t('Totals:') }}
          </td>
          <td align="right">{{ totals.hours?.REGPAY }}</td>
          <td align="right">{{ totals.hours?.OVTPAY }}</td>
          <td align="right">{{ totals.hours?.PRMPAY }}</td>
          <td align="right">{{ totals.hours?.total }}</td>
          <td align="right">{{ $formatPrice(totals.amount) }}</td>
        </tr>
      </template>
    </template>
    <template #html-row="{ htmlData }">
      <tr>
        <td colspan="8">&nbsp;</td>
      </tr>
      <tr class="top-border">
        <td colspan="8" class="subtotal-header">
          {{ $t('Summary Totals for Selected Periods') }}
        </td>
      </tr>
      <tr>
        <td>{{ $t('Pay/Deduction') }}</td>
        <td align="right">{{ $t('Hours') }}</td>
        <td align="right">{{ $t('Amount') }}</td>
        <td colspan="5"/>
      </tr>
      <tr v-for="{special_source, totals} in reservedSources(htmlData.special_sources)">
        <td class="flex justify-between">
          <span>{{ special_source.description }}</span>
          <span>{{ special_source.type }}</span>
        </td>
        <td align="right">{{ totals.hours?.total }}</td>
        <td align="right">{{ $formatPrice(totals.amount) }}</td>
        <td colspan="5"/>
      </tr>
      <tr>
        <td class="subtotal-row" align="right">
          {{ $t('Total Hourly Pay') }}:
        </td>
        <td class="subtotal-row" align="right">
          {{ htmlData.grand_totals?.pays?.hours }}
        </td>
        <td class="subtotal-row" align="right">
          {{ $formatPrice(htmlData.grand_totals?.pays?.amount) }}
        </td>
        <td class="subtotal-row" colspan="5"/>
      </tr>
      <tr v-for="{special_source, totals} in otherSources(htmlData.special_sources)">
        <td class="flex justify-between">
          <span>{{ special_source.description }}</span>
          <span>{{ special_source.type }}</span>
        </td>
        <td align="right">{{ totals.hours?.total }}</td>
        <td align="right">{{ $formatPrice(totals.amount) }}</td>
        <td colspan="5"/>
      </tr>
      <tr>
        <td class="subtotal-row" align="right">
          {{ $t('Grand Totals') }}:
        </td>
        <td class="subtotal-row" align="right">
          {{ htmlData.grand_totals?.others?.hours }}
        </td>
        <td class="subtotal-row" align="right">
          {{ $formatPrice(htmlData.grand_totals?.others?.amount) }}
        </td>
        <td class="subtotal-row" colspan="5"/>
      </tr>
    </template>
  </base-data-table>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { reservedPayCodeList } from '@/enum/enums'
  import { Column } from '@/components/ag-grid/tableTypes'
  import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper.vue'
  import CraftCodeLink from "@/components/links/CraftCodeLink.vue";

  export default defineComponent({
    components: {CraftCodeLink},
    extends: ReportTableWrapper,
    computed: {
      columns(): Column[] {
        return [
          {
            label: this.$t('Employee'),
            prop: 'employee.name',
            hiddenValue: true,
            minWidth: 100,
            maxWidth: 160,
          },
          {
            label: this.$t('Soc Sec #'),
            prop: 'employee.ssn',
            minWidth: 80,
            maxWidth: 110,
          },
          {
            label: this.$t('Craft'),
            prop: 'craft_code',
            minWidth: 120,
            maxWidth: 180,
          },
          {
            label: this.$t('Regular <br> Hours'),
            prop: 'totals.hours.REGPAY',
            minWidth: 100,
            maxWidth: 120,
            align: 'right',
            hideZero: true,
          },
          {
            label: this.$t('Overtime <br> Hours'),
            prop: 'totals.hours.OVTPAY',
            minWidth: 100,
            maxWidth: 120,
            align: 'right',
            hideZero: true,
          },
          {
            label: this.$t('Premium <br> Hours'),
            prop: 'totals.hours.PRMPAY',
            minWidth: 100,
            maxWidth: 120,
            align: 'right',
            hideZero: true,
          },
          {
            label: this.$t('Total <br> Hours/Units'),
            prop: 'totals.hours.total',
            minWidth: 100,
            maxWidth: 120,
            align: 'right',
            hideZero: true,
          },
          {
            label: this.$t('Total Pay/Ded <br> Amount'),
            prop: 'totals.amount',
            minWidth: 100,
            maxWidth: 120,
            component: 'FormattedPrice',
            align: 'right',
            hideZero: true,
          }
        ]
      },
    },
    methods: {
      composeRows(data: Record<string, any>) {
        data.forEach((group: Record<string, any>) => {
          const {union, employees, totals, special_sources, grand_totals} = group
          // @ts-ignore
          this.rows.push({
            header: union,
          })
          employees.forEach((employeeObject: Record<string, any>) => {
            const {craft_codes, employee} = employeeObject || {}
            craft_codes.forEach((craft_code: Record<string, any>) => {
              const row = {
                ...craft_code,
                employee,
              }
              // @ts-ignore
              this.rows.push(row)
            })
          })
          // @ts-ignore
          this.rows.push({
            subtotal: totals,
          })
          // @ts-ignore
          this.rows.push({
            subtotals: special_sources,
          })
          // @ts-ignore
          this.rows.push({
            htmlData: {
              special_sources,
              grand_totals,
            },
          })
        })

        return this.rows
      },
      // *REGPAY, OVTPAY, PRMPAY
      reservedSources(specialSources: Record<string, any>[]) {
        return specialSources.filter(({special_source}) => {
          return reservedPayCodeList.includes(special_source.code)
        })
      },
      otherSources(specialSources: Record<string, any>[]) {
        return specialSources.filter(({special_source}) => {
          return !reservedPayCodeList.includes(special_source.code)
        })
      },
    },
  })
</script>
