<template>
  <div class="mt-10 sm:mt-0">
    <base-form :save-text="$t('Update Settings')"
               :loading="loading"
               :show-buttons="$can('companies_update')"
               :auto-height="true"
               grid-classes="grid grid-cols-8 gap-x-3"
               @cancel="$emit('cancel')"
               @submit="updateSettings"
    >
      <template v-slot:title>
        <div class="flex items-center">
          <span class="mr-1">{{ $t('General Settings') }}</span>
        </div>
      </template>
      <template v-slot:description>
        <div class="my-1">{{ $t('Company specific settings that apply globally at the company level.') }}</div>
      </template>

      <div class="col-span-8 md:col-span-4 lg:col-span-4">
        <base-checkbox
            v-model="model.employee_consent_required"
            :label="$t('Employee consent required')"
            :tip="$t('Require employee consent for all employees to access the employee portal and receive checks via email.')"
            id="employee_consent"
        />
        <base-checkbox
            v-model="model.redirect_to_journal_after_post"
            :label="$t('Redirect to journal after post')"
            :tip="$t('Redirect to the created journal after posting.')"
            id="redirect_to_journal_after_post"
        />

        <base-checkbox
            v-model="model.show_bank_balance"
            :label="$t('Show bank balance')"
            :tip="$t('Displays the bank balance in the bank selection dropdown.')"
            id="show_bank_balance"
        />
      </div>
    </base-form>

    <ReportShading
        class="my-8"
        :settings="model"
        :loading="loading"
        ref="reportShading"
        @save="updateSettings"
    >
      <div class="col-span-8 md:col-span-4 lg:col-span-2">
        <base-checkbox
            v-model="model.show_reports_code"
            :label="$t('Show reports code')"
            :tip="$t('Show reports code in the report headers. This is useful for identifying reports in the report list.')"
            id="show_reports_code"
        />
        <base-checkbox
            v-model="model.shade_reports"
            :label="$t('Shade Reports')"
            :tip="$t('Show reports code in the report headers. This is useful for identifying reports in the report list.')"
            id="shade_reports"
        />
      </div>
      <div class="col-span-8 md:col-span-4 lg:col-span-2">
        <base-select
            v-model="model.print_report_orientation"
            :options="reportPrintOrientationOptions"
            :label="$t('Select Print Orientation')"
        />
      </div>
    </ReportShading>
    <TemplateSettings
        class="my-8"
        :settings="model"
        :loading="loading"
        ref="reportShading"
        @save="updateSettings"
    />

  </div>
</template>
<script>
import axios from 'axios'
import ReportShading from '@/modules/common/components/reports/ReportShading.vue'
import { reportPrintOrientationOptions, reportPrintOrientations } from '@/utils/reports'
import TemplateSettings from "@/modules/common/components/reports/TemplateSettings.vue";

const moduleName = 'general'
export default {
  components: {
    TemplateSettings,
    ReportShading
  },
  data() {
    return {
      loading: false,
      model: {
        employee_consent_required: true,
        show_reports_code: false,
        shade_reports: true,
        redirect_to_journal_after_post: true,
        show_bank_balance: true,
        shading: {},
        print_report_orientation: reportPrintOrientations.Portrait,
        template_settings: {
          logo: ''
        },
      },
      reportPrintOrientationOptions,
    }
  },
  computed: {
    generalSettings() {
      return this.$store.getters['company/getCompanySettingsPerModule'](moduleName)
    },
  },
  methods: {
    async onFileUpdate(event) {
      const file = event.target?.files[0]
      if (!file) {
        this.model.template_settings.logo = null
      }
      this.model.template_settings.logo = URL.createObjectURL(file)
    },
    async updateSettings(settings = {}) {
      try {
        this.model = {
          ...this.model,
          ...settings,
        }

        this.loading = true
        await axios.put(`/restify/company-settings/${this.model.id}`, {
          value: this.model,
          module: moduleName,
        })
        this.$success(this.$t('Company settings updated'))
        await this.$store.dispatch('company/getCompanySettings')
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(this.$t('Could not update the company settings'))
      } finally {
        this.loading = false
      }
    },
  },
  watch: {
    generalSettings: {
      immediate: true,
      handler(value) {
        if (!value) {
          return
        }
        this.model = {
          ...this.model,
          ...value,
        }
      },
    },
  },
}
</script>
