<template>
  <div class="mt-10 sm:mt-0">
    <base-form v-bind="$attrs"
               :title="$t('Personal Information')"
               :description="$t('Use a permanent address where you can receive mail.')"
               :save-text="saveText"
               :update-text="saveText"
               :loading="loading"
               @cancel="$emit('cancel')"
               @submit="updateProfile"
    >

      <div class="col-span-6 md:col-span-3">
        <base-input v-model="model.email"
                    :disabled="model.id"
                    :label="$t('Email')"
                    :placeholder="$t('Email')"
                    id="name"
                    rules="required|email">
        </base-input>
      </div>
      <div v-if="false" class="col-span-6 md:col-span-3 mb-6">
        <div class="flex items-center">
          <base-avatar :name="model.name"
                       :src="model.photo_url"
                       class="mr-5"
                       size="xl"/>
          <base-upload-button accept="image/*"
                              @input="onFileUpdate">
            {{ $t('Change') }}
          </base-upload-button>
        </div>
      </div>

      <div class="col-span-6 md:col-span-3">
        <base-input v-model="model.name"
                    :label="$t('Name')"
                    :placeholder="$t('Full name')"
                    id="name"
                    rules="required">
        </base-input>
      </div>
      <div class="col-span-6 md:col-span-3">
        <base-phone-input v-model="model.phone"
                          :label="$t('Phone')"
                          placeholder="(123) 456-7890"
                          id="phone"/>
      </div>
      <slot></slot>
    </base-form>
  </div>
</template>
<script>
  import UserService from "@/modules/auth/services/UserService";
  import i18n from "@/i18n";

  export default {
    props: {
      data: {
        type: Object,
        default: () => ({})
      },
      saveText: {
        type: String,
        default: i18n.t('Update Profile')
      }
    },
    data() {
      return {
        loading: false,
        model: {
          name: '',
          address: '',
          gender: '',
          email: '',
          photo_url: '',
          avatar: null,
        },
      }
    },
    computed: {
      userName() {
        return this.data.name || ''
      }
    },
    watch: {
      data: {
        immediate: true,
        handler(value) {
          this.model = {
            ...this.model,
            ...value,
          }
        }
      }
    },
    methods: {
      onFileUpdate(evt) {
        const { files } = evt.target
        if (files.length) {
          this.model.photo_url = URL.createObjectURL(files[0])
          this.model.avatar = files[0]
        }
      },
      async updateProfile() {
        try {
          this.loading = true
          const { id, name, email, phone, avatar, photo_url } = this.model
          const data = {
            id,
            name,
            email,
            phone,
            avatar,
            photo_url
          }
          if (email === this.data.email) {
            delete data.email
          }
          if (this.$route.path === '/profile/edit') {
            await UserService.updateProfile(data);
          } else {
            await UserService.updateUser(data);
          }
          await this.$store.dispatch('auth/refreshProfile')
          this.$success(this.$t('User profile updated'))
          this.$emit('save')
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not update the user profile'))
        } finally {
          this.loading = false
        }
      }
    }
  }
</script>
<style>
</style>
