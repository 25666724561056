import axios from "axios";
import {RateFrom} from "@/modules/payroll/utils/timeCardUtils";
import {IRowNode} from "@ag-grid-community/core";
import {
  CostForBillingDataEntry
} from "@/modules/service-billing/components/cost-for-billing/models/costForBillingModels";

async function getBillingRates(ids: (string | undefined)[] = []) {
  const {data} = await axios.get(`/restify/service-billing-histories/billing-rate`, {
    params: {
      repositories: ids
    }
  })
  return data
}

export async function updateRows(rows: IRowNode<CostForBillingDataEntry>[], selected: boolean) {
  const rowIds = rows.map(row => row.id)
  rows.forEach(child => {
    const data = {
      ...child.data,
      selected: Boolean(selected),
      dirty: true,
    } as CostForBillingDataEntry
    if (!selected) {
      data.billing_unit_rate = 0
      data.rate_from = RateFrom.Undefined,
      data.dirty = false
    }
    child.setData(data)
  })
  if (!selected) {
    return
  }

  const rates = await getBillingRates(rowIds)
  rows.forEach(child => {
    const rate = rates.find((rate: any) => rate.id === child.data?.id)
    if (child.data?.rate_from !== RateFrom.Undefined)  {
      return
    }
    child.setData({
      ...child.data,
      billing_unit_rate: rate?.rate || 0,
      rate_from: rate?.rate_from,
    })
  })
}
