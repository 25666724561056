<template>
  <div>
    <TimesheetsTable v-if="view === ViewModes.View">
      <template #additional-actions-before>
        <BaseButton
          variant="white" @click="switchTo(ViewModes.Edit)">
          <Edit2Icon class="w-4 h-4 mr-1"/>
          <span>{{ $t('Grid Mode') }}</span>
        </BaseButton>
      </template>
    </TimesheetsTable>
    <TimesheetsGrid v-if="view === ViewModes.Edit">
      <template #additional-actions-before>
        <BaseButton variant="white" @click="switchTo(ViewModes.View)">
          <ListIcon class="w-4 h-4 mr-1"/>
          <span>{{ $t('List Mode') }}</span>
        </BaseButton>
      </template>
    </TimesheetsGrid>
  </div>
</template>
<script>
import TimesheetsTable from "@/modules/payroll/pages/timesheets/TimesheetsTable.vue";
import { Edit2Icon, ListIcon } from "vue-feather-icons";
import TimesheetsGrid from "@/modules/payroll/components/timesheets/TimesheetsGrid.vue";
import Cache from "@/utils/Cache";

const ViewModes = {
  View: 'view',
  Edit: 'edit',
}

export default {
  components: {
    ListIcon,
    Edit2Icon,
    TimesheetsGrid,
    TimesheetsTable
  },
  data() {
    return {
      ViewModes,
      view: ViewModes.View,
    }
  },
  methods: {
    switchTo(mode) {
      Cache.removeForEntity('timesheet-entries')
      Cache.removeForEntity('timesheets')
      this.view = mode
    }
  }
}
</script>
