<template>
  <base-email-link :value="getValue" noDataText=""/>
</template>
<script>
  import get from 'lodash/get'

  export default {
    props: {
      column: {
        type: Object,
        default: () => ({}),
      },
      row: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      getValue() {
        return get(this.row, this.column.prop)
      }
    }
  }
</script>
