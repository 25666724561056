<template>
  <div>
    <portal to="page-title">
      {{ $t('Adjustment ') }} {{ currentResource.attributes.name }}
    </portal>
    <div class="flex justify-between items-center mr-2">
      <layout-tabs :items="filteredItems"/>
      <ProofListingButton
        :disabled="!$isAuthorized('authorizedToPost', currentResource)"
        :selected-rows="[currentResource]"
        path="/job-costing/transactions/proof-listing"
      />
    </div>
    <router-view/>
  </div>
</template>
<script>
import layoutPermissions from '@/mixins/layoutPermissions'

export default {
  mixins: [layoutPermissions],
  computed: {
    items() {
      const type = this.$route.meta.type
      return [
        {
          name: this.$t('Details'),
          path: `/job-costing/transactions/${type}/${this.$route.params.id}/view`,
          authorizedTo: 'authorizedToShow',
        },
        {
          name: this.$t('Edit'),
          path: `/job-costing/transactions/${type}/${this.$route.params.id}/edit`,
          authorizedTo: 'authorizedToUpdate',
        },
      ]
    },
    currentResource() {
      return this.$store.state.generalLedger.currentJournal || {}
    },
  },
  methods: {
    async getCurrentResource(id, redirectToView = false) {
      await this.$store.dispatch('generalLedger/getJournal', id)

      if (!redirectToView) {
        return
      }

      await this.$router.push(`/job-costing/transactions/cost/${id}/view`)
    },
  },
}
</script>
