<template>
  <router-link :to="`/settings/companies/${company.id}/view`">
    <base-tooltip :content="fullName">
      <div class="truncate leading-6">
        {{ get(company, 'attributes.abbr') }}
      </div>
    </base-tooltip>
  </router-link>
</template>
<script>
  export default {
    props: {
      company: {
        type: Object,
        default: () => ({})
      },
    },
    computed: {
      fullName() {
        const { name, abbr } = this.company?.attributes || {}
        return `${abbr} (${name})`
      }
    }
  }
</script>
