<template>
  <div class="flex flex-col space-y-2 border-t border-gray-300 divide-y divide-gray-300">
    <template v-if="!loading">
      <TimesheetEntry
        v-for="entry in entries"
        :key="entry.id"
        :entry="entry"
        :editable="showTimesheetDetails"
        @edit="onEditEntry"
      />
      <div v-if="!loading && entries.length > 0" class="py-3">
        <div class="flex justify-between">
          <TimesheetActions
            v-if="showTimesheetDetails"
            :timesheet="timesheet?.attributes"
            class="pr-4"
          />
          <div class="flex justify-end items-center pr-2">
            <div
              v-if="showTimesheetDetails"
              class="flex space-x-2 items-center pr-4"
            >
              <TimesheetStatus :params="{ data: timesheet}"></TimesheetStatus>
              <TimesheetTags :row="timesheet"/>
            </div>
            <span class="mr-2 text-lg font-medium text-gray-700">{{ $t('Total:') }}</span>
            <span class="font-semibold text-lg">{{ minutesToHours(totalDuration, true) }}</span>
          </div>
        </div>
        <div
          v-if="showTimesheetDetails"
          class="mt-6"
        >
          <TimesheetClockOutDetails
            :timesheet="timesheet?.attributes"
          />
        </div>
      </div>
    </template>
    <TableOverlayLoading v-else class="py-4 mt-5"/>
    <template v-if="!loading && entries.length === 0">
      <NoDataRow
        :entity="$t('Timesheet entries')"
        :show-description="false"
        class="mt-4"
      />
    </template>
    <TimesheetEntryDialog
      v-if="showEditDialog"
      :open.sync="showEditDialog"
      :data="entryToEdit"
      :date="entryToEdit?.attributes?.date"
      :employee-id="entryToEdit?.attributes?.employee_id"
      @close="showEditDialog = false"
      @submit="showEditDialog = false"
    />
  </div>
</template>
<script>
import TimesheetEntry from "@/modules/payroll/components/timesheets/TimesheetEntry.vue";
import TableOverlayLoading from "@/components/ag-grid/TableOverlayLoading.vue";
import sumBy from "lodash/sumBy";
import { minutesToHours } from "@/modules/payroll/utils/timeCardUtils";
import NoDataRow from "@/components/table/NoDataRow.vue";
import TimesheetEntryDialog from "@/modules/payroll/components/timesheets/TimesheetEntryDialog.vue";
import cloneDeep from "lodash/cloneDeep";
import TimesheetStatus from "@/components/table/cells/TimesheetStatus.vue";
import TimesheetTags from "@/modules/payroll/components/timesheets/TimesheetTags.vue";
import TimesheetActions from "@/modules/payroll/components/timesheets/TimesheetActions.vue";
import TimesheetClockOutDetails from "@/modules/payroll/components/timesheets/TimesheetClockOutDetails.vue";

export default {
  components: {
    TimesheetClockOutDetails,
    TimesheetActions,
    TimesheetTags,
    TimesheetStatus,
    TimesheetEntryDialog,
    NoDataRow,
    TableOverlayLoading,
    TimesheetEntry
  },
  props: {
    loading: Boolean,
    entries: {
      type: Array,
      default: () => [],
    },
    showTimesheetDetails: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      showEditDialog: false,
      entryToEdit: null,
    }
  },
  computed: {
    totalDuration() {
      return sumBy(this.entries, 'attributes.duration')
    },
    timesheet() {
      if (this.entries.length === 0) {
        return null
      }
      return this.entries[0].attributes.timesheet
    }
  },
  methods: {
    minutesToHours,
    onEditEntry(entry) {
      this.entryToEdit = cloneDeep(entry)
      this.showEditDialog = true
    }
  }
}
</script>
