<template>
  <time>
    {{ formattedDate }}
  </time>
</template>
<script>
  import get from 'lodash/get'
  import { formatDate } from "@/plugins/dateFormatPlugin";
  import { isSummaryRow } from "@/components/ag-grid/columnUtils";

  export default {
    props: {
      column: {
        type: Object,
        default: () => ({})
      },
      row: {
        type: Object,
        default: () => ({})
      },
      params: {
        type: Object,
        default: () => ({})
      }
    },
    computed: {
      formattedDate() {
        try {
          const value = get(this.row, this.column.prop)
          const isSummary = isSummaryRow(this.params)
          if (isSummary) {
            return ''
          }
          if (!value) {
            return ''
          }
          const dateForm = this.params.format
          return formatDate(value, dateForm)
        } catch (err) {
          return ''
        }
      }
    }
  }
</script>
