<template>
  <DetailLayout
      :loading="currentEmployeeLoading"
      :current-resource="currentResource"
      :get-current-resource="getCurrentResource"
      :select-component="EmployeeSelectNew"
      :items="items"
  >
  </DetailLayout>
</template>
<script>
  import layoutPermissions from '@/mixins/layoutPermissions'
  import { isProduction } from "@/isProduction";
  import detailScreen from "@/mixins/detailScreen";
  import DetailLayout from "@/modules/common/components/DetailLayout.vue";
  import EmployeeSelectNew from "@/components/select/entities/EmployeeSelectNew.vue";

  export default {
    components: { DetailLayout },
    mixins: [layoutPermissions, detailScreen],
    data() {
      return {
        EmployeeSelectNew
      }
    },
    computed: {
      items() {
        return [
          {
            name: this.$t('Details'),
            path: `/payroll/employees/${this.currentEmployeeId}/view`,
            authorizedTo: 'authorizedToShow',
          },
          {
            name: this.$t('Edit'),
            path: `/payroll/employees/${this.currentEmployeeId}/edit`,
            authorizedTo: 'authorizedToUpdate',
          },
          {
            name: isProduction() ? this.$t('Checks') : this.$t('Checks (desktop)'),
            path: `/payroll/employees/${this.currentEmployeeId}/checks`,
            authorizedTo: 'authorizedToShow',
            enabled: () => isProduction(),
            productionReady: true,
          },
          {
            name: this.$t('Rates'),
            path: `/payroll/employees/${this.currentEmployeeId}/rates`,
            authorizedTo: 'authorizedToShow',
          },
          {
            name: this.$t('Time Off'),
            path: `/payroll/employees/${this.currentEmployeeId}/time-off`,
            authorizedTo: 'authorizedToShow',
          },
          {
            name: this.$t('Documents'),
            path: `/payroll/employees/${this.currentEmployeeId}/documents`,
            authorizedTo: 'authorizedToShow',
          },
          {
            name: this.$t('Timesheets'),
            path: `/payroll/employees/${this.currentEmployeeId}/timesheets`,
            authorizedTo: 'authorizedToShow',
          },
          {
            name: this.$t('Payrolls'),
            path: `/payroll/employees/${this.currentEmployeeId}/payrolls`,
            authorizedTo: 'authorizedToShow',
          },
          {
            name: this.$t('Taxes'),
            path: `/payroll/employees/${this.currentEmployeeId}/taxes`,
            authorizedTo: 'authorizedToShow',
          },
          {
            name: this.$t('Direct Deposit'),
            path: `/payroll/employees/${this.currentEmployeeId}/direct-deposit`,
            authorizedTo: 'authorizedToShow',
          },
          {
            name: this.$t('W-2 Forms'),
            path: `/payroll/employees/${this.currentEmployeeId}/w2-forms`,
            authorizedTo: 'authorizedToShow',
            enabled: () => isProduction(),
          },
        ]
      },
      currentResource() {
        return this.$store.state.payroll.currentEmployee
      },
      currentEmployeeLoading() {
        return this.$store.state.payroll.currentEmployeeLoading
      },
      currentEmployeeId() {
        return this.$route.params.id
      },
    },
    methods: {
      async getCurrentResource(id) {
        await this.$store.dispatch('payroll/getEmployee', id)
      },
    },
  }
</script>
