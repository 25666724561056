<template>
  <base-filter-row :title="$t('Line Items')">
    <base-filter-range class="col-span-3">
      <template #start>
        <BaseInput
          v-model="value.phase_code_from"
          :placeholder="$t('Phase From')"
          :name="$t('Phase From')"
          @blur="onFromBlur($event, 'phase_code_from')"
        >
        </BaseInput>
      </template>
      <template #end>
        <BaseInput
          v-model="value.phase_code_to"
          :placeholder="$t('Phase From')"
          :name="$t('Phase From')"
        >
        </BaseInput>
      </template>
    </base-filter-range>
    <base-filter-range class="col-span-3">
      <template #start>
        <BaseInput
          v-model="value.cost_code_from"
          :placeholder="$t('Cost Code From')"
          :name="$t('Cost Code From')"
          @blur="onFromBlur($event, 'cost_code_from')"
        >
        </BaseInput>
      </template>
      <template #end>
        <BaseInput
          v-model="value.cost_code_to"
          :placeholder="$t('Cost Code To')"
          :name="$t('Cost Code To')"
        >
        </BaseInput>
      </template>
    </base-filter-range>
    <base-filter-range class="col-span-2">
      <template #start>
        <BaseInput
          v-model="value.change_order_from"
          :placeholder="$t('Change Order From')"
          :name="$t('Change Order From')"
          type="number"
          @blur="onFromBlur($event, 'change_order_from')"
        >
        </BaseInput>
      </template>
      <template #end>
        <BaseInput
          v-model="value.change_order_to"
          :placeholder="$t('Change Ord0r To')"
          :name="$t('Change Order To')"
          type="number"
        >
        </BaseInput>
      </template>
    </base-filter-range>
  </base-filter-row>
</template>
<script>

export default {
  props: {
    value: {
      type: Object,
      default: () => ({
        phase_code_from: null,
        phase_code_to: null,
        cost_code_from: null,
        cost_code_to: null,
        change_order_from: null,
        change_order_to: null,
      }),
    },
  },
  methods: {
    onFromBlur(event, key) {
      const value = event?.target?.value
      if (key === 'phase_code_from' && value && !this.value.phase_code_to) {
        this.$emit('on-update-filter', {
          field: 'phase_code_to',
          value: value,
        })
      }
      if (key === 'cost_code_from' && value && !this.value.cost_code_to) {
        this.$emit('on-update-filter', {
          field: 'cost_code_to',
          value: value,
        })
      }
      if (key === 'change_order_from' && value && !this.value.change_order_to) {
        this.$emit('on-update-filter', {
          field: 'change_order_to',
          value: value,
        })
      }
    },
  },
}
</script>
