<template>
  <div>
    <BaseDataTable
        :url="url"
        :columns="getColumns"
        :url-params="getUrlParams"
        :data-map-function="mapData"
        :key="status"
        ref="table"
        hide-actions="add,view"
        actions="search,refresh,view"
    >
      <template #additional-actions>
        <invoice-post-action
            v-if="authorizedToPost"
            :has-recurring-option="false"
            entity-name="Depreciation"
            resource-name="equipment-depreciations"
            @on-action-callback="refreshData"
        />
      </template>
      <template v-slot:actions-header-before>
        <base-tooltip :content="$t(`Group by ${otherGroupBy}`)">
          <button
              class="inline-flex items-center justify-center w-full px-4 bg-transparent text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150 mb-1"
              @click="toggleGroupBy"
          >
            <component :is="selectedGroupBy === groupByOptions.Equipment ? 'IconSalesThisYear' : 'IconEquipment'"
                       class="w-6 h-6"
            />
          </button>
        </base-tooltip>
      </template>
      <template #header="{row}">
        <div class="flex items-center justify-between">
          <equipment-link
              v-if="selectedGroupBy === groupByOptions.StartDate"
              :data="row.header"
          />
          <span v-else>
            {{ $formatDate(row.header) }}
          </span>
          <span>
            {{ $t('Total:') }}
            {{ $formatPrice(row.total) }}
          </span>
        </div>
      </template>
      <template #equipment="{row}">
        <equipment-link
            :data="row.equipment"
        />
      </template>
    </BaseDataTable>
  </div>
</template>
<script>
  import groupBy from 'lodash/groupBy'
  import { ExternalLinkIcon, UserIcon } from 'vue-feather-icons'

  const groupByOptions = {
    Equipment: 'Equipment',
    StartDate: 'Start Date',
  }

  export default {
    props: {
      status: String,
      authorizedToPost: Boolean,
    },
    components: {
      ExternalLinkIcon,
      UserIcon,
    },
    data() {
      return {
        groupByOptions,
        selectedGroupBy: groupByOptions.StartDate,
        url: '/restify/equipment-depreciations',
      }
    },
    computed: {
      getColumns() {
        let firstColumn = {
          label: this.$t('Equipment'),
          prop: 'equipment',
        }
        if (this.selectedGroupBy === groupByOptions.StartDate) {
          firstColumn = {
            label: this.$t('Date'),
            prop: 'start_date',
            component: 'FormattedDate',
          }
        }
        return [
          firstColumn,
          {
            label: this.$t('Amount'),
            prop: 'amount',
            component: 'FormattedPrice',
          },
          {
            label: this.$t('Daily Rate'),
            prop: 'daily_rate',
            component: 'FormattedPrice',
          },
          {
            label: this.$t('Method'),
            prop: 'depreciation_method',
            component: 'FormattedText',
          },
          {
            label: this.$t('End Date'),
            prop: 'attributes.end_date',
            component: 'FormattedDate',
          },
          {
            label: this.$t('Interval Days'),
            prop: 'interval_days',
          },
        ]
      },
      getUrlParams() {
        return {
          related: 'equipment',
          status: this.status,
        }
      },
      otherGroupBy() {
        return this.selectedGroupBy === groupByOptions.StartDate ? groupByOptions.Equipment : groupByOptions.StartDate
      },
      groupByPaths() {
        return {
          [groupByOptions.Equipment]: 'attributes.equipment_id',
          [groupByOptions.StartDate]: 'attributes.meta.start_date',
        }
      },
    },
    methods: {
      mapData(data) {
        data = data.map(op => {
          return {
            ...op.attributes,
            ...op.relationships,
            ...op.attributes.meta,
          }
        })
        const objectPath = this.groupByPaths[this.selectedGroupBy]
        const groups = groupBy(data, objectPath) || []
        let result = []
        let total = 0
        for (let id in groups) {
          const data = groups[id]
          let header = data[0].equipment
          if (this.selectedGroupBy === groupByOptions.Equipment) {
            header = data[0].start_date
          }
          for (let i = 0; i <= data.length - 1; i++) {
            total += +data[i].amount
          }
          result.push({
            header,
            total,
          })
          result.push(...data)
        }
        return result
      },
      async toggleGroupBy() {
        this.selectedGroupBy = this.otherGroupBy
        await this.$nextTick()
        this.refreshData()
      },
      refreshData() {
        this.$refs.table.refresh()
      },
    },
  }
</script>
