<template>
  <div>
    <base-data-table
        :columns="columns"
        :data="getData"
        :can-reorder-columns="false"
        :show-pagination="false"
        :delete-action="deleteLicence"
        :open-entity-in-new-tab="false"
        :add-text="$t('Create driver licence')"
        :update-text="$t('Update driver licence')"
        add-button-variant="primary-light"
        ref="driverLicences"
        actions="add,delete,edit"
        @add="onRowAdd"
        @edit="onRowEdit"
        small-header
        compact
    >
      <template #custom-header>
        <h4>
          {{ $t(title) }}
        </h4>
      </template>
    </base-data-table>
    <base-form-dialog v-if="showFormDialog"
                      :title="$t('Create driver licence')"
                      :open.sync="showFormDialog"
                      ref="addDialog"
                      @close="onClose"
    >
      <base-form layout="modal"
                 grid-classes="grid grid-cols-2 gap-x-3"
                 @submit="onSubmit"
      >
        <state-select v-model="rowData.state"
                      :label="$t('State')"
                      :placeholder="$t('State')"
                      rules="required|max:2"
                      class="col-span-2 md:col-span-1"
        />
        <base-input
            v-model="rowData.number"
            :label="$t('Number')"
            :placeholder="$t('Number')"
            rules="required"
            class="col-span-2 md:col-span-1"
        />
        <base-date-picker
            v-model="rowData.expiration_date"
            :label="$t('Expiration Date')"
            :placeholder="$t('Expiration Date')"
            rules="required"
            class="col-span-2 md:col-span-1"
        />
      </base-form>
    </base-form-dialog>
  </div>
</template>
<script>

  const driverLicenceModel = {
    state: '',
    number: '',
    expiration_date: '',
  }

  export default {
    props: {
      value: {
        type: Array,
        default: () => [],
      },
      title: {
        type: String,
        default: 'Driver Licences',
      },
    },
    data() {
      return {
        showFormDialog: false,
        rowData: {},
        lastEditedRowIndex: -1,
        lastEditedRow: -1,
        columns: [
          {
            label: this.$t('State'),
            prop: 'state',
          },
          {
            label: this.$t('Number'),
            prop: 'number',
          },
          {
            label: this.$t('Expiration Date'),
            prop: 'expiration_date',
            component: 'FormattedDate',
          },
        ],
      }
    },
    computed: {
      getData() {
        return this.value || []
      }
    },
    methods: {
      onRowAdd() {
        this.rowData = { ...driverLicenceModel }
        this.showFormDialog = true
        this.lastEditedRowIndex = -1
      },
      onRowEdit(row, index) {
        this.rowData = row
        this.showFormDialog = true
        this.lastEditedRowIndex = index
        this.lastEditedRow = { ...row }
      },
      onSubmit() {
        if (this.lastEditedRowIndex === -1) {
          this.value.push(this.rowData)
        } else {
          this.value[this.lastEditedRowIndex] = this.rowData
        }
        this.showFormDialog = false
        this.lastEditedRowIndex = -1
        this.rowData = {}
      },
      onClose() {
        this.value[this.lastEditedRowIndex] = this.lastEditedRow
      },
      deleteLicence(_, index) {
        this.value.splice(index, 1)
      },
    },
  }
</script>
