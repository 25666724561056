<template>
  <div class="truncate">
    <router-link v-if="subAccountId && isLink && hasPermission"
                 :to="`/ledger/sub-accounts?id=${subAccountId}`"
                 :title="fullName"
                 :target="target"
    >
      <span>
        {{ subAccountNumber }}
      </span>
      <span class="print:hidden" v-if="showDescription && description">
        ({{ description }})
      </span>
    </router-link>
    <template v-else>
       <span>
        {{ subAccountNumber }}
      </span>
      <span class="print:hidden" v-if="showDescription && description">
        ({{ description }})
      </span>
    </template>
  </div>
</template>
<script>
  export default {
    name: 'SubAccountLink',
    props: {
      data: {
        type: [Object, String, Number],
        default: () => ({}),
      },
      id: {
        type: String,
      },
      showDescription: {
        type: Boolean,
        default: true,
      },
      number: {
        type: [Number, String],
      },
      target: String,
      isLink: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      subAccount() {
        if (this.id) {
          return this.$store.getters['company/getSubAccountById'](this.id)
        }

        const subAccount = this.get(this.data, 'relationships.subaccount.attributes');
        let subAccountField = subAccount?.number
            || this.get(this.data, 'attributes.subaccount')
            || this.get(this.data, 'subaccount')
            || this.get(this.data, 'number')
            || this.number

        if (typeof this.data !== 'object' && !subAccountField) {
          subAccountField = this.data
        }

        if (!subAccount) {
          return this.$store.state.company.subAccounts.find(a => a.number === subAccountField)
        }

        return subAccount
      },
      subAccountNumber() {
        return this.subAccount?.number
      },
      description() {
        return this.subAccount?.description
      },
      fullName() {
          return this.description? `${this.subAccountNumber} (${this.description})` : this.subAccountNumber
      },
      subAccountId() {
        return this.subAccount?.id
      },
      hasPermission() {
        return this.$can('subaccounts_update')
      },
    },
  }
</script>
