<template>
  <entity-select v-bind="$attrs"
                 v-on="$listeners"
                 :url="url"
                 :url-params="urlParams"
                 :name="$attrs.name || $t('Jurisdiction')"
                 :placeholder="$attrs.placeholder || $t('Select Jurisdiction')"
                 :label="$attrs.label !== undefined ? $attrs.label : $t('Jurisdiction')"
                 :id="$attrs.id || 'state-select'"
                 :add-entity="false"
                 :table-columns="tableColumns"
                 :label-format="formatLabel"
                 :hide-label="hideLabel"
  />
</template>
<script>
  export default {
    name: 'TaxJurisdictionSelect',
    inheritAttrs: false,
    props: {
      url: {
        type: String,
        default: '/restify/state-tax-jurisdictions',
      },
      urlParams: {
        type: Object,
        default: () => ({
          sort: 'code',
          perPage: 50,
        }),
      },
      hideLabel: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        tableColumns: [
          {
            minWidth: 100,
            maxWidth: 150,
            name: this.$t('Code'),
            prop: 'attributes.code',
          },
          {
            minWidth: 200,
            maxWidth: 200,
            name: this.$t('Description'),
            prop: 'attributes.description',
          },
        ],
      }
    },
    methods: {
      formatLabel(op) {
        const { code, description } = op.attributes
        if (!description) {
          return code
        }
        return `${code} (${description})`
      },
    },
  }
</script>
