<template>
  <div class="payment-page">
    <div class="mb-6 flex justify-center items-center space-x-4">
      <img class="h-12 w-auto" src="/img/cp.png" alt="logo"/>
      <span class="text-gray-700">Construction Partner Inc.</span>
    </div>
    <base-form
        v-if="!showSuccess && !showCancel"
        :loading="loading"
        :focus-on-first-input="false"
        layout="vertical"
        class="max-w-5xl mx-auto"
        show-cancel
        auto-height
        save-text="Submit"
        @cancel="onCancel"
        @submit="onSubmit"
    >
      <div class="col-span-6">
        <h1 class="text-xl font-semibold mb-4">Make a bank
          payment (ACH)</h1>
        <div class="grid md:grid-cols-3 gap-4 mb-4">
          <BaseInput
              v-model="model.amount"
              :disabled="amountDisabled"
              ref="amountInput"
              type="number"
              label="Amount to pay"
              format="price"
              step="0.01"
              rules="required|min_value:1"
          >
            <template #suffix>
              <BaseButton
                  v-if="amountDisabled"
                  variant="white"
                  size="sm"
                  type="button"
                  class="-mr-2"
                  @click="onChangeAmount"
              >
                <span class="text-xs">Change amount</span>
              </BaseButton>
            </template>
          </BaseInput>
          <BaseInput
              v-model="model.routing_number"
              label="Routing number"
              rules="required|numeric|min:9|max:9"
              placeholder="9 digits"
          />
          <div class="flex flex-col">
            <BaseInput
                v-model="model.account_number"
                label="Account number"
                rules="required|alpha_num|min:6|max:17"
                placeholder="6-17 digits"
            />
          </div>
          <BaseInput
              v-model="model.statement_descriptor"
              label="Statement descriptor"
              placeholder="Payment for invoice #1234"
              class="md:col-span-3"
              rules="required|max:200"
          />
        </div>
        <fieldset class="mb-4">
          <legend class="text-lg font-medium mb-3 mt-2">Account type</legend>
          <div class="flex space-x-4">
            <base-radio
                v-model="model.funding"
                label="checking"
                class="border-none min-w-[170px] -ml-2"
            >
              Business checking
            </base-radio>
            <base-radio
                v-model="model.funding"
                label="savings"
                inline
                class="border-none min-w-[200px]"
            >
              Business savings
            </base-radio>
          </div>
        </fieldset>
        <div class="border-t pt-4 mb-4"><h2 class="text-lg font-medium mb-3">Account holder information</h2>
          <div class="grid md:grid-cols-2 gap-4 mb-4">
            <BaseInput
                v-model="model.individual_name"
                label="Full name"
                placeholder="Full name"
                rules="max:22"
            />
            <BaseInput
                v-model="model.company_name"
                label="Company name"
                placeholder="Company name"
                rules="max:16"
            />
          </div>
        </div>
        <div>
          <h4 class="text-lg text-center font-medium mb-3 mt-2">
            An ACH payment for {{ formatPrice(model.amount) }} will be initiated after clicking submit
          </h4>
        </div>
      </div>
    </base-form>
    <div v-if="showSuccess" class="flex flex-1 flex-col items-center justify-center p-6">
      <div class="flex items-center justify-center w-16 h-16 rounded-full bg-green-500">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
             stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
             class="text-white w-8 h-8">
          <polyline points="20 6 9 17 4 12"></polyline>
        </svg>
      </div>
      <h1 class="mt-4 text-2xl font-semibold text-gray-900">Thanks for your payment</h1>
      <p class="mt-2 text-gray-600 mb-6">
        A payment to Construction Partner Inc. will appear on your statement.
      </p>
      <div class="mt-6 p-4 bg-gray-100 rounded-lg border border-dashed">
        <div class="flex justify-between">
          <span class="text-lg font-semibold">CONSTRUCTION PARTNER</span>
          <span class="text-gray-400 text-lg">...............</span>
          <span class="font-bold text-gray-900 text-lg">US{{ formatPrice(model.amount) }}</span>
        </div>
      </div>
    </div>
    <div v-if="showCancel" class="flex flex-col items-center justify-center p-6">
      <div class="bg-[#EF4444] rounded-full p-2 inline-flex items-center justify-center mb-4">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="text-white h-6 w-6"
        >
          <path d="M18 6 6 18"></path>
          <path d="m6 6 12 12"></path>
        </svg>
      </div>
      <h1 class="text-2xl font-semibold mb-2">Payment Failed</h1>
      <p class="text-gray-600 mb-6">Your payment to Construction Partner could not be processed.</p>
    </div>
  </div>
</template>
<script setup>
import { nextTick, ref } from "vue";
import { useRoute } from "vue2-helpers/vue-router";
import { formatPrice } from "@/plugins/formatPrice";
import axios from "axios";
import config from "@/modules/common/config";
import { error } from "@/components/common/NotificationPlugin";

const model = ref({
  amount: 0,
  routing_number: '',
  account_number: '',
  funding: 'checking',
  individual_name: '',
  company_name: '',
  statement_descriptor: '',
})
const amountDisabled = ref(false)
const loading = ref(false)

const showSuccess = ref(false)
const showCancel = ref(false)

const route = useRoute()
const { amount, invoice_number, company_name } = route.query
if (amount) {
  model.value.amount = +amount
  amountDisabled.value = true
}
if (invoice_number) {
  model.value.statement_descriptor = `Payment for invoice #${invoice_number}`
}

function resetModel(resetAmount = true) {
  const oldAmount = model.value.amount
  model.value = {
    amount: resetAmount ? 0 : oldAmount,
    routing_number: '',
    account_number: '',
    funding: 'checking',
    individual_name: '',
    company_name: '',
    statement_descriptor: '',
  }
}

function onCancel() {
  showCancel.value = true
  resetModel()

}

const amountInput = ref()
async function onChangeAmount() {
  amountDisabled.value = false
  await nextTick()
  amountInput.value?.focus()
}

async function onSubmit() {
  try {
    loading.value = true
    const apiUrl = `${config.PDF_API_URL}/ach/make-payment`
    const response = await axios.post(apiUrl, model.value)
    if (response.id) {
      showSuccess.value = true
      resetModel(false)
    } else {
      showCancel.value = true
    }
  } catch (err) {
    if (err.handled) {
      return
    }
    const message = err.response?.data?.detail
    if (message) {
      error(message)
    } else {
      error('Something went wrong during the payment process. Please try again later.')
    }
  } finally {
    loading.value = false
  }
}
</script>
<style lang="scss">
.payment-page {
  .base-input-errors p {
    white-space: inherit;
  }
}
</style>
