<template>
  <AgDataTable
      :columns="columns"
      :url="url"
      :url-params="urlParams"
      :add-entity-in-new-tab="addEntityInNewTab"
      :default-match-filters="defaultMatchFilters"
      :add-text="$t('New vendor')"
      :enableFillHandle="true"
      :enableRangeSelection="true"
      :disable-col-flex="true"
      :per-page="$pagination.list"
      actions="search,refresh,view,export,import"
      import-url="/accounts-payable/vendors/import"
      entity="vendors"
      ref="table"
      permission="vendors"
      @add="$router.push('/accounts-payable/vendors/add')"
  >
    <template #attributes.code="{row}">
      <entity-preview :entity="row.attributes"
                      :key="`preview-${row.id}`"
                      :addresses="get(row, 'relationships.addresses', [])"
                      :url="`/accounts-payable/vendors/${row.id}/view`"
                      :value-to-display="get(row, 'attributes.code')"
                      :parent-entity-id="row.id"
                      entity-name="vendor"
      />
    </template>
  </AgDataTable>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { Column } from "@/components/ag-grid/tableTypes";
  import {cellEditors} from "@/components/ag-grid/cellEditors/cellEditors";
  import {VendorStatusOptions} from "@/modules/accounts-payable/enum/vendor";
  import {ValueSetterParams} from "@ag-grid-community/core";
  import {cellClasses} from "@/components/ag-grid/columnUtils";
  import {valueSetterWithUpdate} from "@/components/ag-grid/cellEditors/cellEditorUtils";
  import {getDefaultMatchFilter} from "@/components/ag-grid/filterUtils";

  export default defineComponent({
    data() {
      return {
        url: '/restify/vendors',
        urlParams: {
          related: 'addresses'
        },
        addEntityInNewTab: true,
      }
    },
    computed: {
      columns(): Column[] {
        return [
          {
            headerName: this.$t('Code'),
            field: 'attributes.code',
            minWidth: 100,
            maxWidth: 120,
          },
          {
            headerName: this.$t('Name'),
            prop: 'attributes.name',
            minWidth: 160,
            maxWidth: 300,
            editable: true,
            headerClass: cellClasses.HeaderEditable,
            valueSetter: (params: ValueSetterParams) => {
              return valueSetterWithUpdate({
                storeAction: 'accountsPayable/patchVendor',
              })(params)
            },
          },
          {
            headerName: this.$t('Phone'),
            minWidth: 120,
            maxWidth: 200,
            prop: 'attributes.phone',
            component: 'PhoneLink',
          },
          {
            headerName: this.$t('Email'),
            prop: 'attributes.email',
            minWidth: 120,
            maxWidth: 200,
            component: 'EmailLink',
          },
          {
            headerName: this.$t('Status'),
            prop: 'attributes.status',
            minWidth: 120,
            component: 'Status',
            editable: true,
            cellEditor: cellEditors.Status,
            cellEditorParams: {
              options: VendorStatusOptions,
            },
            headerClass: cellClasses.HeaderEditable,
            valueSetter: (params: ValueSetterParams) => {
              return valueSetterWithUpdate({
                storeAction: 'accountsPayable/patchVendor',
              })(params)
            },
          },
          {
            headerName: this.$t('Address'),
            prop: 'addresses',
            minWidth: 120,
            maxWidth: 240,
            component: 'PrimaryAddressLink',
            hide: true,
          },
          {
            headerName: this.$t('Last Payment Date'),
            prop: 'attributes.last_payment_date',
            minWidth: 160,
            maxWidth: 200,
            component: 'FormattedDate',
          },
        ]
      },
      defaultMatchFilters() {
        return getDefaultMatchFilter('show_inactive_vendors')
      }
    }
  })
</script>
