<template>
  <div>
    <transition enter-active-class="transform transition ease-in-out duration-300"
                enter-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="transform transition ease-in-out duration-300"
                leave-class="opacity-100"
                leave-to-class="opacity-0"
                appear
    >
      <div v-show="showSection"
           class="fixed inset-0 top-0 transition-opacity">
        <div class="absolute inset-0 bg-gray-600 opacity-75"/>
      </div>
    </transition>
    <transition enter-active-class="transform transition ease-in-out duration-300"
                enter-class="translate-x-full"
                enter-to-class="translate-x-0"
                leave-active-class="transform transition ease-in-out duration-300"
                leave-class="translate-x-0"
                leave-to-class="translate-x-full"
                appear
    >
      <div class="fixed inset-0 overflow-hidden"
           v-if="showSection">
        <div class="absolute inset-0 overflow-hidden"
             @mousedown.self.stop="showSection = false">
          <section class="absolute inset-y-0 right-0 pl-10 max-w-full flex"
                   aria-labelledby="slide-over-heading">
            <div class="w-screen max-w-2xl bg-white shadow-xl">
              <div class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                <div class="w-full py-4">
                  <div class="px-4 sm:px-6">
                    <div class="flex items-start justify-between">
                      <h4 id="slide-over-heading" class="flex items-center">
                        <span class="mr-1">{{$t('Notes For:')}}</span>
                        <template v-if="notesCustomTitle">
                          <span>{{ notesCustomTitle }}</span>
                        </template>
                        <portal-target v-else name="entity-name"/>
                      </h4>
                      <div class="ml-3 h-7 flex items-center">
                        <button @click="showSection = false"
                                class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500">
                          <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                               stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                  d="M6 18L18 6M6 6l12 12"/>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="min-h-0 flex-1 flex flex-col py-6 overflow-y-scroll"
                     ref="scrollArea"
                     element-loading-background="rgba(0, 0, 0, 0)"
                     v-loading="loading">
                  <div class="relative flex-1 px-4 sm:px-6">
                    <NotesList
                      :data="notes"
                      :loading="loading"
                      :per-page="perPage"
                      :base-url="noteUrl"
                      @on-edit="editNote"
                      @on-delete="onNoteDelete"
                      ref="noteList"
                    />
                  </div>
                </div>
                <div class="px-4 py-4 flex flex-col bg-gray-50">
                  <base-input
                    rules="required"
                    class="w-full"
                  >
                    <template v-slot:container>
                      <div
                        id="html-editor-container"
                      >
                        <html-editor
                          v-model="model.content"
                          :focus-on-load="true"
                          :placeholder="$t('Add a note')"
                          :upload-url="uploadUrl"
                          scroll-target="#html-editor-container"
                          class="max-h-[400px] overflow-y-auto"
                          ref="editor"
                          id="note-input">
                        </html-editor>
                      </div>
                    </template>
                  </base-input>
                  <div class="flex-shrink-0 flex items-center mt-4"
                       :class="model.id ? 'justify-between' : 'justify-end'">
                    <template v-if="model.id">
                      <base-button variant="danger-link"
                                   @click.prevent.stop="resetForm()">
                        <MinusCircleIcon class="w-4 h-4 mr-1"/>
                        {{ $t('Cancel edit action') }}
                      </base-button>
                    </template>
                    <div>
                      <base-cancel-button class="mr-3"
                                          @click="closePanel">
                        {{ $t('Close') }}
                      </base-cancel-button>
                      <base-submit-button :loading="loading"
                                          :disabled="!model.content"
                                          @click="onSubmit"
                                          type="submit">
                        {{ model.id ? $t('Update') : $t('Save') }}
                      </base-submit-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
  import NotesList from '@/modules/dashboard/components/NotesList'
  import { MinusCircleIcon } from 'vue-feather-icons'
  import { RestifyResourceLabels } from "@/components/form/util";

  const EMPTY_MODEL = {
    content: null,
    title: '-',
  }

  export default {
    components: {
      NotesList,
      MinusCircleIcon,
    },
    data() {
      return {
        loading: false,
        showSection: false,
        model: {
          ...EMPTY_MODEL,
        },
        entity: {
          resourceName: '',
          id: null,
        },
        notes: [],
        perPage: 50,
      }
    },
    computed: {
      noteUrl() {
        return `/restify/${this.entity.resourceName}/${this.entity.id}/notes`
      },
      uploadUrl() {
        return `/restify/${this.entity.resourceName}/${this.entity.id}/actions?action=upload-resource-file`
      },
      storageKey() {
        return `prev-notes-count-${this.entity.id}`
      },
      notesPanelData() {
        return this.$store.state.notes.notesPanelData
      },
      notesCustomTitle() {
        const title = this.notesPanelData?.title
        if (!title) {
          return
        }
        const resource = this.notesPanelData?.resourceName
        const resourceName = RestifyResourceLabels[resource] || ''
        return `${resourceName} ${title}`
      }
    },
    methods: {
      setNotes(data) {
        this.notes = data
        this.$store.commit('notes/SET_CURRENT_NOTES', data)
      },
      togglePanel(entityParams) {
        this.showSection = true
        this.entity = { ...entityParams }
        this.setNotes([])
        this.getNotes()
      },
      async getNotes(entityParams) {
        if (entityParams) {
          this.entity = { ...entityParams }
        }
        if (!this.entity.resourceName || !this.entity.id) {
          return
        }
        try {
          this.loading = true
          const { data, meta } = await this.$store.dispatch('notes/getNotes', {
            resourceName: this.entity.resourceName,
            resourceId: this.entity.id,
            params: {
              sort: 'created_at',
              perPage: entityParams ? 1 : this.perPage,
            }
          })
          const notes = data.reverse().map(note => ({ id: note.id, ...note.attributes, ...note.relationships }))
          this.setNotes(notes)
          if (!entityParams) {
            localStorage.setItem(this.storageKey, meta.total)
          }
          const prevValue = localStorage.getItem(this.storageKey) || 0
          this.$store.commit('notes/SET_PREV_NOTES_COUNT', Number(prevValue))
          this.$store.commit('notes/SET_CURRENT_NOTES_COUNT', meta.total)
        } catch (err) {
          if (err.handled) {
            return
          }
        } finally {
          this.loading = false
          this.scrollToBottom()
        }
      },
      scrollToBottom() {
        this.$nextTick(() => {
          const scrollEl = this.$refs.scrollArea
          if (scrollEl) {
            scrollEl.scrollTo(0, scrollEl.scrollHeight)
          }
        })
      },
      closePanel() {
        this.showSection = false
        this.$store.commit('notes/RESET_NOTES_PANEL')
      },
      async onSubmit() {
        this.loading = true
        const noteList = this.$refs.noteList
        if (!noteList) {
          return
        }
        await noteList.submitForm(this.model)
        if (!this.model.id) {
          this.scrollToBottom()
        }
        this.resetForm()
        this.loading = false
        const currentTotal = this.$store.state.notes.currentNotesCount
        this.setNotesCount(currentTotal + 1)
      },
      onNoteDelete() {
        const currentTotal = this.$store.state.notes.currentNotesCount
        this.setNotesCount(currentTotal - 1)
      },
      setNotesCount(count) {
        localStorage.setItem(this.storageKey, count)
        this.$store.commit('notes/SET_PREV_NOTES_COUNT', count)
        this.$store.commit('notes/SET_CURRENT_NOTES_COUNT', count)
      },
      focusOnEditor() {
        const input = document.querySelector('#note-input')
        if (!input) {
          return
        }
        input.focus()
      },
      async editNote(note) {
        this.model = {
          ...note,
        }
        this.triggerActionMenu(note)
        this.focusOnEditor()
      },
      triggerActionMenu(note) {
        const state = !note?.actions
        this.$set(note, 'actions', state)
      },
      resetForm() {
        this.model = { ...EMPTY_MODEL }
      },
    },
  }
</script>
