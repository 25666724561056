<template>
  <div>
    <layout-tabs :items="filteredItems"/>
    <router-view/>
  </div>
</template>
<script>

  export default {
    data() {
      return {
        items: [
          {
            name: this.$t('Work Order Detail'),
            path: '/service-billing/reports/work-order-detail',
            permission: 'service_billing_reports',
          },
          {
            name: this.$t('Work Order Listing'),
            path: '/service-billing/reports/work-order-listing',
            permission: 'service_billing_reports',
          },
          {
            name: this.$t('Work Order Profit Summary'),
            path: '/service-billing/reports/work-order-profit-summary',
            permission: 'service_billing_reports',
          },
          {
            name: this.$t('Service Billing History'),
            path: '/service-billing/reports/service-billing-history',
            permission: 'service_billing_reports',
          },
        ],
      }
    },
    computed: {
      filteredItems() {
        return this.items.filter(item => this.$can(item.permission))
      },
    },
  }
</script>
