import EmptyLayout from "@/components/common/EmptyLayout";
import RebuildActionsLayout from "@/modules/support/layout/RebuildActionsLayout";
import RebuildActions from "@/modules/support/pages/rebuild-actions";

export default [
  {
    path: '/support',
    name: 'Support',
    component: EmptyLayout,
    redirect: '/support/rebuild-actions/jc-amounts',

    children:[
      {
        path: 'rebuild-actions',
        component: RebuildActionsLayout,
          children: [
            {
              path: 'jc-amounts',
              component: RebuildActions,
            },
        ]
      }
    ]
  }
]
