<template>
  <AgDataTable
    :url="url"
    :compact="true"
    :columns="columns"
    :url-params="urlParams"
    :default-sort="defaultSort"
    :actionsColumnWidth="195"
    permission="line_items"
    dom-layout="autoHeight"
    hide-actions="add,delete,view,edit"
    actions="search,refresh"
    entity="line-items"
    class="print:mt-4 -mt-6"
    ref="table"
  >
    <template #additional-actions-before>
      <slot name="actions"></slot>
      <div class="min-w-[350px]">
        <base-select
          v-model="lineItemType"
          :label="$t('Line Item Type')"
          :placeholder="$t('Line Item Type')"
          :add-entity="false"
          :options="lineItemTypeOptions"
        />
      </div>
    </template>

    <template #attributes.links="{row}">
      <div class="flex items-start flex-col pl-4 py-1">
        <template v-if="row.attributes.is_linked">
          <span>{{ $t(`This line item is already linked to ${oppositeTypeName} line item.`) }}</span>
        </template>
        <template v-else>
          <LineItemLink
            v-for="id in row.attributes.links"
            :id="id"
            :key="id"
            :is-link="false"
          />
        </template>
      </div>
    </template>
  </AgDataTable>
</template>
<script>
import { globalResources, moduleAccountTypes, storeMutations } from "@/components/form/util";
import axios from "axios";
import { cellClasses } from "@/components/ag-grid/columnUtils";

export default {
  data() {
    return {
      defaultSort: 'phase_code,cost_code,change_order',
      lineItemType: moduleAccountTypes.Cost,
      lineItemTypeOptions: [
        {
          label: this.$t('Cost Line Items'),
          value: moduleAccountTypes.Cost,
        },
        {
          label: this.$t('Income Line Items'),
          value: moduleAccountTypes.Income,
        },
      ]
    }
  },
  computed: {
    oppositeType() {
      if (this.lineItemType === moduleAccountTypes.Cost) {
        return moduleAccountTypes.Income
      }
      return moduleAccountTypes.Cost
    },
    oppositeTypeName() {
      if (this.oppositeType === moduleAccountTypes.Cost) {
        return this.$t('a cost')
      }
      return this.$t('an income')
    },
    jobId() {
      return this.$route.params.id
    },
    url() {
      return '/restify/line-items'
    },
    urlParams() {
      return {
        job_id: this.jobId,
        type: this.lineItemType,
      }
    },
    columns() {
      const linkHeaderName = this.lineItemType === moduleAccountTypes.Cost
        ? this.$t('Linked Income Line Items')
        : this.$t('Linked Cost Line Items')

      return [
        {
          headerName: this.$t('Description'),
          field: 'attributes.description',
          minWidth: 180,
          maxWidth: 320,
        },
        {
          headerName: this.$t('Phase'),
          field: 'attributes.phase_code',
          minWidth: 60,
          maxWidth: 80,
        },
        {
          headerName: this.$t('Cost Code'),
          field: 'attributes.cost_code',
          minWidth: 60,
          maxWidth: 80,
        },
        {
          headerName: this.$t('Change Order'),
          field: 'attributes.change_order',
          minWidth: 60,
          maxWidth: 70,
          align: 'center',
        },
        {
          headerName: linkHeaderName,
          field: 'attributes.links',
          minWidth: 300,
          flex: 1,
          wrapText: true,
          autoHeight: true,
          editable: params => {
            return !params.data?.attributes?.is_linked
          },
          cellEditor: this.$cellEditors.GlobalResourceSelect,
          cellClass: params => {
            if (params.data?.attributes?.is_linked) {
              return cellClasses.ReadOnly
            }
          },
          cellEditorParams: (params) => {
            const resourceName = this.lineItemType === moduleAccountTypes.Cost ? globalResources.IncomeLineItems : globalResources.CostLineItems
            return {
              resourceName,
              multiple: true,
              filterMethod: (option) => {
                if (option.is_linked || option.links?.length > 0) {
                  option.disabled = true
                }
                if (this.jobId) {
                  return option.job_id === this.jobId
                }
                return true
              },
              target: '_blank',
              class: 'min-w-[400px]'
            }
          },
          valueSetter: params => {
            const { data } = params
            let links = params.newValue || []
            if (!Array.isArray(links)) {
              links = [links]
            }
            data.attributes.links = links
            this.saveLineItemLinks(data, links)
            return true
          }
        },
      ]
    }
  },
  methods: {
    updateLinkedLineItems(links, lineItemToUpdate) {
      const lineItems = this.$store.getters['globalLists/getResourceList'](this.$globalResources.LineItems) || []
      const newLineItems = []
      lineItems.forEach(lineItem => {
        if (links.includes(lineItem.id)) {
          lineItem.is_linked = true
        }
        if (lineItem.id === lineItemToUpdate) {
          lineItem.links = links
        }
        newLineItems.push(lineItem)
      })
      this.$store.commit(`globalLists/${storeMutations.SetResource}`, { data: newLineItems, resource: globalResources.LineItems })
    },
    async saveLineItemLinks(lineItem, links) {
      await axios.post(`/restify/line-items/actions?action=link-line-items`, {
        repositories: 'all',
        job_id: this.jobId,
        links: {
          [lineItem.id]: links,
        },
      })
      this.updateLinkedLineItems(links, lineItem.id)
    }
  }
}
</script>
