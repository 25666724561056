<template>
  <base-popover
    :parent-entity-id="`popover-status-${batch.id}`"
    :placement="placement"
    :ref="`popover-status-${batch.id}`"
  >
    <template #body>
      <div v-if="canChangeStatus" class="w-32 flex-col">
        <base-radio v-for="(label, value) in pendingStatuses"
                    :value="status"
                    :label="value"
                    :key="`radio-status-${value}`"
                    :active="value === status"
                    name="status"
                    @input="changeBatchStatus(batch, $event)"
                    inline>
          {{ label }}
        </base-radio>
      </div>
    </template>
    <template #reference>
      <div class="flex justify-end">
        <status-link :row="batch"/>
      </div>
    </template>
  </base-popover>
</template>
<script>
import axios from 'axios'

export default {
  props: {
    batch: {
      type: Object,
      default: () => ({})
    },
    placement: {
      type: String,
      default: 'right'
    },
  },
  data() {
    return {
      pendingStatuses: {
        [this.$resourceStatuses.NoCompute]: this.$t('No Compute'),
        [this.$resourceStatuses.Pending]: this.$t('Pending'),
      },
    }
  },
  computed: {
    status() {
      return this.batch?.attributes?.status
    },
    canChangeStatus() {
      return [this.$resourceStatuses.NoCompute, this.$resourceStatuses.Pending].includes(this.status)
    },
  },
  methods: {
    async changeBatchStatus(batch, status) {
      const oldStatus = batch.attributes.status
      try {
        this.$set(batch, 'attributes.status', status)
        const data = {
          ...batch.attributes,
          status
        }
        let batchResult
        if (status === this.$resourceStatuses.NoCompute) {
          batchResult = await axios.post(`/restify/timecard-batches/${batch.id}/actions?action=no-compute-on`, data)
        } else {
          batchResult = await axios.post(`/restify/timecard-batches/${batch.id}/actions?action=no-compute-off`, data)
        }
        const finalBatch = {
          ...batch,
          ...batchResult.data,
        }
        this.$set(batch, 'attributes', finalBatch.attributes)
        this.$emit('status-change', finalBatch)
      } catch (err) {
        this.$error(this.$t('Could not update the status.'))
        batch.attributes = oldStatus
        this.$set(batch, 'attributes', batch)
      }
    }
  }
}
</script>
