<template>
  <BottomSummary
    :data="data"
    class="w-[800px] !p-0"
    @close="$emit('close')"
      >
    <OverrideSummaryField
      class="pl-4 col-span-3">
      <div class="mb-2 -ml-1 flex flex-wrap space-x-2">
        <span class="text-sm text-gray-500">
          {{ $t('Worked Hours') }}
        </span>
        <EmployeeLink :data="employee" link-class="text-sm"/>
      </div>
      <div class="w-full flex flex-wrap gap-6">
        <div v-for="day in days" :key="day.value" class="flex flex-col space-y-2 min-w-[70px]">
          <span>{{ day.label }}</span>
          <span class="text-right">{{getHours(day.value)}}</span>
        </div>
      </div>
    </OverrideSummaryField>
  </BottomSummary>
</template>
<script>
  import OverrideSummaryField from '@/modules/payroll/components/timecard/OverrideSummaryField.vue';
  import { defineComponent } from 'vue';
  import CloseButton from "@/components/common/CloseButton.vue";
  import BottomSummary from "@/components/common/BottomSummary.vue";
  import sumBy from "lodash/sumBy";
  import { formatHours } from "@/plugins/formatHours";


  export default defineComponent({
    components: {
      BottomSummary,
      CloseButton,
      OverrideSummaryField,
    },
    props: {
      data: {
        type: [Object, null],
        default: () => ({}),
        required: true,
      },
      days: {
        type: Array,
        default: () => [],
      },
      employee: {
        type: Object,
        default: () => ({}),
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      getHours(day) {
        const entries = this.data.entries.filter(entry => entry.date === day);
        const totalHours = sumBy(entries, (row) => {
          return +row.regular_hours + +row.overtime_hours + +row.premium_hours;
        })
        return formatHours(totalHours)
      }
    }
  })
</script>
