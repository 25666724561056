<template>
  <div>
    <base-alert
        v-if="displayWarning"
                :type="$promptType.Warning"
    >
      {{ $t('This basis is reserved for Non-Taxable Benefits.') }}
    </base-alert>
  </div>
</template>
<script>
  import pick from 'lodash/pick'
  import { TOTAL_TAXED_PAY_KEY } from '@/modules/payroll/components/rates/util'

  const FIELDS_TO_VALIDATE = [
    'subject_to_fit',
    'subject_to_sit',
    'subject_to_lit',
  ]
  const WITH_HOLDING_ACTIVE_KEY = 'yes'

  export default {
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      displayWarning() {
        if (this.data.multiply_by !== TOTAL_TAXED_PAY_KEY) {
          return false
        }

        const obj = pick(this.data, FIELDS_TO_VALIDATE)
        return Object.values(obj).find(el => el === WITH_HOLDING_ACTIVE_KEY)
      },
    },
  }
</script>
