<template>
  <WorkSitesTable/>
</template>
<script>
import WorkSitesTable from "@/modules/service-billing/components/worksite/WorkSitesTable.vue";

export default {
  components: {
    WorkSitesTable,
  }
}
</script>
