<template>
  <base-data-table
    v-bind="defaultAttributes"
    :filters="filters"
    :columns="columns"
    :data="getData"
    :total-rows="rowsLength"
    @force-pagination="forcePagination"
    class="service-billing-history-report"
  >

    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>
    <template #header="{row}">
      <div :style="{marginLeft: `${row?.header?.level * 20}px`}">
        {{ row.header?.title }}
      </div>
    </template>

    <template #work_order="{row}">
      <WorkOrderLink
        :data="row.work_order"
        :show-description="false"
        :show-preview="false"
      />
    </template>
    <template #customer="{row}">
      <CustomerLink
        :data="row.customer"
        :show-description="false"
        :show-preview="false"
      />
    </template>
    <template #business="{row}">
      <BusinessLink v-if="row.business" :data="row"/>
    </template>
    <template #document="{row}">
      <ReportDocumentLink
        :data="row.document"
        :row="row"
      />
    </template>
    <template #worksite="{row}">
      <WorksiteLink
        :data="row.worksite"
        :show-name="false"
      />
    </template>
    <template #journal="{row}">
      <JournalLink
        :data="row.journal"
        :show-name="false"
      />
    </template>

    <template #fiscal_period="{row}">
      <div class="flex space-x-1 items-center">
        <span>{{ row.period }}</span>
        <span>{{ row.fiscal_year }}</span>
      </div>
    </template>

    <template #quantity="{row}">
      <QuantityCell :data="row"/>
    </template>

    <template #cost="{row}">
      <CostCell :data="row"/>
    </template>

    <template #subtotal="{subtotal}">
      <td colspan="5"/>
      <td colspan="7">
        <div
          class="flex justify-between items-center"
          :style="{marginLeft: `${subtotal.level * -20}px`}"
        >
          <div>{{ subtotal.title }}</div>
          <div>
            <span class="mr-4">({{ $t('Actual Labor Hours') }}</span> {{ $formatHours(subtotal.labor_hours) }},
            <span class="mr-4">{{ $t('Billed') }}</span> {{ $formatHours(subtotal.billed_hours) }})
          </div>
        </div>
      </td>
      <td class="text-right !pr-1">
        {{ $formatPrice(subtotal.cost) }}
      </td>
      <td class="text-right !pr-1">
        {{ $formatPrice(subtotal.billed) }}
      </td>
      <td class="text-right !pr-1">
        {{ $formatPrice(subtotal.sales_tax_amount) }}
      </td>
    </template>

    <template #footer-row>
      <td colspan="15">
        <ServiceBillingTypesTotalsFooter
          :grand-totals="grandTotals"
          billing-types-cost-path="service_billing_types.cost"
          billing-types-income-path="service_billing_types.income"
        />
      </td>
    </template>
  </base-data-table>
</template>
<script>
import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'
import ServiceBillingTypesTotalsFooter
  from "@/modules/service-billing/components/reports/ServiceBillingTypesTotalsFooter.vue";
import {
  getWorkOrderDetailSortOptions,
  WODetailSortBy, WorkOrderDetailFormats
} from "@/modules/service-billing/components/reports/sortByOptions";
import BusinessLink from "@/components/links/BusinessLink.vue";
import QuantityCell from "@/modules/service-billing/components/reports/QuantityCell.vue";
import ReportDocumentLink from "@/components/links/ReportDocumentLink.vue";
import CostCell from "@/modules/service-billing/components/reports/CostCell.vue";

export default {
  components: {
    CostCell,
    ReportDocumentLink,
    QuantityCell,
    BusinessLink,
    ServiceBillingTypesTotalsFooter
  },
  extends: ReportTableWrapper,
  props: {
    format: {
      required: true,
      type: String,
      default: '',
    },
    filters: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {}
  },
  computed: {
    columns() {
      return [
        {
          label: this.$t('Work <br> Order'),
          prop: 'work_order',
          minWidth: 60,
          maxWidth: 80,
        },
        {
          label: this.$t('Customer'),
          prop: 'customer',
          minWidth: 50,
          maxWidth: 80,
        },
        {
          label: this.$t('Site <br> No.'),
          prop: 'worksite',
          minWidth: 50,
          maxWidth: 80,
        },
        {
          label: this.$t('Journal'),
          prop: 'journal',
          minWidth: 50,
          maxWidth: 80,
        },
        {
          label: this.$t('Fiscal <br> Period'),
          prop: 'fiscal_period',
          minWidth: 50,
          maxWidth: 80,
        },
        {
          label: this.$t('Posted <br> Date'),
          prop: 'created_At',
          minWidth: 100,
          maxWidth: 120,
          component: "FormattedDate"
        },
        {
          label: this.$t('G/L <br> Account'),
          prop: 'account',
          minWidth: 50,
          maxWidth: 70,
        },
        {
          label: this.$t('Type'),
          prop: 'cost_type',
          minWidth: 50,
          maxWidth: 70,
        },
        {
          label: this.$t('Serv <br> Code'),
          prop: 'service_billing_code.code',
          minWidth: 50,
          maxWidth: 70,
        },
        {
          label: this.$t('Ven/Emp <br> Mtl/Eqp'),
          prop: 'business',
          minWidth: 50,
          maxWidth: 70,
        },
        {
          label: this.$t('Invoice'),
          prop: 'document',
          minWidth: 50,
          maxWidth: 70,
        },
        {
          label: this.$t('Quantity <br> Hours'),
          prop: 'quantity',
          align: 'right',
          minWidth: 100,
          maxWidth: 120,
        },
        {
          label: this.$t('Cost'),
          prop: 'cost',
          minWidth: 100,
          maxWidth: 140,
          align: 'right',
          component: 'FormattedPrice',
          summary: () => this.$formatPrice(this.grandTotals?.cost),
        },
        {
          label: this.$t('Billed'),
          prop: 'billed',
          minWidth: 100,
          maxWidth: 140,
          align: 'right',
          component: 'FormattedPrice',
          params: {
            hideZero: true,
          },
          summary: () => this.$formatPrice(this.grandTotals?.billed),
        },
        {
          label: this.$t('Sales <br> Tax'),
          prop: 'sales_tax_amount',
          minWidth: 100,
          maxWidth: 140,
          align: 'right',
          component: 'FormattedPrice',
          params: {
            hideZero: true,
          },
          summary: () => this.$formatPrice(this.grandTotals?.sales_tax_amount),
        },
      ]
    },
    sortByTitle() {
      return getWorkOrderDetailSortOptions().find(o => o.value === this.filters?.sort_by)?.label
    },
    isDetailFormat() {
      return this.filters?.format === WorkOrderDetailFormats.Detail
    }
  },
  methods: {
    processWorkOrderEntries(data, level = 0)  {
      for (let key in data) {
        const value = data[key]
        let entries = value.entries || []
        const totals = value.totals || {}
        let title = `Work Order ${key}`

        if (this.isDetailFormat) {
          this.rows.push({
            header: {
              title: title,
              level,
            }
          })

          this.rows.push(...entries)
        }

        this.rows.push({
          subtotal: {
            title,
            ...totals,
          },
        })
      }
    },
    processNonWorkOrders(data) {
      let level = 1
      for (let key in data) {
        const value = data[key]
        const entries = value.entries || {}
        const totals = value.totals || {}
        let title = `${this.sortByTitle} ${key}`

        this.rows.push({
          header: {
            title: title,
            level: 0,
          }
        })

        this.processWorkOrderEntries(entries, level)

        this.rows.push({
          subtotal: {
            title,
            ...totals,
          },
        })
      }
    },
    composeRows(data) {
      const isNonWorkOrderSort = this.filters?.sort_by !== WODetailSortBy.WorkOrder
      if (isNonWorkOrderSort) {
        this.processNonWorkOrders(data)
      } else {
        this.processWorkOrderEntries(data)
      }
      return this.rows
    },
  },
}
</script>
