<template>
  <FiscalYearSelect
      ref="select"
      v-focus
      v-model="selectedValue"
      :show-full-label="false"
      inline-errors
      class="w-full"
      @input="onChange"
      @keydown.enter.native="goToNextCell"
  />
</template>
<script>

  import { selectEditorMixin } from "@/components/ag-grid/cellEditors/selectEditorMixin";

  export default {
    name: 'FiscalYearSelectCellEditor',
    mixins: [selectEditorMixin],
  }
</script>
