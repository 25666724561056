<template>
  <div>
    <transition enter-active-class="transform transition ease-in-out duration-300"
                enter-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="transform transition ease-in-out duration-300"
                leave-class="opacity-100"
                leave-to-class="opacity-0"
                appear
    >
      <div v-show="menuOpened"
           class="fixed inset-0 transition-opacity z-999999"
           :class="sidebarExpanded ? 'ml-108' : 'ml-64'"
           @click="hideMenu">
        <div class="absolute inset-0 bg-gray-600 opacity-50"/>
      </div>
    </transition>
    <transition enter-active-class="transform transition ease-in-out duration-300"
                enter-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="transform transition ease-in-out duration-300"
                leave-class="opacity-100"
                leave-to-class="opacity-0"
                appear
    >
      <div v-show="menuOpened"
           class="h-screen flex flex-shrink-0 w-44 absolute z-50"
           :class="overlayClass">
        <div class="flex flex-col flex-1 pt-20"
             :class="{
                'bg-gray-900': theme === 'dark',
                'bg-white': theme === 'white',
                'bg-primary-900': theme === 'primary',
             }"
        >
          <template v-for="item in filteredItems">
            <router-link
                :to="item.path"
                :key="`${item.path}-${item.name}`"
                class="submenu-item mx-2 my-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium rounded-md focus:outline-none transition ease-in-out duration-150"
                tabindex="0"
                :data-test="`submenu-${item.name}`"
                :group="item.name"
                :class="{
                  'text-gray-300 hover:bg-gray-700 hover:text-white focus:text-white focus:bg-gray-700': theme === 'dark',
                  'text-gray-600 hover:text-gray-900 hover:bg-gray-50 focus:bg-gray-100': theme === 'white',
                  'text-primary-200 hover:bg-primary-700 focus:text-white focus:bg-primary-700 hover:text-white': theme === 'primary',
                  'router-link-active' : $route.fullPath.includes(item.path),
                }"
                @click.native="hideMenu"
            >
              {{ $t(item.name) }}
            </router-link>
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>

  export default {
    props: {
      menuOpened: Boolean,
      sidebarExpanded: Boolean,
      theme: String,
      items: Array,
      overlayClass: {
        type: String,
        default: 'ml',
      },
    },
    computed: {
      filteredItems() {
        const permissions = this.$store.getters['auth/permissions']
        return this.items.filter(item => {
          if (!item.productionReady && this.isProduction) {
            return false
          }
          return permissions.includes(item.permission)
        })
      },
    },
    methods: {
      hideMenu() {
        this.$emit('hide-menu')
      },
    },
  }
</script>
<style lang="scss">
  .ml-108 {
    margin-left: 27rem;
  }

  .w-44 {
    width: 11rem;
  }

  .ml-64 {
    margin-left: 16rem;
  }

  .pt-20 {
    padding-top: 5rem;
  }

  .ml-20 {
    margin-left: 5rem;
  }

  .theme-dark .submenu-item.router-link-active {
    @apply bg-gray-700;
  }

  .theme-light .submenu-item.router-link-active {
    @apply bg-gray-100;
  }

  .theme-primary .submenu-item.router-link-active {
    @apply bg-primary-900;
  }
</style>
