<template>
  <div v-if="jobIds.length">
    <router-link v-for="(jobNumber, index) in jobNumbers"
                 :key="jobNumber+index"
                 :to="getPath(index)"
                 :tabindex="-1"
                 class="mr-2"
    >
      {{ jobNumber }}
    </router-link>
  </div>
</template>
<script>
export default {
  props: {
    jobNumbers: {
      type: Array,
      default: () => []
    },
    jobIds: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    getPath(index) {
      if (!this.jobIds[index]) {
        return `/job-costing/jobs`
      }
      return `/job-costing/jobs/${this.jobIds[index]}/view`
    }
  }
}
</script>
<style>
</style>
