<template>
  <div class="grid grid-cols-5 gap-x-2 print:text-print">
    <span class="tracking-wide font-medium text-sm print:text-print leading-normal text-gray-400 mr-2 mb-2">
      {{ title || $t('Customer') }}
    </span>
    <div class="col-span-4 border-l border-gray-200 pl-2">
      <customer-link
          v-if="showDetailsOnHover"
          :data="customer"
          :show-name="true"
          :show-code="false"
          link-class="font-semibold tracking-wider text-base print:text-print text-gray-900"
      />
      <template v-else>
        <div class="font-semibold tracking-wider text-base print:text-print text-gray-900">
          {{ customer.code }}
        </div>
        <div class="font-semibold tracking-wider text-base print:text-print text-gray-900">
          {{ customer.name }}
        </div>
      </template>
      <div class="leading-tight tracking-wide space-y-2 text-sm print:text-print mt-2">
        <base-address-link v-if="customerAddress" :value="customerAddress"/>
        <div>
          {{ customer.contact || $t('No contact provided') }}
        </div>
        <base-email-link :value="customer.email"/>
        <base-phone-link :value="customer.phone"/>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      customer: {
        type: Object,
        default: () => ({})
      },
      title: {
        type: String,
      },
      showDetailsOnHover: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      customerAddress() {
        const { addresses } = this.customer
        if (!addresses || !addresses.length) {
          return ''
        }
        return addresses.find(address => address.type === 'billing') || addresses[0]
      }
    }
  }
</script>
