<template>
  <work-orders-list :work-site-id="workSiteId"/>
</template>
<script>
import WorkOrdersList from "@/modules/service-billing/pages/work-orders/work-orders-list.vue";

export default {
  name: 'WorksiteWorkOrders',
  components: {
    WorkOrdersList
  },
  props: {
    workSiteId: {
      type: String,
    }
  },
}
</script>
