<template>
  <div class="flex flex-col">
    <div class="mb-6">
      <CustomerHistoryFilters @on-filter="onApplyFilters"/>
    </div>
    <CustomerHistoryTable :url-params="filters"/>

    <base-back-link to="/accounts-receivable/customers"/>
  </div>
</template>
<script>
  import CustomerHistoryTable from '@/modules/accounts-receivable/components/customer-history/CustomerHistoryTable'
  import CustomerHistoryFilters from '@/modules/accounts-receivable/components/customer-history/CustomerHistoryFilters'

  export default {
    components: {
      CustomerHistoryTable,
      CustomerHistoryFilters,
    },
    data() {
      return {
        filters: null,
      }
    },
    methods: {
      onApplyFilters(data) {
        this.filters = data
      },
    },
  }
</script>
