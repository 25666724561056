<template>
  <BillingRatesTable :rates-columns="columns"
                     source-type="craft-code"
  />
</template>
<script>
  import BillingRatesTable from '@/modules/accounts-receivable/pages/billing-rates/BillingRatesTable'

  export default {
    components: {
      BillingRatesTable,
    },
    data() {
      return {
        columns: [
          {
            label: this.$t('Craft Code'),
            prop: 'code',
            component: 'EntityLink',
            redirectTo: '/equipment/list',
          },
          {
            label: this.$t('Description'),
            prop: 'description',
          },
        ],
      }
    },
  }
</script>
