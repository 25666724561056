<template>
  <BaseDataTable
    v-bind="defaultAttributes"
    :columns="columns"
    :data="getData"
    :total-rows="rowsLength"
    :has-summary="false"
    :compact="true"
    class="no-borders"
    @force-pagination="forcePagination"
  >

    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template #thead-infos>
      <th />
      <th colspan="2" align="center" class="thead-infos">
        <span class="flex w-full justify-center mr-2 border-b-2 border-dashed pb-2">
          {{ $t('Deduction') }}
        </span>
      </th>
      <th colspan="7" />
    </template>

    <template #header="{row}">
      <div class="flex items-inline-base">
        <EmployeeLink
          :data="row.header"
          :show-preview="false"
          class="min-w-[400px]"
        />
        <div class="flex space-x-2">
          <span>{{ row.header?.masked_ssn }}</span>
          <span>{{ $t('Birthday') }}</span>
          <span>{{ $formatDate(row.header.dob) }}</span>
          <span>{{ $t('Hire Date') }}</span>
          <span>{{ $formatDate(row.header.hire_date) }}</span>
          <span>{{ $t('Term Date') }}</span>
          <span>{{ $formatDate(row.header.term_date) }}</span>
        </div>
      </div>
    </template>

 
    <template #subtotal="{subtotal}">
      <td/>
      <td align="right" class="top-border">
        {{ subtotal.description || $t('Subtotal') }}:
      </td>
      <td colspan="4" align="left" class="top-border">
        (<div class="inline-flex justify-between space-x-2">
            <div class="flex space-x-2">
              <span>{{  $t('total taxable earnings')  }}=</span>
              <span>{{ $formatPrice(subtotal?.['taxable_amount']) }}</span>
            </div>
            <div class="flex space-x-2">
              <span>{{  $t('total hours')  }}=</span>
              <span>{{ $formatHours(subtotal?.['total_hours']) }}</span>
            </div>
          </div>)
      </td>
      <td /> 
      <td align="right" class="top-border">
        {{ $formatPrice(subtotal?.['125s_amount']) }}
      </td>
      <td align="right" class="top-border">
        {{ $formatPrice(subtotal?.['401k_amount']) }}
      </td>
      <td align="right" class="top-border">
        {{ $formatPrice(subtotal?.['401k_employer_amount']) }}
      </td>
    </template>

    <template #html-row="{ htmlData }">
      <td />
      <td colspan="9" class="font-semibold py-4 px-2">
        <div class="grid grid-cols-12">
          <BaseDataTable
            :data="htmlData"
            :title="$t('Summary')"
            :showPagination="false"
            :columns="summaryColumns"
            class="col-span-6"
          />
        </div>
      </td>
    </template>
  </BaseDataTable>
</template>
<script>
import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'

export default {
  extends: ReportTableWrapper,
  computed: {
    columns() {
      return [
        {
          label: this.$t('Employee'),
          prop: '',
          align: 'left',
          minWidth: 40,
          maxWidth: 40,
        },
        {
          label: this.$t('Code'),
          prop: 'special_source.code',
          align: 'left',
          minWidth: 80,
          maxWidth: 110,
        },
        {
          label: this.$t('Description'),
          prop: 'special_source.description',
          align: 'left',
          minWidth: 100,
          maxWidth: 120,
        },
        {
          label: this.$t('Payment #'),
          prop: 'payroll.payment_number',
          align: 'left',
          minWidth: 80,
          maxWidth: 110,
        },
        {
          label: this.$t('Payment <br> Date'),
          prop: 'payroll.payment_date',
          align: 'left',
          minWidth: 80,
          maxWidth: 110,
          component: 'FormattedDate'
        },
        {
          label: this.$t('Period <br> End Date'),
          prop: 'payroll.period_end_date',
          align: 'left',
          minWidth: 80,
          maxWidth: 110,
          component: 'FormattedDate'
        },
        {
          label: this.$t('Quarter/Year'),
          prop: 'quarter_year',
          align: 'center',
          minWidth: 80,
          maxWidth: 110,
        },
        {
          label: this.$t('125S <br> Deduct'),
          prop: '_125s_amount',
          align: 'right',
          minWidth: 80,
          maxWidth: 110,
          component: 'FormattedPrice',
          hideZero: true,
        },
        {
          label: this.$t('401K <br> Deduct'),
          prop: '_401k_amount',
          align: 'right',
          minWidth: 80,
          maxWidth: 110,
          component: 'FormattedPrice',
          hideZero: true,
        },
        {
          label: this.$t('Employer <br> 401K Match'),
          prop: '_401k_match',
          align: 'right',
          minWidth: 80,
          maxWidth: 110,
          component: 'FormattedPrice',
          hideZero: true,
        },
      ]
    },
    summaryColumns() {
      return [
        {
          label: this.$t('Deduction/Benefit'),
          prop: 'source',
        },
        {
          label: this.$t('Amount'),
          prop: 'amount',
          component: 'FormattedPrice',
        }
      ]
    },
    print_detail() {
      return this.options.print_detail
    }
  },
  methods: {
    mapEntry(employeeEntry) {
      const entryAmount = employeeEntry.entry.amount
      const sourceType = employeeEntry.entry.special_source_type
      const sourceScope = employeeEntry.entry.scope

      let _401k_amount, _125s_amount, _401k_match

      if (sourceType === 'flexible-benefit-plan') {
        _125s_amount = entryAmount
      } else if (sourceType === 'retirement-plan') {
        if (sourceScope === 'employer') {
          _401k_match = entryAmount
        } else {
          _401k_amount = entryAmount
        }
      }

      const year = employeeEntry.payroll.year_quarter.substring(0, 4)
      const quarter = employeeEntry.payroll.year_quarter.substring(4)

      const quarter_year = `${quarter}/${year}`

      return {
        ...employeeEntry,
        _401k_amount,
        _125s_amount,
        _401k_match,
        quarter_year,
      }
    },
    addSummary() {
      const htmlData = this.data.code_totals.map(summary => {
        const {
          code = '',
          description = '',
        } = summary.special_source || {}

        const source = `${code} ${description}`

        return {
          source,
          amount: summary.amount,
        }
      })
      this.rows.push(
        {
          htmlData,
        },
      )
    },
    composeRows(data) {

      data.forEach(group => {

        this.rows.push(
            {
              header: group.employee,
            },
        )
        if (this.print_detail) {
          this.rows.push(...group.entries.map(this.mapEntry))
        }

        this.rows.push(
        {
          subtotal: group.total,
        })
      })

      this.rows.push(
        {
          subtotal: {
            ...this.data.grand_totals,
            description: this.$t('Grand Total'),
          },
        },
      )

      this.addSummary()

      return this.rows
    },
  }
}
</script>
