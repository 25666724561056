<template>
  <base-data-table
      v-bind="defaultAttributes"
      :columns="columns"
      :data="getData"
      :total-rows="rowsLength"
      :has-summary="!!includeBudgets"
      @force-pagination="forcePagination"
  >

    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template #header="{row}">
      <AccountLink :data="row.header"/>
    </template>

    <template #subtotal="{row}">
      <template v-if="row.subtotal['account']">
        <td colspan="2" align="right">
          <dl>
            <dt>{{ $t('Total G/L Account') + ' ' + row.subtotal['account'] }}:</dt>
            <dt>&nbsp;</dt>
          </dl>
        </td>
      </template>
      <template v-else>
        <td colspan="2" align="right">
          <dl>
            <dt>{{ $t('First 6 Periods:') }}</dt>
            <dt>{{ $t('Last 6 Periods:') }}</dt>
          </dl>
        </td>
      </template>

      <td v-for="i in 6" align="right">
        <dt>{{ $formatPrice(row.subtotal[`p${i}_total`]) }}</dt>
        <dt>{{ $formatPrice(row.subtotal[`p${i + 6}_total`]) }}</dt>
      </td>

      <td align="right">
        <dt>&nbsp;</dt>
        <dt>{{ $formatPrice(row.subtotal['total']) }}</dt>
      </td>
    </template>

    <template v-for="({prop}, index) in amountColumns" #[getSlotName(prop)]>
      <dl>
        <dt>{{ $formatPrice(grandTotals[`p${index+1}_total`]) }}</dt>
        <dt>{{ $formatPrice(grandTotals[`p${index + 7}_total`]) }}</dt>
      </dl>
    </template>

    <template #total_summary>
      <dl>
        <dt>&nbsp;</dt>
        <dt>{{ $formatPrice(grandTotals.total) }}</dt>
      </dl>
    </template>

  </base-data-table>
</template>
<script>
  import { getOrderedMoths } from '@/modules/ledger/components/reports/util'
  import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'

  export default {
    extends: ReportTableWrapper,
    props: {
      includeBudgets: {
        required: true,
        type: [Boolean, Number],
        default: false,
      },
    },
    computed: {
      amountColumns() {
        return this.columns.filter(column => column.prop.includes('_total'))
      },
      columns() {
        if (!this.includeBudgets) {
          return [
            {
              label: this.$t('G/L <br> Account'),
              prop: 'account',
              hiddenValue: true,
              minWidth: 80,
              maxWidth: 100,
            },
            {
              label: this.$t('Sub <br> Account'),
              prop: 'subaccount',
              component: this.$tableColumns.SubAccountLink,
              minWidth: 80,
              maxWidth: 100,
            },
            {
              label: '',
              prop: 'empty',
              hiddenValue: true,
              minWidth: 200,
            },
          ]
        }

        let periodColumns = []

        const moths = getOrderedMoths()

        for (let i = 0; i <= 5; i++) {
          const column = `${moths[i]} <br> ${moths[i + 6]}`

          periodColumns.push({
            label: this.$t(column),
            prop: `totals.p${i}_total`,
            align: 'right',
            hiddenValue: true,
            minWidth: 80,
            maxWidth: 120,
          })
        }

        return [
          {
            label: this.$t('G/L <br> Account'),
            prop: 'account',
            hiddenValue: true,
            minWidth: 80,
          },
          {
            label: this.$t('Sub <br> Account'),
            prop: 'subaccount',
            component: this.$tableColumns.SubAccountLink,
            width: 50,
            summary: () => `${this.$t('Total All Selected G/L Accounts:')}<br>&nbsp;`,
          },
          ...periodColumns,
          {
            label: this.$t('Total <br> Budget'),
            prop: 'total',
            align: 'right',
            hiddenValue: true,
          },
        ]
      },
    },
    methods: {
      composeRows(data) {

        if (!this.includeBudgets) {
          data.forEach(group => {
            this.rows.push({
              header: group.account,
            })

            group.subaccounts.forEach(_group => {
              this.rows.push({
                subaccount: _group.subaccount,
              })
            })

          })

          return this.rows
        }

        data.forEach(group => {

          const { account, totals } = group

          this.rows.push({
            header: account,
          })

          if (!group.subaccounts?.length) {
            return
          }

          group.subaccounts.forEach(_group => {

            const { subaccount, totals: subtotal } = _group

            this.rows.push({
              subaccount,
            })

            this.rows.push({
              subtotal,
            })

          })

          this.rows.push({
            subtotal: {
              account: account.number,
              ...totals,
            },
          })

        })

        return this.rows
      },
      getSlotName(prop) {
        return `${prop}_summary`
      },
    },
  }
</script>
