<template>
  <div class="w-full">
    <span class="action-item-text"
          @click="showExportDialog = true">
      <div class="p-2 bg-green-50 mr-2 rounded-md">
        <DownloadIcon class="w-4 h-4 text-green-500"/>
      </div>
      <span>{{ $t('Export') }}</span>
    </span>
    <BaseFormDialog
        v-if="showExportDialog"
        :title="$t('Export module settings to JSON')"
        :open.sync="showExportDialog"
        :append-to-body="true"
        size="lg"
    >
      <BaseForm
          :save-text="$t('Export')"
          layout="modal"
          @submit="onExport"
      >
        <SettingsTable
            :settings-object="settingsObject"
            :fields="fields"
            ref="settingsTable"
        />
      </BaseForm>
    </BaseFormDialog>
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue'
  import startCase from 'lodash/startCase'
  import { DownloadIcon } from 'vue-feather-icons'
  import { formatDate } from '@/plugins/dateFormatPlugin'
  import { saveFileAsJson } from '@/modules/common/util/fileUtils'
  import SettingsTable from '@/modules/common/components/modules-settings/SettingsTable.vue'

  const props = defineProps({
    settingsObject: {
      type: Object as () => Record<string, any>,
      default: () => ({}),
    },
    moduleName: String,
    fields: Array as () => string[],
  })

  let showExportDialog = ref(false)
  let settingsTable = ref(null)

  function onExport() {
    // @ts-ignore
    const settings = settingsTable?.value?.getTableData() || {}
    const fileName = `${ startCase(props.moduleName) } - ${ formatDate(new Date()) }`

    saveFileAsJson(settings, `${ fileName }.json`)
    showExportDialog.value = false
  }
</script>
