<template>
  <BaseSelect
    v-on="$listeners"
    v-bind="$attrs"
    :multiple="multiple"
    :options="options"
    :dataFilters="dataFilters"
    :label-format="formatLabel"
    :table-columns="tableColumns"
    :value-key="valueKey"
    label-key="code"
  />
</template>
<script>
import { globalResources } from "@/components/form/util";

export default {
  props: {
    codeTypes: {
      type: Array,
      required: true,
    },
    filterMethod: {
      type: Function,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    valueKey: {
      type: String,
      default: 'id',
    },
    showTypeCol: {
      type: Boolean,
      default: true,
    },
    showActiveColumn: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      globalResources,
      dataFilters: {
        showActive: true,
      },
    }
  },
  computed: {
    options() {
      let options = []

      this.codeTypes.forEach(type => {
        const codes = (this.$store.getters['globalLists/getResourceList'](type) || [])
          .map(code => this.remapCode(code, type))

        options.push(...codes)
      })

      if (this.filterMethod) {
        options = options.filter(this.filterMethod)
      }

      if (this.dataFilters.showActive) {
        options = options.filter(code => code.active)
      }

      return options
    },
    tableColumns() {
      const columns = [
        {
          minWidth: 100,
          maxWidth: 150,
          name: this.$t('Code'),
          prop: 'code',
        },
        {
          minWidth: 150,
          maxWidth: 150,
          name: this.$t('Description'),
          prop: 'description',
        },
        {
          minWidth: 150,
          maxWidth: 150,
          name: this.$t('Type'),
          prop: 'displayType',
          showIf: () => this.showTypeCol,
        },
        {
          minWidth: 130,
          maxWidth: 130,
          name: this.$t('Active'),
          prop: 'active',
          component: 'Status',
          filter: {
            type: 'switch',
            prop: 'showActive',
            tooltip: this.$t('Only show active codes'),
            onChange: (value) => {
              this.dataFilters.showActive = value
            },
          },
          showIf: () => this.showActiveColumn,
        },
      ]

      return columns.filter(col => !col.showIf || col.showIf())
    }
  },
  methods: {
    remapCode(code, type) {
      const active = code.hasOwnProperty('active') ? code.active : true
      return {
        ...code,
        type,
        active,
        displayType: type.match(/[A-Z]*[^A-Z]+/g).join(' '),
        isInactive: !active,
      }
    },
    formatLabel(option) {
      const { code, description } = option
      if (!description) {
        return code
      }
      return `${code} (${description})`
    },
  },
}
</script>
