<template>
  <base-data-table
      v-bind="defaultAttributes"
      :columns="columns"
      :data="getData"
      :total-rows="rowsLength"
      @force-pagination="forcePagination"
  >

    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template #header="{row}">
      <div class="flex items-center">
        <CustomerLink
            :data="row.header"
            :show-preview="false"
            :show-name="true"
            class="pr-24 w-[450px]"
        />
        <span class="flex items-center w-[250px] mr-4">
          <template v-if="row.header?.contact && row.header?.phone">
            ({{ row.header?.contact }} <base-phone-link :value="row.header?.phone" noDataText="" class="ml-6"/>)
          </template>
        </span>
        <span v-if="row.header?.last_payment_date">
          {{ $t('Last Payment Date:') }}
          {{ $formatDate(row.header.last_payment_date) }}
        </span>
      </div>
    </template>

    <template #job="{row}">
      <JobLink
          :data="row.job"
          :show-preview="false"
      />
    </template>

    <template #total="{row}">
      {{ $formatPrice(row.total || row.totals?.open_amount) }}
    </template>

    <template #billing="{row}">
      <BillingLink :data="row"/>
    </template>

    <template #subtotal="{subtotal}">
      <td/>
      <td colspan="3">
        <div class="flex items-center">
          <span class="py-2">{{ $t('Customer Total') }}</span>
          <template v-if="subtotal.retention_receivable">
            <span class="px-10">({{ $t('Retention Receivable:') }}</span>
            <span>{{ $formatPrice(subtotal.retention_receivable) }})</span>
          </template>
        </div>
      </td>

      <td v-if="actualDays"/>

      <td v-for="column in amountColumns"
          align="right"
      >
        <span :class="{'top-border flex pt-3 flex-1 justify-end': format !== agedByViewFormats.CustomerTotal}">
          {{ $formatPrice(get(subtotal, column.prop, 0)) }}
        </span>
      </td>
    </template>

    <template #html-row="{htmlData}">
      <tr class="content-row">
        <td v-if="htmlData.current_unapplied_cash_on_deposit_amount"
            align="right" :colspan="actualDays ? 10 : 9">
            <span class="mx-10">
              {{ $t('Unapplied Cash on Deposit as of') }} {{ $formatDate(now, 'MM/dd/yyyy', true) }}:
            </span>
          <span>{{ $formatPrice(htmlData.current_unapplied_cash_on_deposit_amount) }}</span>
        </td>
      </tr>
    </template>

  </base-data-table>
</template>
<script>
  import { agedByColumns } from '@/modules/common/components/reports/util'
  import { getBillingType } from '@/modules/accounts-receivable/pages/billings/billings'
  import { agedByViewFormats } from '@/modules/accounts-receivable/components/reports/util'
  import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'

  const module = 'Receivable'

  export default {
    extends: ReportTableWrapper,
    props: {
      format: {
        required: true,
        type: String,
        default: agedByViewFormats.EachInvoice,
      },
      days: {
        required: true,
        type: Array,
        default: () => [30, 60, 90],
      },
      actualDays: {
        type: [Boolean, String],
        default: false,
      },
    },
    data() {
      return {
        now: new Date(),
        agedByViewFormats,
      }
    },
    computed: {
      columns() {
        const columns = [
          {
            label: this.$t('Invoice'),
            prop: 'billing',
            minWidth: 110,
            maxWidth: 110,
          },
          {
            label: this.$t('Description'),
            prop: 'description',
            minWidth: 120,
            maxWidth: 150,
          },
          {
            label: this.$t('Job'),
            prop: 'job',
            minWidth: 70,
            maxWidth: 70,
            classes: 'mb-4',
            summary: () => `${this.$t('Total Selected Customers')}<br><br>&nbsp`,
          },
          {
            label: this.$t('Invoice <br> Date'),
            prop: 'date',
            component: 'FormattedDate',
            align: 'center',
            minWidth: 70,
            maxWidth: 70,
          },
        ]

        if (!this.data.aging_totals) {
          return columns
        }

        const agingColumns = agedByColumns(this.days, this.data.aging_totals, this.data.grand_totals, module, this.actualDays)
        return [
          ...columns,
          ...agingColumns,
        ]
      },
    },
    methods: {
      composeRows(data) {

        if (this.format === agedByViewFormats.CustomerTotal) {

          data.forEach(group => {

            const { customer } = group

            this.rows.push({
              header: customer,
            })

            this.rows.push({
              subtotal: {
                aging_totals: group.aging_totals,
                retention_receivable: group.totals?.retention_receivable,
                total: group.totals?.open_amount,
              },
            })

            this.rows.push({
              htmlData: {
                current_unapplied_cash_on_deposit_amount: customer.current_unapplied_cash_on_deposit_amount,
              },
            })
          })

          return this.rows
        }

        data.forEach(group => {

          const { customer, billings } = group
          this.rows.push({
            header: customer,
          })

          billings.forEach(billingObject => {
            const { job, billing } = billingObject
            this.rows.push({
              job,
              ...billing,
              aging_totals: billingObject.aging_totals,
              actual_days: billingObject?.actual_days,
              total: billingObject.totals?.open_amount,
            })
          })

          this.rows.push({
            subtotal: {
              aging_totals: group.aging_totals,
              retention_receivable: group.totals?.retention_receivable,
              total: group.totals?.open_amount,
            },
          })

          this.rows.push({
            htmlData: {
              current_unapplied_cash_on_deposit_amount: customer.current_unapplied_cash_on_deposit_amount,
            },
          })
        })

        return this.rows
      },
      getBillingType,
    },
  }
</script>
