import axios from 'axios'
import Cache from '@/utils/Cache'
import { reactive } from 'vue'
import { delay } from "@/utils/utils";
import { costCenterTypes } from "@/components/grid-table/utils/cost-center";
import { RateFrom } from "@/modules/payroll/utils/timeCardUtils";

const loading = reactive({})

export async function getRates(entity) {
  const employee_id = entity.employee_id || entity.id
  let { craft_code_id, union_id, job_id, cost_center } = entity;
  if (cost_center === costCenterTypes.Job) {
    job_id = entity.source_id
  }
  if (!craft_code_id) {
    return {}
  }
  let data = {}
  const params = {
    employee_id,
    craft_code_id,
    union_id: union_id || undefined,
    job_id: job_id || undefined
  }
  const url = '/restify/employees/rates'
  const key = JSON.stringify(params) + url

  if (loading[key]) {
    await delay(200)
    return getRates(entity)
  }
  try {
    loading[key] = true
    const cachedData = Cache.get(key)
    if (cachedData) {
      return cachedData
    }
    const res = await axios.post(url, params)
    data = res.data
  } catch (err) {
    console.log('Could not fetch employee rates: ', err)
  } finally {
    loading[key] = false
  }
  const response = {
    regular_rate: data?.regular?.rate || 0,
    overtime_rate: data?.overtime?.rate || 0,
    premium_rate: data?.premium?.rate || 0,
    regular_rate_from: data?.regular?.from || RateFrom.Undefined,
    overtime_rate_from: data?.overtime?.from || RateFrom.Undefined,
    premium_rate_from: data?.premium?.from || RateFrom.Undefined,
  }
  Cache.set(key, response)
  return response
}
