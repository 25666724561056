<template>
  <base-form
      v-bind="$attrs"
      layout="modal"
      :model="model"
      :loading="loading"
      :save-text="$t('Create Document Type')"
      :update-text="$t('Update Document Type')"
      @cancel="onCancel"
      @submit="onSubmit"
  >
    <base-input v-model="model.description"
                :label="$t('Name')"
                :placeholder="$t('Name')"
                id="name"
                class="col-span-6"
                rules="required|max:100"
    />
    <base-select
        v-model="model.used_in"
        :options="UsedInOptions"
        :name="$t('Used In')"
        :label="$t('Used In')"
        multiple
        class="col-span-6"
    />
  </base-form>
</template>
<script lang="ts" setup>
import {ref, watch} from "vue";
import axios from "axios";
import {UsedInOptions} from "@/enum/enums";

const props = defineProps({
  data: {
    type: Object,
  }
})

const loading = ref(false)
const model = ref({
  description: '',
  used_in: [],
})

const emit = defineEmits(['close', 'save'])

function onCancel() {
  emit('close')
}

watch(() => props.data, (data) => {
  model.value = {
    ...(data?.attributes || {})
  }
}, {immediate: true})

async function onSubmit() {
  try {
    loading.value = true
    let res
    if (props.data?.id) {
      res = await axios.put(`/restify/document-types/${props.data.id}`, model.value)
    } else {
      res = await axios.post('/restify/document-types', model.value)
    }
    emit('save', res.data)
  } catch (e) {
    console.error(e)
  } finally {
    loading.value = false
  }
}
</script>
