const state = {
  // * Current accessed page
  payrollAdjustmentType: {}
}

const actions = {
  setPayrollAdjustmentType({ state }, adjustmentType) {
    state.payrollAdjustmentType = adjustmentType
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  actions,
  getters
}
