<template>
  <div>
    <Navbar class="print:hidden"/>
    <MissingFiscalYearPrompt v-if="!isProduction" class="print:hidden"/>
    <div v-if="showCompanyViewAlert" class="rounded-md px-4 lg:px-10 bg-orange-50 shadow p-4 mx-auto mt-4 print:hidden">
      <div class="flex">
        <div class="flex-shrink-0">
          <svg class="h-5 w-5 text-orange-400" fill="currentColor" viewBox="0 0 20 20">
            <path fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clip-rule="evenodd"/>
          </svg>
        </div>
        <div class="ml-3 flex-1 md:flex md:justify-between">
          <p class="text-sm leading-5 text-orange-700">
            {{ $t('You are in the company view mode right now.') }}
          </p>
          <p class="mt-3 text-sm leading-5 md:mt-0 md:ml-6" @click="toggleAdminViewingCompany">
            <a href="javascript:;"
               class="whitespace-nowrap font-medium text-orange-700 hover:text-orange-600 transition ease-in-out duration-150">
              {{ $t('Go Back to management layout') }} &rarr;
            </a>
          </p>
        </div>
      </div>
    </div>
    <div v-else-if="fiscalYearSwitched"
         class="rounded-md px-4 lg:px-10 bg-orange-50 shadow p-4 mx-6 mt-4 print:hidden">
      <div class="flex">
        <div class="flex-shrink-0">
          <svg class="h-5 w-5 text-orange-400" fill="currentColor" viewBox="0 0 20 20">
            <path fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clip-rule="evenodd"/>
          </svg>
        </div>
        <div class="ml-3 flex-1 md:flex md:justify-between">
          <p class="text-sm leading-5 text-orange-700"
             v-html="fiscalYearSwitchedMessage"/>
        </div>
      </div>
    </div>
    <div id="page-header"
         class="px-4 pt-2 min-h-16 mx-auto lg:px-8 print:p-0">
      <div class="mx-auto">
        <div class="flex items-center justify-between">
          <div class="flex-1 min-w-0">
            <h2 class="sm:truncate page-title">
              <portal-target name="page-title">
                <template v-if="selectedUser.name && $route.path.includes('users')">
                  {{ selectedUser.name }}
                </template>
                <template v-else>
                  {{ $route.name }}
                </template>
                <div class="hidden print:inline-flex space-x-2 ml-2">
                  <span>{{ $companyName }}</span>
                  <span>{{ $formatDate(new Date(), $dateTypes.DateTime) }}</span>
                </div>
              </portal-target>
            </h2>
          </div>
          <portal-target name="page-title-status" class="mr-2 print:hidden"/>
          <div v-if="showNoteIndicator" class="print:hidden">
            <NotesButton
                :params="entityParams"
                :show-note-indicator="true"
            />
          </div>
          <copy-from-sibling
            v-if="canCopyFromSibling($route.path)"
            class="h-12 items-center"
          />
          <portal-target name="page-title-actions" class="print:hidden">
          </portal-target>
          <div v-if="showFiscalYearSelect"
               class="flex-shrink-0 flex md:mt-0 md:ml-4 print:hidden">
            <FiscalYearSelect
              v-if="!isProduction"
              :save-to-session="true"
              :show-closed-years="true"
              inline-errors
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from '@/modules/dashboard/components/Navbar'
import CopyFromSibling from '@/modules/common/components/CopyFromSibling'
import { canCopyFromSibling } from '@/modules/common/util/copySiblingUtil'
import MissingFiscalYearPrompt from '@/modules/dashboard/components/MissingFiscalYearPrompt'
import { isProduction } from "@/isProduction";
import isEqual from "lodash/isEqual";
import NotesButton from "@/modules/dashboard/components/NotesButton.vue";

const ENTITIES_WITH_NOTES = [
  'vendors',
  'invoices',
  'customers',
  'equipment',
  'materials',
  'billings',
  'jobs',
  'line-items',
  'employees',
  'worksites',
  'work-orders',
  'purchase-orders',
]

export default {
  components: {
    NotesButton,
    Navbar,
    CopyFromSibling,
    MissingFiscalYearPrompt,
  },
  data() {
    return {
      showDropdown: false,
      isProduction: isProduction(),
    }
  },
  computed: {
    showFiscalYearSelect() {
      const pathsToShow = ['ledger']
      const exceptions = ['view', 'summary', 'add', 'edit', 'settings', 'reports']
      return pathsToShow.some(modulePath => {
        const matchesPath = this.$route.path.includes(modulePath)
        const isException = exceptions.some(exception => this.$route.path.includes(exception))
        return matchesPath && !isException
      })
    },
    showCompanyViewAlert() {
      return this.$store.state.company.adminViewingCompany
    },
    selectedUser() {
      return this.$store.state.user.selectedUser
    },
    sessionCurrentFiscalYear() {
      return this.$store.state.company.sessionCurrentFiscalYear
    },
    companyCurrentFiscalYear() {
      return this.$store.state.company.currentFiscalYear
    },
    fiscalYearSwitched() {
      // TODO Remove once GL module works on production
      if (this.isProduction) {
        return false
      }
      if (!this.sessionCurrentFiscalYear) {
        return false
      }
      return this.sessionCurrentFiscalYear !== this.companyCurrentFiscalYear
    },
    fiscalYearSwitchedMessage() {
      return `${this.$t('You switched your fiscal year to')} <b>${this.sessionCurrentFiscalYear}</b> ${this.$t('which is different from the current one')} <b>${this.companyCurrentFiscalYear}</b>`
    },
    showNoteIndicator() {
      const { id } = this.$route.params
      const { resourceName } = this.$route.meta
      if (!id || !resourceName) {
        return false
      }

      return ENTITIES_WITH_NOTES.some(entity => this.$route.path.includes(entity))
    },
    entityParams() {
      const { id } = this.$route.params
      const { resourceName } = this.$route.meta
      return {
        id,
        resourceName,
      }
    },
  },
  methods: {
    canCopyFromSibling,
    toggleAdminViewingCompany() {
      this.$store.commit('company/SET_ADMIN_VIEWING_COMPANY', false)
      this.$store.commit('company/SET_COMPANY', {})
      this.$router.push('/settings/companies')
    },
  },
  watch: {
    entityParams: {
      deep: true,
      immediate: true,
      handler(value, oldValue) {
        if (isEqual(value, oldValue)) {
          return
        }
        if (!this.showNoteIndicator) {
          return
        }
        this.$emit('note-params-changed', value)
      }
    }
  }
}
</script>
<style lang="scss">
.page-title {
  @apply text-xl;
  a {
    @apply text-gray-700 hover:text-primary-700 cursor-pointer truncate;
  }
}
</style>
