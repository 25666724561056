<template>
  <div>
    <ReportShading
        :settings="settings"
        :loading="loading"
        @save="saveSettings"
    />
  </div>
</template>
<script setup lang="ts">
  import axios from 'axios'
  import store from '@/store'
  import i18n from '@/i18n'
  import { computed, ref } from 'vue'
  import { error, success } from '@/components/common/NotificationPlugin'
  import { useReportShading } from '@/modules/common/components/reports/util'
  import ReportShading from '@/modules/common/components/reports/ReportShading.vue'
  import { ReportShadingType } from '@/modules/common/components/reports/reportShadingTypes'

  let loading = ref(<boolean>false)

  const {
    reportShadingSettings: shading,
    shadeReports: shade_reports,
    userShadingId,
    userShadeReportsId,
  } = useReportShading()

  const settings = computed(() => {
    return {
      shading,
      shade_reports,
    }
  })

  async function saveSettings(settings: Record<string, any>) {
    try {
      loading.value = true

      let payload: Record<string, any> = {}


      const shading = {
        key: 'shading',
        value: JSON.stringify(<ReportShadingType>settings.shading),
      }

      if (userShadingId) {
        await axios.put(`/restify/user-company-settings/${ userShadingId }`, shading)
      } else {
        await axios.post('/restify/user-company-settings', shading)
      }


      const shadeReports = {
        key: 'shade_reports',
        value: <boolean>settings.shade_reports,
      }

      if (userShadeReportsId) {
        await axios.put(`/restify/user-company-settings/${ userShadeReportsId }`, shadeReports)
      } else {
        await axios.post('/restify/user-company-settings', shadeReports)
      }


      await store.dispatch('auth/getUserCompanySettings')
      success(i18n.t('User company settings updated'))

    } catch (err) {
      error(i18n.t('Could not update user company settings'))
      console.warn('save shading settings', err)
    } finally {
      loading.value = false
    }
  }
</script>
