<template>
  <base-input
              v-on="$listeners"
              v-bind="$attrs"
              v-input-mask="zipCodeMask"
              :placeholder="$attrs.placeholder"
              :label="$attrs.label !== undefined ? $attrs.label : $t('Zip Code')"
              :name="$attrs.name || $t('Zip Code')"
              :id="$attrs.id || 'zip-code-input'"
              :rules="validationRules"
  >
    <template v-slot:suffix>
      <MailIcon class="w-4 h-4"/>
    </template>
  </base-input>
</template>
<script>
  import { MailIcon } from 'vue-feather-icons'

  export default {
    inheritAttrs: false,
    components: {
      MailIcon,
    },
    props: {
      rules: {
        type: String,
      }
    },
    data() {
      return {
        zipCodeMask: '#####-####',
      }
    },
    computed: {
      validationRules() {
        const isRequired = this.rules && this.rules.includes('required')
        return {
          required: isRequired,
          regex: /^([0-9]{5}|[0-9]{5}-[0-9]{4})$/,
        }
      },
    },
  }
</script>
