<template>
  <div v-loading="loading">
    <div class="flex justify-between items-center">
      <layout-tabs :items="filteredItems"/>
      <div class="flex items-center">
        <TablePrintButton v-if="onProofListingPage"/>
        <PostAction
            v-if="authorizedToPost"
            :id="currentResource.id"
            :is-accounting="false"
            :has-custom-message="true"
            :action-url="postActionUrl"
            entity-name="Cost & Retention Payable"
            resource-name="job-costing-init"
            variant="gray-link"
            size="xs"
            @on-action-callback="getCurrentResource"
        >
          <template #custom-message>
            {{ $t('Do you want to post these Line Items to Job Cost?') }}
          </template>
        </PostAction>
      </div>
    </div>
    <router-view :data="currentResource"/>
  </div>
</template>
<script>
  import axios from 'axios'
  import layoutPermissions from '@/mixins/layoutPermissions'

  export default {
    mixins: [layoutPermissions],
    data() {
      return {
        loading: false,
        currentResource: {},
      }
    },
    computed: {
      items() {
        const id = this.$route.params.id
        return [
          {
            name: this.$t('Proof Listing'),
            path: `/job-costing/settings/job-cost-init/${id}/proof-listing`,
            authorizedTo: 'authorizedToShow',
          },
          {
            name: this.$t('Edit'),
            path: `/job-costing/settings/job-cost-init/${id}/edit`,
            authorizedTo: 'authorizedToUpdate',
          },
        ]
      },
      postActionUrl() {
        return `/restify/job-costing-inits/${this.currentResource.id}/action?action=post-job-costing-init`
      },
      onProofListingPage() {
        return this.$route.path.includes('/proof-listing')
      },
      authorizedToPost() {
        return this.$isAuthorized(this.$actionPolicies.Post, this.currentResource) && this.onProofListingPage
      },
    },
    methods: {
      async getCurrentResource() {
        try {
          this.loading = true
          const { data } = await axios.get(`/restify/job-costing-inits/${this.$route.params.id}`)
          await this.$store.dispatch('globalLists/getJCConfiguration', true)
          this.currentResource = data
        } catch (err) {
          if (err.handled) {
            return
          }
          console.warn(err)
        } finally {
          this.loading = false
        }
      }
    },
    mounted() {
      this.getCurrentResource()
    }
  }
</script>
