import store from "@/store";
import { globalResources } from "@/components/form/util";

export const adjustmentTypes = {
  EARNINGS: 'earnings',
  BENEFITS: 'benefits',
  TAXES: 'taxes',
  DEDUCTIONS: 'deductions',
  W2MEMOS: 'w2-memos',
  ALL: 'all',
}

// * Adjustment types
export const sourceTypeConfigs = {
  [adjustmentTypes.EARNINGS]: {
    cellTitle: 'Pay Code',
    resourceName: globalResources.PayCodes,
    sourceType: 'pay-code',
  },
  [adjustmentTypes.BENEFITS]: {
    cellTitle: 'Benefit Code',
    resourceName: globalResources.BenefitCodes,
    sourceType: 'benefit-code',
  },
  [adjustmentTypes.TAXES]: {
    cellTitle: 'Tax Code',
    resourceName: globalResources.TaxCodes,
    sourceType: 'deduction-code',
  },
  [adjustmentTypes.DEDUCTIONS]: {
    cellTitle: 'Deduction Code',
    cell: 'DeductionCodeCell',
    resourceName: globalResources.DeductionCodes,
    sourceType: 'deduction-code',
  },
  [adjustmentTypes.W2MEMOS]: {
    cellTitle: 'Special Code',
    resourceName: globalResources.WTwoCodes,
    sourceType: 'w-two-code',
  },
  [adjustmentTypes.ALL]: {
    cellTitle: 'Code',
    resourceName: null,
    sourceType: null,
  },
}

export const codeScope = {
  Federal: 'federal',
  Local: 'local',
  State: 'state',
}

function getSourceScope(sourceId) {
  const adjustmentType = store.state.dataGridConfig.payrollAdjustmentType
  const { resourceName } = sourceTypeConfigs[adjustmentType]

  return store.getters['globalLists/getResourceById'](resourceName, sourceId) || {}
}

export function stateTaxValidator(value, callback, instance) {
  const gridInstance = instance || this.instance
  const sourceId = gridInstance?.getDataAtRowProp(this.row, 'source_id')
  const { scope } = getSourceScope(sourceId)

  const state = scope !== codeScope.Federal

  if (typeof callback === 'function') {
    return callback(state ? !!value : true)
  }
  return state ? !!value : true
}

export function localTaxValidator(value, callback, instance) {
  const gridInstance = instance || this.instance
  const sourceId = gridInstance?.getDataAtRowProp(this.row, 'source_id')
  const { scope } = getSourceScope(sourceId)

  const state = scope === codeScope.Local

  if (typeof callback === 'function') {
    return callback(state ? !!value : true)
  }
  return state ? !!value : true
}
