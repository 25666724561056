<template>
  <entity-select url="/restify/journals"
                 v-bind="$attrs"
                 v-on="$listeners"
                 :url-params="urlParams"
                 :filter-options="filterOptions"
                 :name="$attrs.name || $t('Journal')"
                 :placeholder="$attrs.placeholder || $t('Select Journal')"
                 :label="$attrs.label !== undefined ? $attrs.label : $t('Journal')"
                 :add-label="$t('Add new Journal')"
                 :add-entity="addEntity"
                 :edit-entity="editEntity"
                 :set-default-option="editEntity"
                 label-key="name"
                 class="w-full"
                 :id="$attrs.id || 'journal-select'"
                 :table-columns="tableColumns"
  />
</template>
<script>
  export default {
    inheritAttrs: false,
    props: {
      urlParams: {
        type: Object,
        default: () => ({
          sort: 'code'
        })
      },
      excludeIds: {
        type: Array,
        default: () => []
      },
      addEntity: {
        type: Boolean,
        default: true
      },
      editEntity: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        tableColumns: [
          {
            minWidth: 100,
            maxWidth: 150,
            name: this.$t('Name'),
            prop: 'attributes.name'
          },
        ],
      }
    },
    methods: {
      filterOptions(options) {
        if (this.excludeIds.length) {
          return options.filter(o => {
            return !this.excludeIds.includes(o.id)
          })
        }
        return options
      },
    },
  }
</script>
