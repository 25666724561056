const currentYear = new Date().getFullYear()

export const minAmount = -99999999.99
export const maxAmount = 99999999.99

const validator = {
  minFiscalYear: 1990,
  maxFiscalYear: currentYear,

  salesTaxPercent: {
    min: 0,
    max: 99.999,
    step: 0.001,
    fractionDigits: 3,
    rules: 'min_value:0|max_value:99.999',
  },

  minAmount,
  maxAmount,

  amount: {
    min: 0,
    max: 99.999,
    step: 0.01,
    fractionDigits: 2,
    type: 'number',
    format: 'price',
    rules: `min_value:${minAmount}|max_value:${maxAmount}`
  }
}

export default {
  install(Vue) {
    Vue.prototype.$validator = validator
  }
}
