<template>
  <base-date-picker
      v-focus
      v-model="selectedValue"
      :hide-label="true"
      :append-to-body="true"
      :type="type"
      class="flex-1"
      inline-errors
      ref="picker"
      @change="onChange"
      @blur="onBlur"
      @keydown.enter.native="goToNextCell"
  />
</template>
<script>

  import { selectEditorMixin } from "@/components/ag-grid/cellEditors/selectEditorMixin";

  export default {
    name: 'BaseDatePickerEditor',
    mixins: [selectEditorMixin],
    computed: {
      type() {
        return this.params.type || 'date'
      },
    },
    methods: {
      onChange(newValue) {
        this.selectedValue = newValue
        this.params.api.tabToNextCell()
      },
      onBlur() {
        this.params.api.tabToNextCell()
      },
      goToNextCell() {
        this.params.api.tabToNextCell()
      }
    }
  }
</script>
