<template>
  <div class="truncate">
    <template v-if="getValue && !editableCell">
      <component
        :is="composeRoute ? 'router-link' : 'span'"
        :to="composeRoute"
        :tabindex="params.skipFocus ? -1 : 0"
        :title="getValue"
      >
        {{ getValue }}
      </component>
    </template>
    <template v-else-if="isSummary"></template>
    <template v-else-if="editableCell">{{ getValue }}</template>
    <template v-else></template>
  </div>
</template>
<script>
  import get from 'lodash/get'
  import { isSummaryRow } from "@/components/ag-grid/columnUtils";

  export default {
    name: 'entity-link',
    props: {
      column: {
        type: Object,
        default: () => ({}),
      },
      row: {
        type: Object,
        default: () => ({}),
      },
      params: {
        type: Object,
        default: () => ({}),
      },
      editableCell: Boolean,
    },
    computed: {
      isSummary() {
        return isSummaryRow(this.params)
      },
      getValue() {
        const value = get(this.row, this.column.prop)

        if (!this.column.formatter) {
          return value
        }
        return this.column.formatter(value)
      },
      composeRoute() {
        const link = this.column.redirectTo
        const pathQuery = this.column.entityKey ? get(this.row, this.column.entityKey) : this.row.id

        if (!pathQuery) {
          return ''
        }

        return link.replace('{ID}', pathQuery)
      },
    },
  }
</script>
