<template>
  <div class="mt-10 sm:mt-0">
    <base-form v-bind="$attrs"
               :save-text="$t('Invite')"
               :loading="loading"
               @cancel="$emit('cancel')"
               @submit="inviteUser"
    >

      <div class="col-span-6 md:col-span-3">
        <base-input v-model="model.email"
                    :label="$t('Email')"
                    :placeholder="$t('Email to invite')"
                    type="email"
                    id="email"
                    rules="required|email">
        </base-input>
      </div>

      <div class="col-span-6 md:col-span-3">
        <base-input v-model="model.name"
                    :label="$t('Name')"
                    :placeholder="$t('Full Name')"
                    id="name"
                    rules="required">
        </base-input>
      </div>
      <div class="col-span-6 md:col-span-3">
        <company-role-select
            v-model="model.company_roles"
            :multiple="true"
            :label="$t('Company Role')"
            rules="required"
        />
      </div>
    </base-form>
  </div>
</template>
<script>
  import axios from 'axios'
  import CompanySelect from "@/modules/settings/components/CompanySelect";
  import EntitySelect from "@/components/select/EntitySelect";
  import OrganizationRoleSelect from "@/components/select/entities/OrganizationRoleSelect.vue";

  export default {
    components: {
      OrganizationRoleSelect,
      CompanySelect,
      EntitySelect
    },
    data() {
      return {
        loading: false,
        model: {
          name: '',
          email: '',
          company_roles: [],
          organization_roles: [],
        },
      }
    },
    methods: {
      async inviteUser() {
        try {
          this.loading = true
          await axios.post(`/restify/invitations`, this.model)
          this.$success(this.$t('User invitation sent'))
          this.$emit('save')
        } catch (err) {
          this.$error(this.$t('Could not send the user invitation'))
        } finally {
          this.loading = false
        }
      }
    }
  }
</script>
<style>
</style>

