<template>
  <base-filter-row :title="title || $t('Vendors')">
    <base-filter-range :class="gridClass">
      <template #start>
        <vendor-select
          v-bind="$attrs"
          v-model="value[fromKey]"
          :add-entity="false"
          :placeholder="placeholders.from"
          :hide-label="true"
          :key="fromKey"
          value-key="code"
          clearable
          @blur="onBlurStartRange"
        />
      </template>
      <template #end>
        <vendor-select
            v-bind="$attrs"
            v-model="value[toKey]"
            :add-entity="false"
            :placeholder="placeholders.to"
            :hide-label="true"
            :rules="value[fromKey] ? 'required' : ''"
            :key="toKey"
            value-key="code"
            clearable
        />
      </template>
    </base-filter-range>
  </base-filter-row>
</template>
<script>
import i18n from '@/i18n'
export default {
  props: {
    value: {
      type: Object,
      default: () => ({
        vendor_code_from: null,
        vendor_code_to: null,
      }),
    },
    gridClass: {
      type: String,
      default: 'col-span-4',
    },
    title: {
      type: String,
      default: '',
    },
    fromKey: {
      type: String,
      default: 'vendor_code_from',
    },
    toKey: {
      type: String,
      default: 'vendor_code_to',
    },
    placeholders: {
      type: Object,
      default: () => ({
        from: i18n.t('Vendor From'),
        to: i18n.t('Vendor To'),
      }),
    },
  },
  methods: {
    onBlurStartRange() {
      this.value[this.toKey] = this.value[this.toKey] || this.value[this.fromKey]
    }
  }
}
</script>
