<template>
  <div class="col-span-8 grid grid-cols-8 gap-x-4">
    <div class="col-span-8">
      <h5 class="form-section-title">
        {{ $t('Dates') }}
      </h5>
    </div>
    <base-date-picker
      v-model="model.date"
      :label="$t('Invoice Date')"
      :placeholder="$t('Invoice Date')"
      :name="$t('Invoice Date')"
      :disabled="!isEditable"
      id="date"
      rules="required"
      class="col-span-8 md:col-span-2"
      @change="$emit('date-change', $event)"
      @blur="$emit('date-blur', $event)"
    />
    <base-date-picker
      v-model="model.due_date"
      :picker-options="datePickerOptions"
      :label="$t('Due Date')"
      :placeholder="$t('Due Date')"
      :name="$t('Due Date')"
      id="due_date"
      class="col-span-8 md:col-span-2"
    />
    <base-date-picker
      v-if="$settings($modules.AR, 'show_discount_date')"
      v-model="model.discount_date"
      :picker-options="datePickerOptions"
      :label="$t('Discount Date')"
      :placeholder="$t('Discount Date')"
      :name="$t('Discount Date')"
      id="discount_date"
      class="col-span-8 md:col-span-2"
    />
  </div>
</template>
<script>
import parseISO from "date-fns/parseISO";
import isBefore from "date-fns/isBefore";
import { resourceStatuses } from "@/enum/enums";

export default {
  props: {
    model: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    datePickerOptions() {
      return {
        disabledDate: (date) => {
          try {
            if (!this.model.date) {
              return false
            }
            const billingDate = parseISO(this.model.date)
            return isBefore(date, billingDate)
          } catch (err) {
            return false
          }
        },
      }
    },
    isEditable() {
      return !this.$route.params.id || [resourceStatuses.Pending, resourceStatuses.NoPost].includes(this.model.status)
    }
  }

}
</script>
