<template>
  <base-form :loading="loading"
             :showCancel="showCancel"
             :showBack="showBack"
             :save-text="$t('Create work item')"
             :update-text="$t('Update work item')"
             layout="modal"
             @cancel="$emit('cancel')"
             @submit="onSubmit"
  >
    <div class="col-span-6 md:col-span-3">
      <base-input v-model="model.code"
                  :label="$t('Code')"
                  :placeholder="$t('Crew Code')"
                  id="code"
                  rules="required|code"
      />
    </div>
    <div class="col-span-6 md:col-span-3">
      <entity-select v-model="model.formula_id"
                     clearable
                     url="/restify/estimate-formulas"
                     :label="$t('Quantity Formula')"
                     :placeholder="$t('Select A Quantity Formula')"
                     label-key="code"
                     value-key="id"
      />
    </div>
    <div class="col-span-6">
      <entity-select v-model="model.bid_id"
                     clearable
                     url="/restify/bids"
                     :label="$t('Bid')"
                     :label-dictionary="$t('Standard definition or select a Bid to override definition')"
                     :placeholder="$t('Select A Bid')"
                     label-key="number"
                     value-key="id"
      />
    </div>
    <div class="col-span-6">
      <base-input v-model="model.descriptions[0]"
                  :label="$t('Descriptions')"
                  :placeholder="$t('Primary Description')"
                  id="description_0"
                  rules="max:150"
                  inline-errors
      />
    </div>
    <div class="col-span-6">
      <base-input v-model="model.descriptions[1]"
                  :placeholder="$t('Additional Description')"
                  id="description_1"
                  rules="max:150"
      />
    </div>
    <div class="col-span-6 md:col-span-3">
      <base-input v-model="model.um"
                  :label="$t('Unit Measure')"
                  :placeholder="$t('Unit Measure')"
                  id="um"
                  rules="max:4"
      />
    </div>
    <div class="col-span-6 md:col-span-3">
      <base-input v-model="model.sort_group"
                  :label="$t('Sort Group')"
                  :placeholder="$t('Sort Group')"
                  id="sort_group"
                  rules="max:6"
      />
    </div>
    <div class="col-span-6 md:col-span-3">
      <entity-select v-model="model.phase_id"
                     clearable
                     url="/restify/phase-costs"
                     :label="$t('Phase Code')"
                     :placeholder="$t('Phase Code')"
                     label-key="phase"
                     value-key="id"
      />
    </div>
    <div class="col-span-6 md:col-span-3">
      <entity-select v-model="model.code_id"
                     clearable
                     url="/restify/phase-costs"
                     :label="$t('Cost Code')"
                     :placeholder="$t('Cost Code')"
                     label-key="cost"
                     value-key="id"
      />
    </div>
    <div class="col-span-6">
      <el-collapse v-model="activeGroups">
        <el-collapse-item key="material"
                          :title="$t('Material')"
                          :name="$t('Material')">
          <div class="grid grid-cols-6 gap-x-3 border-t pt-5 px-1">
            <div class="col-span-6 md:col-span-3">
              <entity-select v-model="model.material_code_id"
                             clearable
                             url="/restify/material-codes"
                             :label="$t('Material Code')"
                             :placeholder="$t('Select A Material Code')"
                             label-key="code"
                             value-key="id"
              />
            </div>
            <div class="col-span-6 md:col-span-3">
              <entity-select v-model="model.job_type_id"
                             clearable
                             url="/restify/job-types"
                             :label="$t('Cost Type')"
                             :placeholder="$t('Select A Cost Type')"
                             label-key="abbr"
                             value-key="id"
              />
            </div>
            <div class="col-span-6 md:col-span-3">
              <base-input v-model="model.um_conversion_factor"
                          :label="$t('Conversion')"
                          :placeholder="$t('e.g 100.00')"
                          id="conversion"
                          :min="0"
                          :step="0.000001"
                          type="number"
                          rules="max_value:999999.999999|min_value:0"
              />
            </div>
            <div class="col-span-6 md:col-span-3">
              <base-input v-model="model.unit_value"
                          :label="$t('Purchase Unit')"
                          :placeholder="$t('e.g 100.00')"
                          :min="0"
                          :max="9999999.999"
                          :step="0.000001"
                          id="unit_value"
                          type="number"
                          format="price"
                          rules="max_value:9999999.999|min_value:0"
              />
            </div>
          </div>
        </el-collapse-item>
        <el-collapse-item :title="$t('Equipment')"
                          :name="$t('Equipment')"
                          key="equipment"
        >
          <div class="grid grid-cols-6 gap-x-3 border-t pt-5 px-1">
            <div class="col-span-6">
              <entity-select v-model="model.equipment_code_id"
                             :label="$t('Equipment Code')"
                             :placeholder="$t('Select A Equipment Code')"
                             clearable
                             url="/restify/equipment-codes"
                             label-key="code"
                             value-key="id"
              />
            </div>
            <div class="col-span-6 md:col-span-3">
              <base-select v-model="model.equipment_priced_by"
                           :label="$t('Price By')"
                           :options="pricedByOptions"
                           id="equipment_priced_by"
              />
            </div>
            <div class="col-span-6 md:col-span-3">
              <base-input v-model="model.equipment_rate"
                          :label="$t('Purchase Unit')"
                          :placeholder="$t('e.g 100.00')"
                          :min="0"
                          :step="0.000001"
                          :max="9999999999.999999"
                          id="equipment_rate"
                          type="number"
                          format="price"
                          rules="max_value:9999999999.999999|min_value:0"
              />
            </div>
          </div>
        </el-collapse-item>
        <el-collapse-item key="material"
                          :title="$t('Crew')"
                          :name="$t('Crew')">
          <div class="grid grid-cols-6 gap-x-3 border-t pt-5 px-1">
            <div class="col-span-6">
              <entity-select v-model="model.crew_labor_rate_id"
                             clearable
                             url="/restify/crew-labor-rates"
                             :label="$t('Crew Code')"
                             :placeholder="$t('Select A Crew Code')"
                             label-key="code"
                             value-key="id"
              />
            </div>
            <div class="col-span-6 md:col-span-3">
              <base-select :label="$t('Price By')"
                           id="crew_labor_priced_by"
                           v-model="model.crew_labor_priced_by"
                           :options="pricedByOptions"
              />
            </div>
            <div class="col-span-6 md:col-span-3">
              <base-input v-model="model.crew_labor_rate"
                          :label="$t('Purchase Unit')"
                          :placeholder="$t('e.g 100.00')"
                          :min="0"
                          :step="0.001"
                          :max="9999999999.999999"
                          id="crew_labor_rate"
                          type="number"
                          format="price"
                          rules="max_value:9999999999.999999|min_value:0"
              />
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </base-form>
</template>
<script>
  import axios from 'axios'

  export default {
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      showBack: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        loading: false,
        showCancel: true,
        activeGroups: [],
        model: {
          code: '',
          descriptions: [],
          formula_id: undefined,
          phase_id: undefined,
          code_id: undefined,
          material_code_id: undefined,
          equipment_code_id: undefined,
          crew_labor_rate_id: undefined,
          bid_id: undefined,
          job_type_id: undefined,
          um: '',
          sort_group: '',
          um_conversion_factor: 0,
          unit_value: 0,
          equipment_rate: 0,
          crew_labor_rate: 0,
          equipment_priced_by: 'Units per Hour',
          crew_labor_priced_by: 'Units per Hour',
        },
        pricedByOptions: [
          {
            label: this.$t('Units per Day'),
            value: 'Units per Day',
          },
          {
            label: this.$t('Units per Hour'),
            value: 'Units per Hour',
          },
          {
            label: this.$t('Hours per Unit'),
            value: 'Hours per Unit',
          },
          {
            label: this.$t('Days per Unit'),
            value: 'Days per Unit',
          },
          {
            label: this.$t('$$$ per Unit'),
            value: '$$$ per Unit',
          },
        ],
      }
    },
    watch: {
      data: {
        immediate: true,
        handler(value) {
          this.model = {
            ...this.model,
            ...value,
          }
        },
      },
    },
    methods: {
      onBlurNumberInput(field) {
        if (this.model[field] === '') {
          this.model[field] = 0
        }
      },
      async onSubmit() {
        try {
          this.loading = true
          if (this.model.id) {
            await axios.put(`/restify/work-items/${this.model.id}`, this.model)
            this.$success(this.$t('Work item updated'))
          } else {
            await axios.post('/restify/work-items', this.model)
            this.$success(this.$t('Work item added'))
          }
          this.$emit('save', this.model)
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not update the work item value'))
        } finally {
          this.loading = false
        }
      },
    },
  }
</script>
<style lang="scss">
  .el-collapse-item .el-collapse-item__header {
    @apply text-base leading-6 font-medium text-gray-900;
  }
</style>
