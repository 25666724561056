<template>
  <el-date-picker
      v-model="model"
      type="monthrange"
      align="right"
      unlink-panels
      range-separator="To"
      :clearable="false"
      :start-placeholder="$t('Start')"
      :end-placeholder="$t('End')"
      :picker-options="pickerOptions">
  </el-date-picker>
</template>
<script>
  import startOfQuarter from 'date-fns/startOfQuarter'
  import { DatePicker } from 'element-ui'

  export default {
    name: 'MonthRangeDatePicker',
    components: {
      [DatePicker.name]: DatePicker
    },
    props: {
      value: {
        type: [String, Array],
      }
    },
    data() {
      return {
        pickerOptions: {
          shortcuts: [
            {
              text: this.$t('This year'),
              onClick(picker) {
                const year = new Date().getFullYear()
                const end = new Date();
                const start = new Date(year, 0);
                picker.$emit('pick', [start, end]);
              }
            },
            {
              text: this.$t('This quarter'),
              onClick(picker) {
                const end = new Date();
                const start = startOfQuarter(new Date());
                picker.$emit('pick', [start, end]);
              }
            },
            {
              text: this.$t('Last 6 months'),
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setMonth(start.getMonth() - 6);
                picker.$emit('pick', [start, end]);
              }
            },
            {
              text: this.$t('Last year'),
              onClick(picker) {
                const year = new Date().getFullYear() - 1
                const end = new Date(year, 11, 31);
                const start = new Date(year, 0, 1);
                picker.$emit('pick', [start, end]);
              }
            },
          ]
        },
      }
    },
    computed: {
      model: {
        get() {
          return this.value
        },
        set(val) {
          this.$emit('input', val)
        }
      }
    }
  }
</script>
<style>
</style>
