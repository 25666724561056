<template>
  <div>
    <LinkedLineItemsSetup v-if="currentView === viewTypes.Setup">
      <template #actions>
        <LinkedLineItemViews v-model="currentView"/>
      </template>
    </LinkedLineItemsSetup>
    <LinkedLineItemComparison
      v-if="currentView !== viewTypes.Setup"
      :type="currentView === viewTypes.CostVsIncome ? costOrIncomeTypes.Cost : costOrIncomeTypes.Income"
    >
      <template #actions>
        <LinkedLineItemViews v-model="currentView"/>
      </template>
    </LinkedLineItemComparison>
  </div>
</template>
<script>
import LinkedLineItemsSetup from "@/modules/job-costing/components/line-items/LinkedLineItemsSetup.vue";
import LinkedLineItemViews from "@/modules/job-costing/components/line-items/LinkedLineItemViews.vue";
import { linkedLineItemViewTypes } from "@/modules/job-costing/enum/jobs";
import LinkedLineItemComparison from "@/modules/job-costing/components/line-items/LinkedLineItemComparison.vue";
import { costOrIncomeTypes } from "@/components/grid-table/utils/cost-center";

export default {
  components: {
    LinkedLineItemComparison,
    LinkedLineItemViews,
    LinkedLineItemsSetup,
  },
  data() {
    return {
      costOrIncomeTypes,
      viewTypes: linkedLineItemViewTypes,
      currentView: linkedLineItemViewTypes.CostVsIncome
    }
  }
}
</script>
