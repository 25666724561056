<template>
  <div class="flex items-center space-x-1">
    <span class="mr-1">{{ $t('Job') }}</span>
    <JobLink :data="job"/>,
    <span v-if="!get(data, 'source.customer.id', false)">
          {{ $t('(Customer not assigned)') }}
      </span>
    <template v-else>
      <span>{{ $t('Customer') }}</span>
      <customer-link
          :data="customer"
          :show-name="true"
      />
    </template>
    <base-checkbox
      v-model="selectedAll"
      :label="$t('Select All')"
      :id="`${rowIndex}-select-all`"
      @change="updateGroupRows($event)"
    />
  </div>
</template>
<script>
  import {
    selectCostsActionTypes
  } from "@/modules/accounts-receivable/pages/billings/forms/cost-plus/select-costs/selectCostsEnums";

  export default {
    data() {
      return {
        selectedAll: false,
      }
    },
    computed: {
      rowIndex() {
        return this.params.rowIndex
      },
      data() {
        return this.params.node?.allLeafChildren[0]?.data || {}
      },
      job() {
        return this.get(this.data, 'source')
      },
      customer() {
        return this.get(this.data, 'source.customer')
      },
      groupEntries() {
        return this.params.node?.allLeafChildren.map(child => child)
      },
    },
    methods: {
      updateGroupRows(selected) {
        const groupEntries = this.groupEntries
        const action = selected ? selectCostsActionTypes.Create : selectCostsActionTypes.None
        this.params?.updateGroupStatus(groupEntries, action)

        this.updateChildRows(action)
      },
      updateChildRows(action) {
        this.params.node.allLeafChildren.forEach(child => {
          child.setData({
            ...child.data,
            action,
          })
        })
      },
      setSelectedAllDefaultState() {
        this.selectedAll = this.params.node.allLeafChildren.every(child => child.data.action === selectCostsActionTypes.Create)
      }
    },
    mounted() {
      this.setSelectedAllDefaultState()
    }
  }
</script>
