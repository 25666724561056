<template>
  <BaseWrapper v-bind="$attrs"
               :items="items"
               :entity="entity"
               :footer="{}"
               title-key="code"
               status-key="status"
  />
</template>
<script>
  import BaseWrapper from '@/modules/common/components/entity-preview/BaseWrapper'

  export default {
    components: {
      BaseWrapper,
    },
    props: {
      entity: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        items: [
          {
            title: this.$t('Description'),
            value: this.entity.description,
          },
          {
            title: this.$t('Standard Job Cost Hourly Rate'),
            value: this.$formatPrice(this.entity.standard_job_cost_hourly_rate),
          }
        ],
      }
    },
  }
</script>
