import axios from 'axios'
import get from 'lodash/get'

function appendAt(string, index, replacement) {
  return string.substring(0, index) + replacement
}

const pattern1 = /^([a-zA-Z]{1})$/; // A
const pattern2 = /^([a-zA-Z]{2})$/; // AB
const pattern3 = /^([a-zA-Z]{3})$/; // ABC
const pattern4 = /^([a-zA-Z]{3}-[0-9]{2})$/; // ABC-12
const pattern5 = /^([a-zA-Z]{3}-[0-9]{3})$/; // ABC-123

async function getLastNumber(repo) {
  try {
    const { data } = await axios.get(`restify/${repo}`, {
      params: {
        perPage: 1,
        sort: '-updated_at',
      }
    })

    return get(data, '[0][attributes][number]', false)
  } catch (error) {
    console.warn(error)
    return ''
  }
}

export async function generateNextNumber(repo = 'jobs') {
  const number = await getLastNumber(repo)

  if (!number) {
    return {
      lastNumber: '',
      nextNumber: '',
    }
  }

  if (parseInt(number)) {
    return {
      lastNumber: number,
      nextNumber: parseInt(number) + 1,
    }
  }

  if (pattern1.test(number)) {
    return {
      lastNumber: number,
      nextNumber: appendAt(number, 1, '-0001'),
    }
  }

  if (pattern2.test(number)) {
    return {
      lastNumber: number,
      nextNumber: appendAt(number, 2, '-001'),
    }
  }

  if (pattern3.test(number)) {
    return {
      lastNumber: number,
      nextNumber: appendAt(number, 3, '-01'),
    }
  }

  if (pattern4.test(number)) {
    const nextValue = parseInt(number.substring(4, 6)) + 1
    const replacement = nextValue < 10 ? `0${nextValue}` : nextValue
    const nextNumber = appendAt(number, 4, replacement)

    return {
      lastNumber: number,
      nextNumber,
    }
  }

  if (pattern5.test(number)) {
    let replacement = parseInt(number.substring(5, 7)) + 1

    if (replacement < 10) {
      replacement = `00${replacement}`
    } else if (replacement > 10 && replacement < 100) {
      replacement = `0${replacement}`
    }

    const nextNumber = appendAt(number, 4, replacement)

    return {
      lastNumber: number,
      nextNumber,
    }
  }

  let index = number.length - 1

  for (let i = number.length - 1; i >= 0; i--) {
    if (!isNaN(number[i])) {
      index = i
      break
    }
  }

  if (isNaN(number[index])) {
    return {
      lastNumber: number,
      nextNumber: '',
    }
  }

  const replacement =  Number(number[index]) + 1
  const nextNumber = number.substring(0, index) + replacement + number.substring(index + 1)

  return {
    lastNumber: number,
    nextNumber,
  }
}
