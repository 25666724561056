<template>
  <div>
    <ReleaseRetentionFilters @save="onFilter"/>
    <ReleaseRetentionTable v-if="filters" :filters="filters" class="mt-4"/>
  </div>
</template>
<script>
import ReleaseRetentionTable from '@/modules/common/components/release-retention/ReleaseRetentionTable.vue'
import ReleaseRetentionFilters
  from "@/modules/accounts-payable/components/subcontract-invoices/ReleaseRetentionFilters.vue";

export default {
  components: {
    ReleaseRetentionTable,
    ReleaseRetentionFilters,
  },
  data() {
    return {
      filters: null
    }
  },
  methods: {
    onFilter(filters) {
      this.filters = {
        ...filters,
      }
    }
  }
}
</script>
