import defaultTheme from 'tailwindcss/defaultTheme'
import colors from 'tailwindcss/colors'
import forms from '@tailwindcss/forms'
import typography from '@tailwindcss/typography'

export default {
  content: [
    './public/index.html',
    './src/**/*.{js,ts,vue}',
    './safelist.txt',
  ],
  theme: {
    extend: {
      boxShadow: {
        xs: '0 0 0 1px rgba(0, 0, 0, 0.05)',
      },
      fontFamily: {
        sans: ['Inter var', ...defaultTheme.fontFamily.sans],
        mono: ['Chivo Mono', ...defaultTheme.fontFamily.mono],
      },
      fontSize: {
        print: 'var(--size-print)',
        xxs: 'var(--size-xxs)',
        xs: 'var(--size-xs)',
        sm: 'var(--size-sm)',
        base: 'var(--size-base)',
        lg: 'var(--size-lg)',
        xl: 'var(--size-xl)',
        '2xl': 'var(--size-2xl)',
        '3xl': 'var(--size-3xl)',
        '4xl': 'var(--size-4xl)',
        '5xl': 'var(--size-5xl)',
        '6xl': 'var(--size-6xl)',
      },
      colors: {
        current: 'currentColor',
        'teal-600': colors.teal[600],
        'sky-600': colors.sky[600],
        'fuchsia-600': colors.fuchsia[600],
        'blue-600': '#2563eb',
        'yellow-600': '#f59e0b',
        'green-600': '#10b981',
        orange: colors.orange,
        primary: {
          50: 'var(--primary-50)',
          100: 'var(--primary-100)',
          200: 'var(--primary-200)',
          300: 'var(--primary-300)',
          400: 'var(--primary-400)',
          500: 'var(--primary-500)',
          600: 'var(--primary-600)',
          700: 'var(--primary-700)',
          800: 'var(--primary-800)',
          900: 'var(--primary-900)',
        }
      },
      zIndex: {
        ...defaultTheme.zIndex,
        999999: 999999,
      },
      maxWidth: {
        ...defaultTheme.maxWidth,
        '8xl': '90rem',
        '85': '85%',
      },
    },
  },
  plugins: [
    forms,
    typography,
  ],
}
