<template>
  <div>
    <JobLink
        :id="jobId"
        linkClass="text-gray-600 hover:text-gray-800"
    />
  </div>
</template>
<script>
  export default {
    computed: {
      data() {
        return this.params.node?.allLeafChildren[0]?.data || {}
      },
      jobId() {
        return this.get(this.data, 'attributes.job_id')
      },
    },
  }
</script>
