const authorizedPages = {
  Job: '/job-costing/jobs/add',
  IncomeBudget: '/job-costing/income-line-items/add',
  CostBudget: '/job-costing/cost-line-items/add',
  FinancialReport: '/ledger/settings/financial-reports/add',
  default: '',
}

const authorizedPagesMap = {
  [authorizedPages.Job]: {
    name: 'JobSelect',
    title: 'Job',
  },
  [authorizedPages.IncomeBudget]: {
    title: 'Line Item',
    resourceName: 'IncomeLineItems',
  },
  [authorizedPages.CostBudget]: {
    title: 'Line Item',
    resourceName: 'CostLineItems',
  },
  [authorizedPages.FinancialReport]: {
    title: 'Financial Report',
    resourceName: 'FinancialReports',
  },
  default: {},
}

export function canCopyFromSibling(pageName) {
  const pages = Object.values(authorizedPages)
  return pages.includes(pageName)
}

export function getSiblingComponent(pageName) {
  return authorizedPagesMap[pageName]
}

