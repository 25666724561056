<template>
  <div class="px-4 mb-4 print:px-0">
    <router-link :to="`/accounts-payable/invoices/${invoice.id}/view`"
                 class="font-medium text-sm print:text-print my-1 ml-1">
      {{ $t('Invoice ') }} #{{ invoice.number }}
    </router-link>
    <div class="mb-4 print:mb-2">
      <BaseDataTable :data="[invoice]"
                     :columns="invoiceColumns"
                     :no-borders="true"
                     :limit-max-height="false"
                     :show-pagination="false"
                     :compact="true"
      >
      </BaseDataTable>
      <div v-if="invoiceErrors"
           v-html="invoiceErrors"
           class="mt-2 flex justify-end text-red-600">
      </div>
    </div>
    <BaseDataTable
        v-if="invoice.entries"
        :data="invoice.entries"
        :columns="columns"
        :show-pagination="false"
        :no-borders="true"
        :limit-max-height="false"
        :has-summary="true"
        :compact="true"
    />
  </div>
</template>
<script>

  export default {
    props: {
      invoice: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      invoiceErrors() {
        const errors = this.invoice.errors || []
        return errors.map(e => e.message).join('\n')
      },
      invoiceColumns() {
        return [
          {
            label: this.$t('Number'),
            prop: 'number',
            maxWidth: 150,
          },
          {
            label: this.$t('Description'),
            prop: 'description',
            maxWidth: 180,
          },
          {
            label: this.$t('Status'),
            prop: 'status',
            maxWidth: 160,
            component: 'Status',
          },
          {
            label: this.$t('PO Number'),
            prop: 'po_number',
            maxWidth: 100,
          },
          {
            label: this.$t('Date'),
            prop: 'dates.date',
            maxWidth: 160,
            align: 'right',
            component: 'FormattedDate',
          },
          {
            label: this.$t('Discount Date'),
            prop: 'dates.discount_date',
            maxWidth: 160,
            align: 'right',
            component: 'FormattedDate',
          },
          {
            label: this.$t('Due Date'),
            prop: 'dates.due_date',
            maxWidth: 160,
            align: 'right',
            component: 'FormattedDate',
          },
        ]
      },
      columns() {
        return [
          {
            label: this.$t('Cost center'),
            prop: 'cost_center',
            maxWidth: 50,
          },
          {
            label: this.$t('Source'),
            prop: 'source_id',
            maxWidth: 120,
            component: this.$tableColumns.SourceLink,
          },
          {
            label: this.$t('Description'),
            prop: 'description',
            maxWidth: 120,
          },
          {
            label: this.$t('Type'),
            prop: 'type_id',
            maxWidth: 60,
            component: this.$tableColumns.TypeLink,
          },
          {
            label: this.$t('Line Item'),
            prop: 'addl_source_id',
            maxWidth: 140,
            minWidth: 55,
            component: this.$tableColumns.AddlSourceLink,
          },
          {
            label: this.$t('Account'),
            prop: 'account',
            maxWidth: 120,
            component: this.$tableColumns.AccountLink,
            summary: () => this.$t('Invoice Totals'),
          },
          {
            label: this.$t('Sub'),
            prop: 'subaccount',
            maxWidth: 120,
          },
          {
            label: this.$t('Qty'),
            prop: 'quantity',
            maxWidth: 50,
            align: 'right',
          },
          {
            label: this.$t('Amount'),
            prop: 'gross_amount',
            maxWidth: 100,
            align: 'right',
            component: this.$tableColumns.FormattedPrice,
            summary: () => this.$formatPrice(this.invoice.totals.gross_amount),
          },
          {
            label: this.$t('Discount'),
            prop: 'discount_amount',
            maxWidth: 100,
            align: 'right',
            component: this.$tableColumns.FormattedPrice,
            summary: () => this.$formatPrice(this.invoice.totals.discount_amount),
          },
          {
            label: this.$t('Retention'),
            prop: 'retention_amount',
            maxWidth: 100,
            align: 'right',
            component: this.$tableColumns.FormattedPrice,
            summary: () => this.$formatPrice(this.invoice.totals.retention_amount),
          },
          {
            label: this.$t('Net'),
            prop: 'net_amount',
            maxWidth: 100,
            align: 'right',
            component: this.$tableColumns.FormattedPrice,
            summary: () => this.$formatPrice(this.invoice.totals.net_amount),
          },
        ]
      },
    },
  }
</script>
