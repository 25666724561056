<template>
  <CostPlusBillingForm
      :data="billing"
      @refresh="getBilling"
  />
</template>
<script>
  import CostPlusBillingForm from '@/modules/accounts-receivable/components/cost-plus-billings/CostPlusBillingForm'

  export default {
    components: {
      CostPlusBillingForm,
    },
    computed: {
      billing() {
        const result = this.$store.state.accountsReceivable.currentBilling
        return {
          ...result.attributes,
          ...result.relationships,
        }
      },
    },
    methods: {
      async getBilling() {
        await this.$store.dispatch('accountsReceivable/getBilling', this.billing.id)
      }
    }
  }
</script>
