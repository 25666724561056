<template>
  <base-data-table
      v-bind="defaultAttributes"
      :columns="columns"
      :data="getData"
      :total-rows="rowsLength"
      :filters="filters"
      :has-summary="false"
      @force-pagination="forcePagination"
  >

    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template #header="{row}">
      <template v-if="row.level === 0">
        {{ row?.header?.label }}
      </template>
      <template v-else-if="row.level === 1">
        <div class="flex items-center space-x-2 pl-2">
          <span>{{ $t(groupHeaderLabel) }}</span>
          <component
            :is="headerGroupComponent"
            :data="row.header"
            :show-preview="false"
          />
        </div>
      </template>
    </template>

    <template #subtotal="{subtotal}">
      <td colspan="2" align="right">
        {{ getSubtotalTitle }}
        {{ $t('Totals') }}
      </td>
      <td
          v-for="{prop, component} in columnsWithSubtotals"
          :key="prop"
          align="right"
      >
        <template v-if="component === 'FormattedPercent'">

        </template>
        <template v-else-if="component === 'FormattedPrice'">
          {{ $formatPrice(subtotal[prop]) }}
        </template>
        <template v-else>
          {{ subtotal[prop] }}
        </template>
      </td>
    </template>

  </base-data-table>
</template>
<script lang="ts">
  import { PropType, defineComponent } from 'vue'
  import { Column } from '@/components/ag-grid/tableTypes'
  import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper.vue'
  import { workerCompSummarySortBy } from '@/modules/payroll/components/reports/util'

  export default defineComponent({
    extends: ReportTableWrapper,
    props: {
      filters: {
        type: Object as PropType<Record<string, any>>,
        default: () => ({}),
      },
    },
    computed: {
      columns() {
        return [
          {
            label: this.$t('Liability <br> Code'),
            prop: 'code',
            minWidth: 110,
            maxWidth: 110,
            component: 'EntityLink',
            redirectTo: '/payroll/rates/worker-compensation-rates?id={ID}',
            entityKey: 'workers_comp_rate.id',
          },
          {
            label: this.$t('Description'),
            prop: 'description',
            minWidth: 150,
            maxWidth: 220,
          },
          {
            label: this.$t('Regular <br> Hours'),
            prop: 'regular_hours',
            align: 'right',
            minWidth: 90,
            maxWidth: 120,
          },
          {
            label: this.$t('Total <br> Hours'),
            prop: 'total_hours',
            align: 'right',
            minWidth: 90,
            maxWidth: 120,
            hideZero: true,
          },
          {
            label: this.$t(`Total Pay<br> Subject: ${ this.filters.total_on }`),
            prop: 'subject_to',
            component: 'FormattedPrice',
            align: 'right',
            minWidth: 90,
            maxWidth: 120,
            hideZero: true,
          },
          {
            label: this.$t('Overtime <br> Exempt'),
            prop: 'overtime_exempt',
            component: 'FormattedPrice',
            align: 'right',
            minWidth: 90,
            maxWidth: 120,
            hideZero: true,
          },
          {
            label: this.$t('Premtime <br> Exempt'),
            prop: 'primetime_exempt',
            component: 'FormattedPrice',
            align: 'right',
            minWidth: 90,
            maxWidth: 120,
            hideZero: true,
          },
          {
            label: this.$t('Other <br> Exempt'),
            prop: 'other_exempt',
            component: 'FormattedPrice',
            align: 'right',
            minWidth: 90,
            maxWidth: 120,
            hideZero: true,
          },
          {
            label: this.$t('Pay Subject <br> To G/Liab'),
            prop: 'total_earnings',
            component: 'FormattedPrice',
            align: 'right',
            minWidth: 90,
            maxWidth: 120,
            hideZero: true,
          },
          {
            label: this.$t('Rate or <br> Percent'),
            prop: 'percent',
            component: 'FormattedPercent',
            align: 'right',
            minWidth: 90,
            maxWidth: 120,
            hideZero: true,
          },
          {
            label: this.$t('Calculated <br> Premiums'),
            prop: 'calculated',
            component: 'FormattedPrice',
            align: 'right',
            minWidth: 90,
            maxWidth: 120,
            hideZero: true,
          },
        ] as Column
      },
      columnsWithSubtotals(): Column[] {
        return this.columns.filter(column => column.align === 'right')
      },
      sortByEmployee(): boolean {
        return this.filters.sort_by === workerCompSummarySortBy.Employee
      },
      getSubtotalTitle(): string {
        return this.sortByEmployee ? this.$t('Employee') : this.$t('Job')
      },
      headerGroupComponent(): string {
        const componentMap = {
          [workerCompSummarySortBy.Employee]: 'EmployeeLink',
          [workerCompSummarySortBy.Job]: 'JobLink',
          default: 'EmployeeLink',
        }
        return componentMap[this.filters.sort_by] || componentMap.default
      },
      groupHeaderLabel(): string {
        return this.sortByEmployee ? 'Employee' : 'Job'
      },
      grandTotals() {
        const totals: Record<string, any> = {}
        for (let state in this.data.totals) {
          const row = this.data.totals[state]
          for (let key in row) {
            totals[key] = totals[key] ? totals[key] + row[key] : row[key]
          }
        }
        return totals
      }
    },
    methods: {
      composeRows(data: any []) {
        for (let state in data) {
          this.rows.push({
            header: {
              label: state,
            },
            level: 0,
          })
          for (let employee in data[state]) {
            const group = data[state][employee]
            const rates = group.gen_liability_rates || group.general_liability_rates
            const totals = group.totals || group.gen_liability_rates[0]
            this.rows.push({
                header: group.employee || group.job,
                level: 1,
              },
              ...rates,
              {
                subtotal: totals,
              })
          }
        }
      }
    }
  })
</script>
