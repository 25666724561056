<template>
  <div class="rounded flex items-end">
    <BaseButton variant="white" class="h-10 mr-1" @click="prevWeek">
      <ChevronLeftIcon class="w-4 h-4"/>
    </BaseButton>
    <el-date-picker
      v-bind="$attrs"
      v-on="$listeners"
      v-model="model"
      type="week"
      format="Week WW, yyyy"
      placeholder="Pick a week"
      :clearable="false"
      :picker-options="pickerOptions"
    >
    </el-date-picker>
    <BaseButton variant="white" class="h-10 ml-1" @click="nextWeek">
      <ChevronRightIcon class="w-4 h-4"/>
    </BaseButton>
  </div>
</template>
<script>
import { DatePicker } from "element-ui";
import { ChevronLeftIcon, ChevronRightIcon } from "vue-feather-icons";
import { addWeeks, startOfWeek, subDays, subWeeks } from "date-fns";
import { getPayPeriodStartDay } from "@/modules/payroll/components/timesheets/utils";

export default {
  components: {
    [DatePicker.name]: DatePicker,
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  props: {
    value: {
      type: Date,
      required: true,
    },
  },
  computed: {
    model: {
      get() {
        if (!this.value) {
          return startOfWeek(new Date())
        }
        return this.value
      },
      set(value) {
        let prevDay = subDays(value, 1)
        this.$emit('input', prevDay)
      }
    },
    pickerOptions() {
      return {
        firstDayOfWeek: getPayPeriodStartDay(),
      }
    }
  },
  methods: {
    prevWeek() {
      this.model = subWeeks(this.model, 1)
    },
    nextWeek() {
      this.model = addWeeks(this.model, 1)
    }
  }
}
</script>
