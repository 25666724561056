import axios from 'axios'

export async function saveRates(url: string | null, data: any[]) {
  if (!url) {
    return
  }
  const entriesToSave = data.filter((entry) => !entry.id && entry.rate)
  const entriesToUpdate = data.filter((entry) => entry.id && entry.dirty)

  const promises: Promise<any>[] = []
  if (entriesToSave.length) {
    promises.push(axios.post(`${url}/bulk`, entriesToSave))
  }
  if (entriesToUpdate.length) {
    promises.push(axios.post(`${url}/bulk/update`, entriesToUpdate))
  }
  await Promise.all(promises)
}
