export function getHiddenSSN(value) {
  if (!value) {
    return ''
  }

  let last4Digits = ''
  let start = 5
  for (let i = start; i < value.length; i++) {
    last4Digits += value.charAt(i)
  }
  last4Digits = last4Digits.padStart(4, '*')
  return `***-**-${last4Digits}`
}

export function getFormattedSSN(value) {
  if (!value) {
    return ''
  }

  value = value.replace(/\D/g, '')

  return value.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3')
}
