<template>
  <div>
    <BaseDataTable :data="rates"
                   :columns="getColumns"
                   :data-loading="loading"
                   :show-pagination="false"
                   :can-reorder-columns="false"
                   :delete-action="onRowDelete"
                   :open-entity-in-new-tab="false"
                   :add-text="$t('New billing rate')"
                   :limitMaxHeight="false"
                   :compact="true"
                   add-button-variant="primary-light"
                   ref="table"
                   permission="employees"
                   hide-actions="view"
                   actions="add,delete,edit"
                   @add="onRowAdd"
                   @edit="onRowEdit"
    >
      <template v-slot:custom-header>
        <h6 class="form-section-title">
          {{ $t('Billing Rates') }}
        </h6>
      </template>
    </BaseDataTable>
    <BaseFormDialog v-if="showDialog"
                    :title="rowToEdit ? $t('Edit Billing Rate') : $t('Add Billing Rate')"
                    :open.sync="showDialog"
                    @close="onCloseDialog"
    >
      <BillingRateForm v-bind="$props"
                       :data="rowToEdit"
                       :only-regular-type="onlyRegularType"
                       :defined-billing-types="definedBillingTypes"
                       layout="modal"
                       @cancel="onCloseDialog"
                       @save="onSubmit"
      />
    </BaseFormDialog>
  </div>
</template>
<script>
  import axios from 'axios'
  import BillingRateForm from '@/modules/common/components/BillingRateForm'

  export default {
    inheritAttrs: false,
    components: {
      BillingRateForm,
    },
    props: {
      entity: {
        type: Object,
        default: () => ({}),
      },
      onlyRegularType: {
        type: Boolean,
        default: false,
      },
      sourceType: String,
    },
    data() {
      return {
        rates: [],
        loading: false,
        showDialog: false,
        rowToEdit: null,
        rowToEditIndex: null,
        rowsToDelete: [],
      }
    },
    computed: {
      getColumns() {
        if (!this.onlyRegularType) {
          return [
            {
              label: this.$t('Rate'),
              prop: 'billingRateType.attributes.title',
            },
            {
              label: this.$t('Regular'),
              prop: 'regular_rate',
              component: 'FormattedPrice',
            },
            {
              label: this.$t('Overtime'),
              prop: 'overtime_rate',
              component: 'FormattedPrice',
            },
            {
              label: this.$t('Premium'),
              prop: 'premium_rate',
              component: 'FormattedPrice',
            },
          ]
        }
        return [
          {
            label: this.$t('Rate'),
            prop: 'billingRateType.attributes.title',
          },
          {
            label: this.$t('Regular'),
            prop: 'regular_rate',
            component: 'FormattedPrice',
          },
        ]
      },
      definedBillingTypes() {
        return this.rates.map(rate => rate.billing_rate_type_id)
      },
    },
    methods: {
      onRowEdit(row, index) {
        this.showDialog = true
        this.rowToEdit = row
        this.rowToEditIndex = index
      },
      onRowAdd() {
        this.showDialog = true
      },
      refreshTable() {
        this.$refs.table.refresh()
      },
      onCloseDialog() {
        this.rowToEdit = this.rowToEditIndex = null
        this.showDialog = false
        this.refreshTable()
      },
      onSubmit(rate) {
        if (this.rowToEdit) {
          this.rates.splice(this.rowToEditIndex, 1, rate)
        } else {
          this.rates.push(rate)
        }
        this.onCloseDialog()
      },
      async onRowDelete(row, index) {
        if (row.id) {
          this.rowsToDelete.push(row)
        }
        this.rates.splice(index, 1)
      },
      async runEntitiesOperation(source_id, source_type) {
        let promises = []
        let ratesToStore = []
        let ratesToUpdate = []

        ratesToUpdate = this.rates.filter(rate => rate.id).map(rate => rate)

        const newRates = this.rates.filter(rate => !rate.id)

        for (let i = 0; i < newRates.length; i++) {
          let rate = newRates[i]
          if (rate.billing_rate_type_id) {
            rate = {
              ...rate,
              source_id,
              source_type,
            }
            ratesToStore.push(rate)
          } else {
            const { data } = await axios.post('restify/billing-rate-types', rate.billingRateType)
            rate = {
              ...rate,
              source_id,
              source_type,
              billing_rate_type_id: data.id,
            }
            ratesToStore.push(rate)
          }
        }

        const rowsToDeleteIds = this.rowsToDelete.map(rate => rate.id)

        if (rowsToDeleteIds.length) {
          promises.push(axios.delete('/restify/billing-rates/bulk/delete', {
            data: rowsToDeleteIds,
          }))
        }

        if (ratesToStore.length) {
          promises.push(axios.post('/restify/billing-rates/bulk', ratesToStore))
        }
        if (ratesToUpdate.length) {
          promises.push(axios.post('/restify/billing-rates/bulk/update', ratesToUpdate))
        }

        await Promise.all(promises)
        this.rowsToDelete = []
      },
      async fetchData() {
        try {
          this.loading = true
          const { data } = await axios.get('/restify/billing-rates/', {
            params: {
              related: 'billingRateType',
              source_id: this.entity.id,
              source_type: this.sourceType,
            },
          })

          this.rates = data.map(rate => ({
            ...rate.attributes,
            ...rate.relationships,
          }))

        } catch (err) {
          console.warn(err)
        } finally {
          this.loading = false
        }
      },
    },
    watch: {
      entity: {
        deep: true,
        immediate: true,
        handler(value) {
          if (!value?.id) {
            return
          }

          this.fetchData()
        },
      },
    },
  }
</script>
