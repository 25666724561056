<template>
  <BaseSelect
      ref="select"
      v-focus
      v-model="selectedValue"
      :options="getOptions"
      :hide-label="true"
      :clearable="true"
      inline-errors
      class="w-full"
      @change="onChange"
      @keydown.enter.native="goToNextCell"
  />
</template>
<script>
  import { selectEditorMixin } from "@/components/ag-grid/cellEditors/selectEditorMixin";
  import { getSpecialCodeTypeOptions } from "@/components/ag-grid/cellEditors/cellEditorUtils";

  export default {
    name: 'CostCenterSelectCellEditor',
    mixins: [selectEditorMixin],
    computed: {
      costCenter() {
        return this.params?.data?.cost_center
      },
      getOptions() {
        if (this.params.options) {
          return this.params.options
        }
        return getSpecialCodeTypeOptions(this.costCenter)
      }
    },
  }
</script>
