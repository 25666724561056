<template>
  <StatusBadge :status="status">
    {{ UserStatusMap[status] }}
  </StatusBadge>
</template>
<script setup>
import { computed } from "vue";
import i18n from "@/i18n";

const props = defineProps({
  employee: {
    type: Object,
    default: () => ({})
  }
})

const UserStatuses = {
  HasUser: 'has_user',
  Invited: 'invited',
  NoUser: 'no_user',
}

const UserStatusMap = {
  [UserStatuses.HasUser]: i18n.t('Has User'),
  [UserStatuses.Invited]: i18n.t('Invitation Sent'),
  [UserStatuses.NoUser]: i18n.t('No User'),
}


const status = computed(() => {
  const { user_id, has_invitation } = props.employee?.attributes || {}
  if (user_id) {
    return UserStatuses.HasUser
  }
  if (has_invitation) {
    return UserStatuses.Invited
  }
  return UserStatuses.NoUser
})
</script>
