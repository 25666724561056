<template>
  <EntityApproveDialog
    v-bind="$attrs"
    v-on="$listeners"
    :entity-name="$t('purchase order')"
    :data="data"
    api-base-path="/restify/purchase-orders"
    base-path="/purchasing-inventory/purchase-orders"
  >
    <PurchaseOrderDetails
      :show-back="false"
      class="col-span-6"
    />
  </EntityApproveDialog>
</template>
<script>
import InvoiceDetails from "@/modules/accounts-payable/pages/invoices/invoice-details.vue";
import EntityApproveDialog from "@/modules/common/components/review/EntityApproveDialog.vue";
import PurchaseOrderDetails from "@/modules/purchasing-inventory/pages/purchase-orders/purchase-order-details.vue";

export default {
  components: {
    PurchaseOrderDetails,
    EntityApproveDialog,
    InvoiceDetails,
  },
  props: {
    data: {
      type: String,
      required: true,
    },
  },
  watch: {
    data: {
      immediate: true,
      handler(value) {
        if (!value) {
          return
        }
        this.$store.commit('inventoryManagement/SET_CURRENT_PURCHASE_ORDER', value)
      },
    }
  }
}
</script>
