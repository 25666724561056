<template>
  <div>
    <BaseButton variant="primary-light" @click="showDialog = true">
      <DownloadIcon class="w-4 h-4 mr-1"/>
      <span>{{ $t('Export') }}</span>
    </BaseButton>
    <BaseFormDialog
      v-if="showDialog"
      :title="title"
      :appendToBody="true"
      :open.sync="showDialog"
    >
      <BaseForm
        :save-text="$t('Export')"
        :cancel-text="$t('Cancel')"
        :show-cancel="true"
        :loading="loading"
        layout="vertical"
        @cancel="showDialog = false"
        @submit="onSubmit"
      >
        <BaseAlert class="col-span-6">
          {{ $t('Exporting the Timecard Batch will generate a CSV file with the timecard data.') }}
        </BaseAlert>
      </BaseForm>
    </BaseFormDialog>
  </div>
</template>
<script>
import axios from "axios";
import { DownloadIcon } from "vue-feather-icons";
import { downloadFileLocally } from "@/modules/common/util/downloadFileLocally";

export default {
  components: { DownloadIcon },
  data() {
    return {
      showDialog: false,
      loading: false,
      model: {
        settings: [],
      }
    }
  },
  computed: {
    currentResource() {
      return this.$store.state.payroll.currentTimecardBatch
    },
    shortTitle() {
      const date = this.$formatDateOnly(this.currentResource.period_end_date)
      return this.$t('Timecard Batch ') + date
    },
    title() {
      let title = this.shortTitle
      if (this.currentResource.description) {
        title += ` (${this.currentResource.description})`
      }
      return title
    }
  },
  methods: {
    async onSubmit() {
      try {
        const batchId = this.currentResource?.id
        this.loading = true
        const response = await axios.post(`/restify/timecard-batches/${batchId}/actions?action=timecard-batch-export`, this.model, {
          responseType: 'blob',
        })
        downloadFileLocally(response, `${this.title}.csv`)
        this.showDialog = false
      } catch (err) {
        this.$error(this.$t('Could not export the Timecard Batch'))
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
