<template>
  <BaseCard class="border">
    <div class="text-sm flex flex-col space-y-4">
      <div :title="$t('Code')"
           class="detail-row">
        <HashIcon class="detail-icon"/>
        <div>{{ vendorData.code }}</div>
        <StatusBadge :status="vendorData.status"/>
      </div>
      <div :title="$t('Contact')"
           class="detail-row">
        <BookOpenIcon class="detail-icon"/>
        <span>{{ vendorData.contact || $t('No contact provided') }}</span>
      </div>
      <div :title="$t('Website')"
           class="detail-row">
        <ExternalLinkIcon class="detail-icon"/>
        <a v-if="vendorData.website_url"
           :href="vendorData.website_url" ref="construction partner" target="_blank">
          {{ vendorData.website_url }}
        </a>
        <span v-else>{{ $t('No website provided') }}</span>
      </div>
      <div :title="$t('Fax')"
           class="detail-row">
        <PrinterIcon class="detail-icon"/>
        <span>{{ vendorData.fax || $t('No fax provided') }}</span>
      </div>
    </div>
  </BaseCard>
</template>
<script lang="ts" setup>
import { computed, PropType } from 'vue'
import { Vendor } from '@/modules/common/types/models'
import { HashIcon, BookOpenIcon, ExternalLinkIcon, PrinterIcon } from 'vue-feather-icons'
import Data = API.Data;

const props = defineProps({
  vendor: {
    type: Object as PropType<Data<Vendor>>,
    required: true,
  },
})

const vendorData = computed<Vendor>(() => {
  return props.vendor?.attributes || {}
})
</script>
