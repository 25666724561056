<template>
  <BaseFormDialog
      v-if="showOverridesDialog"
      :title="$t('Timecard Entry Overrides')"
      :appendToBody="true"
      :open.sync="showOverridesDialog"
      size="xl"
      @close="onClose">
    <Overrides
        :data="entryToOverride"
        :employee-id="params?.employee_id"
        @close="onClose"
        @submit="updateAndClose"
        @reset="onUpdateOverrides"
    />
  </BaseFormDialog>
</template>
<script>
  import Overrides from "@/modules/payroll/components/timecard/Overrides";

  export default {
    components: {
      Overrides,
    },
    data() {
      return {
        showOverridesDialog: false,
        entryToOverride: this.params?.data || {},
      }
    },
    mounted() {
      this.entryToOverride = this.params?.data || {}
      this.showOverridesDialog = true
    },
    methods: {
      getValue() {
        return {}
      },
      async onClose() {
        this.showOverridesDialog = false
        this.params?.stopEditing()
      },
      async onUpdateOverrides(entry, callback) {
        this.params.node.setData(entry)
        if (this.params.saveOrUpdateEntry) {
          const params = {
            ...this.params,
            data: entry,
          }
          const savedData = await this.params.saveOrUpdateEntry(params)
          if (savedData) {
            this.entryToOverride = savedData
          }
        }
        callback()
      },
      async updateAndClose(entry, callback) {
        await this.onUpdateOverrides(entry, callback)
        await this.onClose()
        callback()
      }

    },
  }
</script>
