<template>
  <div>
    <layout-tabs :items="filteredItems"/>
    <router-view/>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        items: [
          {
            name: this.$t('Job Rates'),
            path: '/payroll/rates/job-rates',
            permission: 'payroll_settings',
          },
          {
            name: this.$t('Union Rates'),
            path: '/payroll/rates/unions',
            permission: 'payroll_settings',
          },
          {
            name: this.$t('Time Off Rates'),
            path: '/payroll/rates/time-off-rates',
            permission: 'payroll_settings',
          },
          {
            name: this.$t(`Worker's Comp Rates`),
            path: '/payroll/rates/worker-compensation-rates',
            permission: 'payroll_settings',
          },
          {
            name: this.$t('Gen Liability Rates'),
            path: '/payroll/rates/general-liability-rates',
            permission: 'payroll_settings',
          },
          {
            name: this.$t('Copy/Delete Rates'),
            path: '/payroll/rates/copy-delete-rates',
            permission: 'payroll_settings',
          },
        ],
      }
    },
    computed: {
      filteredItems() {
        const permissions = this.$store.getters['auth/permissions']
        return this.items.filter(item => permissions.includes(item.permission))
      },
    },
  }
</script>
