<template>
  <BillingRatesForm v-loading="loading"
                    :data="billingRates"
                    :filters="filters"
  />
</template>
<script>
  import axios from 'axios'
  import { mapBillingRates } from '@/modules/accounts-receivable/pages/billing-rates/billingRates'
  import BillingRatesForm from '@/modules/accounts-receivable/components/customer-billing-rates/BillingRatesForm'

  export default {
    components: {
      BillingRatesForm,
    },
    data() {
      return {
        loading: false,
        filters: {},
        billingRates: [],
      }
    },
    methods: {
      async getCustomerBillings() {
        try {
          this.loading = true
          const { params } = this.$route
          this.filters = params
          this.filters.customer_id = params.customer_id

          const { data } = await axios.get('restify/customer-billing-rates', {
            params: {
              ...params,
              related: 'source',
            },
          })
          this.billingRates = mapBillingRates(data)
        } catch (err) {
          console.warn(err)
          if (err.handled) {
            return
          }
          this.$error(this.$t('Something went wrong please try again.'))
        } finally {
          this.loading = false
        }
      },
    },
    mounted() {
      this.getCustomerBillings()
    },
  }
</script>
