<template>
  <entity-select
      v-on="$listeners"
      v-bind="$attrs"
      :url-params="urlParams"
      :clearable="clearable"
      :add-entity="addEntity"
      :name="$attrs.name || $t('Phase Code')"
      :placeholder="$attrs.placeholder || $t('Phase Code')"
      :fetch-on-mount="fetchOnMount"
      :add-label="$t('Add new Budget')"
      :id="$attrs.id || 'phase-select'"
      :label-format="formatLabel"
      :table-columns="phaseCostTableColumns"
      :hide-label="hideLabel"
      :label-key="labelKey"
      url="/restify/line-items"
      class="w-full"
  />
</template>
<script>

  export default {
    inheritAttrs: false,
    props: {
      urlParams: {
        type: Object,
        default: () => ({
          sort: 'phase_code,cost_code,change_order',
        }),
      },
      clearable: {
        type: Boolean,
        default: true,
      },
      addEntity: {
        type: Boolean,
        default: true,
      },
      fetchOnMount: {
        type: Boolean,
        default: true,
      },
      hideLabel: {
        type: Boolean,
        default: false,
      },
      labelFormat: {
        type: Function,
      },
      labelKey: {
        type: String,
        default: 'phase_code',
      },
    },
    data() {
      return {
        phaseCostTableColumns: [
          {
            minWidth: 70,
            maxWidth: 70,
            name: this.$t('Phase Code'),
            prop: 'attributes.phase_code',
          },
          {
            minWidth: 80,
            maxWidth: 80,
            name: this.$t('Cost Code'),
            prop: 'attributes.cost_code',
          },
          {
            minWidth: 70,
            maxWidth: 70,
            name: this.$t('Change Order'),
            prop: 'attributes.change_order',
          },
          {
            minWidth: 150,
            maxWidth: 150,
            name: this.$t('Description'),
            prop: 'attributes.description',
          },
          {
            minWidth: 105,
            maxWidth: 105,
            name: this.$t('Budget'),
            prop: 'attributes.amount',
            toFormat: this.$formatPrice,
          },
          {
            minWidth: 105,
            maxWidth: 105,
            name: this.$t('Actual'),
            prop: 'attributes.amount_to_date',
            toFormat: this.$formatPrice,
          },
        ],
      }
    },
    methods: {
      formatLabel(option) {
        if (this.labelFormat) {
          return this.labelFormat(option)
        }
        const attributeValue = option.attributes[this.labelKey]
        return (attributeValue || attributeValue === 0) ? `${attributeValue}` : '-';
      },
    }
  }
</script>
