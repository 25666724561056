<template>
  <ValidationProvider
    v-bind="$attrs"
    :name="name || label"
    v-slot="{ errors }"
  >
    <BaseInputError
      :errors="errors"
      :show-tooltip="$attrs.inlineErrors"
    >
      <label
        v-if="label"
        :for="id"
        class="block text-sm font-medium leading-5 text-gray-700 truncate"
      >
        <div class="flex items-center">
          <span>{{ label }}</span>
          <span
            v-if="isRequired"
            class="text-gray-500"
          >
            *
          </span>
        </div>
      </label>
      <ElInput
        v-bind="$attrs"
        v-on="$listeners"
        :name="name"
        :placeholder="placeholder"
        :label="label"
        :id="id"
        class="base-free-text-select"
        :class="{
          'has-errors': errors.length,
          'has-dropdown': displayDropdown,
        }"
      >
        <template
          #append
          v-if="displayDropdown"
        >
          <BaseTooltip
            :content="optionsTooltip"
            :disabled="!optionsTooltip"
            :tabindex="-1"
          >
            <div>
              <LoaderIcon
                v-if="loading"
                class="w-4 h-4 spin-animation"
              />
              <ElSelect
                v-else
                v-model="selectedOption"
                placeholder=""
                class="entity-select"
                @change="onOptionSelected"
              >
                <ElOption
                  v-for="item in selectOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
                <template #empty>
                  <span
                    class="mx-3 text-gray-400 text-sm"
                  >
                    {{ noDataText }}
                  </span>
                </template>
              </ElSelect>
            </div>
          </BaseTooltip>
        </template>
      </ElInput>
    </BaseInputError>
  </ValidationProvider>
</template>
<script>
import {
  Select,
  Option,
  Input
} from 'element-ui'
import { LoaderIcon } from 'vue-feather-icons'
import i18n from '@/i18n'
import Cache from "@/utils/Cache";

export default {
  inheritAttrs: false,
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Input.name]: Input,
    LoaderIcon
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    optionsTooltip: {
      type: String,
      default: '',
    },
    optionsUrl: {
      type: String,
      default: '',
    },
    transformData: {
      type: Function,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    noDataText: {
      type: String,
      default: i18n.t('No Data'),
    },
    displayEmptyList: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectOptions: this.options,
      selectedOption: null,
      loading: false,
    }
  },
  computed: {
    isRequired() {
      const rules = this.$attrs.rules
      if (!rules) {
        return false
      }
      return rules?.includes && rules?.includes('required') || rules?.required
    },
    displayDropdown() {
      if (this.displayEmptyList) {
        return true
      }

      return this.loading || this.selectOptions.length > 0
    }
  },
  watch: {
    optionsUrl: {
      immediate: true,
      handler() {
        this.fetchOptions()
      }
    },
  },
  methods: {
    async fetchOptions() {
      if (!this.optionsUrl) {
        return
      }

      this.selectOptions = []

      try {
        this.loading = true

        const { data } = await Cache.getRequest(this.optionsUrl)

        this.selectOptions = this.transformData?.(data) || data
      }
      finally {
        this.loading = false
      }
    },
    onOptionSelected(value) {
      this.$emit('input', value)
      this.selectedOption = null
    }
  }
}
</script>
<style lang="scss">
.el-input.base-free-text-select {
  input {
    @apply form-input;
  }

  &.has-dropdown {
    input {
      @apply rounded-r-none;
    }
  }

  &.has-errors {
    input {
      @apply form-input-error;
    }
  }

  .el-select input.el-input__inner:focus:focus, .el-select .el-input.is-focus input.el-input__inner:focus:focus {
    @apply border-none shadow-none outline-none ring-0;
  }

  .el-select {
    .el-input__suffix {
      @apply left-1.5;
    }
  }
}
</style>
