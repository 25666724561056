<template>
  <AgDataTable
      v-bind="tableProps"
      :data="entries"
      :title="$t('Payroll Expense Accounts')"
      class="col-span-6"
      ref="payrollExpenseTable"
  />
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { Column } from "@/components/ag-grid/tableTypes";
  import { cellClasses } from "@/components/ag-grid/columnUtils";
  import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";
  import { GetRowIdParams } from '@ag-grid-community/core';

  export default defineComponent({
    props: {
      haveBurdenAccounts: {
        type: Boolean,
        default: false,
      },
      modulePrefix: String,
      data: {
        type: Object,
        default: () => ({})
      },
    },
    computed: {
      tableProps() {
        return {
          domLayout: 'autoHeight',
          pagination: false,
          showPagination: false,
          getRowId: (params: GetRowIdParams) => params.data.key,
          columns: [
            {
              headerName: this.$t('Name'),
              field: 'name',
              cellClass: cellClasses.ReadOnly,
              minWidth: 200,
              maxWidth: 320,
            },
            {
              headerName: this.$t('Account'),
              field: 'account',
              component: 'AccountLink',
              editable: true,
              cellEditor: cellEditors.AccountSelect,
              cellEditorParams: {
                valueKey: 'number'
              }
            },
            {
              headerName: this.$t('Sub'),
              field: 'subaccount',
              component: 'SubAccountLink',
              width: 150,
              maxWidth: 200,
              editable: true,
              cellEditor: cellEditors.SubAccountSelect,
              cellEditorParams: {
                valueKey: 'number'
              },
            },
          ] as Column[],
        }
      },
      entries() {
        const result = [
          {
            name: this.$t('Employer Benefits'),
            account: this.data[`employer_benefits_${this.modulePrefix}_expense_account`],
            subaccount: this.data[`employer_benefits_${this.modulePrefix}_expense_subaccount`],
            key: 'employer_benefits',
          },
          {
            name: this.$t('Employer FICA'),
            account: this.data[`fica_${this.modulePrefix}_expense_account`],
            subaccount: this.data[`fica_${this.modulePrefix}_expense_subaccount`],
            key: 'fica',
          },
          {
            name: this.$t('Federal Unemployment'),
            account: this.data[`fui_${this.modulePrefix}_expense_account`],
            subaccount: this.data[`fui_${this.modulePrefix}_expense_subaccount`],
            key: 'fui',
          },
          {
            name: this.$t('State Unemployment'),
            account: this.data[`sui_${this.modulePrefix}_expense_account`],
            subaccount: this.data[`sui_${this.modulePrefix}_expense_subaccount`],
            key: 'sui',
          },
          {
            name: this.$t('Worker\'s Comp'),
            account: this.data[`workers_comp_${this.modulePrefix}_expense_account`],
            subaccount: this.data[`workers_comp_${this.modulePrefix}_expense_subaccount`],
            key: 'workers_comp',
          },
          {
            name: this.$t('Local Tax'),
            account: this.data[`lit_${this.modulePrefix}_expense_account`],
            subaccount: this.data[`lit_${this.modulePrefix}_expense_subaccount`],
            key: 'lit',
          },
          {
            name: this.$t('General Liability'),
            account: this.data[`gen_liability_${this.modulePrefix}_expense_account`],
            subaccount: this.data[`gen_liability_${this.modulePrefix}_expense_subaccount`],
            key: 'gen_liability',
          },
          {
            name: this.$t('Medicare'),
            account: this.data[`medicare_${this.modulePrefix}_expense_account`],
            subaccount: this.data[`medicare_${this.modulePrefix}_expense_subaccount`],
            key: 'medicare',
          },
        ]
        if (!this.haveBurdenAccounts) {
          return result
        }
        result.splice(5, 0, {
          name: this.$t('Add\'l Burden'),
          account: this.data[`estimated_burden_${this.modulePrefix}_expense_account`],
          subaccount: this.data[`estimated_burden_${this.modulePrefix}_expense_subaccount`],
          key: 'estimated_burden',
          renderIf: () => this.haveBurdenAccounts,
        })
        return result
      },
    },
    methods: {
      updatePayrollExpenseAccounts() {
        const entries = this.$refs.payrollExpenseTable?.tableData || []
        entries.forEach((entry: any) => {
          this.$set(this.data, `${entry.key}_${this.modulePrefix}_expense_account`, entry.account)
          this.$set(this.data, `${entry.key}_${this.modulePrefix}_expense_subaccount`, entry.subaccount)
        })
      },
    },
  })
</script>
