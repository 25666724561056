<template>
  <div class="import-data mt-4">
    <ImportForm
        v-bind="importSettings"
        @success="redirectToList"
    >
      <BankSelect
        v-model="model.bank_id"
        :edit-entity="false"
        class="col-span-6 md:col-span-1"
        id="fiscal_year"
      />
    </ImportForm>
  </div>
</template>
<script>
  import ImportForm from '@/modules/common/components/import/ImportForm';
  import { getInitialMapping } from '@/modules/payroll/utils/timecardImportColumns';

  const colMappings = {
    VendorCode: {
      value: 'vendor_code',
      label: 'Vendor Code',
      alternativeLabels: ['Vendor', 'Code'],
      required: true,
    },
    Date: {
      value: 'date',
      label: 'Date',
      alternativeLabels: ['Invoice Date'],
      required: true,
    },
    Description: {
      value: 'description',
      label: 'Description',
      alternativeLabels: ['Invoice Description'],
    },
    Account: {
      value: 'account',
      label: 'Account',
      required: true,
      alternativeLabels: ['G/L Account'],
    },
    Subaccount: {
      value: 'subaccount',
      label: 'Sub-account',
      alternativeLabels: ['G/L Sub-Account'],
    },
    GrossAmount: {
      value: 'gross_amount',
      label: 'Gross Amount',
      alternativeLabels: ['Amount', 'Invoice Amount', 'Gross'],
      required: true,
    },
    CostCenter: {
      value: 'cost_center',
      label: 'Cost Center',
      alternativeLabels: ['Distribution Center'],
    },
    SourceCode: {
      value: 'source_code',
      label: 'Source Code',
      alternativeLabels: ['Distribution Code'],
    },
    SourceType: {
      value: 'source_type',
      label: 'Source Type',
      alternativeLabels: ['Distribution Type'],
    },
    PhaseCode: {
      value: 'phase_code',
      label: 'Phase Code',
      alternativeLabels: ['Phase'],
    },
    CostCode: {
      value: 'cost_code',
      label: 'Cost Code',
      alternativeLabels: ['Cost'],
    },
    ChangeOrder: {
      value: 'change_order',
      label: 'Change Order',
      alternativeLabels: ['Order'],
    },
  }

  const options = [
    colMappings.VendorCode,
    colMappings.Date,
    colMappings.Description,
    colMappings.GrossAmount,
    colMappings.CostCenter,
    colMappings.SourceCode,
    colMappings.SourceType,
    colMappings.PhaseCode,
    colMappings.CostCode,
    colMappings.ChangeOrder,
    colMappings.Account,
    colMappings.Subaccount,
  ]

  export default {
    components: {
      ImportForm,
    },
    data() {
      return {
        importSettings: {
          url: '/restify/invoices/receipts/import',
          columnOptions: options,
          columnMappings: colMappings,
          initialMapping: getInitialMapping(options),
          transformRequestData: this.transformRequestData,
          type: 'invoice-import',
          resource: 'receipts',
        },
        model: {
          bank_id: null,
        },
      }
    },
    methods: {
      transformRequestData(formData) {
        formData.append('bank_id', this.model.bank_id)
      },
      async redirectToList() {
        await this.$router.push('/accounts-payable/invoices/receipts')
      },
    },
  }
</script>
