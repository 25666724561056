import parse from 'date-fns/parse'
import differenceInWeeks from 'date-fns/differenceInWeeks'

export const LastUpdate = '24-07-2022'

/**
 * Hide after a number of weeks of inactivity
 * @type {number}
 */
export const HideAfterInactivityWeeks = 2

export function showChangelog() {
  const lastUpdate = parse(LastUpdate, 'dd-MM-yyyy', new Date())
  const difference = differenceInWeeks(new Date(), lastUpdate)
  return difference <= HideAfterInactivityWeeks
}
