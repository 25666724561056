import store from '@/store'

function activeFiscalYear(): number | string {
  return store.getters['company/getSessionActiveFiscalYear']
}

const entities: Record<string, string> = {
  Accounts: 'accounts'
}

const reportsNumberMap: Record<string, number> = {
  [entities.Accounts]: 1882,
}

function getMappedFilters(entity: string, filters: Record<string, any>) {
  const filtersByEntity: Record<string, Record<string, any>> = {
    [entities.Accounts]: {
      account_ids: [],
      account_number_from: filters?.number_range ? mapRangeToNumbers(filters.number_range).number_from : filters.number,
      account_number_to: filters?.number_range ? mapRangeToNumbers(filters.number_range).number_to : filters.number,
      fiscal_year: activeFiscalYear(),
      exclude_inactive: !filters.active,
      double_spaced: false,
    }
  }

  return filtersByEntity[entity]
}

const entitiesWithRelatedReport: string[] = Object.values(entities)

export function hasRelatedReport(entity: string): boolean {
  return entitiesWithRelatedReport.includes(entity)
}

export function getReportNumber(entity: string): number {
  return reportsNumberMap[entity]
}

export function getFiltersByEntity(entity: string, filters: Record<string, any>) {
  const allFilters = getMappedFilters(entity, filters)

  let result: Record<string, any> = {}
  for (const [key, value] of Object.entries(allFilters)) {
    if (value || value === 0 || value === false) {
      result[key] = value
    }
  }

  return result
}

function mapRangeToNumbers(numberRange: string): { number_from: string, number_to: string } {
  let numberFrom, numberTo;

  if (numberRange.includes(',')) {
    // Range format: "1000,2000"
    const rangeValues = numberRange.split(',');
    numberFrom = parseInt(rangeValues[0], 10);
    numberTo = parseInt(rangeValues[1], 10);
  } else if (numberRange.includes('-')) {
    // Range format: "1000-2000"
    const rangeValues = numberRange.split('-');
    numberFrom = parseInt(rangeValues[0], 10);
    numberTo = parseInt(rangeValues[1], 10);
  }

  return {
    number_from: `${numberFrom}`,
    number_to: `${numberTo}`,
  };
}
