<template>
  <BaseFormDialog
    v-bind="$attrs"
    v-on="$listeners"
    :title="$t('Export Data')"
    :append-to-body="true"
  >
    <base-form
      :save-text="$t('Export')"
      :loading="loading"
      :focus-on-first-input="false"
      layout="vertical"
      @cancel="$emit('close')"
      @submit="onSubmit"
    >
      <div class="col-span-6">
        <base-select
          v-model="model.columns"
          :options="columnOptions"
          :label="$t('Columns')"
          :name="$t('Columns')"
          :placeholder="$t('Choose columns for export')"
          :rules="'required'"
          id="columns"
          multiple
        />
      </div>
    </base-form>
  </BaseFormDialog>
</template>
<script>
import startCase from "lodash/startCase";
import sortBy from "lodash/sortBy";
import axios from "axios";
import { downloadFileLocally } from "@/modules/common/util/downloadFileLocally";

export default {
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    url: {
      type: String,
    },
    urlParams: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {
      loading: false,
      model: {
        columns: [...this.columns],
        format: 'csv',
        settings: [],
        repositories: 'all',
      },
    }
  },
  computed: {
    columnOptions() {
      let mappedColumns = this.columns
        .map(col => {
          return {
            value: col?.value || col,
            label: col?.label || startCase(col),
          }
        })
      return sortBy(mappedColumns, 'label')
    }
  },
  methods: {
    parseResponse(data) {
      const file = data[0]
      if (!file) {
        this.$error('No data to export')
        return
      }
      downloadFileLocally(file.content, file.name)
    },
    async onSubmit() {
      try {
        this.loading = true
        const { data } = await axios.post(this.url, this.model, {
          params: this.urlParams || {},
        })
        this.parseResponse(data)
        this.$emit('close')
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(this.$t('Could not export data'))
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
