<template>
  <BaseFormDialog
    v-bind="$attrs"
    :title="$t('Create One Off Check')"
    :appendToBody="true"
    size="lg"
    @close="$emit('close')"
  >
    <BaseForm
      :saveText="$t('Create')"
      :showCancel="true"
      :loading="saving"
      layout="modal"
      gridClasses="grid grid-cols-8 gap-x-3"
      @submit="onSubmit"
      @cancel="$emit('close')"
    >
     <template #extra-buttons>
        <BaseButton
          :loading="saving"
          type="button"
          class="ml-2"
          @click.stop="onSubmit(true)"
        >
          <PrinterIcon class="w-5 h-5 mr-2" />
          {{ $t('Create & Print') }}
        </BaseButton>
      </template>
      <BankSelect
        v-model="model.bank_id"
        :label="$t('Bank')"
        :placeholder="$t('Bank')"
        :edit-entity="false"
        :disabled="true"
        id="bank"
        class="col-span-8 md:col-span-2"
      />
      <BaseInput
        v-model="model.number"
        :name="$t('Number')"
        :label="$t('Number')"
        :placeholder="$t('Number')"
        rules="required"
        type="number"
        id="number"
        class="col-span-8 md:col-span-2"
      />

      <BaseDatePicker
        v-model="model.date"
        :label="$t('Date')"
        :placeholder="$t('Date')"
        id="date"
        class="col-span-8 md:col-span-2"
        rules="required"
      />

      <BaseInput
        v-model="model.amount"
        :label="$t('Amount')"
        :placeholder="$t('Amount')"
        :step="1"
        :max="9999999999.99"
        id="amount"
        type="number"
        format="price"
        rules="required|max_value:9999999999.99|min_value:1"
        class="col-span-8 md:col-span-2"
      />

      <BaseInput
        v-model="model.payable_to_name"
        :name="$t('Payee Name')"
        :label="$t('Payee Name')"
        :placeholder="$t('Payee Name')"
        rules="required"
        id="payable_to_name"
        class="col-span-8 md:col-span-2"
      />

      <BaseTextarea
        v-model="model.payable_to_address"
        :name="$t('Payee Address')"
        :label="$t('Payee Address')"
        :placeholder="$t('Payee Address (Optional)')"
        id="payable_to_address"
        class="col-span-8 md:col-span-2"
      />

      <FiscalYearSelect
        :value="fiscal_year"
        :label="$t('Fiscal Year')"
        :placeholder="$t('Fiscal Year')"
        disabled
        id="fiscal_year"
        rules="required"
        class="col-span-8 md:col-span-2"
      />

      <PeriodSelect
        :value="period"
        :label="$t('Fiscal Period')"
        :placeholder="$t('Fiscal Period')"
        disabled
        id="period"
        rules="required"
        class="col-span-8 md:col-span-2"
      />

      <BaseAlert
        v-if="model.number != nextCheckNumber"
        type="warning"
        class="col-span-full -mt-2 mb-2"
      >
        <span v-html="paymentNumberWarning" />
      </BaseAlert>
      <div class="col-span-full">
        <AgDataTable
          v-bind="editableTableProps"
          :title="$t('GL Distribution Entries')"
          :columns="glEntriesColumns"
          :data="model.entries"
          actions="add,delete"
          :deleteTitle="$t('Delete Entry')"
          :deleteDescription="$t('Are you sure you want to delete this entry?')"
          :addText="$t('Add Entry')"
          :get-empty-row="getEmptyEntry"
          hide-actions="filters"
          @grid-ready="grid = $event"
          @data-updated="model.entries = $event"
        />
      </div>
    </BaseForm>
  </BaseFormDialog>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'
import axios from 'axios'
import { Bank } from '@/modules/common/types/models'
import Data = API.Data;
import { editableTableProps } from '@/components/ag-grid/tableUtils'
import { accountCol, descriptionCol, subAccountCol } from '@/components/ag-grid/columns/costCenterColumns'
import { cellClasses, getCellClasses } from '@/components/ag-grid/columnUtils'
import { getEditablePriceCol } from '@/components/ag-grid/columns/editableColumns'
import { validateAgDataTable } from '@/components/ag-grid/tableUtils'
import { PrinterIcon } from 'vue-feather-icons';

type GlEntry = {
  _localId: string,
  account: string,
  subaccount: string,
  amount: number,
  description: string,
}

export default defineComponent({
  components: {
    PrinterIcon,
  },
  props: {
    bank: {
      type: Object as PropType<Data<Bank>>,
      required: true,
    },
  },
  data() {
    return {
      editableTableProps,
      saving: false,
      grid: null,
      fiscal_year: this.$currentFiscalYear,
      period: this.$currenPeriod,
      model: {
        bank_id: this.bank.id,
        account: this.bank.attributes.account,
        subaccount: this.bank.attributes.subaccount,
        payable_to_name: '',
        payable_to_address: '',
        number: 0,
        date: this.$formatDateOnly(new Date(), 'yyyy-MM-dd'),
        amount: 0,
        entries: [
        ] as GlEntry[],
      },
    }
  },
  computed: {
    nextCheckNumber() {
      return Number(this.bank.attributes.last_ap_check_no) + 1
    },
    paymentNumberWarning() {
      const numberOnModel = Number(this.model.number)
      const nextCheckNumber = this.nextCheckNumber

      if (numberOnModel < nextCheckNumber) {
        return this.$t('Number is smaller than the next check number for this bank.<br>This might cause collisions with existing invoice payments.')
      }

      if (numberOnModel > nextCheckNumber) {
        return this.$t('Number is greater than the next check number for this bank.<br>This might create gaps in invoice payments sequencing.')
      }

      return ''
    },
    glEntriesColumns() {
      return [
        {
          ...accountCol(),
          cellClass: getCellClasses,
          minWidth: 200,
          maxWidth: 350,
        },
        {
          ...subAccountCol,
          minWidth: 80,
          maxWidth: 120,
        },
        {
          ...getEditablePriceCol({
            headerName: this.$t('Amount'),
            field: 'amount',
            minWidth: 110,
            maxWidth: 150,
            cellClassRules: {
              [cellClasses.Invalid]: (params: any) => {
                const { amount } = params?.data || {}
                return !amount && params?.data
              }
            },
            onChange: null,
          }),
        },
        {
          ...descriptionCol,
          minWidth: 200,
          maxWidth: null,
        },
      ]
    },
    glEntriesTotalAmount() {
      return this.model.entries.reduce((acc, entry) => acc + Number(entry.amount || 0), 0)
    }
  },
  methods: {
    async validateGlEntries() {
      if (!this.model.entries.length) {
        this.$error('At least one GL Distribution Entry is required.')
        return false
      }

      if (this.glEntriesTotalAmount !== this.model.amount) {
        this.$error('GL Distribution Entries total amount must match the check amount.')
        return false
      }

      const isInvalidData = await validateAgDataTable()
      if (isInvalidData) {
        return false
      }

      return true
    },
    async onSubmit(print = false) {      
      if (!await this.validateGlEntries()) {
        return
      }

      try {
        this.saving = true
        const { data } = await axios.post(`/restify/one-off-checks`, this.model)
        const { id } = data

        const postUrl = `/restify/one-off-checks/${id}/actions?action=post-one-off-check`
        await axios.post(postUrl)

        this.$emit('created', {
          check: data,
          print,
        })
      }
      catch (err: any) {
        if (err.handled) {
          return
        }

        this.$error(this.$t('Could not create One-Off Check'))
      }
      finally {
        this.saving = false
      }
    },
    async onDateChange() {
      if (!this.model.date) {
        return
      }

      const { data } = await axios.get(`/support/fiscal-year-period`, {
        params: {
          date: this.model.date,
        }
      })

      this.fiscal_year = data.fiscal_year
      this.period = data.period
    },
    getEmptyEntry() {
      const amountDiff = this.model.amount - this.glEntriesTotalAmount
      return {
        _localId: crypto.randomUUID(),
        account: '',
        subaccount: '',
        amount: amountDiff < 0 ? 0 : amountDiff,
        description: '',
      }
    },
    resetModel() {
      this.model = {
        ...this.model,
        bank_id: this.bank.id,
        account: this.bank.attributes.account,
        subaccount: this.bank.attributes.subaccount,
        number: this.nextCheckNumber,
        date: this.$formatDateOnly(new Date(), 'yyyy-MM-dd'),
        amount: 0,
        entries: [],
      }

      this.onDateChange()
    },
  },
  watch: {
    ['model.date']: {
      handler() {
        this.onDateChange()
      },
      immediate: true,
    }
  },
  created() {
    this.resetModel()
  },
})
</script>
