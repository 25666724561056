<template>
  <BaseDetailCard
    :title="$t('Inventory and Order')"
    :expanded="true"
    entity-name="material"
  >
    <BaseDetailCardRow :title="$t('Current Inventory Value')">
      {{ material.current_value }}
    </BaseDetailCardRow>
    <BaseDetailCardRow :title="$t('Current Quantity on Order')">
      {{ material.quantity_on_order }}
    </BaseDetailCardRow>
    <p class="border-t border-gray-200"/>
    <BaseDetailCardRow :title="$t('Total Quantity on Hand')">
      {{ material.quantity_on_hand }}
    </BaseDetailCardRow>
    <BaseDetailCardRow :title="$t('Less Invoices Pending')">
      <div class="text-red-500">{{ material.quantity_not_invoiced }}</div>
    </BaseDetailCardRow>
    <BaseDetailCardRow :title="$t('Paid Quantity on Hand')">
      {{ paidQuantityOnHand }}
    </BaseDetailCardRow>
    <p class="border-t border-gray-200"/>
    <BaseDetailCardRow :title="$t('Average $ / Unit')">
      {{ formatPrice(averageUnitCost) }}
    </BaseDetailCardRow>
  </BaseDetailCard>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { formatPrice } from '@/plugins/formatPrice'

const props = defineProps({
  material: {
    type: Object,
    required: true,
  },
})

const paidQuantityOnHand = computed(() => {
  return Math.abs(props.material?.quantity_on_hand) + Math.abs(props.material.quantity_not_invoiced)
})

const averageUnitCost = computed(() => {
  return paidQuantityOnHand.value > 0 ? props.material.current_value / paidQuantityOnHand.value : 0
})
</script>
