<template>
  <div>
    <layout-tabs :items="filteredItems"/>
    <router-view/>
  </div>
</template>
<script>

  export default {
    data() {
      return {
        items: [
          {
            name: this.$t('Purchase Order Listing'),
            path: '/purchasing-inventory/reports/purchase-order-listing',
            permission: 'inventory_management_reports',
          },
        ],
      }
    },
    computed: {
      filteredItems() {
        return this.items.filter(item => this.$can(item.permission))
      },
    },
  }
</script>
