<template>
  <base-data-table
    v-bind="defaultAttributes"
    :filters="filters"
    :columns="columns"
    :data="getData"
    :total-rows="rowsLength"
    @force-pagination="forcePagination"
    class="service-billing-history-report"
  >

    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template #work_order="{row}">
      <WorkOrderLink
        :data="row.work_order"
        :show-description="false"
        :show-preview="false"
      />
    </template>
    <template #customer="{row}">
      <CustomerLink
        :data="row.customer"
        :show-name="true"
        :show-preview="false"
      />
    </template>

    <template #assigned="{row}">
      <EmployeeLink
        :data="row.assigned"
        :show-description="false"
        :show-preview="false"
      />
    </template>
    <template #salesperson="{row}">
      <EmployeeLink
        :data="row.salesperson"
        :show-description="false"
        :show-preview="false"
      />
    </template>

    <template #html-row="{htmlData}">
      <template>
        <tr class="content-row">
          <td>
            <div>
              {{ $t('Cost:') }}
            </div>
            <div>
              {{ $t('Income:') }}
            </div>
          </td>
          <td colspan="10">
            <div class="flex space-x-10 items-center text-right">
              <div
                v-for="(type, key) in htmlData.service_billing_types.cost"
                :key="key"
                :title="type.service_billing_type.name"
                class="w-[120px] flex justify-between"
              >
                <span class="text-xs mr-2 text-gray-500">{{ type.service_billing_type.abbr }}</span>
                <span>{{ $formatPrice(type.service_billing_type.total) }}</span>
              </div>
            </div>
            <div class="flex space-x-10 items-center">
              <div
                v-for="(type, key) in htmlData.service_billing_types.income"
                :key="key"
                :title="type.service_billing_type.name"
                class="w-[120px] flex justify-between"
              >
                <span class="text-xs mr-2 text-gray-500">{{ type.service_billing_type.abbr }}</span>
                <span>{{ $formatPrice(type.service_billing_type.total) }}</span>
              </div>
            </div>
          </td>
        </tr>
      </template>
    </template>
    <template #footer-row>
      <td colspan="11">
        <ServiceBillingTypesTotalsFooter :grand-totals="grandTotals"/>
      </td>
    </template>
  </base-data-table>
</template>
<script>
import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'
import ServiceBillingTypesTotalsFooter
  from "@/modules/service-billing/components/reports/ServiceBillingTypesTotalsFooter.vue";

export default {
  components: { ServiceBillingTypesTotalsFooter },
  extends: ReportTableWrapper,
  props: {
    format: {
      required: true,
      type: String,
      default: '',
    },
    filters: {
      type: Object,
      default: () => ({}),
    }
  },
  computed: {
    columns() {
      return [
        {
          label: this.$t('Work <br> Order'),
          prop: 'work_order',
          minWidth: 60,
          maxWidth: 80,
        },
        {
          label: this.$t('Customer'),
          prop: 'customer',
          minWidth: 200,
          maxWidth: 300,
        },
        {
          label: this.$t('Work <br> Site'),
          prop: 'worksite.number',
          minWidth: 50,
          maxWidth: 50,
        },
        {
          label: this.$t('Date'),
          prop: 'work_order.date',
          minWidth: 100,
          maxWidth: 100,
          component: 'FormattedDate',
        },
        {
          label: this.$t('Status'),
          prop: 'work_order.status',
          minWidth: 60,
          maxWidth: 100,
          component: 'Status',
        },
        {
          label: this.$t('Category'),
          prop: 'category.code',
          minWidth: 60,
          maxWidth: 100,
        },
        {
          label: this.$t('Sales <br> Person'),
          prop: 'salesperson',
          minWidth: 60,
          maxWidth: 80,
          component: 'EmployeeLink',
          summary: () => this.$t('Grand Total:'),
        },
        {
          label: this.$t('Assigned <br> To'),
          prop: 'assigned',
          minWidth: 60,
          maxWidth: 80,
          component: 'EmployeeLink',
        },
        {
          label: this.$t('Cost'),
          prop: 'total.cost',
          minWidth: 100,
          maxWidth: 140,
          align: 'right',
          component: 'FormattedPrice',
          summary: () => this.$formatPrice(this.grandTotals.cost?.total),
        },
        {
          label: this.$t('Billed'),
          prop: 'total.income',
          minWidth: 100,
          maxWidth: 140,
          align: 'right',
          component: 'FormattedPrice',
          summary: () => this.$formatPrice(this.grandTotals.income?.total),
        },
        {
          label: this.$t('Gross Margin'),
          prop: 'total.gross_margin',
          minWidth: 100,
          maxWidth: 140,
          align: 'right',
          component: 'FormattedPrice',
          summary: () => this.$formatPrice(this.grandTotals.gross_margin),
        },
      ]
    },
  },
  methods: {
    composeRows(data) {
      data.forEach(row => {
        this.rows.push(row)
        if (this.filters.format === 'long') {
          this.rows.push({
            htmlData: row,
          })
        }
      })
      return this.rows
    },
  },
}
</script>
