<template>
  <div class="flex justify-between items-center"
       :class="{'w-full': showEditComponent}"
       v-if="hasField && isFieldEmpty && canEditField">
    <div v-if="showEditComponent">
      <base-phone-input v-if="field === 'phone'"
                        label=""
                        inline-errors
                        v-model="model.phone"
                        id="phone"
      />
      <account-select v-else-if="field === 'account'"
                      v-model="model.account"
                      inline-errors
                      :name="$t('Account Number')"
                      value-key="number"
                      id="account"
      />
      <sub-account-select v-else-if="field === 'subaccount'"
                          v-model="model.subaccount"
                          value-key="number"
                          id="subaccount"
      />
      <base-input v-else
                  v-model="model[field]"
                  class="-mt-1"
                  inline-errors
                  :placeholder="fieldName"
                  :id="field"
                  :rules="getRules"
      />
    </div>
    <div>
      <template v-if="!showEditComponent">
        <base-button variant="primary-light"
                     size="xs"
                     class="add-button"
                     @click="toggleFieldEdit">
          <IconAdd class="w-4 h-4 mr-1"/>
          {{ $t('Add') }}
          <span class="capitalize ml-1">{{ fieldName }}</span>
        </base-button>
      </template>
      <template v-else>
        <base-cancel-button size="xs" @click="hideEditComponent"/>
        <base-submit-button class="ml-2" size="xs" @click="saveField">
          {{$t('Save')}}
        </base-submit-button>
      </template>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'
  import startCase from "lodash/startCase";
  import camelCase from "lodash/camelCase";
  import isEmpty from 'lodash/isEmpty'
  import capitalize from 'lodash/capitalize'
  import cloneDeep from 'lodash/cloneDeep'

  export default {
    props: {
      entity: {
        type: Object,
        default: () => ({})
      },
      field: String,
      entityName: String,
    },
    data() {
      return {
        model: {},
        showEditComponent: false,
      }
    },
    computed: {
      label() {
        return capitalize(this.field)
      },
      fieldName() {
        return startCase(camelCase(this.field))
      },
      getRules() {
        if (this.field === 'email') {
          return 'required|email'
        }
        return ''
      },
      hasAttributes() {
        return typeof this.entity.attributes === 'object'
      },
      hasField() {
        if (!this.field) {
          return false
        }
        let objectKeys = []
        if (this.hasAttributes) {
          objectKeys = Object.keys(this.entity.attributes)
        } else {
          objectKeys = Object.keys(this.entity)
        }
        return objectKeys.includes(this.field)
      },
      isFieldEmpty() {
        if (!this.field) {
          return false
        }
        if (this.hasAttributes) {
          return isEmpty(this.entity.attributes[this.field])
        }
        return isEmpty(this.entity[this.field])
      },
      canEditField() {
        return this.entity.authorizedToUpdate || this.get(this.entity, 'meta.authorizedToUpdate')
      }
    },
    methods: {
      focusOnInput() {
        const input = this.$el.querySelector('input')
        if (input) {
          input.focus()
        }
      },
      toggleFieldEdit() {
        this.showEditComponent = true
        this.$emit('toggle-input', true)
        this.$nextTick(() => {
          this.focusOnInput()
        })
      },
      hideEditComponent() {
        this.showEditComponent = false
        this.$emit('toggle-input', false)
      },
      setFieldData() {
        if (this.hasAttributes) {
          this.$set(this.entity.attributes, this.field, this.model[this.field])
        } else {
          this.$set(this.entity, this.field, this.model[this.field])
        }
      },
      async saveField() {
        try {
          this.loading = true
          const id = this.$route.params.id
          const url = `/restify/${this.entityName}s/${id}`
          await axios.patch(url, {
            [this.field]: this.model[this.field]
          })
          this.setFieldData()
          this.hideEditComponent()
          this.$success(this.$t(`${capitalize(this.entityName)} ${this.field} updated`))
        } catch (err) {
          if (!err.handled) {
            this.$error(this.$t(`Could not save ${this.entityName} ${this.field}`))
          }
        } finally {
          this.loading = false
        }
      },
      getRequestModel() {
        const fieldsToExclude = ['contacts', 'addresses', 'authorizedToShow', 'authorizedToUpdate', 'authorizedToStore', 'authorizedToDelete']
        const data = cloneDeep(this.model)
        fieldsToExclude.forEach(field => {
          delete data[field]
        })
        return data
      }
    },
    watch: {
      entity: {
        immediate: true,
        handler(value) {
          if (!value) {
            return
          }
          if (value.attributes) {
            this.model = {
              ...value.attributes
            }
          } else {
            this.model = {
              ...value
            }
          }
        }
      }
    }
  }
</script>
<style scoped>
  .add-button {
    min-width: 150px;
  }
  .add-button :deep(button) {
    @apply w-full;
  }
</style>
