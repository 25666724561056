<template>
  <div v-show="getResource">
    <base-form-dialog
      v-if="showAddDialog"
      append-to-body
      :open="showAddDialog"
      :loading="loading"
      :title="title"
      size="xl"
      @close="onClose"
      @update:open="onClose"
    >
      <component :is="getComponent"
                 :redirect-to-list-after-store="false"
                 :show-back="false"
                 @save="onSave"
                 @cancel="onCancel"
      />
    </base-form-dialog>
  </div>
</template>
<script>
import bus from '@/event-bus/EventBus';
import { getAddResourceConfig } from '@/enum/repositories'

export default {
  name: 'AddNewResource',
  inheritAttrs: false,
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    title() {
      const title = this.get(this.getAddResourceConfig, 'actionTitle')
      return this.$t(title)
    },
    getResource() {
      return this.$store.getters['gridSelect/getResourceName']
    },
    getAddResourceConfig() {
      return getAddResourceConfig(this.getResource)
    },
    showAddDialog() {
      return this.$store.getters['gridSelect/getDialogState']
    },
  },
  methods: {
    getComponent() {
      try {
        this.loading = true
        const config = this.getAddResourceConfig
        return config.component()
      } catch (e) {
        console.log(e)
        this.$error(this.$t('Something went wrong. Please try again.'))
      } finally {
        setTimeout(() => {
          this.loading = false
        }, 200)
      }
    },
    refreshOptions() {
      const { refreshDataAction } = this.getAddResourceConfig

      if (!refreshDataAction) {
        return
      }

      this.$store.dispatch(`globalLists/${refreshDataAction}`, this.getResource)
      this.updateStoreState()
    },
    onSave(option) {
      this.refreshOptions()
      this.updateStoreState()
      bus.$emit('on-resource-added', option)
      this.$emit('save', option)
    },
    onCancel() {
      this.updateStoreState()
    },
    onClose() {
      this.updateStoreState()
    },
    updateStoreState() {
      this.$store.dispatch('gridSelect/resetOptions')
    },
  },
}
</script>
