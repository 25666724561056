<template>
  <div></div>
</template>
<script>
import axios from "axios";

export default {
  methods: {
    async deleteRow() {
      const confirmed = await this.$deleteConfirm({
        title: this.$t('Delete entry'),
        description: this.$t('Are you sure you want to delete this timesheet entry ? ')
      })
      const row = this.params.data
      if (confirmed) {
        try {
          this.params.api.applyTransaction({
            remove: [row]
          });
          if (row.id) {
            await axios.delete(`/restify/timesheet-entries/${row.id}`)
          }
          if (this.params.onConfirm) {
            this.params.onConfirm(this.params)
          }
        } catch (err) {
          this.params.api.applyTransaction({
            add: [row]
          });
        }
      } else {
        this.params?.stopEditing()
      }
    },
    getValue() {
      return this.params.value;
    }
  },
  async mounted() {
    await this.deleteRow()
  },
}
</script>
