import i18n from "@/i18n";

export const codeEdit = {
  JobOrMaster: 'code-edit-in-job-or-master',
  Job: 'code-edit-in-job',
  Transaction: 'code-edit-in-transaction',
}

export const codeEditOptions = [
  {
    label: i18n.t('Code must be setup in JOB or JOB "MASTER"'),
    value: codeEdit.JobOrMaster,
  },
  {
    label: i18n.t('Code must be setup in JOB'),
    value: codeEdit.Job,
  },
  {
    label: i18n.t('Allow new code setup in transaction entry screen'),
    value: codeEdit.Transaction,
  }
]

export const rateTypes = {
  Percent: 'rate-percentage',
  Hourly: 'rate-hourly'
}

export const rateTypeOptions = [
  {
    label: i18n.t('Percent'),
    value: rateTypes.Percent,
  },
  {
    label: i18n.t('$/Hour'),
    value: rateTypes.Hourly,
  },
]

export const burdenCalculationMethod = {
  BurdenOnly: 'calc-burden-only',
  BurdenAndEstimated: 'calc-burden-and-estimated',
  Estimated: 'calc-estimated',
}

export const burdenCalculationMethodOptions = [
  {
    label: i18n.t('Actual burden only'),
    value: burdenCalculationMethod.BurdenOnly,
  },
  {
    label: i18n.t('Actual + Estimated Burden'),
    value: burdenCalculationMethod.BurdenAndEstimated,
  },
  {
    label: i18n.t('Estimated Burden Only'),
    value: burdenCalculationMethod.Estimated,
  },
]
