<template>
  <base-form layout="vertical"
             class="subcontract-progress-form"
             :auto-height="true"
             :loading="loading"
             :save-text="$t('Create Invoices')"
             @submit="onSubmit"
  >
    <base-date-picker v-model="model.date"
                      :label="$t('Invoice Date')"
                      :placeholder="$t('Invoice Date')"
                      id="date"
                      rules="required"
                      class="col-span-6 md:col-span-2"
    />
    <ProgressInvoicesTable
      ref="table"
      class="mt-4 col-span-6"
      :data="model.date"
      :filters="filters"
    />
  </base-form>
</template>
<script lang="ts" setup>
import axios from "axios";
import ProgressInvoicesTable
  from "@/modules/accounts-payable/components/subcontract-invoices/ProgressInvoicesTable.vue";
import {ref} from "vue";
import {error} from "@/components/common/NotificationPlugin";
import i18n from "@/i18n";
import {
  DataEntry,
  SubContractInvoiceEntry
} from "@/modules/accounts-payable/components/subcontract-invoices/models/subcontractInvoiceModels";
import {useRouter} from "vue2-helpers/vue-router";
import Cache from "@/utils/Cache";
import {dateTypes, formatDate} from "@/plugins/dateFormatPlugin";

const props = defineProps({
  filters: {
    type: Object,
    required: true
  }
})

const loading = ref(false)
const model = ref({
    date: new Date(),
    override: false,
    entries: [],
  }
)

function mapEntriesToRequest(entries: DataEntry[]): SubContractInvoiceEntry[] {
  return entries.map(entry => {
    let invoice_date = entry.invoice_date
    if (invoice_date) {
      invoice_date = formatDate(invoice_date, dateTypes.IsoDate)
    }
    return {
      vendor_id: entry.vendor.id,
      purchase_order_id: entry.purchase_order?.id,
      purchase_order_entry_id: entry.purchase_order_entry?.id,
      source_id: entry.job?.id,
      addl_source_id: entry.line_item?.id,
      quantity: entry.period_percent || 0,
      gross_amount: entry.gross_amount || 0,
      retention_amount: entry.retention_amount,
      invoice: {
        number: entry.invoice_number,
        date: invoice_date,
        description: entry.invoice_description,
      }
    }
  })
}

const table = ref()

const router = useRouter()
async function onSubmit() {
  try {
    loading.value = true
    const tableEntries = table.value?.getEntries()
    const entries: SubContractInvoiceEntry[] = mapEntriesToRequest(tableEntries)
    if (entries.length === 0) {
      error(i18n.t('Please modify at least one entry.'))
      return
    }
    await axios.post(`/restify/purchase-orders/actions?action=create-subcontract-invoices`, {
      entries,
    })
    Cache.removeForEntity('invoice')
    await router.push('/accounts-payable/invoices/pending')
  } catch (err: any) {
    if (err.handled) {
      return
    }
    error(i18n.t('Something went wrong please try again.'))
  } finally {
    loading.value = false
  }
}
</script>
<style lang="scss">
</style>
