<template>
  <AgDataTable
      :columns="columns"
      :masterDetail="true"
      :detailRowAutoHeight="true"
      :add-entity-in-new-tab="true"
      :detailCellRendererParams="detailCellRendererParams"
      :detailCellRenderer="detailCellRenderer"
      :actionsColumnWidth="200"
      :add-text="$t('Add Adjustment')"
      hide-actions="view"
      url="/restify/equipment-adjustments"
      actions="add,search,refresh"
      dom-layout="autoHeight"
      permission="vendors"
      ref="table"
      @grid-ready="grid = $event"
  >
  </AgDataTable>
</template>
<script>
  import EquipmentAdjustmentEntries from "@/modules/equipment/components/equipment-init/EquipmentAdjustmentEntries.vue";

  export default {
    components: {
      EquipmentAdjustmentEntries,
    },
    data() {
      return {
        grid: null,
        detailCellRenderer: null,
      }
    },
    computed: {
      columns() {
        return [
          {
            label: this.$t('Period'),
            prop: 'attributes.period',
            cellRenderer: 'agGroupCellRenderer',
          },
          {
            label: this.$t('Fiscal Year'),
            prop: 'attributes.fiscal_year',
          },
          {
            label: this.$t('Date'),
            prop: 'attributes.transaction_date',
            component: 'FormattedDate',
          },
          {
            label: this.$t('Entries'),
            prop: 'attributes.entries_count',
          },
          {
            label: this.$t('Status'),
            prop: 'attributes.status',
            component: 'StatusLink',
            align: 'center',
          },
        ]
      },
      detailCellRendererParams() {
        return {
          parentGrid: this.grid,
          readOnly: true,
        }
      }
    },
    created() {
      this.detailCellRenderer = 'EquipmentAdjustmentEntries'
    },
  }
</script>
