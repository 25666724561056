<template>
  <div class="flex items-baseline">
    <slot>
      <slot name="start"/>
      <span :class="separatorClass">
        {{ separator }}
      </span>
      <slot name="end"/>
    </slot>
  </div>
</template>
<script>
  export default {
    props: {
      separator: {
        type: String,
        default: '-',
      },
      separatorClass: {
        type: String,
        default: 'px-1',
      },
    },
  }
</script>
