<template>
  <CompanyRoleSelect
      ref="select"
      v-focus
      v-model="selectedValue"
      label=""
      :multiple="isMultiple"
      :value-key="params?.valueKey"
      inline-errors
      class="w-full"
      @change="onChange"
  />
</template>
<script>
  import { selectEditorMixin } from "@/components/ag-grid/cellEditors/selectEditorMixin";

  export default {
    name: 'CompanyRoleCellEditor',
    mixins: [selectEditorMixin],
    computed: {
      isMultiple() {
        return Array.isArray(this.selectedValue)
      }
    }
  }
</script>
