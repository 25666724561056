<template>
  <base-data-table
      v-bind="defaultAttributes"
      :columns="columns"
      :data="getData"
      :total-rows="rowsLength"
      @force-pagination="forcePagination"
  >

    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template #thead-infos>
      <th colspan="4"/>
      <th colspan="3" align="center" class="thead-infos">
        <span class="flex w-full justify-center mr-2 border-b-2 border-dashed pb-2">
          {{ $t('Period To-Date') }}
        </span>
      </th>
      <th colspan="3" align="center" class="thead-infos">
        <span class="flex w-full justify-center mr-2 border-b-2 border-dashed pb-2">
          {{ $t('Year-To-Date') }}
        </span>
      </th>
    </template>

    <template #subtotal="{subtotal}">
      <td colspan="4" align="right">
        {{ $t('Subtotal:') }}
      </td>

      <td v-for="column in amountColumns" align="right">
        <span class="subtotal-cell">
          {{ $formatPrice(get(subtotal, column.prop, 0)) }}
        </span>
      </td>
    </template>

    <template #account.type_summary>
      <dl class="text-right">
        <dt class="mb-4"> {{ $t('Grand Total:') }}</dt>
        <dt> {{ $t('Total Income:') }}</dt>
        <dt> {{ $t('Total Expense:') }}</dt>
        <dt class="mt-4"> {{ $t('Net Income:') }}</dt>
      </dl>
    </template>

    <template #html-row>
      <tr>
        <span class="py-3">&nbsp;</span>
      </tr>
    </template>

    <template v-for="column in amountColumns" #[getSummarySlotName(column)]>
      <dl>
        <dt class="mb-4">{{ $formatPrice(grandTotals.grand_total?.[column.prop] || 0) }}</dt>
        <dt>{{ $formatPrice(grandTotals.total_income?.[column.prop] || 0) }}</dt>
        <dt>{{ $formatPrice(grandTotals.total_expense?.[column.prop] || 0) }}</dt>
        <dt>
          <span class="subtotal-cell mt-4">
            {{ $formatPrice(grandTotals.net_income?.[column.prop] || 0) }}
          </span>
        </dt>
      </dl>
    </template>

  </base-data-table>
</template>
<script>
  import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'

  export default {
    extends: ReportTableWrapper,
    props: {
      subtotalOn: {
        required: true,
        type: Number,
        default: 0,
      },
    },
    computed: {
      grandTotals() {
        return {
          grand_total: this.data.grand_total,
          total_income: this.data.total_income,
          total_expense: this.data.total_expense,
          net_income: this.data.net_income,
        }
      },
      columns() {
        return [
          {
            label: this.$t('Account'),
            prop: 'account.number',
            minWidth: 60,
            maxWidth: 80,
            component: 'EntityLink',
            redirectTo: '/ledger/accounts/{ID}/view',
          },
          {
            label: this.$t('Sub <br> Account'),
            prop: 'subaccount.number',
            minWidth: 50,
            maxWidth: 70,
            component: 'EntityLink',
            redirectTo: '/ledger/sub-accounts/{ID}/view',
          },
          {
            label: this.$t('Description'),
            prop: 'account.description',
            minWidth: 100,
            maxWidth: 280,
          },
          {
            label: this.$t('Type'),
            prop: 'account.type',
            maxWidth: 100,
            component: 'Status',
            align: 'center',
          },
          {
            label: this.$t('This Year'),
            prop: 'period.this',
            maxWidth: 160,
            minWidth: 65,
            component: 'FormattedPrice',
            align: 'right',
          },
          {
            label: this.$t('Last Year'),
            prop: 'period.last',
            maxWidth: 160,
            minWidth: 65,
            component: 'FormattedPrice',
            align: 'right',
          },
          {
            label: this.$t('Prior Year'),
            prop: 'period.prior',
            maxWidth: 160,
            minWidth: 65,
            component: 'FormattedPrice',
            align: 'right',
          },
          {
            label: this.$t('This Year'),
            prop: 'up_to_period.this',
            maxWidth: 160,
            minWidth: 65,
            component: 'FormattedPrice',
            align: 'right',
          },
          {
            label: this.$t('Last Year'),
            prop: 'up_to_period.last',
            maxWidth: 160,
            minWidth: 65,
            component: 'FormattedPrice',
            align: 'right',
          },
          {
            label: this.$t('Prior Year'),
            prop: 'up_to_period.prior',
            maxWidth: 160,
            minWidth: 65,
            component: 'FormattedPrice',
            align: 'right',
          },
        ]
      },
    },
    methods: {
      composeRows(data) {
        if (!this.subtotalOn) {
          this.rows = this.get(data, '[0].accounts', [])
        } else {

          data.forEach((item, index) => {

            const { accounts, period, up_to_period } = item

            this.rows.push(...accounts, {
              subtotal: {
                period,
                up_to_period,
              }
            })
          })
        }

        this.rows.push({
          htmlData: {},
        })

        return this.rows
      },
    },
  }
</script>
