<template>
  <div class="mt-10 sm:mt-0">
    <portal to="page-title">
      {{ $t('App Template - ') }} {{ model.title }}
    </portal>
    <base-form
        :loading="loading"
        :showBack="showBack"
        :save-text="$t('Update app template')"
        :focusOnFirstInput="false"
        layout="vertical"
        @cancel="$emit('cancel')"
        @submit="onSubmit"
    >
      <div class="col-span-6">
        <base-input v-model="model.title"
                    :label="$t('Title')"
                    :placeholder="$t('Template title')"
                    id="title"
                    rules="required">
        </base-input>
      </div>
      <div class="col-span-6">
        <base-input :label="$t('Content')"
                    rules="required">
          <template v-slot:container>
            <html-editor v-model="model.html"
                         :variables="placeholders"
                         :placeholder="$t('Content of the email')"
                         id="html">
            </html-editor>
          </template>
        </base-input>
      </div>
    </base-form>
  </div>
</template>
<script>
  import axios from 'axios'
  import { convertToRedactorHtml, parseRedactorHtml } from "@/modules/settings/pages/emailUtils";

  export default {
    data() {
      return {
        loading: false,
        showBack: true,
        model: {
          title: '',
          html: '',
        },
        placeholders: []
      }
    },
    computed: {
      id() {
        return this.$route.params.id
      }
    },
    methods: {
      async onSubmit() {
        try {
          this.loading = true
          const data = {
            ...this.model,
          }
          data.html = parseRedactorHtml(data.html)
          await axios.put(`/restify/app-templates/${this.id}`, data);
          this.$success(this.$t('Email template updated'))
          this.$emit('save', this.model)
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not update the app template'))
        } finally {
          this.loading = false
        }
      },
      async getAppTemplate() {
        const { data } = await axios.get(`/restify/app-templates/${this.id}`)
        this.model = data.attributes
        this.model.html = convertToRedactorHtml(this.model.html)
        const placeholders = await axios.get(`/restify/app-templates/${this.id}/placeholders`)
        this.placeholders = Object.keys(placeholders.data)
      },
    },
    created() {
      this.getAppTemplate()
    }
  }
</script>
