<template>
  <entity-select
      ref="select"
      v-on="$listeners"
      v-bind="$attrs"
      :data="filteredData"
      :allow-create="true"
      :clearable="clearable"
      :add-entity="addEntity"
      :name="$attrs.name || $t('Phase Code')"
      :placeholder="$attrs.placeholder || $t('Phase Code')"
      :fetch-on-mount="fetchOnMount"
      :add-label="$t('Add new Line Item')"
      :id="$attrs.id || 'phase-select'"
      :table-columns="phaseCostTableColumns"
      :has-filter-method="true"
  >

    <template v-if="authorizedToUseMasterLineItems"
              #custom-filter>
      <div class="flex items-center cursor-pointer justify-center">
        <base-checkbox
            v-model="showMasterLineItems"
            :label="$t('Show MASTER Line Items')"
            id="showMasterLineItems"
        />
      </div>
    </template>

  </entity-select>
</template>
<script>
  import sortBy from 'lodash/sortBy'

  export default {
    inheritAttrs: false,
    props: {
      urlParams: {
        type: Object,
        default: () => ({}),
      },
      clearable: {
        type: Boolean,
        default: true,
      },
      addEntity: {
        type: Boolean,
        default: true,
      },
      fetchOnMount: {
        type: Boolean,
        default: true,
      },
      authorizedToUseMasterLineItems: {
        type: Boolean,
        default: true,
      },
      lineItemType: String,
      jobId: String,
    },
    data() {
      return {
        showMasterLineItems: false,
        phaseCostTableColumns: [
          {
            minWidth: 70,
            maxWidth: 70,
            name: this.$t('Phase Code'),
            prop: 'attributes.phase_code',
          },
          {
            minWidth: 80,
            maxWidth: 80,
            name: this.$t('Cost Code'),
            prop: 'attributes.cost_code',
          },
          {
            minWidth: 70,
            maxWidth: 70,
            name: this.$t('Change Order'),
            prop: 'attributes.change_order',
          },
          {
            class: 'w-full',
            name: this.$t('Description'),
            prop: 'attributes.description',
          },
          {
            minWidth: 105,
            maxWidth: 105,
            name: this.$t('Budget'),
            prop: 'attributes.amount',
            toFormat: this.$formatPrice,
          },
          {
            minWidth: 105,
            maxWidth: 105,
            name: this.$t('Actual'),
            prop: 'attributes.amount_to_date',
            toFormat: this.$formatPrice,
          },
        ],
      }
    },
    computed: {
      filteredData() {
        let jobId = this.jobId || this.masterJobId
        if (this.showMasterLineItems) {
          jobId = this.masterJobId
        }
        let resourceType = this.$globalResources.LineItems
        if (jobId === this.masterJobId) {
          resourceType = this.$globalResources.MasterLineItems
        }
        let lineItems = this.$store.getters['globalLists/getResourceList'](resourceType)
        lineItems = lineItems
            .filter((lineItem) => {
              let include = lineItem.type === this.lineItemType
              const { phase_code, cost_code, change_order } = this.urlParams
              if (phase_code || phase_code === 0) {
                include = include && lineItem.phase_code === phase_code
              }
              if (cost_code || cost_code === 0) {
                include = include && lineItem.cost_code === cost_code
              }
              if (change_order || change_order === 0) {
                include = include && lineItem.change_order === change_order
              }
              return include
            })

        if (jobId !== this.masterJobId) {
          lineItems = lineItems.filter((lineItem) => lineItem.job_id === jobId)
        }

        return lineItems.map((lineItem) => {
          return {
            id: lineItem.id,
            attributes: {
              ...lineItem,
            },
          }
        })
      },
      masterJobId() {
        return this.$settings(this.$modules.JC, 'master_job_id')
      },
    },
    methods: {
      emitLastQuery() {
        this.$refs.select.emitLastQuery()
      },
    }
  }
</script>
