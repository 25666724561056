<template>
  <div class="import-data mt-4">
    <ImportForm v-bind="importSettings"
                @success="redirectToList"
    />
  </div>
</template>
<script>
  import ImportForm from "@/modules/common/components/import/ImportForm";
  import { getInitialMapping } from "@/modules/payroll/utils/timecardImportColumns";

  const colMappings = {
    Code: {
      value: 'code',
      label: 'Code',
      alternativeLabels: ['Customer Code'],
    },
    Name: {
      value: 'name',
      label: 'Name',
      alternativeLabels: ['Customer Name'],
      required: true,
    },
    Email: {
      value: 'email',
      label: 'Email',
      alternativeLabels: [],
    },
    Phone: {
      value: 'phone',
      label: 'Phone',
      alternativeLabels: ['TELEPHONE'],
    },
    Fax: {
      value: 'fax',
      label: 'Fax',
      alternativeLabels: ['Fax Number'],
    },
    Contact: {
      value: 'contact',
      label: 'Contact',
      alternativeLabels: ['Contact Name'],
    },
    CurrentARAmount: {
      value: 'current_ar_amount',
      label: 'Current AR Amount',
      alternativeLabels: ['AR Amount', 'Open Amount', 'Current Receivable'],
    },
    SalesThisYear: {
      value: 'sales_this_year_amount',
      label: 'Sales This Year',
      alternativeLabels: [],
    },
    SalesLastYear: {
      value: 'sales_last_year_amount',
      label: 'Sales Last Year',
      alternativeLabels: [],
    },
    SalesTax: {
      value: 'sales_tax_percent',
      label: 'Sales Tax',
      alternativeLabels: ['Sales Tax %'],
    },
    Status: {
      value: 'status',
      label: 'Status',
      alternativeLabels: [],
    },
    Terms: {
      value: 'terms',
      label: 'Terms',
      alternativeLabels: ['Terms Message'],
    },
    UnAppliedCashOnDeposit: {
      value: 'current_unapplied_cash_on_deposit_amount',
      label: 'Cash on Deposit',
      alternativeLabels: [],
    },
    Address1: {
      value: 'address_1',
      label: 'Address 1',
      alternativeLabels: ['Customer Address 1', 'Address'],
    },
    Address2: {
      value: 'address_2',
      label: 'Address 2',
      alternativeLabels: ['Customer Address 2'],
    },
    FullAddress: {
      value: 'address',
      label: 'Full Address',
      alternativeLabels: [],
    },
    City: {
      value: 'city',
      label: 'City',
      alternativeLabels: ['Customer City'],
      required: mapping => {
        return mapping.address_1 !== -1;
      }
    },
    State: {
      value: 'state',
      label: 'State',
      alternativeLabels: ['Customer State'],
      required: mapping => {
        return mapping.address_1 !== -1;
      }
    },
    PostalCode: {
      value: 'postal_code',
      label: 'Zip Code',
      alternativeLabels: ['Zip', 'Postal Code', 'Customer Zip'],
      required: mapping => {
        return mapping.address_1 !== -1;
      }
    },
    Account: {
      value: 'account',
      label: 'Account',
      alternativeLabels: ['Account No.', 'Account Number', 'GL Account'],
    },
    SubAccount: {
      value: 'subaccount',
      label: 'Sub Account',
      alternativeLabels: ['Sub Account No.', 'Sub Account Number', 'GL Sub Acct'],
    },
    DistrictCode: {
      value: 'district_code',
      label: 'District Code',
      alternativeLabels: ['Tax District'],
    },
    DistrictState: {
      value: 'district_state',
      label: 'District State',
      alternativeLabels: ['Tax State'],
      required: mapping => {
        return mapping.district_code !== -1
      }
    },
    LateChargeExempt: {
      value: 'exempt_from_late_charges',
      label: 'Late Charge Exempt',
      alternativeLabels: [],
    },
  }

  const options = [
    colMappings.Code,
    colMappings.Name,
    colMappings.Email,
    colMappings.Phone,
    colMappings.Fax,
    colMappings.Contact,
    colMappings.Account,
    colMappings.SubAccount,
    colMappings.FullAddress,
    colMappings.Address1,
    colMappings.Address2,
    colMappings.City,
    colMappings.State,
    colMappings.PostalCode,
    colMappings.DistrictCode,
    colMappings.DistrictState,
    colMappings.SalesTax,
    colMappings.SalesLastYear,
    colMappings.SalesThisYear,
    colMappings.CurrentARAmount,
    colMappings.UnAppliedCashOnDeposit,
    colMappings.Terms,
    colMappings.Status,
    colMappings.LateChargeExempt,
  ]

  export default {
    components: {
      ImportForm,
    },
    data() {
      return {
        importSettings: {
          url: '/restify/customers/import',
          columnOptions: options,
          columnMappings: colMappings,
          initialMapping: getInitialMapping(options),
          type: 'customers-import',
          resource: 'customers',
        },
      }
    },
    methods: {
      async redirectToList() {
        await this.$router.push('/accounts-receivable/customers')
        await this.$store.dispatch('globalLists/getCustomers')
      },
    }
  }
</script>
