<template>
  <div>
    <BaseButton variant="white" @click="showDialog = true">
      <DownloadIcon class="w-4 h-4 text-green-500 mr-2"/>
      <span>{{ $t('Download Template') }}</span>
    </BaseButton>
    <BaseFormDialog
      v-if="showDialog"
      :open.sync="showDialog"
      :title="$t('Download Template')"
      :append-to-body="true"
    >
      <base-form
        :save-text="$t('Download')"
        :loading="loading"
        :focus-on-first-input="false"
        layout="vertical"
        @cancel="$emit('close')"
        @submit="onSubmit"
      >
        <div class="col-span-6">
          <base-select v-model="model.format"
                       :label="$t('Template Format')"
                       :placeholder="$t('Template Format')"
                       :options="formatOptions"
                       id="format">
          </base-select>
        </div>
      </base-form>
    </BaseFormDialog>
  </div>
</template>
<script>
import { DownloadIcon } from "vue-feather-icons";
import axios from "axios";
import { downloadFileLocally } from "@/modules/common/util/downloadFileLocally";

const formatTypes = {
  Csv: 'csv',
  Excel: 'xlsx',
}
export default {
  components: {
    DownloadIcon,
  },
  props: {
    importUrl: {
      type: String,
    },
    name: {
      type: String,
    }
  },
  data() {
    return {
      showDialog: false,
      loading: false,
      model: {
        format: formatTypes.Csv,
      },
      formatOptions: [
        { value: formatTypes.Csv, label: this.$t('CSV') },
        { value: formatTypes.Excel, label: this.$t('Excel') },
      ]
    }
  },
  methods: {
    async onSubmit() {
      if (!this.importUrl) {
        return
      }
      try {
        this.loading = true
        const response = await axios.post(`${this.importUrl}/template`, this.model, {
          responseType: 'blob',
        })

        const fileName = `${this.name}_template.${this.model.format}`
        downloadFileLocally(response, fileName)

        this.showDialog = false
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(this.$t('Could not download the template'))
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
