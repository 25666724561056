<template>
  <div :class="wrapperClasses">
    <div :class="gridClasses">
      <div v-for="field in fieldConfigs"
           :class="field.fieldClasses || fieldClasses"
           :key="field.label"
      >
        <dt class="text-sm font-medium text-gray-500"
            :class="field.labelClass">
          {{ $t(field.label) }}
        </dt>
        <dd class="mt-1 text-sm text-gray-900 truncate"
            :class="field.valueClass"
        >
          <template v-if="field.type === 'price'">
            {{ $formatPrice(entity[field.valueKey]) }}
          </template>
          <template v-if="field.type === 'percent'">
            %{{ entity[field.valueKey] }}
          </template>
          <template v-if="field.type === 'date'">
            {{ $formatDate(entity[field.valueKey]) }}
          </template>
          <template v-if="field.type === 'email'">
            <a :href="`mailto:${entity[field.valueKey]}`">
              {{ entity[field.valueKey] || '' }}
            </a>
          </template>
          <template v-if="field.type === 'phone'">
            <a :href="`tel:${entity[field.valueKey]}`">
              {{ entity[field.valueKey] || '' }}
            </a>
          </template>
          <template v-if="field.type === 'fax'">
            <a :href="`fax:${entity[field.valueKey]}`">
              {{ entity[field.valueKey] || '' }}
            </a>
          </template>
          <template v-if="field.type === 'boolean'">
            <status-badge :status="entity[field.valueKey] ? $t('Yes') : $t('No')"/>
          </template>
          <template v-if="field.isRelatedEntry">
            {{ get(entity, `${field.valueKey}`, '') }}
          </template>
          <template v-else-if="!field.type">
            <span :class="field.localStyles">
              {{ entity[field.valueKey] || '' }}
            </span>
          </template>
        </dd>
      </div>
    </div>
    <slot name="dynamic-fields"/>
    <slot name="additional-details"
          :entity="entity"/>
    <slot name="address-list">
      <address-list v-if="entity.addresses && entity.addresses.length"
                    :data="entity.addresses"
                    class="mt-4"
                    :entity-id="entity.id"
                    :title="addressListTitle"
                    :show-pagination="showPagination"
                    :disabled="true"
      />
    </slot>
    <slot name="contact-list">
      <contact-list v-if="entity.contacts && entity.contacts.length"
                    :contacts="entity.contacts"
                    class="mt-4"
                    title="Contacts"
                    :entity-id="entity.id"
                    :show-pagination="showPagination"
                    :disabled="true"
      />
    </slot>
  </div>
</template>
<script>
  import axios from 'axios'
  import AddressList from '@/modules/common/components/AddressList'
  import ContactList from '@/modules/common/components/contacts/ContactList.vue'

  export default {
    components: {
      ContactList,
      AddressList,
    },
    props: {
      id: [String, Number],
      showPagination: {
        type: Boolean,
        default: false,
      },
      fieldConfigs: {
        type: Array,
        default: () => [],
      },
      url: {
        type: String,
        default: '',
      },
      urlQuery: {
        type: String,
        default: '',
      },
      wrapperClasses: {
        type: String,
        default: 'shadow overflow-hidden sm:rounded-md form__inner bg-white px-4 py-5 bg-white sm:p-6',
      },
      gridClasses: {
        type: String,
        default: 'grid grid-cols-8 gap-x-4',
      },
      fieldClasses: {
        type: String,
        default: 'col-span-8 md:col-span-4 lg:col-span-2 py-2 border-b',
      },
      addressListTitle: {
        type: String,
        default: 'Addresses',
      },
    },
    data() {
      return {
        entity: {},
      }
    },
    methods: {
      async getEntity() {
        const { data } = await axios.get(`${this.url}/${this.id || this.$route.params.id}${this.urlQuery}`)
        this.entity = {
          ...data.attributes,
          ...data.relationships,
        }
      },
    },
    async mounted() {
      if (!this.$route.params.id && !this.id) {
        return
      }
      await this.getEntity()
    },
  }
</script>
