<template>
  <VendorDirectDeposit/>
</template>
<script>
  import VendorDirectDeposit from '@/modules/accounts-payable/components/VendorDirectDeposit'

  export default {
    components: {
      VendorDirectDeposit,
    },
  }
</script>
