<template>
  <div>
    <validation-provider v-bind="$attrs"
                         :name="$attrs.name || label"
                         v-slot="{ errors }">
      <div v-if="!$attrs['hide-label']"
           class="flex items-center justify-between"
           :class="{'h-5':  ($attrs.label || $slots.label )}"
      >
        <base-tooltip :content="label"
                      :tabindex="-1"
                      :key="$attrs?.id"
                      :open-delay="500"
                      placement="top"
        >
          <label
            v-if="label || $slots.label"
            :for="$attrs?.id"
            class="block form-label"
          >
            <slot name="label">
              <div class="flex items-center">
                <span v-html="label" :class="labelClass"></span>
                <span v-if="isRequired"
                      class="text-gray-500">
                  *
                </span>
                <base-tooltip v-if="tip"
                              :tabindex="-1"
                              :content="tip">
                  <HelpCircleIcon class="ml-2 w-4 h-4 text-gray-500 hover:text-gray-700 cursor-help"/>
                </base-tooltip>
              </div>
            </slot>
          </label>
        </base-tooltip>
        <div v-if="editEntity"
             class="flex items-center">
          <base-tooltip :content="$t('See Details')" :tabindex="-1">
            <span v-if="hasEntityViewComponent && editEntity"
                  @click="tryOpenViewDialog"
                  class="flex rounded text-gray-600 mr-2"
                  :class="{
                    'cursor-not-allowed opacity-50': !canOpenEditDialog,
                    'cursor-pointer hover:text-gray-700': canOpenEditDialog,
                }"
            >
            <EyeIcon class="w-4 h-4"/>
          </span>
          </base-tooltip>
          <base-tooltip :content="$t('Edit')" :tabindex="-1">
            <span v-if="editEntity"
                  @click="tryOpenEditDialog"
                  class="flex rounded text-primary-500"
                  :class="{
                    'cursor-not-allowed opacity-50': !canOpenEditDialog,
                    'cursor-pointer hover:text-primary-600': canOpenEditDialog,
                }"
            >
            <Edit2Icon class="w-4 h-4"/>
          </span>
          </base-tooltip>
        </div>
      </div>
      <label v-if="labelDictionary || $slots.labelDictionary"
             :for="$attrs?.id"
             class="form-label"
      >
        <slot name="labelDictionary">
          {{ labelDictionary }}
        </slot>
      </label>
      <base-input-error :errors="errors"
                        :show-tooltip="inlineErrors">
        <el-select
          v-bind="$attrs"
          ref="entitySelect"
          :value="value"
          :allow-create="canCreate"
          v-on="listeners"
          :disabled="$attrs.disabled || $attrs.readonly"
          :multiple="multiple"
          class="entity-select w-full"
          :class="{ 'select-error': errors.length, 'has-table': tableColumns.length }"
          :popper-class="tableColumns.length ? 'has-table' : ''"
          :clearable="clearable"
          :remote="remote"
          :remote-method="remote ? remoteMethod : undefined"
          :collapse-tags="collapseTags"
          :filter-method="hasFilterMethod ? filterMethod : undefined"
          :loading="loading"
          filterable
          default-first-option
        >
          <slot :loading="loading" :options="options"/>

          <el-option v-if="addEntity"
                     id="addNewEntityButton"
                     :value="-1">
            <div class="flex items-center cursor-pointer justify-center"
                 :style="minWidthStyles"
                 @click="showAddEntityDialog = true">
              <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px"
                   viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3"
                   stroke-linecap="round" stroke-linejoin="round"
                   class="w-5 h-5 text-primary-900 feather feather-plus">
                <line x1="12" y1="5" x2="12" y2="19"/>
                <line x1="5" y1="12" x2="19" y2="12"/>
              </svg>
              <span class="mx-1 font-medium text-primary-900">
                {{ addLabel ? $t(addLabel) : $t('Add New Entity') }}
              </span>
            </div>
          </el-option>
          <el-option v-if="multiple && options.length" :label="$t(`Select All`)" :disabled="allValuesSelected"
                     :value="'all'"/>
          <el-option v-if="multiple && options.length" :label="$t(`Clear All`)" :disabled="noValueSelected"
                     :value="'none'"/>
          <template v-if="tableColumns.length">
            <div
              class="options-header flex items-center absolute top-0 px-5 py-2 w-full z-10 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider overflow-visible"
            >
              <span
                v-for="column in tableColumns"
                :key="column.name"
                :style="{minWidth: `${column.minWidth}px`, maxWidth: `${column.maxWidth}px`}"
                class="pr-2"
                :class="{
                  [column.class]: column.class,
                  'overflow-visible': column.filter,
                  'truncate': !column.filter
                }"
              >
                <div class="flex items-center relative">
                  {{ $t(column.name) }}
                  <BaseTooltip
                    v-if="column.filter"
                    :disabled="!column.filter.tooltip"
                    :content="column.filter.tooltip"
                  >
                    <BaseSwitch
                      v-if="column.filter.type === 'switch'"
                      :value="dataFilters[column.filter.prop]"
                      inline
                      showYesNoLabel
                      class="scale-75 absolute right-0"
                      @change="column.filter.onChange"
                    />
                  </BaseTooltip>
                </div>
              </span>
              <RefreshResourceButton :get-data="forceFetchData"/>
            </div>

            <el-option v-if="$slots['custom-filter']"
                       key="custom-filter"
                       value="custom-filter"
                       disabled
            >
              <slot name="custom-filter"/>
            </el-option>

            <el-option
              v-for="(option, index) in options"
              :key="getValue(option) || index"
              :value="getValue(option)"
              :label="getLabel(option)"
              :class="{
                'opacity-70': option.isInactive
              }"
            >
              <template v-slot:default>
                <slot :row="option">
                  <div class="flex"
                       @mousedown="onOptionClick(option)"
                  >
                      <span v-for="(column, index) in tableColumns"
                            :key="index"
                            :style="{minWidth: `${column.minWidth}px`, maxWidth: `${column.maxWidth}px`}"
                            class="truncate pr-2 flex items-center"
                            :class="column.class ? column.class : ''"
                      >
                      <template v-if="column.component">
                        <component :is="column.component"
                                   :column="column"
                                   :row="option"
                        />
                      </template>
                      <template v-else>
                        <template v-if="column.toFormat">
                          {{ column.toFormat(get(option, column.prop)) }}
                        </template>
                        <template v-else>
                          <span class="truncate">
                            {{ get(option, column.prop) }}
                          </span>
                        </template>
                      </template>
                    </span>
                  </div>
                </slot>
              </template>
            </el-option>
          </template>
          <template v-else>
            <el-option v-for="option in options"
                       :value="getValue(option)"
                       :key="getValue(option)"
                       :label="getLabel(option)"
                       @mousedown.native="onOptionClick(option)"
            />
          </template>
          <div class="flex justify-center items-center pt-1"
               v-if="lastPage > page && options.length > 0"
          >
            <base-button variant="primary-link"
                         :loading="loading"
                         @click="loadMore">
              {{ $t('View More') }}
            </base-button>
          </div>
        </el-select>
      </base-input-error>
    </validation-provider>
    <BaseFormDialog v-if="showAddEntityDialog"
                    :title="$t(entityName)"
                    :loading="loading"
                    :append-to-body="true"
                    :open.sync="showAddEntityDialog"
                    size="2xl"
    >
      <component :is="getComponent"
                 :redirect-to-list-after-store="false"
                 :show-back="false"
                 :add-temporary-entity="addTemporaryEntity"
                 :parent-entity-id="parentEntityId"
                 :parent-entity-name="parentEntityName"
                 v-bind="formParams"
                 @save="onSave"
                 @cancel="onCancel"
      />
    </BaseFormDialog>

    <BaseFormDialog v-if="showEditEntityDialog"
                    :title="`${initialEntityName} ${entityTitle}`"
                    :loading="loading"
                    :append-to-body="true"
                    :open.sync="showEditEntityDialog"
                    size="2xl"
    >
      <component :is="getComponent"
                 :redirect-to-list-after-store="false"
                 :show-back="false"
                 :data="selectedEntity"
                 @save="onEditSave"
                 @cancel="onCancel"
      />
    </BaseFormDialog>

    <BaseFormDialog v-if="showPreviewEntityDialog && selectedEntity"
                    :title="`${viewEntityName} ${entityTitle}`"
                    :loading="loading"
                    :append-to-body="true"
                    :open.sync="showPreviewEntityDialog"
                    :has-padding="false"
                    size="2xl"
    >
      <div class="p-4">
        <component :is="getViewComponent"
                   :id="selectedEntity?.id"/>
      </div>
    </BaseFormDialog>
  </div>
</template>
<script>
  import get from 'lodash/get'
  import Cache from '@/utils/Cache'
  import { Option, Select } from 'element-ui'
  import { delay, isReferenceId, isUUID } from '@/utils/utils'
  import Status from '@/components/table/cells/Status'
  import FormattedDate from '@/components/table/cells/FormattedDate'
  import FormattedPrice from '@/components/table/cells/FormattedPrice'
  import FormattedPercent from '@/components/table/cells/FormattedPercent'
  import { Edit2Icon, EyeIcon, HelpCircleIcon } from 'vue-feather-icons'
  import { getEntityComponentData, getEntityViewComponent } from '@/enum/entities'
  import { scrollToSelectedValue } from '@/components/form/selectSelectionUtils';
  import uniqBy from "lodash/uniqBy";
  import RefreshResourceButton from "@/components/select/RefreshResourceButton.vue";
  import sumBy from "lodash/sumBy";

  export default {
    name: 'EntitySelect',
    components: {
      RefreshResourceButton,
      Status,
      EyeIcon,
      Edit2Icon,
      FormattedDate,
      HelpCircleIcon,
      FormattedPrice,
      FormattedPercent,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    props: {
      collapseTags: {
        type: Boolean,
        default: false,
      },
      value: {
        type: [String, Number, Object, Array],
      },
      idValue: {
        type: [String, Number, Object, Array],
      },
      initialValue: {
        type: [Object, Array],
      },
      extraOptions: {
        type: Array,
      },
      label: String,
      labelClass: {
        type: [String, Object, Array],
        default: ''
      },
      tip: {
        type: String,
        default: '',
      },
      labelDictionary: String,
      addLabel: String,
      url: {
        type: String,
      },
      entityUrl: {
        type: String,
      },
      urlParams: {
        type: Object,
        default: () => ({}),
      },
      labelKey: {
        type: String,
        default: 'name',
      },
      valueKey: {
        type: String,
        default: 'id',
      },
      labelFormat: {
        type: Function,
      },
      data: Array,
      inlineErrors: {
        type: Boolean,
        default: false,
      },
      tableColumns: {
        type: Array,
        default: () => [],
      },
      multiple: {
        type: Boolean,
        default: false,
      },
      clearable: {
        type: Boolean,
        default: false,
      },
      addEntity: {
        type: Boolean,
        default: true,
      },
      addTemporaryEntity: {
        type: Boolean,
        default: false,
      },
      editEntity: {
        type: Boolean,
        default: false,
      },
      filterOptions: {
        type: Function,
      },
      remoteSearch: Boolean,
      fetchOnMount: {
        type: Boolean,
        default: true,
      },
      onMapEntry: [Function, Object],
      allowCreate: {
        type: Boolean,
        default: false,
      },
      allowCreateNoOptionsOnly: {
        type: Boolean,
        default: false,
      },
      selectFirstOption: {
        type: Boolean,
        default: false,
      },
      hasFilterMethod: {
        type: Boolean,
        default: false,
      },
      setDefaultOption: Boolean,
      parentEntityId: String,
      parentEntityName: String,
      formParams: {
        type: Object,
        default: () => ({}),
      },
      transformData: Function,
      dataFilters: {
        type: Object,
        default: () => ({}),
      },
    },
    model: {
      prop: 'value',
      event: 'change',
    },
    data() {
      let options = []
      if (this.initialValue?.length || this.initialValue?.id) {
        options = Array.isArray(this.initialValue) ? [...this.initialValue] : [this.initialValue]
      }

      return {
        showAddEntityDialog: false,
        showEditEntityDialog: false,
        showPreviewEntityDialog: false,
        entityName: '',
        viewEntityName: '',
        options,
        loading: false,
        page: 1,
        perPage: 50,
        lastPage: 1,
        lastQuery: null,
        selectedEntity: null,
        fetchedData: [],
      }
    },
    computed: {
      canCreate() {
        if (this.allowCreateNoOptionsOnly) {
          return this.options.length === 0
        }
        return this.allowCreate
      },
      hasMatchingValue() {
        if (!this.value) {
          return true
        }
        return this.findFullValue(this.value) !== undefined
      },
      isRequired() {
        const rules = this.$attrs.rules
        if (!rules) {
          return false
        }
        return rules?.includes && rules?.includes('required') || rules?.required
      },
      entityTitle() {
        return this.get(this.selectedEntity, 'name', '') || this.get(this.selectedEntity, 'number', '')
      },
      initialEntityName() {
        return this.entityName.replace('Add', '')
      },
      remote() {
        return this.url && (this.remoteSearch || this.tableColumns.length !== 0)
      },
      listeners() {
        return {
          ...this.$listeners,
          change: this.onChange,
          // we already handle this via change event
          input: () => {
          },
          blur: this.onBlur,
          focus: this.onFocus,
        }
      },
      allValuesSelected() {
        return this.value?.length === this.options.length
      },
      noValueSelected() {
        return !this.value
      },
      canOpenEditDialog() {
        return this.selectedEntity && !Array.isArray(this.selectedEntity) && !this.multiple
      },
      hasEntityViewComponent() {
        return getEntityViewComponent(this.url) !== undefined
      },
      minColumnsWidth() {
        const offset = 20
        return sumBy(this.tableColumns, 'minWidth') + offset
      },
      minWidthStyles() {
        return {
          minWidth: `${this.minColumnsWidth}px`,
        }
      },
    },
    methods: {
      get,
      onOptionClick(option) {
        // TODO this breaks the multiple select but is needed for the selects inside data grid. Find a proper fix for this...
        if (this.multiple) {
          return
        }

        const value = option[this.valueKey]
        this.onChange(value)
      },
      async remoteMethod(query) {
        await this.getData(query)

        this.$nextTick(() => {
          this.triggerOptionSelect(false)
        })
      },
      filterMethod(query) {
        if (!query) {
          query = isUUID(this.value) ? '' : this.value
        }
        this.lastQuery = query
        if (!query) {
          return
        }
        query = query.toString().toLowerCase()

        this.options = this.data.filter(option => {
          const optionAttrs = option.attributes || option
          const keys = [this.labelKey]
          return keys.some(key => optionAttrs[key] && optionAttrs[key].toString().toLowerCase().includes(query))
        })
      },
      loadMore() {
        this.page++
        this.getData(this.lastQuery)
      },
      tryOpenEditDialog() {
        if (!this.selectedEntity) {
          return
        }
        this.showEditEntityDialog = true
      },
      tryOpenViewDialog() {
        if (!this.selectedEntity) {
          return
        }
        this.showPreviewEntityDialog = true
      },
      getValue(option) {
        return get(option, `attributes.${this.valueKey}`) || get(option, this.valueKey)
      },
      getLabel(option) {
        if (this.labelFormat && option) {
          return this.labelFormat(option)
        }
        return get(option, `attributes.${this.labelKey}`) || get(option, this.labelKey)
      },
      async getViewComponent() {
        const entityConfig = getEntityViewComponent(this.url)

        if (!entityConfig) {
          return
        }
        try {
          this.viewEntityName = entityConfig.name
          this.loading = true
          return entityConfig.component
        } catch (e) {
          console.log(e)
          this.showPreviewEntityDialog = false
          this.$error(this.$t('Something went wrong. Please try again.'))
        } finally {
          setTimeout(() => {
            this.loading = false
          }, 200)
        }
      },
      async getComponent() {
        const entityConfig = getEntityComponentData(this.url || this.entityUrl)

        if (!entityConfig) {
          return
        }

        this.entityName = entityConfig?.name || 'New Entity'

        try {
          this.loading = true
          return entityConfig.component
        } catch (e) {
          console.log(e)
          this.showAddEntityDialog = false
          this.$error(this.$t('Something went wrong. Please try again.'))
        } finally {
          setTimeout(() => {
            this.loading = false
          }, 200)
        }
      },
      async onSave(option, forceFetchAll = false) {
        if (!this.addTemporaryEntity) {
          await this.getData(option?.id, true)
          if (forceFetchAll) {
            await this.getData(null, true)
          }
        } else {
          this.options.splice(0, 0, option)
        }
        if (option) {
          this.onChange(get(option, this.valueKey))
          await delay(300)
          this.$emit('entity-change', option)
          this.focus()
        }
        this.showAddEntityDialog = false
      },
      async onEditSave(data) {
        this.processSelectedValue(data)
        this.showEditEntityDialog = false
        await this.getData(null, true)
        this.emitChange(data.attributes[this.valueKey])
        this.$emit('entity-change', data)
        this.focus()
        await this.$nextTick()
        this.$refs.entitySelect.blur()
      },
      onCancel() {
        this.showAddEntityDialog = this.showEditEntityDialog = false
      },
      onBlur(evt) {
        this.$emit('blur', evt)
      },
      onFocus(evt) {
        if (this.tableColumns.length) {
          this.getData()
        }
        this.$emit('focus', evt)
      },
      async forceFetchData() {
        await this.getData('', true)
      },
      focus() {
        this.$refs.entitySelect.focus()
      },
      getQueryParams(query) {
        if (query !== this.lastQuery) {
          this.page = 1
        }
        const params = {
          page: this.page,
          perPage: this.perPage,
          ...this.urlParams,
        }
        if (query && typeof query === 'string') {
          params.search = query
        }
        return params
      },
      async getData(query, invalidateCache = false) {
        if (!this.url && this.data) {
          let options = this.data
          if (this.initialValue) {
            options.push(this.initialValue)
            options = uniqBy(options, this.valueKey)
          }
          this.options = options
          this.filterMethod(query)
          return
        }

        if (!this.url) {
          return
        }

        if (this.loading) {
          // We are already making a request
          return
        }

        try {
          this.loading = true
          const localOptions = this.localSearch(query)
          // * If local search returns more than 5 results, we don't make a remote request to avoid many db queries
          // * For get more results, user can use the search input with more characters
          if (localOptions?.length > 5) {
            this.loading = false
            this.lastQuery = query
            this.options = [...localOptions]
            return
          }
          let { data, meta } = await Cache.getRequest(this.url, {
            params: this.getQueryParams(query),
            invalidateCache,
          })

          if (this.transformData) {
            data = this.transformData(data)
          }

          this.$emit('data-fetch', data)

          this.lastPage = meta?.last_page || 1
          if (this.page === 1 && !this.data?.length) {
            this.options = data
          } else {
            this.options = this.options.concat(data)
          }
          if (this.extraOptions?.length) {
            this.options = this.options.concat(this.extraOptions)
          }
          if (this.filterOptions) {
            this.options = this.filterOptions(this.options)
          }
          this.lastQuery = query

          if (this.selectFirstOption && this.options.length === 1) {
            const firstOption = this.options[0]
            await this.$nextTick()
            this.onChange(get(firstOption?.attributes, this.valueKey))
          }

          // * For local search, we should search in all fetched options
          this.fetchedData = [...this.options]
          await this.tryAddOptionById()
          if (!query) {
            await this.$nextTick()
            this.$refs?.entitySelect?.setSelected();
          }
        } finally {
          this.loading = false
          this.trySetDefaultValue()
        }
      },
      async tryAddOptionById() {
        if (!this.value || typeof this.value !== 'string') {
          return
        }
        const hasOption = this.findFullValue(this.value)
        if (hasOption) {
          return
        }
        const isId = isReferenceId(this.value)
        if (!isId) {
          return
        }
        try {
          const { data } = await Cache.getRequest(`${this.url}/${this.value}`, {
            params: {
              ignore404: true,
            }
          })
          this.options.unshift(data)
        } catch (err) {
          console.error(err)
        }
      },
      trySetDefaultValue() {
        if (!this.setDefaultOption) {
          return
        }

        const entityValue = this.getFullValue(this.value)
        this.processSelectedValue(entityValue)
      },
      localSearch(query) {
        if (!query) {
          return []
        }

        return this.fetchedData.filter(option => {
          const optionAttrs = option.attributes || option
          const keys = ['code', 'number', 'name', 'description']
          return keys.some(key => optionAttrs[key] && optionAttrs[key].toString().toLowerCase().includes(query.toLowerCase()))
        })
      },
      onChange(value) {
        if (value?.toString() === '-1') {
          if (this.addEntity) {
            this.showAddEntityDialog = true
          }
          return
        }

        const entityValue = this.getFullValue(value)

        this.processSelectedValue(entityValue)

        if (this.multiple && value.includes('all')) {
          return this.selectAll()
        }

        if (this.multiple && value.includes('none')) {
          return this.selectNone()
        }
        this.emitChange(value, entityValue)
        this.$emit('entity-change', entityValue)
      },
      processSelectedValue(entityValue) {
        if (this.onMapEntry) {
          this.onMapEntry(entityValue)
        }

        if (entityValue) {
          this.selectedEntity = {
            ...entityValue?.attributes,
            ...entityValue?.relationships,
          } || {}
        }

      },
      getFullValue(value) {
        if (!value) {
          return ''
        }
        if (Array.isArray(value)) {
          return value.map(val => this.findFullValue(val))
        }
        return this.findFullValue(value)
      },
      findFullValue(value) {
        return this.options.find(entity => {
          if (entity.attributes) {
            return entity.attributes[this.valueKey].toString() === value?.toString()
          }
          return this.get(entity, this.valueKey).toString() === value?.toString()
        })
      },
      selectAll() {
        let value = this.options.map((option) => option.attributes[this.valueKey])
        this.$nextTick(() => {
          this.emitChange(value)
        })
      },
      emitChange(value, fullValue) {
        if (value === -1) {
          return
        }

        if (value === '') {
          value = null
        }

        this.$emit('change', value, fullValue)
        this.$emit('input', value, fullValue)
      },
      selectNone() {
        const value = this.multiple ? [] : null
        this.$nextTick(() => {
          this.emitChange(value)

          this.$emit('entity-change', value)
        })
      },
      triggerOptionSelect(handleOptionSelect = true) {
        const selectRef = this.$refs.entitySelect
        let hoverIndex = selectRef.hoverIndex
        if (this.addEntity && !hoverIndex || this.addEntity) {
          selectRef.hoverIndex++
        }

        if (!handleOptionSelect) {
          return
        }

        const option = selectRef.options[hoverIndex]
        if (option) {
          selectRef.handleOptionSelect(option, false, false)
        }

        if (selectRef.options.length === 0) {
          setTimeout(() => {
            selectRef.resetQuery()
          }, 50)
        }
      },
      scrollToOption(selectRef) {
        let optionIndex = selectRef.hoverIndex

        if (this.options.length !== optionIndex) {
          return
        }

        const addNewEntityButton = document.getElementById('addNewEntityButton')
        if (!addNewEntityButton) {
          return
        }

        addNewEntityButton.scrollIntoView({
          block: 'end',
        })
      },
      forceSelectCreatedOption() {
        const selectRef = this.$refs.entitySelect
        let optionIndex = selectRef.hoverIndex

        if (this.addEntity && optionIndex === 0) {
          optionIndex++
        }

        const option = selectRef.options[optionIndex]

        if (this.allowCreate && !isUUID(option?.value) && this.lastQuery) {
          // * At this time the lastQuery -> input value
          this.$emit('input', this.lastQuery)
          return
        }

        if (option) {
          selectRef.handleOptionSelect(option);
        }
      },
      emitLastQuery() {
        if (this.value && !this.lastQuery) {
          return
        }
        this.$emit('input', this.lastQuery)
      },
      initForceSelect() {
        const input = this.$el.querySelector('.entity-select .el-input')
        const selectRef = this.$refs.entitySelect

        if (!input || !selectRef) {
          return
        }

        input.addEventListener('keydown', this.onKeyDown)
      },
      async onKeyDown(event) {
        const selectRef = this.$refs.entitySelect
        if (event.shiftKey) {
          return
        }

        if (!selectRef || event.key !== 'Tab') {
          return
        }

        this.forceSelectCreatedOption()
      },
      // * cover the case with pagination
      findAutoFilledEntity(id) {
        if (!this.$attrs.disabled || !isUUID(id)) {
          return
        }
        this.getData(id)
      },
      async scrollToSelectedValue(value) {
        await scrollToSelectedValue(value, this.$refs.entitySelect, this.idValue)
      },
      autoSelectIdValue() {
        if (!this.idValue || !this.data.length) {
          return
        }
        const option = this.data.find(o => o?.attributes[this.valueKey] === this.idValue)
        if (option) {

        }
      }
    },
    async mounted() {
      if (this.fetchOnMount) {
        const query = isUUID(this.value) ? '' : this.value
        await this.getData(query, true)
      }
      this.initForceSelect()
      await this.scrollToSelectedValue(this.value)
    },
    beforeDestroy() {
      const input = this.$el.querySelector('.entity-select .el-input')
      if (!input) {
        return
      }
      input.removeEventListener('keydown', this.onKeyDown)
    },
    watch: {
      value(value) {
        if (!value) {
          return
        }
        this.findAutoFilledEntity(value)
        this.scrollToSelectedValue(value)
      },
      initialValue: {
        immediate: true,
        handler(value) {
          if (!value || !value?.attributes) {
            return
          }
          const hasValue = this.findFullValue(this.initialValue?.attributes[this.valueKey])
          if (hasValue) {
            return
          }
          this.options.unshift(this.initialValue)
          this.options = uniqBy(this.options, this.valueKey)
        }
      },
      data(newValue) {
        if (!this.url || newValue) {
          this.options = newValue
        }
      },
      url(value) {
        if (!value) {
          return
        }
        this.getData()
      },
      urlParams(value) {
        if (!value) {
          return
        }
        this.getData()
      },
    },
  }
</script>
<style lang="scss">
  .el-select .el-input {
    input {
      @apply truncate;
    }
  }

  .has-table .el-select-dropdown__list {
    padding-top: 30px;
  }

  .has-table .el-select-dropdown__item {
    height: 30px;
    line-height: 30px;
  }
  .full-value-loading .el-input::after {
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    content: 'Loading...';
    @apply absolute bg-white rounded px-4 py-1.5 text-gray-500;
  }
</style>
