<template>
  <base-form
      :loading="loading"
      :show-cancel="showCancel"
      :save-text="$t('Create pay code')"
      :update-text="$t('Update pay code')"
      :can-create-another-entity="!model.id"
      layout="modal"
      grid-classes="grid grid-cols-8 gap-x-3"
      @cancel="$emit('cancel')"
      @submit="onSubmit"
  >
    <NonTaxableWarning :data="model"
                       class="col-span-8 mb-4"
    />
    <div class="col-span-8 md:col-span-3 xl:col-span-1">
      <code-input
        v-model="model.code"
        :model="model"
        :resource-name="globalResources.PayCodes"
      />
    </div>
    <div class="col-span-8 md:col-span-5 xl:col-span-3">
      <base-input v-model="model.description"
                  :label="$t('Description')"
                  :placeholder="$t('Description')"
                  id="description"
                  rules="max:150"
      />
    </div>
    <div class="col-span-8 md:col-span-2">
      <w-2-code-select v-model="model.w2_code_id"
                       clearable
      />
    </div>
    <div class="col-span-6 md:col-span-1">
      <ActiveSwitch v-model="model.active"></ActiveSwitch>
    </div>
    <div class="col-span-6 md:col-span-1">
      <base-switch
        id="available_in_timecards"
        v-model="model.available_in_timecards"
        :label-info="$t('Available in Timecards')"
      />
    </div>
    <div class="col-span-8">
      <h5 class="form-section-title">
        {{ $t('Accounts') }}
      </h5>
    </div>
    <account-select v-model="model.liability_account"
                    :label="$t('Liability Account')"
                    :placeholder="$t('Liability Account')"
                    clearable
                    value-key="number"
                    label-key="number"
                    id="liability_account"
                    class="col-span-8 md:col-span-2"
    />
    <sub-account-select v-model="model.liability_subaccount"
                        :label="$t('Liability Subaccount')"
                        :placeholder="$t('Liability Subaccount')"
                        clearable
                        value-key="number"
                        label-key="number"
                        id="liability_subaccount"
                        class="col-span-8 md:col-span-2 xl:col-span-1"
    />
    <account-select v-model="model.expense_account"
                    :label="$t('Expense Account')"
                    :placeholder="$t('Expense Account')"
                    clearable
                    value-key="number"
                    label-key="number"
                    id="expense_account"
                    class="col-span-8 md:col-span-2"
    />
    <sub-account-select v-model="model.expense_subaccount"
                        :label="$t('Expense Subaccount')"
                        :placeholder="$t('Expense Subaccount')"
                        clearable
                        value-key="number"
                        label-key="number"
                        id="expense_subaccount"
                        class="col-span-8 md:col-span-2 xl:col-span-1"
    />
    <div class="col-span-8">
      <h5 class="form-section-title">
        {{ $t('Rates') }}
      </h5>
    </div>
    <div class="col-span-8 md:col-span-2 mb-4">
      <base-checkbox v-model="model.is_disposable_earning"
                     :label="$t('Is Disposable Earning')"
                     id="is_disposable_earning"
      />
    </div>
    <base-select v-model="model.piece_pay_behavior"
                 :label="$t('Piece Pay Behavior')"
                 :placeholder="$t('Piece Pay Behavior')"
                 :options="piecePayOptions"
                 id="piece_pay_behavior"
                 class="col-span-8 md:col-span-2"
    />
    <base-select v-model="model.calculate_by"
                 :label="$t('Calculate By')"
                 :placeholder="$t('Calculate By')"
                 :options="calculateByOptions"
                 id="calculate_by"
                 rules="required"
                 class="col-span-8 md:col-span-2"
                 @change="onChangeCalculateBy"
    />
    <div class="col-span-8 md:col-span-2">
      <base-select v-model="model.multiply_by"
                   :label="$t('Multiply Rate By')"
                   :placeholder="$t('Multiply Rate By')"
                   :options="getMultiplyByOptions"
                   id="multiply_by"
                   rules="required"
                   @change="onChangeMultiplyBy"

      />
      <base-checkbox v-if="model.multiply_by === multiplyBy.SPECIAL_PAY_UNITS"
                     v-model="model.use_employee_regular_rate"
                     :label="(`Use Employee regular rate`)"
                     id="use_employee_regular_rate"
                     class="mb-4"
      />
    </div>
    <div class="col-span-8">
      <el-collapse v-model="activeGroups">
        <BaseCollapseSection
          key="subject_to"
          name="subject_to"
        >
          <template v-slot:title>
            <div class="flex w-full items-center justify-between">
              {{ $t('Subject To') }}
              <div class="flex items-center">
                {{ bulkTaxesState ? $t('Disable All') : $t('Enable All') }}
                <BaseSwitch
                  v-model="bulkTaxesState"
                  class="ml-2 mr-4"
                  @click.native.stop
                  @change="triggerRrBulkAction"
                />
              </div>
            </div>
          </template>
          <div class="grid grid-cols-8 gap-x-4">
            <div class="col-span-8 md:col-span-2 mb-2">
              <BaseCheckbox
                v-model="model.subject_to_workers_comp"
                :label="(`Worker's Compensation`)"
                id="subject_to_workers_comp"
              />
              <BaseCheckbox
                v-model="model.subject_to_liability_insurance"
                :label="(`General Liability Insurance`)"
                id="subject_to_liability_insurance"
              />
              <BaseCheckbox
                v-model="model.subject_to_fui"
                :label="$t('Federal Unemployment')"
                id="subject_to_fui"
              />
              <BaseCheckbox
                v-model="model.subject_to_sui"
                :label="$t('State Unemployment')"
                id="subject_to_sui"
              />
              <BaseCheckbox
                v-model="model.subject_to_sdi"
                :label="$t('State Disability')"
                id="subject_to_sdi"
              />
              <BaseCheckbox
                v-model="model.subject_to_fica"
                :label="$t('Fica/Medicare')"
                id="subject_to_fica"
              />
            </div>
            <div class="col-span-8 md:col-span-2 xl:col-span-1">
              <BaseSelect
                v-model="model.subject_to_fit"
                :options="withholdingSubjectOptions"
                :label="$t('Federal Withholding')"
              />
            </div>
            <div class="col-span-8 md:col-span-2 xl:col-span-1">
              <BaseSelect
                v-model="model.subject_to_sit"
                :options="withholdingSubjectOptions"
                :label="$t('State Withholding')"
              />
            </div>
            <div class="col-span-8 md:col-span-2 xl:col-span-1">
              <BaseSelect
                v-model="model.subject_to_lit"
                :options="withholdingSubjectOptions"
                :label="$t('Local Withholding')"
              />
            </div>
          </div>
        </BaseCollapseSection>
        <BaseCollapseSection key="additional_information"
                               :title="$t('Additional Information')"
                               name="additional_information">
          <div class="grid grid-cols-8 gap-x-4">
            <div class="col-span-8 md:col-span-2 mb-4">
              <base-checkbox v-model="model.include_on_certified_payroll_report"
                             :label="$t('Include On Certified Payroll')"
                             id="include_on_certified_payroll_report"
              />
              <base-checkbox v-model="model.include_on_union_reports"
                             :label="$t('Include On Union Report')"
                             id="include_on_union_reports"
              />
              <base-checkbox v-model="model.treat_units_as_regular_hours"
                             :label="$t('Treat Units as Regular Hours')"
                             id="treat_units_as_regular_hours"
              />
            </div>
            <div class="col-span-8 md:col-span-2 mb-4">
              <base-select v-model="model.classify_as"
                           :label="$t('Classify As')"
                           :options="classifyAsOptions"
              />
            </div>
            <div class="col-span-8 md:col-span-2">
              <base-select v-model="model.certified_payroll_category_type"
                           :label="$t('Category Type')"
                           :placeholder="$t('Category Type')"
                           :tip="$t('Certified payroll category type')"
                           :options="categoryTypeOptions"
                           clearable
                           id="certified_payroll_category_type"
              />
            </div>
            <div class="col-span-8 md:col-span-2">
              <entity-select v-model="model.job_type_id"
                             :label="$t('Normal Job Cost Type')"
                             :placeholder="$t('Normal Job Cost Type')"
                             :add-entity="false"
                             clearable
                             url="/restify/job-types?type=cost&sort=index"
                             id="job_type_id"
              />
            </div>
          </div>
        </BaseCollapseSection>
      </el-collapse>
    </div>
  </base-form>
</template>
<script>

  import axios from 'axios'
  import pick from 'lodash/pick'
  import {
    CALCULATE_BY_PERCENTAGE_KEY,
    CALCULATE_BY_RATE_KEY,
    calculateByOptions,
    categoryTypeOptions,
    multiplyBy,
    multiplyOptions,
    piecePayOptions,
  } from '@/modules/payroll/components/rates/util'
  import NonTaxableWarning from '@/modules/payroll/components/codes/NonTaxableWarning'
  import { globalResources } from "@/components/form/util";

  const TAX_FIELDS = [
    'subject_to_fit',
    'subject_to_sit',
    'subject_to_lit',
    'subject_to_fica',
    'subject_to_fui',
    'subject_to_sdi',
    'subject_to_sui',
  ]

  const TAX_NO = 'no'
  const TAX_YES = 'yes'
  const TAX_FLAT = 'flat'

  const CLASSIFICATION_HOURLY_BASE_PAY = 'hourly-base-pay'
  const CLASSIFICATION_FRINGE_PAID_IN_CASH = 'fringe-paid-in-cash'

  export default {
    components: {
      NonTaxableWarning,
    },
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        globalResources,
        showCancel: true,
        multiplyBy,
        multiplyOptions,
        piecePayOptions,
        calculateByOptions,
        categoryTypeOptions,
        classifyAsOptions: [
          {
            label: this.$t('Hourly Base Pay'),
            value: CLASSIFICATION_HOURLY_BASE_PAY,
          },
          {
            label: this.$t('Fringe - Pay in Cash'),
            value: CLASSIFICATION_FRINGE_PAID_IN_CASH,
          },
        ],
        bulkTaxesState: false,
        activeGroups: ['subject_to', 'additional_information'],
        withholdingSubjectOptions: [
          {
            label: this.$t('Yes'),
            value: TAX_YES,
          },
          {
            label: this.$t('No'),
            value: TAX_NO,
          },
          {
            label: this.$t('Flat'),
            value: TAX_FLAT,
          },
        ],
        loading: false,
        model: {
          code: '',
          description: '',
          w2_code_id: undefined,
          job_type_id: undefined,
          is_disposable_earning: false,
          piece_pay_behavior: null,
          liability_account: '',
          liability_subaccount: '',
          expense_account: '',
          expense_subaccount: '',
          calculate_by: CALCULATE_BY_RATE_KEY,
          multiply_by: multiplyBy.REGULAR_HOURS,
          use_employee_regular_rate: false,
          subject_to_workers_comp: true,
          subject_to_liability_insurance: true,
          subject_to_fit: TAX_YES,
          subject_to_sit: TAX_YES,
          subject_to_lit: TAX_YES,
          subject_to_fica: true,
          subject_to_fui: true,
          subject_to_sdi: true,
          subject_to_sui: true,
          include_on_certified_payroll_report: true,
          include_on_union_reports: true,
          classify_as: CLASSIFICATION_HOURLY_BASE_PAY,
          certified_payroll_category_type: undefined,
          is_401k_match: true,
          treat_units_as_regular_hours: false,
          active: true,
          show_in_ytds: false,
          available_in_timecards: true,
        },
      }
    },
    computed: {
      getMultiplyByOptions() {
        return this.multiplyOptions[this.model.calculate_by]
      },
    },
    methods: {
      async onSubmit() {
        try {
          this.loading = true
          if (this.model.id) {
            await axios.put(`/restify/pay-codes/${this.model.id}`, this.model)
            this.$success(this.$t('Pay code updated.'))
            this.$emit('save', this.model)
          } else {
            const { data } = await axios.post('/restify/pay-codes', this.model)
            this.$success(this.$t('Pay code created.'))
            this.$createAnotherEntity ? this.$emit('create-another') : this.$emit('save', data)
          }
        } catch (err) {
          if (err.handled) {
            return
          }
          console.log('err', err)
          this.$error(this.$t('Could not update the pay code.'))
        } finally {
          this.loading = false
        }
      },
      triggerRrBulkAction(state) {
        this.model = {
          ...this.model,
          subject_to_fit: state ? TAX_YES : TAX_NO,
          subject_to_sit: state ? TAX_YES : TAX_NO,
          subject_to_lit: state ? TAX_YES : TAX_NO,
          subject_to_fica: state,
          subject_to_fui: state,
          subject_to_sdi: state,
          subject_to_sui: state,
          subject_to_workers_comp: state,
          subject_to_liability_insurance: state,
        }
      },
      onChangeMultiplyBy() {
        this.model.use_employee_regular_rate = false
      },
      onChangeCalculateBy(option) {
        if (option === CALCULATE_BY_PERCENTAGE_KEY) {
          this.model.multiply_by = multiplyBy.REGULAR_HOURLY_PAY
          this.multiplyByLabel = this.$t('Multiply Rate By')
          return
        }
        this.model.multiply_by = multiplyBy.REGULAR_HOURS
        this.multiplyByLabel = this.$t('Multiply % By')
      },
      setBulkTaxesState() {
        const taxObj = pick(this.model, TAX_FIELDS) || {}
        this.bulkTaxesState = Object.values(taxObj).includes([TAX_NO, false])
      },
    },
    watch: {
      data: {
        immediate: true,
        handler(value) {
          if (!value?.id) {
            return this.bulkTaxesState = true
          }
          this.model = {
            ...this.model,
            ...value.attributes,
          }
          this.setBulkTaxesState()
        },
      },
    },
  }
</script>
