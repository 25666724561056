<template>
  <base-report-form
      :filters="model"
      :report-number="reportNumber"
  >

    <template #filters>

      <base-filter-row :title="$t('View')">
        <base-checkbox
            v-model="model.subtotals_only"
            :label="$t('Subtotals Only')"
            class="col-span-3"
            id="subtotals_only"
        />
      </base-filter-row>

      <base-filter-row :title="$t('Journal')">
        <base-select
            v-model="model.journal_code"
            :options="journalCodeOptions"
            class="col-span-3"
        />
        <div class="col-span-3 flex items-baseline">
          <span class="text-sm text-gray-600 font-medium whitespace-nowrap md:mr-3">
            {{ $t('Journal No') }}
          </span>
          <base-input
              v-model.number="model.journal_number"
              :placeholder="$t('Enter Journal Number')"
              class="col-span-1"
              type="number"
              inline-errors
          />
        </div>
      </base-filter-row>

      <period-year-range-filter v-model="model"/>

      <base-filter-row :title="$t('Date Range')">
        <base-select
            v-model="model.date_to_parse"
            :options="dateToParseOptions"
            class="col-span-2"
        />
        <date-range-filter
            v-if="model.date_to_parse !== dateToParse.All"
            v-model="model"
            class="col-span-4"
        />
      </base-filter-row>

      <job-range-filter
          v-model="model"
      >
        <div class="flex col-span-3 items-baseline">
        <label class="mx-2 mr-4">{{ $t('Include') }}</label>
        <base-select
            v-model="include_transactions_value"
            @entity-change="onChangeTransactionType"
            :options="getTransactionsTypes"
            rules="required"
            class="flex-1"
        />
        </div>
      </job-range-filter>

      <base-filter-row>
        <base-checkbox
            v-model="selectAccountList"
            :label="$t('Select a List of Accounts')"
            class="col-span-3 mb-3"
            id="select_account_list"
            @change="resetAccountFilters"
        />
      </base-filter-row>

      <base-filter-row :title="$t('Accounts')">
        <account-select
            v-if="selectAccountList"
            v-model="model.account_ids"
            :placeholder="$t('Select Accounts')"
            class="col-span-4"
            collapse-tags
            multiple
        />
        <account-range-filter
            v-else
            v-model="model"
        />
        <sub-account-range-filter
            v-model="model"
            grid-class="col-span-3"
        />
        <base-checkbox
            v-model="model.include_period_0"
            :label="$t('Include Period 0')"
            class="col-span-2"
            id="include_period_0"
        />
      </base-filter-row>

    </template>

    <template #table="{data, loading}">

      <job-and-other-distributions-report-table
          :data="data"
          :filters="model"
          :report-number="reportNumber"
          :data-loading="loading"
          :subtotal-only="model.subtotals_only"
          ref="reportTable"
      />

    </template>

  </base-report-form>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { codes } from '@/modules/ledger/enum/journal'
  import { historySelectionGroupBy } from '@/modules/ledger/components/reports/util'
  import JobAndOtherDistributionsReportTable from '@/modules/ledger/components/reports/JobAndOtherDistributionsReportTable.vue'
  import {
    PeriodYearRangeFilter,
    DateRangeFilter,
    AccountRangeFilter,
    SubAccountRangeFilter,
    periodYearFilterKeys,
    JobRangeFilter,
  } from '@/components/range-filters'
  import { globalResources } from '@/components/form/util'

  const transactionGroups = {
    All: 'all',
    Job: 'job',
    NonJob: 'non_job',
    Cost: 'cost',
    Income: 'income',
  }

  const dateToParse = {
    All: 'all',
    ReferenceDate: 'reference_date',
    PostedAt: 'posted_at',
  }

  export default defineComponent({
    components: {
      JobRangeFilter,
      DateRangeFilter,
      AccountRangeFilter,
      PeriodYearRangeFilter,
      SubAccountRangeFilter,
      JobAndOtherDistributionsReportTable,
    },
    data() {
      return {
        dateToParse,
        reportNumber: 1872,
        historySelectionGroupBy,
        selectAccountList: false,
        include_transactions_value: transactionGroups.All,
        model: <GL_REPORTS.JobAndOtherDistributions> {
          subtotals_only: false,
          journal_code: null,
          journal_number: null,

          job_number_from: null,
          job_number_to: null,

          ...periodYearFilterKeys,

          date_to_parse: dateToParse.All,
          start_date: null,
          end_date: null,
          account_ids: [],
          account_number_from: null,
          account_number_to: null,
          subaccount_number_from: null,
          subaccount_number_to: null,
          include_period_0: false,
          include_transactions: transactionGroups.All,
          job_type_id: null,
        },
        dateToParseOptions: [
          {
            label: this.$t('All Dates'),
            value: dateToParse.All,
          },
          {
            label: this.$t('Reference'),
            value: dateToParse.ReferenceDate,
          },
          {
            label: this.$t('Posted'),
            value: dateToParse.PostedAt,
          },
        ],
        journalCodeOptions: [
          {
            label: this.$t('All Journals'),
            value: null,
          },
          ...codes,
        ],
      }
    },
    computed: {
      jobCostTypes() {
        return this.$store.getters['globalLists/getResourceOptions'](globalResources.JobCostTypes)
      },
      jobIncomeTypes() {
        return this.$store.getters['globalLists/getResourceOptions'](globalResources.JobIncomeTypes)
      },
      getTransactionsTypes() {
        // @ts-ignore
        const costTypes = this.jobCostTypes.map(type => {
          return {
            label: `Cost - ${type.abbr} (${type.name})`,
            value: type.id,
            cost: true,
            isJobType: true,
          }
        })
        // @ts-ignore
        const incomeTypes = this.jobIncomeTypes.map(type => {
          return {
            label: `Income - ${type.abbr} (${type.name})`,
            value: type.id,
            income: true,
            isJobType: true,
          }
        })

        return [
          {
            label: this.$t('All Transactions'),
            value: transactionGroups.All,
          },
          {
            label: this.$t('Job Transactions Only'),
            value: transactionGroups.Job,
          },
          {
            label: this.$t('Non-Job Transactions Only'),
            value: transactionGroups.NonJob,
          },
          {
            label: this.$t('Cost Only - All Types'),
            value: transactionGroups.Cost,
          },
          ...costTypes,
          {
            label: this.$t('Income Only - All Types'),
            value: transactionGroups.Income,
          },
          ...incomeTypes,
        ] as Record<string, any>[]

      },
    },
    methods: {
      onChangeTransactionType(type: any) {
        if (!type.isJobType) {
          this.model.include_transactions = type.value
          this.model.job_type_id = null
          return
        }
        this.model.job_type_id = type.value
        this.model.include_transactions = null
      },
      resetAccountFilters() {
        this.model.account_ids = []
        this.model.include_period_0 = false
        this.model.account_number_from = this.model.account_number_to = null
      },
    },
  })
</script>
