<template>
  <entity-preview
      v-if="data?.id"
      :entity="get(data, 'attributes', {})"
      :key="`preview-${data?.id}`"
      :url="`/service-billing/worksites/${data?.id}/view`"
      :value-to-display="valueToDisplay"
      :parent-entity-id="data.id"
      entity-name="worksite"
  />
</template>
<script>
  export default {
    name: 'WorksiteLink',
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      showName: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      worksiteData() {
        return this.data?.attributes || this.data || {}
      },
      valueToDisplay() {
        const { number, name } = this.worksiteData
        if (!name || !this.showName) {
          return number
        }
        return `${number} (${name})`
      },
    },
  }
</script>
