<template>
  <div class="flex relative"
        ref="booleanFilter">
    <div class="selector-wrapper rounded-l-2xl border"
         @click="onSelectOption('true')"
         :class="{'selected-option': selectedOption === 'true'}"
         role="button">
      {{$t('Yes')}}
    </div>
    <div class="selector-wrapper border-t border-b"
         @click="onSelectOption()"
         :class="{'selected-option': !['true', 'false'].includes(selectedOption)}"
         role="button">
      {{$t('All')}}
    </div>
    <div class="selector-wrapper rounded-r-2xl border"
         @click="onSelectOption('false')"
         :class="{'selected-option': selectedOption === 'false'}"
         role="button">
      {{$t('No')}}
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      value: {
        type: [Boolean, String],
        default: '',
      },
    },
    data() {
      return {
        selectedOption: null
      }
    },
    methods: {
      onSelectOption(value) {
        this.selectedOption = value
        this.$emit('input', value)
      },
      preselectOption(option) {
        this.selectedOption = option
      },
    },
    mounted() {
      this.preselectOption(this.value)
    },
  }
</script>
<style lang="scss">
  .selector-wrapper {
    @apply text-sm font-semibold w-24 h-8 bg-gray-50 text-gray-400 border-gray-400 items-center flex justify-center hover:bg-white hover:text-primary-400;
  }
  .selected-option {
    @apply text-primary-600 bg-white;
  }
</style>
