<template>
  <div class="absolute right-[20%] lg:right-[40%] top-0 -rotate-[25deg] z-0">
    <img
        v-if="invoice.status === resourceStatuses.Voided"
        class="h-24"
        src="/img/icons/void.png"
        alt="void"
    >
    <IconPaid
        v-else-if="invoice.status === resourceStatuses.Paid"
        class="h-32 w-32"
    />
    <IconDraft
        v-else-if="invoice.status === resourceStatuses.Pending"
        class="h-32 w-32"
    />
    <IconLate
        v-else-if="isDue(invoice)"
        class="h-32 w-32"
    />
    <IconSent
        v-else-if="isSent"
        class="h-32 w-32"
    />
  </div>
</template>
<script>
  import { resourceStatuses } from "@/enum/enums";
  import { isDue } from "@/modules/accounts-payable/pages/invoices/invoiceUtils";

  export default {
    props: {
      invoice: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        resourceStatuses,
      }
    },
    computed: {
      isSent() {
        return this.invoice?.telemetry?.send > 0
      }
    },
    methods: {
      isDue,
    }
  }
</script>
