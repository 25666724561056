<template>
  <div/>
</template>
<script>
  import cloneDeep from 'lodash/cloneDeep'

  export default {
    props: {
      data: {
        required: true,
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        rows: [],
        pagination: {
          current_page: 1,
          per_page: 5,
          total: 5,
        },
      }
    },
    computed: {
      grandTotals() {
        return this.data?.grand_totals || {}
      },
      options() {
        return this.data?.options || {}
      },
      amountColumns() {
        return this.columns.filter(column => column.component === 'FormattedPrice')
      },
      defaultAttributes() {
        return {
          ...this.$attrs,
          limitMaxHeight: false,
          addDefaultColumns: false,
          showActionsColumn: false,
          hasSummary: true,
          compact: true,
          perPage: 500,
          ref: 'report-table',
          isReportTable: true,
          class: 'no-borders',
        }
      },
      getData() {
        if (!this.rowsLength) {
          return []
        }

        const from = (this.pagination.current_page - 1) * this.pagination.per_page
        const to = this.pagination.current_page === Math.ceil(this.rowsLength / this.pagination.current_page) ? this.rowsLength : (from + this.pagination.per_page)

        const rows = cloneDeep(this.rows)

        return rows.slice(from, to)
      },
      rowsLength() {
        return this.rows?.length
      },
    },
    methods: {
      forcePagination(paginator) {
        this.pagination = paginator
      },
      getSummarySlotName(column) {
        return `${column.prop}_summary`
      },
      tryComposeRows(data) {
        const dataObjectKeys = Object.keys(data?.items || {})
        if (!data?.items?.length && !dataObjectKeys.length) {
          return
        }

        this.rows = []
        return this.composeRows(data.items)
      },
    },
    watch: {
      data(value) {
        this.tryComposeRows(value)
      },
    },
  }
</script>
