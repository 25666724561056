<template>
  <div class="px-5">
    <base-form layout="vertical"
               :loading="loading"
               :save-text="$t('Update page')"
               show-back
               @submit="onSubmit">
      <div v-if="!initialLoading" class="col-span-6 flex items-end justify-end mb-1">
        <base-switch v-model="previewEnabled"
                     :label-info="$t('Preview')"
                     class="mr-2"
        >
        </base-switch>
        <status-badge :status="model.draft ? $t('Draft') : $t('Posted')"></status-badge>
      </div>
      <div v-if="initialLoading"
           class="flex w-full justify-center col-span-6 text-primary-500 loading-wrapper items-center">
        <loading-circle class="stroke-current fill-current w-16 h-16"></loading-circle>
      </div>
      <template v-else>
        <template v-if="previewEnabled">
          <page-preview class="col-span-6"
                        :title="model.title"
                        :category="model.category"
                        :content="model.content">
          </page-preview>
        </template>
        <template v-else>
          <base-input v-model="model.title"
                      class="col-span-6"
                      :label="$t('Title')">
          </base-input>
          <base-input v-model="model.category"
                      class="col-span-6"
                      :label="$t('Category')">
          </base-input>

          <base-input v-if="model.content"
                      :label="$t('Content')"
                      id="article-input"
                      class="col-span-6"
          >
            <html-editor v-model="model.content"></html-editor>
          </base-input>
        </template>
      </template>
      <template v-slot:extra-buttons-before>
        <base-submit-button class="ml-2"
                            variant="danger-link"
                            @click="onDelete"
        >
          {{ $t('Delete') }}
        </base-submit-button>
      </template>
      <template v-slot:extra-buttons>
        <base-submit-button v-if="model.draft"
                            :loading="loadingPublish"
                            class="ml-2"
                            variant="success"
                            @click="onPublish"
        >
          {{ $t('Publish') }}
        </base-submit-button>
      </template>
    </base-form>
  </div>
</template>
<script>
  import axios from 'axios'
  import PagePreview from "@/modules/wiki/components/PagePreview";
  import LoadingCircle from "@/modules/wiki/components/LoadingCircle";

  export default {
    components: {
      PagePreview,
      LoadingCircle,
    },
    data() {
      return {
        loading: false,
        loadingPublish: false,
        initialLoading: true,
        model: {
          title: '',
          content: ``,
          draft: false,
        },
        pageDetails: null,
        previewEnabled: false
      }
    },
    computed: {
      pageId() {
        return this.$route.params.id
      }
    },
    methods: {
      async onSubmit() {
        try {
          this.loading = true
          await axios.post(`/restify/wiki-articles/${this.pageId}`, this.model)
          this.$success(this.$t('Page saved successfully'))
        } finally {
          this.loading = false
        }
      },
      async onPublish() {
        try {
          this.loadingPublish = true
          await axios.post(`/restify/wiki-articles/${this.pageId}/actions?action=publish`, this.model)
          this.model.draft = false
        } finally {
          this.loadingPublish = false
        }
      },
      async getPageDetails() {
        try {
          this.initialLoading = true
          const { data } = await axios.get(`/restify/wiki-articles/${this.pageId}`)
          this.pageDetails = data
          this.model = this.pageDetails.attributes
        } finally {
          this.initialLoading = false
        }
      },
      async onDelete() {
        const confirmed = await this.$deleteConfirm({
          title: this.$t(`Delete page #${this.pageId}`),
          description: this.$t(`Are you sure you want to delete this page and it's content?`),
        })

        if (!confirmed) {
          return
        }

        try {
          await axios.delete(`/restify/wiki-articles/${this.pageId}`)
          this.$success(`Page #${this.pageId} deleted`)
          await this.$router.push('/wiki/new')
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(`Could not delete page #${this.pageId}`)
        }
      }
    },
    created() {
      this.getPageDetails()
    }
  }
</script>
<style>
  .loading-wrapper {
    min-height: 70vh;
  }
</style>
