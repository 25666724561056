<template>
  <div class="text-primary-500 w-full flex h-full items-center justify-center page-loading-container"
  >
    <CraneIcon class="w-12 h-12 crane-icon animate-pulse" />
  </div>
</template>
<script>
  import CraneIcon from "@/components/ag-grid/CraneIcon";

  export default {
    components: {
      CraneIcon,
    }
  }
</script>
<style scoped>
  .page-loading-container {
    min-height: calc(100vh - 200px);
  }
</style>
