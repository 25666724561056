<template>
  <ProofListing
      entity="journal"
      url="/restify/journals/actions?action=post-jc-journals-proof-listing"
      post-url="/restify/journals/action?action=post-jc-journals"
      ids-field="journal_ids"
      @data-fetch="data = $event"
      #default="{ loading }"
  >
    <AgDataTable
        :data="flatData"
        :columns="columns"
        :data-loading="loading"
        :pagination="false"
        :groupDefaultExpanded="-1"
        :groupIncludeFooter="true"
        :groupRowRendererParams="groupRowRendererParams"
        :compact="true"
        :no-borders="true"
        groupDisplayType="groupRows"
        dom-layout="autoHeight"
    />

    <div class="grid lg:grid-cols-2 gap-4 mt-8 print:break-inside-avoid">
      <div></div>
      <div>
        <h5 class="form-section-title">
          {{ $t('Account Summary') }}
        </h5>
        <ProofListingAccountSummary
            :data="data.account_summary"
            :loading="loading"
        />
      </div>
    </div>
  </ProofListing>
</template>
<script>
  import i18n from "@/i18n";
  import PostDialog from "@/components/common/modal/PostDialog";
  import ProofListing from "@/modules/common/components/proof-listing/ProofListing";
  import ProofListingAccountSummary from "@/modules/common/components/ProofListingAccountSummary";
  import ProofListingCostTypeSummary from "@/modules/common/components/ProofListingCostTypeSummary";
  import JobTransactionsProofGroupRow from "@/modules/job-costing/components/JobTransactionsProofGroupRow.vue";
  import { formatPrice } from "@/plugins/formatPrice";

  export default {
    components: {
      PostDialog,
      ProofListing,
      JobTransactionsProofGroupRow,
      ProofListingAccountSummary,
      ProofListingCostTypeSummary,
    },
    data() {
      return {
        loading: false,
        data: {
          jobs: [],
          account_summary: [],
        },
      }
    },
    computed: {
      flatData() {
        const allEntries = []
        this.data.journals?.forEach(journal => {
          journal?.jobs.forEach(job => {
            job?.entries.forEach(entry => {
              allEntries.push({
                ...entry,
                job,
                journal,
                debit_amount: +entry.debit_amount,
                credit_amount: +entry.credit_amount,
              })
            })
          })
        })
        return allEntries
      },
      groupRowRendererParams() {
        return {
          innerRenderer: 'JobTransactionsProofGroupRow',
        }
      },
      columns() {
        return [
          {
            label: i18n.t('Journal'),
            prop: 'journal.id',
            rowGroup: true,
            hide: true,
          },
          {
            label: i18n.t('Job'),
            prop: 'job.number',
            rowGroup: true,
            hide: true,
          },
          {
            headerName: this.$t('Type'),
            field: 'type_id',
            component: 'JobTypeLink',
            minWidth: 30,
            maxWidth: 80,
          },
          {
            headerName: this.$t('Line Iem'),
            field: 'addl_source_id',
            minWidth: 100,
            maxWidth: 120,
            component: 'LineItemLink',
            cellRendererParams: {
              showDescription: false,
            },
          },
          {
            headerName: this.$t('Account'),
            field: 'account',
            minWidth: 100,
            maxWidth: 120,
            component: 'AccountLink',
            cellRendererParams: {
              showDescription: false,
            },
          },
          {
            headerName: this.$t('Description'),
            field: 'description',
            minWidth: 80,
          },
          {
            headerName: this.$t('Ref #'),
            field: 'reference_no',
            minWidth: 80,
            maxWidth: 120,
          },
          {
            headerName: this.$t('Special Source'),
            field: 'special_source_id',
            component: 'SpecialSourceLink',
            minWidth: 80,
            maxWidth: 120,
          },
          {
            headerName: this.$t('Qty'),
            field: 'meta.quantity',
            valueGetter: params => {
              if (!params.data) {
                return ''
              }
              return +params.data?.meta?.quantity
            },
            component: 'FormattedQuantity',
            align: 'right',
            minWidth: 50,
            maxWidth: 100,
          },
          {
            headerName: this.$t('Unit Price'),
            field: 'meta.unit_price',
            valueGetter: params => {
              if (!params.data) {
                return ''
              }
              return +params.data?.meta?.unit_price
            },
            component: 'FormattedPrice',
            align: 'right',
            minWidth: 50,
            maxWidth: 100,
          },
          {
            headerName: this.$t('Extended $'),
            field: 'amount',
            minWidth: 90,
            maxWidth: 150,
            cellRendererParams: {
              hideZero: true,
            },
            valueGetter: params => {
              if (!params.data) {
                return ''
              }
              const { credit_amount, debit_amount } = params.data
              if (credit_amount) {
                return -credit_amount
              }
              return debit_amount
            },
            valueFormatter: params => {
              return formatPrice(params.value)
            },
            align: 'right',
            aggFunc: 'sum',
          },
          {
            headerName: this.$t('Transaction Date'),
            field: 'reference_date',
            minWidth: 100,
            maxWidth: 150,
            component: 'FormattedDate',
            align: 'right',
          },
        ]
      },
    },
  }
</script>
