<template>
  <div class="import-data mt-4">
    <ImportForm
        v-bind="importSettings"
        @success="redirectToList"
    />
  </div>
</template>
<script>
  import ImportForm from "@/modules/common/components/import/ImportForm";
  import { getInitialMapping } from "@/modules/payroll/utils/timecardImportColumns";

  const colMappings = {
    Account: {
      value: 'account',
      label: 'Account',
    },
    Code: {
      value: 'code',
      label: 'Code',
    },
    County: {
      value: 'county',
      label: 'County',
    },
    Description: {
      value: 'description',
      label: 'Description',
    },
    SalesTaxPercent: {
      value: 'sales_tax_percent',
      label: 'Sales Tax Percent',
      alternativeLabels: ['Tax Percent', 'Tax %'],
    },
    State: {
      value: 'state',
      label: 'State',
    },
    SubAccount: {
      value: 'subaccount',
      label: 'Sub Account',
      alternativeLabels: ['GL Sub Account'],
    },
  }

  const options = [
    colMappings.Account,
    colMappings.Code,
    colMappings.County,
    colMappings.Description,
    colMappings.SalesTaxPercent,
    colMappings.State,
    colMappings.SubAccount,
  ]

  export default {
    components: {
      ImportForm,
    },
    data() {
      return {
        importSettings: {
          url: '/restify/districts/import',
          columnOptions: options,
          columnMappings: colMappings,
          initialMapping: getInitialMapping(options),
          type: 'districts-import',
          resource: 'districts',
        },
      }
    },
    methods: {
      async redirectToList() {
        await this.$router.push('/accounts-receivable/settings/sales-tax-districts')
      },
    }
  }
</script>
