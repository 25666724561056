<template>
  <div class="action-item">
    <slot>
      <span
        v-if="title" class="action-item-text"
        @click="onClick"
        :class="{
          'opacity-50 bg-gray-100': disabled,
        }"
      >
      <div
        v-if="icon"
        class="p-2 bg-green-50 mr-2 rounded-md"
        :class="iconClass">
        <component :is="icon" class="w-4 h-4"/>
      </div>
      <span>{{ title }}</span>
    </span>
    </slot>
  </div>
</template>
<script>

import { DownloadIcon } from "vue-feather-icons";

export default {
  name: 'TableActionItem',
  components: {
    DownloadIcon
  },
  props: {
    title: String,
    icon: Object,
    iconClass: String,
    disabled: Boolean,
  },
  methods: {
    onClick() {
      if (this.disabled) {
        return
      }
      this.$emit('click')
    }
  }
}
</script>
<style scoped>
.action-item :deep(.action-item-text) {
  @apply px-4 py-2 hover:bg-gray-50 text-sm cursor-pointer w-full flex items-center font-medium;
}
</style>
