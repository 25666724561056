<template>
  <div>
    <div class="flex justify-between w-full leading-4">
        <span class="font-medium">
          <template v-if="params?.node?.field?.includes('phase_code')">
            <template v-if="matchingPhaseTitle?.description">
              {{ matchingPhaseTitle?.description }}
            </template>
            <template v-else>
              {{$t('Phase Code ')}} {{ params.value }}
            </template>
          </template>
          <template v-else>
            <template v-if="hasChangeOrder">
              {{$t('Change Orders')}}
            </template>
            <template v-else>
              {{ $t('Base Contract') }}
            </template>
          </template>
        </span>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import {globalResources} from "@/components/form/util";

export default defineComponent({
  computed: {
    data() {
      return this.params.node?.allLeafChildren[0]?.data || {}
    },
    hasChangeOrder() {
      const lineItem = this.data?.line_item
      if (lineItem) {
        return lineItem?.change_order?.toString() !== '0'
      }
      return this.data?.change_order?.toString() !== '0'
    },
    level() {
      return this.params.node.level
    },
    phaseTitles() {
      return this.$store.getters['globalLists/getResourceList'](globalResources.PhaseTitles)
    },
    matchingPhaseTitle() {
      const jobId = this.params.jobId
      return this.phaseTitles.find((phaseItem: any) => phaseItem.phase_code === this.params.value && phaseItem.job_id === jobId)
    }
  }
})
</script>
