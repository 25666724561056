<template>
  <entity-select
    url="/restify/payrolls"
    v-bind="$attrs"
    v-on="$listeners"
    :url-params="urlParams"
    :name="$attrs.name || $t('Payroll')"
    :placeholder="$attrs.placeholder || $t('Select Payroll')"
    :label="$attrs.label !== undefined ? $attrs.label : $t('Payroll')"
    :id="$attrs.id || 'payroll-select'"
    :table-columns="tableColumns"
    :label-format="formatLabel"
    label-key="period_end_date"
  />
</template>
<script>
import capitalize from "lodash/capitalize";

export default {
  name: 'PayrollSelect',
  inheritAttrs: false,
  props: {
    urlParams: {
      type: Object,
      default: () => ({
        sort: '-period_end_date'
      }),
    },
  },
  computed: {
    allEmployees() {
      return this.$store.state.globalLists.employees
    },
    tableColumns() {
      return [
        {
          minWidth: 100,
          maxWidth: 150,
          name: this.$t('Period End Date'),
          prop: 'attributes.period_end_date',
          toFormat: (periodEndDate) => {
            return this.$formatDateOnly(periodEndDate)
          }
        },
        {
          minWidth: 250,
          maxWidth: 250,
          name: this.$t('Employee'),
          prop: 'attributes.employee_id',
          toFormat: employeeId => {
            const employee = this.allEmployees.find(e => e.id === employeeId)
            if (!employee)  {
              return ''
            }
            return `${employee.code} (${employee.name})`
          }
        },
        {
          minWidth: 80,
          maxWidth: 120,
          name: this.$t('Status'),
          prop: 'attributes.status',
          toFormat: status => {
            return capitalize(status)
          }
        },
      ]
    }
  },
  methods: {
    formatLabel(batch) {
      const { period_end_date } = batch.attributes
      return this.$formatDateOnly(period_end_date)
    },
  },
}
</script>
