<template>
  <div>
    <AgDataTable
        :columns="columns"
        :url="url"
        :url-params="urlParams"
        :add-text="$t('New budget')"
        :edit-entity-url-query="editEntityUrlQuery"
        :groupDefaultExpanded="-1"
        :disable-col-flex="true"
        :per-page="$pagination.list"
        :exclude-filters="['fiscal_year']"
        groupDisplayType="singleColumn"
        actions="search,refresh,edit"
        permission="budgets"
        hide-actions="view"
        ref="table"
        @add="onRowAdd"
        @edit="onRowEdit"
    >
        <template #attributes.account_id="{row}">
            <AccountLink :data="row" :show-description="false"/>
        </template>
        <template #attributes.subaccount_id="{row}">
            <SubAccountLink :data="row" :show-description="false"/>
        </template>
    </AgDataTable>

    <BaseFormDialog v-if="showDialog"
                    :title="rowToEdit ? $t('Edit Budget') : $t('Add Budget')"
                    :open.sync="showDialog"
                    @close="onEventTrigger">
      <BudgetForm :data="rowToEdit"
                  :key="renderKey"
                  @cancel="onEventTrigger"
                  @save="onEventTrigger"
                  @create-another="renderKey++"
      />
    </BaseFormDialog>
  </div>
</template>
<script>
  import BudgetForm from '@/modules/ledger/components/BudgetForm'

  export default {
    components: {
      BudgetForm,
    },
    data() {
      return {
        renderKey: 1,
        editEntityUrlQuery: '?id={ID}',
        rowToEdit: null,
        showDialog: false,
      }
    },
    computed: {
      url() {
        return '/restify/budgets'
      },
      urlParams() {
        return {
          fiscal_year: this.activeFiscalYear,
          related: 'account,subaccount'
        }
      },
      columns() {
        let columns = [
          {
            headerName: this.$t('Account'),
            field: 'attributes.account_id',
            minWidth: 90,
            maxWidth: 150,
            pinned: 'left',
          },
          {
            headerName: this.$t('Sub'),
            field: 'attributes.subaccount_id',
            minWidth: 80,
            maxWidth: 150,
            pinned: 'left',
          },
          {
            headerName: this.$t('Amount'),
            field: 'attributes.amount',
            minWidth: 120,
            maxWidth: 160,
            component: 'FormattedPrice',
            align: 'right',
            editable: true,
            cellEditor: 'numericCellEditor',
          },
          {
            headerName: this.$t('Spread \n Uniformly'),
            field: 'attributes.spread_uniformly',
            minWidth: 100,
            maxWidth: 120,
            component: 'Status',
          },
        ]

        for (let i = 1; i <= 12; i++) {
          columns.push({
            headerName: this.getPeriodName(i),
            field: `attributes.p${i}_amount`,
            minWidth: 130,
            maxWidth: 300,
            align: 'right',
            component: 'FormattedPrice',
          })
        }
        return columns
      },
    },
    methods: {
      onRowEdit(row) {
        this.showDialog = true
        this.rowToEdit = row
      },
      onRowAdd() {
        this.showDialog = true
      },
      refreshTable() {
        this.$refs.table.refresh()
      },
      onEventTrigger() {
        this.rowToEdit = null
        this.showDialog = false
        this.refreshTable()
      },
      getPeriodName(period) {
        return this.$store.getters['company/getPeriodName'](period, 'originalLabel')
      },
    },
  }
</script>
