import { getSetting } from "@/plugins/settingsAndEnumsPlugin";
import { $modules } from "@/enum/enums";
import { endOfWeek, startOfWeek } from "date-fns";

export const TimesheetStatus = {
  Approved: 'approved',
  Rejected: 'rejected',
  PayrollCreated: 'with-payroll',
  Transferred: 'with-timecard',
  Submitted: 'submitted',
  Pending: 'pending',
}

export function isTimesheetEditable(timesheet) {
  const status = timesheet?.attributes?.status
  if (!status) {
    return false
  }
  return [TimesheetStatus.Pending, TimesheetStatus.Submitted, TimesheetStatus.Rejected].includes(status)
}

export function getPayPeriodStartDay() {
  const start = getSetting($modules.PR, 'payroll_period_start_day')
  if (start === undefined || start === null) {
    return 1
  }
  // In the date picker and date-fns, Sunday is 0, but in our settings, Sunday is 7
  if (start === 7) {
    return 0
  }
  return start
}

export function getStartOfPayWeek(date) {
  return startOfWeek(date, { weekStartsOn: getPayPeriodStartDay() })
}

export function getEndOfPayWeek(date) {
  return endOfWeek(date, { weekStartsOn: getPayPeriodStartDay() })
}
