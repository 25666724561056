<template>
  <line-item-summary
      v-loading="lineItemLoading"
      :show-empty-budgets="false"
      :data="budget"
  />
</template>
<script>
  import LineItemSummary from '@/modules/job-costing/components/line-items/LineItemSummary'

  export default {
    components: {
      LineItemSummary,
    },
    computed: {
      lineItemLoading() {
        return this.$store.state.jobCosting.lineItemLoading
      },
      budget() {
        return this.$store.state.jobCosting.currentLineItem
      },
    },
  }
</script>
