<template>
  <span>
    {{ formattedValue }}
  </span>
</template>
<script>
import { getFormattedSSN, getHiddenSSN } from "@/components/form/ssnUtils";
import { getNodeValue } from "@/components/ag-grid/columnUtils";

export default {
  props: {
    column: {
      type: Object,
      default: () => ({}),
    },
    row: {
      type: Object,
      default: () => ({}),
    },
    params: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    columnParams() {
      return this.column.params || {}
    },
    hiddenSSN() {
      return this.columnParams.hiddenSSN || false
    },
    value() {
      return getNodeValue({
        params: this.params,
        row: this.row,
        column: this.column,
      })
    },
    formattedValue() {
      if (!this.value) {
        return ''
      }

      if (this.hiddenSSN) {
        return getHiddenSSN(this.value)
      }

      return getFormattedSSN(this.value)
    },
  }
}
</script>
