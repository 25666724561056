<template>
  <div class="outstanding-proposals border-b -mx-3">
    <p class="font-semibold mx-3"> {{ $t('Outstanding Proposals:') }} </p>
    <base-data-table
      :columns="columns"
      :data="data.items"
      :compact="true"
      :show-pagination="false"
      :has-summary="true"
      :limit-max-height="false"
      class="mt-4"
    >
      <template #amount_summary>
        <span class="pt-2 top-border">
          {{ $formatPrice(totalProposals) }}
        </span>
      </template>
    </base-data-table>
  </div>
</template>
<script>
  export default {
    props: {
      data: {
        required: true,
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      columns() {
        return [
          {
            label: this.$t('Phase <br> Code'),
            prop: 'phase_code',
            minWidth: 60,
            maxWidth: 60,
            component: 'EntityLink',
            redirectTo: '/job-costing/income-line-items/{ID}/view',
          },
          {
            label: this.$t('Cost <br> Code'),
            prop: 'cost_code',
            minWidth: 60,
            maxWidth: 60,
            component: 'EntityLink',
            redirectTo: '/job-costing/income-line-items/{ID}/view',
          },
          {
            label: this.$t('Change <br> Order'),
            prop: 'change_order',
            minWidth: 60,
            maxWidth: 60,
            component: 'EntityLink',
            redirectTo: '/job-costing/income-line-items/{ID}/view',
          },
          {
            label: '',
            prop: 'description',
            minWidth: 120,
            maxWidth: 160,
          },
          {
            label: this.$t('Proposal #'),
            prop: 'proposal.number',
            minWidth: 80,
            maxWidth: 120,
          },
          {
            label: this.$t('Date'),
            prop: 'proposal.date',
            component: 'FormattedDate',
            minWidth: 100,
            maxWidth: 120,
          },
          {
            label: this.$t('Description'),
            prop: 'proposal.description',
            minWidth: 180,
            maxWidth: 350,
          },
          {
            label: this.$t('Status'),
            prop: 'status',
            minWidth: 180,
            maxWidth: 220,
            summary: () => this.$t('Total Proposals:'),
          },
          {
            label: this.$t('Amount'),
            prop: 'amount',
            minWidth: 100,
            maxWidth: 120,
            align: 'right',
            component: 'FormattedPrice',
          },
        ]
      },
      totalProposals() {
        return this.data.totals?.amount || 0
      },
    }
  }
</script>
<style lang="scss">
  .outstanding-proposals {
    .table-container {
      @apply border-none p-0;

      table > thead > tr > th {
        @apply border-b-0 bg-transparent;
      }
    }
  }
</style>
