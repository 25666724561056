<template>
  <BaseTooltip :content="disabledTooltip" :disabled="!showTooltip">
    <div class="flex lg:flex-col items-center lg:mt-[60px] lg:mr-2">
      <BaseButton
        type="button"
        :disabled="disabled"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <PlusIcon class="w-5 h-5"/>
        <span class="lg:hidden">{{ $t('Track time') }}</span>
      </BaseButton>
      <span class="hidden lg:block text-sm text-gray-500">{{ $t('Track time') }}</span>
    </div>
  </BaseTooltip>
</template>
<script setup>
import { PlusIcon } from "vue-feather-icons";
import { computed } from "vue";
const props = defineProps({
  disabled: Boolean,
  disabledTooltip: String,
})

const showTooltip = computed(() => {
  return props.disabled && props.disabledTooltip
})
</script>
