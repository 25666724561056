<template>
  <span class="flex justify-end">
    <template v-if="data?.quantity">
      {{ $formatQuantity(data.quantity) }}
    </template>
    <template v-if="quantityLabel">
      {{ quantityLabel }}
    </template>
  </span>
</template>
<script>
import { TransactionTypeLabels } from "@/modules/service-billing/util/service-billing";

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    }
  },
  computed: {
    quantityLabel() {
      return TransactionTypeLabels[this.row?.transaction_type] || ''
    }
  },
}
</script>
