<template>
  <BaseWrapper
      v-bind="$attrs"
      :items="items"
      :entity="entity"
      :footer="footer"
      :expanded="expanded"
      title-key="name"
      status-key="status"
      phone-key="phone_1"
  />
</template>
<script>
  import BaseWrapper from '@/modules/common/components/entity-preview/BaseWrapper'

  export default {
    components: {
      BaseWrapper,
    },
    props: {
      entity: {
        type: Object,
        default: () => ({}),
      },
      isPayrollPreview: {
        type: Boolean,
        default: false,
      },
      expanded: {
        type: Boolean,
        default: true
      },
    },
    data() {
      return {
        footer: {
          email: true,
          phone: true,
          address: true,
        },
      }
    },
    computed: {
      items() {
        if (!this.isPayrollPreview) {
          return [
            {
              title: this.$t('Hire Date'),
              value: this.$formatDate(this.entity.hire_date),
              dateType: true,
            },
            {
              title: this.$t('Birth Date'),
              value: this.$formatDate(this.entity.dob),
              dateType: true,
            },
          ]
        }
        return [
          {
            title: this.$t('Minimum check amount'),
            value: this.$formatPrice(this.entity.paper_check_min_pay_amount || 0),
            dateType: true,
          },
          {
            title: this.$t('Exempt On Certified Payroll'),
            value: this.on_certified_payroll ? this.$t('Yes') : this.$t('No'),
            dateType: true,
          },
          {
            title: this.$t('Job Override Worker Comp Code'),
            value: this.job_can_override_workers_comp ? this.$t('Yes') : this.$t('No'),
            dateType: true,
          },
          {
            title: this.$t('Job Override Union Code'),
            value: this.job_can_override_union ? this.$t('Yes') : this.$t('No'),
            dateType: true,
          },
        ]
      }
    }
  }
</script>
