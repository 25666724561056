<template>
  <div v-loading="lineItemLoading">
    <div v-if="currentResource.id">
      <portal to="page-title">
        {{ getLineItemTitle }},
        {{ $t('Job') }}
        <router-link :to="`/job-costing/jobs/${job.id}/view`">
          {{ job.number }}
          <template v-if="job.description">
            ({{ job.description }})
          </template>
        </router-link>
        <StatusBadge :status="currentResource?.attributes?.type"/>
      </portal>
      <div class="flex flex-wrap justify-between items-center mb-2">
        <layout-tabs :items="filteredItems"/>
        <div class="min-w-[300px] -mt-4">
          <LineItemSelect
              v-if="job?.id"
              :value="resourceId"
              :job-id="job?.id"
              :placeholder="$t('Line item')"
              :line-item-type="lineItemResourceType"
              :appliedToCostCenterDefaults="true"
              :label="$t('Line Item')"
              @change="onResourceChange"
          />
        </div>
      </div>
      <router-view v-loading="lineItemLoading && !isDetailsPage"/>
    </div>
  </div>
</template>
<script>
  import { composeLineItemName } from '@/utils/utils'
  import layoutPermissions from '@/mixins/layoutPermissions'
  import LineItemSelect from "@/components/select/entities/LineItemSelect.vue";
  import { jobLineItemTypes } from "@/enum/enums";
  import { globalResources } from "@/components/form/util";
  import detailScreen from "@/mixins/detailScreen";
  import DetailLayout from "@/modules/common/components/DetailLayout.vue";

  export default {
    components: { DetailLayout, LineItemSelect },
    mixins: [layoutPermissions, detailScreen],
    props: {
      items: {
        type: Array,
        default: () => [],
      },
    },
    computed: {
      lineItemResourceType() {
        return this.currentResource?.attributes?.type === jobLineItemTypes.Cost ? globalResources.CostLineItems : globalResources.IncomeLineItems
      },
      lineItemLoading() {
        return this.$store.state.jobCosting.lineItemLoading
      },
      currentResource() {
        return this.$store.state.jobCosting.currentLineItem || {}
      },
      job() {
        const { job } = this.currentResource?.relationships || {}
        return job.attributes
      },
      getPageTitle() {
        const { attributes } = this.currentResource || {}
        const mainTitle = composeLineItemName(attributes)

        return attributes.description ? `${mainTitle} (${attributes.description})` : mainTitle
      },
      getLineItemTitle() {
        return `${this.$t('Line Item')} ${this.getPageTitle}`
      },
    },
    methods: {
      async getCurrentResource(id) {
        await this.$store.dispatch('jobCosting/getLineItem', id)
      },
    },
  }
</script>
