<template>
  <BaseDataTable
    :columns="columns"
    :url="url"
    :add-entity-in-new-tab="addEntityInNewTab"
    :add-text="$t('New equipment class')"
    default-sort="code"
    import-url="/equipment/settings/classes/import"
    actions="search,refresh,add,import,export"
    entity="equipment-class"
    ref="table"
    permission="equipment_class"
    @add="$router.push('/equipment/settings/classes/add')"
  />
</template>
<script>

  export default {
    data() {
      return {
        url: '/restify/equipment-classes',
        addEntityInNewTab: true,
      }
    },
    computed: {
      columns() {
        return [
          {
            label: this.$t('Class'),
            prop: 'attributes.code',
            component: 'EntityLink',
            redirectTo: '/equipment/settings/classes/{ID}/view',
          },
          {
            label: this.$t('Description'),
            prop: 'attributes.description',
            minWidth: 150,
            maxWidth: 200,
          },
          {
            label: this.$t('Disposal Account'),
            prop: 'attributes.disposal_account',
          },
          {
            label: this.$t('Sub Account'),
            prop: 'attributes.disposal_subaccount',
          },
          {
            label: this.$t('Calculated Depreciation Account'),
            prop: 'attributes.calculated_depreciation_account',
          },
          {
            label: this.$t('Sub Account'),
            prop: 'attributes.calculated_depreciation_subaccount',
          },
        ]
      },
    },
  }
</script>
