<template>
  <BaseReportForm
    :filters="model"
    :report-number="reportNumber"
  >
    <template #filters>
      <BaseFilterRow :title="$t('Sort & Subtotal By')">
        <BaseSelect
          v-model="model.sort_by"
          :options="sortByOptions"
          class="col-span-4"
        />
      </BaseFilterRow>

      <EmployeeRangeFilter
        :title="$t('Employee Range')"
        v-model="model"
      />

      <BaseFilterRow :title="$t('Payment No. Range')">
        <BaseInput
          v-model="model.payment_number_from"
          :placeholder="$t('Payment No. From')"
          :name="$t('Payment No. From')"
          type="number"
          class="col-span-2"
          clearable
        />
        <BaseInput
          v-model="model.payment_number_to"
          :placeholder="$t('Payment No. To')"
          :name="$t('Payment No. To')"
          type="number"
          class="col-span-2"
          :rules="model.payment_number_from ? 'required' : ''"
          clearable
        />
      </BaseFilterRow>

      <PayrollDatesFilter
        v-model="model"
      />

    </template>

    <template #table="{ data, loading }">
      <PayrollPaymentRegisterReportTable
        :data="data"
        :filters="model"
        :data-loading="loading"
        :report-number="reportNumber"
        ref="reportTable"
      />
    </template>
  </BaseReportForm>
</template>
<script>
import PayrollPaymentRegisterReportTable from '@/modules/payroll/components/reports/payroll-payment-register/PayrollPaymentRegisterReportTable.vue'
import PayrollDatesFilter from '@/modules/payroll/components/reports/PayrollDatesFilter'
import { DepartmentRangeFilter } from '@/components/range-filters'
import { EmployeeRangeFilter } from '@/components/range-filters'
import { PayrollPaymentRegisterOptions } from '@/modules/payroll/components/reports/util'
import { globalResources } from "@/components/form/util";

export default {
  components: {
    EmployeeRangeFilter,
    DepartmentRangeFilter,
    PayrollPaymentRegisterReportTable,
    PayrollDatesFilter,
  },
  setup() {
    return {
      globalResources,
      PayrollPaymentRegisterOptions,
    }
  },
  data() {
    return {
      reportNumber: 4879,
      model: {

        sort_by: PayrollPaymentRegisterOptions.SortBy.PaymentNumber,
        employee_code_from: null,
        employee_code_to: null,

        payment_number_from: null,
        payment_number_to: null,

        select_by: PayrollPaymentRegisterOptions.SelectBy.PaymentDate,

        start_date: null,
        end_date: null,

        quarter_start: null,
        quarter_end: null,

        year_start: null,
        year_end: null,

        fiscal_year: null,
        period_start: null,
        period_end: null,

        batch_id: null,
      },
      sortByOptions: [
        {
          label: this.$t('Payment Number'),
          value: PayrollPaymentRegisterOptions.SortBy.PaymentNumber,
        },
        {
          label: this.$t('Employee'),
          value: PayrollPaymentRegisterOptions.SortBy.Employee,
        },
        {
          label: this.$t('Fiscal Periods'),
          value: PayrollPaymentRegisterOptions.SortBy.FiscalPeriods,
        },
        
      ],
      selectByOptions: [
        {
          label: this.$t('Payment Date'),
          value: PayrollPaymentRegisterOptions.SelectBy.PaymentDate,
        },
        {
          label: this.$t('Period End Date'),
          value: PayrollPaymentRegisterOptions.SelectBy.PeriodEndDate,
        },
        {
          label: this.$t('Calendar Quarter'),
          value: PayrollPaymentRegisterOptions.SelectBy.CalendarQuarter,
        },
        {
          label: this.$t('Fiscal Periods'),
          value: PayrollPaymentRegisterOptions.SelectBy.FiscalPeriods,
        },
        {
          label: this.$t('All Pay Periods'),
          value: PayrollPaymentRegisterOptions.SelectBy.AllPayPeriods,
        },
        {
          label: this.$t('Batch'),
          value: PayrollPaymentRegisterOptions.SelectBy.Batch,
        },
      ],
    }
  },
}
</script>
