<template>
  <base-button variant="gray-light"
               title="Delete"
               :tabindex="skipFocus ? -1 : 0"
               :class="{
                 'hidden md:flex': hideOnMobile,
                 'flex': !hideOnMobile,
               }"
               size="icon"
               class="table-action-button hidden md:flex"
               data-test="button-row-delete"
               type="button"
               @click="$emit('click')">
    <Trash2Icon class="w-4 h-4" />
  </base-button>
</template>
<script>
  import { Trash2Icon } from 'vue-feather-icons'

  export default {
    name: 'TableDeleteButton',
    components: {
      Trash2Icon,
    },
    props: {
      skipFocus: {
        type: Boolean,
        default: false,
      },
      hideOnMobile: {
        type: Boolean,
        default: true,
      }
    }
  }
</script>
