export function formatAccountName(account, asHtml = false, showLabelDescription = true) {
  const { number, description } = account

  if (!description || !showLabelDescription) {
    return number
  }

  if (asHtml) {
    return `<span>${number}</span> <span class="cell-description truncate">(${description})</span>`
  }

  return `${number} (${description})`
}

export function isFieldRequired(attrs) {
  const rules = attrs.rules || []
  return rules.includes('required')
}
