<template>
  <BaseSelect
      v-bind="$attrs"
      v-on="$listeners"
      :label="$attrs.label || $t('Print Format')"
      :options="printFormats"
  />
</template>
<script lang="ts" setup>

  import { computed } from 'vue'
  import { getPrintFormats } from "@/modules/accounts-receivable/utils/billingModels";
  import { billingTypes } from "@/modules/accounts-receivable/pages/billings/billings";

  const props = defineProps({
    billingType: {
      required: true,
      type: String,
      default: billingTypes.Progress,
    }
  })

  const printFormats = computed(() => {
    return getPrintFormats(props.billingType)
  })
</script>
