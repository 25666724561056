<template>
  <TimesheetEntryStartTime
    ref="select"
    v-focus
    v-bind="params"
    v-model="selectedValue"
    :value-key="params.valueKey"
    :data="undefined"
    inline-errors
    class="w-full"
    @blur="onBlur"
    @change="onChange"
    @keydown.enter.native="goToNextCell"
  />
</template>
<script>
import { selectEditorMixin } from "@/components/ag-grid/cellEditors/selectEditorMixin";
import TimesheetEntryStartTime from "@/modules/payroll/components/timesheets/TimesheetEntryStartTime.vue";

export default {
  name: 'TimesheetStartTimeCell',
  components: {
    TimesheetEntryStartTime
  },
  mixins: [selectEditorMixin],
  methods: {
    onChange(newValue) {
      this.selectedValue = newValue
      this.params.api.tabToNextCell()
    },
    onBlur() {
      this.params.api.tabToNextCell()
    },
    goToNextCell() {
      this.params.api.tabToNextCell()
    }
  }
}
</script>
