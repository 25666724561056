<template>
  <div>
    <BaseButton
      variant="primary"
      class="print:hidden send-email-button"
      v-bind="$attrs"
      @click="onSendClick"
    >
    <span class="flex items-center">
      <MailIcon class="w-4 h-4 mr-2"/>
      <span>
        {{ $t('Send Emails') }}
      </span>
    </span>
    </BaseButton>

    <BaseFormDialog
      v-if="showSendEmailDialog"
      :title="$t('Send Invoice via Email')"
      :open.sync="showSendEmailDialog"
      size="lg"
      @close="showSendEmailDialog = false"
    >
      <base-form
        v-bind="$attrs"
        :save-text="$t('Send')"
        :loading="loading"
        layout="modal"
        :submit-disabled="selectedRows?.length === 0"
        @cancel="$emit('cancel')"
        @submit="sendEmail"
      >

        <BaseSelect
          v-model="selectedTemplate"
          @change="onTemplateChange"
          :label="$t('Template')"
          :options="templateOptions"
          rules="required"
          value-key="id"
          label-key="name"
          class="col-span-6 md:col-span-3"
        />

        <div v-if="false" class="col-span-6">
          <BaseCheckbox
            v-model="model.include_pdf"
            :label="$t('Include a PDF version of the invoice')"
            id="include_pdf"
          />
        </div>

        <div class="col-span-6">
          <BaseCheckbox
            id="include_signature"
            v-model="model.include_signature"
          >
            <template #label>
              {{ $t('Print Signature') }}
              <template v-if="model.include_signature">
                {{ $t(' (if the bank has a signature, the signature will be included on each check)') }}
              </template>
            </template>
          </BaseCheckbox>
        </div>
        <div class="col-span-6">
          <BaseCheckbox
            id="include_direct_deposits"
            v-model="model.include_direct_deposits"
            :label="$t('Include Direct Deposits')"
          />
        </div>
        <div class="col-span-6 mb-4">
          <BaseCheckbox
            id="include_checks"
            v-model="model.include_checks"
            :label="$t('Include Checks')"
          />
        </div>

        <div class="col-span-6">
          <h4>
            {{ $t('Select Payments to Send') }}
          </h4>
          <div class="text-gray-600 text-sm mb-4">
            ({{ $t('When all selected, all payments with valid recipient email addresses will be sent') }})
          </div>
          <AgDataTable
            url="/restify/payrolls"
            :url-params="urlParams"
            :data="data"
            :columns="columns"
            :selected-rows.sync="selectedRows"
            domLayout="autoHeight"
          >
          </AgDataTable>
        </div>
      </base-form>
    </BaseFormDialog>
  </div>
</template>
<script lang="ts" setup>
import {computed, ref, set} from 'vue'
import {MailIcon} from 'vue-feather-icons'
import axios from 'axios'
import i18n from "@/i18n";
import {error} from '@/components/common/NotificationPlugin'
import {usePrintTemplates} from "@/components/form/composables/usePrintTemplates";
import {cellClasses} from "@/components/ag-grid/columnUtils";
import {useRoute} from "vue2-helpers/vue-router";
import {ValueSetterParams} from "@ag-grid-community/core";
import {validateEmail} from "@/modules/common/util/validators";
import get from "lodash/get";
import {Employee} from "@/modules/common/types/models";
import Data = API.Data;
import Cache from "@/utils/Cache";

const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  actionUrl: {
    type: String,
    required: true,
  },
  actionName: {
    type: String,
    required: true,
  },
})

const entity = ref('payroll-batch')
const {availableTemplates, selectedTemplate, onTemplateChange} = usePrintTemplates(entity)

const CheckTemplates = {
  Summary: 'Summary',
}

const detailedTemplateId = computed(() => {
  return availableTemplates.value.find((template: any) => template.name.includes('Detailed'))?.id
})

const templateOptions = computed(() => {
  return [
    {
      id: CheckTemplates.Summary,
      name: i18n.t('Summary'),
    },
    ...availableTemplates.value,
  ]
})

const showSendEmailDialog = ref(false)
const loading = ref(false)
const route = useRoute()

const urlParams = computed(() => {
  return {
    payroll_batch_id: route.params.id,
    related: 'employee',
  }
})

const model = ref({
  repositories: [] as string[],
  include_pdf: false, // Will be added later on
  include_signature: true,
  include_checks: false,
  include_direct_deposits: true,
  template_id: null,
})

const selectedRows = ref([])

async function onSendClick() {
  showSendEmailDialog.value = true
}

function hasEmail(row: Data<Employee>) {
  return !!get(row, 'relationships.employee.attributes.email')
}
const columns = computed(() => {
  const include_direct_deposits = model.value.include_direct_deposits
  const include_checks = model.value.include_checks
  return [
    {
      label: i18n.t('Employee'),
      prop: 'attributes.employee_id',
      component: 'EmployeeLink',
      maxWidth: 300,
      minWidth: 150,
      headerCheckboxSelection: true,
      checkboxSelection: (params: any) => {
        const row = params.data
        const {direct_deposit_pay, check_pay} = row?.attributes?.totals || {}
        if (direct_deposit_pay > 0 && !include_direct_deposits) {
          return false
        }
        if (check_pay > 0 && !include_checks) {
          return false
        }
        return hasEmail(row)
      },
    },
    {
      headerName: i18n.t('Check Pay'),
      field: 'attributes.totals.check_pay',
      component: 'FormattedPrice',
      minWidth: 100,
      maxWidth: 150,
    },
    {
      headerName: i18n.t('DD Pay'),
      field: 'attributes.totals.direct_deposit_pay',
      component: 'FormattedPrice',
      minWidth: 100,
      maxWidth: 150,
    },
    {
      headerName: i18n.t('Net Pay'),
      field: 'attributes.totals.net_pay',
      component: 'FormattedPrice',
      minWidth: 100,
      maxWidth: 150,
    },
    {
      headerName: i18n.t('Email'),
      field: 'relationships.employee.attributes.email',
      headerClass: cellClasses.HeaderEditable,
      editable: true,
      valueSetter: (params: ValueSetterParams) => {
        const row = params.data
        const employeeId = row.attributes.employee_id
        const value = params.newValue
        const isValidEmail = validateEmail(value)
        if (!isValidEmail) {
          error(i18n.t('Please provide a valid email'))
          return false
        }
        axios.patch(`/restify/employees/${employeeId}`, {
          email: value,
        }).then(() => {
          set(params.data?.relationships.employee.attributes, `email`, value)
          params.node?.setData(params.data)
        })
        return true
      }
    },
  ]
})


const emit = defineEmits(['save', 'cancel'])

function getRepositories() {
  return selectedRows.value.map((row: any) => row.id)
}

async function sendEmail() {
  try {
    loading.value = true
    let template_id = selectedTemplate.value
    let hide_payroll_items = false
    if (template_id === CheckTemplates.Summary) {
      template_id = detailedTemplateId.value
      hide_payroll_items = true
    }
    const data = {
      repositories: getRepositories(),
      include_pdf: model.value.include_pdf,
      include_signature: model.value.include_signature,
      print_checks: model.value.include_checks,
      print_dds: model.value.include_direct_deposits,
      hide_payroll_items,
      template_id,
    }
    await axios.post(props.actionUrl, data, {
      params: {
        action: props.actionName,
      }
    })
    // success(i18n.t('Emails are queued to be sent. The selected recipients will receive their email in a couple of minutes.'))
    showSendEmailDialog.value = false
    Cache.removeForEntity('/restify/payrolls')
    emit('save')
  } catch (err: any) {
    if (err.handled) {
      return
    }
    error(i18n.t('Could not send emails'))
  } finally {
    loading.value = false
  }
}
</script>
