import i18n from "@/i18n";
import { globalResources } from "@/components/form/util.js";
import { employeeRatesTypes } from "@/modules/payroll/components/rates/util.js";

export const sourceTypeConfigs = {
  [employeeRatesTypes.PayRates]: {
    cellTitle: 'Pay Code',
    resourceName: globalResources.PayCodes,
    sourceType: 'pay-code',
  },
  [employeeRatesTypes.DeductionRates]: {
    cellTitle: 'Deduction Code',
    resourceName: globalResources.DeductionCodes,
    sourceType: 'deduction-code',
  },
  [employeeRatesTypes.RetirementRates]: {
    cellTitle: 'Retirement Plan',
    resourceName: globalResources.RetirementPlans,
    sourceType: 'retirement-plan',
  },
  [employeeRatesTypes.BenefitRates]: {
    cellTitle: 'Benefit Code',
    resourceName: globalResources.BenefitCodes,
    sourceType: 'benefit-code',
  },
  [employeeRatesTypes.FlexibleBenefitRates]: {
    cellTitle: 'Flexible Benefit Plan',
    resourceName: globalResources.FlexibleBenefitPlans,
    sourceType: 'flexible-benefit-plan',
  },
}

export const deductionOverrideTypes = {
  Union: 'union',
  Job: 'job',
  Both: 'both',
}

export const deductionOverrideTypeOptions = [
  {
    label: i18n.t('Union'),
    value: deductionOverrideTypes.Union,
  },
  {
    label: i18n.t('Job'),
    value: deductionOverrideTypes.Job,
  },
  {
    label: i18n.t('Both'),
    value: deductionOverrideTypes.Both,
  },
]
