<template>
  <div class="grid grid-cols-6 gap-x-3 mt-4 time-card-batches">
    <div class="col-span-6 md:col-span-1">
      <base-select v-if="!isSelectedBatch"
                   v-model="value.year"
                   :options="availableYears"
                   :label="$t('Year')"
                   :name="$t('Year')"
                   id="year"
                   rules="required"
      />
      <base-input v-else
                  v-model="value.year"
                  :label="$t('Year')"
                  :name="$t('Year')"
                  :disabled="true"
      />
    </div>
    <div class="col-span-6 md:col-span-1">
      <period-end-date-picker v-focus v-model="value.period_end_date" :disabled="isSelectedBatch"/>
    </div>
    <div class="col-span-6 md:col-span-2">
      <base-input
        v-model="value.description"
        :label="$t('Name')"
        :placeholder="$t('Batch name (optional)')"
        :name="$t('Name')"
      />
    </div>
    <div class="col-span-6 md:col-span-1">
      <base-select v-model="value.pay_frequency"
                   :options="payFrequencyOptions"
                   :label="$t('Employee pay frequency')"
                   :placeholder="$t('Employee pay frequency')"
                   multiple
                   @change="onChangePayFrequencies"
      />
    </div>
    <div class="col-span-6 md:col-span-1">
      <base-checkbox v-model="value.include_salaried"
                     :vertical="true"
                     id="include_salaried"
                     class="mr-4"
      >
        <template #label>
          <span class="flex items-center">
            {{ $t('Show salary employees') }}
            <base-tooltip :content="$t('Display salary employees')">
              <IconInfo class="ml-2 cursor-help"/>
            </base-tooltip>
          </span>
        </template>
      </base-checkbox>
    </div>
    <div class="col-span-6 -mt-6">
      <AgDataTable
          :columns="columns"
          :url-params="urlParams"
          :selected-rows.sync="selectedRows"
          :is-row-selectable="isRowSelectable"
          actions="search,refresh"
          class="p-3"
          url="/restify/employees"
          domLayout="autoHeight"
          ref="table"
      >
        <template #additional-actions-before="{selectedRows}">
          <span class="text-gray-600" v-if="selectedRows.length">
            {{ $tc('employees selected', selectedRows.length) }}
          </span>
        </template>
        <template #attributes.code="{row}">
          <div class="flex items-center">
            <entity-preview :entity="row.attributes"
                            :key="`preview-${row.id}`"
                            :addresses="get(row, 'relationships.addresses', [])"
                            :url="`/payroll/employees/${row.id}/view`"
                            :value-to-display="get(row, 'attributes.code')"
                            :parent-entity-id="row.id"
                            entity-name="employee"
            />
            <BaseTooltip
                v-if="!isRowSelectable({ data: row })">
              <HelpCircleIcon class="w-4 h-4 ml-2 text-gray-500"/>
              <template #content>
                <div>
                  <div v-if="employeeHasTimeCard(row)">
                    <div>
                      {{ $t('This employee has an associated time card in the current batch.') }}
                    </div>
                    <div>
                      {{ $t('Selecting the employee will be disabled unless the time card is removed.') }}
                    </div>
                  </div>
                  <div v-else>
                    <div>
                      {{ $t('This employee is already present in another timecard.') }}
                    </div>
                    <div>{{
                        $t('Selecting the employee will be disabled unless the employee is removed from the other timecard, the timecard is posted or deleted.')
                      }}
                    </div>
                  </div>
                </div>
              </template>
            </BaseTooltip>
          </div>
        </template>
        <template #extra-actions="{row}">
          <router-link :to="`/payroll/employees/${row.id}/view`">
            <table-view-button/>
          </router-link>
        </template>
      </AgDataTable>
    </div>
  </div>
</template>
<script>
  import cloneDeep from 'lodash/cloneDeep'
  import { employeeStatuses, payFrequencies } from '@/enum/enums'
  import TableViewButton from '@/components/table/actions/TableViewButton'
  import PeriodEndDatePicker from "@/modules/payroll/components/timecard/PeriodEndDatePicker";
  import TableSelections from "@/components/ag-grid/TableSelections";
  import { HelpCircleIcon } from 'vue-feather-icons'

  export default {
    inheritAttrs: false,
    components: {
      PeriodEndDatePicker,
      TableViewButton,
      TableSelections,
      HelpCircleIcon,
    },
    props: {
      isSelectedBatch: {
        type: Boolean,
        default: false,
      },
      value: {
        type: Object,
        default: () => ({
          period_end_date: null,
          year: null,
          pay_frequency: [],
          include_salaried: false,
          selectedEmployees: [],
          description: '',
        }),
      },
    },
    data() {
      return {
        selectedRows: [],
        payFrequencyOptions: [
          {
            label: this.$t('Weekly'),
            value: payFrequencies.WEEKLY,
          },
          {
            label: this.$t('Bi-Weekly'),
            value: payFrequencies.BI_WEEKLY,
          },
          {
            label: this.$t('Monthly'),
            value: payFrequencies.MONTHLY,
          },
          {
            label: this.$t('Semi Monthly'),
            value: payFrequencies.SEMI_MONTHLY,
          },
        ],
        columns: [
          {
            label: this.$t('Code'),
            prop: 'attributes.code',
            headerCheckboxSelection: true,
            checkboxSelection: true,
          },
          {
            label: this.$t('Name'),
            prop: 'attributes.name',
          },
          {
            label: this.$t('Status'),
            prop: 'attributes.status',
            component: 'Status',
          },
          {
            label: this.$t('Pay Frequency'),
            prop: 'attributes.pay_frequency',
            component: 'Status',
          },
        ],
      }
    },
    computed: {
      payrollCalendarYear() {
        const currentYear = new Date().getFullYear()
        return this.$settings(this.$modules.PR, 'calendar_year') || currentYear
      },
      availableYears() {
        return [
          {
            label: this.payrollCalendarYear,
            value: this.payrollCalendarYear,
          },
          {
            label: this.payrollCalendarYear - 1,
            value: this.payrollCalendarYear - 1,
          },
        ]
      },
      urlParams() {
        let status = employeeStatuses.HOURLY
        if (this.value.include_salaried) {
          status = `${employeeStatuses.HOURLY},${employeeStatuses.SALARY}`
        }
        const pay_frequency = this.value.pay_frequency ? this.value.pay_frequency.join(',') : undefined
        const params =  {
          pay_frequency,
          status,
          sort: 'code',
          related: 'computableTimecards'
        }
        return params
      },
      payroll_period_start_day() {
        return this.$settings(this.$modules.PR, 'payroll_period_start_day')
      },
    },
    methods: {
      onChangePayFrequencies(frequencies) {
        this.value.include_salaried = !!frequencies.length
      },
      mapInitialSelections(employees) {
        if (employees.length !== this.selectedRows.length && employees.length) {
          this.selectedRows = cloneDeep(employees)
        }
      },
      isRowSelectable(params) {
        let employeeTimeCards = params.data?.relationships?.computableTimecards || []
        return employeeTimeCards.length === 0
      },
      employeeHasTimeCard(row) {
        let employeeTimeCards = row.relationships.computableTimecards || []
        employeeTimeCards = employeeTimeCards.map(t => t?.attributes?.timecard_batch_id)
        const timeCardBatchId = this.$route.params.id
        return employeeTimeCards.includes(timeCardBatchId)
      },
    },
    watch: {
      selectedRows() {
        if (this.value.selectedEmployees?.length && !this.selectedRows.length) {
          this.mapInitialSelections(this.value.selectedEmployees)
          return
        }
        this.value.selectedEmployees = this.selectedRows
      },
      'value.selectedEmployees': {
        immediate: true,
        handler(employees) {
          this.mapInitialSelections(employees)
        }
      }
    }
  }
</script>
