import { eachDayOfInterval, format, subDays } from 'date-fns'
import parseISO from "date-fns/parseISO";

const composeDayOptions = (interval, toFormat = 'i - ccc do') => {
  return interval.map((date) => ({
    label: format(date, toFormat),
    value: format(date, 'yyyy-MM-dd'),
  }))
}

export function getDatesFromRange(endDate, range = 7) {
  const end = parseISO(endDate)
  let start = subDays(end, range - 1)
  let interval = eachDayOfInterval({
    start,
    end,
  })
  return composeDayOptions(interval)
}

export function getPeriodEndDay(startDay) {
  if (startDay > 0) {
    return startDay - 1
  }
  return 6
}

export const timecardStatuses = {
  PENDING: 'pending',
  COMPUTED: 'computed',
  POSTED: 'posted',
  VOIDED: 'voided',
  ALL: 'all',
}

export const TABLE_ACTIONS = {
  //TODO: define actions for each timecard statuses
  [timecardStatuses.PENDING]: ['computeBulk'],
  [timecardStatuses.VOIDED]: [],
  [timecardStatuses.POSTED]: [],
  [timecardStatuses.ALL]: [],
}

function hasLoggedHours(row, gridInstance) {
  return gridInstance?.getDataAtRowProp(row, 'regular_hours') || gridInstance?.getDataAtRowProp(row, 'overtime_hours') || gridInstance?.getDataAtRowProp(row, 'premium_hours')
}

export function specialCodeValidator(value, callback, instance) {
  const gridInstance = instance || this.instance
  const sourceValue = gridInstance?.getDataAtRowProp(this.row, 'special_source_type') || !hasLoggedHours(this.row, gridInstance)

  if (typeof callback === 'function') {
    return callback(sourceValue ? !!value : true)
  }
  return sourceValue ? !!value : true
}

export function unitsValidator(value, callback, instance) {
  const gridInstance = instance || this.instance
  const sourceValue = gridInstance?.getDataAtRowProp(this.row, 'special_source_id')

  if (typeof callback === 'function') {
    return callback(sourceValue ? !!value : true)
  }
  return sourceValue ? !!value : true
}

export const payCategories = {
  GrossPay: 'gross-pay',
  EmployeeTaxes: 'employee-taxes',
  Deductions: 'deductions',
  Benefits: 'benefits',
  EmployerTaxes: 'employer-taxes',
  Insurance: 'insurance',
  NetPay: 'net-pay',
  NonPayroll: 'non-payroll',
}
