<template>
  <base-report-form
    :filters="model"
    :report-number="reportNumber"
    divider-class="col-span-6"
  >
    <template #filters>
      <base-filter-row
        :title="$t('Sort By')"
        class="col-span-6"
      >
        <base-select
          v-model="model.sort_by"
          :multiple-limit="3"
          :options="sortByOptions"
          :name="$t('Sort By')"
          rules="required"
          class="col-span-3"
          @change="onSortByChange"
        />

      </base-filter-row>
      <base-filter-row
        :title="$t('Format')"
        class="col-span-6"
      >
        <base-select
          v-model="model.format"
          :options="formatOptions"
          :name="$t('Format')"
          rules="required"
          class="col-span-3"
        />

      </base-filter-row>
      <VendorRangeFilter
        v-model="model"
        class="col-span-6"
      />
      <PurchaseOrderRangeFilter
        v-model="model"
        class="col-span-6"
      />
      <base-filter-row
        :title="$t('P.O. Date Range')"
        class="col-span-6"
      >
        <DateRangeFilter
          v-model="model"
          start-date-key="purchase_order_date_start"
          end-date-key="purchase_order_date_end"
          class="col-span-6"
        />
      </base-filter-row>
      <JobRangeFilter
        v-model="model"
        :disabled="[SortBy.WorkOrder].includes(model.sort_by)"
        class="col-span-6"
      />
      <WorkOrderRangeFilter
        v-model="model"
        :disabled="[SortBy.LineItem].includes(model.sort_by)"
        class="col-span-6"
      />
      <EquipmentRangeFilter
        v-model="model"
        :disabled="[SortBy.LineItem, SortBy.WorkOrder].includes(model.sort_by)"
        class="col-span-6"
      />

      <base-filter-row
        :title="$t('P.O. Status & Cost Center')"
        class="col-span-6"
      >
        <base-select
          v-model="model.po_status"
          :options="purchaseOrderStatusOptions"
          :name="$t('Purchase Order Status')"
          rules="required"
          class="col-span-3 md:col-span-2"
        />
        <cost-center-select
          v-model="model.cost_center"
          class="col-span-3 md:col-span-2"
        />
      </base-filter-row>

    </template>
    <template #table="{loading, data}">
      <component
        :is="tableComponent"
        :data="data"
        :data-loading="loading"
        :format="model.format"
        :filters="model"
        :report-number="reportNumber"
        ref="reportTable"
      />
    </template>
  </base-report-form>
</template>
<script>
import WorkOrderRangeFilter from "@/components/range-filters/WorkOrderRangeFilter.vue";
import VendorRangeFilter from "@/components/range-filters/VendorRangeFilter.vue";
import EquipmentRangeFilter from "@/components/range-filters/EquipmentRangeFilter.vue";
import PurchaseOrderRangeFilter from "@/components/range-filters/PurchaseOrderRangeFilter.vue";
import JobRangeFilter from "@/components/range-filters/JobRangeFilter.vue";
import DateRangeFilter from "@/components/range-filters/DateRangeFilter.vue";
import CostCenterSelect from "@/components/select/entities/CostCenterSelect.vue";
import PurchaseOrderListingReportTable
  from "@/modules/purchasing-inventory/components/reports/PurchaseOrderListingReportTable.vue";
import { SortBy } from "@/modules/purchasing-inventory/components/reports/purchaseListingOrderSortBy";
import PurchaseOrderListingLineItemsTable
  from "@/modules/purchasing-inventory/components/reports/PurchaseOrderListingLineItemsTable.vue";

const PoStatus = {
  Open: 'open',
  Closed: 'closed',
  All: 'all'
}

const Format = {
  Detail: 'detail',
  Summary: 'summary',
}

export default {
  components: {
    PurchaseOrderListingReportTable,
    PurchaseOrderListingLineItemsTable,
    CostCenterSelect,
    DateRangeFilter,
    JobRangeFilter,
    PurchaseOrderRangeFilter,
    EquipmentRangeFilter,
    VendorRangeFilter,
    WorkOrderRangeFilter,
  },
  data() {
    return {
      SortBy,
      model: {
        sort_by: SortBy.Vendor,
        po_status: PoStatus.Open,
        cost_center: null,
        format: Format.Summary,
        vendor_code_from: null,
        vendor_code_to: null,
        purchase_order_from: null,
        purchase_order_to: null,
        purchase_order_date_start: null,
        purchase_order_date_end: null,
        job_number_from: null,
        job_number_to: null,
        work_order_number_from: null,
        work_order_number_to: null,
        equipment_code_from: null,
        equipment_code_to: null,
      }
    }
  },
  computed: {
    tableComponent() {
      if (this.model.sort_by === SortBy.LineItem) {
        return 'PurchaseOrderListingLineItemsTable'
      }
      return 'PurchaseOrderListingReportTable'
    },
    sortByOptions() {
      return [
        {
          label: this.$t('Vendor'),
          value: SortBy.Vendor
        },
        {
          label: this.$t('Job'),
          value: SortBy.Job
        },
        {
          label: this.$t('Purchase Order'),
          value: SortBy.PurchaseOrder
        },
        {
          label: this.$t('Line Item'),
          value: SortBy.LineItem
        },
        {
          label: this.$t('Work Order'),
          value: SortBy.WorkOrder
        },
        {
          label: this.$t('Equipment'),
          value: SortBy.Equipment
        },
        {
          label: this.$t('Buyer'),
          value: SortBy.Buyer
        }
      ]
    },
    purchaseOrderStatusOptions() {
      return [
        {
          label: this.$t('Open'),
          value: PoStatus.Open
        },
        {
          label: this.$t('Closed'),
          value: PoStatus.Closed
        },
        {
          label: this.$t('All'),
          value: PoStatus.All
        }
      ]
    },
    onlySummaryFormat() {
      return [SortBy.Equipment, SortBy.WorkOrder, SortBy.Buyer].includes(this.model.sort_by)
    },
    onlyDetailFormat() {
      return [SortBy.LineItem].includes(this.model.sort_by)
    },
    formatOptions() {
      if (this.onlySummaryFormat) {
        return [
          {
            label: this.$t('Summary'),
            value: Format.Summary
          }
        ]
      } else if (this.onlyDetailFormat) {
        return [
          {
            label: this.$t('Detail'),
            value: Format.Detail
          }
        ]
      } else {
        return [
          {
            label: this.$t('Summary'),
            value: Format.Summary
          },
          {
            label: this.$t('Detail'),
            value: Format.Detail
          },
        ]
      }
    },
    reportNumber() {
      return '7871'
    },
  },
  methods: {
    async onSortByChange() {
      await this.$nextTick()
      if (this.onlyDetailFormat) {
        this.model.format = Format.Detail
        return
      }
      this.model.format = Format.Summary
    }
  }
}
</script>
