<template>

  <base-report-form
      :filters="model"
      :report-number="reportNumber"
  >

    <template #filters>
      <base-filter-row :title="$t('Format')">
        <base-select
            v-model="model.format"
            :options="formatOptions"
            :add-entity="false"
            class="col-span-3"
        />
      </base-filter-row>

      <common-report-filters v-model="model"/>

      <base-filter-row
          :title="$t('Include types')"
      >
        <base-select
            v-model="includeType"
            :add-entity="false"
            :options="includeTypeOptions"
            class="col-span-2"
            @change="onChangeIncludeType"
        />
        <base-select
            v-if="includeType !== includeTypes.All"
            v-model="model.job_type_ids"
            :add-entity="false"
            :options="getTypeOptions"
            :collapse-tags="true"
            value-key="id"
            label-key="abbr"
            class="col-span-2"
            multiple
        />
      </base-filter-row>

      <base-filter-row class="mb-3">
        <div class="col-span-3">
          <base-checkbox
              v-model="model.view_phase_totals"
              :label="$t('View Phase Totals')"
              id="view_phase_totals"
          />
          <base-checkbox
              v-model="model.skip_zero_values"
              :label="$t('Skip Rows with Zero Values')"
              id="skip_zero_values"
          />
        </div>
      </base-filter-row>

      <base-filter-row :title="$t('View')">
        <base-select
            v-model="model.total_by"
            :add-entity="false"
            :options="getPrintOptions"
            class="col-span-3"
        />
      </base-filter-row>

      <period-year-range-filter v-model="model"/>

    </template>

    <template #table="{loading, data}">

      <component
          :is="getTableComponentByFormat"
          :data="data"
          :filters="model"
          :report-number="reportNumber"
          :data-loading="loading"
          :total-by="model.total_by"
          :view-phase-totals="model.view_phase_totals"
          ref="reportTable"
          class="job-cost-detail"
      />

    </template>
  </base-report-form>

</template>
<script>
  import { PeriodYearRangeFilter } from '@/components/range-filters'
  import CommonReportFilters from '@/modules/job-costing/components/reports/CommonReportFilters'
  import { commonFilters, jobCostDetailPrintOptions } from '@/modules/job-costing/components/reports/util'
  import JobCostDetailByCostTypeTable
    from '@/modules/job-costing/components/reports/job-cost-detail/JobCostDetailByCostTypeTable'
  import JobCostDetailByLineItemTable
    from '@/modules/job-costing/components/reports/job-cost-detail/JobCostDetailByLineItemTable'

  const formatTypes = {
    ByLineItem: 'by-line-item',
    ByCostType: 'by-cost-type',
  }

  const includeTypes = {
    All: 'all',
    CostOnly: 'cost',
    IncomeOnly: 'income',
  }

  export default {
    components: {
      CommonReportFilters,
      PeriodYearRangeFilter,
      JobCostDetailByCostTypeTable,
      JobCostDetailByLineItemTable,
    },
    data() {
      const currentCalendarYear = new Date().getFullYear()
      return {
        includeTypes,
        reportNumber: '2881',
        includeType: includeTypes.All,
        formatOptions: [
          {
            label: this.$t('Detail By Line Item'),
            value: formatTypes.ByLineItem,
          },
          {
            label: this.$t('Detail By Cost Type'),
            value: formatTypes.ByCostType,
          },
        ],
        includeTypeOptions: [
          {
            label: this.$t('Cost And Income'),
            value: includeTypes.All,
          },
          {
            label: this.$t('Cost Only'),
            value: includeTypes.CostOnly,
          },
          {
            label: this.$t('Income Only'),
            value: includeTypes.IncomeOnly,
          },
        ],
        model: {
          ...commonFilters,
          format: formatTypes.ByLineItem,
          job_type_ids: [],
          total_by: jobCostDetailPrintOptions.HistoryAndBudgets,

          period_start: 0,
          fiscal_year_start: this.$currentYear || currentCalendarYear,
          period_end: this.$currentPeriod,
          fiscal_year_end: this.$currentYear || currentCalendarYear,

          view_phase_totals: false,
          skip_zero_values: false, // ?missing
        },
      }
    },
    computed: {
      getTableComponentByFormat() {
        const componentMap = {
          [formatTypes.ByCostType]: 'JobCostDetailByCostTypeTable',
          [formatTypes.ByLineItem]: 'JobCostDetailByLineItemTable',
        }

        return componentMap[this.model.format]
      },
      jobCostTypes() {
        return this.$store.state.globalLists[this.$globalResources.JobCostTypes]
      },
      jobIncomeTypes() {
        return this.$store.state.globalLists[this.$globalResources.JobIncomeTypes]
      },
      getTypeOptions() {
        return this.includeType === includeTypes.CostOnly ? this.jobCostTypes : this.jobIncomeTypes
      },
      getPrintOptions() {
        const commonOptions = [
          {
            label: this.$t('Job History & Budgets'),
            value: jobCostDetailPrintOptions.HistoryAndBudgets,
          },
          {
            label: this.$t('Line Item Totals Only'),
            value: jobCostDetailPrintOptions.LineItemTotals,
          },
          {
            label: this.$t('Job Page - Selected Jobs'),
            value: jobCostDetailPrintOptions.PageSelectedJobs,
          },
          {
            label: this.$t('Job Page - Period Active'),
            value: jobCostDetailPrintOptions.PagePeriodActive,
          },
        ]

        if (this.model.format === formatTypes.ByCostType) {
          return commonOptions
        }

        return [
          ...commonOptions,
          {
            label: this.$t('Job Summary - Selected Jobs'),
            value: jobCostDetailPrintOptions.SummarySelectedJobs,
          },
          {
            label: this.$t('Job Summary - Period Active'),
            value: jobCostDetailPrintOptions.SummaryPeriodActive,
          },
          {
            label: this.$t('Job History - No Budgets'),
            value: jobCostDetailPrintOptions.History,
          },
          {
            label: this.$t('Line Item & Phase Totals'),
            value: jobCostDetailPrintOptions.LineItemPhaseTotals,
          },
        ]
      },
    },
    methods: {
      onChangeIncludeType() {
        this.model.job_type_ids = []
      },
    },
  }
</script>
<style lang="scss">
  .job-cost-detail .table-wrapper .content-row td {
    @apply border-none;
  }
</style>
