<template>
  <base-form
      :loading="loading"
      :show-back="true"
      :save-text="$t('Compute')"
      grid-classes="grid grid-cols-8 gap-x-3"
      layout="vertical"
      @submit="onCompute"
      @cancel="$router.push('/equipment/depreciation/pending')"
  >
    <base-date-picker
        v-model="model.date"
        :label="$t('Compute Through Date')"
        class="col-span-8 md:col-span-2"
        rules="required"
    />
    <div class="col-span-8 md:col-span-6">
      <equipment-class-select
          v-model="model.class_ids"
          :add-entity="false"
          multiple
          rules="required"
      />
    </div>
  </base-form>
</template>
<script>
  import axios from 'axios'

  export default {
    data() {
      return {
        loading: false,
        model: {
          date: new Date(),
          class_ids: [],
          all_classes: false,
        },
      }
    },
    methods: {
      async onCompute() {
        try {
          this.loading = true
          await axios.post('/restify/equipment/actions?action=calculate-depreciation', {
            ...this.model,
            date: this.$formatDate(this.model.date, this.$dateTypes.IsoDate),
          })
          await this.$router.push('/equipment/depreciation/pending')
        } catch (err) {
          console.warn(err)
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not compute equipment depreciation'))
        } finally {
          this.loading = false
        }
      },
    },
  }
</script>
