<template>
  <BaseSelect
      v-bind="$attrs"
      v-on="$listeners"
      :inline-errors="true"
      :options="options">
  </BaseSelect>
</template>
<script>
  import { ReportIntervals } from "@/modules/accounts-receivable/utils/billingUtils";

  export default {
    name: 'ReportIntervalSelect',
    inheritAttrs: false,
    data() {
      return {
        ReportIntervals
      }
    },
    computed: {
      options() {
        return Object.keys(ReportIntervals).map(key => {
          return {
            value: ReportIntervals[key],
            label: key,
          }
        })
      }
    }
  }
</script>
<style>
</style>
