<template>
  <base-tooltip :content="$t('Download as Excel')">
    <base-button variant="white"
                 size="sm"
                 class="mt-1"
                 :loading="loading"
                 @click="download"
    >
      <slot></slot>
      <DownloadIcon class="w-5 h-5 text-green-500"/>
    </base-button>
  </base-tooltip>
</template>
<script>
  import axios from 'axios'
  import { DownloadIcon } from 'vue-feather-icons'

  export default {
    name: 'DownloadAction',
    components: {
      DownloadIcon
    },
    props: {
      url: {
        type: String,
        default: '',
      },
      entity: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        loading: false
      }
    },
    methods: {
      async download() {
        try {
          this.loading = true
          const data = await axios.get(this.url, {
            responseType: 'blob',
          })

          const formattedDate = this.$formatDate(new Date())
          const fileName = `${this.entity}-${formattedDate}`
          const file = `${fileName}.xlsx`

          const url = window.URL.createObjectURL(new window.Blob([data]))
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', file);
          document.body.appendChild(link);
          link.click();

          this.showImportDialog = false
          this.$success(this.$t('Data downloaded successfully!'))
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Something went wrong please try again.'))
        } finally {
          this.loading = false
        }
      }
    }
  }
</script>
<style>
</style>
