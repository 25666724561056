<template>
  <JournalForm
      v-if="journal.id"
      :data="journal"
      v-loading="journalLoading"
      @update="getJournal"/>
</template>
<script>
  import JournalForm from '@/modules/ledger/components/journals/JournalForm.vue'

  export default {
    components: {
      JournalForm,
    },
    computed: {
      journal() {
        return this.$store.state.generalLedger.currentJournal
      },
      journalLoading() {
        return this.$store.state.generalLedger.journalLoading
      },
    },
    methods: {
      async getJournal() {
        await this.$store.dispatch('generalLedger/getJournal', this.$route.params.id)
      },
    },
  }
</script>
