<template>
  <DeleteDialog
      v-if="showConfirmDialog"
      :title="title"
      :description="description"
      :delete-text="$t('Send')"
      :open="true"
      type="primary"
      @delete="onConfirmSendAction"
      @cancel="onConfirmRejectAction"
  >
    <slot></slot>
  </DeleteDialog>
</template>
<script>
  import DeleteDialog from "@/components/common/modal/DeleteDialog";

  export default {
    name: 'ConfirmEmailSendDialog',
    components: {
      DeleteDialog,
    },
    props: {
      title: {
        type: String,
        default: '',
      },
      description: {
        type: String,
        default: '',
      }
    },
    data() {
      return {
        showConfirmDialog: false,
      }
    },
    methods: {
      onConfirmSendAction() {
        this.showConfirmDialog = false
        if (this.confirmResolve) {
          this.confirmResolve(true)
        }
      },
      onConfirmRejectAction() {
        this.showConfirmDialog = false
        if (this.confirmResolve) {
          this.confirmResolve(false)
        }
      },
      send() {
        this.showConfirmDialog = true
        return new Promise(resolve => {
          this.confirmResolve = resolve
        })
      },
    }
  }
</script>
<style>
</style>
