<template>
  <div>
    <base-checkbox v-if="!showDropdown" :value="allSelected"
                   @input="selectRows($event)"/>
    <el-dropdown v-if="showDropdown"
                 :hide-on-click="false"
                 placement="bottom-start">
      <base-checkbox :value="allSelected || allPagesSelected" disabled/>
      <el-dropdown-menu>
        <el-dropdown-item @click.native.stop="onSelectCurrentRows">
          <div class="py-1"></div>
          <base-checkbox :value="allSelected"
                         @input="selectRows($event)">
            <template v-slot:label>
              {{ $t('Visible on this page') }} ({{ selectableCount }})
            </template>
          </base-checkbox>
          <div class="py-1"></div>
        </el-dropdown-item>
        <el-dropdown-item @click.native.stop="onAllPagesSelected">
          <div class="py-1"></div>
          <base-checkbox v-model="allPagesSelected">
            <template v-slot:label>
              {{ $t('All') }} ({{ total }})<br>
            </template>
          </base-checkbox>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
  import { Dropdown, DropdownMenu, DropdownItem } from 'element-ui'

  export default {
    components: {
      [Dropdown.name]: Dropdown,
      [DropdownMenu.name]: DropdownMenu,
      [DropdownItem.name]: DropdownItem,
    },
    props: {
      selectableRows: {
        type: Array,
        default: () => []
      },
      total: {
        type: Number,
        default: 0
      },
      showDropdown: {
        type: Boolean,
        default: true,
      },
      canSelect: {
        type: Function,
        default: () => true
      }
    },
    data() {
      return {
        allPagesSelected: false,
      }
    },
    computed: {
      allSelected() {
        return this.selectableRows.length && this.selectableRows.every(r => r.selected) && !this.allPagesSelected
      },
      selectableCount() {
        return this.selectableRows.filter(row => this.canSelect(row)).length
      }
    },
    methods: {
      onAllPagesSelected() {
        this.selectRows(!this.allPagesSelected)
        this.allPagesSelected = !this.allPagesSelected
        this.$emit('all-selected', this.allPagesSelected)
      },
      onSelectCurrentRows() {
        this.selectRows(!this.allSelected)
        this.allPagesSelected = false
        this.$emit('all-selected', false)
      },
      selectRows(value) {
        this.selectableRows.forEach(row => {
          if (!this.canSelect(row)) {
            return
          }
          this.$set(row, 'selected', value)
        })
      }
    }
  }
</script>
<style>
</style>
