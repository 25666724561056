<template>
  <div class="h-full flex flex-col">
    <layout-tabs :items="filteredItems"/>
    <router-view class="flex-1"/>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        items: [
          {
            name: this.$t('Craft Codes'),
            path: '/payroll/codes/craft-codes',
            permission: 'payroll_settings',
          },
          {
            name: this.$t('Pay Codes'),
            path: '/payroll/codes/pay-codes',
            permission: 'payroll_settings',
          },
          {
            name: this.$t('Benefit Codes'),
            path: '/payroll/codes/benefit-codes',
            permission: 'payroll_settings',
          },
          {
            name: this.$t('Flexible Benefit Plans'),
            path: '/payroll/codes/flexible-benefit-plans',
            permission: 'payroll_settings',
          },
          {
            name: this.$t('Deduction Codes'),
            path: '/payroll/codes/deduction-codes',
            permission: 'payroll_settings',
          },
          {
            name: this.$t('DD Codes'),
            path: '/payroll/codes/direct-deposit-codes',
            permission: 'payroll_settings',
          },
          {
            name: this.$t('Retirement Plans'),
            path: '/payroll/codes/retirement-plans',
            permission: 'payroll_settings',
          },
          {
            name: this.$t('Time Off'),
            path: '/payroll/codes/time-off',
            permission: 'payroll_settings',
          },
          {
            name: this.$t('Tax Codes'),
            path: '/payroll/codes/tax-codes',
            permission: 'payroll_settings',
          },
          {
            name: this.$t('OCIP Codes'),
            path: '/payroll/codes/ocip-codes',
            permission: 'payroll_settings',
          },
          {
            name: this.$t('Department Codes'),
            path: '/payroll/codes/department-codes',
            permission: 'payroll_settings',
          },
          {
            name: this.$t('W-2 Memo Codes'),
            path: '/payroll/codes/w2-memo-codes',
            permission: 'payroll_settings',
          },
        ],
      }
    },
    computed: {
      filteredItems() {
        const permissions = this.$store.getters['auth/permissions']
        return this.items.filter(item => permissions.includes(item.permission))
      },
    },
  }
</script>
