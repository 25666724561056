<template>
  <base-form-dialog v-bind="$attrs"
                    v-on="$listeners"
                    :title="$t(`Invoice for ${customer.name}`)"
                    :has-padding="true"
                    size="lg"
                    class="check-modal"
  >
    <div class="flex justify-center">
      <h4 class="font-medium">
        <template v-if="data.template === 'annual-maintenance'">
          {{ $t('Annual Maintenance') }}
        </template>
        <template v-if="data.template === 'regular-invoice'">
          {{ $t('Regular Invoice') }}
        </template>
      </h4>
    </div>
    <div class="prose flex flex-col w-full max-w-full">
      <div class="flex justify-between items-end w-full">
        <div>
          <base-tooltip :content="$t('Company')">
            <div class="flex items-center">
              <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-cool-gray-400" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd"
                      d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
                      clip-rule="evenodd"></path>
              </svg>
              <h5 class="text-gray-700">{{ company.name || '' }}</h5>
            </div>
          </base-tooltip>
          <div v-if="company.address_1" class="flex text-xs leading-5 text-cool-gray-500 font-medium">
            <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-cool-gray-400" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd"
                    d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
                    clip-rule="evenodd"></path>
            </svg>
            <span>{{ company.address_1 }}</span>
            <span>{{ company.city }}, {{ company.state }}</span>
          </div>
        </div>
        <div class="flex flex-col items-end">
          <h4>{{ data.no }}</h4>
          <time class="whitespace-no-wrap text-sm leading-5 text-cool-gray-500">
            {{ $formatDate(data.date) }}
          </time>
        </div>
      </div>
      <hr>
      <div class="flex justify-between">
        <div class="flex w-full text-sm leading-5 text-cool-gray-500 font-medium">
          <div>
            <base-tooltip :content="$t('Customer')">
              <div class="flex items-center">
                <svg class="flex-shrink-0 mr-1.5 mb-2 h-5 w-5 text-cool-gray-400" xmlns="http://www.w3.org/2000/svg"
                     fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                </svg>
                <span class="text-cool-gray-700 mb-2">{{ customer.name || '' }}</span>
              </div>
            </base-tooltip>
            <dd v-if="customer.address_1" class="flex items-center capitalize sm:mr-6 mt-1">
              <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-cool-gray-400" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd"
                      d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
                      clip-rule="evenodd"></path>
              </svg>
              {{ customer.address_1 }}, {{ customer.city }}, {{ customer.state }} {{ customer.zip_code }}
            </dd>
          </div>
        </div>
      </div>
      <hr>
    </div>

    <div class="overflow-hidden sm:rounded-lg w-full mt-2">
      <div class="border-b border-gray-200 text-center pb-3">
        <p class="mt-1 text-center text-sm leading-5 text-gray-500">
          {{ $t('Summary') }}
        </p>
      </div>
    </div>
    <div class="flex mt-6 space-x-4">
      <BaseDataTable :columns="summaryColumns"
                     :data="summaryData"
                     :show-pagination="false"
                     :key="summaryData.length"
                     class="flex-1 max-w-full"/>
    </div>
    <div class="overflow-hidden sm:rounded-lg w-full">
      <div class="border-b border-gray-200 text-center py-3">
        <p class="mt-1 text-center text-sm leading-5 text-gray-500">
          {{ $t('Invoice Items ') }}
        </p>
      </div>
    </div>
    <div class="flex mt-6 space-x-4">
      <BaseDataTable :columns="itemColumns"
                     :data="data.items"
                     :show-pagination="false"
                     class="flex-1 max-w-full">
        <template v-slot:description="{row}">
          <base-tooltip
              v-if="row.formatted_reference_date"
              :content="`${row.formatted_reference_date} ${row.item_description1 || ''}`"
          >
            <div class="truncate">
              {{ row.formatted_reference_date }} {{ row.item_description1 || '' }}
            </div>
          </base-tooltip>
          <base-tooltip
              v-else
              :content="row.service_description || row.item_description1 || ''"
          >
            <div class="truncate">
              {{ row.service_description || row.item_description1 || '' }}
            </div>
          </base-tooltip>
        </template>
        <template v-slot:quantity="{row}">
          <template v-if="data.template === 'regular-invoice'">
            {{ row.quantity }} ({{ row.units_of_measure }}) x {{ $formatPrice(row.unit_price) }}
          </template>
          <template v-if="data.template === 'annual-maintenance'">
            1
          </template>
        </template>
      </BaseDataTable>
    </div>
    <FileAttachments
        :entity-id="data.id"
        class="mt-2"
        entity="service-billing-invoices"
    />
  </base-form-dialog>
</template>
<script>
  export default {
    props: {
      data: {
        type: Object,
        default: () => ({})
      },
      customer: {
        type: Object,
        default: () => ({})
      },
      company: {
        type: Object,
        default: () => ({})
      },
    },
    data() {
      return {
        itemColumns: [
          {
            label: this.$t('Code'),
            prop: 'item_code',
            maxWidth: 150,
          },
          {
            label: this.$t('Date'),
            prop: 'iso_reference_date',
            maxWidth: 150,
            minWidth: 120,
          },
          {
            label: this.$t('Description'),
            prop: 'description',
            maxWidth: 150,
            minWidth: 120,
          },
          {
            label: this.$t('Quantity'),
            prop: 'quantity',
            maxWidth: 150,
            minWidth: 120,
          },
          {
            label: this.$t('Amount'),
            prop: 'extented_amount',
            maxWidth: 150,
            minWidth: 120,
            component: 'FormattedPrice'
          },
        ],

      }
    },
    computed: {
      summaryData() {
        const data = this.data.total
        let prevAmount = this.data.customer_data.posted_ar_amount || '0.00'
        prevAmount = prevAmount.replace(/,/g, '')

        return [{
          SubTotal: data.subtotal,
          Total: data.total,
          Tax: data.tax,
          Due: data.due,
          PrevAmount: prevAmount
        }]
      },
      summaryColumns() {
        const columns = [
          {
            label: this.$t('Subtotal'),
            prop: 'SubTotal',
            maxWidth: 150,
            minWidth: 120,
            component: 'FormattedPrice'
          },
          {
            label: this.$t('Tax'),
            prop: 'Tax',
            maxWidth: 150,
            minWidth: 120,
            component: 'FormattedPrice'
          },
          {
            label: this.$t('Total'),
            prop: 'Total',
            maxWidth: 150,
            minWidth: 120,
            component: 'FormattedPrice',
          },
        ]
        if (this.data.template === 'annual-maintenance') {
          columns[columns.length - 1].params = {
            classes: 'font-semibold'
          }
        }
        if (this.data.template === 'regular-invoice') {
          const extraColumns = [
            {
              label: this.$t('Prev. Amount'),
              prop: 'PrevAmount',
              maxWidth: 150,
              minWidth: 120,
              component: 'FormattedPrice',
            },
            {

              label: this.$t('Due'),
              prop: 'Due',
              maxWidth: 150,
              minWidth: 120,
              component: 'FormattedPrice',
              params: {
                classes: 'font-semibold'
              }
            }]
          columns.push(...extraColumns)
        }
        return columns
      }
    },
    methods: {
      getTotal(arr, field) {
        let sum = 0;
        arr.forEach(entry => {
          sum += +entry[field] || 0
        })
        return sum
      },
      getNetPay() {
        const deposits = this.data?.deposit || []
        let sum = 0;
        deposits.forEach(deposit => {
          Object.keys(deposit).map(key => {
            sum += parseFloat(deposit[key]);
          })
        })
        return sum
      }
    }
  }
</script>
<style scoped>
  .check-modal .prose hr {
    @apply my-4;
  }

  .check-modal .status-badge {
    width: 130px;
  }
</style>
