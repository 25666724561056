<template>
  <LineItemLayout :items="items"/>
</template>
<script>
  import LineItemLayout from '@/modules/job-costing/layout/LineItemLayout'

  export default {
    components: {
      LineItemLayout,
    },
    computed: {
      items() {
        return [
          {
            name: this.$t('Details'),
            path: `/job-costing/cost-line-items/${this.$route.params.id}/view`,
            authorizedTo: 'authorizedToShow',
          },
          {
            name: this.$t('Edit'),
            path: `/job-costing/cost-line-items/${this.$route.params.id}/edit`,
            authorizedTo: 'authorizedToUpdate',
          },
          {
            name: this.$t('Update Progress'),
            path: `/job-costing/cost-line-items/${this.$route.params.id}/update-progress`,
            authorizedTo: 'authorizedToShow',
          },
          {
            name: this.$t('Summary & Activity'),
            path: `/job-costing/cost-line-items/${this.$route.params.id}/summary`,
            authorizedTo: 'authorizedToShow',
          },
          {
            name: this.$t('Documents'),
            path: `/job-costing/cost-line-items/${this.$route.params.id}/documents`,
            authorizedTo: 'authorizedToShow',
            permission: 'line_item_documents_show'
          },
          {
            name: this.$t('Timesheets'),
            path: `/job-costing/cost-line-items/${this.$route.params.id}/timesheets`,
            authorizedTo: 'authorizedToShow',
            permission: 'timesheets_approve'
          },
          {
            name: this.$t('Transactions'),
            path: `/job-costing/cost-line-items/${this.$route.params.id}/transactions`,
            authorizedTo: 'authorizedToShow',
          },
        ]
      }
    }
  }
</script>
