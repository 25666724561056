import i18n from "@/i18n";
import {
  accountPayable,
  accountReceivable,
  equipmentManagement,
  estimating,
  jobCosting,
  ledger,
  payroll,
  purchasingAndInventory,
  serviceBilling,
  settings,
} from '@/modules/dashboard/enum/menus'

export default [
  {
    name: i18n.t('General Ledger'),
    abbr: i18n.t('GL'),
    path: '/ledger',
    icon: 'IconGeneralLedger',
    permission: ['general_ledger_show'],
    requiresCompany: true,
    submenus: [...ledger],
  },
  {
    name: i18n.t('Job Costing'),
    abbr: i18n.t('JC'),
    path: '/job-costing',
    icon: 'IconJobCosting',
    permission: ['job_costing_show'],
    requiresCompany: true,
    submenus: [...jobCosting],
  },
  {
    name: i18n.t('Payroll & Invoicing'),
    path: '/',
    group: true,
    productionReady: true,
    permission: ['accounts_payable_show'],
  },
  {
    name: i18n.t('Accounts Payable'),
    abbr: i18n.t('AP'),
    path: '/accounts-payable',
    icon: 'IconAccountsPayable',
    permission: ['accounts_payable_show'],
    requiresCompany: true,
    submenus: [...accountPayable],
  },
  {
    name: i18n.t('Payroll'),
    abbr: i18n.t('PR'),
    path: '/payroll',
    icon: 'IconPayroll',
    permission: ['payroll_show'],
    requiresCompany: true,
    productionReady: true,
    submenus: [...payroll],
  },
  {
    name: i18n.t('Accounts Receivable'),
    abbr: i18n.t('AR'),
    path: '/accounts-receivable',
    icon: 'IconAccountsReceivable',
    permission: ['accounts_receivable_show'],
    requiresCompany: true,
    productionReady: true,
    submenus: [...accountReceivable],
  },
  {
    name: i18n.t('Service Billing'),
    abbr: i18n.t('SB'),
    path: '/service-billing',
    icon: 'IconServiceBilling',
    permission: ['service_billing_show'],
    requiresCompany: true,
    productionReady: true,
    submenus: [...serviceBilling],
  },
  {
    name: i18n.t('Misc'),
    path: '/',
    group: true,
    productionReady: true,
    permission: ['equipment_management_show'],
  },
  {
    name: i18n.t('Equipment Management'),
    abbr: i18n.t('EQ'),
    path: '/equipment',
    icon: 'IconEquipment',
    permission: ['equipment_management_show'],
    requiresCompany: true,
    submenus: [...equipmentManagement],
  },
  {
    name: i18n.t('Purchasing & Inventory'),
    abbr: i18n.t('IV'),
    path: '/purchasing-inventory',
    icon: 'IconWarehouse',
    permission: ['inventory_management_show'],
    requiresCompany: true,
    submenus: [...purchasingAndInventory],
  },
  {
    name: i18n.t('Estimating'),
    abbr: i18n.t('ES'),
    path: '/estimates',
    icon: 'IconEstimation',
    permission: ['estimating_show'],
    requiresCompany: true,
    submenus: [...estimating],
  },
  {
    name: i18n.t('Document Management'),
    abbr: i18n.t('DM'),
    path: '/documents',
    icon: 'IconDocumentManagement',
    permission: ['document_management_show'],
    requiresCompany: true,
  },
  {
    name: i18n.t('Settings'),
    abbr: i18n.t('ST'),
    path: '/settings',
    icon: 'IconSpecialTasks',
    permission: ['settings_show'],
    productionReady: true,
    submenus: [...settings],
  },
]
