<template>
  <BaseCard class="border">
    <div class="text-sm flex flex-col space-y-4">
      <div
        :title="$t('Email')"
        class="detail-row">
        <MailIcon class="detail-icon"/>
        <BaseEmailLink :value="vendorData.email"/>
      </div>
      <div :title="$t('Phone')"
           class="detail-row">
        <PhoneIcon class="detail-icon"/>
        <BasePhoneLink :value="vendorData.phone"/>
      </div>
      <div :title="$t('Address')"
           class="detail-row">
        <MapPinIcon class="detail-icon"/>
        <BaseAddressLink :value="props.vendor?.relationships?.address"/>
      </div>
    </div>
    <div class="flex space-x-4 border-t border-gray-100 mt-4 -mb-4"
    >
      <EmailButton :value="vendorData.email"/>
      <PhoneButton :value="vendorData.phone"/>
      <AddressButton :value="location"/>
    </div>
  </BaseCard>
</template>
<script lang="ts" setup>
import { computed, PropType } from 'vue'
import { locationLink } from '@/utils/utils'
import { Vendor } from '@/modules/common/types/models'
import { MailIcon, MapPinIcon, PhoneIcon } from 'vue-feather-icons'
import EmailButton from '@/modules/common/components/buttons/EmailButton.vue'
import PhoneButton from '@/modules/common/components/buttons/PhoneButton.vue'
import AddressButton from '@/modules/common/components/buttons/AddressButton.vue'
import Data = API.Data

const props = defineProps({
  vendor: {
    type: Object as PropType<Data<Vendor>>,
    default: () => ({}),
    required: true,
  }
})

const vendorData = computed<Vendor>(() => {
  return props.vendor?.attributes || {}
})

const location = computed(() => {
  return locationLink(props.vendor?.relationships?.address)
})
</script>
<style lang="scss" scoped>
.detail-icon {
  @apply h-4 w-4 text-primary-500 min-w-[16px];
}

.detail-row {
  @apply flex space-x-3 items-center;
}
</style>
