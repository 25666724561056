<template>
  <div class="print:hidden">
    <base-tooltip :content="tooltipContent">
      <base-button variant="primary-link"
                   class="quick-pay-button"
                   :disabled="!isOpen"
                   @click="showQuickPayDialog = true"
      >
        <div class="flex items-center">
          <CheckSquareIcon class="w-5 h-5 mr-2"></CheckSquareIcon>
          <span>
          <slot>{{ $t('Quick Pay') }}</slot>
        </span>
        </div>
      </base-button>
    </base-tooltip>
    <PaymentsCreateDialog
        v-if="showQuickPayDialog"
        :open.sync="showQuickPayDialog"
        :selections="paySelections"
        :show-switches="false"
        :show-print="false"
        :title="$t(`Quick Pay Invoice ${invoice?.attributes?.number}`)"
        @save-success="$emit('save-success')"
    />
  </div>
</template>
<script>
  import { emptyCheck } from "@/modules/accounts-payable/components/invoice/emptyCheck";
  import { CheckSquareIcon } from 'vue-feather-icons'
  import PaymentsCreateDialog from "@/modules/accounts-payable/components/checks/PaymentsCreateDialog";
  import { getPaymentType } from "@/modules/accounts-payable/components/invoice/paymentUtils";

  export default {
    components: {
      CheckSquareIcon,
      PaymentsCreateDialog,
    },
    props: {
      invoice: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        showQuickPayDialog: false,
      }
    },
    computed: {
      tooltipContent() {
        return !this.isOpen
            ? this.$t('Quick pay is available only for open invoices')
            : this.$t('You can quickly create a payment for the invoice. Note that special payments are available only from the payments menu');
      },
      isOpen() {
        return this.invoice?.attributes?.status === 'posted'
      },
      paySelections() {
        const check = {
          ...emptyCheck,
          id: this.invoice?.id,
          amount: this.invoice?.attributes?.open_amount,
          discount_amount: this.invoice?.attributes?.discount_amount,
          type: getPaymentType(this.invoice?.relationships?.vendor)
        }

        return {
          [this.invoice?.id]: {
            paid_amount: this.invoice?.attributes?.open_amount,
            vendor_id: this.invoice?.attributes?.vendor_id,
            print_status: this.invoice?.print_status,
            rows: [{ ...check }]
          }
        }
      }
    }
  }
</script>
<style scoped>
  .quick-pay-button .btn {
    @apply px-0;
  }
</style>
