<template>
  <div class="payroll-batch-employee-summary">
    <PayrollPreviewDialog
      v-if="showPreviewDialog"
      :open.sync="showPreviewDialog"
      :data="rowToPreview"
    >
      <PayrollLineItems
        :data="rowToPreview"
      />
    </PayrollPreviewDialog>

    <BaseDataTable
      id="payroll-batch-table"
      :url="url"
      actions="search,refresh"
      hide-actions="print"
      default-sort="employees.code"
      :is-report-table="true"
      :per-page="perPage"
      :columns="columns"
      :is-expandable="true"
      :can-manage-columns="false"
      :limit-max-height="false"
      :expand-all-rows="toggleTableExpand"
      :compact="true"
      :row-classes="rowClasses"
      :data-map-function="mapData"
      :title="$t('Employee Summary')"
      ref="table"
    >
      <template v-slot:additional-actions-before>
        <div class="mr-2 space-x-2 flex items-center">
          <base-switch v-model="toggleTableExpand"
                       :label-info="$t('Toggle row details')"
                       inline
          />
        </div>
      </template>
      <template v-slot:extra-actions="{row}">
        <table-view-button @click="onViewPayroll(row)"/>
        <div v-if="isPosted(row)">
          <DownloadChecksDialog
            :payroll="row"
            :batch="{
              id: row.payroll?.payroll_batch_id
            }"
          />
        </div>
        <base-button
          variant="gray-light"
          title="View Timecard"
          size="icon"
          class="table-action-button"
          :loading="row.timeCardLoading"
          @click="viewTimeCard(row)"
        >
          <ClockIcon class="w-4 h-4"/>
        </base-button>
      </template>
      <template v-slot:expand-content="{row}">
        <div class="pb-8">
          <div v-if="row.originalRow?.employee.has_pre_note"
               class="!text-red-500 text-sm font-medium pl-4 print:pl-0 pt-2">
            {{
              $t('Employee has Direct Deposit set up as Pre Notice. This might affect their Direct Deposit payment for this payroll.')
            }}
          </div>
          <div v-if="row.originalRow?.payroll.was_adjusted"
               class="text-sm pl-4 print:pl-0 pt-1"
          >
            {{ $t('* This payroll was manually adjusted') }}
          </div>
          <PayrollLineItems
            :data="row.originalRow"
            class="m-2 print:-mx-2"
          />
        </div>
      </template>
      <template #period_end_date="{row}">
        <div class="flex items-center w-full justify-between space-x-2">
          <router-link :to="`/payroll/batches/${row.payroll.payroll_batch_id}`">
            {{ $formatDate(row.payroll.period_end_date) }}
          </router-link>
          <StatusBadge v-if="isPosted" :status="row.payroll.status"/>
        </div>
      </template>
      <template #payroll.payment_number="{row}">
        <div class="flex justify-between">
          <PaymentTypeIcon :type="row.payroll.type" class="w-4 h-4"/>
          <span>{{ row.payroll.payment_number }}</span>
        </div>
      </template>
      <template v-slot:employee.name="{row}">
        <div class="flex items-center w-full justify-between space-x-2">
          <EmployeeLink
            :data="get(row, 'employee')"
            :is-payroll-preview="true"
          />
          <StatusBadge v-if="isPosted" :status="row.payroll.status"/>
        </div>
      </template>
    </BaseDataTable>

  </div>
</template>
<script>
import PayrollLineItems from "@/modules/payroll/components/payroll/PayrollLineItems.vue";
import { resourceStatuses } from "@/enum/enums";
import PayrollPreviewDialog from "@/modules/payroll/components/payroll/PayrollPreviewDialog.vue";
import orderBy from "lodash/orderBy";
import DownloadChecksDialog from "@/modules/payroll/components/payroll/DownloadChecksDialog.vue";
import PaymentTypeIcon from "@/modules/payroll/components/payroll/PaymentTypeIcon.vue";
import { EyeIcon, ClockIcon } from "vue-feather-icons";
import axios from "axios";

export default {
  components: {
    EyeIcon,
    ClockIcon,
    PaymentTypeIcon,
    DownloadChecksDialog,
    PayrollPreviewDialog,
    PayrollLineItems,
  },
  props: {
    forEmployee: {
      type: Boolean,
      default: false,
    },
    expand: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showPreviewDialog: false,
      rowToPreview: null,
      toggleTableExpand: this.expand,
      perPage: 200,
      resourceStatuses
    }
  },
  computed: {
    url() {
      const routeId = this.$route.params.id
      if (this.forEmployee) {
        return `/restify/employees/${routeId}/payroll-details`
      }
      return `/restify/payroll-batches/${routeId}/details`
    },
    columns() {
      let firstColumn = {
        label: this.$t('Employee'),
        prop: 'employee.name',
      }
      if (this.forEmployee) {
        firstColumn = {
          label: this.$t('Period End Date'),
          prop: 'period_end_date',
        }
      }
      return [
        firstColumn,
        {
          label: this.$t('Payment Number'),
          prop: 'payroll.payment_number',
          align: 'right',
          maxWidth: 95,
        },
        {
          label: this.$t('Payment Date'),
          prop: 'payroll.payment_date',
          align: 'center',
          component: 'FormattedDate',
        },
        {
          label: this.$t('Gross Pay'),
          prop: 'payroll.totals.gross_pay',
          component: 'FormattedPrice',
          align: 'right',
        },
        {
          label: this.$t('Hours'),
          prop: 'payroll.totals.hours',
          align: 'right',
        },
        {
          label: this.$t('Tax Deduct'),
          prop: 'payroll.totals.employee_taxes',
          component: 'FormattedPrice',
          align: 'right',
        },
        {
          label: this.$t('Other Deduct'),
          prop: 'payroll.totals.other_deductions',
          component: 'FormattedPrice',
          align: 'right',
        },
        {
          label: this.$t('Benefits'),
          prop: 'payroll.totals.benefits',
          component: 'FormattedPrice',
          align: 'right',
        },
        {
          label: this.$t('Employer Taxes'),
          prop: 'payroll.totals.employer_taxes',
          component: 'FormattedPrice',
          align: 'right',
        },
        {
          label: this.$t('Net Pay'),
          prop: 'payroll.totals.net_pay',
          component: 'FormattedPrice',
          align: 'right',
        },
      ]
    }
  },
  methods: {
    rowClasses(row) {
      if (!row.expandable && this.toggleTableExpand) {
        return 'bg-gray-100 print:bg-white'
      }
      return ''
    },
    refresh() {
      this.$refs.table?.refresh()
    },
    mapData(data) {
      this.$emit('data-loaded', data)
      if (this.forEmployee) {
        return orderBy(data, 'payroll.period_end_date', 'desc')
      }
      return data
    },
    onViewPayroll(row) {
      this.rowToPreview = row
      this.showPreviewDialog = true
    },
    async viewTimeCard(row) {
      try {
        this.$set(row, 'timeCardLoading', true)
        const timeCardId = row.payroll.timecard_id
        if (!timeCardId) {
          this.$error(this.$t('No timecard found for this payroll'))
          return
        }
        const { data } = await axios.get(`/restify/timecards/${timeCardId}`)
        const batchId = data?.attributes?.timecard_batch_id
        await this.$router.push(`/payroll/timecard-batches/${batchId}/timecards/${timeCardId}/details`)
      } finally {
        this.$set(row, 'timeCardLoading', false)
      }
    },
    isPosted(row) {
      return row.payroll?.status === resourceStatuses.Posted
    },
  }
}
</script>
<style lang="scss">
.payroll-batch-employee-summary {
  @media print {
    .expandable-row td {
      height: 30px;
    }
  }

  .expanded-row {
    @apply break-inside-avoid;
  }
}
</style>
