<template>
  <base-report-form
      :filters="model"
      :report-number="reportNumber"
      :is-print="isPrint"
  >
    <template #filters>

      <base-filter-row
          :title="$t('Quarter')"
          classes="col-span-2"
      >
        <base-select
            v-model="model.quarter"
            :add-entity="false"
            :options="quarterOptions"
            class="col-span-1"
        />

      </base-filter-row>

      <base-filter-row
          :title="$t('Format')"
          classes="col-span-2"
      >
        <base-select
            v-model="reportNumber"
            :add-entity="false"
            :options="reportOptions"
            class="col-span-3"
        />

      </base-filter-row>

      <base-filter-row
          :title="$t('Print 12th Employee Listing')"
          classes="col-span-2"
      >
        <base-switch
            v-model="model.include_12th_month_employee_listing"
            class="col-span-3"
        />

      </base-filter-row>
      <template v-if="reportNumber === ReportTypes['941Form']">
        <base-filter-row
            :title="$t('Semiweekly Depositor')"
            classes="col-span-2"
        >
          <base-switch
              v-model="model.semiweekly_depositor"
              class="col-span-2"
          />
        </base-filter-row>

        <base-filter-row
            :title="$t('Total Quarter Deposits')"
            classes="col-span-2"
        >
          <base-input
              v-model="model.total_quarter_deposits"
              :name="$t('Total Quarter Deposits')"
              type="number"
              format="price"
              rules="required"
              class="col-span-3"
          />
        </base-filter-row>

        <base-filter-row
            :title="$t('Adjustment For Sick Pay')"
            classes="col-span-2"
        >
          <base-input
              v-model="model.adjustment_sick_pay"
              :name="$t('Adjustment For Sick Pay')"
              type="number"
              format="price"
              rules="required|min:0"
              class="col-span-3"
          />
        </base-filter-row>
        <base-filter-row
            :title="$t('Adjustment For Tips and Group Term Life Insurance')"
            classes="col-span-2"
        >
          <base-input
              v-model="model.adjustments_tips_and_group_term_life_insurance"
              :name="$t('Adjustment For Group Term Life Insurance')"
              format="price"
              type="number"
              rules="required|min:0"
              class="col-span-3"
          />
        </base-filter-row>
        <base-filter-row
            :title="$t('Overpayment Option')"
            classes="col-span-2"
        >
          <base-select
              v-model="model.overpayment_option"
              :add-entity="false"
              :options="overpaymentOptions"
              class="col-span-3"
          />

        </base-filter-row>
      </template>

    </template>

    <template #table="{loading, data}">

      <pdf-preview
          v-if="isPrint"
          :file="data"
          :loading="loading"
      />
      <quarterly-earnings-federal-report-table
          v-else
          :data="data"
          :filters="model"
          :report-number="reportNumber"
          :data-loading="loading"
          ref="reportTable"
      />

    </template>

  </base-report-form>
</template>
<script>
  import { quarterOptions } from '@/enum/enums'
  import QuarterlyEarningsFederalReportTable from '@/modules/payroll/components/reports/QuarterlyEarningsFederalReportTable'
  import PdfPreview from "@/components/common/PdfPreview.vue";

  const ReportTypes = {
    EmployeeList: '4881',
    '941Worksheet': '4881a',
    '941Form': '4881b'
  }
  export default {
    components: {
      PdfPreview,
      QuarterlyEarningsFederalReportTable,
    },
    data() {
      return {
        ReportTypes,
        quarterOptions,
        reportOptions: [
          {
            label: this.$t('Employee List'),
            value: ReportTypes.EmployeeList,
          },
          {
            label: this.$t('941 Worksheet'),
            value: ReportTypes['941Worksheet'],
          },
          {
            label: this.$t('941 Form'),
            value: ReportTypes['941Form'],
          }
        ],
        overpaymentOptions: [
          {
            value: 'apply_next_return',
            label: this.$t('Apply to next return')
          },
          {
            value: 'send_refund',
            label: this.$t('Send refund')
          }
        ],
        reportNumber: ReportTypes.EmployeeList,
        model: {
          quarter: 1,
          calendar_year: null,
          include_12th_month_employee_listing: false,
          semiweekly_depositor: true,
          total_quarter_deposits: 0,
          adjustment_sick_pay: 0,
          adjustments_tips_and_group_term_life_insurance: 0,
          overpayment_option: 'apply_next_return',
        },
      }
    },
    computed: {
      isPrint() {
        return [ReportTypes['941Worksheet'],ReportTypes['941Form']].includes(this.reportNumber)
      }
    }
  }
</script>
