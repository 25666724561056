<template>
  <base-select
    v-bind="$attrs"
    v-on="$listeners"
    v-model="model"
    :label="$t('View')"
    :options="options"
    :inline-errors="true"
  />
</template>
<script>

export default {
  props: {
    value: {
      type: String,
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    options() {
      return [
        {
          label: this.$t('Transactions'),
          value: 'transactions',
        },
        {
          label: this.$t('Initialized Entries'),
          value: 'inits',
        },
      ]
    }
  }
}
</script>
