<template>
  <div>
    <layout-tabs :items="filteredItems"/>
    <router-view/>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        items: [
          {
            name: this.$t('Summaries'),
            path: `/job-costing/review-job/summaries`,
            permission: 'job_costing_reports',
          },
          {
            name: this.$t('Line Items'),
            path: `/job-costing/review-job/line-items`,
            permission: 'job_costing_reports',
          }
        ],
      }
    },
    computed: {
      filteredItems() {
        return this.items.filter(item => this.$can(item.permission))
      },
    },
  }
</script>
