import axios from "axios";
import startCase from "lodash/startCase";
import camelCase from "lodash/camelCase";

class RoleService {

  async getRoles() {
    const { data } = await axios.get(`/restify/company-roles`);
    return data
  }

  async getRole(id) {
    const { data } = await axios.get(`/restify/company-roles/${id}`, {
      params: {
        related: 'permissions',
        relatablePerPage: 200,
      }
    });
    return data
  }

  async createRole(data) {
    return axios.post(`/restify/company-roles`, data);
  }

  async updateRole(data) {
    const requestData = {
      ...data,
      name: undefined
    }
    return axios.put(`/restify/company-roles/${data.id}`, requestData);
  }

  async attachPermissionsToRole({ roleId, permissions }) {
    return axios.post(`/restify/company-roles/${roleId}/attach/permissions`, { permissions });
  }

  async detachPermissionsFromRole({ roleId, permissions }) {
    return axios.post(`/restify/company-roles/${roleId}/detach/permissions`, { permissions });
  }

  async deleteRole(data) {
    return axios.delete(`/restify/company-roles/${data.id}`, data);
  }

  formatRoleNames(roles) {
    const mappedRoles = roles.map(this.getReadableRoleName)
    return mappedRoles.join(', ')
  }
  getReadableRoleName(name) {
    return startCase(camelCase(name))
  }
}

export default new RoleService();
