<template>
  <TimecardBatchesTable :status="resourceStatuses.Posted"/>
</template>
<script>
  import TimecardBatchesTable from "@/modules/payroll/components/timecard/TimecardBatchesTable";
  import { resourceStatuses } from "@/enum/enums";

  export default {
    components: {
      TimecardBatchesTable,
    },
    data() {
      return {
        resourceStatuses,
      }
    }
  }
</script>
