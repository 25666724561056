<template>
  <div>
    <AgDataTable
      ref="table"
      url="/restify/timesheets"
      :view-entity-url-query="viewEntityUrlQuery"
      :url-params="urlParams"
      :columns="columns"
      :selected-rows.sync="selectedRows"
      :change-page-with-timeout="true"
      :per-page="100"
      v-bind="groupParams"
      domLayout="autoHeight"
      actions="refresh,search,view,delete"
    >
      <template #additional-actions-before>
        <slot name="additional-actions-before"/>
      </template>
      <template #header-info>
        <TimesheetsDateFilter
          v-if="!employeeId"
          v-model="startOfWeek"
        />
        <TimesheetsSyncButton
          :start-date="startDate"
          :end-date="endDate"
          class="ml-2"
          @save="refresh"
        />
      </template>
      <template #dropdown-actions>
      <!--TODO Handle bulk approval when backend is done-->
        <TableActionItem
          v-if="false"
          :title="$t('Mark as approved')"
          :icon="CheckIcon"
          :disabled="selectedRows.length === 0"
          icon-class="bg-green-50 text-green-500"
          @click="approveTimesheets(selectedRows)"
        />
        <TableActionItem
          :title="$t('Generate Timecards')"
          :icon="WatchIcon"
          icon-class="bg-primary-50 text-primary-500"
          @click="showTimesheetToTimeCardDialog = true"
        />
      </template>
      <template #attributes.date="{row, params}">
        <div>
          <router-link
            v-if="row"
            :to="`${$route.path}?id=${row?.id}`"
          >
            {{ $formatDate(row.attributes?.date, LongDateFormat) }}
          </router-link>
          <span v-if="!row && params.node.level === 0">
            {{ $t('Employee Totals') }}
          </span>
          <span v-if="!row && params.node.level === -1">
            {{ $t('Grand Totals') }}
          </span>
        </div>
      </template>
      <template #tags="{row}">
        <TimesheetTags :row="row"/>
      </template>
    </AgDataTable>
    <TimesheetDialog
      v-if="showTimesheetDialog"
      :open.sync="showTimesheetDialog"
      :timesheet="selectedTimesheet"
      :has-more-timesheets="hasMoreTimesheets"
      :show-next-button="hasMoreTimesheets"
      :show-prev-button="showPrevButton"
      @prev="onPrevTimesheet"
      @next="onNextTimesheet"
    />

    <TimesheetsToTimecardsDialog
      v-if="showTimesheetToTimeCardDialog"
      :open.sync="showTimesheetToTimeCardDialog"
    />
  </div>

</template>
<script>
import { CheckIcon, WatchIcon } from "vue-feather-icons";
import axios from "axios";
import format from "date-fns/format";
import parse from "date-fns/parse";
import TimesheetTags from "@/modules/payroll/components/timesheets/TimesheetTags.vue";
import TimesheetDialog from "@/modules/payroll/components/timesheets/TimesheetDialog.vue";
import TimesheetGroupRow from "@/modules/payroll/components/timesheets/TimesheetGroupRow.vue";
import TimesheetsDateFilter from "@/modules/payroll/components/timesheets/TimesheetsDateFilter.vue";
import TimesheetsToTimecardsDialog from "@/modules/payroll/components/timesheets/TimesheetsToTimecardsDialog.vue";
import { TimesheetStatuses } from "@/modules/payroll/utils/payrollUtils";
import { getTotalMinutes, hoursFormatter } from "@/modules/payroll/utils/timesheetUtils";
import { getEndOfPayWeek, getStartOfPayWeek } from "@/modules/payroll/components/timesheets/utils";
import TimesheetsSyncButton from "@/modules/payroll/components/timesheets/TimesheetsSyncButton.vue";

export default {
  components: {
    TimesheetsSyncButton,
    TimesheetsToTimecardsDialog,
    TimesheetTags,
    TimesheetsDateFilter,
    TimesheetDialog,
    CheckIcon,
    WatchIcon,
    TimesheetGroupRow,
  },
  props: {
    employeeId: {
      type: String,
    }
  },
  data() {
    return {
      selectedRows: [],
      showTimesheetDialog: false,
      showTimesheetToTimeCardDialog: false,
      selectedTimesheet: null,
      hasMoreTimesheets: true,
      showPrevButton: true,
      LongDateFormat: 'EEE do MMM',
      CheckIcon,
      WatchIcon,
    }
  },
  computed: {
    startOfWeek: {
      get() {
        return this.$store.state.timesheets.selectedStartOfWeek
      },
      set(value) {
        this.$store.commit('timesheets/SET_START_OF_WEEK', value)
      }
    },
    startDate() {
      const start = getStartOfPayWeek(this.startOfWeek)
      return format(start, 'yyyy-MM-dd')
    },
    endDate() {
      const end = getEndOfPayWeek(this.startOfWeek)
      return format(end, 'yyyy-MM-dd')
    },
    selectedEmployeeIds() {
      return this.selectedRows
        .filter(row => {
          return row.attributes.status === TimesheetStatuses.Approved
        })
        .map(row => {
          return row.attributes.employee_id
        })
    },
    urlParams() {
      if (this.employeeId) {
        return {
          sort: '-date',
          employee_id: this.employeeId,
        }
      }
      return {
        sort: 'employee.code,date',
        date: `${this.startDate},${this.endDate}`
      }
    },
    viewEntityUrlQuery() {
      return this.$route.path
    },
    groupParams() {
      if (this.employeeId) {
        return {}
      }
      return {
        groupDefaultExpanded: -1,
        groupIncludeFooter: true,
        suppressAggFuncInHeader: true,
        groupRowRendererParams: this.groupRowRendererParams,
        groupDisplayType: "groupRows"
      }
    },
    columns() {
      return [
        {
          headerName: this.$t('Employee'),
          field: 'attributes.employee_id',
          rowGroup: !this.employeeId,
          hide: true,
        },
        {
          headerName: this.$t('Date'),
          field: 'attributes.date',
          minWidth: 200,
          // headerCheckboxSelection: true,
          // checkboxSelection: (params) => {
          //   return params?.data?.id
          // },
        },
        {
          headerName: this.$t('Status'),
          field: 'attributes.status',
          component: 'TimesheetStatus',
          minWidth: 130,
          maxWidth: 350,
        },
        {
          headerName: this.$t('Tags'),
          field: 'tags',
          minWidth: 160,
          maxWidth: 250,
        },
        {
          headerName: this.$t('Start Time'),
          field: 'attributes.clocked_in_at',
          minWidth: 100,
          maxWidth: 200,
          valueFormatter: params => {
            if (!params.data) {
              return
            }
            const start = params.data?.attributes?.start_time
            let startTime = parse(start, 'HH:mm:ss', new Date())
            return format(startTime, 'hh:mm aa')
          },
        },
        {
          headerName: this.$t('End Time'),
          field: 'attributes.end_time',
          minWidth: 100,
          maxWidth: 200,
          valueFormatter: params => {
            if (!params.data) {
              return
            }
            const end = params.data?.attributes?.end_time
            let startTime = parse(end, 'HH:mm:ss', new Date())
            return format(startTime, 'hh:mm aa')
          },
        },
        {
          headerName: this.$t('Regular'),
          field: 'attributes.regular_minutes',
          minWidth: 100,
          maxWidth: 100,
          valueFormatter: hoursFormatter,
          aggFunc: 'sum',
        },
        {
          headerName: this.$t('Overtime'),
          field: 'attributes.overtime_minutes',
          minWidth: 100,
          maxWidth: 100,
          valueFormatter: hoursFormatter,
          aggFunc: 'sum',
        },
        {
          headerName: this.$t('Premium'),
          field: 'attributes.premium_minutes',
          minWidth: 100,
          maxWidth: 100,
          valueFormatter: hoursFormatter,
          aggFunc: 'sum',
        },
        {
          headerName: this.$t('Lunch'),
          field: 'attributes.lunch_minutes',
          minWidth: 80,
          maxWidth: 80,
          valueFormatter: hoursFormatter,
          aggFunc: 'sum',
        },
        {
          headerName: this.$t('Time Off'),
          field: 'attributes.time_off_units',
          minWidth: 90,
          maxWidth: 90,
          valueGetter: params => {
            const value = params.data?.attributes?.time_off_units || 0
            return value * 60
          },
          valueFormatter: hoursFormatter,
          aggFunc: 'sum',
        },
        {
          headerName: this.$t('Total Hours'),
          field: 'attributes.total_minutes',
          minWidth: 100,
          maxWidth: 100,
          pinned: 'right',
          valueGetter: getTotalMinutes,
          valueFormatter: hoursFormatter,
          aggFunc: 'sum',
        },
      ]
    },
    groupRowRendererParams() {
      return {
        innerRenderer: 'TimesheetGroupRow',
        suppressCount: true,
        innerRendererParams: {
          from: this.startDate,
          to: this.endDate,
        }
      }
    },
  },
  methods: {
    async approveTimesheets(timesheets = []) {
      const validTimesheets = timesheets.filter(timesheet => !timesheet.attributes.approved_at)
      for (const timesheet of validTimesheets) {
        await axios.post(`/restify/timesheets/${timesheet.id}/actions?action=approve-timesheet`)
      }
      await this.$refs.table.refresh()
    },
    getTableData() {
      return this.$refs.table?.getTableData().filter(r => r?.id)
    },
    triggerTimesheetDialog(id) {
      if (!id) {
        return
      }
      const data = this.getTableData()
      const index = data?.findIndex(row => row?.id === id)

      if (index === -1 || index === undefined) {
        return
      }

      const timesheet = data[index]
      this.selectedTimesheet = timesheet?.attributes || {}
      this.showTimesheetDialog = true
      this.hasMoreTimesheets = index < data.length - 1
      this.showPrevButton = index !== 0
    },
    onPrevTimesheet() {
      const data = this.getTableData()
      const index = data.findIndex(row => row?.id === this.selectedTimesheet.id)
      if (index === -1) {
        return
      }
      const prevTimesheet = data[index - 1]
      if (prevTimesheet) {
        this.triggerTimesheetDialog(prevTimesheet.id)
      }
    },
    onNextTimesheet() {
      const data = this.getTableData()
      const index = data.findIndex(row => row?.id === this.selectedTimesheet.id)
      if (index === -1) {
        return
      }
      const nextTimesheet = data[index + 1]
      if (nextTimesheet) {
        this.triggerTimesheetDialog(nextTimesheet.id)
      }
    },
    refresh() {
      this.$refs.table?.refresh()
    },
  },
  watch: {
    '$route.query.id': {
      immediate: true,
      handler(timesheetId) {
        this.triggerTimesheetDialog(timesheetId)
      }
    }
  }
}
</script>
