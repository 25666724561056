<template>
    <base-select v-model="model"
                 :label="$attrs.label"
                 :placeholder="$t('Company')"
                 :options="companies"
                 v-bind="$attrs"
                 id="type">
    </base-select>
</template>
<script>
  import CompanyService from "@/modules/settings/services/CompanyService";

  export default {
    inheritAttrs: false,
    props: {
      value: [String, Number, Array],
      filterMethod: {
        type: Function,
        default: () => true
      }

    },
    model: {
      prop: 'value',
      event: 'change',
    },
    data() {
      return {
        companies: []
      }
    },
    computed: {
      model: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('change', value)
          this.$emit('entity-change', this.getFullValue(value))
        }
      },
    },
    methods: {
      getFullValue(value) {
        if (!Array.isArray(value)) {
          return this.companies.find(c => c.value === value)
        }
        return value.map(val => {
          return this.companies.find(c => c.value === val)
        })
      }
    },
    async mounted() {
      let companies = await CompanyService.getCompanies()
      companies = companies.filter(c => c.attributes.active)
      this.companies = companies.map(c => {
        let name = `${c.attributes.abbr} (${c.attributes.name})`
        return {
          label: name,
          value: c.attributes.id,
        }
      })
      this.companies = this.companies.filter(company => this.filterMethod(company))
    }
  }
</script>
