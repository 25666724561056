<template>
  <div>
    <layout-tabs :items="filteredItems"/>
    <router-view/>
  </div>
</template>
<script>
  export default {
    computed: {
      items() {
        return [
          {
            name: this.$t('Accounts Payable Settings'),
            path: '/accounts-payable/settings/ap-settings',
            permission: 'accounts_payable_settings',
          },
          {
            name: this.$t('Settings Update Activity'),
            path: '/accounts-payable/settings/activity-log',
            permission: 'job_costing_settings',
          },
          {
            name: this.$t('Initialize Open Payable'),
            path: '/accounts-payable/settings/ap-initialize',
            permission: 'accounts_payable_settings',
          },
        ]
      },
      filteredItems() {
        return this.items.filter(item => this.$can(item.permission))
      },
    },
  }
</script>
