<template>
  <div class="flex space-x-4 items-center">
    <post-action
        v-if="$isAuthorized('authorizedToPost', batch)"
        v-on="$listeners"
        :id="batch.id"
        :disabled="!batch.id"
        :payment-date="true"
        :has-recurring-option="false"
        :show-bank="true"
        :show-payment-start-number="true"
        :submit-function="onPost"
        entity-name="Payroll"
        resource-name="payroll-batch"
    />
    <post-action
        v-if="$isAuthorized('authorizedToPost', batch)"
        v-on="$listeners"
        :id="batch.id"
        :disabled="!batch.id"
        :payment-date="true"
        :has-recurring-option="false"
        :show-bank="true"
        :show-payment-start-number="true"
        :submit-function="postAndPrintChecks"
        entity-name="Payroll"
        resource-name="payroll-batch"
    >
      <template #button-text>
        {{ $t('Post & Print Checks') }}
      </template>
    </post-action>
  </div>
</template>
<script>
  import axios from 'axios'

  export default {
    name: 'PayrollBatchActions',
    props: {
      batch: {
        type: Object,
        default: () => ({}),
      },
    },
    methods: {
      async onPost(data) {
        await axios.post(`/restify/payroll-batches/${this.batch.id}/actions?action=post-payroll-batch`,
            {
              fiscal_year: data.fiscal_year,
              period: data.period,
              payment_date: data.payment_date,
              payment_start_number: data.payment_start_number?.toString(),
              bank_id: data.bank_id,
              use_same_series_for_dd: data.use_same_series_for_dd,
              dd_start_number: data.dd_start_number,
            },
        )
      },
      async postAndPrintChecks(data) {
        await this.onPost(data)
        await this.$router.replace({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            printChecks: true,
          }
        })
      },
    },
  }
</script>
