<template>
  <BaseWrapper v-bind="$attrs"
               :items="getItems"
               :entity="entity"
               :footer="footer"
               title-key="description"
               status-key="status"
  />
</template>
<script>
  import BaseWrapper from '@/modules/common/components/entity-preview/BaseWrapper'

  export default {
    components: {
      BaseWrapper,
    },
    props: {
      additionalProps: Array,
      entity: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        footer: {},
      }
    },
    computed: {
      items() {
        return [
          {
            title: this.$t('Owner'),
            value: this.entity.owner_name,
          },
          {
            title: this.$t('Certified Payroll'),
            status: this.entity.certified_payroll,
            component: 'StatusBadge',
          },
        ]
      },
      getItems() {
        if (!this.additionalProps?.length) {
          return this.items
        }

        const additionalItems = this.composeAdditionalItems()

        return [
          ...this.items,
          ...additionalItems,
        ]
      },
    },
    methods: {
      composeAdditionalItems() {
        return this.additionalProps.map(item => {
          return {
            title: item.label,
            value: this.entity[item.prop],
            format: item.format
          }
        })
      },
    },
  }
</script>
