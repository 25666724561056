<template>
    <base-form :loading="loading"
               :showCancel="showCancel"
               :showBack="showBack"
               :save-text="$t('Create phase code')"
               :update-text="$t('Update phase code')"
               layout="modal"
               @cancel="$emit('cancel')"
               @submit="onSubmit"
    >
        <div class="col-span-6 md:col-span-3">
            <base-input v-model="model.phase"
                        :label="$t('Phase Code')"
                        :placeholder="$t('Phase Code')"
                        id="phase"
                        rules="required|max:4">
            </base-input>
        </div>
        <div class="col-span-6 md:col-span-3">
            <base-input v-model="model.cost"
                        :label="$t('Cost Code')"
                        :placeholder="$t('Cost Code')"
                        id="cost"
                        rules="max:6">
            </base-input>
        </div>
        <div class="col-span-6">
            <base-input v-model="model.description"
                        :label="$t('Description')"
                        :placeholder="$t('Description')"
                        id="description"
                        rules="max:150">
            </base-input>
        </div>
        <div class="col-span-6 md:col-span-3">
            <base-input v-model="model.um"
                        :label="$t('Unit Measure')"
                        :placeholder="$t('Unit Measure')"
                        id="um"
                        rules="alpha|length:2">
            </base-input>
        </div>
        <div class="col-span-6 md:col-span-3">
            <entity-select v-model="model.work_item_code_id"
                           url="/restify/work-items"
                           :label="$t('Work Item Code')"
                           :placeholder="$t('Work Item Code')"
                           label-key="code"
                           id="work_item_code_id">
            </entity-select>
        </div>
    </base-form>
</template>
<script>
  import axios from 'axios'

  export default {
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      showBack: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        loading: false,
        showCancel: true,
        model: {
          phase: '',
          cost: '',
          description: '',
          um: '',
          work_item_code_id: undefined,
        },
      }
    },
    watch: {
      data: {
        immediate: true,
        handler(value) {
          this.model = {
            ...this.model,
            ...value,
          }
        },
      },
    },
    methods: {
      async onSubmit() {
        try {
          this.loading = true
          if (this.model.id) {
            await axios.put(`/restify/phase-costs/${this.model.id}`, this.model)
            this.$success(this.$t('Codes updated'))
          } else {
            await axios.post('/restify/phase-costs', this.model)
            this.$success(this.$t('Codes added'))
          }
          this.$emit('save', this.model)
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not update the codes'))
        } finally {
          this.loading = false
        }
      },
    },
  }
</script>
