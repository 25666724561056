<template>
  <div>
    <portal to="page-title">
      {{$route.name}} <template v-if="$route.params.date">{{$formatDate($route.params.date)}}</template>
    </portal>
    <div v-if="$can('service_billing_invoices_send_email')"
         class="flex justify-between items-center mt-8 mb-3">
      <h3>
        {{ $t('Invoices to send') }}
      </h3>
      <div class="flex items-center">
        <base-button variant="primary"
                     :disabled="!customers.some(e => e.selected)"
                     @click="sendEmails"
        >
          {{ $t('Send') }}
        </base-button>
      </div>
    </div>
    <BaseDataTable :columns="columns"
                   :url="url"
                   :url-params="urlParams"
                   :select-row-filter="hasEmail"
                   :row-classes="getRowClasses"
                   default-sort="-date"
                   actions="search,refresh,export,print"
                   entity="service-billing-invoices"
                   ref="table"
                   @edit="addEmail"
                   @data-fetch="customers = $event"
                   @all-selected="allPagesSelected = $event"
    >
      <template v-slot:select="{row}">
        <base-checkbox v-if="$can('service_billing_invoices_update')"
                       v-model="row.selected"
                       :disabled="!getEmail(row)">
        </base-checkbox>
      </template>
      <template v-slot:attributes.total.due="{row}">
        <template v-if="row.attributes.template === 'regular-invoice'">
          {{ $formatPrice(row.attributes.total.due) }}
        </template>
        <template v-else>
          {{ $formatPrice(row.attributes.total.total) }}
        </template>
      </template>
      <template v-slot:attributes.sent_count="{row}">
        <base-badge :type="row.attributes.sent_count > 0 ? 'success' : 'info'">
          {{ $tc('sent times', row.attributes.sent_count, { count: row.attributes.sent_count }) }}
        </base-badge>
      </template>
      <template v-slot:attributes.template="{row}">
        <base-tooltip v-if="row.attributes.template === 'regular-invoice'"
                      :content="$t('Regular')">
          <div>
            {{ $t('R') }}
          </div>
        </base-tooltip>
        <base-tooltip v-if="row.attributes.template === 'annual-maintenance'"
                      :content="$t('Annual')">
          <div>
            {{ $t('A') }}
          </div>
        </base-tooltip>
      </template>
      <template v-slot:attributes.customer_data.Name="{row}">
        <div>
          <customer-link :data="row.relationships.customer"/>
          <base-phone-link :value="getCustomer(row).phone"></base-phone-link>
        </div>
      </template>
      <template v-slot:extra-actions-before="{row, index}">
        <table-view-button v-if="$isAuthorized('authorizedToShow', row)" @click="previewInvoice(row)"/>
        <table-edit-button v-if="$can('customers_update') && !customerId"
                           @click="addEmail(row, index)"/>
        <table-download-button
            v-if="$isAuthorized('authorizedToDownload', row)"
            :loading="row.downloading"
            @click="downloadStatement(row)"
        />
        <table-icon-button v-if="$isAuthorized('authorizedToSend', row) && getEmail(row)"
                           :content="$t('Send Email')"
                           @click="sendInvoice(row)"
        >
          <MailIcon class="w-4 h-4"/>
        </table-icon-button>
      </template>
    </BaseDataTable>
    <fade-transition mode="out-in" :duration="75">
      <base-form-dialog v-if="showAddEmailDialog && rowToEdit"
                        :key="rowToEdit.id"
                        :title="getCustomer(rowToEdit).name"
                        :show-arrows="true"
                        :open.sync="showAddEmailDialog"
                        :previous-disabled="selectedIndex <= 0"
                        :next-disabled="selectedIndex === customers.length - 1 && isLastPage()"
                        @next-click="onNextClick"
                        @prev-click="onPrevClick"
      >
        <ValidationObserver v-slot="{ handleSubmit }">
          <base-form :loading="rowToEdit.loading"
                     layout="modal"
                     @cancel="$emit('cancel')"
                     @submit="handleSubmit(onEmailAdd)"
          >

            <div class="col-span-6">
              <base-input v-model="modelToEdit.email"
                          :label="$t('Email')"
                          :placeholder="$t('Customer Email')"
                          id="email"
                          rules="required|email">
              </base-input>
            </div>
          </base-form>
        </ValidationObserver>
      </base-form-dialog>
    </fade-transition>
    <invoice-preview-dialog v-if="showInvoicePreviewDialog"
                            :open.sync="showInvoicePreviewDialog"
                            :data="get(rowToEdit, 'attributes', {})"
                            :customer="getCustomer(rowToEdit)"
                            :company="get(rowToEdit, 'relationships.company.attributes', {})"
    />
    <DeleteDialog v-if="showConfirmDialog"
                  :title="$t(`Send Emails to ${allPagesSelected ?  `all (${totalCustomers})` : selectedEmails.length} customer(s)`)"
                  :description="$t(`This action will send email(s) to ${allPagesSelected ?  `all (${totalCustomers})` : selectedEmails.length} customer(s). Make sure to validate the invoice amounts and preview the invoice before sending them via email. Note that customers without an email will be skipped.`)"
                  :delete-text="$t('Send')"
                  :open="true"
                  type="primary"
                  @delete="onConfirmSendAction"
                  @cancel="onConfirmRejectAction"
    >
    </DeleteDialog>
  </div>
</template>
<script>
  import axios from 'axios'
  import { MailIcon } from 'vue-feather-icons'
  import DeleteDialog from "@/components/common/modal/DeleteDialog";
  import InvoicePreviewDialog from "@/modules/service-billing/components/InvoicePreviewDialog";
  import { downloadFileForEntity } from "@/modules/common/util/downloadFileLocally";

  export default {
    components: {
      MailIcon,
      DeleteDialog,
      InvoicePreviewDialog,
    },
    props: {
      customerId: String,
    },
    data() {
      return {
        showAddEmailDialog: false,
        showInvoicePreviewDialog: false,
        selectedIndex: -1,
        rowToEdit: null,
        allPagesSelected: false,
        sendEmailLoading: false,
        modelToEdit: {
          email: ''
        },
        confirmResolve: null,
        confirmReject: null,
        showConfirmDialog: false,
        selectedTemplate: 'default',
        customers: [],
      }
    },
    computed: {
      url() {
        return '/restify/service-billing-invoices'
      },
      urlParams() {
        const data = {
          related: 'customer,company'
        }
        const { date } = this.$route.params
        if (date && date !== 'undefined' && date !== 'null') {
          data.date = date
        }
        if (this.customerId) {
          data.customer_id = this.customerId
        }
        return data
      },
      columns() {
        const columns =  [
          {
            label: '',
            prop: 'select',
            minWidth: 40,
            maxWidth: 40,
            smallCell: true,
          },
          {
            label: this.$t('Customer'),
            prop: 'attributes.customer_data.Name',
            maxWidth: 200,
            renderIf: () => !this.customerId
          },
          {
            label: this.$t('Email'),
            prop: 'relationships.customer.attributes.email',
            minWidth: 200,
            maxWidth: 270,
            component: 'EmailLink'
          },
          {
            label: this.$t('Amount'),
            prop: 'attributes.total.due',
            maxWidth: 130,
          },
          {
            label: this.$t('Invoice Date'),
            prop: 'attributes.invoice_date',
            maxWidth: 150,
            align: 'center',
            component: 'FormattedDate',
          },
          {
            label: this.$t('Downloaded'),
            prop: 'attributes.downloaded_at',
            maxWidth: 150,
            align: 'center',
            component: 'FormattedDate',
          },
          {
            label: this.$t('Sent'),
            prop: 'attributes.sent_count',
            maxWidth: 100,
            align: 'center',
          },
          {
            label: this.$t('Type'),
            prop: 'attributes.template',
            maxWidth: 80,
            align: 'center',
          },
        ]

        return columns.filter(c => {
          if (c.renderIf) {
            return c.renderIf()
          }
          return true
        })
      },
      allSelected() {
        return this.selectableRows.length && this.selectableRows.every(r => r.selected) && !this.allPagesSelected
      },
      selectedRows() {
        return this.customers.filter(e => e.selected)
      },
      selectableRows() {
        return this.customers
                   .filter(row => this.hasEmail(row))
                   .map(row => {
                     row.selected = row.selected || this.allPagesSelected
                     return row
                   })
      },
      selectedEmails() {
        return this.customers.filter(e => e.selected && this.hasEmail(e))
      },
      totalCustomers() {
        const table = this.$refs.table
        if (!table) {
          return 0
        }
        return table.pagination.total
      }
    },
    methods: {
      getRowClasses(row) {
        if (this.hasDuePayments(row)) {
          return 'bg-red-50'
        }
        return ''
      },
      hasDuePayments(row) {
        const over90 = row?.attributes?.total?.Over90
        return over90 && over90 !== '0'
      },
      isLastPage() {
        const { last_page, current_page } = this.get(this.$refs, 'table.pagination', {})
        return last_page === current_page
      },
      previewInvoice(row, index) {
        this.selectedIndex = index
        this.rowToEdit = row
        this.showInvoicePreviewDialog = true
      },
      async sendInvoice(row) {
        const customer = this.getCustomer(row)
        const confirmed = await this.$confirm({
          title: this.$t(`Send Invoice Email to ${customer.name}`),
          description: this.$t(`This action will send a invoice email to ${customer.name}.`),
          buttonText: this.$t('Send')
        })

        if (!confirmed) {
          return
        }
        try {
          this.$set(row, 'loading', true)
          const params = {
            template: this.selectedTemplate
          }
          const data = {
            repositories: [row.id]
          }
          await axios.post(`/restify/service-billing-invoices/action?action=send-service-billing-invoice`, data, { params })
          this.$success(this.$t(`Email was queued to be sent. ${customer.name} will receive the email in a couple of minutes.`))
          this.$refs.table.refresh()
        } catch (err) {
          this.$error('An error occurred while trying to send the invoice emails')
        } finally {
          this.$set(row, 'loading', false)
        }
      },
      async downloadStatement(row) {
        await downloadFileForEntity(row, 'service-billing-invoices')
      },
      onConfirmSendAction() {
        this.showConfirmDialog = false
        if (this.confirmResolve) {
          this.confirmResolve(true)
        }
      },
      onConfirmRejectAction() {
        this.showConfirmDialog = false
        if (this.confirmResolve) {
          this.confirmResolve(false)
        }
      },
      confirmSendAction() {
        this.showConfirmDialog = true
        return new Promise(resolve => {
          this.confirmResolve = resolve
        })
      },
      async sendEmails() {
        const confirmed = await this.confirmSendAction()

        if (!confirmed) {
          return
        }
        try {
          this.sendEmailLoading = true
          let payload = {
            repositories: []
          }
          if (this.allPagesSelected) {
            payload.repositories = 'all'
          } else {
            payload.repositories = this.selectedRows.map(r => r.id)
          }
          const params = {
            template: this.selectedTemplate,
            date: this.$route.params.date,
          }
          await axios.post(`/restify/service-billing-invoices/action?action=send-service-billing-invoice`, payload, { params })
          this.allPagesSelected = false
          this.$refs.table.selectRows(false)
          this.$success(this.$t('Invoice emails are queued to be sent. The selected customers will receive their invoice in a couple of minutes.'))
          this.$refs.table.refresh()
        } catch (err) {
          this.$error('An error occurred while trying to send the invoice via email')
        } finally {
          this.sendEmailLoading = false
        }
      },
      hasEmail(row) {
        const email = this.getEmail(row)
        return email !== '' && email !== null
      },
      getEmail(row) {
        return this.get(row, 'relationships.customer.attributes.email', '')
      },
      getCustomer(row) {
        const customerData = row.attributes.customer_data || {}
        const baseCustomer = this.get(row, 'relationships.customer.attributes', {})
        return {
          ...customerData,
          ...baseCustomer,
        }
      },
      async onNextClick() {
        if (this.selectedIndex === this.customers.length - 1) {
          if (!this.isLastPage()) {
            this.$refs.table.pagination.current_page++
            await this.$refs.table.refresh()
            this.selectedIndex = 0
            this.rowToEdit = this.customers[this.selectedIndex]
            this.modelToEdit.email = this.getEmail(this.rowToEdit)
            return
          }
          this.showAddEmailDialog = false
          return
        }
        this.selectedIndex++
        this.rowToEdit = this.customers[this.selectedIndex]
        this.modelToEdit.email = this.getEmail(this.rowToEdit)
      },
      onPrevClick() {
        this.selectedIndex--
        this.rowToEdit = this.customers[this.selectedIndex]
        this.modelToEdit.email = this.getEmail(this.rowToEdit)
      },
      addEmail(row, index) {
        this.rowToEdit = row
        this.selectedIndex = index
        this.showAddEmailDialog = true
        this.modelToEdit.email = this.getEmail(row)
      },
      async onEmailAdd() {
        const customer = this.get(this.rowToEdit, 'relationships.customer.attributes', {})
        const customerId = customer.id
        const previousEmail = customer.email
        try {
          this.$set(this.rowToEdit, 'loading', true)
          customer.email = this.modelToEdit.email
          await axios.patch(`/restify/customers/${customerId}`, {
            email: this.modelToEdit.email
          })
          this.$set(this.rowToEdit, 'loading', false)
          await this.onNextClick()
        } catch (err) {
          this.$error(this.$t('Could not update the customer information'))
          customer.email = previousEmail
        } finally {
          this.$set(this.rowToEdit, 'loading', false)
        }
      }
    },
  }
</script>
