<template>
  <BaseCard class="w-full weekly-timesheet">
    <div class="flex items-start space-x-2">
      <div class="hidden lg:flex min-w-[100px]">
        <TrackTimeButton
          :disabled="!canAddEntry"
          :disabled-tooltip="$t(`This timesheet and it's entries cannot be edited anymore`)"
          @click="showCreateDialog = true"
        />
      </div>
      <div class="w-full">
        <div class="flex flex-wrap items-center mb-6 w-full">
          <div class="flex mt-6">
            <ArrowButtons
              @left-click="prevArrow"
              @right-click="nextArrow"
            />
            <h4 class="text-2xl font-semibold flex items-center space-x-2 ml-4 capitalize">
            <span v-if="isTodaySelected" class="font-semibold">
              {{ $t('Today:') }}
            </span>
              <span>
              {{ formattedSelectedDate }}
            </span>
              <BaseButton
                v-if="!isTodaySelected"
                variant="primary-link"
                size="xs"
                @click="goToToday"
              >
                {{ $t('Return to today') }}
              </BaseButton>
            </h4>
          </div>
          <div class="flex-1 lg:mt-0 flex lg:justify-end">
            <div class="flex items-center space-x-2">
              <TimesheetsSyncButton
                :start-date="startDate"
                :end-date="endDate"
                :employee-ids="[selectedEmployee]"
                size="sm"
                class="mt-6"
                @save="getTimeSheets"
              />
              <TableRefreshButton @click="getTimeSheets" class="mt-6"/>
              <BaseDatePicker
                v-model="selectedDay"
                :clearable="false"
                :valueFormat="null"
                :inline-errors="true"
                :label="$t('Date')"
                :picker-options="pickerOptions"
                class="min-w-[150px]"
                @change="onSelectedDayChange"
              />

              <EmployeeSelectNew
                v-model="selectedEmployee"
                class="min-w-[200px]"
                :inline-errors="true"
                @change="onEmployeeChange"
              />
            </div>
          </div>
        </div>

        <template>
          <div class="lg:hidden items-center my-2">
            <TrackTimeButton
              class="btn-sm px-0 lg:mx-2"
              :disabled="!canAddEntry"
              :disabled-tooltip="$t(`This timesheet and it's entries cannot be edited anymore`)"
              @click="showCreateDialog = true"
            />
          </div>
          <div>
            <div class="relative flex border-gray-300 overflow-x-auto">
              <div
                v-for="(day, index) in weekdays"
                :key="index"
                class="flex day-column flex-col capitalize text-gray-500 cursor-pointer pb-2 px-3 border-b-2"
                :class="{
                    'border-primary-500 font-medium text-gray-700': isSelected(day.value),
                    'border-transparent': !isSelected(day.value),
                 }"
                @click="selectDay(day.value)"
              >
                <div class="flex space-x-2">
                  <span>
                    {{ day.label }}
                  </span>
                  <TimesheetStatusBadge
                    :timesheet="getTimesheet(timeLogs, day.value)"
                    class="mt-[6px]"
                  />
                </div>
                <div class="text-sm flex items-center space-x-2">
                  <span>{{ minutesToHours(getTotalMinutesForDay(timeLogs, day.value), true) }}</span>
                </div>
              </div>
              <div class="flex flex-1 flex-col items-end min-w-[150px] pb-2 text-gray-500">
                <span>{{ $t('Pay week total') }}</span>
                <span class="text-sm">{{ minutesToHours(totalMinutesForWeek, true) }}</span>
              </div>
            </div>
            <div class="min-h-[400px]">
              <TimesheetEntryList
                :loading="loading"
                :entries="timeLogsForSelectedDay"
                :show-timesheet-details="true"
              />
              <div
                v-if="timeLogsForSelectedDay.length === 0 && !loading"
                class="w-full pt-4"
              >
                <BaseButton variant="white" size="sm" :loading="copyRecentTimesheetLoading"
                            @click="copyRecentTimesheet">
                  {{ $t('Copy most recent timesheet') }}
                </BaseButton>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <TimesheetEntryDialog
      v-if="showCreateDialog"
      :open.sync="showCreateDialog"
      :date="apiFormattedSelectedDate"
      :employee-id="selectedEmployee"
      @close="showCreateDialog = false"
      @submit="showCreateDialog = false"
    />
  </BaseCard>
</template>
<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import TrackTimeButton from "@/modules/payroll/components/timesheets/weekly-timesheets/TrackTimeButton.vue";
import {
  addDays,
  addWeeks,
  eachDayOfInterval,
  endOfWeek,
  isAfter,
  isBefore,
  isSameDay,
  startOfDay,
  startOfWeek,
  subDays,
  subWeeks
} from "date-fns";
import ArrowButtons from "@/modules/payroll/components/timesheets/weekly-timesheets/ArrowButtons.vue";
import startCase from "lodash/startCase";
import { getTimesheet, getTimeSheetsForDay, getTotalMinutesForDay, minutesToHours } from "../../../utils/timeCardUtils";
import sumBy from "lodash/sumBy";
import store from "@/store";
import { employeeStatuses } from "@/enum/enums";
import TimesheetEntryList from "@/modules/payroll/components/timesheets/TimesheetEntryList.vue";
import format from "date-fns/format";
import { getPayPeriodStartDay, isTimesheetEditable } from "@/modules/payroll/components/timesheets/utils";
import TimesheetEntryDialog from "@/modules/payroll/components/timesheets/TimesheetEntryDialog.vue";
import TimesheetStatusBadge from "@/components/table/cells/TimesheetStatusBadge.vue";
import { useRoute } from "vue2-helpers/vue-router";
import { useStorage } from "@vueuse/core";
import { ApiDateFormat } from "@/plugins/dateFormatPlugin";
import parse from "date-fns/parse";
import { error } from "@/components/common/NotificationPlugin";
import i18n from "@/i18n";
import TimesheetsSyncButton from "@/modules/payroll/components/timesheets/TimesheetsSyncButton.vue";

const route = useRoute()
const showCreateDialog = ref(false)

const loading = ref(false)
const copyRecentTimesheetLoading = ref(false)

function getStartOfPayWeek(date) {
  return startOfWeek(date, { weekStartsOn: getPayPeriodStartDay() })
}

function getEndOfPayWeek(date) {
  return endOfWeek(date, { weekStartsOn: getPayPeriodStartDay() })
}

const pickerOptions = computed(() => {
  return {
    firstDayOfWeek: getPayPeriodStartDay(),
  }
})

const queryFrom = route.query.from ? parse(route.query.from, ApiDateFormat, new Date()) : null
const queryTo = route.query.to ? parse(route.query.to, ApiDateFormat, new Date()) : null

const selectedDay = useStorage('timesheetsSelectedDay', queryFrom || getStartOfToday(), sessionStorage)
if (queryFrom) {
  selectedDay.value = queryFrom
}

const from = ref(queryFrom || getStartOfPayWeek(selectedDay.value))
const to = ref(queryTo || getEndOfPayWeek(selectedDay.value))

const timeLogs = computed(() => {
  return store.state.timesheets.timesheetEntries
})
const daysOfWeek = computed(() => {
  return eachDayOfInterval({
    start: from.value,
    end: to.value,
  })
})

const weekdays = computed(() => {
  return daysOfWeek.value.map((day) => {
    return {
      label: format(day, 'EEE'),
      value: day,
    }
  })
})

const formattedSelectedDate = computed(() => {
  return startCase(format(selectedDay.value, 'EEEE, dd MMM'))
})

const apiFormattedSelectedDate = computed(() => {
  return format(selectedDay.value, 'yyyy-MM-dd')
})

function getStartOfToday() {
  return startOfDay(new Date())
}

function goToToday() {
  selectedDay.value = getStartOfToday()
  onSelectedDayChange(selectedDay.value)
}

function selectDay(day) {
  selectedDay.value = day
}

function isSelected(day) {
  return isSameDay(day, selectedDay.value)
}

const isTodaySelected = computed(() => {
  return isSameDay(selectedDay.value, new Date())
})

async function onEmployeeChange(employeeId) {
  await getTimeSheets(employeeId)
}

async function onSelectedDayChange(date) {
  const newStart = getStartOfPayWeek(date)
  const newEnd = getEndOfPayWeek(date)

  if (!isSameDay(newStart, from.value)) {
    from.value = newStart
    to.value = newEnd
    await getTimeSheets()
  }
  if (!isSameDay(date, selectedDay.value)) {
    selectedDay.value = date
  }
}

async function nextWeek() {
  from.value = addWeeks(from.value, 1)
  to.value = addWeeks(to.value, 1)
  await getTimeSheets()
}

function prevArrow() {
  const newDay = subDays(selectedDay.value, 1)
  selectedDay.value = newDay
  if (isBefore(newDay, from.value)) {
    prevWeek()
  }
}

function nextArrow() {
  const newDay = addDays(selectedDay.value, 1)
  selectedDay.value = newDay
  if (isAfter(newDay, to.value)) {
    nextWeek()
  }
}

async function prevWeek() {
  from.value = subWeeks(from.value, 1)
  to.value = subWeeks(to.value, 1)
  await getTimeSheets()
}

const allEmployees = computed(() => {
  return store.state.globalLists.employees
})

const firstActiveEmployee = computed(() => {
  return allEmployees.value.find((employee) => employee.status !== employeeStatuses.INACTIVE)
})


const selectedEmployee = ref(route.query.employee_id || null)

watch(() => firstActiveEmployee.value, (employee) => {
  if (!selectedEmployee.value && employee?.id) {
    selectedEmployee.value = employee?.id
  }
}, { immediate: true })

const timeLogsForSelectedDay = computed(() => {
  return getTimeSheetsForDay(timeLogs.value, selectedDay.value)
})

const selectedDayTimesheet = computed(() => {
  if (!timeLogsForSelectedDay.value.length) {
    return null
  }
  return timeLogsForSelectedDay.value[0]?.attributes?.timesheet
})

const canAddEntry = computed(() => {
  if (!selectedDayTimesheet.value) {
    return true
  }
  return isTimesheetEditable(selectedDayTimesheet.value)
})

const totalMinutesForWeek = computed(() => {
  return sumBy(timeLogs.value, 'attributes.duration')
})

const startDate = computed(() => {
  return format(from.value, 'yyyy-MM-dd')
})

const endDate = computed(() => {
  return format(to.value, 'yyyy-MM-dd')
})

async function getTimeSheets(employeeId) {
  try {
    loading.value = true
    await store.dispatch('timesheets/getTimesheetEntries', {
      sort: 'created_at',
      date: `${startDate.value},${endDate.value}`,
      employee_id: employeeId || selectedEmployee.value,
      perPage: 500,
    })
  } finally {
    loading.value = false
  }
}

async function copyRecentTimesheet() {
  try {
    copyRecentTimesheetLoading.value = true
    const daysBeforeSelectedDay = weekdays.value.filter(day => {
      return isBefore(day.value, selectedDay.value) && getTotalMinutesForDay(timeLogs.value, day.value) > 0
    })
    const mostRecentDay = daysBeforeSelectedDay.at(-1)?.value
    const mostRecentDayTimesheets = getTimeSheetsForDay(timeLogs.value, mostRecentDay)
    const entriesToCreate = mostRecentDayTimesheets.map(entry => {
      let start_time = entry.attributes.start_time
      if (start_time?.length > 4) {
        start_time = start_time.slice(0, 5)
      }
      return {
        ...entry.attributes,
        id: undefined,
        start_time,
        date: apiFormattedSelectedDate.value,
      }
    })
    for (const entry of entriesToCreate) {
      await store.dispatch('timesheets/addEntry', entry)
    }
  } catch(err) {
    error(i18n.t('Failed to copy most recent timesheet'))
  } finally {
    copyRecentTimesheetLoading.value = false
  }
}

onMounted(async () => {
  await getTimeSheets()
})

</script>
<style lang="scss">
.weekly-timesheet {
  .day-column {
    min-width: max(8vw, 100px);
  }
}
</style>
