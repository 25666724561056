import { federalFilingStatuses, taxAdjustments } from "@/enum/enums";
import i18n from "@/i18n";

export function getTaxStatusOptions() {
  return [
    {
      label: i18n.t('Single'),
      value: federalFilingStatuses.SINGLE,
    },
    {
      label: i18n.t('Married filing separately'),
      value: federalFilingStatuses.MARRIED_1_OR_LESS,
    },
    {
      label: i18n.t('Married filing jointly'),
      value: federalFilingStatuses.MARRIED_2_OR_MORE,
    },
    {
      label: i18n.t('Head of Household'),
      value: federalFilingStatuses.HEAD_OF_HOUSEHOLD,
    },
    {
      label: i18n.t('Percent of Federal'),
      value: federalFilingStatuses.PERCENT_OF_FEDERAL,
    },
    {
      label: i18n.t('Exempt'),
      value: federalFilingStatuses.EXEMPT,
    },
  ]
}

export function getAdjustmentTypes() {
  return [
    {
      label: i18n.t('No Adjustment'),
      value: taxAdjustments.NO_ADJUSTMENT,
    },
    {
      label: i18n.t('Additional Dollar Amount'),
      value: taxAdjustments.ADDITIONAL_AMOUNT,
    },
    {
      label: i18n.t('Additional Percent of Gross'),
      value: taxAdjustments.ADDITIONAL_GROSS_PERCENTAGE,
    },
    {
      label: i18n.t('Fixed Dollar Amount'),
      value: taxAdjustments.FIXED_AMOUNT,
    },
    {
      label: i18n.t('Fixed Percent'),
      value: taxAdjustments.FIXED_PERCENTAGE,
    },
    {
      label: i18n.t('Additional Percent of Tax'),
      value: taxAdjustments.ADDITIONAL_TAX_PERCENTAGE,
    },
  ]
}
