<template>
  <BaseForm
    :show-back="true"
    :show-cancel="true"
    :loading="loading"
    layout="vertical"
    grid-classes="grid grid-cols-12 gap-x-3"
    @cancel="$router.push('/settings/banks')"
    @submit="onSubmit"
  >
    <BaseSelect
      v-model="model.positive_pay_format"
      :options="positivePayFormats"
      :name="$t('Pay Format')"
      :placeholder="$t('Pay Format')"
      :label="$t('Format')"
      :rules="model.is_for_positive_pay || model.is_for_micr ? 'required' : ''"
      id="positive_pay_format"
      class="col-span-12 md:col-span-3"
    />
    <BaseInput
      v-model="model.routing_number"
      :name="$t('Routing Number')"
      :placeholder="$t('Routing Number')"
      :label="$t('Routing Number')"
      :rules="model.is_for_positive_pay || model.is_for_micr ? 'required|routingNumber' : ''"
      id="routing_number"
      class="col-span-12 md:col-span-3"
    />
    <BaseInput
      v-model="model.account_number"
      :name="$t('Account Number')"
      :placeholder="$t('Account Number')"
      :label="$t('Account Number')"
      :rules="model.is_for_positive_pay || model.is_for_micr ? 'required' : ''"
      id="account_number"
      class="col-span-12 md:col-span-3"
    />
    <template v-if="hasPositivePayOptions">
      <div class="col-span-12 mt-2">
        <h5 class="form-section-title">
          {{ $t('Positive Pay Options') }}
        </h5>
      </div>
      <div class="col-span-12">
        <BaseCheckbox
          v-model="model.meta.include_totals"
          :label="$t('Include Totals')"
          id="include_totals"
        />
      </div>
      <div class="col-span-12">
        <BaseCheckbox
          v-model="model.meta.include_voided_checks"
          :label="$t('Include Voided Checks')"
          id="include_voided_checks"
        />
      </div>
      <div
        v-if="model.positive_pay_format === BankPositivePayFormats.CSVAdditionalInfo"
        class="col-span-12 md:col-span-3 mt-2"
      >
        <BaseSelect
          v-model="model.meta.additional_info_field"
          :options="positivePayAdditionalFieldOptions"
          :name="$t('Additional Info Field')"
          :placeholder="$t('Additional Info Field')"
          :label="$t('Additional Info Field')"
          id="additional_info_field"
        />
      </div>
    </template>
    <div class="col-span-12 mt-2">
      <h5 class="form-section-title">
        {{ $t('Print Checks Options') }}
      </h5>
    </div>
    <div class="col-span-12">
      <BaseCheckbox
        v-model="model.is_for_positive_pay"
        :tip="$t('Is this bank account for Positive Pay?')"
        :label="$t('Is this bank account for Positive Pay?')"
        @change="onPositiveStatePayChange"
        id="is_for_positive_pay"
      />
    </div>
    <div class="col-span-12">
      <BaseCheckbox
        v-model="model.is_for_micr"
        :tip="$t('Print the MICR on checks')"
        :label="$t('Print MICR on Checks')"
        id="is_for_micr"
      />
    </div>
    <div class="col-span-12">
      <BaseCheckbox
        v-model="model.print_company_info"
        :tip="$t('Print company information in the check stubs')"
        :label="$t('Print Company Info')"
        id="print_company_info"
      />
    </div>
    <div class="col-span-12">
      <BaseCheckbox
        v-model="model.print_company_logo"
        :tip="$t('Print company logo in the check stubs')"
        :label="$t('Print Company Logo')"
        id="print_company_logo"
      />
    </div>
    <div class="col-span-12">
      <BaseCheckbox
        v-model="model.print_bank_info"
        :label="$t('Print Bank Info')"
        id="print_bank_info"
      />
    </div>
    <div class="col-span-12">
      <BaseCheckbox
        v-model="model.print_check_number"
        :tip="$t('Display check number in the check stub')"
        :label="$t('Print Check Number')"
        id="print_check_number"
      />
    </div>
    <div class="col-span-12 mb-4 mt-2">
      <h5 class="form-section-title">
        {{ $t('Print Margins') }}
      </h5>
      <span class="text-sm">{{$t('Applies to check templates only. The specified margins will be added on top of existing page margins.')}}</span>
    </div>
    <base-input
      v-model="model.top_margin"
      :label="$t('Top Margin')"
      :tip="$t('2.54 cm = 1 inch')"
      type="number"
      step="0.01"
      class="col-span-12 md:col-span-4 lg:col-span-2"
    >
      <template #suffix>
        {{ $t('cm') }}
      </template>
    </base-input>
    <base-input
      v-model="model.left_margin"
      :label="$t('Left Margin')"
      type="number"
      step="0.01"
      :tip="$t('2.54 cm = 1 inch')"
      class="col-span-12 md:col-span-4 lg:col-span-2"
    >
      <template #suffix>
        {{ $t('cm') }}
      </template>
    </base-input>
  </BaseForm>
</template>
<script lang="ts">
import axios from 'axios'
import { defineComponent, PropType } from 'vue'
import {
  Bank,
  BankAccountType,
  BankPositivePayAdditionalInfoField,
  BankPositivePayFormats
} from '@/modules/common/types/models.d'

export default defineComponent({
  props: {
    data: {
      type: Object as PropType<Bank>,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      BankPositivePayFormats,
      statusOptions: [
        {
          label: this.$t('Active'),
          value: true,
        },
        {
          label: this.$t('Inactive'),
          value: false,
        },
      ],
      loading: false as boolean,
      positivePayFormats: [
        {
          label: this.$t('ARP - Account Reconcilement Plans'),
          value: BankPositivePayFormats.ARP,
        },
        {
          label: this.$t('BofA - Bank of America'),
          value: BankPositivePayFormats.BankOfAmerica,
        },
        {
          label: this.$t('CitSIF - Citizens Bank Standard Input Format'),
          value: BankPositivePayFormats.CIT_SIF,
        },
        {
          label: this.$t('CRS - Common Reporting Standard'),
          value: BankPositivePayFormats.CRS,
        },
        {
          label: this.$t('CSV'),
          value: BankPositivePayFormats.CSV,
        },
        {
          label: this.$t('CSV Additional Info'),
          value: BankPositivePayFormats.CSVAdditionalInfo,
        },
        {
          label: this.$t('FFB - Fresno First Bank'),
          value: BankPositivePayFormats.FFB,
        },
        {
          label: this.$t('JPM - JP Morgan Bank'),
          value: BankPositivePayFormats.JPMorganChase,
        },
        {
          label: this.$t('MICASH - Synovus Gateway'),
          value: BankPositivePayFormats.MICASH,
        },
        {
          label: this.$t('NACHA - National Automated Clearing House Association'),
          value: BankPositivePayFormats.NACHA,
        },
        {
          label: this.$t('NACHA2 - National Automated Clearing House Association alternate'),
          value: BankPositivePayFormats.NACHA2,
        },
        {
          label: this.$t('SVB - Silicon Valley Bank'),
          value: BankPositivePayFormats.SiliconValleyBank,
        },
        {
          label: this.$t('WA - Western Alliance Bank (CSV format)'),
          value: BankPositivePayFormats.WesternAllianceBank,
        },
        {
          label: this.$t('WF - Wells Fargo Bank'),
          value: BankPositivePayFormats.WellsFargoCSV,
        },
      ],
      positivePayAdditionalFieldOptions: [
        {
          label: this.$t('Address 1'),
          value: BankPositivePayAdditionalInfoField.Address1,
        },
        {
          label: this.$t('Check Memo'),
          value: BankPositivePayAdditionalInfoField.CheckMemo,
        },
        {
          label: this.$t('Contact'),
          value: BankPositivePayAdditionalInfoField.Contact,
        }
      ],
      model: {
        name: '',
        account: '',
        subaccount: '',
        phone: '',
        fax: '',
        email: '',
        last_ap_check_no: 0,
        last_ar_check_no: 0,
        last_pr_check_no: 0,
        last_pr_dd_no: 0,
        last_ap_dd_no: 0,
        top_margin: 0,
        left_margin: 0,
        account_type: 'checking' as BankAccountType,
        routing_number: null,
        account_number: null,
        is_for_dd: true,
        is_for_micr: false,
        is_for_positive_pay: false,
        print_bank_info: false,
        print_check_number: false,
        print_company_info: false,
        print_company_logo: false,
        positive_pay_format: 'csv',
        meta: {
          include_totals: true,
          include_voided_checks: false,
          additional_info_field: null
        }
      } as Bank,
    }
  },
  computed: {
    hasPositivePayOptions() {
      return [
        BankPositivePayFormats.CSV,
        BankPositivePayFormats.CSVAdditionalInfo,
      ].includes(this.model.positive_pay_format)
    },
  },
  methods: {
    async onSubmit() {
      try {
        this.loading = true
        const data = {
          ...this.model,
          routing_number: this.model.routing_number || undefined,
          account_number: this.model.account_number || undefined,
        }
        if (this.model.id) {
          await axios.patch(`/restify/banks/${ this.model.id }`, data)
        } else {
          await axios.post('/restify/banks', this.model)
        }
        this.$success(this.$t('Bank Positive Pay updated'))
        await this.$store.dispatch('settings/getBank', this.data.id)
      } catch (err: any) {
        if (err.handled) {
          return
        }
        console.warn(err)
        this.$error(this.$t('Could not setup the Bank Positive Pay'))
      } finally {
        this.loading = false
      }
    },
    onPositiveStatePayChange(state: any) {
      this.model.is_for_positive_pay = !!state
      if (!state) {
        return
      }
    }
  },
  watch: {
    'data.attributes': {
      immediate: true,
      handler(value: Bank) {
        if (!value?.id) {
          return
        }

        this.model = {
          ...value,
        }
      },
    },
  },
})
</script>
