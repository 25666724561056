import get from 'lodash/get'
import { isExceptionDomain } from "@/isProduction";

const env = import.meta.env.VITE_ENV
/**
 * When running locally you can change this to change the default organization for testing purposes, but please don't commit the change
 */
const localStorageOrganization = localStorage.getItem('default-organization')
const defaultOrganization = localStorageOrganization || 'sample'
localStorage.setItem('default-organization', defaultOrganization)

/**
 * When running locally you can change this host to test with another api, but please don't commit the change
 * You can change this at runtime after running npm run serve
 */
const localHost = 'cp4dev.com'
const developHost = 'cp4dev.com'
const stagingHost = 'cpsignon.com'
const productionHost = 'cpsignin.com'
const betaHost = 'cp4beta.com'
const cpDesktopHost = 'cpdesktop.com'

const isProduction = env === 'production' && window.location.host.includes(productionHost)
const isStaging = env === 'staging' && window.location.host.includes(stagingHost)
const isBeta = env === 'beta' && window.location.host.includes(betaHost)
const isCpDesktop = env === 'cpdesktop' && window.location.host.includes(cpDesktopHost)
const isDevelopment = env === 'develop' && window.location.host.includes(developHost)

const envHosts = {
  local: localHost,
  develop: developHost,
  staging: stagingHost,
  beta: betaHost,
  production: productionHost,
  cpdesktop: cpDesktopHost,
}
const host = envHosts[env] || envHosts.develop

let ROOT_API_URL = `https://api.${host}/api`
let API_HOST = `https://{organization}.api.${host}/api`
let PDF_API_URL = `https://pdf-api.${host}`

function replaceOrganizationInHost(organizationName) {
  if (isExceptionDomain(organizationName)) {
    API_HOST = API_HOST.replace('{organization}.', '')
    return
  }

  API_HOST = API_HOST.replace('{organization}', organizationName)
}

function getHostQuery() {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('host')
}

let ORGANIZATION_NAME = ''
if (isStaging || isDevelopment || isProduction || isBeta || isCpDesktop) {
  ORGANIZATION_NAME = get(window.location.host.split('.'), '[0]')
  replaceOrganizationInHost(ORGANIZATION_NAME)
  if (!ORGANIZATION_NAME) {
    API_HOST = ROOT_API_URL
  }
} else {
  const hostQuery = getHostQuery()
  ORGANIZATION_NAME = hostQuery || defaultOrganization

  replaceOrganizationInHost(ORGANIZATION_NAME)
}

export function isBetaTestingOrganization() {
  const isLocal = window.location.origin.includes('localhost')
  return ['sample', 'demo'].includes(ORGANIZATION_NAME) || isLocal
}

const MAXIMUM_FRACTION_DIGITS = 2

export default {
  ROOT_API_URL: ROOT_API_URL,
  API_HOST: API_HOST,
  PDF_API_URL,
  ORGANIZATION_NAME,
  MAXIMUM_FRACTION_DIGITS,
};
