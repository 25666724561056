import Vue from 'vue'
const types = {
  SET_PRESET: 'SET_PRESET'
};
const state = {
  presets: {}
};

const mutations = {
  [types.SET_PRESET]: (state, { modelName, preset }) => {
    Vue.set(state.presets, modelName, preset)
  },
};

const actions = {
};

const getters = {
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
