<template>
    <el-popover
            placement="top"
            width="200"
            v-model="visible">
        <ValidationObserver v-slot="{valid}">
            <div v-for="(option, index) in options" :key="index">
                <base-input v-model="option.value"
                            :key="index"
                            :label="$t(option.label)"
                            rules="required"
                            :id="option.key"/>
            </div>
            <div class="flex w-full justify-between">
                <base-button variant="white"
                             size="sm"
                             @click="visible = false">{{ $t('Cancel') }}
                </base-button>
                <base-button variant="primary"
                             size="sm"
                             :disabled="!valid"
                             @click="onChange">{{ $t('Confirm') }}</base-button>
            </div>
        </ValidationObserver>
        <base-button slot="reference" variant="white" class="mt-1">
            <SettingsIcon class="w-5 h-5 text-primary-500"/>
        </base-button>
    </el-popover>
</template>
<script>
  import { SettingsIcon } from 'vue-feather-icons'
  import { Popover } from 'element-ui'

  export default {
    name: 'accounts-type',
    components: {
      SettingsIcon,
      [Popover.name]: Popover,
    },
    props: {
      options: {
        type: Array,
        default: () => []
      },
    },
    data() {
      return {
        visible: false,
      }
    },
    methods: {
      onChange() {
        this.$emit('on-change', this.options)
        this.visible = false
      },
    }
  }
</script>
