<template>
  <taggable-input
    v-on="$listeners"
    :name="$t('Invoice Number')"
    :label="$t('Invoice #')"
    :placeholder="$t('Invoice Number')"
    :lock-text="$t('Lock')"
    :default-state="isLocked"
    :show-value="true"
    :disabled="canEditNumber"
    rules="required|max:16"
    id="number"
    class="col-span-8 md:col-span-1"
    v-bind="$attrs"
  />
</template>
<script>
import { resourceStatuses } from "@/enum/enums";

export default {
  props: {
    model: {
      type: Object,
      default: () => ({})
    },
    defaultLocked: {
      type: Boolean,
      default: undefined
    }
  },
  computed: {
    isLocked() {
      if (typeof this.defaultLocked === 'boolean') {
        return this.defaultLocked
      }

      if (!this.model.id) {
        return false
      }
      return this.model.status === resourceStatuses.Pending
    },
    canEditNumber() {
      if (this.model.id) {
        return this.model.status !== resourceStatuses.Pending
      }
      return false
    }
  }
}
</script>
