<template>
  <div
    class="vendor-details">
    <DetailsPageLayout :loading="vendorLoading">
      <template #left>
        <VendorMainCard :vendor="vendor"/>
        <VendorMainDetailsCard :vendor="vendor"/>
        <VendorMainAmountsCard :vendor-data="vendorData"/>
      </template>
      <template #right>
        <VendorSecondaryCard :vendor-data="vendorData"/>
        <VendorAdditionalInfoCard :vendor-data="vendorData"/>
        <VendorPaymentsCard :vendor="vendor"/>
        <AddressesDetailsCard
            :addresses="vendor?.relationships?.addresses"
            no-data-text="No addresses were added for this vendor"
        />
        <ContactsDetailsCard
            :contacts="vendor?.relationships?.contacts"
            no-data-text="No contacts were added for this vendor"
        />
      </template>
    </DetailsPageLayout>
    <base-back-link to="/accounts-payable/vendors"/>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue'
import store from '@/store'
import DetailsPageLayout from '@/modules/common/components/DetailsPageLayout.vue'
import AddressesDetailsCard from '@/modules/common/components/AddressesDetailsCard.vue'
import ContactsDetailsCard from '@/modules/common/components/contacts/ContactsDetailsCard.vue'
import VendorMainCard from '@/modules/accounts-payable/components/vendor-details/VendorMainCard.vue'
import VendorPaymentsCard from '@/modules/accounts-payable/components/vendor-details/VendorPaymentsCard.vue'
import VendorSecondaryCard from '@/modules/accounts-payable/components/vendor-details/VendorSecondaryCard.vue'
import VendorMainDetailsCard from '@/modules/accounts-payable/components/vendor-details/VendorMainDetailsCard.vue'
import VendorMainAmountsCard from '@/modules/accounts-payable/components/vendor-details/VendorMainAmountsCard.vue'
import VendorAdditionalInfoCard from '@/modules/accounts-payable/components/vendor-details/VendorAdditionalInfoCard.vue'
import { Vendor } from '@/modules/common/types/models'
import Data = API.Data

const vendor = computed((): Data<Vendor> => {
  return store.state.accountsPayable.currentVendor
})

const vendorData = computed((): Vendor => {
  return vendor.value.attributes
})

const vendorLoading = computed((): boolean => {
  return store.state.accountsPayable.vendorLoading
})
</script>
<style lang="scss">
.vendor-details {
  span {
    @apply text-gray-500 font-medium;
  }
}
</style>
