<template>
  <div>
    <ProfileForm :data="userFromStore" :save-text="$t('Update profile')"/>
    <ChangePasswordForm class="mt-6"></ChangePasswordForm>
  </div>
</template>
<script>
  import ProfileForm from "@/modules/profile/components/ProfileForm";
  import ChangePasswordForm from "@/modules/profile/components/ChangePasswordForm";

  export default {
    components: {
      ProfileForm,
      ChangePasswordForm,
    },
    computed: {
      userFromStore() {
        return this.$store.state.auth.user
      }
    }
  }
</script>
<style>
</style>
