<template>
    <div>
        <div>
            <img class="h-12 mx-auto w-auto" src="/img/cp.png" alt="Workflow"/>
            <h1 class="page-heading">
                {{$t('Sign in to your account')}}
            </h1>
        </div>
        <div class="mt-8">
            <div class="mt-6 relative">
                <div class="w-full border-t border-gray-300"/>
                <div class="relative flex justify-center text-sm leading-5/"/>
            </div>
            <div class="mt-6">
                <ValidationObserver v-slot="{ handleSubmit, valid }">
                    <form @submit.prevent="handleSubmit(onSubmit)">
                        <BaseInput v-model="model.email"
                                   :label="$t('Email address')"
                                   :placeholder="$t('Email address')"
                                   rules="required"
                                   id="email"
                                   type="email"
                        />
                        <BaseInput v-model="model.password"
                                   :label="$t('Password')"
                                   :placeholder="$t('Password')"
                                   :type="showPassword ? 'text' : 'password'"
                                   rules="required"
                                   id="password"

                        >
                          <template #suffix>
                            <component :is="passwordInputSuffix"
                                       class="w-4 h-4 cursor-pointer"
                                       @click="toggleShowPassword"
                            />
                          </template>
                        </BaseInput>
                        <div class="flex justify-end text-sm leading-5">
                            <router-link to="/forgot-password">
                                {{$t('Forgot your password?')}}
                            </router-link>
                        </div>
                        <div class="mt-6">
                            <BaseButton :disabled="!valid"
                                        type="submit"
                                        block
                                        :loading="loading">
                                {{$t('Sign in')}}
                            </BaseButton>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>
<script>
  import config from '@/modules/common/config'
  import { isExceptionDomain } from '@/isProduction';
  import { EyeIcon, EyeOffIcon } from 'vue-feather-icons'
  import AuthService from '@/modules/auth/services/AuthService';

  export default {
    components: {
      EyeIcon,
      EyeOffIcon,
    },
    data() {
      return {
        loading: false,
        model: {
          email: this.$route.query.email || '',
          password: '',
          rememberMe: false,
        },
        showPassword: false,
        organizations: []
      }
    },
    computed: {
      passwordInputSuffix() {
        return this.showPassword ? EyeOffIcon : EyeIcon
      }
    },
    methods: {
      toggleShowPassword() {
        this.showPassword = !this.showPassword
      },
      async handleGlobalLoginResponse(response) {
        const { organizations, plainTextToken } = response
        if (organizations.length === 1) {
          AuthService.loginWithTokenRedirect(organizations[0], plainTextToken)
        } else if (organizations.length > 1) {
          await this.$router.push('/choose-organization')
        }
      },
      async onSubmit() {
        try {
          this.loading = true
          this.$store.commit('company/SET_ADMIN_VIEWING_COMPANY', false)
          if (!isExceptionDomain(config.ORGANIZATION_NAME)) {
            const { from, invitation } = this.$route.query
            await this.$store.dispatch('auth/login', {
              ...this.model,
              from,
              token: invitation,
            })
            if (from && !invitation) {
              await this.$router.push(from)
            } else {
              await this.$router.push({name: 'Dashboard'})
            }
          } else {
            const result = await this.$store.dispatch('auth/globalLogin', this.model)
            await this.handleGlobalLoginResponse(result)
          }
        } finally {
          this.loading = false
        }
      },
      async checkForQueryToken() {
        const { token } = this.$route.query
        if (token) {
          await this.$store.dispatch('auth/loginWithToken', token)
          await this.$router.push({ name: 'Dashboard' })
        }
      },
      async checkForInvitation() {
        const { invitation } = this.$route.query
        if (!invitation) {
          return
        }
        await this.$router.push({
          path: '/register',
          query: this.$route.query
        })
      }
    },
    created() {
      this.checkForQueryToken()
    }
  }
</script>
