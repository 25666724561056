import axios from "axios";
import {
  CellRange,
  ColDef,
  ColumnState,
  GetContextMenuItemsParams,
  GridApi,
  IRowNode,
  SortChangedEvent
} from '@ag-grid-community/core'
import bus from "@/event-bus/EventBus";
import {delay} from "@/utils/utils";
import {setTypeSources} from "@/components/grid-table/utils/cost-center";

export const TableActions = {
  Search: 'search',
  Refresh: 'refresh',
  Add: 'add',
  BulkActions: 'bulk',
  Export: 'export',
  View: 'view',
  Edit: 'edit',
  Delete: 'delete',
  Archive: 'archive',
  BulkDelete: 'bulk-delete',
}

export function hasAction(actions: string[], action: string) {
  return actions?.includes(action);
}

export function getSortProp(params: SortChangedEvent, gridApi?: GridApi) {
  gridApi?.showLoadingOverlay();
  const columns: ColumnState[] = params.columnApi.getColumnState();
  let sortOption: any = {}
  columns.forEach(column => {
    if (column.sort) {
      sortOption.prop = column.colId
      sortOption.order = column.sort
    }
  })

  let sortProp = null
  if (sortOption.order === 'asc') {
    sortProp = sortOption.prop
  }
  if (sortOption.order === 'desc') {
    sortProp = `-${sortOption.prop}`
  }
  sortProp = sortProp?.replace('attributes.', '')

  return sortProp
}

export function getTableData(gridApi: GridApi | undefined) {
  const data: any[] = []
  if (!gridApi) {
    return []
  }
  gridApi.forEachNode(node => {
    if (node.footer || node.group) {
      return
    }
    data.push(node.data)
  })
  return data
}

export function getTableNodes(gridApi: GridApi | undefined) {
  const nodes: IRowNode[] = []
  if (!gridApi) {
    return nodes
  }
  gridApi.forEachNode(node => {
    if (node.footer || node.group) {
      return
    }
    nodes.push(node)
  })
  return nodes
}

/**
 * Detects whether an array has duplicated objects.
 *
 * @param array
 * @param key
 */
export const hasDuplicatedObjects = <T>(array: T[], key: keyof T): boolean => {
  const _array = array.map((element: T) => element[key]);
  const noValues = _array.every((element) => element === undefined);
  if (noValues) {
    return false
  }
  const set = new Set(_array);
  return set.size !== _array.length;
};

export async function storeBatchEntriesProgress(gridApi: GridApi, endPoint = '', batchProps = {}) {
  let entries = getTableData(gridApi)

  const hasDuplicateOrders = hasDuplicatedObjects(entries, 'order')

  if (hasDuplicateOrders) {
    entries = entries.map((entry, index) => {
      entry.order = index + 1
      return entry
    })
  }

  const entriesToUpdate = entries.filter(entry => entry.dirty && entry.id).map(entry => {
    entry = setTypeSources(entry)
    return entry
  })

  const entriesToStore = entries.filter(entry => !entry.id && entry.dirty).map(entry => {
    entry = setTypeSources(entry)
    return {
      ...entry,
      ...batchProps
    }
  })

  const promises = []

  if (entriesToStore.length) {
    promises.push(axios.post(`/restify/${endPoint}/bulk`, entriesToStore))
  }

  if (entriesToUpdate.length) {
    promises.push(axios.post(`/restify/${endPoint}/bulk/update`, entriesToUpdate))
  }

  await Promise.all(promises)
}

export function deleteSelectedRows(params: GetContextMenuItemsParams) {
  const cellRange: CellRange[] | null = getCellRange(params)
  const tableData = getTableData(params.api)
  cellRange?.forEach((range) => {
    let rowsToDelete = []
    const {startRow, endRow} = range
    const start = startRow?.rowIndex || 0
    const end = endRow?.rowIndex || 0
    for (let i = start; i <= end; i++) {
      rowsToDelete.push(tableData[i])
    }
    params.api.applyTransaction({
      remove: rowsToDelete
    })
  })
  params.api.clearRangeSelection()
}

export function insertEmptyRows(params: GetContextMenuItemsParams, rowCount: number, getEmptyRowFn = () => ({})) {
  const cellRange = getCellRange(params)
  const firstRange = cellRange?.[0]
  if (!firstRange) {
    return
  }
  const tableData = getTableData(params.api)
  const {endRow} = firstRange
  let start = endRow?.rowIndex || -1
  start = start + 1
  const row = tableData[start]
  let emptyRows = []
  for (let i = 0; i < rowCount; i++) {
    const newRow = getEmptyRow(row)
    let emptyRow = {}
    if (getEmptyRowFn) {
      emptyRow = getEmptyRowFn()
    }
    emptyRows.push({
      ...newRow,
      ...emptyRow,
    })
  }
  params.api.applyTransaction({
    add: emptyRows,
    addIndex: start
  })
}

type AnyObject = {
  [key: string]: any
}

function getEmptyRow(row: any) {
  let emptyRow: any = {
    _localId: crypto.randomUUID(),
  }
  for (let key in row) {
    if (key !== '_localId') {
      emptyRow[key] = null
    }
  }
  return emptyRow
}

export function getCellRange(params: GetContextMenuItemsParams): CellRange[] | null {
  if (!params?.api) {
    return []
  }

  return params.api.getCellRanges()
}

export function getCellRangeRowCount(params: GetContextMenuItemsParams) {
  const cellRange = getCellRange(params)
  const firstRange = cellRange?.[0]
  if (!firstRange) {
    return 0
  }
  const start = (firstRange.startRow?.rowIndex || 0)
  const end = firstRange.endRow?.rowIndex || 0
  return (end + 1) - start
}

function tableDataLength(gridApi: GridApi) {
  const entries = getTableData(gridApi)
  return entries.length
}

export async function addNewRow(params: GetContextMenuItemsParams, getEmptyRow?: (row: any) => AnyObject, addNewRowAtTop?: boolean) {
  const gridApi = params.api
  let row: any = {}
  if (getEmptyRow) {
    row = getEmptyRow(params)
  }

  row.dirty = true
  if (row.hasOwnProperty('order')) {
    row.order = tableDataLength(gridApi) + 1
  }
  gridApi.applyTransaction({
    add: [row],
    addIndex: addNewRowAtTop ? 0 : tableDataLength(gridApi) + 1,
  });

  const colDefs: any = gridApi.getColumnDefs()
  const colKey = colDefs.find((col: ColDef) => !col.hide)?.field
  const rowNode: any = gridApi.getRowNode(row._localId)
  await delay(200)
  gridApi.setFocusedCell(rowNode.rowIndex, colKey)

  try {
    gridApi.startEditingCell({
      rowIndex: rowNode.rowIndex,
      colKey: colKey,
    })
  } catch (err) {
    console.log('Could not start editing the new row')
  }

  return row
}

export function duplicateRow(params: any, mapDuplicateRow?: (data: any) => any, addNewRowAtTop?: boolean) {
  const node = params.node
  if (!node) {
    return
  }

  let row = structuredClone(params.node.data)
  row.id = undefined
  row.dirty = true
  row.creating = false
  row.updating = false
  row._localId = crypto.randomUUID()

  if (row.hasOwnProperty('order')) {
    row.order = tableDataLength(params.api) + 1
  }

  if (mapDuplicateRow) {
    row = mapDuplicateRow(row)
  }

  params.api.applyTransaction({
    add: [row],
    addIndex: addNewRowAtTop ? 0 : params.node.rowIndex + 1,
  });

  return row
}

export function getRowData(params: any) {
  const {rowIndex} = params
  const rowNode = params.api.getDisplayedRowAtIndex(rowIndex)
  return rowNode?.data
}

export const editableTableProps = {
  pagination: false,
  showPagination: false,
  enableRangeSelection: true,
  compact: true,
  authorizeToCopyLastRow: true,
  addRowOnTab: true,
  detailRowAutoHeight: true,
  sortable: false,
  domLayout: 'autoHeight',
}

function validateDataTable() {
  return new Promise((resolve) => {
    bus.$emit('validate-grid-data', (response: boolean) => {
      resolve(response)
    })
    setTimeout(() => {
      resolve(false)
    }, 300)
  })
}

export async function validateAgDataTable() {
  return await validateDataTable()
}

export async function storeEntriesProgress(entries: any[], url: string) {
  entries = entries.filter(entry => entry.dirty || !entry.id)

  const entriesToSave = entries.filter(entry => !entry.id)
  const entriesToUpdate = entries.filter(entry => entry.id)
  const promises = []

  if (entriesToSave.length) {
    const savePromise = axios.post(`${url}/bulk`, entriesToSave)
    promises.push(savePromise)
  }
  if (entriesToUpdate.length) {
    const updatePromise = axios.post(`${url}/bulk/update`, entriesToUpdate)
    promises.push(updatePromise)
  }
  await Promise.all(promises)
}

export function toggleRowExpand(gridApi: any, value: boolean, level: number = -1) {
  gridApi.forEachNode((node: any) => {
    if (level === -1) {
      gridApi.setRowNodeExpanded(node, value);
    } else if (node.level === level) {
      gridApi.setRowNodeExpanded(node, value);
    }
  });
}

export async function saveInlineEntry({row, params, url, transformData}: any) {
  if (params.data.creating) {
    // We are in the process of creating this row already, therefore delaying the save
    return params.data
  }
  let rowData = params.data
  try {
    params.data.creating = true
    params.node.setData(params.data)
    delete row.id
    let {data} = await axios.post(url, row)
    if (transformData) {
      data = transformData(data)
    }
    rowData = mergeRowData(params.data, data)
    const prevData = params.data
    params.data = rowData
    params.data._localId = prevData._localId
    params.node.setData(params.data)
  } finally {
    params.data.creating = false
    params.node.setData(params.data)
  }
  return rowData
}

export async function updateInlineEntry({ row, params, url, method, transformData }: any) {
  try {
    let requestMethod: string = method || 'put'
    params.data.updating = true
    params.node.setData(params.data)
    // @ts-ignore
    let { data } = await axios[requestMethod](url, row)
    if (transformData) {
      data = transformData(data)
    }
    const rowData = mergeRowData(params.data, data)
    params.data = rowData
    params.data.updating = false
    params.node.setData(rowData)
    return rowData
  } finally {
    params.data.updating = false
  }
}

function mergeRowData(localData: any, apiData: any) {
  let data = apiData?.attributes ? apiData.attributes : apiData
  return {
    ...localData,
    ...(data || {})
  }
}
