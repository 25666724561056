<template>
  <div class="import-data mt-4">
    <ImportForm v-bind="importSettings"
                @success="redirectToList"
    />
  </div>
</template>
<script>
  import ImportForm from "@/modules/common/components/import/ImportForm";
  import { getInitialMapping } from "@/modules/payroll/utils/timecardImportColumns";

  const colMappings = {
    Code: {
      value: 'code',
      label: 'Code',
      required: true,
      alternativeLabels: ['Rate Code'],
    },
    Description: {
      value: 'description',
      label: 'Description',
      alternativeLabels: ['Rate Description'],
    },
    State: {
      value: 'state',
      label: 'State',
      alternativeLabels: ['Rate State'],
    },
    RateLimitType: {
      value: 'rate_limit_type',
      label: 'Rate Limit Type',
      alternativeLabels: ['Limit Type'],
    },
    CalculateBy: {
      value: 'calculate_by',
      label: 'Calculate By',
      alternativeLabels: [],
    },
    MultiplyBy: {
      value: 'multiply_by',
      label: 'Multiply By',
      alternativeLabels: [],
    },
    RateLimit: {
      value: 'rate_limit',
      label: 'Rate Limit',
      alternativeLabels: [],
    },
    EmployerRate: {
      value: 'employer_rate',
      label: 'Employer Rate',
      required: true,
      alternativeLabels: [],
    },
    EmployeeDeductionRate: {
      value: 'employee_deduction_rate',
      label: 'Employee Deduction Rate',
      alternativeLabels: [],
    },
    StartDate: {
      value: 'start_date',
      label: 'Start Date',
      required: true,
      alternativeLabels: [],
    }

  }

  const options = [
    colMappings.Code,
    colMappings.Description,
    colMappings.State,
    colMappings.RateLimitType,
    colMappings.CalculateBy,
    colMappings.MultiplyBy,
    colMappings.RateLimit,
    colMappings.EmployerRate,
    colMappings.EmployeeDeductionRate,
    colMappings.StartDate,
  ]

  export default {
    components: {
      ImportForm,
    },
    data() {
      return {
        importSettings: {
          url: '/restify/workers-comp-rates/import',
          columnOptions: options,
          columnMappings: colMappings,
          initialMapping: getInitialMapping(options),
          type: 'workers-comp-rates-import',
          resource: 'workers-comp-rates',
          showTemplateDownload: true,
        },
      }
    },
    methods: {
      async redirectToList() {
        await this.$router.push('/payroll/rates/worker-compensation-rates')
        await this.$store.dispatch('globalLists/getPRConfiguration')
      },
    }
  }
</script>
