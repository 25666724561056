<template>
  <WorkSiteForm
      v-loading="workSiteLoading"
      :data="workSite"
      @refresh="getWorkSite"
  />
</template>
<script>
  import WorkSiteForm from '@/modules/service-billing/components/WorkSiteForm'

  export default {
    components: {
      WorkSiteForm,
    },
    computed: {
      workSiteLoading() {
        return this.$store.state.serviceBilling.workSiteLoading
      },
      workSite() {
        const workSite = this.$store.state.serviceBilling.currentWorkSite
        return {
          ...workSite?.attributes,
          ...workSite?.relationships,
        }
      },
    },
    methods: {
      getWorkSite(id) {
        this.$store.dispatch('serviceBilling/getWorkSite', id)
      },
    },
  }
</script>
