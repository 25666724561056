<template>
  <entity-preview
      :entity="materialData"
      :key="`preview-${materialData.id}`"
      :url="`/purchasing-inventory/materials/${materialData.id}/view`"
      :value-to-display="valueToDisplay"
      :parent-entity-id="materialData.id"
      :show-preview="showPreview"
      :target="target"
      :is-link="!editableCell"
      :link-class="linkClass"
      entity-name="material"
  />
</template>
<script>
  export default {
    name: 'MaterialLink',
    props: {
      id: [String, Number],
      data: {
        type: Object,
        default: () => ({}),
      },
      showPreview: {
        type: Boolean,
        default: true,
      },
      target: String,
      linkClass: String,
      showName: {
        type: Boolean,
        default: true,
      },
      showDescription: {
        type: Boolean,
        default: true,
      },
      editableCell: Boolean,
    },
    computed: {
      allMaterials() {
        return this.$store.state.globalLists.materials
      },
      materialData() {
        if (this.id) {
          return this.allMaterials.find(material => material.id === this.id)
        }
        return this.data?.attributes || this.data || {}
      },
      valueToDisplay() {
        if (!this.materialData.id) {
          return
        }

        if (!this.showName || !this.showDescription) {
          return this.materialData.code
        }
        const description = this.materialData.description ? `(${this.materialData.description})` : ''
        return `${this.materialData.code} ${description}`
      },
    },
  }
</script>
