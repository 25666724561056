export function toRestifyArray(data: any[]) {
  return data.map((row: any) => {
    return {
      id: row.id,
      attributes: {
        ...row
      },
      meta: row?.meta || {}
    }
  })
}
