<template>
  <EmployeeRatesForm :data="employee"
                     :hide-employee-info="true"
                     :rate-type="employeeRatesTypes.PayRates"
  />
</template>
<script>
  import { employeeRatesTypes } from '@/modules/payroll/components/rates/util'
  import EmployeeRatesForm from '@/modules/payroll/components/employee-rates/EmployeeRatesForm'

  export default {
    components: {
      EmployeeRatesForm,
    },
    data() {
      return {
        employeeRatesTypes,
      }
    },
    computed: {
      employee() {
        return this.$store.state.payroll.currentEmployee
      },
    }
  }
</script>
