<template>
    <base-form :loading="loading"
               :showCancel="showCancel"
               :showBack="showBack"
               :save-text="$t('Create bid item')"
               :update-text="$t('Update bid item')"
               layout="modal"
               @cancel="$emit('cancel')"
               @submit="onSubmit"
    >
        <div class="col-span-6 md:col-span-3">
            <base-input v-model="model.code"
                        :label="$t('Code')"
                        :placeholder="$t('Bid Item Code')"
                        id="code"
                        rules="required|code">
            </base-input>
        </div>
        <div class="col-span-6 md:col-span-3">
            <entity-select v-model="model.bid_id"
                           url="/restify/bids"
                           :label="$t('Bid')"
                           label-key="number"
                           value-key="id"
                           rules="required"
            />
        </div>
        <div class="col-span-6">
            <base-input v-model="model.description"
                        :label="$t('Description')"
                        :placeholder="$t('Description')"
                        id="description"
                        rules="max:150">
            </base-input>
        </div>
        <div class="col-span-6 md:col-span-3">
            <base-select v-model="model.status"
                         :label="$t('Status')"
                         :placeholder="$t('Status')"
                         :options="statusOptions"
                         id="status"
            />
        </div>
        <div class="col-span-6 md:col-span-3">
            <base-select v-model="model.type"
                         :label="$t('Type')"
                         :placeholder="$t('Type')"
                         :options="typeOptions"
                         id="type"
            />
        </div>
    </base-form>
</template>
<script>
  import axios from 'axios'

  export default {
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        loading: false,
        showCancel: true,
        showBack: true,
        activeGroups: [],
        model: {
          bid_id: undefined,
          code: '',
          status: 'Active',
          type: 'Base',
          description: '',
        },
        statusOptions: [
          {
            label: this.$t('Active'),
            value: 'Active',
          },
          {
            label: this.$t('Inactive'),
            value: 'Inactive',
          },
        ],
        typeOptions: [
          {
            label: this.$t('Base'),
            value: 'Base',
          },
          {
            label: this.$t('Alternate'),
            value: 'Alternate',
          },
        ],
      }
    },
    watch: {
      'data.attributes': {
        immediate: true,
        handler(value) {
          this.model = {
            ...this.model,
            ...value,
          }
        },
      },
    },
    methods: {
      async onSubmit() {
        try {
          this.loading = true
          if (this.model.id) {
            await axios.put(`/restify/bid-items/${this.model.id}`, this.model)
            this.$success(this.$t('Bid Item updated'))
          } else {
            await axios.post('/restify/bid-items', this.model)
            this.$success(this.$t('Bid Item added'))
          }
          this.$emit('save', this.model)
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not update the bid item description'))
        } finally {
          this.loading = false
        }
      },
      mounted() {
        const codeInput = document.querySelector('#code')
        if (codeInput) {
          codeInput.focus()
        }
      },
    },
  }
</script>
