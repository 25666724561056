<template>
  <base-form layout="modal"
             :loading="loading"
             :save-text="$t('Create billing rate type')"
             :update-text="$t('Update billing rate type')"
             grid-classes=""
             @submit="onSubmit"
  >
    <base-input v-model="model.title"
                :label="$t('Title')"
                id="code"
                :placeholder="$t('Code')"
                rules="required|max:100">
    </base-input>
  </base-form>
</template>
<script>
  import axios from 'axios'

  export default {
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      addTemporaryEntity: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        loading: false,
        model: {
          title: '',
        },
      }
    },
    watch: {
      'data.attributes': {
        immediate: true,
        handler(value) {
          if (!value) {
            return
          }
          this.model = {
            ...this.model,
            ...value,
          }
        },
      },
    },
    methods: {
      async onSubmit() {
        if (this.addTemporaryEntity) {
          const rateType = {
            attributes: {
              ...this.model,
              isTemporary: true,
              id: Math.random(),
            },
          }
          return this.$emit('save', rateType)
        }
        try {
          this.loading = true
          if (this.model.id) {
            await axios.put(`/restify/billing-rate-types/${this.model.id}`, this.model)
            this.$success(this.$t('Billing rate type updated.'))
          } else {
            await axios.post('/restify/billing-rate-types', this.model)
            this.$success(this.$t('Billing rate type created.'))
          }
          if (!this.$createAnotherEntity) {
            return this.$emit('save', this.model)
          }
          this.createAnotherEntity()
        } catch (err) {
          if (err.handled) {
            return
          }

          this.$error(this.$t('Could not update the billing rate type.'))
        } finally {
          this.loading = false
        }
      },
      createAnotherEntity() {
        this.model.id = undefined
        this.focusOnTitleInput()
      },
      focusOnTitleInput() {
        const titleInput = document.querySelector('#title')
        if (!titleInput) {
          return
        }
        titleInput.focus()
      },
    },
  }
</script>
