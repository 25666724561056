<template>
  <base-form
      :save-text="i18n.t('Update')"
      :show-buttons="can('companies_update')"
      :loading="loading"
      :auto-height="true"
      grid-classes="grid grid-cols-8 gap-x-3"
      @submit="updateSettings"
  >
    <template v-slot:title>
      {{ i18n.t('Report Settings') }}
    </template>
    <template v-slot:description>
      <div class="my-1">
        {{ i18n.t('Enhance the display and printing of reports with colors, patterns, and lines.') }}
      </div>
      <ReportShadingPreview
          :settings="shadingData"
      />
    </template>

    <slot>

    </slot>
    <div class="col-span-8 flex justify-end">
      <base-button
          variant="gray-link"
          @click="resetToDefault"
      >
        <IconRefresh class="w-4 h-4 mx-1"/>
        {{ i18n.t('Reset to Default') }}
      </base-button>
    </div>

    <div class="col-span-8">
      <AgDataTable
          v-bind="editableTableProps"
          :columns="columns"
          :data="tableData"
          ref="gridTable"
          id="gridTable"
      />
    </div>
  </base-form>
</template>
<script setup lang="ts">
  import i18n from '@/i18n'
  import pick from 'lodash/pick'
  import { formattedText } from '@/utils/utils'
  import { computed, PropType, ref, watch } from 'vue'
  import { Column } from '@/components/ag-grid/tableTypes'
  import { can } from '@/modules/auth/plugins/permissionsPlugin'
  import { cellClasses } from '@/components/ag-grid/columnUtils'
  import { editableTableProps } from '@/components/ag-grid/tableUtils'
  import { cellEditors } from '@/components/ag-grid/cellEditors/cellEditors'
  import {
    ReportSectionTypePattern,
    ReportShading,
    ReportShadingDefaults,
    ReportShadingPattern,
    ReportShadingType,
    ReportShadingTypesDefaults,
  } from '@/modules/common/components/reports/reportShadingTypes'
  import ReportShadingPreview from '@/modules/common/components/reports/ReportShadingPreview.vue'

  const props = defineProps({
    settings: Object as PropType<Record<string, any>>,
    loading: Boolean,
  })

  const emit = defineEmits(['save'])

  const model = ref({})

  const patternOptions = [
    {
      label: i18n.t('None'),
      value: ReportShadingPattern.None,
    },
    {
      label: i18n.t('Solid'),
      value: ReportShadingPattern.Solid,
    },
    {
      label: i18n.t('Alternate'),
      value: ReportShadingPattern.Alternate,
    },
  ]

  let tableData = ref([] as ReportShadingType[])

  const columns: Column[] = [
    {
      headerName: i18n.t('Section'),
      field: 'key',
      cellClass: cellClasses.ReadOnlyLight,
      valueFormatter: params => {
        return formattedText(params.value)
      },
    },
    {
      headerName: i18n.t('Pattern'),
      field: 'pattern',
      editable: true,
      cellEditor: cellEditors.BaseSelect,
      cellEditorParams: {
        options: patternOptions,
      },
      valueFormatter: params => {
        return formattedText(params.value)
      },
    },
    {
      headerName: i18n.t('Color 1'),
      field: 'color1',
      editable: true,
      cellEditor: cellEditors.ColorPicker,
      component: 'ColorPickerPreview',
    },
    {
      headerName: i18n.t('Color 2'),
      field: 'color2',
      editable: (params) => {
        return params.data?.pattern === ReportShadingPattern.Alternate
      },
      cellClass: (params) => {
        const isAlternate = params.data?.pattern === ReportShadingPattern.Alternate
        return isAlternate ? '' : cellClasses.ReadOnlyLight
      },
      cellEditor: cellEditors.ColorPicker,
      component: 'ColorPickerPreview',
      cellRendererParams: (params: any) => {
        const isAlternate = params.data?.pattern === ReportShadingPattern.Alternate
        return {
          disabled: !isAlternate,
        }
      }
    },
  ]

  const shadingData = computed(() => {
    const fieldsToPick = Object.keys(ReportShadingDefaults)
    let shading = <ReportShading>{}

    tableData.value.forEach((item: ReportShadingType) => {
      // @ts-ignore
      shading[item.key] = pick(item, fieldsToPick)
    })
    return shading
  })

  function updateSettings() {
    emit('save', {
      ...model.value,
      shading: shadingData.value,
    })
  }

  function mapTableData(settings: any) {
    const shading = settings.shading || {}

    tableData.value = Object.values(ReportSectionTypePattern).map(key => {
      if (!shading[key]) {
        return {
          ...ReportShadingDefaults,
          _localId: crypto.randomUUID(),
          key,
        }
      } else {
        return {
          ...shading[key],
          _localId: crypto.randomUUID(),
          key,
        }
      }
    }) as ReportShadingType[]
  }

  function resetToDefault() {
    mapTableData({
      shading: ReportShadingTypesDefaults,
    })
  }

  function setModel(settings: any) {
    model.value = settings
  }

  watch(() => props.settings, settings => {
    mapTableData(settings)
    setModel(settings)
  }, { immediate: true })

</script>
