import { set } from 'vue'
import store from '@/store'
import get from "lodash/get";

export const cellClasses = {
  Invalid: 'invalid-ag-cell',
  InvalidLight: 'invalid-light-ag-cell',
  ReadOnly: 'readonly-ag-cell',
  ReadOnlyLight: 'readonly-ag-cell',
  Highlight: 'highlight-ag-cell',
  Warning: 'warning-ag-cell',
  HeaderEditable: 'header-editable',
}

export function requiredValueSetter(params, defaultValue, validator) {
  const value = params.newValue
  const field = params.column.colId
  let valid = false
  if (!field) {
    return false
  }
  if (value !== undefined && value !== '' && value !== null) {
    set(params.data, field, params.newValue)
    valid = true
  }

  if (defaultValue !== undefined && !value) {
    set(params.data, field, defaultValue)
    valid = true
  }

  if (validator) {
    valid = validator(params.newValue)
  }

  if (!valid && validator) {
    set(params.data, field, params.oldValue)
  }

  return valid
}

export function booleanValueSetter(params) {
  let value = params.newValue
  const field = params.column.colId
  if (value === 'false' || value === '0') {
    value = false
  } else if (value === 'true' || value === '1') {
    value = true
  } else {
    value = !!value
  }

  set(params.data, field, value)
  return true
}

function isCellEditable(params) {
  return params.colDef.editable
}

export function getCellClasses(params) {
  if (!isCellEditable(params) || params.node.footer) {
    return ''
  }

  const field = params.colDef.field
  const value = params?.data?.[field]
  const hasValue = value !== undefined && value !== '' && value !== null
  return hasValue ? '' : cellClasses.Invalid
}

export function validateTableData(tableId = 'gridTable') {
  const selector = `#${tableId} .${cellClasses.Invalid}`
  const firstInvalidCell = document.querySelector(selector)

  return !firstInvalidCell
}

export const percentageValidator = value => {
  value = +value
  return value >= 0 && value <= 100 && !isNaN(value)
}

export const markupValidator = value => {
  value = +value
  return value >= 0 && value <= 999.99 && !isNaN(value)
}


export function getResource(resourceId, resourceName) {
  const options = store.state.globalLists[resourceName] || []
  return options.find(op => op.id === resourceId) || {}
}

export function getResourceLabel(resourceId, resourceName, fieldToShow = false, showDescription = true) {
  const option = getResource(resourceId, resourceName)

  if (fieldToShow) {
    return option[fieldToShow]
  }

  const mainField = option.code || option.number

  if (!showDescription) {
    return mainField
  }

  const description = option.description || option.name

  return description ? `${mainField} (${description})` : mainField
}

export function getNodeValue({ params, row, column }) {
  let value = get(row, column.prop)
  let isSummary = isSummaryRow(params)
  let hasAggFunc = params?.colDef?.aggFunc

  const nodeData = params?.node?.aggData
  const isGroupRow = params?.node?.group
  const isFooterRow = params?.node?.footer

  if (isGroupRow && !isFooterRow && params?.node?.expanded) {
    return ''
  }

  if (isSummary && !hasAggFunc) {
    return ''
  }

  if (isSummary && hasAggFunc) {
    value = get(nodeData, column.prop)
  }

  return value
}

export function isSummaryRow(params) {
  let isFooterRow = params?.node?.footer
  let isGroupRow = params?.node?.group
  return isGroupRow || isFooterRow
}

export function stopEditingOnTab(params) {
  let isTabKey = params.event.key === 'Tab';
  if (isTabKey) {
    params.api.stopEditing();
  }
}
