export function setStepStates() {
  this.steps.forEach((step, index) => {
    if (index > (this.currentStep - 1)) {
      step.status = 'upcoming'
    } else {
      if (index < (this.currentStep - 1)) {
        step.status = 'complete'
      }
      if ((index + 1) === this.currentStep) {
        step.status = 'current'
      }
    }
  })
}

export function onBack() {
  if (this.currentStep === 1) {
    return
  }
  this.currentStep--
  this.setStepStates()
}

export function onNext() {
  if (this.currentStep === this.steps.length) {
    return this.submitForm()
  }
  this.currentStep++
  this.setStepStates()
}

export function onSetStep(step) {
  this.currentStep = step
  this.setStepStates()
}
