<template>
  <InvoiceTable
      :key="status"
      :invoice-status="status"
  />
</template>
<script>
  import { resourceStatuses } from '@/enum/enums'
  import InvoiceTable from '@/modules/accounts-payable/pages/invoices/InvoiceTable'

  export default {
    components: {
      InvoiceTable,
    },
    data() {
      return {
        status: resourceStatuses.Pending,
      }
    },
    watch: {
      '$route.meta': {
        immediate: true,
        handler(meta) {
          this.status = meta?.status
        },
      },
    },
  }
</script>
