<template>
  <div>
    <div class="grid grid-cols-3 gap-4 h-full">
      <div class="col-span-3 lg:col-span-2">
        <TimeOffPolicyEmployees
          v-if="!loading || timeOffPolicy.id"
          :time-off-policy="timeOffPolicy"
        />
      </div>
      <div class="col-span-3 lg:col-span-1">
        <base-detail-card
            :title="$t('Policy Details')"
            :key="timeOffPolicy.id"
            :entity="timeOffPolicy"
            :can-expand="false"
            :expanded="true"
            :loading="loading"
            entity-name="time-off-policy"
        >
          <base-detail-card-row
              :title="$t('Policy Name')"
              vertical
          >
            {{ timeOffPolicy.attributes.description }} ({{timeOffPolicy.attributes.code}})
          </base-detail-card-row>

          <base-detail-card-row
              :title="$t('Policy Description')"
              vertical
          >
            {{ timeOffPolicy.attributes.details || '' }}
          </base-detail-card-row>

          <base-detail-card-row
              :title="$t('State')"
              vertical
          >
            {{ timeOffPolicy.attributes.state }}
          </base-detail-card-row>

          <base-detail-card-row
              :title="$t('Account')"
              vertical
          >
            <AccountLink :number="timeOffPolicy.attributes.account"/>
          </base-detail-card-row>

          <base-detail-card-row
              :title="$t('Method')"
              vertical
          >
            {{ AccrualMethodLabels[timeOffPolicy.attributes.accrual_method] || '' }}
          </base-detail-card-row>

          <base-detail-card-row
              :title="$t('Max Hours')"
              vertical
          >
            {{ timeOffPolicy.attributes.max_hours || 'Unlimited' }} {{ $t('hours') }}
          </base-detail-card-row>

          <base-detail-card-row
              :title="$t('Waiting Period')"
              vertical
          >
            {{ timeOffPolicy.attributes.waiting_period_days || '0' }} {{ $t('days') }}
          </base-detail-card-row>
          <base-detail-card-row
              :title="$t('Usage Waiting Period')"
              vertical
          >
            {{ timeOffPolicy.attributes.usage_waiting_period_days || '0' }} {{ $t('days') }}
          </base-detail-card-row>

          <base-detail-card-row
              :title="$t('Rules')"
              vertical
          >
            <ul>
              <li
                  v-for="rule in timeOffPolicy?.relationships.tenures || []"
                  :key="rule.id"
              >
                <p>{{ getRuleText(rule.attributes) }}</p>
                <p v-if="rule.attributes.carryover_limit_hours">
                  {{ $t('Carryover limit ') }} {{ rule.attributes.carryover_limit_hours }}
                </p>
              </li>
            </ul>
          </base-detail-card-row>
        </base-detail-card>
      </div>
    </div>
  </div>
</template>
<script>
  import { AccrualMethodLabels } from "@/modules/payroll/utils/accrualMethods";
  import { getRuleText } from "@/modules/payroll/utils/timeOffUtils";
  import TimeOffPolicyEmployees from "@/modules/payroll/components/time-off/TimeOffPolicyEmployees";

  export default {
    components: {
      TimeOffPolicyEmployees
    },
    data() {
      return {
        AccrualMethodLabels,
      }
    },
    computed: {
      timeOffPolicy() {
        return this.$store.state.payroll.currentTimeOffPolicy
      },
      loading() {
        return this.$store.state.payroll.currentTimeOffPolicyLoading
      }
    },
    methods: {
      getRuleText(rule) {
        return getRuleText(rule, this.timeOffPolicy?.attributes?.accrual_method)
      },
    }
  }
</script>
