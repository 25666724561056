<template>
  <div class="flex flex-col space-y-1">
    <div
        v-for="(_, index) in user_notes"
        :key="index"
    >
      <base-input
          :label="index === 0 ? $t('Notes') : ''"
          :value="getValue(index)"
          inline-errors
          @input="updateValue($event, index)"
      />
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      value: {
        type: Array,
        default: []
      }
    },
    data() {
      return {
        user_notes: ['', '', '', '']
      }
    },
    methods: {
      getValue(index) {
        return this.value[index] || this.user_notes[index]
      },
      updateValue(value, index) {
        this.value[index] = value
      }
    }
  }
</script>
