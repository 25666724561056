<template>
  <BaseDetailCard
      :title="$t('Employee Details')"
      :key="employee.id"
      :entity="employee"
      :can-expand="false"
      :expanded="true"
      entity-name="employee"
  >
    <BaseDetailCardRow :title="$t('Full Name')" field="ssn_1">
      {{ getEmployeeFullName(employeeData) }}
    </BaseDetailCardRow>
    <BaseDetailCardRow :title="$t('SSN')" field="ssn_1" stripe>
      {{ getHiddenSSN(employeeData.ssn) }}
    </BaseDetailCardRow>

    <BaseDetailCardRow :title="$t('Gender & Minority')" field="ssn_1">
      <div class="flex space-x-1">
        <span>{{ getOptionLabel(sexOptions, employeeData.sex) }}</span>,
        <span>{{ getOptionLabel(minorityOptions, employeeData.minority) }}</span>
      </div>
    </BaseDetailCardRow>

    <BaseDetailCardRow :title="$t('Receive checks via email')" field="ssn_1" stripe>
      <status-badge :status="employeeData.receive_checks_via_email ? $t('Yes') : $t('No')"/>
    </BaseDetailCardRow>

    <BaseDetailCardRow v-if="employeeUser"
                       :title="$t('Associated User')">
      <router-link :to="`/settings/users/${employeeUser.id}/view`">
        {{ get(employeeUser, 'attributes.name', '') }}
      </router-link>
    </BaseDetailCardRow>
  </BaseDetailCard>
</template>
<script lang="ts" setup>
  import { computed, PropType } from 'vue'
  import { Employee } from "@/modules/common/types/models";
  import {
    getEmployeeFullName,
    getOptionLabel,
    minorityOptions,
    sexOptions
  } from "@/modules/payroll/utils/employeeUtils";
  import { getHiddenSSN } from "@/components/form/ssnUtils";
  import Data = API.Data;

  const props = defineProps({
    employee: {
      type: Object as PropType<Data<Employee>>,
      default: () => ({}),
      required: true,
    }
  })

  const employeeData = computed<Employee>(() => {
    return props.employee?.attributes || {}
  })


  const employeeUser = computed(() => {
    const { user } = props.employee?.relationships || {}
    return user
  })

</script>
