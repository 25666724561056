<template>
  <base-form layout="modal"
             :show-cancel="showCancel"
             @cancel="$emit('cancel')"
             @submit="onSubmit"
             :save-text="saveText"
  >
    <div class="col-span-6 md:col-span-3">
      <base-input v-model="model.label"
                  :label="$t('Label')"
                  :name="$t('Label')"
                  :placeholder="$t('Enter a label for this date')"
                  id="label"
                  :disabled="model.readonly"
                  rules="required|min:3|max:25"
      />
    </div>
    <div class="col-span-6 md:col-span-3">
      <base-date-picker v-model="model.value"
                        :name="$t('Date')"
                        :label="$t('Date')"
                        :placeholder="$t('Date')"
                        id="value"
      />
    </div>
  </base-form>
</template>
<script>

  export default {
    props: {
      showCancel: {
        type: Boolean,
        default: true,
      },
      entity: {
        type: Object,
        default: () => ({}),
      },
      saveText: String,
    },
    data() {
      return {
        model: {
          label: '',
          value: '',
          readonly: false,
        },
      }
    },
    methods: {
      onSubmit() {
        this.$emit('onSubmit', this.model)
      },
    },
    watch: {
      entity: {
        deep: true,
        immediate: true,
        handler(value) {
          if (!value) {
            return
          }
          this.model = {
            ...this.model,
            ...value,
          }
        },
      },
    },
  }
</script>
