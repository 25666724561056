<template>
  <base-report-form
      :filters="model"
      :report-number="reportNumber"
  >

    <template #filters>
      <base-filter-row :title="$t('Asset Type')">
        <base-select
            v-model="model.include_asset_type"
            :options="assetTypeOptions"
            :name="$t('Asset Type')"
            rules="required"
            class="col-span-2"
        />
        <div class="col-span-3 flex items-baseline">
          <span class="text-sm text-gray-600 font-medium whitespace-nowrap md:mr-3">{{ $t('Sort By') }}</span>
          <base-select
              v-model="model.group_by"
              :add-entity="false"
              :options="groupByOptions"
          />
        </div>
      </base-filter-row>
      <base-filter-row :title="$t('Equipments')">
        <RangeFilter
            :config="RangeFilterConfig.Equipment"
            :model="model"
            :from.sync="model.equipment_code_from"
            :to.sync="model.equipment_code_to"
            class="col-span-4"
        />
        <span class="whitespace-nowrap text-sm text-gray-600 font-medium">
          {{ $t('Equipment Classes') }}
        </span>
        <RangeFilter
            :config="RangeFilterConfig.EquipmentClass"
            :model="model"
            :from.sync="model.equipment_class_code_from"
            :to.sync="model.equipment_class_code_to"
            class="ml-6 col-span-4"
        />
      </base-filter-row>
      <base-filter-row :title="$t('Jobs')">
        <RangeFilter
            :config="RangeFilterConfig.Job"
            :model="model"
            :from.sync="model.job_number_from"
            :to.sync="model.job_number_to"
            class="col-span-4"
        />
        <span class="whitespace-nowrap text-sm text-gray-600 font-medium">
          {{ $t('Work Orders') }}
        </span>
        <RangeFilter
            :config="RangeFilterConfig.WorkOrder"
            :model="model"
            :from.sync="model.work_order_number_from"
            :to.sync="model.work_order_number_to"
            class="ml-6 col-span-4"
        />
      </base-filter-row>
      <base-filter-row :title="$t('Equipment Specialties')">
        <RangeFilter
            :config="RangeFilterConfig.EquipmentSpecialty"
            :model="model"
            :from.sync="model.equipment_specialty_code_from"
            :to.sync="model.equipment_specialty_code_to"
            class="col-span-4"
        />
      </base-filter-row>
      <period-year-range-filter v-model="model"/>
      <base-filter-row itemsAlign="items-center">
        <base-select
            v-model="model.equipment_type_id"
            :options="equipmentTypes"
            :label="$t('Equipment Type')"
            clearable
            class="col-span-2"
        />
        <base-select
            v-model="model.transaction_type"
            :options="equipmentTransactionTypeOptions"
            :label="$t('Transaction Type')"
            clearable
            class="col-span-2"
        />
        <base-checkbox
            v-model="model.balance_sheet_equipment_only"
            :label="$t('Balance Sheet Equipment Only')"
            class="col-span-4"
            id="balance_sheet_equipment_only"
        />
      </base-filter-row>
    </template>

    <template #table="{data, loading}">
      <EquipmentHistoryListingReportTable
          :data="data"
          :filters="model"
          :report-number="reportNumber"
          :data-loading="loading"
          ref="reportTable"
      />
    </template>

  </base-report-form>
</template>
<script lang="ts">
  import {defineComponent} from 'vue'
  import {globalResources} from '@/components/form/util'
  import {RangeFilterConfig, PeriodYearRangeFilter} from '@/components/range-filters'
  import {assetTypeOptions as defaultAssetTypeOptions, equipmentTransactionTypeOptions} from '@/enum/equipment'
  import {AssetTypes, GroupByTypes, EquipmentListing} from '@/modules/equipment/components/reports/filterTypes'
  import EquipmentHistoryListingReportTable from '@/modules/equipment/components/reports/EquipmentHistoryListingReportTable.vue'

  export default defineComponent({
    components: {
      PeriodYearRangeFilter,
      EquipmentHistoryListingReportTable,
    },
    data() {
      return {
        reportNumber: 6871,
        RangeFilterConfig,
        equipmentTransactionTypeOptions,
        model: <EquipmentListing>{
          include_asset_type: AssetTypes.All,
          group_by: GroupByTypes.EquipmentCode,
          equipment_code_from: null,
          equipment_code_to: null,
          equipment_class_code_from: null,
          equipment_class_code_to: null,
          job_number_from: null,
          job_number_to: null,
          work_order_number_from: null,
          work_order_number_to: null,
          equipment_specialty_code_from: null,
          equipment_specialty_code_to: null,
          balance_sheet_equipment_only: false,
          period_start: null,
          fiscal_year_start: null,
          period_end: null,
          fiscal_year_end: null,
          equipment_type_id: null,
          transaction_type: null,
        },
        assetTypeOptions: [
          {
            label: this.$t('All'),
            value: AssetTypes.All,
          },
          ...defaultAssetTypeOptions,
          {
            label: this.$t('Exclude Disposed'),
            value: AssetTypes.ExcludeDisposed,
          },
        ],
        groupByOptions: [
          {
            label: this.$t('Equipment Code'),
            value: GroupByTypes.EquipmentCode,
          },
          {
            label: this.$t('Class Code'),
            value: GroupByTypes.ClassCode,
          },
          {
            label: this.$t('Job Number'),
            value: GroupByTypes.JobNumber,
          },
          {
            label: this.$t('Work Order Number'),
            value: GroupByTypes.WorkOrderNumber,
          },
          {
            label: this.$t('Journal Number'),
            value: GroupByTypes.JournalNumber,
          },
          {
            label: this.$t('G/L Account'),
            value: GroupByTypes.GLAccount,
          },
          {
            label: this.$t('Eqp Maint Code'),
            value: GroupByTypes.EqpMaintenanceCode,
          }
        ],
      }
    },
    computed: {
      equipmentTypes(): Array<Record<string, any>> {
        const types = this.$store.getters['globalLists/getResourceOptions'](globalResources.EquipmentTypes)
        return types.map((type: Record<string, any>) => ({
          label: type.name,
          value: type.id,
        }))
      },
    }
  })
</script>
