<template>
  <entity-select
      v-bind="$attrs"
      v-on="$listeners"
      :options="options"
      :name="$attrs.name || $t('Sales Tax District')"
      :placeholder="$attrs.placeholder || $t('Sales tax district')"
      :label="$attrs.label !== undefined ? $attrs.label : $t('Sales Tax District')"
      :id="$attrs.id || 'sales-tax-district-select'"
      :table-columns="tableColumns"
      :label-format="formatLabel"
      :hide-label="hideLabel"
      :add-label="$t('Add new District')"
      :add-entity="addEntity"
      url="/restify/districts"
  />
</template>
<script>

  import { formatDistrictLabel } from "@/modules/accounts-receivable/components/lump-sum-billings/lumpSumBillingUtils";

  export default {
    name: 'SalesTaxDistrictSelect',
    inheritAttrs: false,
    props: {
      hideLabel: {
        type: Boolean,
        default: false,
      },
      addEntity: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        tableColumns: [
          {
            name: this.$t('State'),
            prop: 'attributes.state',
            minWidth: 60,
            maxWidth: 60,
          },
          {
            name: this.$t('Code'),
            prop: 'attributes.code',
            minWidth: 60,
            maxWidth: 60,
          },
          {
            name: this.$t('Description'),
            prop: 'attributes.description',
            minWidth: 220,
            maxWidth: 220,
          },
          {
            name: this.$t('Tax'),
            prop: 'attributes.sales_tax_percent',
            minWidth: 80,
            maxWidth: 120,
            component: 'FormattedPercent',
          },
        ],
      }
    },
    computed: {
      options() {
        return this.$store.state.globalLists[this.$globalResources.SalesTaxDistricts]
      },
    },
    methods: {
      formatLabel(district) {
        return formatDistrictLabel(district.attributes)
      },
    },
  }
</script>
