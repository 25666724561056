import colors from 'tailwindcss/colors'

const types = {
  SET_THEME: 'SET_THEME',
  SET_COLOR: 'SET_COLOR',
  SET_PRIMARY_COLOR: 'SET_PRIMARY_COLOR',
  SET_FONT_SCALE: 'SET_FONT_SCALE',
};

const defaultFontSizes = {
  '--size-xxs': '10px',
  '--size-xs': '12px',
  '--size-sm': '14px',
  '--size-base': '16px',
  '--size-lg': '18px',
  '--size-xl': '20px',
  '--size-2xl': '24px',
  '--size-3xl': '30px',
  '--size-4xl': '36px',
  '--size-5xl': '48px',
  '--size-6xl': '64px',
}
const state = {
  theme: 'dark',
  color: 'blue',
  fontSize: '1rem',
  primaryColor: {
    ...colors.blue,
  },
  fontScaleFactor: 1,
};

const mutations = {
  [types.SET_THEME]: (state, value) => {
    state.theme = value;
  },
  [types.SET_COLOR]: (state, value) => {
    state.color = value;
  },
  [types.SET_PRIMARY_COLOR]: (state, value) => {
    state.primaryColor = value;
  },
  [types.SET_FONT_SCALE]: (state, value) => {
    state.fontScaleFactor = value;
  },
};

function setVar(name, value) {
  let root = document.documentElement;
  root.style.setProperty(name, value);
}

function sePrimaryColor(colors) {
  for (let key in colors) {
    setVar(`--primary-${key}`, colors[key])
  }
}

function setFontSizes(scaleFactor = 1) {
  for (let key in defaultFontSizes) {
    setVar(key, `calc(${defaultFontSizes[key]} * ${scaleFactor})`)
  }
}

const actions = {
  switchTheme({ commit }, { theme, color, fontSizeScale }) {
    if (theme) {
      commit(types.SET_THEME, theme)
    }

    const primaryColor = colors[color]

    if (primaryColor) {
      sePrimaryColor(primaryColor)
      commit(types.SET_COLOR, color)
    }
    if (fontSizeScale) {
      commit(types.SET_FONT_SCALE, fontSizeScale)
      setFontSizes(fontSizeScale)
    }
  },
  setDefaultPreferences({ state }) {
    const primaryColor = colors[state.color]
    if (primaryColor) {
      sePrimaryColor(primaryColor)
    }
    setFontSizes(state.fontScaleFactor)
  }
};
const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
