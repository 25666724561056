import { formatDate } from "@/plugins/dateFormatPlugin";
import { paymentTypes } from "@/modules/accounts-payable/components/invoice/paymentUtils";

const today = formatDate(new Date(), 'yyyy-MM-dd')

export const emptyCheck = {
  type: paymentTypes.Check,
  number: null,
  bank_id: null,
  joint_vendor_id: null,
  group: 0,
  amount: 0,
  discount_amount: 0,
}
