<template>
  <base-button variant="danger-link"
               v-bind="$attrs"
               v-on="$listeners"
  >
    <Trash2Icon class="w-5 h-5 mr-2"></Trash2Icon>
    <span>
      <slot>{{$t('Delete')}}</slot>
    </span>
  </base-button>
</template>
<script>
  import { Trash2Icon } from 'vue-feather-icons'

  export default {
    name: 'delete-button',
    components: {
      Trash2Icon,
    }
  }
</script>
<style>
</style>
