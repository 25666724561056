export enum unionReportFormats {
  ByCraft = 'craft_code',
  ByEmployeeSummary = 'employee_summary',
  ByEmployeeWeek = 'employee_week',
  ByJobHourlyPay = 'job_hourly_pay',
  ByJobSummary = 'job_summary',
  ByJobAndEmployee = 'job_and_employee',
  ByJobAndEmployeeTotals = 'job_and_employee_totals',
  ByCraftHoursPaid = 'craft_hours_paid',
}

export type UnionReportFormat = typeof unionReportFormats[keyof typeof unionReportFormats]
