<template>
  <base-select
      v-bind="$attrs"
      v-on="$listeners"
      :name="$attrs.name || $t('Select sub account')"
      :label="$attrs.label"
      :placeholder="$attrs.placeholder || $t('Select sub account')"
      :options="subAccounts"
      :label-format="formatAccountName"
      :id="$attrs.id || 'sub-account-select'"
      :hide-label="hideLabel"
      :value-key="valueKey"
      label-key="number"
  />
</template>
<script>
  import { formatAccountName } from '@/modules/common/util/formattingUtils';
  import { searchByPriority } from '@/utils/utils'

  export default {
    inheritAttrs: false,
    props: {
      hideLabel: {
        type: Boolean,
        default: false,
      },
      valueKey: {
        type: String,
        default: 'id',
      },
    },
    computed: {
      subAccounts() {
        return this.$store.state.company.subAccounts
      },
    },
    methods: {
      formatAccountName,
      onFilterSubAccounts(options, searchQuery) {
        return searchByPriority(options, searchQuery, ['number', 'description'])
      },
    },
  }
</script>
