<template>
  <div class="mt-10 sm:mt-0">
    <base-form :loading="loading"
               :show-cancel="showCancel"
               :can-create-another-entity="!model.id"
               :save-text="$t('Create deduction code')"
               :update-text="$t('Update deduction code')"
               layout="modal"
               grid-classes="grid grid-cols-8 gap-x-4"
               @cancel="$emit('cancel')"
               @submit="onSubmit"
    >
      <div class="col-span-8 md:col-span-2 xl:col-span-1">
        <code-input
          v-model="model.code"
          :model="model"
          :resource-name="globalResources.DeductionCodes"
        />
      </div>
      <div class="col-span-8 md:col-span-2">
        <base-input v-model="model.description"
                    :label="$t('Description')"
                    id="description"
                    :placeholder="$t('Description')"
                    rules="max:150">
        </base-input>
      </div>
      <div class="col-span-8 md:col-span-2 xl:col-span-1">
        <w-2-code-select v-model="model.w2_code_id"
                         clearable
        />
      </div>
      <div class="col-span-8 md:col-span-2 xl:col-span-1">
        <base-select v-model="model.calculate_by"
                     :label="$t('Calculate By')"
                     :placeholder="$t('Calculate By')"
                     :options="calculateByOptions"
                     id="calculate_by"
                     rules="required"
                     @change="onChangeCalculateBy"
        />
      </div>
      <div class="col-span-8 md:col-span-2">
        <base-select v-model="model.multiply_by"
                     :key="model.calculate_by"
                     :label="$t('Multiply By')"
                     :placeholder="$t('Multiply By')"
                     :options="getMultiplyByOptions"
                     id="multiply_by"
                     rules="required"
        />
      </div>
      <div class="col-span-6 md:col-span-1">
        <ActiveSwitch v-model="model.active"></ActiveSwitch>
      </div>
      <div class="col-span-8 md:col-span-2">
        <account-select v-model="model.account"
                        :label="$t('Liability Account')"
                        :name="$t('Liability Account')"
                        value-key="number"
                        label-key="number"
                        id="liability_account"
                        rules="required"
        />
      </div>
      <div class="col-span-8 md:col-span-2 xl:col-span-1">
        <sub-account-select v-model="model.subaccount"
                            :label="$t('Liability Subaccount')"
                            :name="$t('Liability Subaccount')"
                            clearable
                            value-key="number"
                            label-key="number"
                            id="liability_subaccount"
        />
      </div>
      <div class="col-span-8 md:col-span-2">
        <vendor-select v-model="model.vendor_id"
                       :label="$t('Vendor Payee')"
                       id="vendor"
        />
      </div>
      <div class="col-span-8">
        <h5 class="form-section-title">
          {{ $t('Options') }}
        </h5>
        <base-checkbox
            v-model="model.separate_invocie_by_employee"
            :label="$t('Separate Invoice by Employee')"
            id="separate_invocie_by_employee"
        />
        <base-checkbox
            v-model="model.include_on_union_reports"
            :label="$t('Include on Union Report')"
            id="include_on_union_reports"
        />
      </div>
    </base-form>
  </div>
</template>
<script>
  import axios from 'axios'
  import {
    CALCULATE_BY_RATE_KEY,
    calculateByOptions,
    multiplyBy,
    multiplyOptions,
  } from '@/modules/payroll/components/rates/util'
  import { globalResources } from "@/components/form/util";

  export default {
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        globalResources,
        showCancel: true,
        loading: false,
        model: {
          code: '',
          description: '',
          w2_code_id: undefined,
          vendor_id: undefined,
          calculate_by: CALCULATE_BY_RATE_KEY,
          multiply_by: multiplyBy.REGULAR_HOURS,
          account: '',
          subaccount: '',
          include_on_union_reports: false,
          separate_invocie_by_employee: false,
          active: true,
          show_in_ytds: false,
        },
        calculateByOptions,
        multiplyOptions,
      }
    },
    computed: {
      getMultiplyByOptions() {
        return this.multiplyOptions[this.model.calculate_by]
      },
    },
    watch: {
      'data.attributes': {
        immediate: true,
        handler(value) {
          if (!value) {
            return
          }
          this.model = {
            ...this.model,
            ...value,
          }
        },
      },
    },
    methods: {
      async onSubmit() {
        try {
          this.loading = true
          if (this.model.id) {
            await axios.put(`/restify/deduction-codes/${this.model.id}`, this.model)
            this.$success(this.$t('Deduction code updated.'))
            this.$emit('save', this.model)
          } else {
            const { data } = await axios.post('/restify/deduction-codes', this.model)
            this.$success(this.$t('Deduction code created.'))
            this.$createAnotherEntity ? this.$emit('create-another') : this.$emit('save', data)
          }
        } catch (err) {
          if (err.handled) {
            return
          }
          console.log('err', err)
          this.$error(this.$t('Could not update the deduction code.'))
        } finally {
          this.loading = false
        }
      },
      onChangeCalculateBy(option) {
        if (option === CALCULATE_BY_RATE_KEY) {
          this.model.multiply_by = multiplyBy.REGULAR_HOURS
          this.multiplyByLabel = this.$t('Multiply % By')
          return
        }
        this.model.multiply_by = multiplyBy.REGULAR_HOURLY_PAY
        this.multiplyByLabel = this.$t('Multiply Rate By')
      },
    },
  }
</script>
