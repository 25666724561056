<template>
  <div>
    <div class="flex space-x-4 w-full">
      <BaseButton
        variant="gray-light"
        @click="onEdit"
      >
        <ExternalLinkIcon class="w-4 h-4 mr-2"/>
        <span>{{ $t('Edit') }}</span>
      </BaseButton>
      <BaseButton
        v-if="data?.attributes?.rejected_at || data?.attributes?.approved_at"
        :loading="resetLoading"
        variant="gray-light"
        @click="onReset"
      >
        <RotateCcwIcon class="w-4 h-4 mr-2"/>
        <span>{{ $t('Reset to Pending') }}</span>
      </BaseButton>
      <BaseButton
        v-if="!data?.attributes?.rejected_at"
        :loading="rejectLoading"
        variant="gray-light"
        @click="showRejectDialog = true"
      >
        <XIcon class="w-4 h-4 mr-2"/>
        <span>{{ $t('Reject') }}</span>
      </BaseButton>
      <BaseButton
        v-if="!data?.attributes?.approved_at"
        :loading="approveLoading"
        variant="primary"
        @click="onApprove">
        <CheckIcon class="w-4 h-4 mr-2"/>
        <span>{{ $t('Approve') }}</span>
      </BaseButton>
    </div>
    <BaseFormDialog
      v-if="showRejectDialog"
      :open.sync="showRejectDialog"
      :title="$t('Reject')"
      :append-to-body="true"
    >
      <BaseForm
        :save-text="$t('Reject')"
        :loading="rejectLoading"
        layout="modal"
        @submit="onReject">
        <base-textarea
          v-model="rejectReason"
          :label="$t('Reject Reason')"
          rules="required"
          class="col-span-6"
        />
      </BaseForm>
    </BaseFormDialog>
  </div>
</template>
<script>
import axios from "axios";
import { CheckIcon, XIcon, RotateCcwIcon, Edit2Icon, ExternalLinkIcon } from "vue-feather-icons";

export default {
  components: {
    Edit2Icon,
    XIcon,
    CheckIcon,
    RotateCcwIcon,
    ExternalLinkIcon
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    basePath: {
      type: String,
      required: true,
    },
    apiBasePath: {
      type: String,
      required: true,
    },
    entityName: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      approveLoading: false,
      rejectLoading: false,
      showRejectDialog: false,
      resetLoading: false,
      rejectReason: '',
    }
  },
  methods: {
    onEdit() {
      const url = `${window.location.origin}${this.basePath}/${this.data.id}/edit`
      window.open(url, '_blank')
    },
    async onReject() {
      try {
        this.rejectLoading = true
        await axios.post(`${this.apiBasePath}/${this.data.id}/actions?action=reject`, {
          rejection_reason: this.rejectReason,
        })
        this.rejectReason = ''
        this.showRejectDialog = false
        this.data.attributes.approved_at = null
        this.data.attributes.rejected_at = new Date().toISOString()
        this.data.attributes.rejection_reason = this.rejectReason
        this.$emit('reject')
      } catch (err) {
        if (!err.handled) {
          this.$error(this.$t(`Could not reject entity type`, { entity: this.entityName }))
        }
      } finally {
        this.rejectLoading = false
      }
    },
    async onApprove() {
      try {
        this.approveLoading = true
        await axios.post(`${this.apiBasePath}/${this.data.id}/actions?action=approve`)
        this.data.attributes.rejected_at = null
        this.data.attributes.approved_at = new Date().toISOString()
        this.$emit('approve')
      } catch (err) {
        if (!err.handled) {
          this.$error(this.$t(`Could not approve entity type`, { entity: this.entityName }))
        }
      } finally {
        this.approveLoading = false
      }
    },
    async onReset() {
      try {
        this.resetLoading = true
        await axios.post(`${this.apiBasePath}/${this.data.id}/actions?action=reset`)
        this.data.attributes.rejected_at = null
        this.data.attributes.approved_at = null
      } catch (err) {
        if (!err.handled) {
          this.$error(this.$t(`Could not reset entity type`, { entity: this.entityName }))
        }
      } finally {
        this.resetLoading = false
      }
    }
  }
}
</script>
