<template>
  <div class="import-data mt-4">
    <ImportForm v-bind="importSettings"
                @success="redirectToList"
    />
  </div>
</template>
<script>
  import ImportForm from "@/modules/common/components/import/ImportForm";
  import { getInitialMapping } from "@/modules/payroll/utils/timecardImportColumns";

  const colMappings = {
    Code: {
      value: 'code',
      label: 'Code',
      alternativeLabels: ['Craft Code'],
      required: true,
    },
    Level: {
      value: 'level',
      label: 'Level',
      alternativeLabels: ['Craft Level'],
      required: true,
    },
    Description: {
      value: 'description',
      label: 'Description',
      alternativeLabels: [],
    },
    BillingDescription: {
      value: 'billing_description',
      label: 'Billing Description',
      alternativeLabels: [],
    },
    Trade: {
      value: 'trade',
      label: 'Trade',
      alternativeLabels: [],
    },
    Classification: {
      value: 'classification',
      label: 'Classification',
      alternativeLabels: [],
    },
  }

  const options = [
    colMappings.Code,
    colMappings.Level,
    colMappings.Trade,
    colMappings.Description,
    colMappings.Classification,
    colMappings.BillingDescription,
  ]

  export default {
    components: {
      ImportForm,
    },
    data() {
      return {
        importSettings: {
          url: '/restify/craft-codes/import',
          columnOptions: options,
          columnMappings: colMappings,
          initialMapping: getInitialMapping(options),
          type: 'craft-code-import',
          resource: 'craft-codes',
          showTemplateDownload: true,
        },
      }
    },
    methods: {
      async redirectToList() {
        await this.$router.push('/payroll/codes/craft-codes')
      },
    }
  }
</script>
