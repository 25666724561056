<template>
  <div>
    <base-input v-if="isReadOnly"
                key="readonly-input"
                v-bind="inputAttrs"
                :readonly="true"
                :value="hiddenValue"
                :rules="validationRules"
    >
      <template v-slot:suffix>
        <UnlockIcon @click="unlockInput"
                    @keyup.enter="unlockInput"
                    tabindex="0"
                    class="w-4 h-4 cursor-pointer"/>
      </template>
    </base-input>
    <base-input v-else
                ref="input"
                key="editable-input"
                v-on="listeners"
                v-bind="inputAttrs"
                v-input-mask="ssnMask"
                :rules="validationRules"
                :value="displayValue"
    >
      <template v-slot:suffix>
        <LockIcon @click="lockInput"
                  @keyup.enter="lockInput"
                  tabindex="0"
                  class="w-4 h-4 cursor-pointer"/>
      </template>
    </base-input>
  </div>
</template>
<script>
  import tokens from 'vue-the-mask/src/tokens'
  import masker from 'vue-the-mask/src/masker'
  import { LockIcon, UnlockIcon } from 'vue-feather-icons'
  import { getHiddenSSN } from "@/components/form/ssnUtils";

  export default {
    inheritAttrs: false,
    components: {
      UnlockIcon,
      LockIcon,
    },
    props: {
      value: {
        type: String,
        default: ''
      },
      readOnly: {
        type: Boolean,
        default: true
      },
    },
    data() {
      return {
        isReadOnly: this.readOnly,
        displayValue: this.value,
        ssnMask: '###-##-####',
      }
    },
    computed: {
      validationRules() {
        if (this.isReadOnly) {
          return {}
        }
        return {
          regex: /^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/,
        }
      },
      listeners() {
        return {
          ...this.$listeners,
          input: this.onInput
        }
      },
      inputAttrs() {
        return {
          ...this.$attrs,
          placeholder: this.$attrs.placeholder || '856-45-6789',
          label: this.$attrs.label !== undefined ? this.$attrs.label : this.$t('SSN'),
          name: this.$attrs.name || this.$t('SSN'),
          id: this.$attrs.id || 'ssn-input'
        }
      },
      hiddenValue() {
        return getHiddenSSN(this.value)
      }
    },
    methods: {
      onInput(val) {
        this.displayValue = val
        let rawValue = masker(val, this.ssnMask, false, tokens)
        this.$emit('input', rawValue)
      },
      unlockInput() {
        this.isReadOnly = false
        this.$nextTick(() => {
          const input = this.$el.querySelector('input')
          input && input.focus()
        })
      },
      lockInput() {
        this.isReadOnly = true
      }
    },
    watch: {
      value(val) {
        if (val && !this.displayValue) {
          this.displayValue = masker(val, this.ssnMask, true, tokens)
        }
      }
    }
  }
</script>
