<template>
  <el-collapse :value="activeCollapse"
               class="sm:rounded-md border border-gray-200 overflow-hidden collapse-form"
               @change="onChange"
  >
    <el-collapse-item :title="title"
                      name="collapse-item"
    >
      <template #title>
        <slot name="title"/>
      </template>
      <base-form v-bind="$attrs"
                 v-on="$listeners"
                 @submit="onSubmit"
      >
        <template v-for="(_, name) in $slots" v-slot:[name]="slotData">
          <slot :name="name" v-bind="slotData"/>
        </template>
      </base-form>
    </el-collapse-item>
  </el-collapse>
</template>
<script>

  export default {
    props: {
      title: String,
      expanded: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        activeCollapse: ['collapse-item'],
      }
    },
    methods: {
      // *triggers when active panels change
      onChange(state) {
        this.$emit('update:expanded', !!state.length)
      },
      expand() {
        this.activeCollapse = ['collapse-item']
      },
      collapse() {
        this.activeCollapse = []
      },
      onSubmit() {
        this.collapse()
      }
    },
    watch: {
      expanded: {
        immediate: true,
        handler(expanded) {
          if (expanded) {
            this.expand()
          } else {
            this.collapse()
          }
        }
      }
    }
  }
</script>
<style lang="scss">
  .collapse-form .el-collapse-item {
    .el-collapse-item__header {
      @apply text-base px-4 font-bold leading-8 text-gray-800 flex items-center;
    }

    .el-collapse-item__content {
      @apply pb-0;
    }
  }
</style>
