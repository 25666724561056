<template>
  <DetailLayout
      :loading="customerLoading"
      :current-resource="currentResource"
      :get-current-resource="getCurrentResource"
      :select-component="CustomerSelect"
      :items="items"
  >
  </DetailLayout>
</template>
<script>
  import { isProduction } from "@/isProduction";
  import DetailLayout from "@/modules/common/components/DetailLayout.vue";
  import CustomerSelect from "@/components/select/entities/CustomerSelect.vue";

  export default {
    components: { DetailLayout },
    data() {
      return {
        CustomerSelect,
      }
    },
    computed: {
      items() {
        return [
          {
            name: this.$t('Details'),
            path: `/accounts-receivable/customers/${this.currentCustomerId}/view`,
            productionReady: true,
            authorizedTo: 'authorizedToShow',
          },
          {
            name: this.$t('Edit'),
            path: `/accounts-receivable/customers/${this.currentCustomerId}/edit`,
            productionReady: true,
            authorizedTo: 'authorizedToUpdate',
          },
          {
            name: this.$t('Jobs'),
            path: `/accounts-receivable/customers/${this.currentCustomerId}/jobs`,
            productionReady: false,
            authorizedTo: 'authorizedToShow',
            permission: 'jobs_show',
          },
          {
            name: this.$t('Billings'),
            path: `/accounts-receivable/customers/${this.currentCustomerId}/billings`,
            productionReady: false,
            authorizedTo: 'authorizedToShow',
            permission: 'billings_show',
          },
          {
            name: this.$t('Documents'),
            path: `/accounts-receivable/customers/${this.currentCustomerId}/documents`,
            authorizedTo: 'authorizedToShow',
            permission: 'customer_documents_show'
          },
          {
            name: this.$t('Work Orders'),
            path: `/accounts-receivable/customers/${this.currentCustomerId}/work-orders`,
            productionReady: false,
            authorizedTo: 'authorizedToShow',
            permission: 'work_orders_show',
          },
          {
            name: this.$t('Worksites'),
            path: `/accounts-receivable/customers/${this.currentCustomerId}/worksites`,
            productionReady: false,
            authorizedTo: 'authorizedToShow',
            permission: 'worksite_show',
          },
          {
            name: isProduction() ? this.$t('Statements') : this.$t('Statements (desktop)'),
            path: `/accounts-receivable/customers/${this.currentCustomerId}/statements`,
            productionReady: true,
            authorizedTo: 'authorizedToShow',
            enabled: () => isProduction(),
          },
          {
            name: isProduction() ? this.$t('Service Invoices') : this.$t('Service Invoices (desktop)'),
            path: `/accounts-receivable/customers/${this.currentCustomerId}/service-billing-invoices`,
            productionReady: true,
            authorizedTo: 'authorizedToShow',
            enabled: () => isProduction(),
          },
          {
            name: isProduction() ? this.$t('Lump Sum Billings') : this.$t('Lump Sum Billings (desktop)'),
            path: `/accounts-receivable/customers/${this.currentCustomerId}/lump-sum-billings`,
            productionReady: true,
            authorizedTo: 'authorizedToShow',
            enabled: () => isProduction(),
          },
        ]
      },
      customerLoading() {
        return this.$store.state.accountsReceivable.customerLoading
      },
      currentResource() {
        return this.$store.state.accountsReceivable.currentCustomer
      },
      currentCustomerId() {
        return this.$route.params.id
      },
    },
    methods: {
      async getCurrentResource(id) {
        await this.$store.dispatch('accountsReceivable/getCustomer', id)
      },
    },
  }
</script>
