<template>
  <div>
    <layout-tabs :items="filteredItems"/>
    <router-view/>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        items: [
          {
            name: this.$t('Service Billing Settings'),
            path: '/service-billing/settings/service-billing-settings',
            permission: 'service_billing_settings',
          },
          {
            name: this.$t('Settings Update Activity'),
            path: '/service-billing/settings/activity-log',
            permission: 'service_billing_settings',
          },
          {
            name: this.$t('Categories'),
            path: '/service-billing/settings/categories',
            permission: 'service_billing_settings',
          },
          {
            name: this.$t('Codes'),
            path: '/service-billing/settings/codes',
            permission: 'service_billing_codes_show',
          },
        ],
      }
    },
    computed: {
      filteredItems() {
        return this.items.filter(item => this.$can(item.permission))
      },
    },
  }
</script>
