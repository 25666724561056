<template>
  <div>
    <base-detail-card :title="$t('Company Details')"
                      :key="currentResource.id"
                      :entity="currentResource"
                      :can-expand="false"
                      :expanded="true"
                      entity-name="company"
    >
      <base-detail-card-row :title="$t('Name')">
        {{ currentResource.attributes.name }}
      </base-detail-card-row>
      <base-detail-card-row :title="$t('Short Name')" stripe>
        {{ currentResource.attributes.abbr }}
      </base-detail-card-row>
      <base-detail-card-row :title="$t('Number')">
        {{ currentResource.attributes.number }}
      </base-detail-card-row>
      <base-detail-card-row :title="$t('Email')" stripe>
        <base-email-link :value="currentResource.attributes.email"/>
      </base-detail-card-row>
      <base-detail-card-row :title="$t('Contact Phone')">
        <base-phone-link :value="currentResource.attributes.phone"/>
      </base-detail-card-row>
      <base-detail-card-row :title="$t('Fax')" stripe>
        <base-phone-link :value="currentResource.attributes.phone"/>
      </base-detail-card-row>

      <base-detail-card-row :title="$t('Multiple Taxes')">
        <status-badge :status="get(currentResource, 'attributes.multiple_local_taxes')"/>
      </base-detail-card-row>

      <base-detail-card-row :title="$t('Multiple SUI/SDI')" stripe>
        <status-badge :status="get(currentResource, 'attributes.multiple_state_taxes')"/>
      </base-detail-card-row>

    </base-detail-card>

    <div class="mt-4">
      <AddressesDetailsCard
          :addresses="get(currentResource, 'relationships.addresses', [])"
          no-data-text="No addresses were added for this company"
      />
    </div>

  </div>
</template>
<script>
  import AddressesDetailsCard from '@/modules/common/components/AddressesDetailsCard.vue'

  export default {
    components: { AddressesDetailsCard },
    props: {
      id: [String, Number],
    },
    computed: {
      currentResource() {
        return this.$store.state.company.currentCompany || {}
      }
    }
  }
</script>
