<template>
  <div>
    <portal to="layout-actions">
      <SettingsActions
          :settings-object="model"
          :module-name="moduleName"
          :fields="fields"
          @refresh-form="renderKey++"
      />
    </portal>
    <base-form
        v-bind="$attrs"
        :key="renderKey"
        :loading="loading"
        :show-cancel="showCancel"
        :show-back="showBack"
        :focusOnFirstInput="false"
        :save-text="$t('Update settings')"
        layout="vertical"
        @cancel="$emit('cancel')"
        @submit="onSubmit"
    >
      <el-collapse
          v-if="authorizedToCollapse"
          :value="activeCollapse"
      >
        <base-collapse-section name="collapse-item"
                               class="-m-6"
        >
          <slot name="content" :model="model"/>
        </base-collapse-section>
      </el-collapse>

      <slot :model="model"/>
      <slot name="overrides" :model="model"/>
    </base-form>
  </div>
</template>
<script>
  import axios from 'axios'
  import SettingsActions from '@/modules/common/components/modules-settings/SettingsActions.vue'
  import { refreshGlobalResourcesByModule } from "@/utils/modules";
  import Cache from "@/utils/Cache";

  export default {
    name: 'SettingsForm',
    components: { SettingsActions },
    props: {
      moduleName: {
        type: String,
        default: '',
      },
      fields: {
        type: Array,
        default: () => [],
      },
      beforeSave: {
        type: Function,
      },
      modelMutator: {
        type: Function,
      },
      beforeInitModel: {
        type: Function,
      },
      authorizedToCollapse: false,
    },
    data() {
      let model = this.initModel(this.fields)
      return {
        renderKey: 1,
        loading: false,
        showCancel: true,
        showBack: true,
        model: model,
        activeCollapse: ['collapse-item'],
      }
    },
    computed: {
      settings() {
        return this.$store.getters['company/getCompanySettingsPerModule'](this.moduleName)
      },
    },
    methods: {
      initModel(fields = []) {
        let model = {}
        fields.forEach(field => {
          model[field] = null
        })
        if (this.beforeInitModel) {
          model = this.beforeInitModel(model)
        }
        this.$emit('change', model)
        return model
      },
      async onSubmit() {
        try {
          this.loading = true

          if (this.beforeSave) {
            // * Check for validate grid table
            const canContinue = await this.beforeSave(this.model)
            if (!canContinue) {
              return
            }
          }

          if (this.modelMutator) {
            this.model = this.modelMutator(this.model)
          }

          const data = {
            ...this.model,
            id: undefined,
            module: undefined
          }

          await axios.put(`/restify/company-settings/${this.model.id}`, {
            value: data,
          })
          this.$success(this.$t('Settings updated successfully.'))
          this.$store.dispatch('company/getCompanySettings')
          await refreshGlobalResourcesByModule(this.$currentModule)
          Cache.removeForEntity('/restify/company-setting-activities?')
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Something went wrong. Please try again.'))
        } finally {
          this.loading = false
        }
      },
    },
    watch: {
      settings: {
        immediate: true,
        handler(value) {
          if (!value) {
            return
          }
          this.model = {
            ...this.model,
            ...value,
          }
          this.$emit('change', this.model)
        },
      },
    },
  }
</script>
