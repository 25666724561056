<template>
  <div>
    <div class="flex justify-between items-baseline font-semibold text-sm text-gray-500">
      <div class="flex flex-wrap my-3 space-x-2">
        <div>
          {{ $t('Selected:') }}
          <base-badge
              type="info"
              class="mx-2"
          >
            {{ selectedItems }}
          </base-badge>
        </div>
        <div>
          {{ $t('Removed:') }}
          <base-badge
              type="danger"
              class="mx-2"
          >
            {{ removedItems }}
          </base-badge>
        </div>
      </div>
      <base-button
          v-if="hasSelections"
          variant="danger-link"
          @click="onClearSelection"
      >
        <IconClose class="mr-2"/>
        {{ $t('Clear Selection') }}
      </base-button>
    </div>
    <AgDataTable
        v-bind="editableTableProps"
        :columns="columns"
        :url="url"
        :url-params="urlParams"
        :transform-data="mapTransactions"
        :groupDefaultExpanded="-1"
        :groupRowRendererParams="groupRowRendererParams"
        :compact="true"
        :no-borders="true"
        groupDisplayType="groupRows"
        dom-layout="autoHeight"
        ref="table"
        @grid-ready="onGridReady"
        @cell-value-changed="onCellValueChanged"
    >
      <template #business="{row}">
        <template v-if="row.business_id">
          <component
              :is="getBusinessComponent(row.business_type)"
              :data="row.business"
              :show-description="false"
          />
        </template>
        <span v-else/>
      </template>
    </AgDataTable>
  </div>
</template>
<script>
  import i18n from '@/i18n'
  import sortBy from 'lodash/sortBy'
  import groupBy from 'lodash/groupBy'
  import { transactionBusinessLinkByTypes } from '@/enum/enums'
  import { editableTableProps, getTableData } from '@/components/ag-grid/tableUtils'
  import TransactionGroupRow from '@/modules/accounts-receivable/pages/billings/forms/cost-plus/select-costs/TransactionGroupRow'
  import {
    selectCostsActionTypes
  } from "@/modules/accounts-receivable/pages/billings/forms/cost-plus/select-costs/selectCostsEnums";

  const actionOptions = [
    {
      label: i18n.t('?'),
      value: selectCostsActionTypes.None,
    },
    {
      label: i18n.t('Select'),
      value: selectCostsActionTypes.Create,
    },
    {
      label: i18n.t('Remove'),
      value: selectCostsActionTypes.Remove,
    },
  ]

  export default {
    components: {
      TransactionGroupRow,
    },
    props: {
      url: {
        type: String,
        default: '/restify/transactions/cost-plus',
      },
      data: {
        type: Object,
        default: () => ({}),
      },
      storageKey: {
        type: String,
        default: 'selectedCostsForBilling',
      },
    },
    data() {
      return {
        editableTableProps,
        selections: {},
        jobsManaged: {},
        jobsManagedObj: {},
        grid: null,
        columns: [
          {
            headerName: ' ',
            field: 'source_id',
            rowGroup: true,
            hide: true,
          },
          {
            headerName: ' ',
            field: 'action',
            cellEditor: this.$cellEditors.BaseSelect,
            cellEditorParams: {
              options: actionOptions,
              onValueChanged: this.onChangeSelection,
            },
            valueFormatter: params => {
              const op = actionOptions.find(o => o.value === params.value)
              return op?.label || this.$t('?')
            },
            cellClass: params => {
              if (params.data?.action === selectCostsActionTypes.Remove) {
                return 'text-red-500'
              }

              if (!params.data?.action) {
                return 'text-gray-500'
              }

              return ''
            },
            editable: true,
            minWidth: 150,
            maxWidth: 150,
          },
          {
            headerName: this.$t('Line Item'),
            field: 'budget',
            component: 'AddlSourceLink',
            cellClass: 'readonly-ag-cell',
            cellRendererParams: {
              target: '_blank',
              showDescription: false,
            },
            minWidth: 120,
          },
          {
            headerName: this.$t('Description'),
            field: 'addl_source.description',
            cellClass: 'readonly-ag-cell',
            minWidth: 130,
          },
          {
            headerName: this.$t('Ref Date'),
            field: 'reference_date',
            component: 'FormattedDate',
            cellClass: 'readonly-ag-cell',
            minWidth: 110,
            maxWidth: 130,
          },
          {
            headerName: this.$t('Journal'),
            field: 'journal',
            valueFormatter: params => {
              const { code, number } = params.data?.journal || {}
              return `${code}${number}`
            },
            cellClass: 'readonly-ag-cell',
            minWidth: 100,
            maxWidth: 130,
          },
          {
            headerName: this.$t('Year / Period'),
            field: 'year_period',
            valueFormatter: params => {
              const { fiscal_year, period } = params.data || {}
              return `${fiscal_year} / ${period}`
            },
            cellClass: 'readonly-ag-cell',
            minWidth: 100,
            maxWidth: 130,
          },
          {
            headerName: this.$t('Vendor / Customer'),
            cellClass: 'readonly-ag-cell',
            field: 'business',
            minWidth: 100,
            maxWidth: 130,
          },
          {
            headerName: this.$t('Type'),
            field: 'type.abbr',
            cellClass: 'readonly-ag-cell',
            minWidth: 70,
            maxWidth: 70,
          },
          {
            headerName: this.$t('Qty / H'),
            field: 'meta.quantity',
            cellClass: 'readonly-ag-cell',
            minWidth: 100,
            maxWidth: 120,
          },
          {
            headerName: this.$t('Actual'),
            field: 'amount',
            cellClass: 'readonly-ag-cell',
            component: 'FormattedPrice',
            minWidth: 120,
            maxWidth: 140,
            align: 'right',
          },
        ],
        selectedItems: 0,
        removedItems: 0,
      }
    },
    computed: {
      urlParams() {
        return {
          ...this.data,
          perPage: 100,
        }
      },
      groupRowRendererParams() {
        return {
          innerRenderer: 'TransactionGroupRow',
          updateGroupStatus: this.updateGroupStatus,
        }
      },
      hasSelections() {
        return this.selectedItems + this.removedItems
      },
    },
    methods: {
      onGridReady(params) {
        this.grid = params
      },
      getTableData() {
        const gridApi = this.grid.api
        return getTableData(gridApi)
      },
      getBusinessComponent(businessType) {
        return transactionBusinessLinkByTypes[businessType]
      },
      mapTransactions(data) {
        const selectedItems = JSON.parse(localStorage.getItem(this.storageKey)) || {}
        const { actions: actions = {} } = selectedItems

        const groups = groupBy(data, 'source_id')
        const result = []

        for (let jobId in groups) {
          const transactions = sortBy(groups[jobId], ['addl_source.phase_code', 'addl_source.cost_code', 'addl_source.change_order'])

          result.push(...transactions.map(transaction => {
            transaction.action = actions[transaction.id] || null
            return transaction
          }))
        }

        return result
      },
      // * On change selection - paste and delete event
      onCellValueChanged(params) {
        this.onChangeSelection(params, params.value)
      },
      updateGroupStatus(groupEntries, action) {
        groupEntries.forEach(child => {
          this.onChangeSelection({
            data: child.data,
            node: child,
          }, action)
        })
      },
      onChangeSelection(params, evt) {
        const row = params.data
        const { id, source_id } = row
        this.selections[id] = evt

        if (!Object.prototype.hasOwnProperty.call(this.jobsManaged, [source_id])) {
          this.jobsManaged[source_id] = 0
        }
        if (!evt) {
          delete this.selections[id]
          --this.jobsManaged[source_id]
        } else {
          ++this.jobsManaged[source_id]
        }
        if (!this.jobsManaged[source_id]) {
          delete this.jobsManagedObj[source_id]
        } else {
          this.jobsManagedObj[source_id] = row.source
        }

        row.action = evt
        params.node.setData(row)

        this.mapSelections()
        this.saveSelectionToStorage()
      },
      mapSelections() {
        const data = this.getTableData()
        this.selectedItems = data.filter(s => s?.action === selectCostsActionTypes.Create).length
        this.removedItems = data.filter(s => s?.action === selectCostsActionTypes.Remove).length
      },
      computeSummaries() {
        const items = Object.values(this.selections)

        this.selectedItems = items.filter(s => s === selectCostsActionTypes.Create).length
        this.removedItems = items.filter(s => s === selectCostsActionTypes.Remove).length
      },
      async saveSelectionToStorage() {
        const selections = {
          jobs: this.jobsManagedObj,
          actions: this.selections,
        }
        localStorage.setItem(this.storageKey, JSON.stringify(selections))
      },
      setSelectionsFromStorage() {
        const data = JSON.parse(localStorage.getItem(this.storageKey))
        if (!data) {
          this.selections = {}
          return
        }
        const { actions: actions = {}, jobs = {} } = data
        this.jobsManagedObj = { ... jobs }
        this.selections = { ...actions }
        this.computeSummaries()
      },
      onClearSelection() {
        localStorage.removeItem(this.storageKey)
        this.selections = {}
        this.selectedItems = this.removedItems = 0
        this.$refs.table.refresh()
      },
    },
    mounted() {
      this.setSelectionsFromStorage()
    },
  }
</script>
