<template>
  <Statements :customer-id="customer.id"/>
</template>
<script>
  import Statements from '@/modules/accounts-receivable/pages/statements'

  export default {
    components: {
      Statements,
    },
    computed: {
      customer() {
        return this.$store.state.accountsReceivable.currentCustomer
      },
    },
  }
</script>
