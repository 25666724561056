<template>
  <div>
    <div class="font-semibold print:font-normal mb-2 -mt-1">{{ $t('Pay Period End Date:') }} {{ $formatDate(batch?.attributes?.period_end_date) }}</div>
    <PayrollBatchEmployeeSummary/>
    <div class="break-after-page"></div>
    <PayrollSummary />
  </div>
</template>
<script>
  import PayrollBatchEmployeeSummary from "@/modules/payroll/components/payroll/PayrollBatchEmployeeSummary.vue";
  import PayrollSummary from "@/modules/payroll/components/PayrollSummary.vue";

  export default {
    components: {
      PayrollSummary,
      PayrollBatchEmployeeSummary
    },
    computed: {
      batch() {
        return this.$store.state.payroll.currentPayrollBatch
      }
    },
    async mounted() {
      await this.$store.dispatch('payroll/getPayrollBatch', this.$route.params.id)
    }
  }
</script>
