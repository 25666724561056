<template>
  <VendorForm
      :data="vendor"
      @refresh="getVendor"
  />
</template>
<script>
  import VendorForm from '@/modules/accounts-payable/components/VendorForm'

  export default {
    components: {
      VendorForm,
    },
    computed: {
      vendor() {
        const vendor = this.$store.state.accountsPayable.currentVendor
        return {
          ...vendor?.attributes,
          ...vendor?.relationships,
        }
      },
    },
    methods: {
      async getVendor() {
        await this.$store.dispatch('accountsPayable/getVendor', this.$route.params.id)
      },
    },
  }
</script>
