import PublicLayout from "@/modules/public/layout/PublicLayout.vue";
import PublicProgressBillingDetails from "@/modules/public/pages/billings/public-progress-billing-details";
import PublicACHPaymentPage from "@/modules/public/pages/billings/public-ach-payment-page.vue";

const hasToken = () => localStorage.getItem('token')

export default [
  {
    path: '/',
    components: {
      default: PublicLayout
    },
    redirect: hasToken() ? '/welcome': '/login',
    meta: {
      productionReady: true,
    },
    children: [
      {
        path: '/billings/progress/:id',
        name: 'Progress Billing',
        component: PublicProgressBillingDetails
      },
      {
        path: '/checkout',
        name: 'Pay with ACH - Construction Partner Inc.',
        component: PublicACHPaymentPage
      },
    ]
  },
]
