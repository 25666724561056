<template>
  <div>
    <portal to="page-title">
      <div
        v-if="journal.id"
        class="flex items-center print:hidden"
      >
        {{ $t('Journal ') }} #{{ journal.attributes.name }}
      </div>
    </portal>
    <JournalDetails v-if="!journalLoading"/>
  </div>
</template>
<script>
  import JournalDetails from '@/modules/common/components/journal/JournalDetails'

  export default {
    components: {
      JournalDetails,
    },
    computed: {
      journal() {
        return this.$store.state.generalLedger.currentJournal
      },
      journalLoading() {
        return this.$store.state.generalLedger.journalLoading
      },
    },
    methods: {
      async getCurrentResource(id) {
        await this.$store.dispatch('generalLedger/getJournal', id)
      },
    },
    watch: {
      '$route.params': {
        immediate: true,
        handler({ id }) {
          this.getCurrentResource(id)
        },
      },
    },
  }
</script>
