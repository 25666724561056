import i18n from "@/i18n";

export const FinancialReportType = {
  BalanceSheet: 'balance-sheet',
  IncomeStatement: 'income-statement',
  Other: 'other',
} as const

export const FinancialReportTypeLabels = {
  [FinancialReportType.BalanceSheet]: i18n.t('Balance Sheet'),
  [FinancialReportType.IncomeStatement]: i18n.t('Income Statement'),
  [FinancialReportType.Other]: i18n.t('Other'),
}

export const FinancialReportTypeOptions = [
  {
    label: FinancialReportTypeLabels[FinancialReportType.BalanceSheet],
    value: FinancialReportType.BalanceSheet,
  },
  {
    label: FinancialReportTypeLabels[FinancialReportType.IncomeStatement],
    value: FinancialReportType.IncomeStatement,
  },
  {
    label: FinancialReportTypeLabels[FinancialReportType.Other],
    value: FinancialReportType.Other,
  },
]

export type FinancialReportType = typeof FinancialReportType[keyof typeof FinancialReportType]

export const FinancialReportMethods = {
  ThisPeriodAmount: 'this-period-amount',
  LastPeriodAmount: 'last-period-amount',
  ChangePeriodAmount: 'change-period-amount',
  PeriodBudget: 'period-budget',
  PeriodVariance: 'this-period-variance',
  LastPeriodVariance: 'last-period-variance',
  ThisYearToDateAmount: 'this-ytd-amount',
  LastYearToDateAmount: 'last-ytd-amount',
  ChangeYearToDateAmount: 'change-ytd-amount',
  YearToDateBudget: 'ytd-budget',
  YearToDateVariance: 'this-ytd-variance',
  LastYearVariance: 'last-ytd-variance'
} as const;

export const FinancialReportMethodOptions = [
  {
    label: i18n.t('This Period Amount'),
    value: FinancialReportMethods.ThisPeriodAmount,
  },
  {
    label: i18n.t('Last Period Amount'),
    value: FinancialReportMethods.LastPeriodAmount,
  },
  {
    label: i18n.t('Change Period Amount'),
    value: FinancialReportMethods.ChangePeriodAmount,
  },
  {
    label: i18n.t('Period Budget'),
    value: FinancialReportMethods.PeriodBudget,
  },
  {
    label: i18n.t('This Period Variance'),
    value: FinancialReportMethods.PeriodVariance,
  },
  {
    label: i18n.t('Last Period Variance'),
    value: FinancialReportMethods.LastPeriodVariance,
  },
  {
    label: i18n.t('This Year To Date Amount'),
    value: FinancialReportMethods.ThisYearToDateAmount,
  },
  {
    label: i18n.t('Last Year To Date Amount'),
    value: FinancialReportMethods.LastYearToDateAmount,
  },
  {
    label: i18n.t('Change Year To Date Amount'),
    value: FinancialReportMethods.ChangeYearToDateAmount,
  },
  {
    label: i18n.t('Year To Date Budget'),
    value: FinancialReportMethods.YearToDateBudget,
  },
  {
    label: i18n.t('YTD Variance'),
    value: FinancialReportMethods.YearToDateVariance,
  },
  {
    label: i18n.t('Last Year Variance'),
    value: FinancialReportMethods.LastYearVariance,
  },
]

export function getFilterMethodLabel(value: string) {
  return FinancialReportMethodOptions.find(o => o.value === value)?.label as string || ''
}

export const FinancialReportStyle = {
  Dashed: 'dashed',
  SingleBorder: 'dotted',
  Solid: 'solid',
  DoubleBorder: 'double',
  Wavy: 'wavy',
  None: 'none',
} as const

export const FinancialReportStyleOptions = [
  {
    label: i18n.t('Solid'),
    value: FinancialReportStyle.Solid,
  },
  {
    label: i18n.t('Single Border'),
    value: FinancialReportStyle.SingleBorder,
  },
  {
    label: i18n.t('Double Border'),
    value: FinancialReportStyle.DoubleBorder,
  },
  {
    label: i18n.t('Dashed Border'),
    value: FinancialReportStyle.Dashed,
  },
  {
    label: i18n.t('Wavy'),
    value: FinancialReportStyle.Wavy,
  },
  {
    label: i18n.t('None'),
    value: FinancialReportStyle.None,
  },
]

export const Levels = {
  Zero: 0,
  One: 1,
  Two: 2,
  Three: 3,
  Four: 4,
  Five: 5,
} as const

export const LevelOptions = Object.values(Levels).map((level) => ({
  label: level.toString(),
  value: level,
}))

export type CellStyleType = typeof FinancialReportStyle[keyof typeof FinancialReportStyle]

export type FinancialReportRow = {
  _localId?: string
  description: string
  description_html?: string
  level: number
  accounts: string[]
  order?: number
  page_after: boolean
  print_percent: boolean
  reverse_sign: boolean
  is_title?: boolean
  style: CellStyleType
}

export type FinancialReportCol = {
  _localId: string
  name: string
  method: FinancialReportType | null
  description?: string
  reverse_sign?: boolean
  zero_suppress?: boolean
}
