<template>
  <div class="flex-1 flex flex-col">
    <AgDataTable
      ref="table"
      :columns="columns"
      :edit-entity-url-query="editEntityUrlQuery"
      :url-params="urlParams"
      :add-text="$t('New state tax')"
      :view-entity-url-query="viewEntityUrl"
      url="/restify/employee-state-taxes"
      default-sort="code"
      permission="employees"
      actions="search,refresh"
      class="flex-1"
      @add="onRowAdd"
      @edit="onRowEdit"
    >
      <template #attributes.status="{row}">
        <span class="truncate" :title="getFillingStatus(row)">
          {{ getFillingStatus(row) }}
        </span>
      </template>
      <template #attributes.sit_adjustment_type="{row}">
        <span class="truncate" :title="getAdjustmentTypeLabel(row)">
          {{ getAdjustmentTypeLabel(row) }}
        </span>
      </template>
    </AgDataTable>
    <BaseFormDialog v-if="showDialog"
                    :title="$t('State Tax Status')"
                    :open.sync="showDialog"
                    size="lg"
                    @close="onEventTrigger"
    >
      <StateTaxForm :data="rowToEdit"
                    @cancel="onEventTrigger"
                    @save="onEventTrigger"
      />
    </BaseFormDialog>
  </div>
</template>
<script lang="ts">
import {ValueGetterParams} from '@ag-grid-community/core'
import {federalFilingStatuses, federalFilingStatusesDictionary, taxAdjustments} from '@/enum/enums'
import StateTaxForm from '@/modules/payroll/components/taxes/StateTaxForm.vue'
import {defineComponent} from 'vue'
import {EmployeeStateTax} from "@/modules/common/types/models";
import {Column} from "@/components/ag-grid/tableTypes";
import {getAdjustmentTypes} from "@/modules/payroll/components/taxes/stateTax";
import Data = API.Data;

export default defineComponent({
  components: {
    StateTaxForm,
  },
  props: {
    employeeId: {
      type: String,
    }
  },
  data() {
    return {
      rowToEdit: null as Data<EmployeeStateTax> | null,
      showDialog: false,
      editEntityUrlQuery: '?id={ID}',
      federalFilingStatusesDictionary,
    }
  },
  computed: {
    viewEntityUrl() {
      if (this.employeeId) {
        return `/payroll/employees/${this.employeeId}/taxes/state`
      }
      return `/payroll/taxes/state`
    },
    columns(): Column[] {
      return [
        {
          headerName: this.$t('Employee'),
          field: 'attributes.employee_id',
          minWidth: 250,
          maxWidth: 320,
          component: 'EmployeeLink',
          hide: !!this.employeeId,
        },
        {
          headerName: this.$t('State'),
          field: 'relationships.jurisdiction.attributes.code',
          component: 'EntityLink',
          redirectTo: `${this.viewEntityUrl}?id={ID}`,
          minWidth: 80,
        },
        {
          headerName: this.$t('Type'),
          field: 'attributes.type',
          component: 'Status',
          minWidth: 80,
        },
        {
          headerName: this.$t('Filling Status'),
          field: 'attributes.status',
          component: 'Status',
          minWidth: 80,
        },
        {
          headerName: this.$t('Withholding'),
          field: 'withholding',
          valueGetter: (params: ValueGetterParams) => {
            return this.getWithholdingValue(params.data?.attributes)
          },
          minWidth: 100,
        },
        {
          headerName: this.$t('Adjustment Type'),
          field: 'attributes.sit_adjustment_type',
          minWidth: 100,
          maxWidth: 200,
        },
        {
          headerName: this.$t('Adjustment'),
          field: 'attributes.sit_adjustment_rate',
          valueGetter: (params: ValueGetterParams) => {
            return this.getAdjustmentValue(params.data?.attributes)
          },
          minWidth: 100,
        },
      ]
    },
    urlParams() {
      return {
        related: 'jurisdiction',
        employee_id: this.employeeId,
      }
    },
  },
  methods: {
    getFillingStatus(row: Data<EmployeeStateTax>) {
      const status = this.get(row, 'attributes.status')
      return this.federalFilingStatusesDictionary[status] as string
    },
    getAdjustmentTypeLabel(row: Data<EmployeeStateTax>) {
      const value = row.attributes.sit_adjustment_type
      const options = getAdjustmentTypes()
      const label = options.find(option => option.value === value)?.label as string
      return label || ''
    },
    getWithholdingValue(row: EmployeeStateTax) {
      if (row.status === federalFilingStatuses.PERCENT_OF_FEDERAL) {
        return this.$formatPercent(row.percent_of_federal)
      }
      return row.personal_tax_credit_allowance_count
    },
    getAdjustmentValue(row: EmployeeStateTax) {
      const {sit_adjustment_type, sit_adjustment_rate} = row
      if ([taxAdjustments.ADDITIONAL_AMOUNT, taxAdjustments.FIXED_AMOUNT].includes(sit_adjustment_type)) {
        return this.$formatPrice(sit_adjustment_rate)
      }
      return this.$formatPercent(sit_adjustment_rate)
    },
    onRowEdit(row: Data<EmployeeStateTax>) {
      this.showDialog = true
      this.rowToEdit = row
    },
    onRowAdd() {
      this.showDialog = true
    },
    refreshTable() {
      const table = this.$refs.table as any
      table?.refresh()
    },
    onEventTrigger() {
      this.rowToEdit = null
      this.showDialog = false
      this.refreshTable()
    },
  },
})
</script>
