import billingsRoutes from '@/modules/accounts-receivable/pages/billings/routes'
import PaymentLayout from "@/modules/accounts-receivable/layout/PaymentLayout";
import EmptyLayout from "@/components/common/EmptyLayout";
import AccountsReceivableDashboard from "@/modules/accounts-receivable/pages/dashboard/accounts-receivable-dashboard";
import CustomerList from "@/modules/accounts-receivable/pages/customer/customer-list";
import CustomerImport from "@/modules/accounts-receivable/pages/customer/customer-import";
import CustomerAdd from "@/modules/accounts-receivable/pages/customer/customer-add";
import CustomerLayout from "@/modules/accounts-receivable/layout/CustomerLayout";
import CustomerEdit from "@/modules/accounts-receivable/pages/customer/customer-edit";
import CustomerDetails from "@/modules/accounts-receivable/pages/customer/customer-details";
import CustomerHistory from "@/modules/accounts-receivable/pages/customer/customer-history";
import CustomerStatements from "@/modules/accounts-receivable/pages/customer/customer-statements";
import CustomerServiceBillingInvoices
  from "@/modules/accounts-receivable/pages/customer/customer-service-billing-invoices";
import CustomerLumpSumBillings from "@/modules/accounts-receivable/pages/customer/customer-lump-sum-billings";
import BillingRatesLayout from "@/modules/accounts-receivable/layout/BillingRatesLayout";
import TypeMaterial from "@/modules/accounts-receivable/pages/billing-rates/type-material";
import TypeEquipment from "@/modules/accounts-receivable/pages/billing-rates/type-equipment";
import TypeCraftCode from "@/modules/accounts-receivable/pages/billing-rates/type-craft-code";
import BillingRateAdd from "@/modules/accounts-receivable/pages/billing-rates/billing-rate-add";
import BillingRateEdit from "@/modules/accounts-receivable/pages/billing-rates/billing-rate-edit";
import CustomerReviewHistory from "@/modules/accounts-receivable/pages/customer/customer-review-history";
import CustomerPayments from "@/modules/accounts-receivable/pages/payments/customer-payments";
import ClearJointChecks from "@/modules/accounts-receivable/pages/payments/clear-joint-checks";
import PaymentBatches from "@/modules/accounts-receivable/pages/payments/payment-batches";
import PaymentBatchDetails from "@/modules/accounts-receivable/pages/payments/payment-batch-details";
import StatementBatches from "@/modules/accounts-receivable/pages/statement-batches";
import Statements from "@/modules/accounts-receivable/pages/statements";
import LumpSumBillings from "@/modules/accounts-receivable/pages/lump-sum-billings/lump-sum-billings";
import LumpSumBillingsBatches from "@/modules/accounts-receivable/pages/lump-sum-billings/lump-sum-billings-batches";
import Reports from "@/modules/accounts-receivable/layout/Reports";
import WaiverAndReleaseReport from "@/modules/accounts-receivable/pages/reports/waiver-and-release-report";
import AgedByCustomerReport from '@/modules/accounts-receivable/pages/reports/aged-by-customer-report'
import AgedByJobReport from '@/modules/accounts-receivable/pages/reports/aged-by-job-report'
import JobPreBillingWorksheetReport from '@/modules/accounts-receivable/pages/reports/job-pre-billing-worksheet-report'
import SettingsLayout from "@/modules/accounts-receivable/layout/SettingsLayout";
import AccountsReceivableSettings from "@/modules/accounts-receivable/pages/settings/accounts-receivable-settings";
import SalesTaxDistrictList from "@/modules/accounts-receivable/pages/settings/sales-tax-district-list";
import SalesTaxDistrictImport from "@/modules/accounts-receivable/pages/settings/sales-tax-district-import";
import JournalList from '@/modules/common/components/journal/journal'
import JournalDetails from '@/modules/common/components/journal/journal-details'
import AccountsReceivableInitList
  from "@/modules/accounts-receivable/pages/settings/accounts-receivable-init/accounts-receivable-init-list";
import AccountsReceivableInitAdd
  from "@/modules/accounts-receivable/pages/settings/accounts-receivable-init/accounts-receivable-init-add";
import AccountsReceivableInitLayout from "@/modules/accounts-receivable/layout/AccountsReceivableInitLayout";
import AccountsReceivableInitEdit
  from "@/modules/accounts-receivable/pages/settings/accounts-receivable-init/accounts-receivable-init-edit";
import AccountsReceivableInitProofListing
  from "@/modules/accounts-receivable/pages/settings/accounts-receivable-init/accounts-receivable-init-proof-listing";
import AccountsReceivableInitImport
  from "@/modules/accounts-receivable/pages/settings/accounts-receivable-init/accounts-receivable-init-import.vue";
import { $modules } from "@/enum/enums";
import SettingsActivityLog from "@/modules/common/components/SettingsActivityLog.vue";
import PaymentsProofListing from "@/modules/accounts-receivable/pages/payments/payments-proof-listing.vue";
import BillingRateTypes from "@/modules/payroll/pages/rates/billing-rate-types.vue";
import CustomerJobs from "@/modules/accounts-receivable/pages/customer/customer-jobs.vue";
import CustomerWorkOrders from "@/modules/accounts-receivable/pages/customer/customer-work-orders.vue";
import CustomerWorksites from "@/modules/accounts-receivable/pages/customer/customer-worksites.vue";
import { RestifyResources } from "@/components/form/util";
import DocumentsTable from "@/modules/common/components/documents/DocumentsTable.vue";
import AllPayments from "@/modules/accounts-receivable/pages/payments/all-payments.vue";

export const reportRoutes = [
  {
    path: 'aged-by-customer',
    name: 'Aged A/R By Customer',
    title: 'Aged A/R By Customer',
    meta: {
      permissions: ['accounts_receivable_reports'],
      appScreen: 'Aged A/R By Customer: 58.82',
    },
    component: AgedByCustomerReport,
  },
  {
    path: 'aged-by-job',
    name: 'Aged A/R By Job',
    title: 'Aged A/R By Job',
    meta: {
      permissions: ['accounts_receivable_reports'],
      appScreen: 'Aged A/R By Job: 58.83',
    },
    component: AgedByJobReport,
  },
  {
    path: 'job-pre-billing-worksheet',
    name: 'Job Pre-Billing Worksheet',
    title: 'Job Pre-Billing Worksheet',
    meta: {
      permissions: ['accounts_receivable_reports'],
      appScreen: 'Job Pre-Billing Worksheet: 58.89',
    },
    component: JobPreBillingWorksheetReport,
  },
  {
    path: 'waiver-and-release',
    name: 'AR Waiver & Release',
    title: 'Waiver & Release',
    meta: {
      permissions: ['accounts_receivable_reports'],
      appScreen: 'Aged A/P By Vendor: 58.71',
    },
    component: WaiverAndReleaseReport,
  },
  {
    path: 'customer-history',
    name: 'Customer History',
    title: 'Customer History',
    meta: {
      permissions: ['accounts_receivable_reports'],
      appScreen: 'Customer History: 58.84',
    },
    component: () => import( './pages/reports/customer-history-report'),
  },
  {
    path: 'applied-customer-payments',
    name: 'Applied Customer Payments',
    title: 'Applied Customer Payments',
    meta: {
      permissions: ['accounts_receivable_reports'],
      appScreen: 'Applied Customer Payments: 58.78',
    },
    component: () => import( './pages/reports/applied-customer-payments-report.vue'),
  },
  {
    path: 'invoice-listing-by-invoice',
    name: 'Invoice Listing - By Invoice',
    title: 'Invoice Listing - By Invoice',
    meta: {
      permissions: ['accounts_receivable_reports'],
      appScreen: 'Invoice Listing - By Invoice: 58.73',
    },
    component: () => import( './pages/reports/invoice-listing-by-invoice.vue'),
  },
  {
    path: 'sales-tax',
    name: 'Sales Tax Report',
    title: 'Sales Tax Report',
    meta: {
      permissions: ['accounts_receivable_reports'],
      appScreen: 'Sales Tax: 58.77',
    },
    component: () => import( './pages/reports/sales-tax-report.vue'),
  },
  {
    path: 'statements',
    name: 'Customer Statements Report',
    title: 'Customer Statements',
    meta: {
      permissions: ['accounts_receivable_reports'],
      appScreen: 'Customer Statements: 58.87',
    },
    component: () => import( './pages/reports/statements-report.vue'),
  },
]

export default [
  {
    path: '/accounts-receivable',
    name: 'Accounts Receivable',
    component: EmptyLayout,
    redirect: '/accounts-receivable/customers',
    meta: {
      permissions: ['customers_show'],
      productionReady: true,
      isModule: true,
    },
    children: [
      ...billingsRoutes,
      {
        path: 'dashboard',
        name: 'AR Dashboard',
        title: 'AR Dashboard',
        description: 'A visual summary of accounts receivable',
        meta: {
          permissions: ['billings_show'],
        },
        component: AccountsReceivableDashboard,
      },
      {
        path: 'customers',
        name: 'Customers',
        title: 'Customers',
        description: 'The basics of Construction Partner Accounts Receivable begins with the proper setup of Customers',
        meta: {
          permissions: ['customers_show'],
          productionReady: true,
          appScreen: 'Setup Customers: 50.10',
        },
        component: CustomerList,
      },
      {
        path: 'customers/import',
        name: 'Import AR Customers',
        title: 'Import AR Customers',
        description: 'Import AR Customers from a CSV or Excel file',
        meta: {
          permissions: ['customers_update'],
          appScreen: 'Setup Customers: 50.10',
        },
        component: CustomerImport,
      },
      {
        path: 'customers/add',
        name: 'Add Customer',
        title: 'Add Customer',
        description: 'Add A/R Customers',
        meta: {
          permissions: ['customers_store'],
          productionReady: true,
          appScreen: 'Setup Customers: 50.10',
          resourceName: 'customers',
        },
        component: CustomerAdd,
      },
      {
        path: 'customers/:id',
        name: 'Customer Details ',
        redirect: '/accounts-receivable/customers',
        meta: {
          permissions: ['customers_update'],
          appScreen: 'Setup Customers: 50.10',
          resourceName: 'customers',
          productionReady: true,
        },
        component: CustomerLayout,
        children: [
          {
            path: 'edit',
            name: 'Customer Edit',
            meta: {
              productionReady: true,
              permissions: ['customers_update'],
              appScreen: 'Setup Customers: 50.10',
              resourceName: 'customers',
            },
            component: CustomerEdit,
          },
          {
            path: 'view',
            name: 'View Customer',
            meta: {
              productionReady: true,
              permissions: ['customers_show'],
              appScreen: 'Setup Customers: 50.10',
              resourceName: 'customers',
            },
            component: CustomerDetails,
          },
          {
            path: 'billings',
            meta: {
              permissions: ['billings_show'],
              resourceName: 'customers',
            },
            component: CustomerHistory,
          },
          {
            path: 'jobs',
            meta: {
              permissions: ['jobs_show'],
              resourceName: 'customers',
            },
            component: CustomerJobs,
          },
          {
            path: 'documents',
            name: 'Customer Documents',
            meta: {
              permissions: ['customer_documents_show'],
              resourceName: RestifyResources.Customers,
            },
            component: DocumentsTable,
          },
          {
            path: 'work-orders',
            meta: {
              permissions: ['work_orders_show'],
              resourceName: 'customers',
            },
            component: CustomerWorkOrders,
          },
          {
            path: 'worksites',
            meta: {
              permissions: ['worksite_show'],
              resourceName: 'customers',
            },
            component: CustomerWorksites,
          },
          {
            path: 'statements',
            name: 'Customer Statements',
            meta: {
              productionReady: true,
              permissions: ['customers_show'],
            },
            component: CustomerStatements,
          },
          {
            path: 'service-billing-invoices',
            name: 'Customer Service Invoices',
            meta: {
              permissions: ['customers_show'],
              productionReady: true,
            },
            component: CustomerServiceBillingInvoices,
          },
          {
            path: 'lump-sum-billings',
            name: 'Customer Lump Sum Billings',
            meta: {
              permissions: ['lump_sum_billings_show'],
              productionReady: true,
            },
            component: CustomerLumpSumBillings,
          },
        ],
      },
      {
        path: 'customer-review-history',
        name: 'Customer Current History',
        title: 'Customer Current History',
        description: 'Review customer invoices in open receivable, review paid invoices, void open invoices.',
        meta: {
          permissions: ['customers_show'],
          appScreen: 'Customer History: 50.70',
        },
        component: CustomerReviewHistory,
      },
      {
        path: 'payments',
        name: 'AR Payments',
        redirect: 'payments/create',
        meta: {
          permissions: ['billing_payments_show'],
        },
        component: PaymentLayout,
        children: [
          {
            path: 'create',
            name: 'Customer Payments',
            title: 'Customer Payments',
            description: 'Customer Payments based on AR billings',
            meta: {
              permissions: ['billing_payments_store'],
              appScreen: 'Enter Customer Payments: 50.50',
            },
            component: CustomerPayments,
          },
          {
            path: 'proof-listing',
            name: 'AR Payments Proof Listing',
            title: 'Payments Proof Listing',
            meta: {
              permissions: ['billing_payments_store'],
              appScreen: 'Customer Payments Proof Listing: 50.51',
            },
            component: PaymentsProofListing,
          },
          {
            path: 'clear-joint-checks',
            name: 'Clear Joint Checks',
            title: 'Clear Joint Checks',
            description: 'Clear Joint Customer Checks',
            meta: {
              permissions: ['billing_payments_store'],
              appScreen: 'Clear Joint Customer Checks: 50.60',
            },
            component: ClearJointChecks,
          },
          {
            path: 'batches',
            name: 'AR Payment Batches',
            title: 'Payment Batches',
            description: 'Manage A/P Payment Batches',
            meta: {
              permissions: ['billing_payments_show'],
            },
            component: PaymentBatches,
          },
          {
            path: 'batches/:journal/view',
            name: 'AR Payment Batches Journal',
            meta: {
              permissions: ['billing_payments_show'],
            },
            component: PaymentBatchDetails,
          },
          {
            path: 'all',
            name: 'AR Payments',
            title: 'AR Payment History',
            meta: {
              permissions: ['billing_payments_show'],
            },
            component: AllPayments,
          },
        ]
      },
      {
        path: 'statements',
        name: 'Statement Batches',
        title: 'Statements Batches List',
        description: 'Quickly access and manage Accounts Receivable Customer Statements Batches',
        meta: {
          permissions: ['customer_statements_show'],
          productionReady: true,
        },
        component: StatementBatches,
      },
      {
        path: 'statement-batches/:date',
        name: 'Statement Batch Details',
        meta: {
          permissions: ['customer_statements_show'],
          productionReady: true,
        },
        component: Statements,
      },
      {
        path: 'lump-sum',
        name: 'Lump Sum Billings ',
        title: 'Lump Sum Billings',
        description: 'Manage A/R Lump Sum Billings',
        meta: {
          permissions: ['lump_sum_billings_show'],
          productionReady: true,
        },
        component: LumpSumBillings,
      },
      {
        path: 'lump-sum/batches/:date',
        name: 'Lump Sum Billings Batch',
        meta: {
          permissions: ['lump_sum_billings_show'],
          productionReady: true,
        },
        component: LumpSumBillingsBatches,
      },
      {
        path: 'reports',
        name: 'AR Reports',
        redirect: '/accounts-receivable/reports/aged-by-customer',
        meta: {
          permissions: ['accounts_receivable_reports'],
        },
        component: Reports,
        children: reportRoutes,
      },
      {
        path: 'settings',
        name: 'Sales & Tax Settings',
        redirect: '/accounts-receivable/settings/accounts-receivable-settings',
        meta: {
          hideBreadCrumb: true,
          permissions: ['accounts_receivable_settings'],
        },
        component: SettingsLayout,
        children: [
          {
            path: 'accounts-receivable-settings',
            name: 'Accounts Receivable Settings',
            title: 'Accounts Receivable Settings',
            description: 'Initialize or modify AR Settings',
            meta: {
              permissions: ['accounts_receivable_settings'],
            },
            component: AccountsReceivableSettings,
          },
          {
            path: 'activity-log',
            name: 'AR Settings Update Activity',
            meta: {
              permissions: ['accounts_receivable_settings'],
              module: $modules.AR,
            },
            component: SettingsActivityLog,
          },
          {
            path: 'sales-tax-districts',
            name: 'Sales Tax Districts',
            title: 'Sales Tax Districts',
            description: 'Define Sales Tax Districts',
            meta: {
              permissions: ['districts_show'],
            },
            component: SalesTaxDistrictList,
          },
          {
            path: 'sales-tax-districts/import',
            name: 'Import AR Sales Tax Districts',
            title: 'Import AR Sales Tax Districts',
            description: 'Import AR Sales Tax Districts from a CSV or Excel file',
            meta: {
              permissions: ['districts_update'],
            },
            component: SalesTaxDistrictImport,
          },
          {
            path: 'ar-initialize',
            name: 'Initialize Open Receivable',
            title: 'Initialize Open Receivable',
            meta: {
              hideBreadCrumb: true,
              permissions: ['accounts_receivable_settings'],
              appScreen: 'Initialize Open Receivable: 59.94 ',
              module: 'accounts-receivable',
            },
            component: AccountsReceivableInitList,
          },
          {
            path: 'ar-initialize/add',
            name: 'Add Open Receivable',
            title: 'Add Open Receivable',
            meta: {
              permissions: ['accounts_receivable_settings'],
            },
            component: AccountsReceivableInitAdd,
          },
          {
            path: 'ar-initialize/import',
            name: 'Import Open Receivables',
            title: 'Import Open Receivables',
            meta: {
              permissions: ['accounts_receivable_settings'],
            },
            component: AccountsReceivableInitImport,
          },
          {
            path: 'ar-initialize/:id',
            meta: {
              permissions: ['accounts_receivable_settings'],
            },
            component: AccountsReceivableInitLayout,
            children: [
              {
                path: 'edit',
                title: 'Edit Open Receivable',
                meta: {
                  permissions: ['accounts_receivable_settings'],
                },
                component: AccountsReceivableInitEdit,
              },
              {
                path: 'proof-listing',
                title: 'Proof Listing',
                meta: {
                  permissions: ['accounts_receivable_settings'],
                },
                component: AccountsReceivableInitProofListing,
              },
            ],
          },
            {
                path: 'billing-rate-types',
                name: 'Billing Rate Types',
                title: 'Billing Rate Types',
                description: 'Manage Billing Rate Types',
                meta: {
                    permissions: ['payroll_settings'],
                },
                component: BillingRateTypes,
            },
          {
            path: 'billing-rates',
            title: 'Billing rate overrides for individual customers for either jobs or work orders.',
            redirect: '/accounts-receivable/settings/billing-rates/material',
            meta: {
              permissions: ['customers_show'],
            },
            component: BillingRatesLayout,
            children: [
              {
                path: 'material',
                name: 'Material Bill Type',
                meta: {
                  permissions: ['customers_show'],
                },
                component: TypeMaterial,
              },
              {
                path: 'equipment',
                name: 'Equipment Bill Type',
                meta: {
                  permissions: ['customers_show'],
                },
                component: TypeEquipment,
              },
              {
                path: 'craft-code',
                name: 'Craft/Level Bill Type',
                meta: {
                  permissions: ['customers_show'],
                },
                component: TypeCraftCode,
              },
              {
                path: 'add',
                name: 'Add Customer Billing Rates',
                meta: {
                  permissions: ['customers_show'],
                },
                component: BillingRateAdd,
              },
              {
                path: ':customer_id/:apply_to/:source_type/edit',
                name: 'Edit Customer Billing Rates',
                meta: {
                  permissions: ['customers_show'],
                },
                component: BillingRateEdit,
              },
            ]
          },
        ],
      },
      {
        path: 'journals',
        name: 'AR Journals',
        redirect: 'journals',
        meta: {
          permissions: ['accounts_receivable_reports'],
          appScreen: 'General Journal Entries: 10.40',
          module: 'accounts-receivable',
        },
        component: EmptyLayout,
        children: [
          {
            path: '',
            name: 'AR Journals ',
            title: 'AR Journals',
            meta: {
              hideBreadCrumb: true,
              permissions: ['accounts_receivable_reports'],
              appScreen: 'General Journal Entries: 10.40',
              module: 'accounts-receivable',
            },
            component: JournalList,
          },
          {
            path: ':id/view',
            name: 'AR Journal Details',
            meta: {
              permissions: ['accounts_receivable_reports'],
              appScreen: 'General Journal Entries: 10.40',
              module: 'accounts-receivable',
            },
            component: JournalDetails,
          },
        ],
      },
    ],
  },
]
