<template>
  <div class="w-full">
    <span class="action-item-text"
          @click="showImportDialog = true">
      <div class="p-2 bg-primary-50 mr-2 rounded-md">
        <UploadIcon class="w-4 h-4 text-primary-500"/>
      </div>
      <span>{{ $t('Import') }}</span>
    </span>
    <BaseFormDialog v-if="showImportDialog"
                    :title="$t('Import data from CSV / Excel')"
                    :open.sync="showImportDialog"
                    :append-to-body="true"
                    size="xl"
    >
      <ValidationObserver v-slot="{ handleSubmit }">
        <base-form :loading="loading"
                   layout="modal"
                   @submit="handleSubmit(onSubmit)"
        >
          <div class="col-span-6 md:col-span-1">
            <el-upload
                class="mt-5"
                action="default"
                accept=".xlsx, .xls, .csv"
                :auto-upload="false"
                :on-change="parseFile"
                :on-remove="onFileRemove"
                :limit="1"
            >
              <base-button class="select-file-button focus:outline-none focus:ring-blue"
                           ref="uploadButton"
                           variant="white">
                {{ $t('Select File') }}
              </base-button>
            </el-upload>
          </div>
          <div class="col-span-6 md:col-span-1">
            <base-input v-model.lazy="form.start_row"
                        :label="$t('Start at row')"
                        :placeholder="$t('Row from file to start at')"
                        :name="$t('Start at row')"
                        :min="1"
                        id="start_row"
                        rules="min_value:1"
                        type="number"
            />
          </div>
          <div v-if="overwriteAccounts"
               class="col-span-6 md:col-span-2 ml-2">
            <label class="block text-sm font-medium leading-5 text-gray-700 mb-3">
              {{ $t('Update matching accounts') }}
            </label>
            <base-switch v-model.number="form.overwrite"
                         id="overwrite"
            />
          </div>
          <slot name="addition"/>
          <div class="w-full col-span-6 mb-5 mt-3">
            <div class="px-4 pb-5 border-b border-gray-200 sm:px-6">
              <h4 class="text-center">
                {{ $t('File Preview') }}
              </h4>
            </div>
          </div>
          <div v-if="!form.file" class="w-full col-span-6 mt-3">
            <h5 class="text-center w-full font-medium text-gray-600">
              {{ $t('Please select a file to preview the data') }}
            </h5>
          </div>
          <div :key="form.start_row"
               class="col-span-6 overflow-x-auto visible-scroll flex flex-col">
            <div class="flex w-full justify-between">
              <div v-for="index in maxColumnValue"
                   class="flex flex-col w-full data-column mb-3"
                   :key="`1-${index}`">
                <div class="flex justify-between w-full">
                  <base-select :value="getFieldValue(index)"
                               :options="getFieldOptions(index)"
                               :label="`Column ${index}`"
                               class="w-full"
                               inline-errors
                               @change="onFieldUpdate($event, index)"
                  />

                  <component
                      class="mx-1 pt-5"
                      :is="getDefaultComponent(index)"
                      v-bind="getDefaultComponentProps(index)"
                      @on-change="onUpdateDefaults($event, index)"/>

                  <component
                      class="mx-1 pt-5"
                      :options="getTransforms(index)"
                      :is="getTransformsComponent(index)"
                      @on-change="onUpdateTransforms"/>

                </div>
                <div v-if="getDefaultValue(index)" class="w-full mt-1 truncate">
                  <b>{{ getDefaultValue(index) }}</b> {{ $t('will be used') }}
                </div>
                <div class="w-full mt-2">
                  <base-checkbox v-if="getFieldName(index) === 'credit' && getFieldName(index) !== 'debit'"
                                 :value="form[`mapping[credit]`] === form[`mapping[debit]`]"
                                 :label="$t('Also use for debit')"
                                 @input="onDebitCheckboxChange($event, index)">
                    {{ $t('Also use for debit') }}
                  </base-checkbox>
                  <base-checkbox v-if="getFieldName(index) === 'debit' && getFieldName(index) !== 'credit'"
                                 :value="form[`mapping[credit]`] === form[`mapping[debit]`]"
                                 :label="$t('Also use for credit')"
                                 @input="onCreditCheckboxChange($event, index)">
                  </base-checkbox>
                </div>
              </div>
            </div>
            <div class="file-data-preview flex flex-col w-full">
              <div v-for="(row, index) in fileData"
                   class="flex justify-between"
                   :key="`2-${index}`">
                <div v-for="field in row" :key="field" class="flex truncate w-full data-column truncate">
                  {{ field }}
                </div>
              </div>

              <div v-if="extraFileRows" class="col-span-6 flex text-center w-full justify-center">
                + {{ extraFileRows }} {{ $t(' more rows') }}
              </div>
            </div>
          </div>

          <template v-slot:footer>
            <div class="flex items-center">
              <base-checkbox v-model="saveAsFuturePreset"
                             :label="$t('Save as future preset')"
                             class="mr-4"
              >
              </base-checkbox>
              <base-cancel-button class="mr-3"
                                  @click="$emit('cancel')">
                {{ $t('Cancel') }}
              </base-cancel-button>
              <base-submit-button :loading="loading"
                                  :disabled="!isFormValid"
                                  @click="handleSubmit(onSubmit)">
                {{ $t('Import') }}
              </base-submit-button>
            </div>
          </template>
        </base-form>
      </ValidationObserver>
    </BaseFormDialog>
  </div>
</template>
<script>
  import axios from 'axios'
  import { Upload } from 'element-ui'
  import { UploadIcon } from 'vue-feather-icons'
  import DefaultField from '@/components/table/actions/import/DefaultField'
  import DefaultPeriod from '@/components/table/actions/import/DefaultPeriod'
  import LedgerAccountTypes from '@/components/table/actions/import/LedgerAccountTypes'

  export default {
    name: 'ImportAction',
    components: {
      DefaultField,
      DefaultPeriod,
      UploadIcon,
      [Upload.name]: Upload,
      [LedgerAccountTypes.name]: LedgerAccountTypes,
    },
    props: {
      modelName: {
        type: String,
        default: 'accounts',
      },
    },
    data() {
      return {
        loading: false,
        showImportDialog: false,
        models: {
          'accounts': {
            overwriteAccounts: true,
            fields: [
              'account_number',
              'description',
              'type',
            ],
            fieldLabels: [
              this.$t('Account Number'),
              this.$t('Description'),
              this.$t('Account Type'),
            ],
            transforms: {
              type: [
                {
                  key: 'asset',
                  value: 'Asset',
                  label: this.$t('Asset'),
                },
                {
                  key: 'liability',
                  value: 'Liability',
                  label: this.$t('Liability'),
                },
                {
                  key: 'income',
                  value: 'Income',
                  label: this.$t('Income'),
                },
                {
                  key: 'expense',
                  value: 'Expense',
                  label: this.$t('Expense'),
                },
              ],
            },
            requiredFields: {},
          },
          'journal-entries': {
            overwriteAccounts: true,
            fields: [
              'account',
              'subaccount',
              'description',
              'period',
              'fiscal_year',
              'journal_name',
              'reference_date',
              'journal_type',
              'debit',
              'credit',
            ],
            fieldLabels: [
              this.$t('Account'),
              this.$t('Sub Account'),
              this.$t('Entry Description'),
              this.$t('Period'),
              this.$t('Fiscal Year'),
              this.$t('Journal'),
              this.$t('Reference Date'),
              this.$t('Type'),
              this.$t('Debit'),
              this.$t('Credit'),
            ],
            transforms: {},
            defaults: {
              journal_name: true,
              reference_date: true,
              period: true,
              fiscal_year: true,
              description: true,
            },
            requiredFields: {},
          },
          'gen-liability-rates': {
            fields: [
              'code',
              'start_date',
              'amount',
              'state',
              'description',
              'calculate_by',
              'multiply_by',
            ],
            fieldLabels: [
              this.$t('Code'),
              this.$t('Start Date'),
              this.$t('Amount'),
              this.$t('State'),
              this.$t('Description'),
              this.$t('Calculate By'),
              this.$t('Multiply By'),
            ],
            transforms: {},
            defaults: {
              code: true,
              start_date: true,
              amount: true,
              state: true,
              description: true,
              calculate_by: true,
              multiply_by: true,
            },
            requiredFields: [
              'code',
            ],
          },
          'workers-comp-rates': {
            fields: [
              'code',
              'start_date',
              'employer_rate',
              'employee_deduction_rate',
              'state',
              'description',
              'calculate_by',
              'multiply_by',
            ],
            fieldLabels: [
              this.$t('Code'),
              this.$t('Start Date'),
              this.$t('Employer Amount'),
              this.$t('Employee Deduction'),
              this.$t('State'),
              this.$t('Description'),
              this.$t('Calculate By'),
              this.$t('Multiply By'),
            ],
            transforms: {},
            defaults: {
              code: true,
              start_date: true,
              employer_rate: true,
              employee_deduction_rate: true,
              state: true,
              description: true,
              calculate_by: true,
              multiply_by: true,
            },
            requiredFields: [
              'code',
            ],
          },
        },
        form: {
          file: null,
          overwrite: false,
          fiscal_year: this.activeFiscalYear,
          period_end_date: null,
          start_row: 5,
        },
        mappings: {},
        saveAsFuturePreset: false,
        fileResults: {
          data: [],
          errors: [],
        },
      }
    },
    computed: {
      currentModel() {
        return this.get(this.models, this.modelName, {})
      },
      overwriteAccounts() {
        return this.currentModel.overwriteAccounts
      },
      fields() {
        return this.currentModel.fields
      },
      fieldLabels() {
        return this.currentModel.fieldLabels
      },
      requiredFields() {
        return this.currentModel.requiredFields
      },
      fieldOptions() {
        const options = this.fields.map((field, index) => {
          return {
            value: index,
            label: this.fieldLabels[index],
            name: this.fields[index],
          }
        })
        options.push({
          label: this.$t('Skip'),
          value: -1,
          name: '',
        })
        return options
      },
      maxColumnValue() {
        let max = 0
        this.fileResults.data.forEach(row => {
          if (max < row.length) {
            max = row.length
          }
        })
        return max
      },
      fileData() {
        const maxItemsToDisplay = 50
        if (this.form.start_row < 1 || typeof this.form.start_row !== 'number' || !this.form.start_row) {
          return this.fileResults.data.slice(0, maxItemsToDisplay)
        }
        if (this.form.start_row < this.fileResults.data.length) {
          return this.fileResults.data.slice(this.form.start_row - 1, this.form.start_row + maxItemsToDisplay)
        }
        return this.fileResults.data.slice(0, maxItemsToDisplay)
      },
      extraFileRows() {
        const maxItemsToDisplay = 50
        const totalRows = this.fileResults.data.length
        if (totalRows > maxItemsToDisplay) {
          return totalRows - maxItemsToDisplay
        }
        return 0
      },
      isFormValid() {
        return this.form.file
      },
    },
    methods: {
      getFieldOptions(index) {
        const fieldName = this.getFieldName(index)
        if (this.requiredFields[fieldName]) {
          return this.fieldOptions.slice(0, this.fieldOptions.length - 1)
        }
        return this.fieldOptions
      },
      async onSubmit() {
        try {
          this.loading = true
          const formData = new FormData();
          for (let key in this.form) {
            let value = this.form[key]
            if (key === 'overwrite') {
              value = value ? '1' : '0'
            }
            formData.append(key, value)
          }
          await axios.post(`/restify/${this.modelName}/import`, formData)
          this.$success(this.$t('Data imported successfully!'))

          if (this.saveAsFuturePreset) {
            this.savePresetInStore()
          }

          this.$emit('save')
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not import the data'))
        } finally {
          this.loading = false
        }
      },
      setFieldMappings(modelName) {
        const modelFields = this.get(this.models, `[${modelName}].fields`, [])
        let mappings = {}
        const savedPreset = this.$store.state.fileImport.presets[modelName]
        modelFields.forEach((field, index) => {
          const key = `mapping[${field}]`
          mappings[key] = index
        })

        if (savedPreset) {
          mappings = {
            ...mappings,
            startRow: savedPreset.startRow,
            overwrite: savedPreset.overwrite || false,
            ...savedPreset.mappings,
          }
        }
        this.form = {
          file: this.form.file,
          fiscal_year: this.form.fiscal_year,
          year: this.form.fiscal_year,
          start_row: this.form.start_row,
          overwrite: this.form.overwrite,
          ...mappings,
        }
      },
      getColumnName(index) {
        return this.get(this.fileResults, `data.[${this.form.start_row - 2}][${index - 1}]`, '')
      },
      getFieldValue(value) {
        const fieldName = this.getFieldName(value)
        if (!fieldName) {
          return -1
        }
        const originalField = this.fieldOptions.find(f => f.name === fieldName)
        if (!originalField) {
          return -1
        }
        return originalField.value
      },
      getFieldName(value) {
        value = value - 1
        for (let key in this.form) {
          if (this.form[key] === value && key.startsWith('mapping')) {
            return key.replace(/mapping\[(.+)]/, '$1')
          }
        }
        return ''
      },
      getTransforms(fieldIndex) {
        const field = this.getFieldName(fieldIndex)
        return this.get(this.models, `[${this.modelName}].transforms[${field}]`)
      },
      canSetDefaults(fieldName) {
        return this.get(this.models, `[${this.modelName}].defaults[${fieldName}]`)
      },
      getDefaultValue(index) {
        const fieldName = this.getFieldName(index)
        return this.get(this.form, `defaults[${fieldName}]`)
      },
      getTransformsComponent(fieldIndex) {
        const field = this.getFieldName(fieldIndex)
        if (!this.getTransforms(fieldIndex)) {
          return
        }
        return `accounts-${field}`
      },
      getDefaultComponent(fieldIndex) {
        const fieldName = this.getFieldName(fieldIndex)
        if (!this.canSetDefaults(fieldName)) {
          return
        }
        const componentMapping = {
          period: 'default-period',
          default: 'default-field',
        }
        return componentMapping[fieldName] || componentMapping.default
      },
      getDefaultComponentProps(fieldIndex) {
        const fieldName = this.getFieldName(fieldIndex)
        if (!this.canSetDefaults(fieldName)) {
          return {}
        }
        const index = this.fields.indexOf(fieldName)
        const fieldLabel = this.fieldLabels[index]
        const baseProps = {
          placeholder: fieldLabel,
          label: this.$t('Default ') + fieldLabel,
        }
        if (fieldName === 'reference_date') {
          baseProps.type = 'date'
        }
        return baseProps
      },
      onUpdateTransforms(data) {
        let transforms = {}
        data.forEach((el) => {
          const key = `transforms[${el.key}]`
          transforms[key] = el.value
        })

        this.form = {
          ...this.form,
          ...transforms,
        }
      },
      onUpdateDefaults(value, index) {
        const fieldName = this.getFieldName(index)
        this.$set(this.form, `defaults[${fieldName}]`, value)
      },
      onFieldUpdate(value, index) {
        const field = this.fieldOptions.find(f => f.value === value)
        if (!field) {
          return
        }
        const fieldName = field.name
        const oldValue = this.getFieldValue(index)
        let oldFieldName = this.getFieldName(oldValue + 1)
        if (!oldFieldName) {
          oldFieldName = this.getFieldName(index)
        }

        if (oldFieldName) {
          this.form[`mapping[${oldFieldName}]`] = -1
        }

        if (value !== -1 && fieldName) {
          this.form[`mapping[${fieldName}]`] = index - 1
        }
      },
      onDebitCheckboxChange(value, index) {
        this.form[`mapping[debit]`] = value ? index - 1 : -1
      },
      onCreditCheckboxChange(value, index) {
        this.form[`mapping[credit]`] = value ? index - 1 : -1
      },
      savePresetInStore() {
        let mappings = {}
        const preset = {
          start_row: this.form.start_row,
          overwrite: this.form.overwrite,
          mappings,
        }
        this.fields.forEach(field => {
          mappings[`mapping[${field}]`] = this.form[`mapping[${field}]`]
        })
        this.$store.commit('fileImport/SET_PRESET', {
          modelName: this.modelName,
          preset,
        })
      },
      async parseFile(file) {
        if (!file) {
          this.$error('Please select a CSV / Excel file')
          return
        }
        try {

          let fileData = file.raw

          if (fileData.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            return await this.parseExcelFile(fileData)
          }

          return await this.parseCsvFile(fileData)
        } catch (err) {
          this.$error(`Could not parse the file. Please make sure it's a valid CSV / Excel and try again.`)
        }
      },
      async parseCsvFile(fileData) {
        let Papa = await import('papaparse')
        Papa = Papa?.default || Papa

        Papa.parse(fileData, {
          dynamicTyping: true,
          complete: (results, file) => {
            this.fileResults = results
            this.form.file = file
          },
        })
      },
      async parseExcelFile(fileData) {
        let XLXS = await import('xlsx')
        XLXS = XLXS?.default || XLXS
        const reader = new FileReader('xlsx');
        reader.onload = (e) => {
          let data = new Uint8Array(e.target.result);
          let workbook = XLSX.read(data, { type: 'array' });
          let sheetName = workbook.SheetNames[0]

          let worksheet = workbook.Sheets[sheetName];
          this.fileResults.data = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
        };
        reader.readAsArrayBuffer(fileData)
        this.form.file = fileData
      },
      onFileRemove() {
        this.form.file = null
        this.fileResults.data = []
      },
    },
    watch: {
      activeFiscalYear: {
        immediate: true,
        handler(value) {
          this.form.fiscal_year = value
        },
      },
      modelName: {
        immediate: true,
        handler(value) {
          this.setFieldMappings(value)
        },
      },
    },
    mounted() {
      const uploadButton = this.$el.querySelector('.select-file-button button')
      if (uploadButton) {
        uploadButton.click()
      }
    },
  }
</script>
<style scoped>
  .file-data-preview {
    max-height: 50vh;
  }

  .data-column {
    min-width: 240px;
    max-width: 240px;
    @apply mr-5;
  }
</style>
