import i18n from '@/i18n'
import { globalResources } from '@/components/form/util'
import { equipmentTypeWithMaintenance } from "@/enum/enums";

export const costCenterJobTypes = {
  Job: 'job',
  Type: 'job-type',
  LineItem: 'line-item',
}

export const costOrIncomeTypes = {
  Cost: 'cost',
  Income: 'income',
}

export const costCenterTypes = {
  GeneralAndAdministrative: 'G&A',
  Job: 'JOB',
  WorkOrder: 'S/B',
  Equipment: 'EQP',
  Inventory: 'INV',
  Material: 'MTL',
  Labor: 'LAB',
  Global: 'Global'
}

export const sourceMap = {
  [costCenterTypes.Job]: globalResources.Jobs,
  [costCenterTypes.WorkOrder]: globalResources.WorkOrders,
  [costCenterTypes.Equipment]: globalResources.Equipments,
  default: '',
}

export const sourceTypeMap = {
  [costCenterTypes.Job]: 'job',
  [costCenterTypes.WorkOrder]: 'work-order',
  [costCenterTypes.Equipment]: 'equipment',
  [costCenterTypes.Inventory]: 'material',
  [costCenterTypes.Material]: 'material',
  [costCenterTypes.Labor]: 'craft-code',
  default: '',
}

export const sourceTypeAbbrMap = {
  [sourceTypeMap[costCenterTypes.Job]]: 'JOB',
  [sourceTypeMap[costCenterTypes.WorkOrder]]: 'WO',
  [sourceTypeMap[costCenterTypes.Equipment]]: 'EQP',
  [sourceTypeMap[costCenterTypes.Material]]: 'MAT',
}

export const typeTypesMap = {
  [costCenterTypes.Job]: globalResources.JobCostTypes,
  [costCenterTypes.Equipment]: globalResources.EquipmentTypes,
  [costCenterTypes.WorkOrder]: globalResources.ServiceBillingCostTypes,
  default: '',
}

export const typeTypes = {
  [costCenterTypes.Job]: 'job-type',
  [costCenterTypes.Equipment]: 'equipment-type',
  [costCenterTypes.WorkOrder]: 'service-billing-type',
  [costCenterTypes.Inventory]: 'material',
  default: '',
}

export const additionalSourceMap = {
  [costCenterTypes.Job]: globalResources.CostLineItems,
  [costCenterTypes.Equipment]: globalResources.EquipmentMaintenances,
  default: '',
}

export const additionalSourceTypeMap = {
  [costCenterTypes.Job]: 'line-item',
  [costCenterTypes.Equipment]: 'maintenance-code',
  default: '',
}

const resourceRelated = {
  Source: 'source_id',
  Type: 'type_id',
  AddlSource: 'addl_source_id',
}

export const costCenterDefaultFields = [
  'source_id',
  'source_type',
  'addl_source_id',
  'addl_source_type',
  'type_id',
  'type_type',
  'cost_center',
  'account',
  'subaccount',
]

export const resourceRelatedFields = Object.values(resourceRelated)

const colTitleMap = {
  [costCenterTypes.Job]: {
    [resourceRelated.Source]: 'Job',
    [resourceRelated.Type]: 'Type',
    [resourceRelated.AddlSource]: 'Line Item',
  },
  [costCenterTypes.WorkOrder]: {
    [resourceRelated.Source]: 'Work Order',
    [resourceRelated.Type]: 'Type',
  },
  [costCenterTypes.Equipment]: {
    [resourceRelated.Source]: 'Equipment',
    [resourceRelated.Type]: 'Type',
    [resourceRelated.AddlSource]: 'Maint Code',
  },
  default: {},
}

export const readOnlyCellProps = {
  editor: false,
  readOnly: true,
  className: 'p-x-3 disabled',
}

export function setTypeSources(entry) {
  if (!entry.cost_center) {
    return entry
  }
  entry.source_type = entry.source_id ? sourceTypeMap[entry.cost_center] : null
  entry.addl_source_type = entry.addl_source_id ? additionalSourceTypeMap[entry.cost_center] : null
  entry.type_type = entry.type_id ? typeTypes[entry.cost_center] : null
  entry.type_id = [costCenterTypes.Inventory, costCenterTypes.GeneralAndAdministrative].includes(entry.cost_center) ? null : entry.type_id
  return entry
}

export class CostCenterClass {
  vmInstance = {
    entries: []
  }
  haveMaterialSource = false
  dynamicColTitles = colTitleMap

  constructor(vm, haveMaterialSource = false) {
    this.vmInstance = vm
    this.haveMaterialSource = haveMaterialSource
    haveMaterialSource ? this.dynamicColTitles = {
      ...colTitleMap,
      ...{
        [costCenterTypes.Inventory]: {
          [resourceRelated.Source]: 'Material',
        },
      }
    } : colTitleMap
  }

  costCenterOptions(canBeAppliedToInventory = false) {
    const options = [
      {
        label: i18n.t('G&A'),
        value: costCenterTypes.GeneralAndAdministrative,
      },
      {
        label: i18n.t('JOB'),
        value: costCenterTypes.Job,
      },
      {
        label: i18n.t('S/B'),
        value: costCenterTypes.WorkOrder,
      },
      {
        label: i18n.t('EQP'),
        value: costCenterTypes.Equipment,
      },
    ]
    if (!canBeAppliedToInventory) {
      return options
    }
    return [
      ...options,
      {
        label: i18n.t('INV'),
        value: costCenterTypes.Inventory,
      },
    ]
  }

  equipmentTypeIdWithMaintenance() {
    return this.vmInstance.$store.getters['globalLists/getEquipmentTypeIdWithMaintenance']
  }

  cellsPropForInventoryType(prop) {
    if (!this.haveMaterialSource) {
      return readOnlyCellProps
    }
    return prop === resourceRelated.Source ? {} : readOnlyCellProps
  }

  setCellsProps(prop, costCenter, typeId) {
    if (!costCenter || costCenter === costCenterTypes.GeneralAndAdministrative) {
      return readOnlyCellProps
    }

    if (costCenter === costCenterTypes.Inventory) {
      return this.cellsPropForInventoryType(prop)
    }

    if (prop !== resourceRelated.AddlSource) {
      return {}
    }

    if (costCenter === costCenterTypes.WorkOrder) {
      return readOnlyCellProps
    }

    if (costCenter === costCenterTypes.Equipment) {
      return typeId === this.equipmentTypeIdWithMaintenance() ? {} : readOnlyCellProps
    }

    return {}
  }

  onChangeCostCenter(entry, row, costCenter) {
    let type_id = ''
    let type_type = ''

    if (costCenter === costCenterTypes.Equipment) {
      type_id = this.equipmentTypeIdWithMaintenance()
      type_type = equipmentTypeWithMaintenance
    }

    entry = {
      ...entry,
      quantity: 0,
      source_id: '',
      source_type: '',
      type_id,
      type_type,
      addl_source_id: '',
      addl_source_type: '',
      account: '',
      subaccount: '',
    }

    this.vmInstance.entries[row] = entry
    this.setGridData()
    return entry
  }

  onClearType(row) {
    this.onChangeType({
      row,
      resource: {
        id: '',
      },
    })
  }

  onChangeType({ row, resource }) {
    let entry = this.vmInstance.entries[row]
    entry.addl_source_id = ''
    entry.type_id = resource?.id || ''
    this.vmInstance.entries[row] = entry
    this.setGridData()
  }

  setGridData() {
    const { gridTable } = this.vmInstance.$refs
    if (!gridTable) {
      return
    }
    gridTable.setDataAtSource()
  }

  getColHeaderTitle(prop, entry) {
    let title = ''
    if (prop === resourceRelated.Source) {
      title = this.dynamicColTitles[entry.cost_center]?.[resourceRelated.Source]
      return i18n.t(title)
    }
    if (prop === resourceRelated.Type) {
      title = this.dynamicColTitles[entry.cost_center]?.[resourceRelated.Type]
      return i18n.t(title)
    }
    if (prop === resourceRelated.AddlSource) {
      title = this.dynamicColTitles[entry.cost_center]?.[resourceRelated.AddlSource]
      return i18n.t(title)
    }
    return ''
  }

  setTypes(entry) {
    return setTypeSources(entry)
  }
}

export function sourceRequiredValidation(value, callback, instance) {
  const gridInstance = instance || this.instance
  const sourceValue = gridInstance?.getDataAtRowProp(this.row, 'cost_center')
  let isRequired = !!sourceValue

  if ([costCenterTypes.Inventory, costCenterTypes.GeneralAndAdministrative].includes(sourceValue)) {
    isRequired = false
  }
  if (typeof callback === 'function') {
    return callback(isRequired ? !!value : true)
  }
  return isRequired ? !!value : true
}

export function additionalSourceRequiredValidation(value, callback, instance) {
  const gridInstance = instance || this.instance
  const costCenter = gridInstance?.getDataAtRowProp(this.row, 'cost_center')

  let isRequired = costCenter === costCenterTypes.Job

  if (typeof callback === 'function') {
    return callback(isRequired ? !!value : true)
  }
  return isRequired ? !!value : true
}
