<template>
  <div :class="{
    'p-4': params?.data?.id,
  }">
    <AgDataTable
      v-bind="editableTableProps"
      :url="url"
      :url-params="urlParams"
      :default-filters="false"
      :columns="columns"
      :read-only="isReadOnly"
      :add-text="$t('Add Entry')"
      :transform-data="transformData"
      :get-empty-row="getEmptyRow"
      :show-cells-legend="!isReadOnly"
      :groupIncludeTotalFooter="true"
      :no-borders="isReadOnly"
      :actions="tableActions"
      :perPage="1000"
      :authorizeToCopyLastRow="!data.has_offset_account"
      suppressColumnReordering
      default-sort="updated_at"
      id="gridTable"
      ref="table"
      @grid-ready="onGridReady"
      @cell-value-changed="onCellValueChanged"
    />
  </div>
</template>
<script>
import axios from 'axios'
import { cellEditors } from '@/components/ag-grid/cellEditors/cellEditors'
import { addNewRow, editableTableProps, getTableData } from '@/components/ag-grid/tableUtils'
import { accountCol, descriptionCol, subAccountCol } from '@/components/ag-grid/columns/costCenterColumns'
import { cellClasses, getCellClasses, requiredValueSetter, stopEditingOnTab } from '@/components/ag-grid/columnUtils'
import { getDeleteColumn } from "@/components/ag-grid/columns/deleteColumns";
import { costCenterFields, getDefaultAccounts } from "@/modules/common/util/costCenterUtils";
import { costCenterTypes } from "@/components/grid-table/utils/cost-center";

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editableTableProps,
      grid: null,
    }
  },
  computed: {
    batchId() {
      return this.data.id || this.params?.data?.id
    },
    url() {
      return this.batchId ? '/restify/equipment-adjustment-entries' : ''
    },
    urlParams() {
      const baseParams = {
        sort: 'order',
      }
      return this.batchId ? { equipment_adjustment_id: this.batchId, ...baseParams } : baseParams
    },
    tableActions() {
      return this.isReadOnly ? '' : 'add'
    },
    columns() {
      return [
        {
          headerName: this.$t('Equipment'),
          field: 'equipment_id',
          cellEditor: this.$cellEditors.GlobalResourceSelect,
          component: 'EquipmentLink',
          cellEditorParams: {
            resourceName: this.$globalResources.Equipments,
            target: '_blank',
          },
          editable: true,
          minWidth: 100,
          maxWidth: 300,
          suppressNavigable: (params) => {
            return params.data?.is_offset
          },
        },
        {
          headerName: this.$t('Equipment Type'),
          field: 'equipment_type_id',
          cellEditor: this.$cellEditors.GlobalResourceSelect,
          cellEditorParams: {
            resourceName: this.$globalResources.EquipmentTypes,
            target: '_blank',
          },
          valueFormatter: params => {
            const type = this.$store.getters['globalLists/getResourceById'](this.$globalResources.EquipmentTypes, params.value)
            return type?.name
          },
          editable: true,
          minWidth: 120,
          maxWidth: 200,
          suppressNavigable: (params) => {
            return params.data?.is_offset
          },
        },
        {
          headerName: this.$t('Maint Code'),
          field: 'maintenance_code_id',
          cellEditor: this.$cellEditors.GlobalResourceSelect,
          cellEditorParams: {
            resourceName: this.$globalResources.EquipmentMaintenances,
            target: '_blank',
          },
          valueFormatter: params => {
            const type = this.$store.getters['globalLists/getResourceById'](this.$globalResources.EquipmentMaintenances, params.value)
            return type?.code
          },
          editable: params => {
            return this.isRepairsTypeId(params)
          },
          minWidth: 100,
          maxWidth: 160,
          cellClass: params => {
            if (!params.data) {
              return ''
            }
            if (!this.isRepairsTypeId(params)) {
              return cellClasses.ReadOnly
            }
            return ''
          },
          suppressNavigable: (params) => {
            return !this.isRepairsTypeId(params)
          },
        },
        {
          ...descriptionCol,
        },
        {
          headerName: this.$t('Post to G/L'),
          field: 'post_to_gl',
          editable: true,
          cellEditor: this.$cellEditors.Boolean,
          component: 'Status',
          minWidth: 60,
          maxWidth: 100,
          valueSetter: params => {
            const value = +params.newValue
            if (!value) {
              params.data.account = null
              params.data.subaccount = null
              params.node.setData(params.data)
            }
            params.data.post_to_gl = !!value
            params.api?.redrawRows()
            return true
          }
        },
        {
          ...accountCol(this.readOnly),
          cellClass: params => {
            if(this.isReadOnly || !params.data) {
              return ''
            }
            const { account } = params?.data || {}

            if (!this.requiresAccount(params)) {
              return cellClasses.ReadOnly
            }

            if (!account) {
              return cellClasses.Invalid
            }
            return ''
          },
          suppressNavigable: (params) => {
            return !this.requiresAccount(params)
          }
        },
        {
          ...subAccountCol,
          cellClass: params => {
            if(this.isReadOnly || !params.data) {
              return ''
            }
            if (!this.requiresAccount(params)) {
              return cellClasses.ReadOnly
            }
            return ''
          },
          suppressNavigable: (params) => {
            return !this.requiresAccount(params)
          }
        },
        {
          headerName: this.$t('Amount'),
          field: 'amount',
          editable: true,
          cellEditor: cellEditors.Numeric,
          component: 'FormattedPrice',
          valueSetter: params => requiredValueSetter(params, 0),
          valueGetter: params => {
            if (!params.data) {
              return 0
            }
            return +params.data.amount
          },
          aggFunc: 'sum',
          minWidth: 120,
          maxWidth: 200,
          suppressKeyboardEvent: stopEditingOnTab,
        },
        {
          ...getDeleteColumn({
            url: '/restify/equipment-adjustment-entries',
            hide: this.isReadOnly,
          }),
        },
      ]
    },
    isReadOnly() {
      return this.params ? this.params.readOnly : this.readOnly
    },
  },
  methods: {
    async onCellValueChanged(params) {
      const field = params?.colDef?.field
      const entry = params.data

      const fieldsToCheck = ['equipment_id', 'equipment_type_id', 'post_to_gl']

      if (!fieldsToCheck.includes(field) || !entry.post_to_gl) {
        return true
      }

      const data = {
        ...entry,
        cost_center: costCenterTypes.Equipment,
        source_id: entry.equipment_id,
        type_id: entry.equipment_type_id,
      }
      params.data = await getDefaultAccounts(data)
      // We need to explicitly set cell values here. Otherwise, cells won't update if in edit mode
      params.node.setDataValue('account', params.data.account)
      params.node.setDataValue('subaccount', params.data.subaccount)
    },
    requiresAccount(params) {
      const { post_to_gl } = params?.data || {}
      return post_to_gl
    },
    isRepairsTypeId(params) {
      const repairsTypeId = this.$store.getters['globalLists/getEquipmentTypeIdWithMaintenance']
      return params.data?.equipment_type_id === repairsTypeId
    },
    getData() {
      const gridApi = this.grid?.api
      return getTableData(gridApi) || []
    },
    getOffsetEntry() {
      const previousRow = this.getData().at(-1)
      let {amount, reference_no, reference_date, account, subaccount, description} = previousRow
      account = this.data.account
      subaccount = this.data.subaccount

      return {
        ...this.getEmptyRowData(),
        account,
        subaccount,
        description,
        reference_no,
        reference_date,
        is_offset: true,
        post_to_gl: true,
        amount: amount * -1,
      }
    },
    onGridReady(params) {
      this.grid = params
    },
    getTableData() {
      const gridApi = this.grid?.api
      return getTableData(gridApi)
    },
    async storeProgress(equipment_adjustment_id) {
      const promises = []
      const entries = this.getTableData()

      const entriesToUpdate = entries.filter(entry => entry.dirty && entry.id)

      const entriesToStore = entries.filter(entry => !entry.id && entry.dirty).map(entry => {
        entry.equipment_adjustment_id = equipment_adjustment_id
        return entry
      })

      if (entriesToStore.length) {
        promises.push(axios.post('/restify/equipment-adjustment-entries/bulk', entriesToStore))
      }

      if (entriesToUpdate.length) {
        promises.push(axios.post('/restify/equipment-adjustment-entries/bulk/update', entriesToUpdate))
      }

      await Promise.all(promises)
      this.$refs.table.refresh()
    },
    getEmptyRowData() {
      const nextOrderValue = this.getData().length
      return {
        equipment_id: null,
        equipment_type_id: null,
        maintenance_code_id: null,
        post_to_gl: this.data?.has_offset_account || false,
        account: null,
        subaccount: null,
        description: null,
        quantity: 0,
        amount: 0,
        is_offset: false,
        _localId: crypto.randomUUID(),
        equipment_adjustment_id: this.batchId,
        order: nextOrderValue,
      }
    },
    addEmptyRowAfterOffset() {
      setTimeout(async () => {
        await addNewRow(this.grid, this.getEmptyRowData)
      }, 200)
    },
    shouldAddOffsetRow() {
      const previousRow = this.getData().at(-1)
      return previousRow && !previousRow.is_offset && this.data.has_offset_account && previousRow.post_to_gl
    },
    getEmptyRow() {
      const emptyRow = this.getEmptyRowData()
      if (this.shouldAddOffsetRow()) {
        this.addEmptyRowAfterOffset()
        return this.getOffsetEntry()
      }
      return emptyRow
    },
    transformData(data) {
      return data.map(entry => entry.attributes)
    },
  },
}
</script>
