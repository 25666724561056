<template>
  <Timecard
      :active-step="2"
      :data="currentResource"
  />
</template>
<script>
  import Timecard from '@/modules/payroll/components/timecard/Timecard'

  export default {
    components: {
      Timecard,
    },
    data() {
      return {
        loading: false,
        batch: {},
      }
    },
    computed: {
      currentResource() {
        return this.$store.state.payroll.currentTimecardBatch
      },
    },
  }
</script>
