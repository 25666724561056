<template>
  <div class="flex flex-col md:flex-row space-x-8 p-4">

    <slot></slot>
  </div>
</template>
<script>
  export default {
  }
</script>
<style>
</style>
