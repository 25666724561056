<template>
  <div>
    <base-data-table
        v-bind="defaultAttributes"
        :columns="columns"
        :data="rows"
        :total-rows="rowsLength"
        @force-pagination="forcePagination"
    >

      <template #report-header>
        <BaseReportHeader :header="data.header"/>
      </template>

      <template #account="{row}">
        <AccountLink :data="row.account" :show-description="false"/>
      </template>

      <template #html-row="{htmlData}">
        <tr class="break-before-page">
          <td colspan="8">
            <label class="mt-4 medium">
              {{ $t('Summary by account type:') }}
            </label>
          </td>
        </tr>
        <template v-for="(objectData, objectKey) in htmlData">
          <tr class="account-summaries">
            <td/>
            <td/>
            <td>
              <StatusBadge :status="objectKey"/>
            </td>
            <td align="right">
              {{ $formatPrice(objectData.beginning_balance_amount) }}
            </td>
            <td align="right">
              {{ $formatPrice(objectData.period_change?.debit_amount) }}
            </td>
            <td align="right">
              {{ $formatPrice(objectData.period_change?.credit_amount) }}
            </td>
            <td align="right">
              <label class="mx-2">
                {{ $formatPrice(objectData.ending_balance_amount) }}
              </label>
            </td>
          </tr>
        </template>
      </template>
      <template #after-table>
        <div class="flex w-full justify-end mt-2 text-gray-700 pr-3 font-mono text-sm">
          <span>
            {{ $t('Re-Computed Retained Earnings @ End of Period = ') }}
            {{ $formatPrice(data?.summary?.recomputed_retained_earnings || 0) }}
          </span>
        </div>
      </template>
    </base-data-table>
  </div>
</template>
<script lang="ts">
import {defineComponent, PropType} from 'vue'
import {Column} from '@/components/ag-grid/tableTypes'
import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper.vue'
import cloneDeep from "lodash/cloneDeep";

export default defineComponent({
  extends: ReportTableWrapper,
  props: {
    data: {
      type: Object as PropType<Record<string, any>>,
      default: () => ({}),
    },
  },
  data() {
    return {
      columns: <Column[]>[
        {
          label: this.$t('Account'),
          prop: 'account',
          minWidth: 60,
          maxWidth: 80,
          component: 'AccountLink',
        },
        {
          label: this.$t('Description'),
          prop: 'account.description',
          minWidth: 100,
          maxWidth: 320,
        },
        {
          label: this.$t('Type'),
          prop: 'account.type',
          minWidth: 60,
          maxWidth: 100,
          component: 'Status',
        },
        {
          label: this.$t('Beginning <br> Balance'),
          prop: 'beginning_balance_amount',
          component: 'FormattedPrice',
          align: 'right',
          minWidth: 100,
          maxWidth: 200,
          hideZero: true,
          summary: () => {
            return this.$formatPrice(this.grandTotals.beginning_balance_amount)
          },
        },
        {
          label: this.$t('Debit <br> Amount'),
          prop: 'period_change.debit_amount',
          component: 'FormattedPrice',
          align: 'right',
          minWidth: 100,
          maxWidth: 200,
          hideZero: true,
          summary: () => {
            return this.$formatPrice(this.grandTotals.period_change?.debit_amount)
          },
        },
        {
          label: this.$t('Credit <br> Amount'),
          prop: 'period_change.credit_amount',
          component: 'FormattedPrice',
          align: 'right',
          minWidth: 100,
          maxWidth: 200,
          hideZero: true,
          summary: () => {
            return this.$formatPrice(this.grandTotals.period_change?.credit_amount)
          },
        },
        {
          label: this.$t('Ending <br> Balance'),
          prop: 'ending_balance_amount',
          component: 'FormattedPrice',
          align: 'right',
          minWidth: 100,
          maxWidth: 200,
          hideZero: true,
          summary: () => {
            return this.$formatPrice(this.grandTotals.ending_balance_amount)
          },
        },
      ],
    }
  },
  methods: {
    composeRows(data: Record<string, any>) {
      this.rows = this.data.items
      const htmlData = cloneDeep(this.data.summary)
      delete htmlData.recomputed_retained_earnings
      //@ts-ignore
      this.rows.push({htmlData})
    },
  },
})
</script>
<style lang="scss">
.account-summaries {
  > td {
    border: none;
  }
}
</style>
