<template>
  <div class="col-span-6">
    <div class="flex items-end">
      <CustomerSelect v-model="customer_id"
                      :url-params="urlParams"
                      :show-open-amount="true"
                      :add-entity="false"
                      id="customer"
                      class="w-full"
                      clearable
                      inline-errors
                      @on-map-entry="onChangeCustomer"
      />
      <base-tooltip v-if="customer_id"
                    :tabindex="-1"
                    :content="$t('Edit Customer')">
        <base-button variant="white"
                     :tabindex="-1"
                     class="ml-2"
                     @click="showEditDialog = true">
          <Edit3Icon class="w-5 h-5 text-primary-500"/>
        </base-button>
      </base-tooltip>
    </div>
    <BaseFormDialog v-if="showEditDialog"
                    :title="$t('Edit Customer')"
                    size="xl"
                    :open.sync="showEditDialog">
      <CustomerForm :redirect-to-list-after-store="false"
                    :show-back="false"
                    :data="selectedCustomer"
                    @refresh="onUpdateCustomer"
                    @cancel="showEditDialog = false"
      />
    </BaseFormDialog>
  </div>
</template>
<script>

  import { Edit3Icon } from 'vue-feather-icons'
  import CustomerForm from "@/modules/accounts-receivable/components/CustomerForm";
  import CustomerSelect from "@/components/select/entities/CustomerSelect";

  export default {
    components: {
      Edit3Icon,
      CustomerForm,
      CustomerSelect,
    },
    props: {
      value: {
        type: [String, Number]
      }
    },
    data() {
      return {
        urlParams: {
          sort: '-current_ar_amount',
          related: 'addresses',
        },
        showEditDialog: false,
        customer_id: null,
        selectedCustomer: {},
      }
    },
    methods: {
      onChangeCustomer(customer) {
        this.selectedCustomer = customer
        this.$emit('change', customer)
      },
      onUpdateCustomer(customer) {
        this.onChangeCustomer(customer)
        this.showEditDialog = false
      },
    },
    watch: {
      value: {
        immediate: true,
        handler(value) {
          this.customer_id = value
        }
      }
    }
  }
</script>
