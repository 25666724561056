<template>
  <div :key="get(currentResource, 'id', 'purchase-order-adjustment')">
    <portal to="page-title">
      {{ $t('PO Adjustment for PO') }} #{{ purchaseOrder?.attributes?.number }},&nbsp;@
      <router-link :to="`/accounts-payable/vendors/${vendor.id}/invoices`">
        {{ vendor.code }}
        <span v-if="vendor.name">({{ vendor.name }})</span>
      </router-link>

    </portal>

    <div class="flex justify-between items-center">
      <layout-tabs :items="filteredItems"/>
      <div class="flex items-center">
        <PostAction
          v-if="$isAuthorized('authorizedToPost', currentResource)"
          :id="currentResource.id"
          :is-accounting="false"
          :has-custom-message="true"
          :actionUrl="postUrl"
          entity-name="Purchase Order Adjustment"
          resource-name="purchase-order-adjustments"
          @on-action-callback="afterPost"
        >
          <template #custom-message>
            <span>{{ $t(`The following action will post and modify P.O #${purchaseOrder?.attributes?.number}. Are you sure?`) }}</span>
          </template>
        </PostAction>
      </div>
    </div>

    <portal to="entity-name">
      {{ $t('Purchase Order Adjustment for PO') }} #{{ purchaseOrder?.attributes?.number }}
      ({{ get(vendor, 'name', '') }})
    </portal>
    <router-view/>
  </div>
</template>
<script>
import layoutPermissions from '@/mixins/layoutPermissions'

export default {
  mixins: [layoutPermissions],
  computed: {
    items() {
      return [
        {
          name: this.$t('Details'),
          path: `/purchasing-inventory/purchase-orders-adjustments/${this.$route.params.id}/view`,
          authorizedTo: 'authorizedToShow',
        },
        {
          name: this.$t('Edit'),
          path: `/purchasing-inventory/purchase-orders-adjustments/${this.$route.params.id}/edit`,
          authorizedTo: 'authorizedToPost',
        },
      ]
    },
    postUrl() {
      return `/restify/purchase-order-adjustments/${this.$route.params.id}/actions?action=post-purchase-order-adjustment`
    },
    currentResource() {
      return this.$store.state.inventoryManagement.currentPurchaseOrderAdjustment
    },
    purchaseOrder() {
      return this.currentResource?.relationships?.purchaseOrder
    },
    vendorId() {
      return this.purchaseOrder?.attributes?.vendor_id
    },
    vendor() {
      return this.$store.getters['globalLists/getResourceById'](this.$globalResources.Vendors, this.vendorId)
    },
    isPosted() {
      return this.currentResource?.attributes?.posted_at !== null
    },
    pageTitle() {
      let baseTitle = `${this.$t('PO Adjustment')} #${this.purchaseOrder?.attributes?.number} @ ${this.vendor?.code || ''}`
      let vendorName = this.vendor?.name
      if (vendorName) {
        baseTitle += ` (${vendorName})`
      }
      return baseTitle
    }
  },
  methods: {
    async afterPost(id) {
      await this.getCurrentResource(id)
      await this.$router.push(`/purchasing-inventory/purchase-orders-adjustments/${id}/view`)
    },
    async getCurrentResource(id) {
      await this.$store.dispatch('inventoryManagement/getPurchaseOrderAdjustment', id)
    },
  },
}
</script>
