<template>
  <BaseCard class="border">
    <div class="text-sm flex flex-col space-y-4">
      <div :title="$t('Address')"
           class="detail-row">
        <MapPinIcon class="detail-icon"/>
        <BaseAddressLink :value="props.job?.relationships?.address"/>
      </div>
      <div :title="$t('Customer')"
           class="detail-row">
        <UserIcon class="detail-icon"/>
        <CustomerLink v-if="jobData.customer_id" :id="jobData.customer_id"/>
        <div v-else>- -</div>
      </div>
      <div :title="$t('Owner')"
           class="detail-row">
        <UserCheckIcon class="detail-icon"/>
        <div>{{jobData.owner_name || '- -'}}</div>
      </div>
    </div>
    <div class="flex space-x-4 border-t border-gray-100 mt-4 -mb-4"
    >
      <AddressButton :value="location"/>
    </div>
  </BaseCard>
</template>
<script lang="ts" setup>
  import { MapPinIcon, UserCheckIcon, UserIcon } from 'vue-feather-icons'
  import { computed, PropType } from "vue";
  import { Job } from "@/modules/common/types/models";
  import { locationLink } from "@/utils/utils";
  import AddressButton from "@/modules/common/components/buttons/AddressButton.vue";
  import Data = API.Data;

  const props = defineProps({
    job: {
      type: Object as PropType<Data<Job>>,
      default: () => ({}),
      required: true,
    }
  })

  const jobData = computed<Job>(() => {
    return props.job?.attributes || {}
  })

  const location = computed(() => {
    return locationLink(props.job?.relationships?.address)
  })
</script>
