<template>
  <div class="invoice-batches">
    <div class="mt-8">
      <BaseDataTable :columns="columns"
                     actions="delete"
                     url="/restify/service-billing-invoices/batches"
                     :add-default-columns="false"
                     :data-loading="loading"
                     :delete-action="deleteAction"
                     :on-delete-confirm-message="$t('Are you sure you want to delete this Customer Invoices Batch? Any previously sent email links to customers will no longer work. This action cannot be undone.')"
                     ref="invitesTable">
        <template v-slot:date="{row}">
          <router-link :to="`/service-billing/service-billing-invoice-batches/${row.date}`">
            {{$formatDate(row.date)}}
          </router-link>
        </template>
        <template v-slot:download_percent="{row}">
          {{$getPercent(+row.downloaded_count, +row.sent_count)}}
        </template>
        <template v-slot:extra-actions-before="{row}">
          <table-view-button @click="seeMoreClick(row)"/>
        </template>
      </BaseDataTable>
    </div>
  </div>
</template>
<script>
  import axios from "axios";
  import TableViewButton from "@/components/table/actions/TableViewButton";

  export default {
  components: {
    TableViewButton
  },
  data() {
    return {
      batches: [],
      loading: false,
      columns: [
        {
          label: this.$t('Created At'),
          prop: 'date',
          maxWidth: 200,
          component: 'FormattedDate',
        },
        {
          label: this.$t('Invoice Count'),
          prop: 'count',
          maxWidth: 100,
          align: 'center',
          component: 'Count',
        },
        {
          label: this.$t('Sent Count'),
          prop: 'sent_count',
          maxWidth: 100,
          align: 'center',
          component: 'Count',
        },
        {
          label: this.$t('Sent Times'),
          prop: 'sent_times',
          maxWidth: 100,
          align: 'center',
          component: 'Count',
        },
        {
          label: this.$t('Downloaded'),
          prop: 'downloaded_count',
          maxWidth: 100,
          align: 'center',
          component: 'Count',
        },
        {
          label: this.$t('Downloaded %'),
          prop: 'download_percent',
          maxWidth: 140,
          align: 'center',
        },
      ]
    }
  },
  methods: {
    seeMoreClick(row) {
      this.$router.push(`/service-billing/service-billing-invoice-batches/${row.date}`)
    },
    async deleteAction(row, index) {
      try {
        this.loading = true
        await axios.delete(`/restify/service-billing-invoices/batches/${row.date}`)
        this.$success(`Customer invoice batches for date #${row.date} deleted.`)
        this.$refs.invitesTable.tableData.splice(index, 1)
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(`Could not delete customer invoice batches for date #${row.attributes.date}.`)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
