<template>
  <DebugActionsDropdown
    :items="debugActions"
  />
</template>
<script>
import DebugActionsDropdown from "@/components/dropdowns/DebugActionsDropdown.vue";
import axios from "axios";

export default {
  components: {
    DebugActionsDropdown
  },
  props: {
    id: String,
  },
  computed: {
    debugActions() {
      return [
        {
          name: this.$t('Validate Period Amounts'),
          action: async () => {
            await axios.post(`/restify/accounts/${this.id}/actions?action=validate-account-period-amounts`)
          }
        }
      ]
    }
  }
}
</script>
