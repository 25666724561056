import { costCenterTypes } from "@/components/grid-table/utils/cost-center";

export function isRowValid(row) {
  if (!row) {
    return false
  }
  let requiredFields = ['vendor_id', 'bank_id', 'date', 'cost_center', 'account']
  let requiredFieldMapping = {
    [costCenterTypes.GeneralAndAdministrative]: [],
    [costCenterTypes.Job]: ['source_id', 'addl_source_id'],
    [costCenterTypes.Equipment]: ['source_id'],
    [costCenterTypes.WorkOrder]: ['source_id'],
    default: [],
  }

  const { cost_center } = row
  const extraRequiredFields = requiredFieldMapping[cost_center] || requiredFieldMapping.default

  let allRequiredFields = [...requiredFields, ...extraRequiredFields]

  let requiredFieldsInvalid = allRequiredFields.some(field => !row[field])

  return requiredFieldsInvalid === false
}
