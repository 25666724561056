<template>
  <div class="print:hidden">
    <base-tooltip :content="$t('Open Choose Preset Dialog')">
      <base-button
          variant="white"
          class="ml-2"
          size="sm"
          @click="triggerShowChoosePresetDialog"
      >
        <IconPreset class="w-5 h-5 mr-2"/>
        {{ $t('Choose Preset') }}
      </base-button>
    </base-tooltip>
    <BaseFormDialog
        v-if="showChoosePresetDialog"
        :title="$t('Choose Preset')"
        :open.sync="showChoosePresetDialog"
        :append-to-body="true"
        size="sm"
    >
      <base-form
          :save-text="selectedPreset ? $t('Use preset') : $t('Use without preset')"
          layout="vertical"
          @submit="onApply"
      >
        <div v-loading="loading"
             class="col-span-6 flex flex-col">
          <div v-if="availablePresets.length === 0"
               class="text-center font-medium text-gray-800"
          >
            {{ $t('There are no presets saved yet.') }}
          </div>
          <div v-for="preset in availablePresets"
               :key="preset.id"
               class="border rounded my-3 px-4 py-2"
               :class="getStyles(preset)">
            <div class="flex items-center cursor-pointer"
                 @click="onChoose(preset)">
              <base-tooltip :content="$t('Select Preset')">
                <div class="flex mr-4 w-5 h-5 border-2 rounded"
                     :class="getStyles(preset)">
                  <div :class="getClass(preset)"></div>
                </div>
              </base-tooltip>
              <div class="flex w-full flex-row justify-between items-center">
                <div class="flex flex-col max-w-xs">
                  <p class="truncate">{{ preset.attributes.name }}</p>
                  <p class="text-xs text-gray-500">{{ addedBy(preset) }}</p>
                </div>
                <div v-if="canDelete(preset)" class="justify-end flex items-center">
                  <base-tooltip
                      :content="preset.attributes.public ? $t('This preset is public') : $t('This preset is private')">
                    <div class="mr-2">
                      <lock-icon v-if="!preset.attributes.public" class="w-4 text-gray-600"/>
                      <unlock-icon v-if="preset.attributes.public" class="w-4 text-gray-600"/>
                    </div>
                  </base-tooltip>
                  <base-tooltip :content="$t('Delete Preset')">
                    <trash2-icon class="w-4 text-red-600 cursor-pointer"
                                 @click.stop="deletePreset(preset)"/>
                  </base-tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template #extra-buttons-left>
          <el-upload
              ref="upload"
              action="default"
              accept=".json"
              :auto-upload="false"
              :on-change="onPresetFileChange"
              :limit="1"
          >
            <base-button class="select-file-button focus:outline-none focus:ring-blue"
                         ref="uploadButton"
                         variant="white">
              {{ $t('Import preset') }}
            </base-button>
          </el-upload>
        </template>
      </base-form>
    </BaseFormDialog>
  </div>
</template>
<script lang="ts">
  import axios from 'axios'
  import { defineComponent } from 'vue'
  import { LockIcon, Trash2Icon, UnlockIcon } from 'vue-feather-icons'
  import { Upload } from 'element-ui'
  import { ReportPreset } from "@/modules/common/types/models";
  import { ReportPresetJson } from "@/modules/common/components/reports/presetTypes";
  import Data = API.Data;

  export default defineComponent({
    components: {
      Trash2Icon,
      LockIcon,
      UnlockIcon,
      [Upload.name]: Upload,
    },
    props: {
      reportType: {
        type: [String, Number],
        default: '',
      },
    },
    data() {
      return {
        selectedPreset: null as Data<any> | null,
        loading: false,
        availablePresets: [],
        showChoosePresetDialog: false,
      }
    },
    methods: {
      triggerShowChoosePresetDialog() {
        this.loading = true
        this.getPresets()
        this.showChoosePresetDialog = !this.showChoosePresetDialog
      },
      onPresetFileChange(file: any) {
        const reader = new FileReader()
        reader.onload = (e: any) => {
          try {
            const preset = JSON.parse(e.target.result)
            this.saveImportedPreset(preset)
            this.$emit('preset-changed', preset)
          } catch (err) {
            this.$error(this.$t(`Could not parse preset file. Please make sure it's a valid preset JSON file.`))
          }
        }
        reader.readAsText(file.raw)
      },
      async saveImportedPreset(payload: ReportPresetJson) {
        try {
          const response: { data: Data<ReportPreset> } = await axios.post('/restify/report-presets', payload)
          this.onChoose(response.data)
          await this.getPresets()
          const uploadRef = this.$refs.upload as any
          uploadRef?.clearFiles()
        } catch (err: any) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not save the preset.'))
        }
      },
      async getPresets() {
        try {
          this.loading = true
          const { data } = await axios.get(`/restify/report-presets`, {
            params: {
              report_type: this.reportType,
              related: 'user',
            },
          })
          this.availablePresets = data
        } catch (err: any) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not load presets.'))
        } finally {
          this.loading = false
        }
      },
      addedBy(preset: Data<ReportPreset>) {
        const userName = preset?.relationships?.user?.attributes?.name || ''
        return `${this.$t('Added by')} ${userName}`
      },
      canDelete(preset: Data<any>) {
        return preset?.meta?.authorizedToDelete && preset.id !== this.selectedPreset?.id || false
      },
      async deletePreset(preset: Data<ReportPreset>) {

        const confirmed = await this.$deleteConfirm({
          title: this.$t('Delete Preset?'),
          description: this.$tc(`Are you sure you want to delete preset - <b> ${preset.attributes.name} <b>?`),
          buttonText: this.$t('Delete'),
        })

        if (!confirmed) {
          return
        }

        this.loading = true
        await axios.delete(`/restify/report-presets/${preset.id}`)
        return this.getPresets()
      },
      onChoose(preset: Data<ReportPreset>) {
        if (preset.id === this.selectedPreset?.id) {
          this.selectedPreset = null
          return
        }
        this.selectedPreset = preset
      },
      resetSelection() {
        this.selectedPreset = null
      },
      getClass(preset: Data<any>) {
        if (!this.selectedPreset?.id) {
          return
        }
        return preset.id === this.selectedPreset?.id ? 'selected-preset' : ''
      },
      getStyles(preset: Data<any>) {
        if (!this.selectedPreset?.id) {
          return
        }
        return preset.id === this.selectedPreset?.id ? 'border-primary-400 bg-primary-50' : ''
      },
      onApply() {
        this.$emit('on-apply', this.selectedPreset)
        this.showChoosePresetDialog = false
      },
    },
  })
</script>
<style lang="scss">
  .selected-preset {
    @apply border-b-2 border-r-2 border-primary-400 h-3;
    transform: rotate(45deg);
    margin-left: 5px;
    margin-top: 1px;
    width: 6px;
  }
</style>
