<template>
  <BaseDataTable
      :columns="columns"
      :data="data"
      :can-manage-columns="false"
      :show-actions-column="false"
      :compact="true"
      :show-pagination="false"
  >
    <template #attributes.source_id="{row}">
      <SourceLink :id="row.attributes.source_id" :data="row"/>
    </template>
    <template #attributes.addl_source_id="{row}">
      <AddlSourceLink :id="row.attributes.addl_source_id" :data="row" />
    </template>
    <template #attributes.special_source_id="{row}">
      <SpecialSourceLink :id="row.attributes.special_source_id" :data="row"/>
    </template>
  </BaseDataTable>
</template>
<script>
  import SourceLink from "@/components/links/SourceLink";
  import SpecialSourceLink from "@/components/links/SpecialSourceLink";
  import AddlSourceLink from "@/components/links/AddlSourceLink";
  import EmployeeLink from "@/components/links/EmployeeLink";

  export default {
    components: {
      SourceLink,
      AddlSourceLink,
      SpecialSourceLink,
      EmployeeLink,
    },
    props: {
      data: {
        type: Array,
        default: () => []
      },
      showSource: {
        type: Boolean
      }
    },
    computed: {
      columns() {
        const columns = [
          {
            label: this.$t('Date'),
            prop: 'attributes.date',
            align: 'center',
            maxWidth: '60px',
            component: 'FormattedDate',
            params: {
              format: 'ccc do'
            }
          },
          {
            label: this.$t('Cost \nCenter'),
            prop: 'attributes.cost_center',
            align: 'center',
            maxWidth: '70px',
            renderIf: () => this.showSource,
          },
          {
            label: this.$t('Source'),
            prop: 'attributes.source_id',
            align: 'center',
            maxWidth: '140px',
            renderIf: () => this.showSource,
          },
          {
            label: this.$t('Line Item'),
            prop: 'attributes.addl_source_id',
            align: 'center',
            maxWidth: '100px',
          },
          {
            label: this.$t('Regular Hours'),
            prop: 'attributes.regular_hours',
            align: 'center',
            classes: 'border-l',
            maxWidth: '60px',
          },
          {
            label: this.$t('Premium Hours'),
            prop: 'attributes.overtime_hours',
            align: 'center',
            classes: 'border-l',
            minWidth: '30px',
            maxWidth: '60px',
          },
          {
            label: this.$t('Overtime Hours'),
            prop: 'attributes.premium_hours',
            align: 'center',
            minWidth: '30px',
            maxWidth: '60px',
          },
          {
            label: this.$t('Code'),
            prop: 'attributes.special_source_id',
            align: 'center',
            maxWidth: '100px',
          },
          {
            label: this.$t('Units'),
            prop: 'attributes.units',
            align: 'center',
            minWidth: '30px',
            maxWidth: '40px',
          },
        ]
        return columns.filter(c => c.renderIf ? c.renderIf() : true);
      }
    },
  }
</script>
<style>
</style>
