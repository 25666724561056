<template>
    <div class="bg-white overflow-hidden shadow rounded-lg px-4 py-5 sm:p-6">
        <div>
            <h4 class="font-medium">
                {{$t('Layout color')}}
            </h4>
            <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                {{$t('This will change the sidebar color and the look and feel of it.')}}
            </p>
        </div>
        <div class="flex mt-4">
            <div v-for="theme in themes" :key="theme.value"
                 :tabindex="0"
                 class="rounded-lg w-16 h-16 border-2 border-transparent mr-4 shadow cursor-pointer hover:shadow-lg focus:ring focus:outline-none"
                 :class="{
                    'ring shadow': theme.value === selectedTheme,
                    'bg-gray-800': theme.value === 'dark',
                    'bg-white': theme.value === 'white',
                    'bg-primary-600': theme.value === 'primary',
                 }"
                 @keydown.enter="changeTheme(theme.value)"
                 @click="changeTheme(theme.value)"
            >
            </div>
        </div>
        <div class="mt-10">
            <h4 class="font-medium">
                {{$t('Primary color')}}
            </h4>
            <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                {{$t('This will change the accent color across the application such as buttons, links, icons etc.')}}
            </p>
        </div>
        <div class="flex mt-4">
            <div v-for="color in colors" :key="color"
                 :tabindex="0"
                 class="rounded-lg w-16 h-16 border-2 border-transparent mr-4 shadow cursor-pointer hover:shadow-lg focus:ring focus:outline-none"
                 :class="[
                    {
                      'bg-green-600': color === 'green',
                      'bg-teal-600': color === 'teal',
                      'bg-sky-600': color === 'sky',
                      'bg-blue-600': color === 'blue',
                      'bg-indigo-600': color === 'indigo',
                      'bg-purple-600': color === 'purple',
                      'bg-fuchsia-600': color === 'fuchsia',
                      'bg-gray-600': color === 'gray',
                      'ring shadow': color === selectedColor
                    }
                  ]"
                 @keydown.enter="changePrimaryColor(color)"
                 @click="changePrimaryColor(color)"
            >
            </div>
        </div>
        <div class="mt-10">
            <h4 class="font-medium">
              {{ $t('Font Size') }}
            </h4>
            <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
              {{ $t('Change the base font size to adjust for your own visibility preferences.') }}
            </p>
        </div>
        <div class="max-w-lg mt-4 ml-2">
            <el-slider v-model="baseFontSize"
                       @input="onFontSizeChange"
                       :format-tooltip="val => `${val}px`"
                       :min="12"
                       :max="20"
                       :step="2"
                       :marks="fontMarks"
            >
            </el-slider>
        </div>
    </div>
</template>
<script>
  import { Slider } from 'element-ui'

  export default {
    components: {
      [Slider.name]: Slider,
    },
    data() {
      return {
        themes: [
          {
            name: 'Dark',
            value: 'dark'
          },
          {
            name: 'Light',
            value: 'white'
          },
          {
            name: 'Based on primary color',
            value: 'primary'
          }
        ],
        colors: ['green', 'teal', 'sky', 'blue', 'indigo', 'purple', 'fuchsia', 'gray'],
        baseFontSize: 16 * this.$store.state.theme.fontScaleFactor,
        fontMarks: {
          12: '12px',
          14: '14px',
          16: '16px',
          18: '18px',
          20: '20px',
        }
      }
    },
    computed: {
      selectedTheme() {
        return this.$store.state.theme.theme
      },
      selectedColor() {
        return this.$store.state.theme.color
      }
    },
    methods: {
      changeTheme(theme) {
        this.$store.dispatch('theme/switchTheme', {
          theme,
        })
      },
      changePrimaryColor(color) {
        this.$store.dispatch('theme/switchTheme', {
          color,
        })
      },
      onFontSizeChange(value) {
        const scaleMapping = {
          12: 0.75,
          14: 0.875,
          18: 1.125,
          20: 1.25,
        }
        const fontSizeScale = scaleMapping[value] || 1
        this.$store.dispatch('theme/switchTheme', {
          fontSizeScale,
        })
      }
    }
  }
</script>
<style>
</style>
