<template>
  <AddlSourceLink
      :id="sourceId"
      :data="data"
      :target="params.target"
      :show-description="params.showDescription"
      :is-link="!editableCell"
  />
</template>
<script>
  import AddlSourceLink from "@/components/links/AddlSourceLink";

  export default {
    components: {
      AddlSourceLink,
    },
    props: {
      column: {
        type: Object,
        default: () => ({}),
      },
      row: {
        type: Object,
        default: () => ({}),
      },
      params: {
        type: Object,
        default: () => ({}),
      },
      editableCell: Boolean,
    },
    computed: {
      sourceId() {
        return this.get(this.row, 'attributes.addl_source_id') || this.get(this.row, 'addl_source_id')
      },
      data() {
        if (this.params?.line_item) {
          const line_item = this.params?.line_item || {}
          return {
            cost_center: this.params.data?.entry?.cost_center,
            addl_source_id: line_item.id,
            line_item,
          }
        }
        return this.params?.line_item || this.params?.data || this.row
      }
    }
  }
</script>
