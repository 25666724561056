<template>
  <AgDataTable
      v-bind="gridSettings"
      ref="gridTable"
      actions="add,search,refresh"
      @grid-ready="grid = $event"
  />
</template>
<script lang="ts">
  import Data = API.Data;
  import { defineComponent } from 'vue'
  import { Column } from '@/components/ag-grid/tableTypes'
  import { GridApi, GridReadyEvent } from '@ag-grid-community/core'
  import { cellClasses, getCellClasses } from '@/components/ag-grid/columnUtils'
  import { editableTableProps, storeBatchEntriesProgress, validateAgDataTable } from '@/components/ag-grid/tableUtils'
  import { accountCellProps, subAccountCellProps } from '@/components/ag-grid/columns/editableColumns'
  import { getDeleteColumn } from "@/components/ag-grid/columns/deleteColumns";
  import { costTypes } from '@/enum/enums'

  export default defineComponent({
    data() {
      return {
        grid: null as GridReadyEvent | null,
      }
    },
    computed: {
      gridSettings() {
        const urlParams = {
          sort: 'index',
        }
        return {
          ...editableTableProps,
          url: '/restify/equipment-types',
          urlParams,
          addText: this.$t('New cost type'),
          getEmptyRow: (): INV.EquipmentTypeModel => {
            return {
              name: null,
              abbr: null,
              type: costTypes.Cost,
              debit_account: null,
              debit_subaccount: null,
              credit_account: null,
              credit_subaccount: null,
              readonly: false,
              _localId: crypto.randomUUID(),
            }
          },
          transformData: (data: Data<any>[]) => {
            return data.map(row => {
              return {
                id: row.id,
                ...row.attributes,
                meta: row.meta,
              }
            })
          },
          columns: [
            {
              headerName: this.$t('Name'),
              field: 'name',
              editable: true,
            },
            {
              headerName: this.$t('Abbr'),
              field: 'abbr',
              className: 'p-x-3 htCenter',
              editable: params => {
                const readonly = this.get(params, 'data.readonly', false)
                return !readonly
              },
              cellClass: params => {
                const readonly = this.get(params, 'data.readonly', false)
                return readonly ? cellClasses.ReadOnly : getCellClasses(params)
              },
              align: 'center',
              minWidth: 120,
              maxWidth: 220,
            },
            {
              headerName: this.$t('Debit Account'),
              field: 'debit_account',
              ...accountCellProps,
              cellClass: getCellClasses,
              minWidth: 200,
              maxWidth: 300,
            },
            {
              headerName: this.$t('Sub'),
              field: 'debit_subaccount',
              ...subAccountCellProps,
              suppressKeyboardEvent: params => {
                let isTabKey = params.event.key === 'Tab'
                if (isTabKey) {
                  params.api.stopEditing()
                }
              },
              editable: true,
              minWidth: 200,
              maxWidth: 300,
            },
            {
              ...getDeleteColumn({
                url: '/restify/equipment-types',
              } as any),
            },
          ] as Column[]
        }
      },
    },
    methods: {
      async storeProgress() {
        const gridApi = this?.grid?.api as GridApi
        await storeBatchEntriesProgress(gridApi, 'equipment-types')
        this.refreshData()
      },
      refreshData() {
        // @ts-ignore
        this.$refs.gridTable?.refresh()
      },
      async save() {
        const isInvalidData = await validateAgDataTable()
        if (!isInvalidData) {
          this.storeProgress()
        }

        return !isInvalidData
      },
    },
  })
</script>
