<template>
  <div>
    <base-go-to-edit-alert
        v-if="!!existingTimeOffRateId"
        :path="`/payroll/rates/time-off-rates/${existingTimeOffRateId}/edit`"
        message="Rates for selected time off policy are defined. Please select another time off policy, or edit."
    />
    <base-form :show-back="true"
               :loading="loading"
               :show-cancel="true"
               :focus-on-first-input="!data.id"
               :save-text="$t('Add time off rates')"
               :update-text="$t('Update time off rates')"
               :submit-disabled="!!existingTimeOffRateId"
               ref="baseForm"
               layout="vertical"
               grid-classes="grid grid-cols-3 gap-x-3"
               submit-button-type="button"
               @submit="onSubmit"
               @cancel="$router.push('/payroll/rates/time-off-rates')"
    >
      <div class="col-span-3 lg:col-span-1 flex items-center w-full">
        <time-off-policy-select
          v-model="model.id"
          :disabled="!!data.id"
          :label="$t('Time Off Policy')"
          :name="$t('Time Off Policy')"
          :initial-value="data"
          id="time_off_policy"
          class="flex-1"
          rules="required"
          @change="validateSelection"
        />
      </div>
      <TimeOffRateEntries
          :data="data"
          ref="entriesTable"
          class="col-span-3"
      />
    </base-form>
  </div>
</template>
<script>
  import axios from 'axios'
  import TimeOffRateEntries from '@/modules/payroll/components/rates/TimeOffRateEntries'

  export default {
    inheritAttrs: false,
    components: {
      TimeOffRateEntries,
    },
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        loading: false,
        model: {
          id: '',
        },
        existingTimeOffRateId: false,
      }
    },
    methods: {
      async validateSelection(timeOffPolicyId) {
        try {
          this.loading = this.existingTimeOffRateId = false
          const { data } = await axios.get(`/restify/time-off-rates?time_off_policy_id=${timeOffPolicyId}`)

          this.existingTimeOffRateId = this.get(data, '[0][attributes][time_off_policy_id]', false)
        } catch (err) {
          console.warn(err)
        } finally {
          this.loading = false
        }
      },
      async onSubmit() {
        try {
          this.loading = true
          if (this.data.id) {
            await this.$refs.entriesTable.storeProgress(this.model.id)
            this.$success(this.$t('Time off rates successfully managed.'))
          } else {
            await this.$refs.entriesTable.storeProgress(this.model.id)
            this.$success(this.$t('Time off rates successfully managed.'))
            await this.$router.push(`/payroll/rates/time-off-rates/${this.model.id}/edit`)
          }
        } catch (err) {
          console.warn(err)
          this.$error(this.$t('Something went wrong. Please try again.'))
        } finally {
          this.loading = false
        }
      },
    },
    watch: {
      data: {
        immediate: true,
        handler(value) {
          if (!value?.id) {
            return
          }
          this.model = {
            ...this.model,
            ...value.attributes,
          }
        },
      },
    },
  }
</script>
