<template>
  <div>
    <portal to="page-title">
      <div class="flex items-center justify-between md:justify-start">
        <span class="truncate">
          {{ currentResource.attributes.name }}
          <template v-if="currentResource.attributes.code">
            ({{ currentResource.attributes.code }})
          </template>
        </span>
        <span class="mx-1 md:mx-2 flex items-center">
          <status-badge :status="currentResource.attributes.active ? $t('active'): $t('inactive')"/>
        </span>
      </div>
    </portal>
    <layout-tabs :items="filteredItems"/>
    <router-view/>
  </div>
</template>
<script>
  import layoutPermissions from '@/mixins/layoutPermissions'

  export default {
    mixins: [layoutPermissions],
    computed: {
      items() {
        const id = this.$route.params.id
        return [
          {
            name: this.$t('Details'),
            path: `/settings/companies/${id}/view`,
            authorizedTo: 'authorizedToShow',
          },
          {
            name: this.$t('Edit'),
            path: `/settings/companies/${id}/edit`,
            authorizedTo: 'authorizedToUpdate',
          },
          {
            name: this.$t('Documents'),
            path: `/settings/companies/${id}/documents`,
            authorizedTo: 'authorizedToUpdate',
          },
          {
            name: this.$t('State Tax Jurisdictions'),
            path: `/settings/companies/${id}/state-tax-jurisdictions`,
            authorizedTo: 'authorizedToUpdate',
            enabled: () => this.$currentCompany.id === id
          },
        ]
      },
      currentResource() {
        return this.$store.state.company.currentCompany || {}
      },
    },
    methods: {
      async getCurrentResource(id) {
        await this.$store.dispatch('company/getCompany', id)
      }
    },
  }
</script>
