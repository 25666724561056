<template>
  <div>
    <div class="flex items-center justify-between">
      <nav class="flex"
           aria-label="Tabs">
        <div v-for="(type, index) of types"
             :class="{'text-primary-900 bg-primary-200': index === selectedTypeIndex}"
             role="button"
             class="text-gray-600 hover:text-gray-800 px-3 py-2 font-medium text-sm rounded-md"
             @click="selectedTypeIndex = index"
        >
          {{ formattedText(type) }}
        </div>
      </nav>
      <div>
        <base-tooltip :content="$t('Refresh data')">
          <base-button
              :loading="loading"
              variant="white"
              class="mx-2"
              @click="loadProofList">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"
                 fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                 class="icon-svg feather feather-refresh-cw">
              <polyline points="23 4 23 10 17 10"></polyline>
              <polyline points="1 20 1 14 7 14"></polyline>
              <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path>
            </svg>
          </base-button>
        </base-tooltip>
        <base-button
            :loading="rebuildProgress"
            variant="white"
            @click="onRebuild"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"
               fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
               class="icon-svg feather feather-settings">
            <circle data-v-4c034fe6="" cx="12" cy="12" r="3"/>
            <path
                d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
          </svg>
          <span class="mx-2">{{ $t('Rebuild') }}</span>
        </base-button>
      </div>
    </div>
    <base-data-table
        :data-loading="loading"
        :columns="columns[selectedTypeIndex]"
        :data="proofList[types[selectedTypeIndex]]"
        :show-pagination="false"
        :limit-max-height="false"
        :can-reorder-columns="false"
        class="mt-5"
    />
  </div>
</template>
<script>
  import axios from 'axios'
  import { formattedText } from '@/utils/utils'

  export default {
    data() {
      return {
        columns: {
          0: [
            {
              label: 'Line Item',
              prop: 'code',
              component: 'EntityLink',
              redirectTo: '/job-costing/cost-line-items/{ID}/view',
            },
            {
              label: 'Description',
              prop: 'description',
            },
            {
              label: 'Type',
              prop: 'type',
              component: 'Status',
            },
            {
              label: 'Amount',
              prop: 'amount_to_date',
              component: 'FormattedPrice',
              align: 'right',
            },
            {
              label: 'Retention',
              prop: 'retention_amount',
              component: 'FormattedPrice',
              align: 'right',
            },
          ],
          1: [
            {
              label: 'Code',
              prop: 'code',
              component: 'EntityLink',
              redirectTo: '/accounts-payable/vendors/{ID}/view',
            },
            {
              label: 'Name',
              prop: 'name',
            },
            {
              label: 'Amount',
              prop: 'current_ap_amount',
              component: 'FormattedPrice',
              align: 'right',
            },
            {
              label: 'Payment',
              prop: 'payment_to_date',
              component: 'FormattedPrice',
              align: 'right',
            },
            {
              label: 'Retention',
              prop: 'retention_amount',
              component: 'FormattedPrice',
              align: 'right',
            },
          ],
        },
        types: [
          'line_items',
          'vendors',
        ],
        loading: false,
        rebuildProgress: false,
        selectedTypeIndex: 0,
        proofList: {
          line_items: [],
          vendors: [],
        },
      }
    },
    methods: {
      formattedText,
      getLineItem(id) {
        return this.$store.getters['globalLists/getResourceById'](this.$globalResources.LineItems, id)
      },
      getVendor(id) {
        return this.$store.getters['globalLists/getResourceById'](this.$globalResources.Vendors, id)
      },
      async loadProofList() {
        try {
          this.loading = true

          const { data } = await axios.post('/restify/line-items/actions?action=rebuild-jc-amounts', {
            'proof-listing': true,
          })

          const { line_items, vendors } = data.map

          Object.keys(line_items).forEach(lineItemId => {

            let lineItem = line_items[lineItemId]
            const model = {
              ...this.getLineItem(lineItemId),
              ...lineItem[Object.keys(lineItem)[0]],
            }
            this.proofList.line_items.push(model)
          })

          Object.keys(vendors).forEach(vendorId => {

            const vendor = {
              ...this.getVendor(vendorId),
              ...vendors[vendorId],
            }

            this.proofList.vendors.push(vendor)
          })
        } catch (error) {
          console.warn(error)
        } finally {
          this.loading = false
        }
      },
      async onRebuild() {
        try {

          const confirmed = await this.$confirm({
            title: this.$t('Rebuild JC amounts'),
            description: this.$t('This action will override Vendor amounts, Vendor activities, Line Item amounts and Job Budget amounts.'),
            buttonText: this.$t('Rebuild'),
          })

          if (!confirmed) {
            return
          }

          this.rebuildProgress = true

          await axios.post('/restify/line-items/actions?action=rebuild-jc-amounts', {
            'proof-listing': false,
          })

        } catch (error) {
          console.warn(error)
        } finally {
          this.rebuildProgress = false
        }
      },
      async getLists() {
        await this.$store.dispatch('globalLists/getVendors')
        await this.$store.dispatch('globalLists/getLineItemsConfig', true)
      },
    },
    mounted() {
      this.getLists()
    },
  }
</script>
