<template>
  <div class="truncate">
    <router-link
        v-if="stateTax?.id"
        :to="`/payroll/settings/state-taxes?id=${stateTax.id}`"
        :class="$attrs.class"
    >
      <span>
        {{ valueToDisplay }}
      </span>
    </router-link>
    <template v-else>
    </template>
  </div>
</template>
<script lang="ts" setup>
  import { computed } from 'vue'
  import get from 'lodash/get'
  import store from '@/store'
  import { globalResources } from "@/components/form/util";
  import { CraftCode } from "@/modules/common/types/models";

  const props = defineProps({
    data: {
      type: Object,
      default: () => ({}),
    },
    id: {
      type: String,
    },
    showDescription: {
      type: Boolean,
      default: true,
    },
  })

  const stateTaxId = computed(() => {
    return props.id || get(props.data, 'attributes.state_tax_id')
  })

  const stateTaxes = computed(() => {
    return store.getters['globalLists/getResourceList'](globalResources.StateTaxes)
  })

  const stateTax = computed(() => {
    return stateTaxes.value.find((a: any) => a.id === stateTaxId.value) || {}
  })

  const valueToDisplay = computed(() => {
    const { code, description } = stateTax.value
    if (!props.showDescription || !description) {
      return code
    }
    return `${code} (${description})`
  })
</script>
