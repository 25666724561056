<template>
  <div>
      <layout-tabs :items="items"/>
      <router-view/>
  </div>
</template>
<script>

  export default {
    data() {
      return {
        items: [
          {
            name: this.$t('Rebuild JC amounts'),
            path: '/support/rebuild-actions/jc-amounts',
          },
        ],
      }
    },
  }
</script>
