<template>
  <div class="mr-2 cursor-help">
    <base-tooltip :content="message">
      <CheckCircleIcon v-if="isPostable"
                       class="w-5 h-5 text-green-400"/>
      <MinusCircleIcon v-else
                       class="w-5 h-5 text-red-400"/>
    </base-tooltip>
  </div>
</template>
<script>
  import { CheckCircleIcon, MinusCircleIcon } from 'vue-feather-icons'

  export default {
    name: 'postable-tooltip',
    components: {
      CheckCircleIcon,
      MinusCircleIcon,
    },
    props: {
      isPostable: {
        type: Boolean,
        default: false,
      }
    },
    computed: {
      message() {
        if (this.isPostable) {
          return this.$t('Can be posted, is in balance')
        }
        return this.$t('Cannot be posted, is not in balance or is marked as no post.')
      }
    }
  }
</script>
<style>
</style>
