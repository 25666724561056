<template>
  <div class="-mt-4">
    <portal to="page-title">
      &nbsp;
    </portal>
    <BillingsWidget/>
    <PaymentsWidget class="mt-4" :title="$t('AR Collected')"/>

    <NavigationCardList :data="cardItems"/>
  </div>
</template>
<script>
  import get from "lodash/get";
  import NavigationCardList from "@/modules/dashboard/components/NavigationCardList";
  import BillingsWidget from "@/modules/accounts-receivable/pages/dashboard/widgets/BillingsWidget";
  import PaymentsWidget from "@/modules/accounts-receivable/pages/dashboard/widgets/PaymentsWidget";

  export default {
    components: {
      PaymentsWidget,
      BillingsWidget,
      NavigationCardList,
    },
    computed: {
      cardItems() {
        return [
          {
            name: this.$t('Customers'),
            routeName: this.$t('Customers'),
            icon: 'IconCustomers',
            color: 'primary',
            path: '/accounts-receivable/customers',
          },
          {
            name: this.$t('Billings'),
            routeName: this.$t('Job Progress Billings'),
            icon: 'IconInvoices',
            color: 'yellow',
            path: '/accounts-receivable/billings/progress/pending',
          },
          {
            name: this.$t('Payments'),
            routeName: this.$t('Customer Payments'),
            icon: 'IconPayroll',
            color: 'green',
            path: '/accounts-receivable/payments/create',
          },
          {
            name: this.$t('Statements'),
            routeName: this.$t('Statement Batches'),
            icon: 'IconStatements',
            color: 'red',
            path: '/accounts-receivable/statements',
          },
        ]
      },
      filteredCards() {
        return this.cardItems.filter(item => {
          const route = this.findRoute(item.routeName)
          const permissions = get(route, 'meta.permissions', [])
          return this.$can(permissions);
        })
      },
    },
  }
</script>
<style>
</style>
