import WikiLayout from "@/modules/wiki/layout/WikiLayout";
import NewWikiPage from "@/modules/wiki/pages/new-wiki-page";
import WikiPageDetails from "@/modules/wiki/pages/wiki-page-details";

export default [
  {
    path: '/wiki',
    name: 'Wiki',
    redirect: '/wiki/new',
    meta: {
      productionReady: true,
    },
    component: WikiLayout,
    children: [
      {
        path: 'new',
        name: 'New Wiki Page',
        title: 'New Wiki Page',
        description: 'New Wiki Page',
        component: NewWikiPage
      },
      {
        path: ':id',
        name: 'Wiki Page Details',
        component: WikiPageDetails
      },
    ]
  }
]
