<template>
  <div>
    <BaseDataTable :columns="columns"
                   :url="url"
                   :url-params="urlParams"
                   :is-expandable="true"
                   :delete-action="deleteJobRates"
                   :add-entity-in-new-tab="true"
                   :default-match-filters="defaultMatchFilters"
                   :add-text="$t('New job rate')"
                   ref="table"
                   view-entity-url-query="/payroll/rates/job-rates"
                   default-sort="-updated_at"
                   actions="search, refresh, view"
                   permission="jobs"
                   @add="$router.push('/payroll/rates/job-rates/add')"
                   @edit="onView"
    >
      <template #job="{row}">
        <JobLink :data="row"/>
      </template>
      <template #expand-content="{row}">
        <UnionAndJobRateEntries
            :data="row"
            :read-only="true"
            api-url="/restify/job-rates"
            ref="entriesTable"
            class="m-4"
        />
      </template>
      <template #customer="{row}">
        <CustomerLink :data="get(row, 'relationships.customer.attributes')"/>
      </template>
    </BaseDataTable>
    <BaseFormDialog v-if="showDialog"
                    :title="$t('Job')"
                    :open.sync="showDialog"
                    size="xl"
                    @close="onEventTrigger">
      <base-detail-card :title="$t('Job Details')"
                        :key="jobToView.id"
                        :entity="jobToView"
                        :can-expand="false"
                        :expanded="true"
                        class="m-4"
                        entity-name="job"
      >
        <base-detail-card-row :title="$t('Job')">
          {{ jobToView.attributes.number }} ({{ jobToView.attributes.description }})
        </base-detail-card-row>
        <base-detail-card-row :title="$t('Rates')"
                              stripe>
          <status-badge :status="Number(jobToView.attributes.rates_count)"/>
        </base-detail-card-row>
      </base-detail-card>
      <base-detail-card :title="$t('Rates')"
                        :entity="jobToView"
                        :can-expand="true"
                        :expanded="true"
                        class="m-4"
                        entity-name="union"
      >
        <UnionAndJobRateEntries
            :data="jobToView"
            :read-only="true"
            api-url="/restify/job-rates"
            ref="entriesTable"
        />
      </base-detail-card>
    </BaseFormDialog>
  </div>
</template>
<script>
  import axios from 'axios'
  import { entityPreviewFields } from '@/modules/common/components/entity-preview/entities'
  import UnionAndJobRateEntries from '@/modules/payroll/components/rates/UnionAndJobRateEntries.vue'
  import { getDefaultMatchFilter } from "@/components/ag-grid/filterUtils";

  export default {
    components: {
      UnionAndJobRateEntries,
    },
    data() {
      return {
        url: '/restify/jobs',
        urlParams: {
          related: entityPreviewFields.Customer,
        },
        addEntityInNewTab: true,
        showDialog: false,
        jobToView: {},
      }
    },
    computed: {
      columns() {
        return [
          {
            label: this.$t('Job'),
            prop: 'job',
          },
          {
            label: this.$t('Customer'),
            prop: 'customer',
            minWidth: 100,
            maxWidth: 200,
          },
          {
            label: this.$t('Rates'),
            prop: 'attributes.rates_count',
            component: 'Count',
          },
          {
            label: this.$t('Status'),
            prop: 'attributes.status',
            minWidth: 100,
            maxWidth: 100,
            component: 'Status',
          },
        ]
      },
      defaultMatchFilters() {
        return getDefaultMatchFilter('show_inactive_jobs', {
          status: 'open',
          having_job_rates: true,
        })
      },
    },
    methods: {
      onView(row) {
        this.showDialog = true
        this.jobToView = row
      },
      onEventTrigger() {
        this.jobToView = null
        this.showDialog = false
        this.refreshTable()
      },
      async deleteJobRates(row) {
        try {
          await axios.post(`/restify/jobs/${row.id}/actions?action=delete-job-rates-from-job`)
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Something went wrong. Please try again.'))
        } finally {
          this.refreshTable()
        }
      },
      refreshTable() {
        this.$refs.table.refresh()
      },
    },
  }
</script>
