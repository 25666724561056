<template>
  <div>
    <div class="tip mb-2">{{ $t('Double click or press Enter on budget cells to edit ') }}</div>
    <AgDataTable
        :columns="jobBudgetColumns"
        :data-loading="budgetsLoading"
        :data="budgets"
        :pagination="false"
        :get-row-id="params => params.data.job_type_id"
        domLayout="autoHeight"
        @cell-focused="onCellFocused"
    >
    </AgDataTable>
  </div>
</template>
<script>
  import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";
  import { costOrIncomeTypes } from "@/components/grid-table/utils/cost-center";
  import { JobTypeFor } from "@/modules/job-costing/enum/jobs";

  export default {
    props: {
      budgets: {
        type: Array,
        default: () => [],
      },
      type: {
        type: String,
      },
      budgetsLoading: Boolean,
    },
    computed: {
      jobBudgetColumns() {
        return [
          {
            headerName: this.$t('Name'),
            field: 'name',
            cellClass: 'bg-gray-100',
            maxWidth: 200,
          },
          {
            headerName: this.$t('Budget $'),
            field: 'amount',
            minWidth: 100,
            editable: params => {
              return params.data.for !== JobTypeFor.Income.UnitPrice
            },
            cellEditor: cellEditors.Numeric,
            cellEditorParams: {
              step: '0.01'
            },
            cellClass: params => {
              if (params.data.for === JobTypeFor.Income.UnitPrice) {
                return 'bg-gray-100'
              }
              return ''
            },
            valueGetter: params => {
              if (!params.data) {
                return
              }
              const { amount, quantity, unit_rate } = params.data
              if (!amount && quantity && unit_rate) {
                return quantity * unit_rate
              }
              return amount
            },
            valueFormatter: params => {
              return this.$formatPrice(params.value)
            },
            valueSetter: params => {
              params.data.amount = +params.newValue
              if (!params.newValue) {
                params.data.amount = 0
              }
              return true
            },
          },
          {
            headerName: this.$t('Quantity'),
            field: 'quantity',
            editable: this.extraColumnsEditable,
            maxWidth: 210,
            cellClass: params => {
              return this.extraColumnsEditable(params) ? '' : 'bg-gray-100'
            },
            valueGetter: params => {
              const editable = this.extraColumnsEditable(params)
              if (editable) {
                return params.data.quantity
              }
              return ''
            },
            valueSetter: params => {
              const quantity = +params.newValue
              if (isNaN(quantity)) {
                return false
              }
              const unitRate = +params.data.unit_rate
              if (unitRate) {
                params.data.amount = +params.data.unit_rate * quantity
              }
              params.data.quantity = quantity
              return true
            }
          },
          {
            headerName: this.$t('Measure'),
            field: 'um',
            editable: params => {
              return [JobTypeFor.Income.UnitPrice, JobTypeFor.Cost.Material].includes(params.data.for)
            },
            maxWidth: 150,
            cellClass: params => {
              const editable = [JobTypeFor.Income.UnitPrice, JobTypeFor.Cost.Material].includes(params.data.for)
              return editable ? '' : 'bg-gray-100'
            },
            valueGetter: params => {
              const editable = [JobTypeFor.Income.UnitPrice, JobTypeFor.Cost.Material].includes(params.data.for)
              if (editable) {
                return params.data.um
              }
              return ''
            },
          },
          {
            headerName: this.$t('Unit Price'),
            field: 'unit_rate',
            editable: params => {
              return params.data.for === JobTypeFor.Income.UnitPrice
            },
            cellEditor: cellEditors.Numeric,
            cellEditorParams: {
              step: '0.01'
            },
            cellClass: params => {
              return params.data.for === JobTypeFor.Income.UnitPrice ? '' : 'bg-gray-100'
            },
            valueSetter: params => {
              const unitRate = +params.newValue
              if (isNaN(unitRate)) {
                return false
              }
              params.data.unit_rate = unitRate
              if (!params.newValue) {
                params.data.unit_rate = 0
              }
              params.data.amount = params.data.unit_rate * +params.data.quantity
              return true
            },
            valueGetter: params => {
              const isUnitPrice = params.data.for === JobTypeFor.Income.UnitPrice
              if (isUnitPrice) {
                const value = params.data.unit_rate || 0
                return this.$formatPrice(value)
              }
              return ''
            },
            hide: this.type === costOrIncomeTypes.Cost,
          },
        ]
      }
    },
    methods: {
      extraColumnsEditable(params) {
        const allowedTypes = [
          JobTypeFor.Income.UnitPrice,
          JobTypeFor.Cost.Labor,
          JobTypeFor.Cost.Equipment,
          JobTypeFor.Cost.Material,
        ]
        return allowedTypes.includes(params.data.for)
      },
      onCellFocused(params) {
        const { rowIndex } = params
        const rowNode = params.api.getDisplayedRowAtIndex(rowIndex)
        const row = rowNode?.data
        if (!row) {
          return
        }
        const columnApi = params.columnApi
        const quantityCol = columnApi.getColumn('quantity')?.colDef
        const measureCol = columnApi.getColumn('um')?.colDef

        if (row.for === JobTypeFor.Cost.Labor) {
          quantityCol.headerName = this.$t('Budget Man Hours')
          measureCol.headerName = ''
        } else if (row.for === JobTypeFor.Cost.Equipment) {
          quantityCol.headerName = this.$t('Budget Equipment Hours')
          measureCol.headerName = ''
        } else if (row.for === JobTypeFor.Cost.Material) {
          quantityCol.headerName = this.$t('Budget Units')
          measureCol.headerName = this.$t('Measure')
        } else if (row.for === JobTypeFor.Income.UnitPrice) {
          quantityCol.headerName = this.$t('Quantity')
          measureCol.headerName = this.$t('Measure')
        } else {
          quantityCol.headerName = ''
          measureCol.headerName = ''
        }
        params.api.refreshHeader()
      }
    }
  }
</script>
