<template>
  <CompanySettingsForm/>
</template>
<script>
  import CompanySettingsForm from '@/modules/settings/components/CompanySettingsForm';

  export default {
    components: {
      CompanySettingsForm,
    },
  }
</script>
