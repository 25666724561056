<template>
  <div>
    <layout-tabs :items="filteredItems"/>
    <router-view/>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        items: [
          {
            name: this.$t('Equipment Management Settings'),
            path: `/equipment/settings/equipment-management-settings`,
            permission: 'equipment_management_settings',
          },
          {
            name: this.$t('Classes'),
            path: '/equipment/settings/classes',
            permission: 'equipment_class_show',
          },
          {
            name: this.$t('Specialty Codes'),
            path: '/equipment/settings/specialty-codes',
            permission: 'equipment_specialties_show',
          },
        ],
      }
    },
    computed: {
      filteredItems() {
        return this.items.filter(item => this.$can(item.permission))
      },
    },
  }
</script>
