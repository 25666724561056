<template>
  <div class="w-full flex justify-center">
    <base-tooltip :content="$t('Add new column')">
      <base-button
          variant="gray-light"
          size="icon"
          @click="showDialog = true"
      >
        <PlusIcon class="w-4 h-4"/>
      </base-button>
    </base-tooltip>

    <AddColDialog
        v-if="showDialog"
        @close="showDialog = false"
        @submit="onSubmit"
    />
  </div>
</template>
<script>
  import { PlusIcon } from 'vue-feather-icons'
  import AddColDialog from "@/modules/common/components/import/AddColDialog.vue";

  export default {
    components: {
      PlusIcon,
      AddColDialog,
    },
    data() {
      return {
        showDialog: false,
        model: {
          name: '',
        }
      }
    },
    methods: {
      onSubmit(name) {
        if (!this.params.addColumn) {
          return
        }
        this.params.addColumn({
          name,
          custom: true,
        })
        this.showDialog = false
      }
    }
  }
</script>

