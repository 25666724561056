<template>
  <base-select v-bind="$attrs"
               v-on="$listeners"
               :options="subTrades"
               :table-columns="tableColumns"
               :label-key="labelKey"
               :value-key="valueKey"
               :allow-create="allowCreate"
  />
</template>
<script>
  export default {
    props: {
      tableColumns: {
        type: Array,
        default: () => ([
          {
            minWidth: 150,
            maxWidth: 300,
            name: 'Description',
            prop: 'description',
          },
        ]),
      },
      labelKey: {
        type: String,
        default: 'description',
      },
      valueKey: {
        type: String,
        default: 'id',
      },
      allowCreate: {
        type: Boolean,
        default: true,
      },
      craftCodeId: {
        type: String
      },
      allowSelectionWithoutCraftCode: {
        type: Boolean,
        default: true,
      }
    },
    computed: {
      subTrades() {
        const data = this.$store.state.globalLists[this.$globalResources.SubTrades]
        if (this.craftCodeId) {
          return data.filter(subTrade => subTrade.craft_code_id === this.craftCodeId)
        }
        if (this.allowSelectionWithoutCraftCode) {
          return data
        }
        return []
      }
    }
  }
</script>
