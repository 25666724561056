<template>
  <base-collapse-form
      :show-buttons="showButtons"
      :expanded.sync="sectionExpanded"
      layout="vertical"
  >
    <template #title>
      <span v-if="!sectionExpanded && selectedCustomer.id">
        {{ selectedCustomer.name }}
        ({{ selectedCustomer.code }})
      </span>
    </template>
    <div class="col-span-6 lg:col-span-2">
      <customer-selector
          v-model="model.customer_id"
          :url-params="urlParams"
          clearable
          class="w-full"
          @change="onChangeCustomer"
      />
    </div>
    <div class="lg:col-span-1"/>
    <customer-info
        v-if="model.customer_id"
        :customer="selectedCustomer"
    />
  </base-collapse-form>
</template>
<script>

  import CustomerSelector from '@/modules/accounts-receivable/components/CustomerSelector'
  import CustomerInfo from '@/modules/accounts-receivable/components/customer-history/CustomerInfo'

  export default {
    components: {
      CustomerInfo,
      CustomerSelector,
    },
    data() {
      return {
        urlParams: {
          sort: 'code',
          related: 'addresses,contacts',
        },
        selectedCustomer: {},
        model: {
          customer_id: null,
        },
        showButtons: false,
        sectionExpanded: true,
      }
    },
    methods: {
      onApplyFiler() {
        const filters = { ...this.model }
        this.$emit('on-filter', filters)
      },
      onChangeCustomer(customer) {
        this.selectedCustomer = {
          ...customer?.attributes,
          relationships: {
            ...customer?.relationships,
          },
        }
        this.model.customer_id = customer?.id
        this.onApplyFiler()
      },
    },
  }
</script>
