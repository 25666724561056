<template>
  <AgDataTable
      v-if="filters"
      ref="table"
      url="/restify/service-billing-histories/cost-for-billing"
      :columns="columns"
      :url-params="filters"
      :groupDefaultExpanded="-1"
      :groupRowRendererParams="groupRowRendererParams"
      :transform-data="transformData"
      v-bind="editableTableProps"
      :authorizeToCopyLastRow="false"
      :show-pagination="true"
      :per-page="100"
      groupDisplayType="groupRows"
      actions="search,refresh"
      @grid-ready="grid = $event"
  >
    <template #header-info>
      <base-checkbox
        v-model="selectAll"
        :label="$t('Select All (Visible on this page)')"
        id="select-all"
        @change="updateAllRows($event)"
      />
    </template>
    <template #overrides="{row}">
      <div class="flex justify-center w-full">
        <TableEditButton :skipFocus="true"/>
      </div>
    </template>
  </AgDataTable>
</template>
<script lang="ts" setup>
import {editableTableProps, getTableData, getTableNodes} from "@/components/ag-grid/tableUtils";
import {computed, ref} from "vue";
import {GridReadyEvent, ValueFormatterParams, ValueSetterParams} from "@ag-grid-community/core";
import {Column} from "@/components/ag-grid/tableTypes";
import i18n from "@/i18n";
import {cellClasses} from "@/components/ag-grid/columnUtils";
import {cellEditors} from "@/components/ag-grid/cellEditors/cellEditors";
import axios from "axios";
import {globalResources} from "@/components/form/util";
import store from "@/store";
import {
  CostForBillingDataEntry
} from "@/modules/service-billing/components/cost-for-billing/models/costForBillingModels";
import {RateFrom} from "@/modules/payroll/utils/timeCardUtils";
import {updateRows} from "@/modules/service-billing/components/cost-for-billing/costForBillingUtils";

const props = defineProps({
  filters: {
    type: Object,
    required: true
  },
  date: {
    type: Date,
  }
})

const SelectStatuses = {
  Selected: 'selected',
  NotSelected: 'not-selected',
  Removed: 'removed',
}
const selectOptions = computed(() => {
  return [
    {
      label: i18n.t('?'),
      value: SelectStatuses.NotSelected,
    },
    {
      label: i18n.t('Select'),
      value: SelectStatuses.Selected,
    },
    {
      label: i18n.t('Remove'),
      value: SelectStatuses.Removed,
    },
  ]
})

const serviceBillingCodes = computed(() => {
  return store.getters['globalLists/getResourceOptions'](globalResources.ServiceBillingCodes)
})
const grid = ref<GridReadyEvent>()
const selectAll = ref(false)

const columns = computed<Column[]>(() => {
  return [
    {
      headerName: i18n.t('Work Order'),
      field: 'selected',
      minWidth: 70,
      maxWidth: 120,
      editable: true,
      cellEditor: cellEditors.BaseSelect,
      cellEditorParams: {
        options: selectOptions.value,
      },
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.data?.selected) {
          return i18n.t('Select')
        }
        if (params.data?.removed) {
          return i18n.t('Remove')
        }
        if (params?.data) {
          return i18n.t('?')
        }
      },
      valueGetter: (params) => {
        if (params.data?.selected) {
          return SelectStatuses.Selected
        }
        if (params.data?.removed) {
          return SelectStatuses.Removed
        }
        return SelectStatuses.NotSelected
      },
      valueSetter: (params: ValueSetterParams) => {
        params.data.selected = params.newValue === SelectStatuses.Selected
        params.data.removed = params.newValue === SelectStatuses.Removed
        onSelectionChange(params, params.newValue)
        return true
      },
    },
    {
      headerName: i18n.t('Work Order'),
      field: 'work_order.id',
      rowGroup: true,
      hide: true,
    },
    {
      headerName: i18n.t('Journal'),
      field: 'journal.code',
      minWidth: 80,
      maxWidth: 150,
      cellClass: cellClasses.ReadOnlyLight,
      suppressNavigable: true,
      valueFormatter: (params) => {
        const {number, code} = params.data?.journal || {}
        return `${code || ''}${number || ''}`
      }
    },
    {
      headerName: i18n.t('Vendor / Employee'),
      field: 'business.code',
      minWidth: 80,
      maxWidth: 150,
      cellClass: cellClasses.ReadOnlyLight,
      suppressNavigable: true,
    },
    {
      headerName: i18n.t('Ref Date'),
      field: 'reference_date',
      minWidth: 80,
      maxWidth: 150,
      cellClass: cellClasses.ReadOnlyLight,
      suppressNavigable: true,
      component: 'FormattedDate',
    },
    {
      headerName: i18n.t('Type'),
      field: 'type.abbr',
      minWidth: 60,
      maxWidth: 90,
      cellClass: cellClasses.ReadOnlyLight,
      suppressNavigable: true,
    },
    {
      headerName: i18n.t('Ref No.'),
      field: 'reference_no',
      minWidth: 80,
      maxWidth: 150,
      cellClass: cellClasses.ReadOnlyLight,
      suppressNavigable: true,
    },
    {
      headerName: i18n.t('Quantity or Hours'),
      field: 'quantity',
      minWidth: 80,
      maxWidth: 120,
      cellClass: cellClasses.ReadOnlyLight,
      align: 'right',
      suppressNavigable: true,
    },
    {
      headerName: i18n.t('Direct Unit Cost'),
      field: 'unit_rate',
      minWidth: 80,
      maxWidth: 120,
      cellClass: cellClasses.ReadOnlyLight,
      suppressNavigable: true,
      align: 'right',
      component: 'FormattedPrice',
      aggFunc: 'sum',
    },
    {
      headerName: i18n.t('Service Code'),
      field: 'service_billing_code_id',
      minWidth: 80,
      maxWidth: 120,
      editable: true,
      cellEditor: cellEditors.BaseSelect,
      cellEditorParams: {
        options: serviceBillingCodes.value,
        labelKey: 'code',
        valueKey: 'id',
      },
      valueFormatter: (params: ValueFormatterParams) => {
        const code = serviceBillingCodes.value.find((option: { id: any; }) => option.id === params.value)
        return code?.code || ''
      },
      suppressNavigable: params => !params?.data?.selected,
    },
    {
      headerName: i18n.t('Tax'),
      field: 'subject_to_tax',
      minWidth: 40,
      maxWidth: 70,
      align: 'right',
      editable: true,
      component: 'Status',
      cellEditor: cellEditors.Boolean,
      suppressNavigable: params => !params?.data?.selected,
    },
    {
      headerName: i18n.t('Billing Unit Price'),
      field: 'billing_unit_rate',
      minWidth: 100,
      maxWidth: 150,
      align: 'right',
      editable: true,
      component: 'FormattedPrice',
      cellEditor: cellEditors.Numeric,
      aggFunc: 'sum',
      suppressNavigable: params => !params?.data?.selected,
    },
  ]
})

const groupRowRendererParams = computed(() => {
  return {
    suppressCount: true,
    innerRenderer: 'CostForBillingGroupRow',
    groupRowRendererParams: {
      selectAll: Boolean(selectAll.value),
    },
  }
})
async function onSelectionChange(params: ValueSetterParams, newValue: string) {
  const {data} = params
  data.selected = newValue === SelectStatuses.Selected
  data.removed = newValue === SelectStatuses.Removed
  if (!data.selected) {
    data.billing_unit_rate = 0
  }
  await getBillingRate(params)
}

async function getBillingRate(params: ValueSetterParams) {
  const row = params.data
  const { data } = await axios.get(`/restify/service-billing-histories/billing-rate`, {
    params: {
      repositories: [row.id]
    }
  })
  const responseData = data[0] || {}
  params.data.billing_unit_rate = responseData?.rate || 0
  params.data.rate_from = responseData?.rate_from
  if (responseData?.subject_to_tax !== undefined) {
    params.data.subject_to_tax = responseData?.subject_to_tax
  }
  params.node?.setData(params.data)
}


function transformData(data: CostForBillingDataEntry[]) {
  return data.map((row) => {
    return {
      ...row,
      selected: false,
      removed: false,
      invoice_date: props.date,
      is_taxable: row?.type?.is_taxable || false,
      billing_unit_rate: 0,
      rate_from: RateFrom.Undefined,
    }
  })
}

async function updateAllRows(selected: boolean) {
  const nodes = getTableNodes(grid.value?.api)
  await updateRows(nodes, selected)
}

function getEntries() {
  return getTableData(grid.value?.api).filter(entry => entry.dirty)
}
defineExpose({
  getEntries
})

</script>
<script lang="ts">
import CostForBillingGroupRow from './CostForBillingGroupRow'

export default {
  components: {
    CostForBillingGroupRow,
  }
}
</script>
