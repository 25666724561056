<template>
  <div>
    <base-tooltip
        :content="$t('Search Worksite')"
        :tabindex="-1"
    >
      <base-button
          size="md"
          variant="white"
          @click="showDialog = true">
        <IconSearch class="w-4 h-5"/>
      </base-button>
    </base-tooltip>
    <base-form-dialog
        v-if="showDialog"
        :title="$t('Search Worksite')"
        :open.sync="showDialog"
        size="xl"
        @close="showDialog = false"
    >
      <base-form
          :save-text="$t('Filter')"
          :loading="loading"
          grid-classes="grid grid-cols-4 gap-x-3"
          layout="modal"
          @submit="onSubmit"
      >
        <base-input
            v-model="filters.city"
            :label="$t('City')"
            :placeholder="$t('City')"
            class="col-span-4 md:col-span-1"
        />
        <base-input
            v-model="filters.street_name"
            :label="$t('Address')"
            :placeholder="$t('Address')"
            class="col-span-4 md:col-span-3"
        />
        <base-input
            v-model="filters.unit_number"
            :label="$t('Apt/Unit/Suite')"
            :placeholder="$t('Apt/Unit/Suite')"
            class="col-span-4 md:col-span-1"
        />
        <customer-select
            v-model="filters.customer_id"
            :add-entity="false"
            clearable
        />
        <base-input
            v-model="filters.name"
            :label="$t('Name')"
            :placeholder="$t('Worksite Name')"
            class="col-span-4 md:col-span-2"
        />
        <div class="col-span-4 max-h-[600px] overflow-auto">
          <AgDataTable
              :columns="columns"
              :data="worksites"
              :pagination="false"
              :show-pagination="false"
              domLayout="autoHeight"
          >
            <template #extra-actions="{row}">
              <base-tooltip
                  :content="$t('Use this worksite for new work order')"
                  :tabindex="-1"
              >
                <base-button
                    variant="green-link"
                    @click="onSelectWorksite(row)"
                >
                  {{ $t('Apply') }}
                </base-button>
              </base-tooltip>
            </template>
          </AgDataTable>
        </div>
      </base-form>
    </base-form-dialog>
  </div>
</template>
<script>
  import axios from 'axios'
  import { removeFalsyValues } from '@/utils/utils'

  export default {
    data() {
      return {
        loading: false,
        showDialog: false,
        worksites: [],
        filters: {
          city: '',
          street_name: '',
          unit_number: '',
          customer_id: '',
          name: '',
        },
        columns: [
          {
            headerName: this.$t('Site#'),
            prop: 'attributes.number',
            minWidth: 80,
            maxWidth: 100,
          },
          {
            headerName: this.$t('Name'),
            prop: 'attributes.name',
            minWidth: 150,
          },
          {
            headerName: this.$t('Type'),
            prop: 'attributes.worksite_type',
            minWidth: 120,
            maxWidth: 150,
            component: 'FormattedText',
          },
          {
            headerName: this.$t('Unit'),
            prop: 'attributes.unit_number',
            minWidth: 80,
            maxWidth: 100,
          },
          {
            headerName: this.$t('Phone'),
            minWidth: 150,
            maxWidth: 200,
            prop: 'attributes.worksite_phone',
            component: 'PhoneLink',
          },
          {
            headerName: this.$t('Contact'),
            minWidth: 150,
            maxWidth: 300,
            prop: 'attributes.contact_name',
          },
        ],
      }
    },
    methods: {
      async onSubmit() {
        try {
          this.loading = true

          const params = removeFalsyValues(this.filters)

          const { data } = await axios.get('/restify/work-sites?sort=number', {
            params,
          })
          this.worksites = data
        } catch (err) {
          if (err.handled) {
            return
          }
          console.warn(err)
          this.$error(this.$t('Could not find any worksites'))
        } finally {
          this.loading = false
        }
      },
      onSelectWorksite(worksite) {
        this.$emit('on-select-worksite', worksite)
        this.showDialog = false
      },
    },
  }
</script>
