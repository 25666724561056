import { groupByType } from "@/components/table/utils/groupByTypes";
import groupBy from "lodash/groupBy";
import get from "lodash/get";

const groupByOptions = {
  [groupByType.Employee]: 'relationships.employee.attributes.code',
  [groupByType.Customer]: 'relationships.customer.attributes.code',
  [groupByType.Job]: 'relationships.job.attributes.number',
  [groupByType.ReportJob]: 'job.number',
  [groupByType.Journal]: 'relationships.journal.attributes.name',
  [groupByType.Vendor]: 'relationships.vendor.attributes.name',
  [groupByType.Account]: 'relationships.account.attributes.number',
  [groupByType.Reference]: 'attributes.reference_no',
  [groupByType.Source]: 'attributes.source_id',
  [groupByType.WorkOrder]: 'relationships.workOrder.attributes.number',
  [groupByType.State]: 'attributes.state',
  [groupByType.WorkSite]: 'relationships.workSite.attributes.number',
}

export function groupTableData(data, groupByOption) {
  let filteredData = data.filter(row => row.attributes || row)
  const objectPath = groupByOptions[groupByOption]
  const groups = groupBy(filteredData, objectPath)
  const result = []

  for (let groupId in groups) {
    const groupData = groups[groupId]
    let header = get(groupData, `[0].relationships[${groupByOption}]`) || get(groupData, '[0]')

    if (header) {
      result.push({
        header,
        data: groupData,
      })
    }
    result.push(...groupData.map(row => {
      return {
        ...row,
        ...row?.relationships || {},
      }
    }))
  }
  return result
}
