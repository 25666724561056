<template>
  <div></div>
</template>
<script>
  import axios from "axios";

  export default {
    methods: {
      async deleteRow() {
        const confirmed = await this.$deleteConfirm({
          title: this.params.title || this.$t('Delete row'),
          description: this.params.description || this.$t('Are you sure you want to delete this row ? '),
          buttonText: this.params.buttonText || this.$t('Delete'),
        })
        const row = this.params.data
        if (!confirmed || this.params.skipRowDelete) {
          if (this.params.onConfirm) {
            this.params.onConfirm(this.params)
          }
          return this.params?.stopEditing()
        }

        try {
          this.deleteTableRow(row)

          if (row.id && this.params.url) {
            await axios.delete(`${this.params.url}/${row.id}`)
          }
          if (this.params.onConfirm) {
            this.params.onConfirm(this.params)
          }
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not delete the row'))
          this.deleteTableRow(row)
        }
      },
      deleteTableRow(row) {
        if (this.params?.skipRowDelete) {
          return
        }
        this.params.api.applyTransaction({
          remove: [row]
        });
      }
    },
    async mounted() {
      await this.deleteRow()
    },
  }
</script>
