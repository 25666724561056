<template>
  <div :key="get(currentResource, 'id', 'invoice')">
    <portal to="page-title">
      {{ pageTitle }},&nbsp;@
      <router-link v-if="vendor?.id" :to="`/accounts-payable/vendors/${vendor?.id}/invoices`">
        {{ vendor?.code }}
        <span v-if="vendor?.name">({{ vendor.name }})</span>
      </router-link>
    </portal>
    <div class="flex justify-between items-center">
      <layout-tabs :items="filteredItems"/>
      <div class="flex items-center space-x-2">
        <BaseButton
          v-if="canActivate"
          variant="primary-link"
          @click="activateInvoice"
        >
          <PlayIcon class="w-4 h-4 mr-1"/>
          <span>{{ $t('Activate') }}</span>
        </BaseButton>
        <BaseButton
          v-if="canDeactivate"
          variant="primary-link"
          @click="deactivateInvoice"
        >
          <PauseIcon class="w-4 h-4 mr-1"/>
          <span>{{ $t('Make Inactive') }}</span>
        </BaseButton>
        <BaseButton
          v-if="isActive"
          variant="primary-link"
          @click="showGenerateInvoicesDialog = true"
        >
          <RecurringInvoiceIcon class="w-4 h-4 mr-1"/>
          <span>{{ $t('Generate Invoices') }}</span>
        </BaseButton>
        <InvoiceQuickPay v-if="$isAuthorized('authorizedToQuickPay', currentResource)"
                         :invoice="currentResource"
                         @save-success="getCurrentResource"
        />
        <ProofListingButton
          v-if="$isAuthorized('authorizedToPost', currentResource)"
          :selected-rows="[currentResource]"
          path="/accounts-payable/recurring-invoices/proof-listing"
        />
      </div>
    </div>
    <GenerateInvoicesDialog
      v-if="showGenerateInvoicesDialog"
      :open.sync="showGenerateInvoicesDialog"
      :recurring-invoices="[currentResource]"
      @save="onInvoicesGenerated"
      @close="showGenerateInvoicesDialog = false"
    />
    <portal to="entity-name">
      {{ $t('Invoice ') }}{{ currentResource?.attributes?.description }}
      ({{ vendor?.name }})
    </portal>
    <router-view/>
  </div>
</template>
<script>
import { PauseIcon, PlayIcon, PrinterIcon } from 'vue-feather-icons'
import layoutPermissions from '@/mixins/layoutPermissions'
import { resourceStatuses } from "@/enum/enums";
import GenerateInvoicesDialog from "@/modules/accounts-payable/components/recurring-invoice/GenerateInvoicesDialog.vue";
import RecurringInvoiceIcon from "@/modules/accounts-payable/pages/invoices/RecurringInvoiceIcon.vue";

export default {
  components: {
    RecurringInvoiceIcon,
    GenerateInvoicesDialog,
    PauseIcon,
    PlayIcon,
    PrinterIcon,
  },
  mixins: [layoutPermissions],
  data() {
    return {
      showGenerateInvoicesDialog: false,
    }
  },
  computed: {
    items() {
      return [
        {
          name: this.$t('Details'),
          path: `/accounts-payable/recurring-invoices/${this.$route.params.id}/view`,
          authorizedTo: 'authorizedToShow',
        },
        {
          name: this.$t('Edit'),
          path: `/accounts-payable/recurring-invoices/${this.$route.params.id}/edit`,
          authorizedTo: 'authorizedToUpdate',
        },
      ]
    },
    currentResource() {
      return this.$store.state.accountsPayable.currentRecurringInvoice
    },
    vendor() {
      const { vendor } = this.currentResource?.relationships || {}
      return vendor?.attributes
    },
    pageTitle() {
      const invoiceDescription = this.currentResource.attributes.description || ''
      return `${this.$t('Recurring Payable ')} ${invoiceDescription}`
    },
    isActive() {
      return this.currentResource?.attributes?.status === resourceStatuses.Active
    },
    canDeactivate() {
      return this.isActive
    },
    canActivate() {
      return this.currentResource.attributes.status === resourceStatuses.Pending
    },
  },
  methods: {
    async onInvoicesGenerated() {
      this.showGenerateInvoicesDialog = false
      await this.getCurrentResource()
    },
    async getCurrentResource(id) {
      if (!id) {
        id = this.$route.params.id
      }
      await this.$store.dispatch('accountsPayable/getRecurringInvoice', id)
    },
    async deactivateInvoice() {
      const confirmed = await this.$deleteConfirm({
        title: this.$t('Make Recurring Payanle Inactive'),
        description: this.$t('By confirming this action, the recurring payable will be set to inactive and will not create new invoices anymore. You can always set it back to active.'),
        buttonText: this.$t('Make Inactive')
      })
      if (!confirmed) {
        return
      }
      const { id } = this.currentResource
      await this.$store.dispatch('accountsPayable/deactivateRecurringInvoice', id)
    },
    async activateInvoice() {
      const confirmed = await this.$confirm({
        title: this.$t('Activate Recurring Payable'),
        description: this.$t('By making the recurring payable active, it will start creating new invoices based on the provided schedule. Are you sure?'),
        buttonText: this.$t('Activate')
      })
      if (!confirmed) {
        return
      }
      const { id } = this.currentResource
      await this.$store.dispatch('accountsPayable/activateRecurringInvoice', id)
    },
  },
}
</script>
