<template>
  <UnionForm />
</template>
<script>
  import UnionForm from '@/modules/payroll/components/rates/UnionForm'

  export default {
    components: {
      UnionForm,
    },
  }
</script>
