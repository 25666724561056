<template>
  <div class="flex justify-end relative overflow-visible">
    <span class="w-16 flex justify-end">{{ $formatPrice(data?.cost) }}</span>
    <span v-if="costStatus" class="absolute right-[-78px]">({{ costStatus }})</span>
  </div>
</template>
<script setup lang="ts">

import {resourceStatuses} from "@/enum/enums";
import {computed, PropType} from "vue";

const props = defineProps({
  data: {
    type: Object as PropType<any>,
    required: true,
    default: () => ({}),
  }
})

const costStatus = computed(() => {
  const status = props.data?.billing_status
  if (status === resourceStatuses.Open || status === 'n/a') {
    return
  }
  return status
})
</script>
