<template>
  <AgDataTable
      :columns="accountSummaryColumns"
      :data="filteredData"
      :data-loading="loading"
      :compact="true"
      :pagination="false"
      :no-borders="true"
      :groupIncludeTotalFooter="true"
      :getRowId="getRowId"
      dom-layout="autoHeight"
      v-bind="$attrs"
  >
    <template #description_summary>
      <span class="font-medium print:text-print">
        {{ $t('Totals') }}
      </span>
    </template>
    <template #credit_amount_summary>
      <span class="font-medium print:text-print">
        {{ $formatPrice(sumBy(data, 'credit_amount')) }}
      </span>
    </template>
    <template #debit_amount_summary>
      <span class="font-medium print:text-print">
        {{ $formatPrice(sumBy(data, 'debit_amount')) }}
      </span>
    </template>
  </AgDataTable>
</template>
<script>
  import sumBy from 'lodash/sumBy'
  import { gridContext } from "@/components/ag-grid/gridContext";

  export default {
    name: 'ProofListingAccountSummary',
    props: {
      data: {
        type: Array,
        default: () => [],
      },
      loading: Boolean,
    },
    computed: {
      filteredData() {
        return this.data.filter(account => {
          return account.debit_amount > 0 || account.credit_amount > 0
        })
      },
      accountSummaryColumns() {
        return [
          {
            headerName: this.$t('Account'),
            field: 'number',
            component: 'AccountLink',
            minWidth: 60,
            maxWidth: 80,
            cellRendererParams: {
              showDescription: false,
            }
          },
          {
            headerName: this.$t('Sub'),
            field: 'subaccount',
            minWidth: 40,
            maxWidth: 70,
          },
          {
            headerName: this.$t('Description'),
            field: 'description',
            minWidth: 160,
            maxWidth: gridContext.isPrintMode ? 330 : null,
            showRowGroup: true,
            cellRenderer: 'agGroupCellRenderer',
          },
          {
            headerName: this.$t('Debit'),
            field: 'debit_amount',
            align: 'right',
            component: 'FormattedPrice',
            minWidth: 100,
            maxWidth: gridContext.isPrintMode ? 120: 180,
            cellRendererParams: {
              hideZero: true,
            },
            aggFunc: 'sum',
          },
          {
            headerName: this.$t('Credit'),
            field: 'credit_amount',
            minWidth: 100,
            maxWidth: gridContext.isPrintMode ? 120: 180,
            align: 'right',
            component: 'FormattedPrice',
            cellRendererParams: {
              hideZero: true,
            },
            aggFunc: 'sum',
          },
        ]
      },
    },
    methods: {
      sumBy,
      getRowId(params) {
        return params.data.id + params.data.subaccount
      }
    }
  }
</script>

