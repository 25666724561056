import { getEmployeeDefaults, getTimezone, minutesToHours } from "@/modules/payroll/utils/timeCardUtils";
import { costCenterTypes } from "@/components/grid-table/utils/cost-center";
import { addMinutes, isSameDay } from "date-fns";
import orderBy from "lodash/orderBy";
import parse from "date-fns/parse";
import { getSetting } from "@/plugins/settingsAndEnumsPlugin";

export function getTotalMinutes(params) {
  const { regular_minutes, overtime_minutes, premium_minutes } = params.data?.attributes || {}
  return (+regular_minutes || 0) + (+overtime_minutes || 0) + (+premium_minutes || 0)
}

export function hoursFormatter(params) {
  const minutes = params.value || 0
  return minutesToHours(minutes)
}

export function isRowValid(row) {
  if (!row) {
    return false
  }
  let requiredFields = ['date', 'cost_center', 'employee_id', 'start_time']
  let requiredFieldMapping = {
    [costCenterTypes.GeneralAndAdministrative]: [],
    [costCenterTypes.Job]: ['source_id', 'addl_source_id'],
    [costCenterTypes.Equipment]: ['source_id'],
    [costCenterTypes.WorkOrder]: ['source_id'],
    default: [],
  }

  const { cost_center } = row
  const extraRequiredFields = requiredFieldMapping[cost_center] || requiredFieldMapping.default

  let allRequiredFields = [...requiredFields, ...extraRequiredFields]

  let requiredFieldsInvalid = allRequiredFields.some(field => !row[field])

  return requiredFieldsInvalid === false
}

export function getRoundedMinutes(date) {
  const minutes = date.getMinutes()
  const roundInterval =  getSetting('timesheet', 'round_interval') || 15
  return Math.ceil(minutes / roundInterval) * roundInterval
}

export function getRoundedDate(date) {
  const minutesToAdd = getRoundedMinutes(date)
  date.setMinutes(0)
  return addMinutes(date, minutesToAdd)
}

export function getTimeSheetsForDay(timeSheetEntries, date) {
  let parsedDate = date
  if (typeof date === 'string') {
    parsedDate = parse(date, 'yyyy-MM-dd', new Date())
  }
  const filteredEntries = timeSheetEntries.filter((entry) => {
    const day = parse(entry.date, 'yyyy-MM-dd', new Date())
    return isSameDay(day, parsedDate)
  })
  return orderBy(filteredEntries, 'start_time', 'asc')
}


export function getStartTime(timeSheetEntries, date) {
  let entries = getTimeSheetsForDay(timeSheetEntries, date)
  entries = orderBy(entries, 'start_time', 'asc')
  if (entries.length === 0) {
    return getRoundedDate(new Date())
  }
  const lastEntry = entries[entries.length - 1]
  if (lastEntry.start_time?.length > 5) {
    lastEntry.start_time = lastEntry.start_time.slice(0, 5)
  }
  let parsedDate = date
  if (typeof date === 'string') {
    parsedDate = parse(date, 'yyyy-MM-dd', new Date())
  }
  const startTime = parse(lastEntry.start_time, 'HH:mm', parsedDate)
  const newStartTime = addMinutes(startTime, lastEntry.duration)
  return getRoundedDate(newStartTime)
}

export function getEmptyEntry({ employee, days, previousRow, order, start_time }) {
  const {
    source_id,
    source_type,
    addl_source_id,
    addl_source_type,
    cost_center,
  } = getEmployeeDefaults(employee)

  let date = null
  if (days) {
    const firstDay = days[0]
    date = firstDay.value
  }

  if (previousRow) {
    date = previousRow.date
  }

  return {
    _localId: crypto.randomUUID(),
    order,
    timezone: getTimezone(),
    cost_center: cost_center || costCenterTypes.GeneralAndAdministrative,
    source_id: source_id || null,
    craft_code_id: null,
    sub_trade_id: null,
    source_type: source_type || null,
    addl_source_id: addl_source_id || null,
    addl_source_type: addl_source_type || null,
    is_lunch: false,
    is_break: false,
    notes: '',
    date: date || null,
    duration: 0,
    employee_id: employee?.id,
    start_time: start_time || null,
    special_sources: [],
  }
}
