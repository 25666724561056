import axios from "axios";
import { success } from "@/components/common/NotificationPlugin";
import i18n from "@/i18n";

class InvitationService {
  async createInvitation(data) {
    const requestData = {
      ...data,
      roles: [data.role],
    }
    delete requestData.role
    return axios.post(`/restify/invitations`, requestData);
  }

  async resendInvite(data) {
    try {
      await axios.post(`restify/invitations/${data.id}/resend`, data);
      success(i18n.t(`We've sent another email to: ${data.email}`))
    } catch (err) {
      success(i18n.t`Could not resend the invite. Please try again or contact us`)
    }
  }

  async deleteInvitation(data) {
    try {
      await axios.delete(`/restify/invitations/${data.id}`);
    } catch (err) {
      success(i18n.t`Could delete the invite.`)
    }
  }


  async getInvitations() {
    return axios.get(`/restify/invitations`, {
      params: {
        perPage: 500,
      }
    });
  }
}

export default new InvitationService();
