export function humanReadableSize(sizeInBytes: number, decimals = 2, preferMu = 'KB') {
  if (!sizeInBytes) {
    return `0 ${preferMu}`
  }
  const rank = Math.floor(Math.log(sizeInBytes) / Math.log(1024));
  const mus = ['B', 'KB', 'MB', 'GB', 'TB'];
  const mu = mus[rank];
  let size: string = (sizeInBytes / Math.pow(1024, rank)).toFixed(decimals)
  let sizeFloat = parseFloat(size)

  return `${sizeFloat} ${mu}`;
}

export type FileExtended = File & {
  extension?: string
}

export function getFileExtension(file: FileExtended) {
  if (file.extension) {
    return file.extension
  }
  const name = file.name
  const parts = name.split('.')

  if (parts.length === 1) {
    return 'blank'
  }

  const extension = parts[parts.length - 1]
  if (!extension) {
    return 'txt'
  }

  return extension.toLowerCase()
}

export function saveFileAsJson(json: Record<string, any>, fileName: string) {
  const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(json, null, 2));
  const downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute("href", dataStr);
  downloadAnchorNode.setAttribute("download", fileName);
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}

/**
 * Convert a string to an ArrayBuffer
 * @param s string to convert
 */
export function s2ab(s: string) {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
  return buf;
}

function isPdf(type: string) {
  return type === 'application/pdf'
}

function isImage(type: string) {
  return type.startsWith('image/')
}

export function isAttachmentPreviewAble(type: string) {
  return isPdf(type) || isImage(type)
}

/**
 * Resizes an image file to a maximum dimension. Defaults to 2000
 * @param file Image to resize
 * @param maxDimension
 */
export async function resizeImageFile(file: File, maxDimension = 2000) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        let width = img.width;
        let height = img.height;

        // Check if resizing is necessary
        if (width > maxDimension || height > maxDimension) {
          if (width > height) {
            height = Math.floor((height * maxDimension) / width);
            width = maxDimension;
          } else {
            width = Math.floor((width * maxDimension) / height);
            height = maxDimension;
          }
        }

        canvas.width = width;
        canvas.height = height;
        ctx?.drawImage(img, 0, 0, width, height);

        canvas.toBlob((blob) => {
          resolve(blob);
        }, file.type);
      };
      img.src = event?.target?.result as string;
    };

    fileReader.onerror = (error) => {
      reject(error);
    }

    fileReader.readAsDataURL(file);
  })
}
