<template>
  <base-data-table
      v-bind="defaultAttributes"
      :columns="columns"
      :data="getData"
      :total-rows="rowsLength"
      @force-pagination="forcePagination"
  >

    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template #header="{row}">
      <component
          v-if="row.header?.id "
          :is="getHeaderComponent"
          :data="row.header"
          :show-preview="false"
      />
      <span v-else> {{  groupByJob ? $t('(Non - Job Receivables)') : $t('(Non - Work Order Receivables)') }} </span>
    </template>

    <template #customer="{row}">
      <CustomerLink
          :data="row.customer"
          :show-name="true"
          :show-preview="false"
      />
    </template>

    <template #total="{row}">
      {{ $formatPrice(row.total || row.totals?.open_amount) }}
    </template>

    <template #billing="{row}">
      <BillingLink :data="row.billing"/>
    </template>

    <template #subtotal="{subtotal}">
      <td colspan="3">
        <div class="flex items-center">
          <span class="mx-6 py-2">{{ groupByJob ? $t('Job Total') : $t('Work Order Total') }}</span>
          <template v-if="subtotal.retention_receivable">
            ({{ $t('Retention Receivable:') }}
            <span class="mx-4">{{ $formatPrice(subtotal.retention_receivable) }})</span>
          </template>
        </div>
      </td>

      <td v-if="actualDays"/>

      <td v-for="column in amountColumns"
          align="right"
      >
        <span :class="{'top-border flex pt-3 flex-1 justify-end': format !== agedByViewFormats.JobTotal}">
          {{ $formatPrice(get(subtotal, column.prop, 0)) }}
        </span>
      </td>
    </template>

  </base-data-table>
</template>
<script>
  import { agedByGrouping } from '@/components/range-filters'
  import { agedByColumns } from '@/modules/common/components/reports/util'
  import { getBillingType } from '@/modules/accounts-receivable/pages/billings/billings'
  import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'
  import { agedByViewFormats } from '@/modules/accounts-receivable/components/reports/util'

  const module = 'Receivable'

  export default {
    extends: ReportTableWrapper,
    props: {
      format: {
        required: true,
        type: String,
        default: agedByViewFormats.EachInvoice,
      },
      days: {
        required: true,
        type: Array,
        default: () => [30, 60, 90],
      },
      actualDays: {
        type: [Boolean, String],
        default: false,
      },
      groupBy: {
        required: true,
        type: String,
        default: agedByGrouping.Job,
      },
    },
    data() {
      return {
        agedByViewFormats,
      }
    },
    computed: {
      columns() {
        const columns = [
          {
            label: this.$t('Customer'),
            prop: 'customer',
            minWidth: 150,
            maxWidth: 220,
          },
          {
            label: this.$t('Invoice'),
            prop: 'billing',
            minWidth: 110,
            maxWidth: 110,
            align: 'right',
          },
          {
            label: this.$t('Invoice <br> Date'),
            prop: 'billing.date',
            component: 'FormattedDate',
            minWidth: 90,
            maxWidth: 90,
            align: 'center',
            summary: () => this.groupByJob ? `${this.$t('Total Selected Jobs')}<br><br>&nbsp` : `${this.$t('Total Selected Work Orders')}<br><br>&nbsp`,
          },
        ]

        if (!this.data.aging_totals) {
          return columns
        }

        const agingColumns = agedByColumns(this.days, this.data.aging_totals, this.data.grand_totals, module, this.actualDays)
        return [
          ...columns,
          ...agingColumns,
        ]
      },
      getHeaderComponent() {
        return this.groupByJob ? 'JobLink' : 'WorkOrderLink'
      },
      groupByJob() {
        return this.groupBy === agedByGrouping.Job
      },
    },
    methods: {
      composeRows(data) {

        data.forEach(group => {

          const header = this.groupByJob ? group.job : group.work_order

          this.rows.push({
            header: header || { id: null },
          })

          let billingEntries = []

          group.customers.forEach(_group => {

            if (this.format !== agedByViewFormats.JobTotal) {

              const billings = _group.billings.map(billingObject => {
                return {
                  ...billingObject,
                  customer: _group.customer,
                  aging_totals: billingObject?.aging_totals,
                  actual_days: billingObject?.actual_days,
                  total: billingObject.totals?.open_amount,
                }
              })

              billingEntries.push(...billings)
            }
          })

          this.rows.push(...billingEntries)

          this.rows.push({
            subtotal: {
              aging_totals: group.aging_totals,
              retention_receivable: group.totals?.retention_receivable,
              total: group.totals?.open_amount,
            },
          })
        })

        return this.rows
      },
      getBillingType,
    },
  }
</script>
