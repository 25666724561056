import i18n from '@/i18n'
const TransactionTypes = {
  Expense: 'expense',
  JobCost: 'job-costed',
  CustomerBilled: 'customer-billed',
}

const TransactionTypeLabels = {
  [TransactionTypes.Expense]: 'Expense',
  [TransactionTypes.JobCost]: 'Job Cost',
  [TransactionTypes.CustomerBilled]: 'Customer Billed',
}
export const types = {
  Transaction: 'transaction',
  Billing: 'billing',
  BillingPayment: 'billing-payment',
  EquipmentHistory: 'equipment-history',
  Invoice: 'invoice',
  InvoicePayments: 'invoice-payment',
  JobHistory: 'job-history',
  MaterialHistory: 'material-history',
  Payroll: 'payroll',
  PayrollBath: 'payroll-batch',
  PurchaseOrder: 'purchase-order',
  ServiceInvoice: 'service-invoice',
  AccountsReceivableInit: 'accounts-receivable-init',
  AccountsPayableInit: 'accounts-payable-init',
  JobCostInit: 'job-costing-init',
}

export const relationships = {
  [types.ServiceInvoice]: 'workOrder,customer,district',
  [types.Transaction]: 'account,subaccount',
  [types.Billing]: 'job',
  [types.PurchaseOrder]: 'vendor',
  [types.Invoice]: 'vendor',
  [types.Payroll]: 'employee,timecard,bank',
  [types.EquipmentHistory]: 'customer,business,source,addlSource,equipment',
  default: '',
}

export function getColumns(type, vm) {
  const columns = {
    [types.ServiceInvoice]: [
      {
        headerName: i18n.t('Invoice #'),
        field: 'attributes.number',
        minWidth: 80,
        maxWidth: 100,
        component: 'EntityLink',
        redirectTo: '/service-billing/service-invoices/{ID}/view',
      },
      {
        headerName: i18n.t('Work Order'),
        field: 'relationships.workOrder.attributes.number',
        minWidth: 80,
        maxWidth: 100,
        component: 'EntityLink',
        redirectTo: '/service-billing/work-orders/{ID}/view',
        entityKey: 'attributes.work_order_id',
      },
      {
        headerName: i18n.t('Customer'),
        field: 'relationships.customer.attributes.name',
        minWidth: 80,
        maxWidth: 100,
        component: 'EntityLink',
        redirectTo: '/accounts-receivable/customers/{ID}/view',
        entityKey: 'attributes.customer_id',
      },
      {
        headerName: i18n.t('Date'),
        field: 'attributes.date',
        minWidth: 80,
        maxWidth: 100,
        component: 'FormattedDate',
      },
      {
        headerName: i18n.t('District'),
        field: 'relationships.district.attributes.code',
        minWidth: 80,
        maxWidth: 100,
      },
      {
        headerName: i18n.t('Start Date'),
        field: 'attributes.work_start_date',
        minWidth: 80,
        maxWidth: 100,
        component: 'FormattedDate',
      },
      {
        headerName: i18n.t('End Date'),
        field: 'attributes.work_end_date',
        minWidth: 80,
        maxWidth: 100,
        component: 'FormattedDate',
      },
      {
        headerName: i18n.t('Description'),
        data: 'attributes.description',
        minWidth: 100,
        maxWidth: 120,
      },
    ],
    [types.Billing]: [
      {
        headerName: i18n.t('Invoice #'),
        field: 'billing_number',
        minWidth: 80,
        maxWidth: 120,
      },
      {
        headerName: i18n.t('Job'),
        field: 'relationships.job.attributes.number',
        minWidth: 100,
        maxWidth: 150,
        component: 'EntityLink',
        redirectTo: '/job-costing/jobs/{ID}/view',
        entityKey: 'attributes.job_id',
      },
      {
        headerName: i18n.t('Type'),
        field: 'attributes.type',
        align: 'center',
        maxWidth: 100,
        component: 'Status',
      },
      {
        headerName: i18n.t('Date'),
        field: 'attributes.date',
        component: 'FormattedDate',
      },
      {
        headerName: i18n.t('Created By'),
        field: 'attributes.created_by',
        minWidth: 100,
        maxWidth: 150,
        component: 'UserLink',
      },
      {
        headerName: i18n.t('Amount'),
        field: 'attributes.gross_amount',
        align: 'right',
        component: 'FormattedPrice',
        aggFunc: 'sum',
      },
      {
        headerName: i18n.t('Tax'),
        field: 'attributes.sales_tax_amount',
        align: 'right',
        component: 'FormattedPrice',
        aggFunc: 'sum',
      },
      {
        headerName: i18n.t('Retention'),
        field: 'attributes.retention_amount',
        align: 'right',
        component: 'FormattedPrice',
        aggFunc: 'sum',
      },
      {
        headerName: i18n.t('Open'),
        field: 'attributes.open_amount',
        align: 'right',
        component: 'FormattedPrice',
        aggFunc: 'sum',
      },
    ],
    [types.BillingPayment]: [
      {
        headerName: i18n.t('Payment Date'),
        field: 'attributes.date',
        maxWidth: 160,
        component: 'EntityLink',
        redirectTo: '/accounts-payable/payments/batches/{ID}/view',
        entityKey: 'attributes.journal_id',
        formatter: vm.$formatDate,
      },
      {
        headerName: i18n.t('Payment Reference'),
        field: 'attributes.number',
        maxWidth: 160,
      },
      {
        headerName: i18n.t('Bank'),
        field: 'attributes.bank_id',
        maxWidth: 150,
        component: 'BankLink',
      },
      {
        headerName: i18n.t('Amount'),
        field: 'attributes.net_amount',
        align: 'right',
        minWidth: 100,
        component: 'FormattedPrice',
        aggFunc: 'sum',
      },
      {
        headerName: i18n.t('Created By'),
        field: 'attributes.created_by',
        align: 'right',
        minWidth: 100,
        maxWidth: 150,
        component: 'UserLink',
      },
      {
        headerName: i18n.t('Created At'),
        field: 'attributes.created_at',
        align: 'right',
        minWidth: 100,
        maxWidth: 120,
        component: 'FormattedDate',
      },
    ],
    [types.Transaction]: [
      {
        headerName: i18n.t('Description'),
        field: 'attributes.description',
        minWidth: 100,
        maxWidth: 120,
      },
      {
        headerName: i18n.t('Source'),
        field: 'attributes.source_id',
        minWidth: 80,
        maxWidth: 100,
        component: 'SourceLink',
      },
      {
        headerName: i18n.t('Line Item'),
        field: 'attributes.addl_source_id',
        minWidth: 80,
        maxWidth: 100,
        component: 'AddlSourceLink',
      },
      {
        headerName: i18n.t('Account'),
        field: 'relationships.account.attributes.number',
        minWidth: 50,
        maxWidth: 80,
        component: 'AccountLink',
      },
      {
        headerName: i18n.t('Sub Account'),
        field: 'relationships.subaccount.attributes.number',
        minWidth: 40,
        maxWidth: 80,
      },
      {
        headerName: i18n.t('Qty'),
        field: 'attributes.meta.quantity',
        align: 'center',
        minWidth: 60,
        maxWidth: 80,
      },
      {
        headerName: i18n.t('Unit'),
        field: 'attributes.meta.unit_price',
        minWidth: 80,
        maxWidth: 100,
      },
      {
        headerName: i18n.t('Amount'),
        field: 'attributes.amount',
        align: 'right',
        minWidth: 80,
        maxWidth: 100,
        component: 'FormattedPrice',
        aggFunc: 'sum',
      },
      {
        headerName: i18n.t('Ref#'),
        field: 'attributes.reference_no',
        align: 'center',
        minWidth: 80,
        maxWidth: 120,
      },
      {
        headerName: i18n.t('Reference Date'),
        field: 'attributes.reference_date',
        align: 'right',
        minWidth: 80,
        maxWidth: 100,
        component: 'FormattedDate',
      },
    ],
    [types.InvoicePayments]: [
      {
        headerName: i18n.t('Payment Date'),
        field: 'attributes.date',
        maxWidth: 160,
        component: 'EntityLink',
        redirectTo: '/accounts-payable/payments/batches/{ID}/view',
        entityKey: 'attributes.journal_id',
        formatter: vm.$formatDate,
      },
      {
        headerName: i18n.t('Payment Reference'),
        field: 'attributes.number',
        maxWidth: 160,
      },
      {
        headerName: i18n.t('Bank'),
        field: 'attributes.bank_id',
        maxWidth: 150,
        component: 'BankLink',
      },
      {
        headerName: i18n.t('Amount'),
        field: 'attributes.gross_amount',
        align: 'right',
        minWidth: 100,
        component: 'FormattedPrice',
        aggFunc: 'sum',
      },
      {
        headerName: i18n.t('Created By'),
        field: 'attributes.created_by',
        align: 'right',
        minWidth: 100,
        maxWidth: 150,
        component: 'UserLink',
      },
      {
        headerName: i18n.t('Created At'),
        field: 'attributes.created_at',
        align: 'right',
        minWidth: 100,
        maxWidth: 120,
        component: 'FormattedDate',
      },
    ],
    [types.EquipmentHistory]: [
      {
        headerName: i18n.t('Equipment'),
        field: 'attributes.equipment_id',
        minWidth: 250,
        maxWidth: 400,
        component: 'EquipmentLink'
      },
      {
        headerName: i18n.t('Trans Type'),
        field: 'attributes.transaction_type',
        minWidth: 100,
        maxWidth: 180,
        valueFormatter: params => {
          return TransactionTypeLabels[params.value] || params.value
        },
      },
      {
        headerName: i18n.t('Date'),
        field: 'attributes.reference_date',
        minWidth: 80,
        maxWidth: 110,
        component: 'FormattedDate',
      },
      {
        label: i18n.t('Employee Vend/Cust'),
        prop: 'relationships.business.code',
        minWidth: 100,
        maxWidth: 120,
      },
      {
        headerName: i18n.t('Account'),
        field: 'attributes.account',
        minWidth: 100,
        maxWidth: 120,
        component: 'AccountLink',
        cellRendererParams: {
          showDescription: false,
        },
      },
      {
        headerName: i18n.t('Job'),
        field: 'relationships.source.number',
        minWidth: 100,
        maxWidth: 120,
      },
      {
        headerName: i18n.t('Addl Source'),
        field: 'relationships.addlSource.description',
        minWidth: 100,
        maxWidth: 120,
      },
      {
        headerName: i18n.t('Hours'),
        field: 'attributes.hours',
        minWidth: 80,
        maxWidth: 100,
        align: 'right',
        valueFormatter: params => {
          return params.value ? params.value.toFixed(2) : ''
        },
        aggFunc: 'sum',
      },
      {
        headerName: i18n.t('$ Amount'),
        field: 'attributes.amount',
        minWidth: 100,
        maxWidth: 150,
        component: 'FormattedPrice',
        align: 'right',
        aggFunc: 'sum',
      },
    ],
    [types.JobHistory]: [
      {
        headerName: i18n.t('Job'),
        field: 'attributes.job_id',
        minWidth: 250,
        maxWidth: 400,
        component: 'JobLink'
      },
      {
        headerName: i18n.t('Type'),
        field: 'attributes.job_type_id',
        minWidth: 40,
        maxWidth: 70,
        component: 'JobTypeLink'
      },
      {
        headerName: i18n.t('Line Item'),
        field: 'attributes.line_item_id',
        minWidth: 250,
        maxWidth: 400,
        component: 'LineItemLink'
      },
      {
        headerName: i18n.t('Source'),
        field: 'attributes.source_id',
        minWidth: 80,
        maxWidth: 160,
        component: 'SourceLink'
      },
      {
        headerName: i18n.t('Date'),
        field: 'attributes.reference_date',
        minWidth: 80,
        maxWidth: 110,
        component: 'FormattedDate',
      },
      {
        headerName: i18n.t('Description'),
        field: 'attributes.description',
        minWidth: 100,
        maxWidth: 250,
      },
      {
        headerName: i18n.t('Qty'),
        field: 'attributes.quantity',
        minWidth: 80,
        maxWidth: 100,
        valueFormatter: params => {
          const { meta, quantity } = params?.data || {}
          return `${meta?.quantity || quantity || 0} ${meta?.um || ''}`
        },
        align: 'right',
        aggFunc: 'sum',
      },
      {
        headerName: i18n.t('Unit Price'),
        field: 'attributes.meta.unit_price',
        minWidth: 80,
        maxWidth: 100,
        component: 'FormattedPrice',
        align: 'right',
        aggFunc: 'sum',
      },
      {
        headerName: i18n.t('$ Amount'),
        field: 'attributes.amount',
        minWidth: 100,
        maxWidth: 150,
        component: 'FormattedPrice',
        align: 'right',
        aggFunc: 'sum',
      },
    ],
    [types.PurchaseOrder]: [
      {
        headerName: 'Vendor',
        field: 'relationships.vendor.attributes.code',
        minWidth: 80,
        maxWidth: 80,
        component: 'EntityLink',
        redirectTo: '/accounts-payable/vendors/{ID}/view',
        entityKey: 'attributes.vendor_id',
      },
      {
        headerName: i18n.t('PO #'),
        field: 'attributes.number',
        maxWidth: 100,
        component: 'EntityLink',
        redirectTo: '/purchasing-inventory/purchase-orders/{ID}/view',
      },
      {
        headerName: i18n.t('Order Date'),
        field: 'attributes.date',
        maxWidth: 120,
        component: 'FormattedDate',
      },
      {
        headerName: i18n.t('Type'),
        field: 'attributes.price_by',
        maxWidth: 100,
        component: 'Status',
      },
      {
        headerName: i18n.t('Delivery Date'),
        field: 'attributes.delivery_date',
        maxWidth: 120,
        component: 'FormattedDate',
      },
      {
        headerName: i18n.t('Amount'),
        field: 'attributes.total_amount',
        align: 'right',
        maxWidth: 100,
        component: 'FormattedPrice',
        aggFunc: 'sum',
      },
      {
        headerName: i18n.t('Invoiced Amount'),
        field: 'attributes.amount',
        align: 'right',
        maxWidth: 100,
        component: 'FormattedPrice',
        aggFunc: 'sum',
      },
      {
        headerName: i18n.t('Freight Amount'),
        field: 'attributes.freight_amount',
        align: 'right',
        maxWidth: 100,
        component: 'FormattedPrice',
        aggFunc: 'sum',
      },
      {
        headerName: i18n.t('Contact'),
        field: 'attributes.contact',
        align: 'right',
        minWidth: 80,
        maxWidth: 150,
      },
    ],
    [types.Invoice]: [
      {
        headerName: i18n.t('Invoice #'),
        field: 'attributes.number',
        maxWidth: 160,
        component: 'EntityLink',
        redirectTo: '/accounts-payable/invoices/{ID}/view',
      },
      {
        headerName: i18n.t('Vendor'),
        field: 'relationships.vendor.attributes.code',
        component: 'EntityLink',
        redirectTo: '/accounts-payable/vendors/{ID}/view',
        entityKey: 'attributes.vendor_id',
      },
      {
        headerName: i18n.t('Date'),
        field: 'attributes.date',
        minWidth: 100,
        maxWidth: 150,
        component: 'FormattedDate',
      },
      {
        headerName: i18n.t('Created By'),
        field: 'attributes.created_by',
        minWidth: 100,
        maxWidth: 150,
        component: 'UserLink',
      },
      {
        headerName: i18n.t('Amount'),
        field: 'attributes.gross_amount',
        component: 'FormattedPrice',
        minWidth: 100,
        maxWidth: 140,
        align: 'right',
        aggFunc: 'sum',

      },
      {
        headerName: i18n.t('Discount'),
        field: 'attributes.discount_amount',
        component: 'FormattedPrice',
        minWidth: 100,
        maxWidth: 140,
        align: 'right',
        aggFunc: 'sum',
      },
      {
        headerName: i18n.t('Open'),
        field: 'attributes.open_amount',
        component: 'FormattedPrice',
        minWidth: 100,
        maxWidth: 140,
        align: 'right',
        aggFunc: 'sum',
      },
      {
        headerName: i18n.t('Posted At'),
        field: 'attributes.posted_at',
        component: 'FormattedDate',
        align: 'right',
      },
    ],
    [types.PayrollBath]: [
      {
        headerName: i18n.t('Year'),
        field: 'attributes.year',
        component: 'EntityLink',
        redirectTo: '/payroll/batches/{ID}/summary',
      },
      {
        headerName: i18n.t('Period End Date'),
        field: 'attributes.period_end_date',
        component: 'FormattedDate',
      },
      {
        headerName: i18n.t('Checks Info'),
        field: 'attributes.counts.normal',
        align: 'center',
        aggFunc: 'sum',
      },
      {
        headerName: i18n.t('Payrolls'),
        field: 'attributes.counts.payrolls',
        align: 'center',
        aggFunc: 'sum',
      },
      {
        headerName: i18n.t('Employees'),
        field: 'attributes.counts.employees',
        align: 'center',
        aggFunc: 'sum',
      },
      {
        headerName: i18n.t('Gross Pay'),
        field: 'attributes.totals.gross_pay',
        component: 'FormattedPrice',
        aggFunc: 'sum',
      },
    ],
    [types.Payroll]: [
      {
        headerName: i18n.t('Employee'),
        field: 'relationships.employee.attributes.code',
        component: 'EntityLink',
        redirectTo: '/payroll/employees/{ID}/view',
        entityKey: 'attributes.employee_id',
      },
      {
        headerName: i18n.t('Rate'),
        field: 'attributes.employee_regular_rate',
        align: 'right',
        component: 'FormattedPrice',
      },
      {
        headerName: i18n.t('Start Date'),
        field: 'attributes.period_start_date',
        component: 'FormattedDate',
      },
      {
        headerName: i18n.t('End Date'),
        field: 'attributes.period_end_date',
        component: 'FormattedDate',
      },
      {
        headerName: i18n.t('Bank'),
        field: 'relationships.bank.attributes.name',
        component: 'BankLink',
      },
      {
        headerName: i18n.t('Type'),
        field: 'attributes.type',
        component: 'Status',
      },
    ],
    [types.AccountsReceivableInit]: [
      {
        headerName: i18n.t('Period'),
        field: 'attributes.period',
        minWidth: 50,
        maxWidth: 100,
      },
      {
        headerName: i18n.t('Fiscal Year'),
        field: 'attributes.fiscal_year',
        minWidth: 60,
        maxWidth: 100,
      },
      {
        headerName: i18n.t('Entries'),
        field: 'attributes.entries_count',
        minWidth: 50,
        maxWidth: 100,
      },
      {
        headerName: i18n.t('Status'),
        field: 'attributes.status',
        component: 'StatusLink',
        minWidth: 100,
        flex: 1,
      },
    ],
    default: [],
  }

  return columns[type]
}

// 'billing', +
// 'service-invoice', +
// 'transaction', +
// 'invoice-payment', +
// 'purchase-order', +
// 'invoice', +
// 'payroll-batch', +
// 'payroll', +
// ! Todo and tbd - map columns for other types

// 'billing-payment',
// 'equipment-history',
// 'job-history',
// 'material-history',
