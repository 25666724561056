<template>
  <div>
    <layout-tabs
        v-if="!onViewResourcePage"
        :items="filteredItems"
    />
    <router-view/>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        items: [
          {
            name: this.$t('Pending'),
            tooltip: this.$t('Journals that were not posted yet'),
            path: `/ledger/journal/pending`,
            permission: 'journals_show',
          },
          {
            name: this.$t('Posted'),
            tooltip: this.$t('Posted journals'),
            path: `/ledger/journal/posted`,
            permission: 'journals_show',
          },
        ],
      }
    },
    computed: {
      filteredItems() {
        return this.items.filter(item => this.$can(item.permission))
      },
    },
  }
</script>
