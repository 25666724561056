<template>
  <BaseCard class="border">
    <div class="text-sm flex flex-col space-y-4">
      <div
          :title="$t('Email')"
          class="detail-row">
        <MailIcon class="detail-icon"/>
        <BaseEmailLink :value="employeeData.email"/>
      </div>
      <div :title="$t('Phone')"
           class="detail-row">
        <PhoneIcon class="detail-icon"/>
        <BasePhoneLink :value="employeeData.phone_1"/>
      </div>
      <div :title="$t('Address')"
           class="detail-row">
        <MapPinIcon class="detail-icon"/>
        <BaseAddressLink :value="props.employee?.relationships?.address"/>
      </div>
    </div>
    <div class="flex space-x-4 border-t border-gray-100 mt-4 -mb-4"
    >
      <EmailButton :value="employeeData.email"/>
      <PhoneButton :value="employeeData.phone_1"/>
      <AddressButton :value="location"/>
    </div>
  </BaseCard>
</template>
<script lang="ts" setup>
  import { MailIcon, MapPinIcon, PhoneIcon } from 'vue-feather-icons'
  import { computed, PropType } from "vue";
  import { Employee } from "@/modules/common/types/models";
  import EmailButton from "@/modules/common/components/buttons/EmailButton.vue";
  import { locationLink } from "@/utils/utils";
  import PhoneButton from "@/modules/common/components/buttons/PhoneButton.vue";
  import AddressButton from "@/modules/common/components/buttons/AddressButton.vue";
  import Data = API.Data;

  const props = defineProps({
    employee: {
      type: Object as PropType<Data<Employee>>,
      default: () => ({}),
      required: true,
    }
  })

  const employeeData = computed<Employee>(() => {
    return props.employee?.attributes || {}
  })

  const location = computed(() => {
    return locationLink(props.employee?.relationships?.address)
  })
</script>
<style lang="scss" scoped>
  .detail-icon {
    @apply h-4 w-4 text-primary-500 min-w-[16px];
  }

  .detail-row {
    @apply flex space-x-3 items-center;
  }
</style>
