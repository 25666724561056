<template>
  <div class="direct-deposit-report">
    <BaseDataTable
        v-bind="reportTableProps"
        :url="url"
        :urlParams="urlParams"
        :columns="columns"
    >
      <template #report-header>
        <BaseReportHeader
            :header="header"
        />
      </template>
      <template #employee="{row}">
        <EmployeeLink
            :data="row.relationships?.employee"
            :show-preview="false"
        />
      </template>
      <template #attributes.direct_deposit_code_id="{row}">
        <router-link :to="`/payroll/codes/direct-deposit-codes?id=${row.attributes.direct_deposit_code_id}`">
          {{ row.relationships?.directDepositCode?.attributes?.code }}
          <template v-if="row.relationships?.directDepositCode?.attributes?.description">
            ({{ row.relationships.directDepositCode.attributes.description }})
          </template>
        </router-link>
      </template>
      <template #attributes.account_type="{row}">
        {{ $t(accountTypesAbbr[row.attributes?.account_type]) }}
      </template>
      <template #attributes.calculate_by="{row}">
        {{ $t(calculateByAbbr[row.attributes?.calculate_by]) }}
      </template>
    </BaseDataTable>
  </div>
</template>
<script lang="ts" setup>
  import i18n from '@/i18n'
  import store from '@/store'
  import { computed } from 'vue'
  import { Column } from '@/components/ag-grid/tableTypes'
  import { rateTypes } from '@/modules/payroll/components/rates/util'
  import { DirectDepositAccountTypes } from '@/modules/payroll/components/direct-deposit/directDepositUtil'

  const url = '/restify/employee-direct-deposits'

  const urlParams = {
    related: 'employee[id|code|name|paper_check_min_pay_amount|identification_code],directDepositCode[id|code|description]',
  }

  const accountTypesAbbr = {
    [DirectDepositAccountTypes.CHECKING]: 'CHK',
    [DirectDepositAccountTypes.SAVINGS]: 'SAV',
  }

  const calculateByAbbr = {
    [rateTypes.PERCENT]: '%',
    [rateTypes.AMOUNT]: '$',
  }

  const header = computed(() => {
    return {
      company: store.getters['company/getCurrentCompany'],
      title: i18n.t('Employee Direct Deposit Information'),
      code: '41.16A',
    }
  })

  const reportTableProps = {
    limitMaxHeight: false,
    compact: true,
    perPage: 500,
    ref: 'report-table',
    isReportTable: true,
    class: 'no-borders',
  }

  const columns = [
    {
      label: i18n.t('Employee'),
      prop: 'employee',
      minWidth: 150,
      maxWidth: 200,
    },
    {
      label: i18n.t('Identification <br> Code'),
      prop: 'relationships.employee.attributes.identification_code',
      minWidth: 120,
      maxWidth: 140,
    },
    {
      label: i18n.t('Minimum <br> By Check'),
      prop: 'relationships.employee.attributes.paper_check_min_pay_amount',
      minWidth: 70,
      maxWidth: 80,
      align: 'right',
    },
    {
      label: i18n.t('Prior'),
      prop: 'attributes.order',
      minWidth: 50,
      maxWidth: 50,
      align: 'center',
    },
    {
      label: i18n.t('DD Code'),
      prop: 'attributes.direct_deposit_code_id',
      minWidth: 100,
      maxWidth: 140,
    },
    {
      label: i18n.t('Type'),
      prop: 'attributes.account_type',
      minWidth: 60,
      maxWidth: 60,
      component: 'FormattedText',
      align: 'center',
    },
    {
      label: i18n.t('Trans/Route'),
      prop: 'attributes.routing_number',
      minWidth: 100,
      maxWidth: 120,
    },
    {
      label: i18n.t('Bank Account'),
      prop: 'attributes.account_number',
      minWidth: 100,
      maxWidth: 140,
    },
    {
      label: i18n.t('Calc'),
      prop: 'attributes.calculate_by',
      component: 'FormattedText',
      minWidth: 40,
      maxWidth: 40,
      align: 'center',
    },
    {
      label: i18n.t('Amount'),
      prop: 'attributes.amount',
      minWidth: 80,
      maxWidth: 120,
      align: 'right',
    },
  ] as Column[]
</script>
<style lang="scss">
  .direct-deposit-report {
    .report-header {
      min-width: 1000px;
      max-width: 100% !important;
    }
  }
</style>
