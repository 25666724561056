<template>
  <div class="mt-10 sm:mt-0">
    <ValidationObserver v-slot="{ handleSubmit }">
      <base-form :title="$t('Edit General Ledger Account')"
                 :loading="loading"
                 :showCancel="showCancel"
                 layout="modal"
                 @cancel="$emit('cancel')"
                 @submit="handleSubmit(onSubmit)"
      >
        <div :class="gridClasses">
          <base-input v-model="model.name"
                      :label="$t('Name')"
                      :placeholder="$t('Cost Type name')"
                      id="name"
                      rules="required|max:20">
          </base-input>
        </div>
        <div :class="gridClasses">
          <base-input v-model="model.abbr"
                      :readonly="model.readonly"
                      :label="$t('Abb Name')"
                      :placeholder="$t('Abbreviated Name')"
                      id="abbr"
                      rules="required|max:20">
          </base-input>
        </div>
        <div :class="gridClasses">
          <base-select v-model="model.type"
                       :label="$t('Type')"
                       :disabled="model.readonly"
                       :placeholder="$t('Type')"
                       :options="costTypes"
                       id="type">
          </base-select>
        </div>
        <div v-if="model.type === COST_TYPE"
             class="col-span-6 md:col-span-3">
          <base-input v-model="model.markup_percent"
                      :label="$t('Markup')"
                      :placeholder="$t('Markup')"
                      :min="0"
                      :max="1000"
                      :step="0.000"
                      id="abbr"
                      type="number"
                      rules="min_value:0|max_value:100">
            <template v-slot:suffix>%</template>
          </base-input>
        </div>
        <template v-if="model.type === 'cost'">
          <Cost :model="model"
                :key="model.type"/>
        </template>
        <template v-if="model.type === 'income'">
          <Income :model="model"
                  :key="model.type"/>
        </template>
      </base-form>
    </ValidationObserver>
  </div>
</template>
<script>
  import axios from 'axios'
  import Cost from '@/modules/job-costing/components/costing-types/Cost'
  import Income from '@/modules/job-costing/components/costing-types/Income'

  const COST_TYPE = 'cost'

  export default {
    components: {
      Cost,
      Income,
    },
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        COST_TYPE,
        loading: false,
        showCancel: true,
        model: {
          name: '',
          abbr: '',
          type: COST_TYPE,
          credit_account: undefined,
          credit_subaccount: undefined,
          debit_account: undefined,
          debit_subaccount: undefined,
          sub_type: 'expense',
          markup_percent: 0,
        },
        costTypes: [
          {
            value: 'cost',
            label: this.$t('Cost'),
          },
          {
            value: 'income',
            label: this.$t('Income'),
          },
        ],
      }
    },
    computed: {
      gridClasses() {
        if (this.model.type === COST_TYPE) {
          return 'col-span-6 md:col-span-3'
        }
        return 'col-span-6 md:col-span-2'
      },
    },
    methods: {
      async onSubmit() {
        try {
          this.loading = true
          if (this.model.id) {
            await axios.put(`/restify/job-types/${this.model.id}`, this.model)
          } else {
            await axios.post(`/restify/job-types/bulk`, [this.model])
          }
          await this.$store.dispatch('globalLists/getJCConfiguration')
          this.$success(this.$t('Job Cost type updated'))
          this.$emit('save', this.model)
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not update the job cost type'))
        } finally {
          this.loading = false
        }
      },
    },
    watch: {
      data: {
        immediate: true,
        handler(value) {
          this.model = {
            ...this.model,
            ...value,
          }
        },
      },
    },
  }
</script>
