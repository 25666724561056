<template>
  <base-alert
      v-show="showWarning"
      class="mb-6"
      type="warning"
  >
      <span v-html="getWarningMessage"/>
  </base-alert>
</template>
<script setup>
  import { computed } from 'vue'
  import store from '@/store'

  const props = defineProps({
    period: [String, Number],
    fiscalYear: [String, Number],
  })

  const currentPeriodValue = computed(() => {
    return store.getters['company/getCurrentPeriodValue']
  })
  const acceptablePeriodsNumberAhead = 2

  const currentFiscalYear = computed(() => {
    return store.state.company.currentFiscalYear
  })

  const showYearWarning = () => props.fiscalYear !== currentFiscalYear.value
  const showPeriodWarning = () => props.period >= (currentPeriodValue.value + acceptablePeriodsNumberAhead)

  const showWarning = computed(() => {
    return showPeriodWarning() || showYearWarning()
  })

  const getWarningMessage = computed(() => {
    if (showYearWarning() && showPeriodWarning()) {
      return `You're trying to post to fiscal year <b>${props.fiscalYear}</b> and period <b>${getPeriodName(props.period)}</b>.<br> The current fiscal year is <b>${currentFiscalYear.value}</b> and the current period is <b>${getPeriodName(currentPeriodValue.value)}</b>. Are you sure ?`
    }
    if (showYearWarning()) {
      return `You're trying to post to fiscal year <b>${props.fiscalYear}</b>. The current fiscal year is <b>${currentFiscalYear.value}</b>. Are you sure ?`
    }
    if (showPeriodWarning()) {
      return `You're trying to post to period <b>${getPeriodName(props.period)}</b>. The current period is ${getPeriodName(currentPeriodValue.value)}. Are you sure ?`
    }
  })

  function getPeriodName(period) {
    return store.getters['company/getPeriodName'](period)
  }

</script>
