<template>
	<div class="truncate">
		<router-link
			v-if="rate.id && !editableCell"
			:to="rateLink"
			:class="$attrs.class"
			:target="target"
		>
      <span>
        {{ valueToDisplay }}
      </span>
		</router-link>
		<template v-else>
			{{ valueToDisplay }}
		</template>
	</div>
</template>
<script lang="ts" setup>
import store from '@/store'
import { computed } from 'vue'
import { codeTypes, ratesSourceMap } from '@/modules/payroll/components/rates/util'

const props = defineProps({
	id: {
		type: String,
	},
	showDescription: {
		type: Boolean,
		default: true,
	},
	params: {
		type: Object,
		default: () => ({}),
	},
	target: String,
	editableCell: Boolean,
})

const rate = computed(() => {
	const { source_id, source_type } = props.params?.data
	const resourceName = ratesSourceMap[source_type] || ratesSourceMap.default
	const resourceList = store.getters['globalLists/getResourceList'](resourceName)
	// @ts-ignore
	return resourceList.find(resource => resource.id === source_id) || {}
})

const valueToDisplay = computed(() => {
	if (!props.id) {
		return ''
	}
	const { code, description } = rate.value
	if (!props.showDescription) {
		return code
	}
	return `${code} (${description})`
})

const rateLink = computed(() => {
	const { source_id, source_type } = props.params?.data

	const urlMap = {
		[codeTypes.PAY]: `/payroll/codes/pay-codes?id=${source_id}`,
		[codeTypes.DEDUCTION]: `/payroll/codes/deduction-codes?id=${source_id}`,
		[codeTypes.BEN]: `/payroll/codes/benefit-codes?id=${source_id}`,
		[codeTypes.TAX]: '/payroll/codes/tax-codes',
		[codeTypes.UNION]: `payroll/rates/unions?id=${source_id}`,
	}

	return urlMap[source_type] || ''
})
</script>
