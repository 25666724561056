<template>
  <AgDataTable
      :columns="columns"
      :url="url"
      :url-params="finalUrlParams"
      :add-entity-in-new-tab="addEntityInNewTab"
      :add-entity-url-query="addEntityUrlQuery"
      :default-match-filters="defaultMatchFilters"
      :add-text="$t('New job')"
      :delete-title="$t('Delete job')"
      :delete-description="deleteDescription"
      :enableFillHandle="true"
      :enableRangeSelection="true"
      :disable-col-flex="true"
      entity="jobs"
      :import-url="importUrl"
      default-sort="-updated_at"
      actions="search,refresh,view,import,export,print"
      ref="table"
      permission="jobs"
      @add="$router.push('/job-costing/jobs/add')"
  >
    <template #extra-actions>

    </template>
    <template v-slot:attributes.number="{row}">
      <JobLink :data="row" :label="row.attributes.number"/>
    </template>
    <template v-slot:relationships.customer.attributes.code="{row}">
      <template v-if="row.attributes.customer_id">
        <CustomerLink :data="get(row, 'relationships.customer.attributes')" :show-code="false"/>
      </template>
      <template v-else>

      </template>
    </template>
    <template v-slot:relationships.salesPerson.attributes.code="{row}">
      <template v-if="row.attributes.sales_person_id">
        <EmployeeLink :data="get(row, 'relationships.salesPerson.attributes')" :show-name="false"/>
      </template>
      <template v-else>

      </template>
    </template>
  </AgDataTable>
</template>
<script>
import { entityPreviewFields } from '@/modules/common/components/entity-preview/entities'
import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";
import { cellClasses } from "@/components/ag-grid/columnUtils";
import { valueSetterWithUpdate } from "@/components/ag-grid/cellEditors/cellEditorUtils";
import { jobStatusOptions } from "@/modules/job-costing/enum/jobs";

export default {
  props: {
    urlParams: {
      type: Object,
      default: () => {
        return {
        }
      },
    },
    customerId: {
      type: String,
    }
  },
  data() {
    return {
      url: '/restify/jobs',
      addEntityInNewTab: true,
    }
  },
  computed: {
    importUrl() {
      if (this.customerId) {
        return `/job-costing/jobs/import?customer_id=${this.customerId}`
      }
      return `/job-costing/jobs/import`
    },
    addEntityUrlQuery() {
      if (this.customerId) {
        return `/job-costing/jobs/add?customer_id=${this.customerId}`
      }
      return `/job-costing/jobs/add`
    },
    finalUrlParams() {
      return {
        related: `addresses,category[id|code|description],${entityPreviewFields.Customer},${entityPreviewFields.SalesPerson}`,
        customer_id: this.customerId,
        ...(this.urlParams || {}),
      }
    },
    columns() {
      return [
        {
          label: this.$t('Job'),
          prop: 'attributes.number',
          minWidth: 80,
          maxWidth: 200,
        },
        {
          label: this.$t('Description'),
          prop: 'attributes.description',
          minWidth: 150,
          maxWidth: 350,
          headerClass: cellClasses.HeaderEditable,
          editable: true,
          valueSetter: (params) => {
            return valueSetterWithUpdate({
              storeAction: 'jobCosting/patchJob',
            })(params)
          },
        },
        {
          label: this.$t('Status'),
          prop: 'attributes.status',
          minWidth: 100,
          maxWidth: 120,
          component: 'Status',
          editable: true,
          cellEditor: cellEditors.Status,
          cellEditorParams: {
            options: jobStatusOptions,
          },
          headerClass: cellClasses.HeaderEditable,
          valueSetter: (params) => {
            return valueSetterWithUpdate({
              storeAction: 'jobCosting/patchJob',
            })(params)
          },
        },
        {
          label: this.$t('Address'),
          prop: 'addresses',
          minWidth: 100,
          maxWidth: 240,
          component: 'PrimaryAddressLink',
        },
        {
          label: this.$t('Customer'),
          prop: 'relationships.customer.attributes.code',
          minWidth: 100,
          maxWidth: 250,
        },
        {
          label: this.$t('Salesperson'),
          prop: 'relationships.salesPerson.attributes.code',
          minWidth: 100,
          maxWidth: 250,
        },
        {
          label: this.$t('Category'),
          prop: 'relationships.category.attributes.code',
          minWidth: 100,
          maxWidth: 200,
          hide: true,
        },
        {
          label: this.$t('Updated At'),
          prop: 'attributes.updated_at',
          component: 'FormattedDate',
          minWidth: 100,
          maxWidth: 200,
        },
      ]
    },
    defaultMatchFilters() {
      return this.jobStatusFilter
    },
    deleteDescription() {
      return this.$t(`
        <div class="prose">
          <p>By deleting this job, the following information will be also deleted:</p>
          <ul>
            <li>Job line items</li>
            <li>Line item budgets</li>
          </ul>
          <p>Are you sure you want to delete this job ?</p>
          </div>
`
      )
    }
  },
}
</script>
