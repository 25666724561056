<template>
  <div>
    <AgDataTable
        v-bind="editableTableProps"
        :actions="readOnly ? '' : 'add'"
        :columns="columns"
        :data-loading="loading"
        :add-text="$t('New rate')"
        :get-empty-row="getEmptyEntry"
        :read-only="readOnly"
        domLayout="autoHeight"
        ref="gridTable"
        @grid-ready="onGridReady"
    />
  </div>
</template>
<script lang="ts">
  import axios from 'axios'
  import { defineComponent } from 'vue'
  import { billingRateSources } from '@/enum/enums'
  import { Column } from '@/components/ag-grid/tableTypes'
  import { globalResources } from '@/components/form/util'
  import { cellEditors } from '@/components/ag-grid/cellEditors/cellEditors'
  import { getDeleteColumn } from '@/components/ag-grid/columns/deleteColumns'
  import { editableTableProps, getTableData } from '@/components/ag-grid/tableUtils'
  import { cellClasses, requiredValueSetter } from '@/components/ag-grid/columnUtils'
  import { fillRateOverrides } from '@/modules/accounts-receivable/pages/billing-rates/billingRates'
  import { GridReadyEvent, SuppressKeyboardEventParams, ValueSetterParams } from '@ag-grid-community/core'

  const defaultRates = {
    premium_rate: 0,
    regular_rate: 0,
    overtime_rate: 0,
  }

  export default defineComponent({
    props: {
      data: {
        type: Array,
        default: () => [],
      },
      billingTypes: {
        type: Array,
        default: () => [],
      },
      sourceType: {
        type: String,
        default: billingRateSources.Material,
      },
      readOnly: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        grid: null as GridReadyEvent | null,
        editableTableProps,
        loading: false as boolean,
        entries: [] as any[],
        entriesToDelete: [] as any[],
      }
    },
    computed: {
      columns() {
        let result = [
          {
            headerName: this.$t('Material'),
            field: 'source_id',
            component: 'MaterialLink',
            cellEditor: cellEditors.GlobalResourceSelect,
            cellEditorParams: {
              resourceName: globalResources.Materials,
              target: '_blank',
              stopNavigationOnChange: true,
              onValueChanged: this.onChangeMaterial,
            },
            valueSetter: (params: ValueSetterParams) => {
              params.data.source_id = params.newValue
              this.setupBillingRates(params.newValue, params)
              return true
            },
            hide: this.sourceType !== billingRateSources.Material,
            editable: true,
          },
          {
            headerName: this.$t('UM'),
            field: 'um',
            suppressNavigable: true,
            cellClass: cellClasses.ReadOnlyLight,
            hide: this.sourceType !== billingRateSources.Material,
          },
          {
            headerName: this.$t('Standard Rate'),
            field: 'standard_unit_price',
            cellClass: cellClasses.ReadOnlyLight,
            suppressNavigable: true,
            hide: this.sourceType !== billingRateSources.Material,
          },
          {
            headerName: this.$t('Equipment'),
            field: 'source_id',
            component: 'EquipmentLink',
            cellEditor: cellEditors.GlobalResourceSelect,
            cellEditorParams: {
              resourceName: globalResources.Equipments,
              target: '_blank',
              stopNavigationOnChange: true,
              onValueChanged: (params, _, equipment) => {
                params.data.standard_job_cost_hourly_rate = equipment.standard_job_cost_hourly_rate
              },
            },
            valueSetter: (params: ValueSetterParams) => {
              params.data.source_id = params.newValue
              this.setupBillingRates(params.newValue, params)
              return true
            },
            hide: this.sourceType !== billingRateSources.Equipment,
            editable: true,
          },
          {
            headerName: this.$t('Standard Rate'),
            field: 'standard_job_cost_hourly_rate',
            readOnly: true,
            className: 'p-x-3 disabled',
            type: 'numeric',
            // numericFormat: this.$dt.priceFormat,
            hide: this.sourceType !== billingRateSources.Equipment,
          },
          {
            headerName: this.$t('Craft Code'),
            field: 'source_id',
            component: 'CraftCodeLink',
            cellEditor: cellEditors.GlobalResourceSelect,
            cellEditorParams: {
              resourceName: globalResources.CraftCodes,
              target: '_blank',
              stopNavigationOnChange: true,
            },
            valueSetter: (params: ValueSetterParams) => {
              params.data.source_id = params.newValue
              this.setupBillingRates(params.newValue, params)
              return true
            },
            hide: this.sourceType !== billingRateSources.CraftCode,
            editable: true,
          },
        ]
        if (this.sourceType !== billingRateSources.CraftCode) {
          this.billingTypes.forEach((type, index) => {
            const field = `billing_rates.${ index }.regular_rate`
            result.push(
                {
                  headerName: this.$t(type.title),
                  field,
                  editable: true,
                  component: 'FormattedPrice',
                  cellEditor: cellEditors.Numeric,
                  valueSetter: (params: ValueSetterParams) => requiredValueSetter(params, 0),
                  suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
                    let isTabKey = params.event.key === 'Tab'
                    if (isTabKey) {
                      params.api.stopEditing()
                    }
                  },
                },
            )
          })
        } else {
          for (let i = 0; i < this.billingTypes.length; i++) {
            const regularRateField = `billing_rates.${ i }.regular_rate`
            const overtimeRateField = `billing_rates.${ i }.overtime_rate`
            const premiumRateField = `billing_rates.${ i }.premium_rate`

            result.push(
                {
                  headerName: this.$t(`#${ i + 1 } Regular`),
                  field: regularRateField,
                  editable: true,
                  component: 'FormattedPrice',
                  cellEditor: cellEditors.Numeric,
                  valueSetter: (params: ValueSetterParams) => requiredValueSetter(params, 0),
                },
                {
                  headerName: this.$t(`#${ i + 1 } Overtime`),
                  field: overtimeRateField,
                  component: 'FormattedPrice',
                  cellEditor: cellEditors.Numeric,
                  valueSetter: (params: ValueSetterParams) => requiredValueSetter(params, 0),
                },
                {
                  headerName: this.$t(`#${ i + 1 } Premium`),
                  field: premiumRateField,
                  component: 'FormattedPrice',
                  cellEditor: cellEditors.Numeric,
                  valueSetter: (params: ValueSetterParams) => requiredValueSetter(params, 0),
                },
            )
          }
        }
        if (this.readOnly) {
          return result
        }
        return [
          ...result,
          {
            ...getDeleteColumn({
              url: '',
              onConfirm: this.onDelete,
            }),
          },
        ] as Column[]
      },
    },
    methods: {
      onGridReady(params: GridReadyEvent) {
        this.grid = params
        if (this.data.length) {
          this.setGridData(this.data)
        }
      },
      mapAllRateTypes() {
        return this.billingTypes.map(type => {
          return {
            ...type,
            ...defaultRates,
          }
        })
      },
      getEmptyEntry() {
        const billing_rates = this.mapAllRateTypes()
        return {
            _localId: crypto.randomUUID(),
          billing_rates,
          um: '',
          source_id: undefined,
          standard_unit_price: 0,
          standard_job_cost_hourly_rate: 0,
        }
      },
      async setupBillingRates(id, params) {
        if (!id) {
          return
        }

        try {
          params.data.loading = true
          const {data} = await axios.get(`/restify/billing-rates?search=${ id }`)
          const formattedRates = data.map(r => r.attributes)

          params.data['billing_rates'] = fillRateOverrides(formattedRates)
          params.data.loading = false
          params.node.setData(params.data)

        } catch (err: any) {
          console.warn(err)
        }
      },
      onChangeMaterial(params, _, material) {
        params.data.um = material.um
        params.data.standard_unit_rate = material.standard_unit_price
      },
      getData() {
        const gridApi = this.grid?.api
        return getTableData(gridApi) || []
      },
      removeEntry(entryIndex) {
        const entry = this.getData[entryIndex]
        if (entry?.id) {
          const {billing_rates} = entry
          const rateIds = billing_rates.map(rate => rate.id)
          this.entriesToDelete.push(...rateIds)
        }
      },
      async setGridData(data) {
        await this.$nextTick()
        this.grid?.api?.setRowData(data)
      },
      refreshData() {
        this.$refs.gridTable?.refresh()
      },
    },
    watch: {
      data: {
        immediate: true,
        handler(value) {
          if (!value.length) {
            return
          }
          this.setGridData(value)
        },
      },
    },
  })
</script>
