<template>
  <div class="grid grid-cols-5 gap-x-2 print:text-print">
    <span class="tracking-wide font-medium text-sm print:text-print leading-normal text-gray-400 mr-2 mb-2">
      {{ title || $t('Vendor') }}
    </span>
    <div class="col-span-4 border-l border-gray-200 pl-2">
      <entity-preview :entity="vendor"
                      :key="`preview-${vendor.id}`"
                      entity-name="vendor"
                      :url="`/accounts-payable/vendors/${vendor.id}/view`"
                      :valueToDisplay="vendor.name"
                      :parent-entity-id="vendor.id"
                      link-class="font-semibold tracking-wider text-base print:text-print text-gray-900"
      />
      <div class="leading-tight tracking-wide space-y-2 text-sm print:text-print mt-2">
        <base-address-link v-if="vendorAddress" :value="vendorAddress"/>
        <div>
          {{vendor.contact || $t('No contact provided')}}
        </div>
        <base-email-link :value="vendor.email"/>
        <base-phone-link :value="vendor.phone"/>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      vendor: {
        type: Object,
        default: () => ({})
      },
      title: {
        type: String,
      },
    },
    computed: {
      vendorAddress() {
        const { address } = this.vendor
        if (!address) {
          return ''
        }
        return address
      }
    }
  }
</script>
