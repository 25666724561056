import axios from "axios";

class OrganizationService {
  async getOrganization() {
    const { data } = await axios.get(`/organization`, {
      params: {
        related: 'addresses'
      }
    });
    return data
  }

  async updateOrganization(data) {
    return axios.put(`/organization`, data);
  }
}

export default new OrganizationService();
