<template>
  <div class="import-data mt-4">
    <ImportForm
        v-bind="importSettings"
        @success="redirectToList"
    />
  </div>
</template>
<script>
  import ImportForm from '@/modules/common/components/import/ImportForm';
  import { getInitialMapping } from '@/modules/payroll/utils/timecardImportColumns';

  const colMappings = {
    Code: {
      value: 'code',
      label: 'Code',
      alternativeLabels: ['Benefit Code'],
      required: true,
    },
    Description: {
      value: 'description',
      label: 'Description',
    },
    CalculateBy: {
      value: 'calculate_by',
      label: 'Calculate By',
      alternativeLabels: ['Calculated', 'Calculate By'],
      required: true,
    },
    MultiplyBy: {
      value: 'multiply_by',
      label: 'Multiply By',
      alternativeLabels: ['Multiplied', 'Multiplied By'],
      required: true,
    },
    ExpenseAccount: {
      value: 'expense_account',
      label: 'Expense Account',
      alternativeLabels: [],
    },
    ExpenseSubAccount: {
      value: 'expense_subaccount',
      label: 'Expense SubAccount',
      alternativeLabels: [],
    },
    LiabilityAccount: {
      value: 'liability_account',
      label: 'Liability Account',
      alternativeLabels: [],
    },
    LiabilitySubAccount: {
      value: 'liability_subaccount',
      label: 'Liability SubAccount',
      alternativeLabels: [],
    },
    IncludeOnCertifiedPayrollReport: {
      value: 'include_on_certified_payroll_report',
      label: 'Include On Cert Payroll',
      alternativeLabels: ['Include On Certified Payroll Report'],
    },
    IncludeOnUnionReports: {
      value: 'include_on_union_reports',
      label: 'Include On Union Report',
      alternativeLabels: ['Include On Union Reports'],
    },
    SubjectToFica: {
      value: 'subject_to_fica',
      label: 'Fica',
      alternativeLabels: ['Subject To Fica'],
    },
    SubjectToFit: {
      value: 'subject_to_fit',
      label: 'Fit',
      alternativeLabels: ['Subject To Fit'],
    },
    SubjectToFui: {
      value: 'subject_to_fui',
      label: 'Fui',
      alternativeLabels: ['Subject To Fui'],
    },
    SubjectToLiabilityInsurance: {
      value: 'subject_to_liability_insurance',
      label: 'Subject To Liability Insurance',
      alternativeLabels: ['Gen Liab'],
    },
    SubjectToLit: {
      value: 'subject_to_lit',
      label: 'Lit',
      alternativeLabels: ['Subject To Lit'],
    },
    SubjectToSdi: {
      value: 'subject_to_sdi',
      label: 'Sdi',
      alternativeLabels: ['Subject To Sdi'],
    },
    SubjectToSit: {
      value: 'subject_to_sit',
      label: 'Sit',
      alternativeLabels: ['Subject To Sit'],
    },
    SubjectToSui: {
      value: 'subject_to_sui',
      label: 'Sui',
      alternativeLabels: ['Subject To Sui'],
    },
    SubjectToWorkersComp: {
      value: 'subject_to_workers_comp',
      label: 'Subject To Workers Comp',
      alternativeLabels: ['WrkCmp'],
    },
    UseEmployeeRegularRate: {
      value: 'use_employee_regular_rate',
      label: 'Use RegPay',
      alternativeLabels: ['Use Employee Regular Rate'],
    },
    IsDisposableEarning: {
      value: 'is_disposable_earning',
      label: 'Disposable Earning',
      alternativeLabels: ['Is Disposable Earning'],
    },
    TreatUnitsAsRegularHours: {
      value: 'treat_units_as_regular_hours',
      label: 'Treat Units As Regular Hours',
      alternativeLabels: [],
    }
  }

  const options = [
    colMappings.Code,
    colMappings.Description,
    colMappings.CalculateBy,
    colMappings.MultiplyBy,
    colMappings.ExpenseAccount,
    colMappings.ExpenseSubAccount,
    colMappings.LiabilityAccount,
    colMappings.LiabilitySubAccount,
    colMappings.IncludeOnCertifiedPayrollReport,
    colMappings.IncludeOnUnionReports,
    colMappings.SubjectToFica,
    colMappings.SubjectToFit,
    colMappings.SubjectToFui,
    colMappings.SubjectToLiabilityInsurance,
    colMappings.SubjectToLit,
    colMappings.SubjectToSdi,
    colMappings.SubjectToSit,
    colMappings.SubjectToSui,
    colMappings.SubjectToWorkersComp,
    colMappings.UseEmployeeRegularRate,
    colMappings.TreatUnitsAsRegularHours,
    colMappings.IsDisposableEarning,
  ]

  export default {
    components: {
      ImportForm,
    },
    data() {
      return {
        importSettings: {
          url: '/restify/pay-codes/import',
          columnOptions: options,
          columnMappings: colMappings,
          initialMapping: getInitialMapping(options),
          type: 'pay-code-import',
          resource: 'pay-codes',
        },
      }
    },
    methods: {
      async redirectToList() {
        await this.$router.push('/payroll/codes/pay-codes')
      },
    },
  }
</script>
