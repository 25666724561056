<template>
  <BaseFormDialog
    v-bind="$attrs"
    v-on="$listeners"
    :title="$t('Change Journal Posting')"
    :appendToBody="true"
    size="sm"
  >
    <BaseForm
      :saveText="$t('Change')"
      :showCancel="true"
      :loading="loading"
      layout="modal"
      @submit="onSubmit"
      @cancel="$emit('close')"
    >
      <div class="col-span-full mt-2">
        <h5 class="form-section-title">
          {{ $t('From') }}
        </h5>
      </div>
      <FiscalYearSelect
        :value="journal.fiscal_year"
        disabled
        :showClosedYears="true"
        :label="$t('Fiscal Year')"
        class="col-span-full md:col-span-3"
        id="fiscal_year"
      />
      <PeriodSelect
        :value="journal.period"
        disabled
        :placeholder="$t('Period')"
        :allPeriods="true"
        class="col-span-full md:col-span-3"
        id="period"
      />

      <div class="col-span-full mt-2">
        <h5 class="form-section-title">
          {{ $t('To') }}
        </h5>
      </div>
      <FiscalYearSelect
        v-model="model.fiscal_year"
        :showClosedYears="true"
        :disableClosedYears="true"
        :label="$t('Fiscal Year')"
        class="col-span-full md:col-span-3"
        id="fiscal_year"
      />
      <PeriodSelect
        v-model="model.period"
        :placeholder="$t('Period')"
        :allPeriods="showAllPeriods"
        class="col-span-full md:col-span-3"
        id="period"
      />
      <BaseAlert
        v-if="warningMessage"
        :type="$promptType.Warning"
        class="col-span-full"
      >
        <span v-html="warningMessage" />
      </BaseAlert>
    </BaseForm>
  </BaseFormDialog>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'
import axios from 'axios'
import { Journal } from '@/modules/common/types/models'

export default defineComponent({
  props: {
    journal: {
      type: Object as PropType<Journal>,
      required: true,
    },
    showAllPeriods: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      model: {
        fiscal_year: this.journal?.fiscal_year,
        period: this.journal?.period,
      },
    }
  },
  computed: {
    allFiscalYears() {
      return this.$store.state.company.fiscalYears || []
    },
    fromFiscalYear() {
      return this.allFiscalYears.find((fy: any) => fy.attributes.fiscal_year == this.journal?.fiscal_year) || {}
    },
    toFiscalYear() {
      return this.allFiscalYears.find((fy: any) => fy.attributes.fiscal_year == this.model.fiscal_year) || {}
    },
    warningMessage() {
      if (this.model.fiscal_year == this.journal?.fiscal_year) {
        return ''
      }

      if (this.fromFiscalYear?.attributes?.balances_transferred) {
        // @ts-ignore
        return this.$tc('change posting - from fiscal year has balances', null, {
          fromFiscalYear: this.journal.fiscal_year,
          toFiscalYear: this.model.fiscal_year,
        })
      }
      else if (this.toFiscalYear?.attributes?.balances_transferred) {
        // @ts-ignore
        return this.$tc('change posting - to fiscal year has balances', null, {
          fromFiscalYear: this.journal.fiscal_year,
          toFiscalYear: this.model.fiscal_year,
        })
      }

      return ''
    },
  },
  methods: {
    async onSubmit() {
      try {
        this.loading = true
        const url = `/restify/journals/${this.journal.id}/actions?action=change-journal-posting`
        await axios.post(url, this.model)
        this.$success(this.$t('Journal posting has been changed successfully.'))
        await this.$store.dispatch('generalLedger/getJournal', this.journal.id)
        this.$emit('close')
      }
      catch (err: any) {
        if (err.handled) {
          return
        }
        this.$error(this.$t('Something went wrong please try again.'))
      }
      finally {
        this.loading = false
      }
    },
  },
  watch: {
    journal: {
      handler() {
        this.model.fiscal_year = this.journal?.fiscal_year
        this.model.period = this.journal?.period
      },
      immediate: true,
    },
  }
})
</script>
