<template>
    <div class="menu-item mt-1 group flex items-center py-2 text-sm leading-5 font-medium rounded-md focus:outline-none focus:text-white transition ease-in-out duration-150"
         :class="{
            'text-gray-300 hover:text-white hover:bg-gray-700 focus:bg-gray-700': theme === 'dark',
            'text-gray-600 hover:text-gray-900 hover:bg-gray-50 focus:bg-gray-50': theme === 'white',
            'text-primary-200 hover:text-white hover:bg-primary-700 focus:bg-primary-700': theme === 'primary',
        }"
         @click.prevent.stop="toggleDialog">
        <button class="flex items-center px-2 p-1 focus:outline-none">
            <SearchIcon class="mr-3 h-6 w-6 transition ease-in-out duration-150"
                        :class="{
                            'text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300': theme === 'dark',
                            'text-gray-600 group-hover:text-gray-900 group-focus:text-gray-900': theme === 'white',
                            'text-primary-200 group-hover:text-primary-300 group-focus:text-primary-300': theme === 'primary',
                        }"
            ></SearchIcon>
            <span class="">{{$t('Quick Find')}}</span>
        </button>
        <base-dialog v-if="showDialog"
                     :visible.sync="showDialog"
                     :show-close="false"
                     append-to-body
                     class="search-dialog"
        >
            <div class="search-dialog-input__wrapper">
                <SearchIcon v-if="!loading" class="w-6 h-6 mr-2 text-grey-dark"></SearchIcon>
                <span v-if="loading" class="flex items-center w-6 mr-2">
                    <i class="el-icon-loading text-xl text-grey-dark"></i>
                </span>
                <input class="search-dialog__input"
                       v-model="searchQuery"
                       :placeholder="$t('Search for content...')"
                       type="text"
                       ref="searchInput"
                       @keydown.down.prevent="focusOnOption(0)"
                >
            </div>
            <div class="search-results">
                <div v-for="(item, index) in searchResults"
                     :key="item.name"
                     tabindex="0"
                     :ref="`result-${index}`"
                     class="search-result__item"
                     :class="{'mb-3': index === searchResults.length - 1}"
                     @keydown.down.prevent="onKeyArrowDown"
                     @keydown.up.prevent="onKeyArrowUp"
                     @click="navigateToItem(item)"
                     @keydown.enter="navigateToItem(item)"
                >
                    <div v-html="highlightResult(item.name)"></div>
                    <div class="text-sm" v-html="highlightResult(item.description)"></div>
                </div>


            </div>
        </base-dialog>
    </div>
</template>
<script>
  import get from 'lodash/get'
  import { Tag } from 'element-ui'
  import { SearchIcon } from 'vue-feather-icons'
  import BaseDialog from "@/components/common/BaseDialog";

  export default {
    components: {
      SearchIcon,
      BaseDialog,
      [Tag.name]: Tag,
    },
    props: {
      theme: {
        type: String,
        default: 'white'
      },
    },
    data() {
      return {
        showDialog: false,
        loading: false,
        searchQuery: '',
        searchResults: [],
        focusedOptionIndex: 0,
      }
    },
    computed: {
      resultsCount() {
        return this.searchResults.length
      },
    },
    watch: {
      searchQuery(query) {
        this.onSearchChange(query)
      },
    },
    mounted() {
      this.initKeyboardListeners()
    },
    methods: {
      initKeyboardListeners() {
        document.body.addEventListener('keydown', event => {
          if (this.showDialog) {
            return
          }
          if (event.target && event.target.nodeName === 'INPUT') {
            return
          }
          if (event.ctrlKey && event.key === 'k') {
            this.toggleDialog()
          }
        })
      },
      highlightResult(name = '') {
        const characters = get(this.searchQuery, 'length', 0)
        let query = (this.searchQuery || '').toLowerCase()
        const characterStart = name.toLowerCase().indexOf(query)
        if (characterStart === -1) {
          return name
        }

        const searchedString = name.substr(characterStart, characters)
        return name.substr(0, characterStart) +
          `<span class="highlight">${searchedString}</span>` +
          name.substr(characterStart + characters, name.length)
      },
      routeSearchFilter(query, route) {
        if (!query) {
          return true
        }
        let { title, description } = route
        title = this.$t(title || '')
        description = this.$t(description || '')
        return title.toLowerCase().includes(query) || description.toLowerCase().includes(query)
      },
      async onSearchChange(query) {
        let searchResults = []
        if (!query) {
          this.searchResults = searchResults
        }
        this.$emit('change', query)
        // TODO Integrate api call for search
        try {
          this.loading = true

          if (!query) {
            searchResults = []
          } else {
            searchResults = []
          }
          this.searchResults = searchResults
        } catch (e) {
          this.searchResults = searchResults
        } finally {
          this.loading = false
        }
      },
      toggleDialog() {
        this.showDialog = !this.showDialog
        this.$nextTick(() => {
          if (!this.$refs.searchInput) {
            return
          }
          this.$refs.searchInput.focus()
        })
      },
      onKeyArrowDown() {
        if (this.focusedOptionIndex < this.resultsCount - 1) {
          this.focusedOptionIndex++
        } else {
          this.focusedOptionIndex = 0
        }
        this.focusOnOption(this.focusedOptionIndex)
      },
      onKeyArrowUp() {
        if (this.focusedOptionIndex > 0) {
          this.focusedOptionIndex--
        } else {
          this.focusedOptionIndex = this.resultsCount - 1
        }
        this.focusOnOption(this.focusedOptionIndex)
      },
      focusOnOption(index) {
        if (!this.resultsCount) {
          return
        }
        this.$nextTick(() => {
          const ref = get(this.$refs[`result-${index}`], '[0]')
          if (!ref) {
            return
          }
          ref.focus()
          if (this.focusedOptionIndex !== index) {
            this.focusedOptionIndex = index
          }
        })
      },
      navigateToItem(item) {
        this.$router.push(item)
        this.showDialog = false
        this.searchQuery = ''
      }
    }
  }
</script>
<style lang="scss">
    .search-dialog {
        .el-dialog {
            margin-top: 5vh !important;
            @screen md {
                margin-top: 10vh !important;
            }
        }

        .el-dialog__body,
        .el-dialog__header {
            @apply p-0;
        }

        .search-dialog-input__wrapper {
            height: 50px;
            @apply px-4 border-b border-gray-100 flex items-center;
        }

        .search-dialog__input {
            height: 28px;
            width: calc(100% - 55px);
            @apply border-none text-lg ring-0 shadow-none;
            &:focus,
            &:hover,
            &:active {
                @apply shadow-none border-none;
            }

            &::placeholder {
                @apply text-gray-700;
            }
        }

        .el-dialog__headerbtn {
            top: 16px;
            right: 16px;

            .close-icon {
                @apply text-gray-600;
            }
        }

        .search-results {
            max-height: calc(100vh - 10vh);
            @apply flex flex-col overflow-y-auto;
            @screen md {
                max-height: 400px;
            }
        }

        .search-result__item {
            @apply py-3 px-4 flex flex-col justify-center cursor-pointer text-base font-medium;
            &.selected,
            &:focus,
            &:hover {
                @apply bg-gray-100 outline-none;
            }

            .highlight {
                @apply text-primary-500;
            }
        }
    }
</style>
