<template>
  <ServiceCodeSelect
      v-focus
      v-model="selectedValue"
      :work-order-id="workOrderId"
      :service-billing-code-ids="serviceBillingCodeIds"
      :show-all-codes="showAllCodes"
      class="ag-cell-editor w-full"
      @change="onChange"
      @on-change-filter="onChangeFilter"
      @keydown.enter.native="goToNextCell"
  />
</template>
<script>
  import ServiceCodeSelect from '@/components/select/entities/ServiceCodeSelect'
  import { selectEditorMixin } from '@/components/ag-grid/cellEditors/selectEditorMixin'

  export default {
    components: {
      ServiceCodeSelect,
    },
    mixins: [selectEditorMixin],
    computed: {
      workOrderId() {
        return this.params?.data?.work_order_id || this.params.work_order_id
      },
      serviceBillingCodeIds() {
        return this.params.serviceBillingCodeIds
      },
      showAllCodes() {
        return this.get(this.params, 'data.showAllCodes', false)
      },
    },
    methods: {
      onChangeFilter(state) {
        this.$set(this.params.data, 'showAllCodes', state)
      },
    },
  }
</script>
