import axios from 'axios'
import store from '@/store'
const cache = {}
const limit = 50 // max number of objects to store in the cache
const history = []
const defaultTTL = 2 * 60 * 1000 // 2 minutes
export default {
  set(key, value, ttl = defaultTTL) {
    cache[key] = {
      value,
      timestamp: new Date().getTime(),
      ttl: ttl,
    }
    history.push(key)
    if (history.length > limit) {
      this.removeOldKeys()
    }
  },
  get(key) {
    if (!cache[key]) {
      return null
    }
    let { value, timestamp, ttl } = cache[key]
    ttl = ttl || defaultTTL
    const now = new Date().getTime()
    if (timestamp + ttl < now) {
      // the cache expired
      this.removeKey(key)
      return null
    }
    return value
  },
  removeKey(key) {
    const historyIndex = history.indexOf(key)
    if (historyIndex !== -1) {
      history.splice(historyIndex, 1)
    }
    delete cache[key]
  },
  async getRequest(url, config = {}) {
    const configCopy = {
      ...config
    }
    delete configCopy.invalidateCache
    let key = JSON.stringify({ url, config: configCopy })
    const activeCompany = store.state.company.activeCompany || {id: 0}
    key = key+activeCompany.id

    if (config.invalidateCache) {
      this.removeKey(key)
    }
    if (this.get(key)) {
      return this.get(key)
    }
    const response = await axios.get(url, config)
    this.set(key, response, config.ttl)
    return response
  },
  removeOldKeys() {
    const keysToRemove = history.length - limit
    for (let i = 0; i < keysToRemove; i++) {
      const key = history[0]
      delete cache[key]
      history.shift()
    }
  },
  removeForEntity(entity) {
    for(let key in cache) {
      if (key.includes(entity)) {
        this.removeKey(key)
      }
    }
  }
}
