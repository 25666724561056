<template>
    <div>
        <BaseDataTable :columns="columns"
                       :actions="actions"
                       :url="url"
                       :url-params="urlParams"
                       :default-filters="false"
                       :add-text="$t('New company')"
                       default-sort="name"
                       permission="companies"
                       ref="table"
                       @add="onAdd"
                       @edit="onRowEdit"

        >
        </BaseDataTable>
        <BaseFormDialog v-if="showAddDialog"
                        :title="$t('Add Company')"
                        ref="addDialog"
                        size="lg"
                        :open.sync="showAddDialog">
            <CompanyForm layout="modal"
                         @cancel="$refs.addDialog.onCancel()"
                         @save="onCreate"
            />
        </BaseFormDialog>
        <BaseFormDialog v-if="showEditDialog"
                        :title="$t('Edit Company')"
                        ref="dialog"
                        size="lg"
                        :open.sync="showEditDialog">
            <CompanyForm layout="modal"
                         :data="rowToEdit"
                         @cancel="$refs.dialog.onCancel()"
                         @save="onUpdate"
            />
        </BaseFormDialog>
    </div>
</template>
<script>
  import CompanyForm from '@/modules/settings/components/CompanyForm'

  export default {
    components: {
        CompanyForm,
    },
    data() {
        return {
            rowToEdit: {},
            showEditDialog: false,
            showAddDialog: false,
            url: '/restify/companies',
            urlParams: {
              related: 'addresses,banks'
            },
        }
    },
    computed: {
        actions() {
          let baseActions = `add,edit,delete,search,refresh`
          if (!this.isProduction) {
            baseActions+=',view'
          }
          return baseActions
        },
        columns() {
            return [
                {
                    label: this.$t('Name'),
                    prop: 'attributes.name',
                    maxWidth: 190,
                    component: 'EntityLink',
                    redirectTo: '/settings/companies/{ID}/edit',
                },
                {
                    label: this.$t('Short Name'),
                    minWidth: 150,
                    maxWidth: 160,
                    prop: 'attributes.abbr',
                },
                {
                    label: this.$t('Phone'),
                    minWidth: 150,
                    maxWidth: 200,
                    prop: 'attributes.phone',
                    component: 'PhoneLink',
                },
                {
                    label: this.$t('Address'),
                    prop: 'addresses',
                    minWidth: 100,
                    maxWidth: 150,
                    component: 'PrimaryAddressLink'
                },
                {
                    label: this.$t('Active'),
                    prop: 'attributes.active',
                    minWidth: 90,
                    component: 'Status',
                    align: 'center',
                },
            ]

        }
    },
    methods: {
        onAdd() {
            this.showAddDialog = true
        },
        onRowEdit(row) {
          this.$router.push(`/settings/companies/${row.id}/edit`)
        },
        onCreate() {
            this.showAddDialog = false
            this.$refs.table.refresh()
        },
        onUpdate() {
            this.showEditDialog = false
            this.$refs.table.refresh()
        },
    },
    watch: {
      '$route.query.addCompany': {
        immediate: true,
        handler(value) {
          if (!value) {
            return
          }
          this.showAddDialog = true
          this.$router.replace({
            path: this.$route.path,
            query: {
              ...this.$route.query,
              addCompany: null,
            }
          })
        }
      }
    }
}
</script>
