<template>
  <LumpSumBillings :customer-id="customer.id"/>
</template>
<script>
  import LumpSumBillings from '@/modules/accounts-receivable/pages/lump-sum-billings/lump-sum-billings-batches'

  export default {
    components: {
      LumpSumBillings,
    },
    computed: {
      customer() {
        return this.$store.state.accountsReceivable.currentCustomer
      },
    },
  }
</script>
<style>
</style>
