<template>
  <BaseCard class="border">
    <div class="text-sm flex flex-col space-y-4">
      <div :title="$t('Code')"
           class="detail-row">
        <HashIcon class="detail-icon"/>
        <div>
          <span class="text-xs text-gray-500">{{ $t('Number') }}</span>
          <div>{{ workSiteData.number }}</div>
        </div>
      </div>
      <div :title="$t('Customer')"
           class="detail-row">
        <UserPlusIcon class="detail-icon"/>
        <div class="truncate">
          <span class="text-xs text-gray-500">{{ $t('Customer') }}</span>
          <CustomerLink :data="worksite?.relationships?.customer" :show-name="true"/>
        </div>
      </div>
      <div
          :title="$t('Authorized Person')"
          class="detail-row">
        <UserCheckIcon class="detail-icon"/>
        <div>
          <span class="text-xs text-gray-500">{{ $t('Authorized Person') }}</span>
          <div class="text-gray-700 font-medium">
            <template v-if="workSiteData.authorizing_person_name">
              {{ workSiteData.authorizing_person_name }}
            </template>
            <template v-else>
              {{ $t('No authorized person provided.') }}
            </template>
          </div>
        </div>
      </div>
    </div>
  </BaseCard>
</template>
<script lang="ts" setup>
import {computed, PropType} from 'vue'
import {WorkSite} from '@/modules/common/types/models'
import {HashIcon, UserCheckIcon, UserPlusIcon} from 'vue-feather-icons'
import Data = API.Data;

const props = defineProps({
  worksite: {
    type: Object as PropType<Data<WorkSite>>,
    required: true,
  },
})

const workSiteData = computed<WorkSite>(() => {
  return props.worksite?.attributes || {}
})
</script>
