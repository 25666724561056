<template>
  <BaseSwitch
      ref="input"
      v-focus
      autofocus
      v-model="selectedValue"
      type="date"
      inline-errors
      class="w-full"
      @change="onChange"
      @keydown.enter.native="stopEditing"
  />
</template>
<script>

  import { selectEditorMixin } from "@/components/ag-grid/cellEditors/selectEditorMixin";

  export default {
    name: 'DatePickerCell',
    mixins: [selectEditorMixin],
    methods: {
      stopEditing() {
        this.params.stopEditing()
      }
    },
  }
</script>
