<template>
  <base-dropdown
    v-bind="$attrs"
    :tooltip-content="$t('Actions')"
    class="relative"
    ref="dropdown"
    @change="onDropdownChange"

  >
    <template v-slot:trigger>
      <base-button variant="white"
                   class="ml-2 actions-dropdown-button"
                   size="sm"
      >
        <MenuIcon class="w-4 h-4 md:mr-2"></MenuIcon>
        <span class="hidden md:flex">{{ $t(buttonText) }}</span>
        <IconArrowDown class="hidden md:flex ml-1 transition duration-75"
                       :class="{'rotate-180': showDropdown}"
        />
      </base-button>
    </template>
    <div class="actions-dropdown">
      <slot></slot>
    </div>
  </base-dropdown>
</template>
<script>
import { MenuIcon } from 'vue-feather-icons'

export default {
  name: 'TableActionsDropdown',
  components: {
    MenuIcon,
  },
  props: {
    buttonText: {
      type: String,
      default: 'Actions'
    }
  },
  data() {
    return {
      showDropdown: false,
    }
  },
  methods: {
    onDropdownChange(value) {
      this.showDropdown = value
      const tableHeader = document.querySelector(`.table-header`)
      if (!tableHeader) {
        return
      }
      if (value) {
        tableHeader.classList.add('no-overflow')
      } else {
        tableHeader.classList.remove('no-overflow')
      }
    }
  },
}
</script>
<style scoped>
.actions-dropdown {
  min-width: 200px;
}

.actions-dropdown-button {
  min-height: 38px;
}
</style>
