<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
       stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-file-clock">
    <path d="M16 22h2a2 2 0 0 0 2-2V7l-5-5H6a2 2 0 0 0-2 2v3"/>
    <path d="M14 2v4a2 2 0 0 0 2 2h4"/>
    <circle cx="8" cy="16" r="6"/>
    <path d="M9.5 17.5 8 16.25V14"/>
  </svg>
</template>
<script>
export default {}
</script>
<style>
</style>
