<template>
  <div class="mb-4 flex text-sm items-center mb-3"
       :class="{
          'text-orange-600': variant === 'orange' || !variant,
          'text-red-600': variant === 'red',
       }"
  >
    <i class="mx-1">
      <IconWarning class="w-5 h-5"/>
    </i>
    <slot>
      {{ title }}
    </slot>
  </div>
</template>
<script>
  export default {
    props: {
      title: String,
      variant: {
        type: String,
        default: 'orange' // orange | red
      }
    }
  }
</script>
