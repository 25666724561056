<template>
  <base-form
    :loading="loading"
    :show-cancel="showCancel"
    :can-create-another-entity="!model.id"
    :save-text="$t('Create sub trade')"
    :update-text="$t('Update sub trade')"
    layout="modal"
    @cancel="$emit('cancel')"
    @submit="onSubmit"
  >
    <div class="col-span-6 md:col-span-3">
      <base-input v-model="model.trade"
                  :label="$t('Trade')"
                  :placeholder="$t('Trade')"
                  id="trade"
                  rules="required|max:150"
                  @blur="fillDescription"
      />
    </div>
    <div class="col-span-6 md:col-span-3">
      <base-input v-model="model.classification"
                  :label="$t('Classification')"
                  :placeholder="$t('Classification')"
                  id="classification"
                  rules="required|max:150"
                  @blur="fillDescription"
      />
    </div>

    <div class="col-span-6 md:col-span-3">
      <base-input v-model="model.description"
                  :label="$t('Description')"
                  :placeholder="$t('Description')"
                  id="description"
                  rules="max:150"
      />
    </div>
    <div class="col-span-6 md:col-span-2">
      <base-switch v-model="model.active"
                   :label-info="$t('Active')"
                   :placeholder="$t('Active')"
                   id="active"
                   rules="required"
      />
    </div>
  </base-form>
</template>
<script>
import axios from 'axios'

export default {
  inheritAttrs: false,
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    craft_code_id: {
      type: String,
    }
  },
  data() {
    return {
      loading: false,
      showCancel: true,
      model: {
        description: '',
        active: true,
        is_apprentice: false,
        craft_code_id: this.craft_code_id,
        apprentice: {},
      },
    }
  },
  methods: {
    fillDescription() {
      const { trade = '', classification = '' } = this.model
      this.model.description = `${trade || ''}: ${classification || ''}`
    },
    async onSubmit() {
      try {
        this.loading = true
        const requestData = {
          ...this.model,
          craft_code_id: this.craft_code_id,
          apprentice: {},
        }
        if (this.model.id) {
          const { data } = await axios.put(`/restify/sub-trades/${this.model.id}`, requestData)
          this.$success(this.$t('Sub trade updated.'))
          this.$emit('edit', data)
        } else {
          const { data } = await axios.post('/restify/sub-trades', requestData)
          this.$success(this.$t('Sub trade created.'))
          this.$createAnotherEntity ? this.$emit('create-another') : this.$emit('save', data)
        }
      } catch (err) {
        if (err.handled) {
          return
        }
        console.log('err', err)
        this.$error(this.$t('Could not update the sub trade.'))
      } finally {
        this.loading = false
      }
    },
  },
  watch: {
    data: {
      immediate: true,
      handler(value) {
        if (!value?.id) {
          return
        }
        this.model = {
          ...this.model,
          ...value.attributes,
        }
      },
    },
  },
}
</script>
