<template>
  <div>
    <layout-tabs :items="filteredItems"/>
    <router-view/>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        items: [
          {
            name: this.$t('Job Cost Settings'),
            path: '/job-costing/settings/job-cost-settings',
            permission: 'job_costing_settings',
          },
          {
            name: this.$t('Settings Update Activity'),
            path: '/job-costing/settings/activity/activity-log',
            permission: 'job_costing_settings',
          },
          {
            name: this.$t('Preliminary Notices'),
            path: '/job-costing/settings/pre-notice',
            permission: 'job_pre_notices_show',
          },
          {
            name: this.$t('Initialize Job Costs & Retentions'),
            path: '/job-costing/settings/job-cost-init',
            permission: 'job_costing_settings',
          },
          {
            name: this.$t('Categories'),
            path: '/job-costing/settings/categories',
            permission: 'job_categories_show',
          },
        ],
      }
    },
    computed: {
      filteredItems() {
        return this.items.filter(item => this.$can(item.permission))
      },
    },
  }
</script>
