<template>
  <div class="h-full pb-4">
    <AgDataTable
        key="active-users"
        ref="usersTable"
        :data="users"
        :data-loading="loading"
        :add-text="$t('Invite User')"
        :columns="userColumns"
        :actions="actions"
        :actions-column-width="180"
        :local-sort="true"
        :pagination="false"
        dom-layout="autoHeight"
        default-sort="name"
        @refresh-click="getData"
        @grid-ready="grid = $event"
        @add="onInviteClick"
    >
      <template #attributes.name="{row}">
          <div>
              <UserLink v-if="!row.isInvitation" :id="row.id"/>
              <BaseTooltip
                      v-else
                      :content="$t(`${row.attributes.name} has not confirmed their invitation yet. Once confirmed they will be converted to a user.`)">
                  <span class="font-medium">{{ row.attributes.name }}</span>
              </BaseTooltip>
          </div>
      </template>
      <template #company_roles="{row}">
        <div class="py-2">
          <div v-for="role in row.company_roles" :key="role" class="leading-normal">
            {{RoleService.getReadableRoleName(role)}}
          </div>
        </div>
      </template>
      <template #extra-actions="{row, index}">
        <table-edit-button
            v-if="$can('users_update') && !row.isInvitation"
            @click="onUserEdit(row, index)"
        />
        <base-button
            v-if="row.isInvitation"
            size="sm"
            variant="primary-link"
            @click="resendInvite(row)">
          {{ $t('Resend') }}
        </base-button>

        <table-delete-button
          v-if="row.isInvitation"
          @click="deleteInvite(row)"
        />
        <table-delete-button
            v-if="$isAuthorized('authorizedToRemoveFromCompany', row) && row.id !== $user.id"
            @click="removeUserFromCompany(row)"
        />
      </template>
    </AgDataTable>

    <BaseFormDialog v-if="showInviteDialog"
                    :title="$t('Invite User')"
                    ref="inviteDialog"
                    :open.sync="showInviteDialog">
      <UserInviteForm layout="modal"
                      @cancel="$refs.inviteDialog.onCancel()"
                      @save="onInviteSent"
      />
    </BaseFormDialog>

  </div>
</template>
<script>
  import InvitationService from "@/modules/settings/services/InvitationService";
  import UserInviteForm from "@/modules/settings/components/UserInviteForm";
  import CompanyLink from "@/components/links/CompanyLink.vue";
  import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";
  import difference from "lodash/difference";
  import axios from "axios";
  import cloneDeep from "lodash/cloneDeep";
  import RoleService from "@/modules/settings/services/RoleService";
  import orderBy from "lodash/orderBy";
  import TableDeleteButton from "@/components/table/actions/TableDeleteButton.vue";
  import { TableActions } from "@/components/ag-grid/tableUtils";

  export default {
    components: {
      TableDeleteButton,
      CompanyLink,
      UserInviteForm,
    },
    data() {
      return {
        showInviteDialog: false,
        grid: null,
        users: [],
        loading: false,
        RoleService,
      }
    },
    computed: {
      TableActions() {
        return TableActions
      },
      url() {
        return `/restify/companies/${this.$currentCompany.id}`
      },
      urlParams() {
        return {
          related: 'users.companyRoles',
        }
      },
      userDeleteMessage() {
        return this.$t('After removing the user from the company, they will no longer have access to the company. You can add the user to the company at a later point in time. If you want to remove the user from the organization as well, consider using the Organization Users page instead.')
      },
      filteredItems() {
        return this.items.filter(item => this.$can(item.permission))
      },
      actions() {
        let baseActions = ['search', 'refresh']
        if (this.$can('users_store')) {
          baseActions.push('add')
        }
        return baseActions.join(',')
      },
      userColumns() {
        return [
          {
            headerName: this.$t('Name'),
            field: 'attributes.name',
            sortable: true,
            minWidth: 150,
            maxWidth: 300,
          },
          {
            headerName: this.$t('Email'),
            field: 'attributes.email',
            minWidth: 250,
            maxWidth: 350,
            component: 'EmailLink',
            sortable: true,
          },
          {
            headerName: this.$t('Status'),
            field: 'status',
            minWidth: 120,
            maxWidth: 200,
            component: 'Status',
            sortable: true,
          },
          {
            headerName: this.$t('Roles'),
            field: 'company_roles',
            autoHeight: true,
            valueSetter: params => {
              let newValue = cloneDeep(params.newValue)
              let oldValue = cloneDeep(params.oldValue)
              const rolesToAdd = difference(newValue, oldValue)
              const rolesToRemove = difference(oldValue, newValue)
              if (rolesToAdd.length === 0 && rolesToRemove.length === 0) {
                return true
              }
              this.updateUserRoles({
                user: params.data,
                rolesToAdd,
                rolesToRemove,
              }).then(updated => {
                params.data.company_roles = updated ? newValue : oldValue
                params.node.setData(params.data)
              })
              return true
            },
            editable: true,
            cellEditor: cellEditors.CompanyRoleSelect,
            cellEditorParams: {
              stopNavigationOnChange: true,
            },
            minWidth: 250,
            flex: 1,
            sortable: true,
          },
        ]
      },
    },
    methods: {
      async removeUserFromCompany(user) {
        try {
          const confirm = await this.$confirm({
            title: this.$t('Remove user from company ?'),
            description: this.$t('Are you sure sure you want to remove this user from the company ?'),
            buttonText: this.$t('Remove'),
          })
          if (!confirm) {
            return
          }
          if (user.isInvitation) {
            await axios.delete(`/restify/invitations/${user.id}`)
          } else {
            await axios.post(`/restify/users/${user.id}/actions?action=remove-user-from-companies`, {
              organization_id: this.$currentOrganization.id,
              company_ids: [user.attributes.current_company_id],
            })
          }
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not remove user from company.'))
        }
        await this.getData()
      },
      async updateUserRoles({ user, rolesToAdd = [], rolesToRemove = [] }) {
        const userId = user.id
        const confirmed = await this.$confirm({
          title: this.$t('Change user roles ?'),
          description: this.$t('Are you sure sure you want to change the roles for this user ?'),
          buttonText: this.$t('Change roles'),
        })
        if (!confirmed) {
          return false
        }
        if (rolesToAdd.length) {
          await axios.post(`/restify/users/${userId}/attach/companyRoles`, {
            roles: rolesToAdd,
          })
        }
        if (rolesToRemove.length) {
          await axios.post(`/restify/users/${userId}/detach/companyRoles`, {
            roles: rolesToRemove,
          })
        }
        return true
      },
      onInviteClick() {
        this.showInviteDialog = true
      },
      onUserEdit(row, index) {
        this.grid.api.startEditingCell({
          rowIndex: index,
          colKey: 'company_roles'
        })
      },
      onInviteSent() {
        this.showInviteDialog = false
        this.getData()
      },
      async resendInvite(row) {
        await InvitationService.resendInvite(row.attributes)
      },
      async deleteInvite(row) {
        await InvitationService.deleteInvitation(row)
        await this.getData()
      },
      async getData() {
        try {
          this.loading = true
          const [company, invitations] = await Promise.all([
            axios.get(`/restify/companies/${this.$currentCompany.id}?related=users.companyRoles`),
            InvitationService.getInvitations(),
          ])
          let users = company?.data.relationships?.users || []
          users = orderBy(users, 'attributes.updated_at', 'desc')
          if (!Array.isArray(users) && typeof users === 'object') {
            users = Object.values(users)
          }
          const invitationsArray = invitations?.data || []
          let allUsers = [...invitationsArray, ...users]
          allUsers = allUsers.map(user => {
            user.isInvitation = user.type !== 'users'
            user.company_roles = user.relationships?.companyRoles?.map(r => r.attributes.name) || []
            if (user.isInvitation) {
              user.company_roles = user?.attributes?.company_roles
            }
            user.status = user.isInvitation ? this.$t('Invited') : this.$t('Active')
            return user
          })
          this.users = allUsers
        } finally {
          this.loading = false
        }
      }
    },
    mounted() {
      this.getData()
    }
  }
</script>
