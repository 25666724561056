<template>
  <base-data-table
      v-bind="defaultAttributes"
      :columns="columns"
      :data="getData"
      :total-rows="rowsLength"
      headerRowCompacted
      @force-pagination="forcePagination"
  >

    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template #thead-infos>
      <th v-if="groupByVendor"/>

      <th colspan="8" align="center" class="thead-infos">
      <span class="flex w-full justify-center mr-2 border-b-2 border-dashed pb-2">
        {{ $t('Expensed') }}
      </span>
      </th>

      <th colspan="8" align="center" class="thead-infos">
      <span class="flex w-full justify-center ml-4 border-b-2 border-dashed pb-2">
        {{ $t('Disbursed') }}
      </span>
      </th>
    </template>

    <template #header="{row, index}">
      <div v-if="!groupByVendor"
           class="flex"
      >
        <JobLink
            :data="row.header?.job"
            :show-preview="false"
            class="w-1/3"
        />

        <VendorLink
            :data="row.header?.vendor"
            :show-preview="false"
            class="w-2/3"
        />
      </div>

      <div v-else
           :class="{
              'top-border pt-4': index > 0,
            }"
      >
        <template>
          <VendorLink
              :data="row.header"
              :show-preview="false"
          />
        </template>
      </div>

    </template>

    <template #account="{row}">
      <div class="flex">
        <AccountLink
            :data="row"
            :show-description="false"
        />
        <span v-if="row.subaccount">
          &nbsp; - {{ row.subaccount }}
        </span>
      </div>
    </template>

    <template #journal.period="{row}">
      <span v-if="row.journal?.period">
        {{ row.journal.period + ' ' + row.journal.fiscal_year }}
      </span>
      <span v-else></span>
    </template>

    <template #payment.journal.period="{row}">
      <span v-if="row.payment?.journal?.period">
        {{ row.payment?.journal?.period + ' ' + row.payment?.journal?.fiscal_year }}
      </span>
      <span v-else></span>
    </template>

    <template #job="{row}">
      <JobLink
          v-if="!isNonJobCostCenter(row)"
          :data="row.meta"
          :show-description="false"
          :show-preview="false"
      />
      <span v-else></span>
    </template>

    <template #meta.code="{row}">

      <router-link
          v-if="isNonJobCostCenter(row)"
          :to="getCostCenterLink(row)"
      >
        {{ row.meta?.code }}
      </router-link>

      <router-link
          v-else
          :to="`/job-costing/cost-line-items/${row.addl_source_id}/view`"
      >
        {{ row.meta.cost_code }}
      </router-link>

    </template>

    <template #meta.change_order="{row}">
        <span v-if="isNonJobCostCenter(row)">
          {{ row.meta?.index }}
        </span>
      <span v-else>
          {{ row.meta.change_order ? row.meta.change_order : '' }}
        </span>
    </template>

    <template #meta.phase_code="{row}">
      <router-link
          v-if="!isNonJobCostCenter(row)"
          :to="`/job-costing/cost-line-items/${row.addl_source_id}/view`"
      >
        {{ row.meta.phase_code }}
      </router-link>

      <span v-else></span>
    </template>

    <template #payment.bnk_chk="{row}">
      <div class="flex items-center justify-between">
        <BankLink
            :id="get(row, 'payment.bank.id')"
            displayOnlyInitials
        />
        <span>{{ row.payment?.payment?.number }}</span>
      </div>
    </template>

    <template #subtotal="{subtotal}">
      <td colspan="13"/>
      <td colspan="4" class="text-right">
        <span class="mx-2">{{ $formatPrice(subtotal.open_amount) }}</span>
        {{ $t('Invoice Balance Due *******') }}
      </td>
    </template>

    <template #html-row="{htmlData}">
      <tr class="content-row">
        <td :colspan="groupByVendor ? 9 : 8" align="right">
          {{ $t('Vendor Totals:') }}
        </td>
        <td class="top-border" align="right">
          {{ $formatPrice(htmlData.gross_amount) }}
        </td>
        <td class="top-border" align="right">
          {{ $formatPrice(htmlData.retention_amount) }}
        </td>
        <td colspan="4"/>
        <td class="top-border" align="right">
          {{ $formatPrice(htmlData.discount_taken_amount) }}
        </td>
        <td class="top-border" align="right">
          {{ $formatPrice(htmlData.payments_net_amount) }}
        </td>
      </tr>
      <tr
          v-if="htmlData.open_amount"
          class="content-row"
      >
        <td :colspan="groupByVendor ? 15 : 14" align="right">
          {{ $t('Vendor Balance Due:') }}
        </td>
        <td colspan="2" align="right">
          {{ $formatPrice(htmlData.open_amount) }}
        </td>
      </tr>

      <template v-if="htmlData.job">
        <tr class="content-row">
          <td colspan="8" align="right">
            {{ $t('Job Totals:') }}
          </td>
          <td class="top-border" align="right">
            {{ $formatPrice(htmlData.job.gross_amount) }}
          </td>
          <td class="top-border" align="right">
            {{ $formatPrice(htmlData.job.retention_amount) }}
          </td>
          <td colspan="4"/>
          <td class="top-border" align="right">
            {{ $formatPrice(htmlData.job.discount_taken_amount) }}
          </td>
          <td class="top-border" align="right">
            {{ $formatPrice(htmlData.job.payments_net_amount) }}
          </td>
        </tr>
        <tr class="content-row">
          <td colspan="14" align="right">
            {{ $t('Job Balance Due:') }}
          </td>
          <td colspan="2" align="right">
            {{ $formatPrice(htmlData.job.open_amount) }}
          </td>
        </tr>
      </template>

    </template>

  </base-data-table>
</template>
<script>
  import { costCenterTypes } from '@/components/grid-table/utils/cost-center'
  import { getSourceLink } from '@/components/grid-table/utils/cost-center-cell'
  import { vendorHistoryFormats } from '@/modules/accounts-payable/components/reports/util'
  import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'

  export default {
    extends: ReportTableWrapper,
    props: {
      format: {
        required: true,
        type: String,
        default: vendorHistoryFormats.ByVendor,
      },
    },
    computed: {
      groupByVendor() {
        return this.format === vendorHistoryFormats.ByVendor
      },
      columns() {
        const columns = [
          {
            label: this.$t('Invoice'),
            prop: 'invoice.number',
            component: 'EntityLink',
            redirectTo: '/accounts-payable/invoices/{ID}/view',
            entityKey: 'invoice.id',
            minWidth: 100,
            maxWidth: 120,
          },
          {
            label: this.$t('Journal'),
            prop: 'journal.name',
            component: 'EntityLink',
            redirectTo: '/accounts-payable/journals/{ID}/view',
            entityKey: 'journal.id',
            minWidth: 60,
            maxWidth: 80,
          },
          {
            label: this.$t('Period'),
            prop: 'journal.period',
            align: 'center',
            minWidth: 80,
            maxWidth: 100,
          },
          {
            label: this.$t('Account'),
            prop: 'account',
            minWidth: 80,
            maxWidth: 120,
          },
          {
            label: this.$t('Job'),
            prop: 'job',
            align: 'center',
            minWidth: 100,
            maxWidth: 120,
            renderIf: () => this.groupByVendor,
          },
          {
            label: this.$t('Type'),
            prop: 'meta.abbr',
            component: 'TypeLink',
            minWidth: 40,
            maxWidth: 50,
          },
          {
            label: this.$t('Phase'),
            prop: 'meta.phase_code',
            minWidth: 40,
            maxWidth: 50,
          },
          {
            label: this.$t('Code'),
            prop: 'meta.code',
            align: 'right',
            minWidth: 40,
            maxWidth: 50,
            summary: () => `${this.$t('Grand Totals:')}<br><br>&nbsp;`,
          },
          {
            label: this.$t('CHG'),
            prop: 'meta.change_order',
            minWidth: 40,
            maxWidth: 50,
            hideZero: true,
          },
          {
            label: this.$t('Gross Amt'),
            prop: 'gross_amount',
            component: 'FormattedPrice',
            align: 'right',
            minWidth: 100,
            maxWidth: 120,
            hideZero: true,
            summary: () => `${this.$formatPrice(this.grandTotals.gross_amount)}<br><br>&nbsp`,
          },
          {
            label: this.$t('Retention'),
            prop: 'retention_amount',
            component: 'FormattedPrice',
            align: 'right',
            minWidth: 100,
            maxWidth: 120,
            hideZero: true,
            summary: () => `${this.$formatPrice(this.grandTotals.retention_amount)}<br><br>&nbsp`,
          },
          {
            label: this.$t('Journal'),
            prop: 'payment.journal.name',
            component: 'EntityLink',
            redirectTo: '/accounts-payable/invoices/{ID}/view',
            entityKey: 'journal.id',
            align: 'center',
            minWidth: 100,
            maxWidth: 120,
          },
          {
            label: this.$t('Period'),
            prop: 'payment.journal.period',
            minWidth: 60,
            maxWidth: 80,
          },
          {
            label: this.$t('Bnk/Chk'),
            prop: 'payment.bnk_chk',
            minWidth: 60,
            maxWidth: 70,
            summary: () => `<br><br>&nbsp${this.$t('Total Balance Due:')}`,
          },
          {
            label: this.$t('Date'),
            prop: 'payment.payment.date',
            component: 'FormattedDate',
            align: 'center',
            minWidth: 80,
            maxWidth: 110,
          },
          {
            label: this.$t('Discount'),
            prop: 'payment.payment.discount_amount',
            component: 'FormattedPrice',
            align: 'right',
            minWidth: 100,
            maxWidth: 120,
            hideZero: true,
            summary: () => `${this.$formatPrice(this.grandTotals.discount_taken_amount)}<br><br>&nbsp`,
          },
          {
            label: this.$t('Payment'),
            prop: 'payment.payment.net_amount',
            component: 'FormattedPrice',
            align: 'right',
            minWidth: 100,
            maxWidth: 120,
            hideZero: true,
            summary: () => `${this.$formatPrice(this.grandTotals.payments_net_amount)}<br><br>${this.$formatPrice(this.grandTotals.open_amount)}`,
          },
        ]

        return columns.filter(column => column.renderIf ? column.renderIf() : true)
      },
    },
    methods: {
      isNonJobCostCenter(row) {
        return row.cost_center !== costCenterTypes.Job
      },
      getCostCenterLink(row) {
        return getSourceLink(row)
      },
      composeRows(data) {
        this.rows = this.groupByVendor ? this.mapDataByVendor(data) : this.mapDataByJob(data)
      },
      mapDataByVendor(data) {
        let rows = []

        data.forEach(group => {
          rows.push({
            header: group.vendor,
          })

          group.invoices?.forEach(invoiceObject => {
            const { invoice, totals } = invoiceObject

            let entries = []
            let invoiceEntries = invoiceObject.entries
            if (!Array.isArray(invoiceEntries) && invoiceEntries !== null) {
              console.log('invoiceEntries is not an array')
              invoiceEntries = [invoiceEntries]
            }
            invoiceEntries.forEach((entry, index) => {

              if (entry.cost_center === costCenterTypes.Job) {
                entry.meta.number = entry.meta.code
              } else if (entry.meta) {
                delete entry.meta.abbr
              }

              entries.push({
                invoice,
                journal: invoiceObject.journal,
                ...entry,
              })
            })
            console.log(invoiceObject.payments)
            invoiceObject.payments.forEach((payment, index) => {
              if (entries[index]) {
                entries[index]['payment'] = payment
              } else {
                entries.push({
                  'invoice.number': invoice.number,
                  payment,
                })
              }
            })

            rows.push(...entries)

            if (totals.open_amount) {
              rows.push({
                subtotal: totals,
              })
            }

          })

          rows.push({
            htmlData: group.totals,
          })
        })

        return rows
      },
      mapDataByJob(data) {
        let rows = []

        data.forEach((group, groupIndex) => {

          group.vendors?.forEach((vendorObject, vendorIndex) => {

            if (vendorIndex === 0) {
              rows.push({
                header: {
                  job: group.job,
                  vendor: vendorObject.vendor,
                },
              })
            } else {
              rows.push({
                header: {
                  vendor: vendorObject.vendor,
                },
              })
            }

            vendorObject.invoices?.forEach(invoiceObject => {
              const { invoice, journal, totals } = invoiceObject

              invoiceObject.entries.forEach(entry => {
                if (entry.cost_center !== costCenterTypes.Job) {
                  delete entry.meta.abbr
                }

                rows.push({
                  invoice,
                  journal,
                  ...entry,
                })
              })

              invoiceObject.payments?.forEach(payment => {
                rows.push({
                  'invoice.number': invoice.number,
                  payment: payment,
                })
              })

              if (totals.open_amount) {
                rows.push({
                  subtotal: totals,
                })
              }
            })

            if (vendorIndex === group.vendors?.length - 1) {
              rows.push({
                htmlData: {
                  ...vendorObject.totals,
                  job: { ...group.totals },
                },
              })
            } else {
              rows.push({
                htmlData: { ...vendorObject.totals },
              })
            }

          })

        })

        return rows
      },
    },
  }
</script>
