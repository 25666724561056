<template>
  <div>
    <router-link
        v-if="!row.relationships?.sibling?.id || !showDifference"
        :to="`/job-costing/${lineItemType}-line-items/${row.id}/${sectionToRedirect}`"
    >
      {{ $formatPrice(row.attributes[amountKey]) }}
    </router-link>
    <div
        v-else
        class="flex justify-end space-x-3 text-sm">
      <dl>
        <dt>{{ i18n.t('Cost') }}:</dt>
        <dt>{{ i18n.t('Income') }}:</dt>
      </dl>
      <dl>
        <dt>
          <router-link :to="`/job-costing/cost-line-items/${incomeTypeId}/${sectionToRedirect}`">
            {{ formatPrice(costAmount) }}
          </router-link>
        </dt>
        <dt>
          <router-link :to="`/job-costing/income-line-items/${costTypeId}/${sectionToRedirect}`">
            {{ formatPrice(incomeAmount) }}
          </router-link>
        </dt>
        <dt
            class="text-xs font-semibold mt-1"
            :class="{
              'text-red-500': (incomeAmount - costAmount) < 0,
              'text-green-500': (incomeAmount - costAmount) > 0,
            }"
        >
          {{ formatPrice(amountDifference) }}
        </dt>
      </dl>
    </div>
  </div>
</template>
<script lang="ts" setup>
  import i18n from '@/i18n'
  import get from 'lodash/get'
  import { PropType, computed } from 'vue'
  import { costTypes } from '@/enum/enums'
  import { formatPrice } from '@/plugins/formatPrice'

  const props = defineProps({
    row: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },
    lineItemType: {
      type: String as PropType<string>,
      required: true,
    },
    amountKey: {
      type: String as PropType<string>,
      required: true,
      default: 'amount',
    },
    showDifference: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  })

  const siblingData = computed(() => {
    return get(props.row, 'relationships.sibling.attributes', {})
  })

  const siblingAmount = computed(() => {
    return siblingData.value[props.amountKey] || 0
  })

  const isIncomeType = computed(() => {
    return props.lineItemType === costTypes.Income
  })

  const rowAmount = computed(() => {
    return props.row.attributes[props.amountKey] || 0
  })

  const costAmount = computed(() => {
    return isIncomeType.value ? siblingAmount.value : rowAmount.value
  })

  const incomeAmount = computed(() => {
    return isIncomeType.value ? rowAmount.value : siblingAmount.value
  })

  const amountDifference = computed(() => {
    const difference = costAmount.value - incomeAmount.value
    return Math.abs(difference)
  })

  const sectionToRedirect = computed(() => {
    return props.amountKey === 'amount_to_date' ? 'transactions' : 'view'
  })

  const incomeTypeId = computed(() => {
    return isIncomeType.value ? props.row.id : siblingData.value.id
  })

  const costTypeId = computed(() => {
    return isIncomeType.value ? siblingData.value.id : props.row.id
  })
</script>
