<template>
  <entity-select
      v-bind="$attrs"
      v-on="$listeners"
      :name="$t('Report Type')"
      :add-entity="false"
      :hide-label="true"
      :select-first-option="selectFirstOption"
      :placeholder="$t('Select Report Type')"
      rules="required"
      url="/restify/financial-reports"
      label-key="description"
  />
</template>
<script>
  export default {
    inheritAttrs: false,
    props: {
      selectFirstOption: {
        type: Boolean,
        default: true,
      }
    }
  }
</script>
