<template>
  <div
    v-if="hasTimesheet"
    :class="bgClass"
    class="w-2 h-2 rounded-full"
  />
</template>
<script setup lang="ts">
import {computed} from 'vue'
import i18n from "@/i18n";
import {TimesheetStatus} from "@/modules/payroll/components/timesheets/utils";

const props = defineProps({
  timesheet: {
    type: Object,
    default: () => ({}),
  },
})

const hasTimesheet = computed(() => {
  return !!props.timesheet?.id
})

const status = computed(() => {
  return props.timesheet?.attributes.status
})

const bgClass = computed(() => {
  const map = {
    [TimesheetStatus.Approved]: 'bg-green-500',
    [TimesheetStatus.Rejected]: 'bg-red-500',
    [TimesheetStatus.PayrollCreated]: 'bg-green-500',
    [TimesheetStatus.Transferred]: 'bg-green-500',
    [TimesheetStatus.Submitted]: 'bg-blue-500',
    [TimesheetStatus.Pending]: 'bg-yellow-500',
  }
  return map[status.value] || status.value
})
</script>
