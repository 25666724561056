<template>
  <div ref="wizardInner"
       :style="{
          ...wizardInnerStyles,
          height: wizardInnerStyles.maxHeight,
       }"
       class="wizard bg-white border border-gray-200 rounded-md overflow-y-auto">
    <ValidationObserver v-slot="{ handleSubmit, valid }" class="h-full flex flex-col">
      <nav aria-label="Progress" class="sticky top-0 wizard-nav bg-white">
        <ol role="list" class="border-b border-gray-200 divide-y divide-gray-300 md:flex md:divide-y-0">
          <li v-for="(step, stepIdx) in steps"
              :key="step.name"
              @click="onTrySelectStep(stepIdx+1, valid, handleSubmit)"
              class="relative md:flex-1 md:flex no-select">
            <div v-if="step.status === 'complete'" role="button" class="group flex items-center w-full">
              <span class="px-4 py-2 flex items-center text-sm font-medium">
                <span
                    class="flex-shrink-0 w-6 h-6 flex items-center justify-center bg-primary-600 rounded-full group-hover:bg-primary-300">
                  <svg class="w-4 h-4 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                       fill="currentColor"
                       aria-hidden="true">
                    <path fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"/>
                  </svg>
                </span>
                <span class="ml-4 text-sm font-medium text-gray-900">{{ step.name }}</span>
              </span>
            </div>
            <div v-else-if="step.status === 'current'"
                 role="button"
                 class="px-4 py-2 flex items-center text-sm font-medium" aria-current="step">
              <span
                  class="flex-shrink-0 w-6 h-6 flex items-center justify-center border-2 border-primary-600 rounded-full">
                <span class="text-primary-600">{{ stepIdx + 1 }}</span>
              </span>
              <span class="ml-4 text-sm font-medium text-primary-600">{{ step.name }}</span>
            </div>
            <div v-else class="group flex items-center"
                 role="button">
              <span class="px-4 py-2 flex items-center text-sm font-medium">
                <span
                    class="flex-shrink-0 w-6 h-6 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                  <span class="text-gray-500 group-hover:text-gray-900">{{ stepIdx + 1 }}</span>
                </span>
                <span class="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">{{ step.name }}</span>
              </span>
            </div>
            <template v-if="(stepIdx !== steps.length - 1)">
              <div class="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
                <svg class="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
                  <path d="M0 -2L20 40L0 82" vector-effect="non-scaling-stroke" stroke="currentColor"
                        stroke-linejoin="round"/>
                </svg>
              </div>
            </template>
          </li>
        </ol>
      </nav>
      <div class="flex-1">
        <div
            :class="wrapperClasses"
            class="wizard-wrapper"
        >
          <slot/>
        </div>
        <div v-if="showFooter" class="wizard-footer sticky bottom-0 px-6 py-4 mt-6 bg-gray-50 items-center flex w-full justify-between">
          <div>
            <base-button v-if="currentStep > 1"
                         variant="primary-link"
                         @click="$emit('on-back')">
              &#8592; {{ $t(` Back`) }}
            </base-button>
          </div>
          <div>
            <base-cancel-button v-if="showCancel"
                                class="mr-3"
                                @click="$emit('cancel')">
              {{ $t('Cancel') }}
            </base-cancel-button>
            <template v-if="showNext">
              <base-submit-button @click="onNext(handleSubmit, valid)"
                                  v-bind="$attrs"
                                  type="submit"
              >
                <template v-if="!stepSubmitText">
                  {{ $t('Next') }} &#8594;
                </template>
                <template v-else>
                  <component
                      v-if="stepIcon"
                      :is="stepIcon"
                      class="w-4 h-4 mr-3"
                  />
                  {{ $t(stepSubmitText) }}
                </template>
              </base-submit-button>
            </template>
          </div>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>
<script>
  import { ref } from 'vue';
  import { useMaxAvailableHeight } from '@/modules/common/composables/useMaxAvailableHeight';

  export default {
    props: {
      steps: {
        type: Array,
        default: () => [],
      },
      currentStep: {
        type: Number,
        default: 1,
      },
      skipValidation: {
        type: Boolean,
        default: false,
      },
      showCancel: {
        type: Boolean,
        default: true,
      },
      showNext: {
        type: Boolean,
        default: true,
      },
      wrapperClasses: {
        type: String,
        default: 'px-6',
      },
      showFooter: {
        type: Boolean,
        default: true,
      }
    },
    setup() {
      const wizardInner = ref(null)
      const { styles } = useMaxAvailableHeight(wizardInner)
      return {
        wizardInner,
        wizardInnerStyles: styles,
      }
    },
    computed: {
      stepSubmitText() {
        return this.steps[(this.currentStep - 1)]?.actionName
      },
      stepIcon() {
        return this.steps[(this.currentStep - 1)]?.icon
      },
    },
    methods: {
      onNext(handleSubmit, validForm) {
        handleSubmit()
        if (!validForm && !this.skipValidation) {
          return
        }
        this.$emit('on-next')
      },
      onTrySelectStep(stepIdx, validForm, handleSubmit) {
        handleSubmit()
        if (stepIdx < this.currentStep) {
          return this.$emit('set-step', stepIdx)
        }
        if (!validForm || stepIdx > (this.currentStep + 1)) {
          return
        }
        this.$emit('set-step', stepIdx)
      },
    },
  }
</script>
<style lang="scss">
  .no-select {
    user-select: none;
  }

  .wizard-nav,
  .wizard-footer {
    z-index: 200;
  }
  // * Because of nav and footer height
  .wizard-wrapper {
    height: calc(100% - 100px);
  }
</style>
