<template>
  <div class="timecard h-full">
    <div class="h-full">
      <Wizard
          :steps="steps"
          :current-step="currentStep"
          :loading="loading"
          :show-next="isEditable && currentStep < 2"
          :show-cancel="isEditable && currentStep < 2"
          :show-footer="currentStep < 2"
          wrapper-classes="px-6 pt-4"
          @set-step="onSetStep"
          @on-back="onBack"
          @on-next="onNext"
          @cancel="$router.push('/payroll/timecard-batches')"
      >
        <div v-if="currentStep === 2"
             class="flex items-center absolute top-0 right-0 mr-4 mt-4">
          <span class="text-base leading-6 font-semibold text-gray-900">
            {{ $t('Date') }}
          </span>
          <base-tooltip
              :content="$t('Generate the salaried payroll for the current payroll year and period.')">
            <IconInfo class="text-gray-500 hover:text-primary-500 mx-2 cursor-help"/>
          </base-tooltip>
          <div class="bg-gray-900 bg-opacity-5 font-medium rounded-md py-1 px-4 tracking-wide mr-2">
            {{ model.year }}
          </div>
          <div class="bg-gray-900 bg-opacity-5 font-medium rounded-md py-1 px-4 tracking-wide">
            {{ $formatDate(model.period_end_date, 'MM/dd/yyyy', true) }}
          </div>
        </div>
        <fade-transition :duration="250"
                         mode="out-in">
          <Summary v-if="currentStep === 1"
                   v-model="model"
                   :is-selected-batch="isSelectedBatch"
          />
          <div v-if="currentStep === 2">
            <ManageTimeCards
                :days="days"
                :read-only="isPosted"
                @refresh-employees="getBatchEmployees"
            />
          </div>
        </fade-transition>
      </Wizard>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'
  import { payFrequencies, resourceStatuses } from '@/enum/enums'
  import { onBack } from '@/components/lists/wizard-util'
  import Summary from '@/modules/payroll/components/timecard/Summary'
  import ManageTimeCards from '@/modules/payroll/components/timecard/ManageTimeCards'
  import { timecardStatuses } from '@/modules/payroll/components/timecard/util'
  import { checkTypes, payrollStatuses } from "@/modules/payroll/components/rates/util";
  import { getTimeCardBatchDays } from "@/modules/payroll/utils/timeCardUtils";

  export default {
    components: {
      Summary,
      ManageTimeCards,
    },
    props: {
      activeStep: {
        type: Number,
        default: 1,
      },
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      const isBiWeekly = this.$settings(this.$modules.PR, 'is_biweekly_payroll')
      const defaultFrequency = isBiWeekly ? payFrequencies.BI_WEEKLY : payFrequencies.WEEKLY
      const currentCalendarYear = this.$settings(this.$modules.PR, 'calendar_year')
      return {
        loading: false,
        model: {
          period_end_date: null,
          year: currentCalendarYear,
          pay_frequency: [
            defaultFrequency,
          ],
          include_salaried: false,
          selectedEmployees: [],
        },
        currentStep: 1,
      }
    },
    computed: {
      isEditable() {
        return [resourceStatuses.Pending, resourceStatuses.Computed, undefined].includes(this.data.status)
      },
      steps() {
        const editSteps = [
          {
            name: this.$t('Summary'),
            status: 'current',
          },
          {
            name: 'Manage Timecards',
            status: 'upcoming',
            actionName: this.$t('Update timecard'),
          },
        ]
        if (this.isEditable) {
          return editSteps
        }
        return [
          {
            name: 'Timecards',
            status: 'upcoming',
          },
        ]
      },
      days() {
        return getTimeCardBatchDays(this.model.period_end_date)
      },
      currentResource() {
        return this.$store.state.payroll.currentTimecardBatch
      },
      isSelectedBatch() {
        return !!(this.data.id || this.model.id)
      },
      isPosted() {
        return [resourceStatuses.Posted, resourceStatuses.Voided].includes(this.currentResource?.status)
      },
    },
    methods: {
      onBack,
      focusOnPeriodInput() {
        const input = document.querySelector('#period_end_date input')
        if (!input) {
          return
        }
        input.focus()
      },
      async onNext() {
        if (this.currentStep === this.steps.length) {
          return this.submitForm()
        }
        await this.validateSelection(async (goNext) => {
          if (!goNext) {
            return
          }
          await this.saveTimeCardBatch()
          this.currentStep++
          this.setStepStates()
        })
      },
      onSetStep(step) {
        this.currentStep = step
        this.setStepStates()
      },
      setStepStates() {
        this.steps.forEach((step, index) => {
          if (index > (this.currentStep - 1)) {
            step.status = 'upcoming'
          } else {
            if (index < (this.currentStep - 1)) {
              step.status = 'complete'
            }
            if ((index + 1) === this.currentStep) {
              step.status = 'current'
            }
          }
        })
      },
      async validateSelection(callback) {
        if (this.model.id) {
          return callback(true)
        }
        if (this.model.selectedEmployees.length === 0) {
          this.$error(this.$t('Please select employee(s) for the timecard batch'))
          return callback(false)
        }
        try {
          const { data } = await axios.get('restify/timecard-batches', {
            params: {
              period_end_date: this.model.period_end_date,
              status: timecardStatuses.PENDING,
              year: this.model.year,
            },
          })
          if (!data.length) {
            return callback(true)
          }
          await this.createEmployeeTimeCards(data[0])
          await this.tryRedirectToEdit(data[0], callback)
        } catch (err) {
          if (err.handled) {
            return console.warn(err)
          }
          this.$error(this.$t('Something went wrong. Please refresh the page and try again.'))
        }
      },
      async createEmployeeTimeCards(timeCard) {
        try {
          let createdTimeCards = []
          const batchEmployees = await this.getBatchEmployees(false)
          const data = this.model?.selectedEmployees
              .filter(e => !batchEmployees.some(e2 => e2.id === e.id))
              .map(employee => {
                return {
                  timecard_batch_id: timeCard.id,
                  employee_id: employee.id,
                  period_end_date: this.$formatDate(timeCard.period_end_date, this.$dateTypes.IsoDate),
                  year: timeCard.year,
                  type: checkTypes.NORMAL,
                  status: payrollStatuses.PENDING,
                }
              })
          if (data.length > 0) {
            const response = await axios.post(`/restify/timecards/bulk`, data)
            createdTimeCards = response?.data || []
          }
          if (createdTimeCards.length && !this.$route.params.timecardId) {
            const firstTimeCard = createdTimeCards[0]
            await this.$router.push(`/payroll/timecard-batches/${this.model.id}/timecards/${firstTimeCard.id}/edit`)
          }
        } catch (err) {
          console.log(err, 'Submit timecards')
        }
      },
      async getBatchEmployees(setEmployees = true) {
        const employees = await axios.get(`/restify/timecard-batches/${this.model.id}/employees`)
        if (!setEmployees) {
          return employees
        }
        this.model.selectedEmployees = employees.map(employee => {
          return {
            id: employee.id,
            attributes: {
              ...employee
            },
            relationships: {},
          }
        })
        return employees
      },
      async tryRedirectToEdit(batch, callback) {
        const confirmed = await this.$confirm({
          title: this.$t('Manage batch?'),
          description: this.$t('Batch with this year and ending period already exist, you want to edit it?'),
          buttonText: this.$t('Confirm'),
        })

        if (!confirmed) {
          return this.focusOnPeriodInput()
        }
        this.model = {
          ...this.model,
          ...batch.attributes,
        }
        callback(true)
      },
      async submitForm() {
        const { timecardForm } = this.$refs
        if (!timecardForm) {
          return
        }
        await this.saveTimeCardBatch()
        await timecardForm.onSubmit(this.model, () => {
          this.loading = false
        })
      },
      async saveTimeCardBatch() {
        try {
          this.loading = true
          const requestData = {
            ...this.model,
            period_end_date: this.$formatDate(this.model.period_end_date, this.$dateTypes.IsoDate)
          }
          if (!this.model.id) {
            const { data } = await axios.post('/restify/timecard-batches', requestData)
            this.model = {
              ...this.model,
              ...data.attributes
            }
          } else {
            const { data } = await axios.put(`/restify/timecard-batches/${this.model.id}`, requestData)
            this.model = {
              ...this.model,
              ...data.attributes
            }
          }
          this.model.timecard_batch_id = this.model.id
          await this.createEmployeeTimeCards(this.model);
          await this.$store.dispatch('payroll/resetTimecardBatchToPending')
        } catch (err) {
          if (err.handled) {
            return console.warn(err)
          }
          this.$error(this.$t('Something went wrong. Please refresh the page and try again.'))
        } finally {
          this.loading = false
        }
      },
      async mapModel(batch) {
        if (!batch.id) {
          return
        }
        this.model = {
          ...this.model,
          ...batch,
        }
        await this.getBatchEmployees()
      },
    },
    watch: {
      activeStep: {
        immediate: true,
        async handler(step) {
          this.currentStep = step
          await this.$nextTick()
          this.setStepStates()
        },
      },
      currentResource: {
        immediate: true,
        handler(batch) {
          this.mapModel(batch)
        },
      },
      data: {
        immediate: true,
        handler(batch) {
          this.mapModel(batch)
        },
      },
    },
  }
</script>
<style lang="scss">
  .timecard {
    .btn-danger-link {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
</style>
