<template>
  <BaseDataTable :columns="columns"
                 :is-expandable="true"
                 :default-match-filters="defaultMatchFilters"
                 :add-entity-in-new-tab="addEntityInNewTab"
                 :add-text="$t('New direct deposit')"
                 :delete-action="onDelete"
                 ref="table"
                 url="/restify/employees"
                 default-sort="code"
                 permission="employees"
                 hide-actions="view"
                 actions="search,refresh,print"
  >
    <template #print-action>
      <div class="-m-2">
        <ReportPrintAction variant="gray-link"/>
      </div>
    </template>
    <template #expand-content="{row}">
      <DirectDepositEntries
          :employee-id="row.id"
          :read-only="true"
          class="p-3"
      />
    </template>
    <template #employee="{row}">
      <employee-link :data="row"/>
    </template>
  </BaseDataTable>
</template>
<script>
  import axios from 'axios'
  import ReportPrintAction from '@/components/table/ReportPrintAction'
  import DirectDepositEntries from '@/modules/payroll/components/direct-deposit/DirectDepositEntries'

  export default {
    inheritAttrs: false,
    components: {
      ReportPrintAction,
      DirectDepositEntries,
    },
    data() {
      return {
        addEntityInNewTab: true,
      }
    },
    computed: {
      columns() {
        return [
          {
            label: this.$t('Employee'),
            prop: 'employee',
            minWidth: 100,
            maxWidth: 150,
          },
          {
            label: this.$t('Status'),
            prop: 'attributes.status',
            component: 'Status',
            minWidth: 80,
            maxWidth: 80,
          },
          {
            label: this.$t('Deposits'),
            prop: 'attributes.direct_deposits_count',
            component: 'Count',
          },
          {
            label: this.$t('Pay Frequency'),
            prop: 'attributes.pay_frequency',
            minWidth: 100,
            maxWidth: 100,
            component: 'Status',
          },
        ]
      },
      defaultMatchFilters() {
        const hasDirectDepositFilterKey = 'has_direct_deposits'

        return {
          [hasDirectDepositFilterKey]: true,
        }
      },
    },
    methods: {
      async onDelete(employee) {
        try {
          await axios.post(`/restify/employees/${employee.id}/actions?action=delete-direct-deposits-from-employee`)
        } catch (err) {
          console.warn(err)
        } finally {
          this.$refs.table.refresh()
        }
      },
    },
  }
</script>
