<template>
  <BaseFormDialog
    v-bind="$attrs"
    v-on="$listeners"
    :title="$t('Add employees to policy')"
  >
    <BaseForm
      :loading="loading"
      :save-text="$t('Add Employees')"
      :submit-disabled="selectedEmployees.length === 0"
      layout="modal"
      @cancel="$emit('cancel')"
      @submit="addEmployeesToPolicy"
    >
      <AgDataTable
        url="/restify/employees"
        :url-params="urlParams"
        :columns="employeesToAddColumns"
        :selected-rows.sync="selectedEmployees"
        dom-layout="autoHeight"
        actions="refresh,search,selected-rows"
        class="h-full col-span-6"
        @row-selected="onRowSelected"
      >
      </AgDataTable>
    </BaseForm>
  </BaseFormDialog>
</template>
<script>
import { AccrualMethods } from "@/modules/payroll/utils/accrualMethods";

export default {
  props: {
    timeOffPolicy: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      loading: false,
      showDialog: false,
      selectedEmployees: [],
    }
  },
  computed: {
    isUnlimited() {
      return this.timeOffPolicy?.attributes?.accrual_method === AccrualMethods.Unlimited
    },
    urlParams() {
      return {
        sort: 'code',
        show_inactive: false,
        not_enrolled_in_time_off_policy: this.timeOffPolicy.id
      }
    },
    employeesToAddColumns() {
      return [
        {
          label: this.$t('Code'),
          prop: 'attributes.code',
          component: 'EntityLink',
          redirectTo: '/payroll/employees/{ID}/view',
          minWidth: 120,
          maxWidth: 200,
          headerCheckboxSelection: true,
          checkboxSelection: true,
        },
        {
          label: this.$t('Name'),
          prop: 'attributes.name',
          minWidth: 200,
        },
        {
          label: this.$t('Previous Year Carry Over'),
          prop: 'previous_year_carry_over_hours',
          minWidth: 150,
          hide: this.isUnlimited,
          valueFormatter: this.hoursFormatter,
          editable: this.isEditable,
        },
        {
          label: this.$t('Accrued Hours'),
          prop: 'current_year_accrued_hours',
          minWidth: 150,
          hide: this.isUnlimited,
          valueFormatter: this.hoursFormatter,
          editable: this.isEditable,
        },
        {
          label: this.$t('Used Hours'),
          prop: 'current_year_used_hours',
          minWidth: 150,
          hide: this.isUnlimited,
          valueFormatter: this.hoursFormatter,
          editable: this.isEditable,
        },
      ]
    }
  },
  methods: {
    isEditable(params) {
      const isSelected = params.node.selected
      if (isSelected) {
        return isSelected
      }
      if (!this.$notifications.state?.length) {
        this.$error(this.$t('Please select the employee first'))
      }
      return isSelected
    },
    hoursFormatter(params) {
      return `${params.value || 0} ${this.$t('hours')}`
    },
    async onRowSelected(params) {
      if (this.isUnlimited) {
        return
      }
      const selected = params.node.selected
      const editingParams = {
        rowIndex: params.node.rowIndex,
        colKey: 'balance',
      }
      await this.$nextTick()
      if (selected) {
        params.api.startEditingCell(editingParams)
      } else {
        params.api.stopEditing()
      }
    },
    async addEmployeesToPolicy() {
      try {
        this.loading = true
        const employees = this.selectedEmployees.map(employee => {
          return {
            id: employee.id,
            current_year_accrued_hours: (+employee.current_year_accrued_hours) || 0,
            current_year_used_hours: (+employee.current_year_used_hours) || 0,
            previous_year_carry_over_hours: (+employee.previous_year_carry_over_hours) || 0,
          }
        })

        await this.$store.dispatch('payroll/addEmployeesToTimeOffPolicy', {
          timeOffPolicyId: this.timeOffPolicy.id,
          employees,
        })

        this.$success(this.$t('Employees added to the policy'))
        this.$emit('save', false)
      } catch (err) {
        if (err.handled) {
          return
        }
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
