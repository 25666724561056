<template>
  <base-form :saveText="$t('Submit')"
             :show-cancel="showCancel"
             layout="modal"
             grid-classes="grid grid-cols-6 gap-x-3"
             @cancel="$emit('cancel')"
             @submit="onSubmit"
  >
    <template v-if="onlyRegularType">
      <div class="col-span-6 md:col-span-3">
        <billing-rate-type-select v-model="model.billing_rate_type_id"
                                  :exclude-ids="excludeBillingTypeIds"
                                  :add-temporary-entity="true"
                                  :autocomplete="false"
                                  rules="required"
                                  @entity-change="onChangeType"
        />
      </div>
      <div class="col-span-6 md:col-span-3">
        <base-input v-model="model.regular_rate"
                    :min="0"
                    :max="9999.999999"
                    :step="0.000001"
                    :label="$t('Regular')"
                    :name="$t('Regular Rate')"
                    type="number"
                    id="regular_rate"
        />
      </div>
    </template>
    <template v-else>
      <div class="col-span-6 border-b mb-4">
        <billing-rate-type-select v-model="model.billing_rate_type_id"
                                  :exclude-ids="excludeBillingTypeIds"
                                  :add-temporary-entity="true"
                                  :autocomplete="false"
                                  rules="required"
                                  @entity-change="onChangeType"
        />
      </div>
      <div class="col-span-6 md:col-span-2">
        <base-input v-model="model.regular_rate"
                    :min="0"
                    :max="9999.999999"
                    :step="0.000001"
                    :label="$t('Regular')"
                    :name="$t('Regular Rate')"
                    type="number"
                    id="_regular_rate"
                    @blur="computeNextRates"
        />
      </div>
      <div class="col-span-6 md:col-span-2">
        <base-input v-model="model.overtime_rate"
                    :min="0"
                    :max="9999.999999"
                    :step="0.000001"
                    :label="$t('Overtime')"
                    :name="$t('Overtime Rate')"
                    type="number"
                    id="overtime_rate"
        />
      </div>
      <div class="col-span-6 md:col-span-2">
        <base-input v-model="model.premium_rate"
                    :min="0"
                    :max="9999.999999"
                    :step="0.000001"
                    :label="$t('Premium')"
                    :name="$t('Premium Rate')"
                    type="number"
                    id="premium_rate"
        />
      </div>
    </template>
  </base-form>
</template>
<script>
  import { ratesEnum } from '@/enum/enums'

  export default {
    inheritAttrs: false,
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      definedBillingTypes: {
        type: Array,
        default: () => [],
      },
      onlyRegularType: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        showCancel: true,
        model: {
          billing_rate_type_id: null,
          regular_rate: 0,
          overtime_rate: 0,
          premium_rate: 0,
        },
        billingRateType: {}
      }
    },
    computed: {
      excludeBillingTypeIds() {
        return this.definedBillingTypes.filter(id => id !== this.model?.billing_rate_type_id)
      },
    },
    methods: {
      onChangeType(rateType) {
        this.billingRateType = rateType
      },
      onSubmit() {
        const objToEmit = {
          ...this.model,
          billingRateType: this.billingRateType,
        }
        this.$emit('save', objToEmit)
      },
      computeNextRates() {
        this.model.overtime_rate = this.model.regular_rate * ratesEnum.OvertimeMultiplier
        this.model.premium_rate = this.model.regular_rate * ratesEnum.PremiumMultiplier
      },
    },
    watch: {
      data: {
        immediate: true,
        handler(value) {
          this.model = {
            ...this.model,
            ...value,
          }
        },
      },
    },
  }
</script>
