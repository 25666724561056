<template>
  <LumpSumBillingForm
      :key="renderKey"
      @create-another="renderKey++"
  />
</template>
<script>
  import LumpSumBillingForm from '@/modules/accounts-receivable/components/lump-sum-billings/LumpSumBillingForm'

  export default {
    components: {
      LumpSumBillingForm,
    },
    data() {
      return {
        renderKey: 1,
      }
    },
  }
</script>
