<template>
  <div id="app" class="w-screen print:w-full">
    <notifications/>
    <router-view></router-view>
  </div>
</template>
<script>
  import { isAppleDevice } from "@/utils/isAppleDevice";
  import { checkOrganizationValidity } from "@/modules/auth/utils/organizationCheckUtils";

  export default {
    computed: {
      currentCompany() {
        return this.$store.getters['company/getCurrentCompany']
      },
      companyName() {
        return this.get(this.currentCompany, 'name')
      }
    },
    methods: {
      setTitle(route) {
        if (!route || !route.name) {
          return
        }
        let title = route.name
        if (this.companyName) {
          title = `${title} - ${this.companyName}`
        }
        title = `${title} - Construction Partner`
        document.title = title
      },
      checkNonAppleDevice() {
        if (isAppleDevice()) {
          return
        }
        document.documentElement.classList.add('visible-scroll')
      },
    },
    watch: {
      $route(newRoute) {
        this.setTitle(newRoute)
      }
    },
    mounted() {
      checkOrganizationValidity()
      this.$store.dispatch('theme/setDefaultPreferences')
      this.setTitle(this.$route)
      this.checkNonAppleDevice()
    },
  }
</script>
