<template>
  <entity-select
      v-bind="$attrs"
      v-on="$listeners"
      :url-params="urlParams"
      :name="$attrs.name || $t('Equipment Specialty')"
      :placeholder="$attrs.placeholder || $t('Select Equipment Specialty')"
      :label="$attrs.label !== undefined ? $attrs.label : $t('Equipment Specialty')"
      :add-label="$t('Add new Equipment')"
      :id="$attrs.id || 'equipment-select'"
      :table-columns="tableColumns"
      :label-format="formatLabel"
      url="/restify/equipment-specialties"
      label-key="code"
  />
</template>
<script>
  export default {
    name: 'EquipmentSpecialtySelect',
    inheritAttrs: false,
    props: {
      urlParams: {
        type: Object,
        default: () => ({
          sort: 'code',
        }),
      },
    },
    data() {
      return {
        tableColumns: [
          {
            minWidth: 100,
            maxWidth: 150,
            name: this.$t('Code'),
            prop: 'attributes.code',
          },
          {
            minWidth: 200,
            maxWidth: 200,
            name: this.$t('Description'),
            prop: 'attributes.description',
          },
        ],
      }
    },
    methods: {
      formatLabel(equipment) {
        const {code, description} = equipment.attributes
        if (!description) {
          return code
        }
        return `${code} (${description})`
      },
    },
  }
</script>
