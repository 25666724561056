<template>
  <div>
    <div class="flex justify-between items-center w-full leading-4">
      <EmployeeLink :id="employeeId"/>
      <div class="flex space-x-2 items-center">
        <BaseButton
          v-if="canReset"
          :loading="resetLoading"
          variant="gray-light"
          size="xs"
          @click="onReset"
        >
          <RotateCcwIcon class="w-4 h-4 mr-2"/>
          <span>{{ $t('Reset all') }}</span>
        </BaseButton>
        <BaseButton
          v-if="canReject"
          :loading="rejectLoading"
          variant="gray-light"
          size="xs"
          @click="showRejectDialog = true"
        >
          <XIcon class="w-4 h-4 mr-2"/>
          <span>{{ $t('Reject all') }}</span>
        </BaseButton>
        <BaseButton
          v-if="canApprove"
          :loading="approveLoading"
          variant="primary-light"
          size="xs"
          @click="onApprove">
          <CheckIcon class="w-4 h-4 mr-2"/>
          <span>{{ $t('Approve all') }}</span>
        </BaseButton>
        <TableEditButton @click="editTimesheet"/>
      </div>
      <BaseFormDialog
        v-if="showRejectDialog"
        :open.sync="showRejectDialog"
        :title="$t('Reject Timesheet')"
        :append-to-body="true"
        :loading="rejectLoading"
      >
        <BaseForm
          :save-text="$t('Reject')"
          layout="modal"
          @submit="onReject">
          <base-textarea
            v-model="rejectReason"
            :label="$t('Reject Reason')"
            rules="required"
            class="col-span-6"
          />
        </BaseForm>
      </BaseFormDialog>
    </div>
  </div>
</template>
<script>

import { CheckIcon, RotateCcwIcon, XIcon } from "vue-feather-icons";
import { TimesheetStatus } from "@/modules/payroll/components/timesheets/utils";
import axios from "axios";

export default {
  components: { RotateCcwIcon, XIcon, CheckIcon },
  data() {
    return {
      approveLoading: false,
      rejectLoading: false,
      resetLoading: false,
      showRejectDialog: false,
      rejectReason: '',
    }
  },
  computed: {
    children() {
      return this.params.node?.allLeafChildren
    },
    data() {
      return this.children[0]?.data || {}
    },
    employeeId() {
      return this.data?.attributes?.employee_id || {}
    },
    canReset() {
      return this.children.some(this.notResetFilter)
    },
    canReject() {
      return this.children.some(this.notRejectedFilter)
    },
    canApprove() {
      return this.children.some(this.notApprovedFilter)
    }
  },
  methods: {
    notResetFilter(item) {
      const status = item?.data?.attributes.status
      return [TimesheetStatus.Approved, TimesheetStatus.Rejected].includes(status)
    },
    notRejectedFilter(item) {
      const status = item?.data?.attributes.status
      return [TimesheetStatus.Pending, TimesheetStatus.Approved, TimesheetStatus.Submitted].includes(status)
    },
    notApprovedFilter(item) {
      const status = item?.data?.attributes.status
      return [TimesheetStatus.Pending, TimesheetStatus.Rejected, TimesheetStatus.Submitted].includes(status)
    },
    async onReset() {
      try {
        this.resetLoading = true
        const notReset = this.children.filter(this.notResetFilter)
        const promises = notReset.map(timesheet => {
          return axios.post(`/restify/timesheets/${timesheet?.data?.id}/actions?action=reset-timesheet`)
        })
        await Promise.all(promises)
        notReset.forEach(timesheet => {
          timesheet.data.attributes.rejected_at = null
          timesheet.data.attributes.approved_at = null
          timesheet.data.attributes.status = timesheet.data.attributes.clocked_out_at ? TimesheetStatus.Submitted : TimesheetStatus.Pending
        })
      } finally {
        this.resetLoading = false
      }
    },
    async onReject() {
      try {
        this.rejectLoading = true
        const notRejected = this.children.filter(this.notRejectedFilter)
        const rejectionReason = this.rejectReason
        const promises = notRejected.map(timesheet => {
          return axios.post(`/restify/timesheets/${timesheet?.data?.id}/actions?action=reject-timesheet`, {
            rejection_reason: this.rejectReason,
          })
        })
        this.rejectReason = ''
        this.showRejectDialog = false
        await Promise.all(promises)
        notRejected.map(timesheet => {
          timesheet.data.attributes.rejected_at = new Date().toISOString()
          timesheet.data.attributes.approved_at = null
          timesheet.data.attributes.rejection_reason = rejectionReason
          timesheet.data.attributes.status = TimesheetStatus.Rejected
        })
      } finally {
        this.rejectLoading = false
      }
    },
    async onApprove() {
      try {
        this.approveLoading = true
        const notApproved = this.children.filter(this.notApprovedFilter)
        const promises = notApproved.map(timesheet => {
          return axios.post(`/restify/timesheets/${timesheet?.data?.id}/actions?action=approve-timesheet`)
        })
        await Promise.all(promises)
        notApproved.map(timesheet => {
          timesheet.data.attributes.rejected_at = null
          timesheet.data.attributes.approved_at = new Date().toISOString()
          timesheet.data.attributes.status = TimesheetStatus.Approved
        })
      } finally {
        this.approveLoading = false
      }
    },
    editTimesheet() {
      this.$router.push({
        path: '/payroll/timesheets/review',
        query: {
          employee_id: this.employeeId,
          from: this.params.from,
          to: this.params.to,
        }
      })
    }
  }
}
</script>
