<template>
  <div class="h-full">
    <AgDataTable
        :columns="columns"
        :add-text="$t('New DD Code')"
        :edit-entity-url-query="editEntityUrlQuery"
        ref="table"
        url="/restify/direct-deposit-codes"
        permission="employees"
        actions="search,refresh,add,edit"
        hide-actions="view"
        @add="onRowAdd"
        @edit="onRowEdit"
        @view="onRowEdit"
    >
    </AgDataTable>
    <BaseFormDialog
        v-if="showDialog"
        :title="rowToEdit ? $t('Edit Direct Deposit Code') : $t('Add Direct Deposit Code')"
        :open.sync="showDialog"
        @close="onEventTrigger(false)"
    >
      <DirectDepositCodeForm
          :data="rowToEdit"
          :key="renderKey"
          @cancel="onEventTrigger(false)"
          @save="onEventTrigger"
          @create-another="renderKey++"
      />
    </BaseFormDialog>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { Column } from '@/components/ag-grid/tableTypes'
  import DirectDepositCodeForm from '@/modules/payroll/components/codes/DirectDepositCodeForm.vue'

  export default defineComponent({
    components: {
      DirectDepositCodeForm,
    },
    data() {
      return {
        editEntityUrlQuery: <string> '?id={ID}',
        rowToEdit: <any> null,
        showDialog: <boolean>false,
        renderKey: <number> 1,
      }
    },
    computed: {
      columns(): Column[] {
        return [
          {
            headerName: this.$t('Code'),
            field: 'attributes.code',
            component: 'EntityLink',
            redirectTo: '/payroll/codes/direct-deposit-codes?id={ID}',
            maxWidth: 300,
            minWidth: 160,
          },
          {
            headerName: this.$t('Description'),
            field: 'attributes.description',
            minWidth: 200,
          },
          {
            label: this.$t('Active'),
            prop: 'attributes.active',
            component: 'Status',
            align: 'center',
            minWidth: 60,
          },
        ]
      },
    },
    methods: {
      onRowEdit(row: any) {
        this.showDialog = true
        this.rowToEdit = row
      },
      onRowAdd() {
        this.showDialog = true
      },
      refreshTable() {
        // @ts-ignore
        this.$refs.table.refresh()
      },
      onEventTrigger(refresh = true) {
        this.rowToEdit = null
        this.showDialog = false

        if (!refresh) {
          return
        }

        this.refreshTable()
      },
    },
  })
</script>
