<template>
  <div class="import-data">
    <ReportPresetWrapper :report-type="type"
                         :key="type"
                         @change="onApplyPreset"
    >
    </ReportPresetWrapper>
    <base-form
        :loading="loading"
        :save-text="$t('Import')"
        :show-back="true"
        :show-cancel="true"
        :can-create-another-entity="false"
        :auto-height="true"
        :submit-disabled="!isMappingValid"
        :submit-tooltip="!isMappingValid ? $t('Not all the required columns were mapped') : undefined"
        class="w-full"
        layout="vertical"
        @submit="importData"
        @cancel="onCancel"
    >

      <ImportPreview
          :report-type="type"
          :initial-mapping="initialMapping"
          :column-options="columnOptions"
          :column-mappings="columnMappings"
          :errors="importErrors"
          :preset="selectedPreset"
          :resource="resource"
          ref="importPreview"
          class="col-span-6"
          @valid-change="isMappingValid = $event"
      >

        <slot :model="model">
          <div v-if="showOverwrite" class="col-span-6 md:col-span-1">
            <label class="block text-sm font-medium leading-5 text-gray-700 mb-3">
              {{ $t('Update matching data') }}
            </label>
            <base-switch v-model.number="model.overwrite"
                         id="overwrite"
            />
          </div>
        </slot>

      </ImportPreview>

      <template
        v-if="showTemplateDownload"
        #extra-buttons-right
      >
        <div
          class="mr-4">
          <DownloadImportTemplate
            :import-url="url"
            :name="resource"
          />
        </div>
      </template>
    </base-form>
  </div>
</template>
<script>
  import axios from 'axios'
  import PeriodEndDatePicker from "@/modules/payroll/components/timecard/PeriodEndDatePicker";
  import ReportPresetWrapper from "@/modules/common/components/reports/ReportPresetWrapper";
  import ImportPreview from "@/modules/common/components/import/ImportPreview";
  import DownloadImportTemplate from "@/modules/common/components/import/DownloadImportTemplate.vue";

  export default {
    components: {
      DownloadImportTemplate,
      ReportPresetWrapper,
      PeriodEndDatePicker,
      ImportPreview,
    },
    props: {
      url: {
        type: String,
      },
      columnOptions: {
        type: Array,
        default: () => [],
      },
      columnMappings: {
        type: Object,
        default: () => ({}),
      },
      initialMapping: {
        type: Object,
        default: () => ({})
      },
      type: {
        type: String,
      },
      transformRequestData: {
        type: Function
      },
      resource: {
        type: String,
        default: 'timecards',
      },
      showOverwrite: {
        type: Boolean,
        default: true
      },
      showTemplateDownload: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        model: {
          file: null,
          start_row: 1,
          overwrite: this.showOverwrite,
          mapping: {
            ...this.initialMapping,
          },
        },
        importErrors: [],
        loading: false,
        selectedPreset: null,
        isMappingValid: false,
      }
    },
    methods: {
      onApplyPreset(preset) {
        this.selectedPreset = preset
      },
      getColumnMappings() {
        // This is necessary because we have an extra index column that is not considered in the import
        const mapping = this.$refs.importPreview?.model?.mapping || {}
        let resultMapping = {}

        for(let key in mapping) {
          let value = mapping[key]
          if (value > 0) {
            value = value - 1
          }
          resultMapping[key] = value
        }

        return resultMapping
      },
      async onCancel() {
        const confirmed = await this.$confirm({
          title: this.$t('Are you sure you want to cancel?'),
          buttonText: this.$t('Yes'),
        })
        if (confirmed) {
          await this.$router.go(-1)
        }
      },
      async importData() {
        if (!this.$refs?.importPreview.model.file) {
          this.$error(this.$t('Please select a file to import'))
          return
        }
        try {
          this.loading = true
          const formData = new FormData()
          const file = await this.$refs?.importPreview?.convertDataToCSV()

          const mapping = this.getColumnMappings()
          let startRow = this.model.start_row + 1
          const overwrite = +this.model.overwrite

          formData.append('file', file)
          formData.append('start_row', startRow?.toString())
          formData.append('overwrite', overwrite?.toString())
          formData.append('mapping', JSON.stringify(mapping))

          if (this.transformRequestData) {
            this.transformRequestData(formData)
          }

          const { data } = await axios.post(this.url, formData)
          this.importErrors = []

          this.$emit('success', data)

        } catch (err) {
          console.log(err)
          this.importErrors = err?.response?.data?.errors || []
          if (!err.handled) {
            this.$error(this.$t(`Could not import the ${this.resource}. Please try again.`))
          }
        } finally {
          this.loading = false
        }
      },
    }
  }
</script>
<style lang="scss">
  .import-data .report-preset-button {
    @apply mt-0;
  }
  .import-data .form__inner {
    overflow: inherit !important;
  }
</style>
