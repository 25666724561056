<template>
  <div
      v-bind="$attrs"
      v-on="$listeners"
  >
    <slot />
  </div>

</template>
<script>
  export default {
    inheritAttrs: false,
    name: 'PreviewFixedWrapper',
    mounted() {
      document.body.appendChild(this.$el);
    },
    destroyed() {
      // remove DOM node after destroy
      if (this.$el && this.$el.parentNode) {
        this.$el.parentNode.removeChild(this.$el);
      }
    }
  }
</script>
