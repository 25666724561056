<template>
  <base-form :loading="loading"
             :showCancel="showCancel"
             :showBack="showBack"
             :save-text="$t('Create crew labor rate')"
             :update-text="$t('Update crew labor rate')"
             layout="modal"
             @cancel="$emit('cancel')"
             @submit="onSubmit"
  >
    <div class="col-span-6">
      <base-input v-model="model.code"
                  :label="$t('Code')"
                  :placeholder="$t('Crew Code')"
                  id="code"
                  rules="required|code"
      />
    </div>
    <div class="col-span-6">
      <base-input v-model="model.descriptions[0]"
                  :label="$t('Descriptions')"
                  :placeholder="$t('Primary Description')"
                  id="description_0"
                  rules="max:150"
                  inline-errors
      />
    </div>
    <div class="col-span-6">
      <base-input v-model="model.descriptions[1]"
                  :placeholder="$t('Additional Description')"
                  id="description_1"
                  rules="max:150"
      />
    </div>
    <div class="col-span-6">
      <el-collapse v-model="activeGroups">
        <el-collapse-item key="estimated_costs"
                          :title="$t('Add the Following Markup Percentages to Estimated Cost')"
                          name="estimated_costs">
          <div class="grid grid-cols-6 gap-x-3 border-t pt-5 px-1">
            <div v-for="i in 10" :key="i" class="col-span-6">
              <div class="text-base leading-6 font-medium text-gray-500 flex w-full justify-end">
                {{ $t('Area') }} {{ i }}
                <span class="px-2 text-sm text-green-500">
                  {{ getTotalCost(i) }}
                </span>
              </div>
              <div class="flex flex-col md:flex-row">
                <div class="w-full md:w-1/2 md:mr-2">
                  <base-input v-model="model[`direct_${i}`]"
                              :label="`Direct ${i}`"
                              :placeholder="$t('e.g 100.00')"
                              :id="`direct_${i}`"
                              :key="`direct_${i}`"
                              :min="0"
                              :max="9999999999.999999"
                              :step="0.000001"
                              type="number"
                              format="price"
                              rules="max_value:9999999999.999999|min_value:0"
                  />
                </div>
                <div class="w-full md:w-1/2 md:ml-2">
                  <base-input v-model="model[`burden_${i}`]"
                              :label="`Burden ${i}`"
                              :placeholder="$t('e.g 100.00')"
                              :id="`burden_${i}`"
                              :key="`burden_${i}`"
                              :min="0"
                              :max="999.99"
                              :step="0.01"
                              type="number"
                              format="percent"
                              rules="max_value:999.99|min_value:0"
                  />
                </div>
              </div>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </base-form>
</template>
<script>
  import axios from 'axios'

  export default {
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      showBack: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        loading: false,
        showCancel: true,
        activeGroups: [],
        model: {
          cost: '',
          descriptions: [],
          direct_1: 0,
          direct_2: 0,
          direct_3: 0,
          direct_4: 0,
          direct_5: 0,
          direct_6: 0,
          direct_7: 0,
          direct_8: 0,
          direct_9: 0,
          direct_10: 0,
          burden_1: 0,
          burden_2: 0,
          burden_3: 0,
          burden_4: 0,
          burden_5: 0,
          burden_6: 0,
          burden_7: 0,
          burden_8: 0,
          burden_9: 0,
          burden_10: 0,
        },
      }
    },
    watch: {
      data: {
        immediate: true,
        handler(value) {
          this.model = {
            ...this.model,
            ...value,
          }
        },
      },
    },
    methods: {
      getTotalCost(i) {
        const total = this.model[`direct_${i}`] * this.model[`burden_${i}`] / 100
        const result = (Number(total) + Number(this.model[`direct_${i}`]))
        return this.$formatPrice(result)
      },
      async onSubmit() {
        try {
          this.loading = true
          if (this.model.id) {
            await axios.put(`/restify/crew-labor-rates/${this.model.id}`, this.model)
            this.$success(this.$t('Crew labor rates updated'))
          } else {
            await axios.post('/restify/crew-labor-rates', this.model)
            this.$success(this.$t('Crew labor rates added'))
          }
          this.$emit('save', this.model)
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not update the crew labor rates'))
        } finally {
          this.loading = false
        }
      },
    },
  }
</script>
<style lang="scss">
  .el-collapse-item .el-collapse-item__header {
    @apply text-base;
  }
</style>
