<template>
  <BaseCard class="border">
    <div class="text-sm flex flex-col space-y-4">
      <div :title="$t('Open Amount')"
           class="detail-row">
        <IconOpenAmount class="detail-icon"/>
        <div class="flex w-full justify-between space-x-2">
          <span class="text-sm truncate">{{ $t('Open Amount') }}</span>
          {{ formatPrice(customerData.current_ar_amount) }}
        </div>
      </div>
      <div :title="$t('Retention')"
           class="detail-row">
        <IconRetention class="detail-icon"/>
        <div class="flex w-full justify-between space-x-2">
          <span class="text-sm truncate">{{ $t('Retention') }}</span>
          {{ formatPrice(customerData.retention_amount) }}
        </div>
      </div>
      <div :title="$t('Sales to-date')"
           class="detail-row">
        <IconSalesToDate class="detail-icon"/>
        <div class="flex w-full justify-between space-x-2">
          <span class="text-sm truncate">{{ $t('Sales To-Date') }}</span>
          {{ formatPrice(customerData.sales_activity_to_date) }}
        </div>
      </div>
      <div :title="$t('Sales this year')"
           class="detail-row">
        <IconSalesThisYear class="detail-icon"/>
        <div class="flex w-full justify-between space-x-2">
          <span class="text-sm truncate">{{ $t('Sales This Year') }}</span>
          {{ formatPrice(salesActivities.this_year) }}
        </div>
      </div>
      <div :title="$t('Sales last year')"
           class="detail-row">
        <IconSalesLastYear class="detail-icon"/>
        <div class="flex w-full justify-between space-x-2">
          <span class="text-sm truncate">{{ $t('Sales Last Year') }}</span>
          {{ formatPrice(salesActivities.this_year) }}
        </div>
      </div>
    </div>
  </BaseCard>
</template>
<script lang="ts" setup>
import Data = API.Data
import { computed, PropType } from 'vue'
import { formatPrice } from '@/plugins/formatPrice'
import { getActivitiesThisAndLastYear } from '@/utils/utils'
import { Customer } from '@/modules/common/types/models'
import { DollarSignIcon } from 'vue-feather-icons'

const props = defineProps({
  customer: {
    type: Object as PropType<Data<Customer>>,
    default: () => ({}),
    required: true,
  }
})

const customerData = computed((): Customer => {
  return props.customer?.attributes || {}
})
const salesActivities = computed(() => {
  return getActivitiesThisAndLastYear(props.customer)
})

</script>
