<template>
  <div class="import-data mt-4">
    <ImportForm
        v-bind="importSettings"
        @success="redirectToList"
    />
  </div>
</template>
<script>
import ImportForm from '@/modules/common/components/import/ImportForm';
import { getInitialMapping } from '@/modules/payroll/utils/timecardImportColumns';

const colMappings = {
  Code: {
    value: 'code',
    label: 'Code',
    alternativeLabels: ['Benefit Code'],
    required: true,
  },
  Description: {
    value: 'description',
    label: 'Description',
  },
  CalculateBy: {
    value: 'calculate_by',
    label: 'Calculate By',
    alternativeLabels: ['Calculated', 'Calculate By'],
    required: true,
  },
  MultiplyBy: {
    value: 'multiply_by',
    label: 'Multiply By',
    alternativeLabels: ['Multiplied', 'Multiplied By'],
    required: true,
  },
  Account: {
    value: 'account',
    label: 'Account',
    alternativeLabels: [],
  },
  SubAccount: {
    value: 'subaccount',
    label: 'SubAccount',
    alternativeLabels: [],
  },
  IncludeOnUnionReports: {
    value: 'include_on_union_reports',
    label: 'Include On Union Report',
    alternativeLabels: [],
  },
  ReduceDisposableEarnings: {
    value: 'reduce_disposible_earnings',
    label: 'Reduce Disposable Earnings',
    alternativeLabels: [],
  },
  SubjectToFica: {
    value: 'subject_to_fica',
    label: 'Fica',
    alternativeLabels: [],
  },
  SubjectToFit: {
    value: 'subject_to_fit',
    label: 'Fit',
    alternativeLabels: [],
  },
  SubjectToFui: {
    value: 'subject_to_fui',
    label: 'Fui',
    alternativeLabels: [],
  },
  SubjectToLiabilityInsurance: {
    value: 'subject_to_liability_insurance',
    label: 'Subject To Liability Insurance',
    alternativeLabels: ['Gen Liab'],
  },
  SubjectToLit: {
    value: 'subject_to_lit',
    label: 'Lit',
    alternativeLabels: [],
  },
  SubjectToSdi: {
    value: 'subject_to_sdi',
    label: 'Sdi',
    alternativeLabels: [],
  },
  SubjectToSit: {
    value: 'subject_to_sit',
    label: 'Sit',
    alternativeLabels: [],
  },
  SubjectToSui: {
    value: 'subject_to_sui',
    label: 'Sui',
    alternativeLabels: [],
  },
  SubjectToWorkersComp: {
    value: 'subject_to_workers_comp',
    label: 'Subject To Workers Comp',
    alternativeLabels: ['WrkCmp'],
  },
}

const options = [
  colMappings.Code,
  colMappings.Description,
  colMappings.CalculateBy,
  colMappings.MultiplyBy,
  colMappings.Account,
  colMappings.SubAccount,
  colMappings.IncludeOnUnionReports,
  colMappings.ReduceDisposableEarnings,
  colMappings.SubjectToFica,
  colMappings.SubjectToFit,
  colMappings.SubjectToFui,
  colMappings.SubjectToLiabilityInsurance,
  colMappings.SubjectToLit,
  colMappings.SubjectToSdi,
  colMappings.SubjectToSit,
  colMappings.SubjectToSui,
  colMappings.SubjectToWorkersComp,
]

export default {
  components: {
    ImportForm,
  },
  data() {
    return {
      importSettings: {
        url: '/restify/flexible-benefit-plans/import',
        columnOptions: options,
        columnMappings: colMappings,
        initialMapping: getInitialMapping(options),
        type: 'flexible-benefit-plan-import',
        resource: 'flexible-benefit-plans',
      },
    }
  },
  methods: {
    async redirectToList() {
      await this.$router.push('/payroll/codes/flexible-benefit-plans')
    },
  },
}
</script>
