<template>
  <div>
    <ImportForm v-bind="importSettings"
                @success="redirectToList"
    >
      <div class="col-span-6 md:col-span-1">
        <JobSelectNew v-model="model.job_id"
                   id="job_id"
                   rules="required"
        />
      </div>
      <div class="col-span-6 md:col-span-1">
        <BaseSelect
            v-model="model.type"
            :label="$t('Budget Type')"
            :options="costOptions"
            rules="required"
        />
      </div>
    </ImportForm>
  </div>
</template>
<script>
  import { getInitialMapping } from "@/modules/payroll/utils/timecardImportColumns";
  import ImportForm from "@/modules/common/components/import/ImportForm";
  import PeriodEndDatePicker from "@/modules/payroll/components/timecard/PeriodEndDatePicker";
  import { costTypes } from "@/enum/enums";
  import { globalResources } from "@/components/form/util";
  import { costOptions, JobTypeFor } from "@/modules/job-costing/enum/jobs";
  import JobSelectNew from "@/components/select/entities/JobSelectNew.vue";

  const colMappings = {
    Description: {
      value: 'description',
      label: 'Description',
      alternativeLabels: ['Item Description'],
    },
    Phase: {
      value: 'phase_code',
      label: 'Phase',
      alternativeLabels: ['Phase Code', 'Phase_Code'],
      required: mapping => {
        return mapping.cost_code === -1
      }
    },
    CostCode: {
      value: 'cost_code',
      label: 'Cost Code',
      alternativeLabels: ['Cost', 'Cost_Code'],
      required: mapping => {
        return mapping.phase_code === -1
      }
    },
    ChangeOrder: {
      value: 'change_order',
      label: 'Change Order',
      alternativeLabels: ['CNG', 'Change_Order', 'CHG ORD'],
    },
  }

  export default {
    components: {
      ImportForm,
      JobSelectNew,
      PeriodEndDatePicker,
    },
    data() {
      return {
        costOptions,
        model: {
          job_id: this.$route.query.jobId || null,
          type: null,
        },
      }
    },
    computed: {
      jobTypes() {
        let types = this.$store.state.globalLists[globalResources.JobTypes]
        return types.filter(t => t.type === this.model.type)
      },
      columnOptions() {
        const extraColumns = []
        this.jobTypes.forEach((type) => {
          const amount = {
            value: `job_type_${type.index}_amount`,
            label: `${type.name} Amount`,
            alternativeLabels: [],
          }
          if (type.for !== JobTypeFor.Income.UnitPrice) {
            extraColumns.push(amount)
          }
          const typesWithOutQty = [
            JobTypeFor.Income.Progress,
            JobTypeFor.Income.LumpSum,
            JobTypeFor.Income.CostPlus,
            JobTypeFor.Cost.Subcontract,
          ]
          if (!typesWithOutQty.includes(type.for)) {
            const quantity = {
              value: `job_type_${type.index}_quantity`,
              label: `${type.name} Quantity`,
              alternativeLabels: [],
            }
            extraColumns.push(quantity)
          }

          const typesWithMeasure = [JobTypeFor.Income.UnitPrice, JobTypeFor.Cost.Material]

          if (typesWithMeasure.includes(type.for)) {
            const unitOfMeasure = {
              value: `job_type_${type.index}_um`,
              label: `${type.name} Unit of Measure`,
              alternativeLabels: [],
            }
            extraColumns.push(unitOfMeasure)
          }

          const typesWithUnitRate = [JobTypeFor.Income.UnitPrice]
          if (typesWithUnitRate.includes(type.for)) {
            const unitRate = {
              value: `job_type_${type.index}_unit_rate`,
              label: `${type.name} Unit Rate`,
              alternativeLabels: [],
            }
            extraColumns.push(unitRate)
          }
        })
        return [
          colMappings.Phase,
          colMappings.CostCode,
          colMappings.ChangeOrder,
          colMappings.Description,
          ...extraColumns,
        ]
      },
      importSettings() {
        return {
          url: '/restify/line-items/import',
          columnOptions: this.columnOptions,
          columnMappings: colMappings,
          initialMapping: getInitialMapping(this.columnOptions),
          type: 'line-items-import',
          transformRequestData: this.transformRequestData,
          resource: 'line-items',
        }
      }
    },
    methods: {
      transformRequestData(formData) {
        formData.append('job_id', this.model.job_id)
        formData.append('type', this.model.type)
      },
      async redirectToList() {
        const jobId = this.model.job_id
        await this.$router.push(`/job-costing/jobs/${jobId}/line-items`)
        await this.$store.dispatch('globalLists/getLineItemsConfig', true)
      },
    }
  }
</script>
