<template>
  <div :class="wrapperStyles">
    <component :is="icon"
               :class="iconStyles"
    />
    <span :class="nameStyles">
      {{ $companyName }}
    </span>
  </div>
</template>
<script>
  export default {
    props: {
      wrapperStyles: {
        type: String,
        default: 'flex items-center',
      },
      iconStyles: {
        type: String,
        default: 'w-5 h-5',
      },
      icon: {
        type: String,
        default: 'IconCompanies',
      },
      nameStyles: {
        type: String,
        default: 'text-gray-900 text-sm leading-5 font-medium truncate mx-1',
      },
    },
  }
</script>
