<template>
  <div>
    <BaseButton
      v-bind="$attrs"
      variant="primary-light"
      :loading="loading"
      @click="showDialog = true"
    >
      <PrinterIcon class="w-5 h-5 mr-2"></PrinterIcon>
      <span>{{ $t('Print Checks') }}</span>
    </BaseButton>

    <BaseFormDialog
      v-if="showDialog"
      :open.sync="showDialog"
      :title="title"
      :appendToBody="true"
    >
      <base-form
        :save-text="$t('Download Checks')"
        :loading="loading"
        :focus-on-first-input="false"
        @cancel="$emit('cancel')"
        layout="modal"
        @submit="onSubmit"
      >
        <div class="col-span-6">
          <base-checkbox
            id="print_checks"
            v-model="model.print_checks"
            :label="$t('Print Checks')"
          />
        </div>
        <div class="col-span-6">
          <base-checkbox
            id="print_dds"
            v-model="model.print_dds"
          >
            <template #label>
              {{ $t('Print Direct Deposits') }}
              <template v-if="model.print_dds">
                {{ $t(' (these will be printed after the checks.)') }}
              </template>
            </template>
          </base-checkbox>
        </div>
        <div class="col-span-6">
          <base-checkbox
            id="include_signature"
            v-model="model.include_signature"
          >
            <template #label>
              {{ $t('Print Signature') }}
              <template v-if="model.include_signature">
                {{ $t(' (if the bank has a signature, the signature will be included on each check)') }}
              </template>
            </template>
          </base-checkbox>
        </div>
        <div class="col-span-6">
          <base-checkbox
            id="cut_section"
            v-model="model.flags.cut"
            :label="$t('Show cut section with scissors?')"
          />
        </div>
      </base-form>
    </BaseFormDialog>
    <StimulsoftPrintButton
      ref="stimulsoftPrint"
      method="post"
      :url="`/invoice-payments/stimulsoft`"
      :url-data="printParams"
      :hide-button="true"
      :no-items-warning="$t('There are no checks to preview. All the recorded payments are either credit card or manual.')"
      entity="invoice-payment"
    />
  </div>
</template>
<script lang="ts" setup>
import {computed, ref} from "vue";
import i18n from "@/i18n";
import {info} from "@/components/common/NotificationPlugin";
import {PrinterIcon} from "vue-feather-icons";

const props = defineProps({
  journal: {
    type: Object,
  },
  journalName: {
    type: String,
  },
  payments: {
    type: Array,
    default: () => [],
  },
})

const loading = ref(false)
const showDialog = ref(false)

const model = ref({
  print_checks: true,
  print_dds: true,
  include_signature: true,
  flags: {
    cut: true,
  }
})

const printParams = computed(() => {
  let repositories
  if (props.payments) {
    repositories = props.payments.map((p: any) => p.id)
  }
  return {
    print_checks: Number(model.value.print_checks),
    print_dds: Number(model.value.print_dds),
    include_signature: Number(model.value.include_signature),
    journal_id: props.journal?.id,
    flags: model.value.flags,
    repositories,
  }
})

const title = computed(() => {
  if (props.journal) {
    return i18n.t(`Download checks for ${props.journalName}`)
  }
  const paymentsCount = i18n.tc('payments count', props.payments.length)
  return i18n.t(`Download checks for selected payments`) + ` (${paymentsCount})`
})

async function onSubmit() {
  await previewPDF()
}

const stimulsoftPrint = ref()

async function previewPDF() {
  if (!stimulsoftPrint.value) {
    info(i18n.t('The PDF preview module is still initializing... Please wait a few more seconds and try again.'))
    return
  }
  try {
    loading.value = true
    await stimulsoftPrint.value?.onPrintClick()
  } finally {
    loading.value = false
  }
}

function toggleDialog() {
  showDialog.value = !showDialog.value
}

function openDialog() {
  showDialog.value = true
}

defineExpose({
  toggleDialog,
  openDialog,
})
</script>
