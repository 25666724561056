import { gridContext } from "@/components/ag-grid/gridContext";
import { $modules } from "@/enum/enums";

export function getCurrentModule(route) {
  const moduleMap = {
    [$modules.GL]: $modules.GL,
    [$modules.JC]: $modules.JC,
    [$modules.AP]: $modules.AP,
    [$modules.AR]: $modules.AR,
    [$modules.PR]: $modules.PR,
    [$modules.SB]: $modules.SB,
    [$modules.EQP]: $modules.EQP,
    [$modules.INV]: $modules.INV,
    [$modules.EST]: $modules.EST,
    [$modules.SB]: $modules.SB,
    [$modules.LG]: $modules.LG,
    [$modules.SETTINGS]: $modules.SETTINGS,
    default: null
  }
  const pathArray = route?.path.split('/') || []

  if (!pathArray || pathArray.length < 1) {
    return moduleMap.default
  }
  // * Because first element of array is empty string
  const module = pathArray.at(1)

  return moduleMap[module] || moduleMap.default
}
export default {
  install(Vue) {
    Vue.mixin({
      computed: {
        activeFiscalYear() {
          return this.$store.getters['company/getSessionActiveFiscalYear']
        },
        $isPrintMode() {
          return gridContext.isPrintMode
        },
        $currentFiscalYear() {
          return this.$store.state.company.currentFiscalYear
        },
        $previousFiscalYear() {
          const currentFiscalYear = this.$store.state.company.currentFiscalYear
          if (!currentFiscalYear) {
            return null
          }
          const previousYear = currentFiscalYear - 1
          if (!this.isPreviousFiscalYearOpen) {
            return currentFiscalYear
          }
          return previousYear
        },
        isPreviousFiscalYearOpen() {
          const currentFiscalYear = this.$store.state.company.currentFiscalYear
          if (!currentFiscalYear) {
            return false
          }
          const previousYear = currentFiscalYear - 1
          return this.$store.getters['company/isFiscalYearOpen'](previousYear)
        },
        $createAnotherEntity() {
          return this.$store.state.user.createAnotherEntity
        },
        $formSaveText() {
          const id = this.$parent?.data?.id || this.$parent?.model?.id
          const updateText = this.updateText || 'Update'
          const saveText = this.saveText || 'Save'
          if (id) {
            return updateText
          }
          return saveText
        },
        $user() {
          return this.$store.state.auth?.user || {}
        },
        $isLoggedIn() {
          return this.$store.state.auth?.isLoggedIn
        },
        $isPrintPage() {
          return this.$route.path.startsWith('/print')
        },
        $currentCompany() {
          return this.$store.getters['company/getCurrentCompany']
        },
        $currentOrganization() {
          const current_organization_id = this.$user.current_organization_id
          const current_organization = this.$user.current_organization || {}
          return {
            id: current_organization_id,
            ...current_organization
          }
        },
        $companyName() {
          return this.$currentCompany?.abbr || this.$currentCompany?.name || 'N/A'
        },
        $companyState() {
          return this.$store.getters['company/getPrimaryCompanyState']
        },
        $currenPeriod() {
          return this.$store.getters['company/getCurrentPeriodValue']
        },
        $currentModule() {
          return getCurrentModule(this.$route)
        },
      },
      methods: {
        async $addSystemGeneratedNote(data = {}) {
          await this.$store.dispatch('notes/addSystemGeneratedNote', data)
        }
      },
      watch: {
        pageTitle: {
          immediate: true,
          handler(value) {
            if (value) {
              document.title = value
            }
          }
        }
      }
    })
  }
}
