<template>
  <BaseDataTable
    v-bind="defaultAttributes"
    :columns="columns"
    :data="getData"
    :total-rows="rowsLength"
    @force-pagination="forcePagination"
  >

    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template #thead-infos>
      <th colspan="11"/>
      <th
        colspan="2"
        class="thead-infos border-b-2 border-dashed pb-2"
      >
        <div class="text-center">
          {{ $t(`--Cost Per Job Unit--`)  }}
        </div>
      </th>
    </template>

    <template #header="{ row }">
      <span> {{ $t('Job:') }} </span>
      <JobLink
        :data="row.header.job"
        class="inline"
      />
    </template>

    <template #subtotal="{ subtotal }">
      <td colspan="3" />
      <td align="left">
        {{ subtotal?.description }}
      </td>
      <td />
      <td align="right">
        {{ $formatPrice(subtotal?.budget) }}
      </td>
      <td align="right">
        {{ $formatPrice(subtotal?.to_date) }}
      </td>
      <td align="right">
        {{ isNumber(subtotal?.complete) ? $formatPercent(subtotal?.complete) : '' }}
      </td>
      <td align="right">
        {{ $formatPrice(subtotal?.estimated_final_cost) }}
      </td>
      <td />
      <td align="right">
        {{ $formatPrice(subtotal?.estimated_variance) }}
      </td>
      
      <td align="right">
        {{
          subtotal?.job_budget_unit_cost
            ? $formatPrice(subtotal?.job_budget_unit_cost)
            : ''
        }}
      </td>
      
      <td align="right">
        {{
          subtotal?.job_unit_cost
            ? $formatPrice(subtotal?.job_unit_cost)
            : ''
        }}
      </td>
    </template>

    <template #html-row="{ htmlData }">
      <tr class="content-row font-semibold">
        <td colspan="13">
          <div class="py-4 ">
            {{ htmlData }}
          </div>
        </td>
      </tr>
    </template>

  </BaseDataTable>
</template>
<script>
import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'
import { JobCostVarianceReportOptions } from '@/modules/job-costing/components/reports/util'
import { isNumber } from '@/modules/common/util/commonUtils'

export default {
  extends: ReportTableWrapper,
  computed: {
    columns() {
      return [
        {
          label: this.$t('Phase'),
          prop: 'phase_code',
          minWidth: 120,
          maxWidth: 120,
        },
        {
          label: this.$t('Cost <br> Code'),
          prop: 'cost_code',
          minWidth: 50,
          maxWidth: 100,
          align: 'center',
        },
        {
          label: this.$t('Change <br> Order'),
          prop: 'change_order',
          minWidth: 50,
          maxWidth: 80,
          align: 'center',
        },
        {
          label: this.$t('Description'),
          prop: 'description',
          minWidth: 120,
          maxWidth: 220,
          component: 'EntityLink',
          redirectTo: '/job-costing/cost-line-items/{ID}/view',
        },
        {
          label: this.$t('Cost <br> Type'),
          prop: 'cost_type',
          minWidth: 50,
          maxWidth: 60,
          align: 'center',
        },
        {
          label: this.$t('Budget'),
          prop: 'budget',
          minWidth: 120,
          maxWidth: 150,
          align: 'right',
          component: 'FormattedPrice',
        },
        {
          label: this.$t('To-Date'),
          prop: 'to_date',
          minWidth: 120,
          maxWidth: 150,
          align: 'right',
          component: 'FormattedPrice',
        },
        {
          label: this.$t('% <br> Comp'),
          prop: 'complete',
          minWidth: 120,
          maxWidth: 150,
          align: 'right',
          formatter: (row, value) => {
            if (!isNumber(value)) {
              return ''
            }
            return this.$formatPercent(value)
          },
        },
        {
          label: this.$t('Est. Cost <br> @ Complete'),
          prop: 'estimated_final_cost',
          minWidth: 120,
          maxWidth: 150,
          align: 'right',
          component: 'FormattedPrice',
        },
        {
          label: this.$t('Last <br> Update'),
          prop: 'last_updated_at',
          minWidth: 120,
          maxWidth: 120,
          align: 'center',
          component: 'FormattedDate',
        },
        {
          label: this.$t('Est. Variance <br> @ Complete'),
          prop: 'estimated_variance',
          minWidth: 120,
          maxWidth: 150,
          align: 'right',
          component: 'FormattedPrice',
        },
        {
          label: this.$t('Budget'),
          prop: 'job_budget_unit_cost',
          minWidth: 120,
          maxWidth: 150,
          align: 'right',
          component: 'FormattedPrice',
        },
        {
          label: this.$t('Projected'),
          prop: 'job_unit_cost',
          minWidth: 120,
          maxWidth: 150,
          align: 'right',
          component: 'FormattedPrice',
        },
      ]
    },
    format() {
      return this.options.format
    },
    printPhaseTotals() {
      return [1, "1", true].includes(this.options.phase_totals)
    },
  },
  methods: {
    isNumber,
    addJobLineItems(jobGroup) {
      const lineItems = jobGroup.line_items

      if (!this.printPhaseTotals) {
        lineItems.forEach(this.addLineDetails)
        return
      }

      for (const phaseCode in lineItems) {
        // Add phase lines
        const lines = lineItems[phaseCode].phase

        lines.forEach(this.addLineDetails)

        // Add phase totals
        const phaseTotals = lineItems[phaseCode].totals

        this.rows.push({
          subtotal: {
            description: this.$t('Total Phase'),
            budget: phaseTotals.budget,
            to_date: phaseTotals.to_date,
            complete: phaseTotals.complete,
            estimated_final_cost: phaseTotals.estimated_final_cost,
            estimated_variance: phaseTotals.estimated_variance,
            job_budget_unit_cost: phaseTotals.job_budget_unit_cost,
            job_unit_cost: phaseTotals.job_unit_cost,
          }
        })
      }
      
    },
    addLineDetails(line) {
      const lineItem = line.line_item
      const lineTotals = line.totals

      const budget = this.format === JobCostVarianceReportOptions.Format.Detail
        ? ''
        : lineTotals.budget

      const to_date = this.format === JobCostVarianceReportOptions.Format.Detail
        ? ''
        : lineTotals.to_date

      const row = {
        id: lineItem.id,
        phase_code: lineItem.phase_code,
        cost_code: lineItem.cost_code,
        change_order: lineItem.change_order,
        description: lineItem.description,
        cost_type: lineItem.cost_type,
        budget,
        to_date,
        complete: lineTotals.complete,
        estimated_final_cost: lineTotals.estimated_final_cost,
        last_updated_at: lineTotals.last_updated_at,
        estimated_variance: lineTotals.estimated_variance,
        job_budget_unit_cost: lineTotals.job_budget_unit_cost,
        job_unit_cost: lineTotals.job_unit_cost,
      }

      this.rows.push(row)

      if (this.format === JobCostVarianceReportOptions.Format.Detail) {
        const costTypes = line.job_cost_types
        this.addLineItemCostTypes(costTypes, lineTotals)
      }
    },
    addLineItemCostTypes(costTypes, lineTotals) {
      const abbrs = Object.keys(costTypes.budgets)

      for (const abbr of abbrs) {
        this.rows.push({
          cost_type: abbr,
          budget: costTypes.budgets[abbr],
          to_date: costTypes.to_date[abbr],
        })
      }

      this.rows.push({
        subtotal: {
          description: this.$t('Total Cost Type'),
          budget: lineTotals.budget,
          to_date: lineTotals.to_date,
        }
      })
    },
    addJobSummaryAndTotals(jobGroup) {
      this.rows.push(
        {
          htmlData: this.$t('Job Summary'),
        },
      )

      const jobCostTypes = jobGroup.totals.job_cost_types
      const costTypesMap = jobCostTypes?.cost_type || {}

      const summaryRows = []
      for (const abbr in costTypesMap) {
        const costType = costTypesMap[abbr]

        summaryRows.push({
          description: costType.name,
          budget: jobCostTypes.budgets[abbr],
          to_date: jobCostTypes.to_date[abbr],
        })
      }

      this.rows.push(...summaryRows)

      const jobTotals = jobGroup.totals.totals
      this.rows.push({
        subtotal: {
          description: this.$t('Job Totals'),
          budget: jobTotals.budget,
          to_date: jobTotals.to_date,
          estimated_final_cost: jobTotals.estimated_final_cost,
          estimated_variance: jobTotals.estimated_variance,
          job_budget_unit_cost: jobTotals.job_budget_unit_cost,
          job_unit_cost: jobTotals.job_unit_cost,
        }
      })

    },
    addGrandTotals() {
      // TODO: Implement when  BE is ready
      this.rows.push(
        {
          htmlData: this.$t('Total All Selected Jobs (not implemented)'),
        },
      )
      const grandTotals = this.data.grand_totals
    },
    composeRows(data) {
      for (const jobGroup of data) {
        // Job header
        this.rows.push({
          header: {
            job: jobGroup.job,
          },
        })

        // Job line items
        this.addJobLineItems(jobGroup)

        // Job summary + totals
        this.addJobSummaryAndTotals(jobGroup)
      }

      this.addGrandTotals()

      return this.rows
    },
  }
}
</script>
