export function isProduction() {
  return import.meta.env.VITE_ENV === 'production' || import.meta.env.VITE_ENV === 'beta'
}

export function isDev() {
  return import.meta.env.VITE_ENV === 'develop'
}

export function isLocal() {
  return import.meta.env.VITE_ENV === 'local'
}

export function isDevOrLocal() {
  return isDev() || isLocal()
}

export function isExceptionDomain(domain = '') {
  return ['', 'cp4dev', 'cpsignin', 'cpsignon', 'cp4beta', 'cpdesktop', 'www'].includes(domain)
}
