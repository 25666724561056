<template>
  <SettingsActivityLogTable
      :settings-id="settingsId"
      :module="moduleName"
  />
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import SettingsActivityLogTable from '@/modules/common/components/SettingsActivityLogTable.vue'

  export default defineComponent({
    components: {
      SettingsActivityLogTable,
    },
    data() {
      return {
        moduleName: '' as string,
      }
    },
    computed: {
      settingsId(): string  {
        if (!this.moduleName) {
          return ''
        }

        return this.$store.getters['company/getCompanySettingsIdPerModule'](this.moduleName)
      },
    },
    watch: {
      '$route.meta': {
        immediate: true,
        handler(meta) {
          this.moduleName = meta.module
        },
      },
    },
  })
</script>
