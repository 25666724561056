<template>
  <base-select
    v-bind="$attrs"
    v-on="$listeners"
    v-model="model"
    :label="$t('View')"
    :options="options"
    :inline-errors="true"
  />
</template>
<script>
import { linkedLineItemViewTypes } from "@/modules/job-costing/enum/jobs";

export default {
  props: {
    value: {
      type: String,
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    options() {
      return [
        {
          label: this.$t('Cost vs Income'),
          value: linkedLineItemViewTypes.CostVsIncome,
        },
        {
          label: this.$t('Income vs Cost'),
          value: linkedLineItemViewTypes.IncomeVsCost,
        },
        {
          label: this.$t('Setup'),
          value: linkedLineItemViewTypes.Setup,
        },
      ]
    }
  }
}
</script>
