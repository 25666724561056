<template>
    <entity-select v-bind="$attrs"
                   v-on="$listeners"
                   url="/restify/users"
                   :name="$attrs.name || $t('User')"
                   :placeholder="$attrs.placeholder || $t('Select user')"
                   :label="$attrs.label"
                   :add-entity="false"
                   label-key="name"
                   :id="$attrs.id || 'user-select'"
                   :remote-search="true"
    />
</template>
<script>
  export default {
    inheritAttrs: false,
  }
</script>
