<template>
  <div>
    <InvoiceDetailsPage
        :loading="invoiceLoading"
        :data="originalInvoice"
        entity="invoices">
      <template #title>
        <invoice-status-popover
            :invoice="originalInvoice"
            placement="left"
            class="print:hidden"
        />
        <ReviewStatus
          v-if="showApproveStatus"
          :row="originalInvoice"
          class="ml-2 mr-2"
        />
        <status-badge
          v-if="originalInvoice?.attributes?.is_receipt"
          :status="$t('Receipt')"
          class="ml-1"
        />
        <RecurringInvoiceLink :invoice="originalInvoice"/>
      </template>
      <template #header-left>
        <div class="flex flex-col space-y-4 md:flex-row md:space-x-2 md:space-y-0">
          <VendorContactInfo :title="$t('From')" :vendor="vendor"/>
          <CompanyContactInfo :title="$t('Invoice For')" :company="$currentCompany"/>
        </div>
      </template>
      <template #content>
        <InvoiceDescription
          v-if="invoice.id"
          :invoice="invoice"
        />
        <InvoiceDetails
          v-if="invoice.id"
          :invoice="invoice"
          :no-borders="true"
          :no-side-borders="true"
        />
      </template>
    </InvoiceDetailsPage>

    <base-back-link
      v-if="showBack"
      to="/accounts-payable/invoices"
    />
  </div>
</template>

<script>
  import InvoiceDetails from '@/modules/accounts-payable/pages/invoices/InvoiceDetails'
  import VendorContactInfo from "@/modules/accounts-payable/components/invoice/VendorContactInfo";
  import CompanyContactInfo from "@/modules/accounts-payable/components/invoice/CompanyContactInfo";
  import InvoiceDetailsPage from "@/components/invoice/InvoiceDetailPage";
  import InvoiceDescription from "@/modules/accounts-payable/components/invoice/InvoiceDescription.vue";
  import ReviewStatus from "@/components/links/ReviewStatus.vue";
  import { resourceStatuses } from "@/enum/enums";
  import RecurringInvoiceLink from "@/modules/accounts-payable/pages/invoices/RecurringInvoiceLink.vue";

  export default {
    props: {
      id: [String, Number],
      showBack: {
        type: Boolean,
        default: true,
      }
    },
    components: {
      RecurringInvoiceLink,
      ReviewStatus,
      InvoiceDescription,
      InvoiceDetailsPage,
      CompanyContactInfo,
      InvoiceDetails,
      VendorContactInfo,
    },
    computed: {
      originalInvoice() {
        return this.$store.state.accountsPayable.currentInvoice || {
          attributes: {},
          relationships: {},
          meta: {},
        }
      },
      invoiceLoading() {
        return this.$store.state.accountsPayable.invoiceLoading
      },
      showApproveStatus() {
        const status = this.originalInvoice?.attributes?.status
        return [resourceStatuses.Pending, resourceStatuses.NoPost].includes(status)
      },
      invoice() {
        return {
          ...this.originalInvoice?.attributes || {},
          ...this.originalInvoice?.relationships || {},
        }
      },
      vendor() {
        const vendorData = this.originalInvoice?.relationships?.vendor || { attributes: {}, relationships: {} }
        return {
          ...vendorData?.attributes,
          ...vendorData?.relationships,
        }
      },
    },
  }
</script>
