import { JobStatus } from "@/modules/job-costing/enum/jobs";

export const jobProfitReportFormats = {
  Short: 'short',
  Long: 'long',
  SingleLine: 'single-line',
  Periods: 'periods',
  ActivePeriods: 'active-periods',
}

export const computeTotalBy = {
  PhaseAndCostCode: 'phase-and-cost-code',
  PhaseCode: 'phase-code',
  Job: 'job',
}

export const commonFilters = {
  job_statuses: [JobStatus.Open],
  job_number_from: null,
  job_number_to: null,
  job_ids: [],
  job_date: null,

  phase_code_from: null,
  phase_code_to: null,
  cost_code_from: null,
  cost_code_to: null,
  change_order_from: null,
  change_order_to: null,
}

// * Report 2881
export const jobCostDetailPrintOptions = {
  HistoryAndBudgets: 'job history & budgets',
  LineItemTotals: 'line item totals only',
  PageSelectedJobs: 'job page - selected jobs',
  PagePeriodActive: 'job page - period active jobs only',

  SummarySelectedJobs: 'job summary page - selected jobs',
  SummaryPeriodActive: 'job summary page - period active jobs only',
  History: 'job history only',
  LineItemPhaseTotals: 'line items and phase totals',
}

export const JobCostVarianceReportOptions = {
  Format: {
    Summary: 'summary',
    Detail: 'detail',
  }
}
