<template>
  <div class="page-back-button shadow-md print:hidden">
    <router-link v-bind="$attrs"
                 v-on="$listeners"
                 class="flex items-center text-gray-900 text-sm hover:text-primary-600">
      <div class="flex items-center">
        <arrow-left-icon class="w-4 h-4 mr-2"></arrow-left-icon>
        <slot>
          <span>{{ $t('Back to the list') }}</span>
        </slot>
      </div>
    </router-link>
  </div>
</template>
<script>
  import { ArrowLeftIcon } from 'vue-feather-icons'

  export default {
    inheritAttrs: false,
    components: {
      ArrowLeftIcon
    },
  }
</script>
<style scoped>
  .page-back-button {
    @apply z-10 w-full p-4 rounded bg-gray-50 mt-4 bottom-0;
  }
</style>
