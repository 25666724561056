<template>
  <base-form
      :layout="layout"
      :loading="loading"
      :show-cancel="showCancel"
      :show-back="showBack"
      :save-text="$t('Create equipment class')"
      :update-text="$t('Update equipment class')"
      grid-classes="grid grid-cols-8 gap-x-4"
      @cancel="onCancel"
      @submit="onSubmit"
  >
    <base-input
        v-model="model.code"
        :label="$t('Code')"
        :disabled="!!model.id || readonly"
        :placeholder="$t('Code')"
        class="col-span-8 md:col-span-2"
        id="code"
        rules="required|code"
    />
    <base-input
        v-model="model.description"
        :label="$t('Description')"
        :placeholder="$t('Description')"
        class="col-span-8 md:col-span-5"
        id="description"
        rules="max:150"
    />
    <account-select
        v-model="model.disposal_account"
        :name="$t('Disposal Account')"
        :label="$t('Disposal Account')"
        class="col-span-8 md:col-span-2"
        id="disposal_account"
        value-key="number"
    />
    <sub-account-select
        v-model="model.disposal_subaccount"
        :label="$t('Disposal Sub Account')"
        class="col-span-8 md:col-span-2"
        value-key="number"
        id="disposal_subaccount"
    />
    <account-select
        v-model="model.calculated_depreciation_account"
        :name="$t('Calculated Depreciation Account')"
        :label="$t('Calculated Depreciation Account')"
        class="col-span-8 md:col-span-2"
        id="calculated_depreciation_account"
        value-key="number"
    />
    <sub-account-select
        v-model="model.calculated_depreciation_subaccount"
        :label="$t('Calculated Depreciation Sub Account\n')"
        class="col-span-8 md:col-span-2"
        value-key="number"
        id="calculated_depreciation_subaccount"
    />
    <AgDataTable
        ref="costTypes"
        class="col-span-8"
        url="/restify/equipment-types"
        :transform-data="mapData"
        :columns="columns"
        :url-params="{
          sort: 'index',
        }"
        v-bind="editableTableProps"
        @grid-ready="grid = $event"
    />
  </base-form>
</template>
<script>
  import axios from 'axios'
  import unionBy from 'lodash/unionBy'
  import AccountSelect from '@/modules/ledger/components/AccountSelect'
  import SubAccountSelect from '@/modules/ledger/components/SubAccountSelect'
  import { cellClasses } from '@/components/ag-grid/columnUtils'
  import { cellEditors } from '@/components/ag-grid/cellEditors/cellEditors'
  import { editableTableProps, getTableData } from '@/components/ag-grid/tableUtils'

  export default {
    components: {
      AccountSelect,
      SubAccountSelect,
    },
    props: {
      redirectToListAfterStore: {
        type: Boolean,
        default: true,
      },
      data: {
        type: Object,
        default: () => ({}),
      },
      showBack: {
        type: Boolean,
        default: true,
      },
      readonly: Boolean,
    },
    data() {
      return {
        loading: false,
        showCancel: true,
        model: {
          code: '',
          description: '',
          disposal_account: undefined,
          disposal_subaccount: undefined,
          calculated_depreciation_account: undefined,
          calculated_depreciation_subaccount: undefined,
        },
        editableTableProps,
        costTypes: [],
        grid: null,
      }
    },
    computed: {
      layout() {
        return this.redirectToListAfterStore ? 'vertical' : 'modal'
      },
      columns() {
        return [
          {
            headerName: this.$t('Name'),
            field: 'name',
            cellClass: cellClasses.ReadOnly,
            minWidth: 80,
            maxWidth: 320,
          },
          {
            headerName: this.$t('Abbr'),
            field: 'abbr',
            cellClass: cellClasses.ReadOnly,
            minWidth: 50,
            maxWidth: 100,
          },
          {
            headerName: this.$t('Debit Account'),
            field: 'debit_account',
            editable: true,
            cellEditor: cellEditors.AccountSelect,
            cellEditorParams: {
              valueKey: 'number',
            },
            component: 'AccountLink',
            cellRendererParams: params => {
              return {
                number: params.data.debit_account,

              }
            },
            minWidth: 150,
          },
          {
            headerName: this.$t('Sub'),
            field: 'debit_subaccount',
            editable: true,
            cellEditor: cellEditors.SubAccountSelect,
            cellEditorParams: {
              valueKey: 'number',
            },
            component: 'SubAccountLink',
            cellRendererParams: params => {
              return {
                number: params.data.debit_subaccount,
                valueKey: 'number',
              }
            },
            minWidth: 100,
          },
        ]
      },
    },
    methods: {
      mapData(data) {
        return data.map(item => item.attributes)
      },
      onCancel() {
        if (this.redirectToListAfterStore) {
          return this.$router.push('/equipment/settings/classes')
        }
        this.$emit('cancel')
      },
      async onSubmit() {
        try {
          this.loading = true
          const model = {
            ...this.model,
            costTypes: getTableData(this.grid?.api),
          }

          if (model.id) {
            await axios.put(`/restify/equipment-classes/${model.id}`, model)
            this.$success(this.$t('Equipment class code updated'))
            await this.assignCostTypes(model.id)
          } else {
            const { data } = await axios.post('/restify/equipment-classes', model)
            this.$success(this.$t('Equipment class code created'))
            await this.assignCostTypes(data.id)
            if (this.redirectToListAfterStore) {
              await this.$router.push(`/equipment/settings/classes/${data.id}/edit`)
            }
            this.$emit('save', data)
          }
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Something went wrong. Please try again.'))
        } finally {
          this.loading = false
        }
      },
      async assignCostTypes(codeID) {
        if (!this.costTypes.length) {
          return
        }

        const payload = this.costTypes.map((type) => ({
          type_id: type.id,
          debit_account: type.debit_account || '',
          debit_subaccount: type.debit_subaccount || '',
        }))

        await axios.post(`/restify/equipment-classes/${codeID}/add-types`, payload)
      },
      async getCostTypes() {
        await this.getDefaultCostTypes()

        if (!this.model.id) {
          return
        }

        let assignedCostTypes = this.data?.relationships?.types || []
        assignedCostTypes = assignedCostTypes.map(type => ({ ...type.attributes, ...type.pivots }))
        this.costTypes = unionBy(assignedCostTypes, this.costTypes, 'id')
      },
      async getDefaultCostTypes() {
        const { data } = await axios.get('/restify/equipment-types')
        this.costTypes = data.map(type => type.attributes)
      },
    },
    watch: {
      'data.attributes': {
        immediate: true,
        handler(value) {
          if (!value?.id) {
            return
          }
          this.model = {
            ...this.model,
            ...value,
          }
        },
      },
    },
    async mounted() {
      await this.getCostTypes()
    },
  }
</script>
