<template>
  <div>
    <TimecardsTable :batch="currentResource" ref="table"/>
  </div>
</template>
<script>
  import TimecardsTable from '@/modules/payroll/pages/timecard/TimecardsTable'

  export default {
    components: {
      TimecardsTable,
    },
    computed: {
      currentResource() {
        return this.$store.state.payroll.currentTimecardBatch
      },
    },
  }
</script>
