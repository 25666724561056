<template>
  <entity-select
    url="/restify/payroll-batches"
    v-bind="$attrs"
    v-on="$listeners"
    :url-params="urlParams"
    :name="$attrs.name || $t('Payroll Batch')"
    :placeholder="$attrs.placeholder || $t('Select Payroll Batch')"
    :label="$attrs.label !== undefined ? $attrs.label : $t('Payroll Batch')"
    :id="$attrs.id || 'payroll-batch-select'"
    :table-columns="tableColumns"
    :label-format="formatLabel"
    label-key="period_end_date"
  />
</template>
<script>
import capitalize from "lodash/capitalize";

export default {
  name: 'PayrollBatchSelect',
  inheritAttrs: false,
  props: {
    urlParams: {
      type: Object,
      default: () => ({
        sort: '-period_end_date'
      }),
    },
  },
  data() {
    return {
      tableColumns: [
        {
          minWidth: 100,
          maxWidth: 150,
          name: this.$t('Period End Date'),
          prop: 'attributes.period_end_date',
          toFormat: (periodEndDate) => {
            return this.$formatDateOnly(periodEndDate)
          }
        },
        {
          minWidth: 180,
          maxWidth: 180,
          name: this.$t('Description'),
          prop: 'attributes.description',
        },
        {
          minWidth: 80,
          maxWidth: 120,
          name: this.$t('Status'),
          prop: 'attributes.status',
          toFormat: status => {
            return capitalize(status)
          }
        },
      ],
    }
  },
  methods: {
    formatLabel(batch) {
      const { period_end_date } = batch.attributes
      return this.$formatDateOnly(period_end_date)
    },
  },
}
</script>
