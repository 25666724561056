<template>
  <div class="p-3 break-inside-avoid">

    <div class="flex text-gray-700">

      <dl class="w-1/4">
        <dt>
          <router-link :to="`/payroll/employees/${employee.id}/view`">
            {{ employee.name }}
          </router-link>
        </dt>
        <dt>&nbsp;</dt>
        <dt>&nbsp;</dt>
        <dt class="capitalize space-x-4">
          <router-link :to="`/payroll/employees/${employee.id}/view`">
            {{ employee.masked_ssn }}
          </router-link>
          <span>{{ federalStatusAndAllowances }}</span>
          <span>{{ employeeSex }}</span>
          <span
              v-if="displayMinority"
              class="capitalize"
          >
            {{ employee.minority }}
          </span>
        </dt>
      </dl>

      <div class="w-1/4 flex flex-col justify-between">
        <div v-if="data.craft_code">
          <p class="text-center font-medium mb-2 section-caption">
            {{ $t('Total Pay This Job') }}
          </p>
          <p class="flex justify-between">
            <span>{{ $t('Base Hourly Pay') }}</span>
            {{ $formatPrice(data.craft_code.base_pay_amount) }}
          </p>
          <p class="flex justify-between">
            <span>{{ $t('Fringe Paid To Plans') }}</span>
            {{ $formatPrice(fringeTotals.fringe_paid_to_plans) }}
          </p>
          <p
            v-if="otherDirectPayTotal !== 0"
            class="flex justify-between">
            <span>{{ $t('Other Direct Pay') }}</span>
            {{ $formatPrice(otherDirectPayTotal) }}
          </p>
        </div>
        <div v-if="data.craft_code">
          <p v-if="fringeTotals.fringe_paid_in_cash" class="flex justify-between">
            <span>{{ $t('Fringe Paid In Cash') }}</span>
            {{ $formatPrice(fringeTotals.fringe_paid_in_cash) }}
          </p>
          <p v-if="data.craft_code" class="flex justify-between average-rate">
            <span>{{ $t('Average Hour Rate') }}</span>
            {{ $formatPrice(data.craft_code?.avg_hourly_rate) }}
          </p>
        </div>
      </div>

      <dl class="w-1/4 mx-8">
        <dt class="text-center font-medium mb-2 section-caption">
          {{ $t('Tax Deductions') }}
        </dt>

        <dt
            v-for="tax in taxes"
            class="flex justify-between"
        >
          <router-link to="/payroll/codes/tax-codes">
            {{ tax.description }}
          </router-link>
          {{ $formatPrice(tax.amount) }}
        </dt>
      </dl>

      <dl class="w-1/4">
        <dt class="text-center font-medium mb-2 section-caption">
          {{ $t('Other Deductions') }}
        </dt>
        <dt
            v-for="deduction in deductions"
            class="flex justify-between"
        >
          <router-link :to="`/payroll/codes/deduction-codes?id=${deduction.id}`">
            {{ deduction.description }}
          </router-link>
          {{ $formatPrice(deduction.amount) }}
        </dt>
      </dl>

    </div>

    <div class="mt-4 pb-3 border-b border-gray-400 flex gap-8">
      <dl class="w-1/3">
          <template v-if="data.craft_code">
              <dt class="font-medium text-center mb-2 section-caption"> {{ $t('Job Fringe Benefit Summary') }}</dt>
              <dt v-for="benefit in allBenefits"
                  class="space-x-12 flex w-full">
                  <router-link :to="`/payroll/codes/benefit-codes?id=${benefit.id}`" class="w-full truncate">
                      {{ benefit.description }}
                  </router-link>
                  <span class="flex w-12 justify-end">
                    <template v-if="benefit.classify_as === PaidToPlan">
                      {{$t('To Plans')}}
                    </template>
                    <template v-if="benefit.classify_as === HourlyBasePay">
                      {{$t('Other Pay')}}
                    </template>
                    <template v-else>
                      {{$t('In Cash')}}
                    </template>
                  </span>
              <p class="flex w-32 justify-end">{{ $formatPrice(benefit.amount) }}</p>
          </dt>
        </template>
      </dl>
      <dl class="w-2/3">
        <dt class="font-medium text-center mb-2 section-caption"> {{ $t('Total Pay All Jobs') }}</dt>
        <dt class="space-x-10 flex justify-between">
          <span>{{ $t('Gross Pay:') }}</span>
          <span>{{ $formatPrice(payrollTotals.gross_pay) }}</span>
          <span>{{ $t('Less Deductions:') }}</span>
          <span>{{ $formatPrice(lessDeductions) }}</span>
          <span>{{ $t('Net Pay:') }}</span>
          <span>{{ $formatPrice(payrollTotals.net_pay) }}</span>
          <span class="whitespace-nowrap">{{ $t('Check #') }}</span>
          <router-link :to="`/payroll/batches/${payroll.payroll_batch_id}/summary`">
            {{ payroll.payment_number }}
          </router-link>
        </dt>
      </dl>
    </div>

  </div>
</template>
<script>
  import { minorityTypes, sexOptions } from '@/enum/enums'
  import sumBy from "lodash/sumBy";

  export default {
    name: 'CertifiedPayrollReportRow',
    props: {
      data: {
        required: true,
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        PaidToPlan: 'fringe-paid-to-plans',
        HourlyBasePay: 'hourly-base-pay',
      }
    },
    computed: {
      displayMinority() {
        return this.employee.minority !== minorityTypes.None
      },
      employee() {
        return this.data.employee || {}
      },
      employeeSex() {
        return sexOptions[this.employee.sex]
      },
      allCraftCodesFotCurrentEmployee() {
        return this.get(this.data, 'craft_codes', [])
      },
      allBenefits() {
        return this.data.craft_code?.benefits || []
      },
      taxes() {
        return this.data.taxes || {}
      },
      fringeTotals() {
        return this.data.craft_code?.fringe_totals || {}
      },
      otherDirectPayTotal() {
        return this.data?.craft_code?.other_pay?.amount
      },
      deductions() {
        return this.data.deductions || {}
      },
      federalStatusAndAllowances() {
        const status = this.employee.federal_status.slice(0, 1)
        return `${status}/${this.employee.allowances}`
      },
      payroll() {
        return this.data.payroll || {}
      },
      payrollTotals() {
        return this.payroll.totals || {}
      },
      lessDeductions() {
        const { deductions: deductions = 0, employee_taxes: employee_taxes = 0 } = this.payrollTotals
        return deductions + employee_taxes
      }
    },
  }
</script>
<style>
  .section-caption {
    @apply border-b border-dashed border-gray-400 mx-2;
  }
</style>
