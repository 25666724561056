<template>
  <div class="mt-10 sm:mt-0">
    <base-alert v-if="!employee.id"
                :type="$promptType.Danger"
                class="col-span-6 mb-3">
      {{$t(`You don't have an employee associated with your account and therefore you can't access employee payroll settings`) }}
    </base-alert>
    <base-form v-bind="$attrs"
               :description="$t('Update your payroll specific settings')"
               :show-buttons="false"
    >
      <div class="col-span-6"
           :class="{'opacity-50': !employee.id}"
      >
        <base-switch v-model="employee.receive_checks_via_email"
                     :disabled="!employee.id"
                     :label-info="$t(`Receive checks via email`)"
                     @input="updateSettings"
        >
        </base-switch>
        <small class="text-xs text-gray-600">
          {{
            $t(`When disabled, we won't send your checks via email. You can still access them from the app at any time.`)
          }}
        </small>
      </div>
    </base-form>
  </div>
</template>
<script>
  import axios from "axios";

  export default {
    computed: {
      employee() {
        const user = this.$store.state.auth.user
        return user?.employee || {}
      },
    },
    methods: {
      async updateSettings() {
        try {
          await axios.patch('/restify/employees/receive-checks-via-email')
          this.$store.dispatch('auth/refreshProfile')
          this.$success(this.$t('Setting updated successfully'))
        } catch (err) {
          if (!err.handled) {
            this.$error(this.$t('Could not update employee settings.'))
          }
        }
      }
    }
  }
</script>
<style>
</style>
