<template>
  <div class="bg-white overflow-hidden rounded-md border border-gray-200">
    <div class="pl-6 py-6 flex items-center">
      <div v-if="$slots.icon || icon"  class="w-12 h-12 card-icon-wrapper bg-primary-50 flex items-center justify-center rounded-md mr-4">
        <slot name="icon">
          <component class="w-4 h-4 card-icon text-primary-600" :is="icon"/>
        </slot>
      </div>
      <div class="flex flex-col overflow-y-auto">
        <dt class="font-medium text-gray-500 tracking-wide text-sm -mb-1">
          <slot name="title">
            {{title}}
          </slot>
        </dt>
        <dd class="text-lg font-medium truncate text-gray-900 tracking-tight card-description">
          <slot></slot>
        </dd>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      title: {
        type: String,
        default: ''
      },
      icon: {
        type: String,
      }
    },
  }
</script>
<style lang="scss">
  .card-description {
    @apply leading-normal;
  }
  .card-description a {
    @apply text-gray-900 hover:underline;
  }
  .card-icon-wrapper {
    min-width: 48px;
    min-height: 48px;
  }
</style>
