<template>
  <div class="flex-1 grid grid-cols-7 gap-x-2 text-sm print:text-print">
    <div class="flex flex-col col-span-3 space-y-1 justify-start">
      <span class="tracking-wide font-medium text-gray-400">
        <slot name="label">
          {{ label }}
        </slot>
      </span>
    </div>
    <div
        :class="{ 'capitalize': capitalize }"
        class="col-span-4 flex flex-col space-y-1 justify-start items-end pl-2">
      <span class="tracking-wide text-gray-900">
        <slot>
          {{ value }}
        </slot>
      </span>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      label: String,
      value: String,
      capitalize: {
        type: Boolean,
        default: true
      }
    }
  }
</script>
