<template>
  <base-tabs v-model="activeTab"
             :tabs="accountTypeTabs"
             label-key="label"
  >
    <AgDataTable
        v-if="activeTab === costTypes.Cost"
        v-bind="tableProps"
        :data="costAccounts"
        :data-loading="loading"
        class="col-span-6 lg:col-span-3 mt-4"
        ref="costTable"
    />
    <AgDataTable
        v-else
        v-bind="tableProps"
        :data="incomeAccounts"
        :data-loading="loading"
        class="col-span-6 lg:col-span-3 mt-4"
        ref="incomeTable"
    />
  </base-tabs>
</template>
<script>
  import axios from 'axios'
  import sortBy from 'lodash/sortBy'
  import unionBy from 'lodash/unionBy'
  import { costTypes } from '@/enum/enums'
  import { getAccountTypeTabs, getTableProps } from '@/modules/common/components/settings/util'

  export default {
    props: {
      getTypesUrl: String,
      addTypesUrl: String,
      module: String,
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        costTypes,
        activeTab: costTypes.Cost,
        categoryTypes: [],
        costAccounts: [],
        incomeAccounts: [],
        loading: false,
      }
    },
    computed: {
      accountTypeTabs() {
        return getAccountTypeTabs(this.module)
      },
      tableProps() {
        return {
          ...getTableProps(this.module, this.activeTab),
          domLayout: 'autoHeight',
          pagination: false,
          showPagination: false,
        }
      },
    },
    methods: {
      mapTypeRow(row, codeId) {
        return {
          ...row,
          type_id: row.id,
          category_id: codeId,
        }
      },
      async assignTypes(categoryId) {
        let allAccounts = [...this.costAccounts, ...this.incomeAccounts]

        let accounts = allAccounts
            .filter(account => account.dirty)
            .map(row => this.mapTypeRow(row, categoryId))

        await axios.post(`${this.addTypesUrl}/${categoryId}/add-types`, accounts)
      },
      getCostsByType(costingType) {
        const filteredTypes = this.categoryTypes.filter(op => op.type.toString() === costingType.toString())
        return sortBy(filteredTypes, 'name')
      },
      assignAccounts() {
        this.costAccounts = this.getCostsByType(costTypes.Cost)
        this.incomeAccounts = this.getCostsByType(costTypes.Income)
      },
      async getCostTypes() {
        try {
          this.loading = true
          await this.getDefaultCostTypes()

          if (!this.data?.attributes?.id) {
            this.loading = false
            return
          }

          let assignedCostTypes = this.data?.costTypes || []
          assignedCostTypes = assignedCostTypes.map(type => ({ ...type.attributes, ...type?.pivots }))
          this.categoryTypes = unionBy(assignedCostTypes, this.categoryTypes, 'id')
          this.assignAccounts()
        } finally {
          this.loading = false
        }
      },
      async getDefaultCostTypes() {
        const { data } = await axios.get(this.getTypesUrl)
        this.categoryTypes = data.map(type => {
          return {
            ...type.attributes,
            credit_account: null,
            credit_subaccount: null,
            debit_account: null,
            debit_subaccount: null,
            non_tax_account: null,
            non_tax_subaccount: null,
            account: null,
            subaccount: null,
            markup_percent: null,
          }
        })
        this.assignAccounts()
      },
    },
    async mounted() {
      await this.getCostTypes()
    },
  }
</script>
